<div class="disaggregation-tab">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <ng-container [ngTemplateOutlet]="viewAccordion"></ng-container>
  </ng-container>
</div>

<ng-template #viewAccordion>
  <mat-accordion>
    <mat-expansion-panel [expanded]="!!disaggPlots?.length"
                         [hideToggle]="!disaggPlots?.length">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Result
        </mat-panel-title>
        <mat-panel-description *ngIf="!disaggPlots?.length">
          {{ !disaggPlots.length ? 'Disaggregation not needed for this variable' : 'Images and graphs' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngIf="disaggPlots?.length">
        <indicio-plot-display [plots]="disaggPlots"
                              [plotWidth]="700"></indicio-plot-display>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #loadingTemplate>
  <div><i class="ion-load-c spinner"></i></div>
</ng-template>
