<h1 mat-dialog-title
    style="margin: 0">{{ isUpdate ? 'Update' : 'Create' }} hierarchical forecast</h1>
<div mat-dialog-content>
  <div *ngIf="!isLoading; else showLoader"
       class="hierarchical-dialog-container">
    <div class="ctc-options">
      <ng-container *ngTemplateOutlet="createOptionsTemplate"></ng-container>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button
          (click)="close()">
    Cancel
  </button>
  <button mat-flat-button
          data-test-id="create-h-fcast-button-dialog"
          color="primary"
          [disabled]="isLoading"
          (click)="isUpdate ? update() : create()">
    <i *ngIf="isLoading"
       class="ion-load-c spinner"></i>
    <span *ngIf="!isLoading">{{ isUpdate ? 'Update' : 'Create' }}</span>
  </button>
</div>

<ng-template #createOptionsTemplate>
  <ng-container *ngIf="isLoading">
    <i class="ion-load-c spinner info-loader "></i>
  </ng-container>
  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Select valid periodicity">
    <mat-form-field>
      <mat-select [(value)]="dto.Periodicity"
                  (selectionChange)="setForecastPoints()"
                  required>
        <mat-option [value]="p.Value"
                    [title]="p.Description"
                    *ngFor="let p of periodicities">{{ p.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Select horizon">
    <mat-form-field>
      <mat-select [(ngModel)]="dto.Horizon"
                  required
                  name="horizon">
        <mat-option [value]="point"
                    [title]="point"
                    *ngFor="let point of forecastPoints">{{ point }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #noSVarsFound>
  <div class="row">
    <div class="col-xs-12">No more variables found to add!</div>
  </div>
</ng-template>

<ng-template #showLoader>
  <i class="ion-load-c spinner info-loader "></i>
</ng-template>
