import { Def } from '@core/decorators/def';

export enum TagTypes {
  USERDEFINED = 0,
  CONTINENT = 1,
  COUNTRY = 2,
  USERDEFINEDREGION = 3,
  CUSTOMREGION = 4
}

export class CreateTagDTO {
  Name: string = '';
  ParentTagIds: string[] = [];
  ChildTagIds: string[] = [];
  Type: TagTypes;
}

export class TagMetaDTO {
  Name: string = '';
  TagId: string;
}
export class UpdateTagDTO extends CreateTagDTO {
  TagId: string;

}
export class TagDTO {
  @Def() TagId: string;
  @Def() CompanyId: string;
  @Def() Name: string = '';
  @Def() ParentTagIds: string[] = [];
  @Def() ChildTagIds: string[] = [];
  @Def() Type: TagTypes;
}
