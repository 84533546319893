import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataProvider } from '@core/constants/provider-definitions';

export interface RedirectDialogData {
  Url: string;
  To: string;
}

@Component({
  selector: 'indicio-redirect-dialog',
  templateUrl: 'redirect.dialog.html',
})
export class RedirectDialogComponent {
  public static Id: string = 'RedirectDialogComponent';

  public provider: string;


  constructor(
    public dialogRef: MatDialogRef<RedirectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RedirectDialogData) {
    switch (data.To) {
      case DataProvider.macrobondapi:
        this.provider = 'Macrobond';
        break;
      default:
        this.provider = 'Unknown';
    }

    setTimeout(() => {
      window.location.href = data.Url;
    }, 3000);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(true);
  }
}
