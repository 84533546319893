import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { BrowseNowcastModalOpts } from './browse-nowcast-modal.options';

export class OpenBrowseNowcastModal {
  public static readonly type = '[MODAL] Open browse nowcast';
  data: BrowseNowcastModalOpts;
  constructor(
    nowcast: ForecastModel,
    variable: ForecastVariableModel
  ) {
    this.data = { nowcast, variable };
  }
}
