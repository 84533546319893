<div class="outliers-tab">
  <ng-container *ngIf="!variable.IsTrend; else trendTemplate">
    <ng-container *ngTemplateOutlet="accordionTemplate"></ng-container>
  </ng-container>
</div>

<ng-template #accordionTemplate>
  <mat-accordion>
    <mat-expansion-panel [expanded]="!!outlierPlots.length && variable.CalculateOutliers"
                         (opened)="infopanelOpen = true"
                         (closed)="infopanelOpen = false"
                         [hideToggle]="!!!outlierPlots.length">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Result
        </mat-panel-title>
        <mat-panel-description *ngIf="!infopanelOpen || !outlierPlots.length">
          {{ !!!outlierPlots.length ? 'No outliers found' : 'Images and graphs' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="outlierContentTemplate"></ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="!variable.CalculateOutliers">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Settings
        </mat-panel-title>
        <mat-panel-description>
          {{ calculateOutliers ? 'Outlier adjustment and detection' : 'Outlier adjustment is disabled' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="settingsTemplate"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #outlierContentTemplate>
  <div style="margin-top: -8px;"
       *ngIf="!!outlierPlots.length && variable.CalculateOutliers">
    <div class="grid-system"
         style="--grid-template: 1fr 300px; gap: 2px">
      <ng-container *ngTemplateOutlet="graphTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="infoTemplate"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #graphTemplate>
  <indicio-plot-display style="margin: 23px 0 0 0;"
                        [link]="true"
                        [plots]="outlierPlots"
                        [plotWidth]="410"></indicio-plot-display>
</ng-template>

<ng-template #infoTemplate>
  <div *ngIf="variable.OutlierInfo.length">
    <div class="data-table"
         style="width: 300px;">
      <div class="data-table__header">
        <div class="column header left-align"
             style="width: 80px;">Date</div>
        <div class="column header left-align"
             style="width: 50px;">Type</div>
        <div class="column header left-align"
             style="width: 70px;">Old value</div>
        <div class="column header left-align"
             style="width: 100px;">Adjusted value</div>
      </div>
      <div class="data-table__body"
           #fileuploadscroll
           [style.maxHeight.px]="275">
        <ng-container *ngFor="let outlier of variable.OutlierInfo; let i = index;">
          <ng-container *ngTemplateOutlet="outlierRow; context: { i, outlier } ">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #outlierRow
             let-outlier="outlier">
  <ng-container>
    <div class="body__row body__row--center-height body__row--max-height-30">
      <div class="column left-align filename-Medium crop-string"
           style="width: 80px;"
           [title]="outlier.Date | date: appearance.DateFormat">
        {{ outlier.Date | date: appearance.DateFormat }}
      </div>
      <div class="column left-align"
           style="width: 50px;"
           [title]="outlier.Type">
        {{ outlier.Type }}
      </div>
      <div class="column left-align crop-string"
           style="width: 70px;"
           [title]="outlier.OriginalValue">{{ outlier.OriginalValue }}
      </div>
      <div class="column left-align crop-string"
           style="width: 100px;"
           [title]="outlier.OutlierValue">{{ outlier.OutlierValue }}
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #trendTemplate>
  <indicio-open-twin-component [options]="options"
                               [type]="'Outliers are'"
                               [tab]="viewEnum.outliers"
                               [twinId]="variable.TrendTwinId"
                               (closeDialogEvent)="closeDialogEvent.emit()">
  </indicio-open-twin-component>
</ng-template>

<ng-template #settingsTemplate>
  <indicio-modal-option-row optionTitle="Enabled"
                            optionSubtitle="Might slow down calculations significally if enabled"
                            textWidth="8"
                            optionsWidth="4">
    <div style="margin-top: 25px;">
      <mat-slide-toggle color="accent"
                        [checked]="calculateOutliers"
                        (change)="toggleOutlier()">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>
  <indicio-modal-option-row optionTitle="Model"
                            optionSubtitle="The model to use when searching for outliers"
                            textWidth="8"
                            optionsWidth="4"
                            [disabled]="!calculateOutliers">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [value]="newOutlierModel"
                  required
                  [disabled]="!calculateOutliers"
                  (selectionChange)="setModel($event)">
        <mat-option [value]="mode.Value"
                    [title]="mode.Description"
                    *ngFor="let mode of envService.OutlierModels">{{ mode.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Strategy"
                            optionSubtitle="Used to determine which outliers to remove"
                            textWidth="8"
                            optionsWidth="4"
                            [disabled]="!calculateOutliers">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [value]="newOutlierStrategy"
                  required
                  [disabled]="!calculateOutliers"
                  (selectionChange)="setStrat($event)">
        <mat-option [value]="mode.Value"
                    [title]="mode.Description"
                    *ngFor="let mode of envService.OutlierStrategies">{{ mode.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Types"
                            optionSubtitle="Defines what kind of outliers to search for"
                            textWidth="8"
                            optionsWidth="4"
                            [disabled]="!calculateOutliers">
    <mat-form-field *ngIf="!inSetup"
                    style="margin-top: 15px;">
      <mat-select [value]="selectedOutlierTypes"
                  required
                  [disabled]="!calculateOutliers"
                  [multiple]=true>
        <mat-option [value]="mode.Value"
                    [title]="mode.Description"
                    (onSelectionChange)="selectType($event)"
                    *ngFor="let mode of envService.OutlierTypes">{{ mode.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <div class="pull-right"
       style="width:fit-content; margin-top: 25px;"
       [indicioTippy]="!selectedOutlierTypes.length ? 'You must select at least one outlier type' : null">
    <button mat-raised-button
            type="button"
            color="primary"
            [disabled]="!selectedOutlierTypes.length || !changed"
            (click)="saveSettings()">
      <indicio-spinner *ngIf="pending"></indicio-spinner>
      Save changes
    </button>
  </div>
</ng-template>
