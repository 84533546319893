import { Component } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AceaDataSerieModel } from '@core/store/providers/acea/acea-dataserie.model';
import { AceaService } from '@core/store/providers/acea/acea.service';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { Store } from '@ngxs/store';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { AceaImportModalOpts } from './acea-import-modal.options';

@Component({
  selector: 'indicio-acea-import-modal',
  templateUrl: './acea-import-modal.component.html'
})
export class ACEAImportModalComponent extends ModalModelComponent {

  modalTitle = 'Import variable from ACEA';
  series: AceaDataSerieModel;
  forecastVersion: ForecastVersionModel;
  remoteDataModel = new RemoteDataRequestModel;

  loading = true;
  id: string = '';
  type: string = '';

  constructor(
    public store: Store,
    public service: AceaService,
    private clientService: ClientFrontendService,
    private statusService: StatusService,
    private forecastService: ForecastFrontendService,
    private forecastVariableService: ForecastVariableFrontendService,
    public envService: EnvironmentService
  ) {
    super();
  }

  public async setOptions(options: AceaImportModalOpts) {
    this.id = options.series.AceaVariableId;
    const fVersPromise = this.forecastService.getOrFetchForecastVersion(options.forecastVersionId);

    Promise.all([fVersPromise])
      .then(([v]) => {
        this.forecastVersion = v;
      })
      .catch(err => {
        this.statusService.setError(err, true);
      })
      .finally(() => {
        this.loading = false;
      });

    this.remoteDataModel.Name = (typeof options.series.Metadata.SelectedSubCategoryTitle !== 'undefined' ?
      options.series.Metadata.SelectedSubCategoryTitle : '') + (options.series.SelectedSubCategoryTitle !== 'total' ? ' - ' + options.series.title : '');
    this.series = options.series;
    this.type = this.MapType(this.series.Type);
    if (this.series.title.toLowerCase().indexOf('total') !== -1) {
      options.series.Metadata.SelectedSubCategoryTitle = options.series.Metadata.SelectedBaseCategoryTitle;
    } else {
      options.series.Metadata.SelectedSubCategoryTitle = (typeof options.series.Metadata.SelectedSubCategoryTitle !== 'undefined' ?
        options.series['Metadata']['SelectedSubCategoryTitle'] + ' - ' : '') + options.series.title;
    }

    options.series.Metadata.SelectedSubCategoryTitle = (options.series.Metadata.SelectedBaseCategoryTitle.length > 0 ?
      options.series.Metadata.SelectedBaseCategoryTitle + ' - ' : '') + options.series.title;

  }

  private MapType(type: string) {
    switch (type) {
      case 'heavytrucks':
        return 'Heavy trucks';
      case 'fuel':
        return 'Fuel';
      case 'passengercar':
        return 'Passenger car';
    }
    return '';
  }

  public async addVariable(series) {
    this.loading = true;

    const modell = new RemoteDataRequestModel();
    modell.RemoteUri = '';
    modell.Provider = 'acea';
    modell.RemoteReferenceId = series.Code ?? series.Target;
    modell.Name = `${this.remoteDataModel.Name}`;
    modell.MetaData = {
      Type: [series.Type],
      Code: [series.Code] ?? [series.Target],
      Company: [series.title],
      id: [this.id]
    };

    this.service.addRemoteVariable(this.clientService.activeCompany.CompanyId, modell)
      .then(variable => {
        this.statusService.setMessage('Variable added successfully', 'Success');
        this.close();
        this.forecastVariableService.checkErrorsAndCreateForecastVariable(variable, this.forecastVersion)
          .catch(_err => {
            this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [variable.SourceVariableId]));
          });
      })
      .catch(e => {
        this.statusService.setError(e, true);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
