import { Injectable } from '@angular/core';
import { CreateTagDTO, TagDTO, TagMetaDTO, UpdateTagDTO } from './dtos/tags.dtos';
import { TagModel } from './models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class TagsMapper {

  constructor() { }

  public mapTags(dtos: TagDTO[]) {
    const tags = dtos.map(x => this.mapTag(x));
    return tags;
  }

  public mapTag(dto: TagDTO) {
    const model = Object.faMapTo(new TagModel, dto);
    return model;
  }

  public toMeta(x: TagMetaDTO): any {
    const t = new TagMetaDTO();
    t.Name = x.Name;
    t.TagId = x.TagId;
    return t;
  }

  public toCreate(tag: TagModel) {
    const dto = new CreateTagDTO();
    dto.Name = tag.Name;
    dto.ChildTagIds = tag.ChildTagIds;
    dto.ParentTagIds = tag.ParentTagIds;
    dto.Type = tag.Type;
    return dto;
  }

  public toUpdate(tag: TagModel) {
    const dto = new UpdateTagDTO();
    dto.Name = tag.Name;
    dto.ChildTagIds = tag.ChildTagIds;
    dto.ParentTagIds = tag.ParentTagIds;
    dto.TagId = tag.TagId;
    return dto;
  }
}
