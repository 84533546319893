<div class="company-edit-settings-tab"
     style="--grid-template-global: 1fr 1fr"
     *ngIf="!!company">
  <ng-container *ngTemplateOutlet="licenseTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="macrobondEnabledTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="trialSettings"></ng-container>
  <ng-container *ngTemplateOutlet="scriptSettings"></ng-container>
  <ng-container *ngTemplateOutlet="forecastSettings"></ng-container>
</div>

<ng-template #licenseTemplate>
  <div class="grid-system center-vertical info-row">
    <div style="padding-top: 10px;">License type</div>
    <div>
      <mat-form-field>
        <mat-select [(value)]="company.LicenseType"
                    (selectionChange)="emitCheckSaved()"
                    required>
          <mat-option [value]="mode.Value"
                      [title]="mode.Description"
                      *ngFor="let mode of envService.LicenseTypes">{{ mode.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #macrobondEnabledTemplate>
  <div class="grid-system center-vertical info-row">
    <div style="padding-top: 10px;">Macrobond enabled</div>
    <div>
      <mat-slide-toggle data-test-id='auto-variable-update-toggle-btn'
                        [checked]="company.MacrobondEnabled"
                        (change)="toggleMBE()">
      </mat-slide-toggle>
    </div>
  </div>
</ng-template>

<ng-template #trialSettings>
  <div class="grid-system center-vertical info-row">
    <div style="padding-top: 10px;">Trial status</div>
    <div>
      <mat-slide-toggle data-test-id='auto-variable-update-toggle-btn'
                        [checked]="company.Trial"
                        (change)="toggleTrialStatus()">
      </mat-slide-toggle>
    </div>
  </div>

  <div class="grid-system center-vertical info-row"
       [ngClass]="{ disabled: !company.Trial }">
    <div style="padding-top: 10px;">Trial expiry date</div>
    <div class="grid-system center-vertical"
         style="--grid-template: 1fr 30px">
      <div>
        <indicio-date-picker periodicity="day"
                             [value]="company.TrialExpirationDate"
                             [disabled]="!company.Trial"
                             (selectDateEvent)="setTrialExp($event.toDate())"
                             [min]="minDate"
                             placeholder="Choose a date">
        </indicio-date-picker>
      </div>
      <div class="red center-horizontal">
        <span class="pointer del ion-trash-b"
              indicioTippy="Remove trial expiry date"
              (click)="setTrialExp(null)"></span>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #scriptSettings>
  <div class="grid-system center-vertical info-row">
    <div style="padding-top: 10px;">Max RollingWindow Time</div>
    <div style="padding-top: 6px;">
      <indicio-number-input [value]="company.MaxRollingWindowTime"
                            (valueEvent)="company.MaxRollingWindowTime = $event; emitCheckSaved()">
      </indicio-number-input>
    </div>
  </div>
</ng-template>

<ng-template #forecastSettings>
  <div class="center-vertical info-row">Forecast horizon and periodicities</div>
  <div class="data-table"
       style="width: 320px;">
    <div class="data-table__header">
      <div class="column header left-align"
           style="width: 120px;">Periodicity</div>
      <div class="column header left-align"
           style="width: 100px;">Enabled</div>
      <div class="column header left-align"
           style="width: 120px;">Max horizon</div>
    </div>
    <div class="data-table__body"
         [style.maxHeight.px]="301">
      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngFor="let horizonSettings of company.MaxHorizons let i = index">
        <div class="column left-align"
             style="width: 120px;">{{ horizonSettings.Periodicity }}</div>
        <div class="column left-align"
             style="width: 100px;">
          <mat-slide-toggle [checked]="horizonSettings.Enabled"
                            [disabled]="horizonSettings.Periodicity === 'halfyear'"
                            (change)="togglePeriodicity(horizonSettings)">
          </mat-slide-toggle>
        </div>
        <div class="column"
             style="width: 120px">
          <mat-form-field>
            <input required
                   [disabled]="!horizonSettings.Enabled || horizonSettings.Periodicity === 'halfyear'"
                   class="material"
                   matInput
                   min=1
                   type="number"
                   name="horizon"
                   (ngModelChange)="setHorizonValue(horizonSettings.Periodicity, $event)"
                   [ngModel]="horizonSettings.MaxHorizon">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-template>
