import { AvailableForecastResultDTO, HierarchyReconciliationDTO } from '../entities/dtos/hierarchy-reconciliation.dto';
import { HierarchyRelationDTO, PotentialForecastsForRelationDTO } from '../entities/dtos/hierarchy-relation.dto';
import { HierarchyResultEventArgs } from '../entities/dtos/hierarchy.dto';
import { HierarchyModel } from '../entities/models/hierarchy.model';

export namespace HierarchyActions {

  //
  // Event base class
  //
  export interface IHasHierarchy { hierarchy: HierarchyModel; }
  //
  //
  // Hierarchies
  //
  //
  export class GetAllSuccess {
    public static readonly type = '[HIERARCHICAL FORECAST] Get all success';
    constructor(public hierarchies: HierarchyModel[]) { }
  }
  export class GetSuccess implements IHasHierarchy {
    public static readonly type = '[HIERARCHICAL FORECAST] Get success';
    constructor(public hierarchy: HierarchyModel) { }
  }
  export class RemoveSuccess {
    public static readonly type = '[HIERARCHICAL FORECAST] Remove success';
    constructor(public forecastId: string) { }
  }


  //
  //
  // Relations
  //
  //
  export class GetRelationSuccess {
    public static readonly type = '[HIERARCHICAL FORECAST] Get relation success';
    constructor(public dto: HierarchyRelationDTO) { }
  }

  export class UpdateRelationSuccess implements IHasHierarchy {
    public static readonly type = '[HIERARCHICAL FORECAST] Update relation success';
    constructor(public hierarchy: HierarchyModel) { }
  }

  export class RemoveRelationSuccess implements IHasHierarchy {
    public static readonly type = '[HIERARCHICAL FORECAST] Removed relation success';
    constructor(public hierarchy: HierarchyModel) { }
  }

  //
  //
  // Reconciliations
  //
  //

  export class GetReconciliationSuccess {
    public static readonly type = '[HIERARCHICAL FORECAST] Get reconciliation success';
    constructor(public reconciliation: HierarchyReconciliationDTO) { }
  }

  export class UpdateReconciliationSuccess {
    public static readonly type = '[HIERARCHICAL FORECAST] Update reconciliation';
    constructor(public hierarchy: HierarchyModel) { }
  }


  export class RemoveReconciliation {
    public static readonly type = '[HIERARCHICAL FORECAST] Remove reconciliation';
    constructor(public hierarchyId: string, public reconciliationId: string) { }
  }

  export class FetchNewReconciliationResult {
    public static readonly type = '[HIERARCHICAL FORECAST] Fetch new reconciliation result';
    constructor(public dto: HierarchyResultEventArgs) { }
  }

  //
  //
  // Misc
  //
  //
  export class GetPotentialForecasts {
    public static readonly type = '[HIERARCHICAL FORECAST] Get GetPotentialForecasts';
    constructor(public hierarchyId: string, public dtos: PotentialForecastsForRelationDTO[]) { }
  }

  export class GetResultsSuccess {
    public static readonly type = '[HIERARCHICAL FORECAST] Get results success';
    constructor(public hierarchyId: string, public dtos: AvailableForecastResultDTO[]) { }
  }

  export class TriggerGenerateTree {
    public static readonly type = '[HIERARCHICAL FORECAST] TriggerGenerateTree';
    constructor() { }
  }

  export class TreeGenerated {
    public static readonly type = '[HIERARCHICAL FORECAST] TreeRefreshed';
    constructor() { }
  }
}
