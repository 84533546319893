export namespace BrowserUtils {

  export function isMobile(): boolean {
    const body = document.body;
    const html = document.documentElement;
    const width = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
    const mobile = width < 768;
    return mobile;
  }

  export function isSupported() {
    // https://stackoverflow.com/a/9851769
    // @ts-ignore
    const isFirefox = typeof InstallTrigger !== 'undefined';
    // @ts-ignore
    const isChrome = !!window.chrome && /chrome/i.test(window.navigator.userAgent);
    // @ts-ignore
    const isHeadless = !!navigator.webdriver;
    return isFirefox || isChrome || isHeadless;
  }
}
