import { Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'indicio-button-dropdown',
  templateUrl: './button-dropdown.component.html'
})
export class ButtonDropdownComponent implements OnChanges {

  @ContentChild('content') content: ElementRef;

  @Input() callback: Function;
  @Input() text: string;
  @Input() loading: boolean;
  @Input() extraClasses: string;
  @Input() containerStyle: string;
  @Input() click: 'toggle';

  @Output() clickEvent = new EventEmitter();

  open = false;
  left = 0;
  top = 0;
  element: HTMLElement;
  buttonDisplay: string;
  buttonDescription: string;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.text && changes.text.currentValue) {
      const [display, description] = <string> changes.text.currentValue.split('|');
      this.buttonDisplay = display;
      this.buttonDescription = description;
    }
  }

  public toggle() {
    this.open = !this.open;
  }

  public runCallback() {
    if (!this.callback && this.click === 'toggle') {
      return this.toggle();
    }
    this.clickEvent.emit('clickEvent');
  }

  private bodyClose() {
    this.open = false;
  }

  @HostListener('document:click', ['$event.target'])
  public checkVisibility(target) {
    if (!this.element.contains(target)) {
      this.bodyClose();
    }
  }
}
