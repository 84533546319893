import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { StartDateModalOptions } from './start-date-modal.options';

export class OpenStartDateModal {
  public static readonly type = '[MODAL] Open start date modal';
  data: StartDateModalOptions;
  constructor(
    forecast: ForecastVersionModel,
    variable: ForecastVariableModel
  ) {
    this.data = { forecast, variable };
  }
}
