import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { ForecastTemplateFrontendService } from '@core/store/forecast-template/forecast-template.frontend.service';
import { ForecastTemplate, TemplateAuthorDTO } from '@core/store/forecast-template/forecast-template.model';

export interface AdminEditForecastTemplateDialogData {
  Template: ForecastTemplate;
}

@Component({
  selector: 'indicio-edit-forecast-template-dialog',
  templateUrl: 'admin-edit-forecast-template.dialog.html',
  styleUrls: ['admin-edit-forecast-template.dialog.less']
})
export class AdminEditForecastTemplateDialogComponent {
  public static Id: string = 'AdminEditForecastTemplateDialogComponent';

  public template: ForecastTemplate = null;
  public possibleAuthors: TemplateAuthorDTO[] = [];

  // Getters
  public get availableTags() { return this.templateService.availableTags; }

  // Frontend helpers
  public loader = false;

  constructor(
    public dialogRef: MatDialogRef<AdminEditForecastTemplateDialogComponent>,
    private templateService: ForecastTemplateFrontendService,
    private statusService: StatusService,
    @Inject(MAT_DIALOG_DATA) public data: AdminEditForecastTemplateDialogData
  ) {
    this.template = data.Template;
    this.templateService.getTags();
    this.syncAuthors();
  }

  private syncAuthors() {
    return this.templateService.getAuthors()
      .then(authors => {
        this.possibleAuthors = authors;
      });
  }

  public createNewTag() { this.templateService.createNewTag(); }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    return this.templateService.adminUpdate(this.template)
      .then(() => {
        this.dialogRef.close(true);
        this.statusService.setMessage('Template updated successfully', 'Success');
      });
  }
}
