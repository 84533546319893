import { Def } from '@core/decorators/def';

export class NewRemoteDataSourceModelModel {
  @Def() RemoteUri?: string;
  @Def() User?: string;
  @Def() Password?: string;
  @Def() Provider: string;
  @Def() SavePassword?: boolean = false;
  @Def() ApiKey?: string;
  @Def() Extras?: any;
}
