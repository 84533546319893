import { CreateForecastVariableDTO } from '@core/store/forecast-variable/dtos/create-forecast-variable-dto';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { SourceVariableViewDTO } from '@core/store/source-variable/source-variable.model';
import { VarSelectResultModel } from '@core/store/var-select/var-select-result.model';
import { Transformations } from '@core/types/transformation.type';

export namespace ForecastVariableUtils {

  export function MapCreateDtoFromTransform(variable: ForecastVariableModel, transform: Transformations.FVarTrans) {
    /* We use the source-name to get rid of any names given to us by an active transform. */
    const newName = transform.Transformation === 'no_transform' ? variable.SourceVariableName : (`${transform.getShortName()}:  ${variable.SourceVariableName}`);
    return Object.assign(new CreateForecastVariableDTO(), <CreateForecastVariableDTO> {
      AggregationMethodId: variable.AggregationMethodId,
      IsExogenous: false,
      Name: newName,
      SourceValuesTransformation: Object.assign({}, {
        Transformation: transform.Transformation,
        Arguments: transform.Arguments,
        Display: transform.Display
      }),
      SourceVariableMetaId: variable.SourceVariableMetaId
    });
  }

  export function GetSortedIndicators(fVersion: ForecastVersionModel, vsResult: VarSelectResultModel) {
    const indis = fVersion?.IndicatorVariables || [];
    indis.sort((a, b) => {
      if (!vsResult || !vsResult.ExitSuccess) {
        const aName = a.Name.toLowerCase();
        const bName = b.Name.toLowerCase();
        if (aName < bName) { return -1; }
        if (aName > bName) { return 1; }
        return 0;
      } else {
        const aInf = vsResult.getInfluenceAsNum(a.ForecastVariableId, 4);
        const bInf = vsResult.getInfluenceAsNum(b.ForecastVariableId, 4);
        return bInf - aInf;
      }
    });
    return indis;
  }

  export function NonOverlappingVariables(fVars: SourceVariableViewDTO[]) {
    let noneOverlapping;
    fVars.forEach((fVar, i) => {
      if (noneOverlapping) { return; }
      // Check if any other variable starts and ends before this one
      const anyStartsAndEndsBefore = fVars.some((fVar2) => fVar2.lastBaseObservation.getTime() < fVar.firstBaseObservation.getTime() && fVar2.firstBaseObservation.getTime() > fVar.lastBaseObservation.getTime());
      // Check if any other variable starts and ends after this one
      const anyStartsAndEndsAfter = fVars.some((fVar2) => fVar2.firstBaseObservation.getTime() > fVar.lastBaseObservation.getTime() && fVar2.lastBaseObservation.getTime() > fVar.lastBaseObservation.getTime());
      noneOverlapping = anyStartsAndEndsBefore || anyStartsAndEndsAfter;
    });
    return noneOverlapping;
  }
}
