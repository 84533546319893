import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

export abstract class FrontendBaseService<T> {

  constructor(
    protected store: Store,
    protected dialog: DialogService,
    protected backend: T
  ) {
  }

  protected confirmRemove(title: string, msg: string): Promise<boolean> {
    return this.dialog.openConfirmDialog({
      Title: title,
      Message: msg,
      CancelText: 'No',
      ConfirmText: 'Yes',
      Style: 'warn',
    }, { position: { top: '6%' } }).toPromise();
  }
}
