import { Injectable } from '@angular/core';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { CacheUtils } from '@shared/utils/cache.utils';
import { GetForecastSuccessAction } from '../forecast/forecast.actions';
import { ForecastTemplateBackendService } from './forecast-template.backend.service';
import { AdminUpdateForecastTemplateDTO, CreateForecastTemplateDTO, ForecastTemplate, ForecastTemplateTagDTO, UseForecastTemplateDTO } from './forecast-template.model';


@Injectable({
  providedIn: 'root'
})
export class ForecastTemplateFrontendService {

  // Cache
  private contentCache = new CacheUtils.LruCache<ForecastTemplate[]>(5);

  // Entities
  public availableTags: ForecastTemplateTagDTO[] = [];

  // Flags
  private createTagOpen = false;

  constructor(
    private store: Store,
    private dialog: DialogService,
    private backend: ForecastTemplateBackendService
  ) { }

  public create(dto: CreateForecastTemplateDTO) {
    return this.backend.create(dto);
  }

  public adminUpdate(model: ForecastTemplate) {
    const dto = new AdminUpdateForecastTemplateDTO();
    dto.Title = model.Title;
    dto.Description = model.Description;
    dto.AuthorEmail = model.AuthorInfo.AuthorEmail;
    dto.Tags = model.Tags;
    dto.ForecastTemplateId = model.ForecastTemplateId;
    return this.backend.adminUpdate(dto);
  }

  /**
   * Opens the create new template tag dialog
   * @returns
   */
  public createNewTag() {
    if (this.createTagOpen) { return; }
    this.createTagOpen = true;
    const ref = this.dialog.openCreateTemplateTagDialog();
    return ref.toPromise()
      .then((newTag: ForecastTemplateTagDTO) => {
        this.createTagOpen = false;
        this.getTags(true);
        return newTag;
      });
  }

  public update(model: ForecastTemplate) {
    return this.backend.update({
      ForecastTemplateId: model.ForecastTemplateId,
      Description: model.Description,
      IsPublic: true,
      Tags: model.Tags,
      Title: model.Title,
    });
  }

  public getAll(skip?: number, take?: number) {
    return this.backend.getAll(skip, take);
  }

  public getTopUsed(take?: number) {
    if (this.contentCache.has(String(take))) {
      return Promise.resolve(this.contentCache.get(String(take)));
    }

    return this.backend.getTopUsed(take)
      .then(templates => {
        this.contentCache.put(String(take), templates);
        return templates;
      });
  }

  public getTags(force: boolean = false) {
    if (!force && this.availableTags.length) {
      return Promise.resolve(this.availableTags);
    }
    return this.backend.getTags()
      .then(tags => this.availableTags = tags);
  }

  public createTag(newTag: ForecastTemplateTagDTO) {
    return this.backend.createTag(newTag);
  }

  public getAuthors() {
    return this.backend.getAuthors();
  }

  public use(templateId: string, dto: UseForecastTemplateDTO) {
    return this.backend.use(templateId, dto)
      .then(forecast => {
        this.store.dispatch(new GetForecastSuccessAction(forecast));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return forecast;
      });
  }
}
