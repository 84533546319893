import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlotValueDTO, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { RemoteDataRequestDTO } from '@core/store/providers/dtos/remote-data-request-dto';
import { ProviderService } from '@core/store/providers/provider.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { AggregationMethod } from '@modules/lang/language-files/aggregation';
import { EmptyModelName } from '@modules/lang/types/model-name';
import { Store } from '@ngxs/store';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgOptions, StrokeSetups } from '@shared/components/line-graph/alg.options';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { DateUtils } from '@shared/utils/date.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { DbConfigurationDTO, DbVariableMetaDTO } from '../../sql-database.dtos';
import { DatabaseService } from '../../sql-database.service';

export type ImportPreviewTabs = 'Import' | 'PreviewData';

export class ImportVariableSqlDatabaseData {
  RemoteSourceId: string;
  Config: DbConfigurationDTO;
  Meta: DbVariableMetaDTO;
  forecastVersion: ForecastVersionModel;
}

@Component({
  selector: 'indicio-import-variable-database-dialog',
  templateUrl: 'import-variable-sql-database.dialog.html',
})
export class ImportVariableSqlDatabaseDialogComponent {

  faSave = faSave as IconProp;
  public nameMustChange: boolean = false;
  public nameConflict: boolean = false;

  public loading: boolean = false;
  public title: string = 'hello';
  public config: DbConfigurationDTO = null;
  public metaValues: string[] = [];

  /* Name fields */
  public editName: boolean = false;
  public name: string = null;

  //#region variable meta
  aggregationMethods: AggregationMethod[];
  aggregationMethodId: string = 'average';
  //#endregion

  public forecastVersion: ForecastVersionModel = null;
  public forecast: ForecastModel = null;
  public remoteVariable: RemoteDataRequestDTO = null;

  public view: ImportPreviewTabs = 'Import';
  public preventSpam: boolean = false;
  public dataLoader: boolean = false;
  public historicLine: ALGLineModel = null;
  public graphOptions: AlgOptions.Options;

  public firstDate: Date = null;
  public lastDate: Date = null;
  public periodicity: string = null;
  public dataEmpty: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ImportVariableSqlDatabaseDialogComponent>,
    private dbService: DatabaseService,
    private providerService: ProviderService,
    private client: ClientFrontendService,
    private envService: EnvironmentService,
    private sourcevariableService: SourceVariableFrontendService,
    private store: Store,
    private forecastService: ForecastFrontendService,
    private forecastVariableService: ForecastVariableFrontendService,
    private fvarMapper: ForecastVariableMapper,
    private status: StatusService,
    @Inject(MAT_DIALOG_DATA) public data: ImportVariableSqlDatabaseData
  ) {
    this.config = data.Config;
    this.metaValues = data.Meta.MetaValues;
    this.title = data.Meta.Name.substring(0, 50);
    this.name = data.Meta.Name;
    this.aggregationMethods = this.envService.AggregationMethods;
    this.forecastVersion = data.forecastVersion;
    this.getVariable();
    this.setGraphOpts();
    const fVersPromise = this.forecastService.getOrFetchForecast(this.forecastVersion.ForecastId);
    Promise.all([fVersPromise])
      .then(([v]) => { this.forecast = v; })
      .catch(err => { this.status.setMessage(err, 'Error', true); })
      .finally(() => { });
  }

  public showTippy(text: string) {
    return text.length >= 24;
  }

  public getVariable() {
    this.loading = true;
    this.dataLoader = !this.remoteVariable;
    return this.dbService.getVariable(this.data.RemoteSourceId, this.data.Meta)
      .then(resp => {
        this.remoteVariable = resp;
        this.name = resp.Name;
        this.periodicity = StringUtils.capitalizeFirstLetter(DateUtils.convertToLyFormat(resp.Periodicity));
        this.checkNameErrors();
        this.updateGraphData();
      })
      .catch((err) => {
        this.status.setError(err);
        this.dialogRef.close(null);
      })
      .finally(() => {
        this.loading = false;
        this.dataLoader = false;
      });
  }

  private updateGraphData() {
    const values: SimplePlotValue[] = JSON.parse(this.remoteVariable.Extras['data']).filter(x => x.Value !== null)
      .map(v => ({ D: DateUtils.newDate(v.Date), V: v.Value, m: DateUtils.newMoment(v.Date) }));

    let valsToShow = [];
    if (!values.length) {
      this.dataEmpty = true;
    } else {
      const sortedArray = values.sort((a, b) => a.D.getTime() - b.D.getTime());
      valsToShow = sortedArray.slice(-180) as PlotValueDTO[];
      this.firstDate = sortedArray[0].D;
      this.lastDate = sortedArray[sortedArray.length - 1].D;
      this.dataEmpty = false;
    }

    this.historicLine = {
      Active: true,
      IsHistoric: true,
      Color: 'white',
      Id: 'Value',
      Name: 'Value',
      Segments: [],
      Stroke: StrokeSetups.SolidLine,
      Type: 'Data vendor',
      Values: valsToShow,
      modelName: (() => { const x = EmptyModelName(); x.Display = 'Value'; x.Value = 'Value'; return x; })()
    };

    this.graphOptions.dates = this.historicLine.Values.map(v => v.m);
  }

  public inputIsOk() {
    return !this.nameMustChange && !this.nameConflict;
  }

  public checkNameErrors() {
    this.nameMustChange = this.name.length < 2 || this.name.length > 127;

    this.nameConflict = this.sourcevariableService.sourceVariables.map(x => x.Name).includes(this.name);
  }

  public async import() {
    if (!this.inputIsOk()) {
      this.status.setMessage('Name must be changed.', 'Error', true);
      return;
    }

    this.loading = true;
    if (!this.remoteVariable) {
      await this.getVariable();
    }

    this.remoteVariable.Name = this.name;
    this.providerService.addVariableFromDatasourceV2(this.client.activeCompanyId, this.remoteVariable)
      .then(sourceVariable => {
        const validAgg = this.envService.getValidAggregationMethods(sourceVariable, this.forecastVersion.Periodicity)
          .some(x => x.Value === this.aggregationMethodId);
        sourceVariable._tmpAggregationMethod = this.aggregationMethodId;
        this.dialogRef.close();

        if (!validAgg) {
          setTimeout(() => this.status.setMessage('Variable created successfully, but chosen aggregation method is invalid', 'Warning', true), 10);
          return this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [sourceVariable.SourceVariableId]));
        }

        this.status.setMessage('Variable added successfully', 'Success', !validAgg);
        const model = this.fvarMapper.mapFromSourceVariableModel(sourceVariable, this.forecastVersion.ForecastVersionId);
        this.forecastVariableService.createForecastVariable(model)
          .catch(err => this.status.setError(err));
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => { this.loading = false; });
  }

  public changeView(newView: ImportPreviewTabs) {
    this.view = newView;
    this.onViewChange();
  }

  private setGraphOpts() {
    this.graphOptions = AlgOptions.CreateOptions({
      noDataText: 'No data found',
      inModal: true,
      dontShowCIHover: true,
      axisConfig: {
        yAxisPosition: 'floating'
      },
      menuConfig: {
        showMenu: false,
      },
      isPercent: false,
      forceOriginalData: true,
      dates: [],
      showOnlyHistoric: true
    });
  }

  private async onViewChange() {
    switch (this.view) {
      case 'PreviewData': {
        if (!this.remoteVariable && !this.preventSpam) {
          this.getVariable();
          this.preventSpam = true;
        }
      }
      case 'Import':
      default:
    }
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }
}
