import { PlotValue } from '@core/entities/dtos/plot-value-dto';
import { Periodicity } from '@modules/lang/types/periodicity';
import { AlgInteractions } from '@shared/components/line-graph/alg-models/alg-interactions';
import { ALGLineModel, AlgModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { ALGTypes } from '@shared/components/line-graph/alg-types';
import { DateFormatPipe } from '@shared/modules/pipes';
import { DateUtils } from '../../date.utils';
import { ExcelBaseGenerator } from './excel-base-generator';
import { ExcelFile, ExcelRow, ExcelSheet } from './excel.entities';

const HISTORIC_VALUE_COLOR = '#eee';

export class AlgExcelGeneratorData {
  ForecastName: string;
  Interactions: AlgInteractions;
  Periodicity: Periodicity;
  DatePipe: DateFormatPipe;
  PastSteps: number;
  // Add data from other graphs
  ExtraGraphData?: ExtraGraphData[];
}

export class ExtraGraphData {
  Name: string;
  Interactions: AlgInteractions;
}

export class AlgExcelGenerator extends ExcelBaseGenerator {
  private data: AlgExcelGeneratorData;
  private mainLine: ALGLineModel;
  private file: ExcelFile;
  private transform: string;

  constructor(data: AlgExcelGeneratorData) {
    super();
    this.data = data;
  }

  public generate(): ExcelFile {
    const interactions = this.data.Interactions;
    const periodicity = this.data.Periodicity;
    const forecastName = this.data.ForecastName;
    const currentDate = DateUtils.newMoment().format('YYYY-MM-DD');
    const transform = ALGTypes.getTransformName(interactions.data.config.algTransform, periodicity);
    this.transform = transform;
    // Setup file
    this.file = new ExcelFile();
    const file = this.file;
    file.Type = interactions.data.graphType;
    file.FileName = `${forecastName}_${currentDate}-${file.Type}${(transform === 'None' ? '' : '-' + transform.replace(' ', '_').toLocaleLowerCase())}`;
    file.IsPercentage = interactions.IsPercent;

    // Setup main sheet
    this.mainLine = interactions.ActiveLines.find(x => x.IsHistoric);
    const mainLine = this.mainLine;
    const mainSheet = new ExcelSheet();
    mainSheet.Name = mainLine.Name;
    mainSheet.Title = `${this.mainLine.Name}${this.transform === 'None' ? '' : ' with ' + this.transform + ' transformation'}`;

    const colorRow = this.createColorRow();
    mainSheet.addRow(colorRow);

    // Setup historic dates and data
    this.addHistoricDates(mainSheet);
    this.addHistoricData(mainSheet);

    this.file.Sheets.push(mainSheet);

    if (interactions.ActiveModels.filter(x => !!x.modelName)) {
      this.addModelData(mainSheet);
    }

    if (file.Type === 'variable') {
      return file;
    }

    // Setup CI sheet
    if (interactions.data.graphData.ShowCIIntervals) {
      this.addCISheet();
    }

    if (interactions.FittedData.length) {
      this.addFittedSheet();
    }

    if (interactions.PastForecasts.length) {
      this.addPastForecastSheet();
    }

    // Handle 'other' lines, start with scenarios
    const allOthers = interactions.ActiveLines.filter(x => !x.IsHistoric);
    const scenarios = allOthers.filter(x => x.Type === 'Scenarios');
    const allButScenarios = allOthers.filter(x => x.Type !== 'Scenarios');

    if (scenarios.length) {
      this.addScenarioSheet(scenarios);
    }

    if (allButScenarios.length) {
      this.addOtherSheets(allButScenarios);
    }

    // Handle extra data
    if (this.data.ExtraGraphData) {
      this.data.ExtraGraphData.forEach(extraData => {
        const extraSheet = new ExcelSheet();
        extraSheet.Name = `Indicator ${extraData.Name}`;
        extraSheet.Title = extraSheet.Name;
        extraSheet.addRow(this.createColorRow());
        const historicData = extraData.Interactions.ActiveLines[0].Values;
        this.addHistoricDates(extraSheet, historicData);
        this.addHistoricData(extraSheet, true, extraData.Name, historicData);
        this.addModelData(extraSheet, historicData, extraData.Interactions.ActiveModels);
        this.file.Sheets.push(extraSheet);
      });
    }

    return file;
  }

  private addHistoricDates(sheet: ExcelSheet, values = this.mainLine.Values) {
    const header = new ExcelRow();
    header.id = 'header';
    header.Cells = [this.createCell('Dates')];
    sheet.addRow(header);

    values.forEach(value => {
      this.findOrCreateRow(sheet, value);
    });
  }


  private addHistoricData(sheet: ExcelSheet, includeHeader = true, titleName = this.mainLine.Name, values = this.mainLine.Values) {
    // Set header
    if (includeHeader) { sheet.columnHeaderRow.Cells.push(this.createCell(titleName)); }
    // Set data
    values.forEach(value => {
      const row = this.findOrCreateRow(sheet, value);
      const columnIndex = sheet.columnHeaderRow.Cells.findIndex(x => x.Value === titleName);
      // Add empty cells till we reach the current column
      while (row.Cells.length < columnIndex) { row.Cells.splice(1, 0, this.createCell(null)); }
      row.Cells[columnIndex] = this.createCell(this.getPlotValue(value, 'V', this.file.IsPercentage), HISTORIC_VALUE_COLOR);

    });
  }

  private addModelData(sheet: ExcelSheet, historicValues: PlotValue[] = this.mainLine.Values, models: AlgModel[] = this.data.Interactions.ActiveModels) {
    models.filter(x => !!x.modelName).forEach(model => {
      // Set colors
      const colorRow = sheet.colorRow;
      colorRow.Cells.push(this.createCell('', model.Color));
      // Set header
      sheet.columnHeaderRow.Cells.push(this.createCell(model.modelName.Display));
      // Set values
      const titleIndex = sheet.columnHeaderRow.Cells.findIndex(x => x.Value === model.modelName.Display);
      // Add last historic value
      this.addLastHistoricValue(sheet, historicValues);
      this.addUniqueRowsFromSegments(model.Segments, sheet, titleIndex);
    });
  }

  private addCISheet() {
    const sheet = new ExcelSheet();
    sheet.Name = `CI ${this.mainLine.Name}`;
    sheet.Title = `Confidence intervals for ${this.mainLine.Name}`;
    this.addHistoricDates(sheet);
    // Setup header
    const cells = [
      this.createCell('Max 95%'),
      this.createCell('Max 75%'),
      this.createCell('Max 50%'),
      this.createCell('Historic'),
      this.createCell('Forecast'),
      this.createCell('Min 50%'),
      this.createCell('Min 75%'),
      this.createCell('Min 95%')
    ];
    // Add header
    sheet.columnHeaderRow.Cells.push(...cells);

    this.addCIData(sheet);
    this.file.Sheets.push(sheet);
  }

  private addCIData(sheet: ExcelSheet) {
    const ciHistoricTitle = 'Historic';
    const titleIndex = sheet.columnHeaderRow.Cells.findIndex(x => x.Value === ciHistoricTitle);
    // Add values from variable
    this.addHistoricData(sheet, false, ciHistoricTitle);
    // Pad historic values with nulls before and after
    sheet.rowsWithoutDates.forEach(row => {
      while (row.Cells.length - 1 < titleIndex) { row.Cells.splice(1, 0, this.createCell(null)); }
      row.Cells.push(...Array(4).fill(null).map(x => this.createCell(x)));
    });
    const modelData = this.data.Interactions.ActiveModels[0].Values.filter(x => x.IF);
    // Add model values
    modelData.forEach(value => {
      const row = this.findOrCreateRow(sheet, value);
      // Remove blank cells
      row.Cells.splice(1, 1);
      const cells = [
        this.createCell(value.A95),
        this.createCell(value.A75),
        this.createCell(value.A50),
        this.createCell(null),
        this.createCell(value.V),
        this.createCell(value.I50),
        this.createCell(value.I75),
        this.createCell(value.I95)
      ];
      row.Cells.push(...cells);
    });
  }

  private addFittedSheet() {
    const sheet = new ExcelSheet();
    sheet.Name = 'Fitted values'; //  ${fitted.Model.modelName.Value}
    sheet.Title = 'Fitted values for each model'; // ${fitted.Model.modelName.Display}`
    const colorRow = this.createColorRow(1);
    sheet.addRow(colorRow);
    this.addHistoricDates(sheet);
    this.addFittedData(sheet);

    //Remove rows without data
    this.removeEmptyRows(sheet);

    this.file.Sheets.push(sheet);
  }

  private addFittedData(sheet: ExcelSheet) {
    const titleRow = sheet.columnHeaderRow;
    this.data.Interactions.FittedData.forEach(fitted => {
      // Add model name to titlerow
      titleRow.Cells.push(this.createCell(`${fitted.Model.modelName.Display}`));
      // Add model-specific color
      const colorRow = sheet.colorRow;
      colorRow.Cells.push(this.createCell('null', fitted.Model.Color));
      // Add values from variable
      const column = titleRow.Cells.findIndex(x => x.Value === fitted.Model.modelName.Display);
      this.addUniqueRowsFromSegments(fitted.Values, sheet, column, 'F');
    });
  }

  private addPastForecastSheet() {
    const sheet = new ExcelSheet();
    sheet.Name = `Past forecasts ${this.mainLine.Name}`;
    sheet.Title = `Past forecasts for ${this.mainLine.Name}${this.transform === 'None' ? '' : ' with ' + this.transform + ' transformation'}`;

    // Add empty colors for model columns if we have past steps over 0
    if (this.data.PastSteps > 0) {
      const addS = this.data.PastSteps === 1 ? '' : 's';
      sheet.addRow({
        Height: null,
        m: null,
        id: 'subtitle',
        Cells: [this.createCell(''), this.createCell(''), this.createCell(`${this.data.PastSteps} ${this.data.Periodicity.Value}${addS} ahead`)]
      });
      sheet.addRow(this.createColorRow());
    }

    this.addHistoricDates(sheet);
    this.addHistoricData(sheet);

    // Add stepped past forecasts for one model
    if (this.data.PastSteps === 0) {
      this.addSteppedPastForecastData(sheet);
    } else {
      this.addPastForecastStep(sheet);
    }
    this.file.Sheets.push(sheet);
  }

  private addSteppedPastForecastData(sheet: ExcelSheet) {
    const minDate = this.mainLine.Values[0].m;
    // Setup data
    const forecasts = this.data.Interactions.PastForecasts.filter(x => x.Forecasts.map(y => y.Values).flatten()[0].m.isSameOrAfter(minDate));
    forecasts.forEach((forecast, _) => {
      const values = forecast.Forecasts.map(fc => fc.Values).flatten();
      if (values.length === 0) { return; }
      const titleDate = values.slice(0, 1)[0];
      // Setup header
      const titleString = this.data.DatePipe.transform(titleDate.D, this.data.Periodicity.Value);
      if (!!!sheet.columnHeaderRow.Cells.find(x => x.Value === titleString)) {
        sheet.columnHeaderRow.Cells.push(this.createCell(titleString));
      }
      // Setup values
      const titleIndex = sheet.columnHeaderRow.Cells.findIndex(x => x.Value === titleString);

      // Add historic value
      const historicValue = this.mainLine.Values.find(x => x.m.isSame(titleDate.m));
      const historicRow = this.findOrCreateRow(sheet, historicValue);
      while (historicRow.Cells.length < titleIndex) { historicRow.Cells.push(this.createCell(null)); }
      historicRow.Cells[titleIndex] = this.createCell(this.getPlotValue(historicValue, 'V', this.file.IsPercentage), HISTORIC_VALUE_COLOR);

      values.slice(1).forEach((value, _) => {
        const row = this.findOrCreateRow(sheet, value);
        while (row.Cells.length < titleIndex) { row.Cells.push(this.createCell(null)); }
        row.Cells[titleIndex] = this.createCell(this.getPlotValue(value, 'V', this.file.IsPercentage));
      });
    });
  }

  private addPastForecastStep(sheet: ExcelSheet) {
    const pastForecasts = this.data.Interactions.data.graphData.PastForecasts;
    pastForecasts.forEach(forecast => {
      const title = forecast.Model.modelName.Display;
      // Set colors
      const colorRow = sheet.colorRow;
      colorRow.Cells.push(this.createCell('', forecast.Color));
      // Set header
      const titleIndex = sheet.columnHeaderRow.Cells.push(this.createCell(title)) - 1;
      // Add last historic value
      this.addLastHistoricValue(sheet);
      this.addValuesFromSegments(forecast.Segments[0], sheet, titleIndex);
    });
  }

  private addScenarioSheet(scenarios: ALGLineModel[]) {
    const sheet = new ExcelSheet();
    sheet.Name = 'Scenarios';
    sheet.Title = 'Scenario models';
    // Add empty colors for dates and historic columns
    const colorRow = this.createColorRow(2);
    sheet.addRow(colorRow);
    this.addHistoricDates(sheet);
    this.addHistoricData(sheet);
    scenarios.forEach(model => {
      // Add last historic value
      this.addLastHistoricValue(sheet);
      // Set colors
      colorRow.Cells.push(this.createCell('', model.Color));
      // Set header
      sheet.columnHeaderRow.Cells.push(this.createCell(model.modelName.Display));
      // Set values
      const titleIndex = sheet.columnHeaderRow.Cells.findIndex(x => x.Value === model.modelName.Display);
      this.addUniqueRowsFromSegments(model.Segments, sheet, titleIndex);
    });
    this.file.Sheets.push(sheet);
  }

  private addOtherSheets(lines: ALGLineModel[]) {
    lines.forEach(line => {
      const sheet = new ExcelSheet();
      sheet.Title = `${line.Type} ${line.Name}${this.transform === 'None' ? '' : ' with tranformation ' + this.transform}`;
      sheet.Name = `${line.Type} ${line.Name}`;
      const colorRow = this.createColorRow(1);
      colorRow.Cells.push(this.createCell('', line.Color));
      sheet.addRow(colorRow);
      sheet.addRow(Object.assign(new ExcelRow, { id: 'header', Cells: [this.createCell('Dates'), this.createCell(`${line.Type} ${line.Name}`)] }));
      // Setup header
      this.addOtherData(sheet, line);
      this.file.Sheets.push(sheet);
    });
  }

  private addOtherData(sheet: ExcelSheet, line: ALGLineModel) {
    const column = sheet.columnHeaderRow.Cells.findIndex(x => x.Value === `${line.Type} ${line.Name}`);
    // Add values from variable
    this.addHistoricData(sheet, false, `${line.Type} ${line.Name}`);
    this.addUniqueRowsFromSegments(line.Segments, sheet, column);
  }

  private findOrCreateRow(sheet: ExcelSheet, value: PlotValue) {
    let row = sheet.getRowByDate(value.m);
    if (!row) {
      row = new ExcelRow();
      row.m = value.m;
      row.Cells = [this.createCell(this.data.DatePipe.transform(row.m, this.data.Periodicity.Value)), this.createCell(null)];
      sheet.addRow(row);
    }
    return row;
  }

  private addLastHistoricValue(sheet: ExcelSheet, historicValues: PlotValue[] = this.mainLine.Values) {
    const lastHistoricValue = historicValues[historicValues.length - 1];
    const lastHistoricRow = this.findOrCreateRow(sheet, lastHistoricValue);
    lastHistoricRow.Cells.push(this.createCell(this.getPlotValue(lastHistoricValue, 'V', this.file.IsPercentage), HISTORIC_VALUE_COLOR));
  }

  private addUniqueRowsFromSegments(segments: ALGTypes.LineSegment[], sheet: ExcelSheet, titleIndex: number, valueKey: 'V' | 'F' = 'V') {
    const rows: ExcelRow[] = [];
    segments.forEach(segment => {
      // Filter out values that are not forecasted or those that haven't set IF and hasn't any fitted value
      segment.Values.filter(x => x.IF || x.IF == null && !!x.F).forEach(value => {
        if (rows.find(x => x.m.isSame(value.m, 'day'))) { return; }
        const row = this.findOrCreateRow(sheet, value);
        row.Cells[titleIndex] = this.createCell(this.getPlotValue(value, valueKey, this.file.IsPercentage));
        rows.push(row);
      });
    });
  }

  private addValuesFromSegments(segments: ALGTypes.LineSegment[], sheet: ExcelSheet, titleIndex: number, valueKey: 'V' | 'F' = 'V') {
    segments.forEach(segment => {
      segment.Values.forEach(value => {
        const row = this.findOrCreateRow(sheet, value);
        row.Cells[titleIndex] = this.createCell(this.getPlotValue(value, valueKey, this.file.IsPercentage));
      });
    });
  }

  private createColorRow(defaultCells = 2) {
    const colorRow = new ExcelRow();
    colorRow.Height = 3;
    colorRow.id = 'colorRow';
    colorRow.Cells.push(...Array.from(new Array(defaultCells)).map(_ => this.createCell('')));
    return colorRow;
  }

  private removeEmptyRows(sheet: ExcelSheet) {
    // Get start index, under header
    const headerRowIndex = sheet.Rows.findIndex(x => x.id === 'header') + 1;
    // Remove rows with all null values after date column
    sheet.Rows = sheet.Rows.filter((row, index) => {
      if (index < headerRowIndex) { return true; }
      return !row.Cells.slice(1).every(x => x.Value == null || x.Value === 'null');
    });
  }
}
