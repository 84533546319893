
export class TaskHistoryModalOpts {
  taskId: string = null;
}

export class OpenTaskHistoryModalComponent {
  public static readonly type = '[MODAL] Open task history modal';
  data: TaskHistoryModalOpts;
  constructor(
    public taskId?: string
  ) { this.data = { taskId }; }
}
