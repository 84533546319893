import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { ClientMetaDTO } from '@modules/admin/entities/client-meta.dto';
import { AdminBackendBaseService } from '@modules/admin/services/admin-http-base.service';
import { Store } from '@ngxs/store';
import { SecurityConfigDTO, UserSecurityInfoDTO } from './entities/security.dtos';


@Injectable({
  providedIn: 'root',
})
export class AdminUserService extends AdminBackendBaseService {

  constructor(
    http: HttpService,
    store: Store,
  ) {
    super(http, store);
  }

  public getSecurityInfo(clientId: string) {
    return this.http.post<UserSecurityInfoDTO[]>('users/security', [clientId], 'id')
      .then(resp => resp.body[0]);
  }

  public saveSecurityInfo(dto: SecurityConfigDTO) {
    return this.http.put<UserSecurityInfoDTO>('users/security', dto, 'id')
      .then(resp => resp.body);
  }

  public disable2Fa(dto: ClientMetaDTO) {
    return this.http.post(`users/${dto.Profile.ClientId}/2fa/disable`, null, 'id');
  }
}
