import { DateValue } from '@core/interfaces/if-datevalue-filter';

export namespace CSVConstants {
  export enum DecimalSettings {
    Period = '.',
    Comma = ','
  }
  export type Separator = ',' | ';' | '\t';
  export const SeparatorDefinitions: { value: Separator, display: string, title: string; }[] = [
    { value: ';', display: 'Semicolon', title: ';' },
    { value: ',', display: 'Comma', title: ',' },
    { value: '\t', display: 'Tab', title: '\\t' }
  ];
  export class CSVFile {
    FileName: string;
    Name: string;
    Values: DateValue[];
    Content: string;
  }
}
