import { PlotValue, PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ALGTypes } from '@shared/components/line-graph/alg-types';
import { StatisticsUtils } from '../statistic.utils';


export namespace TransformationUtils {

  export enum ChangeType {
    'percent' = 'percent',
    'absolute' = 'absolute'
  }

  export enum RollingType {
    'Average' = 'Average',
    'Sum' = 'Sum'
  }

  export type FieldType = 'V' | 'F' | 'WF';

  export function getChange(values = [], field: FieldType = 'V', type: ChangeType = ChangeType.percent): any[] {
    values = values.filter(x => x[field] != null);
    let prev, curr;
    prev = values[0][field];
    for (let i = 1; i < values.length; i++) {
      curr = values[i][field];
      const toSet = type === ChangeType.percent
        ? (curr - prev) / prev
        : (curr - prev);
      values[i][field] = (curr === 0 && prev === 0) ? 0 : Number.isNaN(toSet) || !Number.isFinite(toSet) ? null : toSet;
      prev = curr;
    }
    values[0][field] = 0;
    return values;
  }

  export function calcChange(values: number[], frequency: number, isPercent: boolean = false) {
    const resLen = values.length - frequency;
    const change: number[] = new Array(values.length - frequency);
    for (let i = 0; i < resLen; i++) {
      let toSet = values[i + frequency] - values[i];
      if (isPercent) toSet = toSet / values[i];
      if (Number.isNaN(toSet) || !Number.isFinite(toSet)) {
        change[i] = null;
      } else {
        change[i] = toSet;
      }
    }
    return change;
  }

  export function getChangeYearly(
    values: PlotValue[],
    field: FieldType = 'V',
    frequency: number,
    type: ChangeType = ChangeType.percent,
  ): any[] {
    const valuesToTransform = values.filter(x => !(x.IF && x[field] == null)).map(x => x[field]);
    const trans = TransformationUtils.calcChange(valuesToTransform, frequency, type === ChangeType.percent);
    values.splice(0, frequency);
    const result = trans.map((v, i) => {
      values[i][field] = v;
      return values[i];
    });
    return result;
  }

  export function getRollingXY(values: PlotValue[], field: FieldType, points: number, type: RollingType): PlotValue[] {
    const valuesToTransform = values.filter(x => !(x.IF && x[field] == null)).map(x => x[field]);
    const result: PlotValue[] = [];
    const count = valuesToTransform.length;
    if (count < points - 1) {
      console.error('[getRollingXYAverage]: Value count below ' + (points - 1));
      return values;
    }

    const average = type === RollingType.Average;

    for (let i = points - 1; i < count; i++) {
      const c = values[i];
      c[field] = StatisticsUtils.getLastXRolling(valuesToTransform, i, points, average);
      result.push(c);
    }

    return result;
  }

  export function getLog(values: PlotValue[], field: FieldType): PlotValue[] {
    const valuesToTransform = values.filter(x => !(x.IF && x[field] == null)).map(x => x[field]);
    const result: PlotValue[] = [];

    for (let i = 0; i < valuesToTransform.length; i++) {
      const c = values[i];
      c[field] = Math.log(valuesToTransform[i]);
      result.push(c);
    }

    return result;
  }

  export function transformValues(
    transformation: string,
    values: PlotValue[],
    field: FieldType,
    frequency: number
  ): PlotValueDTO[] {
    let modelVals = values.filter(x => x[field] != null);
    if (modelVals.length <= 0) { return; }
    let transformedFittedValue = null;

    switch (transformation) {
      case ALGTypes.Transform.original:
        return;

      case ALGTypes.Transform.roc:
        transformedFittedValue = getChange(modelVals, field, ChangeType.percent);
        transformedFittedValue.shift();
        return transformedFittedValue;

      case ALGTypes.Transform.rocy:
        transformedFittedValue = getChangeYearly(modelVals, field, frequency, ChangeType.percent);
        transformedFittedValue.shift();
        return transformedFittedValue;

      case ALGTypes.Transform.diff:
        transformedFittedValue = getChange(modelVals, field, ChangeType.absolute);
        transformedFittedValue.shift();
        return transformedFittedValue;

      case ALGTypes.Transform.diffy:
        transformedFittedValue = getChangeYearly(modelVals, field, frequency, ChangeType.absolute);
        transformedFittedValue.shift();
        return transformedFittedValue;

      case ALGTypes.Transform.rolling12m:
        transformedFittedValue = getRollingXY(modelVals, field, 12, RollingType.Average);
        return transformedFittedValue;


      case ALGTypes.Transform.rolling12mSum:
        transformedFittedValue = getRollingXY(modelVals, field, 12, RollingType.Sum);
        return transformedFittedValue;

      case ALGTypes.Transform.rolling4q:
        transformedFittedValue = getRollingXY(modelVals, field, 4, RollingType.Average);
        return transformedFittedValue;

      case ALGTypes.Transform.rolling4qSum:
        transformedFittedValue = getRollingXY(modelVals, field, 4, RollingType.Sum);
        return transformedFittedValue;
    }
    return;
  }
}
