import { ReportEntryModel, ReportModel } from '@core/store/reports/models/report.model';

export class FiscalYearEntryMeta {
  Name: string;
  RollingStart: moment.Moment;
  RollingEnd: moment.Moment;

  CurrentValue: number;
  LastValue: number;
  BeforeLastValue: number;
  HistoricCurrentVSPYFYPercentage: number;

  Rolling12: number;
  PYFYTD: number;
  PYFYTDMissingValues: boolean;
  CurrentTD: number;
  CurrentTDMissingValues: boolean;
  VSPYFYTD: number;

  CurrentForecastedValue: number;
  CurrentTotalValue: number;
  ForecastedVSPYFYPercent: number;

  CurrentContainsFCast: boolean;
  LastContainsFCast: boolean;
  BeforeLastContainsFCast: boolean;
  PYFYTDContainsFCast: boolean;

  constructor(public Base: ReportEntryModel) {
    this.Name = Base.Name;
  }
}

export class FiscalYearReport {
  Name: string;
  Entries: FiscalYearEntryMeta[] = [];
  /** Historic */
  TotalCurrentValues: number;
  TotalLastValues: number;
  TotalBeforeLastValues: number;
  TotalHistoricCurrentVSPYFYPercent: number;
  /** Actual */
  TotalRolling12: number;
  TotalPYFYTD: number;
  TotalCurrentTD: number;
  TotalVSPYFYTD: number;
  /** Forecasted */
  TotalCurrentForecast: number;
  TotalCurrent: number;
  TotalForecastedVSPYFYPercent: number;
  /** Fiscal years */
  BeforeLastName: string;
  LastName: string;
  CurrentName: string;
  CurrentYear: number;

  constructor(public Base: ReportModel) {
    this.Name = Base.Name;
  }
}
