<div class="options-menu-container"
     [id]="_id">
  <div data-test-id='show-more-options'
       class="options-menu-container__toggler"
       data-options-menu-toggler
       (click)="toggle($event)"
       [ngClass]="{ invert: invertToggler, 'options-menu-container__toggler--no-hover': nohover }">
    <i data-options-menu-toggler
       [class]="icon"
       [ngStyle]="{ fontSize: iconsize }"></i>
  </div>

  <ng-template *ngIf="open"
               indicioTeleport="outside">
    <div class="options-menu-container__content options-menu-container__content--open"
         [ngStyle]="{ top: top, left: left }">
      <!-- <div class="hidden-filler"
           [ngStyle]="{ top: fillerTop }"
           *ngIf="filler"></div> -->
      <ng-content></ng-content>
    </div>
  </ng-template>
</div>
