import { ChangeDetectorRef, Component } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { HistoricEventFrontendService } from '@core/store/historic-event/historic-event.frontend.service';
import { HistoricBaseEventModel, HistoricEventModel } from '@core/store/historic-event/models/base/historic-event.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OpenEditHistoricEventModal } from '../edit/edit-historic-event-modal.action';
import { CreateHEventModalOpts } from './create-historic-event-modal.options';


@Component({
  selector: 'indicio-create-historic-event-modal',
  templateUrl: './create-historic-event-modal.component.html',
  styleUrls: ['../create-edit-historic-event-modal.component.less']
})
export class CreateHistoricEventModalComponent extends ModalModelComponent {

  forecastVersion: ForecastVersionModel;

  event: HistoricBaseEventModel = null;
  pending: boolean;

  dateSetupComplete = false;
  eventEndedDate: any;
  eventOccuredDate: any;
  openEvents = [];
  baseOpen = true;

  disableText: string;

  impactOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  public get isChanged() {
    return this.modalState.isChanged('event', this.event);
  }

  public startDateChanged(subEvent, $event) {
    subEvent.Date = $event;
  }

  public endDateChanged(subEvent, $event) {
    subEvent.EndDate = $event;
  }

  public get disableNext() {
    let disable = false;
    const before = this.disableText;
    this.disableText = null;
    this.event.Events.forEach(ev => {
      if (!ev.Name) { disable = true; this.disableText = 'Missing name on an event'; }
      if (!ev.Date) { disable = true; this.disableText = 'Missing start date on an event'; }
      if (!ev.EffectType) { disable = true; this.disableText = 'Missing type on an event'; }
    });
    if (disable === false) {
      if (this.event.Events.length === 0) {
        this.disableText = 'Missing events';
        disable = true;
      }
    }
    if (before !== this.disableText) {
      this.cd.detectChanges();
    }
    return disable;
  }

  constructor(
    protected store: Store,
    private cd: ChangeDetectorRef,
    private status: StatusService,
    public appearance: AppearanceService,
    public envService: EnvironmentService,
    private eventService: HistoricEventFrontendService,
    private clientSerive: ClientFrontendService,
  ) {
    super();
  }

  public setOptions(options: CreateHEventModalOpts) {
    this.forecastVersion = options.forecastVersion;
    this.event = new HistoricBaseEventModel();
    this.event.CompanyId = this.clientSerive.activeCompany.CompanyId;
    this.isLoading = false;
    this.modalState.setState('event', this.event);
  }


  public addEvent() {
    const event = new HistoricEventModel();
    event.open = true;
    this.event.open = false;
    this.event.Events.forEach(x => this.toggleEventDisplay(x, false));
    this.event.Events.push(event);
  }

  public removeEvent(event: HistoricEventModel) {
    const idx = this.event.Events.findIndex(x => x.getModelId() === event.getModelId());
    if (idx > -1) {
      this.event.Events.splice(idx, 1);
    }
  }

  public toggleEventDisplay(event: HistoricEventModel, force?: boolean) {
    if (force == null) {
      this.event.open = false;
    }
    event.open = force != null ? force : !event.open;
  }

  public closeAllSubEvents() {
    this.event.Events.forEach(x => this.toggleEventDisplay(x, false));
  }

  public selectEffectType(event: HistoricEventModel, type: string) {
    event.EffectType = type;
  }

  public create() {
    this.pending = true;
    this.eventService.createBaseEvent(this.event)
      .then(created => {
        this.close();
        this.pending = false;
        if (this.forecastVersion) {
          this.store.dispatch(new OpenEditHistoricEventModal(this.forecastVersion.ForecastVersionId, created.HistoricBaseEventId, null));
          setTimeout(() => {
            this.status.setMessage('Event created', 'Success', true);
          }, 0);
        } else {
          this.status.setMessage('Event created', 'Success');
        }
      })
      .catch(error => {
        this.status.setError(error, true);
        this.pending = false;
      });
  }

  public get sortedEvents() {
    const sorted = this.event.Events.sort((a, b) => {
      if (!a.Date || !b.Date) { return 0; }
      return a.Date.isSame(b.Date)
        ? 0
        : a.Date.isAfter(b.Date)
          ? 1
          : -1;
    });
    return sorted;
  }
}


