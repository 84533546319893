import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewContainerRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'indicio-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class DataTableComponent implements AfterViewInit {

  @Input() columnTemplate: string;
  @Input() columnCount: number;

  public styleString = '';

  constructor(
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.setup();
  }

  public setup() {
    const baseElem = this.viewContainer.element.nativeElement;
    const rowElems = baseElem.querySelectorAll('.indicio-datatable__body [row]');
    if (!rowElems) { return; }
    const childrenCount = [...rowElems].map(x => x.children.length || 0);
    if (!childrenCount.length) { return; }
    this.columnCount = this.columnCount || Math.max(...childrenCount);

    if (!!this.columnTemplate) { this.styleString += `--column-template: ${this.columnTemplate};`; }
    if (!!this.columnCount) { this.styleString += `--column-count: ${this.columnCount};`; }

    this.cd.detectChanges();
  }
}
