import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CloseMainMenuAction, NavigateToHome, NavigateToReport } from '@core/actions/navigation.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { FileGeneratorService } from '@core/services/file-generator.service';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { HierarchyReconciliationDTO } from '@core/store/hierarchical-forecast/entities/dtos/hierarchy-reconciliation.dto';
import { HierarchicalForecastFrontendService } from '@core/store/hierarchical-forecast/services/hierachical-forecast.frontend.service';
import { ReportModel } from '@core/store/reports/models/report.model';
import { ReportFrontendService } from '@core/store/reports/report.service';
import { Store } from '@ngxs/store';
import { OpenConfirmModal } from '@shared/modals/confirm/confirm-modal.actions';
import { OpenCreateReportModal } from '@shared/modals/reports/create/create-report-modal.actions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'indicio-reports-drawer',
  templateUrl: './reports-drawer.component.html',
  styleUrls: ['./reports-drawer.component.less'],
})
export class ReportsDrawerComponent implements OnChanges {
  private sub: Subscription = new Subscription();

  @Input() visible: boolean;
  @Output() closeNavigation = new EventEmitter();

  loadingReports = false;
  userCanCreateReports: boolean;
  scrollPosition: number;

  public get reports$() { return this.service.reports$(); }
  public get hierarchies() { return this.hierarchyService.hierarchies; }
  public get reconciliations() { return this.hierarchies.map(x => x.Reconciliations).flatten(); }

  constructor(
    public service: ReportFrontendService,
    public navService: NavigationService,
    private store: Store,
    private authService: AuthFrontendService,
    private status: StatusService,
    private actions: ActionService,
    private fileUtilService: FileGeneratorService,
    private hierarchyService: HierarchicalForecastFrontendService,
  ) {
    this.setup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.visible.currentValue === true) {
      this.loadReports();
    }
  }

  public downloadReport(report: ReportModel) {
    this.service.getOrFetchReport(report.ReportId)
      .then(() => this.fileUtilService.downloadReport(report));
  }

  private loadReports() {
    this.loadingReports = true;

    const promises = [
      this.service.fetchAllReportMetas(),
      this.hierarchyService.getAllInCompany()
    ];
    Promise.all(promises).then(([_reports, _hierarchies]) => {
      this.loadingReports = false;
    });
  }

  setup() {
    this.userCanCreateReports = true;
    this.authService.hasPermission('CAN_CREATE_REPORT');
    this.sub.add(this.actions.dispatched(CloseMainMenuAction).subscribe(() => {
      this.closeNavigation.emit(true);
    }));
  }

  public removeReport(report: ReportModel) {
    const proceed = () => {
      this.service.deleteReport(report)
        .then(() => {
          if (this.navService.isOnReport(report.ReportId)) {
            this.store.dispatch(new NavigateToHome);
          }
          this.status.setMessage('Report removed', 'Success');
        })
        .catch(error => {
          this.status.setError(error);
        });
    };
    const message = 'Are you sure you want to delete this Report?';
    const title = 'Remove report';
    const decline = 'No';
    const confirm = 'Yes, delete it';
    const negativeEffect = true;
    this.store.dispatch(new OpenConfirmModal(proceed, message, title, decline, confirm, negativeEffect));
  }

  public openReport(report: ReportModel) {
    this.store.dispatch(new NavigateToReport(report.ReportId));
    this.closeNavigation.emit(true);
  }

  public createReport() {
    this.store.dispatch(new OpenCreateReportModal);
  }

  public scroll($event) {
    this.scrollPosition = $event.target.scrollTop;
  }

  public toggleReportMenu(report: ReportModel, { open, event }) {
    report.openMenu = open;
  }

  // Reconsiliation stuff
  public openReconciliation(reconciliation: HierarchyReconciliationDTO) {
    this.navService.navToUrlWithQuery(`/reports/${reconciliation.HierarchyId}`, { reconciliation: true });
    this.closeNavigation.emit(true);
  }

  public toggleReconciliationMenu(reconciliation: HierarchyReconciliationDTO, { open, event }) {
    reconciliation.openMenu = open;
  }

  public removeReconciliation(reconciliation: HierarchyReconciliationDTO) {
    this.hierarchyService.removeReconciliation(reconciliation.HierarchyId, reconciliation.HierarchyReconciliationId)
      .then(action => {
        if (!action) { return; }
        this.hierarchyService.getAllInCompany()
          .then(() => {
            if (this.navService.isOnReport(reconciliation.HierarchyId)) {
              this.store.dispatch(new NavigateToHome);
            }
            this.status.setMessage('Report removed', 'Success');
          });
      })
      .catch(error => {
        this.status.setError(error);
      });
  }
}
