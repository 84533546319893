import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'countryName' })
export class CountryNamePipe implements PipeTransform {

  constructor(
    private environmentService: EnvironmentService
  ) { }

  transform(value: any): string {
    const country = this.environmentService.getCountry(value);
    return country ? this.environmentService.getCountry(value).Display : '';
  }
}
