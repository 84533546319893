import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { StateUtils } from '@shared/utils/state.utils';
import * as d3 from 'd3';
import { DragBarOptions } from './dragbar.options';

type HANDLES = 'start' | 'end';

@Component({
  selector: 'indicio-dragbar',
  templateUrl: './dragbar.component.html',
  styleUrls: ['./dragbar.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class DragBarComponent implements AfterViewInit, OnChanges {
  _id = `dragbar-${Math.random().toString(36).substring(7)}`;

  @Input() options: DragBarOptions<Date>;
  @Input() parent: HTMLElement;

  minRange = 0;
  maxRange = 490;

  startIndex: number;
  endIndex: number;

  modalState = new StateUtils.StateHelper();

  @Output() changedEvent = new EventEmitter();
  @Output() startEmitEvent = new EventEmitter<number>();
  @Output() endEmitEvent = new EventEmitter<number>();

  constructor(
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.options?.firstChange) {
      this.updateValues();
    }
  }

  public ngAfterViewInit() {
    this.updateValues();
  }

  private updateValues() {
    this.setStartAndEndCircleIndexes();
    if (this.options.Values.length) {
      this.initRange();
    }
  }

  public initRange() {
    const self = this;
    const barHeight = 7;

    // Options
    const data = this.options.Values;
    const handleOpacity = 0.8;
    const handleColor = '#fff';
    const barColor = this.options.BarColor || '#ffffff';
    let width;

    if (this.parent) {
      width = this.parent?.clientWidth;
    } else {
      width = 550;
    }

    this.maxRange = width - 30;

    if (document.querySelector(`#${this._id} svg`)) {
      document.querySelector(`#${this._id} svg`).remove();
    }

    // Create SVG
    const svgElem = d3
      .select(`#${this._id}`)
      .append('svg')
      .style('margin-left', '-25px')
      .attr('width', width + 11)
      .attr('height', 30);

    // Do we need a scale?
    // 490 is witdh as 550 - 2*25 - 2*5, see above
    let scale;

    switch (this.options.ScaleType) {
      case 'Time':
      default:
        scale = d3.scaleTime()
          .domain([data[0], data.last()])
          .range([this.minRange + 25, this.maxRange - 25])
          .clamp(true);
        break;
    }


    const dragScale = this.getDragScale();

    const group = svgElem.append('g')
      .attr('class', 'color-group')
      .attr('transform', 'translate(30,8)');

    group.append('rect')
      .attr('x', dragScale(0))
      .attr('width', this.maxRange)
      .attr('height', barHeight)
      .attr('stroke', 0)
      .attr('class', 'drag-bar')
      .style('fill', barColor);

    // Add Slider group, with margins
    const sliderGroup = svgElem.append('g')
      .attr('class', 'slider-group')
      .attr('transform', 'translate(30,8)');

    // Slider rect, to be overlayed with color areas
    sliderGroup.append('rect')
      .attr('x', 0).attr('y', 0)   // upper left corner in current coordinate system
      .attr('rx', 5).attr('ry', 5) // rounded corners
      .style('fill', 'none').style('stroke', 'grey')
      .attr('width', this.maxRange).attr('height', barHeight);

    if (!this.options.HideXAxis) {
      // Define axis group, just below the slider
      const axisGroup = svgElem.append('g')
        .attr('class', 'axis-group')
        .attr('width', 100)
        .attr('transform', 'translate(30,12)');

      // Should it be 'live' with updates?
      const xAxis = d3.axisBottom(scale)
        .tickFormat(d3.timeFormat('%Y-%m-%d')) // https://github.com/d3/d3-time-format
        .ticks(this.options.Values.length)
        .tickValues([this.options.Values[0], this.options.Values.last()]);

      axisGroup.call(xAxis);
    }

    const startBoll = sliderGroup.append('circle')
      .attr('cx', dragScale(this.startIndex))
      .attr('cy', barHeight / 2)
      .attr('r', 10)
      .attr('fill-opacity', handleOpacity)
      .attr('class', 'cursor-pointer')
      .style('fill', handleColor)
      .style('stroke', 'black');

    const endBoll = sliderGroup.append('circle')
      .attr('cx', dragScale(this.endIndex))
      .attr('cy', barHeight / 2)
      .attr('r', 10)
      .attr('fill-opacity', handleOpacity)
      .attr('class', 'cursor-pointer')
      .style('fill', handleColor)
      .style('stroke', 'black');

    enableDrag(startBoll, 'start');
    enableDrag(endBoll, 'end');

    function enableDrag(e: d3.Selection<any, any, any, any>, handle: HANDLES) {
      e.call(d3.drag()
        .on('start', function () { dragstarted(this); })
        .on('start.interrupt', function () { e.interrupt(); })
        .on('drag', (event, d) => { dragged(this, handle, event); })
        .on('end', function () { dragended(this); }));
    }

    function dragstarted(e: Element) {
      d3.select(e).attr('fill-opacity', 100);
    }

    function dragged(e: Element, handle: HANDLES, event: any) {
      const prop = 'cx';
      const s = dragScale;
      const value = Math.floor(dragScale.invert(event.x));
      let pos = getPosition(event);


      if (handle === 'start') {
        if (pos > s(self.endIndex - 5)) { return; }
        if (value === self.startIndex) {
          pos = s(value);
        }
        self.startIndex = value;
        self.startEmitEvent.emit(value);
      }

      if (handle === 'end') {
        if (pos < s(self.startIndex + 5)) { return; }
        if (value === self.options.Values.length) {
          pos = s(value);
        }
        self.endIndex = value;
        self.endEmitEvent.emit(value);
      }

      if (handle === 'start' || handle === 'end') {

      }

      d3.select(e).attr(prop, pos);
    }

    function dragended(_e: Element) {
      // d3.select(e).attr('fill-opacity', handleOpacity);
    }

    function getPosition(event: any) {
      const x = Math.floor(event.x);
      if (x < self.minRange) {
        return self.minRange;
      } else if (x > self.maxRange) {
        return self.maxRange;
      }
      return x;
    }
  }

  private getDragScale() {
    return d3.scaleLinear()
      .domain([0, this.options.Values.length - 1])
      .range([this.minRange, this.maxRange])
      .clamp(true);
  }


  private setStartAndEndCircleIndexes() {
    if (this.options.StartIndex == null && this.options.StartValue) {
      const startIndex = this.options.Values.findIndex(x => x.getTime() === this.options.StartValue.getTime());
      this.startIndex = startIndex > -1 ? startIndex : 0;
    } else {
      this.startIndex = this.options.StartIndex || 0;
    }

    if (this.options.EndIndex == null && this.options.EndValue) {
      const endIndex = this.options.Values.findIndex(x => x.getTime() === this.options.EndValue.getTime());
      this.endIndex = endIndex > -1 ? endIndex : this.options.Values.length - 1;
    } else {
      this.endIndex = this.options.EndIndex || this.options.Values.length - 1;
    }
  }
}
