import { Component, Input } from '@angular/core';

@Component({
  selector: 'indicio-influence-stars',
  template: `
      <i *ngFor="let i of [].constructor(4 - greyCount)"
        class="ion-star yellow"></i>
      <i *ngFor="let i of [].constructor(greyCount)"
        class="ion-star grey"></i>
    `
})
export class InfluenceStarsComponent {

  @Input() influence: number;

  public get greyCount() { return Math.min(4, 4 - Math.ceil(this.influence * 4)); }
}
