
import { Injectable } from '@angular/core';
import { ClearStateAction } from '@core/store/auth/auth.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CompanyActions } from '../company/company.actions';
import { HistoricEventActions } from './historic-event.actions';
import { HistoricBaseEventModel } from './models/base/historic-event.model';


class HistoricEventStateModel {
  historicEvents: HistoricBaseEventModel[];
}

@State<HistoricEventStateModel>({
  name: 'historicEvents',
  defaults: {
    historicEvents: []
  }
})
@Injectable()
export class HistoricEventState {

  @Selector()
  static historicEvents(state: HistoricEventStateModel): HistoricBaseEventModel[] { return state.historicEvents; }

  @Action(HistoricEventActions.GetSuccess)
  getHistoricEventsSuccess(ctx: StateContext<HistoricEventStateModel>, action: HistoricEventActions.GetSuccess) {
    const events = ctx.getState().historicEvents;
    events.addOrUpdate(action.event);
    ctx.patchState({ historicEvents: [...events] });
  }

  @Action(HistoricEventActions.GetAllSuccess)
  getHistoricEventSuccess(ctx: StateContext<HistoricEventStateModel>, action: HistoricEventActions.GetAllSuccess) {
    ctx.patchState({ historicEvents: [...action.events] });
  }

  @Action(HistoricEventActions.DeleteSuccess)
  deleteHistoricEventSuccess(ctx: StateContext<HistoricEventStateModel>, action: HistoricEventActions.DeleteSuccess) {
    const events = ctx.getState().historicEvents;
    events.removeById(action.eventbaseId);
    ctx.patchState({ historicEvents: [...events] });
  }

  @Action(HistoricEventActions.ClearCompanyEvents)
  removedHistoricEventsInCompany(ctx: StateContext<HistoricEventStateModel>, action: HistoricEventActions.ClearCompanyEvents) {
    const event = ctx.getState().historicEvents;
    const keep = event.filter(x => x.CompanyId !== action.companyId);
    ctx.patchState({ historicEvents: [...keep] });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<HistoricEventStateModel>) {
    ctx.patchState({ historicEvents: [] });
  }
}
