<div class="row accordion-container">
  <div class="accordion-header cursor-pointer"
       [ngClass]="{ 'accordion-header--dark': darkHeader }"
       (click)="runToggleEvent()">
    <div class="col-xs-10">
      <ng-content select="[accordion-header]"></ng-content>
    </div>
    <div class="col-xs-2 text-right">
      <i [ngClass]="open ? 'ion-minus-circled' : 'ion-plus-circled'"></i>
    </div>
  </div>
  <div class="accordion-content"
       [ngClass]="{ 'accordion-content--open': open }"
       [ngStyle]="{ backgroundColor: bgColor, padding: contentPadding }">
    <ng-content></ng-content>
  </div>
</div>
