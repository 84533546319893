import { Directive, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef } from '@angular/core';
import { TeleportService } from '@core/services/frontend/teleport.service';

@Directive({
  selector: 'ng-template[indicioTeleport]'
})
export class TeleportDirective implements OnChanges, OnDestroy {

  constructor(private teleport: TeleportService, private template: TemplateRef<any>) { }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('indicioTeleport') target: string;

  ngOnChanges(changes: SimpleChanges) {

    const target = changes.target;
    if (!target) { return; }

    setTimeout(() => {
      // Clears the previous target, if any
      if (target.previousValue) {
        this.teleport.activate({ [target.previousValue]: null });
      }

      // Teleports the template to the new target portal
      if (target.currentValue) {
        this.teleport.activate({ [target.currentValue]: this.template });
      }
    });
  }

  ngOnDestroy() {
    // Clears the portal on destroy whenever the target is defined
    setTimeout(() => {
      if (this.target) {
        this.teleport.activate({ [this.target]: null });
      }
    });
  }
}
