import { Injectable } from '@angular/core';
import { AutoTaskAction } from '@core/store/auto-task/auto-task.actions';
import { EAutoTaskState } from '@modules/lang/language-files/automations';
import { Store } from '@ngxs/store';
import { PusherActionDTO } from '../dtos/pusher-action-dto';

export type PusherAutoTaskAction =
  'set-auto-task-state' |
  'add-auto-task-message'
  ;

@Injectable({
  providedIn: 'root'
})
export class PusherAutoTaskBehaviourService {

  constructor(
    private store: Store,
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {
    switch (dto.Action as PusherAutoTaskAction) {
      case 'set-auto-task-state':
        const state = dto.Obj.State as EAutoTaskState;
        return this.store.dispatch(new AutoTaskAction.SetState(dto.AutoTaskId, dto.CompanyId, state));

      case 'add-auto-task-message':
        return this.store.dispatch(new AutoTaskAction.AddMessage(dto.AutoTaskId, dto.CompanyId, dto.Str));

      default:
        console.error(`Missing behaviour for action: (${dto.Action})`);
        return;
    }
  }
}
