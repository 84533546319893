<indicio-modal-base [child]="this"
                    [modalTitle]="'Data download'"
                    bodyExtraClass="overflow-auto"
                    [loading]="isLoading">

  <ng-container *ngIf="empty">
    <div class="row">
      <div class="col-xs-12 center-text">
        Forecast is empty, nothing to download!
      </div>
    </div>
    <div class="row extra-margin-top">
      <div class="col-xs-6">
      </div>
      <div class="col-xs-6">
        <button class="btn right"
                (click)="close()">
          Close
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="forecastVersion && variables && !empty">
    <indicio-modal-option-row optionTitle="Variables"
                              optionSubtitle="Choose what variables to download"
                              textWidth="8"
                              optionsWidth="4">
      <mat-form-field style="margin-top: 10px;">
        <mat-select multiple
                    [(value)]="selectedVariables">
          <mat-option [value]="variable.ForecastVariableId"
                      *ngFor="let variable of variables">{{ variable.Name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Values"
                              optionSubtitle="What values do you want"
                              textWidth="8"
                              optionsWidth="4">
      <mat-form-field style="margin-top: 10px;">
        <mat-select [(value)]="valueOption">
          <mat-option [value]="option.Value"
                      *ngFor="let option of valueOptions">{{ option.Display }}</mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Separator"
                              optionSubtitle="Choose CSV separator"
                              textWidth="8"
                              optionsWidth="4">
      <mat-form-field style="margin-top: 10px;">
        <mat-select [(value)]="separator">
          <mat-option [value]="option.value"
                      *ngFor="let option of separatorOptions">{{ option.display }}</mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Decimal"
                              optionSubtitle="Choose value decimal character"
                              textWidth="8"
                              optionsWidth="4">
      <mat-form-field style="margin-top: 10px;">
        <mat-select [value]="decimalLabel"
                    (selectionChange)="selectDecimal($event.value)">
          <mat-option [value]="option"
                      *ngFor="let option of decimalSettings">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Choose rounding method"
                              optionSubtitle="Use decimal places or significant figures"
                              textWidth="8"
                              optionsWidth="4">
      <div style="padding-top: 15px;">
        <mat-radio-group [(ngModel)]="useSignificant">
          <mat-radio-button [value]="false">Decimal places</mat-radio-button>
          <mat-radio-button [value]="true"
                            style="margin-top: 5px;">Significant figures</mat-radio-button>
        </mat-radio-group>
      </div>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="{{ useSignificant ? 'Significant figures' : 'Decimal places' }}"
                              optionSubtitle="Choose how many {{ useSignificant ? 'significant figures' : 'decimal places' }} you want"
                              textWidth="8"
                              optionsWidth="4">
      <mat-form-field style="margin-top: 10px;">
        <mat-select [(value)]="decimalPoints">
          <mat-option [value]="option"
                      *ngFor="let option of decimalPrecisions">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Delivery"
                              optionSubtitle="Everything in one file or separate files in a zip-archive"
                              textWidth="8"
                              optionsWidth="4">
      <mat-form-field style="margin-top: 10px;">
        <mat-select [(value)]="useZip">
          <mat-option [value]="false">Everything in same file</mat-option>
          <mat-option [value]="true">Separate files in a zip-archive</mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>


    <div class="row">
      <div class="col-xs-6">
        <button class="btn outline blue left"
                type="button"
                (click)="close()">Close</button>
      </div>
      <div class="col-xs-6">
        <button class="btn right"
                (click)="download()">
          <i class="ion-load-c spinner"
             *ngIf="pending"></i>
          Download
        </button>
      </div>
    </div>
  </ng-container>
</indicio-modal-base>
