import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '@shared/utils/string.utils';


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'numberInput' })
export class NumberPipe implements PipeTransform {

  constructor() { }

  transform(value: any) {
    return this.parseValue(value).newValue;
  }

  public parseValue(inputValue: any) {
    // Trim the string, only allow numbers and number-related characters.
    const isNegative = inputValue.startsWith('-');
    let value = this.sanitize(inputValue);
    value = StringUtils.removeAllButFirst(value, '.');
    value = isNegative ? `-${value}` : value;
    const isEmpty = value === '';
    let newValue = isEmpty ? null : +value;
    if ((isNaN(newValue) || newValue == null) && !isEmpty) { return; }
    return { value, newValue };
  }


  private sanitize(input: string) {
    let value = input.replace(/[^0-9.,]+/g, '');
    return value.replace(/,/g, '.');
  }

}
