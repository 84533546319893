<div class="stepped-progress-container">
  <div class="text-row"
       style="--columns: {{ steps.length }}">
    <div class="text-column"
         [class]="{ active: i === step, progressed: i < step }"
         *ngFor="let text of steps; let i = index">
      <div style="position: relative; display: inline-block">
        {{ text }}
        <div class="click-box cursor-pointer"
             (click)="setStep(i)"></div>
      </div>

    </div>
  </div>
  <div class="progress-row"
       style="--columns: {{ steps.length }}">
    <div class="progress-column"
         [class]="{ active: i === step, progressed: i < step }"
         *ngFor="let text of steps; let i = index">
      <div class="circle"></div>
    </div>
  </div>
</div>
