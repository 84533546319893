import { Component } from '@angular/core';
import { NavigateToForecast } from '@core/actions/navigation.actions';
import { HttpStatusCodes } from '@core/constants/http-status-codes.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { FVarDialogViewIndex } from '@dialogs/variables/forecast-variable/info/forecast-variable-info.dialog';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { UpdatedDataInfoModalOpts } from './updated-data-info-modal.options';



@Component({
  selector: 'indicio-updated-data-info-modal',
  templateUrl: './updated-data-info-modal.component.html',
  styleUrls: ['./updated-data-info-modal.component.less']
})
export class UpdatedDataInfoModalComponent extends ModalModelComponent {

  opts: UpdatedDataInfoModalOpts;
  fVar: ForecastVariableModel;
  sourceVar: SourceVariableModel;
  sourceVersion: SourceVariableVersionModel;
  parentRemoved = false;
  sourceVersionNotFound = false;

  public parentForecast: ForecastModel;

  constructor(
    protected store: Store,
    public envService: EnvironmentService,
    private status: StatusService,
    private fVarService: ForecastVariableFrontendService,
    private sourceService: SourceVariableFrontendService,
    private forecastService: ForecastFrontendService,
    private varDialog: VariableDialogService
  ) {
    super();
    this.isLoading = true;
  }

  private setupSubscriptions() {
  }

  public setOptions(options: UpdatedDataInfoModalOpts) {
    this.opts = options;

    const fvPromise = this.fVarService.getOrFetchById(options.fVersionId, options.fVarId);
    const smPromise = this.sourceService.getOrFetchSourceVariable(options.sourceVarId);

    this.setupSubscriptions();

    Promise.all([fvPromise, smPromise])
      .then(([c, p]) => {
        this.fVar = c;
        this.sourceVar = p;
        this.sourceVersion = p.Versions.find(x => x.SourceVariableMetaId === options.sourceMetaId);
        if (this.sourceVersion) {
          this.sourceVersionNotFound = true;
        } else {
          // TODO: Send error to backend to notify admin/dev team. Issue #1896
        }

        return this.forecastService.getOrFetchForecast(this.sourceVersion.ParentForecastId)
          .catch(err => {
            if (err.status === HttpStatusCodes.NOT_FOUND) {
              this.parentRemoved = true;
            }
            throw err;
          });
      })
      .then(fc => {
        this.parentForecast = fc;
      })
      .catch(err => {
        if (!this.parentRemoved) {
          this.status.setError(err, true);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public openFVarModal() {
    const forecast = this.forecastService.forecastByVersionId(this.opts.fVersionId);
    this.close();
    this.varDialog.openForecastVariableInfo({
      forecastId: forecast.ForecastId,
      forecastVariableId: this.opts.fVarId,
      forecastVersionId: this.opts.fVersionId,
      view: FVarDialogViewIndex.general
    });
  }

  public navigateToForecast() {
    this.store.dispatch(new NavigateToForecast(this.parentForecast.ForecastId));
    this.close();
  }
}
