<h1 mat-dialog-title
    style="margin: 0">Create forecast template</h1>

<div mat-dialog-content>
  <div class="create-template-container">
    <ng-container *ngIf="initialized; else loaderTemplate">
      <div class="ctc-options">
        <ng-container *ngTemplateOutlet="createOptionsTemplate"></ng-container>
      </div>
      <div class="ctc-info">
        <ng-container *ngTemplateOutlet="createInfoTemplate"></ng-container>
      </div>
    </ng-container>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
  <div [indicioTippy]="!canCreate ? 'One or more problems exists.' : null">
    <button mat-flat-button
            data-test-id="forecast-template-create-btn"
            color="primary"
            [disabled]="!canCreate || saveInProgress"
            (click)="save()">
      <i *ngIf="saveInProgress"
         class="ion-load-c spinner"></i>
      <span *ngIf="!saveInProgress">Create</span>
    </button>
  </div>
</div>

<ng-template #createInfoTemplate>
  <div class="row"
       style="margin-bottom: 10px;">
    <div class="col-xs-12 bold"
         style="margin-left: 1px">Forecast information</div>
  </div>
  <ng-container *ngIf="selectedFVersion">
    <div class="data-table compact-white">
      <div class="data-table__body"
           style="max-height: 500px;">
        <ng-container
                      *ngTemplateOutlet="infoTableRow, context: { title: 'Main variable:', value: selectedFVersion.ForecastVariable.Name, useTippy: true }">
        </ng-container>
        <ng-container
                      *ngTemplateOutlet="infoTableRow, context: { title: 'Indicator count:', value: selectedFVersion.IndicatorVariables.length }">
        </ng-container>
        <ng-container
                      *ngTemplateOutlet="infoTableRow, context: { title: 'Historic events:', value: selectedFVersion.ImportedHistoricBaseEvents.length }">
        </ng-container>
        <ng-container
                      *ngTemplateOutlet="infoTableRow, context: { title: 'Forecasted points:', value: selectedFVersion.Horizon }">
        </ng-container>
        <ng-container
                      *ngTemplateOutlet="infoTableRow, context: { title: 'Periodicity:', value: selectedFVersion.periodicity.Display }">
        </ng-container>
        <div class="body__row">
          <div class="column"
               style="width: 125px; padding: 10px 0 0 0 !important">Data providers</div>
          <div class="column crop-string"
               style="width: 155px; padding: 10px 0 0 0 !important">
            <ul>
              <li *ngFor="let p of usedProviders">{{ p }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="cannotBeUsedReasons.length">
      <hr>
      <div class="row"
           style="margin-bottom: 10px;">
        <div class="col-xs-12 bold"
             style="margin-left: 1px">Problems <i style="font-size: 20px; margin-left: 5px"
             class="ion-alert-circled red"></i></div>
      </div>
      <ul style="list-style-type: disc;">
        <li style="margin-left: 15px"
            *ngFor="let r of cannotBeUsedReasons">{{ r }}</li>
      </ul>
    </ng-container>
  </ng-container>

</ng-template>

<ng-template #infoTableRow
             let-title=title
             let-value=value
             let-useTippy=useTippy>
  <div class="body__row body__row--max-height-30">
    <div class="column"
         style="width: 125px; padding: 10px 0 0 0 !important">{{ title }}</div>
    <div class="column crop-string"
         style="width: 155px; padding: 10px 0 0 0 !important">
      <span [indicioTippy]="useTippy ? value : null">
        {{ value }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #createOptionsTemplate>
  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Title">
    <mat-form-field>
      <input data-test-id="forecast-template-title-input"
             type="text"
             class="material"
             matInput
             autocomplete="off"
             [ngModel]="createDto.Title"
             (ngModelChange)="createDto.Title = $event"
             name="Title"
             min="4"
             required>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Description">
    <mat-form-field>
      <textarea matInput
                data-test-id="forecast-template-description-input"
                required
                [ngModel]="createDto.Description"
                (ngModelChange)="createDto.Description = $event"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMaxRows="1">
      </textarea>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Tags">
    <indicio-searchable-multiselect-dropdown [required]="true"
                                             [values]="availableTags"
                                             [selectedValue]="selectedTags"
                                             optionDisplay="Name"
                                             optionValue="ForecastTemplateTagId"
                                             (valueChangeEvent)="onSelectTag($event)">
    </indicio-searchable-multiselect-dropdown>

    <span class="pointer dotted-underline"
          data-test-id="forecast-template-create-tag-btn"
          (click)="createNewTag()">Create new tag</span>

    <span *ngIf="!profileService.profile.Bio?.length"
          class="pointer dotted-underline"
          style="margin-top: 10px; display: block"
          (click)="addProfileBio()">Add profile bio</span>
  </indicio-modal-option-row>
</ng-template>

<ng-template #loaderTemplate>
  <i class="ion-load-c spinner"></i>
  Loading information
</ng-template>
