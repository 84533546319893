import { Component } from '@angular/core';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AlphaVantageService } from '@core/store/providers/alphavantage/alphavantage.service';
import { ProviderService } from '@core/store/providers/provider.service';
import { LanguageService } from '@modules/lang/language.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OpenModifyAPIKeyModal } from '../../modify-api-key/modify-api-key.actions';
import { OpenAlphaCryptoModal } from '../crypto/alpha-crypto-modal.actions';
import { OpenAlphaForexModal } from '../forex/alpha-forex-modal.action';
import { OpenAlphaStockModal } from '../stock/alpha-stock-modal.actions';
import { OpenAlphaMainModal } from './alpha-main-modal.actions';
import { AlphaMainModalOpts } from './alpha-main-modal.options';


@Component({
  selector: 'indicio-alpha-main-modal',
  templateUrl: './alpha-main-modal.component.html',
  styleUrls: ['./alpha-main-modal.component.less']
})
export class AlphaMainModalComponent extends ModalModelComponent {

  forecastVersion: ForecastVersionModel;

  constructor(
    public store: Store,
    public envService: EnvironmentService,
    public alphaService: AlphaVantageService,
    private langService: LanguageService,
    private forecastService: ForecastFrontendService,
    private status: StatusService,
    public providerService: ProviderService,
    private clientService: ClientFrontendService
  ) {
    super();
  }

  public setOptions(options: AlphaMainModalOpts) {
    this.initApiKey();
    this.forecastService.getOrFetchForecastVersion(options.forecastVersionId)
      .then(fv => this.forecastVersion = fv)
      .catch(err => this.status.setError(err, true))
      .finally(() => this.isLoading = false);
  }

  initApiKey() {
    const source = this.clientService.client.findDataSource('alphavantage', true);
    if (!source || !source.ApiKey) {
      setTimeout(() => {
        this.close();
        this.store.dispatch(new OpenModifyAPIKeyModal(
          this.envService.getSourceType(DataProvider.alphavantage),
          this.langService.sourceTypes.find(x => x.Value === 'alphavantage')?.Extra ?? 'Contact support.',
          null,
          _key => {
            this.store.dispatch(new OpenAlphaMainModal(this.forecastVersion.ForecastVersionId));
          }, () => { },
          new RegExp('^[0-9A-Z]{16}$')));
      }, 0);

      return;
    } else {
      this.alphaService.setAPIKey(source.ApiKey);
    }
  }

  openForex() {
    this.close();
    this.store.dispatch(new OpenAlphaForexModal(this.forecastVersion));
  }

  openCrypto() {
    this.close();
    this.store.dispatch(new OpenAlphaCryptoModal(this.forecastVersion));
  }

  openStock() {
    this.close();
    this.store.dispatch(new OpenAlphaStockModal(this.forecastVersion));
  }
}


