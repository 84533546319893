import { ChangeDetectorRef, Component } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { HistoricEventActions } from '@core/store/historic-event/historic-event.actions';
import { HistoricEventFrontendService } from '@core/store/historic-event/historic-event.frontend.service';
import { HistoricBaseEventModel, HistoricEventModel, HistoricEventSerializer } from '@core/store/historic-event/models/base/historic-event.model';
import { ImportedHistoricEventCorrelationModel } from '@core/store/historic-event/models/imported/imported-historic-base-event.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { OpenEditImportedHistoricEventModal } from '@shared/modals/imported-historic-events/edit/edit-imported-historic-event-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OpenEditHistoricEventModal } from './edit-historic-event-modal.action';
import { EditHEventModalOpts } from './edit-historic-event-modal.options';


@Component({
  selector: 'indicio-historic-event-modal',
  templateUrl: './edit-historic-event-modal.component.html',
  styleUrls: ['../create-edit-historic-event-modal.component.less']
})
export class EditHistoricEventModalComponent extends ModalModelComponent {

  forecastVersion: ForecastVersionModel = null;
  event: HistoricBaseEventModel = null;

  backup: string;
  onBack: Function;
  usedCorrelations: ImportedHistoricEventCorrelationModel[];

  isNew = false;
  userCanEdit = false;
  isEditing = false;
  dateSetupComplete = false;
  eventEndedDate: any;
  eventOccuredDate: any;

  openEvents = [];
  baseOpen = true;
  alreadyImported: boolean;

  pendingImport: boolean;
  disableText: string;

  public get isChanged() {
    return this.modalState.isChanged('event', this.event);
  }

  public startDateChanged(subEvent, $event) {
    subEvent.Date = $event;
  }

  public endDateChanged(subEvent, $event) {
    subEvent.EndDate = $event;
  }

  public get disableNext() {
    let disable = false;
    const before = this.disableText;
    this.disableText = null;
    this.event.Events.forEach(ev => {
      if (!ev.Name) { disable = true; this.disableText = 'Missing name on an event'; }
      if (!ev.Date) { disable = true; this.disableText = 'Missing start date on an event'; }
      if (!ev.EffectType) { disable = true; this.disableText = 'Missing type on an event'; }
    });
    if (disable === false) {
      if (this.event.Events.length === 0) {
        this.disableText = 'Missing events';
        disable = true;
      } else {
        const sameDateWithinGroup = this.event.Events.map(x => this.checkDate(x)).some(x => x === true);
        if (sameDateWithinGroup) {
          disable = true;
          this.disableText = 'Two or more events with same date is not allowed';
        }
      }
    }
    if (before !== this.disableText) {
      this.cd.detectChanges();
    }
    return disable;
  }

  public get disableImport() {
    let disable = false;
    const before = this.disableText;
    this.disableText = null;
    if (this.alreadyImported) {
      this.disableText = 'Already imported';
      disable = true;
    }
    if (before !== this.disableText) {
      this.cd.detectChanges();
    }
    return disable;
  }

  constructor(
    protected store: Store,
    private status: StatusService,
    private cd: ChangeDetectorRef,
    public appearance: AppearanceService,
    public envService: EnvironmentService,
    private eventService: HistoricEventFrontendService,
    private clientSerive: ClientFrontendService,
    private forecastService: ForecastFrontendService
  ) {
    super();
    this.onClose = this.cancelEdit;
  }

  public setOptions(options: EditHEventModalOpts) {
    const fVersPromise = this.forecastService.getOrFetchForecastVersion(options.forecastVersionId);
    Promise.all([fVersPromise]).then(([fv]) => {
      this.forecastVersion = fv;
      this.event = this.eventService.allEvents.find(x => x.HistoricBaseEventId === options.eventId);
      this.setSavedState(this.event);
      this.onBack = options.onBack;
      this.isNew = !this.event;
      this.userCanEdit = this.clientSerive.activeCompany.hasPermission('CAN_UPDATE_HISTORIC_EVENT') && this.event.CompanyId === this.clientSerive.activeCompany.CompanyId;
      this.isLoading = false;
      this.isEditing = true;
    });
  }

  public addEvent() {
    const event = new HistoricEventModel();
    event.open = true;
    this.event.open = false;
    this.closeAllSubEvents();
    this.event.Events.push(event);
  }

  public removeEvent(event: HistoricEventModel) {
    const idx = this.event.Events.findIndex(x => x.getModelId() === event.getModelId());
    if (event.HistoricEventId) {
      this.eventService.deleteEvent(event, this.clientSerive.activeCompany.CompanyId, event.HistoricBaseEventId)
        .then(() => {
          this.event.Events.splice(idx, 1);
          this.status.setMessage('Event removed', 'Success', true);
        })
        .catch(e => {
          this.status.setError(e, true);
        });
    } else {
      this.event.Events.splice(idx, 1);
    }
  }

  public toggleEventDisplay(event: HistoricEventModel, force?: boolean) {
    if (force == null) {
      this.event.open = false;
    }
    event.open = force != null ? force : !event.open;
  }

  public closeAllSubEvents() {
    this.event.Events.forEach(x => this.toggleEventDisplay(x, false));
  }

  public selectEffectType(event: HistoricEventModel, type: string) {
    event.EffectType = type;
  }

  public update() {
    this.pending = true;
    return this.eventService.updateBaseEvent(this.event)
      .then(updated => {
        this.setSavedState(updated);
        this.status.setMessage('Event saved', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
      })
      .finally(() => this.pending = false);
  }

  public import() {
    this.isLoading = true;
    this.close();
    this.store.dispatch(new OpenEditImportedHistoricEventModal(
      this.forecastVersion.ForecastVersionId,
      null,
      this.event.HistoricBaseEventId,
      () => this.store.dispatch(new OpenEditHistoricEventModal(this.forecastVersion.ForecastVersionId, this.event.HistoricBaseEventId)),
      true));
  }

  public checkDate(event: HistoricEventModel) {
    if (!event.Date) { return; }
    return this.event.Events.filter(x => x.HistoricEventId !== event.HistoricEventId && !!x.Date).some(x => x.Date.isSame(event.Date, 'day'));
  }

  private setSavedState(event) {
    this.event = event;
    this.modalState.setState('event', event);
    this.backup = HistoricEventSerializer.stringify(this.event);
  }

  public cancelEdit() {
    if (this.isChanged) {
      this.event = HistoricEventSerializer.parse(this.backup);
      this.store.dispatch(new HistoricEventActions.GetSuccess(this.event));
    }
    if (this.onBack) {
      this.onBack();
    }
  }
}
