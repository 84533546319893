<indicio-modal-base [child]="this"
                    modalTitle="{{ title }}"
                    [loading]=isLoading>

  <div *ngIf="!isLoading"
       class="row">
    <div class="row">
      <div class="col-xs-12"
           [innerHTML]="mainMessage">
      </div>
    </div>
    <div class='extra-margin-bottom extra-margin-top'>
      <ul>
        <li *ngFor="let entry of tableInfo">
          <div class="row">
            <div class="col-xs-3">{{ entry.colName }}</div>
            <div class="col-xs-1"></div>
            <div class="col-xs-8 selectable-text"
                 [ngClass]="textRight ? 'text-right' : ''">
              <span *ngIf="!isObj(entry.colInfo); else objTemp">{{ entry.colInfo }}</span>
              <ng-template #objTemp>{{ entry.colInfo | json }}</ng-template>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div *ngIf="subMessage"
         class="row">
      <div class="col-xs-12">
        {{ subMessage }}
      </div>
    </div>
    <button *ngIf="leftBtnFn"
            class="btn trigger-btn left extra-margin-top"
            (click)="close(); leftBtnFn()">
      {{ leftBtnTitle }}
    </button>
    <button [ngClass]="!negativeEffect ? 'btn-confirm' : 'btn-decline important' "
            class="btn right extra-margin-top"
            (click)="onConfirm();">
      {{ confirm }}
    </button>
  </div>
</indicio-modal-base>
