import { AvailableForecastInfoDTO } from '@core/store/reports/dtos/company-forcasts.dto';
import { ReportEntryModel, ReportModel } from '@core/store/reports/models/report.model';
import { ReportTemplateType } from '@modules/lang/language-files/reports';
import { FiscalYearEntryMeta, FiscalYearReport } from '@modules/reports/models/fiscal-year.model';
import { OverviewReportEntry } from '@modules/reports/models/overview-model';
import * as moment from 'moment';
import { DateUtils } from './date.utils';
import { ForecastUtils } from './forecast/forecast.utils';
import { ValueUtils } from './value.utils';

export namespace ReportUtils {

  /**
   * Order all forecasts that can be added to a report
   * @param dtos
   * @returns
   */
  export function orderAvailableReportForecasts(dtos: AvailableForecastInfoDTO[]) {
    const enabled = dtos.filter(x => !x.disabled);
    const disabled = dtos.filter(x => x.disabled);
    enabled.sort((a, b) => a.Name < b.Name ? -1 : 1);
    disabled.sort((a, b) => a.Name < b.Name ? -1 : 1);
    return [...enabled, ...disabled];
  }

  /**
   * Get a value from a report given a specific date
   * @param entry
   * @param date
   * @returns
   */
  export function getValueAtDate(entry: OverviewReportEntry, date?: moment.Moment) {
    const value = (date?.isSameOrAfter(entry.ForecastStartDate) ? entry.ForecastedValues : entry.HistoricValues).find(x => x.m.isSame(date, 'day'));
    return value ? value : null;
  }

  export function canAddForecast(template: ReportTemplateType, startDate: moment.Moment, entries: ReportEntryModel[], forecast: AvailableForecastInfoDTO) {
    const exists = entries.some(c => c.ForecastId === forecast.ForecastId);
    if (exists) return false;
    if (template === 'fiscal-year') {
      const validYear = DateUtils.newMoment(forecast.StartDate).isSame(startDate, 'year');
      const validResult = DateUtils.newMoment(forecast.StartDate).isSameOrAfter(startDate, 'month');
      return validYear && validResult && !forecast.MainVarIsTransformed;
    }
    return true;
  }

  export function cloneReport(report: ReportModel) {
    return Object.assign(new ReportModel, <ReportModel> {
      ...report,
      StartDate: new Date(report.StartDate),
      CreatedDate: new Date(report.CreatedDate),
      ModifiedDate: new Date(report.ModifiedDate),
      today: moment(report.today),
      reportEndDate: moment(report.reportEndDate),
      startDate: moment(report.startDate),
      currentToDate: moment(report.currentToDate),
      pyFyTDStart: moment(report.pyFyTDStart),
      pyFyTDEnd: moment(report.pyFyTDEnd),
      Settings: { ...report.Settings },
      Entries: report.Entries.map(e => cloneReportEntry(e)),
    });
  }

  export function cloneReportEntry(entry: ReportEntryModel) {
    return <ReportEntryModel> {
      ...entry,
      ForecastStartDate: new Date(entry.ForecastStartDate),
      LastForecastedDate: new Date(entry.LastForecastedDate),
      Tags: [...entry.Tags],
      HistoricValues: entry.HistoricValues.map(v => ValueUtils.copyValue(v)),
      values: entry.values.map(v => ValueUtils.copyValue(v)),
      ForecastResults: entry.ForecastResults?.map(fr => ForecastUtils.cloneForecastResult(fr)),
      lastHistoricDate: moment(entry.lastHistoricDate),
      lastForecastedDate: moment(entry.LastForecastedDate),
      currentFromDate: moment(entry.currentFromDate),
      currentToDate: moment(entry.currentToDate),
      pyFyStart: moment(entry.pyFyStart),
      pyFyToDate: moment(entry.pyFyToDate),
    };
  }

  export function getDownloadDTO(report: FiscalYearReport) {
    const dto = <FiscalYearReport> {
      ...report,
      Entries: report.Entries.map(entry => getDownloadEntryMeta(entry))
    };

    delete dto.Base;
    return dto;
  }

  export function getDownloadEntryMeta(entry: FiscalYearEntryMeta) {
    const dto = <FiscalYearEntryMeta> {
      ...entry,
      RollingStart: moment(entry.RollingStart),
      RollingEnd: moment(entry.RollingEnd)
    };
    delete dto.Base;
    return dto;
  }
}
