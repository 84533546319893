import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { IndicioHttpContext } from '@core/services/http/indicio-http-context';
import { ForecastVariableDTO } from '@core/store/forecast-variable/dtos/forecast-variable-dto';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionDateRangeDTO, FVersionObjectDTO } from '../forecast/dtos/forecast-version/forecast-version-meta-dto';
import { CanSwapForecastVariableDTO, CreateForecastVariableDTO } from './dtos/create-forecast-variable-dto';
import { VariableMissingModelSettingsDTO } from './dtos/variable-missing-settings-dto';
import { VariableOutlierSettingsDTO } from './dtos/variable-outlier-settings-dto';
import { VariableSeasonalSettingsDTO } from './dtos/variable-seasonal-settings-dto';


@Injectable({
  providedIn: 'root'
})
export class ForecastVariableBackendService {

  constructor(
    private http: HttpService,
    private mapper: ForecastVariableMapper
  ) { }

  public createForecastVariable(fVersionId: string, dto: CreateForecastVariableDTO) {
    return this.http.post<FVersionObjectDTO<ForecastVariableDTO>>(`forecast/version/${fVersionId}/create-forecast-variable`, dto, 'backend', IndicioHttpContext.GetContext({
      Info: { dto }
    }))
      .then(result => this.mapper.map(result.body.Object).then(fVariable => ({ DateInfoDTO: result.body.FVersionDatesMeta, fVariable })));
  }

  public triggerDataProcessingMany(forecastVersionId: string, variableIds: string[]) {
    return this.http.post<null>(`forecast/version/${forecastVersionId}/data-processing/trigger`, variableIds)
      .then(({ body }) => body);
  }

  public setPriorsForForecastVariables(variables: ForecastVariableModel[]) {
    const dto = variables.map(variable => this.mapper.toPriorDto(variable));
    return this.http.post<null>(`forecast/version/${variables[0].ForecastVersionId}/priors`, dto)
      .then(() => {
        dto.forEach(d => {
          const v = variables.find(x => x.ForecastVariableId === d.ForecastVariableId);
          v.BVarSettings.PriorMean = d.PriorMean;
          v.BVarSettings.PriorVariance = d.PriorVariance;
          v.BVarSettings.Transformation = v.PriorTransformation;
          v.BVarSettings.ValidVariance = v.BVarSettings.validBVarVariance(d.PriorVariance);
        });
      });
  }

  public changeSourceVariableVersion(forecastVersionId: string, forecastVariableId: string, newSourceVariableMetaId: string) {
    return this.http.post<FVersionObjectDTO<ForecastVariableDTO>>(`forecast/version/${forecastVersionId}/variables/${forecastVariableId}/meta/${newSourceVariableMetaId}/change-source-version`, null)
      .then(result => this.mapper.map(result.body.Object).then(fVariable => ({ DateInfoDTO: result.body.FVersionDatesMeta, fVariable })));
  }

  public getMainVariable(forecastVersionId: string) {
    return this.http.get<ForecastVariableDTO>(`forecast/version/${forecastVersionId}/get-main-variable`)
      .then(result => {
        const model = this.mapper.map(result.body);
        return model;
      });
  }

  public getAllForecastVariables(forecastVersionId: string) {
    return this.http.get<ForecastVariableDTO[]>(`forecast/version/${forecastVersionId}/get-complete-variables`)
      .then(result => {
        const models = result.body.map(variable => this.mapper.map(variable));
        return Promise.all(models);
      });
  }

  public updateVariableSettings(variable: ForecastVariableModel) {
    const dto = this.mapper.toUpdateSettings(variable);
    return this.http.patch<ForecastVariableDTO>(`forecast/version/${variable.ForecastVersionId}/variables/${variable.ForecastVariableId}/settings`, dto, 'backend', IndicioHttpContext.GetContext({
      Info: { dto }
    }))
      .then(res => this.mapper.map(res.body));
  }

  public updateVariableMissingValueSettings(variable: ForecastVariableModel, settings: VariableMissingModelSettingsDTO) {
    return this.http.post<null>(`forecast/version/${variable.ForecastVersionId}/variables/${variable.ForecastVariableId}/missing-value-settings`, settings)
      .then(() => {
        variable.MissingValueModel = settings.MissingValueModel;
        variable.MissingValueIndicators = JSON.parse(settings.MissingValueIndicators);
        return variable;
      });
  }

  public updateVariableSeasonalSettings(variable: ForecastVariableModel, settings: VariableSeasonalSettingsDTO) {
    return this.http.post<null>(`forecast/version/${variable.ForecastVersionId}/variables/${variable.ForecastVariableId}/seasonal-settings`, settings)
      .then(() => {
        variable.CalculateSeasonal = settings.Active;
        variable.SeasonalStrategy = settings.SeasonalStrategy;
        variable.SeasonalModel = settings.SeasonalModel;
        variable.UseSeasonalTrend = settings.UseSeasonalTrend;
        variable.SeasonalOutliers = settings.SeasonalOutliers;
        variable.SeasonalCalendarEffects = JSON.parse(settings.SeasonalCalendarEffects);
        variable.SeasonalOutlierTypes = JSON.parse(settings.SeasonalOutlierTypes);
        variable.SeasonalCalendarRegion = settings.SeasonalCalendarRegion;
        variable.SeasonalCalendarHolidays = settings.SeasonalCalendarHolidays;
        return variable;
      });
  }

  public updateVariableOutlierSettings(variable: ForecastVariableModel, settings: VariableOutlierSettingsDTO) {
    return this.http.post<null>(`forecast/version/${variable.ForecastVersionId}/variables/${variable.ForecastVariableId}/outlier-settings`, settings)
      .then(() => {
        variable.OutlierModel = settings.OutlierModel;
        variable.OutlierStrategy = settings.OutlierStrategy;
        variable.OutlierTypes = JSON.parse(settings.OutlierTypes);
        variable.CalculateOutliers = settings.Active;
        return variable;
      });
  }

  public getForecastVariableById(forecastVersionId: string, variableId: string) {
    return this.http.get<ForecastVariableDTO>(`forecast/version/${forecastVersionId}/variables/${variableId}/get-forecast-variable`, 'backend', IndicioHttpContext.GetContext({
      Timeout: 300000
    }))
      .then(result => this.mapper.map(result.body));
  }

  public deleteForecastVariable(forecastVersionId: string, variableId: string) {
    return this.http.delete<ForecastVersionDateRangeDTO>(`forecast/version/${forecastVersionId}/variables/${variableId}/delete-forecast-variable`)
      .then(({ body }) => body);
  }

  public swapVariable(forecastVersionId: string, variableId: string) {
    const path = `forecast/version/${forecastVersionId}/variables/${variableId}/swap-variable`;
    return this.http.post<ForecastVersionDateRangeDTO>(path, null, 'backend', IndicioHttpContext.GetContext({ Timeout: 300000 }))
      .then(resp => resp.body);
  }

  public canSwapVariable(forecastVersionId: string, variableId: string) {
    return this.http.post<CanSwapForecastVariableDTO>(`forecast/version/${forecastVersionId}/variables/${variableId}/can-swap-variable`, {})
      .then((res) => res.body);
  }

  public updateVariableValues(fVersionId: string, fVarId: string) {
    return this.http.get<string>(`forecast/version/${fVersionId}/variables/${fVarId}/refresh`, 'backend', IndicioHttpContext.GetContext({
      Timeout: 300000
    })).then(resp => resp.body);
  }
}


