import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { OpenIdFrontendService } from '@core/services/open-id/open-id.service';
import { MacrobondEntityInfoDTO } from '@core/store/providers/macrobond/dtos/macrobond-entityinfo.dto';
import { MacrobondService } from '@core/store/providers/macrobond/macrobond.service';

export interface MacrobondSeriesInfoDialogData {
  RemoteReferenceId: string;
}

@Component({
  selector: 'indicio-macrobond-series-info-dialog',
  templateUrl: 'macrobond-series-info.dialog.html',
})
export class MacrobondSeriesInfoDialogComponent {
  public static Id: string = 'MacrobondSeriesInfoDialogComponent';

  public entityInfo: MacrobondEntityInfoDTO = null;
  public loading = true;
  public unauthorized = false;

  constructor(
    public dialogRef: MatDialogRef<MacrobondSeriesInfoDialogComponent>,
    private mbService: MacrobondService,
    public env: EnvironmentService,
    private openidService: OpenIdFrontendService,
    @Inject(MAT_DIALOG_DATA) public data: MacrobondSeriesInfoDialogData
  ) {
    this.setup();
  }

  private setup() {
    this.mbService.getEntityInfo(this.data.RemoteReferenceId, true, true)
      .then(info => {
        this.entityInfo = info;
      })
      .catch(err => {
        if (err.status === 403) {
          this.unauthorized = true;
        }
      })
      .finally(() => this.loading = false);
  }

  public setupMb() {
    this.openidService.setupOpenIdIntegration(DataProvider.macrobondapi)
      .then(url => {
        window.location.href = url;
      });
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }
}
