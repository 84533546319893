
import { FittedValueDTO, PlotValue, PlotValueDTO, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { StatModelUtils } from '@core/store/stat-model/stat-model.utils';
import { ALGLineModel, ALGSingleSeriesModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { ValueUtils } from '@shared/utils/value.utils';
import * as d3 from 'd3';
import { ALGTypes } from './alg-types';

export namespace ALGUtils {

  export function getBoundingBox(selection: d3.Selection<any, any, any, any>): SVGRect {
    let bbox: SVGRect;
    selection.each(function () { bbox = this.getBBox(); });
    return bbox;
  }

  /**
   * Splits an array of values into line-segments, with a boolean 'isDashed' to cover gaps in the data that are null
   * @param values The array to split into segments.
   * @param field What field to read from each entry in the array. Valid: 'V', 'F', 'WF'.
   * @param transformed
   * @returns ALGTypes.LineSegment[]
   */
  export function splitLineIntoSegments(
    values: PlotValue[] | SimplePlotValue[],
    field: 'V' | 'F' | 'WF' = 'V',
    transformed: boolean = false
  ) {
    /* We need at least 2 values to construct a line segment. */
    if (values.length < 2) { return []; }

    /* Function that returns a new segment, given a start index and a boolean indicating if the segment is dashed or not. */
    const getSegmentFrom = (index: number, isDashed: boolean) => {
      const segmentFirst = values[index];
      const segmentFirstValue: number = segmentFirst[field] === null ? 0 : segmentFirst[field];
      let newSegment: ALGTypes.LineSegment = {
        Values: [],
        invalidStart: segmentFirst[field] === null,
        isDashed: isDashed
      };

      let segmentEndIndex = values.findIndex((v, i) => i > index && (isDashed ? v[field] !== null : v[field] === null));
      /* If we are currently in a non-dashed segment, the end index must be the previous none-null index */
      if (!isDashed) { segmentEndIndex--; }
      /* If we reached the end of the array, set end index so that we get all remaining values */
      if (segmentEndIndex < 0) { segmentEndIndex = values.length - 1; }
      /* Get all the values for this segment, note that slice is exclusive of the end-index, hence the + 1. */
      const segmentValues = values.slice(index, segmentEndIndex + 1);
      /* The last value of the segment is the last observed non-null value */
      const segmentLastValue = segmentValues.last()[field] === null ? segmentFirstValue : segmentValues.last()[field];
      /* Calculate the "null-step" size */
      const nullCount = Math.max(segmentValues.filter(v => v[field] === null).length, 2);
      const nullStepSize = (segmentLastValue - segmentFirstValue) / nullCount;
      /* Add the values to the segment */
      let previousValue = segmentFirstValue;
      for (let i = 0; i < segmentValues.length; i++) {
        const val = segmentValues[i];
        /* Set the value to the next 'interpolated' value or current value if it is non-null. */
        val.N = val[field] === null;
        val[field] = val.N ? previousValue + (i === 0 ? 0 : nullStepSize) : val[field];
        previousValue = val[field];
        newSegment.Values.push(val as PlotValue);
      }
      return { newSegment, segmentEndIndex };
    };

    let currentIndex = 0;
    const segments: ALGTypes.LineSegment[] = [];
    while (currentIndex < values.length - 1) {
      const currentIsDashed = !(values[currentIndex][field] !== null && values[currentIndex + 1][field] !== null);
      const { newSegment, segmentEndIndex } = getSegmentFrom(currentIndex, currentIsDashed || transformed);
      segments.push(newSegment);
      currentIndex = segmentEndIndex;
    }

    return segments;
  }

  export function copyALGSingleSeriesModel(x: ALGSingleSeriesModel, fitted: boolean = false, past: boolean = false) {
    return <ALGSingleSeriesModel> {
      ...x,
      Values: x.Values.map(v => ValueUtils.copyValue<PlotValueDTO>(v)),
      FittedValues: fitted ? x.FittedValues.map(v => ValueUtils.copyValue<FittedValueDTO>(v)) : [],
      WFittedValues: x.WFittedValues?.map(v => ValueUtils.copyValue<FittedValueDTO>(v)),
      ShapValues: x.ShapValues.map(s => StatModelUtils.copyShap(s)),
      WShapValues: x.WShapValues?.map(s => StatModelUtils.copyShap(s)),
      PastForecasts: past ? x.PastForecasts.map(p => ({
        ...p,
        Values: [...p.Values],
        WValues: !!p.WValues ? [...p.WValues] : []
      })) : []
    };
  }

  export function copyALGLineModel(x: ALGLineModel) {
    return <ALGLineModel> {
      ...x,
      Values: x.Values.map(v => ValueUtils.copyValue<PlotValueDTO>(v)),
      Segments: x.Segments.map(s => ({
        ...s,
        Values: s.Values.map(v => ValueUtils.copyValue<PlotValue>(v))
      }))
    };
  }

}
