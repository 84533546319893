import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { HttpStatusCodes } from '@core/constants/http-status-codes.constants';
import { IMemberModel } from '@core/interfaces/if-member';
import { MemberMapper } from '@core/mappers/member.mapper';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { DisplayValue } from '@modules/lang/types/display-value';
import { MemberListOptions } from '@shared/components/member-list/member-list.options';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { ArrayUtils } from '@shared/utils/array.utils';
import { DateUtils } from '@shared/utils/date.utils';
import * as EmailValidator from 'email-validator';

export type MemberListType = 'company' | 'project';

@Component({
  selector: 'indicio-members-list',
  templateUrl: './members-list.component.html'
})
export class MembersListComponent implements OnInit, OnChanges {

  @ViewChild('scrollbarElement') scrollbarElement;
  public previousScrollBarVisible: boolean = false;

  @Input() MemberListOptions: MemberListOptions = null;
  @Input() company: CompanyModel = null;
  @Input() shouldInviteNewMember: boolean = false;

  @Output() hasActiveInviteEvent = new EventEmitter<boolean>();
  @Output() transferOwnershipEvent = new EventEmitter<IMemberModel>();
  @Output() memberChangedRoleEvent = new EventEmitter<{ email: string, newRole: string; }>();
  @Output() atSaveEvent = new EventEmitter<boolean>();

  public get members() { return this.MemberListOptions.CurrentMembers; }
  public get memberService() { return this.MemberListOptions.MemberService; }
  public get newMemberRoleOptions() { return this.MemberListOptions.NewMemberRoleOptions; }
  public get companyId() { return this.MemberListOptions.CompanyId; }
  public get projectId() { return this.MemberListOptions.ProjectId; }
  public get userCanInvite() { return this.MemberListOptions.CurrentUserCanInvite; }
  public get isProjectType() { return this.MemberListOptions.Type === 'project'; }
  public get loadingHelperForSendInviteBtn() { return this.memberService.isLoading; }

  public isLoading = true;
  public newInviteEmail = '';
  public newMemberRole: DisplayValue<string> = null;
  public invitesToSendOnSave: IMemberModel[] = [];
  public sortedMembers: IMemberModel[] = [];
  public newMemberSuggestions: string[];
  public recentlyAddedNewMember: string[] = [];
  public menuOpenFor: number;

  constructor(
    public envService: EnvironmentService,
    private status: StatusService,
    public companyService: CompanyFrontendService,
    public clientService: ClientFrontendService,
    private dialogService: DialogService,
    private memberMapper: MemberMapper,
  ) {
  }

  public ngOnInit() {
    this.checkInput();
    this.sortMembers();
    this.scrollbarVisible();
  }

  public ngOnChanges() {
    this.checkInput();
  }

  private checkInput() {
    if (!this.MemberListOptions) { return; }
    this.newMemberRole = this.MemberListOptions.NewMemberRole;
    this.newMemberSuggestions = this.MemberListOptions.NewMemberSuggestions;
    this.sortMembers();
    this.isLoading = false;
  }

  public removeRow(member: IMemberModel) {
    const index = this.members.findIndex(x => x.Email === member.Email);
    if (index > -1) {
      this.members.splice(index, 1);
    }
  }

  public addRow(member: IMemberModel) {
    this.members.push(member);
  }

  public removeMember(member: IMemberModel) {
    this.dialogService.openConfirmDialog({
      Title: 'Delete user?',
      Message: `Are you sure that you want to delete ${member.Email}.`,
      ConfirmText: 'Yes',
      Style: 'warn',
      ConfirmFunction: () => {
        this.memberService.removeMember(this.companyId, this.projectId, member.Email)
          .then(() => {
            this.removeRow(member);
            this.sortMembers();
          })
          .catch(err => { this.status.setError(err, true); });
      }
    }, { disableClose: true, width: '400px' });
  }

  public async sendQueuedInvites() {
    if (!this.invitesToSendOnSave.length) { return Promise.resolve(); }

    try {
      await this.memberService.inviteMembers(this.companyId, this.projectId, this.invitesToSendOnSave);
      if (this.invitesToSendOnSave.length > 0) {
        this.recentlyAddedNewMember.push(this.invitesToSendOnSave[0].Email);
      }
      this.invitesToSendOnSave = [];
    } catch (error) {
      if (error.status === HttpStatusCodes.BAD_REQUEST) {
        this.invitesToSendOnSave = [];
        this.memberService.syncMembers(this.companyId, this.projectId)
          .then(() => this.sortMembers());
      }

      this.status.setError(error, true); throw Error('');
    }
  }

  public remindUser2FA(member: IMemberModel) {
    if (member.ClientId == null) {
      return;
    }
    this.companyService.remindUser2FA(member.ClientId, this.company.CompanyId)
      .then(() => {
        this.status.setMessage('Successfully sent remind to user about 2FA', 'Success', true);
      }).catch(() => {
        this.status.setMessage('Something went wrong when sending reminder', 'Error', true);
      });
  }

  public checkInvite() {
    if (this.newInviteEmail.length === 0) {
      this.hasActiveInviteEvent.emit(false);
      return;
    }
    if (EmailValidator.validate(this.newInviteEmail.trim())) {
      this.hasActiveInviteEvent.emit(true);
    } else {
      this.hasActiveInviteEvent.emit(false);
    }
  }

  public updateRoleForMember(evt: MatSelectChange, mem: IMemberModel) {
    mem.MemberRole = this.newMemberRoleOptions.find(x => x.Value === evt.value);
    this.memberChangedRoleEvent.emit({ email: mem.Email, newRole: evt.value });
  }

  public transferOwnership(mem: IMemberModel) {
    this.transferOwnershipEvent.emit(mem);
  }

  public resendInvite(member: IMemberModel[]) {
    this.companyService.inviteMembers(this.companyId, this.projectId, member)
      .then(updatedCompany => {
        this.MemberListOptions.CurrentMembers = updatedCompany.Employees.map(e => this.memberMapper.mapCompanyEmployee(e, updatedCompany));
        this.sortMembers();
        this.status.setMessage('Invitation resent.', 'Success', true);
      });
  }

  public invite() {
    this.memberService.isLoading = true;
    const expires = DateUtils.newMoment().add(14, 'days').endOf('day').toDate();
    const newMember: IMemberModel = {
      Email: this.newInviteEmail.trim(),
      MemberRole: this.newMemberRole,
      CompanyId: this.companyId,
      ProjectId: this.projectId,
      IsOwner: false,
      IsCurrentUser: false,
      PendingSignup: true,
      Expires: expires,
      CreatedDate: DateUtils.newDate(),
      newMember: true,
      twoFactorEnabled: false,
      Disabled: false
    };

    // Make sure we cannot invite the same email more than once...
    for (let i = 0; i < this.invitesToSendOnSave.length; i++) {
      if (this.invitesToSendOnSave[i].Email === newMember.Email) {
        this.invitesToSendOnSave[i] = newMember;
        return;
      }
    }

    for (let i = 0; i < this.members.length; i++) {
      if (this.members[i].Email.toLowerCase() === newMember.Email.toLowerCase()) {
        this.status.setMessage('User already invited', 'Error', true);
        return;
      }
    }

    const suggestionIndex = this.newMemberSuggestions.indexOf(newMember.Email);
    this.newMemberSuggestions.splice(suggestionIndex, 1);

    this.invitesToSendOnSave.push(newMember);
    this.newInviteEmail = '';
    this.hasActiveInviteEvent.emit(false);
    this.addRow(newMember);
    this.atSaveEvent.emit(false);
  }

  public pendingInvite(email: string) {
    return this.invitesToSendOnSave.filter(x => x.Email === email).length === 1;
  }

  public sortMembers() {
    const members = this.members.slice();
    this.sortedMembers = members;
    this.sortedMembers.forEach((e) => {
      if (this.recentlyAddedNewMember.includes(e.Email)) {
        e.newMember = true;
      }
    });

    const nonOwners = this.sortedMembers.filter(x => !x.IsOwner);
    const newMembers = nonOwners.filter(x => x.newMember);
    const oldMembers = nonOwners.filter(x => !x.newMember);
    const currentMembers = oldMembers.filter(x => !x.PendingSignup);
    const pendingMembers = oldMembers.filter(x => x.PendingSignup);
    const adminRoleName = this.isProjectType ? 'projectadmin' : 'companyadmin';
    const admin = ArrayUtils.sortArrayAlphabetically(currentMembers.filter(x => x.MemberRole.Value === adminRoleName), 'Email');
    const regular = ArrayUtils.sortArrayAlphabetically(currentMembers.filter(x => x.MemberRole.Value !== adminRoleName), 'Email');
    DateUtils.sortByDates(newMembers, 'CreatedDate', false);

    this.sortedMembers = [...admin, ...regular, ...pendingMembers, ...newMembers];
    if (this.isProjectType) {
      this.sortedMembers = [members.find(x => x.IsOwner), ...this.sortedMembers];
    }

    setTimeout(() => {
      this.scrollbarVisible();
    }, 0);
  }

  public reset() {
    this.newInviteEmail = '';
    this.checkInvite();
  }

  public scrollbarVisible() {
    if (this.scrollbarElement != null) {
      const check = this.scrollbarElement.nativeElement.scrollHeight > this.scrollbarElement.nativeElement.clientHeight;
      this.previousScrollBarVisible = check;
    }
  }

  public getScrollBarVisible() {
    return this.previousScrollBarVisible;
  }

}
