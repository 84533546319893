<ng-container *ngTemplateOutlet="statusTemplate"></ng-container>
<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>

<ng-template #statusTemplate>
  <indicio-status [modal]="true"></indicio-status>
</ng-template>
