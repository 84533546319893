<indicio-dialog-base>
  <h1 mat-dialog-title
      style="text-align:center;">Import variable</h1>
  <div mat-dialog-content>
    <div class="row"
         *ngIf="!loading; else loader">
      <div class="col-xs-7">
        <div style="padding-bottom: 0;padding-top:0; padding-left: 35px; min-height: 420px;">
          <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="graphTemplate"></ng-container>
        </div>
      </div>
      <div class="col-xs-5"
           style="padding-right: 5px; padding-left: 20px">
        <ng-container *ngTemplateOutlet="variableInfoTemplate"></ng-container>
      </div>
    </div>
  </div>

  <div mat-dialog-actions
       style="padding: 10px 25px 20px 30px">
    <button mat-stroked-button
            style="margin-left: 5px"
            (click)="onNoClick()">
      Cancel
    </button>
    <button mat-flat-button
            color="primary"
            [indicioTippy]=" nameMustChange ? 'The variable name is too short' : nameConflict ? 'A variable with this name already exists' : null"
            style="width: 125px"
            [disabled]="loading || !inputIsOk()"
            (click)="import()">
      Import
    </button>
  </div>
</indicio-dialog-base>

<ng-template #nameTemplate>
  <header style="width: 435px; height: 40px; position: relative;">
    <div *ngIf="!editName"
         class="flex-row center-vertical">
      <span class="crop-string"
            style="border-bottom: 1px solid rgba(0,0,0,0.2); width: 100%; font-size: 18px"
            [title]="name">{{ name }}</span>
      <i class="ion-edit margin-left-10 cursor-pointer edit-pen"
         style="font-size: 18px"
         (click)="editName = true"></i>
    </div>
    <div *ngIf="editName"
         class="flex-row center-vertical">
      <mat-form-field style="width: 100%; font-size: 16px; margin-top: -20px">
        <input type="text"
               matInput
               [(ngModel)]="name"
               (ngModelChange)="checkNameErrors()"
               class="material"
               (keyup.enter)="editName = false">
      </mat-form-field>
      <fa-icon class="blue margin-left-10 cursor-pointer edit-pen"
               [icon]="faSave"
               style="font-size: 18px; margin-bottom: 15px"
               (click)="editName = false"></fa-icon>
    </div>
    <div *ngIf="!inputIsOk()">
      <span class="red italic"
            style="position: absolute; font-size: 12px; top: 42px">{{ nameConflict ?
        'A variable with that name already exists.'
        : 'The variable name must be between 3 and 127 characters.' }}
      </span>
    </div>
  </header>
</ng-template>

<ng-template #graphTemplate>
  <ng-container *ngIf="!dataLoader">
    <indicio-alg class="modal-chart"
                 [periodicityType]="forecastVersion.Periodicity"
                 [forecast]="forecast"
                 [forecastVersion]="forecastVersion"
                 [HistoricLine]="historicLine"
                 [options]="graphOptions"
                 [chartStyles]="{ borderSize: 2, borderRadius: 4, plotHeight: 350, historyLine: '#6388D0' }"
                 graphType="variable">
    </indicio-alg>
  </ng-container>
  <ng-container *ngIf="dataLoader">
    <div class="modal-loading"
         style="height: 200px;">
      <i class="ion-load-c spinner"></i>
      <div>Fetching data</div>
    </div>
  </ng-container>
</ng-template>

<ng-template #variableInfoTemplate>
  <div class="row"
       style="margin-bottom:5px;padding-bottom:5px; padding-top:10px;">
    <div class="col-xs-12"
         style="font-weight:300; font-size: 17px">Meta data</div>
  </div>
  <div *ngFor="let metaName of config.MetaFields; index as i;"
       style="margin-bottom: 5px;">
    <div class="row">
      <div class="col-xs-6 crop-string"
           style="font-weight:500;"
           [indicioTippy]="showTippy(metaName) ? metaName : null">{{metaName}}</div>
      <div class="col-xs-6 crop-string"
           [indicioTippy]="showTippy(metaValues[i]) ? metaValues[i] : null">{{metaValues[i]}}</div>
    </div>
  </div>
  <div class="row"
       style="margin-bottom:5px;padding-bottom:5px; padding-top:10px;">
    <div class="col-xs-12"
         style="font-weight:300; font-size: 17px">System identified info</div>
  </div>
  <div style="margin-bottom: 5px;"
       *ngIf="periodicity">
    <div class="row">
      <div class="col-xs-6"
           style="font-weight:500;">Periodicity</div>
      <div class="col-xs-6 crop-string">{{periodicity}}</div>
    </div>
  </div>
  <div style="margin-bottom: 5px;">
    <div class="row">
      <div class="col-xs-6"
           style="font-weight:500;">First date</div>
      <div class="col-xs-6 crop-string">{{firstDate | dateFormat}}</div>
    </div>
  </div>
  <div style="margin-bottom: 5px;">
    <div class="row">
      <div class="col-xs-6"
           style="font-weight:500;">Last date</div>
      <div class="col-xs-6 crop-string">{{lastDate | dateFormat}}</div>
    </div>
  </div>

  <div style="width: 100%; margin-top: 45px">
    <span style="font-weight: 300; font-size: 16px;">Aggregation method</span>
    <mat-form-field style="margin-top: -5px">
      <mat-select [(value)]="aggregationMethodId"
                  required>
        <mat-option [value]="option.Value"
                    *ngFor="let option of aggregationMethods">{{ option.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>


<ng-template #loader>
  <div style="text-align:center;display: inline;"
       class="vertical-align row">
    <div>
      <i class="ion-load-c spinner"></i> Loading... Please wait.
    </div>
  </div>
</ng-template>
