import { Component, ViewEncapsulation } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePickerBaseComponent, StartViewType } from '../base/date-picker-base.component';

export const DAY_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy-MM-dd',
  },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthYearLabel: 'yyyy-MM-dd',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'yyyy MMMM dd'
  },
};

@Component({
  selector: 'indicio-date-picker-day',
  templateUrl: '../base/date-picker-base.component.html',
  styleUrls: ['../date-picker.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DAY_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
})
export class DatePickerDayComponent extends DatePickerBaseComponent {
  public panelClass: string = 'day-date-picker';
  public startView: StartViewType = 'month';
}
