import { Component } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { HistoricEventFrontendService } from '@core/store/historic-event/historic-event.frontend.service';
import { HistoricBaseEventModel } from '@core/store/historic-event/models/base/historic-event.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DeleteHEventModalOptions } from './delete-multiple-events-modal.options';


@Component({
  selector: 'indicio-delete-multiple-events-modal',
  templateUrl: './delete-multiple-events-modal.component.html',
  styleUrls: ['./delete-multiple-events-modal.component.less'],
})
export class DeleteHistoricEventsModalComponent extends ModalModelComponent {

  public events: HistoricBaseEventModel[] = [];
  public forecastVersion: ForecastVersionModel;
  public callback: any;

  public isLoading = true;

  removedCount = 0;

  public get eventsToBeRemoved() { return this.events.filter(x => this.isRemovable(x)); }
  public get triggeredRemove() { return this.events.some(x => x.removedStatus !== 'Pending'); }

  constructor(
    protected store: Store,
    private status: StatusService,
    private service: HistoricEventFrontendService,
    public envService: EnvironmentService,
    public clientService: ClientFrontendService,
    public appearance: AppearanceService
  ) { super(); }

  public setOptions(options: DeleteHEventModalOptions) {
    this.events = options.events;
    this.forecastVersion = options.forecastVersion;
    this.callback = options.callback;
    this.isLoading = false;
  }

  onClose() {
    if (this.callback && this.events.some(x => x.removedStatus !== 'Pending')) {
      this.callback();
    }
  }

  isRemovable(event: HistoricBaseEventModel) {
    const eventIsUserDefined = event.CompanyId === this.clientService.activeCompany.CompanyId;
    return eventIsUserDefined;
  }

  public async removeEvents() {
    const toRemove = this.eventsToBeRemoved;
    this.pending = true;

    toRemove.forEach(x => x.removedStatus = 'Pending');
    const count = toRemove.length;
    const removeFunc = async (variable) => {
      await this.removeEvent(variable);
    };

    let current = 0;

    const queueFunc = async () => {
      if (current >= count) {
        this.pending = false;
        return;
      }
      await removeFunc(toRemove[current]);
      current += 1;
      setTimeout(async () => {
        await queueFunc();
      }, 150);
    };

    await queueFunc();
  }

  public removeEvent(event: HistoricBaseEventModel) {
    event.removedStatus = 'Removing';
    this.service.deleteBaseEvent(event)
      .then(_x => event.removedStatus = 'Success')
      .catch(error => {
        event.removedStatus = 'Failed';
        event.failedMsg = this.status.getMessage(error).message;
      }).finally(() => this.removedCount += 1);
  }
}


