import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { FredBackendService, FredObservationOptions } from './fred.backend.service';
import { FredStateCategoryModel } from './fred-state-category.model';
import { GetFredCategorySubCategoriesSuccessAction, GetFredCategoryDataSeriesSuccessAction, GetFredTagsSuccessAction } from './fred.actions';
import { FredState } from './fred.state';
import { FredTagModel } from '@core/store/providers/fred/fred-tag.model';

@Injectable({
  providedIn: 'root'
})
export class FredFrontendService {

  public get categories() { return this.store.selectSnapshot(FredState.categories); }
  public get categoriesMeta() { return this.store.selectSnapshot(FredState.categoriesMeta); }
  public get tags() { return this.store.selectSnapshot(FredState.tags); }
  public get queue() { return this.store.selectSnapshot(FredState.queue); }
  public get queueNotImported() { return this.store.selectSnapshot(FredState.queue).filter(s => !s.imported); }

  public getCategoryById(categoryId: number) { return this.categories.find(c => c.Id === categoryId); }
  public getCategoryMetaById(metaId: number) { return this.categoriesMeta.find(c => c.Id === metaId); }
  public isSerieInQueue(serieId: string) { return !!this.queue.find(c => c.id === serieId); }
  public serieInQueueIndex(serieId: string) { return this.queue.findIndex(c => c.id === serieId); }

  constructor(
    private store: Store,
    private backend: FredBackendService
  ) {
  }

  public setAPIKey(key: string) {
    this.backend.setAPIKey(key);
  }

  public getOrFetchCategory(categoryId: number): Promise<FredStateCategoryModel> {
    const category = this.getCategoryById(categoryId);
    if (!category) {
      return this.fetchCategoryData(categoryId);
    }
    return Promise.resolve(category);
  }

  public getCategoryTags(category_id: number) {
    return this.backend.getCategoryTags(category_id);
  }

  public getOrFetchTags(force: boolean = false): Promise<FredTagModel[]> {
    const tags = this.tags;
    if (!tags.length || force) {
      return this.fetchTags();
    }
    return Promise.resolve(tags);
  }

  public getMeta(id: number) {
    return this.getCategoryMetaById(id);
  }

  private fetchCategoryData(categoryId: number) {
    const promises = [
      this.fetchCategories(categoryId),
      this.fetchDataSeries(categoryId)
    ];
    // @ts-ignore
    return Promise.all(promises).then(() => this.getCategoryById(categoryId));
  }

  private fetchCategories(categoryId: number) {
    return this.backend.getCategories(categoryId)
      .then(categories => {
        this.store.dispatch(new GetFredCategorySubCategoriesSuccessAction(categoryId, categories));
        return categories;
      });
  }

  private fetchDataSeries(categoryId: number) {
    return this.backend.getDataSeries(categoryId)
      .then(dataseries => {
        this.store.dispatch(new GetFredCategoryDataSeriesSuccessAction(categoryId, dataseries));
        return dataseries;
      });
  }

  public fetchObservations(dto: FredObservationOptions) {
    return this.backend.getObservations(dto);
  }

  public fetchTags() {
    return this.backend.getTags().then(tags => {
      this.store.dispatch(new GetFredTagsSuccessAction(tags));
      return tags;
    });
  }

  public searchSeries(query: string) {
    return this.backend.searchSeries(query);
  }
}
