<indicio-modal-base [child]="this"
                    modalTitle="Forecasted variable information"
                    bodyExtraClass="overflow-auto"
                    [loading]="isLoading">

  <div *ngIf="!isLoading && result"
       style="--mat-select-trigger-text-size: 12px;">
    <ng-container *ngTemplateOutlet="generalView"></ng-container>
    <ng-container *ngTemplateOutlet="buttonRow"></ng-container>

  </div>
</indicio-modal-base>

<ng-template #generalView>
  This variable has observations that is based on a forecasted result

  <div class="data-table extra-margin-top"
       style="width: 500px">

    <div class="data-table__body"
         [style.maxHeight.px]="301">

      <ng-container>
        <div class="body__row body__row--center-height"
             style="background-color: #003ca154; height: 35px;">
          <div class="column left-align"
               style="width: 120px;">
            <span class="bold">Selected result</span>
          </div>
          <div class="column left-align"
               style="width: 330px;">
            <div style="width: 200px">
              <mat-form-field *ngIf="potentialResults.length">
                <mat-select [(value)]="currentPotentialResults.selectedResultId"
                            (selectionChange)="newInput($event.value);"
                            panelClass="data-table-panel-opts fitted-select"
                            required>
                  <ng-container *ngFor="let fver of potentialResults">
                    <mat-option [value]="result.SourceVariableMetaId"
                                [title]="result.ResultName"
                                *ngFor="let result of fver.Results">{{ result.ResultName }}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
              <ng-container *ngIf="!potentialResults.length">{{ sourceVersion.Name }}</ng-container>
            </div>

          </div>
          <div class="column"
               style="width: 50px;">
            <i class="ion-android-exit"
               [ngClass]="{pointer: result.ForecastVersionId}"
               [indicioTippy]="!result.ForecastVersionId ? 'The forecast that produced this result has been removed' : 'Navigate to forecast'"
               (click)="!result.ForecastVersionId ? null : navToForecast()"
               [ngStyle]="!result.ForecastVersionId ? {color: 'grey'} : {} "
               style="font-size: 15px; margin-left: 5px;">
            </i>
          </div>
        </div>
      </ng-container>


      <div class="body__row body__row--center-height body__row--max-height-30">
        <div class="column left-align"
             style="width: 120px;">
          <span class="bold">Forecast name</span>
        </div>
        <div class="column left-align"
             style="width: 330px;">
          {{ result.ForecastName }}
        </div>
      </div>

      <div class="body__row body__row--center-height body__row--max-height-30">
        <div class="column left-align"
             style="width: 120px;">
          <span class="bold">Forecast periodicity</span>
        </div>
        <div class="column left-align"
             style="width: 380px;">
          {{ result.periodicity.Display }}
        </div>
      </div>

      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngIf="result">
        <div class="column left-align"
             style="width: 120px;">
          <span class="bold">Result created at</span>
        </div>
        <div class="column left-align"
             style="width: 380px;">
          {{ result.CreatedDate | dateFormat }}
        </div>
      </div>

      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngIf="result">
        <div class="column left-align"
             style="width: 120px;">
          <span class="bold">Weight method</span>
        </div>
        <div class="column left-align"
             style="width: 380px;">
          {{ result.WeightMethod }}
        </div>
      </div>
    </div>
  </div>


  <ng-container *ngIf="result.getModels(envService.UnivariateModelNames) as uniModels">
    <ng-container
                  *ngTemplateOutlet="resultTypeTemplate; context: { models: uniModels, title: 'Univariate models', open: uniOpen }">
    </ng-container>
  </ng-container>

  <ng-container *ngIf="result.getModels(envService.MultivariateModelNames) as multiModels">
    <ng-container
                  *ngTemplateOutlet="resultTypeTemplate; context: { models: multiModels, title: 'Multivariate models', open: multiOpen }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #resultTypeTemplate
             let-models="models"
             let-title="title"
             let-open="open">
  <indicio-foldable-row [title]="title"
                        [open]="open"
                        *ngIf="models.length"
                        (toggleEvent)="toggleFoldables($event, title)">
    <div class="data-table"
         style="width: 480px">
      <div class="data-table__header">
        <div class="column header left-align"
             style="width: 370px;">Name</div>
        <div class="column header"
             style="width: 100px;">{{ models[0]?.Weight?.length > 1 ? 'Avg. Weight' : 'Weight' }}</div>
      </div>

      <div class="data-table__body"
           [style.maxHeight.px]="200">
        <div class="body__row body__row--center-height"
             *ngFor="let model of models">
          <div class="column left-align"
               style="width: 370px;">
            {{ envService.getModelName(model.Name).Display }}
          </div>
          <div class="column"
               style="width: 100px;">
            <span [class]="{ pointer: model.Weight.length > 1 }"
                  (click)="model.Weight.length > 1 ? openModelWeightDialog(model) : null">
              {{ model.averageWeight | propertyValue }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </indicio-foldable-row>
</ng-template>

<ng-template #buttonRow>
  <div class="row extra-margin-top">
    <div class="col-xs-6">

      <button class="btn blue outline"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6">
      <button class="btn right"
              [disabled]="!potentialResults.length || (!!potentialResults.length && currentPotentialResults.selectedResultId === opts.sourceMetaId || pending)"
              (click)="saveVersionChange()">
        <span *ngIf="!pending">Save</span>
        <i class="ion-load-c spinner"
           *ngIf="pending"></i>
      </button>
    </div>
  </div>
</ng-template>
