import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'dateFormat' })
export class DateFormatPipe extends DatePipe implements PipeTransform {
  constructor(private appearance: AppearanceService) {
    super('en');
  }

  transform(value: any, periodicity?: string, format?: string): any {
    try {
      if (typeof format === 'object') {
        format = null;
      }

      const dateVal = DateUtils.newNullableDate(value);
      if (dateVal === null) return '';
      // const timeOffsetInMS: number = dateVal.getTimezoneOffset() * 60000;
      // dateVal.setTime(dateVal.getTime() - timeOffsetInMS);

      format = format || this.appearance.DateFormat;

      if (periodicity) {
        periodicity = periodicity.toLowerCase().replace('ly', '');
      }

      if (periodicity) {
        format = this.getFormat(value, periodicity, false);
      }

      return super.transform(dateVal, format);
    } catch (e) {
      return value;
    }
  }

  public formatWithLimit(date: Date, p: PeriodicityType, limitToMonthMinimum = false) {
    if (p === 'day' && limitToMonthMinimum) { p = 'month'; }
    return this.transform(date, p);
  }

  private getFormat(value: any, periodicity: string, download: boolean) {
    const date = DateUtils.newDate(value);
    let format = this.appearance.DateFormat;
    if (periodicity) {
      if (periodicity === 'week') {
        format = 'yyyy-\'W\'w';
      } else if (periodicity === 'month') {
        if (download) {
          format = 'yyyy-MM-dd';
        } else {
          format = 'yyyy-MM';
        }
      } else if (periodicity === 'quarter') {
        format = `yyyy ${this.getQuarter(date)}`;
      } else if (periodicity === 'halfyear') {
        const y = super.transform(date, 'yyyy');
        return y + ` ${this.getHalfyear(date)}`;
      } else if (periodicity === 'year' || periodicity === 'annual') {
        format = 'yyyy';
      } else if (periodicity === 'minute') {
        format = 'HH:mm';
      }
    }
    return format;
  }

  private getQuarter(date: Date) {
    const month = date.getMonth();
    if (month >= 0 && month <= 2) {
      return 'Q1';
    } else if (month >= 3 && month <= 5) {
      return 'Q2';
    } else if (month >= 6 && month <= 8) {
      return 'Q3';
    } else if (month >= 9 && month <= 11) {
      return 'Q4';
    }
  }

  private getHalfyear(date: Date) {
    const month = date.getMonth();
    if (month <= 5) {
      return 'H1';
    } else {
      return 'H2';
    }
  }
}
