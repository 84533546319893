import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearStateAction } from '../auth/auth.actions';
import { CompanyActions } from '../company/company.actions';
import { ReportModel } from './models/report.model';
import { ReportAction } from './reports.actions';

class ReportStateModel {
  Reports: ReportModel[];
  ActiveReportId: string;
}

@State<ReportStateModel>({
  name: 'reports',
  defaults: {
    Reports: [],
    ActiveReportId: null
  }
})
@Injectable()
export class ReportState {

  @Selector()
  static reports(state: ReportStateModel) { return state.Reports; }

  @Selector()
  static activeReportId(state: ReportStateModel) { return state.ActiveReportId; }

  @Selector()
  static activeReport(state: ReportStateModel) { return state.Reports.find(x => x.ReportId === state.ActiveReportId); }

  @Action(ReportAction.GetSuccess)
  getReportSuccess(ctx: StateContext<ReportStateModel>, action: ReportAction.GetSuccess) {
    const reports = ctx.getState().Reports;
    reports.addOrUpdate(action.report);
    ctx.patchState({ Reports: [...reports] });
  }

  @Action(ReportAction.GetAllSuccess)
  getAllReportsSuccess(ctx: StateContext<ReportStateModel>, action: ReportAction.GetAllSuccess) {
    const reports = ctx.getState().Reports;
    action.reports.forEach(report => reports.addOrUpdate(report));
    ctx.patchState({ Reports: [...reports] });
  }

  @Action(ReportAction.CreateOrUpdateSuccess)
  createOrUpdateReportSuccess(ctx: StateContext<ReportStateModel>, action: ReportAction.CreateOrUpdateSuccess) {
    const reports = ctx.getState().Reports;
    reports.addOrUpdate(action.report);
    ctx.patchState({ Reports: [...reports] });
  }

  @Action(ReportAction.DeleteSuccess)
  deleteSuccessSuccess(ctx: StateContext<ReportStateModel>, action: ReportAction.CreateOrUpdateSuccess) {
    const reports = ctx.getState().Reports;
    reports.removeById(action.report.ReportId);
    ctx.patchState({ Reports: [...reports] });
  }

  @Action(ReportAction.DeleteSuccess)
  deleteScenarioSuccess(ctx: StateContext<ReportStateModel>, action: ReportAction.DeleteSuccess) {
    const reports = ctx.getState().Reports;
    reports.removeById(action.report.getModelId());
    ctx.patchState({ Reports: [...reports] });
  }

  @Action(ReportAction.SetActiveReport)
  setActiveReport(ctx: StateContext<ReportStateModel>, action: ReportAction.SetActiveReport) {
    ctx.patchState({ ActiveReportId: action.reportId });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<ReportStateModel>) {
    ctx.patchState({ Reports: [], ActiveReportId: null });
  }
}
