import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { RQueueInfoModel } from '@core/store/script-queue/r-queue.models';
import { ScriptQueueFrontendService } from '@core/store/script-queue/script-queue.frontend.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'indicio-script-queue',
  templateUrl: './script-queue.component.html'
})
export class ScriptQueueComponent implements OnDestroy, OnChanges {

  private _interval = interval(45000);
  private _queueSub: Subscription;
  private _timerSub: Subscription = null;

  public queue: RQueueInfoModel = null;
  public minimized = false;

  hasModels = false;
  hasFromNowcast = false;
  sanityInterval = interval(45000);
  sanityTimer: Subscription = null;

  @Input() forecastVersionId: string;

  constructor(
    public queueService: ScriptQueueFrontendService,
    public forecastService: ForecastFrontendService,
    public envService: EnvironmentService
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.forecastVersionId) {
      this.setupSub();
    }
  }

  public ngOnDestroy(): void {
    if (this._queueSub) { this._queueSub.unsubscribe(); }
    if (this.sanityTimer) { this.sanityTimer.unsubscribe(); this.sanityTimer = null; }
    this.stop();
  }

  private setupSub() {
    if (this._queueSub) { this._queueSub.unsubscribe(); }
    this._queueSub = this.queueService.getQueueByForecastVersionId$(this.forecastVersionId)
      .subscribe(partialState => {
        this.queue = partialState;
        this.scrollToBottom();
        this.checkWatcherStatus();
      });
    this.startSanityTimer();
  }

  private startSanityTimer() {
    if (this.sanityTimer) { return; }
    this.sanityTimer = this.sanityInterval.subscribe(_ => {
      if (this.queue?.allEntries?.length) {
        this.forecastService.fetchRequestQueue(this.forecastVersionId, true);
      }
    });
  }

  private scrollToBottom() {
    setTimeout(() => {
      const container = document.querySelector('.sq-row-container');
      if (!container) { return; }
      container.scrollTop = container.scrollHeight - container.clientHeight;
    });
  }

  private checkWatcherStatus() {
    if (this.queue.Entries.length && !this._timerSub) {
      this.start();
    } else if (this.queue.Entries.length === 0 && this._timerSub) {
      this.stop();
    }
    this.hasModels = this.queue.Entries.some(x => !!this.envService.getModelName(x.ModelName).Display);
    this.hasFromNowcast = this.queue.Entries.some(x => x.ForecastVersionId !== this.forecastVersionId);
  }

  private stop() {
    if (this._timerSub) {
      this._timerSub.unsubscribe();
      this._timerSub = null;
    }
  }

  private start() {
    if (this._timerSub) { return; }
    this._timerSub = this._interval.subscribe(_ => {
    });
  }
}
