
export const AlpvaVantageValueTypes = ['max', 'min', 'val', 'open', 'close', 'vol'];
export type AlphaVantageFromToKey = 'from' | 'to';

export class AlphaVantageEntry {
  code: string;
  display: string;
}

export enum AlphaVantageStockPeriodicities {
  TIME_SERIES_DAILY = 'TIME_SERIES_DAILY',
  TIME_SERIES_WEEKLY = 'TIME_SERIES_WEEKLY',
  TIME_SERIES_MONTHLY = 'TIME_SERIES_MONTHLY',
}

export enum AlphaVantageForexPeriodicities {
  FX_DAILY = 'FX_DAILY',
  FX_WEEKLY = 'FX_WEEKLY',
  FX_MONTHLY = 'FX_MONTHLY',
}

export enum AlphaVantageCryptoPeriodicities {
  DIGITAL_CURRENCY_DAILY = 'DIGITAL_CURRENCY_DAILY',
  DIGITAL_CURRENCY_WEEKLY = 'DIGITAL_CURRENCY_WEEKLY',
  DIGITAL_CURRENCY_MONTHLY = 'DIGITAL_CURRENCY_MONTHLY'
}

export const AlphaVantageCryptoPeriodicitiesList = [
  { display: 'Daily', value: AlphaVantageCryptoPeriodicities.DIGITAL_CURRENCY_DAILY },
  { display: 'Weekly', value: AlphaVantageCryptoPeriodicities.DIGITAL_CURRENCY_WEEKLY },
  { display: 'Monthly', value: AlphaVantageCryptoPeriodicities.DIGITAL_CURRENCY_MONTHLY }
];

export const AlphaVantageForexPeriodicitiesList = [
  { display: 'Daily', value: AlphaVantageForexPeriodicities.FX_DAILY },
  { display: 'Weekly', value: AlphaVantageForexPeriodicities.FX_WEEKLY },
  { display: 'Monthly', value: AlphaVantageForexPeriodicities.FX_MONTHLY }
];

export const AlphaVantageCurrencies: AlphaVantageEntry[] = [
  { 'AED': 'United Arab Emirates Dirham' },
  { 'AFN': 'Afghan Afghani' },
  { 'ALL': 'Albanian Lek' },
  { 'AMD': 'Armenian Dram' },
  { 'ANG': 'Netherlands Antillean Guilder' },
  { 'AOA': 'Angolan Kwanza' },
  { 'ARS': 'Argentine Peso' },
  { 'AUD': 'Australian Dollar' },
  { 'AWG': 'Aruban Florin' },
  { 'AZN': 'Azerbaijani Manat' },
  { 'BAM': 'Bosnia-Herzegovina Convertible Mark' },
  { 'BBD': 'Barbadian Dollar' },
  { 'BDT': 'Bangladeshi Taka' },
  { 'BGN': 'Bulgarian Lev' },
  { 'BHD': 'Bahraini Dinar' },
  { 'BIF': 'Burundian Franc' },
  { 'BMD': 'Bermudan Dollar' },
  { 'BND': 'Brunei Dollar' },
  { 'BOB': 'Bolivian Boliviano' },
  { 'BRL': 'Brazilian Real' },
  { 'BSD': 'Bahamian Dollar' },
  { 'BTN': 'Bhutanese Ngultrum' },
  { 'BWP': 'Botswanan Pula' },
  { 'BZD': 'Belize Dollar' },
  { 'CAD': 'Canadian Dollar' },
  { 'CDF': 'Congolese Franc' },
  { 'CHF': 'Swiss Franc' },
  { 'CLF': 'Chilean Unit of Account UF' },
  { 'CLP': 'Chilean Peso' },
  { 'CNH': 'Chinese Yuan Offshore' },
  { 'CNY': 'Chinese Yuan' },
  { 'COP': 'Colombian Peso' },
  { 'CUP': 'Cuban Peso' },
  { 'CVE': 'Cape Verdean Escudo' },
  { 'CZK': 'Czech Republic Koruna' },
  { 'DJF': 'Djiboutian Franc' },
  { 'DKK': 'Danish Krone' },
  { 'DOP': 'Dominican Peso' },
  { 'DZD': 'Algerian Dinar' },
  { 'EGP': 'Egyptian Pound' },
  { 'ERN': 'Eritrean Nakfa' },
  { 'ETB': 'Ethiopian Birr' },
  { 'EUR': 'Euro' },
  { 'FJD': 'Fijian Dollar' },
  { 'FKP': 'Falkland Islands Pound' },
  { 'GBP': 'British Pound Sterling' },
  { 'GEL': 'Georgian Lari' },
  { 'GHS': 'Ghanaian Cedi' },
  { 'GIP': 'Gibraltar Pound' },
  { 'GMD': 'Gambian Dalasi' },
  { 'GNF': 'Guinean Franc' },
  { 'GTQ': 'Guatemalan Quetzal' },
  { 'GYD': 'Guyanaese Dollar' },
  { 'HKD': 'Hong Kong Dollar' },
  { 'HNL': 'Honduran Lempira' },
  { 'HRK': 'Croatian Kuna' },
  { 'HTG': 'Haitian Gourde' },
  { 'HUF': 'Hungarian Forint' },
  { 'ICP': 'Internet Computer' },
  { 'IDR': 'Indonesian Rupiah' },
  { 'ILS': 'Israeli New Sheqel' },
  { 'INR': 'Indian Rupee' },
  { 'IQD': 'Iraqi Dinar' },
  { 'IRR': 'Iranian Rial' },
  { 'ISK': 'Icelandic Krona' },
  { 'JEP': 'Jersey Pound' },
  { 'JMD': 'Jamaican Dollar' },
  { 'JOD': 'Jordanian Dinar' },
  { 'JPY': 'Japanese Yen' },
  { 'KES': 'Kenyan Shilling' },
  { 'KGS': 'Kyrgystani Som' },
  { 'KHR': 'Cambodian Riel' },
  { 'KMF': 'Comorian Franc' },
  { 'KPW': 'North Korean Won' },
  { 'KRW': 'South Korean Won' },
  { 'KWD': 'Kuwaiti Dinar' },
  { 'KYD': 'Cayman Islands Dollar' },
  { 'KZT': 'Kazakhstani Tenge' },
  { 'LAK': 'Laotian Kip' },
  { 'LBP': 'Lebanese Pound' },
  { 'LKR': 'Sri Lankan Rupee' },
  { 'LRD': 'Liberian Dollar' },
  { 'LSL': 'Lesotho Loti' },
  { 'LYD': 'Libyan Dinar' },
  { 'MAD': 'Moroccan Dirham' },
  { 'MDL': 'Moldovan Leu' },
  { 'MGA': 'Malagasy Ariary' },
  { 'MKD': 'Macedonian Denar' },
  { 'MMK': 'Myanma Kyat' },
  { 'MNT': 'Mongolian Tugrik' },
  { 'MOP': 'Macanese Pataca' },
  { 'MRO': 'Mauritanian Ouguiya (pre-2018)' },
  { 'MRU': 'Mauritanian Ouguiya' },
  { 'MUR': 'Mauritian Rupee' },
  { 'MVR': 'Maldivian Rufiyaa' },
  { 'MWK': 'Malawian Kwacha' },
  { 'MXN': 'Mexican Peso' },
  { 'MYR': 'Malaysian Ringgit' },
  { 'MZN': 'Mozambican Metical' },
  { 'NAD': 'Namibian Dollar' },
  { 'NGN': 'Nigerian Naira' },
  { 'NOK': 'Norwegian Krone' },
  { 'NPR': 'Nepalese Rupee' },
  { 'NZD': 'New Zealand Dollar' },
  { 'OMR': 'Omani Rial' },
  { 'PAB': 'Panamanian Balboa' },
  { 'PEN': 'Peruvian Nuevo Sol' },
  { 'PGK': 'Papua New Guinean Kina' },
  { 'PHP': 'Philippine Peso' },
  { 'PKR': 'Pakistani Rupee' },
  { 'PLN': 'Polish Zloty' },
  { 'PYG': 'Paraguayan Guarani' },
  { 'QAR': 'Qatari Rial' },
  { 'RON': 'Romanian Leu' },
  { 'RSD': 'Serbian Dinar' },
  { 'RUB': 'Russian Ruble' },
  { 'RUR': 'Old Russian Ruble' },
  { 'RWF': 'Rwandan Franc' },
  { 'SAR': 'Saudi Riyal' },
  { 'SBDf': 'Solomon Islands Dollar' },
  { 'SCR': 'Seychellois Rupee' },
  { 'SDG': 'Sudanese Pound' },
  { 'SDR': 'Special Drawing Rights' },
  { 'SEK': 'Swedish Krona' },
  { 'SGD': 'Singapore Dollar' },
  { 'SHP': 'Saint Helena Pound' },
  { 'SLL': 'Sierra Leonean Leone' },
  { 'SOS': 'Somali Shilling' },
  { 'SRD': 'Surinamese Dollar' },
  { 'SYP': 'Syrian Pound' },
  { 'SZL': 'Swazi Lilangeni' },
  { 'THB': 'Thai Baht' },
  { 'TJS': 'Tajikistani Somoni' },
  { 'TMT': 'Turkmenistani Manat' },
  { 'TND': 'Tunisian Dinar' },
  { 'TOP': 'Tongan Pa\'anga' },
  { 'TRY': 'Turkish Lira' },
  { 'TTD': 'Trinidad and Tobago Dollar' },
  { 'TWD': 'New Taiwan Dollar' },
  { 'TZS': 'Tanzanian Shilling' },
  { 'UAH': 'Ukrainian Hryvnia' },
  { 'UGX': 'Ugandan Shilling' },
  { 'USD': 'United States Dollar' },
  { 'UYU': 'Uruguayan Peso' },
  { 'UZS': 'Uzbekistan Som' },
  { 'VND': 'Vietnamese Dong' },
  { 'VUV': 'Vanuatu Vatu' },
  { 'WST': 'Samoan Tala' },
  { 'XAF': 'CFA Franc BEAC' },
  { 'XCD': 'East Caribbean Dollar' },
  { 'XDR': 'Special Drawing Rights' },
  { 'XOF': 'CFA Franc BCEAO' },
  { 'XPF': 'CFP Franc' },
  { 'YER': 'Yemeni Rial' },
  { 'ZAR': 'South African Rand' },
  { 'ZMW': 'Zambian Kwacha' },
  { 'ZWL': 'Zimbabwean Dollar' }
].map(c => Object.keys(c).map(x => ({ code: x, display: x + ' - ' + c[x] }))).map(x => x[0]);

export const AlphaVantageCryptoCurrencies = [
  { '1ST': 'FirstBlood' },
  { '2GIVE': 'GiveCoin' },
  { '808': '808Coin' },
  { 'AAVE': 'Aave' },
  { 'ABT': 'ArcBlock' },
  { 'ABY': 'ArtByte' },
  { 'AC': 'AsiaCoin' },
  { 'ACT': 'Achain' },
  { 'ADA': 'Cardano' },
  { 'ADT': 'adToken' },
  { 'ADX': 'AdEx' },
  { 'AE': 'Aeternity' },
  { 'AEON': 'Aeon' },
  { 'AGI': 'SingularityNET' },
  { 'AGRS': 'IDNI-Agoras' },
  { 'AI': 'POLY-AI' },
  { 'AID': 'AidCoin' },
  { 'AION': 'Aion' },
  { 'AIR': 'AirToken' },
  { 'AKY': 'Akuya-Coin' },
  { 'ALGO': 'Algorand' },
  { 'ALIS': 'ALIS' },
  { 'AMBER': 'AmberCoin' },
  { 'AMP': 'Synereo' },
  { 'AMPL': 'Ampleforth' },
  { 'ANC': 'Anoncoin' },
  { 'ANT': 'Aragon' },
  { 'APPC': 'AppCoins' },
  { 'APX': 'APX-Ventures' },
  { 'ARDR': 'Ardor' },
  { 'ARK': 'Ark' },
  { 'ARN': 'Aeron' },
  { 'AST': 'AirSwap' },
  { 'ATB': 'ATBCoin' },
  { 'ATM': 'ATMChain' },
  { 'ATOM': 'Cosmos' },
  { 'ATS': 'Authorship' },
  { 'AUR': 'Auroracoin' },
  { 'AVAX': 'Avalanche' },
  { 'AVT': 'Aventus' },
  { 'B3': 'B3Coin' },
  { 'BAND': 'Band Protocol' },
  { 'BAT': 'Basic-Attention-Token' },
  { 'BAY': 'BitBay' },
  { 'BBR': 'Boolberry' },
  { 'BCAP': 'BCAP' },
  { 'BCC': 'BitConnect' },
  { 'BCD': 'Bitcoin-Diamond' },
  { 'BCH': 'Bitcoin-Cash' },
  { 'BCN': 'Bytecoin' },
  { 'BCPT': 'BlockMason-Credit-Protocol-Token' },
  { 'BCX': 'BitcoinX' },
  { 'BCY': 'BitCrystals' },
  { 'BDL': 'Bitdeal' },
  { 'BEE': 'Bee-Token' },
  { 'BELA': 'BelaCoin' },
  { 'BET': 'DAO-Casino' },
  { 'BFT': 'BF-Token' },
  { 'BIS': 'Bismuth' },
  { 'BITB': 'BitBean' },
  { 'BITBTC': 'BitBTC' },
  { 'BITCNY': 'BitCNY' },
  { 'BITEUR': 'BitEUR' },
  { 'BITGOLD': 'BitGOLD' },
  { 'BITSILVER': 'BitSILVER' },
  { 'BITUSD': 'BitUSD' },
  { 'BIX': 'Bibox-Token' },
  { 'BLITZ': 'Blitzcash' },
  { 'BLK': 'Blackcoin' },
  { 'BLN': 'Bolenum' },
  { 'BLOCK': 'Blocknet' },
  { 'BLZ': 'Bluzelle' },
  { 'BMC': 'Blackmoon-Crypto' },
  { 'BNB': 'Binance-Coin' },
  { 'BNT': 'Bancor-Network-Token' },
  { 'BNTY': 'Bounty0x' },
  { 'BOST': 'BoostCoin' },
  { 'BOT': 'Bodhi' },
  { 'BQ': 'bitqy' },
  { 'BRD': 'Bread' },
  { 'BRK': 'Breakout-Coin' },
  { 'BRX': 'Breakout-Stake' },
  { 'BSV': 'Bitcoin SV' },
  { 'BTA': 'Bata' },
  { 'BTC': 'Bitcoin' },
  { 'BTCB': 'Bitcoin BEP2' },
  { 'BTCD': 'BitcoinDark' },
  { 'BTCP': 'Bitcoin-Private' },
  { 'BTG': 'Bitcoin-Gold' },
  { 'BTM': 'Bitmark' },
  { 'BTS': 'BitShares' },
  { 'BTSR': 'BTSR' },
  { 'BTT': 'BitTorrent' },
  { 'BTX': 'Bitcore' },
  { 'BURST': 'Burstcoin' },
  { 'BUSD': 'Binance-USD' },
  { 'BUZZ': 'BuzzCoin' },
  { 'BYC': 'Bytecent' },
  { 'BYTOM': 'Bytom' },
  { 'C20': 'Crypto20' },
  { 'CAKE': 'PancakeSwap' },
  { 'CANN': 'CannabisCoin' },
  { 'CAT': 'BlockCAT' },
  { 'CCRB': 'CryptoCarbon' },
  { 'CDT': 'Blox' },
  { 'CFI': 'Cofound-it' },
  { 'CHAT': 'ChatCoin' },
  { 'CHIPS': 'Chips' },
  { 'CLAM': 'Clams' },
  { 'CLOAK': 'CloakCoin' },
  { 'CMP': 'Compcoin' },
  { 'CMT': 'CyberMiles' },
  { 'CND': 'Cindicator' },
  { 'CNX': 'Cryptonex' },
  { 'COFI': 'CoinFi' },
  { 'COMP': 'Compound' },
  { 'COSS': 'COSS' },
  { 'COVAL': 'Circuits-Of-Value' },
  { 'CRBIT': 'CreditBIT' },
  { 'CREA': 'CreativeCoin' },
  { 'CREDO': 'Credo' },
  { 'CRO': 'Crypto.com Coin' },
  { 'CRW': 'Crown' },
  { 'CSNO': 'BitDice' },
  { 'CTR': 'Centra' },
  { 'CTXC': 'Cortex' },
  { 'CURE': 'CureCoin' },
  { 'CVC': 'Civic' },
  { 'DAI': 'Dai' },
  { 'DAR': 'Darcrus' },
  { 'DASH': 'Dash' },
  { 'DATA': 'DATAcoin' },
  { 'DAY': 'Chronologic' },
  { 'DBC': 'DeepBrain-Chain' },
  { 'DBIX': 'DubaiCoin' },
  { 'DCN': 'Dentacoin' },
  { 'DCR': 'Decred' },
  { 'DCT': 'DECENT' },
  { 'DDF': 'Digital-Developers-Fund' },
  { 'DENT': 'Dent' },
  { 'DFS': 'DFSCoin' },
  { 'DGB': 'DigiByte' },
  { 'DGC': 'Digitalcoin' },
  { 'DGD': 'DigixDAO' },
  { 'DICE': 'Etheroll' },
  { 'DLT': 'Agrello-Delta' },
  { 'DMD': 'Diamond' },
  { 'DMT': 'DMarket' },
  { 'DNT': 'district0x' },
  { 'DOGE': 'DogeCoin' },
  { 'DOPE': 'DopeCoin' },
  { 'DOT': 'Polkadot' },
  { 'DRGN': 'Dragonchain' },
  { 'DTA': 'Data' },
  { 'DTB': 'Databits' },
  { 'DYN': 'Dynamic' },
  { 'EAC': 'EarthCoin' },
  { 'EBST': 'eBoost' },
  { 'EBTC': 'eBTC' },
  { 'ECC': 'ECC' },
  { 'ECN': 'E-coin' },
  { 'EDG': 'Edgeless' },
  { 'EDO': 'Eidoo' },
  { 'EFL': 'Electronic-Gulden' },
  { 'EGC': 'EverGreenCoin' },
  { 'EGLD': 'Elrond' },
  { 'EKT': 'EDUCare' },
  { 'ELA': 'Elastos' },
  { 'ELEC': 'Electrify.Asia' },
  { 'ELF': 'aelf' },
  { 'ELIX': 'Elixir' },
  { 'EMB': 'Embercoin' },
  { 'EMC': 'Emercoin' },
  { 'EMC2': 'Einsteinium' },
  { 'ENG': 'Enigma' },
  { 'ENJ': 'Enjin-Coin' },
  { 'ENRG': 'EnergyCoin' },
  { 'EOS': 'EOS' },
  { 'EOT': 'EOT-Token' },
  { 'EQT': 'EquiTrader' },
  { 'ERC': 'EuropeCoin' },
  { 'ETC': 'Ethereum-Classic' },
  { 'ETH': 'Ethereum' },
  { 'ETHD': 'Ethereum-Dark' },
  { 'ETHOS': 'Ethos' },
  { 'ETN': 'Electroneum' },
  { 'ETP': 'Metaverse-Entropy' },
  { 'ETT': 'EncryptoTel' },
  { 'EVE': 'Devery' },
  { 'EVX': 'Everex' },
  { 'EXCL': 'ExclusiveCoin' },
  { 'EXP': 'Expanse' },
  { 'FCT': 'Factom' },
  { 'FIL': 'Filecoin' },
  { 'FLDC': 'FoldingCoin' },
  { 'FLO': 'FlorinCoin' },
  { 'FLT': 'FlutterCoin' },
  { 'FRST': 'FirstCoin' },
  { 'FTC': 'Feathercoin' },
  { 'FTT': 'FTX Token' },
  { 'FUEL': 'Etherparty' },
  { 'FUN': 'FunFair' },
  { 'GAM': 'Gambit' },
  { 'GAME': 'GameCredits' },
  { 'GAS': 'Gas' },
  { 'GBG': 'Golos Gold' },
  { 'GBX': 'GoByte' },
  { 'GBYTE': 'Byteball' },
  { 'GCR': 'GCRCoin' },
  { 'GEO': 'GeoCoin' },
  { 'GLD': 'GoldCoin' },
  { 'GNO': 'Gnosis-Token' },
  { 'GNT': 'Golem-Tokens' },
  { 'GOLOS': 'Golos' },
  { 'GRC': 'Gridcoin' },
  { 'GRT': 'Graph' },
  { 'GRS': 'Groestlcoin' },
  { 'GRWI': 'Growers-International' },
  { 'GTC': 'Game' },
  { 'GTO': 'Gifto' },
  { 'GUP': 'Guppy' },
  { 'GVT': 'Genesis-Vision' },
  { 'GXS': 'GXShares' },
  { 'HBAR': 'Hedera' },
  { 'HBN': 'HoboNickels' },
  { 'HEAT': 'HEAT' },
  { 'HMQ': 'Humaniq' },
  { 'HPB': 'High-Performance-Blockchain' },
  { 'HSR': 'Hshare' },
  { 'HT': 'Huobi Token' },
  { 'HUSH': 'Hush' },
  { 'HVN': 'Hive' },
  { 'HXX': 'HexxCoin' },
  { 'ICN': 'ICONOMI' },
  { 'ICX': 'ICON' },
  { 'IFC': 'Infinitecoin' },
  { 'IFT': 'investFeed' },
  { 'IGNIS': 'Ignis' },
  { 'INCNT': 'Incent' },
  { 'IND': 'Indorse-Token' },
  { 'INF': 'InfChain' },
  { 'INK': 'Ink' },
  { 'INS': 'INS-Ecosystem' },
  { 'INSTAR': 'Insights-Network' },
  { 'INT': 'Internet-Node-Token' },
  { 'INXT': 'Internxt' },
  { 'IOC': 'IOCoin' },
  { 'ION': 'ION' },
  { 'IOP': 'Internet-of-People' },
  { 'IOST': 'IOStoken' },
  { 'IOTA': 'IOTA' },
  { 'IOTX': 'IoTeX' },
  { 'IQT': 'Iquant-Chain' },
  { 'ITC': 'IoT-Chain' },
  { 'IXC': 'iXcoin' },
  { 'IXT': 'InsureX' },
  { 'J8T': 'JET8' },
  { 'JNT': 'Jibrel-Network' },
  { 'KCS': 'KuCoin' },
  { 'KICK': 'KickCoin' },
  { 'KIN': 'KIN' },
  { 'KLAY': 'Klaytn' },
  { 'KMD': 'Komodo' },
  { 'KNC': 'Kyber-Network' },
  { 'KORE': 'KoreCoin' },
  { 'KSM': 'Kusama' },
  { 'LBC': 'LBRY-Credits' },
  { 'LCC': 'Litecoin-Cash' },
  { 'LEND': 'EthLend' },
  { 'LEO': 'UNUS SED LEO' },
  { 'LEV': 'Leverj' },
  { 'LGD': 'Legends-Room' },
  { 'LINDA': 'Linda' },
  { 'LINK': 'ChainLink' },
  { 'LKK': 'Lykke' },
  { 'LMC': 'LoMoCoin' },
  { 'LOCI': 'LOCIcoin' },
  { 'LOOM': 'Loom-Token' },
  { 'LRC': 'Loopring' },
  { 'LSK': 'Lisk' },
  { 'LTC': 'Litecoin' },
  { 'LUN': 'Lunyr' },
  { 'LUNA': 'Terra' },
  { 'MAID': 'MaidSafeCoin' },
  { 'MANA': 'Decentraland' },
  { 'MATIC': 'Polygon' },
  { 'MAX': 'Maxcoin' },
  { 'MBRS': 'Embers' },
  { 'MCAP': 'MCAP' },
  { 'MCO': 'Monaco' },
  { 'MDA': 'Moeda-Loyalty-Points' },
  { 'MEC': 'Megacoin' },
  { 'MED': 'MediBlock' },
  { 'MEME': 'Memetic' },
  { 'MER': 'Mercury' },
  { 'MGC': 'MergeCoin' },
  { 'MGO': 'MobileGo' },
  { 'MINEX': 'Minex' },
  { 'MINT': 'Mintcoin' },
  { 'MIOTA': 'IOTA' },
  { 'MITH': 'Mithril' },
  { 'MKR': 'Maker' },
  { 'MLN': 'Melon' },
  { 'MNE': 'Minereum' },
  { 'MNX': 'MinexCoin' },
  { 'MOD': 'Modum' },
  { 'MONA': 'MonaCoin' },
  { 'MRT': 'Miners-Reward-Token' },
  { 'MSP': 'Mothership' },
  { 'MTH': 'Monetha' },
  { 'MTN': 'MedToken' },
  { 'MUE': 'MonetaryUnit' },
  { 'MUSIC': 'Musicoin' },
  { 'MYB': 'MyBit-Token' },
  { 'MYST': 'Mysterium' },
  { 'MZC': 'Mazacoin' },
  { 'NAMO': 'Namocoin' },
  { 'NANO': 'Nano' },
  { 'NAS': 'Nebulas-Token' },
  { 'NAV': 'Nav-Coin' },
  { 'NBT': 'NuBits' },
  { 'NCASH': 'Nucleus-Vision' },
  { 'NDC': 'NeverDie-Coin' },
  { 'NEBL': 'Neblio' },
  { 'NEO': 'NEO' },
  { 'NEOS': 'NeosCoin' },
  { 'NET': 'Nimiq' },
  { 'NLC2': 'NoLimitCoin' },
  { 'NLG': 'Gulden' },
  { 'NMC': 'Namecoin' },
  { 'NMR': 'Numeraire' },
  { 'NOBL': 'NobleCoin' },
  { 'NOTE': 'DNotes' },
  { 'NPXS': 'Pundi-X-Token' },
  { 'NSR': 'NuShares' },
  { 'NTO': 'Fujinto' },
  { 'NULS': 'Nuls' },
  { 'NVC': 'Novacoin' },
  { 'NXC': 'Nexium' },
  { 'NXS': 'Nexus' },
  { 'NXT': 'Nxt' },
  { 'OAX': 'openANX' },
  { 'OBITS': 'Obits' },
  { 'OCL': 'Oceanlab' },
  { 'OCN': 'Odyssey' },
  { 'ODEM': 'ODEM' },
  { 'ODN': 'Obsidian' },
  { 'OF': 'OFCOIN' },
  { 'OK': 'OKCash' },
  { 'OMG': 'OmiseGo' },
  { 'OMNI': 'Omni' },
  { 'ONION': 'DeepOnion' },
  { 'ONT': 'Ontology' },
  { 'OPT': 'Opus' },
  { 'ORN': 'Orion-Protocol' },
  { 'OST': 'Simple-Token' },
  { 'PART': 'Particl' },
  { 'PASC': 'PascalCoin' },
  { 'PAY': 'TenX' },
  { 'PBL': 'Pebbles' },
  { 'PBT': 'Primalbase-Token' },
  { 'PFR': 'Payfair' },
  { 'PING': 'CryptoPing' },
  { 'PINK': 'Pinkcoin' },
  { 'PIVX': 'PIVX' },
  { 'PIX': 'Lampix' },
  { 'PLBT': 'Polybius' },
  { 'PLR': 'Pillar' },
  { 'PLU': 'Pluton' },
  { 'POA': 'POA-Network' },
  { 'POE': 'Poet' },
  { 'POLY': 'Polymath' },
  { 'POSW': 'PoSW-Coin' },
  { 'POT': 'PotCoin' },
  { 'POWR': 'Power-Ledger' },
  { 'PPC': 'Peercoin' },
  { 'PPT': 'Populous' },
  { 'PPY': 'Peerplays' },
  { 'PRG': 'Paragon-Coin' },
  { 'PRL': 'Oyster-Pearl' },
  { 'PRO': 'Propy' },
  { 'PST': 'Primas' },
  { 'PTC': 'Pesetacoin' },
  { 'PTOY': 'Patientory' },
  { 'PURA': 'Pura' },
  { 'QASH': 'QASH' },
  { 'QAU': 'Quantum' },
  { 'QLC': 'Qlink' },
  { 'QRK': 'Quark' },
  { 'QRL': 'Quantum-Resistant-Ledger' },
  { 'QSP': 'Quantstamp' },
  { 'QTL': 'Quatloo' },
  { 'QTUM': 'Qtum' },
  { 'QUICK': 'Quickswap' },
  { 'QWARK': 'Qwark' },
  { 'R': 'Revain' },
  { 'RADS': 'Radium' },
  { 'RAIN': 'Condensate' },
  { 'RBIES': 'Rubies' },
  { 'RBX': 'Ripto-Bux' },
  { 'RBY': 'RubyCoin' },
  { 'RCN': 'Ripio-Credit-Network' },
  { 'RDD': 'ReddCoin' },
  { 'RDN': 'Raiden-Network-Token' },
  { 'REC': 'Regalcoin' },
  { 'RED': 'Redcoin' },
  { 'REP': 'Augur' },
  { 'REQ': 'Request-Network' },
  { 'RHOC': 'RChain' },
  { 'RIC': 'Riecoin' },
  { 'RISE': 'Rise' },
  { 'RLC': 'RLC-Token' },
  { 'RLT': 'RouletteToken' },
  { 'RPX': 'Red-Pulse' },
  { 'RRT': 'Recovery-Right-Tokens' },
  { 'RUFF': 'Ruff' },
  { 'RUNE': 'THORChain' },
  { 'RUP': 'Rupee' },
  { 'RVT': 'Rivetz' },
  { 'SAFEX': 'SafeExchangeCoin' },
  { 'SALT': 'Salt' },
  { 'SAN': 'Santiment-Network-Token' },
  { 'SBD': 'Steem-Dollars' },
  { 'SBTC': 'Super-Bitcoin' },
  { 'SC': 'Siacoin' },
  { 'SEELE': 'Seele' },
  { 'SEQ': 'Sequence' },
  { 'SHIB': 'SHIBA-INU' },
  { 'SHIFT': 'SHIFT' },
  { 'SIB': 'SIBCoin' },
  { 'SIGMA': 'SIGMAcoin' },
  { 'SIGT': 'Signatum' },
  { 'SJCX': 'Storjcoin-X' },
  { 'SKIN': 'SkinCoin' },
  { 'SKY': 'Skycoin' },
  { 'SLR': 'SolarCoin' },
  { 'SLS': 'SaluS' },
  { 'SMART': 'SmartCash' },
  { 'SMT': 'SmartMesh' },
  { 'SNC': 'SunContract' },
  { 'SNGLS': 'SingularDTV' },
  { 'SNM': 'SONM' },
  { 'SNRG': 'Synergy' },
  { 'SNT': 'Status-Network-Token' },
  { 'SOC': 'All-Sports' },
  { 'SOL': 'Solana' },
  { 'SOUL': 'Phantasma' },
  { 'SPANK': 'SpankChain' },
  { 'SPC': 'SpaceChain' },
  { 'SPHR': 'Sphere' },
  { 'SPR': 'SpreadCoin' },
  { 'SNX': 'Synthetix-Network-Token' },
  { 'SRN': 'Sirin-Labs-Token' },
  { 'START': 'Startcoin' },
  { 'STEEM': 'Steem' },
  { 'STK': 'STK-Token' },
  { 'STORJ': 'Storj' },
  { 'STORM': 'Storm' },
  { 'STQ': 'Storiqa' },
  { 'STRAT': 'Stratis' },
  { 'STX': 'Stox' },
  { 'SUB': 'Substratum' },
  { 'SWFTC': 'SwftCoin' },
  { 'SWIFT': 'Bitswift' },
  { 'SWT': 'Swarm-City' },
  { 'SYNX': 'Syndicate' },
  { 'SYS': 'SysCoin' },
  { 'TAAS': 'Taas' },
  { 'TAU': 'Lamden' },
  { 'TCC': 'The-ChampCoin' },
  { 'TFL': 'True-Flip' },
  { 'THC': 'HempCoin' },
  { 'THETA': 'Theta-Token' },
  { 'TIME': 'Time' },
  { 'TIX': 'Blocktix' },
  { 'TKN': 'TokenCard' },
  { 'TKR': 'Trackr' },
  { 'TKS': 'Tokes' },
  { 'TNB': 'Time-New-Bank' },
  { 'TNT': 'Tierion' },
  { 'TOA': 'ToaCoin' },
  { 'TRAC': 'OriginTrail' },
  { 'TRC': 'Terracoin' },
  { 'TRCT': 'Tracto' },
  { 'TRIBE': 'Tribe' },
  { 'TRIG': 'Triggers' },
  { 'TRST': 'Trustcoin' },
  { 'TRUE': 'TrueChain' },
  { 'TRUST': 'TrustPlus' },
  { 'TRX': 'Tronix' },
  { 'TUSD': 'TrueUSD' },
  { 'TX': 'TransferCoin' },
  { 'UBQ': 'Ubiq' },
  { 'UKG': 'UnikoinGold' },
  { 'ULA': 'Ulatech' },
  { 'UNB': 'UnbreakableCoin' },
  { 'UNI': 'Uniswap' },
  { 'UNITY': 'SuperNET' },
  { 'UNO': 'Unobtanium' },
  { 'UNY': 'Unity-Ingot' },
  { 'UP': 'UpToken' },
  { 'URO': 'Uro' },
  { 'USDT': 'Tether' },
  { 'UST': 'TerraUSD' },
  { 'UTK': 'UTrust' },
  { 'VEE': 'BLOCKv' },
  { 'VEN': 'VeChain' },
  { 'VERI': 'Veritaseum' },
  { 'VET': 'VeChain' },
  { 'VIA': 'Viacoin' },
  { 'VIB': 'Viberate' },
  { 'VIBE': 'Vibe' },
  { 'VIVO': 'VIVO' },
  { 'VOISE': 'Voise' },
  { 'VOX': 'Voxels' },
  { 'VPN': 'VPNCoin' },
  { 'VRC': 'Vericoin' },
  { 'VRM': 'Verium' },
  { 'VRS': 'Veros' },
  { 'VSL': 'vSlice' },
  { 'VTC': 'Vertcoin' },
  { 'VTR': 'vTorrent' },
  { 'WABI': 'WaBi' },
  { 'WAN': 'Wanchain' },
  { 'WAVES': 'Waves' },
  { 'WAX': 'Wax-Token' },
  { 'WBTC': 'Wrapped Bitcoin' },
  { 'WCT': 'Waves-Community' },
  { 'WDC': 'WorldCoin' },
  { 'WGO': 'WavesGo' },
  { 'WGR': 'Wagerr' },
  { 'WINGS': 'Wings' },
  { 'WPR': 'WePower' },
  { 'WTC': 'Walton' },
  { 'WTT': 'Giga-Watt-Token' },
  { 'XAS': 'Asch' },
  { 'XAUR': 'Xaurum' },
  { 'XBC': 'Bitcoin-Plus' },
  { 'XBY': 'XtraBYtes' },
  { 'XCN': 'Cryptonite' },
  { 'XCP': 'Counterparty' },
  { 'XDN': 'DigitalNote' },
  { 'XEL': 'Elastic' },
  { 'XEM': 'NEM' },
  { 'NEM': 'NEM' },
  { 'XHV': 'Haven-Protocol' },
  { 'XID': 'Sphere-Identity' },
  { 'XLM': 'Stellar' },
  { 'XMG': 'Magi' },
  { 'XMR': 'Monero' },
  { 'XMT': 'Metal' },
  { 'XMY': 'Myriadcoin' },
  { 'XPM': 'Primecoin' },
  { 'XRL': 'Rialto' },
  { 'XRP': 'Ripple' },
  { 'XSPEC': 'Spectrecoin' },
  { 'XST': 'Stealthcoin' },
  { 'XTZ': 'Tezos' },
  { 'XUC': 'Exchange-Union' },
  { 'XVC': 'Vcash' },
  { 'XVG': 'Verge' },
  { 'XWC': 'WhiteCoin' },
  { 'XZC': 'ZCoin' },
  { 'XZR': 'ZrCoin' },
  { 'YEE': 'Yee' },
  { 'YOYOW': 'YOYOW' },
  { 'ZCC': 'ZcCoin' },
  { 'ZCL': 'Zclassic' },
  { 'ZCO': 'Zebi' },
  { 'ZEC': 'Zcash' },
  { 'ZEN': 'ZenCash' },
  { 'ZET': 'Zetacoin' },
  { 'ZIL': 'Zilliqa' },
  { 'ZLA': 'Zilla' },
  { 'ZRX': '0x' }
].map(c => Object.keys(c).map(x => ({ code: x, display: x + ' - ' + c[x] }))).map(x => x[0]);
