<div class="scenario-manage-tab">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <!-- <div class="graph-container grid-system"
         style="--grid-size: {{ selectedGraphs.length }}">
      <div *ngFor="let graphOpts of selectedGraphs">
        <indicio-alg [periodicityType]="fVersion.Periodicity"
                     [HistoricLine]="graphOpts.Historic"
                     [Lines]="[graphOpts.Line]"
                     [options]="graphOpts.Options"
                     [chartStyles]="chartStyles"
                     [showExport]="false"
                     graphType="generic">
        </indicio-alg>
      </div>
    </div> -->

    <div class="issues"
         *ngIf="issuesFound">
      <ng-container *ngIf="noResultsFound === false && oldForecastVariablesFound.length">
        The scenario is using a variable that has either been removed from the forecast or is inactivated. <br>
        Please remove the highlighted variable(s) and recalculate the scenario.
      </ng-container>
      <ng-container *ngIf="noResultsFound === true">
        <p>None of the models were able to produce a forecast for the scenario, this may be caused by model instability
          or a scenario that deviates too much from the original forecast.
          The scenario needs editing. Please do one of the following:
        </p>
      </ng-container>
    </div>

    <div class="warnings"
         *ngIf="tooManySelections">
      Only 5 variables can be viewed simultaneously.
    </div>

    <div class="variable-container"
         style="--grid-template-global: 250px 1fr;"
         *ngIf="scenario._tempVariables.length; else noVariablesTemplate">
      <div class="grid-system">
        <div>List of added variables</div>
        <div>Values</div>
      </div>
      <ng-container *ngTemplateOutlet="variablesTemplate"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #noVariablesTemplate>
  <div class="no-variables">
    No variables added yet, click below to get started.
    <div class="scenario-btn add-variable"
         (click)="addVariable()">+ Add variable</div>
  </div>
</ng-template>

<ng-template #variablesTemplate>
  <div class="grid-system">
    <div class="list-container">
      <div class="variable-list"
           style="max-height: 263px; overflow: auto;"
           (scroll)="scrollPosition = $event.target.scrollTop">
        <div class="variable grid-system cursor-pointer"
             style="--grid-template-global: {{ variable.isModified ? '13px 1fr 30px 19px' : '13px 1fr 19px' }};"
             *ngFor="let variable of scenario._tempVariables; let index = index"
             [ngClass]="{ selected: selectedVariableIndex.includes(index), red: oldForecastVariablesFound.includes(variable.Item.ForecastVariableId) }">
          <div>
            <input style="margin: 4px 0 0;"
                   type="checkbox"
                   [checked]="selectedVariableIndex.includes(index)"
                   (change)="toggleSelectedVariable(index, $event)">
          </div>
          <div class="crop-string"
               (click)="setActiveVariable(index)">{{ index + 1 }}. {{ variable.Item.Name }}</div>
          <div class="dotted-underline"
               style="margin-top: -3px; font-variant: small-caps;"
               *ngIf="variable.isModified"
               (click)="resetVariableValues(variable)">
            reset
          </div>
          <div>
            <indicio-options-menu fillerTop="-33%"
                                  [nohover]="true"
                                  iconsize="21px"
                                  [containerScrolled]="scrollPosition"
                                  [filler]="true">
              <ul>
                <li (click)="addForecastedValuesForVariable(variable)">
                  <fa-icon [icon]="addIcon"></fa-icon>&nbsp;Add forecasted values
                </li>
                <li (click)="addHistoricValuesForVariable(variable)">
                  <fa-icon [icon]="undoIcon"></fa-icon>&nbsp;Restore from source
                </li>
                <li divider></li>
                <li (click)="removeVariable(variable)">
                  <fa-icon class="red"
                           [icon]="removeIcon"></fa-icon>&nbsp;Remove
                </li>
              </ul>
            </indicio-options-menu>
          </div>
        </div>
      </div>
      <div class="scenario-btn add-variable"
           [ngClass]="{ disabled: variablesLeft === 0 }"
           (click)="addVariable()">+ Add variable</div>
    </div>

    <div class="variable-content"
         style="--grid-template-global: 90px repeat({{ selectedVariables.length }}, 1fr)">
      <div>
        <div class="grid-system">
          <div>Dates</div>
          <div *ngFor="let variable of selectedVariables; let index = index">
            <span class="cursor-default"
                  [indicioTippy]="variable.Item.Name">Variable {{ getIndexOfSelectedVariable(variable.Item) + 1
              }}</span>
          </div>
        </div>
        <div style="max-height: 253px; overflow: auto;">
          <div class="grid-system">
            <div class="dates">
              <div class="date"
                   *ngFor="let date of dates">
                {{ date | dateFormat: fVersion.Periodicity }}
              </div>
            </div>
            <div class="variables"
                 *ngFor="let variable of selectedVariables">
              <div *ngFor="let x of dates; let valueIndex = index">
                <indicio-number-input [value]="variable.Item.Values[valueIndex]"
                                      (valueEvent)="setValueOnVariable(variable.Item, valueIndex, $event)"
                                      [enablePaste]="true"
                                      (pasteEvent)="handlePaste(getVariableIndex(variable.Item), valueIndex, $event)"></indicio-number-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="scenario-btn"
             (click)="addForecastedValuesForSelectedVariables()">Add forecasted values</div>
        <div class="scenario-btn"
             (click)="addHistoricValuesForSelectedVariables()">Restore from source</div>
      </div>
    </div>
  </div>
</ng-template>



<ng-template #loadingTemplate>
  <div><indicio-spinner></indicio-spinner> Loading...</div>
</ng-template>
