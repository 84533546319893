<div class="fcast-info-date-settings-tab">

  <ng-container *ngIf="forecastVersion?.ForecastVariable; else noMainTemplate">
    <div *ngIf="dateRangeOptions"
         class="range-container">
      <indicio-date-range [options]="dateRangeOptions"
                          (changed)="setChanged($event)"></indicio-date-range>
    </div>
  </ng-container>

</div>

<ng-template #noMainTemplate>
  <div style="background-color: rgb(222 192 101); padding: 7px; margin: 20px 0; color: black; text-align: center;"
       *ngIf="canUpdateForecast">
    You need to <span class="dotted-underline pointer"
          (click)="gotoSourceVariables()">add a main variable</span> to be able to see the date range.
  </div>
</ng-template>
