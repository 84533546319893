<h1 mat-dialog-title>Title</h1>
<div mat-dialog-content
     class="pages-collection-container">

  <mat-form-field>
    <mat-label>Filter list</mat-label>
    <input type="text"
           matInput
           required
           (keyup)="filter($any($event.target).value)"
           #filterInput
           class="material">
    <button mat-button
         *ngIf="filterInput.value"
         matSuffix
         mat-icon-button
         aria-label="Clear"
         (click)="filterInput.value=''; filterRegex = null">
      <mat-icon><i class="ion-close cursor-pointer"></i></mat-icon>
    </button>
  </mat-form-field>

  <div class="grid-system"
       style="--grid-template: 1fr 200px">
    <div class="bold">Title</div>
    <div class="bold">Parent</div>
  </div>
  <div class="grid-system hover cursor-pointer"
       style="--grid-template: 1fr 200px"
       *ngFor="let page of pages | filter: 'Title': filterRegex"
       (click)="usePage(page)">
    <div class="crop-string">{{ page.Title }}</div>
    <div class="crop-string">{{ getPageParent(page)?.Title }}</div>
  </div>
</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-stroked-button
          (click)="onNoClick()">
    Close
  </button>
</div>
