<div class="model-prop-modal-container">
  <h1 mat-dialog-title
      style="margin-bottom: 10px;">{{ data.groupName }}
    <div class="subtitle">{{ modelName.Text }}</div>
  </h1>
  <div mat-dialog-content>
    <ng-container *ngIf="loading">
      <div class="row">
        <div class="col-xs-12 vertical-align"
             style="height:400px;text-align: center;">
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading">
      <!-- Model description -->
      <div class="row"
           style="margin-bottom: 20px;">
        <div class="col-xs-12">
          <div *ngIf="modelName">
            <div [ngClass]="{'crop-string': !seeMore}">
              {{ modelName.Description }}
            </div>
            <span class="pointer italic"
                  style="margin-top: 10px; display: block"
                  *ngIf="showSeeMore(modelName.Description);"
                  (click)="clickSeeMore();">{{ !seeMore ? 'Show full description' : 'Hide full description' }} <i
                 [class]="!seeMore ? 'ion-arrow-down-b' : 'ion-arrow-up-b'"></i></span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!noSimplifiedModelFound">
        <!-- Settings -->
        <ng-container *ngTemplateOutlet="modelSettingsTemplate"></ng-container>

        <div class="row margin-top">
          <div class="col-xs-12">
            <ng-container [ngSwitch]="selectedDataset">
              <ng-container *ngSwitchCase="'full'">
                <ng-container *ngTemplateOutlet="propertiesTemplate; context: { decodedString: fullModelProperties }">
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'train'">
                <ng-container *ngTemplateOutlet="propertiesTemplate; context: { decodedString: trainModelProperties }">
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'rw'">
                <ng-container *ngTemplateOutlet="propertiesTemplate; context: {
                decodedString: selectedRollingModelProperties.decoded, date: selectedRollingModelProperties.Date}">
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>

      </ng-container>

      <div class="row margin-top"
           *ngIf="noSimplifiedModelFound">
        <div class="col-xs-12">
          <div class="display-box">
            <div style="height:25vh;display: flex; justify-content: center;"
                 class="vertical-align">
              <h2>{{message}}</h2>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button
            (click)="onNoClick()">
      Close
    </button>
    <button *ngIf="this.data.isAdmin"
            mat-stroked-button
            (click)="recalculate()">
      Recalculate
    </button>
  </div>
</div>

<ng-template #propertiesTemplate
             let-decodedString="decodedString"
             let-date="date">
  <div class="row"
       *ngIf="!loadingTextFiles && mathJaxReady && (fullModelProperties && selectedDataset === 'full'
  || trainModelProperties && selectedDataset === 'train'
  || rollingModelProperties && selectedDataset === 'rw')">
    <div class="col-xs-12">
      <div class="display-box">
        <i class="ion-arrow-resize fullsize-arrow h-grey pointer"
           (click)="openFullSizeWindow(decodedString)"></i>
        <div [indicioMathJax]="decodedString">
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(loadingTextFiles || !mathJaxReady) && decodedString"
       class="row">
    <div class="col-xs-12">
      <div class="display-box">
        <div style="height:25vh;display: flex; justify-content: center;">
          <li class="ion-load-c spinner"
              style="font-size:25px;align-self: center;">
          </li>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #notFound>
  <div class="row">
    <div class="col-xs-12">
    </div>
  </div>
</ng-template>

<ng-template #modelSettingsTemplate>
  <div class="model-settings-box"
       [ngClass]="{ 'no-model': !selectedModel.ModelBuilt }">
    <div class="left">
      <div class="setting">
        <div>Transformation</div>
        <div *ngIf="!isNaive"
             class="transformation-row ">
          <div *ngFor="let opt of modelOptions"
               class="model-selection"
               [ngClass]="{ 'selected': selectedOption.Display === opt.Display }"
               (click)="selectModel(opt); onUpdate()">
            <span>{{ opt.Display }}</span>
            <i [ngClass]="opt.ModelBuilt ? 'ion-ios-checkmark' : 'ion-close-circled'"
               [ngStyle]="{ 'color': opt.ModelBuilt ? opt.Color : '#cecece' }">
            </i>
          </div>
        </div>
        <div *ngIf="isNaive"
             style="width: 150px;">
          <span *ngIf="isNaive">No transformation</span>
        </div>
      </div>
      <div class="setting"
           *ngIf="datasetOptions.length > 0">
        <div>Dataset</div>
        <div style="width: 162px;">
          <mat-form-field>
            <mat-select required
                        (selectionChange)="selectDataset($event);"
                        (closed)="reRender()"
                        [value]="selectedDataset">
              <mat-option [value]="opt.Value"
                          [title]="opt.Display"
                          *ngFor="let opt of datasetOptions; let index = index;">{{ opt.Display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="setting">
        <div>Messages</div>
        <div>
          <ng-container *ngIf="!noMessages(); else noMessagesTemplate">
            <div *ngFor="let msg of selectedModel.InfoMessages">
              - {{msg}}
            </div>
          </ng-container>
          <ng-template #noMessagesTemplate>No messages.</ng-template>
        </div>
      </div>
    </div>
    <div class="right"
         *ngIf="selectedModel.ModelBuilt">
      <div class="setting">
        <span class="dotted-underline pointer"
              (click)="openResidualAnalysis()"
              *ngIf="this.residualFiles.length">
          Show residual analysis
        </span>
      </div>

      <div class="setting">
        <span class="dotted-underline pointer"
              (click)="openShapValues(selectedModel)"
              [indicioTippy]="selectedModel.ShapValues?.length > 0 ? null : 'You need to enable shap values in forecast settings and/or recalculate the results'"
              [ngClass]="selectedModel.ShapValues?.length > 0 ? null : 'disabled'">
          Show SHAP values
        </span>
      </div>
    </div>
  </div>
</ng-template>
