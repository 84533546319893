import { Injectable } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';
import { UUIdUtils } from '@shared/utils/uuid.utils';
import { CreateHistoricBaseEventDTO, HistoricBaseEventDTO, UpdateHistoricBaseEventDTO } from './models/base/historic-base-event.dto';
import { CreateHistoricEventDTO, HistoricEventDTO, UpdateHistoricEventDTO } from './models/base/historic-event.dto';
import { HistoricBaseEventModel, HistoricEventModel } from './models/base/historic-event.model';
import { CreateImportedHistoricBaseEventDTO, CreateImportedHistoricEventDTO, ImportedHistoricBaseEventDTO, ImportedHistoricEventDTO, UpdateImportedHistoricBaseEventDTO } from './models/imported/imported-historic-base-event.dto';
import { ImportedHistoricBaseEventModel, ImportedHistoricEventCorrelationModel, ImportedHistoricEventModel } from './models/imported/imported-historic-base-event.model';


@Injectable({
  providedIn: 'root'
})
export class HistoricEventMapper {

  constructor() { }

  public mapBase(dto: HistoricBaseEventDTO): HistoricBaseEventModel {
    const model = Object.faMapTo(new HistoricBaseEventModel(), dto);
    model.Events = dto.Events.map(e => this.mapEvent(e));
    const sorted = model.Events.sort((a, b) => {
      if (!a.Date || !b.Date) { return 0; }
      return a.Date.isSame(b.Date)
        ? 0
        : a.Date.isAfter(b.Date)
          ? 1
          : -1;
    });
    model.Events = sorted;
    return model;
  }

  public mapEvent(dto: HistoricEventDTO): HistoricEventModel {
    const model = Object.faMapTo(new HistoricEventModel(), dto);
    model.Date = DateUtils.newMoment(dto.Date);
    if (dto.EndDate) {
      model.EndDate = DateUtils.newMoment(dto.EndDate);
    }
    return model;
  }

  public toCreateBase(model: HistoricBaseEventModel): CreateHistoricBaseEventDTO {
    const dto = Object.faMapTo(new CreateHistoricBaseEventDTO, model);
    dto.Events = model.Events.map(e => this.toCreateEvent(e));
    return dto;
  }

  public toCreateEvent(model: HistoricEventModel): CreateHistoricEventDTO {
    const dto = Object.faMapTo(new CreateHistoricEventDTO, model);

    dto.Date = DateUtils.convertToBackendDate(DateUtils.newMoment(model.Date));
    if (model.EndDate) {
      dto.EndDate = DateUtils.convertToBackendDate(DateUtils.newMoment(model.EndDate));
    }

    return dto;
  }

  public toUpdateBase(model: HistoricBaseEventModel): UpdateHistoricBaseEventDTO {
    const dto = Object.faMapTo(new UpdateHistoricBaseEventDTO, model);

    const updatedEvents = model.Events.filter(x => x.HistoricEventId);
    dto.UpdateEvents = updatedEvents.map(x => this.toUpdateEvent(x));

    const createdEvents = model.Events.filter(x => !x.HistoricEventId);
    if (createdEvents.length) {
      dto.CreateEvents = createdEvents.map(x => this.toCreateEvent(x));
    }

    return dto;
  }

  public toUpdateEvent(event: HistoricEventModel): UpdateHistoricEventDTO {
    const dto = Object.faMapTo(new UpdateHistoricEventDTO, event);
    if (typeof dto.Date === 'string') {
      event.Date = DateUtils.newMoment(dto.Date);
    }
    dto.Date = DateUtils.convertToBackendDate(event.Date);
    if (event.EndDate) {
      dto.EndDate = DateUtils.convertToBackendDate(event.EndDate);
    }
    return dto;
  }

  /**
   * Imported part
   */
  public toImportToForecastDTO(event: ImportedHistoricBaseEventModel) {
    const dto = Object.faMapTo(new CreateImportedHistoricBaseEventDTO, event);
    dto.Events = this.toCreateEventDtos(event.Events);
    return dto;
  }

  public toUpdateInForecastDTO(event: ImportedHistoricBaseEventModel) {
    const dto = Object.faMapTo(new UpdateImportedHistoricBaseEventDTO, event);
    dto.Events = this.toCreateEventDtos(event.Events);
    return dto;
  }

  private toCreateEventDtos(events: ImportedHistoricEventModel[]) {
    return events.map(e => {
      const eDto = Object.faMapTo(new CreateImportedHistoricEventDTO, e);
      eDto.Date = DateUtils.convertToBackendDate(DateUtils.newMoment(eDto.Date));
      if (eDto.EndDate) {
        eDto.EndDate = DateUtils.convertToBackendDate(DateUtils.newMoment(eDto.EndDate));
      }
      eDto.Correlations = e.Correlations;
      return eDto;
    });
  }

  public mapImportedBase(dto: ImportedHistoricBaseEventDTO) {
    const model = Object.faMapTo(new ImportedHistoricBaseEventModel(), dto);
    model.Events = dto.Events.map(e => this.mapImportedEvent(e));
    const sorted = model.Events.sort((a, b) => {
      if (!a.Date || !b.Date) { return 0; }
      return a.Date.isSame(b.Date)
        ? 0
        : a.Date.isAfter(b.Date)
          ? 1
          : -1;
    });
    model.Events = sorted;
    return model;
  }

  public mapImportedEvent(dto: ImportedHistoricEventDTO) {
    const model = Object.faMapTo(new ImportedHistoricEventModel(), dto);
    model.Date = DateUtils.newMoment(dto.Date);
    if (dto.EndDate) {
      model.EndDate = DateUtils.newMoment(dto.EndDate);
    }
    return model;
  }

  public mapHistoricToImported(historic: HistoricBaseEventModel) {
    const imported = Object.faMapTo(new ImportedHistoricBaseEventModel, historic);
    imported.HistoricBaseEventId = historic.HistoricBaseEventId;
    imported.ImportedHistoricBaseEventId = UUIdUtils.GetNewIdV4();
    imported.Events = historic.Events.map(x => {
      const event = Object.faMapTo(new ImportedHistoricEventModel, x);
      event.ImportedHistoricBaseEventId = imported.ImportedHistoricBaseEventId;
      event.ImportedHistoricEventId = UUIdUtils.GetNewIdV4();
      event.Date = x.Date.clone();
      return event;
    });
    return imported;
  }

  public mapToImportedCopy(old: ImportedHistoricBaseEventModel) {
    const copy = Object.faMapTo(new ImportedHistoricBaseEventModel, old);
    copy.Events = old.Events.map(x => {
      const event = Object.faMapTo(new ImportedHistoricEventModel, x);
      event.Date = x.Date.clone();
      if (x.EndDate) {
        event.EndDate = x.EndDate.clone();
      }
      event.Correlations = [...event.Correlations.map(z => Object.faMapTo(new ImportedHistoricEventCorrelationModel, z))];
      return event;
    });
    return copy;
  }
}
