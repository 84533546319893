import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentService } from '@core/services/environment/environment.service';
import { FredCategoryListDTO } from '@core/store/providers/fred/dtos/fred-category-list.dto';
import { FredDataSerieListDTO } from '@core/store/providers/fred/dtos/fred-dataserie-list.dto';
import { FredObservationsDTO } from '@core/store/providers/fred/dtos/fred-observations.dto';
import { FredTagListDTO } from '@core/store/providers/fred/dtos/fred-tags.dto';
import { FredMapper } from '@core/store/providers/fred/fred.mapper';

export type FredObservationFrequecy = 'd' | 'wesu' | 'w' | 'm' | 'q' | 'a';
export type FredObservationAggregation = 'avg' | 'sum' | 'eop';

export class FredObservationOptions {
  series_id: string;
  frequency: FredObservationFrequecy;
  aggregation_method: FredObservationAggregation;
}

@Injectable({
  providedIn: 'root'
})
export class FredBackendService {
  private baseUrl = 'https://api.stlouisfed.org/fred';
  private APIKey: string;

  currentReportUrl: string;

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private mapper: FredMapper
  ) { }

  public setAPIKey(key: string) {
    this.APIKey = key;
  }

  private getUrl(path: string, withProxy = true) {
    const questionMarkOrAmp = path.includes('?') ? '&' : '?';
    const url = `${this.baseUrl}/${path}${questionMarkOrAmp}api_key=${this.APIKey}&file_type=json`;

    if (withProxy) {
      return `${this.envService.env.CorsProxy.replace(/\/$/, '')}/${url}`;
    }

    return url;
  }

  public getCategories(category: number = 0) {
    return this.http.get<FredCategoryListDTO>(this.getUrl(`category/children?category_id=${category}`)).toPromise()
      .then(categoriesList => this.mapper.mapCategories(categoriesList));
  }

  public getCategoryTags(category: number = 0) {
    return this.http.get<FredTagListDTO>(this.getUrl(`category/tags?category_id=${category}`)).toPromise()
      .then(tagsList => this.mapper.mapTags(tagsList));
  }

  public getDataSeries(category: number = 0) {
    return this.http.get<FredDataSerieListDTO>(this.getUrl(`category/series?category_id=${category}`)).toPromise()
      .then(dataSeriesList => this.mapper.mapSeries(dataSeriesList));
  }

  public getObservations(dto: FredObservationOptions) {
    const url = this.getUrl(`series/observations?${dto.faJoin('=', '&')}`);
    return this.http.get<FredObservationsDTO>(url).toPromise()
      .then(observations => this.mapper.mapObservations(observations, url));
  }

  public getTags() {
    const url = this.getUrl('tags?order_by=popularity&sort_order=desc&limit=50');
    return this.http.get<FredTagListDTO>(url).toPromise()
      .then(tags => this.mapper.mapTags(tags));
  }

  public getRelatedTags() {
    const url = this.getUrl('tags?order_by=popularity&sort_order=desc&limit=100');
    return this.http.get<FredTagListDTO>(url).toPromise()
      .then(taglist => this.mapper.mapTags(taglist));
  }

  public searchSeries(query: string) {
    query = encodeURI(query);
    return this.http.get<FredDataSerieListDTO>(this.getUrl(`series/search?search_text=${query}`)).toPromise()
      .then(dataSeriesList => this.mapper.mapSeries(dataSeriesList));
  }

  public getRelatedSearchTags(query: string) {
    query = encodeURI(query);
    return this.http.get<FredTagListDTO>(this.getUrl(`series/search/related_tags?series_search_text=${query}&tag_names=30-year;frb`)).toPromise()
      .then(tags => this.mapper.mapTags(tags));
  }

  public testApiKey(key) {
    let url = `${this.baseUrl}/category?category_id=0&api_key=${key}&file_type=json`;
    url = `${this.envService.env.CorsProxy.replace(/\/$/, '')}/${url}`;
    return this.http.get<any>(url).toPromise();
  }
}
