<div class="user-info-general-tab grid-system"
     style="--grid-template: 1fr 1fr; gap: 10px">
  <ng-container *ngTemplateOutlet="leftTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="rightTemplate"></ng-container>
</div>

<br>
<br>

<ng-template #leftTemplate>
  <div style="--grid-template-global: 0.5fr 1fr; gap: 5px; border-right: 1px solid black;">
    <div class="grid-system">
      <div class="bold">Name:</div>
      <div>{{ client.Name }}</div>
    </div>
    <div class="grid-system">
      <div class="bold">Email:</div>
      <div>{{ client.Email }}</div>
    </div>
    <div class="grid-system">
      <div class="bold">ClientId:</div>
      <div>{{ client.ClientId }}</div>
    </div>
    <div class="grid-system">
      <div class="bold">Invited by:</div>
      <div>{{ clientInfo.InvitedBy }}</div>
    </div>
    <div class="grid-system">
      <div class="bold">Created Date:</div>
      <div>{{ client.Profile.CreatedDate | dateFormat }}</div>
    </div>
    <ng-container *ngIf="securityInfo">
      <div class="grid-system"
           *ngIf="!!clientInfo">
        <div class="bold">2FA enabled:</div>
        <div>{{ securityInfo.MfaEnabled ? 'Yes' : 'No' }}</div>
      </div>
      <div class="grid-system"
           *ngIf="securityInfo.ForceMfaFrom && !securityInfo.MfaEnabled">
        <div class="bold">2FA grace period:</div>
        <div>
          <span [class]="isGracePeriodWithinRange(securityInfo.ForceMfaFrom) ? 'green' : 'red'">
            {{ securityInfo.ForceMfaFrom | dateFormat }}
          </span>
          <div style="display: inline;"
               *ngIf="!isGracePeriodWithinRange(securityInfo.ForceMfaFrom)">
            (expired - <span class="dotted-underline cursor-pointer"
                  (click)="bump2FaGracePeriod()">bump</span>)
          </div>
        </div>
      </div>
    </ng-container>

    <div style="width: 200px;">
      <indicio-modal-option-row [stacked]="true"
                                optionTitle="System roles"
                                textWidth="4"
                                *ngIf="securityInfo">

        <mat-form-field>
          <mat-select multiple
                      required
                      [(value)]="securityInfo.Roles"
                      (selectionChange)="checkRoles()">
            <mat-option [value]="p.Value"
                        [disabled]="p.Value === 'sysadmin' && profile.profile.ClientId === securityInfo.ClientId"
                        [title]="p.Display"
                        *ngFor="let p of availableRoles">{{ p.Display }}
            </mat-option>

          </mat-select>
          <mat-hint *ngIf="saveError?.length">{{ saveError }}</mat-hint>
          <mat-hint style="line-height: 12px;"
                    *ngIf="profile.profile.ClientId === securityInfo.ClientId">Sys admins cannot remove him/herself from
            being an admin.</mat-hint>
        </mat-form-field>
      </indicio-modal-option-row>
    </div>

    <div style="margin-top: 30px;">
      <button mat-flat-button
              (click)="toggleDisableUser()"
              [color]="client.Disabled ? 'primary' : 'warn'">{{ client.Disabled ? 'Enable' : 'Disable' }}
        user</button>
      <button mat-stroked-button
              *ngIf="securityInfo.MfaEnabled"
              (click)="disable2faForUser()"
              style="float: right; margin-right: 10px;">Remove MFA</button>
    </div>
  </div>
</ng-template>

<ng-template #rightTemplate>
  <div style="--grid-template-global: 1fr 0.5fr; gap: 5px">
    <h3 style="margin-bottom: 5px;">Companies</h3>
    <div class="grid-system">
      <div class="bold">Company</div>
      <div class="bold">Role</div>
    </div>
    <div class="grid-system"
         *ngFor="let company of clientInfo.Companies">
      <div>{{ company.CompanyName }}</div>
      <div>{{ envService.getCompanyRole(company.Role).Display }}</div>
    </div>

    <h3 style="margin: 10px 0 5px 0;">Data providers</h3>
    <div class="grid-system">
      <div class="bold">Source</div>
      <div class="bold">Created</div>
    </div>
    <div class="grid-system"
         *ngFor="let provider of clientInfo.Providers">
      <div>{{ provider.Provider }}</div>
      <div>{{ provider.CreatedDate | dateFormat }}</div>
    </div>
  </div>
</ng-template>
