import { Component, HostListener } from '@angular/core';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { Store } from '@ngxs/store';
import { ConfirmModalOpts } from './confirm-modal.options';

@Component({
  selector: 'indicio-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent extends ModalModelComponent {

  title = '';
  message = '';
  decline = 'Decline';
  confirm = 'Confirm';
  negativeEffect = false;
  disableYes = false;

  constructor(
    protected store: Store
  ) {
    super();
  }

  public declineFn() {
    this.close();
    setTimeout(() => {
      this.back();
    }, 1);
  }

  public confirmFn() {
    this.close();
    setTimeout(() => {
      this.proceed();
    }, 1);
  }

  back() { }

  @HostListener('document:keyup.enter', ['$event'])
  handleKeyboardEvent() { this.proceed(); this.close(); }

  setOptions(options: ConfirmModalOpts) {
    this.title = options.title;
    this.message = options.message;
    this.confirm = options.confirm;
    this.negativeEffect = options.negativeEffect ? options.negativeEffect : this.negativeEffect;
    this.decline = options.decline;
    this.proceed = options.proceed;
    this.back = options.back ? options.back : this.back;

    if (options.onClose) {
      this.onClose = options.onClose;
    } else if (options.back && !options.onClose) {
      this.onClose = options.back;
    }

    this.disableYes = options.disableYes;
  }
}
