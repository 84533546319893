import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFilterDropdown } from '@core/interfaces/if-filter-dropdown';
import { ComponentFilter, FilterService } from '@core/services/frontend/filter.service';
import { ForecastTemplateFrontendService } from '@core/store/forecast-template/forecast-template.frontend.service';
import { ForecastTemplate } from '@core/store/forecast-template/forecast-template.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProjectModel } from '@core/store/project/project.model';
import { TagTypes } from '@core/store/tags/dtos/tags.dtos';
import { ForecastDialogService } from '@dialogs/forecast/forecast-dialogs.service';
import { IDialogFilter } from '@dialogs/generic/filter/filter.dialog.constants';
import { Store } from '@ngxs/store';
import { OrderByPipe } from '@shared/modules/pipes';

const FILTER_KEY = 'templateFilter';

export interface CreateForecastFromTemplateDialogData {
  project?: ProjectModel;
}

@Component({
  selector: 'indicio-browse-templates-dialog',
  templateUrl: 'browse-templates.dialog.html',
  styleUrls: ['browse-templates.dialog.less']
})
export class BrowseTemplatesDialogComponent {
  public componentFilter: ComponentFilter;

  public static Id: string = 'BrowseTemplatesDialogComponent';
  public filteredTemplates: ForecastTemplate[];
  public templates: ForecastTemplate[];

  public titleFilterActive: boolean = false;

  public titleString;
  public creatorString: string;
  public creatorFilters: IFilterDropdown[] = [];
  public tagFilters: IFilterDropdown[] = [];
  public regionFilters: IFilterDropdown[] = [];
  public providerFilters: IFilterDropdown[] = [];
  public loadingContent: boolean = true;

  public allTagsOption: IFilterDropdown = { display: '* All tags *', value: 'xXx' };
  public allRegionsOption: IFilterDropdown = { display: '* All regions *', value: 'xXx' };
  public allSourcesOption: IFilterDropdown = { display: '* All providers *', value: 'xXx' };

  filters: IDialogFilter[] = [
    {
      label: 'Title',
      value: '',
      type: 'regex',
      key: 'Title',
      savable: false
    },
    {
      label: 'Creator',
      value: '',
      type: 'dropdown',
      key: 'authorEmail',
      values: this.creatorFilters,
      savable: false,
      multiple: false
    },
    {
      label: 'Tags',
      value: '',
      type: 'dropdown',
      key: '!tagsArray',
      values: this.tagFilters,
      savable: false,
      multiple: true
    },
    {
      label: 'Region',
      value: '',
      type: 'dropdown',
      key: '!regionsArray',
      values: this.regionFilters,
      savable: false,
      multiple: true
    },
    {
      label: 'Provider',
      value: '',
      type: 'dropdown',
      key: 'sourcesArray',
      values: this.providerFilters,
      savable: false,
      multiple: true
    }
  ];

  public get creatorFilter() { return this.filterService.getComponentFilter(FILTER_KEY)?.availableFilters.find(x => x.label === 'Creator'); }
  public get tagFilter() { return this.filterService.getComponentFilter(FILTER_KEY)?.availableFilters.find(x => x.label === 'Tags'); }
  public get regionFilter() { return this.filterService.getComponentFilter(FILTER_KEY)?.availableFilters.find(x => x.label === 'Region'); }
  public get providerFilter() { return this.filterService.getComponentFilter(FILTER_KEY)?.availableFilters.find(x => x.label === 'Provider'); }
  public get tagFilterSearchables() { return this.filterService.getComponentFilter(FILTER_KEY)?.availableFilters.find(x => x.label === 'Tags').values.map(x => x.value); }
  public get regionFilterSearchables() { return this.filterService.getComponentFilter(FILTER_KEY)?.availableFilters.find(x => x.label === 'Region').values.map(x => x.value); }

  public get availableTags() { return this.templateService.availableTags.filter(x => x.Type !== TagTypes.USERDEFINEDREGION); }
  public get availableRegions() { return this.templateService.availableTags.filter(x => x.Type === TagTypes.USERDEFINEDREGION); }

  constructor(
    public dialogRef: MatDialogRef<BrowseTemplatesDialogComponent>,
    private templateService: ForecastTemplateFrontendService,
    public appearance: AppearanceService,
    private store: Store,
    private filterService: FilterService,
    private sortPipe: OrderByPipe,
    private forecastDialogService: ForecastDialogService,
    @Inject(MAT_DIALOG_DATA) public data: CreateForecastFromTemplateDialogData) {
    this.componentFilter = this.filterService.setComponentFilter(FILTER_KEY, this.filters);

    this.setup();
  }

  private setup() {
    this.templateService.getAll()
      .then(templates => this.templates = templates)
      .then(() => {
        this.setCreatorFilterValues();
        this.setTagFilterValues();
        this.setRegionFilterValues();
        this.setProviderFilterValues();
        this.filterTemplates();
      })
      .finally(() => this.loadingContent = false);
  }

  private setCreatorFilterValues() {
    this.creatorFilters = this.templates.map(t => <IFilterDropdown> ({ value: t.AuthorInfo.AuthorEmail, display: t.AuthorInfo.AuthorName }))
      .filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
    const filter = this.creatorFilter;
    filter.values = this.creatorFilters;
    filter.values.unshift({ display: '* All creators *', value: 'xXx' });
  }

  public setCreatorFilterValue(email: string) {
    this.creatorFilter.value = email === 'xXx' ? [] : [email];
    this.creatorString = email;
    this.filterTemplates();
  }

  private setProviderFilterValues() {
    this.providerFilters = this.templates.map(t => t.Sources.map(x => (<IFilterDropdown> { value: x.source.Value, display: x.source.Display }))).flatten()
      .filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);

    this.providerFilters = this.sortPipe.transform(this.providerFilters, 'display');
    this.providerFilters.unshift(this.allSourcesOption);

    const filter = this.providerFilter;
    filter.values = this.providerFilters;
    filter.value = [this.allSourcesOption.value];
  }

  public setProviderFilterValue(provider: string[]) {
    // Check if all providers are selected and hasnt been selected before
    if (provider.includes(this.allSourcesOption.value) && !this.providerFilter.value.includes(this.allSourcesOption.value)) { provider = [this.allSourcesOption.value]; }
    // Check if all providers has been selected before and now there are more than one provider selected
    else if (this.providerFilter.value.includes(this.allSourcesOption.value) && provider.length > 1) { provider = provider.filter(x => x !== this.allSourcesOption.value); }
    // Check if no provider are selected
    else if (!provider.length) { provider = [this.allSourcesOption.value]; }

    this.providerFilter.value = provider;
    this.filterTemplates();
  }

  private setTagFilterValues() {
    this.tagFilters = this.templates.map(t => t.tagsArray.map(x => (<IFilterDropdown> { value: x, display: x }))).flatten()
      .filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);

    this.tagFilters = this.sortPipe.transform(this.tagFilters, 'display');
    this.tagFilters.unshift(this.allTagsOption);

    const filter = this.tagFilter;
    filter.values = this.tagFilters;
    filter.value = [this.allTagsOption];
  }

  private setRegionFilterValues() {
    this.regionFilters = this.templates.map(t => t.regionsArray.map(x => (<IFilterDropdown> { value: x, display: x }))).flatten()
      .filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);

    this.regionFilters = this.sortPipe.transform(this.regionFilters, 'display');
    this.regionFilters.unshift(this.allRegionsOption);

    const filter = this.regionFilter;
    filter.values = this.regionFilters;
    filter.value = [this.allRegionsOption];
  }

  public setTagFilterValue(tags: IFilterDropdown[]) {
    // Filter out undefined values
    if (!tags?.filter(x => !!x)?.length) { tags = [this.allTagsOption]; }
    // Check if all tags are selected and hasnt been selected before
    if (tags.some(x => x.value === this.allTagsOption.value) && !this.tagFilter.value.some(x => x.value === this.allTagsOption.value)) { tags = [this.allTagsOption]; }
    // Check if all tags has been selected before and now there are more than one tag selected
    else if (this.tagFilter.value.some(x => x.value === this.allTagsOption.value) && tags.length > 1) { tags = tags.filter(x => x.value !== this.allTagsOption.value); }

    this.tagFilter.value = tags;
    this.filterTemplates();
  }

  public setRegionFilterValue(tags: IFilterDropdown[]) {
    // Filter out undefined values
    if (!tags?.filter(x => !!x)?.length) { tags = [this.allRegionsOption]; }
    // Check if all tags are selected and hasnt been selected before
    if (tags.some(x => x.value === this.allRegionsOption.value) && !this.regionFilter.value.some(x => x.value === this.allRegionsOption.value)) { tags = [this.allRegionsOption]; }
    // Check if all tags has been selected before and now there are more than one tag selected
    else if (this.regionFilter.value.some(x => x.value === this.allRegionsOption.value) && tags.length > 1) { tags = tags.filter(x => x.value !== this.allRegionsOption.value); }

    this.regionFilter.value = tags;
    this.filterTemplates();
  }


  public filterTitle() {
    const filter = this.filterService.getComponentFilter(FILTER_KEY).availableFilters.find(x => x.key === 'Title');
    filter.value = this.titleString;
    this.filterTemplates();
  }

  public filterTemplates(templates: ForecastTemplate[] = this.templates) {
    if (!this.componentFilter) { return this.filteredTemplates = templates; }
    this.filteredTemplates = this.filterService.filterSeries(this.componentFilter.name, templates);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
    if (!!this.data?.project) {
      this.forecastDialogService.openCreate({ ProjectId: this.data.project.ProjectId });
    }
  }

  public save() {
    this.dialogRef.close(true);
  }
}
