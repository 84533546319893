import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { SharepointModalOpts } from './sharepoint-modal.options';

export class OpenAddSharepointProviderModal {
  public static readonly type = '[MODAL] Open sharepoint provider modal';
  data: SharepointModalOpts;
  constructor(
    forecastId: string,
    edit?: RemoteDataSourceModel
  ) {
    this.data = { forecastId, edit };
  }
}
