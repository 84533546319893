import { Injectable } from '@angular/core';
import { TwoFactorProviders } from '@core/services/environment/environment.service';
import { AuthToken } from '@core/store/auth/auth-token';
import { AuthTokenDTO } from '@core/store/auth/dtos/auth-token-dto';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable({
  providedIn: 'root'
})
export class AuthMapper {

  public mapStoredToken(model: AuthToken) {
    model.token_expires = DateUtils.newDate(model.token_expires);
    if (!!model.force_two_factor_from) {
      model.force_two_factor_from = DateUtils.newDate(model.force_two_factor_from);
    }
    return model;
  }

  public mapToken(dto: AuthTokenDTO) {
    const token = new AuthToken();
    token.access_token = dto.access_token;
    token.session_id = dto.session_id;
    token.refresh_token = dto.refresh_token;
    token.refresh_token_secret = dto.refresh_token_secret;
    token.client_id = dto.client_id;
    token.token_expires = DateUtils.newDate(dto['.expires']);
    token.sys_admin = false;
    token.sysrole = dto.sysrole;
    token.two_factor_authed = dto.two_factor_authed === 'True';
    token.two_factor_enabled = dto.two_factor_enabled === 'True';
    token.two_factor_method = dto.two_factor_method as TwoFactorProviders;
    token.force_two_factor = dto.force_two_factor === '1';
    if (!!dto.force_two_factor_from) {
      token.force_two_factor_from = DateUtils.newDate(dto.force_two_factor_from.split('-').join('/') + ' UTC');
    }


    if (typeof dto.sysperms === 'string') {
      token.sysperms = JSON.parse(dto.sysperms);
    } else {
      token.sysperms = dto.sysperms;
    }

    if (dto.roles) {
      const rr = dto.roles;
      const roles = JSON.parse(rr);
      const idx = roles.findIndex(val => val === 'sysadmin');
      token.sys_admin = idx !== -1;
    }
    return token;
  }
}
