<indicio-modal-base [child]="this"
                    modalTitle="Security settings"
                    [loading]=isLoading
                    modalClass=""
                    bodyExtraClass="overflow-auto ">

  <ng-container *ngIf="!isLoading">
    <div style="width:590px;margin: 0 auto;">
      <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>

      <ng-container *ngIf="view === 'password'">
        <ng-container *ngTemplateOutlet="passwordView"></ng-container>
      </ng-container>

      <ng-container *ngIf="view === '2fa'">
        <indicio-2fa-settings (closeEvent)="close();"></indicio-2fa-settings>
      </ng-container>

      <ng-container *ngIf="view === 'sessions'">
        <ng-container *ngTemplateOutlet="sessionsTemplate"></ng-container>
      </ng-container>

      <ng-container *ngIf="view === 'account'">
        <ng-container *ngTemplateOutlet="generalAccountSecurity"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</indicio-modal-base>


<ng-template #menuTemplate>
  <div data-test-id='security-settings-menu'
       class="modal-menu">
    <div class="menu-button"
         data-test-id='security-settings-menu-pw-tab'
         [ngClass]="{ 'current': view === 'password'}"
         (click)="changeView('password')">
      Change password
    </div>
    <div class="menu-button"
         data-test-id='security-settings-menu-2fa-tab'
         [ngClass]="{ 'current': view === '2fa'}"
         (click)="changeView('2fa')">
      Two-factor authentication
    </div>
    <div class="menu-button"
         data-test-id='security-settings-menu-sessions-tab'
         [ngClass]="{ 'current': view === 'sessions'}"
         (click)="changeView('sessions')">
      Sessions
    </div>
    <div class="menu-button"
         data-test-id='security-settings-menu-sessions-tab'
         [ngClass]="{ 'current': view === 'account'}"
         (click)="changeView('account')">
      Preferences
    </div>
  </div>
</ng-template>

<ng-template #generalAccountSecurity>
  <indicio-modal-option-row optionTitle="Automatic logout"
                            optionSubtitle="If enabled, you will be automatically logged out after 15 minutes of inactivity."
                            textWidth="10"
                            [disabled]="clientService.activeCompany.ForceAutomaticLogout"
                            optionsWidth="2">
    <div class="toggle-icon"
         [indicioTippy]="clientService.activeCompany.ForceAutomaticLogout ? 'Setting enforced by the company security policy.' : null"
         style="margin-top: 25px;">
      <mat-slide-toggle [disabled]="clientService.activeCompany.ForceAutomaticLogout"
                        [checked]="clientService.client.AutomaticLogout"
                        (change)="toggleAutomaticLogout($event)">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>
  <div class="extra-margin-top"></div>
</ng-template>

<ng-template #sessionsTemplate>

  <div class="row margin-top">
    <div class="col-xs-12 text-center bold">Active sessions</div>
  </div>
  <div class="row margin-top">
    <div class="col-xs-4 bold">Last login</div>
    <div class="col-xs-2 bold col-no-padding">OS</div>
    <div class="col-xs-3 bold col-no-padding">Browser</div>
    <div class="col-xs-2 bold col-no-padding">IP address</div>
    <div class="col-xs-1 bold col-no-padding"></div>
  </div>
  <div *ngFor="let s of sessions"
       class="row">
    <div class="col-xs-4">
      {{ s.LatestLogin | date: appearance.FullFormatNoSeconds }}
      <i *ngIf="s.Id === sessionId"
         title="Current session"
         style="margin-left: 2px; font-size: 15px;"
         class="ion-home"></i>
      <i *ngIf="s.Active"
         style="margin-left: 2px; font-size: 15px;"
         title="Active session, valid for login"
         class="ion-android-star"></i>
    </div>
    <div class="col-xs-2 col-no-padding">{{ s.OperatingSystem }}</div>
    <div class="col-xs-3 col-no-padding">{{ s.UserAgent }}</div>
    <div class="col-xs-2 col-no-padding">{{ s.RemoteAddress }}</div>
    <div class="col-xs-1 col-no-padding">
      <i *ngIf="s.TwoFactorAuthentication"
         style="margin-left: 2px; font-size: 15px;"
         title="Two-factor authenticated"
         class="ion-checkmark-round green"></i>
      <i *ngIf="!s.TwoFactorAuthentication"
         style="margin-left: 2px; font-size: 15px;"
         title="No two-factor authentication"
         class="ion-close-round red"></i>
      <i *ngIf="s.Id !== sessionId"
         style="margin-left: 2px; font-size: 15px;"
         title="Remove this session"
         class="ion-trash-b h-grey a-lightgrey"
         (click)="deleteSession(s.Id)"></i>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
    </div>
    <div class="col-xs-6"
         indicioTippy="Will end all sessions except the current one, removing any two-factor sign ins">
      <button class="btn right"
              (click)="logOutOtherDevices()">
        Sign out all other sessions
      </button>
    </div>
  </div>

</ng-template>



<ng-template #passwordView>
  <div class="margin-top">
    <form #f="ngForm"
          (ngSubmit)="changePassword($event)"
          ngNativeValidate>
      <input hidden
             type="text"
             autocomplete="username"
             [value]="p.Email">
      <div class="row">
        <div class="col-xs-12">Please fill in the fields below to change your password</div>
      </div>
      <div class="row margin-top">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-label>Current password</mat-label>
            <input type="password"
                   class="material"
                   matInput
                   autocomplete="current-password"
                   [(ngModel)]="oldpw"
                   name="oldpw"
                   required>
          </mat-form-field>
        </div>
      </div>
      <div class="row"
           style="margin-top: 5px">
        <div class="col-xs-12 ">
          <button class="btn right cursor-pointer"
                  style="margin: 0;border-radius: 5px;height: 30px;"
                  type="button"
                  (click)="addGeneratedPassword()">
            <div>Generate password</div>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-label>New password</mat-label>
            <input [type]="showPass ? 'text' : 'password'"
                   class="material"
                   matInput
                   autocomplete="new-password"
                   [(ngModel)]="newpw1"
                   (keyup)="checkPassword()"
                   name="newpw1"
                   required>
            <i style="margin-left: 2px; font-size: 15px;vertical-align: middle;"
               title="Show password"
               [class]="showPass ? 'ion-eye' : 'ion-eye-disabled'"
               class="h-grey a-lightgrey cursor-pointer"
               (click)="showPassword()"
               matSuffix></i>
            <mat-hint *ngIf="passwordStrength === 0"><span class="error-text">Weak password</span></mat-hint>
            <mat-hint *ngIf="passwordStrength === 1"><span class="warning-text">Medium password</span></mat-hint>
            <mat-hint *ngIf="passwordStrength === 2"><span class="success-text">Strong password</span></mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-top">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-label>Repeat new password</mat-label>
            <input type="password"
                   class="material"
                   matInput
                   autocomplete="new-password"
                   [(ngModel)]="newpw2"
                   name="newpw2"
                   required>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <button class="btn outline blue left"
                  data-test-id="security-modal-btn-close"
                  type="button"
                  (click)="close()">
            <span *ngIf="newpw1 || newpw2 || oldpw">Cancel</span>
            <span *ngIf="!newpw1 && !newpw2 && !oldpw">Close</span>
          </button>
        </div>
        <div class="col-xs-6">
          <button class="btn right"
                  type="submit">
            <i *ngIf="inProgress"
               class="ion-load-c spinner"></i>
            <div *ngIf="!inProgress">Change</div>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
