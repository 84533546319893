import { Injectable } from '@angular/core';

import { EnvironmentService } from '@core/services/environment/environment.service';
import { DataManagementSettingsModel } from '@core/store/client/data-management-settings.model';
import { ClientDTO } from '@core/store/client/dtos/client-dto';
import { DataManagementSettingsDTO } from '@core/store/client/dtos/data-management-settings-dto';
import { ProfileDTO } from '@core/store/client/dtos/profile-dto';
import { DateUtils } from '@shared/utils/date.utils';
import { RemoteDataMapper } from '../providers/remote-data.mapper';
import { ClientModel } from './client.model';
import { DisplayFilterModel } from './display-filter.model';
import { DisplayFilterDTO } from './dtos/display-filter-dto';
import { GraphThemeDTO } from './dtos/graph-theme-dto';
import { GraphThemeModel, GraphThemeSettingsModel } from './graph-theme.model';
import { ProfileModel } from './profile.model';


@Injectable({
  providedIn: 'root'
})
export class ClientMapper {

  constructor(
    private envService: EnvironmentService,
    private remoteMapper: RemoteDataMapper
  ) {
  }

  public map(dto: ClientDTO): ClientModel {
    const companies = dto.Companies.slice();
    const model = new ClientModel();
    delete dto.Companies;
    Object.assign(model, dto);
    model.ClientId = dto.Profile.ClientId;
    this.envService.map(dto.Environment);
    model.CompanyIds = companies.map(c => c.CompanyId);
    if (dto.ActiveCompanyId === null) {
      model.ActiveCompanyId = companies[0].CompanyId;
    }

    if (dto.RemoteDataSources.length) {
      model.RemoteDataSources = dto.RemoteDataSources.map(source => this.remoteMapper.toRemoteDataSourceDetailModel(source));
    }

    if (dto.GraphThemes.length) {
      model.GraphThemes = dto.GraphThemes.map(gt => this.mapGraphTheme(gt));
    }
    return model;
  }

  public mapProfile(dto: ProfileDTO): ProfileModel {
    const model = new ProfileModel();
    Object.assign(model, dto);
    model.Country = this.envService.getCountry(dto.CountryCode);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    return model;
  }

  public mapProfileDTO(model: ProfileModel): ProfileDTO {
    return Object.faMapTo(new ProfileDTO(), model);
  }

  public mapDataManagementSettings(dto: DataManagementSettingsDTO): DataManagementSettingsModel {
    const model = Object.faMapTo(new DataManagementSettingsModel(), dto);
    model.DataManagementSortSettings = dto.DataManagementSortSettings.map(setting => JSON.parse(setting));
    return model;
  }

  public mapDataManagementSettingsDTO(model: DataManagementSettingsModel): DataManagementSettingsDTO {
    const dto = new DataManagementSettingsDTO();
    dto.DataManagementSortSettings = model.DataManagementSortSettings.map(s => JSON.stringify(s));
    return dto;
  }

  public mapGraphTheme(dto: GraphThemeDTO): GraphThemeModel {
    const model = Object.faMapTo(new GraphThemeModel, dto);
    if (dto.Theme) {
      model.Theme = Object.faMapTo(new GraphThemeSettingsModel, JSON.parse(dto.Theme));
    }
    return model;
  }

  public mapGraphThemeDTO(model: GraphThemeModel): GraphThemeDTO {
    const dto = Object.faMapTo(new GraphThemeDTO, model);
    if (model.Theme) {
      dto.Theme = JSON.stringify(Object.faMapTo(new GraphThemeSettingsModel, model.Theme));
    }
    return dto;
  }

  public mapDisplayFilter(dto: DisplayFilterDTO): DisplayFilterModel {
    if (!dto.Type) { delete dto.Type; }
    const model = Object.faMapTo(new DisplayFilterModel, dto);
    return model;
  }

  public mapDisplayFilterDTO(model: DisplayFilterModel, component: string): DisplayFilterDTO {
    const dto = Object.faMapTo(new DisplayFilterDTO, model);
    dto.Name = component;
    return dto;
  }
}
