<indicio-modal-base [child]="this"
                    modalTitle="Assessments"
                    [loading]="isLoading">

  <div class="row assessment-row modal-heading extra-margin-bottom">
    <div *ngIf="!userIsAdmin">
      <div class="col-xs-3 col-no-padding">Date</div>
      <div class="col-xs-4 col-no-padding">User</div>
      <div class="col-xs-3 col-no-padding">Change</div>
      <div class="col-xs-2 col-no-padding"></div>
    </div>
    <div *ngIf="userIsAdmin">
      <div class="col-xs-2 col-no-padding">Date</div>
      <div class="col-xs-2 col-no-padding">User</div>
      <div class="col-xs-3 col-no-padding">Change</div>
      <div class="col-xs-1 text-center col-no-padding">Active</div>
      <div class="col-xs-2 text-center col-no-padding">Weight</div>
      <div class="col-xs-2 col-no-padding"></div>
    </div>
  </div>
  <div *ngIf="loaded">
    <div *ngFor="let assessment of assessments; let index = index"
         class="row assessment-row assessment-exists">
      <!-- DATE -->
      <div *ngIf="!userIsAdmin"
           class="col-xs-3 col-no-padding">
        {{ assessment.TimePoint | dateFormat: forecastVersion.Periodicity }}
      </div>
      <div *ngIf="userIsAdmin"
           class="col-xs-2 col-no-padding">
        {{ assessment.TimePoint | dateFormat: forecastVersion.Periodicity }}
      </div>

      <!-- CREATOR -->
      <div class="col-no-padding"
           [ngClass]="userIsAdmin ? 'col-xs-2' : 'col-xs-4'"
           [title]="getCompanyUser(assessment.ClientEmail)?.FullName()">
        {{ getCompanyUser(assessment.ClientEmail)?.FirstName }}
      </div>

      <!-- CHANGE -->
      <div class="col-xs-3 col-no-padding"
           title=" {{ envService.getAssessmentUnit(assessment.Unit).Description }}">
        {{ assessment.Quantity }}
        <span
              *ngIf="assessment.Unit === 'percent' || assessment.Unit === 'roc' || assessment.Unit === 'roc-yy'">%</span>
        <span *ngIf="assessment.Unit === 'roc' || assessment.Unit === 'roc-yy'">{{
          envService.getAssessmentUnit(assessment.Unit).Display }}</span>
      </div>

      <!-- ACTIVE -->
      <div *ngIf="userIsAdmin">
        <div class="col-xs-1 text-center col-no-padding">
          <div *ngIf="userCanUpdateAssessment"
               class="btn-small-icon data-m generic margin-auto"
               (click)="toggleActive(assessment)">
            <i *ngIf="assessment.Active"
               class="margin-auto font-size-20 ion-toggle-filled blue"></i>
            <i *ngIf="!assessment.Active"
               class="margin-auto font-size-20 ion-toggle grey"></i>
          </div>
        </div>
      </div>

      <!-- WEIGHT -->
      <div *ngIf="userIsAdmin">
        <div class="col-xs-2 text-center col-no-padding">
          <input type="number"
                 min="0"
                 max="100"
                 step="1"
                 class="assessment-weight"
                 [ngModel]="assessment.Weight"
                 (ngModelChange)="onChangeWeight($event, assessment)"
                 required>
          <span>%</span>
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="col-xs-2 col-no-padding">
        <div class="btn-small-icon dark h-red pull-right margin-left-10"
             *ngIf="canUserDelete(assessment)">
          <i data-test-id='remove-assessment-btn'
             class="ion-trash-b"
             (click)="removeAssessment(assessment)"></i>
        </div>
        <div class="btn-small-icon dark h-grey pull-right"
             *ngIf="userCanUpdateAssessment">
          <i data-test-id='assessment-settings-btn'
             class="ion-edit"
             (click)="editAssessmentForm(assessment)"></i>
        </div>
      </div>
    </div>
    <div class="row assessment-row extra-margin-bottom">
      <div class="col-xs-4 add-assessment-button h-grey col-no-padding col-no-padding"
           *ngIf="userCanCreateAssessment"
           (click)="addAssessmentForm()">
        <i data-test-id='add-assessment-btn'
           class="ion-plus margin-right-10"></i>
        <span>Add Assessment</span>
      </div>
      <div class="col-xs-8 col-no-padding"></div>
    </div>
  </div>
  <div *ngIf="!loaded">
    <div class="col-xs-4 col-no-padding"></div>
    <div class="col-xs-4 text-center col-no-padding"
         style="font-size: 40px;">
      <i data-test-id='assessment-loading-spinner'
         class="ion-load-c spinner"></i>
    </div>
    <div class="col-xs-4 col-no-padding"></div>
  </div>
  <div class="row">
    <div class="col-xs-6 col-no-padding">
      <button data-test-id='cancel-assessment-btn'
              class="btn outline blue left"
              type="button"
              (click)="close()">Cancel</button>
    </div>
    <div class="col-xs-6 col-no-padding">
      <button data-test-id='accept-assessment-btn'
              class="btn right"
              type="button"
              (click)="close()"
              *ngIf="!saveOnClose; else saveButton">Ok</button>
    </div>
  </div>
</indicio-modal-base>

<ng-template #saveButton>
  <button class="btn right"
          type="button"
          (click)="close()">
    <span data-test-id='save-assessment-btn'
          *ngIf="!pending">Save</span>
    <span *ngIf="pending"
          class="ion-load-c spinner"></span>
  </button>
</ng-template>
