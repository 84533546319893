import { Def } from '@core/decorators/def';
import { FredObservationFrequecy } from '@core/store/providers/fred/fred.backend.service';

export class FredDataSerieModel {
  @Def() id: string;
  @Def() realtime_start: string;
  @Def() realtime_end: string;
  @Def() title: string;
  @Def() observation_start: string;
  @Def() observation_end: string;
  @Def() frequency: string;
  @Def() frequency_short: FredObservationFrequecy;
  @Def() units: string;
  @Def() units_short: string;
  @Def() seasonal_adjustment: string;
  @Def() seasonal_adjustment_short: string;
  @Def() last_updated: string;
  @Def() popularity: number;
  @Def() group_popularity: number;
  @Def() notes: string;

  oldData: boolean;
  imported: boolean;
}
