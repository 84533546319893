import { TermsOfServiceDTO } from './tos.dtos';

export class GetToSSuccessAction {
  public static readonly type = '[TOS] Get tos success';
  constructor(public tos: TermsOfServiceDTO) { }
}

export class AcceptToSSuccessAction {
  public static readonly type = '[TOS] Agree to tos success';
  constructor(public tosId: string) { }
}
