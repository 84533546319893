<h1 mat-dialog-title>Promote to main variable</h1>
<div mat-dialog-content>
  <ng-container *ngIf="!stepStatus.ProcessFailed; else errorTemplate">
    <ng-container *ngIf="!canSwapLoading"
                  [ngTemplateOutlet]="mainContent"></ng-container>
    <ng-container *ngIf="canSwapLoading"
                  [ngTemplateOutlet]="loader"></ng-container>
  </ng-container>
</div>
<ng-template #errorTemplate>
  Something went wrong. Please try again and if the problem persists, contact customer support.
</ng-template>

<ng-template #mainContent>
  This action will remove all current results.
  <ng-container [ngTemplateOutlet]="action"></ng-container>
  <ng-container *ngIf="canSwapStatus && canSwapStatus.Messages?.length">
    <h3 class="margin-top">Warnings:</h3>
    <ul class="bullet-points">
      <li *ngFor="let message of canSwapStatus.Messages">
        {{ message }}
      </li>
    </ul>
  </ng-container>
  <ng-container [ngTemplateOutlet]="statusTemplate"></ng-container>
</ng-template>

<ng-template #action>
  <strong>New Main variable:</strong> {{forecastVariable.Name}}
</ng-template>

<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()"
          data-test-id="swap-cancel-btn">
    Cancel
  </button>
  <button mat-flat-button
          data-test-id="proceed-swap"
          *ngIf="!userHasTriggeredSwap"
          (click)="proceedSwapping()">
    Proceed
  </button>
  <button mat-flat-button
          *ngIf="stepStatus && stepStatus.Done"
          (click)="save()">
    Done
  </button>
</div>

<ng-template #loader>
  <div class="text-center">
    <i class="ion-load-c spinner"
       style="font-size:50px;"></i>
  </div>
</ng-template>

<ng-template #statusContent
             let-status="status"
             let-spin="spin">
  <div class="column"
       style="width: 98px; max-height: 25px;">
    <ng-container *ngIf="!userHasTriggeredSwap">
      -
    </ng-container>
    <ng-container *ngIf="userHasTriggeredSwap">
      <i *ngIf="!status && spin"
         class="ion-load-c spinner"></i>
      <span *ngIf="!status && !spin && stepStatus.Done === false"
            data-test-id="swap-pending">Pending</span>
      <span *ngIf="!status && !spin && stepStatus.Done === true">
        -
      </span>
      <span *ngIf="status">Done</span>
    </ng-container>
  </div>

</ng-template>

<ng-template #statusTemplate>
  <div class="row">
    <div class="col-xs-12 bold text-center extra-margin-top">Status</div>
  </div>
  <div class="row variable-row vertical-align">
    <div class="col-xs-12">
      <div class="data-table"
           style="width: 540px;">
        <div class="data-table__header">
          <div class="column header"
               style="width: 40px;">#</div>
          <div class="column header"
               style="width: 190px; text-align: left; padding-left: 5px;">Object</div>
          <div class="column header"
               style="width: 210px;">Info</div>
          <div class="column header"
               style="width: 98px;">Status</div>
        </div>

        <div class="data-table__body big"
             style="max-height: 350px;">
          <div class="body__row body__row--center-height body__row--max-height-30">
            <div class="column"
                 style="width: 40px;">1</div>
            <div class="column"
                 style="width: 190px; text-align: left;">
              Indicator variable
            </div>
            <div class="column"
                 style="width: 210px;">
              Removing indicator variable
            </div>
            <ng-container *ngTemplateOutlet="statusContent; context: {
              status: stepStatus.IndicatorRemoved,
              spin: !stepStatus.IndicatorRemoved && stepStatus.Done === false
             }">
            </ng-container>
          </div>
          <div class="body__row body__row--center-height body__row--max-height-30"
               *ngIf="hasOldMain">
            <div class="column"
                 style="width: 40px;">2</div>
            <div class="column"
                 style="width: 190px; text-align: left;">
              Main variable
            </div>
            <div class="column"
                 style="width: 210px;">
              Removing old main variable
            </div>
            <ng-container *ngTemplateOutlet="statusContent; context: {
              status: stepStatus.MainVariableRemoved,
              spin: stepStatus.IndicatorRemoved && !stepStatus.MainVariableRemoved && stepStatus.Done === false
             }">
            </ng-container>
          </div>
          <div class="body__row body__row--center-height body__row--max-height-30">
            <div class="column"
                 style="width: 40px;">3</div>
            <div class="column"
                 style="width: 190px; text-align: left;">
              Indicator variable
            </div>
            <div class="column"
                 style="width: 210px;">
              Creating new main variable
            </div>
            <ng-container *ngTemplateOutlet="statusContent; context: {
              status: stepStatus.MainVariableCreated,
              spin: stepStatus.MainVariableRemoved && !stepStatus.MainVariableCreated && stepStatus.Done === false
             }">
            </ng-container>
          </div>
          <div class="body__row body__row--center-height body__row--max-height-30"
               *ngIf="hasOldMain">
            <div class="column"
                 style="width: 40px;">4</div>
            <div class="column"
                 style="width: 190px; text-align: left;">
              Main variable
            </div>
            <div class="column"
                 style="width: 210px;">
              Creating new indicator variable
            </div>
            <ng-container *ngTemplateOutlet="statusContent; context: {
              status: stepStatus.IndicatorCreated,
              spin: stepStatus.MainVariableCreated && !stepStatus.IndicatorCreated && stepStatus.Done === false
            }">
            </ng-container>
          </div>
          <div class="body__row body__row--center-height"
               *ngIf="stepStatus.WarningMessage">
            <div class="column"
                 style="width: 40px;">4</div>
            <div class="column"
                 style="width: 190px; text-align: left;">
              Warning Message
            </div>
            <div class="column"
                 style="width: 210px;color: #ffab3f;">
              {{stepStatus.WarningMessage}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
