import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value): any {
    const keys = [];
    for (const key of Object.keys(value)) {
      keys.push(key);
    }
    return keys;
  }
}
