import { Def } from '@core/decorators/def';
import { ALGLineModel, ALGSingleSeriesModel } from '@shared/components/line-graph/alg-models/graph-data.model';

export namespace PlotValueUtils {
  export function isForecasted(v: PlotValue) {
    return v.IF;
  }
}

export interface IForecastValue {
  D: Date;
  V: number;
  I50?: number;
  A50?: number;
  I75?: number;
  A75?: number;
  I95?: number;
  A95?: number;
}

export class StringDateValueDTO {
  D: string;
  V: number;
}

export class StringDatePlotValueDTO extends StringDateValueDTO {
  I50: number;
  A50: number;
  I75: number;
  A75: number;
  I95: number;
  A95: number;
}

export interface IHasDate {
  D: Date;
  m: moment.Moment;
}

export class StatModelVariableDTO {
  Id: string;
  Data: PlotValueDTO[];
  Historic: HistoricValueDTO[];
}

export class SeasonalValueDTO implements IHasDate {
  /** Date */
  @Def() D: Date;
  /** Trend */
  @Def() T: number;
  /** Irregular */
  @Def() I: number;
  /** Component1 */
  @Def() C1: number;
  /** Component2 */
  @Def() C2: number;
  /** Component3 */
  @Def() C3: number;
  /** Set on Frontend: moment */
  @Def() m: moment.Moment;
}

export class SimplePlotValue implements IHasDate {
  /** Date */
  @Def() D: Date;
  /** Value */
  @Def() V: number;
  /** Set on Frontend: moment */
  @Def() m: moment.Moment;
  /** Was null */
  @Def() N?: boolean = false;
}

export class FittedValueDTO extends SimplePlotValue {
  /** Fitted value */
  @Def() F: number;
  /** Set on Frontend: Weighted Fitted value */
  @Def() WF?: number;
}

export class HistoricValueDTO extends FittedValueDTO {
  /** Is Forecasted */
  @Def() IF: boolean;
  /** Aggregated */
  @Def() A: number;
  /** Missing Value Replacement */
  @Def() M: number;
  /** Value Seasonal */
  @Def() VS: number;
  /** Value Outlier */
  @Def() VO: number;
}

export class PlotValueDTO extends HistoricValueDTO implements IForecastValue {
  /** Min95 */
  @Def() I95: number;
  /** Min75 */
  @Def() I75: number;
  /** Min50 */
  @Def() I50: number;
  /** Max95 */
  @Def() A95: number;
  /** Max75 */
  @Def() A75: number;
  /** Max50 */
  @Def() A50: number;
  /** Max50 Seasonal */
  @Def() A50S: number;
  @Def() A75S: number;
  @Def() A95S: number;
  /** Min50 Seasonal */
  @Def() I50S: number;
  @Def() I75S: number;
  @Def() I95S: number;
  /** Max50 Outlier */
  @Def() A50O: number;
  @Def() A75O: number;
  @Def() A95O: number;
  /** Max50 Outlier */
  @Def() I50O: number;
  @Def() I75O: number;
  @Def() I95O: number;

  /** Set on Frontend: Weighted values */
  @Def() WI50: number;
  @Def() WI75: number;
  @Def() WI95: number;
  @Def() WA50: number;
  @Def() WA75: number;
  @Def() WA95: number;

  // D3 Entries
  model: ALGSingleSeriesModel | ALGLineModel;
}

export type PlotValue = HistoricValueDTO | PlotValueDTO;
