<indicio-modal-base [child]="this"
                    [modalTitle]="'Transfer Project: '+ project?.Name"
                    [loading]="isPending">

  <div class="row">
    <div class="col-xs-6 input-label">
      Transfer project to:
    </div>

    <div class="col-xs-6">
      <mat-form-field>
        <mat-label>Choose a member</mat-label>
        <mat-select [(value)]="transferTo">
          <mat-option [value]="member.Email"
                      *ngFor="let member of members">{{ member.Email }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6">
      <button class="btn outline blue left cancel"
              type="button"
              (click)="close(); back()">Cancel</button>
    </div>
    <div class="col-xs-6">
      <button class="btn right save"
              [ngClass]="{ 'disabled' : !transferTo }"
              (click)="tranferOwnership()">
        <span *ngIf="!isPending">Save</span>
        <span *ngIf="isPending"
              class="ion-load-c spinner"></span>
      </button>
    </div>
  </div>
</indicio-modal-base>
