export const modelColors = [
  '#dbdb8d',
  '#ffbb78',
  '#74c476',
  '#ff9896',
  '#9467bd',
  '#d6616b',
  '#c49c94',
  '#cb6893',
  '#ff7f0e',
  '#bcbd22',
  '#98df8a',
  '#17becf',
  '#67aae4',
  '#8A813E',
  '#648C07',
  '#33A0AD',
  '#854AB6',
  '#D465A8',
  '#E285FF',
  '#79A839',
  '#c56bb9',
  '#3e7ec3',
  '#6bc598',
  '#6b81FF',
  '#81fa6b',
  '#C8B1CC',
  '#f8dc24',
  '#4C4B8B'
];

export const scenarioColors = [
  '#47b129',
  '#ff7f0e',
  '#dab100',
  '#ff9896',
  '#c49c94',
  '#854AB6',
  '#17becf',
  '#ffbb78',
  '#ff951a',
  '#648C07',
  '#98df8a',
  '#D465A8',
  '#67aae4',
  '#2743C9',
  '#b9b911',
  '#74c476',
  '#9467bd',
  '#8A813E',
  '#d6616b',
  '#33A0AD',
  '#7c008e'
];

export const WFittedColor = '#CB6893';

export const pastForecastColors = [
  '#d3fe14', '#fec7f8', '#0b7b3e',
  '#0bf0e9', '#c203c8', '#fd9b39',
  '#906407', '#98ba7f', '#fe6794',
  '#ac7bff', '#fee7c0', '#964c63',
  '#0ad811', '#fe6cfe', '#d1a09c',
  '#78579e', '#81ffad', '#ca6949',
  '#d9bf01', '#d5097e', '#bb73a9',
  '#b6a7d4', '#9e8c62', '#cfe589',
  '#fd4109', '#bf8f0e', '#f097c6',
  '#fc8772', '#94bc19', '#fda2ab'
];

export const benchmarkColors = [...scenarioColors.slice(5, 15)].reverse();


