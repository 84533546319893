import { Component } from '@angular/core';
import { StatusService } from '@core/services/status/status.service';
import { UpdateRemoteDataSourceDTO } from '@core/store/providers/dtos/remote-data-source.dto';
import { NewRemoteDataSourceModelModel } from '@core/store/providers/models/new-remote-data-source-model.model';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { ProviderFrontendService } from '@core/store/providers/provider.frontend.service';
import { SourceType } from '@modules/lang/language-files/source-types';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { ModifyAPIKeyModalOpts } from './modify-api-key.options';

@Component({
  selector: 'indicio-modify-api-key-modal',
  templateUrl: './modify-api-key.component.html'
})
export class ModifyAPIKeyModalComponent extends ModalModelComponent {
  message = '';
  decline = 'Decline';
  confirm = 'Confirm';

  public provider: SourceType;
  public providerName: string;
  public helpText: string;
  public dataSource: RemoteDataSourceModel;
  public confirmFn: Function;
  public cancelFn: Function;
  private keyTest: Function;
  public regEx: RegExp;
  private keyUpdated = false;

  newApiKey: string;
  loading = false;

  constructor(
    public store: Store,
    private providerService: ProviderFrontendService,
    private statusService: StatusService
  ) {
    super();
  }

  setKey() {
    let call;
    this.pending = true;
    if (this.dataSource) {
      call = () => this.updateApiKey();
    } else {
      call = () => this.addNewApiKey();
    }

    if (this.keyTest) {
      return this.keyTest(this.newApiKey).then(() => {
        call();
      }).catch((e) => {
        let msg = 'The API key is not valid.';
        // Handle quandl specific error
        if (e.error?.quandl_error?.message) {
          msg = e.error.quandl_error.message;
        }

        this.statusService.setMessage(msg, 'Error', true);
      }).finally(() => {
        this.pending = false;
      });
    }

    return call();
  }

  private updateApiKey() {
    const model: UpdateRemoteDataSourceDTO = {
      RemoteDataSourceId: this.dataSource.RemoteDataSourceId,
      Provider: this.dataSource.Provider,
      ApiKey: this.newApiKey
    };
    return this.providerService.editDataSource(model)
      .then(() => {
        this.keyUpdated = true;
        this.close();
        this.statusService.setMessage('The API key has been updated.', 'Success');
        if (this.confirmFn) {
          this.confirmFn(this.newApiKey);
        }
      });
  }

  private addNewApiKey() {
    const model: NewRemoteDataSourceModelModel = {
      Provider: this.provider.Value.toLowerCase(),
      ApiKey: this.newApiKey
    };
    return this.providerService.createDataSource(model)
      .then(() => {
        this.keyUpdated = true;
        this.close();
        this.statusService.setMessage('The API key has been added.', 'Success');
        if (this.confirmFn) {
          this.confirmFn(this.newApiKey);
        }
      });
  }

  setOptions(options: ModifyAPIKeyModalOpts) {
    this.provider = options.provider;
    this.providerName = options.provider.Display;
    this.helpText = options.helpText;
    this.dataSource = options.dataSource ? options.dataSource : null;
    this.newApiKey = options.dataSource ? options.dataSource.ApiKey : null;
    this.confirmFn = options.confirmFn;
    this.regEx = options.regEx;
    this.keyTest = options.keyTest;
    this.onClose = () => {
      if (!this.keyUpdated && options.cancelFn) {
        options.cancelFn();
      }
    };
    this.isLoading = false;
  }
}


