<indicio-modal-base [child]="this"
                    [modalTitle]="modalTitle"
                    [loading]="isLoading">

  <ng-container *ngIf="series">
    <ng-container *ngTemplateOutlet="tabsTemplate"></ng-container>

    <ng-container *ngIf="view === 'Import'">
      <ng-container *ngTemplateOutlet="importView"></ng-container>
    </ng-container>

    <ng-container *ngIf="view === 'PreviewData'">
      <ng-container *ngTemplateOutlet="previewData"></ng-container>
    </ng-container>

    <ng-container *ngTemplateOutlet="buttonRow"></ng-container>
  </ng-container>
</indicio-modal-base>

<ng-template #tabsTemplate>
  <div class="modal-menu flex">
    <div class="menu-button"
         [ngClass]="{ 'current': view === 'Import'}"
         (click)="changeView('Import')">
      Import
    </div>
    <div class="menu-button"
         [ngClass]="{ 'current': view === 'PreviewData'}"
         (click)="changeView('PreviewData')">
      Preview
    </div>
  </div>
</ng-template>

<ng-template #buttonRow>
  <div class="row">
    <div class="btn-wrapper extra-margin-top">
      <div class="col-xs-6">
        <button class="btn outline blue left"
                type="button"
                (click)="close()">Back</button>
      </div>
      <div class="col-xs-6">
        <indicio-button-dropdown class="pull-right extra-margin-top"
                                 [extraClasses]="disabled ? 'disabled' : null"
                                 [loading]="loading"
                                 text="Import"
                                 (clickEvent)="addVariable()">
          <ul>
            <li (click)="toggleQueue()"
                [ngClass]="{ disabled: service.isSerieInQueue(series.id) }">
              <span *ngIf="loading"
                    class="ion-load-c spinner"></span>&nbsp;
              Add to queue
            </li>
          </ul>
        </indicio-button-dropdown>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #previewData>
  <ng-container *ngIf="!dataLoader">
    <indicio-alg class="modal-chart"
                 [periodicityType]="displayPeriodicity"
                 [forecast]="forecast"
                 [forecastVersion]="forecastVersion"
                 [HistoricLine]="dataLine"
                 [options]="graphOptions"
                 [chartStyles]="{ borderSize: 2, borderRadius: 4, historyLine: '#6388D0', plotHeight: 210 }"
                 graphType="variable">
    </indicio-alg>
  </ng-container>
  <ng-container *ngIf="dataLoader">
    <div class="modal-loading"
         style="height: 200px;">
      <i class="ion-load-c spinner"></i>
      <div>Fetching data</div>
    </div>
  </ng-container>
</ng-template>

<ng-template #importView>
  <div *ngIf="series">
    <h2>{{ series.title }}</h2>

    <div class="margin-top selectable-text"
         style="max-height: 200px; overflow: auto"
         *ngIf="series.notes"
         [innerHTML]="series.notes | safeHtml"></div>

    <div class="row extra-margin-top">
      <div class="col-xs-3">Date range:</div>
      <div class="col-xs-9">
        {{ series.observation_start | dateFormat }} - {{ series.observation_end | dateFormat }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">Last updated:</div>
      <div class="col-xs-9">
        {{ series.last_updated | dateFormat }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">Units:</div>
      <div class="col-xs-9">
        {{ series.units }}
        <ng-container *ngIf="series.units !== series.units_short">
          ({{ series.units_short }})
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">Adjustment:</div>
      <div class="col-xs-9">
        {{ series.seasonal_adjustment }}
      </div>
    </div>

    <hr class="margin-top">

    <form action=""
          ngNativeValidate>
      <div class="row">
        <div class="col-xs-12 input-label">
          <mat-form-field>
            <mat-label>Enter name</mat-label>
            <input matInput
                   autocomplete="off"
                   [ngModel]="remoteDataModel.Name"
                   (ngModelChange)="changeName($event)"
                   name="Name"
                   maxlength="256"
                   required>
            <mat-hint *ngIf="nameMustChange || nameConflict">
              <span class="error-text">
                {{ nameMustChange ? 'The variable name must between 2 and 256 characters long' :
                'A source variable with that name already exists.' }}
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 input-label">
          <mat-form-field>
            <mat-select [value]="periodicity"
                        (selectionChange)="periodicityChanged($event)"
                        required
                        placeholder="Choose periodicity">
              <mat-option [value]="option.Value"
                          *ngFor="let option of periodicityOptions">{{ option.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-select [(value)]="aggregation"
                        required
                        placeholder="Choose aggregation">
              <mat-option [value]="option.Value"
                          *ngFor="let option of aggregationOptions">{{ option.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</ng-template>
