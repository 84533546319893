
import { AfterViewInit, ChangeDetectorRef, Component, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ErrorReportService } from '@core/services/error-report.service';
import { IdleService } from '@core/services/frontend/idle.service';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { PostLoginService } from '@core/services/frontend/post-login.service';
import { PusherService } from '@core/services/frontend/pusher.service';
import { PusherActionsFrontendService } from '@core/services/pusher/pusher-action.frontend.service';
import { SystemMessageService } from '@core/services/system/system-message.service';
import { SystemService } from '@core/services/system/system.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { NotificationFrontendService } from '@core/store/notifications/notification.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ScriptQueueFrontendService } from '@core/store/script-queue/script-queue.frontend.service';
import { StatModelService } from '@core/store/stat-model/stat-model.service';
import { WikiMathJaxComponent } from '@core/store/wiki/components/mathjax/wiki-mathjax.component';
import { environment } from '@env/environment';
import { ActivityLogService } from '@modules/automation/activity-log.service';
import { LanguageService } from '@modules/lang/language.service';
import { Store } from '@ngxs/store';
import { WikiLinkComponent } from '@shared/components';
import { DebugBoxHelper } from '@shared/components/debug-box/debug-box.helper';
import { ArrayExtensions } from '@shared/extensions/array.extensions';
import { ObjectExtensions } from '@shared/extensions/object.extensions';
import { ModalComponent } from '@shared/modals/modal.component';
import { ModalService } from '@shared/modals/modal.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { AppActions } from './app.actions';

// 10 minutes
const NEW_VERSION_TIMEOUT = 600000;

@Component({
  selector: 'indicio-app',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewInit {

  @ViewChild(ModalComponent, { static: true })
  modalComponent: ModalComponent;

  systemMessagePosition: string;

  confirmRef = null;

  public get loggedIn$() { return this.authService.loggedIn$; }
  public get debugEnabled() { return DebugBoxHelper.enabled; };
  public reload = false;

  public resolverLoading = false;
  public resolverLoadingText: string;

  private versionCheckErrorCounter: number = 0;
  public scrollPosition: number = 0;

  // Do not remove unused services below, they often must be initialized at app-start to get all
  // dispatched events during init
  constructor(
    private appearance: AppearanceService,
    private authService: AuthFrontendService,
    private envService: EnvironmentService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public actions: ActionService,
    private cd: ChangeDetectorRef,
    public systemMessageService: SystemMessageService,
    public pusherActionService: PusherActionsFrontendService,
    public statModelService: StatModelService,
    public scService: ScriptQueueFrontendService,
    public pusherService: PusherService,
    public navService: NavigationService,
    public idle: IdleService,
    public langService: LanguageService,
    public companyService: CompanyFrontendService,
    private eventService: ActivityLogService,
    private systemService: SystemService,
    private dialogService: DialogService,
    private reportService: ErrorReportService,
    private postLogin: PostLoginService,
    private readonly injector: Injector,
    private notificationFrontendService: NotificationFrontendService
  ) {
    ArrayExtensions.Extend();
    ObjectExtensions.Extend();
    DebugBoxHelper.setStore(store);
    if (!!environment.debugBox) {
      DebugBoxHelper.enabled = true;
    }
    this.eventService.setupSubs();
    this.postLogin.setupSubs();
    this.actions.dispatched(AppActions.ResolverLoading).subscribe((evt: AppActions.ResolverLoading) => {
      this.resolverLoading = evt.loading;
      this.resolverLoadingText = evt.loadText;
    });

    customElements.define('indicio-wiki-link', createCustomElement(WikiLinkComponent, { injector: injector }));
    customElements.define('indicio-wiki-math', createCustomElement(WikiMathJaxComponent, { injector: injector }));
  }

  public get indicioLink() {
    return 'https://www.indicio.com/';
  }

  public ngAfterViewInit() {
    this.modalService.setModalComponentLoader(this.modalComponent);
    const closeOptionMenusEvent = new Event('close-option-menu');

    document.querySelector('body').addEventListener('click', function (el) {
      if (!document.querySelector('.options-menu-container')) { return; }
      const target = (el as any).target;
      if (target && !target.hasAttribute('data-options-menu-toggler')) {
        this.dispatchEvent(closeOptionMenusEvent);
      }
    });

    window.addEventListener('new-tz-detected', this.openNewTZDialog.bind(this));

    let validStartUrl = false;
    if (this.validStartUrl(window.location.pathname)) {
      validStartUrl = true;
      this.authService.logoutWithoutRedirection();
    }

    this.authService.loginInProgress = true;
    this.authService.checkStoredAuth()
      .catch(() => {
        this.authService.loginInProgress = false;
        validStartUrl
          ? this.authService.logoutWithoutRedirection(false)
          : this.authService.logout(false);
      });


    this.loggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn === false) {
        this.systemMessagePosition = undefined;
        document.querySelector('body').classList.remove('light-theme');
        document.querySelector('body').classList.remove('dark-theme');
        document.querySelector('body').classList.remove('blue-theme');
        this.appearance.setLoginBg();
        if (this.validStartUrl(window.location.pathname)) {
          /* Do nothing? */
        } else {
          this.navService.navToUrlWithQuery('/login', { returnUrl: window.location.pathname + window.location.search });
        }

      } else {
        this.authService.loginInProgress = false;

        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
        returnUrl = returnUrl === '/' ? '/home' : returnUrl;
        if (this.appearance.settings.Theme === 'legacy') {
          this.appearance.setRandomBg();
        }
        this.router.navigateByUrl(returnUrl);
      }
      document.querySelector('body').classList.toggle('login-view', !isLoggedIn);
    });

    this.systemMessageService.message$.subscribe(value => {
      this.systemMessagePosition = value?.Position;
    });

    this.startVersionCheckTimeout();
  }

  public setContentScroll(evt: any) {
    this.scrollPosition = +evt?.target?.scrollTop || 0;
  }

  public validStartUrl(currentUrl: string): boolean {
    if (currentUrl.match(/signup/) || currentUrl.match(/reset-password/)) {
      return true;
    }
    return false;
  }

  private openNewTZDialog() {
    this.confirmRef = this.dialogService.openConfirmDialog({
      Message: 'A change in your timezone was detected, please reload the page before continuing.',
      Title: 'New timezone',
      HideCancel: true,
      ConfirmText: 'Reload',
      CloseOnClick: false,
      Style: 'warn'
    });
    this.confirmRef.subscribe(result => {
      this.confirmRef = null;
      window.location.reload();
    });
  }

  private startVersionCheckTimeout() {
    if (!!this.confirmRef || !environment.production) { return; }
    this.systemService.getFrontendVersion()
      .then(sysEnv => {
        this.versionCheckErrorCounter = 0;
        if (sysEnv.PackageVersion !== this.envService.env.PackageVersion) {
          this.confirmRef = this.dialogService.openConfirmDialog({
            Message: 'A new version of Indicio is available. Please reload the page to get the latest version.',
            Title: 'New version available',
            HideCancel: true,
            ConfirmText: 'Reload',
            Style: 'warn'
          });
          this.confirmRef.subscribe(result => {
            this.confirmRef = null;
            setTimeout(() => {
              this.startVersionCheckTimeout();
            }, NEW_VERSION_TIMEOUT);
            if (!result) { return; }
            window.location.reload();
          });
        }
      })
      .catch(err => {
        this.versionCheckErrorCounter++;
        if (this.versionCheckErrorCounter >= 3) {
          this.reportService.postFrontendIssue({
            Stacktrace: `New forecast version failed to fetch. Error was ${JSON.stringify(err)}`,
            Subject: 'Error in startVersionCheckTimeout()'
          });
        }
      });
    setTimeout(() => {
      this.startVersionCheckTimeout();
    }, NEW_VERSION_TIMEOUT);
  }
}
