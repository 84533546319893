import { Injectable } from '@angular/core';
import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { DateUtils } from '@shared/utils/date.utils';
import { ModelUtils } from '@shared/utils/forecast/model.utils';
import { PlotUtils } from '@shared/utils/plot.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import { StatModelHistoricDataDTO } from './dtos/stat-model-historic-data.dto';
import { StatModelDTO, StatModelFilesDTO, StatModelTransformationDTO } from './dtos/stat-model.dto';
import { StatModel, StatTransformationModel } from './stat-model.model';

@Injectable({ providedIn: 'root' })
export class StatModelMapper {

  constructor(
    private env: EnvironmentService
  ) {
  }

  public mapModel(dto: StatModelDTO, historicData: StatModelHistoricDataDTO[], isMultiModel: boolean = false): StatModel {
    const model = Object.faMapTo(new StatModel(), dto);
    model.modelName = this.env.getModelName(dto.Name + '_org_std');
    model.Transforms = dto.Transforms.map(t => this.mapTransform(t, isMultiModel, historicData));
    return model;
  }

  private mapTransform(dto: StatModelTransformationDTO, isMultiModel: boolean, hData?: StatModelHistoricDataDTO[]): StatTransformationModel {
    const model = Object.faMapTo(new StatTransformationModel(), dto);
    if (!model.ShapValues) { model.ShapValues = []; }
    model.TransformName = model.TransformName.replace(/(_norm$)/gm, '_std');
    model.WShapValues = [];
    model.modelName = this.env.getModelName(dto.Name);
    model.color = ModelUtils.getColorForModel(model.modelName);
    model.isMultiModel = isMultiModel;
    model.FittedValues.forEach(fv => DateUtils.MapIHasDate(fv));
    model.ShapValues?.forEach(sv => sv.Values.forEach(svV => DateUtils.MapIHasDate(svV)));
    model.Variables.forEach(variable => {
      const historic = hData?.find(x => x.ForecastVariableId === variable.Id);
      variable.Data.forEach(value => DateUtils.MapIHasDate(value));
      if (!variable.Historic.length && historic) {
        variable.Historic = historic.Values.map(v => ValueUtils.copyValue(v));
      } else {
        variable.Historic.forEach(value => DateUtils.MapIHasDate(value));
      }
      variable.Data = [...<PlotValueDTO[]> variable.Historic, ...variable.Data];
    });
    return model;
  }

  public mapPlotImages(dto: StatModelFilesDTO) {
    const modelName = this.env.getModelName(dto.ModelName);
    return Promise.all(dto.Files.map(i => PlotUtils.UnzipPlotImage(i, modelName.Display)))
      .then(all => {
        dto.Files = all;
        return dto;
      });
  }

  public mapTextFiles(dto: StatModelFilesDTO) {
    const modelName = this.env.getModelName(dto.ModelName);
    dto.Files.forEach(i => i.displayName = modelName.Display);
    return dto;
  }
}
