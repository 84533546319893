<h1 mat-dialog-title
    style="margin: 0">Edit forecast template</h1>
<div mat-dialog-content>
  <div class="create-template-container">
    <div class="ctc-options">
      <ng-container *ngTemplateOutlet="createOptionsTemplate"></ng-container>
    </div>
    <div class="ctc-info">
      <ng-container *ngTemplateOutlet="createInfoTemplate"></ng-container>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
  <button mat-flat-button
          color="primary"
          (click)="save()">
    Update
  </button>
</div>

<ng-template #createInfoTemplate>
  <div class="row"
       style="margin-bottom: 10px;">
    <div class="col-xs-12 bold"
         style="margin-left: 1px">Template information</div>
  </div>
  <div class="data-table compact-white">
    <div class="data-table__body"
         style="max-height: 500px;">
      <ng-container
                    *ngTemplateOutlet="infoTableRow, context: { title: 'Main variable:', value: template.MainForecastVariable.Name, useTippy: true }">
      </ng-container>
      <ng-container
                    *ngTemplateOutlet="infoTableRow, context: { title: 'Indicator count:', value: template.Indicators.length }">
      </ng-container>
      <ng-container
                    *ngTemplateOutlet="infoTableRow, context: { title: 'Historic events:', value: template.HistoricEvents.length }">
      </ng-container>
      <ng-container
                    *ngTemplateOutlet="infoTableRow, context: { title: 'Forecasted points:', value: template.Settings.Horizon }">
      </ng-container>
      <ng-container
                    *ngTemplateOutlet="infoTableRow, context: { title: 'Periodicity:', value: template.periodicity.Display }">
      </ng-container>
      <div class="body__row">
        <div class="column"
             style="width: 125px; padding: 10px 0 0 0 !important">Data providers</div>
        <div class="column crop-string"
             style="width: 155px; padding: 10px 0 0 0 !important">
          <ul>
            <li *ngFor="let p of template.Sources">{{ p.Source }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #infoTableRow
             let-title=title
             let-value=value
             let-useTippy=useTippy>
  <div class="body__row body__row--max-height-30">
    <div class="column"
         style="width: 125px; padding: 10px 0 0 0 !important">{{ title }}</div>
    <div class="column crop-string"
         style="width: 155px; padding: 10px 0 0 0 !important">
      <span [indicioTippy]="useTippy ? value : null">
        {{ value }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #createOptionsTemplate>
  <ng-container *ngIf="loader">
    <i class="ion-load-c spinner template-loader"></i>
  </ng-container>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Title">
    <mat-form-field>
      <input type="text"
             class="material"
             matInput
             autocomplete="off"
             [(ngModel)]="template.Title"
             name="Title"
             min=4
             required>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Description">
    <mat-form-field>
      <textarea matInput
                required
                [ngModel]="template.Description"
                (ngModelChange)="template.Description = $event"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMaxRows="1">
      </textarea>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            *ngIf="initialized"
                            optionTitle="Tags">
    <indicio-searchable-multiselect-dropdown [required]="true"
                                             [values]="templateService.availableTags"
                                             optionDisplay="Name"
                                             optionValue="ForecastTemplateTagId"
                                             [initializeWithFirstValue]="true"
                                             [selectedValue]="selectedTags"
                                             (valueChangeEvent)="onSelectTag($event)">
    </indicio-searchable-multiselect-dropdown>

    <span class="pointer dotted-underline"
          (click)="createNewTag()">Create new tag</span>
  </indicio-modal-option-row>
</ng-template>
