import { Component } from '@angular/core';
import { Uris } from '@core/constants/uri.constants';


@Component({
  selector: 'indicio-about-footer',
  templateUrl: './about-footer.component.html',
  styleUrls: ['./about-footer.component.less']
})
export class AboutFooterComponent {

  public indicioLink = Uris.IndicioHomepage;

}


