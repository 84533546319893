<h1 mat-dialog-title>Import Wiki DB</h1>
<div mat-dialog-content>
  Note: Only allowed upload format is the import zip-archive produced by the export function.
  <ng-container *ngTemplateOutlet="fileInputTemplate"></ng-container>

  <hr style="margin: 10px 0;">

  <div *ngIf="contentReady">
    <br>
    {{ fileInfo.name }}
    <hr style="margin: 10px 0;">
    File content: <br>
    Assets: {{ exportDto.Assets.length }} <br>
    Pages: {{ exportDto.Pages.length }} <br>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-stroked-button
          (click)="close()">
    Close
  </button>
  <button *ngIf="contentReady"
          mat-stroked-button
          (click)="import()">
    Import
  </button>
</div>

<ng-template #fileInputTemplate>
  <div>
    <input type="file"
           accept=".zip"
           id="file"
           name="file"
           multiple
           #fileinput
           (change)="fileChange(fileinput.files)">
    <div (dragover)="allowDrop($event)"
         (dragenter)="toggleDragOver(true)"
         (dragleave)="toggleDragOver(false)"
         (drop)="drop($event)"
         data-test-id="drag-and-drop-box"
         (click)="fileinput.click()"
         class="drag-and-drop-box">
      <p><i class="ion-upload"></i></p>
      <p>Click to browse files or drag them here.</p>
    </div>
  </div>
</ng-template>
