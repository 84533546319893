import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { HierarchyActions } from '@core/store/hierarchical-forecast/constants/hierarchy.actions';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { UpdateData } from './update-variables.dialog.entities';

export interface FVarUpdateDialogData {
  type: UpdateData.TargetTypes;
  /* The ObjectId is either: ForecastId, ProjectId or HierarchyId */
  ObjectId: string;
  /* The ObjectId is either for: Forecast, Project or Hierarchy */
  ObjectName: string;
  AutoStart: boolean;
}

@Component({
  selector: 'indicio-update-data-dialog',
  templateUrl: 'update-variables.dialog.html',
  styleUrls: ['./update-variables.dialog.less']
})
export class FVarUpdateDialogComponent extends DialogV2BaseDialog<FVarUpdateDialogComponent> implements OnInit {

  public static Id: string = 'FVarUpdateDialogComponent';
  public opts: FVarUpdateDialogData;

  /* Data */
  public title: string;
  public updateInfos: UpdateData.DataInfoDTO[] = [];

  /* Status flags and properties */
  public isLoading: boolean = true;
  public inProgress: boolean = false;
  public done: boolean = false;
  public errorMessage: string = null;

  public get count() { return this.updateInfos.reduce((a, b) => a + b.Variables.length, 0); }
  public get synced() { return this.updateInfos.reduce((a, b) => a + b.Variables.filter(v => v.status === 'Updated').length, 0); }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: FVarUpdateDialogData,
    public dialogRef: MatDialogRef<FVarUpdateDialogComponent>,
    private companyService: CompanyFrontendService,
    private fVarService: ForecastVariableFrontendService,
    private store: Store,
  ) {
    super(dialogRef);
    this.opts = data;
  }

  public ngOnInit() {
    this.initialize();
  }

  protected initialize() {
    this.isLoading = true;
    this.setTitle();
    this.getUpdateInfo();
    this.initialized = true;
  }

  public get okText(): string {
    return this.updateInfos.every(x => x.Variables.every(v => v.status === 'Error' || v.status === 'Updated')) ? 'Close' : 'Update';
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public async update() {
    this.inProgress = true;
    for (let i = 0; i < this.updateInfos.length; i++) {
      const fcast = this.updateInfos[i];
      for (let j = 0; j < fcast.Variables.length; j++) {
        const v = fcast.Variables[j];
        v.status = 'Updating';
        try {
          await this.fVarService.updateForecastVariableValues(v.ForecastVersionId, v.ForecastVariableId);
          v.status = 'Updated';
        } catch (err) {
          v.status = 'Error';
          v.errorMessage = err;
        }
        fcast.numComplete = fcast.Variables.filter(x => x.status === 'Updated' || x.status === 'Error').length;
      }
      if (+fcast.numComplete > 0) {
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        this.store.dispatch(new HierarchyActions.TriggerGenerateTree);
      }
    }
    this.inProgress = false;
    this.done = true;
  }

  private getUpdateInfo() {
    this.companyService.getUpdatableForecastVariableInfos(this.opts.type, this.opts.ObjectId)
      .then(infos => {
        this.updateInfos = infos;
        this.updateInfos.forEach(info => info.Variables = [...info.Variables.filter(x => x.IsIndicator), ...info.Variables.filter(x => !x.IsIndicator)]);
        if (!!this.updateInfos.length && this.opts.AutoStart) {
          this.update();
        }
      })
      .catch(err => {
        this.errorMessage = 'Could not update data.';
      })
      .finally(() => this.isLoading = false);
  }

  private setTitle() {
    switch (this.opts.type) {
      case 'forecast':
        this.title = 'Update forecast variables used in forecast';
        break;
      case 'project':
        this.title = 'Update forecast variables used in project';
        break;
      case 'hierarchy':
        this.title = 'Update forecast variables used in hierarchy';
        break;
    }
  }
}
