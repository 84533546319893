import { AccuracyMeasureDTO } from '@core/entities/dtos/accuracy-measure-dto';
import { DoubleValuePropertyDTO } from '@core/entities/dtos/double-value-property-dto';

export namespace ErrorBarChart {

  export class Input {
    public title: 'Model accuracy' | 'Result accuracy';
    public transformations: Model[] = [];
    public horizon: number = 1;
    public oosEnabled: boolean = false;
    public type: 'univariate' | 'multivariate' | 'summary' = 'univariate';
    public wikiArticle: string;
    public noDataText: string = 'No accuracy values available, please re-run the models to view new accuracy measures.';
  }

  export class Model {
    public display: string; // StatTransformationModel.modelName.Display
    public show: boolean;   // StatTransformationModel.show
    public color: string;   // StatTransformationModel.color
    public name: string;    // StatTransformationModel.Name

    public InProperties: DoubleValuePropertyDTO[];
    public OutProperties: DoubleValuePropertyDTO[];

    public MAESteps: AccuracyMeasureDTO[];
    public MAPESteps: AccuracyMeasureDTO[];
    public MESteps: AccuracyMeasureDTO[];
    public MPESteps: AccuracyMeasureDTO[];
    public RMSESteps: AccuracyMeasureDTO[];
    public HRSteps: AccuracyMeasureDTO[];
    public MASESteps: AccuracyMeasureDTO[];
    public RSQSteps: AccuracyMeasureDTO[];
  }

}
