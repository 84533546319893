import { Component } from '@angular/core';

import { priorityOptions } from '@core/constants/priority-order';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { CreateNewsPostDTO } from '@core/store/news/dtos/create-news-post.dto';
import { NewsFrontendService } from '@core/store/news/news.frontend.service';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateUtils } from '@shared/utils/date.utils';
import { CreateNewsModalOpts } from './create-news-modal.options';

@Component({
  selector: 'indicio-create-news-modal',
  templateUrl: './create-news-modal.component.html',
  styleUrls: ['./create-news-modal.component.less']
})
export class CreateNewsModalComponent extends ModalModelComponent {

  pending: boolean;

  title: string;
  preview: string;
  body: string;
  hidden: boolean;
  hideAuthor: boolean;
  publishDate: string;
  priority: number;
  disableComments: boolean;

  unsaved: boolean;

  public get priorityOptions() { return priorityOptions; }

  constructor(
    private status: StatusService,
    private newsService: NewsFrontendService,
    public envService: EnvironmentService,

  ) { super(); }

  setOptions(options: CreateNewsModalOpts) {
    this.onClose = function () {
      this.savePost.call(this, arguments);
      if (options.onClose) {
        options.onClose.call(this, arguments);
      }
    };
    this.isLoading = false;
    this.loadPost();
  }

  savePost() {
    const post: CreateNewsPostDTO = this.createPostModel();
    if (Object.keys(JSON.parse(JSON.stringify(post))).length) {
      localStorage.setItem('news.create', JSON.stringify(post));
    } else {
      localStorage.removeItem('news.create');
    }
  }

  loadPost() {
    const post: CreateNewsPostDTO = JSON.parse(localStorage.getItem('news.create'));
    if (post && Object.keys(post).length) {
      this.body = post.Body;
      this.preview = post.Preview;
      this.title = post.Title;
      this.hideAuthor = post.HideAuthor;
      this.priority = post.Priority;
      this.disableComments = post.DisableComments;
      this.hidden = post.Hidden;

      this.unsaved = true;
    }
  }

  createPost() {
    this.pending = true;

    const post: CreateNewsPostDTO = this.createPostModel();

    if (this.publishDate) {
      post.PublishDate = DateUtils.newDate(this.publishDate).toDateString();
    }


    this.newsService.createNewsPost(post)
      .then(() => {
        this.pending = false;
        this.reset();
        this.close();
      })
      .catch(error => {
        this.pending = false;
        this.status.setError(error);
      });
  }

  private createPostModel(): CreateNewsPostDTO {
    return {
      Body: this.body,
      Preview: this.preview,
      Title: this.title,
      HideAuthor: this.hideAuthor,
      Priority: this.priority,
      DisableComments: this.disableComments,
      Hidden: this.hidden
    };
  }

  private reset() {
    this.body = undefined;
    this.preview = undefined;
    this.title = undefined;
    this.hideAuthor = undefined;
    this.priority = undefined;
    this.disableComments = undefined;
    this.hidden = undefined;
  }
}
