import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { HistoricEventUtils } from '@shared/utils/forecast/event.utils';
import * as moment from 'moment';

export class ImportedHistoricEventCorrelationModel {
  @Def() FutureHistoricEventId: string;
  @Def() PastHistoricEventId: string;
  @Def() Strength: number;
}

export class ImportedHistoricEventModel {
  @Def() ImportedHistoricEventId: string;
  @Def() ImportedHistoricBaseEventId: string;
  @Def() Name: string;
  @Def() EffectType: string;
  @Def() Date: moment.Moment;
  @Def() EndDate: moment.Moment;
  @Def() Correlations: ImportedHistoricEventCorrelationModel[] = [];

  #open: boolean = false;
  public get open() { return this.#open; }
  public set open(state: boolean) { this.#open = state; }

  public getCorrelationForId(id: string) {
    return this.Correlations.find(x => x.PastHistoricEventId === id);
  }
}

export class ImportedHistoricBaseEventModel implements IHasModelId {
  @Def() ImportedHistoricBaseEventId: string;
  @Def() ForecastVersionId: string;
  @Def() HistoricBaseEventId: string;
  @Def() Name: string;
  @Def() Type: string;
  @Def() Location: string;
  @Def() Events: ImportedHistoricEventModel[] = [];
  @Def() Order: number;


  #open: boolean = true;
  public get open() { return this.#open; }
  public set open(state: boolean) { this.#open = state; }


  public getPastEventsForEvent(event: ImportedHistoricEventModel, startDate: Date, untilDate: Date, periodicity: string) {
    let events = this.Events.filter(e => HistoricEventUtils.isInHistory(e, startDate, periodicity));
    events = events.filter(e => !HistoricEventUtils.isPartiallyInFuture(e, untilDate, periodicity));
    events = events.filter(e => !HistoricEventUtils.filterSelf(e, event.ImportedHistoricEventId));
    events = events.filter(e => HistoricEventUtils.filterType(e, event.EffectType));
    return events;
  }

  public getPastEvents(startDate: Date, untilDate: Date, periodicity: string) {
    return this.Events.filter(e => HistoricEventUtils.isInHistory(e, startDate, periodicity) &&
      !HistoricEventUtils.isPartiallyInFuture(e, untilDate, periodicity));
  }

  public getModelId(): string {
    return this.ImportedHistoricBaseEventId;
  }
}
