import { Component } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DeleteFVarModalOptions } from './delete-forecast-variable-modal.options';


@Component({
  selector: 'indicio-delete-forecast-variable-modal',
  templateUrl: './delete-forecast-variable-modal.component.html',
  styleUrls: ['./delete-forecast-variable-modal.component.less'],
})
export class DeleteForecastVariableModalComponent extends ModalModelComponent {

  public variables: ForecastVariableModel[] = [];
  public callback: any;

  public isLoading = true;

  public removedCount = 0;

  constructor(
    protected store: Store,
    private status: StatusService,
    private service: ForecastVariableFrontendService,
    public envService: EnvironmentService,
    public appearance: AppearanceService
  ) { super(); }

  public setOptions(options: DeleteFVarModalOptions) {
    this.variables = options.forecastVariables;
    this.callback = options.callback;
    this.isLoading = false;

    if (options.callback) {
      this.onClose = () => {
        this.callback();
      };
    }
  }

  public async removeVariables() {
    this.pending = true;
    const toRemove = this.variables;
    const count = toRemove.length;
    const removeFunc = async (variable) => {
      await this.removeForecastVariable(variable);
    };

    let current = 0;

    const queueFunc = async () => {
      if (current >= count) {
        this.pending = false;
        return;
      }
      await removeFunc(toRemove[current]);
      current += 1;
      setTimeout(async () => {
        await queueFunc();
      }, 20);
    };

    await queueFunc();
  }

  private async removeForecastVariable(variable: ForecastVariableModel) {
    if (!variable) { return; }
    variable.removedStatus = 'Removing';
    return this.service.deleteForecastVariable(variable.ForecastVariableId, variable.ForecastVersionId)
      .then(_fvar => {
        variable.removedStatus = 'Success';
      })
      .catch(err => {
        variable.removedStatus = 'Failed';
        variable.failedMsg = this.status.getMessage(err).message;
      }).finally(() => this.removedCount += 1);
  }
}


