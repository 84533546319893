import { Def } from '@core/decorators/def';
import { EmployeeDTO } from '@core/store/company/dtos/employee-dto';
import { CompanyHorizonInfoDTO } from './company-dto';

export class UpdateCompanyDTO {
  @Def() CompanyId: string;
  @Def() CountryCode: string;
  @Def() Employees: EmployeeDTO[];
  @Def() FiscalYear: number;
}

export class AdminUpdateCompanyDTO extends UpdateCompanyDTO {
  @Def() Name: string;
  @Def() MaxUsers: number;
  @Def() LicenseType: string;
  @Def() MaxRollingWindowTime: number;
  @Def() MacroBondEnabled: boolean;
  @Def() TrialExpirationDate: string;
  @Def() MaxHorizons: CompanyHorizonInfoDTO[];
  @Def() Support: string[] = [];
  @Def() DisabledProviders: string[];
}


export class SupportUpdateCompanyDTO extends UpdateCompanyDTO {
  @Def() MaxUsers: number;
  @Def() TrialExpirationDate: string;
}
