<indicio-modal-base [child]="this"
                    data-test-id="profile-modal-container"
                    modalTitle="{{profile.FirstName}} {{profile.LastName}}"
                    modalExtraClass="small"
                    extraClasses="small">

  <ng-container *ngTemplateOutlet="menuTemplate">
  </ng-container>
  <ng-container [ngSwitch]="view">
    <ng-container *ngSwitchCase="'General'">
      <ng-container *ngTemplateOutlet="generalTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Appearance'">
      <ng-container *ngTemplateOutlet="appearanceTemplate">
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Accounts'">
      <ng-container *ngTemplateOutlet="accountsTemplate">
      </ng-container>
    </ng-container>
  </ng-container>
</indicio-modal-base>

<ng-template #menuTemplate>
  <div class="modal-menu"
       data-test-id="profile-modal-menu">
    <div class="col-xs-4 menu-button"
         data-test-id="profile-modal-menu-general-btn"
         [ngClass]="{ 'current': view === 'General'}"
         (click)="changeView('General')">
      General
    </div>
    <div class="col-xs-4 menu-button"
         data-test-id="profile-modal-menu-appearance-btn"
         [ngClass]="{ 'current': view === 'Appearance'}"
         (click)="changeView('Appearance')">
      Appearance
    </div>
    <div class="col-xs-4 menu-button"
         data-test-id="profile-modal-menu-accounts-btn"
         [ngClass]="{ 'current': view === 'Accounts'}"
         (click)="changeView('Accounts')">
      Accounts
    </div>
  </div>
</ng-template>

<ng-template #accountsTemplate>
  <div>
    <indicio-modal-option-row *ngFor="let dataSource of dataSources"
                              textWidth="11"
                              optionsWidth="1"
                              [optionTitle]="environment.getSourceType(dataSource.Provider).Display"
                              [optionSubtitle]="environment.getSourceType(dataSource.Provider).Description">
      <div class="input-label pull-right cursor-pointer"
           (click)="editDataSource(dataSource)">
        <i class="ion-edit"></i>
      </div>
    </indicio-modal-option-row>
    <div class="row extra-margin-top">
      <div class="col-xs-12 italic">Accounts listed here are used to access 3rd party data providers.</div>
    </div>
  </div>
</ng-template>

<ng-template #appearanceTemplate>
  <div class="modal-space">
    <form #f="ngForm">
      <div class="row">
        <!-- Date format -->
        <div class="col-xs-12"
             title="Set project Date format">
          <mat-form-field>
            <mat-label>Date format</mat-label>
            <mat-select [(value)]="appearance.DateFormat">
              <mat-option [value]="option.value"
                          [title]="option.title"
                          *ngFor="let option of DateFormatOptions">{{ option.display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-top">
        <!-- Time format -->
        <div class="col-xs-12"
             title="Set project Time format">
          <mat-form-field>
            <mat-label>Time format</mat-label>
            <mat-select [(value)]="appearance.TimeFormat">
              <mat-option [value]="option.value"
                          [title]="option.title"
                          *ngFor="let option of TimeFormatOptions">{{ option.display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-top">
        <!-- Background Overlay -->
        <div class="col-xs-12"
             title="Set background overlay">
          <mat-form-field>
            <mat-label>Color theme</mat-label>
            <mat-select [(value)]="currentTheme"
                        (selectionChange)="changeTheme($event)">
              <mat-option [value]="option.value"
                          [title]="option.title"
                          [disabled]="option.admin && !auth.isAdmin"
                          *ngFor="let option of ThemeOptions">
                {{ option.display }}
                <ng-container *ngIf="option.admin && !auth.isAdmin">
                  (coming soon)
                </ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row modal-space">
        <div class="col-xs-6">
          <button class="btn outline blue left cancel"
                  type="button"
                  (click)="close()">
            <span *ngIf="isProfileChanged || isAppearanceChanged">Cancel</span>
            <span *ngIf="!isProfileChanged && !isAppearanceChanged">Close</span>
          </button>
        </div>
        <div class="col-xs-6">
          <button class="btn right save"
                  (click)="saveAppearance()"
                  [ngClass]="{'progress': pending}">Save</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #generalTemplate>
  <form #f="ngForm"
        data-test-id="profile-modal-general-form"
        (submit)="update()"
        ngNativeValidate>
    <div class="row">
      <div class="col-xs-12 text-field text-center">Username: {{ profile.Email }}</div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <mat-form-field>
          <mat-label>Firtst name</mat-label>
          <input required
                 class="material"
                 matInput
                 type="text"
                 name="FirstName"
                 id="FirstName"
                 placeholder="First name"
                 [(ngModel)]="profile.FirstName">
        </mat-form-field>
      </div>
    </div>
    <div class="row margin-top">
      <div class="col-xs-12">
        <mat-form-field>
          <mat-label>Last name</mat-label>
          <input required
                 matInput
                 class="material"
                 type="text"
                 name="LastName"
                 id="LastName"
                 placeholder="Last name"
                 [(ngModel)]="profile.LastName">
        </mat-form-field>
      </div>
    </div>
    <div class="row margin-top">
      <div class="col-xs-12">
        <mat-form-field>
          <mat-label>Short Bio</mat-label>
          <input required
                 matInput
                 class="material"
                 type="text"
                 name="Bio"
                 id="Bio"
                 placeholder="Short bio"
                 [(ngModel)]="profile.Bio">
        </mat-form-field>
      </div>
    </div>
    <div class="row margin-top">
      <div class="col-xs-12">
        <indicio-searchable-dropdown [required]="true"
                                     label="Select Country"
                                     [values]="environment.Countries"
                                     optionDisplay="Display"
                                     [initializeWithFirstValue]="false"
                                     [selectedValue]="country"
                                     (valueChangeEvent)="country = $event">
        </indicio-searchable-dropdown>
      </div>
    </div>

    <div class="row margin-top">
      <div class="col-xs-12">
        <mat-form-field>
          <mat-label>Select decimal character for downloaded data files</mat-label>
          <mat-select [(value)]="decimalCharacter">
            <mat-option [value]="decimal"
                        *ngFor="let decimal of decimalSettings">{{ decimal }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <indicio-modal-option-row *ngIf="clientService.client.CompanyIds.length > 1"
                              optionTitle="Remember Active Company"
                              optionSubtitle=""
                              textWidth="10"
                              optionsWidth="2">
      <div class="toggle-icon pull-right"
           style="margin-top: 25px;">
        <mat-slide-toggle [checked]="appearance.settings.RememberActiveCompany"
                          (change)="toggleRememberActiveCompany()">
        </mat-slide-toggle>
      </div>
    </indicio-modal-option-row>

    <div class="row margin-top">
      <div class="col-xs-12">
        <input type="hidden"
               name="ProfilePicture"
               id="ProfilePicture"
               placeholder="Profile Picture"
               [(ngModel)]="profile.ProfilePicture">
      </div>
      <div class="col-xs-6">
        <button (click)="close()"
                data-test-id="profile-modal-general-close-btn"
                type="button"
                class="btn outline blue left">
          <span *ngIf="isProfileChanged || isAppearanceChanged">Cancel</span>
          <span *ngIf="!isProfileChanged && !isAppearanceChanged">Close</span>
        </button>
      </div>
      <div class="col-xs-6">
        <button type="submit"
                data-test-id="profile-modal-general-save-btn"
                class="btn right"
                [ngClass]="{'progress': pending, disabled: !isProfileChanged}">
          <i *ngIf="pending"
             class="ion-load-c spinner"></i>
          <span>Save</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
