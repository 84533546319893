import { Injectable } from '@angular/core';
import { IMemberModel } from '@core/interfaces/if-member';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { InviteDTO } from '@core/store/invites/dtos/invite-dto';
import { ProjectInviteDTO } from '@core/store/invites/dtos/project-invite-dto';
import { InviteModel } from '@core/store/invites/invite.model';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable({
  providedIn: 'root'
})
export class InviteMapper {

  constructor(
    private envService: EnvironmentService
  ) { }

  public map(dto: InviteDTO): InviteModel {
    const model = new InviteModel();
    Object.assign(model, dto);
    return model;
  }

  public toProjectInvite(companyId: string, dto: ProjectInviteDTO): IMemberModel {
    const model: IMemberModel = {
      Email: dto.Email,
      MemberRole: this.envService.getProjectRole(dto.ProjectRole),
      ProjectId: dto.ProjectId,
      CompanyId: companyId,
      PendingSignup: true,
      ClientId: null,
      IsCurrentUser: false,
      IsOwner: false,
      Expires: null,
      CreatedDate: DateUtils.newDate(),
      Disabled: false
    };
    return model;
  }

  public toProjectInviteDTO(model: IMemberModel): ProjectInviteDTO {
    const dto = new ProjectInviteDTO();
    dto.Email = model.Email;
    dto.ProjectId = model.ProjectId;
    dto.ProjectRole = model.MemberRole.Value;
    return dto;
  }
}
