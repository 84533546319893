import { DisplayValue } from '@modules/lang/types/display-value';

export enum ProviderModals {
  AlphaForex = 'alphaForex',
  AlphaStock = 'alphaStock',
  AlphaCrypto = 'alphaCrypto',
  Alphavantage = 'alphavantage',
  Refinitiv = 'refinitiv'
}


export enum DataProvider {
  scb = 'scb',
  ftp = 'ftp',
  fred = 'fred',
  valueguard = 'valueguard',
  macrobondapi = 'macrobondapi',
  kolada = 'kolada',
  quandl = 'quandl',
  alphavantage = 'alphavantage',
  eurostat = 'eurostat',
  bis = 'bis',
  refinitiv = 'refinitiv',
  oxford = 'oxford',
  sharepoint = 'sharepoint',
  googletrends = 'googletrends',
  oecd = 'oecd',
  acea = 'acea',
  file = 'file',
  sqldatabase = 'sqldatabase',
  statfin = 'statfin',
  statcan = 'statcan',
  statdan = 'statdan',
  statnor = 'statnor',
  bloomberg = 'bloomberg'
}

export namespace DataProviders {
  export const OpenIdProviders: DisplayValue<DataProvider>[] = [
    { Display: 'Macrobond', Value: DataProvider.macrobondapi },
  ];
}

export class ProviderDefinitions {
  public static getDataProviders() {

    const scb = {
      Name: DataProvider.scb,
      Icon: '/assets/icons/scb-icon.svg',
      Url: 'providers/scb'
    };

    const eurostat = {
      Name: DataProvider.eurostat,
      Icon: '/assets/icons/eurostat.icon.svg',
      Url: 'providers/eurostat',
    };

    const fred = {
      Name: DataProvider.fred,
      Icon: '/assets/icons/fred-temp.jpg',
      Url: 'providers/fred',
    };

    const valueguard = {
      Name: DataProvider.valueguard,
      Icon: '/assets/icons/valueguard.icon.svg',
      Url: 'providers/valueguard'
    };

    const kolada = {
      Name: DataProvider.kolada,
      Icon: '/assets/icons/kolada.icon.svg',
      Url: 'providers/kolada'
    };

    const bloomberg = {
      Name: DataProvider.bloomberg,
      Icon: '/assets/icons/bloomberg.png',
      Url: 'providers/bloomberg'
    };

    const quandl = {
      Name: DataProvider.quandl,
      Icon: '/assets/icons/quandl.icon.svg',
      Url: 'providers/quandl'
    };

    const alphavantage = {
      Name: DataProvider.alphavantage,
      Icon: '/assets/icons/Alpha_Vantage_icon.svg',
      Modal: ProviderModals.Alphavantage
    };

    const bis = {
      Name: DataProvider.bis,
      Icon: '/assets/icons/bis.icon.svg',
      Url: 'providers/bis'
    };

    const refinitiv = {
      Name: DataProvider.refinitiv,
      Icon: '/assets/icons/refinitiv.icon.svg',
      Modal: ProviderModals.Refinitiv
    };

    const oecd = {
      Name: DataProvider.oecd,
      Icon: '/assets/icons/oecd_icon.svg',
      Url: 'providers/oecd'
    };

    const sharepoint = {
      Name: DataProvider.sharepoint,
      Icon: '/assets/icons/sharepoint_icon.svg',
      Url: 'providers/sharepoint'
    };

    const oxford = {
      Name: DataProvider.oxford,
      Icon: '/assets/icons/OE_icon.svg',
      Url: 'providers/oxford'
    };

    const statfin = {
      Name: DataProvider.statfin,
      Icon: '/assets/icons/statfin.png',
      Url: 'providers/statfin'
    };

    const acea = {
      Name: DataProvider.acea,
      Icon: '/assets/icons/acea_icon.svg',
      Url: 'providers/acea'
    };

    const statcan = {
      Name: DataProvider.statcan,
      Icon: '/assets/icons/statcan-icon.svg',
      Url: 'providers/statcan'
    };

    const statdan = {
      Name: DataProvider.statdan,
      Icon: '/assets/icons/statdan-header.svg',
      Url: 'providers/statdan'
    };

    const statnor = {
      Name: DataProvider.statnor,
      Icon: '/assets/icons/statnor-icon.svg',
      Url: 'providers/statnor'
    };

    const googletrends = {
      Name: DataProvider.googletrends,
      Icon: '/assets/icons/google-trends-icon.svg',
      Url: 'providers/googletrends'
    };

    const sql = {
      Name: DataProvider.sqldatabase,
      Icon: '/assets/icons/sql-server.png'
    };

    return [
      refinitiv,
      oxford,
      bloomberg,
      fred,
      kolada,
      quandl,
      alphavantage,
      eurostat,
      bis,
      sharepoint,
      scb,
      acea,
      googletrends,
      oecd,
      sql,
      statfin,
      statnor,
      statcan,
      statdan
    ];
  }
}
