import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ForecastTemplateFrontendService } from '@core/store/forecast-template/forecast-template.frontend.service';
import { ForecastTemplateTagDTO } from '@core/store/forecast-template/forecast-template.model';
import { TagTypes } from '@core/store/tags/dtos/tags.dtos';
import { TagModel } from '@core/store/tags/models/tag.model';
import { TagsFrontendService } from '@core/store/tags/tags.frontend.service';

export class CreateTemplateTagDialogData {

}

@Component({
  selector: 'indicio-create-template-tag-dialog',
  templateUrl: 'create-template-tag.dialog.html',
})
export class CreateTemplateTagDialogComponent {
  public static Id: string = 'CreateTemplateTagDialogComponent';


  public newTag: ForecastTemplateTagDTO = new ForecastTemplateTagDTO();
  public importTag: ForecastTemplateTagDTO;
  public nameError: string = null;

  private availableTags: ForecastTemplateTagDTO[] = [];

  public selectedTag: TagModel = null;
  public get availableCompanyTags() { return this.tagService.tags; }

  constructor(
    public dialogRef: MatDialogRef<CreateTemplateTagDialogComponent>,
    private templateService: ForecastTemplateFrontendService,
    private tagService: TagsFrontendService,
    @Inject(MAT_DIALOG_DATA) public data: CreateTemplateTagDialogData
  ) {
    this.syncTags();
  }

  private syncTags() {
    return this.templateService.getTags()
      .then(tags => {
        this.availableTags = tags;
      });
  }

  onSelectTag(tag: TagModel) {
    this.selectedTag = tag;

    if (!this.importTag) { this.importTag = new ForecastTemplateTagDTO(); }

    this.importTag.Name = tag.Name;

    if (tag.Type === TagTypes.CONTINENT || tag.Type === TagTypes.COUNTRY) {
      this.importTag.Type = TagTypes.USERDEFINEDREGION;
    } else {
      this.importTag.Type = tag.Type;
    }

    if (this.availableTags.some(x => x.Name.toLocaleLowerCase() === this.importTag.Name.toLocaleLowerCase())) {
      this.nameError = 'Tag name already exists.';
    } else {
      this.nameError = null;
    }
  }

  public toggleRegion($evt: MatCheckboxChange) {
    this.newTag.Type = $evt.checked ? TagTypes.USERDEFINEDREGION : TagTypes.USERDEFINED;
  }

  public setName(name) {
    this.newTag.Name = name;
    if (this.newTag.Name?.length < 3) {
      this.nameError = 'Tag name must be at least 3 characters long.';
    } else if (this.availableTags.some(x => x.Name.toLocaleLowerCase() === this.newTag.Name.toLocaleLowerCase())) {
      this.nameError = 'Tag name already exists.';
    } else {
      this.nameError = null;
    }
  }

  public newTagValid(): boolean {
    return this.newTag.Name?.length >= 3 && !this.nameError || this.importTag?.Name?.length >= 3;
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public save() {
    if (this.importTag) { this.newTag = this.importTag; }
    return this.templateService.createTag(this.newTag)
      .then(newTag => {
        this.dialogRef.close(newTag);
      });
  }
}
