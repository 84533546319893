<h1 mat-dialog-title>{{ data.Title }}</h1>
<div mat-dialog-content>
  <div *ngIf="data.Text"
       [style]="{ color: data.TextColor ? data.TextColor : null}"
       style="margin-bottom: 20px;">
    {{ data.Text }}
  </div>
  <mat-form-field *ngIf="type === 'text-input'">
    <mat-label>{{ data.Label }}</mat-label>
    <input type="text"
           class="material"
           matInput
           autocomplete="off"
           [ngModel]="answer"
           (ngModelChange)="setAnswer($event)"
           name="Name"
           indicioAutoFocusInput
           maxlength="256"
           require>
  </mat-form-field>
  <mat-form-field *ngIf="type === 'text-area'">
    <textarea matInput
              required
              [ngModel]="answer"
              (ngModelChange)="setAnswer($event)"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMaxRows="2">
      </textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">{{ data.CancelText}}</button>
  <button [disabled]="answer.length === 0"
          mat-flat-button
          [mat-dialog-close]="answer">
    {{ data.ConfirmText }}
  </button>
</div>
