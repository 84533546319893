import * as d3 from 'd3';
import { CurveFactory } from 'd3';
import * as moment from 'moment';

export namespace SLGOptions {
  export class ValueModel {
    Date: Date;
    Value: number;
    moment: moment.Moment;
  }

  export enum AxisPosition {
    left = 'left',
    right = 'right',
    bottom = 'bottom'
  }

  export class Data {
    Name: string;
    Data: ValueModel[] = [];
    AxisPosition: AxisPosition = AxisPosition.left;
    // HEX color
    Color: string = 'white';
    StrokeWidth: number = 3;
  }


  export class AxesOptions {
    Min: number | Date;
    Max: number | Date;
    Dates: Date[] = [];
    TickCount: number = 4;
    Color: string = 'black';
    Position: AxisPosition = AxisPosition.left;
    Id = Math.random().toString(36).substring(10);
  }

  export class Options {
    Data: Data[] = [];
    LeftAxis: AxesOptions;
    RightAxis: AxesOptions = null;
    TimeAxis: AxesOptions;
    Periodicity: string;
    Height: number = 300;
    Width: number = 630;
    BorderWidth = 2;
    InnerPadding = 10;
    BackgroundColor = '#ebebeb';
    Margins: { top: number, right: number, bottom: number, left: number; } = { top: 50, right: 50, bottom: 50, left: 50 };
    Curve: CurveFactory = d3.curveMonotoneX;
    DotSize = 3;
  }

  export class SVGElements {
    svg: d3.Selection<SVGGElement, {}, HTMLElement, any> = null;
    xScale: d3.ScaleTime<number, number>;
    yScaleLeft: d3.ScaleLinear<number, number>;
    yScaleRight: d3.ScaleLinear<number, number>;
    graphGroup: d3.Selection<SVGGElement, {}, HTMLElement, any>;
    innerGraph: d3.Selection<SVGGElement, {}, HTMLElement, any>;
    background: d3.Selection<SVGGElement, {}, HTMLElement, any>;
  }
}

