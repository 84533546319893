<indicio-dialog-base>
  <h1 mat-dialog-title>Forecast templates</h1>
  <div mat-dialog-content>
    <div class="grid-system"
         style="--grid-template: 250px 1fr; position: relative;">
      <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
      <ng-container *ngIf="loadingContent; else templatesTemplate">
        <span>Loading content <indicio-spinner [size]="14"></indicio-spinner></span>
      </ng-container>
    </div>
  </div>
  <div mat-dialog-actions>
    <div></div>
    <button mat-button
            (click)="onNoClick()">
      {{ !data.project ? 'Close' : 'Cancel' }}
    </button>
  </div>
</indicio-dialog-base>

<ng-template #templatesTemplate>
  <div class="grid-system"
       style="gap: 10px;"
       *ngIf="filteredTemplates?.length; else noFilteredTemplatesTemplate">
    <ng-container *ngFor="let template of filteredTemplates">
      <indicio-forecast-template-card [template]="template"
                                      [project]="data.project">
      </indicio-forecast-template-card>
    </ng-container>
  </div>
</ng-template>

<ng-template #noFilteredTemplatesTemplate>
  <ng-container *ngIf="templates?.length; else noTemplatesTemplate">
    No templates found given your filter criteria.
  </ng-container>
</ng-template>

<ng-template #noTemplatesTemplate>
  No templates found.
</ng-template>

<ng-template #filterTemplate>
  <div class="filter">
    <h3>Filters</h3>
    <div style="gap: 10px">
      <div>
        <div class="title">title:</div>
        <mat-form-field>
          <mat-label [style.opacity]="!!titleFilterActive && !!!titleString ? '0':null">Filter by title</mat-label>
          <input matInput
                 (focus)="titleFilterActive = true"
                 (blur)="titleFilterActive = false"
                 [ngModel]="titleString"
                 (ngModelChange)="titleString  = $event; filterTitle()" />
          <button *ngIf="titleString"
                  matSuffix
                  disableRipple
                  mat-icon-button
                  aria-label="Clear"
                  (click)="titleString=''; filterTitle()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div style="margin-top: 15px">
        <div class="title">author</div>
        <indicio-searchable-dropdown [values]="creatorFilters"
                                     (valueChangeEvent)="setCreatorFilterValue($event.value)"
                                     optionDisplay="display"></indicio-searchable-dropdown>
      </div>
      <div style="margin-top: 15px">
        <div class="title">tags</div>
        <indicio-searchable-dropdown [values]="tagFilter.values"
                                     [selectedValue]="tagFilter.value"
                                     [multiple]="true"
                                     (valueChangeEvent)="setTagFilterValue($event)"
                                     optionDisplay="display"></indicio-searchable-dropdown>
      </div>
      <div style="margin-top: 15px">
        <div class="title">regions</div>
        <indicio-searchable-dropdown [values]="regionFilter.values"
                                     [selectedValue]="regionFilter.value"
                                     [multiple]="true"
                                     (valueChangeEvent)="setRegionFilterValue($event)"
                                     optionDisplay="display"></indicio-searchable-dropdown>
      </div>
      <div style="margin-top: 15px">
        <div class="title">data providers</div>
        <mat-form-field>
          <mat-select [multiple]="true"
                      [ngModel]="providerFilter.value"
                      (selectionChange)="setProviderFilterValue($event.value)">
            <mat-option *ngFor="let pFilter of providerFilter.values | orderBy: 'display'"
                        [value]="pFilter.value">
              {{ pFilter.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>
