import { SimplePlotValue } from '@core/entities/dtos/plot-value-dto';

export class ReconciliationEntryMeta {
  Name: string;
  Values: { Date: string, Value: string; }[];
  FittedValues: SimplePlotValue[];
  Residuals: SimplePlotValue[];
}

export class ReconciliationReport {
  Name: string;
  Entries: ReconciliationEntryMeta[] = [];
  Creator: string;
  StartDate: string;
  EndDate: string;
}
