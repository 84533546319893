import { CompanyModalViewTypes } from './company-modal.component';
import { CompanyModalOpts } from './company-modal.options';

export class OpenCompanyModal {
  public static readonly type = '[MODAL] Open company modal';
  data: CompanyModalOpts;
  constructor(
    public companyId: string,
    public view?: CompanyModalViewTypes
  ) { this.data = { companyId, view }; }
}
