import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { DatabaseConnectionActions } from '@modules/forecast/views/add-data/tab-database/tab-database.actions';
import { Store } from '@ngxs/store';
import { DbColumnInfoDTO, DbTableInfoDTO } from '../../sql-database.dtos';
import { DatabaseService } from '../../sql-database.service';

export class ConfigureSqlDatabaseConnectionData {
  Source: RemoteDataSourceModel;
  shouldEditActive: boolean = false;
}

class CategoryNode {
  public name: string;
  public step: number = 0;
}

type RowType = 'observation' | 'meta-values' | 'navigation' | 'meta-table';

@Component({
  selector: 'indicio-configure-db-connection-dialog',
  templateUrl: 'configure-sql-database.dialog.html',
  styleUrls: ['configure-sql-database.dialog.less']
})
export class ConfigureSqlDatabaseConnectionDialogComponent {

  // Font-awesome icons
  public faTriangle = faTriangleExclamation as IconProp;

  public parameters: ConfigureSqlDatabaseConnectionData = null;
  public onEditConfig: boolean = false;
  public configId: string;

  public tables: DbTableInfoDTO[] = [];
  public loading: boolean = true;

  public addNewCategory: boolean = false;
  public addNewMetaValue: boolean = false;
  public addMetaValueVariables: boolean = true;

  public metaTableRowOpen: boolean = false;
  public navigationTreeRowOpen: boolean = false;
  public observationTableeRowOpen: boolean = false;
  public metaValueVariablesRowOpen: boolean = false;

  public currentStep = 0;

  //#region Meta table
  public selectedTableMeta: string = null;
  public selectedMetaIdColumn: string = null;
  public selectedNameColumn: string = null;

  public filteredMetaTables: DbTableInfoDTO[] = [];
  public filteredMetaIdColumns: DbColumnInfoDTO[] = [];
  public filteredMetaNameColumns: DbColumnInfoDTO[] = [];

  public selectableMetaColumns: DbColumnInfoDTO[] = [];

  //#endregion

  //#region Category
  public selectedCategory: string = null;

  public categories: DbColumnInfoDTO[] = [];
  public filteredCategories: DbColumnInfoDTO[] = [];
  public addedCategories: CategoryNode[] = [];
  //#endregion

  //#region meta values for variable
  public selectedMetaValue: string = null;

  public metaValues: DbColumnInfoDTO[] = [];
  public addedMetaValuesVariable: CategoryNode[] = [];
  public filteredMetaValuesVariable: DbColumnInfoDTO[] = [];

  //#endregion

  //#region Observation table
  public selectedObservationTable: string = null;
  public selectedObservationIdColumn: string = null;
  public selectedObservationDateColumn: string = null;
  public selectedObservationValueColumn: string = null;

  public filteredObservationTables: DbTableInfoDTO[] = [];
  public filteredObservationIdColumns: DbColumnInfoDTO[] = [];
  public filteredObservationDateColumns: DbColumnInfoDTO[] = [];
  public filteredObservationValueColumns: DbColumnInfoDTO[] = [];

  public selectableObservationColumns: DbColumnInfoDTO[] = [];
  //#endregion

  public shouldEditActive: boolean = false;

  public metaTableMessages: string[] = [];
  public categoryMessages: string[] = [];
  public observationMessages: string[] = [];

  public categoryControl = new UntypedFormControl();
  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.currentStep < 3) {
        this.currentStep++;
      } else if (this.inputIsOk()) {
        this.configure();
      }
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ConfigureSqlDatabaseConnectionDialogComponent>,
    private dbService: DatabaseService,
    private status: StatusService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: ConfigureSqlDatabaseConnectionData) {
    this.parameters = data;
    this.shouldEditActive = data.shouldEditActive;
    this.syncMeta();

  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public clearFields() {
    this.closeAllRows();
    this.onEditConfig = false;
    this.selectedTableMeta = null;
    this.selectedMetaIdColumn = null;
    this.selectedNameColumn = null;
    this.selectedObservationTable = null;
    this.selectedObservationIdColumn = null;
    this.selectedObservationDateColumn = null;
    this.selectedObservationValueColumn = null;

    this.addedCategories = [];
    this.addedMetaValuesVariable = [];
    this.addSelectableObservationColumns(null);
    this.selectMetaTable(null);
    this.resetFilterCategories(true);
    this.resetFilterMetaValues(true);
  }

  public closeAllRows(type: RowType = null) {
    if (type !== 'meta-table') {
      this.metaTableRowOpen = false;
    }
    if (type !== 'navigation') {
      this.navigationTreeRowOpen = false;
    }
    if (type !== 'observation') {
      this.observationTableeRowOpen = false;
    }
    if (type !== 'meta-values') {
      this.metaValueVariablesRowOpen = false;
    }
  }

  //#region Verification
  public verifyMetaSection() {
    this.metaTableMessages = [];
    if (!this.selectedTableMeta) {
      this.metaTableMessages.push('You must select a meta table.');
    }
    if (!this.selectedMetaIdColumn) {
      this.metaTableMessages.push('You must select a identifier column in the meta table');
    }
    if (!this.selectedNameColumn) {
      this.metaTableMessages.push('You must select a name column in the meta table');
    }
    if (this.metaTableMessages.length) {
      return false;
    }
    const tableMeta = this.tables.find(e => e.Name.toLowerCase().includes(this.selectedTableMeta.toLowerCase()));
    const columnIdMeta = this.selectableMetaColumns.find(e => e.Name.toLowerCase().includes(this.selectedMetaIdColumn.toLowerCase()));
    const columnNameMeta = this.selectableMetaColumns.find(e => e.Name.toLowerCase().includes(this.selectedNameColumn.toLowerCase()));
    if (!tableMeta) {
      this.metaTableMessages.push('Meta table does not exists in your database. Check your spelling, or select one in the dropdown.');
    }
    if (!columnIdMeta) {
      this.metaTableMessages.push('Identifier column does not exists in the table. Check your spelling, or select one in the dropdown');
    }
    if (!columnNameMeta) {
      this.metaTableMessages.push('Name column does not exists in your database. Check your spelling, or select one in the dropdown.');
    }
    return this.metaTableMessages.length === 0;
  }

  public verifyNavigationSection() {
    this.categoryMessages = [];
    if (this.addedCategories.length === 0) {
      this.categoryMessages.push('You must add at least one node in the navigation tree.');
    }
    return this.categoryMessages.length === 0;
  }

  public verifyObservationSection() {
    this.observationMessages = [];
    if (!this.selectedObservationTable) {
      this.observationMessages.push('You must select a observation table.');
    }
    if (!this.selectedObservationIdColumn) {
      this.observationMessages.push('You must select a identifier column in the observation table.');
    }
    if (!this.selectedObservationDateColumn) {
      this.observationMessages.push('You must select a date column in the observation table.');
    }
    if (!this.selectedObservationValueColumn) {
      this.observationMessages.push('You must select a value column in the observation table.');
    }
    if (this.observationMessages.length) {
      return false;
    }
    const tableObservation = this.tables.find(e => e.Name.toLowerCase().includes(this.selectedObservationTable.toLowerCase()));
    const columnIdObservation = this.selectableObservationColumns.find(e => e.Name.toLowerCase().includes(this.selectedObservationIdColumn.toLowerCase()));
    const columnDateObservation = this.selectableObservationColumns.find(e => e.Name.toLowerCase().includes(this.selectedObservationDateColumn.toLowerCase()));
    const columnValueObservation = this.selectableObservationColumns.find(e => e.Name.toLowerCase().includes(this.selectedObservationValueColumn.toLowerCase()));
    if (!tableObservation) {
      this.observationMessages.push('Observation table does not exists in your database. Check your spelling, or select one in the dropdown.');
    }
    if (!columnIdObservation) {
      this.observationMessages.push('Identifier column does not exists in the table. Check your spelling, or select one in the dropdown');
    }
    if (!columnDateObservation) {
      this.observationMessages.push('Date column does not exists in the table. Check your spelling, or select one in the dropdown');
    }
    if (!columnValueObservation) {
      this.observationMessages.push('Value column does not exists in the table. Check your spelling, or select one in the dropdown');
    }
    return this.observationMessages.length === 0;
  }
  //#endregion

  //#region Meta

  public selectMetaTable(evt?: string) {
    this.resetMetaConfigSettings();
    if (!evt) {
      return;
    }
    this.selectedTableMeta = evt;

    const bitFilteredTable = this.tables.filter(this.removeBadTypesAndBit);
    this.filteredMetaTables = bitFilteredTable.filter(e => e.Name.toLowerCase().includes(evt.toLowerCase()));
    this.selectableMetaColumns = bitFilteredTable.find(x => x.Name === evt)?.Columns || [];

    const tableColumns = this.tables.find(x => x.Name === evt)?.Columns || [];
    this.filteredCategories = tableColumns;
    this.filteredMetaValuesVariable = tableColumns;
    this.categories = tableColumns;
    this.metaValues = tableColumns;
    this.filteredMetaIdColumns = tableColumns.filter(this.filterIdColumns);
    this.filteredMetaNameColumns = this.selectableMetaColumns.filter(this.filterNameColumns);

    this.filteredMetaIdColumns = this.sortHelper(this.filteredMetaIdColumns, this.mostLikelyIdColumn);
    this.filteredMetaNameColumns = this.sortHelper(this.filteredMetaNameColumns, this.mostLikelyNameColumn);
  }

  public resetMetaConfigSettings() {
    this.selectedTableMeta = null;
    this.selectedMetaIdColumn = null;
    this.selectedNameColumn = null;
    this.addedCategories = [];
    this.addedMetaValuesVariable = [];
    this.filteredMetaTables = this.tables;
    this.filteredMetaIdColumns = [];
    this.filteredMetaNameColumns = [];
  }

  public filterMetaIdColumns(value: string) {
    this.filteredMetaIdColumns = this.selectableMetaColumns.filter(e => e.Name.toLowerCase().includes(value.toLowerCase()) && this.filterDateColumns(e));
    return this.sortHelper(this.filteredMetaIdColumns, this.mostLikelyIdColumn);
  }

  public filterMetaNameColumns(value: string) {
    this.filteredMetaNameColumns = this.selectableMetaColumns.filter(e => e.Name.toLowerCase().includes(value.toLowerCase()) && this.filterNameColumns(e));
    return this.sortHelper(this.filteredMetaNameColumns, this.mostLikelyNameColumn);
  }

  public removeCategory(value: DbColumnInfoDTO) {
    this.addedCategories = this.addedCategories.filter(e => !e.name.toLowerCase().includes(value.Name.toLowerCase()));
  }
  //#endregion

  //#region Category
  public addCategory(value: string, parent: CategoryNode, force: boolean = false) {
    const possibleNew = this.categories.filter(e => !this.addedCategories.map(t => t.name).includes(e.Name));
    this.filteredCategories = possibleNew.filter(t => t.Name.toLowerCase().includes(value.toLowerCase()));

    const category: DbColumnInfoDTO = possibleNew.find(e => e.Name === value);
    if (!category) {
      this.resetFilterCategories(false);
      return;
    }
    const exists = this.addedCategories.find(x => x.name === category.Name);
    if (!!exists) {
      this.status.setMessage('Already added the category', 'Error', true);
      return;
    }

    if (this.filteredCategories.length === 1 || force) {
      const newChild: CategoryNode = { name: category.Name, step: parent ? parent.step + 1 : 1 };
      this.addedCategories.push(newChild);
      this.resetFilterCategories(true);
      this.addNewCategory = false;
    } else {
      this.resetFilterCategories(false);
    }
  }

  public resetFilterCategories(clearSearch: boolean) {
    this.filteredCategories = this.categories.filter(e => !this.addedCategories.map(t => t.name).includes(e.Name) &&
      (clearSearch || !this.selectedCategory ? true : e.Name.toLowerCase().includes(this.selectedCategory.toLowerCase())));
    if (clearSearch) {
      this.selectedCategory = null;
    }
  }

  public removeCategoryNode(node: CategoryNode) {
    this.addedCategories = this.addedCategories.filter(e => e.name !== node.name);
    this.resetFilterCategories(true);
  }
  //#endregion

  //#region Meta values for variable
  public addMetaFields(value: string, parent: CategoryNode, force: boolean) {
    const possibleNew = this.metaValues.filter(e => !this.addedMetaValuesVariable.map(t => t.name).includes(e.Name));
    this.filteredMetaValuesVariable = possibleNew.filter(t => t.Name.toLowerCase().includes(value.toLowerCase()));

    const category: DbColumnInfoDTO = possibleNew.find(e => e.Name === value);
    if (!category) {
      this.resetFilterMetaValues(false);
      return;
    }
    const exists = this.addedMetaValuesVariable.find(x => x.name === category.Name);
    if (!!exists) {
      this.status.setMessage('Already added the meta field', 'Error', true);
      return;
    }

    if (this.filteredMetaValuesVariable.length === 1 || force) {
      const newChild: CategoryNode = { name: category.Name, step: parent ? parent.step + 1 : 1 };
      this.addedMetaValuesVariable.push(newChild);
      this.resetFilterMetaValues(true);
      this.addNewMetaValue = false;
    } else {
      this.resetFilterMetaValues(false);
    }
  }

  public resetFilterMetaValues(clearSearch: boolean) {
    this.filteredMetaValuesVariable = this.metaValues.filter(e => !this.addedMetaValuesVariable.map(t => t.name).includes(e.Name) &&
      (clearSearch || !this.selectedMetaValue ? true : e.Name.toLowerCase().includes(this.selectedMetaValue.toLowerCase())));
    if (clearSearch) {
      this.selectedMetaValue = null;
    }
  }

  public removeMetaNode(node: CategoryNode) {
    this.addedMetaValuesVariable = this.addedMetaValuesVariable.filter(e => e.name !== node.name);
  }
  //#endregion

  //#region Observations
  public filterObservationTables(value: string) {
    return this.filteredObservationTables = this.tables.filter(e => e.Name.toLowerCase().includes(value.toLowerCase()));
  }

  public addSelectableObservationColumns(value: string) {
    this.selectableObservationColumns = this.tables.find(x => x.Name === value)?.Columns || [];
    if (this.selectableObservationColumns.length === 0) {
      return;
    }
    const bitFilteredTable = [this.tables.find(x => x.Name === value)].filter(this.removeBadTypesAndBit)[0].Columns || [];
    this.filteredObservationIdColumns = this.selectableObservationColumns.filter(this.filterIdColumns);
    this.filteredObservationDateColumns = bitFilteredTable.filter(x => x.Name !== this.selectedObservationValueColumn && this.filterDateColumns(x));
    this.filteredObservationValueColumns = bitFilteredTable.filter(x => x.Name !== this.selectedObservationDateColumn && this.filterValueColumns(x));

    this.filteredObservationIdColumns = this.sortHelper(this.filteredObservationIdColumns, this.mostLikelyIdColumn);
    this.filteredObservationDateColumns = this.sortHelper(this.filteredObservationDateColumns, this.mostLikelyDateColumn);
    this.filteredObservationValueColumns = this.sortHelper(this.filteredObservationValueColumns, this.mostLikelyValueColumn);
  }

  public filterObservationIdColumns(value: string) {
    this.filteredObservationIdColumns = this.selectableObservationColumns
      .filter(x => x.Name.toLowerCase().includes(value.toLowerCase())).filter(this.filterIdColumns);
    return this.sortHelper(this.filteredObservationIdColumns, this.mostLikelyIdColumn);
  }

  public filterObservationDateColumns(value: string) {
    this.filteredObservationValueColumns = this.selectableObservationColumns.filter(x => x.Name !== this.selectedObservationDateColumn && this.filterValueColumns(x));
    this.filteredObservationDateColumns = this.selectableObservationColumns.filter(x => x.Name.toLowerCase().includes(value.toLowerCase()) && this.filterDateColumns(x)).filter(x => x.Name !== this.selectedObservationValueColumn);
    this.filteredObservationValueColumns = this.sortHelper(this.filteredObservationValueColumns, this.mostLikelyValueColumn);
    return this.sortHelper(this.filteredObservationDateColumns, this.mostLikelyDateColumn);
  }

  public filterObservationValueColumns(value: string) {
    this.filteredObservationDateColumns = this.selectableObservationColumns.filter(x => x.Name !== this.selectedObservationValueColumn && this.filterDateColumns(x));
    this.filteredObservationValueColumns = this.selectableObservationColumns.filter(x => x.Name.toLowerCase().includes(value.toLowerCase()) && this.filterValueColumns(x)).filter(x => x.Name !== this.selectedObservationDateColumn);
    this.filteredObservationDateColumns = this.sortHelper(this.filteredObservationDateColumns, this.mostLikelyDateColumn);
    return this.sortHelper(this.filteredObservationValueColumns, this.mostLikelyValueColumn);
  }

  private sortHelper(filteredColumn: DbColumnInfoDTO[], cb: (x: DbColumnInfoDTO) => boolean) {
    const mostLikelyColumns = filteredColumn.filter(e => cb(e));
    const restValue = filteredColumn.filter(e => !cb(e));
    restValue.sort((a, b) => a.Name.localeCompare(b.Name));
    return mostLikelyColumns.concat(restValue);
  }
  //#endregion

  //#region HTTP helpers

  public configure() {
    if (!this.inputIsOk()) {
      this.status.setMessage('Not all required fields were added', 'Error', true);
      return;
    }

    this.loading = true;
    this.store.dispatch(new DatabaseConnectionActions.TabComponentLoading(true, true, true));
    this.dbService.addNewDbConfig(this.data.Source, {
      TableName: this.selectedTableMeta,
      IdField: this.selectedMetaIdColumn,
      ObservationTableName: this.selectedObservationTable,
      ObservationIdField: this.selectedObservationIdColumn,
      ObservationDateField: this.selectedObservationDateColumn,
      ObservationValueField: this.selectedObservationValueColumn,
      NameField: this.selectedNameColumn,
      MetaFields: this.addedMetaValuesVariable.map(e => {
        return e.name;
      }),
      CategoryFields: this.addedCategories.map(e => {
        return e.name;
      })
    })
      .then(resp => {
        this.store.dispatch(new DatabaseConnectionActions.CreatedConfig(resp.cfg));
        this.dialogRef.close(resp);
        this.status.setMessage('Configuration saved.', 'Success');
      })
      .catch(err => {
        this.status.setError(err, true);
      })
      .finally(() => {
        this.loading = false;
        this.store.dispatch(new DatabaseConnectionActions.TabComponentLoading(false, false));
      });
  }

  public currentStepHasMessages() {
    if (this.currentStep === 0) {
      return this.metaTableMessages.length > 0;
    } else if (this.currentStep === 1) {
      return this.observationMessages.length > 0;
    } else if (this.currentStep === 2) {
      return this.categoryMessages.length > 0;
    }
    return !this.inputIsOk();
  }

  public getInputWarnings() {
    const messages: string[] = [];
    if (this.currentStep === 0) {
      this.filteredMetaIdColumns.forEach(p => {
        if (p.Name === this.selectedMetaIdColumn) {
          if (!this.mostLikelyIdColumn(p)) {
            messages.push(`Meta ID column usually contains a type unqiueidentifier not ${p.Type}. Please check if the selected column is correct.`);
          }
        }
      });
      this.filteredMetaNameColumns.forEach(p => {
        if (p.Name === this.selectedNameColumn) {
          if (!this.mostLikelyNameColumn(p)) {
            messages.push(`Meta Name column usually contains a type varchar not ${p.Type}. Please check if the selected column is correct.`);
          }
        }
      });
    } else if (this.currentStep === 1) {
      this.filteredObservationIdColumns.forEach(p => {
        if (p.Name === this.selectedObservationIdColumn) {
          if (!this.mostLikelyIdColumn(p)) {
            messages.push(`Observation ID column usually contains a type unqiueidentifier not ${p.Type}. Please check if the selected column is correct.`);
          }
        }
      });
      this.filteredObservationDateColumns.forEach(p => {
        if (p.Name === this.selectedObservationDateColumn) {
          if (!this.mostLikelyDateColumn(p)) {
            messages.push(`Observation Date column usually contains a type datetime not ${p.Type}. Please check if the selected column is correct.`);
          }
        }
      });
      this.filteredObservationValueColumns.forEach(p => {
        if (p.Name === this.selectedObservationValueColumn) {
          if (!this.mostLikelyValueColumn(p)) {
            messages.push(`Observation Value column usually contains a type float not ${p.Type}. Please check if the selected column is correct.`);
          }
        }
      });
    }
    return messages;
  }

  public inputIsOk() {
    this.verifyMetaSection();
    this.verifyNavigationSection();
    this.verifyObservationSection();
    return !this.loading && this.getAllWarningMessages().length === 0;
  }

  public getAllWarningMessages() {
    return [...this.metaTableMessages, ...this.categoryMessages, ...this.observationMessages];
  }

  private isBadCharacters(t: string) {
    var letters = /^[0-9a-zA-Z]+$/;
    if (letters.test(t)) {
      return false;
    } else {
      return true;
    }
  }

  private syncMeta() {
    this.dbService.getDbMetaInfo(this.data.Source.RemoteDataSourceId)
      .then(metaData => {
        this.tables = metaData.map(e => {
          e.Columns = e.Columns.filter(t => !this.isBadCharacters(t.Name));
          return e;
        });
        this.removeBadTypes();
        this.filteredMetaTables = this.tables;
        this.filteredObservationTables = this.tables;

        this.defaultValues(this.parameters);
      })
      .catch(err => {
        this.status.setMessage(err, 'Error', true);
      })
      .finally(() => this.loading = false);
  }
  //#endregion

  public getLanguageSupportForType(type: string) {
    switch (type) {
      case 'date':
      case 'timestamp':
      case 'datetime':
        return 'date';
      case 'float':
      case 'int':
      case 'bigint':
        return 'number';
      case 'nvarchar':
      case 'varchar':
        return 'character';
      case 'uniqueidentifier':
        return 'identifier';
      case 'bit':
        return 'true/false';
      default:
        return type;
    }
  }

  public setAddedMetaValuesVariables() {
    const activeConfig = this.parameters.Source.DatabaseDTO.Config;
    this.addedMetaValuesVariable = [];
    if (activeConfig?.MetaFields != null && activeConfig.MetaFields.length > 0) {
      activeConfig.MetaFields?.forEach((t, i) => {
        this.addedMetaValuesVariable.push({
          name: t,
          step: i + 1
        });
      });
    }
  }

  private defaultValues(data: ConfigureSqlDatabaseConnectionData) {
    if (data.Source != null && data.Source?.DatabaseDTO?.Config != null && this.shouldEditActive) {
      this.onEditConfig = true;
      const activeConfig = data.Source.DatabaseDTO.Config;
      this.selectedTableMeta = activeConfig.TableName;
      this.selectMetaTable(activeConfig.TableName);
      this.selectedMetaIdColumn = activeConfig.IdField;
      this.selectedNameColumn = activeConfig.NameField;
      activeConfig.CategoryFields.forEach((t, i) => {
        this.addedCategories.push({
          name: t,
          step: i + 1
        });
      });
      activeConfig.MetaFields?.forEach((t, i) => {
        this.addedMetaValuesVariable.push({
          name: t,
          step: i + 1
        });
      });
      if (activeConfig.MetaFields != null && activeConfig.MetaFields.length > 0) {
        this.addMetaValueVariables = true;
      }
      this.selectedObservationTable = activeConfig.ObservationTableName;
      this.addSelectableObservationColumns(activeConfig.ObservationTableName);
      this.selectedObservationIdColumn = activeConfig.ObservationIdField;
      this.selectedObservationDateColumn = activeConfig.ObservationDateField;
      this.selectedObservationValueColumn = activeConfig.ObservationValueField;

      this.resetFilterCategories(true);
      this.resetFilterMetaValues(true);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.addedCategories, event.previousIndex, event.currentIndex);
    this.addedCategories.forEach((s, i) => s.step = i + 1);
  }

  dropMetaNode(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.addedMetaValuesVariable, event.previousIndex, event.currentIndex);
    this.addedMetaValuesVariable.forEach((s, i) => s.step = i + 1);
  }

  //#region Filter validation
  private removeBadTypes() {
    this.tables.forEach(p => {
      p.Columns = p.Columns.filter(x => x.Type !== 'varbinary' && x.Type !== 'timestamp');
    });
  }

  private removeBadTypesAndBit(x: DbTableInfoDTO) {
    x.Columns = x.Columns.filter(y => y.Type !== 'bit' && y.Type !== 'varbinary' && y.Type !== 'timestamp');
    return x;
  }

  private filterDateColumns(x: DbColumnInfoDTO) {
    return x.Type === 'date' || x.Type === 'timestamp' || x.Type === 'float' || x.Type === 'datetime' || x.Type === 'nvarchar' || x.Type === 'varchar';
  }

  private filterValueColumns(x: DbColumnInfoDTO) {
    return x.Type === 'float' || x.Type === 'int' || x.Type === 'bigint' || x.Type === 'nvarchar' || x.Type === 'varchar';
  }

  private filterIdColumns(x: DbColumnInfoDTO) {
    return x.Type === 'int' || x.Type === 'bigint' || x.Type === 'nvarchar' || x.Type === 'varchar' || x.Type === 'uniqueidentifier' || x.Type === 'float';
  }

  private filterNameColumns(x: DbColumnInfoDTO) {
    return x.Type === 'nvarchar' || x.Type === 'varchar';
  }

  private mostLikelyIdColumn(x: DbColumnInfoDTO) {
    return x.Type === 'int' || x.Type === 'bigint' || x.Type === 'uniqueidentifier';
  }

  private mostLikelyDateColumn(x: DbColumnInfoDTO) {
    return x.Type === 'date' || x.Type === 'datetime';
  }

  private mostLikelyValueColumn(x: DbColumnInfoDTO) {
    return x.Type === 'float' || x.Type === 'int' || x.Type === 'bigint';
  }

  private mostLikelyNameColumn(x: DbColumnInfoDTO) {
    return x.Type === 'nvarchar' || x.Type === 'varchar';
  }

  //#endregion end filter validation

}
