<h1 mat-dialog-title>Weights for {{ data.Model }}</h1>
<div mat-dialog-content>
  <div class="data-table__body"
       #fileuploadscroll
       [style.maxHeight.px]="301">
    <div class="body__row body__row--center-height body__row--max-height-30"
         *ngFor="let weight of data.Weights; let i = index">
      <div class="column left-align"
           style="width: 80px;">
        <span>Step {{ i }}</span>
      </div>
      <div class="column left-align"
           style="width: 300px;">
        {{ weight | propertyValue }}
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Close
  </button>
</div>
