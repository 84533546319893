import { Injectable } from '@angular/core';
import { CreateFileVersionDTO } from '@core/store/file/dtos/create-file-version-dto';
import { FileDTO, RemoteFileDTO, RemoteFileVersionDTO } from '@core/store/file/dtos/file-dto';
import { FileVersionDTO } from '@core/store/file/dtos/file-version-dto';
import { FileVersionModel } from '@core/store/file/models/file-version.model';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { OrderByDatePipe } from '@shared/modules/pipes';
import { DateUtils } from '@shared/utils/date.utils';
import { CreateFileDTO } from './dtos/create-file.dto';
import { ConfirmedNewLocationDTO, GetFileColumnDiffDTO, NewLocationInfoDTO, VariableUpdateInfoDTO } from './dtos/variable-update-info-dto';
import { FileInfoModel } from './models/file-info.model';
import { RemoteFileVersionModel } from './models/remote-file-version.model';
import { RemoteFileModel } from './models/remote-file.model';


@Injectable({
  providedIn: 'root'
})
export class FileMapper {
  orderBy: OrderByDatePipe;

  constructor() {
    this.orderBy = new OrderByDatePipe();
  }

  public map(dto: FileDTO, isComplete: boolean = false) {
    const model = Object.faMapTo(new UploadedFileModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.Versions = dto.Versions.map(v => this.mapVersion(v));
    model.Versions = this.orderBy.transform(model.Versions, 'CreatedDate');
    model.fetched = isComplete;
    return model;
  }

  public mapVersion(dto: FileVersionDTO) {
    const model = Object.faMapTo(new FileVersionModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    return model;
  }

  public mapRemote(dto: RemoteFileDTO, isComplete: boolean = false) {
    const model = Object.faMapTo(new RemoteFileModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.Versions = dto.Versions.map(v => this.mapRemoteVersion(v));
    model.Versions = this.orderBy.transform(model.Versions, 'CreatedDate');
    model.fetched = isComplete;
    return model;
  }

  public mapRemoteVersion(dto: RemoteFileVersionDTO) {
    const model = Object.faMapTo(new RemoteFileVersionModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    return model;
  }

  public mapGetColDiffDTO(info: FileInfoModel, location: NewLocationInfoDTO, variable: VariableUpdateInfoDTO) {
    const dto = new GetFileColumnDiffDTO();
    dto.CompanyId = info.FileToUpload.CompanyId;
    dto.ColumnIndex = variable.OldValueIndex;
    dto.FileVersionId = info.OldFile.latestVersion().UploadedFileVersionId;
    dto.SheetIndex = location.NewSheet;
    dto.OldPaddingCount = location.Offset;
    const column = info.getColumnByLocation(location.NewSheet, location.NewValueIndex);
    dto.NewRows = column?.Raw;
    return dto;
  }

  public toDto(file: UploadedFileModel) {
    const dto = Object.faMapTo(new FileDTO(), file);
    dto.Versions = file.Versions.map(v => this.toVersionDto(v));
    return dto;
  }

  public toVersionDto(version: FileVersionModel) {
    const dto = Object.faMapTo(new FileVersionDTO(), version);
    return dto;
  }

  public toCreateDto(file: UploadedFileModel) {
    const dto = Object.faMapTo(new CreateFileDTO(), file);
    return dto;
  }

  public toCreateVersionDto(file: UploadedFileModel, vInfos: ConfirmedNewLocationDTO[]) {
    const dto = Object.faMapTo(new CreateFileVersionDTO(), file);
    dto.VariableLocations = vInfos;
    return dto;
  }
}
