import * as d3 from 'd3';
import * as moment from 'moment';
import { ALGTypes } from '../../alg-types';
import { SvgColors } from './svg-colors';
import { SvgSizes } from './svg-sizes';

export class SvgObjectsInput {
  SVGId: string; // this._id;
  Instance: number; // this.instance
  Header: string; // this.header
  Sizes: SvgSizes;
  Colors: SvgColors;
}

export class SvgObjects {

  /* Selections */
  SVG: d3.Selection<SVGSVGElement, undefined, null, undefined> = null;
  SVGGroup: d3.Selection<SVGGElement, undefined, null, undefined> = null;
  GraphGroup: d3.Selection<SVGGElement, undefined, null, undefined> = null;
  GraphDataGroup: d3.Selection<SVGGElement, undefined, null, undefined> = null;
  ClipPath: d3.Selection<SVGRectElement, undefined, null, undefined> = null;
  AssessmentGroup: d3.Selection<SVGGElement, undefined, null, undefined> = null;
  HoverTarget: d3.Selection<SVGElement, undefined, null, undefined> = null;
  Border: d3.Selection<SVGRectElement, undefined, null, undefined> = null;

  /* Scales */
  XScale: d3.ScaleTime<any, any>;
  YScale: d3.ScaleLinear<any, any>;

  /* Custom helper classes */
  Sizes: SvgSizes;
  Colors: SvgColors;

  /* Flags and misc fields */
  Initialized: boolean = false;

}

export class SvgConfig {

  // Adjustment section
  public algDataType: ALGTypes.Data = ALGTypes.Data.original;
  public get isOriginalData() { return this.algDataType === ALGTypes.Data.original; }
  public get seasonal() { return this.algDataType === ALGTypes.Data.seasonal; }
  public get aggregated() { return this.algDataType === ALGTypes.Data.aggregated; }
  public get outliers() { return this.algDataType === ALGTypes.Data.outlier; }

  // Transform section
  public algTransform: ALGTypes.Transform = ALGTypes.Transform.original;
  public get noTransform() { return this.algTransform === ALGTypes.Transform.original; }
  public get roc() { return this.algTransform === ALGTypes.Transform.roc; }
  public get rocY() { return this.algTransform === ALGTypes.Transform.rocy; }
  public get diff() { return this.algTransform === ALGTypes.Transform.diff; }
  public get diffy() { return this.algTransform === ALGTypes.Transform.diffy; }
  public get isRocType() { return this.roc || this.rocY; }
  public get rolling12MAvg() { return this.algTransform === ALGTypes.Transform.rolling12m; }
  public get rolling4QAvg() { return this.algTransform === ALGTypes.Transform.rolling4q; }
  public get rolling12MSum() { return this.algTransform === ALGTypes.Transform.rolling12mSum; }
  public get rolling4QSum() { return this.algTransform === ALGTypes.Transform.rolling4qSum; }

  public isPercent = false;

  public get showPastForecasts() { return this.isOriginalData && (this.ShowPastForecasts); }

  // What data are we displaying?
  public ShowPastForecasts = false;
  public ShowFittedData = false;
  public ShowCIHover = true;
  public isMiniAlg = false;

  // Past forecast settings
  public PastType: number = 0;

  // Pixel position where forecast area starts
  public HistoricCutoffPosition: number;
  public CutoffDate: moment.Moment;

  /**
  * Decides if the point at xCoordinate belongs in the forecasted area in the graph or not.
  * This influences e.g. the color of the graph line
  * @param xCoordinate The X position belonging to either the cursor or an SVG-object
  */
  public isPointForecasted(xCoordinate: number) {
    return xCoordinate > this.HistoricCutoffPosition;
  }
}

export function createSvgObjects(input: SvgObjectsInput): SvgObjects {

  const result = new SvgObjects();
  const sizes = input.Sizes;

  result.SVG = d3.create('svg');
  const innerRadius = (sizes.ChartBorderRadius - sizes.ChartBorderSize / 2) < 0
    ? 0
    : sizes.ChartBorderRadius - sizes.ChartBorderSize / 2;

  if (input.Header) {
    sizes.ALGMarginTop = 45;
  }

  result.SVG
    .attr('preserveAspectRatio', 'xMinYMin meet')
    .attr('id', `svg-graph-${input.SVGId}`)
    .classed(`svg-content line-graph ${input.Colors.ChartColorScheme}`, true);

  result.SVGGroup = result.SVG.append('g')
    .attr('class', 'SVG-margins')
    .attr('transform', 'translate(' + sizes.ALGMarginLeft + ',' + sizes.ALGMarginTop + ')');

  result.GraphGroup = result.SVGGroup.append('g')
    .attr('class', 'graph-outer')
    .attr('transform', 'translate(' + sizes.getGraphX() + ',' + sizes.getGraphY() + ')');

  result.GraphGroup.append('rect')
    .attr('x', sizes.ChartBorderSize / 2)
    .attr('y', sizes.ChartBorderSize / 2)
    .attr('ry', innerRadius)
    .attr('rx', innerRadius)
    .attr('class', 'chart-background')
    .style('fill', input.Colors.ChartBackgroundColor);

  result.GraphDataGroup = result.GraphGroup.append('g')
    .attr('class', 'graph-inner')
    .attr('transform', 'translate(' + sizes.ChartBorderSize / 2 + ',' + sizes.ChartBorderSize / 2 + ')');

  // Add a clippath to contain all inner data in the graph
  result.ClipPath = result.GraphDataGroup.append('clipPath')
    .attr('id', 'rect-clip-' + input.Instance)
    .append('rect')
    .attr('ry', innerRadius)
    .attr('rx', innerRadius);

  result.Initialized = true;
  result.Colors = input.Colors;
  result.Sizes = sizes;
  return result;
}
