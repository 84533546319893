import { ProjectModel } from '@core/store/project/project.model';
import { EditProjectModalOpts } from './project-edit-modal.options';

export class OpenEditProjectModal {
  public static readonly type = '[MODAL] Open edit project';
  data: EditProjectModalOpts;
  constructor(
    project: ProjectModel,
    view?: string
  ) {
    this.data = { project, view };
  }
}
