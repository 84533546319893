import { DateUtils } from '@shared/utils/date.utils';
import { ForecastVariableModel } from '../forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from './models/forecast-version.model';
import { ForecastModel } from './models/forecast.model';

type actionType = 'StartDate' | 'Nowcast' | 'ProviderDataMissing' | 'DataUpdate' | 'ExogenousValuesNeeded';

const POSSIBLE_ACTIONS: actionType[] = [
  'StartDate',
  'Nowcast',
  'ProviderDataMissing',
  'DataUpdate',
  'ExogenousValuesNeeded'
];

export class ForecastHelper {

  public static hasAction(variable: ForecastVariableModel, forecast: ForecastModel, fVersion: ForecastVersionModel, actions = POSSIBLE_ACTIONS.slice()): boolean {
    const action = actions.shift();
    if (!action) { return false; }
    let hasAction = false;

    switch (action) {
      case 'StartDate':
        hasAction = !variable.IsIndicator && ForecastHelper.canSetLaterStartDate(fVersion, variable);
      case 'Nowcast':
        if (variable.IsBase && !variable.isNowcasting) {
          hasAction = !variable.IsIndicator ? ForecastHelper.needsNowcast(fVersion, variable) || ForecastHelper.canNowcastLastPeriod(fVersion, variable)
            : ForecastHelper.needsNowcast(fVersion, variable);
        }
        break;
      case 'ProviderDataMissing':
        hasAction = variable.ProviderDataMissing;
        break;
      case 'ExogenousValuesNeeded':
        hasAction = variable.ExogenousValuesNeeded;
        break;
    }

    return hasAction || ForecastHelper.hasAction(variable, forecast, fVersion, actions);
  }

  public static needsNowcast(fversion: ForecastVersionModel, variable: ForecastVariableModel): boolean {
    if (fversion == null || variable == null) { return false; }
    const unit = fversion.periodicity.getMomentDurationConstructor();
    const variableMoment = DateUtils.newMoment(variable.LastDate);
    const forecastMoment = DateUtils.newMoment(fversion.DataUntilDateRequirement);
    return variableMoment.isBefore(forecastMoment, unit);
  }

  public static canNowcastLastPeriod(_fversion: ForecastVersionModel, variable: ForecastVariableModel): boolean {
    if (variable === null || variable.IsIndicator) { return false; }
    const main = variable.NowcastOptions.Periodicities.find(x => x.Main);
    return main && main.MissingDataPoints > 0;

  }

  public static canSetLaterStartDate(fversion: ForecastVersionModel, variable: ForecastVariableModel): boolean {
    const variableMoment = DateUtils.newMoment(variable.LatestDate);
    const forecastMoment = DateUtils.newMoment(fversion.StartDate);
    const canSetLater = variableMoment.isSameOrAfter(forecastMoment);
    return canSetLater;
  }

  public static updateCalcMultivariateStatus(forecastVersion: ForecastVersionModel) {
    const canCalcInfo = forecastVersion.ScriptCalculateInfo;
    if (canCalcInfo.NowcastNeeded) {
      canCalcInfo.triggerCalculationTitle = 'One or more of the variables does not contain data up until the forecasting start date';
    } else {
      if (forecastVersion.anyActiveIndicators) {
        canCalcInfo.triggerCalculationTitle = '';
      } else {
        canCalcInfo.triggerCalculationTitle = 'At least one indicator must be active to run a multivariate analysis';
      }
    }
  }

  public static updateCalcVarSelectStatus(forecastVersion: ForecastVersionModel) {
    const canCalcInfo = forecastVersion.ScriptCalculateInfo;
    if (!forecastVersion.ForecastVariable || !forecastVersion.ForecastVariable) {
      canCalcInfo.triggerVarSelectTitle = 'Must add a main variable before any form of analysis';
      return;
    }
    if (forecastVersion.IndicatorVariables.length === 0) {
      canCalcInfo.triggerVarSelectTitle = 'You need to add one or more indicators before running the indicator analysis';
      return;
    }
    if (canCalcInfo.NowcastNeeded) {
      canCalcInfo.triggerVarSelectTitle = 'One or more of the indicators needs to be nowcasted before proceeding';
      return;
    }
    if (!canCalcInfo.CanTriggerVariableSelect) {
      canCalcInfo.triggerVarSelectTitle = 'You must wait for each variable to be processed before running the indicator analysis';
      return;
    }
    canCalcInfo.triggerVarSelectTitle = 'Runs an analysis of the forecast indicators';
  }
}
