import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigateToForecast } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { HistoricEventFrontendService } from '@core/store/historic-event/historic-event.frontend.service';
import { HistoricEventMapper } from '@core/store/historic-event/historic-event.mapper';
import { ImportedHistoricBaseEventModel, ImportedHistoricEventModel } from '@core/store/historic-event/models/imported/imported-historic-base-event.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { HistoricEventUtils } from '@shared/utils/forecast/event.utils';
import { StateUtils } from '@shared/utils/state.utils';
import { UUIdUtils } from '@shared/utils/uuid.utils';
import { OpenImportHistoricEventsCorrelationModal } from '../correlation/event-correlation-modal.action';
import { OpenEditImportedHistoricEventModal } from './edit-imported-historic-event-modal.action';
import { EditImportedHEventModalOpts } from './edit-imported-historic-event-modal.options';


@Component({
  selector: 'indicio-imported-historic-event-modal',
  templateUrl: './edit-imported-historic-event-modal.component.html',
  styleUrls: ['../../historic-event/create-edit-historic-event-modal.component.less']
})
export class EditImportedHistoricEventModalComponent extends ModalModelComponent {

  modelState = new StateUtils.StateHelper();

  opts: EditImportedHEventModalOpts;

  forecastVersion: ForecastVersionModel = null;
  originalBaseEvent: ImportedHistoricBaseEventModel = null;
  baseEvent: ImportedHistoricBaseEventModel = null;
  onBack: Function;

  isNew = false;
  userCanEdit = false;
  isEditing = false;

  baseOpen = true;
  alreadyImported: boolean;

  pendingImport: boolean;
  disableText: string;

  public get isChanged() {
    return this.modelState.isChanged('event', this.baseEvent);
  }

  public get disableNext() {
    let disable = false;
    const before = this.disableText;
    this.disableText = null;
    this.baseEvent.Events.forEach(ev => {
      if (!ev.Name) { disable = true; this.disableText = 'Missing name on an event'; }
      if (!ev.Date) { disable = true; this.disableText = 'Missing start date on an event'; }
      if (!ev.EffectType) { disable = true; this.disableText = 'Missing type on an event'; }
    });
    if (disable === false) {
      if (this.baseEvent.Events.length === 0) {
        this.disableText = 'Missing events';
        disable = true;
      } else {
        const sameDateWithinGroup = this.baseEvent.Events.map(x => this.checkDate(x)).some(x => x === true);
        if (sameDateWithinGroup) {
          disable = true;
          this.disableText = 'Two or more events with same date is not allowed';
        }
      }
    }
    if (before !== this.disableText) {
      this.cd.detectChanges();
    }
    return disable;
  }

  public get disableImport() {
    let disable = false;
    const before = this.disableText;
    this.disableText = null;
    if (!this.getPastEvents().length) {
      this.disableText = 'Cannot import event group with only events in the future';
      disable = true;
    } else if (this.alreadyImported) {
      this.disableText = 'Already imported';
      disable = true;
    } else if (this.needCorrelations() && this.baseEvent.Events.every(x => x.Correlations?.length === 0)) {
      this.disableText = 'Some event is missing a correlation';
      disable = true;
    }
    if (before !== this.disableText) {
      this.cd.detectChanges();
    }
    return disable;
  }

  public get advancedMode() { return this.appearance.AdvancedUI; }

  constructor(
    protected store: Store,
    private status: StatusService,
    private cd: ChangeDetectorRef,
    public appearance: AppearanceService,
    public envService: EnvironmentService,
    private eventService: HistoricEventFrontendService,
    private clientSerive: ClientFrontendService,
    private forecastService: ForecastFrontendService,
    private mapper: HistoricEventMapper
  ) {
    super();
  }

  public setOptions(options: EditImportedHEventModalOpts) {
    this.opts = options;
    const fVersPromise = this.forecastService.getOrFetchForecastVersion(options.forecastVersionId);

    Promise.all([fVersPromise]).then(([fv]) => {
      this.forecastVersion = fv;
      if (options.fromHistoricEventId) {
        const eventBase = this.eventService.allEvents.find(x => x.HistoricBaseEventId === options.fromHistoricEventId);
        const newImportedEvent = this.mapper.mapHistoricToImported(eventBase);
        newImportedEvent.ForecastVersionId = this.forecastVersion.ForecastVersionId;
        this.baseEvent = newImportedEvent;
      } else {
        this.originalBaseEvent = fv.ImportedHistoricBaseEvents.find(x => x.ImportedHistoricBaseEventId === options.baseEventId);
        this.baseEvent = options.unSavedEvent || this.mapper.mapToImportedCopy(this.originalBaseEvent);
      }

      this.setSavedState(this.originalBaseEvent);
      this.onBack = options.onBack;
      this.isNew = !this.originalBaseEvent;
      const eventAlreadyImported = this.forecastVersion.ImportedHistoricBaseEventIds.includes(options.baseEventId);
      if (eventAlreadyImported) { this.alreadyImported = true; }

      this.userCanEdit = this.clientSerive.activeCompany.hasPermission('CAN_UPDATE_HISTORIC_EVENT');

      if (options.navToEventMissingCorrelation) {
        const missingCorr = this.baseEvent.Events.find(x => this.eventMissingCorrelation(x));
        if (missingCorr) {
          this.toggleEventDisplay(missingCorr);
        }
      }

      if (this.isNew && !this.needCorrelations()) {
        this.import();
      } else {
        this.isLoading = false;
        this.isEditing = true;
      }
    });
  }

  public isUsedAsCorrelationInNumEvents(event: ImportedHistoricEventModel) {
    return this.baseEvent.Events.filter(x => x.Correlations.find(c => c.PastHistoricEventId === event.ImportedHistoricEventId)).length;
  }

  public getCorrelation(event: ImportedHistoricEventModel) {
    return event.Correlations.find(c => c.FutureHistoricEventId === event.ImportedHistoricEventId);
  }

  public setEventDate(date, event: ImportedHistoricEventModel, type: 'start' | 'end') {
    if (type === 'start') {
      event.Date = date;
    } else {
      event.EndDate = date;
    }
    if (!HistoricEventUtils.needCorrelation(event, this.forecastVersion)) {
      event.Correlations = [];
    }
  }

  public removeEvent(event: ImportedHistoricEventModel) {
    const idx = this.baseEvent.Events.findIndex(x => x.ImportedHistoricEventId === event.ImportedHistoricEventId);
    if (idx > -1) {
      this.baseEvent.Events.splice(idx, 1);
    }
  }

  public toggleEventDisplay(event: ImportedHistoricEventModel, force?: boolean) {
    if (force == null) {
      this.baseEvent.open = false;
    }
    event.open = force != null ? force : !event.open;
  }

  public closeAllSubEvents() {
    this.baseEvent.Events.forEach(x => this.toggleEventDisplay(x, false));
  }

  public addEvent() {
    const newEvent = new ImportedHistoricEventModel();
    newEvent.ImportedHistoricEventId = UUIdUtils.GetNewIdV4();
    newEvent.open = false;
    this.baseEvent.open = false;
    this.baseEvent.Events.forEach(x => this.toggleEventDisplay(x, false));
    this.baseEvent.Events.push(newEvent);
  }

  getPastEventsForEvent(event: ImportedHistoricEventModel) {
    const fv = this.forecastVersion;
    return this.baseEvent.getPastEventsForEvent(event, fv.StartDate, fv.DataUntilDateRequirement, fv.Periodicity);
  }

  getPastEvents() {
    const fv = this.forecastVersion;
    return this.baseEvent.getPastEvents(fv.StartDate, fv.DataUntilDateRequirement, fv.Periodicity);
  }


  public selectEffectType(event: ImportedHistoricEventModel, type: string) {
    event.EffectType = type;
    event.Correlations = [];
  }

  public update() {
    this.pending = true;
    return this.eventService.updateEventInForecast(this.baseEvent)
      .then(updated => {
        this.setSavedState(updated);
        this.status.setMessage('Event saved', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
      })
      .finally(() => this.pending = false);
  }

  public import() {
    const groups = this.forecastVersion.ImportedHistoricBaseEvents;
    let sameDate;
    for (let i = 0, n = groups.length; i < n; i++) {
      const group = groups[i];
      group.Events.forEach(e => {
        if (sameDate === true) { return; }
        sameDate = this.checkDate(e);
      });
      if (sameDate === true) { break; }
    }

    if (sameDate === true) {
      this.status.setMessage('One or more dates match with an event already added', 'Error', true);
      return;
    }

    this.pendingImport = true;

    const creationCall = () => {
      this.eventService.addEventToForecast(this.baseEvent)
        .then(() => {
          this.close();
          this.status.setMessage('Event added to forecast', 'Success');
          this.store.dispatch(new NavigateToForecast(this.forecastVersion.ForecastId));
        })
        .catch(error => {
          this.status.setError(error, true);
        }).finally(() => {
          this.pendingImport = false;
        });
    };

    creationCall();
  }

  needCorrelations() {
    return !!this.baseEvent.Events.filter(e => this.eventRequiresCorrelation(e)).length;
  }

  eventRequiresCorrelation(event: ImportedHistoricEventModel) {
    return HistoricEventUtils.needCorrelation(event, this.forecastVersion);
  }

  eventMissingCorrelation(event: ImportedHistoricEventModel) {
    return HistoricEventUtils.needCorrelation(event, this.forecastVersion) && !event.Correlations.length;
  }

  createCorrelation(event: ImportedHistoricEventModel) {
    this.close();
    this.store.dispatch(new OpenImportHistoricEventsCorrelationModal(this.baseEvent, event, this.forecastVersion.ForecastVersionId, null, this.correlationBack()));
  }

  viewCorrelations(event: ImportedHistoricEventModel) {
    this.close();
    this.store.dispatch(new OpenImportHistoricEventsCorrelationModal(this.baseEvent, event, this.forecastVersion.ForecastVersionId, null, this.correlationBack()));
  }

  editCorrelation(event: ImportedHistoricEventModel) {
    this.close();
    this.store.dispatch(new OpenImportHistoricEventsCorrelationModal(
      this.baseEvent,
      event,
      this.forecastVersion.ForecastVersionId,
      null,
      this.correlationBack()));
  }

  public checkDate(event: ImportedHistoricEventModel) {
    if (!event.Date) { return; }
    return this.baseEvent.Events.filter(x => x.ImportedHistoricEventId !== event.ImportedHistoricEventId && !!x.Date).some(x => x.Date.isSame(event.Date, 'day'));
  }

  private setSavedState(event: ImportedHistoricBaseEventModel) {
    this.modelState.setState('event', event);
  }

  public cancelEdit() {
    this.close();
    if (this.onBack) {
      this.onBack();
    }
  }

  private correlationBack() {
    return () => this.store.dispatch(new OpenEditImportedHistoricEventModal(
      this.opts.forecastVersionId,
      this.baseEvent.ImportedHistoricBaseEventId,
      null,
      this.opts.onBack,
      this.opts.navToEventMissingCorrelation,
      this.baseEvent
    ));
  }
}
