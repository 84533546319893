import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TagTypes } from '@core/store/tags/dtos/tags.dtos';
import { TagModel } from '@core/store/tags/models/tag.model';
import { TagsFrontendService } from '@core/store/tags/tags.frontend.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export class CreateTagDialogData {
  public preTag: string;
}

@Component({
  selector: 'indicio-create-tag-dialog',
  templateUrl: 'create-tag.dialog.html',
})
export class CreateTagDialogComponent {
  public static Id: string = 'CreateTagDialogComponent';

  @ViewChild('parentInput') parentInput: ElementRef<HTMLInputElement>;

  public newTag: TagModel = new TagModel();
  public nameError: string = null;
  public parentControl = new UntypedFormControl();
  public filteredParents: Observable<TagModel[]>;
  public disableRelatives: boolean = false;

  public disableRegion: boolean;

  constructor(
    public dialogRef: MatDialogRef<CreateTagDialogComponent>,
    public service: TagsFrontendService,
    @Inject(MAT_DIALOG_DATA) public data: CreateTagDialogData
  ) {
    if (this.data?.preTag) {
      this.newTag.Name = this.data.preTag;
    }

    let parent: TagModel;
    if (this.service.selectedTag) {
      parent = this.service.selectedTag;
    }

    this.service.selectedTag = this.newTag;

    if (parent) {
      setTimeout(() => this.selectedParent(<MatAutocompleteSelectedEvent> { option: { value: parent } }));
    }

    this.setAvailableParents();
  }

  private setAvailableParents() {
    this.filteredParents = this.parentControl.valueChanges.pipe(
      startWith(''),
      map((tagName: string) => this.service.filterTags(tagName, 'parents')));
    this.disableRelatives = this.service.filterTags('', 'parents').length === 0;
  }

  public toggleRegion($evt: MatCheckboxChange) {
    this.newTag.Type = $evt.checked ? TagTypes.USERDEFINEDREGION : TagTypes.USERDEFINED;
    this.setAvailableParents();
  }

  public setName(name) {
    this.newTag.Name = name;
    if (this.service.nameConflict(name)) {
      this.nameError = 'Another tag with that name already exists';
    } else {
      this.nameError = null;
    }
  }

  public selectedParent(event: MatAutocompleteSelectedEvent): void {
    const tag: TagModel = event.option.value;
    this.newTag.ParentTags.push(tag);
    this.newTag.ParentTagIds.push(tag.TagId);
    this.parentInput.nativeElement.value = '';
    this.parentInput.nativeElement.blur();
    this.parentControl.setValue('');

    this.checkParents();
  }

  private checkParents() {
    if (this.newTag.ParentTags.length) {
      if (this.newTag.ParentTags.some(x => x.isRegion())) {
        this.disableRegion = true;
        this.newTag.Type = TagTypes.USERDEFINEDREGION;
      }
    } else {
      this.disableRegion = false;
    }
  }

  public removeParent(tag: TagModel): void {
    this.newTag.ParentTags.removeById(tag.TagId);
    this.newTag.ParentTagIds.removeId(tag.TagId);
    this.parentInput.nativeElement.blur();
    this.parentControl.setValue('');
    this.checkParents();
  }

  public newTagValid(): boolean {
    return this.newTag.Name.length !== 0 && !this.nameError;
  }

  public resetService() {
    this.service.selectedTag = null;
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public save() {
    this.dialogRef.close(this.newTag);
  }
}
