<h1 mat-dialog-title>{{ data.Title }}</h1>
<div mat-dialog-content>
  <div *ngIf="data.Ingress">
    {{ data.Ingress}}
  </div>
  <mat-form-field style="margin-top: 20px;">
    <mat-label *ngIf="data.Label">{{ data.Label }}</mat-label>
    <input [(ngModel)]="searchString"
           (ngModelChange)="filteredOptions = filterOptions(searchString)"
           type="text"
           class="material"
           matInput
           [matAutocomplete]="filter">
    <div mat-button
         [ngStyle]="{ opacity: !searchString ? 0 : 1 }"
         matSuffix
         mat-icon-button
         aria-label="Clear"
         (click)="resetSearch()">
      <mat-icon><i class="ion-close"></i></mat-icon>
    </div>

    <mat-autocomplete #filter="matAutocomplete"
                      [displayWith]="getOptionText">
      <mat-option *ngFor="let option of filteredOptions"
                  title="{{ option.Display }}"
                  (onSelectionChange)="selectOption($event)"
                  [value]="option">{{ option.Display }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    {{ data.CancelButtonText || 'Cancel' }}
  </button>
  <button mat-flat-button
          [class]="{ disabled: !chosenOption }"
          [indicioTippy]="!chosenOption ? 'You need to select an option in order to continue.' : null"
          (click)="save()">
    {{ data.ConfirmButtonText || 'Save' }}
  </button>
</div>
