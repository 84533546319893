<div class="company-edit-general-tab"
     style="--grid-template-global: 1fr 0.8fr"
     *ngIf="!!company">

  <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="countryTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="userCountTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>

  <ng-container *ngTemplateOutlet="trialSettings"></ng-container>
  <br>

</div>

<ng-template #nameTemplate>
  <h2>Info and settings</h2>
  <div class="grid-system center-vertical info-row">
    <div>Company name:</div>
    <div class="flex-end">
      <div class="flex-end"
           style="align-items: baseline;">
        <div class="crop-string name-field"
             style="display: inline-block; max-width: 335px; padding: 1px 0;"
             [title]="newName">{{ newName }}
        </div>
        &nbsp;<i class="ion-help-circled"
           indicioTippy="Contact support if the name needs to be changed"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #countryTemplate>
  <div class="grid-system center-vertical info-row"
       style="margin-top: -8px;">
    <div style="padding-top: 10px;">Country:</div>
    <div class="flex-end">
      <indicio-searchable-dropdown [required]="true"
                                   style="width: 250px;"
                                   optionDisplay="Display"
                                   [values]="envService.Countries"
                                   (valueChangeEvent)="setCountry($event)"
                                   [selectedValue]="selectedCountry">
      </indicio-searchable-dropdown>
    </div>
  </div>
</ng-template>

<ng-template #userCountTemplate>
  <div class="grid-system center-vertical info-row"
       style="--grid-template: 1fr 1fr">
    <div>Users: </div>

    <div class="flex-end">
      <div style="margin-right: 10px; margin-bottom: 4px;">{{ company.UserCount }} of</div>
      <mat-form-field style="width: 250px">
        <mat-select [value]="company.MaxUsers"
                    required
                    (selectionChange)="setMaxUsers($event)">
          <mat-option [value]="count"
                      *ngFor="let count of [1, 3, 5, 10, 20, 30, 50, 100]"> {{count}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #summaryTemplate>
  <div class="grid-system center-vertical info-row">
    <div>Forecasts: </div>
    <div class="text-right">{{ company.ForecastCount }}</div>
  </div>
  <div class="grid-system center-vertical info-row">
    <div>Registration date: </div>
    <div class="text-right">{{ company.CreatedDate | date }}</div>
  </div>
  <div class="grid-system center-vertical info-row">
    <div>Enabled/Disabled: </div>
    <div style="text-align: right;">
      <span style="margin-right: 10px;"
            [ngClass]="{ red: !companyEnabled, green: companyEnabled }">{{ !companyEnabled ? 'Disabled' : 'Enabled'
        }}
        <ng-container *ngIf="!canToggleDisabled">
          &nbsp;<i class="ion-help-circled"
             indicioTippy="Contact support if company should be {{ !companyEnabled ? 'enabled' : 'disabled' }}"></i>
        </ng-container>
      </span>
      <mat-slide-toggle *ngIf="canToggleDisabled"
                        [ngModel]="companyEnabled"
                        (change)="toggleDisabled($event)">
      </mat-slide-toggle>

    </div>


  </div>
</ng-template>

<ng-template #trialSettings>
  <h2>Trial settings</h2>
  <div class="grid-system center-vertical info-row">
    <div style="padding-top: 10px;">On trial ({{ company.Trial ? 'yes' : 'no' }})</div>
    <div style="text-align: right;">
      <mat-slide-toggle [checked]="company.Trial"
                        (change)="toggleTrialStatus()">
      </mat-slide-toggle>
    </div>
  </div>

  <div class="grid-system center-vertical info-row"
       *ngIf="company.Trial">
    <div style="padding-top: 10px;">Trial expiry date</div>
    <div style="width: 250px; justify-self: end;"
         class="text-right">
      <indicio-date-picker periodicity="day"
                           [value]="company.TrialExpirationDate"
                           [disabled]="!company.Trial"
                           (selectDateEvent)="setTrialExp($event.toDate())"
                           [min]="minDate"
                           placeholder="Choose a date">
      </indicio-date-picker>
    </div>
  </div>

</ng-template>
