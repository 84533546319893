
import * as d3 from 'd3';

export class DateRangeSVGElements {

  /* Main SVG object */
  public svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;

  /* Scales */
  public dragScale: d3.ScaleLinear<number, number>;

  /* groups */
  public colorGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  public sliderGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  public axisGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

  /* bars */
  public sliderLine: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
  public fullRangeLine: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
  public sampleLine: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
  public testLine: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
  public forecastLine: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
  public maxForecastLine: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;

  /* drag objects */
  public trainBoll: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
  public oosBar: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
  public startCircle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
  public endCircle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;

}
