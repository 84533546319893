import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { AdminUserService } from '@modules/admin/components/system/users/user.admin.service';
import { ClientInfoDTO, ClientMetaDTO } from '@modules/admin/entities/client-meta.dto';
import { AdminFrontendService } from '@modules/admin/services/admin-frontend.service';

export interface UserInfoAdminDialogData {
  Client?: ClientMetaDTO;
  ClientId: string;
}

export enum AdminUserInfoDialogViewIndex {
  'general' = 0,
  'audit' = 1,
}

@Component({
  selector: 'indicio-user-info-admin-dialog',
  templateUrl: 'user-info.admin.dialog.html',
})
export class UserInfoAdminDialogComponent extends DialogV2BaseDialog<UserInfoAdminDialogComponent> {

  public static Id: string = 'UserInfoAdminDialogComponent';

  // Options
  public viewIndex: number = 0;
  public viewTypes = AdminUserInfoDialogViewIndex;

  public info: ClientInfoDTO;
  public loading: boolean = true;
  public shouldSave: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: UserInfoAdminDialogData,
    dialogRef: MatDialogRef<UserInfoAdminDialogComponent, UserInfoAdminDialogData>,
    public envService: EnvironmentService,
    private service: AdminFrontendService,
    private userService: AdminUserService,
    private profile: ProfileFrontendService,
    private status: StatusService,
  ) {
    super(dialogRef);
    this.initialize();
  }

  public save() {
    if (this.shouldSave) {
      this.userService.saveSecurityInfo({ ...this.info.SecurityInfo, BumpMfaGrace: false })
        .then(() => {
          this.status.setMessage('Client updated', 'Success');
          this.dialogRef.close(true);
        })
        .catch(err => this.status.setError(err, true));
    } else {
      this.dialogRef.close(true);
    }
  }

  protected initialize() {
    let promise = Promise.resolve(this.data.Client);
    if (!this.data.Client) {
      promise = this.service.getUser(this.data.ClientId).then(x => this.data.Client = x);
    }
    promise.then(() => this.service.getUserInfo(this.data.Client.ClientId)
      .then(x => {
        this.info = x;
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => {
        this.loading = false;
        this.initialized = true;
      }));
  }

  public changeView(index: number) {
    this.viewIndex = index;
  }


  public onNoClick(): void {
    this.dialogRef.close(false);
  }
}
