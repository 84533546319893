import { Injectable } from '@angular/core';
import { GithubIssue } from '@core/entities/github.models';
import { StringUtils } from '@shared/utils/string.utils';
import { HttpService } from './http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportService {

  reportTimeout = null;
  errorReports: { [key: string]: GithubIssue.FrontendErrorReportDTO; } = {};
  errorsReported: string[] = [];

  constructor(
    private http: HttpService,
  ) { }

  /** Backend */
  public postGithubIssue(issue: GithubIssue.GithubIssueReport) {
    return this.http.post('error/report', issue)
      .then(({ body }) => body);
  }

  public postFrontendIssue(issue: GithubIssue.FrontendErrorReportDTO) {
    const hash = String(StringUtils.getHash(issue.Subject || issue.Stacktrace || StringUtils.createId(10)));
    if (this.errorReports[hash] || this.errorsReported.includes(hash)) { return; }

    if (this.reportTimeout) {
      window.clearTimeout(this.reportTimeout);
    }

    this.errorReports[hash] = issue;

    this.reportTimeout = setTimeout(() => {
      this.postError(issue)
        .then(x => {
          if (x === false) { return; }
          const errors = Object.keys(this.errorReports);
          const workingError = errors.shift();
          if (!workingError) { return; }
          delete this.errorReports[workingError];
          this.errorsReported.push(workingError);
          this.reportTimeout = setTimeout(() => this.postError(this.errorReports[workingError]), 1000);
        });
    }, 1000);
  }

  private postError(issue: GithubIssue.FrontendErrorReportDTO) {
    if (!issue || !!!issue.Subject) { return Promise.resolve(false); }
    return this.http.post('error/frontend-error', issue)
      .then(({ body }) => body);
  }
}
