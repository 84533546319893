import { Component, Inject } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { DateUtils } from '@shared/utils/date.utils';
import { FilterDialogData, IDialogFilter } from './filter.dialog.constants';
import { FilterDialogUtils } from './filter.dialog.utils';

@Component({
  selector: 'indicio-filter-dialog',
  templateUrl: 'filter.dialog.html',
})
export class FilterDialogComponent extends DialogV2BaseDialog<FilterDialogComponent> {

  public static Id: string = 'FilterDialogComponent';

  filters: IDialogFilter[];
  disableUntil: Date = DateUtils.newDate('1900-12-31');
  disableSince: Date = DateUtils.newDate();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: FilterDialogData,
    dialogRef: MatDialogRef<FilterDialogComponent, IDialogFilter[]>
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.filters = this.data.filters.map(f => FilterDialogUtils.cloneIDialogFilter(f));
    this.initialized = true;
  }


  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close(this.filters);
  }

  public updateCheckbox(checkbox: MatCheckbox, filter: IDialogFilter) {
    filter.value = checkbox.checked;
  }

  public onDateChanged(filter, evt) {
    filter.value = evt;
  }
}
