import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddDataTab, NavigateToAddData } from '@core/actions/navigation.actions';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { DatabaseConnectionActions } from '@modules/forecast/views/add-data/tab-database/tab-database.actions';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { ConnectDbDTO } from '../../sql-database.dtos';
import { DatabaseService } from '../../sql-database.service';

export class NewSqlDatabaseConnectionData {
  public source: RemoteDataSourceModel = null;
  public existingDatabase: ConnectDbDTO = null;
  public forecastId: string;
  public shouldNavigateToTab: boolean = true;
}

@Component({
  selector: 'indicio-new-db-connection-dialog',
  templateUrl: 'new-sql-database.dialog.html',
  styleUrls: ['new-sql-database.dialog.less']
})
export class NewSqlDatabaseConnectionDialogComponent {

  public connectDto: ConnectDbDTO = new ConnectDbDTO();
  public loading: boolean = false;
  public edit: boolean = false;
  public messages: string[] = [];
  private manualName: boolean = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.inputIsOk()) {
      this.connect();
    }
  }

  constructor(
    public dialogRef: MatDialogRef<NewSqlDatabaseConnectionDialogComponent>,
    private dbService: DatabaseService,
    private status: StatusService,
    private store: Store,
    private dialogService: DialogService,
    private clientService: ClientFrontendService,
    @Inject(MAT_DIALOG_DATA) public data: NewSqlDatabaseConnectionData) {
    if (data.existingDatabase) {
      this.edit = true;
      this.connectDto = data.existingDatabase;
    }
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  public inputIsOk() {
    this.messages = [];
    if (this.connectDto.Database == null || this.connectDto.Database.length === 0) {
      this.messages.push('Database field cannot be empty.');
    }
    if (this.connectDto.Name == null || this.connectDto.Name.length === 0) {
      this.messages.push('Name field cannot be empty.');
    }
    if (this.connectDto.Password == null || this.connectDto.Password.length === 0) {
      this.messages.push('Password cannot be empty.');
    }
    if (this.connectDto.Url == null || this.connectDto.Url.length === 0) {
      this.messages.push('Server address cannot be empty.');
    }
    if (this.connectDto.Username == null || this.connectDto.Username.length === 0) {
      this.messages.push('Username cannot be empty.');
    }
    if (this.connectDto.Port == null || typeof this.connectDto.Port !== 'number') {
      this.messages.push('Port cannot be empty.');
    }
    return this.messages.length === 0;
  }

  public setName(name: string) {
    this.manualName = true;
    this.connectDto.Name = name;
  }

  public setServer(serverUri: string) {
    this.connectDto.Url = serverUri;
    if (!this.manualName) {
      this.connectDto.Name = serverUri;
    }
  }

  public connect() {
    if (!this.inputIsOk()) {
      return;
    }
    this.loading = true;
    this.store.dispatch(new DatabaseConnectionActions.TabComponentLoading(true, true));
    this.dbService.addNewConnection(this.data.source?.RemoteDataSourceId, this.connectDto)
      .then(source => {
        this.clientService.client.RemoteDataSources.addOrUpdate(source);
        if (this.data.shouldNavigateToTab) {
          this.store.dispatch(new NavigateToAddData(this.data.forecastId, AddDataTab.database));
        }
        this.dialogRef.close(source);
        this.dialogService.openConfigureDbConnection({ Source: source, shouldEditActive: false });
      })
      .catch(err => {
        this.status.setError(err, true);
      })
      .finally(() => {
        this.loading = false;
        this.store.dispatch(new DatabaseConnectionActions.TabComponentLoading(false, false));
      });
  }

}
