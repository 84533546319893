import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewsPostModel } from '@core/store/news/news-post.model';
import { NewsFrontendService } from '@core/store/news/news.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { OpenEditNewsModal } from '@shared/modals/news/edit-news/edit-news-modal.actions';

@Component({
  selector: 'indicio-news-archive-dialog',
  templateUrl: './news-archive.dialog.html',
  styleUrls: ['./news-archive.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class NewsArchiveDialogComponent {
  public static Id: string = 'NewsArchiveDialogComponent';

  public skip: number = 0;
  public take: number = 10;

  public articles: NewsPostModel[] = [];
  loadMorePending: boolean;
  lastLoadFrom: any;

  constructor(
    public dialogRef: MatDialogRef<NewsArchiveDialogComponent>,
    private newsService: NewsFrontendService,
    public appearance: AppearanceService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: void) {
    this.getArticles();
  }

  getArticles() {

    this.loadMorePending = true;

    this.newsService.fetchNewsPosts(this.skip, this.take)
      .then(response => {
        this.lastLoadFrom = response.LastSkip;
        this.articles.push(...response.NewsPosts);
      })
      .then(() => {
        this.loadMorePending = false;
      });
  }

  public loadMore() {
    if (this.loadMorePending || this.lastLoadFrom === this.skip) {
      return;
    }

    this.skip += 10;

    this.getArticles();
  }

  public editPost(article: NewsPostModel) {
    this.dialogRef.close();
    this.store.dispatch(new OpenEditNewsModal(article));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close();
  }

}
