<indicio-modal-base [child]="this"
                    modalTitle="Import variable from Nasdaq Data Link"
                    [loading]="loading"
                    modalExtraClass="small"
                    extraClasses="small">

  <ng-container *ngIf="!loading && dataset">
    <h2>{{ dataset.name }}</h2>
    <a class="premium-tag"
       *ngIf="dataset.premium"
       [href]="getDatabaseSearchLink(dataset.database_code)"
       target="_blank">
      Premium
    </a>

    <div class="margin-top selectable-text"
         style="max-height: 200px; overflow-y: auto; overflow-x: hidden;"
         [innerHTML]="dataset.description | safeHtml"></div>

    <div class="row extra-margin-top">
      <div class="col-xs-3">Date range:</div>
      <div class="col-xs-9">
        {{ dataset.oldest_available_date | dateFormat: dataset.frequency }} -
        {{ dataset.newest_available_date | dateFormat: dataset.frequency }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">Refreshed at:</div>
      <div class="col-xs-9">
        {{ dataset.refreshed_at | dateFormat }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">Periodicity:</div>
      <div class="col-xs-9">
        {{ envService.getPeriodicity(dataset.frequency).Display }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">Provider:</div>
      <div class="col-xs-9">
        {{ findDatabase(dataset.database_code).name || dataset.database_code }}
      </div>
    </div>

    <hr class="margin-top">

    <form (ngSubmit)="addVariable()"
          ngNativeValidate>
      <div class="row extra-margin-top">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-label>Enter name</mat-label>
            <input matInput
                   class="material"
                   type="text"
                   [(ngModel)]="variableName"
                   (ngModelChange)="checkName()"
                   [ngModelOptions]="{standalone: true}"
                   required>
            <mat-hint *ngIf="nameConflict">
              <span class="error-text">A source variable with that name already exists.</span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-label>Choose column to import</mat-label>
            <mat-select (selectionChange)="setId($event)"
                        [ngModel]="chosenColumnValue"
                        required
                        [ngModelOptions]="{standalone: true}">
              <ng-container *ngFor="let value of dataset.column_names">
                <mat-option [value]="value"
                            *ngIf="value !== 'Date' && value !== 'Trade Date'">{{ value }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="btn-wrapper extra-margin-top">
          <div class="col-xs-6">
            <button class="btn outline blue left"
                    type="button"
                    (click)="close()">Back</button>
          </div>
          <div class="col-xs-6"
               [title]="columnId === null ? 'You must choose a column to import' : 'Import'">
            <button class="btn pull-right"
                    [ngClass]="{ disabled: columnId === null || nameConflict }">
              <i class="ion-load-c spinner"
                 *ngIf="pending"></i>&nbsp;
              <span *ngIf="!pending">Import</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</indicio-modal-base>
