// Date-matching
/*
* År:              (19\d{2}|20\d{2})
* Månad:           ([01]?\d)
* Dag:             ([0-3]?\d)
* Timme            ([012][0-9])
* Minut/Sekund     ([0-6][0-9])
* Kvartalstexten:  [kvqu\s\.]{0,4}
* Kvartalssiffran: 0?([1234])
* Separator:       [\-\/\\\s:]
*/
export const dateStringRe1 = /^(19\d{2}|20\d{2})[\-\/\\\s:]([01]?\d)[\-\/\\\s:]([0-3]?\d)\s?([012][0-9])?:?([0-6][0-9])?:?([0-6][0-9])?$/i; // matches 2012-12-02
export const dateStringRe2 = /^(19\d{2}|20\d{2}|\d{2})[\-\/\\\s:]([a-y]{3,9})$/i; // matches 2017 jan
export const excelRe = /^(\d{5})$/;
export const halfYearRe = /^(19\d{2}|20\d{2})[\-\/\\\s:]?[h\s\.]{0,3}0?([12])$/i;
export const monthRe1 = /(19\d{2}|20\d{2})[\-\/\\\s:]?m[\-\/\\\s:]?([01]?\d)$/i; // matches 1990m1
export const quarterRe1 = /^(19\d{2}|20\d{2}|\d{2})[\-\/\\\s:]?[kvqu\s\.]{0,4}0?([1234])$/i; // matches e.g. 2012-4 or 2012\4 or 2012 4
export const quarterRe2 = /^[kvqu\s\.]{0,4}0?([1234])[\-\/\\\s:](19\d{2}|20\d{2}|\d{2})$/i; // matches q2:07
export const timeOfDayRe = /([012][0-9])?:?([0-6][0-9])?:?([0-6][0-9])?/;
export const yearRe = /^(19\d{2}|20\d{2})$/; // matches e.g. 2012

export const CWeekdays: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
