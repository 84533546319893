import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { FileColumnUsageDTO } from './dtos/file-column-usage-dto';
import { RemoteFileModel } from './models/remote-file.model';

export class RemovedFilesInCompanyAction {
  public static readonly type = '[FILE] Remove uploaded files in company';
  constructor(public companyId: string) { }
}

export class GetUploadedFileVersionUsageSuccessAction {
  public static readonly type = '[FILE] Get uploaded file version usage';
  constructor(public usage: FileColumnUsageDTO) { }
}

export class GetUploadedFilesSuccessAction {
  public static readonly type = '[FILE] Get uploaded files';
  constructor(public files: UploadedFileModel[]) { }
}

export class GetUploadedFileSuccessAction {
  public static readonly type = '[FILE] Get uploaded file success';
  constructor(public file: UploadedFileModel) { }
}

export class DeleteFileSuccessAction {
  public static readonly type = '[FILE] Delete uploaded file';
  constructor(public fileId: string) { }
}

export namespace FileActions {

  export class SyncAll {
    public static readonly type = '[FILE] Sync uploaded files';
    constructor(public companyId: string) { }
  }

  export class GetRemoteFilesSuccess {
    public static readonly type = '[FILE] Get remote files';
    constructor(public files: RemoteFileModel[]) { }
  }
  export class GetRemoteFileSuccess {
    public static readonly type = '[FILE] Get remote file';
    constructor(public file: RemoteFileModel) { }
  }
  export class DeleteRemoteFileSuccess {
    public static readonly type = '[FILE] Delete remote file';
    constructor(public fileId: string) { }
  }

}
