import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IFile, IFileVersion } from '@core/interfaces/if-file';
import { FileFrontendService } from '@core/store/file/file.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DialogService } from '@shared/modules/dialogs/dialog.service';


@Component({
  selector: 'indicio-file-info-dialog-general-tab',
  templateUrl: './file-info.general-tab.html',
  styleUrls: ['./file-info.general-tab.less']
})
export class FileInfoDialogGeneralTabComponent implements OnChanges {

  @Input() file: IFile;
  @Output() newNameEvent = new EventEmitter<string>();

  // Icons
  public downloadIcon: IconProp = faDownload;

  // Name settings
  public newName: string;
  public editName: boolean = false;
  public suffix: string = null;

  constructor(
    private fileService: FileFrontendService,
    public appearance: AppearanceService,
    private dialog: DialogService
  ) {
  }

  public downloadVersion(file: IFile, version: IFileVersion) {
    this.fileService.downloadFile(file, version.getModelId());
  }

  public changeFilename(name: string) {
    this.newName = name;
    this.newNameEvent.emit(this.newName + this.suffix);
  }

  public ngOnChanges() {
    if (!this.file) { return; }
    const n = this.file.FileName;
    const nameParts = n.split('.');
    this.suffix = '.' + nameParts.last();
    this.newName = n.substring(0, n.length - this.suffix.length);
  }
}
