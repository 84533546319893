import { Pipe, PipeTransform } from '@angular/core';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { FilterBase } from './filter-base';

export class SourcevariableFilters {
  OwnerEmail: string;
  ObjectName: string;
  usedInForecast: boolean;
  SourceType: string;
}

@Pipe({
  name: 'filterSourceVariables'
})
export class FilterSourceVariablePipe extends FilterBase implements PipeTransform {
  filters: SourcevariableFilters;
  transform(items: SourceVariableModel[], filters: SourcevariableFilters, _trigger): any {
    filters = this._fixFilterValues(filters);
    this.filters = filters;
    let filteredList = items;

    // Filter used - this should filter out those used if active
    if (this._checkFilter('usedInForecast') && filters.usedInForecast === false) {
      filteredList = filteredList.filter(x => x.usedInForecast === false);
    }

    // Search
    if (this._checkFilter('ObjectName')) {
      filteredList = filteredList.filter(item =>
        // Search variables
        new RegExp(filters['ObjectName'], 'gi').test(item['ObjectName']) ||
        // Search file names
        new RegExp(filters['ObjectName'], 'gi').test(item['FileName']) ||
        // Search sources
        new RegExp(filters['ObjectName'], 'gi').test(item['SourceType'])
      );
    }

    // Filter source
    if (this._checkFilter('SourceType')) {
      filteredList = filteredList.filter(item => item.SourceType === filters.SourceType);
    }

    // Filter created by current user
    if (this._checkFilter('OwnerEmail')) {
      filteredList = filteredList.filter(item => item.OwnerEmail === filters.OwnerEmail);
    }

    return filteredList;
  }
}
