import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { AutoTaskStepDTO } from '@core/store/auto-task/dtos/auto-task.dto';
import { AdminFrontendService } from '@modules/admin/services/admin-frontend.service';

export interface CreateAutoTaskStepDialogData {
  Dto: AutoTaskStepDTO;
}

@Component({
  selector: 'indicio-create-auto-task-step-dialog',
  templateUrl: 'create-auto-task-step.dialog.html',
})
export class CreateAutoTaskStepDialogComponent {

  public edit: boolean = false;

  constructor(
    public envService: EnvironmentService,
    public service: AdminFrontendService,
    public dialogRef: MatDialogRef<CreateAutoTaskStepDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateAutoTaskStepDialogData) {


  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onRecipientsChange(recipients: string) {

  }

  save() {
    this.dialogRef.close(this.data.Dto);
  }
}
