import { Injectable } from '@angular/core';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { ActionService } from '@core/services/actions/actions.service';
import { HttpService } from '@core/services/http/http.service';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { BlobUtils } from '@shared/utils/blob.utils';
import { FileUtils } from '@shared/utils/file.utils';
import { WikiActions } from './wiki.actions';
import { WikiAssetDTO, WikiPDFVersionDTO, WikiPageDTO, WikiPageVersionDTO } from './wiki.entities';
import { WikiMapper } from './wiki.mapper';


@Injectable({
  providedIn: 'root'
})
export class WikiService {

  public lastOpenedSlug: string = 'home';
  public pageHistory: string[] = [];
  public openInSidebar: boolean = false;

  // GETTERS
  public previousPage() { return this.pageHistory.pop(); }
  public pages: WikiPageDTO[] = [];
  public assets: WikiAssetDTO[] = [];
  public availablePages: string[] = [];
  public isEnabled: boolean = false;

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private http: HttpService,
    private mapper: WikiMapper,
    private actions: ActionService
  ) {
    this.actions.dispatched(WikiActions.PageClosed).subscribe((ev: WikiActions.PageClosed) => this.pageHistory.push(ev.slug));
  }

  public open(slug: string) {
    this.lastOpenedSlug = slug;
    if (this.openInSidebar) {
      return this.store.dispatch(new WikiActions.OpenInSidebar(slug));
    }
    this.dialogService.openWikiPage({ PageSlug: slug }, { enterAnimationDuration: '0ms' });
  }

  public exists(slug: string) {
    return this.availablePages.includes(slug);
  }

  public getOrFetchPage(slug: string, force: boolean = false) {
    const page = this.pages.find(x => x.Slug === slug);
    if (page && !force) {
      return Promise.resolve(page);
    } else {
      return this.getPage(slug).then(page => {
        this.pages.addOrUpdateBy(page, 'Slug');
        return page;
      });
    }
  }

  public getOrFetchAsset(assetId: string) {
    const asset = this.assets.find(x => x.AssetId === assetId);
    if (asset && !!asset.Base64String) {
      return Promise.resolve(asset);
    } else {
      return this.getAsset(assetId).then(asset => {
        this.assets.addOrUpdateBy(asset, 'AssetId');
        this.store.dispatch(new WikiActions.AssetLoaded(asset));
        return asset;
      });
    }
  }

  public getIsEnabled() {
    return this.http.get<boolean>('wiki/is-enabled', 'wiki').then(({ body }) => body);
  }

  public getAvailablePages() {
    return this.http.get<string[]>('wiki/pages/slugs', 'wiki')
      .then(({ body }) => body)
      .then(pages => {
        this.availablePages = pages;
        this.isEnabled = pages.length > 0;
        return pages;
      });
  }

  public getPage(pageId: string) {
    return this.http.get<WikiPageDTO>(`wiki/page/${pageId}`, 'wiki')
      .then(({ body }) => this.mapper.mapPage(body));
  }

  public getPageVersions(slug: string) {
    return this.http.get<WikiPageVersionDTO[]>(`wiki/admin/page/${slug}/versions`, 'wiki')
      .then(({ body }) => body);
  }

  public getAsset(assetId: string) {
    return this.http.get<WikiAssetDTO>(`wiki/assets/${assetId}`, 'wiki')
      .then(({ body }) => this.mapper.mapAsset(body));
  }

  // PDF
  public downloadLatestPDF(license: IndicioConstants.Licenses = IndicioConstants.Licenses.Indicio) {
    return this.http.get<WikiPDFVersionDTO>('wiki/pdf', 'wiki')
      .then(({ body }) => {
        const blob = BlobUtils.base64toBlob(body.Base64String, body.MimeType);
        FileUtils.downloadBlob(blob, body.Name);
      });
  }
}
