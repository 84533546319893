import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { RemovalWarningsFrontendService } from '@core/services/removal-warnings/removal-warnings.frontend.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { Automation } from '@core/store/automation/automation';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { AutomationDialogService } from '@dialogs/automation/automation-dialogs.service';
import { ForecastDialogService } from '@dialogs/forecast/forecast-dialogs.service';
import { UpdateData } from '@dialogs/variables/forecast-variable/update/update-variables.dialog.entities';
import { Syncable } from '@dialogs/variables/remote-variable/syncable-variables/syncable-variables.entities';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { NavigationForecastListViewType } from '@modules/root/components/navigation/entities/navigation.entities';
import { NavigationComponentService } from '@modules/root/components/navigation/navigation.component.service';
import { Store } from '@ngxs/store';
import { OpenForecastDataDownloadModal } from '@shared/modals/data-download/data-download.actions';
import { OpenCopyForecastModal, OpenMoveForecastModal } from '@shared/modals/forecast/forecast-modal.actions';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { IForecastTreeNodeBase, IForecastTreeNodeForecast } from '../nav-tree/entities/forecast-tree.entities';

@Component({
  selector: 'indicio-nav-tree-forecast-node',
  templateUrl: './forecast-node.component.html',
  styleUrls: ['./forecast-node.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ForecastNavTreeForecastNodeComponent {
  faLock = faLock as IconProp;

  @Input() public node: IForecastTreeNodeForecast;
  @Input() public scrollPosition: number;
  @Input() public listView: NavigationForecastListViewType;
  @Output() clickEvent = new EventEmitter<IForecastTreeNodeForecast>();

  public get isAdmin$() { return this.authService.isAdmin$; }

  constructor(
    public env: EnvironmentService,
    public navService: NavigationService,
    private navComponentService: NavigationComponentService,
    private projectService: ProjectFrontendService,
    private forecastService: ForecastFrontendService,
    private store: Store,
    private dialogService: DialogService,
    private variableDialogs: VariableDialogService,
    private automationDialogs: AutomationDialogService,
    private status: StatusService,
    private removalService: RemovalWarningsFrontendService,
    private authService: AuthFrontendService,
    private forecastDialogService: ForecastDialogService
  ) {

  }

  public isPinned(node: IForecastTreeNodeForecast) {
    return this.navComponentService.pinnedForecastIds.includes(node.ObjectId);
  }

  public getProjectForForecast(node: IForecastTreeNodeForecast) {
    if (!node) return null;
    const proj = this.projectService.projectById(node.parent.ObjectId);
    return proj;
  }

  public toggleForecastMenu(forecast: IForecastTreeNodeForecast, { open, event }) {
    forecast.isActive = open;
    event?.stopPropagation();
  }

  public clickedNode(node: IForecastTreeNodeForecast, $event: MouseEvent) {
    this.clickEvent.emit(node);
    $event?.stopPropagation();
  }

  public getForecast(forecastId: string) {
    return this.forecastService.getOrFetchForecast(forecastId);
  }

  public createForecast(project: ProjectModel) {
    this.forecastDialogService.openCreate({
      ProjectId: project?.ProjectId
    });
  }

  public editForecast(project: ProjectModel, node: IForecastTreeNodeForecast) {
    this.getForecast(node.ObjectId).then(forecast => {
      this.forecastDialogService.openSettings({ ProjectId: project.ProjectId, ForecastId: forecast.ForecastId });
    });
  }

  public togglePinForecast(node: IForecastTreeNodeForecast) {
    this.navComponentService.togglePinForecast(node.ObjectId);
  }

  public downloadForecastData(node: IForecastTreeNodeForecast) {
    this.getForecast(node.ObjectId).then(forecast => {
      this.store.dispatch(new OpenForecastDataDownloadModal(forecast.ForecastId));
    });
  }

  public moveForecast(node: IForecastTreeNodeForecast) {
    this.getForecast(node.ObjectId).then(forecast => {
      this.store.dispatch(new OpenMoveForecastModal(forecast.ForecastId));
    });
  }

  public openRefreshDialog(node: IForecastTreeNodeBase) {
    this.variableDialogs.openFVarUpdateDialog({
      type: node.Type as UpdateData.TargetTypes,
      ObjectId: node.ObjectId,
      ObjectName: node.Name,
      AutoStart: false
    });
  }

  public openSyncDataDialog(node: IForecastTreeNodeBase) {
    this.variableDialogs.openSyncDataDialog({
      type: node.Type as Syncable.TargetTypes,
      ObjectId: node.ObjectId,
      ObjectName: node.Name
    });
  }

  public openCalcResultsDialog(node: IForecastTreeNodeBase) {
    this.automationDialogs.openCalcResultsDialog({
      type: node.Type as Automation.Targets,
      ObjectId: node.ObjectId,
      ObjectName: node.Name
    });
  }

  public copyForecast(node: IForecastTreeNodeForecast) {
    this.getForecast(node.ObjectId).then(forecast => {
      this.store.dispatch(new OpenCopyForecastModal(forecast.ForecastId));
    });
  }

  public removeForecast(node: IForecastTreeNodeForecast) {
    this.getForecast(node.ObjectId).then(forecast => {
      const ref = this.dialogService.openConfirmDialog({
        Title: 'Remove forecast?',
        Message: 'Are you sure you want to remove this forecast?',
        ConfirmText: 'Remove',
        Style: 'warn',
        WarningCheck: this.removalService.getRemovalWarningsForForecast(forecast.ForecastId),
        ExtraWarning: 'WARNING: The following entities are dependent on this forecast'
      }, { width: '522px' });

      ref.subscribe((proceed: boolean) => {
        if (!proceed) { return; }
        this.forecastService.deleteForecast(forecast)
          .then(() => {
            this.status.setMessage('Forecast removed', 'Success');
          })
          .catch(error => {
            this.status.setError(error);
          });
      });
    });
  }

}
