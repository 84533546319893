import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { UnivariateModelName } from '@modules/lang/language-files/stat-models';
import { Store } from '@ngxs/store';
import { ForecastFrontendService } from '../forecast/forecast.frontend.service';
import { MultivariateFrontendService } from './multivariate/multivariate.frontend.service';
import { StatModelActions } from './stat-model.actions';
import { UnivariateFrontendService } from './univariate/univariate.frontend.service';

@Injectable({
  providedIn: 'root'
})
export class StatModelService {

  constructor(
    private store: Store,
    private actions: ActionService,
    private navService: NavigationService,
    private envService: EnvironmentService,
    private forecastService: ForecastFrontendService,
    private uniService: UnivariateFrontendService,
    private multiService: MultivariateFrontendService
  ) {
    this.setupSubsriptions();
  }


  private handleFetchStatModel(a: StatModelActions.FetchStatModel) {
    const forecast = this.forecastService.forecastByVersionId(a.forecastVersionId);
    if (!forecast) { return; }
    const isOnForecast = this.navService.isOnForecast(forecast.ForecastId);
    const fetchInfo = a.data;

    switch (fetchInfo.ScriptName) {
      case this.envService.ScriptNames.Univariate:
        this.uniService.setNotFetched(fetchInfo.ForecastVersionId);
        if (!isOnForecast) { return; }
        this.uniService.fetchModel(fetchInfo.ForecastVersionId, fetchInfo.ModelName as UnivariateModelName);
        break;

      case this.envService.ScriptNames.Multivariate:
        this.multiService.setNotFetched(fetchInfo.ForecastVersionId);
        if (!isOnForecast) { return; }
        const g = this.envService.MultivariateModels.find(model => model.Model.Model === fetchInfo.ModelName);
        this.multiService.fetchModel(fetchInfo.ForecastVersionId, g);
        break;
    }
  }

  private setupSubsriptions() {
    this.actions
      .dispatched(StatModelActions.FetchStatModel)
      .subscribe((a: StatModelActions.FetchStatModel) => this.handleFetchStatModel(a));
  }
}

