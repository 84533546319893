import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { SourceVariableUsageDTO } from '@core/store/source-variable/dtos/used-dto';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel, SourceVariableViewDTO } from '@core/store/source-variable/source-variable.model';
import { TagMetaDTO } from '@core/store/tags/dtos/tags.dtos';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { FileDialogService } from '@dialogs/file/file-dialogs.service';
import { FileDialogViewIndex } from '@dialogs/file/info/file-info.dialog.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FileTypesType } from '@modules/lang/language-files/source-types';
import { Store } from '@ngxs/store';
import { AlgConverter } from '@shared/components/line-graph/alg-line.utils';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgOptions } from '@shared/components/line-graph/alg.options';
import {
  OpenCreateForecastVariableMultiModal
} from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { BehaviorSubject } from 'rxjs';

export class SourceVariableInfoDialogData {
  public sourceVariableId: string;
  public forecastId: string;
  public forecastVersionId: string;
}

@Component({
  selector: 'indicio-source-variable-info-dialog',
  templateUrl: './source-variable-info.dialog.html',
  styleUrls: ['./source-variable-info.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class SourceVariableInfoDialogComponent extends DialogV2BaseDialog<SourceVariableInfoDialogComponent> implements OnInit {

  // This dialog asks the user for an email and lets the user ask for a reset-password link.
  public static Id: string = 'SourceVariableInfoDialogComponent';

  public faSave = faSave as IconProp;
  public faInfo = faInfoCircle as IconProp;

  public chartStyles = { borderSize: 2, borderRadius: 4, historyLine: '#6388D0', plotHeight: 300 };

  private opts: SourceVariableInfoDialogData;
  public variable: SourceVariableModel = null;

  public forecast: ForecastModel;
  public forecastVersion: ForecastVersionModel;
  public versions: SourceVariableVersionModel[] = [];
  public activeVersion: SourceVariableVersionModel;
  public versionId: string;
  public isNonBaseVersion: boolean;
  public historicLine: ALGLineModel;
  public canShowGrapgh = false;
  public nameConflict = false;
  public newName = '';
  public savedName = '';
  public editName = false;
  public editVisibility = false;
  public isNewNamePending = false;
  public newVisibility = null;
  private savedVisibility = null;
  public isVariableUsedInForecast = false;
  public isVariableUsedInAnotherForecast = false;
  public createAndImportLoading = false;
  public isOwner: boolean;
  public graphOptions: AlgOptions.Options;
  public aggregationMethodId: string = IndicioConstants.EAggregationMethods.Average;
  private viewModel: SourceVariableViewDTO = null;
  private usedInfo: string[][] = null;

  private _syncing = new BehaviorSubject<boolean>(false);
  public isSyncing() { return this._syncing.asObservable(); }
  error: string;

  public get tagsStr() {
    if (this.variable.Tags.length === 0) { return 'No tags set'; }
    return this.variable.Tags.map(t => t.Name).join(', ');
  }

  public get periodicityReviewRequired() {
    return !!this.viewModel && this.activeVersion.Base && this.viewModel?.PeriodicityInfo !== null && !this.viewModel?.PeriodicityInfo.IsGoodFit && !this.viewModel?.ManualPeriodicity;
  }

  public get manualPeriodicity() { return this.viewModel?.ManualPeriodicity; }

  public get importBtnTippyText() {
    if (!this.initialized) { return 'Loading...'; }
    if (this.periodicityReviewRequired) { return 'Periodicity review required.'; }
    if (this.isVariableUsedInForecast) { return 'This variable is already present in the forecast.'; }
    return null;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: SourceVariableInfoDialogData,
    dialogRef: MatDialogRef<SourceVariableInfoDialogComponent>,
    protected store: Store,
    private status: StatusService,
    private sourceService: SourceVariableFrontendService,
    private forecastService: ForecastFrontendService,
    private profileService: ProfileFrontendService,
    public envService: EnvironmentService,
    public appearance: AppearanceService,
    private dialogService: DialogService,
    private fileDialogs: FileDialogService,
    private forecastVariableService: ForecastVariableFrontendService,
    private fvarMapper: ForecastVariableMapper,
  ) {
    super(dialogRef);
    this.opts = data;
    this.initialize();
  }

  public ngOnInit() {
    this.importToForecast.bind(this);
  }

  public removeFromNowcastVariables(array: SourceVariableVersionModel[]) {
    return array.filter(e => !e.FromNowcast);
  }

  public openReviewPeriodicityDialog() {
    this.dialogService.openManualPeriodicityDialog({ SourceVariableId: this.activeVersion.SourceVariableId, OpenedFromModal: true })
      .subscribe(changed => {
        if (!changed) { return; }
        this.sourceService.getOrFetchSourceVariable(this.variable.SourceVariableId)
          .then(variable => {
            this.variable = variable;
            this.setupMembers();
          });
      });
  }

  public deleteActiveSourceVariableMeta() {
    const ref = this.dialogService.openConfirmDialog({
      Title: 'Remove source variable version',
      Message: 'Are you sure you want to delete this source variable version? It will no longer be accessible from any forecast',
      ConfirmText: 'Remove',
      Style: 'warn'
    }, { width: '522px' });

    ref.subscribe((proceed: boolean) => {
      if (!proceed) { return; }
      this.sourceService.deleteSourceVariableVersion(this.activeVersion)
        .then(() => {
          this.status.setMessage('Source variable version deleted', 'Success', true);
          this.changeSelectedVersion(<MatSelectChange> { value: this.variable.getBaseVersion() });
          this.versions = this.removeFromNowcastVariables(this.variable.Versions);
          this.activeVersion = this.variable.getBaseVersion();
        })
        .catch(error => {
          this.status.setError(error, true);
        });
    });
  }

  public importToForecast() {
    if (this.isVariableUsedInForecast) { return; }

    const sourcevariable = this.variable;

    this.createAndImportLoading = true;

    const validAgg = this.envService.getValidAggregationMethods(sourcevariable, this.forecastVersion.Periodicity).some(x => x.Value === this.aggregationMethodId);
    sourcevariable._tmpAggregationMethod = this.aggregationMethodId;
    if (!validAgg) {
      this.close();
      return this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [sourcevariable.SourceVariableId]));
    }
    const fvar = this.fvarMapper.mapFromSourceVariableModel(sourcevariable, this.forecastVersion.ForecastVersionId, this.versionId);
    this.forecastVariableService.createForecastVariable(fvar)
      .then(() => {
        this.close();
        this.status.setMessage('Variable added successfully', 'Success');
      })
      .catch(err => {
        this.status.setError(err, true);
      })
      .finally(() => this.createAndImportLoading = false);

  }

  public changeSelectedVersion($event: MatSelectChange) {
    const newIdx = this.versions.findIndex(v => v.SourceVariableMetaId === $event.value);
    if (newIdx > -1) {
      this.activeVersion = this.versions.find(v => v.SourceVariableMetaId === $event.value);
      this.versionId = this.activeVersion.SourceVariableMetaId;
      this.isNonBaseVersion = this.activeVersion.Name !== 'Base';
    }
    this.updateGraphData();
  }

  public saveName() {
    if (this.variable.Name === this.newName) {
      return this.editName = false;
    }

    this.isNewNamePending = true;

    this.variable.Name = this.newName;
    this.savedName = this.newName;
    this.sourceService.editSourceVariable(this.variable)
      .then(() => {
        this.savedName = this.newName;
        this.status.setMessage('Name changed!', 'Success', true);
        this.editName = false;
      })
      .catch(error => {
        this.newName = this.savedName;
        this.status.setError(error, true);
      })
      .finally(() => this.isNewNamePending = false);
  }

  public saveVisibility() {
    this.activeVersion.VisibilityLevelId = this.newVisibility;
    this.variable.VisibilityLevelId = this.newVisibility;
    this.sourceService.editSourceVariable(this.variable)
      .then(() => {
        this.status.setMessage('New visibility level saved!', 'Success', true);
        this.editVisibility = false;
        this.savedVisibility = this.newVisibility;
      })
      .catch(error => {
        this.newVisibility = this.savedVisibility;
        this.status.setError(error, true);
      });
  }

  private updateGraphData() {
    const version = this.activeVersion;
    this.historicLine = AlgConverter.fromSourceVariable(version);
    this.historicLine.Active = true;
    this.setGraphOptions();
  }

  public editTags() {
    const ref = this.dialogService.openEditVariableTagsDialog({
      Tags: this.variable.Tags.slice()
    });
    ref.subscribe((updated: TagMetaDTO[]) => {
      if (updated === undefined) { return; }
      this.sourceService.saveSourceVariableTags(this.variable, updated)
        .then(updatedVar => {
          this.variable = updatedVar;
          this.status.setMessage('Tags updated', 'Success', true);
        })
        .catch(err => this.status.setError(err, true));
    });
  }

  public openFileModal() {
    this.close();
    this.fileDialogs.openFileInfo({
      fileId: this.variable.getFileId(),
      type: this.variable.SourceType as FileTypesType,
      view: FileDialogViewIndex.general,
      companyId: this.variable.CompanyId
    });
  }

  public syncRemoteVariable() {
    this._syncing.next(true);
    this.sourceService.syncVariableFromRemote(this.variable.CompanyId, this.variable.SourceVariableId)
      .then(async lastUpdated => {
        if (lastUpdated === null) {
          this.actOnMissingData();
        } else {
          this.status.setMessage('Variable successfully synced', 'Success', true);
          this.variable = await this.sourceService.fetchVariable(this.variable.SourceVariableId, this.variable);
          this.setupMembers();
        }
        this.variable.LastUpdated = lastUpdated;
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => this._syncing.next(false));
  }

  public openUsedInfoDialog() {
    this.syncUsed().then(x => this.dialogService.openGenericTableDialog({
      Title: 'Used in forecasts',
      Table: x,
      GridTemplate: '1fr 1fr',
      Transpose: false
    }));
  }

  public syncUsed() {
    if (this.usedInfo) { return Promise.resolve(this.usedInfo); }
    return this.sourceService.getSourceVariableUsedStatus([this.variable.SourceVariableId])
      .then(used => this.parseUsedInfo(used[0]));
  }

  private parseUsedInfo(used: SourceVariableUsageDTO) {
    if (!!!used.Usage) { return; }
    // Get all distinct projects
    const projectIds = [...new Set(used.Usage.map(p => p.ProjectId))];
    const projectNames = projectIds.map(id => used.Usage.find(p => p.ProjectId === id).ProjectName);
    const usedInfo = [['Forecast name', 'Forecast variable name']];
    projectNames.forEach((name, i) => {
      usedInfo.push(['Project: ' + name, '[bold]']);
      // Get all distinct forecasts for this project and sort by version, latest first.
      // And remove everything that is either a nowcast, a trend or a MF
      const forecasts = used.Usage.filter(p => p.ProjectId === projectIds[i]).filter(x => !x.IsMF && !x.IsNowcast && !x.IsTrend).sort((a, b) => b.ForecastVersion - a.ForecastVersion);
      const forecastIds = [...new Set(forecasts.map(f => f.ForecastId))];
      // Add remaining forecasts to the table
      forecastIds.forEach(f => {
        // Ensure that we only get the latest version of each forecast
        const forecast = forecasts.filter(x => x.ForecastId === f).reduce((prev, curr) => prev.ForecastVersion > curr.ForecastVersion ? prev : curr);
        usedInfo.push([forecast.ForecastName, forecast.ForecastVariableName]);
      });
      // Add a line break if there are more projects
      if (projectNames[i + 1]) { usedInfo.push(['&nbsp;', '']); }
    });
    this.isVariableUsedInAnotherForecast = used.Usage.length > 0;
    return usedInfo;
  }

  private setGraphOptions() {
    this.graphOptions = AlgOptions.CreateOptions({
      noDataText: '',
      inModal: false,
      menuConfig: {
        showMenu: false,
      },
      dontShowCIHover: true,
      axisConfig: {
        yAxisPosition: 'inside',
      },
      isPercent: false,
      forceOriginalData: true,
      closeCallback: () => this.close(),
      dates: this.historicLine.Values.map(x => x.m),
      showOnlyHistoric: true
    });
    const version = this.activeVersion;
    this.graphOptions.isPercent = version.IsPercent;
  }

  private actOnMissingData() {
    this.close();
    this.dialogService.openTextDialog({
      Text: 'This variable has been removed by the data provider. In order to be able to update it, remove this version of the variable and import it again.',
      Title: 'Variable source missing'
    }, { width: '500px' });
  }

  public openMacrobondInfo() {
    this.dialogService.openMacrobondSeriesInfoDialog({ RemoteReferenceId: this.variable.RemoteReferenceId });
  }

  public editGroupVariable() {
    this.dialogService.openCreateGroupVariableDialog({
      forecastVersionId: null,
      sourceVariableIds: this.variable.GroupMetaIds,
      variable: this.variable
    });
  }

  protected initialize() {
    const fPromise = this.forecastService.getOrFetchForecast(this.opts.forecastId);
    const fvPromise = this.forecastService.getOrFetchForecastVersion(this.opts.forecastVersionId);
    const sPromise = this.sourceService.getOrFetchSourceVariable(this.opts.sourceVariableId);
    const viewListPromise = this.sourceService.getOrFetchListView();

    Promise.all([fPromise, fvPromise, sPromise, viewListPromise])
      .then(([f, fv, s, vl]) => {
        this.forecast = f;
        this.forecastVersion = fv;
        this.variable = s;
        this.viewModel = vl.find(x => x.SourceVariableId === s.SourceVariableId);
        this.setupMembers();
      })
      .then(() => this.syncUsed())
      .catch(err => this.status.setError(err, true))
      .finally(() => { this.initialized = true; });
  }

  public close() {
    this.dialogRef.close();
  }

  private setupMembers() {
    this.versions = this.variable.Versions;
    this.versions = this.removeFromNowcastVariables(this.versions);
    this.savedName = this.variable.Name;
    this.newName = this.savedName;
    this.savedVisibility = this.variable.VisibilityLevelId;
    this.newVisibility = this.savedVisibility;
    this.activeVersion = this.variable.getBaseVersion();
    this.versionId = this.activeVersion.SourceVariableMetaId;
    this.isOwner = this.variable.OwnerEmail === this.profileService.profile.Email;
    this.isVariableUsedInForecast = this.forecastVersion.isSourceVariableUsed(this.variable.SourceVariableId);
    this.updateGraphData();
    this.canShowGrapgh = true;
  }
}
