import { ALGTypes } from '@shared/components/line-graph/alg-types';

export class ExcelFile {
  public FileName: string;
  public FileType: 'xlsx' | 'csv' = 'xlsx';
  public Sheets: ExcelSheet[] = [];
  public Type: ALGTypes.Graph;
  public IsPercentage: boolean;
  public Creator: string;
}

export class ExcelSheet {
  constructor() {
    this.RowDatesMap = new Map<number, number>();
  }

  public Name: string;
  public Title: string;
  public Rows: ExcelRow[] = [];
  private RowDatesMap: Map<number, number>;

  public get columnHeaderRow(): ExcelRow { return this.Rows.find(x => x.id === 'header'); }
  public get colorRow(): ExcelRow { return this.Rows.find(x => x.id === 'colorRow'); }
  public get rowsWithoutDates(): ExcelRow[] { return this.Rows.filter(x => !!x.m); }

  public getRowByDate(date: moment.Moment) {
    let rowIdx = this.RowDatesMap.get(date.unix());
    if (rowIdx) {
      return this.Rows[rowIdx];
    } else {
      return null;
    }
  }

  public addRow(row: ExcelRow) {
    if (row.m) {
      this.RowDatesMap.set(row.m.unix(), this.Rows.length);
    }
    this.Rows.push(row);
  }
}

export class ExcelRow {
  public Cells: ExcelCell[] = [];
  public Height: number = null;
  // Frontend helper fields
  m: moment.Moment;
  id: string;
}

export class ExcelCell {
  public Value: number | string;
  public BG?: string = null; // Background color
  public FontStyle?: object = null;
}
