import { Injectable } from '@angular/core';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ModelGroup, ModelName } from '@modules/lang/types/model-name';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClearStateAction } from '../../auth/auth.actions';
import { SetActiveForecastVersionSuccessAction } from '../../forecast/forecast.actions';
import { ScenarioAction } from '../../scenario/scenario.actions';
import {
  GetMultivariateModelPlotImagesSuccess, GetMultivariateModelSuccessAction, MultivariateActions, RemoveMultivariateModelAction, RemoveMultivariateResultsAction
} from '../stat-model.actions';
import { StatModel, StatTransformationModel } from '../stat-model.model';
import { StatModelState } from '../stat-model.state';
import { MultivariateBackendService } from './multivariate.backend.service';

@Injectable({
  providedIn: 'root'
})
export class MultivariateFrontendService {

  @Select(StatModelState.multivariateModels)
  public allModels$: Observable<StatModel[]>;
  public anyLoading = false;
  private _currentModels = new BehaviorSubject<StatModel[]>([]);
  public models$: Observable<StatModel[]>;
  public models: StatModel[] = [];
  public builtModels: StatModel[] = [];
  public allTransformations: StatTransformationModel[] = [];
  public builtTransformations: StatTransformationModel[] = [];
  public nonDisabledModels: StatModel[] = [];
  private fetchedVersions: string[] = [];
  private currentForecastVersionId: string = null;
  private _loading = new BehaviorSubject<boolean>(false);
  private _currentlyLoading = {};

  public anyLoading$() { return this._loading.asObservable(); }
  public isModelLoading(forecastVersionId: string, modelName: ModelName) {
    const state = this._currentlyLoading[forecastVersionId];
    return state ? !!state[modelName.Model] : false;
  }

  constructor(
    private store: Store,
    private env: EnvironmentService,
    private actions: ActionService,
    private service: MultivariateBackendService,
    private forecastService: ForecastFrontendService
  ) {
    this.models$ = this._currentModels.asObservable();
    this.initialize();
  }

  public triggerMultivariate(forecastVersionId: string) {
    this.currentForecastVersionId = forecastVersionId;
    this.store.dispatch(new MultivariateActions.ClearHistoricData(forecastVersionId));
    return this.service.triggerMultivariate(forecastVersionId)
      .then(() => {
        this.store.dispatch(new ScenarioAction.ClearResults(forecastVersionId));
        this.store.dispatch(new RemoveMultivariateResultsAction(forecastVersionId));
      });
  }

  public triggerMultivariateModels(forecastVersionId: string, models: ModelName[]) {
    this.currentForecastVersionId = forecastVersionId;
    this.store.dispatch(new MultivariateActions.ClearHistoricData(forecastVersionId));
    return this.service.triggerMultivariateModels(forecastVersionId, models)
      .then(() => models.forEach(m => this.removeModel(forecastVersionId, m)));
  }

  public removeMultivariateModels(forecastVersionId: string, models: ModelName[]) {
    this.currentForecastVersionId = forecastVersionId;
    return this.service.removeMultivariateModels(forecastVersionId, models)
      .then(() => models.forEach(x => this.removeModel(forecastVersionId, x)));
  }

  public fetchModelResidualFiles(fVersionId: string, modelIds: number[] = [], getAll: boolean = false) {
    let result: ResultFileDTO[] = [];
    if (getAll) {
      const models = this.store.selectSnapshot(StatModelState.multivariateModels)
        .filter(m => m.ForecastVersionId === fVersionId)
        .filter(m => m.ModelBuilt);

      modelIds = models.filter(m => m.Transforms.some(x => x.ImagesDTO === null)).map(m => m.Transforms.filter(t => t.ModelBuilt).map(t => t.MainModelId)).flatten();
      const intermediate = models.filter(m => m.Transforms.some(x => x.ImagesDTO !== null)).map(m => m.Transforms.filter(t => t.ModelBuilt).map(x => x.ImagesDTO.Files)).flatten();
      result = intermediate.length !== 0 ? intermediate.flatten() : [];
      if (modelIds.length === 0) {
        return Promise.resolve(result);
      }
    }
    return this.service.fetchModelResidualFiles(fVersionId, modelIds)
      .then(fetched => {
        this.store.dispatch(new GetMultivariateModelPlotImagesSuccess(fVersionId, fetched));
        const images = fetched.map(f => f.Files).flatten();
        if (getAll) {
          return [...images, ...result];
        } else {
          return [...images];
        }
      });
  }

  public getModelTextFiles(fversionId: string, models: StatTransformationModel[]) {
    return this.service.fetchModelTextFiles(fversionId, models.map(e => e.MainModelId))
      .then(fetched => {
        return fetched.body;
      });
  }

  public removeModel(forecastVersionId: string, modelName: ModelName) {
    this.store.dispatch(new ScenarioAction.ClearResults(forecastVersionId));
    this.store.dispatch(new RemoveMultivariateModelAction(forecastVersionId, modelName));
  }

  public getOrFetchById(forecastVersionId: string) {
    this.currentForecastVersionId = forecastVersionId;
    const { HasAll, Models } = this.getAllLoadedModelsForForecast(forecastVersionId);
    if (HasAll) {
      this._currentModels.next(Models);
      return Promise.resolve(Models);
    } else {
      return this.fetchMultivariateModels(forecastVersionId);
    }
  }

  public setFetched(forecastVersionId: string) {
    this.fetchedVersions.addUniqueId(forecastVersionId);
  }

  public setNotFetched(forecastVersionId: string) {
    this.fetchedVersions.removeId(forecastVersionId);
  }

  public fetchModel(forecastVersionId: string, group: ModelGroup) {
    if (!group) { return; }
    this.setLoading(forecastVersionId, group);
    return this.service.getMultivariateModel(forecastVersionId, group.Model)
      .then(models => {
        models.forEach(model => this.store.dispatch(new GetMultivariateModelSuccessAction(model)));
        return models;
      })
      .finally(() => this.setNotLoading(forecastVersionId, group));
  }

  public getOrFetchHistoricDataById(forecastVersionId: string) {
    return this.service.getOrFetchHistoricDataById(forecastVersionId);
  }

  private fetchMultivariateModels(forecastVersionId: string) {
    this.setAllLoading(forecastVersionId);
    this._currentModels.next([]);
    this.store.dispatch(new RemoveMultivariateResultsAction(forecastVersionId));
    return this.service.getAllMultivariateModels(forecastVersionId)
      .then(models => {
        models.forEach(model => this.store.dispatch(new GetMultivariateModelSuccessAction(model)));
        this.setFetched(forecastVersionId);
        return models;
      })
      .finally(() => this.setAllFinished(forecastVersionId));
  }

  ///
  ///   Private functions below
  ///

  /**
   * This function returns an object with 2 properties:
   * - HasAll: Is true if all the active models in the forecast are fetched.
   * - Models: Contains all the currently fetched models.
   *
   * Note: Returns an empty array of models and HasAll = true for unfetched forecast-versions
   * @param forecastVersionId
   */
  private getAllLoadedModelsForForecast(forecastVersionId: string): { HasAll: boolean, Models: StatModel[]; } {
    const forecast = this.forecastService.forecastByVersionId(forecastVersionId);
    // Get all models names that are active (regardless of calculation status) for the current forecast.
    const allModelNames = this.forecastService.getActiveMultivariateModels(forecast).map(x => x.Model.Model);
    const allFetchedModels = this.store.selectSnapshot(StatModelState.multivariateModels).filter(m => m.ForecastVersionId === forecastVersionId);
    const hasAll = allModelNames.every(modelName => !!allFetchedModels.find(x => x.ModelName === modelName));
    const currentlyLoaded = allFetchedModels.filter(m => !!allModelNames.find(am => am === m.ModelName));
    return { HasAll: hasAll, Models: currentlyLoaded };
  }

  private setLoading(forecastVersionId: string, group: ModelGroup) {
    if (-1 === Object.keys(this._currentlyLoading).findIndex(k => k === forecastVersionId)) {
      this._currentlyLoading[forecastVersionId] = {};
    }
    this._currentlyLoading[forecastVersionId][group.Model.Model] = true;
    this._loading.next(true);
  }

  private setNotLoading(forecastVersionId: string, group: ModelGroup) {
    if (-1 === Object.keys(this._currentlyLoading).findIndex(k => k === forecastVersionId)) {
      this._currentlyLoading[forecastVersionId] = {};
    }
    this._currentlyLoading[forecastVersionId][group.Model.Model] = false;
    if (!Object.values(this._currentlyLoading).some(x => x === true)) {
      this._loading.next(false);
    }
  }

  private setAllLoading(forecastVersionId: string) {
    Object.values(this.env.MultivariateModels).forEach(group => this.setLoading(forecastVersionId, group));
  }

  private setAllFinished(forecastVersionId: string) {
    Object.values(this.env.MultivariateModels).forEach(group => this.setNotLoading(forecastVersionId, group));
  }

  private initialize() {
    this.anyLoading$().subscribe(val => this.anyLoading = val);
    this.models$.subscribe(val => {
      this.models = val;
      this.nonDisabledModels = val.filter(x => !x.Disabled);
      this.builtModels = val.filter(m => m.ModelBuilt);
      this.allTransformations = val.reduce((acc, m) => acc.concat(m.Transforms), []);
      this.builtTransformations = this.allTransformations.filter(x => x.ModelBuilt);
      this.store.dispatch(new MultivariateActions.BuiltTransformsSet());
    });
    this.allModels$.subscribe(allModels => {
      if (allModels) {
        this._currentModels.next(allModels.filter(x => x.ForecastVersionId === this.currentForecastVersionId));
      }
    });
    this.actions.dispatched(SetActiveForecastVersionSuccessAction).subscribe((action: SetActiveForecastVersionSuccessAction) => {
      this.getOrFetchById(action.model.ForecastVersionId);
    });
    this.actions.dispatched(RemoveMultivariateResultsAction).subscribe((action: RemoveMultivariateResultsAction) => {
      if (this.currentForecastVersionId === action.forecastVersionId) {
        this._currentModels.next([]);
      }
    });
    this.actions.dispatched(ClearStateAction).subscribe(() => {
      this.fetchedVersions = [];
    });
  }
}
