import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { Normalizer } from '@shared/utils/array.utils';
import { StatisticsUtils } from '@shared/utils/statistic.utils';
import { TagTypes } from '../tags/dtos/tags.dtos';
import { ForecastTemplate, ForecastTemplateDTO, ForecastTemplateTagDTO, ForecastTemplateVariableDTO } from './forecast-template.model';
import { ForecastTemplateTypes } from './forecast-template.types';

@Injectable({
  providedIn: 'root'
})
export class ForecastTemplateMapper {

  constructor(
    private envService: EnvironmentService,
  ) {
  }

  public map(dto: ForecastTemplateDTO): ForecastTemplate {
    let model = Object.faMapTo(new ForecastTemplate, dto);
    model.Sources.forEach(s => s.source = this.envService.getSourceType(s.Source));
    model.sources = dto.Sources.map(x => x.source.Display).join(', ');
    model.indicatorCount = dto.Indicators.length;
    model.periodicity = this.envService.getPeriodicity(dto.Periodicity);
    model.MainForecastVariable = this.mapVariable(model.MainForecastVariable, dto);
    model.Indicators = model.Indicators
      .map(i => this.mapVariable(i, dto))
      .sort((a, b) => b.Influence - a.Influence);

    const influences = model.Indicators.map(x => ({ id: x.ForecastTemplateVariableId, influence: x.Influence, real: StatisticsUtils.roundNum(x.Influence, 4) }));
    const normalized = Normalizer.normalize(influences.map(x => x.influence), true);
    model.normalizedInfluence = influences.map((x, i) => ({ ...x, influence: normalized[i] }));
    model.authorEmail = dto.AuthorInfo.AuthorEmail;
    model.sourcesArray = dto.Sources.map(x => x.Source);
    model.tagsArray = dto.Tags.filter(x => x.Type !== TagTypes.USERDEFINEDREGION && x.Type !== TagTypes.COUNTRY && x.Type !== TagTypes.CONTINENT).map(x => x.Name);
    model.regionsArray = dto.Tags.filter(x => !model.tagsArray.includes(x.Name)).map(x => x.Name);

    model.Tags = dto.Tags.length ? dto.Tags : [Object.assign(new ForecastTemplateTagDTO, { Name: ForecastTemplateTypes.Untagged })];

    return model;
  }

  private mapVariable(variable: ForecastTemplateVariableDTO, dto: ForecastTemplateDTO) {
    variable.source = this.envService.getSourceType(variable.Source);
    variable.hasAccess = dto.Sources.find(x => x.Source === variable.Source).HasAccess;
    return variable;
  }
}
