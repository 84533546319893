import { AssessmentOverviewModalComponent } from '@shared/modals/assessments/overview/assessment-overview-modal.component';
import { AssessmentModalComponent } from '@shared/modals/assessments/specific/assessment-modal.component';
import { CompanyModalComponent } from '@shared/modals/company/overview/company-modal.component';
import { ConfirmModalComponent } from '@shared/modals/confirm/confirm-modal.component';
import { ChartDownloadModalComponent } from '@shared/modals/data-download/chart-download/chart-download-modal.component';
import { FileErrorInformationModalComponent } from '@shared/modals/files/file-error/file-error-information-modal.component';
import { FileUploadModalComponent } from '@shared/modals/files/upload-file/upload-file-modal.component';
import { CopyForecastModalComponent } from '@shared/modals/forecast/copy/copy-forecast-modal.component';
import { GDPRTextModalComponent } from '@shared/modals/gdpr/gdpr-text-modal.component';
import { CreateHistoricEventModalComponent } from '@shared/modals/historic-event/create/create-historic-event-modal.component';
import { EditHistoricEventModalComponent } from '@shared/modals/historic-event/edit/edit-historic-event-modal.component';
import { InfoModalComponent } from '@shared/modals/info/information-modal.component';
import { BrowseNowcastModalComponent } from '@shared/modals/nowcast/browse-nowcast/browse-nowcast-modal.component';
import { CreateNowcastModalComponent } from '@shared/modals/nowcast/create-nowcast/create-nowcast-modal.component';
import { ProfileModalComponent } from '@shared/modals/profile/profile-modal.component';
import { ProjectEditModalComponent } from '@shared/modals/project/edit-project/project-edit-modal.component';
import { TransferProjectModalComponent } from '@shared/modals/project/transfer-project/transfer-project-modal.component';
import { SecuritySettingsModalComponent } from '@shared/modals/security-settings/security-settings-modal.component';
import { StartDateModalComponent } from '@shared/modals/start-date/start-date-modal.component';
import { TosModalComponent } from '@shared/modals/tos/tos-modal.component';
import { Google2FAModalComponent } from './2-factor-providers/google/google-2fa-modal.component';
import { TaskModalComponent } from './auto-task/create-edit/task-modal.component';
import { TaskHistoryModalComponent } from './auto-task/history/task-history-modal.component';
import { ClientActivityModalComponent } from './client-activity/client-activity-modal.component';
import { ChangeCompanyModalComponent } from './company/change-company/change-company-modal.component';
import { SetCompanyModalComponent } from './company/set-company/set-company-modal.component';
import { ForecastDataDownloadModalComponent } from './data-download/forecast-data-download/forecast-data-download-modal.component';
import { DataProcessingModalComponent } from './data-processing/data-processing-modal.component';
import { DeleteUploadedFilesModalComponent } from './files/delete-multiple-files/delete-multiple-files-modal.component';
import { NewOrUpdatedFilesModalComponent } from './files/new-or-updated-files/new-or-updated-files.component';
import { CreateForecastVariableMultiModalComponent } from './forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.component';
import { DeleteForecastVariableModalComponent } from './forecast-variable/delete-forecast-variable/delete-forecast-variable-modal.component';
import { ForecastedIndicatorModalComponent } from './forecast-variable/forecasted-indicator/forecasted-indicator-modal.component';
import { UpdateMultiIndicatorsModalComponent } from './forecast-variable/update-multi-indicators/update-multi-indicators-modal.component';
import { UpdatedDataInfoModalComponent } from './forecast-variable/updated-data-info/updated-data-info-modal.component';
import { MoveForecastModalComponent } from './forecast/move/move-forecast-modal.component';
import { ForecastWarningsModalComponent } from './forecast/warnings/forecast-warnings-modal.component';
import { DeleteForecastEventModalComponent } from './historic-event/delete-forecast-event/delete-forecast-event-modal.component';
import { DeleteHistoricEventsModalComponent } from './historic-event/delete-multiple-events/delete-multiple-events-modal.component';
import { ImportHistoricEventsModalComponent } from './historic-event/import-multiple-events/import-multiple-events-modal.component';
import { HistoricEventsCorrelationModalComponent } from './imported-historic-events/correlation/event-correlation-modal.component';
import { EditImportedHistoricEventModalComponent } from './imported-historic-events/edit/edit-imported-historic-event-modal.component';
import { CreateNewsModalComponent } from './news/create-news/create-news-modal.component';
import { EditNewsModalComponent } from './news/edit-news/edit-news-modal.component';
import { MultiCreateNowcastModalComponent } from './nowcast/multi-create-nowcast/multi-create-nowcast-modal.component';
import { ACEAImportModalComponent } from './providers/acea/acea-import-modal.components';
import { AlphaCryptoModalComponent } from './providers/alphavantage/crypto/alpha-crypto-modal.component';
import { AlphaForexModalComponent } from './providers/alphavantage/forex/alpha-forex-modal.component';
import { AlphaMainModalComponent } from './providers/alphavantage/main/alpha-main-modal.component';
import { AlphaStockModalComponent } from './providers/alphavantage/stock/alpha-stock-modal.component';
import { FREDImportModalComponent } from './providers/fred/import/fred-import-modal.component';
import { KoladaImportModalComponent } from './providers/kolada/kolada-import-modal.component';
import { ModifyAPIKeyModalComponent } from './providers/modify-api-key/modify-api-key.component';
import { ModifyAPILoginModalComponent } from './providers/modify-api-login/modify-api-login.component';
import { OxfordImportModalComponent } from './providers/oxford/import/oxford-import-modal.components';
import { QuandlImportModalComponent } from './providers/quandl/quandl-import-modal.component';
import { RefinitivModalComponent } from './providers/refinitiv/refinitiv-modal.component';
import { RemoveSharepointModalComponent } from './providers/sharepoint/remove/remove-sharepoint-modal.component';
import { SharepointModalComponent } from './providers/sharepoint/sharepoint-modal.component';
import { CreateReportModalComponent } from './reports/create/create-report-modal.component';
import { DeleteSourceVariableModalComponent } from './source-variable/delete-source-variable/delete-source-variable-modal.component';
import { MultiUpdateRemoteDataModalComponent } from './source-variable/multi-update-remotedata/multi-update-remotedata-modal.component';
import { SourceVariableRevisionModalComponent } from './source-variable/revisions/source-variable-revision-modal.component';
import { VarSelectResultModalComponent } from './var-select/var-select-modal.component';
export { AssessmentOverviewModalComponent } from '@shared/modals/assessments/overview/assessment-overview-modal.component';
export { AssessmentModalComponent } from '@shared/modals/assessments/specific/assessment-modal.component';
export { TaskModalComponent as CreateTaskModalComponent } from '@shared/modals/auto-task/create-edit/task-modal.component';
export { CompanyModalComponent } from '@shared/modals/company/overview/company-modal.component';
export { ConfirmModalComponent } from '@shared/modals/confirm/confirm-modal.component';
export { ChartDownloadModalComponent } from '@shared/modals/data-download/chart-download/chart-download-modal.component';
export { FileErrorInformationModalComponent } from '@shared/modals/files/file-error/file-error-information-modal.component';
export { FileUploadModalComponent } from '@shared/modals/files/upload-file/upload-file-modal.component';
export { CopyForecastModalComponent } from '@shared/modals/forecast/copy/copy-forecast-modal.component';
export { GDPRTextModalComponent } from '@shared/modals/gdpr/gdpr-text-modal.component';
export { CreateHistoricEventModalComponent } from '@shared/modals/historic-event/create/create-historic-event-modal.component';
export { EditHistoricEventModalComponent } from '@shared/modals/historic-event/edit/edit-historic-event-modal.component';
export { InfoModalComponent } from '@shared/modals/info/information-modal.component';
export { BrowseNowcastModalComponent } from '@shared/modals/nowcast/browse-nowcast/browse-nowcast-modal.component';
export { CreateNowcastModalComponent } from '@shared/modals/nowcast/create-nowcast/create-nowcast-modal.component';
export { ProfileModalComponent } from '@shared/modals/profile/profile-modal.component';
export { ProjectEditModalComponent } from '@shared/modals/project/edit-project/project-edit-modal.component';
export { TransferProjectModalComponent } from '@shared/modals/project/transfer-project/transfer-project-modal.component';
export { StartDateModalComponent } from '@shared/modals/start-date/start-date-modal.component';
export { TosModalComponent } from '@shared/modals/tos/tos-modal.component';
export { Google2FAModalComponent } from './2-factor-providers/google/google-2fa-modal.component';
export { TaskHistoryModalComponent } from './auto-task/history/task-history-modal.component';
export { ClientActivityModalComponent } from './client-activity/client-activity-modal.component';
export { ChangeCompanyModalComponent } from './company/change-company/change-company-modal.component';
export { SetCompanyModalComponent } from './company/set-company/set-company-modal.component';
export { ForecastDataDownloadModalComponent } from './data-download/forecast-data-download/forecast-data-download-modal.component';
export { DataProcessingModalComponent } from './data-processing/data-processing-modal.component';
export { DeleteUploadedFilesModalComponent } from './files/delete-multiple-files/delete-multiple-files-modal.component';
export { NewOrUpdatedFilesModalComponent } from './files/new-or-updated-files/new-or-updated-files.component';
export { CreateForecastVariableMultiModalComponent } from './forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.component';
export { DeleteForecastVariableModalComponent } from './forecast-variable/delete-forecast-variable/delete-forecast-variable-modal.component';
export { UpdateMultiIndicatorsModalComponent } from './forecast-variable/update-multi-indicators/update-multi-indicators-modal.component';
export { UpdatedDataInfoModalComponent } from './forecast-variable/updated-data-info/updated-data-info-modal.component';
export { MoveForecastModalComponent } from './forecast/move/move-forecast-modal.component';
export { ForecastWarningsModalComponent } from './forecast/warnings/forecast-warnings-modal.component';
export { DeleteForecastEventModalComponent } from './historic-event/delete-forecast-event/delete-forecast-event-modal.component';
export { DeleteHistoricEventsModalComponent } from './historic-event/delete-multiple-events/delete-multiple-events-modal.component';
export { ImportHistoricEventsModalComponent } from './historic-event/import-multiple-events/import-multiple-events-modal.component';
export { HistoricEventsCorrelationModalComponent } from './imported-historic-events/correlation/event-correlation-modal.component';
export { EditImportedHistoricEventModalComponent } from './imported-historic-events/edit/edit-imported-historic-event-modal.component';
export { CreateNewsModalComponent } from './news/create-news/create-news-modal.component';
export { EditNewsModalComponent } from './news/edit-news/edit-news-modal.component';
export { MultiCreateNowcastModalComponent } from './nowcast/multi-create-nowcast/multi-create-nowcast-modal.component';
export { ACEAImportModalComponent } from './providers/acea/acea-import-modal.components';
export { AlphaCryptoModalComponent } from './providers/alphavantage/crypto/alpha-crypto-modal.component';
export { AlphaForexModalComponent } from './providers/alphavantage/forex/alpha-forex-modal.component';
export { AlphaMainModalComponent } from './providers/alphavantage/main/alpha-main-modal.component';
export { AlphaStockModalComponent } from './providers/alphavantage/stock/alpha-stock-modal.component';
export { FREDImportModalComponent } from './providers/fred/import/fred-import-modal.component';
export { KoladaImportModalComponent } from './providers/kolada/kolada-import-modal.component';
export { ModifyAPIKeyModalComponent } from './providers/modify-api-key/modify-api-key.component';
export { ModifyAPILoginModalComponent } from './providers/modify-api-login/modify-api-login.component';
export { QuandlImportModalComponent } from './providers/quandl/quandl-import-modal.component';
export { RefinitivModalComponent } from './providers/refinitiv/refinitiv-modal.component';
export { CreateReportModalComponent } from './reports/create/create-report-modal.component';
export { DeleteSourceVariableModalComponent } from './source-variable/delete-source-variable/delete-source-variable-modal.component';
export { MultiUpdateRemoteDataModalComponent } from './source-variable/multi-update-remotedata/multi-update-remotedata-modal.component';
export { SourceVariableRevisionModalComponent } from './source-variable/revisions/source-variable-revision-modal.component';
export { VarSelectResultModalComponent } from './var-select/var-select-modal.component';

export const IndicioModals = [
  AlphaCryptoModalComponent,
  AlphaForexModalComponent,
  AlphaMainModalComponent,
  AlphaStockModalComponent,
  RemoveSharepointModalComponent,
  AssessmentModalComponent,
  AssessmentOverviewModalComponent,
  TaskModalComponent,
  BrowseNowcastModalComponent,
  ChangeCompanyModalComponent,
  ChartDownloadModalComponent,
  ClientActivityModalComponent,
  CompanyModalComponent,
  ConfirmModalComponent,
  CopyForecastModalComponent,
  CreateForecastVariableMultiModalComponent,
  CreateHistoricEventModalComponent,
  CreateNewsModalComponent,
  CreateNowcastModalComponent,
  DataProcessingModalComponent,
  DeleteForecastEventModalComponent,
  DeleteForecastVariableModalComponent,
  DeleteHistoricEventsModalComponent,
  DeleteSourceVariableModalComponent,
  DeleteUploadedFilesModalComponent,
  EditHistoricEventModalComponent,
  EditNewsModalComponent,
  FREDImportModalComponent,
  OxfordImportModalComponent,
  ACEAImportModalComponent,
  SharepointModalComponent,
  FileErrorInformationModalComponent,
  FileUploadModalComponent,
  ForecastDataDownloadModalComponent,
  GDPRTextModalComponent,
  Google2FAModalComponent,
  ImportHistoricEventsModalComponent,
  InfoModalComponent,
  KoladaImportModalComponent,
  ModifyAPIKeyModalComponent,
  ModifyAPILoginModalComponent,
  MoveForecastModalComponent,
  ProfileModalComponent,
  ProjectEditModalComponent,
  QuandlImportModalComponent,
  RefinitivModalComponent,
  SecuritySettingsModalComponent,
  NewOrUpdatedFilesModalComponent,
  SourceVariableRevisionModalComponent,
  StartDateModalComponent,
  TosModalComponent,
  TransferProjectModalComponent,
  VarSelectResultModalComponent,
  SetCompanyModalComponent,
  EditImportedHistoricEventModalComponent,
  HistoricEventsCorrelationModalComponent,
  MultiCreateNowcastModalComponent,
  UpdateMultiIndicatorsModalComponent,
  MultiUpdateRemoteDataModalComponent,
  CreateReportModalComponent,
  ForecastWarningsModalComponent,
  UpdatedDataInfoModalComponent,
  ForecastedIndicatorModalComponent,
  TaskHistoryModalComponent
];
