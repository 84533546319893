import { ProfileDTO } from '@core/store/client/dtos/profile-dto';
import { UserSecurityInfoDTO } from '../components/system/users/entities/security.dtos';

export class ClientMetaDTO {
  Profile: ProfileDTO;
  LastActivity: Date;
  Disabled: boolean;
  TermsOfServiceAgreementId: string;
  ActiveCompany: string;
  SecurityInfo: UserSecurityInfoDTO;

  public get Email() { return this.Profile.Email; }
  public get Name() { return `${this.Profile.FirstName} ${this.Profile.LastName}`; }
  public get ClientId() { return this.Profile.ClientId; }
}

export class ClientInfoDTO {
  Companies: ClientCompanyInfoDTO[];
  Providers: ClientProviderInfoDTO[];
  InvitedBy: string;
  SecurityInfo: UserSecurityInfoDTO;
}

export class ClientCompanyInfoDTO {
  CompanyId: string;
  CompanyName: string;
  Role: string;
}

export class ClientProviderInfoDTO {
  Provider: string;
  CreatedDate: string;
}
