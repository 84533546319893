import { Injectable } from '@angular/core';
import { HistoricValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { CreateForecastVariableDTO } from '@core/store/forecast-variable/dtos/create-forecast-variable-dto';
import { ForecastVariableDTO } from '@core/store/forecast-variable/dtos/forecast-variable-dto';
import { ForecastVariableMetaDTO } from '@core/store/forecast-variable/dtos/forecast-variable-meta-dto';
import {
  ForecastVariableNowcastOptionsDTO,
  NowcastPeriodicityDTO
} from '@core/store/forecast-variable/dtos/forecast-variable-nowcast-options-dto';
import { OutlierInfoDTO } from '@core/store/forecast-variable/dtos/outlier-info-dto';
import { PriorDTO } from '@core/store/forecast-variable/dtos/prior-dto';
import { VariableSettingsDTO } from '@core/store/forecast-variable/dtos/variable-settings-dto';
import { BvarSettings, ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import {
  ForecastVariableNowcastOptionsModel,
  NowcastPeriodicity
} from '@core/store/forecast-variable/models/forecast-variable-nowcast-options.model';
import { OutlierInfoModel } from '@core/store/forecast-variable/models/outlier-info.model';
import { ForecastSubMapper } from '@core/store/forecast/mapper/forecast-pure-mapper';
import { Transformations } from '@core/types/transformation.type';
import { DateUtils } from '@shared/utils/date.utils';
import { PlotUtils } from '@shared/utils/plot.utils';
import { ClientFrontendService } from '../client/client.frontend.service';
import { SourceVariableModel } from '../source-variable/source-variable.model';


@Injectable({
  providedIn: 'root'
})
export class ForecastVariableMapper {
  constructor(
    private envService: EnvironmentService,
    private clientService: ClientFrontendService,
    private forecastSubMapper: ForecastSubMapper
  ) { }

  public map(dto: ForecastVariableDTO) {
    if (!dto || !dto.Meta) { return; }
    const model = this.mapMeta(dto.Meta);
    if (dto.ErrorMessages) { model.ErrorMessages = dto.ErrorMessages; }
    if (dto.WarningMessages) { model.WarningMessages = dto.WarningMessages; }
    if (dto.InfoMessages) {
      model.InfoMessages = dto.InfoMessages;
      model.SeasonalMessages = dto.InfoMessages.filter(s => s.includes('seasonal--')).map(e => e.split('--')[1]);
      model.OutlierMessages = dto.InfoMessages.filter(s => s.includes('outlier--')).map(e => e.split('--')[1]);
    }
    if (dto.ForecastVariableValues) {
      model.ForecastVariableValues = dto.ForecastVariableValues;
      model.ForecastVariableValues.forEach(v => DateUtils.MapIHasDate(v));
    }
    if (dto.ForecastVariableSeasonalValues) {
      model.ForecastVariableSeasonalValues = dto.ForecastVariableSeasonalValues;
      model.ForecastVariableSeasonalValues.forEach(v => DateUtils.MapIHasDate(v));
    }
    if (dto.FutureValues) {
      model.FutureValues = dto.FutureValues;
      model.FutureValues.forEach(v => DateUtils.MapIHasDate(v));
    }
    if (dto.ExogenousFutureValues) { model.ExogenousFutureValues = dto.ExogenousFutureValues; }
    if (dto.OutlierInfo) { model.OutlierInfo = dto.OutlierInfo.map(v => this.mapOutlierInfo(v)); }

    const mvp = dto.MissingValuePlotImages.map(v => this.mapImage(v));
    const opi = dto.OutlierPlotImages.map(v => this.mapImage(v));
    const api = dto.AggregationPlotImages.map(v => this.mapImage(v));

    if (dto.NowcastMeta) { model.Nowcast = this.forecastSubMapper.mapMetaToModel(dto.NowcastMeta); }
    if (dto.ForecastVariableValues) { model.maxNumberCount = this.getMaxNumbersCount(dto.ForecastVariableValues); }
    if (dto.DiagnosticInfos) {
      model.DiagnosticInfos = dto.DiagnosticInfos.map(e => {
        const tToUse = e.Transformation.replace(/(_norm$)|(_std$)/gm, '');
        e.transformation = this.envService.getVariableTransformation(tToUse);
        return e;
      });
    }
    if (dto.DetectedSeasonalOutliers) {
      dto.DetectedSeasonalOutliers.forEach(o => {
        o.Date = DateUtils.newDate(<string> <unknown> o.Date);
      });
      model.DetectedSeasonalOutliers = dto.DetectedSeasonalOutliers;
    }

    model.BVarSettings = this.mapBVarSettings(model);

    return Promise.all([
      Promise.all(mvp).then(m => model.MissingValuePlotImages = m),
      Promise.all(opi).then(m => model.OutlierPlotImages = m),
      Promise.all(api).then(m => model.AggregationPlotImages = m),
    ])
      .then(() => {
        return model;
      });
  }

  private mapBVarSettings(model: ForecastVariableModel): BvarSettings {
    const settings = new BvarSettings();
    settings.ForecastVariableId = model.ForecastVariableId;
    settings.Name = model.Name;
    settings.Transformation = model.PriorTransformation;
    settings.PriorMean = model.PriorMean;
    settings.PriorVariance = model.PriorVariance;
    settings.LogAllowed = model.ForecastVariableValues.every(x => x.V > 0);
    settings.ValidVariance = settings.validBVarVariance(settings.PriorVariance);
    return settings;
  }

  public mapMeta(dto: ForecastVariableMetaDTO) {
    const model = Object.faMapTo(new ForecastVariableModel(), dto);
    model.FirstDate = DateUtils.newDate(dto.FirstDate);
    model.LastDate = DateUtils.newDate(dto.LastDate);
    model.LatestDate = DateUtils.newDate(dto.LatestDate);
    model.NowcastOptions = this.mapNowcastOptions(dto.NowcastOptions);
    model.MissingValueIndicators = JSON.parse(dto.MissingValueIndicators);
    model.NowcastNeededAfterDate = DateUtils.newDate(dto.NowcastNeededAfterDate);
    model.Created = DateUtils.newDate(dto.Created);
    model.LastModified = DateUtils.newDate(dto.LastModified);
    model.periodicity = this.envService.getPeriodicity(dto.Periodicity);
    model.sourceVariablePeriodicity = this.envService.getPeriodicity(dto.SourceVariablePeriodicity);
    model.aggregationMethod = this.envService.getAggregationMethod(dto.AggregationMethodId);
    model.OutlierTypes = JSON.parse(dto.OutlierTypes);
    model.SeasonalCalendarEffects = JSON.parse(dto.SeasonalCalendarEffects);
    model.SeasonalCalendarHolidays = dto.SeasonalCalendarHolidays;
    model.SeasonalCalendarRegion = dto.SeasonalCalendarRegion;
    model.FoundSeasonalCalendarHolidays = dto.FoundSeasonalCalendarHolidays;
    model.FoundSeasonalCalendarRegion = dto.FoundSeasonalCalendarRegion;
    model.SeasonalOutlierTypes = JSON.parse(dto.SeasonalOutlierTypes);
    model.OutlierTypes.sort();
    model.SourceVariableAccess = (dto.CompanyShared && this.clientService.isEmployee())
      || this.clientService.client.ClientId === dto.OwnerId || this.clientService.isAdmin;

    model.isUpdatable = dto.Source !== 'forecast' && dto.Source !== 'trend' && dto.Source !== 'other' && dto.Source !== 'group';
    model.sourceType = this.envService.getSourceType(dto.Source);
    model.PriorTransformation = this.envService.getVariableTransformation(dto.PriorTransformation);
    model.ValueTransform = new Transformations.FVarTrans(dto.SourceValuesTransformation);
    return model;
  }

  public mapNowcastOptions(dto: ForecastVariableNowcastOptionsDTO): any {
    const model = Object.faMapTo(new ForecastVariableNowcastOptionsModel, dto);
    model.SourceLastValueDate = DateUtils.newDate(dto.SourceLastValueDate);
    model.Periodicities = dto.Periodicities.map(x => this.mapNowcastPeriodicity(x));
    model.periodicity = this.envService.getPeriodicity(dto.SourcePeriodicity);
    model.recommendedPeriodicity = this.envService.getPeriodicity(dto.RecommendedPeriodicity);
    return model;
  }

  public mapFromSourceVariableModel(sModel: SourceVariableModel, fvId: string, soureMetaId?: string): ForecastVariableModel {
    const version = !!!soureMetaId ? sModel.getBaseVersion() : sModel.getVersionById(soureMetaId);
    const fV = new ForecastVariableModel();
    fV.SourceVariableId = sModel.SourceVariableId;
    fV.SourceVariableMetaId = version.SourceVariableMetaId;
    fV.ForecastVersionId = fvId;
    fV.Name = sModel.Name;
    fV.AggregationMethodId = sModel._tmpAggregationMethod || version.AggregationMethodId;
    fV.FirstDate = version.FirstDate;
    fV.LastDate = version.LastDate;
    fV.Periodicity = sModel.periodicity;
    fV.status = 'Pending';
    return fV;
  }

  public toCreateDto(model: ForecastVariableModel) {
    return Object.faMapTo(new CreateForecastVariableDTO(), model);
  }

  public toPriorDto(model: ForecastVariableModel) {
    const dto = Object.faMapTo(new PriorDTO(), model);
    dto.PriorTransformation = model.PriorTransformation.Value;
    return dto;
  }

  public toUpdateSettings(model: ForecastVariableModel) {
    const dto = Object.faMapTo(new VariableSettingsDTO(), model);
    dto.SourceValuesTransformation = model.ValueTransform.toDto();
    if (model.ExogenousFutureValues) {
      dto.ExogenousFutureValues = model.ExogenousFutureValues;
    }

    return dto;
  }

  private mapOutlierInfo(dto: OutlierInfoDTO) {
    const model = Object.faMapTo(new OutlierInfoModel(), dto);
    model.Date = DateUtils.newDate(dto.Date);
    return model;
  }

  private mapNowcastPeriodicity(dto: NowcastPeriodicityDTO) {
    const model = new NowcastPeriodicity();
    model.Periodicity = dto.Periodicity;
    model.MissingDataPoints = dto.MissingDataPoints;
    model.Main = dto.Main;
    model.LastValueDate = DateUtils.newDate(dto.LastValueDate);
    model.RequiredValueDate = DateUtils.newDate(dto.RequiredValueDate);
    return model;
  }

  private mapImage(dto: ResultFileDTO) {
    const promise = PlotUtils.UnzipPlotImage(dto, dto.Name);
    return promise.then(img => {
      dto.Base64String = img.Base64String;
      dto.MimeType = img.MimeType;
      dto.sortIndex = this.getSortIndex(dto.Name);
      return dto;
    });
  }

  private getMaxNumbersCount(values: HistoricValueDTO[]) {
    const max = Math.max(...values.map(x => Math.abs(x.V)));
    return max.toString().length;
  }

  getSortIndex(name: string): number {
    switch (true) {
      case /missing_values_overimpute/.test(name): return 1;
      case /missing_values_compare/.test(name): return 2;
      case /missing_values/.test(name): return 3;
      case /seasonal_component/.test(name): return 1;
      case /seasonal_forecast_component/.test(name): return 2;
      case /trend_component/.test(name): return 3;
      case /irregular_component/.test(name): return 4;
      case /original_vs_adjusted/.test(name): return 5;
      default: return 100;
    }
  }
}
