import { Pipe, PipeTransform } from '@angular/core';
import { IFileFilters } from '@core/interfaces/if-file';
import { FilterBase } from './filter-base';

export class UploadedFilesFilters {
  ObjectName: string;
  Used: boolean;
}

@Pipe({
  name: 'filterUploadedFiles'
})
export class FilterUploadedFilesPipe extends FilterBase implements PipeTransform {
  filters: UploadedFilesFilters;
  transform(items: IFileFilters[], filters: UploadedFilesFilters, _trigger): any {
    filters = this._fixFilterValues(filters);
    this.filters = filters;
    let filteredList = items;

    // Filter used - this should filter out those used if active
    if (this._checkFilter('Used') && filters.Used === false) {
      filteredList = filteredList.filter(x => x.Used === false);
    }

    // Search
    if (this._checkFilter('ObjectName')) {
      filteredList = filteredList.filter(item =>
        // Search events
        new RegExp(filters['ObjectName'], 'gi').test(item['ObjectName'])
      );
    }

    return filteredList;
  }
}
