<indicio-modal-base [child]="this"
                    data-test-id="tos-modal-container"
                    modalTitle="Terms of service"
                    [loading]="isLoading">

  <div *ngIf="tos"
       class="tos-container unselectable">
    <div class="tos"
         data-test-id="tos-modal-text-container"
         (scroll)="onScroll($event)">
      <markdown>
        {{ tos.Content }}
      </markdown>
    </div>
    <div class="row accept-tos">
      <div class="valid-from">
        Valid from: {{ tos.ValidFrom | date: appearance.DateFormat }}
      </div>
      <button *ngIf="(hasAcceptedTos$ | async) === false"
              class="btn"
              [ngStyle]="{ 'background-color': atBottom ?  '#6388D0' : '#999'}"
              (click)="acceptTos()"
              data-test-id="tos-modal-accept-btn"
              [disabled]="! atBottom"
              title="Scroll down to accept the terms">
        <span *ngIf="!saving">Accept</span>
        <span *ngIf="saving"
              class="ion-load-c spinner"></span>
      </button>
      <button *ngIf="(hasAcceptedTos$ | async)"
              class="btn"
              data-test-id="tos-modal-ok-btn"
              [ngStyle]="{ 'background-color': '#009966' }"
              (click)="close()">
        Ok
      </button>
    </div>
  </div>
</indicio-modal-base>
