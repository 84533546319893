import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { ClientMapper } from '@core/store/client/client-mapper';
import { DisplayFilterModel } from '@core/store/client/display-filter.model';
import { DisplayFilterDTO } from '@core/store/client/dtos/display-filter-dto';
import { GraphThemeDTO } from '@core/store/client/dtos/graph-theme-dto';
import { GraphThemeModel } from '@core/store/client/graph-theme.model';
import { ProfileModel } from '../client/profile.model';


@Injectable({
  providedIn: 'root'
})
export class ProfileBackendService {

  constructor(
    private base: HttpService,
    private mapper: ClientMapper,
  ) { }

  public updateProfile(profile: ProfileModel) {
    const dto = this.mapper.mapProfileDTO(profile);
    return this.base.put<null>('my/profile', dto)
      .then(() => profile);
  }

  public createTheme(graphTheme: GraphThemeModel) {
    const dto = this.mapper.mapGraphThemeDTO(graphTheme);
    return this.base.post<GraphThemeDTO>('my/profile/graph-theme', dto)
      .then(result => this.mapper.mapGraphTheme(result.body));
  }

  public updateTheme(graphTheme: GraphThemeModel) {
    const dto = this.mapper.mapGraphThemeDTO(graphTheme);
    return this.base.put<GraphThemeDTO>('my/profile/graph-theme', dto)
      .then(result => this.mapper.mapGraphTheme(result.body));
  }

  public deleteTheme(graphTheme: GraphThemeModel) {
    return this.base.delete<null>(`my/profile/graph-theme/${graphTheme.GraphThemeId}`);
  }

  public getDisplayFilters() {
    return this.base.get<DisplayFilterDTO[]>('my/profile/display-filters')
      .then(result => result.body.map(df => this.mapper.mapDisplayFilter(df)));
  }

  public createDisplayFilter(filter: DisplayFilterModel, component: string) {
    const dto = this.mapper.mapDisplayFilterDTO(filter, component);
    return this.base.post<DisplayFilterDTO>('my/profile/display-filters', dto)
      .then(result => this.mapper.mapDisplayFilter(result.body));
  }

  public updateDisplayFilter(filter: DisplayFilterModel, component: string) {
    const dto = this.mapper.mapDisplayFilterDTO(filter, component);
    return this.base.put<DisplayFilterDTO>('my/profile/display-filters', dto)
      .then(result => this.mapper.mapDisplayFilter(result.body));
  }
}
