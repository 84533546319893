
import { NavigationComponent } from '@modules/root/components/navigation/navigation.component';
import { LoaderComponent } from '@modules/root/components/page-load/loader.component';
import { AboutFooterComponent } from './about-footer/about-footer.component';
import { IconAndTextLogoComponent } from './icon-and-text-logo/icon-and-text-logo.component';
import { LoadingProfileComponent } from './loading-profile/loading-profile.component';
import { ForecastNavTreeForecastNodeComponent } from './navigation/components/forecasts-drawer/components/forecast-node/forecast-node.component';
import { ForecastNavTreeComponent } from './navigation/components/forecasts-drawer/components/nav-tree/forecast-nav-tree.component';
import { ForecastsDrawerComponent } from './navigation/components/forecasts-drawer/forecasts-drawer.component';
import { ReportsDrawerComponent } from './navigation/components/reports-drawer/reports-drawer.component';
import { WikiSidebarComponent } from './wiki-sidebar/wiki-sidebar.component';

export { AboutFooterComponent } from './about-footer/about-footer.component';
export { IconAndTextLogoComponent } from './icon-and-text-logo/icon-and-text-logo.component';
export { LoadingProfileComponent } from './loading-profile/loading-profile.component';
export { ForecastNavTreeForecastNodeComponent } from './navigation/components/forecasts-drawer/components/forecast-node/forecast-node.component';
export { ForecastNavTreeComponent } from './navigation/components/forecasts-drawer/components/nav-tree/forecast-nav-tree.component';
export { ForecastsDrawerComponent } from './navigation/components/forecasts-drawer/forecasts-drawer.component';
export { ReportsDrawerComponent } from './navigation/components/reports-drawer/reports-drawer.component';
export { WikiSidebarComponent } from './wiki-sidebar/wiki-sidebar.component';

export const IndicioRootComponents = [
  AboutFooterComponent,
  IconAndTextLogoComponent,
  LoadingProfileComponent,
  NavigationComponent,
  ReportsDrawerComponent,
  ForecastsDrawerComponent,
  ForecastNavTreeComponent,
  ForecastNavTreeForecastNodeComponent,
  WikiSidebarComponent,
  LoaderComponent,
];
