import { DisplayValue } from './display-value';


export class ModelGroup {
  Model: ModelName;
  Transforms: ModelName[];
}
export class ModelName extends DisplayValue<string> {

  GroupName: string;
  Short: string;
  Model: string;
  Sub: number;
  Group: number;
  Text: string;
  // Regular expression to match against strings, regardless of case
  nameRegEx: RegExp;

  constructor(opts: {
    value: string;
    display: string,
    desc: string,
    hover: string,
    model: string,
    groupName: string,
    group: number,
    sub: number,
    short: string;
  }
  ) {
    super(opts.value, opts.display, opts.hover);
    this.nameRegEx = new RegExp(`^${this.Value}`, 'i');
    this.Model = opts.model;
    this.Sub = opts.sub;
    this.Group = opts.group;
    this.Short = opts.short;
    this.GroupName = opts.groupName;
    this.Text = opts.desc;
  }
}

export class EmptyModelGroup extends ModelGroup {
  constructor() {
    super();
    this.Model = EmptyModelName();
    this.Transforms = [];
  }
}

export function EmptyModelName(): ModelName {
  return {
    Description: '',
    Display: '',
    Group: 0,
    Model: 'Unknown',
    GroupName: 'Unknown',
    Short: '',
    Sub: 0,
    Text: 'Unknown',
    Value: 'Unknown',
    nameRegEx: /unknown/i,
  };
}
