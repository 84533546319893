import { IMemberModel } from '@core/interfaces/if-member';
import { IMemberService } from '@core/interfaces/if-member.service';
import { DisplayValue } from '@modules/lang/types/display-value';
import { MemberListType } from './members-list.component';


export class MemberListOptions {
  CurrentUserEmail: string;
  CurrentUserCanInvite: boolean;
  NewMemberSuggestions: string[] = [];
  NewMemberRole: DisplayValue<string>;
  NewMemberRoleOptions: DisplayValue<string>[] = [];
  CurrentMembers: IMemberModel[] = [];
  MemberService: IMemberService = null;
  Type: MemberListType;
  /* CompanyId or ProjectId */
  CompanyId: string;
  ProjectId?: string;
}

