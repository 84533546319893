import { Component, Input, OnChanges } from '@angular/core';
import { AccuracyMeasureType, AccuracyWeightMethod, AccuracyWeightMethodOptions, ExtendedAccuracyDropdownOptions } from '@core/constants/accuracy.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ScenarioDTO } from '@core/store/scenario/dtos/scenario.dtos';
import { ModelGroup } from '@modules/lang/types/model-name';
import { SummaryUtils } from '@shared/utils/forecast/summary.utils';

@Component({
  selector: 'indicio-scenario-dialog-options-tab',
  templateUrl: './scenario-tab.options.component.html',
  styleUrls: ['./scenario-tab.options.component.less']
})
export class ScenarioOptionsTabComponent implements OnChanges {

  @Input() scenario: ScenarioDTO;
  @Input() availableModels: string[];

  public isLoading: boolean = true;

  public weightMeasure: SummaryUtils.WeightMeasure = { Measure: 'RMSE', Method: 'Stepwise' };

  public accuracyMeasurementOpts = ExtendedAccuracyDropdownOptions;
  public accuracyMeasureMethods = AccuracyWeightMethodOptions;
  public exponentChoices: number[] = Array.from({ length: 10 }, (v, k) => k + 1);
  public modelChoices: number[] = [0, 3, 5, 8];

  public displayModels: ModelGroup[] = [];

  constructor(
    public env: EnvironmentService
  ) { }

  public ngOnChanges() {
    this.setup();
  }

  public onWheightMeasure(measure: AccuracyMeasureType) {
    this.weightMeasure.Measure = measure;
    this.scenario.WeightSchema = SummaryUtils.getWeightMeasureString(this.weightMeasure);
  }

  public onWheightMeasureMethod(method: AccuracyWeightMethod) {
    this.weightMeasure.Method = method;
    this.scenario.WeightSchema = SummaryUtils.getWeightMeasureString(this.weightMeasure);
  }

  private async setup() {
    this.weightMeasure = SummaryUtils.getWeightMeasure(this.scenario.WeightSchema);
    this.displayModels = this.env.MultivariateModels;

    this.displayModels = this.displayModels.filter(x => this.availableModels.includes(x.Model.Value));

    this.isLoading = false;
  }
}
