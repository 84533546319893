import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { AssessmentFrontendService } from '@core/store/assessment/assessment.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { FileFrontendService } from '@core/store/file/file.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { HistoricEventFrontendService } from '@core/store/historic-event/historic-event.frontend.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { ForecastModuleReloadAction, RemoveForecastSuccessAction, SetForecastAsNotFetchedAction } from '../forecast/forecast.actions';
import { RemoveNewsPostSuccessAction } from '../news/news.actions';
import { NewsFrontendService } from '../news/news.frontend.service';
import { RemoveProjectSuccessAction } from '../project/project.actions';
import { ScenarioFrontendService } from '../scenario/scenario.frontend.service';
import { NotificationModel } from './notification.model';
import { NotificationTypes } from './notification.types';

@Injectable({
  providedIn: 'root'
})
export class NotificationBehaviourService {

  private get myEmail() { return this.profileService.profile.Email; }

  constructor(
    private profileService: ProfileFrontendService,
    private assessmentService: AssessmentFrontendService,
    private companyService: CompanyFrontendService,
    private fileService: FileFrontendService,
    private forecastService: ForecastFrontendService,
    private forecastVariableService: ForecastVariableFrontendService,
    private historicEventService: HistoricEventFrontendService,
    private projectService: ProjectFrontendService,
    private scenarioService: ScenarioFrontendService,
    private sourceVariableService: SourceVariableFrontendService,
    private newsService: NewsFrontendService,
    private store: Store,
    private router: Router,
    private navService: NavigationService
  ) { }

  public setupNotification(notification: NotificationModel, isNew: boolean) {
    const message = notification.Message;
    const myId = this.profileService.profile.ClientId;
    switch (message.Type) {
      /*****************
       * VARIABLES
       *****************/
      // Performance: Action rewrite - DONE
      case NotificationTypes['new-forecast-variable']:
        message.title = `**${message.ActorClientName}** added a new variable to forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-arrow-graph-up-right';
        return notification;

      case NotificationTypes['updated-forecast-variable']:
        message.title = `**${message.ActorClientName}** updated a variable in forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-arrow-graph-up-right';
        if (isNew) {
          this.forecastVariableService.fetchById(message.ForecastVersionId, message.ObjectId);
        }
        return notification;

      case NotificationTypes['updated-forecast-variable-settings']:
        message.title = `**${message.ActorClientName}** updated settings for **${message.ObjectName}** in forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-arrow-graph-up-right';
        if (isNew) { this.forecastVariableService.fetchById(message.ForecastVersionId, message.ObjectId); }
        return notification;

      case NotificationTypes['updated-forecast-variable-outlier-info']:
        message.title = `**${message.ActorClientName}** updated outlier information for **${message.ObjectName}** in forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-arrow-graph-up-right';
        if (isNew) { this.forecastVariableService.fetchById(message.ForecastVersionId, message.ObjectId); }
        return notification;

      case NotificationTypes['removed-forecast-variable']:
        message.title = `**${message.ActorClientName}** removed a variable from forecast **${message.ForecastName}**`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-arrow-graph-up-right';
        if (isNew) {
          this.forecastVariableService.removeForecastVariable(message.ForecastVersionId, message.ObjectId);
        }
        return notification;

      case NotificationTypes['refreshed-forecast-variable']:
        message.title = `**${message.ActorClientName}** refreshed a variable in forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-refresh';
        if (isNew && message.ActorClientId !== myId) {
          this.forecastVariableService.fetchById(message.ForecastVersionId, message.ObjectId);
        }
        return notification;

      case 'updated-source-variable':
        message.title = `**${message.ActorClientName}** updated a source variable.`;
        if (message.ForecastId) {
          message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
          message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        }
        message.icon = 'ion-arrow-graph-up-right';
        if (isNew) {
          this.sourceVariableService.fetchVariable(message.ObjectId).catch(err => { });
        }
        return notification;

      case NotificationTypes['removed-source-variable']:
        message.title = `**${message.ActorClientName}** removed source variable **${message.ObjectName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-arrow-graph-up-right';
        return notification;

      case NotificationTypes['new-source-variable']:
        message.title = `**${message.ActorClientName}** created a new source variable.`;
        message.linkUrl = null;
        message.icon = 'ion-arrow-graph-up-right';
        if (isNew) { this.sourceVariableService.fetchVariable(message.ObjectId).catch(err => { }); }
        return notification;

      /*****************
       * HISTORIC EVENTS
       *****************/
      case NotificationTypes['new-historic-event-in-forecast']:
        message.title = `**${message.ActorClientName}** added a new historic event to forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-android-time';
        if (isNew) {
          this.historicEventService.syncEventsInForecast(message.ForecastVersionId);
          this.forecastService.fetchVarSelectSettings(message.ForecastId, message.ForecastVersionId);
        }
        return notification;

      case NotificationTypes['updated-historic-event-in-forecast']:
        message.title = `**${message.ActorClientName}** updated an historic event in forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-android-time';
        if (isNew) { this.historicEventService.syncEventsInForecast(message.ForecastVersionId); }
        return notification;

      case NotificationTypes['removed-historic-event-from-forecast']:
        message.title = `**${message.ActorClientName}** removed an historic event from forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-android-time';
        if (isNew) {
          this.historicEventService.syncEventsInForecast(message.ForecastVersionId);
          this.forecastService.fetchVarSelectSettings(message.ForecastId, message.ForecastVersionId);
        }
        return notification;

      case NotificationTypes['new-historic-event']:
        message.title = `**${message.ActorClientName}** added a new historic event.`;
        message.linkUrl = null;
        message.icon = 'ion-stats-bars';
        if (isNew) { this.historicEventService.fetchHistoricBaseEventsInCompany(message.CompanyId); }
        return notification;

      case NotificationTypes['removed-historic-event']:
        message.title = `**${message.ActorClientName}** removed historic event.`;
        message.linkUrl = null;
        message.icon = 'ion-stats-bars';
        if (isNew) { this.historicEventService.fetchHistoricBaseEventsInCompany(message.CompanyId); }
        return notification;

      case NotificationTypes['updated-historic-event']:
        message.title = `**${message.ActorClientName}** updated an historic event.`;
        message.linkUrl = null;
        message.icon = 'ion-stats-bars';
        if (isNew) { this.historicEventService.fetchHistoricBaseEventsInCompany(message.CompanyId); }
        return notification;

      /*****************
       * ASSESSMENTS
       *****************/
      case NotificationTypes['removed-assessment']:
        message.title = `**${message.ActorClientName}** removed an assessment from forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-chatbubble-working';
        if (isNew) { this.assessmentService.syncAssessmentsInForecast(message.ForecastVersionId); }
        return notification;

      case NotificationTypes['new-assessment']:
        message.title = `**${message.ActorClientName}** added a new assessment to forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-chatbubble-working';
        if (isNew) { this.assessmentService.syncAssessmentsInForecast(message.ForecastVersionId); }
        return notification;

      case NotificationTypes['updated-assessment']:
        message.title = `**${message.ActorClientName}** updated an assessment in forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-chatbubble-working';
        if (isNew) { this.assessmentService.getAssessment(message.ForecastId, message.ObjectId); }
        return notification;

      /*****************
       * FILES
       *****************/
      case NotificationTypes['new-file']:
        message.title = `**${message.ActorClientName}** uploaded a new file.`;
        message.linkUrl = null;
        message.icon = 'ion-document';
        if (isNew) { this.fileService.fetchFilesInCompany(message.CompanyId); }
        return notification;

      case NotificationTypes['new-file-version']:
        message.title = `**${message.ActorClientName}** created a new file version.`;
        message.linkUrl = null;
        message.icon = 'ion-document';
        if (isNew) { this.fileService.fetchFilesInCompany(message.CompanyId); }
        return notification;

      case NotificationTypes['updated-file']:
        message.title = `**${message.ActorClientName}** updated a file.`;
        message.linkUrl = null;
        message.icon = 'ion-document';
        if (isNew) { this.fileService.fetchFilesInCompany(message.CompanyId); }
        return notification;

      case NotificationTypes['removed-file']:
        message.title = `**${message.ActorClientName}** removed a file.`;
        message.linkUrl = null;
        message.icon = 'ion-document';
        if (isNew) { this.fileService.fetchFilesInCompany(message.CompanyId); }
        return notification;

      case NotificationTypes['file-ownership-change']:
        message.title = `You are now owner of file **${message.ObjectName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-document';
        if (isNew) { this.fileService.fetchFilesInCompany(message.CompanyId); }
        return notification;

      /*****************
       * FORECAST
       *****************/
      case NotificationTypes['new-forecast']:
        message.title = `**${message.ActorClientName}** added a new forecast named **${message.ForecastName}** to project **${message.ProjectName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-stats-bars';
        if (isNew) {
          this.forecastService.getForecastMeta(message.ForecastId).then(() => {
            this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
          });
        }
        return notification;

      case NotificationTypes['new-forecast-version']:
        message.title = `**${message.ActorClientId === myId ? 'You' : message.ActorClientName}** created a new version in forecast **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-stats-bars';
        if (isNew) {
          if (this.navService.isOnForecast(message.ForecastId)) {
            this.forecastService.fetchForecast(message.ForecastId)
              .then(f => {
                this.forecastService.fetchForecastVersion(f.getLatestVersion().ForecastVersionId)
                  .then(() => {
                    const vers = f.getLatestVersion();
                    const proj = this.projectService.projectById(f.ProjectId);
                    const comp = this.companyService.getCompany(proj.CompanyId);
                    this.store.dispatch(new ForecastModuleReloadAction(vers, comp, proj));
                  });
              });
          } else {
            this.store.dispatch(new SetForecastAsNotFetchedAction(message.ForecastId));
          }
        }
        return notification;

      case NotificationTypes['updated-forecast']:
        message.title = `**${message.ActorClientName}** updated forecast **${message.ForecastName}** in project **${message.ProjectName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-stats-bars';
        if (isNew) {
          this.forecastService.getForecastMeta(message.ForecastId).then(() => {
            this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
          });
        }
        return notification;

      case NotificationTypes['updated-forecast-version']:
        message.title = `**${message.ActorClientName}** updated forecast **${message.ForecastName}** in project **${message.ProjectName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-stats-bars';
        if (isNew) {
          if (this.navService.isOnForecast(message.ForecastId)) {
            this.forecastService.fetchForecast(message.ForecastId)
              .then(f => {
                this.forecastService.fetchForecastVersion(f.getLatestVersion().ForecastVersionId)
                  .then(() => {
                    const vers = f.getLatestVersion();
                    const proj = this.projectService.projectById(f.ProjectId);
                    const comp = this.companyService.getCompany(proj.CompanyId);
                    this.store.dispatch(new ForecastModuleReloadAction(vers, comp, proj));
                  });
              });
          } else {
            this.store.dispatch(new SetForecastAsNotFetchedAction(message.ForecastId));
          }
        }
        return notification;

      case NotificationTypes['removed-forecast']:
        message.title = `**${message.ActorClientName}** removed forecast **${message.ForecastName}** from project **${message.ProjectName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-stats-bars';
        if (isNew) {
          this.store.dispatch(new RemoveForecastSuccessAction(message.ForecastId));
          this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        }
        return notification;

      case NotificationTypes['removed-nowcast']:
        message.title = `**${message.ActorClientName}** removed a nowcast from forecast **${message.ForecastName}**`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-stats-bars';
        if (isNew) {
          const fv = this.forecastService.forecastVersionById(message.ForecastVersionId);
          if (fv) {
            this.forecastVariableService.fetchById(message.ForecastVersionId, message.ObjectId);
          }
        }
        return notification;

      case NotificationTypes['removed-forecast-version']:
        message.title = `**${message.ActorClientName}** removed forecast version **${message.ForecastName}** from project **${message.ProjectName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-stats-bars';
        if (isNew) {
          if (this.navService.isOnForecast(message.ForecastId)) {
            this.forecastService.fetchForecast(message.ForecastId)
              .then(f => {
                this.forecastService.fetchForecastVersion(f.getLatestVersion().ForecastVersionId)
                  .then(() => {
                    const vers = f.getLatestVersion();
                    const proj = this.projectService.projectById(f.ProjectId);
                    const comp = this.companyService.getCompany(proj.CompanyId);
                    this.store.dispatch(new ForecastModuleReloadAction(vers, comp, proj));
                  });
              });
          } else {
            this.store.dispatch(new SetForecastAsNotFetchedAction(message.ForecastId));
          }
        }
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return notification;


      /*****************
       * FORECAST RESULT
       *****************/
      case NotificationTypes['new-forecast-result']:
        message.title = `**${message.ActorClientName}** added a new forecast result in forecast **${message.ForecastName}** in project **${message.ProjectName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-stats-bars';
        if (isNew) { this.sourceVariableService.fetchAllInCompany(message.CompanyId); }
        return notification;

      case NotificationTypes['new-nowcast-result']:
        message.title = `**${message.ActorClientName}** added a new nowcast result`;
        message.linkUrl = `/forecast/${message.ObjectId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ObjectId);
        message.icon = 'ion-stats-bars';
        if (isNew) { this.forecastVariableService.syncAllRelatedToNowcast(message.ObjectId); }
        return notification;

      /*****************
       * FORECAST SCENARIO
       *****************/
      case NotificationTypes['new-forecast-scenario']:
        message.title = `**${message.ActorClientName}** created a new scenario in **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-stats-bars';
        if (isNew) { this.scenarioService.fetchAllScenarios(message.ForecastVersionId); }
        return notification;

      case NotificationTypes['updated-forecast-scenario']:
        message.title = `**${message.ActorClientName}** updated **${message.ObjectName}** scenario in **${message.ForecastName}**.`;
        message.linkUrl = `/forecast/${message.ForecastId}/(forecast:overview)`;
        message.canLink = () => this.forecastService.forecastExists(message.ForecastId);
        message.icon = 'ion-stats-bars';
        if (isNew) { this.scenarioService.fetchAllScenarios(message.ForecastVersionId); }
        return notification;

      case NotificationTypes['deleted-forecast-scenario']:
        message.title = `**${message.ActorClientName}** deleted scenario **${message.ObjectName}** in **${message.ForecastName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-stats-bars';
        if (isNew) { this.scenarioService.fetchAllScenarios(message.ForecastVersionId); }
        return notification;

      /*****************
       * COMPANY
       *****************/
      case NotificationTypes['new-company']:
        message.title = `**${message.ActorClientName}** created a new company called **${message.CompanyName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-briefcase';
        if (isNew) { this.companyService.fetchCompany(message.CompanyId); }
        return notification;

      case NotificationTypes['updated-company']:
        message.title = `**${message.ActorClientName}** updated company **${message.CompanyName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-briefcase';
        if (isNew) {
          this.companyService.fetchCompany(message.CompanyId);
          this.projectService.fetchAllProjects(message.CompanyId);
        }
        return notification;

      case NotificationTypes['removed-company']:
        message.title = `**${message.ActorClientName}** removed company **${message.CompanyName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-briefcase';
        if (isNew) { /* ? */ }
        return notification;

      case NotificationTypes['new-company-invite']:
        message.title = `**${message.ActorClientName}** invited **${message.ObjectName}** to company **${message.CompanyName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-briefcase';
        if (isNew) { this.companyService.fetchCompany(message.CompanyId); }
        return notification;

      case NotificationTypes['removed-company-employee']:
        message.title = `**${message.ActorClientName}** removed **${message.ObjectName}** from the company **${message.CompanyName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-briefcase';
        if (isNew) {
          this.projectService.purgeMemberFromProjects(message.CompanyId, message.ObjectName);
          this.companyService.purgeMemberFromCompany(message.CompanyId, message.ObjectName);
        }
        return notification;

      /*****************
       * PROJECT
       *****************/
      case NotificationTypes['removed-project-member']:
        message.title = `**${message.ActorClientName}** removed **${message.ObjectName}** from project **${message.ProjectName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-android-folder';
        if (isNew) {
          this.projectService.syncMembers(message.CompanyId, message.ProjectId);
          this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        }
        return notification;

      case NotificationTypes['removed-project']:
        message.title = `**${message.ActorClientName}** removed project **${message.ProjectName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-android-folder';
        if (isNew) {
          this.store.dispatch(new RemoveProjectSuccessAction(message.ProjectId));
          this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        }
        return notification;

      case NotificationTypes['updated-project']:
        message.title = `**${message.ActorClientName}** updated project **${message.ProjectName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-android-folder';
        if (isNew) {
          this.projectService.fetchProject(message.CompanyId, message.ProjectId).then(() => {
            this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
          });
        }
        return notification;

      case NotificationTypes['new-project']:
        message.title = `**${message.ActorClientName}** created a new project in **${message.CompanyName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-android-folder';
        if (isNew) {
          this.projectService.fetchAllProjects(message.CompanyId).then(() => {
            this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
          });
        }
        return notification;

      case NotificationTypes['new-project-invite']:
        message.title = `
          **${message.ActorClientName}** invited
          **${message.ObjectName === this.myEmail ? 'you' : message.ObjectName}**
          to project **${message.ProjectName}**.`;
        message.linkUrl = null;
        message.canLink = () => false;
        message.icon = 'ion-android-folder';
        if (isNew) {
          this.projectService.fetchAllProjects(message.CompanyId).then(() => {
            this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
          });
        }
        return notification;

      case NotificationTypes['member-left-project']:
        message.title = `**${message.ActorClientName}** left project **${message.ProjectName}**.`;
        message.linkUrl = null;
        message.icon = 'ion-android-folder';
        if (isNew) { this.projectService.syncMembers(message.CompanyId, message.ProjectId); }
        return notification;

      /*****************
       * USERS
       *****************/

      case NotificationTypes['new-user']:
        message.title = `**${message.ActorClientName}** joined your company.`;
        if (this.profileService.profile.ClientId === message.ObjectId) {
          message.title = 'Welcome to Indicio!';
        }
        message.linkUrl = null;
        message.icon = 'ion-person';
        if (isNew) { this.companyService.syncMembers(message.CompanyId); }
        return notification;

      /*****************
       * NEWS
       *****************/

      case NotificationTypes['new-news-post']:
        message.title = `Update from Indicio: **${message.ObjectName}**.`;
        message.linkUrl = `/home/news/${message.ObjectId}`;
        message.icon = 'ion-android-hangout';
        if (isNew && this.router.url === '/home') {
          this.newsService.reFetchNewsPosts();
        }
        return notification;

      case NotificationTypes['remove-news-post']:
        message.title = `Update from Indicio: **${message.ObjectName}**.`;
        message.linkUrl = `/home/news/${message.ObjectId}`;
        message.icon = 'ion-android-hangout';
        notification.hidden = true;
        if (isNew && this.router.url === '/home') {
          const post = this.newsService.getNewsPostById(message.ObjectId);
          if (post) {
            this.store.dispatch(new RemoveNewsPostSuccessAction(post));
          }
        }
        return notification;

      case NotificationTypes['new-news-reply']:
        message.title = `**${message.ObjectName}** replied to your comment.`;
        message.linkUrl = `/home/news/${message.ObjectId}`;
        message.icon = 'ion-reply';
        if (isNew && this.router.url === '/home') {
          this.newsService.reFetchNewsPosts();
        }
        return notification;

      default:
        console.error('Missing notification for', message.Type);
        return '';
    }
  }
}
