<indicio-dialog-base>
  <h1 mat-dialog-title
      style="text-align:center;">{{onEditConfig ? 'Edit the' : 'Create a'}} configuration for a SQL database connection
  </h1>
  <div mat-dialog-content>
    <ng-container *ngIf="!loading; else loader">
      <div>
        <div class="container">
          <indicio-stepped-progress [steps]="['Select meta data', 'Select observations', 'Create navigation tree', 'Include additional meta data']"
                                    [step]="currentStep"
                                    (stepChangeEvent)="currentStep = $event"></indicio-stepped-progress>
          <div style="width: 640px; margin: 10px auto 0 auto">
            <ng-container [ngSwitch]="currentStep">
              <ng-container *ngSwitchCase="0">
                <ng-container *ngTemplateOutlet="metaTableTemplate"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="1">
                <ng-container *ngTemplateOutlet="observationsTemplate">
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <ng-container *ngTemplateOutlet="navTreeTemplate">
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="3">
                <ng-container *ngTemplateOutlet="metaDataTemplate">
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

    </ng-container>
  </div>

  <div mat-dialog-actions
       style="padding-left: 15px;
       padding-right: 15px;">
    <button mat-stroked-button
            (click)="currentStep === 0 || loading ? onNoClick() : currentStep = currentStep - 1">
      <span *ngIf="currentStep === 0 || loading"
            style="font-weight: 400;">Cancel</span>
      <span *ngIf="currentStep > 0 && !loading">Back</span>
    </button>
    <ng-container *ngIf="getInputWarnings(); let warnings">
      <fa-icon *ngIf="warnings.length"
               [indicioTippy]="warnings.join('<br>')"
               class="orange"
               [icon]="faTriangle"
               style="font-size:18px;">
      </fa-icon>
    </ng-container>
    <div [indicioTippy]="!inputIsOk() && currentStepHasMessages() ? {maxWidth: 600,
      content:
      currentStep === 0 ?  metaTableMessages.join('<br>') :
      currentStep === 1 ? observationMessages.join('<br>') :
      currentStep === 2 ? categoryMessages.join('<br>') :
      getAllWarningMessages().join('<br>')
    } : null">
      <button color="primary"
              mat-flat-button
              [disabled]="(!inputIsOk() && currentStep === 3) || loading"
              (click)="currentStep < 3 ? currentStep = currentStep + 1 : configure()">
        <span *ngIf="currentStep === 3">{{onEditConfig ? 'Re-configure' : 'Configure'}}</span>
        <span *ngIf="currentStep < 3">Next</span>
      </button>
    </div>
  </div>
</indicio-dialog-base>

<ng-template #metaTableTemplate>
  <indicio-modal-option-row optionTitle="Meta table"
                            optionSubtitle="This is the table in the database that contains the information for each variable.
                                            Must contain the name and identifier for all variables."
                            textWidth=6
                            optionsWidth=6>
    <div class="row"
         style="margin-top: 21px;"></div>
    <mat-form-field>
      <input required
             [ngModel]="selectedTableMeta"
             (ngModelChange)="selectMetaTable($event)"
             type="text"
             autocomplete="nope"
             placeholder="Select meta table"
             class="material"
             matInput
             [ngModelOptions]="{standalone: true}"
             [matAutocomplete]="fTableMeta">
      <div mat-button
           *ngIf="selectedTableMeta"
           matSuffix
           mat-icon-button
           aria-label="Clear"
           (click)="resetMetaConfigSettings()">
        <mat-icon><i class="ion-close"></i></mat-icon>
      </div>

      <mat-autocomplete #fTableMeta="matAutocomplete">
        <mat-option *ngFor="let option of filteredMetaTables"
                    [title]="option.Name"
                    [value]="option.Name">{{option.Name}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </indicio-modal-option-row>
  <indicio-modal-option-row optionTitle="Identifier column"
                            optionSubtitle="This column contains the unique ID assigned to each variable in the database."
                            textWidth=6
                            optionsWidth=6>
    <div class="row"
         style="margin-top: 21px;"></div>
    <mat-form-field>
      <input required
             [(ngModel)]="selectedMetaIdColumn"
             (ngModelChange)="filteredMetaIdColumns = filterMetaIdColumns(selectedMetaIdColumn)"
             type="text"
             autocomplete="nope"
             placeholder="Select identifier column"
             class="material"
             matInput
             [ngModelOptions]="{standalone: true}"
             [matAutocomplete]="fIdField">
      <div mat-button
           *ngIf="selectedMetaIdColumn"
           matSuffix
           mat-icon-button
           aria-label="Clear"
           (click)="selectedMetaIdColumn=null; filteredMetaIdColumns = selectableMetaColumns">
        <mat-icon><i class="ion-close"></i></mat-icon>
      </div>

      <mat-autocomplete #fIdField="matAutocomplete">
        <mat-option *ngFor="let option of filteredMetaIdColumns"
                    [title]="option.Name"
                    [value]="option.Name">{{option.Name}} ({{getLanguageSupportForType(option.Type)}})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </indicio-modal-option-row>
  <indicio-modal-option-row optionTitle="Name column"
                            optionSubtitle="This column contain the names of the variables in the database."
                            textWidth=6
                            optionsWidth=6>
    <div class="row"
         style="margin-top: 13px"></div>
    <mat-form-field>
      <input required
             [(ngModel)]="selectedNameColumn"
             (ngModelChange)="filteredMetaNameColumns = filterMetaNameColumns(selectedNameColumn)"
             type="text"
             autocomplete="nope"
             placeholder="Select name column"
             class="material"
             matInput
             [ngModelOptions]="{standalone: true}"
             [matAutocomplete]="fNameField">
      <div mat-button
           *ngIf="selectedNameColumn"
           matSuffix
           mat-icon-button
           aria-label="Clear"
           (click)="selectedNameColumn=null; filteredMetaNameColumns = selectableMetaColumns">
        <mat-icon><i class="ion-close"></i></mat-icon>
      </div>

      <mat-autocomplete #fNameField="matAutocomplete">
        <mat-option *ngFor="let option of filteredMetaNameColumns"
                    [title]="option.Name"
                    [value]="option.Name">{{option.Name}} ({{getLanguageSupportForType(option.Type)}})</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #observationsTemplate>
  <indicio-modal-option-row optionTitle="Observation table"
                            optionSubtitle="This contains all the observations associated with the variables that were previously selected in the meta table."
                            textWidth=6
                            optionsWidth=6>
    <div class="row"
         style="margin-top: 13px"></div>
    <mat-form-field>
      <input required
             [(ngModel)]="selectedObservationTable"
             (ngModelChange)="filteredObservationTables = filterObservationTables(selectedObservationTable); addSelectableObservationColumns(selectedObservationTable);"
             type="text"
             autocomplete="nope"
             placeholder="Select observation table"
             class="material"
             matInput
             [ngModelOptions]="{standalone: true}"
             [matAutocomplete]="fObservationTable">
      <div mat-button
           *ngIf="selectedObservationTable"
           matSuffix
           mat-icon-button
           aria-label="Clear"
           (click)="selectedObservationTable=null; selectedObservationIdColumn=null; selectedObservationValueColumn = null; selectedObservationDateColumn = null; filteredObservationTables = tables;
filteredObservationIdColumns = []; filteredObservationDateColumns = []; filteredObservationValueColumns = [];">
        <mat-icon><i class="ion-close"></i></mat-icon>
      </div>

      <mat-autocomplete #fObservationTable="matAutocomplete">
        <mat-option *ngFor="let option of filteredObservationTables"
                    [title]="option.Name"
                    [value]="option.Name">{{option.Name}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </indicio-modal-option-row>
  <indicio-modal-option-row optionTitle="Meta identifier column"
                            optionSubtitle="This contains all the identifiers associated with the variables that were previously selected in the meta table."
                            textWidth=6
                            optionsWidth=6>
    <div class="row"
         style="margin-top: 13px"></div>
    <mat-form-field>
      <input required
             [(ngModel)]="selectedObservationIdColumn"
             (ngModelChange)="filteredObservationIdColumns = filterObservationIdColumns(selectedObservationIdColumn)"
             type="text"
             autocomplete="nope"
             placeholder="Select identifier column"
             class="material"
             matInput
             [ngModelOptions]="{standalone: true}"
             [matAutocomplete]="fIdObservation">
      <div mat-button
           *ngIf="selectedObservationIdColumn"
           matSuffix
           mat-icon-button
           aria-label="Clear"
           (click)="selectedObservationIdColumn=null; filteredObservationIdColumns = selectableObservationColumns">
        <mat-icon><i class="ion-close"></i></mat-icon>
      </div>

      <mat-autocomplete #fIdObservation="matAutocomplete">
        <mat-option *ngFor="let option of filteredObservationIdColumns"
                    [title]="option.Name"
                    [value]="option.Name">{{option.Name}} ({{getLanguageSupportForType(option.Type)}})</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </indicio-modal-option-row>
  <indicio-modal-option-row optionTitle="Date column"
                            optionSubtitle="This contains the date information for each observation."
                            textWidth=6
                            optionsWidth=6>
    <div class="row"
         style="margin-top: 13px"></div>
    <mat-form-field>
      <input required
             [(ngModel)]="selectedObservationDateColumn"
             (ngModelChange)="filteredObservationDateColumns = filterObservationDateColumns(selectedObservationDateColumn)"
             type="text"
             autocomplete="nope"
             placeholder="Select date column"
             class="material"
             matInput
             [ngModelOptions]="{standalone: true}"
             [matAutocomplete]="fDateColumn">
      <div mat-button
           *ngIf="selectedObservationDateColumn"
           matSuffix
           mat-icon-button
           aria-label="Clear"
           (click)="selectedObservationDateColumn=null; filteredObservationDateColumns = filterObservationDateColumns('')">
        <mat-icon><i class="ion-close"></i></mat-icon>
      </div>

      <mat-autocomplete #fDateColumn="matAutocomplete">
        <mat-option *ngFor="let option of filteredObservationDateColumns"
                    [title]="option.Name"
                    [value]="option.Name">{{option.Name}} ({{getLanguageSupportForType(option.Type)}})</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </indicio-modal-option-row>
  <indicio-modal-option-row optionTitle="Value column"
                            optionSubtitle="This contains the numerical value for each observation."
                            textWidth=6
                            optionsWidth=6>
    <div class="row"
         style="margin-top: 13px"></div>
    <mat-form-field>
      <input required
             [(ngModel)]="selectedObservationValueColumn"
             (ngModelChange)="filteredObservationValueColumns = filterObservationValueColumns(selectedObservationValueColumn)"
             type="text"
             autocomplete="nope"
             placeholder="Select value column"
             class="material"
             matInput
             [ngModelOptions]="{standalone: true}"
             [matAutocomplete]="fValueColumn">
      <div mat-button
           *ngIf="selectedObservationValueColumn"
           matSuffix
           mat-icon-button
           aria-label="Clear"
           (click)="selectedObservationValueColumn=null; filteredObservationValueColumns = filterObservationValueColumns('')">
        <mat-icon><i class="ion-close"></i></mat-icon>
      </div>

      <mat-autocomplete #fValueColumn="matAutocomplete">
        <mat-option *ngFor="let option of filteredObservationValueColumns"
                    [title]="option.Name"
                    [value]="option.Name">{{option.Name}} ({{getLanguageSupportForType(option.Type)}})</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #navTreeTemplate>
  <indicio-modal-option-row optionTitle="Tree nodes"
                            optionSubtitle="You can create a navigation tree based on information in the selected meta table.
                                            <br><br>
                                            This allows you to organize the variables in a hierarchical manner, making it easier to find and view the information you need."
                            textWidth=6
                            optionsWidth=6>
    <div class="tree-node"
         style="margin: 22px 0 20px 0;">
      <span class="pointer"
            [ngClass]="{disabled: addedCategories.length >= 5}"
            [indicioTippy]="addedCategories.length >= 5 ? 'Cannot add more than five tree nodes' : null "
            (click)="addNewCategory = !addNewCategory"> <i class="ion-plus green"
           [ngClass]="{disabled: addedCategories.length >= 5}"
           style="margin-right: 7px;"></i> Add node</span>
    </div>

    <div cdkDropList
         (cdkDropListDropped)="drop($event)"
         class="drag-list">
      <ng-container *ngFor="let node of addedCategories; let index = index">
        <div class="drag-box"
             [cdkDragDisabled]="false"
             cdkDrag>
          <div class="row vertical-align drag-container">
            <div class="drag-placeholder"
                 *cdkDragPlaceholder></div>
            <div class="col-xs-1 vertical-align">
              <div>{{index + 1}}.</div>
            </div>
            <div class="col-xs-7">
              <span class="drag-container-text">{{node.name}}</span>
            </div>
            <div class="col-xs-4">
              <div class="row">
                <div class="col-xs-4"></div>
                <div class="col-xs-4">
                  <i class="ion-trash-b pointer"
                     style="color:#E26464;"
                     (click)="removeCategoryNode(node);"></i>
                </div>
                <div class="col-xs-4">
                  <div class="drag-handle"
                       *ngIf="addedCategories.length > 1"
                       cdkDragHandle>
                    <svg width="18px"
                         fill="#ccc"
                         viewBox="0 0 24 24">
                      <path
                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                      </path>
                      <path d="M0 0h24v24H0z"
                            fill="none"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container>
      <ng-container *ngTemplateOutlet="addCategoryTemplate; context: { i: null, node: null }"></ng-container>
    </ng-container>

  </indicio-modal-option-row>
</ng-template>

<ng-template #metaDataTemplate>
  <indicio-modal-option-row optionTitle="Meta data (optional)"
                            optionSubtitle="This can include additional information about the variables that have been selected.
                                            <br><br>
                                            The selected information will be available when viewing the variables."
                            textWidth=6
                            optionsWidth=6>
    <div class="tree-node"
         style="margin: 22px 0 20px 0;">
      <span class="pointer"
            [ngClass]="{disabled: addedMetaValuesVariable.length >= 5}"
            [indicioTippy]="addedMetaValuesVariable.length >= 5 ? 'Cannot add more than five meta columns' : null "
            (click)="addNewMetaValue = !addNewMetaValue"> <i class="ion-plus green"
           [ngClass]="{disabled: addedMetaValuesVariable.length >= 5}"
           style="margin-right: 7px;"></i> Add meta data</span>
    </div>

    <div cdkDropList
         (cdkDropListDropped)="dropMetaNode($event)"
         class="drag-list">
      <ng-container *ngFor="let node of addedMetaValuesVariable; let index = index">
        <div class="drag-box"
             [cdkDragDisabled]="false"
             cdkDrag>
          <div class="row vertical-align drag-container">
            <div class="drag-placeholder"
                 *cdkDragPlaceholder></div>
            <div class="col-xs-1 vertical-align">
              <div>{{index + 1}}.</div>
            </div>
            <div class="col-xs-7">
              <span class="drag-container-text">{{node.name}}</span>
            </div>
            <div class="col-xs-4">
              <div class="row">
                <div class="col-xs-4"></div>
                <div class="col-xs-4">
                  <i class="ion-trash-b pointer"
                     style="color:#E26464;"
                     (click)="removeMetaNode(node);"></i>
                </div>
                <div class="col-xs-4">
                  <div class="drag-handle"
                       *ngIf="addedMetaValuesVariable.length > 1"
                       cdkDragHandle>
                    <svg width="18px"
                         fill="#ccc"
                         viewBox="0 0 24 24">
                      <path
                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                      </path>
                      <path d="M0 0h24v24H0z"
                            fill="none"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="addMetaValueTemplate; context: { i: null, node: null }"></ng-container>
  </indicio-modal-option-row>
</ng-template>

<ng-template #addCategoryTemplate
             let-i="i"
             let-node="node">
  <div class="tree-node">
    <ng-container *ngIf="addNewCategory">
      <mat-form-field>
        <input required
               [(ngModel)]="selectedCategory"
               (ngModelChange)="addCategory(selectedCategory, node)"
               type="text"
               autocomplete="nope"
               placeholder="Select data"
               class="material"
               matInput
               [ngModelOptions]="{standalone: true}"
               [matAutocomplete]="fCategory">
        <mat-autocomplete #fCategory="matAutocomplete">
          <mat-option *ngFor="let option of filteredCategories"
                      [title]="option.Name"
                      (click)="addCategory(option.Name, node, true)"
                      [value]="option.Name">{{option.Name}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #addMetaValueTemplate
             let-i="i"
             let-node="node">
  <div class="tree-node"
       style="margin-top: 10px;">
    <ng-container *ngIf="addNewMetaValue">
      <mat-form-field>
        <input required
               [(ngModel)]="selectedMetaValue"
               (ngModelChange)="addMetaFields(selectedMetaValue, node, false);"
               type="text"
               autocomplete="nope"
               placeholder="Select meta data"
               class="material"
               matInput
               [ngModelOptions]="{standalone: true}"
               [matAutocomplete]="fCategory">
        <mat-autocomplete #fCategory="matAutocomplete">
          <mat-option *ngFor="let option of filteredMetaValuesVariable"
                      [title]="option.Name"
                      [value]="option.Name">{{option.Name}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #loader>
  <div style="text-align:center;display: inline;"
       class="vertical-align row">
    <div>
      <i class="ion-load-c spinner"></i> Loading... Please wait.
    </div>
  </div>
</ng-template>
