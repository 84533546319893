import { Component, Inject } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DisplayValue } from '@modules/lang/types/display-value';

export interface GenericSearchableDropdownDialogData {
  Title: string;
  Ingress?: string;
  Label: string;
  Options: DisplayValue[];
  ConfirmButtonText?: string;
  CancelButtonText?: string;
  CloseAndSaveOnSelection?: string;
}

@Component({
  selector: 'indicio-generic-searchable-dropdown-dialog',
  templateUrl: 'generic-searchable-dropdown.dialog.html',
})
export class GenericSearchableDropdownDialogComponent {
  public static Id: string = 'GenericSearchableDropdownDialogComponent';

  public chosenOption: DisplayValue;
  public options: DisplayValue[];
  public filteredOptions: DisplayValue[];
  public searchString: string;

  constructor(
    public dialogRef: MatDialogRef<GenericSearchableDropdownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericSearchableDropdownDialogData) {
    this.options = data.Options;
    this.filteredOptions = this.filterOptions();
  }

  getOptionText(val?: DisplayValue) { return val?.Display; };

  filterOptions(val?: string | DisplayValue): DisplayValue[] {
    const vv = typeof val === 'string' ? val : val?.Display;
    if (vv) {
      const filterValue = vv.toLowerCase();
      return this.options.slice().filter(entity => entity.Display.toLowerCase().startsWith(filterValue));
    }
    return this.options.slice();
  }

  resetSearch() {
    this.searchString = null;
    this.filteredOptions = this.filterOptions();
    this.chosenOption = null;
  }

  selectOption($event: MatOptionSelectionChange<DisplayValue>) {
    if ($event.source.value.Value === this.data.CloseAndSaveOnSelection) {
      return this.save($event.source.value);
    }
    this.chosenOption = $event.source.value;
  }


  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save(val?: DisplayValue) {
    this.dialogRef.close(val || this.chosenOption);
  }
}
