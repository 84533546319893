import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthBackendService } from '@core/store/auth/auth.backend.service';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OrderByPipe } from '@shared/modules/pipes';
import { CreateMultiFVarModalOpts } from './create-forecast-variable-multi-modal.options';


@Component({
  selector: 'indicio-import-forecast-variable-multi-modal',
  templateUrl: './create-forecast-variable-multi-modal.component.html',
  styleUrls: ['./create-forecast-variable-multi-modal.component.less'],
})
export class CreateForecastVariableMultiModalComponent extends ModalModelComponent {
  faSave = faSave as IconProp;

  email: string = null;
  inProgress = false;

  forecast: ForecastModel;
  forecastVersion: ForecastVersionModel;
  variables: SourceVariableModel[] = [];
  chosenVersions: string[];

  forecastVariables: ForecastVariableModel[] = [];
  openNameEdits: string[] = [];
  isNewNamePending: boolean;
  mainVariable: ForecastVariableModel;
  nameChangeNeeded: string[] = [];
  usedAlready = [];
  versionsAvailable: boolean;
  importedCount = 0;

  extraErrors: string = null;

  public mainVariableExists: boolean;

  public get unusedVariables() { return this.variables.filter(z => !this.usedAlready.includes(z.SourceVariableId)); }
  public get toImport() {
    return [...this.forecastVariables].filter(x => !this.usedAlready.includes(x.SourceVariableId));
  }

  constructor(
    protected store: Store,
    public statusService: StatusService,
    public authService: AuthBackendService,
    private forecastService: ForecastFrontendService,
    private forecastVarService: ForecastVariableFrontendService,
    public envService: EnvironmentService,
    private sourceVarService: SourceVariableFrontendService,
    private status: StatusService,
    private orderPipe: OrderByPipe,
    private forecastVariableMapper: ForecastVariableMapper
  ) {
    super();
  }

  async setOptions(options: CreateMultiFVarModalOpts) {
    this.extraErrors = options && options.errors && options.errors['message'] ? options.errors['message'] : null;
    this.forecastVersion = options.forecastVersion;
    this.chosenVersions = options.versionIds;

    this.mainVariableExists = !!this.forecastVersion.ForecastVariable;
    this.forecast = await this.forecastService.getOrFetchForecast(this.forecastVersion.ForecastId);

    this.onClose = function () {
      if (options.onClose) {
        setTimeout(function () { options.onClose.call(self, arguments); }, 50);
      }
    };

    this.sourceVarService.getOrFetchSourceVariablesInBulk(options.variableIds)
      .then((variables) => {
        this.setup(variables);
      });
  }

  public nameChangeNeededForImport() {
    return this.toImport.some(x => this.nameChangeNeeded.includes(x.SourceVariableId));
  }

  public removeFromNowcastVariables(array: SourceVariableModel[]) {
    return array.map(e => {
      e.Versions = e.Versions.filter(x => !x.FromNowcast);
      return e;
    });
  }

  public async import() {
    this.pending = true;
    const toImport = this.toImport;
    const count = toImport.length;
    const importFunc = async (variable) => {
      await this.importForecastVariable(variable);
    };

    let current = 0;

    const queueFunc = async () => {
      if (current >= count) {
        this.pending = false;
        return;
      }
      await importFunc(toImport[current]);
      current += 1;
      setTimeout(async () => {
        await queueFunc();
      }, 20);
    };

    if (!this.mainVariableExists) {
      const idx = toImport.findIndex(x => x.SourceVariableId === this.mainVariable.SourceVariableId);
      toImport.splice(idx, 1);
      await this.importForecastVariable(this.mainVariable);
    }

    await queueFunc();
  }

  private async importForecastVariable(variable: ForecastVariableModel) {
    if (!variable) { return; }
    variable.status = 'Importing';
    return this.forecastVarService.createForecastVariable(variable)
      .then(fvar => {
        if (fvar.ForecastVariableValues.length < 30) {
          variable.status = 'Warning';
          variable.warningMsg = 'Value count is low';
        } else {
          variable.status = 'Imported';
        }
      })
      .catch(err => {
        variable.status = 'Failed';
        const errs = this.status.getMessage(err);
        variable.failedMsg = errs.message;
        if (errs.errors?.length) {
          variable.failedMsg += '\n' + errs.errors.join('\n');
        }

      }).finally(() => this.importedCount += 1);
  }


  private setup(variables?: SourceVariableModel[]) {
    this.variables = this.orderPipe.transform(variables, 'Name', variables.length, false);
    this.variables = this.removeFromNowcastVariables(this.variables);
    this.variables.forEach((variable, index) => {
      let version = variable.getBaseVersion();

      const inForecast = this.forecastVersion.getAllVariables().map(x => x.SourceVariableId);

      if (inForecast.includes(variable.SourceVariableId)) {
        this.usedAlready.push(variable.SourceVariableId);
      }

      if (this.forecastVersion.fVarNameExists(variable.Name)) {
        this.nameChangeNeeded.push(variable.SourceVariableId);
      }

      if (variable.Versions.length > 1 && !this.usedAlready.includes(variable.SourceVariableId)) {
        this.versionsAvailable = true;
      }

      if (this.chosenVersions) {
        let meta = variable.Versions.find(x => x.SourceVariableMetaId === this.chosenVersions[index]);
        if (!meta) {
          meta = variable.OldVersions.find(x => x.SourceVariableMetaId === this.chosenVersions[index]);
        }
        version = meta;
      }

      if (!!variable._tmpAggregationMethod) {
        version.AggregationMethodId = variable._tmpAggregationMethod;
      }

      const fV = this.forecastVariableMapper.mapFromSourceVariableModel(variable, this.forecastVersion.ForecastVersionId);
      this.forecastVariables.push(fV);
    });

    if (!this.mainVariableExists) {
      this.mainVariable = this.forecastVariables.find(v => this.usedAlready.findIndex(x => x === v.SourceVariableId) === -1);
    }

    this.isLoading = false;
  }

  public getNameChangeNeeded(variable: ForecastVariableModel) {
    return this.nameChangeNeeded.includes(variable.SourceVariableId);
  }

  public toggleNameChangeNeeded(variable: ForecastVariableModel) {
    if (this.forecastVersion.fVarNameExists(variable.Name)) {
      this.nameChangeNeeded.push(variable.SourceVariableId);
    } else {
      this.nameChangeNeeded.removeWhere(x => x === variable.SourceVariableId);
    }
  }

  public getAggregationMethods(variable: SourceVariableModel) {
    return this.envService.getValidAggregationMethods(variable, this.forecastVersion.Periodicity);
  }

  public toggleNameEdits(variable: ForecastVariableModel) {
    this.openNameEdits.togglePresence(variable.SourceVariableId);
    this.toggleNameChangeNeeded(variable);
  }

  public getForecastVariable(variable: SourceVariableModel) {
    return this.forecastVariables.find(x => x.SourceVariableId === variable.SourceVariableId);
  }

  public setVersion(variable: SourceVariableModel, metaid: MatSelectChange) {
    const fVar = this.getForecastVariable(variable);
    const version = variable.Versions.find(x => x.SourceVariableMetaId === metaid.value);
    fVar.AggregationMethodId = version.AggregationMethodId;
    fVar.FirstDate = version.FirstDate;
    fVar.LastDate = version.LastDate;
  }

  public isNameEditOpen(variable: ForecastVariableModel) {
    return this.openNameEdits.indexOf(variable.SourceVariableId) > -1;
  }

  public removeVariable(variable: SourceVariableModel) {
    const vIdx = this.variables.findIndex(v => v.SourceVariableId === variable.SourceVariableId);
    const fIdx = this.forecastVariables.findIndex(v => v.SourceVariableId === variable.SourceVariableId);
    this.variables.splice(vIdx, 1);
    this.nameChangeNeeded.removeWhere(x => x === variable.SourceVariableId);
    this.openNameEdits.removeWhere(x => x === variable.SourceVariableId);
    this.forecastVariables.splice(fIdx, 1);
  }

  public nameWidth() {
    if (this.mainVariableExists && !this.versionsAvailable) {
      return 290;
    } else if ((!this.mainVariableExists && !this.versionsAvailable) || (this.mainVariableExists && this.versionsAvailable)) {
      return 205;
    } else if (!this.mainVariableExists && this.versionsAvailable) {
      return 132;
    }
  }

  public aggWidth() {
    if (this.mainVariableExists && !this.versionsAvailable) {
      return 190;
    } else if ((!this.mainVariableExists && !this.versionsAvailable) || (this.mainVariableExists && this.versionsAvailable)) {
      return 135;
    } else if (!this.mainVariableExists && this.versionsAvailable) {
      return 100;
    }
  }
}


