<indicio-dialog-base class="alg-date-info">
  <h1 mat-dialog-title
      style="text-align: center">SHapley Additive exPlanations (SHAP)</h1>

  <div mat-dialog-content>
    <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
  </div>

  <div mat-dialog-actions>
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </div>
</indicio-dialog-base>

<ng-template #optionsTemplate>
  <div class="row"
       style="padding: 0 15px;">
    <div class="col-xs-6 text-center">
      <mat-form-field>
        <mat-select [value]="currentDate"
                    placeholder="Date"
                    (selectionChange)="changeActiveDate($event.value)"
                    required>
          <mat-option [value]="p"
                      [title]="p"
                      *ngFor="let p of data.Dates">{{ p | dateFormat: data.Periodicity }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xs-6 text-center">
      <mat-form-field>
        <mat-select [(value)]="currentModelName"
                    placeholder="Model"
                    (selectionChange)="changeActiveModel()"
                    required>
          <mat-option [value]="p"
                      [title]="p.Description"
                      *ngFor="let p of availableModelNames">{{ p.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #mainContentTemplate>
  <div class="row extra-margin-top">
    <div class="col-xs-12 text-center">
      Prediction: {{ modelCurrentValue }}
    </div>
  </div>
  <div class="row margin-top">
    <div class="col-xs-12 text-center">
      <ng-container *ngTemplateOutlet="shapGraphTemplate"></ng-container>
    </div>
  </div>
  <div class="row margin-top"
       style="padding: 0 15px;">
    <div class="col-xs-12">
      Shapley additive explanations (SHAP) shows the contribution of each variable to a specific forecast point.
      A positive SHAP value indicates that the predicted value increased due to the variable, while a negative SHAP
      value implies a decrease.<br><br>
      The sum of all the SHAP values equals the difference between the prediction ({{ modelCurrentValue }}) and the
      previous value ({{ modelPreviousValue }}).
    </div>
  </div>
</ng-template>

<ng-template #shapTableTemplate>
  <div class="row">
    <div class="col-xs-12 text-center margin-bottom-10">
      {{ shapTable.Title }}
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12"
         style="font-size: 11px;">
      <indicio-data-table [columnTemplate]="shapTable.ColumnConfig">
        <div title-row>
          <div *ngFor="let title of shapTable.Columns; let titleIdx = index"
               column
               [attr.left]="shapTable.TitleColumn === titleIdx ? true : null"
               class="crop-string">
            <span title>
              {{ title }}
            </span>
          </div>
        </div>
        <div class="body-scroll">
          <div row
               *ngFor="let row of shapTable.Rows; let rowIdx = index"
               [attr.data-row]="'row' + rowIdx">
            <div column
                 class="crop-string"
                 *ngFor="let column of row; let rowColumnIdx = index"
                 [title]="column || 'N/A'"
                 [ngClass]="{ 'highlighted': rowColumnIdx === shapTable.HighlightedColumn }"
                 [attr.left]="shapTable.TitleColumn === rowColumnIdx ? true : null"
                 [attr.center]="shapTable.TitleColumn !== rowColumnIdx ? true : null">
              <span>{{ column || 'N/A' }}</span>
            </div>
          </div>
        </div>
      </indicio-data-table>
    </div>
  </div>
</ng-template>

<ng-template #shapGraphTemplate>
  <indicio-shap-graph *ngIf="!!graphData.data?.length; else noShapAvailableTemplate"
                      [data]="graphData"></indicio-shap-graph>

  <ng-template #noShapAvailableTemplate>
    <div style="background-color: rgb(222 192 101); padding: 7px; margin: 20px 0; color: black">
      No SHAP data available. Please turn on SHAP in the <span class="dotted-underline pointer"
            (click)="openForecastSettings()">forecast settings</span>
      and/or recalculate the results.
    </div>
  </ng-template>
</ng-template>

<ng-template #buttonTemplate>
  <div></div>
  <button mat-stroked-button
          (click)="onNoClick()">
    Close
  </button>
</ng-template>
