import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { VariableOutlierSettingsDTO } from '@core/store/forecast-variable/dtos/variable-outlier-settings-dto';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { OutlierDetectionType, OutlierModelType, OutlierStrategyType } from '@modules/lang/language-files/outliers';
import { FVarDialogViewIndex, ForecastVariableInfoDialogData } from '../../forecast-variable-info.dialog';

@Component({
  selector: 'indicio-fvar-info-dialog-outliers-tab',
  templateUrl: './fvar-info-tab.outliers.component.html',
  styleUrls: ['./fvar-info-tab.outliers.component.less']
})
export class FVarInfoDialogOutliersTabComponent implements OnChanges {

  @Input() variable: ForecastVariableModel;
  @Input() options: ForecastVariableInfoDialogData;

  @Output() closeDialogEvent = new EventEmitter();

  public pending: boolean;
  public changed: boolean;
  public infopanelOpen: boolean = true;
  public viewEnum = FVarDialogViewIndex;

  public calculateOutliers: boolean;
  public outlierPlots: ResultFileDTO[] = [];
  public newOutlierModel: OutlierModelType;
  public newOutlierStrategy: OutlierStrategyType;
  public selectedOutlierTypes: OutlierDetectionType[] = [];

  constructor(
    public appearance: AppearanceService,
    public envService: EnvironmentService,
    private status: StatusService,
    private fVarService: ForecastVariableFrontendService,
  ) {
  }

  public ngOnChanges() {
    this.setup();
  }

  public setModel(change: MatSelectChange) {
    this.newOutlierModel = change.value;
    this.checkChanged();
  }

  public setStrat(change: MatSelectChange) {
    this.newOutlierStrategy = change.value;
    this.checkChanged();
  }

  public toggleOutlier() {
    this.calculateOutliers = !this.calculateOutliers;
    this.checkChanged();
  }

  public selectType(change: MatOptionSelectionChange) {
    const idx = this.selectedOutlierTypes.findIndex(x => x === change.source.value);
    if (change.source.selected) {
      if (idx === -1) {
        this.selectedOutlierTypes.push(change.source.value);
      }
    } else {
      if (idx !== -1) {
        this.selectedOutlierTypes.splice(idx, 1);
      }
    }
    this.selectedOutlierTypes.sort();
    this.checkChanged();
  }

  public saveSettings() {
    this.pending = true;

    const dto: VariableOutlierSettingsDTO = {
      Active: this.calculateOutliers,
      OutlierModel: this.newOutlierModel,
      OutlierStrategy: this.newOutlierStrategy,
      OutlierTypes: JSON.stringify(this.selectedOutlierTypes)
    };

    this.fVarService.updateVariableOutlierSettings(this.variable, dto)
      .then(res => {
        this.variable = res;
        this.setup();
        this.status.setMessage('Outlier settings updated', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
      })
      .finally(() => this.pending = false);
  }

  public inSetup: boolean = false;
  private setup() {
    this.inSetup = true;
    this.outlierPlots = this.variable.OutlierPlotImages.sort((a, b) => a.sortIndex - b.sortIndex);
    this.calculateOutliers = this.variable.CalculateOutliers;
    this.newOutlierModel = this.variable.OutlierModel;
    this.newOutlierStrategy = this.variable.OutlierStrategy;
    this.selectedOutlierTypes = [...this.variable.OutlierTypes];
    this.selectedOutlierTypes.sort();
    this.checkChanged();
    setTimeout(() => this.inSetup = false, 0);
  }

  private checkChanged() {
    this.changed = this.calculateOutliers !== this.variable.CalculateOutliers ||
      this.newOutlierModel !== this.variable.OutlierModel ||
      this.newOutlierStrategy !== this.variable.OutlierStrategy;
    if (this.changed) { return; }
    this.changed = JSON.stringify(this.selectedOutlierTypes.sort()) !== JSON.stringify(this.variable.OutlierTypes.sort());
  }
}
