
export namespace StringUtils {

  export function getTextWidth(inp: { text: string, style?: string, weight?: string, size: string, family: string; }): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      throw new Error('Failed to get canvas context');
    }

    context.font = `${inp.style} ${inp.weight} ${inp.size} ${inp.family}`;
    const metrics = context.measureText(inp.text);
    return metrics.width;
  }

  export type CropPosition = 'middle' | 'end';

  /**
   * @param s The string to crop (adding elipsis at end or middle of string)
   * @param maxLen The maximum length of the resulting string
   * @param cropPosition Position of elipsis, can be 'end' or 'middle'
   * @returns The (potentially) cropped string
   * @description Note that a maxLen below or equal to 9 doesnt support elipsis.
   */
  export function cropIfNeeded(s: string, maxLen: number, cropPosition: CropPosition = 'end') {
    if (!s) { return ''; }
    if (s?.length <= maxLen) { return s; }
    if (maxLen <= 9) { return s.substring(0, maxLen); }
    switch (cropPosition) {
      case 'end':
        return `${s.slice(0, maxLen - 3)}...`;
      case 'middle':
        let firstPartLength = Math.round(maxLen * 0.5);
        return `${s.substring(0, firstPartLength)}...${s.substring(s.length - firstPartLength + 3)}`;
      default:
        return s;
    }
  }

  export function removeAllButFirst(input: string, char: string) {
    input = input.replace(char, '%indicio-string%');
    input = input.replaceAll(char, '');
    input = input.replace('%indicio-string%', char);
    return input;
  }

  /**
   * Creates a new random string, with a given length
   * @returns
   * @param string
   */
  export function capitalizeFirstLetter(string) {
    if (string == null || string.length === 0) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Escapes a string for use in a regex
   * @param input
   * @returns
   */
  export function escapeRegex(input: string) {
    // List of illegal regex characters
    const illegalChars = /[-\/\\^$*+?.()|[\]{}]/g;
    return input.replace(illegalChars, '\\$&');
  }

  export function b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  export function createId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  export function shuffleString(string: String) {
    const a = string.split(''),
      n = a.length;

    for (let i = n - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const tmp = a[i];
      a[i] = a[j];
      a[j] = tmp;
    }
    return a.join('');
  }

  export function getHash(content: string) {
    let hash = 0, i, chr;
    if (content.length === 0) { return hash; }
    for (i = 0; i < content.length; i++) {
      chr = content.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = ((hash << 5) - hash) + chr;
      // eslint-disable-next-line no-bitwise
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  export function slugify(str: string) {
    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaeeeeiiiioooouuuunc------';

    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  /**
   * Remove the specified set of characters from a string
   * @param string The string from which to remove the supplied characters
   * @param toRemove A string with one or more characters to remove from the string. Defaults to '|&;$%@"<>()+,'
   * @returns The new string with removed characters
   */
  export function stripCharacters(string: string, toRemove: string = '|&;$%@"<>()+,') {
    const regex = new RegExp(`[${toRemove}]`, 'g');
    return string?.replace(regex, '');
  }

  export function isNumeric(str: string) {
    if (typeof str !== 'string') { return false; }
    return !isNaN(parseFloat(str));
  }
}

// https://stackoverflow.com/a/46946490
export function explodeStringBySpace(value: String): string[] {
  return value.match(/\\?.|^$/g).reduce((p: any, c) => {
    if (c === '"') {
      // eslint-disable-next-line no-bitwise
      p.quote ^= 1;
    } else if (!p.quote && c === ' ') {
      p.a.push('');
    } else {
      p.a[p.a.length - 1] += c.replace(/\\(.)/, '$1');
    }
    return p;
  }, { a: [''] }).a;
}

