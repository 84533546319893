import { DisplayValue } from '@modules/lang/types/display-value';

export type ExtendedAccuracyMeasureType = AccuracyMeasureType | 'EQUAL';
export type AccuracyMeasureType = 'RMSE' | 'MAPE' | 'ME' | 'MAE' | 'MPE' | 'HR' | 'MASE' | 'RSQ';
export type AccuracyWeightMethod = 'Stepwise' | 'First' | 'Average';


export const AccuracyDropdownOptions: DisplayValue<AccuracyMeasureType>[] = [
  { Value: 'MAE', Display: 'Mean Absolute Error (MAE)', Description: 'Mean Absolute Error is the mean of the absolute values of the prediction errors.' },
  { Value: 'MAPE', Display: 'Mean Absolute Percentage Error (MAPE)', Description: 'Mean Absolute Percentage Error is the mean of the absolute values of the prediction errors in percentage of the variable values.' },
  { Value: 'ME', Display: 'Margin of Error (ME)', Description: 'Margin of Error is the mean of all prediction errors.' },
  { Value: 'MPE', Display: 'Mean Percentage Error (MPE)', Description: 'Mean Percentage Error is the mean of the prediction errors in percentage of the variable values.' },
  { Value: 'RMSE', Display: 'Root Mean Squared Error (RMSE)', Description: 'Root Mean Squared Error is the square root of the mean of all the squared prediction errors.' },
  { Value: 'HR', Display: 'Hit Ratio (HR)', Description: 'Hit Ratio is the ratio of how often the model was able to predict the right direction of movement of the variable.' },
  { Value: 'MASE', Display: 'Mean absolute scaled error (MASE)', Description: 'Mean absolute scaled error is the mean absolute error of the predictions, divided by the mean absolute error of the in-sample one-step naive forecast.' },
  { Value: 'RSQ', Display: 'R-squared', Description: 'R-squared is the proportion of the variation explained by the prediction.' }
];

export const ExtendedAccuracyDropdownOptions: DisplayValue<ExtendedAccuracyMeasureType>[] = [
  ...AccuracyDropdownOptions,
  { Value: 'EQUAL', Display: 'Equal weight', Description: 'Each model gets an equal weight in the consolidated model.' }
];

export const AccuracyWeightMethodOptions: DisplayValue<AccuracyWeightMethod>[] = [
  { Value: 'Average', Display: 'Average', Description: 'Each model is weighted based on the average accuracy over the full forecast horizon.' },
  { Value: 'First', Display: 'First', Description: 'Each model is weighted based on the accuracy for the first step in the forecast.' },
  { Value: 'Stepwise', Display: 'Stepwise', Description: 'Each model is weighted based on the accuracy per step in the forecast.' },
];


export const AccuracyChartViewDropdownOptions: DisplayValue<EAccuracyChartView>[] = [
  { Value: 0, Display: 'In-sample and Out-of-sample first step' },
  { Value: 1, Display: 'Out-of-sample first and last step' },
  { Value: 2, Display: 'Out-of-sample average' },
  { Value: 3, Display: 'Out-of-sample all steps' },
  { Value: 999, Display: 'In-sample first step' },
];


export enum EAccuracyChartView {
  INSAMPLE_OUTOFSAMPLE_FIRSTSTEP = 0,
  OUTOFSAMPLE_FIRST_LASTSTEP = 1,
  OUTOFSAMPLE_AVERAGE = 2,
  OUTOFSAMPLE_ALLSTEPS = 3,
  INSAMPLE_FIRSTSTEP = 999,
}
