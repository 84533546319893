import { Def } from '@core/decorators/def';

export class CreateAssessmentDTO {
  @Def() TimePoint: string;
  @Def() Reason: string;
  @Def() Source: string;
  @Def() OldValue: number;
  @Def() NewValue: number;
  @Def() Quantity: number;
  @Def() Unit: string;
  @Def() TrustLevel: string;
  @Def() Category: string;
  @Def() Models: string[];
}
