import { PlotValue, PlotValueDTO, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { ShapValueDTO } from '@core/store/stat-model/dtos/stat-model.dto';
import { Periodicity } from '@modules/lang/types/periodicity';
import { getDiffName, getGrowthName } from '@shared/components/line-graph/alg-worker/get-transform-name';
import { ALGLineModel, ALGSingleSeriesModel, AlgModel } from './alg-models/graph-data.model';

export namespace ALGTypes {

  export type LineModelType = 'Scenarios' | 'Reconciliations' | 'Source variable' | 'Forecast result' | 'Benchmarks' | 'Forecast variable' | 'Data vendor' | 'Seasonal adjustment';

  /**
   * inside: Axis is displayed in the graph area
   * floating: Axis is displayed floating to the left of the svg (graph)
   * outside: Axis is displayed to the left of the graph, but is part of the container, pushing the graph to the right
   */
  export type YAxisPosition = 'inside' | 'floating' | 'outside';

  export type Graph = 'multivariate' | 'univariate' | 'scenario' | 'variable' | 'summary' | 'generic';

  export type AlgHoverModelInternal = ALGLineModel | AlgModel;

  export class AlgHoverTextLine {
    Circle: boolean;
    CircleColor?: string;
    Text: string;
    Value?: number;
    ValueStr?: string; // If set, will override any parsing of Value for display purposes
    Margins?: number[];
  }

  export interface MousePos {
    X: number,
    Y: number;
  }

  export interface AlgHoverPoint {
    Model: AlgHoverModelInternal;
    Value: PlotValue;
  }

  export interface AlgShapHoverPoint {
    Model: ALGSingleSeriesModel;
    Shap: ShapValueDTO;
    Value: SimplePlotValue;
  }

  export interface AlgDateInfo {
    MouseValue: number,
    Moment: moment.Moment,
    NewX: number,
    AtLastDate: boolean;
    IsInForecast: boolean;
    ModelPoints: { Model: AlgHoverModelInternal, Value: PlotValueDTO; }[];
    LinePoints: { Model: ALGLineModel, Value: PlotValue; }[];
    FittedPoints: { Model: AlgModel, Value: PlotValue; }[];
    PastPoints: { Color: string, Value: PlotValue, Title: string; }[];
    ShapPoints: AlgShapHoverPoint[];
  }

  export interface PastForecastData {
    Type: number; // 0 = all, else the selected past-forecast-step
    Color?: string;
    Model: ALGSingleSeriesModel,
    PastForecasts: SimplePlotValue[][];
    Segments: ALGTypes.LineSegment[][];
  }

  export function getTransformName(trans: Transform, p: Periodicity) {
    switch (trans) {
      case Transform.original:
      case undefined:
      case null:
        return 'None';
      case Transform.roc:
        return getGrowthName(p);
      case Transform.rocy:
        return 'Growth YOY';
      case Transform.diff:
        return getDiffName(p);
      case Transform.diffy:
        return 'Diff YOY';
      case Transform.rolling12m:
        return 'Rolling 12M Avg';
      case Transform.rolling12mSum:
        return 'Rolling 12M Sum';
      case Transform.rolling4q:
        return 'Rolling 4Q Avg';
      case Transform.rolling4qSum:
        return 'Rolling 4Q Sum';
      case Transform.log:
        return 'Log';
      default:
        return 'Unknown';
    }
  }

  export function getDataName(type: Data) {
    switch (type) {
      case Data.original:
        return 'None';
      case Data.outlier:
        return 'Outlier';
      case Data.seasonal:
        return 'Seasonal';
      case Data.aggregated:
        return 'Aggregated';
      default:
        return 'Unknown';
    }
  }

  export enum Transform {
    original = 'original',
    roc = 'roc',
    rocy = 'rocy',
    diff = 'diff',
    diffy = 'diffy',
    rolling12m = 'rolling12m',
    rolling12mSum = 'rolling12mSum',
    rolling4q = 'rolling4q',
    rolling4qSum = 'rolling4qSum',
    log = 'log'
  }

  export enum Data {
    original = 'original',
    outlier = 'outlier',
    seasonal = 'seasonal',
    aggregated = 'aggregated'
  }

  export const TransformTypesArray = Object.keys(Transform);


  export interface LineSegment {
    isDashed: boolean,
    Values: PlotValue[],
    /* invalidStart: If there are no null-separated values from any previous points, the segment is deeemed to have an invalidStart. */
    invalidStart: boolean;
  }
}
