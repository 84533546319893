<h1 mat-dialog-title
    style="text-align:center;">Two factor Authentication</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-xs-12 text-center">
      <h3>Your company requires you to use two-factor authentication.</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 text-center">
      <h3>You must activate this before: {{ forceFrom | dateFormat: null: appearance.DateFormat }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <indicio-2fa-settings [preventDisabled2FA]="true"
                            [checkStoredAuth]="false"
                            (successEvent)="save();"
                            (skipEvent)="onNoClick()"
                            [useSkip]="!force">
      </indicio-2fa-settings>
    </div>
  </div>
</div>
