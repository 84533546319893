import { Injectable } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';
import { BloombergCatalogListDTO, BloombergContentDTO } from './entities/bloomberg-catalog.dtos';
import { BloombergCredentialsInfoDTO } from './entities/bloomberg-credentials.dtos';
import { BloombergDatasetContentDTO, BloombergDatasetListDTO } from './entities/bloomberg-datasets.dtos';
import { BloombergRequestDTO, BloombergRequestListDTO } from './entities/bloomberg-requests.dtos';


@Injectable({
  providedIn: 'root'
})
export class BloombergMapper {


  public mapCredentialsInfo(credInfoDto: BloombergCredentialsInfoDTO): BloombergCredentialsInfoDTO {
    credInfoDto.Expires = DateUtils.newDate(credInfoDto.Expires);
    return credInfoDto;
  }

  public mapCatalogs(catalog: BloombergCatalogListDTO): BloombergCatalogListDTO {
    catalog.Catalogs = catalog.Catalogs.map(x => this.mapContent(x));
    return catalog;
  }

  public mapContent(dto: BloombergContentDTO, parentId: string = null): BloombergContentDTO {
    dto.parentId = parentId;
    dto.open = false;
    dto.isExpandable = true;
    return dto;
  }

  public mapRequests(dto: BloombergRequestListDTO): BloombergRequestListDTO {
    return dto;
  }

  public mapRequest(requestDto: BloombergRequestDTO): BloombergRequestDTO {
    requestDto.LastRun = DateUtils.newDate(requestDto.LastRun);
    requestDto.Modified = DateUtils.newDate(requestDto.Modified);
    return requestDto;
  }

  public mapDatasets(datasetsDto: BloombergDatasetListDTO) {
    datasetsDto.Content = datasetsDto.Content.map(meta => this.mapDataset(meta));
    return datasetsDto;
  }

  public mapUniverses(dto) {
    return dto;
  }

  public mapDataset(datasetDto: BloombergDatasetContentDTO): BloombergDatasetContentDTO {
    return datasetDto;
  }

}
