<div class="fvar-info-general-tab">
  <ng-container *ngTemplateOutlet="graphTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="infoTemplate"></ng-container>
</div>

<ng-template #graphTemplate>
  <div class="graph-container">
    <indicio-alg class="modal-chart"
                 [periodicityType]="variable.Periodicity"
                 [forecast]="forecast"
                 [forecastVersion]="forecastVersion"
                 [HistoricLine]="historicLine"
                 [ownerDetails]="ownerDetails"
                 [options]="graphOptions"
                 [chartStyles]="chartStyles"
                 graphType="variable">
    </indicio-alg>
  </div>
</ng-template>

<ng-template #infoTemplate>
  <div class="grid-system"
       style="--grid-template: 550px 1fr; padding-top: 10px">
    <div class="info-grid"
         style="--grid-template-global: 145px 360px;">
      <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
      <!-- Datapoints -->
      <div class="grid-system">
        <div>Datapoints:</div>
        <div>
          {{ variable.ForecastVariableValues.length }} points used
          ({{ variable.FirstDate | dateFormat: variable.Periodicity }} -
          {{ variable.LastDate | dateFormat: variable.Periodicity }})
        </div>
      </div>
      <!-- Tags -->
      <div class="grid-system">
        <div>Tags:</div>
        <div class="crop-string"
             [class]="!!sourceVariable ? 'dotted-underline' : null">
          <span (click)="!!sourceVariable ? showSourceVariableInfo(true) : null"
                class="pointer"
                [indicioTippy]="!!sourceVariable ? 'Tags belong to the source variable. Click here to navigate to the source variable to update any tags' : null">
            {{ variable.tagString() }}
          </span>
        </div>
      </div>
      <!-- Periodicity -->
      <div class="grid-system">
        <div>Periodicity:</div>
        <div [indicioTippy]="noFutureMf
               ? 'This variable lacks future observations in the high frequency. It will therefore only be used in the aggregated form.'
               : null">
          {{ variable.periodicity.Display }}
          <fa-icon *ngIf="noFutureMf"
                   [icon]="faInfo"></fa-icon>
        </div>
      </div>
      <!-- Aggregation -->
      <div class="grid-system">
        <div>Aggregation method:</div>
        <div>{{ variable.aggregationMethod.Display }}</div>
      </div>
      <!-- Source type -->
      <ng-container *ngIf="!!sourceVariable">
        <div class="grid-system">
          <div>Source:</div>
          <div>
            <ng-container *ngTemplateOutlet="sourceTemplate; context: { variable: variable }">
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- SourceVariable -->
      <div class="grid-system">
        <div>Source variable:</div>
        <div class="crop-string">
          <span [ngClass]="!!sourceVariable ? 'pointer h-grey' : ''"
                (click)="!!sourceVariable ? showSourceVariableInfo() : null">
            {{ !!sourceVariable ? 'Open source variable view' : 'You do not have access to this source variable' }}
            <i *ngIf="!!sourceVariable"
               class="ion-android-exit"></i>
          </span>
        </div>
      </div>
      <!-- Mixed freq info -->
      <ng-container *ngIf="variable.MixedFreqTwinId">
        <div class="grid-system">
          <div>{{ variable.IsMixedFreq ? 'Aggregated variable' : 'Original variable' }}</div>
          <div class="pointer h-grey"
               (click)="openTwin(variable.MixedFreqTwinId)">
            Open variable view
            <i class="ion-android-exit"></i>
          </div>
        </div>
      </ng-container>
      <!-- Data processing -->
      <div class="grid-system">
        <div>Data processing:</div>
        <div (click)="openDataProcessingModal(variable)">
          <span class="hover-icon pointer"
                [ngClass]="{
                    'light-green': variable.processed(),
                    'red': variable.dataprocessingError() }">
            <i [ngClass]="{
                  'ion-checkmark-circled': variable.processed(),
                  'ion-alert-circled': variable.dataprocessingError(),
                  'ion-clock': variable.initial()
                   }"></i>
          </span>
        </div>
      </div>
    </div>
    <!-- Right-aligned version dropdown, second div of big grid-system. -->
    <div>
      <div *ngIf="sVarVersions.length > 1"
           [class]="!!sourceVariable ? 'version-container' : null">
        <div style="width: 100%;">
          <mat-form-field *ngIf="!!sourceVariable"
                          [indicioTippy]="variable.IsMixedFreq ? 'Version can\'t be changed for mixed frequency variables' : null">
            <mat-label>Version</mat-label>
            <mat-select [value]="newVersionId"
                        panelClass="fitted-select small-header"
                        [disabled]="variable.IsMixedFreq"
                        (selectionChange)="changeSelectedVersion($event.value)">
              <mat-option [value]="option.SourceVariableMetaId"
                          [title]="option.Name"
                          [indicioTippy]="!variable.IsIndicator && forecast.periodicity.isShorter(option.Periodicity) ? 'Cannot disaggregate main variable' : null"
                          [class]="{ disabled: !variable.IsIndicator && forecast.periodicity.isShorter(option.Periodicity) }"
                          *ngFor="let option of sVarVersions">
                <small>{{ option.LastDate | dateFormat: option.Periodicity }} | {{ option.periodicity.Display
                  }}</small>
                {{ option.Name }}
              </mat-option>
              <mat-select-trigger *ngIf="sVarVersion">{{ sVarVersion.Name }}</mat-select-trigger>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sourceTemplate
             let-variable="variable">
  <ng-container [ngSwitch]="variable.sourceType.Value">
    <ng-container *ngSwitchCase="'file'">
      <ng-container *ngIf="!!variable && sVarVersion?.SourceType === 'file'; else noAccessTemplate">
        <ng-container *ngTemplateOutlet="fileTemplate"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'sharepoint'">
      <ng-container *ngIf="!!variable && sVarVersion?.SourceType === 'sharepoint'; else noAccessTemplate">
        <ng-container *ngTemplateOutlet="fileTemplate"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'macrobondapi'">
      <div style="display: inline-block"
           class="pointer h-grey"
           indicioTippy="Open macrobond information view"
           (click)="openMacrobondInfo()">
        {{ variable.sourceType.Display }} <span class="selectable-text">({{ variable.RemoteReferenceId }})</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ variable.sourceType.Display }}
      <span class="selectable-text"
            *ngIf="variable.RemoteReferenceId">
        ({{ variable.RemoteReferenceId }})
      </span>
    </ng-container>
  </ng-container>

  <ng-template #fileTemplate>
    <span class="pointer h-grey"
          indicioTippy="Open file information view"
          (click)="openFileModal()">
      {{ variable.sourceType.Display }} ({{ sourceVariable.FileName }})</span>
  </ng-template>

  <ng-template #noAccessTemplate>
    <ng-container *ngIf="!variable">
      <span indicioTippy="You do not have access to view this information">
        {{ variable.sourceType.Display }}
      </span>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #nameTemplate>
  <div class="grid-system">
    <div class="center-vertical">Name:</div>
    <div class="center-vertical crop-string">
      <div *ngIf="editName"
           style="width: 365px;">
        <mat-form-field>
          <input type="text"
                 matInput
                 [(ngModel)]="newName"
                 class="material"
                 (keyup.enter)="saveName()">
          <i matSuffix
             *ngIf="editName && variable.Name === newName"
             class="ion-close-round red cursor-pointer edit-pen"
             (click)="editName = false">
          </i>
          <fa-icon matSuffix
                   *ngIf="editName && variable.Name !== newName"
                   class="blue cursor-pointer edit-pen"
                   [icon]="faSave"
                   (click)="saveName()">
          </fa-icon>
        </mat-form-field>
      </div>
      <div *ngIf="!editName"
           class="center-vertical">
        <div class="crop-string name-field"
             style="display: inline-block; max-width: 335px; padding: 1px 0;"
             [title]="newName">{{ newName }}
        </div>
        <span style="margin-left: 5px">
          <i class="ion-edit cursor-pointer edit-pen"
             (click)="editName = true"></i>
        </span>
      </div>
    </div>
  </div>
</ng-template>
