
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { SourceType } from '@modules/lang/language-files/source-types';
import { ModifyAPILoginModalOpts } from './modify-api-login.options';

export class OpenModifyAPILoginModal {
  public static readonly type = '[MODAL] Open AddAPILoginModal login modal';
  data: ModifyAPILoginModalOpts;
  constructor(
    provider: SourceType,
    helpText: string,
    dataSource: RemoteDataSourceModel,
    confirmFn: Function,
    cancelFn: Function,
    regEx: RegExp = new RegExp('.*'),
    keyTest?: Function,
    extraOpts?: {
      disableClose?: boolean;
      testKeyFuncReturnsApiKey?: boolean;
      titles?: {
        username: string,
        password: string;
      };
    }
  ) {
    this.data = { provider, helpText, dataSource, confirmFn, cancelFn, regEx, keyTest, extraOpts: extraOpts || {} };
  }
}
