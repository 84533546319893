import { Def } from '@core/decorators/def';

export class AceaMetaDataDTO {
  SelectedBaseCategoryTitle: String;
  SelectedSubCategoryTitle: String;
}

export class AceaDataSerieModel {
  @Def() id: string;
  @Def() AceaVariableId: string;
  @Def() title: string;
  @Def() Country: string;
  @Def() Periodicity: string;
  @Def() LastDate: Date;
  @Def() FirstDate: Date;
  @Def() Values: any;

  @Def() periodicity: string;
  @Def() EndYear: Date;
  @Def() StartYear: Date;

  @Def() Tippy: string;

  @Def() Type: string;
  @Def() Code: string;
  @Def() Route: string;

  @Def() Metadata: AceaMetaDataDTO;

  @Def() SelectedBaseCategoryTitle: string;
  @Def() Description: string;
  @Def() Company: string;
  @Def() Target: string;
  @Def() SelectedSubCategoryTitle: string;
  @Def() Category: string;

  oldData: boolean;
}
