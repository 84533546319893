import { Component, ViewEncapsulation } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { CloseMainMenuAction, NavigateToReport } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { AvailableProjectsInfoDTO } from '@core/store/reports/dtos/company-forcasts.dto';
import { CreateReportDTO } from '@core/store/reports/dtos/report.dtos';
import { ReportFrontendService } from '@core/store/reports/report.service';
import { ReportTemplate, ReportTemplateType } from '@modules/lang/language-files/reports';
import { Store } from '@ngxs/store';
import { OpenCompanyModal } from '@shared/modals/company/overview/company-modal.actions';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateUtils } from '@shared/utils/date.utils';
import { ReportUtils } from '@shared/utils/report.utils';
import * as moment from 'moment';
import { CreateReportModalOpts } from './create-report-modal.options';

@Component({
  selector: 'indicio-create-report-modal',
  templateUrl: './create-report-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CreateReportModalComponent extends ModalModelComponent {

  opts: CreateReportModalOpts;

  createDto: CreateReportDTO = {
    Name: '',
    ForecastIds: [],
    CompanyId: '',
    Template: 'overview',
    StartDate: DateUtils.newDate().toString()
  };

  allProjectsAvailable: AvailableProjectsInfoDTO[];
  projectsAvailable: AvailableProjectsInfoDTO[];
  templatesAvailable: ReportTemplate[];

  pending: boolean;
  reportNameMustChange: boolean;
  date: moment.Moment;

  isCompanyAdmin: boolean;

  public get saveDisabled() {
    return this.reportNameMustChange || !!!this.createDto.Name || this.createDto.ForecastIds.length === 0;
  }

  public get fiscalMonth() {
    return this.envService.fiscalOpts.find(x => x.Value === this.clientService.activeCompany.FiscalYear).Display;
  }

  constructor(
    protected store: Store,
    public envService: EnvironmentService,
    private service: ReportFrontendService,
    private clientService: ClientFrontendService,
    private reportService: ReportFrontendService,
    private status: StatusService,
    private profileService: ProfileFrontendService
  ) { super(); }

  /* CAN_UPDATE_COMPANY */

  public setOptions(options: CreateReportModalOpts) {
    this.opts = options;
    const currentYear = DateUtils.newMoment().month(this.clientService.activeCompany.FiscalYear);
    this.date = currentYear;
    this.createDto.StartDate = currentYear.toISOString();

    this.isCompanyAdmin = this.clientService.activeCompany.canUserEdit(this.profileService.profile.Email);

    const pPromise = this.service.fetchAvailableForecasts();
    Promise.all([pPromise])
      .then(([pa]) => {
        this.allProjectsAvailable = pa;
      })
      .catch(e => this.status.setError(e, true))
      .finally(() => { this.isLoading = false; });

    this.templatesAvailable = this.envService.ReportTemplates;
    this.createDto.CompanyId = this.clientService.activeCompany.CompanyId;
  }

  public changeTemplate(template: ReportTemplateType) {
    switch (template) {
      case 'fiscal-year':
        if (!this.date) { return; }
        this.filterAvailableForecasts();
        break;
      case 'overview':
        this.projectsAvailable = this.allProjectsAvailable;
        this.projectsAvailable.forEach(p => p.Forecasts.forEach(f => f.disabled = false));
        break;
    }
  }

  public chosenYearHandler(normalizedYear: moment.Moment) {
    const year = normalizedYear.year();
    const dateToSet = DateUtils.newMoment(`${year}-${this.clientService.activeCompany.FiscalYear + 1}-01`);
    this.date = dateToSet;
    this.createDto.StartDate = dateToSet.format('yyyy-MM-DD');
    this.createDto.ForecastIds = [];
    this.filterAvailableForecasts();
  }

  private filterAvailableForecasts() {
    this.allProjectsAvailable.forEach(p => {
      p.Forecasts.forEach(f => f.disabled = !ReportUtils.canAddForecast(this.createDto.Template, this.date, [], f));
      p.Forecasts = ReportUtils.orderAvailableReportForecasts(p.Forecasts);
    });
  }

  public changeReportName(name: string) {
    if (this.reportService.reports?.find(x => x.Name === name)) {
      this.reportNameMustChange = true;
    } else {
      this.reportNameMustChange = false;
    }
    this.createDto.Name = name;
  }

  public setForecasts($event: MatOptionSelectionChange) {
    const source = $event.source;
    if (source.selected) {
      this.createDto.ForecastIds.push(source.value);
    } else {
      this.createDto.ForecastIds.splice(this.createDto.ForecastIds.indexOf(source.value), 1);
    }
  }

  public createReport() {
    this.pending = true;
    this.service.createNewReport(this.createDto)
      .then(report => {
        this.close();
        this.store.dispatch(new NavigateToReport(report.ReportId));
        this.store.dispatch(new CloseMainMenuAction());
        this.status.setMessage('Report created successfully', 'Success');
      })
      .catch(e => this.status.setError(e, true))
      .finally(() => {
        this.pending = false;
      });
  }

  public openCompany() {
    this.close();
    this.store.dispatch(new OpenCompanyModal(this.clientService.activeCompany.CompanyId));
  }
}
