import { Def } from '@core/decorators/def';

export class HistoricEventDTO {
  @Def() HistoricEventId: string;
  @Def() HistoricBaseEventId: string;
  @Def() Name: string;
  @Def() Date: string;
  @Def() EffectType: string;
  @Def() EndDate?: string = undefined;
}

export class CreateHistoricEventDTO {
  @Def() Name: string;
  @Def() Date: string;
  @Def() EffectType: string;
  @Def() EndDate?: string = undefined;
}

export class UpdateHistoricEventDTO {
  @Def() HistoricEventId: string;
  @Def() Name: string;
  @Def() Date: string;
  @Def() EffectType: string;
  @Def() EndDate?: string = undefined;
}
