import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { Transformations } from '@core/types/transformation.type';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { DisplayValue } from '@modules/lang/types/display-value';
import { VariableTransformation } from '@modules/lang/types/variable-transformation';
import { StepAhead } from '@shared/components/step-ahead/step-ahead';
import { ForecastVariableUtils } from '@shared/utils/forecast/forecast-variable.utils';
import { FVarAdvancedSettings } from './advanced-settings';

@Component({
  selector: 'indicio-fvar-info-dialog-advanced-tab',
  templateUrl: './fvar-info-tab.advanced.component.html',
  styleUrls: ['./fvar-info-tab.advanced.component.less']
})
export class FVarInfoDialogAdvancedTabComponent implements OnChanges {

  @Input() variable: ForecastVariableModel;
  @Input() forecastVersion: ForecastVersionModel;

  @Output() closeDialogEvent = new EventEmitter();

  // Frontend flags
  public isLoading: boolean;
  public saveInProgress: boolean;
  public transformChanged: boolean;
  public exoSettingsChanged: boolean;

  // Exo settings
  public exoDisabled: boolean = false;
  public isExogenous: boolean;
  public exoNumbersValid: boolean;
  public stepAhead: StepAhead.Model;

  // Display getters
  public get exoNumbersChanged() { return this.stepAhead.isModified(); }
  public get isTrend() { return this.variable.IsTrend; }

  // Data
  public conclusionType = FVarAdvancedSettings.conclusionDisplay;

  // Transform settings
  public sVarTransform: Transformations.FVarTrans;
  public fVarTransform: string;
  public variableTransforms: VariableTransformation[];
  public modelTransforms: DisplayValue[];
  public warningReasons: { HasMissing: boolean, HasZeroes: boolean; };

  // Feature engineering settings
  public addNewFeatureOpen: boolean;
  public canAddFeature: boolean;
  /** Transformations already applied to the source variable. */
  public currentFeatures: Transformations.CurrentTransformInfo[];
  /** The new transformation to add (if user presses Add button). */
  public nextFeature: Transformations.FVarTrans;
  /** All selected, not yet applied, transformations. */
  public selectedFeatures: Transformations.FVarTrans[] = [];

  constructor(
    private status: StatusService,
    private fVarService: ForecastVariableFrontendService,
    private variableDialog: VariableDialogService,
    private cd: ChangeDetectorRef,
    private env: EnvironmentService
  ) {
  }

  public ngOnChanges() {
    this.setup();
    this.cd.detectChanges();
  }

  public addNewFeature() {
    this.addNewFeatureOpen = true;
    this.cd.detectChanges();
  }

  public selectFeature(trans: Transformations.FVarTrans) {
    this.nextFeature = trans;
    this.updateCanAdd();
  }

  public addFeature() {
    const toAdd = this.nextFeature.copy();
    const t = this.env.VariableTransformations.find(x => x.Value === toAdd.Transformation);
    toAdd.Display = t.Display;
    this.selectedFeatures.push(toAdd);
    this.addNewFeatureOpen = false;
    this.updateCanAdd();
  }

  public removeFeature(index: number) {
    this.selectedFeatures.splice(index, 1)[0];
  }

  private updateCanAdd() {
    this.canAddFeature = this.currentFeatures.every(f => !f.Trans.isSame(this.nextFeature)) &&
      this.selectedFeatures.every(f => !f.isSame(this.nextFeature));
  }

  public selectedSVarTransform(trans: Transformations.FVarTrans) {
    this.sVarTransform = trans;
    this.checkTransformChanged();
  }

  public toggleIsExogenous() {
    this.isExogenous = !this.isExogenous;
    this.checkExoChanged();
  }

  public restoreExoValues(fromSource: boolean) {
    this.stepAhead.restore(fromSource);
    this.checkExoChanged();
  }

  public exoValuesChanged() {
    this.exoNumbersValid = this.stepAhead.allValuesSet;
    this.checkExoChanged();
  }

  public setFVarTransform(transform: string) {
    this.fVarTransform = transform;
    this.checkTransformChanged();
  }

  public getTransformationDisplay(transform): VariableTransformation {
    transform = transform.replace(/(_norm$)|(_std$)/gm, '');
    return this.env.getVariableTransformation(transform);
  }

  public resetFeatures() {
    this.selectedFeatures = [];
  }

  public saveFeatures() {
    this.variableDialog.openCreateMultiFVar({
      forecastVersion: this.forecastVersion,
      createDtos: this.selectedFeatures.map(x => ForecastVariableUtils.MapCreateDtoFromTransform(this.variable, x)),
      displays: this.selectedFeatures.map(x => `${x.Display} ${x.getArgString()}`)
    }).then(saved => {
      if (saved) { this.setup(); }
    });
  }

  public saveTransformation() {
    if (this.saveInProgress) { return; }
    this.saveInProgress = true;

    const oldTrans = this.variable.Transformation;
    const oldSVTrans = this.variable.ValueTransform;
    this.variable.Transformation = this.fVarTransform;
    this.variable.ValueTransform = this.sVarTransform;

    return this.fVarService.updateForecastVariableSettings(this.variable)
      .then(updated => {
        this.variable = updated;
        this.setup();
        this.status.setMessage('New transformation set successfully', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
        this.variable.Transformation = oldTrans;
        this.variable.ValueTransform = oldSVTrans;
        throw error;
      })
      .finally(() => this.saveInProgress = false);
  }

  public saveExoSettings() {
    if (this.saveInProgress) { return; }
    this.saveInProgress = true;

    if (this.isExogenous) {
      this.variable.CalculateOutliers = false;
      this.variable.CalculateSeasonal = false;
    }

    this.variable.IsExogenous = this.isExogenous;
    this.variable.ExogenousFutureValues = this.stepAhead.values;

    this.fVarService.updateForecastVariableSettings(this.variable)
      .then(updated => {
        this.variable = updated;
        this.setup();
        this.status.setMessage('Variable updated successfully', 'Success');
      })
      .catch(error => this.status.setError(error, true))
      .finally(() => this.saveInProgress = false);
  }

  private setup() {
    // Transformation settings
    this.fVarTransform = this.variable.Transformation;
    this.variableTransforms = FVarAdvancedSettings.FilterVariableTransformations(this.variable.periodicity, this.env.VariableTransformations);
    this.sVarTransform = this.variable.ValueTransform.copy();
    this.modelTransforms = FVarAdvancedSettings.FilterModelTransformations(this.env.VariableTransformations);
    this.warningReasons = {
      HasMissing: this.variable.HasMissingValues && !this.variable.NeedDisAggregation,
      HasZeroes: this.variable.ForecastVariableValues.some(x => x.V === 0)
    };
    this.checkTransformChanged();
    // Exo settings
    this.setupStepAhead();
    this.isExogenous = this.variable.IsExogenous;
    if (this.variable.NeedDisAggregation === true && this.variable.IsExogenous) {
      this.status.setMessage('A disaggregated variable can not be exogenous, please disable this setting.', 'Error', true);
    } else if (this.variable.NeedDisAggregation === true && !this.variable.IsExogenous) {
      this.exoDisabled = true;
    }
    if (!this.variable.IsIndicator) {
      this.exoDisabled = true;
    }
    // Feature engineering
    // Set up the list of currently active transformations for this variable
    this.currentFeatures = this.forecastVersion
      .getAllVariables()
      .filter(x => !x.IsTrend && !x.IsMixedFreq && x.SourceVariableId === this.variable.SourceVariableId)
      .map(x => ({
        Name: x.Name,
        Trans: x.ValueTransform,
        Display: this.variableTransforms.find(vt => vt.Value === x.ValueTransform.Transformation)
      }));
    this.resetFeatures();
    this.updateCanAdd();
  }

  public setupStepAhead() {
    this.stepAhead = StepAhead.MapFromForecastVariable(this.variable);
    this.exoNumbersValid = this.stepAhead.allValuesSet;
  }

  private checkTransformChanged() {
    this.transformChanged =
      this.fVarTransform !== this.variable.Transformation ||
      !this.sVarTransform.isSame(this.variable.ValueTransform);
  }

  private checkExoChanged() {
    this.exoSettingsChanged =
      this.variable.IsExogenous !== this.isExogenous ||
      this.exoNumbersChanged;
  }
}
