<div class="wiki-snackbar"
     *ngIf="page.Parents.length">
  <div style="display: inline-block;"
       *ngFor="let parent of page.Parents">
    <span class="dotted-underline cursor-pointer"
          (click)="openPage(parent)">{{ parent.Title }}</span>
    <span class="separator">
      &nbsp;&gt;&nbsp;
    </span>
  </div>
  <span style="color: #7186F1">{{ page.Title }}</span>
</div>
