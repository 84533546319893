import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuandlDataSerieDTO } from '@core/store/providers/quandl/dtos/quandl-dataserie.dto';
import { QuandlSearchDTO } from '@core/store/providers/quandl/dtos/quandl-search.dto';
import { QuandlDatabaseModel } from '@core/store/providers/quandl/quandl-database.model';
import { QuandleDatasetModel } from '@core/store/providers/quandl/quandl-dataset.model';
import { QuandlMapper } from '@core/store/providers/quandl/quandl.mapper';
import { DateUtils } from '@shared/utils/date.utils';
import { StorageUtils } from '@shared/utils/storage.utils';
import { EnvironmentService } from '@core/services/environment/environment.service';


@Injectable({
  providedIn: 'root'
})
export class QuandlService {

  private baseUrl = 'https://data.nasdaq.com/api/v3';
  private APIKey: string;
  public lastUri: string;
  public databases: QuandlDatabaseModel[] = [];
  private databasesSet: Boolean;

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private mapper: QuandlMapper
  ) { }

  public setAPIKey(key: string) {
    this.APIKey = key;
  }
  private getUrl(path: string, withProxy = false) {
    const url = `${this.baseUrl}/${path}`;

    this.lastUri = url;

    if (withProxy) {
      return `${this.envService.env.CorsProxy.replace(/\/$/, '')}/${url}`;
    }

    return url;
  }

  public getDataSerie(dataset: QuandleDatasetModel, columnIndex: number) {
    const u = this.getUrl(`/datasets/${dataset.database_code}/${dataset.dataset_code}/data.json?column_index=${columnIndex}&start_date=${dataset.oldest_available_date}&order=asc&api_key=${this.APIKey}`, true);
    return this.http.get<QuandlDataSerieDTO>(u).toPromise()
      .then(d => this.mapper.mapSerie(d));
  }

  public getDatabases(page = 1) {
    if (this.databasesSet) {
      return;
    }

    const storedDatabases = StorageUtils.get('quandl.databases', 'sessionStorage');
    if (storedDatabases) {
      this.databases = storedDatabases.map(x => this.mapper.mapDatabase(x));
      this.databasesSet = true;
      return;
    }

    const u = this.getUrl(`/databases?api_key=${this.APIKey}&page=${page}`, true);

    return this.http.get<any>(u).toPromise().then(d => {
      if (this.databases.length < d.meta.total_count) {
        this.databases.push(...d.databases.map(x => this.mapper.mapDatabase(x)));
        return d.meta.next_page;
      } else {
        return null;
      }
    }).then(next => {
      if (next) {
        this.getDatabases(next);
      } else {
        StorageUtils.set('quandl.databases', this.databases, 'sessionStorage');
        this.databasesSet = true;
      }
    });
  }

  public search(query: string, page = 1) {
    query = encodeURIComponent(query);
    return this.http.get<QuandlSearchDTO>(this.getUrl(`datasets.json?query=${query}&per_page=100&page=${page}&api_key=${this.APIKey}`, true)).toPromise()
      .then(response => this.mapper.mapSearch(response));
  }

  public testApiKey(key: string) {
    const today = DateUtils.newMoment().format('YYYY-MM-DD');
    return this.http.get<any>(this.getUrl(`/datasets/EURONEXT/BNP/data.json?start_date=${today}&api_key=${key}`, true)).toPromise();
  }
}
