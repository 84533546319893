import { PastForecastDTO } from '@core/entities/dtos/past-forecast-dto';
import { PlotValue, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { TransformationUtils } from '@shared/utils/forecast/transformation.utils';
import { StatisticsUtils } from '@shared/utils/statistic.utils';
import { ALGSingleSeriesModel, PastForecastData } from '../alg-models/graph-data.model';
import { ALGTypes } from '../alg-types';

function getValue(value: number, prev: number, growth: boolean = false): number {
  if (value === null || prev === null)
    return null;
  else
    return growth
      ? Math.round(((value - prev) / prev) * 1000) / 1000
      : Math.round((value - prev) * 1000) / 1000;
}

function getPreviousValueOrNull(values: PlotValue[], index: number, lag: number = 1) {
  return index - lag >= 0 ? values[index - lag].V : null;
}

function getOriginalPastForecast(startPoint: PlotValue, pastForecast: PastForecastDTO, values: PlotValue[], valueIndex: number) {
  const result: SimplePlotValue[] = [];
  result.push({ D: startPoint.D, V: startPoint.V, m: startPoint.m });
  for (let i = 0; i < pastForecast.Values.length; i++) {
    const v1 = values[valueIndex + i + 1];
    const pastValue = pastForecast.Values[i];
    result.push({ D: v1.D, V: pastValue, m: v1.m });
  }
  return result.filter(x => x.V !== null);
}

function getChangeTransformedPastForecast(startPoint: PlotValue, pastForecast: PastForecastDTO, values: PlotValue[], valueIndex: number, frequency: number, growth: boolean = true) {
  const result: SimplePlotValue[] = [];
  const p = getPreviousValueOrNull(values, valueIndex, frequency);

  result.push({
    D: startPoint.D,
    V: getValue(startPoint.V, p ? p : startPoint.V, growth),
    m: startPoint.m
  });

  for (let i = 0; i < pastForecast.Values.length; i++) {
    const v1 = values[valueIndex + i + 1];
    const p1 = getPreviousValueOrNull(values, valueIndex + i + 1, frequency);
    const pastValue = pastForecast.Values[i];
    result.push({
      D: v1.D,
      V: getValue(pastValue, p1, growth),
      m: v1.m
    });
  }

  return result.filter(x => x.V !== null);
}

function getRollingTransformedPastForecast(startPoint: PlotValue, pastsAtPoint: PastForecastDTO, values: PlotValue[], valueIndex: number, points: number, type: TransformationUtils.RollingType) {
  const result: SimplePlotValue[] = [];

  if (valueIndex < points - 1) { return result; }

  const numberValues: number[] = values.map(v => v.V);
  const average = type === TransformationUtils.RollingType.Average;

  result.push({
    D: startPoint.D,
    V: StatisticsUtils.getLastXRolling(numberValues, valueIndex, points, average),
    m: startPoint.m
  });

  for (let i = 0; i < pastsAtPoint.Values.length; i++) {
    const pastsToUse = pastsAtPoint.Values.slice(0, i + 1);
    if (pastsToUse.some(x => x === null)) { continue; }

    const v1 = values[valueIndex + i + 1];
    let arr = numberValues.slice(0, valueIndex);
    arr = arr.concat(pastsToUse);
    if (arr.length < points) { continue; }
    result.push({
      D: v1.D,
      V: StatisticsUtils.getLastXRolling(arr, arr.length - 1, points, average),
      m: v1.m
    });
  }

  return result;
}

export function getPastForecastData(
  model: ALGSingleSeriesModel,
  values: PlotValue[],
  transform: ALGTypes.Transform,
  frequency: number
): PastForecastData {
  const result: PastForecastData = { Model: model, PastForecasts: [], Segments: [], Color: model.Color, Type: 0 };
  const pastValues: SimplePlotValue[][] = [];
  const pastPoints = model.PastForecasts;
  if (!pastPoints || pastPoints.length === 0) { return result; }

  const valueCount = values.length;
  const pastCount = pastPoints.length;
  const historicCount = valueCount - values.filter(x => x.IF).length;
  const pastForecastCount = Math.min(pastCount, historicCount);

  frequency = (transform === ALGTypes.Transform.roc || transform === ALGTypes.Transform.diff)
    ? 1
    : frequency;
  const growth = (transform === ALGTypes.Transform.roc || transform === ALGTypes.Transform.rocy);
  const points = (transform === ALGTypes.Transform.rolling12mSum || transform === ALGTypes.Transform.rolling12m)
    ? 12
    : 4;
  const rollingType = (transform === ALGTypes.Transform.rolling12mSum || transform === ALGTypes.Transform.rolling4qSum)
    ? TransformationUtils.RollingType.Sum
    : TransformationUtils.RollingType.Average;

  for (let pastForecastIndex = 1; pastForecastIndex <= pastForecastCount; pastForecastIndex++) {

    const pastForecastAtIndex = pastPoints.find(x => x.Step === pastForecastIndex);
    if (!pastForecastAtIndex) { break; }

    const valueIndex = historicCount - pastForecastIndex - 1;
    const startPoint = values[valueIndex];

    if (!startPoint) { return; }

    switch (transform) {
      case ALGTypes.Transform.original:
        const n = getOriginalPastForecast(startPoint, pastForecastAtIndex, values, valueIndex);
        pastValues.push(n);
        break;

      case ALGTypes.Transform.roc:
      case ALGTypes.Transform.rocy:
      case ALGTypes.Transform.diff:
      case ALGTypes.Transform.diffy:
        const rocs = getChangeTransformedPastForecast(startPoint, pastForecastAtIndex, values, valueIndex, frequency, growth);
        pastValues.push(rocs);
        break;

      case ALGTypes.Transform.rolling12m:
      case ALGTypes.Transform.rolling12mSum:
      case ALGTypes.Transform.rolling4q:
      case ALGTypes.Transform.rolling4qSum:
        const r12m = getRollingTransformedPastForecast(startPoint, pastForecastAtIndex, values, valueIndex, points, rollingType);
        pastValues.push(r12m);
        break;

      default:
        break;
    }
  }
  result.PastForecasts = pastValues;
  return result;
}
