import { Def } from '@core/decorators/def';
import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ItemState } from '@shared/utils/itemstate.util';
import { ScenarioVariableDTO } from './scenario-variable.dto';

export class CreateScenarioDTO {
  ForecastVersionId: string = null;
  Name: string = null;
  WeightSchema: string = null;
  Exponent: number = null;
  Models: number = null;
  ManualRanks: boolean = false;
  ScenarioVariables: ScenarioVariableDTO[] = [];
  ModelsToExclude: string[] = ['var', 'varma'];
}

export class UpdateScenarioDTO extends CreateScenarioDTO {
  ScenarioId: string = null;
}

export class ScenarioDTO {
  @Def() ScenarioId: string = null;
  @Def() ForecastVersionId: string = null;
  @Def() Name: string = null;
  @Def() WeightSchema: string = 'RMSE Stepwise';
  @Def() Exponent: number = 4;
  @Def() Models: number = 0;
  @Def() Pending: boolean = false;
  @Def() Triggered: boolean = false;
  @Def() Processing: boolean = false;
  @Def() ModelsToExclude: string[] = [];
  @Def() ColorIndex: number = 0;

  @Def() FinalResults: ScenarioFinalResultDTO[] = [];
  @Def() ScenarioVariables: ScenarioVariableDTO[] = [];

  // Frontend stuff
  fetched: boolean = false;
  active: boolean = true;
  color: string = 'white';
  showOriginalCI: boolean = false;

  _tempVariables: ItemState<ScenarioVariableDTO>[] = [];
}

export class ScenarioFinalResultDTO {

  ScenarioId: string = null;
  ForecastVersionId: string = null;

  Ci50: boolean = false;
  Ci75: boolean = false;
  Ci95: boolean = false;

  WarningMessages: string[] = [];
  InfoMessages: string[] = [];
  FinalModels: ScenarioFinalResultModelDTO[] = [];
}

export class ScenarioFinalResultModelDTO {
  MainVariable: boolean = null;
  ForecastVariableId: string = null;
  PlotData: PlotValueDTO[] = [];
  OriginalResult: PlotValueDTO[] = [];
}
