import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { CWeekdays } from '@core/constants/date.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AutoTaskFrontendService } from '@core/store/auto-task/auto-task.frontend.service';
import { AutoTaskAlertInfoDTO } from '@core/store/auto-task/dtos/auto-task.dto';
import { AutoTaskInfoModel, AutoTaskModel, AutoTaskStepMetaModel, AutoTaskStepModel } from '@core/store/auto-task/dtos/auto-task.model';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { AvailableProjectsInfoDTO } from '@core/store/reports/dtos/company-forcasts.dto';
import { ReportFrontendService } from '@core/store/reports/report.service';
import { AutoTaskStepType, EAutoTaskByEventType, EAutoTaskSendMailTrigger, EAutoTaskTriggerType } from '@modules/lang/language-files/automations';
import { Period, PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Periodicity } from '@modules/lang/types/periodicity';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import * as EmailValidator from 'email-validator';
import { TaskModalOpts } from './task-modal.action';

export class SupportedAutoTasksIntervals { public static values: string[] = [Period.day, Period.week, Period.month]; }

@Component({
  templateUrl: './task-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./task-modal.component.less']
})
export class TaskModalComponent extends ModalModelComponent {

  public Period = Period;
  public TriggerType = EAutoTaskTriggerType;
  public TriggerByEventType = EAutoTaskByEventType;
  public EmailTriggerType = EAutoTaskSendMailTrigger;
  public generalOpen = true;
  public task: AutoTaskModel;
  public taskEnvInfo: AutoTaskInfoModel = null;
  public intervals: Periodicity[] = [];
  public projects: AvailableProjectsInfoDTO[] = [];
  public weekdays: string[] = CWeekdays;
  public daysInMonth: number[] = Array.from(Array(28).keys()).map(x => x + 1);
  public edit: boolean = false;
  public taskMessage: string = null;
  private opts: TaskModalOpts;

  public chosenTargetForecastIds: string[] = [];

  public get isChanged() { return this.modalState.isChanged('task', this.task); }
  public get companyId() { return this.clientService.activeCompanyId; }
  public get taskValid() {
    if (!this.task || !this.task.Name || !this.task.Name.length) {
      this.taskMessage = 'Title of task missing'; return false;
    }
    if (!this.task.Steps.length) {
      this.taskMessage = 'At least one step is needed'; return false;
    }
    if (!this.task.Steps.every(s => this.stepValid(s))) {
      return false;
    }
    if (this.edit && !this.isChanged) {
      this.taskMessage = 'No changes detected';
      return false;
    }
    this.taskMessage = null;
    return true;
  }

  constructor(
    protected store: Store,
    public envService: EnvironmentService,
    private dialogService: DialogService,
    private service: AutoTaskFrontendService,
    private status: StatusService,
    private clientService: ClientFrontendService,
    private reportService: ReportFrontendService
  ) {
    super();
  }

  public forecastExists(id: string) { return this.projects.some(p => p.Forecasts.some(f => f.ForecastId === id)); }

  public setOptions(options: TaskModalOpts) {
    this.opts = options;
    this.edit = !!this.opts.taskId;
    this.intervals = this.envService.Periodicities.filter(e => SupportedAutoTasksIntervals.values.indexOf(e.Value) > -1);
    const taskPromise = this.edit ? this.service.fetch(this.companyId, this.opts.taskId) : Promise.resolve(new AutoTaskModel());
    const projInfosPromise = this.reportService.fetchAvailableForecasts();
    const getInfoPromise = this.service.getAutoTaskInfo();
    Promise.all([getInfoPromise, taskPromise, projInfosPromise])
      .then(([info, task, projectInfos]) => {
        this.taskEnvInfo = info;
        this.task = task;
        this.projects = projectInfos;
        if (!this.edit) {
          this.task.CompanyId = this.companyId;
          this.setInterval(Period.week);
        } else {
          this.task.Steps.forEach(s => {
            this.setupExistingStep(s);
          });
        }
        this.modalState.setState('task', this.task);
      }).finally(() => {
        this.isLoading = false;
      });
  }

  private setupExistingStep(step: AutoTaskStepModel) {
    step.stepOpen = false;
    if (!!step.TargetForecastId && !this.forecastExists(step.TargetForecastId)) {
      step.TargetForecastId = null;
    }
    if (step.StepType !== 'send-result') {
      step.StepMetas = step.StepMetas.filter(x => this.forecastExists(x.ForecastId));
    }
    switch (step.StepType) {
      case 'use-result': {
        step.targetForecastVariables = this.getForecastInfo(step.TargetForecastId)?.Variables;
        if (!step.targetForecastVariables) {
          step.StepMetas = [];
        } else {
          step.StepMetas.forEach(m => this.setSourceForecast(m.ForecastId, m, step));
        }
      }
    }
  }

  public openStepAlertDialog(infos: AutoTaskAlertInfoDTO[], type: string, event) {
    event.stopPropagation();
    this.dialogService.openConfirmDialog({
      Message: infos.map(x => x.Message).join(', '),
      Title: 'Step ' + type,
      ConfirmText: 'Ok',
      HideCancel: true
    });
  }

  public stepValid(step: AutoTaskStepModel) {
    if (!step.StepType || !step.StepType.length) {
      this.taskMessage = `Step ${step.Step} is missing a type`;
      return false;
    }
    if (!step.StepMetas || !step.StepMetas.length) {
      this.taskMessage = `Step ${step.Step} is missing information`;
      return false;
    }
    if ((step.StepType === 'send-result' || step.StepType === 'use-result') && !!!step.TargetForecastId) {
      this.taskMessage = `Step ${step.Step} is missing forecast information`;
      return false;
    }
    if (step.StepType === 'send-result') {
      if (!!!step.SendWhen) {
        this.taskMessage = `Step ${step.Step} is missing send when`;
        return false;
      } else if (step.StepMetas.some(x => EmailValidator.validate(x.Email) === false)) {
        this.taskMessage = `Step ${step.Step} has an invalid email`;
        return false;
      }
    }
    return true;
  }

  public createOrSave() {
    this.pending = true;
    let promise: Promise<AutoTaskModel>;
    if (this.edit) {
      promise = this.service.updateAutoTask(this.companyId, this.task)
        .then(t => {
          this.status.setMessage('Task updated', 'Success', true);
          return t;
        });
    } else {
      promise = this.service.createAutoTask(this.companyId, this.task)
        .then(t => {
          this.edit = true;
          this.status.setMessage('Task created', 'Success', true);
          return t;
        });
    }
    promise
      .then(t => {
        this.task = t;
        this.task.Steps.forEach(s => {
          s.stepOpen = false;
          this.setupExistingStep(s);
        });
        this.modalState.setState('task', t);
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => this.pending = false);
  }

  public setInterval(interval: PeriodicityType) {
    this.task.Interval = interval;
    switch (interval) {
      case Period.week:
        this.task.TriggerAtDay = 0;
        break;
      case Period.day:
      case Period.month:
      default:
        break;
    }
  }

  public setForecasts(evt: MatOptionSelectionChange, step: AutoTaskStepModel) {
    if (evt.source.selected) {
      if (!step.StepMetas.some(x => x.ForecastId === evt.source.value)) {
        const m = new AutoTaskStepMetaModel();
        m.ForecastId = evt.source.value;
        step.StepMetas.push(m);
      }
    } else {
      step.StepMetas.removeByKey('ForecastId', evt.source.value);
    }
  }

  public setStepType(type: AutoTaskStepType, step: AutoTaskStepModel) {
    step.StepType = type;
    step.stepType = this.taskEnvInfo.StepTypes.find(x => x.Value === step.StepType);

    if (step.StepType === 'send-result' && !step.StepMetas.some(x => !!x.Email)) {
      step.SendWhen = 'on-new-result';
      step.StepMetas = [new AutoTaskStepMetaModel()];
    }
  }

  public setTargetForecast(targetForecastId: string, step: AutoTaskStepModel) {
    step.TargetForecastId = targetForecastId;
    if (step.StepType !== 'send-result' || !step.StepMetas.some(x => !!x.Email)) {
      step.StepMetas = [new AutoTaskStepMetaModel()];
    }
    step.targetForecastVariables = this.getForecastInfo(targetForecastId)?.Variables;
  }

  public setSourceForecast(sourceForecastId: string, meta: AutoTaskStepMetaModel, step: AutoTaskStepModel) {
    const source = this.getForecastInfo(sourceForecastId);
    const mv = source.Variables.find(x => !x.IsIndicator);
    meta.meta = step.targetForecastVariables.find(x => x.SourceVariableId === mv.SourceVariableId);
    meta.ForecastId = sourceForecastId;
  }

  public removeSourceForecast(meta: AutoTaskStepMetaModel, step: AutoTaskStepModel) {
    step.StepMetas.removeByKey('ForecastId', meta.ForecastId);
  }

  public removeEmail(meta: AutoTaskStepMetaModel, step: AutoTaskStepModel) {
    step.StepMetas.removeByKey('Email', meta.Email);
  }

  public isValidEmail(email: string) {
    return EmailValidator.validate(email);
  }

  private getForecastInfo(sourceForecastId: string) {
    return this.projects.find(p => p.Forecasts.some(f => f.ForecastId === sourceForecastId))
      ?.Forecasts.find(f => f.ForecastId === sourceForecastId);
  }

  public anySourceChoosable(step: AutoTaskStepModel) {
    const num = step.StepMetas.length;
    const pickable = this.projects.reduce((a, b) => [...a, ...b.Forecasts.filter(x => this.isSourceChoosable(x.ForecastId, step))], []).length;
    return pickable > num;
  }

  public isSourceChoosable(sourceForecastId: string, step: AutoTaskStepModel) {
    return !!!this.getTippyText(sourceForecastId, step);
  }

  public getTippyText(sourceForecastId: string, step: AutoTaskStepModel) {
    if (step.StepMetas.some(x => x.ForecastId === sourceForecastId)) {
      return 'The result is already used';
    }
    if (step.TargetForecastId === sourceForecastId) {
      return 'This forecast is already used as target forecast';
    }
    const mv = this.getForecastInfo(sourceForecastId).Variables.find(y => !y.IsIndicator);
    if (!step.targetForecastVariables.some(x => x.SourceVariableId === mv.SourceVariableId)) {
      return 'Results from this forecast is not used in target forecast';
    }
  }

  public addEmptyStepMeta(step: AutoTaskStepModel) {
    step.StepMetas.push(new AutoTaskStepMetaModel());
  }

  public addNewStep() {
    const step = new AutoTaskStepModel();
    step.Step = !this.task.Steps.length ? 1 : Math.max(...this.task.Steps.map(x => x.Step)) + 1;
    const forecastIds: string[] = [];
    const previousStep = this.task.Steps.filter(x => x.StepType !== 'send-result').find(x => x.Step === step.Step - 1);

    if (previousStep) {
      forecastIds.push(...previousStep.StepMetas.map(x => x.ForecastId));
    }

    step.StepMetas = forecastIds.map(f => {
      step.forecastIds.push(f);
      const m = new AutoTaskStepMetaModel();
      m.ForecastId = f;
      return m;
    });
    this.task.Steps.forEach(s => s.stepOpen = false);
    this.task.Steps.push(step);
    this.generalOpen = false;
  }

  public removeStep(step: AutoTaskStepModel) {
    this.task.Steps.removeByKey('Step', step.Step);
    this.task.Steps.forEach((s, i) => s.Step = i + 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.task.Steps, event.previousIndex, event.currentIndex);
    this.task.Steps.forEach((s, i) => s.Step = i + 1);
  }
}
