import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
  selector: 'indicio-infinity-scroll',
  templateUrl: './infinity-scroll.component.html'
})
export class InfinityScrollComponent implements AfterViewInit, OnDestroy {

  @Input() container: string;

  @Output() visibleEvent = new EventEmitter();

  containerElement: HTMLElement;

  constructor(private ui: ElementRef) { }

  ngAfterViewInit() {
    if (this.container) {
      this.containerElement = document.querySelector(this.container);
    } else {
      this.containerElement = <any> window;
    }

    this.containerElement.addEventListener('scroll', this.scroll.bind(this));
  }

  ngOnDestroy() {
    this.containerElement.removeEventListener('scroll', this.scroll.bind(this));
  }

  scroll() {
    if (this.isVisible()) {
      this.visibleEvent.emit('visible');
    }
  }

  private isVisible() {
    const { bottom, height, top } = this.ui.nativeElement.getBoundingClientRect();
    const containerRect = this.containerElement.getBoundingClientRect();
    return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
  };
}
