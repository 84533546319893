import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';

@Component({
  selector: 'indicio-fvar-info-dialog-disaggregation-tab',
  templateUrl: './fvar-info-tab.disaggregation.component.html',
  styleUrls: ['./fvar-info-tab.disaggregation.component.less']
})
export class FVarInfoDialogDisaggregationTabComponent implements OnChanges {

  @Input() variable: ForecastVariableModel;

  public disaggPlots: ResultFileDTO[];
  public isLoading: boolean = true;

  constructor(private cd: ChangeDetectorRef) { }

  public ngOnChanges() {
    this.setup();
    this.cd.detectChanges();
  }

  private setup() {
    this.disaggPlots = this.variable.AggregationPlotImages.sort((a, b) => a.sortIndex - b.sortIndex);
    this.isLoading = false;
  }
}
