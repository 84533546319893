import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { CreateForecastVariableDTO } from '@core/store/forecast-variable/dtos/create-forecast-variable-dto';
import { ForecastActions } from '@core/store/forecast/forecast.actions';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { Transformations } from '@core/types/transformation.type';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Periodicity } from '@modules/lang/types/periodicity';
import { VariableTransformation } from '@modules/lang/types/variable-transformation';
import { Store } from '@ngxs/store';
import { OpenDeleteForecastVariableModal } from '@shared/modals/forecast-variable/delete-forecast-variable/delete-forecast-variable-modal.action';
import { Subscription } from 'rxjs';
import { FVarAdvancedSettings } from '../info/tabs/advanced/advanced-settings';

export class FVarEngineeringVariable {
  FvarId: string;
  Name: string;
  Transformation: string;
  Periodicity: Periodicity;
  CurrentFeatures?: Transformations.CurrentTransformInfo[];
  SourceVariableId: string;
  SourceVariableMetaId: string;
  SourceVariableName: string;
  AggregationMethodId: string;
}

export class FVarEngineeringMultiDialogData {
  forecastVersion: ForecastVersionModel;
  variableIds: string[];
}

@Component({
  selector: 'indicio-engineering-multi-fvar-dialog',
  templateUrl: './engineering-multi-fvar.dialog.html',
  styleUrls: ['./engineering-multi-fvar.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class FVarEngineeringMultiDialogComponent extends DialogV2BaseDialog<FVarEngineeringMultiDialogComponent> implements OnDestroy {
  subscriptions = new Subscription();


  public static Id: string = 'FVarEngineeringMultiDialogComponent';

  public faSave = faSave as IconProp;

  private variableIds: string[];
  public variables: FVarEngineeringVariable[] = [];
  public infos: { Status: string, Message: string; }[];
  public done: boolean = false;
  public doneCount: number = 0;

  public addNewFeatureOpen: boolean;
  public canAddFeature: boolean;
  /** Transformations already applied to the source variable. */
  public currentFeatures: Transformations.CurrentTransformInfo[];
  /** The new transformation to add (if user presses Add button). */
  public nextFeature: Transformations.FVarTrans;
  /** All selected, not yet applied, transformations. */
  public selectedFeatures: { trans: Transformations.FVarTrans, creating: number; }[] = [];
  public variableTransforms: VariableTransformation[];

  public get fVersion() { return this.data.forecastVersion; }
  public get availableFeatures() { return this.variableTransforms.filter(x => !this.selectedFeatures.some(f => f.trans.Transformation === x.Value)); }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: FVarEngineeringMultiDialogData,
    private actions: ActionService,
    private env: EnvironmentService,
    private variableDialogService: VariableDialogService,
    private store: Store,
    dialogRef: MatDialogRef<FVarEngineeringMultiDialogComponent>,
  ) {
    super(dialogRef);
    this.initialize();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected initialize() {
    this.variableIds = this.data.variableIds;
    this.variableTransforms = FVarAdvancedSettings.FilterVariableTransformations(this.fVersion.periodicity, this.env.VariableTransformations);

    const fcVariables = this.fVersion.getAllVariables().filter(x => !x.IsTrend && !x.IsMixedFreq && x.IsIndicator);

    let variables = fcVariables.filter(x => x.ValueTransform.Transformation === 'no_transform' && this.variableIds.includes(x.ForecastVariableId));

    const uniqueSourceIdsLeft = [...new Set(fcVariables
      .filter(x => !variables.find(y => y.SourceVariableId === x.SourceVariableId) && this.variableIds.includes(x.ForecastVariableId))
      .map(x => x.SourceVariableId))
    ];

    uniqueSourceIdsLeft.forEach(id => {
      const fvar = fcVariables.find(x => x.SourceVariableId === id);
      if (!fvar) { return; }
      variables.push(fvar);
    });

    // variables = variables.concat(fcVariables.filter(x => uniqueSourceIdsLeft.includes(x.SourceVariableId) && this.variableIds.includes(x.ForecastVariableId)));
    this.variables = variables.map(x => ({
      FvarId: x.ForecastVariableId,
      Periodicity: x.periodicity,
      Name: x.Name,
      Transformation: x.ValueTransform.Transformation,
      AggregationMethodId: x.AggregationMethodId,
      SourceVariableId: x.SourceVariableId,
      SourceVariableMetaId: x.SourceVariableMetaId,
      SourceVariableName: x.SourceVariableName
    }));



    this.variables.forEach(variable => {
      let vars = fcVariables.filter(x => x.SourceVariableId === variable.SourceVariableId);
      if (variable.Transformation === 'no_transform') {
        vars = vars.filter(x => x.ValueTransform.Transformation !== 'no_transform');
      }
      variable.CurrentFeatures = vars.map(x => ({
        Name: x.Name,
        Trans: x.ValueTransform.copy(),
        Display: this.variableTransforms.find(vt => vt.Value === x.ValueTransform.Transformation),
        FVarId: x.ForecastVariableId
      }));
    });

    this.currentFeatures = this.variables.map(x => x.CurrentFeatures).flat();
  }

  public selectFeature(trans: Transformations.FVarTrans) {
    this.nextFeature = trans;
  }

  public removeVariable(index: number) {
    this.variables.splice(index, 1)[0];
    this.updateCreatingNumber();
  }

  public removeFeature(index: number) {
    this.selectedFeatures.splice(index, 1)[0];
  }

  public addFeature() {
    const toAdd = this.nextFeature.copy();
    const t = this.env.VariableTransformations.find(x => x.Value === toAdd.Transformation);
    toAdd.Display = t.Display;

    this.selectedFeatures.push({ trans: toAdd, creating: 0 });
    this.addNewFeatureOpen = false;
    this.nextFeature = null;
    this.updateCreatingNumber();
  }

  private updateCreatingNumber() {
    this.selectedFeatures.forEach(selectedFeature => {
      selectedFeature.creating = this.variables
        .filter(variable => !variable.CurrentFeatures
          .find(variableFeature => variableFeature.Trans.compare(selectedFeature.trans)) && variable.Transformation !== selectedFeature.trans.Transformation).length;
    });
  }

  public saveFeatures() {
    const dtos: CreateForecastVariableDTO[] = [];

    this.selectedFeatures.forEach(selectedFeature => {
      this.variables.forEach(variable => {
        if (variable.CurrentFeatures.find(vFeature => vFeature.Trans.compare(selectedFeature.trans)) || variable.Transformation === selectedFeature.trans.Transformation) { return; }
        const newName = selectedFeature.trans.Transformation === 'no_transform' ? variable.SourceVariableName : (selectedFeature.trans.getShortName() + ': ' + variable.SourceVariableName);
        dtos.push(Object.assign(new CreateForecastVariableDTO(), <CreateForecastVariableDTO> {
          AggregationMethodId: variable.AggregationMethodId,
          IsExogenous: false,
          Name: newName,
          SourceValuesTransformation: selectedFeature.trans.copy(),
          SourceVariableMetaId: variable.SourceVariableMetaId
        }));
      });
    });

    this.subscriptions.add(this.actions.dispatched(ForecastActions.CreatedForecastVariable).subscribe((action: ForecastActions.CreatedForecastVariable) => {
      if (!this.variables.find(x => x.SourceVariableId === action.sourceVarId)) { return; }
      this.doneCount++;
      if (this.doneCount === this.variables.length) {
        this.close();
      }
    }));

    this.variableDialogService.openCreateMultiFVar({
      forecastVersion: this.fVersion,
      createDtos: dtos,
      displays: dtos.map(x => `${x.SourceValuesTransformation.Display} ${x.SourceValuesTransformation.getArgString()}`)
    });
  }

  public override close() {
    this.dialogRef.close();
  }

  public openVariableDialog(variable: FVarEngineeringVariable) {
    this.variableDialogService.openForecastVariableInfo({
      forecastId: this.fVersion.ForecastId,
      forecastVersionId: this.fVersion.ForecastVersionId,
      forecastVariableId: variable.FvarId,
      view: 5
    }).then(() => { this.initialize(); });
  }

  public removeFeatures() {
    this.close();
    const transformVariables = this.variables.map(x => x.CurrentFeatures).flat().map(x => x.FVarId);
    const varsToRemove = this.fVersion.getAllVariables().filter(x => transformVariables.includes(x.ForecastVariableId));
    this.store.dispatch(new OpenDeleteForecastVariableModal(varsToRemove));
  }
}
