import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ForecastResultDTO, ForecastResultModelDTO } from '@core/store/forecast-result/entities/forecast-result-dto';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastBenchmarkModel } from '@core/store/source-variable/forecast-benchmark.model';
import { StatTransformationModel } from '@core/store/stat-model/stat-model.model';
import { VarSelectResultModel } from '@core/store/var-select/var-select-result.model';
import { RemoteDataProviders } from '@modules/lang/language-files/source-types';
import { DateUtils } from '../date.utils';
import { ValueUtils } from '../value.utils';

export namespace ForecastUtils {

  //#region Forecast lag, horizon and test set sizes
  export class LagHorizonTestSizes {
    public Lag: number;
    public MaxLag: number;
    public Horizon: number;
    public MaxHorizon: number;
    public TestSetMin: number;
    public TestSetMax: number;
    public ObsCount: number;
  }

  export function getLagAndHorizon(lag: number, horizon: number, obsCount: number, rw: number, oos: boolean, prioritizeHorizon: boolean = true): LagHorizonTestSizes {
    if (oos) return getLagAndHorizonOOS(lag, horizon, obsCount, rw, prioritizeHorizon);
    else return getLagAndHorizonIS(lag, horizon, obsCount, prioritizeHorizon);
  }

  function getLagAndHorizonIS(lag: number, horizon: number, obsCount: number, prioritizeHorizon: boolean = true) {
    let maxHorizon: number;
    let maxLag: number;
    if (prioritizeHorizon) {
      maxHorizon = obsCount - 5;
      horizon = Math.min(horizon, maxHorizon);
      maxLag = Math.min(obsCount - horizon - 4, obsCount - 9);
      lag = Math.min(lag, maxLag);
    }
    else {
      maxLag = Math.min(obsCount - 5, obsCount - 9);
      lag = Math.min(lag, maxLag);
      maxHorizon = obsCount - lag - 4;
      horizon = Math.min(horizon, maxHorizon);
    }

    return {Lag: lag, MaxLag: maxLag, Horizon: horizon, MaxHorizon: maxHorizon, TestSetMin: 0, TestSetMax: 0, ObsCount: obsCount};
  }

  function getLagAndHorizonOOS(lag: number, horizon: number, obsCount: number, rw: number, prioritizeHorizon: boolean = true): LagHorizonTestSizes {
    let maxHorizon: number;
    let maxLag: number;
    if (prioritizeHorizon) {
      maxHorizon = getMaxHorizon(obsCount, 1, rw);
      horizon = Math.min(horizon, maxHorizon);
      maxLag = getMaxLag(obsCount, horizon, rw);
      lag = Math.min(lag, maxLag);
    }
    else {
      maxLag = getMaxLag(obsCount, 1, rw);
      lag = Math.min(lag, maxLag);
      maxHorizon = getMaxHorizon(obsCount, lag, rw);
      horizon = Math.min(horizon, maxHorizon);
    }
    const tSetMin = testSetMin(obsCount, horizon, rw);
    const tSetMax = testSetMax(obsCount, lag, rw);

    return {Lag: lag, MaxLag: maxLag, Horizon: horizon, MaxHorizon: maxHorizon, TestSetMin: tSetMin, TestSetMax: tSetMax, ObsCount: obsCount};
  }

  // Test set must be at least ~10% and be able to contain two past forecasts
  export function testSetMin(obsCount: number, horizon: number, rw: number) {
    const regularMin = Math.max(Math.floor(obsCount / 10), horizon + 1);
    return rw !== 0 ? horizon + 1 : regularMin;
  }

  // Test set must be at most ~50% and leave at least 9 observations after lagging
  export function testSetMax(obsCount: number, lag: number, rw: number) {
    const regularMax = Math.min(Math.floor(obsCount / 2), obsCount - 10 - lag);
    return rw !== 0 ? Math.min(rw * 12, regularMax) : regularMax;
  }

  // The horizon must allow two past forecasts to fit inside the test set
  function getMaxHorizon(obsCount: number, lag: number, rw: number) { return testSetMax(obsCount, lag, rw) - 1; }

  // The maximum lag order allowed needs to give a differenced training set leaving 8 lagged observations
  function getMaxLag(obsCount: number, horizon: number, rw: number) { return obsCount - testSetMin(obsCount, horizon, rw) - 10; }

  export function validateLagHorizonTest(x: LagHorizonTestSizes) {
    return x.Horizon >= 1 &&
      x.Lag >= 1 &&
      x.TestSetMin >= x.Horizon + 1 &&
      x.TestSetMax >= x.Horizon + 1 &&
      x.Lag <= x.ObsCount - x.TestSetMin - 10 &&
      x.Lag <= x.ObsCount - x.TestSetMax - 10 &&
      x.Horizon <= x.TestSetMin - 1 &&
      x.Horizon <= x.TestSetMax - 1 &&
      x.TestSetMin <= x.ObsCount - x.Lag - 10 &&
      x.TestSetMax <= x.ObsCount - x.Lag - 10;
  }

  //#endregion

  export function forecastedValueFilter(plotValue: PlotValueDTO) {
    return plotValue.IF;
  }

  export function mapForecastedValueToMBValue(plotValue: PlotValueDTO) {
    return {
      D: DateUtils.convertToBackendDate(plotValue.D),
      V: plotValue.V,
      A50: plotValue.A50,
      A75: plotValue.A75,
      A95: plotValue.A95,
      I50: plotValue.I50,
      I75: plotValue.I75,
      I95: plotValue.I95
    };
  }

  export function canBeUsedAsTemplateBase(fVersion: ForecastVersionModel, vsResult: VarSelectResultModel): string[] {
    const response: string[] = [];
    if (!fVersion.ForecastVariable) { response.push('Missing main variable.'); }
    if (fVersion.IndicatorVariables?.filter(i => i.Source !== 'trend').length < 2) { response.push('Requires at least two non-trend indicators.'); }
    if (!vsResult.ResultAvailable) { response.push('Indicator analysis results missing.'); }
    if (!fVersion.anyActiveIndicators) { response.push('At least one indicator must be set as active.'); }
    const allVars = fVersion.getAllVariables().filter(i => i.Source !== 'trend');

    if (allVars.some(fVar => !RemoteDataProviders.includes(fVar.Source))) {
      response.push(`One or more of the variables does not come from a data provider.
      E.g. uploaded files, sharepoint and forecast results are not
      supported.`);
    }

    if (allVars.some(x => x.IsExogenous)) {
      response.push('One or more of the variables are set as exogenous. This is not supported in forecast templates.');
    }

    return response;
  }

  export function filterByForecastVersion(benchmarks: ForecastBenchmarkModel[], forecastVersion: ForecastVersionModel) {
    const unit = forecastVersion.periodicity.getMomentDurationConstructor();
    const start = DateUtils.newMoment(forecastVersion.StartDate);
    const p = forecastVersion.periodicity;
    return benchmarks
      .filter(x => DateUtils.newMoment(x.firstDate()).isSame(start, unit))
      .filter(x => p.is(x.Periodicity))
      ;
  }

  export function getTopModels(models: StatTransformationModel[], measurement: string, count: number): StatTransformationModel[] {
    const countToUse = count !== 0
      ? models.length >= count ? count : models.length
      : models.length;

    const lowerCaseMeas = measurement.toLowerCase();
    const isStepwise = lowerCaseMeas.includes('stepwise');
    const stepCount = isStepwise
      ? models[0].RMSESteps.length
      : 1;

    switch (true) {
      default:
      case lowerCaseMeas.includes('rmse'):
        const rmseSorted = models.sort((a, b) => {
          const first = a.RMSESteps.slice(0, stepCount).reduce((x, y) => x += Math.abs(y.Value), 0);
          const second = b.RMSESteps.slice(0, stepCount).reduce((x, y) => x += Math.abs(y.Value), 0);
          return first > second ? 1 : first === second ? 0 : -1;
        });
        return rmseSorted.slice(0, countToUse);

      case lowerCaseMeas.includes('mape'):
        const mapeSorted = models.sort((a, b) => {
          const first = a.MAPESteps.slice(0, stepCount).reduce((x, y) => x += Math.abs(y.Value), 0);
          const second = b.MAPESteps.slice(0, stepCount).reduce((x, y) => x += Math.abs(y.Value), 0);
          return first > second ? 1 : first === second ? 0 : -1;
        });
        return mapeSorted.slice(0, countToUse);
    }
  }

  export function cloneForecastResult(result: ForecastResultDTO) {
    return <ForecastResultDTO> {
      ...result,
      Residuals: result.Residuals?.map(v => ValueUtils.copyValue(v)),
      FittedValues: result.FittedValues?.map(v => ValueUtils.copyValue(v)),
      Values: result.Values.map(v => ValueUtils.copyValue(v)),
      Models: result.Models.map(x => cloneForecastResultModel(x))
    };
  }

  export function cloneForecastResultModel(model: ForecastResultModelDTO) {
    return <ForecastResultModelDTO> {
      ...model,
      Weight: [...model.Weight],
    };
  }


}
