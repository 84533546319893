<indicio-dialog-base>
  <h1 mat-dialog-title
      style="margin-bottom: 0;">
    {{ edit ? 'Edit benchmark' : 'Create new benchmark' }}
  </h1>
  <div mat-dialog-content>
    <ng-container *ngIf="!isLoading else loadingTemplate">
      <ng-container *ngTemplateOutlet="benchmarkTemplate"></ng-container>
    </ng-container>
  </div>
  <ng-container *ngTemplateOutlet="buttonRowTemplate"></ng-container>
</indicio-dialog-base>

<ng-template #benchmarkTemplate>
  <indicio-modal-option-row optionTitle="Name"
                            optionSubtitle="Name of the benchmark"
                            textWidth="6"
                            optionsWidth="6"
                            style="margin-top: -10px">
    <mat-form-field style="margin-top: 15px;">
      <input type="text"
             class="material"
             matInput
             autocomplete="off"
             [(ngModel)]="benchmark.Name"
             name="Name"
             indicioAutoFocusInput
             maxlength="64"
             required>
    </mat-form-field>
    <mat-hint *ngIf="benchmark.Name?.length < 1">
      <span class="error-text">Name required</span>
    </mat-hint>
  </indicio-modal-option-row>

  <div class="row extra-margin-top">
    <div class="col-xs-12 strong">
      Benchmark
    </div>
  </div>

  <indicio-step-ahead style="display: block; margin-top: 15px; margin-bottom: 5px;"
                      [variable]="stepAhead"
                      [disabled]="false">
  </indicio-step-ahead>
  <button mat-stroked-button
          [indicioTippy]="'Reset the values to the previously saved state.'"
          [disabled]="!stepAhead.isModified()"
          type="button"
          (click)="restoreValues()">
    Undo changes
  </button>

  <div class="row extra-margin-top">
    <div class="col-xs-12 italic">
      A benchmark can be used to evaluate and compare forecasts from different providers.
    </div>
  </div>

</ng-template>

<ng-template #buttonRowTemplate>
  <div mat-dialog-actions>
    <button mat-stroked-button
            (click)="onNoClick()">
      Back
    </button>
    <button mat-flat-button
            color="primary"
            [disabled]="benchmark.Name?.length < 1"
            (click)="edit ? update() : create()">
      {{ edit ? 'Save' : 'Create'}}
    </button>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div style="position: relative; text-align: center">
    <i style="font-size:50px"
       class="ion-load-c spinner"></i>
    <div class="title">Loading information</div>
  </div>
</ng-template>
