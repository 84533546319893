import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { ModalModelComponent } from '@shared/modals/modal.model';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { HistoricBaseEventModel } from '@core/store/historic-event/models/base/historic-event.model';
import { ImportMultiHEventModalOptions } from './import-multiple-events-modal.options';

@Component({
  selector: 'indicio-import-multiple-events-modal',
  templateUrl: './import-multiple-events-modal.component.html',
  styleUrls: ['./import-multiple-events-modal.component.less'],
})
export class ImportHistoricEventsModalComponent extends ModalModelComponent {

  public events: HistoricBaseEventModel[] = [];
  public forecastVersion: ForecastVersionModel;
  public callback: any;

  public isLoading = true;

  constructor(
    protected store: Store,
    public envService: EnvironmentService,
    public clientService: ClientFrontendService,
    public appearance: AppearanceService
  ) { super(); }

  public setOptions(options: ImportMultiHEventModalOptions) {
    this.events = options.events;
    this.forecastVersion = options.forecastVersion;
    this.callback = options.callback;
    this.isLoading = false;
  }

  public importHistoricEvents() {
    // const eventsToBeImported = this.events;
    this.pending = true;

    // const promises: Promise<any>[] = [];
    // eventsToBeImported.forEach(v => {
    //   promises.push(this.service.addEventToForecast(v));
    // });
    // Promise.all(promises)
    //   .then(() => {
    //     if (this.callback) {
    //       this.callback();
    //     }
    //     this.close();
    //     this.status.setMessage('Event(s) imported', 'Success');
    //   })
    //   .catch(error => {
    //     this.status.setError(error, true);
    //   }).finally(() => {
    //     this.pending = false;
    //   });
  }
}
