<indicio-modal-base [child]="this"
                    modalTitle="Indicator analysis result"
                    [loading]=isLoading
                    modalClass="modal-content modal-content--wider"
                    bodyExtraClass="overflow-auto">

  <div class="vs-container">
    <ng-container *ngIf="!isLoading && result !== null">
      <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
      <ng-container [ngSwitch]="tab">
        <ng-container *ngSwitchCase="'influence'"
                      [ngTemplateOutlet]="influenceTemplate"></ng-container>
        <ng-container *ngSwitchCase="'rolling-window'"
                      [ngTemplateOutlet]="rollingWindowTemplate"></ng-container>
        <ng-container *ngSwitchCase="'equation'"
                      [ngTemplateOutlet]="equationTemplate"></ng-container>
        <ng-container *ngSwitchCase="'plots'"
                      [ngTemplateOutlet]="plotsTemplate"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</indicio-modal-base>

<ng-template #menuTemplate>
  <div class="modal-menu">
    <div class="menu-button"
         title="Influence result"
         [ngClass]="{ 'current': tab === 'influence' }"
         (click)="tab = 'influence'">
      <span>Influence</span>
    </div>
    <div class="menu-button"
         [title]="result.VSUseRollingWindow ? 'Rolling window result view' : 'Rolling window disabled, see forecast settings to change this'"
         [ngClass]="{ 'current': tab === 'rolling-window', 'disabled': !result.VSUseRollingWindow }"
         (click)="result.VSUseRollingWindow ? tab = 'rolling-window' : null">
      <span>Rolling window</span>
    </div>
    <div class="menu-button"
         title="Equations result view"
         [ngClass]="{ 'current': tab === 'equation' }"
         (click)="tab = 'equation'">
      <span>Equation</span>
    </div>
    <div class="menu-button"
         title="Different plots for indicator analysis"
         [ngClass]="{ 'current': tab === 'plots' }"
         (click)="tab = 'plots'">
      <span>Plots</span>
    </div>
  </div>
</ng-template>

<ng-template #influenceTemplate>
  <ng-container *ngIf="influenceView === 'Data'">
    <ng-container *ngIf="influenceData">
      <!-- Influence table -->
      <div class="row">
        <div class="col-xs-12 italic text-center extra-margin-top">Endogenous variables</div>
        <div class="col-xs-12">
          <table class="influence-table margin-top">
            <thead matSort
                   (matSortChange)="sortInfluenceCollection($event, influenceData)">
              <tr>
                <th mat-sort-header="name"
                    class="skip">Variable</th>
                <th [ngClass]="{ last: isLast && dataEndoLength >= 10, 'last-small': isLast && dataEndoLength < 10 }"
                    [mat-sort-header]="infStep.Title"
                    *ngFor="let infStep of influenceData[0].Data let i = index; let isLast = last">
                  {{ infStep.Title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let model of influenceData; let i = index">
                <td class="crop-string"
                    [title]="model.Name">{{ model.Name }}</td>
                <td *ngFor="let lag of model.Data; let i = index"
                    [ngClass]="{ 'grey': lag.Value === '0' }">
                  {{ lag.Value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="exoInfluenceData">
      <!-- Influence table -->
      <div class="row">
        <div class="col-xs-12 italic text-center extra-margin-top">Exogenous variables</div>
        <div class="col-xs-12">
          <table class="influence-table margin-top">
            <thead matSort
                   (matSortChange)="sortInfluenceCollection($event, exoInfluenceData)">
              <tr>
                <th mat-sort-header="name"
                    class="skip">Variable</th>
                <th [ngClass]="{ last: isLast && dataEndoLength >= 10, 'last-small': isLast && dataEndoLength < 10 }"
                    [mat-sort-header]="infStep.Title"
                    *ngFor="let infStep of exoInfluenceData[0].Data let i = index; let isLast = last">
                  {{ infStep.Title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let model of exoInfluenceData; let i = index">
                <td class="crop-string"
                    [title]="model.Name">{{ model.Name }}</td>
                <td *ngFor="let lag of model.Data; let i = index"
                    [ngClass]="{ 'grey': lag.Value === '0' }">
                  {{ lag.Value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #plotsTemplate>
  <ng-container *ngIf="plots.length > 0">
    <div class="row">
      <div class="col-xs-12 vs-plot-header">MSFE vs. Lasso penalty</div>
    </div>
    <indicio-plot-display [plots]="plots"
                          [plotWidth]="500"></indicio-plot-display>
  </ng-container>
</ng-template>

<ng-template #rollingWindowTemplate>
  <div class="row"
       [title]="'Measurement unit to plot'">
    <div class="col-xs-7">
      <div class="input-label bold">Measurement</div>
      <span class="subtitle">Measurement value to plot</span>
    </div>
    <div class="col-xs-5 margin-top">
      <mat-form-field>
        <mat-select [(value)]="selectedMeasurement"
                    [disabled]="validVSMeasurements.length === 1"
                    (selectionChange)="updateSLGData()">
          <mat-option [value]="meas.Value"
                      [title]="meas.Description"
                      *ngFor="let meas of validVSMeasurements">{{ meas.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row"
       *ngIf="slgOptions">
    <indicio-slg [options]="slgOptions">
    </indicio-slg>
  </div>

  <div class="row"
       *ngIf="slgOptions">
    <div class="col-xs-12">
      <div class="clearfix"
           style="padding: 5px 0; background-color: var(--indicio-modal-alt-background-color, #fcfcfc); border-radius: 4px; margin-bottom: 10px;">
        <div class="col-xs-4 pointer center-vertical"
             *ngFor="let model of availableModels"
             [title]="model.Name"
             (click)="toggleModel(model.ForecastVariableId)">
          <i class="font-size-20"
             [ngClass]=" modelSelected(model.ForecastVariableId) ? 'ion-ios-checkmark' : 'ion-ios-circle-outline'"
             [ngStyle]="{ color: model.color }"></i>
          <span class="margin-left-10 crop-string"
                [ngClass]="{ 'grey': !modelSelected(model.ForecastVariableId) }">{{ model.Name }}</span>
        </div>
        <ng-container *ngIf="result.VSMode === 'coef'">
          <div class="col-xs-4 pointer center-vertical"
               *ngFor="let model of result.ExoModels"
               [title]="model.name"
               (click)="toggleModel(model.ObjectId)">
            <i class="font-size-20"
               [ngClass]=" modelSelected(model.ObjectId) ? 'ion-ios-checkmark' : 'ion-ios-circle-outline'"
               [ngStyle]="{ color: model.color }"></i>
            <span class="margin-left-10 crop-string"
                  [ngClass]="{ 'grey': !modelSelected(model.ObjectId) }">{{ model.name }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #equationTemplate>
  <div class="row">
    <div class="col-xs-7">
      <span class="input-label bold">Variable</span>
      <div class="subtitle">Pick variable</div>
    </div>
    <div class="col-xs-5 margin-top">
      <mat-form-field>
        <mat-select [(value)]="selectedVariableId"
                    (selectionChange)="updateLagData();">
          <mat-option [value]="variable.Id"
                      [title]="variable.Name"
                      *ngFor="let variable of availableVariables">{{ variable.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row margin-top"
       *ngIf="selectedEquation">
    <div class="col-xs-12">
      <span class="bold">Equation</span>
      <div class="grey selectable-text"
           (click)="expandedEquation = largeEquation ? !expandedEquation : false"
           [ngClass]="{ 'crop-expand': largeEquation, 'crop-expand--expanded' : expandedEquation}">
        {{ selectedEquation }}</div>
    </div>
  </div>

  <!-- Lag tables -->
  <div class="row"
       *ngIf="endoLagData && !expandedEquation">
    <div class="col-xs-12 italic text-center margin-top">Endogenous variables</div>
    <div class="col-xs-12">
      <i class="ion-ios-arrow-back nav-arrow left-side"
         *ngIf="pageEndoLag > 1"
         (click)="changePage(-1)"></i>
      <i class="ion-ios-arrow-forward nav-arrow right-side"
         *ngIf="pageEndoLag <= 1 && pagesEndoRequired"
         (click)="changePage(1)"></i>
      <table class="influence-table margin-top">
        <thead matSort
               (matSortChange)="sortLagCollection($event, endoLagData)">
          <tr>
            <th mat-sort-header="name"
                class="skip">Variable</th>
            <th [ngClass]="{ last: isLast && dataEndoLength >= 10, 'last-small': isLast && dataEndoLength < 10 }"
                [mat-sort-header]="lagStep.LagName"
                *ngFor="let lagStep of getPageData(endoLagData[0].Data); let i = index; let isLast = last">
              {{ lagStep.LagName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let model of endoLagData; let i = index">
            <td class="crop-string"
                [title]="model.Name">{{ model.Name }}</td>
            <td *ngFor="let lag of getPageData(model.Data); let i = index"
                [ngClass]="{ 'grey': lag.Value === 0 }">
              {{ lag.Value }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row margin-bottom-10"
       *ngIf="exoLagData && !expandedEquation">
    <div class="col-xs-12 italic text-center extra-margin-top">Exogenous variables</div>
    <div class="col-xs-12">
      <i class="ion-ios-arrow-back nav-arrow left-side"
         *ngIf="pageExoLag > 1"
         (click)="changePage(-1, 'exo')"></i>
      <i class="ion-ios-arrow-forward nav-arrow right-side"
         *ngIf="pageExoLag <= 1 && pagesExoRequired"
         (click)="changePage(1, 'exo')"></i>
      <table class="influence-table margin-top">
        <thead matSort
               (matSortChange)="sortLagCollection($event, exoLagData, 'exo')">
          <tr>
            <th mat-sort-header="name"
                class="skip">Variable</th>
            <th [ngClass]="{ last: isLast && dataExoLength >= 10, 'last-small': isLast && dataExoLength < 10 }"
                [mat-sort-header]="lagStep.LagName"
                *ngFor="let lagStep of getPageData(exoLagData[0].Data, 'exo'); let i = index; let isLast = last">
              {{ lagStep.LagName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let model of exoLagData; let i = index">
            <td class="crop-string"
                [title]="model.Name">{{ model.Name }}</td>
            <td *ngFor="let lag of getPageData(model.Data, 'exo'); let i = index"
                [ngClass]="{ 'grey': lag.Value === 0 }">
              {{ lag.Value }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
