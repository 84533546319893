<indicio-modal-base [child]="this"
                    modalTitle="Start date warning"
                    [loading]="isLoading">

  <div class="row"
       *ngIf="!isLoading">
    <div class="col-xs-12"
         style="margin: 20px 0 20px 0; font-weight: 500;">Main variable values exists after the forecast start date
    </div>
    <div class="separator_modal"></div>
    <div class="col-xs-6">Current first forecasted point: </div>
    <div class="col-xs-6 text-right"> {{ currentStartDate | dateFormat: variable.Periodicity }} </div>
    <div class="col-xs-6">Main variable last date</div>
    <div class="col-xs-6 text-right">{{ variableLastDate | dateFormat: variable.Periodicity }}</div>
    <div class="separator_modal"></div>
    <div class="col-xs-12">Suggested change</div>
    <div class="col-xs-6">Set first forecasted point to: </div>
    <div class="col-xs-6 text-right">{{ suggestedStartDate | dateFormat: variable.Periodicity }}</div>
    <div class="separator_modal"></div>
    <div class="col-xs-12 extra-margin-top">
      NOTE: Keeping the current settings will cut the variable to align with the forecast start date.
    </div>
    <div class="col-xs-12"
         style="margin: 40px 0 10px 0;">
      Click change below to accept this change.
    </div>
  </div>
  <!-- Buttons -->
  <div class="row">
    <div class="col-xs-6">
      <button class="btn outline blue left"
              type="button"
              (click)="back()">Cancel</button>
    </div>
    <div class="col-xs-6">
      <button class="btn right"
              (click)="change()">
        <span *ngIf="!pending">Change</span>
        <i *ngIf="pending"
           class="ion-load-c spinner"></i>
      </button>
    </div>
  </div>
</indicio-modal-base>
