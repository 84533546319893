<h1 mat-dialog-title>Manage assets</h1>
<div mat-dialog-content>
  <ng-container *ngTemplateOutlet="fileInputTemplate"></ng-container>

  <hr style="margin: 10px 0;">

  <ng-container *ngIf="!initLoading; else loadingTemplate">
    <ng-container *ngTemplateOutlet="imagesTemplate"></ng-container>
  </ng-container>


</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-stroked-button
          (click)="close()">
    Close
  </button>
</div>

<ng-template #imagesTemplate>
  <mat-form-field>
    <mat-label>Filter list</mat-label>
    <input type="text"
           matInput
           required
           (keyup)="filter($any($event.target).value)"
           #filterInput
           class="material">
    <div mat-button
         *ngIf="filterInput.value"
         matSuffix
         mat-icon-button
         aria-label="Clear"
         (click)="filterInput.value=''; filterRegex = null">
      <mat-icon><i class="ion-close cursor-pointer"></i></mat-icon>
    </div>
  </mat-form-field>
  <div class="image-container">
    <div class="grid-system"
         style="--grid-template: 1fr 100px 100px 50px">
      <div class="bold">Filename</div>
      <div class="bold">Size</div>
      <div class="bold">Uploaded</div>
    </div>
    <div class="grid-system hover"
         style="--grid-template: 1fr 100px 100px 50px"
         *ngFor="let asset of wikiService.assets | filter: 'Name': filterRegex">
      <div class="crop-string cursor-pointer"
           (click)="useAsset(asset)">{{ asset.Name }}</div>
      <div class="crop-string">{{ getFormattedSize(asset.Size) }}</div>
      <div class="crop-string">{{ asset.CreatedDate | dateFormat}}</div>
      <div class="crop-string text-right">
        <i class="ion-eye cursor-pointer margin-right-10"
           (click)="viewAsset(asset)"></i>
        <i class="ion-trash-a cursor-pointer red"
           (click)="askRemoveAsset(asset)"></i>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #fileInputTemplate>
  <div>
    <input type="file"
           accept=".png, .svg, .jpg, .jpeg, .gif, .webp"
           id="file"
           name="file"
           multiple
           #fileinput
           (change)="fileChange(fileinput.files)">
    <div (dragover)="allowDrop($event)"
         (dragenter)="toggleDragOver(true)"
         (dragleave)="toggleDragOver(false)"
         (drop)="drop($event)"
         data-test-id="drag-and-drop-box"
         (click)="fileinput.click()"
         class="drag-and-drop-box">
      <p><i class="ion-upload"></i></p>
      <p>Click to browse files or drag them here.</p>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  Loading assets...
</ng-template>
