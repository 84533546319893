<mat-form-field *ngIf="values?.length"
                [class]="dropdownClass">
  <mat-label style="text-transform: capitalize"
             *ngIf="label">{{ label }}</mat-label>
  <mat-select [formControl]="mainControl"
              [multiple]="true"
              [required]="required">
    <mat-option *ngIf="values.length > 4">
      <ngx-mat-select-search placeholderLabel="Search"
                             noEntriesFoundLabel="No search results"
                             [formControl]="searchControl"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let dim of filteredValues"
                [title]="!!optionTitle && dim[optionTitle] ? dim[optionTitle] : ''"
                [value]="dim">
      {{ !!optionDisplay ? dim[optionDisplay] : dim }}
    </mat-option>
  </mat-select>
</mat-form-field>
