import { Def } from '@core/decorators/def';

export class VariableSettingsDTO {
  @Def() CalculateOutliers: boolean;
  @Def() CalculateSeasonal: boolean;
  @Def() IsExogenous: boolean;
  @Def() Active: boolean;
  @Def() ActiveMF: boolean;
  @Def() Name: string;
  @Def() Transformation: string;
  @Def() SourceValuesTransformation: { Transformation: string, Arguments: string; };
  @Def() ExogenousFutureValues: number[];
  @Def() MultivariateTransform: string;
  @Def() ScenarioTransform: string;
}
