import { Injectable } from '@angular/core';
import { LanguageService } from '@modules/lang/language.service';
import { Store } from '@ngxs/store';
import { DateUtils } from '@shared/utils/date.utils';
import { AutoTaskState } from './auto-task.state';
import {
  AutoTaskDTO,
  AutoTaskInfoDTO,
  AutoTaskRunResultDTO,
  AutoTaskStepDTO,
  AutoTaskStepMetaDTO,
  AutoTaskStepResultDTO,
  CreateAutoTaskDTO
} from './dtos/auto-task.dto';
import {
  AutoTaskInfoModel,
  AutoTaskModel,
  AutoTaskRunResultModel,
  AutoTaskStepMetaModel,
  AutoTaskStepModel,
  AutoTaskStepResultModel
} from './dtos/auto-task.model';

@Injectable({
  providedIn: 'root'
})
export class AutoTaskMapper {

  public get autoTaskInfo() { return this.store.selectSnapshot(AutoTaskState.autoTaskInfo); }

  constructor(
    private store: Store,
    private language: LanguageService
  ) {
  }

  /**
   *
   * Public mapper functions
   *
   */
  public mapTask(dto: AutoTaskDTO) {
    const model = Object.faMapTo(new AutoTaskModel, dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.LastRunAt = DateUtils.newNullableDate(dto.LastRunAt);
    model.History = dto.History.map(h => this.mapHistory(h));
    model.Steps = dto.Steps.map(s => this.mapStep(s));
    model.Steps.sort((a, b) => a.Step - b.Step);

    if (model.State === 0 && model.History?.length) {
      model.State = model.History[0].ExitSuccess ? 3 : (model.History[0].CompletedAt ? 2 : 1);
    }

    return model;
  }

  public mapInfo(dto: AutoTaskInfoDTO) {
    const model = new AutoTaskInfoModel();
    model.StepTypes = this.language.mapEntries(dto.StepTypes, this.language.automationSteps);
    model.TriggerOnTypes = this.language.mapEntries(dto.TriggerOnTypes, this.language.automationTriggerOns);
    model.TriggerTypes = this.language.mapEntries(dto.TriggerTypes, this.language.automationTriggers);
    model.OnEventTypes = this.language.mapEntries(dto.OnEventTypes, this.language.automationTriggerOnEvents);
    model.EmailTriggerOnTypes = this.language.mapEntries(dto.EmailTriggerOnTypes, this.language.automationEmailTriggers);
    return model;
  }

  public toCreate(model: AutoTaskModel) {
    const dto = new CreateAutoTaskDTO();
    dto.CompanyId = model.CompanyId;
    dto.Description = model.Description;
    dto.Interval = model.Interval;
    dto.Name = model.Name;
    dto.TriggerAtDay = model.TriggerAtDay;
    dto.TriggerAtHour = model.TriggerAtHour;
    dto.TriggerOn = model.TriggerOn;
    dto.TriggerType = model.TriggerType;
    dto.OnEventType = model.OnEventType;
    dto.Steps = model.Steps.map(s => this.stepDto(s));
    dto.Active = model.Active;
    return dto;
  }

  public toUpdate(model: AutoTaskModel) {
    const dto = <AutoTaskDTO> this.toCreate(model);
    dto.AutoTaskId = model.AutoTaskId;
    return dto;
  }


  mapTaskRunResult(dto: AutoTaskRunResultDTO): any {
    const model = Object.faMapTo(new AutoTaskRunResultModel, dto);
    return model;
  }


  /**
   *
   * Private helpers
   *
   */

  //
  // dto -> model
  //
  private mapHistory(dto: AutoTaskRunResultDTO): AutoTaskRunResultModel {
    const model = Object.faMapTo(new AutoTaskRunResultModel, dto);
    model.CompletedAt = DateUtils.newNullableDate(dto.CompletedAt);
    model.StartedAt = DateUtils.newDate(dto.StartedAt);
    model.StepResults = dto.StepResults.map(s => this.mapStepResult(s)).sort((a, b) => a.Step - b.Step);
    return model;
  }

  private mapStepResult(dto: AutoTaskStepResultDTO): AutoTaskStepResultModel {
    const model = Object.faMapTo(new AutoTaskStepResultModel, dto);
    model.type = this.autoTaskInfo.StepTypes.find(s => s.Value === dto.Type);
    return model;
  }

  private mapStep(dto: AutoTaskStepDTO): AutoTaskStepModel {
    const model = Object.faMapTo(new AutoTaskStepModel, dto);
    model.StepMetas = dto.StepMetas.map(s => this.mapStepMeta(s));
    model.forecastIds = model.StepMetas.map(s => s.ForecastId);
    model.stepType = this.autoTaskInfo.StepTypes.find(s => s.Value === dto.StepType);
    return model;
  }

  private mapStepMeta(dto: AutoTaskStepMetaDTO): AutoTaskStepMetaModel {
    const model = Object.faMapTo(new AutoTaskStepMetaModel, dto);
    return model;
  }

  //
  // model -> dto
  //
  private stepDto(step: AutoTaskStepModel) {
    const dto = new AutoTaskStepDTO();
    dto.Step = step.Step;
    dto.AutoTaskStepId = step.AutoTaskStepId;
    dto.StepType = step.StepType;
    dto.TargetForecastId = step.TargetForecastId;
    dto.StepMetas = step.StepMetas.map(m => this.stepMetaDto(m));
    dto.SendWhen = step.SendWhen;
    return dto;
  }

  private stepMetaDto(meta: AutoTaskStepMetaModel) {
    const dto = new AutoTaskStepMetaDTO();
    dto.AutoTaskStepMetaId = meta.AutoTaskStepMetaId;
    dto.Email = meta.Email;
    dto.ForecastId = meta.ForecastId;
    return dto;
  }
}


