import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { FigiResultDTO } from '@core/store/providers/bloomberg/entities/bloomberg-requests.dtos';
import { BloombergAddCredentialsDialogComponent } from '@modules/dialog/dialogs/providers/bloomberg/add-credentials/add-credentials.dialog';
import { BloombergRequestInfoDialogComponent } from '@modules/dialog/dialogs/providers/bloomberg/request-info/request-info.dialog';
import { ImportProviderDialogComponent, ImportProviderDialogData } from '@shared/modules/dialogs/providers/import-provider/import-provider.dialog';
import { take } from 'rxjs/operators';
import { DialogV2BaseService } from '../base/dialogs.V2.base-service';
import { BloombergCreateRequestDialogComponent } from './bloomberg/create-request/create-request.dialog';
import { BloombergCreateRequestDialogData } from './bloomberg/create-request/create-request.dialog.constants';
import { BloombergManualTickerInputDialogComponent, BloombergManualTickerInputDialogData } from './bloomberg/manual-ticker/manual-ticker.dialog';
import { BloombergRequestInfoDialogData } from './bloomberg/request-info/request-info.dialog.constants';
import { MacrobondImportMultipleDialogComponent } from './macrobond/import-multiple/import-multiple.dialog';
import { MacrobondImportMultipleDialogData } from './macrobond/import-multiple/import-multiple.dialog.constants';
import { MacrobondImportPasteDialogComponent } from './macrobond/import-paste/import-paste.dialog';
import { MacrobondImportPasteDialogData } from './macrobond/import-paste/import-paste.dialog.constants';



@Injectable({ providedIn: 'root' })
export class ProviderDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openBloombergManualTickerDialog(data: BloombergManualTickerInputDialogData) {
    const config = this.getConfig(BloombergManualTickerInputDialogComponent.Id, data, { position: { top: '7%' } });
    const dialogRef = this.dialog.open<BloombergManualTickerInputDialogComponent, any, FigiResultDTO>(BloombergManualTickerInputDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openBloombergRequestInfoDialog(data: BloombergRequestInfoDialogData) {
    const config = this.getConfig(BloombergRequestInfoDialogComponent.Id, data);
    const dialogRef = this.dialog.open<BloombergRequestInfoDialogComponent, any, boolean>(BloombergRequestInfoDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openBloombergCreateRequestDialog(data: BloombergCreateRequestDialogData) {
    const config = this.getConfig(BloombergCreateRequestDialogComponent.Id, data, { width: '600px' });
    const dialogRef = this.dialog.open<BloombergCreateRequestDialogComponent, any, boolean>(BloombergCreateRequestDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openMacrobondPasteImportDialog(data: MacrobondImportPasteDialogData) {
    const config = this.getConfig(MacrobondImportPasteDialogComponent.Id, data, { width: '500px' });
    const dialogRef = this.dialog.open<MacrobondImportPasteDialogComponent, any, boolean>(MacrobondImportPasteDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openMacrobondImportMultipleDialog(data: MacrobondImportMultipleDialogData) {
    const config = this.getConfig(MacrobondImportMultipleDialogComponent.Id, data, { width: '950px' });
    const dialogRef = this.dialog.open<MacrobondImportMultipleDialogComponent, any, boolean>(MacrobondImportMultipleDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  // TODO: Move this dialog to the new structure, this references the old one.
  public openImportProviderDialog(data: ImportProviderDialogData) {
    const config = this.getConfig(ImportProviderDialogComponent.Id, data, { width: '750px', position: { top: '4%' } });
    const dialogRef = this.dialog.open(ImportProviderDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openBloombergAddCredentialsDialog() {
    const config = this.getConfig(BloombergAddCredentialsDialogComponent.Id, null, { width: '750px', position: { top: '4%' } });
    const dialogRef = this.dialog.open<BloombergAddCredentialsDialogComponent, any, boolean>(BloombergAddCredentialsDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

}
