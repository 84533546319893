import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { RemovalWarningsFrontendService } from '@core/services/removal-warnings/removal-warnings.frontend.service';
import { StatusService } from '@core/services/status/status.service';
import { UpdateForecastVersionDTO } from '@core/store/forecast/dtos/forecast-version/update-forecast-version-dto';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProjectModel } from '@core/store/project/project.model';
import { ScriptQueueFrontendService } from '@core/store/script-queue/script-queue.frontend.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

@Component({
  selector: 'indicio-fcast-dialog-version-tab',
  templateUrl: './fcast-tab.version.component.html',
  styleUrls: ['./fcast-tab.version.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FCastSettingsDialogVersionTabComponent implements OnChanges {

  @Input() forecast: ForecastModel;
  @Input() forecastVersion: ForecastVersionModel;
  @Input() project: ProjectModel;
  @Input() canUpdateForecast: boolean;
  @Input() trigger: boolean[];

  @Output() closeDialogEvent = new EventEmitter();


  // Settings
  public alphaIndex: number;
  public newAlphaIndex: number;
  public pending: boolean;
  public newSettings: Partial<UpdateForecastVersionDTO> = {
    EnableMAPE: null,
    EnableShap: null,
    EnableMFM: null,
    ModelThresholdMAPE: null,
    MaxLag: null,
    Alpha: null,
  };

  public get alphaOptions() { return this.envService.alphaOptions; }

  public get changed() {
    return this.newSettings.EnableMAPE !== this.forecastVersion.EnableMAPE
      || this.newSettings.EnableShap !== this.forecastVersion.EnableShap
      || this.newSettings.EnableMFM !== this.forecastVersion.EnableMFM
      || this.newSettings.ModelThresholdMAPE !== this.forecastVersion.ModelThresholdMAPE
      || this.newSettings.MaxLag !== this.forecastVersion.MaxLag
      || this.alphaIndex !== this.newAlphaIndex;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private forecastService: ForecastFrontendService,
    private status: StatusService,
    private dialogService: DialogService,
    private sc: ScriptQueueFrontendService,
    private removalService: RemovalWarningsFrontendService,
    public envService: EnvironmentService
  ) {
  }

  public ngOnChanges() {
    // Set new settings
    this.setDefaults();

    this.cd.detectChanges();
  }

  public newActiveVersion(version: ForecastVersionModel) {
    this.forecastService.setForecastVersion(version)
      .then(_newV => {
        this.setDefaults();
      });
  }

  public deleteForecastVersion(version: ForecastVersionModel) {
    if (this.sc.getQueueByForecastVersionId(this.forecastVersion.ForecastVersionId).Entries.length > 0) {
      this.status.setMessage('Wait for all calculations to finish before removing the version', 'Warning', true);
      return;
    }

    const ref = this.dialogService.openConfirmDialog({
      Title: 'Remove forecast version?',
      Message: 'Are you sure you want to remove this forecast version?',
      ConfirmText: 'Remove',
      Style: 'warn',
      WarningCheck: this.removalService.getRemovalWarningsForForecastVersion(this.forecastVersion.ForecastVersionId),
      ExtraWarning: 'WARNING: This forecast version or its results are in use'
    }, { width: '522px' });

    ref.subscribe((proceed: boolean) => {
      if (!proceed) { return; }
      this.forecastService.deleteVersion(version)
        .then(_forecast => {
          this.forecastService.setForecastVersion(this.forecast.getLatestVersion());
          this.pending = false;
          this.status.setMessage('Forecast version removed successfully.', 'Success', true);
        }).catch(error => {
          this.pending = false;
          this.status.setError(error, true);
        });
    });
  }

  private setDefaults() {
    this.newSettings.EnableMAPE = this.forecastVersion.EnableMAPE;
    this.newSettings.EnableShap = this.forecastVersion.EnableShap;
    this.newSettings.EnableMFM = this.forecastVersion.EnableMFM;
    this.newSettings.ModelThresholdMAPE = this.forecastVersion.ModelThresholdMAPE;
    this.newSettings.MaxLag = this.forecastVersion.MaxLag;
    this.alphaIndex = this.alphaOptions.findIndex(x => x === this.forecastVersion.Alpha);
    this.newSettings.Alpha = this.alphaOptions[this.alphaIndex];
    this.newAlphaIndex = this.alphaIndex;
  }

  public toggleEnableMape() {
    this.newSettings.EnableMAPE = !this.newSettings.EnableMAPE;
  }

  public toggleEnableShap() {
    this.newSettings.EnableShap = !this.newSettings.EnableShap;
  }

  public toggleEnableMFM() {
    this.newSettings.EnableMFM = !this.newSettings.EnableMFM;
  }

  public setAlpha($event: number) {
    this.newAlphaIndex = $event;
    this.newSettings.Alpha = this.alphaOptions[$event];
  }

  public async save() {
    if (!this.changed) { return Promise.resolve(); }
    this.pending = true;
    this.forecastService.updateForecastVersion(this.forecastVersion, this.newSettings)
      .then(fversion => {
        this.setDefaults();
        this.status.setMessage('Forecast version successfully updated.', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
      })
      .finally(() => {
        this.pending = false;
      });
  }
}
