<indicio-modal-base [child]="this"
                    modalTitle="Select Company"
                    [onBackdropClose]="onClose"
                    [hideCloseButton]="true"
                    [loading]=isLoading
                    modalExtraClass="small"
                    extraClasses="small">

  <div *ngIf="!isLoading">
    <div class="row">
      <div class="col-xs-12">Select the company you wish to work with.</div>
    </div>
    <form #form="ngForm"
          (keydown.enter)="proceed()"
          (ngSubmit)="proceed()">
      <div class="row">
        <div class="col-xs-12">

          <mat-form-field>
            <mat-select [(value)]="companyId"
                        (selectionChange)="setActiveCompany($event)"
                        required>
              <mat-option [value]="company.CompanyId"
                          [title]="company.Name"
                          *ngFor="let company of companies">{{ company.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
      <div class="row center-vertical">
        <div class="col-xs-7">
        </div>
        <div class="col-xs-5">
          <button class="btn pull-right"
                  data-test-id="select-company-modal-accept-button"
                  type="submit">
            Select
          </button>
        </div>
      </div>
    </form>
  </div>
</indicio-modal-base>
