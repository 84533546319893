import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { NewsPostModel } from '@core/store/news/news-post.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { OpenEditNewsModal } from '@shared/modals/news/edit-news/edit-news-modal.actions';

export class NewsArticleDialogData {
  public article: NewsPostModel;
}

@Component({
  selector: 'indicio-news-article-dialog',
  templateUrl: 'news-article.dialog.html',
})
export class NewsArticleDialogComponent {
  public static Id: string = 'NewsArticleDialogComponent';

  public article: NewsPostModel;
  public canEdit: boolean;

  constructor(
    public dialogRef: MatDialogRef<NewsArticleDialogComponent>,
    public appearance: AppearanceService,
    private authService: AuthFrontendService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: NewsArticleDialogData) {
    this.article = data.article;
    this.canEdit = this.authService.hasPermission('CAN_UPDATE_NEWSPOSTS');
  }

  public editPost() {
    this.dialogRef.close();
    this.store.dispatch(new OpenEditNewsModal(this.article));
  }

}
