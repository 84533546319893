import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[indicioHoverRows]'
})
export class HoverRowsDirective implements AfterViewInit, OnDestroy {

  private dom: HTMLElement = null;
  private id: string;

  constructor(ui: ElementRef) {
    this.dom = ui.nativeElement;
    if (!this.dom.getAttribute('id')) {
      this.id = `hover-rows-${Math.random().toString(36).substring(7)}`;
    } else {
      this.id = this.dom.getAttribute('id');
    }
    this.dom.setAttribute('id', this.id);
  }

  ngAfterViewInit() {
    setTimeout(() => this.setup(), 0);
  }

  public setup() {
    const onMouseOver = this.onMouseOver.bind(this);
    const onMouseOut = this.onMouseOut.bind(this);

    Array.from(document.querySelectorAll(`#${this.id} [data-row]`))
      .forEach(elem => {
        elem.addEventListener('mouseover', onMouseOver, false);
        elem.addEventListener('mouseout', onMouseOut, false);
      });
  }

  ngOnDestroy() {
    Array.from(document.querySelectorAll(`#${this.id} [data-row]`))
      .forEach(elem => {
        elem.removeEventListener('mouseover', this.onMouseOver.bind(this));
        elem.removeEventListener('mouseout', this.onMouseOut.bind(this));
      });
  }

  onMouseOver(event) {
    if (event == null || event.composedPath()?.length === 0) { return; }
    const hoverRow = this.findRow(event.composedPath());
    if (!hoverRow) { return; }
    Array.from(document.querySelectorAll(`#${this.id} [data-row='${hoverRow}']`))
      .forEach(elem => {
        elem.classList.add('active');
      });
  }

  onMouseOut(event) {
    if (event == null || event.composedPath()?.length === 0) { return; }
    const hoverRow = this.findRow(event.composedPath());
    if (!hoverRow) { return; }
    Array.from(document.querySelectorAll(`#${this.id} [data-row='${hoverRow}']`))
      .forEach(elem => {
        elem.classList.remove('active');
      });
  }

  findRow(path: any[]) {
    if (path == null || path.length === 0) { return; }
    let match;
    for (let i = 0; i < path.length; i++) {
      const elem: HTMLElement = path[i];
      if (!elem) { break; }
      if (elem.getAttribute('data-row') !== null) {
        match = elem.getAttribute('data-row');
        break;
      }
    }
    return match;
  }
}
