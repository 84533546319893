import { Def } from '@core/decorators/def';

export class SaveNotificationSettingsDTO {
  @Def() Variables: { [key: string]: boolean; };
  @Def() HistoricEvents: { [key: string]: boolean; };
  @Def() Assessments: { [key: string]: boolean; };
  @Def() Files: { [key: string]: boolean; };
  @Def() Forecast: { [key: string]: boolean; };
  @Def() Company: { [key: string]: boolean; };
  @Def() Project: { [key: string]: boolean; };
  @Def() HideSystem: boolean;
}
