import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { HttpService } from '@core/services/http/http.service';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { SourceVariableFrontendService } from '../source-variable/source-variable.frontend.service';
import { CreateForecastResultDTO, ForecastResultDTO } from './entities/forecast-result-dto';
import { ForecastResultModel } from './entities/forecast-result.model';
import { ForecastResultActions } from './forecast-result.actions';
import { ForecastResultMapper } from './forecast-result.mapper';


@Injectable({
  providedIn: 'root'
})
export class ForecastResultService {

  constructor(
    private mapper: ForecastResultMapper,
    private svService: SourceVariableFrontendService,
    private http: HttpService,
    private store: Store,
    private dialogService: DialogService,
    private actions: ActionService
  ) {
    this.setupSubscriptions();
  }

  public saveForecastResult(resultDto: CreateForecastResultDTO) {
    return this.http.post<ForecastResultDTO>(`forecast/version/${resultDto.ForecastVersionId}/results/`, resultDto)
      .then(res => this.mapper.mapForecastResultModel(res.body))
      .then(savedResult => {
        this.store.dispatch(new ForecastResultActions.SavedForecastResult(savedResult));
        this.svService.fetchAllInCompany();
        return savedResult;
      });
  }

  public deleteForecastResult(result: ForecastResultModel) {
    const ref = this.dialogService.openConfirmDialog({
      Title: 'Delete Forecast Result',
      Message: `Are you sure you want to delete this forecast result?
              <br>
              <br>
              Result name: ${result.Name}`,
      ConfirmText: 'Yes, remove it',
      Style: 'warn',
    }, { width: '500px' }).toPromise();

    return ref.then(confirmed => {
      if (!confirmed) { return; }
      return this.http.delete(`forecast/version/${result.ForecastVersionId}/results/${result.ForecastResultId}`)
        .then(() => { this.store.dispatch(new ForecastResultActions.RemovedForecastResult(result.ForecastResultId)); return true; });
    });
  }

  public getForecastResult(forecastVersionId: string, resultId: string) {
    return this.http.get<ForecastResultDTO>(`forecast/version/${forecastVersionId}/results/${resultId}`)
      .then(resp => {
        const result = this.mapper.mapForecastResultModel(resp.body);
        this.store.dispatch(new ForecastResultActions.SavedForecastResult(result));
        return result;
      });
  }

  public getAllForecastResults(forecastVersionId: string) {
    return this.http.get<ForecastResultDTO[]>(`forecast/version/${forecastVersionId}/results`)
      .then(resp => resp.body.map(r => this.mapper.mapForecastResultModel(r)));
  }

  private setupSubscriptions() {
    this.actions.dispatched(ForecastResultActions.NewForecastResult).subscribe((ev: ForecastResultActions.NewForecastResult) => {
      this.getForecastResult(ev.forecastVersionId, ev.forecastResultId);
    });
  }
}
