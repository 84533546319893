import { Injectable } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';
import { QuandlDatabaseDTO } from './dtos/quandl-database.dto';
import { QuandlDataSerieDTO } from './dtos/quandl-dataserie.dto';
import { QuandlSearchDTO } from './dtos/quandl-search.dto';
import { QuandlDatabaseModel } from './quandl-database.model';
import { QuandlDataSerieModel } from './quandl-dataserie.model';
import { QuandleDatasetModel } from './quandl-dataset.model';
import { QuandlSearchModel } from './quandl-search.model';


@Injectable({
  providedIn: 'root'
})
export class QuandlMapper {

  public mapSearch(dto: QuandlSearchDTO) {
    const model = new QuandlSearchModel;
    let datasets = [];

    if (dto.datasets.length) {
      datasets = dto.datasets.map(dataset => {
        const dmodel = Object.faMapTo(new QuandleDatasetModel, dataset);
        dmodel.frequency = this._mapFrequency(dmodel.frequency);
        dmodel.oldData = DateUtils.isOldData(dmodel.newest_available_date, dmodel.frequency);
        return dmodel;
      });
    }

    model.datasets = datasets;
    model.meta = dto.meta;

    return model;
  }

  public mapDatabase(dto: QuandlDatabaseDTO) {
    const model = Object.faMapTo(new QuandlDatabaseModel, dto);
    return model;
  }

  public mapSerie(dto: QuandlDataSerieDTO) {
    const model = Object.faMapTo(new QuandlDataSerieModel, dto);
    model.dataset_data = dto.dataset_data;
    return model;
  }

  private _mapFrequency(frequency: string) {
    switch (frequency) {
      case 'daily': {
        return 'day';
      }
      case 'weekly': {
        return 'week';
      }
      case 'monthly': {
        return 'month';
      }
      case 'quarterly': {
        return 'quarter';
      }
      case 'annual': {
        return 'year';
      }
      default: {
        return frequency;
      }
    }
  }
}
