import { ForecastResultUsedWarning, ForecastUsedInReportWarning, RemovalWarning } from './removal-warning';
import { RemovalWarningType } from './removal-warning.dto';

export class RemovalWarningGroup {
  Type: RemovalWarningType;
  Warnings: RemovalWarning<ForecastResultUsedWarning | ForecastUsedInReportWarning>[];

  public get Title() {
    switch (this.Type) {
      case RemovalWarningType.FORECAST_RESULT_USED:
        return 'Forecast(s) using result(s)';
      case RemovalWarningType.FORECAST_USED_IN_REPORT:
        return 'Report(s)';
    }
  }
}
