import { ThemeOptionType } from '@core/constants/theme-options';
import { ProfileModel } from '../client/profile.model';

export namespace ProfileActions {

  export class GetProfileSuccessAction {
    public static readonly type = '[CLIENT] Get profile success';
    constructor(public profile: ProfileModel) { }
  }
  export class SavedColorTheme {
    public static readonly type = '[CLIENT] Saved color theme';
    constructor(public theme: ThemeOptionType) { }
  }

}
