<div class="center-horizontal-and-vertical"
     style="height: 300px; flex-direction: column;">
  <div>
    This is a trend variable. {{ type }} not available.
    See original variable for information.
  </div>
  <br>
  <button class="btn"
          (click)="openTwin()">Open original</button>
</div>
