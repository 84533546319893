import { Def } from '@core/decorators/def';
import { SqlDatabaseInfoModelDTO } from '../sql-database/sql-database.dtos';

export class RemoteDataSourceDTO {
  @Def() RemoteDataSourceId: string;
  @Def() ClientId: string;
  @Def() RemoteUri: string;
  @Def() User: string;
  @Def() Provider: string;
  @Def() SyncedAt: string;
  @Def() ModifiedDate: string;
  @Def() SavedPassword: boolean; // remove? ask jocke
  @Def() ObjectId: string;
  @Def() ObjectName: string;
  @Def() ApiKey: string;
  @Def() DatabaseDTO: SqlDatabaseInfoModelDTO;
}

export class UpdateRemoteDataSourceDTO {
  @Def() RemoteUri?: string;
  @Def() User?: string;
  @Def() Password?: string;
  @Def() Provider: string;
  @Def() SavePassword?: boolean;
  @Def() ApiKey?: string;
  @Def() RemoteDataSourceId: string;
  @Def() Extras?: string;
}
