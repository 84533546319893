import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MaterialModule } from '@shared/modules/material.module';
import { SharedModule } from '@shared/shared.module';
import { PipesModule } from '../../shared/modules/pipes/pipes.module';
import { IndicioV2Dialogs } from './dialogs';


@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    MaterialModule,
    SharedModule
  ],
  declarations: [
    ...IndicioV2Dialogs
  ],
  exports: [

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class IndicioDialogsModule { }
