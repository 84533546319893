
import { Injectable } from '@angular/core';
import { ClearStateAction } from '@core/store/auth/auth.actions';
import { CompanyActions, CompanyDataRemoved, GetCompaniesSuccessAction } from '@core/store/company/company.actions';
import { CompanyModel } from '@core/store/company/company.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdatedUserPermissionsAction } from '../client/client.actions';
import { RemovedFilesInCompanyAction } from '../file/file.actions';
import { HistoricEventActions } from '../historic-event/historic-event.actions';
import { RemovedProjectsInCompanyAction } from '../project/project.actions';
import { DeleteSourceVariableSuccessAction, GetSourceVariableSuccessAction, RemovedSourceVariablesInCompanyAction } from '../source-variable/source-variable.actions';

class CompanyStateModel {
  Companies: CompanyModel[];
}
@State<CompanyStateModel>({
  name: 'companies',
  defaults: {
    Companies: []
  }
})
@Injectable()
export class CompanyState {

  @Selector()
  static companies(state: CompanyStateModel): CompanyModel[] { return state.Companies; }

  constructor() { }

  @Action(CompanyActions.GetCompanySuccessAction)
  getCompanySuccess(ctx: StateContext<CompanyStateModel>, action: CompanyActions.GetCompanySuccessAction) {
    const companies = ctx.getState().Companies;
    const idx = companies.findIndex(company => company.CompanyId === action.company.CompanyId);
    if (idx > -1 && JSON.stringify(action.company.Permissions) !== JSON.stringify(companies[idx].Permissions)) {
      ctx.dispatch(new UpdatedUserPermissionsAction());
    }

    companies.addOrUpdate(action.company);
    ctx.patchState({ Companies: [...companies] });
  }

  @Action(DeleteSourceVariableSuccessAction)
  deleteSourceVariableSuccessAction(ctx: StateContext<CompanyStateModel>, action: DeleteSourceVariableSuccessAction) {
    const companies = ctx.getState().Companies;
    companies.forEach(c => c.SourceVariableIds.removeId(action.SourceVariableId));
    ctx.patchState({ Companies: [...companies] });
  }

  @Action(GetSourceVariableSuccessAction)
  getSourceVariableSuccessAction(ctx: StateContext<CompanyStateModel>, action: GetSourceVariableSuccessAction) {
    const companies = ctx.getState().Companies;
    const company = companies.find(x => x.CompanyId === action.variable.CompanyId);
    if (!company) { return; }
    company.SourceVariableIds.addUniqueId(action.variable.SourceVariableId);
    ctx.patchState({ Companies: [...companies] });
  }

  @Action(GetCompaniesSuccessAction)
  getCompaniesSuccess(ctx: StateContext<CompanyStateModel>, action: GetCompaniesSuccessAction) {
    const companies = ctx.getState().Companies;
    action.companies.forEach(c => {
      companies.addOrUpdate(c);
    });
    companies.sort((a, b) => a.Name.localeCompare(b.Name));
    ctx.patchState({ Companies: [...companies] });
  }

  @Action(CompanyActions.RemovedEmployeeSuccess)
  removedEmployeeSuccess(ctx: StateContext<CompanyStateModel>, action: CompanyActions.RemovedEmployeeSuccess) {
    const companies = ctx.getState().Companies;
    const c = companies.find(x => x.CompanyId === action.companyId);
    if (!c) { return; }
    c.Employees.removeByKey('Email', action.email);
    ctx.patchState({ Companies: [...companies] });
  }

  @Action(CompanyDataRemoved)
  removeCompanyData(ctx: StateContext<CompanyStateModel>, action: CompanyDataRemoved) {
    const companies = ctx.getState().Companies;
    companies.addOrUpdate(action.company);
    ctx.patchState({ Companies: [...companies] });

    const companyId = action.company.CompanyId;
    ctx.dispatch(new RemovedProjectsInCompanyAction(companyId));
    ctx.dispatch(new RemovedFilesInCompanyAction(companyId));
    ctx.dispatch(new HistoricEventActions.ClearCompanyEvents(companyId));
    ctx.dispatch(new RemovedSourceVariablesInCompanyAction(companyId));
  }

  @Action(ClearStateAction)
  logoutUser(ctx: StateContext<CompanyStateModel>) {
    ctx.patchState({ Companies: [] });
  }
}
