import { Injectable } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';
import { RemotePathEntryDTO } from '../dtos/remote-data-path-entry.dto';
import { MacrobondEntityInfoDTO, MacrobondEntityInfoGroupDTO, MacrobondEntityInfoGroupItemDTO } from './dtos/macrobond-entityinfo.dto';
import { MacrobondTreeListDTO, MacrobondTreeListEntityDTO, MacrobondTreeListGroupDTO } from './dtos/macrobond-treelist.dto';

@Injectable({
  providedIn: 'root'
})
export class MacrobondMapper {

  public mapCategory(dto: RemotePathEntryDTO, parent?: RemotePathEntryDTO, level = 0) {
    dto.RemotePathEntryId = `${dto.RemotePathEntryId}<->${Math.random().toString(36).substring(7)}`;
    if (parent) {
      dto.ParentPath = parent.ParentPath ? `${parent.ParentPath}<->${parent.Title}` : parent.Title;
    }

    dto.level = level + 1;

    if (dto.SubPaths) {
      dto.SubPaths = dto.SubPaths.map(x => this.mapCategory(x, dto, dto.level + 1));
    }

    dto.hasChildren = dto.SubPaths?.length > 0 || !!dto.SubPathsUrl?.length;

    return dto;
  }

  public mapTreeList(dto: MacrobondTreeListDTO) {
    dto.Aspects.forEach(aspect => {
      aspect.Groups = aspect.Groups.map(g => this.mapTreeListGroup(g));
    });
    return dto;
  }

  public mapTreeListGroup(dto: MacrobondTreeListGroupDTO) {
    dto.Entities = dto.Entities.map(x => {
      return this.mapTreeListEntry(x);
    });
    return dto;
  }

  public mapEntityInfo(dto: MacrobondEntityInfoDTO) {
    if (!dto) { return; }
    const model = Object.faMapTo(new MacrobondEntityInfoDTO, dto);
    model.groups = dto.groups.map(x => {
      const group = Object.faMapTo(new MacrobondEntityInfoGroupDTO, x);
      group.items = x.items.map(y => Object.faMapTo(new MacrobondEntityInfoGroupItemDTO, y));
      return group;
    });
    return model;
  }

  public mapTreeListEntry(dto: MacrobondTreeListEntityDTO) {

    if (dto.StartDate) {
      if (dto.Periodicity) {
        dto.StartDate = DateUtils.newNullableDate(dto.StartDate);
      } else { dto.StartDate = null; }
    }
    if (dto.LastValueDate) {
      if (dto.Periodicity) {
        dto.LastValueDate = DateUtils.newNullableDate(dto.LastValueDate);
      } else { dto.LastValueDate = null; }
    }

    if (String(dto.CopPercent) === 'NaN') {
      dto.CopPercent = null;
    }

    if (String(dto.CopValue) === 'NaN') {
      dto.CopValue = null;
    }

    return dto;
  }

}
