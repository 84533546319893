import { ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastActions, GetForecastVersionSuccessAction } from '@core/store/forecast/forecast.actions';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { Subscription } from 'rxjs';
import { ForecastSettingsDialogData } from './settings.dialog.constants';
import { FCastSettingsDialogDateSettingsTabComponent } from './tabs/date-settings/fcast-tab.date-settings.component';
import { FCastSettingsDialogModelsTabComponent } from './tabs/models/fcast-tab.models.component';

export enum ForecastSettingsDialogViewIndex {
  'general' = 0,
  'version' = 1,
  'indicator-analysis' = 2,
  'models' = 3,
  'date-settings' = 4,
}

@Component({
  selector: 'indicio-settings-dialog',
  templateUrl: 'settings.dialog.html',
})
export class ForecastSettingsDialogComponent extends DialogV2BaseDialog<ForecastSettingsDialogComponent> implements OnDestroy {
  public static Id: string = 'ForecastSettingsDialogComponent';

  @ViewChild('dateTab') dateTab: FCastSettingsDialogDateSettingsTabComponent;
  @ViewChild('modelsTab') modelsTab: FCastSettingsDialogModelsTabComponent;

  protected subscriptions = new Subscription();

  public fVersion: ForecastVersionModel;
  public forecast: ForecastModel;
  public project: ProjectModel;
  public trigger: boolean[] = [];
  // Tabs
  public viewIndex: number;
  public viewTypes = ForecastSettingsDialogViewIndex;
  public viewsWithModifiedSettings: Set<ForecastSettingsDialogViewIndex> = new Set();

  // Save button
  public showSaveOn: number[] = [this.viewTypes.models, this.viewTypes['date-settings']];
  public modifiedSettings: boolean = false;
  public pending: boolean = false;

  public canUpdateForecast: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: ForecastSettingsDialogData,
    dialogRef: MatDialogRef<ForecastSettingsDialogComponent, ForecastSettingsDialogData>,
    public appearance: AppearanceService,
    public auth: AuthFrontendService,
    private cd: ChangeDetectorRef,
    private actions: ActionService,
    private statusService: StatusService,
    private forecastService: ForecastFrontendService,
    private projService: ProjectFrontendService,
    private clientService: ClientFrontendService
  ) {
    super(dialogRef);
    this.viewIndex = this.data.View == null ? 1 : this.data.View;
    this.initialize();
    this.setupSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected async initialize() {
    this.forecast = await this.forecastService.getOrFetchForecast(this.data.ForecastId).catch((e) => { this.statusService.setError(e); return null; });
    this.fVersion = await this.forecastService.getOrFetchForecastVersion(this.forecast.activeVersionId).catch((e) => { this.statusService.setError(e); return null; });
    this.project = await this.projService.getOrFetchProject(this.clientService.activeCompanyId, this.forecast.ProjectId).catch((e) => { this.statusService.setError(e); return null; });

    if (!this.forecast || !this.fVersion || !this.project) {
      this.statusService.setError('Failed to load everything needed, please try to re-openen the dialog..', true);
      return;
    }

    this.setPermissions();
    this.initialized = true;
  }

  public modified(changed: boolean, type: ForecastSettingsDialogViewIndex) {
    if (changed) {
      this.viewsWithModifiedSettings.add(type);
    } else {
      this.viewsWithModifiedSettings.delete(type);
    }
    this.modifiedSettings = this.viewsWithModifiedSettings.size > 0;
  }

  public changeView(index: number) {
    this.viewIndex = index;
  }

  public hasModifiedSettings(): boolean {
    return this.viewsWithModifiedSettings.has(this.viewIndex);
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public save() {
    this.pending = true;
    const promises = [];
    if (this.viewsWithModifiedSettings.has(ForecastSettingsDialogViewIndex['date-settings'])) {
      promises.push(this.dateTab.save());
    }
    if (this.viewsWithModifiedSettings.has(ForecastSettingsDialogViewIndex.models)) {
      promises.push(this.modelsTab.save());
    }
    Promise.all(promises)
      .catch((e) => {
        this.statusService.setError(e);
      })
      .finally(() => {
        this.pending = false;
      });
  }

  private setupSubscriptions() {
    this.subscriptions.add(this.actions.dispatched(GetForecastVersionSuccessAction).subscribe((action: GetForecastVersionSuccessAction) => {
      if (this.forecast.ForecastId !== action.model.ForecastId) { return; }
      this.fVersion = action.model;
      this.trigger = [true];
      this.cd.detectChanges();
      this.initialize();
    }));

    this.subscriptions.add(this.actions.dispatched(ForecastActions.UpdatedForecast).subscribe(async (action: ForecastActions.UpdatedForecast) => {
      if (this.forecast.ForecastId !== action.forecastId) { return; }
      await this.updateFC(action.forecastId);
    }));

    this.subscriptions.add(this.actions.dispatched(ForecastActions.UpdateForecastVersionMetaFields).subscribe(async (action: ForecastActions.UpdatedForecast) => {
      if (this.forecast.ForecastId !== action.forecastId) { return; }
      await this.updateFC(action.forecastId);
    }));
  }

  private async updateFC(fcId: string) {
    this.forecast = await this.forecastService.getOrFetchForecast(fcId);
    this.fVersion = await this.forecastService.getOrFetchForecastVersion(this.forecast.activeVersionId);
    this.trigger = [true];
    this.cd.detectChanges();
    this.initialize();
  }

  private setPermissions() {
    this.canUpdateForecast = this.project.hasPermission('CAN_UPDATE_FORECAST');
  }
}
