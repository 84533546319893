import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { ProviderService } from '@core/store/providers/provider.service';
import { QuandlDatabaseModel } from '@core/store/providers/quandl/quandl-database.model';
import { QuandleDatasetModel } from '@core/store/providers/quandl/quandl-dataset.model';
import { QuandlService } from '@core/store/providers/quandl/quandl.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { Store } from '@ngxs/store';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { QuandlImportModalOpts } from './quandl-import-modal.options';


@Component({
  selector: 'indicio-quandl-import-modal',
  templateUrl: './quandl-import-modal.component.html'
})
export class QuandlImportModalComponent extends ModalModelComponent {

  dataset: QuandleDatasetModel;
  forecastVersion: ForecastVersionModel;

  remoteDataModel = new RemoteDataRequestModel;

  variableName: string;
  columnId: number = null;
  chosenColumnValue: string;
  nameConflict = false;

  loading = true;

  constructor(
    public store: Store,
    public envService: EnvironmentService,
    private quandlService: QuandlService,
    private providerService: ProviderService,
    private clientService: ClientFrontendService,
    private statusService: StatusService,
    private forecastVariableService: ForecastVariableFrontendService,
    private sourcevariableService: SourceVariableFrontendService
  ) {
    super();
  }

  public setOptions(options: QuandlImportModalOpts) {
    this.dataset = options.dataset;
    this.forecastVersion = options.forecast;

    this.remoteDataModel.Provider = DataProvider.quandl;
    this.variableName = this.dataset.name;
    this.loading = false;

    if (this.dataset.column_names.length === 2) {
      this.columnId = 1;
      this.chosenColumnValue = this.dataset.column_names.find(x => x !== 'Date' && x !== 'Trade Date');
    }
  }

  public checkName() {
    this.nameConflict = this.sourcevariableService.isNameConflict(this.variableName);
  }

  public getDatabaseSearchLink(database: string) {
    return `https://www.quandl.com/data/${database}/pricing`;
  }

  public async addVariable() {
    if (this.columnId === null) { return; }
    this.pending = true;
    this.quandlService.getDataSerie(this.dataset, this.columnId)
      .then(data => {
        this.remoteDataModel.Data = data;
        this.remoteDataModel.Name = this.variableName;
        this.remoteDataModel.RemoteUri = this.quandlService.lastUri;
        this.remoteDataModel.RemoteReferenceId = `${this.dataset.database_code}/${this.dataset.dataset_code}`;
        this.remoteDataModel.MetaData = this.dataset.faKeyValueArray();
        this.providerService.addVariableFromDatasource(this.clientService.activeCompany.CompanyId, this.remoteDataModel)
          .then(sourcevariable => {
            this.statusService.setMessage('Variable added successfully', 'Success');
            this.close();
            this.forecastVariableService.checkErrorsAndCreateForecastVariable(sourcevariable, this.forecastVersion)
              .catch(_err => {
                this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [sourcevariable.SourceVariableId]));
              });
          })
          .catch(err => this.statusService.setError(err, true))
          .finally(() => this.pending = false);
      })
      .catch(error => {
        if (error.status === 403 || (error && error.error.quandl_error && error.error.quandl_error.code === 'QEPx04')) {
          let msg = error.error.quandl_error.message;
          if (msg && !msg.match(/subscribe/)) {
            if (msg.match(/confirm your email/)) {
              msg = msg.replace(/us\.$/, 'Nasdaq Data Link.');
            }
            this.statusService.setMessage(msg, 'Error', true);
          } else {
            this.statusService.setMessage('You do not have permission to view this dataset. Please subscribe to this database to get access.',
              'Error',
              true,
              ['Click on the Premium label below']);
          }
        } else {
          this.statusService.setMessage('Error fetching observations', 'Error', true);
        }
      })
      .finally(() => this.pending = false);
  }

  setId(columnContent: MatSelectChange) {
    const names = this.dataset.column_names;
    const index = names.indexOf(columnContent.value);
    this.columnId = index;
  }

  findDatabase(code: string) {
    if (!this.quandlService.databases) { return <QuandlDatabaseModel> {}; }
    return this.quandlService.databases.find(x => x.database_code === code) || <QuandlDatabaseModel> {};
  }
}
