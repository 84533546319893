import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[indicioAutoFocusInput]'
})
export class AutoFocusInputDirective implements AfterViewInit {

  constructor(private ui: ElementRef) { }

  public ngAfterViewInit(): void {
    setTimeout(() => this.ui.nativeElement.focus(), 10);
  }
}
