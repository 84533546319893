import { ScriptName } from '@core/types/script.name.type';

export class ScriptDisplayNames {
  public static get(scriptName: ScriptName): string {
    switch (scriptName) {
      case 'var-select':
        return 'Indicator analysis';
      case 'data-processing':
        return 'Data-processing';
      case 'univariate':
        return 'Univariate';
      case 'multivariate':
        return 'Multivariate';
      case 'bvar':
        return 'Bayesian analysis';
      case 'conditional':
        return 'Scenario';
      case 'hierarchical':
        return 'Hierarchy';
      default:
        return scriptName;
    }
  }

}
