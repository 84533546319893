import { Def } from '@core/decorators/def';

export class QuandleDatasetModel {
  @Def() column_names: string[];
  @Def() database_code: string;
  @Def() database_id: number;
  @Def() dataset_code: string;
  @Def() description: string;
  @Def() frequency: string;
  @Def() id: number;
  @Def() name: string;
  @Def() newest_available_date: string;
  @Def() oldest_available_date: string;
  @Def() premium: false;
  @Def() refreshed_at: string;
  @Def() type: string;
  @Def() provider: string;
  @Def() providerText: string;
  @Def() providerDescription: string;

  oldData: boolean;
}
