<h1 mat-dialog-title>News archive</h1>
<div mat-dialog-content>
  <div class="news-archive-dialog-list">
    <indicio-news-snippet [article]="article"
                          *ngFor="let article of articles"></indicio-news-snippet>
  </div>
  <indicio-infinity-scroll (visibleEvent)="loadMore()"
                           container=".mat-dialog-content"></indicio-infinity-scroll>
</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-flat-button
          [mat-dialog-close]="">
    Close
  </button>
</div>
