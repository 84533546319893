import { Def } from '@core/decorators/def';

export class ProfileDTO {
  @Def() ClientId: string;
  @Def() Email: string;
  @Def() Bio: string;
  @Def() FirstName: string;
  @Def() LastName: string;
  @Def() CountryCode: string;
  @Def() ProfilePicture: string;
  @Def() CreatedDate: string;
  @Def() DisplayLanguage: string;
}
