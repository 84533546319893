import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { take } from 'rxjs/operators';
import { DialogV2BaseService } from '../base/dialogs.V2.base-service';
import { ForgorPasswordDialogData, ForgotPasswordDialogComponent } from './forgot-password/forgot-password.dialog';


@Injectable({ providedIn: 'root' })
export class SecurityDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openForgotPassword(data: ForgorPasswordDialogData, config?: MatDialogConfig) {
    const cfg = this.getConfig(ForgotPasswordDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, boolean>(ForgotPasswordDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }

}
