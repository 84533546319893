import { Component, Input } from '@angular/core';
import { copyToClipboard } from 'js-copy-to-clipboard';

@Component({
  selector: 'indicio-copy',
  template: `
    <div style="position: relative;">
      <i class="h-grey pointer ion-android-clipboard" (click)="copyIt()"></i>
      <div style="position: absolute; top: 0; right: -52px;">{{ copyItText }}</div>
    </div>`
})
export class IndicioCopyComponent {

  @Input() element: HTMLElement;

  copyItText: string = '';

  public copyIt() {
    const tt = this.element.innerText;
    copyToClipboard(tt);
    this.copyItText = 'Copied!';
    setTimeout(() => {
      this.copyItText = '';
    }, 1500);
  }
}
