import { ValueUtils } from '@shared/utils/value.utils';
import { ReconciliationResultDTO, RECONCILIATION_DISPLAY_TYPE } from '../entities/dtos/hierarchy-reconciliation.dto';
import { HierarchyRelationDTO } from '../entities/dtos/hierarchy-relation.dto';
import { HForecastTreeNode } from '../entities/models/hierarchical-forecast.tree-node';
import { HierarchyModel } from '../entities/models/hierarchy.model';


export namespace HierarchyUtils {

  export function generateTree(hierarchy: HierarchyModel, oldTree: HForecastTreeNode): HForecastTreeNode {
    const flatListOfOldNodes = flattenTree(oldTree);
    const baseRelation = hierarchy.Relations.find(x => x.ParentRelationId === null);
    const baseNode = generateTreeNode(baseRelation, hierarchy, null, oldTree);
    // Get all but the top node
    let relationsToAdd = hierarchy.Relations.filter(x => x.ParentRelationId !== null).slice();
    // Internal fn to create nodes for a specified parent, returning the new children
    const addNodeChildren = (parent: HForecastTreeNode) => {
      let children = relationsToAdd.filter(x => x.ParentRelationId === parent.Relation.RelationId);
      let newNodes = children.map(c => {
        const oldNode = flatListOfOldNodes.find(x => x.Relation.RelationId === c.RelationId);
        return generateTreeNode(c, hierarchy, parent, oldNode);
      });
      return newNodes;
    };
    // Start with tier 1 children
    let nextGeneration = addNodeChildren(baseNode);
    while (nextGeneration.length) {
      // Exhaust the tree
      nextGeneration = nextGeneration.map(ng => addNodeChildren(ng)).flatten();
    }
    // Set reconciled possible flag
    baseNode.canBeReconciled = baseNode.children.length > 1 && checkReconciliationPossible(baseNode);
    // Set invalid children flag
    checkHasInvalidChildren(baseNode);

    return baseNode;
  }

  export function generateTreeNode(
    relation: HierarchyRelationDTO,
    hierarchy: HierarchyModel,
    parent?: HForecastTreeNode,
    oldNode?: HForecastTreeNode
  ): HForecastTreeNode {
    const getMessages = (n: HierarchyRelationDTO) => {
      const res: string[] = [];
      if (!n.SourceVariableId && n.ForecastId) { res.push('Main variable missing for forecast'); }
      return res;
    };
    const newNode = new HForecastTreeNode();
    newNode.children = [];
    newNode.isOpen = !!oldNode?.isOpen;
    newNode.isActive = !!oldNode?.isActive;
    newNode.menuOpen = !!oldNode?.menuOpen;
    newNode.level = parent ? parent.level + 1 : 0;
    newNode.topNode = relation.ParentRelationId === null;
    newNode.missingForecast = !relation?.ForecastId;
    newNode.missingMainVar = !relation?.SourceVariableId;
    newNode.invalidChildrenMessages = [];
    newNode.messages = [...relation.Errors, ...relation.Warnings, ...getMessages(relation)];
    newNode.canBeReconciled = false;
    newNode.canUpdateVariables = (relation.ParentRelationId === null ? hierarchy.NewDataExists : relation.NewDataExists) && relation.hasPermissionToUpdate;
    newNode.Relation = relation;
    newNode.Hierarchy = hierarchy;

    if (parent) {
      parent.children.push(newNode);
    }

    return newNode;
  }

  export function flattenTree(baseNode: HForecastTreeNode): HForecastTreeNode[] {
    const fn = (n: HForecastTreeNode): HForecastTreeNode[] => {
      if (!n?.children.length) { return [n].filter(x => !!x); }
      return [n, ...n.children?.map(c => fn(c)).flatten()];
    };
    return fn(baseNode);
  }

  function checkReconciliationPossible(node: HForecastTreeNode): boolean {
    if (!node.valid) { return false; }
    if (node.children.length) {
      return node.children.every(c => checkReconciliationPossible(c));
    }
    return true;
  }

  function checkHasInvalidChildren(node: HForecastTreeNode) {
    if (node.children.length) {
      node.children.forEach(c => checkHasInvalidChildren(c));
    }
    node.invalidChildrenMessages = node.children.map(x => [...x.messages, ...x.invalidChildrenMessages]).flatten();
  }

  export function getValueAtIndex(idx: number, result: ReconciliationResultDTO, display: RECONCILIATION_DISPLAY_TYPE) {
    const abbrev = (v, num = 3) => { return ValueUtils.getValueWithSignificantDigits(v, num); };
    switch (display) {
      case 'percent_diff':
        return abbrev((100 * (result.ToResult.Values[idx].V - result.FromResult.Values[idx].V) / result.FromResult.Values[idx].V));
      case 'actual_value':
        return abbrev(result.ToResult.Values[idx].V, 5);
    }
  }
}
