import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { VSMeasurementType, VSModeType, VsResultType } from '@modules/lang/language-files/var-select';
import { Normalizer } from '@shared/utils/array.utils';
import { StatisticsUtils } from '@shared/utils/statistic.utils';
import { ForecastVariableModel } from '../forecast-variable/models/forecast-variable-model';
import { VarSelectSettingsDTO } from '../forecast/dtos/var-select-settings/var-select-settings-dto';
import { VariableSelectionExoModelDTO, VariableSelectionModelDTO, XNameDTO } from './dtos/variable-selection-result-dto';

export class InfluenceInfo {
  num: number;
  normNum: number;
  str: string;
  normStr: string;
  isSmall: boolean;
}

export class VarSelectResultModel implements IHasModelId {

  ForecastVersionId: string = null;
  VariableSelectResultId: string = null;
  RRequestId: string = null;
  ResultAvailable: boolean = false;

  VsResultType: VsResultType = null;
  VSMode: VSModeType = null;
  VSLassoStruct: string = null;
  VSMeasurement: VSMeasurementType = null;
  VSBestVarCount: number = null;
  VSIdentifiedVarCount: number = null;
  VSPlots: ResultFileDTO[] = [];
  VSUseRollingWindow: boolean = false;
  ActiveIndicatorIds: string[] = [];
  NameMaps: XNameDTO[] = [];

  Models: VariableSelectionModelDTO[] = [];
  ExoModels: VariableSelectionExoModelDTO[] = [];

  // Messages
  ErrorMessages: string[] = [];
  InfoMessages: string[] = [];
  WarningMessages: string[] = [];

  public get GetIndicatorInfluence(): VariableSelectionModelDTO[] { return this.Models.filter(x => !x.IsMainVariable); }

  // Script status
  ExitError = false;
  ExitSuccess = false;

  // Frontend specific
  fetched = false;

  public update(settings: VarSelectSettingsDTO) {
    if (settings.VSMode === this.VSMode) {
      this.VSMeasurement = settings.VSMeasurement;
      this.VSBestVarCount = settings.VSBestVarCount;
    }
  }

  public getInfluenceType(objectId: string, meas: VSMeasurementType): number[] {
    let m: any = this.Models.find(x => x.ForecastVariableId === objectId);
    if (!m && meas === 'COEF') { m = this.ExoModels.find(x => x.ObjectId === objectId); }
    return !!m
      ? m[meas + 'Influence']
      : [];
  }

  public getInfAsStrAndNum(variable: ForecastVariableModel, sig: number = null): InfluenceInfo {
    const isMF = this.VsResultType === 'MF';
    const id = isMF ? variable.MixedFreqTwinId || variable.ForecastVariableId : variable.ForecastVariableId;
    return this.getInfAsStrAndNumById(id, sig);
  }

  public getInfAsStrAndNumById(id: string, sig: number = null): InfluenceInfo {
    const normRes = this.getInfluenceAsNum(id, 10, true);
    const res = this.getInfluenceAsNum(id, 10, false);
    const small = res < 0.001 && res !== 0 && res > 0;
    const toUse = StatisticsUtils.roundNum(res, sig);
    const normToUse = StatisticsUtils.roundNum(normRes, sig);
    return <InfluenceInfo> {
      num: toUse,
      normNum: normRes || 0,
      str: res === -2 ? 'NA' : small ? '~0' : toUse.toString(),
      normStr: res === -2 ? 'NA' : small ? '~0' : normToUse.toString(),
      isSmall: normRes == null ? false : small
    };
  }

  public getInfluenceAsNum(forecastVariableId: string, significant: number = null, normalize: boolean = true): number {

    const targetModel = this.Models.find(x => x.ForecastVariableId === forecastVariableId);
    if (targetModel && targetModel.IsMainVariable) {
      return 1;
    }

    if (!targetModel && !this.ExoModels.find(x => x.ObjectId === forecastVariableId)) {
      return NaN;
    }

    const models: any[] = [...this.Models.filter(x => !x.IsMainVariable), ...this.ExoModels];

    const modelIdx = models.findIndex(x => (x.ForecastVariableId || x.ObjectId) === forecastVariableId);
    const model = models.find(x => (x.ForecastVariableId || x.ObjectId) === forecastVariableId);

    const rr = inf => {
      if (significant != null) {
        return StatisticsUtils.roundNum(inf, significant);
      }
      return inf;
    };

    let influence = -2;
    if (model) {
      if (this.VSMode !== 'stepwise' && this.VSMode !== 'mfstepwise' && normalize) {
        const measToUse: number[] = models.map(m => m[this.VSMeasurement + 'Influence'] ? m[this.VSMeasurement + 'Influence'][0] : 'NA');
        if (measToUse.length === 1 && measToUse[0] === 0) {
          influence = 0;
        } else {
          const normalized = Normalizer.normalize(measToUse, true);
          influence = normalized[modelIdx];
        }
      } else if (this.VSMode === 'stepwise' || this.VSMode === 'mfstepwise') {
        influence = +Object.values(model['Influence'])[0];
      } else {
        influence = +Object.values(model[this.VSMeasurement + 'Influence'])[0];
      }
      return rr(influence);
    }
    return influence;
  }

  public getModelId(): string { return this.VariableSelectResultId; }
}
