import { Injectable } from '@angular/core';
import { NavigateToHome } from '@core/actions/navigation.actions';
import { IMemberModel } from '@core/interfaces/if-member';
import { IMemberService } from '@core/interfaces/if-member.service';
import { CompanyActions, CompanyDataRemoved, CreateCompanySuccessAction } from '@core/store/company/company.actions';
import { CompanyBackendService } from '@core/store/company/company.backend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { UpdateData } from '@dialogs/variables/forecast-variable/update/update-variables.dialog.entities';
import { Syncable } from '@dialogs/variables/remote-variable/syncable-variables/syncable-variables.entities';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { VisibilityLevelType } from '@modules/lang/language-files/visibility';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { ClientState } from '../client/client.state';
import { TagAction } from '../tags/tags.actions';
import { CompanyState } from './company.state';
import { Set2FACompanyDTO } from './dtos/company-2fa-settings';
import { CreateCompanyDTO } from './dtos/create-company-dto';


@Injectable({
  providedIn: 'root'
})
export class CompanyFrontendService implements IMemberService {

  public supportEmail: string;
  public isLoading: boolean = false;
  public manualSetActiveCompany: boolean = false;
  // OBSERVABLES
  public companies$ = this.store.select(CompanyState.companies);

  // GETTERS
  public get companies() { return this.store.selectSnapshot(CompanyState.companies); }
  public getCompany(companyId: string) { return this.companies.find(x => x.CompanyId === companyId); }
  public get activeCompanyId() { return this.store.selectSnapshot(ClientState.activeCompanyId); }
  public get activeCompany() { return this.getCompany(this.activeCompanyId); }
  public maxHorizon(periodicity: PeriodicityType) { return this.horizonInfos.find(x => x.Periodicity === periodicity).MaxHorizon; }
  public get horizonInfos() { return this.activeCompany.MaxHorizons; }

  constructor(
    private store: Store,
    private backend: CompanyBackendService,
  ) { }

  public getUpdatableForecastVariableInfos(type: UpdateData.TargetTypes, objectId: string) {
    return this.backend.getUpdatableForecastVariableInfos(this.activeCompanyId, type, objectId);
  }

  public getSyncableVariableInfos(type: Syncable.TargetTypes, objectId: string) {
    return this.backend.getSyncableVariableInfos(this.activeCompanyId, type, objectId);
  }

  public getCompanyEmployees(companyId: string) {
    return this.backend.getCompanyEmployees(companyId);
  }

  public remindUser2FA(clientId: string, companyId: string) {
    return this.backend.remindUser2FA(clientId, companyId);
  }

  public set2FACompany(companyId: string, dto: Set2FACompanyDTO) {
    return this.backend.set2FACompany(companyId, dto);
  }

  public setAutomaticLogout(companyId: string, status: boolean) {
    return this.backend.setAutomaticLogout(companyId, status);
  }

  public resetUsers(company: CompanyModel) {
    return this.backend.resetUsers(company.CompanyId)
      .then(comp => {
        this.store.dispatch(new CompanyActions.GetCompanySuccessAction(company));
        return comp;
      });
  }

  public setSourceFromProviderVisibility(company: CompanyModel, visibilityLevel: VisibilityLevelType) {
    return this.backend.setSourceFromProviderVisibility(company.CompanyId, visibilityLevel)
      .then((bol) => {
        if (bol) {
          company.SourceFromProviderVisibility = visibilityLevel;
          this.store.dispatch(new CompanyActions.GetCompanySuccessAction(company));
        }
        return bol;
      });
  }

  public setSourceFromFileVisibility(company: CompanyModel, visibilityLevel: VisibilityLevelType) {
    return this.backend.setSourceFromFileVisibility(company.CompanyId, visibilityLevel)
      .then((bol) => {
        if (bol) {
          company.SourceFromFileVisibility = visibilityLevel;
          this.store.dispatch(new CompanyActions.GetCompanySuccessAction(company));
        }
        return bol;
      });
  }

  public get2FAInfoForCompany(company: CompanyModel) {
    return this.backend.get2FAInfoForCompany(company.CompanyId)
      .then(infos => {
        company.Employees.forEach(e => e.twoFactorEnabled = infos.find(x => x.ClientId === e.ClientId)?.Enabled || false);
        return infos;
      });
  }

  public removeCompanyData(company: CompanyModel) {
    return this.backend.removeCompanyData(company.CompanyId)
      .then(comp => {
        this.store.dispatch(new CompanyDataRemoved(comp));
        return comp;
      });
  }

  public getOrFetchCompany(companyId: string): Promise<CompanyModel> {
    const c = this.getCompany(companyId);
    if (!c || !c.fetched) {
      return this.fetchCompany(companyId).then(x => {
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return x;
      });
    } else {
      return Promise.resolve(c).then(company => {
        if (company.CompanyId === this.activeCompanyId) {
          this.store.dispatch(new CompanyActions.SetActiveCompanySuccess(company.CompanyId));
        }
        return company;
      });
    }
  }

  public createCompany(dto: CreateCompanyDTO) {
    const newDto = Object.assign(new CreateCompanyDTO, dto);
    return this.backend.createCompany(newDto)
      .then(newCompany => {
        this.store.dispatch(new CreateCompanySuccessAction(newCompany));
        return newCompany;
      });
  }

  public getUserStatistics(dto) {
    return this.backend.getUserStatistics(dto);
  }

  public fetchCompany(companyId: string) {
    return this.backend.getCompany(companyId)
      .then(({ company, tags }) => {
        this.store.dispatch(new CompanyActions.GetCompanySuccessAction(company));
        if (company.CompanyId === this.activeCompanyId) {
          this.store.dispatch(new CompanyActions.SetActiveCompanySuccess(company.CompanyId));
        }
        this.store.dispatch(new TagAction.GetAllSuccess(tags));
        return company;
      });
  }

  public updateCompany(company: CompanyModel): Promise<CompanyModel> {
    return this.backend.updateCompany(company)
      .then(updated => {
        this.store.dispatch(new CompanyActions.GetCompanySuccessAction(updated));
        return updated;
      });
  }

  public setActiveCompany(companyId: string, navigate = true) {
    return this.backend.setActiveCompany(companyId)
      .then(id => {
        this.manualSetActiveCompany = true;
        if (this.activeCompanyId !== companyId) {
          this.store.dispatch(new CompanyActions.ResetCompanyData());
        }
        this.store.dispatch(new CompanyActions.SetActive(id));
        this.store.dispatch(new CompanyActions.CheckMacrobondCredentials(id));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        if (navigate) {
          this.store.dispatch(new NavigateToHome());
        }
        return this.getOrFetchCompany(id);
      });
  }

  /************************************************************
   * COMPANY MEMBER SERVICES BELOW - INTERFACE IMemberService
   ************************************************************/
  public inviteMembers(companyId: string, parentId: string, invites: IMemberModel[]) {
    return this.backend.inviteUsersToCompany(companyId, invites)
      .then(() => this.fetchCompany(companyId))
      .finally(() => this.isLoading = false);
  }

  public syncMembers(companyId: string) {
    return this.fetchCompany(companyId);
  }

  public leave(_companyId: string) {
    // Cannot leave company
  }

  public removeMember(companyId: string, parentId: string, userEmail: string) {
    return this.backend.uninviteUserFromCompany(companyId, userEmail)
      .then(() => this.purgeMemberFromCompany(companyId, userEmail));
  }

  public purgeMemberFromCompany(companyId: string, email: string) {
    const c = this.companies.find(x => x.CompanyId === companyId);
    if (!c) { return; }
    this.store.dispatch(new CompanyActions.RemovedEmployeeSuccess(companyId, email));
  }

  public getProjectsSourceVariableList(companyId: string) {
    return this.backend.getProjectsSourceVariableList(companyId);
  }
}
