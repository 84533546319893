
export const BAR_CHART_OPTS = {
  SVG_TARGET_HEIGHT: 420,
  BAR_MAX_HEIGHT: 42,
  BAR_MIN_HEIGHT: 12,
  BAR_MARGIN: 2,
  BAR_AXIS_MARGIN: 40,
};

export interface BarChartOptions {
  Data: BarChartEntry[];
  Labels: { xAxis: string, yAxis: string; };
  Percentage?: boolean;
  Title?: string;
}

export class BarChartEntry {
  public Label: string;
  public Description: string;
  public Value: number;
  public Color: string;
  public HideLabel: boolean;
  public Id: string;
  public IsNA: boolean;

  public vTextBBoxW: number;
  public nTextBBoxW: number;
  public vTextBBoxH: number;
  public barBBoxW: number;
}
