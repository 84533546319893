import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { Store } from '@ngxs/store';
import { ClientFrontendService } from '../client/client.frontend.service';
import { AvailableProjectsInfoDTO } from './dtos/company-forcasts.dto';
import { CreateReportDTO, CreateReportEntryDTO, ReportDTO, ReportEntryDTO, ReportMetaDTO } from './dtos/report.dtos';
import { ReportEntryModel, ReportModel } from './models/report.model';
import { ReportMapper } from './report-mapper';
import { ReportAction } from './reports.actions';
import { ReportState } from './reports.state';


@Injectable({
  providedIn: 'root'
})
export class ReportFrontendService {

  public loading = {
    ReportsLoading: false
  };

  public get reports() { return this.store.selectSnapshot(ReportState.reports); }
  public get activeReport() { return this.store.selectSnapshot(ReportState.activeReport); }
  public get activeReportId() { return this.store.selectSnapshot(ReportState.activeReportId); }
  public reports$() { return this.store.select(ReportState.reports); }
  public reportById(id: string) { return this.reports.find(s => s.ReportId === id); }

  constructor(
    private http: HttpService,
    private store: Store,
    private mapper: ReportMapper,
    private clientService: ClientFrontendService,
  ) {
  }

  public getOrFetchReport(reportId: string) {
    const report = this.reportById(reportId);
    if (!report || !report.fetched) {
      return this.fetchReport(reportId);
    } else {
      return Promise.resolve(report);
    }
  }

  public fetchAvailableForecasts() {
    return this.http.get<AvailableProjectsInfoDTO[]>(`company/${this.clientService.activeCompany.CompanyId}/reports/get-available-forecasts`)
      .then(({ body }) => body);
  }

  public fetchAllReportMetas() {
    return this.http.get<ReportMetaDTO[]>(`company/${this.clientService.activeCompany.CompanyId}/reports/get-all-reports`)
      .then(({ body }) => body.map(s => this.mapper.mapReport(s, this.clientService.activeCompany.FiscalYear)))
      .then(reports => {
        this.store.dispatch(new ReportAction.GetAllSuccess(reports));
        return reports;
      });
  }

  public fetchReport(reportId: string) {
    this.loading.ReportsLoading = true;
    return this.http.get<ReportDTO>(`company/${this.clientService.activeCompany.CompanyId}/reports/${reportId}/get-report`)
      .then(({ body }) => this.mapper.mapReport(body, this.clientService.activeCompany.FiscalYear))
      .then(report => {
        this.store.dispatch(new ReportAction.GetSuccess(report));
        return report;
      })
      .finally(() => {
        this.loading.ReportsLoading = false;
      });
  }

  public createNewReport(reportDto: CreateReportDTO) {
    return this.http.post<any>(`company/${reportDto.CompanyId}/reports/create-report`, reportDto)
      .then(({ body }) => this.mapper.mapReport(body, this.clientService.activeCompany.FiscalYear))
      .then(report => {
        this.store.dispatch(new ReportAction.CreateOrUpdateSuccess(report));
        return report;
      });
  }

  public updateReport(report: ReportModel) {
    const dto = this.mapper.toUpdate(report);
    return this.http.put<ReportDTO>(`company/${report.CompanyId}/reports/update-report`, dto)
      .then(({ body }) => this.mapper.mapReport(body, this.clientService.activeCompany.FiscalYear))
      .then(updated => {
        this.store.dispatch(new ReportAction.CreateOrUpdateSuccess(updated));
        return updated;
      });
  }

  public deleteReport(report: ReportModel) {
    return this.http.delete<null>(`company/${report.CompanyId}/reports/${report.ReportId}/delete-report`)
      .then(() => {
        this.store.dispatch(new ReportAction.DeleteSuccess(report));
        return report.ReportId;
      });
  }

  /** Entries */
  public addEntry(report: ReportModel, entry: CreateReportEntryDTO) {
    return this.http.put<ReportEntryDTO>(`company/${report.CompanyId}/reports/${report.ReportId}/add-entry`, entry)
      .then(({ body }) => this.mapper.mapReportEntry(body, report));
  }

  public removeEntry(report: ReportModel, entry: ReportEntryModel) {
    return this.http.delete<null>(`company/${report.CompanyId}/reports/${report.ReportId}/entries/${entry.ForecastId}/remove-entry`)
      .then(() => {
        return report;
      });
  }

  public setEntryResult(report: ReportModel, entry: ReportEntryModel) {
    return this.http.put<null>(`company/${report.CompanyId}/reports/${report.ReportId}/entries/${entry.ForecastId}/set-result/${entry.SelectedResultId}`, {})
      .then(() => {
        return report;
      });
  }
}
