import { Def } from '@core/decorators/def';
import { Guid } from '@core/types/guid.type';

export class UpdateAssessmentDTO {
  @Def() AssessmentId: Guid;
  @Def() Reason: string;
  @Def() Source: string;
  @Def() NewValue: number;
  @Def() Quantity: number;
  @Def() TrustLevel: string;
  @Def() Category: string;
}
