<div class="wiki-modal-row row"
     *ngIf="show">
  <div class="col-xs-12 center-text cursor-pointer"
       (click)="open()">
    <indicio-wiki-icon [slug]="slug"
                       [link]="false"
                       [size]="15"></indicio-wiki-icon>
    {{ title }}
  </div>
</div>
