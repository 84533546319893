import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { CreateForecastVariableDTO } from '@core/store/forecast-variable/dtos/create-forecast-variable-dto';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';

export class CreateMultiFVariableDialogData {
  forecastVersion: ForecastVersionModel;
  createDtos: CreateForecastVariableDTO[];
  displays: string[];
}

@Component({
  selector: 'indicio-create-multi-fvar-dialog',
  templateUrl: './create-multiple-fvar.dialog.html',
  styleUrls: ['./create-multiple-fvar.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class FVarCreateMultiDialogComponent extends DialogV2BaseDialog<FVarCreateMultiDialogComponent> {

  public static Id: string = 'CreateMultiFVariableDialogComponent';

  public faSave = faSave as IconProp;

  public createDtos: CreateForecastVariableDTO[];
  public transformNames: string[];
  public editNameOpen: boolean[];
  public nameConflicts: boolean[];
  public infos: { Status: string, Message: string; }[];
  public inProgress: boolean = false;
  public done: boolean = false;
  public importedCount: number = 0;

  private get fVersion() { return this.data.forecastVersion; }
  public get anyNameConflic() { return this.nameConflicts.some(x => x); }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: CreateMultiFVariableDialogData,
    private status: StatusService,
    private fVarService: ForecastVariableFrontendService,
    dialogRef: MatDialogRef<FVarCreateMultiDialogComponent>,
  ) {
    super(dialogRef);
    this.createDtos = this.data.createDtos;
    this.transformNames = this.data.displays;
    this.initialize();
  }

  protected initialize() {
    this.resetInfos();
    this.resetNameEditOpen();
    this.updateNameConflicts();
  }

  public override close() {
    this.dialogRef.close(this.done);
  }

  private resetInfos() {
    this.infos = this.createDtos.map(_ => ({ Message: '', Status: 'Pending' }));
  }

  private resetNameEditOpen() {
    this.editNameOpen = this.createDtos.map(_ => false);
  }

  public updateNameConflicts() {
    this.nameConflicts = this.createDtos.map(x => this.fVersion.fVarNameExists(x.Name));
  }

  public removeVariable(index: number) {
    this.createDtos.splice(index, 1);
    this.transformNames.splice(index, 1);
    this.initialize();
  }

  public async import() {
    this.inProgress = true;
    const toImport = [...this.createDtos];
    const count = toImport.length;
    let current = 0;

    const queueFunc = async () => {
      if (current >= count) {
        this.inProgress = false;
        this.done = true;
        return;
      }
      await this.importForecastVariable(toImport[current], current);
      current += 1;
      setTimeout(async () => await queueFunc(), 20);
    };

    await queueFunc();
  }

  private async importForecastVariable(variable: CreateForecastVariableDTO, index: number) {
    if (!variable) { return; }
    this.infos[index].Status === 'Importing';

    return this.fVarService.createForecastVariableByDto(this.fVersion.ForecastVersionId, variable)
      .then(fvar => {
        if (fvar.ForecastVariableValues.length < 30) {
          this.infos[index].Status = 'Warning';
          this.infos[index].Message = 'Value count is low';
        } else {
          this.infos[index].Status = 'Imported';
        }
      })
      .catch(err => {
        this.infos[index].Status = 'Failed';
        const errs = this.status.getMessage(err);
        this.infos[index].Message = errs.message;
        if (errs.errors?.length) {
          this.infos[index].Message += '\n' + errs.errors.join('\n');
        }

      }).finally(() => this.importedCount += 1);
  }
}
