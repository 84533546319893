import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { WikiService } from '../../wiki.service';

@Component({
  selector: 'indicio-wiki-icon',
  templateUrl: './wiki-icon.component.html',
  styleUrls: ['./wiki-icon.component.less'],
})
export class WikiIconComponent implements OnChanges {

  faIcon: IconProp = faGraduationCap;

  @Input() public slug: string = null;
  @Input() public size: number = 14;
  @Input() public link: boolean = true;
  @Input() public color: string;

  public show: boolean = false;

  constructor(private wikiService: WikiService) { }

  public ngOnChanges(changes: SimpleChanges): void {

    if (!!this.slug && this.wikiService.exists(this.slug)) {
      this.show = true;
    }
  }

  public open() {
    this.wikiService.open(this.slug);
  }
}
