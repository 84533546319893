import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MacrobondFilterDTO, MacrobondFilterListDTO } from '@core/store/providers/macrobond/dtos/macrobond-filterlist.dto';

export interface MacrobondSearchOptionsDialogData {
  Filters: MacrobondFilterListDTO[];
  ActiveFilter: MacrobondFilterDTO;
}

@Component({
  selector: 'indicio-macrobond-search-dialog',
  templateUrl: 'searchoptions.dialog.html',
})
export class MacrobondSearchOptionsDialogComponent {
  public static Id: string = 'MacrobondSearchOptionsDialogComponent';

  @ViewChild('select') mySelect;

  public newFilter: MacrobondFilterDTO;
  noRegion: MacrobondFilterDTO = { Path: null, Title: 'No region selected' };

  constructor(
    public dialogRef: MatDialogRef<MacrobondSearchOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MacrobondSearchOptionsDialogData) {
    if (data.ActiveFilter.Path) {
      this.newFilter = data.ActiveFilter;
    } else {
      setTimeout(() => { this.newFilter = this.noRegion; }, 0);
    }
  }

  public setAnswer(event: MatSelectChange) {
    this.newFilter = event.value;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(this.newFilter);
  }
}
