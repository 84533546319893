<h1 mat-dialog-title>Create new template tag</h1>
<div mat-dialog-content>
  <indicio-modal-option-row optionTitle="Tag name"
                            optionSubtitle="The name of the tag. Must be unique."
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field class="margin-top">
      <input type="text"
             data-test-id="create-tag-input"
             class="material"
             matInput
             autocomplete="off"
             [ngModel]="newTag.Name"
             (ngModelChange)="setName($event)"
             name="Name"
             maxlength="64"
             require>
      <mat-hint *ngIf="nameError">
        <span class="error-text">{{ nameError }}</span>
      </mat-hint>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Region"
                            optionSubtitle="Please select this option if the tag is a region."
                            textWidth="7"
                            optionsWidth="5">
    <mat-checkbox style="margin-top: 15px;"
                  [checked]="newTag.Type === 3"
                  (change)="toggleRegion($event)">Yes</mat-checkbox>
  </indicio-modal-option-row>

  <div style="margin-top: 30px; background-color: var(--indicio-modal-alt-background-color, #fefefe); padding: 10px">
    Or import a tag from your company's tag library. <br>
    <indicio-searchable-dropdown optionDisplay="Name"
                                 [initializeWithFirstValue]="false"
                                 [values]="availableCompanyTags"
                                 [selectedValue]="selectedTag"
                                 (valueChangeEvent)="onSelectTag($event)">
    </indicio-searchable-dropdown>
    <br>
    <span class="cursor-pointer dotted-underline"
          (click)="importTag = null">Reset</span>
  </div>

</div>


<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">Cancel</button>
  <button [disabled]="!newTagValid()"
          data-test-id="create-tag-save-btn"
          (click)="save()"
          mat-flat-button
          cdkFocusInitial>
    Create
  </button>
</div>
