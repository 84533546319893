<indicio-dialog-base>
  <h1 mat-dialog-title>Benchmarks</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <ng-container *ngTemplateOutlet="benchmarksTemplate"></ng-container>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button
            (click)="onNoClick()">
      Cancel
    </button>
    <button mat-stroked-button
            (click)="onNoClick()">
      Ok
    </button>
  </div>
</indicio-dialog-base>

<ng-template #benchmarksTemplate>
  <div class="grid-system bold"
       style="--grid-template: 1fr 100px 100px 75px 50px;">
    <div>Name</div>
    <div>From</div>
    <div>To</div>
    <div>Active</div>
    <div><!-- Actions --></div>
  </div>
  <hr style="margin-bottom: 10px;">
  <div class="grid-system center-vertical"
       *ngFor="let bench of benchmarks"
       style="--grid-template: 1fr 100px 100px 75px 50px;">
    <div>{{ bench.Name }}</div>
    <div>{{ bench.firstDate() | dateFormat }}</div>
    <div>{{ bench.lastDate() | dateFormat }}</div>
    <div>
      <mat-slide-toggle color="accent"
                        [checked]="benchActive(bench)"
                        (change)="toggleBench(bench)">
      </mat-slide-toggle>
    </div>
    <div class="btn-small-icon dark">
      <i class="ion-edit pointer h-grey"
         (click)="openEditOrCreate(bench)"></i>
      <i class="ion-trash-b pointer h-red margin-left-10"
         (click)="deleteBench(bench)"></i>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="AddNewButtonTemplate"></ng-container>
</ng-template>

<ng-template #AddNewButtonTemplate>
  <div style="margin-top: 20px; width: fit-content;"
       class="pointer h-grey"
       (click)="openEditOrCreate()">
    <i class="ion-plus margin-right-10"></i>
    <span>Add new benchmark</span>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div style="position: relative; text-align: center">
    <i style="font-size:50px"
       class="ion-load-c spinner"></i>
    <div class="title">Loading benchmarks</div>
  </div>
</ng-template>
