import { Def } from '@core/decorators/def';
import { IFileVersion } from '@core/interfaces/if-file';

export class RemoteFileVersionModel implements IFileVersion {
  @Def() RemoteFileVersionId: string;
  @Def() FileData: string;
  @Def() CreatedDate: Date;

  getModelId(): string {
    return this.RemoteFileVersionId;
  }
}
