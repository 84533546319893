import { Component } from '@angular/core';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';

@Component({
  selector: 'indicio-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent {

  public get loggedIn$() { return this.authService.loggedIn$; }

  constructor(
    public authService: AuthFrontendService
  ) { }
}
