<div class="company-create-general-tab">
  <h3 class="extra-margin-top">
    Company info
  </h3>
  <indicio-modal-option-row optionTitle="Name"
                            optionSubtitle="Name of the new company"
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field style="margin-top: 15px;">
      <input type="text"
             matInput
             required
             type="text"
             value="10"
             cdkFocusInitial
             name="companyname"
             [(ngModel)]="company.Name"
             class="material">
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Max users"
                            optionSubtitle="Max allowed amount of users"
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(ngModel)]="company.MaxUsers"
                  required>
        <mat-option [value]="count"
                    *ngFor="let count of [1, 3, 5, 10, 20, 30, 50, 100]"> {{count}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Country"
                            optionSubtitle="Residing country"
                            textWidth="7"
                            optionsWidth="5">
    <div style="margin-top: 15px;">
      <indicio-searchable-dropdown optionDisplay="Display"
                                   [values]="envService.Countries"
                                   (valueChangeEvent)="setCountry($event)"
                                   [selectedValue]="selectedCountry"
                                   [initializeWithFirstValue]="false">
      </indicio-searchable-dropdown>
    </div>
  </indicio-modal-option-row>

  <indicio-modal-option-row *ngIf="authService.isAdmin && envService.LicenseTypes?.length"
                            optionTitle="License type"
                            optionSubtitle="License type of the company"
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="company.LicenseType"
                  panelClass="fitted-select"
                  required>
        <mat-option [value]="mode.Value"
                    [title]="mode.Description"
                    *ngFor="let mode of envService.LicenseTypes">{{ mode.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <ng-container *ngIf="authService.isAdmin">
    <h3 style="margin-top: 30px;">
      Support users
    </h3>

    <indicio-modal-option-row optionTitle="Set support users"
                              optionSubtitle="Support users connected to company"
                              textWidth="7"
                              optionsWidth="5">
      <mat-form-field style="margin-top: 15px;"
                      *ngIf="supportUsers.length; else supportLoadingTemplate">
        <mat-select [(value)]="selectedSupportUsers"
                    (selectionChange)="setAdministrators($event)"
                    multiple="true"
                    required
                    panelClass="fitted-select">
          <ng-container *ngFor="let role of supportUsers">
            <mat-optgroup *ngIf="role.users.length > 0"
                          [label]="role.role">
              <mat-option [value]="p"
                          [title]="p.Description"
                          *ngFor="let p of role.users">{{ p.Display }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <ng-template #supportLoadingTemplate>
        <indicio-spinner></indicio-spinner>&nbsp;Loading support users...
      </ng-template>
    </indicio-modal-option-row>
  </ng-container>

</div>
