
import { Def } from '@core/decorators/def';
import { HistoricValueDTO } from '@core/entities/dtos/plot-value-dto';
import { FileMetaDataDTO } from '@core/store/file/dtos/file-meta-data-dto';

export class CreateSourceVariableDTO {
  @Def() Name: string;
  @Def() SourceType: string;
  @Def() VisibilityLevelId: string;
  @Def() Values: HistoricValueDTO[];
  @Def() FileMetaData: FileMetaDataDTO;
  @Def() UploadedFileId: string;
  @Def() AggregationMethodId: string = 'average';
  @Def() StaticValues: boolean;
  @Def() IsPercent: boolean;
}
