<div class="forecasts-wrapper drawer"
     [ngClass]="{ 'with-pinned-forecasts': pinnedForecasts?.length }">
  <div class="text-center button-wrapper forecasts-title"
       data-test-id="forecasts-title-container">
    <h3>Forecasts</h3>
  </div>
  <div class="pinned-forecasts"
       *ngIf="pinnedForecasts?.length">
    <div class="text-center title pointer"
         (click)="togglePinnedForecastOpen()">
      Pinned forecasts
      <sup style="opacity: 0.6;">({{ pinnedForecasts?.length }})</sup>
      <i class="ion-close"
         style="opacity: 0.6;"
         (click)="unpinAllForecasts($event)"
         indicioTippy="Unpin all"></i>
    </div>
    <div class="forecasts"
         *ngIf="isPinnedForecastsOpen">
      <div *ngFor="let forecast of pinnedForecasts">
        <indicio-nav-tree-forecast-node [node]="forecast"
                                        listView="flat-list"
                                        [scrollPosition]="scrollPosition"
                                        (clickEvent)="newActiveNode(forecast)"></indicio-nav-tree-forecast-node>
      </div>
    </div>
  </div>
  <div class="list-view-picker text-center"
       data-test-id="navigation-list-view-picker"
       *ngIf="visible">
    <mat-form-field>
      <mat-label>List view</mat-label>
      <mat-select [value]="activeNavigationListView"
                  (selectionChange)="setNavigationListView($event.value)"
                  required
                  name="list-view">
        <mat-option [value]="view.Value"
                    [title]="view.Description"
                    *ngFor="let view of navigationListViews">{{ view.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="list">
    <ng-container *ngIf="activeNavigationListView !== 'hierarchy-list'; else hierarchyNavTemplate"
                  [ngTemplateOutlet]="forecastNavTemplate"></ng-container>
  </div>
</div>

<ng-template #hierarchyNavTemplate>
  <indicio-hierarchical-tree></indicio-hierarchical-tree>
  <ng-container *ngTemplateOutlet="createHierarchyButtonTemplate"></ng-container>
</ng-template>

<ng-template #forecastNavTemplate>
  <indicio-forecast-nav-tree #navTree
                             [loadingContent]="loadingContent"
                             [treeNodes]="forecastTree"
                             [scrollPosition]="scrollPosition"
                             [listView]="activeNavigationListView"
                             (activeNodeEvent)="newActiveNode($event)">
  </indicio-forecast-nav-tree>

  <ng-container [ngSwitch]="activeNavigationListView">
    <ng-container *ngSwitchCase="'project-list'">
      <ng-container *ngTemplateOutlet="createProjectButtonTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'flat-list'">
      <ng-container *ngTemplateOutlet="createForecastButtonTemplate">
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'tag-list'">
      <ng-container *ngTemplateOutlet="createForecastButtonTemplate">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #createProjectButtonTemplate>
  <div *ngIf="activeCompany?.hasPermission('CAN_CREATE_PROJECT')"
       class="unselectable create-project"
       data-onboarding="createProject"
       (click)="createProject()">
    <div class="project-info description">
      <i class="ion-plus green"></i>
      <span style="margin-left: 7px">Create project</span>
    </div>
  </div>
</ng-template>

<ng-template #createForecastButtonTemplate>
  <div class="unselectable create-project"
       data-onboarding="createForecast"
       (click)="createForecast()">
    <div class="project-info description">
      <i class="ion-plus green"></i>
      <span style="margin-left: 7px">Create forecast</span>
    </div>
  </div>
</ng-template>

<ng-template #createHierarchyButtonTemplate>
  <div class="unselectable create-hierararchy"
       data-onboarding="createHierarchy"
       (click)="createNewHF()">
    <div class="project-info description">
      <i class="ion-plus green"></i>
      <span style="margin-left: 7px">Create hierarchy</span>
    </div>
  </div>
</ng-template>


<ng-template #createTagButtonTemplate>

</ng-template>
