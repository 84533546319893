import { AccuracyMeasureDTO } from '@core/entities/dtos/accuracy-measure-dto';
import { DoubleValuePropertyDTO } from '@core/entities/dtos/double-value-property-dto';
import { PastForecastDTO } from '@core/entities/dtos/past-forecast-dto';
import { FittedValueDTO, SimplePlotValue, StatModelVariableDTO } from '@core/entities/dtos/plot-value-dto';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';

export class StatModelDTO {
  ForecastVersionId: string;
  RRequestId: string;
  Name: string;
  ModelName: string;
  Periodicity: string;
  Disabled: boolean;
  ModelBuilt: boolean;
  ExitSuccess: boolean;
  ExitError: boolean;
  IsPending: boolean;
  IsProcessing: boolean;
  Transforms: StatModelTransformationDTO[];
}

export class StatModelTransformationDTO {
  MainModelId: number;
  TransformName: string;
  Name: string;
  RDataAvailable: boolean;
  ModelBuilt: boolean;
  Disabled: boolean;
  FittedValues: FittedValueDTO[];
  InProperties: DoubleValuePropertyDTO[];
  OutProperties: DoubleValuePropertyDTO[];
  MAPESteps: AccuracyMeasureDTO[];
  MPESteps: AccuracyMeasureDTO[];
  MAESteps: AccuracyMeasureDTO[];
  MESteps: AccuracyMeasureDTO[];
  RMSESteps: AccuracyMeasureDTO[];
  HRSteps: AccuracyMeasureDTO[];
  MASESteps: AccuracyMeasureDTO[];
  RSQSteps: AccuracyMeasureDTO[];
  PastForecasts: PastForecastDTO[];
  IndicatorVariableIds: string[];
  Variables: StatModelVariableDTO[];
  ShapValues: ShapValueDTO[];
  InfoMessages: string[];
  WarningMessages: string[];
  ErrorMessages: string[];
}

export class StatModelFilesDTO {
  ModelId: number;
  ModelName: string;
  Files: ResultFileDTO[];
}

export class ShapValueDTO {
  Name: string;
  IsEvent: boolean;
  // ForecastVariableId or HistoricEventId
  Id: string;
  Values: SimplePlotValue[];
}
