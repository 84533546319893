import { Injectable } from '@angular/core';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastResultActions } from '@core/store/forecast-result/forecast-result.actions';
import { UpdatableForecastVariableFieldsDTO } from '@core/store/forecast-variable/dtos/forecast-variable-meta-dto';
import { ForecastVariableNowcastOptionsDTO } from '@core/store/forecast-variable/dtos/forecast-variable-nowcast-options-dto';
import { VariableActions } from '@core/store/forecast-variable/forecast-variable.actions';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { FvarFverGuidsEventArgsDTO, RefreshForecastVariableStepEventArgsDTO, SwapVariablesStepEventArgsDTO } from '@core/store/forecast/dtos/forecast-version/pusher-event-args.dto';
import { NewNowcastEventArgsDTO } from '@core/store/forecast/dtos/nowcast.dto';
import { ForecastActions, SetForecastAsNotFetchedAction } from '@core/store/forecast/forecast.actions';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ScenarioAction } from '@core/store/scenario/scenario.actions';
import { ScenarioFrontendService } from '@core/store/scenario/scenario.frontend.service';
import { MultivariateActions, StatModelActions } from '@core/store/stat-model/stat-model.actions';
import { VarSelectActions } from '@core/store/var-select/var-select.actions';
import { ScriptName } from '@core/types/script.name.type';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { ForecastVariableMessageDTO } from '../dtos/fvar-message-dto';
import { MissingValueModelChangedDTO } from '../dtos/fvar-set-missing-value-model.dto';
import { PusherActionDTO } from '../dtos/pusher-action-dto';
import { PusherInfoMessageDTO } from '../dtos/pusher-info-message.dto';

export type PusherForecastAction =
  'remove-all-results' |
  'remove-uni-results' |
  'remove-vs-results' |
  'remove-multi-results' |
  'remove-scenario-results' |
  'notify-uni-triggered' |
  'notify-triggered' |
  'set-forecast-version-meta-fields' |
  'set-can-calc-vs' |
  'set-can-calc-multi' |
  'scenario-result-calculated' |
  'fetch-forecast-version-date-info' |
  'fetch-forecast-variable' |
  'fetch-forecast-variables' |
  'fetch-forecast-version' |
  'fetch-stat-model' |
  'set-forecast-variables-flags' |
  'set-refresh-fvar-step-info' |
  'set-nowcast-options-for-fvar' |
  'set-swap-variable-step-info' |
  'set-fvar-missing-model' |
  'fvar-message' |
  'forecast-version-message' |
  'dispatch-new-nowcast' |
  'add-new-forecast-result' |
  'remove-forecast-result'
  ;

@Injectable({
  providedIn: 'root'
})
export class PusherForecastBehaviourService {

  constructor(
    private store: Store,
    private navService: NavigationService,
    private client: ClientFrontendService,
    private fVarService: ForecastVariableFrontendService,
    private forecastService: ForecastFrontendService,
    private scenarioService: ScenarioFrontendService,
    private status: StatusService
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {
    switch (dto.Action) {
      case 'remove-all-results':
        this.store.dispatch(new MultivariateActions.ClearHistoricData(dto.ForecastVersionId));
        return this.store.dispatch(new ForecastActions.RemoveAllResults(dto.ForecastVersionId));

      case 'set-refresh-fvar-step-info':
        return this.store.dispatch(new ForecastActions.SetRefreshFVarStepInfo(dto.ForecastVersionId, dto.Obj as RefreshForecastVariableStepEventArgsDTO));

      case 'set-swap-variable-step-info':
        return this.store.dispatch(new ForecastActions.SetSwapVariableStepInfo(dto.ForecastVersionId, dto.Obj as SwapVariablesStepEventArgsDTO));

      case 'set-forecast-version-meta-fields':
        return this.store.dispatch(new ForecastActions.UpdateForecastVersionMetaFields(dto.ForecastVersionId, dto.Obj));

      case 'remove-uni-results':
        return this.store.dispatch(new ForecastActions.RemoveUniResults(dto.ForecastVersionId, dto.Obj as string[]));

      case 'remove-vs-results':
        return this.store.dispatch(new VarSelectActions.RemoveResult(dto.ForecastVersionId));

      case 'remove-multi-results':
        this.store.dispatch(new MultivariateActions.ClearHistoricData(dto.ForecastVersionId));
        this.store.dispatch(new ScenarioAction.ClearResults(dto.ForecastVersionId));
        return this.store.dispatch(new ForecastActions.RemoveMultiResults(dto.ForecastVersionId, dto.Obj as string[]));

      case 'remove-scenario-results':
        return this.store.dispatch(new ForecastActions.RemoveScenarioResults(dto.ForecastVersionId, dto.Obj as string[]));

      case 'add-new-forecast-result':
        if (!this.navService.isOnForecast(dto.ForecastId)) { return; }
        return this.store.dispatch(new ForecastResultActions.NewForecastResult(dto.ForecastVersionId, dto.Str));

      case 'remove-forecast-result':
        return this.store.dispatch(new ForecastResultActions.RemovedForecastResult(dto.Str));

      case 'notify-uni-triggered':
        /* Special case, where the normal flow is that the user triggers multivariate, then should be informed about the uni-trigger event
           abit later. */
        setTimeout(() => {
          return this.store.dispatch(new ForecastActions.NotifyUniTriggered(dto.ForecastVersionId, dto.Obj as string));
        }, 5000);
        break;

      case 'notify-triggered':
        return this.store.dispatch(new ForecastActions.ScriptTriggered(dto.ForecastVersionId, dto.Str as ScriptName));

      case 'set-can-calc-vs':
        return this.store.dispatch(new ForecastActions.UpdateCalcVsStatus(dto.ForecastVersionId, dto.Flag));

      case 'set-nowcast-options-for-fvar':
        return this.store.dispatch(new VariableActions.SetNowcastOptions(dto.ForecastVersionId, dto.ForecastVariableId, dto.Obj as ForecastVariableNowcastOptionsDTO));

      case 'fetch-forecast-variable':
        if (this.navService.isOnForecast(dto.ForecastId) && (this.client.client.ClientId !== dto.ActorId || dto.Flag)) {
          this.store.dispatch(new MultivariateActions.ClearHistoricData(dto.ForecastVersionId));
          this.fVarService.fetchById(dto.ForecastVersionId, dto.ForecastVariableId);
        }
        break;

      case 'fetch-forecast-variables':
        (dto.Obj as FvarFverGuidsEventArgsDTO[]).forEach(x => {
          if (!this.forecastService.forecastByVersionId(x.ForecastVersionId)) { return; }
          this.store.dispatch(new MultivariateActions.ClearHistoricData(x.ForecastVersionId));
          this.fVarService.fetchById(x.ForecastVersionId, x.ForecastVariableId);
        });
        break;

      case 'fetch-forecast-version-date-info':
        if (this.navService.isOnForecast(dto.ForecastId) && (this.client.client.ClientId !== dto.ActorId)) {
          this.forecastService.fetchDateInfo(dto.ForecastVersionId);
        }
        break;

      case 'fetch-forecast-version':
        if (dto.Obj.ClientId == null || this.client.client.ClientId !== dto.Obj?.ClientId) {
          if (this.navService.isOnForecast(dto.ForecastId)) {
            this.forecastService.fetchForecastVersion(dto.ForecastVersionId);
          } else {
            this.store.dispatch(new SetForecastAsNotFetchedAction(dto.ForecastId));
          }
        }
        break;

      case 'fetch-stat-model':
        this.store.dispatch(new StatModelActions.FetchStatModel(dto.ForecastVersionId, dto.Obj));

      case 'set-fvar-missing-model':
        const missingDto = dto.Obj as MissingValueModelChangedDTO;
        if (this.navService.isOnForecast(dto.ForecastId)) {
          this.status.setMessage(`Missing value model set to ${missingDto.NewMissingValueModel}`, 'Success');
        }
        return this.store.dispatch(new VariableActions.SetMissingValueModel(dto.ForecastVersionId, dto.ForecastVariableId, missingDto));

      case 'fvar-message':
        if (this.navService.isOnForecast(dto.ForecastId)) {
          const msgDto = dto.Obj as ForecastVariableMessageDTO;
          if (msgDto.IsError) {
            if (msgDto.Message.indexOf('<->') !== -1) {
              const subs = msgDto.Message.split('<->');
              this.status.setMessage(subs[0], 'Error', false, subs[1].split(','));
            } else {
              this.status.setMessage(msgDto.Message, 'Error');
            }
          } else {
            this.status.setMessage(msgDto.Message, 'Success');
          }
        }
        break;

      case 'set-forecast-variables-flags': {
        const fvarFlagsDto = dto.Obj as UpdatableForecastVariableFieldsDTO[];
        if (fvarFlagsDto.some(x => x.UpdatedValuesExists)) {
          this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        }
        this.store.dispatch(new VariableActions.UpdateForecastVariablesFields(fvarFlagsDto));
        break;
      }

      case 'scenario-result-calculated':
        return this.scenarioService.fetchScenario(dto.ForecastVersionId, dto.Obj).catch(_ => { });

      case 'forecast-version-message':
        if (this.navService.isOnForecast(dto.ForecastId)) {
          return this.store.dispatch(new ForecastActions.GenericMessage(dto.ForecastVersionId, dto.Obj as PusherInfoMessageDTO));
        }
        break;

      case 'dispatch-new-nowcast': {
        const nowcastDTO = dto.Obj as NewNowcastEventArgsDTO;
        const fv = this.forecastService.forecastVersionById(nowcastDTO.ForecastVersionId);
        if (fv) {
          this.fVarService.fetchById(nowcastDTO.ForecastVersionId, nowcastDTO.NowcastedForecastVariableId);
        }
        break;
      }

      default:
        console.error(`Missing behaviour for action: (${dto.Action})`);
        return;
    }
  }
}
