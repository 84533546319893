import { Def } from '@core/decorators/def';
import { NewsPostModel } from './news-post.model';

export class NewsListModel {
  @Def() Count: number;
  @Def() LastSkip: number;
  @Def() LastTake: number;
  @Def() NextSkip: number;
  @Def() NextTake: number;
  @Def() Page: number;
  @Def() Pages: number;
  @Def() NewsPosts: NewsPostModel[];
}
