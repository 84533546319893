<h1 mat-dialog-title>Create test users</h1>
<div mat-dialog-content>
  Create a bunch of new companies, each with new users

  <mat-form-field>
    <mat-label>User count per company</mat-label>
    <input type="number"
           class="material"
           matInput
           autocomplete="off"
           [(ngModel)]="data.UserCount"
           name="UserCount"
           indicioAutoFocusInput
           max=15
           min=1
           require>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Company count</mat-label>
    <input type="number"
           class="material"
           matInput
           autocomplete="off"
           [(ngModel)]="data.CompanyCount"
           name="CompanyCount"
           max=40
           min=1
           require>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
  <button mat-flat-button
          (click)="save()">
    Create
  </button>
</div>
