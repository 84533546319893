import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { CompanyModel } from '@core/store/company/company.model';
import { CreateUserDTO } from '@modules/admin/components/system/users/entities/create-user.dto';

export interface CreateUserDialogData {
  Companies: CompanyModel[];
  Dto: CreateUserDTO;
}

@Component({
  selector: 'indicio-create-user-dialog',
  templateUrl: 'create-user.dialog.html',
})
export class CreateUserDialogComponent {
  constructor(
    public envService: EnvironmentService,
    public dialogRef: MatDialogRef<CreateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateUserDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  //

  save() {
    this.dialogRef.close(this.data.Dto);
  }
}
