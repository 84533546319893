<h1 mat-dialog-title>Macrobond series information</h1>
<div mat-dialog-content>
  <ng-container *ngIf="!loading && entityInfo; else loaderTemplate">
    <ng-container *ngIf="entityInfo?.getGroup('Time series') as timeInfo;">
      <div class="row extra-margin-top">
        <div class="col-xs-4">Periodicity:</div>
        <div class="col-xs-8">
          {{ timeInfo.getInfo('Frequency').value }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-4">Date range:</div>
        <div class="col-xs-8">
          {{ timeInfo.getInfo('Start date').value | dateFormat }} -
          {{ timeInfo.getInfo('Last value date').value | dateFormat }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-4">Units:</div>
        <div class="col-xs-8">
          {{ timeInfo.getInfo('Unit').value }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-4">Code:</div>
        <div class="col-xs-8 selectable-text">
          {{ data.RemoteReferenceId }}
        </div>
      </div>
      <div class="row"
           *ngIf="timeInfo.getInfo('Entity state').value !== 'Active'">
        <div class="col-xs-4">Discontinued:</div>
        <div class="col-xs-8">
          Yes
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="entityInfo?.getGroup('Source') as sourcInfo;">
      <h3 class="margin-top bold italic">Source</h3>
      <div class="grey margin-bottom-10">{{ sourcInfo.title}}</div>
      <div class="row">
        <div class="col-xs-4">Region</div>
        <div class="col-xs-8">{{ sourcInfo.getInfo('Region')?.value }}</div>
      </div>
      <div class="row">
        <div class="col-xs-4">Country of domicile</div>
        <div class="col-xs-8">{{ sourcInfo.getInfo('Country of domicile')?.value }}</div>
      </div>
      <div class="row"
           *ngIf="sourcInfo.getInfo('Info link')?.value as link">
        <div class="col-xs-4">Info link</div>
        <div class="col-xs-8 crop-string"><a [href]="link"
             target="_blank">{{ link }}</a></div>
      </div>
    </ng-container>
    <ng-container *ngIf="entityInfo?.getGroup('Release') as releaseInfo;">
      <h3 class="margin-top bold italic">Release</h3>
      <div class="grey margin-bottom-10">{{ releaseInfo.title}}</div>
      <div class="row"
           *ngIf="releaseInfo.getInfo('Last release time')">
        <div class="col-xs-4">Last release date</div>
        <div class="col-xs-8">{{ releaseInfo.getInfo('Last release time').value | dateFormat }}</div>
      </div>
      <div class="row"
           *ngIf="releaseInfo.getInfo('Next release time')">
        <div class="col-xs-4">Next release date</div>
        <div class="col-xs-8">{{ releaseInfo.getInfo('Next release time').value | dateFormat }}</div>
      </div>
      <div class="row"
           *ngIf="releaseInfo.getInfo('Info link')?.value as link">
        <div class="col-xs-4">Info link</div>
        <div class="col-xs-8 crop-string"><a [href]="link"
             target="_blank">{{ link }}</a></div>
      </div>
      <div class="row"
           *ngIf="releaseInfo.getInfo('History link')?.value as link">
        <div class="col-xs-4">History link</div>
        <div class="col-xs-8 crop-string"><a [href]="link"
             target="_blank">{{ link }}</a></div>
      </div>
    </ng-container>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Close
  </button>
  <button *ngIf="unauthorized"
          mat-flat-button
          (click)="setupMb()">
    Setup connection
  </button>
</div>

<ng-template #loaderTemplate>
  <span *ngIf="unauthorized">You need an active Macrobond account to view this information</span>
  <div *ngIf="!unauthorized">
    Loading macrobond series info <span *ngIf="loading"
          class="ion-load-c spinner"></span>
  </div>
</ng-template>
