import { ColumnType } from '@modules/forecast/views/add-data/file-sheet/types/types';
import { FileUploadState } from '@shared/modals/files/upload-file/upload-file-modal.component';
import { ValueUtils } from '@shared/utils/value.utils';
import { FileUpdateInfoDTO } from '../dtos/file-update-info.dtos';
import { ConfirmedNewLocationDTO, VariableUpdateInfoDTO } from '../dtos/variable-update-info-dto';
import { UploadedFileModel } from './uploaded-file.model';

export class FileInfoModel {
  Valid: boolean;
  FileToUpload: UploadedFileModel;
  NewFile: UploadedFileModel;
  OldFile: UploadedFileModel;
  UpdateInfo: FileUpdateInfoDTO;
  VariableLocations: ConfirmedNewLocationDTO[] = [];
  State: FileUploadState = 'initializing';

  public get sheetCount() {
    if (!this.UpdateInfo) { return; }
    return this.UpdateInfo.Preview.length;
  }

  public getVariable(sourceId: string) {
    return this.UpdateInfo.Variables.find(x => x.SourceVariableId === sourceId);
  }

  public getNewLocation(sourceId: string, sheet: number, dateIdx: number, valueIdx: number) {
    const variable = this.getVariable(sourceId);
    return variable.NewLocations.find(x => x.NewValueIndex === valueIdx && x.NewSheet === sheet && x.NewDateIndex === dateIdx);
  }

  public getSheetname(sheet: number) { return this.UpdateInfo?.Preview[sheet]?.SheetName ?? sheet; }
  public get anyNonNameChanges() { return this.anyVariableWithPossibleNewLocation || this.anyHashStartMoved || this.anyVariableNotFound || this.anyVariableWithSingleNonExactMatch; }
  public get anyHashStartMoved() {
    return this.UpdateInfo && this.UpdateInfo.Variables
      .some(v => v.NewLocations.some(newLoc => newLoc.NewHashStartRow !== v.OldHashStartRow));
  }

  public get containsLessThanOneValues() { return this.FileToUpload && this.FileToUpload.containsLT1Values; }
  public get anyVariableWithSingleNonExactMatch() { return this.UpdateInfo && this.UpdateInfo.Variables.some(v => v.NewLocations.length === 1 && !v.NewLocations[0].Exact); }
  public get anyVariableWithPossibleNewLocation() { return this.variablesWithPossibleNewLocations.length > 0; }
  public get variablesWithPossibleNewLocations() {
    return this.UpdateInfo.Variables
      .filter(v => v.NewLocations.length > 1 ||
        v.NewLocations.length && v.NewLocations.some(loc => loc.NewDateIndex !== v.OldDateIndex || loc.NewSheet !== v.OldSheet || loc.NewValueIndex !== v.OldValueIndex));
  }

  public get anyVariableNotFound() { return this.UpdateInfo.Variables.some(x => !x.NewLocations.length); }
  public get variablesWithConfirmedLocation() {
    return this.UpdateInfo.Variables.filter(v => this.variableHasConfirmedLocation(v));
  }

  public get nameChanged() { return !(this.OldFile?.FileName === this.FileToUpload.FileName); }
  public get needInput() {
    return !this.OldFile ||
      this.UpdateInfo && this.UpdateInfo.Variables.some(x => !x.Found ||
        x.NewLocations.length !== 1 ||
        x.NewLocations.some(y => !y.Exact));
  }
  public get canUpload() { return !this.OldFile || this.UpdateInfo && this.VariableLocations.every(loc => this.isLocationComplete(loc) || loc.SkipUpdate); }
  public get proceedToCreateVariables() {
    const usedIndexes = this.VariableLocations.map(x => x.NewValueIndex);
    const uniqueIndexes = [...new Set(usedIndexes)];
    const valueCols = this.UpdateInfo?.Preview
      .map(x => x.ParsedColumns)
      .flatten()
      .filter(x => x.ColumnType === 'Values' || x.ColumnType === 'MaxValues' || x.ColumnType === 'MinValues');

    return this.FileToUpload.errorStatus ? false : !valueCols ? true : valueCols.length > uniqueIndexes.length;
  }

  public getColumnName(sheet: number, column: number) {
    const col = this.getColumnByLocation(sheet, column);
    return col?.ColumnName || '';
  }

  public getColumnByLocation(sheetIndex: number, column: number) {
    const sheet = this.UpdateInfo.Preview[sheetIndex];
    if (!sheet) { return; }
    const col = sheet.ParsedColumns.find(x => x.ColumnIndex === column);
    return col;
  }

  public getAllPossibleDateColumnIndexes(sheetIndex: number) {
    const dateColumns = this.getColumnsByType(sheetIndex, 'Dates');
    return dateColumns.map(x => x.ColumnIndex);
  }

  public getAllPossibleValueColumnIndexes(sheetIndex: number) {
    const valueColumns = this.getColumnsByType(sheetIndex, 'Values');
    return valueColumns.map(x => x.ColumnIndex);
  }

  private getColumnsByType(sheetIndex: number, type: ColumnType) {
    const sheet = this.UpdateInfo.Preview[sheetIndex];
    if (!sheet) { return []; }
    const columns = sheet.ParsedColumns.filter(x => x.ColumnType === type);
    return columns;
  }

  public variableHasConfirmedLocation(variable: VariableUpdateInfoDTO) {
    const confirmedLocation = this.getVariableConfirmedLocation(variable);
    if (!confirmedLocation) { return false; }
    return this.isLocationComplete(confirmedLocation);
  }

  public getVariableConfirmedLocation(variable: VariableUpdateInfoDTO) {
    return this.VariableLocations.find(x => x.SourceVariableId === variable.SourceVariableId);
  }

  public isLocationComplete(location: ConfirmedNewLocationDTO) {
    const isComplete = ValueUtils.isNum(location.NewDateIndex, false) && ValueUtils.isNum(location.NewSheet, false) && ValueUtils.isNum(location.NewValueIndex, false);
    return isComplete;
  }

  public getVariableValueCount(location: ConfirmedNewLocationDTO) {
    if (!this.isLocationComplete(location)) { return; }
    if (location.Diff && location.Diff.ValueCount) { return location.Diff.ValueCount; }
    return 'NA';
  }
}
