import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterService } from '@core/services/frontend/filter.service';
import { IDialogFilter } from '@dialogs/generic/filter/filter.dialog.constants';

@Component({
  selector: 'indicio-active-filters',
  templateUrl: './active-filters.component.html',
  styleUrls: ['./active-filters.component.less']
})
export class ActiveFiltersComponent {

  @Input() component: string;

  @Output() public filterRemovedEvent = new EventEmitter<{ filter: IDialogFilter, value?: string; }>();

  public get componentFilters() { return this.filtersService.getComponentFilter(this.component); }
  public get basicFilters() { return this.componentFilters.activeFilters.filter(x => !Array.isArray(x.value)); }
  public get arrayFilters() { return this.componentFilters.activeFilters.filter(x => Array.isArray(x.value)); }

  constructor(private filtersService: FilterService) { }

  public getArrayDisplay(value: string, filter: IDialogFilter) {
    return filter.values.find(x => x.value.toString() === value.toString())?.display;
  }

  public removeFilter(filter: IDialogFilter, value?: string) {
    this.componentFilters.resetFilter(filter, value);
    this.filterRemovedEvent.emit({ filter, value });
  }
}
