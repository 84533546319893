import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { AuthState } from '@core/store/auth/auth.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  private idReg;
  private scbReg = /api\.scb\.se/;
  private inviteReg = /v1\/invite/;
  private id1 = /my\/user\/resetpassword/;
  private id2 = /my\/user\/forgotpassword/;
  private fredReg = /api\.stlouisfed\.org/;

  constructor(
    private store: Store,
    private envService: EnvironmentService
  ) {
    this.idReg = new RegExp(this.envService.env.UriIdentityApi + 'token');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.match(this.idReg) ||
      req.url.match(this.scbReg) ||
      req.url.match(this.fredReg) ||
      req.url.match(this.inviteReg) ||
      req.url.match(this.id1) ||
      req.url.match(this.id2)) {
      return next.handle(req);
    }

    const token = this.store.selectSnapshot(AuthState.token);
    if (!token) {
      return next.handle(req);
    }

    const clonedReq = req.clone({ setHeaders: { Authorization: 'bearer ' + token.access_token } });
    return next.handle(clonedReq);
  }
}
