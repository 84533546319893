import { Def } from '@core/decorators/def';

export class FileMetaDataDTO {
  @Def() UploadedFileVersionId: string;
  @Def() SheetIndex: number;
  @Def() DateIndex: number;
  @Def() ValueIndex: number;
  @Def() IdentifierIndex: number;
  @Def() Identifier: string;
  @Def() DisabledRows: number[];
}
