import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';

export class DateRangeOptions {
  public forecastVersion: ForecastVersionModel;
  public forecast: ForecastModel;
  public canUpdateForecast: boolean;
  public width: number = 750;
}

export class DateRangeSliderOptions {
  public length: number;
  public startIdx: number;
  public oosBarEnabled: boolean;
  public testSize: number;
  public endIdx: number;
  public forecastHorizon: number;
  public firstDate: string;
  public lastDate: string;
  public periodicity: PeriodicityType;
  public maxLag: number;
  public maxEndIdx: number;
  public testStartIdx: number;
  public width: number;
  public maxHorizon: number;
  public disabled: boolean;
}

export interface DateRangeLimits {
  minStartIdx: number;
  maxStartIdx: number;
  minTestIdx: number;
  maxTestIdx: number;
  minEndIdx: number;
  expandingWindowInt: number;
}

export interface DataRangeSliderOutput {
  startIdx: number;
  testIdx: number;
  endIdx: number;
  horizon: number;
}

export namespace DateRange {

  export type DR_HANDLES = 'start' | 'end' | 'test';

  /* Width in pixels for the component */
  export const RANGE_START: number = 0;

  export const testBarHeight = 30;
  export const testBarWidth = 8;
  export const barHeight = 7;
  export const circleRadius = 10;
  export const cornerRadius = 5;
  export const handleOpacity = 0.8;
  export const handleColor = '#fff';
  export const missingBarColor = '#ccc';
  export const trainBarColor = '#6ac6d8';
  export const testBarColor = '#a388ff';
  export const forecastBarColor = '#e2df00';
  export const maxForecastBarColor = '#f8f7b7';
}
