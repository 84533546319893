import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export const GenericTableColumnStyles = ['[bold]', '[noCrop]'];

export interface GenericTableDialogData {
  Title?: string;
  Subtitle?: string;
  Message?: string;
  Table: string[][];
  GridTemplate: string;
  // Put titles to the left
  Transpose: boolean;
}

@Component({
  selector: 'indicio-generic-table-dialog',
  templateUrl: 'generic-table.dialog.html',
  styleUrls: ['./generic-table.dialog.less']
})
export class GenericTableDialogComponent {
  public static Id: string = 'GenericTableDialogComponent';

  public styles = GenericTableColumnStyles;

  public titleRow: string[];
  public dataRows: string[][];

  constructor(
    public dialogRef: MatDialogRef<GenericTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericTableDialogData) {

    this.titleRow = data.Table[0];
    this.dataRows = data.Table.slice(1);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(true);
  }
}
