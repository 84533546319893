import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { AuditLogDTO } from '@modules/admin/entities/audit-log.dto';
import { AuditLogSearchDTO } from '@modules/admin/entities/audit-search.dto';
import { ClientMetaDTO } from '@modules/admin/entities/client-meta.dto';
import { AdminFrontendService } from '@modules/admin/services/admin-frontend.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DateUtils } from '@shared/utils/date.utils';


@Component({
  selector: 'indicio-user-info-admin-dialog-audit-tab',
  templateUrl: './user-info-tab.audit.component.html',
  styleUrls: ['./user-info-tab.audit.component.less'],
})
export class UserInfoAuditTabComponent implements OnChanges {

  @Input() client: ClientMetaDTO;

  private dto = new AuditLogSearchDTO;
  private loadMorePending: boolean;
  private lastLoadFrom: number;

  public loadAudits = false;
  public logs: AuditLogDTO[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private adminService: AdminFrontendService,
    public appearance: AppearanceService,
    public envService: EnvironmentService,
    private dialog: DialogService,
  ) {
  }

  public ngOnChanges() {
    if (!this.client) { return; }
    this.dto.SubjectId = this.client.Email;
    this.getAuditLogs();
    this.cd.detectChanges();
  }

  public openAuditInfo(log: AuditLogDTO) {
    const table = [['Key', 'Content'], ...Object.keys(log).map(k => ([k, log[k], '[noCrop]']))];
    this.dialog.openGenericTableDialog({
      Title: 'Audit information',
      GridTemplate: '140px 1fr',
      Table: table,
      Transpose: false
    }, { width: '500px', position: { top: '4%' } });
  }

  public getAuditLogs() {
    this.loadAudits = true;

    const dto = Object.assign({}, this.dto);

    if (dto.From && !dto.To) {
      dto.To = DateUtils.newMoment(dto.From).endOf('d').toDate();
    }

    this.adminService
      .getAuditLogs(dto)
      .then(logs => {
        this.logs.push(...logs);
        this.dto.Skip += this.dto.Take;
      })
      .catch(err => { })
      .finally(() => {
        this.loadAudits = false;
        this.cd.detectChanges();
      });
  }

  async loadMore() {
    if (this.loadMorePending || this.lastLoadFrom === this.dto.Skip) {
      return;
    }

    this.loadMorePending = true;
    this.lastLoadFrom = this.dto.Skip;
    await this.getAuditLogs();

    this.loadMorePending = false;
  }
}
