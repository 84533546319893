import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ScenarioDTO } from '@core/store/scenario/dtos/scenario.dtos';

@Component({
  selector: 'indicio-scenario-dialog-general-tab',
  templateUrl: './scenario-tab.general.component.html',
  styleUrls: ['./scenario-tab.general.component.less']
})
export class ScenarioGeneralTabComponent implements OnChanges {

  @Input() scenario: ScenarioDTO;
  @Output() inputEvent: EventEmitter<true> = new EventEmitter<true>();

  public isLoading: boolean = true;

  constructor() { }

  public ngOnChanges() {
    this.setup();
  }

  private async setup() {
    this.isLoading = false;
  }
}
