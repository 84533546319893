import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { DisplayValue } from '@modules/lang/types/display-value';

export interface DropdownInputDialogData {
  Title: string;
  Text: string;
  Label: string;
  Values: DisplayValue<any>[];
  CancelText?: string;
  ConfirmText?: string;
  MultiSelect?: boolean;
}

@Component({
  selector: 'indicio-dropdown-input-dialog',
  templateUrl: 'dropdown-input.dialog.html',
})
export class DropdownInputDialogComponent {
  public static Id: string = 'DropdownInputDialogComponent';

  public answer: DisplayValue<any>;
  public answers: DisplayValue<any>[];
  public options: DisplayValue<any>[];

  constructor(
    public dialogRef: MatDialogRef<DropdownInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DropdownInputDialogData) {
    if (!data.CancelText) { data.CancelText = 'Cancel'; }
    if (!data.ConfirmText) { data.ConfirmText = 'Save'; }
    this.options = data.Values;
    this.answer = data.Values[0];
  }

  public setAnswer(inp: MatSelectChange) {
    if (this.data.MultiSelect) {
      this.answers = inp.value;
    } else {
      this.answer = inp.value;
    }
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.data.MultiSelect ? this.answers : this.answer);
  }
}
