import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { RemovedStatus } from '@core/types/removed.status.type';
import { DateUtils } from '@shared/utils/date.utils';
import * as moment from 'moment';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';

@jsonObject
export class HistoricEventModel implements IHasModelId {
  @jsonMember HistoricEventId: string = null;
  @jsonMember HistoricBaseEventId: string = null;
  @jsonMember Name: string = null;
  @jsonMember EffectType: string = null;

  @jsonMember({ serializer: value => value.format(), deserializer: timestamp => DateUtils.newMoment(timestamp) })
  Date: moment.Moment = null;
  @jsonMember({ serializer: value => value ? value.format() : null, deserializer: timestamp => DateUtils.newMoment(timestamp) })
  EndDate?: moment.Moment;

  // frontend stuffz
  open: boolean = false;

  getModelId(): string { return this.HistoricEventId; }
}

@jsonObject
export class HistoricBaseEventModel implements IHasModelId {

  @jsonMember HistoricBaseEventId: string = null;
  @jsonMember CompanyId: string = null;
  @jsonMember Name: string = null;
  @jsonMember Type: string = null;
  @jsonMember Location: string = null;

  @jsonArrayMember(HistoricEventModel) Events: HistoricEventModel[] = [];

  removedStatus: RemovedStatus = 'Pending';
  failedMsg: string;
  errorStatus: string;

  open: boolean = true;

  Used: boolean = false;

  public getModelId(): string { return this.HistoricBaseEventId; }
}

export const HistoricEventSerializer = new TypedJSON(HistoricBaseEventModel);
