import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CloseMainMenuAction, NavigateToForecast } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { StatusService } from '@core/services/status/status.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { CReconciliationMethods } from '../../constants/hierarchy.constants';
import { AvailableForecastResultDTO, CreateReconciliationDTO, HierarchyReconciliationDTO, UpdateReconciliationDTO } from '../../entities/dtos/hierarchy-reconciliation.dto';
import { HierarchyRelationDTO } from '../../entities/dtos/hierarchy-relation.dto';
import { HierarchyModel } from '../../entities/models/hierarchy.model';
import { HierarchicalForecastFrontendService } from '../../services/hierachical-forecast.frontend.service';


export interface CreateOrUpdateReconciliationDialogData {
  hierarchy: HierarchyModel;
  reconciliation?: HierarchyReconciliationDTO;
}

@Component({
  selector: 'indicio-create-or-update-reconciliation-dialog',
  templateUrl: 'create-or-update-reconciliation.dialog.html',
  styleUrls: ['../hierarchy.dialog.less']
})
export class CreateOrUpdateReconciliationDialogComponent {

  // Dialog identifier
  public static Id: string = 'create-or-update-reconciliate-hierarchical-forecast';

  // Entities
  public createDto: CreateReconciliationDTO = null;
  public updateDto: UpdateReconciliationDTO = null;
  public hierarchy: HierarchyModel = null;

  private oldResultIds: string[] = [];

  // Options
  public reconciliationMethods = CReconciliationMethods;
  public startDate: string;

  // Status
  public isLoading: boolean;
  public isUpdate: boolean;
  public hasError: boolean;

  public get dto(): CreateReconciliationDTO | UpdateReconciliationDTO { return this.isUpdate ? this.updateDto : this.createDto; }
  public get recalcNeeded() {
    return this.oldResultIds.join(',') !== this.updateDto.ForecastResults.map(x => x.ForecastResultId).join(',')
      || this.updateDto.Method !== this.data.reconciliation.Method || this.updateDto.NonNegative !== this.data.reconciliation.NonNegative;
  }
  public get allRelations() { return this.hierarchy.Relations; }

  constructor(
    public dialogRef: MatDialogRef<CreateOrUpdateReconciliationDialogComponent>,
    public appearance: AppearanceService,
    public env: EnvironmentService,
    private status: StatusService,
    private hierForecastService: HierarchicalForecastFrontendService,
    private store: Store,
    private navService: NavigationService,
    @Inject(MAT_DIALOG_DATA) public data: CreateOrUpdateReconciliationDialogData
  ) {
    this.isLoading = true;
    this.isUpdate = !!data.reconciliation;
    this.setup();
  }

  public close() {
    this.dialogRef.close(null);
  }

  public onResultChange(relation: HierarchyRelationDTO, resultid: string) {
    this.dto.ForecastResults.find(x => x.RelationId === relation.RelationId).ForecastResultId = resultid;
  }

  public create() {
    this.checkForecastResults();

    this.hierForecastService.createReconciliation(this.hierarchy, this.dto)
      .then(newReconciliation => {
        this.store.dispatch(new CloseMainMenuAction);
        this.status.setMessage('Calculation queued', 'Success');
        this.dialogRef.close(newReconciliation);
      })
      .then(() => this.navService.navToUrlWithQuery(`/reports/${this.data.hierarchy.HierarchyId}`, { reconciliation: true }))
      .catch(err => this.status.setError(err))
      ;
  }

  public update() {
    this.checkForecastResults();

    this.hierForecastService.updateReconciliation(this.hierarchy, this.data.reconciliation, this.updateDto)
      .then(() => this.recalcNeeded)
      .then(retriggered => {
        let statustext = 'Reconciliation saved';
        retriggered && (statustext += ' and calculation queued');
        this.status.setMessage(statustext, 'Success');
      })
      .then(() => this.dialogRef.close(true))
      .catch(err => this.status.setError(err, true))
      ;
  }

  public gotoSummary(relation: HierarchyRelationDTO) {
    this.store.dispatch(new NavigateToForecast(relation.ForecastId, 'overview', () => {
      this.store.dispatch(new NavigateToForecast(relation.ForecastId, 'summary'));
    }));
    this.close();
  }

  private setup() {
    this.hierarchy = this.data.hierarchy;
    if (this.hierarchy.Relations.some(x => !x.ForecastId)) {
      this.status.setMessage('One or more of the nodes in the hierarchy does not have a forecast.', 'Error');
      return this.close();
    }

    if (!this.isUpdate) {
      this.createDto = {
        Method: 'shr',
        NonNegative: this.hierarchy.NonNegative,
        ForecastResults: this.hierarchy.Relations.map(r => ({
          ForecastId: r.ForecastId,
          RelationId: r.RelationId,
          ForecastResultId: null
        }))
      };
    } else {
      this.updateDto = {
        Name: this.data.reconciliation.Name,
        Method: this.data.reconciliation.Method,
        NonNegative: this.data.reconciliation.NonNegative,
        ForecastResults: this.hierarchy.Relations.map(r => ({
          ForecastId: r.ForecastId,
          RelationId: r.RelationId,
          ForecastResultId: this.data.reconciliation.ReconciliationResults.find(x => x.RelationId === r.RelationId).FromForecastResultId
        }))
      };
      this.oldResultIds = this.updateDto.ForecastResults.map(x => x.ForecastResultId);
    }
    this.hierForecastService.getAvailableResults(this.hierarchy.HierarchyId)
      .then(results => {
        this.mapResultsToHierarchy(results);
        this.setAvailableResults();
      })
      .catch(err => { this.status.setError(err, true); this.hasError = true; })
      .finally(() => this.isLoading = false);
  }

  private mapResultsToHierarchy(results: AvailableForecastResultDTO[]) {
    this.hierarchy.Relations.forEach(rel => rel.availableResults = results.filter(x => x.RelationId === rel.RelationId));
  }

  public setAvailableResults() {
    this.hierarchy.Relations.forEach(rel => {
      if (rel.availableResults?.length) {
        if (!this.isUpdate) {
          rel.selectedResultId = rel.availableResults[0]?.ForecastResultId;
        } else {
          rel.selectedResultId = this.updateDto.ForecastResults.find(x => x.RelationId === rel.RelationId).ForecastResultId;
        }
      }
    });
  }

  private checkForecastResults() {
    this.dto.ForecastResults.forEach(r => {
      const relation = this.hierarchy.Relations.find(x => x.RelationId === r.RelationId);
      r.ForecastResultId = relation.selectedResultId;
    });
    if (this.dto.ForecastResults.some(x => !x.ForecastResultId)) {
      this.status.setError('One or more of the forecasts are missing a result to reconciliate.', true);
    }
  }
}
