import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ProjectDTO } from '@core/store/project/dtos/project-dto';
import { ProjectMemberDTO } from '@core/store/project/dtos/project-member-dto';
import { UpdateProjectDTO } from '@core/store/project/dtos/update-project-dto';
import { ProjectMemberModel } from '@core/store/project/project-member.model';
import { DateUtils } from '@shared/utils/date.utils';
import { ProjectModel } from './project.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectMapper {

  constructor(
    private environmentService: EnvironmentService
  ) {
  }

  public map(dto: ProjectDTO, model?: ProjectModel): ProjectModel {
    model = Object.faMapTo(model || new ProjectModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.VisibilityLevel = this.environmentService.getVisibilityLevel(dto.VisibilityLevelId);
    model.AggregationMethod = this.environmentService.getAggregationMethod(dto.AggregationMethodId);
    model.OutlierTypes = JSON.parse(dto.OutlierTypes);
    model.SeasonalCalendarEffects = JSON.parse(dto.SeasonalCalendarEffects);
    model.SeasonalOutlierTypes = JSON.parse(dto.SeasonalOutlierTypes);
    model.OutlierTypes.sort();
    model.SeasonalCalendarEffects.sort();
    model.SeasonalOutlierTypes.sort();
    model.Permissions = dto.Permissions;
    model.fetched = false;
    model.OwnerEmail = dto.OwnerEmail;
    model.ForecastIds = dto.ForecastIds;
    model.Members = dto.Members.map(member => this.mapMember(member));
    model.fetched = true;
    return model;
  }


  public mapMember(dto: ProjectMemberDTO) {
    const model = Object.faMapTo(new ProjectMemberModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.Country = this.environmentService.getCountry(dto.CountryCode);
    return model;
  }

  public toUpdate(model: ProjectModel): UpdateProjectDTO {
    const dto = Object.faMapTo(new UpdateProjectDTO(), model);
    dto.OutlierTypes = JSON.stringify(model.OutlierTypes);
    dto.SeasonalCalendarEffects = JSON.stringify(model.SeasonalCalendarEffects);
    dto.SeasonalOutlierTypes = JSON.stringify(model.SeasonalOutlierTypes);
    dto.Members = model.Members.map(member => Object.faMapTo(new ProjectMemberDTO(), member));
    return dto;
  }
}
