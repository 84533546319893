import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastDialogService } from '@dialogs/forecast/forecast-dialogs.service';
import { ForecastSettingsDialogViewIndex } from '@dialogs/forecast/settings/settings.dialog';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { ModelName } from '@modules/lang/types/model-name';
import { Store } from '@ngxs/store';
import { DataTable } from '@shared/components/data-table/data-table.model';
import { ShapGraphTypes } from '@shared/components/graphs/shap-graph/shap-graph.types';
import { ALGTypes } from '@shared/components/line-graph/alg-types';
import { ValueUtils } from '@shared/utils/value.utils';

export class ShapGraphDialogData {

  /* Object Ids */
  ForecastId: string;
  ForecastVersionId: string;
  ProjectId: string;

  /* Data */
  Periodicity: PeriodicityType;
  Dates: moment.Moment[];
  Moment: moment.Moment;
  ModelNames: ModelName[];
  ModelPoints: ALGTypes.AlgHoverPoint[];
  ShapPoints: ALGTypes.AlgShapHoverPoint[];
  GraphType: ALGTypes.Graph;

  /* Fn to fetch data for the dialog given a new date (moment) */
  getDataFn: (date: moment.Moment) => ShapGraphDialogData;
}

@Component({
  selector: 'indicio-shap-graph-dialog',
  templateUrl: 'shap-graph.dialog.html',
  styleUrls: ['./shap-graph.dialog.less']
})
export class ShapGraphDialogComponent {

  public static Id: string = 'ShapGraphDialogComponent';

  /* Selected information */
  public currentDate: moment.Moment;
  public currentModelName: ModelName;
  public modelCurrentValue: string;
  public modelPreviousValue: string;

  /* Data */
  public shapTable: DataTable = null;
  public graphData: ShapGraphTypes.Input = null;

  /* */
  public availableModelNames: ModelName[] = [];
  public availableDates: moment.Moment[];
  private isPercent: boolean;
  private get currentModel() { return this.data.ModelPoints.find(m => m.Model.modelName.Value === this.currentModelName.Value); }
  private get currentShapPoints() { return this.data.ShapPoints.filter(s => s.Model.modelName.Value === this.currentModelName.Value); }

  constructor(
    public dialogRef: MatDialogRef<ShapGraphDialogComponent>,
    private store: Store,
    private forecastService: ForecastFrontendService,
    private forecastDialogService: ForecastDialogService,
    @Inject(MAT_DIALOG_DATA) public data: ShapGraphDialogData
  ) {
    this.availableDates = this.data.Dates;
    this.isPercent = this.forecastService.forecastVersionById(data.ForecastVersionId)?.ForecastVariable?.IsPercent;
    this.changeActiveDate(this.data.Moment);
    this.setupOptions();
    this.setupView();
  }

  public changeActiveDate(newDate: moment.Moment) {
    const newData = this.data.getDataFn(newDate);
    this.currentDate = newData.Moment;
    this.data = newData;
    this.setupOptions();
    this.setupView();
  }

  public changeActiveModel() {
    this.setupView();
  }

  public openForecastSettings() {
    this.dialogRef.close(null);
    this.forecastDialogService.openSettings({
      ProjectId: this.data.ProjectId,
      ForecastId: this.data.ForecastId,
      View: ForecastSettingsDialogViewIndex.version
    });
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public save() {
    this.dialogRef.close({});
  }

  private setupOptions() {
    this.availableModelNames = this.data.ModelPoints.filter(m => !!m.Model.modelName).map(m => m.Model.modelName);
    this.currentModelName = this.currentModelName || this.availableModelNames[0];
  }

  private setupView() {
    const shapValues = this.currentShapPoints.map(i => [i.Shap.Name, this.getDisplayValue(i.Value.V)]);
    this.shapTable = {
      Title: 'Shapley additive explanations',
      ColumnConfig: '1fr 0.5fr',
      Columns: ['Variable', 'Shap value'],
      Rows: shapValues,
      TitleColumn: 0,
      PercentageColumns: []
    };
    this.modelCurrentValue = this.getDisplayValue(this.currentModel.Value.V);

    const dateIndex = this.currentModel.Model.Values.findIndex(x => x.m.isSame(this.currentDate));
    this.modelPreviousValue = this.getDisplayValue(this.currentModel.Model.Values[dateIndex - 1].V);
    this.graphData = { data: this.currentShapPoints.map(v => ({ Name: v.Shap.Name, Value: v.Value.V * (this.isPercent ? 100 : 1) })), isPercent: this.isPercent };
  }

  private getDisplayValue(v: number) {
    return ValueUtils.getValueAsAbbreviatedString(v * (this.isPercent ? 100 : 1), this.isPercent);
  }
}
