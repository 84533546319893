import { Component, ViewEncapsulation } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { RefreshForecastVariableStepEventArgsDTO } from '@core/store/forecast/dtos/forecast-version/pusher-event-args.dto';
import { ForecastActions } from '@core/store/forecast/forecast.actions';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { UpdateMultiIndicatorsModalOpts } from './update-multi-indicators-modal.options';


@Component({
  selector: 'indicio-sheet-import-all-modal',
  templateUrl: './update-multi-indicators-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class UpdateMultiIndicatorsModalComponent extends ModalModelComponent {

  opts: UpdateMultiIndicatorsModalOpts;
  toUpdate: ForecastVariableModel[];
  forecastVersion: ForecastVersionModel;
  stepStatus: RefreshForecastVariableStepEventArgsDTO;
  updatedCount = 0;
  processDone = false;
  newVersion = false;

  public get toUpdateCount() { return this.toUpdate.filter(x => x.shouldUpdate)?.length; }

  constructor(
    protected store: Store,
    public envService: EnvironmentService,
    private forecastVariableService: ForecastVariableFrontendService,
    private forecastService: ForecastFrontendService,
    private actions: ActionService
  ) {
    super();
  }

  public setOptions(options: UpdateMultiIndicatorsModalOpts) {
    this.opts = options;

    const promises = this.forecastVariableService.getOrFetchByIds(options.forecastVersionId, options.indicatorIds, false);
    const fversionPromise = this.forecastService.getOrFetchForecastVersion(this.opts.forecastVersionId);
    Promise.all([fversionPromise, Promise.all(promises)]).then(([fversion, indicators]) => {
      this.forecastVersion = fversion;
      this.toUpdate = indicators;
      this.newVersion = this.toUpdate.some(x => !x.IsIndicator);
      this.toUpdate.forEach(x => {
        x.shouldUpdate = x.UpdatedValuesExists;
        x.updateStatus = 'pending';
      });
      if (this.newVersion) {
        this.stepStatus = new RefreshForecastVariableStepEventArgsDTO();
        this.stepStatus.IndicatorCount = fversion.IndicatorVariables.filter(x => !x.IsTrend && !x.IsMixedFreq).length;
        this.setupActionListener();
      }
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private setupActionListener() {
    this.actions.dispatched(ForecastActions.SetRefreshFVarStepInfo).subscribe((e: ForecastActions.SetRefreshFVarStepInfo) => {
      this.stepStatus = e.obj;
    });
  }

  public toggleUpdate(indicator: ForecastVariableModel) {
    indicator.shouldUpdate = !indicator.shouldUpdate;
    if (indicator.shouldUpdate) {
      indicator.updateStatus = 'pending';
    } else {
      indicator.updateStatus = 'skipped';
    }
  }

  public async update() {
    this.pending = true;
    const toUpd = this.toUpdate.filter(x => x.shouldUpdate);
    const count = toUpd.length;
    const updateFunc = async (i) => {
      const indicator = toUpd[i];
      await this.updateSourcevariable(indicator);
    };

    let current = 0;
    const queueFunc = async () => {
      if (current >= count) {
        this.pending = false;
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return;
      }
      await updateFunc(current);
      current += 1;
      setTimeout(async () => {
        await queueFunc();
      }, 150);
    };

    await queueFunc()
      .finally(() => this.processDone = true);
  }

  private async updateSourcevariable(indicator: ForecastVariableModel) {
    indicator.updateStatus = 'pending';
    indicator.updateInProgress = true;
    return this.forecastVariableService.updateForecastVariableValues(indicator.ForecastVersionId, indicator.ForecastVariableId)
      .then(_updated => {
        indicator.updateStatus = 'success';
      })
      .catch(e => {
        indicator.updateStatus = 'failed';
        if (typeof e === 'string' && e.match(/<->/)) {
          const infos = e.replace(/<->/g, '<br>').replace(/,/g, '');
          indicator.updateError = infos;
        } else if (e.error?.error) {
          indicator.updateError = e.error.error;
        } else if (e.error?.Message) {
          indicator.updateError = e.error.Message;
        } else {
          indicator.updateError = 'Something went wrong';
        }
      })
      .finally(() => {
        this.updatedCount += 1;
        indicator.updateInProgress = false;
      });
  }
}
