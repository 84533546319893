import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { WikiConstants } from '@core/store/wiki/wiki.constants';
import { CreateWikiPageDTO, UpdateWikiPageDTO, WikiPageAdminMetaDTO, WikiPageDTO, WikiPageSize } from '@core/store/wiki/wiki.entities';
import { WikiMapper } from '@core/store/wiki/wiki.mapper';
import { WikiService } from '@core/store/wiki/wiki.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpaghettiMonsterFlying } from '@fortawesome/free-solid-svg-icons';
import { AdminWikiService } from '@modules/admin/components/content/wiki/wiki-admin.service';
import { ResizeOutput } from '@shared/directives/resizable.directive';
import { StorageUtils } from '@shared/utils/storage.utils';
import { StringUtils } from '@shared/utils/string.utils';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { DialogService } from '../../../dialog.service';

export interface CreateEditWikiPageDialogData {
  WikiSlug?: string;
}

@Component({
  selector: 'indicio-dialog-create-wikipage',
  templateUrl: 'create-edit-wikipage.dialog.html',
  styleUrls: ['create-edit-wikipage.dialog.less'],
})
export class CreateEditWikiPageDialogComponent implements OnInit, OnDestroy {
  public static Id: string = 'CreateWikiPageDialogComponent';

  katexIcon: IconProp = faSpaghettiMonsterFlying;

  @ViewChild('content') content: ElementRef;


  public previewWidth: string = '1fr';

  public createDTO: CreateWikiPageDTO = Object.assign(new CreateWikiPageDTO, {
    ParentSlug: null,
    Content: '',
    Title: null,
    Published: true,
    PageSize: null
  });

  public sizes: WikiPageSize = {
    Width: '0',
    Height: '0'
  };

  public restored: boolean = false;
  public validParents: WikiPageAdminMetaDTO[] = [];
  private editPage: WikiPageDTO;

  public get selectedParent() { return this.validParents.find(x => x.Slug === this.createDTO.ParentSlug); }
  public get disabledCreate() { return !this.createDTO.Title || !this.createDTO.Content || !this.createDTO.ParentSlug; }

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CreateEditWikiPageDialogComponent>,
    public adminService: AdminWikiService,
    public wikiService: WikiService,
    private dialogService: DialogService,
    private status: StatusService,
    private cd: ChangeDetectorRef,
    private wikiMapper: WikiMapper,
    @Inject(MAT_DIALOG_DATA) public data: CreateEditWikiPageDialogData) {
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public ngOnInit() {
    if (this.data.WikiSlug) {
      this.loadPage();
    } else {
      const backup = StorageUtils.get(WikiConstants.STORAGE_KEY);
      if (backup) {
        this.createDTO = this.wikiMapper.mapCachedPage(backup);
        this.restored = true;
      }
      this.setValidParents();
    }
    this.cd.detectChanges();
  }

  public removeCache() {
    this.adminService.removeCachedPage(this.data.WikiSlug);
    this.loadPage();
  }

  public onResize(e: ResizeOutput) {
    this.previewWidth = `${Math.max(e.NewSize, 100)}px`;
  }

  public setCategory(category: WikiPageAdminMetaDTO) {
    this.createDTO.ParentSlug = category.Slug;
    this.cd.detectChanges();
  }

  public openAssets() {
    this.dialogService.openAdminManageWikiAssets({}).subscribe(category => {
      if (!category) { return; }
      this.insertString(`[indicio-asset:${category.AssetId} 0x0]`);
    });
  }

  public openPages() {
    this.dialogService.openAdminWikiPages({}).subscribe(pageLink => {
      if (!pageLink) { return; }
      this.insertString(pageLink);
    });
  }

  public setSlug() {
    if (!!this.data.WikiSlug) { return; }
    if (this.createDTO.Title) {
      this.createDTO.Slug = StringUtils.slugify(this.createDTO.Title);
    } else {
      this.createDTO.Slug = null;
    }
  }

  public backupPage() {
    if (this.editPage) {
      return StorageUtils.set(`${WikiConstants.STORAGE_KEY}.${this.editPage.Slug}`, this.createDTO);
    }
    StorageUtils.set(WikiConstants.STORAGE_KEY, this.createDTO);
  }


  private loadPage() {
    this.adminService.getPage(this.data.WikiSlug).then(page => {
      let cached = StorageUtils.get<CreateWikiPageDTO>(`${WikiConstants.STORAGE_KEY}.${this.data.WikiSlug}`);
      if (cached && moment(cached.ModifiedDate).isBefore(moment(page.ModifiedDate))) { cached = this.adminService.removeCachedPage(this.data.WikiSlug); }
      if (!!cached) { cached = this.wikiMapper.mapCachedPage(cached); }
      this.createDTO = cached || page;
      this.editPage = page;
      this.restored = !!cached;
      if (this.createDTO.ParentSlug === null) { this.createDTO.ParentSlug = 'noparent'; }
      if (this.createDTO.PageSize) {
        this.sizes = page.PageSize;
      }
      this.setValidParents();
      this.cd.detectChanges();
    });
  }

  private insertString(text: string) {
    const el = this.content.nativeElement;
    const [start, end] = [el.selectionStart, el.selectionEnd];
    const currentContent = this.createDTO.Content;
    const newContent = [currentContent.slice(0, start), text, currentContent.slice(end)].join('');
    this.createDTO.Content = newContent;

    this.backupPage();
  }

  private setValidParents() {
    const noparentID = this.adminService.wikiPages.find(x => x.Title === 'Home')?.Slug || 'noparent';
    this.validParents = [
      Object.assign(new WikiPageAdminMetaDTO, { Title: '* No parent *', Slug: noparentID }),
      ...!!this.editPage && this.adminService.wikiPages.filter(x => x.Slug !== this.editPage.Slug) || this.adminService.wikiPages
    ];
  }


  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.setPageDefaults(this.createDTO);
    this.adminService.createPage(this.createDTO)
      .then(() => {
        this.dialogRef.close(true);
        StorageUtils.remove(WikiConstants.STORAGE_KEY);
        this.status.setMessage('Wiki page created successfully', 'Success');
      })
      .catch(err => {
        this.status.setError(err, true);
      });
  }

  update() {
    this.setPageDefaults(this.createDTO);
    const page: UpdateWikiPageDTO = Object.assign(new UpdateWikiPageDTO, this.editPage);
    page.Content = this.createDTO.Content;
    page.Title = this.createDTO.Title;
    page.ParentSlug = this.createDTO.ParentSlug;
    page.PageSize = this.createDTO.PageSize;
    this.adminService.updatePage(page)
      .then(() => {
        this.dialogRef.close(true);
        StorageUtils.remove(`${WikiConstants.STORAGE_KEY}.${page.Slug}`);
        this.status.setMessage('Wiki page updated successfully', 'Success');
      })
      .then(() => this.wikiService.getOrFetchPage(page.Slug, true))
      .catch(err => {
        this.status.setError(err, true);
      });
  }

  private setPageDefaults(page: CreateWikiPageDTO) {
    page.ParentSlug = this.createDTO.ParentSlug === 'noparent' ? null : this.createDTO.ParentSlug;
    if (this.sizes.Height.length > 1 || this.sizes.Width.length > 1) { this.createDTO.PageSize = this.sizes; }
  }
}
