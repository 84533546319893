<indicio-modal-base [child]="this"
                    modalTitle="Variable base revisions"
                    modalClass=""
                    extraClasses="modal-content--min-height"
                    [loading]="isLoading">

  <ng-container *ngIf="!isLoading">
    <div style="width: 85%; margin: auto;">
      <ng-container>
        <div class="row"
             style="margin-top: -20px;">
          <div class="col-xs-7">
            <span class="input-label bold">Source variable revisions</span>
            <div class="subtitle">Pick a revision to diff</div>
          </div>
          <div class="col-xs-5 margin-top">
            <mat-form-field>
              <mat-select [value]="selectedRevisionId"
                          (selectionChange)="onSelectChange($event)">
                <ng-container *ngFor="let version of variable.Revisions; let i = index">
                  <mat-option [value]="version.SourceVariableBaseRevisionId"
                              [title]="'Revision '+ version.Revision">
                    {{ 'Revision '+ version.Revision }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- The diff -->
        <ng-container>
          <div class="row">
            <div class="col-xs-12">

              <ng-container *ngIf="addedValues.length">
                <div class="row extra-margin-top">
                  <div class="col-xs-12 bold text-center">Added data</div>
                </div>
                <div class="row"
                     style="margin-top: 5px;">
                  <div class="col-xs-12">
                    <div class="data-table">
                      <div class="data-table__header">
                        <div class="column header"
                             style="max-width: 70px;">Date</div>
                        <div class="column header"
                             *ngIf="categoryContainsValuePair(addedValues)">
                          Value
                        </div>
                      </div>
                      <div class="data-table__body">
                        <div class="body__row"
                             *ngFor="let row of addedValues; let i = index">
                          <div class="column crop-string"
                               style="max-width: 70px;"
                               [indicioTippy]="row.D | dateFormat">{{ row.D | dateFormat }}</div>
                          <div class="column text-center crop-string"
                               [indicioTippy]="row.N"
                               *ngIf="containsValuePair(row)">
                            {{ row.N }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="removedValues.length">
                <div class="row extra-margin-top">
                  <div class="col-xs-12 bold text-center">Removed data</div>
                </div>
                <div class="row"
                     style="margin-top: 5px;">
                  <div class="col-xs-12">
                    <div class="data-table">
                      <div class="data-table__header">
                        <div class="column header"
                             style="max-width: 70px;">Date</div>
                        <div class="column header"
                             *ngIf="categoryContainsValuePair(removedValues)">
                          Value
                        </div>
                      </div>
                      <div class="data-table__body">
                        <div class="body__row"
                             *ngFor="let row of removedValues; let i = index">
                          <div class="column crop-string"
                               style="max-width: 70px;"
                               [indicioTippy]="row.D | dateFormat">{{ row.D | dateFormat }}</div>
                          <div class="column text-center crop-string"
                               [indicioTippy]="row.N"
                               *ngIf="containsValuePair(row)">
                            {{ row.O }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="changedValues.length">
                <div class="row extra-margin-top">
                  <div class="col-xs-12 bold text-center">Changed data</div>
                </div>
                <div class="row"
                     style="margin-top: 5px;">
                  <div class="col-xs-12">
                    <div class="data-table">
                      <div class="data-table__header">
                        <div class="column header"
                             style="max-width: 70px;">Date</div>
                        <div class="column header"
                             *ngIf="categoryContainsValuePair(changedValues)">
                          Value
                        </div>
                      </div>
                      <div class="data-table__subheader">
                        <div class="column subheader"
                             style="max-width: 70px;"></div>
                        <div class="column subheader"
                             *ngIf="categoryContainsValuePair(changedValues)">
                          <div class="row">
                            <div class="col-xs-6 text-center">
                              Old
                            </div>
                            <div class="col-xs-6 text-center">
                              New
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="data-table__body">
                        <div class="body__row"
                             *ngFor="let row of changedValues; let i = index">
                          <div class="column crop-string"
                               style="max-width: 70px;"
                               [indicioTippy]="row.D | dateFormat">{{ row.D | dateFormat }}</div>
                          <div class="column text-center"
                               *ngIf="containsValuePair(row)">
                            <div class="row">
                              <div class="col-xs-6 crop-string"
                                   [indicioTippy]="row.O">
                                {{ row.O }}
                              </div>
                              <div class="col-xs-6 crop-string"
                                   [indicioTippy]="row.N">
                                {{ row.N }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>


            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</indicio-modal-base>
