<div class="missing-values-tab">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <ng-container *ngIf="variable.IsTrend"
                  [ngTemplateOutlet]="trendTemplate"></ng-container>
    <ng-container *ngIf="!variable.IsTrend">
      <ng-container [ngTemplateOutlet]="viewAccordion"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #viewAccordion>
  <mat-accordion>
    <mat-expansion-panel [expanded]="!!mvPlots?.length"
                         (opened)="infopanelOpen = true"
                         (closed)="infopanelOpen = false"
                         [hideToggle]="!mvPlots?.length">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Result
        </mat-panel-title>
        <mat-panel-description *ngIf="!infopanelOpen || !mvPlots?.length">
          {{ !mvPlots.length ? 'No missing values' : 'Images and graphs' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngIf="mvPlots?.length">
        <indicio-plot-display [plots]="mvPlots"
                              [plotWidth]="500"></indicio-plot-display>
      </ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Settings
        </mat-panel-title>
        <mat-panel-description>
          Missing values adjustment and detection
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="settingsTemplate"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #settingsTemplate>
  <!-- Model selection -->
  <indicio-modal-option-row optionTitle="Model"
                            optionSubtitle="Model to use for calculating missing values"
                            textWidth=8
                            optionsWidth=4>
    <div style="margin-top:15px">
      <mat-form-field>
        <mat-select [value]="missingModelType"
                    required>
          <mat-option [value]="model.Value"
                      (onSelectionChange)="setModel($event)"
                      [title]="model.Description"
                      *ngFor="let model of getValidMissingValueModels">{{ model.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>

  <!-- Indicator selection -->
  <indicio-modal-option-row optionTitle="Missing indicators"
                            optionSubtitle="Series to use when calculating the values replacing the missing values"
                            textWidth=8
                            optionsWidth=4
                            [disabled]="missingModelType !== 'amelia'">
    <div style="margin-top:15px">
      <mat-form-field>
        <mat-select [value]="indicatorsToUse"
                    [disabled]="missingModelType !== 'amelia'"
                    [multiple]="true"
                    required>
          <mat-option [value]="ind.value"
                      [title]="ind.display"
                      (onSelectionChange)="setIndicators($event)"
                      *ngFor="let ind of availableIndicators">{{ ind.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>

  <!-- Save updated settings?-->
  <div class="pull-right"
       style="width:fit-content; margin-top: 25px;"
       [indicioTippy]="missingModelType === 'amelia' && !canSave ? 'To use the Amelia model, you need to have selected at least one indicator' : null">
    <button mat-raised-button
            type="button"
            color="primary"
            [disabled]="!canSave || !changed"
            (click)="saveSettings()">
      <span *ngIf="!saveInProgress">Save changes</span>
      <span *ngIf="saveInProgress"
            class="ion-load-c spinner"></span>
    </button>
  </div>

</ng-template>


<ng-template #noMissingTemplate>
  <div style="height: 200px;"
       class="center-horizontal-and-vertical">
    <span>No missing values</span>
  </div>
</ng-template>

<ng-template #trendTemplate>
  <indicio-open-twin-component [options]="options"
                               [type]="'Missing values are'"
                               [tab]="viewEnum.missing"
                               [twinId]="variable.TrendTwinId"
                               (closeDialogEvent)="closeDialogEvent.emit()">
  </indicio-open-twin-component>
</ng-template>

<ng-template #loadingTemplate>

</ng-template>
