<indicio-dialog-base class="import-provider-dialog-container">
  <ng-container *ngTemplateOutlet="titleTemplate">
  </ng-container>
  <ng-container *ngIf="!loadingContent; else loadingTemplate">
    <ng-container *ngTemplateOutlet="contentTemplate">
    </ng-container>
    <ng-container *ngTemplateOutlet="btnTemplate">
    </ng-container>
  </ng-container>
</indicio-dialog-base>

<ng-template #titleTemplate>
  <h1 mat-dialog-title>{{ title }}</h1>
</ng-template>

<ng-template #contentTemplate>
  <div mat-dialog-content>
    <ng-continer *ngIf="discontinued"
                 [ngTemplateOutlet]="previewErrorTemplate"></ng-continer>
    <ng-container *ngIf="!disableGraph">
      <div *ngIf="!initPreview; else loadingGraph"
           class="row">
        <div class="col-xs-12 modal-chart">
          <div class="preview-loader"
               *ngIf="loadingPreview || !!previewError">
            <ng-container *ngIf="loadingPreview; else previewErrorTemplate">
              <indicio-spinner color="white"
                               [size]="25"></indicio-spinner>&nbsp;
              Loading..
            </ng-container>
          </div>
          <indicio-alg [class]="loadingPreview ? 'alg-loader' : null"
                       [style]="loadingPreview ? 'filter: grayscale(1) invert(1) brightness(0.7);' : null"
                       [periodicityType]="periodicity"
                       [HistoricLine]="previewLine"
                       [options]="algOptions"
                       [chartStyles]="chartStyles"
                       [showExport]="false"
                       graphType="generic"
                       #graph>
          </indicio-alg>
        </div>
      </div>

      <ng-template #loadingGraph>
        <div *ngIf="loadingPreview && !!!previewError; else previewErrorTemplate"
             style="margin: 0 0 20px 0;">
          <indicio-spinner></indicio-spinner> Loading graph data...
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngTemplateOutlet="variableHeaderTemplate">
    </ng-container>
    <ng-container *ngTemplateOutlet="variableNameTemplate">
    </ng-container>
    <ng-container *ngFor="let dim of dimensions">
      <ng-container *ngTemplateOutlet="dimensionTemplate; context: { dim }">
      </ng-container>
    </ng-container>

    <div class="extra-margin-top"
         style="background-color: var(--indicio-modal-alt-background-color, #f0f0f0); padding: 10px;"
         *ngIf="existingVars?.length">
      Will use previously imported variable. <indicio-wiki-icon slug="overview-source-variable"></indicio-wiki-icon>
    </div>

  </div>
</ng-template>

<ng-template #previewErrorTemplate>
  <div *ngIf="!!previewError"
       style="margin: 0 0 20px 0;">
    {{ previewError }}
  </div>
</ng-template>

<ng-template #variableHeaderTemplate>
  <h1>{{ variable.Title }}</h1>
  <div class="row">
    <div class="col-xs-4">
      Date range:
    </div>
    <div class="col-xs-8">
      {{ variable.StartDate | dateFormat: variable.Periodicity }} -
      {{ variable.EndDate | dateFormat: variable.Periodicity }}
    </div>
  </div>
  <div class="row">
    <div class="col-xs-4">
      Periodicity:
    </div>
    <div class="col-xs-8">
      {{ envService.getPeriodicity(variable.Periodicity).Display }}
    </div>
  </div>
  <div class="row"
       *ngIf="!disableGraph">
    <div class="col-xs-4">
      Data points:
    </div>
    <div class="col-xs-8">
      {{ !!preview?.length ? preview?.length : '-' }}
    </div>
  </div>
  <div class="row"
       *ngFor="let dim of oneOptionDimensions">
    <div class="col-xs-4"
         style="text-transform: capitalize">
      {{ dim.Display || dim.Code }}:
    </div>
    <div class="col-xs-8">
      {{ dim.Values[0].Name }}
    </div>
  </div>
  <hr>
</ng-template>

<ng-template #variableNameTemplate>
  <div class="row margin-top">
    <div class="col-xs-12">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input type="text"
               matInput
               [ngModelOptions]="{standalone: true}"
               (keyup)="checkName()"
               required
               [(ngModel)]="name"
               class="material">
        <mat-hint *ngIf="!importable && (nameMustChange || nameConflict)">
          <span class="error-text"
                style="padding-left:2px">
            {{ nameMustChange
            ? 'The variable name must between 2 and 256 characters long'
            : 'A source variable with that name already exists.'
            }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <mat-form-field>
        <mat-label>Aggregation method</mat-label>
        <mat-select [(value)]="aggregationMethodId"
                    required>
          <mat-option [value]="option.Value"
                      *ngFor="let option of envService.AggregationMethods">{{ option.Display }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #dimensionTemplate
             let-dim="dim">
  <ng-container *ngIf="getDim(dim) as dimension">
    <indicio-searchable-dropdown [label]="dimension.Display"
                                 optionDisplay="Name"
                                 [values]="dimension.Values"
                                 (valueChangeEvent)="setOption($event, dimension)">
    </indicio-searchable-dropdown>
  </ng-container>
</ng-template>

<ng-template #btnTemplate>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">
      Close
    </button>
    <div [indicioTippy]="disabled || !!previewError ? disabledText : null">
      <button mat-flat-button
              color="primary"
              [disabled]="loadingImport || disabled || !!previewError"
              (click)="import()">
        <i *ngIf="loadingImport"
           class="ion-load-c spinner"></i> Import
      </button>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div mat-dialog-content>
    <indicio-spinner></indicio-spinner> Loading content
  </div>
</ng-template>
