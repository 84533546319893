<indicio-dialog>
  <h1 mat-dialog-title>Create multiple forecasts</h1>
  <div mat-dialog-content
       *ngIf="variables?.length">
    <ng-container *ngTemplateOutlet="introTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="projectTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="forecastTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="variableListTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="statusTemplate"></ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">
      Cancel
    </button>
    <button mat-flat-button
            color="primary"
            [disabled]="!canCreate"
            (click)="createForecasts()">
      Create
    </button>
  </div>
</indicio-dialog>

<ng-template #introTemplate>
  You have selected a total of <span class="bold">{{ variables.length }}</span> variables.
  A forecast will be created for each variable, in the selected project, with the choosen forecast horizon.
</ng-template>

<ng-template #projectTemplate>
  <h3 style="margin-top: 20px;">Required</h3>
  <indicio-modal-option-row [stacked]="false"
                            optionTitle="Select a project for the forecasts"
                            optionSubtitle="Or click on create project to create a new project."
                            textWidth=7
                            optionsWidth=5>

    <mat-form-field style="margin-top: 10px">
      <mat-select [value]="project"
                  required
                  (selectionChange)="onProjectChange($event)"
                  name="project">
        <mat-option [value]="project"
                    [title]="project.Name"
                    *ngFor="let project of projects | orderBy: 'Name'">
          {{ project.Name }}
          <i class="ion-briefcase"
             *ngIf="project.VisibilityLevelId === 'company'"></i>
        </mat-option>
      </mat-select>
      <mat-hint class="cursor-pointer"
                (click)="createProject()">Create project</mat-hint>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #forecastTemplate>
  <indicio-modal-option-row [stacked]="false"
                            optionTitle="Select forecast horizon"
                            optionSubtitle="The number of forecasted points."
                            textWidth=7
                            optionsWidth=5>

    <mat-form-field style="margin-top: 15px">
      <mat-select [(ngModel)]="horizon"
                  required
                  name="horizon">
        <mat-option [value]="point"
                    [title]="point"
                    *ngFor="let point of horizonOpts">{{ point }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <h3 style="margin-top: 20px;">Optional</h3>

  <indicio-modal-option-row [stacked]="false"
                            optionTitle="Select a periodicity for all applicable forecasts"
                            optionSubtitle="Will be applied to all forecasts that can be aggregated to the selected periodicity."
                            textWidth=7
                            optionsWidth=5>

    <mat-form-field style="margin-top: 10px">
      <mat-select [(value)]="periodicityForAll"
                  (selectionChange)="onPeriodicityForAll($event)">
        <mat-option [value]="periodicity.Value"
                    *ngFor="let periodicity of validPeriodicities">{{ periodicity.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #variableListTemplate>
  <div class="data-table extra-margin-top"
       style="width: 700px;">
    <div class="data-table__header stable-scroll">
      <div class="column header"
           style="width: 35px; text-align: left; padding-left: 5px">#</div>
      <div class="column header"
           style="width: 300px; text-align: left; padding-left: 5px">Name</div>
      <div class="column header"
           style="width: 150px; text-align: left;">Periodicity</div>
      <div class="column header"
           style="width: 100px; text-align: left; padding-left: 5px">Source</div>
      <div class="column header"
           style="width: 115px; text-align: left; padding-left: 5px">Status</div>
    </div>
    <div class="data-table__body stable-scroll"
         style="max-height: 250px;">
      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngFor="let variable of variables; let i = index">
        <div class="column crop-string"
             style="width: 35px; text-align: left;">
          <span>{{ i + 1 }}</span>
        </div>
        <div class="column crop-string"
             style="width: 300px; text-align: left;">
          <span [title]="variable.Name">{{ variable.Name }}</span>
        </div>
        <div class="column"
             style="width: 150px;">
          <mat-form-field>
            <mat-select [(value)]="variable.NewPeriodicity"
                        panelClass="data-table-panel-opts"
                        required>
              <mat-option [value]="option.Value"
                          *ngFor="let option of variable.AllowedPeriodicities">{{ option.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="column"
             style="width: 100px; text-align: left;">
          {{ variable.SourceType.Display }}
        </div>
        <div class="column"
             style="width: 115px; text-align: left;">
          {{ variable.step }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #statusTemplate>
  <div style="text-align: right; margin-top: 15px;">
    <span>Created {{ forecastsCreated }} of {{ variables.length }}</span>
  </div>
</ng-template>
