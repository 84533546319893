<indicio-modal-base [child]="this"
                    modalTitle="Import data from Refinitiv Datastream"
                    [loading]="isLoading"
                    modalExtraClass="small"
                    extraClasses="small">


  <form *ngIf="!needLP"
        ngNativeValidate
        (submit)="addVariable()">
    <div class="row">
      <div class="col-xs-12">
        To import data from Refinitiv Datastream, <a
           href="https://product.datastream.com/browse/search.aspx?AppGroup=DSAddin"
           target="_blank">go to this page</a> and search for a variable. Copy the Symbol to the Symbol input field
        below.
      </div>
    </div>
    <div class="row extra-margin-top">
      <div class="col-xs-12">
        <mat-form-field>
          <input type="text"
                 matInput
                 class="material"
                 autocomplete="off"
                 [(ngModel)]="remoteReferenceId"
                 name="Name"
                 placeholder="Symbol"
                 required>
        </mat-form-field>
      </div>
    </div>

    <div class="row margin-top">
      <div class="col-xs-12">
        <mat-form-field>
          <input type="text"
                 matInput
                 class="material"
                 autocomplete="off"
                 [(ngModel)]="variableName"
                 (ngModelChange)="checkNameErrors()"
                 name="Name"
                 placeholder="Variable name"
                 maxlength="256"
                 required>
          <mat-hint *ngIf="nameMustChange || nameConflict">
            <span class="error-text">
              {{ nameMustChange
              ? 'The variable name must between 2 and 256 characters long'
              : 'A source variable with that name already exists.'
              }}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <mat-form-field>
          <mat-label>Periodicity</mat-label>
          <mat-select required
                      [(ngModel)]="periodicity"
                      [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let pFilter of periodicities"
                        [value]="pFilter.value">
              {{ pFilter.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 input-label">
        <mat-form-field>
          <mat-select [(value)]="aggregationMethodId"
                      placeholder="Aggregation method"
                      required>
            <mat-option [value]="option.Value"
                        *ngFor="let option of envService.AggregationMethods">{{ option.Display }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-10">
        Advanced
      </div>

      <div class="col-xs-2">
        <div class="right">
          <mat-slide-toggle (toggleChange)="advanced = !advanced"></mat-slide-toggle>
        </div>
      </div>
    </div>


    <ng-container *ngIf="advanced">
      <div class="row margin-top">
        <div class="col-xs-12">
          Find and read about the different data types <a
             href="https://product.datastream.com/browse/search.aspx?AppGroup=DSAddin&dt=true"
             target="_blank">here</a>.
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <mat-form-field>
            <input type="text"
                   matInput
                   class="material"
                   autocomplete="off"
                   [(ngModel)]="dataType"
                   name="datatype"
                   placeholder="Data type"
                   [required]="advanced || null">
          </mat-form-field>
        </div>
      </div>

    </ng-container>

    <div class="row margin-top">
      <div class="col-xs-6">
        <button (click)="close()"
                type="button"
                class="btn outline blue left">Close</button>
      </div>
      <div class="col-xs-6">
        <button class="btn right"
                [class]="{ disabled: nameConflict || nameMustChange }">
          <span *ngIf="!pending">Import</span>
          <i class="ion-load-c spinner"
             *ngIf="pending"></i>
        </button>
      </div>
    </div>
  </form>

  <ng-container *ngIf="needLP">
    <ng-container *ngTemplateOutlet="loginDetails"></ng-container>
  </ng-container>

</indicio-modal-base>

<ng-template #loginDetails>
  <form ngNativeValidate
        (submit)="saveProvider()">
    <p>Refinitiv former Thomson Reuters is one of the largest data vendors globally. Its product Datastream contains
      over 40 million times series within financial-, economic-, commodity and alternative data. </p>
    <ol>
      <li>To access it, please use your credentials for <a href="https://product.datastream.com/browse/"
           target="_blank">https://product.datastream.com</a></li>
      <li>If you don't have access to Refinitiv, please contact customer support at <a
           href="https://www.refinitiv.com/en/contact-sales"
           target="_blank">https://www.refinitiv.com/en/contact-sales</a> to get credentials</li>
    </ol>
    <p>Your credentials will be saved in Indicio so next time you click on Refinitiv you will get instant access.</p>
    <div class="row">
      <div class="col-xs-12">
        <mat-form-field>
          <input type="text"
                 matInput
                 class="material"
                 autocomplete="off"
                 [(ngModel)]="username"
                 name="username"
                 placeholder="Username"
                 maxlength="10"
                 required>
        </mat-form-field>
      </div>
    </div>
    <div class="row extra-margin-top">
      <div class="col-xs-12">
        <mat-form-field>
          <input type="password"
                 matInput
                 class="material"
                 autocomplete="off"
                 [(ngModel)]="password"
                 name="password"
                 placeholder="Password"
                 required>
        </mat-form-field>
      </div>
    </div>

    <div class="row margin-top">
      <div class="col-xs-6">
        <button (click)="close()"
                type="button"
                class="btn outline blue left">Close</button>
      </div>
      <div class="col-xs-6">
        <button class="btn right"
                [ngClass]="{ disabled: !!!username || !!!password }">
          <i *ngIf="pending"
             class="ion-load-c spinner"></i>
          <span> Save</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
