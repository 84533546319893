import { Injectable } from '@angular/core';
import { HttpStatusCodes } from '@core/constants/http-status-codes.constants';
import { DataProvider } from '@core/constants/provider-definitions';
import { SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { Store } from '@ngxs/store';
import { CacheUtils } from '@shared/utils/cache.utils';
import { DateUtils } from '@shared/utils/date.utils';
import { HttpService } from '../../services/http/http.service';
import { SourceVariableDTO } from '../source-variable/dtos/source-variable-dto';
import { GetSourceVariableSuccessAction } from '../source-variable/source-variable.actions';
import { SourceVariableMapper } from '../source-variable/source-variable.mapper';
import { CategoryRequestDTO, ProviderCategoryContentDTO, ProviderDimensionSelectionDTO, ProviderImportDTO, ProviderNavTreeDTO, ProviderVariableDTO, ProviderVariableSearchDTO } from './entities/import-provider-entities';

export type ImportDataProviderType = DataProvider.statfin | DataProvider.statnor | DataProvider.statcan | DataProvider.statdan | DataProvider.eurostat | DataProvider.scb | DataProvider.oecd | DataProvider.bloomberg | DataProvider.bis;

@Injectable({ providedIn: 'root' })
export class DataProviderBackendService {

  private navCache = new CacheUtils.LruCache<ProviderNavTreeDTO[]>();
  private contentCache = new CacheUtils.LruCache<ProviderCategoryContentDTO>();
  public expandedContent: { [key: string]: string[]; } = {};

  constructor(
    private http: HttpService,
    private env: EnvironmentService,
    private store: Store,
    private sourceVariableMapper: SourceVariableMapper
  ) { }

  public getRoot(type: ImportDataProviderType) {
    const key = `root-${type}`;
    const cacheExists = this.navCache.has(key);
    if (cacheExists) {
      return Promise.resolve(this.navCache.get(key));
    }
    return this.http.get<ProviderNavTreeDTO[]>(`${type}/categories`, 'import')
      .then(({ body }) => {
        this.navCache.put(key, body);
        return body;
      });
  }

  public getCategoryContent(type: ImportDataProviderType, categoryId: string, request: CategoryRequestDTO = null) {
    let key = `${type}-${categoryId}`;
    // If we are requesting the first page of the category content, we can cache it. Otherwise, skip it.
    if (request.Skip === 0) {
      if (!!request.Selections) {
        request.Selections.forEach(obj => {
          key += `-${obj.DimensionCode}:${obj.ValueCode}`;
        });
      }
      if (!!request.EarliestDate) {
        key += `-EarliestDate:${request.EarliestDate}`;
      }
      if (!!request.LatestDate) {
        key += `-LatestDate:${request.LatestDate}`;
      }
      if (!!request.Periodicity) {
        key += `-Periodicity:${request.Periodicity}`;
      }
      const cacheExists = this.contentCache.has(key);
      if (cacheExists) {
        return Promise.resolve(this.contentCache.get(key));
      }
    }
    return this.http.post<ProviderCategoryContentDTO>(`${type}/categories/${categoryId}`, request, 'import')
      .then(({ body }) => {
        body.Variables.forEach(v => { v.periodicity = this.env.getPeriodicity(v.Periodicity); v.oldData = DateUtils.isOldData(v.EndDate); });
        if (request.Skip === 0) {
          this.contentCache.put(key, body);
        }
        return body;
      });
  }

  public getVariable(type: ImportDataProviderType, variableId: string) {
    return this.http.get<ProviderVariableDTO>(`${type}/variables/${variableId}`, 'import')
      .then(({ body }) => body);
  }

  public searchVariables(type: ImportDataProviderType, dto: ProviderVariableSearchDTO) {
    return this.http.post<ProviderVariableDTO[]>(`${type}/search`, dto, 'import')
      .then(({ body }) => {
        body.forEach(v => { v.periodicity = this.env.getPeriodicity(v.Periodicity); v.oldData = DateUtils.isOldData(v.EndDate); });
        return body;
      });
  }

  public getPreview(type: ImportDataProviderType, variableId: string, selection: ProviderDimensionSelectionDTO[]) {
    return this.http.post<SimplePlotValue[]>(`${type}/variables/${variableId}/preview`, selection, 'import')
      .then(({ body }) => body);
  }

  public importVariable(companyId: string, type: ImportDataProviderType, variableId: string, dto: ProviderImportDTO) {
    return this.http.post<SourceVariableDTO>(`company/${companyId}/data-providers/${type}/import/${variableId}`, dto, 'backend')
      .then((resp) => {
        if (resp.status === HttpStatusCodes.NO_CONTENT) {
          throw resp;
        }
        const body = resp.body;
        const sourceVariable = this.sourceVariableMapper.map(body);
        this.store.dispatch(new GetSourceVariableSuccessAction(sourceVariable));
        return sourceVariable;
      });
  }
}
