import { Component } from '@angular/core';
import { SystemMessage } from '@core/services/system/system-message';
import { SystemMessageService } from '@core/services/system/system-message.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'indicio-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.less']
})
export class SystemMessageComponent {

  show = false;
  message: SystemMessage.Entities.Message;
  subscription: Subscription = null;

  constructor(
    public service: SystemMessageService
  ) {
    this.subscribe();
  }

  public subscribe() {
    this.subscription = this.service.message$.subscribe(value => {
      this.message = value;
      if (!!value) {
        this.show = true;
      } else {
        this.show = false;
      }
    });
  }

  public unsubscribe() {
    this.subscription.unsubscribe();
  }
}
