export type ThemeOptionType = 'legacy' | 'light' | 'dark' | 'default' | 'auto' | 'blue';

export const ThemeOptions = [
  {
    display: 'Sync with system',
    title: 'Sync with system',
    value: 'auto',
    admin: false
  },
  {
    display: 'Blue Nature (Legacy)',
    title: 'Blue Nature (Legacy)',
    value: 'legacy',
    admin: false
  },
  {
    display: 'Blue',
    title: 'Blue',
    value: 'blue',
    admin: false
  },
  {
    display: 'Light',
    title: 'Light',
    value: 'light',
    admin: false
  },
  {
    display: 'Dark',
    title: 'Dark',
    value: 'dark',
    admin: false
  }
];
