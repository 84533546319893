import { CalendarEffects } from '@dialogs/variables/forecast-variable/info/tabs/seasonal/fvar-calendar-settings';
import { DisplayValue } from '../types/display-value';

export const SeasonalCalendarRegionType = [
  new DisplayValue<CalendarEffects.RegionType>('New York', 'New York', 'New York'),
  new DisplayValue<CalendarEffects.RegionType>('London', 'London', 'London'),
  new DisplayValue<CalendarEffects.RegionType>('Tokyo', 'Tokyo', 'Tokyo'),
  new DisplayValue<CalendarEffects.RegionType>('Zurich', 'Zurich', 'Zurich')
];

export const SeasonalCalendarHolidayType = [
  new DisplayValue<CalendarEffects.HolidayType>('GBMayDay', 'May Day (UK)', 'May Day is traditionally a public holiday in the UK celebrated on or around 1st May.'),
  new DisplayValue<CalendarEffects.HolidayType>('GBBankHoliday', 'Bank holidays (UK)', 'UK bank holidays are national public holidays in the United Kingdom.'),
  new DisplayValue<CalendarEffects.HolidayType>('GBSummerBankHoliday', 'Summer bank holidays (UK)', 'UK summer bank holidays are national public holidays in the United Kingdom.'),
  new DisplayValue<CalendarEffects.HolidayType>('Septuagesima', 'Septuagesima', ''),
  new DisplayValue<CalendarEffects.HolidayType>('Quinquagesima', 'Quinquagesima', ''),
  new DisplayValue<CalendarEffects.HolidayType>('AshWednesday', 'Ash Wednesday', 'Ash Wednesday is a Christian holy day of prayer and fasting.'),
  new DisplayValue<CalendarEffects.HolidayType>('PalmSunday', 'Palm Sunday', 'Palm Sunday is a Christian moveable feast that falls on the Sunday before Easter. '),
  new DisplayValue<CalendarEffects.HolidayType>('GoodFriday', 'Good Friday', 'Good Friday, the Friday before Easter, the day on which Christians annually observe the commemoration of the Crucifixion of Jesus Christ.'),
  new DisplayValue<CalendarEffects.HolidayType>('EasterSunday', 'Easter Sunday', "Many Christians celebrate Jesus Christ's resurrection on Easter Sunday."),
  new DisplayValue<CalendarEffects.HolidayType>('Easter', 'Easter', '(Easter)'),
  new DisplayValue<CalendarEffects.HolidayType>('EasterMonday', 'Easter Monday', 'Easter Monday is the day after Easter Sunday and a public holiday in some countries.'),
  new DisplayValue<CalendarEffects.HolidayType>('RogationSunday', 'Rogation Sunday', 'In the Anglican tradition, Rogation Sunday is celebrated on the 5th Sunday after Easter.'),
  new DisplayValue<CalendarEffects.HolidayType>('Ascension', 'Ascension', 'he Feast of the Ascension of Jesus Christ, also called Ascension Day.'),
  new DisplayValue<CalendarEffects.HolidayType>('Pentecost', 'Pentecost', ''),
  new DisplayValue<CalendarEffects.HolidayType>('PentecostMonday', 'Pentecost Monday', ''),
  new DisplayValue<CalendarEffects.HolidayType>('TrinitySunday', 'Trinity Sunday', 'Trinity Sunday is the first Sunday after Pentecost in the Western Christian liturgical calendar, and the Sunday of Pentecost in Eastern Christianity.'),
  new DisplayValue<CalendarEffects.HolidayType>('CorpusChristi', 'Corpus Christi', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ChristTheKing', 'Christ The King', 'The Solemnity of Our Lord Jesus Christ, King of the Universe, commonly referred to as the Feast of Christ the King.'),
  new DisplayValue<CalendarEffects.HolidayType>('Advent1st', 'Advent (1st)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('Advent2nd', 'Advent (2nd)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('Advent3rd', 'Advent (3rd)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('Advent4th', 'Advent (4th)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ChristmasEve', 'Christmas Eve', 'The festival commemorating the birth of Jesus.'),
  new DisplayValue<CalendarEffects.HolidayType>('ChristmasDay', 'Christmas Day', 'Christmas is an annual festival commemorating the birth of Jesus Christ.'),
  new DisplayValue<CalendarEffects.HolidayType>('BoxingDay', 'Boxing Day', 'Boxing Day is a holiday celebrated after Christmas Day, occurring on the second day of Christmastide (26 December).'),
  new DisplayValue<CalendarEffects.HolidayType>('NewYearsDay', "New Year's Day", 'First day of the year.'),
  new DisplayValue<CalendarEffects.HolidayType>('SolemnityOfMary', 'Solemnity Of Mary', 'The Solemnity of Mary, the Holy Mother of God is a feast day of the Blessed Virgin Mary under the aspect of her motherhood of Jesus Christ.'),
  new DisplayValue<CalendarEffects.HolidayType>('Epiphany', 'Epiphany', 'is a Christian feast day that celebrates the revelation (theophany) of God.'),
  new DisplayValue<CalendarEffects.HolidayType>('PresentationOfLord', 'Presentation Of Lord', ''),
  new DisplayValue<CalendarEffects.HolidayType>('Annunciation', 'Annunciation', ''),
  new DisplayValue<CalendarEffects.HolidayType>('TransfigurationOfLord', 'Transfiguration Of Lord', ''),
  new DisplayValue<CalendarEffects.HolidayType>('AssumptionOfMary', 'Assumption Of Mary', ''),
  new DisplayValue<CalendarEffects.HolidayType>('BirthOfVirginMary', 'Birth Of VirginMary', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CelebrationOfHolyCross', 'Celebration Of HolyCross', ''),
  new DisplayValue<CalendarEffects.HolidayType>('MassOfArchangels', 'Mass Of Archangels', ''),
  new DisplayValue<CalendarEffects.HolidayType>('AllSaints', 'All Saints', ''),
  new DisplayValue<CalendarEffects.HolidayType>('AllSouls', 'All Souls', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CHBerchtoldsDay', "Berchtold's Day (Switzerland)", ''),
  new DisplayValue<CalendarEffects.HolidayType>('CHSechselaeuten', 'Sechselaeute (Switzerland)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CHAscension', 'Ascension (Switzerland)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CHConfederationDay', 'Confederation Day (Switzerland)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CHKnabenschiessen', 'Knabenschiessen (Switzerland)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('DEAscension', 'Ascension', ''),
  new DisplayValue<CalendarEffects.HolidayType>('DECorpusChristi', 'Corpus Christi (Germany)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('DEGermanUnity', 'German Unity', ''),
  new DisplayValue<CalendarEffects.HolidayType>('DEChristmasEve', 'Christmas Eve (Germany)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('DENewYearsEve', "New Year's Eve (Germany)", ''),
  new DisplayValue<CalendarEffects.HolidayType>('FRFetDeLaVictoire1945', 'Fete De La Victoire 1945 (France)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('FRAscension', 'Ascension (France)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('FRBastilleDay', 'Bastille Day (France)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('FRAssumptionVirginMary', 'Assumption Virgin Mary (France)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('FRAllSaints', 'All Saints (France)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('FRArmisticeDay', 'Armistice Day (France)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ITEpiphany', 'Epiphany (Italy)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ITLiberationDay', 'Liberation Day (Italy)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ITRepublicAnniversary', 'Republic Anniversary (Italy)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ITAssumptionOfVirginMary', 'Assumption Of VirginMary (Italy)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ITAllSaints', 'All Saints (Italy)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ITWWIVictoryAnniversary', 'WWI Victory Anniversary (Italy)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ITStAmrose', 'St Amrose (Italy)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('ITImmaculateConception', 'Immaculate Conception (Italy)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USNewYearsDay', "New Year's Day (USA)", ''),
  new DisplayValue<CalendarEffects.HolidayType>('USInaugurationDay', 'Inauguration Day (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USMLKingsBirthday', "ML King's Birthday (USA)", ''),
  new DisplayValue<CalendarEffects.HolidayType>('USLincolnsBirthday', "Lincoln's Birthday (USA)", ''),
  new DisplayValue<CalendarEffects.HolidayType>('USWashingtonsBirthday', "Washington's Birthday (USA)", ''),
  new DisplayValue<CalendarEffects.HolidayType>('USMemorialDay', 'Memorial Day (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USIndependenceDay', 'Independence Day (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USLaborDay', 'Labor Day (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USColumbusDay', 'Columbus Day (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USElectionDay', 'Election Day (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USVeteransDay', 'Veterans Day (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USThanksgivingDay', 'Thanksgiving Day (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USChristmasDay', 'Christmas Day (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('USCPulaskisBirthday', "Pulaski's Birthday (USA)", ''),
  new DisplayValue<CalendarEffects.HolidayType>('USGoodFriday', 'Good Friday (USA)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CAVictoriaDay', 'Victoria Day (Canada)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CACanadaDay', 'Canada Day', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CACivicProvincialHoliday', 'Civic Provincial Holiday (Canada)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CALabourDay', 'Labour Day (Canada)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CAThanksgivingDay', 'Thanksgiving Day (Canada)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('CaRemembranceDay', 'Remembrance Day (Canada)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPNewYearsDay', "New Year's Day (Japan)", ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPGantan', 'Gantan (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPBankHolidayJan2', 'Bank Holiday Januari 2nd (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPBankHolidayJan3', 'Bank Holiday Januari 3th (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPComingOfAgeDay', 'Coming Of Age Day (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPSeijinNoHi', 'Seijin No Hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPNatFoundationDay', 'Nat Foundation Day (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPKenkokuKinenNoHi', 'Kenkoku Kinen No Hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPGreeneryDay', 'Greenery Day (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPMidoriNoHi', 'Midori No Hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPConstitutionDay', 'Constitution Day (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPKenpouKinenBi', 'Kenpou Kinen Bi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPNationHoliday', 'Nation Holiday (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPKokuminNoKyujitu', 'Kokumin No Kyujitu (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPChildrensDay', "Children's Day (Japan)", ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPKodomoNoHi', 'Kodomo No Hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPMarineDay', 'Marine Day (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPUmiNoHi', 'Umi No Hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPRespectForTheAgedDay', 'Respect For The Aged Day (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPKeirouNoHi', 'Keirou No Hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPAutumnalEquinox', 'Autumnal Equinox (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPShuubun-no-hi', 'Shuubun-no-hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPHealthandSportsDay', 'Health and Sports Day (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPTaiikuNoHi', 'Taiiku No Hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPNationalCultureDay', 'National Culture Day (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPBunkaNoHi', 'Bunka No Hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPThanksgivingDay', 'Thanksgiving Day (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPKinrouKanshaNohi', 'Kinrou Kansha No Hi (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPEmperorsBirthday', 'Emperors Birthday (Japan)', ''),
  new DisplayValue<CalendarEffects.HolidayType>('JPTennou-tanjyou-bi', 'Tennou-tanjyou-bi (Japan)', '')
];
