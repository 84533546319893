
export namespace StateUtils {

  export class StateHelperState {
    key: string;
    value: string;
  }
  export class StateHelper {
    public states: StateHelperState[] = [];

    public setState(key: string, obj: any) {
      const existIndex = this.states.findIndex(s => s.key === key);
      const state = new StateHelperState;
      state.key = key;
      state.value = JSON.stringify(obj);
      if (existIndex === -1) {
        this.states.push(state);
      } else {
        this.states[existIndex] = state;
      }
    }

    public getstate(key: string) {
      return this.states.find(state => state.key === key);
    }

    public removeState(key: string) {
      const existIndex = this.states.findIndex(s => s.key === key);
      if (existIndex === -1) { return; }
      this.states.splice(existIndex, 1);
    }

    public isChanged(key: string, obj) {
      if (this.states.length === 0) { return false; }
      const existIndex = this.states.findIndex(s => s.key === key);
      if (existIndex === -1) { return false; }
      return this.states[existIndex].value !== JSON.stringify(obj);
    }
  }
}
