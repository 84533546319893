<indicio-dialog class="calculate-results-dialog">
  <h2 mat-dialog-title>{{ title }}
    <div class="crop-string subtitle"
         [title]="data.ObjectName"
         *ngIf="initialized">
      <span class="capitalize">{{ data.type }}</span>: {{ data.ObjectName }}
    </div>
  </h2>
  <div mat-dialog-content>
    <div style="margin: 10px 0">
      Data processing, indicator analysis, multivatiate and nowcasts (if needed), will be run for each forecast.
    </div>
    <ng-container *ngIf="!initialized"
                  [ngTemplateOutlet]="loader"></ng-container>
    <ng-container *ngIf="initialized && infos.length">
      <ng-container [ngTemplateOutlet]="listTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="infoTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="initialized && !infos.length"
                  [ngTemplateOutlet]="emptyTemplate"></ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Close</button>
    <button mat-flat-button
            color="primary"
            (click)="calculate()"
            cdkFocusInitial>Calculate</button>
  </div>
</indicio-dialog>

<ng-template #listTemplate>
  <indicio-data-table columnTemplate="50px 1fr repeat(4, 120px)">
    <div title-row>
      <div column><span title>#</span></div>
      <div column
           left><span title>Forecast</span></div>
      <div column
           left><span title>Periodicity</span></div>
      <div column
           left><span title>Horizon</span></div>
      <div column
           left><span title>Variable count</span></div>
      <div column
           left><span title>Version</span></div>
    </div>
    <div style="max-height: 360px; overflow: auto;">
      <ng-container *ngFor="let item of infos; let index = index">
        <div row>
          <div column
               center><span>{{ index }}</span></div>
          <div class="crop-string"
               [title]="item.ForecastName"
               column><span>{{ item.ForecastName }}</span></div>
          <div column><span>{{ item.Periodicity }}</span></div>
          <div column><span>{{ item.Horizon }}</span></div>
          <div column><span>{{ item.VariableCount }}</span></div>
          <div column><span>{{ item.LatestVersion }}</span></div>
        </div>
      </ng-container>
    </div>
  </indicio-data-table>
</ng-template>

<ng-template #infoTemplate>

</ng-template>

<ng-template #emptyTemplate>

</ng-template>

<ng-template #loader>
  <div class="center-horizontal-and-vertical"
       style="height: 100px;">
    <indicio-spinner [size]="24">
    </indicio-spinner>
    &nbsp; Loading information...
  </div>
</ng-template>
