
export class BloombergCredentialsInfoDTO {
  HasCredentials: boolean;
  Expires: Date;
}

export class BloombergCredentialsDTO {
  client_id: string;
  client_secret: string;
  name: string;
  scopes: string[];
  expiration_date: number;
  created_date: number;

  // Frontend stuff
  public expirationDate: Date;
  public creationDate: Date;
}
