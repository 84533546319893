import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[indicioFocusInput]'
})
export class FocusInputDirective implements OnInit, OnDestroy {

  @Input() field: string;

  private dom: any = null;

  constructor(ui: ElementRef) { this.dom = ui.nativeElement; }

  public ngOnInit() {
    this.dom.addEventListener('click', this.onClick.bind(this));
  }

  public ngOnDestroy() {
    this.dom.removeEventListener('click', this.onClick);
  }

  public onClick() {
    if (!this.field) { return; }
    const inputfield = <HTMLInputElement> document.querySelector(this.field);
    inputfield.select();
  }
}
