<h1 mat-dialog-title>Source variable data</h1>
<div mat-dialog-content>
  <div>
    <indicio-slg *ngIf="graphData"
                 [options]="graphData">
    </indicio-slg>
  </div>
</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-stroked-button
          (click)="onNoClick()">
    Close
  </button>
</div>
