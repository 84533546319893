import { ReportModel } from './models/report.model';

export namespace ReportAction {
  export class GetSuccess {
    public static readonly type = '[REPORTS] Get report success';
    constructor(public report: ReportModel) { }
  }

  export class GetAllSuccess {
    public static readonly type = '[REPORTS] Get all reports success';
    constructor(public reports: ReportModel[]) { }
  }

  export class CreateOrUpdateSuccess {
    public static readonly type = '[REPORTS] Create or update report success';
    constructor(public report: ReportModel) { }
  }

  export class DeleteSuccess {
    public static readonly type = '[REPORTS] Delete report success';
    constructor(public report: ReportModel) { }
  }

  export class SetActiveReport {
    public static readonly type = '[REPORTS] Set active report success';
    constructor(public reportId: string) { }
  }
}
