import { Def } from '@core/decorators/def';
import { FileVersionDTO } from '@core/store/file/dtos/file-version-dto';
import { VariableSheetDTO } from '@core/store/file/dtos/variable-sheet-dto';
import { FileAccessDTO } from './file-access.dto';

export class FileDTO {
  @Def() UploadedFileId: string;
  @Def() FileName: string;
  @Def() FileData: string;
  @Def() CreatedDate: string;
  @Def() Separator: string;
  @Def() OwnerEmail: string;
  @Def() Extension: string;
  @Def() CompanyId: string;
  @Def() ParsedData: VariableSheetDTO[];
  @Def() Versions: FileVersionDTO[];
  @Def() Used: boolean;
  @Def() NewColumns: boolean;
  @Def() Users: FileAccessDTO[];
}

export class UpdateFileInfoDTO {
  @Def() CompanyId: string;
  @Def() UploadedFileId: string;
  @Def() FileName: string;
}

export class RemoteFileDTO {
  @Def() RemoteFileId: string;
  @Def() FileName: string;
  @Def() CreatedDate: string;
  @Def() Separator: string;
  @Def() OwnerEmail: string;
  @Def() Extension: string;
  @Def() ParsedData: VariableSheetDTO[];
  @Def() Versions: RemoteFileVersionDTO[];
  @Def() Used: boolean;
  @Def() Users: FileAccessDTO[];
}

export class RemoteFileVersionDTO {
  @Def() RemoteFileVersionId: string;
  @Def() FileData: string;
  @Def() CreatedDate: string;
}

export class Base64FileDTO {
  Name: string;
  Base64String: string;
  MimeType: string;
}
