import { IndicioConstants } from '@core/constants/indicio.constants';
import { RemoteCalls } from '@core/constants/remote-calls.constants';
import { Def } from '@core/decorators/def';
import { ExistingRemoteVarInfoDTO } from '../dtos/existing-remote-varinfo.dto';

export class RemoteDataRequestModel {
  @Def() RemoteUri: string;
  @Def() Provider: string;
  @Def() RemoteReferenceId: string;
  @Def() Name: string;
  @Def() MetaData: any;
  @Def() Query: any;
  @Def() Data: any;
  @Def() ValueType: string;
  @Def() User?: string;
  @Def() Password?: string;
  @Def() Periodicity?: string;
  @Def() Extras?: object;

  // frontend only
  public nameError: string;
  public existingVariable: ExistingRemoteVarInfoDTO;
  public aggregationMethodId: string = IndicioConstants.EAggregationMethods.Average;
  public status: RemoteCalls.Steps = 'queued';
  public statusMessage: string;
}
