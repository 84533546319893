import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { StorageUtils } from '@shared/utils/storage.utils';
import { ToggleLightDarkMode } from './light-dark-button.component.actions';

export type LightDarkModeType = 'light' | 'dark';

@Component({
  selector: 'indicio-light-dark-button',
  templateUrl: './light-dark-button.component.html',
  styleUrls: ['./light-dark-button.component.less']
})
export class LightDarkButtonComponent {

  public state: LightDarkModeType = 'light';

  constructor(public store: Store) {
    this.state = StorageUtils.get('indicio.sidebar.mode') as LightDarkModeType || 'light';
    this.store.dispatch(new ToggleLightDarkMode(this.state));
  }

  public toggleState() {
    this.state = this.state === 'light' ? 'dark' : 'light';
    this.store.dispatch(new ToggleLightDarkMode(this.state));
    StorageUtils.set('indicio.sidebar.mode', this.state);
  }
}
