import { PastForecastDTO } from '@core/entities/dtos/past-forecast-dto';
import { FittedValueDTO, PlotValue, PlotValueDTO, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { ShapValueDTO } from '@core/store/stat-model/dtos/stat-model.dto';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { ModelName } from '@modules/lang/types/model-name';
import { SummaryUtils } from '@shared/utils/forecast/summary.utils';
import * as moment from 'moment';
import { ALGTypes } from '../alg-types';
import { SvgColors } from '../alg-worker/svg-manipulations/svg-colors';
import { StrokeDefinition } from '../alg.options';



export class ALGSingleSeriesModel implements SummaryUtils.SummaryInfo {
  Name: string;
  Description: string;
  modelName: ModelName;
  /** Variable ID; ForecastVariableId, Source, etc */
  Id?: string;
  show: boolean;
  Color: string;
  /** The main data to plot */
  Values: PlotValueDTO[];
  FittedValues: FittedValueDTO[];
  /* Past forecast data */
  RollingAccuracy: boolean;
  PastForecasts: PastForecastDTO[];
  TransformedPastForecast?: PastForecastData;
  Periodicity: PeriodicityType;
  /** Set by Summary component */
  WFittedValues?: FittedValueDTO[];
  /** SHAP values */
  ShapValues: ShapValueDTO[];
  WShapValues: ShapValueDTO[];
  /** Set by TransformData in ALG */
  Factor?: number;
  /** Set by TransformData in ALG */
  FactorAbbr?: string;
  /** D3 group used/set by ALG to handle hover etc */
  group?: d3.Selection<SVGGElement, undefined, null, undefined>;
}

export interface ALGLineModel {
  Id: string;
  Values: PlotValueDTO[];
  Active: boolean;
  modelName?: ModelName;
  Name: string;
  IsHistoric: boolean;
  Color: string;
  Stroke: StrokeDefinition;
  Type: ALGTypes.LineModelType;
  Segments: ALGTypes.LineSegment[];
}

export interface AlgModel {
  Id: string;
  Values: PlotValueDTO[];
  Active: boolean;
  Model: ALGSingleSeriesModel;
  modelName: ModelName;
  Color: string;
  Segments: ALGTypes.LineSegment[];
}

export interface PastForecastData {
  Type: number; // 0 = all, else the selected past-forecast-step
  Color?: string;
  Model: ALGSingleSeriesModel,
  PastForecasts: SimplePlotValue[][];
  Segments: ALGTypes.LineSegment[][];
}

export class GraphData {
  /* Finalized entities */
  Models: ALGSingleSeriesModel[] = [];
  Forecasted: AlgModel[] = [];
  Fitted: AlgModel[] = [];
  Lines: ALGLineModel[] = [];
  HistoricLine: ALGLineModel;
  PastForecasts: PastForecastData[] = [];
  HistoricSegments: ALGTypes.LineSegment[] = [];
  /*  */
  LastTwoHistoric: PlotValue[] = [];
  Dates: moment.Moment[] = [];
  ActiveModelCount: number = 0;
  DataEmpty: boolean = false;
  DecimalCount: number = 0;
  MinXValue: Date;
  MaxXValue: Date;
  MinYValue: number = 0;
  MaxYValue: number = 0;
  GroupedAssessments: {
    moment: moment.Moment,
    items: AssessmentModel[],
    newValue: number;
  }[] = [];
  Colors: SvgColors = new SvgColors();
  HasAggregatedData: boolean = false;
  ShowCIIntervals: boolean = false;
  FittedValues?: number[] = [];
}
