import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { AceaDataSerieModel } from '@core/store/providers/acea/acea-dataserie.model';
import { AceaMapper } from '@core/store/providers/acea/acea.mapper';
import { SourceVariableDTO } from '@core/store/source-variable/dtos/source-variable-dto';
import { GetSourceVariableSuccessAction } from '@core/store/source-variable/source-variable.actions';
import { SourceVariableMapper } from '@core/store/source-variable/source-variable.mapper';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class AceaService {

  constructor(
    private http: HttpService,
    private store: Store,
    private sourceVariableMapper: SourceVariableMapper,
    private mapper: AceaMapper
  ) { }

  public getBaseCategories() {
    return this.http.post<any>('my/remotesources/Acea/get-base-categories', {})
      .then(res => {
        const body = res.body;
        return { categories: body.map(exp => this.mapper.mapSeries(exp)) };
      });
  }

  public getSubCategories(baseCategory: string) {
    const url = 'my/remotesources/Acea/get-sub-categories/' + baseCategory;
    return this.http.post<any>(url, {})
      .then(res => {
        const body = res.body;
        return { categories: body.map(exp => this.mapper.mapSeries(exp)) };
      }).catch(() => {
        return { categories: [] };
      });
  }

  public getAvailableTimeseries(aceaMetaData: object) {
    const url = 'my/remotesources/Acea/get-available-timeSeries';
    return this.http.post<any>(url, aceaMetaData)
      .then(res => {
        const body = res.body;
        return {
          categories: typeof body.Result !== 'undefined'
            ? this.putTotalOnTop(this.conditionMutipleArray(body.Result).map(exp => this.mapper.mapSeries(exp)))
            : this.putTotalOnTop(this.conditionMutipleArray(body).map(exp => this.mapper.mapSeries(exp)))
        };
      }).catch(() => {
        return { categories: [] };
      });
  }

  public addRemoteVariable(companyId: string, aceaMetaData: object) {
    const url = 'company/' + companyId + '/remotevariables/request';
    return this.http.post<SourceVariableDTO>(url, aceaMetaData)
      .then((res) => {
        const sourceVariable = this.sourceVariableMapper.map(res.body);
        this.store.dispatch(new GetSourceVariableSuccessAction(sourceVariable));
        return sourceVariable;
      });
  }

  public search(searchQuery: string) {
    const url = 'my/remotesources/Acea/search/' + searchQuery;
    return this.http.post<any>(url, {})
      .then((res) => {
        return res.body;
      });
  }

  private putTotalOnTop(series: AceaDataSerieModel[]) {
    for (let i = 0; i < series.length; i++) {
      if (typeof series[i].SelectedSubCategoryTitle !== 'undefined' && series[i].SelectedSubCategoryTitle.toLocaleLowerCase() === 'total') {
        const b = series[i];
        series.splice(i, 1);
        series.unshift(this.addTotalLabel(b));
      }
    }
    return series;
  }

  private addTotalLabel(series: AceaDataSerieModel) {
    series.Country = series.Country + ' (total)';
    return series;
  }

  private conditionMutipleArray(a) {
    if (Array.isArray(a) && Array.isArray(a[0])) { return a[0]; }
    return a;
  }
}
