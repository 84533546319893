import { Def } from '@core/decorators/def';
import { CreateHistoricEventDTO, HistoricEventDTO } from './historic-event.dto';

export class HistoricBaseEventDTO {
  @Def() HistoricBaseEventId: string;
  @Def() Name: string;
  @Def() Type: string;
  @Def() Location: string;
  @Def() Events: HistoricEventDTO[] = [];
}

export class CreateHistoricBaseEventDTO {
  @Def() Name: string;
  @Def() Type: string;
  @Def() Location: string;
  @Def() Events: CreateHistoricEventDTO[] = [];
}

export class UpdateHistoricBaseEventDTO {
  @Def() HistoricBaseEventId: string;
  @Def() Name: string;
  @Def() Type: string;
  @Def() Location: string;
  @Def() CreateEvents?: CreateHistoricEventDTO[];
  @Def() UpdateEvents: CreateHistoricEventDTO[];
}
