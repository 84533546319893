import { Component } from '@angular/core';
import { NavigateToForecast } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastResultModel, ForecastResultModelModel } from '@core/store/forecast-result/entities/forecast-result.model';
import { ForecastResultService } from '@core/store/forecast-result/forecast-result.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { PotentialForecastDTO } from '@core/store/forecast/dtos/forecast/potential-forecast.dto';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DateUtils } from '@shared/utils/date.utils';
import { ForecastedIndicatorModalOpts } from './forecasted-indicator-modal.options';

@Component({
  selector: 'indicio-forecasted-indicator-modal',
  templateUrl: './forecasted-indicator-modal.component.html',
  styleUrls: ['./forecasted-indicator-modal.component.less']
})
export class ForecastedIndicatorModalComponent extends ModalModelComponent {

  opts: ForecastedIndicatorModalOpts;

  forecast: ForecastModel;
  forecastVersion: ForecastVersionModel;
  fVar: ForecastVariableModel;
  source: SourceVariableModel;
  sourceVersion: SourceVariableVersionModel;
  result: ForecastResultModel;
  potentialResults: PotentialForecastDTO[];
  currentPotentialResults: PotentialForecastDTO;

  uniOpen = false;
  multiOpen = false;

  constructor(
    protected store: Store,
    private status: StatusService,
    public envService: EnvironmentService,
    private fVarService: ForecastVariableFrontendService,
    private sourceService: SourceVariableFrontendService,
    private forecastService: ForecastFrontendService,
    private forecastResultService: ForecastResultService,
    private clientService: ClientFrontendService,
    private dialogService: DialogService
  ) {
    super();
  }

  private setupSubscriptions() {
    // this.addSubscription(this.actions.dispatched(GetCompanySuccessAction).subscribe({
    //   next: (value: GetCompanySuccessAction) => {
    //     if (value.company.CompanyId === this.company.CompanyId) { this.setupMemberListOptions(); }
    //   }
    // }));
  }

  public async setOptions(options: ForecastedIndicatorModalOpts) {
    this.opts = options;

    const fvarPromise = this.fVarService.getOrFetchById(options.fVersionId, options.fVarId);
    const fcPromise = this.forecastService.getOrFetchForecastVersion(options.fVersionId);
    const svPromise = this.sourceService.getOrFetchSourceVariable(options.sourceVariableId);

    Promise.all([fvarPromise, fcPromise, svPromise]).then(([fVar, fVer, sourceVariable]) => {
      this.fVar = fVar;
      this.forecastVersion = fVer;
      this.source = sourceVariable;
      this.sourceVersion = sourceVariable.getVersionById(options.sourceMetaId);
      this.forecast = this.forecastService.forecastByVersionId(options.fVersionId);
      const potentialPromise = this.forecastService.getPotentialForecasts({
        ResultsTo: DateUtils.convertToBackendDate(fVer.DataUntilDateRequirement),
        SourceVariableId: options.sourceVariableId,
        IsMain: false, // NOTE: Should this be: !fVar.IsIndicator?
        Periodicity: fVer.Periodicity,
      }, this.clientService.activeCompanyId);
      return Promise.all([potentialPromise]);
    })
      .then(([potentials]) => {
        const forecastVersions = potentials.filter(x => x.ForecastId === this.sourceVersion.ParentForecastId);
        this.potentialResults = forecastVersions;
        this.currentPotentialResults = forecastVersions.find(x => x.Results.some(y => y.SourceVariableMetaId === options.sourceMetaId));
        if (this.currentPotentialResults) {
          if (!this.currentPotentialResults.Results.find(x => x.SourceVariableMetaId === this.sourceVersion.SourceVariableMetaId)) {
            this.sourceVersion = this.source.getVersionById(this.currentPotentialResults.Results[0].SourceVariableMetaId);
          }
          this.currentPotentialResults.selectedResultId = this.sourceVersion.SourceVariableMetaId;
        }
        return this.getResult();
      })
      .finally(() => {
        this.isLoading = false;
      });

    this.setupSubscriptions();
  }

  public newInput(newId: string) {
    this.currentPotentialResults = this.potentialResults.find(x => x.Results.some(y => y.SourceVariableMetaId === newId));
    this.currentPotentialResults.selectedResultId = newId;
    this.sourceVersion = this.source.getVersionById(this.currentPotentialResults.Results[0].SourceVariableMetaId);
    this.getResult();
  }

  private getResult() {
    return this.forecastResultService.getForecastResult(this.sourceVersion.ParentForecastVersionId, this.sourceVersion.ForecastResultId)
      .then((result) => {
        result.Models = result.Models.filter(x => x.Active);
        this.result = result;
      })
      .catch(err => this.status.setError(err))
      ;
  }

  public saveVersionChange() {
    this.pending = true;
    this.fVarService.changeSourceMetaIdForFVar(this.forecastVersion.ForecastVersionId, this.fVar.ForecastVariableId, this.currentPotentialResults.selectedResultId)
      .then(() => {
        this.close();
      })
      .catch(err => this.status.setError(err))
      .finally(() => {
        this.pending = false;
        this.currentPotentialResults.saveLoading = false;
      });
  }

  public navToForecast() {
    this.close();
    this.store.dispatch(new NavigateToForecast(this.result.ForecastId));
  }

  public openModelWeightDialog(model: ForecastResultModelModel) {
    this.dialogService.openForecastVariableModelWeightsDialog({
      Model: this.envService.getModelName(model.Name).Display,
      Weights: model.Weight
    });
  }

  toggleFoldables(_state: boolean, type: string) {
    if (type.match(/univariate/i)) {
      this.multiOpen = false;
    } else {
      this.uniOpen = false;
    }
  }
}
