import { Injectable } from '@angular/core';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { CoefLagDTO, VariableSelectionModelDTO, VariableSelectionResultDTO, XNameDTO } from '@core/store/var-select/dtos/variable-selection-result-dto';
import { VarSelectResultModel } from '@core/store/var-select/var-select-result.model';
import { PlotUtils } from '@shared/utils/plot.utils';


@Injectable({
  providedIn: 'root'
})
export class VarSelectMapper {

  constructor() { }

  public map(dto: VariableSelectionResultDTO): Promise<VarSelectResultModel> {
    if (!dto) { return null; }
    const model = Object.faMapTo(new VarSelectResultModel(), dto);
    model.Models = dto.VariableSelectionModels;
    model.ExoModels = dto.VariableSelectionExoModels;
    model.NameMaps = dto.NameMaps.map((x: XNameDTO) => ({ ...x, ObjectId: x.ObjectId || x.ForecastVariableId }));
    model.Models.forEach(m => {
      m.COEFLags = m.COEFLags.map((x: CoefLagDTO) => ({ ...x, ObjectId: x.ObjectId || x.ForecastVariableId }));
    });
    const promises = dto.VSPlots.map(x => this.mapImage(x));
    const maxLag = Math.max(...model.Models.map(x => Math.max(...x.COEFLags.map(z => z.Values.length))));

    model.Models = model.Models.map(x => this.mapEndoSelectModel(x, maxLag));

    model.fetched = dto.ExitError || dto.ExitSuccess;
    return Promise.all(promises).then(images => {
      model.VSPlots = images;
      return model;
    });
  }

  private mapEndoSelectModel(model: VariableSelectionModelDTO, maxLag: number) {
    model.COEFLags = model.COEFLags.map(cl => {
      cl = Object.faMapTo(new CoefLagDTO, cl);
      if (cl.IsEvent) {
        const missingLagSteps = maxLag - cl.Values.length;
        if (missingLagSteps > 0) {
          for (let i = 0; i < missingLagSteps; i++) {
            cl.Values.push(0);
          }
        }
      }
      return cl;
    });

    Object.keys(model).forEach(k => {
      if (Array.isArray(model[k]) && k.includes('Influence')) {
        model[k] = model[k].map(v => v === 'NaN' ? 0 : v);
      }
    });

    return model;
  }

  private async mapImage(dto: ResultFileDTO) {
    dto.sortIndex = 1;
    const x = await PlotUtils.UnzipPlotImage(dto, dto.Name);
    dto.Base64String = x.Base64String;
    dto.MimeType = x.MimeType;
    return dto;
  }
}
