import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { copyToClipboard } from 'js-copy-to-clipboard';

@Directive({
  selector: '[indicioCopy]'
})
export class CopyDirective implements AfterViewInit, OnDestroy {
  private dom: HTMLElement = null;

  constructor(ui: ElementRef) {
    this.dom = ui.nativeElement;
  }

  ngAfterViewInit() {
    this.dom.addEventListener('click', this.copy.bind(this));
  }

  ngOnDestroy() {
    this.dom.removeEventListener('click', this.copy.bind(this));
  }

  private copy() {
    let text: string;
    if (this.dom.innerText) {
      text = this.dom.innerText;
    }
    copyToClipboard(text);
  }
}
