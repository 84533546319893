<indicio-modal-base [child]="this"
                    modalTitle="Remove historical event(s)"
                    [loading]="isLoading"
                    bodyExtraClass="overflow-auto">
<p *ngIf="events.length > 1">Are you sure you want to remove these historical events?</p>
<p *ngIf="events.length === 1">Are you sure you want to remove this historical event?</p>

<div class="row bold margin-bottom-10">
  <div class="col-xs-10">Events</div>
  <div class="col-xs-2 center-text">Status</div>
</div>

<div class="list">
  <div class="row" *ngFor="let event of events">
    <div class="col-xs-10">{{ event.Name }}</div>
    <div class="col-xs-2 center-text">
      <i class="ion-checkmark-circled green"></i>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xs-6">
    <button class="btn outline blue left"
            type="button"
            (click)="close()">Cancel</button>
  </div>
  <div class="col-xs-6">
    <button class="btn right" (click)="removeEvents()" [ngClass]="{ disabled: pending }">
      <i *ngIf="pending"
        class="ion-load-c spinner"></i>
      <span *ngIf="!pending">Remove</span>
    </button>
  </div>
</div>
</indicio-modal-base>
