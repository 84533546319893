import { Injectable } from '@angular/core';
import { AuthActions } from '@core/store/auth/auth.actions';
import { ClientSettingsService } from '@core/store/client/client-settings.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ToSService } from '@core/store/tos/tos.service';
import { Store } from '@ngxs/store';
import { OpenSetCompanyModal } from '@shared/modals/company/set-company/set-company-modal.actions';
import { ActionService } from '../actions/actions.service';
import { StatusService } from '../status/status.service';
import { SystemMessage } from '../system/system-message';

@Injectable({
  providedIn: 'root'
})
export class PostLoginService {

  constructor(
    private store: Store,
    private actions: ActionService,
    private status: StatusService,
    private tosService: ToSService,
    private appearance: AppearanceService,
    private companyService: CompanyFrontendService,
    private clientSettingsService: ClientSettingsService,
    private clientService: ClientFrontendService
  ) { }


  public setupSubs() {
    this.actions.dispatched(AuthActions.RunPostLogin).subscribe((action: AuthActions.RunPostLogin) => {
      this.checkList(action.checkCompanies);
    });
  }

  private checkList(checkCompanies: boolean) {
    this.status.removeMessage();
    // Step 1: ToS
    if (!this.tosService.hasAcceptedTos) { return this.handleTosNotAccepted(); }
    // Step 2: Themes
    if (!this.clientSettingsService.settings.InitialSetup) { return this.appearance.openSetThemeDialog(); }
    // Step 3: Set active company if needed. Note: This is the last step, will not dispatch a new AuthActions.RunPostLogin
    this.handleCompany(checkCompanies);
  }

  private handleTosNotAccepted() {
    this.store.dispatch(new SystemMessage.Actions.Display({
      Type: 'tos',
      Content: 'You need to accept the terms of service',
      Position: 'bottom',
      Sticky: true,
      InfoIcon: 'ion-info',
      InfoAction: () => {
        this.tosService.openTosModal();
      }
    }));
    return this.tosService.openTosModal();
  }

  private handleCompany(checkCompanies: boolean) {
    const activeId = this.clientService.client.ActiveCompanyId;
    const allIds = this.clientService.client.CompanyIds;
    const currentExists = allIds.includes(activeId);
    const rememberActiveCompany = this.clientSettingsService.settings.RememberActiveCompany;
    if (!checkCompanies) {
      return this.companyService.getOrFetchCompany(this.clientService.client.ActiveCompanyId);
    }

    if (allIds.length <= 1 && currentExists) {
      return this.companyService.setActiveCompany(activeId, false);
    }

    if (!rememberActiveCompany) {
      if (!currentExists) {
        this.store.dispatch(new OpenSetCompanyModal(allIds[0]));
      } else {
        this.store.dispatch(new OpenSetCompanyModal(activeId));
      }
    } else {
      return this.companyService.setActiveCompany(currentExists ? activeId : allIds[0], false);
    }
  }

}
