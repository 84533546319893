import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { GraphHover } from '@shared/components/line-graph/alg-models/graph-hover';
import { ALGTypes } from '@shared/components/line-graph/alg-types';
import { ValueUtils } from '@shared/utils/value.utils';
import * as d3 from 'd3';
import { range } from 'd3';
import { MonthPlotOptions } from './monthplot.options';

@Component({
  selector: 'indicio-monthplot',
  templateUrl: './monthplot.component.html',
  styleUrls: ['./monthplot.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MonthPlotComponent implements AfterViewInit, OnChanges {

  @Input() options: MonthPlotOptions.Options;
  @Input() loading: boolean;

  // SVG properties
  svgElements = new MonthPlotOptions.SVGElements();
  _id = `monthplot-${Math.random().toString(36).substring(7)}`;
  instance: number = Math.floor((Math.random() * 100) + 1);
  element: HTMLElement;
  parent: HTMLElement;
  private yMax: number;
  private yMin: number;
  private bucketSize: number;
  private bucketCount: number;
  private tickValues: number[];

  private Hover: GraphHover;

  private get margins() { return this.options.Margins; }
  private get svgWidth() { return this.options.Width || this.parent.clientWidth; }
  private get svgHeight() { return this.options.Height; }
  private get getGraphWidth() { return this.svgWidth - this.margins.left - this.margins.right; }
  private get getGraphHeight() { return this.svgHeight - this.margins.top - this.margins.bottom; }
  private get getXRange() { return { start: this.options.InnerPadding, end: this.getGraphWidth - this.options.InnerPadding }; }
  private get getYRange() { return { start: this.getGraphHeight - this.options.InnerPadding, end: this.options.InnerPadding }; }

  constructor(
    private ui: ElementRef
  ) {
    this.element = this.ui.nativeElement;
    this.parent = this.ui.nativeElement.parentElement;
  }

  public ngOnChanges(_: SimpleChanges) {
    this.setupChart();
  }

  public ngAfterViewInit() {
    this.setupChart();
  }

  private setupChart() {
    if (this.loading) { return; }
    this.removeSvg();
    this.createSvg();
    if (this.options.Data.Series.length === 0) {
      this.setNoData();
      return;
    }
    this.setYaxisMinMax();
    this.createScales();
    this.createXAxis();
    this.createYAxis();
    this.addData();
    this.Hover = new GraphHover({
      edgeDistance: 20,
      graphHeight: this.getGraphHeight,
      graphWidth: this.getGraphWidth,
      targetGroup: this.svgElements.graphGroup,
      yScale: this.svgElements.yScale,
      isPercentage: false,
      instance: this.instance,
    });
  }

  private setYaxisMinMax() {
    const allValues = this.options.Data.Series
      .flatMap(series => series.Buckets)
      .flatMap(bucket => bucket.Values)
      .map(value => value.Value).filter(x => x != null);
    this.yMax = allValues.max();
    this.yMin = allValues.min();
    const yRange = this.yMax - this.yMin;
    this.yMax += yRange * 0.05;
    this.yMin -= yRange * 0.05;

    this.bucketCount = this.options.Data.Labels.Buckets.length;
    this.bucketSize = this.options.Data.Series
      .flatMap(series => series.Buckets)
      .flatMap(bucket => bucket.Values.length)
      .max();

    const bucketWidth = this.bucketSize + 2;
    this.tickValues = range(bucketWidth / 2, bucketWidth * this.bucketCount, bucketWidth);
  }

  private removeSvg() {
    if (this.svgElements.svg) {
      const elem = this.element.querySelectorAll('svg');
      if (elem != null) { elem.forEach(e => e.remove()); }
    }
    this.svgElements = new MonthPlotOptions.SVGElements();
  }

  private createSvg() {
    const w = this.options.Width || this.parent.clientWidth;
    const b = this.options.BorderWidth;
    const h = this.options.Height;
    const m = this.options.Margins;

    this.svgElements.svg = d3.select(`.monthplot#${this._id}`)
      .append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    this.svgElements.graphGroup = this.svgElements.svg
      .append('g')
      .attr('transform', 'translate(' + m.left + ',' + m.top + ')');

    // background
    this.svgElements.graphGroup.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('ry', 7)
      .attr('rx', 7)
      .attr('width', w - m.left - m.right)
      .attr('height', h - m.top - m.bottom)
      .attr('class', 'monthplot-background');

    this.svgElements.innerGraph = this.svgElements.graphGroup.append('g')
      .attr('width', w - m.left - m.right)
      .attr('height', h - m.top - m.bottom)
      .attr('clip-path', `url(#rect-clip-${this._id})`)
      .attr('class', 'inner-graph');

    // border
    this.svgElements.graphGroup.append('rect')
      .attr('x', +(b / 2))
      .attr('y', -(b / 2))
      .attr('ry', 7)
      .attr('rx', 7)
      .attr('width', w - m.left - m.right - b)
      .attr('height', h - m.top - m.bottom + b)
      .style('stroke-width', b)
      .attr('class', 'monthplot-border');

    this.svgElements.graphGroup.append('clipPath')
      .attr('id', 'rect-clip-' + this._id)
      .append('rect')
      .attr('ry', 7)
      .attr('width', this.getGraphWidth + b * 2)
      .attr('height', this.getGraphHeight + b * 2)
      .attr('rx', 7);

    this.svgElements.svg.append('rect')
      .attr('transform', 'translate(' + m.left + ',' + m.top + ')')
      .attr('x', 0)
      .attr('y', 0)
      .attr('ry', 7)
      .attr('rx', 7)
      .attr('width', w - m.left - m.right)
      .attr('height', h - m.top - m.bottom)
      .attr('opacity', 0)
      .on('mouseover', this.handleMouseOver.bind(this))
      .on('mousemove', this.handleMouseOver.bind(this))
      .on('mouseout', this.handleMouseOut.bind(this));
  }

  private createScales() {
    const xRange = this.getXRange;
    const yRange = this.getYRange;

    this.svgElements.xScale = d3.scaleLinear()
      .range([xRange.start, xRange.end])
      .domain([0, (this.bucketSize + 2) * this.bucketCount]);

    this.svgElements.yScale = d3.scaleLinear()
      .range([yRange.start, yRange.end])
      .domain([this.yMin, this.yMax]);
  }

  private createXAxis() {
    // Append x tick lines and labels
    this.svgElements.graphGroup.append('g')
      .attr('class', 'x monthplot-axis')
      .call(
        d3.axisBottom(this.svgElements.xScale)
          .tickValues(this.tickValues)
          .tickFormat((d, i) => this.options.Data.Labels.Buckets[i])
          .tickSize(this.getGraphHeight)
          .tickPadding(10)
      );
  }

  private createYAxis() {
    const axOpts = this.options.YAxis;
    const ticks = this.getTickValues(this.yMin, this.yMax, axOpts.TickCount);
    // Append y lines
    this.svgElements.graphGroup.append('g')
      .attr('class', 'y monthplot-axis')
      .call(
        d3.axisLeft(this.svgElements.yScale)
          .tickValues(ticks)
          .tickFormat(f => ValueUtils.getValueAsAbbreviatedString(f.valueOf(), this.options.PercentScale))
          .tickPadding(-15)
          .tickSize(-this.getGraphWidth)
      );
  }

  private handleMouseOver(event: MouseEvent) {
    const graphPos = d3.pointer(event);
    this.getPointsAtMouseXY(graphPos[0]);
  }

  private handleMouseOut() {
    this.Hover.hideHover();
    this.Hover.hideHoverBox();
  }

  /** Get the closest points to the current mouse position. */
  private getPointsAtMouseXY(mouseX: number) {
    /* Setup mouse position values (y-value, x-value both as date and new pixel position) */
    if (!this.svgElements.xScale) { return; }
    const xValue = this.svgElements.xScale.invert(mouseX);

    const bucketIdx = Math.min(this.bucketCount - 1, Math.max(0, Math.floor(xValue / (this.bucketSize + 2))));
    const bucketLocation = Math.min(this.bucketSize - 1, Math.max(0, Math.round(xValue - (bucketIdx * (this.bucketSize + 2))) - 1));
    const bucketX = (this.bucketSize + 2) * bucketIdx + 1 + bucketLocation;

    const data = this.getHoverData(bucketIdx, bucketLocation);
    if (data.length <= 1) {
      this.handleMouseOut();
      return;
    }
    this.Hover.showHover();
    this.Hover.showHoverBox();
    this.Hover.updateHover(data, this.svgElements.xScale(bucketX), this.options.Data.Labels.Buckets[bucketIdx]);
  }

  private getHoverData(bucket: number, position: number) {
    const data: ALGTypes.AlgHoverTextLine[] = [];

    data.push({ Circle: false, Text: this.options.Data.Labels.Ticks[bucket][position] });
    this.options.Data.Series.forEach(x => {
      if (x.Buckets.length > bucket && x.Buckets[bucket].Values.length > position) {
        const value = x.Buckets[bucket].Values[position];
        if (value.Value === null || !value.Hover) return;
        data.push({ Circle: true, CircleColor: x.Color, Margins: [0, 0], Text: x.Name, Value: value.Value, ValueStr: ValueUtils.getValueAsAbbreviatedString(value.Value, this.options.PercentScale) });
        if (x.DrawBase) {
          data.last().ValueStr += ' (' + ValueUtils.getValueAsAbbreviatedString(x.Buckets[bucket].BaseValue, this.options.PercentScale) + ')';
        }
        if (value.Circle) {
          data.last().Text += ' (' + value.CircleLabel + ')';
        }
      }
    });
    return (data);
  }

  private addData() {
    this.options.Data.Series.forEach(d => {
      if (d.Type === 'line') {
        this.addLine(d, this.svgElements.xScale, this.svgElements.yScale);
      } else if (d.Type === 'bars') {
        this.addBars(d, this.svgElements.xScale, this.svgElements.yScale);
      }
    });
  }

  private addLine(data: MonthPlotOptions.SeriesModel, xScale: any, yScale: any) {
    data.Buckets.forEach((bucket, bucketIdx) => {
      const bucketStart = (this.bucketSize + 2) * bucketIdx + 1;
      const lineGenerator = d3.line<{ x: number, y: number; }>()
        .x(d => xScale(d.x))
        .y(d => yScale(d.y));

      const lineData = bucket.Values.map((v, i) => {
        return ({ x: bucketStart + i, y: v.Value });
      }).filter(v => v.y !== null);

      this.svgElements.innerGraph
        .append('path')
        .data([lineData])
        .style('fill', 'none')
        .style('stroke-width', 1.5)
        .style('stroke', data.Color)
        .attr('d', lineGenerator);

      if (data.DrawBase) {
        this.addBaseLine(data.Color, bucketIdx, bucket.BaseValue, xScale, yScale);
      }
      bucket.Values.forEach((value, tick) => {
        if (value.Circle) {
          this.addCircle(data.CircleColor, bucketIdx, tick, value.Value, value.CircleLabel, xScale, yScale);
        }
      });
    });
  }

  private addBars(data: MonthPlotOptions.SeriesModel, xScale: any, yScale: any) {
    const barGenerator = d3.line<{ x: number, y: number; }>()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y));

    data.Buckets.forEach((bucket, bucketIdx) => {
      const bucketStart = (this.bucketSize + 2) * bucketIdx + 1;
      bucket.Values.forEach((value, j) => {
        const barData = [
          { x: bucketStart + j, y: bucket.BaseValue },  // Start point
          { x: bucketStart + j, y: value.Value } // End point
        ];

        this.svgElements.innerGraph
          .append('path')
          .data([barData])
          .style('fill', 'none')
          .style('stroke-width', 1.5)
          .style('stroke', data.Color)
          .attr('d', barGenerator);

        if (value.Circle) {
          this.addCircle(data.CircleColor, bucketIdx, j, value.Value, value.CircleLabel, xScale, yScale);
        }
        if (data.DrawBase) {
          this.addBaseLine(data.Color, bucketIdx, bucket.BaseValue, xScale, yScale);
        }
      });
    });
  }

  private addBaseLine(color: string, bucket: number, value: number, xScale: any, yScale: any) {
    const lineGenerator = d3.line<{ x: number, y: number; }>()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y));

    const bucketStart = (this.bucketSize + 2) * bucket + 0.5;

    const lineData = [
      { x: bucketStart, y: value },  // Start point
      { x: bucketStart + this.bucketSize, y: value } // End point
    ];

    this.svgElements.innerGraph
      .append('path')
      .data([lineData])
      .style('fill', 'none')
      .style('stroke-width', 1.5)
      .style('stroke', color)
      .attr('d', lineGenerator);
  }

  private addCircle(color: string, bucket: number, tick: number, value: number, label: string, xScale: any, yScale: any) {
    this.svgElements.innerGraph
      .append('circle')
      .attr('fill', color)
      .attr('r', 3)
      .attr('cx', xScale((this.bucketSize + 2) * bucket + 1 + tick))
      .attr('cy', yScale(value));

    this.svgElements.innerGraph
      .append('text')
      .attr('fill', 'white')
      .style('font-size', '12px')
      .attr('r', 3)
      .attr('x', xScale((this.bucketSize + 2) * bucket + 1 + tick))
      .attr('y', yScale(value) - 5)
      .text(label);
  }

  private getTickValues(min: number, max: number, count: number) {
    const tickSize = (max - min) / (count - 1);
    const ans = [min];
    for (let i = 1; i < count - 1; i++) { ans.push(min + (i * tickSize)); }
    ans.push(max);
    return ans;
  }

  private setNoData() {
    const x = this.getGraphWidth / 2;
    const y = this.getGraphHeight / 2;
    this.svgElements.innerGraph.append('text')
      .attr('x', x + 'px')
      .attr('y', y + 'px')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .style('font-size', '30px')
      .style('font-family', 'Roboto')
      .style('font-weight', '700')
      .style('fill', 'rgba(0,0,0,0.2)')
      .text('No Data Selected');
  }
}
