import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigateToHome } from '@core/actions/navigation.actions';
import { PusherService } from '@core/services/frontend/pusher.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { DisplayFrontendService } from '@core/store/display/display.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { ToSService } from '@core/store/tos/tos.service';
import { ForecastModuleService } from '@modules/forecast/services/forecast-module.service';
import { AppActions } from '@modules/root/app.actions';
import { Store } from '@ngxs/store';
import { StorageUtils } from '@shared/utils/storage.utils';

export enum ForecastKeys {
  forecastReturnUrl = 'forecast.returnUrl'
}

@Injectable()
export class ForecastResolver {

  constructor(
    private forecastService: ForecastFrontendService,
    private projectService: ProjectFrontendService,
    private companyService: CompanyFrontendService,
    private moduleService: ForecastModuleService,
    private auth: AuthFrontendService,
    private store: Store,
    private route: ActivatedRoute,
    private tosService: ToSService,
    private pusher: PusherService,
    private displayService: DisplayFrontendService
  ) { }

  async resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (!this.tosService.hasAcceptedTos) {
      return this.tosService.openTosModal();
    }
    const forecastId = route.paramMap.get('forecastId');
    const versionString = route.queryParamMap.get('version');
    let versionNumber = versionString ? +versionString : null;
    this.store.dispatch(new AppActions.ResolverLoading(true, 'Loading forecast...'));

    if (this.auth.needToSetActiveCompany()) {
      this.store.dispatch(new AppActions.ResolverLoading(false));
      this.store.dispatch(new NavigateToHome);
      StorageUtils.set(ForecastKeys.forecastReturnUrl, this.route.snapshot.queryParams['returnUrl']);
      return;
    }

    try {
      const forecast = await this.getForecast(forecastId);
      if (!forecast) { return null; }
      const project = await this.projectService.getOrFetchProject(this.companyService.activeCompanyId, forecast.ProjectId);
      const company = await this.companyService.getOrFetchCompany(project.CompanyId);
      const fverClientSettings = await this.forecastService.getForecastClientSettings(forecast.activeVersionId);
      const forecastVersion = await this.handleVersion(versionNumber, forecast, project, company);
      const objs = { forecastVersion, project, company };
      versionNumber = forecast.activeVersion.VersionNumber;
      this.forecastService.setActiveForecast(forecastId);
      this.displayService.useSettings(fverClientSettings);

      this.pusher.joinForecast(this.moduleService.forecastId);

      return Promise.resolve(objs);
    } catch (error) {
      console.error('Error in forecast-model resolver', error);
      this.auth.reload();
    } finally {
      this.store.dispatch(new AppActions.ResolverLoading(false));
    }
  }

  private async getForecast(id: string) {
    try {
      return await this.forecastService.getOrFetchForecast(id);
    } catch (err) {
      console.error('Error in forecast-model resolver', err);
      this.auth.reload();
      return null;
    }
  }

  private async handleVersion(versionNumber: number = null, forecast: ForecastModel, project: ProjectModel, company: CompanyModel) {
    let version: ForecastVersionModel;
    if (versionNumber) {
      version = forecast.ForecastVersions.find(x => x.VersionNumber === versionNumber);
      version = await this.forecastService.getOrFetchForecastVersion(version.ForecastVersionId);
    } else {
      const versionId = forecast.activeVersion ? forecast.activeVersion.ForecastVersionId : null;
      if (versionId) {
        version = await this.forecastService.getOrFetchForecastVersion(versionId);
      } else {
        version = forecast.getLatestVersion();
      }
    }

    if (!this.moduleService.initialized ||
      this.moduleService.forecastId !== forecast.ForecastId ||
      this.moduleService.forecastVersionId !== version.ForecastVersionId) {
      this.moduleService.setup({ forecastVersion: version, project, company });
    }

    return version;
  }
}
