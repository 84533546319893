import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';

import { UpdatableStatus } from '@core/interfaces/if-update-status';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ApiStatus } from '@core/types/api.status.type';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { MultiUpdateRemoteDataModalOpts } from './multi-update-remotedata-modal.options';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';

class RemoteTemp implements UpdatableStatus {
  SourceVariableId: string;
  Name: string;
  shouldUpdate: boolean;
  updateError: string;
  updateInProgress: boolean;
  updateMessage: string;
  updateStatus: ApiStatus;
  missing: boolean;
}

@Component({
  selector: 'indicio-multi-update-remotedata-modal',
  templateUrl: './multi-update-remotedata-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MultiUpdateRemoteDataModalComponent extends ModalModelComponent {

  opts: MultiUpdateRemoteDataModalOpts;
  toUpdate: ForecastVariableModel[];
  forecastVersion: ForecastVersionModel;
  updatedCount = 0;
  processDone = false;
  variables: RemoteTemp[];

  public get toUpdateCount() { return this.variables.filter(x => x.shouldUpdate)?.length; }

  constructor(
    protected store: Store,
    public envService: EnvironmentService,
    private sourceService: SourceVariableFrontendService
  ) {
    super();
  }

  public setOptions(options: MultiUpdateRemoteDataModalOpts) {
    this.opts = options;

    this.variables = this.opts.sourceVariableIds.map(x => {
      const v = new RemoteTemp();
      v.SourceVariableId = x.Value;
      v.Name = x.Display;
      v.shouldUpdate = true;
      v.updateStatus = 'pending';
      return v;
    });

    this.isLoading = false;
  }

  public toggleUpdate(variable: RemoteTemp) {
    variable.shouldUpdate = !variable.shouldUpdate;
    if (variable.shouldUpdate) {
      variable.updateStatus = 'pending';
    } else {
      variable.updateStatus = 'skipped';
    }
  }

  public async update() {
    this.pending = true;
    const toUpd = this.variables.filter(x => x.shouldUpdate);
    const count = toUpd.length;
    const updateFunc = async (i) => {
      const variable = toUpd[i];
      await this.syncRemoteVariable(variable);
    };

    let current = 0;
    const queueFunc = async () => {
      if (current >= count) {
        this.pending = false;
        return;
      }
      await updateFunc(current);
      current += 1;
      setTimeout(async () => {
        await queueFunc();
      }, 150);
    };

    await queueFunc()
      .finally(() => this.processDone = true);
  }

  private syncRemoteVariable(variable: RemoteTemp){
    variable.updateStatus = 'pending';
    this.sourceService.syncVariableFromRemote(this.opts.companyId, variable.SourceVariableId)
      .then(lastUpdated => {
        variable.updateStatus = 'success';
        if (lastUpdated === null) {
          variable.missing = true;
        }
      })
      .catch(e => {
        variable.updateStatus = 'failed';
        if (e.error && e.error.match(/<->/)) {
          variable.updateError = e.error.split('<->')[0];
        } else if (e.error) {
          variable.updateError = e.error;
        } else {
          variable.updateError = 'Something went wrong';
        }
      })
      .finally(() => this.updatedCount += 1);
  }
}
