import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { FredDataSerieModel } from '@core/store/providers/fred/fred-dataserie.model';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { FredImportModalOpts } from './fred-import-modal.options';

export class OpenFREDImportModal {
  public static readonly type = '[MODAL] Open fred import modal';
  data: FredImportModalOpts;
  constructor(
    series: FredDataSerieModel,
    forecastVersion: ForecastVersionModel,
    processQueue?: boolean,
    createdSourceVariables?: SourceVariableModel[]
  ) {
    this.data = { series, forecastVersion, processQueue, createdSourceVariables };
  }
}
