import { Injectable } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';
import { UUIdUtils } from '@shared/utils/uuid.utils';
import { OxfordContentDTO, OxfordTreeLocationDTO, OxfordVariableDTO, OxfordVariableMetaDTO } from './oxford-dtos';
import { OxfordVariableMetaModel } from './oxford-models';

@Injectable({
  providedIn: 'root'
})
export class OxfordMapper {

  public mapRegion(dto: OxfordTreeLocationDTO, level = -1) {
    dto.level = level;
    dto.id = UUIdUtils.GetNewIdV4();
    if (dto.Children) {
      dto.Children = dto.Children.map(child => this.mapRegion(child, level + 1));
    }
    return dto;
  }

  public mapContent(dto: OxfordContentDTO, level = 0) {
    dto.id = UUIdUtils.GetNewIdV4();
    dto.Categories.forEach(category => {
      category.id = UUIdUtils.GetNewIdV4();
      category.parentId = dto.id;
      category.level = level;
      if (!category.Children) { return category; }
      category.Children = this.mapContent(category.Children, level + 1);
    });

    dto.Variables.forEach(variable => {
      variable.oldData = false;
      variable = Object.faMapTo(new OxfordVariableDTO, variable);
      variable.id = UUIdUtils.GetNewIdV4();
      variable.parentId = dto.id;
      if (!variable.Children) { return variable; }
      variable.Children = this.mapContent(variable.Children, level + 1);
    });

    dto.hasChildren = !!dto.Categories.length;
    dto.hasVariables = !!dto.Variables.length;

    return dto;
  }

  public mapMeta(dto: OxfordVariableMetaDTO) {
    const model = Object.faMapTo(new OxfordVariableMetaModel, dto);
    model.VariableCode = dto.VC;
    model.Description = dto.Desc;
    model.Units = dto.U;
    model.HasQuarterly = dto.HasQ;
    model.HistoricalEndQuarter = DateUtils.newNullableDate(dto.EndQ);
    model.HistoricalEndYear = DateUtils.newNullableDate(dto.EndY);
    model.LastUpdate = DateUtils.newNullableDate(dto.Updated);
    model.ScaleFactor = dto.SF;
    model.SeasonallyAdjusted = dto.Seas;
    model.Source = dto.S;
    model.SourceDescription = dto.SD;

    if (model.EndDate) {
      model.oldData = DateUtils.isOldData(model.EndDate);
    }

    return model;
  }

}
