import { AccuracyMeasureType } from '@core/constants/accuracy.constants';
import { DoubleValuePropertyDTO } from '@core/entities/dtos/double-value-property-dto';
import { PastForecastDTO } from '@core/entities/dtos/past-forecast-dto';
import { FittedValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ModelName } from '@modules/lang/types/model-name';

export namespace AccuracyMeasureUtils {

  export class AccuracyObject {
    public modelName?: ModelName;
    public show: boolean;
    public Color: string;
    public RollingAccuracy: boolean;
    public FittedValues: FittedValueDTO[];
    public WFittedValues?: FittedValueDTO[];
    public PastForecasts: PastForecastDTO[];
  }

  /** Helper class that defines the nessecary members to call the below functions. */
  class InOutProperties {
    InProperties: DoubleValuePropertyDTO[];
    OutProperties: DoubleValuePropertyDTO[];
  };

  export function getInPropertyValue(obj: InOutProperties, propertyName: AccuracyMeasureType) {
    if (!obj.InProperties || obj.InProperties.length === 0) { return null; }
    const property = obj.InProperties.find(x => x.Name === propertyName);
    if (property) { return property.Value; }
    return null;
  }

  export function getOutPropertyValue(obj: InOutProperties, propertyName: AccuracyMeasureType): number {
    if (!obj.OutProperties || obj.OutProperties.length === 0) { return null; }
    const property = obj.OutProperties.find(x => x.Name === propertyName);
    if (property) { return property.Value; }
    return null;
  }

  export function compare(a: InOutProperties, b: InOutProperties, meas: AccuracyMeasureType, inOut: 'in' | 'out') {
    return inOut === 'in'
      ? getInPropertyValue(a, meas) === getInPropertyValue(b, meas) ? 0 : getInPropertyValue(a, meas) > getInPropertyValue(b, meas) ? 1 : -1
      : getOutPropertyValue(a, meas) === getOutPropertyValue(b, meas) ? 0 : getOutPropertyValue(a, meas) > getOutPropertyValue(b, meas) ? 1 : -1;
  }

  export function shouldScaleBeReversed(propertyName: AccuracyMeasureType) {
    switch (propertyName) {
      case 'HR': return true;
      case 'RSQ': return true;
      default: return false;
    }
  }

  export function getPropertyDescription(propertyName: AccuracyMeasureType): string {
    switch (propertyName) {
      case 'ME': return 'Mean error';
      case 'RMSE': return 'Root mean square error';
      case 'MAE': return 'Mean absolute error';
      case 'MPE': return 'Mean percentage error';
      case 'MAPE': return 'Mean absolute percentage error';
      case 'HR': return 'Hit Ratio';
      case 'MASE': return 'Mean average scaled error';
      case 'RSQ': return 'R-squared';
      default: return 'Property unknown';
    }
  }
}
