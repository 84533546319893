import { DisplayValue } from '../types/display-value';

// The specifications "Basic", "Lag," "SparseLag," "SparseOO," and "OwnOther"
// can accommodate both VAR and VARX models.
// EFX only applies to VARX models.
// "HVARC," "HVAROO," "HVARELEM," and "Tapered" can only be used with VAR models.
export const LassoUniSupport = ['Basic', 'Lag']; // Maybe 'HVARC' here as well
export const LassoVARXSupport = ['Basic', 'EFX', 'Lag', 'SparseLag', 'SparseOO', 'OwnOther'];
export const LassoVARSupport = [
  'Basic', 'Lag', 'SparseLag', 'SparseOO', 'OwnOther',
  'HVARC', 'HVAROO', 'HVARELEM', 'Tapered'
];

export type VsResultType = 'MF' | 'SF';
export type VSModeType = 'stepwise' | 'backward' | 'coef' | 'mfstepwise' | 'mfbackward' | 'mfcoef';
export type VsCoefMethodType = 'first' | 'all-by-lag' | 'all-by-eq';
export type VSMeasurementType = 'InSample' | 'OOS' | 'COEF' | 'MAE';

export class VSMode extends DisplayValue<VSModeType> { };
export const VSModes = [
  new VSMode('stepwise', 'Stepwise search', 'Search for indicators by adding one at a time, only keeping those improving the accuracy of the model. Already included indicators may be removed in each step. This strategy may take some time.'),
  new VSMode('backward', 'Backwards search', 'Search for indicators by starting with all of them in one model, then measure impact of indicators by removing one at a time.'),
  new VSMode('coef', 'Coefficient search', 'Order variables by their coefficient value'),
  new VSMode('mfstepwise', 'MF Stepwise search', ''),
  new VSMode('mfbackward', 'MF Backwards search', ''),
  new VSMode('mfcoef', 'MF Coefficient search', '')
];

export class VSCoefMethod extends DisplayValue<VsCoefMethodType> { };
export const VSCoefMethods = [
  new VSCoefMethod('first', 'Main equation only', 'Use only coefficients from the main variable equation'),
  new VSCoefMethod('all-by-lag', 'All equations - lagwise', 'Use coeffiencients from all equations, weighted lagwise'),
  new VSCoefMethod('all-by-eq', 'All equations', 'Use coeffiencients from all equations')
];

export class VSLassoStruct extends DisplayValue<string> { };
export const VSLassoStructs = [
  new VSLassoStruct('Basic', 'Basic', 'Basic'),
  new VSLassoStruct('BasicEN', 'Basic elastic net', 'Basic elastic net'),
  new VSLassoStruct('Lag', 'Lag', 'Lag'),
  new VSLassoStruct('OwnOther', 'Own Other', 'OwnOther'),
  new VSLassoStruct('SparseOO', 'Sparse Own Other', 'Sparse Own Other'),
  new VSLassoStruct('EFX', 'EFX', 'EFX'),
  new VSLassoStruct('HVARC', 'HVARC', 'HVARC'),
  new VSLassoStruct('HVAROO', 'HVAROO', 'HVAROO'),
  new VSLassoStruct('HVARELEM', 'HVARELEM', 'HVARELEM'),
  new VSLassoStruct('Tapered', 'Tapered', 'Tapered')
];

export class VSMeasurement extends DisplayValue<VSMeasurementType> { };
export const VSMeasurements = [
  new VSMeasurement('InSample', 'In-sample MSFE', 'Lasso model In-sample MSFE', 'RW'),
  new VSMeasurement('OOS', 'Out-of-sample MSFE', 'Lasso model Out-of-sample MSFE', 'OOS'),
  new VSMeasurement('COEF', 'Coefficient', 'Lasso model coefficients weights, summarized', 'Coef'),
  new VSMeasurement('MAE', 'MAE', 'Lasso model Mean Average Error (MAE)', 'MAE'),
];
