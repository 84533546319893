import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * https://medium.com/wizdm-genesys/teleporting-content-in-angular-4cbd9ba8427f
 */

export interface TeleportInstance {
  [target: string]: TemplateRef<any>;
}

@Injectable({
  providedIn: 'root'
})
export class TeleportService extends BehaviorSubject<TeleportInstance> {

  constructor() { super(null); }

  public activate(instance: TeleportInstance) {
    this.next(instance);
  }

  public clearAll() {
    this.next(null);
  }
}
