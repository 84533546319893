<h1 mat-dialog-title>{{ !edit ? 'Create new' : 'Edit' }} automatic email</h1>
<div mat-dialog-content>

  <div class="grid-system"
       style="--grid-template: 1fr 1fr; gap: 20px">
    <div style="padding-right: 20px; border-right: 1px solid var(--indicio-modal-text-color, #ccc);">
      <h2>Settings</h2>
      <ng-container *ngTemplateOutlet="createTemplate"></ng-container>
    </div>
    <div>
      <h2>Included companies</h2>
      <div style="max-height: 465px; overflow: auto;">
        <ul class="company-list">
          <li *ngFor="let company of includedCompanies"
              (click)="toggleIgnoredCompany(company)"
              [ngClass]="{ ignored: isIgnoredCompany(company)}">
            {{ company.Display }}
            <i class="ion-eye"
               [class]="isIgnoredCompany(company) ? 'ion-eye-disabled' : 'ion-eye'"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
  <button mat-flat-button
          (click)="save()">
    {{ edit ? 'Edit' : 'Create' }}
  </button>
</div>

<ng-template #createTemplate>
  <indicio-modal-option-row *ngIf="variables"
                            [stacked]="true"
                            optionTitle="Report type">
    <mat-form-field>
      <mat-label>Report type</mat-label>
      <mat-select [value]="data.Dto.ReportType"
                  (selectionChange)="onReportTypeChange($event)"
                  required>
        <mat-option [value]="c"
                    [title]="c"
                    *ngFor="let c of variables.ReportTypes">{{ c }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Recipients"
                            optionSubtitle="Comma separated list">

    <mat-form-field>
      <mat-label>Recipients</mat-label>
      <input type="email"
             class="material"
             matInput
             autocomplete="off"
             [ngModel]="recipients"
             (ngModelChange)="onRecipientsChange($event)"
             name="Email"
             min=4
             require>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Send at hour"
                            optionSubtitle="Hour of day (0-23)">

    <mat-form-field>
      <mat-label>Hour</mat-label>
      <input type="number"
             class="material"
             matInput
             autocomplete="off"
             [(ngModel)]="data.Dto.SendAtHour"
             name="SendAtHour"
             min=0
             max="23"
             require>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Send interval">
    <mat-form-field>
      <mat-label>Interval</mat-label>
      <mat-select [(value)]="data.Dto.SendInterval"
                  required>
        <mat-option [value]="p.Value"
                    [title]="p.Display"
                    *ngFor="let p of periodicities">{{ p.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Only for trial accounts">
    <section class="margin-top margin-bottom-10">
      <mat-slide-toggle color="accent"
                        [ngModel]="data.Dto.TrialOnly"
                        (change)="onTrialOnlyChange($event)"
                        [checked]="data.Dto.TrialOnly">
      </mat-slide-toggle>
    </section>
  </indicio-modal-option-row>
</ng-template>
