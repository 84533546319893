import { Component } from '@angular/core';
import { AdminSites, CloseMainMenuAction, NavigateToAdmin, NavigateToAutomation, NavigateToSavedAdminPage } from '@core/actions/navigation.actions';
import { PusherActions } from '@core/actions/pusher.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { OnboardingService } from '@core/services/frontend/onboarding.service';
import { PusherService } from '@core/services/frontend/pusher.service';
import { PusherOpenAdminActionDTO } from '@core/services/pusher/dtos/pusher-action-dto';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ToSService } from '@core/store/tos/tos.service';
import { WikiActions } from '@core/store/wiki/wiki.actions';
import { WikiService } from '@core/store/wiki/wiki.service';
import { BugDialogService } from '@dialogs/bugs/bug-dialogs.service';
import { SupportDialogService } from '@dialogs/support/support-dialogs.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGraduationCap, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { AdminFrontendService } from '@modules/admin/services/admin-frontend.service';
import { Store } from '@ngxs/store';
import { OpenCompanyModal } from '@shared/modals/company/overview/company-modal.actions';
import { OpenGdprModal } from '@shared/modals/gdpr/gdpr-text-modal.action';
import { OpenProfileModal } from '@shared/modals/profile/profile-modal.actions';
import { ProfileModalTab } from '@shared/modals/profile/profile-modal.options';
import { OpenSecuritySettingsModal } from '@shared/modals/security-settings/security-settings-modal.actions';
import { OpenToSModal } from '@shared/modals/tos/tos-modal.action';
import { Subscription } from 'rxjs';
import { NavigationDrawerType, NavigationPageType } from './entities/navigation.entities';
import { NavigationActions } from './navigation.actions';

@Component({
  selector: 'indicio-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less'],
})
export class NavigationComponent {

  public wikiIcon = faGraduationCap as IconProp;
  public supportIcon = faHeadset as IconProp;
  private sub: Subscription = new Subscription();

  public activePage: NavigationPageType = null;

  systemMessagePosition: string;
  openDrawer: NavigationDrawerType;
  profileOpen = false;

  adminErrors = 0;

  public profile$ = this.profileService.profile$();
  public get loggedIn$() { return this.authService.loggedIn$; }
  public get isAdmin$() { return this.authService.isAdmin$; }
  public get isAdmin() { return this.authService.isAdmin; }
  public get isSupport() { return this.authService.isSupport; }
  public get activeCompany$() { return this.clientService.activeCompany$; }
  public get companies$() { return this.companyService.companies$; }
  public get hasAcceptedTos() { return this.tosService.hasAcceptedTos; }

  public constructor(
    private store: Store,
    private status: StatusService,
    public appearance: AppearanceService,
    private actions: ActionService,
    private profileService: ProfileFrontendService,
    private clientService: ClientFrontendService,
    private companyService: CompanyFrontendService,
    private tosService: ToSService,
    public authService: AuthFrontendService,
    public onboarding: OnboardingService,
    private adminFrontendService: AdminFrontendService,
    private pusherService: PusherService,
    public envService: EnvironmentService,
    public wikiService: WikiService,
    public supportDialogService: SupportDialogService,
    private bugDialogService: BugDialogService,
  ) {
    this.sub.add(this.actions.dispatched(PusherActions.SetupAdminBindings).subscribe(() => {
      this.pusherService.addAdminBinding('admin-event', d => this.store.dispatch(new PusherActions.AdminEvent('admin-event', d)));
      this.sub.add(this.actions.dispatched(PusherActions.AdminEvent).subscribe((a: PusherActions.AdminEvent) => {
        if (a.content === 'error') {
          this.adminErrors++;
        }
      }));
    }));

    this.sub.add(this.actions.dispatched(PusherActions.SetupOpenAdminBindings).subscribe(() => {
      this.pusherService.addOpenAdminBinding('client-open-admin-event', (d: PusherOpenAdminActionDTO) => this.store.dispatch(new PusherActions.OpenAdminEvent(d.Type, d)));
    }));

    this.sub.add(this.authService.loggedIn$.subscribe(loggedin => {
      if (!loggedin) { this.systemMessagePosition = undefined; }
      if (!loggedin || !this.isAdmin) { return; }
      this.setupAdminPusher();
      this.adminFrontendService.getIndicioEvents('error')
        .then(errors => {
          this.adminErrors = errors.body.length;
        });
    }));

    this.sub.add(this.actions.dispatched(CloseMainMenuAction).subscribe(() => {
      this.openDrawer = null;
      this.activePage = null;
      this.profileOpen = false;
    }));
  }

  public toggleDrawer(drawer: NavigationDrawerType) {
    switch (drawer) {
      case 'forecasts':
        this.toggleForecastsDrawer();
        break;
      case 'reports':
        this.toggleReportsDrawer();
        break;
    }
    this.profileOpen = false;
  }

  public toggleProfile() {
    this.activePage = null;
    this.profileOpen = !this.profileOpen;
    if (!!this.openDrawer) { this.toggleDrawer(this.openDrawer); }
  }

  public reportABug() { this.bugDialogService.opeBugReport({}); }
  public readTos() { this.store.dispatch(new OpenToSModal); }
  public readGdpr() { this.store.dispatch(new OpenGdprModal); }
  public editProfile(tab?: ProfileModalTab) { this.store.dispatch(new OpenProfileModal(tab)); }
  public openAutomationView() { this.store.dispatch(new NavigateToAutomation('logs')); this.activePage = 'automation'; }
  public openSecuritySettings() { this.store.dispatch(new OpenSecuritySettingsModal); }
  public editCompany(company: CompanyModel) {
    this.store.dispatch(new OpenCompanyModal(company !== null ? company.CompanyId : this.clientService.activeCompanyId));
  }
  public reloadSession() {
    this.authService.reload()
      .catch(error => this.status.setError(error));
  }

  public logout() {
    this.toggleProfile();
    this.authService.logout();
  }

  public openCompany() {
    this.store.dispatch(new OpenCompanyModal(this.clientService.client.ActiveCompanyId));
  }

  public openWiki() {
    this.store.dispatch(new WikiActions.CloseSidebar);
    this.wikiService.openInSidebar = false;
    this.wikiService.open(this.wikiService.lastOpenedSlug);
  }

  public gotoAdmin() {
    if (!this.isAdmin) { return; }
    this.store.dispatch(new NavigateToAdmin(AdminSites.overview, () => {
      this.store.dispatch(new NavigateToSavedAdminPage());
    }));
    this.activePage = 'admin';
  }

  private toggleReportsDrawer() {
    this.activePage = null;
    this.openDrawer = this.openDrawer === 'reports' ? null : 'reports';
  }

  private toggleForecastsDrawer() {
    this.activePage = null;
    this.openDrawer = this.openDrawer === 'forecasts' ? null : 'forecasts';
    if (this.openDrawer === 'forecasts') {
      this.store.dispatch(new NavigationActions.SetActiveProject);
    }
  }

  private setupAdminPusher() {
    this.adminFrontendService.getAdminPusherChannelId()
      .then(id => {
        this.pusherService.setupAdmin(id);
        this.pusherService.setupOpenAdmin(id);
      })
      .catch(_err => { console.error(_err); });
  }
}
