<h1 mat-dialog-title
    style="margin-bottom: 5px;">{{'Task progress: ' + data.task.Name }}</h1>
<div mat-dialog-content
     style="position: relative">
  <h3 style="margin-bottom: 10px;">Status:
    <ng-container [ngSwitch]="data.task.State">
      <ng-container *ngSwitchCase="ETaskState.STARTING">
        Running <i class="ion-load-c spinner"></i>
      </ng-container>
      <ng-container *ngSwitchCase="ETaskState.RUNNING">
        Running <i class="ion-load-c spinner"></i>
      </ng-container>
      <ng-container *ngSwitchCase="ETaskState.FAILED">
        Failed <i class="ion-close red"></i>
      </ng-container>
      <ng-container *ngSwitchCase="ETaskState.SUCCESS">
        Done <i class="ion-checkmark green"></i>
      </ng-container>
      <ng-container *ngSwitchCase="ETaskState.QUEUED">
        Queued <i class="ion-clock"></i>
      </ng-container>
    </ng-container>
  </h3>
  <ul id="task-progress-messages"
      (scroll)="onScroll($event)"
      *ngIf="data.task.messages.length"
      style="max-height: 300px; overflow-y: auto;">
    <li *ngFor="let msg of data.task.messages"
        [innerHtml]="msg | safeHtml"></li>
  </ul>
  <div class="pointer"
       style="position: absolute; bottom: 25px; left: 200px; background-color: rgba(0,0,0,0.7); padding: 5px 10px; border-radius: 3px; color: white;"
       *ngIf="manualScroll"
       (click)="followLog()">Follow the log</div>
  <div id="task-progress-waiting"
       *ngIf="!!!data.task.messages.length">
    Waiting for messages...
  </div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button
          (click)="close()">
    Close
  </button>
</div>
