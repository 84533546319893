<indicio-dialog>
  <h1 mat-dialog-title>Client settings</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="initialized && info; else loadingTemplate">
      <ng-container *ngTemplateOutlet="dialogTabNavigationTemplate"></ng-container>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Close</button>
    <button mat-flat-button
            color="primary"
            (click)="save()"
            cdkFocusInitial>Save</button>
  </div>
</indicio-dialog>

<ng-template #loadingTemplate>
  <indicio-spinner></indicio-spinner>
</ng-template>

<ng-template #dialogTabNavigationTemplate>
  <mat-tab-group animationDuration="0ms"
                 color="primary"
                 [selectedIndex]="viewIndex"
                 (selectedIndexChange)="changeView($event)"
                 [disablePagination]="true">
    <mat-tab label="General">
      <ng-container *ngIf="viewIndex === viewTypes.general"
                    [ngTemplateOutlet]="generalTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Audit">
      <ng-container *ngIf="viewIndex === viewTypes.audit"
                    [ngTemplateOutlet]="auditTemplate"></ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<ng-template #generalTemplate>
  <indicio-user-info-admin-dialog-general-tab [clientInfo]="info"
                                              (clientInfoChange)="shouldSave = $event"
                                              [client]="data.Client">
  </indicio-user-info-admin-dialog-general-tab>
</ng-template>

<ng-template #auditTemplate>
  <indicio-user-info-admin-dialog-audit-tab [client]="data.Client"></indicio-user-info-admin-dialog-audit-tab>
</ng-template>
