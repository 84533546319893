import { ImportedHistoricBaseEventModel, ImportedHistoricEventModel } from '@core/store/historic-event/models/imported/imported-historic-base-event.model';
import { HistoricEventsCorrelationModalOptions } from './event-correlation-modal.options';

export class OpenImportHistoricEventsCorrelationModal {
  public static readonly type = '[MODAL] Open imported historic events correlation modal';
  data: HistoricEventsCorrelationModalOptions;
  constructor(
    baseEvent: ImportedHistoricBaseEventModel,
    event: ImportedHistoricEventModel,
    forecastVersionId: string,
    callback?: any,
    onBack?: any
  ) {
    this.data = { baseEvent, event, forecastVersionId, callback, onBack };
  }
}
