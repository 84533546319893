import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { DatePickerDayComponent, DatePickerMonthComponent, DatePickerQuarterComponent, DatePickerYearComponent } from '@shared/components';
import * as moment from 'moment';
import { DatePickerBaseComponent } from './base/date-picker-base.component';

@Component({
  selector: 'indicio-date-picker',
  templateUrl: './date-picker.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent extends DatePickerBaseComponent implements AfterViewInit, OnChanges {

  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
  public dateViewContainer: ViewContainerRef;

  @Input() public periodicity: PeriodicityType = 'day';

  constructor(public viewContainerRef: ViewContainerRef, private cdr: ChangeDetectorRef) { super(); }

  public ngOnChanges(_changes: SimpleChanges): void {
    this.setComponent();
  }

  public ngAfterViewInit(): void {
    this.setComponent();
  }

  private setComponent() {
    let component;

    switch (this.periodicity) {
      case 'month':
        component = DatePickerMonthComponent;
        break;
      case 'quarter':
        component = DatePickerQuarterComponent;
        break;
      case 'year':
        component = DatePickerYearComponent;
        break;
      case 'weekday':
      case 'day':
      default:
        component = DatePickerDayComponent;
    }

    this.dateViewContainer.clear();

    const componentRef = this.dateViewContainer.createComponent<DatePickerBaseComponent>(component);
    // Add any inputs that needs to be passed down to the component
    componentRef.instance.changeDate = this.changeDate;
    componentRef.instance.closeDatePicker = this.closeDatePicker;
    componentRef.instance.disabled = this.disabled;
    componentRef.instance.hint = this.hint;
    componentRef.instance.label = this.label;
    componentRef.instance.max = this.max;
    componentRef.instance.min = this.min;
    componentRef.instance.picker = this.picker;
    componentRef.instance.placeholder = this.placeholder;
    componentRef.instance.style = this.style;
    componentRef.instance.value = this.value;
    if (this.periodicity === 'weekday') {
      componentRef.instance.filter = (d: Date | moment.Moment) => {
        if (!d) { return false; }
        const day = moment.isMoment(d) ? d.day() : d.getDay();
        /* Prevent Saturday and Sunday for select. */
        return day !== 0 && day !== 6;
      };
    }
    componentRef.instance.selectDateEvent.subscribe(val => this.selectDateEvent.emit(val));
    this.cdr.detectChanges();
  }
}
