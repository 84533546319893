<indicio-dialog-base>
  <ng-container>
    <h1 mat-dialog-title>{{ page?.Title || data.PageSlug }}
      <fa-icon *ngIf="authService.isAdmin$ | async"
               [icon]="faDownload"
               style="margin-left: 10px"
               class="cursor-pointer"
               (click)="downloadPDF()"
               [indicioTippy]="{ content: 'Download entire Wiki as a PDF file', placement: 'top' }"></fa-icon>
      <i *ngIf="authService.isAdmin$ | async"
         style="margin-left: 10px"
         class="ion-edit cursor-pointer"
         (click)="openEdit()"></i>
      <div class="pull-right cursor-pointer vertical-align pin"
           (click)="pin()">
        <span>Dock to side</span>
        <i class="ion-ios-browsers-outline"></i>
      </div>
    </h1>
    <div mat-dialog-content
         style="position: relative;">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ng-container>
  <div mat-dialog-actions>
    <div>
      <button *ngIf="wikiService.pageHistory?.length"
              mat-stroked-button
              (click)="open(wikiService.previousPage())">
        Back
      </button>
    </div>
    <button mat-stroked-button
            (click)="onNoClick()">
      Close
    </button>
  </div>
</indicio-dialog-base>

<ng-template #contentTemplate>
  <ng-container *ngIf="page && loaded; else notFoundTemplate">
    <div class="margin-bottom-10">
      <indicio-wiki-snackbar [page]="page"
                             (openEvent)="open($event)"></indicio-wiki-snackbar>
    </div>
    <indicio-wiki-page [page]="page"></indicio-wiki-page>
  </ng-container>

  <ng-template #notFoundTemplate>
    <div class="margin-bottom-10">
      <ng-container *ngIf="!page && loaded; else loadingTemplate">
        Page not found.
      </ng-container>
    </div>
  </ng-template>

  <ng-template #loadingTemplate>
    <ng-container *ngIf="!page && !loaded">
      Loading...
    </ng-container>
  </ng-template>


</ng-template>
