import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { DisplayFrontendService } from '@core/store/display/display.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ScenarioVariableDTO } from '@core/store/scenario/dtos/scenario-variable.dto';
import { ScenarioDTO } from '@core/store/scenario/dtos/scenario.dtos';
import { ScenarioMapper } from '@core/store/scenario/scenario-mapper';
import { ForecastVersionHistoricDataModel } from '@core/store/stat-model/dtos/stat-model-historic-data.dto';
import { MultivariateFrontendService } from '@core/store/stat-model/multivariate/multivariate.frontend.service';
import { StatModelUtils } from '@core/store/stat-model/stat-model.utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faRotateLeft, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { DisplayValue } from '@modules/lang/types/display-value';
import { EmptyModelName } from '@modules/lang/types/model-name';
import { AlgConverter } from '@shared/components/line-graph/alg-line.utils';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgChartStyles, AlgOptions, StrokeSetups } from '@shared/components/line-graph/alg.options';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { NumberPipe } from '@shared/modules/pipes/number.pipe';
import { ArrayUtils } from '@shared/utils/array.utils';
import { SummaryUtils } from '@shared/utils/forecast/summary.utils';
import { ItemState } from '@shared/utils/itemstate.util';
import * as moment from 'moment';

@Component({
  selector: 'indicio-scenario-dialog-manage-tab',
  templateUrl: './scenario-tab.manage.component.html',
  styleUrls: ['./scenario-tab.manage.component.less']
})
export class ScenarioManageTabComponent implements OnChanges {
  public removeIcon: IconProp = faTrashCan;
  public addIcon: IconProp = faPlus;
  public undoIcon: IconProp = faRotateLeft;

  @Input() scenario: ScenarioDTO;
  @Input() fVersion: ForecastVersionModel;
  @Input() availableModels: string[];
  @Output() inputEvent: EventEmitter<true> = new EventEmitter<true>();
  @Output() issuesEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isLoading: boolean = true;
  public scrollPosition: number = 0;

  public dates: Date[] = [];
  public selectedVariableIndex: number[] = [0];
  public selectedGraphs: { Line: ALGLineModel, Historic: ALGLineModel, Options: AlgOptions.Options; }[] = [];
  public variablesLeft: number = 0;
  public oldForecastVariablesFound: string[] = [];
  public noResultsFound: boolean = undefined;
  private historicValues: ForecastVersionHistoricDataModel;
  public tooManySelections: boolean;

  public chartStyles: AlgChartStyles = {
    borderSize: 2, borderRadius: 2, historyLine: '#6388D0',
    colorScheme: 'dark',
    plotHeight: 210
  };

  public get selectedVariables() {
    return this.scenario._tempVariables.filter((x, i) => this.selectedVariableIndex.includes(i));
  }

  public get issuesFound() { return !!this.oldForecastVariablesFound.length || this.noResultsFound === true; }

  constructor(
    private dialog: DialogService,
    private fvarService: ForecastVariableFrontendService,
    private mapper: ScenarioMapper,
    private numberPipe: NumberPipe,
    private multiService: MultivariateFrontendService,
    private dialogService: DialogService,
    private displayService: DisplayFrontendService,
    private multivariateService: MultivariateFrontendService
  ) { }

  public ngOnChanges(_changes: SimpleChanges) {
    this.setup();
  }

  public getVariableIndex(variable: ScenarioVariableDTO) {
    return this.scenario._tempVariables.findIndex(x => x.Item.ForecastVariableId === variable.ForecastVariableId);
  }

  public addForecastedValuesForSelectedVariables() {
    this.selectedVariables.forEach(this.addForecastedValuesForVariable.bind(this));
    // this.generateGraphs();
  }

  public addForecastedValuesForVariable(variable: ItemState<ScenarioVariableDTO>) {
    const values = this.getForecastedValues(variable.Item.ForecastVariableId);
    variable.Item.Values = values.map(x => x.V);
    this.inputEvent.emit(true);
  }

  public addHistoricValuesForSelectedVariables() {
    this.selectedVariables.forEach(this.addHistoricValuesForVariable.bind(this));
    // this.generateGraphs();
  }

  public addHistoricValuesForVariable(variable: ItemState<ScenarioVariableDTO>) {
    const fvar = this.findFVarById(variable.Item.ForecastVariableId);
    variable.Item.Values = fvar.FutureValues.map(x => x.V);
    this.inputEvent.emit(true);
  }

  public resetVariableValues(variable: ItemState<ScenarioVariableDTO>) {
    variable.restore();
    // this.generateGraphs();
  }

  public removeVariable(variable: ItemState<ScenarioVariableDTO>) {
    const fn = () => {
      this.scenario._tempVariables = this.scenario._tempVariables.filter(x => x.Item.ForecastVariableId !== variable.Item.ForecastVariableId);
      this.variablesLeft = this.addableVariables().length;

      // Remove from selected variables
      const activeIndex = this.getVariableIndex(variable.Item);
      this.selectedVariableIndex = this.selectedVariableIndex.filter(x => x !== activeIndex);
      this.checkIssues();
      this.inputEvent.emit(true);
    };
    this.dialogService.openConfirmDialog({
      Title: 'Remove variable',
      Message: 'Are you sure you want to remove this variable from the scenario?',
      ConfirmText: 'Remove',
      CancelText: 'Cancel',
      Style: 'warn'
    }).toPromise().then((ans) => {
      if (ans) {
        fn();
      }
    });
  }

  public handlePaste(variableIndex: number, valueIndex: number, content: string) {
    const valueRows = content.split(/[\n|\r\n]/).map(x => x.trim()).filter(x => x).map(x => x.split('\t').map(y => this.numberPipe.transform(y))).slice(0, this.fVersion.Horizon - valueIndex);
    if (!valueRows.length) { return; }
    const variablesInPaste = valueRows[0].length;
    if (variablesInPaste > this.selectedVariables.length) {
      // TODO: Show error?
    }

    // Handle single variable paste
    if (variablesInPaste === 1) {
      if (valueRows.length === 1) {
        const value = valueRows[0][0];
        this.setValueOnVariable(this.selectedVariables[0].Item, valueIndex, value);
      } else {
        const variable = this.scenario._tempVariables[variableIndex];
        variable.Item.Values.splice(valueIndex, this.fVersion.Horizon - valueIndex, ...valueRows.map(x => x[0]));
      }
      // Handle multiple variable paste
    } else {
      if (this.selectedVariableIndex.length === 1) {
        this.selectedVariables[0].Item.Values.splice(valueIndex, this.fVersion.Horizon - valueIndex, ...valueRows.map(x => x[0]));
      }
      else {
        this.selectedVariableIndex.slice(variableIndex).forEach((varIndex, i) => {
          if (i >= variablesInPaste) { return; }
          const variable = this.scenario._tempVariables[varIndex];
          variable.Item.Values.splice(valueIndex, this.fVersion.Horizon - valueIndex, ...valueRows.map(x => x[i]));
        });
      }
    }
    // this.generateGraphs();
  }

  public setValueOnVariable(variable: ScenarioVariableDTO, valueIndex: number, value: number) {
    variable.Values[valueIndex] = value;
    // this.generateGraphs();
    if (this.noResultsFound) {
      this.noResultsFound = false;
      this.issuesEvent.emit(this.issuesFound);
    }
  }

  public getIndexOfSelectedVariable(variable: ScenarioVariableDTO) {
    return this.scenario._tempVariables.findIndex(x => x.Item.ForecastVariableId === variable.ForecastVariableId);
  }

  public toggleSelectedVariable(index: number, event: MouseEvent) {
    if ((this.selectedVariableIndex.length === 1 && this.selectedVariableIndex.includes(index)) || (this.selectedVariableIndex.length >= 5 && !this.selectedVariableIndex.includes(index))) {
      setTimeout(() => { event.target['checked'] = false; }, 150);
      this.tooManySelections = true;
      event.stopPropagation();
      return;
    }
    this.selectedVariableIndex.togglePresence(index);
    // Sort by index
    this.selectedVariableIndex.sort((a, b) => a - b);
    this.tooManySelections = false;
    // this.generateGraphs();
    event.stopPropagation();
  }

  public setActiveVariable(index: number) {
    this.selectedVariableIndex = [index];
    // this.generateGraphs();
  }

  public addVariable() {
    return this.openVariableDialog().then((fvar) => {
      if (!fvar) { return null; }
      fvar.forEach(fvar => {
        const scenarioVar = this.getSVarFromFVar(fvar);
        this.setDates(fvar);
        const newIndex = this.scenario._tempVariables.push(scenarioVar) - 1;
        if (this.selectedVariableIndex.length < 5) {
          this.selectedVariableIndex.push(newIndex);
        }
        this.variablesLeft = this.addableVariables().length;
        // this.generateGraphs();
        return scenarioVar;
      });
      this.inputEvent.emit(true);
    });
  }

  private generateGraphs() {
    this.selectedGraphs = this.selectedVariables.map((variable) => {
      // Get historic line
      const history = this.historicValues.Data.find(x => x.ForecastVariableId === variable.Item.ForecastVariableId).Values.filter(x => x.m.isBefore(this.dates[0]));
      const historicLine = AlgConverter.fromSpeedHistoricValues(history as PlotValueDTO[], 'historic', 'data');
      historicLine.IsHistoric = true;
      const values = <PlotValueDTO[]> [history.last(), ...variable.Item.Values.map((v, i) => <PlotValueDTO> ({ D: this.dates[i], m: moment(this.dates[i]), V: v, IF: true }))];
      const scenarioVariableLine = AlgConverter.fromSpeedHistoricValues(values as PlotValueDTO[], variable.Item.Name, 'data');
      scenarioVariableLine.IsHistoric = false;
      scenarioVariableLine.Name = variable.Item.Name;
      const options = this.getGraphOptions([...history as PlotValueDTO[], ...values]);
      return { Line: scenarioVariableLine, Historic: historicLine, Options: options };
    });
  }

  private getGraphOptions(values: PlotValueDTO[]) {
    const opts = AlgOptions.CreateOptions({
      noDataText: 'No data selected',
      inModal: true,
      summary: true,
      menuConfig: {
        showMenu: false
      },
      axisConfig: {
        yAxisPosition: 'inside',
        yAxisLine: StrokeSetups.AxisLineDashed,
        dontShowXAxis: this.selectedVariableIndex.length > 1
      },
      updateGraph: false,
      showAssessments: false,
      dontShowCIHover: false,
      isPercent: false,
      rocEnabled: false,
      rocYYEnabled: false,
      dates: values.map(x => x.m),
      pointsToShow: Math.min(values.length, 21),
      showOnlyHistoric: true,
      forceOriginalData: true
    });
    return opts;
  }

  private setDates(fvar: ForecastVariableModel) {
    if (!this.dates.length) {
      this.dates = fvar.FutureValues.map(x => x.D);
    }
  }

  private async setup() {
    this.selectedVariableIndex = this.scenario.ScenarioVariables.map((v, i) => i).slice(0, 5);
    this.variablesLeft = this.addableVariables().length;
    if (this.scenario._tempVariables.length) {
      this.setDates(this.fVersion.getAllVariables()[0]);
    }

    this.historicValues = await this.multiService.getOrFetchHistoricDataById(this.fVersion.ForecastVersionId);
    // this.generateGraphs();
    this.checkIssues();
    this.isLoading = false;
  }

  private findFVarById(id: string) {
    return this.fVersion.getAllVariables().find(x => x.ForecastVariableId === id);
  }

  private checkIssues() {
    this.oldForecastVariablesFound = this.scenario._tempVariables
      .filter(y => !this.getForecastVariables().map(x => x.ForecastVariableId).includes(y.Item.ForecastVariableId))
      .map(x => x.Item.ForecastVariableId);

    if (this.scenario.ScenarioId) {
      this.noResultsFound = this.scenario.Triggered && this.scenario.FinalResults.length === 0;
    }

    this.issuesEvent.emit(this.issuesFound);
  }

  private openVariableDialog() {
    const variables = this.addableVariables();
    const displayValues = variables.map(fvar => new DisplayValue<string>(fvar.ForecastVariableId, fvar.Name, fvar.Name));
    const ref = this.dialog.openDropdownInputDialog({
      Title: 'Add a forecast variable to scenario',
      Label: 'Select variable',
      Values: ArrayUtils.sortArrayAlphabetically(displayValues, 'Display'),
      MultiSelect: true,
      Text: 'Select variables to add to scenario',
      CancelText: 'Cancel',
      ConfirmText: 'Add',
    });

    return ref.toPromise()
      .then((ans: DisplayValue<string> | DisplayValue<string>[]) => {
        if (!ans) { return null; }
        if (!Array.isArray(ans)) { ans = [ans]; }
        const fvars = ans.map((a) => this.fvarService.getOrFetchById(this.fVersion.ForecastVersionId, a.Value));
        return Promise.all(fvars);
      })
      .then((fvar) => {
        if (!fvar) { return null; }
        return fvar;
      });
  }

  private getForecastVariables() {
    return this.fVersion.getAllVariables().filter(x => !x.IsExogenous && !x.IsMixedFreq && x.Active);
  }

  private addableVariables() {
    const currentVariables = this.scenario._tempVariables.map(y => y.Item.ForecastVariableId);
    const variables = this.getForecastVariables().filter(x => !currentVariables.includes(x.ForecastVariableId));
    return variables;
  }

  private getSVarFromFVar(fvar: ForecastVariableModel) {
    const svarTemp = this.mapper.mapFCVarToScenarioVar(fvar);
    const svarState = new ItemState<ScenarioVariableDTO>().set(svarTemp);
    return svarState;
  }

  private multivariateData() {
    const hiddenMulti = this.displayService.settings.Multivariate;
    if (!this.multivariateService.builtModels.length) { return []; }

    // Get all models that are available and not hidden
    const models = this.multivariateService.builtModels
      .filter(x => this.availableModels.includes(x.ModelName) && !hiddenMulti.includes(x.ModelName))
      .map(m => StatModelUtils.cloneStatModel(m));

    return models.map(m => m.Transforms).flat().filter(x => x.ModelBuilt);
  }

  private getForecastedValues(variableId: string) {
    const d = this.multivariateData();
    const summary = SummaryUtils.summarizeAndConsolidateModels({
      modelName: { ...EmptyModelName(), Display: 'Weighted', Value: 'weighted' },
      activeVariableIds: this.getForecastVariables().map(x => x.ForecastVariableId),
      dates: this.fVersion.ForecastVariable.FutureValues.map(x => ({ D: x.D, m: x.m })),
      data: d,
      forecastedCount: this.dates.length,
      exponent: 4,
      // Maybe we should use the same method as on summary.
      weightInfo: { Measure: 'RMSE', Method: 'Stepwise' },
      name: 'Weighted',
      periodicity: this.fVersion.Periodicity,
      desc: 'Data after combining the selected models into a single forecast',
      setPastForecast: false,
      variableId: variableId,
      color: 'fff',
    });
    return summary.Values.filter(x => x.I50 || x.A50);
  }
}
