import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearStateAction } from '../auth/auth.actions';
import { CompanyActions } from '../company/company.actions';
import { GetForecastVersionSuccessAction } from '../forecast/forecast.actions';
import { ForecastVersionHistoricDataModel } from './dtos/stat-model-historic-data.dto';
import {
  GetMultivariateModelPlotImagesSuccess, GetMultivariateModelSuccessAction, GetMultivariateModelTextFilesSuccess, GetUnivariateModelPlotImagesSuccess, GetUnivariateModelSuccessAction, GetUnivariateModelTextFilesSuccess, MultivariateActions, RemoveMultivariateModelAction,
  RemoveMultivariateResultsAction,
  RemoveUnivariateModelAction,
  RemoveUnivariateResultsAction
} from './stat-model.actions';
import { StatModel } from './stat-model.model';


export class StatModelStateModel {
  Univariate: StatModel[];
  Multivariate: StatModel[];
  HistoricData: ForecastVersionHistoricDataModel[];
}

@State<StatModelStateModel>({
  name: 'statModelState',
  defaults: {
    Univariate: [],
    Multivariate: [],
    HistoricData: []
  }
})
@Injectable()
export class StatModelState {

  constructor() { }

  //
  //
  // MULTIVARIATE
  //
  //
  @Selector()
  static univariateModels(state: StatModelStateModel) { return state.Univariate; }

  @Selector()
  static historicData(state: StatModelStateModel) { return state.HistoricData; }

  @Selector()
  static multivariateModels(state: StatModelStateModel) { return state.Multivariate; }

  @Action(GetMultivariateModelSuccessAction)
  getMultivariateModelSuccessAction(ctx: StateContext<StatModelStateModel>, action: GetMultivariateModelSuccessAction) {
    const models = ctx.getState().Multivariate;
    models.addOrUpdate(action.model);
    ctx.patchState({ Multivariate: [...models] });
  }

  @Action(GetMultivariateModelPlotImagesSuccess)
  getMultivariateModelPlotImagesSuccess(ctx: StateContext<StatModelStateModel>, action: GetMultivariateModelPlotImagesSuccess) {
    const models = ctx.getState().Multivariate;
    const possibleUpdates = models.filter(m => m.ForecastVersionId === action.forecastVersionId).map(x => x.Transforms).flatten();
    action.dtos.forEach(dto => {
      const transform = possibleUpdates.find(x => x.MainModelId === dto.ModelId);
      transform.ImagesDTO = dto;
    });
    const cpy = models.map(x => x);
    ctx.patchState({ Multivariate: cpy });
  }

  @Action(MultivariateActions.GetHistoricDataSuccess)
  getHistoricDataSuccess(ctx: StateContext<StatModelStateModel>, action: MultivariateActions.GetHistoricDataSuccess) {
    const models = ctx.getState().HistoricData;
    const idx = models.findIndex(x => x.ForecastVersionId === action.forecastVersionId);
    if (idx === -1) {
      models.push(action.data);
    } else {
      models[idx] = action.data;
    }
    ctx.patchState({ HistoricData: [...models] });
  }

  @Action(MultivariateActions.ClearHistoricData)
  clearHistoricData(ctx: StateContext<StatModelStateModel>, action: MultivariateActions.ClearHistoricData) {
    const models = ctx.getState().HistoricData;
    models.removeById(action.forecastVersionId);
    ctx.patchState({ HistoricData: [...models] });
  }


  @Action(GetForecastVersionSuccessAction)
  getForecastVersionSuccessAction(ctx: StateContext<StatModelStateModel>, action: GetForecastVersionSuccessAction) {
    this.clearHistoricData(ctx, { forecastVersionId: action.model.ForecastVersionId });
  }

  @Action(GetMultivariateModelTextFilesSuccess)
  getMultivariateModelTextFilesSuccess(ctx: StateContext<StatModelStateModel>, action: GetMultivariateModelTextFilesSuccess) {
    const models = ctx.getState().Multivariate;
    const possibleUpdates = models.filter(m => m.ForecastVersionId === action.forecastVersionId).map(x => x.Transforms).flatten();
    action.dtos.forEach(dto => {
      const transform = possibleUpdates.find(x => x.MainModelId === dto.ModelId);
      transform.TextFilesDTO = dto;
    });
    const cpy = models.map(x => x);
    ctx.patchState({ Multivariate: cpy });
  }

  @Action(RemoveMultivariateModelAction)
  removeMultivariateModel(ctx: StateContext<StatModelStateModel>, action: RemoveMultivariateModelAction) {
    const models = ctx.getState().Multivariate;
    const keep = models
      .filter(x => x.ForecastVersionId !== action.forecastVersionId ||
        (x.ForecastVersionId === action.forecastVersionId && x.ModelName !== action.modelName.Model));
    ctx.patchState({ Multivariate: keep });
  }

  @Action(RemoveMultivariateResultsAction)
  removeMultivariateResultFromForecastVersion(ctx: StateContext<StatModelStateModel>, action: RemoveMultivariateResultsAction) {
    const models = ctx.getState().Multivariate;
    const keep = models.filter(x => x.ForecastVersionId !== action.forecastVersionId);
    ctx.patchState({ Multivariate: keep });
  }

  //
  //
  // UNIVARIATE
  //
  //
  @Action(GetUnivariateModelSuccessAction)
  getUnivariateModelSuccess(ctx: StateContext<StatModelStateModel>, action: GetUnivariateModelSuccessAction) {
    const models = ctx.getState().Univariate;
    models.addOrUpdate(action.model);
    ctx.patchState({ Univariate: [...models] });
  }

  @Action(GetUnivariateModelPlotImagesSuccess)
  getUnivariateModelPlotImagesSuccess(ctx: StateContext<StatModelStateModel>, action: GetUnivariateModelPlotImagesSuccess) {
    const models = ctx.getState().Univariate;
    const possibleUpdates = models.filter(m => m.ForecastVersionId === action.forecastVersionId).map(x => x.Transforms).flatten();
    action.dtos.forEach(dto => {
      const transform = possibleUpdates.find(x => x.MainModelId === dto.ModelId);
      transform.ImagesDTO = dto;
    });
    const cpy = models.map(x => x);
    ctx.patchState({ Univariate: cpy });
  }

  @Action(GetUnivariateModelTextFilesSuccess)
  getUnivariateModelTextFilesSuccess(ctx: StateContext<StatModelStateModel>, action: GetUnivariateModelTextFilesSuccess) {
    const models = ctx.getState().Univariate;
    const possibleUpdates = models.filter(m => m.ForecastVersionId === action.forecastVersionId).map(x => x.Transforms).flatten();
    action.dtos.forEach(dto => {
      const transform = possibleUpdates.find(x => x.MainModelId === dto.ModelId);
      transform.TextFilesDTO = dto;
    });
    const cpy = models.map(x => x);
    ctx.patchState({ Univariate: cpy });
  }

  @Action(RemoveUnivariateModelAction)
  removeUnivariateModel(ctx: StateContext<StatModelStateModel>, action: RemoveUnivariateModelAction) {
    const models = ctx.getState().Univariate;
    const keep = models.filter(x =>
      x.ForecastVersionId !== action.forecastVersionId ||
      (x.ForecastVersionId === action.forecastVersionId && x.ModelName !== action.modelName));
    ctx.patchState({ Univariate: keep });
  }

  @Action(RemoveUnivariateResultsAction)
  removeUnivariateResultFromForecastVersion(ctx: StateContext<StatModelStateModel>, action: RemoveUnivariateResultsAction) {
    const models = ctx.getState().Univariate;
    const keep = models.filter(x => x.ForecastVersionId !== action.forecastVersionId);
    ctx.patchState({ Univariate: keep });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<StatModelStateModel>) {
    ctx.patchState({ Univariate: [], Multivariate: [], HistoricData: [] });
  }
}
