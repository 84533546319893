import { Injectable } from '@angular/core';
import { DataProviderProxy } from '@core/actions/navigation.actions';
import { RemoteDataRequestDTO } from '@core/store/providers/dtos/remote-data-request-dto';
import { RemoteDataSourceDTO } from '@core/store/providers/dtos/remote-data-source.dto';
import { NewRemoteDataSourceModelModel } from '@core/store/providers/models/new-remote-data-source-model.model';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { DateUtils } from '@shared/utils/date.utils';


@Injectable({
  providedIn: 'root'
})
export class RemoteDataMapper {

  public toRequestDto(model: RemoteDataRequestModel) {
    return Object.faMapTo(new RemoteDataRequestDTO(), model);
  }

  public toNewDataSource(object: any) {
    return Object.faMapTo(new NewRemoteDataSourceModelModel(), object);
  }

  public toNewDataSourceDto(model: NewRemoteDataSourceModelModel) {
    return Object.faMapTo(new NewRemoteDataSourceModelModel(), model);
  }

  public toRemoteDataSourceDetailModel(dto: RemoteDataSourceDTO) {
    const model = Object.faMapTo(new RemoteDataSourceModel(), dto);
    model.Provider = DataProviderProxy.get(dto.Provider.toLowerCase());
    if (dto.SyncedAt) {
      model.LastAccessed = DateUtils.newNullableDate(dto.SyncedAt);
    }
    if (dto.ModifiedDate) {
      model.ModifiedDate = DateUtils.newNullableDate(dto.ModifiedDate);
    }
    return model;
  }
}
