<h1 mat-dialog-title>Create new user</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-xs-6">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input type="email"
               class="material"
               matInput
               autocomplete="off"
               [(ngModel)]="data.Dto.Email"
               indicioAutoFocusInput
               name="Email"
               max=15
               min=1
               require>
      </mat-form-field>
    </div>
    <div class="col-xs-6">
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input type="password"
               class="material"
               matInput
               autocomplete="off"
               [(ngModel)]="data.Dto.Password"
               name="password"
               max=15
               min=1
               require>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6">
      <mat-form-field>
        <mat-label>First name</mat-label>
        <input type="text"
               class="material"
               matInput
               autocomplete="off"
               [(ngModel)]="data.Dto.Firstname"
               name="firstname"
               max=15
               min=1
               require>
      </mat-form-field>
    </div>
    <div class="col-xs-6">
      <mat-form-field>
        <mat-label>Last name</mat-label>
        <input type="text"
               class="material"
               matInput
               autocomplete="off"
               [(ngModel)]="data.Dto.Lastname"
               name="lastname"
               max=15
               min=1
               require>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6">
      <mat-form-field>
        <mat-label>Company</mat-label>
        <mat-select [(value)]="data.Dto.CompanyId"
                    required>
          <mat-option [value]="c.CompanyId"
                      [title]="c.Name"
                      *ngFor="let c of data.Companies">{{ c.Name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xs-6">
      <mat-form-field>
        <mat-label>Role</mat-label>
        <mat-select [(value)]="data.Dto.CompanyRole"
                    required>
          <mat-option [value]="c.Value"
                      [title]="c.Description"
                      *ngFor="let c of envService.CompanyRoles">{{ c.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row center-vertical">
    <div class="col-xs-6">
      <mat-form-field>
        <mat-label>Country</mat-label>
        <mat-select [(value)]="data.Dto.Country"
                    required>
          <mat-option [value]="c.Value"
                      [title]="c.Description"
                      *ngFor="let c of envService.Countries">{{ c.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xs-6">
      Test user:
      <mat-slide-toggle color="accent"
                        [(ngModel)]="data.Dto.IsTest"
                        [checked]="data.Dto.IsTest">
      </mat-slide-toggle>
    </div>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
  <button mat-flat-button
          (click)="save()">
    Create
  </button>
</div>
