<h1 mat-dialog-title>Shapley additive explanations
  <span class="subtitle">{{ data.Subtitle }}</span>
</h1>


<div mat-dialog-content
     style="margin-top:10px">
  <div style="width: 100%; overflow-x: auto;">
    <indicio-data-table style="display: block; width: fit-content"
                        [columnTemplate]="'95px repeat('+ data.ShapValues.length +', minmax(95px, calc(100% / '+ data.ShapValues.length +'))'">
      <div title-row
           style="padding: 0 1px">
        <div column
             left>
          <span title>Dates</span>
        </div>
        <div *ngFor="let item of data.ShapValues"
             column
             center
             class="crop-string">
          <span [title]="item.Name">
            {{ item.Name }}
          </span>
        </div>
      </div>
      <div style="max-height: 500px; overflow-y: auto;">
        <ng-container *ngFor="let date of dates; let index = index">
          <div row>
            <div column
                 class="crop-string">
              <span>{{ date | dateFormat }}</span>
            </div>
            <ng-container *ngFor="let x of data.ShapValues">
              <div column
                   center>
                <span>{{ x.Values[index].V | propertyValue }}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </indicio-data-table>
  </div>

</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-stroked-button
          (click)="close()">
    Cancel
  </button>
</div>
