import { Injectable, OnDestroy } from '@angular/core';
import { HttpStatusCodes } from '@core/constants/http-status-codes.constants';
import { ActionService } from '@core/services/actions/actions.service';
import {
  GetAllForecastVariablesSuccessAction,
  GetForecastVariableSuccessAction,
  RemoveForecastVariableSuccessAction,
  VariableActions
} from '@core/store/forecast-variable/forecast-variable.actions';
import { ForecastVariableBackendService } from '@core/store/forecast-variable/forecast-variable.backend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { ALGTypes } from '@shared/components/line-graph/alg-types';
import { Subscription } from 'rxjs';
import { ForecastVersionDateRangeDTO } from '../forecast/dtos/forecast-version/forecast-version-meta-dto';
import { ForecastActions } from '../forecast/forecast.actions';
import { ForecastState } from '../forecast/forecast.state';
import { ForecastVersionModel } from '../forecast/models/forecast-version.model';
import { SourceVariableModel } from '../source-variable/source-variable.model';
import { RemoveMultivariateResultsAction } from '../stat-model/stat-model.actions';
import { VarSelectActions } from '../var-select/var-select.actions';
import { CreateForecastVariableDTO } from './dtos/create-forecast-variable-dto';
import { VariableMissingModelSettingsDTO } from './dtos/variable-missing-settings-dto';
import { VariableOutlierSettingsDTO } from './dtos/variable-outlier-settings-dto';
import { VariableSeasonalSettingsDTO } from './dtos/variable-seasonal-settings-dto';
import { ForecastVariableMapper } from './forecast-variable-mapper';
import { ForecastVariableModel } from './models/forecast-variable-model';
import { EForecastVariableStatus } from '@core/store/forecast-variable/models/forecast-variable-meta.model';



@Injectable({
  providedIn: 'root'
})
export class ForecastVariableFrontendService implements OnDestroy {

  subs = new Subscription();

  // GETTERS
  public get forecastVariables() { return this.store.selectSnapshot(ForecastState.forecastVariables) || []; }
  public getVariablesWithNowcastId(nowcastId: string) { return this.forecastVariables.filter(x => x.Nowcast && x.Nowcast.ForecastId === nowcastId); }
  public forecastVariableById(id: string) { return this.forecastVariables.find(x => x.ForecastVariableId === id); }
  // OBSERVABLES

  constructor(
    private store: Store,
    private actions: ActionService,
    private backend: ForecastVariableBackendService,
    private forecastService: ForecastFrontendService,
    private forecastVariableMapper: ForecastVariableMapper
  ) {
    this.addServiceActionListeners();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public getOrFetchById(forecastVersionId: string, forecastVariableId: string, checkNeedFetch: boolean = true) {
    const f = this.forecastVariableById(forecastVariableId);
    if (!f || (f.needFetch() && checkNeedFetch)) {
      return this.fetchById(forecastVersionId, forecastVariableId);
    } else {
      return Promise.resolve(f);
    }
  }

  public getOrFetchByIds(forecastVersionId: string, forecastVariableIds: string[], checkNeedFetch: boolean = true) {
    return forecastVariableIds.map(id => this.getOrFetchById(forecastVersionId, id, checkNeedFetch));
  }

  public fetchById(forecastVersionId: string, forecastVariableId: string) {
    return this.backend.getForecastVariableById(forecastVersionId, forecastVariableId)
      .then(variable => {
        this.store.dispatch(new GetForecastVariableSuccessAction(variable));
        return variable;
      })
      .catch(err => {
        if (err.status === HttpStatusCodes.NOT_FOUND) {
          this.removeForecastVariable(forecastVersionId, forecastVariableId);
          this.syncAllInForecastVersion(forecastVersionId);
        }
        throw err;
      });
  }

  public checkErrorsAndCreateForecastVariable(variable: SourceVariableModel, fversion: ForecastVersionModel) {
    const errors = this.checkErrorsBetweenSourceVariableAndForecast(variable, fversion);
    if (errors.length) {
      return Promise.reject(errors);
    } else {
      return this.createForecastVariable(this.forecastVariableMapper.mapFromSourceVariableModel(variable, fversion.ForecastVersionId));
    }
  }

  public checkErrorsBetweenSourceVariableAndForecast(variable: SourceVariableModel, fversion: ForecastVersionModel) {
    const errors = [];
    if (fversion.IndicatorVariables.map(x => x.Name).includes(variable.Name)) {
      errors.push({
        type: 'name',
        message: `A variable with name (${variable.Name}) already exists in the forecast version`
      });
    }
    if (variable.Periodicity !== fversion.Periodicity) {
      errors.push({
        type: 'periodicity',
        message: `Variable ${variable.Name} periodicity is different from forecast version periodicity`
      });
    }
    return errors;
  }

  public createForecastVariable(variable: ForecastVariableModel) {
    const dto = this.forecastVariableMapper.toCreateDto(variable);
    return this.createForecastVariableByDto(variable.ForecastVersionId, dto);
  }

  public createForecastVariableByDto(fVersionId: string, dto: CreateForecastVariableDTO) {
    return this.backend.createForecastVariable(fVersionId, dto)
      .then(created => {
        this.store.dispatch(new ForecastActions.CreatedForecastVariable(created.fVariable.ForecastVersionId, created.fVariable.ForecastVariableId, created.fVariable.SourceVariableId));
        this.store.dispatch(new ForecastActions.GetDateInfoSuccess(created.DateInfoDTO));
        this.store.dispatch(new GetForecastVariableSuccessAction(created.fVariable));
        this.store.dispatch(new VariableActions.CreatedForecastVariable(created.fVariable.ForecastVariableId));
        this.store.dispatch(new VarSelectActions.RemoveResult(created.fVariable.ForecastVersionId));
        this.store.dispatch(new RemoveMultivariateResultsAction(created.fVariable.ForecastVersionId));

        if (!created.fVariable.IsIndicator) {
          this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
          this.store.dispatch(new ForecastActions.AddedMainVariable(
            created.fVariable.ForecastId, created.fVariable.ForecastVersionId,
            created.fVariable.ForecastVariableId, created.fVariable.SourceVariableId
          ));
        }
        return created.fVariable;
      });
  }

  public updateForecastVariableSettings(variable: ForecastVariableModel) {
    return this.backend.updateVariableSettings(variable)
      .then(updated => {
        this.store.dispatch(new GetForecastVariableSuccessAction(updated));
        return updated;
      });
  }

  public updateTransformSettings(variableId: string, view: ALGTypes.Graph, transform: ALGTypes.Transform) {
    const fVariable = this.forecastVariableById(variableId);
    if (!fVariable) { return; }
    switch (view) {
      case 'multivariate':
        fVariable.MultivariateTransform = transform;
        break;
      case 'scenario':
        fVariable.ScenarioTransform = transform;
        break;
    }
    return this.backend.updateVariableSettings(fVariable)
      .then(updated => {
        this.store.dispatch(new GetForecastVariableSuccessAction(updated));
        return updated;
      });
  }

  public updateVariableMissingValueSettings(variable: ForecastVariableModel, newSettings: VariableMissingModelSettingsDTO) {
    return this.backend.updateVariableMissingValueSettings(variable, newSettings)
      .then(updated => {
        this.store.dispatch(new GetForecastVariableSuccessAction(updated));
        return updated;
      });
  }

  public updateVariableSeasonalSettings(variable: ForecastVariableModel, newSettings: VariableSeasonalSettingsDTO) {
    return this.backend.updateVariableSeasonalSettings(variable, newSettings)
      .then(updated => {
        this.store.dispatch(new GetForecastVariableSuccessAction(updated));
        return updated;
      });
  }

  public updateVariableOutlierSettings(variable: ForecastVariableModel, newSettings: VariableOutlierSettingsDTO) {
    return this.backend.updateVariableOutlierSettings(variable, newSettings)
      .then(updated => {
        this.store.dispatch(new GetForecastVariableSuccessAction(updated));
        return updated;
      });
  }

  public changeSourceMetaIdForFVar(forecastVersionId: string, forecastVariableId: string, newSourceVariableMetaId: string) {
    return this.backend.changeSourceVariableVersion(forecastVersionId, forecastVariableId, newSourceVariableMetaId)
      .then(updated => {
        this.store.dispatch(new ForecastActions.GetDateInfoSuccess(updated.DateInfoDTO));
        this.store.dispatch(new GetForecastVariableSuccessAction(updated.fVariable));
        return updated;
      });
  }

  public triggerDataProcessingMany(forecastVersionId: string, variableIds: string[]) {
    return this.backend.triggerDataProcessingMany(forecastVersionId, variableIds);
  }

  public setPriorInfo(_forecastVersionId: string, variables: ForecastVariableModel[]) {
    return this.backend.setPriorsForForecastVariables(variables)
      .then(() => {
        variables.forEach(variable => {
          this.store.dispatch(new GetForecastVariableSuccessAction(variable));
        });
      });
  }

  public syncAllRelatedToNowcast(nowcastId: string) {
    const variables = this.getVariablesWithNowcastId(nowcastId);
    variables.forEach(fv => {
      this.fetchById(fv.ForecastVersionId, fv.ForecastVariableId);
    });
  }

  public setNotFetched(_forecastVersionId: string, variableId: string) {
    const variable = this.forecastVariableById(variableId);
    if (variable) {
      variable.ForecastVariableValues = [];
      this.store.dispatch(new GetForecastVariableSuccessAction(variable));
    }
  }

  public setNotProcessed(_forecastVersionId: string, variableId: string) {
    const variable = this.forecastVariableById(variableId);
    if (variable) {
      variable.Status = EForecastVariableStatus.INITIAL;
      this.store.dispatch(new GetForecastVariableSuccessAction(variable));
    }
  }

  public updateForecastVariableValues(fVersionId: string, fVarId: string) {
    return this.backend.updateVariableValues(fVersionId, fVarId)
      .then(newId => {
        this.store.dispatch(new VariableActions.GetForecastVariable(fVersionId, newId));
      });
  }

  public getMainVariable(forecastVersionId: string) {
    const fversion = this.forecastService.forecastVersions.find(x => x.ForecastVersionId === forecastVersionId);
    if (!fversion) {
      return this.backend.getMainVariable(forecastVersionId)
        .then(res => {
          this.store.dispatch(new GetForecastVariableSuccessAction(res));
          return res;
        });
    } else {
      return this.getOrFetchById(forecastVersionId, fversion.ForecastVariableId);
    }
  }

  public syncAllInForecastVersion(forecastVersionId: string) {
    return this.backend.getAllForecastVariables(forecastVersionId)
      .then(res => {
        this.store.dispatch(new GetAllForecastVariablesSuccessAction(forecastVersionId, res));
        return res;
      });
  }

  public getOrFetchAllInForecastVersionById(fVersionId: string) {
    const fversion = this.forecastService.forecastVersionById(fVersionId);
    if (!fversion) {
      return Promise.reject('ForecastVersion not synced');
    }
    return this.getOrFetchAllInForecastVersion(fversion);
  }

  public getOrFetchAllInForecastVersion(forecastVersion: ForecastVersionModel) {
    const variableIds = [forecastVersion.ForecastVariableId, ...forecastVersion.IndicatorVariableIds];
    const promises: Promise<ForecastVariableModel>[] = [];
    variableIds.forEach(id => {
      promises.push(this.getOrFetchById(forecastVersion.ForecastVersionId, id));
    });
    return Promise.all(promises);
  }

  public syncAllInActiveForecasts() {
    const versionIds = this.forecastService.getAllActiveForecasVersions().filter(fv => fv !== null && fv !== undefined).map(fv => fv.ForecastVersionId);
    versionIds.forEach(id => this.syncAllInForecastVersion(id));
  }

  public swapVariable(forecastVersionId: string, variableId: string) {
    return this.backend.swapVariable(forecastVersionId, variableId)
      .then(dateInfo => {
        dateInfo.forceFVersionUpdate = true;
        this.store.dispatch(new ForecastActions.GetDateInfoSuccess(dateInfo));
      });
  }

  public canSwapVariable(forecastVersionId: string, variableId: string) {
    return this.backend.canSwapVariable(forecastVersionId, variableId);
  }

  public deleteForecastVariable(variableId: string, forecastVersionId: string = null) {
    let fVerId = forecastVersionId;
    const fvarId = variableId;
    if (forecastVersionId === null) {
      const variable = this.forecastVariableById(variableId);
      if (!variable) { return; }
      fVerId = variable.ForecastVersionId;
    }

    return this.backend.deleteForecastVariable(fVerId, fvarId)
      .then((dateInfo) => this.removeForecastVariable(fVerId, fvarId, dateInfo))
      .then(() => true);
  }

  public removeForecastVariable(fversionId: string, fvariableId: string, dateInfo?: ForecastVersionDateRangeDTO) {
    const variable = this.forecastVariableById(fvariableId);
    if (!variable) { return; }

    this.store.dispatch(new ForecastActions.GetDateInfoSuccess(dateInfo));
    this.store.dispatch(new RemoveForecastVariableSuccessAction(fversionId, fvariableId));
    this.store.dispatch(new ForecastActions.FetchCanCalc(fversionId));

    if (!variable.IsIndicator) {
      this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
    }

    if (!!variable.mfTwin) {
      this.store.dispatch(new RemoveForecastVariableSuccessAction(fversionId, variable.mfTwin.ForecastVariableId));
    }
  }

  private addServiceActionListeners() {
    this.subs.add(
      this.actions.dispatched(VariableActions.GetForecastVariable)
        .subscribe((action: VariableActions.GetForecastVariable) => {
          this.fetchById(action.forecastVersionId, action.forecastVariableId);
        }));

    this.subs.add(
      this.actions.dispatched(GetForecastVariableSuccessAction)
        .subscribe((action: GetForecastVariableSuccessAction) => {
          if (action.variable.NowcastExists && action.variable.Nowcast === null) {
            this.getOrFetchById(action.variable.ForecastVersionId, action.variable.ForecastVariableId);
          }
        }));

    this.subs.add(
      this.actions.dispatched(VariableActions.SaveTransformation)
        .subscribe((action: VariableActions.SaveTransformation) => {
          this.updateTransformSettings(action.forecastVariableId, action.view, action.transform);
        }));
  }
}


