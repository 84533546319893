import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { NewsBackendService } from './news.backend.service';
import { NewsPostModel } from '@core/store/news/news-post.model';
import {
  GetNewsPostSuccessAction,
  GetNewsPostsSuccessAction,
  RemoveNewsPostSuccessAction,
  AddNewsCommentSuccessAction,
  AddNewsCommentReplySuccessAction,
  RemoveNewsCommentSuccessAction,
  RemoveNewsCommentReplySuccessAction,
  EmptyNewsStoreSuccessAction
} from './news.actions';
import { CreateNewsPostDTO } from '@core/store/news/dtos/create-news-post.dto';
import { UpdateNewsPostDTO } from '@core/store/news/dtos/update-news-post.dto';
import { CreateNewsCommentDTO } from '@core/store/news/dtos/create-news-comment.dto';
import { NewsCommentModel } from '@core/store/news/news-comment.model';
import { UpdateNewsCommentDTO } from '@core/store/news/dtos/update-news-comment.dto';
import { NewsState } from './news.state';

@Injectable({
  providedIn: 'root'
})
export class NewsFrontendService {

  public get posts() { return this.store.selectSnapshot(NewsState.posts); }
  public get posts$() { return this.store.select(NewsState.posts); }
  public getNewsPostById(postId) { return this.posts.find(c => c.Meta.NewsPostId === postId); }

  constructor(
    private store: Store,
    private backend: NewsBackendService
  ) {
  }

  public getOrFetchPost(postId: string): Promise<NewsPostModel> {
    const post = this.getNewsPostById(postId);
    if (!post || post.needFetch()) {
      return this.fetchNewsPost(postId);
    }
    return Promise.resolve(post);
  }

  public createNewsPost(post: CreateNewsPostDTO) {
    return this.backend.createNewsPost(post).then(() => {
      if (post.PublishDate) { return; }
      this.reFetchNewsPosts();
    });
  }

  public updateNewsPost(post: UpdateNewsPostDTO) {
    return this.backend.updateNewsPost(post).then(newPost => {
      this.store.dispatch(new GetNewsPostSuccessAction(newPost));
    });
  }

  public removeNewsPost(post: NewsPostModel) {
    return this.backend.deleteNewsPost(post).then(() => {
      this.store.dispatch(new RemoveNewsPostSuccessAction(post));
      this.fetchNewsPosts();
    });
  }

  public addComment(comment: CreateNewsCommentDTO) {
    return this.backend.createComment(comment)
      .then(nComment => {
        this.store.dispatch(new AddNewsCommentSuccessAction(nComment));
      });
  }

  public updateComment(comment: UpdateNewsCommentDTO) {
    return this.backend.updateComment(comment);
  }

  public removeComment(comment: NewsCommentModel) {
    return this.backend.deleteComment(comment.NewsCommentId)
      .then(() => this.store.dispatch(new RemoveNewsCommentSuccessAction(comment)));
  }

  public replyComment(comment: NewsCommentModel, reply: CreateNewsCommentDTO) {
    return this.backend.replyComment(comment.NewsCommentId, reply)
      .then(nComment => {
        this.store.dispatch(new AddNewsCommentReplySuccessAction(comment, nComment));
      });
  }

  public removeCommentReply(reply: NewsCommentModel, comment: NewsCommentModel) {
    return this.backend.deleteComment(reply.NewsCommentId)
      .then(() => this.store.dispatch(new RemoveNewsCommentReplySuccessAction(comment, reply)));
  }

  public reFetchNewsPosts() {
    this.store.dispatch(new EmptyNewsStoreSuccessAction());
    return this.fetchNewsPosts();
  }

  public fetchNewsPosts(skip = 0, take = 5) {
    return this.backend.getNewsPosts(skip, take)
      .then(list => {
        this.store.dispatch(new GetNewsPostsSuccessAction(list.NewsPosts));
        return list;
      });
  }

  private fetchNewsPost(postId: string) {
    return this.backend.getNewsPost(postId)
      .then(post => {
        this.store.dispatch(new GetNewsPostSuccessAction(post));
        return post;
      });
  }
}
