<indicio-dialog>
  <h1 mat-dialog-title>Filters</h1>
  <div mat-dialog-content>
    <ng-container *ngFor="let filter of filters">
      <div *ngIf="filter.type === 'boolean'"
           style="height: 32px; margin-top: 10px">
        <section>
          <mat-checkbox #checkbox="matCheckbox"
                        [(ngModel)]="filter.value">{{ filter.label }}</mat-checkbox>
        </section>
      </div>

      <div *ngIf="filter.type === 'regex' || filter.type === 'text'">
        <mat-form-field style="margin-bottom: 35px">
          <mat-label>{{ filter.label }}</mat-label>
          <input type="text"
                 class="material"
                 matInput
                 autocomplete="off"
                 [(ngModel)]="filter.value"
                 [name]="filter.key"
                 (keyup.enter)="save()">
          <span *ngIf="filter.value"
                matSuffix
                aria-label="Clear"
                (click)="filter.value=''">
            <i class="ion-close cursor-pointer"></i>
          </span>
          <mat-hint *ngIf="filter.type === 'regex'">Separate multiple values with a space.
            Wrap a value containing multiple words within quotes.
            Use a - before a value to exclude it.
          </mat-hint>
        </mat-form-field>
      </div>

      <div *ngIf="(filter.type === 'dropdown' || filter.type === 'dropdown-match') && filter.values.length > 1"
           style="margin-top: 10px">
        <form>
          <mat-form-field>
            <mat-label>{{ filter.label }}</mat-label>
            <mat-select [multiple]="filter.multiple"
                        [(ngModel)]="filter.value"
                        [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let pFilter of filter.values | orderBy: 'display'"
                          [value]="pFilter.value">
                {{ pFilter.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <div *ngIf="filter.type === 'date'"
           style="margin-top: 25px">
        <indicio-date-picker periodicity="day"
                             [value]="filter.value"
                             [min]="disableUntil"
                             (selectDateEvent)="onDateChanged(filter, $event)"
                             [max]="disableSince"
                             [placeholder]="filter.label">
        </indicio-date-picker>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Cancel</button>
    <button mat-button
            [mat-dialog-close]="filters"
            cdkFocusInitial>Filter</button>
  </div>
</indicio-dialog>
