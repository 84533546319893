import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { CloseMainMenuAction, NavigateToForecast } from '@core/actions/navigation.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { ForecastActions } from '@core/store/forecast/forecast.actions';
import { AutomationDialogService } from '@dialogs/automation/automation-dialogs.service';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlusSquare, faSquareCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { HierarchyActions } from '../../constants/hierarchy.actions';
import { HForecastTreeNode } from '../../entities/models/hierarchical-forecast.tree-node';
import { HierarchyModel } from '../../entities/models/hierarchy.model';
import { HierarchicalForecastFrontendService } from '../../services/hierachical-forecast.frontend.service';
import { HierarchyUtils } from '../../utils/hierarchy.utils';

@Component({
  selector: 'indicio-hierarchical-tree',
  templateUrl: './hierarchical-tree.component.html',
  styleUrls: ['./hierarchical-tree.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HierarchicalTreeComponent {

  // Font-awesome icons
  public faTriangle = faTriangleExclamation as IconProp;
  public faCheck = faSquareCheck as IconProp;
  public faPlus = faPlusSquare as IconProp;

  @Output() addNewEvent = new EventEmitter<HForecastTreeNode>();
  @Output() editNodeEvent = new EventEmitter<HForecastTreeNode>();

  // Status fields
  public loadingContent: boolean = true;
  public scrollPosition: number = 0;

  // Getters
  public get isAdmin$() { return this.authService.isAdmin$; }
  public get company() { return this.companyService.activeCompany; }
  public get treeNodes() { return this.hFcastService.treeNodes; }

  constructor(
    public env: EnvironmentService,
    private status: StatusService,
    private store: Store,
    private companyService: CompanyFrontendService,
    private hFcastService: HierarchicalForecastFrontendService,
    private cd: ChangeDetectorRef,
    private actions: ActionService,
    private navService: NavigationService,
    private dialogService: DialogService,
    private variableDialogs: VariableDialogService,
    private automationDialogs: AutomationDialogService,
    private authService: AuthFrontendService
  ) {
    this.hFcastService.getOrSetupTree()
      .catch(err => this.status.setError(err))
      .finally(() => {
        this.loadingContent = false;
        this.cd.detectChanges();
      });

    this.actions.dispatched(HierarchyActions.TreeGenerated).subscribe(() => this.cd.detectChanges());
    this.actions.dispatched(ForecastActions.AddedMainVariable).subscribe(action => this.mainVarAddedSomewhere(action));
  }

  public asTreeNode(e: any): HForecastTreeNode {
    return e as HForecastTreeNode;
  }

  public shouldBeVisible(node: HForecastTreeNode) {
    return node.shouldBeVisible();
  }

  public treeScrolled($event) {
    this.scrollPosition = $event.target.scrollTop;
  }

  public toggleHierarchyMenu(node: HForecastTreeNode, evt: { open: boolean; event: PointerEvent; }) {
    node.menuOpen = evt.open;
    evt.event?.stopPropagation();
  }

  public editHierarchy(hierarchy: HierarchyModel) {
    this.handlePromise(this.hFcastService.update(hierarchy), 'Hierarchy updated');
  }

  public handleRelationErrors(node: HForecastTreeNode) {
    const onlyMissingMainVar = (node) => node.missingMainVariable && !node.missingForecast && !!node.errors?.length && !!node.warnings?.length;
    switch (true) {
      case onlyMissingMainVar(node):
        return this.store.dispatch(new NavigateToForecast(node.Relation.ForecastId));
      case node.missingForecast:
        return this.editRelation(node);
      case !!node.errors?.length:
      case !!node.warnings?.length:
        return this.showInfoDialog(node);
    }
  }

  public editRelation(node: HForecastTreeNode, navigateToForecast = false) {
    if (navigateToForecast && !node.missingForecast) {
      return this.navToForecast(node);
    }
    this.handlePromise(this.hFcastService.updateRelation(node.Relation), 'Relation updated');
  }

  public deleteHierarchy(hierarchy: HierarchyModel) {
    this.handlePromise(this.hFcastService.remove(hierarchy), 'Hierarchy removed');
  }

  public deleteRelation(node: HForecastTreeNode) {
    this.handlePromise(this.hFcastService.removeRelation(node), 'Relation removed');
  }

  public addNewChild(node: HForecastTreeNode) {
    this.handlePromise(this.hFcastService.createRelation(node.Relation), 'New relation created');
  }

  public addNewReconciliation(node: HForecastTreeNode) {
    this.handlePromise(this.hFcastService.createReconciliation(node.Hierarchy), 'Reconciliation created');
  }

  public navToForecast(node: HForecastTreeNode) {
    this.store.dispatch(new NavigateToForecast(node.Relation.ForecastId, 'overview'));
  }

  public navToReconciliation(node: HForecastTreeNode) {
    this.navService.navToUrlWithQuery(`/reports/${node.Hierarchy.HierarchyId}`, { reconciliation: true });
    this.store.dispatch(new CloseMainMenuAction);
  }

  public editReconciliation(node: HForecastTreeNode) {
    this.hFcastService.updateReconciliation(node.Hierarchy, node.Hierarchy.Reconciliations[0])
      .then(updated => {
        if (!updated) { return; }
        this.status.setMessage('Reconciliation updated', 'Success');
      })
      .catch(err => this.status.setError(err));
  }

  public deleteReconciliation(node: HForecastTreeNode) {
    this.hFcastService.removeReconciliation(node.Hierarchy.HierarchyId, node.Hierarchy.Reconciliations[0].HierarchyReconciliationId)
      .then(removed => {
        if (!removed) { return; }
        this.status.setMessage('Reconciliation removed', 'Success');
      })
      .catch(err => this.status.setError(err));
  }

  private inActive(nodes: HForecastTreeNode[]) {
    nodes.forEach(n => {
      n.isActive = false;
      if (n.children?.length) {
        this.inActive(n.children);
      }
    });
  }

  public clickedNode(node: HForecastTreeNode) {
    if (node == null) { return; }
    this.treeNodes.filter(x => !!x).forEach(treeNode => {
      treeNode.isActive = false;
      if (treeNode.children?.length) {
        this.inActive(treeNode.children);
      }
    });
    if (node.foldOnClick()) {
      node.isOpen = !node.isOpen;
    }
    node.isActive = node.isOpen;
  }


  public openRefreshDialog(node: HForecastTreeNode) {
    this.variableDialogs.openFVarUpdateDialog({
      type: node.topNode ? 'hierarchy' : 'forecast',
      ObjectId: node.topNode ? node.Hierarchy.HierarchyId : node.Relation.ForecastId,
      ObjectName: node.Relation.ForecastName,
      AutoStart: false
    });
  }

  public openSyncDataDialog(node: HForecastTreeNode) {
    this.variableDialogs.openSyncDataDialog({
      type: node.topNode ? 'hierarchy' : 'forecast',
      ObjectId: node.topNode ? node.Hierarchy.HierarchyId : node.Relation.ForecastId,
      ObjectName: node.Relation.ForecastName,
    });
  }

  public openCalcResultsDialog(node: HForecastTreeNode) {
    this.automationDialogs.openCalcResultsDialog({
      type: node.topNode ? 'hierarchy' : 'forecast',
      ObjectId: node.topNode ? node.Hierarchy.HierarchyId : node.Relation.ForecastId,
      ObjectName: node.Relation.ForecastName,
    });
  }

  public isActive(node: HForecastTreeNode) {
    return node && node.isActive;
  }

  /**
   * Function that acts as the handler for all promises for this component (Hierarchy-tree-component).
   * Will print the supplied message as a status message with the "success" flag. If the promise is rejected,
   * the function will print the error given.
   * If the promise is resolved, will trigger emit of the refreshEvent event.
   * @param p The promise to handle
   * @param msg The message to print when the promise resolves
   * @returns A void promise
   */
  private handlePromise(p: Promise<boolean>, msg: string) {
    return p
      .then(removed => {
        if (!removed) { return; }
        this.status.setMessage(msg, 'Success');
      })
      .catch(err => this.status.setError(err));
  }

  private mainVarAddedSomewhere(action: ForecastActions.AddedMainVariable) {
    const allNodes = this.treeNodes.map(x => HierarchyUtils.flattenTree(x)).flatten();
    const affectedNodes = allNodes.filter(x => x.Relation.ForecastId === action.forecastId);
    if (affectedNodes.length) {
      affectedNodes.forEach(node => {
        node.Relation.SourceVariableId = action.sourceVarId;
        node.missingMainVar = false;
      });
    }
    this.cd.detectChanges();
  }

  private showInfoDialog(node: HForecastTreeNode) {

    const title = `Relation ${node.errors.length ? 'errors' : 'warnings'}`;
    const list = node.errors.length ? node.errors : node.warnings;

    this.dialogService.openInfoMessageDialog({
      Title: title,
      Content: [...list]
    });
  }
}
