import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { MatSelectChange } from '@angular/material/select';
import { NavigateToForecast } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { MoveForecastDTO } from '@core/store/forecast/dtos/forecast/move-forecast-dto';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { MoveForecastModalOpts } from './move-forecast-modal.options';

@Component({
  selector: 'indicio-move-forecast-modal',
  templateUrl: './move-forecast-modal.component.html',
  styleUrls: ['./move-forecast-modal.less']
})
export class MoveForecastModalComponent extends ModalModelComponent {

  moveDto: MoveForecastDTO = null;
  projects: ProjectModel[] = [];
  forecast: ForecastModel = null;
  oldProject: ProjectModel;
  newProjectId: string;
  forecastNameMustChange: boolean;

  constructor(
    protected store: Store,
    private forecastService: ForecastFrontendService,
    private projectService: ProjectFrontendService,
    private clientService: ClientFrontendService,
    public envService: EnvironmentService,
    private statusService: StatusService,
  ) {
    super();
  }

  public setOptions(options: MoveForecastModalOpts) {
    this.moveDto = new MoveForecastDTO();

    const fc = this.forecastService.getOrFetchForecast(options.forecastId);
    const pj = this.projectService.fetchAllProjects(this.clientService.activeCompanyId);
    Promise.all([fc, pj]).then(([fcx, pjx]) => {
      this.forecast = fcx;
      this.oldProject = pjx.find(x => x.ProjectId === this.forecast.ProjectId);
      this.projects = pjx.filter(x => x.ProjectId !== this.oldProject.ProjectId);
      this.moveDto.Name = `${fcx.Name}`;
      this.moveDto.ForecastId = fcx.ForecastId;
      this.moveDto.ProjectId = this.projects[0] ? this.projects[0].ProjectId : null;
      this.checkName();
    }).catch(err => {
      this.statusService.setError(err, true);
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public changeForecastName(newName: string) {
    this.moveDto.Name = newName.trim();
    this.checkName();
  }

  public changeProject(selection: MatSelectChange) {
    this.newProjectId = selection.value;
    this.moveDto.ProjectId = selection.value;
    this.checkName();
  }

  public onSubmit() {
    this.pending = true;
    this.forecastService.moveForecast(this.moveDto)
      .then(moved => {
        this.close();
        this.projectService.fetchProject(this.oldProject.CompanyId, this.oldProject.ProjectId);
        this.projectService.fetchProject(this.oldProject.CompanyId, moved.ProjectId);
        this.statusService.setMessage('Forecast moved', 'Success');
        this.forecastService.fetchForecastVersion(moved.getLatestVersion().ForecastVersionId);
        this.store.dispatch(new NavigateToForecast(moved.ForecastId));
      })
      .catch(error => {
        this.statusService.setError(error, true);
      })
      .finally(() => {
        this.pending = false;
      });
  }

  private checkName() {
    this.projectService.getOrFetchProject(this.clientService.activeCompanyId, this.moveDto.ProjectId).then(project => {
      this.forecastService.forecastNameConflict(this.moveDto.Name, project, this.forecast.ForecastId).then(notOk => {
        if (notOk) {
          this.forecastNameMustChange = true;
        } else {
          this.forecastNameMustChange = false;
        }
      });
    });
  }
}



