import { Periodicity } from '@modules/lang/types/periodicity';

export interface MacrobondImportMultipleDialogData {
  forecastVersionId: string;
  variableIds: string[];
  variableRevisions?: Map<string, string>;
}

export class MBEntityInfo {
  public TimeSeries: MBEntityInfoTimeSeries;
  public Source: MBEntityInfoSource;
  public Release: MBEntityInfoRelease;
}

export class MBEntityInfoTimeSeries {
  public Title: string;
  public Code: string;
  public Periodicity: Periodicity;
  public StartDate: Date;
  public EndDate: Date;
  public Units: string;
  public RevisionHistory: boolean;
  public RevisionStartDate: Date;
  public Description: string;
  public Category: string;
  public Active: boolean;
}

export class MBEntityInfoSource {
  public Title: string;
  public Region: string;
  public InfoUrl: string;
  public CountryOfDomicile: string;
}

export class MBEntityInfoRelease {
  public Title: string;
  public ReleaseDate: Date;
  public NextReleaseDate: Date;
  public InfoLink: string;
  public HistoryLink: string;
  public CalendarLink: string;
  public MethodologyLink: string;
}
