export class HttpStatusCodes {
  /**
   * Informational 1XX
   */
  public static CONTINUE = 100;
  public static SWITCHING_PROTOCOLS = 101;
  public static PROCESSING = 102;
  /**
   * Successful 2XX
   */
  public static OK = 200;
  public static CREATED = 201;
  public static ACCEPTED = 202;
  public static NON_AUTHORITATIVE_INFORMATION = 203;
  public static NO_CONTENT = 204;
  public static RESET_CONTENT = 205;
  public static PARTIAL_CONTENT = 206;
  public static MULTI_STATUS = 207;
  /**
   * Redirection 3XX
   */
  public static MULTIPLE_CHOICES = 300;
  public static MOVED_PERMANENTLY = 301;
  public static MOVED_TEMPORARILY = 302;
  public static SEE_OTHER = 303;
  public static NOT_MODIFIED = 304;
  public static USE_PROXY = 305;
  public static TEMPORARY_REDIRECT = 307;
  public static PERMANENT_REDIRECT = 308;
  /**
   * Client Error 4XX
   */
  public static BAD_REQUEST = 400;
  public static UNAUTHORIZED = 401;
  public static FORBIDDEN = 403;
  public static PROXY_AUTHENTICATION_REQUIRED = 407;
  public static CONFLICT = 409;
  public static GONE = 410;
  public static EXPECTATION_FAILED = 417;
  public static FAILED_DEPENDENCY = 424;
  public static INSUFFICIENT_SPACE_ON_RESOURCE = 419;
  public static NOT_ACCEPTABLE = 406;
  public static NOT_FOUND = 404;
  public static REQUEST_HEADER_FIELDS_TOO_LARGE = 431;
  public static PAYMENT_REQUIRED = 402;
  public static PRECONDITION_FAILED = 412;
  public static PRECONDITION_REQUIRED = 428;
  public static REQUESTED_RANGE_NOT_SATISFIABLE = 416;
  public static LENGTH_REQUIRED = 411;
  public static LOCKED = 423;
  public static METHOD_FAILURE = 420;
  public static METHOD_NOT_ALLOWED = 405;
  public static REQUEST_TIMEOUT = 408;
  public static REQUEST_TOO_LONG = 413;
  public static REQUEST_URI_TOO_LONG = 414;
  public static TOO_MANY_REQUESTS = 429;
  public static UNPROCESSABLE_ENTITY = 422;
  public static UNSUPPORTED_MEDIA_TYPE = 415;
  /**
   * Server Error 5XX
   */
  public static GATEWAY_TIMEOUT = 504;
  public static HTTP_VERSION_NOT_SUPPORTED = 505;
  public static INSUFFICIENT_STORAGE = 507;
  public static INTERNAL_SERVER_ERROR = 500;
  public static NOT_IMPLEMENTED = 501;
  public static NETWORK_AUTHENTICATION_REQUIRED = 511;
  public static SERVICE_UNAVAILABLE = 503;
  public static BAD_GATEWAY = 502;
}
