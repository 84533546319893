import { EmployeeModel } from '@core/store/company/employee.model';

export class CompanyHelper {
  public static userHasRole(userEmail: string, employees: EmployeeModel[], role: string) {
    const e = employees.find(x => x.Email === userEmail);
    if (e) {
      return e.CompanyRole === role;
    } else { return false; }
  }
}
