<indicio-modal-base [child]="this"
                    [modalTitle]="modalTitle"
                    modalClass=""
                    [loading]=isLoading
                    bodyExtraClass="overflow-auto">
  <div *ngIf="!isLoading"
       style="width: 85%; margin: 0 auto;">

    <div class="row">
      <div class="col-xs-12">
        <span class="input-label bold">You are currently working on</span>
        <div class="subtitle">{{currentFile.FileName}}</div>
      </div>
    </div>
    <indicio-modal-option-row textWidth="7"
                              optionsWidth="5"
                              *ngIf="sheetService.parsedSheets.length > 1"
                              [optionTitle]="'Active sheet'"
                              optionSubtitle="Select sheet to import from">
      <mat-form-field style="margin-top:15px">
        <mat-select [value]="currentSheet"
                    (selectionChange)="changeSheet($event.value)">
          <ng-container *ngFor="let sheet of sheetService.parsedSheets">
            <mat-option *ngIf="{ importable: sheetService.isSheetImportable(sheet.SheetIndex) } as info"
                        [value]="sheet.SheetIndex"
                        [indicioTippy]="!info.importable ? 'Sheet contains no readable data' : null"
                        [disabled]="!info.importable">
              Sheet {{ sheet.SheetIndex + 1 }}: {{ sheet.SheetName }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>
    <indicio-sheet-column-import [forecastVersionId]="forecastVersionId"
                                 [forecastId]="opts.forecastId"
                                 [fileId]="currentFile.getModelId()"
                                 [fileType]="currentFile.SourceType"
                                 [valueColumns]="valueColumns || []"
                                 [sheetLoading]="sheetLoading"
                                 (stateEvent)="setImportState($event)"
                                 (closeEvent)="close()">
    </indicio-sheet-column-import>
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>
</indicio-modal-base>

<ng-template #buttons>
  <div class="row">
    <div class="col-xs-6">
      <button class="btn outline blue"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6">
      <button class="btn pull-right"
              (click)="nextAction()"
              data-test-id="import-file-variables"
              [ngClass]="{ disabled: isDisabled }"
              [indicioTippy]="importState === 'error' ? 'One or more name issues must be resolved' : null">
        <span *ngIf="importState !== 'importing'">{{ nextBtnText }}</span>
        <i class="ion-load-c spinner"
           *ngIf="importState === 'importing'"></i>
      </button>
    </div>
  </div>
</ng-template>
