
import { Injectable } from '@angular/core';
import { GithubIssue } from '@core/entities/github.models';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ErrorReportService } from '../error-report.service';



interface MathJaxConfig {
  source: string;
}

declare global {
  interface Window {
    MathJax: {
      typesetPromise: () => void;
      startup: {
        promise: Promise<any>;
      };
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class MathService {
  private signal: Subject<boolean>;
  private mathJax: MathJaxConfig = {
    source: '/assets/js/mathjax.js'
  };

  constructor(ReportService: ErrorReportService) {
    this.signal = new ReplaySubject<boolean>();
    void this.registerMathJaxAsync(this.mathJax)
      .then(() => this.signal.next())
      .catch((error) => {
        const b = new GithubIssue.FrontendErrorReportDTO();
        b.Stacktrace = error;
        b.Subject = 'Error';
        b.Type = 'Error';
        ReportService.postFrontendIssue(b);
      });
  }

  private async registerMathJaxAsync(config: MathJaxConfig): Promise<any> {
    return new Promise<void>((resolve, reject) => {

      const script: HTMLScriptElement = document.createElement('script');
      script.type = 'text/javascript';
      script.src = config.source;
      script.crossOrigin = 'anonymous';
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => resolve();
      script.onerror = error => reject(error);
    });
  }

  ready(): Observable<boolean> {
    return this.signal;
  }

  render(element: HTMLElement, math: string, preWrap: boolean = true) {
    window.MathJax.startup.promise.then(() => {
      if (preWrap) {
        element.innerHTML = '<p>' + math + '</p>';
        window.MathJax.typesetPromise();
        element.innerHTML = element.innerHTML.replace('<p>', '<pre style="overflow:inherit;margin-top: 0; white-space: pre-wrap;">');
        element.innerHTML = element.innerHTML.replace('</p>', '</pre>');
      } else {
        element.innerHTML = math;
        window.MathJax.typesetPromise();
      }
    });
  }
}
