<indicio-modal-base [child]="this"
                    [hideCloseButton]="hideCloseButton"
                    [onBackdropClose]="hideCloseButton ? onClose : null"
                    modalTitle="Add login for {{ providerName }}"
                    [loading]=isLoading>

  <div *ngIf="!isLoading">
    <span [innerHTML]="helpText | safeHtml"></span>
    <div class="extra-margin-top">
      <form #f="ngForm"
            (ngSubmit)="setKey()">
        <mat-form-field>
          <input type="text"
                 matInput
                 class="material"
                 placeholder="Enter {{ extraOpts?.titles?.username || 'Username' }}"
                 name="Username"
                 [(ngModel)]="newUsername">
        </mat-form-field>
        <mat-form-field>
          <input type="password"
                 matInput
                 class="material"
                 placeholder="Enter {{ extraOpts?.titles?.password || 'Password' }}"
                 name="Password"
                 [(ngModel)]="newPassword">
        </mat-form-field>
        <div class="row">
          <div class="col-xs-6">
            <ng-container *ngIf="!hideCloseButton">
              <button type="button"
                      class="btn btn-confirm outline blue"
                      (click)="close()">
                Cancel
              </button>
            </ng-container>
            <ng-container *ngIf="hideCloseButton">
              <button type="button"
                      class="btn btn-confirm outline blue"
                      (click)="manyCompanies ? openChangeCompanyModal() : logout()">
                {{ manyCompanies ? 'Change company' : 'Logout' }}
              </button>
              <div *ngIf="manyCompanies"
                   class="cursor-pointer underline"
                   (click)="logout()"
                   style="margin-left: 10px; display: inline-block;">
                Logout
              </div>
            </ng-container>
          </div>
          <div class="col-xs-6">
            <button type="submit"
                    class="btn btn-confirm right"
                    [class]="{ disabled: pending }">
              <i *ngIf="pending"
                 class="ion-load-c spinner"></i>
              <span *ngIf="!pending">Save</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</indicio-modal-base>
