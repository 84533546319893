import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { Guid } from '@core/types/guid.type';
import { AssessmentUnit } from '@modules/lang/language-files/assessment';
import * as moment from 'moment';

export class AssessmentModel implements IHasModelId {

  @Def() AssessmentId: Guid;
  @Def() ForecastVersionId: string;

  @Def() TimePoint: Date;
  @Def() NewValue: number;
  @Def() OldValue: number;
  @Def() ClientEmail: string;
  @Def() Quantity = 0;
  @Def() Weight: number;
  @Def() Reason: string;
  @Def() Source: string;
  @Def() Active = true;

  @Def() Unit: string;
  @Def() Category: string;
  @Def() TrustLevel: string;
  @Def() Models: string[] = [];

  moment: moment.Moment;

  // Frontend specific
  Name: string;
  needsSave: boolean;
  creatorName: string;
  assessmentUnit: AssessmentUnit = null;
  assessmentLevel;
  assessmentCategory;
  userCanDeleteOrEdit;

  // Helper functions
  public getModelId(): string {
    return this.AssessmentId;
  }
}
