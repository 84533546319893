import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';
import { StringUtils, explodeStringBySpace } from '@shared/utils/string.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import * as moment from 'moment';


const INVERTED_FILTERS = [
  // acea, bis, fred, oxford, nasdaq
  'oldData',
  // nasdaq
  'premium',
  // ??
  'old',
  // source variables
  'usedInForecast',
  // uploaded files, events
  'Used',
  // macrobond
  'Discontinued',
  // Company admin
  'Disabled'
];

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(itemList: any[], itemKey: string, matchFor: any = '', _trigger?: any, fullMatch?: boolean): any[] {
    if (!itemList) { return []; }
    let momentOperator;

    // Check if the itemKey has a moment operator attached to it (:after, :before)
    if (itemKey.match(':')) {
      const [_itemKey, _momentOperator] = itemKey.split(':');
      itemKey = _itemKey;
      momentOperator = _momentOperator;
    }

    const filteredOutItems = new Set();

    itemList.forEach((x, indexOfItem) => {
      let objectValue;

      // Do a deep search for object value if the key contains a dot
      if (itemKey.match('.')) {
        objectValue = itemKey.split('.').reduce(function (m, n) { return m[n]; }, x);
      } else {
        objectValue = x[itemKey];
      }

      // Set up the objectValue and matchFor for moment comparisons
      if (momentOperator && matchFor) {
        if (!(objectValue instanceof moment)) {
          objectValue = DateUtils.newMoment(objectValue);
        }
        if (!(matchFor instanceof moment)) {
          matchFor = DateUtils.newMoment(matchFor);
        }
      }

      if (objectValue === undefined) { return false; }

      switch (true) {
        // Handle boolean filters
        case typeof matchFor === 'boolean': { !this.filterByBoolean(objectValue, matchFor, itemKey) && filteredOutItems.add(indexOfItem); break; }
        // Handle array filters
        case Array.isArray(matchFor): { !this.filterByArray(objectValue, matchFor, fullMatch) && filteredOutItems.add(indexOfItem); break; }
        // Handle string filters
        case typeof matchFor === 'string' && !!matchFor.length: {
          if (matchFor.includes(' ')) {
            const strings = this.sortExcludes(explodeStringBySpace(matchFor));
            strings.forEach(str => !this.filterByString(objectValue, matchFor) && filteredOutItems.add(indexOfItem));
          } else {
            !this.filterByString(objectValue, matchFor) && filteredOutItems.add(indexOfItem);
          }
          break;
        }
        // Handle regex filters
        case ValueUtils.isRegexValue(matchFor): {
          const stringMatchFor = String(matchFor).match(/\/(.+?)\//)[1];
          if (stringMatchFor.includes(' ')) {
            const strings = this.sortExcludes(explodeStringBySpace(stringMatchFor));
            strings.forEach(str => !this.filterByRegex(objectValue, str) && filteredOutItems.add(indexOfItem));
          } else {
            !this.filterByRegex(objectValue, stringMatchFor) && filteredOutItems.add(indexOfItem);
          }
          break;
        }
        // Handle moment filters
        case moment.isMoment(matchFor): { !this.filterByMomentOperator(objectValue, matchFor, momentOperator) && filteredOutItems.add(indexOfItem); break; }
      }
    });
    return itemList.filter((x, indexOfItem) => !filteredOutItems.has(indexOfItem));
  }

  private filterByBoolean(objectValue: boolean, matchFor: boolean, filter: string) {
    if (INVERTED_FILTERS.includes(filter)) {
      if (objectValue === true && matchFor === true) {
        return false;
      } else {
        return true;
      }
    } else {
      if (objectValue === true && matchFor === false) { return false; }
      return objectValue;
    }
  }

  private filterByArray(objectValue: any, matchFor: any[], fullMatch: boolean) {
    let includeItem: boolean;
    if (fullMatch) {
      let match;
      if (matchFor?.length > 1) { match = matchFor.every(x => objectValue.includes(x)); }
      else if (matchFor?.length === 1) { match = objectValue.some(x => x.toLowerCase() === matchFor[0].toLowerCase()); }
      else { match = true; }
      includeItem = match;
    } else {
      if (matchFor.length === 0) { return true; }
      matchFor.forEach(v => {
        if (includeItem === true) {
          return;
        } else if (ValueUtils.isRegexValue(v)) {
          const match = new RegExp(v, 'gi').test(objectValue);
          includeItem = !!match;
        } else {
          if (Array.isArray(objectValue)) {
            const match = objectValue.find(x => x.toLowerCase() === v.toLowerCase());
            includeItem = !!match;
          } else {
            includeItem = objectValue?.toLowerCase() === v.toLowerCase();
          }
        }
      });
    }
    return includeItem;
  }

  private filterByString(objectValue: string, matchFor: string) {
    let includeItem: boolean;
    if (matchFor.startsWith('-')) {
      includeItem = !objectValue.includes(matchFor.substring(1));
    } else {
      includeItem = objectValue.toLowerCase() === matchFor.toLowerCase();
    }
    return includeItem;
  }

  private filterByRegex(objectValue: string, matchFor: string) {
    const inverse = matchFor.replace(/\\/g, '').startsWith('-');
    const subractIndex = matchFor.indexOf('-');
    const matchForCleaned = inverse ? matchFor.substring(subractIndex + 1) : matchFor;
    const match = new RegExp(StringUtils.escapeRegex(matchForCleaned), 'gi').test(objectValue);

    // If the matchFor is inversed and there is no match, include the item
    if (inverse && !match) { return true; }

    return inverse ? !match : match;
  }

  private filterByMomentOperator(objectValue: moment.Moment, matchFor: moment.Moment, momentOperator: string) {
    if (momentOperator === 'after') {
      return objectValue.isSameOrAfter(matchFor);
    } else if (momentOperator === 'before') {
      return objectValue.isSameOrBefore(matchFor);
    }
  }

  private sortExcludes(strings: string[]): string[] {
    // Put items starting with '-' at the start of the array
    return [...strings.filter(x => x.startsWith('-')), ...strings.filter(x => !x.startsWith('-'))];
  }
}
