import { PlotValue, SeasonalValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { INeedFetch } from '@core/interfaces/if-need-fetch';
import {
  EForecastVariableStatus,
  ForecastVariableMetaModel
} from '@core/store/forecast-variable/models/forecast-variable-meta.model';
import { OutlierInfoModel } from '@core/store/forecast-variable/models/outlier-info.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { RemovedStatus } from '@core/types/removed.status.type';
import { ImportStep } from '@modules/forecast/views/add-data/file-sheet/types/types';
import { VariableTransformation } from '@modules/lang/types/variable-transformation';
import { ValueUtils } from '@shared/utils/value.utils';
import { DiagnosticInfoDTO } from '../dtos/diagnostic-info-dto';

export class BvarSettings {
  ForecastVariableId: string;
  Name: string;
  Transformation: VariableTransformation;
  PriorMean: number;
  PriorVariance: number;
  Changed: boolean;
  Focused: boolean = false;
  LogAllowed: boolean = false;
  ValidVariance: boolean = false;

  public validBVarVariance(variance: number) { return ValueUtils.isNum(variance) && variance > 0; }
  public validSettings(): { canRun: boolean, cantSaveMessage: string; } {
    let canRun = true;
    let cantSaveMessage = '';
    if (!ValueUtils.isNum(this.PriorMean)) {
      canRun = false;
      cantSaveMessage = 'One or more prior mean(s) must be a number';
    } else if (!this.validBVarVariance(this.PriorVariance)) {
      canRun = false;
      cantSaveMessage = 'One or more prior variance(s) must be a positive number';
    } else if (this.Transformation === null) {
      canRun = false;
      cantSaveMessage = 'One or more variables are missing a transformation';
    }
    return { canRun, cantSaveMessage };
  }
}

export class ForecastVariableModel extends ForecastVariableMetaModel implements INeedFetch {
  // Data processing information
  ForecastVariableValues: PlotValue[] = [];
  ForecastVariableSeasonalValues: SeasonalValueDTO[] = [];
  FutureValues: PlotValue[] = [];
  OutlierInfo: OutlierInfoModel[] = [];
  OutlierPlotImages: ResultFileDTO[] = [];
  AggregationPlotImages: ResultFileDTO[] = [];
  MissingValuePlotImages: ResultFileDTO[] = [];
  Nowcast: ForecastModel = null;
  ExogenousFutureValues: number[];
  DiagnosticInfos: DiagnosticInfoDTO[];
  DetectedSeasonalOutliers: DetectedSeasonalOutlierDTO[];

  // R Script stuff
  ErrorMessages: string[] = [];
  InfoMessages: string[] = [];
  SeasonalMessages: string[] = [];
  OutlierMessages: string[] = [];
  WarningMessages: string[] = [];
  BVarSettings: BvarSettings = null;

  std: number;
  maxNumberCount: number = 1;
  isUpdatable: boolean = true;
  status: ImportStep = 'Pending';
  removedStatus: RemovedStatus = 'Pending';
  warningMsg: string;
  errorStatus: string;
  failedMsg: string;
  /** Used to pair an aggregated variable with its base-frequency twin. Frontend specific. */
  mfTwin: ForecastVariableModel = null;

  public get isNowcasting() { return this.Nowcast !== null && this.NowcastExists && this.Nowcast.Results.length === 0; }
  public nowcastFailed() { return this.Nowcast !== null && this.NowcastExists && this.NowcastFailed; }
  public needFetch() { return this.ForecastVariableValues.length === 0; }
  public tagString() { return this.Tags.length === 0 ? 'No tags set' : this.Tags.map(t => t.Name).join(', '); }
  public dataprocessingError() { return this.Status === EForecastVariableStatus.DATAPROCESSING_ERROR; }
  public varSelectError() { return this.Status === EForecastVariableStatus.VARSELECT_ERROR; }
  public variableError() { return this.Status === EForecastVariableStatus.VARIABLE_ERROR; }
  public processed() { return this.Status >= EForecastVariableStatus.PROCESSED; }
  public initial() { return this.Status === EForecastVariableStatus.INITIAL; }
}

export class ForecastVariableAccessModel {
  ForecastVariableId?: string;
  IsOwner: boolean;
  OwnerEmail: string;
  MissingAccessMessage: string;
}

export class DetectedSeasonalOutlierDTO {
  Date: Date;
  Type: string;
}
