import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { KoladaKPIGroupMemberModel } from '@core/store/providers/kolada/kolada-groups.model';
import { KoladaImportModalOpts } from './kolada-import-modal.options';

export class OpenKoladaImportModal {
  public static readonly type = '[MODAL] Open kolada import modal';
  data: KoladaImportModalOpts;
  constructor(
    member: KoladaKPIGroupMemberModel,
    forecast: ForecastVersionModel
  ) {
    this.data = { member, forecast };
  }
}
