import { Def } from '@core/decorators/def';
import { ProjectMemberDTO } from '@core/store/project/dtos/project-member-dto';

export class UpdateProjectDTO {
  @Def() Name: string;
  @Def() OwnerEmail: string;
  @Def() AggregationMethodId: string;
  @Def() VisibilityLevelId: string;
  @Def() Alpha: number;
  @Def() AssessmentWeightLow: number;
  @Def() AssessmentWeightMedium: number;
  @Def() AssessmentWeightHigh: number;
  @Def() ModelThresholdMAPE: number;
  @Def() CalculateOutliers: boolean;
  @Def() CalculateSeasonal: boolean;
  @Def() AutoUpdateVariables: boolean;
  @Def() Members: ProjectMemberDTO[];
  @Def() SeasonalModel: string;
  @Def() SeasonalStrategy: string;
  @Def() SeasonalTrend: string;
  @Def() UseSeasonalTrend: boolean;
  @Def() OutlierModel: string;
  @Def() OutlierStrategy: string;
  @Def() OutlierTypes: string;
  @Def() SeasonalOutliers: boolean;
  @Def() SeasonalCalendarEffects: string;
  @Def() SeasonalOutlierTypes: string;
}
