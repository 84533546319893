import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'orderByDate', pure: false })
export class OrderByDatePipe implements PipeTransform {
  /**
 * if args is prepended with a - sign, reverse ordering is returned
 */
  transform(array: Array<any>, args: string): Array<any> {
    if (typeof args[0] === undefined || array[0] === undefined) {
      return array;
    }

    const direction = args[0][0];
    const column = args.replace('-', '');

    if (!array[0].hasOwnProperty(column)) {
      return array;
    }

    array.sort((a: any, b: any) => {
      const left = Number(a[column]);
      const right = Number(b[column]);
      return (direction === '-') ? right - left : left - right;
    });

    return array;
  }
}
