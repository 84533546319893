import { CSVConstants } from '@core/constants/csv.constants';
import { DateValue } from '@core/interfaces/if-datevalue-filter';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import * as JSZip from 'jszip';
import { DateUtils } from './date.utils';
import { FileUtils } from './file.utils';

export namespace CSVUtils {

  export type VariableValueType = 'V' | 'A' | 'VO' | 'VS';

  export function ConvertForecastVariableValues(variable: ForecastVariableModel, valueField: VariableValueType = 'V', dateFormat = 'YYYY-MM-DD', valueConverter?: any): DateValue[] {
    return variable.ForecastVariableValues.map(v => {
      let value = v[valueField] || v.V;
      const dateValue = v.m.format(dateFormat);

      if (valueConverter) {
        value = valueConverter(value);
      }

      return {
        Date: dateValue, Value: value
      };
    });
  }

  export function ConvertSourceVariableValues(variable: SourceVariableVersionModel, dateFormat = 'YYYY-MM-DD', valueConverter?: any): DateValue[] {
    return variable.PlotValues.map(v => {
      let value = v.V;
      const dateValue = v.m.format(dateFormat);

      if (valueConverter) {
        value = valueConverter(value);
      }

      return {
        Date: dateValue, Value: value
      };
    });
  }

  export function CreateDateValueArray(values: number[], dates: string[]): DateValue[] {
    return dates.map((date, i) => {
      let value = values[i];

      return {
        Date: date, Value: value
      };
    });
  }

  export class ValueConverter {
    constructor(
      public maxValueCount: number,
      public separator: CSVConstants.Separator,
      public vertical: boolean,
      public useZip: boolean
    ) { }

    public Download(files: CSVConstants.CSVFile[], fileName?: string) {
      const date = DateUtils.newMoment().format('YYYY-MM-DD');

      if (this.useZip) {
        const zip = new JSZip();
        files.forEach(x => {
          zip.file(x.Name + '.csv', x.Content);
        });

        zip.generateAsync({ type: 'blob' }).then(content => {
          FileUtils.downloadBlob(content, `${fileName || files.length + '_files'}_${date}.zip`);
        });
      } else {
        const result = this.GetSingleFileContentFromMultipleFiles(files);
        const blob = new Blob([result], { type: 'text/plain;charset=utf-8' });
        FileUtils.downloadBlob(blob, `${fileName}_${date}.csv`);
      }
    }

    GetSingleFileContentFromMultipleFiles(files: CSVConstants.CSVFile[]) {
      let result = '';
      const values = this.MapValuesToDates(files);
      const keys = Object.keys(values.dateArray).sort();
      if (this.vertical) {
        result += `${values.titles.map(x => `"${x}"`).join(this.separator)}\r\n`;
        keys.forEach(key => {
          result += `${key}${this.separator}${values.dateArray[key].join(this.separator)}\r\n`;
        });
      } else {
        result += `Dates${this.separator}${Object.keys(values.dateArray).join(this.separator).replace(`Dates${this.separator}`, '')}\r\n`;
        values.titles.splice(0, 1);
        values.titles.forEach((title, index) => {
          result += `"${title}"${this.separator}`;
          keys.forEach(key => {
            const value = `${values.dateArray[key][index]}` || '';
            result += `${value}${this.separator}`;
          });
          result += '\r\n';
        });
      }
      return result;
    }

    public WriteVertical(values: DateValue[], title: string) {
      const header = `"${title}"\r\n\r\nDates${this.separator}Values`;
      if (values.length + 1 > this.maxValueCount) { this.maxValueCount = values.length + 1; }

      let valueLines;
      valueLines = values.map(value => {
        let line = '';
        line += value.Date + this.separator;
        line += value.Value != null ? value.Value : '';
        return line;
      });

      const fileContent = `${header}\r\n${valueLines.join('\r\n')}`;
      return fileContent;
    }

    public WriteHorizontal(values: DateValue[], title: string) {
      if (values.length + 1 > this.maxValueCount) { this.maxValueCount = values.length + 1; }
      let dates: any = values.map(x => x.Date);
      dates.unshift('Dates');
      dates = dates.join(this.separator);
      let vals: any = values.map(x => x.Value);
      vals.unshift(title);
      vals = vals.join(this.separator);
      return `${dates}\r\n${vals}\r\n`;
    }

    MapValuesToDates(files: CSVConstants.CSVFile[]) {
      const dateArray = {};
      const titles = ['Dates'];
      // Set dates
      files.forEach(file => {
        let rows: any[];
        const titleRemoveReg = new RegExp(`Dates${this.separator}.+\r\n`);
        if (this.vertical) {
          rows = file.Content.split(titleRemoveReg).pop().split('\r\n').map(row => row.split(this.separator));
        } else {
          rows = file.Content.split('\r\n')[0].replace(titleRemoveReg, '').split(this.separator);
        }
        const name = file.Name;
        titles.push(name);
        for (let i = 0; i <= this.maxValueCount; i++) {
          if (rows[i] === undefined) { return; }
          if (!dateArray.hasOwnProperty(rows[i])) {
            if (this.vertical) { dateArray[rows[i][0]] = []; } else { dateArray[rows[i]] = []; }
          }
        }
      });
      // Set values
      files.forEach(file => {
        let rows = [];
        if (this.vertical) {
          rows = file.Content.split('\r\n').map(row => row.split(this.separator));
          rows.splice(0, 1);
        } else {
          // files[file].split('\r\n').forEach(type => {
          //   type = type.split(this.separator); type.splice(0, 1);
          //   type.forEach((value, index) => {
          //     if (!rows[index]) { rows[index] = []; }
          //     rows[index].push(value);
          //   });
          // });
        }
        Object.keys(dateArray).forEach(date => {
          const dateIndex = rows.findIndex(row => row[0] === date);
          const value = rows[dateIndex] !== undefined ? rows[dateIndex][1] : '';
          dateArray[date].push(value);
        });
      });
      return { titles, dateArray };
    }
  }
}
