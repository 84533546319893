import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigateToAddData } from '@core/actions/navigation.actions';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { OpenIdFrontendService } from '@core/services/open-id/open-id.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

export interface ManageOpenIdIntegrationDialogData {
  Source: RemoteDataSourceModel;
  CallbackUrl?: string;
}

@Component({
  selector: 'indicio-manage-openid-integration-dialog',
  templateUrl: 'manage-openid-integration.dialog.html',
})
export class ManageOpenIdIntegrationDialogComponent {
  public static Id: string = 'ManageOpenIdIntegrationDialogComponent';

  connected: boolean;
  connectionLoader: boolean;

  connectUrl: string;
  connectLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<ManageOpenIdIntegrationDialogComponent>,
    public env: EnvironmentService,
    public dialogService: DialogService,
    private openidService: OpenIdFrontendService,
    private clientService: ClientFrontendService,
    private store: Store,
    private authService: AuthFrontendService,
    private forecastService: ForecastFrontendService,
    @Inject(MAT_DIALOG_DATA) public data: ManageOpenIdIntegrationDialogData) {
    this.connectionLoader = true;
    this.checkStates();
  }

  public close() {
    this.dialogRef.close(null);
  }

  public checkStates() {
    return this.openidService.testConnection(this.data.Source.Provider)
      .then(() => this.connected = true)
      .catch(() => this.connected = false)
      .finally(() => this.connectionLoader = false);
  }

  public disconnect() {
    this.dialogService.openConfirmDialog({
      ExtraWarning: 'This action will prevent you from continued use of this data provider and your source variables will not be updated.',
      Message: '',
      Title: `Remove connection to ${this.env.getSourceType(this.data.Source.Provider).Display}`,
      ConfirmText: 'Remove',
      CancelText: 'Cancel',
      Style: 'warn',
      ConfirmFunction: () => { this.openidService.disconnectProvider(this.data.Source.Provider); this.handleConfirm(); },
    }, { width: '400px' });
  }

  public connect() {
    this.connectLoading = true;
    this.openidService.setupOpenIdIntegration(this.data.Source.Provider || DataProvider.macrobondapi)
      .then(url => {
        window.location.href = url;
      })
      .finally(() => this.connectLoading = false);
  }

  public handleConfirm() {
    if (this.data.CallbackUrl) {
      window.open(this.data.CallbackUrl, '_blank');
    }
    switch (this.data.Source.Provider) {
      case DataProvider.macrobondapi:
        if (this.clientService.activeCompany.LicenseType === 'Macrobond') {
          this.authService.logout(false);
        } else {
          this.store.dispatch(new NavigateToAddData(this.forecastService.activeForecastId));
        }
    }
    this.close();
  }
}
