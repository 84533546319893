import { Def } from '@core/decorators/def';

export class OutlierInfoModel {
  @Def() Date: Date;
  @Def() Type: string;
  @Def() Coefhat: number;
  @Def() Tstat: number;
  @Def() OriginalValue: number;
  @Def() OutlierValue: number;
}
