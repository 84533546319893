import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { StatusService } from '@core/services/status/status.service';
import { CompanyMapper } from '@core/store/company/company-mapper';
import { CreateCompanySuccessAction } from '@core/store/company/company.actions';
import { CompanyModel } from '@core/store/company/company.model';
import { SupportService } from '@core/store/support/support.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { Country } from '@modules/lang/types/country';
import { DisplayValue } from '@modules/lang/types/display-value';
import { Subscription } from 'rxjs';
import { SupportDialogService } from '../support-dialogs.service';

export class SupportDialogOptions {
  public companyId?: string;
  public viewIndex?: SupportDialogViewIndex;
}

export enum SupportDialogViewIndex {
  'general' = 0,
  'users' = 1,
  'audit' = 2,
}

@Component({
  selector: 'indicio-main-support-dialog',
  templateUrl: 'main-support.dialog.html',
  styleUrls: ['main-support.dialog.less']
})
export class SupportDialogComponent extends DialogV2BaseDialog<SupportDialogComponent> {

  public static Id: string = 'SupportDialogComponent';

  private sub: Subscription = new Subscription();

  // Options
  public viewIndex: number = 0;
  public viewTypes = SupportDialogViewIndex;

  // Companies
  public activeCompanyId: string;
  public activeCompany: CompanyModel;
  public managedCompanies: DisplayValue<string>[] = [];
  public selectedCompany: DisplayValue<string>;

  // Update properties
  public maxUserCount: number;
  private savedEmployees: string;
  private savedHorizons: string;
  private trialDate: Date;
  private country: Country;
  private maxRollingTime: number;
  public saveActive: boolean;

  public get savable() {
    return this.saveActive && !this.inProgress && this.activeCompany.Trial && this.activeCompany?.TrialExpirationDate > new Date();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: SupportDialogOptions,
    private actions: ActionService,
    dialogRef: MatDialogRef<SupportDialogComponent, any>,
    private statusService: StatusService,
    private supportDialogService: SupportDialogService,
    private service: SupportService,
    private companyMapper: CompanyMapper
  ) {
    super(dialogRef);
    this.initialize();
    this.setupSubscriptions();
  }

  private setupSubscriptions() {
    this.sub.add(this.actions.dispatched(CreateCompanySuccessAction).subscribe(() => {
      this.getManagedCompanies();
    }));
  }

  public changeView(index: number) {
    this.viewIndex = index;
  }

  public setNewCountry(country: Country) {
    this.country = country;
    this.checkNeedSave();
  }

  public setMaxUsers(count: number) {
    this.maxUserCount = count;
    this.checkNeedSave();
  }

  protected async initialize() {
    if (this.data.companyId) { this.activeCompanyId = this.data.companyId; }
    if (this.data.viewIndex) { this.viewIndex = this.data.viewIndex; }

    this.getManagedCompanies();
  }

  public setActiveCompany($event: DisplayValue<string>) {
    this.selectedCompany = $event;
    this.activeCompanyId = $event.Value;

    if (!this.activeCompanyId) {
      this.activeCompany = null;
      return;
    }

    const cPromise = this.service.getCompany(this.activeCompanyId);

    return Promise.all([cPromise])
      .then(([c]) => {
        this.activeCompany = c;
        this.setup();
      })
      .catch(err => this.statusService.setError(err, true))
      .finally(() => {
        this.initialized = true;
      });
  }


  public createCompany() {
    this.supportDialogService.openCreateCompany({});
  }

  public onNoClick(): void {
    if (!this.activeCompanyId) {
      this.dialogRef.close();
    } else {
      this.selectedCompany = null;
      this.activeCompanyId = null;
    }
  }

  public save() {
    this.activeCompany.CountryCode = this.country.Value;
    this.activeCompany.MaxUsers = this.maxUserCount;
    const dto = this.companyMapper.toSupportUpdate(this.activeCompany);
    this.inProgress = true;
    return this.service.updateCompany(this.activeCompany.CompanyId, dto)
      .then(() => this.statusService.setMessage('Company settings saved', 'Success', true))
      .catch(error => this.statusService.setError(error, true))
      .finally(() => {
        this.setup();
        this.inProgress = false;
      });
  }

  public checkNeedSave() {
    const newCountrySet = this.country.Value !== this.activeCompany.Country.Value;
    const newMaxUsersSet = this.maxUserCount !== this.activeCompany.MaxUsers;
    const rolesChanged = this.savedEmployees !== JSON.stringify(this.activeCompany.Employees);
    const trialChanged = this.trialDate !== this.activeCompany.TrialExpirationDate;
    const rollingTimeChanged = this.maxRollingTime !== this.activeCompany.MaxRollingWindowTime;
    const horizonsChanged = this.savedHorizons !== JSON.stringify(this.activeCompany.MaxHorizons);
    this.saveActive = newCountrySet || newMaxUsersSet || rolesChanged || trialChanged || rollingTimeChanged || horizonsChanged;
  }


  private setup() {
    this.country = this.activeCompany.Country;
    this.maxUserCount = this.activeCompany.MaxUsers;
    this.trialDate = this.activeCompany.TrialExpirationDate;
    this.maxRollingTime = this.activeCompany.MaxRollingWindowTime;
    this.savedEmployees = JSON.stringify(this.activeCompany.Employees);
    this.savedHorizons = JSON.stringify(this.activeCompany.MaxHorizons);
    this.checkNeedSave();
    this.inProgress = false;
  }

  private getManagedCompanies() {
    Promise.all([
      this.service.getManagedCompanies()
    ]).then(([managedCompanies]) => {
      this.managedCompanies = managedCompanies;
      const current = this.managedCompanies.find(x => x.Value === this.activeCompanyId);
      if (!!current) {
        return this.setActiveCompany(current);
      }
    }).catch((error) => {
      this.statusService.setError(error, true);
    })
      .finally(() => {
        this.initialized = true;
      });
  }

}
