import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnRowChangeDTO, ConfirmedNewLocationDTO, FileColumnDiffDTO } from '@core/store/file/dtos/variable-update-info-dto';

export interface FileColumnnDialogData {
  Location: ConfirmedNewLocationDTO;
  VariableName: string;
  DateCol: string[];
  ValueCol: string[];
  DisabledRows: number[];
  Diff: FileColumnDiffDTO;
  ForbidDiff: boolean;
}

@Component({
  selector: 'indicio-file-column-dialog',
  templateUrl: 'file-column.dialog.html',
})
export class FileColumnDialogComponent {
  public static Id: string = 'FileColumnDialogComponent';

  public variableName: string;
  public rows: [boolean, string, string, string][] = [];
  public disabledRows: number[] = [];
  public diffs: ColumnRowChangeDTO[] = [];
  public forbidDiff: boolean;
  public above20: boolean = false;
  public above3: boolean = false;
  public offset: number = 0;

  constructor(
    public dialogRef: MatDialogRef<FileColumnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileColumnnDialogData
  ) {
    this.variableName = data.VariableName;
    this.rows = [];
    this.offset = data.Diff.Offset;
    this.diffs = data.Diff.Changes;
    this.above20 = data.Diff.Above20;
    this.above3 = data.Diff.Above3 && !data.Diff.Above20;
    this.forbidDiff = data.ForbidDiff;
    this.disabledRows = data.DisabledRows || [];
    const minLen = Math.min(data.DateCol.length, data.ValueCol.length);
    for (let i = 0; i < minLen; i++) {
      this.rows.push([
        this.disabledRows.some(x => x === i),
        data.DateCol[i],
        data.ValueCol[i],
        this.diffs.find(x => x.Row === i)?.Old
      ]);
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(true);
  }
}
