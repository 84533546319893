import { IndicioPermissionType } from '@core/types/indicio-permission.type';
import { StringUtils } from '@shared/utils/string.utils';

export namespace SecurityUtils {

  export function StrengthChecker(PasswordParameter) {
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');

    if (strongPassword.test(PasswordParameter)) {
      return 2;
    } else if (mediumPassword.test(PasswordParameter)) {
      return 1;
    } else {
      return 0;
    }
  }

  export function checkPass(pwd1: string, pwd2: string): string {
    if (pwd1 !== pwd2) {
      return 'Error: Passwords does not match!';
    }

    if (pwd1.length < 8) {
      return 'Error: Password must contain at least 8 characters!';
    }

    let re = /[0-9]/;
    if (!re.test(pwd1)) {
      return 'Error: password must contain at least one number (0-9)!';
    }

    re = /[a-z]/;
    if (!re.test(pwd1)) {
      return 'Error: password must contain at least one lowercase letter (a-z)!';
    }

    re = /[A-Z]/;
    if (!re.test(pwd1)) {
      return 'Error: password must contain at least one uppercase letter (A-Z)!';
    }

    return null;
  }

  export function generatePassword() {
    // eslint-disable-next-line prefer-const
    const length = 5;
    // eslint-disable-next-line prefer-const
    const charset = 'abcdefghijklmnopqrstuvwxyz';
    const charset1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charset2 = '0123456789';
    const charset3 = '!?*+-';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    for (let i = 0, n = charset1.length; i < length; ++i) {
      retVal += charset1.charAt(Math.floor(Math.random() * n));
    }
    for (let i = 0, n = charset2.length; i < length; ++i) {
      retVal += charset2.charAt(Math.floor(Math.random() * n));
    }
    for (let i = 0, n = charset3.length; i < length; ++i) {
      retVal += charset3.charAt(Math.floor(Math.random() * n));
    }
    return StringUtils.shuffleString(retVal);
  }


  export function checkPermission(
    currentPermissions: false | IndicioPermissionType[],
    permissionsNeeded: IndicioPermissionType | IndicioPermissionType[]
  ) {
    if (currentPermissions === false) {
      return false;
    }

    if (currentPermissions === undefined || currentPermissions.length === 0) {
      return true;
    }

    if (typeof permissionsNeeded === 'string') {
      permissionsNeeded = [permissionsNeeded];
    }

    for (const permission of permissionsNeeded) {
      if (currentPermissions.indexOf(permission) === -1) {
        return false;
      }
    }

    return true;
  }

}
