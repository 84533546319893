
export class ColumnSetAction {
  public static readonly type = '[SHEET-DROPDOWN] Column set';
  constructor(public type: string) { }
}

export class SetDropdownVisibility {
  public static readonly type = '[SHEET-DROPDOWN] Column set visibility';
  constructor(
    public visible: boolean,
    public column: number
  ) { }
}
