import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GithubIssue } from '@core/entities/github.models';
import { ErrorReportService } from '@core/services/error-report.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IndicioHttpContext } from '../services/http/indicio-http-context';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private service: ErrorReportService,
    private profileService: ProfileFrontendService,
    private authService: AuthFrontendService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!this.authService.loggedIn) { return throwError(error); }
        if (<string> error.name === 'TimeoutError') {
          this.postIssue({
            Type: 'Warning',
            Subject: `HttpErrorInterceptor: Timeout occured while requesting ${request.url}`,
            Stacktrace: error.message,
            User: this.profileService.profile?.Email || 'Unknown user',
          });
        }
        if (error.name === 'HttpErrorResponse') {
          const reqInfo = request.context.get(IndicioHttpContext.INFO_KEY);
          const stack = reqInfo === null ? error.message : `${JSON.stringify(reqInfo)}, Stack: ${error.message}`;
          this.postIssue({
            Type: 'Warning',
            Subject: `HttpErrorInterceptor: Error occured while requesting ${request.url}`,
            Stacktrace: stack,
            User: this.profileService.profile?.Email || 'Unknown user',
          });
        }
        return throwError(error);
      })
    ) as Observable<HttpEvent<any>>;
  }

  private postIssue(error: GithubIssue.FrontendErrorReportDTO) {
    this.service.postFrontendIssue(error);
  }
}
