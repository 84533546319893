import { Component, Input } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CreateCompanyDTO, INewUser } from '@core/store/company/dtos/create-company-dto';
import { DisplayValue } from '@modules/lang/types/display-value';

@Component({
  selector: 'indicio-create-company-preview-tab',
  templateUrl: './create-company-tab.preview.component.html',
  styleUrls: ['./create-company-tab.preview.component.less'],
})
export class CreateCompanyTabPreviewComponent {

  @Input() company: CreateCompanyDTO;
  @Input() supportUsers: { role: string, users: DisplayValue[]; }[] = [];


  public get validUsers(): INewUser[] { return this.company.Users.filter(x => x.valid); }
  public get invalidUsers(): INewUser[] { return this.company.Users.filter(x => !x.valid); }
  public get selectedSupportUsers(): DisplayValue[] {
    return this.company.Support.map(x => {
      return this.supportUsers.find(y => y.users.find(z => z.Value === x)).users.find(z => z.Value === x);
    });
  }

  constructor(
    public envService: EnvironmentService,
    public authService: AuthFrontendService
  ) {
  }
}
