import { MultiCreateNowcastModalOpts } from './multi-create-nowcast-modal.options';

export class OpenMultiCreateNowcastModal {
  public static readonly type = '[MODAL] Open multi create nowcast';
  data: MultiCreateNowcastModalOpts;
  constructor(
    variableIds: string[],
    forecastVersionId: string,
    callback?: any
  ) {
    this.data = { forecastVersionId, variableIds, callback };
  }
}
