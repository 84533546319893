import { Injectable } from '@angular/core';
import { PusherActionDTO } from '@core/services/pusher/dtos/pusher-action-dto';
import { Store } from '@ngxs/store';
import { HierarchyActions } from './constants/hierarchy.actions';
import { HierarchyResultEventArgs, ReconciliationRemovedEventArgs } from './entities/dtos/hierarchy.dto';

export type PusherHierarchicalAction =
  'fetch-hierarchy-reconciliation' |
  'removed-hierarchy-reconciliation';

@Injectable({
  providedIn: 'root'
})
export class PusherHierarchicalBehaviourService {

  constructor(
    private store: Store,
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {
    switch (dto.Action as PusherHierarchicalAction) {
      case 'fetch-hierarchy-reconciliation':
        return this.store.dispatch(new HierarchyActions.FetchNewReconciliationResult(dto.Obj as HierarchyResultEventArgs));

      case 'removed-hierarchy-reconciliation':
        const info = dto.Obj as ReconciliationRemovedEventArgs;
        return this.store.dispatch(new HierarchyActions.RemoveReconciliation(info.HierarchyId, info.ReconciliationId));

      default:
        console.error(`Missing behaviour for action: (${dto.Action})`);
        return;
    }
  }
}
