import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgChartStyles, AlgOptions } from '@shared/components/line-graph/alg.options';
import * as moment from 'moment';
import { ChartDownloadModalOpts } from './chart-download/chart-download-modal.options';
import { ForecastDataDownloadModalOpts } from './forecast-data-download/forecast-data-download-modal.options';


/**
 * Actions
 */
export class OpenChartDownloadModal {
  public static readonly type = '[MODAL] Open chart download modal';
  data: ChartDownloadModalOpts;
  constructor(
    forecast: ForecastModel,
    forecastVersion: ForecastVersionModel,
    models: any,
    type: string,
    singleVariable: any,
    userDataSettings: any,
    Lines?: ALGLineModel[],
    HistoricLine?: ALGLineModel,
    periodicity?: PeriodicityType,
    fromDate?: moment.Moment,
    toDate?: moment.Moment,
    chartStyles?: AlgChartStyles,
    options?: AlgOptions.Options,
  ) {
    this.data = { forecast, forecastVersion, models, type, singleVariable, userDataSettings, Lines, HistoricLine, fromDate, toDate, periodicity, chartStyles, options };
  }
}

export class OpenForecastDataDownloadModal {
  public static readonly type = '[MODAL] Open forecast data download modal';
  data: ForecastDataDownloadModalOpts;
  constructor(
    forecastId: string
  ) {
    this.data = { forecastId };
  }
}
