<indicio-dialog>
  <h2 mat-dialog-title
      style="margin-bottom: 10px;">File information
    <div class="crop-string subtitle"
         [title]="file.FileName"
         *ngIf="initialized">
      File: {{ file.FileName }}
    </div>
  </h2>
  <mat-dialog-content>
    <ng-container *ngIf="initialized; else loadingTemplate">
      <ng-container *ngTemplateOutlet="dialogTabNavigationTemplate"></ng-container>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button
            type="button"
            (click)="close()">
      Close
    </button>
    <button mat-raised-button
            [disabled]="!shouldSave || inProgress"
            (click)="save()"
            type="button"
            color="primary">
      <div>
        Save <indicio-spinner *ngIf="inProgress"
                         [size]="14"></indicio-spinner>
      </div>
    </button>
  </mat-dialog-actions>

</indicio-dialog>

<ng-template #loadingTemplate>
  <indicio-spinner [size]="24"></indicio-spinner>
  <br>Loading file information...
</ng-template>

<ng-template #dialogTabNavigationTemplate>
  <mat-tab-group animationDuration="0ms"
                 color="primary"
                 [selectedIndex]="viewIndex"
                 (selectedIndexChange)="changeView($event)"
                 [disablePagination]="true">
    <mat-tab label="General">
      <ng-container *ngIf="viewIndex === viewTypes.general"
                    [ngTemplateOutlet]="generalTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Access and users">
      <ng-container *ngIf="viewIndex === viewTypes.access"
                    [ngTemplateOutlet]="accessTemplate"></ng-container>
    </mat-tab>
    <!-- <mat-tab label="Revisions">
      <ng-container *ngIf="viewIndex === viewTypes.revisions"
                    [ngTemplateOutlet]="revisionsTemplate"></ng-container>
    </mat-tab> -->
  </mat-tab-group>
</ng-template>

<ng-template #generalTemplate>
  <indicio-file-info-dialog-general-tab [file]="file"
                                        (newNameEvent)="onNewName($event)">
  </indicio-file-info-dialog-general-tab>
  <br>
</ng-template>

<ng-template #accessTemplate>
  <indicio-file-info-dialog-access-tab [file]="file"
                                       [companyUsers]="companyEmployees">

  </indicio-file-info-dialog-access-tab>
  <br>
</ng-template>

<ng-template #revisionsTemplate>
  TODO: Migrate revisions here
  <br>
</ng-template>
