<div class="reports-wrapper drawer">
  <div class="text-center button-wrapper reports-title"
       *ngIf="visible"
       data-test-id="reports-title-container">
    <h3>Reports</h3>
  </div>
  <ul class="reports-list menu"
      (scroll)="scroll($event)">
    <li *ngIf="loadingReports"
        class="cursor-default">
      <i class="ion-load-c spinner"></i>
      Fetching reports
    </li>
    <ng-container *ngIf="!loadingReports">
      <li class="no-padding unselectable"
          *ngFor="let report of (reports$ | async) | orderBy: 'Name' : (reports$ | async)?.length"
          [ngClass]="{active: navService.isOnReport(report.ReportId) }"
          [attr.data-project]="report.Name">
        <div class="menu-item"
             (click)="openReport(report)">
          <span class="mini-description">Report</span><br>
          <span class="description inline-block">
            {{ report.Name }}
          </span>
          <span class="date block">{{ report.Periodicity }}</span>
        </div>
        <div class="menu-item-buttons">
          <indicio-options-menu [filler]="true"
                                [containerScrolled]="scrollPosition"
                                (openEvent)="toggleReportMenu(report, $event)"
                                fillerTop="-67%">
            <ul>
              <ng-container>
                <li divider></li>
                <li (click)="downloadReport(report)">
                  <i [ngClass]="!report.downloadPending ? 'ion-android-download' : 'ion-load-c spinner'"></i>
                  <div class="text">Download</div>
                </li>
                <li divider></li>
                <li (click)="removeReport(report)">
                  <i [ngClass]="!report.isPending ? 'ion-trash-b' : 'ion-load-c spinner'"></i>
                  <div class="text">Remove</div>
                </li>
              </ng-container>
            </ul>
          </indicio-options-menu>
        </div>
      </li>
      <ng-container [ngTemplateOutlet]="reconciliationsTemplate"></ng-container>
    </ng-container>
    <li *ngIf="userCanCreateReports"
        class="no-padding unselectable create-report"
        data-onboarding="createReport"
        (click)="createReport()">
      <div class="report-info">
        <i class="ion-plus green"></i>
        <span style="margin-left: 4px">Create report</span>
      </div>
    </li>
  </ul>
</div>

<ng-template #reconciliationsTemplate>
  <li class="no-padding unselectable"
      *ngFor="let reconciliation of reconciliations | orderBy: 'Name' : reconciliations?.length"
      [ngClass]="{active: navService.isOnReport(reconciliation.HierarchyId) }"
      [attr.data-project]="reconciliation.Name">
    <div class="menu-item"
         (click)="openReconciliation(reconciliation)">
      <span class="mini-description">Reconciliation</span><br>
      <span class="description inline-block">
        {{ reconciliation.Name }}
      </span>
      <span class="date block">{{ reconciliation.Periodicity }}</span>
    </div>
    <div class="menu-item-buttons">
      <indicio-options-menu [filler]="true"
                            [containerScrolled]="scrollPosition"
                            (openEvent)="toggleReconciliationMenu(reconciliation, $event)"
                            fillerTop="-67%">
        <ul>
          <ng-container>
            <li divider></li>
            <!-- <li (click)="downloadReport(reconciliation)">
              <i [ngClass]="!reconciliation.downloadPending ? 'ion-android-download' : 'ion-load-c spinner'"></i>
              <div class="text">Download</div>
            </li>
            <li divider></li> -->
            <li (click)="removeReconciliation(reconciliation)">
              <i [ngClass]="!reconciliation.isPending ? 'ion-trash-b' : 'ion-load-c spinner'"></i>
              <div class="text">Remove</div>
            </li>
          </ng-container>
        </ul>
      </indicio-options-menu>
    </div>
  </li>
</ng-template>
