import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { StatusService } from '@core/services/status/status.service';
import { GetForecastVariableSuccessAction } from '@core/store/forecast-variable/forecast-variable.actions';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';


export enum FVarDialogViewIndex {
  'general' = 0,
  'disaggregation' = 1,
  'missing' = 2,
  'outliers' = 3,
  'seasonal' = 4,
  'advanced' = 5
}

export class ForecastVariableInfoDialogData {
  forecastId: string;
  forecastVersionId: string;
  forecastVariableId: string;
  view: FVarDialogViewIndex = 0;
}

@Component({
  selector: 'indicio-fvar-info-dialog',
  templateUrl: './forecast-variable-info.dialog.html',
  styleUrls: ['./forecast-variable-info.dialog.less']
})
export class FVarInfoDialogComponent extends DialogV2BaseDialog<FVarInfoDialogComponent> {

  // This dialog shows information and settings for a forecast variable.
  public static Id: string = 'ForecastVariableInfoDialogComponent';

  // Entities
  public variable: ForecastVariableModel;
  public sourceVariable: SourceVariableModel;
  public forecast: ForecastModel;
  public forecastVersion: ForecastVersionModel;

  // Options
  public viewIndex: number;
  public viewTypes = FVarDialogViewIndex;
  public hasAccessToSource: boolean = false;

  // Getters
  public get fVersionId() { return this.opts.forecastVersionId; }
  public get forecastId() { return this.opts.forecastId; }
  public get fVariableId() { return this.opts.forecastVariableId; }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public opts: ForecastVariableInfoDialogData,
    private actions: ActionService,
    private fVarService: ForecastVariableFrontendService,
    private sVarService: SourceVariableFrontendService,
    private status: StatusService,
    private forecastService: ForecastFrontendService,
    private cd: ChangeDetectorRef,
    dialogRef: MatDialogRef<FVarInfoDialogComponent>,
  ) {
    super(dialogRef);

    this.initialize();
  }

  public changeView(index: number) {
    this.viewIndex = index;
    this.cd.detectChanges();
  }

  //
  // Private functions below
  //

  protected initialize() {
    const fPromise = this.forecastService.getOrFetchForecast(this.forecastId);
    const fvPromise = this.forecastService.getOrFetchForecastVersion(this.fVersionId);
    const vPromise = this.fVarService.getOrFetchById(this.fVersionId, this.fVariableId);

    Promise.all([fPromise, fvPromise, vPromise])
      .then(([f, fv, v]) => {
        this.forecast = f;
        this.forecastVersion = fv;
        this.setupSubscriptions();
        this.viewIndex = this.opts.view;
        return this.setupVariable(v);
      })
      .then(() => this.initialized = true)
      .catch(err => {
        this.status.setError(err);
        this.close();
      });
  }

  /**
   * Once a variable is set, load related entitites and setup the graph and tab-information.
   */
  public async setupVariable(variable: ForecastVariableModel) {
    this.variable = variable;
    return this.sVarService.getOrFetchSourceVariable(this.variable.SourceVariableId)
      .then(sv => {
        this.sourceVariable = sv;
        this.hasAccessToSource = true;
      })
      .catch(err => { /* We do nothing here, user does not have access to the source, but that is not a bug. */ });
  }

  /**
   * Add listeners for relevant actions here
   * Note: .subscription is handled in the base class and does not need to be unsubscribed from here.
   */
  private setupSubscriptions() {
    this.subsription.add(
      this.actions.dispatched(GetForecastVariableSuccessAction)
        .subscribe((action: GetForecastVariableSuccessAction) => {
          if (action.variable.ForecastVariableValues.length === 0 || action.variable.ForecastVariableId !== this.fVariableId) { return; }
          this.setupVariable(action.variable);
        }));
  }
}
