import { d3Utils } from '@shared/utils/d3/d3.utils';
import * as d3 from 'd3';

import { SvgObjects } from './create-svg-objects';
import { SvgSizes } from './svg-sizes';

export class UpdateSvgInput {
  SVGObjects: SvgObjects;
  IsMiniAlg: boolean;
  Sizes: SvgSizes;
  MaxX: Date;
  MinX: Date;
  MaxY: number;
  MinY: number;
  DateSelection: boolean;
}

export class UpdateSvgOutput {
  SVGObjects: SvgObjects;
  Sizes: SvgSizes;
}

export function updateSvgObjects(input: UpdateSvgInput): UpdateSvgOutput {
  const result = new UpdateSvgOutput();
  const sizes = input.Sizes;

  if (input.IsMiniAlg && sizes.ContainerHeight > 210) {
    sizes.ContainerHeight = sizes.ContainerHeight / 2;
  } else if (!input.IsMiniAlg && sizes.ContainerHeight < 200) {
    sizes.ContainerHeight = 420;
  }

  input.SVGObjects.SVG.attr('viewBox', '0 0 ' + sizes.ContainerWidth + ' ' + sizes.ContainerHeight);

  input.SVGObjects.SVGGroup.select('.chart-background')
    .attr('width', sizes.getGraphWidth())
    .attr('height', sizes.getGraphHeight());

  input.SVGObjects.ClipPath
    .attr('width', sizes.getGraphWidth())
    .attr('height', sizes.getGraphHeight());

  result.SVGObjects = input.SVGObjects;
  result.Sizes = input.Sizes;
  result.SVGObjects.XScale = d3.scaleTime().range([0, sizes.getGraphWidth()]).domain([input.MinX, input.MaxX]);
  result.SVGObjects.YScale = d3.scaleLinear().range([sizes.getGraphHeight(), 0]).domain([input.MinY, input.MaxY]);

  // TODO: Might need refactoring
  if (input.DateSelection) {
    result.SVGObjects.Border = d3Utils.addRectElement(result.SVGObjects.SVG, { Class: 'border', X: sizes.getGraphX(), Y: sizes.getGraphY() })
      .attr('height', sizes.getBorderHeight())
      .attr('width', sizes.getBorderWidth())
      .attr('stroke', `var(--indicio-alg-border-color, ${result.SVGObjects.Colors.ChartBorderColor}`)
      .attr('stroke-width', sizes.ChartBorderSize)
      .attr('fill', 'transparent');

    result.SVGObjects.HoverTarget = d3Utils.addGroup(result.SVGObjects.SVG, { Class: 'hover-target', X: sizes.getGraphX(), Y: sizes.getGraphY() })
      .attr('height', sizes.getBorderHeight())
      .attr('width', sizes.getBorderWidth());
  } else {
    result.SVGObjects.HoverTarget = d3Utils.addRectElement(result.SVGObjects.SVG, { Class: 'hover-target', X: sizes.getGraphX(), Y: sizes.getGraphY() })
      .attr('height', sizes.getBorderHeight())
      .attr('width', sizes.getBorderWidth())
      .attr('stroke', `var(--indicio-alg-border-color, ${result.SVGObjects.Colors.ChartBorderColor}`)
      .attr('stroke-width', sizes.ChartBorderSize)
      .attr('fill', 'transparent');
  }

  return result;
}
