import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AlphaStockModalOpts } from './alpha-stock-modal.options';

export class OpenAlphaStockModal {
  public static readonly type = '[MODAL] Open alpha stock modal';
  data: AlphaStockModalOpts;
  constructor(
    forecastVersion: ForecastVersionModel
  ) {
    this.data = { forecastVersion };
  }
}
