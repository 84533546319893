import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VariableDialogService } from '../../../../variable-dialogs.service';
import { FVarDialogViewIndex, ForecastVariableInfoDialogData } from '../../forecast-variable-info.dialog';

@Component({
  selector: 'indicio-open-twin-component',
  templateUrl: './open-twin.component.html',
  styleUrls: ['./open-twin.component.less']
})
export class OpenTwinComponent {

  @Input() type: string;
  @Input() tab: FVarDialogViewIndex;
  @Input() twinId: string;
  @Input() options: ForecastVariableInfoDialogData;

  @Output() closeDialogEvent = new EventEmitter();

  // Entity Ids
  public get fVersionId() { return this.options.forecastVersionId; }
  public get forecastId() { return this.options.forecastId; }

  constructor(
    private varDialogService: VariableDialogService
  ) {
  }

  public openTwin() {
    this.closeDialogEvent.emit();
    this.varDialogService.openForecastVariableInfo({ forecastId: this.forecastId, forecastVariableId: this.twinId, forecastVersionId: this.fVersionId, view: this.tab });
  }
}
