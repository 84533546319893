<div *ngIf="queue?.allEntries.length"
     [ngClass]="{ minimized: minimized }"
     data-test-id="script-queue"
     class="script-queue cursor-default">
  <i class="ion-minus minimize"
     title="Minimize window"
     *ngIf="!minimized"
     (click)="minimized = true"></i>

  <div class="sq-container">
    <div class="row sq-header">
      <!-- From nowcast (FN), HasModel (M): col-xs-YY where YY is number in matrix
        FN  M  |  Task  FN  Model  Progress  Status
        0   0  |  3     0   0      5         4
        1   0  |  3     2   0      4         3
        0   1  |  2     0   3      4         3
        1   1  |  2     2   2      3         3
      -->
      <div [ngClass]="hasModels ? 'col-xs-2' : 'col-xs-3'">Task
      </div>
      <div *ngIf="hasFromNowcast"
           class="col-xs-2">Nowcast</div>
      <div *ngIf="hasModels"
           [ngClass]="hasFromNowcast ? 'col-xs-2' : 'col-xs-3'">Model</div>
      <div [ngClass]="hasModels && hasFromNowcast ? 'col-xs-3' : ((!hasModels && !hasFromNowcast) ? 'col-xs-5' : 'col-xs-4')"
           style="padding-left: 0;">Progress</div>
      <div [ngClass]="!hasModels && !hasFromNowcast ? 'col-xs-4' : 'col-xs-3'"
           style="padding-left: 5px;">Status</div>
    </div>
    <div class="sq-row-container">
      <div class="row"
           *ngFor="let script of queue.allEntries">
        <div class="col-xs-12 sq-row">
          <div class="sq-entry crop-string"
               [ngClass]="hasModels ? 'col-xs-2' : 'col-xs-3'"
               title="{{ script.DisplayName }}">{{ script.DisplayName }}</div>
          <div *ngIf="hasFromNowcast"
               class="col-xs-2 sq-entry">{{ script.ForecastVersionId === forecastVersionId ? 'No' : 'Yes' }}</div>
          <div *ngIf="hasModels"
               [ngClass]="hasFromNowcast ? 'col-xs-2' : 'col-xs-3'"
               class="sq-entry crop-string"
               [title]="envService.getModelName(script.ModelName).Display || '&nbsp;'">{{
            envService.getModelName(script.ModelName).Display || '&nbsp;' }}</div>
          <div class="sq-entry progress"
               [ngClass]="hasModels && hasFromNowcast ? 'col-xs-3' : ((!hasModels && !hasFromNowcast) ? 'col-xs-5' : 'col-xs-4')">
            <span class="text">{{ script.PercentDone }}%</span>
            <div class="bar-container">
              <div class="bar"
                   [style.width]="script.PercentDone +'%'"></div>
            </div>
          </div>
          <div class="sq-entry"
               [ngClass]="!hasModels && !hasFromNowcast ? 'col-xs-4' : 'col-xs-3'">
            <span [class]="{
            red: script.Status === 'error',
            orange: script.Status !== 'error' && script.TryCount > 1 }">
              {{ script.statusText }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6 sq-footer">In queue: {{ queue.allEntries.length }} tasks</div>
      <div class="col-xs-6 sq-footer text-right">
        <span *ngIf="queue?.Entries.length">Estimated time: {{ queue.tufText }}</span>
        <span *ngIf="!queue?.Entries.length">&nbsp;</span>
      </div>
    </div>
  </div>

  <div class="row maximize"
       title="Maximize window"
       *ngIf="minimized"
       (click)="minimized = false">
    <div class="col-xs-12 col-no-padding">
      <span *ngIf="queue?.Entries.length">Total: {{ queue.tufText }}</span>
      <span *ngIf="!queue?.Entries.length">&nbsp;</span>
    </div>
  </div>

</div>
