import { Def } from '@core/decorators/def';

export class NotificationMessageModel {

  @Def() ActorClientId: string;
  @Def() ActorClientName: string;
  @Def() CompanyId: string;
  @Def() CompanyName: string;
  @Def() ForecastId: string;
  @Def() ForecastVersionId: string;
  @Def() ForecastName: string;
  @Def() ObjectId: string;
  @Def() ObjectName: string;
  @Def() ProjectId: string;
  @Def() ProjectName: string;
  @Def() SharedProject: boolean;
  @Def() Type: string;

  // frontend stuff
  @Def() title: string;
  @Def() linkUrl: string;
  @Def() icon: string;
  @Def() canLink: Function = () => true;

  public toMarkup() {
    let t = this.title;
    if (!t) { return ''; }
    t = t.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    t = t.replace(/__(.*?)__/g, '<u>$1</u>');
    t = t.replace(/~~(.*?)~~/g, '<i>$1</i>');
    t = t.replace(/--(.*?)--/g, '<del>$1</del>');
    t = t.replace(/<<(.*?)>>/g, '<a href="$1">Link</a>');
    return t;
  }

  public toPlain() {
    let t = this.title;
    if (!t) { return ''; }
    t = t.replace(/\*\*(.*?)\*\*/g, '$1');
    t = t.replace(/__(.*?)__/g, '$1');
    t = t.replace(/~~(.*?)~~/g, '$1');
    t = t.replace(/--(.*?)--/g, '$1');
    t = t.replace(/<<(.*?)>>/g, '$1');
    return t;
  }
}
