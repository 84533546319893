import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataProvider } from '@core/constants/provider-definitions';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { MacrobondEntityInfoDTO } from '@core/store/providers/macrobond/dtos/macrobond-entityinfo.dto';
import { MacrobondExportSeasonalDTO } from '@core/store/providers/macrobond/dtos/macrobond-export.dto';
import { MacrobondService } from '@core/store/providers/macrobond/macrobond.service';
import { MacrobondUtils } from '@core/store/providers/macrobond/macrobond.utils';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { FVarDialogViewIndex } from '@dialogs/variables/forecast-variable/info/forecast-variable-info.dialog';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';

export class ExportSeasonalDataToMacrobondDialogData {
  variable: ForecastVariableModel;
}

@Component({
  selector: 'indicio-export-sa-data-to-macrobond-dialog',
  templateUrl: './export-sa-data-to-macrobond.dialog.html',
  styleUrls: ['./export-sa-data-to-macrobond.dialog.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportSeasonalDataToMacrobondDialogComponent extends DialogV2BaseDialog<ExportSeasonalDataToMacrobondDialogComponent> {

  public static Id: string = 'ExportSeasonalDataToMacrobondDialogComponent';

  // Flags
  public saveInProgress = false;

  // Entities
  public categories: string[] = [];
  public exportDto = new MacrobondExportSeasonalDTO();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ExportSeasonalDataToMacrobondDialogData,
    private status: StatusService,
    public mbService: MacrobondService,
    private cd: ChangeDetectorRef,
    private variableDialogService: VariableDialogService,
    dialogRef: MatDialogRef<ExportSeasonalDataToMacrobondDialogComponent>,
  ) {
    super(dialogRef);
    this.initialize();
  }

  public override close() {
    this.dialogRef.close();
    this.variableDialogService.openForecastVariableInfo({
      forecastId: this.data.variable.ForecastId,
      forecastVariableId: this.data.variable.ForecastVariableId,
      forecastVersionId: this.data.variable.ForecastVersionId,
      view: FVarDialogViewIndex.seasonal
    });
  }

  protected initialize() {
    this.initVariable();
    Promise
      .all([
        this.mbService.getOrFetchStorageLocations(),
        this.mbService.getOrFetchUserCategories(),
      ])
      .then(([locations, categories]) => {
        this.exportDto.Prefix = locations.find(x => x.Name === 'Personal account').Prefix;
        this.categories = categories;
        this.exportDto.RemoteVariableId = this.data.variable.RemoteVariableId;
        return this.data.variable.Source === DataProvider.macrobondapi;
      })
      .then((proceed: any) => {
        if (!proceed) return proceed;
        return this.mbService.getEntityInfo(this.data.variable.RemoteReferenceId);
      })
      .then(entityInfo => typeof entityInfo !== 'boolean' && this.setupEntityInfo(entityInfo))
      .catch(err => this.mbService.handleError(err))
      .catch(err => this.status.setError(err, true))
      .finally(() => {
        this.initialized = true;
        this.cd.detectChanges();
      });
  }

  public export() {
    if (this.saveInProgress) { return; }
    this.saveInProgress = true;
    this.mbService.exportSeasonalData(this.exportDto)
      .then(() => {
        this.status.setMessage('Export to Macrobond successful', 'Success');
        this.close();
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => {
        this.saveInProgress = false;
        this.cd.detectChanges();
      });
  }


  private setupEntityInfo(entityInfo: MacrobondEntityInfoDTO) {
    MacrobondUtils.initExportDTO(this.exportDto, entityInfo, 'saIndicio', 'Seasonally adjusted in Indicio');
    if (!!this.exportDto.IHCategory) {
      this.categories.addUniqueId(this.exportDto.IHCategory);
    }
  }

  private initVariable() {
    this.exportDto.ForecastVariableId = this.data.variable.ForecastVariableId;
    this.exportDto.IncludeAdjusted = true;
    this.exportDto.IncludeComponent = true;
    this.exportDto.IncludeIrregular = false;
    this.exportDto.IncludeTrend = false;
    this.exportDto.IncludeOriginal = false;
  }


}
