import { ChangeDetectorRef, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { StatusService } from '@core/services/status/status.service';
import { StatusComponent } from '@shared/components/status/status.component';


@Component({
  selector: 'indicio-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class DialogBaseComponent {

  @ViewChild(StatusComponent, { static: true }) statusComponent: StatusComponent;

  @Input() set loading(flag: boolean) { this._loading = flag; this.change.detectChanges(); }

  _loading: boolean;

  constructor(
    public statusService: StatusService,
    private change: ChangeDetectorRef
  ) { }
}
