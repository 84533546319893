import { Def } from '@core/decorators/def';
import { AccuracyMeasureDTO } from '@core/entities/dtos/accuracy-measure-dto';
import { DoubleValuePropertyDTO } from '@core/entities/dtos/double-value-property-dto';
import { PastForecastDTO } from '@core/entities/dtos/past-forecast-dto';
import { FittedValueDTO, StatModelVariableDTO } from '@core/entities/dtos/plot-value-dto';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { ModelName } from '@modules/lang/types/model-name';
import { ALGSingleSeriesModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { ShapValueDTO, StatModelFilesDTO } from './dtos/stat-model.dto';
import { StatModelUtils } from './stat-model.utils';

export interface IHasModelWeightsModel {
  MAPESteps: AccuracyMeasureDTO[];
  MPESteps: AccuracyMeasureDTO[];
  MAESteps: AccuracyMeasureDTO[];
  MESteps: AccuracyMeasureDTO[];
  RMSESteps: AccuracyMeasureDTO[];
  HRSteps: AccuracyMeasureDTO[];
  MASESteps: AccuracyMeasureDTO[];
  RSQSteps: AccuracyMeasureDTO[];
  show: boolean;
}

export class StatModel implements IHasModelId {
  @Def() ForecastVersionId: string;
  @Def() RRequestId: string;
  @Def() Name: string;
  @Def() ModelName: string;
  @Def() Periodicity: PeriodicityType;
  @Def() Disabled: boolean;
  @Def() ModelBuilt: boolean;
  @Def() ExitSuccess: boolean;
  @Def() ExitError: boolean;
  @Def() IsPending: boolean;
  @Def() IsProcessing: boolean;
  @Def() Transforms: StatTransformationModel[];

  modelName: ModelName;

  // Frontend fields / methods
  public getModelId(): string {
    return this.ForecastVersionId + this.ModelName;
  }

  public getALGModels(): ALGSingleSeriesModel[] {
    const result: ALGSingleSeriesModel[] = [];
    for (let transform of this.Transforms) {
      result.push(...transform.getALGModels(this));
    }
    return result;
  }
}

export class StatTransformationModel implements IHasModelWeightsModel, IHasModelId {
  @Def() MainModelId: number;
  @Def() TransformName: string;
  @Def() Name: string;
  @Def() RDataAvailable: boolean;
  @Def() ModelBuilt: boolean;
  @Def() Disabled: boolean;
  @Def() FittedValues: FittedValueDTO[];
  @Def() WFittedValues: FittedValueDTO[];
  @Def() InProperties: DoubleValuePropertyDTO[];
  @Def() OutProperties: DoubleValuePropertyDTO[];
  @Def() MAPESteps: AccuracyMeasureDTO[];
  @Def() MPESteps: AccuracyMeasureDTO[];
  @Def() MAESteps: AccuracyMeasureDTO[];
  @Def() MESteps: AccuracyMeasureDTO[];
  @Def() RMSESteps: AccuracyMeasureDTO[];
  @Def() HRSteps: AccuracyMeasureDTO[];
  @Def() MASESteps: AccuracyMeasureDTO[];
  @Def() RSQSteps: AccuracyMeasureDTO[];
  @Def() PastForecasts: PastForecastDTO[];
  @Def() IndicatorVariableIds: string[];
  @Def() InfoMessages: string[];
  @Def() WarningMessages: string[];
  @Def() ErrorMessages: string[];
  @Def() Variables: StatModelVariableDTO[];
  @Def() ShapValues: ShapValueDTO[];
  @Def() WShapValues: ShapValueDTO[];

  // Frontend fields / methods
  ImagesDTO: StatModelFilesDTO = null;
  TextFilesDTO: StatModelFilesDTO = null;
  modelName: ModelName;
  color = '#ccc';
  show = true;
  isMultiModel: boolean;
  ScenarioId: string = null;

  public getALGModels(statModel: StatModel) {
    const result: ALGSingleSeriesModel[] = [];
    for (let variable of this.Variables) {
      result.push(StatModelUtils.getALGModel(statModel, this, variable));
    }
    return result;
  }

  public getModelId(): string {
    return this.MainModelId.toString();
  }

  private nameRe() { return new RegExp(this.Name, 'i'); }
  public isSameModel(name: string) {
    return this.nameRe().test(name);
  }
}
