export namespace WikiConstants {

  // Define the string used to represent an asset ID
  export const AssetIdString = '((asset-id))';
  export const AssetSizeString = '((asset-size))';

  // Define a regular expression to match the asset ID string
  export const AssetIdStringRegex = AssetIdString.replace(/\(/g, '\(').replace(/\)/g, '\\)').replace(/\-/, '\\-');

  // Define the string used to represent an asset
  export const AssetString = `[indicio-asset:${AssetIdString} ${AssetSizeString}]`;
  // Define a regular expression to match the asset string
  export const AssetRegexp = /\[indicio-asset:([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{3,4}-[0-9a-f]{3,4}-[0-9a-f]{12})\s?(\d{1,}x\d{1,})?\]/gm;

  // Define an element that displays a loader
  export const AssetLoaderElement = '<i class="ion-load-c spinner"></i>';

  // Define the string used to represent an asset loader
  export const AssetLoaderString = `[indicio-asset-loader:${AssetIdString} ${AssetSizeString}]`;

  export const AssetLoaderRegexp = /\[indicio-asset-loader:([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{3,4}-[0-9a-f]{3,4}-[0-9a-f]{12})\s?(\d{1,}x\d{1,})?\]/gm;

  // Define a function to generate a regular expression to match the asset loader string for a given asset ID
  export const AssetLoaderRegexpFn = (assetId: string) => new RegExp(`\\[indicio-asset-loader:(${assetId.replace(/\-/g, '\\-')})\\s?(\\d{1,}x\\d{1,})?\\]`, 'gm');

  // Define the string used to represent a link to a wiki page
  export const LinkString = 'indicio-wiki://';

  // Define a regular expression to match the wiki page link string
  export const LinkRegexp = /indicio\-wiki:\/\//gm;

  // Define the key used to store the backup of a wiki page in local storage
  export const STORAGE_KEY = 'wiki.page.backup';
}
