<div class="fcast-info-general-tab">
  <mat-accordion>
    <!-- Stationarity-->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Settings
        </mat-panel-title>
        <mat-panel-description>
          Settings for the forecast
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="settingsTemplate"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #settingsTemplate>
  <indicio-modal-option-row optionTitle="Name"
                            optionSubtitle="Modify the current name">
    <mat-form-field style="margin-top: 25px;">
      <input type="text"
             matInput
             [disabled]="!canUpdateForecast"
             class="material"
             autocomplete="off"
             [ngModel]="newName"
             (ngModelChange)="changeForecastName($event)"
             name="Name"
             maxlength="64"
             required>
      <mat-hint *ngIf="forecastNameMustChange">
        <span class="error-text">A forecast with that name already exists in the target project.</span>
      </mat-hint>
    </mat-form-field>
  </indicio-modal-option-row>

  <!-- Forecast periodicity -->
  <indicio-modal-option-row optionTitle="Periodicity"
                            optionSubtitle="Modify the current periodicity">
    <mat-form-field style="margin-top: 25px;">
      <mat-select [value]="forecast.Periodicity"
                  [disabled]="!canUpdateForecast"
                  (selectionChange)="changePeriodicity($event.value)"
                  [required]="true">
        <mat-option [value]="periodicity.Value"
                    *ngFor="let periodicity of validPeriodicities">{{ periodicity.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <!-- Save button -->
  <div class="pull-right"
       style="width:fit-content; margin-top: 25px;">
    <button *ngIf="canUpdateForecast"
            mat-raised-button
            type="button"
            color="primary"
            [disabled]="!changed"
            (click)="save()">
      <span *ngIf="!pending">Save changes</span>
      <indicio-spinner *ngIf="pending"></indicio-spinner>
    </button>
  </div>
</ng-template>
