import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReconciliationReport } from '@core/store/hierarchical-forecast/entities/models/reconciliation-report.model';
import { ReportModel } from '@core/store/reports/models/report.model';
import { FiscalYearReport } from '@modules/reports/models/fiscal-year.model';
import { ReportTools } from '@modules/reports/utilities/report-converter';
import { DateFormatPipe } from '@shared/modules/pipes';
import { FileUtils } from '@shared/utils/file.utils';
import { ExcelFile } from '@shared/utils/generators/xlsx/excel.entities';
import { OverviewReportGenerator } from '@shared/utils/generators/xlsx/reports/overview-report.generator';
import { ReportUtils } from '@shared/utils/report.utils';
import { EnvironmentService } from './environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class FileGeneratorService {

  private get baseUrl() { return this.env.env.UriFrontendUtilsApi; }

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private datePipe: DateFormatPipe,
  ) { }

  /** Report frontend */
  public downloadReport(report: ReportModel) {
    let promise;
    report.downloadPending = true;
    switch (report.Template) {
      case 'fiscal-year':
        const fiscal = ReportTools.ConvertReport<FiscalYearReport>(report);
        promise = this.generateFiscalYearXLXS(fiscal);
        break;
      case 'overview':
        promise = this.generateExcelFile(new OverviewReportGenerator({
          DateFormat: this.datePipe,
          Report: report,
          Overview: ReportTools.ConvertReport(report)
        }).generate());
        break;
      default:
        break;
    }
    return promise.finally(() => report.downloadPending = false);
  }

  /** Report Backend */
  public generateFiscalYearXLXS(report: FiscalYearReport) {
    const dto = ReportUtils.getDownloadDTO(report);

    return this.http.request('post', `${this.baseUrl}file-generator/fiscal-year`, { body: dto, observe: 'response', responseType: 'blob', }).toPromise()
      .then(resp => {
        const filename = resp.headers.get('content-disposition').split('=')[1];
        FileUtils.downloadBlob(resp.body, filename);
      });
  }

  /** Reconciliation */
  public generateReconciliationReport(report: ReconciliationReport) {
    return this.http.request('post', `${this.baseUrl}file-generator/reconciliation`, { body: report, observe: 'response', responseType: 'blob' }).toPromise()
      .then(resp => {
        const filename = resp.headers.get('content-disposition').split('=')[1];
        FileUtils.downloadBlob(resp.body, filename);
      });
  }

  /** Excel file */
  public async generateExcelFile(fileDto: ExcelFile) {
    return this.http.request('post', `${this.baseUrl}file-generator/generate-excel`, { body: fileDto, observe: 'response', responseType: 'blob' }).toPromise()
      .then(resp => {
        const filename = resp.headers.get('content-disposition').split('=')[1];
        FileUtils.downloadBlob(resp.body, filename);
      });
  }
}
