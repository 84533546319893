import { d3Types } from '@shared/utils/d3/d3-svg.types';
import { d3Utils } from '@shared/utils/d3/d3.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import * as d3 from 'd3';
import { ALGTypes } from '../alg-types';
import { ALGUtils } from '../alg.utils';

export interface GraphHoverData {
  targetGroup: d3.Selection<SVGGElement, any, any, any>,
  instance: number;
  isPercentage: boolean;
  edgeDistance: number;
  graphHeight: number;
  graphWidth: number;
  yScale: d3.ScaleLinear<any, any>;
}

// We normally only use the distinct margin to showcase titles and 'main' numbers, e.g. model value in middle of all confidence interval numbers.
const MARGINS = { Standard: 20, Distinct: 30 };

export class GraphHover {

  /* Main SVG hover groups */
  private HoverGroup: d3Types.Group = null;
  private HoverTimeGroup: d3Types.Group = null;

  // Misc SVG elements with direct access
  private HoverLine: d3Types.Line = null;
  private HoverTimeRect: d3Types.Rect = null;
  private HoverTimeText: d3Types.Text = null;

  // Hover box group
  private HoverBoxGroup: d3Types.Group = null;
  private HoverBoxRect: d3Types.Rect = null;

  // Helpers/Getters
  private get GraphH() { return this.data.graphHeight; }
  private get GraphW() { return this.data.graphWidth; }
  private getDisplayValue = (v: number) => ValueUtils.getValueAsAbbreviatedString(v * (this.data.isPercentage ? 100 : 1), this.data.isPercentage);

  // Special flags
  private hideBox: boolean = false;

  /**
   * Note that this class will be re-initialized whenever the data in the ALG changes.
   * To make the hover as fast as possible, make sure to setup easy access to the relevant data as soon as possible.
   */
  constructor(
    public data: GraphHoverData
  ) {
    this.createHoverGroup();
  }

  private createHoverGroup() {
    this.HoverGroup = d3Utils.addGroup(this.data.targetGroup, { Class: 'hover-group', Opacity: 0 });
    this.HoverTimeGroup = d3Utils.addGroup(this.HoverGroup, { Class: 'hover-time-group' });
    this.HoverTimeRect = d3Utils.addRectElement(this.HoverTimeGroup, { Class: 'hover-rect solid' });
    this.HoverTimeText = d3Utils.addTextElement(this.HoverTimeGroup, { Class: 'hover-text' });
    this.addHoverLine();
    const chg = d3Utils.addGroup(this.HoverGroup, { Class: 'forecast-hover-group', Opacity: 0 });
    this.HoverBoxGroup = chg;
    this.HoverBoxRect = d3Utils.addRectElement(chg, { Class: 'hover-rect' });
    d3Utils.moveElement(chg, this.GraphW / 2, this.GraphH / 2);
  }

  /*
   * Public functions:
   * - hide: Hides all the hover elements
   * - show: Show all the hover elements
   * - move(x, y): Move all the relevant hover elements to show the content given the mouseX (x) and mouseY (y) positions
   */
  public hideHover() { d3Utils.setVisibility(this.HoverGroup, false); }
  public showHover() { d3Utils.setVisibility(this.HoverGroup, true); }

  public hideHoverBox() { this.hideBox = true; d3Utils.setVisibility(this.HoverBoxGroup, false); }
  public showHoverBox() { this.hideBox = false; d3Utils.setVisibility(this.HoverBoxGroup, true); }

  private getAdjustedPosition(selection: d3Types.Base, dateXPos: number, valueYPos: number) {
    const bbox = ALGUtils.getBoundingBox(selection);
    const edgeDist = this.data.edgeDistance;
    let newX = dateXPos + 15;
    /* Check if the element would go outside the graph to the right.
       No need to care about the left edge. */
    if (this.GraphW < bbox.width + dateXPos + edgeDist) {
      newX = dateXPos - (bbox.width + 15);
    }

    let newY = valueYPos - (bbox.height * 0.67);
    const boxBottomY = valueYPos + (bbox.height - (bbox.height * 0.67));

    /* Check if the element would go outside the graph to the top. */
    if (newY < edgeDist) {
      newY = valueYPos - (valueYPos - edgeDist);
    }

    /* Check if the element would go outside the graph to the bottom. */
    if (boxBottomY > this.GraphH - edgeDist) {
      newY = this.GraphH - edgeDist - bbox.height;
    }
    return { newX, newY };
  }

  /** Move the vertical line that marks the current hover position */
  private moveHoverTime(newXPos: number, date: string) {
    d3Utils.moveElement(this.HoverLine, newXPos, 0);
    this.HoverTimeText.text(date);
    const bbox1: any = ALGUtils.getBoundingBox(this.HoverTimeText);
    const w = bbox1.width ? bbox1.width + 20 : 0;
    const h = bbox1.height ? bbox1.height + 10 : 0;
    const w2 = w / 2;

    // Fix the position of the rect, so it doesn't go outside the graph left or right
    let dateXPos = newXPos < w2 ? -newXPos : - w2;
    if (newXPos > this.GraphW - w2) { dateXPos = -(newXPos - this.GraphW + w); }

    this.HoverTimeRect.attr('width', w).attr('height', h).attr('x', dateXPos).attr('y', (h / 2) - 8);
    this.HoverTimeText.attr('x', dateXPos + 10).attr('y', h - 4);

    d3Utils.moveElement(this.HoverTimeGroup, newXPos, this.GraphH - 1);
  };


  /* Specific element functions */
  private addHoverLine() {
    return this.HoverLine = d3Utils.addGroup(this.HoverGroup, { Class: 'hover-line' })
      .attr('clip-path', 'url(#rect-clip-' + this.data.instance + ')')
      .append('line')
      .attr('x1', 0).attr('x2', 0)
      .attr('y1', 0).attr('y2', this.GraphH)
      .style('stroke-width', 2);
  }

  public updateHover(x: ALGTypes.AlgHoverTextLine[], xPos: number, date: string) {
    /* Move the vertical line and the date-display below the graph */
    this.moveHoverTime(xPos, date);

    /* Remove all previously drawn elements */
    this.HoverGroup.selectAll('.hover-content').remove();

    /* If box is hidden, do nothing here */
    if (this.hideBox) {
      return;
    }

    /* Pixel start position for the next line */
    let nextLineStart = MARGINS.Standard;

    const grp = this.HoverBoxGroup;
    for (let info of x) {
      const yCoord = nextLineStart + (!info.Margins ? 0 : info.Margins[0]);
      const xCoord = info.Circle ? 30 : 10;
      if (info.Circle) {
        // Create the circle element inside the hover-box
        d3Utils.addCircleElement(grp, { Class: 'hover-content', R: 6, Fill: info.CircleColor, Cx: 15, Cy: yCoord - 5.5 });
        // Create the circle over the line in graph, and move it to the correct position
        const pc = d3Utils.addCircleElement(this.HoverGroup, { Class: 'hover-content', Fill: info.CircleColor, StrokeWidth: 1 });
        d3Utils.moveElement(pc, xPos, this.data.yScale(info.Value));
      }

      const valueDisplay = !!info.ValueStr ? info.ValueStr : info.Value == null ? null : this.getDisplayValue(info.Value);
      if (!!valueDisplay) info.Text += ': ';
      const tSpans = [{ Text: info.Text }, { Text: valueDisplay, FontWeight: 600 }];
      d3Utils.addLabelValueElement(grp, { Class: 'hover-content hover-text', Children: tSpans, X: xCoord, Y: yCoord });
      nextLineStart += (!info.Margins ? 0 : info.Margins[0]) + (!info.Margins ? 0 : info.Margins[1]) + MARGINS.Standard;
    }

    this.updateRectSize();
    const value = x.filter(p => Number.isFinite(p.Value)).map(p => p.Value).avg();
    const yPos = this.data.yScale(value);
    const { newX, newY } = this.getAdjustedPosition(this.HoverBoxRect, xPos, yPos);
    const t = d3.transition().duration(150).ease(d3.easeCubicOut);
    /* Move the group containing the hover-box */
    d3Utils.moveElement(grp, newX, newY, t);
    d3Utils.setVisibility(grp, true);
  }

  private updateRectSize() {
    const textElements = this.HoverBoxGroup.selectAll<SVGTextElement, d3Types.Group>('text');
    const bboxes = textElements.nodes().map(e => ALGUtils.getBoundingBox(d3.select(e)));
    const newWidth = Math.max(...bboxes.map(bbox => bbox.x + bbox.width)) + 10;
    this.HoverBoxRect.attr('width', newWidth);
    const newHeight = Math.max(...bboxes.map(bbox => bbox.y + bbox.height)) + 12;
    this.HoverBoxRect.attr('height', newHeight);
    return;
  }
}
