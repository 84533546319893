import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { VariableSelectionResultDTO } from '@core/store/var-select/dtos/variable-selection-result-dto';
import { VarSelectMapper } from '@core/store/var-select/var-select-mapper';


@Injectable({
  providedIn: 'root'
})
export class VariableSelectBackendService {

  constructor(
    private http: HttpService,
    private mapper: VarSelectMapper
  ) { }

  public triggerVarSelect(forecastVersionId: string, autoTriggerMultiAfter: boolean) {
    return this.http.post<null>(`forecast/version/${forecastVersionId}/varselect-trigger`, autoTriggerMultiAfter);
  }

  public getVariableSelectionResult(forecastVersionId: string) {
    return this.http.get<VariableSelectionResultDTO[]>(`forecast/version/${forecastVersionId}/varselect-result`)
      .then(resp => resp.body)
      .then(res => res.map(result => this.mapper.map(result)))
      .then(res => Promise.all(res));
  }
}


