import { Component, ViewEncapsulation } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AutoTaskFrontendService } from '@core/store/auto-task/auto-task.frontend.service';
import { AutoTaskModel } from '@core/store/auto-task/dtos/auto-task.model';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { EAutoTaskState } from '@modules/lang/language-files/automations';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateUtils } from '@shared/utils/date.utils';
import { TaskHistoryModalOpts } from './task-history-modal.action';
@Component({
  templateUrl: './task-history-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./task-history-modal.component.less']
})
export class TaskHistoryModalComponent extends ModalModelComponent {

  public modalTitle = 'Task History';

  private opts: TaskHistoryModalOpts;
  public task: AutoTaskModel;
  public selectedEntryId: string;
  public selectedStepNumber: number;

  public get history() { return this.task.History; }
  public get selectedEntry() { return this.history.find(x => x.AutoTaskRunResultId === this.selectedEntryId); }
  public get selectedStep() { return this.selectedEntry.StepResults.find(x => x.Step === this.selectedStepNumber); }
  public get companyId() { return this.clientService.activeCompanyId; }
  public get elapsedTime() {
    if (this.task.State === EAutoTaskState.SUCCESS || this.task.State === EAutoTaskState.FAILED) {
      return this.selectedEntry.elapsedTime;
    }
    return DateUtils.getSecMinHrFromMs(DateUtils.newMoment().diff(DateUtils.newDate(this.selectedEntry.StartedAt.toString()), 'milliseconds'));
  }

  constructor(
    public appearance: AppearanceService,
    protected store: Store,
    public envService: EnvironmentService,
    private service: AutoTaskFrontendService,
    private status: StatusService,
    private clientService: ClientFrontendService,
  ) {
    super();
  }

  public setOptions(options: TaskHistoryModalOpts) {
    this.opts = options;
    this.service.getOrFetch(this.companyId, this.opts.taskId)
      .then(t => {
        this.task = t;
        this.selectedEntryId = this.history[0].AutoTaskRunResultId;
        this.selectedStepNumber = 1;
        this.modalTitle = `Task History: ${t.Name}`;
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => this.isLoading = false);
  }
}
