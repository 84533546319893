<indicio-dialog class="admin-edit-company-dialog">
  <h2 mat-dialog-title
      style="margin-bottom: 10px;">Company information (SYS-ADMIN)
    <div class="crop-string subtitle"
         [title]="company.Name"
         *ngIf="initialized">
      Company: {{ company.Name }}
    </div>
  </h2>
  <mat-dialog-content>
    <ng-container *ngIf="initialized; else loadingTemplate">
      <ng-container *ngTemplateOutlet="dialogTabNavigationTemplate"></ng-container>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button
            type="button"
            (click)="close()">
      Close
    </button>
    <button mat-raised-button
            [disabled]="!saveActive"
            (click)="save()"
            color="primary">
      <span *ngIf="!inProgress">Save</span>
      <indicio-spinner *ngIf="inProgress"></indicio-spinner>
    </button>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #loadingTemplate>
  <div><i class="ion-load-c spinner"></i></div>
</ng-template>

<ng-template #dialogTabNavigationTemplate>
  <mat-tab-group animationDuration="0ms"
                 color="primary"
                 [selectedIndex]="viewIndex"
                 (selectedIndexChange)="changeView($event)"
                 [disablePagination]="true">
    <mat-tab label="General">
      <ng-container *ngIf="viewIndex === viewTypes.general"
                    [ngTemplateOutlet]="generalTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Users">
      <ng-container *ngIf="viewIndex === viewTypes.users"
                    [ngTemplateOutlet]="usersTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Data providers">
      <ng-container *ngIf="viewIndex === viewTypes.providers"
                    [ngTemplateOutlet]="providersTemplate"></ng-container>
    </mat-tab>

    <mat-tab label="Audit">
      <ng-container *ngIf="viewIndex === viewTypes.audit"
                    [ngTemplateOutlet]="auditTemplate"></ng-container>
    </mat-tab>

    <mat-tab label="Settings">
      <ng-container *ngIf="viewIndex === viewTypes.settings"
                    [ngTemplateOutlet]="settingsTemplate"></ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<ng-template #generalTemplate>
  <indicio-company-edit-admin-dialog-general-tab [company]="company"
                                                 (newCountryEvent)="setNewCountry($event)"
                                                 (maxUsersEvent)="setMaxUsers($event)"
                                                 (supportEvent)="setSupport($event)">
  </indicio-company-edit-admin-dialog-general-tab>
</ng-template>

<ng-template #usersTemplate>
  <indicio-company-edit-admin-dialog-users-tab [company]="company"
                                               (roleChangedEvent)="checkNeedSave()">
  </indicio-company-edit-admin-dialog-users-tab>
</ng-template>

<ng-template #providersTemplate>
  <indicio-company-edit-admin-dialog-providers-tab [company]="company"
                                                   (providersChangedEvent)="checkNeedSave()">
  </indicio-company-edit-admin-dialog-providers-tab>
</ng-template>

<ng-template #auditTemplate>
  <indicio-company-edit-admin-dialog-audit-tab [company]="company"></indicio-company-edit-admin-dialog-audit-tab>
</ng-template>


<ng-template #settingsTemplate>
  <indicio-company-edit-admin-dialog-settings-tab [company]="company"
                                                  (checkSavedEvent)="checkNeedSave()"
                                                  (reloadEvent)="setup()">
  </indicio-company-edit-admin-dialog-settings-tab>
</ng-template>
