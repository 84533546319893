import { Def } from '@core/decorators/def';
import { RemoteDataSourceModel } from '../providers/models/remote-data-source.model';
import { GraphThemeModel } from './graph-theme.model';

export class ClientModel {
  @Def() ClientId: string;
  @Def() AutomaticLogout: boolean;
  @Def() TermsOfServiceAgreementId: string;
  @Def() CompanyIds: string[] = [];
  @Def() ActiveCompanyId: string;
  @Def() RemoteDataSourceIds: string[];
  @Def() RemoteDataSources: RemoteDataSourceModel[];
  @Def() GraphThemes: GraphThemeModel[];
  @Def() Disabled: boolean;

  public findDataSource(source: string, withApikey = false) {
    const regex = new RegExp(source, 'i');
    return this.RemoteDataSources.find(s =>
      (!!s.Provider?.match(regex) && withApikey === false) || (withApikey === true && s.ApiKey !== null && !!s.Provider?.match(regex))
    );
  }
}
