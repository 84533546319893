export namespace NavigationActions {
  export class ForceForecastDrawerReload {
    public static readonly type = '[Navigation] Force forecast drawer reload';
    constructor() { }
  }

  export class SetActiveProject {
    public static readonly type = '[Navigation] Set active project';
    constructor() { }
  }
}
