import { VariableOwnerInfo } from '../../models/forecast-version.model';

export class RefreshForecastVariableStepEventArgsDTO {
  ForecastVersionId: string;
  ForecastVersionCreated: boolean = false;
  MainVariableCreated: boolean = false;
  IndicatorsCreated: number = 0;
  IndicatorCount: number;
  NotUpdated: VariableOwnerInfo[] = [];
}

export class SwapVariablesStepEventArgsDTO {
  ForecastVersionId: string;
  MainVariableRemoved: boolean = false;
  MainVariableCreated: boolean = false;
  IndicatorRemoved: boolean = false;
  IndicatorCreated: boolean = false;
  Done: boolean = false;
  ProcessFailed: boolean = false;
  WarningMessage: string = null;
}

export class FvarFverGuidsEventArgsDTO {
  ForecastVersionId: string;
  ForecastVariableId: string;
}
