import { Def } from '@core/decorators/def';

export class CreateNowcastDTO {
  @Def() ForecastVariableId: string;
  @Def() ForecastVersionId: string;
  @Def() IncludeParentData: boolean;
  @Def() Periodicity: string;
  @Def() Horizon: number;
  @Def() Quick: boolean = false;
}
