
import { ColumnDTO, VariableSheetDTO } from '@core/store/file/dtos/variable-sheet-dto';

/**************************************** */

/**************************************** */

export type ColumnType = 'Dates' | 'Values' | 'MaxValues' | 'MinValues' | 'NotUsed' | 'Identifiers';
export type ImportStep = 'Pending' | 'Importing' | 'Imported' | 'Warning' | 'Failed' | 'Step2Error';

export class SheetError {
  dateError: any = null;
  valueError: any = null;
  minError: any = null;
  maxError: any = null;
}
export class Sheet extends VariableSheetDTO {

  Columns: SheetColumn[] = [];
  Rows: any[] = [];
  empty: boolean = false;
  maxRowCount: number;
  disabledRows: number[] = [];

  constructor(
    dto: VariableSheetDTO
  ) {
    super(dto);
    this.empty = this.ParsedColumns.length === 0;
    this.setMaxRowCount();
    this.setupColumns();
    this.setupRows();
    this.setDisabledRows();
  }

  public setDisabledRows() {
    const dateCol = this.getColumnByType('Dates');
    if (dateCol) {
      this.disableInvalidDateRows(dateCol.columnIndex);
    }
  }

  public getColumnByType(type: ColumnType) {
    return this.Columns.find(x => x.ColumnType === type);
  }

  public getColumnByIndex(idx: number) {
    return this.Columns.find(x => x.columnIndex === idx);
  }

  public disableInvalidDateRows(index: any): any {
    const dateColumn = this.Columns[index];
    this.disabledRows = dateColumn.DisabledRows;
  }

  private setupRows() {
    for (let i = 0; i < this.maxRowCount; i++) {
      const row = [];
      for (let j = 0; j < this.ParsedColumns.length; j++) {
        const c = this.ParsedColumns[j];
        if (c.Raw.length < i || !c.Raw[i]) {
          row.push('');
        } else {
          row.push(c.Raw[i]);
        }
      }
      this.Rows.push(row);
    }
  }

  private setupColumns() {
    this.Columns = this.ParsedColumns.map((columnDto, idx) => new SheetColumn(columnDto, idx));
  }

  private setMaxRowCount() {
    let max = 0;
    this.ParsedColumns.forEach(a => max = a.Raw.length > max ? a.Raw.length : max);
    this.maxRowCount = max;
  }
}

export class SheetColumn extends ColumnDTO {

  name: string = '';
  used: boolean = false;
  editName: boolean;
  nameError: string;
  failedMsg: string;
  warningMsg: string;
  mainVariable: boolean = false;

  constructor(
    dto: ColumnDTO,
    public columnIndex: number,
    public aggregationId = 'average',
    public status: ImportStep = 'Pending'
  ) {
    super(dto);
    this.setColumnName();
  }

  public setImported() {
    this.used = true;
    this.status = 'Imported';
  }

  private setColumnName() {
    this.name = this.Raw.getFirstComment();
  }
}


