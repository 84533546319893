<indicio-dialog class="wiki-order-dialog">
  <h2 mat-dialog-title>PDF settings</h2>
  <mat-dialog-content *ngIf="initialized; else loadingTemplate">

    <ng-container *ngTemplateOutlet="fileInputTemplate"></ng-container>

    <hr style="margin: 10px 0;">

    <ng-container *ngTemplateOutlet="pdfsTemplate"></ng-container>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button
            type="button"
            (click)="back()">Close</button>
    <button mat-stroked-button
            type="button"
            [disabled]="isSaving"
            (click)="save()">
      <indicio-spinner *ngIf="isSaving"></indicio-spinner>
      Save
    </button>
  </mat-dialog-actions>
</indicio-dialog>


<ng-template #pdfsTemplate>
  <div class="pdf-container">
    <div class="grid-system"
         style="--grid-template: 1fr 100px 70px 150px 50px">
      <div class="bold">Filename</div>
      <div class="bold">License</div>
      <div class="bold">Size</div>
      <div class="bold">Uploaded</div>
    </div>
    <div class="grid-system hover"
         style="--grid-template: 1fr 100px 70px 150px 50px"
         *ngFor="let pdf of files; let first = first">
      <div class="crop-string cursor-pointer">
        {{ pdf.Name }}
        <i class="ion-star yellow"
           *ngIf="first"></i>
      </div>
      <div class="crop-string">{{ pdf.License }}</div>
      <div class="crop-string">{{ getFormattedSize(pdf.Size) }}</div>
      <div class="crop-string">{{ pdf.CreatedDate | dateFormat:null:appearance.FullFormatNoSeconds}}</div>
      <div class="crop-string text-right">
        <fa-icon [icon]="faDownload"
                 style="margin-right: 10px"
                 class="cursor-pointer"
                 (click)="download(pdf.WikiPDFVersionId)"></fa-icon>
        <i class="ion-trash-a cursor-pointer red"
           (click)="askRemoveAsset(pdf)"></i>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #fileInputTemplate>
  <div>
    <input type="file"
           accept=".pdf"
           id="file"
           name="file"
           multiple
           #fileinput
           (change)="fileChange(fileinput.files)">
    <div (dragover)="allowDrop($event)"
         (dragenter)="toggleDragOver(true)"
         (dragleave)="toggleDragOver(false)"
         (drop)="drop($event)"
         data-test-id="drag-and-drop-box"
         (click)="fileinput.click()"
         class="drag-and-drop-box">
      <p><i class="ion-upload"></i></p>
      <p>Click to browse files or drag them here.</p>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  Loading assets...
</ng-template>
