import { Component } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CompanyActions } from '@core/store/company/company.actions';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { ChangeCompanyModalOpts } from './change-company-modal.options';


@Component({
  selector: 'indicio-change-company-modal',
  templateUrl: './change-company-modal.component.html',
  styleUrls: ['./change-company-modal.component.less']
})
export class ChangeCompanyModalComponent extends ModalModelComponent {

  opts: ChangeCompanyModalOpts;
  passToVerify: string = '';

  constructor(
    private companyService: CompanyFrontendService,
    private projService: ProjectFrontendService,
    private profileService: ProfileFrontendService,
    private authService: AuthFrontendService,
    private status: StatusService,
    public envService: EnvironmentService,
    protected store: Store,
    public appearance: AppearanceService,
  ) {
    super();
  }

  public setOptions(o: ChangeCompanyModalOpts) {
    this.opts = o;
    this.isLoading = false;
  }

  public onClose() {
    // this.companyService.setActiveCompany(this.opts.oldCompanyId);
  }

  public proceed() {
    if (!this.passToVerify.length) { return; }
    this.pending = true;
    this.authService.verifyPassword(this.profileService.profile.Email, this.passToVerify)
      .then(_passwordOk => this.companyService.setActiveCompany(this.opts.newCompanyId, undefined))
      .then(() => this.companyService.fetchCompany(this.opts.newCompanyId))
      .then(() => this.projService.fetchAllProjects(this.opts.newCompanyId))
      .then(() => {
        this.close();
        this.store.dispatch(new CompanyActions.ChangedActiveCompany(this.opts.newCompanyId));
        this.status.setMessage('Company changed', 'Success');
      })
      .catch(_err => {
        this.companyService.setActiveCompany(this.opts.oldCompanyId);
        this.status.setMessage('Password verification failed', 'Error', true);
      })
      .finally(() => this.pending = false);
  }
}
