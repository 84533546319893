import { Component, Input } from '@angular/core';

@Component({
  selector: 'indicio-icon-and-text-logo',
  templateUrl: './icon-and-text-logo.component.html',
  styleUrls: ['./icon-and-text-logo.component.less']
})
export class IconAndTextLogoComponent {

  @Input() showIcon: boolean = true;
  @Input() showTextIcon: boolean = true;
  @Input() showText: boolean = true;
}
