import { Injectable } from '@angular/core';
import { RQueueEntryDTO, RQueueInfoDTO } from './dtos/r-queue.dtos';
import { RQueueEntryModel, RQueueInfoModel } from './r-queue.models';
import { ScriptDisplayNames } from './script-display-names';

@Injectable({
  providedIn: 'root'
})
export class ScriptQueueMapper {

  public mapRQueueInfo(dto: RQueueInfoDTO) {
    const model = Object.faMapTo(new RQueueInfoModel(), dto);
    model.Entries = dto.Entries.map(e => this.mapRQueueEntry(e));
    return model;
  }

  public mapRQueueEntry(dto: RQueueEntryDTO) {
    const model = new RQueueEntryModel();
    model.DisplayName = ScriptDisplayNames.get(dto.SN);
    model.EstimatedTime = dto.ET;
    model.FromNowcast = dto.FN;
    model.ForecastVersionId = dto.FId;
    model.ModelName = dto.MN;
    model.RRequestId = dto.RRequestId;
    model.ScriptName = dto.SN;
    model.Status = dto.S;
    model.PlaceInQueue = dto.PIQ;
    model.TimeUntilProcessing = dto.TUP;
    model.TryCount = dto.TC;
    model.ForecastVariableId = dto.FVId || undefined;
    model.ScenarioId = dto.SId;
    model.PercentDone = dto.PD;
    return model;
  }
}
