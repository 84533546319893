import { Injectable } from '@angular/core';
import { DataProvider } from '@core/constants/provider-definitions';
import { MacrobondService } from '../../store/providers/macrobond/macrobond.service';
import { HttpService } from '../http/http.service';
import { OpenIdFinalizeDTO } from './entities/open-id-finalize.dto';

@Injectable({
  providedIn: 'root'
})
export class OpenIdFrontendService {
  constructor(
    private http: HttpService,
    private macrobondService: MacrobondService
  ) { }

  public finalizeSetup(provider: DataProvider, dto: OpenIdFinalizeDTO) {
    return this.http.post<null>(`open-id/finalize/${provider}`, dto);
  }

  public setupOpenIdIntegration(provider: DataProvider) {
    return this.http.get<string>(`open-id/setup/${provider}`)
      .then(({ body }) => body);
  }

  public getScopes(provider: DataProvider) {
    return this.http.get<string[]>(`open-id/scopes/${provider}`)
      .then(({ body }) => body);
  }

  public disconnectProvider(provider: DataProvider) {
    return this.http.get<string>(`open-id/disconnect/${provider}`)
      .then(({ body }) => body);
  }

  public testConnection(provider: DataProvider) {
    switch (provider) {
      case DataProvider.macrobondapi:
        return this.macrobondService.testLoginDetails(true, true);
    }
  }
}
