type StorageType = 'localStorage' | 'sessionStorage';

export namespace StorageUtils {
  export function set(key: string, content: any, type: StorageType = 'localStorage') {
    window[type].setItem(key, JSON.stringify(content));
  }

  export function get<T = any>(key: string, type: StorageType = 'localStorage') {
    const item = window[type].getItem(key);
    return item && item !== 'undefined' && item !== 'null' ? JSON.parse(item) as T : null;
  }

  export function remove(key: string, type: StorageType = 'localStorage') {
    window[type].removeItem(key);
  }

  export function check(key: string, type: StorageType = 'localStorage') {
    return !!window[type].getItem(key);
  }
}
