import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { PlotValue, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { FileGeneratorService } from '@core/services/file-generator.service';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { DisplayFrontendService } from '@core/store/display/display.frontend.service';
import { VariableActions } from '@core/store/forecast-variable/forecast-variable.actions';
import { ForecastVariableAccessModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ScenarioViewAction } from '@modules/forecast/views/scenario/scenario.actions';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Periodicity } from '@modules/lang/types/periodicity';
import { Store } from '@ngxs/store';
import { getDiffName, getGrowthName } from '@shared/components/line-graph/alg-worker/get-transform-name';
import { AlgChartStyles, AlgOptions, StrokeSetups } from '@shared/components/line-graph/alg.options';
import { ChartDownloadSettingsModel } from '@shared/modals/data-download/chart-download/chart-download-settings.model';
import { OpenChartDownloadModal } from '@shared/modals/data-download/data-download.actions';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { PropertyValuePipe } from '@shared/modules/pipes';
import { DateFormatPipe } from '@shared/modules/pipes/date-format.pipe';
import { d3Utils } from '@shared/utils/d3/d3.utils';
import { DateUtils } from '@shared/utils/date.utils';
import { AlgExcelGenerator } from '@shared/utils/generators/xlsx/alg-data-xlsx.generator';
import { ValueUtils } from '@shared/utils/value.utils';
import * as d3 from 'd3';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DebugBoxHelper } from '../debug-box/debug-box.helper';
import { DragBarOptions } from '../dragbar/dragbar.options';
import { AlgInteractions, AlgInteractionsData } from './alg-models/alg-interactions';
import { ALGLineModel, ALGSingleSeriesModel, GraphData } from './alg-models/graph-data.model';
import { ALGTypes } from './alg-types';
import { GraphDataInput, getGraphData } from './alg-worker/get-graph-data';
import { getMinAndMaxDates } from './alg-worker/get-min-and-max-dates';
import { getMinAndMaxValue } from './alg-worker/get-min-and-max-value';
import { setupLineSegments } from './alg-worker/set-line-segments';
import { SvgConfig, SvgObjects, createSvgObjects } from './alg-worker/svg-manipulations/create-svg-objects';
import { SvgColors } from './alg-worker/svg-manipulations/svg-colors';
import { getCIPathGenerator, getLineGenerator } from './alg-worker/svg-manipulations/svg-generators';
import { SvgSizes } from './alg-worker/svg-manipulations/svg-sizes';
import { updateSvgObjects } from './alg-worker/svg-manipulations/update-svg-objects';
import { ALGActions } from './alg.actions';
import { ALGUtils } from './alg.utils';

@Component({
  selector: 'indicio-alg',
  templateUrl: './alg.component.html',
  styleUrls: ['./alg.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedLineGraphComponent implements OnChanges, OnDestroy, AfterViewInit {
  public noData = true;

  public downloadIcon = faDownload as IconProp;

  private subHelper = new Subscription();

  // SVG objects
  svgObjects: SvgObjects = new SvgObjects();
  svgSizes: SvgSizes = new SvgSizes();
  svgColors: SvgColors = new SvgColors();
  svgConfig: SvgConfig = new SvgConfig();

  // Alg specific components/helpers
  Interactions: AlgInteractions;

  // SVG properties
  _id = `alg-${Math.random().toString(36).substring(7)}`;
  instance: number = Math.floor((Math.random() * 100) + 1);

  // Data
  graphData: GraphData = new GraphData();
  updateInProgress: boolean;

  _chartStyles: AlgChartStyles = {
    backgroundColor: this.svgColors.ChartBackgroundColor,
    colorScheme: this.svgColors.ChartColorScheme,
    graphOverlay: this.svgColors.GraphOverlayColor,
    axisXline: this.svgColors.AxisXLineColor,
    axisYline: this.svgColors.AxisYLineColor,
    axisText: this.svgColors.AxisTextColor,
    historyLine: this.svgColors.HistoryLineColor,
    borderColor: this.svgColors.ChartBorderColor,
    borderSize: this.svgSizes.ChartBorderSize,
    borderRadius: this.svgSizes.ChartBorderRadius,
    marginRight: this.svgSizes.ChartMarginRight,
    plotHeight: this.svgSizes.PlotHeight,
    plotWidth: this.svgSizes.PlotWidth
  };

  transitionDuration = 0;
  initialized = false;
  optionsSet = false;

  // Date dragbar
  dragbarOpts: DragBarOptions<Date>;

  // Transformations
  transforms = ALGTypes.Transform;
  ALGDataType = ALGTypes.Data;

  // Transformation buttons
  rocYActive = false;
  showRolling12MBtn = false;
  showRolling4QBtn = false;
  showHistoricData = true;

  showXAxis = true;

  valuesCut = false;
  // Other
  periodicity: Periodicity = null;
  valuePipe: PropertyValuePipe = null;
  onDownloadCallback: Function = null;

  decoupledTransform = false;

  showOnlyHistoric = false;

  // Dates
  public showDatePickerBtn: boolean = false;
  private fromDateMoment: moment.Moment = null;
  private toDateMoment: moment.Moment = null;
  public fromDateMomentCopy: moment.Moment = null;
  public toDateMomentCopy: moment.Moment = null;
  private fromDate: Date = null;
  private toDate: Date = null;
  private fromDateCopy: Date = null;
  private toDateCopy: Date = null;
  public showDateResetBtn: boolean = false;

  disableFromUntil: Date = DateUtils.newDate('1980-01-01');
  disableFromSince: Date = DateUtils.newDate('2030-01-01');
  disableToUntil: Date = DateUtils.newDate('1980-01-01');
  disableToSince: Date = DateUtils.newDate('2030-01-01');
  allDates: moment.Moment[];
  allDatesUnix: number[];

  // Buttons
  showMenu: boolean = false; // Full menu will be hidden
  showDownloadBtn = true;
  showFittedDataBtn = false;
  showPastForecastBtn = true;
  showDecoupleBtn = false;
  showROCYYBtn = false;
  showROCBtn = true;
  showDiffBtn = true;
  showDiffyBtn = true;
  showIndicatorBtn = false;
  public showSeasonalAdjBtn = false;
  public showOutlierAdjBtn = false;

  forceActiveAggregatedBtn = false;
  resizeInProgress: boolean = false;
  afterViewInitComplete = false;
  dashedLineThickness = '3';


  get xScale() { return this.svgObjects.XScale; }
  get yScale() { return this.svgObjects.YScale; }
  get activateAggregatedBtn() { return this.graphData.HasAggregatedData; }
  get horizon() { return this.forecastVersion?.Horizon ?? 0; }
  public horizonSteps: number[];
  public get displayMenu() { return (!this.svgConfig.isMiniAlg || this.svgConfig.isMiniAlg && this.decoupledTransform) && this.showMenu; }

  @Input() showIndicators: boolean;
  @Input() Lines: ALGLineModel[] = [];
  @Input() HistoricLine: ALGLineModel;
  @Input() forecast: ForecastModel;
  @Input() forecastVersion: ForecastVersionModel = null;
  @Input() models: ALGSingleSeriesModel[] = [];
  @Input() assessments: AssessmentModel[] = [];
  @Input() watermark = false;
  @Input() header: string;
  @Input() subheader: string;
  @Input() options: AlgOptions.Options = AlgOptions.CreateOptions();
  @Input() graphType: ALGTypes.Graph;
  @Input() periodicityType: PeriodicityType = 'month';
  @Input() showExport: boolean = false;
  @Input()
  get chartStyles() { return this._chartStyles; }
  set chartStyles(styles: AlgChartStyles) {
    this._chartStyles = styles;
    this.updateStyles(styles);
  }
  @Input() showLoader: boolean = false;
  @Input()
  get userDataSettings() { return this.currentUserDataSettings; }
  set userDataSettings(settings) {
    this.showHistoricData = settings.showHistoricData;
    if (settings.roc) { this.svgConfig.algTransform = ALGTypes.Transform.roc; }
    if (settings.rocY) { this.svgConfig.algTransform = ALGTypes.Transform.rocy; }
    if (settings.diff) { this.svgConfig.algTransform = ALGTypes.Transform.diff; }
    if (settings.diffy) { this.svgConfig.algTransform = ALGTypes.Transform.diffy; }
    this.rocYActive = settings.rocYActive;
    this.svgConfig.ShowPastForecasts = settings.showPastForecasts;
    if (settings.aggregated) { this.svgConfig.algDataType = ALGTypes.Data.aggregated; }
    if (settings.seasonal) { this.svgConfig.algDataType = ALGTypes.Data.seasonal; }
    if (settings.outliers) { this.svgConfig.algDataType = ALGTypes.Data.outlier; }
  }
  @Input() ownerDetails: ForecastVariableAccessModel;
  get isOwner() { return this.ownerDetails?.IsOwner || !this.ownerDetails; }
  @Input() forecastVariableId?: string;
  @Output() emitDownload: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private el: ElementRef,
    private zone: NgZone,
    private store: Store,
    private actions: ActionService,
    private dateFormat: DateFormatPipe,
    private displaySettings: DisplayFrontendService,
    private env: EnvironmentService,
    private dialogs: DialogService,
    private generatorService: FileGeneratorService,
    private cd: ChangeDetectorRef
  ) {
    this.valuePipe = new PropertyValuePipe();
    this.setupActions();
  }

  ngAfterViewInit() {
    this.afterViewInitComplete = true;
    this.horizonSteps = Array.from(new Array(this.horizon)).map((x, i) => i + 1);
  }

  ngOnDestroy() {
    this.afterViewInitComplete = false;
    this.subHelper.unsubscribe();
  }

  // Update styles from chart download modal
  private updateStyles(styles) {
    this.svgColors.updateFromExternal(styles);
    this.svgSizes.updateFromExternal(styles);
  }

  @HostListener('window:resize', ['$event.target'])
  public onResize() {
    if (this.options.inModal) { return; }
    if (this.resizeInProgress) { return; }
    this.resizeInProgress = true;
    setTimeout(() => {
      this.resizeInProgress = false;
      this.zone.runOutsideAngular(() => {
        this.updateGraph();
      });
    }, 100);
  }

  @HostListener('document:click', ['$event.target'])
  public checkVisibility(target: HTMLElement) {
    if (target.classList.contains('menu__item')) {
      return;
    }
    const openMenus: HTMLElement[] = this.el.nativeElement.querySelectorAll('.line-graph__menu .active');
    if (openMenus.length) {
      openMenus.forEach(m => {
        if (m.hasAttribute('sticky')) { return; }
        m.classList.remove('active');
      });
    }
  }

  private get currentUserDataSettings(): ChartDownloadSettingsModel {
    return {
      showHistoricData: this.showHistoricData,
      roc: this.svgConfig.roc,
      rocY: this.svgConfig.rocY,
      diff: this.svgConfig.diff,
      diffy: this.svgConfig.diffy,
      rocYActive: this.rocYActive,
      isPercent: this.svgConfig.isPercent,
      showPastForecasts: this.svgConfig.ShowPastForecasts,
      seasonal: this.svgConfig.seasonal,
      aggregated: this.svgConfig.aggregated,
      outliers: this.svgConfig.outliers,
      showCIHover: this.svgConfig.ShowCIHover
    };
  }

  /**
   * This function is executed each time an input field is updated (or its reference changes)
   * @param changes An array of key-value pairs where the keys correspond to the changed input-fields
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (!!changes['showLoader'] && Object.keys(changes).length === 1) {
      return;
    }
    if (changes['options'] &&
      JSON.stringify(changes['options'].currentValue) !== JSON.stringify(changes['options'].previousValue)) {
      this.setOptions();
    }
    if (changes['chartStyles']) {
      this.updateStyles(changes['chartStyles']);
    }

    this.store.dispatch(new ALGActions.Update(this._id));
  }

  private setOptions() {
    // Margin left is set to zero for both inside and floating axis.
    this.svgSizes.GraphMarginLeft = this.options.axisConfig.yAxisPosition === 'outside' ? 45 : 0;
    if (this.options.menuConfig.dontShowDownloadBtn) { this.showDownloadBtn = false; }
    if (this.options.menuConfig.dontShowPastForecastBtn) { this.showPastForecastBtn = false; }
    if (this.options.menuConfig.showDecoupleBtn) { this.showDecoupleBtn = true; }
    if (this.options.menuConfig.showFittedDataBtn) { this.showFittedDataBtn = true; }
    if (this.options.menuConfig.showMenu) { this.showMenu = true; }
    if (this.options.menuConfig.showDatePickerBtn) { this.showDatePickerBtn = true; }
    if (this.options.menuConfig.showIndicatorBtn) { this.showIndicatorBtn = true; }
    if (this.options.menuConfig.showSeasonalAdjBtn) { this.showSeasonalAdjBtn = true; }
    if (this.options.menuConfig.showOutlierAdjBtn) { this.showOutlierAdjBtn = true; }
    if (this.options.menuConfig.forceActiveAggregatedBtn) { this.forceActiveAggregatedBtn = true; }
    if (this.options.closeCallback != null) { this.onDownloadCallback = this.options.closeCallback; }
    if (this.options.isPercent != null) { this.svgConfig.isPercent = this.options.isPercent; }
    if (this.options.miniGraph) { this.svgConfig.isMiniAlg = true; }
    if (this.options.dontShowHistoricData) { this.showHistoricData = false; }
    if (this.options.axisConfig.dontShowXAxis) { this.showXAxis = false; }
    this.svgConfig.ShowCIHover = !this.options.dontShowCIHover;
    if (this.options.showOnlyHistoric) { this.showOnlyHistoric = true; }
    if (this.options.hoverMinDistanceFromTop) {
      this.svgSizes.MinDistanceFromTop = parseInt(this.options.hoverMinDistanceFromTop, 10);
    }

    if (this.options.rocEnabled) {
      this.svgConfig.algTransform = ALGTypes.Transform.roc;
    }
    if (this.options.rocYYEnabled) {
      this.svgConfig.algTransform = ALGTypes.Transform.rocy;
    }
    if (this.options.aggregatedEnabled) {
      this.svgConfig.algDataType = ALGTypes.Data.aggregated;
    }
    if (this.options.outliersEnabled) {
      this.svgConfig.algDataType = ALGTypes.Data.outlier;
    }

    this.periodicity = this.env.getPeriodicity(this.periodicityType);
    this.showDiffyBtn = this.periodicity.is('month') || this.periodicity.is('quarter') || this.periodicity.is('halfyear');
    this.showROCYYBtn = this.periodicity.is('month') || this.periodicity.is('quarter') || this.periodicity.is('halfyear');
    this.showRolling12MBtn = this.periodicity.is('month');
    this.showRolling4QBtn = this.periodicity.is('quarter');

    this.decoupledTransform = !!this.forecastVersion ? this.displaySettings.settings.DecoupledTransform : false;

    this.svgConfig.algTransform = !!this.forecastVersion ? this.displaySettings.settings.ALGTransform : ALGTypes.Transform.original;
    this.svgConfig.algDataType = !!this.forecastVersion ? this.displaySettings.settings.ALGDataType : ALGTypes.Data.original;

    if (this.decoupledTransform && this.svgConfig.isMiniAlg) {
      this.svgConfig.algTransform = this.getVariableTransform();
      this.cd.detectChanges();
    }

    if (this.options.forceOriginalData) {
      this.svgConfig.algTransform = ALGTypes.Transform.original;
      this.svgConfig.algDataType = ALGTypes.Data.original;
    }

    this.rocYActive = this.periodicity.is('month') || this.periodicity.is('quarter') || this.periodicity.is('halfyear');
    if (!this.rocYActive) {
      if (this.svgConfig.rocY) {
        this.svgConfig.algTransform = ALGTypes.Transform.roc;
      } else if (this.svgConfig.diffy) {
        this.svgConfig.algTransform = ALGTypes.Transform.diff;
      }
    }

    if (!this.forecastVersion && !this.options.menuConfig.forceShowButtons) {
      this.showMenu = false;
    }

    this.setValidDateRange();
    this.optionsSet = true;
  }

  public exportResult() {
    this.store.dispatch(ALGActions.ExportResult);
  }

  /**
   * Needed for HTML template
   */
  public getGrowthName() { return getGrowthName(this.periodicity); }
  public getDiffName() { return getDiffName(this.periodicity); }


  public setPastType(type: number) {
    this.svgConfig.PastType = type;
    const tt = document.getElementsByClassName('past-forecast-select')?.item(0) as any;
    if (tt) tt.value = String(type);
    this.updateGraph();
  }

  public toggleShowIndicators() {
    this.store.dispatch(new ScenarioViewAction.ToggleIndicatorsView(!this.showIndicators));
  }

  public clickFittedData() {
    this.svgConfig.ShowFittedData = true;
    this.svgConfig.ShowPastForecasts = false;
    this.showHistoricData = true;
    this.updateGraph();
  }

  public clickShowOriginal() {
    this.svgConfig.ShowFittedData = false;
    this.svgConfig.ShowPastForecasts = false;
    this.updateGraph();
  }

  public clickPastForecasts() {
    this.svgConfig.ShowFittedData = false;
    this.svgConfig.ShowPastForecasts = true;
    this.showHistoricData = true;
    this.updateGraph();
  }

  public downloadData() {
    const emitDownloadTypes: ALGTypes.Graph[] = ['multivariate'];
    /**
     * Emit download event if the graph type is in the emitDownloadTypes array.
     * Make parent handle the download. This is to allow for custom download types to be implemented.
     * Useful where data from multiple graphs are needed to be downloaded.
     * Currently, only multivariate graphs are supported.
     */
    if (emitDownloadTypes.includes(this.graphType)) { return this.emitDownload.emit(); }

    this.generatorService.generateExcelFile(new AlgExcelGenerator({
      ForecastName: this.forecast?.Name || this.options.header,
      Interactions: this.Interactions,
      Periodicity: this.periodicity,
      DatePipe: this.dateFormat,
      PastSteps: this.svgConfig.PastType
    }).generate());
  }

  public downloadImage() {
    if (this.onDownloadCallback) { this.onDownloadCallback(); }
    this.store.dispatch(new OpenChartDownloadModal(
      this.forecast,
      this.forecastVersion,
      this.models,
      this.graphType,
      null,
      this.currentUserDataSettings,
      this.Lines,
      this.HistoricLine,
      this.periodicity.Value,
      this.fromDateMoment,
      this.toDateMoment,
      this.chartStyles,
      this.options
    ));
  }

  // Sort all the model-values into two different lists, historic ones and forecasted ones.
  public updateGraph() {
    if (this.noData) return;
    this.updateInProgress = true;
    if (this.HistoricLine?.Values?.length > 500 && this.graphType === 'variable') {
      this.HistoricLine.Values = this.HistoricLine.Values.slice(-500);
      this.valuesCut = true;
    } else {
      this.valuesCut = false;
    }

    const token = DebugBoxHelper.start();
    const originalData = this.getOriginalData();
    const data = getGraphData(originalData);
    token.log('ALG: getGraphData');
    this.setDateAndValueRange(originalData, data);
    this.svgColors = data.Colors;
    this.graphData = setupLineSegments({
      GraphData: data,
      FromDate: this.fromDate,
      ToDate: this.toDate,
      GraphDataInput: originalData
    });
    token.log('ALG: line seg');

    setTimeout(() => {
      const drawStart = performance.now();
      if (!this.afterViewInitComplete && !this.graphData.DataEmpty) { return; }
      this.ClearOldAndCreateNewSVGGraphObjects();
      if (this.graphData.DataEmpty) {
        this.addNoDataText();
        return;
      }
      this.addHistoricOverlay();
      this.addXAxis();
      this.addYAxis();
      if (this.graphData.ActiveModelCount > 0) {
        this.addForecastedData();
        this.addPastForecastData();
        this.addFittedData();
      }
      this.addLines();
      if (this.forecastVersion) {
        this.addAssessments();
      }
      this.addTransition();
      this.addHover();

      if (this.header) {
        this.addChartHeader();
      }
      if (this.watermark) {
        this.addWatermark();
      }
      this.updateInProgress = false;
      const drawEnd = performance.now();
      DebugBoxHelper.send('ALG: Draw', drawEnd - drawStart);
    }, 0);
    return;
  }

  private ClearOldAndCreateNewSVGGraphObjects() {
    if (this.svgObjects.SVG) {
      const elems: HTMLElement[] = this.el.nativeElement.querySelectorAll('svg.line-graph');
      elems.forEach(x => x.remove());
      this.svgObjects.SVG = null;
    }

    const svgObjects = createSvgObjects({
      SVGId: this._id,
      Instance: this.instance,
      Sizes: this.svgSizes,
      Colors: this.svgColors,
      Header: this.header,
    });

    this.svgColors = svgObjects.Colors;
    this.svgSizes = svgObjects.Sizes;
    this.svgObjects = svgObjects;

    this.attachSvg(svgObjects);
    this.initialized = svgObjects.Initialized;

    this.setSizesFromContainer();

    const updated = updateSvgObjects({
      SVGObjects: svgObjects,
      IsMiniAlg: this.svgConfig.isMiniAlg,
      Sizes: this.svgSizes,
      MaxX: this.graphData.MaxXValue,
      MinX: this.graphData.MinXValue,
      MinY: this.graphData.MinYValue,
      MaxY: this.graphData.MaxYValue,
      DateSelection: this.showDatePickerBtn
    });
    this.svgSizes = updated.Sizes;
  }

  private setSizesFromContainer() {
    const container = d3.select(this.el.nativeElement.children[0]);
    this.svgSizes.ContainerWidth = (container.node() as HTMLElement).clientWidth;
    this.svgSizes.ContainerHeight = (container.node() as HTMLElement).clientHeight;
  }

  private attachSvg(created: SvgObjects) {
    const container = d3.select(this.el.nativeElement.children[0]);
    const n = created.SVG.node();
    container.append(() => n);
  }

  private addWatermark() {
    let titleContainerHeight = 45;

    const titleNode = document.getElementById(this._id + '-title');
    if (titleNode?.getBoundingClientRect()?.height > 24) {
      titleContainerHeight += 24 * (Math.floor(titleNode.getBoundingClientRect().height / 24));
    }

    const leftMargin = this.options.axisConfig.yAxisPosition === 'inside' ? 80 : 40;

    this.svgObjects.SVG.append('svg:image')
      .attr('xlink:href', this.svgColors.ChartColorScheme === 'dark' ? '/assets/icons/indicio-logga-vit.svg' : '/assets/icons/indicio-logga-black.svg')
      .attr('x', this.svgSizes.getGraphWidth() - leftMargin + Number(this.svgSizes.ChartBorderSize))
      .attr('y', this.svgSizes.getGraphHeight() - 30 + Number(this.svgSizes.ChartBorderSize) + titleContainerHeight)
      .attr('height', 25)
      .attr('width', 80)
      .style('opacity', this.svgColors.ChartColorScheme === 'dark' ? 0.25 : 0.15);
  }

  private addChartHeader() {
    let height = 45;
    const xPos = this.options.axisConfig.yAxisPosition === 'inside' ? 0 : 45;

    const fobj = this.svgObjects.SVG.append('foreignObject')
      .attr('x', xPos)
      .attr('y', '0')
      .attr('width', 650)
      .attr('height', height);
    const titleText = fobj.append('xhtml:div')
      .attr('xmlns', 'http://www.w3.org/1999/xhtml')
      .text(this.header)
      .attr('id', this._id + '-title')
      .style('font-size', '20px')
      .style('font-family', 'Roboto')
      .style('font-weight', '500')
      .style('fill', 'black')
      .attr('contenteditable', true);

    if ((<HTMLElement> titleText.node())?.getBoundingClientRect()?.height > 24) {
      height += 24 * (Math.floor((<HTMLElement> titleText.node()).getBoundingClientRect().height / 24));
      fobj.attr('height', height);
      this.svgObjects.SVGGroup.attr('transform', `translate(0, ${height})`);
    }

    fobj.append('xhtml:div')
      .attr('xmlns', 'http://www.w3.org/1999/xhtml')
      .text(this.subheader)
      .style('font-size', '14px')
      .style('font-family', 'Roboto')
      .style('font-weight', '400')
      .style('fill', 'black')
      .attr('contenteditable', true)
      .style('margin-top', '3px;');

    this.svgObjects.HoverTarget.attr('y', function () {
      return ALGUtils.getBoundingBox(d3.select(this)).y + height;
    });
    // Only check for the y pos if a border is set.
    if (this.svgObjects.Border) {
      this.svgObjects.Border.attr('y', function () {
        return ALGUtils.getBoundingBox(d3.select(this)).y + height;
      });
    }
  }

  private addAssessments() {
    if (!this.assessments.some(x => x.Active)) { return; }

    this.graphData.GroupedAssessments.forEach(groupedAssessment => {
      const date = groupedAssessment.moment.toDate();
      const value = groupedAssessment.newValue;

      this.svgObjects.AssessmentGroup = this.svgObjects.GraphDataGroup.append('g')
        .attr('class', 'assessment-group')
        .attr('clip-path', 'url(#rect-clip-' + this.instance + ')')
        .attr('opacity', 1);

      this.svgObjects.AssessmentGroup.append('circle')
        .attr('r', 5).attr('class', 'assessment-circle')
        .attr('cx', this.xScale(date))
        .attr('cy', this.yScale(value));
    });
  }

  private addXAxis() {
    if (!this.showXAxis) { return; }
    const tickCount = 6;
    const fromUnix = this.graphData.MinXValue.getTime();
    const toUnix = this.graphData.MaxXValue.getTime();
    const activeDates = this.allDatesUnix.filter(x => x >= fromUnix && x <= toUnix);
    const dateCount = activeDates.length;
    const stepLen = (dateCount - 1) / (tickCount - 1);
    const tickSteps = [...Array(tickCount - 1).keys()];
    const tickValues = dateCount < tickCount
      ? activeDates
      : [...tickSteps.map(step => activeDates[Math.round(step * stepLen)]), activeDates.last()];

    const graphHeight = this.svgSizes.getGraphHeight();
    const axis = d3.axisBottom(this.xScale)
      .tickValues(tickValues.map(ts => new Date(ts)))
      .tickFormat(d => this.dateFormat.formatWithLimit(<Date> d, this.periodicity.Value, true))
      .tickSize(-graphHeight)
      .tickPadding(11);

    this.svgObjects.GraphDataGroup.append('g')
      .attr('transform', 'translate(0,' + graphHeight + ')')
      .attr('class', 'x axis removeStroke')
      .call(axis);

    this.svgObjects.GraphDataGroup.selectAll('.x.axis .tick line').style('stroke', this.svgColors.AxisXLineColor);
    this.svgObjects.GraphDataGroup.selectAll('.x.axis .tick text').style('fill', this.svgColors.AxisTextColor).attr('class', 'alg-text');
    // Move the first and last tick-texts and remove their vertical lines.
    // They are on the left and right border of the graph and should not show.
    this.svgObjects.GraphDataGroup.selectAll('.x.axis .tick').each(function (_, i) {
      if (i !== 0 && i !== tickCount - 1) { return; }

      const elem = d3.select(this);
      if (i === 0) { elem.style('text-anchor', 'start'); }
      if (i === tickCount - 1) { elem.style('text-anchor', 'end'); }
      elem.select('line').style('display', 'none');
    });

    if (this.options.axisConfig.xAxisTextColor) {
      this.svgObjects.GraphDataGroup.selectAll('.x.axis .tick text').style('fill', this.options.axisConfig.xAxisTextColor);
    }
  }

  private addYAxis() {
    const self = this;
    this.svgObjects.GraphDataGroup.append('g')
      .attr('transform', 'translate(0, 0)')
      .attr('class', 'y axis removeStroke')
      .call(d3.axisLeft(this.yScale)
        .ticks(4)
        .tickSize(-this.svgSizes.getGraphWidth())
        .tickPadding(15 - (this.svgConfig.isMiniAlg ? 9 : 0))
        .tickFormat(f => {
          if (this.svgConfig.isRocType || this.svgConfig.isPercent) {
            return this.valuePipe.toPercent(f);
          } else {
            return ValueUtils.abbreviateNum(f, 2);
          }
        }));

    const lines = this.svgObjects.GraphDataGroup.selectAll('.y.axis .tick line');
    const texts = this.svgObjects.GraphDataGroup.selectAll('.y.axis .tick text');
    lines.style(this.options.axisConfig.yAxisLine.Type, this.options.axisConfig.yAxisLine.Opt).style('stroke-width', this.options.axisConfig.yAxisLine.W);
    lines.style('stroke', this.svgColors.AxisYLineColor);
    texts.style('fill', this.svgColors.AxisTextColor).attr('class', 'alg-text');
    if (this.options.axisConfig.yAxisTextColor) {
      texts.style('fill', this.options.axisConfig.yAxisTextColor);
    }
    if (this.options.axisConfig.yAxisPosition === 'inside') {
      // TODO: translation here will need a new option if used in a mini-alg.
      texts.style('text-anchor', 'start').attr('transform', 'translate(25,-10)');
      // Hide any text outside the graph
      texts.each(function () {
        const text = d3.select(this);
        const tick = d3.select((text.node() as any).parentNode);
        const box = ALGUtils.getBoundingBox(tick);
        const [_, y] = d3Utils.getTranslation(tick);
        if (self.svgSizes.ChartBorderSize + box.height > +y) {
          text.attr('display', 'none');
        }
      });
    }
  }

  private addNoDataText() {
    if (this.showLoader) { return; }
    const text = this.options.noDataText;
    const textWidth = this.svgConfig.isMiniAlg ? 107 : 232;
    const x = (this.svgSizes.getGraphWidth() - textWidth) / 2 + this.svgSizes.GraphMarginLeft;
    this.svgObjects.SVG.append('text')
      .attr('x', x + 'px')
      .attr('y', '48%')
      .style('font-size', '30px')
      .style('font-family', 'Roboto')
      .style('font-weight', '700')
      .style('fill', 'rgba(0,0,0,0.2)')
      .text(text);
  }

  private addTransition() {
    const toSet = Math.max(this.showHistoricData ? this.svgConfig.HistoricCutoffPosition : 0, 0);
    this.svgObjects.ClipPath.attr('width', toSet);
    this.svgObjects.ClipPath.transition().attr('width', this.svgSizes.getGraphWidth()).duration(this.transitionDuration);
  }

  private getInteractionsData(): AlgInteractionsData {
    return {
      projectId: this.forecast?.ProjectId,
      forecastId: this.forecast?.ForecastId,
      fVersionId: this.forecastVersion?.ForecastVersionId,
      fVarId: this.forecastVariableId,
      svgObjs: this.svgObjects,
      instance: this.instance,
      graphData: this.graphData,
      periodicity: this.periodicity,
      datePipe: this.dateFormat,
      graphType: this.graphType,
      config: this.svgConfig,
      horizon: this.horizon,
    };
  }

  private lastXYPos: { X: number, Y: number; } = null;
  private addHover() {
    this.Interactions = new AlgInteractions(this.store, this.dialogs, this.getInteractionsData());
    this.Interactions.hideHover();
    const self = this;
    const getPos = (t: SVGRectElement, event: any): ALGTypes.MousePos => ({
      X: self.svgSizes.mouseXToGraphX(d3.pointer(event, t)[0]),
      Y: self.svgSizes.mouseYToGraphY(d3.pointer(event, t)[1])
    });

    this.zone.runOutsideAngular(() => {
      let target = d3.select(`#svg-graph-${self._id} .hover-target`);
      if (this.options.menuConfig.showDatePickerBtn) {
        target = this.addDateSelection(target);
      }
      target.on('click', (event, d) => {
        self.Interactions.click(getPos(<SVGRectElement> d, event));
      })
        .on('mouseout', () => { this.Interactions.hideHover(); })
        .on('mousemove', (event, dhis) => {
          const start = performance.now();
          /* New data is being loaded, do nothing */
          if (self.updateInProgress) { return; }
          /* Get mouse positions */
          const pos = getPos(<SVGRectElement> dhis, event);
          /* Calculate distance from the last move - if the distance is below 3 pixels we do nothing. */
          const d = Math.sqrt(Math.pow(pos.X - (self.lastXYPos?.X || 0), 2) + Math.pow(pos.Y - (self.lastXYPos?.Y || 0), 2));
          if (!!self.lastXYPos && d < 3) { return; }
          self.lastXYPos = { X: pos.X, Y: pos.Y };
          /* Determine if we are outside the main graph area (we have some elements outside).
             If so, deactivate the hover. */
          self.Interactions.showHover();
          self.Interactions.moveHover(pos);
          const end = performance.now();
          DebugBoxHelper.send('ALG Hover', end - start);
        })
        .on('wheel', function (event: any) {
          if (!self.options.menuConfig.showDatePickerBtn) { return; }
          const direction = event.deltaY < 0 ? 1 : -1;
          self.setDateRangeFromScroll(direction);
          event.preventDefault();
        });
    });
  }

  private addDateSelection(elem: d3.Selection<d3.BaseType, unknown, HTMLElement, undefined>) {
    let startPos: number;
    const self = this;
    const brush = d3.brushX()
      .extent([[this.svgSizes.ChartBorderSize, this.svgSizes.ChartBorderSize], [this.svgSizes.getSvgWidth(), this.svgSizes.getSvgHeight() - this.svgSizes.GraphMarginBottom]])
      .on('start', (event: any) => {
        if (!event.selection) { return; }
        startPos = event.selection[0];
        this.Interactions.hideHoverBox();
      })
      .on('brush', function (event) {
        self.Interactions.hideHover();
        if (event.selection[0] < startPos) self.Interactions.moveHover({ X: event.selection[0], Y: event.selection[1] });
        self.Interactions.showHover();
      })
      .on('end', (event: any) => {
        this.Interactions.showHoverBox();
        if (!event.selection) { return; }
        const [x1, x2] = event.selection as number[];

        let direction: -1 | 1 = x1 < startPos ? -1 : 1;
        const from = moment(this.xScale.invert(x1));
        const to = moment(this.xScale.invert(x2));
        this.setDatesFromSelection(direction, from, to);
      });
    return elem.call(brush);
  }

  private addHistoricOverlay() {
    /* Add the historic background */
    this.svgObjects.GraphDataGroup.append('path')
      .attr('class', 'graph-overlay')
      .attr('data-test-id', 'graph')
      .attr('clip-path', 'url(#rect-clip-' + this.instance + ')')
      .style('fill', this.svgColors.GraphOverlayColor);

    this.svgConfig.CutoffDate = this.graphData.HistoricLine.Values.last().m;
    this.svgConfig.HistoricCutoffPosition = this.xScale(this.svgConfig.CutoffDate);
    const innerRadius = this.svgSizes.getInnerRadius();
    const context = d3.path();
    context.moveTo(this.svgConfig.HistoricCutoffPosition, 0);
    context.lineTo(this.svgConfig.HistoricCutoffPosition, this.svgSizes.getGraphHeight());
    context.lineTo(innerRadius, this.svgSizes.getGraphHeight());
    context.arcTo(0, this.svgSizes.getGraphHeight(), 0, this.svgSizes.getGraphHeight() - innerRadius, innerRadius);
    context.lineTo(0, innerRadius);
    context.arcTo(0, 0, innerRadius, 0, innerRadius);
    context.closePath();
    this.svgObjects.GraphDataGroup.select('.graph-overlay').attr('d', context.toString());
  }

  private addPastForecastData() {
    if (!this.svgConfig.ShowPastForecasts || this.svgConfig.seasonal || this.svgConfig.outliers) { return; }

    const lineGenerator = getLineGenerator<SimplePlotValue>('D', 'V', this.xScale, this.yScale);
    this.graphData.PastForecasts.forEach(pastInfo => {
      pastInfo.Segments.forEach((segments, i) => {
        const color = pastInfo.Type === 0 ? this.svgColors.getPastColor(i) : pastInfo.Color;
        this.drawLine(segments, lineGenerator, color, color, `.past-forecast-line-${i + 1}`, pastInfo.Type !== 0 ? StrokeSetups.Result : null);
      });
    });
  }

  private addLines() {
    const lineGenerator = getLineGenerator<PlotValue | SimplePlotValue>('D', 'V', this.xScale, this.yScale);
    this.svgObjects.GraphDataGroup.selectAll('.permanent-line').remove();
    this.graphData.Lines.filter(line => !!line && line.Active).forEach(line => {
      const color = (line.Color && !line.IsHistoric ? line.Color : this.svgColors.HistoryLineColor);
      const dashedColor = color;
      this.drawLine(line.Segments, lineGenerator, color, dashedColor, 'permanent-line', line.Stroke, line.Stroke.W);
    });
  }

  private addForecastedData() {
    if (this.graphData.ShowCIIntervals) {
      const area50 = getCIPathGenerator<PlotValue>('I50', 'A50', this.xScale, this.yScale);
      const area75 = getCIPathGenerator<PlotValue>('I75', 'A75', this.xScale, this.yScale);
      const area95 = getCIPathGenerator<PlotValue>('I95', 'A95', this.xScale, this.yScale);
      const ci = this.svgObjects.GraphDataGroup.append('g')
        .attr('class', 'ci-group')
        .attr('opacity', 1);
      ci.append('path')
        .attr('class', 'area p95')
        .attr('d', area95(this.graphData.Forecasted[0].Values))
        .attr('clip-path', 'url(#rect-clip-' + this.instance + ')');
      ci.append('path')
        .attr('class', 'area p75')
        .attr('d', area75(this.graphData.Forecasted[0].Values))
        .attr('clip-path', 'url(#rect-clip-' + this.instance + ')');
      ci.append('path')
        .attr('class', 'area p50')
        .attr('d', area50(this.graphData.Forecasted[0].Values))
        .attr('clip-path', 'url(#rect-clip-' + this.instance + ')');
    }

    const lineGenerator = getLineGenerator<PlotValue | SimplePlotValue>('D', 'V', this.xScale, this.yScale);
    this.svgObjects.GraphDataGroup.selectAll('.forecasted-model').remove();
    this.graphData.Forecasted.forEach(forecasted => {
      const color = this.graphData.ShowCIIntervals ? 'white' : (forecasted.Color || 'white');
      this.drawLine(forecasted.Segments, lineGenerator, color, color, 'forecasted-model');
    });
  }

  private addFittedData() {
    const fieldName = this.graphType === 'summary' ? 'WF' : 'F';
    const lineGenerator = getLineGenerator<PlotValue | SimplePlotValue>('D', fieldName, this.xScale, this.yScale);
    this.svgObjects.GraphDataGroup.selectAll('.fitted-model').remove();
    if (!this.svgConfig.ShowFittedData) { return; }
    this.graphData.Fitted.forEach(fitted => {
      this.drawLine(fitted.Segments, lineGenerator, fitted.Color, fitted.Color, 'fitted-model');
    });
  }

  private drawLine(segments: ALGTypes.LineSegment[], lineGenerator, color, dashedColor, className, stroke = null, width = '2px') {
    segments.forEach((seg) => {
      const path = this.svgObjects.GraphDataGroup
        .append('g')
        .attr('class', className)
        .append('path')
        .attr('class', 'line')
        .attr('d', lineGenerator(seg.Values))
        .attr('clip-path', 'url(#rect-clip-' + this.instance + ')')
        .style('stroke', !seg.isDashed ? color : dashedColor)
        .style('stroke-width', width);
      if (seg.isDashed) {
        path.style('stroke-dasharray', this.dashedLineThickness);
        path.style('opacity', 0.5);
      }
      if (stroke !== null) {
        path.style(stroke.Type, stroke.Opt);
      }
    });
  }

  public toggleMenu(event, date?: boolean) {
    const target: HTMLElement = event.target;
    const openMenus: HTMLElement[] = this.el.nativeElement.querySelectorAll('.line-graph__menu .active');
    const isMenu = target.classList.contains('menu__item');
    const withinMenu = target.closest('.menu__item');
    let menu: Element;

    if (isMenu) {
      menu = target;
    } else if (!!withinMenu) {
      menu = withinMenu;
    }

    if (openMenus.length) {
      openMenus.forEach(m => {
        if (m.isSameNode(target) || m.contains(target)) { return; }
        m.classList.remove('active');
      });
    }

    if (!!!menu) { return; }

    if (menu.classList.contains('active') && !date) {
      menu.classList.remove('active');

    } else if (menu.classList.contains('active') && date) {
      if (menu.contains(target) && target.nodeName !== 'BUTTON' && !['icon', 'ion-calendar'].some(x => target.classList.contains(x))) { return; }
      menu.classList.remove('active');
      this.resetDates();
    } else {
      menu.classList.add('active');
    }

    if (menu.classList.contains('active')) {
      const content: HTMLElement = menu.querySelector('.item__content');
      const menuDimensions = content.getBoundingClientRect();
      const menuEnd = menuDimensions.x + menuDimensions.width;
      const graphDimensions = this.el.nativeElement.getBoundingClientRect();
      const graphEnd = graphDimensions.x + (this.svgSizes.ContainerWidth || graphDimensions.width);
      if (graphEnd < menuEnd) {
        content.style.left = 'unset';
        content.style.right = '0px';
      }
    }
  }

  private resetDates() {
    this.fromDateCopy = this.fromDate;
    this.toDateCopy = this.toDate;
    this.fromDateMomentCopy = this.fromDateMoment;
    this.toDateMomentCopy = this.toDateMoment;
    this.dragbarOpts.StartIndex = null;
    this.dragbarOpts.EndIndex = null;
    this.dragbarOpts.StartValue = this.fromDate;
    this.dragbarOpts.EndValue = this.toDate;
  }

  private setDateAndValueRange(getDataInput: GraphDataInput, data: GraphData) {
    if (this.noData || !data || data.DataEmpty) return;
    const summary = this.graphType === 'summary';
    const confidenceIntervals = !(getDataInput.SvgConfig.isRocType || !data.ShowCIIntervals);
    if (this.graphType !== 'variable' && !this.svgConfig.isMiniAlg) {
      const unit = this.periodicity.getMomentDurationConstructor();
      const firstPossibleHistoricPoint = moment.min(data.HistoricLine.Values.map(x => x.m).concat(...data.Lines.map(l => l.Values.map(v => v.m))));
      if (firstPossibleHistoricPoint.isAfter(this.fromDateMoment, DateUtils.getMomentPeriod(this.periodicity.Value))) {
        data.MinXValue = firstPossibleHistoricPoint.toDate();
      } else {
        data.MinXValue = this.fromDateMoment.toDate();
      }
      data.MaxXValue = this.toDateMoment.toDate();
      const fromDate = DateUtils.alignJsDate(DateUtils.newMoment(data.MinXValue), this.periodicity.Value);
      const toDate = DateUtils.newMoment(data.MaxXValue).endOf(unit).toDate();

      [data.MinYValue, data.MaxYValue] = getMinAndMaxValue(data, summary, confidenceIntervals, getDataInput.ShowHistoricData, getDataInput.SvgConfig.ShowFittedData, getDataInput.SvgConfig.showPastForecasts, fromDate, toDate);
    } else {
      [data.MinYValue, data.MaxYValue] = getMinAndMaxValue(data, summary, confidenceIntervals, getDataInput.ShowHistoricData, getDataInput.SvgConfig.ShowFittedData, getDataInput.SvgConfig.showPastForecasts);
      if (!data.DataEmpty) {
        const { dateMin, dateMax } = getMinAndMaxDates(getDataInput.ShowHistoricData, data);
        data.MinXValue = dateMin;
        data.MaxXValue = dateMax;
        this.fromDateMoment = DateUtils.newMoment(dateMin);
        this.fromDateMomentCopy = DateUtils.newMoment(dateMin);
        this.fromDate = dateMin;
        this.toDateMoment = DateUtils.newMoment(dateMax);
        this.toDateMomentCopy = DateUtils.newMoment(dateMax);
        this.toDate = dateMax;
      }
    }
  }

  /*
   *
   * Date helpers
   *
   */
  public setDatesFromSelection(direction: -1 | 1, startDate?: moment.Moment, endDate?: moment.Moment) {
    let firstDateIndex: number;
    let lastDateIndex: number;
    // Select backwards / Zoom out
    if (direction === -1) {
      firstDateIndex = 0;
      lastDateIndex = this.allDates.length - 1;
      // Select forwards / Zoom in
    } else {
      firstDateIndex = DateUtils.bisectDateIndex(startDate, this.allDates);
      lastDateIndex = DateUtils.bisectDateIndex(endDate, this.allDates);

      // Modify indexes to always show at least minPointsToShow points.
      let points = () => lastDateIndex - firstDateIndex;
      while (firstDateIndex > 0 && points() < this.options.minPointsToShow) {
        firstDateIndex--;
      }
      while (lastDateIndex < this.allDates.length - 1 && points() < this.options.minPointsToShow) {
        lastDateIndex++;
      }
    }
    this.setDatesFromIndexes(firstDateIndex, lastDateIndex);
  }

  private setDateRangeFromScroll(direction: -1 | 1) {
    const unit = this.periodicity.getMomentDurationConstructor();
    const firstDateIndex = this.allDates.findIndexFromEnd(x => x.isSame(this.fromDateMoment, unit));
    const dates = this.allDates.length - 1;
    const tickSize = Math.min(Math.max(Math.floor(dates / 10), 5), 30);
    let newFirstDateIndex: number;

    if (direction === -1) {
      // Zoom out by tickSize
      newFirstDateIndex = Math.max(0, firstDateIndex - tickSize);
    } else {
      // Zoom in by tickSize
      newFirstDateIndex = Math.min(firstDateIndex + tickSize, dates - this.options.minPointsToShow);
    }

    this.setDatesFromIndexes(newFirstDateIndex, dates);
  }

  public resetDateRange() {
    const unit = this.periodicity.getMomentDurationConstructor();
    const lastDateIndex = this.allDates.findIndexFromEnd(x => x.isSame(this.options.toDate || this.allDates.last(), unit));
    const pointsToShow = Math.min(this.options.pointsToShow, this.allDates.slice(0, lastDateIndex).length);
    const firstDateIndex = !!this.options.fromDate ? this.allDates.findIndexFromEnd(x => x.isSame(this.options.fromDate, unit)) : lastDateIndex - pointsToShow;
    this.setDatesFromIndexes(firstDateIndex, lastDateIndex);
  }

  private setDatesFromIndexes(firstDateIndex: number, lastDateIndex: number) {
    this.fromDateMoment = DateUtils.newMoment(this.allDates[firstDateIndex]);
    this.fromDateMomentCopy = DateUtils.newMoment(this.allDates[firstDateIndex]);
    this.fromDate = this.fromDateMomentCopy.toDate();
    this.fromDateCopy = this.fromDateMomentCopy.toDate();
    this.toDateMoment = DateUtils.newMoment(this.allDates[lastDateIndex]);
    this.toDateMomentCopy = DateUtils.newMoment(this.allDates[lastDateIndex]);
    this.toDate = this.toDateMomentCopy.toDate();
    this.toDateCopy = this.toDateMomentCopy.toDate();

    this.showDateResetBtn = firstDateIndex !== this.allDates.length - 1 - this.options.pointsToShow;

    this.updateGraph();
    this.cd.detectChanges();

    // P*cker
    if (!this.showDatePickerBtn) { return; }
    this.dragbarOpts.StartIndex = firstDateIndex;
    this.dragbarOpts.EndIndex = lastDateIndex;
    this.dragbarOpts = { ...this.dragbarOpts };
  }

  private setValidDateRange() {
    if (this.options.dates.length === 0) {
      console.error('Alg got empty date-array.');
      return;
    }
    this.noData = false;

    const unit = this.periodicity.getMomentDurationConstructor();

    this.allDates = this.options.dates;
    this.allDatesUnix = this.allDates.map(m => m.unix() * 1000);
    let lastDate = this.options.toDate || this.allDates.last();
    const lastDateIndex = this.allDates.findIndexFromEnd(x => x.isSame(lastDate, unit));
    const pointsToShow = Math.min(this.options.pointsToShow, this.allDates.slice(0, lastDateIndex).length);
    let firstDate = this.options.fromDate || this.allDates[lastDateIndex - pointsToShow];

    firstDate = DateUtils.alignDate(firstDate.startOf(unit), this.periodicityType);

    const firstPossibleDate = this.allDates[0];
    // From
    this.fromDateMoment = firstDate;
    this.fromDate = this.fromDateMoment.toDate();
    // To
    this.toDateMoment = lastDate;
    this.toDate = this.toDateMoment.toDate();

    // P*cker
    if (!this.showDatePickerBtn) { return; }
    this.fromDateCopy = DateUtils.newDate(this.fromDate);
    this.fromDateMomentCopy = this.fromDateMoment.clone();
    this.toDateMomentCopy = this.toDateMoment.clone();
    this.toDateCopy = DateUtils.newDate(this.toDate);

    this.disableFromSince = this.toDateMoment.clone().subtract(1, unit).toDate();
    this.disableFromUntil = firstPossibleDate.toDate();
    this.disableToSince = this.toDateMoment.toDate();
    this.disableToUntil = DateUtils.nextPeriod(firstPossibleDate.clone(), this.periodicity).toDate();;
    this.setDragbarOpts();
  }

  setDragbarOpts() {
    this.dragbarOpts = new DragBarOptions();
    const dates = this.allDates.map(x => x.toDate());
    this.dragbarOpts = {
      ScaleType: 'Time',
      Values: dates,
      StartValue: this.fromDateMoment.toDate(),
      EndValue: this.toDateMoment.toDate()
    };
  }

  public setDatePicker($event: moment.Moment, direction: 'start' | 'end') {
    const unit = this.periodicity.getMomentDurationConstructor();
    const date = $event.toDate();
    const dateIdx = this.dragbarOpts.Values.findIndex(x => x.getTime() === date.getTime());
    this.setDateDragbar(dateIdx, direction);
    this.dragbarOpts = { ...this.dragbarOpts };
  }

  public setDateDragbar($event: number, direction: 'start' | 'end') {
    switch (direction) {
      case 'start': {
        this.fromDateMomentCopy = this.allDates[$event];
        this.fromDateCopy = this.fromDateMomentCopy.toDate();
        this.dragbarOpts.StartIndex = $event;
        break;
      }
      case 'end': {
        this.toDateMomentCopy = this.allDates[$event];
        this.toDateCopy = this.toDateMomentCopy.toDate();
        this.dragbarOpts.EndIndex = $event;
        break;
      }
    }
  }

  public applyDateRanges($event) {
    // close menu
    const menu = document.querySelector('.menu__item[sticky]');
    menu.classList.remove('active');
    this.fromDate = this.fromDateCopy;
    this.toDate = this.toDateCopy;
    this.fromDateMoment = this.fromDateMomentCopy.clone();
    this.toDateMoment = this.toDateMomentCopy.clone();
    this.updateGraph();
    this.toggleMenu($event, true);
    this.showDateResetBtn = true;
    this.cd.detectChanges();
  }

  /**
   *
   * Name helpers
   *
   */
  public getTransformName() { return ALGTypes.getTransformName(this.svgConfig.algTransform, this.periodicity); }
  public getDataName() { return ALGTypes.getDataName(this.svgConfig.algDataType); }
  public getDataTypeName() {
    if (this.svgConfig.ShowFittedData) { return 'Fitted values'; }
    if (this.svgConfig.ShowPastForecasts) { return 'Past forecasts'; }
    return 'Original';
  }

  /**
   *
   * Action setup
   *
   */
  private setupActions() {
    this.subHelper.add(this.actions.dispatched(ALGActions.SetTransformation).subscribe((action: ALGActions.SetTransformation) => this.onSetTransform(action)));
    this.subHelper.add(this.actions.dispatched(ALGActions.SetDataType).subscribe((action: ALGActions.SetDataType) => this.onSetDataType(action)));
    this.subHelper.add(this.actions.dispatched(ALGActions.SetDecoupledTransform).subscribe((action: ALGActions.SetDecoupledTransform) => this.onSetDecoupled(action)));
    this.subHelper.add(this.actions.dispatched(ALGActions.Update).subscribe((action: ALGActions.Update) => {
      if (action.graphId && action.graphId !== this._id || !this.optionsSet) { return; }
      this.updateGraph();
    }));
    this.subHelper.add(this.actions.dispatched(ALGActions.ClickFirstForecastPoint).subscribe((_: ALGActions.ClickFirstForecastPoint) => {
      if (this.svgConfig.isMiniAlg || !this.forecastVersion || !this.graphData.Dates.length) { return; }
      const modelDate = DateUtils.bisectDate(moment(this.forecastVersion.StartDate), this.graphData.Dates);
      this.Interactions.clickDate(modelDate);
    }));
  }

  /**
   *
   * Action dispatchers
   *
   **/
  public dispatchTransformChange(toSet: ALGTypes.Transform, saveTransform: boolean = true) {
    if (this.graphType === 'generic') {
      return this.store.dispatch(new ALGActions.SetTransformation(toSet, null, null, false));
    }
    this.store.dispatch(new ALGActions.SetTransformation(toSet, this.forecastVersion.ForecastVersionId, this.forecastVariableId, saveTransform));
  }

  public dispatchDataTypeChange(toSet: ALGTypes.Data) {
    this.store.dispatch(new ALGActions.SetDataType(toSet, this.forecastVersion.ForecastVersionId));
  }

  public dispatchDecoupledChange(toSet: boolean) {
    this.store.dispatch(new ALGActions.SetDecoupledTransform(toSet, this.forecastVersion.ForecastVersionId));
  }


  /**
   *
   *
   * Action handlers
   *
   */
  private onSetDataType(action: ALGActions.SetDataType) {
    const oldType = this.svgConfig.algDataType;
    this.svgConfig.algDataType = action.type;
    if (!this.svgConfig.isMiniAlg) {
      this.displaySettings.setSetting({ ALGDataType: action.type });
    }
    if (oldType !== this.svgConfig.algDataType && this.graphType !== 'summary') {
      this.store.dispatch(new ALGActions.Update(this._id));
    }
  }

  private onSetTransform(action: ALGActions.SetTransformation) {
    if (this.graphType !== 'generic' && this.decoupledTransform && this.forecastVariableId !== action.fVariableId) {
      return;
    }

    const oldTrans = this.svgConfig.algTransform;
    this.svgConfig.algTransform = action.type;
    if (!this.svgConfig.isMiniAlg) {
      this.displaySettings.setSetting({ ALGTransform: action.type });
    }

    if (this.decoupledTransform && action.saveTransform) {
      this.store.dispatch(new VariableActions.SaveTransformation(this.forecastVariableId, this.graphType, this.svgConfig.algTransform));
    }

    if (oldTrans !== this.svgConfig.algTransform) {
      this.store.dispatch(new ALGActions.Update(this._id));
    }
  }

  public setTransform(transform: ALGTypes.Transform) {
    this.svgConfig.algTransform = transform;
    this.store.dispatch(new ALGActions.Update(this._id));
  }

  private onSetDecoupled(action: ALGActions.SetDecoupledTransform) {
    this.decoupledTransform = action.state;
    if (!this.svgConfig.isMiniAlg) {
      this.displaySettings.setSetting({ DecoupledTransform: action.state });
      if (!this.decoupledTransform) {
        this.dispatchTransformChange(this.svgConfig.algTransform);
      }
    } else if (this.decoupledTransform && this.svgConfig.isMiniAlg) {
      this.dispatchTransformChange(this.getVariableTransform() || this.svgConfig.algTransform, false);
    }
  }

  private getVariableTransform() {
    const variable = this.forecastVersion.getVariableById(this.forecastVariableId);
    switch (this.graphType) {
      case 'multivariate':
        return variable?.MultivariateTransform || this.svgConfig.algTransform;
      case 'scenario':
        return variable?.ScenarioTransform || this.svgConfig.algTransform;
      default:
        return this.svgConfig.algTransform;
    }
  }

  private getOriginalData() {
    return <GraphDataInput> {
      Assessments: this.assessments,
      Lines: this.Lines,
      HistoricLine: this.HistoricLine,
      SvgConfig: this.svgConfig,
      ShowHistoricData: this.showHistoricData,
      Models: this.models,
      Periodicity: this.periodicity.Value,
      GraphType: this.graphType,
      Colors: this.svgColors,
      ForceAggregatedBtn: this.forceActiveAggregatedBtn,
      ShowOnlyHistoric: this.showOnlyHistoric,
    };
  }
}
