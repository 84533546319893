import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { WikiActions } from '../../wiki.actions';
import { WikiPageDTO, WikiParent } from '../../wiki.entities';
import { WikiService } from '../../wiki.service';

@Component({
  selector: 'indicio-wiki-snackbar',
  templateUrl: './wiki-snackbar.component.html',
  styleUrls: ['./wiki-snackbar.component.less'],
})
export class WikiSnackbarComponent {

  @Input() public page: WikiPageDTO;
  @Output() public openEvent = new EventEmitter<string>();

  constructor(
    private store: Store,
    private wikiService: WikiService
  ) { }

  public openPage(parent: WikiParent) {
    this.store.dispatch(new WikiActions.PageClosed(this.wikiService.lastOpenedSlug));
    this.openEvent.emit(parent.Slug);
  }
}
