<div class="seasonal-tab">
  <ng-container *ngIf="!inSetup; else loadingTemplate">
    <ng-container *ngIf="variable.IsTrend"
                  [ngTemplateOutlet]="trendTemplate"></ng-container>
    <ng-container *ngIf="!variable.IsTrend">
      <ng-container [ngTemplateOutlet]="viewAccordion"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #viewAccordion>
  <mat-accordion>
    <!-- New Result panel 1 -->
    <mat-expansion-panel [expanded]="variable.SeasonalFound"
                         [hideToggle]="!variable.SeasonalFound">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Result: Adjustments
        </mat-panel-title>
        <mat-panel-description>
          {{ !variable.SeasonalFound ? 'No seasonal patterns found' : 'Seasonal adjustment and trend' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngIf="variable.SeasonalFound"
                    [ngTemplateOutlet]="seasonalAdjustmentTemplate"></ng-container>
    </mat-expansion-panel>
    <!-- New Result panel 2 -->
    <mat-expansion-panel [hideToggle]="!variable.SeasonalFound">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Result: Components
        </mat-panel-title>
        <mat-panel-description>
          {{ !variable.SeasonalFound ? 'No seasonal patterns found' : 'Irregular and seasonal components' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngIf="variable.SeasonalFound"
                    [ngTemplateOutlet]="seasonalComponentTemplate"></ng-container>
    </mat-expansion-panel>
    <!-- Settings panel-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Settings
        </mat-panel-title>
        <mat-panel-description>
          {{ seasonalSettings.seasonalEnabled
          ? 'Seasonal adjustment and detection'
          : 'Seasonal adjustment is disabled' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="settingsTemplate"></ng-container>
    </mat-expansion-panel>
    <!-- Calendar panel -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Calendar effects
        </mat-panel-title>
        <mat-panel-description>
          {{ seasonalSettings.seasonalEnabled
          ? 'Select the calendar effects to search for'
          : 'Seasonal adjustment must be enabled for this feature' }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="calendarSettings"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #seasonalAdjustmentTemplate>
  <div class="grid-system center-vertical"
       style="--grid-template: repeat(3, auto) 1fr; gap: 20px">
    <indicio-check-circle [model]="adjustedDataModel"
                          (clickEvent)="setupGraph()"></indicio-check-circle>
    <indicio-check-circle [model]="originalDataModel"
                          (clickEvent)="setupGraph()"></indicio-check-circle>
    <indicio-check-circle [model]="trendDataModel"
                          (clickEvent)="setupGraph()"></indicio-check-circle>
    <div *ngIf="variable.Source === 'macrobondapi'"
         class="upload-button center-vertical"
         (click)="openExportSeasonalResult()">
      <fa-icon [icon]="faUpload"></fa-icon>
      Upload to Macrobond
    </div>
  </div>
  <indicio-alg class="modal-chart"
               [periodicityType]="variable.Periodicity"
               [HistoricLine]="algHistLine"
               [Lines]="algLines"
               [options]="graphOptions"
               [chartStyles]="chartStyles"
               graphType="generic">
  </indicio-alg>
</ng-template>

<ng-template #seasonalComponentTemplate>
  <div class="grid-system center-vertical"
       style="--grid-template: repeat(3, auto) 1fr; gap: 20px">
    <indicio-check-circle [model]="seasComponentCheckModel"
                          (clickEvent)="updateMonthPlot()"></indicio-check-circle>
    <indicio-check-circle [model]="seasHistoricCheckModel"
                          (clickEvent)="updateMonthPlot()"></indicio-check-circle>
    <indicio-check-circle [model]="seasIrregularCheckModel"
                          (clickEvent)="updateMonthPlot()"></indicio-check-circle>
    <mat-form-field *ngIf="variable.Periodicity === 'day' || variable.Periodicity === 'weekday'"
                    style="margin-top: -10px">
      <mat-select [ngModel]="component"
                  required
                  [multiple]=false
                  (selectionChange)="componentChanged($event)">
        <mat-option [value]="value.Value"
                    [title]="value.Label"
                    *ngFor="let value of componentOptions">{{ value.Label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <indicio-monthplot style="display: block; height: 400px;"
                     [loading]="seasCompPlotLoading"
                     [options]="seasCompPlotData">
  </indicio-monthplot>

  <div class="component-dates-slider"
       (mouseleave)="showSliderLabels = false"
       (mouseenter)="showSliderLabels = true">
    <div style="margin-top: 12px; margin-bottom: -7px;">Active years</div>
    <mat-slider min="0"
                max="{{seasSliderPeriods.length - 1}}"
                [discrete]="showSliderLabels"
                [displayWith]="sliderFormatLabel.bind(this)">
      <input value="0"
             matSliderStartThumb
             (valueChange)="seasSliderChanged($event, 'start')">
      <input value="{{seasSliderPeriods.length}}"
             matSliderEndThumb
             (valueChange)="seasSliderChanged($event, 'end')">
    </mat-slider>
    <div class="grid-system"
         style="--grid-template: 1fr 1fr; width: calc(100% + 31px); margin-left: -7px">
      <div>{{ seasSliderLabels[0] }}</div>
      <div style="text-align: end;">{{ seasSliderLabels.last() }}</div>
    </div>
  </div>
</ng-template>

<ng-template #settingsTemplate>

  <!-- Enabled -->
  <indicio-modal-option-row optionTitle="Enabled"
                            optionSubtitle="Might slow down calculations significally if enabled"
                            textWidth="8"
                            optionsWidth="4">
    <div style="margin-top: 25px;">
      <mat-slide-toggle color="accent"
                        [checked]="seasonalSettings.seasonalEnabled"
                        (change)="toggleSeasonal()">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>

  <!-- Model -->
  <indicio-modal-option-row optionTitle="Model"
                            optionSubtitle="The seasonal model to use"
                            [disabled]="!seasonalSettings.seasonalEnabled">
    <div style="margin-top:15px">
      <mat-form-field>
        <mat-select [value]="seasonalSettings.seasonalModelType"
                    required
                    [disabled]="!seasonalSettings.seasonalEnabled"
                    (selectionChange)="setModel($event.value)">
          <mat-option [value]="mode.Value"
                      [title]="mode.Description"
                      *ngFor="let mode of env.SeasonalModels">{{ mode.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>

  <!-- Strategy -->
  <indicio-modal-option-row optionTitle="Strategy"
                            optionSubtitle="The seasonal pattern to use"
                            [title]="seasonalSettings.seasonalModelType !== 'x13' ? 'Setting unavailable when using X11 model':''"
                            [disabled]="!seasonalSettings.seasonalEnabled">
    <div style="margin-top:15px">
      <mat-form-field>
        <mat-select [value]="seasonalSettings.seasonalStrategyType"
                    required
                    [disabled]="seasonalSettings.seasonalModelType !== 'x13' || !seasonalSettings.seasonalEnabled"
                    (selectionChange)="setStrat($event.value)">
          <mat-option [value]="mode.Value"
                      [title]="mode.Description"
                      *ngFor="let mode of env.SeasonalStrategies">{{ mode.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>

  <!-- Outlier types -->
  <indicio-modal-option-row optionTitle="Seasonal outlier types"
                            optionSubtitle="Select outlier types for seasonal outliers"
                            [disabled]="!seasonalSettings.seasonalEnabled">
    <div style="margin-top:15px">
      <mat-form-field *ngIf="!inSetup">
        <mat-select [value]="seasonalSettings.seasonalOutlierTypes"
                    required
                    [disabled]="!seasonalSettings.seasonalEnabled"
                    [multiple]=true>
          <mat-option [value]="type.Value"
                      [title]="type.Description"
                      (onSelectionChange)="selectSeasonalOutlierType($event)"
                      *ngFor="let type of env.SeasonalOutlierTypes">{{ type.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>

  <!-- Outlier adjustment -->
  <indicio-modal-option-row optionTitle="Seasonal outlier adjustment"
                            optionSubtitle="Adjust the seasonal pattern for outliers"
                            textWidth=10
                            optionsWidth=2
                            [disabled]="!seasonalSettings.seasonalEnabled">
    <div class="toggle-icon text-right"
         style="margin-top: 25px;">
      <mat-slide-toggle data-test-id='use-trend-toggle-btn'
                        [disabled]="!seasonalSettings.seasonalEnabled"
                        [checked]="seasonalSettings.seasonalOutlier"
                        (change)="toggleSeasonalOutlier()">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>

  <!-- Extract a trend component -->
  <indicio-modal-option-row optionTitle="Model seasonal trend"
                            optionSubtitle="Option to model and extract the seasonal trend"
                            textWidth=10
                            optionsWidth=2
                            [disabled]="!seasonalSettings.seasonalEnabled">
    <div class="toggle-icon text-right"
         style="margin-top: 25px;">
      <mat-slide-toggle data-test-id='use-trend-toggle-btn'
                        [checked]="seasonalSettings.seasonalExtractTrend"
                        [disabled]="!seasonalSettings.seasonalEnabled"
                        (change)="toggleUseTrend()">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>

  <!-- Save button -->
  <div class="pull-right"
       style="width:fit-content; margin-top: 25px;">
    <button mat-raised-button
            type="button"
            color="primary"
            [disabled]="!seasonalSettings.settingsChanged"
            (click)="saveSettings()">
      <span *ngIf="!saveInProgress">Save changes</span>
      <span *ngIf="saveInProgress"
            class="ion-load-c spinner"></span>
    </button>
  </div>
</ng-template>

<ng-template #calendarSettings>

  <indicio-modal-option-row style="margin-top: -15px; display: block;"
                            optionTitle="Effects built in x11 or x13">
    <div style="margin-top:15px">
      <mat-form-field>
        <mat-select [value]="seasonalSettings.calendarTypes"
                    required
                    [multiple]=true>
          <mat-option [value]="mode.Value"
                      [title]="mode.Description"
                      (onSelectionChange)="selectSeasonalcalendarEffect($event)"
                      *ngFor="let mode of availableSeasonalEffects">{{ mode.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>

  <span class="bold">Specific effects</span>
  <div class="calendar-list-container">
    <div class="grid-system"
         style="--grid-template: 1fr 0.7fr; gap: 20px;">
      <ng-container *ngTemplateOutlet="effectListTemplate;"></ng-container>
      <ng-container *ngTemplateOutlet="selectedEffectListTemplate;"></ng-container>
    </div>
  </div>

  <!-- Save button -->
  <div class="pull-right"
       style="width:fit-content; margin-top: 15px;">
    <button mat-raised-button
            type="button"
            color="primary"
            [disabled]="!seasonalSettings.calendarEffectsChanged"
            (click)="saveSettings()">
      <span *ngIf="!saveInProgress">Save changes</span>
      <span *ngIf="saveInProgress"
            class="ion-load-c spinner"></span>
    </button>
  </div>
</ng-template>

<ng-template #effectListTemplate>
  <div class="list-container divider">
    <ng-container *ngFor="let item of seasonalSettings.calendarItems; let index = index">
      <div class="grid-system pointer"
           [ngClass]="{ 'selected': index === lastClickedIndex }"
           style="--grid-template: 50px 1fr">
        <div>
          <span (click)="unfoldItem(item, index)"
                class="list-btn"
                style="width: 24px">
            <i [class]="item.open ? 'ion-chevron-down' : 'ion-chevron-right'"></i>
          </span>
          <span *ngIf="!item.isRegion"
                (click)="selectItem(item)"
                class="list-btn"
                style="width: 22px">
            <i [class]="item.isAllSelected
                ? 'ion-android-checkbox'
                : item.anySelected
                  ? 'ion-android-checkbox-outline'
                  : 'ion-android-checkbox-outline-blank'">
            </i>
          </span>
        </div>
        <div (click)="unfoldItem(item, index)">
          {{item.item.Display}}
        </div>
      </div>

      <ng-container *ngIf="item.open">
        <ng-container *ngFor="let subItem of item.subItems; let index = index">
          <ng-container *ngTemplateOutlet="subItemRow; context: {subItem, first: index === 0}">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #subItemRow
             let-subItem="subItem">
  <ng-container *ngIf="subItem;">
    <div class="pointer sub-item"
         (click)="selectSubItem(subItem, true)">
      <i [class]="subItem.selected
           ? (subItem.isRegion ? 'ion-android-radio-button-on' : 'ion-android-checkbox-outline')
           : (subItem.isRegion ? 'ion-android-radio-button-off' : 'ion-android-checkbox-outline-blank')">
      </i>
      <span>
        {{ subItem.item.Display }}
      </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #selectedEffectListTemplate>
  <div class="list-container">
    <div>Selected effects</div>
    <ng-container *ngIf="seasonalSettings.selectedHolidaysItem.length > 0">
      <h4 style="margin-top: 10px; text-decoration: underline; font-weight: 500;">Holidays</h4>
      <div *ngFor="let item of seasonalSettings.selectedHolidaysItem;"
           style="margin: 5px 0 0 10px">
        {{ item.item.Display }} {{ item.selectedCount() }}
      </div>
    </ng-container>
    <ng-container *ngIf="seasonalSettings.selectedWorkingdayItem.length > 0">
      <h4 style="margin-top: 10px; text-decoration: underline; font-weight: 500;">Working day</h4>
      <h4></h4>
      <div *ngFor="let item of seasonalSettings.selectedWorkingdayItem;"
           style="margin: 5px 0 0 10px">
        {{ item.regionDisplay() }}
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #trendTemplate>
  <indicio-open-twin-component [options]="options"
                               [type]="'Seasonal settings are'"
                               [tab]="viewEnum.seasonal"
                               [twinId]="variable.TrendTwinId"
                               (closeDialogEvent)="closeDialogEvent.emit()">
  </indicio-open-twin-component>
</ng-template>

<ng-template #loadingTemplate>

</ng-template>
