<indicio-modal-base [child]="this"
                    data-test-id="GDPR-modal-container"
                    modalTitle="Data processing policy (GDPR)">

  <p class="text-center">
    Personal Data Processing Agreement (PDPA)
  </p>
  <p>1. Indicio handles the following personal information</p>
  <ul class="gdpr-list"
      data-test-id="GDPR-modal-text-container">
    <li class="gdpr-entry">
      Email, which is used for:
      <ul class="gdpr-list-internal">
        <li>
          Username to access the service
        </li>
        <li>
          Account restoration
        </li>
        <li>
          User invitations
        </li>
        <li>
          Listing users in the same company or in a shared project
        </li>
        <li>
          Communication between Indicio and its end-users, including information about quota limits and service
          availability information
        </li>
      </ul>
    </li>
    <li class="gdpr-entry">
      Full name, which is used for:
      <ul class="gdpr-list-internal">
        <li>
          Displaying information to other users in the same company or shared projects
        </li>
      </ul>
    </li>
    <li class="gdpr-entry">
      Country of residence, which is used for:
      <ul class="gdpr-list-internal">
        <li>
          Setting default values when choosing currencies
        </li>
        <li>
          Setting default formatting when displaying numbers and dates
        </li>
      </ul>
    </li>
    <li class="gdpr-entry">
      IP address, which is used for:
      <ul class="gdpr-list-internal">
        <li>
          Account and system security
        </li>
      </ul>
    </li>
  </ul>
  <p>2. At any time, the user can delete his/her account, completely removing any personal data stored, excluding
    information that must be kept due to legal obligations</p>
  <p>3. To delete your account contact Indicio Technologies at customer@indicio.com</p>
  <div class="row">
    <div class="col-xs-6"></div>
    <div class="col-xs-6">
      <button class="btn right"
              data-test-id="GDPR-modal-ok-btn"
              (click)="close()">
        <span>Ok</span>
      </button>
    </div>
  </div>
</indicio-modal-base>
