import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearStateAction } from '../auth/auth.actions';
import { CompanyActions } from '../company/company.actions';
import { TagModel } from './models/tag.model';
import { TagAction } from './tags.actions';

class TagsStateModel {
  Tags: TagModel[];
}

@State<TagsStateModel>({
  name: 'tags',
  defaults: {
    Tags: [],
  }
})
@Injectable()
export class TagsState {

  @Selector()
  static tags(state: TagsStateModel) { return state.Tags; }

  @Action(TagAction.GetSuccess)
  getSuccess(ctx: StateContext<TagsStateModel>, action: TagAction.GetSuccess) {
    let tags = ctx.getState().Tags;
    tags.addOrUpdate(action.tag);
    tags = this.linkTags(tags);
    ctx.patchState({ Tags: [...tags] });
  }

  @Action(TagAction.GetAllSuccess)
  getAllSuccess(ctx: StateContext<TagsStateModel>, action: TagAction.GetAllSuccess) {
    const tags = this.linkTags(action.tags);
    ctx.setState({ Tags: [...tags] });
  }

  @Action(TagAction.CreateSuccess)
  createSuccess(ctx: StateContext<TagsStateModel>, action: TagAction.CreateSuccess) {
    let tags = ctx.getState().Tags;
    tags.addOrUpdate(action.tag);
    this.updateRelatives(action.tag, tags);
    tags = this.linkTags(tags);
    ctx.patchState({ Tags: [...tags] });
  }

  @Action(TagAction.UpdateSuccess)
  updateSuccess(ctx: StateContext<TagsStateModel>, action: TagAction.UpdateSuccess) {
    let tags = ctx.getState().Tags;
    tags.addOrUpdate(action.tag);
    this.updateRelatives(action.tag, tags);
    tags = this.linkTags(tags);
    ctx.patchState({ Tags: [...tags] });
  }

  @Action(TagAction.DeleteSuccess)
  deleteScenarioSuccess(ctx: StateContext<TagsStateModel>, action: TagAction.DeleteSuccess) {
    let tags = ctx.getState().Tags;
    tags.removeById(action.tag.TagId);
    tags.forEach(t => {
      t.ChildTagIds.removeId(action.tag.TagId);
      t.ParentTagIds.removeId(action.tag.TagId);
    });
    tags = this.linkTags(tags);
    ctx.patchState({ Tags: [...tags] });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<TagsStateModel>) {
    ctx.patchState({ Tags: [] });
  }

  private linkTags(tags: TagModel[]) {
    for (const t of tags) {
      t.ChildTags = tags.filter(x => t.ChildTagIds.includes(x.TagId)).sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
      t.ParentTags = tags.filter(x => t.ParentTagIds.includes(x.TagId)).sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    }
    return tags.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
  }

  public updateRelatives(tag: TagModel, all: TagModel[]) {
    tag.ChildTagIds.forEach(id => {
      const child = all.find(x => x.TagId === id);
      if (child) { child.ParentTagIds.addUniqueId(tag.TagId); }
    });
    tag.ParentTagIds.forEach(id => {
      const parent = all.find(x => x.TagId === id);
      if (parent) { parent.ChildTagIds.addUniqueId(tag.TagId); }
    });
  }
}
