import { Component } from '@angular/core';
import { NavigateToForecast } from '@core/actions/navigation.actions';
import { DataProvider } from '@core/constants/provider-definitions';
import { IFile } from '@core/interfaces/if-file';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { FileUsageCollectionDTO } from '@core/store/file/dtos/used-file-dto';
import { FileFrontendService } from '@core/store/file/file.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { SharepointService } from '@core/store/providers/sharepoint/sharepoint.service';
import { SourceVariableUsageDTO, UsedInProjectAndForecastDTO } from '@core/store/source-variable/dtos/used-dto';
import { RemovedSourceVariablesInCompanyAction } from '@core/store/source-variable/source-variable.actions';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { RemoveSharepointModalOptions } from './remove-sharepoint-modal.component.options';


class UsedAsSourceVariable {
  FileId: string;
  SourceVariables: SourceVariableUsageDTO[];
}

@Component({
  selector: 'indicio-delete-sharepoint-files',
  templateUrl: './remove-sharepoint-modal.component.html'
})
export class RemoveSharepointModalComponent extends ModalModelComponent {

  public opts: RemoveSharepointModalOptions;
  private sourceVaribles: SourceVariableModel[] = [];
  public fileUsage: FileUsageCollectionDTO;
  public files: IFile[] = [];
  public callback: any;
  usedFiles: UsedAsSourceVariable[] = [];
  public remoteDataSourceId: string;

  public gonefishing: boolean;
  public isLoading = true;

  constructor(
    protected store: Store,
    private service: SharepointService,
    private status: StatusService,
    public envService: EnvironmentService,
    public appearance: AppearanceService,
    private actions: ActionService,
    private dialog: DialogService,
    private fvarService: ForecastVariableFrontendService,
    private sVarService: SourceVariableFrontendService,
    private clientService: ClientFrontendService,
    private fileService: FileFrontendService,
    private variableDialogService: VariableDialogService
  ) { super(); }

  public setOptions(options: RemoveSharepointModalOptions) {
    this.opts = options;
    this.remoteDataSourceId = options.remoteDataSourceId;
    this.callback = options.callback;

    this.sub.add(this.actions.dispatched(RemovedSourceVariablesInCompanyAction).subscribe(_x => {
      this.syncUsed();
    }));

    this.service.getFilesAttachedToSharepoint(this.remoteDataSourceId, this.clientService.activeCompanyId)
      .then(files => {
        this.files = files;
        return this.syncUsed();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private syncUsed() {
    return this.service.getUsage(this.remoteDataSourceId, this.clientService.activeCompanyId)
      .then(used => {
        const x = [...used.RemoteFileUsage, ...used.UploadedFileUsage];
        this.usedFiles = [];
        x.forEach(variable => {
          this.usedFiles.push(<UsedAsSourceVariable> {
            SourceVariables: variable.SourceVarInfos,
            FileId: variable.FileId
          });
        });
        const sourceIds = this.usedFiles.map(x => x.SourceVariables.map(s => s.SourceVariableId)).flatten();
        this.sVarService.getOrFetchSourceVariablesInBulk(sourceIds)
          .then(svars => this.sourceVaribles = svars);
      });
  }

  public filesAcceptedToBeRemoved() {
    return this.files.filter(v => !this.isUsed(v));
  }

  public filesNotAcceptedToBeRemoved() {
    return this.files.filter(v => this.isUsed(v));
  }

  public forceRemoveFiles() {
    const ref = this.dialog.openConfirmDialog({
      Title: 'Are you sure you want to delete all variables and files?',
      Message: 'This action will delete all forecast variables, source variables including selected files and cannot be undone.',
      CancelText: 'No',
      ConfirmText: 'Delete',
      Style: 'warn'
    });
    ref.subscribe(async (ans) => {
      if (ans) {
        await this.forceRemoveAll();
      }
    });
  }

  private async forceRemoveAll() {
    this.status.setMessage('Removing sharepoint files...', 'Success', true);
    const sources = this.files.map(file => this.usedIn(file)?.SourceVariables).filter(x => x != null).flatten();
    const fvar = sources.map(source => source.Usage?.filter(x => !x.IsNowcast) || []).flatten().filter(e => e != null);
    const svar = sources.map(source => this.sourceVaribles.find(x => x.SourceVariableId === source.SourceVariableId));
    for (let i = 0; i < fvar.length; i++) {
      try {
        const usage = fvar[i];
        const deleteFVarReponse = await this.proceedDeletingForecastVariable(usage);
        if (!deleteFVarReponse) {
          this.status.setError(`${usage.ForecastName} cannot be deleted`, true);
          return;
        } else {
          await this.syncUsed();
        }
      } catch (error) {
        this.status.setError(error, true);
        return;
      }
    }
    this.status.setMessage('All forecast variables deleted', 'Success', true);
    for (let i = 0; i < svar.length; i++) {
      try {
        const SVar = svar[i];
        const deleteSVarResponse = await this.proceedDeletingSourceVariable(SVar);
        if (!deleteSVarResponse) {
          this.status.setError(`${SVar.Name} cannot be deleted`, true);
          return;
        } else {
          await this.syncUsed();
        }
      } catch (error) {
        this.status.setError(error, true);
        return;
      }
    }
    this.status.setMessage('All source variables deleted', 'Success', true);
    this.removeFiles();
  }

  private finalRemovalCallback() {
    this.pending = true;
    this.service.remove(this.remoteDataSourceId).then(() => {
      this.close();
      this.clientService.client.RemoteDataSources.removeWhere(x => x.Provider === DataProvider.sharepoint);
      this.status.setMessage('Successfully removed sharepoint', 'Success');
    }).catch(() => {
      this.status.setMessage('Can\'t remove sharepoint', 'Error', true);
    })
      .finally(() => {
        this.pending = false;
      });
  }

  public async removeFiles() {
    const filesToBeRemoved = this.filesAcceptedToBeRemoved();
    const count = filesToBeRemoved.length;
    this.pending = true;
    this.gonefishing = true;

    const removeFunc = async (currentFile) => {
      let call;
      currentFile.removedStatus = 'Removing';
      try {
        if (currentFile.UploadedFileId) {
          call = this.fileService.deleteFile(currentFile);
        } else if (currentFile.RemoteFileId) {
          call = this.fileService.deleteRemoteFile(currentFile);
        }
        await call;
        currentFile.removedStatus = 'Success';
      } catch (e) {
        currentFile.removedStatus = 'Failed';
        currentFile.errorStatus = e.error;
      }
    };

    let current = 0;

    const queueFunc = async () => {
      if (current >= count) {
        this.pending = false;
        this.finalRemovalCallback();
        return;
      }
      await removeFunc(filesToBeRemoved[current]);
      current += 1;
      setTimeout(async () => {
        await queueFunc();
      }, 20);
    };

    await queueFunc();
  }

  public isUsed(file: IFile) {
    return !!this.usedFiles.find(uv => uv.FileId === file.RemoteFileId);
  }

  public usedIn(file: IFile) {
    if (this.isUsed(file)) {
      return this.usedFiles.find(uv => uv.FileId === file.RemoteFileId);
    }
    return null;
  }

  public navigateToForecast(usage: UsedInProjectAndForecastDTO) {
    const ref = this.dialog.openConfirmDialog({
      Title: 'Navigate to forecast?',
      Message: 'Navigate to forecast means that current procedure will exist, but you can always go back and proceed another time.',
      CancelText: 'No',
      ConfirmText: 'Yes',
      Style: 'warn'
    });
    ref.subscribe(ans => {
      if (ans) {
        this.close();
        this.store.dispatch(new NavigateToForecast(usage.ForecastId));
      }
    });
  }

  public deleteForecastVariable(usage: UsedInProjectAndForecastDTO) {
    const ref = this.dialog.openConfirmDialog({
      Title: 'Delete forecast variable?',
      Message: `Do you want to remove the variable ${usage.ForecastVariableName}<br><br>` +
        `Project: ${usage.ProjectName}<br>` +
        `Forecast: ${usage.ForecastName}<br>`,
      CancelText: 'No',
      ConfirmText: 'Delete',
      Style: 'warn'
    });
    ref.subscribe(ans => {
      if (ans) {
        this.proceedDeletingForecastVariable(usage)
          .then(() => {
            this.status.setMessage('Forecast varible deleted', 'Success', true);
            this.syncUsed();
          })
          .catch(err => this.status.setError(err, true));
      }
    });
  }

  public proceedDeletingForecastVariable(usage: UsedInProjectAndForecastDTO) {
    return this.fvarService.deleteForecastVariable(usage.ForecastVariableId, usage.ForecastVersionId);
  }

  public deleteSourceVariable(source: SourceVariableUsageDTO) {
    const ref = this.dialog.openConfirmDialog({
      Title: 'Delete source variable?',
      Message: `Do you want to remove the source variable ${source.SourceVariableName}<br><br>`,
      CancelText: 'No',
      ConfirmText: 'Delete',
      Style: 'warn'
    });
    ref.subscribe(ans => {
      if (ans) {
        const svar = this.sourceVaribles.find(x => x.SourceVariableId === source.SourceVariableId);
        if (svar) {
          this.proceedDeletingSourceVariable(svar)
            .then(() => this.syncUsed())
            .catch(err => this.status.setError(err, true));
        } else {
          this.status.setMessage('Could not find source variable', 'Warning', true);
        }
      }
    });
  }

  public proceedDeletingSourceVariable(sourceVar: SourceVariableModel) {
    return this.sVarService.deleteSourceVariable(sourceVar);
  }

  public openSourceVariableModal(source: SourceVariableUsageDTO) {
    if (this.opts.forecastVersionId != null) {
      const ref = this.dialog.openConfirmDialog({
        Title: 'Navigate to source variable?',
        Message: 'Navigate to source variable means that current procedure will exist, but you can always go back and proceed another time.',
        CancelText: 'No',
        ConfirmText: 'Yes',
        Style: 'warn'
      });
      ref.subscribe(ans => {
        if (ans) {
          this.close();
          this.variableDialogService.openSourceVariableInfo({
            sourceVariableId: source.SourceVariableId,
            forecastId: this.opts.forecastId,
            forecastVersionId: this.opts.forecastVersionId
          });
        }
      });
    }
  }
}


