<ng-container *ngIf="(loggedIn$ | async)">
  <div *ngIf="!!openDrawer || profileOpen"
       class="modal-backdrop nav"
       (click)="toggleDrawer(openDrawer)">
  </div>
  <div class="navigation-wrapper"
       [class]="'system-message-'+ systemMessagePosition || 'bottom'"
       data-test-id="navigation-container"
       [ngClass]="{'profile-active': profileOpen, 'logged-in': (loggedIn$ | async)}">

    <ng-container [ngTemplateOutlet]="profileMenuTemplate"></ng-container>


    <nav>
      <ng-container [ngTemplateOutlet]="brandTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
    </nav>

    <ng-container [ngTemplateOutlet]="forecastsDrawerTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="reportsDrawerTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #forecastsDrawerTemplate>
  <div class="drawer"
       [ngClass]="{ active: openDrawer === 'forecasts' }">
    <indicio-forecasts-drawer (closeNavigation)="openDrawer = null"
                              [visible]="openDrawer === 'forecasts'"></indicio-forecasts-drawer>
  </div>
</ng-template>

<ng-template #reportsDrawerTemplate>
  <div class="drawer"
       data-test-id="reports-drawer"
       [ngClass]="{ active: openDrawer === 'reports' }">
    <indicio-reports-drawer (closeNavigation)="openDrawer = null"
                            [visible]="openDrawer === 'reports'"></indicio-reports-drawer>
  </div>
</ng-template>


<ng-template #brandTemplate>
  <div id="nav-brand"
       data-test-id="home-btn"
       class="cursor-pointer"
       [routerLink]="['/home']"
       (click)="activePage = null">
    <indicio-icon-logo [stroke]="appearance.ActualTheme === 'light' ? 'transparent' : 'var(--indicio-nav-text-color, #fff)'"
                       [fill]="appearance.ActualTheme === 'light' ? 'rgb(99 123 169)' : 'transparent'"
                       style="width: 50px;"></indicio-icon-logo>
  </div>
</ng-template>

<ng-template #menuTemplate>
  <ul class="top-menu">
    <li (click)="toggleDrawer('forecasts')"
        data-test-id="navigation-projects-btn"
        class="drawer-control"
        [ngClass]="{active: openDrawer === 'forecasts', disabled: !hasAcceptedTos}">
      <i class="font-size-large icon-project"></i>
      <div class="title">Forecasts</div>
    </li>
    <li (click)="toggleDrawer('reports')"
        data-test-id="navigation-reports-btn"
        class="drawer-control"
        [ngClass]="{active: openDrawer === 'reports', disabled: !hasAcceptedTos}">
      <i class="font-size-large ion-document-text"></i>
      <div class="title">Reports</div>
    </li>
    <li *ngIf="isAdmin$ | async;"
        (click)="openAutomationView()"
        class="beta-badge"
        [ngClass]="{active: activePage === 'automation'}">
      <ng-container [ngTemplateOutlet]="automatedTaskIconTemplate"></ng-container>
      <div class="title">Automation</div>
    </li>
  </ul>

  <ul class="bottom-menu">

    <ng-container *ngIf="wikiService.isEnabled">
      <li (click)="openWiki()">
        <fa-icon [icon]="wikiIcon"></fa-icon>
        <div class="title">Wiki</div>
      </li>
    </ng-container>

    <ng-container *ngIf="isSupport">
      <li (click)="supportDialogService.openSupportDialog({})">
        <fa-icon [icon]="supportIcon"></fa-icon>
        <div class="title">Support</div>
      </li>
    </ng-container>

    <ng-container *ngIf="isAdmin$ | async">
      <li (click)="gotoAdmin()"
          [ngClass]="{active: activePage === 'admin'}">
        <div [attr.data-badge]="adminErrors > 0 ? (adminErrors > 99 ? '*' : adminErrors) : null"></div>
        <i class="font-size-large ion-wand"></i>
        <div class="title">Admin</div>
      </li>
    </ng-container>

    <li (click)="openCompany()"
        data-test-id="navigation-company-btn"
        class="drawer-control">
      <i class="font-size-large ion-briefcase"></i>
      <div class="title">Company</div>
    </li>
    <li (click)="profileOpen = !profileOpen; openDrawer = null"
        data-test-id="navigation-account-btn"
        [ngClass]="{active: profileOpen}"
        class="profile-menu-item">
      <i class="font-size-large icon-account"></i>
      <div class="title">Account</div>
    </li>

    <li (click)="appearance.toggleAdvancedUI()"
        data-test-id="navigation-advanced-toggle">
      <i class="font-size-large"
         [attr.data-test-id]="'advanced-mode-' + (appearance.AdvancedUI ? 'true' : 'false') "
         [ngClass]="appearance.AdvancedUI ? 'ion-toggle-filled' : 'ion-toggle' "></i>
      <div class="title">Advanced</div>
    </li>
    <li class="brand-fa">
      <indicio-text-logo style="width: 58px; fill: var(--indicio-nav-text-color, #fff)"></indicio-text-logo>
    </li>
  </ul>
</ng-template>

<ng-template #profileMenuTemplate>
  <div class="profile-drawer"
       data-test-id="navigation-profile-drawer"
       [ngClass]="{active: profileOpen }">
    <div class="profile-image-wrapper">
      <div class="profile-image">
        <i class="ion-person"></i>
      </div>
      <div class="text-center extra-margin-top font-size-label-big">
        {{ (profile$ | async)?.FirstName }} {{ (profile$ | async)?.LastName }}
      </div>
    </div>
    <div class="profile-info companies">
      <p> PERSONAL</p>
      <p data-test-id="navigation-profile-btn"
         class="link"
         (click)="editProfile()">
        Profile
        <i class="ion-gear-a grey pull-right h-blue"></i>
      </p>
      <p data-test-id="navigation-security-settings-btn"
         class="link"
         (click)="openSecuritySettings()">
        Security settings
        <i class="ion-gear-a grey pull-right h-blue"></i>
      </p>
      <p data-test-id="navigation-appearance-btn"
         class="link"
         (click)="editProfile('Appearance')">
        Appearance settings
        <i class="ion-gear-a grey pull-right h-blue"></i>
      </p>
      <p data-test-id="navigation-3rdparty-btn"
         class="link"
         (click)="editProfile('Accounts')">
        Third party accounts
        <i class="ion-gear-a grey pull-right h-blue"></i>
      </p>
    </div>

    <div class="profile-footer margin-top-5">

      <div class="row">
        <div class="col-xs-12">
          <p data-test-id="navigation-report-a-bug-btn"
             class="cursor-pointer h-blue"
             (click)="reportABug()">Report issue</p>
          <p data-test-id="navigation-tos-btn"
             class="cursor-pointer h-blue"
             (click)="readTos()">Terms of service</p>
          <p data-test-id="navigation-gdpr-btn"
             class="cursor-pointer h-blue extra-margin-bottom"
             (click)="readGdpr()">Data processing policy</p>
        </div>
      </div>

      <ng-container *ngIf="onboarding.ACTIVE">
        <div class="row">
          <div class="col-xs-12">
            <a class="cursor-pointer h-blue"
               title="Reset onboarding help"
               (click)="onboarding.toggleOnOff()">
              <i
                 [ngClass]="[onboarding.hide ? 'ion-android-checkbox-outline-blank' : 'ion-android-checkbox-outline']"></i>
              Show help
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <a class="cursor-pointer h-blue"
               title="Reset onboarding help"
               (click)="onboarding.reset()">
              <i class="ion-help-circled"></i>
              Reset tutorial
            </a>
          </div>
        </div>
      </ng-container>
      <div class="row">
        <div class="col-xs-12">
          <a class="cursor-pointer h-blue"
             data-test-id="navigation-reload-session-btn"
             title="Reload all data from the server and reset the session"
             (click)="reloadSession()">
            <i class="ion-refresh"></i>
            Reload session
          </a>
        </div>
      </div>
      <a data-test-id="navigation-logout-btn"
         class="cursor-pointer h-blue logout"
         (click)="logout()">
        <i data-class="ion-android-exit"></i>
        Log out
      </a>
    </div>
  </div>
</ng-template>

<ng-template #automatedTaskIconTemplate>
  <svg version="1.1"
       id="Lager_1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 845 841.9"
       style="enable-background:new 0 0 845 841.9"
       xml:space="preserve">
    <style type="text/css">
      .st0 {
        fill: var(--indicio-nav-text-color, #FFFFFF);
        width: 70%;
      }
    </style>
    <path class="st0"
          d="M52.5,247.5c3.6-11,11.6-16,22.8-17.1c7.9-0.7,17.7,0.5,23.2-3.7c5.2-3.9,5.7-13.7,8.5-20.9
	c1.4-3.6,2.5-7.5,4.8-10.6c5.3-7.2,3.8-12.8-2.3-18.8c-4.1-4-7.5-8.9-10.9-13.7c-6.7-9.5-6.4-19.4,1.6-27.8
	c10.7-11.3,21.7-22.3,33-32.9c8.7-8.2,18.5-8.2,28.4-0.9c5.8,4.3,11.2,9.3,17.2,13.4c1.8,1.2,5.3,1.4,7.5,0.6
	c12.4-4.8,24.6-10,36.8-15.4c1.9-0.9,4-3.3,4.4-5.3c1.3-6.6,1.9-13.4,2.7-20.2c1.6-13.6,8.8-20.7,22.3-20.9
	c14.6-0.3,29.1-0.3,43.7,0c13.3,0.2,20.6,6.8,22.6,19.9c1.2,8.1-0.4,18.3,3.9,23.9c4.3,5.5,14.6,6.3,22.2,9.4
	c4,1.6,8.4,3.1,11.8,5.7c6.8,5.3,11.8,3.5,17.5-1.9c4.7-4.4,10.2-8.2,15.7-11.7c7.1-4.5,15-4.2,21,1.3c13.2,12.2,26,24.8,38,38
	c6.6,7.2,5.7,16-0.5,24.8c-4.4,6.1-9.5,11.7-13.7,17.9c-1.3,1.9-1.6,5.4-0.8,7.6c4.2,11.3,8.8,22.5,13.7,33.6c0.9,1.9,3.4,4,5.5,4.4
	c6.7,1.2,13.5,1.7,20.2,2.5c13.9,1.6,21,8.9,21.3,22.9c0.3,14.3,0.3,28.6,0,43c-0.3,14-7.3,21.4-21.1,23c-7.9,0.9-17.6-0.4-23.3,3.7
	c-5.2,3.7-6.2,13.4-9.2,20.4c-2.4,5.5-5,11.1-7.9,16.3c-2,3.5-1.7,6,0.9,9.1c4.7,5.5,9.2,11.3,13.6,17.1c8.3,11,7.8,20.8-1.7,30.6
	c-10,10.3-20.1,20.4-30.4,30.4c-9.7,9.5-19.6,9.9-30.6,1.6c-6.9-5.3-13.2-14.1-20.7-15.2c-7.3-1.1-15.7,5.6-23.7,8.8
	c-1.6,0.6-3.1,1.6-4.8,1.7c-14.2,1.2-16.8,10.3-17.9,22.6c-2.4,27.9-6.3,30.7-34.5,30.7c-10.7,0-21.4,0.1-32,0
	c-13.3-0.2-20.6-6.6-22.6-19.8c-1.2-8.2-1.6-16.4-3.1-24.5c-0.5-2.5-2.8-5.5-5.1-6.6c-10.3-4.8-20.8-9.2-31.4-13.3
	c-2.2-0.8-5.7-0.5-7.6,0.8c-6.6,4.6-12.6,9.9-19,14.7c-11.1,8.4-20.7,8-30.6-1.6c-10.3-9.9-20.4-20.1-30.4-30.4
	c-9.5-9.8-9.9-19.6-1.6-30.6c4.7-6.2,9.9-12,14.3-18.4c1.5-2.2,2.1-6.3,1.2-8.8c-3.6-10-7.8-19.8-12.3-29.5
	c-1.1-2.3-4.1-4.6-6.6-5.1c-7.1-1.4-14.4-2.1-21.7-2.6c-11.1-0.8-18.4-6.3-22.2-16.7C52.5,284.4,52.5,265.9,52.5,247.5z
	 M274.5,351.2c43.4-0.1,78.8-35.3,78.9-78.7c0.2-43.3-35.9-79.1-79.4-79c-43.1,0.1-78.8,36.1-78.6,79.1
	C195.7,315.9,231.2,351.2,274.5,351.2z" />
    <path class="st0"
          d="M729,599.2c-7.4-4.2-14.5-7.4-20.5-11.9c-5.9-4.4-10.5-4.8-16.6-0.5c-5.7,4-12.2,7-18.8,9.4
	c-5.6,2-7.5,5.2-7.1,10.8c0.3,4.3,0,8.7-0.3,13.1c-0.8,11.8-7.8,19.3-19.6,20.6c-9.9,1.1-19.8,2-29.7,2.7
	c-12.2,0.8-21.9-5.3-23.1-17.3c-1.5-15.8-8-23.3-24-27.3c-11.2-2.8-17.9-3.2-26.1,5.5c-13.4,14.2-21.9,13.8-36.9,1.1
	c-5.9-5-11.9-9.9-17.8-15.1c-10.2-8.9-12.6-19.1-4.3-29.5c9.3-11.6,10.2-20.7,2.2-34.2c-6.2-10.5-11.5-14.8-23.4-14.3
	c-17.7,0.7-24.9-7-26-24.8c-0.5-8-1.4-16-1.8-23.9c-0.8-13.6,4.6-22.1,18-23.7c14.4-1.7,19.5-8.4,23.7-22.1
	c4.2-13.7,3.4-22.2-7.2-31.7c-9.9-8.9-9.4-19.4-1-29.8c5.9-7.4,12-14.6,18.2-21.8c8.6-9.9,19.2-12.3,29.5-4
	c10.8,8.7,19.5,9.1,30.2,0.3c5.5-4.5,15.3-4.8,18.8-10c3.6-5.2,1.6-14,2.5-21.2c1.3-9.5,8-15.8,18.1-17c10.4-1.2,20.8-2.2,31.2-2.8
	c11.8-0.7,21,4.8,22.1,16.4c1.6,15.4,9.7,20.1,23.4,24.4c13.1,4,22,5.8,31.9-5.9c7.5-8.9,18.1-8.1,27.5-0.7
	c8,6.3,15.8,12.9,23.4,19.7c9,8.2,11.3,18.4,3.7,27.9c-8.9,11.1-8.1,19.3-1.5,31.8c6.5,12.4,12,19.1,26.9,17.8
	c12.2-1,19.4,7.5,20.7,19.8c1,9.2,1.8,18.3,2.6,27.5c1.1,14.1-4.5,23.1-18.4,24.9c-13.4,1.7-18.2,8-22.6,20.7
	c-5,14.6-4.5,24,7.5,34.3c8.7,7.4,8.2,17.7,1.2,26.9c-6.7,8.6-13.6,17.2-21.3,24.9C740.6,594,734.9,595.9,729,599.2z M553.1,459.3
	c-0.6,34.4,27.4,63.8,60.4,65.1c38.4,1.6,67.4-28.3,68.1-61.8c0.8-36.2-26.8-65.4-60.8-66.9C582.5,394,552.4,425.5,553.1,459.3z" />
    <path class="st0"
          d="M226.1,723.5c3.2-5.2,6.4-11.6,10.7-17.1c3.4-4.2,3.1-7.3,1.5-12.3c-4.3-12.8-9.7-22.5-25.7-22.6
	c-7.4-0.1-12.6-6.7-13.3-14.3c-0.8-8.9-1-17.9-0.1-26.8c1.1-10.2,7.4-15.8,17.5-17.5c1-0.2,1.9-0.3,2.9-0.3
	c8.6,0.5,11.6-4.3,15-11.8c5.2-11.7,7.4-20.9-2.4-31.5c-5.4-5.8-4.1-14.5,1.3-20.8c5.3-6.3,11.1-12.3,17.4-17.5
	c8.9-7.3,18.1-7,27.6,1.4c5.8,5.1,10.8,6.3,17,1.7c0.6-0.4,1.2-0.8,1.9-1c12.9-2.9,21.6-8.7,22.5-23.8c0.4-7.3,7.7-11.8,15.3-12.4
	c8.2-0.7,16.5-0.8,24.7,0.1c11.7,1.2,17.9,8.7,18.5,22.2c0.3,6.2,2.5,9.4,8.3,10.6c1.4,0.3,2.9,0.7,4.1,1.5c11,7.2,21,8.7,32.2-0.9
	c5.2-4.6,13.3-2.8,18.9,2c6.1,5.2,11.9,10.7,17.1,16.8c7.9,9.2,7.6,18.7-1.3,28.8c-4.8,5.4-5.9,10.1-1.7,15.9
	c0.3,0.4,0.5,0.9,0.6,1.3c2.5,12.6,8.5,20.4,23,21.4c8.7,0.6,13.3,9.1,13.8,19.7c0.2,5.3,0.1,10.7-0.2,16c-0.7,14.7-7.2,22-22.6,23
	c-7.1,0.5-10.6,3-11.8,9.6c0,0.2-0.1,0.5-0.2,0.7c-7,11.5-9.7,22.2,1.3,33.9c5.3,5.7,3.2,14.6-2.3,20.7c-5.3,6-10.9,11.8-17.1,16.8
	c-8.5,7-17.9,6.8-27-1.6c-6.9-6.3-12.2-5.4-20.3-2.5c-12.1,4.2-20.3,8.9-20.8,23.6c-0.3,8.8-8.2,14.1-17.4,14.5
	c-7.7,0.4-15.5,0.4-23.3-0.2c-10.3-0.8-16.9-8.1-18.4-19.3c-0.4-2.6-0.6-5.3-0.7-8c-0.1-4.2-2-6.8-6.2-7.6c-0.5-0.1-1-0.2-1.4-0.5
	c-12-7-23.4-10.3-35.2,1.5c-4.7,4.7-13.1,4.1-18.3-0.3c-7.9-6.7-14.9-14.5-21.8-22.2C228,732.2,227.8,728.5,226.1,723.5z M346,695.2
	c29,0.2,53.2-23.7,53.1-52.4c-0.1-28.7-23.5-52.4-52.1-52.8c-28.5-0.4-52.6,23.2-52.9,51.8C293.7,671.1,317,695,346,695.2z" />
  </svg>
</ng-template>
