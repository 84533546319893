import { VariableTransformation } from '@modules/lang/types/variable-transformation';

export namespace Transformations {

  export class FVarTrans {

    public Transformation: Transformations.Type;
    public Arguments: string;

    public MaArgs: MovingAvgArgs;
    public GaArgs: GeometricAvgArgs;

    public Display?: string;

    public get isMoving() { return Moving.includes(this.Transformation); }
    public get isGeometric() { return Geometric.includes(this.Transformation); }
    public get isGrowth() { return Growth.includes(this.Transformation); }

    constructor(jsonString?: string) {
      const parsed = !!jsonString ? <FVarTrans> JSON.parse(jsonString) : null;
      this.Transformation = parsed?.Transformation || 'no_transform';
      this.Arguments = parsed?.Arguments || null;
      this.MaArgs = new MovingAvgArgs(this.isMoving, this.Arguments);
      this.GaArgs = new GeometricAvgArgs(this.isGeometric, this.Arguments);
    }

    public compare(trans: FVarTrans) {
      return this.Transformation === trans.Transformation && this.Arguments === trans.Arguments;
    }

    public copy() {
      this.setArgument();
      const copy = new FVarTrans(null);
      copy.Transformation = this.Transformation;
      copy.Arguments = this.Arguments;
      copy.GaArgs = new GeometricAvgArgs(this.isGeometric, this.Arguments);
      copy.MaArgs = new MovingAvgArgs(this.isMoving, this.Arguments);
      copy.Display = this.Display;
      return copy;
    }

    public isSame(trans: FVarTrans) {
      if (trans == null) { return false; }
      this.setArgument();
      trans.setArgument();
      return this.Transformation === trans.Transformation && this.Arguments === trans.Arguments;
    }

    public toDto() {
      return { Transformation: this.Transformation, Arguments: this.Arguments };
    }

    public getShortName() {
      let sn = TypeShortName[this.Transformation];
      if (this.isMoving) sn += this.MaArgs.Terms;
      if (this.isGeometric) sn += this.GaArgs.Alpha;
      return sn;
    }

    public getArgString() {
      switch (true) {
        case this.isMoving:
          return `(order: ${this.MaArgs.Terms})`;
        case this.isGeometric:
          return `(alpha: ${this.GaArgs.Alpha})`;
        default:
          return '';
      }
    }

    public setArgument() {
      switch (true) {
        case this.isMoving:
          this.Arguments = JSON.stringify(this.MaArgs); break;
        case this.isGeometric:
          this.Arguments = JSON.stringify(this.GaArgs); break;
        default:
          this.Arguments = null; break;
      }
    }
  }

  export class MovingAvgArgs {
    public Terms: number;

    constructor(isMa: boolean, args?: string) {
      this.Terms = isMa && !!args ? (<MovingAvgArgs> JSON.parse(args)).Terms : 1;
    }
  }

  export class GeometricAvgArgs {
    public Alpha: number;

    constructor(isGa: boolean, args?: string) {
      this.Alpha = isGa && !!args ? (<GeometricAvgArgs> JSON.parse(args)).Alpha : 0.5;
    }
  }

  export type Type = 'no_transform' | 'all' |
    'log' | 'diff_normal' | 'diff_season' |
    'growth_normal' | 'growth_season' | 'growth_annualized' |
    'moving_average' | 'moving_sum' | 'geometric_average';

  export enum TypeShortName {
    'no_transform' = '',
    'log' = 'Log',
    'diff_normal' = 'Diff',
    'diff_season' = 'Diff YoY',
    'growth_normal' = 'Growth',
    'growth_season' = 'Growth YoY',
    'growth_annualized' = 'Growth Annualized',
    'moving_average' = 'MA',
    'moving_sum' = 'MS',
    'geometric_average' = 'GA'
  }

  export const Growth: Type[] = [
    'growth_normal',
    'growth_season',
    'growth_annualized'
  ];

  export const Moving: Type[] = [
    'moving_sum',
    'moving_average'
  ];

  export const Geometric: Type[] = [
    'geometric_average'
  ];

  export const Models: Type[] = [
    'no_transform',
    'all',
    'log',
    'diff_normal',
    'diff_season',
  ];

  export const Variable: Type[] = [
    'no_transform',
    'diff_normal',
    'diff_season',
    'growth_normal',
    'growth_season',
    'growth_annualized',
    'moving_average',
    'moving_sum',
    'geometric_average'
  ];

  export const BVarPrior: Type[] = [
    'no_transform',
    'log',
    'diff_normal',
    'diff_season',
    'growth_normal',
    'growth_season'
  ];

  export class CurrentTransformInfo {
    public Name: string;
    public Trans: Transformations.FVarTrans;
    public Display: VariableTransformation;
    public FVarId?: string;
  }
}
