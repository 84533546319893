import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminSites, NavigateToAdmin } from '@core/actions/navigation.actions';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { WikiActions } from '@core/store/wiki/wiki.actions';
import { WikiPageDTO } from '@core/store/wiki/wiki.entities';
import { WikiService } from '@core/store/wiki/wiki.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngxs/store';

export interface WikiPageDialogData {
  PageSlug: string;
}

@Component({
  selector: 'indicio-wiki-page-dialog',
  templateUrl: 'wiki-page.dialog.html',
  styleUrls: ['wiki-page.dialog.less']
})
export class WikiPageDialogComponent {
  public static Id: string = 'WikiPageDialogComponent';

  public faDownload: IconProp = faFloppyDisk;

  public page: WikiPageDTO;
  public loaded = false;

  constructor(
    public dialogRef: MatDialogRef<WikiPageDialogComponent>,
    public wikiService: WikiService,
    public statusService: StatusService,
    public authService: AuthFrontendService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: WikiPageDialogData) {
    this.wikiService.getOrFetchPage(this.data.PageSlug)
      .then((page) => {
        this.page = page;
        let sizes = page.PageSize;
        if (!sizes) { return; }
        // Update the size of the dialog if the page has a custom size set
        this.dialogRef.updateSize(+sizes.Width.length <= 1 ? 'auto' : sizes.Width, sizes.Height.length <= 1 ? 'auto' : sizes.Height);
      })
      .catch((err) => {
        this.dialogRef.updateSize('400px', '175px');
        this.statusService.setError(err, true);
      })
      .finally(() => this.loaded = true);
  }

  public openEdit() {
    this.dialogRef.close();
    this.store.dispatch(new NavigateToAdmin(AdminSites.wiki, () => {
      setTimeout(() => {
        this.store.dispatch(new WikiActions.OpenAdminEdit(this.page.Slug));
      }, 0);
    }));
  }

  public downloadPDF() {
    this.wikiService.downloadLatestPDF();
  }

  public open(slug: string) {
    this.dialogRef.close();
    this.wikiService.open(slug);
  }

  public pin() {
    this.onNoClick();
    this.store.dispatch(new WikiActions.Pinned(this.page));
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  };
}
