import { Def } from '@core/decorators/def';

export class MacrobondEntityInfoDTO {
  @Def() groups: MacrobondEntityInfoGroupDTO[];

  public getGroup(name: string) {
    return this.groups?.find(x => x.description === name);
  }
}

export class MacrobondEntityInfoGroupDTO {
  @Def() description: string;
  @Def() title: string;
  @Def() items: MacrobondEntityInfoGroupItemDTO[];

  public getInfo(name: string) {
    return this.items?.find(x => x.description === name);
  }
}

export class MacrobondEntityInfoGroupItemDTO {
  @Def() description: string;
  @Def() value: string;
  @Def() valueType: number;
}
