import { Component } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { RemoveForecastVariableSuccessAction } from '@core/store/forecast-variable/forecast-variable.actions';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { SourceVariableUsageDTO, UsedInProjectAndForecastDTO } from '@core/store/source-variable/dtos/used-dto';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { Subscription } from 'rxjs';
import { OpenDeleteSourceVariableModal } from './delete-source-variable-modal.actions';
import { DeleteSourceVarModalOptions } from './delete-source-variable-modal.options';


class UsedInForecast {
  SourceVariableId: string;
  Forecasts: UsedInProjectAndForecastDTO[];
  Groups: SourceVariableUsageDTO[];
}

@Component({
  selector: 'indicio-delete-source-variable-modal',
  templateUrl: './delete-source-variable-modal.component.html',
  styleUrls: ['./delete-source-variable-modal.component.less'],
})
export class DeleteSourceVariableModalComponent extends ModalModelComponent {

  public variables: SourceVariableModel[] = [];
  public usedVariables: UsedInForecast[] = [];
  public callback: any;
  private variableIds: string[] = [];
  private opts: DeleteSourceVarModalOptions;
  sub = new Subscription();

  removedCount = 0;
  gonefishing = false;

  public isLoading = true;

  constructor(
    protected store: Store,
    private status: StatusService,
    private sourceService: SourceVariableFrontendService,
    public envService: EnvironmentService,
    public appearance: AppearanceService,
    private actions: ActionService,
    private dialog: DialogService,
    private fvarService: ForecastVariableFrontendService,
  ) { super(); }

  public setOptions(options: DeleteSourceVarModalOptions) {
    this.opts = options;
    this.variableIds = options.sourceVariableIds;
    this.callback = options.callback;

    this.sourceService.getOrFetchSourceVariablesInBulk(this.variableIds)
      .then(variables => this.variables = variables)
      .then(() => this.syncUsed())
      .finally(() => {
        this.isLoading = false;
        setTimeout(() => {
          if (this.opts.startWithoutConfirmation) {
            this.forceRemoveSources();
          }
        }, 0);
      });

    this.sub.add(this.actions.dispatched(RemoveForecastVariableSuccessAction).subscribe(_x => {
      this.syncUsed();
    }));
  }

  onClose() {
    if (this.callback) {
      this.callback();
    }
  }

  syncUsed() {
    return this.sourceService.getSourceVariableUsedStatus(this.variableIds)
      .then(used => {
        this.usedVariables = [];
        used.forEach(variable => {
          this.usedVariables.push(<UsedInForecast> { Forecasts: variable.Usage, SourceVariableId: variable.SourceVariableId, Groups: variable.Groups });
          if (variable.Usage?.length || variable.Groups?.length) {
            this.variables.find(x => x.SourceVariableId === variable.SourceVariableId).removedStatus = 'Skipped';
          }
        });
      });
  }

  public isUsed(variable: SourceVariableModel) {
    return !!this.usedVariables.find(uv => uv.SourceVariableId === variable.SourceVariableId && (uv.Forecasts?.length || uv.Groups?.length));
  }

  public usedIn(variable: SourceVariableModel) {
    if (this.isUsed(variable)) {
      return this.usedVariables.find(uv => uv.SourceVariableId === variable.SourceVariableId);
    }
    return null;
  }

  public variablesAcceptedToBeRemoved() {
    return this.variables.filter(v => !this.isUsed(v));
  }

  public variablesNotAcceptedToBeRemoved() {
    return this.variables.filter(v => this.isUsed(v));
  }

  public async removeVariables() {
    this.gonefishing = true;
    this.pending = true;
    const toRemove = this.variablesAcceptedToBeRemoved();
    toRemove.forEach(x => x.removedStatus = 'Pending');
    const count = toRemove.length;
    const removeFunc = async (variable) => {
      await this.removeVariable(variable);
    };

    let current = 0;

    const queueFunc = async () => {
      if (current >= count) {
        this.pending = false;
        if (this.opts.startWithoutConfirmation) {
          this.close();
        }
        return;
      }
      await removeFunc(toRemove[current]);
      current += 1;
      setTimeout(async () => {
        await queueFunc();
      }, 150);
    };

    await queueFunc();
  }

  public removeVariable(variable: SourceVariableModel) {
    variable.removedStatus = 'Removing';
    this.sourceService.deleteSourceVariable(variable)
      .then(_x => variable.removedStatus = 'Success')
      .catch(error => {
        variable.removedStatus = 'Failed';
        variable.failedMsg = this.status.getMessage(error).message;
      }).finally(() => this.removedCount += 1);
  }

  public deleteGroup(group: SourceVariableUsageDTO) {
    this.close();
    this.store.dispatch(new OpenDeleteSourceVariableModal(
      [group.SourceVariableId],
      false,
      () => this.store.dispatch(new OpenDeleteSourceVariableModal(this.opts.sourceVariableIds.filter(x => x !== group.SourceVariableId), false, this.opts.callback))
    ));
  }

  public deleteForecastVariable(usage: UsedInProjectAndForecastDTO) {
    const ref = this.dialog.openConfirmDialog({
      Title: 'Delete forecast variable?',
      Message: `Do you want to remove the variable ${usage.ForecastVariableName}<br><br>` +
        `Project: ${usage.ProjectName}<br>` +
        `Forecast: ${usage.ForecastName}<br>`,
      CancelText: 'No',
      ConfirmText: 'Delete',
      Style: 'warn'
    }, { width: '500px' });
    ref.subscribe(ans => {
      if (ans) {
        this.fvarService.deleteForecastVariable(usage.ForecastVariableId, usage.ForecastVersionId)
          .then(() => {
            this.status.setMessage('Forecast varible deleted', 'Success', true);
            this.syncUsed();
          })
          .catch(err => this.status.setError(err, true));
      }
    });
  }


  public async forceRemoveSources() {
    if (this.opts.startWithoutConfirmation) {
      this.pending = true;
      await this.forceRemoveAll();
    } else {
      const ref = this.dialog.openConfirmDialog({
        Title: 'Are you sure you want to delete all forecast and source variables?',
        Message: 'This action will delete all forecast variables connected to the selected source variable and cannot be undone.',
        CancelText: 'No',
        ConfirmText: 'Delete',
        Style: 'warn'
      }, { width: '500px' });
      ref.subscribe(async (ans) => {
        if (ans) {
          const fromGroups = this.usedVariables.filter(x => x.Groups.length > 0);
          if (fromGroups.length) {
            return this.forceRemoveGroupsFirst();
          }
          this.pending = true;
          await this.forceRemoveAll();
        }
      });
    }
  }

  private async forceRemoveGroupsFirst() {
    const fromGroups = this.usedVariables.filter(x => x.Groups.length > 0);
    if (fromGroups.length) {
      const gIds = fromGroups.map(x => x.Groups.map(g => g.SourceVariableId)).flatten();
      this.close();
      return this.store.dispatch(new OpenDeleteSourceVariableModal(
        gIds,
        true,
        () => this.store.dispatch(new OpenDeleteSourceVariableModal(
          this.opts.sourceVariableIds.filter(x => !gIds.includes(x)),
          true,
          null))
      ));
    }
  }

  private async forceRemoveAll() {
    this.status.setMessage('Removing source variables...', 'Success', true);
    const fvar = this.usedVariables.map(source => source.Forecasts?.filter(x => !x.IsNowcast) || []).flatten().filter(e => e != null);
    for (let i = 0; i < fvar.length; i++) {
      try {
        const usage = fvar[i];
        const deleteFVarReponse = await this.fvarService.deleteForecastVariable(usage.ForecastVariableId, usage.ForecastVersionId);
        if (!deleteFVarReponse) {
          this.status.setError(`${usage.ForecastName} cannot be deleted`, true);
          return;
        } else {
          await this.syncUsed();
        }
      } catch (error) {
        this.status.setError(error, true);
        return;
      }
    }
    this.status.setMessage('All forecast variables deleted', 'Success', true);
    this.removeVariables();
  }
}


