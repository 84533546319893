import { Injectable } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';
import { NewsCommentDTO } from './dtos/news-comment.dto';
import { NewsListDTO } from './dtos/news-list.dto';
import { NewsPostMetaDTO } from './dtos/news-post-meta.dto';
import { NewsPostDTO } from './dtos/news-post.dto';
import { NewsCommentModel } from './news-comment.model';
import { NewsListModel } from './news-list.model';
import { NewsPostMetaModel } from './news-post-meta.model';
import { NewsPostModel } from './news-post.model';

@Injectable({
  providedIn: 'root'
})
export class NewsMapper {

  constructor() { }

  public map(dto: NewsPostDTO): NewsPostModel {
    const model = Object.faMapTo(new NewsPostModel, dto);
    if (dto.Comments) {
      model.Comments = dto.Comments.map(c => this.mapComment(c));
    }
    model.Meta = this.mapMeta(dto.Meta);

    model.shouldDisplay = true;

    if (model.Meta.Hidden === true) {
      model.shouldDisplay = false;
    } else if (model.Meta.PublishDate && Date.now() < model.Meta.PublishDate.getTime()) {
      model.shouldDisplay = false;
    }

    return model;
  }

  public mapMeta(dto: NewsPostMetaDTO): NewsPostMetaModel {
    const model = Object.faMapTo(new NewsPostMetaModel, dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.ModifiedDate = DateUtils.newNullableDate(dto.ModifiedDate);
    model.PublishDate = DateUtils.newDate(dto.PublishDate);
    return model;
  }

  public mapComment(dto: NewsCommentDTO): NewsCommentModel {
    const model = Object.faMapTo(new NewsCommentModel, dto);
    model.AuthorId = dto.AuthorId;
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    if (dto.ModifiedDate) {
      model.ModifiedDate = DateUtils.newDate(dto.ModifiedDate);
    }
    return model;
  }

  public mapList(dto: NewsListDTO): NewsListModel {
    const model = Object.faMapTo(new NewsListModel, dto);
    model.NewsPosts = dto.NewsPosts.map(n => this.map(<NewsPostDTO> { Meta: n }));
    return model;
  }
}
