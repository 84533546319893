import { SourceVariableRevisionModalOpts } from './source-variable-revision-modal.options';

export class OpenSourceVariableRevisionModal {
  public static readonly type = '[MODAL] Open source variable revision modal';
  data: SourceVariableRevisionModalOpts;
  constructor(
    public SourceVariableId: string
  ) {
    this.data = {
      SourceVariableId
    };
  }
}
