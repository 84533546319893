import { Injectable } from '@angular/core';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { ActionService } from '@core/services/actions/actions.service';
import { UnivariateBackendService } from '@core/store/stat-model/univariate/univariate.backend.service';
import { UnivariateModelName } from '@modules/lang/language-files/stat-models';
import { ModelName } from '@modules/lang/types/model-name';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClearStateAction } from '../../auth/auth.actions';
import { SetActiveForecastVersionSuccessAction } from '../../forecast/forecast.actions';
import { GetUnivariateModelPlotImagesSuccess, GetUnivariateModelSuccessAction, RemoveUnivariateModelAction, RemoveUnivariateResultsAction } from '../stat-model.actions';
import { StatModel, StatTransformationModel } from '../stat-model.model';
import { StatModelState } from '../stat-model.state';

@Injectable({
  providedIn: 'root'
})
export class UnivariateFrontendService {

  private _loading = new BehaviorSubject<boolean>(false);

  @Select(StatModelState.univariateModels)
  public allModels$: Observable<StatModel[]>;

  public anyLoading = false;
  private _currentModels = new BehaviorSubject<StatModel[]>([]);
  public models$: Observable<StatModel[]>;
  public models: StatModel[] = [];
  public builtModels: StatModel[] = [];
  public allTransformations: StatTransformationModel[] = [];
  public builtTransformations: StatTransformationModel[] = [];
  private _currentlyLoading = {};
  private currentCount = 0;

  private fetchedVersions: string[] = [];
  private currentForecastVersionId: string = null;

  public anyLoading$() { return this._loading.asObservable(); }
  public isModelLoading(forecastVersionId: string, modelName: ModelName) {
    const state = this._currentlyLoading[forecastVersionId];
    return state ? !!state[modelName.Model] : false;
  }

  constructor(
    private store: Store,
    private actions: ActionService,
    private service: UnivariateBackendService,
  ) {
    this.models$ = this._currentModels.asObservable();
    this.initialize();
  }

  public removeModel(forecastVersionId: string, modelName: UnivariateModelName) {
    this.store.dispatch(new RemoveUnivariateModelAction(forecastVersionId, modelName));
  }

  public triggerUnivariateModels(fversionId: string, models: UnivariateModelName[]) {
    this.currentForecastVersionId = fversionId;
    return this.service.triggerUnivariateModels(fversionId, models)
      .then(() => models.forEach(x => this.removeModel(fversionId, x)));
  }

  public triggerUnivariate(forecastVersionId: string) {
    this.currentForecastVersionId = forecastVersionId;
    return this.service.triggerUnivariate(forecastVersionId)
      .then(() => this.store.dispatch(new RemoveUnivariateResultsAction(forecastVersionId)));
  }

  public removeUnivariateModels(fversionId: string, models: UnivariateModelName[]) {
    this.currentForecastVersionId = fversionId;
    return this.service.removeUnivariateModels(fversionId, models)
      .then(() => {
        models.forEach(x => this.removeModel(fversionId, x));
      });
  }

  public fetchModelResidualFiles(fVersionId: string, modelIds: number[] = [], getAll: boolean = false) {
    let result: ResultFileDTO[] = [];
    if (getAll) {
      const models = this.store.selectSnapshot(StatModelState.univariateModels)
        .filter(m => m.ForecastVersionId === fVersionId)
        .filter(m => m.ModelBuilt);

      modelIds = models.filter(m => m.Transforms.some(x => x.ImagesDTO === null)).map(m => m.Transforms.filter(t => t.ModelBuilt).map(t => t.MainModelId)).flatten();
      const intermediate = models.filter(m => m.Transforms.some(x => x.ImagesDTO !== null)).map(m => m.Transforms.filter(t => t.ModelBuilt).map(x => x.ImagesDTO.Files)).flatten();
      result = intermediate.length !== 0 ? intermediate.flatten() : [];
      if (modelIds.length === 0) {
        return Promise.resolve(result);
      }
    }
    return this.service.fetchModelResidualFiles(fVersionId, modelIds)
      .then(fetched => {
        this.store.dispatch(new GetUnivariateModelPlotImagesSuccess(fVersionId, fetched));
        const images = fetched.map(f => f.Files).flatten();
        if (getAll) {
          return [...images, ...result];
        } else {
          return [...images];
        }
      });
  }

  public getModelTextFiles(fversionId: string, models: StatTransformationModel[]) {
    return this.service.fetchModelTextFiles(fversionId, models.map(e => e.MainModelId));
  }

  public getOrFetchById(forecastVersionId) {
    this.currentForecastVersionId = forecastVersionId;
    if (this.fetchedVersions.includes(forecastVersionId)) {
      const models = this.store.selectSnapshot(StatModelState.univariateModels).filter(m => m.ForecastVersionId === forecastVersionId);
      this._currentModels.next(models);
      return Promise.resolve(models);
    } else {
      return this.fetchUnivariateModels(forecastVersionId);
    }
  }

  public setFetched(forecastVersionId: string) {
    this.fetchedVersions.addUniqueId(forecastVersionId);
  }

  public setNotFetched(forecastVersionId: string) {
    this.fetchedVersions.removeId(forecastVersionId);
  }

  public fetchModel(forecastVersionId: string, modelName: UnivariateModelName) {
    this.setLoading(forecastVersionId, modelName);
    return this.service.getUnivariateModel(forecastVersionId, modelName)
      .then(models => {
        models.forEach(model => this.store.dispatch(new GetUnivariateModelSuccessAction(model)));
        return models;
      })
      .finally(() => this.setNotLoading(forecastVersionId, modelName));
  }

  private fetchUnivariateModels(forecastVersionId: string) {
    this.setAllLoading(forecastVersionId);
    this._currentModels.next([]);
    this.store.dispatch(new RemoveUnivariateResultsAction(forecastVersionId));
    return this.service.getAllUnivariateModels(forecastVersionId)
      .then(models => {
        models.forEach(model => this.store.dispatch(new GetUnivariateModelSuccessAction(model)));
        this.setFetched(forecastVersionId);
        this._currentModels.next(models);
        return models;
      })
      .finally(() => this.setAllFinished(forecastVersionId));
  }

  ///
  ///   Private functions below
  ///
  private setLoading(forecastVersionId: string, modelName: UnivariateModelName) {
    if (-1 === Object.keys(this._currentlyLoading).findIndex(k => k === forecastVersionId)) {
      this._currentlyLoading[forecastVersionId] = {};
    }
    this._currentlyLoading[forecastVersionId][modelName] = true;
    this._loading.next(true);
  }

  private setNotLoading(forecastVersionId: string, modelName: UnivariateModelName) {
    if (-1 === Object.keys(this._currentlyLoading).findIndex(k => k === forecastVersionId)) {
      this._currentlyLoading[forecastVersionId] = {};
    }
    this._currentlyLoading[forecastVersionId][modelName] = false;
    if (!Object.values(this._currentlyLoading).some(x => x === true)) {
      this._loading.next(false);
    }
  }

  private setAllLoading(forecastVersionId: string) {
    Object.values(UnivariateModelName).forEach(modelName => this.setLoading(forecastVersionId, modelName));
  }

  private setAllFinished(forecastVersionId: string) {
    Object.values(UnivariateModelName).forEach(modelName => this.setNotLoading(forecastVersionId, modelName));
  }


  private initialize() {
    this.anyLoading$().subscribe(val => this.anyLoading = val);
    this.models$.subscribe((val: StatModel[]) => {
      this.models = val;
      this.builtModels = val.filter(m => m.ModelBuilt);
      this.allTransformations = val.reduce((acc, m) => acc.concat(m.Transforms), []);
      this.builtTransformations = this.allTransformations.filter(x => x.ModelBuilt);
    });
    this.allModels$.subscribe(allModels => {
      if (allModels && this.currentCount !== allModels.length) {
        this.currentCount = allModels.length;
        this._currentModels.next(allModels.filter(x => x.ForecastVersionId === this.currentForecastVersionId));
      }
    });
    this.actions.dispatched(SetActiveForecastVersionSuccessAction).subscribe((action: SetActiveForecastVersionSuccessAction) => {
      this.getOrFetchById(action.model.ForecastVersionId);
    });
    this.actions.dispatched(RemoveUnivariateResultsAction).subscribe((action: RemoveUnivariateResultsAction) => {
      if (this.currentForecastVersionId === action.forecastVersionId) {
        this._currentModels.next([]);
      }
    });
    this.actions.dispatched(ClearStateAction).subscribe(() => {
      this.fetchedVersions = [];
    });
  }
}
