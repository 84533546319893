<div [hidden]="!show"
     data-test-id="status-message-container"
     class="status-msg"
     [ngClass]="[ status.showOnPage || (modal && show) ? 'active' : '' ]"
     [ngStyle]="{'background-color': status.backgroundColor, 'color': status.color }"
     (click)="statusService.removeMessage();">
  <div class="report">
    <fa-icon *ngIf="status.type === 'Error'"
             [icon]="bugIcon"
             style="font-size: 14px;"
             class="pointer"
             (click)="openBugReport()"
             [indicioTippy]="{ content: 'Report bug', placement: 'bottom'}"></fa-icon>
  </div>
  <div>
    <span class="message"
          data-test-id="status-message-text"
          *ngIf="status.message">
      {{ status.message }} </span>
    <div class="errors"
         *ngIf="status.errors?.length"
         data-test-id="status-message-errors">
      <ul>
        <li *ngFor="let error of status.errors">
          <span *ngIf="errorHasLink(error)"
                [innerHTML]="error | safeHtml">
          </span>
          <span *ngIf="!errorHasLink(error)">
            {{error}}
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="text-center">
    <i class="icon-cross pointer"
       data-test-id="status-message-close"
       title="Close message"
       (click)="statusService.removeMessage();"></i>
  </div>
</div>
