
export namespace AuthUtils {

  /**
   * Converts a number to a hex-string representation using upper-case characters.
   * dec2hex :: Integer -> String
   * i.e. 0-255 -> '00'-'ff'
   * @param dec Number to convert
   * @returns
   */
  function dec2hex(dec: number) {
    return dec.toString(16).toUpperCase().padStart(2, '0');
  }

  /**
   * Returns a upper-case HEX formated string with a specified length
   * @param len
   * @returns
   */
  export function getRandomHexString(len: number) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
  }


  /**
   * Hashes the msg using SHA-256, returning the hashed message as a string in HEX format.
   * @param msg
   * @returns
   */
  export async function sha256(msg: string) {
    // encode as (utf-8) Uint8Array
    const msgUint8 = new TextEncoder().encode(msg);
    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    // convert buffer to byte array
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return Array.from(hashArray, dec2hex).join('');
  }
}
