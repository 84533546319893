import { IHasModelId } from '@core/interfaces/if-has-model-id';
import * as moment from 'moment';
import { ValueUtils } from '../utils/value.utils';

declare global {
  interface Array<T> {
    chunk(chunkSize: number): Array<Array<T>>;
    countNumberOfValidNumbers(): number;
    getMoments(): moment.Moment[];
    getFirstComment(): string;
    /** Add or update the supplied model in the list, returning the modified list */
    addOrUpdate(obj: IHasModelId, beginning?: boolean): Array<T>;
    addOrUpdateBy<TKey extends keyof T>(this: Array<T>, obj: T, key: TKey): Array<T>;
    replaceIfExist(obj: IHasModelId): Array<T>;
    /** Remove the element with the supplied model-ID, returning the modified list */
    removeById(id: string): Array<T>;
    removeId(id: string): Array<T>;
    /** Remove the first occurance that satisfies the supplied condition */
    removeFirst(func: Function): Array<T>;
    removeWhere(predicate: (value: T, index: number, array: T[]) => boolean): Array<T>;
    includesAllIds(ids: string[]): boolean;
    groupBy<K>(keyGetter: (item: T) => K): Map<K, T[]>;

    /** Adds the supplied string to the array, if it doesn't already exist. Return true if the id was added. */
    addUniqueId(id: string): boolean;
    /** Remove the element with key equal to value, returning the modified list */
    removeByKey<TKey extends keyof T>(key: TKey, value: any): Array<T>;
    /** Toggle the presence of the object or literal in the array. If the Key parameter is null, uses array.indexOf. Returns true if object was added.*/
    togglePresence<TKey extends keyof T>(this: Array<T>, obj: T, key?: TKey): boolean;
    findByKeyName(key: string): Array<T>;
    distinctBy<TKey extends keyof T>(this: Array<T>, key: TKey): Array<T>;
    findById(id: string): T;
    last(): T;
    findIndexFromEnd(this: Array<T>, func: (a: T) => boolean): number;
    sum(this: Array<T>, key: string): number;
    sum(this: Array<number>): number;
    avg(this: Array<number>): number;
    avg(this: Array<T>, key: string): number;
    max(this: Array<number>): number;
    max<TKey extends keyof T>(this: Array<T>, key?: TKey): number;
    min(this: Array<number>): number;
    min<TKey extends keyof T>(this: Array<T>, key?: TKey): number;
    flatten(): T;
  }
}

export class ArrayExtensions {

  public static Extend() {
    ArrayExtensions.addCountNumberOfValidNumbers();
    ArrayExtensions.addGetFirstComment();
    ArrayExtensions.addAddOrUpdate();
    ArrayExtensions.addAddOrUpdateBy();
    ArrayExtensions.addRemoveById();
    ArrayExtensions.addIncludesAllIds();
    ArrayExtensions.addAddUniqueId();
    ArrayExtensions.addRemoveId();
    ArrayExtensions.addRemoveByKey();
    ArrayExtensions.addfindByKeyName();
    ArrayExtensions.addReplaceIfExist();
    ArrayExtensions.addRemoveFirst();
    ArrayExtensions.addRemoveWhere();
    ArrayExtensions.addTooglePresence();
    ArrayExtensions.addLast();
    ArrayExtensions.addFindIndexFromEnd();
    ArrayExtensions.addSum();
    ArrayExtensions.addFlatten();
    ArrayExtensions.addAvg();
    ArrayExtensions.addMax();
    ArrayExtensions.addMin();
    ArrayExtensions.addFindById();
    ArrayExtensions.addDistinctBy();
    ArrayExtensions.addChunk();
    ArrayExtensions.addGroupBy();
  }

  private static addGroupBy() {
    if (!Array.prototype.groupBy) {
      Array.prototype.groupBy = function <T, K>(this: T[], keyGetter: (item: T) => K): Map<K, T[]> {
        const map = new Map<K, T[]>();
        this.forEach(item => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (collection) {
            collection.push(item);
          } else {
            map.set(key, [item]);
          }
        });
        return map;
      };
    }
  }

  private static addChunk() {
    if (!Array.prototype.chunk) {
      Array.prototype.chunk = function (this: Array<any>, cSize: number): Array<Array<any>> {
        const res = [];
        for (let i = 0; i < this.length; i += cSize) {
          const chunk = this.slice(i, i + cSize);
          res.push(chunk);
        }
        return res;
      };
    }
  }

  private static addAvg() {
    if (!Array.prototype.avg) {
      Array.prototype.avg = function (this: Array<any>, k?: string): number {
        return this.sum(k) / this.length;
      };
    }
  }

  private static addMax() {
    if (!Array.prototype.max) {
      Array.prototype.max = function (this: Array<any>, k?: string): number {
        if (k) { return this.reduce((max, val) => Math.max(max[k], val), Number.NEGATIVE_INFINITY); }
        return this.reduce((max, val) => Math.max(max, val), Number.NEGATIVE_INFINITY);
      };
    }
  }

  private static addMin() {
    if (!Array.prototype.min) {
      Array.prototype.min = function (this: Array<any>, k?: string): number {
        if (k) { return this.reduce((min, val) => Math.min(min[k], val), Number.POSITIVE_INFINITY); }
        return this.reduce((min, val) => Math.min(min, val), Number.POSITIVE_INFINITY);
      };
    }
  }

  private static addSum() {
    if (!Array.prototype.sum) {
      Array.prototype.sum = function (this: Array<any>, k?: string) {
        if (k) {
          let acc = 0;
          for (let i = 0; i < this.length; i++) {
            acc += this[i][k];
          }
          return acc;
        }
        let acc = 0;
        for (let i = 0; i < this.length; i++) {
          acc += this[i];
        }
        return acc;
      };
    }
  }

  private static addFlatten() {
    if (!Array.prototype.flatten) {
      Array.prototype.flatten = function () {
        return this.reduce((a, b) => [...a, ...b], []);
      };
    }
  }

  private static addRemoveFirst() {
    if (!Array.prototype.removeFirst) {
      Array.prototype.removeFirst = function (fn: Function) {
        const idx = this.findIndex(fn);
        if (idx !== -1) { this.splice(idx, 1); }
        return this;
      };
    }
  }

  private static addDistinctBy() {
    if (!Array.prototype.distinctBy) {
      Array.prototype.distinctBy = function (k) {
        let unique = [];
        let distinct = [];
        for (let i = 0; i < this.length; i++) {
          if (!unique[this[i][k]]) {
            distinct.push(this[i]);
            unique[this[i][k]] = 1;
          }
        }
        return distinct;
      };
    }
  }

  private static addRemoveWhere() {
    if (!Array.prototype.removeWhere) {
      Array.prototype.removeWhere = function (fn: Function) {
        let currentIdx = -1;
        while ((currentIdx = this.findIndex(fn)) > -1) {
          this.splice(currentIdx, 1);
        }
        return this;
      };
    }
  }

  private static addTooglePresence() {
    if (!Array.prototype.togglePresence) {
      Array.prototype.togglePresence = function (obj, key = null) {
        const index = key === null
          ? this.indexOf(obj)
          : this.findIndex(v => v[key] === obj[key]);
        if (index === -1) { this.push(obj); }
        else { this.splice(index, 1); }
        return index === -1;
      };
    }
  }

  private static addFindIndexFromEnd() {
    if (!Array.prototype.findIndexFromEnd) {
      Array.prototype.findIndexFromEnd = function (fn: Function) {
        for (let i = this.length - 1; i > 0; i--) {
          if (fn(this[i])) { return i; }
        }
        return -1;
      };
    }
  }

  private static addLast() {
    if (!Array.prototype.last) {
      Array.prototype.last = function () {
        return this[this.length - 1];
      };
    }
  }

  private static addfindByKeyName() {
    if (!Array.prototype.findByKeyName) {
      Array.prototype.findByKeyName = function (key: string) {
        let match;
        this.forEach(s => {
          Object.keys(s).forEach(k => {
            if (k === key) {
              match = s[k];
            }
          });
        });
        return match;
      };
    }
  }

  private static addRemoveByKey() {
    if (!Array.prototype.removeByKey) {
      Array.prototype.removeByKey = function (key, value) {
        const idx = this.findIndex(x => x[key] === value);
        if (idx !== -1) { this.splice(idx, 1); }
        return this;
      };
    }
  }

  private static addRemoveId() {
    if (!Array.prototype.removeId) {
      Array.prototype.removeId = function (id: string) {
        const idx = this.findIndex(x => x === id);
        if (idx !== -1) { this.splice(idx, 1); }
        return this;
      };
    }
  }

  private static addAddUniqueId() {
    if (!Array.prototype.addUniqueId) {
      Array.prototype.addUniqueId = function (id: string) {
        const idx = this.findIndex(x => x === id);
        if (idx === -1) { this.push(id); }
        return idx === -1;
      };
    }
  }

  private static addRemoveById() {
    if (!Array.prototype.removeById) {
      Array.prototype.removeById = function (id: string) {
        const idx = this.findIndex(x => x.getModelId() === id);
        if (idx !== -1) { this.splice(idx, 1); }
        return this;
      };
    }
  }

  private static addFindById() {
    if (!Array.prototype.findById) {
      Array.prototype.findById = function (id: string) {
        return this.find(x => x.getModelId() === id);
      };
    }
  }

  private static addIncludesAllIds() {
    if (!Array.prototype.includesAllIds) {
      Array.prototype.includesAllIds = function (ids: string[]) {
        let allPresent = true;
        for (const id of ids) {
          if (this.findIndex(x => x.getModelId() === id) === -1) {
            allPresent = false;
            break;
          }
        }
        return allPresent;
      };
    }
  }

  private static addReplaceIfExist() {
    if (!Array.prototype.replaceIfExist) {
      Array.prototype.replaceIfExist = function (obj: IHasModelId) {
        const idx = this.findIndex(x => x.getModelId() === obj.getModelId());
        if (idx === -1) {
          this.push(obj);
        } else {
          this[idx] = obj;
        }
        return this;
      };
    }
  }

  private static addAddOrUpdate() {
    if (!Array.prototype.addOrUpdate) {
      Array.prototype.addOrUpdate = function (obj: IHasModelId, beginning = false) {
        const idx = this.findIndex(x => x.getModelId() === obj.getModelId());
        if (idx === -1) {
          if (beginning === false) {
            this.push(obj);
          } else {
            this.unshift(obj);
          }
        } else {
          Object.assign(this[idx], obj);
        }
        return this;
      };
    }
  }

  private static addAddOrUpdateBy() {
    if (!Array.prototype.addOrUpdateBy) {
      Array.prototype.addOrUpdateBy = function (obj, key) {
        const idx = this.findIndex(x => x[key] === obj[key]);
        if (idx === -1) {
          this.push(obj);
        } else {
          Object.assign(this[idx], obj);
        }
        return this;
      };
    }
  }

  private static addGetFirstComment() {
    if (!Array.prototype.getFirstComment) {
      Array.prototype.getFirstComment = function (): string {
        for (let i = 0; i < this.length; i++) {
          const current = this[i];
          if (current.length > 0 && !ValueUtils.isValidValue(current)) {
            return current;
          }
        }
        return null;
      };
    }
  }

  private static addCountNumberOfValidNumbers() {
    if (!Array.prototype.countNumberOfValidNumbers) {
      Array.prototype.countNumberOfValidNumbers = function (): number {
        let freqs = 0;
        for (let i = 0; i < this.length; i++) {
          const current = this[i];
          if (current !== ''
            && (ValueUtils.isValidValueRe.test(current)
              || ValueUtils.isPercentRe.test(current)
              || ValueUtils.isLargeValueRe.test(current))
          ) {
            freqs++;
          }
        }
        return freqs;
      };
    }
  }
}
