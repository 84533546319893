<indicio-modal-base [child]="this"
                    modalTitle="Change Company"
                    [loading]=isLoading>

  <div *ngIf="!isLoading">
    <div class="row">
      <div class="col-xs-12">Confirm your password to change to a new company</div>
    </div>
    <form #form="ngForm"
          (keydown.enter)="proceed()"
          (ngSubmit)="proceed()">
      <div class="row">
        <div class="col-xs-12">

          <mat-form-field>
            <mat-label>Password</mat-label>
            <input type="password"
                   class="material"
                   matInput
                   autocomplete="password"
                   [(ngModel)]="passToVerify"
                   name="Name"
                   indicioAutoFocusInput
                   required>
          </mat-form-field>
        </div>
      </div>
      <button class="btn blue outline"
              (click)="close()">
        Cancel
      </button>
      <button class="btn pull-right"
              type="submit">
        <span *ngIf="pending"
              class="ion-load-c spinner"></span>
        <span *ngIf="!pending">Change</span>

      </button>
    </form>
  </div>
</indicio-modal-base>
