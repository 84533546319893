<div class="active-filters">
  <ng-container *ngFor="let filter of basicFilters">
    <ng-container *ngTemplateOutlet="filterTemplate; context: { filter }"></ng-container>
  </ng-container>

  <ng-container *ngFor="let arrayFilter of arrayFilters">
    <ng-container *ngFor="let filterValue of arrayFilter.value">
      <ng-container *ngTemplateOutlet="filterTemplate; context: { filter: arrayFilter, arrayValue: filterValue }">
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #filterTemplate
             let-filter="filter"
             let-arrayValue="arrayValue">
  <div class="filter"
       (click)="removeFilter(filter, arrayValue)">
    <div class="max-length crop-string">
      <span title="{{ filter.key }}: {{ filter.value }}"
            [attr.data-test-id]="'active-filters-' + filter.type.toLowerCase() "
            *ngIf="filter.type === 'regex'">
        {{ filter.key }}: {{ filter.value }}
      </span>

      <span title="{{ filter.label }}: {{ filter.value }}"
            [attr.data-test-id]="'active-filters-' + filter.type.toLowerCase() "
            *ngIf="filter.type === 'date'">
        {{ filter.label }}: {{ filter.value | dateFormat }}
      </span>

      <span title="{{ filter.label }}"
            [attr.data-test-id]="'active-filters-' + filter.type.toLowerCase() "
            *ngIf="filter.type === 'boolean'">
        {{ filter.label }}
      </span>

      <span title="{{ filter.label }}: {{ getArrayDisplay(arrayValue, filter) }}"
            [attr.data-test-id]="'active-filters-' + filter.type.toLowerCase() "
            *ngIf="filter.type === 'dropdown' || filter.type === 'dropdown-match'">
        {{ filter.label }}: {{ getArrayDisplay(arrayValue, filter) }}
      </span>
    </div>

    <span title="Hidden by filter"
          *ngIf="filter.itemsRemoved && filter.itemsRemoved > 0">
      ({{ filter.itemsRemoved }})
    </span>

    <i class="remove ion-close-circled"></i>
  </div>
</ng-template>
