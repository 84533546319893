import { FredCategoryModel } from '@core/store/providers/fred/fred-category.model';
import { FredDataSerieModel } from '@core/store/providers/fred/fred-dataserie.model';
import { FredTagModel } from '@core/store/providers/fred/fred-tag.model';

export class GetFredCategorySubCategoriesSuccessAction {
  public static readonly type = '[FRED] Get subcategories';
  constructor(
    public categoryId: number,
    public categories: FredCategoryModel[]
  ) { }
}

export class GetFredCategoryDataSeriesSuccessAction {
  public static readonly type = '[FRED] Get dataseries';
  constructor(
    public categoryId: number,
    public series: FredDataSerieModel[]
  ) { }
}

export class GetFredStoredCategoriesMetaAction {
  public static readonly type = '[FRED] Get stored categories';
  constructor() { }
}

export class GetFredStoredTagsAction {
  public static readonly type = '[FRED] Get stored tags';
  constructor() { }
}

export class GetFredTagsSuccessAction {
  public static readonly type = '[FRED] Get tags';
  constructor(public tags: FredTagModel[]) { }
}


export class ToggleFredSerieInQueueSuccess {
  public static readonly type = '[FRED] Toggle serie in queue';
  constructor(public serie: FredDataSerieModel, public imported?: boolean) { }
}

export class ClearFredQueueSuccess {
  public static readonly type = '[FRED] Clear queue';
  constructor() { }
}
