import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { FredDataSerieModel } from '@core/store/providers/fred/fred-dataserie.model';
import { FredObservationsModel } from '@core/store/providers/fred/fred-observations.model';
import { ClearFredQueueSuccess, ToggleFredSerieInQueueSuccess } from '@core/store/providers/fred/fred.actions';
import { FredObservationAggregation, FredObservationFrequecy } from '@core/store/providers/fred/fred.backend.service';
import { FredFrontendService } from '@core/store/providers/fred/fred.frontend.service';
import { FredMapper } from '@core/store/providers/fred/fred.mapper';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { ProviderService } from '@core/store/providers/provider.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Store } from '@ngxs/store';
import { AlgConverter } from '@shared/components/line-graph/alg-line.utils';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgOptions } from '@shared/components/line-graph/alg.options';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateUtils } from '@shared/utils/date.utils';
import { OpenFREDImportModal } from './fred-import-modal.actions';
import { FredImportModalOpts } from './fred-import-modal.options';

export type FredImportView = 'Import' | 'PreviewData';

@Component({
  selector: 'indicio-fred-import-modal',
  templateUrl: './fred-import-modal.component.html'
})
export class FREDImportModalComponent extends ModalModelComponent {

  modalTitle = 'Import variable from FRED';

  series: FredDataSerieModel;
  forecast: ForecastModel;
  forecastVersion: ForecastVersionModel;
  processQueue: boolean;
  periodicity: FredObservationFrequecy;
  displayPeriodicity: PeriodicityType;
  aggregation: FredObservationAggregation = 'avg';
  data: FredObservationsModel = null;
  dataLoader = false;
  dataLine: ALGLineModel = null;
  graphOptions: AlgOptions.Options;

  remoteDataModel = new RemoteDataRequestModel;
  createdSourceVariables: SourceVariableModel[] = [];

  public aggregationNeeded: boolean;

  nameConflict: boolean;
  nameMustChange: boolean;

  view: FredImportView = 'Import';

  periodicityOptions = [
    {
      Display: 'Daily',
      Value: 'd'
    },
    {
      Display: 'Weekly',
      Value: 'w'
    },
    {
      Display: 'Monthly',
      Value: 'm'
    },
    {
      Display: 'Quarterly',
      Value: 'q'
    },
    {
      Display: 'Yearly',
      Value: 'a'
    },
  ];

  aggregationOptions = [
    {
      Display: 'Average',
      Value: 'avg'
    },
    {
      Display: 'Sum',
      Value: 'sum'
    },
    {
      Display: 'End of Period',
      Value: 'eop'
    }
  ];

  loading = false;

  public get disabled() {
    return this.nameConflict || this.nameMustChange || !!!this.aggregation;
  }

  constructor(
    public store: Store,
    public service: FredFrontendService,
    private fcService: ForecastFrontendService,
    private fvarService: ForecastVariableFrontendService,
    private providerService: ProviderService,
    private clientService: ClientFrontendService,
    private statusService: StatusService,
    public envService: EnvironmentService,
    private fredMapper: FredMapper,
    private fvarMapper: ForecastVariableMapper,
    private sourceVarService: SourceVariableFrontendService
  ) {
    super();
  }

  public async setOptions(options: FredImportModalOpts) {
    this.series = options.series;
    this.forecastVersion = options.forecastVersion;
    this.forecast = await this.fcService.getOrFetchForecast(this.forecastVersion.ForecastId);
    this.processQueue = options.processQueue;
    this.createdSourceVariables = options.createdSourceVariables || [];

    if (this.service.queueNotImported.length) {
      if (this.processQueue) {
        this.modalTitle += ` (${this.service.serieInQueueIndex(this.series.id) + 1} / ${this.service.queue.length})`;
      } else {
        this.modalTitle += ` (1 / ${this.service.queue.length + 1})`;
      }
    }

    this.periodicity = options.series.frequency_short.slice(0).toLowerCase() as FredObservationFrequecy;

    if (this.periodicity === 'w') {
      this.periodicityOptions = this.periodicityOptions.slice(1);
    } else if (this.periodicity === 'm') {
      this.periodicityOptions = this.periodicityOptions.slice(2);
    } else if (this.periodicity === 'q') {
      this.periodicityOptions = this.periodicityOptions.slice(3);
    } else if (this.periodicity === 'a') {
      this.periodicityOptions = this.periodicityOptions.slice(4);
    }

    this.remoteDataModel.Name = options.series.title.substring(0, 256);
    this.remoteDataModel.Provider = DataProvider.fred;
    this.remoteDataModel.MetaData = options.series.faKeyValueArray();

    this.isLoading = false;
  }

  public changeView(newView: FredImportView) {
    this.view = newView;
    this.onViewChange();
  }

  private async onViewChange() {
    switch (this.view) {
      case 'PreviewData': {
        await this.getObservations();
        this.updateGraphData();
      }
      case 'Import':
      default:
    }
  }

  private updateGraphData() {
    this.dataLine = AlgConverter.fromRawFredData(this.data, this.remoteDataModel.Name);
    this.displayPeriodicity = this.fredMapper.mapFrequencyToPeriodicity(this.periodicity);
    this.setGraphOptions();
  }

  public toggleQueue() {
    this.store.dispatch(new ToggleFredSerieInQueueSuccess(this.series));
    this.close();
  }

  public periodicityChanged(periodicity: MatSelectChange) {
    this.periodicity = periodicity.value;
    const realPeriodicity = this.periodicityOptions.find(x => x.Value === this.periodicity).Display;
    const p = DateUtils.convertFromLyFormat(realPeriodicity);
    this.aggregationNeeded = p !== this.forecastVersion.Periodicity;
  }

  public getAggregationMethods(variable: SourceVariableModel) {
    return this.envService.getValidAggregationMethods(variable, this.forecastVersion.Periodicity);
  }

  private async getObservations() {
    this.dataLoader = true;
    if (!this.data) {
      this.data = await this.service.fetchObservations({
        aggregation_method: this.aggregation,
        frequency: this.periodicity as FredObservationFrequecy,
        series_id: this.series.id
      });
    }
    this.dataLoader = false;
    return this.data;
  }

  private checkPeriodicity(peridocity: string) {
    return peridocity !== this.forecastVersion.Periodicity;
  }

  public async addVariable() {
    this.loading = true;
    try {
      const observationsList = await this.getObservations();

      this.remoteDataModel.Data = observationsList;
      this.remoteDataModel.RemoteUri = observationsList.uri;
      this.remoteDataModel.RemoteReferenceId = this.series.id;

      this.providerService.addVariableFromDatasource(this.clientService.activeCompany.CompanyId, this.remoteDataModel)
        .then(sourcevariable => {
          const tmpAggregationMethod = this.fredMapper.mapAggregationMethod(this.aggregation);
          const validAgg = this.envService.getValidAggregationMethods(sourcevariable, this.forecastVersion.Periodicity).some(x => {
            return x.Value === tmpAggregationMethod;
          });
          sourcevariable._tmpAggregationMethod = tmpAggregationMethod;

          this.createdSourceVariables.push(sourcevariable);

          if (!validAgg) {
            setTimeout(() => this.statusService.setMessage('Variable created successfully, but chosen aggregation method is invalid', 'Warning', true), 10);
            this.close();
            return this.openCreateForecastModal(sourcevariable);
          }

          this.close();

          if (!this.processQueue) {
            return this.fvarService.createForecastVariable(this.fvarMapper.mapFromSourceVariableModel(sourcevariable, this.forecastVersion.ForecastVersionId))
              .then(() => {
                this.statusService.setMessage('Variable created successfully', 'Success');
                this.store.dispatch(new ClearFredQueueSuccess);
              })
              .catch(err => {
                this.statusService.setError(err);
              });
          }

          this.statusService.setMessage('Variable created successfully', 'Success', true);

          if (this.processQueue || this.service.isSerieInQueue(this.series.id)) {
            this.store.dispatch(new ToggleFredSerieInQueueSuccess(this.series, true));
          }
          if (this.service.queueNotImported.length) {
            this.store.dispatch(new OpenFREDImportModal(this.service.queueNotImported[0], this.forecastVersion, true, this.createdSourceVariables));
          } else {
            this.store.dispatch(new ClearFredQueueSuccess);
            this.openCreateForecastModal(sourcevariable);
          }
        })
        .catch(err => this.statusService.setError(err, true))
        .finally(() => this.loading = false);
    } catch (error) {
      this.statusService.setMessage('Error fetching observations', 'Error', true);
    }
  }

  private openCreateForecastModal(sourcevariable: SourceVariableModel) {
    this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, this.createdSourceVariables.map(x => x.SourceVariableId), null, null,
      this.checkPeriodicity(sourcevariable.Periodicity) ?
        {
          message: `FRED returned the variable in ${this.envService.getPeriodicity(sourcevariable.Periodicity).Display.toLowerCase()}
              periodicity when queried for ${this.envService.getPeriodicity(this.forecastVersion.Periodicity).Display.toLowerCase()}`
        } : null));
  }

  private setGraphOptions() {
    const header = this.remoteDataModel.Name;

    this.graphOptions = AlgOptions.CreateOptions({
      noDataText: '',
      inModal: true,
      menuConfig: {
        showMenu: false,
      },
      dontShowCIHover: true,
      axisConfig: {
        yAxisPosition: 'floating',
      },
      isPercent: false,
      closeCallback: () => this.close(),
      header: header,
      subheader: null,
      dates: this.dataLine.Values.map(x => x.m),
      showOnlyHistoric: true
    });
  }

  public changeName(newName: string) {
    this.remoteDataModel.Name = newName.trim();
    this.checkNameErrors();
  }

  private checkNameErrors() {
    this.nameMustChange = this.remoteDataModel.Name.length < 2 || this.remoteDataModel.Name.length > 127;
    this.nameConflict = this.sourceVarService.sourceVariables.map(x => x.Name).includes(this.remoteDataModel.Name);
  }
}
