import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { VarSelectSettingsDTO } from '@core/store/forecast/dtos/var-select-settings/var-select-settings-dto';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProjectModel } from '@core/store/project/project.model';
import { LassoVARSupport, LassoVARXSupport, VSLassoStruct, VSMode } from '@modules/lang/language-files/var-select';

@Component({
  selector: 'indicio-fcast-dialog-indicator-analysis-tab',
  templateUrl: './fcast-tab.indicator-analysis.component.html',
  styleUrls: ['./fcast-tab.indicator-analysis.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FCastSettingsDialogIndicatorTabComponent implements OnChanges {

  @Input() forecast: ForecastModel;
  @Input() forecastVersion: ForecastVersionModel;
  @Input() project: ProjectModel;
  @Input() canUpdateForecast: boolean;

  @Output() closeDialogEvent = new EventEmitter();

  // New settings
  public vsSettings: VarSelectSettingsDTO;
  public newStepChosen: number;
  // Old settings
  private vsSettingsBackup: VarSelectSettingsDTO;

  public vsMode: VSMode = { Display: '', Description: '', Value: 'coef' };
  public stepwiseOptions: number[] = [];
  public validLassoStructs: VSLassoStruct[];

  public pending: boolean = false;

  public get hasExogenous() { return this.forecastVersion.ImportedHistoricBaseEventIds.length > 0 || this.forecastVersion.IndicatorVariables.some(x => x.IsExogenous); }
  public get measurementActive() { return this.vsSettings.VSMode === 'backward'; }
  public get changed() { return JSON.stringify(this.vsSettings) !== JSON.stringify(this.vsSettingsBackup) || this.fverChanged; }
  private get changedExceptMode() { return JSON.stringify(this.withoutMode(this.vsSettings)) !== JSON.stringify(this.withoutMode(this.vsSettingsBackup)); }
  private get fverChanged() { return this.newStepChosen !== this.forecastVersion.StepChosen; }

  constructor(
    private cd: ChangeDetectorRef,
    private forecastService: ForecastFrontendService,
    private status: StatusService,
    public envService: EnvironmentService
  ) {
  }

  private withoutMode(settings: VarSelectSettingsDTO) {
    const without = { ...settings };
    delete without.VSMode;
    return without;
  }

  public ngOnChanges() {
    if (!this.forecast || !this.forecastVersion) { return; }
    const maxAllowed = this.forecastVersion.Horizon + 1;
    this.stepwiseOptions = Array.from(Array(maxAllowed)).map((_, i) => i);

    let structs = this.envService.VSLassoStructs;
    if (this.hasExogenous) {
      structs = structs.filter(x => LassoVARXSupport.includes(x.Value));
    }
    if (!this.hasExogenous) {
      structs = structs.filter(x => LassoVARSupport.includes(x.Value));
    }

    this.validLassoStructs = structs;

    this.forecastService.getVarSelectSettings(this.forecast.ForecastId, this.forecastVersion.ForecastVersionId).then(this.setSettings.bind(this));

    this.cd.detectChanges();
  }

  private setSettings(dto: VarSelectSettingsDTO) {
    this.vsSettingsBackup = Object.assign(new VarSelectSettingsDTO, dto);
    this.vsSettings = Object.assign(new VarSelectSettingsDTO, dto);
    this.newStepChosen = this.forecastVersion.StepChosen;
  }

  public setVsMode(change: MatSelectChange) {
    this.vsSettings.VSMode = change.value;
    this.vsMode = this.envService.getVarSelectMode(this.vsSettings.VSMode);

    // STEPWISE
    if (change.value === 'stepwise') {
      this.vsSettings.VSLassoStruct = this.hasExogenous ? 'EFX' : 'Basic';
      this.vsSettings.VSUseRollingWindow = false;
      this.vsSettings.VSMeasurement = 'MAE';
    } else if (change.value === 'coef') {
      // COEF
      this.vsSettings.VSLassoStruct = this.hasExogenous ? 'EFX' : 'HVARELEM';
      this.vsSettings.VSMeasurement = 'COEF';
    } else {
      // BACKWARD
      this.vsSettings.VSLassoStruct = this.hasExogenous ? 'EFX' : 'Basic';
      this.vsSettings.VSMeasurement = 'OOS';
    }
    if (!this.changedExceptMode && this.vsSettingsBackup.VSMode === change.value) {
      this.vsSettings = { ...this.vsSettingsBackup };
    }
  }

  public save() {
    if (!this.changed) { return Promise.resolve(); }
    return this.forecastService.saveVarSelectSettings(this.forecast.ForecastId, this.forecastVersion.ForecastVersionId, this.vsSettings)
      .then(saved => {
        this.status.setMessage('Indicator analysis settings saved', 'Success', true);
        if (this.fverChanged) {
          return this.forecastService.updateForecastVersion(this.forecastVersion, { StepChosen: this.newStepChosen })
            .then(() => saved);
        }
        return saved;
      })
      .then((saved) => { this.setSettings(saved); })
      .catch(err => {
        this.status.setError(err, true);
      })
      .finally(() => this.pending = false);
  }
}
