import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {

  transform(array: Array<any>, orderField: string, trigger: number = 0, orderType: boolean = false): Array<any> {
    if (trigger) { } // Dummy
    if (!array) { return; }
    if (orderField === null) { return array; }

    /** _d is for company admin */
    if (orderField.match(/date|occured|createddate|lastbaseobservation|_d/ig)) {
      array.sort((a: any, b: any) => {
        const left = a[orderField] instanceof Date ? a[orderField].getTime() : Number(a[orderField]);
        const right = b[orderField] instanceof Date ? b[orderField].getTime() : Number(b[orderField]);
        return (orderType === true) ? right - left : left - right;
      });
    } else {
      array.sort((a: any, b: any) => {
        const ae = a[orderField];
        const be = b[orderField];
        if ((ae === undefined || ae === null) && (be === undefined || be === null)) { return 0; }
        if ((ae === undefined || ae === null) && (be !== undefined && be !== null)) { return orderType ? 1 : -1; }
        if ((ae !== undefined && ae !== null) && (be === undefined || be === null)) { return orderType ? -1 : 1; }
        if (ae === be) { return 0; }
        return orderType ? (ae.toString().toLowerCase() > be.toString().toLowerCase() ? -1 : 1) : (be.toString().toLowerCase() > ae.toString().toLowerCase() ? -1 : 1);
      });
    }
    return array;
  }
}
