import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

export class SearchDropDownDTO {
  public value: string;
  fn = (callback) => callback();
}

@Component({
  selector: 'indicio-autocomplete',
  templateUrl: './autocomplete.component.html'
})
export class AutocompleteComponent<T = any> {

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() searchLoading: Boolean = false;
  @Input() filteredAutoComplete: T[];
  @Input() extraClass = '';
  @Input() label: string;
  @Input() title: string = 'title';
  @Input() keyword: string = '';
  @Input() suffix: string = null;

  @Output() changeEvent = new EventEmitter<string>();
  @Output() clearEvent = new EventEmitter();
  @Output() selectEvent = new EventEmitter<T>();

  public onSelect(value: T) {
    this.changeEvent.emit(value[this.title]);
    this.selectEvent.emit(value);
  }

  public changed(search: string) {
    this.changeEvent.emit(search);
  }

  public clear() {
    this.input.nativeElement.value = '';
    this.keyword = null;
    this.clearEvent.emit();
  }
}
