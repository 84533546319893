<div class="file-info-general-tab">
  <ng-container *ngTemplateOutlet="infoTemplate"></ng-container>
</div>

<ng-template #infoTemplate>

  <div class="grid-system"
       style="--grid-template: 270px 1fr">
    <div>
      <div class="input-label bold">Filename</div>
      <span class="subtitle">Used for searching and browsing</span>
    </div>
    <div>
      <mat-form-field style="margin-top: 25px">
        <input data-test-id='file-info-name-input'
               type="text"
               matInput
               class="material"
               autocomplete="off"
               [ngModel]="newName"
               (ngModelChange)="changeFilename($event)"
               name="Name"
               maxlength="64"
               required>
        <span *ngIf="!!suffix"
              class="file-extension"
              matTextSuffix>{{ suffix }}</span>
      </mat-form-field>
    </div>
  </div>

  <div class="grid-system center-vertical"
       style="--grid-template: 270px 1fr 40px; margin-top: 15px">
    <div class="bold">Version history</div>
  </div>

  <div class="grid-system center-vertical"
       style="--grid-template: 270px 1fr 40px; margin-top: 10px">
    <ng-container *ngFor="let version of file.Versions; let index = index">
      <div>Version {{ index + 1 }}</div>
      <div class="text-right">{{ version.CreatedDate | dateFormat: appearance.FullFormat }}</div>
      <div style="margin: auto"
           [indicioTippy]="'Download file version ' + (index + 1) + ' (uploaded ' + (version.CreatedDate | dateFormat: appearance.FullFormat) + ')'">
        <fa-icon [icon]="downloadIcon"
                 (click)="downloadVersion(file, version)"></fa-icon>
      </div>
    </ng-container>
  </div>

</ng-template>
