<ng-container *ngIf="(loggedIn$ | async) && activePage">
  <div indicioResizable
       resizeTarget="1"
       (resizeEvent)="resize($event)"
       class="wiki-sidebar-container"
       [class.dark]="mode === 'dark'"
       [style.top.px]="scrollPosition">
    <div resizeHandle="1"
         class="resize-handle"></div>
    <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #contentTemplate>
  <div class="content"
       [style.paddingBottom.px]="wikiService.pageHistory?.length ? 66 : 20">
    <h1 class="wiki-title">{{ activePage.Title }}</h1>

    <div class="cursor-pointer pin"
         style="position: absolute; top: 10px;"
         (click)="unpin()">
      <span>Open in dialog</span>
      <i class="ion-ios-browsers-outline"></i>
    </div>

    <indicio-light-dark-button
                               style="position: absolute; right: 50px; top: 10px; transform: scale(0.95);"></indicio-light-dark-button>
    <span class="cursor-pointer"
          style="position: absolute; right: 20px; top: 10px;">
      <i (click)="close(true)"
         style="font-size: 20px;"
         class="ion-close-round"></i>
    </span>
    <div class="wiki-content">
      <div class="margin-bottom-10">
        <indicio-wiki-snackbar [page]="activePage"
                               (openEvent)="open($event)"></indicio-wiki-snackbar>
      </div>
      <indicio-wiki-page [darkMode]="mode === 'dark'"
                         [page]="activePage"
                         [showTitle]="false">
      </indicio-wiki-page>
    </div>
    <div *ngIf="wikiService.pageHistory?.length"
         style="margin-top: 10px">
      <button mat-stroked-button
              (click)="back()">Back</button>
    </div>
  </div>
</ng-template>
