import * as moment from 'moment';
import { ALGTypes } from './alg-types';
import { AlgConstants } from './alg.constants';

export class StrokeDefinition {
  Type: string;
  Opt: any;
  W: string;
}

export class StrokeSetups {
  // General stroke setups
  public static AxisLine: StrokeDefinition = { Type: 'stroke', Opt: ('1'), W: '1px' };
  public static AxisLineDashed: StrokeDefinition = { Type: 'stroke-dasharray', Opt: ('2, 2'), W: '1px' };
  // Value lines below
  public static Scenario: StrokeDefinition = { Type: 'stroke-dasharray', Opt: ('20,5'), W: '2px' };
  public static Result: StrokeDefinition = { Type: 'stroke-dasharray', Opt: ('11,5'), W: '2px' };
  public static Benchmark: StrokeDefinition = { Type: 'stroke-dasharray', Opt: ('15,5'), W: '2px' };
  public static Def2: StrokeDefinition = { Type: 'stroke-dasharray', Opt: ('8,3'), W: '2px' };
  public static Def3: StrokeDefinition = { Type: 'stroke-dasharray', Opt: ('4,4'), W: '2px' };
  public static SolidLine: StrokeDefinition = { Type: 'stroke', Opt: ('1'), W: '2px' };
}

export class AlgChartStyles {
  backgroundColor?: string;
  colorScheme?: string;
  graphOverlay?: string;
  axisXline?: string;
  axisYline?: string;
  axisText?: string;
  historyLine?: string;
  borderColor?: string;
  borderSize?: number;
  borderRadius?: number;
  marginRight?: number;
  plotHeight?: number;
  plotWidth?: number;
}

export namespace AlgOptions {

  export function CreateOptions(options?: Options) {
    const config = Object.assign(new Options, options);
    config.axisConfig = Object.assign(new AxisConfig, options?.axisConfig);
    config.menuConfig = Object.assign(new MenuConfig, options?.menuConfig);
    return config;
  }

  export class Options {

    /**
     * Functions
     */
    closeCallback?: Function;

    /**
     * FLAGS
     *
     */
    summary?: boolean;
    showAssessments?: boolean;
    showOnlyHistoric?: boolean;

    /**Axis configuration */
    axisConfig?: AxisConfig = new AxisConfig;

    /** Menu configuration; top right in the graph */
    menuConfig?: MenuConfig = new MenuConfig;

    /**
     * DATES
     */
    dates: moment.Moment[] = [];
    fromDate?: moment.Moment;   // If not set, will be set to .dates.first()
    toDate?: moment.Moment;     // If not set, will be set to .dates.last()
    pointsToShow?: number = AlgConstants.HistoricPoints; // If fromDate and toDate is set, this option is ignored
    /** User cannot zoom in to show less that this amount of points. */
    minPointsToShow?: number = 10;

    /**
     *
     */
    rocEnabled?: boolean;

    /**
     * Note that rocEnabled and rocYYEnabled cannot be true at the same time
     */
    rocYYEnabled?: boolean;
    updateGraph?: any;

    miniGraph?: boolean;
    dontShowHistoricData?: boolean;
    dontShowCIHover?: boolean;
    isPercent?: boolean;
    /**
     * Distance in pixels from top of graph-area to render hover-boxes with values
     * Beware that the hover-box can cover buttons
     */
    hoverMinDistanceFromTop?: string;
    header?: string;
    subheader?: string;
    seasonal?: boolean;
    aggregatedEnabled?: boolean;
    outliersEnabled?: boolean;
    // If inside a modal, do not trigger update on resize
    inModal: boolean = false;
    // Text to show when no data is selected
    noDataText: string = 'No Data Selected';
    // Force all transformations to be ignores, only show original data
    forceOriginalData?: boolean = false;
  }

  export class AxisConfig {
    yAxisLine?: StrokeDefinition = StrokeSetups.AxisLine;
    yAxisPosition?: ALGTypes.YAxisPosition = 'outside';
    xAxisLine?: StrokeDefinition = StrokeSetups.AxisLine;
    xAxisTextColor?: string;
    yAxisTextColor?: string;
    dontShowXAxis?: boolean;
  }

  export class MenuConfig {
    /**
     * BUTTONS
     * Every button in the the top-right menu in the alg should be explicitly enabled here.
     * TODO: Remove/rename/rewrite all "dontShow" to "show", and set to false as default for all.
     */
    showMenu?: boolean = true;
    forceShowButtons?: boolean;
    forceActiveAggregatedBtn?: boolean;
    showFittedDataBtn?: boolean;
    dontShowDownloadBtn?: boolean;
    dontShowPastForecastBtn?: boolean;
    showIndicatorBtn?: boolean = false;   // Toggle visibility for indicator graphs om scenario
    showDecoupleBtn?: boolean;
    showSeasonalAdjBtn?: boolean = false;
    showOutlierAdjBtn?: boolean = false;
    showDatePickerBtn?: boolean = false;
  }
}


