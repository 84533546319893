import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigateToSignup } from '@core/actions/navigation.actions';
import { HttpStatusCodes } from '@core/constants/http-status-codes.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { SecurityDialogService } from '@dialogs/security/security-dialogs.service';
import { Store } from '@ngxs/store';
import * as EmailValidator from 'email-validator';
import { Login } from './login.constants';

export type LoginStep = 'email' | 'password';
const links = require('@root/src/links.json');
@Component({
  selector: 'indicio-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent {

  // Frontend flags
  public username: string;
  public password: string;

  public step: LoginStep = 'email';
  public emailError: boolean = false;
  public showLoader: boolean = false;

  public get fromMacrobond() { return !!this.router.url.match(Login.mbAuthRegex); }

  constructor(
    private store: Store,
    private status: StatusService,
    public env: EnvironmentService,
    public authService: AuthFrontendService,
    private securityDialogs: SecurityDialogService,
    private router: Router
  ) {
    const urlParams = new URLSearchParams(window.location.search);
  }

  public next(evt: KeyboardEvent) {
    if (this.showLoader) { return; }
    if (evt && evt.key !== 'Enter') {
      this.emailError = false;
      return;
    }

    this.emailError = !EmailValidator.validate(this.username);
    if (this.emailError) { return; }

    this.showLoader = true;
    this.authService.startLogin(this.username)
      .then(() => {
        this.step = 'password';
      })
      .catch(err => this.status.setError(err))
      .finally(() => this.showLoader = false);
  }

  public back() {
    this.username = null;
    this.step = 'email';
  }

  public login() {
    if (this.showLoader) { return; }
    this.showLoader = true;
    this.authService.authenticate(this.username, this.password)
      .catch(err => {
        this.step = 'email';
        if (err.status === HttpStatusCodes.UNAUTHORIZED) {
          return this.status.setMessage('Incorrect username and password combination', 'Error');
        }
        this.status.setError(err);
      })
      .finally(() => this.showLoader = false);
  }

  public resetPassword() {
    this.securityDialogs.openForgotPassword({ email: this.username });
  }

  public requestAccess() {
    window.open(links.BetaProgram, '_blank');
  }

  public signup() {
    this.store.dispatch(new NavigateToSignup());
  }
}
