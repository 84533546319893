import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { ScenarioMapper } from '@core/store/scenario/scenario-mapper';
import { ScenarioDTO } from './dtos/scenario.dtos';

@Injectable({
  providedIn: 'root'
})
export class ScenarioBackendService {

  constructor(
    private http: HttpService,
    private mapper: ScenarioMapper
  ) { }

  public getAllScenariosInForecast(forecastVersionId: string) {
    return this.http.get<ScenarioDTO[]>(`forecast-version/${forecastVersionId}/scenarios/`)
      .then(({ body }) => body.map(x => this.mapper.map(x)));
  }

  public getScenario(forecastVersionId: string, scenarioId: string) {
    return this.http.get<ScenarioDTO>(`forecast-version/${forecastVersionId}/scenarios/${scenarioId}`)
      .then(({ body }) => this.mapper.map(body));
  }

  public getAvailableScenarioMultiModels(forecastVersionId: string) {
    return this.http.get<string[]>(`forecast/version/${forecastVersionId}/available-multivariate-models`)
      .then(({ body }) => body);
  }

  public createScenario(scenario: ScenarioDTO) {
    const dto = this.mapper.toCreate(scenario);
    return this.http.post<ScenarioDTO>(`forecast-version/${scenario.ForecastVersionId}/scenarios`, dto)
      .then(({ body }) => body);
  }

  public updateScenario(scenario: ScenarioDTO) {
    const dto = this.mapper.toUpdate(scenario);
    return this.http.put<ScenarioDTO>(`forecast-version/${scenario.ForecastVersionId}/scenarios`, dto)
      .then(({ body }) => body);
  }

  public deleteScenario(scenario: ScenarioDTO) {
    return this.http.delete<null>(`forecast-version/${scenario.ForecastVersionId}/scenarios/${scenario.ScenarioId}`)
      .then(() => scenario.ScenarioId);
  }

  public triggerScenario(scenario: ScenarioDTO) {
    return this.http.post(`forecast-version/${scenario.ForecastVersionId}/scenarios/${scenario.ScenarioId}/trigger`, {})
      .then(() => scenario);
  }
}
