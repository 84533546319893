<indicio-modal-base [child]="this"
                    modalTitle='Data processing information'
                    [loading]=isLoading>

  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="variable.dataprocessingError()">
      <ng-container *ngTemplateOutlet="errorTemplate">
      </ng-container>
    </ng-container>
    <ng-container *ngIf="variable.processed()">
      <ng-container *ngTemplateOutlet="successTemplate">
      </ng-container>
    </ng-container>
    <ng-container *ngIf="variable.initial()">
      <ng-container *ngTemplateOutlet="noResultTemplate">
      </ng-container>
    </ng-container>
  </ng-container>

</indicio-modal-base>

<ng-template #noResultTemplate>
  <div class="row">
    <div class="col-xs-12">
      Data processing result missing<span *ngIf="isPending"
            class="bold">, but is pending</span>
    </div>
    <br>
    <br>
    <div class="col-xs-12">
      Data processing checks for missing values
      and optionally for outliers and seasonal patterns.
      Result for this variable is missing. You can trigger a recalculation below
    </div>
  </div>
  <ng-container *ngTemplateOutlet="btnRowTemplate; context: { fn: recalc.bind(this) }">
  </ng-container>
</ng-template>

<ng-template #successTemplate>
  <div class="row">
    <div class="col-xs-12">
      Data processing successful
    </div>
    <br>
    <br>
    <div class="col-xs-12">
      {{ !variable.IsTrend
        ? 'Data processing checks for missing values and optionally for outliers and seasonal patterns. Below are messages from the calculation'
        : 'This is a trend variable. Data processing for this variable is calculated from the original variable'
      }}
    </div>
  </div>
  <ng-container *ngIf="variable.IsTrend">
    <div class="row extra-margin-top">
      <div class="col-xs-6">
        <button class="btn blue outline"
                (click)="navToVariableInfo();">
          Back
        </button>
      </div>
      <div class="col-xs-6">
        <button class="btn right"
                (click)="openTwin();">
          See original
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!variable.IsTrend">
    <div class='extra-margin-bottom extra-margin-top'>
      <ul>
        <li *ngFor="let entry of tableInfo">
          <div class="row">
            <div class="col-xs-3">{{ entry.colName }}</div>
            <div class="col-xs-1"></div>
            <div class="col-xs-8">{{ entry.colInfo }}</div>
          </div>
        </li>
      </ul>
    </div>
    <ng-container *ngTemplateOutlet="btnRowTemplate; context: { fn: recalc.bind(this) }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #errorTemplate>
  <div class="row">
    <div class="col-xs-12">
      Variable {{ variable.Name }} could not be processed. See errors below. To rerun this step click the button
      below.
    </div>
  </div>
  <div class='extra-margin-bottom extra-margin-top'>
    <ul>
      <li *ngFor="let entry of tableInfo">
        <div class="row">
          <div class="col-xs-3">{{ entry.colName }}</div>
          <div class="col-xs-1"></div>
          <div class="col-xs-8">{{ entry.colInfo }}</div>
        </div>
      </li>
    </ul>
  </div>
  <div class="row">
    <div class="col-xs-12">
      Note: When re-triggering this calculation, any options to search for outliers or seasonal patterns will be
      turned off.
    </div>
  </div>
  <ng-container *ngTemplateOutlet="btnRowTemplate; context: { fn: recalcOnError.bind(this) }">
  </ng-container>
</ng-template>

<ng-template #btnRowTemplate
             let-fn=fn>
  <div class="row extra-margin-top">
    <div class="col-xs-6">
      <button class="btn "
              (click)="navToVariableInfo();">
        Back
      </button>
    </div>
    <div class="col-xs-6">
      <button class="btn right btn-decline important"
              (click)="fn();">
        Recalculate
      </button>
    </div>
  </div>
</ng-template>
