import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { SourceType } from '@modules/lang/language-files/source-types';
import { ModifyAPIKeyModalOpts } from './modify-api-key.options';

export class OpenModifyAPIKeyModal {
  public static readonly type = '[MODAL] Open AddAPIKeyModal login modal';
  data: ModifyAPIKeyModalOpts;
  constructor(
    provider: SourceType,
    helpText: string,
    dataSource: RemoteDataSourceModel,
    confirmFn: Function,
    cancelFn: Function,
    regEx: RegExp = new RegExp('.*'),
    keyTest?: Function
  ) {
    this.data = { provider, helpText, dataSource, confirmFn, cancelFn, regEx, keyTest };
  }
}
