<indicio-modal-base [child]="this"
                    [modalTitle]="isChanged ? 'Edit event group' : 'Import event group'"
                    [loading]=isLoading
                    bodyExtraClass="overflow-auto">

  <div *ngIf="!isLoading">
    <ng-container *ngIf="isEditing">
      <div class="event-settings-container">
        <ng-container *ngTemplateOutlet="baseEventTemplate"></ng-container>
      </div>

      <div class="events-container margin-top">
        <ng-container *ngFor="let subEvent of event.Events;
                              index as i;">
          <ng-container *ngTemplateOutlet="subEventTemplate; context: { subEvent, i }"></ng-container>

        </ng-container>
      </div>

      <ng-container *ngIf="event.Events.length === 0">
        <button class="btn w130"
                type="button"
                (click)="addEvent()">Add event</button>
      </ng-container>
      <ng-container *ngIf="event.Events.length > 0">
        <button class="btn blue outline w130"
                type="button"
                (click)="addEvent()">Add another event</button>
      </ng-container>
    </ng-container>

    <div class="row">
      <div class="col-xs-5">
        <button class="btn outline blue left w130"
                type="button"
                (click)="cancelEdit(); close()">{{ onBack ? 'Back' : isChanged ? 'Cancel' : 'Close' }}</button>
      </div>
      <div class="col-xs-7"
           [title]="disableText || ''">
        <ng-container *ngIf="isChanged; else importButton">
          <button class="btn right w130"
                  type="button"
                  [disabled]="disableNext"
                  (click)="update()">
            <span *ngIf="pending"
                  class="ion-load-c spinner"></span>
            <span *ngIf="!pending">Save</span>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</indicio-modal-base>

<ng-template #titleField
             let-subEvent="subEvent">
  <mat-form-field>
    <input placeholder="Event title"
           matInput
           class="material"
           type="text"
           [(ngModel)]="subEvent.Name"
           name="title"
           required>
  </mat-form-field>
</ng-template>

<ng-template #importButton>
  <button class="btn right green"
          [ngClass]="disableImport ? 'disabled' : ''"
          type="button"
          (click)="import()">
    <span>
      <i *ngIf="pendingImport"
         class="ion-load-c spinner"></i>
      Import</span>
  </button>
</ng-template>

<ng-template #titleField
             let-subEvent="subEvent">
  <mat-form-field>
    <input placeholder="Event title"
           matInput
           data-test-id="event-title-input"
           class="material"
           type="text"
           [(ngModel)]="subEvent.Name"
           name="title"
           required>
  </mat-form-field>
</ng-template>

<ng-template #baseEventTemplate>
  <indicio-foldable-row #row
                        [manualToggle]="true"
                        [open]="event.open">
    <div class="row"
         (click)="event.open = !event.open; closeAllSubEvents()"
         style="width: 100%;"
         foldable-row-title>
      Event group settings
    </div>

    <div class="row">
      <div class="col-sm-6 col-xs-12 relative">
        <mat-form-field>
          <input placeholder="Event title"
                 matInput
                 class="material"
                 type="text"
                 [(ngModel)]="event.Name"
                 name="title"
                 data-test-id="event-title"
                 required>
        </mat-form-field>
      </div>
    </div>
    <div class="row margin-top">
      <div class="col-sm-6 col-xs-12 relative">
        <div class="margin-top">
          <mat-form-field>
            <mat-select [(value)]="event.Location"
                        required
                        data-test-id="location-of-event-dropdown"
                        placeholder="Location of event">
              <mat-option [value]="option.Value"
                          [title]="option.Description"
                          *ngFor="let option of envService.HistoricEventLocations">{{ option.Display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row margin-top">
      <div class="col-sm-6 col-xs-12 relative">
        <div class="margin-top">
          <mat-form-field>
            <mat-select [(value)]="event.Type"
                        required
                        data-test-id="type-of-event-dropdown"
                        placeholder="Type of event">
              <mat-option [value]="option.Value"
                          [title]="option.Description"
                          *ngFor="let option of envService.HistoricEventTypes">{{ option.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </indicio-foldable-row>
</ng-template>


<ng-template #subEventTemplate
             let-subEvent="subEvent"
             let-i="i">
  <indicio-foldable-row #row
                        [open]="subEvent.open"
                        [manualToggle]="true">
    <div class="row"
         (click)="toggleEventDisplay(subEvent)"
         style="width: 100%;"
         foldable-row-title>
      <span *ngIf="subEvent.Name">{{ subEvent.Name }}</span>
      <span *ngIf="!subEvent.Name">Event {{ i + 1 }}</span>
      <span *ngIf="subEvent.Date"
            class="pull-right">{{ subEvent.Date | dateFormat: appearance.DateFormat }}</span>
    </div>

    <div class="row extra-margin-top">
      <div class="col-xs-12">
        <ng-container *ngTemplateOutlet="titleField; context { subEvent: subEvent }">
        </ng-container>
      </div>
    </div>
    <div class="row extra-margin-top">
      <div [ngClass]="subEvent.EffectType === 'level-shift' ? 'col-xs-6' : 'col-xs-12'">
        <indicio-date-picker periodicity="day"
                             [value]="subEvent.Date"
                             (selectDateEvent)="startDateChanged(subEvent, $event);"
                             placeholder="Start date">
        </indicio-date-picker>
      </div>
      <div class="col-xs-6"
           *ngIf="subEvent.EffectType === 'level-shift'">
        <indicio-date-picker periodicity="day"
                             [value]="subEvent.EndDate"
                             [min]="subEvent.Date"
                             (selectDateEvent)="endDateChanged(subEvent, $event);"
                             placeholder="End date (optional)">
        </indicio-date-picker>
      </div>
    </div>
    <div class="row extra-margin-top">
      <div class="col-xs-12 effect-type--title">
        Effect type *
      </div>
      <div class="col-xs-12 relative">
        <div class="row margin-top">
          <div class="col-xs-12">
            <div class="row">
              <div class="col-xs-3 effect-type cursor-pointer"
                   *ngFor="let effect of envService.HistoricEventEffectTypes"
                   [indicioTippy]="effect.Description"
                   [ngClass]="{ 'effect-type--selected' : subEvent.EffectType === effect.Value }"
                   [attr.data-test-id]="'historical-event-image-' + effect.Value"
                   (click)="selectEffectType(subEvent, effect.Value)">
                <img src="/assets/images/event_type_-_{{effect.Value}}.svg"
                     alt=""
                     srcset="">
                <span>{{ effect.Display }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn--text"
            type="button"
            (click)="removeEvent(subEvent)">Remove event</button>
  </indicio-foldable-row>
</ng-template>
