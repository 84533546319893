import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { CloseMainMenuAction, NavigateToForecast } from '@core/actions/navigation.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { ClientSettingsService } from '@core/store/client/client-settings.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { ForecastDialogService } from '@dialogs/forecast/forecast-dialogs.service';
import { ProjectDialogService } from '@dialogs/project/project-dialogs.service';
import { ForecastNavTreeComponent } from '@modules/root/components';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { StorageUtils } from '@shared/utils/storage.utils';
import { Subscription } from 'rxjs';
import { NavigationForecastListViewType, NavigationListViews } from '../../entities/navigation.entities';
import { NavigationActions } from '../../navigation.actions';
import { NavigationComponentService } from '../../navigation.component.service';
import { IForecastTreeNodeBase, IForecastTreeNodeForecast } from './components/nav-tree/entities/forecast-tree.entities';

const PINNED_FORECAST_STATE_KEY = 'pinned-forecasts-open';

@Component({
  selector: 'indicio-forecasts-drawer',
  templateUrl: './forecasts-drawer.component.html',
  styleUrls: ['./forecasts-drawer.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ForecastsDrawerComponent implements OnChanges {

  private sub: Subscription = new Subscription();

  @ViewChild('navTree')
  private navTree: ForecastNavTreeComponent;

  @Input() visible: boolean;
  @Output() closeNavigation = new EventEmitter();

  public navigationListViews = NavigationListViews;
  public activeNavigationListView: NavigationForecastListViewType = 'project-list';
  public forecastTree: IForecastTreeNodeBase[] = [];
  public loadingForecasts = false;
  public selectedForecastId = null;
  public scrollPosition: number;
  public getForecasts$ = this.forecastService.getForecastsInProjectFn$;
  public getForecastById(id: string) { return this.forecastService.forecastById(id); }
  public getProjectById(id: string) { return this.projectService.projectById(id); }
  public get activeCompany() { return this.clientService.activeCompany; }
  public get loadingContent() { return this.loadingForecasts || this.projectService.projectsLoading(this.clientService.activeCompanyId); }
  public get pinnedForecasts(): IForecastTreeNodeForecast[] {
    return <IForecastTreeNodeForecast[]> this.service.pinnedForecastIds
      .map(pinnedId => this.service.forecastTree?.find(forecast => forecast.ObjectId === pinnedId)).filter(x => !!x);
  }
  public isPinnedForecastsOpen = true;

  constructor(
    private store: Store,
    private actions: ActionService,
    private forecastService: ForecastFrontendService,
    private projectService: ProjectFrontendService,
    private service: NavigationComponentService,
    private clientService: ClientFrontendService,
    private clientSettingsService: ClientSettingsService,
    private dialog: DialogService,
    private cd: ChangeDetectorRef,
    private projectDialogs: ProjectDialogService,
    private forecastDialogService: ForecastDialogService,
  ) {
    this.setup();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.visible.currentValue === true) {
      this.loadForecasts();
    }
  }

  // TODO: Check if a full reload really is necessary here, maybe use e.g. HierarchyActions.GetAllSuccess to update only specific content
  public loadForecasts(force = false) {
    if (!force) { this.loadingForecasts = true; }
    this.service.getNavContent(this.clientService.activeCompanyId, this.activeNavigationListView, force)
      .then(content => {
        this.forecastTree = content as IForecastTreeNodeBase[];
        this.loadingForecasts = false;
      });
  }

  private setup() {
    this.activeNavigationListView = this.clientSettingsService.settings.NavigationListView;
    this.sub.add(this.actions.dispatched(CloseMainMenuAction).subscribe(() => this.closeNavigation.emit(true)));
    this.sub.add(this.actions.dispatched(NavigationActions.ForceForecastDrawerReload).subscribe(() => this.loadForecasts(true)));
    this.sub.add(this.actions.dispatched(NavigationActions.SetActiveProject).subscribe(() => this.navTree?.restoreExpandedNodes()));

    if (StorageUtils.check(PINNED_FORECAST_STATE_KEY)) {
      this.isPinnedForecastsOpen = StorageUtils.get(PINNED_FORECAST_STATE_KEY);
    }
  }

  public setNavigationListView(view: NavigationForecastListViewType) {
    const force = this.activeNavigationListView === 'hierarchy-list' && view !== 'hierarchy-list';
    this.activeNavigationListView = view;
    this.clientSettingsService.settings.NavigationListView = view;
    this.clientSettingsService.updateSettings();
    this.loadForecasts(force);
  }

  public newActiveNode(node: IForecastTreeNodeBase) {
    if (node.Type !== 'forecast') { return; }
    this.selectedForecastId = node.ObjectId;
    this.store.dispatch(new NavigateToForecast(node.ObjectId));
    this.closeNavigation.emit(true);
  }

  public unpinAllForecasts($event: PointerEvent) {
    $event.stopPropagation();
    this.service.unpinAllForecasts();
    setTimeout(() => { this.navTree.cd.detectChanges(); }, 0);
  }

  public togglePinnedForecastOpen() {
    this.isPinnedForecastsOpen = !this.isPinnedForecastsOpen;
    setTimeout(() => { this.navTree.cd.detectChanges(); }, 0);
    StorageUtils.set(PINNED_FORECAST_STATE_KEY, this.isPinnedForecastsOpen);
  }

  public createProject() {
    this.projectDialogs.openCreate({});
  }

  public createForecast(project?: ProjectModel) {
    this.forecastDialogService.openCreate({
      ProjectId: project?.ProjectId
    });
  }

  public scroll($event) {
    this.scrollPosition = $event.target.scrollTop;
  }

  public createNewHF() {
    this.dialog.openCreateOrUpdateHierarchyDialog({});
  }
}
