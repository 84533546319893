import { PlotValue } from '@core/entities/dtos/plot-value-dto';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import * as JSZip from 'jszip';

export namespace PlotUtils {

  export function UnzipPlotImage(img: ResultFileDTO, displayName: string): Promise<ResultFileDTO> {
    const z = new JSZip();
    return z.loadAsync(img.Base64String, { base64: true })
      .then((res: any) => {
        return res.files[img.Name + '.svg'].async('base64')
          .then(f => {
            img.Base64String = f;
            img.MimeType = 'image/svg+xml';
            img.displayName = displayName;
            return img;
          });
      });
  }

  /**
   * Get the plot data, stripped or including the historic part of the time series
   * @param data
   * @param includeHistory
   */
  export function getPlotData(data: PlotValue[], includeHistory: boolean = true) {
    const result: PlotValue[] = [];
    if (includeHistory) {
      data.forEach(x => result.push(x));
    } else {
      const filtered = data.filter(x => x.IF);
      filtered.forEach(x => result.push(x));
    }
    return result;
  }
}
