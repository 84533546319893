import { NewsPostModel } from '@core/store/news/news-post.model';
import { NewsCommentModel } from '@core/store/news/news-comment.model';

export class GetNewsPostsSuccessAction {
  public static readonly type = '[NEWS] Get news posts';
  constructor(
    public newsPosts: NewsPostModel[]
  ) { }
}

export class GetNewsPostSuccessAction {
  public static readonly type = '[NEWS] Get news post';
  constructor(
    public newsPost: NewsPostModel
  ) { }
}

export class RemoveNewsPostSuccessAction {
  public static readonly type = '[NEWS] Remove news post';
  constructor(
    public newsPost: NewsPostModel
  ) { }
}

export class AddNewsCommentSuccessAction {
  public static readonly type = '[NEWS] Add news comment';
  constructor(
    public comment: NewsCommentModel
  ) { }
}

export class RemoveNewsCommentSuccessAction {
  public static readonly type = '[NEWS] Remove news comment';
  constructor(
    public comment: NewsCommentModel
  ) { }
}

export class AddNewsCommentReplySuccessAction {
  public static readonly type = '[NEWS] Add news comment reply';
  constructor(
    public comment: NewsCommentModel,
    public reply: NewsCommentModel
  ) { }
}

export class RemoveNewsCommentReplySuccessAction {
  public static readonly type = '[NEWS] Remove news comment reply';
  constructor(
    public comment: NewsCommentModel,
    public reply: NewsCommentModel
  ) { }
}

export class EmptyNewsStoreSuccessAction {
  public static readonly type = '[NEWS] Remove all news';
  constructor() { }
}
