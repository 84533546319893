import { Def } from '@core/decorators/def';

export class CreateProfileDTO {
  @Def() Code: string;
  @Def() Username: string;
  @Def() Password: string;
  @Def() FirstName: string;
  @Def() LastName: string;
  @Def() CountryCode = '';
}
