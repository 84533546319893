import { StringDatePlotValueDTO, StringDateValueDTO } from '@core/entities/dtos/plot-value-dto';

export type MacrobondExportType = 'statmodel' | 'summary' | 'scenario';

export class MacrobondExportBase {
  RemoteVariableId: string;
  BasePrimName: string;
  PrimName: string = null;
  IHCategory: string;
  Description: string;
  Prefix: string;
}

export class MacrobondExportSeasonalDTO extends MacrobondExportBase {
  ForecastVariableId: string;

  // What data should be exported?
  IncludeAdjusted: boolean;
  IncludeIrregular: boolean;
  IncludeTrend: boolean;
  IncludeOriginal: boolean;
  IncludeComponent: boolean;
  IncludeComponentC1: boolean;
  IncludeComponentC2: boolean;
  IncludeComponentC3: boolean;
}

export class MacrobondExportDTO extends MacrobondExportBase {
  ForecastId: string;
  Frequency: string;
  IncludeCi: boolean = true;
  VersionNumber: number;
  HistoricValues: StringDateValueDTO[];
  Models: MacrobondExportModelDTO[];
}

export class MacrobondExportModelDTO {
  ForecastedValues: StringDatePlotValueDTO[];
  IHInfo: string;
  Type: MacrobondExportType;
  Name: string;
  Identifier: string;
}
