<indicio-modal-base [child]="this"
                    modalTitle="Import currency data"
                    [loading]="isLoading"
                    modalExtraClass="small"
                    extraClasses="small">

  <div class="row extra-margin-top bold">
    <div class="col-xs-6">From currency</div>
    <div class="col-xs-6">To currency</div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <indicio-searchable-dropdown *ngIf="!!currencies && currencies.length > 0"
                                   [required]="true"
                                   [values]="currencies"
                                   optionDisplay="display"
                                   [initializeWithFirstValue]="false"
                                   (valueChangeEvent)="generateName($event, 'from')">
      </indicio-searchable-dropdown>

    </div>
    <div class="col-xs-6">
      <indicio-searchable-dropdown *ngIf="!!currencies && currencies.length > 0"
                                   [required]="true"
                                   [values]="currencies"
                                   optionDisplay="display"
                                   [initializeWithFirstValue]="false"
                                   (valueChangeEvent)="generateName($event, 'to')">
      </indicio-searchable-dropdown>
    </div>
  </div>

  <form ngNativeValidate
        (submit)="importData()"
        *ngIf="from && to">
    <div class="row">
      <div class="col-xs-12 bold extra-margin-top">Variable settings</div>
    </div>
    <div class="row">
      <div class="col-xs-12 input-label">
        <mat-form-field>
          <input [(ngModel)]="variable.Name"
                 type="text"
                 class="material"
                 required
                 [ngModelOptions]="{standalone: true}"
                 placeholder="Variable name"
                 matInput>
          <mat-hint *ngIf="nameConflict">
            <span class="error-text">A source variable with that name already exists.</span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 input-label">
        <mat-form-field>
          <mat-select [(value)]="variable.ValueType"
                      required
                      placeholder="Select value type">
            <mat-option [value]="option"
                        *ngFor="let option of valueTypes">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 input-label">
        <mat-form-field>
          <mat-select [(value)]="periodicity"
                      required
                      placeholder="Select value type">
            <mat-option [value]="option.value"
                        *ngFor="let option of periodicities">{{ option.display }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-6">
        <button (click)="back()"
                type="button"
                class="btn outline blue left">Back</button>
      </div>
      <div class="col-xs-6">
        <button type="submit"
                class="btn pull-right"
                [ngClass]="{ disabled: !from || !to || nameConflict }">
          <i class="ion-load-c spinner"
             *ngIf="pending"></i>
          <span *ngIf="!pending">Import</span>
        </button>
      </div>
    </div>
  </form>

  <div class="row"
       *ngIf="!from || !to">
    <div class="col-xs-12">
      <button (click)="back()"
              type="button"
              class="btn outline blue left">Back</button>
    </div>
  </div>
</indicio-modal-base>
