import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { CreateMultiFVarModalOpts } from './create-forecast-variable-multi-modal.options';

export class OpenCreateForecastVariableMultiModal {
  public static readonly type = '[MODAL] Open create forecast variable multi';
  data: CreateMultiFVarModalOpts;
  constructor(
    forecastVersion: ForecastVersionModel,
    variableIds: string[],
    onClose?: Function,
    versionIds?: string[],
    errors?: any
  ) { this.data = { forecastVersion, variableIds, onClose, versionIds, errors }; }
}
