import { DisplayValue } from '../types/display-value';

export type StatModelName = UnivariateModelNameType | MultivariateModelNameType;

export enum UnivariateModelName {
  arima = 'arima',
  ets = 'ets',
  theta = 'theta',
  stl = 'stl',
  tbats = 'tbats',
  naive = 'naive',
  neural = 'neural',
  prophet = 'prophet'
}

export type UnivariateModelNameType =
  'arima' |
  'ets' |
  'theta' |
  'stl' |
  'tbats' |
  'naive' |
  'neural' |
  'prophet';

export type MultivariateModelNameType =
  'var' |
  'vecm' |
  'varma' |
  'ardl' |
  'ssbvar' |
  'mbvar' |
  'tvpbvarsv' |
  'msvar' |
  'varlasso' |
  'lassobasic' |
  'lassobasicen' |
  'lassolag' |
  'lassosparselag' |
  'lassoefx' |
  'lassohvarc' |
  'lassosparseoo' |
  'lassoownother' |
  'lassohvaroo' |
  'lassohvarelem' |
  'lassotapered' |
  'scad' |
  'mcp' |
  'ann' |
  'gru' |
  'lstm' |
  'vecmlasso' |
  'bgr' |
  'mfvar' |
  'midas' |
  'umidas' |
  'sglmidas' |
  'lmidas';

export enum TransformName {
  org_norm = '_org_norm',
  org_diff_norm = '_org_diff_norm',
  org_log_norm = '_org_log_norm'
}

export type ModelTransformationType = 'org_norm' | 'diff_norm' | 'log_norm' | 'org_std' | 'diff_std' | 'log_std';
export type ModelPropertiesType = 'full' | 'train' | 'rw';

export const UnivariateDisplayNames = ['Arima', 'ETS', 'STL', 'Tbats', 'Theta', 'Neural'];
export const NaiveModelName = ['naive_org_std', 'naive_org_norm'];
export const UnivariateOrgNames = ['arima_org_norm', 'ets_org_norm', 'stl_org_norm', 'tbats_org_norm', 'theta_org_norm', 'neural_org_norm'];
export const UnivariateDiffNames = ['arima_org_diff_norm', 'ets_org_diff_norm', 'stl_org_diff_norm', 'tbats_org_diff_norm', 'theta_org_diff_norm', 'neural_org_diff_norm'];
export const UnivariateLogNames = ['arima_org_log_norm', 'ets_org_log_norm', 'stl_org_log_norm', 'tbats_org_log_norm', 'theta_org_log_norm', 'neural_org_log_norm'];

export class StatModelType extends DisplayValue<string> { }
export const StatModelTypes = [
  /* Naive first! */
  new StatModelType('naive', 'Naive', 'A simple model using the last value as a prediction for the future.', 'Last value used as prediction'),
  /* Univariate */
  new StatModelType('arima', 'ARIMA', 'An autoregressive integrated moving average (ARIMA) model is a generalization of an autoregressive moving average (ARMA) model.', 'Autoregressive integrated moving average'),
  new StatModelType('ets', 'ETS', 'Exponential smoothing is a rule of thumb technique for smoothing time series data using the exponential window function.', 'Exponential smoothing'),
  new StatModelType('theta', 'Theta', 'The Theta model is a simple method for forecasting that involves fitting two theta-lines, forecasting the lines using simple exponential smoothing, and then combining the forecasts from the two lines to produce the final forecast.', 'Decomposition with modified the local curvature'),
  new StatModelType('stl', 'STL', 'STL is a versatile and robust method for decomposing time series. STL is an acronym for “Seasonal and Trend decomposition using Loess”, while Loess is a method for estimating nonlinear relationships.', 'Seasonal and Trend decomposition using Loess'),
  new StatModelType('tbats', 'TBATS', 'TBATS is a time series model that is useful for handling data with multiple seasonal patterns. TBATS is an acronym for key features of the model: T: Trigonometric seasonality B: Box-Cox transformation A: ARIMA errors T: Trend S: Seasonal components', 'Decomposition of multiple seasonalities'),
  new StatModelType('prophet', 'Prophet', 'Prophet is a procedure for forecasting time series data based on an additive model where non-linear trends are fit with yearly, weekly, and daily seasonality, plus holiday effects. It works best with time series that have strong seasonal effects and several seasons of historical data. Prophet is robust to missing data and shifts in the trend, and typically handles outliers well.', 'Prophet model developed by Facebook’s Core Data Science team'),
  new StatModelType('neural', 'Neural', 'An artificial neural network (ANN) is a model which is based on a collection of connected units or nodes called artificial neurons, which loosely model the neurons in a biological brain.', 'Neural network with 1 hidden layer'),
  /* Multivariate */
  new StatModelType('var', 'VAR', 'Vector Auto Regression is a model that captures the linear relations among multiple time series. VAR models generalize the univariate autoregressive model (AR model) by allowing for multiple variables. All variables in a VAR enter the model in the same way: each variable has an equation explaining its evolution based on its own lagged values, the lagged values of the other model variables, and an error term. The calculations find the best common lag length for all variables in all equations (vectors).', 'Vector autoregression'),
  new StatModelType('vecm', 'VECM', "Vector Error Correction Models is useful for data sets with long-run relationships (also called cointegration). VECMs are useful for estimating both short-term and long-term effects of one time series on another. The term error-correction relates to the fact that last-period's deviation from a long-run equilibrium, the error, influences its short-run dynamics. These models estimate, besides the long run relationships between variables, also directly the speed at which a dependent variable returns to equilibrium after a change in other variables.", 'Vector error correction'),
  new StatModelType('varma', 'VARMA', 'In the statistical analysis of time series, Auto-Regressive–Moving-Average (ARMA) models provide a description of the relationships between the variables in terms of the two factors: autoregression (AR) and moving average (MA). The AR part involves regressing the variable on its own lagged (i.e. past) values. The MA part involves modelling the error term as a linear combination of error terms occurring contemporaneously and at various times in the past. VARMA is the VAR (multivariate) version of the ARMA model.', 'Vector autoregressive moving average'),
  new StatModelType('ardl', 'ARDL', "Auto Regressive Distributed Lag was the standard model before the VAR model was invented. Compared to the VAR, it's a less complex model, where the variables are not seen as interrelated. The main variable that are forecasted depends on the indicators, but the indicators do not depend on other indicators or the main variable. ", 'Autoregressive distributed lag'),
  new StatModelType('ssbvar', 'Steady-State BVAR', "Bayesian statistics, in contrast to classical statistics, use probability more widely to model uncertainty. A Bayesian model use informative priors, i.e. a probability distribution that express one's beliefs of a certain variable, to create a benchmark model and then shrink the parameter uncertainty to get more accurate forecasts. In macroeconomics the steady state model has been honoured for the ability of incorporate economic theory by the prior. An example of a prior is inflation that, in the long-term, should centre around 2%, which can be incorporated in the model by the prior. A criticism of the Bayesian model is the self-confirming effect you may get when setting a too tight prior, confirming your own belief rather than building an accurate model.", 'Bayesian vector autoregression with steady-state prior'),
  new StatModelType('mbvar', 'Minnesota BVAR', "Bayesian statistics, in contrast to classical statistics, use probability more widely to model uncertainty. A Bayesian model use informative priors, i.e. a probability distribution that express one's beliefs of a certain variable, to create a benchmark model and then shrink the parameter uncertainty to get more accurate forecasts. In macroeconomics the steady state model has been honoured for the ability of incorporate economic theory by the prior. An example of a prior is inflation that, in the long-term, should centre around 2%, which can be incorporated in the model by the prior. A criticism of the Bayesian model is the self-confirming effect you may get when setting a too tight prior, confirming your own belief rather than building an accurate model.", 'Bayesian vector autoregression with Minnesota/Litterman prior'),
  new StatModelType('tvpbvarsv', 'TVP BVAR with SV', "Bayesian statistics, in contrast to classical statistics, use probability more widely to model uncertainty. A Bayesian model use informative priors, i.e. a probability distribution that express one's beliefs of a certain variable, to create a benchmark model and then shrink the parameter uncertainty to get more accurate forecasts. In macroeconomics the steady state model has been honoured for the ability of incorporate economic theory by the prior. An example of a prior is inflation that, in the long-term, should centre around 2%, which can be incorporated in the model by the prior. A criticism of the Bayesian model is the self-confirming effect you may get when setting a too tight prior, confirming your own belief rather than building an accurate model.", 'Time-Varying Bayesian VAR with Stochastic Volatility'),
  new StatModelType('msvar', 'Markov Switching VAR', 'A Markov-switching vector autoregression is a VAR model which identifies multiple regimes within the data and switches between different VAR models per regime. The different regime models are considered more or less probable at each point in time.', 'Markov switching vector autoregression'),
  new StatModelType('varlasso', 'VAR Lasso', 'Lasso penalized vector autoregression', 'Lasso penalized vector autoregression'),
  new StatModelType('lassobasic', 'VARX Lasso', 'Lasso, Least Absolute Shrinkage and Selection Operator is the most successful application of AI within econometrics. Lasso was introduced in order to improve the prediction accuracy and interpretability of regression models by altering the model fitting process to select only a subset of the provided independent variables for use in the final model rather than using all of them. Lasso forces certain coefficients to be set to zero, effectively choosing a simpler model that does not include those coefficients.', 'Lasso penalized vector autoregression'),
  new StatModelType('lassobasicen', 'VAR Elastic Net', 'The elastic net is a regression method that linearly combines the lasso and ridge (see below) methods. Basically, the elastic net method finds the ridge regression coefficients, and then does a lasso type shrinkage of the coefficients.', 'Elastic net penalized vector autoregression'),
  new StatModelType('lassolag', 'VARX Lag Group Lasso', 'Groups the series based on the lags of the explanatory variables. The model selects the variables and their lags based on lag grouping, meaning that the 1st lags, 2nd lags etc. of all variables are put into groups. If not contributing, entire groups will then be penalized.', 'Group lag penalized vector autoregression'),
  new StatModelType('lassosparselag', 'VARX Sparse Lag Group Lasso', 'Sparse lag group penalized vector autoregression', 'Sparse lag group penalized vector autoregression'),
  new StatModelType('lassoefx', 'VARX Endogenous-First', 'VARX Endegenous-First utilizes a penalty to prioritize endogenous series. At a given lag, an exogenous series can enter the model only if their endogenous counterpart is nonzero.', 'Endogenous-First penalized vector autoregression'),
  new StatModelType('lassohvarc', 'HVAR Componentwise Lasso', 'In Componentwise models all variables have the same maximum lag.', 'Componentwise penalized hierarchical vector autoregression'),
  new StatModelType('lassosparseoo', 'VARX Own/Other Sparse Group Penalty', 'Sparse refers to not penalizing a whole group. In certain scenarios, a group penalty can be too restrictive. On the other hand, having many groups will substantially increase computation time and generally does not improve forecasting performance.', 'Own/Other sparse group penalized vector autoregression'),
  new StatModelType('lassoownother', 'VARX Own/Other Group Penalty', "In this model the grouping distinguishes between a series' own lags and those of other series. This structure is similar to Componentwise (see below) but prioritizes “own” lags over “other” lags for a specific lag. This is based on the hypothesis that own lags are more informative than other lags.", 'Own/Other group penalized vector autoregression'),
  new StatModelType('lassohvaroo', 'HVAR Own/Other Lasso', 'Imposes an additional layer of hierarchy: prioritizing “own” lags over “other” lags in the HVAR framework.', 'Own/Other penalized hierarchical vector autoregression'),
  new StatModelType('lassohvarelem', 'HVAR Elementwise Lasso', 'The most general structure, in each marginal model, each series may have its own maximum lag.', 'Elementwise penalized hierarchical vector autoregression'),
  new StatModelType('lassotapered', 'VAR Weighted Lag Lasso', 'Consists of a Lasso penalty that increases geometrically with lag. This means that shorter lags are prioritized in these models, compared to the set up in other VAR models.', 'Weighted lag penalized vector autoregression'),
  new StatModelType('scad', 'VAR Smoothly Clipped Absolute Deviation', 'In contrast to lasso Smoothly Clipped Absolute Deviation (SCAD) shrinks smaller parameters more slowly than large ones, producing less biased estimates.', 'Smoothly Clipped Absolute Deviation vector autoregression'),
  new StatModelType('mcp', 'VAR Minimax Concave Penalty', 'In contrast to lasso Minimax Concave Penalty (MCP) shrinks smaller parameters more slowly than large ones, producing less biased estimates.', 'Minimax Concave Penalty vector autoregression'),
  new StatModelType('ann', 'ANN', 'The artificial neural network (ANN) is a model inspired by biological neural networks such as the human brain. The model is an example of a more sparse machine learning model compared to LSTM and GRU. This lessens the risk of overfitting while still offering more flexibility than a linear model. ANN is trained on data using variants of gradient descent, such as AdaGrad and ADAM.', 'Multi Layer Artificial Neural Network'),
  new StatModelType('gru', 'GRU', 'The gated recurrent unit (GRU) model is a type of recurrent neural network. As such it is well suited for sequential data such as time series. A main strength is the high flexibility compared to linear models, a GRU model can identify non-linear patterns in data, allowing it to more accurately describe it. It is similar to LSTM but has fewer parameters, which lessens the risk of overfitting on smaller sets of data. GRU is trained on data using variants of gradient descent, such as AdaGrad and ADAM.', 'Gated Recurrent Unit Neural Network'),
  new StatModelType('lstm', 'LSTM', 'The long short-term memory (LSTM) model is an artificial recurrent neural network. It is especially suited for processing sequences of data, owing to its feedback connections. LSTM models are used for many different tasks such as speech and video analysis, as well as time series analysis. One of the main strengths of an LSTM model is its flexibility, it can identify complex structures in data thanks to its non-linear activation functions and heavy parametrisation. LSTM is trained on data using variants of gradient descent, such as AdaGrad and ADAM.', 'Long Short-Term Memory Recurrent Neural Network'),
  new StatModelType('vecmlasso', 'VECM Lasso', "Vector Error Correction Models is useful for data sets with long-run relationships (also called cointegration). VECMs are useful for estimating both short-term and long-term effects of one time series on another. The term error-correction relates to the fact that last-period's deviation from a long-run equilibrium, the error, influences its short-run dynamics. These models estimate, besides the long run relationships between variables, also directly the speed at which a dependent variable returns to equilibrium after a change in other variables. This version is combined with Lasso, Least Absolute Shrinkage and Selection Operator which forces certain coefficients to be set to zero, effectively choosing a simpler model that does not include those coefficients.", 'Lasso penalized vector error correction'),
  new StatModelType('bgr', 'VAR Bayesian Ridge Regression', 'This is a way of using Bayesian models in a VAR framework. Prior to Lasso, the most widely used method for choosing which variables to include was stepwise selection. At that time, ridge regression was the most popular alternative technique used for improving prediction accuracy. Ridge regression improves prediction error by shrinking large regression coefficients in order to reduce overfitting, but it does not perform variable selection and therefore does not help to make the model more interpretable.', 'Bayesian ridge regression penalized vector autoregression'),
  new StatModelType('mfvar', 'Mixed Frequency VAR', 'TBD.', 'Mixed frequency vector autoregression'),
  new StatModelType('midas', 'MIDAS', 'Mixed Data Sampling (MIDAS) models use high frequency indicators to predict a low frequency variable. By fitting a lag distribution function the number of parameters is kept low, reducing the risk of over-fitting.', 'Mixed-data sampling'),
  new StatModelType('umidas', 'Unrestricted MIDAS', 'Unrestricted Mixed Data Sampling (MIDAS) models use high frequency indicators to predict a low frequency variable.', 'Unrestricted Mixed Data Sampling'),
  new StatModelType('sglmidas', 'MIDAS Sparse Group Penalty', 'Mixed Data Sampling (MIDAS) models use high frequency indicators to predict a low frequency variable. By applying a sparse group penalty function the parameters are shrunk towards zero, reducing the risk of over-fitting.', 'Mixed data sampling sparse group lasso penalty'),
  new StatModelType('lmidas', 'MIDAS Lasso', 'Mixed Data Sampling (MIDAS) models use high frequency indicators to predict a low frequency variable. By applying a lasso penalty function the parameters are shrunk towards zero, reducing the risk of over-fitting.', 'Mixed data sampling lasso penalty')
];

export const StatModelSimpleTransforms = [
  new DisplayValue<ModelTransformationType>('org_norm', 'Normalized', 'Each variable is adjusted to have minimum 0 and maximum 1.'),
  new DisplayValue<ModelTransformationType>('log_norm', 'Logarithmic', 'Each variable is log transformed if it only contains positive values.'),
  new DisplayValue<ModelTransformationType>('diff_norm', 'Differenced', 'Each variable is replaced with its first difference if it is not stationary.'),
  new DisplayValue<ModelTransformationType>('org_std', 'Standardized', 'Each variable is adjusted to have zero mean and unit variance.'),
  new DisplayValue<ModelTransformationType>('log_std', 'Logarithmic', 'Each variable is log transformed if it only contains positive values.'),
  new DisplayValue<ModelTransformationType>('diff_std', 'Differenced', 'Each variable is replaced with its first difference if it is not stationary.'),
];

export const StatModelTransforms = [
  new DisplayValue('org_norm', 'normalized', 'org'),
  new DisplayValue('org_log_norm', 'logarithmic transformed and normalized', 'log'),
  new DisplayValue('org_diff_norm', 'differentiated and normalized', 'diff'),
  new DisplayValue('org', 'no transformation', 'org'),
  new DisplayValue('org_std', 'standardised', 'org'),
  new DisplayValue('org_log_std', 'logarithmic transformed and standardised', 'log'),
  new DisplayValue('org_diff_std', 'differentiated and standardised', 'diff')
];

export const StatModelPropertyTypes = [
  new DisplayValue<ModelPropertiesType>('full', 'Full', 'Model built on all available data'),
  new DisplayValue<ModelPropertiesType>('train', 'Train', 'Model built on the test set'),
  new DisplayValue<ModelPropertiesType>('rw', 'Expanding window', 'Models built on incremental pieces of data')
]

