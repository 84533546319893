<indicio-modal-base [child]="this"
                    modalTitle="Import variable from acea"
                    [loading]="loading"
                    modalExtraClass="small"
                    extraClasses="small">

  <ng-container *ngIf="!loading">
    <h2>{{ series.Metadata["selectedSubCategoryTitle"] }}</h2>

    <div class="margin-top selectable-text"
         style="max-height: 200px; overflow-y: auto; overflow-x: hidden;"
         [innerHTML]="
         series.Tippy
         | safeHtml"></div>

    <div class="row extra-margin-top">

    </div>

    <div class="row extra-margin-top"
         *ngIf="series.Type == 'passengercar' && series.Code == 'manufacturer'">
      <div class="col-xs-3">Company:</div>
      <div class="col-xs-9">
        {{ series.title }}
      </div>
    </div>

    <div class="row"
         *ngIf="series.Code != 'manufacturer'">
      <div class="col-xs-3">Country:</div>
      <div class="col-xs-9">
        {{ series.title }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">Range:</div>
      <div class="col-xs-9">
        {{ series.FirstDate | dateFormat }} - {{ series.LastDate | dateFormat }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">Periodicity:</div>
      <div class="col-xs-9">
        {{ envService.getPeriodicity(series.Periodicity).Display }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">Type:</div>
      <div class="col-xs-9">
        {{ type }}
      </div>
    </div>


    <form (ngSubmit)="addVariable(series)"
          ngNativeValidate>
      <div class="row extra-margin-top">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-label>Choose a name</mat-label>
            <input matInput
                   class="material"
                   type="text"
                   [(ngModel)]="remoteDataModel.Name"
                   [ngModelOptions]="{standalone: true}"
                   required>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="btn-wrapper extra-margin-top">
          <div class="col-xs-6">
            <button class="btn outline blue left"
                    type="button"
                    (click)="close()">Back</button>
          </div>
          <div class="col-xs-6">
            <button class="btn pull-right"
                    data-test-id="import-acea">
              <i class="ion-load-c spinner"
                 *ngIf="pending"></i>
              <span *ngIf="!pending">Import</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</indicio-modal-base>
