<h1 mat-dialog-title>{{ data.Title }}</h1>
<div mat-dialog-content>
  <div style="margin: 0 0 10px 0; color: red"
       *ngIf="(data.ExtraWarning && !data.WarningCheck) || (data.WarningCheck && warningGroups.length && data.ExtraWarning)">
    {{ data.ExtraWarning }}
  </div>

  <div class="margin-top"
       *ngIf="data.WarningCheck">
    <ng-container *ngIf="warningGroups.length && !loading">
      <ng-container *ngFor="let group of warningGroups">
        <indicio-foldable-row [title]="group.Title"
                              [open]="true">
          <ng-container [ngSwitch]="group.Type">
            <ng-container *ngSwitchCase="0">
              <ng-container *ngTemplateOutlet="forecastResultWarningTemplate; context: { group: group }">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="1">
              <ng-container *ngTemplateOutlet="forecastInReportWarningTemplate; context: { group: group }">
              </ng-container>
            </ng-container>
          </ng-container>
        </indicio-foldable-row>
      </ng-container>
    </ng-container>
    <div *ngIf="loading">
      <i class="ion-load-c spinner"></i> Getting warnings...
    </div>
  </div>

  <div [class]="{ 'extra-margin-top' : !!data.WarningCheck && warningGroups.length }"
       [innerHtml]="data.Message"></div>
</div>

<div mat-dialog-actions
     [style]=" { 'justify-content' : !data.HideCancel ? 'space-between' : 'flex-end' }">
  <button mat-button
          *ngIf="!data.HideCancel"
          (click)="onNoClick()">
    {{ data.CancelText}}
  </button>
  <ng-container *ngIf="!data.ConfirmChoices">
    <button [color]="data.Style"
            mat-flat-button
            data-test-id="confirm-dialog-btn"
            (click)="onConfirmClick()">
      {{ data.ConfirmText }}
    </button>
  </ng-container>
  <ng-container *ngIf="data.ConfirmChoices">
    <ng-container *ngFor="let val of data.ConfirmChoices">
      <button [color]="data.Style"
              mat-flat-button
              (click)="onConfirmClick(val.Key ? val.Key : val.Name)">
        {{ val.Name }}
      </button>
    </ng-container>
  </ng-container>
</div>

<ng-template #forecastResultWarningTemplate
             let-group="group">
  <div class="data-table"
       style="width: 453px">
    <div class="data-table__header">
      <div class="column header left-align"
           style="width: 151px;">Variable</div>
      <div class="column header left-align"
           style="width: 151px;">Project</div>
      <div class="column header left-align"
           style="width: 151px;">Forecast</div>
    </div>

    <div class="data-table__body big"
         style="max-height: 200px;">
      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngFor="let warning of group.Warnings;">
        <ng-container *ngIf="warning.Content; let content">
          <div class="column crop-string left-align"
               style="width: 151px;">{{ content.FVarName }}</div>
          <div class="column crop-string left-align"
               style="width: 151px;">{{ content.ProjectName }}</div>
          <div class="column crop-string left-align"
               style="width: 151px; display: flex; align-content: center;">
            <div class="crop-string"
                 style="width: 123px;"
                 [indicioTippy]="content.ForecastName">{{ content.ForecastName }}</div>
            <div style="width: 28px; display: flex; justify-content: end;">
              <a [href]="'/forecast/'+ content.ForecastId"
                 indicioTippy="Navigate to forecast"
                 target="_blank">
                <i class="ion-android-exit pointer"
                   style="font-size: 15px; margin-left: 5px;"></i>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #forecastInReportWarningTemplate
             let-group="group">
  <div class="data-table"
       style="width: 453px">
    <div class="data-table__header">
      <div class="column header left-align"
           style="width: 151px;">Report</div>
      <div class="column header left-align"
           style="width: 151px;">Project</div>
      <div class="column header left-align"
           style="width: 151px;">Forecast</div>
    </div>

    <div class="data-table__body big"
         style="max-height: 200px;">
      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngFor="let warning of group.Warnings">
        <ng-container *ngIf="warning.Content; let content">
          <div class="column crop-string left-align"
               style="width: 151px;  display: flex; align-content: center;">
            <div class="crop-string"
                 style="width: 123px;"
                 [indicioTippy]="content.ReportName">{{ content.ReportName }}</div>
            <div style="width: 28px; display: flex; justify-content: end;">
              <a [href]="'/reports/'+ content.ReportId"
                 indicioTippy="Navigate to report"
                 target="_blank">
                <i class="ion-android-exit pointer"
                   style="font-size: 15px; margin-left: 5px;"></i>
              </a>
            </div>
          </div>
          <div class="column crop-string left-align"
               style="width: 151px;">{{ content.ProjectName }}</div>
          <div class="column crop-string left-align"
               style="width: 151px; display: flex; align-content: center;">
            <div class="crop-string"
                 style="width: 123px;"
                 [indicioTippy]="content.ForecastName">{{ content.ForecastName }}</div>
            <div style="width: 28px; display: flex; justify-content: end;">
              <a [href]="'/forecast/'+ content.ForecastId"
                 indicioTippy="Navigate to forecast"
                 target="_blank">
                <i class="ion-android-exit pointer"
                   style="font-size: 15px; margin-left: 5px;"></i>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
