import { Component, ViewEncapsulation } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePickerBaseComponent, StartViewType } from '../base/date-picker-base.component';

export const QUART_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy-MM',
  },
  display: {
    dateInput: 'yyyy-MM',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  },
};

@Component({
  selector: 'indicio-date-picker-quarter',
  templateUrl: '../base/date-picker-base.component.html',
  styleUrls: ['../date-picker.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: QUART_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
})
export class DatePickerQuarterComponent extends DatePickerBaseComponent {
  public panelClass: string = 'date-picker-base quarter-date-picker';
  public startView: StartViewType = 'multi-year';
  public monthSelected: Function = this.closeDatePicker;
}
