import { Injectable } from '@angular/core';

import { StorageUtils } from '@shared/utils/storage.utils';
import { WikiConstants } from './wiki.constants';
import { CreateWikiPageDTO, WikiAssetDTO, WikiPageAdminMetaDTO, WikiPageDTO } from './wiki.entities';

@Injectable({
  providedIn: 'root'
})
export class WikiMapper {

  constructor() { }

  public mapPage(dto: WikiPageDTO) {
    dto = Object.assign(new WikiPageDTO, dto);
    if (dto.assetsInContent) { dto.Content = this.insertLoadersToContent(dto.Content); }
    if (!!!dto.ParentSlug) { dto.ParentSlug = null; }
    dto.Parents = dto.Parents.reverse();
    return dto;
  }

  public mapCachedPage(dto: CreateWikiPageDTO) {
    dto = Object.assign(new CreateWikiPageDTO, dto);
    if (dto.Content.match(WikiConstants.AssetRegexp)) { dto.Content = this.insertLoadersToContent(dto.Content); }
    return dto;
  }

  public mapPageMeta(dto: WikiPageAdminMetaDTO) {
    dto = Object.assign(new WikiPageAdminMetaDTO, dto);
    if (StorageUtils.check(`${WikiConstants.STORAGE_KEY}.${dto.Slug}`)) { dto.cachedPageExists = true; }
    if (!!!dto.ParentSlug) { dto.ParentSlug = null; }
    return dto;
  }

  public mapAsset(dto: WikiAssetDTO) {
    dto = Object.assign(new WikiAssetDTO, dto);
    return dto;
  }

  public insertLoadersToContent(content: string) {
    const searchRegex = WikiConstants.AssetRegexp;
    let match;
    while (match = searchRegex.exec(content)) {
      let assetString = WikiConstants.AssetLoaderString.replace(WikiConstants.AssetIdString, match[1]);
      if (match[2]) { assetString = assetString.replace(WikiConstants.AssetSizeString, match[2]); }
      else { assetString = assetString.replace(` ${WikiConstants.AssetSizeString}`, ''); }
      content = content.replace(match[0], assetString);
    }
    return content;
  }

  public replaceLoadersWithAssets(content: string, assetId?: string) {
    let searchRegex;
    if (assetId) { searchRegex = WikiConstants.AssetLoaderRegexpFn(assetId); }
    else { searchRegex = WikiConstants.AssetLoaderRegexp; }
    let match;
    while (match = searchRegex.exec(content)) {
      let assetString = WikiConstants.AssetString.replace(WikiConstants.AssetIdString, match[1]);
      if (match[2]) { assetString = assetString.replace(WikiConstants.AssetSizeString, match[2]); }
      else { assetString = assetString.replace(` ${WikiConstants.AssetSizeString}`, ''); }
      content = content.replace(match[0], assetString);
    }
    return content;
  }
}
