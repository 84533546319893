<indicio-modal-base [child]="this"
                    modalTitle="{{ company?.Name }}"
                    [loading]=isLoading
                    modalClass=""
                    data-test-id="company-modal-container"
                    bodyExtraClass="overflow-auto">

  <div *ngIf="!isLoading"
       style="width: 85%; margin: auto;"
       class="company-modal">
    <ng-container *ngTemplateOutlet="modalNavigation">
    </ng-container>
    <ng-container [ngSwitch]="view">
      <ng-container *ngSwitchCase="'General'">
        <ng-container *ngTemplateOutlet="generalView"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Users'">
        <ng-container *ngTemplateOutlet="usersView">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Projects'">
        <ng-container *ngTemplateOutlet="projectsView">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Providers'">
        <ng-container *ngTemplateOutlet="providersView">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Tags'">
        <ng-container *ngTemplateOutlet="tagsView">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'Security'">
        <ng-container *ngTemplateOutlet="securityView">
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="buttonRow"></ng-container>
  </div>
</indicio-modal-base>

<ng-template #modalNavigation>
  <div class="modal-menu">
    <ng-container *ngIf="!isUserGuest">
      <div data-test-id="company-modal-general-tab-btn"
           class="menu-button"
           [ngClass]="{ 'current': view === 'General', withSecurityTab: canForce2FA() }"
           (click)="changeView('General')">
        General
      </div>
      <div data-test-id="company-modal-users-tab-btn"
           class="menu-button"
           [ngClass]="{ 'current': view === 'Users', withSecurityTab: canForce2FA() }"
           (click)="changeView('Users')">
        Users
      </div>
      <div data-test-id="company-modal-projects-tab-btn"
           class="menu-button"
           [ngClass]="{ 'current': view === 'Projects', withSecurityTab: canForce2FA() }"
           (click)="changeView('Projects')">
        Projects
      </div>
      <div data-test-id="company-modal-providers-tab-btn"
           class="menu-button"
           [ngClass]="{ 'current': view === 'Providers', withSecurityTab: canForce2FA() }"
           (click)="changeView('Providers')">
        Providers
      </div>
      <div data-test-id="company-modal-providers-tab-btn"
           class="menu-button"
           [ngClass]="{ 'current': view === 'Tags', withSecurityTab: canForce2FA() }"
           (click)="changeView('Tags')">
        Tags
      </div>
      <div *ngIf="canForce2FA();"
           data-test-id="company-modal-providers-tab-btn"
           class="menu-button"
           [ngClass]="{ 'current': view === 'Security', withSecurityTab: canForce2FA() }"
           (click)="changeView('Security')">
        Security
      </div>
    </ng-container>

    <ng-container *ngIf="isUserGuest">
      <div data-test-id="company-modal-general-tab-btn"
           class="col-xs-6 menu-button"
           [ngClass]="{ 'current': view === 'General' }"
           (click)="changeView('General')">
        General
      </div>
      <div data-test-id="company-modal-users-tab-btn"
           class="col-xs-6 menu-button"
           [ngClass]="{ 'current': view === 'Users' }"
           (click)="changeView('Users')">
        Employees and Users
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #generalView>
  <!-- Company Overview -->
  <div class="row"
       *ngIf="companies.length > 1">
    <div class="col-xs-12 relative">
      <div data-test-id='change-company-dropdown'
           class="margin-top-5">
        <indicio-searchable-dropdown *ngIf="companies.length"
                                     [values]="companies | orderBy: 'Name'"
                                     optionDisplay="Name"
                                     [initializeWithFirstValue]="false"
                                     [selectedValue]="company"
                                     (valueChangeEvent)="setCompany($event.CompanyId)">
        </indicio-searchable-dropdown>
      </div>
    </div>
  </div>

  <div data-test-id="company-modal-general-tab-container"
       class="company-general-container">
    <div class="text-center">
      Company information
    </div>
    <div class="row">
      <div class="col-xs-6">Company name: </div>
      <div class="col-xs-6 text-right"
           data-test-id="company-modal-company-name">{{ company.Name }}</div>
    </div>
    <div class="row">
      <div class="col-xs-6">Country: </div>
      <div class="col-xs-6 text-right"
           data-test-id="company-modal-country">{{ company.CountryCode | countryName }}</div>
    </div>
    <ng-container *ngIf="!isUserGuest">
      <div class="row">
        <div class="col-xs-6">Forecasts: </div>
        <div class="col-xs-6 text-right"
             data-test-id="company-modal-forecast-count">{{ company.ForecastCount }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">Users: </div>
        <div class="col-xs-6 text-right"
             data-test-id="company-modal-user-count">{{ company.UserCount }} of {{ company.MaxUsers }} max users</div>
      </div>
      <div class="row">
        <div class="col-xs-6">Number of employees: </div>
        <div class="col-xs-6 text-right"
             data-test-id="company-modal-employee-count">{{ company.Employees.length }}</div>
      </div>
      <div class="row">
        <div class="col-xs-6">Registration date: </div>
        <div class="col-xs-6 text-right"
             data-test-id="company-modal-registration-date">{{ company.CreatedDate | date: appearance.DateFormat }}
        </div>
      </div>
      <div *ngIf="!canUserEdit"
           class="row">
        <div class="col-xs-6">Fiscal year start: </div>
        <div class="col-xs-6 text-right"
             data-test-id="company-modal-registration-date">{{ envService.fiscalOpts[company.FiscalYear].Display }}
        </div>
      </div>
      <div class="row">
        <div class="col-xs-8"></div>
        <div class="col-xs-4">
          <button class="btn pull-right"
                  data-test-id="company-modal-remove-all-data-btn"
                  *ngIf="canResetCompanyData"
                  (click)="removeAllCompanyData()">
            Remove all data
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <indicio-modal-option-row *ngIf="canUserEdit"
                            optionTitle="Fiscal year start"
                            optionSubtitle="Month when the fiscal year starts">
    <mat-form-field style="margin-top:15px">
      <mat-select [value]="company.FiscalYear"
                  (selectionChange)="setFiscalYear($event)"
                  required>
        <mat-option [value]="fiscalOpt.Value"
                    [title]="fiscalOpt.Display"
                    *ngFor="let fiscalOpt of envService.fiscalOpts">{{ fiscalOpt.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
  <indicio-modal-option-row optionTitle="Data visibility setttings"
                            optionSubtitle="Default visibility for variables imported from data providers">
    <mat-form-field style="margin-top:15px">
      <mat-select [value]="SourceFromProviderVisibility"
                  [disabled]="!canUserEdit"
                  (selectionChange)="setSourceFromProviderVisibility($event)"
                  required>
        <mat-option [value]="visibilityLevels.Value"
                    [title]="visibilityLevels.Display"
                    *ngFor="let visibilityLevels of envService.VisibilityLevels">{{ visibilityLevels.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
  <indicio-modal-option-row optionSubtitle="Default visibility for variables imported from files">
    <mat-form-field style="margin-top:15px">
      <mat-select [value]="SourceFromFileVisibility"
                  [disabled]="!canUserEdit"
                  (selectionChange)="setSourceFromFileVisibility($event)"
                  required>
        <mat-option [value]="visibilityLevels.Value"
                    [title]="visibilityLevels.Display"
                    *ngFor="let visibilityLevels of envService.VisibilityLevels">{{ visibilityLevels.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #usersView>
  <!-- Employees -->
  <div data-test-id="company-modal-users-tab-container">
    <indicio-members-list *ngIf="!isUserGuest"
                          [MemberListOptions]="memberListOptions"
                          data-test-id="company-modal-users-list"
                          (hasActiveInviteEvent)="toggleSaveMemberButton($event)"
                          (memberChangedRoleEvent)="updateMemberRole($event)"
                          [company]="company"
                          [shouldInviteNewMember]="shouldInviteNewMember"
                          (atSaveEvent)="saveInvitation();">
    </indicio-members-list>
    <ng-container *ngIf="isUserGuest">
      <div class="row margin-top"
           *ngFor="let employee of company.Employees">
        <div class="col-xs-8">{{ employee.FirstName }} {{ employee.LastName }}</div>
        <div class="col-xs-4 text-right">{{ envService.getCompanyRole(employee.CompanyRole).Display }}</div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-xs-6">
        <button *ngIf="canViewUserStatistics()"
                class="btn left outline blue"
                (click)="openClientActivityModal()">
          <span>User statistics</span>
        </button>
      </div>
      <div class="col-xs-6">
        <button *ngIf="canResetCompanyData"
                class="btn pull-right right"
                data-test-id="company-modal-reser-users-btn"
                (click)="resetAllUsers()">
          Reset all users
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #projectsView>
  <!-- Project list -->
  <div data-test-id="company-modal-projects-tab-container"
       [attr.data-project-count]="projects.length">
    <div *ngFor="let project of projects; let index = index"
         [attr.data-test-id]="'company-modal-project-info' + '_' + index"
         class="project clearfix">
      <div class="col-xs-4">Name:</div>
      <div class="col-xs-8">{{ project.Name }}</div>
      <div class="col-xs-4">Owner: </div>
      <div class="col-xs-8 crop-string"
           [title]="getEmployeeEmail(project.OwnerId)?.Email">{{ getEmployeeEmail(project.OwnerId)?.Email }}</div>
      <div class="col-xs-4">Forecasts: </div>
      <div class="col-xs-8">{{ project.ForecastCount }}</div>
    </div>
    <div *ngIf="projects.length === 0"
         data-test-id="company-modal-no-projects-info">
      <div class="col-xs-12 text-center italic no-projects-found">No projects found</div>
    </div>
  </div>
</ng-template>

<ng-template #providersView>
  <!-- Providers list -->
  <div data-test-id="company-modal-providers-tab-container">
    <div style="max-height: 400px; overflow-y: auto; overflow-x:hidden">
      <indicio-modal-option-row *ngFor="let provider of providers"
                                textWidth="10"
                                optionsWidth="2"
                                [optionTitle]="envService.getSourceType(provider.Name).Display"
                                [optionSubtitle]="envService.getSourceType(provider.Name).Description">
        <div class="toggle-icon"
             style="margin-top: 25px;">
          <!-- <mat-slide-toggle data-test-id='auto-variable-update-toggle-btn'
                            [indicioTippy]="canUserEdit ? 'Activate or deactivate this data provider' : 'Ask your company admin to enable/disable different data providers'"
                            [disabled]="!canUserEdit"
                            [checked]="!company.DisabledProviders.includes(provider.Name)"
                            (change)="toggleDisabledProvider(provider.Name)">
          </mat-slide-toggle> -->
        </div>
      </indicio-modal-option-row>
    </div>
  </div>
</ng-template>

<ng-template #tagsView>
  <div data-test-id="company-modal-tags-tab-container">
    <div style="margin-bottom: 15px;">
      Tags are used to sort and order variables and forecasts
    </div>
    <indicio-tag-list [companyId]="opts.companyId"></indicio-tag-list>
  </div>
</ng-template>

<ng-template #securityView>
  <indicio-modal-option-row textWidth="10"
                            optionsWidth="2"
                            optionTitle="Require two-factor authentication (2FA) for all employees"
                            optionSubtitle="If required, all employees must setup two-factor authentication.">
    <div class="row"
         style="height: 63px;
                display: flex;
                justify-content: center;">
      <div class="col-xs-12"
           style="align-self: center;">
        <mat-slide-toggle indicioTippy="Enable 2FA"
                          [checked]="company.Force2FA"
                          (change)="toggleForce2FA()">
        </mat-slide-toggle>
      </div>
    </div>
  </indicio-modal-option-row>
  <indicio-modal-option-row optionTitle="Force automatic logout"
                            optionSubtitle="If enabled, company users cannot disable automatic logout."
                            textWidth="10"
                            optionsWidth="2">
    <div class="toggle-icon"
         style="margin-top: 25px;">
      <mat-slide-toggle [checked]="company.ForceAutomaticLogout"
                        (change)="toggleForceAutomaticLogout()">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>
  <div class="extra-margin-top"></div>
</ng-template>

<ng-template #buttonRow>
  <div class="row"
       *ngIf="view !== 'Projects' && view !== 'Security'">
    <div class="col-xs-6">
      <button data-test-id="company-modal-close-btn"
              *ngIf="company.hasPermission('CAN_UPDATE_COMPANY')"
              class="btn outline blue left"
              type="button"
              (click)="close()">
        <span *ngIf="isCompanyChanged">Cancel</span>
        <span *ngIf="!isCompanyChanged">Close</span>
      </button>
    </div>
    <div class="col-xs-6"
         [title]="saveAndInviteTitle"
         *ngIf="company.hasPermission('CAN_UPDATE_COMPANY') || !company.hasPermission('CAN_UPDATE_COMPANY') && company.invitesToSendOnSave.length || shouldInviteNewMember">
      <button data-test-id="company-modal-save-btn"
              class="btn right"
              [ngClass]="{ disabled: !isCompanyChanged }"
              type="submit"
              (click)="save()"
              *ngIf="view !== 'Users'">
        <span *ngIf="!pending">
          <span>Save</span>
        </span>
        <span *ngIf="pending"
              class="ion-load-c spinner"></span>
      </button>
    </div>
    <div class="col-xs-6"
         *ngIf="!company.hasPermission('CAN_UPDATE_COMPANY') && !company.invitesToSendOnSave.length && ! shouldInviteNewMember">
      <button data-test-id="company-modal-cancel-btn"
              class="btn right"
              (click)="close()">
        <span>Close</span>
      </button>
    </div>
  </div>
</ng-template>
