import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ProjectHelpers } from '@core/store/project/project-helpers';
import { IndicioPermissionType } from '@core/types/indicio-permission.type';
import { CalendarEffects } from '@dialogs/variables/forecast-variable/info/tabs/seasonal/fvar-calendar-settings';
import { AggregationMethod } from '@modules/lang/language-files/aggregation';
import { OutlierDetectionType, OutlierModelType, OutlierStrategyType } from '@modules/lang/language-files/outliers';
import { SeasonalModelType, SeasonalOutlierType, SeasonalStrategyType, SeasonalTrendType } from '@modules/lang/language-files/seasonal';
import { VisibilityLevel } from '@modules/lang/language-files/visibility';
import { SecurityUtils } from '@shared/utils/security.utils';
import { ProjectMemberModel } from './project-member.model';


export class ProjectModel implements IHasModelId {

  @Def() ProjectId: string = null;
  @Def() CompanyId: string = null;
  @Def() OwnerId: string = null;
  @Def() Name: string = null;
  @Def() AggregationMethodId = 'average';
  @Def() VisibilityLevelId = 'private';
  @Def() CreatedDate: Date = null;
  @Def() ForecastCount: number = 0;
  @Def() Alpha = 0.05;
  @Def() ModelThresholdMAPE = 20;
  @Def() AssessmentWeightLow = 20;
  @Def() AssessmentWeightMedium = 50;
  @Def() AssessmentWeightHigh = 90;
  @Def() CalculateOutliers = true;
  @Def() CalculateSeasonal = true;
  @Def() AutoUpdateVariables = false;
  @Def() SeasonalModel: SeasonalModelType;
  @Def() SeasonalStrategy: SeasonalStrategyType;
  @Def() SeasonalTrend: SeasonalTrendType;
  @Def() UseSeasonalTrend: boolean;
  @Def() OutlierModel: OutlierModelType;
  @Def() OutlierStrategy: OutlierStrategyType;
  @Def() OutlierTypes: OutlierDetectionType[];
  @Def() SeasonalOutliers: boolean;
  @Def() SeasonalCalendarEffects: CalendarEffects.CalendarType[];
  @Def() SeasonalOutlierTypes: SeasonalOutlierType[];
  @Def() OwnerEmail: string = null;
  @Def() Permissions: IndicioPermissionType[] = [];
  @Def() ForecastIds: string[] = [];
  @Def() Forecasts: ForecastVersionModel[] = [];
  @Def() Members: ProjectMemberModel[] = [];

  // Frontend types
  public AggregationMethod: AggregationMethod = null;
  public VisibilityLevel: VisibilityLevel = null;
  public openMenu: boolean; // sidemenu

  // Site specific
  public unsaved = false;
  invitesToSendOnSave = [];
  isLoading = false;
  isPending = false;
  activeUserCanCreateForecast = false;
  fetched = false;

  public getModelId() { return this.ProjectId; }
  public GetMemberByEmail(email: string) { return this.Members.find(x => x.Email === email); }
  public isUserAdmin(userEmail: string) { return ProjectHelpers.userHasRole(userEmail, this.Members, 'projectadmin'); }
  public isUserOwner(userEmail: string) { return this.OwnerEmail === userEmail; }
  public isMember(userEmail: string) { return this.Members.some(x => x.Email === userEmail); }
  public hasPermission(permissionsNeeded: IndicioPermissionType) { return SecurityUtils.checkPermission(this.Permissions, permissionsNeeded); }
}
