
export class PriorityOptionType {
  Title: string;
  Explanation: string;
  Value: number;
}

export const priorityOptions: PriorityOptionType[] = [
  { Title: 'High', Explanation: 'Prio over standard and low (1000)', Value: 1000 },
  { Title: 'Standard', Explanation: 'Prio over low (100)', Value: 100 },
  { Title: 'Low', Explanation: 'Prio over noting. Show last. Bad post. (10)', Value: 10 },
];
