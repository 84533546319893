import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { NavigateToHome } from '@core/actions/navigation.actions';
import { IMemberModel } from '@core/interfaces/if-member';
import { MemberMapper } from '@core/mappers/member.mapper';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { UpdatedUserPermissionsAction } from '@core/store/client/client.actions';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyActions } from '@core/store/company/company.actions';
import { CompanyModel } from '@core/store/company/company.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { GetProjectMembersSuccessAction, RemoveProjectMemberSuccessAction, RemoveProjectSuccessAction } from '@core/store/project/project.actions';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { CalendarEffects } from '@dialogs/variables/forecast-variable/info/tabs/seasonal/fvar-calendar-settings';
import { SeasonalCalendar, SeasonalModelType } from '@modules/lang/language-files/seasonal';
import { Store } from '@ngxs/store';
import { MemberListOptions } from '@shared/components/member-list/member-list.options';
import { MembersListComponent } from '@shared/components/member-list/members-list.component';
import { OpenConfirmModal } from '@shared/modals/confirm/confirm-modal.actions';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { OpenEditProjectModal } from './project-edit-modal.actions';
import { EditProjectModalOpts } from './project-edit-modal.options';


type modalViews = 'General' | 'Variables' | 'Members' | 'Assessment' | 'data-processing';

@Component({
  selector: 'indicio-project-edit-modal',
  templateUrl: './project-edit-modal.component.html',
  styleUrls: ['./project-edit-modal.less']
})
export class ProjectEditModalComponent extends ModalModelComponent implements AfterViewChecked {

  @ViewChild(MembersListComponent)
  private membersList: MembersListComponent;
  public memberListOptions: MemberListOptions = null;

  // Input properties
  project: ProjectModel = null;
  company: CompanyModel = null;
  index: number = null;
  canUserEdit = false;

  // Option-values
  public get alphaOptions() { return this.envService.alphaOptions; }

  frequencyExp = 'Frequency is...';
  alphaExp = 'The significance level of the models built';
  digitsExp = 'Number of decimals';
  ciExp = 'Confidence interval';
  sizeExp =
    'The % of the dataset that will be used for testing. ' +
    'This value is only preliminary and might be changed during calculation';
  thresholdExp = 'All models under the threshold will be thrown away';

  views: modalViews[] = ['General', 'Variables', 'Members', 'Assessment', 'data-processing'];
  view: modalViews = this.views[0];
  showAggInfo = false;
  projects: ProjectModel[];

  newProjectName: string = null;
  nameConflict: boolean;

  shouldInviteNewMember = false;

  canUpdateProject: boolean;
  canDeleteProject: boolean;
  userIsOwner: boolean;
  userIsMember: boolean;

  initialProject = null;
  outlierOpen = false;
  seasonalOpen = false;

  public availableSeasonalEffects: SeasonalCalendar[];

  constructor(
    public envService: EnvironmentService,
    public clientService: ClientFrontendService,
    private actions: ActionService,
    private store: Store,
    private status: StatusService,
    private projectService: ProjectFrontendService,
    private profileService: ProfileFrontendService,
    private memberMapper: MemberMapper,
    public appearance: AppearanceService,
    private cd: ChangeDetectorRef,
    private dialog: DialogService
  ) {
    super();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  public get advancedMode() { return this.appearance.AdvancedUI; }

  public get isProjectChanged() {
    const projUpd = this.initialProject !== JSON.stringify(this.project);
    const memberUpd = (this.membersList && this.invitesToSend > 0);
    const nameUpd = this.project.Name !== this.newProjectName;
    const updated = projUpd || memberUpd || nameUpd;

    return updated;
  }

  private get profile() { return this.profileService.profile; }
  public get invitesToSend() { return this.membersList.invitesToSendOnSave.length; }
  public toggleSaveMemberButton(shouldToggle) {
    this.shouldInviteNewMember = shouldToggle;
  }
  public OnRangeInput(value) { this.project.Alpha = this.alphaOptions[value]; }
  public changeThresholdMape(event) { this.project.ModelThresholdMAPE = +event.target.value; }
  public changeView(newView: modalViews) {
    this.view = newView;
    this.onViewChange();
  }

  public setOptions(options: EditProjectModalOpts) {
    this.company = this.clientService.activeCompany;
    this.projects = this.projectService.projects;
    this.projectService.getOrFetchProject(options.project.CompanyId, options.project.ProjectId)
      .then(proj => {
        this.project = proj;
        this.newProjectName = this.project.Name;
        options.project.openMenu = undefined;
        this.setPermissions();
        this.userIsOwner = this.project.isUserOwner(this.profileService.profile.Email);
        this.userIsMember = this.project.isMember(this.profileService.profile.Email);
        this.addSubscription(
          this.actions.dispatched(GetProjectMembersSuccessAction, RemoveProjectMemberSuccessAction).subscribe(
            (value: GetProjectMembersSuccessAction | RemoveProjectMemberSuccessAction) => {
              if (value.projectId === this.project.ProjectId) {
                this.setupMemberListOptions();
              }
            }));
        this.setupMemberListOptions();
        this.addSubscription(this.actions.dispatched(UpdatedUserPermissionsAction).subscribe(() => {
          this.setPermissions();
        }));

        this.addSubscription(this.actions.dispatched(CompanyActions.GetCompanySuccessAction).subscribe({
          next: (value: CompanyActions.GetCompanySuccessAction) => {
            if (value.company.CompanyId === this.company.CompanyId) {
              this.company = value.company;
              this.setupMemberListOptions();
            }
          }
        }));

        this.addSubscription(this.actions.dispatched(RemoveProjectSuccessAction).subscribe((action: RemoveProjectSuccessAction) => {
          if (action.projectId === this.project.ProjectId) {
            this.close();
          }
        }));

        if (options.view) {
          this.view = <modalViews> options.view;
        }
        this.initialProject = JSON.stringify(options.project);
        this.isLoading = false;
      });
  }

  public setOutlierModel(change: MatSelectChange) {
    this.project.OutlierModel = change.value;
  }
  public setOutlierStrat(change: MatSelectChange) {
    this.project.OutlierStrategy = change.value;
  }

  public selectOutlierType(change: MatOptionSelectionChange) {
    const idx = this.project.OutlierTypes.findIndex(x => x === change.source.value);
    if (change.source.selected) {
      if (idx === -1) {
        this.project.OutlierTypes.push(change.source.value);
      }
    } else {
      if (idx !== -1) {
        this.project.OutlierTypes.splice(idx, 1);
      }
    }
    this.project.OutlierTypes.sort();
  }

  public setSeasonalModel(change: MatSelectChange) {
    const val: SeasonalModelType = change.value;
    this.project.SeasonalModel = change.value;
    if (val === 'x11') { this.project.SeasonalStrategy = 'previous'; }
  }

  public setSeasonalStrat(change: MatSelectChange) {
    this.project.SeasonalStrategy = change.value;
  }

  public setSeasonalTrend(change: MatSelectChange) {
    this.project.SeasonalTrend = change.value;
  }

  private setPermissions() {
    this.canUserEdit = true;
    this.canUpdateProject = this.project.hasPermission('CAN_UPDATE_PROJECT');
    this.canDeleteProject = this.project.hasPermission('CAN_DELETE_PROJECT');
  }

  private setupMemberListOptions() {
    const options: MemberListOptions = {
      CurrentMembers: this.project.Members.map(m => this.memberMapper.mapProjectMember(m, this.project)),
      NewMemberSuggestions: this.company.getEmailsNotInProject(this.project),
      MemberService: this.projectService,
      CurrentUserCanInvite: this.project.hasPermission('CAN_CREATE_PROJECT_INVITE'),
      CurrentUserEmail: this.profile.Email,
      CompanyId: this.project.CompanyId,
      ProjectId: this.project.ProjectId,
      NewMemberRole: this.envService.getProjectRole('projectuser'),
      NewMemberRoleOptions: this.envService.ProjectRoles,
      Type: 'project'
    };
    this.memberListOptions = options;
  }

  public transferOwnership(mem: IMemberModel, leaveAfter: boolean = false) {
    const transferTo = mem.Email;
    this.dialog.openConfirmDialog({
      Title: 'Transfer project ownership',
      Message: `Transfer project ownership to ${transferTo}? Ownership can only be transfered by owners.`,
      CancelText: 'No',
      ConfirmText: 'Yes, transfer',
      Style: 'warn',
      ConfirmFunction: () => {
        this.projectService.transferOwnership(this.project, transferTo)
          .then(() => {
            this.setupMemberListOptions();
            if (leaveAfter) {
              this.projectService.leave(this.project.CompanyId, this.project.ProjectId)
                .then(() => {
                  this.status.setMessage('Successfully left project', 'Success', false);
                  this.close();
                });
            } else {
              this.status.setMessage('Transfer Ok', 'Success', true);
            }
          })
          .catch(error => {
            this.status.setError(error);
          });
      }
    });
  }

  public updateMemberRole(event: { email: string, newRole: string; }) {
    this.memberListOptions.CurrentMembers.find(x => x.Email === event.email).MemberRole = this.envService.getProjectRole(event.newRole);
    this.project.GetMemberByEmail(event.email).ProjectRole = event.newRole;
    this.update();
    this.cd.detectChanges();
  }

  public changeProjectName(newName: string) {
    this.newProjectName = newName.trim();
    if (this.projects.find(project => project.Name === this.newProjectName && project.ProjectId !== this.project.ProjectId)) {
      this.setError(true);
    } else {
      this.setError(false);
    }
  }

  public leaveProject() {
    this.close();
    const proceed = () => {
      this.projectService.leave(this.project.CompanyId, this.project.ProjectId)
        .then(() => {
          this.status.setMessage('Left project', 'Success');
        })
        .catch(error => {
          this.status.setError(error);
        });
    };
    const back = () => this.store.dispatch(new OpenEditProjectModal(this.project));
    const message = 'Are you sure you want to leave this project?';
    const title = `Leave project: ${this.project.Name}`;
    const decline = 'No';
    const confirm = 'Yes, leave it';
    this.store.dispatch(new OpenConfirmModal(proceed, message, title, decline, confirm, true, back));
  }

  public saveInvitation() {
    this.membersList.sendQueuedInvites()
      .then(() => {
        this.initialProject = JSON.stringify(this.project);
        this.update();
      })
      .finally(() => {
        this.memberListOptions.MemberService.isLoading = false;
        setTimeout(() => {
          this.membersList.sortMembers();
        }, 0);
      });
  }

  public update() {
    this.project.Name = this.newProjectName;
    return this.projectService.updateProject(this.project)
      .then(pm => {
        this.project = pm;
        this.status.setMessage('Project updated', 'Success', true);
        this.initialProject = JSON.stringify(this.project);
      })
      .catch(error => {
        this.status.setError(error, true);
      })
      .finally(() => {
        this.setupMemberListOptions();
        setTimeout(() => {
          if (this.membersList) {
            this.membersList.sortMembers();
          }
        }, 0);
      });
  }

  public removeProject(project: ProjectModel, customMessage?: string) {
    this.close();
    const proceed = () => {
      this.store.dispatch(new NavigateToHome());
      this.projectService.deleteProject(project)
        .then(() => {
          this.status.setMessage('Project removed', 'Success');
        })
        .catch(error => {
          this.status.setError(error);
        });
    };
    const back = () => this.store.dispatch(new OpenEditProjectModal(this.project));
    const message = customMessage || 'Are you sure you want to delete this project?';
    const title = 'Remove project';
    const decline = 'No';
    const confirm = 'Yes, delete it';
    const negativeEffect = true;
    this.store.dispatch(new OpenConfirmModal(proceed, message, title, decline, confirm, negativeEffect, back));
  }

  public toggleOutlier() { this.project.CalculateOutliers = !this.project.CalculateOutliers; }
  public toggleSeasonal() { this.project.CalculateSeasonal = !this.project.CalculateSeasonal; }
  public toggleUseTrend() { this.project.UseSeasonalTrend = !this.project.UseSeasonalTrend; }
  public toggleAutoUpdateVariables() { this.project.AutoUpdateVariables = !this.project.AutoUpdateVariables; }

  public invite() {
    this.membersList.invite();
  }

  private onViewChange() {
    this.membersList?.reset();
    if (this.view === 'Assessment') {
      setTimeout(() => {
        const assLowElem = <HTMLInputElement> document.getElementById('assLowRange');
        assLowElem.value = this.project.AssessmentWeightLow.toString();
        const assMedElem = <HTMLInputElement> document.getElementById('assMidRange');
        assMedElem.value = this.project.AssessmentWeightMedium.toString();
        const assHighElem = <HTMLInputElement> document.getElementById('assHighRange');
        assHighElem.value = this.project.AssessmentWeightHigh.toString();
      }, 0);
    }
  }

  private setError(state) {
    this.nameConflict = state;
  }

  public toggleAccordion(name) {
    if (name === 'outlier') {
      this.outlierOpen = !this.outlierOpen;
      this.seasonalOpen = false;
    } else if (name === 'seasonal') {
      this.seasonalOpen = !this.seasonalOpen;
      this.outlierOpen = false;
    }
  }

  /***
   *
   * New seasonal stuff
   */

  setAvailableSeasonalEffects() {
    this.availableSeasonalEffects = this.envService.SeasonalCalendarEffects;
  }

  public selectSeasonalcalendarEffect(change: MatOptionSelectionChange) {
    if (!change.isUserInput) { return; }
    const updated = CalendarEffects.updateSeasonalCalendarEffects(this.project.SeasonalCalendarEffects.slice(0), change, true);
    setTimeout(() => {
      this.project.SeasonalCalendarEffects = updated;
    });
  }

  public selectSeasonalOutlierType(change: MatOptionSelectionChange) {
    if (!change.isUserInput) { return; }
    const changed = change.source.value;
    if (change.source.selected) {
      this.project.SeasonalOutlierTypes.addUniqueId(changed);
    } else {
      this.project.SeasonalOutlierTypes.removeFirst(x => x === changed);
    }
    this.project.SeasonalOutlierTypes.sort();
  }

  public toggleSeasonalOutlier() {
    this.project.SeasonalOutliers = !this.project.SeasonalOutliers;
  }
}


