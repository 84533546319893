<indicio-dialog>
  <h1 mat-dialog-title>Request info for "{{ data.request.Name || data.request.Id }}"</h1>
  <div mat-dialog-content>
    <p>Current status is <span [class]="data.request.Enabled ? 'green' : 'red'">{{ data.request.Enabled ?
        'enabled' : 'disabled' }}</span></p>
    <div style="--grid-template-global: 150px 1fr">
      <ng-container *ngTemplateOutlet="requestInfoTemplate"></ng-container>
      <ng-container *ngIf="requestDetails; else loading">
        <ng-container *ngTemplateOutlet="instrumentsTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
      </ng-container>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-stroked-button
            (click)="onNoClick()">Close</button>
  </div>
</indicio-dialog>

<ng-template #loading>
  <br>
  <br>
  <indicio-spinner color="var(--indicio-modal-text-color, black)"></indicio-spinner>
</ng-template>

<ng-template #requestInfoTemplate>
  <h2>Meta info</h2>

  <div class="box">
    <div class="grid-system">
      <div>Id</div>
      <div>{{ data.request.Id }}</div>
    </div>

    <div class="grid-system">
      <div>Type</div>
      <div>{{ data.request.Type }}</div>
    </div>

    <div class="grid-system">
      <div>Frequency</div>
      <div>{{ data.request.Frequency }}</div>
    </div>

    <div class="grid-system">
      <div>Modified</div>
      <div>{{ data.request.Modified | dateFormat: null: 'yyyy-MM-dd HH:mm' }}</div>
    </div>

    <div class="grid-system">
      <div>Last run</div>
      <div>{{ data.request.LastRun | dateFormat: null: 'yyyy-MM-dd HH:mm' }}</div>
    </div>

    <div class="grid-system">
      <div>Next run</div>
      <div>{{ data.request.NextRun | dateFormat: null: 'yyyy-MM-dd HH:mm' }}</div>
    </div>
  </div>
</ng-template>

<ng-template #instrumentsTemplate>
  <h2>Instruments</h2>

  <div class="box">
    <div class="grid-system title-row"
         style="--grid-template: 1fr">
      <div>Name</div>
    </div>

    <div class="grid-system"
         style="--grid-template: 1fr"
         *ngFor="let inst of requestDetails.Identifiers">
      <div>{{ inst.Value }}</div>
    </div>
  </div>
</ng-template>

<ng-template #fieldsTemplate>
  <h2>Fields</h2>

  <div class="box">
    <div class="grid-system  title-row"
         style="--grid-template: 1fr 1fr 1fr">
      <div>Name</div>
      <div>Mnemonic</div>
      <div>Type</div>
    </div>

    <div class="grid-system"
         style="--grid-template: 1fr 1fr 1fr"
         *ngFor="let field of requestDetails.Fields">
      <div>{{ field.CleanName }}</div>
      <div>{{ field.Mnemonic }}</div>
      <div>{{ field.Type }}</div>
    </div>
  </div>
</ng-template>
