import { Attribute, Component, Input, TemplateRef } from '@angular/core';
import { TeleportService } from '@core/services/frontend/teleport.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'indicio-portal',
  template: '<ng-container *ngTemplateOutlet="template$ | async; context: context"></ng-container>'
})
export class PortalComponent {

  readonly template$: Observable<TemplateRef<any>>;

  @Input() context: any;

  constructor(@Attribute('name') name: string, private teleport: TeleportService) {

    // Builds the template observable
    this.template$ = this.teleport.pipe(
      // Filters only those instances targetting this very portal
      filter(instance => !instance || (name in instance)),
      // Returns the template or null
      map(instance => instance && instance[name])
    );
  }
}
