import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { VarSelectActions } from '@core/store/var-select/var-select.actions';
import { VariableSelectBackendService } from '@core/store/var-select/var-select.backend.service';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { VarSelectState } from './var-select.state';

@Injectable({
  providedIn: 'root'
})
export class VariableSelectFrontendService {

  public getVarSelectInFVersion(forecastVersionId: string) {
    const all = this.store.selectSnapshot(VarSelectState.varSelectResults);
    return all.filter(x => x.ForecastVersionId === forecastVersionId);
  }

  public fVersionHasValidVarSelect(forecastVersionId: string) {
    const all = this.store.selectSnapshot(VarSelectState.varSelectResults);
    const results = all.filter(x => x.ForecastVersionId === forecastVersionId);
    return results.length && results.every(x => x.ResultAvailable);
  }

  /** Returns an observable that emits when a var-select result for a given forecast-version is changed. */
  public getVarSelectInFVersion$(fVerionId: string) {
    return this.store.select(VarSelectState.vsByForecastVersionId)
      .pipe(map(filterFn => filterFn(fVerionId)));
  }

  constructor(
    private store: Store,
    private service: VariableSelectBackendService,
    private actions: ActionService,
  ) {
    this.setupActions();
  }

  public setNotFetched(forecastVersionId: string) {
    const results = this.getVarSelectInFVersion(forecastVersionId);
    if (results?.length) {
      results.forEach(r => r.fetched = false);
    }
  }

  public triggerVarSelect(forecastVersionId: string, autoTriggerMultiAfter: boolean = false) {
    return this.service.triggerVarSelect(forecastVersionId, autoTriggerMultiAfter)
      .then(() => {
        this.store.dispatch(new VarSelectActions.TriggerSuccess(forecastVersionId));
      })
      .catch(err => {
        this.store.dispatch(new VarSelectActions.TriggerFailed(err));
        return Promise.reject(err);
      });
  }

  public getOrFetchById(forecastVersionId) {
    const results = this.getVarSelectInFVersion(forecastVersionId);
    if (!results?.length || results.some(x => !x.fetched)) {
      return this.fetchVarSelect(forecastVersionId);
    } else {
      return Promise.resolve(results);
    }
  }

  public fetchVarSelect(forecastVersionId: string) {
    return this.service.getVariableSelectionResult(forecastVersionId)
      .then(varSelect => {
        this.store.dispatch(new VarSelectActions.GetSuccess(varSelect));
        return varSelect;
      });
  }

  private setupActions() {
    this.actions.dispatched(VarSelectActions.Trigger)
      .subscribe((action: VarSelectActions.Trigger) => this.triggerVarSelect(action.forecastVersionId));
  }
}
