<h1 mat-dialog-title>{{ this.edit ? 'Create new' : 'Edit' }} automated step</h1>
<div mat-dialog-content>
  <h3>Create steps here</h3>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
  <button mat-flat-button
          (click)="save()">
    Create
  </button>
</div>
