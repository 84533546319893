import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { take } from 'rxjs/operators';
import { DialogV2BaseService } from '../base/dialogs.V2.base-service';
import { CreateProjectDialogComponent, CreateProjectDialogData } from './create-project/create-project.dialog';


@Injectable({ providedIn: 'root' })
export class ProjectDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openCreate(data: CreateProjectDialogData) {
    const config = this.getConfig(CreateProjectDialogComponent.Id, data);
    const dialogRef = this.dialog.open<CreateProjectDialogComponent, any, any>(CreateProjectDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

}
