import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { SecurityConfigDTO, UserSecurityInfoDTO } from '@modules/admin/components/system/users/entities/security.dtos';
import { AdminUserService } from '@modules/admin/components/system/users/user.admin.service';
import { ClientInfoDTO, ClientMetaDTO } from '@modules/admin/entities/client-meta.dto';
import { AdminFrontendService } from '@modules/admin/services/admin-frontend.service';
import { Store } from '@ngxs/store';
import { OpenConfirmModal } from '@shared/modals/confirm/confirm-modal.actions';


@Component({
  selector: 'indicio-user-info-admin-dialog-general-tab',
  templateUrl: './user-info-tab.general.component.html',
  styleUrls: ['./user-info-tab.general.component.less'],
})
export class UserInfoGeneralTabComponent implements OnChanges {

  @Input() clientInfo: ClientInfoDTO;
  @Input() client: ClientMetaDTO;
  @Output() clientInfoChange = new EventEmitter<boolean>();

  public securityInfo: UserSecurityInfoDTO;
  public shouldSave: boolean = false;
  public saveError: string = null;
  public loading: boolean = true;
  private savedRoles: IndicioConstants.SystemRoleType[];

  public availableRoles = IndicioConstants.SystemRoles;

  constructor(
    private status: StatusService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private service: AdminFrontendService,
    public profile: ProfileFrontendService,
    public envService: EnvironmentService,
    private userService: AdminUserService,
  ) {
  }

  public ngOnChanges() {
    if (!this.client || !this.clientInfo) { return; }
    this.securityInfo = this.clientInfo.SecurityInfo;
    this.savedRoles = this.securityInfo.Roles;
    this.cd.detectChanges();
  }

  public checkRoles() {
    if (this.securityInfo.Roles.length < 1) {
      this.saveError = 'Must set at least one system role.';
    } else {
      this.saveError = null;
    }
    if (this.securityInfo.Roles.every(r => this.savedRoles.includes(r)) &&
      this.savedRoles.every(r => this.securityInfo.Roles.includes(r))) {
      this.shouldSave = false;
    } else {
      this.shouldSave = true;
    }

    this.clientInfoChange.emit(this.shouldSave);
  }

  public isGracePeriodWithinRange(date: string) {
    return moment().isBefore(moment(date));
  }

  public disable2faForUser() {
    const client = this.client;
    if (client.Profile.Email === this.profile.profile.Email) {
      this.status.setMessage('Cannot disable/enable 2fa for yourself. Go to account -> security instead', 'Warning');
      return;
    }

    this.store.dispatch(new OpenConfirmModal(
      () => {
        this.userService.disable2Fa(client)
          .then(() => {
            this.status.setMessage('2FA disabled for user', 'Success');
          })
          .catch(err => this.status.setError(err));
      },
      `Disable 2fa for user ${client.Profile.Email}`,
      'Disable 2fa for user',
      'No',
      'Disable',
      true,
      () => { },
      false,
      () => { }
    ));
  }

  public bump2FaGracePeriod() {
    const dto: SecurityConfigDTO = { ...this.securityInfo, Roles: this.savedRoles, BumpMfaGrace: true };
    this.userService.saveSecurityInfo(dto)
      .then(savedConf => {
        this.status.setMessage(`2FA grace period bumped to ${savedConf.ForceMfaFrom}`, 'Success');
        this.clientInfo.SecurityInfo = savedConf;
      });
  }

  public toggleDisableUser() {
    const client = this.client;
    if (client.Profile.Email === this.profile.profile.Email) {
      this.status.setMessage('Cannot disable/enable yourself!', 'Warning');
      return;
    }
    client.Disabled = !client.Disabled;
    this.service.updateClient(client)
      .then(c => {
        c.Disabled
          ? this.status.setMessage('Client disabled', 'Success')
          : this.status.setMessage('Client enabled', 'Success');
      })
      .catch(err => this.status.setError(err))
      .finally()
      ;
  }
}
