import { DataProvider } from '@core/constants/provider-definitions';
import { INavigationAction } from '@core/interfaces/if-navigation.action';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AutomationView } from '@modules/automation/components/automation-navigation/automation.navigation.component';

export type ForecastView = 'overview' | 'univariate' | 'multivariate' | 'scenario' | 'summary' | 'evaluation' | 'add-data';

export enum AddDataTab {
  sourcevariables = 'sourcevariables',
  uploadedfiles = 'uploadedfiles',
  dataproviders = 'dataproviders',
  historicevents = 'historicevents',
  forecastedvariables = 'forecastedvariables',
  macrobond = 'macrobond',
  database = 'database'
}

export const DataProviderProxy = new Map<string, DataProvider>();
Object.keys(DataProvider).forEach((provider: DataProvider) => {
  const value: string = DataProvider[<any> provider];
  DataProviderProxy.set(value, provider);
});

export enum AdminSites {
  audits = 'audits',
  autoTaskQueue = 'autoTaskQueue',
  autoTasks = 'autoTasks',
  autoTaskHistory = 'autoTaskHistory',
  automails = 'automails',
  automatedTasks = 'automatedTasks',
  bas64converter = 'bas64converter',
  company = 'company',
  eta = 'eta',
  events = 'events',
  forecastresults = 'forecastresults',
  forecasts = 'forecasts',
  forecasttemplates = 'forecasttemplates',
  forecastvariables = 'forecastvariables',
  frontendUtilsCommands = 'frontendUtilsCommands',
  hierarchicalforecasts = 'hierarchicalforecasts',
  historicevents = 'historicevents',
  invites = 'invites',
  logSettings = 'logSettings',
  overview = 'overview',
  openid = 'openid',
  performance = 'performance',
  queueStats = 'queueStats',
  pings = 'pings',
  projects = 'projects',
  providers = 'providers',
  rclients = 'rclients',
  rclientslogs = 'rclientslogs',
  remotedata = 'remotedata',
  rrequests = 'rrequests',
  scenario = 'scenario',
  scriptlogs = 'scriptlogs',
  rServerLoad = 'rServerLoad',
  sourcevariables = 'sourcevariables',
  syslogs = 'syslogs',
  termsofservice = 'termsofservice',
  test = 'test',
  unsupportedDates = 'unsupportedDates',
  unsupportedFiles = 'unsupportedFiles',
  uploadedfiles = 'uploadedfiles',
  systemusagegraphs = 'systemusagegraphs',
  detailedusage = 'detailedusage',
  rusage = 'rusage',
  users = 'users',
  wiki = 'wiki',
}

export class AlphaVantageModalBackAction {
  public static readonly type = '[ALPHA VANTAGE] Back button';
  constructor(public forecastVersion: ForecastVersionModel) { }
}

export class CloseMainMenuAction {
  public static readonly type = '[Menu] Close menu';
}

export class NavigateToSavedAdminPage {
  public static readonly type = '[NAVIGATION] Admin default view';
}

export class NavigateToAdmin implements INavigationAction {
  public static readonly type = '[NAVIGATION] Admin';
  public readonly url: string;
  public readonly after: Function;
  constructor(
    site: AdminSites = AdminSites.overview,
    after: Function = null
  ) {
    this.url = `/admin/(admin:${site})`;
    this.after = after;
  }
}

export class NavigateToForecast implements INavigationAction {
  public static readonly type = '[NAVIGATION] Forecast';
  public readonly url: string;
  public readonly after: Function;
  public readonly forecastId: string;
  constructor(
    forecastId: string,
    view: ForecastView = 'overview',
    after?: Function) {
    this.url = `/forecast/${forecastId}/(forecast:${view})`;
    this.after = after;
    this.forecastId = forecastId;
  }
}

export class NavigateToAutomation implements INavigationAction {
  public static readonly type = '[NAVIGATION] Task';
  public readonly after: Function;
  public readonly url: string;
  constructor(
    public page: AutomationView = 'workflows',
    after?: Function
  ) {
    this.after = after;
    this.url = `/automation/(automation:${page})`;
  }
}

export class NavigateToSignup implements INavigationAction {
  public static readonly type = '[NAVIGATION] Signup';
  public readonly url: string;
  public readonly after: Function;
  constructor(after?: Function) { this.url = '/signup'; this.after = after; }
}

export class NavigateToAddData implements INavigationAction {
  public static readonly type = '[NAVIGATION] Add data';
  public readonly url: string;
  public readonly after: Function;
  constructor(forecastId: string, tab: AddDataTab = AddDataTab.sourcevariables, after: Function = null) {
    if (tab === AddDataTab.sourcevariables) {
      this.url = `/forecast/${forecastId}/(forecast:add-data-source-variables)`;
    } else if (tab === AddDataTab.historicevents) {
      this.url = `/forecast/${forecastId}/(forecast:add-data-historic-events)`;
    } else if (tab === AddDataTab.uploadedfiles) {
      this.url = `/forecast/${forecastId}/(forecast:add-data-uploaded-files)`;
    } else if (tab === AddDataTab.macrobond) {
      this.url = `/forecast/${forecastId}/(forecast:add-data-macrobond)`;
    } else if (tab === AddDataTab.database) {
      this.url = `/forecast/${forecastId}/(forecast:add-data-database)`;
    } else {
      this.url = `/forecast/${forecastId}/(forecast:add-data)?tab=${tab}`;
    }
    this.after = after;
  }
}

export class NavigateToAddDataFromSheet implements INavigationAction {
  public static readonly type = '[NAVIGATION] Add data from sheet';
  public readonly url: string;
  public readonly after: Function;
  constructor(
    forecastId: string,
    fileId: string,
    type: string,
    after?: Function
  ) {
    this.url = `/forecast/${forecastId}/(forecast:add-data-from-sheet/${fileId})?type=${type}`; this.after = after;
  }
}

export class NavigateToDataProvider implements INavigationAction {
  public static readonly type = '[NAVIGATION] Add data from provider';
  public readonly url: string;
  public readonly after: Function;
  constructor(
    forecastId: string, provider: DataProvider, after?: Function, query: string = null
  ) {
    this.url = `/forecast/${forecastId}/(forecast:providers/${provider})`;
    this.after = after;
    switch (true) {
      case provider === 'eurostat' && !!query:
        this.url += `?search=${query}`;
        break;
      default:
        break;
    }
  }
}

export class NavigateToLogin implements INavigationAction {
  public static readonly type = '[NAVIGATION] Login';
  public readonly url: string;
  public readonly after: Function;
  constructor(after?: Function) { this.url = '/login'; this.after = after; }
}

export class NavigateReload implements INavigationAction {
  public static readonly type = '[NAVIGATION] Reload';
  public readonly url: string;
  public readonly after: Function;
  constructor(returnUrl: string = '/login', after?: Function) { this.url = returnUrl; this.after = after; }
}

export class NavigateToHome implements INavigationAction {
  public static readonly type = '[NAVIGATION] Home';
  public readonly url: string;
  public readonly after: Function;
  constructor(after?: Function) { this.url = '/home'; this.after = after; }
}

export class NavigateToReports implements INavigationAction {
  public static readonly type = '[NAVIGATION] Reports';
  public readonly url: string;
  public readonly after: Function;
  constructor(after?: Function) { this.url = '/reports'; this.after = after; }
}

export class NavigateToReport implements INavigationAction {
  public static readonly type = '[NAVIGATION] Report';
  public readonly url: string;
  public readonly after: Function;
  constructor(reportId: string, after?: Function) { this.url = `/reports/${reportId}`; this.after = after; }
}
