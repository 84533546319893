<mat-form-field [class]="extraClass">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <ng-content></ng-content>
  <input required
         #input
         [ngModel]="keyword"
         (keyup)="changed($event.target.value)"
         type="text"
         autocomplete="nope"
         class="material"
         matInput
         [ngModelOptions]="{standalone: true}"
         [matAutocomplete]="fKeywords"
         data-test-id="search-dropdown"
         style="transform: translateY(5px);">
  <span *ngIf="!!suffix"
        style="transform: translate(-10px, 6px); display: block;"
        matTextSuffix>{{ suffix }}</span>
  <div mat-button
       matSuffix
       style="transform: translateY(12px);"
       mat-icon-button>
    <i class="ion-close cursor-pointer"
       *ngIf="input.value && !searchLoading"
       (click)="clear()"></i>
    <i class="ion-load-c spinner"
       *ngIf="searchLoading"></i>
  </div>

  <mat-autocomplete #fKeywords="matAutocomplete">
    <mat-option *ngFor="let option of filteredAutoComplete"
                [title]="option[title]"
                [value]="option[title]"
                (onSelectionChange)="onSelect(option)">
      {{option[title]}} {{option['extra'] ? option['extra'] : null}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
