import { Injectable } from '@angular/core';
import { NavigateToHome } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { HttpService } from '@core/services/http/http.service';
import { AcceptToSSuccessAction, GetToSSuccessAction } from '@core/store/tos/tos.actions';
import { Store } from '@ngxs/store';
import { OpenToSModal } from '@shared/modals/tos/tos-modal.action';
import { DateUtils } from '@shared/utils/date.utils';
import { map } from 'rxjs/operators';
import { ClientState } from '../client/client.state';
import { TermsOfServiceDTO } from './tos.dtos';


@Injectable({
  providedIn: 'root'
})
export class ToSService {

  public get tosId() { return this.store.selectSnapshot(ClientState.acceptedTosId); }
  public get hasAcceptedTos() { return this.store.selectSnapshot(ClientState.acceptedTosId) === this.env.LatestToSId; }
  public get hasAcceptedTos$() {
    return this.store.select(ClientState.acceptedTosId).pipe(map(id => {
      return id === this.env.LatestToSId;
    }));
  }
  public get tosAgreements() { return this.store.selectSnapshot(ClientState.tosAgreements); }

  constructor(
    private store: Store,
    private http: HttpService,
    private env: EnvironmentService
  ) {
  }

  public openTosModal() {
    this.store.dispatch(new NavigateToHome);
    this.store.dispatch(new OpenToSModal);
  }

  public getOrFetchTosById(tosId: string = null) {
    const idx = this.tosAgreements.findIndex(tos => tos.TermsOfServiceAgreementId === tosId);
    if (idx === -1 || tosId === null) {
      return this.fetchTosById(tosId);
    } else {
      return Promise.resolve(this.tosAgreements[idx]);
    }
  }

  public async fetchTosById(tosId: string = null) {
    const path = tosId !== null ? `tos/${tosId}` : 'tos';
    const { body } = await this.http.get<TermsOfServiceDTO>(path);
    body.ValidFrom = DateUtils.newDate(body.ValidFrom);
    this.store.dispatch(new GetToSSuccessAction(body));
    return body;
  }

  public async acceptTos(tosId: string) {
    await this.http.post<null>(`tos/${tosId}/agree/`, null);
    this.store.dispatch(new AcceptToSSuccessAction(tosId));
    return tosId;
  }

  ///
  /// Admin stuff below
  ///

  public async fetchAll() {
    const { body } = await this.http.get<TermsOfServiceDTO[]>('admin/tos');
    body.forEach(x => x.ValidFrom = DateUtils.newDate(x.ValidFrom));
    body.forEach(tos => this.store.dispatch(new GetToSSuccessAction(tos)));
    return body;
  }

  public async saveNewToS(newToS: TermsOfServiceDTO) {
    const { body } = await this.http.post<TermsOfServiceDTO>('admin/tos', newToS);
    body.ValidFrom = DateUtils.newDate(body.ValidFrom);
    this.store.dispatch(new GetToSSuccessAction(body));
    return body;
  }
}


