import { Injectable } from '@angular/core';
import { SourceVariableMetaDTO } from '@core/store/source-variable/dtos/source-variable-dto';
import { SourceVariableActions } from '@core/store/source-variable/source-variable.actions';
import { Store } from '@ngxs/store';
import { PusherActionDTO } from '../dtos/pusher-action-dto';

export type PusherSourceVariableAction =
  'remove-source-variable' |
  'set-source-variable-used' |
  'set-source-variable-meta' |
  'sync-list-view' |
  'add-new-source-variable' |
  'remove-source-variable-meta' |
  'remote-bulk-sync' |
  'remote-bulk-sync-overview'
  ;

@Injectable({
  providedIn: 'root'
})
export class PusherSourceVariableBehaviourService {

  constructor(
    private store: Store,
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {
    switch (dto.Action) {

      case 'set-source-variable-used':
        return this.store.dispatch(new SourceVariableActions.GetUsageSuccess(dto.SourceVariableId, dto.Flag));

      case 'set-source-variable-meta':
      case 'add-new-source-variable':
        return this.store.dispatch(new SourceVariableActions.GetSourceVariableMetaSuccessAction(dto.SourceVariableId, dto.Obj as SourceVariableMetaDTO));

      case 'sync-list-view':
        return this.store.dispatch(new SourceVariableActions.SyncListView(dto.CompanyId));

      case 'remove-source-variable':
        return this.store.dispatch(new SourceVariableActions.RemovedSourceVariable(dto.SourceVariableId));

      case 'remove-source-variable-meta':
        return this.store.dispatch(new SourceVariableActions.FetchSourceVariable(dto.CompanyId, dto.SourceVariableId));

      case 'remote-bulk-sync':
        return this.store.dispatch(new SourceVariableActions.RemoteBulkSync(dto.CompanyId, dto.Obj));

      case 'remote-bulk-sync-overview':
        return this.store.dispatch(new SourceVariableActions.RemoteBulkSyncOverview(dto.CompanyId, dto.Obj));

      default:
        console.error(`Missing behaviour for action: (${dto.Action})`);
        return;
    }
  }
}
