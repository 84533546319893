<div class="model-prop-modal-container">
  <h1 mat-dialog-title
      style="margin-bottom: 10px;">{{modelName ? modelName : ''}}</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="!isLoading">
      <div *ngIf="images.length > 0; then showCriteriaImages"></div>
    </ng-container>

  </div>
  <div mat-dialog-actions
       style="margin-left: -12px;">
    <button mat-button
            (click)="onNoClick()">
      Close
    </button>
  </div>
</div>

<ng-template #notFound>
  <div class="row">
    <div class="col-xs-12">
    </div>
  </div>
</ng-template>


<ng-template #showCriteriaImages>
  <indicio-plot-display style="margin: 23px 0 0 -40px;"
                        [plots]="images"
                        [plotWidth]="630"></indicio-plot-display>

</ng-template>
