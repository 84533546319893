import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CreateCompanyDTO } from '@core/store/company/dtos/create-company-dto';
import { SupportService } from '@core/store/support/support.service';
import { CreateCompanyTabUsersComponent } from '@dialogs/admin/company/create/tabs/users/create-company-tab.users.component';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { AdminCompanyService } from '@modules/admin/components/system/companies/company.admin.service';
import { DisplayValue } from '@modules/lang/types/display-value';
import { DateUtils } from '@shared/utils/date.utils';

export interface CreateCompanySupportDialogData { }

export enum SupportCreateCompanyDialogViewIndex {
  'general' = 0,
  'options' = 1,
  'users' = 2,
  'preview' = 3,
}

@Component({
  selector: 'indicio-create-company-support-dialog',
  templateUrl: 'create-company.support.dialog.html',
})
export class CreateCompanySupportDialogComponent extends DialogV2BaseDialog<CreateCompanySupportDialogComponent> {
  @ViewChild(CreateCompanyTabUsersComponent) usersTab: CreateCompanyTabUsersComponent;

  public static Id: string = 'CreateCompanySupportDialogComponent';

  // Options
  public viewIndex: number = 0;
  public viewTypes = SupportCreateCompanyDialogViewIndex;
  public supportUsers: { role: string; users: DisplayValue[]; }[];

  public company: CreateCompanyDTO = null;
  pending: boolean;

  public get saveDisabled(): boolean {
    const generalOk = this.company.Name && this.company.CountryCode;
    const usersOk = !!this.company.Users.filter(x => x.valid).length;
    if (this.viewIndex === SupportCreateCompanyDialogViewIndex.general) {
      return !generalOk;
    }

    if (this.viewIndex === SupportCreateCompanyDialogViewIndex.preview) {
      return !generalOk || !usersOk;
    }

    return false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CreateCompanySupportDialogData,
    dialogRef: MatDialogRef<CreateCompanySupportDialogComponent, CreateCompanySupportDialogData>,
    public envService: EnvironmentService,
    private statusService: StatusService,
    private service: SupportService,
    private clientService: ClientFrontendService,
    private adminService: AdminCompanyService
  ) {
    super(dialogRef);
    this.initialize();
  }

  public backOrCancel() {
    if (this.viewIndex === SupportCreateCompanyDialogViewIndex.general) {
      this.dialogRef.close(false);
      return;
    }
    this.viewIndex--;
  }

  public saveOrNext() {
    if (this.viewIndex === SupportCreateCompanyDialogViewIndex.preview) {
      this.save();
      return;
    }

    if (this.viewIndex + 1 === SupportCreateCompanyDialogViewIndex.preview) {
      this.usersTab.parseUserList();
    }

    this.viewIndex++;

    if (this.viewIndex === SupportCreateCompanyDialogViewIndex.preview) {
      this.company.Users = this.usersTab.additionalUsers;

    }
  }

  protected async initialize() {
    this.company = new CreateCompanyDTO;
    this.company.MaxUsers = 10;
    this.company.Trial = true;
    this.company.TrialExpirationDate = DateUtils.newMoment().add(2, 'w').toISOString();
    this.company.LicenseType = IndicioConstants.Licenses.Macrobond;
    this.company.Support = [this.clientService.client.ClientId];
    if (this.clientService.isAdmin) {
      await this.getSupportUsers();
    }
    this.initialized = true;
  }

  private async getSupportUsers() {
    this.supportUsers = await this.adminService.getSupportUsers()
      .catch(err => {
        this.statusService.setError(err, true);
        return [];
      });
  }

  public changeView(index: number) {
    this.viewIndex = index;
  }

  private save() {
    this.pending = true;
    this.service.createCompany(this.company)
      .then(() => {
        this.close();
      })
      .catch(error => {
        this.statusService.setError(error, true);
      })
      .finally(() => this.pending = false);
  }

}
