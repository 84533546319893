import { AuthState } from '@core/store/auth/auth.state';
import { ClientState } from '@core/store/client/client.state';
import { CompanyState } from '@core/store/company/company.state';
import { FileState } from '@core/store/file/file.state';
import { ForecastState } from '@core/store/forecast/forecast.state';
import { HistoricEventState } from '@core/store/historic-event/historic-event.state';
import { NotificationState } from '@core/store/notifications/notification.state';
import { ProjectState } from '@core/store/project/project.state';
import { ScriptQueueState } from '@core/store/script-queue/script-queue.state';
import { SourceVariableState } from '@core/store/source-variable/source-variable.state';
import { AssessmentState } from './assessment/assessment.state';
import { AutoTaskState } from './auto-task/auto-task.state';
import { HierarchicalForecastState } from './hierarchical-forecast/hierarchical-forecast.state';
import { NewsState } from './news/news.state';
import { FredState } from './providers/fred/fred.state';
import { ReportState } from './reports/reports.state';
import { StatModelState } from './stat-model/stat-model.state';
import { TagsState } from './tags/tags.state';
import { VarSelectState } from './var-select/var-select.state';

export const IndicioStore = [
  AuthState,
  AssessmentState,
  ClientState,
  CompanyState,
  FileState,
  ForecastState,
  VarSelectState,
  StatModelState,
  HistoricEventState,
  HierarchicalForecastState,
  NotificationState,
  ProjectState,
  ScriptQueueState,
  SourceVariableState,
  FredState,
  AutoTaskState,
  NewsState,
  ReportState,
  TagsState
];
