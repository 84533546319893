import { ReportEntryModel, ReportModel } from '@core/store/reports/models/report.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { DateUtils } from '@shared/utils/date.utils';
import { ReportUtils } from '@shared/utils/report.utils';
import * as moment from 'moment';
import { FiscalYearEntryMeta, FiscalYearReport } from '../models/fiscal-year.model';

export class FiscalYearHelper {

  public static generateReport(report: ReportModel): FiscalYearReport {
    report = ReportUtils.cloneReport(report);
    const fiscalReport = new FiscalYearReport(report);
    const startDate = DateUtils.newMoment(report.StartDate).startOf('day');
    fiscalReport.Base.Name = report.Name;
    fiscalReport.Base.CreatorEmail = report.CreatorEmail;
    fiscalReport.CurrentYear = Number(DateUtils.newMoment(report.StartDate).format('YY'));
    fiscalReport.BeforeLastName = `${fiscalReport.CurrentYear - 2 < 10 ? '0' : ''}${fiscalReport.CurrentYear - 2}`;
    fiscalReport.LastName = `${fiscalReport.CurrentYear - 1 < 10 ? '0' : ''}${fiscalReport.CurrentYear - 1}`;
    fiscalReport.CurrentName = `${fiscalReport.CurrentYear < 10 ? '0' : ''}${fiscalReport.CurrentYear}`;

    report.Entries.forEach(entry => {
      const meta = new FiscalYearEntryMeta(entry);
      meta.Base.ForecastVersionId = entry.ForecastVersionId;
      meta.Base.ForecastId = entry.ForecastId;
      meta.Base.Name = entry.Name;
      const rollingDates = FiscalYearHelper.getRollingDates(entry);
      meta.RollingStart = rollingDates.rollingStart;
      meta.RollingEnd = rollingDates.rollingEnd;

      const { Value: CValue, Forecasted: CFCast } = FiscalYearHelper.calcHistoricValues(entry, startDate, true);
      meta.CurrentValue = CValue;
      meta.CurrentContainsFCast = CFCast;
      const { Value: LValue, Forecasted: LFCast } = FiscalYearHelper.calcHistoricValues(entry, startDate, false, 1);
      meta.LastValue = LValue;
      meta.LastContainsFCast = LFCast;
      const { Value: BValue, Forecasted: BFCast } = FiscalYearHelper.calcHistoricValues(entry, startDate, false, 2);
      meta.BeforeLastValue = BValue;
      meta.BeforeLastContainsFCast = BFCast;

      meta.HistoricCurrentVSPYFYPercentage = ((meta.LastValue - meta.BeforeLastValue) / meta.BeforeLastValue) * 100;
      meta.Rolling12 = FiscalYearHelper.calcRolling(entry, meta.RollingStart, meta.RollingEnd);
      const { Value: fy15TD, Forecasted: PYFYForecasted, Missing: pyfyMissing } = FiscalYearHelper.calcTDValues(entry, entry.pyFyStart, entry.pyFyToDate);
      meta.PYFYTD = fy15TD;
      meta.PYFYTDMissingValues = pyfyMissing;
      meta.PYFYTDContainsFCast = PYFYForecasted;
      const { Value: fy16TD, Missing: fy16Missing } = FiscalYearHelper.calcTDValues(entry, report.startDate, entry.currentToDate);
      meta.CurrentTD = fy16TD;
      meta.CurrentTDMissingValues = fy16Missing;
      meta.VSPYFYTD = ((fy16TD - meta.PYFYTD) / meta.PYFYTD) * 100;
      meta.CurrentForecastedValue = FiscalYearHelper.calcForecasted(entry, report.reportEndDate);
      meta.CurrentTotalValue = meta.CurrentForecastedValue + meta.CurrentValue;
      meta.ForecastedVSPYFYPercent = ((meta.CurrentTotalValue - meta.LastValue) / meta.LastValue) * 100;

      meta.Base.hasResults = entry.ForecastResults.length > 0;

      fiscalReport.Entries.push(meta);
    });

    fiscalReport.TotalCurrentValues = fiscalReport.Entries.map(x => x.CurrentValue).sum();
    fiscalReport.TotalLastValues = fiscalReport.Entries.map(x => x.LastValue).sum();
    fiscalReport.TotalBeforeLastValues = fiscalReport.Entries.map(x => x.BeforeLastValue).sum();
    fiscalReport.TotalHistoricCurrentVSPYFYPercent = ((fiscalReport.TotalLastValues - fiscalReport.TotalBeforeLastValues) / fiscalReport.TotalBeforeLastValues) * 100;
    fiscalReport.TotalRolling12 = fiscalReport.Entries.map(x => x.Rolling12).sum();
    fiscalReport.TotalPYFYTD = fiscalReport.Entries.map(x => x.PYFYTD).sum();
    fiscalReport.TotalCurrentTD = fiscalReport.Entries.map(x => x.CurrentTD).sum();
    fiscalReport.TotalVSPYFYTD = ((fiscalReport.TotalCurrentTD - fiscalReport.TotalPYFYTD) / fiscalReport.TotalPYFYTD) * 100;
    fiscalReport.TotalCurrentForecast = fiscalReport.Entries.map(x => x.CurrentForecastedValue).sum();
    fiscalReport.TotalCurrent = fiscalReport.TotalCurrentForecast + fiscalReport.TotalCurrentValues;
    fiscalReport.TotalForecastedVSPYFYPercent = ((fiscalReport.TotalCurrent - fiscalReport.TotalLastValues) / fiscalReport.TotalLastValues) * 100;
    return fiscalReport;
  }

  private static calcForecasted(entry: ReportEntryModel, endDate: moment.Moment): number {
    let values = entry.values.slice();
    values = values.filter(x => x.m.isSameOrAfter(entry.currentFromDate));
    values = values.filter(x => x.m.isSameOrBefore(endDate.clone().endOf(entry.Periodicity as any)));
    return values.map(x => x.V).sum();
  }

  private static isInFiscal(date: moment.Moment, startDate: moment.Moment, yearsBack = 0) {
    const targetStartDate = startDate.clone().startOf('month').subtract(yearsBack, 'years');
    const targetEndDate = targetStartDate.clone().add(1, 'years');

    if (date.isSameOrAfter(targetStartDate) && date.isBefore(targetEndDate)) {
      return true;
    }

    return false;
  }

  private static calcHistoricValues(entry: ReportEntryModel, startDate: moment.Moment, filterForecasted: boolean, year: number = 0) {
    let vals = entry.values.slice(0)
      .filter(x => FiscalYearHelper.isInFiscal(x.m, startDate, year));
    if (filterForecasted) {
      vals = vals.filter(x => x.m.isBefore(entry.ForecastStartDate));
    }
    const containsFcast = vals.filter(x => x.m.isSameOrAfter(entry.ForecastStartDate));
    const summa = vals.map(x => x.V).sum();
    return { Value: summa, Forecasted: containsFcast.length > 0 };
  }

  private static calcTDValues(entry: ReportEntryModel, startDate: moment.Moment, endDate: moment.Moment) {
    const shouldHaveNumValues = DateUtils.getPointsBetween(startDate, endDate, entry.Periodicity as PeriodicityType);
    let vals = entry.values.slice(0);
    vals = vals.filter(x => x.m.isBefore(entry.ForecastStartDate));
    vals = vals.filter(x => x.m.isSameOrAfter(startDate));
    vals = vals.filter(x => x.m.clone().endOf(entry.Periodicity as any).isSameOrBefore(endDate));
    const fcasted = vals.filter(x => x.m.isSameOrAfter(entry.ForecastStartDate));
    const summa = vals.map(x => x.V).sum();
    const missing = vals.length !== shouldHaveNumValues;
    return { Value: summa, Forecasted: fcasted.length > 0, Missing: missing };
  }

  private static calcRolling(entry: ReportEntryModel, start: moment.Moment, end: moment.Moment) {
    const values = entry.values
      .filter(x => x.m.isBefore(entry.ForecastStartDate))
      .filter(x =>
        x.m.isSameOrAfter(start) &&
        x.m.isBefore(end));
    if (!values.length) { return 0; }
    const r = values.map(x => x.V).sum();
    return r;
  }

  private static getRollingDates(entry: ReportEntryModel) {
    let rollingTo = entry.lastHistoricDate.clone();
    const fcastStart = DateUtils.newMoment(entry.ForecastStartDate);
    rollingTo = rollingTo.isSameOrAfter(fcastStart)
      ? fcastStart.subtract(1, entry.Periodicity as any)
      : rollingTo;
    const rollingStart = rollingTo.clone().subtract(11, 'month').startOf('month');
    const rollingEnd = rollingTo.add(1, 'month').startOf('month').subtract(1, 'day');
    return { rollingStart, rollingEnd };
  }
}
