
export class OpenCreateNewsModal {
  public static readonly type = '[MODAL] Open create news modal';
  data: {
    onClose: Function;
  };
  constructor(
    onClose?: Function
  ) {
    this.data = { onClose };
  }
}
