
import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[indicioScrollHorizontally]'
})
export class ScrollHorizontallyDirective implements AfterViewInit, OnDestroy {

  private dom: Element = null;

  constructor(ui: ElementRef) {
    this.dom = ui.nativeElement;
  }

  ngAfterViewInit() { this.dom.addEventListener('wheel', this.scroll.bind(this)); }
  ngOnDestroy() { this.dom.removeEventListener('wheel', this.scroll.bind(this)); }

  private scroll(e: WheelEvent) {
    // Prevent the default behavior of scrolling vertically
    e.preventDefault();
    const direction = e.deltaY > 0 ? 1 : -1;
    const distance = Math.abs(e.deltaY) > 50 ? direction * 50 : e.deltaY;
    this.dom.scrollLeft += distance;
  }
}
