import { ErrorHandler, Injectable } from '@angular/core';
import { GithubIssue } from '@core/entities/github.models';
import { ErrorReportService } from '@core/services/error-report.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { GlobalDialogService } from '@modules/dialog/global-dialogs.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  reportTimeout = null;
  errorReports: { [key: string]: GithubIssue.FrontendErrorReportDTO; } = {};
  errorsReported: string[] = [];

  private get activeForecastId() { return this.forecastService.activeForecastId; }
  private get companyId() { return this.companyService.activeCompanyId; }

  constructor(
    private service: ErrorReportService,
    private profileService: ProfileFrontendService,
    private companyService: CompanyFrontendService,
    private authService: AuthFrontendService,
    private forecastService: ForecastFrontendService,
    private diagServ: GlobalDialogService
  ) { }

  handleError(error: Error) {
    console.error('Error from global error handler', error);

    if (!this.authService.loggedIn) { return; }

    if ((<any> error).rejection?.name === 'TimeoutError' || (<any> error).rejection?.name === 'HttpErrorResponse') { return; }

    const openDialogs = this.diagServ.getOpenDialogs();

    let user = this.profileService.profile?.Email || 'Unknown user';
    const activeUsersInFC = this.forecastService.activeUsers;
    if (activeUsersInFC?.length > 1) {
      const additionalUsers = activeUsersInFC.filter(user => user !== this.profileService.profile?.Email);
      user += ` with ${additionalUsers.length} other user${additionalUsers.length > 1 ? 's' : ''}`;
    }

    const issue = <GithubIssue.FrontendErrorReportDTO> {
      Type: 'Error',
      Subject: error.message,
      User: user,
      CompanyId: this.companyId,
      ForecastId: this.activeForecastId,
      Stacktrace: error.stack,
      Location: {
        URL: window.location.pathname,
        Dialog: openDialogs.join(', ')
      }
    };

    this.service.postFrontendIssue(issue);
  }

  public postErrorToBackend(issue: GithubIssue.FrontendErrorReportDTO) {
    this.service.postFrontendIssue(issue);
  }
}
