import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { take } from 'rxjs/operators';
import { DialogV2BaseService } from '../base/dialogs.V2.base-service';
import { CreateCompanySupportDialogComponent, CreateCompanySupportDialogData } from './create-company/create-company.support.dialog';
import { SupportDialogComponent, SupportDialogOptions } from './main-support/main-support.dialog';


@Injectable({ providedIn: 'root' })
export class SupportDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openSupportDialog(data: SupportDialogOptions) {
    const config: MatDialogConfig = { width: '750px', position: { top: '6%' } };
    const cfg = this.getConfig(SupportDialogComponent.Id, data, config);
    const dialogRef = this.dialog.open<SupportDialogComponent, any, any>(SupportDialogComponent, cfg);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateCompany(data: CreateCompanySupportDialogData) {
    const config: MatDialogConfig = { width: '550px', position: { top: '6%' } };
    const cfg = this.getConfig(CreateCompanySupportDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, boolean>(CreateCompanySupportDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }


}
