<div class="debug-box-contrainer">
  <div>Ram: {{ usedRam }}</div>
  <ng-container *ngIf="count > 0">
    <hr>
    <div>Count: {{ count }}</div>
    <ng-containe *ngIf="!!entries?.length">
      <div *ngFor="let entry of entries">{{ entry.name }}: {{ entry.ms }} ms</div>
    </ng-containe>
  </ng-container>
</div>
