<div class="scenario-manage-tab"
     *ngIf="!isLoading">
  <indicio-modal-option-row optionTitle="Weight schema"
                            optionSubtitle="How to combine the results from each model"
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [value]="weightMeasure.Measure"
                  (selectionChange)="onWheightMeasure($event.value)"
                  required>
        <mat-option [value]="mode.Value"
                    [title]="mode.Display"
                    *ngFor="let mode of accuracyMeasurementOpts">{{ mode.Value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Method"
                            optionSubtitle="Method used when weighting the results"
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [value]="weightMeasure.Method"
                  (selectionChange)="onWheightMeasureMethod($event.value)"
                  required>
        <mat-option [value]="method.Value"
                    [title]="method.Description"
                    *ngFor="let method of accuracyMeasureMethods">{{ method.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Exponent"
                            optionSubtitle="Weight the accurate results exponentially higher"
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [value]="scenario.Exponent"
                  required>
        <mat-option [value]="exponent"
                    [title]="exponent"
                    *ngFor="let exponent of exponentChoices">{{ exponent }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Selected models"
                            optionSubtitle="Limit the number of models in the weighting"
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="scenario.Models"
                  required>
        <mat-option [value]="model"
                    [title]="(model === 0 ? 'All' : model)"
                    *ngFor="let model of modelChoices">
          {{ (model === 0 ? 'All' : 'Top ' + model + ' models') }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Exclude models"
                            optionSubtitle="Select models which should not be used"
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field style="margin-top: 15px;">
      <mat-select multiple
                  [(value)]="scenario.ModelsToExclude">
        <mat-option [value]="model.Model.Value"
                    *ngFor="let model of displayModels">{{ model.Model.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
  <br>
</div>
<ng-template #loadingTemplate>
  <div><i class="ion-load-c spinner"></i></div>
</ng-template>
