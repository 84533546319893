import { RQueueEntryDTO, RQueueInfoDTO } from './dtos/r-queue.dtos';
import { QueuedModel } from './r-queue.models';

export namespace QueueActions {
  export class QueueInfo {
    public static readonly type = '[REQUEST] New info';
    constructor(public message: RQueueInfoDTO, public complete: boolean = false, public isSanity: boolean = false) { }
  }

  export class Finished {
    public static readonly type = '[REQUEST] Finished';
    constructor(public message: QueuedModel) { }
  }

  export class Queued {
    public static readonly type = '[REQUEST] Queued';
    constructor(public message: QueuedModel) { }
  }

  export class NewEntries {
    public static readonly type = '[REQUEST] New entry';
    constructor(public forecastVersionId: string, public arr: RQueueEntryDTO[]) { }
  }

  export class RemoveEntries {
    public static readonly type = '[REQUEST] Remove entry';
    constructor(public forecastVersionId: string, public rRequestIds: string[]) { }
  }

  export class UpdateEntries {
    public static readonly type = '[REQUEST] Update entry';
    constructor(public forecastVersionId: string, public arr: RQueueEntryDTO[]) { }
  }

  export class FailedEntry {
    public static readonly type = '[REQUEST] Failed entry';
    constructor(public forecastVersionId: string, public requestId: string) { }
  }
}

