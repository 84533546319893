<h1 mat-dialog-title>Title</h1>
<div mat-dialog-content>
  Content
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
  <button mat-flat-button
          (click)="save()">
    Create
  </button>
</div>
