<indicio-dialog class="main-support-container">
  <h1 mat-dialog-title
      class="title-row">Support
    <div class="crop-string subtitle"
         [title]="activeCompany.Name"
         *ngIf="activeCompany">
      Company: {{ activeCompany.Name }}
    </div>

    <div class="company-select"
         *ngIf="activeCompanyId">
      <ng-container *ngTemplateOutlet="companySelectTemplate"></ng-container>
    </div>
  </h1>


  <div mat-dialog-content>

    <div *ngIf="!activeCompanyId; else companyEditTemplate"
         class="start">
      <p>Choose a company to manage</p>
      <ng-container *ngTemplateOutlet="companySelectTemplate"></ng-container>
      <p style="text-align: center;">
        Or
        <br>
        <button color="primary"
                (click)="createCompany()"
                mat-button>create a new one</button>
      </p>
    </div>
  </div>

  <div mat-dialog-actions>
    <div *ngIf="!activeCompanyId"></div>
    <button mat-button
            (click)="onNoClick()">{{ !activeCompanyId ? 'Close' : 'Cancel' }}</button>
    <button mat-button
            *ngIf="activeCompanyId"
            [disabled]="!savable"
            (click)="save()">Save</button>
  </div>
</indicio-dialog>

<ng-template #companySelectTemplate>

  <indicio-searchable-dropdown *ngIf="managedCompanies.length; else noCompaniesTemplate"
                               [values]="managedCompanies | orderBy: 'Display'"
                               optionDisplay="Display"
                               [initializeWithFirstValue]="false"
                               [selectedValue]="selectedCompany"
                               (valueChangeEvent)="setActiveCompany($event)">
  </indicio-searchable-dropdown>

  <ng-template #noCompaniesTemplate>
    You aren't set to manage any companies yet.
  </ng-template>

</ng-template>

<ng-template #companyEditTemplate>
  <mat-tab-group animationDuration="0ms"
                 color="primary"
                 [selectedIndex]="viewIndex"
                 (selectedIndexChange)="changeView($event)"
                 [disablePagination]="true">
    <mat-tab label="General">
      <ng-container *ngIf="viewIndex === viewTypes.general"
                    [ngTemplateOutlet]="generalTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Users">
      <ng-container *ngIf="viewIndex === viewTypes.users"
                    [ngTemplateOutlet]="usersTemplate"></ng-container>
    </mat-tab>

    <mat-tab label="Audit">
      <ng-container *ngIf="viewIndex === viewTypes.audit"
                    [ngTemplateOutlet]="auditTemplate"></ng-container>
    </mat-tab>

  </mat-tab-group>
</ng-template>

<ng-template #generalTemplate>
  <indicio-support-dialog-general-tab [company]="activeCompany"
                                      (checkSavedEvent)="checkNeedSave()"
                                      (newCountryEvent)="setNewCountry($event)"
                                      (maxUsersEvent)="setMaxUsers($event)">
  </indicio-support-dialog-general-tab>
</ng-template>

<ng-template #usersTemplate>
  <indicio-support-dialog-users-tab [company]="activeCompany"
                                    (roleChangedEvent)="checkNeedSave()">
  </indicio-support-dialog-users-tab>
</ng-template>

<ng-template #auditTemplate>
  <indicio-support-dialog-audit-tab [company]="activeCompany"></indicio-support-dialog-audit-tab>
</ng-template>
