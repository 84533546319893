import { ModalAction } from '../modal.service';
import { ToSModalOpts } from './tos-modal.options';

export class OpenToSModal implements ModalAction {
  public static readonly type = '[MODAL] Open ToS';
  data: ToSModalOpts;
  ao?: boolean = true;
  constructor(
    tosId: string = null
  ) {
    this.data = { tosId };
  }
}
