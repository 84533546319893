
export class TaskModalOpts {
  taskId: string = null;
}

export class OpenTaskModalComponent {
  public static readonly type = '[MODAL] Open create/edit task modal';
  data: TaskModalOpts;
  constructor(
    public taskId?: string
  ) { this.data = { taskId }; }
}
