import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { IndicioHttpContext } from '@core/services/http/indicio-http-context';
import { ForecastDTO } from '../forecast/dtos/forecast/forecast-dto';
import { ForecastMapper } from '../forecast/mapper/forecast-mapper';
import { ForecastModel } from '../forecast/models/forecast.model';
import { ForecastTemplateMapper } from './forecast-template.mapper';
import { AdminUpdateForecastTemplateDTO, CreateForecastTemplateDTO, ForecastTemplate, ForecastTemplateDTO, ForecastTemplateSearchDTO, ForecastTemplateTagDTO, TemplateAuthorDTO, UpdateForecastTemplateDTO, UseForecastTemplateDTO } from './forecast-template.model';


@Injectable({
  providedIn: 'root'
})
export class ForecastTemplateBackendService {

  constructor(
    private http: HttpService,
    private mapper: ForecastTemplateMapper,
    private forecastMapper: ForecastMapper
  ) { }

  public getAll(skip?: number, take?: number): Promise<ForecastTemplate[]> {
    const toSkip = skip || 0;
    const toTake = take || 50;
    return this.http.get<ForecastTemplateDTO[]>(`forecast-templates?skip=${toSkip}&take=${toTake}`)
      .then(resp => {
        return resp.body.map(dto => this.mapper.map(dto));
      });
  }

  public get(templateId: string): Promise<ForecastTemplate> {
    return this.http.get<ForecastTemplateDTO>(`forecast-templates/${templateId}`)
      .then(resp => {
        return this.mapper.map(resp.body);
      });
  }

  public getTopUsed(take?: number): Promise<ForecastTemplate[]> {
    return this.http.get<ForecastTemplateDTO[]>(`forecast-templates/get-top?take=${take}`)
      .then(resp => {
        return resp.body.map(dto => this.mapper.map(dto));
      });
  }

  public search(dto: ForecastTemplateSearchDTO): Promise<ForecastTemplate[]> {
    return this.http.post<ForecastTemplateDTO[]>('forecast-templates/search', dto)
      .then(resp => {
        return resp.body.map(dto => this.mapper.map(dto));
      });
  }

  public create(dto: CreateForecastTemplateDTO) {
    return this.http.post<ForecastTemplateDTO>('forecast-templates', dto)
      .then(resp => {
        return this.mapper.map(resp.body);
      });
  }

  public delete(templateId: string): Promise<any> {
    return this.http.delete(`forecast-templates/${templateId}`);
  }

  public async update(dto: UpdateForecastTemplateDTO): Promise<ForecastTemplate> {
    const resp = await this.http.put<ForecastTemplateDTO>(`forecast-templates/${dto.ForecastTemplateId}`, dto);
    return this.mapper.map(resp.body);
  }

  public use(templateId: string, dto: UseForecastTemplateDTO): Promise<ForecastModel> {
    return this.http.post<ForecastDTO>(`forecast-templates/${templateId}/use`, dto, 'backend', IndicioHttpContext.GetContext({ Timeout: 300000 }))
      .then(resp => this.forecastMapper.map(resp.body));
  }

  public getVariablePreview(templateId: string, variableId: string): Promise<ForecastDTO> {
    return this.http.get<ForecastDTO>(`forecast-templates/${templateId}/variable${variableId}/preview`)
      .then(resp => resp.body);
  }

  public getTags() {
    return this.http.get<ForecastTemplateTagDTO[]>('forecast-templates/tags')
      .then(resp => resp.body);
  }

  public createTag(tag: ForecastTemplateTagDTO) {
    return this.http.post<ForecastTemplateTagDTO>('forecast-templates/tags', tag)
      .then(resp => resp.body);
  }

  public getAuthors() {
    return this.http.get<TemplateAuthorDTO[]>('admin/forecast-templates/get-authors')
      .then(resp => resp.body);
  }

  public adminUpdate(dto: AdminUpdateForecastTemplateDTO) {
    return this.http.post(`admin/forecast-templates/${dto.ForecastTemplateId}`, dto);
  }
}



