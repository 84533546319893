import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type TextInputType = 'text-area' | 'text-input';
export interface TextInputDialogData {
  Title: string;
  Text?: string;
  TextColor?: string;
  Label: string;
  Value?: string;
  CancelText?: string;
  ConfirmText?: string;
  placeholder?: string;
  type?: TextInputType;
}

@Component({
  selector: 'indicio-text-input-dialog',
  templateUrl: 'text-input.dialog.html',
})
export class TextInputDialogComponent {
  public static Id: string = 'TextInputDialogComponent';

  public answer: string;
  public type: TextInputType = 'text-input';

  constructor(
    public dialogRef: MatDialogRef<TextInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextInputDialogData) {
    if (!data.CancelText) { data.CancelText = 'Cancel'; }
    if (!data.ConfirmText) { data.ConfirmText = 'Save'; }
    if (!!data.type) { this.type = data.type; }
    this.answer = data.Value || '';
  }

  public setAnswer(inp) {
    this.answer = inp;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(this.answer);
  }
}
