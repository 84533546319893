import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatSelectChange } from '@angular/material/select';
import { RemoteCalls } from '@core/constants/remote-calls.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CreateForecastDTO } from '@core/store/forecast/dtos/forecast/create-forecast-dto';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { SourceVariableViewDTO } from '@core/store/source-variable/source-variable.model';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { SourceType } from '@modules/lang/language-files/source-types';
import { Periodicity } from '@modules/lang/types/periodicity';
import { DateUtils } from '@shared/utils/date.utils';

export interface CreateMultipleForecastsDialogComponentData {
  SourceVariables: SourceVariableViewDTO[];
}

class VariableOptions {
  Name: string;
  Periodicity: PeriodicityType;
  SourceType: SourceType;
  AllowedPeriodicities: Periodicity[];
  SVar: SourceVariableViewDTO;
  NewPeriodicity: PeriodicityType;
  // frontend helpers
  step: RemoteCalls.Steps;
  stepMessage?: string;
}

@Component({
  selector: 'indicio-edit-forecast-template-dialog',
  templateUrl: 'create-multiple-forecasts.dialog.html',
  styleUrls: ['create-multiple-forecasts.dialog.less']
})
export class CreateMultipleForecastsDialogComponent extends DialogV2BaseDialog<CreateMultipleForecastsDialogComponent> {
  public static Id: string = 'CreateMultipleForecastsDialogComponent';

  public canCreate: boolean = false;
  public projects: ProjectModel[];
  public project: ProjectModel;
  public horizon: number = 3;
  public horizonOpts = Array.from(Array(15), (_, i) => i + 1);
  public variables: VariableOptions[];
  public validPeriodicities: Periodicity[];
  public periodicityForAll: PeriodicityType = null;
  public forecastsCreated: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CreateMultipleForecastsDialogComponentData,
    private env: EnvironmentService,
    private clientService: ClientFrontendService,
    private profileService: ProfileFrontendService,
    private projectService: ProjectFrontendService,
    private forecastService: ForecastFrontendService,
    private companyService: CompanyFrontendService,
    private statusService: StatusService,
    dialogRef: MatDialogRef<CreateMultipleForecastsDialogComponent>) {
    super(dialogRef);
    this.initialize();
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public createProject() {
    this.projectService.openCreateProjectDialog(this.profileService.profile.Email, this.profileService.profile.ClientId, this.clientService.client.ActiveCompanyId)
      .then(newProj => {
        if (!newProj) { return; }
        this.projects.push(newProj);
        this.onProjectChange({ value: newProj });
        this.statusService.setMessage(`Project ${newProj.Name} created successfully`, 'Success', true);
      });
  }

  public onProjectChange($event) {
    this.project = $event.value;
    this.canCreate = !!this.project;
  }

  public onPeriodicityForAll(event: MatSelectChange) {
    this.periodicityForAll = event.value;


    this.variables.forEach(v => {
      if (this.periodicityForAll === <any> 'xXx') { return v.NewPeriodicity = v.AllowedPeriodicities.find(x => x.isLongerOrSame(v.Periodicity)).Value; }
      if (!v.AllowedPeriodicities.some(p => p.Value === this.periodicityForAll)) { return; }
      v.NewPeriodicity = this.periodicityForAll;
    });
  }

  public createForecasts() {
    const dtos = this.variables.map(v => (Object.assign(new CreateForecastDTO, <CreateForecastDTO> {
      Horizon: this.horizon,
      Name: v.Name,
      Periodicity: v.NewPeriodicity,
      SourceVariableId: v.SVar.SourceVariableId,
      ProjectId: this.project.ProjectId,
      StepChosen: null,
      StartDate: DateUtils.convertToBackendDate(DateUtils.newDate())
    })));

    this.processDTOs(dtos, dtos.length);
  }

  private processDTOs(dtos: CreateForecastDTO[], count: number) {
    const dto = dtos.shift();
    if (!dto) {
      this.dialogRef.close(true);
      return this.statusService.setMessage(`${count} forecasts created successfully`, 'Success');
    }
    const entry = this.variables.find(v => v.SVar.SourceVariableId === dto.SourceVariableId);
    entry.step = 'processing';

    this.forecastService.createForecast(this.clientService.activeCompanyId, dto)
      .then(() => {
        dto.unsaved = false;
        entry.step = 'complete';
        this.forecastsCreated++;
      })
      .catch((e) => {
        dto.unsaved = true;
        entry.step = 'error';
        entry.stepMessage = e.message;
      })
      .finally(() => {
        this.processDTOs(dtos, count);
      });
  }

  protected initialize() {
    this.projects = this.projectService.projects.filter(proj => proj.hasPermission('CAN_CREATE_FORECAST'));
    const allowedPeriodicities = this.env.getValidPeriodicites(this.companyService.horizonInfos);
    allowedPeriodicities.sort((a, b) => a.getInt() - b.getInt());
    this.variables = this.data.SourceVariables.map(v => ({
      Name: v.Name,
      Periodicity: v.Periodicity,
      NewPeriodicity: allowedPeriodicities.find(x => x.isLongerOrSame(v.Periodicity)).Value,
      SVar: v,
      SourceType: v.sourceType,
      AllowedPeriodicities: allowedPeriodicities.filter(p => p.isLongerOrSame(v.Periodicity)),
      step: 'queued'
    }));
    this.validPeriodicities = allowedPeriodicities;
    const originalPeriodicity = <Periodicity> { Value: (<any> 'xXx'), Display: 'Use source periodicity' };
    this.validPeriodicities.push(originalPeriodicity);
    this.periodicityForAll = originalPeriodicity.Value;
  }
}
