<h1 mat-dialog-title>{{ data.Title }}</h1>
<div mat-dialog-content>
  <div>{{ data.Ingress }}</div>
  <br>
  <span *ngIf="!isList; else listTemplate">
    {{ data.Content }}
  </span>
  <ng-template #listTemplate>
    <ul>
      <li *ngFor="let item of data.Content">
        {{ item }}
      </li>
    </ul>
  </ng-template>

</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-flat-button
          (click)="save()">
    Ok
  </button>
</div>
