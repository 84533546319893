import { Def } from '@core/decorators/def';

export class CopyForecastDTO {
  @Def() Name: string;
  @Def() ProjectId: string;
  @Def() ForecastId: string;
  @Def() ForecastVersionId: string;
  @Def() IncludeIndicators: boolean = true;
  @Def() IncludeAssessments: boolean = true;
  @Def() IncludeScenarios: boolean = true;
  @Def() IncludeEvents: boolean = true;
  @Def() Periodicity: string;
}
