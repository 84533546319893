import { Injectable } from '@angular/core';
import { StatusModel } from '@core/services/status/status.model';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { SystemMessage } from '../system/system-message';


type Types = 'Success' | 'Info' | 'Warning' | 'Error';
export const IndicioSystemMessageIdentifier = 'SysMsg:';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  status$: Subject<StatusModel> = new Subject<StatusModel>();
  timeout: any;

  constructor(
    private store: Store
  ) { }

  public setMessage(message: string, type: Types, modal: boolean = false, errors?: string[], sticky?) {
    const newStatus = new StatusModel();

    newStatus.message = message;
    newStatus.type = type;
    newStatus.errors = errors;

    if (modal === true) {
      newStatus.showInModal = true;
    } else {
      newStatus.showOnPage = true;
    }

    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    switch (type) {
      case 'Success':
      case 'Info':
        newStatus.backgroundColor = '#009966';
        if (!sticky) { newStatus.timeout = 3000; }
        break;

      case 'Warning':
        newStatus.backgroundColor = '#e77f35';
        if (!sticky) { newStatus.timeout = 5000; }
        break;

      case 'Error':
        newStatus.backgroundColor = '#E26464';
        newStatus.reportBug = true;
        break;

      default:
        console.error('Unknown statusErrorType');
    }
    newStatus.show = true;

    if (newStatus.timeout != null) {
      this.timeout = setTimeout(() => {
        this.removeMessage();
      }, newStatus.timeout);
    }

    this.status$.next(newStatus);
  }

  public setError(error, modal = false) {
    const { message, errors } = this.getMessage(error);

    if (error?.name === 'TimeoutError') {
      error.Message = 'The request timed out, but is still processing. Please reload the page in a bit to view the result';
      return this.setWarning(error, modal, true);
    }

    this.setMessage(message, 'Error', modal, errors);
  }

  public setWarning(error, modal = false, sticky = false) {
    const { message, errors } = this.getMessage(error);
    this.setMessage(message, 'Warning', modal, errors, sticky);
  }

  public getMessage(error) {
    let message: string;
    let errors;

    if (error === null) {
      message = 'Unknown error.';
    } else if (error.stack) {
      message = 'Javascript error, check console.';
      console.error(error);
    } else {
      if (error.status === 0) {
        message = 'Service Unavailable';
      } else {
        error = error.error || error.Message || `${error.status}: ${error.statusText}`;
        if (typeof error === 'string') {
          message = error;
        } else if (typeof error === 'object' && error.hasOwnProperty('error_description')) {
          message = error.error_description;
        } else {
          message = error.Message ? error.Message : 'Error';
          errors = [];

          if (error.ModelState && Object.keys(error.ModelState).length) {
            Object.keys(error.ModelState).forEach(x => {
              error.ModelState[x].forEach(y => errors.push(y));
            });
          }
        }
      }
    }

    // TODO: Cleanup after net6 is released
    if (error.SubMessages?.length && Array.isArray(error.SubMessages)) {
      errors = error.SubMessages;
    } else {
      if (message.indexOf('<->') !== -1) {
        const split = message.split('<->');
        message = split[0];
        const subMessages = split[1].split('|-|');
        errors = subMessages.filter(x => !x.startsWith(IndicioSystemMessageIdentifier));
        if (!errors.length) {
          errors = null;
        }
        const sysMessages = subMessages.filter(x => x.startsWith(IndicioSystemMessageIdentifier));
        if (sysMessages.length) {
          this.store.dispatch(new SystemMessage.Actions.BackendMessage(sysMessages));
        }
      }
    }

    return { message, errors };
  }

  public removeMessage() {
    const emptyStatus = new StatusModel();
    this.timeout = null;
    this.status$.next(emptyStatus);
  }
}
