<!-- Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg
          PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg version="1.1"
     id="Lager_1"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     x="0px"
     y="0px"
     viewBox="0 0 176.5 60"
     enable-background="new 0 0 176.5 60"
     xml:space="preserve"
     [style]="style"
     class="indicio-text-logo">
  <g>
    <path d="M149.6,54.6c-3,0-5.3-1.1-7-3.2s-2.5-5-2.5-8.8c0-4.8,1.3-9,3.9-12.4c2.6-3.5,6.2-5.2,10.7-5.2l2.3,0.1
		c0.7-0.4,1.6-0.6,2.5-0.6c3.4,0,5.1,3.2,5.1,9.7c1.8-0.1,3.6-0.3,5.4-0.7l1-0.3l0.4,2.5c-1.4,0.6-3.4,1.2-5.8,1.6l-1.3,0.3
		c-0.8,4.6-2.5,8.5-5,11.9C156.8,52.9,153.5,54.6,149.6,54.6z M153.1,49c1.8,0,3.5-1.2,4.9-3.5c1.5-2.3,2.5-4.8,3-7.4
		c-4.6,0-7-1.7-7-5.2c0-1.4,0.3-2.7,0.8-3.9l0.3-0.7c-0.2-0.1-0.4-0.1-0.7-0.1c-0.3,0-0.5,0-0.6,0.1c-1.1,0.7-2.1,2.3-3.1,4.9
		c-1,2.6-1.4,5.5-1.4,8.8C149.5,46.7,150.7,49,153.1,49z" />
    <g>
      <path d="M16.3,19.4c1.5,0,2.9-0.5,4.1-1.6c1-0.9,1.5-1.9,1.7-3h67.3c0,1.4,0.4,2.5,1.3,3.4
			c0.9,0.8,2.1,1.3,3.6,1.3c1.5,0,2.9-0.5,4.1-1.6c1-0.9,1.5-1.9,1.7-3h29.2c0,1.4,0.4,2.5,1.3,3.4c0.9,0.8,2.1,1.3,3.6,1.3
			c1.5,0,2.9-0.5,4.1-1.6c1.2-1.1,1.9-2.3,1.9-3.7c0-1.4-0.4-2.5-1.3-3.4c-0.9-0.9-2.1-1.3-3.6-1.3s-2.9,0.5-4.1,1.6
			c-0.8,0.7-1.2,1.6-1.5,2.5h-29.4c-0.1-1.1-0.5-2.1-1.2-2.8c-0.9-0.9-2.1-1.3-3.6-1.3s-2.9,0.5-4.1,1.6c-0.8,0.7-1.2,1.6-1.5,2.5
			H22.2c-0.1-1.1-0.5-2.1-1.2-2.8c-0.9-0.9-2.1-1.3-3.6-1.3s-2.9,0.5-4.1,1.6c-1.2,1.1-1.8,2.3-1.8,3.8c0,1.4,0.4,2.6,1.3,3.4
			C13.6,19,14.8,19.4,16.3,19.4z" />
      <path d="M20.2,48c-1.7,0.8-2.7,1.2-3,1.2c-0.4,0-0.6-0.7-0.6-2.1c0-1.4,0.6-4.3,1.8-8.7c1.2-4.4,1.8-7.6,1.8-9.4
			c0-1.8-0.3-3.1-0.8-3.7c-5.2,0-8.9,0.3-11.2,1L8,27.1l0.6,0.8c1.1,1.6,1.7,3.3,1.7,5.1c0,1.2-0.5,3.9-1.6,7.9
			C7.6,45,7,47.8,7,49.4c0,1.6,0.4,2.8,1.3,3.8c0.9,0.9,2.1,1.4,3.6,1.4c1.5,0,3.2-0.4,4.9-1.3c1.8-0.9,3-1.6,3.6-2.1
			c0.7-0.5,1.2-1,1.6-1.3L21,47.6L20.2,48z" />
      <path d="M51.6,48c-1.6,0.8-2.5,1.2-2.7,1.2c-0.3,0-0.5-0.7-0.5-2.1c0-1.4,0.5-4,1.5-8c1-4,1.5-6.8,1.5-8.4
			c0-1.6-0.5-3-1.4-4.1C49,25.5,47.7,25,46.2,25c-2.6,0-5.8,1.5-9.6,4.4c0-2-0.3-3.3-0.9-4c-5.2,0-8.9,0.3-11.3,1l-0.1,0.8l0.6,0.8
			c1.2,1.6,1.8,3.1,1.8,4.6c0,0.8-0.6,4.1-1.9,9.8C23.7,48.1,23,52,23,54h1.2c4,0,6.7-0.3,8.2-0.9c0-2.8,0.8-7.6,2.3-14.5
			c0.7-3.2,1.2-5.1,1.3-5.9c1.8-1.1,3.4-1.6,4.6-1.6c0.7,0,1.1,0.6,1.1,1.8c0,1.2-0.5,3.8-1.5,7.9c-1,4.1-1.5,6.9-1.5,8.4
			c0,3.6,1.6,5.4,4.8,5.4c1.4,0,3-0.4,4.7-1.3c1.8-0.9,3-1.6,3.6-2.1c0.6-0.5,1.2-1,1.6-1.3l-1.2-2.3L51.6,48z" />
      <path d="M98.2,48c-1.7,0.8-2.7,1.2-3,1.2c-0.4,0-0.6-0.7-0.6-2.1c0-1.4,0.6-4.3,1.8-8.7s1.8-7.6,1.8-9.4
			c0-1.8-0.3-3.1-0.8-3.7c-5.2,0-8.9,0.3-11.2,1L86,27.1l0.6,0.8c1.1,1.6,1.7,3.3,1.7,5.1c0,1.2-0.5,3.9-1.6,7.9
			C85.6,45,85,47.8,85,49.4c0,1.6,0.4,2.8,1.3,3.8c0.9,0.9,2.1,1.4,3.6,1.4c1.5,0,3.2-0.4,5-1.3c1.8-0.9,3-1.6,3.6-2.1
			c0.7-0.5,1.2-1,1.6-1.3L99,47.6L98.2,48z" />
      <path d="M119.4,36.1c1.4,0.5,2.7,0.7,3.8,0.7c1.2-1.4,1.8-3,1.8-5c0-1.9-0.9-3.6-2.6-4.9c-1.7-1.3-4-2-6.9-2
			c-4.5,0-8.1,1.6-11,4.8c-2.8,3.2-4.3,7.4-4.3,12.6c0,3.7,0.8,6.7,2.5,8.9c1.7,2.2,4.2,3.3,7.6,3.3c1.4,0,2.8-0.2,4.2-0.7
			c1.4-0.4,2.6-0.9,3.5-1.4c0.9-0.5,1.9-1.2,2.9-2c1-0.8,1.7-1.4,2.1-1.9l-1.5-2l-1,0.8c-0.7,0.5-1.6,1-2.8,1.5
			c-1.2,0.5-2.3,0.7-3.3,0.7c-3.2,0-4.8-2.6-4.8-7.8c0-3.3,0.5-6.4,1.6-9.3c1-2.9,2.4-4.3,4.2-4.3c0.7,0,1.2,0.1,1.6,0.4l-1,5.7
			C116.9,35,118,35.6,119.4,36.1z" />
      <path d="M138.1,48c-1.7,0.8-2.7,1.2-3,1.2c-0.4,0-0.6-0.7-0.6-2.1c0-1.4,0.6-4.3,1.8-8.7s1.8-7.6,1.8-9.4
			c0-1.8-0.3-3.1-0.8-3.7c-5.2,0-8.9,0.3-11.2,1l-0.1,0.8l0.6,0.8c1.1,1.6,1.7,3.3,1.7,5.1c0,1.2-0.5,3.9-1.6,7.9
			c-1.1,4.1-1.6,6.9-1.6,8.5c0,1.6,0.4,2.8,1.3,3.8c0.9,0.9,2.1,1.4,3.6,1.4c1.5,0,3.2-0.4,5-1.3c1.8-0.9,3-1.6,3.6-2.1
			c0.7-0.5,1.2-1,1.6-1.3l-1.1-2.3L138.1,48z" />
      <path d="M75.5,11.6h9.6c0.1-1.2,0.2-2.2,0.2-3c0-1-0.2-1.8-0.5-2.5c-3.1,0-6.3,0.6-9.6,1.7l0.3,2.7
			C75.5,10.8,75.5,11.2,75.5,11.6z" />
      <path d="M82.8,48c-1.4,0.8-2.2,1.2-2.5,1.2c-0.5,0-0.7-0.5-0.7-1.6c0-3.5,1-10.1,2.9-19.8c0.9-4.4,1.5-8,2-11.1
			h-9.3c-0.3,3-0.9,6.4-1.6,10.3c-2.1-1.4-4.7-2.1-7.7-2.1c-3.1,0-5.8,1.6-8.2,4.9c-2.4,3.3-3.6,7.8-3.6,13.5c0,3.8,0.7,6.6,2.2,8.5
			c1.5,1.8,3.3,2.7,5.3,2.7c1.2,0,2.6-0.4,4.2-1.1c1.6-0.7,3.1-1.8,4.4-3.1c0.4,2.8,2.1,4.2,4.9,4.2c2.8,0,6-1.6,9.7-4.8l-1.2-2.3
			L82.8,48z M70.1,47.3c-1.4,1.2-2.8,1.8-4.2,1.8c-0.8,0-1.4-0.4-1.9-1.2c-0.4-0.8-0.6-2.7-0.6-5.6c0-2.9,0.6-5.9,1.7-8.9
			c1.1-3,2.4-4.5,3.9-4.5c1.2,0,2.6,0.4,4,1.2C71.2,39.8,70.2,45.6,70.1,47.3z" />
    </g>
  </g>
</svg>
