import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CreateCompanyDTO, INewUser } from '@core/store/company/dtos/create-company-dto';
import { DisplayValue } from '@modules/lang/types/display-value';
import * as EmailValidator from 'email-validator';

const COMPANYADMIN = new DisplayValue('companyadmin', 'Admin');
const COMPANYUSER = new DisplayValue('companyuser', 'User');

@Component({
  selector: 'indicio-create-company-users-tab',
  templateUrl: './create-company-tab.users.component.html',
  styleUrls: ['./create-company-tab.users.component.less'],
})
export class CreateCompanyTabUsersComponent implements OnChanges {
  @ViewChild('content') content: ElementRef;

  @Input() company: CreateCompanyDTO;
  public additionalUsers: INewUser[] = [];
  public userList: string;

  public get validUsers(): INewUser[] { return this.additionalUsers.filter(x => x.valid); }
  public get invalidUsers(): INewUser[] { return this.additionalUsers.filter(x => !x.valid); }

  public roles = [COMPANYADMIN, COMPANYUSER];

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.company) { return; }
    this.userList = this.company.Users.map(x => x.Email).join('\n');
    this.parseUserList();
  }

  public handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const text = event.clipboardData.getData('text');

    const el = this.content.nativeElement;
    const [start, end] = [el.selectionStart, el.selectionEnd];
    const currentContent = this.userList;
    const newContent = [currentContent.slice(0, start), text, currentContent.slice(end)].join('');

    this.userList = newContent;
    this.parseUserList();
  }

  public parseUserList() {
    // Get the users from the text area, separated by new line, commas or semicolons
    const userList = [...new Set(this.userList.split(/[\n,;]/).map(x => x.trim()))].filter(x => x);

    userList.forEach((user, i) => {
      const newUser: INewUser = {
        Email: user,
        CompanyRole: i === 0 ? COMPANYADMIN.Value : COMPANYUSER.Value,
        valid: EmailValidator.validate(user)
      };
      if (this.additionalUsers.find(x => x.Email === newUser.Email)) { return; }
      this.additionalUsers.push(newUser);
    });

    this.additionalUsers = this.additionalUsers.filter(x => userList.includes(x.Email));
  }
}
