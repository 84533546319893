import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ForecastTemplate } from '@core/store/forecast-template/forecast-template.model';
import { ProjectModel } from '@core/store/project/project.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

@Component({
  selector: 'indicio-forecast-template-card',
  templateUrl: './forecast-template-card.component.html',
  styleUrls: ['./forecast-template-card.component.less']
})
export class ForecastTemplateComponent {

  @Input() template: ForecastTemplate;
  @Input() dark: boolean;
  @Input() project?: ProjectModel;

  @Output() useEvent = new EventEmitter<string>();
  @Output() infoEvent = new EventEmitter<ForecastTemplate>();

  public get indicators() { return this.template.Indicators; }

  constructor(private dialogService: DialogService) { }

  public viewInfo() {
    const ref = this.dialogService.openForecastTemplateInfoDialog({ template: this.template, project: this.project });
    ref.subscribe(ans => {
      if (!ans) { return; }
      this.useEvent.emit(this.template.ForecastTemplateId);
    });
  }

  public useTemplate() {
    this.useEvent.emit(this.template.ForecastTemplateId);
  }
}
