import { Injectable } from '@angular/core';
import { IMemberModel } from '@core/interfaces/if-member';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { CompanyModel } from '@core/store/company/company.model';
import { CompanyDTO, CompanyMetaDTO } from '@core/store/company/dtos/company-dto';
import { CreateEmployeeDTO } from '@core/store/company/dtos/create-employee-dto';
import { EmployeeDTO } from '@core/store/company/dtos/employee-dto';
import { AdminUpdateCompanyDTO, SupportUpdateCompanyDTO, UpdateCompanyDTO } from '@core/store/company/dtos/update-company-dto';
import { EmployeeModel } from '@core/store/company/employee.model';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable({
  providedIn: 'root'
})
export class CompanyMapper {

  constructor(
    private envService: EnvironmentService,
  ) {
  }

  public mapSupport(dto: CompanyDTO) {
    let model = Object.faMapTo(new CompanyModel, dto);
    model = this.mapMeta(dto.Meta, model);
    model.Employees = dto.Employees.map(employee => this.mapEmployee(employee));
    model.fetched = true;
    return model;
  }

  public map(dto: CompanyDTO, target?: CompanyModel): CompanyModel {
    let model = Object.faMapTo(target || new CompanyModel(), dto);
    model = this.mapMeta(dto.Meta, model);
    model.Employees = dto.Employees.map(employee => this.mapEmployee(employee));
    model.fetched = true;
    return model;
  }

  public mapMeta(dto: CompanyMetaDTO, target?: CompanyModel): CompanyModel {
    const model = Object.faMapTo(target || new CompanyModel(), dto);
    model.Country = this.envService.getCountry(dto.CountryCode);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.MacrobondEnabled = dto.MBE;
    model.MaxHorizons = dto.MaxHorizons;
    model.fetched = false;
    model.TrialExpirationDate = DateUtils.newNullableDate(dto.TrialExpirationDate);
    return model;
  }

  public mapEmployee(dto: EmployeeDTO): EmployeeModel {
    const model = Object.faMapTo(new EmployeeModel(), dto);
    if (dto.Joined) {
      model.Joined = DateUtils.newNullableDate(dto.Joined);
    }
    return model;
  }

  public toUpdate(model: CompanyModel) {
    const dto = Object.faMapTo(new UpdateCompanyDTO(), model);
    dto.Employees = model.Employees.map(e => this.toEmployeeDto(e));
    return dto;
  }

  public toAdminUpdate(model: CompanyModel) {
    const dto = Object.faMapTo(new AdminUpdateCompanyDTO(), model);
    dto.MaxRollingWindowTime = +dto.MaxRollingWindowTime;
    dto.Employees = model.Employees.map(e => this.toEmployeeDto(e));
    dto.TrialExpirationDate = DateUtils.convertToBackendDate(model.TrialExpirationDate, true);
    dto.MacroBondEnabled = model.MacrobondEnabled;
    dto.MaxHorizons = model.MaxHorizons;
    return dto;
  }

  public toSupportUpdate(model: CompanyModel) {
    const dto = Object.faMapTo(new SupportUpdateCompanyDTO(), model);
    dto.Employees = model.Employees.map(e => this.toEmployeeDto(e));
    dto.TrialExpirationDate = DateUtils.convertToBackendDate(model.TrialExpirationDate, true);
    return dto;
  }

  public toInviteDto(employee: IMemberModel) {
    const dto = new CreateEmployeeDTO();
    dto.Email = employee.Email;
    dto.CompanyRole = employee.MemberRole.Value;
    dto.CompanyId = employee.CompanyId;
    return dto;
  }

  public toEmployeeDto(employee: EmployeeModel) {
    const dto = Object.faMapTo(new EmployeeDTO(), employee);
    if (dto.PendingSignup) {
      dto.Joined = '1900-01-01';
    }
    return dto;
  }
}
