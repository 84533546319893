import { pastForecastColors } from '@core/constants/color.constants';

export class SvgColors {
  private PastColorScheme = [...pastForecastColors];

  public ChartBorderColor: string = 'rgba(0,0,0,0.22)';
  public ChartColorScheme: string = 'light';
  public ChartBackgroundColor: string = '';
  public GraphOverlayColor: string = '';

  public AxisXLineColor: string = '';
  public AxisYLineColor: string = '';
  public AxisTextColor: string = '';

  public HistoryLineColor: string = 'var(--indicio-alg-historic-line-color, rgba(255,255,255,1))';

  updateFromExternal(styles: any) {
    if (styles.backgroundColor) { this.ChartBackgroundColor = styles.backgroundColor; }
    if (styles.colorScheme) { this.ChartColorScheme = styles.colorScheme; }
    if (styles.graphOverlay) { this.GraphOverlayColor = styles.graphOverlay; }
    if (styles.axisXline) { this.AxisXLineColor = styles.axisXline; }
    if (styles.axisYline) { this.AxisYLineColor = styles.axisYline; }
    if (styles.axisText) { this.AxisTextColor = styles.axisText; }
    if (styles.historyLine) { this.HistoryLineColor = styles.historyLine; }
    if (styles.borderColor) { this.ChartBorderColor = styles.borderColor; }
  }

  public getPastColor(i: number) {
    return this.PastColorScheme[i % pastForecastColors.length];
  }
}
