import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { FileUtils } from '@shared/utils/file.utils';
import { DebugBoxActions } from './debug-box.actions';
import { DebugBoxTypes } from './debug-box.types';

@Component({
  selector: 'indicio-debug-box',
  templateUrl: './debug-box.component.html',
  styleUrls: ['./debug-box.component.less']
})
export class DebugBoxComponent implements OnDestroy {

  public count: number = 0;
  public entries: DebugBoxTypes.Entry[] = [];
  public usedRam: string;

  private lastUsedHeap: any;
  private ramLoop: any;
  private resetTimeout: any;


  constructor(
    private actions: ActionService,
    private cd: ChangeDetectorRef
  ) {
    this.setup();
  }

  ngOnDestroy(): void {
    clearTimeout(this.ramLoop);
  }

  private setup() {
    this.actions.dispatched(DebugBoxActions.Display).subscribe((e: DebugBoxActions.Display) => {
      this.entries.push(e.entry);
      this.count++;
      if (this.entries.length > 13) { this.entries.splice(0, 1); }
      this.cd.detectChanges();
      if (this.resetTimeout) { clearTimeout(this.resetTimeout); }
      this.resetTimeout = setTimeout(() => this.reset(), 10000);
    });
    this.setupRam();
  }

  private reset() {
    this.count = 0;
    this.entries = [];
  }

  private setupRam() {
    if (window.performance && !(<any> performance).memory) {
      (<any> performance).memory = { usedJSHeapSize: 0, totalJSHeapSize: 0 };
    }

    // support of the API?
    if ((<any> performance).memory.totalJSHeapSize === 0) {
      console.warn('totalJSHeapSize === 0... performance.memory is only available in Chrome .');
    }

    this.updateRam();
  }

  private updateRam() {
    this.lastUsedHeap = (<any> performance).memory.usedJSHeapSize;
    this.usedRam = FileUtils.formatBytes(this.lastUsedHeap, 2);
    this.ramLoop = setTimeout(() => this.updateRam(), 1000 / 5);
  }

}
