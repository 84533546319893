import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataProvider } from '@core/constants/provider-definitions';
import { SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { ActionService } from '@core/services/actions/actions.service';
import { HttpService } from '@core/services/http/http.service';
import { OpenIdActions } from '@core/services/open-id/open-id.actions';
import { ClearStateAction } from '@core/store/auth/auth.actions';
import { RemotePathEntryDTO } from '@core/store/providers/dtos/remote-data-path-entry.dto';
import { MacrobondEntityInfoDTO } from '@core/store/providers/macrobond/dtos/macrobond-entityinfo.dto';
import { MacrobondFilterDTO, MacrobondFilterListDTO } from '@core/store/providers/macrobond/dtos/macrobond-filterlist.dto';
import { MacrobondLocationDTO, MacrobondTreeListDTO, MacrobondTreeListEntityDTO } from '@core/store/providers/macrobond/dtos/macrobond-treelist.dto';
import { MacrobondMapper } from '@core/store/providers/macrobond/macrobond.mapper';
import { Store } from '@ngxs/store';
import { CacheUtils } from '@shared/utils/cache.utils';
import { StorageUtils } from '@shared/utils/storage.utils';
import { ProviderService } from '../provider.service';
import { MacrobondExportDTO, MacrobondExportSeasonalDTO } from './dtos/macrobond-export.dto';

const REGION_FILTER_STORE_KEY = 'macrobond.region';
const ACTIVE_CATEGORY_STORE_KEY = 'macrobond.activeCategory';
const ACTIVE_SEARCH_QUERY_STORE_KEY = 'macrobond.activeSearchQuery';
const MAJOR_COUNTRIES = 'macrobond://macrobondsearchfilter/macrobond/searchfilters/Major%20countries%20%26%20regions.mbsf';

@Injectable({
  providedIn: 'root'
})
export class MacrobondService {

  public regionFilter: string;
  public DISCONNECT_URL = 'https://apiauth.macrobondfinancial.com/mbauth/consents/granted';

  private LOGIN_VERIFY_CUTOFF_MS = 1000 * 60 * 60 * 12;

  // State stuff below
  private initialized = false;
  private loginVerifiedAt: number = 0;
  private filterCache = new CacheUtils.LruCache<MacrobondFilterListDTO[]>();
  private treeCache = new CacheUtils.LruCache<RemotePathEntryDTO[]>();
  private treeListCache = new CacheUtils.LruCache<MacrobondTreeListDTO>();
  private entityInfoCache = new CacheUtils.LruCache<MacrobondEntityInfoDTO>(10);
  // Public part
  public activeLeaf: string;
  public activeCategory: RemotePathEntryDTO = null;
  public selectedCategory: RemotePathEntryDTO = null;
  public categories: RemotePathEntryDTO[] = [];
  public selectedRegion: MacrobondFilterDTO = null;
  public selectedRegionForSearch: MacrobondFilterDTO = null;
  public macrobondRegionFilters: MacrobondFilterListDTO[];
  public noRegion: MacrobondFilterDTO = { Path: null, Title: 'No region selected' };
  public activeListing: MacrobondTreeListDTO = null;
  public activeListingPath: string = null;
  public activeListingPathDisplay: string = null;
  public activeSearchQuery: string = null;
  // Export data stuff
  public storageLocations: MacrobondLocationDTO[] = [];
  public storageCategories: string[] = [];
  // End state stuff

  constructor(
    private http: HttpService,
    private mapper: MacrobondMapper,
    private store: Store,
    private actions: ActionService,
    private providerService: ProviderService
  ) {
    this._loadActiveCategory();
    this._loadRegionFilter();
    this._loadActiveSearchQuery();
    this.treeCache.setMaxEntries(5);
    this.treeListCache.setMaxEntries(5);
    this.filterCache.setMaxEntries(5);
    this.setupSubscriptions();
  }

  public setRegion(region: MacrobondFilterDTO) {
    this.selectedRegion = region;
  }

  public setCategory(category: RemotePathEntryDTO) {
    this.selectedCategory = category;
    this._saveActiveCategory(category);
  }

  public setActiveSearchQuery(query: string) {
    this.activeSearchQuery = query;
    this._saveActiveSearchQuery(query);
  }

  public getOrFetchStorageLocations() {
    if (this.storageLocations.length > 0) { return Promise.resolve(this.storageLocations); }
    return this.fetchStorageLocations();
  }

  public fetchStorageLocations() {
    return this.http.get<MacrobondLocationDTO[]>('my/remotesources/macrobond/storage-locations')
      .then(resp => {
        this.storageLocations = resp.body;
        return this.storageLocations;
      });
  }

  public getOrFetchUserCategories() {
    if (this.storageCategories.length > 0) { return Promise.resolve(this.storageCategories); }
    return this.fetchUserCategories();
  }

  public fetchUserCategories() {
    return this.http.get<string[]>('my/remotesources/macrobond/user-categories')
      .then(resp => {
        this.storageCategories = resp.body;
        return this.storageCategories;
      });
  }

  public getExistingRVarInformation(referenceId: string) {
    return this.providerService.getExistingRVarInformation({ RemoteReferenceId: referenceId, SourceType: DataProvider.macrobondapi });
  }

  public exportSeasonalData(dto: MacrobondExportSeasonalDTO) {
    return this.http.post('my/remotesources/macrobond/export-sa', dto)
      .catch(err => this.handleError(err));
  }

  public exportResult(dto: MacrobondExportDTO) {
    return this.http.post('my/remotesources/macrobond/export-result', dto)
      .catch(err => this.handleError(err));
  }

  public init() {
    if (this.initialized) { return Promise.resolve(true); }
    this.resetState();
    const mbFilterPromise = this.getFilters('Region');
    const mbNodePromise = this.getTreeNodes();

    this.selectedRegionForSearch = this.noRegion;

    return Promise.all([mbFilterPromise, mbNodePromise])
      .then(([regionFilters, treeBaseNodes]) => {
        if (regionFilters) {
          this.macrobondRegionFilters = regionFilters;
          if (!this.selectedRegion) {
            for (let i = 0; i < regionFilters.length; i++) {
              if (this.selectedRegion) break;
              const filter = regionFilters[i];
              this.setRegion(filter.Items.find(x => x.Path === this.regionFilter));
            }
            if (!this.selectedRegion) {
              this.setRegion(this.noRegion);
            }
          }
        }
        if (treeBaseNodes) {
          this.categories = treeBaseNodes;
          if (!this.selectedCategory) {
            this.setCategory(treeBaseNodes[0]);
          }
        }
        this.initialized = true;
        return true;
      });
  }

  public setRegionFilter(filter: string) {
    this.regionFilter = filter;
    if (filter) {
      this._saveRegionFilter(filter);
    } else {
      this._removeRegionFilter();
    }
  }

  public testLoginDetails(throwOnError: boolean = false, ignoreConnectDialog: boolean = false) {
    if (this.loginVerifiedAt > Date.now() - this.LOGIN_VERIFY_CUTOFF_MS) {
      return Promise.resolve(true);
    }
    return this.http.get<any>('my/remotesources/macrobond/test-login')
      .then(_ => {
        this.loginVerifiedAt = Date.now();
        return true;
      })
      .catch(err => {
        if (!ignoreConnectDialog) { this.handleError(err); }
        if (throwOnError) { throw err; }
      });
  }

  public getTreeNodes(path: string = '', parent?: RemotePathEntryDTO) {
    const key = `${path}${this.regionFilter}`;
    const cacheExists = this.treeCache.has(key);
    if (cacheExists) {
      return Promise.resolve(this.treeCache.get(key));
    }
    return this.http.post<RemotePathEntryDTO[]>('my/remotesources/macrobond/get-tree-nodes', { path, extras: path?.length ? this.regionFilter : null })
      .then(({ body }) => {
        const ans = body.map(x => this.mapper.mapCategory(x, parent, parent?.level));
        this.treeCache.put(key, ans);
        return ans;
      })
      .catch(this.handleError.bind(this));
  }

  public getTreeListing(path: string = '') {
    path = encodeURIComponent(path.split('<->').map(part => encodeURIComponent(part)).join('/'));
    const cacheExists = this.treeListCache.has(path);
    if (cacheExists) {
      return Promise.resolve(this.treeListCache.get(path));
    }
    return this.http.post<MacrobondTreeListDTO>('my/remotesources/macrobond/get-tree-listing', { path })
      .then(({ body }) => {
        const ans = this.mapper.mapTreeList(body);
        this.treeListCache.put(path, ans);
        return ans;
      })
      .catch(this.handleError.bind(this));
  }

  public getSeries(seriesId: string) {
    return this.http.post<{ Values: SimplePlotValue[], MetaData: any; }[]>('my/remotesources/macrobond/fetch-series', { path: seriesId })
      .then(({ body }) => body)
      .catch(this.handleError.bind(this));
  }

  public searchEntities(query: string, region: string) {
    return this.http.post<MacrobondTreeListEntityDTO[]>('my/remotesources/macrobond/search-entities', { path: query, extras: query?.length ? region : null })
      .then(({ body }) => body.map(x => this.mapper.mapTreeListEntry(x)))
      .catch(this.handleError.bind(this));
  }

  public getFilters(entityType: string) {
    const cacheExists = this.filterCache.has(entityType);
    if (cacheExists) {
      return Promise.resolve(this.filterCache.get(entityType));
    }
    return this.http.get<MacrobondFilterListDTO[]>(`my/remotesources/macrobond/fetch-filters/${entityType}`)
      .then(({ body }) => {
        this.filterCache.put(entityType, body);
        return body;
      })
      .catch(this.handleError.bind(this));
  }

  public getEntityInfo(seriesId: string, throwOnError: boolean = false, ignoreConnectDialog: boolean = false): Promise<MacrobondEntityInfoDTO> {
    const cacheExists = this.entityInfoCache.has(seriesId);
    if (cacheExists) {
      return Promise.resolve(this.entityInfoCache.get(seriesId));
    }
    return this.http.get<MacrobondEntityInfoDTO>(`my/remotesources/macrobond/fetch-entityinfo/${seriesId}`)
      .then(({ body }) => {
        const mapped = this.mapper.mapEntityInfo(body);
        this.entityInfoCache.put(seriesId, mapped);
        return mapped;
      })
      .catch(err => {
        if (!ignoreConnectDialog) { this.handleError(err); }
        if (throwOnError) { throw err; }
        return null;
      });
  }

  public openSetupDialog() {
    return this.store.dispatch(new OpenIdActions.OpenSetupDialog({
      ButtonText: 'Proceed',
      Title: 'Connect Macrobond account',
      Text: 'To use Macrobond, you need to connect your Macrobond account by clicking proceed and entering your Macrobond credentials.',
      Provider: DataProvider.macrobondapi
    }));
  }

  private _loadRegionFilter() {
    this.regionFilter = StorageUtils.get<string>(REGION_FILTER_STORE_KEY) || MAJOR_COUNTRIES;
  }

  private _saveRegionFilter(filter: string) {
    StorageUtils.set(REGION_FILTER_STORE_KEY, filter);
  }

  private _removeRegionFilter() {
    StorageUtils.remove(REGION_FILTER_STORE_KEY);
  }


  private _loadActiveCategory() {
    this.activeCategory = StorageUtils.get<RemotePathEntryDTO>(ACTIVE_CATEGORY_STORE_KEY);
  }


  private _saveActiveCategory(category: RemotePathEntryDTO) {
    StorageUtils.set(ACTIVE_CATEGORY_STORE_KEY, category);
  }

  private _loadActiveSearchQuery() {
    this.activeSearchQuery = StorageUtils.get<string>(ACTIVE_SEARCH_QUERY_STORE_KEY);
  }


  private _saveActiveSearchQuery(query: string) {
    if (!query) { return StorageUtils.remove(ACTIVE_SEARCH_QUERY_STORE_KEY); }
    StorageUtils.set(ACTIVE_SEARCH_QUERY_STORE_KEY, query);
  }

  public handleError(error: HttpErrorResponse) {
    if (error.status === 403) {
      return this.openSetupDialog();
    }
    throw error;
  }

  public resetState() {
    this.loginVerifiedAt = 0;
    this.filterCache.clear();
    this.treeCache.clear();
    this.treeListCache.clear();
    this.selectedCategory = null;
    this.categories = [];
    this.selectedRegion = null;
    this.selectedRegionForSearch = null;
    this.macrobondRegionFilters = [];
    this.activeListing = null;
    this.activeListingPath = null;
    this.activeListingPathDisplay = null;
    this.initialized = false;
  }

  private setupSubscriptions() {
    this.actions.dispatched(ClearStateAction).subscribe(() => {
      this.resetState();
    });
  }
}
