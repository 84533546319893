import { Injectable } from '@angular/core';
import { ServiceAction } from '@core/actions/service.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { ClientFrontendService } from '../client/client.frontend.service';
import { TagModel } from './models/tag.model';
import { TagAction } from './tags.actions';
import { TagsBackendService } from './tags.backend.service';
import { TagsState } from './tags.state';


@Injectable({
  providedIn: 'root'
})
export class TagsFrontendService {

  public selectedTag: TagModel = null;
  public get tags() { return this.store.selectSnapshot(TagsState.tags); }
  public get tags$() { return this.store.select(TagsState.tags); }

  constructor(
    private store: Store,
    private actions: ActionService,
    private clientService: ClientFrontendService,
    private backend: TagsBackendService,
  ) {
    this.actions.dispatched(ServiceAction.Reset).subscribe(() => {
      this.selectedTag = null;
    });
  }

  /**
   * Frontend functions
   */
  public searchTags(searchStr: string = '') {
    if (!searchStr || typeof searchStr !== 'string') { searchStr = ''; }
    const all = this.tags.slice();
    const filtered = all.filter(x => searchStr === '' || x.Name.toLowerCase().includes(searchStr.toLowerCase()));
    return filtered;
  }

  public getTagById(tagId: string) {
    return this.tags.find(x => x.TagId === tagId);
  }

  public currentTags(searchStr: string = '') {
    const all = this.tags.slice();
    const filtered = this.searchTags(searchStr);
    if (searchStr !== '') {
      const parents: string[] = [];
      filtered.map(x => x.getAllParentIds()).forEach(ids => {
        ids.filter(id => filtered.findIndex(x => x.TagId === id) === -1).forEach(id => parents.addUniqueId(id));
      });
      parents.forEach(pId => {
        const parent = all.find(x => x.TagId === pId);
        filtered.addOrUpdate(parent);
      });
    }
    return filtered;
  }

  public nameConflict(name: string) {
    const lower = name.toLowerCase();
    const conflict = this.tags.some(x => x.Name.toLowerCase() === lower);
    return conflict;
  }

  public filterTags(filterStr: string = '', byType: 'parents' | 'children'): TagModel[] {
    if (!filterStr || typeof filterStr !== 'string') {
      filterStr = '';
    }
    let all = this.currentTags(filterStr);
    if (this.selectedTag) {
      all = all.filter(x => x.TagId !== this.selectedTag.TagId);
      all = all.filter(x => !this.selectedTag.ChildTagIds.includes(x.TagId) && !this.selectedTag.ParentTagIds.includes(x.TagId));
      // all = all.filter(x => x.isRegion() === this.selectedTag.isRegion());
      if (byType === 'children') {
        const allParentIds = this.selectedTag.getAllParentIds();
        all = all.filter(x => !allParentIds.includes(x.TagId));
        if (this.selectedTag.isRegion()) {
          all = all.filter(x => x.isRegion());
        }
      } else {
        const allChildIds = this.selectedTag.getAllChildIds();
        all = all.filter(x => !allChildIds.includes(x.TagId));
        if (!this.selectedTag.isRegion()) {
          all = all.filter(x => !x.isRegion());
        }
      }
    }
    return all;
  }

  public tagById(id: string) { return this.tags.find(s => s.TagId === id); }

  /**
   * Backend functions
   */
  public getOrFetchTag(tagId: string) {
    const tag = this.tagById(tagId);
    if (!tag) {
      return this.fetchTag(tagId);
    } else {
      return Promise.resolve(tag);
    }
  }

  public fetchTag(tagId: string) {
    return this.backend.getTag(tagId, this.clientService.activeCompany.CompanyId)
      .then(tag => {
        this.store.dispatch(new TagAction.GetSuccess(tag));
        return tag;
      });
  }

  public createNewTag(tag: TagModel) {
    return this.backend.createTag(tag, this.clientService.activeCompany.CompanyId)
      .then(created => {
        this.store.dispatch(new TagAction.CreateSuccess(created));
        return created;
      });
  }

  public updateTag(tag: TagModel) {
    return this.backend.updateTag(tag)
      .then(updated => {
        this.store.dispatch(new TagAction.UpdateSuccess(updated));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return updated;
      });
  }

  public deleteTag(tag: TagModel) {
    return this.backend.deleteTag(tag)
      .then(() => {
        this.store.dispatch(new TagAction.DeleteSuccess(tag));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return tag.TagId;
      });
  }
}
