import { NotificationModel } from './notification.model';

export class GetNotificationsAction {
  public static readonly type = '[NOTIFICATION] Get notifications';
  constructor() { }
}

export class GetAllNotificationsSuccessAction {
  public static readonly type = '[NOTIFICATION] Get all notifications';
  constructor(public notifications: NotificationModel[]) { }
}

export class GetNotificationSuccessAction {
  public static readonly type = '[NOTIFICATION] Get notification';
  constructor(public notification: NotificationModel) { }
}

export class ToggleNotificationReadAction {
  public static readonly type = '[NOTIFICATION] Toggle notification read';
  constructor(public notification: NotificationModel) { }
}

export class MarkAllNotificationsAsRead {
  public static readonly type = '[NOTIFICATION] Mark all notifications as read';
  constructor() { }
}

export class RemoveNotificationAction {
  public static readonly type = '[NOTIFICATION] Remove notification';
  constructor(public notification: NotificationModel) { }
}

export class RemoveAllNotificationsAction {
  public static readonly type = '[NOTIFICATION] Remove all notification';
  constructor() { }
}

export class ToggleNotificationsHiddenAction {
  public static readonly type = '[NOTIFICATION] Toggle notifications hidden or visible';
  constructor(public hiddenTopics: Array<string>) { }
}
