<div class="text-center">
  <indicio-icon-logo *ngIf="showIcon"></indicio-icon-logo>
  <ng-container *ngIf="showTextIcon">
    <br>
    <br>
    <indicio-text-logo></indicio-text-logo>
  </ng-container>
  <ng-container *ngIf="showText">
    <h2 class="light"
        style="font-size: 20px;">
      The future of data-driven forecasting
    </h2>
  </ng-container>
</div>
