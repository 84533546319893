import { Injectable } from '@angular/core';

import { DataManagementSettingsModel } from '@core/store/client/data-management-settings.model';
import { ClientSettingsDTO } from '@core/store/client/dtos/client-settings.dto';
import { DataManagementSettingsDTO } from '@core/store/client/dtos/data-management-settings-dto';


@Injectable({
  providedIn: 'root'
})
export class ClientSettingsMapper {

  constructor() {
  }

  public map(dto: ClientSettingsDTO): ClientSettingsDTO {

    if (dto.DecimalCharacter === null) {
      delete dto.DecimalCharacter;
    }

    return Object.faMapTo(new ClientSettingsDTO(), dto);
  }

  public mapDTO(model: ClientSettingsDTO): ClientSettingsDTO {
    return Object.faMapTo(new ClientSettingsDTO(), model);
  }

  public mapDataManagementSettings(dto: DataManagementSettingsDTO): DataManagementSettingsModel {
    const model = Object.faMapTo(new DataManagementSettingsModel(), dto);
    if (dto.DataManagementSortSettings) {
      model.DataManagementSortSettings = dto.DataManagementSortSettings.map(setting => JSON.parse(setting));
    } else {
      model.DataManagementSortSettings = [];
    }
    return model;
  }

  public mapDataManagementSettingsDTO(model: DataManagementSettingsModel): DataManagementSettingsDTO {
    const dto = new DataManagementSettingsDTO();
    dto.DataManagementSortSettings = model.DataManagementSortSettings.map(s => JSON.stringify(s));
    return dto;
  }
}
