<h1 mat-dialog-title>Entry settings</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Select forecast result</mat-label>
    <mat-select [(value)]="newResultId">
      <mat-option [value]="result.ForecastResultId"
                  *ngFor="let result of data.entry.ForecastResults">{{ result.Name }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">Cancel</button>
  <button [disabled]="false"
          mat-flat-button
          [mat-dialog-close]="updatedSettings"
          cdkFocusInitial>
    Save
  </button>
</div>
