
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ClearStateAction } from '@core/store/auth/auth.actions';
import { ClientState } from '@core/store/client/client.state';
import { CompanyModel } from '@core/store/company/company.model';
import { DeleteFileSuccessAction, FileActions, GetUploadedFilesSuccessAction, GetUploadedFileSuccessAction, RemovedFilesInCompanyAction } from '@core/store/file/file.actions';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { CompanyActions } from '../company/company.actions';
import { RemoteFileModel } from './models/remote-file.model';

class FileStateModel {
  Files: UploadedFileModel[];
  RemoteFiles: RemoteFileModel[];
}

@State<FileStateModel>({
  name: 'files',
  defaults: {
    Files: [],
    RemoteFiles: []
  }
})
@Injectable()
export class FileState {

  @Selector()
  static uploadedFiles(state: FileStateModel) { return state.Files; }

  @Selector()
  static remoteFiles(state: FileStateModel) { return state.RemoteFiles; }

  @Selector([ClientState.activeCompany])
  static getUploadedFilesInActiveCompany(state: FileStateModel, company: CompanyModel) {
    return state.Files.filter(x => x.CompanyId === company.CompanyId);
  }

  constructor() { }

  @Action(GetUploadedFilesSuccessAction)
  getUploadedFiles(ctx: StateContext<FileStateModel>, action: GetUploadedFilesSuccessAction) {
    ctx.patchState({ Files: action.files });
  }

  @Action(GetUploadedFileSuccessAction)
  getUploadedFile(ctx: StateContext<FileStateModel>, action: GetUploadedFileSuccessAction) {
    const files = ctx.getState().Files;
    files.addOrUpdate(action.file);
    ctx.patchState({ Files: [...files] });
  }

  @Action(FileActions.GetRemoteFilesSuccess)
  getRemoteFilesSuccessAction(ctx: StateContext<FileStateModel>, action: FileActions.GetRemoteFilesSuccess) {
    ctx.patchState({ RemoteFiles: action.files });
  }

  @Action(FileActions.GetRemoteFileSuccess)
  getRemoteFileSuccessAction(ctx: StateContext<FileStateModel>, action: FileActions.GetRemoteFileSuccess) {
    const files = ctx.getState().RemoteFiles;
    files.addOrUpdate(action.file);
    ctx.patchState({ RemoteFiles: [...files] });
  }

  @Action(FileActions.DeleteRemoteFileSuccess)
  deleteRemoteFileSuccessAction(ctx: StateContext<FileStateModel>, action: FileActions.DeleteRemoteFileSuccess) {
    const files = ctx.getState().RemoteFiles;
    files.removeById(action.fileId);
    ctx.patchState({ RemoteFiles: [...files] });
  }

  @Action(DeleteFileSuccessAction)
  deleteFileSuccess(ctx: StateContext<FileStateModel>, action: DeleteFileSuccessAction) {
    const files = ctx.getState().Files;
    files.removeById(action.fileId);
    ctx.patchState({ Files: [...files] });
  }

  @Action(RemovedFilesInCompanyAction)
  removeFilesInCompany(ctx: StateContext<FileStateModel>, action: RemovedFilesInCompanyAction) {
    const files = ctx.getState().Files;
    const toRemove = files.filter(f => f.CompanyId === action.companyId).map(f => f.UploadedFileId);
    for (const id of toRemove) {
      ctx.dispatch(new DeleteFileSuccessAction(id));
    }
  }

  @Action(CompanyActions.SetActive)
  setActiveCompanyAction(ctx: StateContext<FileStateModel>, _action: CompanyActions.SetActive) {
    ctx.patchState({ Files: [] });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<FileStateModel>) {
    ctx.patchState({ Files: [], RemoteFiles: [] });
  }

}
