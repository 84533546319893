import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { GetClientSuccessAction } from '@core/store/client/client.actions';
import { ClientBackendService } from '@core/store/client/client.backend.service';
import { CompanyActions, GetCompaniesSuccessAction } from '@core/store/company/company.actions';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { MacrobondService } from '@core/store/providers/macrobond/macrobond.service';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { ClearStateAction } from '../auth/auth.actions';
import { CompanyFrontendService } from '../company/company.frontend.service';
import { ProfileActions } from '../profile/profile.actions';
import { ProfileFrontendService } from '../profile/profile.frontend.service';
import { ToSService } from '../tos/tos.service';
import { WikiService } from '../wiki/wiki.service';
import { ClientSettingsService } from './client-settings.service';
import { ClientState } from './client.state';


const DISABLE_CHAT_FOR_LICENSES = [IndicioConstants.Licenses.Macrobond];
enum SUPPORT_EMAILS {
  Macrobond = 'support@macrobond.com?subject=Indicio support'
}

@Injectable({
  providedIn: 'root'
})
export class ClientFrontendService {

  private sub: Subscription = new Subscription();

  // GETTERS
  public get activeCompany() { return this.store.selectSnapshot(ClientState.activeCompany); }
  public get activeCompanyId() { return this.store.selectSnapshot(ClientState.activeCompanyId); }
  public get client() { return this.store.selectSnapshot(ClientState.client); }
  public get isAdmin() { return this.store.selectSnapshot(ClientState.isAdmin); }
  public get userEmail() { return this.profileService.profile.Email; }
  // OBSERVABLES
  public activeCompany$ = this.store.select(ClientState.activeCompany);

  constructor(
    private store: Store,
    private backend: ClientBackendService,
    private appearanceService: AppearanceService,
    private envService: EnvironmentService,
    private companyService: CompanyFrontendService,
    private macrobondService: MacrobondService,
    private actions: ActionService,
    private profileService: ProfileFrontendService,
    private router: Router,
    private clientSettingsService: ClientSettingsService,
    private tosService: ToSService,
    private wikiService: WikiService
  ) {
    this.setupActionSubscriptions();
  }

  public sendIAmActive() {
    return this.backend.sendIAmActive();
  }

  public isEmployee(companyId: string = this.activeCompany.CompanyId) {
    return this.companyService.companies.find(x => x.CompanyId === companyId).Employees.some(x => x.ClientId === this.client.ClientId);
  }

  public setAutomaticLogout(automatic: boolean) {
    return this.backend.setAutomaticLogout(automatic);
  }

  public fetchSecuritySettings() {
    return this.backend.fetchSecuritySettings();
  }

  public getClient() {
    return this.backend.getClient()
      .then(response => {
        this.store.dispatch(new GetClientSuccessAction(response.client));
        this.store.dispatch(new GetCompaniesSuccessAction(response.companies));
        this.store.dispatch(new ProfileActions.GetProfileSuccessAction(response.profile));
        this.store.dispatch(new CompanyActions.SetActive(response.client.ActiveCompanyId));
        this.clientSettingsService.setSettings(response.clientSettings);
        this.appearanceService.setTheme(response.clientSettings.Theme, false);
        this.wikiService.getAvailablePages().catch(e => console.error(e));
        return response;
      });
  }

  private setupActionSubscriptions() {
    this.sub.add(this.actions.dispatched(CompanyActions.CheckMacrobondCredentials).subscribe((action: CompanyActions.CheckMacrobondCredentials) => {
      this.checkMacrobondProvider(action.companyId);
    }));

    this.sub.add(this.actions.dispatched(CompanyActions.SetActiveCompanySuccess).subscribe((_action: CompanyActions.SetActiveCompanySuccess) => {
      this.setVisibilityOfDrifttIcon();
    }));

    this.sub.add(this.actions.dispatched(ClearStateAction).subscribe((_action: ClearStateAction) => {
      this.toggleDriftIcon(false);
      this.toggleEmailIcon(false);
    }));
  }

  private async checkMacrobondProvider(companyId: string) {
    const company = await this.companyService.getCompany(companyId);
    if (company.LicenseType !== 'Macrobond' || this.router.url.match(/open-id/) || !this.tosService.hasAcceptedTos) { return; }
    this.macrobondService.testLoginDetails()
      .then(() => { console.log('MACRO ACCOUNT VALID'); })
      .catch(() => { console.log('MACRO ACCOUNT NOT VALID'); });
  }

  private disableDriftForCompanyLicense(license: IndicioConstants.Licenses) {
    return DISABLE_CHAT_FOR_LICENSES.includes(license);
  }

  private shouldDrifttBeDisabled() {
    return this.disableDriftForCompanyLicense(this.companyService.getCompany(this.client.ActiveCompanyId).LicenseType as IndicioConstants.Licenses);
  }

  private shouldEmailSupportBeVisible() {
    return this.getSupportEmail() !== undefined;
  }

  private getSupportEmail() {
    const license = this.companyService.getCompany(this.client.ActiveCompanyId).LicenseType;
    return SUPPORT_EMAILS[license];
  }

  private toggleDriftIcon(state: boolean) {
    try {
      switch (state) {
        case true:
          return (<any> window).drift.show();
        default:
          return (<any> window).drift.hide();
      }
    } catch (e) {
      console.error('Drift not found.');
    }
  }

  private toggleEmailIcon(state: boolean) {
    try {
      switch (state) {
        case true:
          return this.companyService.supportEmail = this.getSupportEmail();
        default:
          return this.companyService.supportEmail = null;
      }
    } catch (e) {
      console.error('Error showing mail support icon.');
    }
  }

  private setVisibilityOfDrifttIcon() {
    if (this.shouldDrifttBeDisabled()) {
      this.toggleDriftIcon(false);
      if (this.shouldEmailSupportBeVisible()) {
        this.toggleEmailIcon(true);
      }
    } else {
      this.initDrift();
      this.toggleEmailIcon(false);
      this.toggleDriftIcon(true);
    }
  }

  private initDrift() {
    if ((!!this.envService.env.UseDrift === true || <any> this.envService.env.UseDrift === 'true') && !(<any> window)._drift) {
      (<any> window)._drift = {
        userid: this.client.ClientId.split('-').shift(),
        email: this.profileService.profile.Email
      };
    }
  }
}
