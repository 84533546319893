import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class RefinitivService {

  private baseUrl = 'http://product.datastream.com/DSWSClient/V1/DSService.svc/rest';

  constructor(
    private httpClient: HttpClient,
    private envService: EnvironmentService,
  ) { }

  public testLoginDetails(username: string, password: string) {
    return this.httpClient.get<any>(`${this.envService.env.CorsProxy.replace(/\/$/, '')}/${this.baseUrl}/Token?username=${username}&password=${password}`).toPromise();
  }

}
