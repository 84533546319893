import { Injectable } from '@angular/core';
import { EnvironmentDTO } from '@core/services/environment/dtos/environment-dto';
import { CompanyHorizonInfoDTO } from '@core/store/company/dtos/company-dto';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { ModelScaling } from '@core/types/model-scaling.type';
import { CalendarEffects } from '@dialogs/variables/forecast-variable/info/tabs/seasonal/fvar-calendar-settings';
import { AdminEnvDTO } from '@modules/admin/entities/admin-env.dto';
import { AggregationMethod } from '@modules/lang/language-files/aggregation';
import { AssessmentCategory, AssessmentLevel, AssessmentUnit } from '@modules/lang/language-files/assessment';
import { HistoricEventLocation, HistoricEventType } from '@modules/lang/language-files/historic-events';
import { LicenseType } from '@modules/lang/language-files/license-types';
import { MFASupplier } from '@modules/lang/language-files/mfa-suppliers';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { CompanyRole, ProjectRole } from '@modules/lang/language-files/roles';
import { SourceType } from '@modules/lang/language-files/source-types';
import { VSMeasurement, VSMode, VSModeType } from '@modules/lang/language-files/var-select';
import { VisibilityLevel, VisibilityLevelType } from '@modules/lang/language-files/visibility';
import { DisplayValue } from '@modules/lang/types/display-value';
import { FileType } from '@modules/lang/types/file-type';
import { EmptyModelName, ModelName } from '@modules/lang/types/model-name';
import { Periodicity } from '@modules/lang/types/periodicity';
import { AppConfig } from '@modules/root/app.config';
import { LanguageService } from '../../../modules/lang/language.service';
import { Country } from '../../../modules/lang/types/country';
import { VariableTransformation } from '../../../modules/lang/types/variable-transformation';
import { EnvironmentState } from './environment-state';

export type TwoFactorProviders = 'GoogleAuthenticator';

export const RW_MAX_RECALCULATION_FREQUENCY = 10;
export const MINIMUM_OBSERVATION_COUNT_FOR_OUT_OF_SAMPLE_CALCULATIONS = 13;

@Injectable({ providedIn: 'root' })
export class EnvironmentService {

  private state: EnvironmentState = new EnvironmentState();

  // App Env
  env: AppConfig;

  public get LatestToSId() { return this.state.LatestToSId; }

  // Roles
  public get CompanyRoles() { return this.state.CompanyRoles; }
  public get ProjectRoles() { return this.state.ProjectRoles; }

  // Assessments
  public get AssessmentCategories() { return this.state.AssessmentCategories; }
  public get AssessmentLevels() { return this.state.AssessmentLevels; }
  public get AssessmentUnits() { return this.state.AssessmentUnits; }
  // Aggregation Methods
  public get AggregationMethods() { return this.state.AggregationMethods; }
  // Scripts
  public get ScriptNames() { return this.state.ScriptNames; }
  // Model names
  public get UnivariateModelNames() { return this.state.UnivariateModelNames; }
  public get UnivariateModels() { return this.state.UnivariateModels; }
  public get MultivariateModelNames() { return this.state.MultivariateModelNames; }
  public get MultivariateModels() { return this.state.MultivariateModels; }
  public get AllModelNames() { return this.state.AllModelNames; }
  // Files
  public get FileTypes() { return this.state.FileTypes; }
  public get ValueTypes() { return this.state.ValueTypes; }
  // Currencies and Countries
  public get Currencies() { return this.state.Currencies; }
  public get Countries() { return this.state.Countries; }
  // Historic Events
  public get HistoricEventTypes() { return this.state.HistoricEventTypes; }
  public get HistoricEventLocations() { return this.state.HistoricEventLocations; }
  public get HistoricEventEffectTypes() { return this.state.HistoricEventEffectTypes; }
  // Graph transformations
  public get GraphTransformations() { return this.state.GraphTransformations; }
  // Variable Information
  public get Periodicities() { return this.state.Periodicities; }
  public get SourceTypes() { return this.state.SourceTypes; }
  public get VariableTransformations() { return this.state.VariableTransformations; }
  // Var select settings
  public get VSModes() { return this.state.VSModes; }
  public get SelectableVSModes() { return this.state.VSModes.filter(x => x.Value !== 'mfstepwise' && x.Value !== 'mfcoef' && x.Value !== 'mfbackward'); }
  public get VSMeasurements() { return this.state.VSMeasurements; }
  public get VSLassoStructs() { return this.state.VSLassoStructs; }
  public get VSCoefMethods() { return this.state.VSCoefMethods; }
  // Missing value settings
  public get MissingValueModels() { return this.state.MissingValueModels; }
  // Seasonal settings
  public get SeasonalModels() { return this.state.SeasonalModels; }
  public get SeasonalStrategies() { return this.state.SeasonalStrategies; }
  public get SeasonalTrends() { return this.state.SeasonalTrends; }
  public get SeasonalCalendarEffects() { return this.state.SeasonalCalendarEffects; }
  public get SeasonalOutlierTypes() { return this.state.SeasonalOutlierTypes; }
  public get SeasonalHolidaysCategories() { return this.state.HolidayCategories; }
  public get SeasonalRegionsCategories() { return this.state.RegionTypes; }
  // Outlier settings
  public get OutlierModels() { return this.state.OutlierModels; }
  public get OutlierStrategies() { return this.state.OutlierStrategies; }
  public get OutlierTypes() { return this.state.OutlierTypes; }
  // Report settings
  public get ReportTemplates() { return this.state.ReportTemplates; }
  // General
  public get ValidityLevels() { return this.state.ValidityLevels; }
  public get VisibilityLevels() { return this.state.VisibilityLevels; }

  /* TODO: Remove the following getter. */
  public get VariablesVisibilityCompany() {
    if (this.VisibilityLevels != null && this.VisibilityLevels.length > 0) {
      return this.VisibilityLevels.find(x => x.Value === 'company').Value;
    }
    return null;
  }
  /* TODO: Remove the following getter. */
  public get VariablesVisibilityPrivate() {
    if (this.VisibilityLevels != null && this.VisibilityLevels.length > 0) {
      return this.VisibilityLevels.find(x => x.Value === 'private').Value;
    }
    return null;
  }
  public get SourceVariableVisibilityLevels() { return this.state.SourceVariableVisibilityLevels; }
  public get NotificationTypes() { return this.state.NotificationTypes; }
  public get LicenseTypes() { return this.state.LicenseTypes; }

  public GetModelPropertyTypes() {
    return this.state.ModelPropertyTypes.slice();
  }

  public GetModelTransformations(scaling: ModelScaling.ModelScalingType) {
    switch (scaling) {
      case 'norm':
        return this.state.ModelTransformations.filter(x => x.Value.includes('norm'));
      case 'std':
        return this.state.ModelTransformations.filter(x => x.Value.includes('std'));
      default:
        console.error('Unknown scaling recieved in GetModelTransformations');
        return [];
    }
  }

  public fiscalOpts: DisplayValue[] = [
    { Value: 0, Display: 'January' },
    // { Value: 1, Display: 'February' },
    // { Value: 2, Display: 'March' },
    { Value: 3, Display: 'April' },
    // { Value: 4, Display: 'May' },
    // { Value: 5, Display: 'June' },
    { Value: 6, Display: 'July' },
    // { Value: 7, Display: 'August' },
    // { Value: 8, Display: 'September' },
    { Value: 9, Display: 'October' },
    // { Value: 10, Display: 'November' },
    // { Value: 11, Display: 'December' }
  ];

  // Security stuff
  MFASuppliers: MFASupplier[];

  alphaOptions: number[] = [0.01, 0.05, 0.1];

  // Text, values stuff
  private missing = {
    Value: 'Unknown',
    Display: 'Unknown',
    Description: 'Unknown',
    nameRegEx: '',
    CurrencyCode: 'USD',
    PhoneCode: null
  };

  constructor(
    private lang: LanguageService,
    private config: AppConfig
  ) {
    this.env = this.config;
    this.setNewState();
  }

  public isProduction() {
    return this.env.Environment === 'Production';
  }

  public setupAdminEnv(dto: AdminEnvDTO) {
    this.state.LicenseTypes = dto.LicenseTypes.map(z => this.lang.licenseTypes.find(x => x.Value === z)).filter(x => !!x);
  }

  private setNewState() {
    this.state = new EnvironmentState();
    this.state.Countries = this.lang.countries;
    this.state.Currencies = this.lang.currencies;
    this.state.ValueTypes = this.lang.valueTypes;
    this.state.ModelTransformations = this.lang.statModelSimpleTransforms;
    this.state.ModelPropertyTypes = this.lang.statModelPropertyTypes;
  }

  public map(dto: EnvironmentDTO) {
    this.setNewState();

    this.state.LatestToSId = dto.LatestToSId;
    this.state.ScriptNames = dto.ScriptNames;

    // Normal mappings below; array of strings
    this.MFASuppliers = this.lang.mapEntries(dto.MFASuppliers, this.lang.mfaSuppliers);
    this.state.AggregationMethods = this.lang.mapEntries(dto.AggregationMethods, this.lang.aggregationMethods);
    this.state.AssessmentUnits = this.lang.mapEntries(dto.AssessmentUnits, this.lang.assessmentUnits);
    this.state.AssessmentLevels = this.lang.mapEntries(dto.AssessmentLevels, this.lang.assessmentLevels);
    this.state.AssessmentCategories = this.lang.mapEntries(dto.AssessmentCategories, this.lang.assessmentCategories);
    this.state.CompanyRoles = this.lang.mapEntries(dto.CompanyRoles, this.lang.companyRoles);
    this.state.ProjectRoles = this.lang.mapEntries(dto.ProjectRoles, this.lang.projectRoles);
    this.state.HistoricEventTypes = this.lang.mapEntries(dto.HistoricEventTypes, this.lang.historicEventTypes);
    this.state.HistoricEventLocations = this.lang.mapEntries(dto.HistoricEventLocations, this.lang.historicEventLocations);
    this.state.HistoricEventEffectTypes = this.lang.mapEntries(dto.HistoricEventEffectTypes, this.lang.historicEventEffectTypes);
    this.state.Periodicities = this.lang.mapEntries(dto.Periodicities, this.lang.periodicities);
    this.state.ValidityLevels = this.lang.mapEntries(dto.ValidityLevels, this.lang.validityLevels);
    this.state.VariableTransformations = this.lang.mapEntries(dto.VariableTransformations, this.lang.variableTransformations);
    this.state.VSModes = this.lang.mapEntries(dto.VSModes, this.lang.varSelectModes);
    this.state.VSMeasurements = this.lang.mapEntries(dto.VSMeasurements, this.lang.varSelectMeasurements);
    this.state.VSLassoStructs = this.lang.mapEntries(dto.VSLassoStructs, this.lang.varSelectLassoStructs);
    this.state.VSCoefMethods = this.lang.mapEntries(dto.VSCoefMethods, this.lang.varSelectCoefMethods);
    this.state.VisibilityLevels = this.lang.mapEntries(dto.VisibilityLevels, this.lang.visibilityLevels);
    this.state.SourceVariableVisibilityLevels = this.lang.mapEntries(dto.SourceVariableVisibilityLevels, this.lang.sourceVariableVisibilityLevels);
    this.state.SourceTypes = this.lang.mapEntries(dto.SourceTypes, this.lang.sourceTypes);
    this.state.MissingValueModels = this.lang.mapEntries(dto.MissingValueModels, this.lang.missingValueModels);
    this.state.SeasonalModels = this.lang.mapEntries(dto.SeasonalModels, this.lang.seasonalModels);
    this.state.SeasonalStrategies = this.lang.mapEntries(dto.SeasonalStrategies, this.lang.seasonalStrategies);
    this.state.SeasonalTrends = this.lang.mapEntries(dto.SeasonalTrends, this.lang.seasonalTrends);
    this.state.SeasonalCalendarEffects = this.lang.mapEntries(dto.SeasonalCalendarEffects, this.lang.seasonalCalendar);
    this.state.RegionTypes = this.lang.mapEntries(dto.RegionTypes, this.lang.seasonalCalendarRegionType);
    this.state.SeasonalOutlierTypes = this.lang.mapEntries(dto.SeasonalOutlierTypes, this.lang.seasonalOutlierTypes);
    this.state.OutlierModels = this.lang.mapEntries(dto.OutlierModels, this.lang.outlierModels);
    this.state.OutlierStrategies = this.lang.mapEntries(dto.OutlierStrategies, this.lang.outlierStrategies);
    this.state.OutlierTypes = this.lang.mapEntries(dto.OutlierTypes, this.lang.outlierTypes);
    this.state.ReportTemplates = this.lang.mapEntries(dto.ReportTemplates, this.lang.reportTemplates);
    this.state.NotificationTypes = this.lang.mapEntries(dto.NotificationTypes, this.lang.notificationTypes);
    this.state.GraphTransformations = this.lang.mapEntries(dto.GraphTransformations, this.lang.graphTransformations);

    this.state.UnivariateModelNames = [];
    this.state.UnivariateModels = [];
    this.state.MultivariateModelNames = [];
    this.state.MultivariateModels = [];

    Object.keys(dto.UnivariateModelNames).forEach((key, index) => {
      try {
        const models = dto.UnivariateModelNames[key];
        const base = this.lang.mapModelName(key, key, index, 0);
        const transforms = models.map((name, t) => {
          let sub = models.length === 1 ? 2 : t + 1;
          return this.lang.mapModelName(name, key, index, sub);
        });
        this.UnivariateModelNames.push(base);
        this.UnivariateModelNames.push(...transforms);
        this.UnivariateModels.push({ Model: base, Transforms: transforms });
      } catch (e) { console.error('MODEL definition is missing for model-name', key); }
    });

    Object.keys(dto.MultivariateModelNames).forEach((key, index) => {
      try {
        const models = dto.MultivariateModelNames[key];
        const base = this.lang.mapModelName(key, key, index, 0);
        const transforms = models.map((name, t) => {
          let sub = models.length === 1 ? 2 : t + 1;
          return this.lang.mapModelName(name, key, index, sub);
        });
        this.MultivariateModelNames.push(base);
        this.MultivariateModelNames.push(...transforms);
        this.MultivariateModels.push({ Model: base, Transforms: transforms });
      } catch (e) { console.error('MODEL definition is missing for model-name', key); }
    });

    this.state.AllModelNames = [...this.UnivariateModelNames, ...this.MultivariateModelNames];
    this.state.AllModelGroups = [...this.MultivariateModels, ...this.UnivariateModels];

    /* Holiday type are special... Can we get rid of this? */
    this.state.HolidayTypes = dto.HolidayTypes.map(mode => this.lang.seasonalCalendarHolidayType.find(x => x.Value === mode)).filter(x => !!x);
    this.state.HolidayTypes.map(e => {
      const index = CalendarEffects.HolidayCategories.findIndex(p => p.children.includes(e.Value));
      CalendarEffects.HolidayCategories[index].subCategories = [...CalendarEffects.HolidayCategories[index].subCategories, e];
    });
    this.state.HolidayCategories = CalendarEffects.HolidayCategories;

    // Special mappings; dictionary-like strings
    for (const key in dto.FileTypes) {
      if (dto.FileTypes.hasOwnProperty(key)) {
        this.FileTypes.push(new FileType(key, dto.FileTypes[key]));
      }
    }

    // Mapping complete; other environment setup below
    this.Currencies.sort((a, b) => {
      return ((a.Value < b.Value) ? -1 : ((a.Value > b.Value) ? 1 : 0));
    });
  }

  public getValidPeriodicites(horizonInfos: CompanyHorizonInfoDTO[]) {
    const active = horizonInfos.filter(x => x.Enabled).map(x => x.Periodicity);
    return this.Periodicities.filter(x => active.some(s => s === x.Value));
  }

  public getValidAggregationMethods(variable: SourceVariableModel, forecastPeriodicity: PeriodicityType) {
    const variableHorizon = variable.getBaseVersion().periodicity;
    if ((variableHorizon.isShorter(forecastPeriodicity) ||
      variableHorizon.isLonger(forecastPeriodicity)) && !variable.getBaseVersion().DuplicateDates) {
      return this.AggregationMethods;
    } else if (variable.getBaseVersion().DuplicateDates) {
      return this.AggregationMethods.filter(x => x.Value !== 'last-value');
    }
    return this.AggregationMethods;
  }


  public getModelName(name: string): ModelName {
    if (!name) { return EmptyModelName(); }
    let m = this.state.AllModelNames.find(x => x.Value === name);
    if (!m && name.includes('naive')) {
      m = this.state.AllModelNames.find(x => x.Value === 'naive_org_std');
    }
    return m ? m : EmptyModelName();
  }

  public getAssessmentCategory(name: string): AssessmentCategory {
    const v = this.state.AssessmentCategories.find(x => x.Value === name);
    return v ? v : this.missing;
  }

  public getAssessmentTrustLevel(name: string): AssessmentLevel {
    const v = this.state.AssessmentLevels.find(x => x.Value === name);
    return v ? v : this.missing;
  }
  public getAssessmentUnit(name: string): AssessmentUnit {
    const v = this.state.AssessmentUnits.find(x => x.Value === name);
    return v ? v : this.missing;
  }
  public getAggregationMethod(name: string): AggregationMethod {
    const v = this.state.AggregationMethods.find(x => x.Value === name);
    return v ? v : this.missing;
  }
  public getVisibilityLevel(name: VisibilityLevelType): VisibilityLevel {
    const v = this.state.VisibilityLevels.find(x => x.Value === name);
    return v ? v : this.missing as VisibilityLevel;
  }
  public getSourceVariableVisibilityLevel(name: string) {
    const v = this.SourceVariableVisibilityLevels.find(x => x.Value === name);
    return v ? v : this.missing;
  }
  public getSourceType(name: string): SourceType {
    if (!name) { name = ''; }
    const v = this.state.SourceTypes.find(x => x.Value.toLowerCase() === name.toLowerCase());
    return v ? v : <any> this.missing;
  }
  public getHistoricEventType(name: string): HistoricEventType {
    const v = this.state.HistoricEventTypes.find(x => x.Value === name);
    return v ? v : this.missing;
  }
  public getHistoricEventLocation(name: string): HistoricEventLocation {
    const v = this.state.HistoricEventLocations.find(x => x.Value === name);
    return v ? v : this.missing;
  }

  public getCountry(name: string): Country {
    name = name ? name.toUpperCase() : null;
    const v = this.state.Countries.find(x => x.Value === name);
    return v ? v : this.missing;
  }

  public getLicense(name: string): LicenseType {
    name = name ? name.toLowerCase() : null;
    const v = this.state.LicenseTypes.find(x => x.Value.toLowerCase() === name);
    return v ? v : this.missing;
  }

  public getPeriodicity(name: string): Periodicity {
    name = name ? name.toLowerCase() : null;
    const v = this.state.Periodicities.find(x => x.Value.toLowerCase() === name);
    return v ? v : new Periodicity(null, null, null);
  }

  public getProjectRole(role: string): ProjectRole {
    const v = this.state.ProjectRoles.find(x => x.Value === role);
    return v ? v : this.missing;
  }

  public getCompanyRole(code: string): CompanyRole { return this.state.CompanyRoles.find(x => x.Value === code); }
  public getVarSelectMode(type: string): VSMode { return this.state.VSModes.find(x => x.Value === type); }
  public getVarSelectMeasurement(type: string): VSMeasurement { return this.state.VSMeasurements.find(x => x.Value === type); }
  public getVariableTransformation(type: string): VariableTransformation { return this.state.VariableTransformations.find(x => x.Value === type); }

  public validVSMeasurements(mode: VSModeType) {
    let measures = this.state.VSMeasurements;
    if (mode === 'stepwise') {
      measures = measures.filter(x => x.Value === 'MAE');
    }
    if (mode === 'backward') {
      measures = measures.filter(x => x.Value !== 'COEF' && x.Value !== 'MAE');
    }
    if (mode === 'coef') {
      measures = measures.filter(x => x.Value === 'COEF');
    }
    return measures;
  }
}
