import { DPModalOpts } from './data-processing-modal.options';

export class OpenDataProcessingModal {
  public static readonly type = '[MODAL] Open data processing modal';
  data: DPModalOpts;
  constructor(
    forecastId: string,
    forecastVersionId: string,
    forecastVariableId: string,
  ) { this.data = { forecastVariableId, forecastVersionId, forecastId }; }
}
