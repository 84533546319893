import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateFormatPipe } from '@shared/modules/pipes/date-format.pipe';
import * as d3 from 'd3';
import { SLGOptions } from './slg.options';

@Component({
  selector: 'indicio-slg',
  templateUrl: './simple-line-graph.component.html',
  styleUrls: ['./simple-line-graph.component.less']
})
export class SimpleLineGraphComponent implements AfterViewInit, OnChanges {

  @Input() options: SLGOptions.Options;

  // SVG properties
  svgElements = new SLGOptions.SVGElements();
  _id = `slg-${Math.random().toString(36).substring(7)}`;
  instance: number = Math.floor((Math.random() * 100) + 1);
  containerId: string;
  element: HTMLElement;
  parent: HTMLElement;

  private getTickFormat(date: Date) { return this.dateFormat.transform(date, this.options.Periodicity); }
  private get margins() { return this.options.Margins; }
  private get svgWidth() { return this.options.Width || this.parent.clientWidth; }
  private get svgHeight() { return this.options.Height; }
  private get getGraphWidth() { return this.svgWidth - this.margins.left - this.margins.right; }
  private get getGraphHeight() { return this.svgHeight - this.margins.top - this.margins.bottom; }
  private get getXRange() { return { start: this.options.InnerPadding, end: this.getGraphWidth - this.options.InnerPadding }; }
  private get getYRange() { return { start: this.getGraphHeight - this.options.InnerPadding, end: this.options.InnerPadding }; }

  constructor(
    private ui: ElementRef,
    private dateFormat: DateFormatPipe
  ) {
    this.element = this.ui.nativeElement;
    this.parent = this.ui.nativeElement.parentElement;
  }

  public ngOnChanges(_: SimpleChanges) {
    this.setupChart();
  }

  public ngAfterViewInit() {
    this.setupChart();
  }

  private setupChart() {
    this.removeSvg();
    this.createSvg();
    this.createScales();
    this.createXAxis();
    this.createYAxisLeft();
    this.createYAxisRight();
    this.addData();
    // // this.svgElements.background.call(this.hover.bind(this));
    // this.addHover();
  }

  private removeSvg() {
    if (this.svgElements.svg) {
      const elem = this.element.querySelector('svg');
      if (elem != null) { elem.remove(); }
    }
    this.svgElements = new SLGOptions.SVGElements();
  }

  private createSvg() {
    const w = this.options.Width || this.parent.clientWidth;
    const b = this.options.BorderWidth;
    const h = this.options.Height;
    const m = this.options.Margins;

    this.svgElements.svg = d3.select(`.simple-line-graph#${this._id}`)
      .append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    this.svgElements.graphGroup = this.svgElements.svg
      .append('g')
      .attr('transform', 'translate(' + m.left + ',' + m.top + ')');

    this.svgElements.graphGroup.append('clipPath')
      .attr('id', 'rect-clip-' + this._id)
      .append('rect')
      .attr('ry', 4)
      .attr('width', this.getGraphWidth)
      .attr('height', this.getGraphHeight)
      .attr('rx', 4);

    // background
    this.svgElements.graphGroup.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('ry', b / 2)
      .attr('rx', b / 2)
      .attr('width', w - m.left - m.right)
      .attr('height', h - m.top - m.bottom)
      .attr('fill', this.options.BackgroundColor)
      .attr('class', 'slg-background');

    this.svgElements.innerGraph = this.svgElements.graphGroup.append('g')
      .attr('width', w - m.left - m.right)
      .attr('height', h - m.top - m.bottom)
      .attr('clip-path', `url(#rect-clip-${this._id})`)
      .attr('class', 'inner-graph');

    // border
    this.svgElements.graphGroup.append('rect')
      .attr('x', -(b / 2))
      .attr('y', -(b / 2))
      .attr('ry', b)
      .attr('rx', b)
      .attr('width', w - m.left - m.right + b)
      .attr('height', h - m.top - m.bottom + b)
      .style('fill', 'none')
      .style('stroke-width', b)
      .style('stroke', 'rgba(0, 0, 0, 0.22)')
      .attr('class', 'slg-border');
  }

  private createScales() {
    const xRange = this.getXRange;
    const yRange = this.getYRange;

    const timeAxis = this.options.TimeAxis;
    this.svgElements.xScale = d3.scaleTime()
      .range([xRange.start, xRange.end])
      .domain([timeAxis.Min, timeAxis.Max]);

    const leftAxis = this.options.LeftAxis;
    this.svgElements.yScaleLeft = d3.scaleLinear()
      .range([yRange.start, yRange.end])
      .domain([leftAxis.Min, leftAxis.Max]);

    const rightAxis = this.options.RightAxis;
    if (!!rightAxis) {
      this.svgElements.yScaleRight = d3.scaleLinear()
        .range([yRange.start, yRange.end])
        .domain([rightAxis.Min, rightAxis.Max]);
    }
  }

  private createXAxis() {
    const h = this.options.Height;
    const m = this.options.Margins;

    const getDisplayDates = (all: Date[], count: number) => {
      const res: Date[] = [all[0]];
      if (all.length <= count) { count = Math.round(all.length / 3); }
      const step = Math.round(all.length / (count - 1));
      for (let i = 1; i < count - 1; i++) { res.push(all[i * step]); }
      res.push(all.last());
      return res;
    };

    // Append x lines
    this.svgElements.innerGraph.append('g')
      .attr('class', 'x slg-axis')
      .attr('transform', `translate(0, ${h})`)
      .call(
        d3.axisBottom(this.svgElements.xScale)
          .tickSize(-h)
      );

    const dates = getDisplayDates(this.options.TimeAxis.Dates, this.options.TimeAxis.TickCount);
    // Append x text
    this.svgElements.graphGroup.append('g')
      .attr('class', 'x slg-axis hide-line')
      .attr('transform', `translate(0, ${h - m.top - m.bottom + 5})`)
      .call(
        d3.axisBottom(this.svgElements.xScale)
          .tickValues([...dates])
          .tickFormat(d => this.getTickFormat(<Date> d))
      );
  }

  private createYAxisLeft() {
    const axOpts = this.options.LeftAxis;
    const ticks = this.getTickValues(axOpts.Min as number, axOpts.Max as number, axOpts.TickCount);
    // Append y lines
    this.svgElements.innerGraph.append('g')
      .attr('class', 'y slg-axis')
      .style('fill', 'rgb(24,24,24)')
      .call(
        d3.axisLeft(this.svgElements.yScaleLeft)
          .tickValues(ticks)
          .tickPadding(5)
          .tickSize(-this.getGraphWidth)
      );

    // Append y text
    this.svgElements.graphGroup.append('g')
      .attr('class', 'y slg-axis left hide-line')
      .attr('transform', 'translate(-5,0)')
      .call(
        d3.axisLeft(this.svgElements.yScaleLeft)
          .tickValues(ticks)
      );

    const color = axOpts.Color;
    this.svgElements.svg.selectAll('.slg-axis.left text')
      .attr('color', color);
  }

  createYAxisRight() {
    const axOpts = this.options.RightAxis;
    if (!axOpts) { return; }

    const ticks = this.getTickValues(axOpts.Min as number, axOpts.Max as number, axOpts.TickCount);
    this.svgElements.svg.append('g')
      .attr('class', 'y slg-axis right hide-line')
      .attr('transform', `translate(${this.getGraphWidth + 5},0)`)
      .call(
        d3.axisRight(this.svgElements.yScaleRight)
          .tickPadding(5)
          .tickValues(ticks)
      );

    const color = axOpts.Color;
    this.svgElements.svg.selectAll('.slg-axis.right text')
      .attr('color', color);
  }

  private addData() {

    const addLine = (data: SLGOptions.Data, scale: any) => {
      const valueline = d3.line<SLGOptions.ValueModel>()
        .defined(d => {
          const v = !(isNaN(+d.Value));
          return v;
        })
        .x(d => {
          const scaleval = this.svgElements.xScale(d.Date);
          return scaleval;
        })
        .y((d) => {
          const scaleval = scale(d.Value);
          return scaleval;
        });
      if (this.options.Curve) { valueline.curve(this.options.Curve); }

      this.svgElements.innerGraph
        .append('path')
        .data([data.Data])
        .style('fill', 'none')
        .style('stroke-width', data.StrokeWidth)
        .style('stroke', data.Color)
        .attr('d', valueline);
    };

    const addDots = (data: SLGOptions.Data, scale: any) => {
      // ! Must remove before updating
      data.Data.forEach(dx => {
        this.svgElements.innerGraph.append('circle')
          .data([dx])
          .filter(d => !isNaN(+d.Value))
          .attr('class', 'dot')
          .attr('cx', d => this.svgElements.xScale(d.Date))
          .attr('cy', d => scale(d.Value))
          .attr('r', this.options.DotSize);
      });
    };

    this.options.Data.forEach(d => {
      const scale = d.AxisPosition === SLGOptions.AxisPosition.left
        ? this.svgElements.yScaleLeft
        : this.svgElements.yScaleRight;

      addLine(d, scale);
      if (this.options.DotSize > 0) {
        addDots(d, scale);
      }
    });
  }

  private getTickValues(min: number, max: number, count: number) {
    const tickSize = (max - min) / (count - 1);
    const ans = [min];
    for (let i = 1; i < count - 1; i++) { ans.push(min + (i * tickSize)); }
    ans.push(max);
    return ans;
  }
}
