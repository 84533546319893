import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { CreateForecastResultDTO } from '@core/store/forecast-result/entities/forecast-result-dto';
import { ForecastResultService } from '@core/store/forecast-result/forecast-result.service';

export interface SaveForecastResultDialogData {
  result: CreateForecastResultDTO;
  forecastVersionId: string;
  existingResultNames: string[];
}

@Component({
  selector: 'indicio-dialog-save-forecast-result',
  templateUrl: 'save-result.dialog.component.html',
  styleUrls: ['save-result.dialog.component.less']
})
export class SaveForecastResultDialogComponent {
  public static Id: string = 'SaveForecastResultDialogComponent';

  /* Properties */
  public title: string = 'Save forecast';
  public name: string = '';

  /* Entities  */
  private result: CreateForecastResultDTO;

  /* Status */
  public initialized: boolean = false;
  public saveInProgress: boolean = false;
  public nameError: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: SaveForecastResultDialogData,
    private dialogRef: MatDialogRef<SaveForecastResultDialogComponent>,
    private status: StatusService,
    private forecastResultService: ForecastResultService
  ) {
    this.setup();
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public nameChange(name: string) {
    if (this.data.existingResultNames.includes(name)) {
      return this.nameError = 'A result with that name already exists.';
    }
    return this.nameError = null;
  }

  public save() {
    this.result.Name = this.name;
    this.saveInProgress = true;

    this.forecastResultService.saveForecastResult(this.result)
      .then(res => this.dialogRef.close(res))
      .then(() => this.status.setMessage('Forecast result saved.', 'Info'))
      .catch(error => this.status.setError(error, true))
      .finally(() => this.saveInProgress = false);
  }

  private setup() {
    this.result = this.data.result;
    this.name = this.result.Name;
    this.nameChange(this.name);
    this.initialized = true;
  }
}
