import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { UnivariateModelName } from '@modules/lang/language-files/stat-models';
import { ModelPropertiesDTO } from '@shared/modules/dialogs/model-properties/model-properties.dialog';
import { StatModelDTO, StatModelFilesDTO } from '../dtos/stat-model.dto';
import { StatModelMapper } from '../stat-model.mapper';
import { StatModel } from '../stat-model.model';


@Injectable({
  providedIn: 'root'
})
export class UnivariateBackendService {

  private _calls: { fId: string, p: Promise<StatModel[]>; }[] = [];

  constructor(
    private http: HttpService,
    private mapper: StatModelMapper
  ) { }

  public triggerUnivariateModels(fversionId: string, model: UnivariateModelName[]) {
    return this.http.post<null>(`forecast/version/${fversionId}/univariate/trigger`, model);
  }

  public triggerUnivariate(fversionId: string) {
    return this.http.post<null>(`forecast/version/${fversionId}/univariate/trigger`, null);
  }

  public removeUnivariateModels(fversionId: string, model: UnivariateModelName[]) {
    return this.http.post<null>(`forecast/version/${fversionId}/univariate/remove-models`, model);
  }

  public getAllUnivariateModels(forecastVersionId: string) {
    const call = this._calls.find(x => x.fId === forecastVersionId);
    if (call) { return call.p; }
    const newCall = {
      fId: forecastVersionId,
      p: this.http.get<StatModelDTO[]>(`forecast/version/${forecastVersionId}/univariate`)
        .then(result => this.handleResult(forecastVersionId, result.body))
        .finally(() => this._calls.removeByKey('fId', forecastVersionId))
    };
    this._calls.push(newCall);
    return newCall.p;
  }

  public getUnivariateModel(forecastVersionId: string, modelName: UnivariateModelName) {
    return this.http.get<StatModelDTO>(`forecast/version/${forecastVersionId}/univariate/models/${modelName}`)
      .then(result => {
        const models = this.handleResult(forecastVersionId, [result.body]);
        return models;
      });
  }

  public fetchModelResidualFiles(fVersionId: string, modelIds: number[]) {
    return this.http.post<StatModelFilesDTO[]>(`forecast/version/${fVersionId}/univariate/images/`, modelIds)
      .then(result => {
        const models = result.body.map(i => this.mapper.mapPlotImages(i));
        return Promise.all(models);
      });
  }

  public fetchModelTextFiles(fVersionId: string, modelIds: number[]) {
    return this.http.post<ModelPropertiesDTO[]>(`forecast/version/${fVersionId}/univariate/text-files/`, modelIds)
      .then(result => {
        return result.body;
      });
  }

  private handleResult(_: string, res: StatModelDTO[]) {
    const filtered = res.filter(x => !(x.IsProcessing || x.IsPending));
    return filtered.map(m => this.mapper.mapModel(m, null));
  }
}


