import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { MissingValueModelType } from '@modules/lang/language-files/missing-values';
import { FVarDialogViewIndex, ForecastVariableInfoDialogData } from '../../forecast-variable-info.dialog';

@Component({
  selector: 'indicio-fvar-info-dialog-missing-values-tab',
  templateUrl: './fvar-info-tab.missing-values.component.html',
  styleUrls: ['./fvar-info-tab.missing-values.component.less']
})
export class FVarInfoDialogMissingValuesTabComponent implements OnChanges {

  @Input() variable: ForecastVariableModel;
  @Input() forecastVersion: ForecastVersionModel;
  @Input() options: ForecastVariableInfoDialogData;

  @Output() closeDialogEvent = new EventEmitter();

  // Frontend flags
  public isLoading: boolean;
  public saveInProgress: boolean;
  public infopanelOpen: boolean = true;
  public viewEnum = FVarDialogViewIndex;
  public changed: boolean;

  // Data
  public availableIndicators: { value: string, display: string; }[] = [];
  public mvPlots: ResultFileDTO[] = [];
  public missingModelType: MissingValueModelType;
  public indicatorsToUse: string[] = [];

  // Entity Ids
  public get fVersionId() { return this.options.forecastVersionId; }
  public get forecastId() { return this.options.forecastId; }
  public get fVariableId() { return this.options.forecastVariableId; }

  constructor(
    private status: StatusService,
    private fVarService: ForecastVariableFrontendService,
    private cd: ChangeDetectorRef,
    private env: EnvironmentService,
  ) {
  }

  public ngOnChanges() {
    this.setup();
    this.cd.detectChanges();
  }

  public saveSettings() {
    if (!this.changed || !this.canSave || this.saveInProgress) { return; }
    this.saveInProgress = true;

    this.fVarService.updateVariableMissingValueSettings(this.variable, {
      MissingValueModel: this.missingModelType,
      MissingValueIndicators: JSON.stringify(this.indicatorsToUse)
    })
      .then(res => {
        this.variable = res;
        this.setSaved();
        this.status.setMessage('Missing value settings updated', 'Success', true);
      })
      .catch(error => {
        this.setSaved();
        this.status.setError(error, true);
      })
      .finally(() => this.saveInProgress = false);
  }

  private setup() {
    this.mvPlots = this.variable.MissingValuePlotImages.sort((a, b) => a.sortIndex - b.sortIndex);
    this.setAvailableIndicators();
    this.purgeIncorrectIndicators();
    this.isLoading = false;
  }

  public get getValidMissingValueModels() {
    return this.env.MissingValueModels
      .filter(x => this.variable.NeedDisAggregation ? x.Value === 'linearinterpolation' : true);
  }

  public get canSave(): boolean {
    if (this.missingModelType === 'amelia' && this.indicatorsToUse.length === 0) {
      return false;
    }
    return true;
  }

  public setModel(change: MatOptionSelectionChange) {
    if (!change.isUserInput) { return; }
    const val: MissingValueModelType = change.source.value;
    this.missingModelType = val;
    this.checkChanged();
  }

  public setIndicators(change: MatOptionSelectionChange) {
    if (!change.isUserInput) { return; }
    if (change.source.selected) {
      this.indicatorsToUse.addUniqueId(change.source.value);
    } else {
      this.indicatorsToUse.removeId(change.source.value);
    }
    this.checkChanged();
  }

  private checkChanged() {
    const indicators = this.indicatorsToUse.sort();
    const mvIndicators = this.variable.MissingValueIndicators.sort();
    this.changed = this.missingModelType !== this.variable.MissingValueModel ||
      JSON.stringify(indicators) !== JSON.stringify(mvIndicators);
  }

  private setSaved() {
    this.missingModelType = this.variable.MissingValueModel;
    this.indicatorsToUse = [...this.variable.MissingValueIndicators];
    this.checkChanged();
  }

  private setAvailableIndicators() {
    this.availableIndicators = this.forecastVersion.IndicatorVariables
      .filter(x => !x.IsTrend && !x.IsMixedFreq && x.ForecastVariableId !== this.variable.ForecastVariableId)
      .filter(x => !x.NeedDisAggregation)
      .map(x => ({
        display: x.Name,
        value: x.ForecastVariableId
      }));

    if (this.variable.IsIndicator) {
      this.availableIndicators.unshift({
        display: this.forecastVersion.ForecastVariable.Name,
        value: this.forecastVersion.ForecastVariable.ForecastVariableId
      });
    }

    this.setSaved();
  }

  private purgeIncorrectIndicators() {
    const anyIndicatorMissing = this.variable.MissingValueIndicators.some(x => -1 === this.availableIndicators.findIndex(y => y.value === x));
    if (anyIndicatorMissing) {
      const toRemove = this.variable.MissingValueIndicators.filter(x => -1 === this.availableIndicators.findIndex(y => y.value === x));
      toRemove.forEach(mvi => {
        this.indicatorsToUse.removeId(mvi);
      });
      this.checkChanged();
    }
  }
}
