<div class="wiki-page-container"
     [ngClass]="{ dark: darkMode || isDarkMode }"
     *ngIf="page">
  <h2 class="wiki-title"
      *ngIf="showTitle">
    {{ page.Title }}
  </h2>
  <div class="markdown-body">
    <markdown katex
              ngPreserveWhitespaces
              [disableSanitizer]="true"
              [data]="page.Content">
    </markdown>
  </div>
</div>
