
export namespace CacheUtils {
  /**
   * Class that wraps a Map instance, extending it to act as a
   * least-recently used (LRU) cache
   */
  export class LruCache<T> {

    private values: Map<string, T> = new Map<string, T>();
    private maxEntries: number = 20;

    constructor(size: number = 20) {
      this.maxEntries = size;
    }

    public has(key: string): boolean {
      return this.values.has(key);
    }

    public delete(key: string): boolean {
      return this.values.delete(key);
    }

    public get(key: string): T {
      const hasKey = this.values.has(key);
      let entry: T;
      if (hasKey) {
        // Re-insert entry for LRU strategy
        entry = this.values.get(key);
        this.values.delete(key);
        this.values.set(key, entry);
      }
      return entry;
    }

    public put(key: string, value: T) {
      if (this.values.size >= this.maxEntries) {
        // LRU cache eviction strategy
        const keyToDelete = this.values.keys().next().value;
        this.values.delete(keyToDelete);
      }
      this.values.set(key, value);
    }

    public clear() {
      this.values.clear();
    }

    public setMaxEntries(num: number) {
      this.maxEntries = num;
    }
  }
}

