import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { ActionService } from '@core/services/actions/actions.service';
import { OpenIdActions } from '@core/services/open-id/open-id.actions';
import { ForecastTemplateTagDTO } from '@core/store/forecast-template/forecast-template.model';
import { CreateOrUpdateHierarchyDialogComponent, CreateOrUpdateHierarchyDialogData } from '@core/store/hierarchical-forecast/dialogs/create-or-update-hierarchy/create-or-update-hierarchy.dialog';
import { CreateOrUpdateReconciliationDialogComponent, CreateOrUpdateReconciliationDialogData } from '@core/store/hierarchical-forecast/dialogs/create-or-update-reconciliation/create-or-update-reconciliation.dialog';
import { InspectReconciliationResultDialogComponent, InspectReconciliationResultDialogData } from '@core/store/hierarchical-forecast/dialogs/inspect-reconciliation-result/inspect-reconciliation-result.dialog';
import { InspectReconciliationDialogComponent, InspectReconciliationDialogData } from '@core/store/hierarchical-forecast/dialogs/inspect-reconciliation/inspect-reconciliation.dialog';
import { NewSqlDatabaseConnectionData, NewSqlDatabaseConnectionDialogComponent } from '@core/store/providers/sql-database/dialogs/add-new/new-sql-database.dialog';
import { ConfigureSqlDatabaseConnectionData, ConfigureSqlDatabaseConnectionDialogComponent } from '@core/store/providers/sql-database/dialogs/configure/configure-sql-database.dialog';
import { ImportVariableSqlDatabaseData, ImportVariableSqlDatabaseDialogComponent } from '@core/store/providers/sql-database/dialogs/import-variable/import-variable-sql-database.dialog';
import { WikiAssetDTO } from '@core/store/wiki/wiki.entities';
import { AdminSourceGraphDialogComponent, AdminSourceGraphDialogData } from '@modules/admin/components/content/source-variables/source-graph-dialog/source-graph.dialog';
import { DisplayValue } from '@modules/lang/types/display-value';
import { ALGActions } from '@shared/components/line-graph/alg.actions';
import { CloseActiveModals } from '@shared/modals/modal.actions';
import { CreateEditAutoMailDialogComponent, CreateEditAutoMailDialogData } from '@shared/modules/dialogs/admin/auto-mail/create-edit-automail.dialog';
import { AutotaskEmailDialogComponent, AutotaskEmailDialogData } from '@shared/modules/dialogs/auto-task/email-template/email-template.dialog';
import { AutoTaskLiveViewDialogComponent, TaskLiveViewData } from '@shared/modules/dialogs/auto-task/live-view/auto-task-live.dialog';
import { SetBvarPriorsDialogComponent, SetBvarPriorsDialogComponentData } from '@shared/modules/dialogs/bvar-priors/set-bvar-priors.dialog';
import { CreateAutoTaskStepDialogComponent, CreateAutoTaskStepDialogData } from '@shared/modules/dialogs/create-auto-task-step/create-auto-task-step.dialog';
import { CreateGroupVariableDialogComponent, CreateGroupVariableDialogComponentData } from '@shared/modules/dialogs/create-group-variable/create-group-variable.dialog';
import { CreateUserDialogComponent, CreateUserDialogData } from '@shared/modules/dialogs/create-user/create-user.dialog';
import { DialogActions } from '@shared/modules/dialogs/dialog.actions';
import { FileColumnDialogComponent, FileColumnnDialogData } from '@shared/modules/dialogs/file/file-column/file-column.dialog';
import { PreFileUploadDialogComponent, PreFileUploadDialogData } from '@shared/modules/dialogs/file/pre-file-upload/pre-file-upload.dialog';
import { AdminEditForecastTemplateDialogComponent, AdminEditForecastTemplateDialogData } from '@shared/modules/dialogs/forecast-template/admin-edit-template/admin-edit-forecast-template.dialog';
import { BrowseTemplatesDialogComponent, CreateForecastFromTemplateDialogData } from '@shared/modules/dialogs/forecast-template/browse-templates/browse-templates.dialog';
import { CreateForecastTemplateDialogComponent, CreateForecastTemplateDialogData } from '@shared/modules/dialogs/forecast-template/create-forecast-template/create-forecast-template.dialog';
import { CreateTemplateTagDialogComponent, CreateTemplateTagDialogData } from '@shared/modules/dialogs/forecast-template/create-template-tag/create-template-tag.dialog';
import { ForecastTemplateInfoDialogComponent, ForecastTemplateInfoDialogData } from '@shared/modules/dialogs/forecast-template/forecast-template-info/forecast-template-info.dialog';
import { UseTemplateLiveViewDialogComponent, UseTemplateLiveViewDialogData } from '@shared/modules/dialogs/forecast-template/use-template-live-view/use-template-live-view.dialog';
import { ModelWeightsComponent, ModelWeightsDialogData } from '@shared/modules/dialogs/forecast-variables/model-weights/model-weights.dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from '@shared/modules/dialogs/generic/confirm/confirm.dialog';
import { GenericInfoMessageDialogComponent, GenericInfoMessageDialogData } from '@shared/modules/dialogs/generic/generic-info-message/generic-info-message.dialog';
import { GenericSearchableDropdownDialogComponent, GenericSearchableDropdownDialogData } from '@shared/modules/dialogs/generic/generic-searchable-dropdown/generic-searchable-dropdown.dialog';
import { TextDialogComponent, TextDialogData } from '@shared/modules/dialogs/generic/text/text.dialog';
import { DropdownInputDialogComponent, DropdownInputDialogData } from '@shared/modules/dialogs/input/dropdown/dropdown-input.dialog';
import { TextInputDialogComponent, TextInputDialogData } from '@shared/modules/dialogs/input/text/text-input.dialog';
import { ModelPropertiesDialogComponent, ModelPropertiesDialogData } from '@shared/modules/dialogs/model-properties/model-properties.dialog';
import { NewsArchiveDialogComponent } from '@shared/modules/dialogs/news/archive/news-archive.dialog';
import { NewsArticleDialogComponent, NewsArticleDialogData } from '@shared/modules/dialogs/news/article/news-article.dialog';
import { MacrobondSearchOptionsDialogComponent, MacrobondSearchOptionsDialogData } from '@shared/modules/dialogs/providers/macrobond/search-options/searchoptions.dialog';
import { MacrobondSeriesInfoDialogComponent, MacrobondSeriesInfoDialogData } from '@shared/modules/dialogs/providers/macrobond/series-info/macrobond-series-info.dialog';
import { PusherInfoMessageDialogComponent, PusherInfoMessageDialogData } from '@shared/modules/dialogs/pusher-info-message/pusher-info-message.dialog';
import { ReportAddForecastsDialogComponent, ReportAddForecastsDialogData } from '@shared/modules/dialogs/report/add-forecast/report-add-forecast.dialog';
import { ReportEntrySettingsDialogComponent, ReportEntrySettingsDialogData } from '@shared/modules/dialogs/report/choose-result/report-choose-result.dialog';
import { ReportSettingsDialogComponent, ReportSettingsDialogData } from '@shared/modules/dialogs/report/settings/report-settings.dialog';
import { ResidualAnalysisDialogComponent, ResidualAnalysisDialogData } from '@shared/modules/dialogs/residual-analysis/residual-analysis.dialog';
import { SampleAccurracyDialogComponent, SampleAccurracyDialogData } from '@shared/modules/dialogs/sample-accuracy/sample-accuracy.dialog';
import { Auth2FASettingsData, Authentication2FASettingsDialogComponent } from '@shared/modules/dialogs/security/2-factor-settings/2-factor-settings.dialog';
import { ManageOpenIdIntegrationDialogComponent, ManageOpenIdIntegrationDialogData } from '@shared/modules/dialogs/security/openid/manage-integration/manage-openid-integration.dialog';
import { SetupOpenIdIntegrationDialogComponent, SetupOpenIdIntegrationDialogData } from '@shared/modules/dialogs/security/openid/setup-integration/setup-openid-integration.dialog';
import { SwapVariableDialogComponent, SwapVariableDialogData } from '@shared/modules/dialogs/swap-variable/swap-variable.dialog';
import { BulkTagSourceVariablesDialogComponent, BulkTagSourceVariablesDialogData } from '@shared/modules/dialogs/tags/bulk-tag-sourcevariables/bulk-tag-sourcevariables.dialog';
import { CreateTagDialogComponent, CreateTagDialogData } from '@shared/modules/dialogs/tags/create-tag/create-tag.dialog';
import { EditVariableTagsDialogComponent, EditVariableTagsDialogData } from '@shared/modules/dialogs/tags/edit-variable-tags/edit-variable-tags.dialog';
import { ModifyTagRelativesDialogComponent, ModifyTagRelativesDialogData } from '@shared/modules/dialogs/tags/modify-tag-relatives/modify-tag-relatives.dialog';
import { TestUserDialogComponent, TestUserDialogData } from '@shared/modules/dialogs/test-user/test-user.dialog';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CreateEditWikiPageDialogComponent, CreateEditWikiPageDialogData } from './admin/wiki/create-edit-wikipage/create-edit-wikipage.dialog';
import { AdminImportWikiDbWikiDialogComponent, AdminImportWikiDbWikiDialogData } from './admin/wiki/import/import-wikiDb.dialog';
import { AdminManageWikiAssetsDialogComponent, AdminManageWikiAssetsDialogData } from './admin/wiki/manage-assets/manage-assets.dialog';
import { AdminWikiPageCollectionDialogComponent, AdminWikiPageCollectionDialogData } from './admin/wiki/page-collection/page-collection.dialog';
import { EditForecastTemplateDialogComponent, EditForecastTemplateDialogData } from './forecast-template/edit-template/edit-forecast-template.dialog';
import { GenericALGDialogComponent, GenericALGDialogData } from './generic/generic-alg/generic-alg.dialog';
import { GenericTableDialogComponent, GenericTableDialogData } from './generic/generic-table/generic-table.dialog';
import { ImportProviderDialogComponent, ImportProviderDialogData } from './providers/import-provider/import-provider.dialog';
import { RedirectDialogComponent, RedirectDialogData } from './redirect/redirect.dialog';
import { ExportForecastResultDialogComponent, ExportForecastResultDialogData } from './results/export/export-result.dialog.component';
import { SaveForecastResultDialogComponent, SaveForecastResultDialogData } from './results/save/save-result.dialog.component';
import { PickSystemRoleDialogComponent, PickSystemRoleDialogData } from './security/pick-system-role/pick-system-role.dialog.component';
import { ShapGraphDialogComponent } from './shap-values/graph-dialog/shap-graph.dialog';
import { ShapValuesDialogComponent, ShapValuesDialogData } from './shap-values/table-dialog/shap-values.dialog';
import { CreateOrEditBenchmarkDialogComponent } from './source-variables/benchmarks/create-or-edit/create-or-edit-benchmark.dialog';
import { CreateOrEditBenchmarkDialogData } from './source-variables/benchmarks/create-or-edit/create-or-edit-benchmark.dialog.options';
import { ListAllBenchmarksDialogComponent } from './source-variables/benchmarks/list-all/list-all-benchmarks.dialog';
import { ListAllBenchmarksDialogData } from './source-variables/benchmarks/list-all/list-all-benchmarks.dialog.options';
import { ManualPeriodicityDialogComponent, ManualPeriodicityDialogData } from './source-variables/manual-periodicity/manual-periodicity.dialog';
import { WikiPageDialogComponent, WikiPageDialogData } from './wiki-page/wiki-page.dialog';

@Injectable({ providedIn: 'root' })
export class DialogService {

  private static defaultCfg: MatDialogConfig = {
    width: '350px',
    autoFocus: false,
    disableClose: false,
  };

  private wikisOpened = 0;

  constructor(
    private dialog: MatDialog,
    private actions: ActionService
  ) {
    this.setupActionListeners();
  }

  private setupActionListeners() {
    this.actions.dispatched(CloseActiveModals).subscribe((_: CloseActiveModals) => this.dialog.closeAll());
    this.actions.dispatched(ALGActions.Click).subscribe((a: ALGActions.Click) => this.openAlgDateInfoDialog(a));
    this.actions.dispatched(DialogActions.CloseById).subscribe((a: DialogActions.CloseById) => this.closeById(a.id));
    this.actions.dispatched(OpenIdActions.OpenSetupDialog).subscribe((a: OpenIdActions.OpenSetupDialog) => this.openSetupOpenIdIntegrationDialog(a.options));
  }

  /**
   * Generic dialogs
   */
  public openGenericALGDialogComponent(data: GenericALGDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '900px', position: { top: '10%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open<GenericALGDialogComponent, GenericALGDialogData, void>(GenericALGDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openTextInputDialog(data: TextInputDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open<TextInputDialogComponent, TextInputDialogData, string>(TextInputDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openDropdownInputDialog(data: DropdownInputDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '450px', position: { top: '10%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open<DropdownInputDialogComponent, any, DisplayValue<string>>(DropdownInputDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openConfirmDialog(data: ConfirmDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openInfoMessageDialog(data: GenericInfoMessageDialogData, cfg?: MatDialogConfig): Observable<void> {
    if (!cfg) { cfg = { width: '500px', position: { top: '10%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(GenericInfoMessageDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openGenericTableDialog(data: GenericTableDialogData, cfg?: MatDialogConfig): Observable<void> {
    if (!cfg) { cfg = { width: '800px', position: { top: '6%' } }; }
    const config = this.getConfig(data, cfg, GenericTableDialogComponent.Id);
    const dialogRef = this.dialog.open(GenericTableDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  /** Needs the type of iterable item */
  public openSearchDropdownDialog(data: GenericSearchableDropdownDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '500px', position: { top: '10%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(GenericSearchableDropdownDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openTextDialog(data: TextDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(TextDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openRedirectDialog(data: RedirectDialogData) {
    const config = this.getConfig(data, {
      width: '100%',
      height: '100%',
      panelClass: 'full-screen-dialog',
      maxHeight: '100vh',
      maxWidth: '100vw',
      enterAnimationDuration: '0ms'
    });
    const dialogRef = this.dialog.open(RedirectDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  /**
   * Special dialogs
   */
  public openPickSystemRoleDialog(data: PickSystemRoleDialogData) {
    const config = this.getConfig(data, { width: '500px', position: { top: '5%' }, disableClose: true });
    const dialogRef = this.dialog.open<PickSystemRoleDialogComponent, any, IndicioConstants.SystemRoleType>(PickSystemRoleDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openExportForecastResultDialog(data: ExportForecastResultDialogData) {
    const config = this.getConfig(data, { width: '500px', position: { top: '4%' } });
    const dialogRef = this.dialog.open(ExportForecastResultDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openSaveForecastResultDialog(data: SaveForecastResultDialogData) {
    const config = this.getConfig(data, { width: '400px', position: { top: '4%' } });
    const dialogRef = this.dialog.open(SaveForecastResultDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openImportProviderDialog(data: ImportProviderDialogData) {
    const config = this.getConfig(data, { width: '750px', position: { top: '4%' } });
    const dialogRef = this.dialog.open(ImportProviderDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateGroupVariableDialog(data: CreateGroupVariableDialogComponentData) {
    const config = this.getConfig(data, { width: '750px', position: { top: '2%' } });
    const dialogRef = this.dialog.open(CreateGroupVariableDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openSetBvarPriorsDialogComponent(data: SetBvarPriorsDialogComponentData) {
    const config = this.getConfig(data, { width: '800px', position: { top: '4%' } });
    const dialogRef = this.dialog.open(SetBvarPriorsDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openManualPeriodicityDialog(data: ManualPeriodicityDialogData) {
    const config = this.getConfig(data, { width: '650px', position: { top: '4%' } });
    const dialogRef = this.dialog.open(ManualPeriodicityDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openAlgDateInfoDialog(action: ALGActions.Click) {
    const config = this.getConfig(action.data, { width: '1000px', position: { top: '4%' } });
    const dialogRef = this.dialog.open(ShapGraphDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openShapValuesDialog(data: ShapValuesDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '80wv', position: { top: '8%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(ShapValuesDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openSampleAccurracyDialog(data: SampleAccurracyDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '85wv', position: { top: '6%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(SampleAccurracyDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openConfigureDbConnection(data: ConfigureSqlDatabaseConnectionData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '900px', position: { top: '2%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(ConfigureSqlDatabaseConnectionDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openImportVariableDatabaseConnection(data: ImportVariableSqlDatabaseData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '1000px' }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(ImportVariableSqlDatabaseDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openSetupNewDatabaseConnection(data: NewSqlDatabaseConnectionData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '700px', position: { top: '4%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(NewSqlDatabaseConnectionDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openNewsArchiveDialog(cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '700px', position: { top: '6%' } }; }
    const config = this.getConfig(null, cfg);
    const dialogRef = this.dialog.open(NewsArchiveDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openNewsArticleDialog(data: NewsArticleDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '800px', position: { top: '8%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(NewsArticleDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateOrUpdateReconciliationDialog(data: CreateOrUpdateReconciliationDialogData, cfg?: MatDialogConfig): Observable<boolean> {
    if (!cfg) { cfg = { width: '600px', position: { top: '6%' } }; }
    const config = this.getConfig(data, cfg, CreateOrUpdateReconciliationDialogComponent.Id);
    const dialogRef = this.dialog.open(CreateOrUpdateReconciliationDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateOrUpdateHierarchyDialog(data: CreateOrUpdateHierarchyDialogData, cfg?: MatDialogConfig): Observable<boolean> {
    if (!cfg) { cfg = { width: '400px', position: { top: '6%' } }; }
    const config = this.getConfig(data, cfg, CreateOrUpdateHierarchyDialogComponent.Id);
    const dialogRef = this.dialog.open(CreateOrUpdateHierarchyDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openInspectReconciliationDialog(data: InspectReconciliationDialogData, cfg?: MatDialogConfig): Observable<void> {
    if (!cfg) { cfg = { width: '550px', position: { top: '6%' } }; }
    const config = this.getConfig(data, cfg, InspectReconciliationDialogComponent.Id);
    const dialogRef = this.dialog.open(InspectReconciliationDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openInspectReconciliationResultDialog(data: InspectReconciliationResultDialogData, cfg?: MatDialogConfig): Observable<void> {
    if (!cfg) { cfg = { width: '450px', position: { top: '8%' } }; }
    const config = this.getConfig(data, cfg, InspectReconciliationResultDialogComponent.Id);
    const dialogRef = this.dialog.open(InspectReconciliationResultDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openPusherInfoMessageDialog(data: PusherInfoMessageDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '550px', position: { top: '8%' } }; }
    const config = this.getConfig(data, cfg, PusherInfoMessageDialogComponent.Id);
    const dialogRef = this.dialog.open(PusherInfoMessageDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateForecastFromTemplateDialog(data: CreateForecastFromTemplateDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '1300px', position: { top: '3%' } }; }
    const config = this.getConfig(data, cfg, BrowseTemplatesDialogComponent.Id);
    const dialogRef = this.dialog.open(BrowseTemplatesDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openEditForecastTemplateDialog(data: EditForecastTemplateDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '750px', position: { top: '8%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(EditForecastTemplateDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openAdminEditForecastTemplateDialog(data: AdminEditForecastTemplateDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '750px', position: { top: '8%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(AdminEditForecastTemplateDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openForecastTemplateInfoDialog(data: ForecastTemplateInfoDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '600px', position: { top: '10%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(ForecastTemplateInfoDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openUseTemplateLiveViewDialog(data: UseTemplateLiveViewDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '600px', position: { top: '8%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(UseTemplateLiveViewDialogComponent, config);
    return { Opened: dialogRef.afterOpened().pipe(take(1)), Ref: dialogRef.afterClosed().pipe(take(1)) };
  }

  public openCreateForecastTemplateDialog(data: CreateForecastTemplateDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '750px', position: { top: '8%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(CreateForecastTemplateDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openMacrobondSeriesInfoDialog(data: MacrobondSeriesInfoDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '650px', position: { top: '10%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(MacrobondSeriesInfoDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openTaskLiveView(data: TaskLiveViewData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(AutoTaskLiveViewDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openForce2FASettingsDialog(data: Auth2FASettingsData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(Authentication2FASettingsDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openModelProperties(data: ModelPropertiesDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '760px', position: { top: '4%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(ModelPropertiesDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openResidualAnalysis(data: ResidualAnalysisDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '850px', position: { top: '5%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(ResidualAnalysisDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openSwapVariables(data: SwapVariableDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(SwapVariableDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openBulkTagSourceVariablesDialog(data: BulkTagSourceVariablesDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(BulkTagSourceVariablesDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateEditAutomailDialog(data: CreateEditAutoMailDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '700px', position: { top: '5%' } }; }
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(CreateEditAutoMailDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateAutoTasksDialog(data: CreateAutoTaskStepDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(CreateAutoTaskStepDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openAutoTaskEmailTemplate(data: AutotaskEmailDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(AutotaskEmailDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateTemplateTagDialog(data?: CreateTemplateTagDialogData) {
    const config = this.getConfig(data, { width: '550px', position: { top: '5%' } });
    const dialogRef = this.dialog.open<CreateTemplateTagDialogComponent, any, ForecastTemplateTagDTO>(CreateTemplateTagDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateTagDialog(data?: CreateTagDialogData) {
    const config = this.getConfig(data);
    const dialogRef = this.dialog.open(CreateTagDialogComponent, config);
    dialogRef.beforeClosed().pipe(take(1)).subscribe(() => dialogRef.componentInstance.resetService());
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateUserDialog(data: CreateUserDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(CreateUserDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openTestUserDialog(data: TestUserDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(TestUserDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openModifyTagRelativesDialog(data: ModifyTagRelativesDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(ModifyTagRelativesDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openEditVariableTagsDialog(data: EditVariableTagsDialogData) {
    const config = this.getConfig(data);
    const dialogRef = this.dialog.open(EditVariableTagsDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openReportSettingsDialog(data: ReportSettingsDialogData) {
    const config = this.getConfig(data);
    const dialogRef = this.dialog.open(ReportSettingsDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openReportEntrySettingsDialog(data: ReportEntrySettingsDialogData) {
    const config = this.getConfig(data);
    const dialogRef = this.dialog.open(ReportEntrySettingsDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openReportAddForecastsDialog(data: ReportAddForecastsDialogData) {
    const config = this.getConfig(data);
    const dialogRef = this.dialog.open(ReportAddForecastsDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openMacrobondSearchOptionsDialog(data: MacrobondSearchOptionsDialogData) {
    const config = this.getConfig(data);
    const dialogRef = this.dialog.open(MacrobondSearchOptionsDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openForecastVariableModelWeightsDialog(data: ModelWeightsDialogData) {
    const config = this.getConfig(data);
    const dialogRef = this.dialog.open(ModelWeightsComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }



  public openPreFileUploadDialog(data: PreFileUploadDialogData) {
    const cfg = { minWidth: 600, position: { top: '10%' } };
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(PreFileUploadDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openFileColumnDialog(data: FileColumnnDialogData) {
    const cfg = { minWidth: 500, position: { top: '12%' } };
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(FileColumnDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openSetupOpenIdIntegrationDialog(data: SetupOpenIdIntegrationDialogData) {
    this.closeById(SetupOpenIdIntegrationDialogComponent.Id);
    const cfg = { minWidth: 500, position: { top: '12%' }, disableClose: data.Force || false };
    const config = this.getConfig(data, cfg, SetupOpenIdIntegrationDialogComponent.Id);
    const dialogRef = this.dialog.open(SetupOpenIdIntegrationDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openManageOpenIdIntegrationDialog(data: ManageOpenIdIntegrationDialogData) {
    const cfg = { minWidth: 500, position: { top: '12%' } };
    const config = this.getConfig(data, cfg);
    const dialogRef = this.dialog.open(ManageOpenIdIntegrationDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openAdminSourceVariableGraph(data: AdminSourceGraphDialogData) {
    const config = this.getConfig(data, { width: '600px', position: { top: '4%' } });
    const dialogRef = this.dialog.open(AdminSourceGraphDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openAdminCreateWikiPage(data: CreateEditWikiPageDialogData): Observable<boolean> {
    const config = this.getConfig(data, {
      width: '90%',
      height: '90%',
      panelClass: 'full-screen-dialog',
      maxHeight: '100vh',
      maxWidth: '100vw'
    });
    const dialogRef = this.dialog.open(CreateEditWikiPageDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openAdminWikiImportDialog(data: AdminImportWikiDbWikiDialogData) {
    const config = this.getConfig(data, { width: '40vw', position: { top: '8%' } });
    const dialogRef = this.dialog.open(AdminImportWikiDbWikiDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openAdminManageWikiAssets(data: AdminManageWikiAssetsDialogData): Observable<WikiAssetDTO> {
    const config = this.getConfig(data, { width: '600px', position: { top: '6%' } });
    const dialogRef = this.dialog.open(AdminManageWikiAssetsDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openAdminWikiPages(data: AdminWikiPageCollectionDialogData): Observable<string> {
    const config = this.getConfig(data, { width: '650px', position: { top: '6%' } });
    const dialogRef = this.dialog.open(AdminWikiPageCollectionDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openWikiPage(data: WikiPageDialogData, cfg?: MatDialogConfig): Observable<null> {
    let id = `${WikiPageDialogComponent.Id}-${++this.wikisOpened}`;
    const config = this.getConfig(data, Object.assign({ width: '800px', position: { top: '6%' } }, cfg || {}), id);
    const dialogRef = this.dialog.open(WikiPageDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openListAllBenchmarks(data: ListAllBenchmarksDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, { width: '650px', position: { top: '6%' } });
    const dialogRef = this.dialog.open(ListAllBenchmarksDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateOrEditBenchmark(data: CreateOrEditBenchmarkDialogData, cfg?: MatDialogConfig) {
    const config = this.getConfig(data, { width: '880px', position: { top: '6%' } });
    const dialogRef = this.dialog.open(CreateOrEditBenchmarkDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  /**
   * Private stöff below
   */
  private getConfig(data?: any, cfg?: MatDialogConfig, id?: string) {
    const config = Object.assign({}, DialogService.defaultCfg, cfg);
    config.data = data;
    config.id = id;
    return config;
  }

  private closeById(id: string) {
    const dialogues = this.dialog.openDialogs.filter(x => x.id.match(new RegExp(id)));
    dialogues.forEach(d => d.close());
  }
}
