import { Component } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { Verify2FaDTO } from '@core/store/auth/dtos/verify-2fa-dto';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';


@Component({
  selector: 'indicio-change-pw-modal',
  templateUrl: './google-2fa-modal.component.html',
  styleUrls: ['./google-2fa-modal.less']
})
export class Google2FAModalComponent extends ModalModelComponent {
  inProgress: boolean = false;
  codeToVerify: number = null;
  rememberMe: boolean = false;
  cookieValue: string;
  withCookie: boolean = false;
  started: boolean = false;
  preventSpam: boolean = false;
  hasBeenTriggered: boolean = false;
  preventAutomaticSubmit = true;
  backupCode: string = null;
  useBackup: boolean = false;

  constructor(
    public store: Store,
    public authService: AuthFrontendService,
    public profileService: ProfileFrontendService,
    public env: EnvironmentService,
    private statusService: StatusService,
  ) {
    super();
  }

  public setOptions() {
    this.isLoading = false;
    setTimeout(() => this.preventAutomaticSubmit = false, 500);
  }

  public onClose() {
    if (!this.inProgress) {
      this.authService.loginInProgress = false;
    }
  }

  public codeInput() {
    if (!this.preventAutomaticSubmit && this.codeToVerify != null && this.codeToVerify.toString().length >= 6 && !this.preventSpam) {
      this.preventSpam = true;
      this.verify2FA();
    }
  }

  public verify2FA() {
    const dto = this.getDto();
    this.inProgress = true;
    this.started = true;
    this.authService.verify2FA(dto)
      .then(() => {
        this.authService.refreshToken()
          .then(token => {
            this.authService.fetchClientAndStoreToken(token);
          });
        this.close();
        this.statusService.setMessage('Login success', 'Success', false);
      })
      .catch(error => {
        this.cookieValue = null;
        this.codeToVerify = null;
        this.started = false;
        this.statusService.setError(error, true);
      })
      .finally(() => this.inProgress = false);
  }

  private getDto(): Verify2FaDTO {
    return {
      RememberMe: this.rememberMe,
      Code: this.codeToVerify,
      RememberToken: this.cookieValue,
      BackupCode: this.backupCode
    };
  }
}
