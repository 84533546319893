<h1 mat-dialog-title>{{ data.Title }}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>{{isParent ? 'Parent tags' : 'Child tags'}}</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row *ngFor="let tag of relatives"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="removeParent(tag)">
        {{ tag.Name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input [placeholder]="isParent ? 'Add new parent' : 'Add new child'"
             #relativeInput
             [formControl]="relativeControl"
             [matAutocomplete]="autoRelative"
             [matChipInputFor]="chipList">
    </mat-chip-grid>
    <mat-autocomplete #autoRelative="matAutocomplete"
                      (optionSelected)="selectedRelative($event)">
      <mat-option *ngFor="let relative of filteredRelatives | async"
                  [value]="relative">
        {{relative.Name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

</div>

<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">Cancel</button>
  <button mat-flat-button
          [mat-dialog-close]="true"
          cdkFocusInitial>
    Save
  </button>
</div>
