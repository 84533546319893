
import { Def } from '@core/decorators/def';
import { HistoricValueDTO, PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { ForecastResultDTO } from '@core/store/forecast-result/entities/forecast-result-dto';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { ReportTemplateType } from '@modules/lang/language-files/reports';
import { ReportSettingsDTO } from '../dtos/report.dtos';



export class ReportModel implements IHasModelId {
  @Def() ReportId: string;
  @Def() CompanyId: string;
  @Def() Name: string;
  @Def() CreatorEmail: string;
  @Def() CreatedDate: Date;
  @Def() ModifiedDate: Date;
  @Def() Template: ReportTemplateType;
  @Def() Entries: ReportEntryModel[];
  @Def() Settings: ReportSettingsDTO;
  @Def() StartDate: Date;
  @Def() NameDisplay: string;
  @Def() SelectedTagId: string;

  // Frontend
  public reportYear: number;
  public today: moment.Moment;
  public reportEndDate: moment.Moment;
  public startDate: moment.Moment;
  public currentToDate: moment.Moment;
  public FiscalYear: number = 0;
  public fetched = false;
  public openMenu = false;
  public isPending = false;
  public pyFyTDStart: moment.Moment;
  public pyFyTDEnd: moment.Moment;
  public downloadPending = false;

  public getModelId() { return this.ReportId; }
}

export class ReportEntryModel {
  @Def() ReportId: string;
  @Def() ForecastId: string;
  @Def() ForecastVersionId: string;
  @Def() Periodicity: PeriodicityType;
  @Def() SelectedResultId: string;
  @Def() SourceVariableId: string;
  @Def() ForecastStartDate: Date;
  @Def() LastForecastedDate: Date;
  @Def() Name: string;
  @Def() SourceVariableName: string;
  @Def() Tags: string[];
  @Def() HistoricValues: HistoricValueDTO[];
  @Def() ForecastResults: ForecastResultDTO[];
  @Def() ForecastVariableTransform: string;
  @Def() IsPercent: boolean;

  @Def() lastHistoricDate: moment.Moment;
  @Def() lastForecastedDate: moment.Moment;
  @Def() forecastTooShort: boolean;
  @Def() values: PlotValueDTO[];
  @Def() hasResults: boolean;

  public currentFromDate: moment.Moment;
  public currentToDate: moment.Moment;
  public pyFyStart: moment.Moment;
  public pyFyToDate: moment.Moment;
}
