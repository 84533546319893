<indicio-modal-base [child]="this"
                    [modalTitle]="'Project '+ project?.Name"
                    *ngIf="project"
                    modalClass=""
                    [loading]="isLoading">

  <div style="margin: 0 auto; width: 645px;">
    <ng-container *ngTemplateOutlet="modalNavigation">
    </ng-container>
    <ng-container *ngIf="view === 'General'"
                  [ngTemplateOutlet]="generalView">
    </ng-container>
    <ng-container *ngIf="view === 'Members'"
                  [ngTemplateOutlet]="membersView">
    </ng-container>
    <ng-container *ngIf="view === 'Variables'"
                  [ngTemplateOutlet]="variablesView">
    </ng-container>
    <ng-container *ngIf="view === 'Assessment'"
                  [ngTemplateOutlet]="assessmentView">
    </ng-container>
    <ng-container *ngIf="view === 'data-processing'"
                  [ngTemplateOutlet]="dataProcessingView">
    </ng-container>
    <ng-container [ngTemplateOutlet]="buttonRow">
    </ng-container>
  </div>

</indicio-modal-base>

<ng-template #modalNavigation>
  <div class="row">
    <div class="modal-menu">
      <div data-test-id='edit-project-general-btn'
           class="menu-button project-menu-button"
           [ngClass]="{ 'current': view === 'General'}"
           (click)="changeView('General')">
        General
      </div>
      <div data-test-id='edit-project-members-btn'
           class="menu-button project-menu-button"
           [ngClass]="{ 'current': view === 'Members'}"
           (click)="changeView('Members')">
        Members
      </div>
      <div data-test-id='edit-project-variables-btn'
           class="menu-button project-menu-button"
           [ngClass]="{ 'current': view === 'Variables', 'disabled-effect': !advancedMode }"
           [indicioTippy]="!advancedMode ? 'This is only available when advanced settings are turned on' : null"
           (click)="!advancedMode ? null : changeView('Variables')">
        Variables
      </div>
      <div data-test-id='edit-project-assessment-btn'
           class="menu-button project-menu-button"
           [ngClass]="{ 'current': view === 'Assessment', 'disabled-effect': !advancedMode}"
           [indicioTippy]="!advancedMode ? 'This is only available when advanced settings are turned on' : null"
           (click)="!advancedMode ? null : changeView('Assessment')">
        Assessment
      </div>
      <div data-test-id='edit-project-data-processing-btn'
           class="menu-button project-menu-button"
           [ngClass]="{ 'current': view === 'data-processing', 'disabled-effect': !advancedMode}"
           [indicioTippy]="!advancedMode ? 'This is only available when advanced settings are turned on' : null"
           (click)="!advancedMode ? null : changeView('data-processing')">
        Data Processing
      </div>
    </div>
  </div>
</ng-template>


<ng-template #generalView>
  <form #f="ngForm"
        ngNativeValidate>
    <!-- Project name -->
    <div class="row"
         *ngIf="true"
         title="Project name">
      <div class="col-xs-7">
        <div class="input-label bold">Project name</div>
        <span class="subtitle">Display name of the project
        </span>
      </div>
      <div class="col-xs-5"
           style="margin-top: 15px;">
        <mat-form-field>
          <input data-test-id='edit-project-name'
                 matInput
                 autocomplete="off"
                 [ngModel]="newProjectName"
                 (ngModelChange)="changeProjectName($event)"
                 [disabled]="!canUpdateProject"
                 name="Name"
                 maxlength="64"
                 required>
          <mat-hint *ngIf="nameConflict">
            <span class="error-text">A project with that name already exists.</span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <!-- Project visibility -->
    <div class="row"
         *ngIf="true"
         title="Project visibility">
      <div class="col-xs-7">
        <div class="input-label bold">Project visibility</div>
        <span class="subtitle">{{ project.VisibilityLevelId === 'private'
          ? "Only invited members can see this project"
          : "Everyone in your organization can see this project" }}
        </span>
      </div>
      <div class="col-xs-5 toggle-icon text-right"
           data-test-id='edit-project-visibility'
           style="margin-top: 15px;">
        <mat-form-field>
          <mat-select [(value)]="project.VisibilityLevelId"
                      [disabled]="!canUpdateProject"
                      required>
            <mat-option [value]="visibility.Value"
                        [title]="visibility.Description"
                        *ngFor="let visibility of envService.VisibilityLevels">{{ visibility.Display }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="row"
       *ngIf="!userIsOwner && userIsMember && canUpdateProject">
    <div class="col-xs-12">
      <button data-test-id='edit-project-leave'
              class="btn outline danger no-margin-bottom"
              (click)="leaveProject()">
        Leave project
      </button>
    </div>
  </div>
</ng-template>

<ng-template #membersView>
  <div class="row">
    <div class="row">
      <div class="col-xs-12">
        <indicio-members-list [MemberListOptions]="memberListOptions"
                              (transferOwnershipEvent)="transferOwnership($event)"
                              (hasActiveInviteEvent)="toggleSaveMemberButton($event)"
                              (memberChangedRoleEvent)="updateMemberRole($event)"
                              [shouldInviteNewMember]="shouldInviteNewMember"
                              [company]="company"
                              (atSaveEvent)="saveInvitation();">
        </indicio-members-list>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="noEditRights"></ng-container>
</ng-template>

<ng-template #variablesView>
  <!-- Aggregation settings -->
  <div class="row">
    <div class="col-xs-8">
      <div class="input-label bold">Aggregation method</div>
      <span class="subtitle">The method used when aggregating a variable from e.g. monthly to quarterly
        periodicity</span>
    </div>
    <div class="col-xs-4"
         style="margin-top:15px">
      <mat-form-field>
        <mat-select [(value)]="project.AggregationMethodId"
                    [disabled]="!canUpdateProject"
                    required>
          <mat-option [value]="aggMethod.Value"
                      [title]="aggMethod.Description"
                      *ngFor="let aggMethod of envService.AggregationMethods">{{ aggMethod.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row"
       *ngIf="true"
       title="Automatic update of variables">
    <div class="col-xs-8">
      <div class="input-label bold">Automatic update of variables</div>
      <span class="subtitle">{{ project.AutoUpdateVariables
        ? "Variables will be updated as soon as new data becomes available"
        : "Variables can be updated manually from the data management view for each forecast" }}
      </span>
    </div>
    <div class="col-xs-4 toggle-icon text-right"
         style="margin-top: 25px;">
      <mat-slide-toggle data-test-id='auto-variable-update-toggle-btn'
                        [disabled]="!canUpdateProject"
                        [checked]="project.AutoUpdateVariables"
                        (change)="toggleAutoUpdateVariables()">
      </mat-slide-toggle>
    </div>
  </div>

  <div class="row"
       *ngIf="true"
       title="Automatic update of variables">
    <div class="col-xs-8">
      <div class="input-label bold">Adjust variables for outliers</div>
      <span class="subtitle">{{ project.CalculateOutliers
        ? "Variables will checked for outliers by default"
        : "Outlier detection turned off by default, but can be enabled on a variable basis" }}
      </span>
    </div>
    <div class="col-xs-4 toggle-icon text-right"
         style="margin-top: 25px;">
      <mat-slide-toggle data-test-id='auto-variable-update-toggle-btn'
                        [disabled]="!canUpdateProject"
                        [checked]="project.CalculateOutliers"
                        (change)="toggleOutlier()">
      </mat-slide-toggle>
    </div>
  </div>

  <div class="row"
       *ngIf="true"
       title="Automatic update of variables">
    <div class="col-xs-8">
      <div class="input-label bold">Adjust variables for seasonal effects</div>
      <span class="subtitle">{{ project.CalculateSeasonal
        ? "Variables will checked for seasonal effects by default"
        : "Seasonal adjustments turned off by default, but can be enabled on a variable basis" }}
      </span>
    </div>
    <div class="col-xs-4 toggle-icon text-right"
         style="margin-top: 25px;">
      <mat-slide-toggle data-test-id='auto-variable-update-toggle-btn'
                        [disabled]="!canUpdateProject"
                        [checked]="project.CalculateSeasonal"
                        (change)="toggleSeasonal()">
      </mat-slide-toggle>
    </div>
  </div>
</ng-template>

<ng-template #assessmentView>
  <form #f="ngForm"
        ngNativeValidate>
    <!-- Assessment weights -->
    <div class="row">
      <div class="col-xs-8">
        <div data-test-id='low-weight-percentage'
             class="input-label bold">Low weight (%)</div>
        <span data-test-id='low-weight-percentage-value-column'
              class="subtitle">Impact of a low-trust assessments: {{ project.AssessmentWeightLow }} %</span>
      </div>
      <div class="col-xs-4 drag-bar-blue"
           style="margin-top:25px"
           [ngClass]="{'disabled': !canUpdateProject }"
           title="Low weight">
        <input data-test-id='low-weight-percentage-range'
               id="assLowRange"
               type="range"
               min="10"
               max="40"
               step="5"
               name="size1"
               [(ngModel)]="project.AssessmentWeightLow" />
      </div>
    </div>

    <div class="row">
      <div class="col-xs-8">
        <div data-test-id='medium-weight-percentage'
             class="input-label bold">Medium weight (%)</div>
        <span data-test-id='medium-weight-percentage-value-column'
              class="subtitle">Impact of a medium-trust assessments: {{ project.AssessmentWeightMedium }} %</span>
      </div>
      <div class="col-xs-4 drag-bar-blue"
           style="margin-top:25px"
           [ngClass]="{'disabled': !canUpdateProject }"
           title="Medium weight">
        <input data-test-id='medium-weight-percentage-range'
               id="assMidRange"
               type="range"
               min="40"
               max="70"
               step="5"
               name="size2"
               [(ngModel)]="project.AssessmentWeightMedium" />
      </div>
    </div>

    <div class="row">
      <div class="col-xs-8">
        <div data-test-id='high-weight-percentage'
             class="input-label bold">High weight (%)</div>
        <span data-test-id='high-weight-percentage-value-column'
              class="subtitle">Impact of a high-trust assessments: {{ project.AssessmentWeightHigh }} %</span>
      </div>
      <div class="col-xs-4 drag-bar-blue"
           style="margin-top:25px"
           [ngClass]="{'disabled': !canUpdateProject }"
           title="High weight">
        <input data-test-id='high-weight-percentage-range'
               id="assHighRange"
               type="range"
               min="70"
               max="100"
               step="5"
               name="size3"
               [(ngModel)]="project.AssessmentWeightHigh" />
      </div>
    </div>
  </form>
  <ng-container *ngTemplateOutlet="noEditRights"></ng-container>
</ng-template>

<ng-template #dataProcessingView>
  <ng-container *ngTemplateOutlet="dpSettingsTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="noEditRights"></ng-container>
</ng-template>

<ng-template #noEditRights>
  <div *ngIf="!canUpdateProject"
       class="row">
    <div class="col-xs-12 modal-distinct-row red text-center">
      You have insufficient access rights to edit project settings.
    </div>
  </div>
</ng-template>

<ng-template #buttonRow>
  <div class="row">
    <div class="col-xs-6">
      <button data-test-id='edit-project-cancel-btn'
              *ngIf="canUpdateProject"
              class="btn outline blue left cancel"
              type="button"
              (click)="close()">
        <span *ngIf="isProjectChanged">Cancel</span>
        <span *ngIf="!isProjectChanged">Close</span>
      </button>

      <button data-test-id='edit-project-leave'
              *ngIf="!canUpdateProject && userIsMember"
              class="btn outline danger no-margin-bottom"
              (click)="leaveProject()">
        Leave project
      </button>
    </div>
    <div class="col-xs-6"
         *ngIf="canUpdateProject && view !== 'Members'">
      <button data-test-id='edit-project-save-btn'
              class="btn right save"
              [ngClass]="{ disabled: !isProjectChanged || nameConflict }"
              (click)="update()">
        <span *ngIf="!isLoading">Save</span>
        <span *ngIf="isLoading"
              class="ion-load-c spinner"></span>
      </button>
    </div>
    <div class="col-xs-6"
         *ngIf="!canUpdateProject">
      <button data-test-id='edit-project-close-btn'
              class="btn right"
              (click)="close()">
        <span>Close</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #dpSettingsTemplate>
  <indicio-foldable-row #row
                        (toggleEvent)="toggleAccordion('outlier')"
                        [manualToggle]="true"
                        [open]="outlierOpen">
    <div class="row"
         (click)="row.runToggleEvent()"
         style="width: 100%;"
         foldable-row-title>
      <div class="col-xs-12">
        Outlier settings
      </div>
    </div>

    <div class="row">
      <div class="col-xs-8">
        <div class="input-label bold">Outlier model</div>
        <span class="subtitle">The model to use when searching for outliers</span>
      </div>
      <div class="col-xs-4"
           style="margin-top:15px">
        <mat-form-field>
          <mat-select [value]="project.OutlierModel"
                      required
                      (selectionChange)="setOutlierModel($event)">
            <mat-option [value]="mode.Value"
                        [title]="mode.Description"
                        *ngFor="let mode of envService.OutlierModels">{{ mode.Display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-8">
        <div class="input-label bold">Outlier strategy</div>
        <span class="subtitle">Outlier selection strategy, used to select what outliers are used</span>
      </div>
      <div class="col-xs-4"
           style="margin-top:15px">
        <mat-form-field>
          <mat-select [value]="project.OutlierStrategy"
                      required
                      (selectionChange)="setOutlierStrat($event)">
            <mat-option [value]="mode.Value"
                        [title]="mode.Description"
                        *ngFor="let mode of envService.OutlierStrategies">{{ mode.Display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-8">
        <div class="input-label bold">Outlier types</div>
        <span class="subtitle">Outlier types defines what kind of outliers to search for</span>
      </div>
      <div class="col-xs-4"
           style="margin-top:15px">
        <mat-form-field *ngIf="!isLoading">
          <mat-select [value]="project.OutlierTypes"
                      required
                      [multiple]=true>
            <mat-option [value]="mode.Value"
                        [title]="mode.Description"
                        (onSelectionChange)="selectOutlierType($event)"
                        *ngFor="let mode of envService.OutlierTypes">{{ mode.Display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row center-horizontal-and-vertical">
      <span *ngIf="project.OutlierTypes.length === 0"
            class="red">You must select at least one outlier type</span>
    </div>
  </indicio-foldable-row>

  <indicio-foldable-row #row2
                        (toggleEvent)="toggleAccordion('seasonal')"
                        [manualToggle]="true"
                        [open]="seasonalOpen">
    <div class="row"
         (click)="row2.runToggleEvent()"
         style="width: 100%;"
         foldable-row-title>
      <div class="col-xs-12">
        Seasonal settings
      </div>
    </div>

    <div class="row">
      <div class="col-xs-8">
        <div class="input-label bold">Seasonal model</div>
        <span class="subtitle">What seasonal model to use</span>
      </div>
      <div class="col-xs-4"
           style="margin-top:15px">
        <mat-form-field>
          <mat-select [value]="project.SeasonalModel"
                      required
                      (selectionChange)="setSeasonalModel($event)">
            <mat-option [value]="mode.Value"
                        [title]="mode.Description"
                        *ngFor="let mode of envService.SeasonalModels">{{ mode.Display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row"
         [title]="project.SeasonalModel !== 'x13' ? 'Setting unavailable when using X11 model':''">
      <div class="col-xs-8">
        <div class="input-label bold"
             [ngClass]="project.SeasonalModel !== 'x13' ? 'grey':''">Seasonal strategy</div>
        <span class="subtitle">What seasonal pattern should be used</span>
      </div>
      <div class="col-xs-4"
           style="margin-top:15px">
        <mat-form-field>
          <mat-select [value]="project.SeasonalStrategy"
                      required
                      [disabled]="project.SeasonalModel !== 'x13'"
                      (selectionChange)="setSeasonalStrat($event)">
            <mat-option [value]="mode.Value"
                        [title]="mode.Description"
                        *ngFor="let mode of envService.SeasonalStrategies">{{ mode.Display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row"
         title="Extract and model the trend for the target variables">
      <div class="col-xs-8">
        <div class="input-label bold">Model seasonal trend</div>
        <span class="subtitle">
          {{ project.SeasonalTrend !== 'deactivate'
          ? 'Seasonal trends will be extracted by default (changeable on a variable basis)'
          : 'Disregard seasonal trends by default (changeable on a variable basis)'
          }}</span>
      </div>
      <div class="col-xs-4 toggle-icon text-right"
           style="margin-top: 15px;">
        <mat-form-field>
          <mat-select [value]="project.SeasonalTrend"
                      required
                      (selectionChange)="setSeasonalTrend($event)">
            <mat-option [value]="mode.Value"
                        [title]="mode.Description"
                        *ngFor="let mode of envService.SeasonalTrends">{{ mode.Display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <mat-slide-toggle data-test-id='use-trend-toggle-btn'
                            [disabled]="!canUpdateProject"
                            [checked]="project.UseSeasonalTrend"
                            (change)="toggleUseTrend()">
          </mat-slide-toggle> -->
      </div>
    </div>

    <indicio-modal-option-row optionTitle="Test calendar effect"
                              optionSubtitle="AIC test for calendar effects">
      <div style="margin-top:15px">
        <mat-form-field *ngIf="!isLoading">
          <mat-select [value]="project.SeasonalCalendarEffects"
                      required
                      [multiple]=true>
            <mat-option [value]="mode.Value"
                        [title]="mode.Description"
                        (onSelectionChange)="selectSeasonalcalendarEffect($event)"
                        *ngFor="let mode of envService.SeasonalCalendarEffects">{{ mode.Display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Seasonal outlier types"
                              optionSubtitle="Select outlier types for seasonal outliers">
      <div style="margin-top:15px">
        <mat-form-field>
          <mat-select [value]="project.SeasonalOutlierTypes"
                      required
                      [multiple]=true>
            <mat-option [value]="mode.Value"
                        [title]="mode.Description"
                        (onSelectionChange)="selectSeasonalOutlierType($event)"
                        *ngFor="let mode of envService.SeasonalOutlierTypes">{{ mode.Display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Seasonal outlier adjustment"
                              optionSubtitle="Adjust the seasonal pattern for outliers"
                              textWidth=10
                              optionsWidth=2>
      <div class="toggle-icon text-right"
           style="margin-top: 25px;">
        <mat-slide-toggle data-test-id='use-trend-toggle-btn'
                          [checked]="project.SeasonalOutliers"
                          (change)="toggleSeasonalOutlier()">
        </mat-slide-toggle>
      </div>
    </indicio-modal-option-row>
  </indicio-foldable-row>
</ng-template>
