
import { Injectable } from '@angular/core';
import { ClearStateAction } from '@core/store/auth/auth.actions';
import { ScriptQueueMapper } from '@core/store/script-queue/script-queue.mapper';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CompanyActions } from '../company/company.actions';
import { RQueueEntryModel, RQueueInfoModel } from './r-queue.models';
import { QueueActions } from './script-queue.actions';


export class ScriptQueueStateModel {
  Queue: RQueueInfoModel[];
}

@State<ScriptQueueStateModel>({
  name: 'scriptQueue',
  defaults: { Queue: [] }
})
@Injectable()
export class ScriptQueueState {

  constructor(
    private mapper: ScriptQueueMapper
  ) { }

  @Selector()
  static queue(state: ScriptQueueStateModel) { return state.Queue; }

  @Action(QueueActions.RemoveEntries)
  removeEntry(ctx: StateContext<ScriptQueueStateModel>, action: QueueActions.RemoveEntries) {
    const queue = ctx.getState().Queue;
    const entry = queue.find(q => q.ForecastVersionId === action.forecastVersionId);
    if (!entry) { return; }
    action.rRequestIds.forEach(rRequestId => {
      entry.Entries.removeById(rRequestId);
    });
    ctx.patchState({ Queue: [...queue] });
  }

  @Action(QueueActions.FailedEntry)
  failedEntry(ctx: StateContext<ScriptQueueStateModel>, action: QueueActions.FailedEntry) {
    const { model, queue } = this.getOrCreateQueueModel(ctx.getState().Queue, action.forecastVersionId);
    const oldEntry = model.Entries.find(x => x.RRequestId === action.requestId);
    if (!oldEntry) { return; }
    this.fastForwardCompleteEventInInfo(model, oldEntry, ctx);
    model.CompletedEntries.push(oldEntry);
    model.Entries.removeById(oldEntry.getModelId());
    oldEntry.Status = 'error';

    ctx.dispatch(new QueueActions.Finished({
      ForecastVersionId: model.ForecastVersionId,
      ScenarioId: oldEntry.ScenarioId,
      ForecastVariableId: oldEntry.ForecastVariableId,
      ModelName: oldEntry.ModelName,
      ScriptName: oldEntry.ScriptName,
      Extras: {
        PendingScenarios: null
      }
    }));

    ctx.patchState({ Queue: [...queue] });
  }

  @Action(QueueActions.NewEntries)
  newEntry(ctx: StateContext<ScriptQueueStateModel>, action: QueueActions.NewEntries) {
    const { model, queue } = this.getOrCreateQueueModel(ctx.getState().Queue, action.forecastVersionId);
    action.arr.forEach(rQentry => {
      if (model.Entries.filter(e => e.RRequestId === rQentry.RRequestId && e.ScriptName === rQentry.SN).length === 0) {
        const newEntry = this.mapper.mapRQueueEntry(rQentry);
        model.Entries.push(newEntry);
      }
    });
    ctx.patchState({ Queue: [...queue] });
  }

  @Action(QueueActions.UpdateEntries)
  updateEntry(ctx: StateContext<ScriptQueueStateModel>, action: QueueActions.UpdateEntries) {
    const { model, queue } = this.getOrCreateQueueModel(ctx.getState().Queue, action.forecastVersionId);
    action.arr.forEach(rQEntry => {
      const updatedEntry = this.mapper.mapRQueueEntry(rQEntry);
      model.TimeUntilFinished = rQEntry.TUF;
      switch (updatedEntry.Status) {
        case 'pending':
        case 'processing':
          const currentEntry = model.Entries.find(x => x.RRequestId === updatedEntry.RRequestId);
          if (currentEntry) {
            updatedEntry._elapsedTime = currentEntry._elapsedTime;
            updatedEntry.PercentDone = currentEntry.PercentDone;
            currentEntry.stopCounter();
            model.Entries.removeById(updatedEntry.RRequestId);
          }
          updatedEntry.startCounter();
          model.Entries.push(updatedEntry);
          ctx.dispatch(new QueueActions.Queued({
            ForecastVersionId: updatedEntry.ForecastVersionId,
            ForecastVariableId: updatedEntry.ForecastVariableId,
            ModelName: updatedEntry.ModelName,
            ScriptName: updatedEntry.ScriptName,
            ScenarioId: updatedEntry.ScenarioId,
          }));
          break;
        case 'complete':
          const oldE = model.Entries.find(x => x.RRequestId === updatedEntry.RRequestId);
          if (oldE) { oldE.stopCounter(); }
          this.fastForwardCompleteEventInInfo(model, updatedEntry, ctx);
          model.CompletedEntries.push(updatedEntry);
          model.Entries.removeById(updatedEntry.getModelId());
          const pendingScenarios = model.Entries.filter(xe => xe.ScriptName === 'conditional' && xe.ScenarioId === updatedEntry.ScenarioId).length;
          ctx.dispatch(new QueueActions.Finished({
            ForecastVersionId: updatedEntry.ForecastVersionId,
            ScenarioId: updatedEntry.ScenarioId,
            ForecastVariableId: updatedEntry.ForecastVariableId,
            ModelName: updatedEntry.ModelName,
            ScriptName: updatedEntry.ScriptName,
            Extras: {
              PendingScenarios: pendingScenarios
            }
          }));
          break;
        case 'queued':
          break;
      }
    });
    ctx.patchState({ Queue: [...queue] });
  }

  @Action(QueueActions.QueueInfo)
  newQueueAction(ctx: StateContext<ScriptQueueStateModel>, action: QueueActions.QueueInfo) {
    const queue = ctx.getState().Queue;
    const newEntry = this.mapper.mapRQueueInfo(action.message);
    const oldEntry = queue.find(q => q.ForecastVersionId === newEntry.ForecastVersionId);

    if (oldEntry && oldEntry.Entries.length > 0 && newEntry.Entries.length === 0) {
      newEntry.Entries = [];
      queue.addOrUpdate(newEntry);
      return ctx.patchState({ Queue: [...queue] });
    }

    if (action.isSanity) {
      return;
    }

    if (!oldEntry) {
      newEntry.Entries.forEach(e => e.startCounter());
    } else {
      newEntry.Entries.forEach(ne => {
        const oe = oldEntry.Entries.find(x => x.ScriptName === ne.ScriptName && x.ModelName === ne.ModelName && x.ForecastVariableId === ne.ForecastVariableId);
        if (oe) {
          ne.PercentDone = oe.PercentDone;
          ne._elapsedTime = oe._elapsedTime;
          ne.PlaceInQueue = oe.PlaceInQueue;
          ne.startCounter();
        }
      });
      newEntry.CompletedEntries = oldEntry.CompletedEntries;
      oldEntry.stopAllTimers();
      oldEntry.Entries.forEach(e => {
        const idx = newEntry.Entries.findIndex(x => x.ScriptName === e.ScriptName && x.ModelName === e.ModelName && x.ForecastVariableId === e.ForecastVariableId);

        // Finished stuffz
        if (idx === -1 && !newEntry.CompletedEntries.find(x => x.ScriptName === e.ScriptName && x.ModelName === e.ModelName && x.ForecastVariableId === e.ForecastVariableId)) {
          this.fastForwardCompleteEventInInfo(newEntry, e, ctx);
          newEntry.CompletedEntries.push(e);
          const pendingScenarios = newEntry.Entries.filter(xe => xe.ScriptName === 'conditional').length;
          ctx.dispatch(new QueueActions.Finished({
            ForecastVersionId: e.ForecastVersionId,
            ScenarioId: e.ScenarioId,
            ForecastVariableId: e.ForecastVariableId,
            ModelName: e.ModelName,
            ScriptName: e.ScriptName,
            Extras: {
              PendingScenarios: pendingScenarios
            }
          }));
        } /** Pending/in progress Stuffz */ else {
          ctx.dispatch(new QueueActions.Queued({
            ForecastVersionId: e.ForecastVersionId,
            ForecastVariableId: e.ForecastVariableId,
            ModelName: e.ModelName,
            ScriptName: e.ScriptName,
            ScenarioId: e.ScenarioId,
          }));
        }
      });
    }
    queue.addOrUpdate(newEntry);
    ctx.patchState({ Queue: [...queue] });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<ScriptQueueStateModel>) {
    ctx.patchState({ Queue: [] });
  }

  private getOrCreateQueueModel(queue: RQueueInfoModel[], forecastVersionId: string) {
    let model = queue.find(q => q.ForecastVersionId === forecastVersionId);
    if (!model) {
      model = new RQueueInfoModel();
      model.ForecastVersionId = forecastVersionId;
      model.Entries = [];
      queue.push(model);
    }
    return { model, queue };
  }

  private fastForwardCompleteEventInInfo(info: RQueueInfoModel, event: RQueueEntryModel, ctx: StateContext<ScriptQueueStateModel>) {
    event.Status = 'complete';
    event.stopCounter();

    setTimeout(() => {
      const idx = info.CompletedEntries.findIndex(ce => ce.ScriptName === event.ScriptName && ce.ModelName === event.ModelName);
      info.CompletedEntries.splice(idx, 1);
      ctx.patchState({ Queue: [...ctx.getState().Queue] });
    }, 3000);
  }
}
