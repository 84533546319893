import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  currentTime = DateUtils.newDate();

  constructor(
    private datePipe: DatePipe
  ) { }

  public GetDateFormats(): any[] {
    return [
      {
        display: this.datePipe.transform(this.currentTime, 'yyyy-MM-dd'),
        value: 'yyyy-MM-dd',
        title: 'yyyy-MM-dd'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'dd-MM-yyyy'),
        value: 'dd-MM-yyyy',
        title: 'dd-MM-yyyy'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'dd/MM/yyyy'),
        value: 'dd/MM/yyyy',
        title: 'dd/MM/yyyy'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'dd-MM-yy'),
        value: 'dd-MM-yy',
        title: 'dd-MM-yy'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'dd/MM/yy'),
        value: 'dd/MM/yy',
        title: 'dd/MM/yy'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'd/M-yy'),
        value: 'd/M-yy',
        title: 'd/M-yy'
      }
    ];
  }

  public GetTimeFormats(): any[] {
    return [
      {
        display: this.datePipe.transform(this.currentTime, 'HH:mm:ss'),
        value: 'HH:mm:ss',
        title: 'HH:mm:ss'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'HH:mm'),
        value: 'HH:mm',
        title: 'HH:mm'
      }
    ];
  }
}
