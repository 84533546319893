
import { Injectable } from '@angular/core';
import { ErrorReportService } from '@core/services/error-report.service';
import { ClearStateAction } from '@core/store/auth/auth.actions';
import { ClientState } from '@core/store/client/client.state';
import {
  CreateForecastSuccessAction, GetForecastMetaSuccessAction, GetForecastSuccessAction,
  RemoveForecastSuccessAction
} from '@core/store/forecast/forecast.actions';
import {
  ChangeActiveProjectAction,
  GetProjectMembersSuccessAction, GetProjectsInCompanySuccessAction,
  GetProjectSuccessAction,
  RemovedProjectsInCompanyAction,
  RemoveProjectMemberSuccessAction,
  RemoveProjectSuccessAction
} from '@core/store/project/project.actions';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UpdatedUserPermissionsAction } from '../client/client.actions';
import { CompanyActions } from '../company/company.actions';
import { CompanyModel } from '../company/company.model';
import { ProjectModel } from './project.model';



class ProjectStateModel {
  Projects: ProjectModel[];
  ProjectMetas: ProjectModel[];
}
@State<ProjectStateModel>({
  name: 'project',
  defaults: {
    Projects: [],
    ProjectMetas: []
  }
})
@Injectable()
export class ProjectState {

  @Select(ClientState.activeCompany)
  activeCompany$: Observable<CompanyModel>;

  constructor(
    private errorFrontendReport: ErrorReportService
  ) { }

  @Selector([ClientState.activeCompany])
  static projectsInActiveCompany(state: ProjectStateModel, activeCompany: CompanyModel) {
    return state.Projects.filter(p => p.CompanyId === activeCompany.CompanyId);
  }

  @Selector([ClientState.activeCompany])
  static projectMetasInActiveCompany(state: ProjectStateModel, activeCompany: CompanyModel) {
    return state.ProjectMetas.filter(p => p.CompanyId === activeCompany.CompanyId);
  }

  @Action(ChangeActiveProjectAction)
  changeActiveProject(ctx: StateContext<ProjectStateModel>, _action: ChangeActiveProjectAction) {
    ctx.dispatch(new UpdatedUserPermissionsAction());
  }

  @Action(RemoveProjectSuccessAction)
  removeProjectSuccess(ctx: StateContext<ProjectStateModel>, action: RemoveProjectSuccessAction) {
    const projects = ctx.getState().Projects;
    projects.removeById(action.projectId);
    ctx.patchState({ Projects: [...projects] });
  }

  @Action(GetProjectSuccessAction)
  getProjectSuccess(ctx: StateContext<ProjectStateModel>, action: GetProjectSuccessAction) {
    const projects = ctx.getState().Projects;
    const idx = projects.findIndex(project => project.ProjectId === action.project.ProjectId);

    if (idx > -1 && JSON.stringify(action.project.Permissions) !== JSON.stringify(projects[idx].Permissions)) {
      ctx.dispatch(new UpdatedUserPermissionsAction());
    }

    projects.addOrUpdate(action.project);
    ctx.patchState({ Projects: [...projects] });
  }

  @Action(GetForecastMetaSuccessAction)
  getForecastMetaSuccess(ctx: StateContext<ProjectStateModel>, action: GetForecastMetaSuccessAction) {
    try {
      const projects = ctx.getState().Projects;
      const idx = projects.findIndex(x => x.ProjectId === action.model.ProjectId);
      projects[idx].ForecastIds.addUniqueId(action.model.ForecastId);
      ctx.patchState({ Projects: [...projects] });
    } catch (e) {
      this.errorFrontendReport.postFrontendIssue({
        Stacktrace: e,
        Subject: `Error in getForecastMetaSuccess() project.state.ts. ForecastMetaDTO: ${JSON.stringify(action.model)} action: ${JSON.stringify(action)} projects: ${ctx.getState().Projects}`
      });
    }
  }

  @Action(GetProjectMembersSuccessAction)
  getProjectMembersSuccess(ctx: StateContext<ProjectStateModel>, action: GetProjectMembersSuccessAction) {
    const projects = ctx.getState().Projects;
    const idx = projects.findIndex(project => project.ProjectId === action.projectId);
    projects[idx].Members = action.members;
    ctx.patchState({ Projects: [...projects] });
  }

  @Action(RemoveProjectMemberSuccessAction)
  removeProjectMember(ctx: StateContext<ProjectStateModel>, action: RemoveProjectMemberSuccessAction) {
    const projects = ctx.getState().Projects;
    const project = projects.find(x => x.ProjectId === action.projectId);
    if (!project) { return; }
    project.Members.removeByKey('Email', action.memberEmail);
    ctx.patchState({ Projects: [...projects] });
  }

  @Action(GetProjectsInCompanySuccessAction)
  getCompanyProjectsSuccess(ctx: StateContext<ProjectStateModel>, action: GetProjectsInCompanySuccessAction) {
    const projects = ctx.getState().Projects;
    const projectsToRemove = projects.filter(x => action.projects.findIndex(y => y.ProjectId === x.ProjectId) === -1);
    projectsToRemove.forEach(p => projects.removeById(p.ProjectId));
    action.projects.forEach(p => projects.addOrUpdate(p));
    ctx.patchState({ Projects: [...projects] });
  }

  @Action(GetForecastSuccessAction)
  getForecastSuccessAction(ctx: StateContext<ProjectStateModel>, action: GetForecastSuccessAction) {
    const projects = ctx.getState().Projects;
    const project = projects.find(x => x.ProjectId === action.forecast.ProjectId);
    if (project && !project.ForecastIds.includes(action.forecast.ForecastId)) {
      project.ForecastIds.push(action.forecast.ForecastId);
    }
    ctx.patchState({ Projects: [...projects] });
  }

  @Action(RemoveForecastSuccessAction)
  removeForecastSuccessAction(ctx: StateContext<ProjectStateModel>, action: RemoveForecastSuccessAction) {
    const projects = ctx.getState().Projects;
    const project = projects.find(x => x.ForecastIds.includes(action.forecastId));
    if (!project) { return; }
    const index = project.ForecastIds.indexOf(action.forecastId);
    project.ForecastIds.splice(index, 1);
    project.ForecastCount--;
    ctx.patchState({ Projects: [...projects] });
  }

  @Action(CreateForecastSuccessAction)
  createForecastSuccessAction(ctx: StateContext<ProjectStateModel>, action: CreateForecastSuccessAction) {
    const projects = ctx.getState().Projects;
    const project = projects.find(x => x.ProjectId === action.projectId);
    if (!project) { return; }
    project.ForecastCount++;
    ctx.patchState({ Projects: [...projects] });
  }

  // Meta action
  @Action(RemovedProjectsInCompanyAction)
  removeProjectsInCompany(ctx: StateContext<ProjectStateModel>, action: RemovedProjectsInCompanyAction) {
    const projects = ctx.getState().Projects;
    const toRemove = projects.filter(p => p.CompanyId === action.companyId).map(p => p.ProjectId);
    for (const id of toRemove) {
      ctx.dispatch(new RemoveProjectSuccessAction(id));
    }
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<ProjectStateModel>) {
    ctx.patchState({ Projects: [], ProjectMetas: [] });
  }
}

