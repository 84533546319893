<indicio-modal-outlet></indicio-modal-outlet>
<ng-container *ngIf="!reload">
  <indicio-system-message></indicio-system-message>
  <main [ngClass]="{ 'logged-in': loggedIn$ | async }"
        [class]="'system-message-'+ systemMessagePosition || 'bottom'"
        data-test-id="app-main-container">
    <indicio-status></indicio-status>
    <indicio-debug-box *ngIf="debugEnabled"></indicio-debug-box>
    <div class="content-wrapper"
         (scroll)="setContentScroll($event)">
      <router-outlet></router-outlet>
      <ng-container [ngTemplateOutlet]="loader"
                    *ngIf="resolverLoading"></ng-container>
      <indicio-wiki-sidebar [scrollPosition]="scrollPosition"></indicio-wiki-sidebar>
    </div>
  </main>
  <indicio-portal name="outside"></indicio-portal>
  <indicio-navigation></indicio-navigation>

  <a [href]="'mailto:'+ companyService.supportEmail"
     class="support-email"
     indicioTippy="Email support"
     *ngIf="companyService.supportEmail">
    <i class="ion-chatboxes"></i>
  </a>

  <div class="mobile-view">
    <a href="{{ indicioLink }}"
       target="_blank">
      <indicio-icon-logo></indicio-icon-logo>
    </a>
    <br>
    <a href="{{ indicioLink }}"
       target="_blank">
      <indicio-text-logo style="fill: #333; stroke: #333"></indicio-text-logo>
    </a>
    <h1 class="dark light">Mobile view not yet supported.</h1>
  </div>
</ng-container>

<ng-template #loader>
  <div class="center-horizontal-and-vertical"
       style="flex-direction: column; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: rgba(0,0,0,0.3); z-index: 20;">
    <i class="ion-load-c spinner large-loader"></i> <br>
    <div>{{ resolverLoadingText || 'Loading...' }}</div>
  </div>
</ng-template>
