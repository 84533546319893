<div class="advanced-tab">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <ng-container [ngTemplateOutlet]="viewAccordion"></ng-container>
  </ng-container>
</div>

<ng-template #viewAccordion>
  <mat-accordion>
    <!-- Stationarity-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Stationarity
        </mat-panel-title>
        <mat-panel-description>
          Diagnostics and information about stationarity
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="diagnosticTable"></ng-container>
    </mat-expansion-panel>
    <!-- Transformations-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Transformations
        </mat-panel-title>
        <mat-panel-description>
          Configure how this variable is transformed
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="transformationTemplate"></ng-container>
    </mat-expansion-panel>
    <!-- Feature engineering-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Feature engineering
        </mat-panel-title>
        <mat-panel-description>
          Extract and configure features for this variable
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="featuresTemplate"></ng-container>
    </mat-expansion-panel>
    <!-- Calendar panel -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Exogeneity settings
        </mat-panel-title>
        <mat-panel-description>
          {{ variable.IsIndicator
          ? 'Configure how this variable is treated in the models, either as endogenous or exogenous'
          : 'These settings are not available for the main variable.'
          }}

        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="exoTemplate"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>


<ng-template #diagnosticTable>
  <div class="data-table"
       *ngIf="variable.DiagnosticInfos.length; else missingInfo"
       style="width: 730px; line-height: 14px; margin-left: -15px">
    <div class="data-table__header">
      <div class="column header left-align"
           style="width:110px;">Transformation</div>
      <div class="column header left-align"
           style="width: 74px;"
           indicioTippy="Kwiatkowski-Phillips-Schmidt-Shin (KPSS) tests the null hypothesis that a time series is level stationary.">
        KPSS Level (P-value)
      </div>
      <div class="column header left-align"
           style="width: 74px;"
           indicioTippy="Kwiatkowski-Phillips-Schmidt-Shin (KPSS) Trend tests the null hypothesis that a time series is stationary around a deterministic trend.">
        KPSS Trend (P-value)
      </div>
      <div class="column header left-align"
           style="width: 78px;"
           indicioTippy="Augmented Dickey-Fuller (ADF) tests the null hypothesis that a unit root is present in a time series. The alternative hypothesis is that the time series is stationary.">
        ADF <br>(P-value)
      </div>
      <div class="column header left-align"
           style="width: 131px;"
           indicioTippy="By combining the test results, different conclusions about the stationarity of a time series can be made. <br> Significance level 0.01.">
        Conclusion, &alpha;=0.01</div>
      <div class="column header left-align"
           style="width: 131px;"
           indicioTippy="By combining the test results, different conclusions about the stationarity of a time series can be made. <br> Significance level 0.05.">
        Conclusion, &alpha;=0.05</div>
      <div class="column header left-align"
           style="width: 131px;"
           indicioTippy="By combining the test results, different conclusions about the stationarity of a time series can be made. <br> Significance level 0.1.">
        Conclusion, &alpha;=0.1</div>
    </div>
    <div class="data-table__body"
         [style.maxHeight.px]="301">
      <ng-container *ngFor="let diagnostic of variable.DiagnosticInfos;">
        <ng-container *ngTemplateOutlet="diagnosticRow; context: { diagnostic } ">
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #missingInfo>
  <div class="row">
    <div class="col-xs-12">
      No stationarity diagnostics available. Wait for data processing to complete.
    </div>
  </div>
</ng-template>

<ng-template #diagnosticRow
             let-diagnostic="diagnostic">
  <ng-container>
    <div class="body__row body__row--center-height body__row--max-height-30">
      <div class="column left-align"
           style="width: 110px;">
        {{ diagnostic.transformation.Display }}
      </div>
      <div class="column left-align"
           style="width: 74px;"
           indicioTippy="KPSS Level: {{ diagnostic.KpssLevelStatistic }} <br> P-Value: {{ diagnostic.KpssLevelPvalue }}">
        {{ diagnostic.KpssLevelStatistic | propertyValue }} ({{ diagnostic.KpssLevelPvalue | propertyValue }})
      </div>
      <div class="column left-align"
           style="width: 74px;"
           indicioTippy="KPSS Trend: {{ diagnostic.KpssTrendStatistic }} <br> P-Value: {{ diagnostic.KpssTrendPvalue }}">
        {{ diagnostic.KpssTrendStatistic | propertyValue }} ({{ diagnostic.KpssTrendPvalue | propertyValue }})
      </div>
      <div class="column left-align"
           style="width: 78px;"
           indicioTippy="ADF: {{ diagnostic.AdfStatistic }} <br> P-Value: {{ diagnostic.AdfPvalue }}">
        {{ diagnostic.AdfStatistic | propertyValue }} ({{ diagnostic.AdfPvalue | propertyValue }})
      </div>
      <div class="column left-align"
           style="width: 131px;">{{ conclusionType[diagnostic.ConclusionAlpha1].Display }}
      </div>
      <div class="column left-align"
           style="width: 131px;">{{ conclusionType[diagnostic.ConclusionAlpha5].Display }}
      </div>
      <div class="column left-align"
           style="width: 131px;">{{ conclusionType[diagnostic.ConclusionAlpha10].Display }}
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #transformationTemplate>

  <!-- Variable -->
  <indicio-modal-option-row optionTitle="Variable transformation"
                            optionSubtitle="Transform the variable, essentially creating a new variable."
                            textWidth="7"
                            optionsWidth="5"
                            [disabled]="isTrend">

    <indicio-variable-transform-dropdown style="display: block; margin-top: 10px"
                                         [tippy]="isTrend ? 'Setting not available for trend variables.' : null"
                                         [required]="true"
                                         [disabled]="isTrend"
                                         [transformations]="variableTransforms"
                                         [currentTransform]="sVarTransform"
                                         [periodicity]="variable.periodicity"
                                         (selectedTransformEvent)="selectedSVarTransform($event)">
    </indicio-variable-transform-dropdown>
  </indicio-modal-option-row>


  <div *ngIf="sVarTransform.Transformation !== 'no_transform' && warningReasons.HasMissing"
       class="red">
    This will increase the missing values count.
  </div>

  <div *ngIf="sVarTransform.isGrowth && warningReasons.HasZeroes"
       class="red">
    The data series contains one or more zeros and will be interpreted as missing.
  </div>

  <!-- Model -->
  <indicio-modal-option-row optionTitle="Model transformation"
                            optionSubtitle="Transformation used while building models."
                            textWidth="7"
                            optionsWidth="5">

    <div class="grid-system"
         style="--grid-template: 1fr; margin-top: 10px;">
      <mat-form-field>
        <mat-label>Transformation</mat-label>
        <mat-select [value]="fVarTransform"
                    (selectionChange)="setFVarTransform($event.value)">
          <mat-option [value]="trans.Value"
                      *ngFor="let trans of modelTransforms">{{ trans.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>

  <!-- Save button -->
  <div class="pull-right"
       style="width:fit-content; margin-top: 25px;">
    <button mat-raised-button
            type="button"
            color="primary"
            [disabled]="!transformChanged"
            (click)="saveTransformation()">
      <span *ngIf="!saveInProgress">Save changes</span>
      <span *ngIf="saveInProgress"
            class="ion-load-c spinner"></span>
    </button>
  </div>
</ng-template>

<ng-template #exoTemplate>

  <!-- Model selection -->
  <indicio-modal-option-row optionTitle="Exogenous"
                            optionSubtitle="An exogenous variable is one whose value is determined outside the model and is imposed on the model"
                            textWidth=8
                            optionsWidth=4>
    <mat-slide-toggle style="margin-top:35px"
                      color="accent"
                      [indicioTippy]="exoDisabled ? 'This variable cannot be exogenous.' : null"
                      [checked]="isExogenous"
                      [disabled]="exoDisabled"
                      (change)="toggleIsExogenous()">
    </mat-slide-toggle>
  </indicio-modal-option-row>

  <indicio-step-ahead style="display: block; margin-top: 15px;"
                      [variable]="stepAhead"
                      [disabled]="!isExogenous"
                      (changedEvent)="exoValuesChanged()">
  </indicio-step-ahead>

  <div style="display: flex; justify-content: space-between; margin-top: 25px;">
    <span>
      <button mat-stroked-button
              [indicioTippy]="'Reset the values to the previously saved state.'"
              [disabled]="!isExogenous || !exoNumbersChanged"
              type="button"
              (click)="restoreExoValues(false)">
        Undo changes
      </button>
      <button style="margin-left: 5px"
              mat-stroked-button
              [indicioTippy]="'Restore the originally known values from the data source.'"
              [disabled]="!isExogenous"
              type="button"
              (click)="restoreExoValues(true)">
        Restore from source
      </button>
    </span>
    <span [indicioTippy]="exoNumbersValid
          ? null
          : 'Indicio does not yet support partial exogeneity - You must enter values for all dates.'">
      <button mat-raised-button
              type="button"
              color="primary"
              [disabled]="!exoSettingsChanged || !exoNumbersValid"
              (click)="saveExoSettings()">
        <span *ngIf="!saveInProgress">Save changes</span>
        <span *ngIf="saveInProgress"
              class="ion-load-c spinner pull-right"></span>
      </button>
    </span>
  </div>
</ng-template>

<ng-template #featuresTemplate>
  <div class="features-container">
    <div class="intro-text">
      Feature engineering: By adding copies of the same variable, but with different transformations,
      it is sometimes possible to extract more informations from a variable.
    </div>

    <indicio-modal-option-row style="margin-top: -5px; display: block;"
                              optionTitle="Active transformations"
                              optionSubtitle="The following transformations are currently active for this variable."
                              textWidth="6"
                              optionsWidth="6">
      <div class="transforms-list">
        <div class="scroll-content">
          <div *ngFor="let currentTrans of currentFeatures"
               class="list-entry">
            <span>
              {{ currentTrans.Display.Display }}
              {{ currentTrans.Trans.getArgString() }}
            </span>
          </div>
          <div *ngFor="let trans of selectedFeatures; let i = index"
               class="list-entry">
            <span>
              {{ trans.Display }}
              {{ trans.getArgString() }}
            </span>
            <span>
              <i class="ion-trash-b pointer"
                 style="color:#E26464; font-size: 18px"
                 (click)="removeFeature(i)">
              </i>
            </span>
          </div>
        </div>
      </div>

      <div *ngIf="!addNewFeatureOpen"
           class="add-another-feature">
        <span (click)="addNewFeature()"><i class="ion-plus"></i> Add another transformation</span>
      </div>

      <div style="margin-top: -10px; padding: 1px;"
           *ngIf="addNewFeatureOpen">
        <indicio-variable-transform-dropdown [tippy]="isTrend ? 'Setting not available for trend variables.' : null"
                                             [disabled]="isTrend"
                                             [currentTransform]="nextFeature"
                                             [transformations]="variableTransforms"
                                             [periodicity]="variable.periodicity"
                                             (selectedTransformEvent)="selectFeature($event)">
        </indicio-variable-transform-dropdown>
        <div class="add-transform">
          <span *ngIf="canAddFeature"><!-- Empty --></span>
          <span *ngIf="!canAddFeature"
                class="red warning-text">
            Cannot add duplicate transforms
          </span>
          <span [ngClass]="{ disabled: !canAddFeature || !nextFeature }"
                class="add-button"
                (click)="addFeature()">
            <i class="ion-plus-round"></i>
            Add
          </span>
        </div>
      </div>

    </indicio-modal-option-row>

    <!-- Button row, the margin-bottom stops the scroll from appearing in the dialog and only in the list -->
    <div style="display: flex; justify-content: space-between; margin-top: 20px; margin-bottom: -2px;">

      <button mat-stroked-button
              [indicioTippy]="'Reset the values to the previously saved state.'"
              [disabled]="selectedFeatures.length < 1"
              type="button"
              (click)="resetFeatures()">
        Reset features
      </button>
      <button mat-raised-button
              [indicioTippy]="'This will create ' + selectedFeatures.length + ' new forecast variables.' "
              [disabled]="selectedFeatures.length < 1"
              (click)="saveFeatures()"
              type="button"
              color="primary">
        <div>
          <indicio-spinner *ngIf="saveInProgress"
                           [size]="14"></indicio-spinner>
          Save features
        </div>
      </button>
    </div>
  </div>

</ng-template>

<ng-template #loadingTemplate>

</ng-template>
