import { Periodicity } from '../types/periodicity';


export enum Period {
  day = 'day',
  weekday = 'weekday',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  halfyear = 'halfyear',
  year = 'year'
}

export type PeriodicityType = 'day' | 'week' | 'weekday' | 'month' | 'quarter' | 'halfyear' | 'year';

export const Periodicities = [
  new Periodicity('day', 'Daily', 'A Daily period'),
  new Periodicity('weekday', 'Weekdaily', 'A non weekend daily period'),
  new Periodicity('week', 'Weekly', 'A Weekly period'),
  new Periodicity('month', 'Monthly', 'A Monthly period'),
  new Periodicity('quarter', 'Quarterly', 'A Quarterly period'),
  new Periodicity('halfyear', 'Half-yearly', 'A Half-yearly period'),
  new Periodicity('year', 'Yearly', 'A Yearly period')
];
