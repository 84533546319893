<div>
  <input [checked]="state === 'light'"
         (change)="toggleState()"
         type="checkbox"
         class="checkbox"
         id="checkbox">
  <label for="checkbox"
         class="checkbox-label">
    <i class="fas fa-moon"></i>
    <i class="fas fa-sun"></i>
    <span class="ball"></span>
  </label>
</div>
