import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';

import { RemoteDataSourceDTO } from '@core/store/providers/dtos/remote-data-source.dto';
import { NewRemoteDataSourceModelModel } from '@core/store/providers/models/new-remote-data-source-model.model';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { RemoteDataMapper } from '@core/store/providers/remote-data.mapper';
import { SourceVariableDTO } from '@core/store/source-variable/dtos/source-variable-dto';
import { GetSourceVariableSuccessAction } from '@core/store/source-variable/source-variable.actions';
import { SourceVariableMapper } from '@core/store/source-variable/source-variable.mapper';
import { Store } from '@ngxs/store';
import { CompanyFrontendService } from '../company/company.frontend.service';
import { ExistingRemoteRequestDTO, ExistingRemoteVarInfoDTO } from './dtos/existing-remote-varinfo.dto';
import { RemoteDataRequestDTO } from './dtos/remote-data-request-dto';
import { UpdateRemoteDataSourceDTO } from './dtos/remote-data-source.dto';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(
    private http: HttpService,
    private store: Store,
    private remoteDataMapper: RemoteDataMapper,
    private sourceVariableMapper: SourceVariableMapper,
    private companyService: CompanyFrontendService
  ) { }

  public addVariableFromDatasource(companyId: string, model: RemoteDataRequestModel) {
    const dto = this.remoteDataMapper.toRequestDto(model);
    return this.addVariableFromDatasourceV2(companyId, dto);
  }

  public addVariableFromDatasourceV2(companyId: string, dto: RemoteDataRequestDTO) {
    return this.http.post<SourceVariableDTO>(`company/${companyId}/remotevariables/request`, dto)
      .then(resp => {
        const sourceVariable = this.sourceVariableMapper.map(resp.body);
        this.store.dispatch(new GetSourceVariableSuccessAction(sourceVariable));
        return sourceVariable;
      });
  }

  public getExistingRVarInformation(dto: ExistingRemoteRequestDTO) {
    return this.http.post<ExistingRemoteVarInfoDTO[]>(`company/${this.companyService.activeCompanyId}/remote-variables/existing-info`, dto).then(resp => resp.body);
  }

  public getRemoteSources() {
    return this.http.get<RemoteDataSourceDTO[]>('my/remotesources')
      .then(data => data.body.map(source => this.remoteDataMapper.toRemoteDataSourceDetailModel(source)));
  }

  public addDataSource(dataSource: NewRemoteDataSourceModelModel) {
    const dto = this.remoteDataMapper.toNewDataSourceDto(dataSource);
    return this.http.post<RemoteDataSourceDTO>('my/remotesources', dto)
      .then(data => this.remoteDataMapper.toRemoteDataSourceDetailModel(data.body));
  }

  public editDataSource(dataSourceId: string, dto: UpdateRemoteDataSourceDTO) {
    return this.http.put<RemoteDataSourceDTO>(`my/remotesources/${dataSourceId}`, dto)
      .then(data => this.remoteDataMapper.toRemoteDataSourceDetailModel(data.body));
  }
}
