<h1 mat-dialog-title>{{ data.Dto.Title }}</h1>
<div mat-dialog-content>
  {{ data.Dto.Message }}
  <ul *ngIf="data.Dto.Extras?.length"
      style="margin-top: 20px;">
    <li *ngFor="let line of data.Dto.Extras">
      {{ line }}
    </li>
  </ul>
</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-flat-button
          (click)="save()">
    Ok
  </button>
</div>
