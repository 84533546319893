import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { GetRemoteDataSourceSuccessAction } from '@core/store/client/client.actions';
import { RemoteFileDTO } from '@core/store/file/dtos/file-dto';
import { FileUsageCollectionDTO } from '@core/store/file/dtos/used-file-dto';
import { FileMapper } from '@core/store/file/file.mapper';
import { RemoteFileModel } from '@core/store/file/models/remote-file.model';
import { RemotePathEntryDTO } from '@core/store/providers/dtos/remote-data-path-entry.dto';
import { RemoteDataSourceDTO, UpdateRemoteDataSourceDTO } from '@core/store/providers/dtos/remote-data-source.dto';
import { NewRemoteDataSourceModelModel } from '@core/store/providers/models/new-remote-data-source-model.model';
import { RemoteDataMapper } from '@core/store/providers/remote-data.mapper';
import { Store } from '@ngxs/store';


@Injectable({
  providedIn: 'root'
})
export class SharepointService {

  constructor(
    private http: HttpService,
    private mapper: FileMapper,
    private store: Store,
    private remoteDataMapper: RemoteDataMapper
  ) { }

  public getTenantId(org: string) {
    return this.http.get<string>(`remote/sharepoint/get-tenant-id/${org}`)
      .then(res => res.body);
  }

  public addSharepointSite(dto: NewRemoteDataSourceModelModel) {
    return this.http.post<RemoteDataSourceDTO>('remote/sharepoint/add-provider', dto)
      .then(res => {
        const model = this.remoteDataMapper.toRemoteDataSourceDetailModel(res.body);
        this.store.dispatch(new GetRemoteDataSourceSuccessAction(model));
        return res.body;
      });
  }

  public removeConnection(sourceId: string) {
    return this.http.get<string>(`remote/sharepoint/${sourceId}/remove-connection`)
      .then(res => res.body);
  }

  public remove(RemoteDataSourceId: string) {
    return this.http.get<string>(`remote/sharepoint/${RemoteDataSourceId}/remove`)
      .then(res => res.body);
  }

  public getUsage(sourceId: string, companyId: string) {
    return this.http.get<FileUsageCollectionDTO>(`remote/sharepoint/${sourceId}/${companyId}/get-usage`)
      .then(res => res.body);
  }

  public getFilesAttachedToSharepoint(sourceId: string, companyId: string) {
    return this.http.post<RemoteFileModel[]>(`remote/sharepoint/${sourceId}/${companyId}/get-files`, {})
      .then(res => res.body);
  }

  public updateSharepointSite(dto: UpdateRemoteDataSourceDTO) {
    return this.http.put<RemoteDataSourceDTO>(`remote/sharepoint/${dto.RemoteDataSourceId}/update-provider`, dto)
      .then(res => {
        const model = this.remoteDataMapper.toRemoteDataSourceDetailModel(res.body);
        this.store.dispatch(new GetRemoteDataSourceSuccessAction(model));
        return res.body;
      });
  }

  public resyncDownloadedFile(file: RemoteFileModel) {
    return this.http.post<RemoteFileDTO>(`remote/sharepoint/files/${file.getModelId()}/update-file`, null)
      .then(res => this.mapper.mapRemote(res.body))
      ;
  }

  public downloadFile(sourceId: string, dto: RemotePathEntryDTO) {
    return this.http.post<RemoteFileDTO>(`remote/sharepoint/${sourceId}/download-file`, dto)
      .then(res => this.mapper.mapRemote(res.body));
  }

  public getTreeNodes(sourceId: string, path: RemotePathEntryDTO) {
    return this.http.post<RemotePathEntryDTO[]>(`remote/sharepoint/${sourceId}/get-content`, { path: path.Path })
      .then(res => {
        const body = res.body;
        const folders = body.filter(x => x.RemoteEntryType === 'folder');
        const files = body.filter(x => x.RemoteEntryType === 'file');
        path.entries = files;
        path.SubPaths = folders;
        path.SubPaths.forEach(f => f.parent = path);
        return { files, folders };
      });
  }

  public searchContent(_sourceId: string, _search: string) {
    return Promise.resolve([]);
  }
}
