export class EmployeeModel {
  ClientId: string = null;
  Email: string = null;
  FirstName: string = null;
  LastName: string = null;
  CompanyPosition: string = null;
  CompanyRole: string = null;
  Joined: Date;
  Disabled: boolean = false;
  SharedProjectRole: string = null;
  PendingSignup: boolean = null;
  CreatedDate: Date = null;
  Expires: Date = null;

  twoFactorEnabled: boolean = false;
  public FullName() { return this.FirstName + ' ' + this.LastName; }
}
