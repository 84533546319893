<ng-container *ngTemplateOutlet="globalOptions"></ng-container>
<ng-container *ngTemplateOutlet="variableList"></ng-container>
<ng-container *ngTemplateOutlet="buttons"></ng-container>

<ng-template #globalOptions>
  <div class="row"
       style="margin-top: -20px;">
    <div class="col-xs-7">
      <span class="input-label bold">Visibility</span>
      <div class="subtitle">Select visibility for all new source variables</div>
    </div>
    <div class="col-xs-5 margin-top">
      <mat-form-field>
        <mat-select [(value)]="selectedVisibilityLevel">
          <mat-option [value]="level.Value"
                      [title]="level.Display"
                      *ngFor="let level of envService.VisibilityLevels">
            {{ level.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row"
       style="margin-top: -20px;">
    <div class="col-xs-7">
      <span class="input-label bold">Add to forecast</span>
      <div class="subtitle">Add all new source variables to the current forecast</div>
    </div>
    <div class="col-xs-5 margin-top col-no-padding">
      <div class="col-xs-4">
        <mat-slide-toggle color="accent"
                          class="margin-top"
                          [checked]="addToForecast"
                          (change)="toggleAddToForecast()">
        </mat-slide-toggle>
      </div>
      <div class="col-xs-8"
           *ngIf="showHideImported">
        <div style="margin-top: 15px; display: inline-block;"
             class="bold">Hide imported</div>
        <mat-slide-toggle color="accent"
                          style="float:right;"
                          class="margin-top"
                          [checked]="hideImported"
                          (change)="toggleHideImported()">
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #variableList>
  <div class="row extra-margin-top">
    <div class="col-xs-12 bold text-center">Variables to import ({{ valueColumns.length - valueColumnsToShow.length }}
      hidden column(s))</div>
  </div>
  <div class="row variable-row vertical-align"
       style="position: relative;">
    <div *ngIf="sheetLoading"
         class="indicio-fullsize-loader-backdrop light">
      <div class="indicio-fullsize-loader white"
           style="top: 75px;">
        <i class="ion-load-c spinner"></i>
        <span class="title">Loading...</span>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="data-table">
        <div class="data-table__header">
          <div class="column header"
               style="width: 40px;">Column</div>
          <div class="column header"
               [style]="!mainVariableExists ? 'width: 246px;' : 'width: 360px;'">Name</div>
          <div *ngIf="!mainVariableExists"
               class="column header pointer"
               style="width: 120px;">Main Variable</div>
          <div class="column header pointer dotted-underline"
               style="width: 60px;"
               (click)="toggleAllImport()"><span indicioTippy="Toggle all columns">Import</span></div>
          <div class="column header"
               style="width: 110px;">Aggregation</div>
          <div class="column header"
               style="width: 70px;">Status</div>
        </div>
        <div class="data-table__body big">
          <div class="body__row body__row--center-height body__row--max-height-30"
               [ngStyle]="{ 'backgroundColor': !toImport.includes(variable.columnIndex) ? 'var(--indicio-button-disabled-background-color, lightgrey)' : null }"
               *ngFor="let variable of valueColumnsToShow">
            <div class="column"
                 style="width: 40px;"
                 [indicioTippy]="'Column ' + variable.columnIndex">{{ variable.columnIndex }}</div>

            <ng-container *ngIf="!variable.IsValidValueColumn">
              <div class="column"
                   [style]="!mainVariableExists ? 'width: 246px;' : 'width: 360px;'">
                <div class="row">
                  <div class="text-left crop-string"
                       [style.maxWidth.px]="360"
                       [ngClass]="'col-xs-10'"
                       [title]="variable.name">
                    <span
                          [ngClass]="{italic: variable.name == null || variable.name.length === 0, red: variable.name == null || variable.name.length === 0}">{{
                      variable.name == null || variable.name.length === 0 ? 'Name not found' : variable.name
                      }}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="!mainVariableExists"
                   class="column"
                   style="width: 120px;">

              </div>
              <div class="column"
                   style="width: 60px;">

              </div>
              <div class="column"
                   style="width: 110px;">

              </div>
              <div class="column"
                   style="width: 70px;">
                {{variable.failedMsg}}
                {{variable.warningMsg}}
                <span class="red"
                      [indicioTippy]="variable.failedMsg
                        ? variable.failedMsg
                        : (variable.warningMsg
                          ? variable.warningMsg
                          : (!variable.IsValidValueColumn
                            ? variable.Message?.length
                              ? variable.Message
                              : 'Column values could not be uploaded.' + (valueColumnsToShow.length > 1
                                ? ' Try to upload data in a separate file'
                                : '')
                            : 'Column is not valid'))">
                  Invalid
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="variable.IsValidValueColumn">
              <div class="column"
                   [style]="!mainVariableExists ? 'width: 246px;' : 'width: 360px;'">
                <div class="row"
                     *ngIf="!variable.editName">
                  <div class="text-left crop-string"
                       [style.maxWidth.px]="!variable.nameError ? 360 : null"
                       [ngClass]="variable.nameError ? 'col-xs-6' : 'col-xs-10'">
                    {{ variable.name }}
                  </div>
                  <div class="text-right"
                       [ngClass]="variable.nameError ? 'col-xs-6' : 'col-xs-1'">
                    <ng-container *ngIf="variable.nameError">
                      <span class="red italic">{{ variable.nameError }}</span>
                    </ng-container>
                    <i class="ion-edit edit-pen cursor-pointer"
                       *ngIf="!variable.editName"
                       [attr.data-test-id]="variable.name + '-edit-variable-name'"
                       (click)="variable.editName = true"></i>
                  </div>
                </div>
                <ng-container *ngIf="variable.editName">
                  <div class="flex-row center-vertical">
                    <mat-form-field *ngIf="variable.editName">
                      <input type="text"
                             minlength="2"
                             maxlength="256"
                             matInput
                             [ngModel]="variable.name"
                             (change)="setVariableName($event, variable)"
                             [attr.data-test-id]="variable.name + '-file-input-name'"
                             class="material"
                             (keyup.enter)="variable.editName = !variable.editName">
                    </mat-form-field>
                    <div class="pull-right">
                      <fa-icon class="blue margin-left-10 cursor-pointer edit-pen"
                               [icon]="faSave"
                               (click)="variable.editName = false"></fa-icon>

                    </div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="!mainVariableExists"
                   class="column"
                   style="width: 120px;">
                <mat-radio-group [ngClass]="{disabled: this.pending}">
                  <mat-radio-button (click)="selectMainVariable(variable)"
                                    [checked]="variable.mainVariable"
                                    [value]="false"></mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="column"
                   style="width: 60px;">
                <mat-slide-toggle color="accent"
                                  (change)="toggleImport(variable.columnIndex)"
                                  [checked]="toImport.includes(variable.columnIndex)">
                </mat-slide-toggle>
              </div>
              <div class="column"
                   style="width: 110px;">
                <mat-form-field>
                  <mat-select [(value)]="variable.aggregationId"
                              panelClass="data-table-panel-opts"
                              required>
                    <mat-option [value]="option.Value"
                                [title]="option.Description"
                                *ngFor="let option of envService.AggregationMethods">{{ option.Display }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="column"
                   style="width: 70px;"
                   [indicioTippy]="variable.used
               ? 'Variable has been imported'
               : variable.status === 'Pending' ? 'Pending upload' : null">
                <ng-container *ngIf="toImport.includes(variable.columnIndex) || variable.used; else skipped">
                  <ng-container *ngIf="variable.status === 'Failed'; else status">
                    <span class="red"
                          [indicioTippy]="variable.failedMsg">Failed</span>
                  </ng-container>
                  <ng-template #status>
                    <ng-container *ngIf="variable.status === 'Warning'; else realStatus">
                      <span class="red"
                            indicioTippy="Import to forecast successful.<br><br>Warning: {{ variable.warningMsg }}">Warning</span>
                    </ng-container>
                    <ng-template #realStatus>
                      {{ variable.status }}
                    </ng-template>
                  </ng-template>
                </ng-container>
                <ng-template #skipped>
                  Skipped
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="row extra-margin-top">
    <div class="col-xs-9 italic">
      Note: If you need more advanced settings, use <span class="dotted-underline pointer"
            (click)="gotoSheet()">single column import</span>.
    </div>
    <div class="col-xs-3 text-right">
      <ng-container *ngIf="pending || importedCount !== 0">
        Imported: {{importedCount }}/{{ toImport?.length}}
      </ng-container>
      <ng-container *ngIf="!pending && importedCount === 0">
        Selected: {{ toImport?.length }}/{{ valueColumns?.length }}
      </ng-container>
    </div>
  </div>
</ng-template>
