import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'filterData' })
export class FilterDataPipe implements PipeTransform {
  transform(items: any[], filters: {}, _trigger): any {
    let filteredList = items;

    Object.keys(filters).forEach(key => {
      let value = filters[key];

      if (key === 'usedInForecast' || key.toLowerCase() === 'used') {
        if (value === true) {
          value = undefined;
        }
      }

      if (filters['ObjectName'] && filters['ObjectName'].length > 0) {
        const list = filteredList.length ? filteredList : items;
        filteredList = list.filter(item => new RegExp(filters['ObjectName'], 'gi').test(item['ObjectName']));
      }

      if (filters['OwnerEmail'] && filters['OwnerEmail'].length > 0) {
        filteredList = filteredList.filter(item => item['OwnerEmail'] === filters['OwnerEmail']);
      }

      if (Array.isArray(value)) {
        if (value.length > 0) {
          value.forEach(v => {
            filteredList.push(...items
              .filter(item => item[key] === v)
              .filter(item => filteredList.findIndex(fi => fi.ObjectName === item.ObjectName) === -1)
            );
          });
        } else {
          filteredList.push(...items.filter(item => filteredList.findIndex(fi => fi.ObjectName === item.ObjectName) === -1));
        }
      } else {
        if (typeof value === 'boolean') {
          filteredList = filteredList.filter(item => item[key] === value);
        } else if (key !== 'OwnerEmail' && typeof value === 'string' && value.length) {
          filteredList = filteredList.filter(item => new RegExp(value, 'gi').test(item[key]));
        }
      }
    });

    if (!this.containsValues(filters)) {
      filteredList = items;
    }


    return filteredList;
  }

  private containsValues(object) {
    let contains = false;
    Object.values(object).forEach(value => {
      if (Array.isArray(value) && value.length > 0) {
        contains = true;
      } else if (typeof value === 'boolean') {
        contains = true;
      } else if (typeof value === 'string' && String(value).length > 0) {
        contains = true;
      }
    });
    return contains;
  }
}
