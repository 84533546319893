<div class="company-create-options-tab">
  <h3 class="extra-margin-top margin-bottom-20">
    Set trial status and expiration date
    <div class="subtitle">If the company is running a trial, set the expiration date here.
    </div>
  </h3>


  <indicio-modal-option-row optionTitle="Trial status"
                            optionSubtitle="Is the company running a trial"
                            textWidth="7"
                            optionsWidth="5">
    <div style="margin-top: 25px;">
      <mat-slide-toggle [checked]="company.Trial"
                        (change)="toggleTrial()">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Trial expiration date"
                            [optionSubtitle]="company.Trial ? 'Date when trial expires' : 'Only applicable to trials'"
                            textWidth="7"
                            optionsWidth="5">
    <div style="margin-top: 25px;">
      <indicio-date-picker periodicity="day"
                           [value]="trialExpirationDate"
                           [disabled]="!company.Trial"
                           [min]="minDate"
                           (selectDateEvent)="setDate($event)"
                           placeholder="Choose a date"></indicio-date-picker>
    </div>
  </indicio-modal-option-row>
</div>
