import { Injectable } from '@angular/core';
import { UtilsFrontendService } from '@core/services/frontend/utils.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { FileColumnUsageDTO } from '@core/store/file/dtos/file-column-usage-dto';
import { VariableSheetDTO } from '@core/store/file/dtos/variable-sheet-dto';
import { FileFrontendService } from '@core/store/file/file.frontend.service';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { CreateSourceVariableFromFileDTO } from '@core/store/source-variable/dtos/create-source-variable-from-file-dto';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceTypesType } from '@modules/lang/language-files/source-types';
import { Store } from '@ngxs/store';
import { SetDropdownVisibility } from './sheet-dropdown/sheet-dropdown.actions';
import { SheetActions } from './sheet.actions';
import { ColumnType, Sheet, SheetColumn, SheetError } from './types/types';

@Injectable({
  providedIn: 'root'
})
export class SheetService {

  public get containsErrors() { return Object.values(this.errors).filter(e => e !== null).length > 0; }
  public get loading() { return this.sheetLoading || !this.setupComplete; }
  public get errorMessages() { return ''; }
  public get paged() { return this.currentSheet?.Columns?.length || 0 > this.visibleColumnCount; }
  public get pageCount() { return Math.ceil(this.currentSheet?.Columns.length / this.visibleColumnCount); }

  public setupComplete: boolean = false;
  public sheetLoading: boolean = true;

  public currentSheet: Sheet;
  public errors: SheetError;

  public sheets: VariableSheetDTO[] = [];
  public file: UploadedFileModel = null;
  public ftpData: any = null;
  public Variable: SourceVariableVersionModel = null;
  public Variables: SourceVariableVersionModel[];
  public parsedSheets: Sheet[] = [];
  public usage: FileColumnUsageDTO;

  public minSheetIndex: number = 0;
  public sheetIndex: number = null;
  public maxRowCount: number = 100;

  public idColumn: Number;
  public activeIds: string[];

  public containsDuplicateDates: boolean;
  public sheetContainsDateAndValues: boolean;

  constructor(
    private store: Store,
    private status: StatusService,
    private sourcevariableService: SourceVariableFrontendService,
    private utilsService: UtilsFrontendService,
    private fileService: FileFrontendService,
    private clientService: ClientFrontendService
  ) { }

  visibleColumnCount = 9;

  public async setupFile(fileId: string, type: SourceTypesType) {
    const resolverData = await this.fileService.getFileData(fileId, type);
    this.sheets = resolverData.sheets;
    this.ftpData = resolverData.ftpData;
    this.file = resolverData.file as UploadedFileModel;
    this.usage = resolverData.usage;
    this.reset();
    this.toggleSheet(this.minSheetIndex);
  }

  public toggleSheet(idx) {
    this.sheetLoading = true;
    setTimeout(() => {
      this.changeToSheet(idx);
      setTimeout(() => {
        this.sheetLoading = false;
        this.store.dispatch(new SheetActions.SheetChanged());
      }, 10);
    }, 0);
  }

  public setupSheets() {
    this.clearErrors();
    this.setupComplete = false;
    this.parsedSheets = this.sheets.map(s => new Sheet(s));
    this.maxRowCount = Math.max(...this.parsedSheets.map(s => s.maxRowCount));
    this.minSheetIndex = Math.min(...this.parsedSheets.map(x => x.SheetIndex));
    this.changeToSheet(this.minSheetIndex)
      .then(() => {
        this.setupComplete = true;
        setTimeout(() => {
          const colToOpen = this.shouldAutoOpenDropdown();
          if (colToOpen > -1) {
            this.store.dispatch(new SetDropdownVisibility(true, colToOpen));
          }
          this.store.dispatch(new SheetActions.ViewInitialized());
        }, 25);
      });
  }

  public syncDisabledRows(col: SheetColumn) {
    return this.utilsService.getDisabledRowsForDateColumn(col.DateFormat, col.Raw)
      .then(info => {
        col.DisabledRows = info.DisabledRows;
        col.Message = info.Message;
        col.IsValidDateColumn = info.IsValidDateColumn;
        col.IsValidValueColumn = info.IsValidValueColumn;
      })
      .catch(err => this.status.setError(err));
  }

  public setSheetContainsDateAndValues() {
    const hasDates = this.currentSheet.getColumnByType('Dates') != null;
    const hasValues = this.currentSheet.getColumnByType('Values') != null;
    this.sheetContainsDateAndValues = hasDates && hasValues;
  }

  public isSheetImportable(sheetNum: number) {
    const sheet = this.parsedSheets.find(x => x.SheetIndex === sheetNum);
    if (!sheet) { return false; }
    const hasDates = sheet.getColumnByType('Dates') != null;
    const hasValues = sheet.getColumnByType('Values') != null;
    return hasDates && hasValues;
  }

  public checkNameConflict(columnInfo: SheetColumn) {
    const namesOnSheet = this.currentSheet.Columns
      .filter(x => x.ColumnType !== 'Dates')
      .filter(x => x.columnIndex !== columnInfo.columnIndex).map(x => x.name);
    if (this.sourcevariableService.sourceVariables.findIndex(x => x.Name === columnInfo.name) !== -1
      || namesOnSheet?.includes(columnInfo.name)) {
      return 'Name conflict';
    } else if (!columnInfo.name || columnInfo.name.length < 1) {
      return 'Name required';
    } else if (columnInfo.name.length > 126) {
      return 'Name too long';
    }
    return undefined;
  }

  public shouldAutoOpenDropdown() {
    const hasDates = this.currentSheet.getColumnByType('Dates') != null;
    const hasValues = this.currentSheet.getColumnByType('Values') != null;
    const hasMinValues = this.currentSheet.getColumnByType('MinValues') != null;
    const hasMaxValues = this.currentSheet.getColumnByType('MaxValues') != null;
    const colCount = this.currentSheet.Columns.length;
    if (colCount === 2 && hasDates && hasValues) {
      return this.currentSheet.getColumnByType('Values').columnIndex;
    } else if (colCount === 3 && hasDates && hasMinValues && hasMaxValues) {
      return this.currentSheet.getColumnByType('MaxValues').columnIndex;
    }
    return -1;
  }

  public async changeToSheet(index: number) {
    this.currentSheet = this.parsedSheets.find(x => x.SheetIndex === index);
    this.sheetIndex = index;
    if (!this.currentSheet) {
      return;
    }

    const firstDateCol = this.currentSheet.Columns.find(x => x.ColumnType === 'Dates' && x.IsValidDateColumn);
    if (firstDateCol) {
      if (!this.currentSheet.disabledRows) {
        try {
          await this.syncDisabledRows(firstDateCol);
          this.updateColumn({ type: 'Dates', index: firstDateCol.columnIndex });
          this.currentSheet.setDisabledRows();
        } catch (e) {
          this.status.setError(e);
        }
      } else {
        this.updateColumn({ type: 'Dates', index: firstDateCol.columnIndex });
        this.currentSheet.setDisabledRows();
      }
    }

    this.currentSheet.Columns.forEach(c => {
      c.nameError = this.checkNameConflict(c);
      if (this.usage) {
        const sheet = this.usage?.Sheets.find(x => x.SheetIndex === index);
        const columnUsage = sheet ? sheet.Columns.filter(uc => uc.Column === c.columnIndex) : [];
        c.used = columnUsage.length > 0;
        if (c.used) {
          c.status = 'Imported';
        }
      }
    });

    this.resetNewSourceVariable();
    this.activeIds = undefined;
    this.containsDuplicateDates = undefined;
    this.setSheetContainsDateAndValues();
  }

  public isRowDisabled(row: number) {
    return this.currentSheet?.disabledRows?.findIndex(r => r === row) !== -1;
  }

  public resetNewSourceVariable() {
    const variable = new CreateSourceVariableFromFileDTO();
    if (this.file !== null) {
      variable.Name = this.file.FileName.replace(/\.[^/.]+$/, '');
      variable.FileId = this.file.getModelId();
      variable.FileVersionId = this.file.latestVersion().getModelId();
      variable.SourceType = this.file.getSourceType();
      // variable.SourceType = 'file';
      variable.SheetIndex = this.currentSheet.SheetIndex;
      variable.DateIndex = this.currentSheet.Columns.findIndex(x => x.ColumnType === 'Dates');
    } else {
      variable.Name = this.ftpData.MetaData.PrimName.replace(/\.[^/.]+$/, '');
      // variable.SourceType = 'ftp';
    }
    return variable;
  }

  public updateColumn(event: any) {
    // eslint-disable-next-line prefer-const
    let { type, index } = event;
    this.currentSheet.Columns[index].ColumnType = type;
    if (type === 'Values') { return; }
    for (let i = 0; i < this.currentSheet.Columns.length; i++) {
      const curr = this.currentSheet.Columns[i];
      if (curr.ColumnType === type && curr.columnIndex !== index) {
        curr.ColumnType = 'NotUsed';
      }
    }
    if (type === 'Dates') {
      if (!this.currentSheet.Columns[index].DisabledRows) {
        this.syncDisabledRows(this.currentSheet.Columns[index])
          .then(() => {
            this.currentSheet.disableInvalidDateRows(index);
          });
      } else {
        this.currentSheet.disableInvalidDateRows(index);
      }
    }
    this.setSheetContainsDateAndValues();
  }

  private createSourceVariableFromColumnDTO(
    variable: CreateSourceVariableFromFileDTO,
    columnDTO: SheetColumn,
  ) {
    variable.VisibilityLevelId = 'private';
    const dateCol = this.findColumnType('Dates');
    if (dateCol) {
      variable.DateIndex = dateCol.columnIndex;
    }

    if (columnDTO.ColumnType === 'Values') {
      variable.ValueIndex = columnDTO.columnIndex;
    }
    variable.CompanyId = this.clientService.activeCompany.CompanyId;
    return variable;
  }

  public createSourceVariableFromFile(columnDTO: SheetColumn) {
    const variable = this.resetNewSourceVariable();
    return Promise.resolve(this.createSourceVariableFromColumnDTO(variable, columnDTO))
      .catch(err => {
        console.error(err);
        return variable;
      });
  }

  public setIdentifierColumn(column: Number) {
    this.idColumn = column;
  }

  public setActiveIdentifiers(identifiers?: string[]) {
    this.activeIds = identifiers || undefined;
    this.containsDuplicateDates = undefined;
  }

  public reset() {
    this.setupSheets();
  }

  public findColumnType(type: ColumnType) {
    return this.currentSheet.getColumnByType(type);
  }

  private clearErrors() {
    this.errors = {
      dateError: null,
      valueError: null,
      minError: null,
      maxError: null
    };
  }
}
