import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { CompanyModel } from '@core/store/company/company.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { SupportService } from '@core/store/support/support.service';
import { AuditLogDTO } from '@modules/admin/entities/audit-log.dto';
import { AuditLogSearchDTO } from '@modules/admin/entities/audit-search.dto';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DateUtils } from '@shared/utils/date.utils';


@Component({
  selector: 'indicio-support-dialog-audit-tab',
  templateUrl: './tab.audit.component.html',
  styleUrls: ['./tab.audit.component.less'],
})
export class SupportAuditTabComponent implements OnChanges {

  @Input() company: CompanyModel;

  private dto = new AuditLogSearchDTO;
  private loadMorePending: boolean;
  private lastLoadFrom: number;

  public loadAudits = false;
  public logs: AuditLogDTO[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private service: SupportService,
    public appearance: AppearanceService,
    public envService: EnvironmentService,
    private dialog: DialogService,
  ) {
  }

  public ngOnChanges() {
    if (!this.company) { return; }
    this.dto.CompanyId = this.company.CompanyId;
    this.resetSearch();
    this.getAuditLogs();
    this.cd.detectChanges();
  }

  private resetSearch() {
    this.dto.Skip = 0;
    this.dto.Take = 50;
    this.logs = [];
  }

  public openAuditInfo(log: AuditLogDTO) {
    const table = [['Key', 'Content'], ...Object.keys(log).map(k => ([k, log[k], '[noCrop]']))];
    this.dialog.openGenericTableDialog({
      Title: 'Audit information',
      GridTemplate: '140px 1fr',
      Table: table,
      Transpose: false
    }, { width: '500px', position: { top: '4%' } });
  }

  public getAuditLogs() {
    this.loadAudits = true;

    const dto = Object.assign({}, this.dto);

    if (dto.From && !dto.To) {
      dto.To = DateUtils.newMoment(dto.From).endOf('d').toDate();
    }

    this.service.getAuditLog(this.company.CompanyId, dto)
      .then(logs => {
        this.logs.push(...logs);
        this.dto.Skip += this.dto.Take;
      })
      .catch(err => { })
      .finally(() => {
        this.loadAudits = false;
        this.cd.detectChanges();
      });
  }

  async loadMore() {
    if (this.loadMorePending || this.lastLoadFrom === this.dto.Skip) {
      return;
    }

    this.loadMorePending = true;
    this.lastLoadFrom = this.dto.Skip;
    await this.getAuditLogs();

    this.loadMorePending = false;
  }
}
