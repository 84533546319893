<indicio-modal-base [child]="this"
                    modalTitle="Remove Source Variable/s"
                    [loading]="isLoading"
                    bodyExtraClass="overflow-auto">
  <p *ngIf="variables.length > 1">Are you sure you want to delete these source variables? <br> They will no longer be
    accessible from any forecast.</p>
  <p *ngIf="variables.length === 1">Are you sure you want to delete this source variable? <br> It will no longer be
    accessible from any forecast.</p>

  <div class="remove-table">
    <div class="row header">
      <div class="col-xs-7">Source variable</div>
      <div class="col-xs-3 center-text">Status</div>
      <div class="col-xs-2 center-text">Action</div>
    </div>
    <div style="max-height: 301px; overflow: auto;">
      <div class="content"
           *ngFor="let variable of variables">
        <ng-container *ngTemplateOutlet="variableTemplate; context: { variable: variable }">
        </ng-container>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 center-text">
      <button class="btn full-width"
              data-test-id="remove-source-variables-button-modal"
              (click)="gonefishing || !variablesAcceptedToBeRemoved().length ? close() : removeVariables()"
              [ngClass]="{ disabled: pending || !variablesAcceptedToBeRemoved()?.length }">
        <i *ngIf="pending"
           class="ion-load-c spinner"></i>
        <span *ngIf="!pending">
          {{ gonefishing || !variablesAcceptedToBeRemoved().length ? 'Done' : 'Remove' }}
        </span>
      </button>

      <button *ngIf="variablesNotAcceptedToBeRemoved().length"
              class="btn full-width red"
              style="margin-top: -3px;"
              indicioTippy="This action will remove all source variables and everything thats derived from them."
              data-test-id="file-confirm-removal"
              (click)="forceRemoveSources()"
              [ngClass]="{ disabled: pending }">
        <i *ngIf="pending"
           class="ion-load-c spinner"></i>
        <span *ngIf="!pending">
          Force remove
        </span>
      </button>
    </div>
  </div>
</indicio-modal-base>

<ng-template #variableTemplate
             let-variable="variable">
  <ng-container *ngTemplateOutlet="variableMainRow; context: { variable }"></ng-container>
  <ng-container *ngTemplateOutlet="forecastsRow; context: { variable }"></ng-container>
  <ng-container *ngTemplateOutlet="groupsRow; context: { variable }"></ng-container>
</ng-template>

<ng-template #groupsRow
             let-variable="variable">
  <ng-container *ngIf="usedIn(variable)?.Groups.length > 0;">
    <div class="row"
         style="margin-top: 10px;
         padding: 5px 0 0 10px;
         border-top: 1px solid #60606029;
         font-weight: 500;">
      Variable is used in the following groups:
    </div>
    <div class="row"
         *ngFor="let g of usedIn(variable)?.Groups">
      <div title="Variable: {{ g.SourceVariableName }}"
           class="col-xs-10 crop-string"
           style="padding-left: 20px;">
        Group variable: {{ g.SourceVariableName }}
      </div>
      <div class="col-xs-1"></div>
      <div class="col-xs-1 col-no-padding">
        <i class="ion-trash-a pointer"
           style="margin-left: 5px;"
           indicioTippy="Delete group variable"
           (click)="deleteGroup(g)"></i>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #forecastsRow
             let-variable="variable">
  <div class="row"
       *ngFor="let forecast of usedIn(variable)?.Forecasts">
    <div title="Forecast: {{ forecast.ForecastName }}"
         class="col-xs-10 crop-string"
         style="padding-left: 20px;">
      Project: {{ forecast.ProjectName }}, forecast:
      {{ forecast.ForecastName }}
    </div>
    <div class="col-xs-1 text-right">
      <a [href]="'/forecast/'+ forecast.ForecastId"
         indicioTippy="Navigate to forecast"
         target="_blank">
        <i class="ion-android-exit"></i>
      </a>
    </div>
    <div class="col-xs-1 col-no-padding">
      <i class="ion-trash-a pointer"
         style="margin-left: 5px;"
         indicioTippy="Delete forecast variable"
         (click)="deleteForecastVariable(forecast)"></i>
    </div>
  </div>
</ng-template>

<ng-template #variableMainRow
             let-variable="variable">
  <div class="row margin-bottom">
    <div class="col-xs-7 crop-string"
         [title]="variable.Name">
      {{ variable.Name }}
    </div>
    <div class="col-xs-3 center-text"
         *ngIf="!gonefishing"
         [title]="isUsed(variable) ? 'This source variable is in use and cannot be removed' : 'This varible can be removed'">
      <ng-container *ngIf="!isUsed(variable)">
        <i class="ion-checkmark-circled green"></i>
      </ng-container>
      <ng-container *ngIf="isUsed(variable)">
        <i class="ion-close-circled red"></i>
      </ng-container>
    </div>

    <div class="col-xs-3 center-text"
         *ngIf="gonefishing">
      <ng-container *ngIf="variable.removedStatus !== 'Failed'">
        {{ variable.removedStatus === 'Success' ? 'Removed' : variable.removedStatus }}
      </ng-container>
      <ng-container *ngIf="variable.removedStatus === 'Failed'">
        <span [indicioTippy]="variable.failedMsg"
              class="red">Failed</span>
      </ng-container>
    </div>
  </div>
</ng-template>
