import { AccuracyMeasureType, EAccuracyChartView } from '@core/constants/accuracy.constants';
import { ALGTypes } from '@shared/components/line-graph/alg-types';

export namespace DisplayActions {

  export class SetALGTransform {
    public static readonly type = '[DISPLAY] Set ALG transform';
    constructor(public forecastVersionId: string, public transform: ALGTypes.Transform) { }
  }

  export class SetALGDataType {
    public static readonly type = '[DISPLAY] Set ALG data type';
    constructor(public forecastVersionId: string, public datatype: ALGTypes.Data) { }
  }

  export class AccuracyMeasureVariableMultivariate {
    public static readonly type = '[DISPLAY] Set Accuracy Measure Variable Multivariate type';
    constructor(public forecastVersionId: string, public state: AccuracyMeasureType) { }
  }

  export class AccuracyMeasureVariableUnivariate {
    public static readonly type = '[DISPLAY] Set Accuracy Measure Variable Univariate type';
    constructor(public forecastVersionId: string, public state: AccuracyMeasureType) { }
  }

  export class AccuracyChartView {
    public static readonly type = '[DISPLAY] Set Accuracy chart view';
    constructor(public forecastVersionId: string, public state: EAccuracyChartView) { }
  }

  export class SetALGDecoupledTransform {
    public static readonly type = '[DISPLAY] Set ALG decoupled';
    constructor(public forecastVersionId: string, public state: boolean) { }
  }
}
