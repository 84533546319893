import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ThemeOptionType, ThemeOptions } from '@core/constants/theme-options';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { ClientSettingsDialogData } from './settings.dialog.constants';

@Component({
  selector: 'indicio-settings-dialog',
  templateUrl: 'settings.dialog.html',
})
export class ClientSettingsDialogComponent extends DialogV2BaseDialog<ClientSettingsDialogComponent> {

  public static Id: string = 'ClientSettingsDialogComponent';

  public themeOptions = ThemeOptions;
  public currentTheme: ThemeOptionType = this.appearance.Theme;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: ClientSettingsDialogData,
    dialogRef: MatDialogRef<ClientSettingsDialogComponent, ClientSettingsDialogData>,
    public appearance: AppearanceService,
    public auth: AuthFrontendService,
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    if (this.currentTheme === 'default') {
      this.currentTheme = 'auto';
    }
    this.initialized = true;
  }

  public changeTheme($event: MatSelectChange) {
    this.appearance.setTheme($event.value, false);
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public save() {
    this.dialogRef.close(true);
  }
}
