
import { Injectable } from '@angular/core';
import { ClearStateAction } from '@core/store/auth/auth.actions';
import { ClientState } from '@core/store/client/client.state';
import {
  DeleteSourceVariableSuccessAction,
  DeleteSourceVariableVersionSuccessAction, GetAllSourceVariablesSuccessAction, GetSourceVariableSuccessAction,
  GetSourceVariableVersionSuccessAction,
  RemovedSourceVariablesInCompanyAction, SourceVariableActions
} from '@core/store/source-variable/source-variable.actions';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CompanyActions } from '../company/company.actions';
import { CompanyModel } from '../company/company.model';
import { ForecastBenchmarkModel } from './forecast-benchmark.model';
import { SourceVariableMapper } from './source-variable.mapper';

class SourceVariableStateModel {
  SourceVariables: SourceVariableModel[];
  ForecastBenchmarks: ForecastBenchmarkModel[];
}
@State<SourceVariableStateModel>({
  name: 'sourcevariables',
  defaults: {
    SourceVariables: [],
    ForecastBenchmarks: []
  }
})
@Injectable()
export class SourceVariableState {

  constructor(
    private sourceMapper: SourceVariableMapper
  ) { }

  @Selector([ClientState.activeCompany])
  static sourceVariables(state: SourceVariableStateModel, activeCompany: CompanyModel) {
    return state.SourceVariables.filter(v => v.CompanyId === activeCompany.CompanyId && v.SourceType !== 'trend');
  }

  @Selector()
  static benchmarks(state: SourceVariableStateModel) {
    return state.ForecastBenchmarks;
  }

  @Selector()
  static getSourceVariableVersionById(state: SourceVariableStateModel) {
    return (versionId: string) => {
      for (const v of state.SourceVariables) {
        for (const version of v.Versions) {
          if (version.SourceVariableMetaId === versionId) { return version; }
        }
      }
      return null;
    };
  }

  @Selector()
  static getForecastBenchmarkById(state: SourceVariableStateModel) {
    return (benchId: string) => {
      return state.ForecastBenchmarks.find(x => x.ForecastBenchmarkId === benchId);
    };
  }

  @Action(GetSourceVariableSuccessAction)
  getSourceVariableSuccess(ctx: StateContext<SourceVariableStateModel>, action: GetSourceVariableSuccessAction) {
    const variables = ctx.getState().SourceVariables;
    const newVar = action.variable;
    variables.addOrUpdate(newVar);
    ctx.patchState({ SourceVariables: variables });
  }

  @Action(GetSourceVariableVersionSuccessAction)
  getSourceVariableVersionSuccess(ctx: StateContext<SourceVariableStateModel>, action: GetSourceVariableVersionSuccessAction) {
    const variables = ctx.getState().SourceVariables;
    const idx = variables.findIndex(v => v.SourceVariableId === action.version.SourceVariableId);
    if (idx !== -1) {
      variables[idx].Versions.addOrUpdate(action.version);
    }
    ctx.patchState({ SourceVariables: variables });
  }

  @Action(GetAllSourceVariablesSuccessAction)
  getAllSourceVariablesInCompany(ctx: StateContext<SourceVariableStateModel>, action: GetAllSourceVariablesSuccessAction) {
    ctx.patchState({ SourceVariables: action.variables });
  }

  @Action(DeleteSourceVariableSuccessAction)
  removeSourceVariable(ctx: StateContext<SourceVariableStateModel>, action: DeleteSourceVariableSuccessAction) {
    setTimeout(() => {
      const svvs = ctx.getState().SourceVariables;
      svvs.removeById(action.SourceVariableId);
      ctx.patchState({ SourceVariables: svvs });
    }, 0);
  }

  @Action(SourceVariableActions.RemovedSourceVariable)
  removedSourceVariable(ctx: StateContext<SourceVariableStateModel>, action: SourceVariableActions.RemovedSourceVariable) {
    const svvs = ctx.getState().SourceVariables;
    svvs.removeById(action.sourceVariableId);
    ctx.patchState({ SourceVariables: svvs });
  }

  @Action(DeleteSourceVariableVersionSuccessAction)
  removeSourceVariableVersion(ctx: StateContext<SourceVariableStateModel>, action: DeleteSourceVariableVersionSuccessAction) {
    const svvs = ctx.getState().SourceVariables;
    const idx = svvs.findIndex(x => x.getVersionById(action.model.SourceVariableMetaId) !== undefined);
    if (idx !== -1) {
      svvs[idx].Versions.removeById(action.model.SourceVariableMetaId);
      ctx.patchState({ SourceVariables: svvs });
    }
  }

  @Action(SourceVariableActions.GetForecastBenchmarksForVariableSuccess)
  getForecastBenchmarksForVariableSuccess(ctx: StateContext<SourceVariableStateModel>, action: SourceVariableActions.GetForecastBenchmarksForVariableSuccess) {
    const benchmarks = ctx.getState().ForecastBenchmarks;
    action.models.forEach(bench => benchmarks.addOrUpdate(bench));
    ctx.patchState({ ForecastBenchmarks: benchmarks });
  }

  @Action(SourceVariableActions.GetForecastBenchmarkSuccess)
  getForecastBenchmarkSuccess(ctx: StateContext<SourceVariableStateModel>, action: SourceVariableActions.GetForecastBenchmarkSuccess) {
    const benchmarks = ctx.getState().ForecastBenchmarks;
    benchmarks.addOrUpdate(action.model);
    ctx.patchState({ ForecastBenchmarks: benchmarks });
  }

  @Action(SourceVariableActions.GetUsageSuccess)
  getUsageSuccess(ctx: StateContext<SourceVariableStateModel>, action: SourceVariableActions.GetUsageSuccess) {
    const variable = ctx.getState().SourceVariables.find(x => x.SourceVariableId === action.sourceVariableId);
    if (!variable) { return; }
    variable.Used = action.used;
    const sources = ctx.getState().SourceVariables.addOrUpdate(variable);
    ctx.patchState({ SourceVariables: sources });
  }

  @Action(SourceVariableActions.GetSourceVariableMetaSuccessAction)
  getSourceVariableMetaSuccessAction(ctx: StateContext<SourceVariableStateModel>, action: SourceVariableActions.GetSourceVariableMetaSuccessAction) {
    let variable = ctx.getState().SourceVariables.find(x => x.SourceVariableId === action.sourceVariableId);
    variable = this.sourceMapper.mapSourceMeta(action.metaDTO, variable);
    const sources = ctx.getState().SourceVariables.addOrUpdate(variable);
    ctx.patchState({ SourceVariables: sources });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<SourceVariableStateModel>) {
    ctx.patchState({ SourceVariables: [], ForecastBenchmarks: [] });
  }

  @Action(RemovedSourceVariablesInCompanyAction)
  removedHistoricEventsInCompany(ctx: StateContext<SourceVariableStateModel>, action: RemovedSourceVariablesInCompanyAction) {
    const variables = ctx.getState().SourceVariables;
    const toRemove = variables.filter(sv => sv.CompanyId === action.companyId).map(sv => sv.SourceVariableId);
    for (const id of toRemove) {
      ctx.dispatch(new DeleteSourceVariableSuccessAction(id));
    }
  }
}
