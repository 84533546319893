import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { OxfordDatabankDTO, OxfordIndicatorDTO, OxfordTreeLocationDTO } from '@core/store/providers/oxford/oxford-dtos';
import { OxfordVariableMetaModel } from '@core/store/providers/oxford/oxford-models';
import { OxfordImportModalOpts } from './oxford-import-modal.options';

export class OpenOxfordImportModal {
  public static readonly type = '[MODAL] Open oxford import modal';
  data: OxfordImportModalOpts;
  constructor(
    indicator: OxfordIndicatorDTO,
    meta: OxfordVariableMetaModel,
    databank: OxfordDatabankDTO,
    forecastVersion: ForecastVersionModel,
    region: OxfordTreeLocationDTO,
  ) {
    this.data = { indicator, forecastVersion, meta, databank, region };
  }
}
