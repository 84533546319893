import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { AuthBackendService } from '@core/store/auth/auth.backend.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';

export class ForgorPasswordDialogData {
  email: string;
}

@Component({
  selector: 'indicio-forgot-password-dialog',
  templateUrl: './forgot-password.dialog.html',
  styleUrls: ['./forgot-password.dialog.less']
})
export class ForgotPasswordDialogComponent extends DialogV2BaseDialog<ForgotPasswordDialogComponent> {

  // This dialog asks the user for an email and lets the user ask for a reset-password link.
  public static Id: string = 'ForgotPasswordDialogComponent';

  public email: string = '';
  public emailSent: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: ForgorPasswordDialogData,
    private status: StatusService,
    private authService: AuthBackendService,
    dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.email = this.data.email || '';
    this.initialized = true;
  }

  public send() {
    this.inProgress = true;
    this.authService.forgotPassword(this.email)
      .then(() => {
        this.emailSent = true;
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => this.inProgress = false);
  }
}
