import { Pipe, PipeTransform } from '@angular/core';
import { HistoricBaseEventModel } from '@core/store/historic-event/models/base/historic-event.model';
import { FilterBase } from './filter-base';

export class HistoricEventFilters {
  Name: string;
  used: boolean;
  Location: string;
  Type: string;
}

@Pipe({
  name: 'filterHistoricEvents'
})
export class FilterHistoricEventsPipe extends FilterBase implements PipeTransform {
  filters: HistoricEventFilters;
  transform(items: HistoricBaseEventModel[], filters: HistoricEventFilters, _trigger): any {
    filters = this._fixFilterValues(filters);
    this.filters = filters;
    let filteredList = items;

    // Search
    if (this._checkFilter('Name')) {
      filteredList = filteredList.filter(item =>
        // Search events
        new RegExp(filters['Name'], 'gi').test(item['Name'])
      );
    }

    // Filter location
    if (this._checkFilter('Location')) {
      filteredList = filteredList.filter(item => item.Location === filters.Location);
    }

    // Filter type
    if (this._checkFilter('Type')) {
      filteredList = filteredList.filter(item => item.Type === filters.Type);
    }

    return filteredList;
  }
}
