import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoTaskModel } from '@core/store/auto-task/dtos/auto-task.model';
import { EAutoTaskState } from '@modules/lang/language-files/automations';

export interface TaskLiveViewData {
  task: AutoTaskModel;
}

@Component({
  selector: 'indicio-task-live-view-dialog',
  templateUrl: 'auto-task-live.dialog.html',
})
export class AutoTaskLiveViewDialogComponent {

  public manualScroll = false;
  public ETaskState = EAutoTaskState;

  constructor(
    public dialogRef: MatDialogRef<AutoTaskLiveViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TaskLiveViewData
  ) {
    this.scrollToBottom();
  }


  private scrollToBottom() {
    setTimeout(() => {
      const container = document.querySelector('#task-progress-messages');
      const waiting = document.querySelector('#task-progress-waiting');
      const triggerScrollTimer = () => setTimeout(() => this.scrollToBottom(), 1000);
      if (!container) {
        if (waiting) { triggerScrollTimer(); }
        return;
      }
      const bottomPos = container.scrollHeight - container.clientHeight;
      if (this.manualScroll) { triggerScrollTimer(); return; }
      container.scrollTop = bottomPos;
      this.scrollToBottom();
    }, 10);
  }

  public onScroll($event) {
    const container = $event.target;
    if (!container) { return; }
    if (Math.ceil(container.scrollTop) >= Math.round(container.scrollHeight - container.clientHeight)) {
      return this.manualScroll = false;
    }
    this.manualScroll = true;
  }

  public followLog() {
    const container = document.querySelector('#task-progress-messages');
    if (!container) { return; }
    const bottomPos = container.scrollHeight - container.clientHeight;
    container.scrollTop = bottomPos;
  }

  close() {
    this.dialogRef.close(true);
  }
}
