import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PusherInfoMessageDTO } from '@core/services/pusher/dtos/pusher-info-message.dto';

export interface PusherInfoMessageDialogData {
  Dto: PusherInfoMessageDTO;
}

@Component({
  selector: 'indicio-pusher-info-message-dialog',
  templateUrl: 'pusher-info-message.dialog.html',
})
export class PusherInfoMessageDialogComponent {
  public static Id: string = 'PusherInfoMessageDialogComponent';

  constructor(
    public dialogRef: MatDialogRef<PusherInfoMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PusherInfoMessageDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(true);
  }
}
