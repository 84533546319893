import { ProjectMemberModel } from '@core/store/project/project-member.model';


export class ProjectHelpers {
  public static userHasRole(userEmail: string, members: ProjectMemberModel[], role: string) {
    const m = members.find(x => x.Email === userEmail);
    if (m) {
      return m.ProjectRole === role;
    } else {
      return false;
    }
  }
}
