<div class="wiki-icon"
     [ngClass]="{ 'cursor-pointer': link }"
     (click)="link ? open() : null"
     *ngIf="show">
  <fa-icon class="icon"
           [icon]="faIcon"
           [style.fontSize.px]="size"
           [style.color]="color">
  </fa-icon>
  <span class="text dotted-underline">
    <ng-content></ng-content>
  </span>
</div>
