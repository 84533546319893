

const matchRe = /rgb\((\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})\)/;

export class ContrastColorSchemeInput {
  BackgroundColor: string;

}

export class ContrastColorSchemeOutput {
  ColorScheme: string = 'light';
}

export function getContrastColorScheme(input: ContrastColorSchemeInput): ContrastColorSchemeOutput {
  const result = new ContrastColorSchemeOutput();
  if (!input.BackgroundColor) { return result; }
  const match = matchRe.exec(input.BackgroundColor);
  if (match === null) { return result; }

  const red = Number(match[1]);
  const green = Number(match[2]);
  const blue = Number(match[3]);
  const contrast = red * 0.299 + green * 0.587 + blue * 0.114;

  if (contrast < 186) {
    result.ColorScheme = 'dark';
  } else {
    result.ColorScheme = 'light';
  }
  return result;
}
