import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { DateColumnInfoDTO } from '@core/store/file/dtos/variable-sheet-dto';



@Injectable({
  providedIn: 'root'
})
export class UtilsFrontendService {

  constructor(
    private http: HttpService
  ) { }

  public getDisabledRowsForDateColumn(format: string, raw: string[]) {
    return this.http.post<DateColumnInfoDTO>(`utils/get-disabled-rows-for-column/${format}`, raw)
      .then(({ body }) => body);
  }
}
