<div data-test-id="model-accuracy"
     class="error-bar-charts-container"
     style="margin-top: 20px;"
     *ngIf="data !== null">

  <div class="grid-system page-content-header"
       style="--grid-template: 200px 1fr;">
    <div class="left-side">
      {{ data.title }}
      <indicio-wiki-icon [slug]="data.wikiArticle"
                         [link]="true"
                         [size]="20"></indicio-wiki-icon>
    </div>
    <div *ngIf="!showContent"
         class="right-side">
      <span class="title">Hidden content: </span>
      <span class="hidden-content"
            (click)="toggleShow()">
        Accuracy information
        <i class="remove ion-close-circled"></i>
      </span>
    </div>
  </div>

  <div [ngClass]="{ 'hide-acc': !showContent }">
    <div class="row">
      <div class="col-xs-12 filled-box unselectable">
        <div class="title-row">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select [value]="chosenChartOption"
                        (selectionChange)="changeChartOption($event.value)">
              <mat-option [value]="value"
                          [ngClass]="{ 'disabled': chartOptionsExtras.get(value.Value).Disable }"
                          [indicioTippy]="chartOptionsExtras.get(value.Value).Disable ? { placement: 'right', content: chartOptionsExtras.get(value.Value).Tippy } : null"
                          *ngFor="let value of chartOptions">{{ value.Display }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Measurement</mat-label>
            <mat-select [value]="activeMeasurement"
                        (selectionChange)="changeAccuracyMeasure($event.value)">
              <mat-option [value]="value"
                          [title]="value.Description"
                          *ngFor="let value of measurements">{{ value.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="text-right"
               style="margin-top: 24px;">
            <span class="cursor-pointer dotted-underline"
                  indicioTippy="View data in a table format"
                  *ngIf="barChartConfigs.length"
                  (click)="openAccuracyDialogEvent.emit(true)">Show table</span>
          </div>
          <span> <fa-icon class="close-button"
                     [icon]="faIcon"
                     indicioTippy="Hide accuracy information"
                     (click)="toggleShow()"> </fa-icon></span>
        </div>
        <div style="padding: 0 10px;">
          <div class="graph-container"
               style="--columns: {{ barChartConfigs.length }}; --min-width: {{ barChartConfigs.length > 1 ? '390px': '860px'}}">
            <ng-container *ngIf="barChartConfigs.length; else nothingToShowTemplate">
              <ng-container *ngFor="let chart of barChartConfigs">
                <div>
                  {{ chart.Title }}
                  <indicio-bar-chart #barChart
                                     [config]="chart"
                                     [hidden]="!showContent">
                  </indicio-bar-chart>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #nothingToShowTemplate>
              {{ data.noDataText }}
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
