<indicio-dialog class="file-diff-dialog">
  <h2 mat-dialog-title>File revisions</h2>
  <mat-dialog-content *ngIf="initialized && !isLoading && diff">
    <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>
    <!-- Nothing new or changed -->
    <ng-container *ngIf="noChanges"
                  [ngTemplateOutlet]="noChangesTemplate"></ng-container>
    <!-- Added columns (no diff) -->
    <ng-container *ngIf="currentSheet.ColDiffs.length"
                  [ngTemplateOutlet]="newColumnsTemplate"></ng-container>
    <!-- Added rows -->
    <ng-container *ngIf="currentSheet.Added.length"
                  [ngTemplateOutlet]="addedRowsTemplate"></ng-container>
    <!-- Updated rows -->
    <ng-container *ngIf="currentSheet.Updates.length"
                  [ngTemplateOutlet]="updatedRowsTemplate"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #optionsTemplate>
  <indicio-modal-option-row optionTitle="File versions"
                            optionSubtitle="Pick versions to compare"
                            textWidth="5"
                            optionsWidth="7"
                            style="margin-top: -15px; display: block;">
    <mat-form-field style="margin-top: 20px">
      <mat-select [value]="selectedVersionId"
                  (selectionChange)="onSelectChange($event)">
        <ng-container *ngFor="let version of oldFile.Versions; let i = index">
          <mat-option [value]="version.getModelId()"
                      [title]="'Version '"
                      *ngIf="i > 0">
            {{ 'Version '+ i + ' -> Version '+ (i + 1) }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
  <!-- Select sheet (if not .csv) -->
  <indicio-modal-option-row *ngIf="!isCsv && diff.Sheets.length > 0"
                            optionTitle="Sheet"
                            optionSubtitle="Pick a sheet to view"
                            textWidth="5"
                            optionsWidth="7">
    <mat-form-field style="margin-top: 20px">
      <mat-select [value]="currentSheetIdx"
                  (selectionChange)="selectNewSheet($event)">
        <mat-option *ngFor="let sheet of diff.Sheets; let i = index"
                    [value]="i"
                    [title]="sheet.SheetName">
          {{ sheet.SheetName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #actionsTemplate>
  <button mat-stroked-button
          type="button"
          (click)="back()">Close</button>
</ng-template>

<ng-template #noChangesTemplate>
  <div class="row extra-margin-top">
    <div class="col-xs-12 bold text-center">No changes has been made.</div>
  </div>
</ng-template>

<ng-template #newColumnsTemplate>
  <indicio-modal-option-row optionTitle="New columns"
                            textWidth="7"
                            optionsWidth="5"
                            optionSubtitle="Newly added columns, for which no comparison will be shown">
    <div style="margin-top:26px">
      {{ currentSheet.ColDiffs.length }}
    </div>
  </indicio-modal-option-row>
</ng-template>

<ng-template #navArrowsTemplate
             let-type="type"
             let-page="page"
             let-count="count">
  <!-- Nav arrows -->
  <i class="ion-ios-arrow-back diff-arrow left"
     [ngClass]="{ disabled: page === 1 }"
     (click)="changePage(type, -1)"></i>
  <i class="ion-ios-arrow-forward diff-arrow right"
     [ngClass]="{ disabled: page === count }"
     (click)="changePage(type, 1)"></i>
  <!-- /Nav arrows -->
</ng-template>

<ng-template #addedRowsTemplate>
  <div class="row extra-margin-top">
    <div class="col-xs-12 bold text-center">Added data</div>
  </div>

  <div class="grid-system extra-margin-top"
       style="--grid-template: 50px repeat(5, 1fr); margin-left: 25px; width: 760px;">
    <div class="header">Row</div>
    <div class="header center-horizontal-and-vertical"
         *ngFor="let column of getAddedPage(currentSheet.Added[0].ColDiffs)">
      Column {{ column.Col }}
    </div>
  </div>

  <div class="diff-content">
    <ng-container *ngIf="addedPageCount > 1"
                  [ngTemplateOutlet]="navArrowsTemplate"
                  [ngTemplateOutletContext]="{ type: 'added', page: currentAddedPage, count: addedPageCount }"></ng-container>
    <cdk-virtual-scroll-viewport style="height: 152px;"
                                 maxBufferPx="200"
                                 minBufferPx="150"
                                 itemSize="25">
      <div *cdkVirtualFor="let row of currentSheet.Added"
           class="grid-system"
           style="--grid-template: 50px repeat(5, 1fr)">
        <div class="crop-string"
             [indicioTippy]="'Row ' + row.Row">{{ row.Row }}</div>
        <div *ngFor="let column of getAddedPage(row.ColDiffs)"
             class="text-center crop-string"
             [indicioTippy]="column.New">
          {{ column.New }}
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

</ng-template>

<ng-template #updatedRowsTemplate>
  <div class="row extra-margin-top">
    <div class="col-xs-12 bold text-center">Changed data</div>
  </div>

  <div class="grid-system extra-margin-top"
       style="--grid-template: 50px repeat(3, 1fr); margin-left: 25px; width: 760px;">
    <div class="header">Row</div>
    <div *ngFor="let column of getUpdatedPage(currentSheet.UpdatedColumns)"
         class="header center-horizontal-and-vertical">
      Column {{ column }}
    </div>
  </div>
  <div class="grid-system"
       style="--grid-template: 50px repeat(3, 1fr); margin-left: 25px; width: 760px;">
    <div class="sub-header"><!-- Empty --></div>
    <div *ngFor="let column of getUpdatedPage(currentSheet.UpdatedColumns)"
         class="sub-header">
      <div class="grid-system newold text-center"
           style="--grid-template: 1fr 1fr">
        <span>Old</span>
        <span>New</span>
      </div>

    </div>
  </div>

  <div class="diff-content">
    <ng-container *ngIf="addedPageCount > 1"
                  [ngTemplateOutlet]="navArrowsTemplate"
                  [ngTemplateOutletContext]="{ type: 'updated', page: currentUpdatedPage, count: updatedPageCount }"></ng-container>
    <cdk-virtual-scroll-viewport style="height: 152px;"
                                 maxBufferPx="200"
                                 minBufferPx="150"
                                 itemSize="25">
      <div *cdkVirtualFor="let row of currentSheet.Updates"
           class="grid-system"
           style="--grid-template: 50px repeat(3, 1fr)">
        <div class="crop-string"
             [indicioTippy]="'Row ' + row.Row">{{ row.Row }}
        </div>
        <div class="grid-system newold"
             style="--grid-template: 1fr 1fr"
             *ngFor="let column of getUpdatedPage(currentSheet.UpdatedColumns)">
          <ng-container *ngIf="getUpdatedColumnContent(row.Row, column)">
            <div class="crop-string center-horizontal-and-vertical"
                 [indicioTippy]="getUpdatedColumnContent(row.Row, column).Old">
              {{ getUpdatedColumnContent(row.Row, column).Old }}
            </div>
            <div class="crop-string center-horizontal-and-vertical"
                 [indicioTippy]="getUpdatedColumnContent(row.Row, column).New">
              {{ getUpdatedColumnContent(row.Row, column).New }}
            </div>
          </ng-container>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>
