import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { Automation } from './automation';


@Injectable({
  providedIn: 'root'
})
export class AutomationService {

  constructor(
    private http: HttpService,
  ) { }

  public async getCalculableInfo(companyId: string, type: Automation.Targets, targetId: string) {
    const res = await this.http.get<Automation.Types.CalculableInfoDTO[]>(`company/${companyId}/automation/calculable/${type}/${targetId}`);
    return res.body;
  }

  public async calculate(companyId: string, forecastIds: string[]) {
    return await this.http.post<void>(`company/${companyId}/automation/calculable/trigger`, forecastIds);
  }
}
