import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { DateUtils } from '@shared/utils/date.utils';
import { TransformationUtils } from '@shared/utils/forecast/transformation.utils';
import * as moment from 'moment';
import { ALGLineModel, ALGSingleSeriesModel, GraphData } from '../alg-models/graph-data.model';
import { ALGTypes } from '../alg-types';
import { getContrastColorScheme } from './get-contrast-color-scheme';
import { TransformedDataInput, transformData } from './get-data-after-transform';
import { SvgConfig } from './svg-manipulations/create-svg-objects';
import { SvgColors } from './svg-manipulations/svg-colors';

export class GraphDataInput {
  Lines: ALGLineModel[];
  HistoricLine: ALGLineModel;
  Models: ALGSingleSeriesModel[];
  Assessments: AssessmentModel[];
  Periodicity: PeriodicityType;
  Colors: SvgColors;
  GraphType: ALGTypes.Graph;
  ShowHistoricData: boolean;
  ForceAggregatedBtn: boolean;
  ShowOnlyHistoric: boolean;
  SvgConfig: SvgConfig;
}

export function getGraphData(inp: GraphDataInput): GraphData {
  const result = new GraphData();
  const transformed = transformData(inp as TransformedDataInput);
  result.ActiveModelCount = transformed.ActiveModelCount;
  result.ShowCIIntervals = result.ActiveModelCount === 1 && inp.SvgConfig.algTransform === ALGTypes.Transform.original;
  result.DecimalCount = transformed.DecimalCount;
  result.GroupedAssessments = transformed.GroupedAssessments;
  result.Models = transformed.Models;
  result.Lines = transformed.Lines;
  result.HistoricLine = transformed.HistoricLine;
  if (result.ActiveModelCount === 0 && !inp.ShowOnlyHistoric) {
    result.DataEmpty = true;
    return result;
  }

  const unixDates = new Set<number>();
  // Add all line-dates to date-array first
  if (transformed.HistoricLine && transformed.HistoricLine.Active) {
    transformed.HistoricLine.Values.forEach(v => {
      unixDates.add(v.m.unix());
    });
  }

  // Special lines
  for (let line of transformed.Lines) {
    if (!line.Active) { continue; }
    const valueCount = line.Values.length;
    for (let i = 0; i < valueCount; i++) {
      unixDates.add(line.Values[i].m.unix());
    }
  }

  // Now handle all models
  transformed.Models.forEach(model => {
    if (!model.show) { return; }
    const frequency = DateUtils.getFrequencyFromPeriodicity(model.Periodicity);
    const valueCount = model.Values.length;
    for (let i = 0; i < valueCount; i++) {
      unixDates.add(model.Values[i].m.unix());
    }

    const modelsFittedValues = model?.FittedValues?.length ? model.FittedValues : model?.WFittedValues;
    const isSummaryPage = model.WFittedValues != null;
    const field = isSummaryPage ? 'WF' : 'F';
    if (modelsFittedValues != null && inp.HistoricLine != null
      && inp.SvgConfig.ShowFittedData) {
      const values = modelsFittedValues as PlotValueDTO[];
      let valuesToUse: PlotValueDTO[] = values;
      if (values.length > 0 && inp.SvgConfig.algTransform !== ALGTypes.Transform.original) {
        valuesToUse = TransformationUtils.transformValues(inp.SvgConfig.algTransform, values, field, frequency);
      }
      result.Fitted.push({
        Id: model.Name,
        Values: valuesToUse,
        Active: model.show,
        Model: model,
        Segments: [],
        modelName: model.modelName,
        Color: model.Color
      });
      model.Values = model.Values.sort((a, b) => a.D.getTime() - b.D.getTime());
      result.Fitted.forEach(e => {
        e.Values = e.Values.sort((a, b) => a.D.getTime() - b.D.getTime());
      });
    }

    result.LastTwoHistoric = [result.HistoricLine.Values[result.HistoricLine.Values.length - 2], result.HistoricLine.Values.last()];

    // Add forecasted part
    const vals = model.Values.filter(v => v.IF);
    vals.unshift(result.LastTwoHistoric[1] as PlotValueDTO);
    result.Forecasted.push({
      Id: model.Name,
      Values: vals,
      Active: model.show,
      Model: model,
      Segments: [],
      modelName: model.modelName,
      Color: model.Color
    });
  });
  // Declare correct dates-array
  result.Dates = Array.from(unixDates).map(x => moment.unix(x));
  result.PastForecasts = transformed.PastForecasts;
  const contrastOutput = getContrastColorScheme({ BackgroundColor: inp.Colors.ChartBackgroundColor });
  result.Colors = inp.Colors;
  result.Colors.ChartColorScheme = contrastOutput.ColorScheme;
  if (!result.DataEmpty) {
    result.HasAggregatedData = result.HasAggregatedData || inp.ForceAggregatedBtn;
  }
  return result;
}
