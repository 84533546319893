import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { CloseActiveModals } from '@shared/modals/modal.actions';
import { DialogV2Actions } from '../dialogs.V2.actions';

export abstract class DialogV2BaseService {

  /** We keep track of how many times we have opened the any dialog, to add a unique number to the ID of the dialog.
 * This helps us to open multiple dialogs at the same time, or right after each other, without getting conflicts with existing IDs. */
  private dialogOpenCount: number = 0;

  protected static defaultCfg: MatDialogConfig = {
    position: { top: '5%' },
    width: '500px',
    autoFocus: false,
    disableClose: false,
  };

  constructor(
    protected dialog: MatDialog,
    protected actions: ActionService,
  ) {
    this.setupActionListeners();
  }

  private setupActionListeners() {
    this.actions.dispatched(CloseActiveModals).subscribe((_: CloseActiveModals) => this.dialog.closeAll());
    this.actions.dispatched(DialogV2Actions.CloseById).subscribe((a: DialogV2Actions.CloseById) => this.closeById(a.id));
  }

  protected getConfig(id: string, data?: any, cfg?: MatDialogConfig) {
    const config = Object.assign({}, DialogV2BaseService.defaultCfg, cfg);
    config.data = data;
    config.id = id + this.dialogOpenCount++;
    return config;
  }

  protected closeById(id: string) {
    const dialogues = this.dialog.openDialogs.filter(x => x.id.match(new RegExp(id)));
    dialogues.forEach(d => d.close());
  }
}
