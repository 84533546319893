import { DisplayValue } from '@modules/lang/types/display-value';
import { MultiUpdateRemoteDataModalOpts } from './multi-update-remotedata-modal.options';

export class OpenMultiUpdateRemoteDataModalAction {
  public static readonly type = '[MODAL] Open MultiUpdateRemoteData modal';
  data: MultiUpdateRemoteDataModalOpts;
  constructor(
    sourceVariableIds: DisplayValue[],
    companyId: string
  ) {
    this.data = { sourceVariableIds, companyId };
  }
}
