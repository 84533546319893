<indicio-dialog>
  <h1 mat-dialog-title>Report issue</h1>
  <div mat-dialog-content>

    <div class="row">
      <div class="col-xs-6">
        <indicio-searchable-dropdown label="Main subject"
                                     data-test-id="report_a_bug_modal_subject_dropdown"
                                     [required]="true"
                                     [values]="subjects"
                                     [initializeWithFirstValue]="false"
                                     (valueChangeEvent)="issue.Subject = $event">
        </indicio-searchable-dropdown>
      </div>
      <div class="col-xs-6">
        <indicio-searchable-dropdown label="Area"
                                     [required]="true"
                                     [values]="problemTypes"
                                     [initializeWithFirstValue]="false"
                                     (valueChangeEvent)="issue.Type = $event">
        </indicio-searchable-dropdown>
      </div>
    </div>

    <div class="row extra-margin-top">
      <div class="col-xs-12">
        <textarea required
                  data-test-id="report_a_bug_modal_textfield"
                  rows="4"
                  cols="45"
                  [(ngModel)]="issue.Text"
                  name="TextField"
                  placeholder="Please describe the issue here, what happened and if possible - describe how to reproduce the error. Thank you for your help.">

        </textarea>
      </div>
    </div>

  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Close</button>
    <button mat-flat-button
            color="primary"
            (click)="save()"
            cdkFocusInitial>Save</button>
  </div>
</indicio-dialog>
