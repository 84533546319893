import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { ALGUtils } from '@shared/components/line-graph/alg.utils';
import { DateUtils } from '@shared/utils/date.utils';
import { TransformationUtils } from '@shared/utils/forecast/transformation.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import * as d3 from 'd3';
import { ALGLineModel, ALGSingleSeriesModel, GraphData, PastForecastData } from '../alg-models/graph-data.model';
import { ALGTypes } from '../alg-types';
import { setAssessmentImpactOnData } from './get-assessment-impact';
import { getPastForecastData } from './get-past-forecast-data';
import { SvgConfig } from './svg-manipulations/create-svg-objects';

export class TransformedDataInput {
  Lines: ALGLineModel[];
  HistoricLine: ALGLineModel;
  Models: ALGSingleSeriesModel[];
  Assessments: AssessmentModel[];
  Periodicity: PeriodicityType;
  GraphType: ALGTypes.Graph;
  FittedValues?: number[] = [];
  ShowOnlyHistoric: boolean = false;
  SvgConfig: SvgConfig;
}

/**
 * This function transforms all the data to the correct display-format:
 *  1. Rate of Change (ROC) - point to point
 *  2. Rate of Change Yearly (ROC-Y) - compared against last year's values in the same time-period (monthly or quarterly)
 *  3. No transformation; data is as produced by the forecast
 *  4. Rolling 12M - Data is averaged over the closest 12 months
 *  5. Rolling 4Q - Data is averaged over the closest 4 quarters
 */
export function transformData(input: TransformedDataInput): GraphData {
  // Result object init
  const res = new GraphData();
  let models: ALGSingleSeriesModel[] = input.Models.filter(x => !!x && x.show).map(x => ALGUtils.copyALGSingleSeriesModel(x, input.SvgConfig.ShowFittedData, input.SvgConfig.showPastForecasts));
  // Set result flag
  res.DataEmpty = input.ShowOnlyHistoric ? false : models.length === 0;
  // And return if we have no data
  if (res.DataEmpty) {
    res.ActiveModelCount = 0;
    return res;
  }

  const linesToUse: ALGLineModel[] = input.Lines?.map(x => ALGUtils.copyALGLineModel(x)) ?? [];
  const historicLine: ALGLineModel = ALGUtils.copyALGLineModel(input.HistoricLine);
  res.ActiveModelCount = models.length;

  [...linesToUse, historicLine].filter(x => !!x).forEach(l => {
    l.Values = applyAdjustment(input.SvgConfig.algDataType, l.Values);
  });

  // Set up arguments to be used below
  const freq = DateUtils.getFrequencyFromPeriodicity(input.Periodicity);
  const transform = input.SvgConfig.algTransform;
  const transformLags = requiredLags(transform, freq);
  const historicValues = historicLine ? historicLine.Values : [];
  const handlePastForecasts = input.GraphType !== 'variable' && historicLine && input.SvgConfig.ShowPastForecasts;

  for (let i = 0; i < models.length; i++) {
    const model = models[i];
    if (input.GraphType !== 'summary') {
      model.Values = applyAdjustment(input.SvgConfig.algDataType, model.Values);
    }

    // Transform past forecasts
    if (handlePastForecasts) {
      const allValues = [...historicValues, ...model.Values.filter(x => x.IF)];
      const pastForecasts = getPastForecastData(model, allValues, transform, freq);
      model.TransformedPastForecast = pastForecasts;
    }
  }

  const assessmentImpact = setAssessmentImpactOnData(input.Assessments, models, transform);

  models = assessmentImpact.Models;
  res.GroupedAssessments = assessmentImpact.GroupedAssessments;

  // Transform all models (forecasts)
  for (let i = 0; i < models.length; i++) {
    models[i] = transformModel(models[i], transform, historicValues, transformLags, freq);
    // Handle past forecasts. Note: They are already transformed
    if (handlePastForecasts) {
      models[i].TransformedPastForecast = selectPastForecasts(models[i].TransformedPastForecast, input, res);
    }
  }

  // Consolidate the pasts forecasts, if any, info the result
  res.PastForecasts = models.filter(m => !!m.TransformedPastForecast).map(m => m.TransformedPastForecast);

  // Transform all lines
  for (let i = 0; i < linesToUse.length; i++) {
    linesToUse[i] = transformLine(linesToUse[i], transform, historicValues, freq);
  }

  // Transform the historic line
  if (historicLine) {
    res.HistoricLine = transformLine(historicLine, transform, [], freq);
  }


  res.DecimalCount = ValueUtils.getDecimalCount(models);
  res.Models = models;
  res.Lines = [...linesToUse, historicLine];
  return res;
}

function transformModel(
  model: ALGSingleSeriesModel,
  transformation: ALGTypes.Transform,
  historicValues: PlotValueDTO[],
  transformLags: number,
  frequency: number
) {
  // Determine if we need to join in some historic data to be able to do the transformation
  // The assumption here is that the models should have a single historic point left after transformation
  const integratedHistory = model.Values.filter(x => !x.IF).length - 1;
  let addedHistory: PlotValueDTO[] = [];
  const requiredExtra = transformLags - integratedHistory;
  if (requiredExtra > 0) {
    // Get the preceding historic data points required to perform the transformation
    const historicIdx = lastHistoryIdxBefore(model.Values, historicValues);
    addedHistory = historicValues.slice(historicIdx - requiredExtra, historicIdx + 1).map(x => ValueUtils.copyValue<PlotValueDTO>(x));
  }
  const modelValues = [...addedHistory, ...model.Values];

  if (transformation === ALGTypes.Transform.original) {
    model.Values = model.Values.filter(x => !(x.IF && x.V == null));
  } else {
    model.Values = applyTransformation(transformation, modelValues, 'V', frequency).slice(-model.Values.length);
  }
  return model;
}

function selectPastForecasts(data: PastForecastData, config: TransformedDataInput, output: GraphData): PastForecastData {
  /* If we are showing all pasts, return null if more than one model is active (subject to change). */
  data.Type = config.SvgConfig.PastType;
  if (data.Type === 0) {
    if (output.ActiveModelCount !== 1) { return null; }
    return data;
  }

  const firsts = data.PastForecasts.map(p => p[data.Type]);
  data.PastForecasts = [firsts];
  return data;
}

function transformLine(line: ALGLineModel, transformation: ALGTypes.Transform, historicValues: PlotValueDTO[], frequency: number) {
  let addedHistory: PlotValueDTO[] = [];
  if (line.Values.some(x => x.IF) && historicValues.length > 0) {
    const lineHistoric = lastHistoryIdxBefore(line.Values, historicValues);
    addedHistory = historicValues.slice(lineHistoric - 12, lineHistoric + 1);
  }
  const values = [...addedHistory.map(x => ValueUtils.copyValue<PlotValueDTO>(x)), ...line.Values];
  line.Values = applyTransformation(transformation, values, 'V', frequency).slice(-line.Values.length);
  return line;
}

function applyAdjustment(adjustment: ALGTypes.Data, values: PlotValueDTO[]) {
  switch (adjustment) {
    case ALGTypes.Data.original:
      return values;
    case ALGTypes.Data.outlier:
      return ValueUtils.setOutlierValues(values);
    case ALGTypes.Data.seasonal:
      return ValueUtils.setSeasonalValues(values);
    case ALGTypes.Data.aggregated:
      return ValueUtils.setAggregatedValues(values);
    default:
      return null;
  }
}

function applyTransformation(transform: ALGTypes.Transform, values: PlotValueDTO[], field: TransformationUtils.FieldType, frequency: number) {
  switch (transform) {
    case ALGTypes.Transform.original:
      return values;
    case ALGTypes.Transform.roc:
      return TransformationUtils.getChange(values, field, TransformationUtils.ChangeType.percent);
    case ALGTypes.Transform.rocy:
      return TransformationUtils.getChangeYearly(values, field, frequency, TransformationUtils.ChangeType.percent);
    case ALGTypes.Transform.diff:
      return TransformationUtils.getChange(values, field, TransformationUtils.ChangeType.absolute);
    case ALGTypes.Transform.diffy:
      return TransformationUtils.getChangeYearly(values, field, frequency, TransformationUtils.ChangeType.absolute);
    case ALGTypes.Transform.rolling12m:
      return TransformationUtils.getRollingXY(values, field, 12, TransformationUtils.RollingType.Average);
    case ALGTypes.Transform.rolling12mSum:
      return TransformationUtils.getRollingXY(values, field, 12, TransformationUtils.RollingType.Sum);
    case ALGTypes.Transform.rolling4q:
      return TransformationUtils.getRollingXY(values, field, 4, TransformationUtils.RollingType.Average);
    case ALGTypes.Transform.rolling4qSum:
      return TransformationUtils.getRollingXY(values, field, 4, TransformationUtils.RollingType.Sum);
    case ALGTypes.Transform.log:
      return TransformationUtils.getLog(values, field);
  }
}

function requiredLags(transform: ALGTypes.Transform, frequency: number) {
  if (transform === ALGTypes.Transform.roc || transform === ALGTypes.Transform.diff)
    return 1;
  if (transform === ALGTypes.Transform.rocy || transform === ALGTypes.Transform.diffy)
    return frequency;
  if (transform === ALGTypes.Transform.rolling12m || transform === ALGTypes.Transform.rolling12mSum)
    return 12;
  if (transform === ALGTypes.Transform.rolling4q || transform === ALGTypes.Transform.rolling4qSum)
    return 4;
  else
    return 0;
}

/***
 * Get the index of the last historic point which comes before the first value in the line,
 * to be used for joining series when making diff/growth transformations.
 * @param line The line with values which occurs at some point in time
 * @param history The historic values which are assumed to have a point immediately before the start of line
 */
function lastHistoryIdxBefore(line: PlotValueDTO[], history: PlotValueDTO[]) {
  const firstDate = d3.min(line, (v) => v.D);
  let idx = history.length - 1;
  while (history[idx].D >= firstDate) {
    idx--;
  }
  return idx;
}





