import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastBenchmarkModel } from '@core/store/source-variable/forecast-benchmark.model';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { ForecastUtils } from '@shared/utils/forecast/forecast.utils';
import { ListAllBenchmarksDialogData } from './list-all-benchmarks.dialog.options';

export interface ManualPeriodicityDialogData {
  SourceVariableId: string;
  OpenedFromModal: boolean;
}

@Component({
  selector: 'indicio-list-all-benchmarks-dialog',
  templateUrl: 'list-all-benchmarks.dialog.html',
})
export class ListAllBenchmarksDialogComponent {
  public static Id: string = 'ListAllBenchmarksDialogComponent';

  // Fetched entities
  private forecastVersion: ForecastVersionModel;
  public benchmarks: ForecastBenchmarkModel[] = [];
  public allBenchmarks: ForecastBenchmarkModel[] = [];

  // Status flags
  public hasError = true;
  public isLoading = true;

  constructor(
    public dialogRef: MatDialogRef<ListAllBenchmarksDialogComponent>,
    public env: EnvironmentService,
    private dialog: DialogService,
    private sourceService: SourceVariableFrontendService,
    private fVersionService: ForecastFrontendService,
    private status: StatusService,
    @Inject(MAT_DIALOG_DATA) private data: ListAllBenchmarksDialogData
  ) {
    const syncPromise = this.syncBenchmarks();
    const fVersionPromise = this.syncForecast();

    Promise.all([syncPromise, fVersionPromise])
      .then(() => this.setup())
      .finally(() => this.isLoading = false);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  };

  public close() {
    this.dialogRef.close(true);
  }

  public toggleBench(bench: ForecastBenchmarkModel) {
    this.sourceService.toggleForecastBenchmark(this.data.ForecastVersionId, bench.ForecastBenchmarkId)
      .then(() => this.status.setMessage('Benchmark updated', 'Success', true));
  }

  public benchActive(bench: ForecastBenchmarkModel) {
    return this.forecastVersion.ActiveBenchmarks.findIndex(x => x === bench.ForecastBenchmarkId) !== -1;
  }

  public openEditOrCreate(bench?: ForecastBenchmarkModel) {
    this.close();
    this.dialog.openCreateOrEditBenchmark({
      ForecastVariableId: this.data.ForecastVariableId,
      ForecastVersionId: this.data.ForecastVersionId,
      SourceVariableId: this.data.SourceVariableId,
      ForecastBenchmarkId: bench?.ForecastBenchmarkId
    });
  }

  public deleteBench(bench: ForecastBenchmarkModel) {
    this.dialog.openConfirmDialog({
      Title: 'Remove benchmark',
      Message: `Are you sure you want to delete the benchmark ${bench.Name}?`,
      CancelText: 'No',
      ConfirmText: 'Yes, remove it',
      Style: 'warn',
      ConfirmFunction: () => this.sourceService.deleteForecastBenchmark(bench)
        .then(() => {
          this.status.setMessage('Benchmark deleted', 'Success', true);
          return this.syncBenchmarks();
        })
        .then(_ => this.setup()),
    });
  }

  private setup() {
    this.allBenchmarks = this.benchmarks;
    this.benchmarks = ForecastUtils.filterByForecastVersion(this.allBenchmarks, this.forecastVersion);
  }

  private syncBenchmarks() {
    return this.sourceService.getForecastBenchmarks(this.data.SourceVariableId)
      .then(b => this.benchmarks = b)
      .catch(err => {
        this.status.setError(err, true);
        return [] as ForecastBenchmarkModel[];
      });
  }

  private syncForecast() {
    return this.fVersionService.getOrFetchForecastVersion(this.data.ForecastVersionId)
      .then(fver => this.forecastVersion = fver)
      .catch(err => this.status.setError(err, true));
  }
}
