import { Injectable } from '@angular/core';
import { AlphaVantageForexDTO } from './dtos/alphavantage-forex.dto';
import { AlphaVantageForexModel } from './alphavantage-forex.model';
import { AlphaVantageStockDTO } from './dtos/alphavantage-stock.dto';
import { AlphaVantageStockModel } from './alphavantage-stock.model';
import { AlphaVantageCryptoDTO } from './dtos/alphavantage-crypto.dto';
import { AlphaVantageCryptoModel } from './alphavantage-crypto.model';

@Injectable({
  providedIn: 'root'
})
export class AlphaVantageMapper {
  public mapForex(dto: AlphaVantageForexDTO) {
    const model = Object.faMapTo(new AlphaVantageForexModel, dto);
    return model;
  }

  public mapCrypto(dto: AlphaVantageCryptoDTO) {
    const model = Object.faMapTo(new AlphaVantageCryptoModel, dto);
    return model;
  }

  public mapStock(dto: AlphaVantageStockDTO) {
    const model = Object.faMapTo(new AlphaVantageStockModel, dto);
    return model;
  }
}
