import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { HierarchyReconciliationDTO, ReconciliationResultDTO } from '../../entities/dtos/hierarchy-reconciliation.dto';


export interface InspectReconciliationDialogData {
  reconciliation: HierarchyReconciliationDTO;
}

@Component({
  selector: 'indicio-create-hierarchical-forecast-dialog',
  templateUrl: 'inspect-reconciliation.dialog.html',
  styleUrls: ['../hierarchy.dialog.less']
})
export class InspectReconciliationDialogComponent {

  // Dialog identifier
  public static Id: string = 'inspect-reconciliation';

  // Entities
  public dto: HierarchyReconciliationDTO = null;

  // Status
  public isLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<InspectReconciliationDialogComponent>,
    public env: EnvironmentService,
    private status: StatusService,
    private dialog: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: InspectReconciliationDialogData
  ) {
    this.isLoading = true;
    this.setup();
  }

  public close() {
    this.dialogRef.close(null);
  }

  public viewResult(result: ReconciliationResultDTO) {
    this.dialog.openInspectReconciliationResultDialog({ result });
  }

  private setup() {
    this.dto = this.data.reconciliation;
    this.isLoading = false;
  }
}
