import { Def } from '@core/decorators/def';
import { HistoricValueDTO } from '@core/entities/dtos/plot-value-dto';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';

export class ForecastBenchmarkModel implements IHasModelId {
  // Ids
  @Def() ForecastBenchmarkId: string;
  @Def() SourceVariableId: string;
  @Def() Name: string;
  @Def() Values: HistoricValueDTO[];
  @Def() Created: Date;
  @Def() Periodicity: PeriodicityType;
  moment: moment.Moment;

  public get ObjectName() { return this.Name; }
  public getModelId() { return this.ForecastBenchmarkId; }

  public firstDate() { return this.Values[0].D; }
  public lastDate() { return this.Values.last().D; }

  // Frontend specific
  public color: string = '#DDD';
  public active: boolean = true;
}
