import { Injectable } from '@angular/core';
import { CalendarEffects } from '@dialogs/variables/forecast-variable/info/tabs/seasonal/fvar-calendar-settings';
import { AggregationMethod, AggregationMethods } from './language-files/aggregation';
import { AssessmentCategories, AssessmentCategory, AssessmentLevel, AssessmentLevels, AssessmentUnit, AssessmentUnits } from './language-files/assessment';
import { AutoTaskSendEmailTrigger, AutoTaskSendEmailTriggerTypes, AutoTaskStep, AutoTaskStepTypes, AutoTaskTrigger, AutoTaskTriggerOn, AutoTaskTriggerOnEvent, AutoTaskTriggerOnEventTypes, AutoTaskTriggerOnTypes, AutoTaskTriggerTypes } from './language-files/automations';
import { SeasonalCalendarHolidayType, SeasonalCalendarRegionType } from './language-files/calendar';
import { Countries } from './language-files/countries';
import { Currencies, Currency } from './language-files/currencies';
import { HistoricEventEffectType, HistoricEventEffectTypes, HistoricEventLocation, HistoricEventLocations, HistoricEventType, HistoricEventTypes } from './language-files/historic-events';
import { LicenseType, LicenseTypes } from './language-files/license-types';
import { MFASupplier, MFASuppliers } from './language-files/mfa-suppliers';
import { MissingValueModel, MissingValueModels } from './language-files/missing-values';
import { NotificationType, NotificationTypes } from './language-files/notification-types';
import { OutlierModel, OutlierModels, OutlierStrategies, OutlierStrategy, OutlierType, OutlierTypes } from './language-files/outliers';
import { Periodicities } from './language-files/periodicities';
import { ReportTemplate, ReportTemplates } from './language-files/reports';
import { CompanyRole, CompanyRoles, ProjectRole, ProjectRoles } from './language-files/roles';
import { SeasonalCalendar, SeasonalCalendars, SeasonalModel, SeasonalModels, SeasonalOutlier, SeasonalOutlierTypes, SeasonalStrategies, SeasonalStrategy, SeasonalTrend, SeasonalTrends } from './language-files/seasonal';
import { SourceType, SourceTypes } from './language-files/source-types';
import { ModelPropertiesType, ModelTransformationType, StatModelPropertyTypes, StatModelSimpleTransforms, StatModelTransforms, StatModelType, StatModelTypes } from './language-files/stat-models';
import { GraphTransformation, GraphTransformations, VariableTransformations } from './language-files/transformations';
import { ValidityLevel, ValidityLevels } from './language-files/validity-levels';
import { ValueType, ValueTypes } from './language-files/value-types';
import { VSCoefMethod, VSCoefMethods, VSLassoStruct, VSLassoStructs, VSMeasurement, VSMeasurements, VSMode, VSModes } from './language-files/var-select';
import { SourceVariableVisibilityLevel, SourceVariableVisibilityLevels, VisibilityLevel, VisibilityLevels } from './language-files/visibility';
import { Country } from './types/country';
import { DisplayValue } from './types/display-value';
import { ModelName } from './types/model-name';
import { Periodicity } from './types/periodicity';
import { VariableTransformation } from './types/variable-transformation';


@Injectable({ providedIn: 'root' })
export class LanguageService {

  public automationSteps: AutoTaskStep[] = AutoTaskStepTypes;
  public automationTriggerOns: AutoTaskTriggerOn[] = AutoTaskTriggerOnTypes;
  public automationTriggerOnEvents: AutoTaskTriggerOnEvent[] = AutoTaskTriggerOnEventTypes;
  public automationEmailTriggers: AutoTaskSendEmailTrigger[] = AutoTaskSendEmailTriggerTypes;
  public automationTriggers: AutoTaskTrigger[] = AutoTaskTriggerTypes;


  public countries: Country[] = Countries;
  public currencies: Currency[] = Currencies;
  public licenseTypes: LicenseType[] = LicenseTypes;
  public aggregationMethods: AggregationMethod[] = AggregationMethods;
  public validityLevels: ValidityLevel[] = ValidityLevels;
  public mfaSuppliers: MFASupplier[] = MFASuppliers;
  public assessmentCategories: AssessmentCategory[] = AssessmentCategories;
  public assessmentLevels: AssessmentLevel[] = AssessmentLevels;
  public assessmentUnits: AssessmentUnit[] = AssessmentUnits;
  public visibilityLevels: VisibilityLevel[] = VisibilityLevels;
  public sourceVariableVisibilityLevels: SourceVariableVisibilityLevel[] = SourceVariableVisibilityLevels;
  public projectRoles: ProjectRole[] = ProjectRoles;
  public companyRoles: CompanyRole[] = CompanyRoles;
  public valueTypes: ValueType[] = ValueTypes;
  public historicEventEffectTypes: HistoricEventEffectType[] = HistoricEventEffectTypes;
  public historicEventTypes: HistoricEventType[] = HistoricEventTypes;
  public historicEventLocations: HistoricEventLocation[] = HistoricEventLocations;
  public sourceTypes: SourceType[] = SourceTypes;
  public graphTransformations: GraphTransformation[] = GraphTransformations;
  public seasonalModels: SeasonalModel[] = SeasonalModels;
  public seasonalStrategies: SeasonalStrategy[] = SeasonalStrategies;
  public seasonalTrends: SeasonalTrend[] = SeasonalTrends;
  public seasonalOutlierTypes: SeasonalOutlier[] = SeasonalOutlierTypes;
  public seasonalCalendar: SeasonalCalendar[] = SeasonalCalendars;
  public outlierModels: OutlierModel[] = OutlierModels;
  public outlierStrategies: OutlierStrategy[] = OutlierStrategies;
  public outlierTypes: OutlierType[] = OutlierTypes;
  public missingValueModels: MissingValueModel[] = MissingValueModels;
  public variableTransformations: VariableTransformation[] = VariableTransformations;
  public notificationTypes: NotificationType[] = NotificationTypes;
  public varSelectModes: VSMode[] = VSModes;
  public varSelectCoefMethods: VSCoefMethod[] = VSCoefMethods;
  public varSelectLassoStructs: VSLassoStruct[] = VSLassoStructs;
  public varSelectMeasurements: VSMeasurement[] = VSMeasurements;
  public statModels: StatModelType[] = StatModelTypes;
  public statModelTransforms: DisplayValue[] = StatModelTransforms;
  public statModelSimpleTransforms: DisplayValue<ModelTransformationType>[] = StatModelSimpleTransforms;
  public statModelPropertyTypes: DisplayValue<ModelPropertiesType>[] = StatModelPropertyTypes;
  public periodicities: Periodicity[] = Periodicities;
  public seasonalCalendarRegionType: DisplayValue<CalendarEffects.RegionType>[] = SeasonalCalendarRegionType;
  public seasonalCalendarHolidayType: DisplayValue<CalendarEffects.HolidayType>[] = SeasonalCalendarHolidayType;
  public reportTemplates: ReportTemplate[] = ReportTemplates;

  public mapEntries<T extends DisplayValue>(keys: string[], langContent: T[]) {
    return keys.map(v => this.findEntity(langContent, v)).filter(x => !!x);
  }

  public mapModelName(name: string, model: string, index: number, sub: number): ModelName {
    const trans = name.substring(name.indexOf('_') + 1);
    const transEntry = this.statModelTransforms.find(x => x.Value === trans) ?? trans;
    const modelEntry = this.statModels.find(x => x.Value === model);
    const short = typeof transEntry !== 'string' ? transEntry.Description : modelEntry.Display;
    const display = typeof transEntry !== 'string' ? modelEntry.Display + ` ${short}` : modelEntry.Display;
    return new ModelName({
      value: name,
      model: model,
      display: display,
      desc: modelEntry.Extra,
      hover: modelEntry.Description,
      group: index,
      sub: sub,
      groupName: modelEntry.Display,
      short: short
    });
  }

  private findEntity<T extends DisplayValue>(langContent: T[], key: string) {
    const found = langContent.find(x => x.Value === key);
    if (!found) {
      console.warn(`Language entry for ${String(key)} not found for ${langContent[0]?.constructor?.name}`);
      return null;
    }
    return found;
  }
}
