import { DisplayValue } from '../types/display-value';

export class ProjectRole extends DisplayValue<string> { };
export const ProjectRoles = [
  new ProjectRole('projectadmin', 'Admin', 'Can create forecasts and administrate the project'),
  new ProjectRole('projectreviewer', 'Reviewer', 'Can review forecasts and add assessments'),
  new ProjectRole('projectuser', 'User', 'Can create forecasts and use system normally'),
  new ProjectRole('projectviewer', 'Viewer', 'Can only view projects and forecasts')
];

export class CompanyRole extends DisplayValue<string> { };
export const CompanyRoles = [
  new CompanyRole('companyadmin', 'Admin', 'Company administrator'),
  new CompanyRole('companymoderator', 'Moderator', 'Full access to employees'),
  new CompanyRole('companyuser', 'User', 'Normal company employee'),
  new CompanyRole('companyguest', 'Guest', 'Limited company access, can only see projects with explicit invites')
];
