import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { NewsPostModel } from '@core/store/news/news-post.model';
import {
  GetNewsPostsSuccessAction,
  GetNewsPostSuccessAction,
  RemoveNewsPostSuccessAction,
  AddNewsCommentSuccessAction,
  RemoveNewsCommentReplySuccessAction,
  RemoveNewsCommentSuccessAction,
  AddNewsCommentReplySuccessAction,
  EmptyNewsStoreSuccessAction
} from './news.actions';

class NewsStateModel {
  NewsPosts: NewsPostModel[];
}

@State<NewsStateModel>({
  name: 'news',
  defaults: {
    NewsPosts: []
  }
})
@Injectable()
export class NewsState {

  @Selector()
  static posts(state: NewsStateModel) { return state.NewsPosts; }

  @Action(GetNewsPostsSuccessAction)
  getNewsPosts(ctx: StateContext<NewsStateModel>, action: GetNewsPostsSuccessAction) {
    const posts = ctx.getState().NewsPosts;
    action.newsPosts.forEach(post => posts.addOrUpdate(post));
    ctx.patchState({ NewsPosts: [...posts] });
  }

  @Action(GetNewsPostSuccessAction)
  getNewsPost(ctx: StateContext<NewsStateModel>, action: GetNewsPostSuccessAction) {
    const posts = ctx.getState().NewsPosts;
    posts.addOrUpdate(action.newsPost);
    ctx.patchState({ NewsPosts: [...posts] });
  }

  @Action(RemoveNewsPostSuccessAction)
  removeNewsPostSuccessAction(ctx: StateContext<NewsStateModel>, action: RemoveNewsPostSuccessAction) {
    const posts = ctx.getState().NewsPosts;
    const idx = posts.findIndex(x => x.Meta.NewsPostId === action.newsPost.Meta.NewsPostId);
    if (idx !== -1) { posts.splice(idx, 1); }
    ctx.patchState({ NewsPosts: [...posts] });
  }

  @Action(AddNewsCommentSuccessAction)
  addNewsCommentSuccessAction(ctx: StateContext<NewsStateModel>, action: AddNewsCommentSuccessAction) {
    const posts = ctx.getState().NewsPosts;
    const post = posts.find(x => x.Meta.NewsPostId === action.comment.NewsPostId);
    post.Comments.unshift(action.comment);
    post.Meta.CommentCount = post.Comments.length;
    ctx.patchState({ NewsPosts: [...posts] });
  }

  @Action(RemoveNewsCommentSuccessAction)
  removeNewsCommentSuccessAction(ctx: StateContext<NewsStateModel>, action: RemoveNewsCommentSuccessAction) {
    const posts = ctx.getState().NewsPosts;
    const post = posts.find(x => x.Meta.NewsPostId === action.comment.NewsPostId);
    const idx = post.Comments.findIndex(x => x.NewsCommentId === action.comment.NewsCommentId);
    if (idx !== -1) { post.Comments.splice(idx, 1); }
    post.Meta.CommentCount -= 1;
    posts.addOrUpdate(post);
    ctx.patchState({ NewsPosts: [...posts] });
  }

  @Action(AddNewsCommentReplySuccessAction)
  addNewsCommentReplySuccessAction(ctx: StateContext<NewsStateModel>, action: AddNewsCommentReplySuccessAction) {
    const posts = ctx.getState().NewsPosts;
    const comment = action.comment;
    const post = posts.find(x => x.Meta.NewsPostId === comment.NewsPostId);
    post.Meta.CommentCount += 1;
    comment.Replies.unshift(action.reply);
    ctx.patchState({ NewsPosts: [...posts] });
  }

  @Action(RemoveNewsCommentReplySuccessAction)
  removeNewsCommentReplySuccessAction(ctx: StateContext<NewsStateModel>, action: RemoveNewsCommentReplySuccessAction) {
    const posts = ctx.getState().NewsPosts;
    const comment = action.comment;
    const post = posts.find(x => x.Meta.NewsPostId === action.comment.NewsPostId);
    const idx = comment.Replies.findIndex(x => x.NewsCommentId === action.reply.NewsCommentId);
    if (idx !== -1) { comment.Replies.splice(idx, 1); }
    post.Meta.CommentCount -= 1;
    ctx.patchState({ NewsPosts: [...posts] });
  }

  @Action(EmptyNewsStoreSuccessAction)
  emptyNewsStoreSuccessAction(ctx: StateContext<NewsStateModel>, _action: EmptyNewsStoreSuccessAction) {
    ctx.patchState({ NewsPosts: [] });
  }
}
