import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';

import { MatSelectChange } from '@angular/material/select';
import { IFile, IFileVersion } from '@core/interfaces/if-file';
import { StatusService } from '@core/services/status/status.service';
import { FileAdditionsDTO, FileVersionDiffDTO, SheetDiffDTO } from '@core/store/file/dtos/file-version-diff-dto';
import { FileFrontendService } from '@core/store/file/file.frontend.service';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';

export class FileDiffDialogData {
  oldFile: UploadedFileModel;
  newFile?: UploadedFileModel;
  onBack: Function = null;
}

@Component({
  selector: 'indicio-file-diff-modal',
  templateUrl: './file-diff.dialog.component.html',
  styleUrls: ['./file-diff.dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDiffDialogComponent extends DialogV2BaseDialog<FileDiffDialogComponent> implements AfterViewInit {

  public static Id: string = 'FileDiffDialogComponent';

  public oldFile: IFile;
  private newFile: IFile;
  public diff: FileVersionDiffDTO = null;
  private selectedVersion: IFileVersion = null;
  public selectedVersionId: string;
  public currentSheetIdx = 0;
  public currentSheet: SheetDiffDTO;
  // private visibleColumns: RowDiffDTO[];
  public isCsv = false;
  private onBack: Function = null;
  public currentAddedPage = 1;
  public currentUpdatedPage = 1;
  public addedPageCount = 0;
  public updatedPageCount = 0;
  public noChanges = false;

  // Frontend flags
  public isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: FileDiffDialogData,
    private status: StatusService,
    private fileService: FileFrontendService,
    private cd: ChangeDetectorRef,
    dialogRef: MatDialogRef<FileDiffDialogComponent>,
  ) {
    super(dialogRef);
    this.initialize();
  }

  public ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  protected initialize() {
    this.oldFile = this.data.oldFile;
    this.onBack = this.data.onBack;
    if (this.data.newFile) {
      this.newFile = this.data.newFile;
    }

    if (this.oldFile.Extension === '.csv') {
      this.isCsv = true;
    }

    this.selectVersion(this.oldFile.Versions.slice().pop().getModelId())
      .then(() => {
        this.isLoading = false;
        this.cd.detectChanges();
      });
    this.initialized = true;
  }


  public selectNewSheet($event: MatSelectChange) {
    const idx = $event.value;
    this.isLoading = true;
    setTimeout(() => {
      this.currentSheetIdx = idx;
      this.currentSheet = this.diff.Sheets[this.currentSheetIdx];
      this.addedPageCount = Math.ceil(this.currentSheet.Added[0]?.ColDiffs.length / 5);
      this.updatedPageCount = Math.ceil(this.currentSheet.UpdatedColumns.length / 3);
      this.currentAddedPage = 1;
      this.currentUpdatedPage = 1;
      this.noChanges = this.currentSheet.Added.length === 0 &&
        this.currentSheet.Added.length === 0 &&
        this.currentSheet.ColDiffs.length === 0 &&
        this.currentSheet.UpdatedColumns.length === 0;
      this.isLoading = false;
      this.cd.detectChanges();
    }, 0);
  }

  public changePage(list: 'added' | 'updated', direction: 1 | -1) {
    if (list === 'added') {
      this.currentAddedPage = direction === 1 ? this.currentAddedPage + 1 : this.currentAddedPage - 1;
    } else {
      this.currentUpdatedPage = direction === 1 ? this.currentUpdatedPage + 1 : this.currentUpdatedPage - 1;
    }
    this.cd.detectChanges();
  }

  public getAddedPage(data: FileAdditionsDTO[], maxPerPage = 5) {
    const start = this.currentAddedPage <= 1 ? 0 : ((maxPerPage * this.currentAddedPage) - maxPerPage);
    return data.slice(start, maxPerPage * this.currentAddedPage);
  }

  public getUpdatedPage(data: number[], maxPerPage = 3) {
    const start = this.currentUpdatedPage <= 1 ? 0 : ((maxPerPage * this.currentUpdatedPage) - maxPerPage);
    return data.slice(start, maxPerPage * this.currentUpdatedPage);
  }

  public getUpdatedColumnContent(row: number, column: number) {
    return this.currentSheet.Updates.find(x => x.Row === row).ColDiffs.find(x => x.Col === column);
  }

  public back() {
    this.close();
    if (this.onBack) { this.onBack(); }
  }

  public onSelectChange($event: MatSelectChange) {
    this.selectVersion($event.value);
  }

  public selectVersion(versionId: string) {
    this.selectedVersionId = versionId;
    this.selectedVersion = this.oldFile.Versions.find(x => x.getModelId() === versionId);
    this.isLoading = true;
    return this.updateDiff()
      .then(() => {
        this.selectNewSheet(<MatSelectChange> { value: this.currentSheetIdx });
        this.isLoading = false;
      });
  }

  private updateDiff() {
    const fileId = this.oldFile.getModelId();
    const index = this.oldFile.Versions.findIndex(x => x.getModelId() === this.selectedVersion.getModelId());
    const newVer = this.oldFile.Versions[index].getModelId();
    const oldVer = this.oldFile.Versions[index - 1].getModelId();

    let call;

    switch (this.oldFile.SourceType) {
      case 'file':
        call = this.fileService.getFileDiff(this.oldFile.CompanyId, fileId, oldVer, newVer)
          .then(r => {
            r.Sheets[0].Added = [...r.Sheets[0].Added, ...r.Sheets[0].Added, ...r.Sheets[0].Added, ...r.Sheets[0].Added];
            return r;
          });
        break;
      case 'sharepoint':
        call = this.fileService.getRemoteFileDiff(fileId, oldVer, newVer);
    }

    return call.then(res => {
      this.diff = res;
    })
      .catch(err => {
        this.status.setError(err, true);
      });
  }

  public numb2char(int) {
    const chr = String.fromCharCode(65 + int);
    return chr;
  }
}
