import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { IndicioHttpContext } from '@core/services/http/indicio-http-context';
import { CompanyMapper } from '@core/store/company/company-mapper';
import { CompanyActions } from '@core/store/company/company.actions';
import { CompanyModel } from '@core/store/company/company.model';
import { CompanyDTO, CompanyMetaAdminDTO } from '@core/store/company/dtos/company-dto';
import { AdminUpdateCompanyDTO } from '@core/store/company/dtos/update-company-dto';
import { UserStatisticsDTO } from '@core/store/company/dtos/user-statistics.dto';
import { InviteDTO } from '@core/store/invites/dtos/invite-dto';
import { IndicioEventType } from '@core/types/admin.type';
import { ScriptName } from '@core/types/script.name.type';
import { RRequestInfoDTO, SystemsInfoDTO } from '@modules/admin/entities/systems-info.dto';
import { AdminMapper } from '@modules/admin/mapper/admin.mapper';
import { DisplayValue } from '@modules/lang/types/display-value';
import { Store } from '@ngxs/store';
import { DateUtils } from '@shared/utils/date.utils';
import { AdminForecastResultsDTO, ForecastResultsSearchDTO } from '../components/content/forecast-results/forecast-results-overview.component';
import { ForecastVariableInfoDTO } from '../components/content/forecast-variables/entities/forecast-variable-info.dto';
import { ForecastVariableSearchDTO } from '../components/content/forecast-variables/entities/forecast-variable-search.dto';
import { AdminForecastMetaDTO, AdminRemoveForecastDTO } from '../components/content/forecasts/entities/admin-forecast-meta.dto';
import { ForecastAdminSearchDTO } from '../components/content/forecasts/entities/forecast-search-dto';
import { AdminProjectMetaDTO, ProjectAdminSearchDTO } from '../components/content/projects/entities/admin-project.dto';
import { RemoteVariableInfoDTO } from '../components/content/remote-data/entities/remote-variable-info.dto';
import { RemoteVariableSearchDTO } from '../components/content/remote-data/entities/remote-variable-search.dto';
import { SourceVariableInfoDTO } from '../components/content/source-variables/entities/source-variable-info.dto';
import { SourceVariableSearchDTO } from '../components/content/source-variables/entities/source-variable-search.dto';
import { AdminFileMetaDTO, AdminFileSearchDTO } from '../components/content/uploaded-files/uploaded-files.admin.dto';
import { EndpointDTO } from '../components/health/performance/entities/endpoint-dto';
import { TestUserDTO } from '../components/health/test/entities/test-user.dto';
import { EtaResultDTO } from '../components/R/eta/entities/eta-result.dto';
import { AutoMailDTO, CreateAutoMailDTO } from '../components/system/auto-mails/entities/auto-mail.dto';
import { OpenIdAdminMetaDTO, OpenIdAdminSearchDTO } from '../components/system/open-id/open-id.admin.dtos';
import { SystemUsageDTO } from '../components/usage/graphs/system-usage.dtos';
import { RPerfDTO } from '../components/usage/r-performance/entities/r-performance-request.dto';
import { RPerformanceDTO } from '../components/usage/r-performance/entities/r-performance.dto';
import { AdminEnvDTO } from '../entities/admin-env.dto';
import { AuditLogDTO } from '../entities/audit-log.dto';
import { AuditLogSearchDTO } from '../entities/audit-search.dto';
import { ClientInfoDTO, ClientMetaDTO } from '../entities/client-meta.dto';
import { RLogSearchDTO } from '../entities/r-log-search.dto';
import { ScenarioAdminDTO, ScenarioSearchDTO } from '../entities/scenario-admin-dto';
import { ScriptLogOutDTO } from '../entities/script-log-out.dto';
import { SysLogDTO } from '../entities/syslog-dto';
import { RClientInfoModel, RClientLogsInfo } from '../entities/systems-info.model';


@Injectable({
  providedIn: 'root',
})
export class AdminFrontendService {

  constructor(
    private http: HttpService,
    private mapper: AdminMapper,
    private store: Store,
    private companyMapper: CompanyMapper
  ) { }

  /** System usage */
  public getSystemUsage(companyId: string, fromDate?: Date, toDate?: Date) {
    let path = companyId ? `admin/system-usage/${companyId}` : 'admin/system-usage/';
    if (fromDate && toDate) {
      path += `?fromDate=${DateUtils.convertToBackendDate(fromDate)}&toDate=${DateUtils.convertToBackendDate(toDate)}`;
    }
    return this.http.get<SystemUsageDTO>(path)
      .then(({ body }) => body);
  }

  /**
   * Performance
   */

  public getAllRPerformanceInfo(dto: RPerfDTO) {
    return this.http.post<RPerformanceDTO[]>('admin/r/performance', dto)
      .then(resp => resp.body);
  }

  public getPerformanceData() {
    return this.http.get<EndpointDTO[]>('admin/get-performance-data')
      .then(resp => resp.body.map(x => this.mapper.mapEndpointPerformance(x)));
  }

  /**
   * Remote data update
   */
  public updateOECDData() {
    return this.http.post<null>('OECD/update', null, 'utility');
  }

  /*
  * Ping tools
  */

  public pingFrontendUtilities() {
    return this.http.get<any>('ping', 'utility')
      .then(resp => resp.body);
  }

  public pingBackend() {
    return this.http.get<any>('', 'backend-only-ping')
      .then(resp => resp.body);
  }

  public pingImport() {
    return this.http.get<any>('', 'import')
      .then(resp => resp.body);
  }

  public pingIdentity() {
    return this.http.get<any>('', 'id')
      .then(resp => resp.body);
  }


  /**
   * Company stuff
   */
  public getAllCompanies() {
    return this.http.get<CompanyMetaAdminDTO[]>('company')
      .then(({ body }) => body.map(c => this.mapper.mapCompany(c)));
  }

  public getCompany(id: string) {
    return this.http
      .get<CompanyDTO>(`company/${id}/get-company`)
      .then(resp => this.companyMapper.map(resp.body));
  }

  public async updateCompany(dto: AdminUpdateCompanyDTO): Promise<CompanyModel> {
    const resp = await this.http.post<CompanyDTO>('admin/company/update-company', dto);
    const m = this.companyMapper.map(resp.body);
    this.store.dispatch(new CompanyActions.GetCompanySuccessAction(m));
    this.store.dispatch(new CompanyActions.UpdatedCompany(m));
    return m;
  }

  /**
   * User stuff
   */
  public getTestUsers() {
    return this.http
      .get<TestUserDTO[]>('admin/client/get-test-users')
      .then(resp => resp.body);
  }

  public createTestUser(dto: TestUserDTO) {
    return this.http.post('admin/client/create-test-user', dto);
  }

  public deleteTestUsers() {
    return this.http.delete('admin/client/remove-test-users');
  }

  public createUser(dto) {
    return this.http.post<UserStatisticsDTO>('admin/client/create-user', dto).then(resp => resp.body);
  }

  public getAllUsers() {
    return this.http.get<ClientMetaDTO[]>('admin/get-users')
      .then(resp => {
        const users = resp.body.map(x => this.mapper.mapClientMeta(x));
        users.sort((a, b) => b.LastActivity.getTime() - a.LastActivity.getTime());
        return users;
      });
  }

  public getUser(userid: string) {
    return this.http.get<ClientMetaDTO[]>(`admin/get-user/${userid}`)
      .then(({ body }) => this.mapper.mapClientMeta(body[0]));
  }

  public getUserInfo(userId: string) {
    return this.http.get<ClientInfoDTO>(`admin/get-user-info/${userId}`).then(({ body }) => body);
  }

  public updateClient(dto: ClientMetaDTO) {
    return this.http.put<ClientMetaDTO>('admin/user', dto).then(resp => resp.body);
  }

  /**
   * Data provider stuff
   */
  public removeValueguardVariables() {
    return this.http.delete<null>('admin/remove-valueguard-variables');
  }

  public resetUserStatistics() {
    return this.http.delete<UserStatisticsDTO>('admin/remove-old-statistics');
  }

  public removeCompany(company: CompanyModel, wipe: boolean = false) {
    if (wipe) {
      return this.http.delete<null>(`admin/company/${company.CompanyId}/wipe`);
    } else {
      return this.http.delete<null>(`company/${company.CompanyId}/remove-company`);
    }
  }

  public disableCompany(company: CompanyModel, set: boolean = false) {
    return this.http.post(`admin/company/${company.CompanyId}/disable`, set);
  }

  public getAdminInfo() {
    return this.http
      .get<SystemsInfoDTO>('admin')
      .then(resp => this.mapper.mapSystemInfo(resp.body));
  }

  public getRRequestInfos() {
    return this.http
      .get<RRequestInfoDTO[]>('admin/get-r-requests')
      .then(resp => {
        let models = resp.body.map(x => this.mapper.mapRRequestInfo(x));
        models = models.sort((a, b) => +a.CreatedDate - +b.CreatedDate);
        return models;
      });
  }

  public removeOldRClients() {
    return this.http.post<null>('admin/remove-old-r-clients', null);
  }

  public clearTrash() {
    return this.http.post<null>('admin/clear-trash', null);
  }

  public downloadRScriptLogs(RRequestId: string) {
    return this.http.get<ScriptLogOutDTO[]>(`admin/r-script-logs/download-by-request-id/${RRequestId}`, 'backend', IndicioHttpContext.GetContext({ Timeout: 1600000 }))
      .then(resp => resp.body);
  }

  public downloadScriptLog(rRequestLogId: number) {
    return this.http.get<ScriptLogOutDTO>(`admin/r-script-logs/download/${rRequestLogId}`);
  }

  public retryRRequest(rRequestId: string) {
    return this.http.post<any>(`admin/r-requests/recalc/${rRequestId}`, null);
  }

  public removeAllRRequests() {
    return this.http.post<any>('admin/r-requests/remove-all', null);
  }

  public getScriptLogs(dto: RLogSearchDTO) {
    return this.http.post<ScriptLogOutDTO[]>('admin/r-script-logs/search', dto)
      .then(resp => {
        resp.body.forEach(l => l.VariableNames = JSON.parse(l.VariableNames));
        return resp;
      });
  }

  public deleteRRequest(rRequestId: string) {
    return this.http.delete(`admin/r-requests/${rRequestId}`);
  }

  public getAuditLogs(dto: AuditLogSearchDTO) {
    return this.http.post<AuditLogDTO[]>('admin/audits/search', dto)
      .then(({ body }) => body);
  }

  //
  // RClient stuff
  //
  public getRClientEnvJson(id: string) {
    return this.http.get<string>(`admin/rclient/${id}/env-json`);
  }

  public toggleRClientDisabled(client: RClientInfoModel, state?: boolean) {
    const toSet = state == null ? !client.Disabled : state;
    return this.http.post<null>('admin/toggle-r-client', {
      RClientId: client.RClientId,
      Name: client.Name,
      Disabled: toSet
    });
  }

  public restartRClient(client: RClientInfoModel) {
    return this.http.post<SysLogDTO>('admin/restart-r-client', { RClientId: client.RClientId, Name: client.Name });
  }

  public syncRClientLogs(client: RClientInfoModel) {
    return this.http.post<SysLogDTO>('admin/sync-r-client-logs', { RClientId: client.RClientId, Name: client.Name });
  }

  public fetchRClientLogs(ids: string[]) {
    return this.http.post<RClientLogsInfo[]>('admin/fetch-r-clients-logs', { RClientsId: ids });
  }

  public removeRClient(client: RClientInfoModel) {
    return this.http.post<SysLogDTO>('admin/remove-r-client', { RClientId: client.RClientId, Name: client.Name });
  }

  //
  // Forecast variables
  //
  public searchForecastVariables(dto: ForecastVariableSearchDTO) {
    return this.http.post<ForecastVariableInfoDTO[]>('admin/forecast-variables/search', dto);
  }

  public recalculateForecastVariables(forecastVariableIds: string[]) {
    return this.http.post<null>('admin/forecast-variables/trigger', forecastVariableIds);
  }

  public deleteForecastVariables(forecastVariableIds: string[]) {
    return this.http.post<string[]>('admin/forecast-variables/delete', forecastVariableIds)
      .then(({ body }) => body);
  }

  //
  // Source variables
  //
  public searchSourceVariables(dto: SourceVariableSearchDTO) {
    return this.http.post<SourceVariableInfoDTO[]>('admin/source-variables/search', dto);
  }

  public deleteSourceVariable(SourceVariableId: string) {
    return Promise.reject({ error: `Not implemented. (${SourceVariableId})` });
  }

  //
  // Remote variables
  //
  public searchRemoteVariables(dto: RemoteVariableSearchDTO) {
    return this.http.post<RemoteVariableInfoDTO[]>('admin/remote-variables/search', dto);
  }

  public updateRemoteVariable(remoteVariableId: string) {
    return this.http.post<RemoteVariableInfoDTO>(`admin/remote-variables/${remoteVariableId}/update`, null)
      .then(({ body }) => body);
  }

  public deleteRemoteVariable(remoteVariableId: string) {
    return this.http.delete(`admin/remote-variables/${remoteVariableId}`);
  }

  public updateAllRemotes() {
    return this.http.post<null>('admin/remote-variables/update-all-remote-variables', null);
  }

  //
  // Scenario stuff
  //
  public getScenarios(dto: ScenarioSearchDTO) {
    return this.http.post<ScenarioAdminDTO[]>('admin/scenarios/search', dto);
  }

  public downloadScenarioData(scenarioId: string) {
    return this.http.get<any>(`admin/scenarios/${scenarioId}/get-complete-data`);
  }

  //
  // ETA stuff
  //
  public getEtaResults(script: ScriptName) {
    return this.http.get<EtaResultDTO>(`admin/eta-results/${script}`);
  }

  public queueEtaScript(script: ScriptName) {
    return this.http.post<null>(`admin/queue-eta/${script}`, null);
  }

  //
  // Logs and R Scripts
  //
  public deleteRScriptLog(id: number) {
    return this.http.delete(`admin/r-script-logs/${id}`);
  }

  public deleteOldRScriptLogs() {
    return this.http.delete('admin/r-script-logs/remove-old');
  }

  public downloadRScriptAsString(rRequestId: string) {
    return this.http.get<string>(`admin/r-script/${rRequestId}/download-as-string`);
  }

  /**
 * Projects
 */
  public searchProjects(searchDto: ProjectAdminSearchDTO) {
    return this.http.post<AdminProjectMetaDTO[]>('admin/projects', searchDto).then(({ body }) => body);
  }

  public removeProjects(projectIds: string[]) {
    return this.http.post<AdminProjectMetaDTO[]>('admin/projects/remove', projectIds);
  }


  /**
   * Forecast / ForecastVersions / forecast results
   */
  public searchFForecasts(searchDto: ForecastAdminSearchDTO) {
    return this.http.post<AdminForecastMetaDTO[]>('admin/forecasts', searchDto);
  }

  public removeForecasts(fcs: AdminRemoveForecastDTO[]) {
    return this.http.post<AdminProjectMetaDTO[]>('admin/forecasts/remove', fcs);
  }

  public setIsLatest() {
    return this.http.post<null>('admin/forecasts/setIsLatest', null);
  }

  public getForecastResults(dto: ForecastResultsSearchDTO) {
    return this.http.post<AdminForecastResultsDTO[]>('admin/forecast-results/get', dto)
      .then(resp => resp.body);
  }

  public deleteForecastResults(forecastResultsId: string) {
    return this.http.delete(`admin/forecast-results/${forecastResultsId}/delete`);
  }

  /**
   * Pusher
   */
  public getAdminPusherChannelId() {
    return this.http.get<string>('admin/pusher/channel')
      .then(resp => resp.body);
  }

  /**
   * Indicio Events
   */
  public getIndicioEvents(type: IndicioEventType) {
    if (!type) {
      return this.http.get<any>('admin/events');
    } else {
      return this.http.get<any>(`admin/events/${type}`);
    }
  }

  public deleteAllIndicioEvents(type: string) {
    return this.http.delete(`admin/events/${type}/clear`);
  }

  public deleteOldIndicioEvents(type: string) {
    return this.http.delete(`admin/events/${type}/remove`);
  }

  public syncAdminEnv() {
    return this.http.get<AdminEnvDTO>('admin/environment');
  }

  /**
   * Frontend Utils
   */
  public syncFrontendSetup() {
    return this.http.get('admin/setup', 'utility').then(({ body }) => body);
  }

  public toggleFrontendUtilsDebug() {
    return this.http.post('admin/toggle-debug', null, 'utility');
  }

  /**
   * Auto mails
   */
  public getAutoMailVariables() {
    return this.http.get<any>('auto-mail/get-variables').then(({ body }) => body);
  }

  public getAutoMailIncludedCompanies(dto: CreateAutoMailDTO) {
    // Item1: Company Name
    // Item2: Company Id (Guid as a string)
    return this.http.post<{ Item1: string, Item2: string; }[]>('auto-mail/get-companies', dto)
      .then(({ body }) => body.map(x => (new DisplayValue<string>(x.Item2, x.Item1))));
  }

  public getAutoMailList() {
    return this.http.get<AutoMailDTO[]>('auto-mail').then(({ body }) => body);
  }

  public createAutoMail(dto: CreateAutoMailDTO) {
    return this.http.post<AutoMailDTO>('auto-mail', dto).then(({ body }) => body);
  }

  public updateAutoMail(dto: AutoMailDTO) {
    return this.http.put<AutoMailDTO>('auto-mail', dto).then(({ body }) => body);
  }

  public triggerSendOfAutoMail(dto: AutoMailDTO) {
    return this.http.post<AutoMailDTO>(`auto-mail/${dto.AutoMailId}/send`, {}).then(({ body }) => body);
  }

  public removeAutoMail(automail: AutoMailDTO) {
    return this.http.delete<null>(`auto-mail/${automail.AutoMailId}`).then(({ body }) => body);
  }


  /** Invites */

  public getAllInvites() {
    return this.http.get<InviteDTO[]>('admin/invites').then(({ body }) => body);
  }

  public removeInvite(inviteId: string) {
    return this.http.delete<null>(`admin/invites/${inviteId}/remove-invite`);
  }

  public removeInvites(inviteIds: string[]) {
    return this.http.post<null>('admin/invites/remove-invites', inviteIds);
  }

  public triggerSendOfInvite(inviteId: string) {
    return this.http.get<null>(`admin/invites/${inviteId}/send-reminder-email`);
  }

  /** OpenId */

  public searchOpenIdConnections(dto: OpenIdAdminSearchDTO) {
    return this.http.post<OpenIdAdminMetaDTO[]>('admin/open-id', dto).then(({ body }) => body);
  }

  public testOpenIdTokenForClient(provider: string, clientId: string) {
    return this.http.post<boolean>(`admin/open-id/test/${provider}/${clientId}`, {}).then(({ body }) => body);
  }

  /** Files */

  public searchFiles(dto: AdminFileSearchDTO) {
    return this.http.post<AdminFileMetaDTO[]>('admin/files', dto).then(({ body }) => body);
  }
}
