import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { SupportService } from '@core/store/support/support.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { Country } from '@modules/lang/types/country';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DateUtils } from '@shared/utils/date.utils';


export class SupportUser {
  Display: string;
  Value: string;
  Roles: string[];
}

@Component({
  selector: 'indicio-support-dialog-general-tab',
  templateUrl: './tab.general.component.html',
  styleUrls: ['./tab.general.component.less'],
})
export class SupporyGeneralTabComponent implements OnChanges {

  // Font-aweseome
  public faSave = faSave as IconProp;
  public faInfo = faInfoCircle as IconProp;

  @Input() company: CompanyModel;

  // Date limit for trials
  public minDate: Date = DateUtils.newMoment().add(2, 'days').toDate();
  public trialEndDate: Date = DateUtils.newMoment().add(2, 'weeks').toDate();
  public companyEnabled: boolean = false;

  @Output() newCountryEvent = new EventEmitter<Country>();
  @Output() maxUsersEvent = new EventEmitter<number>();
  @Output() supportEvent = new EventEmitter<string[]>();
  @Output() checkSavedEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  // Settings
  public newName: string;
  public editName: boolean = false;
  public pendingName: boolean = false;
  public selectedCountry: Country;

  public get canToggleDisabled() { return this.auth.isSales || this.auth.isAdmin; }

  constructor(
    private status: StatusService,
    private cd: ChangeDetectorRef,
    private service: SupportService,
    private auth: AuthFrontendService,
    public envService: EnvironmentService,
    private dialogService: DialogService,
  ) {
  }

  public ngOnChanges() {
    if (!this.company) { return; }
    this.setup();
    this.cd.detectChanges();
  }

  private setup() {
    this.newName = this.company.Name;
    this.selectedCountry = this.envService.getCountry(this.company.CountryCode);
    this.trialEndDate = this.company.TrialExpirationDate || this.trialEndDate;
    this.companyEnabled = !this.company.Disabled;
  }

  public async toggleTrialStatus() {
    this.service.toggleTrial(this.company.CompanyId)
      .then((status) => {
        this.company.Trial = status;
        this.status.setMessage('Trial status updated', 'Success', true);
        this.reloadEvent.emit();
      })
      .catch(err => this.status.setError(err, true));
  }

  public async toggleDisabled(event: MatSlideToggleChange) {

    const toggle = () => {
      this.service.toggleDisabled(this.company.CompanyId)
        .then((status) => {
          this.company.Disabled = status;
          this.companyEnabled = !status;
          this.status.setMessage(`Company ${!status ? 'enabled' : 'disabled'}`, 'Success', true);
          this.reloadEvent.emit();
        })
        .catch(err => this.status.setError(err, true));
    };

    if (!this.companyEnabled) {
      return toggle();
    }

    const ref = this.dialogService.openConfirmDialog({
      Title: 'Disable company',
      Message: 'This will prevent users from accessing the company and its data.',
      ConfirmText: 'Disable',
      Style: 'warn',
      ExtraWarning: '',
      CancelText: 'No'
    });

    ref.subscribe((proceed: boolean) => {
      if (!proceed) {
        return event.source.checked = true;
      }
      toggle();
    });
  }

  public setTrialExp(date: Date) {
    this.company.TrialExpirationDate = date;
    this.checkSavedEvent.emit();
  }

  public setCountry($event: Country) {
    this.newCountryEvent.emit($event);
  }

  public setMaxUsers(change: MatSelectChange) {
    this.maxUsersEvent.emit(change.value);
  }
}
