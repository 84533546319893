<indicio-modal-base [child]="this"
                    modalTitle="Import variable from Kolada"
                    [loading]="loading"
                    modalExtraClass="small"
                    extraClasses="small">

  <ng-container *ngIf="!loading && kpi">
    <h2>{{ member.member_title }}</h2>
    <span>{{ kpi.description }}</span>

    <div class="row margin-top">
      <div class="col-xs-3">Område:</div>
      <div class="col-xs-9">
        {{ kpi.operating_area }}
      </div>
    </div>

    <hr class="margin-top">

    <form (ngSubmit)="addVariable()"
          ngNativeValidate>
      <div class="row extra-margin-top">
        <div class="col-xs-12">
          <mat-form-field>
            <mat-label>Välj namn</mat-label>
            <input matInput
                   class="material"
                   type="text"
                   [(ngModel)]="variableName"
                   (ngModelChange)="checkName()"
                   [ngModelOptions]="{standalone: true}"
                   required>
            <mat-hint *ngIf="nameConflict">
              <span class="error-text">A source variable with that name already exists.</span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row"
           *ngIf="kpi.is_divided_by_gender">
        <div class="col-xs-12 input-label">
          <indicio-searchable-dropdown label="Kön"
                                       optionDisplay="Display"
                                       [values]="genderOptions"
                                       [required]="true"
                                       (valueChangeEvent)="gender = $event.Value">
          </indicio-searchable-dropdown>
        </div>
      </div>

      <div class="row"
           *ngIf="kpi.municipality_type === 'K' || kpi.municipality_type === 'A'">
        <div class="col-xs-12 input-label">
          <indicio-searchable-dropdown label="Kommun"
                                       optionDisplay="title"
                                       [values]="kommuner"
                                       [required]="!chosenMunicipality"
                                       [selectedValue]="chosenMunicipalityModel"
                                       (valueChangeEvent)="setMunicipality($event)">
          </indicio-searchable-dropdown>
        </div>
      </div>

      <div class="row"
           *ngIf="kpi.municipality_type === 'L' || kpi.municipality_type === 'A'">
        <div class="col-xs-12 input-label">
          <indicio-searchable-dropdown label="Landsting"
                                       optionDisplay="title"
                                       [values]="landsting"
                                       [required]="!chosenMunicipality"
                                       [selectedValue]="chosenMunicipalityModel"
                                       (valueChangeEvent)="setMunicipality($event)">
          </indicio-searchable-dropdown>
        </div>
      </div>

      <div class="row"
           *ngIf="kpi.has_ou_data && ous && chosenMunicipality !== '0000'">
        <div class="col-xs-12 input-label">
          <indicio-searchable-dropdown label="Välj organisation"
                                       optionDisplay="title"
                                       [values]="ous"
                                       [required]="!chosenMunicipality"
                                       (valueChangeEvent)="chosenUO = $event.id">
          </indicio-searchable-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="btn-wrapper extra-margin-top">
          <div class="col-xs-6">
            <button class="btn outline blue left"
                    type="button"
                    (click)="close()">Back</button>
          </div>
          <div class="col-xs-6">
            <button type="submit"
                    [class]="{ disabled: nameConflict }"
                    class="btn pull-right">
              <i class="ion-load-c spinner"
                 *ngIf="pending"></i>
              <span *ngIf="!pending">Import</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</indicio-modal-base>
