<h1 mat-dialog-title>Themes</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-xs-6">
      <mat-form-field>
        <mat-select [(value)]="selectedTheme"
                    [required]="true">
          <mat-option [value]="theme"
                      *ngFor="let theme of themes">{{ theme.Name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xs-6"
         style="margin-top: 8px">
      <button (click)="deleteTheme(selectedTheme)"
              mat-button
              [ngClass]="{ disabled: ! selectedTheme }"
              [color]="selectedTheme ? 'warn' : null">Delete</button>
      <button (click)="updateTheme(selectedTheme)"
              mat-button
              [ngClass]="{ disabled: ! selectedTheme }"
              [color]="selectedTheme ? 'primary' : null">Save</button>
    </div>
  </div>

  <div class="row margin-top-extra">
    <div class="col-xs-12">
      <form (submit)="saveNewTheme()">
        <mat-form-field>
          <mat-label>Save a new theme</mat-label>
          <input data-test-id='theme-name-input-field'
                 type="text"
                 class="material"
                 matInput
                 autocomplete="off"
                 [(ngModel)]="newThemeName"
                 name="Name"
                 maxlength="64">
          <button [color]="newThemeName?.length ? 'primary' : null"
                  mat-button
                  [ngClass]="{ disabled: ! newThemeName?.length }"
                  matSuffix>Create</button>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">Cancel</button>
  <div class="green"
       *ngIf="statusmsg">{{ statusmsg }}</div>
  <button mat-button
          [mat-dialog-close]="selectedTheme"
          cdkFocusInitial>Use</button>
</div>
