import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import { SourceVariableModel, SourceVariableViewDTO } from '@core/store/source-variable/source-variable.model';
import { ActiveBenchmarksDTO } from './dtos/active-benchmarks.dto';
import { SourceVariableMetaDTO } from './dtos/source-variable-dto';
import { SyncOverviewDTO, SyncStateDTO } from './dtos/sync-state.dto';
import { ForecastBenchmarkModel } from './forecast-benchmark.model';

export class GetSourceVariableSuccessAction {
  public static readonly type = '[Sourcevariable] Get sourcevariable success';
  constructor(public variable: SourceVariableModel) { }
}

export class GetAllSourceVariablesSuccessAction {
  public static readonly type = '[Sourcevariable] Get all sourcevariables';
  constructor(public variables: SourceVariableModel[]) { }
}

export class RemovedSourceVariablesInCompanyAction {
  public static readonly type = '[Sourcevariable] Remove sourcevariables in company';
  constructor(public companyId: string) { }
}

export class DeleteSourceVariableSuccessAction {
  public static readonly type = '[Sourcevariable] Remove sourcevariable';
  constructor(public SourceVariableId: string) { }
}

export class GetSourceVariableVersionSuccessAction {
  public static readonly type = '[Sourcevariable] Get sourcevariable version success';
  constructor(public version: SourceVariableVersionModel) { }
}

export class DeleteSourceVariableVersionSuccessAction {
  public static readonly type = '[Sourcevariable] Remove sourcevariable version';
  constructor(public model: SourceVariableVersionModel) { }
}

export namespace SourceVariableActions {

  export class FetchSourceVariable {
    public static readonly type = '[Sourcevariable] Fetch/sync source variable';
    constructor(public companyId: string, public sourceId: string) { }
  }

  export class GetActiveForecastBenchmarksSuccess {
    public static readonly type = '[Sourcevariable] Get active forecast benchmarka';
    constructor(public active: ActiveBenchmarksDTO[]) { }
  }

  export class RemovedSourceVariable {
    public static readonly type = '[Sourcevariable] Removed source variable';
    constructor(public sourceVariableId: string) { }
  }

  export class CreateForecastBenchmarkSuccess {
    public static readonly type = '[Sourcevariable] Create forecast benchmark';
    constructor(public model: ForecastBenchmarkModel, public fverId: string) { }
  }

  export class UpdateForecastBenchmarkSuccess {
    public static readonly type = '[Sourcevariable] Update forecast benchmark';
    constructor(public model: ForecastBenchmarkModel) { }
  }

  export class DeleteForecastBenchmarksForVariableSuccess {
    public static readonly type = '[Sourcevariable] Delete forecast benchmark';
    constructor(public benchId: string, public sourceId: string) { }
  }

  export class ToggleForecastBenchmarkSuccess {
    public static readonly type = '[Sourcevariable] Toggle forecast benchmark';
    constructor(public benchId: string, public fverId: string) { }
  }

  export class GetForecastBenchmarksForVariableSuccess {
    public static readonly type = '[Sourcevariable] Get forecast benchmarks for variable';
    constructor(public models: ForecastBenchmarkModel[]) { }
  }

  export class GetForecastBenchmarkSuccess {
    public static readonly type = '[Sourcevariable] Get forecast benchmark';
    constructor(public model: ForecastBenchmarkModel) { }
  }

  export class GetUsageSuccess {
    public static readonly type = '[Sourcevariable] Get source variable usage';
    constructor(public sourceVariableId: string, public used: boolean) { }
  }

  export class GetSourceVariableMetaSuccessAction {
    public static readonly type = '[Sourcevariable] Get updated sourcevariable meta success';
    constructor(public sourceVariableId: string, public metaDTO: SourceVariableMetaDTO) { }
  }

  export class SyncListView {
    public static readonly type = '[Sourcevariable] Sync list view';
    constructor(public companyId: string) { }
  }

  export class RemoteBulkSync {
    public static readonly type = '[Sourcevariable] Remote bulk sync';
    constructor(public companyId: string, public dto: SyncStateDTO) { }
  }

  export class RemoteBulkSyncOverview {
    public static readonly type = '[Sourcevariable] Remote bulk sync overview';
    constructor(public companyId: string, public dto: SyncOverviewDTO) { }
  }

  export class GetListViewSuccessAction {
    public static readonly type = '[Sourcevariable] Get list view success';
    constructor(public sourceVariablesListView: SourceVariableViewDTO[]) { }
  }
}
