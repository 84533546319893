
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { ConfirmedNewLocationDTO, NewLocationInfoDTO, VariableUpdateInfoDTO } from '@core/store/file/dtos/variable-update-info-dto';
import { FileFrontendService } from '@core/store/file/file.frontend.service';
import { FileInfoModel } from '@core/store/file/models/file-info.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { ValueUtils } from '@shared/utils/value.utils';

export interface PreFileUploadDialogData {
  Info: FileInfoModel;
}

@Component({
  selector: 'indicio-pre-file-upload-dialog',
  templateUrl: 'pre-file-upload.dialog.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['pre-file-upload.dialog.less']
})
export class PreFileUploadDialogComponent {
  public static Id: string = 'PreFileUploadDialogComponent';

  public title: string;
  public info: FileInfoModel;

  constructor(
    public dialogRef: MatDialogRef<PreFileUploadDialogComponent>,
    private dialog: DialogService,
    private fileService: FileFrontendService,
    private status: StatusService,
    @Inject(MAT_DIALOG_DATA) public data: PreFileUploadDialogData
  ) {
    this.title = 'Update file: ' + data.Info.OldFile.FileName;
    this.info = data.Info;
    this.markNotFoundAsExcluded();
  }

  private markNotFoundAsExcluded() {
    this.info.VariableLocations.forEach(loc => {
      if (!this.info.isLocationComplete(loc)) {
        loc.SkipUpdate = true;
      }
    });
  }

  public isNum(num) { return ValueUtils.isNum(num, false); }

  public getConfirmedLocation(variable: VariableUpdateInfoDTO) {
    const loc = this.info.VariableLocations.find(x => x.SourceVariableId === variable.SourceVariableId);
    if (this.info.isLocationComplete(loc)) {
      this.syncFileColumnDiffForLocation(variable, loc);
    }
    return loc;
  }

  public setNewSheet(newSheetIndex: number, location: ConfirmedNewLocationDTO) {
    if (newSheetIndex === -2) {
      location.SkipUpdate = true;
      location.NewSheet = -3;
      setTimeout(() => {
        location.NewSheet = -1;
      }, 0);
      return;
    }
    location.NewSheet = newSheetIndex;
    location.SkipUpdate = false;
    const allDateCols = this.info.getAllPossibleDateColumnIndexes(newSheetIndex);
    if (allDateCols.length === 1) {
      location.NewDateIndex = allDateCols[0];
    }
  }

  public setNewDateCol(newDateIndex: number, location: ConfirmedNewLocationDTO) {
    if (newDateIndex === -2) {
      location.SkipUpdate = true;
      location.NewDateIndex = -3;
      setTimeout(() => {
        location.NewDateIndex = -1;
      }, 0);
      return;
    }
    location.NewDateIndex = newDateIndex;
    location.SkipUpdate = false;
  }

  public setNewValueCol(newValueIndex: number, location: ConfirmedNewLocationDTO) {
    if (newValueIndex === -2) {
      location.SkipUpdate = true;
      location.NewValueIndex = -3;
      setTimeout(() => {
        location.NewValueIndex = -1;
      }, 0);
    } else {
      location.NewValueIndex = newValueIndex;
      location.SkipUpdate = false;
    }
  }

  public openFileColumnDiffDialog(variable: VariableUpdateInfoDTO, location: ConfirmedNewLocationDTO) {
    if (!this.info.isLocationComplete(location)) { return; }
    this.syncFileColumnDiffForLocation(variable, location)
      .then(ans => {
        if (!ans) { return; }
        this.openFileColumnDialog(location, ans.forbidDiff);
      });
  }

  public syncFileColumnDiffForLocation(variable: VariableUpdateInfoDTO, location: ConfirmedNewLocationDTO) {
    const complete = this.info.isLocationComplete(location);
    if (!complete) { return Promise.reject(null); }
    let newLoc = variable.NewLocations.find(x => x.NewDateIndex === location.NewDateIndex && x.NewSheet === location.NewSheet && x.NewValueIndex === location.NewValueIndex);
    let forbidDiff = false;
    if (!newLoc) {
      newLoc = new NewLocationInfoDTO();
      newLoc.Diff = null;
      newLoc.Exact = false;
      newLoc.NewDateIndex = location.NewDateIndex;
      newLoc.NewSheet = location.NewSheet;
      newLoc.NewValueIndex = location.NewValueIndex;
      const newHashRowInfo = variable.NewHashStartRows.find(x => x.m_Item1 === location.NewSheet && x.m_Item2 === location.NewDateIndex);
      if (newHashRowInfo) {
        newLoc.Offset = newHashRowInfo.m_Item3;
      } else {
        forbidDiff = true;
      }
      newLoc.forbidDiff = forbidDiff;
      variable.NewLocations.push(newLoc);
    }
    if (!newLoc.Diff && !newLoc.diffLoading) {
      newLoc.diffLoading = true;
      return this.fileService.getFileColumnDiff(this.info, newLoc, variable)
        .then(resp => {
          newLoc.Diff = resp;
          location.Diff = newLoc.Diff;
          return { newLoc, forbidDiff };
        })
        .catch(err => {
          this.status.setError(err, true);
          return { newLoc, forbidDiff };
        })
        .finally(() => newLoc.diffLoading = false)
        ;
    }
    location.Diff = newLoc.Diff;
    return Promise.resolve({ newLoc, forbidDiff: newLoc.forbidDiff });
  }

  public getPossibleSheetIndexes(variable: VariableUpdateInfoDTO) {
    let possibleIndexes = [];
    if (variable.NewLocations.length) {
      possibleIndexes = variable.NewLocations.map(x => x.NewSheet);
    } else {
      possibleIndexes = this.info.UpdateInfo.Preview.map((_, index) => index);
    }
    return possibleIndexes;
  }

  private openFileColumnDialog(location: ConfirmedNewLocationDTO, forbidDiff: boolean) {
    const dateIdx = location.NewDateIndex;
    const valueIdx = location.NewValueIndex;
    const data = this.info.UpdateInfo.Preview;
    const dates = data[location.NewSheet].ParsedColumns[dateIdx];
    const values = data[location.NewSheet].ParsedColumns[valueIdx];
    const variable = this.info.getVariable(location.SourceVariableId);
    const newLoc = this.info.getNewLocation(location.SourceVariableId, location.NewSheet, dateIdx, valueIdx);
    const diff = newLoc?.Diff || null;
    this.dialog.openFileColumnDialog({
      Location: location,
      VariableName: variable.Name,
      DateCol: dates.Raw,
      ValueCol: values.Raw,
      DisabledRows: dates.DisabledRows,
      Diff: diff,
      ForbidDiff: forbidDiff
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(true);
  }
}

