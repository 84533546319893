<indicio-modal-base [child]="this"
                    modalTitle="Remove indicator(s)"
                    [loading]="isLoading"
                    bodyExtraClass="overflow-auto">
  <p *ngIf="variables.length > 1">Are you sure you want to delete these indicators?</p>
  <p *ngIf="variables.length === 1">Are you sure you want to delete this indicator?</p>

  <div class="row">
    <div class="col-xs-12">

      <div class="data-table">
        <div class="data-table__header">
          <div class="column header"
               style="max-width: 40px; min-width: 40px;">#</div>
          <div class="column header"
               style="width: 360px;">Indicator</div>
          <div class="column"
               style="width: 90px;">
            &nbsp;
          </div>
        </div>
      </div>

      <div class="data-table__body"
           [style.maxHeight.px]="301">
        <div class="body__row body__row--center-height body__row--max-height-30"
             *ngFor="let variable of variables; let i = index">
          <div class="column"
               style="min-width: 40px;">{{ i + 1 }}</div>
          <div class="column"
               style="width: 360px;">
            <div class="row">
              <div class="col-xs-12 text-left crop-string"
                   [title]="variable.Name">
                {{ variable.Name }}
              </div>
            </div>
          </div>
          <div class="column"
               style="width: 90px;">
            <ng-container *ngIf="variable.removedStatus === 'Failed'; else status">
              <span class="red"
                    [indicioTippy]="variable.failedMsg">Failed</span>
            </ng-container>
            <ng-template #status>
              {{ variable.removedStatus === 'Success' ? 'Removed' : variable.removedStatus }}
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row extra-margin-top">
    <div class="col-xs-12 text-right"
         style="padding-right: 20px;">
      <ng-container *ngIf="pending || removedCount !== 0">
        Removed: {{removedCount }}/{{ variables.length}}
      </ng-container>
      <ng-container *ngIf="!pending && removedCount === 0">
        Selected: {{ variables.length }}/{{ variables.length }}
      </ng-container>
    </div>
  </div>

  <div class="row extra-margin-top">
    <div class="col-xs-12 center-text">
      <button class="btn full-width"
              data-test-id="confirm-removal-indicators"
              [disabled]="pending"
              (click)="removeVariables()"
              *ngIf="variables?.length && removedCount !== variables.length">
        <span *ngIf="!pending">Remove</span>
        <i class="ion-load-c spinner"
           *ngIf="pending"></i>
      </button>

      <button class="btn full-width"
              (click)="close()"
              *ngIf="!variables?.length || removedCount === variables.length">
        {{ removedCount > 0 ? 'Done' : 'Close' }}
      </button>
    </div>
  </div>
</indicio-modal-base>
