import { Injectable } from '@angular/core';
import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { DateUtils } from '@shared/utils/date.utils';
import * as moment from 'moment';
import { CreateReportDTO, ReportDTO, ReportEntryDTO, ReportMetaDTO, UpdateReportDTO } from './dtos/report.dtos';
import { ReportEntryModel, ReportModel } from './models/report.model';

@Injectable({
  providedIn: 'root'
})
export class ReportMapper {

  constructor(
  ) { }

  public mapReport(dto: ReportDTO | ReportMetaDTO, fiscalYear: number, today: moment.Moment = DateUtils.newMoment()) {
    const model = Object.faMapTo(new ReportModel, dto);
    model.today = today;
    model.reportYear = DateUtils.newMoment(dto.StartDate).year();
    model.StartDate = DateUtils.newMoment().year(model.reportYear).month(fiscalYear).startOf('month').toDate();
    model.startDate = DateUtils.newMoment(model.StartDate);
    model.reportEndDate = model.startDate.clone().add(11, 'months');
    model.currentToDate = DateUtils.newMoment(today).year(model.startDate.year());
    model.FiscalYear = fiscalYear;
    model.pyFyTDStart = model.startDate.clone().subtract(12, 'months');
    model.pyFyTDEnd = model.currentToDate.clone().subtract(12, 'months');
    if (dto.hasOwnProperty('Entries')) {
      model.Entries = dto['Entries'].map(x => this.mapReportEntry(x, model));
      model.fetched = true;
    }
    return model;
  }

  public mapReportEntry(dto: ReportEntryDTO, report: ReportModel) {
    let model = Object.faMapTo(new ReportEntryModel, dto);
    model.ForecastStartDate = DateUtils.newDate(dto.ForecastStartDate);
    model.LastForecastedDate = DateUtils.newDate(dto.LastForecastedDate);
    model.lastForecastedDate = DateUtils.newMoment(dto.LastForecastedDate);
    model.HistoricValues.forEach(x => DateUtils.MapIHasDate(x));
    model.lastHistoricDate = model.HistoricValues.slice(0).pop().m;
    const resultExists = !!model.SelectedResultId && model.ForecastResults.some(x => x.ForecastResultId === model.SelectedResultId);
    if (!resultExists) { model.SelectedResultId = undefined; }
    if (!model.SelectedResultId && model.ForecastResults?.length) {
      model.SelectedResultId = model.ForecastResults[0].ForecastResultId;
    } else if (model.SelectedResultId && !model.ForecastResults?.length) {
      model.SelectedResultId = undefined;
    }
    model.currentFromDate = DateUtils.newMoment(model.ForecastStartDate).isBefore(report.startDate)
      ? DateUtils.newMoment(report.startDate)
      : DateUtils.newMoment(model.ForecastStartDate);
    model.currentToDate = DateUtils.newMoment(report.today).year(report.startDate.year()).subtract(1, dto.Periodicity as any).endOf(dto.Periodicity as any);
    model.pyFyStart = report.startDate.clone().subtract(1, 'year');
    model.pyFyToDate = DateUtils.newMoment(report.today).year(model.pyFyStart.year()).subtract(1, dto.Periodicity as any).endOf(dto.Periodicity as any);
    model = this.remapReportEntry(model, report);
    return model;
  }

  public remapReportEntry(model: ReportEntryModel, report: ReportModel): ReportEntryModel {
    const resultValues = model.SelectedResultId
      ? model.ForecastResults.find(x => x.ForecastResultId === model.SelectedResultId).Values
      : [];
    resultValues.forEach(x => DateUtils.MapIHasDate(x));
    const toKeep = model.HistoricValues.slice(0).filter(x => x.m.isBefore(model.ForecastStartDate));
    model.values = <PlotValueDTO[]> toKeep.concat(resultValues);
    if (resultValues.length) {
      const endOfResult = model.lastForecastedDate.clone().endOf(model.Periodicity as any);
      model.forecastTooShort = endOfResult.isBefore(report.reportEndDate);
    }
    return model;
  }

  public toCreate(report: ReportModel) {
    const dto = Object.faMapTo(new CreateReportDTO, report);
    return dto;
  }

  public toUpdate(report: ReportModel) {
    const dto = Object.faMapTo(new UpdateReportDTO, report);
    dto.StartDate = DateUtils.convertToBackendDate(report.startDate);
    return dto;
  }
}
