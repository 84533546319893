import { HistoricValueDTO, PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import * as moment from 'moment';

export class OverviewReportEntry {
  public Name: string;
  public Periodicity: PeriodicityType;
  public StartDate: moment.Moment;
  public EndDate: moment.Moment;
  public ForecastStartDate: moment.Moment;
  public LastForecastedDate: moment.Moment;
  public ForecastId: string;
  public ForecastVersionId: string;
  public HistoricValues: HistoricValueDTO[] = [];
  public ForecastedValues: PlotValueDTO[] = [];
  public ForecastVariableTransform: string;
  public IsPercent: boolean;

  // Frontend stuffz
  public noResultsAvailable: boolean = false;
}

export class OverviewReport {
  public Name: string;
  public Entries: OverviewReportEntry[] = [];
  public StartDate: moment.Moment;
  public EndDate: moment.Moment;
  public HistoricValueLimit: number = 20;

  public dates: moment.Moment[] = [];
  public dateJumps: number = 0;
}
