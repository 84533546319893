import { AccuracyMeasureType } from '@core/constants/accuracy.constants';

export namespace StatisticsUtils {

  /** See: {@link AccuracyMeasureType | AccuracyMeasureType } */
  export class Accuracies {
    public RMSE: number;
    public MAPE: number;
    public ME: number;
    public MAE: number;
    public MPE: number;
    public HR: number;
    public MASE: number;
    public RSQ: number;
  }

  /**
   * Slower function to return all accuracy measures given a forecast; used only for one-time off calculations.
   * @param forecasts The forecasted values
   * @param actuals The historical values
   * @returns an object of type Accuraciess
   */
  export function getAccuracyMeasures(forecasts: number[], actuals: number[]): Accuracies {
    if (forecasts.length !== actuals.length)
      throw 'Forecasts length cannot differ from actuals length';

    return {
      HR: Internal.getHr(forecasts, actuals),
      MAE: Internal.getMae(forecasts, actuals),
      MASE: Internal.getMase(forecasts, actuals),
      MAPE: Internal.getMape(forecasts, actuals),
      MPE: Internal.getMpe(forecasts, actuals),
      ME: Internal.getMe(forecasts, actuals),
      RSQ: Internal.getRsq(forecasts, actuals),
      RMSE: Internal.getRmse(forecasts, actuals),
    };
  }

  /**
   *
   * @param values The array of values.
   * @param index The index for which to calculate a new rolling number.
   * @param x The number of observations, before and including index, to include in the calculation.
   * @param average If true, the average of the sum is returned.
   * @returns
   */
  export function getLastXRolling(values: number[], index: number, x: number, average: boolean = false) {
    let sum = 0;
    values.forEach((v, i) => {
      if (i > index - x && i <= index) {
        sum += v;
      }
    });

    if (average) return sum / x;
    else return sum;
  }

  export function roundNum(num: number, significant: number) {
    const signi = Math.pow(10, significant - 1);
    return Math.round(num * signi) / signi;
  }

  export function getDiff(data: number[]) {
    return data.map((d, idx) => idx !== 0 ? d - data[idx - 1] : null).filter(x => x !== null);
  }

  export function getPercentile(data: number[], p: number) {
    if (data.length < 1) return 0;
    const sorted = [...data].sort((a, b) => a - b);
    const halfIdx = Math.max(Math.floor(sorted.length * (p / 100)) - 1, 0);
    return sorted[halfIdx];
  }

  export function getStd(data: number[], isPopulation: boolean = false): number {
    const mean = data.avg();
    const squared = data.map(v => Math.pow(v - mean, 2));
    const reduced = squared.sum() / (isPopulation ? squared.length : squared.length - 1);
    return Math.sqrt(reduced);
  }

  /**
   * This namespace contains performance optimized functions for retrieving/calculating specific accuracy-measurements.
   */
  namespace Internal {

    export function getMase(forecasts: number[], actuals: number[]) {
      if (actuals.length < 2)
        throw 'Need more than 2 observations to calculate MASE.';

      let acc = 0;
      for (let i = 1; i < actuals.length; i++) {
        acc += Math.abs(actuals[i] - actuals[i - 1]);
      }
      const mae = getMae(forecasts, actuals);
      return mae / ((acc / (actuals.length - 1)) + Number.EPSILON);
    }

    export function getMape(forecasts: number[], actuals: number[]) {
      let acc = 0;
      for (let i = 0; i < actuals.length; i++) {
        acc += Math.abs((actuals[i] - forecasts[i]) / (actuals[i] + Number.EPSILON) * 100);
      }
      return acc / actuals.length;
    }

    export function getPe(forecasts: number[], actuals: number[]) {
      const pe = new Float32Array(actuals.length);
      for (let i = 0; i < actuals.length; i++) {
        pe[i] = (actuals[i] - forecasts[i]) / (actuals[i] + Number.EPSILON) * 100;
      }
      return pe;
    }

    export function getMpe(forecasts: number[], actuals: number[]) {
      let acc = 0;
      for (let i = 0; i < actuals.length; i++) {
        acc += (actuals[i] - forecasts[i]) / (actuals[i] + Number.EPSILON) * 100;
      }
      return acc / actuals.length;
    }

    export function getMe(forecasts: number[], actuals: number[]) {
      let acc = 0;
      for (let i = 0; i < actuals.length; i++) {
        acc += actuals[i] - forecasts[i];
      }
      return acc / actuals.length;
    }

    export function getMae(forecasts: number[], actuals: number[]) {
      let acc = 0;
      for (let i = 0; i < actuals.length; i++) {
        acc += Math.abs(actuals[i] - forecasts[i]);
      }
      return acc / actuals.length;
    }

    export function getRmse(forecasts: number[], actuals: number[]) {
      let sumOfErrSquared = 0;
      for (let i = 0; i < actuals.length; i++) {
        const error = actuals[i] - forecasts[i];
        sumOfErrSquared += (error * error);
      }
      return (Math.sqrt(sumOfErrSquared / actuals.length));
    }

    export function getRsq(forecasts: number[], actuals: number[]) {
      const actualMean = actuals.avg();
      let sumOfErrSquared = 0;
      for (let i = 0; i < actuals.length; i++) {
        const error = actuals[i] - forecasts[i];
        sumOfErrSquared += (error * error);
      }
      return Math.max(1 - sumOfErrSquared / actuals.map(a => Math.pow(a - actualMean, 2)).sum(), 0);
    }

    export function getHr(forecasts: number[], actuals: number[]) {
      const signs = getDiff(actuals).map(d => Math.sign(d));
      const fSigns = forecasts.slice(1).map((f, idx) => f - actuals[idx]).map(d => Math.sign(d));
      const hits = signs.map((s, idx) => s === fSigns[idx] ? 1 : 0).sum();
      return hits / (actuals.length - 1);
    }
  }
}
