import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { ForecastModelFilterSettingsDTO } from './dtos/model-filters/forecast-model-filter-settings-dto';
import { GetForecastModelFilterSettingsAction } from './forecast.actions';
import { ForecastBackendService } from './forecast.backend.service';
import { ForecastFrontendService } from './forecast.frontend.service';

@Injectable({
  providedIn: 'root'
})
export class ModelFiltersFrontendService {

  constructor(
    private store: Store,
    private backendService: ForecastBackendService,
    private frontendService: ForecastFrontendService
  ) {
  }

  /**
   *
   * MODEL FILTER SETTINGS
   *
   */
  // GET
  public getForecastModelFilterSettings(forecastId: string) {
    const forecast = this.frontendService.forecastById(forecastId);
    if (!forecast.ModelFilterSettings) {
      return this.fetchForecastModelFilterSettings(forecastId);
    } else {
      return Promise.resolve(forecast.ModelFilterSettings);
    }
  }

  public fetchForecastModelFilterSettings(forecastId: string) {
    return this.backendService.getForecastModelFilterSettings(forecastId)
      .then(dto => {
        this.store.dispatch(new GetForecastModelFilterSettingsAction(forecastId, dto));
        return dto;
      });
  }

  // SET
  public saveForecastModelFilterSettings(forecastId: string, dto: ForecastModelFilterSettingsDTO) {
    return this.backendService.saveForecastModelFilterSettings(forecastId, dto)
      .then(dtoX => {
        this.store.dispatch(new GetForecastModelFilterSettingsAction(forecastId, dtoX));
        return dtoX;
      });
  }

  // DELETE
  public removeForecastModelFilterSettings(forecastId: string, filterId: string) {
    return this.backendService.removeForecastModelFilterSettings(forecastId, filterId)
      .then(dto => {
        this.store.dispatch(new GetForecastModelFilterSettingsAction(forecastId, dto));
        return dto;
      });
  }
}
