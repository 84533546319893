import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CompanyMapper } from '@core/store/company/company-mapper';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CompanyModel, LicenseType } from '@core/store/company/company.model';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { AdminFrontendService } from '@modules/admin/services/admin-frontend.service';
import { Country } from '@modules/lang/types/country';
import { DateUtils } from '@shared/utils/date.utils';

export interface EditCompanyAdminDialogData {
  CompanyId: string;
  View?: AdminCompanyDialogViewIndex;
}

export enum AdminCompanyDialogViewIndex {
  'general' = 0,
  'users' = 1,
  'providers' = 2,
  'audit' = 3,
  'settings' = 4,
}

@Component({
  selector: 'indicio-edit-company-admin-dialog',
  templateUrl: 'edit-company.admin.dialog.html',
  styleUrls: ['edit-company.admin.dialog.less']
})
export class EditCompanyAdminDialogComponent extends DialogV2BaseDialog<EditCompanyAdminDialogComponent> {

  public static Id: string = 'EditCompanyAdminDialogComponent';

  // Options
  public viewIndex: number;
  public viewTypes = AdminCompanyDialogViewIndex;

  // Models
  public company: CompanyModel = null;

  // Update properties
  public country: Country;
  public maxUserCount: number;
  private savedEmployees: string;
  private savedProviders: string;
  private savedLicense: LicenseType;
  private savedHorizons: string;
  private savedSupport: string;
  private trialDate: Date;
  private maxRollingTime: number;

  // Flags
  public saveActive: boolean = false;

  public minDate: Date = DateUtils.newDate();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: EditCompanyAdminDialogData,
    private status: StatusService,
    private companyMapper: CompanyMapper,
    private cd: ChangeDetectorRef,
    private adminService: AdminFrontendService,
    private companySerive: CompanyFrontendService,
    private authService: AuthFrontendService,
    dialogRef: MatDialogRef<EditCompanyAdminDialogComponent>
  ) {
    super(dialogRef);
    this.initialize();
  }

  public changeView(index: number) {
    this.viewIndex = index;
    this.cd.detectChanges();
  }

  public setNewCountry(country: Country) {
    this.country = country;
    this.checkNeedSave();
  }

  public setSupport(support: string[]) {
    this.company.Support = support;
    this.checkNeedSave();
  }

  public setMaxUsers(count: number) {
    this.maxUserCount = count;
    this.checkNeedSave();
  }

  public checkNeedSave() {
    const newCountrySet = this.country.Value !== this.company.Country.Value;
    const newMaxUsersSet = this.maxUserCount !== this.company.MaxUsers;
    const rolesChanged = this.savedEmployees !== JSON.stringify(this.company.Employees);
    const providersChanged = this.savedProviders !== JSON.stringify(this.company.DisabledProviders);
    const licenseChanged = this.savedLicense !== this.company.LicenseType;
    const trialChanged = this.trialDate !== this.company.TrialExpirationDate;
    const rollingTimeChanged = this.maxRollingTime !== this.company.MaxRollingWindowTime;
    const horizonsChanged = this.savedHorizons !== JSON.stringify(this.company.MaxHorizons);
    const supportChanged = this.savedSupport !== JSON.stringify(this.company.Support);
    this.saveActive = newCountrySet || newMaxUsersSet || rolesChanged || providersChanged || licenseChanged || trialChanged || rollingTimeChanged || horizonsChanged || supportChanged;
  }

  protected initialize() {
    if (!this.authService.isAdmin) {
      this.status.setMessage('Permission denied', 'Error');
      this.close();
    }

    this.viewIndex = this.data.View ?? 0;
    const cPromise = this.companySerive.getOrFetchCompany(this.data.CompanyId);

    Promise.all([cPromise])
      .then(([c]) => {
        this.company = c;
        this.setup();
      })
      .catch(err => this.status.setError(err))
      .finally(() => {
        this.initialized = true;
        this.cd.detectChanges();
      });
  }

  public async save() {
    this.company.CountryCode = this.country.Value;
    this.company.MaxUsers = this.maxUserCount;
    const dto = this.companyMapper.toAdminUpdate(this.company);
    this.inProgress = true;
    return this.adminService.updateCompany(dto)
      .then(() => this.status.setMessage('Company settings saved', 'Success', true))
      .catch(error => this.status.setError(error, true))
      .finally(() => {
        this.setup();
        this.inProgress = false;
      });
  }

  public setup() {
    this.country = this.company.Country;
    this.maxUserCount = this.company.MaxUsers;
    this.trialDate = this.company.TrialExpirationDate;
    this.maxRollingTime = this.company.MaxRollingWindowTime;
    this.savedLicense = this.company.LicenseType;
    this.savedEmployees = JSON.stringify(this.company.Employees);
    this.savedProviders = JSON.stringify(this.company.DisabledProviders);
    this.savedHorizons = JSON.stringify(this.company.MaxHorizons);
    this.savedSupport = JSON.stringify(this.company.Support);
    this.checkNeedSave();
    this.inProgress = false;
  }
}
