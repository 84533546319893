import { Component } from '@angular/core';
import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AssessmentFrontendService } from '@core/store/assessment/assessment.frontend.service';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { UpdatedUserPermissionsAction } from '@core/store/client/client.actions';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateFormatPipe } from '@shared/modules/pipes/date-format.pipe';
import { ArrayUtils } from '@shared/utils/array.utils';
import { DateUtils } from '@shared/utils/date.utils';
import { TransformationUtils } from '@shared/utils/forecast/transformation.utils';
import { AssessmentModalOpts } from './assessment-modal.options';


@Component({
  selector: 'indicio-assessment-modal',
  templateUrl: './assessment-modal.component.html',
  styleUrls: ['../assessment-modal.less']
})
export class AssessmentModalComponent extends ModalModelComponent {

  allData: PlotValueDTO[] = null;
  data: PlotValueDTO[] = null;
  dateOptions: { Display: string; Value: string; }[] = [];
  edit = false;
  isCreator = true;
  isRocType = false;
  newValue = '';
  oldValue = '';
  timePoint: string = null;
  valueTitle = 'Value';

  callback: Function = null;
  onBack: Function = null;
  assessment: AssessmentModel = null;
  forecastVersion: ForecastVersionModel;
  forecast: ForecastModel;
  project: ProjectModel;
  modalTitle = 'Assessment information';

  userCanCreateAssessment = false;
  userCanUpdateAssessment = false;

  savedState = {
    TimePoint: null,
    Quantity: null,
    Unit: null,
    Source: null,
    Reason: null,
    Category: null
  };

  public get isStateChanged() {
    return this.modalState.isChanged('assessment', {
      TimePoint: this.timePoint,
      Quantity: this.assessment.Quantity,
      Unit: this.assessment.Unit,
      Source: this.assessment.Source,
      Reason: this.assessment.Reason,
      Category: this.assessment.Category
    });
  }

  public get isDisabled() {
    return this.assessment.Category == null
      || this.assessment.Unit == null
      || this.assessment.TrustLevel == null
      || this.assessment.Reason == null
      || this.assessment.Quantity == null
      || this.assessment.TimePoint == null;
  }

  constructor(
    protected store: Store,
    private profileS: ProfileFrontendService,
    public environment: EnvironmentService,
    private status: StatusService,
    private assService: AssessmentFrontendService,
    private forecastService: ForecastFrontendService,
    private datePipe: DateFormatPipe,
    private actions: ActionService
  ) {
    super();
  }

  public setOptions(options: AssessmentModalOpts) {
    this.forecastVersion = options.forecastVersion;
    this.forecast = this.forecastService.forecastById(this.forecastVersion.ForecastId);
    this.project = options.project;
    this.callback = options.callback;
    this.onBack = options.onBack;
    this.assessment = options.assessment || new AssessmentModel();
    this.allData = options.inputData;
    this.edit = options.assessment !== null;
    this.setSavedState();
    if (this.edit) {
      this.isCreator = this.assessment.ClientEmail === this.profileS.profile.Email;
      this.figureOutType();
      this.setOldValue();
      this.timePoint = DateUtils.newMoment(this.assessment.TimePoint).format('YYYY-MM-DD');
      this.savedState.TimePoint = this.timePoint;
      this.updateValueAfter();
    } else {
      this.transformData();
      this.assessment.ForecastVersionId = this.forecastVersion.ForecastVersionId;
    }
    this.setHeader();
    this.isLoading = false;

    this.addSubscription(this.actions.dispatched(UpdatedUserPermissionsAction).subscribe(() => {
      this.setPermissions();
    }));

    this.setPermissions();
    this.modalState.setState('assessment', this.savedState);
  }

  private setSavedState() {
    const ass = this.assessment;
    this.savedState.Category = ass.Category;
    this.savedState.Quantity = ass.Quantity;
    this.savedState.Reason = ass.Reason;
    this.savedState.Source = ass.Source;
    this.savedState.Unit = ass.Unit;
    this.savedState.TimePoint = ass.TimePoint;
  }

  private setPermissions() {
    this.userCanCreateAssessment = this.project.hasPermission('CAN_CREATE_ASSESSMENT');
    this.userCanUpdateAssessment = this.project.hasPermission('CAN_UPDATE_ASSESSMENT');
  }

  private setHeader() {
    if (this.isCreator) {
      this.modalTitle = this.edit ? 'Edit assessment' : 'Add assessment';
    } else {
      this.modalTitle = 'Assessment information';
    }
  }

  public changeUnit() {
    this.transformData();
    this.updateDate();
  }

  private setOldValue() {
    if (this.isRocType) {
      this.oldValue = this.valuePipe.toPercent(this.assessment.OldValue);
    } else {
      this.oldValue = this.valuePipe.transform(this.assessment.OldValue);
    }
  }

  private figureOutType() {
    const transformation = this.assessment.Unit || 'percent';
    if (transformation === 'roc' || transformation === 'roc-yy') {
      this.isRocType = true;
    } else {
      this.isRocType = false;
    }
  }

  private transformData() {
    const transformation = this.assessment.Unit || 'percent';
    let cpy: PlotValueDTO[];
    switch (transformation) {
      case 'roc':
        cpy = ArrayUtils.copyArrayObjects(this.allData);
        this.data = TransformationUtils.getChange(cpy, 'V', TransformationUtils.ChangeType.percent).filter(x => x.I50 || x.A50);
        this.isRocType = true;
        this.valueTitle = 'ROC';
        break;
      case 'roc-yy':
        cpy = ArrayUtils.copyArrayObjects(this.allData);
        const freq = DateUtils.getFrequencyFromPeriodicity(this.forecast.Periodicity);
        this.data = TransformationUtils.getChangeYearly(cpy, 'V', freq, TransformationUtils.ChangeType.percent).filter(x => x.I50 || x.A50);
        this.isRocType = true;
        this.valueTitle = 'ROC Y-Y';
        break;
      default:
        this.data = this.allData.filter(x => x.I50 || x.A50);
        this.isRocType = false;
        this.valueTitle = 'Value';
        break;
    }

    this.dateOptions = [];
    for (const p of this.data) {
      this.dateOptions.push({
        Display: p.m.format('YYYY-MM'),
        Value: p.m.format('YYYY-MM-DD')
      });
    }
  }

  public updateDate() {
    this.assessment.TimePoint = DateUtils.newDate(this.timePoint);
    const cmp = x => {
      const m1 = DateUtils.newMoment(x.D);
      const m2 = DateUtils.newMoment(this.assessment.TimePoint);
      return m1.isSame(m2, 'day');
    };
    const data = this.data.find(cmp);
    if (data) {
      this.assessment.OldValue = data.V;
      this.setOldValue();
      this.updateValueAfter();
    }
  }

  public calcResult() {
    this.updateValueAfter();
  }

  public updateValueAfter() {
    if (!this.assessment.Unit || !this.assessment.OldValue) { return; }
    switch (this.assessment.Unit) {

      case 'percent':
        if (+this.assessment.Quantity === 0) {
          this.assessment.NewValue = this.assessment.OldValue;
        } else {
          this.assessment.NewValue = Math.round((this.assessment.Quantity / 100 + 1) * this.assessment.OldValue * 100) / 100;
        }
        break;

      case 'nominal':
        if (+this.assessment.Quantity === 0) {
          this.assessment.NewValue = this.assessment.OldValue;
        } else {
          this.assessment.NewValue = this.assessment.OldValue + this.assessment.Quantity;
        }
        break;

      case 'absolute':
        this.assessment.NewValue = +this.assessment.Quantity;
        break;

      case 'roc':
        this.assessment.NewValue = this.assessment.Quantity / 100;
        break;

      case 'roc-yy':
        this.assessment.NewValue = this.assessment.Quantity / 100;
        break;
    }

    if (this.isRocType) {
      this.newValue = this.valuePipe.toPercent(this.assessment.NewValue);
    } else {
      this.newValue = this.valuePipe.transform(this.assessment.NewValue);
    }
  }

  public back(updateGraphAfter: boolean = false) {
    if (this.callback && updateGraphAfter) { this.callback(); }
    this.close();
    if (this.onBack) {
      this.onBack();
    }
  }

  public save() {
    const promise = this.edit
      ? this.assService.updateAssessment(this.assessment)
      : this.assService.createAssessment(this.assessment);

    promise.then(() => {
      this.status.setMessage(this.edit ? 'Assessment updated' : 'Assessment created', 'Success', true);
      this.back(true);
    }).catch(error => {
      this.status.setError(error, true);
    });
  }
}
