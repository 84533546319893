export enum NotificationTypes {
  'new-forecast-variable' = 'new-forecast-variable',
  'updated-forecast-variable' = 'updated-forecast-variable',
  'updated-forecast-variable-settings' = 'updated-forecast-variable-settings',
  'updated-forecast-variable-outlier-info' = 'updated-forecast-variable-outlier-info',
  'removed-forecast-variable' = 'removed-forecast-variable',
  'refreshed-forecast-variable' = 'refreshed-forecast-variable',
  'updated-source-variable' = 'updated-source-variable',
  'removed-source-variable' = 'removed-source-variable',
  'new-source-variable' = 'new-source-variable',

  /*****************
   * HISTORIC EVENTS
   *****************/
  'new-historic-event-in-forecast' = 'new-historic-event-in-forecast',
  'updated-historic-event-in-forecast' = 'updated-historic-event-in-forecast',
  'removed-historic-event-from-forecast' = 'removed-historic-event-from-forecast',
  'new-historic-event' = 'new-historic-event',
  'removed-historic-event' = 'removed-historic-event',
  'updated-historic-event' = 'updated-historic-event',

  /*****************
   * ASSESSMENTS
   *****************/
  'removed-assessment' = 'removed-assessment',
  'new-assessment' = 'new-assessment',
  'updated-assessment' = 'updated-assessment',

  /*****************
   * FILES
   *****************/
  'new-file' = 'new-file',
  'new-file-version' = 'new-file-version',
  'updated-file' = 'updated-file',
  'removed-file' = 'removed-file',
  'file-ownership-change' = 'file-ownership-change',

  /*****************
   * FORECAST
   *****************/
  'new-forecast' = 'new-forecast',
  'new-nowcast' = 'new-nowcast',
  'new-forecast-version' = 'new-forecast-version',
  'updated-forecast' = 'updated-forecast',
  'updated-forecast-version' = 'updated-forecast-version',
  'removed-forecast' = 'removed-forecast',
  'removed-nowcast' = 'removed-nowcast',
  'removed-forecast-version' = 'removed-forecast-version',

  /*****************
   * FORECAST RESULT
   *****************/
  'new-forecast-result' = 'new-forecast-result',
  'new-nowcast-result' = 'new-nowcast-result',

  /*****************
   * FORECAST SCENARIO
   *****************/
  'new-forecast-scenario' = 'new-forecast-scenario',
  'updated-forecast-scenario' = 'updated-forecast-scenario',
  'deleted-forecast-scenario' = 'deleted-forecast-scenario',

  /*****************
   * COMPANY
   *****************/
  'new-company' = 'new-company',
  'updated-company' = 'updated-company',
  'removed-company' = 'removed-company',
  'new-company-invite' = 'new-company-invite',
  'removed-company-employee' = 'removed-company-employee',

  /*****************
   * PROJECT
   *****************/
  'removed-project-member' = 'removed-project-member',
  'removed-project' = 'removed-project',
  'updated-project' = 'updated-project',
  'new-project' = 'new-project',
  'new-project-invite' = 'new-project-invite',
  'member-left-project' = 'member-left-project',

  /*****************
   * USERS
   *****************/
  'new-user' = 'new-user',

  /*****************
   * NEWS
   *****************/
  'new-news-post' = 'new-news-post',
  'remove-news-post' = 'remove-news-post',
  'new-news-reply' = 'new-news-reply'
}
