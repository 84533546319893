import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastTemplateFrontendService } from '@core/store/forecast-template/forecast-template.frontend.service';
import { CreateForecastTemplateDTO, ForecastTemplateTagDTO } from '@core/store/forecast-template/forecast-template.model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { VarSelectResultModel } from '@core/store/var-select/var-select-result.model';
import { VariableSelectFrontendService } from '@core/store/var-select/var-select.frontend.service';
import { VsResultType } from '@modules/lang/language-files/var-select';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { ForecastVariableUtils } from '@shared/utils/forecast/forecast-variable.utils';
import { ForecastUtils } from '@shared/utils/forecast/forecast.utils';

export interface CreateForecastTemplateDialogData {
  ForecastId: string;
  ForecastVersionId: string;
  VsResultType: VsResultType;
}

@Component({
  selector: 'indicio-create-forecast-template-dialog',
  styleUrls: ['create-forecast-template.dialog.less'],
  templateUrl: 'create-forecast-template.dialog.html',
})
export class CreateForecastTemplateDialogComponent {
  public static Id: string = 'CreateForecastTemplateDialogComponent';


  public createDto = new CreateForecastTemplateDTO;
  public selectedFVersion: ForecastVersionModel = null;
  public selectedForecast: ForecastModel = null;
  public selectedTags: any = [];
  public usedProviders: string[] = [];
  private VsResult: VarSelectResultModel = null;
  public cannotBeUsedReasons: string[] = [];

  // Flags
  public initialized: boolean = false;
  public saveInProgress: boolean = false;

  // Frontend helpers
  public get availableTags() { return this.templateService.availableTags; }
  private get isCreateDtoValid() { return this.createDto.Title?.length && this.createDto.Description?.length; }
  public get canCreate() { return this.cannotBeUsedReasons.length === 0 && this.isCreateDtoValid; }

  constructor(
    public dialogRef: MatDialogRef<CreateForecastTemplateDialogComponent>,
    private clientService: ClientFrontendService,
    public profileService: ProfileFrontendService,
    private forecastService: ForecastFrontendService,
    private templateService: ForecastTemplateFrontendService,
    private statusService: StatusService,
    private dialog: DialogService,
    private varSelectService: VariableSelectFrontendService,
    @Inject(MAT_DIALOG_DATA) public data: CreateForecastTemplateDialogData
  ) {
    this.createDto.CompanyId = this.clientService.activeCompanyId;
    this.createDto.IsPublic = true;
    this.createDto.Tags = [];
    this.createDto.VariableTags = [];
    this.createDto.ForecastVersionId = this.data.ForecastVersionId;
    this.initialize();
  }

  public createNewTag() {
    this.templateService.createNewTag()
      .then((tag) => this.onSelectTag([tag]))
      .catch(err => this.statusService.setError(err, true));
  }

  public onSelectTag($event: ForecastTemplateTagDTO[]) {
    this.selectedTags = $event;
    this.createDto.Tags = $event.map(x => x.Name);
  }

  private initialize() {
    const tagPromise = this.templateService.getTags();
    const fVersionPromise = this.forecastService.getOrFetchForecastVersion(this.data.ForecastVersionId);
    const forecastPromise = this.forecastService.getOrFetchForecast(this.data.ForecastId);
    Promise.all([tagPromise, fVersionPromise, forecastPromise])
      .then(([_, forecastVersion, forecast]) => {
        this.selectedForecast = forecast;
        this.selectedFVersion = forecastVersion;
        const allVarSelects = this.varSelectService.getVarSelectInFVersion(this.selectedFVersion.ForecastVersionId);
        this.VsResult = allVarSelects.find(x => x.VsResultType === this.data.VsResultType);
        this.cannotBeUsedReasons = ForecastUtils.canBeUsedAsTemplateBase(forecastVersion, this.VsResult);
        this.setDescription();
        this.setUsedProviders();
      })
      .catch(err => this.statusService.setError(err, true))
      .finally(() => this.initialized = true);
  }

  public addProfileBio() {
    const ref = this.dialog.openTextInputDialog({
      Label: 'Bio',
      Title: 'Update profile bio',
      CancelText: 'Cancel',
      ConfirmText: 'Save',
      placeholder: 'Your bio here',
      type: 'text-area'
    });
    ref.subscribe(ans => {
      if (!ans) { return; }
      this.profileService.profile.Bio = ans;
      this.profileService.updateProfile(this.profileService.profile)
        .then(() => {
          this.statusService.setMessage('Profile updated', 'Success');
        });
    });
  }

  private setDescription() {
    const fv = this.selectedFVersion;
    const indis = ForecastVariableUtils.GetSortedIndicators(fv, this.VsResult);
    let toUse = indis.filter(x => !x.IsTrend && !x.IsMixedFreq);
    let desc = `Forecast "${fv.ForecastVariable.Name}" using ${toUse.length} indicators, including `;
    const count = Math.min(3, toUse.length);
    for (let i = 0; i < count; i++) {
      const name = toUse[i].Name;
      if (i === count - 1) {
        desc += `and "${name}".`;
      } else {
        desc += `"${name}", `;
      }
    }
    this.createDto.Title = this.selectedForecast.Name;
    this.createDto.Description = desc;
  }

  private setUsedProviders() {
    const allProviders: string[] = this.selectedFVersion.IndicatorVariables.map(i => i.sourceType.Display);
    allProviders.push(this.selectedFVersion.ForecastVariable.sourceType.Display);
    allProviders.removeWhere(x => x === 'Trend');
    this.usedProviders = [...new Set(allProviders)];
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    if (this.saveInProgress) { return; }
    this.saveInProgress = true;
    this.templateService.create(this.createDto)
      .then(() => {
        this.dialogRef.close(true);
        this.statusService.setMessage('Template created successfully', 'Success');
      })
      .catch(err => this.cannotBeUsedReasons.push(`${err.error.StatusCode}: ${err.error.Message}`))
      .finally(() => this.saveInProgress = false);
  }
}
