import { HistoricBaseEventModel } from './models/base/historic-event.model';
import { ImportedHistoricBaseEventModel } from './models/imported/imported-historic-base-event.model';

export namespace HistoricEventActions {
  export class GetSuccess {
    public static readonly type = '[HistoricEvent] Get historic event success';
    constructor(public event: HistoricBaseEventModel) { }
  }

  export class GetAllSuccess {
    public static readonly type = '[HistoricEvent] Get all historic events in company success';
    constructor(public events: HistoricBaseEventModel[]) { }
  }

  export class DeleteSuccess {
    public static readonly type = '[HistoricEvent] Delete historic base event';
    constructor(public eventbaseId: string) { }
  }

  ///
  /// Forecast part
  ///
  export class GetAllInForecastSuccess {
    public static readonly type = '[HistoricEvent] Get historic events in forecast success';
    constructor(public forecastVersionId: string, public events: ImportedHistoricBaseEventModel[]) { }
  }

  export class GetOneInForecastSuccess {
    public static readonly type = '[HistoricEvent] Get historic event in forecast version success';
    constructor(public baseEvent: ImportedHistoricBaseEventModel) { }
  }

  export class RemoveFromForecast {
    public static readonly type = '[HistoricEvent] Remove historic event from forecast version';
    constructor(public forecastVersionId: string, public baseEventId: string) { }
  }

  ///
  /// STORE EXTRAS
  ///
  export class ClearCompanyEvents {
    public static readonly type = '[HistoricEvent] Remove historic events in company';
    constructor(public companyId: string) { }
  }
}
