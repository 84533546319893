import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { HierarchyReconciliationDTO } from '../dtos/hierarchy-reconciliation.dto';
import { HierarchyRelationDTO } from '../dtos/hierarchy-relation.dto';

export class HierarchyModel implements IHasModelId {
  @Def() Periodicity: PeriodicityType;
  @Def() Horizon: number;
  @Def() HierarchyId: string;
  @Def() CreatorClientId: string;
  @Def() CreatorClientEmail: string;
  @Def() CreatedDate: Date;
  @Def() ModifiedDate: Date;
  @Def() ReconciliationStartDate: Date;
  @Def() NonNegative: boolean;
  @Def() Relations: HierarchyRelationDTO[];
  @Def() Reconciliations: HierarchyReconciliationDTO[];
  @Def() NewDataExists: boolean;

  // Frontend
  public validReconciliation: boolean;

  public getModelId() {
    return this.HierarchyId;
  }

  public getTopRelation() {
    return this.Relations.find(x => !x.ParentRelationId);
  }
}
