import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpStatusCodes } from '@core/constants/http-status-codes.constants';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { DataProvider } from '@core/constants/provider-definitions';
import { PlotValueDTO, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ExistingRemoteVarInfoDTO } from '@core/store/providers/dtos/existing-remote-varinfo.dto';
import { ProviderAspectValueDTO, ProviderImportDTO, ProviderVariableAspectsDTO, ProviderVariableDTO } from '@core/store/providers/entities/import-provider-entities';
import { DataProviderBackendService, ImportDataProviderType } from '@core/store/providers/provider.backend.service';
import { ProviderService } from '@core/store/providers/provider.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Store } from '@ngxs/store';
import { AlgConverter } from '@shared/components/line-graph/alg-line.utils';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgChartStyles, AlgOptions, StrokeSetups } from '@shared/components/line-graph/alg.options';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { DateUtils } from '@shared/utils/date.utils';

const DISABLE_GRAPH_PROVIDERS = [DataProvider.bloomberg];

export interface ImportProviderDialogData {
  Type: ImportDataProviderType,
  Id: string;
  ForecastVersionId: string;
  Variable: ProviderVariableDTO;
}

@Component({
  selector: 'indicio-import-provider-dialog',
  templateUrl: './import-provider.dialog.html',
  styleUrls: ['./import-provider.dialog.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ImportProviderDialogComponent {

  public static Id: string = 'ImportProviderDialogComponent';

  public loadingContent: boolean = false;
  public variable: ProviderVariableDTO;
  public title: string = 'N/A';
  public name: string = '';
  public discontinued: boolean = false;
  public nameConflict: boolean;
  public nameMustChange: boolean;
  public loadingImport: boolean = false;
  public aggregationMethodId: string = IndicioConstants.EAggregationMethods.Average;

  public oneOptionDimensions: ProviderVariableAspectsDTO[] = [];
  public dimensions: ProviderVariableAspectsDTO[] = [];
  public preview: SimplePlotValue[] = [];
  public previewLine: ALGLineModel;
  public algOptions: AlgOptions.Options;
  public periodicity: PeriodicityType;
  public chartStyles: AlgChartStyles = { borderSize: 2, borderRadius: 4, historyLine: '#6388D0', plotHeight: 210 };

  public graphReady: boolean = false;
  public disableGraph: boolean = false;
  public loadingPreview: boolean = false;
  public initPreview: boolean = true;
  public previewError: string;
  public existingVars: ExistingRemoteVarInfoDTO[] = [];


  private forecastVersion: ForecastVersionModel;

  public get disabled() {
    return !this.importable || this.variable.Dimensions.some(x => !x.selected);
  }

  public get disabledText(): string {
    if (this.discontinued) return this.previewError;
    if (this.previewError) return 'No data available for this selection.';
    const missing = this.variable.Dimensions.filter(x => !x.selected).map(x => x.Display);
    if (missing.length === 0) return 'Name already in use.';
    return `The following options are missing: ${missing.join(', ')}.`;
  }

  public get importable() { return this.existingVars?.length > 0 || !(this.nameMustChange || this.nameConflict); }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImportProviderDialogData,
    public envService: EnvironmentService,
    public service: DataProviderBackendService,
    public dialogRef: MatDialogRef<ImportProviderDialogComponent>,
    private status: StatusService,
    private cd: ChangeDetectorRef,
    private sVarService: SourceVariableFrontendService,
    private clientService: ClientFrontendService,
    private forecastService: ForecastFrontendService,
    private forecastVariableService: ForecastVariableFrontendService,
    private fvarMapper: ForecastVariableMapper,
    private store: Store,
    private providerService: ProviderService
  ) {
    this.setTitle();
    this.setup(data.Variable);
  }

  public getDim(d: ProviderVariableAspectsDTO) { return d; }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public import() {
    this.loadingImport = true;

    if (this.existingVars?.length > 0) {
      return this.importExistingSourceVariable();
    }

    const selection = this.variable.Dimensions.map(x => ({ DimensionCode: x.Code, ValueCode: x.selected.Code }));
    const dto: ProviderImportDTO = { Name: this.name, Selections: selection };
    this.service.importVariable(this.clientService.activeCompanyId, this.data.Type, this.data.Id, dto)
      .then(this.importSourceVariable.bind(this)).catch(err => {
        if (err.status === HttpStatusCodes.NO_CONTENT)
          this.status.setMessage('No data available for this selection.', 'Warning', true);
        else
          this.status.setError(err, true);
      })
      .finally(() => {
        this.loadingImport = false;
        this.cd.detectChanges();
      });
  }

  public importExistingSourceVariable() {
    this.sVarService.getOrFetchSourceVariable(this.existingVars[0].SourceVariableId)
      .then(this.importSourceVariable.bind(this))
      .catch(err => {
        this.status.setError(err, true);
      });
  }

  private importSourceVariable(sourceVariable: SourceVariableModel) {
    const validAgg = this.envService.getValidAggregationMethods(sourceVariable, this.forecastVersion.Periodicity).some(x => x.Value === this.aggregationMethodId);
    sourceVariable._tmpAggregationMethod = this.aggregationMethodId;
    this.onNoClick();
    if (!validAgg) {
      setTimeout(() => this.status.setMessage('Variable created successfully, but chosen aggregation method is invalid', 'Warning', true), 10);
      return this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [sourceVariable.SourceVariableId]));
    }
    this.status.setMessage('Variable added successfully', 'Success', !validAgg);
    const fvar = this.fvarMapper.mapFromSourceVariableModel(sourceVariable, this.forecastVersion.ForecastVersionId);
    this.forecastVariableService.createForecastVariable(fvar)
      .catch(err => this.status.setError(err));
  }


  public checkName() {
    if (this.name.length < 2 || this.name.length > 255) {
      this.nameMustChange = true;
    } else {
      this.nameMustChange = false;
    }

    this.nameConflict = this.sVarService.isNameConflict(this.name);
    this.cd.detectChanges();
  }

  private setup(variable: ProviderVariableDTO) {
    if (this.loadingContent) { return; }
    this.loadingContent = true;

    const promise = variable === null ? this.service.getVariable(this.data.Type, this.data.Id) : Promise.resolve(variable);
    promise
      .then(resp => this.setupVariable(resp))
      .then(() => this.forecastService.getOrFetchForecastVersion(this.data.ForecastVersionId))
      .then(fv => this.forecastVersion = fv)
      .then(() => this.getPreview())
      .then(() => this.getExistingRVarInformation(this.variable.Id))
      .catch(err => this.status.setError(err, true))
      .finally(() => {
        this.loadingContent = false;
        this.cd.detectChanges();
      });
  }

  private getExistingRVarInformation(referenceId: string) {
    if (!this.variable.Dimensions.every(x => !!x.selected)) { return; }
    return this.providerService.getExistingRVarInformation({
      RemoteReferenceId: referenceId,
      SourceType: this.data.Type,
      Dimensions: this.variable.Dimensions.map(x => ({ DimensionCode: x.Code, ValueCode: x.selected.Code }))
    }).then(vars => this.existingVars = vars).catch(err => this.existingVars = []);
  }

  private setupVariable(variable: ProviderVariableDTO) {
    this.variable = variable;
    this.name = variable.Title.substring(0, 256);
    this.discontinued = variable.Discontinued;
    this.previewError = 'This variable has been discontinued.';
    this.checkName();
    this.checkSetDimensions();
    this.getPreview();
  }

  private checkSetDimensions() {
    this.oneOptionDimensions = this.variable.Dimensions.filter(x => x.Values.length === 1);
    this.dimensions = this.variable.Dimensions.filter(x => x.Values.length > 1);

    this.variable.Dimensions.forEach(dim => {
      if (dim.Values.length > 1) { return; }
      dim.selected = dim.Values[0];
    });
  }

  public setOption(option: ProviderAspectValueDTO, dim: ProviderVariableAspectsDTO) {
    if (this.loadingImport) { return; }
    dim.selected = option;
    this.getPreview();
    this.getExistingRVarInformation(this.variable.Id);
  }

  private getPreview() {
    if (this.discontinued || DISABLE_GRAPH_PROVIDERS.includes(this.data.Type)) { this.disableGraph = true; return; }
    if (this.loadingPreview || this.variable.Dimensions.some(x => !x.selected)) { return; }
    this.loadingPreview = true;
    this.previewError = null;
    const selection = this.variable.Dimensions.map(x => ({ DimensionCode: x.Code, ValueCode: x.selected.Code }));
    return this.service.getPreview(this.data.Type, this.variable.Id, selection).then(preview => {
      if (preview.length === 0) {
        this.previewError = 'No data available for this selection.';
        return;
      }
      this.preview = preview;
      this.updateGraphData();
      this.initPreview = false;
    })
      .catch(err => { this.previewError = 'Error getting preview data.'; })
      .finally(() => {
        this.loadingPreview = false;
        this.cd.detectChanges();
      });
  }

  private updateGraphData() {
    this.preview.forEach(x => { x.m = DateUtils.newMoment(x.D); x.D = DateUtils.newDate(x.D); });
    const singleSeries = AlgConverter.fromSpeedHistoricValues(this.preview as PlotValueDTO[], this.variable.Title, this.variable.Id);

    singleSeries.Name = `Result: ${this.variable.Title}`;

    this.previewLine = singleSeries;
    this.algOptions = this.getGraphOptions();
    this.periodicity = this.envService.getPeriodicity(this.variable.Periodicity).Value;
  }

  private getGraphOptions() {
    const opts = AlgOptions.CreateOptions({
      noDataText: 'No data selected',
      inModal: true,
      summary: true,
      showOnlyHistoric: true,
      menuConfig: {
        forceShowButtons: true,
        showFittedDataBtn: false,
        dontShowPastForecastBtn: true,
        dontShowDownloadBtn: false,
        showSeasonalAdjBtn: false,
        showOutlierAdjBtn: false,
        showMenu: false
      },
      axisConfig: {
        yAxisPosition: 'inside',
        yAxisLine: StrokeSetups.AxisLineDashed,
      },
      updateGraph: false,
      showAssessments: false,
      dontShowCIHover: false,
      isPercent: false,
      rocEnabled: false,
      rocYYEnabled: false,
      header: 'Remove variable ',
      dates: this.preview.map(x => x.m),
      pointsToShow: this.preview.length
    });
    return opts;
  }

  private setTitle() {
    const dp = this.envService.getSourceType(this.data.Type);
    if (!dp) return this.title = 'Unknown data provider';
    this.title = `Import variable from ${dp.Display}`;
  }

}
