import { Injectable } from '@angular/core';
import { KoladaKPIGroupsDTO, KoladaKPIGroupDTO } from './dtos/kolada-groups.dto';
import { KoladaKPIGroupsModel, KoladaKPIGroupMemberModel, KoladaKPIGroupModel } from './kolada-groups.model';
import { KoladaKPIDTO } from './dtos/kolada-kpi.dto';
import { KoladaKPIModel } from './kolada-kpi.model';
import { KoladaMunicipalityDTO } from './dtos/kolada-municipality.dto';
import { KoladaMunicipalityModel } from './kolada-municipality.model';
import { KoladaMunicipalityValueListDTO } from './dtos/kolada-municipality-values.dto';
import { KoladaMunicipalityValueModel, KoladaMunicipalityValuePeriodModel, KoladaMunicipalityValueListModel } from './kolada-municipality-values.model';
import { KoladaOrganizationalUnitDTO } from './dtos/kolada-organizational-unit.dto';
import { KoladaOrganizationalUnitModel } from './kolada-organizational-unit.dto';
import { KoladaOUValueListDTO } from './dtos/kolada-ou-values.dto';
import { KoladaOUValueListModel, KoladaOUValueModel, KoladaOUValuePeriodModel } from './kolada-ou-values.model';

@Injectable({
  providedIn: 'root'
})
export class KoladaMapper {

  public mapGroups(groups: KoladaKPIGroupsDTO) {
    const model = Object.faMapTo(new KoladaKPIGroupsModel, groups);
    model.values = model.values.map(v => this.mapGroup(v));
    return model;
  }

  public mapGroup(group: KoladaKPIGroupDTO) {
    const model = Object.faMapTo(new KoladaKPIGroupModel, group);
    model.members = model.members.map(m => Object.faMapTo(new KoladaKPIGroupMemberModel, m));
    return model;
  }

  public mapKPI(kpi: KoladaKPIDTO) {
    const model = Object.faMapTo(new KoladaKPIModel, kpi);
    return model;
  }

  public mapKPItoMember(kpi: KoladaKPIDTO) {
    const model = new KoladaKPIGroupMemberModel;
    model.member_id = kpi.id;
    model.member_title = kpi.title;
    model.is_divided_by_gender = kpi.is_divided_by_gender === 1;
    model.description = kpi.description;
    return model;
  }

  public mapMunicipality(dto: KoladaMunicipalityDTO) {
    const model = Object.faMapTo(new KoladaMunicipalityModel, dto);
    return model;
  }

  public mapOU(dto: KoladaOrganizationalUnitDTO) {
    const model = Object.faMapTo(new KoladaOrganizationalUnitModel, dto);
    return model;
  }

  public mapMunicipalityValues(dto: KoladaMunicipalityValueListDTO, uri?: string) {
    const model = Object.faMapTo(new KoladaMunicipalityValueListModel, dto);
    model.values = dto.values.map(v => {
      const period = Object.faMapTo(new KoladaMunicipalityValuePeriodModel, v);
      period.values = v.values.map(vp => Object.faMapTo(new KoladaMunicipalityValueModel, vp));
      return period;
    });

    model.uri = uri;

    return model;
  }

  public mapOUValues(dto: KoladaOUValueListDTO, uri?: string) {
    const model = Object.faMapTo(new KoladaOUValueListModel, dto);
    model.values = dto.values.map(v => {
      const period = Object.faMapTo(new KoladaOUValuePeriodModel, v);
      period.values = v.values.map(vp => Object.faMapTo(new KoladaOUValueModel, vp));
      return period;
    });

    model.uri = uri;

    return model;
  }
}
