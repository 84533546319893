<mat-form-field>
  <mat-label *ngIf="label">{{label}}</mat-label>
  <input matInput
         [matDatepicker]="picker"
         autocomplete="off"
         [style]="style"
         [placeholder]="placeholder"
         [disabled]="disabled"
         [min]="min"
         [max]="max"
         [matDatepickerFilter]="filter"
         [ngModel]="value"
         [ngModelOptions]="{ standalone: true }"
         (dateChange)="inputEvent($event)">
  <mat-hint *ngIf="hint?.show">
    <span [class]="hint.class">{{hint.message}}</span>
  </mat-hint>
  <button class="open-btn"
          matSuffix
          (click)="picker.open()">
    <i class="ion-android-calendar"></i>
  </button>
  <mat-datepicker #picker
                  [startView]="startView"
                  (yearSelected)="!!yearSelected ? yearSelected($event) : null"
                  (monthSelected)="!!monthSelected ? monthSelected($event) : null"
                  [panelClass]="panelClass">
  </mat-datepicker>
</mat-form-field>
