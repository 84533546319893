import { IHasDate, PlotValue } from '@core/entities/dtos/plot-value-dto';
import { Period, PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Periodicity } from '@modules/lang/types/periodicity';
import * as moment from 'moment-timezone';

export namespace DateUtils {

  /**
   * Returns true if the "distance" between two dates is bigger than the supplied diff (default 12) in a specific period (default month)
   * @returns
   */
  export function isDateDiffLargerThan(from: moment.Moment, to: moment.Moment, p: moment.unitOfTime.Diff = 'month', diff: number = 12) {
    return from.diff(to, p) > diff;
  }


  /**
   * TODO: Improve this to accept arbitrary arrays, where entries contain Date, OR Moment (moment)
   * @param array
   * @param from Must be Date.getTime()
   * @param to Must be Date.getTime()
   */
  export function filterBetween(array: PlotValue[], from: number, to: number) {
    return array.filter(x => x.D.getTime() >= from && x.D.getTime() <= to);
  }

  export function DateOrdinalString(num: number) {
    const lastDigit = +(String(num).split('').pop());
    switch (lastDigit) {
      case 1: return `${num}st`;
      case 2: return `${num}nd`;
      case 3: return `${num}rd`;
      default:
        return `${num}th`;
    }
  }

  export function bisectDate(date: moment.Moment, dates: moment.Moment[]): moment.Moment {
    return dates[bisectDateIndex(date, dates)];
  }

  export function bisectDateIndex(date: moment.Moment, dates: moment.Moment[]): number {
    if (date.isBefore(dates[0])) { return 0; }
    const index = dates.findIndex(x => x.isAfter(date));
    if (index === -1) { return dates.length - 1; }
    const closeAfter = dates[index];
    const closeBefore = dates[index - 1];
    const dist1 = closeAfter.unix() - date.unix();
    const dist2 = date.unix() - closeBefore.unix();
    if (dist1 < dist2) { return index; } else { return index - 1; }
  }

  export function getMonthFromString(month) {
    switch (month.toLowerCase()) {
      case 'january':
      case 'januari':
      case 'jan':
        return 1;

      case 'february':
      case 'februari':
      case 'feb':
        return 2;

      case 'march':
      case 'mars':
      case 'mar':
        return 3;

      case 'april':
      case 'apr':
        return 4;

      case 'may':
      case 'maj':
        return 5;

      case 'june':
      case 'juni':
      case 'jun':
        return 6;

      case 'july':
      case 'juli':
      case 'jul':
        return 7;

      case 'august':
      case 'augusti':
      case 'aug':
        return 8;

      case 'september':
      case 'sep':
        return 9;

      case 'october':
      case 'oktober':
      case 'oct':
      case 'okt':
        return 10;

      case 'november':
      case 'nov':
        return 11;

      case 'december':
      case 'dec':
        return 12;
    }
  }

  export function getYear(match: string) {
    const yearInt = parseInt(match, 10);
    if (yearInt > 100) {
      return match;
    } else {
      return yearInt < 70 ? `20${match}` : `19${match}`;
    }
  }

  export function getStartOf(date: Date, periodicity: PeriodicityType) {
    const momentDate = DateUtils.newMoment(date);
    switch (periodicity) {
      case 'week': {
        return momentDate.startOf('week');
      }
      case 'month': {
        return momentDate.startOf('month');
      }
      case 'quarter': {
        return momentDate.startOf('quarter');
      }
      case 'halfyear': {
        if (date.getMonth() < 5) {
          return momentDate.startOf('year');
        } else {
          return DateUtils.newMoment().month(5).startOf('month');
        }
      }
      case 'year': {
        return momentDate.startOf('year');
      }
      default: {
        return momentDate;
      }
    }
  }

  export function getPointsBetween(start: moment.Moment, end: moment.Moment, periodicity: PeriodicityType) {
    start = start.clone();
    end = end.clone();
    let amount = 0;
    while (end > start || start.format('M') === end.format('M')) {
      amount++;
      start.add(1, 'month');
    }
    switch (periodicity) {
      case 'year':
        amount = amount / 12;
        break;
      case 'halfyear':
        amount = amount / 6;
        break;
      case 'quarter':
        amount = amount / 4;
        break;
      case 'week':
        amount = amount * 4;
        break;
      case 'day':
        amount = amount * 4 * 5;
      case 'month':
      default:
    }
    return Math.round(amount);
  }


  export function convertToBackendDate(date: moment.Moment | Date, allowNull = false) {
    if (!date && allowNull) { return null; }
    try {
      if (date instanceof Date) {
        date = DateUtils.newMoment(date);
      }
      return date.format('YYYY-MM-DD');
    } catch (e) {
      throw new Error('Date is not moment');
    }
  }

  export function diffDate(d1: Date, d2: Date, p: PeriodicityType) {
    const preciseDiff = DateUtils.newMoment(d1).diff(DateUtils.newMoment(d2), DateUtils.getMomentPeriod(p), true);
    return Math.round(preciseDiff);
  }

  export function isOldData(date: string | Date, _?: string) {
    return DateUtils.newMoment().diff(date, 'years') > 2;
  }

  export const getMomentPeriod = (P: PeriodicityType) => {
    switch (P) {
      case Period.day: {
        return 'day';
      }
      case Period.weekday: {
        return 'day';
      }
      case Period.week: {
        return 'week';
      }
      case Period.month: {
        return 'month';
      }
      case Period.quarter: {
        return 'quarter';
      }
      case Period.year: {
        return 'year';
      }
      default:
        return 'month';
    }
  };

  export function sortByDates(array: any[], field: string, descending: boolean = true) {
    const sorted = array.sort((a: any, b: any) => DateUtils.newDate(a[field]).getTime() - DateUtils.newDate(b[field]).getTime());
    if (descending) {
      return sorted.reverse();
    }
    return sorted;
  }

  export function ToRQueueDisplayTime(ns: number): string {
    const seconds = ns / 1000;
    switch (true) {
      case seconds < 10:
        return '< 10 sec';
      case seconds < 30:
        return '< 30 sec';
      case seconds < 60:
        return '< 1 min';
      case seconds < 120:
        return '< 2 min';
      case seconds < 180:
        return '< 3 min';
      case seconds < 300:
        return '< 5 min';
      case seconds < 600:
        return '< 10 min';
      case seconds < 900:
        return '< 15 min';
      case seconds < 1200:
        return '< 20 min';
      case seconds < 1800:
        return '< 30 min';
      case seconds < 2700:
        return '< 40 min';
      default:
        return '> 40 min';
    }
  }

  export function getSecMinHrFromMs(ms: number) {
    let milliseconds = Math.floor((ms % 1000) / 100);
    let seconds = Math.floor((ms / 1000) % 60);
    let minutes = Math.floor((ms / (1000 * 60)) % 60);
    let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    let hoursStr = (hours < 10) ? '0' + hours : hours;
    let minutesStr = (minutes < 10) ? '0' + minutes : minutes;
    let secondsStr = (seconds < 10) ? '0' + seconds : seconds;
    return hoursStr + ':' + minutesStr + ':' + secondsStr + '.' + milliseconds;
  }

  export function convertFromLyFormat(lyFormat: string) {
    lyFormat = lyFormat.toLowerCase();
    switch (lyFormat) {
      case 'daily':
        return Period.day;
      case 'weekdaily':
        return Period.weekday;
      case 'weekly':
        return Period.week;
      case 'monthly':
        return Period.month;
      case 'quarterly':
        return Period.quarter;
      case 'yearly':
        return Period.year;
      case 'half-yearly':
        return Period.halfyear;
      default:
        return Period.month;
    }
  }

  export function convertToLyFormat(periodicity: PeriodicityType) {
    switch (periodicity) {
      case Period.day:
        return 'daily';
      case Period.weekday:
        return 'weekdaily';
      case Period.week:
        return 'weekly';
      case Period.month:
        return 'monthly';
      case Period.quarter:
        return 'quarterly';
      case Period.year:
        return 'yearly';
      case Period.halfyear:
        return 'half-yearly';
      default:
        return Period.month;
    }
  }

  export function getFrequencyFromPeriodicity(p: PeriodicityType) {
    switch (p) {
      case 'day':
        return 365;
      case 'weekday':
        return 260;
      case 'week':
        return 52;
      case 'month':
        return 12;
      case 'quarter':
        return 4;
      case 'halfyear':
        return 2;
      case 'year':
        return 1;
      default:
        console.error('Unknown periodicity: ', p);
    }
  }

  /**
   * @param o Must be of type implementing the IHasDate interface
   * @returns the same object with the fields m and D set
   */
  export function MapIHasDate<T extends IHasDate>(o: T) {
    o.D = newDate(<string> <unknown> o.D);
    o.m = DateUtils.newMoment(o.D);
    return o;
  }

  export function nextPeriodAfterSteps(date: moment.Moment, p: Periodicity, steps: number) {
    let startDate = DateUtils.newMoment(date);
    for (let i = 0; i < steps; i++) {
      startDate = nextPeriod(startDate, p);
    }
    return startDate;
  }

  export function nextPeriod(date: moment.Moment, p: Periodicity) {
    const unit = p.getMomentDurationConstructor();
    const next = DateUtils.newMoment(date.add(1, unit).startOf(unit));
    return DateUtils.alignDate(next, p.Value);
  }

  export function alignDate(date: moment.Moment, period: PeriodicityType) {
    if (period === 'weekday') {
      if (date.weekday() === 0)
        date.add(1, 'day');
      if (date.weekday() === 6)
        date.add(2, 'day');
    }
    if (period === 'week') {
      date.day(3);
    }
    return date;
  }

  export function alignJsDate(date: moment.Moment, period: PeriodicityType) {
    const copy = DateUtils.newMoment(date);
    if (!copy) { return null; }
    const start = copy.startOf(DateUtils.getMomentPeriod(period));
    const ans = this.alignDate(start, period);
    return ans.toDate();
  }

  /**
   * @param input Can be any of moment, Date or string
   * @returns a new moment instance
   */
  export function newMoment(input?: moment.Moment | Date | string): moment.Moment {
    return moment(input);
  }

  export function fromUtc(a: string): Date {
    return moment.utc(a).toDate();
  }

  export function newNullableDate(a: any) {
    if (a == null) {
      return null;
    }
    return newDate(a);
  }

  export function newDate(a?: string | Date | number | moment.Moment): Date;
  export function newDate(c: number, d: number, e: number): Date;
  export function newDate(): Date {
    switch (arguments.length) {
      case 0: return new Date();
      case 1:
        switch (typeof arguments[0]) {
          case 'string':
            if (arguments[0].match('T')) {
              return new Date(arguments[0]);
            } else if (arguments[0].match(/^\d{4}[-\/]\d{2}[-\/]\d{2}$/)) {
              const dateParts: number[] = arguments[0].split(/-|\//).map(Number);
              if (dateParts.length !== 3) throw ('Invalid date: ' + arguments[0]);
              return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
            } else if (arguments[0].match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
              return new Date(arguments[0]);
            } else {
              throw ('Invalid date: ' + arguments[0]);
            }
          case 'number':
            return new Date(arguments[0]);
          case 'object':
            if (Object.prototype.toString.call(arguments[0]) === '[object Date]') {
              return arguments[0];
            } else if (moment.isMoment(arguments[0])) {
              return arguments[0].toDate();
            } else {
              throw ('Invalid date: ' + arguments[0]);
            }
          default:
            throw ('Invalid date: ' + arguments[0]);
        }
      case 2: // Error: return date from arg1
        return new Date(arguments[0]);
      case 3: return new Date(arguments[0], arguments[1], arguments[2]);
    }
  }
}
