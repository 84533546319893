import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

export class ResizeOutput {
  public Id: string;
  public NewSize: number;
}

/**
 * Note: The resizeTarget used by this directive can be located on the indicioResizable element
 * This means that the parent element cannot itself be a indicioResizable element
 */
@Directive({
  selector: '[indicioResizable]'
})
export class ResizableDirective implements AfterViewInit, OnDestroy {

  @Input() growDirection: 'left' | 'right' = 'left';
  @Output() resizeEvent = new EventEmitter<ResizeOutput>();

  private destroy$ = new Subject<void>();

  constructor(private ui: ElementRef<HTMLElement>) { }

  public ngOnDestroy() { this.destroy$.next(); }
  public ngAfterViewInit() { this.setup(); }

  private setup() {
    const handles = Array.from(this.ui.nativeElement.querySelectorAll('[resizeHandle]'));
    const mousemove$ = fromEvent(document, 'mousemove');
    const mouseup$ = fromEvent(document, 'mouseup');
    for (let h of handles) {
      this.setupOne(h, mousemove$, mouseup$);
    }
  }

  private setupOne(h: Element, move: Observable<Event>, up: Observable<Event>) {
    const handleId = h.getAttribute('resizeHandle');
    const target = this.ui.nativeElement.querySelector(`[resizeTarget="${handleId}"]`) || this.ui.nativeElement.parentElement.querySelector(`[resizeTarget="${handleId}"]`);
    const mousedown$ = fromEvent(h, 'mousedown');
    mousedown$.pipe(
      switchMap((event: MouseEvent) => {
        const dragStartX = event.clientX;
        const startW = target.clientWidth;
        return move.pipe(
          map((e: MouseEvent) => {
            e.preventDefault();
            const moved = dragStartX - e.clientX;
            this.resizeEvent.emit({
              Id: handleId,
              NewSize: this.growDirection === 'left' ? startW + moved : startW - moved,
            });
          }),
          takeUntil(up),
        );
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }
}
