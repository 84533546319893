import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { FredCategoryModel } from '@core/store/providers/fred/fred-category.model';
import { FredDataSerieModel } from '@core/store/providers/fred/fred-dataserie.model';

export class FredStateCategoryModel implements IHasModelId {
  @Def() Id: number;
  @Def() Categories: FredCategoryModel[];
  @Def() Series: FredDataSerieModel[];
  getModelId() { return String(this.Id); }
}
