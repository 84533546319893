<ng-container *ngIf="!isLoading; else loader">
  <div class="tag-list-grid">
    <div class="tag-list-container">
      <div class="header">
        Tag name
      </div>
      <div class="tag-list">
        <cdk-virtual-scroll-viewport class="virtual-scroll-container"
                                     style="height: 335px"
                                     minBufferPx="600"
                                     maxBufferPx="600"
                                     itemSize="20">
          <ng-container *cdkVirtualFor="let node of dataSource">
            <div style="height: 20px;"
                 class="center-vertical tag"
                 [class]="{ active: isActive(node) }"
                 [style.padding-left]="node.level * 9 + 'px'">
              <div style="display:inline-block;"
                   (click)="clickedNode(node)">
                <div class="icon"
                     *ngIf="node.hasChildren">
                  <i [class]="treeControl.isExpanded(node) ? 'ion-chevron-down' : 'ion-chevron-right'"></i>
                </div>
                <div class="icon"
                     *ngIf="!node.hasChildren">
                </div>
              </div>
              <div [title]="node._real.Name"
                   (click)="selectNode(node)"
                   class="crop-string"
                   style="display:inline-block;">
                <span>{{node._real.Name}}</span>
              </div>
              <div style="height: 100%; flex-grow: 1;"
                   (click)="selectNode(node)"></div>
            </div>
          </ng-container>
        </cdk-virtual-scroll-viewport>
      </div>

      <div class="tag-list-btn">
        <span class="text h-grey a-lightgrey cursor-pointer unselectable"
              (click)="addNewTag();">
          <i class="ion-plus-round"></i>
          Add new tag
        </span>
        <div class="search">
          <mat-form-field appearance="outline">
            <mat-label>Search</mat-label>
            <input type="text"
                   class="material"
                   matInput
                   autocomplete="off"
                   [(ngModel)]="searchStr"
                   (ngModelChange)="updateSearch()"
                   name="Name"
                   maxlength="64"
                   require>

            <button *ngIf="searchStr"
                    (click)="searchStr = ''; updateSearch()"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="tag-list-container">
      <div class="tag-list">
        <div class="header">
          Tag info
        </div>
        <ng-container *ngIf="service.selectedTag; let tag">
          <span class="tag-name">
            Tag: {{ tag.Name }} <i class="pointer ion-edit"
               (click)="renameTag()"></i>
          </span>
          <div class="bold"
               (click)="addNewParent(true)"
               style="margin-top: 15px;">
            <span class="tag-name">
              Parents <i class="pointer ion-edit"></i>
            </span>
          </div>
          <div class="tag-info-list">
            <div *ngFor="let parent of tag.ParentTags">
              {{ parent.Name }}
            </div>
          </div>
          <div class="text h-grey a-lightgrey cursor-pointer unselectable"
               (click)="addNewParent();">
            <i class="ion-plus-round"></i>
            Add new parent
          </div>

          <div class="bold"
               (click)="addNewChild(true)"
               style="margin-top: 9px;">
            <span class="tag-name">
              Children <i class="pointer ion-edit"></i>
            </span>
          </div>
          <div class="tag-info-list children">

            <div *ngFor="let child of tag.ChildTags">
              {{ child.Name }}
            </div>
          </div>
          <div class="text h-grey a-lightgrey cursor-pointer unselectable"
               (click)="addNewChild();">
            <i class="ion-plus-round"></i>
            Add new child
          </div>
        </ng-container>
      </div>
      <div *ngIf="service.selectedTag"
           class="tag-list-btn">
        <span class="text h-grey a-lightgrey cursor-pointer unselectable"
              (click)="openRemoveDialog();">
          <i class="ion-trash-b"></i>
          Remove tag
        </span>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <div class="row">
    <div class="col-xs-12 tab-loader center-horizontal-and-vertical">
      <i class="ion-load-c spinner"></i><br>
      <span class="title">Loading...</span>
    </div>
  </div>
</ng-template>
