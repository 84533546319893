import { Injectable } from '@angular/core';
import { NavigateToHome } from '@core/actions/navigation.actions';
import { ErrorReportService } from '@core/services/error-report.service';
import { ClearStateAction } from '@core/store/auth/auth.actions';
import {
  GetAllForecastVariablesSuccessAction, GetForecastVariableSuccessAction,
  RemoveForecastVariableSuccessAction,
  VariableActions
} from '@core/store/forecast-variable/forecast-variable.actions';
import { EForecastVariableStatus } from '@core/store/forecast-variable/models/forecast-variable-meta.model';
import { ForecastHelper } from '@core/store/forecast/forecast-helper';
import {
  ForecastActions,
  GetCanCalculateInfoSuccessAction, GetForecastMetaSuccessAction,
  GetForecastModelFilterSettingsAction,
  GetForecastSuccessAction,
  GetForecastVersionSuccessAction,
  GetForecastsInProjectSuccessAction,
  GetNowcastSuccessAction, RemoveForecastSuccessAction,
  RemoveNowcastSuccessAction,
  SetActiveForecastIdAction, SetActiveForecastVersionSuccessAction,
  SetForecastAsNotFetchedAction
} from '@core/store/forecast/forecast.actions';
import { ForecastMapper } from '@core/store/forecast/mapper/forecast-mapper';
import { ForecastVersionDateArrayTriggerFields, ForecastVersionDateFields, ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { HistoricEventActions } from '@core/store/historic-event/historic-event.actions';
import { RemoveProjectSuccessAction } from '@core/store/project/project.actions';
import { UnivariateModelName } from '@modules/lang/language-files/stat-models';
import { ModelName } from '@modules/lang/types/model-name';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DateUtils } from '@shared/utils/date.utils';
import { CompanyActions } from '../company/company.actions';
import { ForecastVariableMapper } from '../forecast-variable/forecast-variable-mapper';
import { ScenarioAction } from '../scenario/scenario.actions';
import { ActiveBenchmarksDTO } from '../source-variable/dtos/active-benchmarks.dto';
import { DeleteSourceVariableVersionSuccessAction, SourceVariableActions } from '../source-variable/source-variable.actions';
import { GetMultivariateModelSuccessAction, GetUnivariateModelSuccessAction, RemoveMultivariateModelAction, RemoveMultivariateResultsAction, RemoveUnivariateModelAction, RemoveUnivariateResultsAction } from '../stat-model/stat-model.actions';
import { VarSelectActions } from '../var-select/var-select.actions';
import { ScriptInfoModel } from './models/script-calculate-info.model';


export class ForecastStateModel {
  Forecasts: ForecastModel[];
  ActiveForecastId: string;
  ActiveBenchmarks: ActiveBenchmarksDTO[];
}

@State<ForecastStateModel>({
  name: 'forecast',
  defaults: {
    Forecasts: [],
    ActiveForecastId: null,
    ActiveBenchmarks: []
  }
})
@Injectable()
export class ForecastState {

  constructor(
    private mapper: ForecastMapper,
    private fVarMapper: ForecastVariableMapper,
    private errorReportService: ErrorReportService
  ) { }

  @Selector()
  static forecasts(state: ForecastStateModel) { return state.Forecasts; }

  @Selector()
  static activeForecastId(state: ForecastStateModel) { return state.ActiveForecastId; }

  @Selector()
  static forecastVersions(state: ForecastStateModel) {
    return state.Forecasts.map(f => f.ForecastVersions).flatten();
  }

  @Selector()
  static getForecastsInProjectFn(state: ForecastStateModel) {
    return (projectId: string) => state.Forecasts.filter(c => c.ProjectId === projectId && !c.IsNowcast);
  }

  @Selector([ForecastState.forecastVersions])
  static forecastVariables(_state: ForecastStateModel, versions: ForecastVersionModel[]) {
    return versions.map(v => v.getAllVariables()).flatten();
  }

  @Selector([ForecastState.forecasts])
  static getActiveForecastVersionFn(_state: ForecastStateModel, forecasts: ForecastModel[]) {
    return (forecastId: string) => {
      const forecast = forecasts.find(x => x.ForecastId === forecastId);
      if (forecast) {
        if (!forecast.activeVersion) {
          return forecast.getLatestVersion();
        } else {
          return forecast.activeVersion;
        }
      } else {
        return null;
      }
    };
  }

  @Action(ForecastActions.UpdateCalcVsStatus)
  updateCalcVsStatus(ctx: StateContext<ForecastStateModel>, action: ForecastActions.UpdateCalcVsStatus) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    version.ScriptCalculateInfo.CanTriggerVariableSelect = action.canCalc;
    ForecastHelper.updateCalcVarSelectStatus(version);
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(ForecastActions.ScriptTriggered)
  actOnScriptTriggered(ctx: StateContext<ForecastStateModel>, action: ForecastActions.ScriptTriggered) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }

    if (action.scriptType === 'var-select') {
      version.getAllVariables().forEach(x => {
        x.ErrorMessages = null;
        x.Status = EForecastVariableStatus.PROCESSED;
      });
    }
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(GetUnivariateModelSuccessAction)
  getUnivariateModelSuccessAction(ctx: StateContext<ForecastStateModel>, action: GetUnivariateModelSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.model.ForecastVersionId);
    if (!version) { return; }
    const si = version.ScriptCalculateInfo.Univariate.find(x => x.Name === action.model.ModelName);
    if (!si) {
      const newSi = Object.faMapTo(new ScriptInfoModel(), action.model);
      newSi.Name = action.model.ModelName;
      version.ScriptCalculateInfo.Univariate.push(newSi);
    } else {
      Object.faMapTo(si, action.model);
      si.Name = action.model.ModelName;
    }
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(GetMultivariateModelSuccessAction)
  getMultivariateModelSuccessAction(ctx: StateContext<ForecastStateModel>, action: GetMultivariateModelSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.model.ForecastVersionId);
    if (!version) { return; }
    const si = version.ScriptCalculateInfo.Multivariate.find(x => x.Name === action.model.ModelName);
    if (!si) {
      const newSi = Object.faMapTo(new ScriptInfoModel(), action.model);
      newSi.Name = action.model.ModelName;
      version.ScriptCalculateInfo.Multivariate.push(newSi);
    } else {
      Object.faMapTo(si, action.model);
      si.Name = action.model.ModelName;
    }
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(ForecastActions.UpdateForecastVersionMetaFields)
  updateForecastVersionMetaFields(ctx: StateContext<ForecastStateModel>, action: ForecastActions.UpdateForecastVersionMetaFields) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    Object.entries(action.obj).forEach(([key, value]) => {
      if (ForecastVersionDateFields.includes(key)) {
        version[key] = DateUtils.newNullableDate(value);
      } else {
        version[key] = value;
      }
    });
    Object.keys(action.obj).some(key => {
      if (ForecastVersionDateArrayTriggerFields.includes(key)) {
        version.setAllDatesArray();
      }
    });
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(GetCanCalculateInfoSuccessAction)
  getCanCalculateInfoSuccess(ctx: StateContext<ForecastStateModel>, action: GetCanCalculateInfoSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    this.mapper.mapCalcInfo(action.calcInfo, version);
    ForecastHelper.updateCalcMultivariateStatus(version);
    ForecastHelper.updateCalcVarSelectStatus(version);
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(RemoveForecastVariableSuccessAction)
  removeForecastVariableSuccess(ctx: StateContext<ForecastStateModel>, action: RemoveForecastVariableSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }

    ctx.dispatch(new RemoveMultivariateResultsAction(action.forecastVersionId));
    ctx.dispatch(new ScenarioAction.ClearResults(action.forecastVersionId));
    ctx.dispatch(new VarSelectActions.RemoveResult(action.forecastVersionId));

    if (version && version.ForecastVariableId === action.variableId) {
      ctx.dispatch(new RemoveUnivariateResultsAction(action.forecastVersionId));
    }
    version.removeVariableById(action.variableId);
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(ForecastActions.RemoveAllResults)
  removeResults(ctx: StateContext<ForecastStateModel>, action: ForecastActions.RemoveAllResults) {
    ctx.dispatch(new VarSelectActions.RemoveResult(action.forecastVersionId));
    ctx.dispatch(new ScenarioAction.ClearResults(action.forecastVersionId));
    this.removeUniResults(ctx, action as ForecastActions.RemoveUniResults, true);
    this.removeMultiResults(ctx, action as ForecastActions.RemoveMultiResults, true);
  }


  @Action(ForecastActions.RemoveUniResults)
  removeUniResults(ctx: StateContext<ForecastStateModel>, action: ForecastActions.RemoveUniResults, removeAll = false) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    if (removeAll === true || !!!action.modelNames?.length) {
      version.ScriptCalculateInfo.Univariate = [];
    } else {
      action.modelNames.forEach(modelName => {
        const idx = version.ScriptCalculateInfo.Univariate.findIndex(x => x.Name === modelName);
        version.ScriptCalculateInfo.Univariate.splice(idx, 1);
      });
    }
    action.modelNames?.forEach(x => ctx.dispatch(new RemoveUnivariateModelAction(action.forecastVersionId, <UnivariateModelName> x)));
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(ForecastActions.RemoveMultiResults)
  removeMultiResults(ctx: StateContext<ForecastStateModel>, action: ForecastActions.RemoveMultiResults, removeAll = false) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    if (removeAll === true || !!!action.modelNames?.length) {
      version.ScriptCalculateInfo.Multivariate = [];
      ctx.dispatch(new RemoveMultivariateResultsAction(action.forecastVersionId));
    } else {
      action.modelNames.forEach(modelName => {
        ctx.dispatch(new RemoveMultivariateModelAction(action.forecastVersionId, <ModelName> { Model: modelName }));
        const idx = version.ScriptCalculateInfo.Multivariate.findIndex(x => x.Name === modelName);
        if (idx > -1) {
          version.ScriptCalculateInfo.Multivariate.splice(idx, 1);
        }
      });
    }
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(ForecastActions.RemoveScenarioResults)
  removeScenarioResults(ctx: StateContext<ForecastStateModel>, action: ForecastActions.RemoveScenarioResults) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    action.scenarioIds.forEach(sId => {
      version.ScenarioIds.splice(version.ScenarioIds.indexOf(sId));
    });
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(ForecastActions.RemoveIndicatorWarnings)
  removeIndicatorWarnings(ctx: StateContext<ForecastStateModel>, action: ForecastActions.RemoveIndicatorWarnings) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    version.IndicatorWarnings = [];
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(GetNowcastSuccessAction)
  getNowcastSuccess(ctx: StateContext<ForecastStateModel>, action: GetNowcastSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const idx = this.getForecastIndexById(ctx, action.forecast.ParentForecastId);
    if (idx !== -1) {
      const fversion = forecasts[idx].getVersionById(action.forecast.ParentForecastVersionId);
      if (fversion) {
        const variable = fversion.getVariableById(action.forecast.NowcastedVariableId);
        if (variable) {
          variable.NowcastExists = true;
          variable.Nowcast = action.forecast;
          forecasts.addOrUpdate(action.forecast);
          ctx.patchState({ Forecasts: [...forecasts] });
        }
      }
    }
  }

  @Action(GetForecastSuccessAction)
  getForecastSuccessAction(ctx: StateContext<ForecastStateModel>, action: GetForecastSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    forecasts.addOrUpdate(action.forecast);
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(GetForecastsInProjectSuccessAction)
  getForecastsInProjectSuccess(ctx: StateContext<ForecastStateModel>, action: GetForecastsInProjectSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    action.models.forEach(m => {
      const idx = forecasts.findIndex(f => f.ForecastId === m.ForecastId);
      const toAddOrUpdate = idx === -1
        ? this.mapper.mapMetaToModel(m)
        : this.mapper.mapMetaToModel(m, forecasts[idx]);

      forecasts.addOrUpdate(toAddOrUpdate);
    });
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(GetForecastVersionSuccessAction)
  getForecastVersionSuccessAction(ctx: StateContext<ForecastStateModel>, action: GetForecastVersionSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const idx = this.getForecastIndexById(ctx, action.model.ForecastId);
    if (idx !== -1) {
      forecasts[idx].ForecastVersions.addOrUpdate(action.model);
      forecasts[idx].activeVersionId = action.model.ForecastVersionId;
      ctx.patchState({ Forecasts: [...forecasts] });
    }
  }

  @Action(SetActiveForecastVersionSuccessAction)
  setActiveForecastVersionSuccessAction(ctx: StateContext<ForecastStateModel>, action: SetActiveForecastVersionSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const idx = this.getForecastIndexById(ctx, action.model.ForecastId);
    forecasts[idx].activeVersionId = action.model.ForecastVersionId;
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(GetForecastVariableSuccessAction)
  getForecastVariableSuccess(ctx: StateContext<ForecastStateModel>, action: GetForecastVariableSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.variable.ForecastVersionId);

    if (!version) { return; }

    version.addVariable(action.variable);
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(VariableActions.SetMissingValueModel)
  setMissingValueModel(ctx: StateContext<ForecastStateModel>, action: VariableActions.SetMissingValueModel) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    const variable = version.getAllVariables().find(x => x.ForecastVariableId === action.forecastVariableId);
    if (variable) {
      variable.MissingValueModel = action.dto.NewMissingValueModel;
      ctx.patchState({ Forecasts: [...forecasts] });
    }
  }

  @Action(VariableActions.SetNowcastOptions)
  setNowcastOptions(ctx: StateContext<ForecastStateModel>, action: VariableActions.SetNowcastOptions) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    const variable = version.getAllVariables().find(x => x.ForecastVariableId === action.forecastVariableId);
    const newOptions = this.fVarMapper.mapNowcastOptions(action.options);
    if (variable) {
      variable.NowcastOptions = newOptions;
      ctx.patchState({ Forecasts: [...forecasts] });
    }
  }

  @Action(VariableActions.UpdateForecastVariablesFields)
  updateForecastVariablesFields(ctx: StateContext<ForecastStateModel>, action: VariableActions.UpdateForecastVariablesFields) {
    const forecasts = ctx.getState().Forecasts;
    let updated = false;
    action.forecstVariables.forEach(variableToUpdate => {
      const version = this.getForecastVersion(forecasts, variableToUpdate.ForecastVersionId);
      if (!version) { return; }
      const variable = version.getAllVariables().find(x => x.ForecastVariableId === variableToUpdate.ForecastVariableId);
      if (!variable) { return; }
      variable.ProviderDataMissing = variableToUpdate.ProviderDataMissing;
      variable.UpdatedValuesExists = variableToUpdate.UpdatedValuesExists;
      updated = true;
    });
    if (updated) { ctx.patchState({ Forecasts: [...forecasts] }); }
  }

  @Action(VariableActions.RemoveTrend)
  removeTrendFromFvar(ctx: StateContext<ForecastStateModel>, action: VariableActions.RemoveTrend) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }

    const variable = version.getVariableById(action.forecastVariableId);
    if (!variable) { return; }

    variable.UseSeasonalTrend = false;
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(GetForecastMetaSuccessAction)
  getForecastMetaSuccess(ctx: StateContext<ForecastStateModel>, action: GetForecastMetaSuccessAction) {
    try {
      const forecasts = ctx.getState().Forecasts;
      const idx = this.getForecastIndexById(ctx, action.model.ForecastId);
      const toAdd = idx === -1
        ? this.mapper.mapMetaToModel(action.model)
        : this.mapper.mapMetaToModel(action.model, forecasts[idx]);

      forecasts.addOrUpdate(toAdd);
      ctx.patchState({ Forecasts: [...forecasts] });
    } catch (e) {
      this.errorReportService.postFrontendIssue({
        Stacktrace: e,
        Subject: `Error in getForecastMetaSuccess() forecast.state.ts. ForecastMetaDTO: ${JSON.stringify(action.model)} action: ${JSON.stringify(action)}`
      });
    }
  }

  @Action(RemoveProjectSuccessAction)
  removeProjectSuccessAction(ctx: StateContext<ForecastStateModel>, action: RemoveProjectSuccessAction) {
    const forecasts = ctx.getState().Forecasts.filter(f => f.ProjectId === action.projectId);
    forecasts.forEach(forecast => {
      ctx.dispatch(new RemoveForecastSuccessAction(forecast.ForecastId));
    });
  }

  @Action(RemoveForecastSuccessAction)
  removeForecastSuccess(ctx: StateContext<ForecastStateModel>, action: RemoveForecastSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const idx = this.getForecastIndexById(ctx, action.forecastId);
    if (idx !== -1) {
      forecasts.splice(idx, 1);
      ctx.patchState({ Forecasts: [...forecasts] });

      const activeId = ctx.getState().ActiveForecastId;
      if (activeId === action.forecastId) {
        ctx.dispatch(new NavigateToHome());
      }
    }
  }

  @Action(SetForecastAsNotFetchedAction)
  setForecastAsNotFetchedAction(ctx: StateContext<ForecastStateModel>, action: SetForecastAsNotFetchedAction) {
    const forecasts = ctx.getState().Forecasts;
    const fcast = forecasts.find(x => x.ForecastId === action.forecastId);
    if (fcast) {
      fcast.ForecastVersions = [];
      ctx.patchState({ Forecasts: [...forecasts] });
    }
  }

  @Action(RemoveNowcastSuccessAction)
  removeNowcastSuccessAction(ctx: StateContext<ForecastStateModel>, action: RemoveNowcastSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const fversion = this.getForecastVersion(forecasts, action.parentFversionId);
    if (fversion !== null) {
      const variable = fversion.getAllVariables().find(v => v.ForecastVariableId === action.nowcastedFvarId);
      variable.Nowcast = null;
      ctx.patchState({ Forecasts: [...forecasts] });
    }
  }

  @Action(GetAllForecastVariablesSuccessAction)
  syncForecastVariablesAction(ctx: StateContext<ForecastStateModel>, action: GetAllForecastVariablesSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (version) {
      version.updateVariables(action.variable);
      ctx.patchState({ Forecasts: [...forecasts] });
    }
  }

  @Action(SetActiveForecastIdAction)
  setActiveForecastIdAction(ctx: StateContext<ForecastStateModel>, action: SetActiveForecastIdAction) {
    ctx.patchState({ ActiveForecastId: action.forecastId });
  }

  @Action(DeleteSourceVariableVersionSuccessAction)
  deleteSourceVariableVersionAction(ctx: StateContext<ForecastStateModel>, action: DeleteSourceVariableVersionSuccessAction) {
    const forecasts = ctx.getState().Forecasts;
    const metaId = action.model.SourceVariableMetaId;
    forecasts.forEach(forecast => {
      forecast.ForecastVersions.forEach(forecastVersion => {
        if (!forecastVersion.needFetch()) {
          forecastVersion.removeNowcastResultByMetaId(metaId);
        }
      });
    });
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  ///
  ///
  /// VAR SELECT
  ///
  ///
  @Action(VarSelectActions.SetResultOnVariables)
  SetVarSelectModelsOnVariablesAction(ctx: StateContext<ForecastStateModel>, action: VarSelectActions.SetResultOnVariables) {
    if (!action.results?.length) { return; }
    const fVersionId = action.results[0].ForecastVersionId;
    const forecasts = ctx.getState().Forecasts;
    const fVersion = this.getForecastVersion(forecasts, fVersionId);
    if (!fVersion) { return; }
    action.results.forEach(result => {
      fVersion.IndicatorVariables.forEach(x => {
        if (result.VsResultType !== 'MF') {
          x.Active = result.ActiveIndicatorIds.includes(x.ForecastVariableId);
        } else {
          x.ActiveMF = result.ActiveIndicatorIds.includes(x.ForecastVariableId);
        }
      });
    });
    ctx.patchState({ Forecasts: [...forecasts] });
    ctx.dispatch(new VarSelectActions.PostSetResultOnVariables(fVersionId));
  }

  @Action(VarSelectActions.RemoveResult)
  removeVarSelectResultAction(ctx: StateContext<ForecastStateModel>, action: VarSelectActions.RemoveResult) {
    const forecasts = ctx.getState().Forecasts;
    const fVersion = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!fVersion) { return; }
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(VarSelectActions.UpdatedSettingsSucces)
  varSelectUpdatedSettingsSucces(ctx: StateContext<ForecastStateModel>, action: VarSelectActions.UpdatedSettingsSucces) {
    const forecasts = ctx.getState().Forecasts;
    const fcast = forecasts.find(f => f.ForecastId === action.forecastId);
    if (fcast == null) { return; }
    const fVersion = fcast.ForecastVersions.find(p => p.ForecastVersionId === action.forecastVersionId);
    if (!fcast || !fVersion) { return; }
    fVersion.VarSelectSettings = action.settings;
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  ///
  ///
  /// HISTORIC EVENTS
  ///
  ///
  @Action(HistoricEventActions.GetOneInForecastSuccess)
  getHistoricEventInForecastSuccess(ctx: StateContext<ForecastStateModel>, action: HistoricEventActions.GetOneInForecastSuccess) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.baseEvent.ForecastVersionId);
    if (!version) { return; }
    version.ImportedHistoricBaseEventIds.addUniqueId(action.baseEvent.ImportedHistoricBaseEventId);
    version.ImportedHistoricBaseEvents.addOrUpdate(action.baseEvent);
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(HistoricEventActions.RemoveFromForecast)
  removeHistoricEventFromForecastSuccess(ctx: StateContext<ForecastStateModel>, action: HistoricEventActions.RemoveFromForecast) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    version.ImportedHistoricBaseEventIds.removeId(action.baseEventId);
    version.ImportedHistoricBaseEvents.removeById(action.baseEventId);
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(HistoricEventActions.GetAllInForecastSuccess)
  getHistoricEventsInForecastSuccess(ctx: StateContext<ForecastStateModel>, action: HistoricEventActions.GetAllInForecastSuccess) {
    const forecasts = ctx.getState().Forecasts;
    const version = this.getForecastVersion(forecasts, action.forecastVersionId);
    if (!version) { return; }
    version.ImportedHistoricBaseEvents = action.events;
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  ///
  ///
  /// Benchmarks
  ///
  ///
  @Action(SourceVariableActions.CreateForecastBenchmarkSuccess)
  createForecastBenchmarkSuccess(ctx: StateContext<ForecastStateModel>, action: SourceVariableActions.CreateForecastBenchmarkSuccess) {
    const forecasts = ctx.getState().Forecasts;
    const forecastVersion = this.getForecastVersion(forecasts, action.fverId);
    if (!forecastVersion) { return; }
    forecastVersion.ActiveBenchmarks.push(action.model.ForecastBenchmarkId);
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(SourceVariableActions.GetActiveForecastBenchmarksSuccess)
  getActiveForecastBenchmarksSuccess(ctx: StateContext<ForecastStateModel>, action: SourceVariableActions.GetActiveForecastBenchmarksSuccess) {
    const forecasts = ctx.getState().Forecasts;
    action.active.forEach(x => {
      const forecastVersion = this.getForecastVersion(forecasts, x.ForecastVersionId);
      forecastVersion.ActiveBenchmarks = x.BenchmarkIds;
    });

    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(SourceVariableActions.ToggleForecastBenchmarkSuccess)
  toggleForecastBenchmarkSuccess(ctx: StateContext<ForecastStateModel>, action: SourceVariableActions.ToggleForecastBenchmarkSuccess) {
    const forecasts = ctx.getState().Forecasts;
    const forecastVersion = this.getForecastVersion(forecasts, action.fverId);
    const idx = forecastVersion.ActiveBenchmarks.indexOf(action.benchId);
    if (idx === -1) {
      forecastVersion.ActiveBenchmarks.push(action.benchId);
    } else {
      forecastVersion.ActiveBenchmarks.splice(idx, 1);
    }
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  @Action(SourceVariableActions.DeleteForecastBenchmarksForVariableSuccess)
  deleteForecastBenchmarksForVariableSuccess(ctx: StateContext<ForecastStateModel>, _action: SourceVariableActions.CreateForecastBenchmarkSuccess) {
    const forecasts = ctx.getState().Forecasts;

    ctx.patchState({ Forecasts: [...forecasts] });
  }

  ///
  ///
  /// Model Filters
  ///
  ///
  @Action(GetForecastModelFilterSettingsAction)
  getForecastModelFilterSettingsAction(ctx: StateContext<ForecastStateModel>, action: GetForecastModelFilterSettingsAction) {
    const forecasts = ctx.getState().Forecasts;
    const forecast = forecasts.find(x => x.ForecastId === action.forecastId);
    if (!forecast) { return; }
    forecast.ModelFilterSettings = action.settings;
    ctx.patchState({ Forecasts: [...forecasts] });
  }

  ///
  ///
  /// SCENARIO
  ///
  ///
  @Action(ScenarioAction.CreateOrUpdateSuccess)
  scenarioActionCreateOrUpdateSuccess(ctx: StateContext<ForecastStateModel>, action: ScenarioAction.CreateOrUpdateSuccess) {
    const forecasts = ctx.getState().Forecasts;
    const forecast = forecasts.find(x => x.ForecastVersionIds.includes(action.scenario.ForecastVersionId));
    if (!forecast) { return; }
    const forecastVersion = forecast.ForecastVersions.find(x => x.ForecastVersionId === action.scenario.ForecastVersionId);
    if (!forecastVersion.ScenarioIds.includes(action.scenario.ScenarioId)) {
      forecastVersion.ScenarioIds.push(action.scenario.ScenarioId);
      forecast.ForecastVersions.addOrUpdate(forecastVersion);
      ctx.patchState({ Forecasts: [...forecasts] });
    }
  }

  @Action(ScenarioAction.DeleteSuccess)
  scenarioActionDeleteSuccess(ctx: StateContext<ForecastStateModel>, action: ScenarioAction.DeleteSuccess) {
    const forecasts = ctx.getState().Forecasts;
    const forecast = forecasts.find(x => x.ForecastVersionIds.includes(action.forecastVersionId));
    if (!forecast) { return; }
    const forecastVersion = forecast.ForecastVersions.find(x => x.ForecastVersionId === action.forecastVersionId);
    if (!forecastVersion) { return; }
    const idx = forecastVersion.ScenarioIds.indexOf(action.scenarioId);
    if (idx > -1) {
      forecastVersion.ScenarioIds.splice(idx, 1);
      forecast.ForecastVersions.addOrUpdate(forecastVersion);
      ctx.patchState({ Forecasts: [...forecasts] });
    }
  }

  ///
  ///
  /// OTHERS
  ///
  ///
  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<ForecastStateModel>) {
    ctx.patchState({ Forecasts: [], ActiveForecastId: null });
  }

  ///
  ///
  /// HELPERS
  ///
  ///
  private getForecastVersion(forecasts: ForecastModel[], fVersionId: string) {
    const forecast = forecasts.find(x => x.getVersionById(fVersionId) !== undefined);
    return forecast ? forecast.getVersionById(fVersionId) : null;
  }

  private getForecastIndexById(ctx: StateContext<ForecastStateModel>, forecastId: string) {
    return ctx.getState().Forecasts.findIndex(x => x.ForecastId === forecastId);
  }
}
