import { Def } from '@core/decorators/def';
import { ConfirmedNewLocationDTO } from './variable-update-info-dto';

export class CreateFileVersionDTO {
  @Def() CompanyId: string;
  @Def() UploadedFileId: string;
  @Def() FileName: string;
  @Def() FileData: string;
  @Def() Separator: string;
  @Def() Extension: string;
  @Def() VariableLocations: ConfirmedNewLocationDTO[];
}
