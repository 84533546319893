import { DisplayValue } from '../types/display-value';

export class ValidityLevel extends DisplayValue<string> { };
export const ValidityLevels = [
  new ValidityLevel('high', 'High', 'High validity'),
  new ValidityLevel('medium', 'Medium', 'Medium validity'),
  new ValidityLevel('low', 'Low', 'Low validity')
]


