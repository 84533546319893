import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';

export class ComponentDisplayFilters {
  @Def() Name: string;
  @Def() Filters: DisplayFilterModel[];
}

export class DisplayFilterModel implements IHasModelId {
  @Def() DisplayFilterId?: string;
  @Def() Key: string = 'hideUsed';
  @Def() Display: string = 'Hide used';
  @Def() Active: boolean = false;
  @Def() Name?: string;
  @Def() Type: DisplayFilterModelTypes = 'boolean';

  getModelId() {
    return this.Key;
  }

  constructor(opts: any = {}) {
    Object.assign(this, opts);
  }
}

export type DisplayFilterModelTypes = 'boolean' | 'dropdown';
