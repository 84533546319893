import { UpdateMultiIndicatorsModalOpts } from './update-multi-indicators-modal.options';

export class OpenUpdateMultiIndicatorsModalAction {
  public static readonly type = '[MODAL] Open update multi update for indicators modal';
  data: UpdateMultiIndicatorsModalOpts;
  constructor(
    forecastVersionId: string,
    indicatorIds: string[]
  ) {
    this.data = { forecastVersionId, indicatorIds };
  }
}
