import { MacrobondEntityInfoDTO } from './dtos/macrobond-entityinfo.dto';
import { MacrobondExportBase } from './dtos/macrobond-export.dto';

export namespace MacrobondUtils {

  export function parseEntityInfo(info: MacrobondEntityInfoDTO) {
    const ts = info?.getGroup('Time series');
    const category = ts?.getInfo('Category')?.value;
    const name = ts?.getInfo('Name')?.value;
    const basePrimName = name?.split(':')?.last();
    return { category, basePrimName };
  }

  export function initExportDTO(
    target: MacrobondExportBase,
    info: MacrobondEntityInfoDTO,
    nameExtra: string,
    desc: string
  ) {
    const { category, basePrimName } = MacrobondUtils.parseEntityInfo(info);
    target.IHCategory = category;
    target.BasePrimName = basePrimName;
    target.PrimName = basePrimName + nameExtra;
    target.Description = desc;
  }

}
