
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { CompanyHelper } from '@core/store/company/company-helpers';
import { ProjectModel } from '@core/store/project/project.model';
import { IndicioPermissionType } from '@core/types/indicio-permission.type';
import { VisibilityLevelType } from '@modules/lang/language-files/visibility';
import { SecurityUtils } from '@shared/utils/security.utils';
import { Country } from '../../../modules/lang/types/country';
import { CompanyHorizonInfoDTO } from './dtos/company-dto';
import { EmployeeModel } from './employee.model';


export type LicenseType = 'Indicio' | 'Macrobond';

export class CompanyModel implements IHasModelId {

  CompanyId: string = null;
  Name: string = null;
  CountryCode: string = null;
  Country: Country = null;
  CreatedDate: Date = null;
  FiscalYear: number = 0;
  Employees: EmployeeModel[] = [];
  Permissions: IndicioPermissionType[] = [];
  ProjectIds: string[] = [];
  UploadedFileIds: string[] = [];
  HistoricBaseEventIds: string[] = [];
  SourceVariableIds: string[] = [];
  DisabledProviders: string[] = [];
  LicenseType: LicenseType = 'Indicio';
  SourceFromProviderVisibility: VisibilityLevelType = 'private';
  SourceFromFileVisibility: VisibilityLevelType = 'private';
  Trial: boolean = false;
  MacrobondEnabled: boolean = false;
  MaxRollingWindowTime: number = 15;
  Force2FAFrom: Date = null;
  Force2FA: boolean = false;
  ForceAutomaticLogout = true;
  Disabled: boolean = false;
  TrialExpirationDate: Date = null;

  ProjectCount: number = null;
  ForecastCount: number = null;
  UserCount: number = null;
  MaxUsers: number = null;

  MaxHorizons: CompanyHorizonInfoDTO[];
  Support: string[] = [];

  // Frontend specific
  invitesToSendOnSave = [];
  activeUserCanCreateProject = false;
  fetched = false;

  // Create specific
  CompanyAdminEmail: string = null;

  public hasPermission(neededPermissions: IndicioPermissionType | IndicioPermissionType[]) { return SecurityUtils.checkPermission(this.Permissions, neededPermissions); }
  public getEmployeeByEmail(email: string) { return this.Employees.find(x => x.Email === email); }
  public canUserEdit(userEmail: string) { return this.isUserAdmin(userEmail); }
  public isUserAdmin(userEmail: string) { return CompanyHelper.userHasRole(userEmail, this.Employees, 'companyadmin'); }
  public isUserGuest(userEmail: string) { return CompanyHelper.userHasRole(userEmail, this.Employees, 'companyguest'); }
  public getModelId(): string { return this.CompanyId; }
  public getEmailsNotInProject(project: ProjectModel) {
    const members = project.Members;
    return this.Employees
      .filter(employee => members.findIndex(m => m.Email === employee.Email) === -1)
      .map(employee => employee.Email);
  }
}

export class CompanyAdminModel extends CompanyModel {
  LastActivityDate?: Date = null;
  LastActivityUserEmail?: string = null;

  oldTrial = false;
  recentActivity = false;
  daysUsedAfterTrial = 0;
}
