import { Component } from '@angular/core';
import { AlphaVantageModalBackAction } from '@core/actions/navigation.actions';
import { AlphaVantageStockPeriodicities } from '@core/constants/alphavantage.constants';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AlphaVantageService } from '@core/store/providers/alphavantage/alphavantage.service';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { ProviderService } from '@core/store/providers/provider.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { LanguageService } from '@modules/lang/language.service';
import { Store } from '@ngxs/store';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OpenModifyAPIKeyModal } from '../../modify-api-key/modify-api-key.actions';
import { OpenAlphaStockModal } from './alpha-stock-modal.actions';
import { AlphaStockModalOpts } from './alpha-stock-modal.options';


@Component({
  selector: 'indicio-alpha-stock-modal',
  templateUrl: './alpha-stock-modal.component.html'
})
export class AlphaStockModalComponent extends ModalModelComponent {

  forecastVersion: ForecastVersionModel;

  stockQuery: string;
  finalQuery: string;
  searchResult = [];
  searchLoading = false;

  valueTypes = ['max', 'min', 'val', 'open', 'close', 'vol'];
  periodicities = [
    { display: 'Daily', value: AlphaVantageStockPeriodicities.TIME_SERIES_DAILY },
    { display: 'Weekly', value: AlphaVantageStockPeriodicities.TIME_SERIES_WEEKLY },
    { display: 'Monthly', value: AlphaVantageStockPeriodicities.TIME_SERIES_MONTHLY }
  ];
  periodicity: AlphaVantageStockPeriodicities;

  selectedStockName: string;
  selectedStock: object;

  variable = new RemoteDataRequestModel;
  timeout: any;
  nameConflict = false;

  constructor(
    public store: Store,
    public envService: EnvironmentService,
    public alphaService: AlphaVantageService,
    public providerService: ProviderService,
    private langService: LanguageService,
    private clientService: ClientFrontendService,
    private statusService: StatusService,
    private forecastVariableService: ForecastVariableFrontendService,
    private sourcevariableService: SourceVariableFrontendService
  ) {
    super();
  }

  public back() {
    this.close();
    this.store.dispatch(new AlphaVantageModalBackAction(this.forecastVersion));
  }

  public setOptions(options: AlphaStockModalOpts) {
    this.initApiKey();

    this.isLoading = false;
    this.forecastVersion = options.forecastVersion;

    this.variable.ValueType = 'close';
    this.periodicity = AlphaVantageStockPeriodicities.TIME_SERIES_DAILY;
  }

  initApiKey() {
    const source = this.clientService.client.findDataSource('alphavantage', true);
    if (!source || !source.ApiKey) {
      setTimeout(() => {
        this.close();
        this.store.dispatch(new OpenModifyAPIKeyModal(
          this.envService.getSourceType(DataProvider.alphavantage),
          this.langService.sourceTypes.find(x => x.Value === 'alphavantage')?.Extra ?? 'Contact support.',
          null,
          _key => {
            this.store.dispatch(new OpenAlphaStockModal(this.forecastVersion));
          }, () => { },
          new RegExp('^[0-9A-Z]{16}$')));
      }, 0);

      return;
    } else {
      this.alphaService.setAPIKey(source.ApiKey);
    }
  }

  debounce(val: string) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.searchStock(val), 500);
  }

  public async searchStock(val: string) {
    if (val && val !== this.selectedStockName) {
      this.searchLoading = true;
      const result = await this.alphaService.searchStock(val);
      this.searchLoading = false;
      return this.searchResult = result.bestMatches;
    }
    return [];
  }

  resetSearch() {
    this.stockQuery = '';
    this.searchResult = [];
    this.selectedStock = null;
    this.selectedStockName = '';
  }

  public setSelectedStock($event) {
    this.selectedStockName = $event.source.value;
    this.selectedStock = this.searchResult.find(s => s['1. symbol'] === this.selectedStockName);

    this.variable.Name = `${this.selectedStock['2. name']} (${this.selectedStock['1. symbol']})`;
  }

  public checkName() {
    this.nameConflict = this.sourcevariableService.isNameConflict(this.variable.Name);
  }

  public async importData() {
    this.pending = true;
    try {
      const data = await this.alphaService.getStockData(this.periodicity, this.selectedStockName);

      if (!data.hasOwnProperty('Meta Data')) {
        throw new Error('Error fetching data');
      }

      this.variable.MetaData = this.selectedStock.faKeyValueArray();
      this.variable.Provider = DataProvider.alphavantage;
      this.variable.RemoteReferenceId = this.selectedStock['1. symbol'];
      this.variable.Data = data;
      this.variable.RemoteUri = this.alphaService.currentUri;

      const sourcevariable = await this.providerService.addVariableFromDatasource(this.clientService.activeCompany.CompanyId, this.variable);
      this.statusService.setMessage('Variable created successfully!', 'Success', true);
      this.close();
      this.forecastVariableService.checkErrorsAndCreateForecastVariable(sourcevariable, this.forecastVersion)
        .catch(_err => {
          this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [sourcevariable.SourceVariableId]));
        });
    } catch (e) {
      if (e.hasOwnProperty('stack')) {
        e = { error: e.message };
      }
      this.statusService.setError(e, true);
      this.pending = false;
    }
  }
}


