// date-time.service
import { Injectable } from '@angular/core';
import * as steps from '@app/modules/lang/misc/onboarding.json';
import { CloseActiveModals } from '@shared/modals/modal.actions';
import { StorageUtils } from '@shared/utils/storage.utils';
import { ShepherdService } from 'angular-shepherd';
import { AppearanceService } from '../../store/profile/appearance.service';
import { ActionService } from '../actions/actions.service';

const TOURS_DEACTIVATED = true;

interface StepOptions {
  id: string;
  title: string;
  text: string;
  attachTo: string;
  buttons: any[];
  advancedSetting: boolean;
  advanceOn?: string;
  when?: object;
  showOn?: Function;
}

@Injectable({ providedIn: 'root' })
export class OnboardingService {

  public ACTIVE = !TOURS_DEACTIVATED;

  public steps;

  tour: any;
  hide = false;
  constructor(
    private shepherd: ShepherdService,
    public appearance: AppearanceService,
    private actions: ActionService
  ) {
    this.steps = (steps as any).default;
    this.hide = this._isComplete('hide');

    this.actions.dispatched(CloseActiveModals).subscribe(() => {
      if (this.shepherd.isActive) {
        this.shepherd.hide();
      }
    });
  }

  create(tourId: String, section: object) {
    if (TOURS_DEACTIVATED) { return; }
    if (this._isComplete(tourId) || this.hide) { return; }

    if (this.shepherd.isActive) {
      this.shepherd.complete();
    }

    this.shepherd.defaultStepOptions = {
      classes: 'shepherd-theme-arrows',
      cancelIcon: {
        enabled: true
      },
      scrollTo: { behavior: 'smooth' }
    };
    // this.shepherd.disableScroll = true;
    this.shepherd.modal = true;
    this.shepherd.onTourFinish = () => {
      this._markComplete(tourId);
    };

    const _steps = [];

    Object.keys(section).forEach((id, i) => {
      const step = { id, options: {} };

      const options = <StepOptions> section[id];

      if (options.advancedSetting === true && this.appearance.AdvancedUI === false) { return; }

      options.id = id;
      // options.advanceOn = `${options.attachTo} click`;
      options.buttons = this._getButtons(section, i + 1);
      options.when = {
        complete: () => {
          console.log(id, 'complete step');
        },
        hide: () => {
          console.log(id, 'hide step', section);
        },
        cancel: () => {
          console.log(id, 'cancel step');
        },
        next: () => {
          console.log(id, 'next step');
        }
      };

      step.options = options;

      _steps.push(step);
    });

    this.shepherd.addSteps(_steps.map(s => s.options));

    this.shepherd.start();
  }

  private _getButtons(section: object, stepNmb) {
    const _steps = Object.keys(section).length;
    const backButton = {
      action: () => this.shepherd.back(),
      classes: 'shepherd-button-secondary',
      text: ''
    };

    if (stepNmb === 1) {
      backButton.text = 'Close';
      backButton.action = () => this.shepherd.complete();
    } else {
      backButton.text = 'Back';
    }

    const nextButton = {
      action: () => this.shepherd.next(),
      text: 'Next'
    };

    if (_steps === 1) {
      backButton.classes = 'hidden';
      nextButton.action = () => this.shepherd.complete();
      nextButton.text = 'Complete';
    } else if (stepNmb === steps) {
      nextButton.action = () => this.shepherd.complete();
      nextButton.text = 'Complete';
    }

    return [backButton, nextButton];
  }

  public toggleOnOff() {
    if (this.hide) {
      return this.on();
    }
    this.off();
  }

  public off() {
    this._markComplete('hide');
    this.hide = true;
  }

  public on() {
    this._resetStep('hide');
    this.hide = false;
  }

  public reset() {
    StorageUtils.remove('tours');
  }

  private _getTourStates(): Object {
    return StorageUtils.get('tours') || {};
  }

  private _isComplete(id) {
    const tourState = this._getTourStates()[id];
    return tourState;
  }

  private _markComplete(id) {
    const tourStates = this._getTourStates();
    tourStates[id] = true;
    StorageUtils.set('tours', tourStates);
  }

  private _resetStep(id) {
    const tourStates = this._getTourStates();
    delete tourStates[id];
    StorageUtils.set('tours', tourStates);
  }
}
