// customDateAdapter.ts
import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateFormatPipe } from '@shared/modules/pipes/';
import * as moment from 'moment';


@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  constructor(
    private datePipe: DateFormatPipe
  ) {
    super('en-US');
  }

  // This overrides the default first-day-of-week to be 1 instead of 0
  public getFirstDayOfWeek(): number {
    return 1;
  }

  public format(date: moment.Moment, displayFormat: string): string {
    const dated = this.datePipe.transform(date, null, displayFormat);
    return dated;
  }
}
