import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicioPipes } from '.';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...IndicioPipes,
  ],
  exports: [
    ...IndicioPipes,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class PipesModule { }
