import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { scenarioColors } from '@core/constants/color.constants';
import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { BvarSettings, ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { MultivariateFrontendService } from '@core/store/stat-model/multivariate/multivariate.frontend.service';
import { Transformations } from '@core/types/transformation.type';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { VariableTransformation } from '@modules/lang/types/variable-transformation';
import { Store } from '@ngxs/store';
import { AlgConverter } from '@shared/components/line-graph/alg-line.utils';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AdvancedLineGraphComponent } from '@shared/components/line-graph/alg.component';
import { AlgChartStyles, AlgOptions, StrokeSetups } from '@shared/components/line-graph/alg.options';

export interface SetBvarPriorsDialogComponentData {
  forecastVersion: ForecastVersionModel,
}

@Component({
  selector: 'indicio-create-user-dialog',
  templateUrl: 'set-bvar-priors.dialog.html',
})
export class SetBvarPriorsDialogComponent {
  public static Id: string = 'SetBvarPriorsDialogComponent';

  @ViewChild('graph') graph: AdvancedLineGraphComponent;

  private opts: SetBvarPriorsDialogComponentData;

  // Status flags
  public isLoading = true;
  public canRun = false;
  public canSave = false;
  public cantSaveMessage: string;

  public settings: BvarSettings[] = [];

  public transformationOptions: VariableTransformation[] = [];
  public transformationOptionsNoLog: VariableTransformation[] = [];
  public variable: ForecastVariableModel;
  private variables: ForecastVariableModel[] = [];
  private fVersion: ForecastVersionModel;


  public graphReady = false;
  public periodicity: PeriodicityType;
  public lines: ALGLineModel[];
  public historicLine: ALGLineModel;
  public options: AlgOptions.Options;
  public chartStyles: AlgChartStyles = {
    borderSize: 2, borderRadius: 4, historyLine: '#6388D0',
    colorScheme: 'dark',
    plotHeight: 210
  };

  constructor(
    protected store: Store,
    public envService: EnvironmentService,
    public dialogRef: MatDialogRef<SetBvarPriorsDialogComponent>,
    public appearance: AppearanceService,
    private status: StatusService,
    private statService: MultivariateFrontendService,
    private variableService: ForecastVariableFrontendService,
    @Inject(MAT_DIALOG_DATA) public data: SetBvarPriorsDialogComponentData) {
    this.setOptions(data);
    this.transformationOptions = this.envService.VariableTransformations.filter(x => Transformations.BVarPrior.includes(x.Value));
    this.transformationOptionsNoLog = this.transformationOptions.filter(x => x.Value !== 'log');
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  /***
   * Set which variable should be set as focused (i.e. shown in the graph).
   * @param forecastVariableId
   */
  public setFocused(forecastVariableId: string) {
    this.settings.forEach(x => x.Focused = x.ForecastVariableId === forecastVariableId);
    this.variable = this.variables.find(x => x.ForecastVariableId === forecastVariableId);
    this.updateGraphData();
    const settings = this.settings.find(x => x.ForecastVariableId === forecastVariableId);
    this.setTransformation(settings.Transformation);
  }

  public setTransformation(transform: VariableTransformation) {
    if (transform == null) {
      transform = this.transformationOptions.find(x => x.Value === 'no_transform');
    }
    if (!!this.graph) this.graph.setTransform(transform.toAlgTransformation());
  }

  /***
   * Reset temporary settings of a single variable to those previous to changing.
   */
  public resetSettings(settings: BvarSettings) {
    let variable = this.variables.find(x => x.ForecastVariableId === settings.ForecastVariableId);
    settings.Transformation = variable.PriorTransformation;
    settings.PriorMean = variable.PriorMean;
    settings.PriorVariance = variable.PriorVariance;
    this.checkAllSettings();
  }

  /***
   * Apply all temporary settings to the variables.
   */
  private applySettings() {
    for (const variableSettings of this.settings) {
      let variable = this.variables.find(x => x.ForecastVariableId === variableSettings.ForecastVariableId);
      variable.PriorTransformation = variableSettings.Transformation;
      variable.PriorMean = variableSettings.PriorMean;
      variable.PriorVariance = variableSettings.PriorVariance;
    }
  }

  /***
   * Set a value (transform, prior mean, prior variance) of one of the variable settings.
   */
  public setValue(event, settings: BvarSettings, field: string) {
    let variable = this.variables.find(x => x.ForecastVariableId === settings.ForecastVariableId);
    if (field === 'trans') {
      settings.Transformation = this.envService.getVariableTransformation(event);
      if (!!this.graph) this.graph.setTransform(settings.Transformation.toAlgTransformation());
    } else if (field === 'mean') {
      settings.PriorMean = +event.target.value;
    } else if (field === 'var') {
      settings.PriorVariance = +event.target.value;
    }
    if (variable.PriorMean !== settings.PriorMean
      || variable.PriorVariance !== settings.PriorVariance
      || variable.PriorTransformation !== settings.Transformation) {
      settings.Changed = true;
      this.canRun = false;
    }
    settings.ValidVariance = settings.validBVarVariance(settings.PriorVariance);

    this.checkAllSettings();
  }

  /***
   * All settings should be valid before this function is called, applies the settings and saves them.
   */
  public save() {
    if (!this.canSave) {
      return;
    }
    this.applySettings();
    const variables: ForecastVariableModel[] = [];
    for (const v of this.variables) {
      const settings = this.settings.find(x => x.ForecastVariableId === v.ForecastVariableId);
      if (settings.Changed) {
        variables.push(v);
      }
    }
    this.variableService.setPriorInfo(this.fVersion.ForecastId, variables)
      .then(() => {
        this.canRun = true;
        this.status.setMessage('Settings saved!', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
      });
  }

  /***
   * All settings should be saved before this function is called, triggers calculation.
   */
  public run() {
    if (!this.canRun) {
      return;
    }
    const modelName = this.envService.getModelName('ssbvar');
    this.statService.triggerMultivariateModels(this.fVersion.ForecastVersionId, [modelName])
      .then(() => {
        this.status.setMessage('Running Bayesian VAR', 'Success');
        this.dialogRef.close();
      })
      .catch(error => {
        this.status.setError(error, true);
      });
  }

  /***
   *
   * Private functions below
   *
   */
  /***
   * Validate all settings to determine if they can be saved.
   * @private
   */
  private checkAllSettings() {
    let allValid = true;
    this.cantSaveMessage = null;
    for (const variableSettings of this.settings) {
      const { canRun, cantSaveMessage } = variableSettings.validSettings();
      if (!canRun) {
        allValid = false;
        this.cantSaveMessage = cantSaveMessage;
      }
    }
    const anyChanged = this.settings.some(x => x.Changed);
    this.canSave = allValid && anyChanged;
    this.canRun = allValid && !anyChanged;
  }

  private setOptions(options: SetBvarPriorsDialogComponentData) {
    this.opts = options;
    this.fVersion = options.forecastVersion;
    const variableIds = this.fVersion.getAllVariables().filter(x => x.Active && !x.IsMixedFreq).map(x => x.ForecastVariableId);
    Promise.all(this.variableService.getOrFetchByIds(this.fVersion.ForecastVersionId, variableIds))
      .then(fVars => this.variables = fVars)
      .then(_ => {
        this.periodicity = this.fVersion.Periodicity;
        this.settings = this.variables.map(x => x.BVarSettings);
        // Set the first variable as focused to show it in the graph.
        this.settings[0].Focused = true;
        this.variable = this.variables[0];
        this.settings.forEach(x => x.Transformation ??= this.transformationOptions.find(x => x.Value === 'no_transform'));
        this.checkAllSettings();
        this.updateGraphData();
      }).catch(err => this.status.setError(err))
      .finally(() => this.isLoading = false);
  }



  private updateGraphData() {
    const singleSeries = AlgConverter.fromSpeedHistoricValues(this.variable.ForecastVariableValues as PlotValueDTO[], this.variable.Name, this.variable.Name, 'data');

    singleSeries.Color = scenarioColors[0];
    singleSeries.Name = `Result: ${this.variable.Name}`;

    this.historicLine = singleSeries;
    this.options = this.getGraphOptions();
    this.graphReady = true;
  }

  private getGraphOptions() {
    const opts = AlgOptions.CreateOptions({
      noDataText: 'No data selected',
      inModal: true,
      summary: true,
      showOnlyHistoric: true,
      menuConfig: {
        forceShowButtons: true,
        showFittedDataBtn: false,
        dontShowPastForecastBtn: true,
        dontShowDownloadBtn: false,
        showSeasonalAdjBtn: false,
        showOutlierAdjBtn: false,
        showMenu: false
      },
      axisConfig: {
        yAxisPosition: 'inside',
        yAxisLine: StrokeSetups.AxisLineDashed
      },
      updateGraph: false,
      showAssessments: false,
      dontShowCIHover: false,
      isPercent: false,
      rocEnabled: this.appearance.RoCEnabled,
      rocYYEnabled: this.appearance.RoCYYEnabled,
      header: 'Group variable ',
      subheader: 'Result: ',
      dates: this.variable.ForecastVariableValues.map(x => x.m),
      pointsToShow: Math.min(this.variable.ForecastVariableValues.length, 200)
    });
    return opts;
  }
}
