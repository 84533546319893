import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { CreateAutoMailDTO } from '@modules/admin/components/system/auto-mails/entities/auto-mail.dto';
import { AdminFrontendService } from '@modules/admin/services/admin-frontend.service';
import { DisplayValue } from '@modules/lang/types/display-value';

const ALLOWED_PERIODICITIES = ['week', 'month', 'quarter'];

export interface CreateEditAutoMailDialogData {
  Dto: CreateAutoMailDTO;
  edit?: boolean;
}

@Component({
  selector: 'indicio-create-edit-automail-dialog',
  templateUrl: 'create-edit-automail.dialog.html',
  styleUrls: ['create-edit-automail.dialog.less'],
})
export class CreateEditAutoMailDialogComponent {
  public variables: any;
  public recipients: string;
  public edit: boolean = false;

  public periodicities: DisplayValue<string>[] = [];
  public includedCompanies: DisplayValue<string>[] = [];

  constructor(
    public envService: EnvironmentService,
    public service: AdminFrontendService,
    public dialogRef: MatDialogRef<CreateEditAutoMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateEditAutoMailDialogData) {
    this.service.getAutoMailVariables().then(variables => {
      this.variables = variables;
    });

    this.periodicities = this.envService.Periodicities.filter(x => ALLOWED_PERIODICITIES.includes(x.Value)).map(x => new DisplayValue(x.Value, x.Display));

    if (data.Dto.Recipients) {
      this.recipients = data.Dto.Recipients.join(', ');
    }
    this.edit = data.edit || false;

    if (this.edit) {
      this.getCompanies();
    }
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onRecipientsChange(recipients: string) {
    this.recipients = recipients;
    this.data.Dto.Recipients = recipients.split(',').map(x => x.trim());
  }

  public toggleIgnoredCompany(company: DisplayValue<string>) {
    this.data.Dto.IgnoredCompanies.togglePresence(company.Value);
  }

  public isIgnoredCompany(company: DisplayValue<string>) {
    return this.data.Dto.IgnoredCompanies.includes(company.Value);
  }

  public onReportTypeChange(change: MatSelectChange) {
    this.data.Dto.ReportType = change.value;
    this.getCompanies();
  }

  public onTrialOnlyChange(change: MatSlideToggleChange) {
    this.data.Dto.TrialOnly = change.checked;
    this.getCompanies();
  }

  private getCompanies() {
    const dto = {
      ReportType: this.data.Dto.ReportType,
      TrialOnly: this.data.Dto.TrialOnly,
    };
    this.service.getAutoMailIncludedCompanies(<CreateAutoMailDTO> dto).then(includedCompanies => {
      this.includedCompanies = includedCompanies;
      this.data.Dto.IgnoredCompanies = this.data.Dto.IgnoredCompanies || [];
    });
  }

  save() {
    this.dialogRef.close(this.data.Dto);
  }
}
