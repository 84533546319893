import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { MathService } from '@core/services/mathjax/mathjax.service';
import { StatusService } from '@core/services/status/status.service';
import { MultivariateFrontendService } from '@core/store/stat-model/multivariate/multivariate.frontend.service';
import { StatTransformationModel } from '@core/store/stat-model/stat-model.model';
import { StatModelUtils } from '@core/store/stat-model/stat-model.utils';
import { UnivariateFrontendService } from '@core/store/stat-model/univariate/univariate.frontend.service';
import { UnivariateModelName } from '@modules/lang/language-files/stat-models';
import { DisplayValue } from '@modules/lang/types/display-value';
import { ModelName } from '@modules/lang/types/model-name';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DateUtils } from '@shared/utils/date.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { ModelPropertiesType, ResidualAnalysisDialogData } from '../residual-analysis/residual-analysis.dialog';


export type ModelComponentType = 'univariate' | 'multivariate';

export class ModelPropertiesDialogData {
  groupName: string;
  modelName: ModelName;
  models: StatTransformationModel[];
  forecastVersionId: string;
  type: ModelComponentType;
  transformation?: string;
  isAdmin: boolean;
}

export class ModelPropertiesDTO {
  public Name: string;
  public Base64String: string;
  public MimeType: string;
  public Type: ModelPropertiesType;
  public Date: string;
  public decoded: string;
}

class ModelOpts {
  Display: string;
  Color: string;
  Value: string;
  ModelBuilt: boolean;
}

@Component({
  selector: 'indicio-model-properties-dialog',
  templateUrl: 'model-properties.dialog.html',
  styleUrls: ['./model-properties.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class ModelPropertiesDialogComponent {
  public static Id: string = 'ModelPropertiesDialogComponent';

  public loading: boolean = true;
  public allFailed: boolean = false;
  public loadingTextFiles: boolean = true;
  public mathJaxReady: boolean = false;
  public isNaive: boolean = false;
  public seeMore: boolean = false;
  public noSimplifiedModelFound: boolean = false;

  public forecastVersionId: string = null;
  public fullModelProperties: string = null;
  public trainModelProperties: string = null;
  public message: string = null;

  public models: StatTransformationModel[] = [];
  public modelTextFiles: ModelPropertiesDTO[] = [];
  public residualFiles: ResultFileDTO[] = [];
  public rollingModelProperties: ModelPropertiesDTO[] = null;
  public selectedRollingModelProperties: ModelPropertiesDTO = null;
  public selectedModel: StatTransformationModel = null;
  public type: ModelComponentType = 'multivariate';
  public datasetOptions: DisplayValue<ModelPropertiesType>[] = [];
  public selectedDataset: ModelPropertiesType = 'full';
  public modelOptions: ModelOpts[] = [];
  public selectedOption: ModelOpts = null;
  public inputData: ModelPropertiesDialogData = null;
  public modelName: ModelName = null;


  constructor(
    public dialogRef: MatDialogRef<ModelPropertiesDialogComponent>,
    private multivariateService: MultivariateFrontendService,
    private univariateService: UnivariateFrontendService,
    private status: StatusService,
    private env: EnvironmentService,
    private mathJax: MathService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: ModelPropertiesDialogData
  ) {
    this.inputData = data;
    this.modelName = data.modelName;
    if (/naive/i.test(data.groupName)) { this.isNaive = true; }
    this.datasetOptions = this.env.GetModelPropertyTypes();
    this.forecastVersionId = data.forecastVersionId;
    this.modelOptions = data.models.map(m => ({ Display: m.modelName.Short, Color: m.color, Value: m.modelName.Value, ModelBuilt: m.ModelBuilt }));
    this.selectedOption = this.modelOptions[0];
    if (data.models.length > 0) {
      this.selectedModel = data.models.find(x => x.ModelBuilt) || data.models[0];
      this.selectedOption = data.transformation ? this.modelOptions.find(x => x.Value.includes(data.transformation)) : this.modelOptions[0];
      this.models = data.models;
      this.type = data.type;
      if (data.models.some(x => x.ModelBuilt)) {
        this.getModelProperties();
      } else {
        this.allFailed = true;
        this.datasetOptions = [];
        this.loadingTextFiles = false;
        this.loading = false;
        this.message = 'No models built';
      }
      this.setResidualAnalysisFiles();
    } else {
      this.message = 'Model did either not build or could not be found.';
      this.noSimplifiedModelFound = true;
      this.loading = false;
    }
    this.mathJax.ready().subscribe(() => {
      this.mathJaxReady = true;
    });
  }

  public openResidualAnalysis() {
    const x = new ResidualAnalysisDialogData();
    x.files = this.residualFiles;
    const modelName = this.models.filter(e => e.modelName.Display === this.selectedModel.modelName.Display);
    for (let i = 0; i < x.files.length; i++) {
      if (x.files[i].displayName === modelName[0].modelName.Display) {
        x.currentIndex = i;
        break;
      }
    }
    this.dialogService.openResidualAnalysis(x);
  }

  public openShapValues(model: StatTransformationModel) {
    this.dialogService.openShapValuesDialog({
      ShapValues: model.ShapValues,
      ForecastVersionId: this.forecastVersionId,
      Subtitle: `Model: ${model.modelName.Display}`
    });
  }

  public openFullSizeWindow(mathJaxData: string) {
    this.dialogService.openTextDialog({
      MathJax: true,
      Text: mathJaxData,
      Title: this.selectedModel.modelName.Display
    }, {
      width: '95vw',
      height: '95vh',
    });
  }

  public showSeeMore(text: string) {
    return text.length >= 110;

  }

  public noMessages() {
    return !(this.selectedModel?.InfoMessages?.length ||
      this.selectedModel?.WarningMessages?.length ||
      this.selectedModel?.ErrorMessages?.length);
  }

  public selectModel(change: ModelOpts) {
    this.selectedOption = change;
    if (this.allFailed) { return; }
    this.loadingTextFiles = true;
  }

  public selectDataset(change: MatSelectChange) {
    this.selectedDataset = change.value;
    this.loadingTextFiles = true;
  }

  public changeRollingWindow(change: MatSelectChange) {
    this.selectedRollingModelProperties = change.value;
    this.loadingTextFiles = true;
  }

  public clickSeeMore() {
    this.seeMore = !this.seeMore;
  }

  public handleError(err) {
    this.status.setMessage(err, 'Error');
  }

  private setResidualAnalysisFiles() {
    const builtIds = this.models.filter(x => x.ModelBuilt).map(s => s.MainModelId);
    let promise: Promise<ResultFileDTO[]>;
    if (this.type === 'multivariate') {
      promise = this.multivariateService.fetchModelResidualFiles(this.forecastVersionId, builtIds);
    } else {
      promise = this.univariateService.fetchModelResidualFiles(this.forecastVersionId, builtIds);
    }
    promise.then(e => {
      this.residualFiles = e;
    })
      .catch(err => this.status.setError(err));
  }

  public getModelProperties() {
    const built = this.models.filter(x => x.ModelBuilt);
    let promise;
    if (this.type === 'multivariate') {
      promise = this.multivariateService.getModelTextFiles(this.forecastVersionId, built);
    } else {
      promise = this.univariateService.getModelTextFiles(this.forecastVersionId, built);
    }
    promise.then(e => {
      this.loading = false;
      this.modelTextFiles = e;
      this.onUpdate();
    }).catch(() => {
    });
  }

  public onUpdate() {
    this.selectedModel = this.models.find(e => this.selectedOption.Display === e.modelName.Short);
    if (this.selectedModel == null) {
      this.selectedModel = this.models[0];
    }
    this.mapTextFiles();
    setTimeout(() => {
      this.loadingTextFiles = false;
    }, 0);
  }

  public reRender() {
    setTimeout(() => {
      this.loadingTextFiles = false;
    }, 0);
  }

  public mapTextFiles() {
    this.datasetOptions = this.env.GetModelPropertyTypes();
    this.fullModelProperties = this.getModelPropertyFiles('full');
    this.trainModelProperties = this.getModelPropertyFiles('train');
    this.rollingModelProperties = this.getRollingModelProperties();
    if (this.rollingModelProperties.length) {
      this.selectedRollingModelProperties = this.rollingModelProperties[0];
    }
    if (this.rollingModelProperties.length === 0) {
      this.datasetOptions = this.datasetOptions.filter(e => e.Value !== 'rw');
    }
    if (!this.fullModelProperties) {
      this.datasetOptions = this.datasetOptions.filter(e => e.Value !== 'full');
    }
    if (!this.trainModelProperties) {
      this.datasetOptions = this.datasetOptions.filter(e => e.Value !== 'train');
    }
  }

  public getModelPropertyFiles(type: ModelPropertiesType) {
    const nameToLookFor = this.isNaive ? 'org' : this.selectedOption.Value;
    const propFile = this.modelTextFiles.find(x => x.Type === type && x.Name.includes(nameToLookFor));
    if (propFile) {
      if (propFile.decoded) { return propFile.decoded; }
      propFile.decoded = StringUtils.b64DecodeUnicode(propFile.Base64String);
      return propFile.decoded;
    }
    return null;
  }

  public getRollingModelProperties() {
    const nameToLookFor = this.isNaive ? 'org' : this.selectedOption.Value;
    const rwFiles = this.modelTextFiles.filter(x => x.Type === 'rw');
    const rolling = rwFiles.filter(x => x.Name.includes(nameToLookFor));
    return rolling.map(e => {
      if (e.decoded) {
        return e;
      }
      e.decoded = StringUtils.b64DecodeUnicode(e.Base64String);
      e.Date = DateUtils.newMoment(e.Date).format('YYYY-MM-DD');
      return e;
    });
  }

  public recalculate(): void {
    let promise;
    if (StatModelUtils.isUniModel(this.modelName))
      promise = this.univariateService.triggerUnivariateModels(this.forecastVersionId, [this.modelName.Model as UnivariateModelName]);
    else
      promise = this.multivariateService.triggerMultivariateModels(this.forecastVersionId, [{ ...this.modelName, Value: this.modelName.Model }]);
    promise
      .then(() => true)
      .catch(() => false);
    this.dialogRef.close(null);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(null);
  }

}
