<div class="tab-plot-container">
  <div *ngIf="plots[plotIndex]; let plot"
       class="text-center">
    <img [width]="plotWidth"
         [src]="plotSrc"
         (click)="link ? viewPlot() : null"
         [ngClass]="{ pointer: link}">
  </div>
  <ng-container *ngIf="plots.length > 1">
    <ng-container *ngTemplateOutlet="arrowsTemplate"></ng-container>
    <!-- Gallery buttons below the graph -->
    <div class="text-center">
      <fa-icon *ngFor="let _ of plots; let i = index"
               matRipple
               [matRippleCentered]="true"
               [matRippleColor]="'#6182ff4d'"
               [matRippleUnbounded]="true"
               [matRippleRadius]="17"
               [icon]="faCircle"
               class="icon pointer ripple"
               [style.color]="plotIndex === i ? '#6182ff' : '#b7b7b7'"
               (click)="changePlotIndex(i)">
      </fa-icon>
    </div>
  </ng-container>
</div>

<ng-template #arrowsTemplate>
  <!-- Left arrow -->
  <fa-icon [icon]="arrowLeft"
           matRipple
           [matRippleCentered]="true"
           [matRippleColor]="'#6182ff4d'"
           [matRippleUnbounded]="true"
           [matRippleRadius]="22"
           [style.color]="'#6182ff'"
           class="arrow left pointer"
           (click)="left()">
  </fa-icon>
  <!-- Right arrow-->
  <fa-icon [icon]="arrowRight"
           matRipple
           [matRippleCentered]="true"
           [matRippleColor]="'#6182ff4d'"
           [matRippleUnbounded]="true"
           [matRippleRadius]="22"
           [style.color]="'#6182ff'"
           class="arrow right pointer"
           (click)="right()">
  </fa-icon>
</ng-template>
