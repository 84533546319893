import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { HttpService } from '@core/services/http/http.service';
import { ClearStateAction } from '@core/store/auth/auth.actions';
import { CacheUtils } from '@shared/utils/cache.utils';
import { StorageUtils } from '@shared/utils/storage.utils';
import { IForecastTreeNodeBase } from './components/forecasts-drawer/components/nav-tree/entities/forecast-tree.entities';
import { ProjectNavDTO } from './entities/navigation.dtos';
import { NavigationForecastListViewType } from './entities/navigation.entities';
import { NavigationMapper } from './navigation.component.mapper';

const PINNED_FORECASTS_KEY = 'pinnedForecasts';

@Injectable({
  providedIn: 'root'
})
export class NavigationComponentService {

  public pinnedForecastIds: string[] = [];

  private contentCache = new CacheUtils.LruCache<IForecastTreeNodeBase[]>(5);
  public expandedNodes = new Array<IForecastTreeNodeBase>();

  public get forecastTree() { return this.contentCache.get('flat-list'); }

  constructor(
    private http: HttpService,
    private actions: ActionService,
    private env: EnvironmentService,
    private mapper: NavigationMapper,
  ) {
    this.setupSubscriptions();
    this.loadPinnedForecasts();
  }

  /**
   * Get or fetch the navigation content for a company.
   * Response is cached if user swaps between different companies (max 5 cached)
   */
  public getNavContent(companyId: string, listType: NavigationForecastListViewType, forceReload = false) {
    const key = listType;
    const cache = this.contentCache;
    const cacheExists = cache.has(key);
    if (cacheExists && !forceReload) {
      return Promise.resolve(cache.get(key));
    }

    if (forceReload) {
      if (listType !== 'hierarchy-list') {
        cache.has('project-list') && cache.delete('project-list');
        cache.has('flat-list') && cache.delete('flat-list');
        cache.has('tag-list') && cache.delete('tag-list');
      }
    }

    return this.http.get<ProjectNavDTO[]>(`navigation/${companyId}/get-navigation-content`)
      .then(({ body }) => {
        const dtos = body.map(dto => {
          dto.Forecasts.forEach(f => f.periodicity = this.env.getPeriodicity(f.Periodicity));
          return dto;
        });
        let mapped;

        this.contentCache.put('project-list', this.mapper.map(dtos, 'project-list'));
        this.contentCache.put('flat-list', this.mapper.map(dtos, 'flat-list'));

        if (listType === 'tag-list') {
          mapped = this.mapper.map(dtos, listType);
          this.contentCache.put('tag-list', mapped);
        } else if (listType === 'project-list') {
          mapped = this.contentCache.get('project-list');
        } else {
          mapped = this.contentCache.get('flat-list');
        }

        return mapped;
      });
  }

  // PIN
  public togglePinForecast(forecastId: string) {
    if (this.pinnedForecastIds.includes(forecastId)) {
      this.pinnedForecastIds = this.pinnedForecastIds.filter(x => x !== forecastId);
    } else {
      this.pinnedForecastIds.push(forecastId);
    }
    this.savePinnedForecasts();
  }

  public unpinAllForecasts() {
    this.pinnedForecastIds = [];
    this.savePinnedForecasts();
  }

  private savePinnedForecasts() {
    StorageUtils
      .set(PINNED_FORECASTS_KEY, JSON.stringify(this.pinnedForecastIds));
  }

  private loadPinnedForecasts() {
    const saved = StorageUtils.get(PINNED_FORECASTS_KEY);
    if (!saved) { return; }
    this.pinnedForecastIds = JSON.parse(saved);
  }

  private setupSubscriptions() {
    // TODO: Add more cache-clear and/or reload content for company
    this.actions.dispatched(ClearStateAction).subscribe(x => {
      this.contentCache.clear();
    });
  }
}
