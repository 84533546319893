import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { StatModelDTO, StatModelFilesDTO } from '@core/store/stat-model/dtos/stat-model.dto';
import { ModelName } from '@modules/lang/types/model-name';
import { Store } from '@ngxs/store';
import { ModelPropertiesDTO } from '@shared/modules/dialogs/model-properties/model-properties.dialog';
import { DateUtils } from '@shared/utils/date.utils';
import { ForecastVersionHistoricDataModel, StatModelHistoricDataDTO } from '../dtos/stat-model-historic-data.dto';
import { MultivariateActions } from '../stat-model.actions';
import { StatModelMapper } from '../stat-model.mapper';
import { StatModel } from '../stat-model.model';
import { StatModelState } from '../stat-model.state';

@Injectable({
  providedIn: 'root'
})
export class MultivariateBackendService {

  private _calls: { fId: string, p: Promise<StatModel[]>; }[] = [];

  constructor(
    private http: HttpService,
    private store: Store,
    private mapper: StatModelMapper,
    private statModelMapper: StatModelMapper
  ) { }

  public triggerMultivariate(forecastVersionId: string) {
    return this.http.post(`forecast/version/${forecastVersionId}/multivariate-trigger`, null);
  }

  public triggerMultivariateModels(forecastVersionId: string, models: ModelName[]) {
    const toTrigger = models.map(x => x.Value);
    return this.http.post(`forecast/version/${forecastVersionId}/multivariate-trigger`, toTrigger);
  }

  public removeMultivariateModels(forecastVersionId, models: ModelName[]) {
    const toRemove = models.map(x => x.Value);
    return this.http.post(`forecast/version/${forecastVersionId}/multivariate/remove-models`, toRemove);
  }

  public fetchModelResidualFiles(fVersionId: string, modelIds: number[]) {
    return this.http.post<StatModelFilesDTO[]>(`forecast/version/${fVersionId}/multivariate/images/`, modelIds)
      .then(result => {
        const models = result.body.map(i => this.mapper.mapPlotImages(i));
        return Promise.all(models);
      });
  }

  public fetchModelTextFiles(fVersionId: string, modelIds: number[]) {
    return this.http.post<ModelPropertiesDTO[]>(`forecast/version/${fVersionId}/multivariate/text-files/`, modelIds)
      .then(result => {
        return result;
      });
  }

  public getAllMultivariateModels(forecastVersionId: string) {
    const call = this._calls.find(x => x.fId === forecastVersionId);
    if (call) { return call.p; }
    const newCall = {
      fId: forecastVersionId,
      p: this.http.get<StatModelDTO[]>(`forecast/version/${forecastVersionId}/multivariate`)
        .then(result => this.handleResult(forecastVersionId, result.body))
        .finally(() => this._calls.removeByKey('fId', forecastVersionId))
    };
    this._calls.push(newCall);
    return newCall.p;
  }

  public getMultivariateModel(forecastVersionId: string, mName: ModelName) {
    return this.http.get<StatModelDTO>(`forecast/version/${forecastVersionId}/multivariate/models/${mName.Model}`)
      .then(result => {
        const models = this.handleResult(forecastVersionId, [result.body]);
        return models;
      });
  }

  public getOrFetchHistoricDataById(forecastVersionId: string) {
    const hData = this.store.selectSnapshot(StatModelState.historicData);
    const versionData = hData?.find(x => x.ForecastVersionId === forecastVersionId);
    if (!versionData) {
      return this.fetchHistoricData(forecastVersionId);
    } else {
      return Promise.resolve(versionData);
    }
  }

  private fetchHistoricData(forecastVersionId: string) {
    return this.http.get<StatModelHistoricDataDTO[]>(`forecast/version/${forecastVersionId}/multivariate/get-historic`)
      .then(resp => {
        const model = new ForecastVersionHistoricDataModel();
        model.ForecastVersionId = forecastVersionId;
        model.Data = resp.body.map(x => {
          x.Values.forEach(value => DateUtils.MapIHasDate(value));
          return x;
        });
        this.store.dispatch(new MultivariateActions.GetHistoricDataSuccess(forecastVersionId, model));
        return model;
      });
  }

  private handleResult(forecastVersionId: string, res: StatModelDTO[]) {
    return this.getOrFetchHistoricDataById(forecastVersionId)
      .then(historic => {
        const filtered = res.filter(x => !(x.IsProcessing || x.IsPending));
        return filtered.map(m => this.statModelMapper.mapModel(m, historic.Data, true));
      });
  }
}


