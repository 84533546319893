import { Component } from '@angular/core';
import { CSVConstants } from '@core/constants/csv.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { DisplayValue } from '@modules/lang/types/display-value';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { CSVUtils } from '@shared/utils/csv.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import { ForecastDataDownloadModalOpts } from './forecast-data-download-modal.options';

type ValueOption = 'Used In Forecast' | 'Original from source' | 'A' | 'VO' | 'VS';

@Component({
  selector: 'indicio-forecast-data-download-modal',
  templateUrl: './forecast-data-download-modal.component.html',
  styleUrls: ['./forecast-data-download.less']
})
export class ForecastDataDownloadModalComponent extends ModalModelComponent {

  forecast: ForecastModel;
  forecastVersion: ForecastVersionModel;
  variables: ForecastVariableModel[];

  // Modal specific
  selectedVariables: string[];
  converter: CSVUtils.ValueConverter;
  separatorOptions = CSVConstants.SeparatorDefinitions;
  separator: CSVConstants.Separator = ';';
  valueOptions: DisplayValue<ValueOption>[] = [{
    Display: 'Used In Forecast',
    Value: 'Used In Forecast'
  }, {
    Display: 'Original from source',
    Value: 'Original from source'
  }, {
    Display: 'Aggregated',
    Value: 'A'
  }, {
    Display: 'Outlier',
    Value: 'VO'
  }, {
    Display: 'Seasonal',
    Value: 'VS'
  }];
  valueOption: ValueOption = 'Used In Forecast';
  useZip = false;
  decimalSettings: string[];
  decimalCharacter: string = ',';
  decimalLabel: string;
  decimalPoints: number = 3;
  decimalDisabled = false;
  decimalPrecisions: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  useSignificant = false;
  empty: boolean;

  constructor(
    protected store: Store,
    public env: EnvironmentService,
    protected forecastService: ForecastFrontendService,
    protected forecastVariableService: ForecastVariableFrontendService,
    protected sourceVariableService: SourceVariableFrontendService,
    protected appearance: AppearanceService
  ) { super(); }

  public setOptions(options: ForecastDataDownloadModalOpts) {
    this.decimalSettings = Object.keys(CSVConstants.DecimalSettings);
    this.selectDecimal(this.appearance.DecimalCharacter);

    this.forecastService.getOrFetchForecast(options.forecastId)
      .then((f) => {
        this.forecast = f;
        this.forecastVersion = f.activeVersion;
        return f;
      })
      .then(f => {
        if (!f.activeVersion.ForecastVariableId && !f.activeVersion.IndicatorVariableIds.length) {
          throw new Error('Nothing to download');
        } else {
          return Promise.resolve();
        }
      })
      .then(() => this.forecastVariableService.getOrFetchAllInForecastVersion(this.forecastVersion))
      .then(variables => {
        this.variables = variables;
        this.selectedVariables = variables.filter(x => !x.IsTrend).map(x => x.ForecastVariableId);
      })
      .catch(err => {
        if (err?.message === 'Nothing to download') {
          this.empty = true;
        }
        console.dir(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public selectSeparator(separator: CSVConstants.Separator) {
    if (separator === ',') {
      this.selectDecimal('Period');
      this.decimalDisabled = true;
    } else {
      this.decimalDisabled = false;
    }
    this.separator = separator;
  }

  public selectDecimal(decimal: string) {
    this.decimalLabel = decimal;
    this.decimalCharacter = CSVConstants.DecimalSettings[decimal];
  }

  public async download() {
    this.pending = true;
    const self = this;
    const csvs: CSVConstants.CSVFile[] = [];
    const converter = new CSVUtils.ValueConverter(200, ';', true, this.useZip);
    if (this.valueOption === 'Original from source') {
      this.downloadSourceVariableValues(valueConverter);
    } else {
      const valueType: CSVUtils.VariableValueType = this.valueOption === 'Used In Forecast' ? 'V' : this.valueOption;
      this.selectedVariables.forEach(id => {
        const variable = this.variables.find(x => x.ForecastVariableId === id);
        const csvFile = new CSVConstants.CSVFile();
        csvFile.Name = variable.Name;
        csvFile.Values = CSVUtils.ConvertForecastVariableValues(variable, valueType, undefined, valueConverter);
        csvFile.Content = converter.WriteVertical(csvFile.Values, csvFile.Name);
        csvs.push(csvFile);
      });
      converter.Download(csvs, this.forecast.Name);
      this.pending = false;
    }

    function valueConverter(value: number) {
      if (value === undefined || value === null) { return undefined; }
      value = self.useSignificant ? ValueUtils.getValueWithSignificantDigits(value, self.decimalPoints) : self.valuePipe.roundNumberToDecimalPrecision(value, self.decimalPoints);
      let val = value.toString();
      const currentDecimalMatch = new RegExp('([\.|\,])').exec(val);
      if (!currentDecimalMatch) { return value; }
      const currentDecimal = currentDecimalMatch[0];
      val = val.replace(currentDecimal, self.decimalCharacter);
      return val;
    }
  }

  async downloadSourceVariableValues(valueConverter) {
    const csvs: CSVConstants.CSVFile[] = [];
    const converter = new CSVUtils.ValueConverter(200, ';', true, this.useZip);
    const sources: Promise<SourceVariableModel>[] = [];
    this.variables.forEach(v => sources.push(this.sourceVariableService.getOrFetchSourceVariable(v.SourceVariableId)));
    Promise.all(sources).then(variables => {
      variables.forEach(variable => {
        const csvFile = new CSVConstants.CSVFile();
        csvFile.Name = variable.Name;
        csvFile.Values = CSVUtils.ConvertSourceVariableValues(variable.getBaseVersion(), undefined, valueConverter);
        csvFile.Content = converter.WriteVertical(csvFile.Values, csvFile.Name);
        csvs.push(csvFile);
      });
      converter.Download(csvs, this.forecast.Name);
      this.pending = false;
    });
  }
}
