import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PusherActions } from '@core/actions/pusher.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { PusherService } from '@core/services/frontend/pusher.service';
import { HttpService } from '@core/services/http/http.service';
import { Base64FileDTO } from '@core/store/file/dtos/file-dto';
import { WikiActions } from '@core/store/wiki/wiki.actions';
import { WikiConstants } from '@core/store/wiki/wiki.constants';
import { CreateWikiPageDTO, UpdateWikiPageDTO, WIKIPDFSettingsDTO, WikiAssetDTO, WikiExportDTO, WikiPDFVersionDTO, WikiPDFVersionMetaDTO, WikiPageAdminMetaDTO, WikiPageDTO } from '@core/store/wiki/wiki.entities';
import { WikiMapper } from '@core/store/wiki/wiki.mapper';
import { WikiService } from '@core/store/wiki/wiki.service';
import { AdminBackendBaseService } from '@modules/admin/services/admin-http-base.service';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { BlobUtils } from '@shared/utils/blob.utils';
import { CacheUtils } from '@shared/utils/cache.utils';
import { FileUtils } from '@shared/utils/file.utils';
import { StorageUtils } from '@shared/utils/storage.utils';


@Injectable({
  providedIn: 'root',
})
export class AdminWikiService extends AdminBackendBaseService {

  public openWikiPages: { slug: string, user: string; }[] = [];

  private wikiPageCache = new CacheUtils.LruCache<WikiPageAdminMetaDTO[]>(1000);
  public get wikiPages() { return this.wikiPageCache.get('all') || []; }
  private notifyTimeout: any;

  constructor(
    http: HttpService,
    store: Store,
    private wikiService: WikiService,
    private wikiMapper: WikiMapper,
    private actions: ActionService,
    private pusher: PusherService,
    private dialogService: DialogService,
    private httpCli: HttpClient,
    private env: EnvironmentService,
  ) {
    super(http, store);
    this.setupSubscriptions();
  }

  public isOpen(slug: string) {
    return this.openWikiPages.some(x => x.slug === slug);
  }

  public currentEditors(slug: string) {
    return this.openWikiPages.filter(x => x.slug === slug).map(x => x.user);
  }

  public notifyOpenClose(slug: string, open: boolean) {
    this.pusher.sendOpenAdminEvent({ Obj: slug, Flag: open, Type: 'wiki-page' });
  }

  public openEdit(slug: string) {
    this.notifyEdit(slug);
    return this.dialogService.openAdminCreateWikiPage({ WikiSlug: slug })
      .pipe(u => {
        u.subscribe(_ => {
          this.notifyOpenClose(slug, false);
          clearTimeout(this.notifyTimeout);
        });
        return u;
      }).toPromise();
  }

  private setupSubscriptions() {
    this.actions.dispatched(PusherActions.OpenAdminEvent).subscribe((a: PusherActions.OpenAdminEvent) => this.handleOpenWikiEvent(a));
    this.actions.dispatched(WikiActions.OpenAdminEdit).subscribe((a: WikiActions.OpenAdminEdit) => this.openEdit(a.slug));
  }

  public toggleEnabled(state: boolean) {
    return this.http.post<boolean>(`wiki/admin/toggle-enabled/${state}`, null, 'wiki').then(({ body }) => {
      if (body) {
        this.wikiService.getAvailablePages();
      } else {
        this.wikiService.availablePages = [];
        this.wikiService.isEnabled = false;
      }
      return body;
    });
  }

  public getPage(slug: string) {
    return this.http.get<WikiPageDTO>(`wiki/admin/page/${slug}`, 'wiki').then(({ body }) => this.wikiMapper.mapPage(body));
  }

  public getAllWikiPages() {
    return this.http.get<WikiPageAdminMetaDTO[]>('wiki/admin/pages', 'wiki').then(({ body }) => {
      const metas = body.map((x) => this.wikiMapper.mapPageMeta(x));
      this.wikiPageCache.put('all', metas);
      return metas;
    });
  }

  public getAllAssets() {
    return this.http.get<WikiAssetDTO[]>('wiki/admin/assets', 'wiki').then(({ body }) => body.map(file => Object.assign(new WikiAssetDTO, file)));
  }

  public createAsset(dto: Base64FileDTO) {
    return this.http.post<WikiAssetDTO>('wiki/admin/assets', dto, 'wiki').then(({ body }) => body);
  }

  public removeAsset(assetId: string) {
    return this.http.delete<null>(`wiki/admin/assets/${assetId}`, 'wiki').then(({ body }) => body);
  }

  public createPage(dto: CreateWikiPageDTO) {
    return this.http.post<WikiPageDTO>('wiki/admin/page', dto, 'wiki').then(({ body }) => body);
  }

  public updatePage(dto: UpdateWikiPageDTO) {
    return this.http.put<WikiPageDTO>('wiki/admin/page', dto, 'wiki').then(({ body }) => body);
  }

  public removePage(pageId: string) {
    return this.http.delete<null>(`wiki/admin/page/${pageId}`, 'wiki').then(({ body }) => body);
  }

  public downloadDb() {
    return this.http.get<WikiExportDTO>('wiki/admin/export', 'wiki').then(({ body }) => body);
  }

  public importDb(dto: WikiExportDTO) {
    return this.http.post<null>('wiki/admin/import', dto, 'wiki').then(({ body }) => body);
  }

  public removeCachedPage(slug: string) {
    StorageUtils.remove(`${WikiConstants.STORAGE_KEY}.${slug}`);
    const page = this.wikiPages.find(x => x.Slug === slug);
    page && (page.cachedPageExists = false);
    return null;
  }

  /**
   * PDF Endpoints
   *
   * Generate a PDF from markdown
 */
  public async generatePDF(markdownZip: any) {
    const base64 = await BlobUtils.blobToBase64(markdownZip);
    return this.httpCli.request('post', `${this.env.env.UriFrontendUtilsApi}pdf/wiki`, { body: { zip: base64 }, observe: 'response', responseType: 'blob' }).toPromise()
      .then(resp => {
        const filename = resp.headers.get('content-disposition').split('=')[1];
        FileUtils.downloadBlob(resp.body, filename);
      });
  }

  public setPDFSettings(newSettings: WIKIPDFSettingsDTO[]) {
    return this.http.post<null>('wiki/admin/pdf/settings', newSettings, 'wiki')
      .then(_ => newSettings.forEach(y => {
        const page = this.wikiPages.find(z => z.Slug === y.Slug);
        if (!page) { return; }
        page.IncludePDF = y.IncludePDF;
        page.Order = y.Order;
      }))
      ;
  }

  public downloadPDF(pdfid: string) {
    return this.http.get<WikiPDFVersionDTO>(`wiki/admin/pdf/${pdfid}`, 'wiki').then(({ body }) => {
      const blob = BlobUtils.base64toBlob(body.Base64String, body.MimeType);
      FileUtils.downloadBlob(blob, body.Name);
    });
  }

  public getAllPDFs() {
    return this.http.get<WikiPDFVersionMetaDTO[]>('wiki/admin/pdf', 'wiki').then(({ body }) => body.map(file => Object.assign(new WikiPDFVersionMetaDTO, file)));
  }

  public createPDF(dto: WikiPDFVersionDTO) {
    return this.http.post<WikiPDFVersionMetaDTO>('wiki/admin/pdf', dto, 'wiki').then(({ body }) => body);
  }

  public removePDF(pdfId: string) {
    return this.http.delete<null>(`wiki/admin/pdf/${pdfId}`, 'wiki').then(({ body }) => body);
  }



  /* Private helpers */

  private handleOpenWikiEvent(a: PusherActions.OpenAdminEvent) {
    if (a.evt !== 'wiki-page') { return; }
    const isOpen = this.openWikiPages.some(x => x.slug === a.content.Obj && x.user === a.content.ActorId);
    const toSet = a.content.Flag;
    if (toSet && isOpen || !toSet && !isOpen) { return; }
    return toSet
      ? this.openWikiPages.push({ slug: a.content.Obj, user: a.content.ActorId })
      : this.openWikiPages.removeWhere(x => x.slug === a.content.Obj && x.user === a.content.ActorId);
  }

  private notifyEdit(slug: string) {
    this.notifyOpenClose(slug, true);
    this.notifyTimeout = setTimeout(() => {
      this.notifyEdit(slug);
    }, 10000);
  }

}
