import { HttpService } from '@core/services/http/http.service';
import { Store } from '@ngxs/store';


export abstract class AdminBackendBaseService {

  constructor(
    protected http: HttpService,
    protected store: Store
  ) { }

}
