<indicio-modal-base [child]="this"
                    modalTitle="Create news post"
                    [loading]="isLoading">

  <div class="create-news-post-container clearfix">
    <form class="clearfix"
          (ngSubmit)="createPost()"
          ngNativeValidate>
      <div class="row">
        <div class="col-xs-5 input-label m6">Title</div>
        <div class="col-xs-7">
          <mat-form-field>
            <input type="text"
                   matInput
                   class="material"
                   required
                   placeholder="Enter title"
                   [(ngModel)]="title"
                   [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>

      <div class="row margin-top">
        <div class="col-xs-5 input-label m6">Preview</div>
        <div class="col-xs-7">
          <mat-form-field>
            <textarea required
                      matInput
                      placeholder="Enter preview text"
                      [(ngModel)]="preview"
                      [ngModelOptions]="{standalone: true}"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="row margin-top">
        <div class="col-xs-5 input-label m6">Body</div>
        <div class="col-xs-7">
          <mat-form-field>
            <textarea required
                      matInput
                      placeholder="Enter body"
                      [(ngModel)]="body"
                      [ngModelOptions]="{standalone: true}"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-5 input-label">Hidden?</div>
        <div class="col-xs-7 input-label cursor-pointer"
             (click)="hidden = !hidden">
          <i [ngClass]="hidden ? 'ion-android-checkbox-outline' : 'ion-android-checkbox-outline-blank'"></i>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-5 input-label">Hide author?</div>
        <div class="col-xs-7 input-label cursor-pointer"
             (click)="hideAuthor = !hideAuthor">
          <i [ngClass]="hideAuthor ? 'ion-android-checkbox-outline' : 'ion-android-checkbox-outline-blank'"></i>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-5 input-label">Disable comments?</div>
        <div class="col-xs-7 input-label cursor-pointer"
             (click)="disableComments = !disableComments">
          <i [ngClass]="disableComments ? 'ion-android-checkbox-outline' : 'ion-android-checkbox-outline-blank'"></i>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-5 input-label">Publish date</div>
        <div class="col-xs-7 input-label cursor-pointer">
          <input type="date"
                 [(ngModel)]="publishDate"
                 [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div class="row">
        <div class="col-xs-5 input-label">Priority</div>
        <div class="col-xs-7 input-label cursor-pointer">
          <indicio-searchable-dropdown label="Priority"
                                       [required]="true"
                                       optionDisplay="Title"
                                       [values]="priorityOptions"
                                       (valueChangeEvent)="priority = $event.Value"
                                       [initializeWithFirstValue]="true">
          </indicio-searchable-dropdown>
        </div>
      </div>

      <div class="row margin-top"
           *ngIf="unsaved">
        <div class="col-xs-12 red">
          * This is a loaded and unsaved version.
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <button class="btn outline blue left"
                  type="button"
                  (click)="close()">Cancel</button>
        </div>
        <div class="col-xs-6">
          <button class="btn right"
                  type="submit">
            <span *ngIf="!pending">Create</span>
            <span *ngIf="pending"
                  class="ion-load-c spinner"></span>
          </button>
        </div>
      </div>
    </form>

  </div>
</indicio-modal-base>
