import { DisplayValue } from '../types/display-value';

export type VisibilityLevelType = 'company' | 'private';
export class VisibilityLevel extends DisplayValue<VisibilityLevelType> { };
export const VisibilityLevels = [
  new VisibilityLevel('private', 'Private', 'Only project members can see and modify the project'),
  new VisibilityLevel('company', 'Company', 'Project is visible to all employees in the company')
];

export class SourceVariableVisibilityLevel extends DisplayValue<VisibilityLevelType> { };
export const SourceVariableVisibilityLevels = [
  new SourceVariableVisibilityLevel('private', 'Private', 'Only you can see this source variable'),
  new SourceVariableVisibilityLevel('company', 'Company', 'Company users can see this source variable')
];
