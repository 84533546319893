<div class="scenario-general-tab"
     *ngIf="!isLoading">
  <indicio-modal-option-row optionTitle="Name"
                            optionSubtitle="Enter name of the scenario"
                            textWidth="7"
                            optionsWidth="5">
    <mat-form-field style="margin-top: 15px;">
      <input type="text"
             matInput
             required
             type="text"
             cdkFocusInitial
             name="companyname"
             (keyup)="inputEvent.emit(true)"
             [(ngModel)]="scenario.Name"
             class="material">
    </mat-form-field>
  </indicio-modal-option-row>
</div>
