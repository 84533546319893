import { UpdatedDataInfoModalOpts } from './updated-data-info-modal.options';

export class OpenUpdatedDataInfoModal {
  public static readonly type = '[MODAL] Open UpdatedDataInfoModal modal';
  data: UpdatedDataInfoModalOpts;
  constructor(
    public fVarId: string,
    public sourceVarId: string,
    public sourceMetaId: string,
    public fVersionId: string
  ) { this.data = { fVarId, sourceVarId, sourceMetaId, fVersionId }; }
}
