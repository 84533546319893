import { HierarchicalTreeComponent } from '@core/store/hierarchical-forecast/components/tree/hierarchical-tree.component';
import { WikiIconComponent } from '@core/store/wiki/components/icon/wiki-icon.component';
import { WikiLinkComponent } from '@core/store/wiki/components/link/wiki-link.component';
import { WikiMathJaxComponent } from '@core/store/wiki/components/mathjax/wiki-mathjax.component';
import { WikiModalRowComponent } from '@core/store/wiki/components/modal-row/wiki-modal-row.component';
import { WikiPageComponent } from '@core/store/wiki/components/page/wiki-page.component';
import { WikiSnackbarComponent } from '@core/store/wiki/components/snackbar/wiki-snackbar.component';
import { DateRangeComponent } from '@dialogs/forecast/settings/tabs/date-settings/range-component/date-range.component';
import { NewsSnippetComponent } from '@modules/home/views/dashboard/components/news-snippet/news-snippet.component';
import { IndicioIconLogoComponent } from '@modules/root/components/icon-logo/icon-logo.component';
import { IndicioTextLogoComponent } from '@modules/root/components/text-logo/text-logo.component';
import { CheckCircleComponent } from '@shared/components/checkcircle/check-circle.component';
import { CheckmarkComponent } from '@shared/components/checkmark/checkmark.component';
import { MonthPlotComponent } from '@shared/components/graphs/monthplot/monthplot.component';
import { MembersListComponent } from '@shared/components/member-list/members-list.component';
import { OptionsMenuComponent } from '@shared/components/options-menu/options-menu.component';
import { ScriptQueueComponent } from '@shared/components/script-queue/script-queue.component';
import { SliderComponent } from '@shared/components/slider/slider.component';
import { StatusComponent } from '@shared/components/status/status.component';
import { Authentication2FASettingsComponent } from './2-factor/2-factor-settings.component';
import { AccordionComponent } from './accordion/accordion.component';
import { ErrorBarChartComponent } from './accuracy/error-bar-chart/error-bar-chart.component';
import { ActiveFiltersComponent } from './active-filters/active-filters.component';
import { IndicioCopyComponent } from './copy/indicio-copy.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DatePickerDayComponent } from './date-picker/periodicities/date-picker-day.component';
import { DatePickerMonthComponent } from './date-picker/periodicities/date-picker-month.component';
import { DatePickerQuarterComponent } from './date-picker/periodicities/date-picker-quarter.component';
import { DatePickerYearComponent } from './date-picker/periodicities/date-picker-year.component';
import { DebugBoxComponent } from './debug-box/debug-box.component';
import { DragBarComponent } from './dragbar/dragbar.component';
import { ButtonDropdownComponent } from './dropdown/button-dropdown/button-dropdown.component';
import { VariableTransformDropdownComponent } from './dropdown/fvar-transform/fvar-transform-dropdown.component';
import { SearchableHierarchyDropdownComponent } from './dropdown/searchable-hierarchy/searchable-hierarchy.component';
import { SearchableMultiDropdownComponent } from './dropdown/searchable-multiselect/searchable-multiselect-dropdown.component';
import { SearchableDropdownComponent } from './dropdown/searchable/searchable-dropdown.component';
import { TagDropdownComponent } from './dropdown/tag-dropdown/tag-dropdown';
import { FoldableRowComponent } from './foldable-row/foldable-row.component';
import { ForecastTemplateComponent } from './forecast-template-card/forecast-template-card.component';
import { BarChartComponent } from './graphs/bar-chart/bar-chart.component';
import { ShapGraphComponent } from './graphs/shap-graph/shap-graph.component';
import { SimpleLineGraphComponent } from './graphs/slg/simple-line-graph.component';
import { InfinityScrollComponent } from './infinity-scroll/infinity-scroll.component';
import { InfluenceStarsComponent } from './influence-stars/influece-stars.component';
import { IndicioInputComponents } from './input';
import { LightDarkButtonComponent } from './light-dark-button/light-dark-button.component';
import { AdvancedLineGraphComponent } from './line-graph/alg.component';
import { MacrobondComponents } from './macrobond';
import { ModalOptionRowComponent } from './modal-option-row/modal-option-row.component';
import { NavTreeComponent } from './nav-tree/nav-tree.component';
import { PlotDisplayComponent } from './plot-display-component/plot-display-component';
import { PortalComponent } from './portal/portal.component';
import { SheetColumnImportComponent } from './sheet-import-columns/sheet-column-import.component';
import { IndicioSpinnerComponent } from './spinner/spinner.component';
import { StepAheadComponent } from './step-ahead/step-ahead.component';
import { SteppedProgressComponent } from './stepped-progress/stepped-progress.component';
import { SystemMessageComponent } from './system-message/system-message.component';
import { TagListComponent } from './tag-list/tag-list.component';

export { HierarchicalTreeComponent } from '@core/store/hierarchical-forecast/components/tree/hierarchical-tree.component';
export { WikiIconComponent } from '@core/store/wiki/components/icon/wiki-icon.component';
export { WikiLinkComponent } from '@core/store/wiki/components/link/wiki-link.component';
export { WikiModalRowComponent } from '@core/store/wiki/components/modal-row/wiki-modal-row.component';
export { WikiPageComponent as WikiArticleComponent } from '@core/store/wiki/components/page/wiki-page.component';
export { NewsSnippetComponent } from '@modules/home/views/dashboard/components/news-snippet/news-snippet.component';
export { IndicioIconLogoComponent } from '@modules/root/components/icon-logo/icon-logo.component';
export { IndicioTextLogoComponent } from '@modules/root/components/text-logo/text-logo.component';
export { Authentication2FASettingsComponent } from '@shared/components/2-factor/2-factor-settings.component';
export { CheckmarkComponent } from '@shared/components/checkmark/checkmark.component';
export { MembersListComponent } from '@shared/components/member-list/members-list.component';
export { OptionsMenuComponent } from '@shared/components/options-menu/options-menu.component';
export { ScriptQueueComponent } from '@shared/components/script-queue/script-queue.component';
export { StatusComponent } from '@shared/components/status/status.component';
export { AccordionComponent } from './accordion/accordion.component';
export { ErrorBarChartComponent } from './accuracy/error-bar-chart/error-bar-chart.component';
export { IndicioCopyComponent } from './copy/indicio-copy.component';
export { DatePickerComponent } from './date-picker/date-picker.component';
export { DatePickerDayComponent } from './date-picker/periodicities/date-picker-day.component';
export { DatePickerMonthComponent } from './date-picker/periodicities/date-picker-month.component';
export { DatePickerQuarterComponent } from './date-picker/periodicities/date-picker-quarter.component';
export { DatePickerYearComponent } from './date-picker/periodicities/date-picker-year.component';
export { DragBarComponent } from './dragbar/dragbar.component';
export { AutocompleteComponent } from './dropdown/autocomplete/autocomplete.component';
export { ButtonDropdownComponent } from './dropdown/button-dropdown/button-dropdown.component';
export { SearchableMultiDropdownComponent } from './dropdown/searchable-multiselect/searchable-multiselect-dropdown.component';
export { TagDropdownComponent } from './dropdown/tag-dropdown/tag-dropdown';
export { InfinityScrollComponent } from './infinity-scroll/infinity-scroll.component';
export { InfluenceStarsComponent } from './influence-stars/influece-stars.component';
export { LightDarkButtonComponent } from './light-dark-button/light-dark-button.component';
export { ModalOptionRowComponent } from './modal-option-row/modal-option-row.component';
export { NavTreeComponent } from './nav-tree/nav-tree.component';
export { PlotDisplayComponent } from './plot-display-component/plot-display-component';
export { PortalComponent } from './portal/portal.component';
export { SheetColumnImportComponent } from './sheet-import-columns/sheet-column-import.component';
export { IndicioSpinnerComponent } from './spinner/spinner.component';
export { StepAheadComponent } from './step-ahead/step-ahead.component';
export { TagListComponent } from './tag-list/tag-list.component';

export const IndicioSharedComponents = [
  ...IndicioInputComponents,
  ...MacrobondComponents,
  ErrorBarChartComponent,
  DebugBoxComponent,
  VariableTransformDropdownComponent,
  PlotDisplayComponent,
  SearchableHierarchyDropdownComponent,
  Authentication2FASettingsComponent,
  ShapGraphComponent,
  InfluenceStarsComponent,
  AccordionComponent,
  AdvancedLineGraphComponent,
  StepAheadComponent,
  BarChartComponent,
  ButtonDropdownComponent,
  CheckmarkComponent,
  DateRangeComponent,
  DatePickerMonthComponent,
  DatePickerQuarterComponent,
  DatePickerYearComponent,
  DatePickerDayComponent,
  TagDropdownComponent,
  IndicioCopyComponent,
  DragBarComponent,
  HierarchicalTreeComponent,
  InfinityScrollComponent,
  NavTreeComponent,
  MembersListComponent,
  ModalOptionRowComponent,
  OptionsMenuComponent,
  PortalComponent,
  ScriptQueueComponent,
  SimpleLineGraphComponent,
  MonthPlotComponent,
  SheetColumnImportComponent,
  StatusComponent,
  TagListComponent,
  DatePickerComponent,
  FoldableRowComponent,
  SystemMessageComponent,
  ActiveFiltersComponent,
  ForecastTemplateComponent,
  DataTableComponent,
  SteppedProgressComponent,
  SearchableDropdownComponent,
  SearchableMultiDropdownComponent,
  LightDarkButtonComponent,
  WikiPageComponent,
  WikiLinkComponent,
  WikiIconComponent,
  WikiModalRowComponent,
  WikiSnackbarComponent,
  WikiMathJaxComponent,
  NewsSnippetComponent,
  IndicioIconLogoComponent,
  IndicioTextLogoComponent,
  IndicioSpinnerComponent,
  SliderComponent,
  CheckCircleComponent
];
