export class FilterBase {
  filters: any;
  public _checkFilter(filter: string) {
    return this.filters.hasOwnProperty(filter) && this.filters[filter] != null;
  }

  public _fixFilterValues(filters: any) {
    filters = JSON.parse(JSON.stringify(filters));
    Object.keys(filters).forEach(key => {
      const val = filters[key];
      if (val == null) { return; }
      if (typeof val === 'string' && val.length === 0) {
        filters[key] = null;
      }
    });
    return filters;
  }
}
