import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AssessmentFrontendService } from '@core/store/assessment/assessment.frontend.service';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { UpdatedUserPermissionsAction } from '@core/store/client/client.actions';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { OpenAssessmentModal, OpenAssessmentOverviewModal } from '@shared/modals/assessments/assessment-modal.actions';
import { AssessmentOverviewModalOpts } from '@shared/modals/assessments/overview/assessment-overview-modal.options';
import { OpenConfirmModal } from '@shared/modals/confirm/confirm-modal.actions';
import { ModalModelComponent } from '@shared/modals/modal.model';

@Component({
  selector: 'indicio-assessment-overview-modal',
  templateUrl: './assessment-overview-modal.component.html'
})
export class AssessmentOverviewModalComponent extends ModalModelComponent {

  forecastVersion: ForecastVersionModel;
  project: ProjectModel;
  data: PlotValueDTO[] = null;
  callback: Function = null;
  assessment: AssessmentModel = null;
  assessments: AssessmentModel[] = [];
  loaded = false;
  userIsAdmin = false;
  saveOnClose = false;

  userCanCreateAssessment = false;
  userCanUpdateAssessment = false;

  constructor(
    protected store: Store,
    public appearance: AppearanceService,
    public envService: EnvironmentService,
    private status: StatusService,
    private clientService: ClientFrontendService,
    private profileService: ProfileFrontendService,
    private assService: AssessmentFrontendService,
    private actions: ActionService
  ) {
    super();
  }

  public close() { }

  public setOptions(options: AssessmentOverviewModalOpts) {
    this.forecastVersion = options.forecastVersion;
    this.project = options.project;
    this.setUserAdminRights();
    this.syncAssessments();
    this.data = options.inputData;
    this.callback = options.callback;
    this.assessment = options.assessment;

    this.addSubscription(this.actions.dispatched(UpdatedUserPermissionsAction).subscribe(() => {
      this.setPermissions();
    }));

    this.setPermissions();
    this.isLoading = false;
  }

  private setPermissions() {
    this.userCanCreateAssessment = this.project.hasPermission('CAN_CREATE_ASSESSMENT');
    this.userCanUpdateAssessment = this.project.hasPermission('CAN_UPDATE_ASSESSMENT');
  }

  public onClose() {
    if (this.saveOnClose) {
      this.pending = true;
      const promises = [];
      this.assessments.forEach(ass => {
        if (ass.needsSave) {
          const p = this.assService.updateAssessmentOverview(ass);
          promises.push(p);
          ass.needsSave = false;
        }
      });
      Promise.all(promises)
        .then(() => {
          this.pending = false;
          if (this.callback) { this.callback(); }
        })
        .catch(() => {
          this.pending = false;
          this.status.setMessage('Failed to update assessment', 'Error', false);
        });
    }
  }

  private syncAssessments() {
    this.assessments = this.assService.assessmentsInForecastVersion(this.forecastVersion.ForecastVersionId);
    this.loaded = true;
  }

  private setUserAdminRights() {
    const isAdmin = this.project.isUserAdmin(this.profileService.profile.Email);
    const companyAdmin = this.clientService.activeCompany.isUserAdmin(this.profileService.profile.Email);
    this.userIsAdmin = isAdmin || companyAdmin;
  }

  public canUserDelete(assessment: AssessmentModel): boolean {
    const can = this.project.hasPermission('CAN_DELETE_ASSESSMENT');
    if (!can) { return false; }
    const isOwner = assessment.ClientEmail === this.profileService.profile.Email;
    return isOwner || this.userIsAdmin;
  }

  private getReopenThisFn() {
    return () => {
      this.store.dispatch(new OpenAssessmentOverviewModal(this.project, this.forecastVersion, this.data, this.callback, this.assessment));
    };
  }

  public addAssessmentForm() {
    this.close();
    this.store.dispatch(new OpenAssessmentModal(
      this.forecastVersion,
      this.project,
      this.data,
      this.callback, null, this.getReopenThisFn()));
  }

  public editAssessmentForm(assessment: AssessmentModel) {
    this.close();
    this.store.dispatch(new OpenAssessmentModal(
      this.forecastVersion,
      this.project,
      this.data,
      this.callback, assessment, this.getReopenThisFn()));
  }

  public fillAssessmentsWithInfo() {
    const len = this.assessments.length;
    for (let i = 0; i < len; i++) {
      const creator = this.clientService.activeCompany.getEmployeeByEmail(this.assessments[i].ClientEmail);
      if (creator !== null) {
        this.assessments[i].creatorName = creator.FirstName;
      }

      const unit = this.envService.getAssessmentUnit(this.assessments[i].Unit);
      if (unit !== null) { this.assessments[i].assessmentUnit = unit; }

      const level = this.envService.getAssessmentTrustLevel(this.assessments[i].TrustLevel);
      if (level !== null) { this.assessments[i].assessmentLevel = level; }

      const category = this.envService.getAssessmentCategory(this.assessments[i].Category);
      if (level !== null) { this.assessments[i].assessmentCategory = category; }

      this.assessments[i].userCanDeleteOrEdit = this.canUserDelete(this.assessments[i]);
    }
  }

  public getCompanyUser(email: string) {
    return this.clientService.activeCompany.getEmployeeByEmail(email);
  }

  public toggleActive(assessment: AssessmentModel) {
    assessment.Active = !assessment.Active;
    assessment.needsSave = true;
    this.saveOnClose = true;
  }

  public onChangeWeight(weight, assessment: AssessmentModel) {
    if (weight === null || weight < 0) { weight = 0; }
    if (weight > 100) { weight = 100; }
    assessment.Weight = weight;
    assessment.needsSave = true;
    this.saveOnClose = true;
  }

  public getNameOfProjectMember(clientEmail: string) {
    const member = this.project.GetMemberByEmail(clientEmail);
    return member.FirstName;
  }

  public removeAssessment(assessment: AssessmentModel) {
    const proceed = () => {
      this.assService.removeAssessment(assessment)
        .then(() => {
          if (this.callback) { this.callback(); }
        })
        .catch(error => {
          if (this.callback) { this.callback(); }
          this.status.setError(error, true);
        });
    };
    const back = () => {
      this.store.dispatch(new OpenAssessmentOverviewModal(this.project, this.forecastVersion, this.data));
    };

    const message = 'Are you sure you wish to remove this assessment?';
    const title = 'Remove assessment';
    const decline = 'No';
    const confirm = 'Yes, delete it';
    const negativeEffect = true;
    this.close();
    this.store.dispatch(new OpenConfirmModal(proceed, message, title, decline, confirm, negativeEffect, back, undefined, () => { }));
  }
}
