<div class="company-edit-audit-tab"
     *ngIf="!!company">
  <ng-container *ngTemplateOutlet="auditTemplate"></ng-container>
</div>

<ng-template #auditTemplate>
  <!-- <div class="settings">settings</div> -->
  <div style="--grid-template-global: repeat(5, 1fr) minmax(100px, 0.4fr) minmax(60px, 0.4fr)">
    <ng-container *ngTemplateOutlet="logsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #logsTemplate>
  <div class="grid-system bold margin-bottom-10 stable-scroll-padding">
    <div class="crop-string">Actor</div>
    <div class="crop-string">Action</div>
    <div class="crop-string">Project</div>
    <div class="crop-string">Forecast</div>
    <div class="crop-string">Object</div>
    <div class="crop-string">Date</div>
    <div class="crop-string">Time</div>
  </div>
  <div class="logs stable-scroll"
       *ngIf="logs.length">
    <div class="grid-system hover pointer h-grey"
         *ngFor="let log of logs"
         (click)="openAuditInfo(log)">
      <div class="margin-right-10 crop-string"
           [title]="log.SubjectName || log.SubjectId">
        {{ log.SubjectName || log.SubjectId }}
      </div>
      <div class="margin-right-10 crop-string"
           [title]="log.Action">{{ log.Action }}
      </div>
      <div class="margin-right-10 crop-string"
           [title]="log.ProjectName">{{ log.ProjectName }}</div>
      <div class="margin-right-10 crop-string"
           [title]="log.ForecastName">{{ log.ForecastName }}</div>
      <div class="margin-right-10 crop-string"
           [title]="log.ObjectName || log.ObjectId">{{ log.ObjectName || log.ObjectId }}</div>
      <div class="margin-right-10 crop-string"
           [title]="log.Time | date: appearance.DateFormat ">{{ log.Time | date: appearance.DateFormat }}</div>
      <div class="margin-right-10 crop-string"
           [title]="log.Time | dateFormat: null:appearance.TimeFormatNoSeconds">
        {{ log.Time | dateFormat: null:appearance.TimeFormatNoSeconds}}</div>
    </div>
    <indicio-infinity-scroll (visibleEvent)="loadMore()"
                             container=".logs"></indicio-infinity-scroll>
  </div>
</ng-template>
