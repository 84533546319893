<ng-container *ngIf="!isLoading">
  <ng-container *ngTemplateOutlet="listUsers;">
  </ng-container>
</ng-container>

<ng-template #listUsers>
  <div class="data-table margin-top"
       style="width: 645px;">
    <div class="data-table__header">
      <div class="column header left-align"
           style="width: 290px">Users</div>
      <ng-container *ngIf="!isProjectType">
        <div class="column header"
             [style.width.px]="userCanInvite ? 200 : 240">Joined</div>
        <div class="column header"
             *ngIf="userCanInvite"
             style="width: 40px">2FA</div>
      </ng-container>
      <ng-container *ngIf="isProjectType">
        <div class="column header"
             style="width: 240px;">
          Transfer ownership
        </div>
      </ng-container>
      <div class="column header left-align"
           [style.width.px]="userCanInvite ? 75 : 105">Role</div>
      <div class="column header"
           *ngIf="userCanInvite"
           style="width: 30px;"></div>
    </div>
    <div class="data-table__body"
         style="margin-top: 0"
         #fileuploadscroll
         [style.maxHeight.px]="256"
         #scrollbarElement>
      <ng-container *ngFor="let user of sortedMembers; let i = index;">
        <ng-container *ngTemplateOutlet="rowUsers; context: { i } ">
        </ng-container>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="userCanInvite"
                [ngTemplateOutlet]="newInviteRow">
  </ng-container>
</ng-template>

<ng-template #rowUsers
             let-i="i">
  <ng-container *ngIf="sortedMembers[i]; let user">
    <div class="body__row body__row--center-height body__row--max-height-30"
         [attr.data-test-id]="user.Email + '-member-row'"
         [indicioTippy]="user.Disabled ? 'User is disabled' : null">
      <div class="column left-align crop-string vertical-align selectable-text"
           style="height:30px;"
           [style.opacity]="user.Disabled ? 0.5 : 1"
           [style.width.px]="290">
        {{ user.Email }} <span *ngIf="user.IsCurrentUser">
          &nbsp;(<span class="italic">*</span>)
        </span>
        <i *ngIf="user.newMember && !pendingInvite(user.Email)"
           indicioTippy="New member"
           class="ion-android-checkmark-circle"
           style="color: #009966; margin-left: 3px"></i>
        <i *ngIf="pendingInvite(user.Email);"
           class="ion-load-c spinner"
           style="color:rgb(128,128,128);margin-left: 3px"></i>
      </div>
      <ng-container *ngIf="!isProjectType">
        <div class="column"
             [style.opacity]="user.Disabled ? 0.5 : 1"
             [style.width.px]="userCanInvite ? 200 : 240"
             [indicioTippy]="user.PendingSignup ? 'Pending signup, invite sent ' + (user.CreatedDate | dateFormat) + ', Expires ' + (user.Expires | dateFormat) + '' : null">
          <span *ngIf="!user.PendingSignup">
            {{ user.Joined | dateFormat }}
          </span>
          <span *ngIf="user.PendingSignup">
            Expires {{ user.Expires | dateFormat }}
            <div class="btn-small"
                 style="line-height: normal;margin-left:2px;"
                 (click)="resendInvite([user]);">
              Resend
            </div>
          </span>
        </div>
        <div class="column"
             *ngIf="userCanInvite"
             style="width: 40px;"
             [style.opacity]="user.Disabled ? 0.5 : 1"
             [indicioTippy]="user.Disabled ? null : user.twoFactorEnabled ? '2 factor authentication enabled' : '2 factor not enabled'">
          <i [class]="user.twoFactorEnabled ? 'ion-locked green' : 'ion-unlocked grey'"></i>
        </div>
      </ng-container>
      <ng-container *ngIf="isProjectType">
        <div class="column"
             style="width:240px;">
          <indicio-options-menu *ngIf="user.IsCurrentUser && user.IsOwner"
                                (openEvent)="menuOpenFor = $event.open ? i : null"
                                iconsize="21px"
                                [nohover]="true"
                                icon="ion-arrow-swap">
            <ul>
              <ng-container *ngFor="let user of sortedMembers">
                <li (click)="transferOwnership(user);"
                    *ngIf="!user.IsCurrentUser">
                  {{user.Email}}
                </li>
              </ng-container>
            </ul>
          </indicio-options-menu>
          <ng-container *ngIf="!user.IsCurrentUser || !user.IsOwner">N/A</ng-container>
        </div>
      </ng-container>
      <div class="column left-align"
           [style.opacity]="user.Disabled ? 0.5 : 1"
           [style.width.px]="userCanInvite ? 80 : 105">
        <ng-container>
          <div *ngIf="user.IsOwner">Owner</div>
          <div *ngIf="!user.IsOwner">
            <div *ngIf="userCanInvite && !user.IsCurrentUser && !user.Disabled">
              <mat-form-field
                              style="padding-right: 5px; --mat-form-field-container-text-size: 12px; --mat-select-trigger-text-size: 12px;">
                <mat-select [attr.data-test-id]="'member-list-user-row-' + i + '-role-dropdown'"
                            panelClass="fitted-select"
                            [value]="user.MemberRole.Value"
                            (selectionChange)="updateRoleForMember($event, user)">
                  <mat-option [value]="role.Value"
                              [title]="role.Description"
                              [attr.data-test-id]="'member-list-dropdown-option-' + role.Value"
                              *ngFor="let role of newMemberRoleOptions">{{ role.Display }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <ng-container *ngIf="!userCanInvite || user.IsCurrentUser || user.Disabled">
              <div title="{{ user.MemberRole.Description }}">
                {{ user.MemberRole.Display }}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="column"
           *ngIf="userCanInvite"
           style="width: 30px; padding: 0; margin: 0; opacity: 1">
        <ng-container *ngIf="!user.IsCurrentUser && (!isProjectType || !user.IsOwner)">
          <indicio-options-menu (openEvent)="menuOpenFor = $event.open ? i : null"
                                iconsize="21px"
                                [nohover]="true">
            <ul>
              <li *ngIf="userCanInvite && !user.IsOwner"
                  (click)="removeMember(user)"
                  data-test-id="members-list-member-row-remove-btn">
                <div class="text">Remove user</div>

              </li>
              <li *ngIf="!isProjectType && !user.twoFactorEnabled && user.Joined"
                  (click)="remindUser2FA(user)">
                <div class="text">Remind user about 2FA</div>
              </li>
            </ul>
          </indicio-options-menu>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #newInviteRow>
  <div class="data-table"
       style="width: 645px; border-top: 2px solid #c7c7c7;">
    <div class="data-table__body"
         style="margin-top: 0"
         #fileuploadscroll
         [style.maxHeight.px]="35">
      <div class="body__row body__row--center-height body__row--max-height-30">
        <div class="column left-align crop-string vertical-align"
             [style]="getScrollBarVisible() ? 'width:287px; height:30px;' : 'width:290px; height:30px;'">
          <input style="width: -webkit-fill-available; border: none; outline: none; font-size: inherit; background-color: var(--indicio-modal-background-color, white); color: var(--indicio-modal-text-color, black)"
                 data-test-id="members-list-invite-email-field"
                 required
                 placeholder="Invite user by email"
                 autocomplete="off"
                 list="newMembers"
                 name="email"
                 (blur)="checkInvite(); email.hasError('email')"
                 (keyup)="checkInvite(); email.hasError('email')"
                 (keyup.enter)="invite()"
                 [(ngModel)]="newInviteEmail"
                 #email="ngModel"
                 email>
          <datalist id="newMembers">
            <option [value]="employee"
                    *ngFor="let employee of newMemberSuggestions">{{ employee }}</option>
          </datalist>
        </div>
        <div class="column vertical-align text-center"
             [style]="getScrollBarVisible() ? (isProjectType ? 'width: 238px;' : 'width: 198px;') : (isProjectType ? 'width: 240px;' : 'width: 200px;')">
          <button class="btn--small btn"
                  data-test-id="send-invite-to-user-btn"
                  style="margin: 0 auto; font-size: 12px; margin-top: 2px;"
                  (click)="invite()"
                  [indicioTippy]="shouldInviteNewMember ? 'Send invite' : newInviteEmail.length ? 'Email invalid' : 'Email empty'"
                  [ngClass]="{ disabled: !shouldInviteNewMember, green: shouldInviteNewMember }">
            Send invite
            <span *ngIf="loadingHelperForSendInviteBtn">
              &nbsp;<i class="ion-load-c spinner"></i>
            </span>
          </button>
        </div>
        <div class="column"
             *ngIf="!isProjectType"
             style="width: 40px;">
        </div>
        <div class="column vertical-align"
             style="width: 75px; display: block">
          <div>
            <mat-form-field style="--mat-form-field-container-text-size: 12px; --mat-select-trigger-text-size: 12px;">
              <mat-select data-test-id="members-list-invite-role-dropdown"
                          panelClass="fitted-select"
                          [(value)]="newMemberRole">
                <mat-option [value]="role"
                            [title]="role.Description"
                            [attr.data-test-id]="'member-list-dropdown-option-' + role.Value"
                            *ngFor="let role of newMemberRoleOptions">{{ role.Display }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
