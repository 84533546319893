import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionService } from '../actions/actions.service';
import { SystemMessage } from './system-message';

@Injectable({
  providedIn: 'root'
})
export class SystemMessageService {

  message$: Subject<SystemMessage.Entities.Message> = new Subject<SystemMessage.Entities.Message>();

  private messages: SystemMessage.Entities.Message[] = [];

  constructor(
    private actionService: ActionService
  ) {
    this.setup();
  }

  private setup() {
    this.actionService.dispatched(SystemMessage.Actions.Display)
      .subscribe(({ message }: SystemMessage.Actions.Display) => this.handleDisplayMessage(message));

    this.actionService.dispatched(SystemMessage.Actions.Clear)
      .subscribe((action: SystemMessage.Actions.Clear) => this.handleClearDisplayMessage(action.type));
  }

  private handleDisplayMessage(message: SystemMessage.Entities.Message) {
    this.messages.addOrUpdateBy(message, 'Type');
    this.showWithPrio();
  }

  private handleClearDisplayMessage(type: SystemMessage.MessageType | 'all') {
    if (type === 'all') {
      this.messages = [];
    } else {
      this.messages.removeByKey('Type', type);
    }
    this.showWithPrio();
  }

  private showWithPrio() {
    const tos = this.messages.find(x => x.Type === 'tos');
    if (tos) {
      return this.message$.next(tos);
    }
    const pusher = this.messages.find(x => x.Type === 'pusher');
    if (pusher) {
      return this.message$.next(pusher);
    }
    this.message$.next(undefined);
  }
}
