import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { AddDataTab, NavigateToAddData } from '@core/actions/navigation.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { SheetActions } from '@modules/forecast/views/add-data/file-sheet/sheet.actions';
import { SheetService } from '@modules/forecast/views/add-data/file-sheet/sheet.service';
import { SheetColumn } from '@modules/forecast/views/add-data/file-sheet/types/types';
import { Store } from '@ngxs/store';
import { SheetColumnImportComponent, SheetColumnImportState } from '@shared/components/sheet-import-columns/sheet-column-import.component';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { NavigateToForecast } from '@core/actions/navigation.actions';
import { NewOrUpdatedFilesModalOpts } from './new-or-updated-files.options';


@Component({
  selector: 'indicio-new-or-updated-files',
  templateUrl: './new-or-updated-files.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class NewOrUpdatedFilesModalComponent extends ModalModelComponent {

  @ViewChild(SheetColumnImportComponent)
  private importComponent: SheetColumnImportComponent;

  opts: NewOrUpdatedFilesModalOpts;
  currentFileNum = 0;
  currentFile: UploadedFileModel;
  modalTitle: string = 'Import data from file';
  nextBtnText: string = 'Import';

  forecastVersionId: string;
  valueColumns: SheetColumn[];
  importState: SheetColumnImportState;
  importedCount: number;
  toImportCount: number;
  currentSheet = 0;
  sheetLoading: boolean = false;
  firstLoad = true;
  fileName: string = null;

  public get isDisabled() {
    return this.importState === 'no-imports' || this.importState === 'error' || this.importState === 'importing';
  }

  constructor(
    protected store: Store,
    public sheetService: SheetService,
    public envService: EnvironmentService,
    private actions: ActionService
  ) {
    super();
  }

  public nextAction() {
    switch (this.importState) {
      case 'done':
        if (this.currentFileNum < this.opts.files.length) {
          this.nextFile();
        } else {
          if (!this.opts.skipSheetInit) {
            this.backToForecast();
          } else {
            this.backToFiles();
          }
        }
        break;
      case 'pending':
        this.import();
        break;
      default:
        this.close();
    }
  }

  private setNextBtnText() {
    switch (this.importState) {
      case 'done':
        if (this.currentFileNum < this.opts.files.length) {
          this.nextBtnText = 'Next';
        } else {
          if (!this.opts.skipSheetInit) {
            this.nextBtnText = 'Back to forecast';
          } else {
            this.nextBtnText = 'Back to files';
          }
        }
        break;
      case 'pending':
        this.nextBtnText = 'Import';
        break;
      default:
        this.nextBtnText = 'Back to files';
    }
  }

  private backToFiles() {
    this.close();
    this.store.dispatch(new NavigateToAddData(this.opts.forecastId, AddDataTab.uploadedfiles));
  }

  private backToForecast() {
    this.close();
    this.store.dispatch(new NavigateToForecast(this.opts.forecastId));
  }

  public nextFile() {
    this.currentFileNum++;
    this.currentFile = this.opts.files[this.currentFileNum - 1];
    if (!this.opts.skipSheetInit) {
      this.sheetService.setupFile(this.currentFile.getModelId(), this.currentFile.SourceType);
    }
    this.isLoading = true;
    this.updateTitle();
  }

  public setImportState(state) {
    this.importState = state;
    this.setNextBtnText();
  }

  public import() {
    this.importComponent.import();
  }

  private updateTitle() {
    this.modalTitle = 'Import data from file ' + this.currentFileNum + ' of ' + this.opts.files.length;
  }

  public changeSheet(event: number) {
    this.sheetLoading = true;
    const sheet = event;
    this.setSheet(sheet);
  }

  private setSheet(idx: number) {
    this.currentSheet = idx;
    this.sheetService.toggleSheet(idx);
  }

  public async setOptions(options: NewOrUpdatedFilesModalOpts) {
    this.opts = options;
    this.nextFile();
    this.forecastVersionId = this.opts.forecastVersionId;
    if (this.opts.skipSheetInit) { this.setData(); }
    this.actions.dispatched(SheetActions.SheetChanged).subscribe(() => {
      this.setData();
    });
  }

  private setData() {
    if (this.firstLoad && !this.opts.skipSheetInit) {
      const firstSheetToView = this.sheetService.parsedSheets.findIndex(x => {
        if (x.Columns) {
          return x.Columns.filter(z => z.ColumnType === 'Values').some(y => !y.used);
        } return false;
      });
      if (firstSheetToView > 0) {
        this.setSheet(firstSheetToView);
      }
      this.firstLoad = false;
    }
    this.valueColumns = this.sheetService.currentSheet.Columns.filter(x => x.ColumnType === 'Values' || x.ColumnType === 'NotUsed') || [];
    this.currentSheet = this.sheetService.currentSheet.SheetIndex;
    this.importedCount = 0;
    this.isLoading = false;
    this.sheetLoading = false;
  }
}
