import { ReportModel } from '@core/store/reports/models/report.model';
import { OverviewReport } from '@modules/reports/models/overview-model';
import { DateFormatPipe } from '@shared/modules/pipes';
import { DateUtils } from '@shared/utils/date.utils';
import { ReportUtils } from '@shared/utils/report.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { ExcelBaseGenerator } from '../excel-base-generator';
import { ExcelFile, ExcelRow, ExcelSheet } from '../excel.entities';

export class OverviewReportGeneratorData {
  Overview: OverviewReport;
  Report: ReportModel;
  DateFormat: DateFormatPipe;
}

export class OverviewReportGenerator extends ExcelBaseGenerator {
  private data: OverviewReportGeneratorData;

  constructor(data: OverviewReportGeneratorData) {
    super();
    this.data = data;
  }

  public generate(): ExcelFile {
    const file = new ExcelFile;
    file.FileName = StringUtils.slugify(this.data.Report.Name);
    file.FileType = 'xlsx';

    const sheet = new ExcelSheet;
    sheet.Name = 'Values';
    sheet.Title = `Report: ${this.data.Report.Name}`;

    // Add header
    const headerRow = new ExcelRow;
    headerRow.id = 'header';
    headerRow.Cells.push(
      this.createCell('Forecast', null, { bold: true }),
      this.createCell('...', null, { bold: true }),
    );

    // Add dates
    const dates = this.data.Overview.dates;
    dates.forEach((date, i) => {
      headerRow.Cells.push(this.createCell(this.data.DateFormat.transform(date), null, { bold: true }));
      if (dates[i + 1] && DateUtils.isDateDiffLargerThan(date, dates[i + 1])) {
        headerRow.Cells.push(this.createCell('...'));
      }
    });

    sheet.addRow(headerRow);

    const forecasts = this.data.Overview.Entries;

    // Add forecast rows
    forecasts.forEach(forecast => {
      const row = new ExcelRow;
      row.Cells.push(this.createCell(forecast.Name));
      // Add values
      dates.forEach((date, i) => {
        const value = ReportUtils.getValueAtDate(forecast, date);
        const cell = this.createCell(value?.V);
        const cellIndex = this.getDateIndex(sheet, date);
        if (value) {
          cell.BG = !value.IF ? '#eee' : 'yellow';
        }
        row.Cells[cellIndex] = cell;
        if (dates[i + 1] && DateUtils.isDateDiffLargerThan(date, dates[i + 1])) return row.Cells.push(this.createCell(''));
      });
      sheet.addRow(row);
    });

    sheet.addRow(new ExcelRow);

    // Add asterisk
    const asteriskRow = new ExcelRow;
    asteriskRow.Cells.push(this.createCell('* Showing 20 latest historical data points for each forecast'));
    sheet.addRow(asteriskRow);

    file.Sheets.push(sheet);

    return file;
  }

  private getDateIndex(sheet: ExcelSheet, date: moment.Moment) { return sheet.columnHeaderRow.Cells.findIndex(x => x.Value === this.data.DateFormat.transform(date)); }
}
