import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVersionDTO } from '@core/store/forecast/dtos/forecast-version/forecast-version-dto';
import { ForecastVersionDateRangeDTO, ForecastVersionMetaDTO } from '@core/store/forecast/dtos/forecast-version/forecast-version-meta-dto';
import { UpdateForecastVersionDTO } from '@core/store/forecast/dtos/forecast-version/update-forecast-version-dto';
import { ForecastDTO, RecentForecastDTO } from '@core/store/forecast/dtos/forecast/forecast-dto';
import { UpdateForecastDTO } from '@core/store/forecast/dtos/forecast/update-forecast-dto';
import { ScriptCalculateInfoDTO } from '@core/store/forecast/dtos/misc/script-calculate-info-dto';
import { ForecastHelper } from '@core/store/forecast/forecast-helper';
import { ForecastSubMapper } from '@core/store/forecast/mapper/forecast-pure-mapper';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ScriptCalculateInfoModel, ScriptInfoModel } from '@core/store/forecast/models/script-calculate-info.model';
import { HistoricEventMapper } from '@core/store/historic-event/historic-event.mapper';
import { DateUtils } from '@shared/utils/date.utils';
import { ForecastMetaDTO } from '../dtos/forecast/forecast-meta-dto';


@Injectable({
  providedIn: 'root'
})
export class ForecastMapper {

  constructor(
    private forecastVariableMapper: ForecastVariableMapper,
    private forecastSubMapper: ForecastSubMapper,
    private heMapper: HistoricEventMapper,
    private envService: EnvironmentService
  ) {
  }

  public map(dto: ForecastDTO, model?: ForecastModel): ForecastModel {
    model = this.forecastSubMapper.map(dto, model);
    return model;
  }

  public mapMetaToModel(dto: ForecastMetaDTO, model?: ForecastModel) {
    model = this.forecastSubMapper.mapMetaToModel(dto, model);
    return model;
  }

  public mapRecentForecast(dto: RecentForecastDTO) {
    dto.periodicity = this.envService.getPeriodicity(dto.Periodicity);
    dto.ModifiedDate = DateUtils.newDate(dto.ModifiedDate);
    return dto;
  }

  public mapVersion(dto: ForecastVersionDTO) {
    const model = this.forecastSubMapper.mapVersionMeta(dto.Meta);
    model.ImportedHistoricBaseEvents = dto.ImportedHistoricBaseEvents.map(x => this.heMapper.mapImportedBase(x));
    if (dto.ForecastVariable) {
      model.ForecastVariable = this.forecastVariableMapper.mapMeta(dto.ForecastVariable);
    }

    model.IndicatorVariables = dto.IndicatorVariables.map(indicator => this.forecastVariableMapper.mapMeta(indicator));
    model.setOriginalActiveVarables();

    model.IndicatorWarnings = dto.IndicatorWarnings;

    this.mapCalcInfo(dto.ScriptCalculateInfo, model);
    model.fetched = true;

    model.VarSelectSettings = dto.VarSelectSettings;
    return model;
  }

  public mapVersionMeta(dto: ForecastVersionMetaDTO, model?: ForecastVersionModel) {
    model = this.forecastSubMapper.mapVersionMeta(dto, model);
    return model;
  }

  public mapDateInfo(dto: ForecastVersionDateRangeDTO, fVersion: ForecastVersionModel) {
    const model = this.forecastSubMapper.mapDateInfo(dto, fVersion);
    return model;
  }

  public toUpdateDto(model: ForecastModel) {
    const dto = Object.faMapTo(new UpdateForecastDTO(), model);
    return dto;
  }

  public toUpdateVersionDto(model: ForecastVersionModel) {
    const dto = Object.faMapTo(new UpdateForecastVersionDTO(), model);
    dto.StartDate = DateUtils.newMoment(model.StartDate).format('YYYY-MM-DD');
    dto.DataStartDate = DateUtils.convertToBackendDate(model.DataStartDate, true);
    dto.TestStartDate = DateUtils.convertToBackendDate(model.TestStartDate, true);
    return dto;
  }

  public mapCalcInfo(dto: ScriptCalculateInfoDTO, model: ForecastVersionModel) {
    model.ScriptCalculateInfo = Object.faMapTo(new ScriptCalculateInfoModel(), {
      CanTriggerVariableSelect: dto.CanTriggerVariableSelect,
      NowcastNeeded: dto.NowcastNeeded,
      Multivariate: dto.Multivariate.map(multi => Object.faMapTo(new ScriptInfoModel(), multi)),
      Univariate: dto.Univariate.map(uni => Object.faMapTo(new ScriptInfoModel(), uni)),
    });
    ForecastHelper.updateCalcVarSelectStatus(model);
    ForecastHelper.updateCalcMultivariateStatus(model);
  }
}
