import { DisplayValue } from '@modules/lang/types/display-value';

export namespace IndicioConstants {
  export enum Licenses {
    Indicio = 'Indicio',
    Macrobond = 'Macrobond'
  }

  export enum EAggregationMethods {
    Average = 'average',
    Sum = 'sum',
    LastValue = 'last-value'
  }

  export type SystemRoleType = 'sysadmin' | 'sysuser' | 'syssales' | 'syssupport';
  export enum SystemRole {
    sysadmin = 'Admin',
    sysuser = 'User',
    syssales = 'Sales',
    syssupport = 'Support'
  }

  export const SystemRoles: DisplayValue<IndicioConstants.SystemRoleType>[] = [
    { Value: 'sysadmin', Display: IndicioConstants.SystemRole.sysadmin },
    { Value: 'sysuser', Display: IndicioConstants.SystemRole.sysuser },
    { Value: 'syssales', Display: IndicioConstants.SystemRole.syssales },
    { Value: 'syssupport', Display: IndicioConstants.SystemRole.syssupport },
  ];
}
