import { DisplayValue } from './display-value';

export class Country extends DisplayValue<string> {

  public CurrencyCode: string;
  public PhoneCode: number;

  constructor(value: string, display: string, currency: string, phone: number) {
    super(value, '', display);
    this.Display = value + ' - ' + display;
    this.CurrencyCode = currency;
    this.PhoneCode = phone;
  }
}
