
export namespace MathUtils {

  export function getInfixExpressionRegex(identifiers: string[]) {
    // Create a regex pattern dynamically based on the valid variables
    const vars = identifiers.join('');
    // Allowed optional left edge characters
    const optEdgeL = '[\\s\\(\\-]';
    // Allowed optional right edge characters
    const optEdgeR = '[\\s\\)]';
    // Allowed left edge characters
    const edgeL = `[${vars}\\s\\(\\d\\.]`;
    // Allowed right edge characters
    const edgeR = `[${vars}\\s\\)\\d\\.]`;
    // Allowed body characters
    const body = `[${vars}\\s\\d\\.\\+\\-\\*\\/\\^\\(\\)]`;
    return new RegExp(`^${optEdgeL}?${edgeL}${body}*${edgeR}${optEdgeR}?$`);
  }

  export function checkGroupInfixExpression(expression: string, regex: RegExp) {
    const parentheses = expression.split('(').length === expression.split(')').length;
    return regex.test(expression.trim()) && parentheses;
  }
}
