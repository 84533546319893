<div class="forecast-template-card"
     [class]="{ dark: dark }"
     data-test-id="forecast-template-card"
     (click)="viewInfo()">
  <div class="title bold"
       [indicioTippy]="template.Title">
    {{ template.Title }}
  </div>
  <div class="indicators">
    <ul>
      <li *ngFor="let ind of indicators | slice:0:4; let i = index">
        <div class="name crop-string">{{ind.Name}}</div>
        <div class="influence">
          <indicio-influence-stars [influence]="template.normalizedInfluence[i].influence"></indicio-influence-stars>
        </div>
      </li>
    </ul>
  </div>
  <div class="footer">
    <div>
      Maintained by {{ template.AuthorInfo.AuthorName }}
    </div>
    <div>
    </div>
  </div>
</div>
