import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { PeriodicityFitDTO, PeriodicityFitInfoDTO, PeriodicitySourceVarInfoDTO } from '@core/store/source-variable/dtos/source-variable-version-dto';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableViewDTO } from '@core/store/source-variable/source-variable.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { DateFormatPipe } from '@shared/modules/pipes';

export interface ManualPeriodicityDialogData {
  SourceVariableId: string;
  OpenedFromModal: boolean;
}

@Component({
  selector: 'indicio-manual-periodicity-dialog',
  templateUrl: 'manual-periodicity.dialog.html',
  encapsulation: ViewEncapsulation.None,
})
export class ManualPeriodicityDialogComponent {
  public static Id: string = 'ManualPeriodicityDialogComponent';

  public sourceVariable: SourceVariableViewDTO;
  public periodicityInfos: PeriodicityFitDTO[];
  public loadingPInfo = false;
  private detailedPeriodicityInfo: PeriodicitySourceVarInfoDTO;

  constructor(
    public dialogRef: MatDialogRef<ManualPeriodicityDialogComponent>,
    public env: EnvironmentService,
    private sourceVariableService: SourceVariableFrontendService,
    private status: StatusService,
    private datePipe: DateFormatPipe,
    @Inject(MAT_DIALOG_DATA) public data: ManualPeriodicityDialogData
  ) {
    this.sourceVariable = this.sourceVariableService.viewVariables.find(x => x.SourceVariableId === data.SourceVariableId);
    this.sourceVariableService.getOrFetchSourceVariable(data.SourceVariableId);
    this.periodicityInfos = this.sourceVariable.PeriodicityInfo.AllFits;

    if (this.periodicityInfos.length > 1) {
      this.loadingPInfo = true;
      this.sourceVariableService.getPeriodicityInfo(this.data.SourceVariableId, this.periodicityInfos.map(x => x.Periodicity))
        .then(periodicityInfos => this.detailedPeriodicityInfo = periodicityInfos)
        .catch(err => this.status.setError(err, true))
        .finally(() => this.loadingPInfo = false);
    }
  }

  public getExtraMissingInfoHover(periodicity: PeriodicityType, type: keyof PeriodicityFitInfoDTO) {
    if (type === 'Periodicity') return null;
    const info = this.detailedPeriodicityInfo?.PeriodicityInfos?.find(x => x.Periodicity === periodicity);
    if (!info) return null;
    const dates: string[] = (<string[]> info[type]).map(x => this.datePipe.transform(x, type === 'Missing' ? periodicity : null));
    return `<b>${type} dates</b> <hr style="margin-top: 5px"> ${dates.join('<br>')}`;
  }

  public setPeriodicity(p: PeriodicityType) {
    this.sourceVariableService.setManualPeriodicity(this.sourceVariable.SourceVariableId, p)
      .then(newPeriodicity => {
        this.close();
        this.status.setMessage(`Manual periodicity set to ${newPeriodicity.Display}.`, 'Success', this.data.OpenedFromModal);
      })
      .catch(err => this.status.setError(err, true));
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  };

  public close() {
    this.dialogRef.close(true);
  }
}
