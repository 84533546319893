import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GraphThemeModel, GraphThemeSettingsModel } from '@core/store/client/graph-theme.model';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';

export interface DialogData {
  themes: GraphThemeModel[];
  currentTheme: GraphThemeModel;
  selectedTheme: GraphThemeModel;
}

@Component({
  selector: 'indicio-dialog-graph-themes-dialog',
  templateUrl: 'dialog-graph-themes.dialog.html',
  styleUrls: ['../dialogs.less']
})
export class DialogGraphThemesComponent {
  public static Id: string = 'DialogGraphThemesComponent';

  themes: GraphThemeModel[];
  currentTheme: GraphThemeSettingsModel;
  selectedTheme: GraphThemeModel;

  newThemeName: string;

  statusmsg: string;
  statusTimer: any;

  constructor(
    public dialogRef: MatDialogRef<DialogGraphThemesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private service: ProfileFrontendService
  ) {
    this.themes = data.themes;
    this.currentTheme = Object.faMapTo(new GraphThemeSettingsModel, data.currentTheme);
  }

  saveNewTheme() {
    const theme = new GraphThemeModel;
    theme.Name = this.newThemeName;
    theme.Theme = this.currentTheme;
    this.service.createGraphTheme(theme)
      .then(newTheme => {
        this.themes.push(newTheme);
        this.newThemeName = '';
        this.selectedTheme = newTheme;
        this.setStatusMessage('Theme created!');
      });
  }

  setStatusMessage(message: string) {
    if (this.statusTimer) {
      window.clearTimeout(this.statusTimer);
    }
    this.statusmsg = message;
    this.statusTimer = setTimeout(() => {
      this.statusmsg = null;
    }, 2500);
  }

  updateTheme(theme: GraphThemeModel) {
    theme.Theme = this.currentTheme;
    this.service.updateGraphTheme(theme)
      .then(() => {
        this.setStatusMessage('Theme updated!');
      });
  }

  deleteTheme(theme: GraphThemeModel) {
    this.service.deleteGraphTheme(theme)
      .then(() => {
        const index = this.themes.findIndex(t => t.GraphThemeId === theme.GraphThemeId);
        this.themes.splice(index, 1);
        this.selectedTheme = null;
        this.setStatusMessage('Theme removed!');
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
