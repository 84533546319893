<indicio-modal-base [child]="this"
                    modalTitle="Edit news post"
                    [loading]="isLoading"
                    bodyExtraClass="overflow-auto">

  <div class="edit-news-post-container"
       *ngIf="newsPost">
    <form (ngSubmit)="updatePost()"
          ngNativeValidate>
      <div class="row">
        <div class="col-xs-5 input-label m6">Title</div>
        <div class="col-xs-7">
          <mat-form-field>
            <input type="text"
                   matInput
                   placeholder="Enter title"
                   class="material"
                   required
                   [(ngModel)]="newsPost.Meta.Title"
                   [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
      </div>

      <div class="row margin-top">
        <div class="col-xs-5 input-label m6">Preview</div>
        <div class="col-xs-7">
          <mat-form-field>
            <textarea required
                      matInput
                      placeholder="Enter preview text"
                      [(ngModel)]="newsPost.Meta.Preview"
                      [ngModelOptions]="{standalone: true}"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="row margin-top">
        <div class="col-xs-5 input-label m6">Body</div>
        <div class="col-xs-7">
          <mat-form-field>
            <textarea required
                      matInput
                      placeholder="Enter body"
                      [(ngModel)]="newsPost.Meta.Body"
                      [ngModelOptions]="{standalone: true}"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-5 input-label">Hidden?</div>
        <div class="col-xs-7 input-label cursor-pointer"
             (click)="newsPost.Meta.Hidden = !newsPost.Meta.Hidden">
          <i
             [ngClass]="newsPost.Meta.Hidden ? 'ion-android-checkbox-outline' : 'ion-android-checkbox-outline-blank'"></i>
        </div>
      </div>

      <div class="row margin-top">
        <div class="col-xs-5 input-label">Hide author?</div>
        <div class="col-xs-7 input-label cursor-pointer"
             (click)="newsPost.Meta.HideAuthor = !newsPost.Meta.HideAuthor">
          <i
             [ngClass]="newsPost.Meta.HideAuthor ? 'ion-android-checkbox-outline' : 'ion-android-checkbox-outline-blank'"></i>
        </div>
      </div>

      <div class="row margin-top">
        <div class="col-xs-5 input-label">Disable comments?</div>
        <div class="col-xs-7 input-label cursor-pointer"
             (click)="newsPost.Meta.DisableComments = !newsPost.Meta.DisableComments">
          <i
             [ngClass]="newsPost.Meta.DisableComments ? 'ion-android-checkbox-outline' : 'ion-android-checkbox-outline-blank'"></i>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-5 input-label">Publish date</div>
        <div class="col-xs-7 input-label cursor-pointer">
          <input type="date"
                 [(ngModel)]="publishDate"
                 [ngModelOptions]="{standalone: true}">
        </div>
      </div>

      <div class="row">
        <div class="col-xs-5 input-label">Priority</div>
        <div class="col-xs-7 input-label cursor-pointer">
          <indicio-searchable-dropdown label="Priority"
                                       [required]="true"
                                       optionDisplay="Title"
                                       [values]="priorityOptions"
                                       [initializeWithFirstValue]="false"
                                       [selectedValue]="selectedPrio"
                                       (valueChangeEvent)="newsPost.Meta.Priority = $event.Value">
          </indicio-searchable-dropdown>
        </div>
      </div>

      <div class="row margin-top"
           *ngIf="unsaved">
        <div class="col-xs-12 red">
          * This is a loaded and unsaved version.
        </div>
      </div>

      <div class="row">
        <div class="col-xs-3">
          <button class="btn outline blue"
                  type="button"
                  (click)="close()">Cancel</button>
        </div>
        <div class="col-xs-4">
          <button class="btn danger"
                  type="button"
                  (click)="deletePost()">
            <span>Delete</span>
          </button>
        </div>
        <div class="col-xs-5">
          <button class="btn right"
                  type="submit">
            <span *ngIf="!pending">Save</span>
            <span *ngIf="pending"
                  class="ion-load-c spinner"></span>
          </button>
        </div>
      </div>
    </form>

  </div>
</indicio-modal-base>
