import { HttpErrorResponse } from '@angular/common/http';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { ClientModel } from './client.model';

export namespace ClientActions {
  export class GetRemoteSources {
    public static readonly type = '[CLIENT] Get remote sources';
    constructor(public sources: RemoteDataSourceModel[]) { }
  }

  export class FetchClientSecuritySettings {
    public static readonly type = '[CLIENT] Get client security settings';
    constructor() { }
  }
}

export class GetClientSuccessAction {
  public static readonly type = '[CLIENT] Get client success';
  constructor(public client: ClientModel) { }
}

export class GetClientFailedAction {
  public static readonly type = '[CLIENT] Get client failed';
  constructor(public error: HttpErrorResponse) { }
}

export class GetRemoteDataSourceSuccessAction {
  public static readonly type = '[CLIENT] Get remote data source success';
  constructor(public dataSource: RemoteDataSourceModel) { }
}

export class UpdatedUserPermissionsAction {
  public static readonly type = '[USER] Updated permissions';
  constructor() { }
}

export class SetAdvancedMode {
  public static readonly type = '[USER] Set advanced mode';
  constructor(public mode: boolean) { }
}
