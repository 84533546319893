import { Injectable, OnDestroy } from '@angular/core';
import { PusherActions } from '@core/actions/pusher.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { PusherService } from '@core/services/frontend/pusher.service';
import { NotificationSettingsModel } from '@core/store/notifications/notification-settings.model';
import {
  GetNotificationSuccessAction
} from '@core/store/notifications/notification.actions';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { NotificationDTO } from './dtos/notification-dto';
import { NotificationMapper } from './notification.mapper';
import { NotificationState } from './notification.state';


@Injectable({
  providedIn: 'root'
})
export class NotificationFrontendService implements OnDestroy {

  public settings: NotificationSettingsModel = new NotificationSettingsModel();
  private subHelper: Subscription = new Subscription();
  loadingNotifications = false;
  notificationLimit = 30;

  skip = 100;
  take = 100;
  endReached: boolean;

  public get notifications() { return this.store.selectSnapshot(NotificationState.notifications); }
  public get unreadNotifications() { return this.store.selectSnapshot(NotificationState.unreadNotifications); }
  public unreadNotifications$() { return this.store.select(NotificationState.unreadNotifications); }
  public getNotificationById(notificationId: string) { return this.notifications.find(c => c.NotificationId === notificationId); }

  constructor(
    private store: Store,
    private actions: ActionService,
    private pusherService: PusherService,
    private mapper: NotificationMapper
  ) {
    this.subHelper.add(this.actions.dispatched(PusherActions.SetupPrivateBindings)
      .subscribe(() => this.setupPrivate()));
    this.subHelper.add(this.actions.dispatched(PusherActions.SetupCompanyBindings)
      .subscribe(() => this.setupCompany()));
  }

  public ngOnDestroy() {
    this.subHelper.unsubscribe();
  }

  private setupPrivate() {
    this.pusherService.addPrivateBinding('notification', (notification: NotificationDTO) => {
      this.handleNewNotification(notification);
    });
  }

  private setupCompany() {
    this.pusherService.addCompanyBinding('notification', (notification: NotificationDTO) => {
      this.handleNewNotification(notification);
    });
  }

  private handleNewNotification(notification: NotificationDTO) {
    this.printIf(notification.Message.Type);
    const model = this.mapper.map(notification, true, this.settings);
    this.store.dispatch(new GetNotificationSuccessAction(model));
  }

  toggleSetting(category, setting) {
    this.settings.categories[category][setting] = !this.settings.categories[category][setting];
  }

  findSetting(type: string): boolean {
    let setting = true;
    let match = false;
    const keys = Object.keys(this.settings.categories);
    for (let i = 0, n = keys.length; i < n; i++) {
      if (match) { continue; }
      const settingKeys = Object.keys(this.settings.categories[keys[i]]);
      const settingIndex = settingKeys.indexOf(type);
      if (settingIndex > -1) {
        setting = this.settings.categories[keys[i]][settingKeys[settingIndex]];
        match = true;
      }
    }
    return setting;
  }

  private printIf(blob) {
    if (environment.pusherInfoLogging) {
      console.log(blob);
    }
  }
}



