<indicio-dialog class="export-sa-data-to-macrobond-container">
  <h2 mat-dialog-title>Export seasonally adjusted data
    <div class="subtitle">
      <span class="capitalize">Macrobond export</span>
    </div>
  </h2>
  <mat-dialog-content>
    <ng-container *ngTemplateOutlet="selectLocationTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="selectCategoryTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="primNameTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="includeDataTemplate"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button
            (click)="close()"
            type="button">
      Close
    </button>
    <button mat-raised-button
            (click)="export()"
            [disabled]="saveInProgress"
            type="button"
            color="primary">
      <indicio-spinner *ngIf="saveInProgress"></indicio-spinner>
      Export
    </button>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #selectLocationTemplate>
  <indicio-mb-select-storage-location [storageLocations]="mbService.storageLocations"
                                      [currentLocation]="exportDto.Prefix"
                                      (newLocationEvent)="exportDto.Prefix = $event">
  </indicio-mb-select-storage-location>
</ng-template>

<ng-template #selectCategoryTemplate>
  <indicio-mb-select-category [categories]="categories"
                              [currentCategory]="exportDto.IHCategory"
                              [showLoader]="!initialized"
                              (newCategory)="categories.push($event)"
                              (newCategorySelected)="exportDto.IHCategory = $event">
  </indicio-mb-select-category>
</ng-template>

<ng-template #primNameTemplate>
  <indicio-mb-set-prim-name style="display: block"
                            class="margin-top"
                            [currentPrimName]="exportDto?.PrimName ?? ''"
                            [showLoader]="!initialized"
                            (newPrimName)="exportDto.PrimName = $event">
  </indicio-mb-set-prim-name>
</ng-template>

<ng-template #includeDataTemplate>
  <div class="extra-margin-top italic">
    Select the data you want to export
  </div>
  <hr>
  <ng-container [ngTemplateOutlet]="includeTemplate"
                [ngTemplateOutletContext]="{ title: 'Seasonally adjusted series', key: 'IncludeAdjusted' }"></ng-container>
  <ng-container [ngTemplateOutlet]="includeTemplate"
                [ngTemplateOutletContext]="{ title: 'Seasonal component', key: 'IncludeComponent' }"></ng-container>
  <ng-container *ngIf="data.variable.periodicity.isShorterOrSame('day')"
                [ngTemplateOutlet]="includeTemplate"
                [ngTemplateOutletContext]="{ title: 'Day of year component', key: 'IncludeComponentC1' }"></ng-container>
  <ng-container *ngIf="data.variable.periodicity.isShorterOrSame('day')"
                [ngTemplateOutlet]="includeTemplate"
                [ngTemplateOutletContext]="{ title: 'Day of month component', key: 'IncludeComponentC2' }"></ng-container>
  <ng-container *ngIf="data.variable.periodicity.isShorterOrSame('day')"
                [ngTemplateOutlet]="includeTemplate"
                [ngTemplateOutletContext]="{ title: 'Day of week component', key: 'IncludeComponentC3' }"></ng-container>
  <ng-container [ngTemplateOutlet]="includeTemplate"
                [ngTemplateOutletContext]="{ title: 'Irregular component', key: 'IncludeIrregular' }"></ng-container>
  <ng-container [ngTemplateOutlet]="includeTemplate"
                [ngTemplateOutletContext]="{ title: 'Trend series', key: 'IncludeTrend' }"></ng-container>
  <ng-container [ngTemplateOutlet]="includeTemplate"
                [ngTemplateOutletContext]="{ title: 'Unadjusted series', key: 'IncludeOriginal' }"></ng-container>
</ng-template>

<ng-template #includeTemplate
             let-title="title"
             let-key="key">
  <div class="row"
       style="margin-bottom: 15px; margin-top: 10px;">
    <div class="col-xs-8">
      <div>{{ title }}</div>
    </div>
    <div class="col-xs-4 text-right">
      <mat-slide-toggle [checked]="exportDto[key]"
                        (change)="exportDto[key] = !exportDto[key]">
      </mat-slide-toggle>
    </div>
  </div>
</ng-template>
