import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'propertyValue' })
export class PropertyValuePipe implements PipeTransform {

  constructor() { }

  public transform(value: any, dec?: number): string {
    const num = +value;
    let decimals = 1;
    if (Math.abs(num) <= 10) { decimals = 2; }
    if (dec) { decimals = dec; }

    return this.toDisplayForm(num, decimals);
  }

  public toDisplayWithDecimalPoints(value: any, decimals: number, removeTrailingZeroes?: boolean) {
    return this.toDisplayForm(+value, decimals, removeTrailingZeroes);
  }

  private toDisplayForm = (num, decimals, removeTrailingZeroes = false) => {
    if (num === null) { return null; }
    if (num === 0) { return '0'; }
    const power = num.toPrecision(2).split('e');
    const k = power.length === 1 ? 0 : Math.floor(Math.min(power[1].slice(1), 14) / 3);
    if (k >= 3) { decimals++; }
    if (k > 0 && k < 3) { decimals++; }
    const c = k < 1 ? num.toFixed(decimals) : (num / Math.pow(10, k * 3)).toFixed(decimals);
    const e = c + ['', ' k', ' M', ' B', ' T'][k];

    if (removeTrailingZeroes === true && decimals > 0) {
      const split = e.split('.');
      let d = split.pop();
      const integerPart = split.pop();
      if (/^0*$/.test(d)) {
        return e.split('.').shift();
      }

      while (d.length && d.endsWith('0')) {
        d = d.slice(0, d.length - 1);
      }
      return integerPart + '.' + d;
    }

    return e;
  };

  public toPercent(number): string {
    const num = +number;
    return '' + Math.round(num * 1000) / 10 + ' %';
  }

  public toScenarioDisplay(number) {
    let num = +number;
    if (num > 100) {
      num = Math.round(num);
    } else if (num < 100 && num > 10) {
      num = Math.round(num * 10) / 10;
    } else {
      num = Math.round(num * 1000) / 1000;
    }
    return num;
  }

  public roundNumberToDecimalPrecision(value: number, decimals: number) {
    if (decimals === 0) {
      return Math.round(value);
    }
    if (decimals < 0) { decimals = 1; }
    const fac = Math.pow(10, decimals);
    const v = value * fac;
    const r = Math.round(v);
    return r / fac;
  }
}
