<indicio-modal-base [child]="this"
                    [modalTitle]="'Copy forecast'">

  <form #f="ngForm"
        *ngIf="!isLoading"
        (ngSubmit)="onSubmit()"
        ngNativeValidate>
    <div class="">
      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Forecast name</div>
          <span class="subtitle">The name of your new copy</span>
        </div>
        <div class="col-xs-6"
             style="margin-top:15px">
          <mat-form-field>
            <mat-label>Forecast name</mat-label>
            <input data-test-id='forcast-name-input-field'
                   type="text"
                   class="material"
                   matInput
                   autocomplete="off"
                   [ngModel]="copyDto.Name"
                   (ngModelChange)="changeForecastName($event)"
                   name="Name"
                   maxlength="64"
                   required>
            <mat-hint *ngIf="forecastNameMustChange">
              <span class="error-text">A forecast with that name already exists in the target project.</span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Forecast version</div>
          <span class="subtitle">The version you wish to copy</span>
        </div>
        <div class="col-xs-6"
             style="margin-top:15px">
          <mat-form-field>
            <mat-select [(value)]="copyDto.ForecastVersionId"
                        required
                        placeholder="Pick version">
              <mat-option [value]="version.ForecastVersionId"
                          [title]="'Version '+ version.VersionNumber"
                          *ngFor="let version of forecast.ForecastVersions">{{ 'Version '+ version.VersionNumber }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Target project</div>
          <span class="subtitle">The project you want the copy in</span>
        </div>
        <div class="col-xs-6"
             style="margin-top:15px">
          <mat-form-field>
            <mat-select [value]="copyDto.ProjectId"
                        (selectionChange)="changeProject($event)"
                        required
                        placeholder="Pick project">
              <mat-option [value]="project.ProjectId"
                          [title]="project.Name"
                          *ngFor="let project of projects">{{ project.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Indicators</div>
          <span class="subtitle">{{ copyDto.IncludeIndicators ? 'Copy indicators' : 'Do not copy indicators' }}</span>
        </div>
        <div class="col-xs-6"
             style="margin-top:15px">
          <mat-slide-toggle [checked]="copyDto.IncludeIndicators"
                            (change)="toggleIndicators()">
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Events</div>
          <span class="subtitle">{{ copyDto.IncludeEvents ? 'Copy events' : 'Do not copy events' }}</span>
        </div>
        <div class="col-xs-6"
             style="margin-top:15px">
          <mat-slide-toggle [checked]="copyDto.IncludeEvents"
                            (change)="toggleEvents()">
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Assessments</div>
          <span class="subtitle">{{ copyDto.IncludeAssessments ? 'Copy assessments' : 'Do not copy assessments'
            }}</span>
        </div>
        <div class="col-xs-6"
             style="margin-top:15px">
          <mat-slide-toggle [checked]="copyDto.IncludeAssessments"
                            (change)="toggleAssessments()">
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Scenarios</div>
          <ng-container *ngIf="!nowcastedMainVariable">
            <span class="subtitle">{{ copyDto.IncludeScenarios ? 'Copy scenarios' : 'Do not copy scenarios' }}</span>
          </ng-container>

          <ng-container *ngIf="nowcastedMainVariable">
            <span class="subtitle"
                  style="color: red;">Scenarios can not be copied if the main variable is nowcasted</span>
          </ng-container>
        </div>
        <div class="col-xs-6"
             style="margin-top:15px">
          <mat-slide-toggle [checked]="copyDto.IncludeScenarios"
                            [disabled]="nowcastedMainVariable"
                            (change)="toggleScenarios()">
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="note-box">Note: No results will be copied over, but can be obtained by recalculating</div>
        </div>
      </div>

      <div class="row extra-margin-bottom">
        <div class="col-xs-6">
          <button class="btn outline blue left"
                  type="button"
                  (click)="close()">Cancel</button>
        </div>
        <div class="col-xs-6">
          <button class="btn right"
                  [disabled]="forecastNameMustChange || !copyDto.Name.length"
                  type="submit">
            <span *ngIf="!pending">Copy</span>
            <span *ngIf="pending"
                  class="ion-load-c spinner"></span>
          </button>
        </div>
      </div>
    </div>
  </form>

</indicio-modal-base>
