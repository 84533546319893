import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CreateCompanyDTO } from '@core/store/company/dtos/create-company-dto';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { AdminCompanyService } from '@modules/admin/components/system/companies/company.admin.service';
import { DisplayValue } from '@modules/lang/types/display-value';
import { DateUtils } from '@shared/utils/date.utils';
import { CreateCompanyTabUsersComponent } from './tabs/users/create-company-tab.users.component';

export interface CreateCompanyAdminDialogData { }

export enum AdminCreateCompanyDialogViewIndex {
  'general' = 0,
  'options' = 1,
  'users' = 2,
  'preview' = 3,
}

@Component({
  selector: 'indicio-create-company-admin-dialog',
  templateUrl: 'create-company.admin.dialog.html',
})
export class CreateCompanyAdminDialogComponent extends DialogV2BaseDialog<CreateCompanyAdminDialogComponent> {
  @ViewChild(CreateCompanyTabUsersComponent) usersTab: CreateCompanyTabUsersComponent;

  public static Id: string = 'CreateCompanyAdminDialogComponent';

  // Options
  public viewIndex: number = 0;
  public viewTypes = AdminCreateCompanyDialogViewIndex;

  public supportUsers: { role: string, users: DisplayValue[]; }[] = [];
  public selectedSupporters: DisplayValue[] = [];
  public loadingSupportUsers: boolean = false;

  public company: CreateCompanyDTO = null;
  pending: boolean;

  public get saveDisabled(): boolean {
    const generalOk = this.company.Name && this.company.CountryCode;
    const usersOk = !!this.company.Users.filter(x => x.valid).length;
    if (this.viewIndex === AdminCreateCompanyDialogViewIndex.general) {
      return !generalOk;
    }

    if (this.viewIndex === AdminCreateCompanyDialogViewIndex.preview) {
      return !generalOk || !usersOk;
    }

    return false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CreateCompanyAdminDialogData,
    dialogRef: MatDialogRef<CreateCompanyAdminDialogComponent, CreateCompanyAdminDialogData>,
    public envService: EnvironmentService,
    private companyService: CompanyFrontendService,
    private statusService: StatusService,
    private service: AdminCompanyService,
    private clientService: ClientFrontendService
  ) {
    super(dialogRef);
    this.initialize();
  }

  public backOrCancel() {
    if (this.viewIndex === AdminCreateCompanyDialogViewIndex.general) {
      this.dialogRef.close(false);
      return;
    }
    this.viewIndex--;
  }

  public saveOrNext() {
    if (this.viewIndex === AdminCreateCompanyDialogViewIndex.preview) {
      this.save();
      return;
    }

    if (this.viewIndex + 1 === AdminCreateCompanyDialogViewIndex.preview) {
      this.usersTab.parseUserList();
    }

    this.viewIndex++;

    if (this.viewIndex === AdminCreateCompanyDialogViewIndex.preview) {
      this.company.Users = this.usersTab.additionalUsers;

    }
  }

  protected async initialize() {
    this.company = new CreateCompanyDTO;
    this.company.MaxUsers = 10;
    this.company.Trial = true;
    this.company.TrialExpirationDate = DateUtils.newMoment().add(2, 'w').toISOString();
    // Change this to the users license type
    this.company.LicenseType = this.companyService.activeCompany.LicenseType;
    if (this.clientService.isAdmin) {
      await this.getSupportUsers();
    }
    this.initialized = true;
  }

  private async getSupportUsers() {
    this.supportUsers = await this.service.getSupportUsers()
      .catch(err => {
        this.statusService.setError(err, true);
        return [];
      });
    this.selectedSupporters = this.supportUsers.filter(x => this.company.Support.find(y => x.users.find(z => z.Value === y))).map(x => x.users[0]);
    this.loadingSupportUsers = false;
  }

  public changeView(index: number) {
    this.viewIndex = index;
  }

  private save() {
    this.pending = true;
    this.companyService.createCompany(this.company)
      .then(() => {
        this.close();
      })
      .catch(error => {
        this.statusService.setError(error, true);
      })
      .finally(() => this.pending = false);
  }

}
