<div mat-dialog-content>
  <div class="template-live-view-container">
    <div class="row">
      <div class="col-xs-5">New forecast name:</div>
      <div class="col-xs-7 crop-string">Template: {{ data.template.Title }}</div>
    </div>
    <div class="row">
      <div class="col-xs-5">Periodicity:</div>
      <div class="col-xs-7">{{ data.template.Periodicity }}</div>
    </div>
    <hr>
    <div class="row">
      <div class="col-xs-7 italic">Status</div>
    </div>
    <div class="row">
      <div class="col-xs-5">Create forecast: </div>
      <div class="col-xs-7">{{ statusDto.ForecastStatus }}</div>
    </div>
    <div class="row">
      <div class="col-xs-5">Create main variable:</div>
      <div class="col-xs-7">{{ statusDto.MainVarStatus }}</div>
    </div>
    <div class="row">
      <div class="col-xs-5">Create indicators: </div>
      <div class="col-xs-7">{{ statusDto.IndicatorsStatus }}</div>
    </div>
    <div class="row">
      <div class="col-xs-5">Create historic events: </div>
      <div class="col-xs-7">{{ statusDto.EventStatus }}</div>
    </div>
    <div *ngIf="statusDto.InfoMessages?.length || statusDto.ErrorMessages?.length || statusDto.SuccessMessages?.length">
      <hr>
      <div class="row">
        <div class="col-xs-12 italic">Messages</div>
      </div>
      <div class="row"
           *ngFor="let msg of statusDto.ErrorMessages">
        <div class="col-xs-12"
             style="color: red">{{ msg }}</div>
      </div>
      <div class="row"
           *ngFor="let msg of statusDto.InfoMessages">
        <div class="col-xs-12">{{ msg }}</div>
      </div>
      <div class="row"
           *ngFor="let msg of statusDto.SuccessMessages">
        <div class="col-xs-12"
             style="color: green">{{ msg }}</div>
      </div>
    </div>

  </div>
</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-button
          (click)="onNoClick()">
    Close
  </button>
</div>
