import { Def } from '@core/decorators/def';

export class QuandlDataSerieModel {
  @Def() dataset_data: {
    collapse: any;
    column_index: number;
    column_names: string[];
    data: any[];
    end_date: string;
    frequency: string;
    limit: any;
    order: string;
    start_date: string;
    transform: any;
  };
}
