import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataProvider } from '@core/constants/provider-definitions';
import { OpenIdActions } from '@core/services/open-id/open-id.actions';
import { OpenIdFrontendService } from '@core/services/open-id/open-id.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { MacrobondEntityInfoDTO } from '@core/store/providers/macrobond/dtos/macrobond-entityinfo.dto';
import { MacrobondExportDTO, MacrobondExportModelDTO } from '@core/store/providers/macrobond/dtos/macrobond-export.dto';
import { MacrobondLocationDTO } from '@core/store/providers/macrobond/dtos/macrobond-treelist.dto';
import { CMacrobond } from '@core/store/providers/macrobond/macrobond.constants';
import { MacrobondService } from '@core/store/providers/macrobond/macrobond.service';
import { MacrobondUtils } from '@core/store/providers/macrobond/macrobond.utils';
import { Store } from '@ngxs/store';
import { DateUtils } from '@shared/utils/date.utils';
import { StringUtils } from '@shared/utils/string.utils';

export interface ExportForecastResultDialogData {
  resultFn: () => MacrobondExportModelDTO[];
  forecastVersionId: string;
  includeCi: boolean;
  weighted: boolean;
}

@Component({
  selector: 'indicio-dialog-export-forecast-result',
  templateUrl: 'export-result.dialog.component.html',
  styleUrls: ['export-result.dialog.component.less']
})
export class ExportForecastResultDialogComponent {
  public static Id: string = 'ExportForecastResultDialogComponent';

  /* Properties */
  public title: string = 'Export to Macrobond';

  /* Entities  */
  private forecastVersion: ForecastVersionModel;
  private results: MacrobondExportModelDTO[];

  private get forecastVersionId() { return this.data.forecastVersionId; }
  private get mainVar() { return this.forecastVersion.ForecastVariable; }

  public exportDto = new MacrobondExportDTO();
  public storageLocations: MacrobondLocationDTO[] = [];
  public categories: string[];

  /* Status */
  public initialized: boolean = false;
  public saveInProgress: boolean = false;
  public get exportReady() { return !!this.exportDto.IHCategory && !!this.exportDto.Description && !!this.exportDto.PrimName; }
  public get missingSetupMessage() {
    return 'You must fill in: <br>' +
      (!this.exportDto.IHCategory ? ' - Category selection <br>' : '') +
      (!this.exportDto.Description ? ' - Description <br>' : '') +
      (!this.exportDto.PrimName ? ' - Macrobond id' : '');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: ExportForecastResultDialogData,
    private dialogRef: MatDialogRef<ExportForecastResultDialogComponent>,
    private status: StatusService,
    private store: Store,
    private forecastService: ForecastFrontendService,
    private mbService: MacrobondService,
    private openId: OpenIdFrontendService
  ) {
    this.setup();
  }

  public close(): void {
    this.dialogRef.close(null);
  }

  public export() {
    this.saveInProgress = true;
    this.mbService.exportResult(this.exportDto)
      .then(() => this.status.setMessage('Export to Macrobond successful', 'Success', true))
      .catch(err => this.status.setError(err, true))
      .finally(() => this.saveInProgress = false);
  }

  private setup() {
    this.results = this.data.resultFn();
    Promise.all([
      this.forecastService.getOrFetchForecastVersion(this.forecastVersionId),
      this.openId.getScopes(DataProvider.macrobondapi)
    ])
      .then(([fversion, scopes]) => {
        this.forecastVersion = fversion;
        if (!scopes.includes(CMacrobond.RequiredScopeForExport)) {
          return this.store.dispatch(new OpenIdActions.OpenSetupDialog({
            ButtonText: 'Proceed',
            Title: 'Macrobond export result access',
            Text: 'To enable export of Indicio results to Macrobond, please verify your Macrobond credentials.',
            Provider: DataProvider.macrobondapi
          }));
        }
        this.setupMacrobond();
      })
      .catch(err => this.status.setError(err, true));
  }

  private setupMacrobond() {

    Promise.all([
      this.mbService.getOrFetchStorageLocations(),
      this.mbService.getOrFetchUserCategories(),
    ])
      .then(([locations, categories]) => {
        this.storageLocations = locations;
        this.exportDto.Prefix = locations.find(x => x.Name === 'Personal account').Prefix;
        this.categories = categories;
        this.exportDto.RemoteVariableId = this.mainVar.RemoteVariableId;
        return this.mainVar.Source === DataProvider.macrobondapi;
      })
      .then((proceed: any) => {
        if (!proceed) return proceed;
        return this.mbService.getEntityInfo(this.mainVar.RemoteReferenceId);
      })
      .then(entityInfo => typeof entityInfo !== 'boolean' && this.setupEntityInfo(entityInfo))
      .catch(err => this.mbService.handleError(err))
      .catch(err => this.status.setError(err, true))
      .finally(() => this.initialized = true);
  }

  private setupEntityInfo(info: MacrobondEntityInfoDTO) {
    MacrobondUtils.initExportDTO(this.exportDto, info, 'indicio', 'Forecasted in Indicio');
    if (!!this.exportDto.IHCategory) {
      this.categories.addUniqueId(this.exportDto.IHCategory);
    }

    const historic = this.mainVar.ForecastVariableValues.map(v => ({ D: DateUtils.convertToBackendDate(v.D), V: v.V }));
    this.exportDto.Frequency = StringUtils.capitalizeFirstLetter(DateUtils.convertToLyFormat(this.mainVar.Periodicity));
    this.exportDto.HistoricValues = historic;
    this.exportDto.ForecastId = this.forecastVersion.ForecastId;
    this.exportDto.VersionNumber = this.forecastVersion.VersionNumber;
    this.exportDto.IncludeCi = !!this.data.includeCi;
    this.exportDto.Models = this.results;
  }
}
