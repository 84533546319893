
import { Def } from '@core/decorators/def';
import { SimplePlotValue } from '@core/entities/dtos/plot-value-dto';

export class CreateForecastBenchmarkDTO {
  @Def() SourceVariableId: string;
  @Def() ForecastVersionId?: string;
  @Def() Name: string;
  @Def() Values: SimplePlotValue[];
}
