import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MacrobondLocationDTO } from '@core/store/providers/macrobond/dtos/macrobond-treelist.dto';

@Component({
  selector: 'indicio-mb-select-storage-location',
  template: `
  <mat-form-field>
        <mat-label *ngIf="storageLocations.length > 0">Select storage</mat-label>
        <mat-label *ngIf="storageLocations.length < 1"> <i class="ion-load-c spinner"></i> Loading storage options...</mat-label>
        <mat-select [value]="currentLocation"
                    (selectionChange)="newLocationEvent.emit($event.value)"
                    required>
          <mat-option [value]="location.Prefix"
                      *ngFor="let location of storageLocations">{{ location.Name }}</mat-option>
        </mat-select>
      </mat-form-field>
  `
})
export class MacrobondSelectStorageLocationComponent {

  @Input() storageLocations: MacrobondLocationDTO[] = [];
  @Input() currentLocation: string;

  @Output() newLocationEvent = new EventEmitter<string>();
}
