import { Injectable } from '@angular/core';
import { ForecastResultUsedWarning, ForecastUsedInReportWarning, RemovalWarning } from './entities/removal-warning';
import { RemovalWarningGroup } from './entities/removal-warning-group';
import { RemovalWarningDTO, RemovalWarningType } from './entities/removal-warning.dto';

@Injectable({
  providedIn: 'root'
})
export class RemovalWarningsMapper {

  constructor() { }

  public map(dtos: RemovalWarningDTO[]) {
    const list: RemovalWarningGroup[] = [];

    Object.entries(RemovalWarningType).forEach((key, value) => {
      const group = new RemovalWarningGroup;
      group.Type = value;
      group.Warnings = dtos.filter(dto => dto.Type === value).map(x => this.mapContentType(x));
      if (group.Warnings.length) {
        list.push(group);
      }
    });

    return list;
  }

  private mapContentType(dto: RemovalWarningDTO) {
    switch (dto.Type) {
      case RemovalWarningType.FORECAST_RESULT_USED:
        return new RemovalWarning<ForecastResultUsedWarning>(dto.Type, Object.faMapTo(new ForecastResultUsedWarning, dto.Content));
      case RemovalWarningType.FORECAST_USED_IN_REPORT:
        return new RemovalWarning<ForecastUsedInReportWarning>(dto.Type, Object.faMapTo(new ForecastUsedInReportWarning, dto.Content));
    }
  }
}

