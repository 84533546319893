<div style="display: grid; flex-direction: column; grid-template-rows: auto 1fr auto; height: 100%;">
  <h1 mat-dialog-title>{{ data.Title }}</h1>
  <div mat-dialog-content
       style="max-height: 85vh;">
    <div *ngIf="!mathJax"
         style="margin-bottom: 20px;"
         [innerHTML]="data.Text | safeHtml">
    </div>
    <div *ngIf="mathJax">
      <div [indicioMathJax]="data.Text">
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button
            [mat-dialog-close]="">
      Close
    </button>
  </div>
</div>
