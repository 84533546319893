import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { ProviderDialogService } from '@dialogs/providers/provider-dialogs.service';
import { DisplayValue } from '@modules/lang/types/display-value';
import { BehaviorSubject } from 'rxjs';
import { ProviderVariableDTO } from '../entities/import-provider-entities';
import { BloombergMapper } from './bloomberg.mapper';
import { BloombergCatalogListDTO, BloombergContentDTO } from './entities/bloomberg-catalog.dtos';
import { BloombergCredentialsDTO, BloombergCredentialsInfoDTO } from './entities/bloomberg-credentials.dtos';
import { BloombergDatasetListDTO, BloombergDatasetSnapshotDTO } from './entities/bloomberg-datasets.dtos';
import { BloombergCreateRequestResponseDTO, BloombergFieldDTO, BloombergRequestListDTO, BloombergRequestsContentDTO, CreateRequestDTO, FigiExchangeDTO, FigiResultDTO, OpenFigiSearchDTO } from './entities/bloomberg-requests.dtos';

@Injectable({ providedIn: 'root' })
export class BloombergService {


  private _loading = new BehaviorSubject<boolean>(false);
  public isLoading$() { return this._loading.asObservable(); }
  public setLoading(loading: boolean) { this._loading.next(loading); }

  public exchangeCodes: DisplayValue<string>[] = [];
  public marketSectors: string[] = [];

  // In-memory cache...
  catalogs: BloombergCatalogListDTO = null;

  constructor(
    private http: HttpService,
    private providerDialogs: ProviderDialogService,
    private bloombergMapper: BloombergMapper
  ) { }

  //
  // Credentials
  //
  public hasCredentials() {
    return this.http.get<BloombergCredentialsInfoDTO>('bloomberg/credentials', 'import')
      .then(({ body }) => this.bloombergMapper.mapCredentialsInfo(body));
  }

  public addCredentials(dto: BloombergCredentialsDTO, companyId: string) {
    return this.http.post<boolean>(`company/${companyId}/data-providers/bloomberg/credentials`, dto)
      .then(({ body }) => body);
  }

  public removeCredentials(companyId: string) {
    return this.http.delete<boolean>(`company/${companyId}/data-providers/bloomberg/credentials`)
      .then(({ body }) => body);
  }

  //
  // Catalog
  //
  public getCatalogs() {
    if (this.catalogs != null)
      return Promise.resolve(this.catalogs);

    return this.http.get<BloombergCatalogListDTO>('bloomberg/catalogs', 'import')
      .then(({ body }) => this.catalogs = this.bloombergMapper.mapCatalogs(body));
  }

  public getCatalog(catalog: BloombergContentDTO) {
    return this.http.get<BloombergContentDTO[]>(`bloomberg/catalogs/${catalog.Identifier}`, 'import')
      .then(({ body }) => body.map(c => this.bloombergMapper.mapContent(c, catalog.Identifier)));
  }

  //
  // Requests
  //
  public getRequests(catalogId: string) {
    return this.http.get<BloombergRequestListDTO>(`bloomberg/catalogs/${catalogId}/requests`, 'import')
      .then(({ body }) => this.bloombergMapper.mapRequests(body));
  }

  public getRequest(catalogId: string, requestId: string) {
    return this.http.get<BloombergRequestsContentDTO>(`bloomberg/catalogs/${catalogId}/requests/${requestId}`, 'import')
      .then(({ body }) => body);
  }

  public createRequest(catalogId: string, dto: CreateRequestDTO) {
    return this.http.post<BloombergCreateRequestResponseDTO>(`bloomberg/catalogs/${catalogId}/requests`, dto, 'import')
      .then(({ body }) => body);
  }

  public disableRequest(catalogId: string, requestId: string) {
    return this.http.get<BloombergCreateRequestResponseDTO>(`bloomberg/catalogs/${catalogId}/requests/${requestId}/disable`, 'import')
      .then(({ body }) => body);
  }

  //
  // Datasets
  //
  public getDatasets(catalogId: string) {
    return this.http.get<BloombergDatasetListDTO>(`bloomberg/catalogs/${catalogId}/datasets`, 'import')
      .then(({ body }) => this.bloombergMapper.mapDatasets(body));
  }

  public getDatasetLatestSnapshot(catalogId: string, datasetId: string) {
    return this.http.get<BloombergDatasetSnapshotDTO>(`bloomberg/catalogs/${catalogId}/datasets/${datasetId}`, 'import')
      .then(({ body }) => body);
  }

  public getDatasetSnapshotMeta(catalogId: string, datasetId: string, fileId: string) {
    return this.http.get<ProviderVariableDTO[]>(`bloomberg/catalogs/${catalogId}/datasets/${datasetId}/snapshots/${fileId}/meta`, 'import')
      .then(({ body }) => body);
  }

  public getDatasetSnapshotData(catalogId: string, datasetId: string, fileId: string) {
    return this.http.get<BloombergDatasetListDTO>(`bloomberg/catalogs/${catalogId}/datasets/${datasetId}/snapshots/${fileId}`, 'import')
      .then(({ body }) => this.bloombergMapper.mapDatasets(body));
  }

  //
  // Universes
  //
  public getUniverses(catalogId: string) {
    return this.http.get<BloombergDatasetListDTO>(`bloomberg/catalogs/${catalogId}/universes`, 'import')
      .then(({ body }) => this.bloombergMapper.mapUniverses(body));
  }



  /** Search and find FIGI identifiers (maybe move to its own service later) */
  public searchFigi(dto: OpenFigiSearchDTO) {
    return this.http.post<FigiResultDTO[]>('openfigi/search', dto, 'import')
      .then(({ body }) => body);
  }

  /** Get all availale exhange codes defined and used by OpenFIGI */
  public getExchangeCodes() {
    if (this.exchangeCodes.length > 0)
      return Promise.resolve(this.exchangeCodes);

    return this.http.get<FigiExchangeDTO[]>('openfigi/exchangeCodes', 'import')
      .then(({ body }) => {
        this.exchangeCodes = body.map(x => {
          const result = `${x.Code}` + (!!x.Country?.length ? ` - ${x.Country}` : '');
          return { Value: x.Code, Display: result, Description: x.FullName };
        });
        return this.exchangeCodes;
      });
  }

  /** Get all availale market sectors defined and used by OpenFIGI */
  public getMarketSectors() {
    if (this.marketSectors.length > 0)
      return Promise.resolve(this.marketSectors);

    return this.http.get<string[]>('openfigi/marketSectors', 'import')
      .then(({ body }) => {
        this.marketSectors = body;
        return body;
      });
  }

  /** Search and find Fields */
  public searchFields(query: string) {
    return this.http.get<BloombergFieldDTO[]>('bloomberg/fields/' + encodeURI(query), 'import')
      .then(({ body }) => body);
  }

  /** Open a dialog for manual input of a open-figi ticker dto */
  public openGetManualTickerDialog() {
    return this.providerDialogs.openBloombergManualTickerDialog({}).toPromise();
  }
}
