import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { StatusService } from '@core/services/status/status.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ModalModelComponent } from '@shared/modals/modal.model';

import { MatSelectChange } from '@angular/material/select';
import { SpeedSourceVariableBaseValueDTO } from '@core/store/source-variable/dtos/source-variable-revision.dto';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { SourceVariableRevisionModalOpts } from './source-variable-revision-modal.options';

type ValuePairs = 'Max50' | 'Min50' | 'Value';

@Component({
  selector: 'indicio-source-variable-revision-modal',
  templateUrl: './source-variable-revision-modal.component.html'
})
export class SourceVariableRevisionModalComponent extends ModalModelComponent {

  opts: SourceVariableRevisionModalOpts;
  variable: SourceVariableModel;
  selectedRevisionId: string;

  addedValues: SpeedSourceVariableBaseValueDTO[] = [];
  removedValues: SpeedSourceVariableBaseValueDTO[] = [];
  changedValues: SpeedSourceVariableBaseValueDTO[] = [];

  constructor(
    protected store: Store,
    private status: StatusService,
    public appearance: AppearanceService,
    private sourceService: SourceVariableFrontendService,
  ) {
    super();
  }

  public setOptions(options: SourceVariableRevisionModalOpts) {
    this.opts = options;

    const sPromise = this.sourceService.getOrFetchSourceVariable(this.opts.SourceVariableId);

    Promise.all([sPromise])
      .then(([s]) => {
        this.variable = s;
        this.setupMembers();
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => this.isLoading = false);
  }

  private setupMembers() {
    return this.setRevision(this.variable.Revisions.slice(0).pop().SourceVariableBaseRevisionId);
  }

  public setRevision(revisionId: string) {
    this.isLoading = true;
    this.selectedRevisionId = revisionId;
    const revision = this.variable.Revisions.find(x => x.SourceVariableBaseRevisionId === revisionId);
    this.addedValues = revision.Values.filter(x =>
      !x.O && x.N
    );

    this.removedValues = revision.Values.filter(x =>
      x.O && !x.N
    );

    this.changedValues = revision.Values.filter(x =>
      (x.O && x.N) && (x.O !== x.N)
    );
    this.isLoading = false;
    return [this.addedValues, this.removedValues, this.changedValues];
  }

  public categoryContainsValuePair(values: SpeedSourceVariableBaseValueDTO[]) {
    return values.filter(x => this.containsValuePair(x)).length > 0;
  }

  public containsValuePair(value: SpeedSourceVariableBaseValueDTO) {
    return !!value.N || !!value.O;
  }


  public onSelectChange($event: MatSelectChange) {
    this.setRevision($event.value);
  }

  public selectVersion(_versionId: string) {

  }

}
