import { Def } from '@core/decorators/def';

export class NotificationSettingsModel {
  @Def() Variables: { [key: string]: boolean; };
  @Def() HistoricEvents: { [key: string]: boolean; };
  @Def() Assessments: { [key: string]: boolean; };
  @Def() Files: { [key: string]: boolean; };
  @Def() Forecast: { [key: string]: boolean; };
  @Def() Company: { [key: string]: boolean; };
  @Def() Project: { [key: string]: boolean; };
  @Def() HideSystem: boolean;

  showSeen = false;
  categories = {};

  public get hiddenTopics(): Array<string> {
    const hidden = [];
    Object.keys(this.categories).forEach(category => {
      Object.keys(this.categories[category]).forEach(topic => {
        if (this.categories[category][topic] === false) {
          hidden.push(topic);
        }
      });
    });
    return hidden;
  }

  public toggleShowSeen() {
    this.showSeen = !this.showSeen;
  }
}
