import { FocusInputDirective } from '@shared/directives/focus-input.directive';
import { HoverRowsDirective } from '@shared/directives/hover-rows.directive';
import { ProgressBarDirective } from '@shared/directives/progressbar.directive';
import { AutoFocusInputDirective } from './auto-focus-input';
import { ClickStopPropagationDirective } from './click-stop-propagation';
import { CopyDirective } from './copy.directive';
import { DraggableDirective } from './draggable';
import { MathJaxDirective } from './mathjax.directive';
import { ResizableDirective } from './resizable.directive';
import { ScrollHorizontallyDirective } from './scroll-horizontally';
import { TeleportDirective } from './teleport.directive';
import { TippyDirective } from './tippy.directive';

export { FocusInputDirective } from '@shared/directives/focus-input.directive';
export { HoverRowsDirective } from '@shared/directives/hover-rows.directive';
export { ProgressBarDirective } from '@shared/directives/progressbar.directive';
export { AutoFocusInputDirective } from './auto-focus-input';
export { ClickStopPropagationDirective } from './click-stop-propagation';
export { CopyDirective } from './copy.directive';
export { DraggableDirective } from './draggable';
export { MathJaxDirective } from './mathjax.directive';
export { ResizableDirective } from './resizable.directive';
export { ScrollHorizontallyDirective } from './scroll-horizontally';
export { TeleportDirective } from './teleport.directive';
export { TippyDirective } from './tippy.directive';

export const IndicioSharedDirectives = [
  FocusInputDirective,
  ScrollHorizontallyDirective,
  MathJaxDirective,
  HoverRowsDirective,
  ProgressBarDirective,
  AutoFocusInputDirective,
  ClickStopPropagationDirective,
  TippyDirective,
  TeleportDirective,
  CopyDirective,
  DraggableDirective,
  ResizableDirective
];
