<div class="button-dropdown-container"
     [style]="containerStyle">
  <div class="button-container"
       [style]="containerStyle">
    <button class="btn"
            type="submit"
            (click)="runCallback()"
            [ngClass]="extraClasses">
      {{ buttonDisplay }}
      <span class="desc"
            *ngIf="buttonDescription">{{ buttonDescription }}</span>
    </button>
    <div class="toggler"
         *ngIf="dropdown.hasChildNodes()"
         (click)="toggle()"
         [ngClass]="extraClasses">
      <i class="ion-arrow-down-b"
         *ngIf="!loading"></i>
      <i class="ion-load-c spinner"
         *ngIf="loading"></i>
    </div>
  </div>

  <div class="button-dropdown__menu"
       [hidden]="!open"
       #dropdown>
    <ng-content></ng-content>
  </div>
</div>
