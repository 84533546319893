import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { DeleteFVarModalOptions } from './delete-forecast-variable-modal.options';

export class OpenDeleteForecastVariableModal {
  public static readonly type = '[MODAL] Open delete forecast variable modal';
  data: DeleteFVarModalOptions;
  constructor(
    forecastVariables: ForecastVariableModel[],
    callback?: any
  ) { this.data = { forecastVariables, callback }; }
}
