import { Def } from '@core/decorators/def';

export class InviteDTO {
  @Def() InviteId: string;
  @Def() Email: string;
  @Def() InviteString: string;
  @Def() ProjectRole: string;
  @Def() CompanyRole: string;
  @Def() ProjectId: string;
  @Def() CompanyId: string;
  @Def() Used: boolean;
  @Def() Reminders: number;
}
