
export const ForecastEndpoints = {
  // Forecast
  get: (forecastId) => `forecast/${forecastId}/get-forecast`,
  getMeta: (forecastId) => `forecast/${forecastId}/get-forecast-meta`,
  getMetas: (projectId) => `project/${projectId}/forecast-metas`,
  create: (companyId, projectId) => `company/${companyId}/projects/${projectId}/create-forecast`,
  copy: (companyId) => `company/${companyId}/forecasts/copy-forecast`,
  move: (companyId) => `company/${companyId}/forecasts/move-forecast`,
  remove: (companyId, forecastId) => `company/${companyId}/forecasts/${forecastId}/remove-forecast`,
  removeNowcast: (companyId, forecastId) => `company/${companyId}/forecasts/${forecastId}/remove-nowcast`,
  import: () => 'forecast/import-forecast',
  createNowcast: () => 'forecast/create-nowcast',
  update: (forecastId) => `forecast/${forecastId}/update-forecast`,
  download: (forecastId) => `forecast/${forecastId}/export-forecast`,
  potentialForecasts: (companyId) => `company/${companyId}/forecasts/get-potential-forecasts-for-variable`,
  removalWarnings: (fcId) => `forecast/${fcId}/removal-warnings`,
  recentlyModified: (companyId, count = 5) => `company/${companyId}/recently-modified-forecasts?take=${count}`,
  getForecastCount: (companyId) => `company/${companyId}/forecast-count`,

  // Forecast versions
  deleteVersion: (fVersionId) => `forecast/version/${fVersionId}/delete-forecast-version`,
  getVersion: (fVersionId) => `forecast/version/${fVersionId}/get-forecast-version`,
  getDateInfo: (fVersionId) => `forecast/version/${fVersionId}/dates-info`,
  getVersionDates: (fVersionId) => `forecast/version/${fVersionId}/get-forecast-version-dates`,
  getCanCalc: (fVersionId) => `forecast/version/${fVersionId}/get-can-calc-info`,
  updateVersion: (fVersionId) => `forecast/version/${fVersionId}/update-forecast-version`,
  removeWarnings: (fVersionId) => `forecast/version/${fVersionId}/remove-indicator-warnings`,
  removalWarningsVersion: (fVersionId) => `forecast/version/${fVersionId}/removal-warnings`,

  // Client settings
  settings: (fVersionId) => `forecast/version/${fVersionId}/client-settings`,
  saveSettings: (fVersionId) => `forecast/version/${fVersionId}/client-settings`,

  // Model filters
  filters: (forecastId) => `forecast/${forecastId}/model-filters`,
  saveFilters: (forecastId) => `forecast/${forecastId}/model-filters`,
  removeFilter: (forecastId, filterId) => `forecast/${forecastId}/model-filters/${filterId}`,

  // VS Settings
  vsSettings: (fVersionId) => `forecast/version/${fVersionId}/vs-settings/`,
  saveVsSettings: (fVersionId) => `forecast/version/${fVersionId}/vs-settings/`,

  // Misc
  rQueue: (fVersionId) => `forecast-version/${fVersionId}/request-queue`,
};
