import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { DialogV2BaseService } from '@dialogs/base/dialogs.V2.base-service';
import { take } from 'rxjs/operators';
import { FileDiffDialogComponent, FileDiffDialogData } from './diff/file-diff.dialog.component';
import { FileInfoDialogData, FileInformatinDialogComponent } from './info/file-info.dialog.component';


@Injectable({ providedIn: 'root' })
export class FileDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openFileInfo(data: FileInfoDialogData) {
    const config: MatDialogConfig = { width: '600px', position: { top: '3%' } };
    const cfg = this.getConfig(FileInformatinDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, boolean>(FileInformatinDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }

  public openFileDiff(data: FileDiffDialogData) {
    const config: MatDialogConfig = { width: '856px', position: { top: '3%' } };
    const cfg = this.getConfig(FileDiffDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, boolean>(FileDiffDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }
}
