import { Injectable } from '@angular/core';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastTemplateAction } from '@core/store/forecast-template/forecast-template.actions';
import { ForecastTemplateEventDTO } from '@core/store/forecast-template/forecast-template.model';
import { Store } from '@ngxs/store';
import { PusherActionDTO } from '../dtos/pusher-action-dto';

export type PusherTemplateAction =
  'forecast-template-progress-status'
  ;

@Injectable({
  providedIn: 'root'
})
export class PusherTemplateBehaviourService {

  constructor(
    private store: Store,
    private client: ClientFrontendService
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {

    switch (dto.Action as PusherTemplateAction) {
      case 'forecast-template-progress-status':
        // Ignore action if from other user
        if (dto.ActorId !== this.client.client.ClientId) { return; }
        return this.store.dispatch(new ForecastTemplateAction.ProgressStatus(dto.Obj as ForecastTemplateEventDTO));

      default:
        console.error(`Missing behaviour for action: (${dto.Action})`);
        return;
    }
  }

}
