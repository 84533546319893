<indicio-modal-base [child]="this"
                    modalTitle="Save chart as image"
                    extraClasses="chart-download"
                    [loading]="isLoading">

  <div class="chart-download">
    <div class="row plot">
      <div class="col-xs-12"
           *ngIf="!isLoading"
           style="margin-bottom:10px">
        <indicio-alg [periodicityType]="periodicity"
                     [forecast]="forecast"
                     [forecastVersion]="forecastVersion"
                     [HistoricLine]="historicLine"
                     [chartStyles]="chartStyles"
                     [models]="models || []"
                     [assessments]="assessments"
                     [options]="algOptions"
                     [userDataSettings]="userDataSettings"
                     [graphType]="type"
                     [watermark]="true"
                     [header]="header"
                     [Lines]="Lines"
                     [subheader]="subheader">
        </indicio-alg>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-7 col-no-padding setting"
           [style.marginLeft.px]="algOptions?.axisConfig.yAxisPosition === 'inside' ? 10 : 55">
        <div class="col-xs-5"
             style="margin-left:10px">
          <div class="row">
            <div class="text">Background color:</div>
            <input [colorPicker]="chartStyles.backgroundColor"
                   (colorPickerChange)="updateColor('backgroundColor', $event)"
                   [style.background]="chartStyles.backgroundColor"
                   cpOutputFormat="rgba"
                   cpAlphaChannel="enabled"
                   cpPosition="top"
                   readonly>
          </div>
          <div class="row">
            <div class="text">Overlay color:</div>
            <input [colorPicker]="chartStyles.graphOverlay"
                   (colorPickerChange)="updateColor('graphOverlay', $event)"
                   [style.background]="chartStyles.graphOverlay"
                   [cpDisableInput]="true"
                   cpPosition="top"
                   cpAlphaChannel="enabled"
                   cpOutputFormat="rgba"
                   readonly>
          </div>
          <div class="row">
            <div class="text">Line color:</div>
            <input [colorPicker]="chartStyles.historyLine"
                   (colorPickerChange)="updateColor('historyLine', $event)"
                   [style.background]="chartStyles.historyLine"
                   cpPosition="top"
                   readonly>
          </div>
          <div class="row">
            <div class="text">Border color:</div>
            <input [colorPicker]="chartStyles.borderColor"
                   (colorPickerChange)="updateColor('borderColor', $event)"
                   [style.background]="chartStyles.borderColor"
                   cpPosition="top"
                   readonly>
          </div>
          <div class="row">
            <div class="text">Axis Y line:</div>
            <input [colorPicker]="chartStyles.axisYline"
                   (colorPickerChange)="updateColor('axisYline', $event)"
                   [style.background]="chartStyles.axisYline"
                   cpPosition="top"
                   readonly>
          </div>
          <div class="row">
            <div class="text">Axis X line:</div>
            <input [colorPicker]="chartStyles.axisXline"
                   (colorPickerChange)="updateColor('axisXline', $event)"
                   [style.background]="chartStyles.axisXline"
                   cpPosition="top"
                   readonly>
          </div>
          <div class="row">
            <div class="text"> Axis text:</div>
            <input [colorPicker]="chartStyles.axisText"
                   (colorPickerChange)="updateColor('axisText', $event)"
                   [style.background]="chartStyles.axisText"
                   cpPosition="top"
                   readonly>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="row">
            <div class="text"> Plot width:</div>
            <input type="number"
                   class="size"
                   name="plotWidth"
                   [ngModel]="chartStyles.plotWidth"
                   (change)="updateNumber($event)"
                   placeholder="Plot width"
                   min="50"
                   max="760"
                   required>
          </div>
          <div class="row">
            <div class="text"> Plot height:</div>
            <input type="number"
                   class="size"
                   name="plotHeight"
                   [ngModel]="chartStyles.plotHeight"
                   (change)="updateNumber($event)"
                   placeholder="Plot height"
                   min="50"
                   max="480"
                   required>
          </div>
          <div class="row">
            <div class="text"> Border size:</div>
            <input type="number"
                   class="size"
                   name="borderSize"
                   [ngModel]="chartStyles.borderSize"
                   (change)="updateNumber($event)"
                   placeholder="Border size"
                   min="0"
                   max="10"
                   step="0.25"
                   required>
          </div>
          <div class="row">
            <div class="text"> Border radius:</div>
            <input type="number"
                   class="size"
                   name="borderRadius"
                   [ngModel]="chartStyles.borderRadius"
                   (change)="updateNumber($event)"
                   placeholder="Border radius"
                   min="0"
                   max="20"
                   step="0.25"
                   required>
          </div>
        </div>
      </div>
      <div class="col-xs-4">
        <div class="row themes">
          <div class="col-xs-8">
            <mat-form-field>
              <mat-select placeholder="Select theme"
                          [value]="selectedTheme"
                          required="false"
                          (selectionChange)="setTheme($event.value)"
                          [required]="true">
                <mat-option [value]="theme"
                            *ngFor="let theme of profileService.graphThemes">{{ theme.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-xs-3 manage">
            <button mat-button
                    matSuffix
                    (click)="openSaveThemeDialog()">
              <i class="ion-gear-a"></i>
            </button>
          </div>
        </div>
        <indicio-button-dropdown text="Download (PNG)"
                                 (clickEvent)="saveImage()">
          <ul>
            <li (click)="downloadSVG()">Download (SVG)</li>
          </ul>
        </indicio-button-dropdown>
      </div>
    </div>
  </div>
</indicio-modal-base>
