import { Injectable } from '@angular/core';
import { HttpStatusCodes } from '@core/constants/http-status-codes.constants';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { GroupVariableBackendService } from '@core/store/source-variable/group-variable.backend.service';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import {
  DeleteSourceVariableSuccessAction,
  DeleteSourceVariableVersionSuccessAction, GetAllSourceVariablesSuccessAction,
  GetSourceVariableSuccessAction, SourceVariableActions
} from '@core/store/source-variable/source-variable.actions';
import { SourceVariableBackendService } from '@core/store/source-variable/source-variable.backend.service';
import { SourceVariableModel, SourceVariableViewDTO } from '@core/store/source-variable/source-variable.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Select, Store } from '@ngxs/store';
import { DateUtils } from '@shared/utils/date.utils';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CompanyActions } from '../company/company.actions';
import { TagMetaDTO } from '../tags/dtos/tags.dtos';
import { CreateSourceVariableFromFileDTO } from './dtos/create-source-variable-from-file-dto';
import { ForecastBenchmarkModel } from './forecast-benchmark.model';
import { SourceVariableMapper } from './source-variable.mapper';
import { SourceVariableState } from './source-variable.state';

@Injectable({ providedIn: 'root' })
export class SourceVariableFrontendService {

  @Select(SourceVariableState.sourceVariables)
  public sourceVariables$: Observable<SourceVariableModel[]>;
  public get sourceVariables() { return this.store.selectSnapshot(SourceVariableState.sourceVariables); }
  public get benchmarks() { return this.store.selectSnapshot(SourceVariableState.benchmarks); }
  public get hasAny() { return this.sourceVariables.length !== 0; }
  private _loading = new BehaviorSubject<boolean>(false);
  public fetchInProgress$: Observable<boolean>;
  private subs = new Subscription();
  public viewVariables: SourceVariableViewDTO[] = [];

  public getSourceVariablesByFileId(fileId: string) { return this.sourceVariables.filter(sv => sv.UploadedFileId === fileId); }

  constructor(
    private store: Store,
    private actions: ActionService,
    private backend: SourceVariableBackendService,
    private groupBackend: GroupVariableBackendService,
    private clientService: ClientFrontendService,
    private mapper: SourceVariableMapper,
    private envService: EnvironmentService,
  ) {
    this.fetchInProgress$ = this._loading.asObservable();
    this.addServiceActionListeners();
  }

  public getPeriodicityInfo(sourceVariableId: string, periodicities: PeriodicityType[]) {
    return this.backend.getPeriodicityInfo(this.clientService.activeCompany?.CompanyId, sourceVariableId, periodicities);
  }

  public getOrFetchListView() {
    const ids = this.clientService.activeCompany?.SourceVariableIds;
    const hasAll = ids.every(x => this.viewVariables.map(y => y.SourceVariableId).includes(x));
    if (!hasAll) {
      return this.fetchListView();
    } else {
      this.viewVariables = this.viewVariables.filter(x => ids.includes(x.SourceVariableId));
      return Promise.resolve(this.viewVariables)
        .then(() => this.store.dispatch(new SourceVariableActions.GetListViewSuccessAction(this.viewVariables)))
        .then(() => this.viewVariables);
    }
  }

  public fetchListView() {
    this._loading.next(true);
    return this.backend.getListViewInCompany(this.clientService.activeCompany.CompanyId)
      .then(vars => this.viewVariables = vars)
      .then(() => this.store.dispatch(new SourceVariableActions.GetListViewSuccessAction(this.viewVariables)))
      .then(() => this.viewVariables)
      .finally(() => this._loading.next(false));
  }

  public setManualPeriodicity(variableId: string, p: PeriodicityType) {
    return this.backend.setManualPeriodicity(this.clientService.activeCompany.CompanyId, variableId, p)
      .then(updatedP => {
        const newPeriodicity = this.envService.getPeriodicity(updatedP);
        const svarModel = this.sourceVariables.find(x => x.SourceVariableId === variableId);
        const viewModel = this.viewVariables.find(x => x.SourceVariableId === variableId);
        if (svarModel) {
          svarModel.Periodicity = updatedP;
          svarModel.fetched = false;
          const base = svarModel.getBaseVersion();
          if (base) {
            base.Periodicity = updatedP;
            base.periodicity = newPeriodicity;
          }
        }
        if (viewModel) {
          viewModel.Periodicity = updatedP;
          viewModel.ManualPeriodicity = true;
        }
        return newPeriodicity;
      });
  }

  private addServiceActionListeners() {
    this.subs.add(this.actions.dispatched(CompanyActions.SetActiveCompanySuccess).subscribe((_action: CompanyActions.SetActiveCompanySuccess) => {
      this.getOrFetchSourceVariables();
    }));
    this.subs.add(this.actions.dispatched(SourceVariableActions.FetchSourceVariable).subscribe((_action: SourceVariableActions.FetchSourceVariable) => {
      if (this.clientService.activeCompany.CompanyId !== _action.companyId) { return; }
      this.fetchVariable(_action.sourceId);
    }));
    this.subs.add(this.actions.dispatched(SourceVariableActions.SyncListView).subscribe(() => {
      this.fetchListView();
    }));
    this.subs.add(this.actions.dispatched(DeleteSourceVariableSuccessAction).subscribe((action: DeleteSourceVariableSuccessAction) => {
      this.viewVariables.removeByKey('SourceVariableId', action.SourceVariableId);
    }));
  }

  public getOrFetchSourceVariableVersion(versionId: string): Promise<SourceVariableVersionModel> {
    const c = this.sourceVariables.find(x => !!x.getVersionById(versionId));
    if (!c || !c.fetched) {
      return this.fetchVariable(c.SourceVariableId, c)
        .then(n => n.getVersionById(versionId));
    } else {
      return Promise.resolve(c.getVersionById(versionId));
    }
  }

  public getOrFetchSourceVariable(sourceId: string, checkNeedFetch = true): Promise<SourceVariableModel> {
    const v = this.sourceVariables.find(x => x.SourceVariableId === sourceId);
    if (!v || (!v.fetched && checkNeedFetch)) {
      return this.fetchVariable(sourceId, v);
    } else {
      return Promise.resolve(v);
    }
  }

  public async getOrFetchSourceVariablesInBulk(sourceVariableIds: string[]): Promise<SourceVariableModel[]> {
    const nonExistantVars = sourceVariableIds.filter(x => !this.sourceVariables.findById(x));
    const needFetchVars = this.sourceVariables.filter(x => sourceVariableIds.includes(x.SourceVariableId) && !x.fetched);
    const svars = this.sourceVariables.filter(x => sourceVariableIds.includes(x.SourceVariableId)
      && !nonExistantVars.includes(x.SourceVariableId)
      && !needFetchVars.find(y => y.SourceVariableId === x.SourceVariableId)
    );
    let fetchedVariables = [];

    if (nonExistantVars.length || needFetchVars.length) {
      fetchedVariables = await this.backend.getSourceVariableBulk(this.clientService.activeCompany.CompanyId, nonExistantVars, needFetchVars);
      fetchedVariables.forEach(x => {
        this.store.dispatch(new GetSourceVariableSuccessAction(x));
      });
    }

    return Promise.resolve([...svars, ...fetchedVariables]);
  }

  public getOrFetchSourceVariables() {
    const ids = this.clientService.activeCompany.SourceVariableIds;
    const hasAll = this.sourceVariables.includesAllIds(ids);
    if (!hasAll) {
      this._loading.next(true);
      return this.fetchAllInCompany()
        .finally(() => this._loading.next(false));
    } else {
      return Promise.resolve(this.sourceVariables);
    }
  }

  public fetchAllInCompany(companyId: string = null) {
    return this.backend.getAllUserVariables(companyId ? companyId : this.clientService.activeCompany.CompanyId)
      .then(variables => {
        this.store.dispatch(new GetAllSourceVariablesSuccessAction(variables));
        return variables;
      });
  }

  public fetchVariable(variableId: string, model?: SourceVariableModel) {
    const company = this.clientService.activeCompany;
    return this.backend.getSourceVariable(company.CompanyId, variableId, model)
      .then(variable => {
        if (company.SourceVariableIds.addUniqueId(variableId)) {
          this.store.dispatch(new SourceVariableActions.SyncListView(company.CompanyId));
        }
        this.store.dispatch(new GetSourceVariableSuccessAction(variable));
        return variable;
      })
      .catch(err => {
        if (err.status === HttpStatusCodes.NOT_FOUND || err.status === HttpStatusCodes.FORBIDDEN) {
          this.store.dispatch(new DeleteSourceVariableSuccessAction(variableId));
        }
        return Promise.reject(err);
      });
  }

  public createSourceVariable(variable: SourceVariableVersionModel) {
    return this.backend.createSourceVariable(variable)
      .then(newSourceVariable => {
        this.store.dispatch(new GetSourceVariableSuccessAction(newSourceVariable));
        return newSourceVariable;
      });
  }

  public createSourceVariableFromFile(variable: CreateSourceVariableFromFileDTO) {
    return this.backend.createSourceVariableFromFile(variable)
      .then(newSourceVariable => {
        this.store.dispatch(new GetSourceVariableSuccessAction(newSourceVariable));
        return newSourceVariable;
      });
  }

  public createGroup(variable: SourceVariableModel, identifiers: string[]) {
    return this.groupBackend.createGroupVariable(variable, identifiers)
      .then(groupVariable => {
        this.store.dispatch(new GetSourceVariableSuccessAction(groupVariable));
        return groupVariable;
      });
  }

  public syncVariablesFromRemote(companyId: string, forecastIds: string[]) {
    return this.backend.syncVariablesFromRemote(companyId, forecastIds);
  }

  public syncVariableFromRemote(companyId: string, sourceVariableId: string) {
    return this.backend.syncVariableFromRemote(companyId, sourceVariableId);
  }

  public updateGroup(variable: SourceVariableModel, identifiers: string[]) {
    return this.groupBackend.updateGroupVariable(variable, identifiers)
      .then(groupVariable => {
        this.store.dispatch(new GetSourceVariableSuccessAction(groupVariable));
        return groupVariable;
      });
  }

  public getPreviewGroup(variable: SourceVariableModel, identifiers: string[]) {
    return this.groupBackend.getPreviewGroupVariable(variable, identifiers)
      .then(values => {
        values.forEach(plotValue => DateUtils.MapIHasDate(plotValue));
        return values;
      });
  }

  public editSourceVariable(variable: SourceVariableModel) {
    const dto = this.mapper.toUpdateDTO(variable);
    return this.backend.editSourceVariable(variable.CompanyId, variable.SourceVariableId, dto)
      .then(updated => {
        this.store.dispatch(new GetSourceVariableSuccessAction(variable));
        return updated;
      });
  }

  public deleteSourceVariable(variable: SourceVariableModel) {
    return this.backend.deleteSourceVariable(variable)
      .then(deleted => {
        this.store.dispatch(new DeleteSourceVariableSuccessAction(variable.SourceVariableId));
        return deleted.SourceVariableId;
      });
  }

  public deleteSourceVariableVersion(variable: SourceVariableVersionModel) {
    return this.backend.deleteSourceVariableVersion(variable)
      .then(deleted => {
        this.store.dispatch(new DeleteSourceVariableVersionSuccessAction(variable));
        return deleted.SourceVariableId;
      });
  }

  public isNameConflict(name: string) {
    return this.sourceVariables
      .filter(x => x.CompanyId === this.clientService.activeCompanyId)
      .findIndex(v => v.Name.toLowerCase() === name.toLowerCase()) !== -1;
  }

  public getSourceVariableUsedStatus(variableIds: string[]) {
    return this.backend.getSourceVariableUsedStatus(this.clientService.activeCompany.CompanyId, variableIds);
  }

  /**
 * Benchmarks
 */
  public getActiveForecastBenchmarks(forecastVersionIds: string[]) {
    return this.backend.getActiveForecastBenchmarks(forecastVersionIds)
      .then(active => {
        this.store.dispatch(new SourceVariableActions.GetActiveForecastBenchmarksSuccess(active));
        return active;
      });
  }

  public toggleForecastBenchmark(forecastVersionId: string, benchmarkId: string) {
    return this.backend.toggleForecastBenchmark(forecastVersionId, benchmarkId)
      .then(() => {
        this.store.dispatch(new SourceVariableActions.ToggleForecastBenchmarkSuccess(benchmarkId, forecastVersionId));
      });
  }

  public getOrFetchForecastBenchmark(benchId: string) {
    const bench = this.benchmarks.find(x => x.ForecastBenchmarkId === benchId);
    if (!bench) {
      // return this.backend.getForecastBenchmark(companyId, benchId)
    } else {
      return Promise.resolve(bench);
    }
  }

  public getForecastBenchmarks(variableId: string) {
    return this.backend.getForecastBenchmarks(this.clientService.activeCompany.CompanyId, variableId)
      .then(benchmarks => {
        this.store.dispatch(new SourceVariableActions.GetForecastBenchmarksForVariableSuccess(benchmarks));
        return benchmarks;
      });
  }

  public deleteForecastBenchmark(bench: ForecastBenchmarkModel) {
    return this.backend.deleteForecastBenchmark(this.clientService.activeCompany.CompanyId, bench.ForecastBenchmarkId)
      .then(benchmarks => {
        this.store.dispatch(new SourceVariableActions.DeleteForecastBenchmarksForVariableSuccess(bench.SourceVariableId, bench.ForecastBenchmarkId));
        return benchmarks;
      });
  }

  public createForecastBenchmark(model: ForecastBenchmarkModel, forecastVersionId: string) {
    return this.backend.createForecastBenchmark(this.clientService.activeCompany.CompanyId, model, forecastVersionId)
      .then(benchmark => {
        this.store.dispatch(new SourceVariableActions.GetForecastBenchmarkSuccess(benchmark));
        this.store.dispatch(new SourceVariableActions.CreateForecastBenchmarkSuccess(benchmark, forecastVersionId));
        return benchmark;
      });
  }

  public updateForecastBenchmark(model: ForecastBenchmarkModel) {
    return this.backend.updateForecastBenchmark(this.clientService.activeCompany.CompanyId, model)
      .then(benchmark => {
        this.store.dispatch(new SourceVariableActions.GetForecastBenchmarkSuccess(benchmark));
        this.store.dispatch(new SourceVariableActions.UpdateForecastBenchmarkSuccess(benchmark));
        return benchmark;
      });
  }

  /**
   * TAGS
   */
  public saveSourceVariableTags(variable: SourceVariableModel, updated: TagMetaDTO[]) {
    return this.backend.updateSourceVariableTags(variable.CompanyId, variable.SourceVariableId, updated)
      .then(() => {
        variable.Tags = updated;
        this.store.dispatch(new GetSourceVariableSuccessAction(variable));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return variable;
      });
  }
}
