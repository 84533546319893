import { benchmarkColors, modelColors, scenarioColors } from '@core/constants/color.constants';
import { ModelName } from '@modules/lang/types/model-name';
import * as d3 from 'd3';

export namespace ModelUtils {
  export function getScenarioColorForIndex(idx: number) {
    idx = idx % scenarioColors.length;
    return scenarioColors[idx];
  }

  export function getForecastResultColorForIndex(idx: number) {
    idx = idx % scenarioColors.length;
    return scenarioColors.slice().reverse()[idx];
  }


  export function getColorForIndex(idx: number) {
    idx = idx % modelColors.length;
    return modelColors[idx];
  }

  export function getBenchColorForIndex(idx: number) {
    idx = idx % benchmarkColors.length;
    return benchmarkColors[idx];
  }

  export function getColorForModel(model: ModelName) {
    if (model.Group < modelColors.length) {
      const co = d3.hsl(modelColors[model.Group]);
      switch (model.Sub) {
        case 1:
        case 4:
          return co.brighter(0.3).formatHex();
        case 3:
        case 6:
          return co.darker(0.3).formatHex();
        case 2:
        case 5:
        default:
          return co.formatHex();
      }
    }
    return 'ffffff';
  }

}
