import { Component, Inject } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AvailableForecastInfoDTO, AvailableProjectsInfoDTO } from '@core/store/reports/dtos/company-forcasts.dto';
import { CreateReportEntryDTO } from '@core/store/reports/dtos/report.dtos';
import { ReportModel } from '@core/store/reports/models/report.model';
import { ReportFrontendService } from '@core/store/reports/report.service';
import { DateUtils } from '@shared/utils/date.utils';
import { ReportUtils } from '@shared/utils/report.utils';
import * as moment from 'moment';

export interface ReportAddForecastsDialogData {
  report: ReportModel;
}

export interface AddedForecastSettings {
  ForecastsToAdd: CreateReportEntryDTO[];
}

@Component({
  selector: 'indicio-report-add-forecast-dialog',
  templateUrl: 'report-add-forecast.dialog.html'
})
export class ReportAddForecastsDialogComponent {
  public static Id: string = 'ReportAddForecastsDialogComponent';

  forecastsToAdd: string[] = [];
  startDate: moment.Moment;

  allProjectsAvailable: AvailableProjectsInfoDTO[];

  public loading = true;

  public get forecastToAddCount() {
    if (!this.allProjectsAvailable) { return 0; }
    return this.allProjectsAvailable.reduce((x, y) => x += y.Forecasts.filter(c => !c.disabled).length, 0);
  }

  constructor(
    public dialogRef: MatDialogRef<ReportAddForecastsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportAddForecastsDialogData,
    public env: EnvironmentService,
    private reportService: ReportFrontendService,
    private status: StatusService
  ) {
    this.startDate = DateUtils.newMoment(data.report.StartDate);
    this.reportService.fetchAvailableForecasts().then(projects => {
      this.allProjectsAvailable = projects;
      this.filterAvailableForecasts();
    }).catch(err => {
      this.status.setError(err);
    })
      .finally(() => {
        this.loading = false;
      });
  }

  private filterAvailableForecasts() {
    const r = this.data.report;
    this.allProjectsAvailable.forEach(p => {
      p.Forecasts.forEach(f => f.disabled = !ReportUtils.canAddForecast(r.Template, r.startDate, r.Entries, f));
      p.Forecasts = ReportUtils.orderAvailableReportForecasts(p.Forecasts);
    });
  }

  public setForecasts($event: MatOptionSelectionChange) {
    const source = $event.source;
    if (source.selected) {
      this.forecastsToAdd.push(source.value);
    } else {
      this.forecastsToAdd.splice(this.forecastsToAdd.indexOf(source.value), 1);
    }
  }

  public getForecastsToAddAsDTOS() {
    const allForecasts: AvailableForecastInfoDTO[] = [];
    this.allProjectsAvailable.forEach(p => {
      allForecasts.push(...p.Forecasts);
    });
    const forecastDTOS = this.forecastsToAdd.map(x => {
      const dto = new CreateReportEntryDTO;
      dto.ForecastId = x;
      dto.Name = allForecasts.find(y => y.ForecastId === x).Name;
      return dto;
    });

    return forecastDTOS;
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public save() {
    this.dialogRef.close(this.getForecastsToAddAsDTOS());
  }
}
