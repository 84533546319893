import { IndicioConstants } from '@core/constants/indicio.constants';
import { TwoFactorProviders } from '@core/services/environment/environment.service';

export class AuthToken {
  access_token: string;
  session_id: string;
  refresh_token: string;
  refresh_token_secret: string;
  client_id: string;
  token_expires: Date;
  sys_admin: boolean;
  sysrole: IndicioConstants.SystemRoleType;
  sysperms: string[];
  two_factor_authed: boolean;
  two_factor_enabled: boolean;
  two_factor_method: TwoFactorProviders;
  force_two_factor: boolean;
  force_two_factor_from: Date;
}
