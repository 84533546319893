import { Component } from '@angular/core';
import { NavigateToForecast } from '@core/actions/navigation.actions';
import { IFile } from '@core/interfaces/if-file';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { FileFrontendService } from '@core/store/file/file.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { SourceVariableUsageDTO, UsedInProjectAndForecastDTO } from '@core/store/source-variable/dtos/used-dto';
import { RemovedSourceVariablesInCompanyAction } from '@core/store/source-variable/source-variable.actions';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DeleteMultiFilesModalOptions } from './delete-multiple-files-modal.options';


class UsedAsSourceVariable {
  FileId: string;
  SourceVariables: SourceVariableUsageDTO[];
}

@Component({
  selector: 'indicio-delete-multiple-files-modal',
  templateUrl: './delete-multiple-files-modal.component.html',
  styleUrls: ['./delete-multiple-files-modal.component.less'],
})
export class DeleteUploadedFilesModalComponent extends ModalModelComponent {

  private opts: DeleteMultiFilesModalOptions;
  private sourceVaribles: SourceVariableModel[] = [];
  public files: IFile[] = [];
  public callback: any;
  usedFiles: UsedAsSourceVariable[] = [];

  public gonefishing: boolean;
  public isLoading = true;

  constructor(
    protected store: Store,
    private service: FileFrontendService,
    private status: StatusService,
    public envService: EnvironmentService,
    public appearance: AppearanceService,
    private actions: ActionService,
    private dialog: DialogService,
    private fvarService: ForecastVariableFrontendService,
    private sVarService: SourceVariableFrontendService,
    private variableDialogService: VariableDialogService,
  ) { super(); }

  public setOptions(options: DeleteMultiFilesModalOptions) {
    this.opts = options;
    this.files = options.files;
    this.callback = options.callback;

    if (this.callback) {
      this.onClose = this.callback;
    }

    this.sub.add(this.actions.dispatched(RemovedSourceVariablesInCompanyAction).subscribe(_x => {
      this.syncUsed();
    }));

    this.syncUsed()
      .finally(() => {
        this.isLoading = false;
      });
  }

  private syncUsed() {
    return this.service.fetchUsage(this.files)
      .then(used => {
        const x = [...used.RemoteFileUsage, ...used.UploadedFileUsage];
        this.usedFiles = [];
        x.forEach(variable => {
          this.usedFiles.push(<UsedAsSourceVariable> {
            SourceVariables: variable.SourceVarInfos,
            FileId: variable.FileId
          });
        });
        const sourceIds = this.usedFiles.map(x => x.SourceVariables.map(s => s.SourceVariableId)).flatten();
        this.sVarService.getOrFetchSourceVariablesInBulk(sourceIds)
          .then(svars => this.sourceVaribles = svars);
      });
  }

  public filesAcceptedToBeRemoved() {
    return this.files.filter(v => !this.isUsed(v));
  }

  public filesNotAcceptedToBeRemoved() {
    return this.files.filter(v => this.isUsed(v));
  }

  public forceRemoveFiles() {
    const ref = this.dialog.openConfirmDialog({
      Title: 'Are you sure you want to delete all variables and files?',
      Message: 'This action will delete all forecast variables, source variables including selected files and cannot be undone.',
      CancelText: 'No',
      ConfirmText: 'Delete',
      Style: 'warn'
    });
    ref.subscribe(async (ans) => {
      if (ans) {
        this.pending = true;
        await this.forceRemoveAll();
      }
    });
  }

  private async forceRemoveAll() {
    this.status.setMessage('Removing files...', 'Success', true);
    const sources = this.files.map(file => this.usedIn(file)?.SourceVariables).filter(x => x != null).flatten();
    if (this.sourceVaribles.length === 0) {
      this.sVarService.getOrFetchSourceVariablesInBulk(sources.map(e => e.SourceVariableId))
        .then(svars => {
          this.sourceVaribles = svars;
          this.continueForceRemoveAll(sources);
        });
    } else {
      this.continueForceRemoveAll(sources);
    }
  }

  private async continueForceRemoveAll(sources: SourceVariableUsageDTO[]) {
    const fvar = sources.map(source => source.Usage?.filter(x => !x.IsNowcast) || []).flatten().filter(e => e != null);
    const svar = sources.map(source => this.sourceVaribles.find(x => x.SourceVariableId === source.SourceVariableId));
    for (let i = 0; i < fvar.length; i++) {
      try {
        const usage = fvar[i];
        const deleteFVarReponse = await this.proceedDeletingForecastVariable(usage);
        if (!deleteFVarReponse) {
          this.status.setError(`${usage.ForecastName} cannot be deleted`, true);
          return;
        } else {
          await this.syncUsed();
        }
      } catch (error) {
        this.status.setError(error, true);
        return;
      }
    }
    this.status.setMessage('All forecast variables deleted', 'Success', true);
    for (let i = 0; i < svar.length; i++) {
      try {
        const SVar = svar[i];
        let deleteSVarResponse = null;
        if (SVar != null) {
          deleteSVarResponse = await this.proceedDeletingSourceVariable(SVar);
        }
        if (deleteSVarResponse == null) {
          this.status.setError('Cannot be deleted', true);
          continue;
        } else {
          await this.syncUsed();
        }
      } catch (error) {
        this.status.setError(error, true);
        return;
      }
    }
    this.status.setMessage('All source variables deleted', 'Success', true);
    this.removeFiles();
  }

  public async removeFiles() {
    const filesToBeRemoved = this.filesAcceptedToBeRemoved();
    const count = filesToBeRemoved.length;
    this.pending = true;
    this.gonefishing = true;

    const removeFunc = async (currentFile) => {
      let call;
      currentFile.removedStatus = 'Removing';
      try {
        if (currentFile.UploadedFileId) {
          call = this.service.deleteFile(currentFile);
        } else if (currentFile.RemoteFileId) {
          call = this.service.deleteRemoteFile(currentFile);
        }
        await call;
        currentFile.removedStatus = 'Success';
      } catch (e) {
        currentFile.removedStatus = 'Failed';
        currentFile.errorStatus = e.error;
      }
    };

    let current = 0;

    const queueFunc = async () => {
      if (current >= count) {
        this.pending = false;
        return;
      }
      await removeFunc(filesToBeRemoved[current]);
      current += 1;
      setTimeout(async () => {
        await queueFunc();
      }, 20);
    };

    await queueFunc();
  }

  public isUsed(file: IFile) {
    return !!this.usedFiles.find(uv => uv.FileId === file.getModelId());
  }

  public usedIn(file: IFile) {
    if (this.isUsed(file)) {
      return this.usedFiles.find(uv => uv.FileId === file.getModelId());
    }
    return null;
  }

  public navigateToForecast(usage: UsedInProjectAndForecastDTO) {
    this.close();
    this.store.dispatch(new NavigateToForecast(usage.ForecastId));
  }

  public deleteForecastVariable(usage: UsedInProjectAndForecastDTO) {
    const ref = this.dialog.openConfirmDialog({
      Title: 'Delete forecast variable?',
      Message: `Do you want to remove the variable ${usage.ForecastVariableName}<br><br>` +
        `Project: ${usage.ProjectName}<br>` +
        `Forecast: ${usage.ForecastName}<br>`,
      CancelText: 'No',
      ConfirmText: 'Delete',
      Style: 'warn'
    });
    ref.subscribe(ans => {
      if (ans) {
        this.proceedDeletingForecastVariable(usage)
          .then(() => {
            this.status.setMessage('Forecast varible deleted', 'Success', true);
            this.syncUsed();
          })
          .catch(err => this.status.setError(err, true));
      }
    });
  }

  public proceedDeletingForecastVariable(usage: UsedInProjectAndForecastDTO) {
    return this.fvarService.deleteForecastVariable(usage.ForecastVariableId, usage.ForecastVersionId);
  }

  public deleteSourceVariable(source: SourceVariableUsageDTO) {
    const ref = this.dialog.openConfirmDialog({
      Title: 'Delete source variable?',
      Message: `Do you want to remove the source variable ${source.SourceVariableName}<br><br>`,
      CancelText: 'No',
      ConfirmText: 'Delete',
      Style: 'warn'
    });
    ref.subscribe(ans => {
      if (ans) {
        const svar = this.sourceVaribles.find(x => x.SourceVariableId === source.SourceVariableId);
        if (svar) {
          this.proceedDeletingSourceVariable(svar)
            .then(() => this.syncUsed())
            .catch(err => this.status.setError(err, true));
        } else {
          this.status.setMessage('Could not find source variable', 'Warning', true);
        }
      }
    });
  }

  public proceedDeletingSourceVariable(sourceVar: SourceVariableModel) {
    return this.sVarService.deleteSourceVariable(sourceVar);
  }

  public openSourceVariableModal(source: SourceVariableUsageDTO) {
    this.close();
    this.variableDialogService.openSourceVariableInfo({
      sourceVariableId: source.SourceVariableId,
      forecastId: this.opts.forecastId,
      forecastVersionId: this.opts.forecastVersionId
    });
  }
}


