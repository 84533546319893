
import { Component, HostListener, OnDestroy } from '@angular/core';
import { Def } from '@core/decorators/def';
import { PropertyValuePipe } from '@shared/modules/pipes/property-value.pipe';
import { StateUtils } from '@shared/utils/state.utils';
import { Subscription } from 'rxjs';


@Component({
  template: 'Empty'
})
export abstract class ModalModelComponent implements OnDestroy {

  @Def() CAN_OPEN: boolean = true;

  public modalState = new StateUtils.StateHelper();
  public valuePipe: PropertyValuePipe = null;
  public isLoading: boolean;
  public pending: boolean = false;
  protected sub: Subscription = new Subscription();
  protected subscriptionHelper = new Subscription();
  protected _onDestroy() { }

  constructor() {
    this.valuePipe = new PropertyValuePipe();
    this.isLoading = true;
  }

  public ngOnDestroy() {
    this._onDestroy();
    this.subscriptionHelper.unsubscribe();
    this.sub.unsubscribe();
  }

  @HostListener('document:keyup.escape', ['$event'])
  handleKeyboardEvent() { this.close(); }

  public addSubscription(subscription: Subscription) {
    this.subscriptionHelper.add(subscription);
  }

  public abstract setOptions(options: any);
  public close() { }
  public onClose() { }
  public proceed?() { }
}
