import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WikiService } from '../../wiki.service';

@Component({
  selector: 'indicio-wiki-modal-row',
  templateUrl: './wiki-modal-row.component.html',
  styleUrls: ['./wiki-modal-row.component.less'],
})
export class WikiModalRowComponent implements OnChanges {

  @Input() public title: string;
  @Input() public slug: string;

  public show: boolean = false;

  constructor(private wikiService: WikiService) { }

  public ngOnChanges(changes: SimpleChanges): void {

    if (!!this.slug && this.wikiService.exists(this.slug)) {
      this.show = true;
    }
  }

  public open() {
    this.wikiService.open(this.slug);
  }
}
