import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export enum SanitizeType {
  HTML = 'html',
  STYLE = 'style',
  SCRIPT = 'script',
  URL = 'url',
  RESOURCE_URL = 'resourceUrl'
}
@Injectable({ providedIn: 'root' })
@Pipe({ name: 'safeHtml' })
export class SanitizerPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  public transform(stringContent: string, type: SanitizeType = SanitizeType.HTML): any {
    switch (type) {
      case SanitizeType.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(stringContent);
      case SanitizeType.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(stringContent);
      case SanitizeType.SCRIPT:
        return this.sanitizer.bypassSecurityTrustScript(stringContent);
      case SanitizeType.URL:
        return this.sanitizer.bypassSecurityTrustUrl(stringContent);
      case SanitizeType.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(stringContent);
      default:
        throw new Error(`Unable to bypass security for invalid type (${type})`);
    }
  }
}

