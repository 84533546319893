import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { AssessmentDTO } from '@core/store/assessment/dtos/assessment-dto';
import { CreateAssessmentDTO } from '@core/store/assessment/dtos/create-assessment-dto';
import { SetAssessmentOverviewInfoDTO } from '@core/store/assessment/dtos/set-assessment-overview-dto';
import { UpdateAssessmentDTO } from '@core/store/assessment/dtos/update-assessment-dto';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable({
  providedIn: 'root'
})
export class AssessmentMapper {

  constructor(
    private envService: EnvironmentService
  ) { }

  public map(dto: AssessmentDTO): AssessmentModel {
    const model = Object.faMapTo(new AssessmentModel(), dto);
    model.TimePoint = DateUtils.newDate(dto.TimePoint);
    model.Name = `${DateUtils.convertToBackendDate(model.TimePoint)}: ${model.Category}`;
    model.moment = DateUtils.newMoment(model.TimePoint);
    model.assessmentUnit = this.envService.getAssessmentUnit(dto.Unit);
    return model;
  }

  public toCreate(model: AssessmentModel): CreateAssessmentDTO {
    const dto = new CreateAssessmentDTO();
    Object.assign(dto, model);
    dto.TimePoint = DateUtils.newMoment(model.TimePoint).format('YYYY-MM-DD');
    return dto;
  }

  public toUpdate(model: AssessmentModel): UpdateAssessmentDTO {
    const dto = new UpdateAssessmentDTO();
    Object.assign(dto, model);
    return dto;
  }

  public toUpdateOverview(model: AssessmentModel): SetAssessmentOverviewInfoDTO {
    const dto = new SetAssessmentOverviewInfoDTO();
    Object.assign(dto, model);
    return dto;
  }
}
