import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ReportModel } from '@core/store/reports/models/report.model';
import { DateUtils } from '@shared/utils/date.utils';
import * as moment from 'moment';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface ReportSettingsDialogData {
  report: ReportModel;
}

export interface UpdatedReportSettings {
  Name: string;
}

@Component({
  selector: 'indicio-report-settings-dialog',
  templateUrl: 'report-settings.dialog.html',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class ReportSettingsDialogComponent {
  public static Id: string = 'ReportSettingsDialogComponent';

  endDate = DateUtils.newMoment().add(8, 'years');
  startDateModel: moment.Moment = null;
  disableUntil: Date = DateUtils.newMoment().subtract(10, 'years').toDate();
  disableSince: Date = this.endDate.toDate();

  date: UntypedFormControl;
  newDate: moment.Moment;

  newName: string;

  public get updatedSettings(): UpdatedReportSettings {
    return {
      Name: this.newName
    };
  }

  constructor(
    public dialogRef: MatDialogRef<ReportSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportSettingsDialogData,
    public env: EnvironmentService,
    private clientService: ClientFrontendService
  ) {
    this.date = new UntypedFormControl(DateUtils.newMoment(data.report.StartDate));
    this.newName = data.report.Name;
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public save() {
    this.dialogRef.close(this.data.report);
  }
}
