import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigateToLogin } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CreateProfileDTO } from '@core/store/client/dtos/create-profile-dto';
import { Country } from '@modules/lang/types/country';
import { Store } from '@ngxs/store';
import { OpenGdprModal } from '@shared/modals/gdpr/gdpr-text-modal.action';
import { SecurityUtils } from '@shared/utils/security.utils';

const links = require('@root/src/links.json');

@Component({
  selector: 'indicio-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.less']
})
export class SignupComponent implements OnInit {

  activation = true;
  agreedGDPR = false;
  codeProvided = false;
  codeStatus = '';
  codeValid = true;
  inviteValid = false;
  path: string;
  tokenExists = false;
  showPass = false;
  passwordStrength = -1;
  password1: string = null;
  password2: string = null;
  chosenCountry: string = '';
  profile: CreateProfileDTO = new CreateProfileDTO();
  inProgress = false;

  public get disableSubmit() {
    return !this.agreedGDPR || !this.profile.CountryCode || !this.profile.FirstName || !this.profile.LastName;
  }

  public get getErrorText() {
    if (!this.agreedGDPR) {
      return 'You must accept the terms and conditions (GDPR)';
    }
    if (!this.profile.CountryCode) {
      return 'You must select a country from the dropdown (make sure to click on the desired country)';
    }
    if (!this.profile.FirstName) {
      return 'You must supply a first name';
    }
    if (!this.profile.LastName) {
      return 'You must supply a last name';
    }
    return null;
  }

  constructor(
    private store: Store,
    private router: Router,
    private authService: AuthFrontendService,
    private status: StatusService,
    public envService: EnvironmentService
  ) {
  }

  public ngOnInit() {
    this.path = this.router.url;
    this.profile.Username = 'Username';
    this.profile.Code = this.path.replace(/.+signup\/?/g, '');
    if (this.profile.Code.length === 36) {
      this.codeProvided = true;
      this.activateInvite();
    }
  }

  setCountry(country: Country) {
    this.profile.CountryCode = country.Value;
    this.chosenCountry = country.Display;
  }

  public showPassword() {
    this.showPass = !this.showPass;
  }

  public checkPassword() {
    const passwordCategory = SecurityUtils.StrengthChecker(this.password1);
    this.passwordStrength = passwordCategory;
  }

  public addGeneratedPassword() {
    const newpassword = SecurityUtils.generatePassword();
    this.password1 = newpassword;
    this.password2 = newpassword;
    this.checkPassword();
  }

  public changeGDPRStatus() {
    this.agreedGDPR = !this.agreedGDPR;
  }

  public changeCountry(countryCode: string) {
    this.profile.CountryCode = countryCode;
  }

  public readGDPR() {
    this.store.dispatch(new OpenGdprModal());
  }

  public activateInvite() {
    if (this.profile.Code.length !== 36) {
      this.codeValid = false;
      this.status.setMessage('Code invalid or already used', 'Error');
      return;
    }

    this.status.setMessage('Checking code...', 'Info');

    this.authService.checkInviteCode(this.profile.Code)
      .then(validCode => {
        this.profile.Username = validCode.body.Email;
        this.codeValid = true;
        this.codeProvided = true;
        this.status.setMessage('Code Valid', 'Success');
      })
      .catch(() => {
        this.codeValid = false;
        this.status.setMessage('Code invalid or already used', 'Error');
      });
  }

  public backTologin() {
    this.store.dispatch(new NavigateToLogin());
  }

  public update() {
    this.activateInvite();
  }

  public createProfile(values, _$event) {
    const pwCtrl = SecurityUtils.checkPass(this.password1, this.password2);
    if (pwCtrl) {
      this.status.setMessage(pwCtrl, 'Warning');
      return;
    }

    if (!this.agreedGDPR) {
      this.status.setMessage('You must agree to GDPR.', 'Warning');
      return;
    }

    this.profile.Password = this.password1;
    this.inProgress = true;

    // Create User
    this.authService.signup(this.profile)
      .then(() => {
        this.status.setMessage('Account created. Redirecting to login.', 'Success');
        this.store.dispatch(new NavigateToLogin());
      })
      .catch(clientNotCreated => {
        this.status.setError(clientNotCreated);
      })
      .finally(() => this.inProgress = false);
  }

  accessPrompt() {
    window.location.href = links.BetaProgram;
  }
}
