import { AssessmentModel } from '@core/store/assessment/assessment.model';

export class GetAssessmentSuccessAction {
  public static readonly type = '[Assessment] Get assessment success';
  constructor(public assessment: AssessmentModel) { }
}

export class GetAssessmentsInForecastVersionSuccessAction {
  public static readonly type = '[Assessment] Get all assessments in forecast success';
  constructor(public forecastVersionId: string, public assessments: AssessmentModel[]) { }
}

export class RemoveAssessmentSuccessAction {
  public static readonly type = '[Assessment] Remove assessment success';
  constructor(public forecastVersionId: string, public assessmentId: string) { }
}
