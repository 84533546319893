import { ScenarioDTO } from './dtos/scenario.dtos';

export namespace ScenarioAction {
  export class GetSuccess {
    public static readonly type = '[SCENARIOS] Get scenario success';
    constructor(public scenario: ScenarioDTO) { }
  }

  export class GetAllSuccess {
    public static readonly type = '[SCENARIOS] Get all scenarios in fcast success';
    constructor(public forecastVersionId: string, public scenarios: ScenarioDTO[]) { }
  }

  export class CreateOrUpdateSuccess {
    public static readonly type = '[SCENARIOS] Create or update scenario success';
    constructor(public scenario: ScenarioDTO) { }
  }

  export class DeleteSuccess {
    public static readonly type = '[SCENARIOS] Delete scenarios success';
    constructor(public scenarioId: string, public forecastVersionId: string) { }
  }

  export class TriggerSuccess {
    public static readonly type = '[SCENARIOS] Trigger scenarios success';
    constructor(public scenarioId: string) { }
  }

  export class ClearResults {
    public static readonly type = '[SCENARIOS] Clear scenario success';
    constructor(public forecastVersionId: string) { }
  }

  export class OldImported {
    public static readonly type = '[SCENARIOS] Old scenario imported success';
    constructor(public forecastVersionId: string) { }
  }

  export class OldRemoved {
    public static readonly type = '[SCENARIOS] Old scenario removed success';
    constructor(public forecastVersionId: string) { }
  }
}
