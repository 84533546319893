import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Directive({
  selector: '[indicioProgressBar]'
})
export class ProgressBarDirective implements OnChanges, OnInit {

  @Input() progress: any;
  @Input() step: number;

  svg;
  defs;
  options: any;
  progressLength: any = [];
  radius = 20;

  constructor(
    private el: ElementRef,
  ) {
    this.svg = d3.select(this.el.nativeElement).append('svg');
    this.defs = this.svg.append('defs');
  }

  ngOnChanges() {
    this.options = {
      width: this.el.nativeElement.clientWidth * 0.52 + this.progress.labels.length * 90,
      height: 100,
      offset: this.el.nativeElement.clientWidth / this.progress.labels.length
    };
    this.drawClipPath();
  }

  ngOnInit() {
    this.progressLength = Array(this.progress.labels.length);
    this.calcProgressLength();
    this.options = {
      width: this.el.nativeElement.clientWidth * 0.52 + this.progress.labels.length * 90,
      height: 100,
      offset: this.el.nativeElement.clientWidth / this.progress.labels.length
    };
    const options = this.options;
    this.svg.attr('width', options.width).attr('height', options.height);

    this.defs.append('clipPath')
      .attr('id', 'progress-clip')
      .append('rect')
      .attr('width', 84)
      .attr('height', options.height)
      .style('transition', 'width 0.3s ease');

    this.defs.append('mask')
      .attr('id', 'progress-mask')
      .append('path')
      .attr('d', lineFunction(this.progress.labels, this.radius))
      .style('stroke', 'none')
      .style('fill', 'white').attr('clip-path', 'url(#progress-clip)');


    function lineFunction(data, radius) {
      radius = radius || 30;
      let str = 'M60,60';
      str += 'm-' + radius + ',-30';

      for (let i = 0; i < data.length; i++) {
        if (i === 0 || i === data.length - 1) {
          str += 'a' + radius + ', ' + radius + ' 0 0,1 ' + ((radius * 2) - 0.5) + ',0';
        } else {
          str += 'a' + (radius * 0.8) + ', ' + (radius * 0.8) + ' 0 0,1 ' + (((radius * 0.8) * 2) - 0.5) + ',0';
        }
        if (i !== data.length - 1) {
          str += 'l ' + (options.offset - (radius * 1.6)) + ',0';
        }
      }
      str += 'c0,0 0.5,3 0,6';
      for (let i = 0; i < data.length; i++) {
        if (i === 0 || i === data.length - 1) {
          str += 'a' + radius + ', ' + radius + ' 0 0,1 -' + ((radius * 2) - 0.5) + ',0';
        } else {
          str += 'a' + (radius * 0.8) + ', ' + (radius * 0.8) + ' 0 0,1 -' + (((radius * 0.8) * 2) - 0.5) + ',0';
        }
        if (i !== data.length - 1) {
          str += 'l -' + (options.offset - (radius * 1.6)) + ',0';
        }
      }
      str += 'c0,0 -0.5,-3 0,-6';
      return str;
    }

    const innerFill = this.svg.append('path');
    innerFill.attr('d', lineFunction(this.progress.labels, this.radius))
      .style('stroke', 'none')
      .style('fill', 'white')
      .attr('clip-path', 'url(#progress-clip)');

    const innerPath = this.svg.append('path');
    innerPath.attr('d', lineFunction(this.progress.labels, this.radius))
      .style('stroke', '#6388D0')
      .style('stroke-width', '4px')
      .style('fill', 'none')
      .attr('mask', 'url(#progress-mask)');

    const path = this.svg.append('path');
    path.attr('d', lineFunction(this.progress.labels, this.radius)).style('stroke', 'white').style('fill', 'none');

    this.svg.append('g').selectAll('text').data(this.progress.labels).enter().append('text')
      .attr('text-anchor', 'middle')
      .attr('y', 75)
      .attr('x', function (_d, i) { return options.offset * i + 60; })
      .text(function (d) { return d; })
      .style('font-family', 'Roboto')
      .style('fill', 'white')
      .style('text-transform', 'uppercase')
      .style('font-size', '10px')
      .style('font-weight', '700');
    this.drawClipPath();
  }

  private calcProgressLength() {
    const data = this.progress.labels;
    const radius: number = this.radius;
    this.progressLength[0] = 38 + radius * 2;
    for (let i = 1; i < data.length - 1; i++) {
      this.progressLength[i] = this.progressLength[i - 1] + this.options.offset;
    }
    this.progressLength[data.length - 1] = (this.progressLength[data.length - 2] + this.options.offset + radius * 0.35);
  }

  private drawClipPath() {
    this.defs.select('#progress-clip rect').attr('width', this.progressLength[this.step]);
  }
}
