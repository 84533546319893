import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { DialogV2BaseService } from '@dialogs/base/dialogs.V2.base-service';
import { take } from 'rxjs/operators';
import { CreateMultipleForecastsDialogComponent, CreateMultipleForecastsDialogComponentData } from './create-multiple/create-multiple-forecasts.dialog';
import { CreateForecastDialogComponent, CreateForecastDialogData } from './create/create.dialog';
import { ScenarioDialogComponent, ScenarioDialogData } from './scenario/scenario.dialog';
import { ForecastSettingsDialogComponent } from './settings/settings.dialog';
import { ForecastSettingsDialogData } from './settings/settings.dialog.constants';


@Injectable({ providedIn: 'root' })
export class ForecastDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openCreate(data: Partial<CreateForecastDialogData>) {
    const config: MatDialogConfig = { width: '450px', position: { top: '6%' } };
    const cfg = this.getConfig(CreateForecastDialogComponent.Id, data, config);
    const ref = this.dialog.open<CreateForecastDialogComponent, any, any>(CreateForecastDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }

  public openCreateMultipleForecasts(data: CreateMultipleForecastsDialogComponentData) {
    const config: MatDialogConfig = { width: '750px', position: { top: '6%' } };
    const cfg = this.getConfig(CreateMultipleForecastsDialogComponent.Id, data, config);
    const ref = this.dialog.open<CreateMultipleForecastsDialogComponent, any, any>(CreateMultipleForecastsDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }

  public openScenario(data: ScenarioDialogData) {
    const config: MatDialogConfig = { width: '950px', position: { top: '6%' } };
    const cfg = this.getConfig(ScenarioDialogComponent.Id, data, config);
    const ref = this.dialog.open<ScenarioDialogComponent, any, any>(ScenarioDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }

  public openSettings(data: ForecastSettingsDialogData) {
    const config: MatDialogConfig = { width: '790px', position: { top: '3%' } };
    const cfg = this.getConfig(ForecastSettingsDialogComponent.Id, data, config);
    const ref = this.dialog.open<ForecastSettingsDialogComponent, any, any>(ForecastSettingsDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }
}
