import { BloombergDatasetContentDTO } from '@core/store/providers/bloomberg/entities/bloomberg-datasets.dtos';
import { DisplayValue } from '@modules/lang/types/display-value';

export interface BloombergCreateRequestDialogData {
  dataset: BloombergDatasetContentDTO;
  catalogId: string;
  timeZone: string;
}

export type BloombergRecurrenceOptionType = 'daily' | 'weekday' | 'weekend' | 'weekly' | 'monthly';

export const BloombergRecurrenceOptions = [
  new DisplayValue<BloombergRecurrenceOptionType>('daily', 'Daily'),
  new DisplayValue<BloombergRecurrenceOptionType>('weekday', 'Weekday'),
  new DisplayValue<BloombergRecurrenceOptionType>('weekend', 'Weekend'),
  new DisplayValue<BloombergRecurrenceOptionType>('weekly', 'Weekly'),
  new DisplayValue<BloombergRecurrenceOptionType>('monthly', 'Monthly')
];
