import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { DialogV2BaseService } from '@dialogs/base/dialogs.V2.base-service';
import { take } from 'rxjs/operators';
import { ClientSettingsDialogComponent } from './settings/settings.dialog';
import { ClientSettingsDialogData } from './settings/settings.dialog.constants';


@Injectable({ providedIn: 'root' })
export class ClientDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openClientSettings(data: ClientSettingsDialogData) {
    const config: MatDialogConfig = { width: '400px', position: { top: '3%' } };
    const cfg = this.getConfig(ClientSettingsDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, boolean>(ClientSettingsDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }
}
