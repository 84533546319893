import { InfoModalOpts } from './information-modal.options';

export class OpenInformationModal {
  public static readonly type = '[MODAL] Open info modal';
  data: InfoModalOpts;
  constructor(
    title: string,
    mainMessage: string,
    confirm: string,
    tableInfo: {
      'colName': string;
      'colInfo': string;
    }[],
    textRight = false,
    subMessage?: string,
    leftBtn?: {
      fn: () => void;
      title: string;
    }
  ) {
    this.data = { title, mainMessage, confirm, tableInfo, textRight, subMessage, leftBtn };
  }
}
