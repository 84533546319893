import { Component, Input } from '@angular/core';

@Component({
  selector: 'indicio-text-logo',
  templateUrl: './text-logo.component.html',
  styles: [`
  .indicio-text-logo {
    width: 265px;
    fill: var(--indicio-main-text-color, #333);
    stroke: var(--indicio-main-text-color, #333)
  }
`]
})
export class IndicioTextLogoComponent {
  @Input() style: string;
}
