<indicio-dialog>
  <h1 mat-dialog-title>Import {{ multipleVariables ? 'multiple variables' : 'variable' }} from Macrobond</h1>
  <div mat-dialog-content
       class="macrobond-import-container"
       style="--grid-template-global: {{ multipleVariables ? '250px 1fr' : '1fr' }};"
       *ngIf="initialized">

    <ng-container *ngIf="multipleVariables">
      <div class="headers grid-system">
        <div>Variables in this request</div>

        <div style="padding-left: 10px;">
          Information about the selected variable
        </div>
      </div>
    </ng-container>

    <div class="grid-system"
         style="border-bottom: 1px solid var(--indicio-modal-accent-color, rgba(0, 0, 0, 0.4));"
         *ngIf="activeEntity && activeRequest; else loadingTemplate">

      <div class="variable-list"
           *ngIf="multipleVariables">
        <ng-container [ngTemplateOutlet]="variableList"></ng-container>
      </div>

      <div class="variable-info"
           [ngClass]="{ 'single': !multipleVariables }">
        <ng-container [ngTemplateOutlet]="variableInfoTemplate"></ng-container>
      </div>
    </div>

    <ng-container *ngIf="activeEntity && activeRequest">
      <div class="grid-system"
           style="--grid-template: {{ multipleVariables ? '1fr 0.5fr' : '1fr' }}; margin-top: 10px;">
        <div class="error-summary"
             *ngIf="multipleVariables">
          <ng-container *ngIf="!imported">
            <div *ngIf="errorVariables.length">
              <i class="ion-alert-circled red"></i>*{{ errorVariables.length }}
            </div>
            <div *ngIf="alertVariables.length">
              <i class="ion-alert-circled orange"></i>*{{ alertVariables.length }}
            </div>
          </ng-container>
        </div>
        <div class="text-right">
          <span class="dotted-underline cursor-pointer"
                indicioTippy="{{ newSourceVariables === 0 ? 'No source variables will be created' : importedAsSource + '/'+ newSourceVariables + ' source variables created'}}">
            {{ importedToForecast }}/{{ importableVariables.length }}</span> {{ multipleVariables ? 'variables' :
          'variable' }} imported to forecast
        </div>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Cancel</button>
    <div>
      <button mat-flat-button
              color="primary"
              [disabled]="pending"
              (click)="imported ? save() : importVariables()">
        <span *ngIf="!pending">{{ imported ? 'Close' : 'Import' }}</span>
        <indicio-spinner *ngIf="pending"></indicio-spinner>
      </button>
    </div>
  </div>
</indicio-dialog>

<ng-template #variableList>
  <div class="variable"
       (click)="setActiveVariable(variable.RemoteReferenceId)"
       [ngClass]="{ 'selected': activeVariableId === variable.RemoteReferenceId }"
       *ngFor="let variable of requestVariablesArray">
    {{ variable.Name }}

    <div class="status">
      <ng-container [ngSwitch]="variable.status">
        <ng-container *ngSwitchCase="'processing'">
          <indicio-spinner></indicio-spinner>
        </ng-container>
        <ng-container *ngSwitchCase="'complete'">
          <i class="ion-checkmark-circled green"
             indicioTippy="Successfully imported"></i>
        </ng-container>
        <ng-container *ngSwitchCase="'error'">
          <i class="ion-alert-circled red"
             [indicioTippy]="variable.statusMessage"></i>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <i class="ion-alert-circled red"
             *ngIf="!variable.existingVariable && variable.nameError"
             [indicioTippy]="variable.nameError"></i>
          <i class="ion-alert-circled orange"
             *ngIf="variable.existingVariable"
             indicioTippy="Will use previously imported variable."></i>
        </ng-container>
      </ng-container>

    </div>
  </div>
</ng-template>

<ng-template #variableInfoTemplate>
  <ng-container *ngIf="activeEntity as entry">
    <div class="row">
      <div class="col-xs-12 modal-chart">
        <div class="preview-loader"
             *ngIf="!graphReady">
          <indicio-spinner color="white"
                           [size]="25"></indicio-spinner>&nbsp;
          Loading...
        </div>
        <indicio-alg [class]="!graphReady ? 'alg-loader' : null"
                     [style]="!graphReady ? 'filter: grayscale(1) invert(1) brightness(0.7);' : null"
                     [periodicityType]="periodicity"
                     [HistoricLine]="historicLine"
                     [options]="options"
                     [chartStyles]="chartStyles"
                     [showExport]="false"
                     graphType="generic"
                     #graph>
        </indicio-alg>
      </div>
    </div>

    <ng-template #loadingGraph>
      <div style="margin: 0 0 20px 0;">
        <i class="ion-load-c spinner"></i> Loading graph data...
      </div>
    </ng-template>

    <!-- <h2>{{ remoteDataModel.Name }}</h2> -->
    <div style="max-height: 200px; overflow-y: auto; overflow-x: hidden;">

      <div class="row">
        <div class="col-xs-4">Periodicity:</div>
        <div class="col-xs-8">
          {{ entry.TimeSeries.Periodicity.Display }}
        </div>
      </div>

      <div class="row">
        <div class="col-xs-4">Date range:</div>
        <div class="col-xs-8">
          {{ entry.TimeSeries.StartDate| dateFormat }} - {{ entry.TimeSeries.EndDate | dateFormat }}
        </div>
      </div>

      <div class="row"
           *ngIf="entry.TimeSeries.Units">
        <div class="col-xs-4">Units:</div>
        <div class="col-xs-8">
          {{ entry.TimeSeries.Units }}
        </div>
      </div>

      <div class="row">
        <div class="col-xs-4">Code:</div>
        <div class="col-xs-8 selectable-text">
          {{ entry.TimeSeries.Code }}
        </div>
      </div>

      <div class="row"
           *ngIf="!entry.TimeSeries.Active">
        <div class="col-xs-4">Discontinued:</div>
        <div class="col-xs-8">
          Yes
        </div>
      </div>

      <ng-container *ngIf="entry.Source as sourceInfo;">
        <h3 class="margin-top bold italic">Source</h3>
        <div class="grey margin-bottom-10">{{ sourceInfo.Title }}</div>
        <div class="row">
          <div class="col-xs-4">Region</div>
          <div class="col-xs-8">{{ sourceInfo.Region }}</div>
        </div>
        <div class="row">
          <div class="col-xs-4">Country of domicile</div>
          <div class="col-xs-8">{{ sourceInfo.CountryOfDomicile }}</div>
        </div>
        <div class="row"
             *ngIf="sourceInfo.InfoUrl">
          <div class="col-xs-4">Info link</div>
          <div class="col-xs-8 crop-string"><a [href]="sourceInfo.InfoUrl"
               target="_blank">{{ sourceInfo.InfoUrl }}</a></div>
        </div>
      </ng-container>

      <ng-container *ngIf="activeEntity.Release as releaseInfo;">
        <h3 class="margin-top bold italic">Release</h3>
        <div class="grey margin-bottom-10">{{ releaseInfo.Title}}</div>
        <div class="row"
             *ngIf="releaseInfo.ReleaseDate">
          <div class="col-xs-4">Last release date</div>
          <div class="col-xs-8">{{ releaseInfo.ReleaseDate | dateFormat }}</div>
        </div>
        <div class="row"
             *ngIf="releaseInfo.NextReleaseDate">
          <div class="col-xs-4">Next release date</div>
          <div class="col-xs-8">{{ releaseInfo.NextReleaseDate| dateFormat }}</div>
        </div>
        <div class="row"
             *ngIf="releaseInfo.InfoLink as link">
          <div class="col-xs-4">Info link</div>
          <div class="col-xs-8 crop-string"><a [href]="link"
               target="_blank">{{ link }}</a></div>
        </div>
        <div class="row"
             *ngIf="releaseInfo.HistoryLink as link">
          <div class="col-xs-4">History link</div>
          <div class="col-xs-8 crop-string"><a [href]="link"
               target="_blank">{{ link }}</a></div>
        </div>
        <div class="row"
             *ngIf="releaseInfo.CalendarLink as link">
          <div class="col-xs-4">Calendar link</div>
          <div class="col-xs-8 crop-string"><a [href]="link"
               target="_blank">{{ link }}</a></div>
        </div>
        <div class="row"
             *ngIf="releaseInfo.MethodologyLink as link">
          <div class="col-xs-4">Methodology link</div>
          <div class="col-xs-8 crop-string"><a [href]="link"
               target="_blank">{{ link }}</a></div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <hr class="margin-top"
      style="border-color: #e4e4e4;">

  <ng-container *ngIf="activeRequest">
    <div class="row">
      <div class="col-xs-12 input-label">
        <mat-form-field>
          <mat-label>Enter name</mat-label>
          <input matInput
                 autocomplete="off"
                 [ngModel]="activeRequest.Name"
                 (ngModelChange)="changeName($event)"
                 name="Name"
                 maxlength="256"
                 required>
          <mat-hint *ngIf="!activeRequest.existingVariable && activeRequest.nameError">
            <span class="error-text">
              {{ activeRequest.nameError}}
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 input-label">
        <mat-form-field>
          <mat-label>Aggregation method</mat-label>
          <mat-select [ngModel]="activeRequest.aggregationMethodId"
                      (ngModelChange)="activeRequest.aggregationMethodId = $event"
                      required>
            <mat-option [value]="option.Value"
                        *ngFor="let option of env.AggregationMethods">{{ option.Display }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <br>
    <div style="background-color: var(--indicio-modal-alt-background-color, #f0f0f0); padding: 10px;"
         *ngIf="activeRequest.existingVariable">
      Will use previously imported variable. <indicio-wiki-icon slug="overview-source-variable"></indicio-wiki-icon>
    </div>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loading">
    <indicio-spinner></indicio-spinner> Loading data...
  </div>
</ng-template>
