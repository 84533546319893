<indicio-dialog-base>
  <h1 mat-dialog-title>Reconciliation result overview</h1>
  <div mat-dialog-content>
    <div *ngIf="!isLoading; else showLoader"
         class="hierarchical-dialog-container">
      <div class="ctc-options">
        <ng-container *ngTemplateOutlet="reconciliateTemplate"></ng-container>
      </div>
    </div>
    <br>
  </div>

  <div mat-dialog-actions>
    <button mat-button
            (click)="close()">
      Close
    </button>
  </div>
</indicio-dialog-base>

<ng-template #reconciliateTemplate>

  <div class="row">
    <div class="col-xs-6">Variable</div>
    <div class="col-xs-6">Reconciliation result</div>
  </div>
  <div class="row"
       style="margin-top: 10px"
       *ngFor="let result of dto.ReconciliationResults">
    <div class="col-xs-6 crop-string">{{ result.ReconciliationResultId }}</div>
    <div class="col-xs-6 pointer h-bg-lightgrey"
         (click)="viewResult(result)">
      <span indicioTippy="Inspect result">
        <i class="ion-transgender"></i> Inspect
      </span>
    </div>
  </div>

</ng-template>

<ng-template #showLoader>
  <i class="ion-load-c spinner info-loader "></i>
</ng-template>
