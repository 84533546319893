import { Injectable } from '@angular/core';
import { IMemberModel } from '@core/interfaces/if-member';
import { IMemberService } from '@core/interfaces/if-member.service';
import { HttpService } from '@core/services/http/http.service';
import { AuditLogDTO } from '@modules/admin/entities/audit-log.dto';
import { AuditLogSearchDTO } from '@modules/admin/entities/audit-search.dto';
import { DisplayValue } from '@modules/lang/types/display-value';
import { Store } from '@ngxs/store';
import { CompanyMapper } from '../company/company-mapper';
import { CompanyActions, CreateCompanySuccessAction } from '../company/company.actions';
import { CompanyModel } from '../company/company.model';
import { CompanyDTO } from '../company/dtos/company-dto';
import { CreateCompanyDTO } from '../company/dtos/create-company-dto';
import { SupportUpdateCompanyDTO } from '../company/dtos/update-company-dto';
import { InviteDTO } from '../invites/dtos/invite-dto';


@Injectable({
  providedIn: 'root'
})
export class SupportService implements IMemberService {

  constructor(
    private http: HttpService,
    private store: Store,
    private companyMapper: CompanyMapper
  ) { }
  isLoading: boolean;

  /**
   * Backend calls
   */

  public getManagedCompanies() {
    return this.http.get<{ Item1: string, Item2: string; }[]>('support/companies')
      .then(({ body }) => body.map(s => new DisplayValue<string>(s.Item1, s.Item2)));
  }

  public getCompany(companyId: string) {
    return this.http.get<CompanyDTO>(`support/company/${companyId}`).then(({ body }) => {
      return this.companyMapper.mapSupport(body);
    });
  }

  public toggleTrial(companyId: string) {
    return this.http.get<boolean>(`support/company/${companyId}/toggle-trial`).then(({ body }) => body);
  }

  public getAuditLog(companyId: string, dto: AuditLogSearchDTO) {
    return this.http.post<AuditLogDTO[]>(`support/company/${companyId}/audit-log`, dto).then(({ body }) => body);
  }

  public updateCompany(companyId: string, dto: SupportUpdateCompanyDTO) {
    return this.http.put<CompanyDTO>(`support/company/${companyId}/update`, dto).then(({ body }) => body);
  }

  public createCompany(dto: CreateCompanyDTO) {
    return this.http.post<CompanyModel>('support/company/create-company', dto).then(({ body }) => body)
      .then(newCompany => {
        this.store.dispatch(new CreateCompanySuccessAction(newCompany));
        return newCompany;
      });
  }

  public toggleDisabled(companyId: string) {
    return this.http.post<boolean>(`support/company/${companyId}/toggle-disabled`, null).then(({ body }) => body);
  }


  /************************************************************
   * MEMBER SERVICES BELOW - INTERFACE IMemberService
   ************************************************************/
  public inviteMembers(companyId: string, _parentId: string, invites: IMemberModel[]) {
    const dtos: InviteDTO[] = invites.map(i => Object.assign(new InviteDTO, { Email: i.Email, CompanyRole: i.MemberRole.Value, CompanyId: i.CompanyId }));
    return this.http.post<null>(`support/company/${companyId}/employees`, dtos);
  }

  public syncMembers(companyId: string) {
    return this.getCompany(companyId);
  }

  public leave(_companyId: string) {
    // Cannot leave company
  }

  public removeMember(companyId: string, userEmail: string) {
    return this.http.delete<null>(`support/company/${companyId}/employees/${userEmail}/`)
      .then(() => this.store.dispatch(new CompanyActions.RemovedEmployeeSuccess(companyId, userEmail)));
  }

}
