import { Injectable, NgZone } from '@angular/core';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { Store } from '@ngxs/store';
import { OpenConfirmModal } from '@shared/modals/confirm/confirm-modal.actions';
import { CloseActiveModals } from '@shared/modals/modal.actions';
import { EnvironmentService } from '../environment/environment.service';


@Injectable({
  providedIn: 'root'
})
export class IdleService {
  idleActive = false;
  idleFor = 0;
  idleForceLogout = false;

  private lastActivePingSentUnixTS: number = Date.now();
  private idleTimeAlert = 600;
  private idleTimeLogout = 300;
  private idleTimeout = null;
  private idleForceTimeout = null;

  private get loggedIn$() { return this.authService.loggedIn$; }

  constructor(
    private zone: NgZone,
    private authService: AuthFrontendService,
    private client: ClientFrontendService,
    protected store: Store,
    private env: EnvironmentService
  ) {
    this.loggedIn$.subscribe(next => {
      this.idleActive = next;
      if (this.idleActive === true) {
        this.logoutQuestionTimer();
      }
    });
    this.zone.runOutsideAngular(() => {
      window.addEventListener('mousemove', (_el) => {
        this.idleFor = 0;
        this.sendUserIsActive();
      });
    });
  }

  private sendUserIsActive() {
    const now = Date.now();
    const loggedIn = this.authService.loggedIn;
    // Hammer protection: 10 seconds minimum between calls
    if (now > this.lastActivePingSentUnixTS + 60000 && loggedIn) {
      this.lastActivePingSentUnixTS = now;
      this.client.sendIAmActive()
        .then(() => {
          this.lastActivePingSentUnixTS = Date.now();
        });
    }
  }

  private logoutQuestionTimer() {
    if (this.idleTimeout) {
      window.clearTimeout(this.idleTimeout);
    }

    this.idleTimeout = setTimeout(() => {
      if (this.client.client?.AutomaticLogout || this.env.env.Environment.toLowerCase() === 'production') {
        this.idleFor++;
      }

      if (this.authService.loggedIn === true) {
        if (this.idleFor === this.idleTimeAlert) {
          this.store.dispatch(new CloseActiveModals());
          this.openLogoutModal();
          this.logoutTimer();
          return;
        }
      }

      if (this.idleActive === true) {
        this.logoutQuestionTimer();
      }
    }, 1000);
  }

  private onTimeoutOrProceed(remove2fa = true) {
    this.authService.logout(remove2fa, true);
  }

  private logoutTimer() {
    if (this.idleForceTimeout) {
      window.clearTimeout(this.idleForceTimeout);
    }

    this.idleForceTimeout = setTimeout(() => {
      if (this.idleForceLogout === true) {
        this.onTimeoutOrProceed(false);
      }
    }, this.idleTimeLogout * 1000);
  }

  openLogoutModal() {
    this.idleForceLogout = true;

    const proceed = () => this.onTimeoutOrProceed();
    const back = () => {
      this.idleForceLogout = false;
      this.logoutQuestionTimer();
    };
    this.store.dispatch(new OpenConfirmModal(
      proceed,
      'We have noticed you have been inactive for 10 minutes. Press cancel if you want to stay logged in, otherwise you will be logged out in 5 minutes.',
      'Stay logged in?',
      'Cancel',
      'Log out',
      false,
      back
    ));
  }
}
