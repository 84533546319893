import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { CompanyHorizonInfoDTO } from '@core/store/company/dtos/company-dto';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { AdminCompanyService } from '@modules/admin/components/system/companies/company.admin.service';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { DateUtils } from '@shared/utils/date.utils';


@Component({
  selector: 'indicio-company-edit-admin-dialog-settings-tab',
  templateUrl: './company-edit-tab.settings.component.html',
  styleUrls: ['./company-edit-tab.settings.component.less'],
})
export class CompanyEditSettingsTabComponent implements OnChanges {

  @Input() company: CompanyModel;

  @Output() checkSavedEvent = new EventEmitter();
  @Output() reloadEvent = new EventEmitter();

  // Date limit for trials
  public minDate: Date = DateUtils.newDate(); // Today

  constructor(
    private cd: ChangeDetectorRef,
    private adminService: AdminCompanyService,
    private status: StatusService,
    private companySerive: CompanyFrontendService,
    private profileService: ProfileFrontendService,
    public envService: EnvironmentService,
  ) {
  }

  public ngOnChanges() {
    if (!this.company) { return; }
    this.cd.detectChanges();
  }

  public async toggleMBE() {
    this.adminService.changeMacrobondEnabled(this.company)
      .then(() => {
        this.status.setMessage('Macrobond enabled flag changed', 'Success', true);
        this.reloadEvent.emit();
      })
      .catch(err => this.status.setError(err, true));
  }

  public async toggleTrialStatus() {
    this.adminService.changeTrialStatus(this.company)
      .then(() => {
        this.status.setMessage('Trial status updated', 'Success', true);
        this.reloadEvent.emit();
      })
      .catch(err => this.status.setError(err, true));
  }

  public setHorizonValue(periodicity: PeriodicityType, horizon: number) {
    this.company.MaxHorizons.find(x => x.Periodicity === periodicity).MaxHorizon = horizon;
    this.checkSavedEvent.emit();
  }

  public togglePeriodicity(dto: CompanyHorizonInfoDTO) {
    dto.Enabled = !dto.Enabled;
    this.checkSavedEvent.emit();
  }

  public setTrialExp(date: Date) {
    this.company.TrialExpirationDate = date;
    this.checkSavedEvent.emit();
  }

  public emitCheckSaved() {
    this.checkSavedEvent.emit();
  }
}
