import { MatOptionSelectionChange } from '@angular/material/core';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { Period, PeriodicityType } from '@modules/lang/language-files/periodicities';
import { SeasonalCalendar } from '@modules/lang/language-files/seasonal';
import { DisplayValue } from '@modules/lang/types/display-value';

export namespace CalendarEffects {
  export function FilterEffectsByPeriodicity(period: PeriodicityType, effects: SeasonalCalendar[]): SeasonalCalendar[] {
    const rmLoq = () => {
      const idx = effects.findIndex(x => x.Value === 'loq');
      if (idx !== -1) { effects.splice(idx, 1); }
    };

    const rmLom = () => {
      const idx = effects.findIndex(x => x.Value === 'lom');
      if (idx !== -1) { effects.splice(idx, 1); }
    };

    if (period === Period.month) { rmLoq(); }
    else if (period === Period.quarter) { rmLom(); }
    else { rmLoq(); rmLom(); }
    return effects;
  }

  export function updateSeasonalCalendarEffects(current: CalendarType[], change: MatOptionSelectionChange, isProj: boolean = false) {
    const changed = change.source.value;
    if (change.source.selected) {
      checkRequirements(changed);
      current.addUniqueId(changed);
    } else {
      current.removeFirst(x => x === changed);
    }

    function checkRequirements(value: CalendarType) {
      if (TradingEffects.includes(<any> value)) {
        current.removeFirst(x => x === 'lom');
        current.removeFirst(x => x === 'loq');
      }
      if (value === 'lpyear') {
        current.removeFirst(x => x === 'lom');
        current.removeFirst(x => x === 'loq');
      }
      if (value === 'lom') {
        current.removeFirst(x => x === 'lpyear');
        if (!isProj) { current.removeFirst(x => x === 'loq'); }
      }
      if (value === 'loq') {
        current.removeFirst(x => x === 'lpyear');
        if (!isProj) { current.removeFirst(x => x === 'lom'); }
      }
      if (value === 'easter') {
        current.removeFirst(x => x === 'easterstock');
      }
      if (value === 'easterstock') {
        current.removeFirst(x => x === 'easter');
      }
      if (TradingEffects.includes(<any> value) || value === 'lom' || value === 'loq') {
        TradingEffects.forEach((effect: CalendarType) => {
          current.removeFirst(x => x === effect);
        });
      }
    }

    current.sort();
    return current;
  }

  export type RelatedToEaster = 'Septuagesima' | 'Quinquagesima' | 'AshWednesday' | 'PalmSunday' | 'GoodFriday' | 'EasterSunday' | 'Easter' | 'EasterMonday' | 'RogationSunday' |
    'Ascension' | 'Pentecost' | 'PentecostMonday' | 'TrinitySunday' | 'CorpusChristi';
  export type RelatedToChristmas = 'ChristTheKing' | 'Advent1st' | 'Advent2nd' | 'Advent3rd' | 'Advent4th' | 'ChristmasEve' | 'ChristmasDay' | 'BoxingDay' | 'NewYearsDay';
  export type OtherEcclesticalFeasts = 'SolemnityOfMary' | 'Epiphany' | 'PresentationOfLord' | 'Annunciation' | 'TransfigurationOfLord' | 'AssumptionOfMary' | 'AssumptionOfMary' |
    'BirthOfVirginMary' | 'CelebrationOfHolyCross' | 'MassOfArchangels' | 'AllSaints' | 'AllSouls';
  export type CHZurichPublicHolidays = 'CHBerchtoldsDay' | 'CHSechselaeuten' | 'CHAscension' | 'CHConfederationDay' | 'CHKnabenschiessen';
  export type GBLondonPublicHolidays = 'GBMayDay' | 'GBBankHoliday' | 'GBSummerBankHoliday';
  export type DEFrankfurtPublicHolidays = 'DEAscension' | 'DECorpusChristi' | 'DEGermanUnity' | 'DEChristmasEve' | 'DENewYearsEve';
  export type FRParisPublicHolidays = 'FRFetDeLaVictoire1945' | 'FRAscension' | 'FRBastilleDay' | 'FRAssumptionVirginMary' | 'FRAllSaints' | 'FRArmisticeDay';
  export type ITMilanoPublicHolidays = 'ITEpiphany' | 'ITLiberationDay' | 'ITRepublicAnniversary' | 'ITAssumptionOfVirginMary' |
    'ITAllSaints' | 'ITWWIVictoryAnniversary' | 'ITStAmrose' | 'ITImmaculateConception';
  export type USNewYorkUSChicagoPublicHolidays = 'USNewYearsDay' | 'USInaugurationDay' | 'USMLKingsBirthday' | 'USLincolnsBirthday' | 'USWashingtonsBirthday' | 'USMemorialDay' | 'USIndependenceDay' |
    'USLaborDay' | 'USColumbusDay' | 'USElectionDay' | 'USVeteransDay' | 'USThanksgivingDay' | 'USChristmasDay' | 'USCPulaskisBirthday' | 'USGoodFriday';
  export type CATorontoCAMontrealPublicHolidays = 'CAVictoriaDay' | 'CACanadaDay' | 'CACivicProvincialHoliday' | 'CALabourDay' | 'CAThanksgivingDay' | 'CaRemembranceDay';
  export type JPTokyoJPOsakaPublicHolidays = 'JPNewYearsDay' | 'JPGantan' | 'JPBankHolidayJan2' | 'JPBankHolidayJan3' | 'JPComingOfAgeDay' | 'JPSeijinNoHi' | 'JPNatFoundationDay' |
    'JPKenkokuKinenNoHi' | 'JPGreeneryDay' | 'JPMidoriNoHi' | 'JPConstitutionDay' | 'JPKenpouKinenBi' | 'JPNationHoliday' | 'JPKokuminNoKyujitu' | 'JPChildrensDay' | 'JPKodomoNoHi' |
    'JPMarineDay' | 'JPUmiNoHi' | 'JPRespectForTheAgedDay' | 'JPKeirouNoHi' | 'JPAutumnalEquinox' | 'JPShuubun-no-hi' | 'JPHealthandSportsDay' | 'JPTaiikuNoHi' | 'JPNationalCultureDay' |
    'JPBunkaNoHi' | 'JPThanksgivingDay' | 'JPKinrouKanshaNohi' | 'JPKinrouKanshaNohi' | 'JPEmperorsBirthday' | 'JPTennou-tanjyou-bi';

  export enum EEasterHolidays {
    Septuagesima = 'Septuagesima',
    Quinquagesima = 'Quinquagesima',
    AshWednesday = 'AshWednesday',
    PalmSunday = 'PalmSunday',
    GoodFriday = 'GoodFriday',
    EasterSunday = 'EasterSunday',
    Easter = 'Easter',
    EasterMonday = 'EasterMonday',
    RogationSunday = 'RogationSunday',
    Ascension = 'Ascension',
    Pentecost = 'Pentecost',
    PentecostMonday = 'PentecostMonday',
    TrinitySunday = 'TrinitySunday',
    CorpusChristi = 'CorpusChristi'
  }

  export enum EChristmasHolidays {
    ChristTheKing = 'ChristTheKing',
    Advent1st = 'Advent1st',
    Advent2nd = 'Advent2nd',
    Advent3rd = 'Advent3rd',
    Advent4th = 'Advent4th',
    ChristmasEve = 'ChristmasEve',
    ChristmasDay = 'ChristmasDay',
    BoxingDay = 'BoxingDay',
    NewYearsDa = 'NewYearsDay'
  }

  export enum EOtherEcclesticalFeastsHolidays {
    SolemnityOfMary = 'SolemnityOfMary',
    Epiphany = 'Epiphany',
    PresentationOfLord = 'PresentationOfLord',
    Annunciation = 'Annunciation',
    TransfigurationOfLord = 'TransfigurationOfLord',
    AssumptionOfMary = 'AssumptionOfMary',
    BirthOfVirginMary = 'BirthOfVirginMary',
    CelebrationOfHolyCross = 'CelebrationOfHolyCross',
    MassOfArchangels = 'MassOfArchangels',
    AllSaints = 'AllSaints',
    AllSouls = 'AllSouls'
  }

  export enum ESwitzerlandHolidays {
    CHBerchtoldsDay = 'CHBerchtoldsDay',
    CHSechselaeuten = 'CHSechselaeuten',
    CHAscension = 'CHAscension',
    CHConfederationDay = 'CHConfederationDay',
    CHKnabenschiessen = 'CHKnabenschiessen'
  }

  export enum EUKHolidays {
    GBBankHoliday = 'GBBankHoliday',
    GBSummerBankHoliday = 'GBSummerBankHoliday',
    GBMayDay = 'GBMayDay'
  }

  export enum EGermanyHolidays {
    DEAscension = 'DEAscension',
    DECorpusChristi = 'DECorpusChristi',
    DEGermanUnity = 'DEGermanUnity',
    DEChristmasEve = 'DEChristmasEve',
    DENewYearsEve = 'DENewYearsEve'
  }

  export enum EFranceHolidays {
    FRFetDeLaVictoire1945 = 'FRFetDeLaVictoire1945',
    FRAscension = 'FRAscension',
    FRBastilleDay = 'FRBastilleDay',
    FRAssumptionVirginMary = 'FRAssumptionVirginMary',
    FRAllSaints = 'FRAllSaints',
    FRArmisticeDay = 'FRArmisticeDay'
  }

  export enum EItalyHolidays {
    ITEpiphany = 'ITEpiphany',
    ITLiberationDay = 'ITLiberationDay',
    ITRepublicAnniversary = 'ITRepublicAnniversary',
    ITAssumptionOfVirginMary = 'ITAssumptionOfVirginMary',
    ITAllSaints = 'ITAllSaints',
    ITWWIVictoryAnniversary = 'ITWWIVictoryAnniversary',
    ITStAmrose = 'ITStAmrose',
    ITImmaculateConception = 'ITImmaculateConception'
  }

  export enum EUSAHolidays {
    USNewYearsDay = 'USNewYearsDay',
    USInaugurationDay = 'USInaugurationDay',
    USMLKingsBirthday = 'USMLKingsBirthday',
    USLincolnsBirthday = 'USLincolnsBirthday',
    USWashingtonsBirthday = 'USWashingtonsBirthday',
    USMemorialDay = 'USMemorialDay',
    USIndependenceDay = 'USIndependenceDay',
    USLaborDay = 'USLaborDay',
    USColumbusDay = 'USColumbusDay',
    USElectionDay = 'USElectionDay',
    USVeteransDay = 'USVeteransDay',
    USThanksgivingDay = 'USThanksgivingDay',
    USChristmasDay = 'USChristmasDay',
    USCPulaskisBirthday = 'USCPulaskisBirthday',
    USGoodFriday = 'USGoodFriday'
  }

  export enum ECanadaHolidays {
    CAVictoriaDay = 'CAVictoriaDay',
    CACanadaDay = 'CACanadaDay',
    CACivicProvincialHoliday = 'CACivicProvincialHoliday',
    CALabourDay = 'CALabourDay',
    CAThanksgivingDay = 'CAThanksgivingDay',
    CaRemembranceDay = 'CaRemembranceDay'
  }

  export enum EJapanHolidays {
    JPNewYearsDay = 'JPNewYearsDay',
    JPGantan = 'JPGantan',
    JPBankHolidayJan2 = 'JPBankHolidayJan2',
    JPBankHolidayJan3 = 'JPBankHolidayJan3',
    JPComingOfAgeDay = 'JPComingOfAgeDay',
    JPSeijinNoHi = 'JPSeijinNoHi',
    JPNatFoundationDay = 'JPNatFoundationDay',
    JPKenkokuKinenNoHi = 'JPKenkokuKinenNoHi',
    JPGreeneryDay = 'JPGreeneryDay',
    JPMidoriNoHi = 'JPMidoriNoHi',
    JPConstitutionDay = 'JPConstitutionDay',
    JPKenpouKinenBi = 'JPKenpouKinenBi',
    JPNationHoliday = 'JPNationHoliday',
    JPKokuminNoKyujitu = 'JPKokuminNoKyujitu',
    JPChildrensDay = 'JPChildrensDay',
    JPKodomoNoHi = 'JPKodomoNoHi',
    JPMarineDay = 'JPMarineDay',
    JPUmiNoHi = 'JPUmiNoHi',
    JPRespectForTheAgedDay = 'JPRespectForTheAgedDay',
    JPKeirouNoHi = 'JPKeirouNoHi',
    JPAutumnalEquinox = 'JPAutumnalEquinox',
    JPShuubun = 'JPShuubun-no-hi',
    JPHealthandSportsDay = 'JPHealthandSportsDay',
    JPTaiikuNoHi = 'JPTaiikuNoHi',
    JPNationalCultureDay = 'JPNationalCultureDay',
    JPBunkaNoHi = 'JPBunkaNoHi',
    JPThanksgivingDay = 'JPThanksgivingDay',
    JPKinrouKanshaNohi = 'JPKinrouKanshaNohi',
    JPEmperorsBirthday = 'JPEmperorsBirthday',
    JPTennou = 'JPTennou-tanjyou-bi'
  }

  export type RegionType = 'New York' | 'London' | 'Tokyo' | 'Zurich';

  export type HolidayType =
    RelatedToEaster |
    RelatedToChristmas |
    OtherEcclesticalFeasts |
    CHZurichPublicHolidays |
    GBLondonPublicHolidays |
    DEFrankfurtPublicHolidays |
    FRParisPublicHolidays |
    ITMilanoPublicHolidays |
    USNewYorkUSChicagoPublicHolidays |
    CATorontoCAMontrealPublicHolidays |
    JPTokyoJPOsakaPublicHolidays;

  export type CategoryType = 'easter' | 'christmas' | 'ecclestical-feast' | 'switzerland' | 'united-kingdom' | 'germany' | 'france' | 'italy' | 'usa' | 'canada' | 'japan';

  export class HolidayCategory {
    public Value: CategoryType;
    public Display: string;
    public Description: string;
    public children: HolidayType[];
    public subCategories: DisplayValue<HolidayType>[] = [];

    constructor(parent: DisplayValue<CategoryType>, children: HolidayType[], subcategories: DisplayValue<HolidayType>[] = []) {
      this.Value = parent.Value;
      this.Display = parent.Display;
      this.Description = parent.Description;
      this.children = children;
      this.subCategories = subcategories;
    }
  }


  export const HolidayCategories: HolidayCategory[] = [
    new HolidayCategory({ Value: 'easter', Display: 'Easter', Description: 'Holidays related to Easter' }, Object.values(EEasterHolidays)),
    new HolidayCategory({ Value: 'christmas', Display: 'Christmas', Description: 'Holidays related to Christmas' }, Object.values(EChristmasHolidays)),
    // eslint-disable-next-line max-len
    new HolidayCategory({ Value: 'ecclestical-feast', Display: 'Ecclestical feast', Description: 'Holidays related to Ecclestical feast' }, Object.values(EOtherEcclesticalFeastsHolidays)),
    new HolidayCategory({ Value: 'switzerland', Display: 'Switzerland', Description: 'Holidays related to Switzerland' }, Object.values(ESwitzerlandHolidays)),
    new HolidayCategory({ Value: 'united-kingdom', Display: 'United Kingdom', Description: 'Holidays related to United Kingdom' }, Object.values(EUKHolidays)),
    new HolidayCategory({ Value: 'germany', Display: 'Germany', Description: 'Holidays related to Germany' }, Object.values(EGermanyHolidays)),
    new HolidayCategory({ Value: 'france', Display: 'France', Description: 'Holidays related to France' }, Object.values(EFranceHolidays)),
    new HolidayCategory({ Value: 'italy', Display: 'Italy', Description: 'Holidays related to Italy' }, Object.values(EItalyHolidays)),
    new HolidayCategory({ Value: 'usa', Display: 'USA', Description: 'Holidays related to USA' }, Object.values(EUSAHolidays)),
    new HolidayCategory({ Value: 'canada', Display: 'Canada', Description: 'Holidays related to Canada' }, Object.values(ECanadaHolidays)),
    new HolidayCategory({ Value: 'japan', Display: 'Japan', Description: 'Holidays related to Japan' }, Object.values(EJapanHolidays))
  ];

  export type EffectType = 'working-day' | 'holiday';
  export const EffectTypes: DisplayValue<EffectType>[] = [
    { Value: 'working-day', Display: 'Working day region', Description: 'Effects related to region' },
    { Value: 'holiday', Display: 'Holidays', Description: 'Days related to holidays' }
  ];

  export type TradingEffectType = 'td' | 'tdnolpyear' | 'tdstock' | 'td1coef' | 'td1nolpyear' | 'tdstock1coef';
  export const TradingEffects: TradingEffectType[] = ['td', 'tdnolpyear', 'tdstock', 'td1coef', 'td1nolpyear', 'tdstock1coef'];


  export type DateEffectType = 'lom' | 'loq' | 'lpyear';
  export type CalendarType = TradingEffectType | DateEffectType | 'easter' | 'easterstock';


  export class DialogData {
    public SeasonalCalendarHolidays: string[];
    public SeasonalCalendarRegion: string[];
    public forecastVersion: ForecastVersionModel;
    public SeasonalCalendarTypes: CalendarType[];
  }

  export class ListItem {
    public item: HolidayCategory | DisplayValue<EffectType>;
    public open: boolean = false;
    public subItems: ListSubItem[] = [];
    public isRegion: boolean = false;

    public get isAllSelected() { return this.subItems.filter(e => e.selected).length === this.subItems.length; }
    public get anySelected() { return this.subItems.filter(e => e.selected).length > 0; }
    public get selectedSubItems() { return this.subItems.filter(x => x.selected); }

    /** Returns a string on the form (selectedCount / totalCount) or null if nothins is selected */
    public selectedCount() {
      const selected = this.selectedSubItems.length;
      return selected > 0 ? `(${selected}/${this.subItems.length})` : null;
    }

    /** The first selected sub-item will be the displayed region-name (only one such region can be selected). */
    public regionDisplay() {
      const selected = this.selectedSubItems;
      return selected.length > 0 ? selected[0].item.Display : null;
    }
  }

  export class ListSubItem {
    public item: DisplayValue<HolidayType> | DisplayValue<RegionType>;
    public selected: boolean = false;
    public isRegion: boolean = false;
  }
}
