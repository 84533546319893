
export namespace SheetActions {
  export class ToggleSheet {
    public static readonly type = '[Sheet] Toggle sheet';
    data: { index: number; };
    constructor(
      public index: number
    ) { this.data = { index }; }
  }

  export class SheetChanged {
    public static readonly type = '[Sheet] Sheet changed';
  }
  export class ViewInitialized {
    public static readonly type = '[Sheet] View initialized';
  }
}
