import { Injectable } from '@angular/core';
import { IMemberModel } from '@core/interfaces/if-member';
import { IMemberService } from '@core/interfaces/if-member.service';
import { ActionService } from '@core/services/actions/actions.service';
import {
  GetProjectMembersSuccessAction, GetProjectsInCompanySuccessAction, GetProjectSuccessAction, RemoveProjectMemberSuccessAction, RemoveProjectSuccessAction
} from '@core/store/project/project.actions';
import { ProjectBackendService } from '@core/store/project/project.backend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Select, Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { Observable, Subscription } from 'rxjs';
import { CompanyActions } from '../company/company.actions';
import { CreateProjectDTO } from './dtos/create-project-dto';
import { ProjectState } from './project.state';


@Injectable({ providedIn: 'root' })
export class ProjectFrontendService implements IMemberService {

  tempNewProject: CreateProjectDTO = new CreateProjectDTO;

  /** This flag is used by the IMemberService IF users. */
  public isLoading: boolean = false;
  // Dict to hold promises for 'fetch-all' for a specific company.
  private _projectsLoading: { CompanyId: string, Promise: Promise<ProjectModel[]>; }[] = [];

  private sub: Subscription = new Subscription();

  /* Selectors */
  @Select(ProjectState.projectsInActiveCompany)
  public projects$: Observable<ProjectModel[]>;

  public get projects() { return this.store.selectSnapshot(ProjectState.projectsInActiveCompany); }
  public get projectMetas() { return this.store.selectSnapshot(ProjectState.projectMetasInActiveCompany); }
  // possible null when navigating from admin view
  public projectById(id: string) { return this.projects != null ? this.projects.find(x => x.ProjectId === id) : null; }
  public projectByForecastId(id: string) { return this.projects != null ? this.projects.find(x => x.ForecastIds.includes(id)) : null; }
  public projectMetas$() { return this.store.select(ProjectState.projectMetasInActiveCompany); }
  public projectsLoading(companyId: string) { return this._projectsLoading.some(x => x.CompanyId === companyId); }

  constructor(
    private store: Store,
    private actions: ActionService,
    private dialogService: DialogService,
    private backendService: ProjectBackendService
  ) {
    this.setupActionSubscriptions();
  }

  ////
  //// Project part
  ////
  public getOrFetchProject(companyId: string, projectId: string): Promise<ProjectModel> {
    const p = this.projectById(projectId);
    if (!p || !p.fetched) {
      return this.fetchProject(companyId, projectId);
    } else {
      return Promise.resolve(p);
    }
  }

  public fetchProject(companyId: string, projectId: string) {
    return this.backendService.getProject(companyId, projectId)
      .then(project => {
        this.store.dispatch(new GetProjectSuccessAction(project));
        return project;
      });
  }

  public fetchAllProjects(companyId: string) {
    let existingPromise = this._projectsLoading.find(x => x.CompanyId === companyId);
    if (!!existingPromise) { return existingPromise.Promise; }
    const newPromise = this.backendService.getProjects(companyId)
      .then(projects => {
        this.store.dispatch(new GetProjectsInCompanySuccessAction(companyId, projects));
        return projects;
      })
      .finally(() => this._projectsLoading.removeByKey('CompanyId', companyId));

    this._projectsLoading.push({ CompanyId: companyId, Promise: newPromise });
    return newPromise;
  }

  public createProject(project: CreateProjectDTO, companyId: string) {
    return this.backendService.createProject(companyId, project)
      .then(newProj => {
        this.tempNewProject = new CreateProjectDTO;
        this.store.dispatch(new GetProjectSuccessAction(newProj));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        this.syncMembers(newProj.CompanyId, newProj.ProjectId);
        return newProj;
      });
  }

  public updateProject(project: ProjectModel) {
    return this.backendService.updateProject(project.CompanyId, project)
      .then(updated => {
        this.store.dispatch(new GetProjectSuccessAction(updated));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return updated;
      });
  }

  public deleteProject(project: ProjectModel) {
    project.isPending = true;
    return this.backendService.deleteProject(project.CompanyId, project.ProjectId)
      .then(() => {
        this.store.dispatch(new RemoveProjectSuccessAction(project.ProjectId));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return project.ProjectId;
      });
  }

  public openCreateProjectDialog(userEmail: string, clientId: string, companyId: string) {
    const ref = this.dialogService.openTextInputDialog({
      Title: 'Create a new project',
      Label: 'Project name',
      ConfirmText: 'Create',
      CancelText: 'Cancel'
    });
    return ref.toPromise()
      .then((name: string) => {
        if (!name) { return null; }
        const proj = new CreateProjectDTO();
        proj.Name = name;
        return this.createProject(proj, companyId)
          .then(project => project);
      });
  }

  ///
  /// Special functions
  ///
  public transferOwnership(project: ProjectModel, newOwnerEmail: string) {
    return this.backendService.transferOwnership(project, newOwnerEmail)
      .then(members => {
        project.Members = members;
        project.OwnerEmail = newOwnerEmail;
        this.store.dispatch(new GetProjectSuccessAction(project));
        return members;
      });
  }

  public purgeMemberFromProjects(companyId: string, email: string) {
    const projs = this.projects.filter(x => x.CompanyId === companyId);
    if (projs.length) {
      projs.forEach(p => this.store.dispatch(new RemoveProjectMemberSuccessAction(p.ProjectId, email)));
    }
  }

  /************************************************************
   * PROJECT MEMBER SERVICES BELOW - INTERFACE IMemberService
   ************************************************************/
  public syncMembers(companyId: string, projectId: string) {
    return this.backendService.getProjectMembers(companyId, projectId)
      .then(members => {
        this.store.dispatch(new GetProjectMembersSuccessAction(projectId, members));
        return members;
      })
      .catch(error => {
        if (error.status === 403) {
          this.store.dispatch(new RemoveProjectSuccessAction(projectId));
        }
      });
  }

  public removeMember(companyId: string, projectId: string, userEmail: string) {
    return this.backendService.removeProjectMember(companyId, projectId, userEmail)
      .then(removedEmail => {
        this.store.dispatch(new RemoveProjectMemberSuccessAction(projectId, removedEmail));
        return removedEmail;
      });
  }

  public inviteMembers(companyId: string, projectId: string, invites: IMemberModel[]) {
    return this.backendService.inviteProjectMembers(companyId, projectId, invites)
      .then(() => this.syncMembers(companyId, projectId));
  }


  public leave(companyId: string, projectId: string) {
    return this.backendService.leaveProject(companyId, projectId)
      .then(() => {
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        this.store.dispatch(new RemoveProjectSuccessAction(projectId));
      });
  }

  ///
  /// Private functions below
  ///
  private setupActionSubscriptions() {
    this.sub.add(this.actions.dispatched(CompanyActions.SetActive).subscribe((action: CompanyActions.SetActive) => {
      this.fetchAllProjects(action.companyId);
    }));
  }
}
