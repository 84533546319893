<indicio-modal-base [child]="this"
                    modalTitle="Remove event group(s)"
                    [loading]="isLoading"
                    bodyExtraClass="overflow-auto">
  <p *ngIf="events.length > 1">Are you sure you want to remove these event groups?</p>
  <p *ngIf="events.length === 1">Are you sure you want to remove this event group?</p>

  <div class="data-table">
    <div class="data-table__header">
      <div class="column header"
           style="max-width: 40px; min-width: 40px;">#</div>
      <div class="column header"
           style="width: 360px;">Name</div>
      <div class="column"
           style="width: 90px;">
        &nbsp;
      </div>
    </div>
  </div>

  <div class="data-table__body"
       [style.maxHeight.px]="301">
    <div class="body__row body__row--center-height body__row--max-height-30"
         *ngFor="let event of events; let i = index">
      <div class="column"
           style="min-width: 40px;">{{ i + 1 }}</div>
      <div class="column"
           style="width: 360px;">
        <div class="row">
          <div class="col-xs-12 text-left crop-string"
               [title]="event.Name">
            {{ event.Name }}
          </div>
        </div>
      </div>
      <div class="column"
           style="width: 90px;">
        <ng-container *ngIf="event.removedStatus === 'Failed'; else status">
          <span class="red"
                [indicioTippy]="event.failedMsg">Failed</span>
        </ng-container>
        <ng-template #status>
          {{ event.removedStatus === 'Success' ? 'Removed' : event.removedStatus }}
        </ng-template>
      </div>
    </div>
  </div>

  <div class="row extra-margin-top">
    <div class="col-xs-12 text-right"
         style="padding-right: 20px;">
      <ng-container *ngIf="pending || removedCount !== 0">
        Removed: {{removedCount }}/{{ events.length}}
      </ng-container>
      <ng-container *ngIf="!pending && removedCount === 0">
        Selected: {{ events.length }}/{{ events.length }}
      </ng-container>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 center-text">
      <button class="btn full-width"
              (click)="removeEvents()"
              data-test-id="remove-events-confirm-btn"
              [disabled]="pending"
              *ngIf="events?.length && removedCount !== events.length">
        <span *ngIf="!pending">Remove</span>
        <i *ngIf="pending"
           class="ion-load-c spinner"></i>
      </button>

      <button class="btn full-width"
              (click)="close()"
              *ngIf="!events?.length || removedCount === events.length">
        {{ removedCount > 0 ? 'Done' : 'Close' }}
      </button>
    </div>
  </div>
</indicio-modal-base>
