import { Periodicity } from '@modules/lang/types/periodicity';

export function getGrowthName(p: Periodicity) {
  switch (true) {
    case p.is('day'):
      return 'Growth DOD';
    case p.is('week'):
      return 'Growth WOW';
    case p.is('month'):
      return 'Growth MOM';
    case p.is('quarter'):
      return 'Growth QOQ';
    case p.is('halfyear'):
      return 'Growth HOH';
    case p.is('year'):
      return 'Growth YOY';
    default:
      return 'Growth';
  }
}

export function getDiffName(p: Periodicity) {
  switch (true) {
    case p.is('day'):
      return 'Diff DOD';
    case p.is('week'):
      return 'Diff WOW';
    case p.is('month'):
      return 'Diff MOM';
    case p.is('quarter'):
      return 'Diff QOQ';
    case p.is('halfyear'):
      return 'Diff HOH';
    case p.is('year'):
      return 'Diff YOY';
    default:
      return 'Diff';
  }
}
