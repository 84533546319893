import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ForecastKeys } from '@modules/forecast/services/forecast-model-resolver.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OrderByPipe } from '@shared/modules/pipes';
import { StorageUtils } from '@shared/utils/storage.utils';
import { SetCompanyModalOpts } from './set-company-modal.options';


@Component({
  selector: 'indicio-set-company-modal',
  templateUrl: './set-company-modal.component.html',
  styleUrls: ['./set-company-modal.component.less']
})
export class SetCompanyModalComponent extends ModalModelComponent {

  opts: SetCompanyModalOpts;
  companyId: string;
  companies: CompanyModel[];


  constructor(
    private companyService: CompanyFrontendService,
    private projService: ProjectFrontendService,
    private status: StatusService,
    public envService: EnvironmentService,
    protected store: Store,
    public appearance: AppearanceService,
    public clientService: ClientFrontendService,
    private orderPipe: OrderByPipe,
    private router: Router
  ) {
    super();
  }

  public setOptions(o: SetCompanyModalOpts) {
    this.opts = o;
    this.isLoading = false;
    this.companyId = o.companyId;
    this.companies = this.orderPipe.transform(this.companyService.companies, 'Name', 0, false);
  }

  public onClose() { }

  public setActiveCompany(change: MatSelectChange) {
    this.companyId = change.value;
  }

  public proceed() {
    if (!this.companyId.length) { return; }
    this.close();
    const differentCompany = this.companyId !== this.clientService.activeCompanyId;
    this.companyService.setActiveCompany(this.companyId, differentCompany)
      .then(c => {
        this.projService.fetchAllProjects(c.CompanyId);
        const onAdminPage = this.router.url.includes('admin');
        const returnUrl = StorageUtils.get(ForecastKeys.forecastReturnUrl) || (onAdminPage ? this.router.url : '/home');
        StorageUtils.remove(ForecastKeys.forecastReturnUrl);

        if (!differentCompany) {
          this.router.navigateByUrl(returnUrl);
        }
      })
      .catch(_err => {
        this.status.setMessage('Failed to set company', 'Error', true);
      });
  }
}
