import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MathService } from '@core/services/mathjax/mathjax.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[indicioMathJax]'
})
export class MathJaxDirective implements OnInit, OnChanges, OnDestroy {

  @Input() private indicioMathJax: string;
  @Input() private PreWrap: boolean = true;

  private alive$ = new Subject<boolean>();
  private readonly el: HTMLElement;

  constructor(private mathService: MathService, private elementRef: ElementRef) {
    this.el = elementRef.nativeElement;
  }

  ngOnInit() {
    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['MathJax'] && changes['MathJax'].currentValue) {
      this.render();
    }
  }

  private render() {
    this.mathService.ready().pipe(
      take(1),
      takeUntil(this.alive$)
    ).subscribe(() => this.mathService.render(this.el, this.indicioMathJax, this.PreWrap));
  }

  ngOnDestroy() {
    this.alive$.next(false);
  }

}
