<h1 mat-dialog-title>{{ data.Title }}</h1>
<div mat-dialog-content>
  <div *ngIf="data.Text"
       style="margin-bottom: 20px;"
       [innerHtml]="data.Text | safeHtml">
  </div>
  <mat-form-field>
    <mat-select [value]="data.MultiSelect ? answers : answer"
                [multiple]="data.MultiSelect"
                (selectionChange)="setAnswer($event)">
      <mat-option [value]="opt"
                  [indicioTippy]="opt?.Description || null"
                  *ngFor="let opt of options">{{ opt.Display }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">{{ data.CancelText }}</button>
  <button mat-flat-button
          color="primary"
          (click)="save()">
    {{ data.ConfirmText }}
  </button>
</div>
