<indicio-dialog-base>
  <h1 mat-dialog-title
      style="text-align:center;">{{edit ? 'Edit the' : 'Create a new'}} connection - Microsoft SQL server</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="!loading; else loader">
      <div style="padding: 0 10px;">
        <div class="container">
          <div class="row"
               *ngIf="!edit">
            <div class="col-xs-12">
              <div class="warning-box">
                <p>Important!<br>
                  Please select a user with read-only permissions when connecting to a SQL
                  database. This can help to protect the integrity and security of your database
                  by preventing unintended changes or modifications.</p>
              </div>
            </div>
          </div>
          <indicio-modal-option-row optionTitle="Server address"
                                    optionSubtitle="The address or hostname of the server hosting the SQL database."
                                    textWidth=6
                                    optionsWidth=6>
            <div class="row"
                 style="margin-top: 25px;"></div>
            <mat-form-field>
              <input type="text"
                     class="material"
                     matInput
                     autocomplete="off"
                     [ngModel]="connectDto.Url"
                     (ngModelChange)="setServer($event)"
                     name="serverUrl"
                     minlength="4"
                     placeholder="Server address"
                     required>
            </mat-form-field>
          </indicio-modal-option-row>
          <indicio-modal-option-row optionTitle="Port"
                                    optionSubtitle="The port number used to establish a connection (default 1433 for MS SQL)."
                                    textWidth=6
                                    optionsWidth=6>
            <div class="row"
                 style="margin-top: 25px;"></div>
            <mat-form-field>
              <input type="number"
                     [(ngModel)]="connectDto.Port"
                     class="material"
                     placeholder="Port"
                     matInput
                     autocomplete="off"
                     required>
            </mat-form-field>
          </indicio-modal-option-row>
          <indicio-modal-option-row optionTitle="Database"
                                    optionSubtitle="The name of the database on the server that you want to connect to."
                                    textWidth=6
                                    optionsWidth=6>
            <div class="row"
                 style="margin-top: 25px;"></div>
            <mat-form-field>
              <input type="text"
                     [(ngModel)]="connectDto.Database"
                     placeholder="Database name"
                     class="material"
                     matInput
                     autocomplete="off"
                     required>
            </mat-form-field>
          </indicio-modal-option-row>
          <indicio-modal-option-row optionTitle="Encrypt"
                                    optionSubtitle="If the server supports encrypted connections we recommend turning this on."
                                    textWidth=6
                                    optionsWidth=6>
            <div class="toggle-icon"
                 style="margin-top: 35px;">
              <mat-slide-toggle data-test-id='auto-variable-update-toggle-btn'
                                [checked]="connectDto.Encrypt"
                                (change)="connectDto.Encrypt = !connectDto.Encrypt">
              </mat-slide-toggle>
            </div>
          </indicio-modal-option-row>
          <indicio-modal-option-row optionTitle="Username"
                                    optionSubtitle="The username used to authenticate when connecting to the database."
                                    textWidth=6
                                    optionsWidth=6>
            <div class="row"
                 style="margin-top: 25px;"></div>
            <mat-form-field>
              <input type="text"
                     [(ngModel)]="connectDto.Username"
                     placeholder="Username"
                     class="material"
                     matInput
                     name="username-database"
                     required>
            </mat-form-field>
          </indicio-modal-option-row>
          <indicio-modal-option-row optionTitle="Password"
                                    optionSubtitle="The password associated with the username."
                                    textWidth=6
                                    optionsWidth=6>
            <div class="row"
                 style="margin-top: 25px;"></div>
            <mat-form-field>
              <input type="password"
                     [(ngModel)]="connectDto.Password"
                     placeholder="Password"
                     class="material"
                     matInput
                     name="password"
                     autocomplete="new-password"
                     required>
            </mat-form-field>
            <div class="row"
                 *ngIf="edit && !connectDto.Password">
              <div class="warning-box"
                   style="color:red;">
                It is mandatory to rewrite or update the password when updating the database connection for security
                reasons.
              </div>
            </div>
          </indicio-modal-option-row>
          <indicio-modal-option-row optionTitle="Name"
                                    optionSubtitle="The name of the new connection."
                                    textWidth=6
                                    optionsWidth=6>
            <div class="row"
                 style="margin-top: 15px;"></div>
            <mat-form-field>
              <input type="text"
                     [ngModel]="connectDto.Name"
                     (ngModelChange)="setName($event)"
                     placeholder="Name"
                     class="material"
                     matInput
                     autocomplete="off"
                     required>
            </mat-form-field>
          </indicio-modal-option-row>
        </div>
      </div>
    </ng-container>
  </div>

  <div mat-dialog-actions
       style="padding-left: 38px;
       padding-right: 45px;">
    <button mat-stroked-button
            (click)="onNoClick()">
      Cancel
    </button>
    <div [indicioTippy]="!inputIsOk() ? messages.join('<br>') : null">
      <button [disabled]="!inputIsOk() || loading"
              mat-flat-button
              color="primary"
              (click)="connect()">
        Connect
      </button>
    </div>
  </div>

  <ng-template #loader>
    <div style="text-align:center;display: inline;"
         class="vertical-align row">
      <div>
        <i class="ion-load-c spinner"></i> Loading... Please wait.
      </div>
    </div>
  </ng-template>


</indicio-dialog-base>
