import { HistoricValueDTO, PlotValue, PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ForecastResultModel } from '@core/store/forecast-result/entities/forecast-result.model';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { FredObservationsModel } from '@core/store/providers/fred/fred-observations.model';
import { ScenarioDTO, ScenarioFinalResultModelDTO } from '@core/store/scenario/dtos/scenario.dtos';
import { ForecastBenchmarkModel } from '@core/store/source-variable/forecast-benchmark.model';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { EmptyModelName } from '@modules/lang/types/model-name';
import { DateUtils } from '@shared/utils/date.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import { ALGLineModel, ALGSingleSeriesModel } from './alg-models/graph-data.model';
import { ALGTypes } from './alg-types';
import { StrokeSetups } from './alg.options';

export namespace AlgConverter {

  export function mapAlgModelFromReconciliation(result: ForecastResultModel): ALGSingleSeriesModel {
    return {
      Color: result.color,
      Description: `Reconciled result from hierarchy ${result.shortname}`,
      WFittedValues: result.FittedValues.map(v => ({ ...v, WF: v.F })),
      FittedValues: [],
      Name: result.shortname,
      PastForecasts: result.PastForecasts,
      Periodicity: result.Periodicity,
      ShapValues: [],
      RollingAccuracy: result.RollingAccuracy,
      show: true,
      Values: result.Values,
      WShapValues: result.ShapModels,
      modelName: (() => { const x = EmptyModelName(); x.Display = result.shortname; x.Value = result.shortname; return x; })(),
    };
  }

  export function mapAlgModelFromResult(result: ForecastResultModel): ALGSingleSeriesModel {
    return {
      Color: result.color,
      Description: `Forecast result ${result.Name}`,
      WFittedValues: result.FittedValues.map(v => ({ ...v, WF: v.F })),
      FittedValues: [],
      Name: result.Name,
      RollingAccuracy: result.RollingAccuracy,
      PastForecasts: result.PastForecasts,
      Periodicity: result.Periodicity,
      ShapValues: [],
      show: true,
      Values: result.Values,
      WShapValues: result.ShapModels,
      modelName: (() => { const x = EmptyModelName(); x.Display = result.Name; x.Value = result.Name; return x; })(),
    };
  }

  export function mapForecastResultLine(result: ForecastResultModel, fVersion: ForecastVersionModel): ALGLineModel {
    const mainVar = fVersion.ForecastVariable;
    const lastMoment = DateUtils.newMoment(fVersion.DataUntilDateRequirement);
    const lhv = mainVar.ForecastVariableValues.find(x => x.m.isSame(lastMoment, 'day'));
    return {
      Name: result.shortname,
      Active: true,
      Id: result.ForecastResultId,
      Values: [lhv as PlotValueDTO, ...result.Values],
      Color: result.color,
      Stroke: StrokeSetups.Result,
      IsHistoric: false,
      Type: 'Forecast result',
      modelName: null,
      Segments: []
    };
  }

  export function getHistoricFromAlgModel(model: ALGSingleSeriesModel, miniAlg: boolean = false): ALGLineModel {
    if (model == null) { return null; }
    const historicValues = model.Values.filter(v => !v.IF);
    return {
      Active: true,
      Color: 'white',
      Id: model.Id,
      IsHistoric: true,
      Name: model.Name,
      Segments: [],
      Stroke: StrokeSetups.SolidLine,
      Type: 'Forecast variable',
      Values: miniAlg ? historicValues.slice(-15) : historicValues
    };
  }



  export function singleSeriesFromValues(values: PlotValueDTO[], desc: string, name: string, id: string, p: PeriodicityType) {
    const ans: ALGSingleSeriesModel = {
      Description: desc,
      Name: name,
      modelName: (() => { const x = EmptyModelName(); x.Display = name; x.Value = name; return x; })(),
      Values: values,
      PastForecasts: [],
      Id: id,
      RollingAccuracy: false,
      Periodicity: p,
      show: true,
      FittedValues: [],
      WFittedValues: [],
      ShapValues: [],
      WShapValues: [],
      Color: 'white'
    };
    return ans;
  }

  export function fromForecastVariable(variable: ForecastVariableModel, miniAlg: boolean = false, beforeDate: Date = null): ALGLineModel {
    let values = miniAlg ? variable.ForecastVariableValues.slice(-15) : variable.ForecastVariableValues;
    if (beforeDate !== null) {
      values = values.filter(x => x.D < beforeDate);
    }
    return fromSpeedHistoricValues(values, variable.Name, variable.ForecastVariableId);
  }

  export function fromSpeedHistoricValues(values: HistoricValueDTO[], name: string, id: string, color = 'white') {
    const model: ALGLineModel = {
      Values: values.map(x => {
        const v = new PlotValueDTO();
        v.D = x.D;
        v.m = x.m;
        v.V = (x.A || x.A === 0) ? x.A : (x.V || x.V === 0) ? x.V : (x.M || x.M === 0) ? x.M : null;
        v.VO = x.VO;
        v.VS = x.VS;
        v.M = x.M;
        v.A = x.A;
        return v;
      }),
      Type: 'Forecast variable',
      Name: name,
      Active: true,
      Color: color,
      Id: id,
      Stroke: StrokeSetups.SolidLine,
      IsHistoric: true,
      Segments: []
    };
    return model;
  }

  export function fromPlotValues(values: PlotValueDTO[], name: string, color: string, type: ALGTypes.LineModelType) {
    values.forEach(x => x.m = DateUtils.newMoment(x.D))
    const model: ALGLineModel = {
      Values: values,
      Name: name,
      Active: true,
      Color: color,
      Id: '',
      Stroke: StrokeSetups.SolidLine,
      IsHistoric: false,
      Type: type,
      Segments: []
    };

    return model;
  }

  export function fromSourceVariable(variable: SourceVariableVersionModel) {
    const values = variable.PlotValues;
    const model: ALGLineModel = {
      Values: values as PlotValueDTO[],
      Name: variable.Name,
      Active: true,
      Color: 'white',
      Id: variable.SourceVariableMetaId,
      Stroke: StrokeSetups.SolidLine,
      IsHistoric: true,
      Type: 'Source variable',
      Segments: []
    };

    return model;
  }

  export function fromRawFredData(variable: FredObservationsModel, name: string) {
    const values = variable.observations;
    const model: ALGLineModel = {
      Values: values.map(x => {
        const v = new PlotValueDTO();
        v.D = DateUtils.newDate(x.date);
        v.m = DateUtils.newMoment(x.date);
        v.V = +x.value;
        return v;
      }),
      IsHistoric: true,
      Type: 'Data vendor',
      Name: name,
      Active: true,
      Color: 'white',
      Id: variable.uri,
      Stroke: StrokeSetups.SolidLine,
      Segments: []
    };

    return model;
  }

  export function mapScenarioLine(scenario: ScenarioDTO, model: ScenarioFinalResultModelDTO, historicData: PlotValueDTO[]): ALGLineModel {
    const values = [...historicData, ...model.PlotData];
    const lastIdx = values.findIndex(v => !!v.A50);
    return {
      Name: scenario.Name,
      Active: true,
      Id: scenario.ScenarioId,
      Values: values.slice(lastIdx - 1),
      Color: scenario.color,
      Stroke: StrokeSetups.Scenario,
      IsHistoric: false,
      Type: 'Scenarios',
      Segments: [],
      modelName: (() => {
        const x = EmptyModelName();
        x.Display = `Scenario: ${scenario.Name}`;
        x.Value = `Scenario: ${scenario.Name}`;
        return x;
      })(),
    };
  }

  export function mapScenarioSingleSeries(scenario: ScenarioDTO, model: ScenarioFinalResultModelDTO, periodicity: PeriodicityType, historicData: PlotValueDTO[]) {
    const ans: ALGSingleSeriesModel = {
      Description: 'Scenario',
      Name: 'Weighted',
      modelName: (() => { const x = EmptyModelName(); x.Display = 'Weighted'; x.Value = 'Weighted'; return x; })(),
      Values: [...historicData, ...model.OriginalResult],
      PastForecasts: [],
      Id: model.ForecastVariableId,
      Periodicity: periodicity,
      RollingAccuracy: false,
      show: true,
      FittedValues: [],
      WFittedValues: [],
      ShapValues: [],
      WShapValues: [],
      Color: 'white',
    };
    return ans;
  }


  export function mapBenchmarkLine(
    bench: ForecastBenchmarkModel,
    mainVariable: ForecastVariableModel,
    dataAdjustment: ALGTypes.Data,
    date: Date): ALGLineModel {

    let hData = mainVariable.ForecastVariableValues.map(v => ValueUtils.copyValue<PlotValue>(v));
    switch (dataAdjustment) {
      case ALGTypes.Data.seasonal:
        hData = ValueUtils.setSeasonalValues(hData as PlotValueDTO[]);
        break;
      case ALGTypes.Data.outlier:
        hData = ValueUtils.setOutlierValues(hData as PlotValueDTO[]);
        break;
      case ALGTypes.Data.original:
      default:
        break;
    }

    const lastDate = DateUtils.newMoment(date);
    const lastHistoric = hData.find(x => x.m.isSame(lastDate, 'day'));

    const res: ALGLineModel = {
      Name: bench.Name,
      Active: true,
      Id: bench.ForecastBenchmarkId,
      Color: '#ff0000',
      Stroke: StrokeSetups.Benchmark,
      IsHistoric: false,
      Type: 'Benchmarks',
      Values: bench.Values.filter(x => x.V !== null && x.V !== undefined).map(x => {
        const vm = new PlotValueDTO;
        vm.V = x.V;
        vm.D = DateUtils.newDate(x.D);
        vm.m = DateUtils.newMoment(x.D);
        vm.IF = true;
        return vm;
      }),
      Segments: []
    };
    // Add last historic value
    res.Values.unshift(lastHistoric as PlotValueDTO);
    return res;
  }
}

