import { Injectable } from '@angular/core';
import { IFilterDropdown } from '@core/interfaces/if-filter-dropdown';
import { IFilter } from '@core/interfaces/if-provider-filter';
import { ClientSettingsService } from '@core/store/client/client-settings.service';
import { IDialogFilter } from '@dialogs/generic/filter/filter.dialog.constants';
import { FilterPipe } from '@shared/modules/pipes';
import { StringUtils } from '@shared/utils/string.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import { isMoment } from 'moment';

export class ComponentFilter {
  public name: string;
  public availableFilters: IDialogFilter[];
  public activeFilterCount: number = 0;

  public get savableFilters() {
    return this.availableFilters.filter(x => x.savable);
  }

  public get activeFilters() {
    return this.availableFilters.filter(filter => {
      switch (true) {
        case typeof filter.value === 'boolean': {
          return filter.value === true;
        }
        case Array.isArray(filter.value): {
          return !!filter.value?.length;
        }
        case typeof filter.value === 'string' && filter.type !== 'regex': {
          return !!filter.value?.length;
        }
        case ValueUtils.isRegexValue(filter.value) || filter.type === 'regex': {
          return !!filter.value;
        }
        case isMoment(filter.value): {
          return !!filter.value;
        }
      }
    });
  }

  public initFilters(filters: IFilter[]) {
    this.savableFilters.forEach(filter => {
      const f = filters?.length ? filters.find(x => x.key === filter.key) : undefined;
      if (f) { filter.value = f.value; }
    });
    this.setFilters(this.savableFilters);
    this.countActiveFilters();
  }

  public setFilters(filtersToSet: IDialogFilter[]) {
    filtersToSet.forEach(filter => {
      switch (true) {
        case typeof filter.value === 'boolean': {
          this.toggleBooleanFilter(filter);
          break;
        }
        case Array.isArray(filter.value): {
          this.toggleArrayFilter(filter);
          break;
        }
        case typeof filter.value === 'string' || filter.type === 'regex': {
          this.toggleStringFilter(filter);
          break;
        }
        case ValueUtils.isRegexValue(filter.value): {
          this.toggleRegexFilter(filter);
          break;
        }
        case filter.type === 'date': {
          this.toggleDateFilter(filter);
        }
      }
    });
    this.countActiveFilters();
  }

  public resetFilter(filter: IDialogFilter, value?: string) {
    filter.itemsRemoved = 0;
    switch (true) {
      case typeof filter.value === 'boolean': {
        filter.value = false;
        break;
      }
      case Array.isArray(filter.value): {
        if (value) { filter.value.splice(filter.value.indexOf(value), 1); break; }
        filter.value = [];
        break;
      }
      case typeof filter.value === 'string' || filter.type === 'regex': {
        filter.value = '';
        break;
      }
      case ValueUtils.isRegexValue(filter.value): {
        filter.value = null;
        break;
      }
      case filter.type === 'date': {
        filter.value = null;
      }
    }

    this.setFilters(this.availableFilters);
    this.countActiveFilters();
  }

  public countActiveFilters() {
    this.activeFilterCount = this.activeFilters.length;
    return this.activeFilterCount;
  }

  public resetFilteredItems() {
    this.availableFilters.forEach(filter => filter.itemsRemoved = 0);
  }

  private toggleStringFilter(filter: IDialogFilter) {
    if (!filter.value?.length) {
      return this.availableFilters.find(x => x.key === filter.key).value = '';
    }
    this.availableFilters.find(x => x.key === filter.key).value = filter.value;
  }

  private toggleRegexFilter(filter: IDialogFilter) {
    if (!filter.value?.length) {
      return this.availableFilters.find(x => x.key === filter.key).value = null;
    }
    this.availableFilters.find(x => x.key === filter.key).value = filter.value;
  }

  private toggleArrayFilter(filter: IDialogFilter) {
    if (!filter.value?.length) {
      return this.availableFilters.find(x => x.key === filter.key).value = [];
    }

    Object.values(filter.value).forEach((value, index) => {
      if (!filter.values?.some(x => x.value === value)) {
        filter.value.splice(index, 1);
      }
    });

    this.availableFilters.find(x => x.key === filter.key).value = filter.value;
  }

  private toggleBooleanFilter(filter: IDialogFilter) {
    this.availableFilters.find(x => x.key === filter.key).value = filter.value === undefined ? true : filter.value;
  }

  private toggleDateFilter(filter: IDialogFilter) {
    if (!isMoment(filter.value)) {
      return this.availableFilters.find(x => x.key === filter.key).value = null;
    }
    this.availableFilters.find(x => x.key === filter.key).value = filter.value;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public components: ComponentFilter[] = [];

  constructor(
    private clientSettings: ClientSettingsService,
    private filterPipe: FilterPipe,
  ) { }

  public getComponentFilter(componentName: string) {
    return this.components.find(x => x.name === componentName);
  }

  public setComponentFilter(componentName: string, filtersToSet: IDialogFilter[]) {
    let component = this.getComponentFilter(componentName);
    if (!component) {
      component = new ComponentFilter();
      component.name = componentName;
      this.components.push(component);
    }
    component.availableFilters = filtersToSet;
    return component;
  }


  public filterSeries(componentName: string, series: any[], resetCount = true) {
    const component = this.getComponentFilter(componentName);
    if (!component || !component.activeFilters.length) { return series; }
    component.activeFilters.forEach((filter) => {
      series = this.applyFilter(series, filter, resetCount);
    });
    return series;
  }

  public applyFilter(series: any[], filter: IDialogFilter, resetCount: boolean) {
    if (!filter.itemsRemoved || resetCount) { filter.itemsRemoved = 0; }
    let tempVal = this.isValueDropdownObject(filter.value) ? (<IFilterDropdown[]> filter.value).map(x => x.value) : filter.value;
    const beforeCount = series?.length || 0;
    if (filter.type === 'regex') { tempVal = new RegExp(StringUtils.escapeRegex(filter.value), 'ig'); }

    if (filter.type === 'dropdown-match' && filter.match) {
      const tempSeries = [];
      filter.value.forEach(f => {
        tempSeries.push(...series.slice().filter(x => filter.match.values?.find(y => y.key === f)?.values?.includes(x[filter.match.identifier])));
      });
      series = tempSeries;
    } else {
      const hardMatch = filter.key.startsWith('!');
      const key = hardMatch ? filter.key.substring(1) : filter.key;
      if (tempVal?.length === 1 && tempVal[0] === 'xXx') { tempVal = []; }
      series = this.filterPipe.transform(series, key, tempVal, undefined, hardMatch);
    }
    const afterCount = series?.length || 0;
    filter.itemsRemoved += beforeCount - afterCount;
    return series;
  }

  private isValueDropdownObject(filterValue: any) {
    if (!Array.isArray(filterValue)) { return false; }
    return filterValue.some(x => typeof x === 'object' && x.hasOwnProperty('value') && x.hasOwnProperty('display'));
  }

  public saveFilter(component: ComponentFilter) {
    const filter = component.savableFilters
      .map(x => <IFilter> { key: x.key, value: x.value });

    if (!filter.length) { return; }
    return this.clientSettings.saveDataManagementSettings(component.name, filter);
  }
}
