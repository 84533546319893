import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigateToDataProvider } from '@core/actions/navigation.actions';
import { DataProvider } from '@core/constants/provider-definitions';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { Base64FileDTO } from '@core/store/file/dtos/file-dto';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { BloombergService } from '@core/store/providers/bloomberg/bloomberg.service';
import { BloombergCredentialsDTO } from '@core/store/providers/bloomberg/entities/bloomberg-credentials.dtos';
import { ProviderFrontendService } from '@core/store/providers/provider.frontend.service';
import { DialogV2BaseDialog } from '@modules/dialog/dialogs/base/dialogs.V2.base-dialog';
import { Store } from '@ngxs/store';

export class BloombergAddCredentialsData { }

@Component({
  selector: 'indicio-bloomberg-add-import-dialog',
  templateUrl: 'add-credentials.dialog.html',
  styleUrls: ['add-credentials.dialog.less']
})
export class BloombergAddCredentialsDialogComponent extends DialogV2BaseDialog<BloombergAddCredentialsDialogComponent> {

  public static Id: string = 'BloombergAddCredentialsDialogComponent';

  public files: Base64FileDTO[] = [];
  public uri: string = 'eap/catalogs';
  public response: string[][] = [];
  public pending: boolean;
  public error: boolean;

  public credentials: BloombergCredentialsDTO = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BloombergAddCredentialsData,
    public dialogRef: MatDialogRef<BloombergAddCredentialsDialogComponent, boolean>,
    private fcService: ForecastFrontendService,
    private service: BloombergService,
    private providerService: ProviderFrontendService,
    private status: StatusService,
    private store: Store,
    private clientService: ClientFrontendService,) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.initialized = true;
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  //
  // File drop-zone functions
  //
  public fileChange(fileList: FileList) {
    this.error = false;
    this.resetList();
    Promise.all(this.getFileList(fileList))
      .then(files => files.map(file => {
        try {
          this.credentials = Object.assign(new BloombergCredentialsDTO, JSON.parse(atob(file.Base64String)));
          this.credentials.expirationDate = new Date(this.credentials.expiration_date);
          this.credentials.creationDate = new Date(this.credentials.created_date);
        } catch (e) {
          this.error = true;
          throw 'Error parsing bloomberg credentials file';
        }
      }))
      .then(promises => Promise.all(promises));
  }

  public addCredentials() {
    this.pending = true;
    return this.service.addCredentials(this.credentials, this.clientService.activeCompanyId)
      .then(() => this.providerService.getRemoteSources())
      .then(() => {
        this.status.setMessage('Credentials added successfully', 'Success', false);
        this.dialogRef.close(true);
        this.store.dispatch(new NavigateToDataProvider(this.fcService.activeForecastId, DataProvider.bloomberg));
      })
      .catch(() => {
        this.status.setMessage('Error adding credentials', 'Error', true);
      })
      .finally(() => {
        this.pending = false;
      });
  }

  public allowDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  public drop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const inputElement = <HTMLInputElement> (document.querySelector('input[type=file]'));
    inputElement.files = event.dataTransfer.files;
    this.fileChange(inputElement.files);
    const box = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    box.classList.remove('active');
  }

  public toggleDragOver(state: boolean) {
    const element = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    if (state) {
      element.classList.add('active');
    } else {
      element.classList.remove('active');
    }
  }

  private getFileList(fileList: FileList) {
    const promises: Promise<Base64FileDTO>[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const dto = Object.assign(new Base64FileDTO(), <Base64FileDTO> { Name: file.name, MimeType: file.type });
      const promise = new Promise<Base64FileDTO>((res, rej) => {
        const dataReader = new FileReader();
        dataReader.onloadend = () => {
          const body: string = <string> dataReader.result;
          dto.Base64String = body.substring(body.indexOf(',') + 1);
          res(dto);
        };
        dataReader.onerror = rej;
        dataReader.readAsDataURL(file);
      });
      promises.push(promise);
    }
    return promises;
  }

  private resetList() {
    this.files = [];
    this.status.removeMessage();
  }
}
