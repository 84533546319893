import { Def } from '@core/decorators/def';
import { ICanFilter } from '@core/interfaces/if-can-filter';
import { IFile } from '@core/interfaces/if-file';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { FileVersionModel } from '@core/store/file/models/file-version.model';
import { RemovedStatus } from '@core/types/removed.status.type';
import { FileAccessDTO } from '../dtos/file-access.dto';
import { VariableSheetDTO } from '../dtos/variable-sheet-dto';

export class UploadedFileModel implements IHasModelId, ICanFilter, IFile {

  @Def() UploadedFileId: string;
  @Def() CompanyId: string;
  @Def() CreatedDate: Date;
  @Def() FileData: any = null;
  @Def() FileName: string;
  @Def() OwnerEmail: string;
  @Def() Shared: boolean;
  @Def() ParsedData: VariableSheetDTO[];
  @Def() Separator: string = null;
  @Def() Versions: FileVersionModel[];
  @Def() Extension: string;
  @Def() Used: boolean;
  @Def() NewColumns: boolean;
  @Def() SourceType: 'file' = 'file';
  @Def() Users: FileAccessDTO[];

  removedStatus: RemovedStatus;
  errorStatus: string = null;
  containsLT1Values: boolean = false;
  loading: boolean = false;
  fetched = false;
  forceUpdate: boolean = false;
  isCsv: boolean = false;

  public getModelId(): string { return this.UploadedFileId; }
  public getSourceType() { return this.SourceType; }
  public latestVersion() { return this.Versions.length ? this.Versions.last() : null; }

  public get ObjectName() { return this.FileName; }
  public get numberOfVersions() { return this.Versions.length; }
  public get latestCreatedDate() { return this.latestVersion() ? this.latestVersion().CreatedDate : null; }
}


