import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearStateAction } from '../auth/auth.actions';
import { CompanyActions } from '../company/company.actions';
import { VarSelectResultModel } from './var-select-result.model';
import { VarSelectActions } from './var-select.actions';


export class VarSelectStateModel {
  Results: VarSelectResultModel[];
}

@State<VarSelectStateModel>({
  name: 'varSelect',
  defaults: {
    Results: []
  }
})
@Injectable()
export class VarSelectState {

  @Selector()
  static varSelectResults(_state: VarSelectStateModel) { return _state.Results; }

  @Selector()
  static vsByForecastVersionId(state: VarSelectStateModel) {
    return (id: string) => state.Results.find(s => s.ForecastVersionId === id);
  }

  @Action(VarSelectActions.TriggerSuccess)
  triggerVarSelectSuccessAction(ctx: StateContext<VarSelectStateModel>, action: VarSelectActions.TriggerSuccess) {
    ctx.dispatch(new VarSelectActions.RemoveResult(action.forecastVersionId));
  }

  @Action(VarSelectActions.RemoveResult)
  removeVarSelectResultAction(ctx: StateContext<VarSelectStateModel>, action: VarSelectActions.RemoveResult) {
    const results = ctx.getState().Results;
    const toKeep = results.removeByKey('ForecastVersionId', action.forecastVersionId);
    ctx.patchState({ Results: [...toKeep] });
  }

  @Action(VarSelectActions.UpdatedSettingsSucces)
  updatedVarSelectSettingsSuccessAction(ctx: StateContext<VarSelectStateModel>, action: VarSelectActions.UpdatedSettingsSucces) {
    if (action.forecastVersionId == null) { return; }
    const all = ctx.getState().Results;
    const results = all.filter(res => res.ForecastVersionId === action.forecastVersionId);
    if (results?.length) {
      results.forEach(r => r.update(action.settings));
    }
    ctx.dispatch(new VarSelectActions.GetSuccess(results));
  }

  @Action(VarSelectActions.GetSuccess)
  getVariableSelectSuccess(ctx: StateContext<VarSelectStateModel>, action: VarSelectActions.GetSuccess) {
    if (!action.varSelects?.length) { return; }
    const results = ctx.getState().Results;
    action.varSelects.forEach(vs => {
      const idx = results.findIndex(x => x.VsResultType === vs.VsResultType && x.ForecastVersionId === vs.ForecastVersionId);
      if (idx === -1) {
        results.push(vs);
      } else {
        results[idx] = vs;
      }
    });
    ctx.dispatch(new VarSelectActions.SetResultOnVariables(action.varSelects));
    ctx.patchState({ Results: [...results] });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  clearClient(ctx: StateContext<VarSelectStateModel>, _action: ClearStateAction) {
    ctx.patchState({ Results: [] });
  }
}
