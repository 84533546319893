import { DisplayValue } from '../types/display-value';

export class AssessmentCategory extends DisplayValue<string> { };
export const AssessmentCategories = [
  new AssessmentCategory('political', 'Political', 'Indicates that the assessment is due to a political decision'),
  new AssessmentCategory('economical', 'Economical', 'Indicates that the assessment is due to an economical event, e.g. a stock market crash'),
  new AssessmentCategory('weather', 'Weather', 'Indicates that the assessment is due to weather'),
  new AssessmentCategory('disaster', 'Disaster', 'Indicates that the assessment is due to a natural disaster such as earthquake or flood'),
  new AssessmentCategory('war', 'War', 'Indicates that the assessment is due to war or armed conflicts'),
  new AssessmentCategory('terrorism', 'Terrorism', 'Indicates that the assessment is due to terrorism'),
  new AssessmentCategory('infrastructure', 'Infrastructure', 'Indicates that the assessment is due to a infrastrucutre event, such as a power outage'),
  new AssessmentCategory('other', 'Other', 'The reason behind this assessment is not known')
];

export class AssessmentLevel extends DisplayValue<string> { };
export const AssessmentLevels = [
  new AssessmentLevel('high', 'High', 'Indicates a high trust level'),
  new AssessmentLevel('medium', 'Medium', 'Indicates a medium trust level'),
  new AssessmentLevel('low', 'Low', 'Indicates a low trust level')
];

export class AssessmentUnit extends DisplayValue<string> { };
export const AssessmentUnits = [
  new AssessmentUnit('percent', 'Percent', 'Value is adjusted percent-wise'),
  new AssessmentUnit('absolute', 'Absolute', 'Value is set to the supplied value'),
  new AssessmentUnit('nominal', 'Nominal', 'Value is nominally adjusted'),
  new AssessmentUnit('roc', 'ROC', 'Assessment is made with Rate-Of-Change (ROC) data'),
  new AssessmentUnit('roc-yy', 'ROC-YY', 'Assessment is made with Rate-Of-Change Year-to-Year (ROC-YY) data')
];




