import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CreateProjectDTO } from '@core/store/project/dtos/create-project-dto';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';


export class CreateProjectDialogData { }

@Component({
  selector: 'indicio-create-project-dialog',
  templateUrl: 'create-project.dialog.html',
})
export class CreateProjectDialogComponent extends DialogV2BaseDialog<CreateProjectDialogComponent> {

  public static Id: string = 'CreateProjecttDialogComponent';

  public unsaved = false;
  public pending = false;

  // The new project
  public project: CreateProjectDTO = null;
  public nameConflict: boolean;

  public get disabledText() {
    if (!this.project.Name) { return 'Enter a name for the forecast'; }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CreateProjectDialogData,
    public envService: EnvironmentService,
    private projectService: ProjectFrontendService,
    private companyService: CompanyFrontendService,
    private statusService: StatusService,
    dialogRef: MatDialogRef<CreateProjectDialogComponent, CreateProjectDialogData>,
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected async initialize() {
    this.project = this.projectService.tempNewProject;
    if (this.projectService.tempNewProject.unsaved) {
      this.unsaved = true;
    }
    this.initialized = true;
  }

  public setName(name: string) {
    this.project.Name = name.trim();
    if (this.projectService.projects.find(project => project.Name === name)) {
      this.setError(true);
    } else {
      this.setError(false);
    }
    this.project.unsaved = true;
  }


  private setError(state: boolean) {
    this.nameConflict = state;
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public save() {
    if (this.pending || this.nameConflict) { return; }
    this.pending = true;
    return this.projectService.createProject(this.project, this.companyService.activeCompanyId)
      .then(proj => {
        this.statusService.setMessage('Project created', 'Success');
        this.dialogRef.close(proj.ProjectId);
      })
      .catch(error => {
        this.statusService.setError(error, true);
      })
      .finally(() => this.pending = false);
  }

}
