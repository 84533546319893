<h1 mat-dialog-title>{{ data.Title }}
  <ng-container *ngIf="data.Subtitle?.length">
    <br>
    <span class="subtitle">{{ data.Subtitle }}</span>
  </ng-container>
</h1>
<div mat-dialog-content>
  <div class="row extra-margin-top">
    <div class="col-xs-12">
      <indicio-alg [periodicityType]="data.Periodicity"
                   [Lines]="data.Lines"
                   [HistoricLine]="data.HistoricLine"
                   [models]="data.Models"
                   [options]="data.Options"
                   [chartStyles]="chartStyles"
                   [showExport]="false"
                   graphType="generic">
      </indicio-alg>
    </div>
  </div>
  <div *ngIf="data.TableInfo?.length"
       class="row">
    <div class="col-xs-12">
      <div *ngFor="let entry of data.TableInfo"
           class="grid-system"
           style="--grid-template: 100px 1fr">
        <div>{{ entry.Title }}</div>
        <div>{{ entry.Value }}</div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions
     style="padding-right: 10px">
  <div></div>
  <button mat-stroked-button
          (click)="onNoClick()">
    Close
  </button>
</div>
