<indicio-dialog-base>
  <h1 mat-dialog-title>Periodicity identification</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="periodicityInfos.length > 1; else nothingToDoTemplate">
      <ng-container *ngTemplateOutlet="setPeriodicityTemplate"></ng-container>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button
            (click)="onNoClick()">
      Close
    </button>
  </div>
</indicio-dialog-base>

<ng-template #setPeriodicityTemplate>
  We could not determine the periodicity of this variable, please review the following options and select the
  correct one from the list below.

  <div class="extra-margin-top">
    <indicio-data-table columnTemplate="1fr 120px 120px 100px">
      <div title-row>
        <div column
             left>
          <span title>Periodicity</span>
        </div>
        <div column
             center>
          <span title>Extra values</span>
        </div>
        <div column
             center>
          <span title>Missing values</span>
        </div>
        <div column>
          <span title></span>
        </div>
      </div>
      <ng-container>
        <div row
             *ngFor="let info of periodicityInfos">
          <div column>
            <span>{{ env.getPeriodicity(info.Periodicity).Display }}</span>
          </div>
          <div column
               center
               [ngClass]="{ 'pointer dotted-underline': info.Extra > 0 && !loadingPInfo }"
               [indicioTippy]="info.Extra > 0 ? getExtraMissingInfoHover(info.Periodicity, 'Extra') : null">
            <span>{{ info.Extra }}</span>
            <ng-container *ngIf="info.Extra > 0 && loadingPInfo">
              &nbsp;<i class="ion-load-c spinner"></i>
            </ng-container>
          </div>
          <div column
               center
               [ngClass]="{ 'pointer dotted-underline': info.Missing > 0 && !loadingPInfo }"
               [indicioTippy]="info.Missing > 0 ? getExtraMissingInfoHover(info.Periodicity, 'Missing') : null">
            <span>{{ info.Missing }}</span>
            <ng-container *ngIf="info.Missing > 0 && loadingPInfo">
              &nbsp;<i class="ion-load-c spinner"></i>
            </ng-container>
          </div>
          <div column
               center>
            <button (click)="setPeriodicity(info.Periodicity)"
                    mat-flat-button>Use</button>
          </div>
        </div>
      </ng-container>
    </indicio-data-table>

    <div class="extra-margin-top">
      Indicio identified the most likely periodicity to be
      <strong>
        {{ env.getPeriodicity(sourceVariable.PeriodicityInfo.BestFit.Periodicity).Display.toLowerCase() }}
      </strong>
      since extra values usually implies errors in the time series.
    </div>
  </div>
</ng-template>

<ng-template #nothingToDoTemplate>
  <div>
    The periodicity has been automatically identified as
    <strong>{{ env.getPeriodicity(sourceVariable.PeriodicityInfo.BestFit.Periodicity).Display.toLowerCase() }}</strong>,
    no further action is required.
  </div>
</ng-template>
