<indicio-modal-base [child]="this"
                    [modalTitle]="modalTitle"
                    [loading]="isLoading">

  <div *ngIf="indicator">
    <h2>{{ indicator.Name }}</h2>

    <div class="margin-top selectable-text"
         style="max-height: 200px; overflow: auto"></div>

    <div class="row extra-margin-top"
         *ngIf="meta.Description">
      <div class="col-xs-4">Description:</div>
      <div class="col-xs-8 crop-string">
        <span [title]="meta.Description">{{ meta.Description }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4">Date range:</div>
      <div class="col-xs-8">
        {{ databank.StartYear | dateFormat: 'year' }} - {{ (meta.HistoricalEndQuarter || meta.HistoricalEndYear) |
        dateFormat: 'year' }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4">Last updated:</div>
      <div class="col-xs-8">
        {{ meta.LastUpdate | dateFormat: 'date' }}
      </div>
    </div>

    <div class="row"
         *ngIf="meta.ScaleFactor">
      <div class="col-xs-4">Scale factor:</div>
      <div class="col-xs-8">
        {{ meta.ScaleFactor }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4">Seasonaly adjusted:</div>
      <div class="col-xs-8">
        {{ meta.SeasonallyAdjusted ? 'Yes' : 'No' }}
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4">Units:</div>
      <div class="col-xs-8">
        {{ meta.Units }}
      </div>
    </div>

    <div class="row"
         *ngIf="meta.Source">
      <div class="col-xs-4">Source:</div>
      <div class="col-xs-8 crop-string">
        <span [title]="meta.Source">{{ meta.Source }}</span>
      </div>
    </div>

    <div class="row"
         *ngIf="meta.SourceDescription">
      <div class="col-xs-4">Source description:</div>
      <div class="col-xs-8">
        {{ meta.SourceDescription }}
      </div>
    </div>

    <form action=""
          ngNativeValidate>
      <div class="row extra-margin-top">
        <div class="col-xs-12 input-label">
          <mat-form-field>
            <mat-label>Enter name</mat-label>
            <input matInput
                   autocomplete="off"
                   [(ngModel)]="remoteDataModel.Name"
                   (ngModelChange)="checkName()"
                   name="Name"
                   maxlength="64"
                   required>
            <mat-hint *ngIf="nameConflict">
              <span class="error-text">A source variable with that name already exists.</span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row"
           *ngIf="periodicityOptions.length > 1">
        <div class="col-xs-12 input-label">
          <mat-form-field>
            <mat-select [(value)]="periodicity"
                        required
                        placeholder="Choose periodicity">
              <mat-option [value]="option.Value"
                          *ngFor="let option of periodicityOptions">{{ option.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 input-label">
          <mat-form-field>
            <mat-select [(value)]="aggregationMethodId"
                        placeholder="Aggregation method"
                        required>
              <mat-option [value]="option.Value"
                          *ngFor="let option of env.AggregationMethods">{{ option.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="btn-wrapper extra-margin-top">
          <div class="col-xs-6">
            <button class="btn outline blue left"
                    type="button"
                    (click)="close()">Back</button>
          </div>

          <div class="col-xs-6">
            <button class="btn pull-right"
                    [disabled]="!isPeriodicitySet || nameConflict"
                    [title]="!isPeriodicitySet
                      ? 'You need to set all options'
                      : nameConflict
                        ? 'A source variable with that name already exists'
                        : ''"
                    (click)="addVariable(null)">
              <span *ngIf="loading"
                    class="ion-load-c spinner"></span>&nbsp;
              Import
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</indicio-modal-base>
