import { VSMeasurementType, VSModeType, VsCoefMethodType } from '@modules/lang/language-files/var-select';

export class VarSelectSettingsDTO {
  VSMode: VSModeType;
  VSLassoStruct: string;
  VSMeasurement: VSMeasurementType;
  VSCoefMethod: VsCoefMethodType;
  VSBestVarCount: number;
  VSUseRollingWindow: boolean;
}
