<mat-form-field *ngIf="!!selectedNode">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select [ngModel]="selectedNode"
              [required]="required"
              #selector>

    <mat-option *ngIf="flatList?.length > 4">
      <ngx-mat-select-search placeholderLabel="Search"
                             noEntriesFoundLabel="No search results"
                             [formControl]="searchControl"></ngx-mat-select-search>
    </mat-option>

    <ng-template #parentTemplate
                 let-node="node">
      <mat-option *ngIf="!!node"
                  [value]="node"
                  (click)="clickedNode(node)">
        <i class="ion-arrow-right-c"></i> {{ node.Display }}
      </mat-option>
    </ng-template>

    <ng-template #hierarchy>
      <!-- The Parent nodes, if any -->
      <ng-container *ngFor="let node of getParents()"
                    [ngTemplateOutlet]="parentTemplate"
                    [ngTemplateOutletContext]="{ node }"></ng-container>

      <!-- The current node -->
      <mat-option [value]="selectedNode"
                  (click)="clickedNode(selectedNode)">
        <span class="bold">{{ selectedNode.Display }}</span>
      </mat-option>

      <!-- Children, if any -->
      <mat-option *ngFor="let child of selectedNode.Children"
                  (click)="clickedNode(child)"
                  [value]="child">&nbsp;&nbsp;&#8226; {{ child.Display }}</mat-option>
    </ng-template>

    <ng-container *ngIf="filteredNodes?.length> 0; else hierarchy">
      <mat-option *ngFor="let node of filteredNodes"
                  (click)="clickedNode(node, true)"
                  [value]="node">{{ node.Display }}</mat-option>
    </ng-container>

  </mat-select>
</mat-form-field>
