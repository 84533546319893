import { ShapGraphDialogData } from '@shared/modules/dialogs/shap-values/graph-dialog/shap-graph.dialog';
import { ALGTypes } from './alg-types';

export namespace ALGActions {

  export class ExportResult {
    public static readonly type = '[ALG] Clicked export forecast result';
    constructor() { }
  }

  export class ClickFirstForecastPoint {
    public static readonly type = '[ALG] Emulate click on first forecasted alg point';
    constructor() { }
  }

  export class Click {
    public static readonly type = '[ALG] Clicked on alg date';
    constructor(public data: ShapGraphDialogData) { }
  }

  export class Update {
    public static readonly type = '[ALG] Update';
    constructor(public graphId?: string) { }
  }

  export class SetTransformation {
    public static readonly type = '[ALG] Set transformation';
    constructor(public type: ALGTypes.Transform, public fVersionId: string, public fVariableId?: string, public saveTransform: boolean = true) { }
  }

  export class SetDataType {
    public static readonly type = '[ALG] Set data type';
    constructor(public type: ALGTypes.Data, public fVersionId: string) { }
  }

  export class SetDecoupledTransform {
    public static readonly type = '[ALG] Set decoupled';
    constructor(public state: boolean, public fVersionId: string) { }
  }

  export class SetVariableTransformation {
    public static readonly type = '[ALG] Set variable transformation';
    constructor(public state: boolean, public fVariableId: string, public fVersionId: string) { }
  }
}
