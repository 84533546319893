import { Injectable } from '@angular/core';
import { IFile } from '@core/interfaces/if-file';
import { HttpService } from '@core/services/http/http.service';
import { FileDTO, RemoteFileDTO, UpdateFileInfoDTO } from '@core/store/file/dtos/file-dto';
import { FileVersionDiffDTO } from '@core/store/file/dtos/file-version-diff-dto';
import { RawFileDTO } from '@core/store/file/dtos/raw-file-dto';
import { FileMapper } from '@core/store/file/file.mapper';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { FileAccessDTO } from './dtos/file-access.dto';
import { FileColumnUsageDTO } from './dtos/file-column-usage-dto';
import { FileUpdateInfoDTO } from './dtos/file-update-info.dtos';
import { FileUsageCollectionDTO } from './dtos/used-file-dto';
import { ConfirmedNewLocationDTO, FileColumnDiffDTO, NewLocationInfoDTO, VariableUpdateInfoDTO } from './dtos/variable-update-info-dto';
import { FileInfoModel } from './models/file-info.model';

@Injectable({
  providedIn: 'root'
})
export class FileBackendService {

  constructor(
    private http: HttpService,
    private mapper: FileMapper
  ) { }

  public getCompanyFiles(companyId: string) {
    return this.http.get<FileDTO[]>(`company/${companyId}/files`)
      .then(resp => {
        const files = resp.body.map(file => this.mapper.map(file));
        return files;
      });
  }

  public getFileColumnDiff(info: FileInfoModel, location: NewLocationInfoDTO, variable: VariableUpdateInfoDTO) {
    const dto = this.mapper.mapGetColDiffDTO(info, location, variable);
    return this.http.post<FileColumnDiffDTO>('files/get-file-column-diff', dto)
      .then(resp => resp.body);
  }

  public getFileUpdateInfo(file: UploadedFileModel) {
    const dto = this.mapper.toCreateVersionDto(file, null);
    return this.http.post<FileUpdateInfoDTO>('files/get-file-update-info', dto)
      .then(resp => resp.body);
  }

  public getFile(companyId: string, fileId: string) {
    return this.http.get<FileDTO>(`company/${companyId}/files/${fileId}/get-file`)
      .then(resp => this.mapper.map(resp.body, true));
  }

  public getRemoteFiles() {
    return this.http.get<RemoteFileDTO[]>('remote/files')
      .then(resp => {
        const files = resp.body.map(file => this.mapper.mapRemote(file));
        return files;
      });
  }

  public async updateAccess(file: IFile, dto: FileAccessDTO) {
    return this.http.post<FileAccessDTO[]>(`company/${file.CompanyId}/files/${file.UploadedFileId}/access`, dto)
      .then(resp => resp.body);
  }

  public getRemoteFile(fileId: string) {
    return this.http.get<RemoteFileDTO>(`remote/files/${fileId}`)
      .then(resp => this.mapper.mapRemote(resp.body, true));
  }

  public getFileVersionUsage(CompanyId: string, fileId: string, versionId: string) {
    return this.http.get<FileColumnUsageDTO>(`company/${CompanyId}/files/${fileId}/${versionId}/get-file-column-usage`)
      .then(resp => resp.body);
  }

  public getRemoteFileVersionUsage(fileId: string, versionId: string) {
    return this.http.get<FileColumnUsageDTO>(`remote/files/${fileId}/${versionId}/get-file-column-usage`)
      .then(resp => resp.body);
  }

  public getFileUsage(companyId: string, files: IFile[]) {
    const UploadedFileIds = files.filter(x => !!x.UploadedFileId).map(x => x.UploadedFileId);
    const RemoteFileIds = files.filter(x => !!x.RemoteFileId).map(x => x.RemoteFileId);
    return this.http.post<FileUsageCollectionDTO>(`company/${companyId}/files/usage`, { UploadedFileIds, RemoteFileIds })
      .then(({ body }) => body);
  }

  public getRawFile(file: IFile, version: string = null) {
    const v = version ? version : file.latestVersion().getModelId();
    return this.getRawFileByIds(file.UploadedFileId, file.CompanyId, v);
  }

  public getRawFileByIds(fileId: string, companyId: string, version: string) {
    return this.http.get<RawFileDTO>(`company/${companyId}/files/${fileId}/versions/${version}/raw`)
      .then(resp => resp.body);
  }

  public getRawRemoteFile(file: IFile, version: string = null) {
    const v = version ? version : file.latestVersion().getModelId();
    return this.http.get<RawFileDTO>(`remote/files/${file.RemoteFileId}/versions/${v}/raw`)
      .then(resp => resp.body);
  }

  public getFileDiff(companyId: string, fileId: string, oldVersionId: string, newVersionId: string) {
    return this.http.get<FileVersionDiffDTO>(`company/${companyId}/files/${fileId}/diff/${oldVersionId}/${newVersionId}/get-file-diff`)
      .then(({ body }) => body);
  }

  public getRemoteFileDiff(fileId: string, oldVersionId: string, newVersionId: string) {
    return this.http.get<FileVersionDiffDTO>(`remote/files/${fileId}/diff/${oldVersionId}/${newVersionId}/get-file-diff`)
      .then(({ body }) => body);
  }

  public createFile(file: UploadedFileModel) {
    const dto = this.mapper.toCreateDto(file);
    return this.http.post<FileDTO>('files/create-file', dto)
      .then(resp => this.mapper.map(resp.body));
  }

  public createNewFileVersion(file: UploadedFileModel, varInfos: ConfirmedNewLocationDTO[]) {
    const dto = this.mapper.toCreateVersionDto(file, varInfos);
    return this.http.post<FileDTO>('files/create-file-version', dto)
      .then(resp => this.mapper.map(resp.body));
  }

  public updateFileInfo(dto: UpdateFileInfoDTO) {
    return this.http.post<FileDTO>('files/update-file-info', dto)
      .then(resp => this.mapper.map(resp.body, true));
  }

  public deleteFile(file: IFile) {
    return this.http.delete<null>(`company/${file.CompanyId}/files/${file.UploadedFileId}`)
      .then(() => file.UploadedFileId);
  }

  public deleteRemoteFile(file: IFile) {
    return this.http.delete<null>(`remote/files/${file.RemoteFileId}`)
      .then(() => file.RemoteFileId);
  }
}
