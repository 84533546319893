import { DisplayValue } from '../types/display-value';

export class ValueType extends DisplayValue<string> { };
export const ValueTypes = [
  new ValueType('original', 'Original', 'The original unprocessed values'),
  new ValueType('aggregation', 'Aggregated', 'Values after (dis)aggregation, e.g. monthly to quarterly'),
  new ValueType('outlier', 'Outlier', 'Values adjusted due to outliers'),
  new ValueType('seasonal', 'Seasonal', 'Values adjusted due to seasonal patterns'),
  new ValueType('processed', 'Processed', 'Values after initial processing')
];
