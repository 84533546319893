import { Component, Input, OnChanges } from '@angular/core';
import { MathService } from '@core/services/mathjax/mathjax.service';

@Component({
  selector: 'indicio-wiki-mathjax',
  templateUrl: './wiki-mathjax.component.html',
  styleUrls: ['./wiki-mathjax.component.less'],
})
export class WikiMathJaxComponent implements OnChanges {

  @Input() public content: string;

  public mjString: string = '';

  public mathjaxInited = false;

  constructor(private mathjaxService: MathService) {
    this.mathjaxService.ready().subscribe(() => {
      this.mathjaxInited = true;
    });
  }

  ngOnChanges() {
    if (!this.content) { this.mjString = ''; return; }
    this.mjString = `$$ ${this.content} $$`;
  }

}
