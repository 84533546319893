import { Def } from '@core/decorators/def';

export class KoladaKPIModel {
  @Def() auspices: string;
  @Def() description: string;
  @Def() has_ou_data: boolean;
  @Def() id: string;
  @Def() is_divided_by_gender: number;
  @Def() municipality_type: string;
  @Def() operating_area: string;
  @Def() ou_publication_date: string;
  @Def() perspective: string;
  @Def() prel_publication_date: string;
  @Def() publ_period: string;
  @Def() publication_date: string;
  @Def() title: string;
}
