
export class OxfordVariableMetaModel {
  VariableCode: string;
  Description: string;
  ScaleFactor: string;
  HistoricalEndYear: Date;
  HistoricalEndQuarter: Date;
  LastUpdate: Date;
  SeasonallyAdjusted: string;
  Units: string;
  Source: string;
  SourceDescription: string;
  HasQuarterly: string;


  // frontend mappings
  oldData: boolean;

  public get EndDate() {
    return this.HistoricalEndQuarter || this.HistoricalEndYear;
  }

  public get Periodicity() {
    return this.HasQuarterly ? 'Quarterly' : 'Yearly';
  }
}
