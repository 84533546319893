
export class DisplayValue<T = any> {

  public Value: T;
  public Display: string;
  public Description?: string;
  public Extra?: string;

  constructor(value?: T, display?: string, description?: string, extra?: string) {
    this.Value = value;
    this.Display = display;
    this.Description = description;
    this.Extra = extra;
  }
}
