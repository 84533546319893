import { Injectable } from '@angular/core';
import { NameDTO } from '@core/entities/dtos/name-dto';
import { HttpService } from '@core/services/http/http.service';
import { CompanyActions } from '@core/store/company/company.actions';
import { CompanyModel } from '@core/store/company/company.model';
import { CompanyActivitySummaryDTO, CompanySummarySearchDTO } from '@core/store/company/dtos/company-activity-summary.dto';
import { ClientMetaDTO } from '@modules/admin/entities/client-meta.dto';
import { AdminBackendBaseService } from '@modules/admin/services/admin-http-base.service';
import { DisplayValue } from '@modules/lang/types/display-value';
import { Store } from '@ngxs/store';


@Injectable({
  providedIn: 'root',
})
export class AdminCompanyService extends AdminBackendBaseService {


  constructor(
    http: HttpService,
    store: Store,
  ) {
    super(http, store);
  }

  public disable2Fa(dto: ClientMetaDTO) {
    return this.http.post(`users/${dto.Profile.ClientId}/2fa/disable`, null, 'id');
  }

  public async updateCompanyName(companyId: string, dto: NameDTO) {
    const resp = await this.http.post<string>(`admin/company/${companyId}/set-name`, dto, 'backend');
    return resp.body;
  }

  public async changeMacrobondEnabled(company: CompanyModel) {
    const resp = await this.http.get<boolean>(`admin/company/${company.CompanyId}/change-macrobond-enabled`);
    company.MacrobondEnabled = resp.body;
    this.store.dispatch(new CompanyActions.GetCompanySuccessAction(company));
    return company.MacrobondEnabled;
  }

  public async getSupportUsers() {
    return this.http.get<{ Item1: string, Item2: string, Item3: string[]; }[]>('admin/support-users')
      .then(({ body }) => body.map(x => ({ Display: x.Item1, Value: x.Item2, Roles: x.Item3 })))
      .then(resp => {
        const users: { role: string, users: DisplayValue[]; }[] = [];
        resp.forEach(x => {
          const user = { Display: x.Display, Value: x.Value };
          x.Roles.forEach(role => {
            role = role.replace('sys', '');
            const idx = users.findIndex(y => y.role === role);
            if (idx === -1) {
              users.push({ role, users: [] });
            }
            if (users.filter(y => y.role !== role).some(w => w.users.find(y => y.Value === user.Value))) { return; }
            users.find(y => y.role === role).users.push(user);
          });
        });
        return users.sort((a, b) => a.role.localeCompare(b.role));
      });
  }

  public async changeTrialStatus(company: CompanyModel) {
    const resp = await this.http.get<boolean>(`admin/company/${company.CompanyId}/change-trial-status`);
    company.Trial = resp.body;
    this.store.dispatch(new CompanyActions.GetCompanySuccessAction(company));
    return company.Trial;
  }

  public async getCompanyUsage(dto: CompanySummarySearchDTO, companyId?: string) {
    const resp = await this.http.post<CompanyActivitySummaryDTO[]>(`admin/company/usage-summary/${companyId || ''}`, dto);
    return resp.body;
  }
}
