import { Injectable } from '@angular/core';
import { IFile } from '@core/interfaces/if-file';
import { ActionService } from '@core/services/actions/actions.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import {
  DeleteFileSuccessAction,
  FileActions, GetUploadedFilesSuccessAction,
  GetUploadedFileSuccessAction
} from '@core/store/file/file.actions';
import { FileBackendService } from '@core/store/file/file.backend.service';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { SourceTypesType } from '@modules/lang/language-files/source-types';
import { Store } from '@ngxs/store';
import { OpenInformationModal } from '@shared/modals/info/information-modal.actions';
import { BlobUtils } from '@shared/utils/blob.utils';
import { FileUtils } from '@shared/utils/file.utils';
import JSZip from 'jszip';
import { SourceVariableModel, SourceVariableViewDTO } from '../source-variable/source-variable.model';
import { FileAccessDTO } from './dtos/file-access.dto';
import { FileColumnUsageDTO } from './dtos/file-column-usage-dto';
import { UpdateFileInfoDTO } from './dtos/file-dto';
import { ConfirmedNewLocationDTO, NewLocationInfoDTO, VariableUpdateInfoDTO } from './dtos/variable-update-info-dto';
import { FileState } from './file.state';
import { FileInfoModel } from './models/file-info.model';
import { RemoteFileModel } from './models/remote-file.model';

@Injectable({
  providedIn: 'root'
})
export class FileFrontendService {

  // OBSERVABLES
  public filesInActiveCompany$ = this.store.select(FileState.getUploadedFilesInActiveCompany);

  // GETTERS
  public get uploadedFiles() { return this.store.selectSnapshot(FileState.uploadedFiles); }
  public get remoteFiles() { return this.store.selectSnapshot(FileState.remoteFiles); }
  public get filesInActiveCompany() { return this.store.selectSnapshot(FileState.getUploadedFilesInActiveCompany); }
  public getFileById(fileId: string) { return this.uploadedFiles.find(x => x.UploadedFileId === fileId); }
  public getRemoteFileById(fileId: string) { return this.remoteFiles.find(x => x.RemoteFileId === fileId); }

  constructor(
    private store: Store,
    private clientService: ClientFrontendService,
    private actions: ActionService,
    private backend: FileBackendService
  ) {
    this.setupSubscriptions();
  }

  public async getFileData(fileId: string, type: SourceTypesType) {
    let call: Promise<IFile>;
    if (type === 'file') {
      call = this.getOrFetchFile(fileId) as Promise<IFile>;
    } else {
      call = this.getOrFetchRemoteFile(fileId) as Promise<IFile>;
    }
    const file = await call;
    const usage = await this.getFileUsage(file);
    const sheets = file.ParsedData;
    const ftpData = null;

    // await this.sourceVariableService.getOrFetchSourceVariables();
    return Promise.resolve({ sheets, file, ftpData, usage });
  }

  public getFileColumnDiff(info: FileInfoModel, location: NewLocationInfoDTO, variable: VariableUpdateInfoDTO) {
    return this.backend.getFileColumnDiff(info, location, variable);
  }

  public getFileUpdateInfo(file: UploadedFileModel) {
    return this.backend.getFileUpdateInfo(file);
  }


  public updateAccess(file: IFile, dto: FileAccessDTO) {
    if (file.SourceType !== 'file') {
      return Promise.reject('Cannot add editors to sharepoint files.');
    }

    return Promise.all([this.backend.updateAccess(file, dto), this.getOrFetchFile(file.UploadedFileId, false, file.CompanyId)])
      .then(([editors, uFile]) => {
        uFile.Users = editors;
        this.store.dispatch(new GetUploadedFileSuccessAction(uFile));
        return editors;
      });
  }

  public getOrFetchFiles(force: boolean = false): Promise<UploadedFileModel[]> {
    const ids = this.clientService.activeCompany.UploadedFileIds;
    const hasAll = this.uploadedFiles.includesAllIds(ids);
    if (!hasAll || force) {
      return this.fetchFilesInCompany();
    } else {
      return Promise.resolve(this.uploadedFiles);
    }
  }

  public getOrFetchFile(fileId: string, force: boolean = false, companyId?: string) {
    const file = this.getFileById(fileId);
    if (force || !file || !file.fetched) {
      return this.fetchFile(fileId, companyId);
    } else {
      return Promise.resolve(file);
    }
  }

  private async fetchFile(fileId: string, companyId?: string) {
    const file = await this.backend.getFile(companyId || this.clientService.activeCompany.CompanyId, fileId);
    this.store.dispatch(new GetUploadedFileSuccessAction(file));
    return file;
  }

  public getFileUsage(file: IFile) {
    let call: Promise<FileColumnUsageDTO>;
    if (file.SourceType === 'file') {
      call = this.backend.getFileVersionUsage(this.clientService.activeCompany.CompanyId, file.getModelId(), file.latestVersion().getModelId());
    } else {
      call = this.backend.getRemoteFileVersionUsage(file.getModelId(), file.latestVersion().getModelId());
    }
    return call;
  }

  public async fetchFilesInCompany(companyId: string = null) {
    const id = companyId ? companyId : this.clientService.activeCompany.CompanyId;
    const files = await this.backend.getCompanyFiles(id);
    this.store.dispatch(new GetUploadedFilesSuccessAction(files));
    return files;
  }

  public getOrFetchRemoteFiles(force: boolean = false): Promise<RemoteFileModel[]> {
    const hasAll = false;
    if (!hasAll || force) {
      return this.fetchRemoteFiles();
    } else {
      return Promise.resolve(this.remoteFiles);
    }
  }

  public getOrFetchRemoteFile(fileId: string, force: boolean = false) {
    const file = this.getRemoteFileById(fileId);
    if (force || !file || !file.fetched) {
      return this.fetchRemoteFile(fileId);
    } else {
      return Promise.resolve(file);
    }
  }

  public async fetchRemoteFiles() {
    const files = await this.backend.getRemoteFiles();
    this.store.dispatch(new FileActions.GetRemoteFilesSuccess(files));
    return files;
  }

  public async fetchRemoteFile(fileId: string) {
    const file = await this.backend.getRemoteFile(fileId);
    this.store.dispatch(new FileActions.GetRemoteFileSuccess(file));
    return file;
  }

  public async createNewFile(file: UploadedFileModel) {
    const newFile = await this.backend.createFile(file);
    this.store.dispatch(new GetUploadedFileSuccessAction(newFile));
    return newFile;
  }

  public async createNewFileVersion(file: UploadedFileModel, variableInfos: ConfirmedNewLocationDTO[]) {
    const newFile = await this.backend.createNewFileVersion(file, variableInfos);
    this.store.dispatch(new GetUploadedFileSuccessAction(newFile));
    return newFile;
  }

  public async getFileDiff(companyId: string, fileId: string, oldVersion: string, newVersion: string) {
    const diff = await this.backend.getFileDiff(companyId, fileId, oldVersion, newVersion);
    return diff;
  }

  public async getRemoteFileDiff(fileId: string, oldVersion: string, newVersion: string) {
    const diff = await this.backend.getRemoteFileDiff(fileId, oldVersion, newVersion);
    return diff;
  }

  public async updateFileInfo(dto: UpdateFileInfoDTO) {
    const updated = await this.backend.updateFileInfo(dto);
    this.store.dispatch(new GetUploadedFileSuccessAction(updated));
    return updated;
  }

  public downloadFileForSourceVariables(variables: SourceVariableViewDTO[]) {
    const promises = variables
      .filter(v => v.SourceType === 'file')
      .distinctBy('FileName')
      .map(v => this.getOrFetchFile(v.UploadedFileId));

    const remoteVariables = variables.filter(v => v.SourceType !== 'file');
    if (remoteVariables.length) {
      let message = 'You are trying to download the file(s) for a remote variable that is not yet supported.';
      message += '<p>';
      remoteVariables.forEach(rv => message += `<br>- ${rv.Name}`);
      message += '</p>';
      this.store.dispatch(new OpenInformationModal('Download info', message, 'Ok', null));
    }

    if (!promises.length) { return; }
    Promise.all(promises)
      .then(files => this.downloadFilesAsZip(files, 'sourcevariables'));
  }

  public downloadFileForSourceVariable(variable: SourceVariableModel) {
    let call;
    switch (variable.SourceType) {
      case 'file':
        call = this.getOrFetchFile(variable.getFileId());
        break;
      case 'sharepoint':
        call = this.getOrFetchRemoteFile(variable.getFileId());
        break;
    }
    call.then(f => {
      this.downloadFile(f, f.latestVersion().UploadedFileVersionId);
    });
  }

  public downloadFile(file: IFile, version: string = null) {
    let call;
    if (file.UploadedFileId) {
      call = this.backend.getRawFile(file, version);
    } else {
      call = this.backend.getRawRemoteFile(file, version);
    }
    return call.then(raw => {
      const blob = BlobUtils.base64toBlob(raw.FileData, 'application/octet-stream');
      FileUtils.downloadBlob(blob, raw.FileName);
    });
  }

  public async downloadFileById(fileId: string, companyId: string, versionId: string) {
    const raw = await this.backend.getRawFileByIds(fileId, companyId, versionId);
    const blob = BlobUtils.base64toBlob(raw.FileData, 'application/octet-stream');
    FileUtils.downloadBlob(blob, raw.FileName);
  }

  public downloadFilesAsZip(files: UploadedFileModel[], name: string) {
    const promises = [];
    files.forEach(f => promises.push(this.backend.getRawFile(f, f.latestVersion().UploadedFileVersionId)));
    Promise.all(promises)
      .then(rawFiles => {
        const zip = new JSZip();
        rawFiles.forEach(x => {
          zip.file(x.FileName, BlobUtils.base64toBlob(x.FileData, 'application/octet-stream'));
        });
        zip.generateAsync({ type: 'blob' }).then(content => {
          FileUtils.downloadBlob(content, `${name}.zip`);
        });
      });
  }

  public async deleteFile(file: IFile) {
    const deletedId = await this.backend.deleteFile(file);
    this.store.dispatch(new DeleteFileSuccessAction(deletedId));
    return deletedId;
  }

  public async deleteRemoteFile(file: IFile) {
    const deletedId = await this.backend.deleteRemoteFile(file);
    this.store.dispatch(new FileActions.DeleteRemoteFileSuccess(file.RemoteFileId));
    return deletedId;
  }

  public fetchUsage(files: IFile[]) {
    return this.backend.getFileUsage(this.clientService.activeCompany.CompanyId, files);
  }

  private setupSubscriptions() {
    this.actions.dispatched(FileActions.SyncAll).subscribe((a: FileActions.SyncAll) => {
      if (this.clientService.activeCompany.CompanyId === a.companyId) {
        this.fetchFilesInCompany();
      }
    });
  }
}
