<div class="fcast-info-models-tab">

  <mat-accordion *ngIf="newSettings; else loadingTemplate">
    <!-- Stationarity-->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Settings
        </mat-panel-title>
        <mat-panel-description>
          &quot;Top model&quot; settings
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="settingsTemplate"></ng-container>
    </mat-expansion-panel>
    <!-- Stationarity-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Univariate models
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container
                    *ngTemplateOutlet="modelGroupFilterTemplate; context: { groups: envService.UnivariateModels, name: 'Univariate' }">
      </ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Multivariate models
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container
                    *ngTemplateOutlet="modelGroupFilterTemplate; context: { groups: envService.MultivariateModels, name: 'Multivariate' }">
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #settingsTemplate>
  <indicio-modal-option-row [disabled]="!canUpdateForecast"
                            optionTitle="Univariate &quot;top models&quot; count">
    <mat-form-field style="margin-top: 7px;">
      <mat-select [value]="newSettings.TopUnivariateModelsCount"
                  (selectionChange)="newSettings.TopUnivariateModelsCount = $event.value; modifiedEvent.emit(changed)"
                  required
                  [disabled]="!canUpdateForecast"
                  placeholder="Pick a number">
        <mat-option [value]="num"
                    [title]="num"
                    *ngFor="let num of [3, 5, 10]">{{ num === 0 ? 'All' : num }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [disabled]="!canUpdateForecast"
                            optionTitle="Multivariate &quot;top models&quot; count">
    <mat-form-field style="margin-top: 7px;">
      <mat-select [value]="newSettings.TopMultivariateModelsCount"
                  (selectionChange)="newSettings.TopMultivariateModelsCount = $event.value; modifiedEvent.emit(changed)"
                  required
                  [disabled]="!canUpdateForecast"
                  placeholder="Pick a number">
        <mat-option [value]="num"
                    [title]="num"
                    *ngFor="let num of [3, 5, 10]">{{ num === 0 ? 'All' : num }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #modelGroupFilterTemplate
             let-groups="groups"
             let-name="name">

  <table class="model-filter-table"
         cellpadding="1"
         cellspacing="1">
    <thead>
      <tr>
        <th class="filter-toggle dotted-underline"
            (click)="clickAll('models', name)">Active</th>
        <th>Model</th>
        <th class="filter-toggle"
            (click)="clickAll('org', name)">Org</th>
        <th class="filter-toggle"
            (click)="clickAll('diff', name)">Diff</th>
        <th class="filter-toggle"
            (click)="clickAll('log', name)">Log<br></th>
      </tr>
    </thead>
    <tbody style="display: block; max-height: 350px; min-height: 210px; overflow: auto;">
      <tr *ngFor="let modelGroup of groups">

        <td style="width: 40px;">
          <mat-slide-toggle [disabled]="!canUpdateForecast"
                            data-test-id="{{ modelGroup.Model.Model }}-filter-toggle-btn"
                            [checked]="getFilterState(modelGroup.Model.Value, 'full')"
                            (change)="toggleModelFilter(modelGroup.Model.Value, 'full')">
          </mat-slide-toggle>
        </td>
        <td [title]="modelGroup.Model.Display"
            class="filter-toggle model-name"
            [ngClass]="{ pointer: canUpdateForecast }"
            (click)="toggleModelFilter(modelGroup.Model.Value, 'full')">
          {{ modelGroup.Model.Display }}
        </td>
        <td class="center model-filter-toggle"
            (click)="toggleModelFilter(modelGroup.Model.Value, 'org')">
          <i [class]="getFilterState(modelGroup.Model.Value, 'org') ? 'ion-ios-checkmark' : 'ion-ios-circle-outline'"
             [ngClass]="canUpdateForecast ? 'blue' : 'grey'"></i>
        </td>
        <td class="center model-filter-toggle"
            (click)="toggleModelFilter(modelGroup.Model.Value, 'diff')">
          <i [class]="getFilterState(modelGroup.Model.Value, 'diff') ? 'ion-ios-checkmark' : 'ion-ios-circle-outline'"
             [ngClass]="canUpdateForecast ? 'blue' : 'grey'"></i>
        </td>
        <td class="center model-filter-toggle"
            (click)="toggleModelFilter(modelGroup.Model.Value, 'log')">
          <i [class]="getFilterState(modelGroup.Model.Value, 'log') ? 'ion-ios-checkmark' : 'ion-ios-circle-outline'"
             [ngClass]="canUpdateForecast ? 'blue' : 'grey'"></i>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #loadingTemplate>
  <div>
    <indicio-spinner></indicio-spinner> Loading...
  </div>
</ng-template>
