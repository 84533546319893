import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFile, IFileVersion } from '@core/interfaces/if-file';
import { StatusService } from '@core/services/status/status.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { EmployeeMetaDTO } from '@core/store/company/dtos/employee-dto';
import { UpdateFileInfoDTO } from '@core/store/file/dtos/file-dto';
import { FileFrontendService } from '@core/store/file/file.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { FileTypesType } from '@modules/lang/language-files/source-types';

export enum FileDialogViewIndex {
  'general' = 0,
  'access' = 1,
  'revisions' = 2,
}

export class FileInfoDialogData {
  companyId: string;
  fileId: string;
  type: FileTypesType;
  view: FileDialogViewIndex = 0;
}

@Component({
  selector: 'indicio-file-info-dialog',
  templateUrl: './file-info.dialog.component.html',
  styleUrls: ['./file-info.dialog.component.less']
})
export class FileInformatinDialogComponent extends DialogV2BaseDialog<FileInformatinDialogComponent> implements AfterViewInit {

  public static Id: string = 'FileInformatinDialogComponent';

  // Properties
  public file: IFile = null;
  public viewIndex: number;
  public viewTypes = FileDialogViewIndex;
  public isRemote: boolean = false;

  // Changes
  public shouldSave: boolean = false;
  public newName: string;

  // Possible editors
  public companyEmployees: EmployeeMetaDTO[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: FileInfoDialogData,
    private status: StatusService,
    private fileService: FileFrontendService,
    private companyService: CompanyFrontendService,
    public appearance: AppearanceService,
    private cd: ChangeDetectorRef,
    dialogRef: MatDialogRef<FileInformatinDialogComponent>,
  ) {
    super(dialogRef);
    this.initialize();
  }

  public ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  public onNewName(newName: string) {
    this.newName = newName;
    this.checkShouldSave();
  }

  public changeView(index: number) {
    this.viewIndex = index;
    this.cd.detectChanges();
  }

  protected initialize() {
    this.viewIndex = this.data.view;
    this.isRemote = this.data.type === 'sharepoint';
    let call: Promise<any>;
    if (!this.isRemote) {
      call = this.fileService.getOrFetchFile(this.data.fileId, undefined, this.data.companyId);
    } else {
      call = this.fileService.getOrFetchRemoteFile(this.data.fileId);
    }

    call.then(file => this.setup(file))
      .then(() => this.companyService.getCompanyEmployees(this.file.CompanyId))
      .then(employees => this.companyEmployees = employees)
      .finally(() => this.initialized = true);
  }

  private setup(file: IFile) {
    this.file = file;
    this.newName = file.FileName;
    this.checkShouldSave();
  }

  public async save() {
    this.inProgress = true;

    const dto: UpdateFileInfoDTO = {
      CompanyId: this.file.CompanyId,
      FileName: this.newName,
      UploadedFileId: this.file.UploadedFileId
    };

    return this.fileService.updateFileInfo(dto)
      .then(res => {
        this.setup(res);
        this.status.setMessage('File updated', 'Success', true);
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => this.inProgress = false);
  }

  public downloadVersion(file: IFile, version: IFileVersion) {
    this.fileService.downloadFile(file, version.getModelId());
  }

  private checkShouldSave() {
    const nameChanged = this.file.FileName !== this.newName;
    this.shouldSave = nameChanged;
  }
}
