
import { Def } from '@core/decorators/def';
import { FileMetaDataDTO } from '@core/store/file/dtos/file-meta-data-dto';

export class CreateSourceVariableFromFileDTO {
  @Def() Name: string;
  @Def() VisibilityLevelId: string;
  @Def() CompanyId: string;
  @Def() FileMetaData: FileMetaDataDTO;
  @Def() AggregationMethodId: string = 'average';
  @Def() StaticValues: boolean;
  @Def() IsPercent: boolean;
  @Def() SourceType: string;

  @Def() FileId: string;
  @Def() FileVersionId: string;
  @Def() SheetIndex: number;
  @Def() DateIndex: number;
  @Def() ValueIndex: number;
  @Def() IdentifierIndex: number;
  @Def() Identifier: string;
  @Def() DisabledRows: number[];
}
