import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'indicio-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.less']
})
export class AccordionComponent {

  @Input() open = false;
  @Input() darkHeader: boolean;
  @Input() bgColor: string = '#EFEFEF';
  @Input() contentPadding: string = '10px';
  @Output() toggleEvent = new EventEmitter();

  public runToggleEvent() {
    this.toggleEvent.emit('toggle');
  }
}
