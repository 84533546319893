<indicio-dialog>
  <h2 mat-dialog-title
      class="text-center">Create new company</h2>
  <div mat-dialog-content>
    <ng-container *ngIf="initialized; else loadingTemplate">

      <div style="margin: 0 0 30px 0;">
        <indicio-stepped-progress [steps]="['Company info', 'Trial status', 'Add users', 'Preview']"
                                  [step]="viewIndex"
                                  (stepChangeEvent)="viewIndex = $event"></indicio-stepped-progress>
      </div>
      <ng-container [ngSwitch]="viewIndex">
        <ng-container *ngSwitchCase="0">
          <ng-container *ngTemplateOutlet="generalTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="1">
          <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <ng-container *ngTemplateOutlet="usersTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <ng-container *ngTemplateOutlet="previewTemplate"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>


  <div mat-dialog-actions
       class="margin-top">
    <button mat-button
            (click)="backOrCancel()">{{ viewIndex === viewTypes.general ? 'Cancel' : 'Back' }}</button>
    <button mat-flat-button
            color="primary"
            [disabled]="saveDisabled"
            (click)="saveOrNext()">
      <indicio-spinner *ngIf="pending"></indicio-spinner>
      {{ viewIndex === viewTypes.preview ? 'Create' : 'Next' }}
    </button>
  </div>
</indicio-dialog>

<ng-template #loadingTemplate>
  <indicio-spinner></indicio-spinner>
</ng-template>

<ng-template #generalTemplate>
  <indicio-create-company-general-tab [company]="company"
                                      [supportUsers]="supportUsers"></indicio-create-company-general-tab>
</ng-template>

<ng-template #optionsTemplate>
  <indicio-create-company-options-tab [company]="company"></indicio-create-company-options-tab>
</ng-template>

<ng-template #usersTemplate>
  <indicio-create-company-users-tab #usersTab
                                    [company]="company"></indicio-create-company-users-tab>
</ng-template>

<ng-template #previewTemplate>
  <indicio-create-company-preview-tab [company]="company"></indicio-create-company-preview-tab>
</ng-template>
