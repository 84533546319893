import { ScriptStep } from '@core/constants/script.constants';
import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { ScriptName } from '@core/types/script.name.type';
import { DateUtils } from '@shared/utils/date.utils';

export class RQueueInfoModel implements IHasModelId {

  @Def() ForecastVersionId: string;
  @Def() TimeUntilFinished: number;
  @Def() Entries: RQueueEntryModel[] = [];

  // Frontend stuffs
  CompletedEntries: RQueueEntryModel[] = [];

  public get tufText() {
    return DateUtils.ToRQueueDisplayTime(this.TimeUntilFinished);
  }

  public get allEntries() {
    return [...this.Entries, ...this.CompletedEntries].sort((a, b) => a.PlaceInQueue > b.PlaceInQueue ? -1 : 1);
  }

  getModelId(): string { return (this.ForecastVersionId || '').toString(); }

  public stopAllTimers() {
    this.allEntries.forEach(e => {
      window.clearTimeout(e._timeout);
    });
  }
}
export class RQueueEntryModel implements IHasModelId {
  @Def() RRequestId: string;
  @Def() ForecastVersionId: string;
  @Def() ForecastVariableId: string;
  @Def() ScenarioId: string;
  @Def() FromNowcast: boolean;
  @Def() ScriptName: ScriptName;
  @Def() ModelName: string;
  @Def() Status: ScriptStep;
  @Def() TryCount: number = 1;
  @Def() PlaceInQueue: number;
  @Def() TimeUntilProcessing: number;
  @Def() EstimatedTime: number;
  @Def() PercentDone = 0;

  // Frontend defined
  @Def() DisplayName: string;

  public _elapsedTime = 0;
  private _counterStarted = false;
  private _slowDownAtPercent = 85;
  public _maxTries = 3;
  public _timeout;
  public _failed = false;

  getModelId(): string { return (this.RRequestId || '').toString(); }

  public get statusText() {
    switch (true) {
      case this.Status === 'pending':
        return `Starts in ${DateUtils.ToRQueueDisplayTime(this.TimeUntilProcessing)}`;
      case this.Status === 'processing' && !this._failed:
        let msg = `Done in ${DateUtils.ToRQueueDisplayTime(this.EstimatedTime - this._elapsedTime)}`;
        if (this.TryCount > 1) { msg += ` (${this.TryCount}/${this._maxTries})`; }
        return msg;
      case this.Status === 'queued':
        return 'Queued';
      case this.Status === 'error':
        return `Error (${this.TryCount}/${this._maxTries})`;
      default:
        return 'Completed';
    }
  }

  public startCounter() {
    if (this.Status === 'processing') {
      if (!this._counterStarted) {
        this._counter();
      }
    } else if (this.Status === 'complete') {
      this._elapsedTime = this.EstimatedTime;
      window.clearTimeout(this._timeout);
      this.update();
    }
  }

  public stopCounter() {
    this._elapsedTime = this.EstimatedTime;
    window.clearTimeout(this._timeout);
    this.update();
  }

  private _counter(interval = 500) {
    this._counterStarted = true;
    this._timeout = setTimeout(() => {
      if (this.PercentDone > 100 || this.Status === 'complete') {
        window.clearTimeout(this._timeout);
        return;
      }
      if (this.PercentDone < this._slowDownAtPercent) {
        this._elapsedTime += 500;
        this.update();
        this._counter();
      } else if (this.PercentDone >= this._slowDownAtPercent && interval === 500) {
        this._counter(5000);
      }
      if (this.PercentDone >= this._slowDownAtPercent && interval === 5000) {
        this._elapsedTime += 5;
        this.update();
        this._counter(5000);
      }
    }, interval);
  }

  private update() {
    if (isNaN(this.EstimatedTime) || this.EstimatedTime === 0) { this.PercentDone = 0; }
    let percent = Math.round((this._elapsedTime / this.EstimatedTime) * 100);
    percent = percent < 0
      ? Math.abs(percent)
      : percent;

    percent >= 100
      ? this.PercentDone = 100
      : this.PercentDone = percent;
  }
}

export class QueuedModel {
  ForecastVersionId: string;
  ScenarioId: string;
  ForecastVariableId: string;
  ModelName: string;
  ScriptName: string;
  Extras?: {
    PendingScenarios: number;
  };
}
