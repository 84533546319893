import { IDialogFilter } from './filter.dialog.constants';

export namespace FilterDialogUtils {

  export function cloneIDialogFilter(filter: IDialogFilter) {
    return <IDialogFilter> {
      ...filter,
      values: filter.values?.map(v => ({ ...v })),
      match: !!filter.match
        ? { ...filter.match, values: filter.match.values.map(val => ({ ...val, values: [...val.values] })) }
        : null
    };
  }

}
