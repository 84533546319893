<indicio-dialog>
  <h2 mat-dialog-title>Password recovery</h2>
  <mat-dialog-content class="text">
    <ng-container *ngIf="!emailSent; else emailSentTemplate"
                  [ngTemplateOutlet]="emailInpuTemplate">
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div *ngIf="emailSent"></div>
    <button mat-stroked-button
            type="button"
            (click)="close()">{{ emailSent ? 'Close' : 'Cancel' }}</button>
    <button *ngIf="!emailSent"
            mat-raised-button
            (click)="send()"
            color="primary">
      <div *ngIf="inProgress"
           class="ion-load-c spinner"></div> Send
    </button>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #emailInpuTemplate>
  <div class="row">
    <div class="col-xs-12">
      Please enter the email address you used when creating the account.
    </div>
  </div>
  <div class="row margin-top">
    <div class="col-xs-12">
      <div class="email-input">
        <input type="text"
               [value]="email"
               (input)="email = $event.target.value"
               placeholder="Email"
               autocomplete="username"
               required>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emailSentTemplate>
  <h1>Email sent</h1>
  <br>
  <span>We have sent a link to {{ email }} from {{ 'donotreply@indicio.com.' }}</span>
  <br>
  <span class="italic">Make sure to check the spam folder if you cannot find the email.</span>
</ng-template>
