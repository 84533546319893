import { Component } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ProfileModel } from '@core/store/client/profile.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { Country } from '@modules/lang/types/country';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { SecurityUtils } from '@shared/utils/security.utils';
import { SecurityModalOpts, SecurityModalView } from './security-settings-modal.actions';


@Component({
  selector: 'indicio-change-pw-modal',
  templateUrl: './security-settings-modal.component.html',
  styleUrls: ['./security-settings-modal.less']
})
export class SecuritySettingsModalComponent extends ModalModelComponent {

  opts: SecurityModalOpts = null;
  view: SecurityModalView = 'password';

  // Sessions stuff
  sessions: any[] = [];
  public get sessionId() { return this.authService.sessionId; }

  // New password stuff
  oldpw: string = null;
  newpw1: string = null;
  newpw2: string = null;
  inProgress = false;
  complete = false;

  showPass = false;
  passwordStrength = -1;

  public p: ProfileModel;
  public countries: Country[] = [];
  public filteredCountries: Country[] = [];
  public new_country_phone_display = '';

  constructor(
    public store: Store,
    public authService: AuthFrontendService,
    public profileService: ProfileFrontendService,
    public appearance: AppearanceService,
    public env: EnvironmentService,
    private statusService: StatusService,
    private sanitizer: DomSanitizer,
    public clientService: ClientFrontendService,
    private status: StatusService
  ) { super(); }

  public toggleAutomaticLogout(e: MatSlideToggleChange) {
    const x = !this.clientService.client.AutomaticLogout;
    this.clientService.setAutomaticLogout(x)
      .then(() => {
        this.clientService.client.AutomaticLogout = !this.clientService.client.AutomaticLogout;
        e.source.checked = !this.clientService.client.AutomaticLogout;
        if (e.checked) {
          this.status.setMessage('Automatic logout enabled.', 'Success', true);
        } else {
          this.status.setMessage('Automatic logout disabled.', 'Success', true);
        }
      })
      .catch(err => {
        e.source.checked = true;
        this.status.setError(err, true);
      });
  }

  public addGeneratedPassword() {
    const newpassword = SecurityUtils.generatePassword();
    this.newpw1 = newpassword;
    this.newpw2 = newpassword;
    this.checkPassword();
  }

  public showPassword() {
    this.showPass = !this.showPass;
  }

  public checkPassword() {
    const passwordCategory = SecurityUtils.StrengthChecker(this.newpw1);
    this.passwordStrength = passwordCategory;
  }

  public setOptions(opts: SecurityModalOpts) {
    this.opts = opts;
    this.p = this.profileService.profile;
    this.countries = this.env.Countries.filter(c => c.PhoneCode !== null);
    this.filteredCountries = this.countries;
    this.changeView(this.opts.view);
    this.isLoading = false;
  }

  public changeView(newView: SecurityModalView) {
    this.view = newView;
    if (this.view === 'sessions') {
      this.syncSessions();
    }
  }

  public deleteSession(id: string) {
    this.authService.deleteSession(id)
      .then(() => {
        this.statusService.setMessage('Session removed', 'Success', true);
        this.syncSessions();
      })
      .catch(err => this.statusService.setError(err))
      ;
  }

  public logOutOtherDevices() {
    this.authService.deleteAllSession()
      .then(() => {
        this.statusService.setMessage('Sessions removed', 'Success', true);
        this.syncSessions();
      })
      .catch(err => this.statusService.setError(err))
      ;
  }

  public syncSessions() {
    this.authService.getMySessions()
      .then(sessions => {
        this.sessions = sessions.body;
      });
  }

  public changePassword(event) {
    event.preventDefault();
    const validationError = SecurityUtils.checkPass(this.newpw1, this.newpw2);
    if (validationError) {
      this.statusService.setMessage(validationError, 'Error', true);
      return;
    }

    this.inProgress = true;

    this.authService.changePassword(this.oldpw, this.newpw1)
      .then(() => {
        this.close();
        this.statusService.setMessage('Password updated', 'Success', false);
      })
      .catch(error => {
        this.statusService.setError(error, true);
      })
      .finally(() => this.inProgress = false);
  }

  public filterPhoneCodes(val: string | number): any[] {
    if (val) {
      const filterValue = String(val).toLowerCase();
      const reg = new RegExp(filterValue, 'i');
      return this.countries.filter(country => String(country.PhoneCode).startsWith(filterValue) || country.Display.match(reg));
    }
    return this.countries;
  }

  public setPhoneDisplay($event) {
    this.new_country_phone_display = $event.viewValue;
  }

}
