import { ForecastModel } from '../forecast/models/forecast.model';
import { ForecastTemplateEventDTO } from './forecast-template.model';

export namespace ForecastTemplateAction {

  export class Error {
    public static readonly type = '[FORECAST-TEMPLATE] Use error';
    constructor(public err: any) { }
  }

  export class UseSuccess {
    public static readonly type = '[FORECAST-TEMPLATE] Use success';
    constructor(public dto: ForecastModel) { }
  }

  export class ProgressStatus {
    public static readonly type = '[FORECAST-TEMPLATE] Pusher status message';
    constructor(public dto: ForecastTemplateEventDTO) { }
  }

  export class GetAllSuccess {
    public static readonly type = '[FORECAST-TEMPLATE] Get all success';
    constructor(public templates: any[]) { }
  }
}
