import { EventEmitter } from '@angular/core';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { ForecastUtils } from '@shared/utils/forecast/forecast.utils';
import * as d3 from 'd3';
import { DataRangeSliderOutput, DateRange, DateRangeLimits, DateRangeSliderOptions } from './date-range.options';
import { DateRangeSVGElements } from './date-range.svg-objects';


export class DateRangeSlider {

  // Sizes
  private svgWidth: number;
  private get contentWidth() { return this.svgWidth - 50; }

  // Range circles
  startCircleIdx = 0;
  endCircleIdx = 0;
  // Range circle limits
  maxEndIdx: number;

  // Train-test split bar
  oosBarEnabled = false;
  testStartIdx = 0;

  // Forecast line (yellow)
  forecastHorizon = 0;
  companyMaxHorizon = 0;

  // General properties
  length = 0;
  isDragging = false;
  periodicity: PeriodicityType;
  maxLag: number;
  elems = new DateRangeSVGElements;
  limits: DateRangeLimits;
  public changeEmitter: EventEmitter<DataRangeSliderOutput> = new EventEmitter();
  lastEmitted = 0;
  disabled = false;

  public get activeObsCount() { return this.endCircleIdx - this.startCircleIdx; }
  public get maxHorizon() {
    const max = ForecastUtils.getLagAndHorizon(this.maxLag, this.companyMaxHorizon, this.activeObsCount, this.limits.expandingWindowInt, this.oosBarEnabled).MaxHorizon;
    return Math.min(max, this.companyMaxHorizon);
  }

  /**
   *
   */
  constructor(options: DateRangeSliderOptions, limits: DateRangeLimits) {
    this.limits = limits;

    // Initialize properties
    this.oosBarEnabled = options.oosBarEnabled;
    this.length = options.length;
    this.maxEndIdx = options.maxEndIdx;
    this.forecastHorizon = options.forecastHorizon;
    this.startCircleIdx = options.startIdx;
    this.endCircleIdx = options.endIdx;
    this.periodicity = options.periodicity;
    this.maxLag = options.maxLag;
    this.testStartIdx = options.testStartIdx;
    this.svgWidth = options.width;
    this.companyMaxHorizon = options.maxHorizon;
    this.disabled = options.disabled;

    // Create main SVG obj
    this.elems.svg = d3.create('svg');
    this.elems.svg.style('margin-left', '-24px').attr('width', options.width - 24).attr('height', 50);

    // Create scales
    this.elems.dragScale = this.getDragScale();

    // Get some pixel positions from the new scales, used below during init of svg-objects
    const dragBarWidth = this.elems.dragScale(this.length);

    // Create groups
    this.elems.colorGroup = this.elems.svg.append('g').attr('class', 'color-group').attr('transform', 'translate(25,20)');
    this.elems.sliderGroup = this.elems.svg.append('g').attr('class', 'slider-group').attr('transform', 'translate(25,20)');

    const CreateRect = (grp: d3.Selection<SVGGElement, unknown, HTMLElement, any>, width: number, f: string) => {
      return grp.append('rect')
        .attr('x', 0).attr('y', 0)
        .attr('clip-path', 'url(#slider-clip)')
        .attr('width', width).attr('height', DateRange.barHeight)
        .attr('stroke', 0).style('fill', f);
    };

    // Create all rects (background, train, test, forecast)
    // Each bar has a separate color and shows the size of each section
    this.elems.fullRangeLine = CreateRect(this.elems.colorGroup, dragBarWidth, DateRange.missingBarColor);
    this.elems.maxForecastLine = CreateRect(this.elems.colorGroup, 0, DateRange.maxForecastBarColor);
    this.elems.forecastLine = CreateRect(this.elems.colorGroup, 0, DateRange.forecastBarColor).attr('class', 'forecast-bar');
    this.elems.sampleLine = CreateRect(this.elems.colorGroup, 0, DateRange.trainBarColor).attr('class', 'train-bar');
    this.elems.testLine = CreateRect(this.elems.colorGroup, 0, DateRange.testBarColor);
    this.elems.sliderLine = CreateRect(this.elems.sliderGroup, this.contentWidth, 'none');

    // Add a clip-path to stop all rects from being drawn outside (rounded corners)
    this.elems.sliderGroup.append('clipPath')
      .attr('id', 'slider-clip')
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('rx', DateRange.cornerRadius).attr('ry', DateRange.cornerRadius)
      .attr('height', DateRange.barHeight).attr('width', this.elems.dragScale(this.length));


    this.elems.startCircle = this.elems.sliderGroup.append('circle')
      .attr('cy', DateRange.barHeight / 2)
      .attr('r', DateRange.circleRadius)
      .attr('fill-opacity', DateRange.handleOpacity)
      .attr('class', 'cursor-pointer')
      .style('fill', DateRange.handleColor)
      .style('stroke', 'black');

    this.elems.endCircle = this.elems.sliderGroup.append('circle')
      .attr('cy', DateRange.barHeight / 2)
      .attr('r', DateRange.circleRadius)
      .attr('fill-opacity', DateRange.handleOpacity)
      .attr('class', 'cursor-pointer')
      .style('fill', DateRange.handleColor)
      .style('stroke', 'black');

    this.elems.oosBar = this.elems.sliderGroup.append('rect')
      .attr('y', -11)
      .attr('height', DateRange.testBarHeight)
      .attr('width', DateRange.testBarWidth)
      .style('fill', DateRange.handleColor)
      .attr('fill-opacity', DateRange.handleOpacity)
      .style('stroke', 'black');

    this.enableDrag(this.elems.startCircle, 'start');
    this.enableDrag(this.elems.endCircle, 'end');
    this.enableDrag(this.elems.oosBar, 'test');

    this.setOosBarEnabled(this.oosBarEnabled, this.testStartIdx);
    this.setStartIdx(this.startCircleIdx);
    this.setEndIdx(this.endCircleIdx);
    this.setSampleTestLines();

    if (this.disabled) {
      this.elems.startCircle.attr('class', 'hidden');
      this.elems.endCircle.attr('class', 'hidden');
      this.elems.oosBar.attr('class', 'hidden');
    }

    return this;
  }

  public setLimits(limits: DateRangeLimits) {
    this.limits = limits;
  }

  public setOosBarEnabled(enabled: boolean, idx: number) {
    this.oosBarEnabled = enabled;
    if (enabled) { this.setTestStartIdx(idx); }
    this.elems.testLine.attr('class', `${this.oosBarEnabled ? null : 'hidden'} test-bar`);
    this.elems.oosBar.attr('class', this.oosBarEnabled ? 'test-handle cursor-pointer' : 'hidden');
  }

  public setStartIdx(idx: number) {
    this.startCircleIdx = idx;
    const pixel = this.elems.dragScale(idx);
    const sampleEndPixel = this.elems.dragScale(this.endCircleIdx);

    this.elems.startCircle.attr('cx', pixel);
    this.elems.sampleLine.attr('x', pixel);
    this.elems.sampleLine.attr('width', sampleEndPixel - pixel);

    this.setSampleTestLines();
  }

  public setEndIdx(idx: number) {
    this.endCircleIdx = idx;
    const pixel = this.elems.dragScale(idx);

    this.elems.endCircle.attr('cx', pixel);

    this.setSampleTestLines();
    this.setForecastLines(idx);
  }

  public setTestStartIdx(idx: number) {
    if (!this.oosBarEnabled) { idx = 0; }
    this.testStartIdx = idx;
    const pixel = this.elems.dragScale(idx);

    this.elems.oosBar.attr('x', pixel);

    this.setSampleTestLines();
  }

  private getSanitizedForecastHorizon() {
    if (this.forecastHorizon > this.maxHorizon) {
      return this.maxHorizon;
    }
    return Math.max(this.forecastHorizon, 1);
  }

  public setForecastHorizon(horizon: number) {
    this.forecastHorizon = horizon;
    this.setForecastLines(this.endCircleIdx);
  }

  private setSampleTestLines() {
    // Train set
    const startPx = this.elems.dragScale(this.startCircleIdx);
    const endPx = this.elems.dragScale(this.endCircleIdx);
    this.elems.sampleLine.attr('x', startPx);
    this.elems.sampleLine.attr('width', endPx - startPx);
    // Test set
    if (this.oosBarEnabled) {
      const oosBarPx = this.elems.dragScale(this.testStartIdx);
      this.elems.testLine.attr('x', oosBarPx);
      this.elems.testLine.attr('width', endPx - oosBarPx);
    }
  }

  private setForecastLines(start: number) {
    const startPx = this.elems.dragScale(start);
    const endFcstPx = this.elems.dragScale(start + this.forecastHorizon);
    const endFcstMaxPx = this.elems.dragScale(start + this.maxHorizon);

    this.elems.forecastLine.attr('x', startPx);
    this.elems.forecastLine.attr('width', endFcstPx - startPx);
    this.elems.maxForecastLine.attr('x', startPx);
    this.elems.maxForecastLine.attr('width', endFcstMaxPx - startPx);
  }

  private enableDrag(e: d3.Selection<any, any, any, any>, handle: DateRange.DR_HANDLES) {
    const self = this;
    e.call(d3.drag()
      .on('start', function () { self.dragStarted(this); })
      .on('start.interrupt', function () { e.interrupt(); })
      .on('drag', (event, d) => { self.dragged(<SVGRectElement> d, handle, event); })
      .on('end', function () { self.dragEnded(this); }));
  }

  private dragStarted(e: Element) {
    this.isDragging = true;
    d3.select(e).attr('fill-opacity', 100);
  }

  private dragEnded(e: Element) {
    this.isDragging = false;
    this.emitState(false);
    d3.select(e).attr('fill-opacity', DateRange.handleOpacity);
  }

  private getAllowedPosition(value: number, handle: DateRange.DR_HANDLES) {
    switch (handle) {
      case 'start':
        value = Math.max(value, this.limits.minStartIdx);
        value = Math.min(value, this.limits.maxStartIdx);
        break;
      case 'test':
        value = Math.max(value, this.limits.minTestIdx);
        value = Math.min(value, this.limits.maxTestIdx);
        break;
      case 'end':
        value = Math.max(value, this.limits.minEndIdx);
        value = Math.min(value, this.maxEndIdx);
        break;
    }
    return value;
  }

  private getSanitizedTestStartIdx() {
    const minTestStartIdx = this.startCircleIdx + Math.round(this.activeObsCount * 0.5);
    const maxTestStartIdx = this.endCircleIdx - ForecastUtils.testSetMin(this.activeObsCount, this.forecastHorizon, this.limits.expandingWindowInt);
    if (this.testStartIdx < minTestStartIdx) {
      return minTestStartIdx;
    } else if (this.testStartIdx > maxTestStartIdx) {
      return maxTestStartIdx;
    } else {
      return this.testStartIdx;
    }
  }

  private dragged(_: Element, handle: DateRange.DR_HANDLES, event: any) {
    let value = Math.floor(this.elems.dragScale.invert(event.x));
    value = this.getAllowedPosition(value, handle);
    const oldMaxHorizon = this.maxHorizon;

    let testStartIdx = -1;
    switch (handle) {
      case 'start':
        this.setStartIdx(value);
        testStartIdx = this.getSanitizedTestStartIdx();
        if (testStartIdx !== this.testStartIdx) this.setTestStartIdx(testStartIdx);
        break;
      case 'test':
        this.setTestStartIdx(value);
        break;
      case 'end':
        this.setEndIdx(value);
        testStartIdx = this.getSanitizedTestStartIdx();
        if (testStartIdx !== this.testStartIdx) this.setTestStartIdx(testStartIdx);
        break;
    }

    // Always sanitize and update the horizon, regardless of what we changed
    let horizon = this.getSanitizedForecastHorizon();
    if (horizon !== this.forecastHorizon || oldMaxHorizon !== this.maxHorizon) {
      this.setForecastHorizon(horizon);
    }

    this.emitState();
  }

  private emitState(throttled = true) {
    const now = Date.now();
    if (!throttled || now > this.lastEmitted + 50) {
      this.lastEmitted = now;
      this.changeEmitter.emit({
        startIdx: this.startCircleIdx,
        testIdx: this.testStartIdx,
        endIdx: this.endCircleIdx,
        horizon: this.forecastHorizon
      });
    }
  }

  private getDragScale() {
    return d3.scaleLinear().domain([0, this.length]).range([DateRange.RANGE_START, this.contentWidth]).clamp(true);
  }
}
