import { Component, ViewEncapsulation } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { VariableSelectionModelDTO } from '@core/store/var-select/dtos/variable-selection-result-dto';
import { VarSelectResultModel } from '@core/store/var-select/var-select-result.model';
import { VariableSelectFrontendService } from '@core/store/var-select/var-select.frontend.service';
import { VSMeasurement, VSMeasurementType } from '@modules/lang/language-files/var-select';
import { Store } from '@ngxs/store';
import { SLGOptions } from '@shared/components/graphs/slg/slg.options';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateUtils } from '@shared/utils/date.utils';
import { ModelUtils } from '@shared/utils/forecast/model.utils';
import { VarSelectModalOpts, VarSelectModalView } from './var-select-modal.options';


type InfluenceView = 'Graph' | 'Data';
type ExDo = 'endo' | 'exo';

@Component({
  selector: 'indicio-var-select-modal',
  templateUrl: './var-select-modal.component.html',
  styleUrls: ['./var-select-modal.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class VarSelectResultModalComponent extends ModalModelComponent {

  opts: VarSelectModalOpts;
  tab: VarSelectModalView;
  influenceView: InfluenceView = 'Data';
  result: VarSelectResultModel = null;
  forecastVersion: ForecastVersionModel;
  allForecastVars: ForecastVariableModel[];
  selectedVariableId: string;
  selectedMeasurement: VSMeasurementType = 'COEF';
  validVSMeasurements: VSMeasurement[] = [];
  slgOptions: SLGOptions.Options;
  selectedVariables: { Id: string, inactive: boolean; }[] = [];
  maxPerPage = 7;
  selectedEquation: string;
  largeEquation: boolean;
  expandedEquation: boolean;

  endoLagData: { Name: string, Data: { Lag: string, Value: number; }[], MainVariable: boolean, Const: number; }[];
  sortAscDescEndoLag: 'asc' | 'desc';
  sortByEndoLag = 'name';
  pageEndoLag = 1;
  dataEndoLength: number;
  pagesEndoRequired: boolean;

  exoLagData: { Name: string, Data: { Lag: string, Value: number; }[], MainVariable: boolean, Const: number; }[];
  sortAscDescExoLag: 'asc' | 'desc';
  sortByExoLag = 'name';
  pageExoLag = 1;
  dataExoLength: number;
  pagesExoRequired: boolean;

  influenceData: { Name: string, Data: { Title: string, Value: string; }[], MainVariable: boolean; }[];
  exoInfluenceData: { Name: string, Data: { Title: string, Value: string; }[]; }[];
  sortAscDescInf: 'asc' | 'desc';
  sortByInf = 'name';

  availableVariables: any[];
  availableModels: VariableSelectionModelDTO[];
  plots: ResultFileDTO[] = [];

  public changePage(direction: 1 | -1, exdo: ExDo = 'endo') {
    if (exdo === 'endo') {
      this.pageEndoLag = direction === 1 ? this.pageEndoLag + 1 : this.pageEndoLag - 1;
    } else {
      this.pageExoLag = direction === 1 ? this.pageExoLag + 1 : this.pageExoLag - 1;
    }
  }

  public getPageData(data: any[], exdo: ExDo = 'endo'): { LagName: string, Value: number; }[] {
    let page;
    if (exdo === 'endo') {
      page = this.pageEndoLag;
    } else {
      page = this.pageExoLag;
    }
    const start = page === 1 ? 0 : ((this.maxPerPage * page) - this.maxPerPage);
    return data.slice(start, this.maxPerPage * page);
  }

  public modelSelected(id: string) {
    const state = this.selectedVariables.find(x => x.Id === id);
    return state ? !state.inactive : true;
  }

  constructor(
    protected store: Store,
    private status: StatusService,
    private sanitizer: DomSanitizer,
    private forecastService: ForecastFrontendService,
    private varSelectService: VariableSelectFrontendService,
    public env: EnvironmentService
  ) {
    super();
  }

  public setOptions(options: VarSelectModalOpts) {
    this.opts = options;
    this.tab = options.tab;
    this.setup();
  }

  private setup() {
    const fpromise = this.forecastService.getOrFetchForecastVersion(this.opts.fVersionId);
    const rpromise = this.varSelectService.getOrFetchById(this.opts.fVersionId);
    Promise.all([fpromise, rpromise])
      .then(([f, r]) => {
        const result = r.find(x => x.VsResultType === this.opts.resultType);
        this.result = result;
        this.forecastVersion = f;
        this.allForecastVars = this.forecastVersion.getAllVariables();
        this.selectedVariableId = f.ForecastVariableId;
        this.availableModels = this.result.Models.filter(x => x.Equation !== 'NA');
        this.availableVariables = this.availableModels.map(m => ({ Id: m.ForecastVariableId, Name: m.Name }));
        this.result.ExoModels.forEach(em => {
          em.name = this.getNameForEventOrVariable(em);
        });
        this.selectedMeasurement = result.VSMeasurement;
        this.validVSMeasurements = this.env.validVSMeasurements(result.VSMode);
        this.updatePlots();
        this.updateSLGData();
        this.transformEndoVarSelectionsToLagData(this.selectedVariableId);
        this.transformVarSelectionsToInfluenceData();
      })
      .catch(err => {
        this.status.setError(err, true);
      })
      .finally(() => this.isLoading = false);
  }

  private transformEndoVarSelectionsToLagData(variableId?: string) {
    const endoData: { Name: string, Data: { LagName: string, Value: number; }[], MainVariable: boolean; }[] = [];
    const exoData: { Name: string, Data: { LagName: string, Value: number; }[]; }[] = [];
    const model = this.result.Models.find(m => variableId ? m.ForecastVariableId === variableId : m.IsMainVariable);
    this.selectedEquation = model.Equation;
    this.largeEquation = this.selectedEquation?.length > 150;
    this.expandedEquation = false;

    model.COEFLags.forEach(lag => {
      const nm = this.result.NameMaps.find(x => x.ObjectId === lag.ObjectId);
      let nmn: string;
      let isMainVariable = false;
      let name: string;

      if (nm) {
        nmn = nm.XName;
      } else {
        nmn = 'NA';
      }

      const isExogenous = !nmn.startsWith('Y');

      if (!lag.IsEvent) {
        const v = this.allForecastVars.find(x => x.ForecastVariableId === lag.ObjectId);
        if (v) {
          isMainVariable = !v.IsIndicator;
          name = nmn + ' - ' + v.Name;
        }
      } else {
        const be = this.forecastVersion.ImportedHistoricBaseEvents.find(x => x.Events.some(y => y.ImportedHistoricEventId === lag.ObjectId));
        const he = be?.Events.find(x => x.ImportedHistoricEventId === lag.ObjectId);
        if (he && be) {
          name = nmn + ' - ' + he.Name + ': ' + be.Name;
        }
      }

      // This array is only used for exo, hence it is always 13 long, from 0 to 12
      const vals = Array.from(Array(13).keys()).map((i) => ({ LagName: `Lag ${i}`, Value: 0 }));
      const d = {
        Name: name ? name : nmn,
        Data: isExogenous
          ? vals.map((x, i) => { x.Value = lag.Values.length > i ? lag.Values[i] : 0; return x; })
          : lag.Values.slice(0).map((v, i) => ({ LagName: `Lag ${i + 1}`, Value: v })),
        MainVariable: isMainVariable
      };

      if (!isExogenous) {
        d.Data.push({ LagName: 'Const', Value: lag.Const || 0 });
      }

      if (!isExogenous) {
        endoData.push(d);
      } else {
        exoData.push(d);
      }
    });

    if (endoData.length) {
      for (let i = endoData[0].Data.length - 1; i >= 0; i--) {
        if (!endoData.some(e => e.Data[i]?.Value !== 0)) {
          endoData.forEach(x => x.Data.splice(i, 1));
        }
      }
      this.endoLagData = this.sortLagCollection(<Sort> { active: 'name', direction: 'asc' }, endoData);
      this.pagesEndoRequired = this.endoLagData[0].Data.length >= this.maxPerPage;
      this.dataEndoLength = this.endoLagData.length;
    }

    if (exoData.length) {
      for (let i = exoData[0].Data.length - 1; i >= 0; i--) {
        if (!exoData.some(e => e.Data[i]?.Value !== 0)) {
          exoData.forEach(x => x.Data.splice(i, 1));
        }
      }
      this.exoLagData = this.sortLagCollection(<Sort> { active: 'name', direction: 'asc' }, exoData);
      this.pagesExoRequired = this.exoLagData[0].Data.length >= this.maxPerPage;
      this.dataExoLength = this.exoLagData.length;
    }
  }

  public getNameForEventOrVariable(m: any) {
    let name = '';
    if (m.IsEvent) {
      const be = this.forecastVersion.ImportedHistoricBaseEvents.find(x => x.Events.some(y => y.ImportedHistoricEventId === m.ObjectId));
      const he = be?.Events.find(x => x.ImportedHistoricEventId === m.ObjectId);
      if (he && be) {
        name = he.Name + ': ' + be.Name;
      }
    } else {
      const v = this.allForecastVars.find(x => x.ForecastVariableId === (m.ForecastVariableId || m.ObjectId));
      if (v) { name = v.Name; }
    }
    return name;
  }

  private transformVarSelectionsToInfluenceData() {
    const endoData = [];
    const exoData = [];
    this.result.Models.forEach(model => {
      const d = { Name: model.Name, Data: [], MainVariable: model.IsMainVariable };
      this.getInfluenceData(d, model);
      endoData.push(d);
    });
    this.result.ExoModels.forEach(model => {
      const d = { Name: this.getNameForEventOrVariable(model), Data: [], MainVariable: false };
      this.getInfluenceData(d, model);
      exoData.push(d);
    });

    if (endoData.length) { this.influenceData = this.sortInfluenceCollection(<Sort> { active: 'name', direction: 'asc' }, endoData); }
    if (exoData.length) { this.exoInfluenceData = this.sortInfluenceCollection(<Sort> { active: 'name', direction: 'asc' }, exoData); }
  }

  private getInfluenceData(d, model) {
    switch (this.result.VSMode) {
      case 'mfbackward':
      case 'backward':
        d.Data.push(...[{ Title: 'In-sample', Value: model.InSampleInfluence[0]?.toString() }, { Title: 'Out-of-sample', Value: model.OOSInfluence[0]?.toString() }]);
        break;
      case 'mfcoef':
      case 'coef':
        d.Data.push(...[{ Title: 'COEF', Value: model.COEFInfluence[0]?.toString() }]);
        break;
      default:
        console.error('Unknown VS mode in var-select-modal');
        break;
    }
  }

  private updatePlots() {
    this.plots = this.result.VSPlots.sort((a, b) => a.sortIndex - b.sortIndex);
  }

  public updateSLGData() {
    this.transformVarSelectionsToSLG();
  }

  public updateLagData() {
    this.transformEndoVarSelectionsToLagData(this.selectedVariableId);
  }

  public toggleModel(id: string) {
    const idx = this.selectedVariables.findIndex(x => x.Id === id);
    if (idx === -1) {
      this.selectedVariables.push({ Id: id, inactive: true });
    } else {
      this.selectedVariables[idx].inactive = !this.selectedVariables[idx].inactive;
    }
    this.updateSLGData();
  }

  private transformVarSelectionsToSLG() {
    const slgOptions = new SLGOptions.Options();
    slgOptions.TimeAxis = new SLGOptions.AxesOptions();
    slgOptions.Periodicity = this.forecastVersion.Periodicity;
    let measurements: any[] = [...this.result.Models.filter(x => x.Equation !== 'NA')];
    if (this.selectedMeasurement === 'COEF') {
      measurements.push(...this.result.ExoModels);
    }
    measurements = measurements.map((m: any, i) => {
      m.color = ModelUtils.getColorForIndex(i);
      return {
        ObjectId: m.ForecastVariableId || m.ObjectId,
        Color: m.color,
        Name: this.getNameForEventOrVariable(m),
        values: this.result.getInfluenceType(m.ForecastVariableId || m.ObjectId, this.selectedMeasurement)
      };
    });
    measurements = measurements.filter(x => !this.selectedVariables.find(y => x.ObjectId === y.Id && y.inactive));
    const firstDate = DateUtils.newMoment(this.forecastVersion.DataUntilDateRequirement);
    const unit = this.forecastVersion.periodicity.getMomentDurationConstructor();
    measurements.forEach((line, i) => {
      const slgData = new SLGOptions.Data();
      slgData.AxisPosition = SLGOptions.AxisPosition.left;
      slgData.Color = line.Color;
      slgData.Name = line.Name;
      slgData.Data = line.values.map((v, j) => {
        const c = DateUtils.newMoment(firstDate).subtract(j, unit);
        if (i === 0) {
          slgOptions.TimeAxis.Dates.push(c.toDate());
        }
        return {
          moment: c,
          Date: c.toDate(),
          Value: v
        };
      });
      slgData.Data.sort((a, b) => a.Date.getTime() - b.Date.getTime());
      slgOptions.Data.push(slgData);
    });
    slgOptions.TimeAxis.Dates.sort((a, b) => a.getTime() - b.getTime());
    const allValueModels = slgOptions.Data.map(d => d.Data);
    const allValues = allValueModels.map(vm => vm.map(m => m.Value)).reduce((acc, curr) => acc.concat(...curr), []);
    const filtered = allValues.filter(v => !isNaN(+v));
    const max = Math.max(...filtered);
    const min = Math.min(...filtered);

    // Setup Time axis
    slgOptions.TimeAxis.Position = SLGOptions.AxisPosition.bottom;
    slgOptions.TimeAxis.Min = slgOptions.TimeAxis.Dates[0];
    slgOptions.TimeAxis.Max = slgOptions.TimeAxis.Dates.last();
    slgOptions.TimeAxis.TickCount = 4;

    // Setup left axis
    slgOptions.LeftAxis = new SLGOptions.AxesOptions();
    slgOptions.LeftAxis.Position = SLGOptions.AxisPosition.left;
    slgOptions.LeftAxis.Min = min;
    slgOptions.LeftAxis.Max = max;
    slgOptions.LeftAxis.TickCount = this.result.Models[0].COEFInfluence.length;

    slgOptions.Width = 660;
    slgOptions.Height = 330;
    slgOptions.DotSize = 3;
    slgOptions.Margins = { bottom: 25, left: 50, right: 15, top: 5 };
    this.slgOptions = slgOptions;
  }

  public sortLagCollection(event: Sort, object: any[], exdo: 'endo' | 'exo' = 'endo') {
    const what = event.active;

    let ascDesc;

    if (exdo === 'endo') {
      ascDesc = what === this.sortByEndoLag ? (this.sortAscDescEndoLag === 'asc' ? 'desc' : 'asc') : 'asc';
      this.sortAscDescEndoLag = ascDesc;
      this.sortByEndoLag = what;
    } else {
      ascDesc = what === this.sortByExoLag ? (this.sortAscDescExoLag === 'asc' ? 'desc' : 'asc') : 'asc';
      this.sortAscDescExoLag = ascDesc;
      this.sortByExoLag = what;
    }

    if (what === 'name') {
      object = object.sort((a, b) => {
        if (a.Name < b.Name) { return -1; }
        if (a.Name > b.Name) { return 1; }
        return 0;
      });
      if (ascDesc === 'asc') {
        const mainVar = object.splice(object.findIndex(o => o.MainVariable), 1).pop();
        object.unshift(mainVar);
      }
    } else if (what.includes('lag')) {
      const lag = +what.match(/lag(\d)/)[1];
      if (!lag) { return; }
      object = object.sort((a, b) => {
        const lagIndex = a.Data.findIndex(x => x.Lag === lag);
        if (a.Data[lagIndex].Value < b.Data[lagIndex].Value) { return -1; }
        if (a.Data[lagIndex].Value > b.Data[lagIndex].Value) { return 1; }
        return 0;
      });
    }

    if (this.sortAscDescEndoLag === 'desc') {
      object = object.reverse();
    }

    return object;
  }

  public sortInfluenceCollection(event: Sort, object: any[]) {
    const what = event.active;
    this.sortAscDescInf = what === this.sortByInf ? (this.sortAscDescInf === 'asc' ? 'desc' : 'asc') : 'asc';
    this.sortByInf = what;
    if (what === 'name') {
      object = object.sort((a, b) => {
        if (a.Name < b.Name) { return -1; }
        if (a.Name > b.Name) { return 1; }
        return 0;
      });
      if (this.sortAscDescInf === 'asc') {
        const mainVar = object.splice(object.findIndex(o => o.MainVariable), 1).pop();
        object.unshift(mainVar);
      }
    } else {
      object = object.sort((a, b) => {
        const titleIndex = a.Data.findIndex(x => x.Title === what);
        if (a.Data[titleIndex].Value < b.Data[titleIndex].Value) { return -1; }
        if (a.Data[titleIndex].Value > b.Data[titleIndex].Value) { return 1; }
        return 0;
      });
    }

    if (this.sortAscDescInf === 'desc') {
      object = object.reverse();
    }

    return object;
  }
}
