import { Component } from '@angular/core';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { UpdateRemoteDataSourceDTO } from '@core/store/providers/dtos/remote-data-source.dto';
import { NewRemoteDataSourceModelModel } from '@core/store/providers/models/new-remote-data-source-model.model';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { ProviderFrontendService } from '@core/store/providers/provider.frontend.service';
import { SourceType } from '@modules/lang/language-files/source-types';
import { Store } from '@ngxs/store';
import { OpenSetCompanyModal } from '@shared/modals/company/set-company/set-company-modal.actions';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { ModifyAPILoginModalOpts } from './modify-api-login.options';



@Component({
  selector: 'indicio-modify-api-login-modal',
  templateUrl: './modify-api-login.component.html'
})
export class ModifyAPILoginModalComponent extends ModalModelComponent {
  message = '';
  decline = 'Decline';
  confirm = 'Confirm';

  public provider: SourceType;
  public providerName: string;
  public helpText: string;
  public dataSource: RemoteDataSourceModel;
  public confirmFn: Function;
  public cancelFn: Function;
  public hideCloseButton = false;
  private keyTest: Function;
  public regEx: RegExp;
  public extraOpts: any;

  manyCompanies: boolean;

  newUsername: string;
  newPassword: string;
  loading = false;
  private accountUpdated = false;

  constructor(
    public store: Store,
    private providerService: ProviderFrontendService,
    private statusService: StatusService,
    public clientService: ClientFrontendService,
    private authService: AuthFrontendService
  ) {
    super();
  }

  setKey() {
    let call;
    this.pending = true;
    if (this.dataSource) {
      call = (key?) => this.updateLogin(key);
    } else {
      call = (key?) => this.addNewLogin(key);
    }

    if (this.keyTest) {
      return this.keyTest(this.newUsername, this.newPassword).then((response) => {
        call(this.extraOpts?.testKeyFuncReturnsApiKey ? response : null);
      }).catch((_e) => {
        const msg = 'The login is not valid.';
        this.statusService.setMessage(msg, 'Error', true);
      }).finally(() => {
        this.pending = false;
      });
    }

    return call();
  }

  private updateLogin(apiKey?: string) {
    const model: UpdateRemoteDataSourceDTO = {
      RemoteDataSourceId: this.dataSource.RemoteDataSourceId,
      Provider: this.dataSource.Provider,
      User: this.newUsername,
      Password: this.newPassword,
      ApiKey: apiKey
    };
    return this.providerService.editDataSource(model)
      .then(() => {
        this.accountUpdated = true;
        this.close();
        this.statusService.setMessage('The login has been updated.', 'Success');
        if (this.confirmFn) {
          this.confirmFn(this.newUsername);
        }
      });
  }

  private addNewLogin(apiKey?: string) {
    const model: NewRemoteDataSourceModelModel = {
      Provider: this.provider.Value.toLowerCase(),
      User: this.newUsername,
      Password: this.newPassword,
      ApiKey: apiKey
    };
    return this.providerService.createDataSource(model)
      .then(() => {
        this.accountUpdated = true;
        this.close();
        this.statusService.setMessage('The login has been added.', 'Success');
        if (this.confirmFn) {
          this.confirmFn(this.newUsername);
        }
      });
  }

  setOptions(options: ModifyAPILoginModalOpts) {
    this.provider = options.provider;
    this.providerName = options.provider.Display;
    this.helpText = options.helpText;
    this.dataSource = options.dataSource || null;
    this.newUsername = options.dataSource?.User || '';
    this.confirmFn = options.confirmFn;
    this.regEx = options.regEx;
    this.keyTest = options.keyTest;
    this.extraOpts = options.extraOpts;
    if (options.extraOpts?.disableClose) {
      this.hideCloseButton = true;
      this.manyCompanies = this.clientService.client.CompanyIds.length > 1;
      this.onClose = () => { };
    }

    this.onClose = () => {
      if (!this.accountUpdated && options.cancelFn) {
        options.cancelFn();
      }
    };

    this.isLoading = false;
  }

  public openChangeCompanyModal() {
    this.close();
    this.store.dispatch(new OpenSetCompanyModal(this.clientService.activeCompany.CompanyId));
  }

  public logout() {
    this.authService.logout(false);
  }
}


