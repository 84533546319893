import { ThemeOptionType } from '@core/constants/theme-options';
import { Def } from '@core/decorators/def';
import { NavigationForecastListViewType } from '@modules/root/components/navigation/entities/navigation.entities';

export type SourceVariableListViewType = 'list' | 'tags' | 'projects';

export class ClientSettingsDTO {
  // From backend
  @Def() InitialSetup: boolean = false;
  @Def() DateFormat: string = 'yyyy-MM-dd';
  @Def() TimeFormat: string = 'HH:mm:ss';
  @Def() Theme: ThemeOptionType = 'legacy';
  @Def() AdvancedUI: boolean = false;
  @Def() SourceVariableListView: SourceVariableListViewType = 'list';
  @Def() NavigationListView: NavigationForecastListViewType = 'project-list';
  @Def() DisplayLanguage = 'en';
  @Def() DecimalCharacter = 'Period';
  @Def() RememberActiveCompany: boolean = false;


  // Not from backend
  AdminDate = 'MM-dd HH:mm:ss';
  TimeFormatNoSeconds = 'HH:mm';
  FullFormat = 'yyyy-MM-dd HH:mm';
  FullFormatNoSeconds = '';
  ActualTheme: ThemeOptionType;
}
