<indicio-modal-base [child]="this"
                    modalTitle="Nowcasted variable information"
                    [loading]="isLoading"
                    extraClasses="modal-content--min-height">

  <div *ngIf="!isLoading"
       style="--mat-select-trigger-text-size: 12px;">
    <ng-container *ngTemplateOutlet="generalView"></ng-container>
    <ng-container *ngTemplateOutlet="buttonRow"></ng-container>
  </div>
</indicio-modal-base>

<ng-template #generalView>
  This variable has been nowcasted

  <ng-container *ngIf="!results.length">
    and has not yet produced any results. You can do this by <span indicioTippy="Navigate to nowcast"
          (click)="navigateToNowcast()"
          class="underline pointer">navigating</span> to the
    forecast and clicking on the save button on the summary page. Or wait for the result to be produced.
  </ng-container>


  <div class="data-table extra-margin-top"
       style="width: 500px">

    <div class="data-table__body"
         #fileuploadscroll
         [style.maxHeight.px]="301">

      <ng-container *ngIf="results.length">
        <div class="body__row body__row--center-height"
             style="background-color: var(--indicio-modal-accent-color, #003ca154); height: 35px;">
          <div class="column left-align"
               style="width: 120px;">
            <span class="bold">Selected result</span>
          </div>
          <div class="column left-align"
               style="width: 330px;">
            <div style="width: 200px">
              <mat-form-field>
                <mat-select panelClass="data-table-panel-opts fitted-select"
                            [(value)]="selectedMeta"
                            (selectionChange)="setSelectedMeta($event)">
                  <mat-option [value]="res"
                              *ngFor="let res of results">{{ res.ModelName }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
          <div class="column"
               style="width: 50px;">
            <i class="ion-android-exit pointer"
               indicioTippy="Navigate to nowcast"
               (click)="navigateToNowcast()"
               style="font-size: 15px; margin-left: 5px;"></i>
          </div>
        </div>
      </ng-container>

      <div class="body__row body__row--center-height body__row--max-height-30">
        <div class="column left-align"
             style="width: 120px;">
          <span class="bold">Nowcast name</span>
        </div>
        <div class="column left-align"
             style="width: 330px;">
          {{ nowcast.Name }}
        </div>
      </div>

      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngIf="result">
        <div class="column left-align"
             style="width: 120px;">
          <span class="bold">Result created at</span>
        </div>
        <div class="column left-align"
             style="width: 380px;">
          {{ result.CreatedDate | dateFormat }}
        </div>
      </div>

      <div class="body__row body__row--center-height body__row--max-height-30">
        <div class="column left-align"
             style="width: 120px;">
          <span class="bold">Forecast periodicity</span>
        </div>
        <div class="column left-align"
             style="width: 380px;">
          {{ nowcast.periodicity.Display }}
        </div>
      </div>

      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngIf="result">
        <div class="column left-align"
             style="width: 120px;">
          <span class="bold">Weight method</span>
        </div>
        <div class="column left-align"
             style="width: 380px;">
          {{ result.WeightMethod }}
        </div>
      </div>
    </div>
  </div>


  <ng-container *ngIf="results.length">
    <ng-container *ngIf="result.getModels(envService.UnivariateModelNames) as uniModels">
      <ng-container *ngTemplateOutlet="resultTypeTemplate; context: { models: uniModels, title: 'Univariate models' }">
      </ng-container>
    </ng-container>

    <ng-container *ngIf="result.getModels(envService.MultivariateModelNames) as multiModels">
      <ng-container
                    *ngTemplateOutlet="resultTypeTemplate; context: { models: multiModels, title: 'Multivariate models' }">
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="row extra-margin-top">
    <div class="col-xs-12">
      <span class="red cursor-pointer"
            (click)="deleteNowcast()">
        Remove nowcast</span>
    </div>
  </div>
</ng-template>

<ng-template #resultTypeTemplate
             let-models="models"
             let-title="title">
  <indicio-foldable-row [title]="title"
                        *ngIf="models.length">
    <div class="data-table"
         style="width: 480px">
      <div class="data-table__header">
        <div class="column header left-align"
             style="width: 370px;">Name</div>
        <div class="column header"
             style="width: 100px;">{{ models[0].Weight.length > 1 ? 'Avg. Weight' : 'Weight' }}</div>
      </div>

      <div class="data-table__body"
           [style.maxHeight.px]="200">
        <div class="body__row body__row--center-height"
             *ngFor="let model of models">
          <div class="column left-align"
               style="width: 370px;">
            {{ envService.getModelName(model.Name).Display }}
          </div>
          <div class="column"
               style="width: 100px;">
            <span [class]="{ pointer: model.Weight.length > 1 }"
                  (click)="model.Weight.length > 1 ? openModelWeightDialog(model) : null">
              {{ model.averageWeight | propertyValue }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </indicio-foldable-row>
</ng-template>

<ng-template #buttonRow>
  <div class="row extra-margin-top">
    <div class="col-xs-6">

      <button class="btn blue outline"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6"
         *ngIf="results.length">
      <button class="btn right"
              [disabled]="variable?.SourceVariableMetaId === selectedMeta?.SourceVariableMetaId || pending"
              (click)="useSelectedResult()">
        <span *ngIf="!pending">Save</span>
        <i class="ion-load-c spinner"
           *ngIf="pending"></i>
      </button>
    </div>
  </div>
</ng-template>
