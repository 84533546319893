import { RemoveSharepointModalOptions } from './remove-sharepoint-modal.component.options';

export class OpenRemoveSharepointModal {
  public static readonly type = '[MODAL] Open delete sharepoint files modal';
  data: RemoveSharepointModalOptions;
  constructor(
    forecastId: string,
    forecastVersionId: string,
    remoteDataSourceId: string,
    callback?: any,
  ) {
    this.data = { forecastId, forecastVersionId, remoteDataSourceId, callback };
  }
}
