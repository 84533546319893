import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { KoladaKPIGroupDTO, KoladaKPIGroupsDTO } from '@core/store/providers/kolada/dtos/kolada-groups.dto';
import { KoladaKPIListDTO } from '@core/store/providers/kolada/dtos/kolada-kpi.dto';
import { KoladaMunicipalityValueListDTO } from '@core/store/providers/kolada/dtos/kolada-municipality-values.dto';
import { KoladaMunicipalityListDTO } from '@core/store/providers/kolada/dtos/kolada-municipality.dto';
import { KoladaOrganizationalUnitListDTO } from '@core/store/providers/kolada/dtos/kolada-organizational-unit.dto';
import { KoladaMunicipalityModel } from '@core/store/providers/kolada/kolada-municipality.model';
import { KoladaMapper } from '@core/store/providers/kolada/kolada.mapper';
import { EnvironmentService } from '@core/services/environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class KoladaService {

  private baseUrl = 'http://api.kolada.se/v2';

  public municipalities: KoladaMunicipalityModel[];

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private mapper: KoladaMapper
  ) { }

  private getUrl(path: string, withProxy = true) {
    const url = `${this.baseUrl}/${path}`;

    if (withProxy) {
      return `${this.envService.env.CorsProxy.replace(/\/$/, '')}/${url}`;
    }

    return url;
  }

  public getGroups() {
    return this.http.get<KoladaKPIGroupsDTO>(this.getUrl('kpi_groups')).toPromise()
      .then(res => this.mapper.mapGroups(res));
  }

  public getMunicipalities() {
    if (this.municipalities) {
      return Promise.resolve(this.municipalities);
    }

    return this.http.get<KoladaMunicipalityListDTO>(this.getUrl('municipality')).toPromise()
      .then(res => {
        this.municipalities = res.values.map(dto => this.mapper.mapMunicipality(dto));
        return this.municipalities;
      });
  }

  public getGroup(groupId: string) {
    return this.http.get<KoladaKPIGroupDTO>(this.getUrl(`kpi_groups/${groupId}`)).toPromise()
      .then(res => this.mapper.mapGroup(res));
  }

  public searchKPI(query: string) {
    return this.http.get<KoladaKPIListDTO>(this.getUrl(`kpi?title=${query}`)).toPromise()
      .then(res => res.values.map(kpi => this.mapper.mapKPItoMember(kpi)));
  }

  public getKPI(kpiId: string) {
    return this.http.get<KoladaKPIListDTO>(this.getUrl(`kpi/${kpiId}`)).toPromise()
      .then(res => res.values.map(kpi => this.mapper.mapKPI(kpi)));
  }

  public getMunicipalityValues(kpiId: string, municipalityId: string) {
    return this.http.get<KoladaMunicipalityValueListDTO>(this.getUrl(`data/kpi/${kpiId}/municipality/${municipalityId}`)).toPromise()
      .then(res => this.mapper.mapMunicipalityValues(res, this.getUrl(`data/kpi/${kpiId}/municipality/${municipalityId}`, false)));
  }

  public getOUValues(kpiId: string, ouId: string) {
    return this.http.get<KoladaMunicipalityValueListDTO>(this.getUrl(`data/kpi/${kpiId}/ou/${ouId}`)).toPromise()
      .then(res => this.mapper.mapOUValues(res, this.getUrl(`data/kpi/${kpiId}/ou/${ouId}`, false)));
  }

  public getMunicipalityOUs(municipalityId: string) {
    return this.http.get<KoladaOrganizationalUnitListDTO>(this.getUrl(`ou?municipality=${municipalityId}`)).toPromise()
      .then(res => res.values.map(ou => this.mapper.mapOU(ou)));
  }
}
