<h1 mat-dialog-title>Search options</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-select #select
                [value]="newFilter"
                placeholder="Select region"
                (selectionChange)="setAnswer($event)">
      <mat-option [value]="noRegion"
                  title="No region filter">
        No region filter
      </mat-option>
      <mat-optgroup *ngFor="let filter of data.Filters"
                    [label]="filter.Title">
        <mat-option [value]="region"
                    [title]="region.Title"
                    *ngFor="let region of filter.Items">
          {{ region.Title }}
        </mat-option>
      </mat-optgroup>

    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">Close</button>
  <button mat-flat-button
          [mat-dialog-close]="newFilter">
    Save
  </button>
</div>
