<ng-container *ngTemplateOutlet="mfaTemplate"></ng-container>

<ng-template #mfaTemplate>
  <ng-container *ngIf="setupStep === 1">
    <ng-container *ngTemplateOutlet="mfaStep1"></ng-container>
  </ng-container>
  <ng-container *ngIf="setupStep === 2">
    <ng-container *ngTemplateOutlet="mfaStep2"></ng-container>
  </ng-container>
</ng-template>

<ng-template #mfaStep2>
  <div class="row margin-top">
    <div class="col-xs-12 text-center bold">Manual setup code</div>
    <div class="col-xs-12 margin-top selectable-text">{{ this.manualSetupCode }}</div>
  </div>
  <div class="row margin-top">
    <div class="col-xs-12 text-center bold">Recovery codes (keep safe)</div>
    <div class="col-xs-4 text-center selectable-text  margin-top"
         *ngFor="let code of this.oneTimeCodes">{{ code }}</div>
  </div>
  <div class="row margin-top">
    <div class="col-xs-12 text-center bold">QR Code (scan with app)</div>
    <div class="col-xs-12 text-center">
      <img class="QR-code"
           [alt]="this.manualSetupCode"
           [src]="image">
    </div>
  </div>
  <div class="row margin-top">
    <div class="col-xs-12 text-center bold">Verify setup. Note: 2-factor will not be enabled unless this step is
      successful</div>
    <div class="col-xs-7">
      <div class="input-label bold">Enter code</div>
      <span class="subtitle">The code can be found in your 2-factor app</span>
    </div>
    <div class="col-xs-5 text-right"
         style="margin-top: 15px;">
      <mat-form-field>
        <mat-label>2-factor code</mat-label>
        <input type="number"
               class="material"
               [(ngModel)]="code_to_verify"
               (keyup.enter)="verify2FA()"
               matInput
               name="otp"
               required>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <p style="font-weight: bold;
      color: #E26464;
      line-height: 23px;">{{warningMessage}}</p>
    </div>
    <div class="col-xs-6">
      <button class="btn pull-right"
              (click)="verify2FA()">
        <i *ngIf="inProgress"
           class="ion-load-c spinner"></i>
        <span *ngIf="!inProgress">Verify</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #mfaStep1>
  <div class="row margin-top">
    <div *ngIf="!authService.setupInProcess"
         class="col-xs-12 italic text-center">Two-factor authentication:
      {{ authService.mfa_enabled ? 'Enabled' : 'Disabled' }}
    </div>
    <div *ngIf="authService.mfa_enabled"
         class="col-xs-12 italic text-center">Provider: {{ authService.mfa_method }}</div>
  </div>
  <ng-container *ngIf="!authService.mfa_enabled && !authService.setupInProcess">
    <div class="row"
         title="2-factor provider">
      <div class="col-xs-7">
        <div class="input-label bold">Provider</div>
        <span class="subtitle">Choose a two-factor authentication provider</span>
      </div>
      <div class="col-xs-5 text-right"
           style="margin-top: 15px;">
        <mat-form-field>
          <mat-select [(value)]="new_method"
                      required>
            <mat-option [value]="v.Value"
                        [title]="v.Description"
                        *ngFor="let v of suppliersDisplay">{{ v.Display }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <button class="btn outline blue"
                *ngIf="useSkip"
                [ngClass]="{disabled: inProgress}"
                (click)="onSkip()">
          <span>Skip</span>
        </button>
      </div>
      <div class="col-xs-6">
        <button class="btn pull-right"
                (click)="setup2FA()">
          <i *ngIf="inProgress"
             class="ion-load-c spinner"></i>
          <span *ngIf="!inProgress">Setup</span>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="authService.mfa_enabled && !authService.setupInProcess">
    <div class="row margin-top">
      <div class="col-xs-4">
        <button class="btn outline blue left"
                type="button"
                (click)="close()">
          <span>Close</span>
        </button>
      </div>
      <div class="col-xs-4 vertical-align text-center"
           style="height:80px;">
        <span *ngIf="modalWarningMessage"
              class="red"
              style="width: 100%;"><i>{{modalWarningMessage}}</i></span>
      </div>
      <div class="col-xs-4"
           *ngIf="!preventDisabled2FA">

        <button class="btn important pull-right"
                (click)="disable2FA()">
          <i *ngIf="inProgress"
             class="ion-load-c spinner"></i>
          <span *ngIf="!inProgress">Disable 2FA</span></button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="authService.setupInProcess">
    <div class="row">
      <div class="col-xs-12 text-center">
        The setup is about to finish, you may close the modal...
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <button class="btn outline blue left"
                type="button"
                (click)="close()">
          <span>Close</span>
        </button>
      </div>
      <div class="col-xs-4 vertical-align text-center"
           style="height:80px;">
      </div>
      <div class="col-xs-4">
        <button class="btn important pull-right">
          <i class="ion-load-c spinner"></i></button>
      </div>
    </div>
  </ng-container>
</ng-template>
