<indicio-modal-base [child]="this"
                    modalTitle="Forecast warnings"
                    modalClass=""
                    [loading]=isLoading
                    bodyExtraClass="overflow-auto">
  <div style="width: 85%; margin: 0 auto;"
       *ngIf="!isLoading && forecastVersion">

    <ng-container *ngIf="forecastVersion.IndicatorWarnings?.length">
      <ng-container [ngTemplateOutlet]="warningsTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="!forecastVersion.IndicatorWarnings?.length">
      <ng-container [ngTemplateOutlet]="noWarningsTemplate"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>
</indicio-modal-base>

<ng-template #warningsTemplate>
  <div class="row">
    <div class="col-xs-12">
      This version of the forecast was created by a user that did not have access to all indicator variables. This
      caused the following variables to not be included in this version of the forecast.
    </div>
  </div>
  <div class="row variable-row vertical-align extra-margin-top">
    <div class="col-xs-12">
      <div class="data-table">
        <div class="data-table__header">
          <div class="column header"
               style="width: 80px;">#</div>
          <div class="column header"
               style="width: 350px; text-align: left; padding-left: 5px;">Variable</div>
          <div class="column header">Owner email</div>
        </div>

        <div class="data-table__body big"
             style="max-height: 350px;">
          <div class="body__row body__row--center-height body__row--max-height-30"
               *ngFor="let error of forecastVersion.IndicatorWarnings; let index = index">
            <div class="column"
                 style="width: 80px;">{{ index + 1}}</div>
            <div class="column crop-string"
                 style="width: 350px; text-align: left;">
              {{error.Item1}}
            </div>
            <div class="column crop-string">
              {{error.Item2}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noWarningsTemplate>
  <div class="row">
    <div class="col-xs-12">
      No warnings to display!
    </div>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="row">
    <div class="col-xs-6">
      <button class="btn outline blue"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6"
         *ngIf="!processDone && isOwner">
      <button class="btn pull-right"
              (click)="discard()">
        <span *ngIf="!pending">Discard</span>
        <i class="ion-load-c spinner"
           *ngIf="pending"></i>
      </button>
    </div>
  </div>
</ng-template>
