import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { ModalModelComponent } from '@shared/modals/modal.model';
import { StatusService } from '@core/services/status/status.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { HistoricEventFrontendService } from '@core/store/historic-event/historic-event.frontend.service';
import { DeleteFCastEventModalOptions } from './delete-forecast-event-modal.options';
import { ImportedHistoricBaseEventModel } from '@core/store/historic-event/models/imported/imported-historic-base-event.model';

@Component({
  selector: 'indicio-delete-event-variable-modal',
  templateUrl: './delete-forecast-event-modal.component.html',
  styleUrls: ['./delete-forecast-event-modal.component.less'],
})
export class DeleteForecastEventModalComponent extends ModalModelComponent {

  public events: ImportedHistoricBaseEventModel[] = [];
  public forecastVersion: ForecastVersionModel;
  public callback: any;

  public isLoading = true;

  constructor(
    protected store: Store,
    private status: StatusService,
    private service: HistoricEventFrontendService,
    public envService: EnvironmentService,
    public appearance: AppearanceService
  ) { super(); }

  public setOptions(options: DeleteFCastEventModalOptions) {
    this.events = options.events;
    this.forecastVersion = options.forecastVersion;
    this.callback = options.callback;
    this.isLoading = false;
  }

  public removeEvents() {
    const eventsToBeRemoved = this.events;
    this.pending = true;

    const promises: Promise<any>[] = [];
    eventsToBeRemoved.forEach(v => {
      promises.push(this.service.removeEventFromForecast(v));
    });
    Promise.all(promises)
      .then(() => {
        if (this.callback) {
          this.callback();
        }
        this.close();
        this.status.setMessage('Historical Event(s) removed from forecast', 'Success');
      })
      .catch(error => {
        this.status.setError(error, true);
      }).finally(() => {
        this.pending = false;
      });
  }
}


