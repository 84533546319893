import { DbConfigurationDTO } from '@core/store/providers/sql-database/sql-database.dtos';


export namespace DatabaseConnectionActions {

  export class RemoveDatabaseTab {
    public static readonly type = '[SQL-DATABASE] Remove Database Tab';
    constructor() { }
  }

  export class CreatedConfig {
    public static readonly type = '[SQL-DATABASE] Created new DB config';
    constructor(public cfg: DbConfigurationDTO) { }
  }

  export class TabComponentLoading {
    public static readonly type = '[SQL-DATABASE] Set loading in Tab Database Component';
    constructor(public treeLoading: boolean, public containerLoading?: boolean, public setTitlesEmpty?: boolean) { }
  }

}

