import { Def } from '@core/decorators/def';
import { ParsedLogType } from '@modules/admin/entities/syslog-dto';

export namespace GithubIssue {

  export const GithubIssueSubjects = [
    'Account',
    'Assessments',
    'Forecast',
    'Project',
    'Statistics',
    'Other'
  ];

  export const GithubIssueProblemTypes = [
    'Design',
    'Unresponsive',
    'Other'
  ];

  export type GithubIssueSubjectsType = 'Account' | 'Assessments' | 'Forecast' | 'Project' | 'Statistics' | 'Other' | 'Pusher' | string;
  export type GithubIssueProblemTypesType = 'Design' | 'Unresponsive' | 'Other' | 'Internal error' | string;

  export class GithubIssueReport {
    Subject: GithubIssueSubjectsType;
    Type: GithubIssueProblemTypesType;
    Text: string;
    User: string;
    FrontendVersion: string;
    BackendVersion: string;
    IdentityVersion: string;
    ImportVersion: string;
  }

  export class ErrorLocation {
    URL: string;
    Dialog: string;
  }

  export class FrontendErrorReportDTO {
    @Def() Subject: string;
    @Def() Stacktrace: string;
    @Def() Type?: ParsedLogType;
    @Def() User?: string;
    @Def() Location?: ErrorLocation;
    @Def() ForecastId?: string;
    @Def() CompanyId?: string;
  }
}

