import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { ModalModelComponent } from '@shared/modals/modal.model';
import { StatusService } from '@core/services/status/status.service';
import { FileErrorModalOpts } from './file-error-information-modal.options';

@Component({
  selector: 'indicio-file-error-info-modal',
  templateUrl: './file-error-information-modal.component.html'
})
export class FileErrorInformationModalComponent extends ModalModelComponent {

  onBack: Function;

  constructor(
    protected store: Store,
    private status: StatusService
  ) {
    super();
  }

  public setOptions(options: FileErrorModalOpts) {
    if (options.error) {
      this.status.setError(options.error, true);
    }
    this.onBack = options.onBack ? options.onBack : null;
    this.isLoading = false;
  }

  public back() {
    this.close();
    if (this.onBack) {
      this.onBack();
    }
  }
}
