import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface AutotaskEmailDialogData {
  Dto: {};
}

@Component({
  selector: 'indicio-email-template-dialog',
  templateUrl: 'email-template.dialog.html',
})
export class AutotaskEmailDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AutotaskEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AutotaskEmailDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(true);
  }
}
