<indicio-modal-base [child]="this"
                    modalTitle="Information"
                    [loading]="isLoading">

  <div *ngIf="!isLoading">
    <ng-container *ngTemplateOutlet="generalView"></ng-container>
    <ng-container *ngTemplateOutlet="buttonRow"></ng-container>
  </div>
</indicio-modal-base>

<ng-template #generalView>

  <ng-container *ngIf="!sourceVersionNotFound">
    <h3>This variable could not be syncronized</h3>
    <ol class="extra-margin-top">
      <li>Try to update the variable from its source</li>
      <li>If the problem persists, please contact customer support</li>
    </ol>
  </ng-container>
  <ng-container *ngIf="sourceVersionNotFound">
    <ng-container *ngIf="parentRemoved">
      <h3>Parent forecast has been removed, or you do not have access to it. Either:</h3>
      <ol class="extra-margin-top">
        <li>Change variable version using the variable modal <span class="underline pointer"
                (click)="openFVarModal()">(click here)</span></li>
        <li>Remove the variable and import a variable that you have access to</li>
      </ol>
    </ng-container>
    <ng-container *ngIf="!parentRemoved">
      <h3>Parent forecast needs to be updated with new data</h3>
      <ol class="extra-margin-top">
        <li>Navigate to parent forecast ({{ parentForecast.Name }})</li>
        <li>Update data for main variable</li>
        <li>Calculate new model results</li>
        <li>Generate and save a new result on summary</li>
        <li>Change version for this variable to the newly saved result</li>
      </ol>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #buttonRow>
  <div class="row margin-top">
    <div class="col-xs-6"
         [class]="{'pull-right': parentRemoved}">
      <button class="btn"
              [class]="parentRemoved ? 'right' : 'left outline blue'"
              type="button"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6"
         *ngIf="!parentRemoved">
      <button class="btn right"
              (click)="navigateToForecast()"
              type="button">
        Navigate to parent forecast
      </button>
    </div>
  </div>
</ng-template>
