<div class="modal-backdrop"
     data-test-id="modal-backdrop"
     (click)="backdropClose()"></div>
<div class="modal"
     [indicioDraggable]
     dragHandle="#indicio-modal-base-title"
     dragTarget="#indicio-modal-base-container"
     id="indicio-modal-base-container"
     data-test-id="modal-base-container"
     [ngClass]="mClass">
  <div data-test-id='modal-title-container'
       class="modal-header crop-string unselectable"
       title="{{ modalTitle }}"
       id="indicio-modal-base-title"
       #modalBaseTitleBar>
    {{ modalTitle }}
    <i data-test-id='close-modal-btn'
       class="icon-cross right close-modal"
       *ngIf="!hideCloseButton"
       (click)="backdropClose()"></i>
  </div>

  <indicio-status [modal]="true"></indicio-status>

  <div class="body unselectable"
       [ngClass]="bodyClass">
    <div [ngClass]="cClass">
      <div *ngIf="_loading"
           class="modal-loading">
        <i class="ion-load-c spinner"></i>
        <div>Loading content</div>
      </div>
      <ng-content *ngIf="!_loading"></ng-content>
    </div>
  </div>
</div>
