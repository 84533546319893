import { Transformations } from '@core/types/transformation.type';
import { ALGTypes } from '@shared/components/line-graph/alg-types';
import { DisplayValue } from './display-value';

export class VariableTransformation extends DisplayValue<Transformations.Type> {

  constructor(value: Transformations.Type, display: string, desc: string) {
    super(value, display, desc);
  }

  /***
   * Convert a transformation to the corresponding ALG transformation to be used in a graph
   */
  public toAlgTransformation(): ALGTypes.Transform {
    if (this.Value === 'no_transform')
      return ALGTypes.Transform.original;
    if (this.Value === 'log')
      return ALGTypes.Transform.log;
    if (this.Value === 'diff_normal')
      return ALGTypes.Transform.diff;
    if (this.Value === 'diff_season')
      return ALGTypes.Transform.diffy;
    if (this.Value === 'growth_normal')
      return ALGTypes.Transform.roc;
    if (this.Value === 'growth_season')
      return ALGTypes.Transform.rocy;
    throw 'Invalid transformation.';
  }
}
