import { Injectable } from '@angular/core';
import { RQueueEntryDTO } from '@core/store/script-queue/dtos/r-queue.dtos';
import { QueueActions } from '@core/store/script-queue/script-queue.actions';
import { Store } from '@ngxs/store';
import { PusherActionDTO } from '../dtos/pusher-action-dto';

export type PusherRQueueAction =
  'add-new-r-queue-entries' |
  'remove-r-queue-entries' |
  'update-r-requests' |
  'failed-r-request'
  ;

@Injectable({
  providedIn: 'root'
})
export class PusherRQueueBehaviourService {

  constructor(
    private store: Store,
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {
    const parentFVersionId = dto.Str;
    switch (dto.Action) {
      case 'remove-r-queue-entries':
        this.store.dispatch(new QueueActions.RemoveEntries(dto.ForecastVersionId, dto.Obj as string[]));
        if (parentFVersionId) { this.store.dispatch(new QueueActions.RemoveEntries(parentFVersionId, dto.Obj as string[])); }
        return;

      case 'add-new-r-queue-entries':
        this.store.dispatch(new QueueActions.NewEntries(dto.ForecastVersionId, dto.Obj as RQueueEntryDTO[]));
        if (parentFVersionId) { this.store.dispatch(new QueueActions.NewEntries(parentFVersionId, dto.Obj as RQueueEntryDTO[])); }
        return;

      case 'update-r-requests':
        this.store.dispatch(new QueueActions.UpdateEntries(dto.ForecastVersionId, dto.Obj as RQueueEntryDTO[]));
        if (parentFVersionId) { this.store.dispatch(new QueueActions.UpdateEntries(parentFVersionId, dto.Obj as RQueueEntryDTO[])); }
        return;

      case 'failed-r-request':
        this.store.dispatch(new QueueActions.FailedEntry(dto.ForecastVersionId, dto.Obj as string));
        if (parentFVersionId) { this.store.dispatch(new QueueActions.FailedEntry(parentFVersionId, dto.Obj as string)); }
        return;

      default:
        console.error(`Missing behaviour for action: (${dto.Action})`);
        return;
    }
  }
}
