import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StatusModel } from '@core/services/status/status.model';
import { StatusService } from '@core/services/status/status.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
  selector: 'indicio-dialog',
  templateUrl: './indicio.dialog.html'
})
export class IndicioDialogComponent {

  public status: StatusModel = new StatusModel();
  public show = false;
  private subscription: Subscription = null;

  constructor(
    dialogRef: MatDialogRef<IndicioDialogComponent>,
    public service: StatusService,
    private ref: ChangeDetectorRef
  ) {
    this.subscribe();
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => this.unsubscribe());
  }

  public errorHasLink(str: string) {
    if (str.includes('<a') && str.includes('</a>')) {
      return true;
    }
    return false;
  }

  private subscribe() {
    this.subscription = this.service.status$.subscribe(value => {
      this.status = value;
      if (value.message !== undefined && value.showInModal) {
        this.show = true;
      } else {
        this.show = false;
      }
      this.ref.detectChanges();
    });
  }

  private unsubscribe() {
    this.subscription.unsubscribe();
  }

}
