
import { Injectable } from '@angular/core';
import { AuthActions, ClearStateAction } from '@core/store/auth/auth.actions';
import { ClientActions, GetClientSuccessAction, GetRemoteDataSourceSuccessAction } from '@core/store/client/client.actions';
import { CompanyState } from '@core/store/company/company.state';
import { AcceptToSSuccessAction, GetToSSuccessAction } from '@core/store/tos/tos.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CompanyActions } from '../company/company.actions';
import { CompanyModel } from '../company/company.model';
import { ProfileActions } from '../profile/profile.actions';
import { TermsOfServiceDTO } from '../tos/tos.dtos';
import { ClientModel } from './client.model';
import { ProfileModel } from './profile.model';

class ClientStateModel {
  Client: ClientModel;
  ToS: TermsOfServiceDTO[];
  Profile: ProfileModel;
  IsAdmin: boolean;
}
@State<ClientStateModel>({
  name: 'client',
  defaults: {
    Client: null,
    Profile: null,
    ToS: [],
    IsAdmin: false
  }
})
@Injectable()
export class ClientState {

  constructor() { }

  @Selector()
  static acceptedTosId(state: ClientStateModel) { return state.Client.TermsOfServiceAgreementId; }

  @Selector()
  static hasAcceptedTos(state: ClientStateModel) { return state.Client.TermsOfServiceAgreementId !== null; }

  @Selector()
  static tosAgreements(state: ClientStateModel) { return state.ToS; }

  @Selector()
  static client(state: ClientStateModel) { return state.Client; }

  @Selector()
  static activeCompanyId(state: ClientStateModel) { return state.Client.ActiveCompanyId; }

  @Selector()
  static profile(state: ClientStateModel) { return state.Profile; }

  @Selector()
  static isAdmin(state: ClientStateModel) { return state.IsAdmin; }

  @Selector([CompanyState.companies])
  static activeCompany(state: ClientStateModel, companies: CompanyModel[]): CompanyModel {
    return companies.find(company => company.CompanyId === state.Client.ActiveCompanyId);
  }

  @Action(ClearStateAction)
  clearClient(ctx: StateContext<ClientStateModel>, _action: ClearStateAction) {
    ctx.patchState({ Client: null, Profile: null, ToS: [] });
  }

  @Action(ProfileActions.GetProfileSuccessAction)
  getProfileSuccess(ctx: StateContext<ClientStateModel>, action: ProfileActions.GetProfileSuccessAction) {
    ctx.patchState({ Profile: action.profile });
  }

  @Action(GetClientSuccessAction)
  getClientSuccess(ctx: StateContext<ClientStateModel>, action: GetClientSuccessAction) {
    ctx.patchState({ Client: action.client });
  }

  @Action(CompanyActions.SetActive)
  setActiveCompany(ctx: StateContext<ClientStateModel>, action: CompanyActions.SetActive) {
    const client = ctx.getState().Client;
    client.ActiveCompanyId = action.companyId;
    ctx.patchState({ Client: client });
  }

  @Action(GetRemoteDataSourceSuccessAction)
  getRemoteDataSourceSuccess(ctx: StateContext<ClientStateModel>, action: GetRemoteDataSourceSuccessAction) {
    const client = ctx.getState().Client;
    client.RemoteDataSources.addOrUpdate(action.dataSource);
    ctx.patchState({ Client: client });
  }

  @Action(AcceptToSSuccessAction)
  acceptToSSuccessAction(ctx: StateContext<ClientStateModel>, action: AcceptToSSuccessAction) {
    const client = ctx.getState().Client;
    client.TermsOfServiceAgreementId = action.tosId;
    ctx.patchState({ Client: client });
  }

  @Action(GetToSSuccessAction)
  getToSSuccessAction(ctx: StateContext<ClientStateModel>, action: GetToSSuccessAction) {
    const terms = ctx.getState().ToS;
    terms.addOrUpdateBy(action.tos, 'TermsOfServiceAgreementId');
    ctx.patchState({ ToS: terms });
  }

  @Action(ClientActions.GetRemoteSources)
  getRemoteSources(ctx: StateContext<ClientStateModel>, action: ClientActions.GetRemoteSources) {
    const client = ctx.getState().Client;
    client.RemoteDataSources = [...action.sources];
    client.RemoteDataSourceIds = client.RemoteDataSources.map(x => x.RemoteDataSourceId);
    ctx.patchState({ Client: client });
  }

  @Action(AuthActions.StoreToken)
  storeToken(ctx: StateContext<ClientStateModel>, action: AuthActions.StoreToken) {
    ctx.patchState({ IsAdmin: action.token.sys_admin });
  }

}

