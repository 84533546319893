import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'secMinHrFromMs' })
export class SecMinHrFromMsPipe implements PipeTransform {

  transform(ms: number | string): string {
    return DateUtils.getSecMinHrFromMs(+ms);
  }
}
