import { HistoricBaseEventModel } from '@core/store/historic-event/models/base/historic-event.model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { DeleteHEventModalOptions } from './delete-multiple-events-modal.options';

export class OpenDeleteHistoricEventsModalComponent {
  public static readonly type = '[MODAL] Open delete historic events modal';
  data: DeleteHEventModalOptions;
  constructor(
    events: HistoricBaseEventModel[],
    forecastVersion: ForecastVersionModel,
    callback?: any
  ) {
    this.data = { events, forecastVersion, callback };
  }
}
