import { DisplayValue } from '@modules/lang/types/display-value';

export type NavigationDrawerType = 'forecasts' | 'reports';
export type NavigationForecastListViewType = 'project-list' | 'flat-list' | 'tag-list' | 'hierarchy-list';
export type NavigationPageType = 'admin' | 'automation';

export const NavigationListViews: DisplayValue<NavigationForecastListViewType>[] = [
  new DisplayValue('project-list', 'Project list', 'Grouped by project'),
  new DisplayValue('flat-list', 'Flat list', 'All forecasts in one list'),
  new DisplayValue('tag-list', 'Tag list', 'Grouped by tag'),
  new DisplayValue('hierarchy-list', 'Hierarchy list', 'Hierachies in a list'),
];
