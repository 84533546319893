import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { CSVConstants } from '@core/constants/csv.constants';
import { DataProvider } from '@core/constants/provider-definitions';
import { ThemeOptionType, ThemeOptions } from '@core/constants/theme-options';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { RegionService } from '@core/services/frontend/region.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ClientSettingsDTO } from '@core/store/client/dtos/client-settings.dto';
import { ProfileModel } from '@core/store/client/profile.model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { FredBackendService } from '@core/store/providers/fred/fred.backend.service';
import { MacrobondService } from '@core/store/providers/macrobond/macrobond.service';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { OxfordService } from '@core/store/providers/oxford/oxford.service';
import { ProviderFrontendService } from '@core/store/providers/provider.frontend.service';
import { QuandlService } from '@core/store/providers/quandl/quandl.service';
import { LanguageService } from '@modules/lang/language.service';
import { Country } from '@modules/lang/types/country';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DateUtils } from '@shared/utils/date.utils';
import { StateUtils } from '@shared/utils/state.utils';
import { OpenModifyAPIKeyModal } from '../providers/modify-api-key/modify-api-key.actions';
import { OpenModifyAPILoginModal } from '../providers/modify-api-login/modify-api-login.actions';
import { OpenRefinitivModal } from '../providers/refinitiv/refinitiv-modal.actions';
import { OpenAddSharepointProviderModal } from '../providers/sharepoint/sharepoint-modal.actions';
import { ProfileModalOpts, ProfileModalTab } from './profile-modal.options';

@Component({
  selector: 'indicio-profile-modal',
  templateUrl: './profile-modal.component.html'
})
export class ProfileModalComponent extends ModalModelComponent {

  view: ProfileModalTab = 'General';
  currentTime = DateUtils.newDate();

  modalState = new StateUtils.StateHelper();

  DateFormatOptions: any = [];
  TimeFormatOptions: any = [];
  ThemeOptions = ThemeOptions;

  profile: ProfileModel;
  profileBackup: ProfileModel;
  appearanceBackup: ClientSettingsDTO;
  country: Country;
  currentTheme: ThemeOptionType = this.appearance.Theme;

  decimalSettings: string[];
  decimalCharacter: string;

  public get isProfileChanged() {
    return this.modalState.isChanged('profile', this.profile) ||
      this.country.Display !== this.profileBackup.Country.Display ||
      this.decimalCharacter !== this.appearanceBackup.DecimalCharacter;
  }
  public get isAppearanceChanged() { return this.modalState.isChanged('appearance', this.appearance.settings); }
  public get dataSources() { return this.providerService.availableDataSources.filter(x => x.editable); }

  constructor(
    protected store: Store,
    public appearance: AppearanceService,
    private status: StatusService,
    private profileSerice: ProfileFrontendService,
    public environment: EnvironmentService,
    private providerService: ProviderFrontendService,
    private fredService: FredBackendService,
    private quandlService: QuandlService,
    private macrobondService: MacrobondService,
    private oxfordService: OxfordService,
    private langService: LanguageService,
    public clientService: ClientFrontendService,
    public auth: AuthFrontendService,
    public region: RegionService,
    private forecastService: ForecastFrontendService,
    private dialogService: DialogService
  ) {
    super();
    this.profile = profileSerice.profile;
    this.DateFormatOptions = region.GetDateFormats();
    this.TimeFormatOptions = region.GetTimeFormats();
    this.country = this.environment.getCountry(this.profile.CountryCode);
    this.decimalSettings = Object.keys(CSVConstants.DecimalSettings);
    this.decimalCharacter = this.appearance.DecimalCharacter;

    this.profileBackup = Object.assign(new ProfileModel, this.profile);
    this.appearanceBackup = Object.assign(new ClientSettingsDTO, this.appearance.settings);

    this.modalState.setState('profile', this.profileBackup);
    this.modalState.setState('appearance', this.appearanceBackup);

    this.providerService.getRemoteSources();

    if (this.currentTheme === 'default') {
      this.currentTheme = 'auto';
    }

    this.onClose = () => {
      if (this.isProfileChanged) {
        this.profile.FirstName = this.profileBackup.FirstName;
        this.profile.LastName = this.profileBackup.LastName;
        this.profile.Country = this.profileBackup.Country;
      }
      if (this.isAppearanceChanged) {
        this.appearance.settings.AdvancedUI = this.appearanceBackup.AdvancedUI;
        this.appearance.settings.Theme = this.appearanceBackup.Theme;
        this.appearance.settings.DateFormat = this.appearanceBackup.DateFormat;
        this.appearance.settings.DecimalCharacter = this.appearanceBackup.DecimalCharacter;
        this.appearance.settings.FullFormat = this.appearanceBackup.FullFormat;
        this.appearance.settings.FullFormatNoSeconds = this.appearanceBackup.FullFormatNoSeconds;
        this.appearance.settings.TimeFormat = this.appearanceBackup.TimeFormat;
        this.appearance.settings.TimeFormatNoSeconds = this.appearanceBackup.TimeFormatNoSeconds;

        this.appearance.setTheme(this.appearance.settings.Theme);
      }
    };
  }

  public setOptions(opts: ProfileModalOpts) {
    this.view = opts.tab;
  }

  public changeView(newView) {
    this.view = newView;
  }

  public toggleRememberActiveCompany() {
    this.appearance.settings.RememberActiveCompany = !this.appearance.settings.RememberActiveCompany;
    this.appearance.updateAppearanceSetting()
      .then(() => {
        this.status.setMessage('Remember active company ' + (this.appearance.settings.RememberActiveCompany ? 'enabled' : 'disabled'), 'Success', true);
      })
      .catch(err => this.status.setError(err, true));
  }

  update() {
    this.pending = true;
    if (this.country) {
      this.profile.CountryCode = this.country.Value;
    } else {
      this.status.setMessage('Not a valid country.', 'Error', true);
      return;
    }

    if (this.decimalCharacter !== this.appearance.DecimalCharacter) {
      this.appearance.settings.DecimalCharacter = <CSVConstants.DecimalSettings> this.decimalCharacter;
      this.saveAppearance();
    }

    this.profileSerice.updateProfile(this.profile)
      .then(() => {
        this.status.setMessage('Profile saved', 'Success', false);
        this.pending = false;
        this.modalState.removeState('profile');
        this.close();
      })
      .catch(error => {
        this.status.setError(error, true);
        this.pending = false;
      });
  }

  changeTheme($event: MatSelectChange) {
    this.appearance.setTheme($event.value, false);
  }

  saveAppearance() {
    this.pending = true;
    this.appearance.updateAppearanceSetting()
      .then(() => {
        this.status.setMessage('Appearance settings saved', 'Success', false);
        this.pending = false;
        this.modalState.removeState('appearance');
        this.close();
      })
      .catch(error => {
        this.status.setError(error, true);
        this.pending = false;
      });
  }


  public editDataSource(source: RemoteDataSourceModel) {
    this.close();
    switch (source.Provider) {
      case DataProvider.fred:
        this.store.dispatch(new OpenModifyAPIKeyModal(
          this.environment.getSourceType(DataProvider.fred),
          this.langService.sourceTypes.find(x => x.Value === 'fred').Extra,
          source,
          null,
          null,
          new RegExp('.{5,}'),
          (key) => this.fredService.testApiKey(key)
        ));
        break;

      case DataProvider.quandl:
        this.store.dispatch(new OpenModifyAPIKeyModal(
          this.environment.getSourceType(DataProvider.quandl),
          this.langService.sourceTypes.find(x => x.Value === 'quandl').Extra,
          source,
          null,
          null,
          new RegExp('.{5,}'),
          key => this.quandlService.testApiKey(key)
        ));
        break;

      case DataProvider.alphavantage:
        this.store.dispatch(new OpenModifyAPIKeyModal(
          this.environment.getSourceType(DataProvider.alphavantage),
          this.langService.sourceTypes.find(x => x.Value === 'alphavantage').Extra,
          source,
          null,
          null,
          new RegExp('.{5,}')));
        break;

      case DataProvider.refinitiv:
        this.store.dispatch(new OpenRefinitivModal(null, true));
        break;

      case DataProvider.macrobondapi:
        this.dialogService.openManageOpenIdIntegrationDialog({
          Source: source,
          CallbackUrl: this.macrobondService.DISCONNECT_URL
        });
        break;

      case DataProvider.sharepoint:
        this.store.dispatch(new OpenAddSharepointProviderModal(this.forecastService.activeForecastId, source));
        break;

      case DataProvider.oxford:
        this.store.dispatch(new OpenModifyAPILoginModal(
          this.environment.getSourceType(DataProvider.oxford),
          this.langService.sourceTypes.find(x => x.Value === 'oxford').Extra,
          source,
          null,
          null,
          null,
          (u, p) => this.oxfordService.testLoginDetails(u, p),
          { testKeyFuncReturnsApiKey: true }));
        break;

      default:
        console.error('Unknown provider: ', source.Provider);

    }
  }
}


