import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { ALGLineModel, ALGSingleSeriesModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgChartStyles, AlgOptions } from '@shared/components/line-graph/alg.options';

export interface GenericALGDialogData {

  // Dialog data
  Title: string;
  Subtitle?: string;
  TableInfo?: { Title: string, Value: string; }[];

  // Alg data
  Periodicity: PeriodicityType;
  Lines: ALGLineModel[];
  HistoricLine: ALGLineModel;
  Models: ALGSingleSeriesModel[];
  Options: AlgOptions.Options;
}

@Component({
  selector: 'indicio-generic-alg-dialog',
  styleUrls: ['./generic-alg.dialog.less'],
  templateUrl: 'generic-alg.dialog.html',
})
export class GenericALGDialogComponent {
  public static Id: string = 'GenericALGDialogComponent';

  chartStyles: AlgChartStyles = { borderSize: 4, borderRadius: 4, historyLine: '#6388D0', colorScheme: 'dark' };

  constructor(
    public dialogRef: MatDialogRef<GenericALGDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericALGDialogData) {
    data.Options.closeCallback = () => this.dialogRef.close(null);
  }


  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(true);
  }
}
