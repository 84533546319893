import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { DateUtils } from '@shared/utils/date.utils';
import { ModelUtils } from '@shared/utils/forecast/model.utils';
import { ForecastResultDTO, ForecastResultModelDTO } from './entities/forecast-result-dto';
import { ForecastResultModel, ForecastResultModelModel } from './entities/forecast-result.model';
import { NowcastResultMetaDTO } from './entities/nowcast-result-meta-dto';

@Injectable({
  providedIn: 'root'
})
export class ForecastResultMapper {

  constructor(
    private envService: EnvironmentService
  ) {
  }

  public mapNowcastResult(dto: NowcastResultMetaDTO, model?: NowcastResultMetaDTO) {
    model = Object.faMapTo(model || new NowcastResultMetaDTO(), dto);
    return model;
  }

  public mapNowcastResultDTO(model: NowcastResultMetaDTO) {
    model = Object.faMapTo(new NowcastResultMetaDTO, model);
    return model;
  }

  public mapForecastResultModel(dto: ForecastResultDTO) {
    const model = Object.faMapTo(new ForecastResultModel(), dto);
    model.Values.forEach(x => DateUtils.MapIHasDate(x));
    model.ShapModels.forEach(x => x.Values.forEach(v => DateUtils.MapIHasDate(v)));
    model.FittedValues.forEach(x => DateUtils.MapIHasDate(x));
    model.Residuals.forEach(x => DateUtils.MapIHasDate(x));
    model.Models = dto.Models.map(x => this.mapForecastResultModelModel(x));
    model.periodicity = this.envService.getPeriodicity(dto.Periodicity);
    model.CreatedDate = DateUtils.fromUtc(dto.CreatedDate);
    model.color = ModelUtils.getForecastResultColorForIndex(dto.ColorIndex);

    if (dto.FromReconciliation) {
      model.shortname = dto.Name.split('From hierarchy: ')[1];
    }

    return model;
  }

  private mapForecastResultModelModel(dto: ForecastResultModelDTO) {
    return Object.faMapTo(new ForecastResultModelModel(), dto);
  }
}
