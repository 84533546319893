import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';

@Injectable({
  providedIn: 'root'
})
export class SysAdminGuard  {

  constructor(
    private authService: AuthFrontendService
  ) { }

  public canLoad(_route: Route) {
    if (!this.authService.isAdmin) {
      return false;
    }
    return true;
  }
}
