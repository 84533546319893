<indicio-modal-base [child]="this"
                    [modalTitle]="modalTitle"
                    [loading]=isLoading
                    modalClass="auto-task-modal-opts"
                    bodyExtraClass="overflow-auto">
  <ng-container *ngIf="!isLoading">
    <indicio-modal-option-row optionTitle="Task results"
                              optionSubtitle="Select a result to inspect"
                              textWidth="8"
                              optionsWidth="4">
      <mat-form-field style="margin-top: 15px;">
        <mat-select [(value)]="selectedEntryId">
          <mat-option [value]="p.AutoTaskRunResultId"
                      *ngFor="let p of task.History | orderByDate: '-CompletedAt'">{{ (p.CompletedAt || p.StartedAt) |
            dateFormat: null :
            appearance.FullFormatNoSeconds }}
            <ng-container *ngIf="!!p.CompletedAt">
              <i *ngIf="p.ExitSuccess === false"
                 title="Failed"
                 class="ion-close red"></i>
              <i *ngIf="p.ExitSuccess === true"
                 title="Completed"
                 class="ion-checkmark green"></i>
            </ng-container>
            <ng-container *ngIf="!!!p.CompletedAt">
              <span title="Running task"><i class="ion-load-c spinner"></i></span>
            </ng-container>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>

    <ng-container *ngTemplateOutlet="historyTemplate"></ng-container>

  </ng-container>


  <ng-container *ngTemplateOutlet="buttons"></ng-container>
</indicio-modal-base>

<ng-template #historyTemplate>
  <div class="task-details">
    <div>
      <span class="bold">Started at:</span> {{ selectedEntry?.StartedAt | dateFormat: null :
      appearance.FullFormat }}&nbsp;
      <span *ngIf="!!!selectedEntry.CompletedAt"
            title="Running task"><i class="ion-load-c spinner"></i></span>
    </div>
    <div class="center-text">
      <ng-container *ngIf="!!selectedEntry.CompletedAt">
        <span class="bold">Completed at:</span> {{ selectedEntry?.CompletedAt | dateFormat: null :
        appearance.FullFormat }} <i *ngIf="selectedEntry.ExitSuccess === false"
           title="Failed"
           class="ion-close red"></i>
        <i *ngIf="selectedEntry.ExitSuccess === true"
           title="Completed"
           class="ion-checkmark green"></i>
      </ng-container>
    </div>
    <div class="text-right">
      <span class="bold">Elapsed time:</span> {{ elapsedTime }}
    </div>

  </div>
  <div class="task-history">
    <ul class="history-dates">
      <li class="cursor-pointer"
          [class]="{'active': selectedStep?.Step === step.Step }"
          *ngFor="let step of selectedEntry.StepResults"
          (click)="selectedStepNumber = step.Step">
        Step {{ step.Step }}: {{ step.type.Display }}
      </li>
    </ul>
    <div class="history-entry">
      <ul>
        <li *ngFor="let message of selectedStep?.Messages"
            [innerHtml]="message | safeHtml"></li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="row">
    <div class="col-xs-6">
    </div>
    <div class="col-xs-6">
      <button class="btn pull-right"
              (click)="close()">
        <span>Close</span>
      </button>
    </div>
  </div>
</ng-template>
