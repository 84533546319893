import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { CreateCompanyDTO } from '@core/store/company/dtos/create-company-dto';
import { DateUtils } from '@shared/utils/date.utils';

@Component({
  selector: 'indicio-create-company-options-tab',
  templateUrl: './create-company-tab.options.component.html',
  styleUrls: ['./create-company-tab.options.component.less'],
})
export class CreateCompanyTabOptionsComponent implements OnChanges {

  @Input() company: CreateCompanyDTO;

  public minDate: Date = DateUtils.newMoment().add(2, 'days').toDate();
  public trialExpirationDate: Date;

  constructor(
    public envService: EnvironmentService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.company) { return; }
    if (this.company.Trial && this.company.TrialExpirationDate) {
      this.trialExpirationDate = new Date(this.company.TrialExpirationDate);
    }
  }

  public toggleTrial() {
    this.company.Trial = !this.company.Trial;
    if (!this.company.Trial) {
      this.company.TrialExpirationDate = null;
    } else {
      this.company.TrialExpirationDate = this.trialExpirationDate.toISOString();
    }
  }

  public setDate(date: moment.Moment) {
    this.company.TrialExpirationDate = date.toISOString();
  }
}
