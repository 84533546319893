import { Component } from '@angular/core';

import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { InfoModalOpts } from './information-modal.options';

@Component({
  selector: 'indicio-information-modal',
  templateUrl: 'information-modal.component.html'
})
export class InfoModalComponent extends ModalModelComponent {
  title = '';
  mainMessage = '';
  subMessage = undefined;
  tableInfo = [];
  confirm = 'Confirm';
  negativeEffect = false;
  textRight = false;
  leftBtnTitle = '';

  constructor(
    protected store: Store,
  ) { super(); }

  public isObj(obj: any) {
    return typeof obj === 'object';
  }

  public onConfirm() {
    this.close();
  }

  public leftBtnFn() {

  }

  public setOptions(options: InfoModalOpts) {
    this.title = options.title;
    this.mainMessage = options.mainMessage;
    this.confirm = options.confirm;
    this.tableInfo = options.tableInfo;
    this.textRight = options.textRight;
    this.subMessage = options.subMessage;
    if (options.leftBtn) {
      this.leftBtnFn = options.leftBtn.fn;
      this.leftBtnTitle = options.leftBtn.title;
    } else {
      this.leftBtnFn = null;
    }

    this.isLoading = false;
  }
}


