import { Component, ViewEncapsulation } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ImportedHistoricBaseEventModel, ImportedHistoricEventCorrelationModel, ImportedHistoricEventModel } from '@core/store/historic-event/models/imported/imported-historic-base-event.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateUtils } from '@shared/utils/date.utils';
import { HistoricEventsCorrelationModalOptions } from './event-correlation-modal.options';


@Component({
  selector: 'indicio-event-correlation-modal',
  templateUrl: './event-correlation-modal.component.html',
  styleUrls: ['./event-correlation-modal.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HistoricEventsCorrelationModalComponent extends ModalModelComponent {

  public opts: HistoricEventsCorrelationModalOptions;
  public fVersion: ForecastVersionModel;
  public baseEvent: ImportedHistoricBaseEventModel;
  public futureEvent: ImportedHistoricEventModel;
  public onBack;

  availableCorrelations: ImportedHistoricEventCorrelationModel[] = [];
  backupCorrelation: ImportedHistoricEventCorrelationModel[] = [];

  public action = 'create';

  public isLoading = true;
  public isLagged: boolean;

  public get getTotalWeight() {
    return this.futureEvent.Correlations.reduce((pv, cv) => {
      return Math.round(pv + cv.Strength);
    }, 0);
  }

  constructor(
    protected store: Store,
    private forecastService: ForecastFrontendService,
    public envService: EnvironmentService,
    public clientService: ClientFrontendService,
    public appearance: AppearanceService
  ) { super(); }

  public setOptions(options: HistoricEventsCorrelationModalOptions) {
    this.opts = options;
    this.futureEvent = options.event;
    this.baseEvent = options.baseEvent;
    this.onBack = options.onBack;

    const fVersPromise = this.forecastService.getOrFetchForecastVersion(options.forecastVersionId);

    Promise.all([fVersPromise]).then(([fv]) => {
      this.fVersion = fv;
      this.isLagged = this.futureEvent.Date.isSame(DateUtils.newMoment(this.fVersion.DataUntilDateRequirement), <any> this.fVersion.Periodicity) && this.futureEvent.EffectType === 'lagged-spike';

      this.backupCorrelation = this.futureEvent.Correlations.slice();
      this.setSavedState(this.backupCorrelation);

      if (this.getPastEvents()?.length) {
        this.availableCorrelations = this.getPastEvents().filter(x => !this.availableCorrelations.find(z => x.ImportedHistoricEventId !== z.PastHistoricEventId)).map(x => {
          const newC = new ImportedHistoricEventCorrelationModel;
          newC.FutureHistoricEventId = this.futureEvent.ImportedHistoricEventId;
          newC.PastHistoricEventId = x.ImportedHistoricEventId;
          newC.Strength = 0;
          return newC;
        });
      } else {
      }
    })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public get isChanged() {
    return this.modalState.isChanged('correlations', this.futureEvent.Correlations);
  }

  getPastEvents() {
    const fv = this.fVersion;
    return this.baseEvent.getPastEventsForEvent(this.futureEvent, fv.StartDate, fv.DataUntilDateRequirement, fv.Periodicity);
  }

  addCorrelation(correlation: ImportedHistoricEventCorrelationModel) {
    if (this.futureEvent.getCorrelationForId(correlation.PastHistoricEventId)) {
      this.futureEvent.Correlations.splice(this.futureEvent.Correlations.findIndex(x => x.PastHistoricEventId === correlation.PastHistoricEventId), 1);
    } else {
      this.futureEvent.Correlations.push(correlation);
    }

    const total = this.futureEvent.Correlations?.length;
    const strength = Math.round(100 / total);

    this.futureEvent.Correlations.forEach(pe => {
      pe.Strength = strength;
    });
  }

  public getEventFromId(id: string) {
    return this.baseEvent.Events.find(x => x.ImportedHistoricEventId === id);
  }

  public save() {
    this.close();
    if (this.onBack) {
      this.onBack();
    }
  }

  public closeCorrelations() {
    this.close();
    this.futureEvent.Correlations = this.backupCorrelation;
    if (this.onBack) {
      this.onBack();
    }
  }

  private setSavedState(corres: ImportedHistoricEventCorrelationModel[]) {
    this.modalState.setState('correlations', corres);
  }

}
