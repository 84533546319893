<indicio-modal-base [child]="this"
                    modalTitle="Remove Sharepoint"
                    [loading]="isLoading"
                    bodyExtraClass="overflow-auto">
  Are you sure you want to remove your connection to Sharepoint?

  <ng-container *ngIf="files.length">
    <ng-container *ngTemplateOutlet="existingFilesTemplate"></ng-container>
  </ng-container>

  <div class="row">
    <div class="col-xs-12 center-text">
      <button class="btn full-width"
              data-test-id="file-confirm-removal"
              [indicioTippy]="!filesAcceptedToBeRemoved().length ? 'Variables exists in forecasts and cannot be removed.' : null"
              (click)="gonefishing ? close() : removeFiles()"
              [ngClass]="{ disabled: pending || filesNotAcceptedToBeRemoved().length }">
        <i *ngIf="pending"
           class="ion-load-c spinner"></i>
        <span *ngIf="!pending">
          {{ gonefishing ? 'Done' : 'Remove sharepoint' }}
        </span>
      </button>
      <button *ngIf="filesNotAcceptedToBeRemoved().length"
              class="btn full-width red"
              style="margin-top: -3px;"
              indicioTippy="This action will delete all files and everything thats derived from them."
              data-test-id="file-confirm-removal"
              (click)="forceRemoveFiles()"
              [ngClass]="{ disabled: pending }">
        <i *ngIf="pending"
           class="ion-load-c spinner"></i>
        <span *ngIf="!pending">
          Force remove
        </span>
      </button>
    </div>
  </div>
</indicio-modal-base>

<ng-template #existingFilesTemplate>
  <ng-container *ngIf="!filesNotAcceptedToBeRemoved()">
    <p *ngIf="files.length > 1">Are you sure you want to remove these files?</p>
    <p *ngIf="files.length === 1">Are you sure you want to remove this file?</p>
  </ng-container>

  <ng-container *ngIf="filesNotAcceptedToBeRemoved()">
    <p>One or more files are in use, remove dependant things in the action column to the right.</p>
  </ng-container>

  <p>You need to remove all files connected to your sharepoint.</p>
  <div class="remove-table">
    <div class="row header">
      <div class="col-xs-8">File name</div>
      <div class="col-xs-2 center-text">Status</div>
      <div class="col-xs-2 center-text">Action</div>
    </div>
    <div style="max-height: 301px; overflow: auto;"
         #fileScroll>
      <div class="content"
           *ngFor="let file of files">
        <div class="row margin-bottom">
          <div class="col-xs-8 crop-string"
               [scrollContainer]="fileScroll"
               [indicioTippy]="file.FileName">
            {{ file.FileName }}
          </div>
          <div class="col-xs-2 center-text"
               *ngIf="!gonefishing"
               [scrollContainer]="fileScroll"
               [indicioTippy]="isUsed(file) ? 'This file is in use and cannot be removed' : 'This file can be removed'">
            <ng-container *ngIf="!isUsed(file)">
              <i class="ion-checkmark-circled green"></i>
            </ng-container>
            <ng-container *ngIf="isUsed(file)">
              <i class="ion-close-circled red"></i>
            </ng-container>
          </div>

          <div class="col-xs-2 center-text"
               *ngIf="gonefishing">
            <ng-container *ngIf="file.removedStatus === 'Pending'">
              pending
            </ng-container>
            <ng-container *ngIf="file.removedStatus === 'Removing'">
              <i class="ion-load-c spinner"></i>
            </ng-container>
            <ng-container *ngIf="file.removedStatus === 'Success'">
              removed
            </ng-container>
            <ng-container *ngIf="file.removedStatus === 'Failed'">
              <span class="red"
                    [indicioTippy]="file.errorStatus">failed</span>
            </ng-container>
          </div>
        </div>
        <div *ngFor="let source of usedIn(file)?.SourceVariables">
          <div class="row pointer">
            <div [indicioTippy]="source.SourceVariableName"
                 [scrollContainer]="fileScroll"
                 style="padding-left: 20px;"
                 class="col-xs-10 crop-string">
              Source variable: {{ source.SourceVariableName }}
            </div>
            <div class="col-xs-1 col-no-padding text-right">
              <i [ngClass]="{disabled: opts.forecastVersionId == null}"
                 class="ion-android-exit"
                 [scrollContainer]="fileScroll"
                 [indicioTippy]="opts.forecastVersionId != null ? 'Open source variable' : 'Cannot open source variable'"
                 (click)="openSourceVariableModal(source)"></i>
            </div>
            <div class="col-xs-1 col-no-padding">
              <i class="ion-trash-a"
                 style="margin-left: 5px;"
                 [ngClass]="!source.Usage ? '' : 'disabled'"
                 [scrollContainer]="fileScroll"
                 [indicioTippy]="!source.Usage ? 'Delete variable' : 'This variable is used in one or more forecasts. Delete them first, see below'"
                 (click)="deleteSourceVariable(source)"></i>
            </div>
          </div>
          <div class="row"
               *ngFor="let usage of source.Usage">
            <div [indicioTippy]="'Project: ' + usage.ProjectName + ' - Forecast: ' + usage.ForecastName"
                 [scrollContainer]="fileScroll"
                 class="col-xs-10 crop-string">
              <span style="padding-left: 20px;">
                Project: {{ usage.ProjectName }}, Forecast: {{ usage.ForecastName }} (Version {{ usage.ForecastVersion
                }})
              </span>
            </div>
            <div class="col-xs-1 col-no-padding text-right">
              <i class="ion-android-exit pointer"
                 [scrollContainer]="fileScroll"
                 indicioTippy="Navigate to forecast"
                 (click)="navigateToForecast(usage)"></i>
            </div>
            <div class="col-xs-1 col-no-padding">
              <i class="ion-trash-a pointer"
                 style="margin-left: 5px;"
                 [scrollContainer]="fileScroll"
                 indicioTippy="Delete forecast variable"
                 (click)="deleteForecastVariable(usage)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
