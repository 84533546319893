<indicio-dialog class="wiki-order-dialog">
  <h2 mat-dialog-title>PDF settings</h2>
  <mat-dialog-content *ngIf="initialized">
    <div class="grid-system bold"
         style="--grid-template: 1fr 150px 100px; padding: 5px 10px; margin-bottom: 10px;">
      <span>Title</span>
      <div>Include in manual</div>
      <div class="text-right">Order</div>
    </div>
    <ng-container *ngFor="let page of treeData | orderBy: 'frontendOrder'">
      <ng-container [ngTemplateOutlet]="pageTemplate"
                    [ngTemplateOutletContext]="{ page: page, parent: null, level: 0, index: 0, childrenCount: +page.children?.length }"></ng-container>
    </ng-container>
  </mat-dialog-content>
  <br>
  <br>
  <mat-dialog-actions>
    <button mat-stroked-button
            type="button"
            (click)="back()">Close</button>
    <button mat-stroked-button
            type="button"
            [disabled]="isSaving"
            (click)="save()">
      <indicio-spinner *ngIf="isSaving"></indicio-spinner>
      Save
    </button>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #pageTemplate
             let-page="page"
             let-parent="parent"
             let-level="level"
             let-childrenCount="childrenCount">
  <div style="padding-left: {{ 10 * level }}px;">
    <div class="page grid-system"
         [ngClass]="{ bold: page.Slug === lastMovedChildSlug }"
         [ngStyle]="{ 'background-color': page.Slug === lastMovedChildSlug ? 'rgba(0, 0, 0, 0.1)' : null }"
         style="--grid-template: 1fr 150px 100px; padding: 5px 10px">

      <span>{{page.Title}}</span>

      <div *ngIf="page.ParentSlug">
        <mat-slide-toggle color="accent"
                          [checked]="page.IncludePDF"
                          (change)="toggleInclude(page)">
        </mat-slide-toggle>
      </div>

      <div class="text-right">
        <ng-container *ngIf="page.ParentSlug">
          {{ page.Order + 1 }}
          &nbsp;
          <i class="ion-chevron-up pointer"
             [ngClass]="{ disabled: page.Order === 0 }"
             (click)="moveChild(page, parent, -1)"></i>
          &nbsp;
          <i class="ion-chevron-down pointer"
             [ngClass]="{ disabled: page.Order === +childrenCount - 1 }"
             (click)="moveChild(page, parent, 1)"></i>
        </ng-container>
      </div>
    </div>
    <ng-container *ngFor="let child of page.children | orderBy: 'frontendOrder'; let index = index">
      <ng-container [ngTemplateOutlet]="pageTemplate"
                    [ngTemplateOutletContext]="{ page: child, parent: child.ParentSlug, level: level + 1, childrenCount: +findParent(child).children?.length }"></ng-container>
    </ng-container>
  </div>
</ng-template>
