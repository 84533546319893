import { Injectable } from '@angular/core';
import { AceaDataSerieModel } from '@core/store/providers/acea/acea-dataserie.model';
import { AceaStateCategoryMetaModel } from '@core/store/providers/acea/acea-state-category-meta.model';
import { DateUtils } from '@shared/utils/date.utils';
import { StringUtils } from '@shared/utils/string.utils';
@Injectable({
  providedIn: 'root'
})

export class AceaMapper {

  public mapTippy(object: AceaDataSerieModel, searched: boolean, category_path: AceaStateCategoryMetaModel[]) {
    if (searched || object.SelectedBaseCategoryTitle) {
      object.Tippy = `${StringUtils.capitalizeFirstLetter(object.SelectedBaseCategoryTitle)} / `;
      if (searched && !!object.Description) {
        object.Tippy += object.Description.toLowerCase();
      } else {
        object.Tippy += object.SelectedSubCategoryTitle.toLowerCase();
      }
      if (!searched && !!object.Description) {
        object.Tippy += ` /  ${object.Description.toLowerCase()}`;
      }
    } else {
      object.Tippy = category_path.slice(1).map(exp => exp.Title).join(' / ');
    }
    return object;
  }

  public mapTitle(object: AceaDataSerieModel, searched: boolean) {
    if (object.Country === null) {
      object.title = StringUtils.capitalizeFirstLetter(object.Company) + (searched && typeof object.SelectedSubCategoryTitle !== 'undefined' ? ' / ' + object.SelectedSubCategoryTitle : '');
    } else {
      object.title = StringUtils.capitalizeFirstLetter(object.Country) + (searched && typeof object.SelectedSubCategoryTitle !== 'undefined' ? ' / ' + object.SelectedSubCategoryTitle : '');
    }
    return object;
  }

  public mapSeries(object) {
    const model = Object.faMapTo(new AceaDataSerieModel, object);
    model.Code = (model.Code || model.Target || '').toLocaleLowerCase();
    if (model.LastDate) {
      model.oldData = DateUtils.isOldData(model.LastDate);
    }
    return model;
  }

}
