import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { SeasonalSettings } from '@core/store/forecast-variable/models/saved-seasonal-settings.model';
import { MacrobondDialogsService } from '@dialogs/macrobond/macrobond-dialogs.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { SeasonalCalendar, SeasonalModelType, SeasonalStrategyType } from '@modules/lang/language-files/seasonal';
import { CheckCircleModel } from '@shared/components/checkcircle/check-circle.component';
import { MonthplotMapper } from '@shared/components/graphs/monthplot/monthplot.mapper';
import { MonthPlotOptions } from '@shared/components/graphs/monthplot/monthplot.options';
import { AlgConverter } from '@shared/components/line-graph/alg-line.utils';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgOptions } from '@shared/components/line-graph/alg.options';
import { DateFormatPipe } from '@shared/modules/pipes';
import { FVarDialogViewIndex, ForecastVariableInfoDialogData } from '../../forecast-variable-info.dialog';
import { CalendarEffects } from './fvar-calendar-settings';

@Component({
  selector: 'indicio-fvar-info-dialog-seasonal-tab',
  templateUrl: './fvar-info-tab.seasonal.component.html',
  styleUrls: ['./fvar-info-tab.seasonal.component.less']
})
export class FVarInfoDialogSeasonalTabComponent implements OnChanges {

  public faUpload = faCloudArrowUp as IconProp;

  @Input() variable: ForecastVariableModel;
  @Input() periodicity: PeriodicityType;
  @Input() options: ForecastVariableInfoDialogData;
  @Output() closeDialogEvent = new EventEmitter();

  // Frontend flags
  public saveInProgress: boolean;
  public isLoading: boolean = false;
  public lastClickedIndex: number;
  public viewEnum = FVarDialogViewIndex;

  // Settings
  public seasonalSettings: SeasonalSettings = new SeasonalSettings;

  // Available seasonal effects
  public availableSeasonalEffects: SeasonalCalendar[];

  // Results
  public seasCompPlotData: MonthPlotOptions.Options = new MonthPlotOptions.Options;
  public seasCompPlotLoading: boolean = false;
  public seasComponentCheckModel: CheckCircleModel;
  public seasHistoricCheckModel: CheckCircleModel;
  public seasIrregularCheckModel: CheckCircleModel;
  public adjustedDataModel: CheckCircleModel;
  public originalDataModel: CheckCircleModel;
  public trendDataModel: CheckCircleModel;
  public seasSliderPeriods: number[] = [];
  public seasSliderLabels: string[] = [];
  public seasLimitStart: number = 0;
  public seasLimitEnd: number = 0;
  public showSliderLabels: boolean = false;

  public algLines: ALGLineModel[] = [];
  public algHistLine: ALGLineModel;
  public graphOptions: AlgOptions.Options;
  public chartStyles = { borderSize: 2, borderRadius: 4, historyLine: '#6388D0', plotHeight: 300 };
  public component: number = 1;

  public componentOptions = [
    { Value: 1, Label: 'Day of year component' },
    { Value: 2, Label: 'Day of month component' },
    { Value: 3, Label: 'Day of week component' }
  ];

  constructor(
    private status: StatusService,
    private fVarService: ForecastVariableFrontendService,
    private cd: ChangeDetectorRef,
    public env: EnvironmentService,
    private datePipe: DateFormatPipe,
    private mbDialogsService: MacrobondDialogsService
  ) {
  }

  public ngOnChanges() {
    this.setup();
    this.cd.detectChanges();
  }

  public sliderFormatLabel(n: number): string {
    if (this.seasSliderLabels.length < n)
      return this.seasSliderLabels.last();
    return this.seasSliderLabels[n];
  }

  public openExportSeasonalResult() {
    this.closeDialogEvent.emit();
    this.mbDialogsService.openExportSaDataToMacrobond({ variable: this.variable });
  }

  public toggleSeasonal() {
    this.seasonalSettings.seasonalEnabled = !this.seasonalSettings.seasonalEnabled;
    this.seasonalSettings.checkSettingsChanged();
  }

  public setModel(modelType: SeasonalModelType) {
    this.seasonalSettings.seasonalModelType = modelType;
    if (modelType === 'x11') { this.seasonalSettings.seasonalStrategyType = 'previous'; }
    this.seasonalSettings.checkSettingsChanged();
  }

  public toggleSeasonalOutlier() {
    this.seasonalSettings.seasonalOutlier = !this.seasonalSettings.seasonalOutlier;
    this.seasonalSettings.checkSettingsChanged();
  }

  public toggleUseTrend() {
    this.seasonalSettings.seasonalExtractTrend = !this.seasonalSettings.seasonalExtractTrend;
    this.seasonalSettings.checkSettingsChanged();
  }

  public setStrat(type: SeasonalStrategyType) {
    this.seasonalSettings.seasonalStrategyType = type;
    this.seasonalSettings.checkSettingsChanged();
  }

  public selectSeasonalOutlierType(change: MatOptionSelectionChange) {
    const changed = change.source.value;
    if (change.source.selected) {
      this.seasonalSettings.seasonalOutlierTypes.addUniqueId(changed);
    } else {
      this.seasonalSettings.seasonalOutlierTypes.removeFirst(x => x === changed);
    }
    this.seasonalSettings.seasonalOutlierTypes.sort();
    this.seasonalSettings.checkSettingsChanged();
  }

  public selectSeasonalcalendarEffect(change: MatOptionSelectionChange) {
    this.seasonalSettings.calendarTypes = CalendarEffects.updateSeasonalCalendarEffects(this.seasonalSettings.calendarTypes.slice(), change);
    this.seasonalSettings.checkCalendarEffectsChanged();
  }

  public saveSettings() {
    if (!this.seasonalSettings.changed || this.saveInProgress) { return; }
    this.saveInProgress = true;
    const dto = this.seasonalSettings.getSettingsDto();

    this.fVarService.updateVariableSeasonalSettings(this.variable, dto)
      .then(res => {
        this.variable = res;
        this.setup();
        this.status.setMessage('Seasonal settings updated', 'Success', true);
      })
      .catch(error => {
        this.setup();
        this.status.setError(error, true);
      })
      .finally(() => this.saveInProgress = false);
  }

  public selectItem(item: CalendarEffects.ListItem) {
    this.lastClickedIndex = null;
    const active = !item.isAllSelected;
    item.subItems.forEach(subItem => {
      subItem.selected = active;
      if (active) { this.addSubItem(subItem); }
      else { this.removeSubItem(subItem); }
    });
    if (active) { this.addItem(item); }
    else { this.removeItem(item); }
  }

  public unfoldItem(item: CalendarEffects.ListItem, index: number) {
    item.open = !item.open;
    this.lastClickedIndex = index;
  }

  public addItem(item: CalendarEffects.ListItem) {
    const index = this.seasonalSettings.selectedItems.findIndex(x => x.item.Value === item.item.Value);
    if (index === -1) { this.seasonalSettings.selectedItems.push(item); }
    this.seasonalSettings.checkCalendarEffectsChanged();
  }

  public addSubItem(item: CalendarEffects.ListSubItem) {
    const index = this.seasonalSettings.selectedSubItems.findIndex(x => x.item.Value === item.item.Value);
    if (index === -1) { this.seasonalSettings.selectedSubItems.push(item); }
    this.seasonalSettings.checkCalendarEffectsChanged();
  }

  public removeSubItem(item: CalendarEffects.ListSubItem) {
    const index = this.seasonalSettings.selectedSubItems.findIndex(x => x.item.Value === item.item.Value);
    if (index > -1) { this.seasonalSettings.selectedSubItems.splice(index, 1); }
    this.seasonalSettings.checkCalendarEffectsChanged();
  }

  public removeItem(item: CalendarEffects.ListItem) {
    const index = this.seasonalSettings.selectedItems.findIndex(x => x.item.Value === item.item.Value);
    if (index > -1 && item.subItems.filter(p => p.selected).length === 0) {
      this.seasonalSettings.selectedItems.splice(index, 1);
    }
    this.seasonalSettings.checkCalendarEffectsChanged();
  }

  public selectSubItem(subItem: CalendarEffects.ListSubItem, clicked = false) {
    this.lastClickedIndex = null;
    const selected = !subItem.selected;

    if (subItem.isRegion && clicked) {
      const selectedRegionIdx = this.seasonalSettings.selectedSubItems.findIndex(x => x.isRegion);
      if (selectedRegionIdx > -1) {
        this.selectSubItem(this.seasonalSettings.selectedSubItems[selectedRegionIdx]);
      }
    }

    subItem.selected = selected;
    const relatedItems = this.seasonalSettings.calendarItems.filter(e => e.subItems.findIndex(p => p.item.Value === subItem.item.Value) > -1);

    if (!subItem.selected) {
      this.removeSubItem(subItem);
      this.removeItem(relatedItems[0]);
    } else {
      this.addSubItem(subItem);
      this.addItem(relatedItems[0]);
    }
  }

  /**
   * Setup the component with valid options, settings and current configuration.
   */
  public inSetup: boolean = false;
  private setup() {
    this.inSetup = true;
    this.seasonalSettings.init(this.variable);
    this.availableSeasonalEffects = CalendarEffects.FilterEffectsByPeriodicity(this.periodicity, this.env.SeasonalCalendarEffects.slice());
    this.setupCalendarHolidays();
    this.setupCalendarRegions();

    // Set initial "changed" flags
    this.seasonalSettings.checkSettingsChanged();
    this.seasonalSettings.checkCalendarEffectsChanged();
    this.isLoading = false;
    setTimeout(() => {
      if (this.variable.SeasonalFound) {
        this.setupSeasonalPlots();
        this.setupGraph();
      }
      this.inSetup = false;
    }, 0);
  }

  /**
   * This function is used to setup the available calendar effects,
   * e.g. all the different holidays tied to Christmas, Easter and others.
   */
  private setupCalendarHolidays() {
    const selected = this.variable.SeasonalCalendarHolidays.slice();
    this.seasonalSettings.calendarItems.push(...this.env.SeasonalHolidaysCategories.map(p => {
      const newItem = Object.assign(new CalendarEffects.ListItem(), <CalendarEffects.ListItem> {
        item: p,
        subItems: p.subCategories.map(e => {
          const subCat = Object.assign(new CalendarEffects.ListSubItem(), <CalendarEffects.ListSubItem> {
            item: e,
            selected: selected.includes(e.Value)
          });
          if (subCat.selected) { this.addSubItem(subCat); }
          return subCat;
        })
      });
      if (newItem.anySelected) { this.addItem(newItem); }
      return newItem;
    }));
  }

  /**
   * This function is used to setup the available working-day sets, tied to different regions.
   * E.g. the user can choose to select the working-day definition from New York stock exchange.
   */
  private setupCalendarRegions() {
    const selected = this.variable.SeasonalCalendarRegion.slice();
    const regionItem = Object.assign(new CalendarEffects.ListItem(), <CalendarEffects.ListItem> {
      item: CalendarEffects.EffectTypes.find(p => p.Value === 'working-day'),
      isRegion: true,
      subItems: this.env.SeasonalRegionsCategories.map(e => {
        const subCat = Object.assign(new CalendarEffects.ListSubItem(), <CalendarEffects.ListSubItem> {
          item: e,
          isRegion: true,
          selected: selected.includes(e.Value)
        });
        if (subCat.selected) { this.addSubItem(subCat); }
        return subCat;
      })
    });
    this.seasonalSettings.calendarItems.push(regionItem);
    if (regionItem.anySelected) { this.addItem(regionItem); }
  }

  public setupSeasonalPlots() {
    if (this.variable.ForecastVariableSeasonalValues.length === 0) return;
    const startDate = this.variable.ForecastVariableValues[0].D;
    const startYear = startDate.getFullYear();
    const endYear = this.variable.ForecastVariableSeasonalValues.last().D.getFullYear();
    for (let year = startYear; year <= endYear; year++) {
      this.seasSliderPeriods.push(year);
      this.seasSliderLabels.push(year.toString());
    }

    this.seasComponentCheckModel = new CheckCircleModel('#f99', 'Seasonal Component', true, true);
    this.seasHistoricCheckModel = new CheckCircleModel('#99f', 'Historic Data', true, true);
    this.seasIrregularCheckModel = new CheckCircleModel('#9f9', 'Irregular Component', true, false);
    this.adjustedDataModel = new CheckCircleModel('#9f9', 'Adjusted', true, true);
    this.originalDataModel = new CheckCircleModel('#f99', 'Original', true, true);
    this.trendDataModel = new CheckCircleModel('#99f', 'Trend', true, true);

    this.seasLimitEnd = this.seasSliderPeriods.length - 1;
    this.seasCompPlotData.PercentScale = this.variable.IsPercent;

    this.updateMonthPlot();
  }

  public seasSliderChanged(event: any, handle: string) {
    if (handle === 'start') {
      this.seasLimitStart = event;
    } else {
      this.seasLimitEnd = event;
    }
    this.updateMonthPlot();
  }

  public componentChanged(event: MatSelectChange) {
    this.component = event.value;
    this.updateMonthPlot();
  }

  public updateMonthPlot() {
    this.seasCompPlotLoading = true;
    this.cd.detectChanges();
    setTimeout(() => {
      const values = MonthplotMapper.mapValues(
        this.variable.ForecastVariableSeasonalValues,
        this.variable.DetectedSeasonalOutliers,
        this.periodicity,
        this.variable.SeasonalLogTrans,
        this.variable.LatestDate,
        this.seasSliderPeriods[this.seasLimitStart],
        this.seasSliderPeriods[this.seasLimitEnd],
        this.component,
        this.datePipe,
      );

      this.seasCompPlotData.Data.Labels.Buckets = values.labels;
      this.seasCompPlotData.Data.Labels.Ticks = values.tickLabels;

      this.seasCompPlotData.Data.Series = [];
      if (this.seasHistoricCheckModel.Checked) {
        this.seasCompPlotData.Data.Series.push(values.historicSeries);
      }

      if (this.seasComponentCheckModel.Checked) {
        this.seasCompPlotData.Data.Series.push(values.componentSeries);
        this.seasCompPlotData.Data.Series.push(values.componentMaxSeries);
        this.seasCompPlotData.Data.Series.push(values.componentMinSeries);
        this.seasCompPlotData.Data.Series.push(values.forecastSeries);
      }
      if (this.seasIrregularCheckModel.Checked) {
        this.seasCompPlotData.Data.Series.push(values.irregularSeries);
      }

      this.seasCompPlotData = { ...this.seasCompPlotData };
      this.seasCompPlotLoading = false;
    }, 5);
  }

  public setupGraph() {
    const originalValues: PlotValueDTO[] = this.variable.ForecastVariableValues.map(x => {
      const v = new PlotValueDTO();
      v.D = x.D;
      v.V = x.VO;
      return v;
    });

    const originalLine = AlgConverter.fromPlotValues(originalValues, 'Original value', '#f99', 'Seasonal adjustment');


    this.algLines = [];
    this.algHistLine = originalLine;
    if (!this.originalDataModel.Checked) {
      originalLine.Active = false;
    }
    if (this.adjustedDataModel.Checked) {
      const adjustedValues: PlotValueDTO[] = this.variable.ForecastVariableValues.map(x => {
        const v = new PlotValueDTO();
        v.D = x.D;
        v.V = x.VS;
        return v;
      });
      const adjustedLine = AlgConverter.fromPlotValues(adjustedValues, 'Adjusted value', '#9f9', 'Seasonal adjustment');
      this.algLines.push(adjustedLine);
    }

    if (this.trendDataModel.Checked) {
      const trendValues: PlotValueDTO[] = this.variable.ForecastVariableSeasonalValues.map(x => {
        const v = new PlotValueDTO();
        v.D = x.D;
        v.V = x.T;
        return v;
      });
      const trendLine = AlgConverter.fromPlotValues(trendValues, 'Trend component', '#99f', 'Seasonal adjustment');
      this.algLines.push(trendLine);
    }
    this.setGraphOptions();
  }

  private setGraphOptions() {
    const header = this.variable.Name;
    const startDate = this.algHistLine.Values[0]?.D;
    const endDate = this.variable.LastDate;
    let subheader = '';

    if (startDate && endDate) {
      subheader = this.datePipe.transform(startDate) + ' - ' + this.datePipe.transform(endDate);
    }

    this.graphOptions = AlgOptions.CreateOptions({
      noDataText: '',
      inModal: true,
      menuConfig: {
        showMenu: true,
        dontShowPastForecastBtn: true,
        showSeasonalAdjBtn: false,
        showOutlierAdjBtn: false,
        forceActiveAggregatedBtn: this.variable.Aggregated,
        showDatePickerBtn: true,
      },
      dontShowCIHover: true,
      axisConfig: { yAxisPosition: 'inside' },
      isPercent: this.variable.IsPercent,
      header: header,
      closeCallback: () => this.closeDialogEvent.emit(true),
      subheader: subheader,
      dates: this.algHistLine.Values.map(x => x.m),
      pointsToShow: this.algHistLine.Values.length,
      showOnlyHistoric: true,
      dontShowHistoricData: true
    });
  }
}
