import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CloseMainMenuAction, NavigateToForecast } from '@core/actions/navigation.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { ForecastTemplateAction } from '@core/store/forecast-template/forecast-template.actions';
import { ForecastTemplate, ForecastTemplateEventDTO } from '@core/store/forecast-template/forecast-template.model';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { DialogActions } from '../../dialog.actions';
import { BrowseTemplatesDialogComponent } from '../browse-templates/browse-templates.dialog';

export interface UseTemplateLiveViewDialogData {
  template: ForecastTemplate;
}

@Component({
  selector: 'indicio-use-template-live-view.dialog',
  templateUrl: 'use-template-live-view.dialog.html',
  styleUrls: ['use-template-live-view.dialog.less']
})
export class UseTemplateLiveViewDialogComponent implements OnDestroy {
  public static Id: string = 'UseTemplateLiveViewDialogComponent';

  subs: Subscription = new Subscription();

  statusDto: ForecastTemplateEventDTO = new ForecastTemplateEventDTO();

  forecastId: string = null;
  done: boolean = false;
  failed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UseTemplateLiveViewDialogComponent>,
    private actions: ActionService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: UseTemplateLiveViewDialogData
  ) {
    this.store.dispatch(new DialogActions.CloseById(BrowseTemplatesDialogComponent.Id));
    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close(null);
    this.store.dispatch(new CloseMainMenuAction());
  }

  public navToFcast() {
    this.store.dispatch(new NavigateToForecast(this.forecastId, 'overview', () => {
      this.store.dispatch(new CloseMainMenuAction());
    }));
  }

  private setupSubscriptions() {
    this.subs.add(this.actions.dispatched(ForecastTemplateAction.Error).subscribe((err: ForecastTemplateAction.Error) => {
      this.statusDto.ErrorMessages.push(err.err);
      this.failed = true;
    }));
    this.subs.add(this.actions.dispatched(ForecastTemplateAction.UseSuccess).subscribe((action: ForecastTemplateAction.UseSuccess) => {
      this.forecastId = action.dto.ForecastId;
      this.done = true;
      this.navToFcast();
    }));
    this.subs.add(this.actions.dispatched(ForecastTemplateAction.ProgressStatus).subscribe(action => {
      this.actOnProgress(action.dto);
    }));
  }

  private actOnProgress(dto: ForecastTemplateEventDTO) {
    this.statusDto = dto;
    if (dto.ProcessFailed) {
      this.failed = true;
    }
  }
}
