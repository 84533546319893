import { PastForecastDTO } from '@core/entities/dtos/past-forecast-dto';
import { StatModelVariableDTO } from '@core/entities/dtos/plot-value-dto';
import { UnivariateModelName } from '@modules/lang/language-files/stat-models';
import { ModelName } from '@modules/lang/types/model-name';
import { ALGSingleSeriesModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { ValueUtils } from '@shared/utils/value.utils';
import { ShapValueDTO } from './dtos/stat-model.dto';
import { StatModel, StatTransformationModel } from './stat-model.model';

export namespace StatModelUtils {

  export function isUniModel(name: ModelName) {
    return Object.values(UnivariateModelName).includes(name.Model as any);
  }

  export function isMultiModel(name: ModelName) {
    return !this.isUniModel(name);
  }

  export function getALGModel(statModel: StatModel, transform: StatTransformationModel, variable: StatModelVariableDTO): ALGSingleSeriesModel {
    if (!variable || !transform || !statModel) { return null; }
    return {
      Description: transform.modelName.Description,
      RollingAccuracy: false,
      Name: transform.modelName.Value,
      PastForecasts: transform.PastForecasts,
      FittedValues: transform.FittedValues,
      ShapValues: transform.ShapValues,
      WShapValues: [],
      Periodicity: statModel.Periodicity,
      show: transform.show,
      Values: variable.Data,
      Id: variable.Id,
      modelName: transform.modelName,
      Color: transform.color
    };
  }

  /** Return a collection of ALG models for the specified variableId */
  export function getALGModelsForVariable(statModels: StatModel[], varId: string) {
    const result: ALGSingleSeriesModel[] = [];
    statModels.forEach(statModel => {
      statModel.Transforms.forEach(statTransform => {
        const main = statTransform.Variables.find(x => x.Id === varId);
        const modelToAdd = StatModelUtils.getALGModel(statModel, statTransform, main);
        if (modelToAdd) {
          result.push(modelToAdd);
        }
      });
    });
    return result;
  }

  export function cloneStatModel(statModel: StatModel) {
    return Object.assign(new StatModel, <StatModel> {
      ...statModel,
      Transforms: statModel.Transforms.map(t => cloneTransformModel(t))
    });
  }

  export function cloneTransformModel(transform: StatTransformationModel) {
    return Object.assign(new StatTransformationModel, <StatTransformationModel> {
      ...transform,
      Variables: transform.Variables?.map(v => cloneStatModelVariableDTO(v)),
      FittedValues: transform.FittedValues?.map(f => ValueUtils.copyValue(f)),
      WFittedValues: transform.WFittedValues?.map(f => ValueUtils.copyValue(f)),
      IndicatorVariableIds: [...transform.IndicatorVariableIds],
      InfoMessages: [...transform.InfoMessages],
      WarningMessages: [...transform.WarningMessages],
      ErrorMessages: [...transform.ErrorMessages],
      InProperties: transform.InProperties?.map(x => ({ ...x })),
      OutProperties: transform.OutProperties?.map(x => ({ ...x })),
      MAPESteps: transform.MAPESteps?.map(x => ({ ...x })),
      MPESteps: transform.MPESteps?.map(x => ({ ...x })),
      MAESteps: transform.MAESteps?.map(x => ({ ...x })),
      MESteps: transform.MESteps?.map(x => ({ ...x })),
      RMSESteps: transform.RMSESteps?.map(x => ({ ...x })),
      HRSteps: transform.HRSteps?.map(x => ({ ...x })),
      MASESteps: transform.MASESteps?.map(x => ({ ...x })),
      RSQSteps: transform.RSQSteps?.map(x => ({ ...x })),
      PastForecasts: transform.PastForecasts?.map(p => clonePastForecastDTO(p)),
      ShapValues: transform.ShapValues?.map(s => copyShap(s)),
      WShapValues: transform.WShapValues?.map(s => copyShap(s)),
      ImagesDTO: !!transform.ImagesDTO ? { ...transform.ImagesDTO, Files: transform.ImagesDTO?.Files?.map(f => ({ ...f })) } : null,
      TextFilesDTO: !!transform.TextFilesDTO ? { ...transform.TextFilesDTO, Files: transform.TextFilesDTO?.Files?.map(f => ({ ...f })) } : null
    });
  }

  export function cloneStatModelVariableDTO(variable: StatModelVariableDTO) {
    return <StatModelVariableDTO> {
      ...variable,
      Data: variable.Data.map(x => ValueUtils.copyValue(x)),
      Historic: variable.Historic.map(x => ValueUtils.copyValue(x)),
    };
  }

  export function clonePastForecastDTO(past: PastForecastDTO) {
    return <PastForecastDTO> {
      ...past,
      Values: [...past.Values],
      WValues: !!past.WValues ? [...past.WValues] : null
    };
  }

  export function copyShap(x: ShapValueDTO) {
    return <ShapValueDTO> {
      ...x,
      Values: x.Values.map(v => ValueUtils.copyValue(v))
    };
  }
}
