export class DragBarOptions<T> {
  public Values: Array<T>;
  public ScaleType: 'Time' = 'Time';
  public StartIndex?: number;
  public EndIndex?: number;
  public StartValue?: T;
  public EndValue?: T;
  public BarColor?: string;
  public HideXAxis?: boolean;
}
