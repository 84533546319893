import { DataProvider } from '@core/constants/provider-definitions';
import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { SqlDatabaseInfoModelDTO } from '../sql-database/sql-database.dtos';

export class RemoteDataSourceModel implements IHasModelId {
  @Def() RemoteDataSourceId: string;
  @Def() ClientId: string;
  @Def() RemoteUri: string;
  @Def() User: string;
  @Def() Provider: DataProvider;
  @Def() Visibility: string;
  @Def() LastAccessed: Date;
  @Def() ModifiedDate: Date;
  @Def() Password: string;
  @Def() ObjectId: string;
  @Def() ObjectName: string;
  @Def() ApiKey: string;
  @Def() DatabaseDTO: SqlDatabaseInfoModelDTO;

  variables: any[];

  public getModelId() {
    return this.RemoteDataSourceId;
  }

  public get editable() {
    switch (this.Provider) {
      // If the provider has authentication credentials, it should be listed here.
      case DataProvider.alphavantage:
      case DataProvider.fred:
      case DataProvider.quandl:
      case DataProvider.ftp:
      case DataProvider.macrobondapi:
      case DataProvider.refinitiv:
      case DataProvider.sharepoint:
      case DataProvider.acea:
      case DataProvider.googletrends:
        return true;

      default:
        return !!this.User || !!this.Password || !!this.ApiKey;
    }
  }
}
