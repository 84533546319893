import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { QuandleDatasetModel } from '@core/store/providers/quandl/quandl-dataset.model';
import { QuandlImportModalOpts } from './quandl-import-modal.options';

export class OpenQuandlImportModal {
  public static readonly type = '[MODAL] Open nasdaq data link import modal';
  data: QuandlImportModalOpts;
  constructor(
    dataset: QuandleDatasetModel,
    forecast: ForecastVersionModel
  ) {
    this.data = { dataset, forecast };
  }
}
