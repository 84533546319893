import { Injectable } from '@angular/core';
import { IMemberModel } from '@core/interfaces/if-member';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { HttpService } from '@core/services/http/http.service';
import { CompanyDTO, Employee2FAInfo } from '@core/store/company/dtos/company-dto';
import { UserStatisticsDTO } from '@core/store/company/dtos/user-statistics.dto';
import { UpdateData } from '@dialogs/variables/forecast-variable/update/update-variables.dialog.entities';
import { Syncable } from '@dialogs/variables/remote-variable/syncable-variables/syncable-variables.entities';
import { TagsMapper } from '../tags/tags-mapper';
import { CompanyMapper } from './company-mapper';
import { CompanyModel } from './company.model';
import { Set2FACompanyDTO } from './dtos/company-2fa-settings';
import { CreateCompanyDTO } from './dtos/create-company-dto';
import { EmployeeMetaDTO } from './dtos/employee-dto';
import { SourcevariablesInProjectDTO } from './dtos/sourcevariables-in-project-dto';


@Injectable({
  providedIn: 'root'
})
export class CompanyBackendService {

  constructor(
    private http: HttpService,
    private companyMapper: CompanyMapper,
    private tagMapper: TagsMapper,
    private env: EnvironmentService
  ) { }

  public getCompany(companyId: string) {
    return this.http.get<CompanyDTO>(`company/${companyId}/get-company`)
      .then(res => {
        const company = this.companyMapper.map(res.body);
        const tags = this.tagMapper.mapTags(res.body.Tags);
        return { company, tags };
      });
  }

  public getCompanyEmployees(companyId: string) {
    return this.http.get<EmployeeMetaDTO[]>(`company/${companyId}/employees`)
      .then(({ body }) => body);
  }

  public getUpdatableForecastVariableInfos(companyId: string, type: UpdateData.TargetTypes, objectId: string) {
    return this.http.get<UpdateData.DataInfoDTO[]>(`company/${companyId}/updatable-data/${type}/${objectId}`)
      .then(res => res.body);
  }

  public async getSyncableVariableInfos(companyId: string, type: Syncable.TargetTypes, objectId: string) {
    return this.http.get<Syncable.DataInfoDTO[]>(`company/${companyId}/syncable-data/${type}/${objectId}`)
      .then(res => {
        res.body.forEach(d => d.Variables.forEach(v => v.sourceType = this.env.getSourceType(v.SourceType)));
        return res.body;
      });
  }

  public get2FAInfoForCompany(companyId: string) {
    return this.http.get<Employee2FAInfo[]>(`company/${companyId}/get-2fa-status`)
      .then(res => res.body);
  }

  public createCompany(company: CreateCompanyDTO) {
    return this.http.post<CompanyDTO>('company/create-company', company)
      .then(resp => {
        const model = this.companyMapper.map(resp.body);
        return model;
      });
  }

  public getUserStatistics(dto) {
    return this.http.post<UserStatisticsDTO>('company/get-user-statistics', dto)
      .then(resp => resp.body);
  }

  public updateCompany(company: CompanyModel) {
    const body = this.companyMapper.toUpdate(company);
    return this.http.put<CompanyDTO>(`company/${company.CompanyId}/update-company`, body)
      .then(resp => {
        const model = this.companyMapper.map(resp.body);
        return model;
      });
  }

  public setActiveCompany(companyId: string) {
    return this.http.post<null>(`my/company/${companyId}/set-active`, {})
      .then(() => companyId);
  }

  public inviteUsersToCompany(companyId: string, invites: IMemberModel[]) {
    const dtos = invites.map(i => this.companyMapper.toInviteDto(i));
    return this.http.post<null>(`company/${companyId}/employees`, dtos);
  }

  public uninviteUserFromCompany(companyId: string, employeeEmail: string) {
    return this.http.delete<null>(`company/${companyId}/employees/${employeeEmail}/`);
  }

  public remindUser2FA(clientId: string, companyId: string) {
    return this.http.get(`company/${companyId}/remind-user-2fa/${clientId}`);
  }

  public set2FACompany(companyId: string, dto: Set2FACompanyDTO) {
    return this.http.post(`company/${companyId}/set-mfa-required`, dto);
  }


  public setAutomaticLogout(companyId: string, status: boolean) {
    return this.http.post(`company/${companyId}/set-automatic-logout`, status);
  }

  // Test endpoints, not active on production
  public resetUsers(companyId: string) {
    return this.http.delete<CompanyDTO>(`company/${companyId}/reset-users`)
      .then(res => {
        const company = this.companyMapper.map(res.body);
        return company;
      });
  }

  public setSourceFromProviderVisibility(companyId: string, visibilityLevel: string) {
    return this.http.get(`company/${companyId}/set-source-from-provider-visibility/${visibilityLevel}`)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  public setSourceFromFileVisibility(companyId: string, visibilityLevel: string) {
    return this.http.get(`company/${companyId}/set-source-from-file-visibility/${visibilityLevel}`)
      .then(() => {
        return true;
      }).catch(() => {
        return false;
      });
  }

  public removeCompanyData(companyId: string) {
    return this.http.delete<CompanyDTO>(`company/${companyId}/remove-company-data`)
      .then(res => {
        const company = this.companyMapper.map(res.body);
        return company;
      });
  }

  public getProjectsSourceVariableList(companyId: string) {
    return this.http.get<SourcevariablesInProjectDTO[]>(`company/${companyId}/get-projects-sourcevariable-list`)
      .then(({ body }) => body.sort((a, b) => a.ProjectName.localeCompare(b.ProjectName)));
  }
}



