import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'indicio-options-menu',
  templateUrl: './options-menu.component.html'
})
export class OptionsMenuComponent implements OnInit, OnDestroy, OnChanges {

  open = false;
  left = '0px';
  top = '0px';
  _id = `oMenu-${Math.random().toString(36).substring(7)}`;

  @Input() invertToggler: boolean;
  @Input() icon: string = 'ion-android-more-vertical';
  @Input() iconsize: string = '30px';
  @Input() nopadding: boolean = false;
  @Input() nohover: boolean = false;
  @Input() filler: boolean;
  @Input() fillerTop: string;
  @Input() containerScrolled: number;

  @Output() openEvent = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.containerScrolled) {
      this.reposition(changes.containerScrolled.currentValue);
    }
  }

  ngOnInit() {
    document.querySelector('body').addEventListener('close-option-menu', this.bodyClose.bind(this));
  }

  ngOnDestroy() {
    document.querySelector('body').removeEventListener('close-option-menu', this.bodyClose.bind(this));
  }

  public toggle($event: any, force?: boolean) {
    this.open = force !== undefined ? force : !this.open;
    if (this.open) {
      const setTopTimeout = () => setTimeout(() => {
        if (!!document.querySelector('.options-menu-container__content')) {
          this.setTop();
        } else {
          setTopTimeout();
        }
      }, 0);
      setTopTimeout();
    }
    this.openEvent.emit({ open: this.open, event: $event });
  }

  public reposition(scrolled: number) {
    if (!this.open) { return; }
    this.setTop(scrolled);
  }

  private setTop(_scrolled: number = 0) {
    const toggler = document.querySelector(`#${this._id} .options-menu-container__toggler`);
    const pos = toggler.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    let left = pos.left;


    if (this.nopadding) {
      left -= 15;
    }

    const menu: HTMLElement = document.querySelector('.options-menu-container__content');
    this.top = `${pos.top + pos.height}px`;
    this.left = `${left}px`;
    menu.style.opacity = '0';

    const menuDimensions = menu.getBoundingClientRect();

    if ((pos.top + pos.height + menuDimensions.height) > windowHeight) {
      this.top = `${pos.top - menuDimensions.height}px`;
    }

    const menuEnd = menuDimensions.x + menuDimensions.width;
    const windowEnd = window.innerWidth;

    if (windowEnd < menuEnd) {
      left -= menuDimensions.width;
      this.left = `${left}px`;
    }
    menu.style.opacity = '1';
  }

  private bodyClose() {
    if (!this.open) { return; }
    this.toggle(null, false);
  }
}
