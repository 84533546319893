import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { BehaviorSubject } from 'rxjs';
import { HistoricEventActions } from './historic-event.actions';
import { HistoricEventBackendService } from './historic-event.backend.service';
import { HistoricEventState } from './historic-event.state';
import { HistoricBaseEventModel, HistoricEventModel } from './models/base/historic-event.model';
import { ImportedHistoricBaseEventModel } from './models/imported/imported-historic-base-event.model';

@Injectable({
  providedIn: 'root'
})
export class HistoricEventFrontendService {

  // OBSERVABLES
  public _loading = new BehaviorSubject<boolean>(false);
  public loading = this._loading.asObservable();
  public historicEvents$ = this.store.select(HistoricEventState.historicEvents);
  // GETTERS
  public get allEvents() { return this.store.selectSnapshot(HistoricEventState.historicEvents); }

  public findEvent(eventId: string) {
    for (const g of this.allEvents) {
      const e = g.Events.find(z => z.HistoricEventId === eventId);
      if (e) {
        return e;
      }
    }
    return null;
  }

  constructor(
    private store: Store,
    private clientService: ClientFrontendService,
    private backend: HistoricEventBackendService
  ) {
  }

  ///
  /// BASE PART
  ///
  public findBaseEvent(eventId: string) {
    for (const g of this.allEvents) {
      const e = g.Events.find(z => z.HistoricEventId === eventId);
      if (e) {
        return g;
      }
    }
    return null;
  }

  public getHistoricBaseEventsInCompany() {
    const ids = this.clientService.activeCompany.HistoricBaseEventIds;
    const hasAll = this.allEvents.includesAllIds(ids);
    if (!hasAll) {
      return this.fetchHistoricBaseEventsInCompany(this.clientService.activeCompany.CompanyId);
    } else {
      return Promise.resolve(this.allEvents);
    }
  }

  public fetchHistoricBaseEventsInCompany(companyId: string) {
    this._loading.next(true);
    return this.backend.getHistoricBaseEvents(companyId)
      .then(events => {
        this.store.dispatch(new HistoricEventActions.GetAllSuccess(events));
        this._loading.next(false);
        return events;
      });
  }

  public createBaseEvent(baseEvent: HistoricBaseEventModel) {
    return this.backend.createBaseEvent(baseEvent)
      .then(created => {
        this.store.dispatch(new HistoricEventActions.GetSuccess(created));
        return created;
      });
  }

  public updateBaseEvent(hEvent: HistoricBaseEventModel) {
    return this.backend.updateBaseEvent(hEvent)
      .then(updated => {
        this.store.dispatch(new HistoricEventActions.GetSuccess(updated));
        return updated;
      });
  }

  public deleteBaseEvent(hEvent: HistoricBaseEventModel) {
    return this.backend.deleteBaseEvent(hEvent)
      .then(deleted => {
        this.store.dispatch(new HistoricEventActions.DeleteSuccess(deleted));
        return deleted;
      });
  }

  ///
  /// EVENT PART
  ///
  public deleteEvent(hEvent: HistoricEventModel, companyId: string, baseEventId: string) {
    return this.backend.deleteEvent(hEvent, companyId, baseEventId);
  }



  ///
  /// FORECAST PART
  ///
  public syncEventsInForecast(forecastVersionId: string) {
    return this.backend.getHistoricEventsInForecastVersion(forecastVersionId)
      .then(events => {
        this.store.dispatch(new HistoricEventActions.GetAllInForecastSuccess(forecastVersionId, events));
        return events;
      });
  }

  public addEventToForecast(hEvent: ImportedHistoricBaseEventModel) {
    return this.backend.addEventToForecast(hEvent)
      .then(added => {
        this.store.dispatch(new HistoricEventActions.GetOneInForecastSuccess(added));
        return added;
      });
  }

  public updateEventInForecast(hEvent: ImportedHistoricBaseEventModel) {
    return this.backend.updateEventInForecast(hEvent)
      .then(added => {
        this.store.dispatch(new HistoricEventActions.GetOneInForecastSuccess(added));
        return added;
      });
  }

  public removeEventFromForecast(hEvent: ImportedHistoricBaseEventModel) {
    return this.backend.removeEventFromForecast(hEvent)
      .then(removedId => {
        this.store.dispatch(new HistoricEventActions.RemoveFromForecast(hEvent.ForecastVersionId, removedId));
        return removedId;
      });
  }
}
