import { HttpContext, HttpContextToken } from '@angular/common/http';

export namespace IndicioHttpContext {

  export const INFO_KEY = new HttpContextToken<object>(() => null);
  export const IS_PUBLIC_KEY = new HttpContextToken<boolean>(() => false);
  export const TIMEOUT = new HttpContextToken<number>(() => 60000);

  export function GetContext(o: {
    Info?: object;
    Timeout?: number,
    IsPublic?: boolean;
  }) {
    const ctx = new HttpContext();
    if (o.Info) { ctx.set(INFO_KEY, o.Info); }
    if (o.Timeout) { ctx.set(TIMEOUT, o.Timeout); }
    if (o.IsPublic !== undefined) { ctx.set(IS_PUBLIC_KEY, o.IsPublic); }
    return ctx;
  }
}
