import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { ErrorReportService } from '@core/services/error-report.service';
import { PusherService } from '@core/services/frontend/pusher.service';
import { CreateForecastDTO } from '@core/store/forecast/dtos/forecast/create-forecast-dto';
import { CreateNowcastDTO } from '@core/store/forecast/dtos/forecast/create-nowcast-dto';
import {
  CreateForecastSuccessAction,
  ForecastActions,
  ForecastModuleReloadAction,
  GetCanCalculateInfoSuccessAction,
  GetForecastMetaSuccessAction,
  GetForecastsInProjectSuccessAction,
  GetForecastSuccessAction,
  GetForecastVersionSuccessAction,
  GetNowcastSuccessAction,
  RemoveForecastSuccessAction,
  RemoveNowcastSuccessAction,
  SetActiveForecastIdAction
} from '@core/store/forecast/forecast.actions';
import { ForecastBackendService } from '@core/store/forecast/forecast.backend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ModelScaling } from '@core/types/model-scaling.type';
import { VsResultType } from '@modules/lang/language-files/var-select';
import { ModelGroup } from '@modules/lang/types/model-name';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { CacheUtils } from '@shared/utils/cache.utils';
import { DateUtils } from '@shared/utils/date.utils';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../services/environment/environment.service';
import { AssessmentMapper } from '../assessment/assessment-mapper';
import { GetAssessmentsInForecastVersionSuccessAction } from '../assessment/assessment.actions';
import { ClientFrontendService } from '../client/client.frontend.service';
import { CompanyActions } from '../company/company.actions';
import { CompanyFrontendService } from '../company/company.frontend.service';
import { ForecastVariableMapper } from '../forecast-variable/forecast-variable-mapper';
import { GetForecastVariableSuccessAction } from '../forecast-variable/forecast-variable.actions';
import { RemoveProjectSuccessAction } from '../project/project.actions';
import { ProjectModel } from '../project/project.model';
import { ScenarioMapper } from '../scenario/scenario-mapper';
import { ScenarioAction } from '../scenario/scenario.actions';
import { QueueActions } from '../script-queue/script-queue.actions';
import { VarSelectMapper } from '../var-select/var-select-mapper';
import { VarSelectResultModel } from '../var-select/var-select-result.model';
import { VarSelectActions } from '../var-select/var-select.actions';
import { ForecastVersionDTO } from './dtos/forecast-version/forecast-version-dto';
import { ForecastVersionDateRangeDTO } from './dtos/forecast-version/forecast-version-meta-dto';
import { UpdateForecastVersionDTO } from './dtos/forecast-version/update-forecast-version-dto';
import { CopyForecastDTO } from './dtos/forecast/copy-forecast-dto';
import { RecentForecastDTO } from './dtos/forecast/forecast-dto';
import { MoveForecastDTO } from './dtos/forecast/move-forecast-dto';
import { GetPotentialForecastsDTO } from './dtos/forecast/potential-forecast.dto';
import { UpdateForecastDTO } from './dtos/forecast/update-forecast-dto';
import { VarSelectSettingsDTO } from './dtos/var-select-settings/var-select-settings-dto';
import { ForecastState } from './forecast.state';
import { ForecastMapper } from './mapper/forecast-mapper';

type LoadableItems = 'ForecastLoading' | 'ForecastVersionLoading';

@Injectable({
  providedIn: 'root'
})
export class ForecastFrontendService {

  loading = {
    ForecastLoading: false,
    ForecastVersionLoading: false
  };

  // Cache
  private recentCache = new CacheUtils.LruCache<RecentForecastDTO[]>(5);
  private resyncCache: boolean;

  private sub = new Subscription();

  public tempNewForecast: CreateForecastDTO = new CreateForecastDTO();

  // OBSERVABLES
  public forecasts$ = this.store.select(ForecastState.forecasts);
  public getForecastsInProjectFn$ = this.store.select(ForecastState.getForecastsInProjectFn);
  public forecastById$(forecastId: string) { return this.forecasts$.pipe(map(x => x.find(f => f.ForecastId === forecastId))); }
  public activeVersion$(forecastId: string) { return this.forecastById$(forecastId).pipe(map(x => x ? x.activeVersion : null)); }

  // GETTERS
  public get forecasts() { return this.store.selectSnapshot(ForecastState.forecasts) || []; }
  public get activeForecastId() { return this.store.selectSnapshot(ForecastState.activeForecastId); }
  public get forecastVersions() { return this.store.selectSnapshot(ForecastState.forecastVersions) || []; }
  public forecastById(forecastId: string) { return this.forecasts.find(x => x.ForecastId === forecastId); }
  public forecastVersionById(forecastVersionId: string) { return this.forecastVersions.find(x => x.ForecastVersionId === forecastVersionId); }
  public getForecastsInProject(projectId: string) { return this.forecasts.filter(x => x.ProjectId === projectId); }
  public forecastByVersionId(forecastVersionId: string) { return this.forecasts.find(x => x.ForecastVersionIds.includes(forecastVersionId)); }

  constructor(
    private store: Store,
    private actions: ActionService,
    private env: EnvironmentService,
    private backendService: ForecastBackendService,
    private projectService: ProjectFrontendService,
    private companyService: CompanyFrontendService,
    private clientService: ClientFrontendService,
    private errorReportService: ErrorReportService,
    private dialogService: DialogService,
    private assessmentMapper: AssessmentMapper,
    private varSelectMapper: VarSelectMapper,
    private scenarioMapper: ScenarioMapper,
    private forecastMapper: ForecastMapper,
    private forecastVariableMapper: ForecastVariableMapper,
    private pusherService: PusherService
  ) {
    this.setupActionSubscriptions();
  }

  public get activeUsers() {
    const members = this.pusherService.getForecastChannel()?.members.members;
    if (!members) { return []; }
    return Object.values(members).map(x => (<any> x).email);
  }

  private setupActionSubscriptions() {
    this.sub.add(this.actions.dispatched(ForecastActions.FetchCanCalc).subscribe((action: ForecastActions.FetchCanCalc) => {
      this.syncCanCalculateInformation(action.forecastVersionId);
    }));

    this.sub.add(this.actions.dispatched(ForecastActions.GenericMessage).subscribe((msg: ForecastActions.GenericMessage) => {
      this.openGenericMessage(msg);
    }));

    this.sub.add(this.actions.dispatched(ForecastActions.GetDateInfoSuccess).subscribe((msg: ForecastActions.GetDateInfoSuccess) => {
      this.handleNewDateInfo(msg.dto);
    }));

    this.sub.add(this.actions.dispatched(RemoveForecastSuccessAction, CompanyActions.ChangedActiveCompany, RemoveProjectSuccessAction)
      .subscribe(() => {
        this.resyncCache = true;
      }));
  }

  private openGenericMessage(msg: ForecastActions.GenericMessage) {
    if (!msg.Dto.Extras || !msg.Dto.Extras.length) { return this.dialogService.openPusherInfoMessageDialog(msg); }
    this.dialogService.openGenericTableDialog({
      Table: msg.Dto.Extras.map(x => x.RowContent),
      Title: msg.Dto.Title,
      Message: msg.Dto.Message,
      GridTemplate: '0.5fr 1fr',
      Transpose: false
    });
  }

  public setActiveVsResultMode(versionId: string, mode: VsResultType) {
    const version = this.forecastVersionById(versionId);
    return this.backendService.setActiveVs(versionId, mode)
      .then(resp => {
        if (resp.body) {
          version.ActiveVSResultType = mode;
          this.store.dispatch(new GetForecastVersionSuccessAction(version));
          return version;
        }
      });
  }

  public deleteVersion(version: ForecastVersionModel) {
    return this.backendService.deleteVersion(version.ForecastVersionId)
      .then(() => {
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return this.fetchForecast(version.ForecastId);
      });
  }

  private setLoadingStatus(whatIsLoading: LoadableItems, state: boolean) {
    this.loading[whatIsLoading] = state;
  }

  public forecastNameConflict(name: string, project: ProjectModel, forecastId: string) {
    return this.getOrFetchForecastsInProject(project).then((potentialHits) => {
      return potentialHits
        .filter(x => x.ForecastId !== forecastId)
        .some(x => x.Name.toLowerCase() === name.toLowerCase());
    });
  }

  public getAllActiveForecasVersions() {
    return this.forecasts.map(f => f.activeVersion || f.getLatestVersion());
  }

  public syncCanCalculateInformation(forecastVersionId: string) {
    return this.backendService.fetchCanCalculateInformation(forecastVersionId)
      .then(_calcInfo => {
        this.store.dispatch(new GetCanCalculateInfoSuccessAction(forecastVersionId, _calcInfo));
      });
  }

  public deleteForecast(forecast: ForecastModel) {
    forecast.isPending = true;
    return this.backendService.removeForecast(this.clientService.activeCompanyId, forecast.ForecastId)
      .then(() => {
        this.store.dispatch(new RemoveForecastSuccessAction(forecast.ForecastId));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
      });
  }

  public deleteNowcast(nowcastId: string, parentFversionId: string, nowcastedFvarId: string) {
    return this.backendService.removeNowcast(this.clientService.activeCompanyId, nowcastId)
      .then(() => {
        this.store.dispatch(new RemoveNowcastSuccessAction(parentFversionId, nowcastedFvarId));
        this.syncCanCalculateInformation(parentFversionId);
      });
  }

  public getOrFetchForecast(forecastId: string): Promise<ForecastModel> {
    const f = this.forecastById(forecastId);
    if (!f || f.needFetch()) {
      this.setLoadingStatus('ForecastLoading', true);
      return this.fetchForecast(forecastId)
        .finally(() => this.setLoadingStatus('ForecastLoading', false));
    } else {
      return Promise.resolve(f);
    }
  }

  public getOrFetchForecastVersion(forecastVersionId: string) {
    const fV = this.forecastVersionById(forecastVersionId);
    if (!fV) { this.store.dispatch(new NavigationActions.ForceForecastDrawerReload); }
    if (!fV || fV.needFetch()) {
      this.setLoadingStatus('ForecastVersionLoading', true);
      return this.fetchForecastVersion(forecastVersionId).then(fcv => {
        this.setLoadingStatus('ForecastVersionLoading', false);
        return fcv;
      });
    } else {
      return Promise.resolve(fV);
    }
  }

  public fetchForecast(forecastId: string) {
    this.setLoadingStatus('ForecastLoading', true);
    return this.backendService.getForecast(forecastId)
      .then(forecast => {
        this.store.dispatch(new GetForecastSuccessAction(forecast));
        return forecast;
      })
      .finally(() => this.setLoadingStatus('ForecastLoading', false));
  }

  public fetchDateInfo(forecastVersionId: string) {
    this.setLoadingStatus('ForecastVersionLoading', true);
    return this.backendService.getForecastVersionDateInfo(forecastVersionId)
      .then(dateInfo => this.handleNewDateInfo(dateInfo))
      .finally(() => this.setLoadingStatus('ForecastVersionLoading', false));
  }

  public fetchForecastVersion(forecastVersionId: string) {
    this.setLoadingStatus('ForecastVersionLoading', true);
    return this.backendService.getForecastVersion(forecastVersionId)
      .then(parts => this.handleForecastVersionDTO(parts.body))
      .finally(() => this.setLoadingStatus('ForecastVersionLoading', false));
  }

  public getOrFetchForecastsInProject(project: ProjectModel) {
    if (!project) {
      this.errorReportService.postFrontendIssue({
        Stacktrace: `Project was ${project}`,
        Subject: 'getOrFetchForecastsInProject(project) recieved project undefined or null.'
      });
      return Promise.resolve([] as ForecastModel[]);
    }
    const forecasts = this.getForecastsInProject(project.ProjectId);
    const hasAll = forecasts.includesAllIds(project.ForecastIds) && forecasts.length === project.ForecastCount;
    if (!hasAll) {
      return this.fetchForecastsInProject(project.ProjectId);
    } else {
      return Promise.resolve(forecasts);
    }
  }

  /**
   * This call does NOT store anything in users storage
   */
  public getRecentlyModified(companyId: string, count: number) {
    const cache = this.recentCache.get(companyId);
    if (this.resyncCache === false && cache?.length >= count) {
      return Promise.resolve(this.recentCache.get(companyId));
    }
    return this.backendService.getRecentlyModified(companyId, count)
      .then(fVersionMetaDtos => {
        this.recentCache.put(companyId, fVersionMetaDtos);
        this.resyncCache = false;
        return fVersionMetaDtos;
      });
  }

  public getForecastCount(companyId: string) {
    return this.backendService.getForecastCount(companyId)
      .then(count => count);
  }

  public fetchForecastsInProject(projectId: string) {
    return this.backendService.getForecastsInProject(projectId)
      .then(forecastMetaDtos => {
        this.store.dispatch(new GetForecastsInProjectSuccessAction(forecastMetaDtos));
        return forecastMetaDtos;
      });
  }

  public createForecast(companyId: string, createDto: CreateForecastDTO) {
    return this.backendService.createForecast(companyId, createDto)
      .then(f => {
        this.tempNewForecast = new CreateForecastDTO();
        this.store.dispatch(new GetForecastSuccessAction(f));
        this.store.dispatch(new CreateForecastSuccessAction(f.ForecastId, f.ProjectId));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return f;
      });
  }

  public copyForecast(dto: CopyForecastDTO) {
    return this.backendService.copyForecast(this.clientService.activeCompanyId, dto)
      .then(f => {
        this.store.dispatch(new GetForecastSuccessAction(f));
        this.store.dispatch(new CreateForecastSuccessAction(f.ForecastId, f.ProjectId));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return f;
      });
  }

  public moveForecast(moveDto: MoveForecastDTO) {
    return this.backendService.moveForecast(this.clientService.activeCompanyId, moveDto)
      .then(f => {
        this.store.dispatch(new GetForecastSuccessAction(f));
        this.store.dispatch(new CreateForecastSuccessAction(f.ForecastId, f.ProjectId));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return f;
      });
  }

  public createNowcast(dto: CreateNowcastDTO) {
    return this.backendService.createNowcast(dto)
      .then(nowcast => {
        this.store.dispatch(new GetNowcastSuccessAction(nowcast));
        return nowcast;
      });
  }

  public getForecastClientSettings(forecastVersionId: string) {
    return this.backendService.getForecastVersionSettings(forecastVersionId);
  }

  public getForecastMeta(forecastId: string) {
    return this.backendService.getForecastMeta(forecastId)
      .then(forecastMeta => {
        this.store.dispatch(new GetForecastMetaSuccessAction(forecastMeta));
        return forecastMeta;
      });
  }

  public updateForecast(forecast: ForecastModel, dto: UpdateForecastDTO) {
    return this.backendService.updateForecast(forecast, dto)
      .then(f => {
        this.store.dispatch(new GetForecastSuccessAction(f));
        this.store.dispatch(new ForecastActions.UpdatedForecast(f.ForecastId));
        return f;
      });
  }

  public setForecastVersion(version: ForecastVersionModel) {
    return this.getOrFetchForecastVersion(version.ForecastVersionId)
      .then(fv => {
        const f = this.forecastById(fv.ForecastId);
        const p = this.projectService.projectById(f.ProjectId);
        const c = this.companyService.getCompany(p.CompanyId);
        this.store.dispatch(new ForecastModuleReloadAction(fv, c, p));
        return fv;
      });
  }

  public updateForecastVersion(version: ForecastVersionModel, updatedFields: Partial<UpdateForecastVersionDTO>) {
    const dto = Object.assign(Object.faMapTo(new UpdateForecastVersionDTO, version), updatedFields);
    dto.StartDate = DateUtils.convertToBackendDate(new Date(dto.StartDate));
    dto.DataStartDate = DateUtils.convertToBackendDate(DateUtils.newNullableDate(dto.DataStartDate), true);
    dto.TestStartDate = DateUtils.convertToBackendDate(DateUtils.newNullableDate(dto.TestStartDate), true);
    return this.backendService.updateForecastVersion(version, dto)
      .then(async (resp) => {
        await this.handleForecastVersionDTO(resp);
        const updated = this.forecastMapper.mapVersion(resp);
        this.store.dispatch(new ForecastActions.UpdatedForecast(updated.ForecastId));
        this.store.dispatch(new NavigationActions.ForceForecastDrawerReload);
        return updated;
      });
  }

  public getPotentialForecasts(dto: GetPotentialForecastsDTO, companyId: string) {
    return this.backendService.getPotentialForecasts(dto, companyId);
  }

  public isSourceVariableInUse(sourceId: string) {
    return this.forecastVersions.some(f => !!f.getVariableBySourceVariableId(sourceId));
  }

  public setActiveForecast(forecastId: string) {
    this.store.dispatch(new SetActiveForecastIdAction(forecastId));
  }

  public forecastExists(forecastId) {
    const forecast = this.forecastById(forecastId);
    return !!forecast;
  }

  public async handleForecastVersionDTO(resp: ForecastVersionDTO): Promise<ForecastVersionModel> {
    const scenarios = resp.Scenarios.map(x => this.scenarioMapper.map(x));
    const varSelectPromise = resp.VariableSelectionResults.map(r => this.varSelectMapper.map(r));
    const assessments = resp.Assessments.map(a => this.assessmentMapper.map(a));
    const version = this.forecastMapper.mapVersion(resp);
    let vs: VarSelectResultModel[] = null;
    if (varSelectPromise) {
      vs = await Promise.all(varSelectPromise);
    }
    this.store.dispatch(new VarSelectActions.GetSuccess(vs));
    this.store.dispatch(new GetForecastVersionSuccessAction(version));
    this.store.dispatch(new ScenarioAction.GetAllSuccess(version.ForecastVersionId, scenarios));
    this.store.dispatch(new GetAssessmentsInForecastVersionSuccessAction(version.ForecastVersionId, assessments));

    [resp.ForecastVariable, ...resp.IndicatorVariables]
      .filter(x => !!x)
      .forEach(e => {
        const variable = this.forecastVariableMapper.mapMeta(e);
        this.store.dispatch(new GetForecastVariableSuccessAction(variable));
      });

    return version;
  }

  /**
   *
   * VAR SELECT SETTINGS
   *
   */
  // GET
  public getVarSelectSettings(forecastId: string, fversionId: string) {
    const forecastVersion = this.forecastVersionById(fversionId);
    if (!forecastVersion.VarSelectSettings) {
      return this.fetchVarSelectSettings(forecastId, fversionId);
    } else {
      return Promise.resolve(forecastVersion.VarSelectSettings);
    }
  }

  public fetchVarSelectSettings(forecastId: string, fversionId: string) {
    return this.backendService.getVarSelectSettings(fversionId)
      .then(dto => {
        this.store.dispatch(new VarSelectActions.UpdatedSettingsSucces(forecastId, fversionId, dto));
        return dto;
      });
  }

  // SET
  public saveVarSelectSettings(forecastId: string, fVersionId: string, settings: VarSelectSettingsDTO) {
    return this.backendService.saveVarSelectSettings(fVersionId, settings)
      .then(dtoX => {
        this.store.dispatch(new VarSelectActions.UpdatedSettingsSucces(forecastId, fVersionId, dtoX));
        return dtoX;
      });
  }

  /**
   *
   * MISC
   *
   */

  // Get active for forecast
  public getActiveUnivariateModels(forecast: ForecastModel): ModelGroup[] {
    if (!forecast) { return []; }
    const allModels = this.env.UnivariateModels.map(mg => ({ ...mg, Transforms: mg.Transforms.map(t => ({ ...t })) }));
    const filters = forecast.ModelFilterSettings ? forecast.ModelFilterSettings.Filters : null;
    return !filters || !filters.length
      ? allModels
      : allModels.filter(x => !filters.find(y => y.ModelName === x.Model.Value)?.SkipModel);
  }

  public getActiveMultivariateModels(forecast: ForecastModel): ModelGroup[] {
    if (!forecast) { return []; }
    const allModels = this.env.MultivariateModels.map(mg => ({ ...mg, Transforms: mg.Transforms.map(t => ({ ...t })) }));
    const filters = forecast.ModelFilterSettings ? forecast.ModelFilterSettings.Filters : null;
    return !filters || !filters.length
      ? allModels
      : allModels.filter(x => !filters.find(y => y.ModelName === x.Model.Value)?.SkipModel);
  }

  public isModelInCorrectScaling(fVersion: ForecastVersionModel, value: string) {
    return fVersion.ModelScaling === ModelScaling.Normalisation && value.toLowerCase().includes(ModelScaling.Normalisation)
      || fVersion.ModelScaling === ModelScaling.Standardisation && value.toLowerCase().includes(ModelScaling.Standardisation);
  }

  public fetchRequestQueue(forecastVersionId: string, isSanity: boolean = false) {
    return this.backendService.getRequestQueue(forecastVersionId)
      .then(queue => this.store.dispatch(new QueueActions.QueueInfo(queue, true, isSanity)));
  }

  public removeIndicatorWarnings(forecastVersionId: string) {
    return this.backendService.removeIndicatorWarnings(forecastVersionId)
      .then(() => this.store.dispatch(new ForecastActions.RemoveIndicatorWarnings(forecastVersionId)));
  }

  private handleNewDateInfo(dto: ForecastVersionDateRangeDTO) {
    if (!dto) { return; }
    if (dto.forceFVersionUpdate) { return this.fetchForecastVersion(dto.ForecastVersionId); }
    const fVersion = this.forecastVersionById(dto.ForecastVersionId);
    if (!fVersion) { return; }
    const updated = this.forecastMapper.mapDateInfo(dto, fVersion);
    this.store.dispatch(new GetForecastVersionSuccessAction(updated));
    return updated;
  }
}

