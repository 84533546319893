import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ImportedHistoricEventModel } from '@core/store/historic-event/models/imported/imported-historic-base-event.model';
import { DateUtils } from '../date.utils';

export namespace HistoricEventUtils {
  export function filterSelf(e: ImportedHistoricEventModel, id: string) { return e.ImportedHistoricEventId === id; }
  export function isInHistory(e: ImportedHistoricEventModel, startDate: Date, periodicity) { return e.Date && e.Date.isBefore(DateUtils.newMoment(startDate), periodicity); }
  export function isAfterForecastedPeriod(e: ImportedHistoricEventModel, lastResultDate: Date, periodicity) { return e.Date && e.Date.isAfter(DateUtils.newMoment(lastResultDate), periodicity); }
  export function filterType(e: ImportedHistoricEventModel, type) { return e.EffectType === type; }
  export function isPartiallyInFuture(e: ImportedHistoricEventModel, untilDate, periodicity) {
    return e.Date && (e.Date.isSame(DateUtils.newMoment(untilDate), periodicity) && e.EffectType === 'lagged-spike');
  }

  export function needCorrelation(event: ImportedHistoricEventModel, fv: ForecastVersionModel) {
    const past = HistoricEventUtils.isInHistory(event, fv.StartDate, fv.Periodicity);
    const beyond = HistoricEventUtils.isAfterForecastedPeriod(event, fv.LastResultDate, fv.Periodicity);
    const partials = HistoricEventUtils.isPartiallyInFuture(event, fv.DataUntilDateRequirement, fv.Periodicity);
    return (!past || partials) && !beyond;
  }
}
