import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { INeedFetch } from '@core/interfaces/if-need-fetch';
import { NowcastResultMetaDTO } from '@core/store/forecast-result/entities/nowcast-result-meta-dto';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Periodicity } from '@modules/lang/types/periodicity';
import { ForecastModelFilterSettingsDTO } from '../dtos/model-filters/forecast-model-filter-settings-dto';

export class ForecastMetaModel implements IHasModelId {
  @Def() ForecastId: string;
  @Def() ProjectId: string;
  @Def() ParentForecastId: string;
  @Def() ParentForecastVersionId: string;
  @Def() NowcastedVariableId: string;
  @Def() Name: string;
  @Def() Periodicity: PeriodicityType;
  @Def() AggregationMethodId: string;
  @Def() ForecastVersionIds: string[] = [];
  @Def() Results: NowcastResultMetaDTO[] = [];
  @Def() IsNowcast: boolean;
  @Def() CreatedDate: Date;

  public ModelScaling: string = 'all';

  // frontend
  public openMenu: boolean; // sidemenu
  public isPending = false;
  public periodicity: Periodicity;
  public getModelId() { return this.ForecastId; }
}

export class ForecastModel extends ForecastMetaModel implements INeedFetch {

  ForecastVersions: ForecastVersionModel[] = [];
  ModelFilterSettings: ForecastModelFilterSettingsDTO = null;

  // frontend functions
  public activeVersionId: string = null;
  public get activeVersion() {
    return this.ForecastVersions.find(fv => fv.ForecastVersionId === this.activeVersionId);
  }

  public set activeVersion(forecastVersion: ForecastVersionModel) {
    this.activeVersionId = forecastVersion.ForecastVersionId;
  }

  public getVersionById(forecastVersionId: string) {
    return this.ForecastVersions.find(fversion => fversion.ForecastVersionId === forecastVersionId);
  }

  public getLatestVersion() {
    const sorted = this.ForecastVersions.sort((a, b) => b.VersionNumber - a.VersionNumber);
    return sorted[0];
  }

  public needFetch(): boolean {
    return this.ForecastVersionIds.length !== this.ForecastVersions.length;
  }
}
