import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StatusService } from '@core/services/status/status.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ScenarioVariableDTO } from '@core/store/scenario/dtos/scenario-variable.dto';
import { ScenarioDTO } from '@core/store/scenario/dtos/scenario.dtos';
import { ScenarioFrontendService } from '@core/store/scenario/scenario.frontend.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { ItemState } from '@shared/utils/itemstate.util';


export enum ScenarioDialogViewIndex {
  'general' = 0,
  'manage' = 1,
  'settings' = 2
}

export class ScenarioDialogData {
  ForecastVersionId: string;
  ScenarioId?: string;
  Tab?: ScenarioDialogViewIndex = ScenarioDialogViewIndex.general;
}


@Component({
  selector: 'indicio-scenario-dialog',
  templateUrl: 'scenario.dialog.html',
  styleUrls: ['scenario.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class ScenarioDialogComponent extends DialogV2BaseDialog<ScenarioDialogComponent> {
  public static Id: string = 'ScenarioDialogComponent';

  public viewIndex: number = 0;
  public viewTypes = ScenarioDialogViewIndex;

  public edit: boolean = false;
  public pending: boolean = false;
  public saved: boolean = false;

  public missingName: boolean = false;
  public missingVariables: boolean = false;

  public scenario: ItemState<ScenarioDTO>;
  public fVersion: ForecastVersionModel;
  public availableModels: string[] = [];

  public issues: boolean;

  public get savable() {
    return !this.issues && !this.missingName && !this.missingVariables;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ScenarioDialogData,
    private service: ScenarioFrontendService,
    private forecastService: ForecastFrontendService,
    private statusService: StatusService,
    dialogRef: MatDialogRef<ScenarioDialogComponent>) {
    super(dialogRef);
    this.initialize().catch((e) => this.statusService.setError(e));
  }

  public checkName() { this.missingName = !this.scenario.Item.Name; }
  public checkVariables() { this.missingVariables = this.scenario.Item._tempVariables.length === 0 || !this.scenario.Item._tempVariables.some(x => x.Item.Values.filter(y => y != null).length > 0); }

  protected async initialize() {
    this.fVersion = await this.forecastService.getOrFetchForecastVersion(this.data.ForecastVersionId).catch((e) => { this.statusService.setError(e); return null; });
    this.availableModels = await this.service.fetchAvailableScenarioMultiModels(this.data.ForecastVersionId);
    if (this.data.ScenarioId) {
      this.edit = true;
      const scenario = await this.getScenario();
      this.scenario = new ItemState<ScenarioDTO>().set(scenario);
    } else {
      this.scenario = new ItemState<ScenarioDTO>().set(new ScenarioDTO);
      this.scenario.Item.ForecastVersionId = this.data.ForecastVersionId;
    }
    this.viewIndex = this.data.Tab || ScenarioDialogViewIndex.general;
    this.initialized = true;
  }

  public changeView(index: number) {
    this.viewIndex = index;
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public save() {
    this.pending = true;
    this.scenario.Item.ScenarioVariables = this.scenario.Item._tempVariables.map(x => x.Item);
    const promise = (this.saved || !this.scenario.isModified) ? this.triggerScenario() : (this.edit ? this.update() : this.create());
    promise.finally(() => {
      this.pending = false;
      this.saved = true;
    });
  }

  private update() {
    return this.service.updateScenario(this.scenario.Item)
      .then(scenario => this.handleSave(scenario))
      .then(() => this.statusService.setMessage('Scenario saved', 'Success', true))
      .catch(error => this.statusService.setError(error, true));
  }

  private create() {
    return this.service.createNewScenario(this.scenario.Item)
      .then(scenario => this.handleSave(scenario))
      .then(() => {
        this.statusService.setMessage('Scenario created', 'Success', true);
        this.edit = true;
      })
      .catch(error => this.statusService.setError(error, true));
  }

  private triggerScenario() {
    return this.service.triggerScenario(this.scenario.Item)
      .then(() => {
        this.statusService.setMessage(`Running scenario ${this.scenario.Item.Name}`, 'Info');
        this.close();
      })
      .catch(error => this.statusService.setError(error, true));
  }


  private getScenario() {
    return this.service.getOrFetchScenario(this.data.ForecastVersionId, this.data.ScenarioId)
      .then(scenario => {
        this.mapTempVariables(scenario);
        return scenario;
      })
      .catch((e) => { this.statusService.setError(e); return null; });
  }

  private handleSave(scenario: ScenarioDTO) {
    this.scenario = new ItemState<ScenarioDTO>().set(scenario);
    this.mapTempVariables(scenario);
  }

  private mapTempVariables(scenario: ScenarioDTO) {
    scenario._tempVariables = scenario.ScenarioVariables.map(x => (new ItemState<ScenarioVariableDTO>().set(x)));
  }

}
