export class SvgSizes {

  public PlotHeight: number = undefined;
  public PlotWidth: number = undefined;

  public ContainerWidth: number = 0;
  public ContainerHeight: number = 0;

  public ALGMarginTop: number = 0;
  public ALGMarginLeft: number = 0;
  public ALGMarginRight: number = 0;
  public ALGMarginBottom: number = 0;

  public GraphMarginLeft: number = 45;
  public GraphMarginTop: number = 0;
  public GraphMarginRight: number = 0;
  public GraphMarginBottom: number = 45;

  public ChartBorderSize: number = 4;
  public ChartBorderRadius: number = 8;
  public ChartMarginRight: number = 0;

  public ButtonGroupWidth: number = 0;

  public XAxisWidth: number = 25;
  public YAxisHeight: number = 40;
  public IconSize: number = 45;
  public MinDistanceFromTop: number = 35;
  public SVGMargins = { top: 0, right: 8, bottom: 0, left: 0 };
  public Buttons = { big: 100, medium: 45, small: 30, height: 28, radius: 4, marginTop: 5 };
  public MoreMenue = { width: 155, height: 28, marginLeft: 10 };
  public Hover = { margin: 5, fieldHeight: 27, radius: 20, distanceToEdge: 40 };

  public getSvgWidth() { return this.ContainerWidth - this.ALGMarginLeft - this.ALGMarginRight - this.ChartMarginRight; }
  public getSvgHeight() { return this.ContainerHeight - this.ALGMarginTop - this.ALGMarginBottom; }
  public getBorderX() { return this.GraphMarginLeft; }
  public getBorderY() { return this.GraphMarginTop; }
  public getBorderWidth() { return this.getSvgWidth() - this.GraphMarginLeft - this.GraphMarginRight; }
  public getBorderHeight() { return this.getSvgHeight() - this.GraphMarginBottom - this.GraphMarginTop; }
  public getGraphX() { return this.getBorderX() + this.ChartBorderSize / 2; }
  public getGraphY() { return this.getBorderY() + this.ChartBorderSize / 2; }
  public getGraphWidth() { return this.getBorderWidth() - this.ChartBorderSize; }
  public getGraphHeight() { return this.getBorderHeight() - this.ChartBorderSize; }
  public getInnerRadius() {
    return (this.ChartBorderRadius - this.ChartBorderSize / 2) < 0
      ? 0
      : this.ChartBorderRadius - this.ChartBorderSize / 2;
  }

  public updateFromExternal(styles: any) {
    if (styles.borderSize) { this.ChartBorderSize = styles.borderSize; }
    if (styles.borderRadius) { this.ChartBorderRadius = styles.borderRadius; }
    if (styles.marginRight) { this.ChartMarginRight = styles.marginRight; }
    if (styles.plotHeight) { this.PlotHeight = styles.plotHeight; }
    if (styles.plotWidth) { this.PlotWidth = styles.plotWidth; }
  }

  /**
   *
   * Mouse helpers
   *
   */
  public mouseYToGraphY(mouseY: number) {
    const totalTrans = this.SVGMargins.top + this.getGraphY() + this.ChartBorderSize;
    return mouseY - totalTrans;
  }
  public mouseXToGraphX(mouseX: number) {
    const totalTrans = this.SVGMargins.left + this.getGraphX() + this.ChartBorderSize;
    return mouseX - totalTrans;
  }
}
