import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';

// Do not add any indicio-code to this service, as it must be usable everywhere in the
// code and therfore, cannot have any dependencies
// Adding 3rd party deps is fine
@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor(
    private actions: Actions
  ) {
  }

  public dispatched(...types: any[]) {
    return this.actions.pipe(ofActionDispatched(...types));
  }
}
