import { PlotValue } from '@core/entities/dtos/plot-value-dto';
import { ValueUtils } from '@shared/utils/value.utils';
import { ExcelCell } from './excel.entities';

export class ExcelBaseGenerator {
  public createCell(value: string | number, backgroundColor?: string, fontStyle?: object) {
    return <ExcelCell> {
      Value: value,
      BG: backgroundColor,
      FontStyle: fontStyle
    };
  }

  public getPlotValue(value: PlotValue, key: 'V' | 'F' = 'V', isPercentage = false) {
    const v = value[key] != null ? value[key] : null;
    return isPercentage ? `${(ValueUtils.round(v * 100, 3)).toString()}%` : v;
  }

  public getValue(value: string, isPercentage = false) {
    return isPercentage ? `${value}%` : value;
  }
}
