import { Injectable } from '@angular/core';
import { IMemberModel } from '@core/interfaces/if-member';
import { HttpService } from '@core/services/http/http.service';
import { ProjectInviteDTO } from '@core/store/invites/dtos/project-invite-dto';
import { InviteMapper } from '@core/store/invites/invite-mapper';
import { ProjectDTO } from '@core/store/project/dtos/project-dto';
import { ProjectMemberDTO } from '@core/store/project/dtos/project-member-dto';
import { ProjectMapper } from '@core/store/project/project-mapper';
import { ProjectModel } from '@core/store/project/project.model';
import { CreateProjectDTO } from './dtos/create-project-dto';


@Injectable({
  providedIn: 'root'
})
export class ProjectBackendService {

  constructor(
    private http: HttpService,
    private projectMapper: ProjectMapper,
    private inviteMapper: InviteMapper
  ) { }


  ////
  //// Project part
  ////
  public getProjects(companyId: string): Promise<ProjectModel[]> {
    return this.http.get<ProjectDTO[]>(`company/${companyId}/projects`)
      .then(resp => resp.body.map(p => this.projectMapper.map(p)));
  }
  public getProject(companyId: string, projectId: string): Promise<ProjectModel> {
    return this.http.get<ProjectDTO>(`company/${companyId}/projects/${projectId}`)
      .then(resp => this.projectMapper.map(resp.body));
  }

  public createProject(companyId: string, dto: CreateProjectDTO) {
    return this.http.post<ProjectDTO>(`company/${companyId}/projects`, dto)
      .then(resp => this.projectMapper.map(resp.body));
  }

  public updateProject(companyId: string, project: ProjectModel) {
    const dto = this.projectMapper.toUpdate(project);
    return this.http.put<ProjectDTO>(`company/${companyId}/projects/${project.ProjectId}`, dto)
      .then(response => this.projectMapper.map(response.body));
  }

  public deleteProject(companyId: string, projectId: string) {
    return this.http.delete<null>(`company/${companyId}/projects/${projectId}`);
  }

  ////
  //// Members part
  ////
  public getProjectMembers(companyId: string, projectId: string) {
    return this.http.get<ProjectMemberDTO[]>(`company/${companyId}/projects/${projectId}/members`)
      .then(resp => resp.body.map(m => this.projectMapper.mapMember(m)));
  }

  public inviteProjectMembers(companyId: string, projectId: string, invites: IMemberModel[]) {
    const dtos = invites.map(i => this.inviteMapper.toProjectInviteDTO(i));
    return this.http.post<ProjectInviteDTO[]>(`company/${companyId}/projects/${projectId}/invite`, dtos)
      .then(invited => invited.body.map(i => this.inviteMapper.toProjectInvite(companyId, i)));
  }

  public removeProjectMember(companyId: string, projectId: string, email: string) {
    return this.http.delete<null>(`company/${companyId}/projects/${projectId}/members/${email}`)
      .then(() => email);
  }

  ////
  //// Special
  ////
  public transferOwnership(project: ProjectModel, newOwnerEmail) {
    const dto = { Email: newOwnerEmail };
    return this.http.post<ProjectMemberDTO[]>(`company/${project.CompanyId}/projects/${project.ProjectId}/transfer-owner`, dto)
      .then(resp => resp.body.map(m => this.projectMapper.mapMember(m)));
  }

  public leaveProject(companyId: string, projectId: string) {
    return this.http.post<null>(`company/${companyId}/projects/${projectId}/leave-project`, null);
  }
}
