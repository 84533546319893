import { RefinitivModalOpts } from './refinitiv-modal.options';

export class OpenRefinitivModal {
  public static readonly type = '[MODAL] Open refinitiv modal';
  data: RefinitivModalOpts;
  constructor(
    forecastVersionId: string,
    edit?: boolean
  ) {
    this.data = { forecastVersionId, edit };
  }
}
