import { DisplayValue } from '../types/display-value';

export class Currency extends DisplayValue<string> { };

export const Currencies = [
  new Currency('ATS', 'Austrian schilling', 'ATS - Austrian schilling'),
  new Currency('BEF', 'Belgian franc', 'BEF - Belgian franc'),
  new Currency('EEK', 'Estonian kroon', 'EEK - Estonian kroon'),
  new Currency('ESP', 'Spanish peseta', 'ESP - Spanish peseta'),
  new Currency('FIM', 'Finnish markka', 'FIM - Finnish markka'),
  new Currency('FRF', 'French franc', 'FRF - French franc'),
  new Currency('GRD', 'Greek drachma', 'GRD - Greek drachma'),
  new Currency('IEP', 'Irish pound', 'IEP - Irish pound'),
  new Currency('ITL', 'Italian lira', 'ITL - Italian lira'),
  new Currency('LUF', 'Luxembourgish franc', 'LUF - Luxembourgish franc'),
  new Currency('MTL', 'Maltese lira', 'MTL - Maltese lira'),
  new Currency('NLG', 'Dutch guilder', 'NLG - Dutch guilder'),
  new Currency('PTE', 'Portuguese escudo', 'PTE - Portuguese escudo'),
  new Currency('AED', 'United Arab Emirates Dirham', 'AED - United Arab Emirates Dirham'),
  new Currency('AFN', 'Afghanistan Afghani', 'AFN - Afghanistan Afghani'),
  new Currency('ALL', 'Albania Lek', 'ALL - Albania Lek'),
  new Currency('AMD', 'Armenia Dram', 'AMD - Armenia Dram'),
  new Currency('ANG', 'Netherlands Antilles Guilder', 'ANG - Netherlands Antilles Guilder'),
  new Currency('AOA', 'Angola Kwanza', 'AOA - Angola Kwanza'),
  new Currency('ARS', 'Argentina Peso', 'ARS - Argentina Peso'),
  new Currency('AUD', 'Australia Dollar', 'AUD - Australia Dollar'),
  new Currency('AWG', 'Aruba Guilder', 'AWG - Aruba Guilder'),
  new Currency('AZN', 'Azerbaijan Manat', 'AZN - Azerbaijan Manat'),
  new Currency('BAM', 'Bosnia and Herzegovina Convertible Marka', 'BAM - Bosnia and Herzegovina Convertible Marka'),
  new Currency('BBD', 'Barbados Dollar', 'BBD - Barbados Dollar'),
  new Currency('BDT', 'Bangladesh Taka', 'BDT - Bangladesh Taka'),
  new Currency('BGN', 'Bulgaria Lev', 'BGN - Bulgaria Lev'),
  new Currency('BHD', 'Bahrain Dinar', 'BHD - Bahrain Dinar'),
  new Currency('BIF', 'Burundi Franc', 'BIF - Burundi Franc'),
  new Currency('BMD', 'Bermuda Dollar', 'BMD - Bermuda Dollar'),
  new Currency('BND', 'Brunei Darussalam Dollar', 'BND - Brunei Darussalam Dollar'),
  new Currency('BOB', 'Bolivia Bolíviano', 'BOB - Bolivia Bolíviano'),
  new Currency('BRL', 'Brazil Real', 'BRL - Brazil Real'),
  new Currency('BSD', 'Bahamas Dollar', 'BSD - Bahamas Dollar'),
  new Currency('BTN', 'Bhutan Ngultrum', 'BTN - Bhutan Ngultrum'),
  new Currency('BWP', 'Botswana Pula', 'BWP - Botswana Pula'),
  new Currency('BYN', 'Belarus Ruble', 'BYN - Belarus Ruble'),
  new Currency('BZD', 'Belize Dollar', 'BZD - Belize Dollar'),
  new Currency('CAD', 'Canada Dollar', 'CAD - Canada Dollar'),
  new Currency('CDF', 'Congo/Kinshasa Franc', 'CDF - Congo/Kinshasa Franc'),
  new Currency('CHF', 'Switzerland Franc', 'CHF - Switzerland Franc'),
  new Currency('CLP', 'Chile Peso', 'CLP - Chile Peso'),
  new Currency('CNY', 'China Yuan Renminbi', 'CNY - China Yuan Renminbi'),
  new Currency('COP', 'Colombia Peso', 'COP - Colombia Peso'),
  new Currency('CRC', 'Costa Rica Colon', 'CRC - Costa Rica Colon'),
  new Currency('CUC', 'Cuba Convertible Peso', 'CUC - Cuba Convertible Peso'),
  new Currency('CUP', 'Cuba Peso', 'CUP - Cuba Peso'),
  new Currency('CVE', 'Cape Verde Escudo', 'CVE - Cape Verde Escudo'),
  new Currency('CZK', 'Czech Republic Koruna', 'CZK - Czech Republic Koruna'),
  new Currency('DEM', 'Deutsche Mark', 'DEM - Deutsche Mark'),
  new Currency('DJF', 'Djibouti Franc', 'DJF - Djibouti Franc'),
  new Currency('DKK', 'Denmark Krone', 'DKK - Denmark Krone'),
  new Currency('DOP', 'Dominican Republic Peso', 'DOP - Dominican Republic Peso'),
  new Currency('DZD', 'Algeria Dinar', 'DZD - Algeria Dinar'),
  new Currency('EGP', 'Egypt Pound', 'EGP - Egypt Pound'),
  new Currency('ERN', 'Eritrea Nakfa', 'ERN - Eritrea Nakfa'),
  new Currency('ETB', 'Ethiopia Birr', 'ETB - Ethiopia Birr'),
  new Currency('EUR', 'Euro Member Countries', 'EUR - Euro Member Countries'),
  new Currency('FJD', 'Fiji Dollar', 'FJD - Fiji Dollar'),
  new Currency('FKP', 'Falkland Islands (Malvinas) Pound', 'FKP - Falkland Islands (Malvinas) Pound'),
  new Currency('GBP', 'United Kingdom Pound', 'GBP - United Kingdom Pound'),
  new Currency('GEL', 'Georgia Lari', 'GEL - Georgia Lari'),
  new Currency('GGP', 'Guernsey Pound', 'GGP - Guernsey Pound'),
  new Currency('GHS', 'Ghana Cedi', 'GHS - Ghana Cedi'),
  new Currency('GIP', 'Gibraltar Pound', 'GIP - Gibraltar Pound'),
  new Currency('GMD', 'Gambia Dalasi', 'GMD - Gambia Dalasi'),
  new Currency('GNF', 'Guinea Franc', 'GNF - Guinea Franc'),
  new Currency('GTQ', 'Guatemala Quetzal', 'GTQ - Guatemala Quetzal'),
  new Currency('GYD', 'Guyana Dollar', 'GYD - Guyana Dollar'),
  new Currency('HKD', 'Hong Kong Dollar', 'HKD - Hong Kong Dollar'),
  new Currency('HNL', 'Honduras Lempira', 'HNL - Honduras Lempira'),
  new Currency('HRK', 'Croatia Kuna', 'HRK - Croatia Kuna'),
  new Currency('HTG', 'Haiti Gourde', 'HTG - Haiti Gourde'),
  new Currency('HUF', 'Hungary Forint', 'HUF - Hungary Forint'),
  new Currency('IDR', 'Indonesia Rupiah', 'IDR - Indonesia Rupiah'),
  new Currency('ILS', 'Israel Shekel', 'ILS - Israel Shekel'),
  new Currency('IMP', 'Isle of Man Pound', 'IMP - Isle of Man Pound'),
  new Currency('INR', 'India Rupee', 'INR - India Rupee'),
  new Currency('IQD', 'Iraq Dinar', 'IQD - Iraq Dinar'),
  new Currency('IRR', 'Iran Rial', 'IRR - Iran Rial'),
  new Currency('ISK', 'Iceland Krona', 'ISK - Iceland Krona'),
  new Currency('JEP', 'Jersey Pound', 'JEP - Jersey Pound'),
  new Currency('JMD', 'Jamaica Dollar', 'JMD - Jamaica Dollar'),
  new Currency('JOD', 'Jordan Dinar', 'JOD - Jordan Dinar'),
  new Currency('JPY', 'Japan Yen', 'JPY - Japan Yen'),
  new Currency('KES', 'Kenya Shilling', 'KES - Kenya Shilling'),
  new Currency('KGS', 'Kyrgyzstan Som', 'KGS - Kyrgyzstan Som'),
  new Currency('KHR', 'Cambodia Riel', 'KHR - Cambodia Riel'),
  new Currency('KMF', 'Comorian Franc', 'KMF - Comorian Franc'),
  new Currency('KPW', 'Korea (North) Won', 'KPW - Korea (North) Won'),
  new Currency('KRW', 'Korea (South) Won', 'KRW - Korea (South) Won'),
  new Currency('KWD', 'Kuwait Dinar', 'KWD - Kuwait Dinar'),
  new Currency('KYD', 'Cayman Islands Dollar', 'KYD - Cayman Islands Dollar'),
  new Currency('KZT', 'Kazakhstan Tenge', 'KZT - Kazakhstan Tenge'),
  new Currency('LAK', 'Laos Kip', 'LAK - Laos Kip'),
  new Currency('LBP', 'Lebanon Pound', 'LBP - Lebanon Pound'),
  new Currency('LKR', 'Sri Lanka Rupee', 'LKR - Sri Lanka Rupee'),
  new Currency('LRD', 'Liberia Dollar', 'LRD - Liberia Dollar'),
  new Currency('LSL', 'Lesotho Loti', 'LSL - Lesotho Loti'),
  new Currency('LYD', 'Libya Dinar', 'LYD - Libya Dinar'),
  new Currency('MAD', 'Morocco Dirham', 'MAD - Morocco Dirham'),
  new Currency('MDL', 'Moldova Leu', 'MDL - Moldova Leu'),
  new Currency('MGA', 'Madagascar Ariary', 'MGA - Madagascar Ariary'),
  new Currency('MKD', 'Macedonia Denar', 'MKD - Macedonia Denar'),
  new Currency('MMK', 'Myanmar (Burma) Kyat', 'MMK - Myanmar (Burma) Kyat'),
  new Currency('MNT', 'Mongolia Tughrik', 'MNT - Mongolia Tughrik'),
  new Currency('MOP', 'Macau Pataca', 'MOP - Macau Pataca'),
  new Currency('MRO', 'Mauritania Ouguiya', 'MRO - Mauritania Ouguiya'),
  new Currency('MUR', 'Mauritius Rupee', 'MUR - Mauritius Rupee'),
  new Currency('MVR', 'Maldives Rufiyaa', 'MVR - Maldives Rufiyaa'),
  new Currency('MWK', 'Malawi Kwacha', 'MWK - Malawi Kwacha'),
  new Currency('MXN', 'Mexico Peso', 'MXN - Mexico Peso'),
  new Currency('MYR', 'Malaysia Ringgit', 'MYR - Malaysia Ringgit'),
  new Currency('MZN', 'Mozambique Metical', 'MZN - Mozambique Metical'),
  new Currency('NAD', 'Namibia Dollar', 'NAD - Namibia Dollar'),
  new Currency('NGN', 'Nigeria Naira', 'NGN - Nigeria Naira'),
  new Currency('NIO', 'Nicaragua Cordoba', 'NIO - Nicaragua Cordoba'),
  new Currency('NOK', 'Norway Krone', 'NOK - Norway Krone'),
  new Currency('NPR', 'Nepal Rupee', 'NPR - Nepal Rupee'),
  new Currency('NZD', 'New Zealand Dollar', 'NZD - New Zealand Dollar'),
  new Currency('OMR', 'Oman Rial', 'OMR - Oman Rial'),
  new Currency('PAB', 'Panama Balboa', 'PAB - Panama Balboa'),
  new Currency('PEN', 'Peru Sol', 'PEN - Peru Sol'),
  new Currency('PGK', 'Papua New Guinea Kina', 'PGK - Papua New Guinea Kina'),
  new Currency('PHP', 'Philippines Peso', 'PHP - Philippines Peso'),
  new Currency('PKR', 'Pakistan Rupee', 'PKR - Pakistan Rupee'),
  new Currency('PLN', 'Poland Zloty', 'PLN - Poland Zloty'),
  new Currency('PYG', 'Paraguay Guarani', 'PYG - Paraguay Guarani'),
  new Currency('QAR', 'Qatar Riyal', 'QAR - Qatar Riyal'),
  new Currency('RON', 'Romania Leu', 'RON - Romania Leu'),
  new Currency('RSD', 'Serbia Dinar', 'RSD - Serbia Dinar'),
  new Currency('RUB', 'Russia Ruble', 'RUB - Russia Ruble'),
  new Currency('RWF', 'Rwanda Franc', 'RWF - Rwanda Franc'),
  new Currency('SAR', 'Saudi Arabia Riyal', 'SAR - Saudi Arabia Riyal'),
  new Currency('SBD', 'Solomon Islands Dollar', 'SBD - Solomon Islands Dollar'),
  new Currency('SCR', 'Seychelles Rupee', 'SCR - Seychelles Rupee'),
  new Currency('SDG', 'Sudan Pound', 'SDG - Sudan Pound'),
  new Currency('SEK', 'Sweden Krona', 'SEK - Sweden Krona'),
  new Currency('SGD', 'Singapore Dollar', 'SGD - Singapore Dollar'),
  new Currency('SHP', 'Saint Helena Pound', 'SHP - Saint Helena Pound'),
  new Currency('SLL', 'Sierra Leone Leone', 'SLL - Sierra Leone Leone'),
  new Currency('SOS', 'Somalia Shilling', 'SOS - Somalia Shilling'),
  new Currency('SPL', 'Seborga Luigino', 'SPL - Seborga Luigino'),
  new Currency('SRD', 'Suriname Dollar', 'SRD - Suriname Dollar'),
  new Currency('STD', 'São Tomé and Príncipe Dobra', 'STD - São Tomé and Príncipe Dobra'),
  new Currency('SVC', 'El Salvador Colon', 'SVC - El Salvador Colon'),
  new Currency('SYP', 'Syria Pound', 'SYP - Syria Pound'),
  new Currency('SZL', 'Swaziland Lilangeni', 'SZL - Swaziland Lilangeni'),
  new Currency('THB', 'Thailand Baht', 'THB - Thailand Baht'),
  new Currency('TJS', 'Tajikistan Somoni', 'TJS - Tajikistan Somoni'),
  new Currency('TMT', 'Turkmenistan Manat', 'TMT - Turkmenistan Manat'),
  new Currency('TND', 'Tunisia Dinar', 'TND - Tunisia Dinar'),
  new Currency('TOP', 'Tonga Pa`anga', 'TOP - Tonga Pa`anga'),
  new Currency('TRY', 'Turkey Lira', 'TRY - Turkey Lira'),
  new Currency('TTD', 'Trinidad and Tobago Dollar', 'TTD - Trinidad and Tobago Dollar'),
  new Currency('TVD', 'Tuvalu Dollar', 'TVD - Tuvalu Dollar'),
  new Currency('TWD', 'Taiwan New Dollar', 'TWD - Taiwan New Dollar'),
  new Currency('TZS', 'Tanzania Shilling', 'TZS - Tanzania Shilling'),
  new Currency('UAH', 'Ukraine Hryvnia', 'UAH - Ukraine Hryvnia'),
  new Currency('UGX', 'Uganda Shilling', 'UGX - Uganda Shilling'),
  new Currency('USD', 'United States Dollar', 'USD - United States Dollar'),
  new Currency('UYU', 'Uruguay Peso', 'UYU - Uruguay Peso'),
  new Currency('UZS', 'Uzbekistan Som', 'UZS - Uzbekistan Som'),
  new Currency('VEF', 'Venezuela Bolívar', 'VEF - Venezuela Bolívar'),
  new Currency('VND', 'Viet Nam Dong', 'VND - Viet Nam Dong'),
  new Currency('VUV', 'Vanuatu Vatu', 'VUV - Vanuatu Vatu'),
  new Currency('WST', 'Samoa Tala', 'WST - Samoa Tala'),
  new Currency('XAF', 'CFA Franc BEAC', 'XAF - CFA Franc BEAC'),
  new Currency('XCD', 'East Caribbean Dollar', 'XCD - East Caribbean Dollar'),
  new Currency('XDR', 'IMF Special Drawing Rights', 'XDR - IMF Special Drawing Rights'),
  new Currency('XOF', 'BCEAO Franc', 'XOF - BCEAO Franc'),
  new Currency('XPF', 'CFP Franc', 'XPF - CFP Franc'),
  new Currency('YER', 'Yemen Rial', 'YER - Yemen Rial'),
  new Currency('ZAR', 'South Africa Rand', 'ZAR - South Africa Rand'),
  new Currency('ZMW', 'Zambia Kwacha', 'ZMW - Zambia Kwacha'),
  new Currency('ZWD', 'Zimbabwe Dollar', 'ZWD - Zimbabwe Dollar')
];
