<indicio-dialog>
  <h1 mat-dialog-title>Code import from Macrobond</h1>

  <div mat-dialog-content
       class="macrobond-paste-import-container"
       *ngIf="initialized">

    <h3>
      Codes to import
      <div class="subtitle">
        A list of codes to import from Macrobond. Can be separated by commas, semicolons or new lines. A code is
        sometimes referred to as <span class="italic">PrimaryName</span> or <span
              class="italic">MacrobondSeriesId</span>.
      </div>
    </h3>

    <textarea class="extra-margin-top"
              #content
              [(ngModel)]="variableList"
              (paste)="handlePaste($event)"
              (blur)="parseVariableList()"
              name="variables"
              cols="15"
              rows="10"></textarea>
    <button mat-button
            (click)="parseVariableList()">Verify list</button>

    <ng-container *ngIf="variableIds.length">
      <hr>
      <p>Preview of the codes to import and their status.</p>
      <indicio-data-table class="margin-top"
                          *ngIf="variableStatus.size"
                          [columnTemplate]="variableRevisions.size ?  '50px 1fr 170px' : '50px 1fr'">
        <div title-row>
          <div column><span title>#</span></div>
          <div column
               left><span title>Code</span></div>
          <div *ngIf="variableRevisions.size"
               column
               left><span title>Revision</span></div>
        </div>
        <div style="max-height: 150px; overflow: auto;">
          <ng-container *ngFor="let id of variableIds; let index = index">
            <div row>
              <div column
                   class="center-vertical"
                   style="justify-content: center;"><span>{{ index + 1 }}</span></div>
              <div class="center-vertical crop-string"
                   [class]="variableStatus.get(id) ? 'green' : 'red'"
                   column>
                <span [title]="id"
                      style="margin-right: 5px;">
                  {{ id }}
                </span>
                <i [ngClass]="{ 'ion-checkmark-circled' : variableStatus.get(id), 'ion-close-circled': !variableStatus.get(id), 'orange': variableCodes.get(id) && variableCodes.get(id) !== id }"
                   [indicioTippy]="titleContent(id)"></i>
              </div>
              <div *ngIf="variableRevisions.size"
                   column
                   class="center-vertical crop-string"
                   [title]="variableRevisions[index] || ''">
                {{ variableRevisions.get(id) || (variableStatus.get(id) ? 'Latest' : 'Skipping') }}
              </div>
            </div>
          </ng-container>
        </div>
      </indicio-data-table>

      <div class="note"
           *ngIf="variableRevisions.size">
        Revisions are not currently supported. <br> Only the latest revision will be imported.
      </div>
    </ng-container>


  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Cancel</button>
    <div [indicioTippy]="!variableIds.length ? 'Need at least one code to proceed' : null">
      <button mat-flat-button
              [disabled]="!variableIds.length"
              color="primary"
              (click)="importVariables()">
        <span>Use these codes</span>
      </button>
    </div>
  </div>
</indicio-dialog>
