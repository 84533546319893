import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

class Position { X: number; Y: number; }

export abstract class DialogV2BaseDialog<T> {

  /** Used for CTA button loaders/spinners */
  public inProgress: boolean;

  /** Used during init of the dialogs, must be set to true */
  public initialized: boolean = false;

  /** Current position, used when repositioning the dialog. */
  private position: Position;

  /** Current move-delta, the amount of X and Y to move the dialog during the next redraw. */
  private moveDelta: Position;

  /** A subject, used to destroy subscriptions on elements during drag-and-drop. */
  private destroy$ = new Subject<void>();

  /** The native element for this dialog. */
  private ne: HTMLElement;

  /** Subscription holder for all dialogs -  */
  protected subsription = new Subscription();

  constructor(
    protected dialogRef: MatDialogRef<T>
  ) {
    this.dialogRef.afterOpened().pipe(take(1)).toPromise().then(() => this.initialize_base());
    this.dialogRef.beforeClosed().pipe(take(1)).toPromise().then(() => {
      this.subsription.unsubscribe();
      this.destroy$.next();
    });
  }

  public close() {
    this.dialogRef.close();
  }

  /**
  * Returns the native element for the dialog
  * @returns
  */
  protected getDialogNativeElement(): HTMLElement {
    const elementRef = this.dialogRef._containerInstance['_elementRef'];
    return elementRef.nativeElement;
  }

  /** Child components must implement this function, to remind them that they must set initialized to true. */
  protected abstract initialize();


  /**
   * Initialize the dialog.
   *  - Setup initial position,
   *  - Setup drag
   *  - Setup element refs
   */
  private initialize_base() {
    this.ne = this.getDialogNativeElement();
    this.setInitialPosition();
    this.setupDrag();
  }

  /**
   * Set the position of the dialog right after it was opened.
   */
  private setInitialPosition() {
    const rect = this.ne.getBoundingClientRect();
    this.position = { X: rect.left, Y: rect.top };
    this.moveDelta = { X: 0, Y: 0 };
  }

  /**
   * Setup the drag functions and events to handle drag-and-drop of the dialog
   */
  private setupDrag() {
    const ne = this.getDialogNativeElement();
    const title = ne.querySelector('[mat-dialog-title]') as HTMLElement;
    if (!title) { return; }
    title.style.cursor = 'move';

    const { mousedown$, mousemove$, mouseup$ } = this.getDragEvents(title);
    const mousedrag$ = this.getMouseDrag(mousedown$, mousemove$, mouseup$);

    mousedrag$.subscribe(() => {
      if (this.moveDelta.X === 0 && this.moveDelta.Y === 0) { return; }
      this.updatePosition();
    });

    mouseup$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.position.X += this.moveDelta.X;
      this.position.Y += this.moveDelta.Y;
      this.moveDelta = { X: 0, Y: 0 };
    });
  }

  /**
   * Update the dialog with a new position, calculated from the current position and the moveDelta (if any).
   */
  private updatePosition() {
    requestAnimationFrame(() => {
      this.dialogRef.updatePosition({
        top: `${this.position.Y + this.moveDelta.Y}px`,
        left: `${this.position.X + this.moveDelta.X}px`,
      });
    });
  }

  private getDragEvents(dragHandle: HTMLElement) {
    const mousedown$ = fromEvent(dragHandle, 'mousedown');
    const mousemove$ = fromEvent(document, 'mousemove');
    const mouseup$ = fromEvent(document, 'mouseup');
    return { mousedown$, mousemove$, mouseup$ };
  }

  /**
   * Gets an observable that will emit a new observable every time the "mousedown" event is triggered.
   * @param mousedown$ The mousedown event to trigger on
   * @param mousemove$ The mouse move event that triggers on each mouse-movement, after mousedown has triggered
   * @param mouseup$ The mouse up event, when fired, will close the returned observable stream.
   * @returns
   */
  private getMouseDrag(
    mousedown$: Observable<Event>,
    mousemove$: Observable<Event>,
    mouseup$: Observable<Event>
  ) {
    return mousedown$.pipe(
      switchMap((event: MouseEvent) => {
        const startX = event.clientX;
        const startY = event.clientY;
        return mousemove$.pipe(
          map((e: MouseEvent) => {
            e.preventDefault();
            this.moveDelta = { X: e.clientX - startX, Y: e.clientY - startY };
          }),
          takeUntil(mouseup$),
        );
      }),
      takeUntil(this.destroy$),
    );
  }
}
