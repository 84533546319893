import { Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { MacrobondService } from '@core/store/providers/macrobond/macrobond.service';
import { ProviderDialogService } from '@dialogs/providers/provider-dialogs.service';
import { DialogV2BaseDialog } from '../../../base/dialogs.V2.base-dialog';
import { MacrobondImportPasteDialogData } from './import-paste.dialog.constants';

@Component({
  selector: 'indicio-macrobond-import-paste-dialog',
  templateUrl: 'import-paste.dialog.html',
  styleUrls: ['./import-paste.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class MacrobondImportPasteDialogComponent extends DialogV2BaseDialog<MacrobondImportPasteDialogComponent> {
  public static Id: string = 'MacrobondImportPasteDialogComponent';

  @ViewChild('content') content: ElementRef;

  public initialized = false;
  public fVersion: ForecastVersionModel;
  public variableList: string = '';
  public variableIds: string[] = [];
  public variableRevisions: Map<string, string> = new Map<string, string>();
  public variableCodes: Map<string, string> = new Map<string, string>();
  public variableStatus: Map<string, boolean> = new Map<string, boolean>();

  public titleContent(id: string) {
    if (this.variableCodes.get(id) && this.variableCodes.get(id) === id) {
      return 'Variable found';
    } else if (this.variableCodes.get(id) && this.variableCodes.get(id) !== id) {
      return 'Variable found: ' + this.variableCodes.get(id);
    } else {
      return 'Variable not found';
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: MacrobondImportPasteDialogData,
    public dialogRef: MatDialogRef<MacrobondImportPasteDialogComponent, boolean>,
    private providerDialogs: ProviderDialogService,
    public service: MacrobondService,
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.initialized = true;
  }

  public handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const text = event.clipboardData.getData('text');

    const el = this.content.nativeElement;
    const [start, end] = [el.selectionStart, el.selectionEnd];
    const currentContent = this.variableList;
    const newContent = [currentContent.slice(0, start), text, currentContent.slice(end)].join('');

    this.variableList = newContent;
    this.parseVariableList();
  }

  public parseVariableList() {
    const varList = [...new Set(this.variableList.split(/[\n,;]/).map(x => x.trim()))].filter(x => x);
    this.variableStatus.clear();
    this.variableRevisions.clear();

    varList.forEach(async (code, index) => {
      if (code.includes('{')) {
        const codeWithoutRevision = code.substring(0, code.indexOf('{')).trim();
        this.variableRevisions.set(codeWithoutRevision, code.substring(code.indexOf('{') + 1, code.indexOf('}')).trim());
        varList[index] = codeWithoutRevision;
        code = codeWithoutRevision;
      }

      const entityInfo = await this.service.getEntityInfo(code).catch(() => { });
      this.variableStatus.set(code, !!entityInfo);
      if (entityInfo) {
        this.variableCodes.set(code, entityInfo.getGroup('Time series')?.getInfo('Name')?.value);
      }
    });

    this.variableIds = varList;
  }


  public onNoClick(): void {
    this.dialogRef.close();
  }

  public importVariables() {
    this.providerDialogs.openMacrobondImportMultipleDialog({
      forecastVersionId: this.data.forecastVersionId,
      variableIds: this.variableIds.filter(x => this.variableStatus.get(x)), // Only import variables that have been found
      variableRevisions: this.variableRevisions
    });

    this.dialogRef.close();
  }
}
