<indicio-modal-base [child]="this"
                    modalClass=""
                    [loading]="isLoading"
                    [modalTitle]="nowcastMainToFullPeriod ? 'Create nowcast?' : 'Nowcasting'">

  <div *ngIf="!isLoading"
       style="width: 600px; margin: auto;">
    <ng-container *ngIf="!!nowcastOptions; else missingOptsTemplate">
      <ng-container *ngIf="nowcastOptions.NeedNowcast; else nowcastNotNeededTemplate">
        <div class="unselectable">
          <div class="row">
            <div class="col-xs-12"
                 style="margin: 20px 0; font-weight: 500;">

              <ng-container *ngIf="!nowcastMainToFullPeriod">
                This variable needs to be extended with more data
                since its last date occurs before the forecasting start date.
              </ng-container>

              <ng-container *ngIf="nowcastMainToFullPeriod">
                This variables data include values that have been removed since they cannot be aggregated
                to the target periodicity ({{this.forecast.periodicity.Display}}).
                <br>
                If you want to include all data, the missing value points must be extended.
              </ng-container>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!nowcastOptions.CanNowcast">
          <div class="unselectable">
            <div class="row">
              <div class="col-xs-12 red"
                   style="margin: 1px 0; font-weight: 500;">
                {{ nowcastOptions.Message }}
              </div>
            </div>
          </div>

          <div class="row extra-margin-top">
            <div class="col-xs-6">
              <button class="btn outline blue"
                      (click)="close()">
                Close
              </button>
            </div>
            <div class="col-xs-6">
              <button class="btn danger pull-right"
                      (click)="removeFvar()">
                Remove variable
              </button>
            </div>
          </div>

        </ng-container>

        <ng-container *ngIf="nowcastOptions.CanNowcast">
          <indicio-foldable-row title="Create nowcast"
                                [open]="nowcastOpen">
            <ng-container *ngIf="nowcastCanBeCreated && variable && forecast && forecastVersion">
              <ng-container *ngTemplateOutlet="mainTemplate">
              </ng-container>
            </ng-container>
          </indicio-foldable-row>

          <indicio-foldable-row title="Use existing forecast"
                                *ngIf="potentialForecasts.length"
                                [open]="potentialsOpen">
            <ng-container>
              <ng-container *ngTemplateOutlet="potentialsTemplate"></ng-container>
            </ng-container>
          </indicio-foldable-row>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</indicio-modal-base>

<ng-template #nowcastNotNeededTemplate>
  <div class="row">
    <div class="col-xs-12"
         style="margin: 20px 0 20px 0; font-weight: 500;">
      This variable needs to be extended with more data since its last date occurs before the forecasting start date.
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      However, the variable was created with old data, and can be refreshed. Click below to refresh the variable with
      new data.
    </div>
  </div>

  <div class="row"
       style="margin-top: 20px">
    <div class="col-xs-8"></div>
    <div class="col-xs-4">
      <button class="btn right"
              style="width: 149px; margin-bottom: 0; margin-top: 0"
              (click)="refreshVariableData()">
        <indicio-spinner *ngIf="inProgress"
                         color="var(--indicio-modal-text-color, black)"
                         [size]=12></indicio-spinner>
        {{ inProgress ? '' : 'Refresh data' }}
      </button>
    </div>
  </div>

</ng-template>

<ng-template #missingOptsTemplate>
  <div class="row">
    <div class="col-xs-12"
         style="margin: 20px 0 20px 0; font-weight: 500;">
      This variable cannot be nowcasted since it lacks a connection to a underlying source-variable.
      Please try to locate this variable in your source-variable library, and re-import the variable to the forecast.
    </div>
  </div>

  <div class="row extra-margin-top">
    <div class="col-xs-6">
      <button class="btn outline blue"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6">
      <button class="btn danger pull-right"
              (click)="removeFvar()">
        Remove variable
      </button>
    </div>
  </div>
</ng-template>

<ng-template #potentialsTemplate>
  <div class="data-table"
       style="width: 576px">
    <div class="data-table__header">
      <div class="column header left-align"
           style="width: 256px;">Forecast</div>
      <div class="column header"
           style="width: 170px;">Results</div>
      <div class="column header"
           style="width: 150px;">Use</div>
    </div>

    <div class="data-table__body"
         #potentionforecastscroll
         [style.maxHeight.px]="150">
      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngFor="let forecast of potentialForecasts; let i = index">
        <div class="column left-align pointer"
             style="width: 256px; line-height: 15px;"
             (click)="navToForecast(forecast)">
          <div style="display: inline-flex; width: 260px;">
            <div style="display: inline-flex;"
                 [indicioTippy]="forecast.ForecastName"
                 [scrollContainer]="potentionforecastscroll"
                 class="crop-string">
              {{ forecast.ForecastName }}
            </div>
            <i class="ion-android-exit"
               style="font-size: 15px; margin-left: 5px;"></i>
          </div>
        </div>
        <div class="column"
             style="width: 170px;">
          <ng-container *ngIf="!forecast.Results?.length">
            No results
          </ng-container>

          <ng-container *ngIf="forecast.Results?.length">
            <mat-form-field>
              <mat-select [(value)]="forecast.selectedResultId"
                          panelClass="data-table-panel-opts"
                          required>
                <mat-option value="fake"
                            title="Create new result">Create new result</mat-option>
                <mat-option [value]="result.SourceVariableMetaId"
                            [title]="result.ResultName"
                            *ngFor="let result of forecast.Results">{{ result.ResultName }}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
        <div class="column"
             style="width: 150px;">
          <button class="btn"
                  style="width: 110px;"
                  [class]="{ disabled: forecast.saveLoading }"
                  (click)="clickOnUse(forecast)">
            <i *ngIf="forecast.saveLoading"
               class="ion-load-c spinner"></i>
            {{ forecast.selectedResultId && forecast.selectedResultId !== 'fake' ? ' Use result' : ' Create result' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mainTemplate>
  <ng-container *ngIf="!nowcastMainToFullPeriod">
    <div class="row extra-margin-bottom unselectable">
      <div class="col-xs-12">Choose nowcasting periodicity:
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="nowcastMainToFullPeriod">
    <div class="row medium-margin-bottom">
      <div class="nowcast-modal-sep"></div>
      <div class="col-xs-12 unselectable">
        Nowcast
        <b>{{ mainNowcast.MissingDataPoints }}
          {{ mainNowcast.MissingDataPoints > 1 ? sourcePeriodicity.Value + 's' : sourcePeriodicity.Value }}
        </b>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">Variable last date:</div>
      <div class="col-xs-6 text-right">{{ mainNowcast.LastValueDate | date: appearance.DateFormat }}</div>
    </div>
    <div class="row">
      <div class="col-xs-6">Data needed until:</div>
      <div class="col-xs-6 text-right">{{ mainNowcast.RequiredValueDate | date: appearance.DateFormat }}</div>
      <div class="nowcast-modal-sep"></div>
    </div>
  </ng-container>

  <div *ngFor="let nowcastPeriodicity of nowcastOptions.Periodicities, let i = index">
    <ng-container
                  *ngTemplateOutlet="NowcastPeriodicity context: { periodicity: nowcastPeriodicity.Periodicity, lastValueDate: nowcastPeriodicity.LastValueDate, missingDataPoints: nowcastPeriodicity.MissingDataPoints, index: i }"></ng-container>
  </div>

  <div class="row">
    <div class="col-sm-12 col-xs-12">
      <label class="input-label-mini checkbox cursor-pointer unselectable h-grey"
             [class]="{ disabled: quick }"
             [indicioTippy]="quick ? 'A naive nowcast cannot include any extra variables.' : null"
             (click)="toggleIncludeData()">
        <i
           [ngClass]=" includeData ? 'ion-android-checkbox-outline blue' : 'ion-android-checkbox-outline-blank blue' "></i>
        Include variables from parent forecast
      </label>
    </div>
  </div>
  <div class="row"
       *ngIf="includeData">
    <div class="col-xs-12"
         style="background-color: rgb(222 192 101); font-size: 13px; padding-top: 5px; padding-bottom: 5px; color: black">
      Warning: Including variables from the parent forecast will increase the time it takes to nowcast.
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-xs-12">
      <label (click)="toggleQuick()"
             class="input-label-mini checkbox cursor-pointer unselectable h-grey"
             indicioTippy="If checked, the nowcast will only use a naive model.">
        <i [ngClass]=" quick ? 'ion-android-checkbox-outline blue' : 'ion-android-checkbox-outline-blank blue' "></i>
        Naive only
      </label>
    </div>
  </div>
  <ng-container *ngIf="forecastPoints > 10 && !hasTooManyForecastPoints">
    <div class="row">
      <div class="col-xs-12 margin-top">
        Warning: {{ forecastPoints }} forecasting points. Consider creating a forecast that starts closer to when
        your
        data ends to achieve a model with reasonable accuracy.
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="hasTooManyForecastPoints">
    <div class="row">
      <div class="col-xs-12 margin-top">
        <span class="red">Error</span>: Too many forecast points, max is {{ maxHorizon }}.
        Please change forecast start date.
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-xs-12">
      <span *ngIf="inProgress"><i class="ion-load-c spinner"></i> {{ status }}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 text-field"
         *ngIf="isMainVariable && !nowcastMainToFullPeriod">
      You can also change the date for when this forecast should start.
    </div>
  </div>

  <div class="row">
    <div class="col-xs-4">
    </div>
    <div class="col-xs-4 text-center">
      <!-- Empty -->
    </div>
    <div class="col-xs-4">
      <button class="btn right"
              data-test-id="create-nowcast-btn"
              style="width: 149px; margin-bottom: 0; margin-top: 0"
              [ngClass]="{'disabled': hasTooManyForecastPoints }"
              [disabled]="hasTooManyForecastPoints || !isOneOptionSelected"
              (click)="createNowcast()">
        Create Nowcast
      </button>
    </div>
  </div>
</ng-template>

<ng-template #NowcastPeriodicity
             let-periodicity=periodicity
             let-missingDataPoints=missingDataPoints
             let-lastValueDate=lastValueDate
             let-index=index>
  <div class="row medium-margin-bottom">
    <div class="col-xs-4 pointer h-grey a-darkgrey unselectable"
         [ngClass]="selectedPeriodicity.Periodicity === periodicity ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"
         (click)="pickNowcastPeriodicity(periodicity)">
      {{ getDisplayName(periodicity) }}
    </div>
    <div class="col-xs-8 h-grey unselectable cursor-pointer"
         (click)="showDetails[index] = !showDetails[index]">
      This will nowcast
      <b>{{ missingDataPoints }}
        {{ missingDataPoints > 1 ? periodicity + 's' : periodicity }}
      </b>
      {{ nowcastOptions.RecommendedPeriodicity === periodicity ? '(recommended)' : ''}}
      <i class="ion-information-circled blue info-icon"></i>
    </div>
  </div>
  <ng-container *ngIf="showDetails[index]">
    <div class="row">
      <div class="nowcast-modal-sep"></div>
      <div class="col-xs-6">Variable last date:</div>
      <div class="col-xs-6 text-right">{{ lastValueDate | date: appearance.DateFormat }}
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">First forecasted date:</div>
      <div class="col-xs-6 text-right">{{ forecastVersion.StartDate | date: appearance.DateFormat }}</div>
    </div>
    <div class="row">
      <div class="col-xs-6">Forecast periodicity:</div>
      <div class="col-xs-6 text-right">{{ forecast.periodicity.Display }}</div>
    </div>
    <div class="row medium-margin-top">
      <div class="col-xs-12">
        <ng-container [ngTemplateOutlet]="RecommendedPeriodicityText"></ng-container>
      </div>
      <div class="nowcast-modal-sep"></div>
    </div>
  </ng-container>
</ng-template>
<ng-template #RecommendedPeriodicityText>
  <div *ngIf="nowcastOptions.SourcePeriodicity === nowcastOptions.RecommendedPeriodicity">
    We recommend a <b>{{ nowcastOptions.recommendedPeriodicity.Display }}</b> periodicity for the nowcast,
    since it is the original periodicity, and therefore should yield the most accurate nowcast.
  </div>
  <div *ngIf="nowcastOptions.SourcePeriodicity !== nowcastOptions.RecommendedPeriodicity">
    We recommend a <b>{{ nowcastOptions.recommendedPeriodicity.Display }}</b> periodicity for the nowcast since there
    are too few data points to nowcast using the variable's original periodicity.
  </div>
</ng-template>
