<indicio-modal-base [child]="this"
                    modalTitle="File error"
                    [loading]="isLoading">

  <div class="row">
    <div class="col-xs-12">
      The file you tried to upload is not supported by Indicio. A file must contain at least one date column and one
      value column.
      <ul class="extra-margin-top"
          style="list-style-type:disc">
        <li>Values should only contain numbers and decimal separators.</li>
        <li>Dates must appear in ascending order.</li>
        <li>Data must appear in columns, not on rows.</li>
        <li>No macros may appear in excel files.</li>
        <li>Cross-referencing data between sheets is not supported.</li>
        <li>Series that extend before 1945 must have dates with 4-digit years.</li>
      </ul>

      <div class="extra-margin-top">
        Supported date formats are, among others:
      </div>

      <div class="row">
        <div class="col-xs-3">
          <ul style="list-style-type:disc">
            <li>2012-12-25</li>
            <li>2012/12/25</li>
            <li>25/12/2012</li>
          </ul>
        </div>
        <div class="col-xs-3">
          <ul style="list-style-type:disc">
            <li>2012:q4</li>
            <li>2012-q04</li>
            <li>q4-2012</li>
            <li>4q12</li>
          </ul>
        </div>
        <div class="col-xs-3">
          <ul style="list-style-type:disc">
            <li>2012-12</li>
            <li>2012-dec</li>
            <li>2012-m12</li>
          </ul>
        </div>
        <div class="col-xs-3">
          <ul style="list-style-type:disc">
            <li>Excel dates</li>
            <li>2012</li>
            <li>2012-h2</li>
          </ul>
        </div>
      </div>

      <indicio-wiki-modal-row slug="advanced-supported-date-formats"
                              title="Read more about the supported date formats"></indicio-wiki-modal-row>

      <div class="extra-margin-top">
        To solve this, please try the following:
      </div>

      <ul class="extra-margin-top"
          style="list-style-type:disc">
        <li>Copy the data you are interested in into a new file, making sure to copy the fields by value, not by
          reference.</li>
        <li>Follow the restrictions above.</li>
      </ul>

      <div class="extra-margin-top">
        If you still have problems after this, do not hesitate to contact our customer support.
      </div>

      <div class="row extra-margin-top asd">
        <div class="col-xs-6">
          <button class="btn outline blue left"
                  type="button"
                  (click)="back()">Back</button>
        </div>
        <div class="col-xs-6">
          <button type="button"
                  (click)="close()"
                  class="btn pull-right">Ok</button>
        </div>
      </div>
    </div>
  </div>
</indicio-modal-base>
