import { Injectable } from '@angular/core';
import { FileGeneratorService } from '@core/services/file-generator.service';
import { HttpService } from '@core/services/http/http.service';
import { CompanyFrontendService } from '../../company/company.frontend.service';
import { AvailableForecastResultDTO, CreateReconciliationDTO, HierarchyReconciliationDTO, UpdateReconciliationDTO } from '../entities/dtos/hierarchy-reconciliation.dto';
import { CreateHierarchyRelationDTO, PotentialForecastsForRelationDTO, UpdateHierarchyRelationDTO } from '../entities/dtos/hierarchy-relation.dto';
import { CreateHierarchyDTO, HierarchyAdminOverviewDTO, HierarchyDTO, UpdateHierarchyDTO } from '../entities/dtos/hierarchy.dto';
import { HForecastTreeNode } from '../entities/models/hierarchical-forecast.tree-node';
import { HierarchicalForecastMapper } from '../utils/hierarchy.mapper';

@Injectable({
  providedIn: 'root'
})
export class HierarchicalForecastBackendService {

  constructor(
    private base: HttpService,
    private mapper: HierarchicalForecastMapper,
    private companyService: CompanyFrontendService,
    private fileGeneratorService: FileGeneratorService
  ) { }

  //#region Admin
  public getAllHierarchiesInSystem() {
    return this.base.post<HierarchyAdminOverviewDTO>('admin/hierarchical-forecasts/get-all', {})
      .then(({ body }) => body);
  }

  public getCompanyHierarchiesInSystem(companyId: string) {
    return this.base.post<HierarchyAdminOverviewDTO>(`admin/hierarchical-forecasts/get-company/${companyId}`, {})
      .then(({ body }) => body);
  }
  //#endregion

  public getAllInCompany() {
    return this.base.get<HierarchyDTO[]>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts`)
      .then(({ body }) => body.map(x => this.mapper.map(x)));
  }

  public get(hierarchyId: string) {
    return this.base.get<HierarchyDTO>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}`)
      .then(({ body }) => this.mapper.map(body));
  }

  public create(dto: CreateHierarchyDTO) {
    return this.base.post<HierarchyDTO>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts`, dto)
      .then(({ body }) => this.mapper.map(body));
  }

  public createReconciliation(hierarchyId: string, dto: CreateReconciliationDTO) {
    return this.base.post<HierarchyReconciliationDTO>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}/reconciliations`, dto)
      .then(({ body }) => body);
  }

  public update(hierarchyId: string, dto: UpdateHierarchyDTO) {
    return this.base.put<HierarchyDTO>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}`, dto)
      .then(({ body }) => this.mapper.map(body));
  }

  public updateReconciliation(hierarchyId: string, reconciliationId: string, dto: UpdateReconciliationDTO) {
    return this.base.put<HierarchyDTO>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}/reconciliations/${reconciliationId}`, dto)
      .then(({ body }) => this.mapper.map(body));
  }

  public triggerReconciliation(hierarchyId: string, reconciliationId: string) {
    return this.base.get<void>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}/reconciliations/${reconciliationId}/trigger`);
  }

  public remove(hierarchyId: string) {
    return this.base.delete<void>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}`);
  }

  public removeReconciliation(hierarchyId: string, reconciliationId: string) {
    return this.base.delete<void>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}/reconciliations/${reconciliationId}`);
  }

  public addRelation(hierarchyId: string, dto: CreateHierarchyRelationDTO) {
    return this.base.post<HierarchyDTO>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}/relations`, dto)
      .then(({ body }) => this.mapper.map(body));
  }

  public updateRelation(hierarchyId: string, dto: UpdateHierarchyRelationDTO) {
    return this.base.put<HierarchyDTO>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}/relations/${dto.RelationId}`, dto)
      .then(({ body }) => this.mapper.map(body));
  }

  public removeRelation(hierarchyId: string, relationId: string) {
    return this.base.delete<HierarchyDTO>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}/relations/${relationId}`)
      .then(({ body }) => this.mapper.map(body));
  }

  public getAvailableResults(hierarchyId: string) {
    return this.base.get<AvailableForecastResultDTO[]>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}/available-results`)
      .then(({ body }) => body);
  }

  // TODO: Cache this
  public getPotentialForecasts(hierarchyId: string) {
    return this.base.get<PotentialForecastsForRelationDTO[]>(`company/${this.companyService.activeCompanyId}/hierarchical-forecasts/${hierarchyId}/potential-forecasts`)
      .then(({ body }) => body);
  }

  public downloadReport(HFNode: HForecastTreeNode) {
    const reportModel = this.mapper.mapReconciliationReport(HFNode);
    return this.fileGeneratorService.generateReconciliationReport(reportModel);
  }

}
