import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { SourceType } from '@modules/lang/language-files/source-types';
import { Periodicity } from '@modules/lang/types/periodicity';
import { ForecastModelFilterDTO } from '../forecast/dtos/model-filters/forecast-model-filter-settings-dto';
import { HistoricBaseEventDTO } from '../historic-event/models/base/historic-base-event.dto';
import { RemoteDataRequestDTO } from '../providers/dtos/remote-data-request-dto';
import { TagTypes } from '../tags/dtos/tags.dtos';

export class AdminUpdateForecastTemplateDTO {
  ForecastTemplateId: string;
  Title: string;
  Description: string;
  Tags: ForecastTemplateTagDTO[];
  AuthorEmail: string;
}

export class ForecastTemplateVariableDTO {
  ForecastTemplateVariableId: string;
  Name: string;
  Source: string;
  Periodicity: string;
  RemoteReferenceId: string;
  Extras: RemoteDataRequestDTO;
  AggregationMethodId: string;
  Influence: number;

  // Frontend mapped fields
  source: SourceType;
  hasAccess: boolean;
}

export class TemplateAuthorDTO {
  AuthorName: string;
  AuthorBio: string;
  AuthorEmail: string;
  AuthorPicture: any;
}

export class ForecastTemplateSettingsDTO {
  // ForecastSetting
  AggregationMethodId: string;

  // VersionSetting
  MaxLag: number;
  StepChosen: number;
  Alpha: number;
  Horizon: number;
  TestSize: number;
  UseModelRollingWindow: boolean;
  ModelRollingWindowRecalc: number;
  ModelThresholdMAPE: number;
  CalculateOutliers: boolean;
  CalculateSeasonal: boolean;
  AutoUpdateVariables: boolean;
  AutoTriggerVarSelect: boolean;
  AutoTriggerMulti: boolean;
  NeedsReview: boolean;
  IsLatest: boolean;
  EnableMAPE: boolean;
  OverlappingValueCount: number;
  IndicatorUpdateWarning: string;

  // VarSelect
  VSMode: string;
  VSLassoStruct: string;
  VSMeasurement: string;
  VSCoefMethod: string;
  ModelScaling: string;
  VSBestVarCount: number;
  VSUseRollingWindow: boolean;
  IndicatorSortOrder: string;
  IndicatorAutoSort: string;
  EarliestSharedDate: string;
  DataStartDate: string;
  StartDate: string;
  CreatedDate: string;
  ModifiedDate: string;
  Filters: ForecastModelFilterDTO[];
}

export class ForecastTemplateTagDTO {
  ForecastTemplateTagId: string;
  Name: string;
  Type: TagTypes = TagTypes.USERDEFINED;
}


export class ForecastTemplateVariableTagDTO {
  SourceVariableId: string;
  Name: string;
}

export class ForecastTemplateSearchDTO {
  SearchKeyword: string;
}

export class UpdateForecastTemplateDTO {
  ForecastTemplateId: string;
  Title: string;
  Description: string;
  Tags: ForecastTemplateTagDTO[];
  IsPublic: boolean;
}

export class CreateForecastTemplateDTO {
  ForecastVersionId: string;
  Title: string;
  Description: string;
  Tags: string[];
  VariableTags: ForecastTemplateVariableTagDTO[];
  IsPublic: boolean;
  CompanyId: string;
}


export class UseForecastTemplateDTO {
  ProjectId: string;
}


export class ForecastTemplateDTO {
  @Def() ForecastTemplateId: string;
  @Def() Title: string;
  @Def() Description: string;
  @Def() Periodicity: string;
  @Def() Tags: ForecastTemplateTagDTO[];
  @Def() UsageCount: number;

  @Def() MainForecastVariable: ForecastTemplateVariableDTO;
  @Def() Indicators: ForecastTemplateVariableDTO[];
  @Def() HistoricEvents: HistoricBaseEventDTO[];
  @Def() Sources: SourceInfoDTO[];
  @Def() Settings: ForecastTemplateSettingsDTO;
  @Def() AuthorInfo: TemplateAuthorDTO;
  @Def() CreatedDate: string;
  @Def() ModifiedDate: string;
}

export class SourceInfoDTO {
  Source: string;
  HasAccess: boolean;

  // Frontend specific
  source: SourceType;
}

export class ForecastTemplate extends ForecastTemplateDTO implements IHasModelId {
  getModelId(): string { return this.ForecastTemplateId; }
  sources: string;
  sourcesArray: string[];
  tagsArray: string[];
  regionsArray: string[];
  indicatorCount: number;
  periodicity: Periodicity;
  normalizedInfluence: { id: string, influence: number, real: number; }[];
  authorEmail: string;
}

export class ForecastTemplateEventDTO {
  ClientId: string;
  ErrorMessages: string[] = [];
  InfoMessages: string[] = [];
  SuccessMessages: string[] = [];
  ProcessFailed: boolean;
  ForecastStatus: string;
  MainVarStatus: string;
  IndicatorsStatus: string;
  EventStatus: string;
}
