import { Injectable, OnDestroy } from '@angular/core';
import { PusherActions } from '@core/actions/pusher.actions';
import { GithubIssue } from '@core/entities/github.models';
import { AuthToken } from '@core/store/auth/auth-token';
import { AuthActions, ClearStateAction } from '@core/store/auth/auth.actions';
import { AuthState } from '@core/store/auth/auth.state';
import { GetClientSuccessAction } from '@core/store/client/client.actions';
import { CompanyActions } from '@core/store/company/company.actions';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { DialogActions } from '@shared/modules/dialogs/dialog.actions';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import * as PusherTypes from 'pusher-js';
import Pusher from 'pusher-js/with-encryption';
import { Subscription } from 'rxjs';
import { ActionService } from '../actions/actions.service';
import { EnvironmentService } from '../environment/environment.service';
import { ErrorReportService } from '../error-report.service';
import { PusherOpenAdminActionDTO } from '../pusher/dtos/pusher-action-dto';
import { SystemMessage } from '../system/system-message';

type PusherState = 'initialized' | 'connecting' | 'connected' | 'unavailable' | 'failed' | 'disconnected';

class IndicioPusherBinding {
  event: string = '';
  cb: Function = null;
}

@Injectable({
  providedIn: 'root'
})
export class PusherService implements OnDestroy {

  private sub: Subscription = new Subscription();

  private clientId: string;
  private pusher: Pusher = null;

  private companyChannel: PusherTypes.Channel;
  private companyBindings: IndicioPusherBinding[] = [];

  private forecastChannel: PusherTypes.Channel;

  private userPrivateChannel: PusherTypes.Channel;
  private userPrivateBindings: IndicioPusherBinding[] = [];

  private userChannel: PusherTypes.Channel;
  private userBindings: IndicioPusherBinding[] = [];

  private adminChannel: PusherTypes.Channel;
  private adminBindings: IndicioPusherBinding[] = [];

  private openAdminChannel: PusherTypes.Channel;
  private openAdminBindings: IndicioPusherBinding[] = [];

  private setupComplete: boolean;

  constructor(
    private store: Store,
    private envService: EnvironmentService,
    private actions: ActionService,
    private dialogService: DialogService,
    private errorService: ErrorReportService,
    private profileService: ProfileFrontendService
  ) {
    this.setupActionSubscriptions();

  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public addAdminBinding(evt: string, cb: any) {
    this.addBinding(evt, this.adminChannel, this.adminBindings, cb);
  }

  public addOpenAdminBinding(evt: string, cb: any) {
    this.addBinding(evt, this.openAdminChannel, this.openAdminBindings, cb);
  }

  public addUserBinding(evt: string, cb: any) {
    this.addBinding(evt, this.userChannel, this.userBindings, cb);
  }

  public addPrivateBinding(evt: string, cb: any) {
    this.addBinding(evt, this.userPrivateChannel, this.userPrivateBindings, cb);
  }

  public addCompanyBinding(evt: string, cb: any) {
    this.printInfo('Adding company binding for ' + evt);
    this.addBinding(evt, this.companyChannel, this.companyBindings, cb);
  }

  public sendOpenAdminEvent(content: PusherOpenAdminActionDTO) {
    content.ActorId = this.profileService.profile.Email;
    this.openAdminChannel.trigger('client-open-admin-event', content);
  }

  /**
   *
   * Main entry point for this service.
   * We setup the pusher-connections whenever a user tries to store a token
   * After setting up the connection, we dispatch an event 'SetupBindings'
   * SetupBindings instructs other components/services they should now register their bindings
   *
   */
  private setupActionSubscriptions() {
    this.sub.add(this.actions.dispatched(AuthActions.StoredToken)
      .subscribe((value: AuthActions.StoredToken) => {
        if (this.setupComplete === true) {
          this.setToken(value.token);
          return;
        }
        this.clientId = value.token.client_id;
        this.setupConnection();
        this.setupUserChannel();
        this.setupComplete = true;
      })
    );
    this.sub.add(this.actions.dispatched(GetClientSuccessAction)
      .subscribe(() => {
        this.setupPrivateUserChannel();
      })
    );
    this.sub.add(this.actions.dispatched(ClearStateAction)
      .subscribe(() => {
        this.logout();
        this.store.dispatch(new SystemMessage.Actions.Clear('all'));
      })
    );
    this.sub.add(this.actions.dispatched(CompanyActions.SetActive).subscribe((action: CompanyActions.SetActive) => {
      this.setupCompanyChannel(action.companyId);
    }));
  }

  /**
   *
   * Setup main connection, the Pusher-client
   * Registers the auth-endpoint for private channels
   *
   */
  private setupConnection() {
    this.logout();
    const token = this.store.selectSnapshot(AuthState.token);
    // See https://pusher.com/docs/channels/using_channels/connection/#channels-options-parameter
    const opts: PusherTypes.Options = {
      cluster: this.envService.env.PusherServerCluster,
      authEndpoint: `${this.envService.env.UriBackendApi}api/v1/pusher/auth`,
      forceTLS: true,
      auth: {
        headers: {
          'Authorization': `bearer ${token.access_token}`
        }
      }
    };
    this.pusher = new Pusher(this.envService.env.PusherServerAppKey, opts);
    this.setToken(token);
    this.addConnectionBindings();
  }

  /**
   *
   * Set user token for pusher-auth
   *
   */
  private setToken(token: AuthToken) {
    if (this.pusher) {
      this.pusher.config.auth = {
        headers: {
          'Authorization': `bearer ${token.access_token}`
        }
      };
    }
  }

  /**
   *
   * Setup the user channel, used for id-server communication (not private)
   *
   */
  private setupUserChannel() {
    this.resetChannel('user');
    const cn = `user@${this.clientId}`;
    this.userChannel = this.pusher.subscribe(cn);
    this.addChannelStatusBindings(cn, this.userChannel, this.userBindings);
    this.store.dispatch(new PusherActions.SetupUserBindings());
  }

  /**
   *
   * Setup the private user channel, only the current user gets messages on this channel
   *
   */
  private setupPrivateUserChannel() {
    this.resetChannel('private');
    const cn = `private-encrypted-user@${this.clientId}`;
    this.userPrivateChannel = this.pusher.subscribe(cn);
    this.addChannelStatusBindings(cn, this.userPrivateChannel, this.userPrivateBindings);
    this.store.dispatch(new PusherActions.SetupPrivateBindings());
  }

  /**
   *
   * Setup the private company channel. Only employees in the same company gets those events
   *
   */
  private setupCompanyChannel(companyId: string) {
    if (this.companyChannel?.name.match(new RegExp(companyId))) { return; }
    this.resetChannel('company');
    const cn = `private-encrypted-company@${companyId}`;
    this.companyChannel = this.pusher.subscribe(cn);
    this.addChannelStatusBindings(cn, this.companyChannel, this.companyBindings);
    this.store.dispatch(new PusherActions.SetupCompanyBindings());
  }

  /**
   *
   * Setup the private admin channel. Only indicio admins gets these events
   *
   */
  public setupAdmin(channelId: string) {
    this.resetChannel('admin');
    const cn = `private-encrypted-admin@${channelId}`;
    this.adminChannel = this.pusher.subscribe(cn);
    this.addChannelStatusBindings(cn, this.adminChannel, this.adminBindings);
    this.store.dispatch(new PusherActions.SetupAdminBindings());
  }

  /**
  *
  * Setup the open admin channel. Only indicio admins gets these events
  *
  */
  public setupOpenAdmin(channelId: string) {
    this.resetChannel('open-admin');
    const cn = `private-admin@${channelId}`;
    this.openAdminChannel = this.pusher.subscribe(cn);
    this.addChannelStatusBindings(cn, this.openAdminChannel, this.openAdminBindings);
    this.store.dispatch(new PusherActions.SetupOpenAdminBindings());
  }

  /**
  *
  * Forecast presence channel
  *
  */
  public joinForecast(forecastId: string) {
    this.resetChannel('forecast');
    const cn = `presence-forecast@${forecastId}`;
    this.forecastChannel = this.pusher.subscribe(cn);
  }

  public leaveForecast() {
    this.resetChannel('forecast');
  }

  public getForecastChannel(): PusherTypes.PresenceChannel {
    return <PusherTypes.PresenceChannel> this.forecastChannel;
  }

  /**
   *
   * Internal helper to add binding, if not already present in bingings
   *
   */
  private addBinding(event: string, channel: PusherTypes.Channel, bindings: IndicioPusherBinding[], cb: any) {
    if (bindings.findIndex(x => x.event === event) === -1) {
      this.bindToEvent({ event, cb }, channel, bindings);
    }
  }

  /**
   *
   * Perform a clean logout when refreshing the webpage
   *
   */
  private logout() {
    this.setupComplete = false;
    this.resetChannel('company');
    this.resetChannel('admin');
    this.resetChannel('private');
    this.resetChannel('user');
    if (this.pusher) {
      this.pusher.disconnect();
      this.pusher = null;
    }
    this.setupComplete = false;
  }

  /**
   *
   * Resets a channel, unbinding it and clearing its bindings-list
   *
   */
  private resetChannel(name: 'forecast' | 'company' | 'user' | 'admin' | 'open-admin' | 'private') {
    switch (name) {
      case 'forecast':
        if (this.forecastChannel) {
          this.forecastChannel.unsubscribe();
          this.forecastChannel.unbind();
        }
        this.forecastChannel = null;
        break;
      case 'company':
        if (this.companyChannel) {
          this.companyChannel.unsubscribe();
          this.companyChannel.unbind();
        }
        this.companyBindings = [];
        this.companyChannel = null;
        break;
      case 'user':
        if (this.userChannel) {
          // this.userChannel.unsubscribe();
          this.userChannel.unbind();
        }
        this.userBindings = [];
        this.userChannel = null;
        break;
      case 'admin':
        if (this.adminChannel) {
          // this.adminChannel.unsubscribe();
          this.adminChannel.unbind();
        }
        this.adminBindings = [];
        this.adminChannel = null;
        break;
      case 'open-admin':
        if (this.openAdminChannel) {
          this.openAdminChannel.unbind();
        }
        this.openAdminBindings = [];
        this.openAdminChannel = null;
        break;
      case 'private':
        if (this.userPrivateChannel) { this.userPrivateChannel.unbind(); }
        this.userPrivateBindings = [];
        this.userPrivateChannel = null;
        break;
    }
  }

  /**
   *
   * Add connection bindings that are used for debugging - if enabled
   *
   */
  private addConnectionBindings() {
    this.pusher.connection.bind('error', (err) => {
      if (err && err.error && err.error.data?.code === 4004) {
        this.printIf('[Pusher service] Message limit reached. Please contact customer support');
        this.errorService.postGithubIssue(<GithubIssue.GithubIssueReport> {
          Subject: 'Pusher',
          Type: 'Internal error',
          Text: 'Message limit reached. Investigate.',
          User: this.profileService.profile.Email,
          BackendVersion: 'NA',
          IdentityVersion: 'NA',
          ImportVersion: 'NA',
        });
        this.displayPusherInterrupted();
      }
    });
    this.pusher.connection.bind('connecting_in', (delay) => {
      this.printIf('[Pusher service] Reconnecting in [' + delay + '] seconds');
    });

    this.pusher.connection.bind('state_change', (states: { previous: PusherState, current: PusherState; }) => {
      const reconnecting = states.previous === 'connected' && states.current === 'connecting';
      const internetLost = states.previous === 'connecting' && states.current === 'disconnected';
      const connectionFailed = states.previous === 'connecting' && states.current === 'unavailable';
      const connectionEstablished = states.current === 'connected';
      switch (true) {
        case reconnecting:
          return this.displayConnectionLost();
        case internetLost:
          return this.displayConnectionUnavailable();
        case connectionFailed:
          return this.displayConnectionFailed();
        case connectionEstablished: {
          this.store.dispatch(new SystemMessage.Actions.Clear('pusher'));
          this.store.dispatch(new DialogActions.Close('confirm'));
          return;
        }
      }
    });

    this.pusher.connection.bind('failed', () => {
      this.printIf('[Pusher service] Channels is not supported by the browser - see pusher.com for more information or contact customer support');
    });
    this.pusher.connection.bind('connecting', () => { });
    this.pusher.connection.bind('connected', () => { });
    this.pusher.connection.bind('unavailable', () => { });
    this.pusher.connection.bind('disconnected', () => { });
  }

  /**
   *
   * Adds channel status bindings, used for debugging - if enabled
   *
   */
  private addChannelStatusBindings(channelName: string, channel: PusherTypes.Channel, bindings: IndicioPusherBinding[]) {
    this.printIf('[Pusher service] Setting up channel: ' + channelName);
    this.addBinding('pusher:subscription_succeeded', channel, bindings, () => {
      this.printIf('[Pusher service] Subscription to channel *' + channelName + '* succeeded');
    });
    this.addBinding('pusher:subscription_error', channel, bindings, () => {
      this.printIf('[Pusher service] Subscription error: Auth request to private/presence channel failed.');
    });
    this.addBinding('pusher:error', channel, bindings, status => {
      this.printIf('[Pusher service] Error: ', status);
    });
  }

  private bindToEvent(x: IndicioPusherBinding, channel: PusherTypes.Channel, bindings: IndicioPusherBinding[]) {
    if (channel) {
      bindings.push(x);
      channel.bind(x.event, data => {
        this.printIf(`[Pusher service] Channel ${channel.name} got event: ${x.event}. Data = ${JSON.stringify(data)}`);
        x.cb(data);
      });
      this.printIf(`[Pusher service] Channel ${channel.name} bound to event: ${x.event}`);
    }
  }

  private printInfo(str: any) {
    if (environment.pusherInfoLogging) {
      console.log(str);
    }
  }

  private printIf(str: any, blob: any = null) {
    if (environment.pusherDebugLogging) {
      console.log(str, blob || '');
    }
  }

  /**
   *
   * Debug helpers below
   *
   */
  public verifyBindings() {
    if (!this.pusher || !this.pusher.connection) {
      this.printIf('[Pusher service] Verification: No connection object');
      return;
    }

    switch (this.pusher.connection.state) {
      case 'connecting':
        this.printIf('[Pusher service] VerifyConnection: connecting');
        break;
      case 'connected':
        this.printIf('[Pusher service] VerifyConnection: connected');
        break;
      default:
        this.printIf('[Pusher service] In unknown state: *' + this.pusher.connection.state + '*');
        break;
    }
  }

  /** Display */
  private displayConnectionLost() {
    this.store.dispatch(new DialogActions.Close('confirm'));
    this.dialogService.openConfirmDialog({
      Title: 'Your connection to the Indicio services has been lost',
      Message: 'If you\'re experiencing internet connectivity issues, try reloading the application when it has subsided or wait until we have re-established the connection.',
      ConfirmText: 'Logout',
      Style: 'primary',
      ExtraWarning: '',
      HideCancel: true,
      ConfirmFunction: () => this.store.dispatch(new AuthActions.Logout)
    }, { disableClose: true, width: '400px' });

    this.store.dispatch(new SystemMessage.Actions.Display(<SystemMessage.Entities.Message> {
      Content: 'Disconnected from Indicio service. Trying to re-establish connection <i class="ion-load-c spinner"></i>',
      Position: 'top',
      Type: 'pusher'
    }));
  }

  private displayConnectionUnavailable() {
    this.store.dispatch(new DialogActions.Close('confirm'));
    this.dialogService.openConfirmDialog({
      Title: 'Your internet connection to the Indicio services has been lost',
      Message: 'If you\'re experiencing internet connectivity issues, try reloading the application when it has subsided or wait until we have re-established the connection.',
      ConfirmText: 'Logout',
      Style: 'primary',
      ExtraWarning: '',
      HideCancel: true,
      ConfirmFunction: () => this.store.dispatch(new AuthActions.Logout)
    }, { disableClose: true, width: '400px' });

    this.store.dispatch(new SystemMessage.Actions.Display(<SystemMessage.Entities.Message> {
      Content: 'Disconnected from Indicio services. Trying to re-establish connection <i class="ion-load-c spinner"></i>',
      Position: 'top',
      Type: 'pusher'
    }));
  }

  private displayConnectionFailed() {
    let sysMessage = 'Failed to connect to Indicio services. Trying to establish a connection <i class="ion-load-c spinner"></i>';

    this.store.dispatch(new DialogActions.Close('confirm'));
    this.dialogService.openConfirmDialog({
      Title: 'Failed to connect to Indicio services',
      Message: `This may be due to your firewall blocking one or more of the Indicio services. Please make sure connections to the following is allowed in your firewall:
                  <br>
                  <br>
                  Messaging service: wss://ws-eu.pusher.com
                  <br>
                  Application: https://app.indicio.com
                  <br>`,
      ConfirmText: 'Logout',
      Style: 'primary',
      ExtraWarning: '',
      CancelText: 'Ignore',
      ConfirmFunction: () => this.store.dispatch(new AuthActions.Logout)
    }, { width: '400px', disableClose: false }).subscribe((result) => {
      if (result) { return; }
      sysMessage += ' | Until the connection is re-established, you may experience issues with the application.';
      this.store.dispatch(new SystemMessage.Actions.Display(<SystemMessage.Entities.Message> {
        Content: sysMessage,
        Position: 'bottom',
        Type: 'pusher'
      }));
    });

    this.store.dispatch(new SystemMessage.Actions.Display(<SystemMessage.Entities.Message> {
      Content: sysMessage,
      Position: 'top',
      Type: 'pusher'
    }));
  }

  private displayPusherInterrupted() {
    this.dialogService.openConfirmDialog({
      Title: 'Failed to connect to Indicio services',
      Message: `A connection to the Indicio services could not be established
                <br>
                Please contact the customer support with the following error code:
                <br>
                0x4004`,
      ConfirmText: 'OK',
      Style: 'primary',
      ExtraWarning: '',
    }, { width: '400px' });
  }
}
