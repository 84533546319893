<indicio-modal-base [child]="this"
                    modalTitle="Import data from Alpha Vantage"
                    [loading]="isLoading"
                    modalExtraClass="small"
                    extraClasses="small">

  <div class="row alpha-icons">
    <div class="col-xs-4 text-center">
      <img src="/assets/icons/forex.svg"
           class="cursor-pointer"
           (click)="openForex()">
      <div class="text-center">Forex</div>
    </div>
    <div class="col-xs-4 text-center">
      <img src="/assets/icons/stock.png"
           class="cursor-pointer"
           (click)="openStock()">
      <div class="text-center">Stocks</div>
    </div>
    <div class="col-xs-4 text-center">
      <img src="/assets/icons/crypto.png"
           class="cursor-pointer"
           (click)="openCrypto()">
      <div class="text-center">Cryptocurrency</div>
    </div>
  </div>
  <div class="row margin-top">
    <div class="col-xs-12">
      <button (click)="close()"
              type="button"
              class="btn outline blue left">Close</button>
    </div>
  </div>
</indicio-modal-base>
