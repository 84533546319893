<indicio-modal-base [child]="this"
                    modalTitle="Update variables"
                    modalClass=""
                    [loading]=isLoading
                    bodyExtraClass="overflow-auto">
  <div style="width: 85%; margin: 0 auto;"
       *ngIf=!isLoading>
    <ng-container *ngTemplateOutlet="variableList"></ng-container>
    <ng-container *ngIf="newVersion"
                  [ngTemplateOutlet]="statusTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>
</indicio-modal-base>

<ng-template #statusTemplate>
  <div class="row">
    <div class="col-xs-12 bold text-center extra-margin-top">Status</div>
  </div>
  <div class="row variable-row vertical-align">
    <div class="col-xs-12">
      <div class="data-table">
        <div class="data-table__header">
          <div class="column header"
               style="width: 40px;">#</div>
          <div class="column header"
               style="width: 270px; text-align: left; padding-left: 5px;">Object</div>
          <div class="column header"
               style="width: 200px;">Info</div>
          <div class="column header"
               style="width: 128px;">Status</div>
        </div>

        <div class="data-table__body big"
             style="max-height: 350px;">
          <div class="body__row body__row--center-height body__row--max-height-30">
            <div class="column"
                 style="width: 40px;">1</div>
            <div class="column"
                 style="width: 270px; text-align: left;">
              <div class="row">
                <div class="col-xs-12">
                  Forecast version
                </div>
              </div>
            </div>
            <div class="column"
                 style="width: 200px;">
              Creating new forecast version
            </div>
            <div class="column"
                 style="width: 128px;">
              {{ !stepStatus.ForecastVersionCreated ? 'Pending' : 'Complete' }}
            </div>
          </div>
          <div class="body__row body__row--center-height body__row--max-height-30">
            <div class="column"
                 style="width: 40px;">2</div>
            <div class="column"
                 style="width: 270px; text-align: left;">
              <div class="row">
                <div class="col-xs-12">
                  Main variable
                </div>
              </div>
            </div>
            <div class="column"
                 style="width: 200px;">
              Creating new main variable
            </div>
            <div class="column"
                 style="width: 128px;">
              {{ !stepStatus.MainVariableCreated ? 'Pending' : 'Complete' }}
            </div>
          </div>
          <div class="body__row body__row--center-height body__row--max-height-30">
            <div class="column"
                 style="width: 40px;">3</div>
            <div class="column"
                 style="width: 270px; text-align: left;">
              <div class="row">
                <div class="col-xs-12">
                  Indicators
                </div>
              </div>
            </div>
            <div class="column"
                 style="width: 200px;">
              Creating new indicators
            </div>
            <div class="column"
                 style="width: 128px;">
              {{ stepStatus.IndicatorsCreated }} / {{ stepStatus.IndicatorCount }}
            </div>
          </div>
          <div *ngIf="stepStatus.NotUpdated.length"
               class="body__row body__row--center-height body__row--max-height-30">
            <div class="column"
                 style="width: 40px;">4</div>
            <div class="column"
                 style="width: 270px; text-align: left;">
              <div class="row">
                <div class="col-xs-12">
                  Failed indicators
                </div>
              </div>
            </div>
            <div class="column"
                 style="width: 200px;">
              Access denied ({{stepStatus.NotUpdated.length}})
            </div>
            <div class="column"
                 [indicioTippy]="{ maxWidth: 500 }"
                 style="width: 128px;">
              Info <i class="ion-information-circled"></i>
              <div tippy-content>
                <div class="row"
                     style="width: 500px;">
                  <div class="col-xs-6 bold">
                    Variable name
                  </div>
                  <div class="col-xs-6 bold">
                    Owner
                  </div>
                </div>
                <div class="row"
                     style="width: 500px;"
                     *ngFor="let e of stepStatus.NotUpdated">
                  <div class="col-xs-6 crop-string"
                       [title]="e.Item1">
                    {{ e.Item1 }}
                  </div>
                  <div class="col-xs-6 crop-string"
                       [title]="e.Item2">
                    {{ e.Item2 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #variableList>
  <div class="row">
    <div class="col-xs-12 bold text-center">Variables to update</div>
  </div>
  <div class="row variable-row vertical-align">
    <div class="col-xs-12">
      <div class="data-table">
        <div class="data-table__header">
          <div class="column header"
               style="width: 40px;">#</div>
          <div class="column header"
               style="width: 400px; text-align: left; padding-left: 5px;">Name</div>
          <div class="column header"
               style="width: 70px;">Update</div>
          <div class="column header"
               style="width: 128px;">Status</div>
        </div>

        <div class="data-table__body big"
             style="max-height: 350px;">
          <div class="body__row body__row--center-height body__row--max-height-30"
               *ngFor="let variable of toUpdate; let i = index">
            <div class="column"
                 style="width: 40px;">{{ i }}</div>
            <div class="column"
                 style="width: 400px; text-align: left;">
              <div class="row">
                <div class="col-xs-12">
                  {{ variable.Name }}
                </div>
              </div>
            </div>
            <div class="column"
                 style="width: 70px;">
              <mat-slide-toggle color="accent"
                                (change)="toggleUpdate(variable)"
                                [checked]="variable.shouldUpdate">
              </mat-slide-toggle>
            </div>
            <div class="column"
                 style="width: 128px;">
              <ng-container *ngIf="variable.shouldUpdate; else skipped">
                <ng-container *ngIf="variable.updateStatus === 'failed'; else status">
                  <span class="red cursor-pointer"
                        [indicioTippy]="{ content: variable.updateError, maxWidth: 600 }">Failed
                    <i class="ion-information-circled info-icon blue"></i>
                    <!-- <div tippy-content
                         [innerHtml]=>
                    </div> -->
                  </span>
                </ng-container>
                <ng-template #status>
                  {{ variable.updateStatus }}
                </ng-template>
              </ng-container>
              <ng-template #skipped>
                Skipped
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="row extra-margin-top">
    <div class="col-xs-9 italic">{{ newVersion ? "Updating the main variable can take a long time" : "" }}</div>
    <div class="col-xs-3 text-right">
      <ng-container *ngIf="pending || updatedCount !== 0">
        Processed: {{updatedCount }}/{{ toUpdateCount}}
      </ng-container>
      <ng-container *ngIf="!pending && updatedCount === 0">
        Selected: {{ toUpdateCount }}/{{ toUpdate.length }}
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <button class="btn outline blue"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6">
      <button class="btn pull-right"
              (click)="processDone ? close() : update()"
              [ngClass]="{ disabled: toUpdateCount === 0 }">
        <span *ngIf="!pending">{{ processDone ? 'Done' : 'Update'}}</span>
        <i class="ion-load-c spinner"
           *ngIf="pending"></i>
      </button>
    </div>
  </div>
</ng-template>
