import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { NotificationModel } from '@core/store/notifications/notification.model';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'latestNotifications' })
export class NotificationPipe implements PipeTransform {
  transform(array: NotificationModel[], limitTo = 50, _trigger: number): NotificationModel[] {
    return array.filter((item, index) => index < limitTo && item.Message !== undefined && item.hidden === false);
  }
}
