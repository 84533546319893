import { DisplayValue } from '@modules/lang/types/display-value';

export type CReconciliationMethodTypes = 'ols' | 'bu' | 'struc' | 'wls' | 'shr' | 'sam';

export const CReconciliationMethods = [
  new DisplayValue<CReconciliationMethodTypes>('ols', 'Identity', 'All forecasts get the same weight during reconciliation.'),
  new DisplayValue<CReconciliationMethodTypes>('bu', 'Bottom up', 'Reconcile the hierarchy using the bottom up approach.'),
  new DisplayValue<CReconciliationMethodTypes>('struc', 'Structural variances', 'Forecasts are weighted based on their importance in the hierarchy.'),
  new DisplayValue<CReconciliationMethodTypes>('wls', 'Variance scaling', 'Forecasts are weighted based on their accuracy.'),
  new DisplayValue<CReconciliationMethodTypes>('shr', '(Recommended) Shrunk covariance matrix', 'Forecasts are weighted based on their accuracy, optimised for large hierarchies.'),
  new DisplayValue<CReconciliationMethodTypes>('sam', '(Recommended) Sample covariance matrix', 'Forecasts are weighted based on their accuracy, less performant for large hierarchies.'),
];
