import { Def } from '@core/decorators/def';
import { ColumnType } from '@modules/forecast/views/add-data/file-sheet/types/types';

export class VariableSheetDTO {
  @Def() SheetIndex: number;
  @Def() SheetName: string;
  @Def() ParsedColumns: ColumnDTO[] = [];

  constructor(dto: VariableSheetDTO) {
    this.SheetName = dto.SheetName;
    this.ParsedColumns = dto.ParsedColumns;
    this.SheetIndex = dto.SheetIndex;
  }
}

export class DateColumnInfoDTO {
  IsValidDateColumn: boolean;
  IsValidValueColumn: boolean;
  Message: string;
  DateFormat: string;
  DisabledRows: number[] = [];

  constructor(dto: DateColumnInfoDTO) {
    this.DisabledRows = dto.DisabledRows;
    this.DateFormat = dto.DateFormat;
    this.IsValidDateColumn = dto.IsValidDateColumn;
    this.IsValidValueColumn = dto.IsValidValueColumn;
    this.Message = dto.Message;
  }
}

export class ColumnDTO extends DateColumnInfoDTO {
  ColumnName: string;
  ColumnType: ColumnType;
  SheetIndex: number;
  ColumnIndex: number;
  Raw: string[] = [];

  constructor(dto: ColumnDTO) {
    super(dto);
    this.ColumnType = dto.ColumnType;
    this.Raw = dto.Raw;
  }
}
