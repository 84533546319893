import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { RemovalWarningsFrontendService } from '@core/services/removal-warnings/removal-warnings.frontend.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { Automation } from '@core/store/automation/automation';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { AutomationDialogService } from '@dialogs/automation/automation-dialogs.service';
import { ForecastDialogService } from '@dialogs/forecast/forecast-dialogs.service';
import { UpdateData } from '@dialogs/variables/forecast-variable/update/update-variables.dialog.entities';
import { Syncable } from '@dialogs/variables/remote-variable/syncable-variables/syncable-variables.entities';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { faLock } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NavigationForecastListViewType } from '@modules/root/components/navigation/entities/navigation.entities';
import { NavigationComponentService } from '@modules/root/components/navigation/navigation.component.service';
import { Store } from '@ngxs/store';
import { OpenEditProjectModal } from '@shared/modals/project/edit-project/project-edit-modal.actions';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { Observable } from 'rxjs';
import { IForecastTreeNodeBase, IForecastTreeNodeForecast, IForecastTreeNodeProject } from './entities/forecast-tree.entities';

function getNodeLevel(node: IForecastTreeNodeBase) {
  return node.level;
}

// Function that determines whether a flat node is expandable or not
function getIsNodeExpandable(node: IForecastTreeNodeBase) {
  return node.Type !== 'forecast' || node.Children.length > 0;
}

@Component({
  selector: 'indicio-forecast-nav-tree',
  templateUrl: './forecast-nav-tree.component.html',
  styleUrls: ['./forecast-nav-tree.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ForecastNavTreeComponent implements OnChanges {
  faLock = faLock as IconProp;

  @ViewChild('scrollViewport')
  private cdkVirtualScrollViewport;

  @HostListener('window:resize', ['$event'])
  onResize(event?) { this.screenHeight = window.innerHeight; }

  @Input() listView: NavigationForecastListViewType;
  @Input() public loadingContent: boolean;
  @Input() public treeNodes: IForecastTreeNodeBase[] = [];
  @Input() public scrollPosition: number = 0;
  @Output() getListingEvent = new EventEmitter();
  @Output() activeNodeEvent = new EventEmitter<IForecastTreeNodeBase>();

  /* Angular tree controll and properties */
  treeControl = new FlatTreeControl<IForecastTreeNodeBase>(getNodeLevel, getIsNodeExpandable);
  dataSource: MatTreeFlatDataSource<IForecastTreeNodeBase, IForecastTreeNodeBase>;
  treeFlattener: MatTreeFlattener<IForecastTreeNodeBase, IForecastTreeNodeBase> = null;
  dataSourceViewer: CollectionViewer = { viewChange: new Observable<ListRange>() };
  expandedNodeList: IForecastTreeNodeBase[] = [];

  /* Misc */
  private screenHeight: number;
  public get isAdmin$() { return this.authService.isAdmin$; }

  public getNode(node: IForecastTreeNodeBase) { return node; }
  public getPNode(node: IForecastTreeNodeProject) { return node; }

  constructor(
    public env: EnvironmentService,
    public navService: NavigationService,
    public cd: ChangeDetectorRef,
    private navComponentService: NavigationComponentService,
    private projectService: ProjectFrontendService,
    private store: Store,
    private dialogService: DialogService,
    private variableDialogs: VariableDialogService,
    private automationDialogs: AutomationDialogService,
    private status: StatusService,
    private removalService: RemovalWarningsFrontendService,
    private forecastDialogService: ForecastDialogService,
    public authService: AuthFrontendService
  ) {
    this.onResize();
    this.setup();
  }

  public ngOnChanges(): void {
    if (this.treeNodes === undefined) {
      this.dataSource.data = [];
      return;
    }

    this.sortTreeNodes(this.treeNodes);
    this.dataSource.data = this.treeNodes;
    this.restoreExpandedNodes();
  }

  private sortTreeNodes(nodes: IForecastTreeNodeBase[]) {
    nodes.forEach(x => x.Children?.length > 0 && this.sortTreeNodes(x.Children));
    nodes.sort((a, b) => a.Name.localeCompare(b.Name));
  }

  private setup() {
    this.treeFlattener = new MatTreeFlattener<IForecastTreeNodeBase, IForecastTreeNodeBase>(
      // TransformFn, LevelFn, ExpandedFn, ChildrenFn
      (n, l) => ({ ...n, level: l }), getNodeLevel, getIsNodeExpandable, (n) => n.Children
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.connect(this.dataSourceViewer).subscribe(data => this.expandedNodeList = data);
  }

  /**
   * Function that returns a project when a create-forecast button should be visible, or null otherwise.
   * @param currentNode The current node in the scrollable view-list
   * @param index The index of the current node
   * @returns A ProjecModel or null
   */
  public getProjectForCreateForecastButton(currentNode: IForecastTreeNodeBase, index: number) {
    // Tags never shows a create-forecast btn.
    if (currentNode.Type === 'tag') { return; }
    const nextNode = this.expandedNodeList[index + 1];
    // If next node is not a project, we are still showing content for the previous project.
    if (!!nextNode && nextNode.Type !== 'project') { return null; }
    const projNode = currentNode.Type === 'project' ? currentNode : this.expandedNodeList.slice(0, index).reverse().find(x => x.Type === 'project');
    const projNodeOpen = this.treeControl.isExpanded(projNode);
    // If the project-node is not open, no btn will be shown.
    if (!projNodeOpen) { return null; }
    const proj = this.getProject(projNode);
    const hasPermission = !!proj && proj.hasPermission('CAN_CREATE_FORECAST');
    return hasPermission ? proj : null;
  }

  /**
   * Given the height of the current window, calculate the height of the scrollable container.
   * We must leave room for:
   * - Title at the top (60px)
   * - Dropdown (63px)
   * - Create 'new' button at the bottom (41px)
   * @returns New container height in px.
   */
  public calculateContainerHeight(): string {
    const itemHeight = 30;
    const visibleItems = this.expandedNodeList.length || 0;
    const expandedProjects = this.expandedNodeList.filter(x => x.Type === 'project' && this.treeControl.isExpanded(x)).length;
    const numberOfItems = visibleItems + expandedProjects;
    let pinnedHeight = 0;

    if (document.querySelector('.pinned-forecasts')) {
      pinnedHeight = document.querySelector('.pinned-forecasts').clientHeight;
    }

    let maxHeightPx = this.screenHeight - 60 - 63 - 41 - pinnedHeight;
    const showScroll = numberOfItems * itemHeight >= maxHeightPx;

    let newHeight = showScroll ? maxHeightPx : numberOfItems * itemHeight;
    this.cdkVirtualScrollViewport?.checkViewportSize();
    return `${newHeight}px`;
  }

  public restoreExpandedNodes() {
    this.navComponentService.expandedNodes.forEach(node => {
      this.treeControl.expand(this.treeControl.dataNodes.find(n => n.ObjectId === node.ObjectId));
    });
    const nodes = this.expandedNodeList;
    if (!nodes || !nodes.length) { return; }
    const onNode = nodes.filter(x => x.Type === 'project').find(n => n.Children.some(f => this.navService.isOnForecast(f.ObjectId)));
    if (!onNode) { return; }
    this.treeControl.expand(this.treeControl.dataNodes.find(n => n.ObjectId === onNode.ObjectId));
  }

  private saveExpandedNodes() {
    this.navComponentService.expandedNodes = new Array<IForecastTreeNodeBase>();
    this.treeControl.dataNodes.forEach(node => {
      if (getIsNodeExpandable(node) && this.treeControl.isExpanded(node)) {
        this.navComponentService.expandedNodes.push(node);
      }
    });
  }

  public getProject(node: IForecastTreeNodeBase) {
    if (!node || node.Type !== 'project') return null;
    const proj = this.projectService.projectById(node.ObjectId);
    return proj;
  }

  public getProjectForForecast(node: IForecastTreeNodeForecast) {
    if (!node) return null;
    const proj = this.projectService.projectById(node.parent.ObjectId);
    return proj;
  }

  public editProject(project: ProjectModel, view?: string) {
    this.store.dispatch(new OpenEditProjectModal(project, view));
  }

  public removeProject(project: ProjectModel) {
    const ref = this.dialogService.openConfirmDialog({
      Title: 'Remove project?',
      Message: 'Are you sure you want to remove this project?',
      ConfirmText: 'Remove',
      Style: 'warn',
      WarningCheck: this.removalService.getRemovalWarningsForProject(project.ProjectId),
      ExtraWarning: 'WARNING: The following entities are dependent on forecasts in this project'
    }, { width: '522px' });

    ref.subscribe((proceed: boolean) => {
      if (!proceed) { return; }
      this.projectService.deleteProject(project)
        .then(() => {
          this.status.setMessage('Project removed', 'Success');
        })
        .catch(error => {
          this.status.setError(error);
        });
    });
  }

  public createForecast(project: ProjectModel) {
    this.forecastDialogService.openCreate({
      ProjectId: project?.ProjectId
    });
  }

  public openRefreshDialog(node: IForecastTreeNodeBase) {
    this.variableDialogs.openFVarUpdateDialog({
      type: node.Type as UpdateData.TargetTypes,
      ObjectId: node.ObjectId,
      ObjectName: node.Name,
      AutoStart: false
    });
  }

  public openSyncDataDialog(node: IForecastTreeNodeBase) {
    this.variableDialogs.openSyncDataDialog({
      type: node.Type as Syncable.TargetTypes,
      ObjectId: node.ObjectId,
      ObjectName: node.Name
    });
  }

  public openCalcResultsDialog(node: IForecastTreeNodeBase) {
    this.automationDialogs.openCalcResultsDialog({
      type: node.Type as Automation.Targets,
      ObjectId: node.ObjectId,
      ObjectName: node.Name
    });
  }

  public clickedNode(node: IForecastTreeNodeBase, $event: MouseEvent) {
    if (node.Type === 'forecast') { this.activeNodeEvent.emit(node); }
    if (node.Type !== 'forecast') {
      this.treeControl.toggle(node);
      node.isOpen = this.treeControl.isExpanded(node);
      this.saveExpandedNodes();
    }
    if ($event == null) { return; }
    $event?.stopPropagation();
  }

  public isActive(node: IForecastTreeNodeBase) {
    return node && node.isActive;
  }

  public toggleProjectMenu(project: IForecastTreeNodeProject, { open, event }) {
    project.isActive = open;
    event?.stopPropagation();
  }

}
