import { ImportedHistoricBaseEventModel } from '@core/store/historic-event/models/imported/imported-historic-base-event.model';
import { EditImportedHEventModalOpts } from './edit-imported-historic-event-modal.options';

export class OpenEditImportedHistoricEventModal {
  public static readonly type = '[MODAL] Open edit imported historic event modal';
  data: EditImportedHEventModalOpts;
  constructor(
    forecastVersionId: string,
    baseEventId?: string,
    fromHistoricEventId?: string,
    onBack?: Function,
    navToEventMissingCorrelation: boolean = false,
    unSavedEvent?: ImportedHistoricBaseEventModel
  ) { this.data = { forecastVersionId, baseEventId, fromHistoricEventId, onBack, navToEventMissingCorrelation, unSavedEvent }; }
}
