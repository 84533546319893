import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ReportEntryModel } from '@core/store/reports/models/report.model';

export interface ReportEntrySettingsDialogData {
  entry: ReportEntryModel;
}

export interface UpdatedReportEntrySettings {
  ResultId: string;
}

@Component({
  selector: 'indicio-report-choose-result-dialog',
  templateUrl: 'report-choose-result.dialog.html'
})
export class ReportEntrySettingsDialogComponent {
  public static Id: string = 'ReportEntrySettingsDialogComponent';

  newResultId: string;

  public get updatedSettings(): UpdatedReportEntrySettings {
    return {
      ResultId: this.newResultId
    };
  }

  constructor(
    public dialogRef: MatDialogRef<ReportEntrySettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportEntrySettingsDialogData,
    public env: EnvironmentService
  ) {
    if (data.entry.SelectedResultId) {
      this.newResultId = data.entry.ForecastResults.find(x => x.ForecastResultId === data.entry.SelectedResultId).ForecastResultId;
    }
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public save() {
    this.dialogRef.close(this.updatedSettings);
  }
}
