<indicio-dialog-base>
  <h1 mat-dialog-title>Report settings</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="!loading">

      <mat-form-field *ngIf="forecastToAddCount > 0">
        <mat-select [value]="forecastsToAdd"
                    [multiple]="true"
                    placeholder="Add forecasts to report"
                    required>
          <mat-optgroup *ngFor="let project of allProjectsAvailable"
                        label="Project: {{ project.ProjectName }}">
            <mat-option [value]="forecast.ForecastId"
                        (onSelectionChange)="setForecasts($event)"
                        [title]="forecast.Name"
                        [disabled]="forecast.disabled"
                        *ngFor="let forecast of project.Forecasts">{{ forecast.Name }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>


      <div *ngIf="forecastToAddCount === 0">
        No more forecasts to add given the start date of the report.
      </div>
    </ng-container>


    <div style="height: 30px;"
         *ngIf="loading">
      <i class="spinner ion-load-c"></i> Loading...
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Cancel</button>
    <button *ngIf="forecastToAddCount > 0"
            mat-flat-button
            (click)="save()">
      Save
    </button>
  </div>
</indicio-dialog-base>
