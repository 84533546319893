import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { CompanyActions } from '@core/store/company/company.actions';
import { ForecastActions } from '@core/store/forecast/forecast.actions';
import { FrontendBaseService } from '@core/store/frontend-base.service';
import { ForecastDialogService } from '@dialogs/forecast/forecast-dialogs.service';
import { DisplayValue } from '@modules/lang/types/display-value';
import { NavigationActions } from '@modules/root/components/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { ArrayUtils } from '@shared/utils/array.utils';
import { HierarchyActions } from '../constants/hierarchy.actions';
import { CreateReconciliationDTO, HierarchyReconciliationDTO, UpdateReconciliationDTO } from '../entities/dtos/hierarchy-reconciliation.dto';
import { HierarchyRelationDTO, PotentialForecastsForRelationDTO } from '../entities/dtos/hierarchy-relation.dto';
import { CreateHierarchyDTO, UpdateHierarchyDTO } from '../entities/dtos/hierarchy.dto';
import { HForecastTreeNode } from '../entities/models/hierarchical-forecast.tree-node';
import { HierarchyModel } from '../entities/models/hierarchy.model';
import { HierarchicalForecastState } from '../hierarchical-forecast.state';
import { HierarchicalForecastMapper } from '../utils/hierarchy.mapper';
import { HierarchyUtils } from '../utils/hierarchy.utils';
import { HierarchicalForecastBackendService } from './hierachical-forecast.backend.service';

const TREE_GENERATION_TRIGGERS = [
  HierarchyActions.GetSuccess,
  HierarchyActions.UpdateRelationSuccess,
  HierarchyActions.RemoveRelationSuccess,
  HierarchyActions.GetRelationSuccess,
  HierarchyActions.RemoveSuccess,
  HierarchyActions.RemoveReconciliation,
  HierarchyActions.TriggerGenerateTree,
];

@Injectable({
  providedIn: 'root'
})
export class HierarchicalForecastFrontendService extends FrontendBaseService<HierarchicalForecastBackendService> {

  public get potentials() { return this.store.selectSnapshot(HierarchicalForecastState.potentials); }
  public get hierarchies() { return this.store.selectSnapshot(HierarchicalForecastState.all); }

  // Status
  public treeGenerated = false;
  private companyLoaded = false;

  // State fields
  public treeNodes: HForecastTreeNode[] = [];

  constructor(
    protected store: Store,
    protected dialog: DialogService,
    protected backend: HierarchicalForecastBackendService,
    private mapper: HierarchicalForecastMapper,
    private actions: ActionService,
    private forecastDialogService: ForecastDialogService
  ) {
    super(store, dialog, backend);
    this.actions.dispatched(...TREE_GENERATION_TRIGGERS).subscribe(() => this.generateTree());
    this.actions.dispatched(
      HierarchyActions.GetReconciliationSuccess,
      HierarchyActions.RemoveReconciliation,
      HierarchyActions.GetSuccess,
      HierarchyActions.RemoveSuccess,
      HierarchyActions.UpdateRelationSuccess,
      HierarchyActions.GetRelationSuccess,
      HierarchyActions.RemoveRelationSuccess,
      CompanyActions.ChangedActiveCompany,
      NavigationActions.ForceForecastDrawerReload)
      .subscribe(() => this.getOrSetupTree(true));

    this.actions.dispatched(
      ForecastActions.UpdatedForecast,
      ForecastActions.AddedMainVariable)
      .subscribe((action: ForecastActions.UpdatedForecast) => {
        if (this.hierarchies.find(x => x.Relations.some(y => y.ForecastId === action.forecastId))) {
          this.getOrSetupTree(true);
        }
      });
  }

  public getOrSetupTree(force: boolean = false) {
    if (this.companyLoaded && !force) {
      return Promise.resolve(this.treeNodes);
    }
    return this.getAllInCompany()
      .then(() => {
        this.generateTree();
        this.companyLoaded = true;
        return this.treeNodes;
      });
  }

  public getAllInCompany() {
    return this.backend.getAllInCompany()
      .then(hierarchies => {
        this.store.dispatch(new HierarchyActions.GetAllSuccess(hierarchies));
        return hierarchies;
      });
  }

  public getOrFetch(hierarchyId: string) {
    return this.backend.get(hierarchyId)
      .then(hierarchy => {
        this.store.dispatch(new HierarchyActions.GetSuccess(hierarchy));
        return hierarchy;
      });
  }

  public get(hierarchyId: string) {
    return this.backend.get(hierarchyId)
      .then(hierarchy => {
        this.store.dispatch(new HierarchyActions.GetSuccess(hierarchy));
        return hierarchy;
      });
  }

  public create(dto?: CreateHierarchyDTO) {
    if (!dto) {
      return this.dialog.openCreateOrUpdateHierarchyDialog({ hierarchy: null }).toPromise();
    }
    return this.backend.create(dto).then(hFcast => {
      this.store.dispatch(new HierarchyActions.GetSuccess(hFcast));
      return true;
    });
  }

  public update(hierarchy: HierarchyModel, updateDto?: UpdateHierarchyDTO): Promise<boolean> {
    if (!updateDto) {
      return this.dialog.openCreateOrUpdateHierarchyDialog({ hierarchy }).toPromise();
    }
    return this.backend.update(hierarchy.HierarchyId, updateDto).then(forecast => {
      this.store.dispatch(new HierarchyActions.GetSuccess(forecast));
      return true;
    });
  }

  public createRelation(parent: HierarchyRelationDTO): Promise<boolean> {
    return this.backend.addRelation(parent.HierarchyId, { ParentRelationId: parent.RelationId })
      .then(updated => {
        this.store.dispatch(new HierarchyActions.GetSuccess(updated));
        return true;
      });
  }

  public createReconciliation(hierarchy: HierarchyModel, dto?: CreateReconciliationDTO) {
    if (!dto) {
      return this.dialog.openCreateOrUpdateReconciliationDialog({ hierarchy }).toPromise();
    }

    return this.backend.createReconciliation(hierarchy.HierarchyId, dto)
      .then(reconciliation => {
        this.store.dispatch(new HierarchyActions.GetReconciliationSuccess(reconciliation));
        return true;
      });
  }

  public getAvailableResults(hierarchyId: string) {
    return this.backend.getAvailableResults(hierarchyId).then(results => {
      this.store.dispatch(new HierarchyActions.GetResultsSuccess(hierarchyId, results));
      return results;
    });
  }

  public getPotentialForecasts(hierarchyId: string) {
    if (this.potentials && this.potentials[hierarchyId]) {
      return Promise.resolve(this.potentials[hierarchyId]);
    }
    return this.backend.getPotentialForecasts(hierarchyId).then(results => {
      this.store.dispatch(new HierarchyActions.GetPotentialForecasts(hierarchyId, results));
      return results;
    });
  }

  /**
   * Edit / Update relation
   */
  public updateRelation(relation: HierarchyRelationDTO) {
    const hierarchy = this.hierarchies.find(x => x.HierarchyId === relation.HierarchyId);
    if (!hierarchy) { return Promise.reject('Hierarchy not found - reload the page and try again.'); }
    return this.getPotentialForecasts(relation.HierarchyId)
      .then(potentials => this.selectForecastDropdown(potentials))
      .then(newForecastId => {
        if (!newForecastId) { return false; }
        if (newForecastId === 'new') {
          return this.forecastDialogService.openCreate({
            Horizon: hierarchy.Horizon,
            Periodicity: hierarchy.Periodicity,
            UserCanChangeSettings: false,
            HideTemplateOption: true
          }).then(newId => {
            if (!newId) { return false; }
            return this.forceUpdateRelation(relation, newId);
          });
        }
        return this.forceUpdateRelation(relation, newForecastId);
      });
  }

  public forceUpdateRelation(relation: HierarchyRelationDTO, newForecastId: string) {
    const dto = this.mapper.mapNewRelation(relation, newForecastId);
    return this.backend.updateRelation(relation.HierarchyId, dto)
      .then(updated => {
        this.store.dispatch(new HierarchyActions.UpdateRelationSuccess(updated));
        return true;
      });
  }

  //#region Admin
  public getAllHierarchiesInSystem() {
    return this.backend.getAllHierarchiesInSystem()
      .then(hierarchies => {
        return hierarchies;
      });
  }

  public getCompanyHierarchiesInSystem(companyId: string) {
    return this.backend.getCompanyHierarchiesInSystem(companyId)
      .then(hierarchies => {
        return hierarchies;
      });
  }
  //#endregion

  /**
   * Remove hierarchy
   */
  public remove(hierarchy: HierarchyModel) {
    // If the hierarchy is empty, we do not ask the user for removal confirmation
    if (hierarchy.Relations.some(x => !x.ForecastId)) {
      return this.forceRemove(hierarchy.HierarchyId);
    }
    return this.confirmRemove('Remove hierarchy', 'Do you want to delete this hierarchy?')
      .then(ans => {
        if (!ans) { return Promise.resolve(false); }
        return this.forceRemove(hierarchy.HierarchyId);
      });
  }

  public removeById(hierarchyId: string) {
    return this.confirmRemove('Remove hierarchy', 'Do you want to delete this hierarchy?')
      .then(ans => {
        if (!ans) { return Promise.resolve(false); }
        return this.forceRemove(hierarchyId);
      });
  }

  public forceRemove(hierarchyId: string) {
    return this.backend.remove(hierarchyId).then(() => {
      this.store.dispatch(new HierarchyActions.RemoveSuccess(hierarchyId));
      return true;
    });
  }

  /**
   * Remove hierarchy relation
   */
  public removeRelation(treeNode: HForecastTreeNode) {
    if (!treeNode.Relation.ForecastId && !treeNode.children.length) {
      return this.forceRemoveRelation(treeNode.Relation.HierarchyId, treeNode.Relation.RelationId);
    }
    return this.confirmRemove('Remove hierarchy relation', 'Do you want to delete this relation? This will also remove any child relations.')
      .then(ans => {
        if (!ans) { return Promise.resolve(false); }
        return this.forceRemoveRelation(treeNode.Relation.HierarchyId, treeNode.Relation.RelationId);
      });
  }

  public forceRemoveRelation(hierarchyId: string, relationId: string) {
    return this.backend.removeRelation(hierarchyId, relationId).then(hierarchy => {
      this.store.dispatch(new HierarchyActions.RemoveRelationSuccess(hierarchy));
      return true;
    });
  }

  /**
   * Update reconciliation
   */
  public updateReconciliation(hierarchy: HierarchyModel, reconciliation: HierarchyReconciliationDTO, updatedDto?: UpdateReconciliationDTO): Promise<boolean> {
    if (!updatedDto) {
      return this.dialog.openCreateOrUpdateReconciliationDialog({ hierarchy, reconciliation }).toPromise();
    }
    return this.backend.updateReconciliation(reconciliation.HierarchyId, reconciliation.HierarchyReconciliationId, updatedDto)
      .then(updated => {
        this.store.dispatch(new HierarchyActions.UpdateReconciliationSuccess(updated));
        return true;
      });
  }

  /* NOTE: This function should not be called 'automatically' after changes has been made. Backend decides when to automatically recalculate
     the reconciliation results.
     However; this function may be explicitly called by the user clicking on a "recalculate" button or any such thing.
   */
  public triggerReconciliation(hierarchyId: string, reconciliationId: string) {
    return this.backend.triggerReconciliation(hierarchyId, reconciliationId);
  }

  /**
   * Remove reconciliation
   */
  public removeReconciliation(hierarchyId: string, reconciliationId: string) {
    return this.confirmRemove('Remove reconciliation', 'Do you want to delete this hierarchy reconciliation?')
      .then(ans => {
        if (!ans) { return Promise.resolve(false); }
        return this.forceRemoveReconciliation(hierarchyId, reconciliationId);
      });
  }

  public forceRemoveReconciliation(hierarchyId: string, reconciliationId: string) {
    return this.backend.removeReconciliation(hierarchyId, reconciliationId).then(() => {
      this.store.dispatch(new HierarchyActions.RemoveReconciliation(hierarchyId, reconciliationId));
      return true;
    });
  }

  /**
   * Reconciliation report
   */
  public downloadReport(hierarchyReconciliation: HForecastTreeNode) {
    return this.backend.downloadReport(hierarchyReconciliation);
  }


  // #########################
  // #    Private helpers    #
  // #########################

  /**
   * Note that this functions asks the user for input, and should offer viable options.
   *
   * @param values A collection of potential forecasts that can be used in the hierarchy
   * @returns Either null, "new" or a ForecastId
   */
  private selectForecastDropdown(values: PotentialForecastsForRelationDTO[]) {
    const displayValues = values.map(pf => new DisplayValue<string>(
      pf.ForecastId,
      `${pf.ForecastName}`,
      `Forecast: ${pf.ForecastName}<br> Main variable: ${pf.SourceVariableName}`));
    const ref = this.dialog.openSearchDropdownDialog({
      Title: 'Create or select a forecast',
      Label: 'Select forecast',
      Ingress: 'This relation in the hierarchy will get its result from the specified forecast.',
      Options: [
        new DisplayValue<string>('new', 'Create new forecast', 'Create a new forecast with the correct settings.'),
        ...ArrayUtils.sortArrayAlphabetically(displayValues, 'Display'),
      ],
      CancelButtonText: 'Cancel',
      ConfirmButtonText: 'Ok',
      CloseAndSaveOnSelection: 'new'
    });

    return ref.toPromise()
      .then((ans: DisplayValue<string>) => {
        if (!ans) { return null; }
        return ans.Value;
      });
  }

  /**
   * Generates a new tree structure that is used by the hierarchy-tree component.
   * Uses all currently synced hierarchies to construct the tree, using any prevously synced nodes
   * to set open/active/menu state for the new tree.
   *
   * Note: This will set a new array-reference for treeNodes
   */
  private generateTree() {
    const empty = this.hierarchies.filter(x => x.Relations.length === 1 && x.Relations[0].ForecastId == null);
    const ordered = this.hierarchies
      .filter(x => x.Relations.length >= 1 && x.Relations[0].ForecastId != null)
      .sort((a, b) => a.getTopRelation().ForecastName.localeCompare(b.getTopRelation().ForecastName));

    this.treeNodes = [...ordered, ...empty].map(x => {
      const oldTree = this.treeNodes.find(tn => x.HierarchyId === tn.Relation.HierarchyId);
      return HierarchyUtils.generateTree(x, oldTree);
    });
    this.store.dispatch(new HierarchyActions.TreeGenerated);
    this.treeGenerated = true;
  }
}


