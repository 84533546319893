import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { ClientActions, GetRemoteDataSourceSuccessAction } from '@core/store/client/client.actions';
import { NewRemoteDataSourceModelModel } from '@core/store/providers/models/new-remote-data-source-model.model';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { ProviderService } from '@core/store/providers/provider.service';
import { RemoteDataMapper } from '@core/store/providers/remote-data.mapper';
import { UpdateRemoteDataSourceDTO } from './dtos/remote-data-source.dto';

@Injectable({
  providedIn: 'root'
})
export class ProviderFrontendService {

  activeSource: RemoteDataSourceModel;
  availableDataSources: RemoteDataSourceModel[] = [];

  constructor(
    private backend: ProviderService,
    private remoteMapper: RemoteDataMapper,
    private store: Store
  ) { }

  public getRemoteSources() {
    return this.backend.getRemoteSources().then(sources => {
      this.availableDataSources = sources;
      this.store.dispatch(new ClientActions.GetRemoteSources(sources));
      return sources;
    });
  }

  public createDataSource(details: NewRemoteDataSourceModelModel) {
    const source = this.remoteMapper.toNewDataSource(details);
    return this.backend.addDataSource(source)
      .then(s => {
        this.store.dispatch(new GetRemoteDataSourceSuccessAction(s));
        this.availableDataSources.push(s);
        return s;
      });
  }

  public editDataSource(editedSource: UpdateRemoteDataSourceDTO) {
    return this.backend.editDataSource(editedSource.RemoteDataSourceId, editedSource)
      .then(source => {
        this.store.dispatch(new GetRemoteDataSourceSuccessAction(source));
        this.availableDataSources.addOrUpdate(source);
        return source;
      });
  }
}
