import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { CreateNewsCommentDTO } from '@core/store/news/dtos/create-news-comment.dto';
import { CreateNewsPostDTO } from '@core/store/news/dtos/create-news-post.dto';
import { NewsCommentDTO } from '@core/store/news/dtos/news-comment.dto';
import { NewsListDTO } from '@core/store/news/dtos/news-list.dto';
import { NewsPostMetaDTO } from '@core/store/news/dtos/news-post-meta.dto';
import { NewsPostDTO } from '@core/store/news/dtos/news-post.dto';
import { UpdateNewsCommentDTO } from '@core/store/news/dtos/update-news-comment.dto';
import { UpdateNewsPostDTO } from '@core/store/news/dtos/update-news-post.dto';
import { NewsPostModel } from '@core/store/news/news-post.model';
import { NewsMapper } from '@core/store/news/news.mapper';

@Injectable({
  providedIn: 'root'
})
export class NewsBackendService {

  constructor(
    private http: HttpService,
    private mapper: NewsMapper
  ) { }

  public getLatestNewsPosts() {
    return this.http.get<NewsPostMetaDTO[]>(`news`)
      .then(resp => resp.body.map(newsPost => this.mapper.map(<NewsPostDTO> { Meta: newsPost })));
  }

  public getNewsPosts(skip: number, take: number) {
    return this.http.get<NewsListDTO>(`news/${skip}/${take}`)
      .then(resp => this.mapper.mapList(resp.body));
  }

  public getNewsPost(id: string) {
    return this.http.get<NewsPostDTO>(`news/${id}`)
      .then(resp => this.mapper.map(resp.body));
  }

  public createNewsPost(newsPost: CreateNewsPostDTO) {
    return this.http.post<NewsPostDTO>('news', newsPost)
      .then(resp => this.mapper.map(resp.body));
  }

  public updateNewsPost(newsPost: UpdateNewsPostDTO) {
    return this.http.put<NewsPostDTO>('news', newsPost)
      .then(resp => this.mapper.map(resp.body));
  }

  public deleteNewsPost(newsPost: NewsPostModel) {
    return this.http.delete<null>(`news/${newsPost.Meta.NewsPostId}`);
  }

  public createComment(comment: CreateNewsCommentDTO) {
    return this.http.post<NewsCommentDTO>(`news/${comment.NewsPostId}/comments`, comment)
      .then(resp => this.mapper.mapComment(resp.body));
  }

  public updateComment(comment: UpdateNewsCommentDTO) {
    return this.http.put<null>(`news/comments`, comment);
  }

  public deleteComment(commentId: string) {
    return this.http.delete<null>(`news/comments/${commentId}`);
  }

  public replyComment(commentId: string, comment: CreateNewsCommentDTO) {
    return this.http.post<NewsCommentDTO>(`news/comments/${commentId}`, comment)
      .then(resp => this.mapper.mapComment(resp.body));
  }
}
