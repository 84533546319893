<indicio-dialog-base>
  <h1 mat-dialog-title>
    <ng-container *ngIf="!isUpdate; else updateTitle">Create reconciliation for hierarchy</ng-container>
    <ng-template #updateTitle>Update reconciliation "{{ data.reconciliation.Name }}"</ng-template>
    <div class="subtitle"
         *ngIf="!!hierarchy.ReconciliationStartDate">
      Start date: {{ hierarchy.ReconciliationStartDate | dateFormat }}
    </div>
  </h1>

  <div mat-dialog-content>
    <div *ngIf="!isLoading; else showLoader"
         class="hierarchical-dialog-container">
      <div class="ctc-options">
        <ng-container *ngTemplateOutlet="reconciliateTemplate"></ng-container>
      </div>
    </div>
    <br>
  </div>

  <div mat-dialog-actions>
    <button mat-button
            (click)="close()">
      Cancel
    </button>
    <button mat-flat-button
            *ngIf="!hasError"
            data-test-id="reconciliate-h-fcast-button-dialog"
            color="primary"
            [disabled]="isLoading"
            (click)="isUpdate ? update() : create()">
      <i *ngIf="isLoading"
         class="ion-load-c spinner"></i>
      <span *ngIf="!isLoading">
        {{ isUpdate ? recalcNeeded ? 'Save and calculate' : 'Save' : 'Create' }}</span>
    </button>
  </div>
</indicio-dialog-base>

<ng-template #reconciliateTemplate>
  <ng-container *ngIf="!hasError; else showHasError">
    <indicio-modal-option-row [stacked]="true"
                              optionTitle="Name"
                              *ngIf="isUpdate">
      <mat-form-field>
        <input type="text"
               class="material"
               matInput
               autocomplete="off"
               [(ngModel)]="updateDto.Name"
               name="Name"
               minlength="4"
               required>
      </mat-form-field>
    </indicio-modal-option-row>

    <indicio-modal-option-row [stacked]="true"
                              optionTitle="Reconciliation method">
      <div class="col-xs-8">
        <mat-form-field>

          <mat-select [(value)]="dto.Method"
                      required>
            <mat-option [value]="res.Value"
                        [title]="res.Description"
                        *ngFor="let res of reconciliationMethods">{{ res.Display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-xs-3"
           style="padding-top:16px">Non-negative</div>
      <div class="col-xs-1"
           style="padding-top:12px">
        <mat-slide-toggle data-test-id='auto-seasonal-update-toggle-btn'
                          [checked]="dto.NonNegative"
                          [indicioTippy]="'Force the reconciliation to produce non-negative forecasts.'"
                          [(ngModel)]="dto.NonNegative">
        </mat-slide-toggle>
      </div>
    </indicio-modal-option-row>

    <ng-container *ngTemplateOutlet="resultsTemplate"></ng-container>
  </ng-container>
</ng-template>

<ng-template #resultsTemplate>
  <div class="row extra-margin-top">
    <div class="col-xs-12">

      <div class="data-table">
        <div class="data-table__header">
          <div class="column header"
               style="width: 40px;">#</div>
          <div class="column header left-align"
               style="width: 280px;">Forecast</div>
          <div class="column header"
               style="width: 230px;">Forecast result</div>
          <!-- <div class="column"
               style="width: 50px;">
            &nbsp;
          </div> -->
        </div>

        <div class="data-table__body"
             [style.maxHeight.px]="301">
          <div class="body__row body__row--center-height body__row--max-height-30"
               *ngFor="let relation of allRelations let i = index">
            <div class="column"
                 style="width: 40px;">{{ i + 1 }}</div>
            <div class="column left-align crop-string"
                 [style]="{ width: relation.availableResults?.length ? '280px' : '220px' }">
              {{ relation.ForecastName }}
            </div>
            <div class="column"
                 [style]="{ width: relation.availableResults?.length ? '230px' : '280px' }">
              <mat-form-field panelClass="data-table-panel-opts"
                              *ngIf="relation.availableResults?.length; else noResultsTemplate">
                <mat-select [(value)]="relation.selectedResultId"
                            panelClass="fitted-select"
                            (selectionChange)="onResultChange(relation, $event.value)"
                            required>
                  <mat-option [value]="res.ForecastResultId"
                              [title]="res.ResultName"
                              *ngFor="let res of relation.availableResults">{{ res.ResultName
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <ng-template #noResultsTemplate>
                <div style="color: red; font-size: 13px;">
                  <span class="no-results-text">No results available, click <span class="dotted-underline pointer"
                          (click)="gotoSummary(relation)">here</span> to
                    save one.</span>
                </div>
              </ng-template>
            </div>
            <!--<div class="column"
                 style="width: 50px;">
               <div class="ion-trash-a h-red cursor-pointer"
                 [ngClass]="{ disabled: pending === true || importedCount > 0 }"
                 (click)="removeVariable(variable)"></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showHasError>
  <div style="margin-top: 30px">
    One or more errors exists - cannot create reconciliation
  </div>
</ng-template>

<ng-template #showLoader>
  <i class="ion-load-c spinner info-loader "></i>
</ng-template>
