import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ForecastResultMapper } from '@core/store/forecast-result/forecast-result.mapper';
import { ForecastMetaDTO } from '@core/store/forecast/dtos/forecast/forecast-meta-dto';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { DateUtils } from '@shared/utils/date.utils';
import { ForecastVersionDateRangeDTO, ForecastVersionMetaDTO } from '../dtos/forecast-version/forecast-version-meta-dto';
import { ForecastDTO } from '../dtos/forecast/forecast-dto';


@Injectable({
  providedIn: 'root'
})
export class ForecastSubMapper {

  constructor(
    private envService: EnvironmentService,
    private resultMapper: ForecastResultMapper
  ) { }

  public map(dto: ForecastDTO, model?: ForecastModel): ForecastModel {
    model = this.mapMetaToModel(dto.Meta, model);
    model = this.handleVersions(model, dto.ForecastVersions);
    model.activeVersionId = model.getLatestVersion().ForecastVersionId;
    model.ModelFilterSettings = dto.ModelFilterSettings;
    return model;
  }

  private handleVersions(model: ForecastModel, versions: ForecastVersionMetaDTO[]): ForecastModel {
    versions.forEach(v => {
      const idx = model.ForecastVersions.findIndex(x => x.ForecastVersionId === v.ForecastVersionId);
      if (idx !== -1) {
        this.mapVersionMeta(v, model.ForecastVersions[idx]);
      } else {
        const newVersion = this.mapVersionMeta(v);
        model.ForecastVersions.push(newVersion);
      }
    });

    model.ForecastVersions = model.ForecastVersions
      .sort((a, b) => a.VersionNumber - b.VersionNumber);
    return model;
  }

  public mapMetaToModel(dto: ForecastMetaDTO, model?: ForecastModel) {
    model = Object.faMapTo(model || new ForecastModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.periodicity = this.envService.getPeriodicity(dto.Periodicity);
    model.Results = dto.Results.map(r => this.resultMapper.mapNowcastResult(r));
    return model;
  }

  public mapVersionMeta(dto: ForecastVersionMetaDTO, model?: ForecastVersionModel) {
    model = Object.faMapTo(model || new ForecastVersionModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.periodicity = this.envService.getPeriodicity(dto.Periodicity);
    model.StepChosen = dto.StepChosen;

    model = this.mapDateInfo(dto, model);
    return model;
  }

  public mapDateInfo(dto: ForecastVersionDateRangeDTO, fVersion: ForecastVersionModel) {
    // Properties
    fVersion.Horizon = dto.Horizon;
    fVersion.MaxLag = dto.MaxLag;
    fVersion.OverlappingValueCount = dto.OverlappingValueCount;
    fVersion.ModelRollingWindowRecalc = dto.ModelRollingWindowRecalc;
    fVersion.UseModelRollingWindow = dto.UseModelRollingWindow;
    fVersion.OOSEnabled = dto.OOSEnabled;
    // Dates
    fVersion.LatestForecastStartDate = DateUtils.newDate(dto.LatestForecastStartDate);
    fVersion.EarliestSharedDate = DateUtils.newNullableDate(dto.EarliestSharedDate);
    fVersion.DataStartDate = DateUtils.newNullableDate(dto.DataStartDate);
    fVersion.StartDate = DateUtils.newDate(dto.StartDate);
    fVersion.TestStartDate = DateUtils.newNullableDate(dto.TestStartDate);
    fVersion.DataUntilDateRequirement = DateUtils.newDate(dto.DataUntilDateRequirement);
    fVersion.LastResultDate = DateUtils.newDate(dto.LastResultDate);

    // Set dates-array (used in date-range picker)
    fVersion.setAllDatesArray();
    return fVersion;
  }
}
