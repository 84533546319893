import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearStateAction } from '../auth/auth.actions';
import { CompanyActions } from '../company/company.actions';
import { HierarchyActions } from './constants/hierarchy.actions';
import { PotentialForecastsForRelationDTO } from './entities/dtos/hierarchy-relation.dto';
import { HierarchyModel } from './entities/models/hierarchy.model';

const CLEAR_POTENTIALS_TRIGGERS = [
  HierarchyActions.RemoveSuccess,
  HierarchyActions.RemoveRelationSuccess,
  HierarchyActions.UpdateRelationSuccess
];

class HierarchicalForecastStateModel {
  HierarchicalForecasts: HierarchyModel[];
  PotentialForecasts: { [key: string]: PotentialForecastsForRelationDTO[]; };
}
@State<HierarchicalForecastStateModel>({
  name: 'hierarchies',
  defaults: {
    HierarchicalForecasts: [],
    PotentialForecasts: {}
  }
})
@Injectable()
export class HierarchicalForecastState {

  constructor() { }

  @Selector()
  static all(state: HierarchicalForecastStateModel) { return state.HierarchicalForecasts; }

  @Selector()
  static potentials(state: HierarchicalForecastStateModel) { return state.PotentialForecasts; }

  @Action(HierarchyActions.GetAllSuccess)
  getAllSuccess(ctx: StateContext<HierarchicalForecastStateModel>, action: HierarchyActions.GetAllSuccess) {
    ctx.patchState({ HierarchicalForecasts: [...action.hierarchies] });
  }

  @Action(HierarchyActions.GetReconciliationSuccess)
  getReconciliation(ctx: StateContext<HierarchicalForecastStateModel>, action: HierarchyActions.GetReconciliationSuccess) {
    const hierarchies = ctx.getState().HierarchicalForecasts;
    const hierarchy = hierarchies.find(x => x.HierarchyId === action.reconciliation.HierarchyId);
    if (hierarchy) {
      hierarchy.Reconciliations.push(action.reconciliation);
    }
    ctx.patchState({ HierarchicalForecasts: [...hierarchies] });
  }

  @Action([
    HierarchyActions.RemoveRelationSuccess,
    HierarchyActions.GetSuccess,
    HierarchyActions.UpdateRelationSuccess,
    HierarchyActions.UpdateReconciliationSuccess
  ])
  getSuccess(ctx: StateContext<HierarchicalForecastStateModel>, action: HierarchyActions.IHasHierarchy) {
    const hierarchies = ctx.getState().HierarchicalForecasts;
    hierarchies.addOrUpdate(action.hierarchy);
    ctx.patchState({ HierarchicalForecasts: [...hierarchies] });
  }

  @Action(HierarchyActions.RemoveSuccess)
  removeSuccess(ctx: StateContext<HierarchicalForecastStateModel>, action: HierarchyActions.RemoveSuccess) {
    const forecasts = ctx.getState().HierarchicalForecasts;
    forecasts.removeById(action.forecastId);
    ctx.patchState({ HierarchicalForecasts: [...forecasts] });
  }

  @Action(HierarchyActions.GetPotentialForecasts)
  getPotentialForecasts(ctx: StateContext<HierarchicalForecastStateModel>, action: HierarchyActions.GetPotentialForecasts) {
    const potentialsList = ctx.getState().PotentialForecasts;
    potentialsList[action.hierarchyId] = action.dtos;
    ctx.patchState({ PotentialForecasts: potentialsList });
  }

  @Action(CLEAR_POTENTIALS_TRIGGERS)
  clearPotentialForecasts(ctx: StateContext<HierarchicalForecastStateModel>, action) {
    ctx.patchState({ PotentialForecasts: {} });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<HierarchicalForecastStateModel>) {
    ctx.patchState({ HierarchicalForecasts: [], PotentialForecasts: {} });
  }
}

