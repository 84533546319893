import { Def } from '@core/decorators/def';

export class NewsCommentModel {
  @Def() NewsCommentId: string;
  @Def() NewsPostId: string;
  @Def() Comment: string;
  @Def() AuthorName: string;
  @Def() AuthorId: string;
  @Def() CreatedDate: Date;
  @Def() ModifiedDate: Date;
  @Def() Replies: NewsCommentModel[];
}
