import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { RemovalWarningGroup } from '@core/services/removal-warnings/entities/removal-warning-group';
import { RemovalWarningType } from '@core/services/removal-warnings/entities/removal-warning.dto';
import { Subscription } from 'rxjs';
import { DialogActions } from '../../dialog.actions';

class ConfirmChoice {
  Name: string;
  Key?: string;
}

export interface ConfirmDialogData {
  Title: string;
  Message: string;
  ExtraWarning?: string;
  CancelText?: string;
  ConfirmText?: string;
  ConfirmFunction?: Function;
  CancelFunction?: Function;
  Style?: null | 'primary' | 'warn';
  WarningCheck?: Promise<RemovalWarningGroup[]>;
  HideCancel?: boolean;
  CloseOnClick?: boolean;
  ConfirmChoices?: ConfirmChoice[];
}

@Component({
  selector: 'indicio-confirm-dialog',
  templateUrl: 'confirm.dialog.html',
})
export class ConfirmDialogComponent {
  public static Id: string = 'ConfirmDialogComponent';

  private sub: Subscription = new Subscription();

  loading = false;
  warningGroups: RemovalWarningGroup[] = [];

  WARNING_TYPES = RemovalWarningType;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private actions: ActionService) {
    if (!data.CancelText) { data.CancelText = 'Cancel'; }
    if (!data.ConfirmText) { data.ConfirmText = 'Confirm'; }
    if (!data.CloseOnClick) { data.CloseOnClick = true; }
    if (data.WarningCheck) {
      this.loading = true;
      data.WarningCheck.then(warnings => {
        this.warningGroups = warnings;
      })
        .finally(() => {
          this.loading = false;
        });
    }

    this.sub.add(this.actions.dispatched(DialogActions.Close)
      .subscribe((action: DialogActions.Close) => {
        if (action.name === 'confirm') {
          this.dialogRef.close();
        }
      })
    );
  }

  onNoClick(): void {
    if (this.data.CancelFunction) {
      this.data.CancelFunction();
    }
    this.dialogRef.close(false);
  }

  onConfirmClick(val?: string) {
    if (this.data.ConfirmFunction) {
      this.data.ConfirmFunction(val || true);
    }
    if (!this.data.CloseOnClick) { return; }

    this.dialogRef.close(val || true);
  }
}
