import { Injectable } from '@angular/core';
import { HistoricValueDTO } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { FileMetaDataDTO } from '@core/store/file/dtos/file-meta-data-dto';
import { CreateGroupVariableDTO, GroupMetaEntry } from '@core/store/source-variable/dtos/create-group-variable-dto';
import { CreateSourceVariableDTO } from '@core/store/source-variable/dtos/create-source-variable-dto';
import { SourceVariableDTO, SourceVariableMetaDTO } from '@core/store/source-variable/dtos/source-variable-dto';
import { SourceVariableVersionDTO } from '@core/store/source-variable/dtos/source-variable-version-dto';
import { UpdateGroupVariableDTO, UpdateSourceVariableDTO } from '@core/store/source-variable/dtos/update-dtos';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import { SourceVariableModel, SourceVariableViewDTO } from '@core/store/source-variable/source-variable.model';
import { DateUtils } from '@shared/utils/date.utils';
import { ModelUtils } from '@shared/utils/forecast/model.utils';
import { CreateForecastBenchmarkDTO } from './dtos/create-forecast-benchmark-dto';
import { ForecastBenchmarkDTO } from './dtos/forecast-benchmark-dto';
import { ForecastBenchmarkModel } from './forecast-benchmark.model';


@Injectable({
  providedIn: 'root'
})
export class SourceVariableMapper {


  constructor(
    private envService: EnvironmentService,
  ) { }

  public map(dto: SourceVariableDTO, model?: SourceVariableModel): SourceVariableModel {
    model = this.mapSourceMeta(dto.Meta, model);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.ModifiedDate = DateUtils.newDate(dto.ModifiedDate);
    model.LastUpdated = dto.LastUpdated !== null ? DateUtils.newDate(dto.LastUpdated) : null;
    model.UpAggregationMethodId = dto.UpAggregationMethodId;
    model.DownAggregationMethodId = dto.DownAggregationMethodId;
    model.Revisions = dto.Revisions;

    if (dto.Versions.length === 0) {
      delete dto.Versions;
      model.Versions = [];
    } else {
      model.Versions = dto.Versions.map(x => this.mapVersion(x));
      model.Versions.sort((a, b) => {
        return a.CreatedDate < b.CreatedDate ? -1 : 1;
      });
    }

    if (dto.OldVersions && dto.OldVersions.length > 0) {
      model.OldVersions = dto.OldVersions.filter(x => !!x).map(x => this.mapVersion(x));
      model.OldVersions.sort((a, b) => {
        return a.CreatedDate < b.CreatedDate ? -1 : 1;
      });
    } else {
      delete dto.OldVersions;
      model.OldVersions = [];
    }

    model.GroupVariableBridges = dto.GroupVariableBridges;

    model.fetched = true;
    return model;
  }

  public mapViewDTO(dto: SourceVariableViewDTO) {
    dto.sourceType = this.envService.getSourceType(dto.SourceType);
    dto.periodicity = this.envService.getPeriodicity(dto.Periodicity);
    dto.fileId = dto.UploadedFileId || dto.RemoteFileId;
    dto.firstBaseObservation = DateUtils.newNullableDate(dto.FirstBaseObservation);
    dto.lastBaseObservation = DateUtils.newNullableDate(dto.LastBaseObservation);
    dto.createdDate = DateUtils.newDate(dto.CreatedDate);
    return dto;
  }

  public mapSourceMeta(dto: SourceVariableMetaDTO, model?: SourceVariableModel): SourceVariableModel {
    model = Object.faMapTo(model || new SourceVariableModel(), dto);
    model.LastBaseObservation = DateUtils.newNullableDate(dto.LastBaseObservation);
    model.fetched = false;
    model.sourceType = this.envService.getSourceType(dto.SourceType);
    return model;
  }

  public mapVersion(dto: SourceVariableVersionDTO): SourceVariableVersionModel {
    const model = Object.faMapTo(new SourceVariableVersionModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.FirstDate = DateUtils.newDate(dto.FirstDate);
    model.LastDate = DateUtils.newDate(dto.LastDate);
    model.periodicity = this.envService.getPeriodicity(dto.Periodicity);
    model.PlotValues.forEach(plotValue => DateUtils.MapIHasDate(plotValue));
    model.fetched = true;
    return model;
  }

  public toUpdateDTO(model: SourceVariableModel) {
    return Object.faMapTo(new UpdateSourceVariableDTO(), model);
  }

  public toFileMetaDto(model: SourceVariableVersionModel): FileMetaDataDTO {
    return Object.faMapTo(new FileMetaDataDTO(), model);
  }

  public toCreate(model: SourceVariableVersionModel): CreateSourceVariableDTO {
    const dto = Object.faMapTo(new CreateSourceVariableDTO(), model);
    dto.FileMetaData = this.toFileMetaDto(model);
    dto.Values = this.mapCreateValuesData(model.Values, model.MinValues, model.MaxValues, model.Dates);
    return dto;
  }

  public toCreateGroup(model: SourceVariableModel, identifiers: string[]): CreateGroupVariableDTO {
    const entries: GroupMetaEntry[] = model.GroupMetaIds.map((x, i) => {
      return { MetaId: x, Identifier: identifiers[i] };
    });
    return {
      DownAggregationMethodId: model.DownAggregationMethodId,
      GroupMetaEntries: entries,
      InfixExpression: model.InfixExpression,
      Periodicity: model.groupPeriodicity,
      UpAggregationMethodId: model.UpAggregationMethodId,
      Visibility: model.VisibilityLevelId,
      Name: model.Name
    };
  }

  public toEditGroup(model: SourceVariableModel, identifiers: string[]) {
    const entries: GroupMetaEntry[] = model.GroupMetaIds.map((x, i) => {
      return { MetaId: x, Identifier: identifiers[i] };
    });
    const dto: UpdateGroupVariableDTO = {
      DownAggregationMethodId: model.DownAggregationMethodId,
      GroupMetaEntries: entries,
      InfixExpression: model.InfixExpression,
      UpAggregationMethodId: model.UpAggregationMethodId,
      Visibility: model.VisibilityLevelId,
      Name: model.Name
    };
    return dto;
  }

  public mapCreateValuesData(values: HistoricValueDTO[], minValues: any[], maxValues: any[], dates: any[]) {
    const result: HistoricValueDTO[] = [];
    const parseValue = value => {
      if (value === '' || value === null) { return null; }
      return value.replace(',', '.');
    };

    if (values.length === dates.length) {
      for (let i = 0; i < dates.length; i++) {
        const value = parseValue(values[i]);
        const model = new HistoricValueDTO();
        model.V = value;
        model.D = dates[i];
        result.push(model);
      }
    } else if (minValues.length === dates.length && maxValues.length === dates.length) {
      for (let i = 0; i < dates.length; i++) {
        const max = parseValue(maxValues[i]);
        const min = parseValue(minValues[i]);
        let value = null;
        if (max && min) {
          value = (+min + +max) / 2;
        } else if (max) {
          value = +max;
        } else if (min) {
          value = +min;
        }
        const model = new HistoricValueDTO();
        model.V = value;
        model.D = dates[i];
        result.push(model);
      }
    } else { return null; }
    return result;
  }

  public toCreateBenchDTO(model: ForecastBenchmarkModel, forecastVersionId: string) {
    const dto = new CreateForecastBenchmarkDTO();
    dto.ForecastVersionId = forecastVersionId;
    dto.Name = model.Name;
    dto.SourceVariableId = model.SourceVariableId;
    dto.Values = model.Values.map(v => ({
      D: <any> DateUtils.newMoment(v.D).format('YYYY-MM-DD'),
      V: v.V,
      m: null
    }));
    return dto;
  }

  public toUpdateBenchDTO(model: ForecastBenchmarkModel) {
    const dto = Object.faMapTo(new ForecastBenchmarkDTO(), model);
    dto.Values = model.Values.map(v => ({
      D: <any> DateUtils.newMoment(v.D).format('YYYY-MM-DD'),
      V: v.V,
      m: null
    }));
    return dto;
  }

  public mapBenchmark(dto: ForecastBenchmarkDTO, i: number = 1) {
    const model = Object.faMapTo(new ForecastBenchmarkModel(), dto);
    model.Created = DateUtils.newDate(dto.Created);
    model.moment = DateUtils.newMoment(dto.Created);
    model.color = ModelUtils.getBenchColorForIndex(i);
    return model;
  }
}
