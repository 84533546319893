import { IHasDate, PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ALGSingleSeriesModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import Decimal from 'decimal.js-light';
import * as moment from 'moment/moment';

export type EValueFactor = {
  abbr: 'T' | 'B' | 'M' | 'k' | '' | 'm' | 'μ',
  description: string,
  factor: number,
  weight: 0;
};

export const ValueFactors: { T: EValueFactor, B: EValueFactor, M: EValueFactor, K: EValueFactor, None: EValueFactor, m: EValueFactor, mu: EValueFactor; } = {
  T: { abbr: 'T', description: '10^12', factor: 1_000_000_000_000, weight: 0 },
  B: { abbr: 'B', description: '10^9', factor: 1_000_000_000, weight: 0 },
  M: { abbr: 'M', description: '10^6', factor: 1_000_000, weight: 0 },
  K: { abbr: 'k', description: '10^3', factor: 1_000, weight: 0 },
  None: { abbr: '', description: '10^0', factor: 1, weight: 0 },
  m: { abbr: 'm', description: '10^-3', factor: 1 / 1_000, weight: 0 },
  mu: { abbr: 'μ', description: '10^-6', factor: 1 / 1_000_000, weight: 0 }
};
export namespace ValueUtils {

  export const isValidValueRe = /^-?[\s\d]+(,\d+)?(\.\d+)?$/;
  export const isPercentRe = /^-?[\s\d]+(,\d+)?(\.\d+)?\s{0,}\%\s{0,}$/;
  export const isLargeValueRe = /^(-?[\s\d]+(,\d+)?(\.\d+)?)e\+?(\d+)?$/i;

  export function getOrdinalNum(d: number) {
    if (d > 3 && d < 21) { return 'th'; }
    switch (d % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  export function AddZeroUnderTen(value: number) {
    return Number(value) < 10 ? '0' + value : value;
  }

  export function round(value: number, decimals: number) {
    const factor = Math.pow(10, decimals);
    return Math.round(factor * value) / factor;
  }

  export function isNum(num: any, useNegatives: boolean = true) {
    return !(!useNegatives && Number(num) < 0) && num !== null && !isNaN(num);
  }

  export function isOnlyZeros(value: string) {
    if (value.length === 0) { return false; }

    const stringInArray = value.replace('-', '').replace('.', '').replace(',', '').split('');
    for (let i = 0; i < stringInArray.length; i++) {
      if (stringInArray[i] !== '0') {
        return false;
      }
    }
    return true;
  }

  export function isRegexValue(value: any) {
    return Object.prototype.toString.call(value) === '[object RegExp]';
  }

  export function isValidValue(str: string) {
    if (str === '' || str === null) { return true; }
    return isValidValueRe.test(str);
  }

  export function setValueForDate(
    values: { m: moment.Moment, V: number; }[],
    date: moment.Moment,
    periodicity: moment.unitOfTime.StartOf,
    newValue: number
  ) {
    const idx = values.findIndex(value => value.m.isSame(date, periodicity));
    if (idx !== -1) {
      values[idx].V = newValue;
    }
  }

  export function setAggregatedValues(values: PlotValueDTO[]) {
    values.forEach(v => { v.V = v.A; });
    return values;
  }

  export function setOutlierValues(values: PlotValueDTO[], outlier = true) {
    if (!outlier) { return values; }
    values.forEach(v => {
      v.V = v.VO || v.V;
      v.A50 = v.A50O || v.A50;
      v.A75 = v.A75O || v.A75;
      v.A95 = v.A95O || v.A95;
      v.I50 = v.I50O || v.I50;
      v.I75 = v.I75O || v.I75;
      v.I95 = v.I95O || v.I95;
    });
    return values;
  }

  export function setSeasonalValues(values: PlotValueDTO[], seasonal = true) {
    if (!seasonal) { return values; }
    values.forEach(v => {
      v.V = v.VS || v.V;
      v.A50 = v.A50S || v.A50;
      v.A75 = v.A75S || v.A75;
      v.A95 = v.A95S || v.A95;
      v.I50 = v.I50S || v.I50;
      v.I75 = v.I75S || v.I75;
      v.I95 = v.I95S || v.I95;
    });
    return values;
  }

  export function GetMostCommonValueFactor(values: number[]) {
    Object.values(ValueFactors).forEach(v => v.weight = 0);
    let selectedFactor: EValueFactor = { factor: 0, weight: 0, abbr: undefined, description: '' };
    values.forEach(v => {
      const val = Math.abs(v);
      if (val === 0) { return; }
      if (val >= ValueFactors.T.factor) {
        ValueFactors.T.weight++;
      } else if (val >= ValueFactors.B.factor) {
        ValueFactors.B.weight++;
      } else if (val >= ValueFactors.M.factor) {
        ValueFactors.M.weight++;
      } else if (val >= ValueFactors.K.factor) {
        ValueFactors.K.weight++;
      } else if (val >= 0.1) {
        ValueFactors.None.weight++;
      } else if (val >= 0.0001) {
        ValueFactors.m.weight++;
      } else if (val < 0.0001) {
        ValueFactors.mu.weight++;
      }
    });

    Object.values(ValueFactors).forEach(v => {
      if (v.weight > selectedFactor.weight) {
        selectedFactor = <EValueFactor> v;
      }
    });

    return selectedFactor;
  }

  export function FormatNumberBasedOnFactor(num: number | string, factor: number) {
    if (num === undefined) { return Number.NaN; }
    if (typeof num === 'string') {
      num = +num;
    }
    const value = +(factor ? num / factor : num).toFixed(2);
    const hasDecimal = String(value).match(/\.|,/g);
    let valLen = String(value).length;
    let numDecimals = 2;
    if (hasDecimal) {
      valLen = String(value).replace(/\.|,/, '').length;
    }
    if (valLen === 5) {
      numDecimals = 2;
    } else if (valLen === 6) {
      numDecimals = 1;
    } else if (valLen >= 7) {
      numDecimals = 0;
    }
    return +value.toFixed(numDecimals);
  }

  export function getDecimalCount(models: ALGSingleSeriesModel[]) {
    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    let decimals = 1;
    for (const m of models) {
      const values = m.Values.map(v => v.V);
      min = Math.min(min, ...values);
      max = Math.max(max, ...values);
    }
    decimals = 2;
    if (Math.abs(min) < 10 || Math.abs(max) < 10) {
      decimals = 6;
    }
    return decimals;
  }

  /**
   * Get number rounded to a specific significant resolution
   * @param value The number to round
   * @param signif The count of significant figures
   * @returns
   */
  export function getValueWithSignificantDigits(value: number, signif = 3) {
    if (!isFinite(value)) { return value; }
    const isNegative = value < 0;
    const decimal = new Decimal(Math.abs(value));
    const rounded = decimal.toSignificantDigits(signif);
    // Set return value to correct sign again
    const toRet = rounded.toNumber();
    return toRet * (isNegative ? -1 : 1);
  }

  /**
   * Returns a string representation of a number, with a value-factor if needed, 10 000 -> 10 k.
   * @param value The number to abbreviate
   *
   * Note: Will return scientific notation if the value is above 1e15, or below 1e-7.
   * @param isPercent
   */
  export function getValueAsAbbreviatedString(value: number, isPercent = false) {
    if (value === 0 || isNaN(value)) { return '0'; }
    const abs = Math.abs(value);
    /* If number is bigger than 10^15 or smaller than 10^-7, we return the answer in scientific notation (e.g. 1.2e11) */
    if (abs > Math.pow(10, 15) || abs < Math.pow(10, -7)) {
      return `${new Decimal(value).toExponential(2)}${isPercent ? '%' : ''}`;
    }

    const valueFactor = GetMostCommonValueFactor([value]);
    const shortened = value / valueFactor.factor;

    let signif = 3;
    switch (true) {
      case Math.abs(shortened) > 100:
        signif = 5; break;
      case Math.abs(shortened) > 10:
        signif = 4; break;
      default:
        signif = 3; break;
    }
    const valueToUse = getValueWithSignificantDigits(shortened, signif);
    return valueToUse + valueFactor.abbr + (isPercent ? '%' : '');
  }

  /**
   * Retuns a string with suffix (e.g. K, M, m, etc)
   * @param number The number to represent
   * @param decPlaces Number of decimal places to show
   * @param abbrev A list of abbreviations to use, defaults to ['k', 'M', 'B', 'T'] (e3, e6, e9. e12)
   */
  export function abbreviateNum(number, decPlaces, abbrev = null): string {
    const negative = number < 0;
    number = Math.abs(number);
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = decPlaces != null ? decPlaces : 2;
    decPlaces = Math.pow(10, decPlaces);
    // Enumerate number abbreviations
    abbrev = abbrev || ['k', 'M', 'B', 'T'];
    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {
      // Convert array index to '1000', '1000000', etc
      const size = Math.pow(10, (i + 1) * 3);
      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round(number * decPlaces / size) / decPlaces;
        // Handle special case where we round up to the next abbreviation
        if ((number === 1000) && (i < abbrev.length - 1)) {
          number = 1;
          i++;
        }
        // Add the letter for the abbreviation
        number += ' ' + abbrev[i];
        // We are done... stop
        break;
      }
    }
    if (negative) {
      number = '-' + number;
    }

    return number;
  }

  export function copyValue<T extends IHasDate>(x: T) {
    return <T> {
      ...x,
      D: new Date(x.D),
      m: moment(x.m)
    };
  }

}

