import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DataProvider, ProviderDefinitions } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { CompanyModel } from '@core/store/company/company.model';


@Component({
  selector: 'indicio-company-edit-admin-dialog-providers-tab',
  templateUrl: './company-edit-tab.providers.component.html',
  styleUrls: ['./company-edit-tab.providers.component.less'],
})
export class CompanyEditProvidersTabComponent implements OnChanges {

  @Input() company: CompanyModel;

  @Output() providersChangedEvent = new EventEmitter();

  public providers: { Name: DataProvider; }[];

  constructor(
    private cd: ChangeDetectorRef,
    public envService: EnvironmentService,
  ) {
  }

  public ngOnChanges() {
    if (!this.company) { return; }
    this.providers = ProviderDefinitions.getDataProviders();
    this.providers.push({ Name: DataProvider.file });
    this.cd.detectChanges();
  }

  public toggleDisabledProvider(providerId: string) {
    const idx = this.company.DisabledProviders.indexOf(providerId);
    if (idx > -1) {
      this.company.DisabledProviders.splice(idx, 1);
    } else {
      this.company.DisabledProviders.push(providerId);
    }
    this.providersChangedEvent.emit();
  }
}
