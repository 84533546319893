<indicio-dialog>
  <h1 mat-dialog-title>Forecast settings
    <div class="crop-string subtitle"
         [title]="forecast.Name"
         *ngIf="initialized">
      Forecast: {{ forecast.Name }} (Version {{ fVersion.VersionNumber }})
    </div>
  </h1>
  <div mat-dialog-content
       style="margin-bottom: 10px;">
    <ng-container *ngIf="initialized; else loadingTemplate">
      <ng-container *ngTemplateOutlet="dialogTabNavigationTemplate"></ng-container>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Close</button>
    <button mat-flat-button
            *ngIf="canUpdateForecast && showSaveOn.includes(viewIndex)"
            [disabled]="!hasModifiedSettings() || pending"
            color="primary"
            (click)="save()">
      <indicio-spinner *ngIf="pending"></indicio-spinner>&nbsp;
      Save changes
    </button>
  </div>
</indicio-dialog>

<ng-template #dialogTabNavigationTemplate>
  <mat-tab-group animationDuration="0ms"
                 color="primary"
                 [selectedIndex]="viewIndex"
                 (selectedIndexChange)="changeView($event)"
                 [disablePagination]="true">
    <mat-tab label="General">
      <ng-container *ngIf="viewIndex === viewTypes.general"
                    [ngTemplateOutlet]="generalTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Version settings">
      <ng-container *ngIf="viewIndex === viewTypes.version"
                    [ngTemplateOutlet]="versionTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Indicator analysis">
      <ng-container *ngIf="viewIndex === viewTypes['indicator-analysis']"
                    [ngTemplateOutlet]="indicatorTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Models">
      <ng-container *ngIf="viewIndex === viewTypes.models"
                    [ngTemplateOutlet]="modelsTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Date settings">
      <ng-container *ngIf="viewIndex === viewTypes['date-settings']"
                    [ngTemplateOutlet]="datesTemplate"></ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-template>


<ng-template #generalTemplate>
  <indicio-fcast-dialog-general-tab [forecast]="forecast"
                                    [forecastVersion]="fVersion"
                                    [project]="project"
                                    [canUpdateForecast]="canUpdateForecast"
                                    (closeDialogEvent)="close()">
  </indicio-fcast-dialog-general-tab>
  <br>
</ng-template>

<ng-template #versionTemplate>
  <indicio-fcast-dialog-version-tab [forecast]="forecast"
                                    [forecastVersion]="fVersion"
                                    [project]="project"
                                    [trigger]="trigger"
                                    [canUpdateForecast]="canUpdateForecast"
                                    (closeDialogEvent)="close()">
  </indicio-fcast-dialog-version-tab>
</ng-template>

<ng-template #indicatorTemplate>
  <indicio-fcast-dialog-indicator-analysis-tab [forecast]="forecast"
                                               [forecastVersion]="fVersion"
                                               [project]="project"
                                               [canUpdateForecast]="canUpdateForecast"
                                               (closeDialogEvent)="close()">
  </indicio-fcast-dialog-indicator-analysis-tab>
</ng-template>

<ng-template #modelsTemplate>
  <indicio-fcast-dialog-models-tab #modelsTab
                                   [forecast]="forecast"
                                   [forecastVersion]="fVersion"
                                   [project]="project"
                                   [canUpdateForecast]="canUpdateForecast"
                                   [trigger]="trigger"
                                   (modifiedEvent)="modified($event, viewTypes.models)"
                                   (closeDialogEvent)="close()">
  </indicio-fcast-dialog-models-tab>
</ng-template>

<ng-template #datesTemplate>
  <indicio-fcast-dialog-date-settings-tab #dateTab
                                          [forecast]="forecast"
                                          [forecastVersion]="fVersion"
                                          [project]="project"
                                          [trigger]="trigger"
                                          [canUpdateForecast]="canUpdateForecast"
                                          (modifiedEvent)="modified($event, viewTypes['date-settings'])"
                                          (closeDialogEvent)="close()">
  </indicio-fcast-dialog-date-settings-tab>
</ng-template>

<ng-template #loadingTemplate>
  <div>
    <indicio-spinner></indicio-spinner> Loading...
  </div>
</ng-template>
