import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropdownInputDialogComponent } from '@shared/modules/dialogs/input/dropdown/dropdown-input.dialog';
import { ColorPickerModule } from 'ngx-color-picker';
import { MarkdownModule } from 'ngx-markdown';
import { IndicioSharedComponents } from './components';
import { IndicioSharedDirectives } from './directives';
import { IndicioModals } from './modals';
import { ModalBaseComponent } from './modals/modal-base/modal-base.component';
import { ModalComponent } from './modals/modal.component';
import { IndicioDialogs } from './modules/dialogs';
import { MaterialModule } from './modules/material.module';
import { PipesModule } from './modules/pipes/pipes.module';


@NgModule({
  imports: [
    CommonModule,
    ScrollingModule,
    ColorPickerModule,
    MaterialModule,
    FontAwesomeModule,
    DragDropModule,
    // DialogModule,
    PipesModule,
    MarkdownModule.forRoot(),
    FormsModule,
    ObserversModule,
  ],
  declarations: [
    ...IndicioSharedComponents,
    ...IndicioSharedDirectives,
    ...IndicioModals,
    ...IndicioDialogs,
    ModalComponent,
    ModalBaseComponent
  ],
  exports: [
    CommonModule,
    ScrollingModule,
    DragDropModule,
    FontAwesomeModule,
    ...IndicioSharedComponents,
    ...IndicioSharedDirectives,
    PipesModule,
    ModalComponent,
    ModalBaseComponent,
    MaterialModule,
    DropdownInputDialogComponent,
    // DialogModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule { }
