<indicio-modal-base [child]="this"
                    modalTitle="Edit similarity schema"
                    [loading]="isLoading"
                    bodyExtraClass="overflow-auto">
  <div class="correlation-container">
    <ng-container *ngIf="!isLoading">
      <ng-container *ngTemplateOutlet="createTemplate">
      </ng-container>

      <ng-container *ngTemplateOutlet="buttonRow">
      </ng-container>
    </ng-container>
  </div>
</indicio-modal-base>

<ng-template #createTemplate>
  <div class="correlation-box margin-top">
    <p>Event name: {{ futureEvent.Name }}</p>
    <div class="row">
      <div class="col-xs-12">
        <p *ngIf="getPastEvents().length">
          This
          <span *ngIf="!isLagged">event</span>
          <span *ngIf="isLagged">lagged event partially</span>
          appears in the future and its effect can therefore not be estimated.
          Is this event similar to any of the historical ones?
        </p>
        <p *ngIf="!getPastEvents().length">
          There are no past events available to describe this event and it will therefore not be used.
        </p>
      </div>
    </div>

    <div class="row flex-row titles"
         *ngIf="availableCorrelations.length">
      <div class="col-xs-7">
        Event name
      </div>
      <div class="col-xs-3">
        Active
      </div>
      <div class="col-xs-2">
        Weight
      </div>
    </div>
  </div>

  <ng-container *ngIf="availableCorrelations.length">
    <div class="events-container">
      <ng-container *ngFor="let correlation of availableCorrelations">
        <ng-container *ngTemplateOutlet="correlationRow; context { correlation: correlation, event: futureEvent }">
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <div class="row"
       *ngIf="getPastEvents().length">
    <div class="col-xs-11 margin-top">
      <div class="pull-right"
           *ngIf="action !== 'set'">
        Total weight: <br> {{ getTotalWeight }}%
        <i class="ion-alert-circled orange weight-warning"
           title="You have entered a total weight over 100%, is this correct?"
           *ngIf="getTotalWeight > 100"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #correlationRow
             let-correlation="correlation"
             let-event="event">
  <div class="row flex-row event-header event-header--correlation"
       [class]="{'not-active' : !event.getCorrelationForId(correlation.PastHistoricEventId) }">
    <div class="crop-string col-xs-7"
         *ngIf="getEventFromId(correlation.PastHistoricEventId) as eventInfo">
      <span class="name">{{ eventInfo.Name }}</span>
      <span class="date">{{ eventInfo.Date | dateFormat }}</span>
    </div>
    <div class="col-xs-3">
      <mat-slide-toggle [checked]="event.getCorrelationForId(correlation.PastHistoricEventId) || null"
                        (change)="addCorrelation(correlation)">
      </mat-slide-toggle>
    </div>
    <div class="col-xs-2">
      <ng-container>
        <ng-container *ngTemplateOutlet="strengthField; context { correlation: correlation }">
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #strengthField
             let-correlation="correlation">
  <mat-form-field *ngIf="futureEvent.getCorrelationForId(correlation.PastHistoricEventId)">
    <input matInput
           data-test-id='edit-correlation-bridge-strength-field'
           class="material"
           type="number"
           name="strength"
           [(ngModel)]="futureEvent.getCorrelationForId(correlation.PastHistoricEventId).Strength"
           min="0"
           placeholder="">
    <span matSuffix>%</span>
  </mat-form-field>

  <mat-form-field *ngIf="!futureEvent.getCorrelationForId(correlation.PastHistoricEventId)">
    <input matInput
           data-test-id='edit-correlation-bridge-strength-field-disabled'
           class="material"
           type="number"
           name="strength2"
           value="0"
           min="0"
           disabled="disabled"
           placeholder="">
    <span matSuffix>%</span>
  </mat-form-field>

</ng-template>

<ng-template #buttonRow>
  <div class="row modal-space"
       *ngIf="getPastEvents().length">
    <div class="col-xs-6">
      <button class="btn outline blue left cancel"
              type="button"
              (click)="closeCorrelations()">
        {{ isChanged ? 'Cancel' : 'Back' }}
      </button>
    </div>
    <div class="col-xs-6">
      <button class="btn right save"
              [disabled]="!isChanged"
              (click)="save()">
        Save
      </button>
    </div>
  </div>

  <div class="row modal-space"
       *ngIf="!getPastEvents().length">
    <div class="col-xs-6">

    </div>
    <div class="col-xs-6">
      <button class="btn right"
              (click)="closeCorrelations()">
        Ok
      </button>
    </div>
  </div>
</ng-template>
