
export class CreateRequestDTO {
  Identifier: string;
  Title: string;
  Tickers: string[];
  Fields: string[];

  // The time frame of the data the request returns
  StartDate: Date;
  EndDate: Date;
  Days: number;

  // The triggering of the request itself
  Trigger: string;
  RequestStartDate: Date;
  Time: string;
}

export class BloombergCreateRequestResponseDTO {
  Title: string;
  Message: string;
  StatusCode: number;
}

export class BloombergRequestListDTO {
  ParentId: string;
  Page: number;
  TotalItems: number;
  PageCount: number;
  Title: string;
  Description: string;
  Content: BloombergRequestsContentDTO[];
}

export class BloombergRequestsContentDTO {
  Id: string;
  Type: string;
  Name: string;
  Enabled: boolean;
  Modified: Date;
  LastRun: Date;
  NextRun: Date;
  Fields: BloombergFieldDTO[];
  Identifiers: BloombergIdentifierDTO[];
  Frequency: string;
}

export class BloombergRequestDTO {
  Id: string;
  Type: string;
  Name: string;
  Modified: Date;
  LastRun: Date;
  Fields: BloombergFieldDTO[];
  Identifiers: BloombergIdentifierDTO[];
}

export class BloombergFieldDTO {
  Id: string;
  CleanName: string;
  Type: string;
  Identifier: string;
  Mnemonic: string;
}

export class BloombergIdentifierDTO {
  Type: string;
  Value: string;
}

export class OpenFigiSearchDTO {
  query: string;
  exchCode: string;
  marketSecDes: string = '* All markets *';
}

export class FigiResultDTO {
  Figi: string;
  ExchangeCode: string;
  MarketSector: string;
  Name: string;
  Description: string;
  Ticker: string;
  Type: string;
}

export class FigiExchangeDTO {
  public Code: string;
  public Country: string;
  public FullName: string;
}
