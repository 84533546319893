import { Def } from '@core/decorators/def';

export class ScriptInfoModel {
  @Def() Name: string;
  @Def() ExitError: boolean;
}

export class ScriptCalculateInfoModel {
  @Def() CanTriggerVariableSelect: boolean = false;
  @Def() NowcastNeeded: boolean = false;
  @Def() Multivariate: ScriptInfoModel[] = [];
  @Def() Univariate: ScriptInfoModel[] = [];

  // Frontend
  public triggerCalculationTitle = 'You need to analyze the indicators before running a multivariate analysis';
  public triggerVarSelectTitle = 'You need to add at least one indicator before running an indicator analysis';
}
