import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MemberMapper } from '@core/mappers/member.mapper';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ProfileModel } from '@core/store/client/profile.model';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { MembersListComponent } from '@shared/components';
import { MemberListOptions } from '@shared/components/member-list/member-list.options';


@Component({
  selector: 'indicio-company-edit-admin-dialog-users-tab',
  templateUrl: './company-edit-tab.users.component.html',
  styleUrls: ['./company-edit-tab.users.component.less'],
})
export class CompanyEditUsersTabComponent implements OnChanges {

  @ViewChild(MembersListComponent)
  public employeesList: MembersListComponent;
  public memberListOptions: MemberListOptions = null;

  @Input() company: CompanyModel;
  @Output() roleChangedEvent = new EventEmitter<boolean>();

  // Models
  private currentProfile: ProfileModel;

  // Flags
  public shouldInviteNewMember: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private memberMapper: MemberMapper,
    private companySerive: CompanyFrontendService,
    private profileService: ProfileFrontendService,
    public envService: EnvironmentService,
  ) {
  }

  public ngOnChanges() {
    if (!this.company) { return; }
    this.currentProfile = this.profileService.profile;
    this.setupMemberListOptions();
    this.cd.detectChanges();
  }

  public saveInvitation() {
    if (this.employeesList && this.employeesList.invitesToSendOnSave.length) {
      this.employeesList.sendQueuedInvites();
    }
  }

  public toggleSaveMemberButton(shouldToggle) {
    this.shouldInviteNewMember = shouldToggle;
  }

  public updateMemberRole(event: { email: string, newRole: string; }) {
    this.company.getEmployeeByEmail(event.email).CompanyRole = event.newRole;
    this.setupMemberListOptions();
    this.roleChangedEvent.emit(true);
  }

  private setupMemberListOptions() {
    const options: MemberListOptions = {
      CurrentMembers: this.company.Employees.map(e => this.memberMapper.mapCompanyEmployee(e, this.company)),
      NewMemberSuggestions: [],
      MemberService: this.companySerive,
      CurrentUserCanInvite: true,
      CurrentUserEmail: this.currentProfile.Email,
      CompanyId: this.company.CompanyId,
      ProjectId: null,
      NewMemberRole: this.envService.getCompanyRole('companyuser'),
      NewMemberRoleOptions: this.envService.CompanyRoles,
      Type: 'company'
    };
    this.memberListOptions = options;
  }
}
