import { d3Types } from '@shared/utils/d3/d3-svg.types';

export namespace ShapGraphTypes {

  export interface ShapValue {
    Name: string;
    Value: number;
    Index?: number;
  }

  export interface Input {
    data: ShapValue[];
    isPercent: boolean;
  }

  export class Objects {
    MainGroup: d3Types.Group;
    SVG: d3Types.SVG;

    /* Scales */
    yScale: d3.ScaleBand<string>;
    xScale: d3.ScaleLinear<number, number>;

    /* Axes */
    xAxisGroup: d3Types.Group;
    yAxisGroup: d3Types.Group;
  }

}
