import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import tippy, { Instance, Props, hideAll } from 'tippy.js';

@Directive({
  selector: '[indicioTippy]'
})
export class TippyDirective implements AfterViewInit, OnChanges {

  @Input() indicioTippy: string | number | Partial<Props>;
  @Input() scrollContainer: HTMLDivElement = null;

  _options: Partial<Props> = { allowHTML: true };
  _tippy: Instance<Props>[];

  private dom: any = null;

  constructor(ui: ElementRef) {
    this.dom = ui.nativeElement;
  }

  public ngAfterViewInit() {
    this.initTippy();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.indicioTippy.firstChange === true) { return; }
    if (this._tippy) {
      (this._tippy as any).destroy();
    }
    this.initTippy();
  }

  private initTippy() {
    if (this.indicioTippy === null) { return; }
    if (typeof this.indicioTippy !== 'object') {
      this._options.content = String(this.indicioTippy);
    } else {
      this._options = Object.assign(this._options, this.indicioTippy);
      if (!this._options?.content && !this.dom.querySelector('[tippy-content]')) { return; }
    }

    const content = this.dom.querySelector('[tippy-content]');
    if (content !== null) {
      this._options.content = content.innerHTML;
    }

    this._tippy = tippy(this.dom);

    (this._tippy as any).setProps(this._options);

    if (this.scrollContainer) {
      const exists = this.scrollContainer.eventListeners('scroll');
      if (exists.length === 0) {
        this.scrollContainer.addEventListener('scroll', () => {
          hideAll({ duration: 0 });
        });
      }
    }
  }
}
