import { AccuracyMeasureType, EAccuracyChartView } from '@core/constants/accuracy.constants';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { TinyColor } from '@ctrl/tinycolor';
import { DataTable } from '@shared/components/data-table/data-table.model';
import { ExcelBaseGenerator } from './excel-base-generator';
import { ExcelFile, ExcelRow, ExcelSheet } from './excel.entities';

export class SourceVariableGeneratorData {
  Tables: DataTable[];
  Type: 'univariate' | 'multivariate' | 'summary';
  Forecast: ForecastModel;
  InPercantage: boolean;
  ChartView: EAccuracyChartView;
  Measurement: AccuracyMeasureType;
  Colors: string[];
}

export class ModalAccuracyExcelGenerator extends ExcelBaseGenerator {
  private data: SourceVariableGeneratorData;

  constructor(data: SourceVariableGeneratorData) {
    super();
    this.data = data;
  }

  public generate(): ExcelFile {
    const file = new ExcelFile;
    file.FileName = `${this.data.Forecast.Name}_model-accuracy_${this.data.Type}`;
    file.FileType = 'xlsx';

    const sheet = new ExcelSheet;
    sheet.Name = 'Accuracy';
    sheet.Title = `Accuracy values for ${this.data.Type} in ${this.data.Forecast.Name}`;

    sheet.addRow(new ExcelRow());

    const tableHeader = new ExcelRow();
    sheet.addRow(tableHeader);
    sheet.addRow(new ExcelRow());

    this.data.Tables.forEach((table, index) => {
      const headerExists = !!sheet.columnHeaderRow;
      const headerRow = this.getOrCreateHeaderRow(sheet);
      // Add headers
      if (headerExists) { headerRow.Cells.push(this.createCell('')); }

      let valueStartPos = 0; // Position of the first value in the row. Not 0 based

      table.Columns.forEach((col, colIndex) => {
        const celNum = headerRow.Cells.push(this.createCell(col, null, { bold: true }));
        if (colIndex === 0) {
          valueStartPos = tableHeader.Cells.push(
            ...Array.from({ length: celNum - (1 + index) }, () => { return this.createCell(''); }),
            this.createCell(table.Title, null)
          );
        }
      });

      //Add values
      table.Rows.forEach((row, rowIndex) => {
        const valueRow = this.getOrCreateTableRow(sheet, rowIndex);
        while (valueRow.Cells.length < valueStartPos - 1) {
          valueRow.Cells.push(this.createCell(''));
        }
        row.forEach((cell, cellIndex) => {
          const isPercentage = this.data.InPercantage || table.PercentageColumns.includes(cellIndex);
          const value = cellIndex === 0 ? cell : cell ? this.getValue(cell, isPercentage) : 'N/A';
          if (this.data.Colors.length > 0 && valueStartPos === 1 && cellIndex === 0) {
            const c = new TinyColor(this.data.Colors[rowIndex]).lighten(20);
            valueRow.Cells.push(this.createCell(value, c.toHex8String(), { color: { argb: c.isLight() ? '00000000' : 'FFFFFFFF' } }));
          } else {
            valueRow.Cells.push(this.createCell(value, table.HighlightedColumn === cellIndex ? '#eee' : null));
          }
        });
      });
    });

    if (this.data.Tables.length === 1 && this.data.ChartView === EAccuracyChartView.OUTOFSAMPLE_ALLSTEPS) {
      sheet.Rows.push(new ExcelRow());
      sheet.Rows.push(new ExcelRow());
      const modelRow = new ExcelRow();
      modelRow.Cells.push(this.createCell(`Measurement: ${this.data.Measurement}`));
      sheet.Rows.push(modelRow);
    }


    file.Sheets.push(sheet);

    return file;
  }

  private getOrCreateHeaderRow(sheet: ExcelSheet) {
    let headerRow = sheet.columnHeaderRow;
    if (!headerRow) {
      headerRow = new ExcelRow();
      headerRow.id = 'header';
      sheet.addRow(headerRow);
    }
    return headerRow;
  }

  private getOrCreateTableRow(sheet: ExcelSheet, rowIndex: number) {
    let row = sheet.Rows.find(x => x.id === rowIndex.toString());
    if (!row) {
      row = new ExcelRow();
      row.id = rowIndex.toString();
      sheet.addRow(row);
    }
    return row;
  }
}
