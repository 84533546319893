import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlphaVantageCryptoPeriodicities, AlphaVantageForexPeriodicities, AlphaVantageStockPeriodicities } from '@core/constants/alphavantage.constants';
import { AlphaVantageMapper } from '@core/store/providers/alphavantage/alphavantage.mapper';
import { AlphaVantageCryptoDTO } from '@core/store/providers/alphavantage/dtos/alphavantage-crypto.dto';
import { AlphaVantageForexDTO } from '@core/store/providers/alphavantage/dtos/alphavantage-forex.dto';
import { AlphaVantageStockDTO } from '@core/store/providers/alphavantage/dtos/alphavantage-stock.dto';
import { EnvironmentService } from '@core/services/environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class AlphaVantageService {

  private baseUrl = 'https://www.alphavantage.co/query?&function=';
  public APIKey: string;
  public currentUri: string;

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private mapper: AlphaVantageMapper
  ) { }

  private getUrl(path: string, withProxy = false) {
    const url = `${this.baseUrl}${path}&apikey=${this.APIKey}`;
    this.currentUri = url;

    if (withProxy) {
      return `${this.envService.env.CorsProxy.replace(/\/$/, '')}/${url}`;
    }

    return url;
  }

  public setAPIKey(key: string) {
    this.APIKey = key;
  }

  public getCurrencyData(periodicity: AlphaVantageForexPeriodicities, from: string, to: string) {
    return this.http.get<AlphaVantageForexDTO>(this.getUrl(`${periodicity}&from_symbol=${from}&to_symbol=${to}&outputsize=full`)).toPromise()
      .then(resp => this.mapper.mapForex(resp));
  }

  public getCryptoCurrencyData(periodicity: AlphaVantageCryptoPeriodicities, from: string, to: string) {
    return this.http.get<AlphaVantageCryptoDTO>(this.getUrl(`${periodicity}&symbol=${from}&market=${to}&outputsize=full`)).toPromise()
      .then(resp => this.mapper.mapCrypto(resp));
  }

  public searchStock(query: string) {
    return this.http.get<any>(this.getUrl(`SYMBOL_SEARCH&keywords=${query}`)).toPromise();
  }

  public getStockData(periodicity: AlphaVantageStockPeriodicities, stockName: string) {
    return this.http.get<AlphaVantageStockDTO>(this.getUrl(`${periodicity}&symbol=${stockName}&outputsize=full`)).toPromise();
  }
}
