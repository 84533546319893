<indicio-dialog-base>
  <h1 mat-dialog-title>Reconciliation result overview</h1>
  <div mat-dialog-content>
    <div *ngIf="!isLoading; else showLoader"
         class="hierarchical-dialog-container">
      <div class="ctc-options">
        <ng-container *ngTemplateOutlet="reconciliateTemplate"></ng-container>
      </div>
    </div>
    <br>
  </div>

  <div mat-dialog-actions>
    <button mat-button
            (click)="close()">
      Close
    </button>
  </div>
</indicio-dialog-base>

<ng-template #reconciliateTemplate>

  <div class="row"
       style="margin-bottom: 10px; border-bottom: 1px solid black">
    <div class="col-xs-6">Variable</div>
    <div class="col-xs-6">{{ dto.ReconciliationResultId }}</div>
  </div>
  <div class="row">
    <div class="col-xs-3">Date</div>
    <div class="col-xs-4">Old value</div>
    <div class="col-xs-4">New value</div>
    <div class="col-xs-1"></div>
  </div>
  <ng-container *ngFor="let newRes of dto.ToResult.Values; let index = index">
    <ng-container *ngIf="dto.FromResult.Values[index] as oldRes">
      <div class="row"
           indicioTippy="Value changed by: {{ newRes.V - oldRes.V }}">
        <div class="col-xs-3">{{ newRes.D | dateFormat }}</div>
        <div class="col-xs-4">{{ oldRes.V | propertyValue: 3 }}</div>
        <div class="col-xs-4">{{ newRes.V | propertyValue: 3 }}</div>
        <div class="col-xs-1"><i [class]="oldRes.V > newRes.V ? 'ion-arrow-down-c red' : 'ion-arrow-up-c green'"></i>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #showLoader>
  <i class="ion-load-c spinner info-loader "></i>
</ng-template>
