
import * as d3 from 'd3';
import { BarChartEntry } from './bar-chart.options';

export class BarChartSVGElements {

  /* Main SVG obj */
  public svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;

  /* Groups */
  public graphGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  public bars: d3.Selection<SVGGElement, BarChartEntry, SVGGElement, any>;

  /* Axes */
  public xAxis: d3.Selection<SVGTextElement, unknown, HTMLElement, any>;
  public yAxis: d3.Selection<SVGTextElement, unknown, HTMLElement, any>;

  /* Scales */
  public xScale: d3.ScaleLinear<number, number> = null;
}
