<indicio-modal-base [child]="this"
                    modalTitle="Download client Activity"
                    [loading]=isLoading
                    bodyExtraClass="overflow-auto">

  <ng-container *ngIf="!isLoading">

    <!-- Forecast periodicity -->
    <div class="row"
         *ngIf="isAdmin();">
      <div class="col-xs-6 input-label">Filter by Company</div>
      <div class="col-xs-6">
        <indicio-searchable-dropdown [values]="companies | orderBy: 'Name'"
                                     [selectedValue]="selectedCompany"
                                     (valueChangeEvent)="setCompany($event)"
                                     [initializeWithFirstValue]="false"
                                     optionDisplay="Name"></indicio-searchable-dropdown>
      </div>
    </div>

    <!-- Periodicity -->
    <div class="row">
      <div class="col-xs-6 input-label">
        Periodicity
      </div>
      <div class="col-xs-6 relative">
        <mat-form-field class="margin-top-5">
          <mat-select [value]="searchDto.Periodicity"
                      (selectionChange)="onPeriodicityChanged($event.value)"
                      [required]="true">
            <mat-option [value]="periodicity.Value"
                        *ngFor="let periodicity of env.Periodicities">{{ periodicity.Display }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- From date -->
    <div class="row margin-top">
      <div class="col-xs-6 input-label">Choose from date</div>
      <div class="col-xs-6">
        <indicio-date-picker periodicity="day"
                             [value]="fromDateModel"
                             (selectDateEvent)="onDateChanged($event)"
                             placeholder="Pick a start date">
        </indicio-date-picker>

      </div>
    </div>


    <indicio-modal-option-row optionTitle="Use last full period"
                              optionSubtitle="If false, get statistics from today and one period back. If true, get statiscs from the last full period"
                              textWidth="11"
                              optionsWidth="1">
      <div class="toggle-icon"
           style="margin-top: 25px;">
        <mat-slide-toggle [checked]="searchDto.FullPeriod"
                          (change)="searchDto.FullPeriod = !searchDto.FullPeriod">
        </mat-slide-toggle>
      </div>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Download as CSV"
                              optionSubtitle="Generate a CSV for the statistics"
                              [disabled]="searchDto.CompanyId === ''"
                              textWidth="11"
                              optionsWidth="1">
      <div class="toggle-icon"
           style="margin-top: 25px;">
        <mat-slide-toggle [checked]="asCSV"
                          [disabled]="searchDto.CompanyId === ''"
                          (change)="asCSV = !asCSV">
        </mat-slide-toggle>
      </div>
    </indicio-modal-option-row>

    <div class="row">
      <div class="col-xs-6">
        <button class="btn outline blue left"
                type="button"
                (click)="close()">Close
        </button>
      </div>
      <div class="col-xs-6">
        <button class="btn right"
                [class]=" { disabled: !!!searchDto.CompanyId }"
                [indicioTippy]="!!!searchDto.CompanyId ? 'Pick a company' : null"
                (click)="getStats()">
          <span>Download</span>
        </button>
      </div>
    </div>

  </ng-container>
</indicio-modal-base>
