
export namespace ArrayUtils {

  export function sortArrayAlphabetically(arr: any[], key: string = null) {
    if (key == null) {
      return arr.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      });
    } else {
      return arr.sort((a, b) => {
        if (a[key] < b[key]) { return -1; }
        if (a[key] > b[key]) { return 1; }
        return 0;
      });
    }
  }

  export function sortNumber(a: number, b: number) {
    return a - b;
  }

  // https://stackoverflow.com/a/5306832
  export function arrayMove(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  }

  export function findDeep(array: any[], childArrayProp: string, targetProp: string, value: string) {
    if (!array) {
      return null;
    }
    let result;
    array.some(o => result = o[targetProp] === value ? o : findDeep(o[childArrayProp] || [], childArrayProp, targetProp, value));
    return result;
  }

  export function copyArrayObjects(arr) {
    const res = [];
    for (const obj of arr) {
      const cpy = Object.assign({}, obj);
      res.push(cpy);
    }
    return res;
  }

  export async function awaitResponse(array: any[], callback: Function) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  export function getChunks(array: any[], chunkCount: number) {
    return array.map((_v, i) => {
      return i % chunkCount === 0 ? array.slice(i, i + chunkCount) : null;
    }).filter(c => !!c);
  }
}

export class Normalizer {
  /**
   * @param values  Array to be normalized
   * @param neg     if true, range is set to [-1, 1] instead of [0, 1]
   * @returns       A new array with normalized values
   */
  public static normalize(values: number[], neg: boolean = false) {
    const nonNullValues = values.map(x => x === undefined || x === null ? 1 : x);
    const cMax = Math.max(...nonNullValues);
    const cMin = Math.min(...nonNullValues);
    let range = cMax - cMin;
    if (neg === false) {
      return nonNullValues.map(v => (v - cMin) / (Number.EPSILON + range));
    } else {
      if (Math.abs(cMin) > cMax) {
        range = Math.abs(cMin) - cMin;
      } else {
        range = cMax + cMax;
      }
      return nonNullValues.map(v => 2 * v / (Number.EPSILON + range));
    }
  }
}
