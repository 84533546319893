import { ForecastWarningsModalOpts } from './forecast-warnings-modal.options';

export class OpenForecastWarningsModalAction {
  public static readonly type = '[MODAL] Open forecast warnings modal';
  data: ForecastWarningsModalOpts;
  constructor(
    projectId: string,
    forecastVersionId: string,
  ) {
    this.data = { forecastVersionId, projectId };
  }
}
