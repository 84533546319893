import { IFile } from '@core/interfaces/if-file';
import { FileInfoModel } from '@core/store/file/models/file-info.model';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { DeleteMultiFilesModalOptions } from './delete-multiple-files/delete-multiple-files-modal.options';
import { FileErrorModalOpts } from './file-error/file-error-information-modal.options';
import { UploadFileModalOpts } from './upload-file/upload-file-modal.options';

export class OpenDeleteUploadedFilesModal {
  public static readonly type = '[MODAL] Open delete files modal';
  data: DeleteMultiFilesModalOptions;
  constructor(
    files: IFile[],
    forecastId: string,
    forecastVersionId: string,
    callback?: any
  ) {
    this.data = { files, forecastId, forecastVersionId, callback };
  }
}

export class OpenFileErrorInfoModal {
  public static readonly type = '[MODAL] Open file error modal';
  data: FileErrorModalOpts;
  constructor(
    onBack: Function = null,
    error: any
  ) { this.data = { onBack, error }; }
}

export class OpenUploadFileModal {
  public static readonly type = '[MODAL] Open upload file modal';
  data: UploadFileModalOpts;
  constructor(
    forecastId: string,
    forecastVersionId: string,
    file: UploadedFileModel = null,
    files?: FileInfoModel[]
  ) { this.data = { file, forecastId, forecastVersionId, fileInfos: files }; }
}
