import { Def } from '@core/decorators/def';
import { VisibilityLevelType } from '@modules/lang/language-files/visibility';

export class CreateProjectDTO {
  // Max length 128
  @Def() Name: string;
  @Def() VisibilityLevelId: VisibilityLevelType = 'private';

  // Frontend only
  public unsaved: boolean = false;
}
