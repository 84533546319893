import { Injectable } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';
import { ModelUtils } from '@shared/utils/forecast/model.utils';
import { ForecastVariableModel } from '../forecast-variable/models/forecast-variable-model';
import { ScenarioVariableDTO } from './dtos/scenario-variable.dto';
import {
  CreateScenarioDTO, ScenarioDTO,
  UpdateScenarioDTO
} from './dtos/scenario.dtos';

@Injectable({
  providedIn: 'root'
})
export class ScenarioMapper {

  constructor() { }

  public map(dto: ScenarioDTO) {
    dto.fetched = true;
    dto.color = ModelUtils.getScenarioColorForIndex(dto.ColorIndex);
    dto.ScenarioVariables.forEach(x => {
      x.HistoricData = x.HistoricData.map(value => DateUtils.MapIHasDate(value));
    });
    dto.FinalResults.forEach(x => {
      x.FinalModels.forEach(m => {
        m.PlotData = m.PlotData.map(value => DateUtils.MapIHasDate(value));
        m.OriginalResult = m.OriginalResult.map(value => DateUtils.MapIHasDate(value));
      });
    });
    return dto;
  }

  public toCreate(scenario: ScenarioDTO): CreateScenarioDTO {
    const dto = Object.faMapTo(new CreateScenarioDTO, scenario);
    return dto;
  }

  public toUpdate(scenario: ScenarioDTO): UpdateScenarioDTO {
    const dto = Object.faMapTo(new UpdateScenarioDTO, scenario);
    dto.ScenarioVariables = scenario.ScenarioVariables.map(x => ({ ...x, HistoricData: [] }));
    return dto;
  }

  public mapFCVarToScenarioVar(variable: ForecastVariableModel) {
    const scenarioVar = new ScenarioVariableDTO();
    scenarioVar.Name = variable.Name;
    scenarioVar.ForecastVariableId = variable.ForecastVariableId;
    scenarioVar.Values = variable.FutureValues.map(x => x.V);
    return scenarioVar;
  }
}
