import { HistoricValueDTO } from '@core/entities/dtos/plot-value-dto';
import { IHasModelId } from '@core/interfaces/if-has-model-id';

export class ForecastVersionHistoricDataModel implements IHasModelId {
  public ForecastVersionId: string;
  public Data: StatModelHistoricDataDTO[];
  public getModelId(): string { return this.ForecastVersionId; }
}

export class StatModelHistoricDataDTO {
  ForecastVariableId: string;
  Values: HistoricValueDTO[];
}
