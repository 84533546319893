import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { IndicioEventDTO } from './systems-info.dto';

export class SystemsInfoModel {
  @Def() PackageVersion: string;
  @Def() RequestsInDatabase: number;
  @Def() RequestsInProgress: number;
  @Def() RequestsPending: number;
  @Def() ActiveRClients: number;
  @Def() QCount: number;
  @Def() Requests: RRequestInfoModel[];
  @Def() RClients: RClientInfoModel[];
  @Def() RecenentEvents: IndicioEventDTO[];
  @Def() RecenentWarnings: IndicioEventDTO[];
  @Def() RecenentErrors: IndicioEventDTO[];
}

export class RRequestInfoModel {
  @Def() RRequestId: string;
  @Def() ScriptName: string;
  @Def() ModelName: string;
  @Def() IsPending: boolean;
  @Def() IsProcessing: boolean;
  @Def() ExitSuccess: boolean;
  @Def() ExitError: boolean;
  @Def() Closed: boolean;
  @Def() Messages: boolean;
  @Def() Attempts: number;
  @Def() ErrorMessages: string[];
  @Def() RErrorMessages: string[];
  @Def() ClaimedAt: Date;
  @Def() CreatedDate: Date;
  @Def() ClientId: string;
  @Def() RClientId: string;
  @Def() Maintenance: boolean;
}

export class RComputerModel implements IHasModelId {
  name: string;
  children: RClientInfoModel[] = [];

  getModelId() { return this.name; }

  public get allActive(): boolean { return this.children.every(rc => !rc.Disabled); }
  public get anyActive(): boolean { return this.children.some(rc => !rc.Disabled); }
  public get totalClients(): number { return this.children.length; }
  public get activeClients(): number { return this.children.filter(rc => !rc.Disabled).length; }
}

export class RClientInfoModel {
  @Def() Name: string;
  @Def() IndicioVersion: string;
  @Def() LastActive: Date;
  @Def() LatestRequest: Date;
  @Def() RClientId: string;
  @Def() Disabled: boolean;
  @Def() InstanceId: string;
  @Def() ComputerName: string;
  @Def() Environment: string;

  logInfo: RClientLogsInfo;
}

export class RClientLogsInfo {
  @Def() Logs: RClientLogDTO[];
  @Def() ComputerName: string;
  @Def() RClientId: string;
}

export class RClientLogDTO {
  @Def() Name: string;
  @Def() Content: string;
}

