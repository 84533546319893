<indicio-dialog>
  <h2 mat-dialog-title
      style="margin-bottom: 10px;">Forecast variable information
    <div class="crop-string subtitle"
         [title]="variable.Name"
         *ngIf="initialized">
      Variable: {{ variable.Name }}
    </div>
  </h2>
  <mat-dialog-content>
    <ng-container *ngIf="initialized; else loadingTemplate">
      <ng-container *ngTemplateOutlet="dialogTabNavigationTemplate"></ng-container>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button
            type="button"
            (click)="close()">
      Close
    </button>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #loadingTemplate>
  <div><i class="ion-load-c spinner"></i></div>
</ng-template>

<ng-template #dialogTabNavigationTemplate>
  <mat-tab-group animationDuration="0ms"
                 color="primary"
                 [selectedIndex]="viewIndex"
                 (selectedIndexChange)="changeView($event)"
                 [disablePagination]="true">
    <mat-tab label="General">
      <ng-container *ngIf="viewIndex === viewTypes.general"
                    [ngTemplateOutlet]="generalTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Disaggregation">
      <ng-container *ngIf="viewIndex === viewTypes.disaggregation"
                    [ngTemplateOutlet]="disaggregationTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Missing values">
      <ng-container *ngIf="viewIndex === viewTypes.missing"
                    [ngTemplateOutlet]="missingTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Outliers">
      <ng-container *ngIf="viewIndex === viewTypes.outliers"
                    [ngTemplateOutlet]="outliersTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Seasonal">
      <ng-container *ngIf="viewIndex === viewTypes.seasonal"
                    [ngTemplateOutlet]="seasonalTemplate"></ng-container>
    </mat-tab>
    <mat-tab label="Advanced">
      <ng-container *ngIf="viewIndex === viewTypes.advanced"
                    [ngTemplateOutlet]="advancedTemplate"></ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<ng-template #generalTemplate>
  <indicio-fvar-info-dialog-general-tab [variable]="variable"
                                        [sourceVariable]="sourceVariable"
                                        [forecast]="forecast"
                                        [forecastVersion]="forecastVersion"
                                        [options]="opts"
                                        (changedSvarMetaEvent)="setupVariable($event)"
                                        (closeDialogEvent)="close()">
  </indicio-fvar-info-dialog-general-tab>
  <br>
</ng-template>

<ng-template #missingTemplate>
  <indicio-fvar-info-dialog-missing-values-tab [variable]="variable"
                                               [forecastVersion]="forecastVersion"
                                               [options]="opts"
                                               (closeDialogEvent)="close()">
  </indicio-fvar-info-dialog-missing-values-tab>
  <br>
</ng-template>

<ng-template #outliersTemplate>
  <indicio-fvar-info-dialog-outliers-tab [variable]="variable"
                                         [options]="opts"
                                         (closeDialogEvent)="close()">
  </indicio-fvar-info-dialog-outliers-tab>
  <br>
</ng-template>

<ng-template #seasonalTemplate>
  <indicio-fvar-info-dialog-seasonal-tab [variable]="variable"
                                         [periodicity]="forecastVersion.Periodicity"
                                         [options]="opts"
                                         (closeDialogEvent)="close()">
  </indicio-fvar-info-dialog-seasonal-tab>
  <br>
</ng-template>

<ng-template #disaggregationTemplate>
  <indicio-fvar-info-dialog-disaggregation-tab [variable]="variable">
  </indicio-fvar-info-dialog-disaggregation-tab>
  <br>
</ng-template>

<ng-template #advancedTemplate>
  <indicio-fvar-info-dialog-advanced-tab [variable]="variable"
                                         [forecastVersion]="forecastVersion"
                                         (closeDialogEvent)="close()">
  </indicio-fvar-info-dialog-advanced-tab>
  <br>
</ng-template>
