import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccuracyChartViewDropdownOptions, AccuracyDropdownOptions, AccuracyMeasureType, EAccuracyChartView } from '@core/constants/accuracy.constants';
import { ActionService } from '@core/services/actions/actions.service';
import { FileGeneratorService } from '@core/services/file-generator.service';
import { StatusService } from '@core/services/status/status.service';
import { DisplayActions } from '@core/store/display/display.actions';
import { DisplayFrontendService } from '@core/store/display/display.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { GetMultivariateModelSuccessAction, GetUnivariateModelSuccessAction } from '@core/store/stat-model/stat-model.actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DisplayValue } from '@modules/lang/types/display-value';
import { ErrorBarChart } from '@shared/components/accuracy/error-bar-chart/error-bar-chart.input';
import { ErrorBarchartMapper } from '@shared/components/accuracy/error-bar-chart/error-bar-chart.mapper';
import { DataTable } from '@shared/components/data-table/data-table.model';
import { HoverRowsDirective } from '@shared/directives';
import { ModalAccuracyExcelGenerator } from '@shared/utils/generators/xlsx/model-accuracy.generator';
import { Subscription } from 'rxjs';

export interface SampleAccurracyDialogData {
  Models: ErrorBarChart.Model[],
  Type: 'univariate' | 'multivariate' | 'summary',
  ForecastVersionId: string,
}

@Component({
  selector: 'indicio-dialog-sample-accuracy',
  templateUrl: 'sample-accuracy.dialog.html',
  styleUrls: ['sample-accuracy.dialog.less']
})
export class SampleAccurracyDialogComponent implements OnInit, OnDestroy {
  @ViewChild(HoverRowsDirective) hoverRowsDir: HoverRowsDirective;

  public static Id: string = 'SampleAccurracyDialogComponent';

  private sub = new Subscription();

  public downloadIcon = faDownload as IconProp;

  public chartOptions = AccuracyChartViewDropdownOptions;
  public chosenChartOption = this.chartOptions[0];
  public currentAccuracyMeasurement: DisplayValue<AccuracyMeasureType>;
  public accuracyMeasurements: DisplayValue<AccuracyMeasureType>[] = AccuracyDropdownOptions;
  public accuracyTypes = [];
  private fVersion: ForecastVersionModel;
  private forecast: ForecastModel;

  private tableMapper: ErrorBarchartMapper;
  public dataTables: DataTable[] = [];

  private get outOfSampleEnabled() { return this.fVersion.OOSEnabled; };

  constructor(
    public dialogRef: MatDialogRef<SampleAccurracyDialogComponent>,
    private displayService: DisplayFrontendService,
    private forecastService: ForecastFrontendService,
    private actions: ActionService,
    private status: StatusService,
    private cd: ChangeDetectorRef,
    private generatorService: FileGeneratorService,
    @Inject(MAT_DIALOG_DATA) public data: SampleAccurracyDialogData) {
  }
  ngOnInit(): void {
    this.setup();
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public orderTable(table: DataTable, columnIndex: number) {
    this.tableMapper.orderTable(table, columnIndex);
  }

  public changeAccuracyMeasure(value: DisplayValue<AccuracyMeasureType>) {
    this.currentAccuracyMeasurement = value;
    this.displayService.setSetting({ AccuracyMeasureVariableMultivariate: this.currentAccuracyMeasurement.Value });
    this.displayService.setSetting({ AccuracyMeasureVariableUnivariate: this.currentAccuracyMeasurement.Value });

    if (this.chosenChartOption.Value !== EAccuracyChartView.OUTOFSAMPLE_ALLSTEPS) {
      return this.setHighlightColumn();
    }

    this.updateData();
  }

  public changeChartOption(value: DisplayValue<EAccuracyChartView>) {
    this.chosenChartOption = value;
    this.displayService.setSetting({ AccuracyChartView: this.chosenChartOption.Value });
    this.updateData();
  }

  public download() {
    const allSteps = this.chosenChartOption.Value === EAccuracyChartView.OUTOFSAMPLE_ALLSTEPS;
    this.generatorService.generateExcelFile((new ModalAccuracyExcelGenerator({
      Forecast: this.forecast,
      Tables: this.dataTables,
      Type: this.data.Type,
      InPercantage: allSteps && (this.currentAccuracyMeasurement.Value === 'MAPE' || this.currentAccuracyMeasurement.Value === 'MPE'),
      ChartView: this.chosenChartOption.Value,
      Measurement: this.currentAccuracyMeasurement.Value,
      Colors: this.dataTables[0].Rows.map(x => x[0]).map(x => this.data.Models.find(y => y.display === x)?.color),
    })).generate());
  }

  private async setup() {
    try {
      this.fVersion = await this.forecastService.getOrFetchForecastVersion(this.data.ForecastVersionId);
      this.forecast = await this.forecastService.getOrFetchForecast(this.fVersion.ForecastId);
      const settings = this.displayService.settings;
      const accuracyMeasure = settings.AccuracyMeasureVariableMultivariate;

      this.accuracyTypes = this.accuracyMeasurements.map(x => x.Value);
      this.currentAccuracyMeasurement = this.accuracyMeasurements.find(x => x.Value === accuracyMeasure);

      if (!this.fVersion.OOSEnabled) {
        this.chartOptions = [this.chartOptions.find(x => x.Value === EAccuracyChartView.INSAMPLE_FIRSTSTEP)];
        if (this.chosenChartOption.Value !== EAccuracyChartView.INSAMPLE_FIRSTSTEP) {
          this.changeChartOption(this.chartOptions[0]);
        }
      } else {
        this.chartOptions = AccuracyChartViewDropdownOptions.filter(x => x.Value !== EAccuracyChartView.INSAMPLE_FIRSTSTEP);
        if (this.chosenChartOption.Value === EAccuracyChartView.INSAMPLE_FIRSTSTEP) {
          this.changeChartOption(this.chartOptions[0]);
        }
        this.chosenChartOption = this.chartOptions.find(x => x.Value === settings.AccuracyChartView);
      }

      this.tableMapper = new ErrorBarchartMapper({
        Models: this.data.Models.filter(x => !!x && x.show),
        OutOfSampleEnabled: this.outOfSampleEnabled
      });

      this.setupSubscriptions();

      this.updateData();
    } catch (err) {
      this.status.setError(err, true);
    };
  }

  private setupSubscriptions() {
    this.sub.add(this.actions.dispatched(
      DisplayActions.AccuracyMeasureVariableMultivariate,
      DisplayActions.AccuracyMeasureVariableUnivariate
    ).subscribe((event: DisplayActions.AccuracyMeasureVariableMultivariate | DisplayActions.AccuracyMeasureVariableUnivariate) => {
      this.currentAccuracyMeasurement = this.accuracyMeasurements.find(x => x.Value === event.state);
      this.updateData();
    }));

    this.sub.add(this.actions.dispatched(
      GetUnivariateModelSuccessAction,
      GetMultivariateModelSuccessAction
    ).subscribe(() => this.updateData()));
  }

  private updateData() {
    this.dataTables = this.tableMapper.toDataTable(this.chosenChartOption.Value, this.currentAccuracyMeasurement.Value);
    this.cd.detectChanges();
    this.hoverRowsDir.setup();
    this.setHighlightColumn();
  }

  private setHighlightColumn() {
    const highlightColumn = this.currentAccuracyMeasurement.Value;
    this.dataTables.forEach(config => {
      this.tableMapper.setHighlightColumn(highlightColumn, config);
    });
    this.cd.detectChanges();
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
