import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NavigateToLogin } from '@core/actions/navigation.actions';
import { ServiceAction } from '@core/actions/service.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { PusherService } from '@core/services/frontend/pusher.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthActions, ClearStateAction } from '@core/store/auth/auth.actions';
import { AuthBackendService } from '@core/store/auth/auth.backend.service';
import { AuthMapper } from '@core/store/auth/auth.mapper';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CreateProfileDTO } from '@core/store/client/dtos/create-profile-dto';
import { IndicioPermissionType } from '@core/types/indicio-permission.type';
import { Store } from '@ngxs/store';
import { OpenGoogle2FAModal } from '@shared/modals/2-factor-providers/google/google-2fa-modal.actions';
import { CloseActiveModals } from '@shared/modals/modal.actions';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DateUtils } from '@shared/utils/date.utils';
import { SecurityUtils } from '@shared/utils/security.utils';
import { StorageUtils } from '@shared/utils/storage.utils';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { ClientSettingsService } from '../client/client-settings.service';
import { GetClientSuccessAction } from '../client/client.actions';
import { ClientSecurityDTO } from '../client/dtos/client-security-dto';
import { CompanyFrontendService } from '../company/company.frontend.service';
import { ToSService } from '../tos/tos.service';
import { AuthToken } from './auth-token';
import { AuthState } from './auth.state';
import { AuthUtils } from './auth.utils';
import { Verify2FaDTO } from './dtos/verify-2fa-dto';


const MFASTORAGENAME = 'indicio-2fa-rmt';

@Injectable({
  providedIn: 'root'
})
export class AuthFrontendService {

  private static started = false;
  public loginInProgress = false;

  /* Fields related to login-flow */
  private pkceKey: string;
  private flowId: string;

  private sub: Subscription = new Subscription();

  // OBSERVABLES
  public loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isAdmin$ = this.store.select(AuthState.isSysAdmin);

  // GETTERS
  public get loggedIn() { return this.store.selectSnapshot(AuthState.loggedIn); }
  public get isAdmin() { return this.store.selectSnapshot(AuthState.isSysAdmin); }
  public get systemPermissions() { return this.store.selectSnapshot(AuthState.systemPermissions); }
  public get token() { return this.store.selectSnapshot(AuthState.token); }
  public get mfa_authed() { return this.store.selectSnapshot(AuthState.mfa_authed); }
  public get mfa_enabled() { return this.store.selectSnapshot(AuthState.mfa_enabled); }
  public get mfa_method() { return this.store.selectSnapshot(AuthState.mfa_method); }
  public get sessionId() { return this.store.selectSnapshot(AuthState.sessionId); }
  public get isSupport() { return this.store.selectSnapshot(AuthState.isSupport); }
  public get isSales() { return this.store.selectSnapshot(AuthState.isSales); }

  public setupInProcess: boolean = false;

  constructor(
    private zone: NgZone,
    private store: Store,
    private pusher: PusherService,
    private clientService: ClientFrontendService,
    private clientSettingsService: ClientSettingsService,
    private backend: AuthBackendService,
    private mapper: AuthMapper,
    private status: StatusService,
    private router: Router,
    private companyService: CompanyFrontendService,
    private actions: ActionService,
    private dialogService: DialogService,
    private tosService: ToSService,
  ) {
    this.setupActionSubscriptions();
  }

  public startLogin(username: string) {
    this.pkceKey = AuthUtils.getRandomHexString(32);
    this.flowId = AuthUtils.getRandomHexString(12);
    return AuthUtils.sha256(this.pkceKey)
      .then(challenge => {
        return this.backend.startLogin(username, this.flowId, challenge);
      })
      .then(resp => {
        if (resp.IsSso) { /* Add SSO Support... */ }
      });
  }

  public authenticate(username: string, password: string) {
    return this.backend.authenticate(this.flowId, password)
      .then(resp => {
        /* Users with a single role does not need to pick one... */
        if (resp.Roles.length === 1) { return { role: resp.Roles[0], code: resp.Code }; }

        /* Users with multiple roles must select one. */
        const ref = this.dialogService.openPickSystemRoleDialog({ Roles: resp.Roles });
        return ref.toPromise()
          .then(role => {
            if (role == null) { throw 'Role not picked'; }
            return { role: role, code: resp.Code };
          });

      })
      .then(({ role, code }) => {
        this.loginInProgress = true;
        return this.backend.getToken(this.flowId, username, code, this.pkceKey, role);
      })
      .then(token => this.handleToken(token))
      .catch(err => {
        this.logout(false);
        throw err;
      });
  }

  public hasPermission(permission: IndicioPermissionType) {
    return SecurityUtils.checkPermission(this.systemPermissions, permission);
  }

  public changePassword(oldPassword: string, newPassword: string) {
    return this.backend.changePassword(oldPassword, newPassword);
  }

  public is2FaForced() {
    return this.token.force_two_factor;
  }

  public fetchClientSecuritySettings() {
    this.refreshToken(this.token).then((resp) => {
      if (!resp || resp.force_two_factor && !this.token.two_factor_enabled) {
        return this.logout();
      }
      this.clientService.fetchSecuritySettings().then((securitySettingsDTO: ClientSecurityDTO) => {
        this.clientService.client.AutomaticLogout = securitySettingsDTO.AutomaticLogout;
        this.clientService.client.Disabled = securitySettingsDTO.Disabled;
        this.store.dispatch(new GetClientSuccessAction(this.clientService.client));
      }).catch(() => {
        this.logout();
      });
    });
  }

  public handleForce2FA(token: AuthToken) {
    const now = DateUtils.newDate();
    now.setDate(now.getDate() + 5);
    const forceFrom = DateUtils.newNullableDate(token.force_two_factor_from) ?? DateUtils.newDate('1970-01-01');
    const force = forceFrom.getTime() < now.getTime();
    return new Promise<void>((resolve, reject) => {
      const ref = this.dialogService.openForce2FASettingsDialog({ force, forceFrom }, { width: '600px', disableClose: force, position: { top: '7%' } });
      ref.subscribe((x: boolean) => {
        if (!x && force) {
          this.status.setMessage('You must setup two factor authentication. Please login again and follow the instructions.', 'Error');
          this.loginInProgress = false;
          this.logout();
          return reject();
        }
        resolve();
      });

    });
  }



  private handleToken(token: AuthToken) {
    return this.store.dispatch(new AuthActions.StoreToken(token)).toPromise()
      .then(() => {
        if (token.force_two_factor && !token.two_factor_enabled) {
          if (moment(token.force_two_factor_from).isBefore(moment(), 'day')) {
            this.loginInProgress = false;
            return this.status.setMessage('Your grace period for setting up two-factor authentication has expired. Please contact customer support.', 'Error');
          }
          return this.handleForce2FA(token).then(() => {
            return this.refreshToken(token)
              .then(newToken => {
                this.fetchClientAndStoreToken(newToken, false);
              }).catch(e => {
                throw e;
              });
          });
        } else if (token.two_factor_enabled && !token.two_factor_authed) {
          return this.handle2FaLogin(token);
        } else {
          this.fetchClientAndStoreToken(token);
        }
      });
  }

  private handle2FaLogin(token: AuthToken) {
    if (this.check2faCookie()) {
      const value = this.get2faCookie();
      this.verify2FA({ RememberToken: value, Code: null, RememberMe: false, BackupCode: null })
        .then(() => {
          this.refreshToken()
            .then(t => {
              this.fetchClientAndStoreToken(t);
            });
        })
        .catch(error => {
          this.open2FaModal(token);
          this.status.setError(error, false);
        });
    } else {
      this.open2FaModal(token);
    }
  }

  private open2FaModal(token: AuthToken) {
    switch (token.two_factor_method) {
      case 'GoogleAuthenticator':
        this.store.dispatch(new OpenGoogle2FAModal());
        break;
      default:
        console.error('Unknown 2fa provider. Contact support');
        this.status.setMessage('Unknown 2-factor provider. Contact support if you cannot login', 'Error');
        this.logout();
        break;
    }
  }

  public check2faCookie() {
    return StorageUtils.check(MFASTORAGENAME);
  }

  public get2faCookie() {
    return StorageUtils.get(MFASTORAGENAME);
  }

  public set2faCookie(str: string) {
    StorageUtils.set(MFASTORAGENAME, str);
  }

  public fetchClientAndStoreToken(token: AuthToken, checkCompanies = true) {
    this.__storeSession(token);
    this.__tokenRefresher();
    return this.clientService.getClient()
      .then(() => this.store.dispatch(new AuthActions.RunPostLogin(checkCompanies)))
      .catch(error => {
        this.logout(false);
        this.status.setError(error);
        throw error;
      })
      .finally(() => this.loginInProgress = false);
  }

  public verifyPassword(username: string, password: string) {
    return this.backend.verifyPassword(username, password);
  }

  public reload() {
    const currentUrl = this.router.url;
    this.loginInProgress = true;
    this.router.navigateByUrl('/login');
    return this.clearStore()
      .then(() => this.checkStoredAuth(true, false)
        .then(() => {
          this.router.navigateByUrl(currentUrl);
        })
        .catch(() => {
          this.logout(false);
        })
      ).catch(() => {
        this.logout(false);
      });
  }

  public logout(remove2fa = true, rememberUrl = false) {
    this.logoutWithoutRedirection(remove2fa);
    if (rememberUrl) {
      return this.router.navigate(['login'], { queryParams: { returnUrl: window.location.pathname + window.location.search } });
    }
    this.store.dispatch(new NavigateToLogin());
  }

  public logoutWithoutRedirection(remove2fa = true) {
    this.loginInProgress = false;
    this.clearStore();
    if (remove2fa) {
      this.remove2faCookie();
    }
    this.__clearSession();
  }

  public clearStore() {
    return Promise.all([
      this.store.dispatch(new ServiceAction.Reset()).toPromise(),
      this.store.dispatch(new CloseActiveModals()).toPromise(),
      this.store.dispatch(new ClearStateAction()).toPromise()
    ]);
  }

  public remove2faCookie() {
    StorageUtils.remove(MFASTORAGENAME);
  }

  public __clearSession() {
    localStorage.removeItem('_token');
  }

  public checkInviteCode(code: string) {
    return this.backend.checkInviteCode(code);
  }

  public signup(profile: CreateProfileDTO) {
    return this.backend.signup(profile);
  }

  public readSession() {
    const token = this.__readToken();
    if (token) {
      const tokenObject: AuthToken = JSON.parse(token);
      const t = this.mapper.mapStoredToken(tokenObject);
      return t;
    } else { return null; }
  }

  public checkStoredAuth(check2fa: boolean = true, checkCompanies: boolean = true) {
    const tempToken = this.readSession();
    if (!tempToken) {
      return Promise.reject('No token found');
    }

    return this.refreshToken(tempToken)
      .then(newToken => {
        if (newToken.force_two_factor && !newToken.two_factor_enabled) {
          return this.handleForce2FA(newToken).then(() => {
            this.refreshToken(newToken)
              .then(newNewToken => {
                this.fetchClientAndStoreToken(newNewToken, false);
              });
          });
        }
        if (newToken.two_factor_enabled && !newToken.two_factor_authed && check2fa) {
          return this.handle2FaLogin(newToken);
        }
        this.fetchClientAndStoreToken(newToken, checkCompanies);
      }).catch(e => {
        throw e;
      });
  }

  public needToSetActiveCompany() {
    if (this.companyService.manualSetActiveCompany) { return false; }
    const activeId = this.clientService.client.ActiveCompanyId;
    const allIds = this.clientService.client.CompanyIds;
    const currentExists = allIds.includes(activeId);
    const rememberActiveCompany = this.clientSettingsService.settings.RememberActiveCompany;

    if (allIds.length <= 1 && currentExists) {
      return false;
    }

    if (!rememberActiveCompany) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * 2-factor stuff
   *
   */
  public setup2FA(body) {
    return this.backend.setup2FA(body);
  }

  public verify2FA(body: Verify2FaDTO) {
    return this.backend.verify2FA(body)
      .then(ans => {
        if (body.RememberMe) {
          this.set2faCookie(ans.body.RememberToken);
        }
      });
  }

  public disable2FA() {
    return this.backend.disable2FA();
  }

  public getMySessions() {
    return this.backend.getMySessions();
  }

  public deleteAllSession() {
    return this.backend.deleteAllSession();
  }

  public deleteSession(sessionId: string) {
    return this.backend.deleteSession(sessionId);
  }

  /***********************
   ****    HELPERS    ****
   ***********************/
  private __tokenRefresher() {
    if (AuthFrontendService.started) { return; }
    this.zone.runOutsideAngular(() => {
      const source = interval(10000);
      source.subscribe(_trigger => {
        if (this.token) {
          const now = DateUtils.newDate().getTime() / 1000;
          const timeToRefresh = now + 40;
          const tokenExpires = this.token.token_expires.getTime() / 1000;
          this.pusher.verifyBindings();
          if (now > tokenExpires) {
            this.logout(false);
          } else if (timeToRefresh > tokenExpires) {
            this.refreshToken();
          }
        }
      });
      AuthFrontendService.started = true;
    });
  }

  public refreshToken(token: AuthToken = null) {
    return this.backend.refreshToken(token ? token : this.token)
      .then(newToken => {
        this.store.dispatch(new AuthActions.StoreToken(newToken));
        this.__storeSession(newToken);
        return newToken;
      });
  }

  private __readToken() {
    return localStorage.getItem('_token');
  }

  private __storeSession(token: AuthToken) {
    const tokenString = JSON.stringify(token);
    localStorage.setItem('_token', tokenString);
  }

  private setupActionSubscriptions() {
    this.sub.add(this.actions.dispatched(AuthActions.Logout).subscribe((action: AuthActions.Logout) => {
      this.logout(action.remember2fa);
    }));

    this.store.select(AuthState.loggedIn).subscribe((loggedIn) => {
      this.loggedIn$.next(loggedIn);
    });
  }
}
