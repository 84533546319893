<div mat-dialog-content
     style="width: 100%; height: calc(100vh - 100px); max-height: unset; align-items: center; justify-content: center; display: flex; font-size: 30px;">
  Redirecting to {{ provider }}...
</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
</div>
