export namespace DialogActions {
  export class Close {
    public static readonly type = '[DIALOG] Close dialog';
    constructor(public name: string) { }
  }

  export class CloseById {
    public static readonly type = '[DIALOG] Close dialog by id';
    constructor(public id: string) { }
  }
}
