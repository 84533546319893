import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'indicio-mb-set-prim-name',
  template: `
      <mat-form-field>
        <mat-label *ngIf="!showLoader">Macrobond id</mat-label>
        <mat-label *ngIf="showLoader"> <i class="ion-load-c spinner"></i> Getting identifier from
          macrobond...</mat-label>
        <input type="text"
               class="material"
               matInput
               autocomplete="off"
               [ngModel]="currentPrimName"
               (ngModelChange)="newPrimName.emit($event)"
               name="primname"
               minlength="4"
               required>
      </mat-form-field>
  `
})
export class MacrobondSetPrimNameComponent {

  @Input() currentPrimName: string;
  @Input() showLoader: boolean = true;

  @Output() newPrimName = new EventEmitter<string>();
}
