import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { TagMetaDTO } from '@core/store/tags/dtos/tags.dtos';
import { TagModel } from '@core/store/tags/models/tag.model';
import { TagsFrontendService } from '@core/store/tags/tags.frontend.service';
import { SearchDropDownDTO } from '@shared/components/dropdown/autocomplete/autocomplete.component';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { Observable } from 'rxjs';
import { CreateTagDialogData } from '../create-tag/create-tag.dialog';

export interface BulkTagSourceVariablesDialogData {
  Tags: TagMetaDTO[];
}

@Component({
  selector: 'indicio-bulk-tag-sourcevariables-dialog',
  templateUrl: 'bulk-tag-sourcevariables.dialog.html',
  styleUrls: ['../../dialogs.less']
})
export class BulkTagSourceVariablesDialogComponent {
  public static Id: string = 'BulkTagSourceVariablesDialogComponent';

  public searchControl = new UntypedFormControl();
  public filteredTags: Observable<TagModel[]>;
  public filteredTagsModels: TagModel[] = [];
  public result: TagMetaDTO[] = [];

  public statusMsg: string;
  private statusTimer: any;

  constructor(
    public dialogRef: MatDialogRef<BulkTagSourceVariablesDialogComponent>,
    public service: SourceVariableFrontendService,
    public dialogService: DialogService,
    private tagService: TagsFrontendService,
    private status: StatusService,
    @Inject(MAT_DIALOG_DATA) public data: BulkTagSourceVariablesDialogData) {
    this.result = data.Tags.slice();
    this.filteredTagsModels = this.tagService.searchTags('');
  }

  onNoClick(): void {
    this.dialogRef.close(undefined);
  }

  public onAddNew(event: SearchDropDownDTO) {
    const dto = new CreateTagDialogData();
    dto.preTag = event.value;
    const ref = this.dialogService.openCreateTagDialog(dto);
    ref.subscribe((newTag: TagModel) => {
      if (!newTag) { return; }
      this.tagService.createNewTag(newTag)
        .then((tag: TagModel) => {
          this.setStatusMessage('New tag created');
          this.result.push(tag);
          event.fn(tag);
        })
        .catch(err => this.status.setError(err));
    });
  }

  setStatusMessage(message: string) {
    if (this.statusTimer) {
      window.clearTimeout(this.statusTimer);
    }
    this.statusMsg = message;
    this.statusTimer = setTimeout(() => {
      this.statusMsg = null;
    }, 10500);
  }

  save() {
    this.dialogRef.close(true);
  }
}
