<div *ngIf="model"
     class="check-circle-label"
     (click)="click()">
  <div class="inline">
    <i class="pull-left"
       [ngClass]="model.Active ? (model.Checked ? 'ion-ios-checkmark' : 'ion-ios-circle-outline') : 'ion-close-circled'"
       [ngStyle]="{ '--color': model.Active ? (model.Checked ? model.Color : 'var(--indicio-main-text-color, #cecece)') : '#CACACA' }">
    </i>
    &nbsp;
    <span>
      {{ model.Text }}
    </span>
  </div>
</div>
