<ng-container *ngIf="getProjectForForecast(node) as project">
  <div class="node center-vertical cursor-pointer {{ node.Type }} {{ listView }}"
       style="height: 30px;"
       [ngClass]="{ active: navService.isOnForecast(node.ObjectId) || node.isActive }"
       [style.padding-left.px]="7 + (node.level) * 7"
       (click)="clickedNode(node, $event)">
    <div></div>
    <div [title]="node.Name"
         class="name crop-string"
         style="display:inline-block;">
      <span>{{node.Name}}</span>
    </div>
    <div class="info-chip"
         *ngIf="!project.hasPermission('CAN_UPDATE_FORECAST'); else canUpdateTemplate"
         indicioTippy="You have limited permissions in this shared forecast"
         style="margin-right: 3px; font-size: 8px;">
      <fa-icon class="cursor-pointer"
               [icon]="faLock"></fa-icon>
    </div>
    <ng-template #canUpdateTemplate>
      <div class="info-chip shared"
           indicioTippy="New data available, click to update"
           (click)="openRefreshDialog(node); $event.stopPropagation()"
           *ngIf="node.NewData; else fillerDiv">
        <i class="ion-loop"></i>
      </div>
    </ng-template>
    <div class="info-chip periodicity"
         indicioTippy="{{ env.getPeriodicity(node.Periodicity).Display }} periodicity">{{ node.Periodicity[0] }}</div>
    <div class="menu">
      <ng-container *ngTemplateOutlet="forecastMenuTemplate; context: { node: node, project: project }">
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #fillerDiv>
  <div></div>
</ng-template>

<ng-template #forecastMenuTemplate
             let-node="node"
             let-parent="parent"
             let-project="project">
  <indicio-options-menu fillerTop="-33%"
                        [nohover]="true"
                        iconsize="21px"
                        [containerScrolled]="scrollPosition"
                        [filler]="true"
                        (openEvent)="toggleForecastMenu(node, $event)">
    <ul>
      <ng-container *ngIf="project.hasPermission('CAN_UPDATE_FORECAST_VARIABLE')">
        <li (click)="openSyncDataDialog(node)"
            indicioTippy="Fetch latest version for each source variable used in this forecast">
          <i class="ion-loop"></i>
          <div class="text">
            Update data
          </div>
        </li>
        <li *ngIf="isAdmin$ | async"
            (click)="openCalcResultsDialog(node)"
            indicioTippy="Calculate all results in this forecast">
          <i class="ion-ios-list-outline"></i>
          <div class="text">
            Calculate
          </div>
        </li>
        <li divider></li>
      </ng-container>

      <li (click)="editForecast(project, node)">
        <i
           [ngClass]="project.hasPermission('CAN_UPDATE_FORECAST') ? 'ion-android-settings' : 'ion-information-circled'"></i>
        <div class="text">
          Settings
        </div>
      </li>
      <li (click)="togglePinForecast(node)">
        <i class="ion-pin"></i>
        <div class="text">
          {{ isPinned(node) ? 'Unpin from top' : 'Pin to top' }}
        </div>
      </li>
      <ng-container *ngIf="project.hasPermission('CAN_DELETE_FORECAST')">
        <li divider></li>
        <li (click)="downloadForecastData(node)">
          <i class="ion-android-download"></i>
          <div class="text">Download data</div>
        </li>
      </ng-container>
      <li divider></li>
      <li *ngIf="project.hasPermission('CAN_DELETE_FORECAST')"
          (click)="moveForecast(node)">
        <i class="ion-arrow-swap"></i>
        <div class="text">Move</div>
      </li>
      <li *ngIf="project.hasPermission('CAN_UPDATE_FORECAST_VARIABLE')"
          (click)="copyForecast(node)">
        <i class="ion-ios-copy"></i>
        <div class="text">Copy</div>
      </li>
      <ng-container *ngIf="project.hasPermission('CAN_DELETE_FORECAST')">
        <li divider></li>
        <li (click)="removeForecast(node)">
          <i ngClass="ion-trash-b"></i>
          <div class="action-button remove">Remove
          </div>
        </li>
      </ng-container>
    </ul>
  </indicio-options-menu>
</ng-template>
