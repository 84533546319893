import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileActions } from '@core/store/profile/profile.actions';
import { TinyColor } from '@ctrl/tinycolor';
import { ValueUtils } from '@shared/utils/value.utils';
import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { BarChartSVGElements } from './bar-chart-svg-elements';
import { BAR_CHART_OPTS, BarChartEntry, BarChartOptions } from './bar-chart.options';

@Component({
  selector: 'indicio-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class BarChartComponent implements OnDestroy, OnChanges, AfterViewInit {
  subscriptions = new Subscription();

  /* _id field help keep track of each one when displaying more than one bar-graph, e.g. in uni or multi view */
  public _id = `barchart-${Math.random().toString(36).substring(7)}`;

  /* Event listeners */
  @HostListener('window:resize', ['$event.target'])
  public onResize() { this.updateGraph(); }

  /* Input */
  @Input() config: BarChartOptions;
  @Input() drawTrigger: any;
  @Input() hidden: boolean;

  /* State flags */
  private initialized = false;

  /* SVG Elements */
  private svgElems: BarChartSVGElements = new BarChartSVGElements();

  /* SVG properties */
  private margins = { top: 10, right: 20, bottom: 20, left: 10 };
  private graphWidth: number;
  private graphHeight: number;
  private minValue: number;
  private maxValue: number;

  /* Container properties, set on svg-container div in html */
  public chartStyles = { plotHeight: 'auto', plotWidth: '400px' };

  constructor(
    private el: ElementRef,
    private appearance: AppearanceService,
    private actions: ActionService
  ) { }

  //#region Getters used to access data
  private get divName() { return this._id; }
  private get data() { return this.config.Data; }
  private get labels() { return this.config.Labels; }
  private get svgHeight() { return this.barHeight * this.modelCount + BAR_CHART_OPTS.BAR_AXIS_MARGIN; }
  private get svgWidth() { return +this.svgElems.svg.attr('width'); }
  private get maxValueDiff() { return this.maxValue - this.minValue; }
  private get modelCount() { return this.config.Data.length; }
  private get singleModel() { return this.modelCount === 1; }
  private get maxTicks() { return !this.singleModel && this.maxValue - this.minValue < 6 ? this.maxValue - this.minValue : 6; }
  private get barHeight() {
    const usableHeight = BAR_CHART_OPTS.SVG_TARGET_HEIGHT - (this.modelCount * BAR_CHART_OPTS.BAR_MARGIN);
    const curr = Math.ceil(usableHeight / this.modelCount);
    const max = Math.max(BAR_CHART_OPTS.BAR_MIN_HEIGHT, curr);
    return Math.min(max, BAR_CHART_OPTS.BAR_MAX_HEIGHT);
  }
  //#endregion

  /* Create a linear scale from 0 to the graphWidth - the supplied margin */
  public getXScale(leftMargin: number): d3.ScaleLinear<number, number> {
    return d3.scaleLinear()
      .domain([this.minValue, this.maxValue])
      .rangeRound([0, this.graphWidth - leftMargin])
      .nice();
  }

  //#region NG Event functions
  public ngOnDestroy() { this.el.nativeElement.innerHTML = ''; }
  public ngOnChanges(_changes: SimpleChanges) {
    if (!this.initialized && !!document.getElementById(this.divName)) {
      this.initialized = true;
      this.updateGraph();
    }
  }
  public ngAfterViewInit(): void {
    this.updateGraph();

    this.subscriptions.add(this.actions.dispatched(ProfileActions.SavedColorTheme).subscribe((_evt: ProfileActions.SavedColorTheme) => {
      this.updateGraph();
    }));
  }
  //#endregion

  private getXPos(d: BarChartEntry) {
    if (this.minValue < 0) {
      return d.Value < 0 ? this.svgElems.xScale(d.Value) : this.svgElems.xScale(0);
    } else {
      return 0;
    }
  }

  private getBarWidth(d: BarChartEntry) {
    const xCoord = this.svgElems.xScale(d.Value);
    d.barBBoxW = Math.abs(xCoord);
    return d.barBBoxW;
  }

  private getValueText(d: BarChartEntry) {
    if (!d.IsNA)
      return this.toDisplay(d.Value);
    else
      return 'N/A';
  }

  private onBarMouseover(e: SVGRectElement, d: BarChartEntry) {
    const hidden = e.parentNode.querySelector('.hidden');
    if (hidden) {
      hidden.classList.remove('hidden');
      hidden.classList.add('hideafter');
    }
    try {
      const rect = e.parentNode.querySelector(`.${d.Id}`);
      rect.classList.remove('hidden');
    } catch (e) { }
  }

  private onBarMouseout(e: SVGRectElement, _d: BarChartEntry) {
    const hidden = e.parentNode.querySelector('.hideafter');
    if (!hidden) { return; }
    hidden.classList.remove('hideafter');
    hidden.classList.add('hidden');
  }

  public updateGraph() {
    if (this.hidden) { return; }
    const allowNegatives = false;
    // Do nothing if we are not initialized
    if (!this.config.Data.length || !!!document.getElementById(this.divName)) { return; }
    // Remove current entry
    this.removeSvg();

    /* fake-this to reference this inside inline functions below */
    const self = this;

    /* Create main SVG object and assign properties */
    this.svgElems.svg = d3.select(`#${this.divName}`).append('svg')
      .attr('width', document.getElementById(this.divName).offsetWidth)
      .attr('height', this.svgHeight);

    /* Calculate graph properties given the main SVG object */
    this.graphWidth = +this.svgWidth - this.margins.left - this.margins.right;
    this.graphHeight = +this.svgElems.svg.attr('height') - this.margins.top - this.margins.bottom;
    this.maxValue = d3.max(this.data, d => d3.max(this.data, _ => +d.Value));
    this.minValue = d3.min(this.data, d => d3.min(this.data, _ => +d.Value));

    /* Create our 'bands'
       - y0: One band for each model
     */
    /* Create the main group, move the inner coordinate system to the correct location (x, y) */
    this.svgElems.graphGroup = this.svgElems.svg.append('g').attr('transform', 'translate(' + this.margins.left + ',' + this.margins.top + ')');

    const scale = !this.singleModel;

    /* If we only show 1 model, set minValue to 0 */
    if (this.minValue > 0 && !scale) { this.minValue = 0; }

    /* If we show more than 1 model, set the minValue to have 5% margin, calculated from the max value diff (min to max) */
    else if (!!scale) { this.minValue = Math.min(this.minValue - 0.05 * this.maxValueDiff, this.minValue * 0.95); }
    if (!allowNegatives && this.minValue < 0) { this.minValue = 0; }

    /* If we only show 1 model, we want the maxValue to "end" at a minimum of 0, (to align it with the y-axis?) */
    if (this.maxValue < 0) { this.maxValue = 0; }

    /* But if we indeed to have more than 1 model; set the maxValue to maxValue + 5% or maxDiff */
    if (!!scale) { this.maxValue = this.maxValue + 0.05 * this.maxValueDiff; }

    const y0 = d3.scaleBand().domain(this.data.map(x => x.Id)).rangeRound([this.graphHeight, 0]).padding(0.05);

    const barMinHeight = Math.max(y0.bandwidth(), this.barHeight);
    const magicTextOffset = 3;

    /* X-Scale */
    this.svgElems.xScale = this.getXScale(this.margins.left);

    /* All them bars here */
    this.svgElems.bars = this.svgElems.graphGroup
      .selectAll('g').data(this.data).enter()
      .append('g').attr('height', barMinHeight).attr('transform', (_, i) => `translate(0, ${i * (barMinHeight)})`);

    /* Each bar has a rect with the color for the current model. */
    this.svgElems.bars.append('rect')
      // Positioned at the top left-corner of the group
      .attr('x', d => this.getXPos(d))
      .attr('y', BAR_CHART_OPTS.BAR_MARGIN)
      .attr('width', d => this.getBarWidth(d))
      .attr('height', barMinHeight - BAR_CHART_OPTS.BAR_MARGIN)
      .attr('data-key', d => d.Id)
      .attr('fill', d => d.Color);

    /* The first text-element holds the value - the next one holds the name */
    this.svgElems.bars.append('text')
      .text(d => this.getValueText(d))
      .attr('style', this.getValueStyle())
      .attr('class', function (d) { return self.getValueClasses(this, d); })
      .attr('y', BAR_CHART_OPTS.BAR_MARGIN)
      .attr('dx', function (d) { return self.getValueDx(d); })
      .attr('dy', function (d) { return barMinHeight / 2 + (d.vTextBBoxH / 2) - magicTextOffset; })
      .attr('text-anchor', (d) => this.getValueTextAnchor(d));

    /* Here we add the text element that holds the name */
    this.svgElems.bars.append('text')
      .text(d => d.Label)
      .attr('style', this.getValueStyle())
      .attr('x', 0)
      .attr('y', BAR_CHART_OPTS.BAR_MARGIN)
      .attr('dx', function (d) { return self.getNameDx(d); })
      .attr('dy', function (d) { return barMinHeight / 2 + (d.vTextBBoxH / 2) - magicTextOffset; })
      .attr('text-anchor', function (d) { return self.getNameTextAnchor(d); })
      .attr('class', function (d) { return self.getNameClasses(d); });

    /* Hover-rect overlay over full width of the bar (one for each bar in bars)
       Note: Must be added last in the bars-group, to put this rect on top of the others */
    this.svgElems.bars.append('rect')
      .attr('x', 0)
      .attr('y', BAR_CHART_OPTS.BAR_MARGIN)
      .attr('width', this.graphWidth)
      .attr('height', barMinHeight - BAR_CHART_OPTS.BAR_MARGIN)
      .style('fill', 'transparent')
      .on('mouseover', function (d) { self.onBarMouseover(this, d); })
      .on('mouseout', function (d) { self.onBarMouseout(this, d); });


    const tickValues = [
      ValueUtils.getValueWithSignificantDigits(this.minValue, 2),
      ValueUtils.getValueWithSignificantDigits((this.minValue + this.maxValue) / 2, 2),
      ValueUtils.getValueWithSignificantDigits(this.maxValue, 2)];
    this.svgElems.xAxis = this.svgElems.graphGroup.append('g')
      .attr('class', 'axis')
      .attr('transform', 'translate(0,' + this.graphHeight + ')')
      .call(d3.axisBottom(this.svgElems.xScale)
        .tickValues(tickValues)
        .tickFormat(d => this.toDisplay(d))
      )
      .append('text')
      .attr('x', this.graphWidth / 2 - 35)
      .attr('y', this.margins.bottom * 0.8)
      .attr('dx', '0.32em')
      .attr('fill', '#000')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'start')
      .text(this.labels.xAxis);

    if (this.minValue < 0) {
      const middleAxis = this.svgElems.graphGroup.append('g')
        .attr('class', 'axis middle-axis')
        .call(d3.axisRight(y0).tickSize(0))
        .attr('transform', `translate(${this.svgElems.xScale(0)},0)`);
      middleAxis.selectAll('text').remove();
    }

    this.svgElems.yAxis = this.svgElems.graphGroup.append('g')
      .attr('class', 'axis leftaxis')
      .call(d3.axisLeft(y0).ticks(0, 's').tickSize(0))
      .append('text')
      .attr('dy', '0.71em')
      .attr('fill', '#000')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'start')
      .text(this.labels.yAxis)
      .attr('x', function (_) {
        return ((self.svgHeight / 2) * -1) + self.margins.bottom;
      })
      .attr('y', (this.margins.left === 0 ? 40 : this.margins.left) * 0.8 * -1)
      .attr('transform', 'rotate(-90)');

    [...this.el.nativeElement.querySelectorAll('.leftaxis .tick')].forEach(elem => elem.remove());
  }

  private getBBoxes(e: SVGTextElement) {
    const textBBox = e.getBBox();
    return { textBBox };
  }

  private getValueBoxInfo(d: BarChartEntry) {
    const margin = 10;
    const valueIsOutside = d.vTextBBoxW + margin >= d.barBBoxW;
    const forceTextInsideBar = d.nTextBBoxW + d.barBBoxW >= (this.svgWidth - this.margins.right);
    return { valueIsOutside, forceTextInsideBar };
  }

  private getNameDx(d: BarChartEntry) {
    const { valueIsOutside, forceTextInsideBar } = this.getValueBoxInfo(d);
    const nameMargin = 7;
    const nameMarginInside = 17;
    const nameMarginFromBar = 5;
    let position: number;
    if (forceTextInsideBar) {
      position = d.barBBoxW - d.vTextBBoxW - nameMarginInside;
    } else if (valueIsOutside) {
      position = d.barBBoxW + d.vTextBBoxW + nameMarginFromBar;
    } else {
      position = d.barBBoxW;
    }
    return position + nameMargin;
  }

  private getValueDx(d: BarChartEntry) {
    const { valueIsOutside } = this.getValueBoxInfo(d);
    const xCoord = this.svgElems.xScale(d.Value);
    const spaceBeforeText = Math.abs(xCoord);
    const valueMargin = 5;
    if (this.minValue < 0) {
      if (d.Value < 0) {
        if (d.vTextBBoxW + valueMargin > d.barBBoxW) {
          return this.svgElems.xScale(d.Value) - d.vTextBBoxW;
        }
        return this.svgElems.xScale(d.Value) + valueMargin;
      } else {
        const w = Math.abs(xCoord);
        if (d.vTextBBoxW + valueMargin > d.barBBoxW) {
          return w + d.vTextBBoxW + 3;
        }
        return w - valueMargin;
      }
    } else {
      // If we put it outside
      if (valueIsOutside) {
        return spaceBeforeText + d.vTextBBoxW + valueMargin;
      }
      // Else the value fits inside the bar, put it there
      return spaceBeforeText - valueMargin;
    }
  }

  private getNameClasses(d: BarChartEntry) {
    const c = new TinyColor(d.Color);
    const { forceTextInsideBar } = this.getValueBoxInfo(d);
    let classes;
    if (forceTextInsideBar) { classes = `${d.Id} ${(c.isLight() ? 'black' : 'white')}`; }
    if (d.HideLabel) { classes += ' hidden'; }
    return classes;
  }

  private getValueClasses(e: SVGTextElement, d: BarChartEntry) {
    const c = new TinyColor(d.Color);
    // Start with color, set to black for light bar-colors, else white
    let classes = c.isLight() ? 'black' : 'white';
    const { textBBox } = this.getBBoxes(e);
    d.vTextBBoxH = textBBox.height;
    d.vTextBBoxW = textBBox.width;
    if (textBBox.width + 5 > d.barBBoxW || textBBox.width + 15 > d.barBBoxW) {
      classes = this.appearance.ActualTheme !== 'light' ? 'white' : 'black';
    }

    classes += ' id-' + d.Id;
    return classes;
  }

  private toDisplay(v: any): string {
    return ValueUtils.getValueAsAbbreviatedString(+v, this.config.Percentage);
  }

  private getValueStyle() {
    return `font-size: ${this.modelCount > 20 ? '9.5' : '11'}px`;
  }

  private getValueTextAnchor(d: BarChartEntry) {
    return this.minValue < 0 && d.Value < 0 ? 'start' : 'end';
  }

  private getNameTextAnchor(d: BarChartEntry) {
    const { forceTextInsideBar } = this.getValueBoxInfo(d);
    return forceTextInsideBar ? 'end' : 'start';
  }

  private removeSvg() {
    if (this.svgElems.svg) {
      this.svgElems.svg.remove();
      this.svgElems.svg = null;
    }
  }
}
