import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { VariableInfoDTO } from '@core/store/reports/dtos/company-forcasts.dto';
import { AutoTaskByEventType, AutoTaskSendEmailTrigger, AutoTaskSendMailTriggerType, AutoTaskStep, AutoTaskStepType, AutoTaskTrigger, AutoTaskTriggerOn, AutoTaskTriggerOnEvent, AutoTaskTriggerOnType, AutoTaskTriggerType, EAutoTaskState } from '@modules/lang/language-files/automations';
import { DateUtils } from '@shared/utils/date.utils';
import { AutoTaskAlertInfoDTO } from './auto-task.dto';

export class CreateAutoTaskModel {
  @Def() Name: string;
  @Def() Description: string;
  @Def() Active: boolean = true;
  @Def() Interval: string;
  @Def() TriggerOn: AutoTaskTriggerOnType = 'first';
  @Def() TriggerAtDay: number;
  @Def() TriggerAtHour: number = 4;
  @Def() TriggerType: AutoTaskTriggerType = 'by-schedule';
  @Def() OnEventType: AutoTaskByEventType;
  @Def() Steps: AutoTaskStepModel[] = [];
  @Def() CompanyId: string;
}

export class AutoTaskModel extends CreateAutoTaskModel implements IHasModelId {
  @Def() AutoTaskId: string;
  @Def() CreatedDate: Date;
  @Def() LastRunAt: Date;
  @Def() State: EAutoTaskState = 0;
  @Def() History: AutoTaskRunResultModel[] = [];
  @Def() CreatorEmail: string;
  @Def() Errors: AutoTaskAlertInfoDTO[] = [];
  @Def() Warnings: AutoTaskAlertInfoDTO[] = [];

  // frontend stuff
  messages: string[] = [];
  getModelId() { return this.AutoTaskId; }
  getErrorForStep(step: number) { return this.Errors.filter(x => x.Step === step); }
  getWarningForStep(step: number) { return this.Warnings.filter(x => x.Step === step); }
}

export class AutoTaskStepResultModel {
  @Def() Step: number;
  @Def() Type: string;
  @Def() Messages: string[];

  // frontend stuff
  type: AutoTaskStep;
}
export class AutoTaskRunResultModel {
  @Def() AutoTaskRunResultId: string;
  @Def() StepResults: AutoTaskStepResultModel[];
  @Def() CurrentStep: number;
  @Def() ExitSuccess: boolean;
  @Def() StartedAt: Date;
  @Def() CompletedAt: Date;
  @Def() ElapsedMs: number;

  public get elapsedTime() {
    return DateUtils.getSecMinHrFromMs(this.ElapsedMs);
  }
}

export class AutoTaskStepModel implements IHasModelId {
  @Def() AutoTaskStepId: string;
  @Def() Step: number;
  @Def() StepType: AutoTaskStepType;
  @Def() StepMetas: AutoTaskStepMetaModel[] = [];
  @Def() TargetForecastId: string;
  @Def() SendWhen: AutoTaskSendMailTriggerType;

  // frontend stöff
  public stepOpen = true;
  public stepType: AutoTaskStep;
  public forecastIds: string[] = [];
  public targetForecastVariables: VariableInfoDTO[] = [];
  public getModelId(): string { return this.AutoTaskStepId; }
}

export class AutoTaskStepMetaModel {
  @Def() AutoTaskStepMetaId: string;
  @Def() ForecastId: string = '-1';
  @Def() Email: string;

  // frontend stöff
  public meta: any;
}

export class AutoTaskInfoModel {
  @Def() StepTypes: AutoTaskStep[];
  @Def() TriggerOnTypes: AutoTaskTriggerOn[];
  @Def() TriggerTypes: AutoTaskTrigger[];
  @Def() OnEventTypes: AutoTaskTriggerOnEvent[];
  @Def() EmailTriggerOnTypes: AutoTaskSendEmailTrigger[];

  // frontend only
  hoursInADay: number[] = Array.from(Array(24).keys());
}
