import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NavigateToAddDataFromSheet } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { SheetService } from '@modules/forecast/views/add-data/file-sheet/sheet.service';
import { SheetColumn } from '@modules/forecast/views/add-data/file-sheet/types/types';
import { Store } from '@ngxs/store';

export type SheetColumnImportState = 'pending' | 'error' | 'no-imports' | 'importing' | 'done';

@Component({
  selector: 'indicio-sheet-column-import',
  templateUrl: './sheet-column-import.component.html'
})
export class SheetColumnImportComponent implements OnChanges {

  @Input() forecastVersionId: string;
  @Input() forecastId: string;
  @Input() valueColumns: SheetColumn[] = [];
  @Input() sheetLoading: boolean;
  @Input() fileId: string;
  @Input() fileType: string;

  @Output() stateEvent = new EventEmitter<SheetColumnImportState>();
  @Output() closeEvent = new EventEmitter<null>();

  valueColumnsToShow: SheetColumn[] = [];

  faSave = faSave as IconProp;
  selectedVisibilityLevel: string = 'private';
  addToForecast = true;
  toImport = [];
  importedCount = 0;
  pending = false;
  hideImported = true;
  showHideImported = false;
  mainVariableIndex: number = null;
  mainVariableExists: boolean;

  public get containsError() {
    return this.valueColumnsToShow.filter(x => x.nameError && this.toImport.includes(x.columnIndex)).length > 0;
  }

  constructor(
    protected store: Store,
    public sheetService: SheetService,
    public envService: EnvironmentService,
    private status: StatusService,
    private sourceVariableService: SourceVariableFrontendService,
    private forecastVariableService: ForecastVariableFrontendService,
    private clientService: ClientFrontendService,
    private forecastService: ForecastFrontendService
  ) {
    const visibility = this.clientService.activeCompany.SourceFromFileVisibility;
    if (visibility === this.envService.VariablesVisibilityCompany) {
      this.selectedVisibilityLevel = this.envService.VariablesVisibilityCompany;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valueColumns?.currentValue && changes.valueColumns.currentValue.length) {
      this.updateData();
    }
  }

  private updateData() {
    this.showHideImported = !this.valueColumns.every(x => !x.used);
    this.valueColumnsToShow = this.hideImported
      ? this.valueColumns.filter(x => !x.used)
      : this.valueColumns;

    this.toImport = this.valueColumnsToShow.filter(x => !x.used && x.IsValidValueColumn).map(x => x.columnIndex);
    this.importedCount = 0;
    this.mainVariableExists = !!this.forecastService.forecastVersionById(this.forecastVersionId)?.ForecastVariable;

    this.checkStates();
  }

  public selectMainVariable(column: SheetColumn) {
    this.valueColumnsToShow.forEach(e => {
      e.mainVariable = false;
    });
    column.mainVariable = true;
  }

  public toggleHideImported() {
    this.hideImported = !this.hideImported;
    this.updateData();
  }

  public toggleAllImport() {
    const anyImport = this.toImport.length;
    if (anyImport) {
      this.toImport = [];
    } else {
      this.toImport = this.valueColumnsToShow.filter(x => x.IsValidValueColumn).map(x => x.columnIndex);
    }
  }

  private checkStates() {
    this.valueColumnsToShow.forEach(col => {
      col.nameError = this.sheetService.checkNameConflict(col);
    });
    const columnWithoutError = this.valueColumnsToShow.find(x => !x.nameError && x.ColumnType === 'Values');
    if (columnWithoutError && !this.mainVariableExists) {
      this.selectMainVariable(columnWithoutError);
    }

    if (this.containsError) {
      return this.stateEvent.emit('error');
    } else if (this.toImport.length === 0) {
      return this.stateEvent.emit('no-imports');
    }
    this.stateEvent.emit('pending');
  }

  public toggleImport(column: number) {
    const idx = this.toImport.indexOf(column);
    if (idx > -1) {
      this.toImport.splice(idx, 1);
    } else {
      this.toImport.push(column);
    }
    this.checkStates();
  }

  public setVariableName($event, variable) {
    variable.name = $event.target.value;
    variable.nameError = this.sheetService.checkNameConflict(variable);
    this.checkStates();
  }

  public toggleAddToForecast() {
    this.addToForecast = !this.addToForecast;
  }

  public gotoSheet() {
    this.store.dispatch(new NavigateToAddDataFromSheet(this.forecastId, this.fileId, this.fileType, () => this.closeEvent.emit()));
  }

  public async import() {
    this.pending = true;
    this.stateEvent.emit('importing');
    const count = this.toImport.length;
    const importFunc = async (i) => {
      const colNum = this.toImport[i];
      const col = this.valueColumnsToShow.find(x => x.columnIndex === colNum);
      await this.importSourceVariable(col);
    };

    if (!this.mainVariableExists && this.valueColumnsToShow.findIndex(x => x.mainVariable) > -1) {
      const mainColumn = this.valueColumnsToShow.find(x => x.mainVariable);
      const mainVariableIndex = mainColumn.columnIndex;
      this.toImport = this.toImport.sort(a => a === mainVariableIndex ? -1 : 1);
    }

    let current = 0;
    const queueFunc = async () => {
      if (current >= count) {
        this.pending = false;
        this.stateEvent.emit('done');
        return;
      }
      await importFunc(current);
      current += 1;
      setTimeout(async () => {
        await queueFunc();
      }, 150);
    };

    await queueFunc();
  }

  private async importSourceVariable(col: SheetColumn) {
    col.status = 'Importing';
    const variable = await this.createSourceVariable(col);
    return this.sourceVariableService.createSourceVariableFromFile(variable)
      .then(async source => {
        if (this.addToForecast) {
          await this.importForecastVariable(col, source);
        } else {
          col.setImported();
        }
      })
      .catch(err => {
        col.status = 'Failed';
        col.failedMsg = `Import source variable failed.<br><br>Message: ${this.status.getMessage(err).message}`;
      })
      .finally(() => {
        this.importedCount += 1;
      });
  }

  private async importForecastVariable(col: SheetColumn, source: SourceVariableModel) {
    const version = await this.sourceVariableService.getOrFetchSourceVariableVersion(source.getBaseVersion().SourceVariableMetaId);
    const variable = new ForecastVariableModel();
    variable.SourceVariableMetaId = version.SourceVariableMetaId;
    variable.ForecastVersionId = this.forecastVersionId;
    variable.Name = source.Name;
    variable.AggregationMethodId = col.aggregationId;
    variable.FirstDate = version.PlotValues[0].D;
    variable.LastDate = version.PlotValues.last().D;
    return this.forecastVariableService.createForecastVariable(variable)
      .then(fvar => {
        col.setImported();
        if (fvar.ForecastVariableValues.length < 30) {
          col.status = 'Warning';
          col.warningMsg = 'Value count is low';
        }
      })
      .catch(err => {
        col.status = 'Failed';
        col.failedMsg = `The variable was successfully created, but it could not be added to the forecast.<br><br>Message: ${this.status.getMessage(err).message}`;
      });
  }

  private createSourceVariable(column: SheetColumn) {
    return this.sheetService.createSourceVariableFromFile(column)
      .then(variable => {
        variable.CompanyId = this.clientService.activeCompany.CompanyId;
        variable.Name = column.name;
        variable.AggregationMethodId = column.aggregationId;
        variable.VisibilityLevelId = this.selectedVisibilityLevel;
        return variable;
      });
  }
}

