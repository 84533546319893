<indicio-modal-base [child]="this"
                    modalClass=""
                    [loading]="isLoading"
                    [modalTitle]="nowcastMainToFullPeriod ? 'Create nowcast?' : 'Nowcasting'">

  <div class="modal-width"
       *ngIf="!isLoading">
    <ng-container *ngIf="nowcastVariables.length">
      <ng-container [ngTemplateOutlet]="mainTemplate">
      </ng-container>
    </ng-container>
    <ng-container *ngIf="variablesMissingOptions.length">
      <ng-container [ngTemplateOutlet]="missingOptionVarsTemplate">
      </ng-container>
    </ng-container>

    <ng-container [ngTemplateOutlet]="buttonRow">
    </ng-container>
  </div>
</indicio-modal-base>

<ng-template #mainTemplate>
  <div class="row">
    <div class="col-xs-12"
         style="font-weight: 500;">
      The following variables must be nowcasted since their last date occurs before the forecast start date.
    </div>
  </div>
  <div class="forecast-info margin-top">
    <div class="row">
      <div class="col-xs-6">First forecasted date:</div>
      <div class="col-xs-6 text-right">{{ forecastVersion.StartDate | date: appearance.DateFormat }}</div>
    </div>
    <div class="row">
      <div class="col-xs-6">Forecast periodicity:</div>
      <div class="col-xs-6 text-right">{{ forecastVersion.periodicity.Display }}</div>
    </div>
  </div>

  <div class="data-table data-table--no-max-width extra-margin-top">
    <div class="data-table__header">
      <div class="column header"
           style="max-width: 40px; min-width: 40px;">#</div>
      <div class="column header"
           style="width: 280px; text-align: left; padding-left: 5px;">Name</div>
      <div class="column header"
           style="width: 70px;"
           indicioTippy="If checked, the nowcast will only use a naive model">Naive only</div>
      <div class="column header"
           style="width: 100px;">Periodicity</div>
      <div class="column header"
           style="width: 60px;"
           indicioTippy="Amount of periods to be nowcasted">
        Amount
      </div>
      <div class="column header"
           style="width: 90px;">
        Status
      </div>
    </div>
  </div>

  <div class="data-table__body"
       *ngIf="nowcastVariables.length"
       style="margin-bottom: 60px;"
       [style.maxHeight.px]="301">
    <div class="body__row body__row--center-height body__row--max-height-30"
         [class]="{ 'not-importable': variable.hasTooManyForecastPoints }"
         *ngFor="let variable of nowcastVariables; let i = index">
      <div class="column"
           style="min-width: 40px;">{{ i + 1 }}</div>
      <div class="column crop-string"
           style="width: 280px;">
        <div class="row">
          <div class="text-left crop-string col-xs-10"
               style="width: 210px;"
               [indicioTippy]="variable.variable.Name">
            {{ variable.variable.Name }}
          </div>
          <div class="text-right col-xs-2 small-right-text">
            <span indicioTippy="Variable last date">
              {{ variable.variable.LastDate | dateFormat }}
            </span>
          </div>
        </div>
      </div>
      <div class="column"
           style="width: 70px;">
        <label class="input-label-mini checkbox cursor-pointer unselectable h-grey"
               (click)="variable.naiveOnly = !variable.naiveOnly">
          <i
             [ngClass]=" variable.naiveOnly ? 'ion-android-checkbox-outline' : 'ion-android-checkbox-outline-blank' "></i>
        </label>
      </div>
      <div class="column"
           style="width: 100px;"
           [indicioTippy]="variable.recommendedPeriodicityText()">
        <span *ngIf="variable.nowcastOptions.Periodicities.length < 2">
          {{ env.getPeriodicity(variable.getSelectedPeriodicity()).Display }}
        </span>

        <mat-form-field *ngIf="variable.nowcastOptions.Periodicities.length > 1">
          <mat-select [(value)]="variable.selectedPeriodicity"
                      (selectionChange)="setNowcastPeriodicity(variable, $event)"
                      panelClass="data-table-panel-opts"
                      required>
            <mat-option [value]="option"
                        *ngFor="let option of variable.nowcastOptions.Periodicities">
              {{ option.Periodicity }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="column"
           style="width: 60px;">{{ variable.getSelectedValuesCount() }}</div>
      <div class="column crop-string"
           style="width: 90px;">
        <span *ngIf="!variable.hasTooManyForecastPoints">
          <i class="ion-alert-circled orange"
             *ngIf="variable.forecastPoints > 10 && !variable.hasTooManyForecastPoints"
             indicioTippy="Warning: {{ variable.forecastPoints }} forecasting points. Consider creating a forecast that starts closer to when
        your
        data ends to achieve a model with reasonable accuracy.">
          </i>
          {{ variable.status }}
        </span>
        <span class="red"
              *ngIf="variable.hasTooManyForecastPoints">
          <i class="ion-alert-circled"
             indicioTippy="Too many forecast points, max is {{ maxHorizon(variable.selectedPeriodicity.Periodicity) }}. Please change forecast start date."></i>
        </span>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #missingOptionVarsTemplate>
  <div class="row"
       style="font-weight: 500;">
    <div class="col-xs-12">
      These variables cannot be nowcasted since they are missing a connection to a underlying source-variable.
      Please try to locate the corresponding variables in your source-variable library, and re-import the variables to
      the forecast.
    </div>
  </div>

  <div class="data-table data-table--no-max-width margin-top">
    <div class="data-table__header">
      <div class="column header"
           style="max-width: 40px; min-width: 40px;">#</div>
      <div class="column header"
           style="width: 510px; text-align: left; padding-left: 5px;">Name</div>
      <div class="column header"
           style="width: 90px;">
        Status
      </div>
    </div>
  </div>

  <div class="data-table__body"
       [style.maxHeight.px]="301">
    <div class="body__row body__row--center-height body__row--max-height-30"
         *ngFor="let variable of variablesMissingOptions; let i = index">
      <div class="column"
           style="min-width: 40px;">{{ i + 1 }}</div>
      <div class="column crop-string"
           style="width: 510px;">
        <div class="row">
          <div class="text-left crop-string col-xs-10"
               style="width: 430px;"
               [indicioTippy]="variable.Name">
            {{ variable.Name }}
          </div>
          <div class="text-right col-xs-2 small-right-text">
            <span indicioTippy="Variable last date">
              {{ variable.LastDate | dateFormat }}
            </span>
          </div>
        </div>
      </div>
      <div class="column crop-string"
           style="width: 90px;">
        <span class="red">
          <i class="ion-alert-circled"
             indicioTippy="Cannot create nowcast"></i>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #buttonRow>
  <div class="row">
    <div class="col-xs-6">
      <button class="btn outline blue left"
              *ngIf="hasCreatableNowcasts"
              type="button"
              (click)="close()">Cancel</button>
    </div>
    <div class="col-xs-6">
      <button class="btn right"
              *ngIf="hasCreatableNowcasts"
              [ngClass]="{'disabled': !nowcastVariables.length }"
              (click)="createNowcast()">
        Create Nowcasts
      </button>

      <button class="btn right"
              *ngIf="!hasCreatableNowcasts"
              (click)="close()">
        Close
      </button>
    </div>
  </div>
</ng-template>
