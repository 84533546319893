import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { BloombergService } from '@core/store/providers/bloomberg/bloomberg.service';
import { BloombergRequestsContentDTO } from '@core/store/providers/bloomberg/entities/bloomberg-requests.dtos';
import { DialogV2BaseDialog } from '../../../base/dialogs.V2.base-dialog';
import { BloombergRequestInfoDialogData } from './request-info.dialog.constants';

@Component({
  selector: 'indicio-bloomberg-request-info-dialog',
  templateUrl: 'request-info.dialog.html',
  styleUrls: ['request-info.dialog.less'],
})
export class BloombergRequestInfoDialogComponent extends DialogV2BaseDialog<BloombergRequestInfoDialogComponent> {

  public static Id: string = 'BloombergRequestInfoDialogComponent';

  requestDetails: BloombergRequestsContentDTO;
  requestLoading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: BloombergRequestInfoDialogData,
    public dialogRef: MatDialogRef<BloombergRequestInfoDialogComponent, boolean>,
    private service: BloombergService,
    private statusService: StatusService,
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.service.getRequest(this.data.catalogId, this.data.request.Id)
      .then(dto => this.requestDetails = dto)
      .catch(err => this.statusService.setError(err, true))
      .finally(() => this.requestLoading = false);
    this.initialized = true;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
