import { Injectable } from '@angular/core';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { HttpService } from '@core/services/http/http.service';
import { AuthMapper } from '@core/store/auth/auth.mapper';
import { AuthTokenDTO } from '@core/store/auth/dtos/auth-token-dto';
import { CreateProfileDTO } from '@core/store/client/dtos/create-profile-dto';
import { InviteDTO } from '@core/store/invites/dtos/invite-dto';
import { AuthToken } from './auth-token';
import { AuthenticateDTO, AuthorizeDTO } from './dtos/authorize.dto';

@Injectable({
  providedIn: 'root'
})
export class AuthBackendService {

  isRefreshing = false;

  constructor(
    private http: HttpService,
    private mapper: AuthMapper
  ) { }


  public startLogin(username: string, flowId: string, challenge: string) {
    return this.http.post<AuthorizeDTO>('authorize', { FlowId: flowId, Email: username, Challenge: challenge }, 'id')
      .then(({ body }) => body);
  }

  public authenticate(flowId: string, password: string) {
    return this.http.post<AuthenticateDTO>('authenticate', { FlowId: flowId, Password: password }, 'id')
      .then(({ body }) => body);
  }

  public getToken(flowId: string, username: string, code: string, pkce: string, systemRole: IndicioConstants.SystemRoleType) {
    const body = `grant_type=authentication-code&username=${encodeURIComponent(username)}` +
      `&authCode=${encodeURIComponent(code)}&pkce=${encodeURIComponent(pkce)}` +
      `&flowId=${encodeURIComponent(flowId)}&role=${encodeURIComponent(systemRole)}`;

    return this.http.post<AuthTokenDTO>('token', body, 'id')
      .then(res => {
        const token = this.mapper.mapToken(res.body);
        return token;
      });
  }

  public setup2FA(body) {
    return this.http.post<any>('2fa/setup', body, 'id');
  }

  public verify2FA(body) {
    return this.http.post<{ RememberToken: string; }>('2fa/verify', body, 'id');
  }

  public disable2FA() {
    return this.http.post('2fa/disable', null, 'id');
  }

  public getMySessions() {
    return this.http.get<any>('sessions/', 'id');
  }

  public deleteAllSession() {
    return this.http.delete('sessions/', 'id');
  }

  public deleteSession(sessionId: string) {
    return this.http.delete(`sessions/${sessionId}`, 'id');
  }

  public verifyPassword(username: string, password: string) {
    return this.http.post<null>('my/user/verifypassword', { Username: username, Password: password }, 'id');
  }

  public refreshToken(token: AuthToken) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      const body = `grant_type=refresh_token&client_secret=${token.refresh_token_secret}&refresh_token=${token.refresh_token}`;
      return this.http.post<AuthTokenDTO>('token', body, 'id')
        .then(newToken => {
          const t = this.mapper.mapToken(newToken.body);
          this.isRefreshing = false;
          return t;
        })
        .catch(noNewToken => {
          this.isRefreshing = false;
          throw noNewToken;
        });
    }
    return Promise.reject('No token or refresh in progress');
  }

  public checkResetPasswordCode(code: string) {
    return this.http.post<null>('my/user/resetpassword/check', { Code: code }, 'id');
  }

  public forgotPassword(Email) {
    return this.http.post<null>('my/user/forgotpassword', { Email }, 'id');
  }

  public resetPassword(code: string, newPassword: string): any {
    const body = { Code: code, NewPassword: newPassword };
    return this.http.post('my/user/resetpassword', body, 'id');
  }

  public changePassword(oldPassword: string, newPassword: string) {
    const body = { CurrentPassword: oldPassword, NewPassword: newPassword };
    return this.http.post<null>('my/user/password', body, 'id');
  }

  public checkInviteCode(code: string) {
    return this.http.get<InviteDTO>(`invite/check/${code}`);
  }

  public signup(profile: CreateProfileDTO) {
    return this.http.post('invite/use', profile);
  }
}
