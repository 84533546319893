<indicio-modal-base [child]="this"
                    modalTitle="Import stock data"
                    [loading]="isLoading"
                    modalExtraClass="small"
                    extraClasses="small">

  <div class="row extra-margin-top bold">
    <div class="col-xs-12">Search stock</div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <mat-form-field>
        <input #fromCurrency="ngModel"
               [(ngModel)]="stockQuery"
               (ngModelChange)="debounce(stockQuery)"
               type="text"
               class="material"
               matInput
               [matAutocomplete]="search">
        <div mat-button
             *ngIf="stockQuery"
             matSuffix
             mat-icon-button
             aria-label="Clear"
             (click)="resetSearch()">
          <mat-icon>
            <i class="ion-close"
               *ngIf="!searchLoading"></i>
            <i class="ion-load-c spinner"
               *ngIf="searchLoading"></i>
          </mat-icon>
        </div>

        <mat-autocomplete #search="matAutocomplete">
          <mat-option *ngFor="let option of searchResult"
                      (onSelectionChange)="setSelectedStock($event)"
                      title="{{ option['1. symbol'] }} - {{option['2. name'] }} ({{ option['4. region']}}, {{ option['8. currency']}})"
                      [value]="option['1. symbol']">{{ option['1. symbol'] }} - {{option['2. name'] }}
            ({{ option['4. region']}}, {{ option['8. currency']}})</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>

  <form ngNativeValidate
        (submit)="importData()"
        *ngIf="selectedStock">
    <div class="row">
      <div class="col-xs-12 bold extra-margin-top">Variable settings</div>
    </div>
    <div class="row">
      <div class="col-xs-12 input-label">
        <mat-form-field>
          <input [(ngModel)]="variable.Name"
                 (ngModelChange)="checkName()"
                 type="text"
                 class="material"
                 required
                 [ngModelOptions]="{standalone: true}"
                 placeholder="Enter name"
                 matInput>
          <mat-hint *ngIf="nameConflict">
            <span class="error-text">A source variable with that name already exists.</span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 input-label">
        <mat-form-field>
          <mat-select [(value)]="variable.ValueType"
                      required
                      placeholder="Select value type">
            <mat-option [value]="option"
                        *ngFor="let option of valueTypes">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 input-label">
        <mat-form-field>
          <mat-select [(value)]="periodicity"
                      required
                      placeholder="Select value type">
            <mat-option [value]="option.value"
                        *ngFor="let option of periodicities">{{ option.display }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-6">
        <button (click)="back()"
                type="button"
                class="btn outline blue left">Back</button>
      </div>
      <div class="col-xs-6">
        <button type="submit"
                [class]="{ disabled: nameConflict }"
                class="btn pull-right">
          <i class="ion-load-c spinner"
             *ngIf="pending"></i>
          <span *ngIf="!pending">Import</span>
        </button>
      </div>
    </div>
  </form>

  <div class="row"
       *ngIf="!selectedStock">
    <div class="col-xs-12">
      <button (click)="back()"
              type="button"
              class="btn outline blue left">Back</button>
    </div>
  </div>
</indicio-modal-base>
