<ng-container *ngIf="!loadingContent; else loader">
  <ng-container *ngIf="treeNodes">
    <ng-container *ngTemplateOutlet="nodeListTemplate; context: { nodes: treeNodes, level: 1 }"></ng-container>
  </ng-container>
</ng-container>

<ng-template #nodeListTemplate
             let-nodes=nodes
             let-level=level>
  <ng-container *ngFor="let node of nodes; let i = index">
    <ng-container *ngTemplateOutlet="nodeTemplate; context: { node: node, level: level }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #nodeTemplate
             let-node=node
             let-level=level>
  <div class="node center-vertical"
       style="height: 30px;"
       [class]="{ active: node.isActive }"
       *ngIf="shouldBeVisible(node)"
       [style.padding-left.px]="(level) * 9">
    <div style="display:inline-block;">
      <div class="cursor-pointer icon"
           style="margin-right:11px;"
           (click)="clickedToggleFold(node)"
           *ngIf="node.children?.length">
        <i [class]="node.isOpen ? 'ion-chevron-down' : 'ion-chevron-right'"></i>
      </div>
      <div class="cursor-pointer icon"
           style="margin-right:5px;"
           (click)="clickedNode(node)"
           *ngIf="!node.children?.length">
        <i class="ion-arrow-graph-up-right"></i>
      </div>
    </div>
    <div [title]="node.name"
         class="cursor-pointer crop-string"
         (click)="clickedNode(node)"
         style="display:inline-block;">
      <span>{{node.name}}</span>
    </div>
  </div>
  <div *ngIf="node.isOpen">
    <ng-container *ngTemplateOutlet="nodeListTemplate; context: { nodes: node.children, level: level + 1 }">
    </ng-container>
  </div>
</ng-template>

<ng-template #loader>
  <div class="row full-size">
    <div class="col-xs-12 tab-loader">
      <i class="ion-load-c spinner"></i>
    </div>
  </div>
</ng-template>
