import { Component, ViewEncapsulation } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePickerBaseComponent, StartViewType } from '../base/date-picker-base.component';

const MY_FORMATS = {
  parse: {
    dateInput: 'yyyy',
  },
  display: {
    dateInput: 'yyyy',
    monthYearLabel: 'yyyy',
    dateA11yLabel: 'yyyy',
    monthYearA11yLabel: 'yyyy',
  },
};
@Component({
  selector: 'indicio-date-picker-year',
  templateUrl: '../base/date-picker-base.component.html',
  styleUrls: ['../date-picker.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class DatePickerYearComponent extends DatePickerBaseComponent {
  public panelClass: string = 'date-picker-base year-date-picker';
  public startView: StartViewType = 'multi-year';
  public yearSelected: Function = this.closeDatePicker;
}
