import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

class Config {
  UriBackendApi: string;
  UriIdentityApi: string;
  UriImportApi: string;
  UriWikiApi: string;
  CorsProxy: string;
  UriFrontendUtilsApi: string;
  PusherServerAppKey: string;
  PusherServerCluster: string;
  Environment: string;
  PackageVersion: string;
  UseDrift: boolean;
}

export interface IConfig extends Config {
  load: () => Promise<void>;
}

@Injectable()
export class AppConfig implements IConfig {
  public UriBackendApi: string;
  public UriIdentityApi: string;
  public UriImportApi: string;
  public UriWikiApi: string;
  public CorsProxy: string;
  public UriFrontendUtilsApi: string;
  public PusherServerAppKey: string;
  public PusherServerCluster: string;
  public Environment: string;
  public PackageVersion: string;
  public UseDrift: boolean;

  constructor(private readonly http: HttpClient) { }

  public load(): Promise<void> {
    return this.http
      .get<Config>('assets/env.json')
      .toPromise()
      .then(config => this.verifyEnvFile(config))
      .then(config => { Object.assign(this, config); });
  }

  private verifyEnvFile(env: Config) {
    const errors = [];
    if (!env.hasOwnProperty('UriBackendApi')) {
      errors.push('UriBackendApi missing in env.json');
      console.error('UriBackendApi missing in env.json');
    }
    if (!env.hasOwnProperty('UriIdentityApi')) {
      errors.push('UriIdentityApi missing in env.json');
      console.error('UriIdentityApi missing in env.json');
    }
    if (!env.hasOwnProperty('CorsProxy')) {
      errors.push('CorsProxy missing in env.json');
      console.error('CorsProxy missing in env.json');
    }
    if (!env.hasOwnProperty('PusherServerAppKey')) {
      errors.push('PusherServerAppKey missing in env.json');
      console.error('PusherServerAppKey missing in env.json');
    }
    if (!env.hasOwnProperty('Environment')) {
      errors.push('Environment missing in env.json');
      console.error('Environment missing in env.json. Set to LocalDebug');
    }
    if (!env.hasOwnProperty('PusherServerCluster')) {
      errors.push('PusherServerCluster missing in env.json');
      console.error('PusherServerCluster missing in env.json');
    }
    if (!env.hasOwnProperty('PackageVersion')) {
      errors.push('PackageVersion missing in env.json');
      console.error('PackageVersion missing in env.json');
    }
    if (!env.hasOwnProperty('UseDrift')) {
      errors.push('UseDrift missing in env.json');
      console.error('UseDrift missing in env.json');
    }
    if (errors.length > 0) {
      throw new Error(errors.join('\n'));
    }
    return env;
  }
}

export function initConfig(config: AppConfig): () => Promise<void> {
  return () => config.load();
}
