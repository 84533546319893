import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { BloombergService } from '@core/store/providers/bloomberg/bloomberg.service';
import { FigiResultDTO } from '@core/store/providers/bloomberg/entities/bloomberg-requests.dtos';
import { DialogV2BaseDialog } from '@modules/dialog/dialogs/base/dialogs.V2.base-dialog';
import { DisplayValue } from '@modules/lang/types/display-value';

export class BloombergManualTickerInputDialogData { }


@Component({
  selector: 'indicio-bloomberg-add-import-dialog',
  templateUrl: 'manual-ticker.dialog.html',
  styleUrls: ['manual-ticker.dialog.less']
})
export class BloombergManualTickerInputDialogComponent extends DialogV2BaseDialog<BloombergManualTickerInputDialogComponent> {

  public static Id: string = 'BloombergManualTickerInputDialogComponent';

  public figiInfo = new FigiResultDTO;
  public selectedExchange: DisplayValue<string>;

  // Dropdown options below
  public exchanceCodes: DisplayValue<string>[] = [];
  public markets: string[] = [];

  public get canSave() {
    return this.figiInfo.Ticker?.length > 0 &&
      this.figiInfo.MarketSector?.length > 0 &&
      this.figiInfo.ExchangeCode?.length > 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BloombergManualTickerInputDialogData,
    public dialogRef: MatDialogRef<BloombergManualTickerInputDialogComponent, FigiResultDTO>,
    private service: BloombergService,
    private status: StatusService) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    const exchPromise = this.service.getExchangeCodes();
    const markPromise = this.service.getMarketSectors();
    Promise.all([exchPromise, markPromise])
      .then(([ex, mark]) => {
        this.exchanceCodes = ex;
        this.markets = mark;
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => this.initialized = true);
  }

  public setSelectedExchange(info: DisplayValue<string>) {
    this.selectedExchange = info;
    this.figiInfo.ExchangeCode = info.Value;
  }

  public save() {
    this.dialogRef.close(this.figiInfo);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }
}
