<ng-container *ngIf="article">
  <div class="news-post"
       data-test-id="news-post"
       [ngClass]="{ 'future': !article.shouldDisplay }">
    <div class="news-post__title">
      <a class="cursor-pointer"
         (click)="readMoreDialog(article);">{{ article.Meta.Title }}</a>
    </div>
    <div class="news-post__date">
      {{ article.Meta.CreatedDate | date: appearance.FullFormatNoSeconds }}
      <!-- <ng-container *ngIf="!article.Meta.DisableComments">
        &bull; <a [routerLink]="articleRoute">{{ article.Meta.CommentCount }} comments</a>
      </ng-container> -->
      <ng-container *ngIf="userCanUpdateNews">
        &bull;
        <span (click)="editPost(article)"
              class="cursor-pointer">
          edit
        </span>
      </ng-container>
    </div>
    <div class="news-post__main-text">
      <markdown ngPreserveWhitespaces>
        {{ article.Meta.Preview || article.Meta.Body }}
      </markdown>
    </div>
    <div class="news-post__read-more"
         *ngIf="article.Meta.Preview">
      <a class="cursor-pointer"
         (click)="readMoreDialog(article);">Read more &raquo;</a>
    </div>
  </div>
</ng-container>
