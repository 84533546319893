import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { DialogActions } from '@shared/modules/dialogs/dialog.actions';
import { WikiPageDialogComponent } from '@shared/modules/dialogs/wiki-page/wiki-page.dialog';
import { WikiActions } from '../../wiki.actions';
import { WikiService } from '../../wiki.service';

@Component({
  selector: 'indicio-wiki-link',
  templateUrl: './wiki-link.component.html',
  styleUrls: ['./wiki-link.component.less'],
})
export class WikiLinkComponent implements OnChanges {

  @Input() public href: string;
  @Input() public title: string = null;
  @Input() public text: string;

  public _title: string = null;

  constructor(private wikiService: WikiService, private store: Store) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.title === 'undefined' || this.text === 'undefined') {
      this._title = '';
    } else if (this.title != null || this.text != null) {
      this._title = this.title || this.text;
    }
  }

  public handleClick() {
    switch (true) {
      case this.href.startsWith('indicio-wiki://'):
        const articleId = this.href.replace('indicio-wiki://', '');
        this.store.dispatch(new DialogActions.CloseById(WikiPageDialogComponent.Id));
        this.store.dispatch(new WikiActions.PageClosed(this.wikiService.lastOpenedSlug));
        this.wikiService.open(articleId);
        break;

      case this.href.startsWith('http://') || this.href.startsWith('https://'):
        window.open(this.href, '_blank');
      case this.href.includes('@'):
        window.open(`mailto:${this.href}`, '_blank');
        break;
      default:
        console.error(`Unknown link type: ${this.href}`);
    }
  }
}
