<indicio-dialog>
  <h1 mat-dialog-title>Create forecast</h1>
  <div mat-dialog-content>
    <ng-container [ngTemplateOutlet]="createTemplate"
                  *ngIf="initialized; else loadingTemplate">

    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Close</button>
    <div [indicioTippy]="disabledText || null">
      <button mat-flat-button
              color="primary"
              [disabled]="nameConflict || pending || !project || !project.Name"
              (click)="save()"
              cdkFocusInitial>
        <indicio-spinner *ngIf="pending"></indicio-spinner>
        Create
      </button>
    </div>
  </div>
</indicio-dialog>

<ng-template #createTemplate>
  <div class="orange margin-bottom-10 italic"
       *ngIf="unsaved">
    Unsaved version
  </div>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Project name"
                            optionSubtitle="Enter the name of the project"
                            textWidth=7
                            optionsWidth=5>

    <mat-form-field>
      <input matInput
             autocomplete="off"
             [ngModel]="project.Name"
             (ngModelChange)="setName($event)"
             name="Name"
             indicioAutoFocusInput
             maxlength="128"
             required>
      <mat-hint *ngIf="nameConflict">
        <span class="error-text">A project with that name already exists.</span>
      </mat-hint>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Select a visibility"
                            optionSubtitle="Should this be a private or company wide project?"
                            textWidth=7
                            optionsWidth=5>

    <mat-form-field>
      <mat-label></mat-label>
      <mat-select [(value)]="project.VisibilityLevelId"
                  (selectionChange)="project.unsaved = true">
        <mat-option [value]="level.Value"
                    *ngFor="let level of envService.VisibilityLevels">{{ level.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #loadingTemplate>
  <indicio-spinner></indicio-spinner> Loading...
</ng-template>
