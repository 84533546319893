import { CalendarEffects } from '@dialogs/variables/forecast-variable/info/tabs/seasonal/fvar-calendar-settings';
import { SeasonalModelType, SeasonalOutlierType, SeasonalStrategyType } from '@modules/lang/language-files/seasonal';
import { VariableSeasonalSettingsDTO } from '../dtos/variable-seasonal-settings-dto';
import { ForecastVariableModel } from './forecast-variable-model';

export class SeasonalSettings {

  // Seasonal settings
  public seasonalOutlierTypes: SeasonalOutlierType[] = [];
  public seasonalModelType: SeasonalModelType;
  public seasonalStrategyType: SeasonalStrategyType;
  public seasonalExtractTrend: boolean;
  public seasonalOutlier: boolean;
  public seasonalEnabled: boolean;

  // Calendar effects
  public selectedSubItems: CalendarEffects.ListSubItem[] = [];
  public selectedItems: CalendarEffects.ListItem[] = [];
  public calendarTypes: CalendarEffects.CalendarType[];

  // List items
  public calendarItems: CalendarEffects.ListItem[];

  // Flags
  public settingsChanged: boolean;
  public calendarEffectsChanged: boolean;

  // Entities
  private variable: ForecastVariableModel;

  public get changed() { return this.settingsChanged || this.calendarEffectsChanged; }
  public get selectedHolidays() { return this.getHolidays(this.selectedSubItems); }
  public get selectedWorkingday() { return this.getRegion(this.selectedSubItems); }
  public get selectedHolidaysItem() { return this.getHolidays(this.selectedItems); }
  public get selectedWorkingdayItem() { return this.getRegion(this.selectedItems); }
  public getHolidays(items: any[]) { return items.filter(e => !e.isRegion); }
  public getRegion(items: any[]) { return items.filter(e => e.isRegion); }

  public init(variable: ForecastVariableModel) {
    this.variable = variable;
    this.selectedItems = [];
    this.selectedSubItems = [];
    this.calendarItems = [];
    this.seasonalEnabled = variable.CalculateSeasonal;
    this.seasonalModelType = variable.SeasonalModel;
    this.seasonalStrategyType = variable.SeasonalStrategy;
    this.seasonalExtractTrend = variable.UseSeasonalTrend;
    this.seasonalOutlier = variable.SeasonalOutliers;
    this.seasonalOutlierTypes = [...variable.SeasonalOutlierTypes];
    this.seasonalOutlierTypes.sort();
    this.calendarTypes = variable.SeasonalCalendarEffects.slice();
  }

  public getSettingsDto() {
    return <VariableSeasonalSettingsDTO> {
      Active: this.seasonalEnabled,
      SeasonalModel: this.seasonalModelType,
      SeasonalStrategy: this.seasonalStrategyType,
      UseSeasonalTrend: this.seasonalExtractTrend,
      SeasonalOutliers: this.seasonalOutlier,
      SeasonalOutlierTypes: JSON.stringify(this.seasonalOutlierTypes),
      SeasonalCalendarEffects: JSON.stringify(this.calendarTypes),
      SeasonalCalendarHolidays: this.selectedHolidays.map(e => e.item.Value),
      SeasonalCalendarRegion: this.selectedWorkingday.map(e => e.item.Value)
    };
  }

  /**
 * This function sets the flag settingsChanged to true if any of the seasonal settings have changed compared to the saved
 * configuration for this variable.
 */
  public checkSettingsChanged() {
    this.settingsChanged = this.seasonalModelType !== this.variable.SeasonalModel ||
      this.seasonalStrategyType !== this.variable.SeasonalStrategy ||
      this.seasonalExtractTrend !== this.variable.UseSeasonalTrend ||
      this.seasonalOutlier !== this.variable.SeasonalOutliers ||
      this.seasonalEnabled !== this.variable.CalculateSeasonal ||
      JSON.stringify(this.seasonalOutlierTypes) !== JSON.stringify(this.variable.SeasonalOutlierTypes);
  }

  /**
   * This function sets the flag calendarEffectsChanged to true if any of the selected calendar effects have changed compared to the saved
   * configuration for this variable.
   */
  public checkCalendarEffectsChanged() {
    this.calendarEffectsChanged =
      JSON.stringify(this.calendarTypes.sort()) !== JSON.stringify(this.variable.SeasonalCalendarEffects.sort()) ||
      JSON.stringify(this.selectedHolidays.map(e => e.item.Value).sort()) !== JSON.stringify(this.variable.SeasonalCalendarHolidays.sort()) ||
      JSON.stringify(this.selectedWorkingday.map(e => e.item.Value).sort()) !== JSON.stringify(this.variable.SeasonalCalendarRegion.sort());
  }
}
