<indicio-modal-base [child]="this"
                    modalTitle="Sync remote data"
                    modalClass=""
                    [loading]=isLoading
                    bodyExtraClass="overflow-auto">
  <div style="width: 85%; margin: 0 auto;"
       *ngIf=!isLoading>
    <ng-container *ngTemplateOutlet="variableList"></ng-container>
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>
</indicio-modal-base>

<ng-template #variableList>
  <div class="row">
    <div class="col-xs-12 bold text-center">Variables to update</div>
  </div>
  <div class="row variable-row vertical-align">
    <div class="col-xs-12">
      <div class="data-table">
        <div class="data-table__header">
          <div class="column header">#</div>
          <div class="column header"
               style="width: 400px; text-align: left; padding-left: 5px;">Name</div>
          <div class="column header"
               style="width: 70px;">Update</div>
          <div class="column header"
               style="width: 100px;">Status</div>
        </div>

        <div class="data-table__body big"
             style="max-height: 350px;">
          <div class="body__row body__row--center-height body__row--max-height-30"
               *ngFor="let variable of variables; let i = index">
            <div class="column">{{ i }}</div>
            <div class="column"
                 style="width: 400px; text-align: left;">
              <div class="row">
                <div class="col-xs-12">
                  {{ variable.Name }}
                </div>
              </div>
            </div>
            <div class="column"
                 style="width: 70px;">
              <mat-slide-toggle color="accent"
                                (change)="toggleUpdate(variable)"
                                [checked]="variable.shouldUpdate">
              </mat-slide-toggle>
            </div>
            <div class="column"
                 style="width: 100px;">
              <ng-container *ngIf="variable.shouldUpdate; else skipped">
                <ng-container *ngIf="variable.updateStatus === 'failed'; else status">
                  <span class="red"
                        [indicioTippy]="variable.updateError">Failed</span>
                </ng-container>
                <ng-template #status>
                  {{ variable.updateStatus }}
                </ng-template>
              </ng-container>
              <ng-template #skipped>
                Skipped
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="row extra-margin-top">
    <div class="col-xs-9 italic"></div>
    <div class="col-xs-3 text-right">
      <ng-container *ngIf="pending || updatedCount !== 0">
        Processed: {{updatedCount }}/{{ toUpdateCount }}
      </ng-container>
      <ng-container *ngIf="!pending && updatedCount === 0">
        Selected: {{ toUpdateCount }}/{{ variables.length }}
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <button class="btn outline blue"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6">
      <button class="btn pull-right"
              (click)="processDone ? close() : update()"
              [ngClass]="{ disabled: toUpdateCount === 0 }">
        <span *ngIf="!pending">{{ processDone ? 'Done' : 'Update'}}</span>
        <i class="ion-load-c spinner"
           *ngIf="pending"></i>
      </button>
    </div>
  </div>
</ng-template>
