import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { NewOrUpdatedFilesModalOpts } from './new-or-updated-files.options';

export class OpenNewOrUpdatedFilesModal {
  public static readonly type = '[MODAL] Open new or updated files modal';
  data: NewOrUpdatedFilesModalOpts;
  constructor(
    forecastId: string,
    forecastVersionId: string,
    files: UploadedFileModel[],
    skipSheetInit: boolean = false,
  ) { this.data = { files, forecastId, forecastVersionId, skipSheetInit }; }
}
