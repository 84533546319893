<indicio-modal-base [child]="this"
                    [modalTitle]="isChanged || !isNew ? 'Edit event group' : 'Import event group'"
                    [loading]=isLoading
                    bodyExtraClass="overflow-auto">

  <div *ngIf="!isLoading">
    <ng-container *ngIf="isEditing">
      <div class="event-settings-container">
        <ng-container *ngTemplateOutlet="baseEventTemplate"></ng-container>
      </div>

      <div class="events-container margin-top">
        <ng-container *ngFor="let subEvent of baseEvent.Events;
                              index as i;">
          <ng-container
                        *ngTemplateOutlet="subEventTemplate; context: { subEvent, i, isUsedAsCorrelationInNumEvents: isUsedAsCorrelationInNumEvents(subEvent) }"></ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="baseEvent.Events.length === 0">
        <button class="btn w130"
                type="button"
                (click)="addEvent()">Add event</button>
      </ng-container>
      <ng-container *ngIf="baseEvent.Events.length > 0">
        <button class="btn blue outline w130"
                type="button"
                (click)="addEvent()">Add another event</button>
      </ng-container>
    </ng-container>

    <div class="row margin-top">
      <div class="col-xs-5">
        <button class="btn outline blue left w130"
                type="button"
                (click)="cancelEdit();">{{ onBack ? 'Back' : isChanged ? 'Cancel' : 'Close' }}</button>
      </div>
      <div class="col-xs-7"
           [title]="disableText || ''">
        <ng-container *ngIf="isChanged && !isNew; else importButton">
          <button class="btn right w130"
                  type="button"
                  [disabled]="disableNext"
                  (click)="update()">
            <span *ngIf="pending"
                  class="ion-load-c spinner"></span>
            <span *ngIf="!pending">Save</span>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</indicio-modal-base>

<ng-template #titleField
             let-subEvent="subEvent">
  <mat-form-field>
    <input placeholder="Event title"
           matInput
           class="material"
           type="text"
           [(ngModel)]="subEvent.Name"
           name="title"
           required>
  </mat-form-field>
</ng-template>

<ng-template #importButton>
  <ng-container *ngIf="!alreadyImported">
    <button class="btn right green"
            [ngClass]="disableImport ? 'disabled' : ''"
            type="button"
            (click)="import()">
      <span>
        <i *ngIf="pendingImport"
           class="ion-load-c spinner"></i>
        Import</span>
    </button>
  </ng-container>
</ng-template>

<ng-template #baseEventTemplate>
  <indicio-foldable-row #row
                        [manualToggle]="true"
                        [open]="baseEvent.open">
    <div class="row"
         (click)="baseEvent.open = !baseEvent.open; closeAllSubEvents()"
         style="width: 100%;"
         foldable-row-title>
      Event group settings
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 relative">
        <mat-form-field>
          <input placeholder="Event title"
                 matInput
                 class="material"
                 type="text"
                 [(ngModel)]="baseEvent.Name"
                 name="title"
                 data-test-id="event-title"
                 required>
        </mat-form-field>
      </div>
    </div>
    <div class="row margin-top">
      <div class="col-sm-6 col-xs-12 relative">
        <div class="margin-top">
          <mat-form-field>
            <mat-select [(value)]="baseEvent.Location"
                        required
                        data-test-id="location-of-event-dropdown"
                        placeholder="Location of event">
              <mat-option [value]="option.Value"
                          [title]="option.Description"
                          *ngFor="let option of envService.HistoricEventLocations">{{ option.Display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row margin-top">
      <div class="col-sm-6 col-xs-12 relative">
        <div class="margin-top">
          <mat-form-field>
            <mat-select [(value)]="baseEvent.Type"
                        required
                        data-test-id="type-of-event-dropdown"
                        placeholder="Type of event">
              <mat-option [value]="option.Value"
                          [title]="option.Description"
                          *ngFor="let option of envService.HistoricEventTypes">{{ option.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </indicio-foldable-row>
</ng-template>


<ng-template #subEventTemplate
             let-subEvent="subEvent"
             let-isUsedAsCorrelationInNumEvents="isUsedAsCorrelationInNumEvents"
             let-i="i">
  <indicio-foldable-row #row
                        [open]="subEvent.open"
                        [manualToggle]="true">
    <div class="row"
         (click)="toggleEventDisplay(subEvent)"
         style="width: 100%;"
         foldable-row-title>
      <span *ngIf="subEvent.Name">{{ subEvent.Name }}</span>
      <span *ngIf="!subEvent.Name">Event {{ i + 1 }}</span>
      <span *ngIf="subEvent.Date"
            class="pull-right">{{ subEvent.Date | dateFormat: appearance.DateFormat }}</span>
    </div>

    <div class="row">
      <div [ngClass]="eventRequiresCorrelation(subEvent) ? 'col-xs-6' : 'col-xs-12'">
        <ng-container *ngTemplateOutlet="titleField; context { subEvent: subEvent }">
        </ng-container>
      </div>
      <div *ngIf="eventRequiresCorrelation(subEvent)"
           class="col-xs-6">
        <div *ngIf="eventMissingCorrelation(subEvent)"
             class="center-text"
             [title]="getPastEventsForEvent(subEvent).length ? 'Set similarity schema' : 'There are no past events to describe this event'">
          <button class="btn no-margin"
                  style="width: 185px;"
                  type="button"
                  [disabled]="!getPastEventsForEvent(subEvent).length"
                  (click)="createCorrelation(subEvent)">Set similarity schema</button>
        </div>
        <div class="center-text"
             *ngIf="!eventMissingCorrelation(subEvent)">
          <button class="btn no-margin"
                  type="button"
                  [disabled]="!getPastEventsForEvent(subEvent).length"
                  (click)="viewCorrelations(subEvent)">View similarity schemas</button>
        </div>
      </div>
    </div>
    <div class="row extra-margin-top">
      <div [ngClass]="subEvent.EffectType === 'level-shift' ? 'col-xs-6' : 'col-xs-12'">
        <div
             [indicioTippy]="isUsedAsCorrelationInNumEvents > 0 ? 'This event is used to describe ' + isUsedAsCorrelationInNumEvents +' future events and cant have its date changed.' : null">
          <indicio-date-picker periodicity="day"
                               [value]="subEvent.Date"
                               [disabled]="isUsedAsCorrelationInNumEvents > 0"
                               (selectDateEvent)="setEventDate($event, subEvent, 'start')"
                               placeholder="Start date">
          </indicio-date-picker>
        </div>
      </div>
      <div class="col-xs-6"
           *ngIf="subEvent.EffectType === 'level-shift'">
        <div
             [indicioTippy]="isUsedAsCorrelationInNumEvents > 0 ? 'This event is used to describe ' + isUsedAsCorrelationInNumEvents +' future events and cant have its date changed.' : null">
          <indicio-date-picker periodicity="day"
                               [value]="subEvent.EndDate"
                               [disabled]="isUsedAsCorrelationInNumEvents > 0"
                               [min]="subEvent.Date"
                               (selectDateEvent)="setEventDate($event, subEvent, 'end')"
                               placeholder="End date (optional)">
          </indicio-date-picker>
        </div>
      </div>
    </div>
    <div class="row extra-margin-top">
      <div class="col-xs-12 effect-type--title">
        Effect type *
      </div>
      <div class="col-xs-12 relative">
        <div class="row margin-top">
          <div class="col-xs-12">
            <div class="row"
                 [indicioTippy]="isUsedAsCorrelationInNumEvents > 0 ? 'This event is used to describe ' + isUsedAsCorrelationInNumEvents +' future events and cant have its effect type changed.' : null">
              <div class="col-xs-3 effect-type cursor-pointer"
                   *ngFor="let effect of envService.HistoricEventEffectTypes"
                   [indicioTippy]="isUsedAsCorrelationInNumEvents === 0 ? effect.Description : null"
                   [ngClass]="{ 'effect-type--selected' : subEvent.EffectType === effect.Value, disabled: isUsedAsCorrelationInNumEvents > 0 }"
                   [attr.data-test-id]="'historical-event-image-' + effect.Value"
                   (click)="selectEffectType(subEvent, effect.Value)">
                <img src="/assets/images/event_type_-_{{effect.Value}}.svg"
                     alt=""
                     srcset="">
                <span>{{ effect.Display }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn--text"
            type="button"
            [indicioTippy]="isUsedAsCorrelationInNumEvents > 0 ? 'This event is used to describe ' + isUsedAsCorrelationInNumEvents +' future events and cant be removed.' : null"
            [class]="{ disabled: isUsedAsCorrelationInNumEvents > 0 }"
            (click)="removeEvent(subEvent)">Remove event</button>
  </indicio-foldable-row>
</ng-template>
