import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AlphaVantageModalBackAction,
  NavigateReload,
  NavigateToAddData,
  NavigateToAddDataFromSheet,
  NavigateToAdmin,
  NavigateToAutomation,
  NavigateToDataProvider,
  NavigateToForecast,
  NavigateToHome,
  NavigateToLogin,
  NavigateToReport,
  NavigateToSignup
} from '@core/actions/navigation.actions';
import { INavigationAction } from '@core/interfaces/if-navigation.action';
import { Store } from '@ngxs/store';
import { OpenAlphaMainModal } from '@shared/modals/providers/alphavantage/main/alpha-main-modal.actions';
import { ActionService } from '../actions/actions.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private returnRe = /.+returnUrl.+/i;

  constructor(
    private actions: ActionService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store
  ) {
    this.setupActionListeners();
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public reload() {
    const returnUrl = this.getUrl();
    this.router.navigateByUrl(returnUrl);
  }

  public getUrl(): string { return this.router.url; }
  public getPreviousUrl() { return this.previousUrl; }
  public previousUrlIsReload() { return this.returnRe.test(this.previousUrl); }
  public navBack() { this.location.back(); }
  public navToHome() { this.store.dispatch(new NavigateToHome); }

  public isOnForecast(forecastId: string) {
    const re = new RegExp(`forecast/${forecastId}`);
    const url = this.getUrl();
    return re.test(url);
  }

  public isOnReport(reportId: string) {
    const re = new RegExp(`reports/${reportId}`);
    const url = this.getUrl();
    return re.test(url);
  }

  public isOnAddData() {
    const re = new RegExp('/add-data');
    const url = this.getUrl();
    return re.test(url);
  }

  public addUrlQuery(queryParams) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  public navToUrlWithQuery(url: string, queryParams: object) {
    this.router.navigate([url], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  private setupActionListeners() {
    this.setupAction(new NavigateToLogin());
    this.setupAction(new NavigateReload(null));
    this.setupAction(new NavigateToForecast(null));
    this.setupAction(new NavigateToAutomation);
    this.setupAction(new NavigateToSignup());
    this.setupAction(new NavigateToHome());
    this.setupAction(new NavigateToAddDataFromSheet(null, null, null));
    this.setupAction(new NavigateToAddData(null));
    this.setupAction(new NavigateToDataProvider(null, null));
    this.setupAction(new NavigateToAdmin(null));
    this.setupAction(new NavigateToReport(null));

    this.actions.dispatched(AlphaVantageModalBackAction).subscribe((a: AlphaVantageModalBackAction) => {
      this.store.dispatch(new OpenAlphaMainModal(a.forecastVersion.ForecastVersionId));
    });
  }

  private setupAction<T extends INavigationAction>(action: T) {
    this.actions.dispatched(action).subscribe((value: T) => {
      this.navigateByAction(value.url, value.after);
    });
  }

  private navigateByAction(url: string, after?: Function) {
    this.router.navigateByUrl(url)
      .then(() => { if (after) { after(); } });
  }
}
