<indicio-dialog-base class="save-forecast-result">
  <h1 mat-dialog-title
      style="text-align: center">{{ title }}</h1>
  <div mat-dialog-content>
    <ng-container *ngTemplateOutlet="selectLocationTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="selectCategoryTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="descriptionTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="primNameTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="includeCiTemplate"></ng-container>
  </div>
  <div mat-dialog-actions>
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </div>
</indicio-dialog-base>

<ng-template #buttonTemplate>
  <button mat-stroked-button
          (click)="close()">
    Close
  </button>
  <div [indicioTippy]="exportReady ? 'Export result to Macrobond.' : missingSetupMessage">
    <button color="primary"
            [disabled]="!exportReady"
            mat-flat-button
            (click)="export()">
      <i *ngIf="saveInProgress"
         class="ion-load-c spinner"></i>
      {{ saveInProgress ? '' : 'Export' }}
    </button>
  </div>
</ng-template>

<ng-template #selectLocationTemplate>
  <indicio-mb-select-storage-location [storageLocations]="storageLocations"
                                      [currentLocation]="exportDto.Prefix"
                                      (newLocationEvent)="exportDto.Prefix = $event">
  </indicio-mb-select-storage-location>
</ng-template>

<ng-template #selectCategoryTemplate>
  <indicio-mb-select-category [categories]="categories"
                              [currentCategory]="exportDto.IHCategory"
                              [showLoader]="!initialized"
                              (newCategory)="categories.push($event)"
                              (newCategorySelected)="exportDto.IHCategory = $event">
  </indicio-mb-select-category>
</ng-template>

<ng-template #descriptionTemplate>
  <indicio-mb-set-description [currentDescription]="exportDto.Description"
                              [showLoader]="!initialized"
                              (newDescription)="exportDto.Description = $event">
  </indicio-mb-set-description>
</ng-template>

<ng-template #primNameTemplate>
  <indicio-mb-set-prim-name [currentPrimName]="exportDto?.PrimName ?? ''"
                            [showLoader]="!initialized"
                            (newPrimName)="exportDto.PrimName = $event">
  </indicio-mb-set-prim-name>
</ng-template>

<ng-template #includeCiTemplate>
  <div class="row"
       style="margin-bottom: 15px; margin-top: 10px;">
    <div class="col-xs-8">
      <div class="bold">Include confidence intervals</div>
    </div>
    <div class="col-xs-4 text-right">
      <mat-slide-toggle [checked]="exportDto.IncludeCi"
                        (change)="exportDto.IncludeCi = !exportDto.IncludeCi">
      </mat-slide-toggle>
    </div>
  </div>
</ng-template>
