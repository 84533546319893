<div class="company-edit-users-tab"
     *ngIf="!!company">
  <ng-container *ngTemplateOutlet="usersTemplate"></ng-container>
</div>

<ng-template #usersTemplate>
  <indicio-members-list [MemberListOptions]="memberListOptions"
                        data-test-id="admin-company-modal-users-list"
                        (hasActiveInviteEvent)="toggleSaveMemberButton($event)"
                        [shouldInviteNewMember]="shouldInviteNewMember"
                        (memberChangedRoleEvent)="updateMemberRole($event)"
                        (atSaveEvent)="saveInvitation();">
  </indicio-members-list>
</ng-template>
