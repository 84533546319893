import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { HierarchyReconciliationDTO } from './hierarchy-reconciliation.dto';
import { HierarchyRelationDTO } from './hierarchy-relation.dto';

export class HierarchyBaseDTO {
  Periodicity: PeriodicityType;
  Horizon: number;
}

export class CreateHierarchyDTO extends HierarchyBaseDTO { }
export class UpdateHierarchyDTO extends HierarchyBaseDTO { }

export class HierarchyMetaDTO extends HierarchyBaseDTO {
  HierarchyId: string;
  CreatorClientId: string;
  CreatorClientEmail: string;
  CreatedDate: string;
  ModifiedDate: string;
  NonNegative: boolean;
  CompanyId: string;
  CompanyName: string;
}

export class HierarchyDTO extends HierarchyMetaDTO {
  NewDataExists: boolean;
  ReconciliationStartDate: string;
  Relations: HierarchyRelationDTO[];
  Reconciliations: HierarchyReconciliationDTO[];
}

export class HierarchyResultEventArgs {
  CompanyId: string;
  HierarchyId: string;
}

export class ReconciliationRemovedEventArgs {
  CompanyId: string;
  HierarchyId: string;
  ReconciliationId: string;
}

export class HierarchyAdminOverviewTopListItem {
  ItemName: string;
  Rank: number;
}

export class HierarchyAdminOverviewDTO {
  TotalCount: number;
  PercentageUse: number;
  TopCompanies: HierarchyAdminOverviewTopListItem[];
  TopEmployee: HierarchyAdminOverviewTopListItem[];
  Hierarchies: HierarchyMetaDTO[];
}
