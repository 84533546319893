import { Def } from '@core/decorators/def';
import { SLGOptions } from '@shared/components/graphs/slg/slg.options';
import { DateUtils } from '@shared/utils/date.utils';

export class EndpointModel {
  @Def() LastUsage: Date;
  @Def() Endpoint: string;
  @Def() Dates: Date[];
  @Def() Count: number[];
  @Def() Max: number[];
  @Def() Min: number[];
  @Def() Average: number[];

  public get getCount() { return this.Count.slice().pop(); }
  public get getMin() { return this.Min.slice().pop(); }
  public get getMax() { return this.Max.slice().pop(); }
  public get getAverage() { return this.Average.slice().pop(); }
  public get getYearMonth() { return DateUtils.newMoment(this.Dates.slice().pop()).format('YYYY-MM'); }

}

export class EndpointSLGModel {
  @Def() Title: string;
  @Def() Data: SLGOptions.ValueModel[];
  constructor(title: string) {
    this.Title = title;
  }
}
