<div class="file-info-access-tab">
  <ng-container *ngTemplateOutlet="infoTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="usersTemplate"></ng-container>
</div>

<ng-template #infoTemplate>
  <div class="margin-bottom-10">
    Only people with explicit access can update and view this file.
  </div>
</ng-template>

<ng-template #usersTemplate>

  <indicio-data-table columnTemplate="1fr 100px 60px">
    <div title-row>
      <div column
           left>
        <span title>User email</span>
      </div>
      <div column>
        <span title>Access</span>
      </div>
      <div column>
      </div>
    </div>
    <div class="scroll-content"
         *ngIf="users?.length">
      <div row
           *ngFor="let u of users; let i = index">
        <div column
             style="height: 34px;"
             class="crop-string center-vertical"
             [title]="u.Email">
          {{ u.Email }}
        </div>
        <div column
             style="height: 33px; padding: 8px 5px 5px 5px;"
             center>
          <span>
            {{ u.Access }}
          </span>
        </div>
        <div column
             style="height: 33px; padding: 8px 5px 5px 5px;"
             center>
          <i class="ion-trash-b pointer"
             [ngClass]="{ disabled: u.Email === clientService.userEmail }"
             *ngIf="file.SourceType === 'file' && u.Access === 'editor'"
             (click)="removeEditor(u)"
             [indicioTippy]="u.Email === clientService.userEmail ? 'Cannot remove yourself' : 'Remove editor'"
             style="color:#E26464;"></i>
          <i style="font-size: 20px;"
             *ngIf="file.SourceType === 'file' && u.Access === 'owner'"
             [ngClass]="{ disabled: u.Email !== clientService.userEmail }"
             (click)="transferOwner()"
             [indicioTippy]="u.Email !== clientService.userEmail ? 'Only the current owner can transfer the ownership' : 'Transfer ownership'"
             class="ion-arrow-swap pointer h-grey"></i>
        </div>
      </div>
    </div>
  </indicio-data-table>

  <div class="add-editor-btn-container">
    <button style="margin-left: 5px"
            mat-stroked-button
            type="button"
            (click)="addEditor()">
      + Add editor
    </button>
  </div>


</ng-template>
