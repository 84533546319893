import * as d3 from 'd3';

import { GraphData } from '../alg-models/graph-data.model';

export function getMinAndMaxDates(showHistoricData: boolean, data: GraphData) {
  const min: Date = showHistoricData
    ? data.HistoricLine.Values.length === 0
      ? d3.min(data.Forecasted, c => {
        return d3.min(c.Values.filter(x => !!x), d => {
          return d.D;
        });
      })
      : data.HistoricLine.Values[0].D
    : data.HistoricLine.Values[data.HistoricLine.Values.length - 2].D;
  const max = data.Forecasted.length > 0
    ? d3.max(data.Forecasted, c => {
      return d3.max(c.Values.filter(x => !!x), d => {
        return d.D;
      });
    })
    : d3.max(data.HistoricLine.Values, (v) => v.D);
  return { dateMin: min, dateMax: max };
}
