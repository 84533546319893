import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import * as d3 from 'd3';
import { GraphData } from '../alg-models/graph-data.model';

export function getMinAndMaxValue(
  graphData: GraphData,
  summary: boolean,
  confInt: boolean,
  historic: boolean,
  fitted: boolean,
  past: boolean,
  from?: Date,
  to?: Date
) {
  /**
   * First, get data for historic section,
   * minH and maxH -> Min and Max Historic value
   */
  const [minH, maxH] = getMinMax(graphData.HistoricLine.Values, from, to);

  /**
   * minHF and maxHF is for the case when we don't show any historic data (in reality this means we show 2 historic data points)
   * minHF and maxHF are those 2 "always" visible historic data points
   */
  const [minHF, maxHF] = getMinMax(graphData.LastTwoHistoric as PlotValueDTO[], from, to);

  /**
   * Get the min and max values from the forecasted section
   * minF: Min forecasted value
   * maxF: Max forecasted value
   */
  const [minF, maxF] = getMinMax(graphData.Forecasted.map(x => x.Values).flatten(), from, to, confInt);

  /**
   * Get all the 'lines' defined.
   * Lines can be scenario lines, summary, or any data conforming to the 'new' input pattern
   * minLine: Min value for any of the defined lines
   * maxLine: Min value for any of the defined lines
   * Note: Change this if scenario-values ever should display their CIs d3.min([d.Min50, d.Min75, d.Min95, d.Value, d.Max50, d.Max75, d.Max95]);
   */
  const [minL, maxL] = getMinMax(graphData.Lines.map(x => x.Values).flatten(), from, to);

  /**
   * Decide on what data to compare
   */
  const minToCompare = historic ? [minH, minL, minF] : [minHF, minL, minF];
  const maxToCompare = historic ? [maxH, maxL, maxF] : [maxHF, maxL, maxF];

  if (fitted) {
    const relevantFittedValues = graphData.Fitted.map(f => f.Values.filter(v => (from && to) ? (v.D >= from && v.D <= to) : true)).flatten();
    const f = summary ? 'WF' : 'F';
    const fMin = d3.min(relevantFittedValues, d => d[f]);
    const fMax = d3.max(relevantFittedValues, d => d[f]);
    minToCompare.push(fMin);
    maxToCompare.push(fMax);
  }
  if (past) {
    const f = 'V';
    const relevantValues = graphData.PastForecasts.map(p => p.PastForecasts)
      .flatten().flatten()
      .filter(v => v[f] != null)
      .filter(v => isFinite(v[f]))
      .filter(v => (from && to) ? (v.D >= from && v.D <= to) : true);
    const pfMin = d3.min(relevantValues, d => d[f]);
    const pfMax = d3.max(relevantValues, d => d[f]);
    minToCompare.push(pfMin);
    maxToCompare.push(pfMax);
  }

  /**
   * Get the result, and add some margin to make the graph 'look' better
   */
  const min = Math.min(...minToCompare.filter(x => x !== null && x !== undefined));
  const max = Math.max(...maxToCompare.filter(x => x !== null && x !== undefined));
  const minDiff = max - min + 0.1;
  const maxDelta = 0.18;
  const minDelta = 0.10;
  const minToSet = min - Math.abs(minDiff * minDelta);
  const maxToSet = max + Math.abs(minDiff * maxDelta);

  return [minToSet, maxToSet];
}

/***
 * Get the minimum and maximum value from an array of SpeedPlotValueDTO.
 * If from and to are set, these denote the part of the array to consider values between.
 * @param values The values to consider
 * @param from Date to consider values from
 * @param to Date to consider values to
 * @param ci Should confidence intervals be considered? (default: false)
 */
function getMinMax(values: PlotValueDTO[], from?: Date, to?: Date, ci = false) {
  const valuesToUse = (from && to) ? values.filter(x => !!x && x.D >= from && x.D <= to) : values;
  const min = ci
    ? d3.min(valuesToUse, (d) => d3.min([d.I50, d.I75, d.I95, d.V, d.A50, d.A75, d.A95]))
    : d3.min(valuesToUse, d => +d.V);
  const max = ci
    ? d3.max(valuesToUse, (d) => d3.max([d.I50, d.I75, d.I95, d.V, d.A50, d.A75, d.A95]))
    : d3.max(valuesToUse, d => +d.V);
  return [min, max];
}
