import { RECONCILIATION_DISPLAY_TYPE } from '../dtos/hierarchy-reconciliation.dto';
import { HierarchyRelationDTO } from '../dtos/hierarchy-relation.dto';
import { HierarchyModel } from './hierarchy.model';

export class HForecastTreeNode {

  isOpen: boolean;
  isActive: boolean;
  children: HForecastTreeNode[];
  topNode: boolean;
  menuOpen: boolean;
  level: number;
  canBeReconciled: boolean;
  messages: string[];
  missingForecast: boolean;
  missingMainVar: boolean;
  invalidChildrenMessages: string[];

  // dtos
  Relation: HierarchyRelationDTO;
  Hierarchy: HierarchyModel;

  //frontend
  displayAs: RECONCILIATION_DISPLAY_TYPE;
  canUpdateVariables: boolean;

  get state(): 'error' | 'warning' | 'ok' {
    if (!this.valid || this.invalidChildrenMessages.length) { return 'error'; }
    if (this.warnings.length) { return 'warning'; }
    return 'ok';
  }

  get valid(): boolean {
    return !this.missingForecast && !this.missingMainVar && !!!this.errors.length;
  }

  get errors() {
    return this.Relation.Errors;
  }

  get warnings() {
    return this.Relation.Warnings;
  }

  shouldBeVisible(): boolean {
    return true;
  }

  foldOnClick(): boolean {
    return true;
  }

}
