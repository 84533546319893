import { ProjectModel } from '@core/store/project/project.model';
import { TransferProjectModalOpts } from './transfer-project-modal.options';

export class OpenTransferProjectModal {
  public static readonly type = '[MODAL] Open transfer project';
  data: TransferProjectModalOpts;
  constructor(
    project: ProjectModel,
    leaveAfterTransfer: boolean,
    back?: Function
  ) {
    this.data = { project, back, leaveAfterTransfer };
  }
}
