import { EAutoTaskState } from '@modules/lang/language-files/automations';
import { AutoTaskInfoModel, AutoTaskModel } from './dtos/auto-task.model';

export namespace AutoTaskAction {

  export class AddMessage {
    public static readonly type = '[TASKS] Add auto task message';
    constructor(public taskId: string, public companyId: string, public message: string) { }
  }

  export class SetState {
    public static readonly type = '[TASKS] Set auto task state';
    constructor(public taskId: string, public companyId: string, public state: EAutoTaskState) { }
  }

  export class GetInfoSuccess {
    public static readonly type = '[TASKS] Get auto task info success';
    constructor(public info: AutoTaskInfoModel) { }
  }

  export class GetAllSuccess {
    public static readonly type = '[TASKS] Get all tasks success';
    constructor(public tasks: AutoTaskModel[]) { }
  }

  export class GetSuccess {
    public static readonly type = '[TASKS] Get task success';
    constructor(public task: AutoTaskModel) { }
  }

  export class RemoveSuccess {
    public static readonly type = '[TASKS] Remove task success';
    constructor(public taskId: string) { }
  }
}

