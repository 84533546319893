import { Component, Input } from '@angular/core';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight, faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'indicio-plot-display',
  templateUrl: './plot-display-component.html',
  styleUrls: ['./plot-display-component.less'],
})
export class PlotDisplayComponent {

  public faCircle = faCircle as IconProp;
  public arrowRight = faArrowRight as IconProp;
  public arrowLeft = faArrowLeft as IconProp;

  @Input() plots: ResultFileDTO[];
  @Input() plotWidth: number;
  @Input() link: boolean = false;

  // Entity Ids
  public plotIndex: number = 0;

  public get plotSrc() { return `data:${this.plots[this.plotIndex]?.MimeType};base64,${this.plots[this.plotIndex]?.Base64String}`; }

  /** Change the plot to show */
  public changePlotIndex(index: number) { this.plotIndex = index; }
  public right() { this.plotIndex = (this.plotIndex + 1) % this.plots.length; }
  public left() { this.plotIndex = (this.plotIndex - 1 + this.plots.length) % this.plots.length; }

  public viewPlot() {
    if (!this.link) { return; }
    const win = window.open();
    win.document.write(`<img src="${this.plotSrc}"/>`);
  }
}
