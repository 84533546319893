<h1 mat-dialog-title
    *ngIf="data.Title">{{ data.Title }}</h1>
<div mat-dialog-content>

  <p *ngIf="data.Message">{{ data.Message }}</p>

  <ng-container *ngIf="!data.Transpose">
    <div class="grid-system"
         [style]="data.GridTemplate ? '--grid-template: ' + data.GridTemplate  : null">
      <div class="title-row"
           *ngFor="let col of titleRow">
        {{ col }}
      </div>
    </div>

    <div class="data-rows">
      <div class="grid-system"
           *ngFor="let row of dataRows"
           [style]="data.GridTemplate ? '--grid-template: ' + data.GridTemplate  : null">
        <ng-container *ngFor="let col of row; let i = index">
          <div *ngIf="!styles.includes(col)"
               [title]="col"
               [ngClass]="{ bold: row[+i + 1] === '[bold]', 'crop-string': row[+i + 1] !== '[noCrop]' }"
               [innerHTML]="col | safeHtml">
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

</div>
<div mat-dialog-actions>
  <div></div>
  <button mat-flat-button
          (click)="save()">
    Ok
  </button>
</div>
