<indicio-dialog-base class="pick-system-role-dialog-container">
  <h1 mat-dialog-title>Pick a system role</h1>
  <div mat-dialog-content>
    <ng-container *ngTemplateOutlet="pickRoleTemplate"></ng-container>
  </div>
  <div mat-dialog-actions>
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </div>
</indicio-dialog-base>

<ng-template #pickRoleTemplate>
  <mat-form-field>
    <mat-select [(value)]="role"
                required>
      <mat-option [value]="p.Value"
                  [title]="p.Display"
                  *ngFor="let p of roles">{{ p.Display }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="extra-margin-top italic">
    Log in with the role with the least access needed for the work you are about to do.
  </div>
</ng-template>

<ng-template #buttonTemplate>
  <div></div>
  <button color="primary"
          mat-raised-button
          (click)="proceed()">
    Proceed
  </button>
</ng-template>
