export class FileType {

  Extension: string = null;
  MimeType: string = null;

  constructor(ext: string, mime: string) {
    this.Extension = ext;
    this.MimeType = mime;
  }
}
