import { Injectable } from '@angular/core';
import { DataProvider } from '@core/constants/provider-definitions';
import { HttpService } from '@core/services/http/http.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { OxfordContentDTO, OxfordDatabankDTO, OxfordTreeLocationDTO, OxfordVariableMetaDTO } from '@core/store/providers/oxford/oxford-dtos';
import { OxfordVariableMetaModel } from '@core/store/providers/oxford/oxford-models';
import { OxfordMapper } from '@core/store/providers/oxford/oxford.mapper';
import { CacheUtils } from '@shared/utils/cache.utils';

@Injectable({
  providedIn: 'root'
})
export class OxfordService {

  // State stuff below
  private databankCache = new CacheUtils.LruCache<OxfordDatabankDTO[]>();
  private contentCache = new CacheUtils.LruCache<OxfordContentDTO>();
  private indicatorCache = new CacheUtils.LruCache<OxfordVariableMetaModel[]>();
  private treeListCache = new CacheUtils.LruCache<OxfordTreeLocationDTO[]>(100);

  constructor(
    private http: HttpService,
    private mapper: OxfordMapper,
    private clientService: ClientFrontendService
  ) { }

  private getCredentialsBase() {
    return { ClientId: this.clientService.client.ClientId, Provider: DataProvider.oxford };
  }

  public testLoginDetails(username: string, password: string) {
    return this.http.post<string>('oxford/test-login', { Username: username, Password: password }, 'import')
      .then(({ body }) => body);
  }

  public getDatabanks() {
    const key = 'databanks';
    const cacheExists = this.databankCache.has(key);
    if (cacheExists) {
      return Promise.resolve(this.databankCache.get(key));
    }
    return this.http.post<OxfordDatabankDTO[]>('oxford/databanks', this.getCredentialsBase(), 'import')
      .then(({ body }) => {
        this.databankCache.put(key, body);
        return body;
      });
  }

  public getDatabankContent(databank: string) {
    const key = `content-${databank}`;
    const cacheExists = this.contentCache.has(key);
    if (cacheExists) {
      return Promise.resolve(this.contentCache.get(key));
    }
    return this.http.post<OxfordContentDTO>(`oxford/databank/${databank}`, this.getCredentialsBase(), 'import')
      .then(({ body }) => {
        const mapped = this.mapper.mapContent(body);
        this.contentCache.put(key, mapped);
        return mapped;
      })
      .catch(e => {
        console.error('Failed to get oxford content', `status: ${e.status}, message: ${e.statusText}`);
      });
  }

  public getRegions(databank: string) {
    const key = `tree-${databank}`;
    const cacheExists = this.treeListCache.has(key);
    if (cacheExists) {
      return Promise.resolve(this.treeListCache.get(key));
    }
    return this.http.post<OxfordTreeLocationDTO[]>(`oxford/databank/${databank}/regions`, this.getCredentialsBase(), 'import')
      .then(({ body }) => {
        body = body.map(region => this.mapper.mapRegion(region));
        this.treeListCache.put(key, body);
        return body;
      })
      .catch(e => {
        console.error('Failed to get oxford regions', `status: ${e.status}, message: ${e.statusText}`);
      });
  }

  public getAvailableIndicators(databank: string, countryCode: string) {
    const key = `indicators-${countryCode}`;
    const cacheExists = this.indicatorCache.has(key);
    if (cacheExists) {
      return Promise.resolve(this.indicatorCache.get(key));
    }
    return this.http.post<OxfordVariableMetaDTO[]>(`oxford/databank/${databank}/region/${countryCode.toUpperCase()}/variables`, this.getCredentialsBase(), 'import')
      .then(({ body }) => {
        const mapped = body.map(x => this.mapper.mapMeta(x));
        this.indicatorCache.put(key, mapped);
        return mapped;
      });
  }
}
