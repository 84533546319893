import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SysAdminGuard } from '@core/guards/admin.route-guard';
import { LoginRouteGuard } from '@core/guards/login.route-guard';
import { environment } from '@env/environment';
import { LoaderComponent } from '@modules/root/components/page-load/loader.component';
import { ResetPasswordComponent } from '@modules/root/views/reset-password/reset-password.component';
import { SignupComponent } from '@modules/root/views/signup/signup.component';
import { LoginComponent } from './views/login/login.component';

const routes: Routes = [
  { path: 'signup/:code', component: SignupComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'loading', component: LoaderComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password/:code', component: ResetPasswordComponent },
  { path: 'api/v1/macrobond/auth', pathMatch: 'full', redirectTo: 'home/open-id' },
  {
    path: '',
    canActivate: [LoginRouteGuard],
    children: [
      { path: 'home', loadChildren: () => import('../home/home.module').then(m => m.HomeModule) },
      { path: 'forecast', loadChildren: () => import('../forecast/forecast.module').then(m => m.ForecastModule) },
      { path: 'admin', canLoad: [SysAdminGuard], loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule) },
      { path: 'reports', loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule) },
      { path: 'automation', loadChildren: () => import('../automation/automation.module').then(m => m.AutomationModule) },
    ]
  },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: environment.navLogging })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
