import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CloseMainMenuAction, NavigateToForecast } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CreateForecastDTO } from '@core/store/forecast/dtos/forecast/create-forecast-dto';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Periodicity } from '@modules/lang/types/periodicity';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DateUtils } from '@shared/utils/date.utils';


export class CreateForecastDialogData {
  public ProjectId: string;
  public Horizon: number = 3;
  public Periodicity: PeriodicityType = 'month';
  /* If set to false, user cannot change periodicity or horizon */
  public UserCanChangeSettings: boolean = true;
  public HideTemplateOption: boolean = false;
}

@Component({
  selector: 'indicio-create-forecast-dialog',
  templateUrl: 'create.dialog.html',
})
export class CreateForecastDialogComponent extends DialogV2BaseDialog<CreateForecastDialogComponent> {

  public static Id: string = 'CreateForecastDialogComponent';

  // Status flags
  public forecastNameMustChange: boolean = false;
  public unsaved: boolean = false;
  public needProject: boolean = true;
  public pending: boolean = false;

  // Entities
  public createDto: CreateForecastDTO = null;
  public project: ProjectModel = null;

  // Options
  public projects: ProjectModel[] = [];
  public validPeriodicities: Periodicity[];
  public forecastPoints = [0];

  public get disabledText() {
    if (!this.createDto.Name) { return 'Enter a name for the forecast'; }
    if (this.forecastNameMustChange) { return 'The forecast name must be unique'; }
    if (!this.project) { return 'Select a project'; }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CreateForecastDialogData,
    private forecastService: ForecastFrontendService,
    public envService: EnvironmentService,
    private projectService: ProjectFrontendService,
    private companyService: CompanyFrontendService,
    private clientService: ClientFrontendService,
    private profileService: ProfileFrontendService,
    private statusService: StatusService,
    private store: Store,
    private dialogService: DialogService,
    dialogRef: MatDialogRef<CreateForecastDialogComponent, CreateForecastDialogData>,
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected async initialize() {
    const defaults = new CreateForecastDialogData();
    if (!this.data.Periodicity) { this.data.Periodicity = defaults.Periodicity; }
    if (!this.data.Horizon) { this.data.Horizon = defaults.Horizon; }
    if (this.data.UserCanChangeSettings == null) { this.data.UserCanChangeSettings = defaults.UserCanChangeSettings; }
    if (this.data.HideTemplateOption == null) { this.data.HideTemplateOption = defaults.HideTemplateOption; }

    if (this.data.ProjectId) {
      this.project = await this.projectService.getOrFetchProject(this.companyService.activeCompanyId, this.data.ProjectId);
    }

    this.createDto = this.forecastService.tempNewForecast;
    this.createDto.Periodicity = this.data.Periodicity;
    this.createDto.Horizon = this.data.Horizon;
    if (!!this.project) {
      this.needProject = false;
      this.setProject(<MatSelectChange> { value: this.project });
    } else {
      this.projects = this.projectService.projects.filter(proj => proj.hasPermission('CAN_CREATE_FORECAST'));
    }

    this.validPeriodicities = this.envService.getValidPeriodicites(this.companyService.horizonInfos);
    this.setForecastPoints();

    if (this.forecastService.tempNewForecast.unsaved) {
      this.unsaved = true;
    }

    this.initialized = true;
  }

  public setProject(event: MatSelectChange) {
    if (!event.value) { return; }
    const project = event.value as ProjectModel;
    this.project = project;
    this.createDto.ProjectId = project.ProjectId;
  }

  public createProject() {
    this.projectService.openCreateProjectDialog(this.profileService.profile.Email, this.profileService.profile.ClientId, this.clientService.client.ActiveCompanyId)
      .then(newProj => {
        if (!newProj) { return; }
        this.projects.push(newProj);
        this.setProject(<MatSelectChange> { value: newProj });
      });
  }

  private setForecastPoints() {
    this.forecastPoints = Array.from(Array(this.companyService.maxHorizon(this.createDto.Periodicity)), (_, i) => i + 1);
  }

  public onNewHorizon() {
    this.createDto.unsaved = true;
    this.setForecastPoints();
  }

  public changeForecastName(newName: string) {
    this.createDto.Name = newName.trim();
    this.createDto.unsaved = true;
    this.checkName();
  }

  public createFromTemplate() {
    this.close();
    this.dialogService.openCreateForecastFromTemplateDialog({ project: this.project });
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public save() {
    if (!this.createDto.Name || this.forecastNameMustChange || !this.project) { return; }
    this.statusService.removeMessage();
    this.pending = true;
    this.createDto.StartDate = DateUtils.convertToBackendDate(DateUtils.newDate());
    this.forecastService.createForecast(this.clientService.activeCompanyId, this.createDto)
      .then(f => {
        this.dialogRef.close(f.ForecastId);
        this.statusService.setMessage('Forecast created successfully', 'Success');
        this.store.dispatch(new NavigateToForecast(f.ForecastId));
        this.store.dispatch(new CloseMainMenuAction());
      })
      .catch(error => {
        this.pending = false;
        this.statusService.setError(error, true);
      });
  }

  private checkName() {
    if (!this.project) { return; }
    this.forecastService.forecastNameConflict(this.createDto.Name, this.project, null).then(notOk => {
      this.forecastNameMustChange = !!notOk;
    });
  }
}
