import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastTemplateAction } from '@core/store/forecast-template/forecast-template.actions';
import { ForecastTemplateFrontendService } from '@core/store/forecast-template/forecast-template.frontend.service';
import { ForecastTemplate } from '@core/store/forecast-template/forecast-template.model';
import { ForecastTemplateTypes } from '@core/store/forecast-template/forecast-template.types';
import { ProjectModel } from '@core/store/project/project.model';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

export interface ForecastTemplateInfoDialogData {
  template: ForecastTemplate;
  project: ProjectModel;
}

@Component({
  selector: 'indicio-forecast-template-info-dialog',
  templateUrl: 'forecast-template-info.dialog.html',
  styleUrls: ['forecast-template-info.dialog.less']
})
export class ForecastTemplateInfoDialogComponent {
  public static Id: string = 'ForecastTemplateInfoDialogComponent';

  public TemplateTypes = ForecastTemplateTypes;
  public template: ForecastTemplate;
  public canUse: boolean = false;
  public missing: string = '';
  public canEdit: boolean = false;

  public get tagString() {
    return this.template.Tags.map(x => x.Name).join(', ');
  }

  constructor(
    public dialogRef: MatDialogRef<ForecastTemplateInfoDialogComponent>,
    private dialog: DialogService,
    private clientService: ClientFrontendService,
    private service: ForecastTemplateFrontendService,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: ForecastTemplateInfoDialogData) {
    this.setup();
  }

  private setup() {
    this.template = this.data.template;
    this.canUse = this.template.Sources.every(x => x.HasAccess);
    this.missing = this.template.Sources.filter(x => !x.HasAccess).map(x => x.source.Display).join(', ');
    this.canEdit = this.clientService.isAdmin || this.template.authorEmail === this.clientService.userEmail;
  }

  public openEdit() {
    this.onNoClick();
    const ref = this.dialog.openEditForecastTemplateDialog({ Template: this.template });
    ref.subscribe(updated => {
      this.dialog.openForecastTemplateInfoDialog({
        project: this.data.project,
        template: !updated ? this.template : updated
      });
    });

  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public use() {
    const { Opened, Ref } = this.dialog.openUseTemplateLiveViewDialog({ template: this.template });
    Opened.subscribe(() => {
      this.service.use(this.template.ForecastTemplateId, { ProjectId: this.data.project?.ProjectId })
        .then(forecast => {
          this.store.dispatch(new ForecastTemplateAction.UseSuccess(forecast));
        })
        .catch(err => {
          this.store.dispatch(new ForecastTemplateAction.Error(err?.error?.Message || err?.message || err));
        });
    });
    this.dialogRef.close(true);
  }
}
