import * as d3 from 'd3';

export type DateKey = 'D';
export type ValueKey = 'V' | 'F' | 'WF';
export type CIMinKey = 'I50' | 'I75' | 'I95';
export type CIMaxKey = 'A50' | 'A75' | 'A95';

export function getLineGenerator<T>(
  dateKey: DateKey = 'D',
  valueKey: ValueKey = 'V',
  xScale,
  yScale
): d3.Line<T> {
  return d3.line<T>().curve(d3.curveLinear)
    .x(d => xScale(d[dateKey]))
    .y(d => yScale(d[valueKey]));
}

export function getCIPathGenerator<T>(
  minKey: CIMinKey,
  maxKey: CIMaxKey,
  xScale: d3.ScaleTime<any, any>,
  yScale: d3.ScaleLinear<any, any>
): d3.Area<T> {
  const area = d3.area<T>()
    .x(d => xScale((d as any).D))
    .y0(d => yScale(d[maxKey] || d['V']))
    .y1(d => yScale(d[minKey] || d['V']));
  return area;
}
