import { Component, Input } from '@angular/core';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';

@Component({
  selector: 'indicio-loading-profile',
  templateUrl: './loading-profile.component.html',
  styleUrls: ['./loading-profile.component.less']
})
export class LoadingProfileComponent {

  @Input() fromMacrobond: boolean;

  public get loggedIn$() { return this.authService.loggedIn$; }

  constructor(
    public authService: AuthFrontendService,
  ) { }
}
