import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { RQueueInfoModel } from '@core/store/script-queue/r-queue.models';
import { ScriptQueueFrontendService } from '@core/store/script-queue/script-queue.frontend.service';
import { FVarDialogViewIndex } from '@dialogs/variables/forecast-variable/info/forecast-variable-info.dialog';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OpenDataProcessingModal } from './data-processing-modal.actions';
import { DPModalOpts } from './data-processing-modal.options';
import { EForecastVariableStatus } from '@core/store/forecast-variable/models/forecast-variable-meta.model';

@Component({
  selector: 'indicio-dp-modal',
  templateUrl: 'data-processing-modal.component.html'
})
export class DataProcessingModalComponent extends ModalModelComponent {

  private opts: DPModalOpts;
  protected readonly EForecastVariableStatus = EForecastVariableStatus;

  public Q: RQueueInfoModel = null;
  public variable: ForecastVariableModel;
  public fVersion: ForecastVersionModel;
  public forecast: ForecastModel;
  public tableInfo = [];
  public isPending = false;

  constructor(
    protected store: Store,
    private status: StatusService,
    private variableService: ForecastVariableFrontendService,
    private forecastService: ForecastFrontendService,
    private scriptQ: ScriptQueueFrontendService,
    private varDialog: VariableDialogService
  ) { super(); }

  public setOptions(options: DPModalOpts) {
    this.opts = options;
    this.Q = this.scriptQ.getQueueByForecastVersionId(this.opts.forecastVersionId);
    const vPromise = this.variableService.fetchById(this.opts.forecastVersionId, this.opts.forecastVariableId);
    const fvPromise = this.forecastService.getOrFetchForecastVersion(this.opts.forecastVersionId);
    const fPromise = this.forecastService.getOrFetchForecast(this.opts.forecastId);
    Promise.all([vPromise, fPromise, fvPromise])
      .then(([v, f, fv]) => {
        this.fVersion = fv;
        this.forecast = f;
        this.variable = v;
        this.setup();
      })
      .catch(err => this.status.setError(err));
  }

  private setup() {
    if (this.variable.Status > 0) {
      this.setMessages();
    }
    this.isPending = this.Q.Entries.findIndex(x => x.ForecastVariableId === this.opts.forecastVariableId && x.ScriptName === 'data-processing') !== -1;
    this.isLoading = false;
  }

  public navToVariableInfo() {
    this.close();
    this.varDialog.openForecastVariableInfo({
      forecastId: this.opts.forecastId,
      forecastVariableId: this.opts.forecastVariableId,
      forecastVersionId: this.opts.forecastVersionId,
      view: FVarDialogViewIndex.general
    });
  }

  public openTwin() {
    this.close();
    this.store.dispatch(new OpenDataProcessingModal(this.opts.forecastId, this.opts.forecastVersionId, this.variable.TrendTwinId));
  }

  private setMessages() {
    this.tableInfo = []
      .concat(this.variable.InfoMessages.map(msg => ({ 'colName': 'Message', 'colInfo': msg })))
      .concat(this.variable.WarningMessages.map(msg => ({ 'colName': 'Message', 'colInfo': msg })))
      .concat(this.variable.ErrorMessages.map(msg => ({ 'colName': 'Message', 'colInfo': msg })));
  }

  public recalcOnError() {
    this.variable.CalculateOutliers = false;
    this.variable.CalculateSeasonal = false;
    this.variableService.updateForecastVariableSettings(this.variable)
      .then(updated => {
        this.variable = updated;
        this.recalc();
      })
      .catch(err => { this.status.setError(err); });
  }

  public recalc() {
    this.variableService.triggerDataProcessingMany(this.fVersion.ForecastVersionId, [this.variable.ForecastVariableId])
      .then(() => {
        this.close();
        this.status.setMessage('Recalculation in progress', 'Success');
      })
      .catch(err => { this.status.setError(err, true); });
  }
}


