
export namespace SystemMessage {

  export type MessageType = 'tos' | 'pusher';

  export namespace Entities {

    export class Message {
      Type: MessageType;
      Content: string;
      InfoAction?: Function;
      InfoIcon: string = 'ion-info';
      Sticky: boolean = true;
      Position: 'top' | 'bottom' = 'top';
    }
  }

  export namespace Actions {

    export class BackendMessage {
      public static readonly type = '[SYSTEM] Indicio backend message';
      constructor(public messages: string[]) { }
    }

    export class Display {
      public static readonly type = '[SYSTEM] Display message';
      constructor(public message: Entities.Message) { }
    }

    export class Clear {
      public static readonly type = '[SYSTEM] Clear display message';
      constructor(public type: MessageType | 'all') { }
    }
  }
}


