import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NumberPipe } from '@shared/modules/pipes/number.pipe';

@Component({
  selector: 'indicio-number-input',
  template: `
  <input *ngIf="!_loading"
               class="material"
               [disabled]="disabled"
               [value]="_display"
               (paste)="handlePaste($event)"
               (input)="setValue($event)"
               type="text">
    `,
  styles: ['input { width: 100% }']
})
export class NumberInputComponent implements OnChanges {

  @Input() disabled: boolean;
  @Input() value: number;
  @Input() enablePaste: boolean = false;

  @Output() valueEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() pasteEvent: EventEmitter<string> = new EventEmitter<string>();

  public _display: string;
  public _loading: boolean = true;

  public constructor(private numberPipe: NumberPipe) { }

  public ngOnChanges() {
    this.setup();
  }

  public handlePaste(event: ClipboardEvent) {
    if (!this.enablePaste) { return; }
    event.preventDefault();
    const text = event.clipboardData.getData('text');
    this.pasteEvent.emit(text);
  }

  public setValue(evt: InputEvent) {
    const input = (evt.target as HTMLInputElement);
    const parsed = this.numberPipe.parseValue(input.value);
    if (parsed == null) { return; }
    const { value, newValue } = parsed;
    input.value = value;
    this.valueEvent.emit(newValue);
  }

  private setup() {
    this._display = `${this.value || ''}`;
    this._loading = false;
  }
}
