import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TagMetaDTO } from '@core/store/tags/dtos/tags.dtos';
import { TagModel } from '@core/store/tags/models/tag.model';
import { StringUtils } from '@shared/utils/string.utils';

export class SearchDropDownDTO {
  public value: string;
  fn;
}

@Component({
  selector: 'indicio-tag-dropdown',
  templateUrl: './tag-dropdown.html',
  encapsulation: ViewEncapsulation.None
})
export class TagDropdownComponent implements OnInit {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('trigger') mySelect;

  public keyword: string = '';
  public subscribed: boolean = false;
  public globalTagArray: TagModel[] = [];
  public control: UntypedFormControl = new UntypedFormControl();
  private newTagPrefix: string = 'newTag-';

  @Input() searchLoading: Boolean = false;
  @Input() filteredAutoComplete: any[];
  @Input() placeholder: string = 'Add a new tag';
  @Input() extraClass = '';
  @Input() label: string;
  @Input() title: string = 'title';
  @Input() filteredTags: TagModel[];
  @Input() result: TagMetaDTO[];

  @Output() addNewEvent = new EventEmitter();

  public ngOnInit() {
    if (this.filteredTags != null) {
      this.filteredTags = this.filteredTags.filter(e => this.result.map(p => p.TagId).indexOf(e.TagId) === -1);
      this.globalTagArray = this.filteredTags.slice();
    }
  }

  public clearTags(tag: TagMetaDTO) {
    if (tag == null) {
      return;
    }
    this.result.removeByKey('TagId', tag.TagId);
    this.control.setValue('');
    this.searchInput.nativeElement.value = '';
    setTimeout(() => {
      this.searchInput.nativeElement.blur();
    }, 0);
  }

  public optionSelected(value: any) {
    if (value == null) {
      return;
    }
    const tag: TagModel = value.option.value;
    if (this.isNewTag(tag.TagId) && this.addNewIsEnabled()) {
      const addNewDTO = new SearchDropDownDTO();
      const callback = (theNewTag) => {
        this.globalTagArray.push(theNewTag);
        this.reset();
        setTimeout(() => {
          this.softReset();
          this.mySelect.closePanel();
        }, 100);
      };
      addNewDTO.fn = callback;
      addNewDTO.value = this.getNewTagName(tag.TagId);
      this.addNewEvent.emit(addNewDTO);
    } else {
      const newTag = new TagMetaDTO();
      newTag.Name = tag.Name;
      newTag.TagId = tag.TagId;
      this.result.push(newTag);

      this.softReset();
    }
  }

  public removeInputValue() {
    this.searchInput.nativeElement.value = '';
  }

  public changed(value: any) {
    if (value == null) {
      return;
    }
    if (typeof value === 'object') {
      this.search(value['Name']);
    } else if (typeof value === 'string' && value.length > 0) {
      this.search(value);
    }
    if (typeof value === 'string' && this.filteredTags.length === 0 && value.length > 0 && this.addNewIsEnabled() && this.dontExistsInResult(value)
      || typeof value === 'string' && this.noExplicitMatch(value) && this.addNewIsEnabled() && this.dontExistsInResult(value)) {
      const a = new TagModel();
      const thisNewTag = StringUtils.stripCharacters(value);
      a.Name = `Would you like to add "${thisNewTag}"?`;
      a.TagId = `${this.newTagPrefix}${thisNewTag}`;
      this.filteredTags.push(a);
    }
    if (typeof value === 'string' && value.length === 0) {
      this.reset();
    }
  }

  private search(value: string) {
    this.filteredTags = this.globalTagArray.filter(e => e != null).filter(e => e.Name.toLowerCase().includes(value));
    this.filteredTags = this.filteredTags.filter(e => e != null).filter(e => this.result.map(p => p.Name.toLowerCase()).includes(e.Name.toLowerCase()) === false);
  }

  private softReset() {
    this.control.setValue('');
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.blur();
    setTimeout(() => {
      this.reset();
    }, 1000);
  }

  private reset() {
    if (this.result.length > 0) {
      this.filteredTags = this.globalTagArray.filter(e => e != null).filter(e => this.result.map(p => p.Name.toLowerCase()).indexOf(e.Name.toLowerCase()) === -1);
    } else {
      this.filteredTags = this.globalTagArray.filter(e => e != null);
    }
  }

  private dontExistsInResult(value: string) {
    const result = this.result.filter(e => e != null && e.Name.toLowerCase() === value.toLowerCase());
    return result.length === 0;
  }

  private isNewTag(value: string) {
    if (value == null) {
      return false;
    }
    const rm = this.newTagPrefix;
    return value.indexOf(rm) > -1;
  }

  private getNewTagName(value: string) {
    const rm = this.newTagPrefix;
    const str = value.substring(value.indexOf(rm) + rm.length, value.length);
    return str;
  }

  private addNewIsEnabled() {
    return this.addNewEvent.observers.length > 0;
  }

  private noExplicitMatch(value: string) {
    const a = this.filteredTags.filter(e => e.Name.toLowerCase() === value.toLowerCase());
    return a.length === 0;
  }

}

