<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content
     class="pre-file-container">

  <div
       style="background-color: var(--indicio-modal-alt-background-color, rgb(241, 241, 241)); padding: 10px; margin: 0">
    <div class="row">
      <div class="col-xs-6 crop-string"
           [indicioTippy]="info.FileToUpload.FileName">
        <span>Filename: {{ info.FileToUpload.FileName }}</span>
      </div>
      <div class="col-xs-3 text-right"
           [class]="{ orange: info?.UpdateInfo?.FileChanges.OldSheetCount !== info?.UpdateInfo?.FileChanges.NewSheetCount }">
        <span>{{ info?.UpdateInfo?.FileChanges.NewSheetCount }} sheet(s)</span>
      </div>
      <div class="col-xs-3 text-right"
           [class]="{ orange: info?.UpdateInfo?.FileChanges.OldColumnCount !== info?.UpdateInfo?.FileChanges.NewColumnCount }">
        <span>{{ info?.UpdateInfo?.FileChanges.NewColumnCount }} column(s)</span>
      </div>
    </div>
    <div class="row margin-top">
      <div class="col-xs-6 crop-string"
           [indicioTippy]="info.OldFile.FileName">
        <span>Old filename: {{ info.OldFile.FileName }}</span>
      </div>
      <div class="col-xs-3 text-right">
        <span>{{ info?.UpdateInfo?.FileChanges.OldSheetCount }} sheet(s)</span>
      </div>
      <div class="col-xs-3 text-right">
        <span>{{ info?.UpdateInfo?.FileChanges.OldColumnCount }} column(s)</span>
      </div>
    </div>
  </div>

  <ng-container *ngFor="let variable of info.UpdateInfo.Variables">
    <ng-container *ngTemplateOutlet="variablesTemplate; context: { variable }">
    </ng-container>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Close
  </button>
  <div [indicioTippy]="!info.canUpload ? 'You need to select columns for all variables' : null">
    <button mat-flat-button
            color="success"
            [disabled]="!info.canUpload"
            [mat-dialog-close]="">
      Done
    </button>
  </div>

</div>

<ng-template #variablesTemplate
             let-variable="variable">
  <indicio-foldable-row #row
                        [manualToggle]="true">
    <div class="row"
         style="width: 100%;"
         foldable-row-title>
      <div class="col-xs-6 crop-string"
           [indicioTippy]="variable.Name"
           (click)="row.runToggleEvent()">Imported variable: {{variable.Name}}</div>
      <ng-container *ngIf="getConfirmedLocation(variable) as location">
        <ng-container *ngIf="{ complete: info.isLocationComplete(location) } as completeInfo">
          <div class="col-xs-4"
               (click)="row.runToggleEvent()">
            <ng-container *ngIf="completeInfo.complete">
              {{ info.getVariableValueCount(location) }} observations
            </ng-container>
            <ng-container *ngIf="!completeInfo.complete">
              <span class="orange">Select column(s)</span>
            </ng-container>
          </div>
          <div class="col-xs-2">
            <button (click)="openFileColumnDiffDialog(variable, location)"
                    class="btn btn-small-inline"
                    [indicioTippy]="location.SkipUpdate ? 'Variable will not be updated' : completeInfo.complete
                      ? location?.Diff?.Above3 && !location?.Diff?.Above20
                        ? 'Changes above 3%'
                        : location?.Diff?.Above20
                          ? 'Changes above 20%'
                          : null
                      : 'Select column(s) to import'"
                    [class]="{
                      disabled: !completeInfo.complete && !location.SkipUpdate,
                      orange: location?.Diff?.Above3 && !location?.Diff?.Above20,
                      red: location?.Diff?.Above20 || location.SkipUpdate,
                      green: !location?.Diff?.Above20 && !location?.Diff?.Above3
                    }"
                    style="position: absolute; right: -10px; width: 120px; top: -2px">
              <span>
                {{ location.SkipUpdate ? 'Excluded' : location?.Diff?.Above3 ? location?.Diff?.Above20 ? 'Attention' :
                'Warning' : 'Preview' }}
              </span>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="getConfirmedLocation(variable) as location">
      <ng-container *ngTemplateOutlet="variableOptionTemplate; context: { variable, location }">
      </ng-container>
    </ng-container>
  </indicio-foldable-row>
</ng-template>

<ng-template #variableOptionTemplate
             let-location=location
             let-variable=variable>
  <div class="data-table"
       style="width: 530px;">
    <div class="data-table__body"
         [style.maxHeight.px]="301">
      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngIf="!info.FileToUpload.isCsv">
        <div class="column left-align"
             style="width: 100px;">
          <span>Sheet</span>
        </div>
        <div class="column left-align"
             style="width: 240px;">
          <ng-container *ngIf="location.Exact">
            <span [class]="{ orange: variable.OldSheet !== location.NewSheet }">Sheet {{ location.NewSheet + 1 }}</span>
            <span> - Title: {{ info.getSheetname(location.NewSheet) }}</span>
          </ng-container>

          <ng-container *ngIf="!location.Exact">
            <mat-form-field>
              <mat-select [value]="location.NewSheet"
                          [attr.data-warning]="location.NewSheet === -1"
                          (selectionChange)="setNewSheet($event.value, location)">
                <mat-option [value]="-1"
                            disabled>Select sheet</mat-option>
                <mat-option [value]="-2"
                            style="color: #E26464">Exclude</mat-option>
                <mat-option [value]="sheetIndex"
                            *ngFor="let sheetIndex of getPossibleSheetIndexes(variable)">
                  Sheet {{ sheetIndex + 1 }} - Title: {{ info.getSheetname(sheetIndex) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
        <div class="column left-align"
             style="width: 188px;">
          Previous file version:
          <span [class]="{ orange: variable.OldSheet !== location.NewSheet }">Sheet {{ variable.OldSheet + 1 }}</span>
        </div>
      </div>

      <div class="body__row body__row--center-height body__row--max-height-30">
        <div class="column left-align"
             style="width: 100px;">
          <span>Date column</span>
        </div>
        <div class="column left-align"
             style="width: 240px;">
          <ng-container *ngIf="location.Exact">
            <span [class]="{ orange: variable.OldDateIndex !== location.NewDateIndex }">
              Column {{ location.NewDateIndex + 1 }}</span>
            <span> - Title: {{ info.getColumnName(location.NewSheet, location.NewDateIndex) }}</span>
          </ng-container>
          <ng-container *ngIf="!location.Exact">
            <ng-container *ngIf="info.getAllPossibleDateColumnIndexes(location.NewSheet) as dateIndexes">
              <mat-form-field>
                <mat-select [value]="location.NewDateIndex"
                            [attr.data-warning]="location.NewDateIndex === -1"
                            [disabled]="!isNum(location.NewSheet) || dateIndexes.length === 1"
                            (selectionChange)="setNewDateCol($event.value, location)">
                  <mat-option [value]="-1"
                              disabled>Select column</mat-option>
                  <mat-option [value]="-2"
                              style="color: #E26464">Exclude</mat-option>
                  <mat-option [value]="dateIndex"
                              *ngFor="let dateIndex of dateIndexes">
                    Column {{ dateIndex + 1 }} -
                    Title: {{ info.getColumnName(location.NewSheet, dateIndex) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </ng-container>
        </div>
        <div class="column left-align"
             style="width: 188px;">
          Previous file version:
          <span [class]="{ orange: variable.OldDateIndex !== location.NewDateIndex }">
            Column {{ variable.OldDateIndex + 1}}
          </span>
        </div>
      </div>

      <div class="body__row body__row--center-height body__row--max-height-30">
        <div class="column left-align"
             style="width: 100px;">
          <span>Value column</span>
        </div>
        <div class="column left-align crop-string"
             style="width: 240px;">
          <ng-container *ngIf="location.Exact">
            <span [class]="{ orange: variable.OldValueIndex !== location.NewValueIndex }">
              Column {{ location.NewValueIndex + 1 }}
            </span>
            <span> - Title: {{ info.getColumnName(location.NewSheet, location.NewValueIndex) }}
            </span>
          </ng-container>

          <ng-container *ngIf="!location.Exact">
            <mat-form-field *ngIf="info.getAllPossibleValueColumnIndexes(location.NewSheet) as valueIndexes">
              <mat-select [value]="location.NewValueIndex"
                          [attr.data-warning]="location.NewValueIndex === -1"
                          [disabled]="!isNum(location.NewSheet)"
                          (selectionChange)="setNewValueCol($event.value, location)">
                <mat-option [value]="-1"
                            disabled>Select column</mat-option>
                <mat-option [value]="-2"
                            style="color: #E26464">Exclude</mat-option>
                <mat-option [value]="v"
                            *ngFor="let v of valueIndexes">
                  Column {{ v + 1 }} -
                  Title: {{ info.getColumnName(location.NewSheet, v) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
        <div class="column left-align"
             style="width: 188px;">
          Previous file version:
          <span [class]="{ orange: variable.OldValueIndex !== location.NewValueIndex }">
            Column {{ variable.OldValueIndex + 1 }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
