<div class="step-ahead-container"
     indicioScrollHorizontally
     [class]="{ 'disabled': disabled }">

  <div *ngIf="!loading"
       class="grid-system array"
       [style]="'--grid-template: repeat(' + stepCount + ', ' + stepWidth + 'px)'">
    <div *ngFor="let date of variable.dates; let i = index">
      <div class="date"
           [class]="{ 'disabled': disabled }">
        {{ date | dateFormat }}
      </div>
      <div>
        <div style="display: inline-block; width: 90px;">
          <indicio-number-input [disabled]="disabled"
                                [value]="variable.display[i]"
                                (valueEvent)="setStep(i, $event)">
          </indicio-number-input>
        </div>
        <span *ngIf="!!variable.factor.abbr"
              class="pointer"
              (click)="wikiService.open('advanced-numbers-formats-and-suffixes')"
              [indicioTippy]="disabled ? null :'Factor: ' + variable.factor.description + '<br>' + 'Click here to read more.' ">
          {{ variable.factor.abbr }}
        </span>
      </div>
    </div>
  </div>
</div>
