import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { WikiExportDTO } from '@core/store/wiki/wiki.entities';
import { WikiService } from '@core/store/wiki/wiki.service';
import { AdminWikiService } from '@modules/admin/components/content/wiki/wiki-admin.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import * as JSZip from 'jszip';

export interface AdminImportWikiDbWikiDialogData { }

@Component({
  selector: 'indicio-import-wiki-db-dialog',
  templateUrl: 'import-wikiDb.dialog.html'
})
export class AdminImportWikiDbWikiDialogComponent {

  public contentReady: boolean = false;
  public exportDto: WikiExportDTO = null;
  public fileInfo: File = null;
  public initLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<AdminImportWikiDbWikiDialogComponent>,
    private adminService: AdminWikiService,
    public wikiService: WikiService,
    private status: StatusService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: AdminImportWikiDbWikiDialogData) {
  }

  close(fetch: boolean = false): void { this.dialogRef.close(fetch); }

  public import() {
    this.adminService.importDb(this.exportDto)
      .then(_ => {
        this.close(true);
        this.status.setMessage('Wiki DB updated', 'Success');
      })
      .catch(err => this.status.setError(err, true));
  }

  //
  // File drop-zone functions
  //
  public fileChange(files: FileList) {
    this.contentReady = false;
    this.fileInfo = files[0];
    this.parseZip(this.fileInfo)
      .then(content => {
        this.exportDto = content;
        this.contentReady = true;
      });
  }

  public allowDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  public drop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const inputElement = <HTMLInputElement> (document.querySelector('input[type=file]'));
    inputElement.files = event.dataTransfer.files;
    this.fileChange(inputElement.files);
    const box = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    box.classList.remove('active');
  }

  public toggleDragOver(state: boolean) {
    const element = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    if (state) {
      element.classList.add('active');
    } else {
      element.classList.remove('active');
    }
  }

  private async parseZip(data: File): Promise<WikiExportDTO> {
    const dto = Object.assign(new WikiExportDTO(), <WikiExportDTO> { Pages: [], Assets: [] });
    var zip = new JSZip();
    await zip.loadAsync(data)
      .then((zipContent: JSZip) => {
        Object.keys(zipContent.files).forEach(filename => {
          zip.file(filename)
            .async('text')
            .then(content => {
              const parsed = JSON.parse(content);
              switch (true) {
                case filename.startsWith('_Asset'):
                  dto.Assets = parsed;
                  break;
                default:
                  dto.Pages.push(parsed);
                  break;
              }
            });
        });
      });
    return dto;
  }
}
