import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AddDataTab, NavigateToAddData, NavigateToHome } from '@core/actions/navigation.actions';
import { DataProvider } from '@core/constants/provider-definitions';
import { OpenIdFinalizeDTO } from '@core/services/open-id/entities/open-id-finalize.dto';
import { OpenIdFrontendService } from '@core/services/open-id/open-id.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ProviderFrontendService } from '@core/store/providers/provider.frontend.service';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { SetupOpenIdIntegrationState } from '@shared/modules/dialogs/security/openid/setup-integration/setup-openid-integration.dialog';
import { StorageUtils } from '@shared/utils/storage.utils';

export enum OpenIdStorageKeys {
  forecastId = 'openid.forecastId',
  provider = 'openid.provider'
}

@Component({
  selector: 'indicio-openid-landing',
  templateUrl: './openid-landing.component.html',
  styleUrls: ['./openid-landing.component.less']
})
export class OpenIdLandingComponent {

  constructor(
    private route: ActivatedRoute,
    private openIdService: OpenIdFrontendService,
    private status: StatusService,
    private store: Store,
    private dialogService: DialogService,
    private providerService: ProviderFrontendService,
    private clientService: ClientFrontendService
  ) {
    this.handleRequest();
  }

  private handleRequest() {
    const dto: OpenIdFinalizeDTO = {
      Code: this.route.snapshot.queryParams['code'],
      Scope: this.route.snapshot.queryParams['scope'],
      SessionState: this.route.snapshot.queryParams['session_state'],
      State: this.route.snapshot.queryParams['state'],
    };
    this.status.setMessage('Macrobond integration finalizing...', 'Success');
    this.openIdService.finalizeSetup(DataProvider.macrobondapi, dto)
      .then(() => this.providerService.getRemoteSources())
      .then(() => {
        this.status.setMessage('Macrobond integration complete', 'Success');
        this.dialogService.openSetupOpenIdIntegrationDialog({
          ButtonText: 'Ok',
          Title: 'Connected to Macrobond',
          Text: 'Your macrobond account has been successfully connected to Indicio. You can now use the macrobond integration.',
          State: SetupOpenIdIntegrationState.COMPLETE
        });

        const prevForecast = StorageUtils.get(OpenIdStorageKeys.forecastId);

        if (this.clientService.activeCompany.LicenseType !== 'Macrobond' && prevForecast) {
          this.store.dispatch(new NavigateToAddData(prevForecast, AddDataTab.macrobond));
          StorageUtils.remove(OpenIdStorageKeys.forecastId);
        } else {
          this.store.dispatch(new NavigateToHome);
        }
      })
      .catch(err => {
        this.status.setMessage('Macrobond integration failed.', 'Error');
        this.store.dispatch(new NavigateToHome);
        throw err;
      });
  }

}
