import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CopyForecastDTO } from '@core/store/forecast/dtos/forecast/copy-forecast-dto';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { CopyForecastModalOpts } from './copy-forecast-modal.options';


@Component({
  selector: 'indicio-copy-forecast-modal',
  templateUrl: './copy-forecast-modal.component.html',
  styleUrls: ['./copy-forecast-modal.less']
})
export class CopyForecastModalComponent extends ModalModelComponent {

  copyDto: CopyForecastDTO = null;
  projects: ProjectModel[] = [];
  forecast: ForecastModel = null;
  forecastVersion: ForecastVersionModel;
  forecastId: string;
  projectId: string;

  nowcastedMainVariable: boolean;

  forecastNameMustChange: boolean;

  constructor(
    protected store: Store,
    private forecastService: ForecastFrontendService,
    private projectService: ProjectFrontendService,
    private clientService: ClientFrontendService,
    public envService: EnvironmentService,
    private statusService: StatusService,
    private router: Router,
  ) {
    super();
  }

  public setOptions(options: CopyForecastModalOpts) {
    this.copyDto = new CopyForecastDTO();

    const fc = this.forecastService.getOrFetchForecast(options.forecastId);
    const pj = this.projectService.fetchAllProjects(this.clientService.activeCompanyId);
    Promise.all([fc, pj]).then(([fcx, pjx]) => {
      this.forecast = fcx;
      this.projects = pjx.filter(x => x.hasPermission('CAN_CREATE_FORECAST'));
      this.copyDto.Name = `Copy: ${fcx.Name}`;
      this.copyDto.ForecastVersionId = fcx.activeVersionId;
      this.copyDto.ForecastId = fcx.ForecastId;
      this.copyDto.ProjectId = fcx.ProjectId;
      return this.forecastService.getOrFetchForecastVersion(fcx.activeVersionId);
    }).then(version => {
      this.forecastVersion = version;
      this.nowcastedMainVariable = !!version.ForecastVariable.Nowcast || version.ForecastVariable.NowcastOptions.NeedNowcast;
      if (this.nowcastedMainVariable) {
        this.copyDto.IncludeScenarios = false;
      }

      this.checkName();
    }).catch(err => {
      this.statusService.setError(err, true);
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public toggleIndicators() { this.copyDto.IncludeIndicators = !this.copyDto.IncludeIndicators; }
  public toggleAssessments() { this.copyDto.IncludeAssessments = !this.copyDto.IncludeAssessments; }
  public toggleScenarios() { this.copyDto.IncludeScenarios = !this.copyDto.IncludeScenarios; }
  public toggleEvents() { this.copyDto.IncludeEvents = !this.copyDto.IncludeEvents; }

  public changeForecastName(newName: string) {
    this.copyDto.Name = newName.trim();
    this.checkName();
  }

  public changeProject(selection: MatSelectChange) {
    this.copyDto.ProjectId = selection.value;
    this.checkName();
  }

  public onSubmit() {
    this.pending = true;
    this.forecastService.copyForecast(this.copyDto)
      .then(copy => {
        this.close();
        this.statusService.setMessage('Forecast copied successfully', 'Success');
        this.router.navigateByUrl(`/forecast/${copy.ForecastId}/(forecast:overview)?copy=true`);
      })
      .catch(error => {
        this.statusService.setError(error, true);
      })
      .finally(() => {
        this.pending = false;
      });
  }

  private checkName() {
    this.projectService.getOrFetchProject(this.clientService.activeCompanyId, this.copyDto.ProjectId).then(project => {
      this.forecastService.forecastNameConflict(this.copyDto.Name, project, this.forecastId).then(notOk => {
        if (notOk) {
          this.forecastNameMustChange = true;
        } else {
          this.forecastNameMustChange = false;
        }
      });
    });
  }
}



