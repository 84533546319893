import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpStatusCodes } from '@core/constants/http-status-codes.constants';
import { StatusService } from '@core/services/status/status.service';
import { AuthBackendService } from '@core/store/auth/auth.backend.service';
import { SecurityUtils } from '@shared/utils/security.utils';


@Component({
  selector: 'indicio-password-reset',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  code: string;
  codeValid = false;
  error = false;
  password2: string;
  password: string;
  success = false;
  showPass = false;
  passwordStrength = -1;

  links = require('@root/src/links.json');

  constructor(
    private authService: AuthBackendService,
    private status: StatusService,
    private router: Router
  ) { }

  ngOnInit() {
    const path = this.router.url;
    this.code = path.replace(/.+reset-password\/?/g, '');
    if (this.code === 'test') {
      this.codeValid = true;
      this.status.setMessage('You are accessing a test-page', 'Error');
      return;
    }
    this.authService.checkResetPasswordCode(this.code)
      .then(() => {
        this.codeValid = true;
      })
      .catch(codeError => {
        if (codeError.status === HttpStatusCodes.NOT_FOUND) {
          this.status.setMessage('Code not found or invalid', 'Warning');
        }
        this.codeValid = false;
        this.backToLogin();
      });
  }

  public backToLogin() {
    this.router.navigate(['/login']);
  }

  public addGeneratedPassword() {
    const newpassword = SecurityUtils.generatePassword();
    this.password = newpassword;
    this.password2 = newpassword;
    this.checkPassword();
  }

  public showPassword() {
    this.showPass = !this.showPass;
  }

  public checkPassword() {
    const passwordCategory = SecurityUtils.StrengthChecker(this.password);
    this.passwordStrength = passwordCategory;
  }

  public resetPassword() {
    const pwCtrl = SecurityUtils.checkPass(this.password, this.password2);
    if (pwCtrl) {
      this.status.setMessage(pwCtrl, 'Warning');
      return;
    }

    this.authService.resetPassword(this.code, this.password)
      .then(() => {
        this.success = true;
        this.status.setMessage('Successfully updated password.', 'Success');
        this.router.navigate(['/login'], { queryParams: { show: true } });
      })
      .catch(errorChanging => {
        this.status.setError(errorChanging);
        this.error = true;
      });
  }
}
