import { ForecastResultModel } from './entities/forecast-result.model';

export namespace ForecastResultActions {

  export class NewForecastResult {
    public static readonly type = '[Forecast] fetch new forecast result';
    constructor(public forecastVersionId: string, public forecastResultId: string) { }
  }

  export class SavedForecastResult {
    public static readonly type = '[Forecast] saved forecast result';
    constructor(public forecastResult: ForecastResultModel) { }
  }

  export class RemovedForecastResult {
    public static readonly type = '[Forecast] removed forecast result';
    constructor(public forecastResultId: string) { }
  }

}
