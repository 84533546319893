import { Def } from '@core/decorators/def';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Periodicity } from '@modules/lang/types/periodicity';

export class ForecastVariableNowcastOptionsModel {

  @Def() NeedNowcast: boolean;
  @Def() CanNowcast: boolean;
  @Def() Message: string;
  @Def() SourcePeriodicity: PeriodicityType;
  @Def() SourceLastValueDate: Date;
  @Def() RecommendedPeriodicity: string;
  @Def() Periodicities: NowcastPeriodicity[] = [];

  periodicity: Periodicity = null;
  recommendedPeriodicity: Periodicity = null;
}

export class NowcastPeriodicity {
  Main: boolean;
  Periodicity: PeriodicityType;
  MissingDataPoints: number;
  LastValueDate: Date;
  RequiredValueDate: Date;
}
