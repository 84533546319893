
import { UnivariateModelName } from '@modules/lang/language-files/stat-models';
import { ModelName } from '@modules/lang/types/model-name';
import { FetchStatModelPusherArgs } from './dtos/stat-model-action.dtos';
import { ForecastVersionHistoricDataModel } from './dtos/stat-model-historic-data.dto';
import { StatModelFilesDTO } from './dtos/stat-model.dto';
import { StatModel } from './stat-model.model';

export namespace StatModelActions {
  export class FetchStatModel {
    public static readonly type = '[Stat-model] Fetch stat model result';
    constructor(public forecastVersionId: string, public data: FetchStatModelPusherArgs) { }
  }
}

export namespace MultivariateActions {
  export class GetHistoricDataSuccess {
    public static readonly type = '[Multivariate] Get multivariate historic data success';
    constructor(public forecastVersionId: string, public data: ForecastVersionHistoricDataModel) { }
  }

  export class ClearHistoricData {
    public static readonly type = '[Multivariate] Clear historic data';
    constructor(public forecastVersionId: string) { }
  }

  export class BuiltTransformsSet {
    public static readonly type = '[Multivariate] Built transformations set';
    constructor() { }
  }
}

export class GetMultivariateModelSuccessAction {
  public static readonly type = '[Multivariate] Get multivariate model success';
  constructor(public model: StatModel) { }
}

export class RemoveMultivariateResultsAction {
  public static readonly type = '[Multivariate] Remove multivariate result';
  constructor(public forecastVersionId: string) { }
}

export class RemoveMultivariateModelAction {
  public static readonly type = '[Multivariate] Remove multivariate models';
  constructor(public forecastVersionId: string, public modelName: ModelName) { }
}

export class GetMultivariateModelPlotImagesSuccess {
  public static readonly type = '[Multivariate] Get multivariate plot image model dtos';
  constructor(public forecastVersionId: string, public dtos: StatModelFilesDTO[]) { }
}

export class GetMultivariateModelTextFilesSuccess {
  public static readonly type = '[Multivariate] Get multivariate text files model dtos';
  constructor(public forecastVersionId: string, public dtos: StatModelFilesDTO[]) { }
}

export class GetUnivariateModelSuccessAction {
  public static readonly type = '[UNIVARIATE] Get univariate model success';
  constructor(public model: StatModel) { }
}

export class RemoveUnivariateResultsAction {
  public static readonly type = '[UNIVARIATE] Remove univariate models';
  constructor(public forecastVersionId: string) { }
}

export class RemoveUnivariateModelAction {
  public static readonly type = '[UNIVARIATE] Remove univariate model';
  constructor(public forecastVersionId: string, public modelName: UnivariateModelName) { }
}

export class GetUnivariateModelPlotImagesSuccess {
  public static readonly type = '[UNIVARIATE] Get univariate plot image model dtos';
  constructor(public forecastVersionId: string, public dtos: StatModelFilesDTO[]) { }
}

export class GetUnivariateModelTextFilesSuccess {
  public static readonly type = '[UNIVARIATE] Get univariate text files model dtos';
  constructor(public forecastVersionId: string, public dtos: StatModelFilesDTO[]) { }
}
