<indicio-dialog class="update-variables-dialog">
  <h2 mat-dialog-title>{{ title }}
    <div class="crop-string subtitle"
         [title]="opts.ObjectName"
         *ngIf="!isLoading">
      <span class="capitalize">{{ opts.type }}</span>: {{ opts.ObjectName }}
    </div>
  </h2>

  <mat-dialog-content>
    <p>
      This will update the listed forecast variables with the latest observations available from their respective source
      variable.<br>
    </p>
    <ng-container *ngIf="!!updateInfos?.length; else empty"
                  [ngTemplateOutlet]="contentTemplate">
    </ng-container>
  </mat-dialog-content>

  <div mat-dialog-actions>
    <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
  </div>
</indicio-dialog>

<ng-template #contentTemplate>
  <indicio-data-table columnTemplate="50px 1fr 120px">
    <div title-row>
      <div column>
        <span title>#</span>
      </div>
      <div column>
        <span title>Name</span>
      </div>
      <div column>
        <span title>Status</span>
      </div>
    </div>
    <ng-container>
      <ng-container *ngFor="let item of updateInfos">
        <div row
             style="--column-template: 1fr 120px">
          <div column>
            <span>Forecast: {{ item.ForecastName }}</span>
          </div>
          <div column
               center>
            <span>Variables: {{ item.numComplete || 0 }}/{{ item.Variables.length }}</span>
          </div>
        </div>
        <div row
             *ngFor="let fVar of item.Variables; let i = index">
          <div column
               center>
            <span>#{{ i + 1 }}</span>
          </div>
          <div column
               class="crop-string">
            <span>{{ fVar.VariableName }}</span>
          </div>
          <div column
               class="status-column"
               center>
            <span [attr.data-status]="fVar.status">{{ fVar.status || 'Ready to update' }}</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </indicio-data-table>
  <div class="grid-system"
       style="--grid-template: 1fr 220px; margin-top: 20px;">
    <div>
      <span *ngIf="done"
            class="italic">
        All forecast variables updated
      </span>
    </div>
    <div class="text-right"
         style="font-weight: 400; font-size: 16px;">
      <span *ngIf="!inProgress && !done">{{ count }} variable(s) to update</span>
      <span *ngIf="inProgress || done">{{ synced }} / {{ count }} variables updated</span>
    </div>
  </div>
</ng-template>

<ng-template #empty>
  <div class="center-horizontal-and-vertical"
       style="height: 80px; background-color: var(--indicio-modal-alt-background-color, #F5F5F5)">
    No new observations available for the forecast variables found in this {{ opts.type }}
  </div>
</ng-template>

<ng-template #actionsTemplate>
  <button mat-button
          (click)="onNoClick()">Cancel</button>
  <button mat-flat-button
          [disabled]="inProgress"
          (click)="okText === 'Close' ? onNoClick() : update()">
    <i *ngIf="inProgress"
       class="ion-load-c spinner"></i>
    <span *ngIf="!inProgress">{{ okText }}</span>
  </button>
</ng-template>
