<h1 mat-dialog-title>Apply a tag for all variables</h1>
<div mat-dialog-content>

  <indicio-tag-dropdown [filteredTags]="filteredTagsModels"
                        title="Name"
                        [result]="result"
                        (addNewEvent)="onAddNew($event)">
  </indicio-tag-dropdown>

</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">Cancel</button>
  <div class="green"
       *ngIf="statusMsg">{{ statusMsg }}</div>
  <button mat-flat-button
          [mat-dialog-close]="result"
          [ngClass]="{disabled: result.length === 0}"
          cdkFocusInitial>
    Save
  </button>
</div>
