import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'indicio-mb-set-description',
  template: `
      <mat-form-field>
        <mat-label *ngIf="!showLoader">Description</mat-label>
        <mat-label *ngIf="showLoader"> <i class="ion-load-c spinner"></i> Getting description from
          macrobond...</mat-label>
        <textarea matInput
                  required
                  [ngModel]="currentDescription"
                  (ngModelChange)="newDescription.emit($event)"
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="1">
        </textarea>
      </mat-form-field>
  `
})
export class MacrobondSetDescriptionComponent {

  @Input() currentDescription: string;
  @Input() showLoader: boolean = true;

  @Output() newDescription = new EventEmitter<string>();
}
