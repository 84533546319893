import { Injectable } from '@angular/core';
import { FredDataSerieModel } from '@core/store/providers/fred/fred-dataserie.model';
import { FredStateCategoryMetaModel } from '@core/store/providers/fred/fred-state-category-meta.model';
import { FredStateCategoryModel } from '@core/store/providers/fred/fred-state-category.model';
import { FredTagModel } from '@core/store/providers/fred/fred-tag.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StorageUtils } from '@shared/utils/storage.utils';
import {
  ClearFredQueueSuccess, GetFredCategoryDataSeriesSuccessAction, GetFredCategorySubCategoriesSuccessAction, GetFredStoredCategoriesMetaAction, GetFredStoredTagsAction, GetFredTagsSuccessAction, ToggleFredSerieInQueueSuccess
} from './fred.actions';


class FredStateModel {
  Categories: FredStateCategoryModel[];
  CategoriesMeta: FredStateCategoryMetaModel[];
  Tags: FredTagModel[];
  Queue: FredDataSerieModel[];
}

@State<FredStateModel>({
  name: 'fred',
  defaults: {
    Categories: [],
    CategoriesMeta: [],
    Tags: [],
    Queue: []
  }
})
@Injectable()
export class FredState {
  private storedCategories = 'fred.categories';
  private storedTags = 'fred.tags';

  @Selector()
  static categories(state: FredStateModel) { return state.Categories; }

  @Selector()
  static categoriesMeta(state: FredStateModel) { return state.CategoriesMeta; }

  @Selector()
  static tags(state: FredStateModel) { return state.Tags; }

  @Selector()
  static queue(state: FredStateModel) { return state.Queue; }

  @Action(GetFredCategorySubCategoriesSuccessAction)
  getFredCategorySubCategories(ctx: StateContext<FredStateModel>, action: GetFredCategorySubCategoriesSuccessAction) {
    const state = ctx.getState();
    const categories = state.Categories;
    const categoriesMeta = state.CategoriesMeta;
    const actionCategory = Object.faMapTo(new FredStateCategoryModel, { Id: +action.categoryId, Categories: action.categories });
    const actionCategoriesMeta = action.categories.map(c => {
      return Object.faMapTo(new FredStateCategoryMetaModel, { Id: c.id, Name: c.name, ParentId: +c.parent_id });
    });
    const categoriesStoredMeta = this.getStoredCategories();
    if (categoriesStoredMeta.length) {
      actionCategoriesMeta.forEach(c => categoriesStoredMeta.addOrUpdate(c));
      localStorage.setItem(this.storedCategories, JSON.stringify(categoriesMeta));
    } else {
      localStorage.setItem(this.storedCategories, JSON.stringify(actionCategoriesMeta));
    }
    actionCategoriesMeta.forEach(c => categoriesMeta.addOrUpdate(c));
    categories.addOrUpdate(actionCategory);
    ctx.patchState({ Categories: categories, CategoriesMeta: categoriesMeta });
  }

  @Action(GetFredCategoryDataSeriesSuccessAction)
  getFredCategoryDataSeries(ctx: StateContext<FredStateModel>, action: GetFredCategoryDataSeriesSuccessAction) {
    const categories = ctx.getState().Categories;
    const stateCategory = Object.faMapTo(new FredStateCategoryModel, { Id: +action.categoryId, Series: action.series });
    categories.addOrUpdate(stateCategory);
    ctx.patchState({ Categories: [...categories] });
  }

  @Action(GetFredStoredCategoriesMetaAction)
  setStoredCategories(ctx: StateContext<FredStateModel>) {
    let categoriesMeta = JSON.parse(localStorage.getItem(this.storedCategories));
    if (!categoriesMeta) { return; }
    categoriesMeta = this.getStoredCategories();
    ctx.patchState({ CategoriesMeta: categoriesMeta });
  }

  @Action(GetFredTagsSuccessAction)
  getFredTagsSuccessAction(ctx: StateContext<FredStateModel>, action: GetFredTagsSuccessAction) {
    const tags = ctx.getState().Tags;
    action.tags.forEach(tag => tags.addOrUpdate(tag));

    StorageUtils.set(this.storedTags, tags, 'sessionStorage');

    ctx.patchState({ Tags: tags });
  }

  @Action(GetFredStoredTagsAction)
  getFredStoredTagsAction(ctx: StateContext<FredStateModel>, _action: GetFredStoredTagsAction) {
    const tags = StorageUtils.get(this.storedTags, 'sessionStorage');
    if (!tags) { return; }
    tags.forEach(tag => Object.faMapTo(new FredTagModel, tag));
    ctx.patchState({ Tags: tags });
  }

  @Action(ToggleFredSerieInQueueSuccess)
  toggleFredSerieInQueue(ctx: StateContext<FredStateModel>, action: ToggleFredSerieInQueueSuccess) {
    const queue = ctx.getState().Queue;
    const index = queue.findIndex(s => s.id === action.serie.id);
    if (index === -1) {
      queue.push(action.serie);
    } else {
      if (action.imported === true) {
        queue[index].imported = true;
      } else {
        queue.splice(index, 1);
      }
    }
    ctx.patchState({ Queue: queue });
  }

  @Action(ClearFredQueueSuccess)
  clearFredQueueSuccess(ctx: StateContext<FredStateModel>, _action: ClearFredQueueSuccess) {
    ctx.patchState({ Queue: [] });
  }

  private getStoredCategories() {
    let categories: any[] = JSON.parse(localStorage.getItem(this.storedCategories)) || [];
    categories = categories.map(c => Object.faMapTo(new FredStateCategoryMetaModel, c));
    return categories;
  }
}
