import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { NotificationDTO } from '@core/store/notifications/dtos/notification-dto';
import { NotificationMessageDTO } from '@core/store/notifications/dtos/notification-message-dto';
import { NotificationSettingsDTO } from '@core/store/notifications/dtos/notification-settings-dto';
import { NotificationMessageModel } from '@core/store/notifications/notification-message.model';
import { NotificationSettingsModel } from '@core/store/notifications/notification-settings.model';
import { NotificationBehaviourService } from '@core/store/notifications/notification.behaviour.service';
import { NotificationModel } from '@core/store/notifications/notification.model';
import { NotificationTypes } from '@core/store/notifications/notification.types';
import { DateUtils } from '@shared/utils/date.utils';
import { SaveNotificationSettingsDTO } from './dtos/save-notification-settings-dto';

const ONLY_SHOW_THESE_NOTIFICATIONS_IN_SHARED_PROJECTS = [
  NotificationTypes['new-project'],
  NotificationTypes['new-forecast'],
  NotificationTypes['new-forecast-result']
];

const FORCE_SHOW_SELF = [
  NotificationTypes['new-project-invite'],
  NotificationTypes['new-company-invite']
];

@Injectable({
  providedIn: 'root'
})
export class NotificationMapper {

  constructor(
    private behaviour: NotificationBehaviourService,
    private clientService: ClientFrontendService,
    private envService: EnvironmentService
  ) { }

  public map(dto: NotificationDTO, isNew = false, settings?: NotificationSettingsModel): NotificationModel {
    const model = Object.faMapTo(new NotificationModel(), dto);
    model.TimeSeen = DateUtils.newNullableDate(dto.TimeSeen);
    model.Occurred = DateUtils.newDate(dto.Occurred);
    model.Message = this.mapMessage(dto.Message);

    const now = DateUtils.newMoment();
    const occMoment = DateUtils.newMoment(model.Occurred);
    const offset = occMoment.utcOffset();
    const timezonedOcc = occMoment.add(offset, 'minutes');

    if (settings) {
      if (settings.hiddenTopics.includes(model.Message.Type)) {
        model.forceHide = true;
      }
      if (settings.HideSystem === true && model.Message.ActorClientName === 'System') {
        model.forceHide = true;
      }
    }

    if (dto.Message.SharedProject === true) {
      let hide = true;
      if (ONLY_SHOW_THESE_NOTIFICATIONS_IN_SHARED_PROJECTS.includes(dto.Message.Type) && dto.Message.ActorClientId !== this.clientService.client.ClientId) {
        hide = false;
      }
      model.forceHide = hide;
    }

    if (dto.Message.ActorClientId === this.clientService.client.ClientId && !FORCE_SHOW_SELF.includes(dto.Message.Type)) {
      model.forceHide = true;
    }

    if (timezonedOcc.isSameOrAfter(now)) {
      model.displayDate = 'now';
    } else {
      model.displayDate = timezonedOcc.fromNow();
    }

    this.behaviour.setupNotification(model, isNew);
    return model;
  }

  public mapMessage(dto: NotificationMessageDTO): NotificationMessageModel {
    const model = Object.faMapTo(new NotificationMessageModel(), dto);
    return model;
  }

  public mapSettings(dto: NotificationSettingsDTO) {
    const defaultTypes = this.envService.NotificationTypes;
    const defaultCategories = {};

    for (let i = 0, n = defaultTypes.length; i < n; i++) {
      const type = defaultTypes[i];
      if (!type.Extra) { continue; }
      if (!defaultCategories[type.Extra]) {
        defaultCategories[type.Extra] = {};
      }
      defaultCategories[type.Extra][type.Value] = true;
    }

    const hideSystem = dto.HideSystem;
    delete dto.HideSystem;

    const dtoCategories = Object.keys(dto);
    const settings = Object.faMapTo(new NotificationSettingsModel(), defaultCategories);
    const categories = Object.assign({}, defaultCategories);

    settings.HideSystem = hideSystem;

    for (let i = 0, n = dtoCategories.length; i < n; i++) {
      const defaultCategory = defaultCategories[dtoCategories[i]];
      const category = dtoCategories[i];
      const types = Object.keys(defaultCategory);
      for (let j = 0, m = types.length; j < m; j++) {
        const settingForType = dto[category][types[j]] !== undefined ? dto[category][types[j]] : defaultCategory[types[j]];
        try {
          categories[dtoCategories[i]][types[j]] = settingForType;
        } catch (error) {
          console.error('missing category or type for a NotificationSetting', dtoCategories[i], types[j]);
        }
      }
    }

    settings.categories = categories;

    return settings;
  }

  public toSaveSettings(model: NotificationSettingsModel) {
    const dto = Object.faMapTo(new SaveNotificationSettingsDTO(), model);
    return dto;
  }
}
