import { Injectable } from '@angular/core';
import { ClearStateAction } from '@core/store/auth/auth.actions';
import {
  GetAllNotificationsSuccessAction, GetNotificationSuccessAction,
  MarkAllNotificationsAsRead, RemoveAllNotificationsAction, RemoveNotificationAction, ToggleNotificationReadAction, ToggleNotificationsHiddenAction
} from '@core/store/notifications/notification.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OrderByDatePipe } from '@shared/modules/pipes';
import { DateUtils } from '@shared/utils/date.utils';
import { NotificationModel } from './notification.model';


class NotificationStateModel {
  Notifications: NotificationModel[];
}
@State<NotificationStateModel>({
  name: 'notification',
  defaults: {
    Notifications: []
  }
})
@Injectable()
export class NotificationState {
  orderPipe: OrderByDatePipe;
  constructor() {
    this.orderPipe = new OrderByDatePipe;
  }

  @Selector()
  static notifications(state: NotificationStateModel) {
    return state.Notifications.filter(notification => notification.hidden === false && notification.forceHide === false);
  }

  @Selector([NotificationState.notifications])
  static unreadNotifications(_state: NotificationStateModel, notifications: NotificationModel[]) {
    return notifications.filter(notification => notification.TimeSeen === null);
  }

  @Action(GetAllNotificationsSuccessAction)
  getAllNotificationsSuccessAction(
    ctx: StateContext<NotificationStateModel>,
    action: GetAllNotificationsSuccessAction
  ) {
    let notifications = ctx.getState().Notifications;
    action.notifications.forEach(n => notifications.addOrUpdate(n));

    notifications = this.orderPipe.transform(notifications, '-Occurred');

    ctx.patchState({ Notifications: notifications });
  }

  @Action(GetNotificationSuccessAction)
  getNotificationSuccessAction(ctx: StateContext<NotificationStateModel>, action: GetNotificationSuccessAction) {
    const notifications = ctx.getState().Notifications;
    notifications.addOrUpdate(action.notification, true);
    ctx.patchState({ Notifications: [...notifications] });
  }

  @Action(ToggleNotificationReadAction)
  toggleNotificationReadAction(ctx: StateContext<NotificationStateModel>, action: ToggleNotificationReadAction) {
    const notifications = ctx.getState().Notifications;
    notifications.addOrUpdate(action.notification);
    ctx.patchState({ Notifications: [...notifications] });
  }

  @Action(MarkAllNotificationsAsRead)
  markAllNotificationsAsRead(ctx: StateContext<NotificationStateModel>, _action: MarkAllNotificationsAsRead) {
    const notifications = ctx.getState().Notifications;
    notifications.forEach(n => n.TimeSeen = DateUtils.newDate());
    ctx.patchState({ Notifications: [...notifications] });
  }

  @Action(RemoveNotificationAction)
  removeNotificationAction(ctx: StateContext<NotificationStateModel>, action: RemoveNotificationAction) {
    const notifications = ctx.getState().Notifications;
    const index = notifications.findIndex(n => n.NotificationId === action.notification.NotificationId);
    if (index !== -1) { notifications.splice(index, 1); }
    ctx.patchState({ Notifications: [...notifications] });
  }

  @Action(RemoveAllNotificationsAction)
  removeAllNotificationsAction(
    ctx: StateContext<NotificationStateModel>,
    _action: RemoveAllNotificationsAction
  ) {
    ctx.patchState({ Notifications: [] });
  }

  @Action(ToggleNotificationsHiddenAction)
  toggleNotificationsHiddenAction(ctx: StateContext<NotificationStateModel>, action: ToggleNotificationsHiddenAction) {
    const notifications = ctx.getState().Notifications;
    notifications.forEach(notification => {
      if (action.hiddenTopics.indexOf(notification.Message.Type) !== -1) {
        notification.hidden = true;
      } else {
        notification.hidden = false;
      }
    });
    ctx.patchState({ Notifications: [...notifications] });
  }

  @Action(ClearStateAction)
  logoutUser(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({ Notifications: [] });
  }
}
