import { Def } from '@core/decorators/def';

export class NewsPostMetaModel {
  @Def() NewsPostId: string;
  @Def() Title: string;
  @Def() Preview: string;
  @Def() Body: string;
  @Def() AuthorName: string;
  @Def() AuthorEmail: string;
  @Def() CommentCount: number;
  @Def() HideAuthor: boolean;
  @Def() Priority: number;
  @Def() DisableComments: boolean;
  @Def() CreatedDate: Date;
  @Def() ModifiedDate?: Date;
  @Def() PublishDate?: Date;
  @Def() Hidden: boolean;
}
