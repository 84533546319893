import { Def } from '@core/decorators/def';

export class OxfordDatabankDTO {
  Name: string;
  DatabankCode: string;
  Url: string;
  StartYear: string;
  EndYear: string;
  HasQuarterlyData: string;
  HasAccess: boolean;
  Tree: OxfordDatabankTreeDTO[];
}

export class OxfordDatabankTreeDTO {
  Name: string;
  TreeCode: string;
  Url: string;
}

export class OxfordContentDTO {
  @Def() Categories: OxfordCategoryDTO[];
  @Def() Variables: OxfordVariableDTO[];

  // frontend mappings
  hasChildren = false;
  hasVariables = false;
  id: string;
}

export class OxfordIndicatorDTO {
  @Def() Name: string;
  @Def() Code: string;
  @Def() ProductTypeCode: string;
  @Def() DisplayOrder: string;
  @Def() HasAccess: string;
  @Def() Children: OxfordContentDTO;

  // frontend mappings
  @Def() oldData = false;
  @Def() isOpen = false;
  @Def() level = 0;
  @Def() id = null;
  @Def() parentId = null;
}

export class OxfordVariableMetaDTO {
  // VariableCode
  VC: string;
  // Description
  Desc: string;
  // ScaleFactor
  SF: string;
  // HistoricalEndYear
  EndY: string;
  // HistoricalEndQuarter
  EndQ: string;
  // LastUpdate
  Updated: string;
  // SeasonallyAdjusted
  Seas: string;
  // Units
  U: string;
  // Source
  S: string;
  // SourceDescription
  SD: string;
  // HasQuarterly
  HasQ: string;
}

export class OxfordCategoryDTO extends OxfordIndicatorDTO {
  @Def() Type: string;
  @Def() Children: OxfordContentDTO;

  public get children() { return this.Children.Categories; }
}

export class OxfordVariableDTO extends OxfordIndicatorDTO {
  @Def() Type: string;
  @Def() Children: OxfordContentDTO;

  public get children() { return this.Children.Variables; }
}

export class OxfordTreeLocationDTO {
  Name: string;
  Code: string;
  ProductTypeCode: string;
  DisplayOrder: string;
  HasAccess: string;
  Children: OxfordTreeLocationDTO[];

  // frontend mappings
  isOpen = false;
  level = 0;
  id: string;
}

export class OxfordExtraDTO {
  ProductTypeCode: string;
  Code: string;
  HasAccess: boolean;
  EndYear: Date;
  HasQuarterlyData: boolean;
  StartYear: Date;
}
