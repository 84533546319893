import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GithubIssue } from '@core/entities/github.models';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { ErrorReportService } from '@core/services/error-report.service';
import { StatusService } from '@core/services/status/status.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { Store } from '@ngxs/store';
import { BugReportDialogData } from './bug-report.dialog.constants';

@Component({
  selector: 'indicio-bug-report-dialog',
  templateUrl: 'bug-report.dialog.html',
})
export class BugReportDialogComponent extends DialogV2BaseDialog<BugReportDialogComponent> {

  public static Id: string = 'BugReportDialogComponent';

  public subjects = GithubIssue.GithubIssueSubjects;
  public problemTypes = GithubIssue.GithubIssueProblemTypes;

  public issue: GithubIssue.GithubIssueReport;
  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: BugReportDialogData,
    dialogRef: MatDialogRef<BugReportDialogComponent, BugReportDialogData>,
    protected store: Store,
    private envService: EnvironmentService,
    private profileService: ProfileFrontendService,
    private status: StatusService,
    private errorService: ErrorReportService,
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.issue = {
      Subject: null,
      Type: null,
      Text: this.data.Message ? `\n\n\n-----------------------------\n${this.data.Message}` : null,
      User: this.profileService.profile.Email,
      FrontendVersion: this.envService.env.PackageVersion,
      BackendVersion: 'NA',
      IdentityVersion: 'NA',
      ImportVersion: 'NA',
    };
    this.initialized = true;
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public save() {
    this.errorService.postGithubIssue(this.issue)
      .then(() => {
        this.loading = false;
        this.dialogRef.close(true);
        this.status.setMessage('Issue successfully reported. Thank you!', 'Success');
      })
      .catch(error => {
        this.loading = false;
        this.status.setError(error, true);
      });
  }
}
