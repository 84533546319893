import { Def } from '@core/decorators/def';

export class KoladaOUValueModel {
  @Def() count: number;
  @Def() gender: string;
  @Def() status: string;
  @Def() value: number;
}

export class KoladaOUValuePeriodModel {
  @Def() kpi: string;
  @Def() municipality: string;
  @Def() period: number;
  @Def() values: KoladaOUValueModel[];
}

export class KoladaOUValueListModel {
  @Def() count: number;
  @Def() values: KoladaOUValuePeriodModel[];
  uri: string;
}
