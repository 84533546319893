import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { AutoTaskMapper } from './auto-task.mapper';
import { AutoTaskDTO, AutoTaskInfoDTO } from './dtos/auto-task.dto';
import { AutoTaskModel } from './dtos/auto-task.model';


@Injectable({
  providedIn: 'root'
})
export class AutoTaskBackendService {

  constructor(
    private http: HttpService,
    private mapper: AutoTaskMapper
  ) { }

  public getAutoTaskInfo(companyId: string) {
    return this.http.get<AutoTaskInfoDTO>(`company/${companyId}/auto-task/get-info`)
      .then(resp => this.mapper.mapInfo(resp.body));
  }

  public getAllAutoTasks(companyId: string) {
    return this.http.get<AutoTaskDTO[]>(`company/${companyId}/auto-task/get-all`)
      .then(resp => resp.body.map(t => this.mapper.mapTask(t)));
  }

  public getAutoTask(companyId: string, taskId: string) {
    return this.http.get<AutoTaskDTO>(`company/${companyId}/auto-task/${taskId}`)
      .then(resp => this.mapper.mapTask(resp.body));
  }

  public createAutoTask(companyId: string, model: AutoTaskModel) {
    const dto = this.mapper.toCreate(model);
    return this.http.post<AutoTaskDTO>(`company/${companyId}/auto-task`, dto)
      .then(resp => this.mapper.mapTask(resp.body));
  }

  public updateAutoTask(companyId: string, model: AutoTaskModel) {
    const dto = this.mapper.toUpdate(model);
    return this.http.put<AutoTaskDTO>(`company/${companyId}/auto-task`, dto)
      .then(resp => this.mapper.mapTask(resp.body));
  }

  public setActive(companyId: string, model: AutoTaskModel) {
    return this.http.post<boolean>(`company/${companyId}/auto-task/${model.AutoTaskId}/set-active`, model.Active)
      .then(resp => resp.body);
  }

  public triggerAutoTask(companyId: string, taskId: string) {
    return this.http.get(`company/${companyId}/auto-task/${taskId}/trigger`);
  }

  public deleteAutoTask(companyId: string, taskId: string) {
    return this.http.delete(`company/${companyId}/auto-task/${taskId}`);
  }
}
