import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { WikiService } from '@core/store/wiki/wiki.service';
import { StepAhead } from './step-ahead';

@Component({
  selector: 'indicio-step-ahead',
  templateUrl: './step-ahead.component.html',
  styleUrls: ['./step-ahead.component.less']
})
export class StepAheadComponent implements OnChanges {

  @Input() variable: StepAhead.Model;
  @Input() disabled: boolean;
  @Output() changedEvent = new EventEmitter<boolean>();

  //
  public loading: boolean = true;
  public stepWidth: number;
  public stepCount: number;

  constructor(
    public wikiService: WikiService) {
  }

  public ngOnChanges(): void {
    this.update();
  }

  private update() {
    this.stepWidth = this.getStepsWidth();
    this.stepCount = this.variable.dates.length;
    this.loading = false;
  }

  private getStepsWidth() {
    const stepWidth = !!this.variable.factor.abbr ? 110 : 95;
    return stepWidth;
  }

  public setStep(step: number, value: number) {
    this.variable.setStep(step, value);
    this.changedEvent.emit(this.variable.isModified());
  }
}
