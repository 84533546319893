import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVariableNowcastOptionsModel, NowcastPeriodicity } from '@core/store/forecast-variable/models/forecast-variable-nowcast-options.model';
import { CreateNowcastDTO } from '@core/store/forecast/dtos/forecast/create-nowcast-dto';
import { ForecastHelper } from '@core/store/forecast/forecast-helper';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Periodicity } from '@modules/lang/types/periodicity';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { MultiCreateNowcastModalOpts } from './multi-create-nowcast-modal.options';


class NowcastVariableOption {
  MaxForecastingPoints: number;
  startDate: Date;
  forecastPoints = 0;
  inProgress = false;
  status: string = 'Pending';
  variable: ForecastVariableModel;
  nowcastOptions: ForecastVariableNowcastOptionsModel;
  nowcastMainToFullPeriod: boolean;
  sourcePeriodicity: Periodicity;
  naiveOnly: boolean = false;
  forecastPeriodicity: string;
  selectedPeriodicity: NowcastPeriodicity;

  public get mainNowcast() {
    return this.variable.NowcastOptions.Periodicities.find(x => x.Main);
  }

  public get hasTooManyForecastPoints() {
    return this.forecastPoints > this.MaxForecastingPoints;
  }

  public getSelectedValuesCount() {
    if (this.selectedPeriodicity !== null) {
      return this.selectedPeriodicity.MissingDataPoints;
    }
    if (this.nowcastMainToFullPeriod) {
      return this.mainNowcast.MissingDataPoints;
    }
  }

  public getSelectedPeriodicity() {
    if (this.selectedPeriodicity !== null) {
      return this.selectedPeriodicity.Periodicity;
    }
    if (this.nowcastMainToFullPeriod) {
      return this.nowcastOptions.SourcePeriodicity;
    }
  }

  public recommendedPeriodicityText() {
    if (this.nowcastOptions.SourcePeriodicity === this.nowcastOptions.RecommendedPeriodicity) {
      return `We recommend a <b>${this.nowcastOptions.recommendedPeriodicity.Display}</b> periodicity for the nowcast,
    since it is the original periodicity, and therefore should yield the most accurate nowcast.`;
    } else if (this.nowcastOptions.SourcePeriodicity !== this.nowcastOptions.RecommendedPeriodicity) {
      return `We recommend a <b>${this.nowcastOptions.recommendedPeriodicity.Display}</b> periodicity for the nowcast,
      since there are too few data points to nowcast using the variable's original periodicity.`;
    }
  }
}

@Component({
  selector: 'indicio-multi-create-nowcast-modal',
  templateUrl: './multi-create-nowcast-modal.component.html',
  styleUrls: ['./multi-create-nowcast-modal.less'],
})
export class MultiCreateNowcastModalComponent extends ModalModelComponent {

  opts: MultiCreateNowcastModalOpts;
  // Modal variables
  forecastVersion: ForecastVersionModel = null;
  forecast: ForecastModel = null;
  variables: ForecastVariableModel[] = [];
  nowcastMainToFullPeriod = false;

  nowcastVariables: NowcastVariableOption[] = [];
  variablesMissingOptions: ForecastVariableModel[] = [];

  public maxHorizon(periodicity: PeriodicityType) { return this.companyService.maxHorizon(periodicity); }
  public get hasCreatableNowcasts() {
    return this.nowcastVariables
      .filter(x => x.status !== 'Done')
      .filter(x => !x.hasTooManyForecastPoints).length > 0;
  }

  constructor(
    protected store: Store,
    private statusService: StatusService,
    private companyService: CompanyFrontendService,
    private forecastService: ForecastFrontendService,
    private forecastVariableService: ForecastVariableFrontendService,
    public env: EnvironmentService,
    public appearance: AppearanceService
  ) { super(); }

  public setOptions(options: MultiCreateNowcastModalOpts) {
    this.opts = options;

    const fvPromise = this.forecastService.getOrFetchForecastVersion(this.opts.forecastVersionId);
    const vPromise = this.forecastVariableService.getOrFetchByIds(this.opts.forecastVersionId, this.opts.variableIds);

    Promise.all([fvPromise, Promise.all(vPromise)])
      .then(([fv, v]) => {
        this.forecastVersion = fv;
        this.variables = v;
        this.setup();
        this.isLoading = false;
      })
      .catch(err => {
        this.statusService.setError(err);
      });
  }

  public setup() {
    this.variables.filter(x => !!x.NowcastOptions).forEach(vari => {
      const nvar = new NowcastVariableOption();
      nvar.nowcastOptions = vari.NowcastOptions;
      nvar.sourcePeriodicity = this.env.getPeriodicity(nvar.nowcastOptions.SourcePeriodicity);
      nvar.forecastPeriodicity = this.forecastVersion.periodicity.Display;
      nvar.variable = vari;
      if (!vari.IsIndicator && ForecastHelper.canNowcastLastPeriod(this.forecastVersion, vari)) {
        nvar.nowcastMainToFullPeriod = true;
      } else {
        nvar.selectedPeriodicity = nvar.nowcastOptions.Periodicities.find(p => p.Periodicity === nvar.nowcastOptions.RecommendedPeriodicity);
        nvar.forecastPoints = nvar.selectedPeriodicity.MissingDataPoints;
      }

      this.nowcastVariables.push(nvar);
    });

    this.variablesMissingOptions = this.variables.filter(x => !!!x.NowcastOptions);
  }

  setNowcastPeriodicity(variable: NowcastVariableOption, $event: MatSelectChange) {
    variable.selectedPeriodicity = variable.nowcastOptions.Periodicities.find(p => p.Periodicity === $event.value);
  }

  public createNowcast() {
    this.nowcastVariables.filter(x => !x.hasTooManyForecastPoints).forEach(async (ncVariable) => {

      ncVariable.inProgress = true;
      ncVariable.status = 'Creating..';

      const body = new CreateNowcastDTO();
      body.ForecastVariableId = ncVariable.variable.ForecastVariableId;
      body.ForecastVersionId = this.forecastVersion.ForecastVersionId;
      body.Quick = ncVariable.naiveOnly;
      body.Horizon = ncVariable.getSelectedValuesCount();
      body.Periodicity = ncVariable.getSelectedPeriodicity();

      try {
        await this.forecastService.createNowcast(body);
        ncVariable.inProgress = false;
        ncVariable.status = 'Done';
      } catch (e) {
        ncVariable.inProgress = false;
        ncVariable.status = 'Failed';
      }
    });
  }

  onClose() {
    if (this.opts.callback) {
      this.opts.callback();
    }
  }
}
