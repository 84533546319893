import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { TagDTO } from './dtos/tags.dtos';
import { TagModel } from './models/tag.model';
import { TagsMapper } from './tags-mapper';


@Injectable({
  providedIn: 'root'
})
export class TagsBackendService {

  constructor(
    private http: HttpService,
    private mapper: TagsMapper
  ) { }

  public getAllTags(companyId: string) {
    return this.http.get<TagDTO[]>(`company/${companyId}/tags/get-all-tags`)
      .then(({ body }) => body.map(s => this.mapper.mapTag(s)));
  }

  public getTag(tagId: string, companyId: string) {
    return this.http.get<TagDTO>(`/company/${companyId}/tags/${tagId}/get-tag`)
      .then(({ body }) => this.mapper.mapTag(body));
  }

  public createTag(tag: TagModel, companyId: string) {
    const createDto = this.mapper.toCreate(tag);
    return this.http.post<TagDTO>(`company/${companyId}/tags/create-tag`, createDto)
      .then(({ body }) => this.mapper.mapTag(body));
  }

  public updateTag(tag: TagModel) {
    const updateDto = this.mapper.toUpdate(tag);
    return this.http.put<TagDTO>(`company/${tag.CompanyId}/tags/update-tag`, updateDto)
      .then(({ body }) => this.mapper.mapTag(body));
  }

  public deleteTag(tag: TagModel) {
    return this.http.delete<null>(`company/${tag.CompanyId}/tags/${tag.TagId}/delete-tag`)
      .then(() => { });
  }
}
