import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateFilterFn, MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateUtils } from '@shared/utils/date.utils';
import * as moment from 'moment';
import { DatePickerHint } from '../date-picker.entities';

export type StartViewType = 'year' | 'month' | 'multi-year';

@Component({
  selector: 'indicio-date-picker-base',
  template: ''
})
export abstract class DatePickerBaseComponent {

  @ViewChild('picker') picker: MatDatepicker<moment.Moment>;

  public endDate = DateUtils.newMoment().add(10, 'years');
  public startDateModel: moment.Moment = null;

  /**
   * Any inputs added here needs to be added and passed down in the date picker wrapper as well
   */

  @Input() public placeholder = '';
  @Input() public min: Date = DateUtils.newDate('1980-01-01');
  @Input() public max: Date = this.endDate.toDate();
  @Input() public value: moment.Moment | Date = null;
  @Input() public disabled: boolean = false;
  @Input() public label: string;
  @Input() public hint: DatePickerHint;
  @Input() public style: string;

  @Output() public selectDateEvent = new EventEmitter();

  public startView: StartViewType = 'month';
  public panelClass: string = null;
  public yearSelected: Function;
  public monthSelected: Function;
  public filter: DateFilterFn<Date | moment.Moment> = null;

  public closeDatePicker(event: moment.Moment) {
    this.picker.close();
    this.changeDate(event);
  }

  public inputEvent(event: MatDatepickerInputEvent<moment.Moment>) {
    if (!event.value) { return; }
    this.changeDate(event.value);
  }

  public changeDate(date: moment.Moment) {
    this.value = date;
    this.selectDateEvent.emit(date);
  }
}
