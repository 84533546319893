if (!environment.consoleLogging) {
  if (window) {
    window.console.log = function () { };
    window.console.dir = function () { };
  }
}

// Font Awesome
require('@app/icons');

// Angular specific
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/environment';
// Indicio specific
import { AppModule } from '@modules/root/app.module';
import { BrowserUtils } from '@shared/utils/browser.utils';


// Check browser...
if (!BrowserUtils.isSupported()) {
  console.error('Browser not supported');
  if (window.stop) {
    (<HTMLElement> document.querySelector('.unsupported-browser')).style.opacity = '1';
    window.stop();
  }
  (<HTMLElement> document.querySelector('.unsupported-browser')).style.opacity = '1';
  throw new Error('Browser not supported');
}

(<HTMLElement> document.querySelector('.unsupported-browser')).remove();
window.localStorage.setItem('browser.checked', 'true');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
