import { Def } from '@core/decorators/def';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';

export class RemoteDataRequestDTO {
  @Def() RemoteUri: string;
  @Def() Provider: string;
  @Def() RemoteReferenceId: string;
  @Def() Name: string;
  @Def() MetaData: any;
  @Def() Query: any;
  @Def() Data: any;
  @Def() ValueType: string;
  @Def() User?: string;
  @Def() Password?: string;
  @Def() Periodicity?: PeriodicityType;
  @Def() Extras?: object;
}
