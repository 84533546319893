export class StatusModel {
  message: string;
  type: string;
  color: string;
  backgroundColor: string;
  showInModal: boolean;
  showOnPage: boolean;
  show: boolean;
  errors: string[];
  reportBug: boolean;
  timeout: number;
}
