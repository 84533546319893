import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface TextDialogData {
  Title: string;
  Text: string;
  MathJax?: boolean;
}

@Component({
  selector: 'indicio-text-dialog',
  templateUrl: 'text.dialog.html',
})
export class TextDialogComponent {
  public static Id: string = 'TextDialogComponent';

  public answer: string;
  public mathJax: boolean;

  constructor(
    public dialogRef: MatDialogRef<TextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextDialogData) {
    this.mathJax = data.MathJax;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close();
  }
}
