import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataProvider } from '@core/constants/provider-definitions';
import { PlotValueDTO, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ExistingRemoteVarInfoDTO } from '@core/store/providers/dtos/existing-remote-varinfo.dto';
import { MacrobondEntityInfoDTO } from '@core/store/providers/macrobond/dtos/macrobond-entityinfo.dto';
import { MacrobondService } from '@core/store/providers/macrobond/macrobond.service';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { ProviderService } from '@core/store/providers/provider.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Store } from '@ngxs/store';
import { AlgConverter } from '@shared/components/line-graph/alg-line.utils';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgChartStyles, AlgOptions, StrokeSetups } from '@shared/components/line-graph/alg.options';
import { DateUtils } from '@shared/utils/date.utils';
import { DialogV2BaseDialog } from '../../../base/dialogs.V2.base-dialog';
import { MBEntityInfo, MBEntityInfoRelease, MBEntityInfoSource, MBEntityInfoTimeSeries, MacrobondImportMultipleDialogData } from './import-multiple.dialog.constants';



@Component({
  selector: 'indicio-macrobond-import-multiple-dialog',
  templateUrl: 'import-multiple.dialog.html',
  styleUrls: ['./import-multiple.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class MacrobondImportMultipleDialogComponent extends DialogV2BaseDialog<MacrobondImportMultipleDialogComponent> {

  public static Id: string = 'MacrobondImportMultipleDialogComponent';

  public activeVariableId: string;
  private variableInfos: Map<string, MBEntityInfo> = new Map<string, MBEntityInfo>();

  private fVersion: ForecastVersionModel;
  private requestVariables: Map<string, RemoteDataRequestModel> = new Map<string, RemoteDataRequestModel>();
  private serieInfos: Map<string, { Values: SimplePlotValue[], MetaData: any; }> = new Map<string, { Values: SimplePlotValue[], MetaData: any; }>();
  public multipleVariables = true;

  public importedToForecast = 0;
  public importedAsSource = 0;
  public pending = false;
  public loadingVariable: boolean;
  public imported = false;

  public newSourceVariables: number = 0;
  public existingVars: ExistingRemoteVarInfoDTO[] = [];

  public graphReady = false;
  public periodicity: PeriodicityType;
  public lines: ALGLineModel[];
  public historicLine: ALGLineModel;
  public options: AlgOptions.Options;
  public chartStyles: AlgChartStyles = { borderSize: 2, borderRadius: 4, historyLine: '#6388D0', plotHeight: 210 };

  public get requestVariablesArray() { return Array.from(this.requestVariables.values()); }
  public get activeRequest() { return this.requestVariables.get(this.activeVariableId); }
  public get activeEntity() { return this.variableInfos.get(this.activeVariableId); }
  public get alertVariables() { return this.requestVariablesArray.filter(x => x.nameError && !!x.existingVariable); }
  public get errorVariables() { return this.requestVariablesArray.filter(x => x.nameError && !!!x.existingVariable); }
  public get importableVariables() { return this.requestVariablesArray.filter(x => this.errorVariables.map(y => y.RemoteReferenceId).indexOf(x.RemoteReferenceId) === -1); }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: MacrobondImportMultipleDialogData,
    public dialogRef: MatDialogRef<MacrobondImportMultipleDialogComponent, boolean>,
    public store: Store,
    public service: MacrobondService,
    public env: EnvironmentService,
    private forecastService: ForecastFrontendService,
    private providerService: ProviderService,
    private clientService: ClientFrontendService,
    private statusService: StatusService,
    private forecastVariableService: ForecastVariableFrontendService,
    private sourcevariableService: SourceVariableFrontendService,
    private fvarMapper: ForecastVariableMapper,
    private appearance: AppearanceService
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    const fVersPromise = this.forecastService.getOrFetchForecastVersion(this.data.forecastVersionId);
    this.multipleVariables = this.data.variableIds.length > 1;

    if (!this.multipleVariables) {
      this.dialogRef.updateSize('677px');
    }

    Promise.all([fVersPromise])
      .then(([fver]) => {
        this.fVersion = fver;
        return this.mapData();
      })
      .catch(e => {
        this.statusService.setError(e, true);
      })
      .finally(() => {
        this.initialized = true;
      });
  }

  public setActiveVariable(id: string) {
    if (this.activeVariableId === id) { return; }
    this.activeVariableId = id;
    this.getObservations(id);
  }

  public getVariableInfo(id: string) {
    let promise;
    if (this.variableInfos.has(id)) {
      promise = Promise.resolve(this.variableInfos.get(id));
    } else {
      promise = this.service.getEntityInfo(id);
    }

    this.loadingVariable = true;

    return promise
      .then((info) => {
        this.variableInfos.set(id, info);
        return info;
      })
      .catch(e => {
        this.statusService.setError(e, true);
      })
      .finally(() => {
        this.loadingVariable = false;
      });
  }

  private mapData() {
    this.data.variableIds.forEach(async (x, i) => {
      // Get display info from macrobond
      const entityInfoFromMB = await this.getVariableInfo(x);
      const entityInfo = this.mapMBEntityInfo(entityInfoFromMB);
      this.variableInfos.set(entityInfo.TimeSeries.Code, entityInfo);

      // Set new request
      const request = new RemoteDataRequestModel();
      request.Name = entityInfo.TimeSeries.Title;
      request.RemoteReferenceId = entityInfo.TimeSeries.Code;
      request.Provider = DataProvider.macrobondapi;
      request.existingVariable = (await this.service.getExistingRVarInformation(entityInfo.TimeSeries.Code))?.shift();


      if (!request.existingVariable) { this.newSourceVariables++; }

      this.checkNameErrors(request);
      this.requestVariables.set(request.RemoteReferenceId, request);

      // Set the first variable as active
      if (i === 0) {
        this.setActiveVariable(entityInfo.TimeSeries.Code);
      }
    });
  }

  private mapMBEntityInfo(info: MacrobondEntityInfoDTO) {
    const timeSeriesInfo = info.getGroup('Time series');
    const sourceInfo = info.getGroup('Source');
    const releaseInfo = info.getGroup('Release');

    const mapped = new MBEntityInfo();

    mapped.TimeSeries = <MBEntityInfoTimeSeries> {
      Title: timeSeriesInfo.title,
      Code: timeSeriesInfo.getInfo('Name').value,
      Periodicity: this.env.getPeriodicity(DateUtils.convertFromLyFormat(timeSeriesInfo.getInfo('Frequency')?.value)),
      StartDate: DateUtils.newDate(timeSeriesInfo.getInfo('Start date')?.value),
      EndDate: DateUtils.newDate(timeSeriesInfo.getInfo('Last value date')?.value),
      RevisionHistory: timeSeriesInfo.getInfo('Revision history')?.value === 'true',
      RevisionStartDate: timeSeriesInfo.getInfo('Time of the first revision')?.value ? DateUtils.newDate(timeSeriesInfo.getInfo('Time of the first revision')?.value) : null,
      Category: timeSeriesInfo.getInfo('Category')?.value,
      Units: timeSeriesInfo.getInfo('Unit')?.value,
      Description: timeSeriesInfo.description,
      Active: timeSeriesInfo.getInfo('Entity state')?.value === 'Active'
    };

    if (sourceInfo) {
      mapped.Source = <MBEntityInfoSource> {
        Title: sourceInfo.title,
        Region: sourceInfo.getInfo('Region')?.value,
        InfoUrl: sourceInfo.getInfo('Info link')?.value,
        CountryOfDomicile: sourceInfo.getInfo('Country of domicile')?.value,
      };
    }

    if (releaseInfo) {
      mapped.Release = <MBEntityInfoRelease> {
        Title: releaseInfo.title,
        ReleaseDate: releaseInfo.getInfo('Last release time') && DateUtils.newDate(releaseInfo.getInfo('Last release time')?.value),
        NextReleaseDate: releaseInfo.getInfo('Next release time') && DateUtils.newDate(releaseInfo.getInfo('Next release time')?.value),
        InfoLink: releaseInfo.getInfo('Info link')?.value,
        HistoryLink: releaseInfo.getInfo('History link')?.value,
        CalendarLink: releaseInfo.getInfo('Calendar link')?.value,
        MethodologyLink: releaseInfo.getInfo('Methodology link')?.value
      };
    }

    return mapped;
  }

  public changeName(newName: string) {
    this.activeRequest.Name = newName.trim();
    this.checkNameErrors();
  }

  private checkNameErrors(variable: RemoteDataRequestModel = this.activeRequest) {
    if (variable.Name.length < 2 || variable.Name.length > 255) {
      return variable.nameError = 'Name must be between 2 and 255 characters.';
    }

    if (this.sourcevariableService.sourceVariables.map(x => x.Name.toLowerCase()).includes(variable.Name.toLowerCase())) {
      return variable.nameError = 'A variable with this name already exists.';
    }

    variable.nameError = null;
  }

  private getObservations(variableId = this.activeVariableId) {
    this.graphReady = false;
    if (this.serieInfos.has(variableId)) {
      this.updateGraphData();
      return Promise.resolve(this.serieInfos.get(variableId));
    }
    return this.service.getSeries(variableId).then(res => {
      const info = res[0].Values.map(x => ({ D: DateUtils.newDate(x.D), V: x.V, m: DateUtils.newMoment(x.D) }));
      const metaData = res[0].MetaData;
      this.serieInfos.set(variableId, { Values: info, MetaData: metaData });
      this.updateGraphData();
      return res[0];
    });
  }

  private updateGraphData() {
    const activeRequest = this.requestVariables.get(this.activeVariableId);
    const seriesInfo = this.serieInfos.get(this.activeVariableId);
    const singleSeries = AlgConverter.fromSpeedHistoricValues(seriesInfo.Values as PlotValueDTO[], activeRequest.Name, activeRequest.Name);

    singleSeries.Name = `Result: ${activeRequest.Name}`;

    this.historicLine = singleSeries;
    this.options = this.getGraphOptions();
    this.graphReady = true;
    this.periodicity = this.env.getPeriodicity(seriesInfo.MetaData.Frequency).Value;
  }

  private getGraphOptions() {
    const serieInfo = this.serieInfos.get(this.activeVariableId);
    const opts = AlgOptions.CreateOptions({
      noDataText: 'No data selected',
      inModal: true,
      summary: true,
      showOnlyHistoric: true,
      menuConfig: {
        forceShowButtons: true,
        showFittedDataBtn: false,
        dontShowPastForecastBtn: true,
        dontShowDownloadBtn: false,
        showSeasonalAdjBtn: false,
        showOutlierAdjBtn: false,
        showMenu: false
      },
      axisConfig: {
        yAxisPosition: 'inside',
        yAxisLine: StrokeSetups.AxisLineDashed,
      },
      updateGraph: false,
      showAssessments: false,
      dontShowCIHover: false,
      isPercent: false,
      rocEnabled: this.appearance.RoCEnabled,
      rocYYEnabled: this.appearance.RoCYYEnabled,
      dates: serieInfo.Values.map(x => x.m),
      pointsToShow: Math.min(serieInfo.Values.length, 200)
    });
    return opts;
  }

  public async importVariables() {
    this.pending = true;
    const variablesToAdd = this.importableVariables;
    if (variablesToAdd.length === 0) {
      this.statusService.setError('No variables to import', true);
      return;
    }
    let i = 0;
    for (const variable of variablesToAdd) {
      i++;
      try {
        variable.status = 'processing';
        await this.addVariable(variable);
        variable.status = 'complete';
        this.importedToForecast++;

        if (variablesToAdd.length === i) {
          this.statusService.setMessage(`${this.importedToForecast} variables added successfully`, 'Success', this.multipleVariables);
          if (!this.multipleVariables) {
            this.save();
          }
        }

      } catch (error) {
        variable.status = 'error';
        variable.statusMessage = error?.error?.Message || error?.error || error?.message || 'An error occurred';
        if (!this.multipleVariables) {
          this.statusService.setError(error, true);
        }
      }
    }
    this.pending = false;
    this.imported = true;
  }

  public async addVariable(variable: RemoteDataRequestModel) {
    if (variable.existingVariable) {
      return this.importExistingSourceVariable(variable);
    }

    const data = await this.getObservations(variable.RemoteReferenceId);

    variable.RemoteUri = data.MetaData.Uri;
    variable.Periodicity = data.MetaData.Frequency;
    variable.MetaData = data.MetaData.faKeyValueArray();
    if (data.MetaData['Region']) {
      variable.MetaData['Region'] = data.MetaData['Region'];
    }

    return this.providerService.addVariableFromDatasource(this.clientService.activeCompany.CompanyId, variable)
      .then(sourcevariable => {
        this.importedAsSource++;
        return this.importSourceVariable(sourcevariable);
      });
  }

  public importExistingSourceVariable(variable: RemoteDataRequestModel) {
    return this.sourcevariableService.getOrFetchSourceVariable(variable.existingVariable.SourceVariableId)
      .then(sourceVariable => {
        return this.importSourceVariable(sourceVariable);
      });

  }

  private importSourceVariable(sourceVariable: SourceVariableModel) {
    return this.forecastVariableService.createForecastVariable(this.fvarMapper.mapFromSourceVariableModel(sourceVariable, this.fVersion.ForecastVersionId));
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close(true);
  }
}
