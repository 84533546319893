import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { NavigateToForecast } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastResultModel, ForecastResultModelModel } from '@core/store/forecast-result/entities/forecast-result.model';
import { NowcastResultMetaDTO } from '@core/store/forecast-result/entities/nowcast-result-meta-dto';
import { ForecastResultService } from '@core/store/forecast-result/forecast-result.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { BrowseNowcastModalOpts } from './browse-nowcast-modal.options';


@Component({
  selector: 'indicio-browse-nowcasts-modal',
  templateUrl: './browse-nowcast-modal.component.html'
})
export class BrowseNowcastModalComponent extends ModalModelComponent {

  // Modal variables
  nowcast: ForecastModel = null;
  variable: ForecastVariableModel = null;
  results: NowcastResultMetaDTO[] = [];
  selectedMeta: NowcastResultMetaDTO = null;
  result: ForecastResultModel;


  updating = false;

  constructor(
    protected store: Store,
    private forecastVariableService: ForecastVariableFrontendService,
    private forecastService: ForecastFrontendService,
    private forecastResultService: ForecastResultService,
    private statusService: StatusService,
    public appearance: AppearanceService,
    public envService: EnvironmentService,
    private dialogService: DialogService
  ) { super(); }

  public setOptions(options: BrowseNowcastModalOpts) {
    this.nowcast = options.nowcast;
    this.results = this.nowcast.Results;
    this.variable = options.variable;

    if (this.results.length) {
      this.selectedMeta = this.results.find(x => x.SourceVariableMetaId === this.variable.SourceVariableMetaId);

      if (!this.selectedMeta) { return this.selectedMeta = this.results[0]; }

      Promise.all([this.getResult()])
        .then(() => { })
        .catch(err => this.statusService.setError(err, true))
        .finally(() => {
          this.isLoading = false;
        });
    } else { this.isLoading = false; }
  }

  private getResult() {
    return this.forecastResultService.getForecastResult(this.selectedMeta.ForecastVersionId, this.selectedMeta.ForecastResultId)
      .then((result) => {
        result.Models = result.Models.filter(x => x.Active);
        this.result = result;
      });
  }

  public openModelWeightDialog(model: ForecastResultModelModel) {
    this.dialogService.openForecastVariableModelWeightsDialog({
      Model: this.envService.getModelName(model.Name).Display,
      Weights: model.Weight
    });
  }

  public navigateToNowcast() {
    this.store.dispatch(new NavigateToForecast(this.nowcast.ForecastId));
    this.close();
  }

  public setSelectedMeta(m: MatSelectChange) {
    this.selectedMeta = m.value;
  }

  public useSelectedResult() {
    this.updating = true;
    this.forecastVariableService.changeSourceMetaIdForFVar(this.variable.ForecastVersionId, this.variable.ForecastVariableId, this.selectedMeta.SourceVariableMetaId)
      .then(() => {
        this.updating = false;
        this.close();
      });
  }

  public deleteNowcast() {
    const ref = this.dialogService.openConfirmDialog({
      Title: 'Remove nowcast',
      Message: 'Are you sure you want to remove this Nowcast?',
      ConfirmText: 'Remove',
      Style: 'warn',
      ExtraWarning: '',
      CancelText: 'No'
    });

    ref.subscribe((proceed: boolean) => {
      if (!proceed) { return; }
      this.pending = true;
      this.forecastService.deleteNowcast(this.nowcast.ForecastId, this.variable.ForecastVersionId, this.variable.ForecastVariableId)
        .then(_resp => {
          this.close();
          this.statusService.setMessage('Nowcast removed successfully', 'Success');
          this.forecastVariableService.fetchById(this.variable.ForecastVersionId, this.variable.ForecastVariableId);
        })
        .catch(err => this.statusService.setError(err))
        .finally(() => this.pending = false);
    });
  }
}


