import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ModelWeightsDialogData {
  Model: string;
  Weights: number[];
}

@Component({
  selector: 'indicio-model-weights-dialog',
  templateUrl: 'model-weights.dialog.html',
})
export class ModelWeightsComponent {
  public static Id: string = 'ModelWeightsComponent';
  constructor(
    public dialogRef: MatDialogRef<ModelWeightsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModelWeightsDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(true);
  }
}
