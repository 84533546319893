import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { HistoricBaseEventModel } from '@core/store/historic-event/models/base/historic-event.model';
import { ImportMultiHEventModalOptions } from './import-multiple-events-modal.options';

export class OpenImportHistoricEventsModalComponent {
  public static readonly type = '[MODAL] Open import historic events modal';
  data: ImportMultiHEventModalOptions;
  constructor(
    events: HistoricBaseEventModel[],
    forecastVersion: ForecastVersionModel,
    callback?: any
  ) {
    this.data = { events, forecastVersion, callback };
  }
}
