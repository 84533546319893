import { MacrobondSelectCategoryComponent } from './select-storage-location/macrobond-select-category.component';
import { MacrobondSelectStorageLocationComponent } from './select-storage-location/macrobond-select-storage-location.component';
import { MacrobondSetDescriptionComponent } from './select-storage-location/macrobond-set-description.component';
import { MacrobondSetPrimNameComponent } from './select-storage-location/macrobond-set-primName.component';

export const MacrobondComponents = [
  MacrobondSelectStorageLocationComponent,
  MacrobondSelectCategoryComponent,
  MacrobondSetDescriptionComponent,
  MacrobondSetPrimNameComponent
];
