<indicio-modal-base [child]="this"
                    modalTitle="Import to forecast"
                    modalClass=""
                    [loading]=isLoading
                    bodyExtraClass="overflow-auto">
  <div class="modal-width">
    <ng-container *ngIf="unusedVariables.length">
      <ng-container *ngTemplateOutlet="variableListTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="!unusedVariables.length">
      <ng-container *ngTemplateOutlet="nothingToImport"></ng-container>
    </ng-container>
  </div>
</indicio-modal-base>

<ng-template #nothingToImport>
  <div class="row">
    <div class="col-xs-12 center-text">
      All selected variables have already been added.
    </div>
  </div>
</ng-template>

<ng-template #variableListTemplate>
  <div class="row">
    <div class="col-xs-12">

      <div class="data-table">
        <div class="data-table__header">
          <div class="column header"
               style="width: 40px;">#</div>
          <div class="column header"
               [style.width.px]="nameWidth()">Name</div>
          <div class="column header"
               style="width: 104px;"
               *ngIf="!mainVariableExists">Main variable</div>
          <div class="column header"
               style="width: 119px;"
               *ngIf="versionsAvailable">Version</div>
          <div class="column header"
               [style.width.px]="aggWidth()">Aggregation</div>
          <div class="column"
               style="width: 50px;">
            &nbsp;
          </div>
        </div>
      </div>

      <div class="data-table__body"
           [style.maxHeight.px]="301">
        <div class="body__row body__row--center-height body__row--max-height-30"
             *ngFor="let variable of variables; let i = index">
          <ng-container *ngIf="getForecastVariable(variable) as fvar">
            <div class="column"
                 style="width: 40px;">{{ i + 1 }}</div>
            <div class="column"
                 [style.width.px]="nameWidth()">
              <div class="row"
                   *ngIf="!isNameEditOpen(fvar)">
                <div class="col-xs-10 text-left crop-string"
                     [style.maxWidth.px]="290"
                     [title]="fvar.Name">
                  {{ fvar.Name }}
                  <div style="position: absolute; right: 0; top: 1px;"
                       *ngIf="getNameChangeNeeded(fvar)"
                       class="red"><i class="ion-alert-circled"
                       indicioTippy="A variable with that name already exists in the forecast"></i></div>
                </div>
                <div class="col-xs-2">
                  <i class="ion-edit edit-pen cursor-pointer"
                     (click)="toggleNameEdits(fvar)"></i>
                </div>
              </div>
              <ng-container *ngIf="isNameEditOpen(fvar)">
                <div class="flex-row">
                  <mat-form-field style="width: -webkit-fill-available;">
                    <input type="text"
                           minlength="2"
                           maxlength="256"
                           matInput
                           [(ngModel)]="fvar.Name"
                           class="material"
                           (keyup)="toggleNameChangeNeeded(fvar)"
                           (keyup.enter)="toggleNameEdits(fvar)">
                    <div matSuffix
                         *ngIf="getNameChangeNeeded(fvar)"
                         class="red"><i class="ion-alert-circled"
                         indicioTippy="A variable with that name already exists in the forecast"></i></div>
                  </mat-form-field>
                  <div style="display: flex; justify-content: center; align-items: center;">
                    <fa-icon class="blue cursor-pointer edit-pen"
                             [icon]="faSave"
                             (click)="toggleNameEdits(fvar)"></fa-icon>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="column"
                 style="width: 104px;"
                 *ngIf="!mainVariableExists">
              <mat-radio-group *ngIf="!usedAlready.includes(variable.SourceVariableId)">
                <mat-radio-button (click)="mainVariable = fvar"
                                  [checked]="mainVariable.SourceVariableId === variable.SourceVariableId"
                                  [value]="fvar"></mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="column"
                 style="width: 119px;"
                 *ngIf="versionsAvailable">
              <mat-form-field *ngIf="variable.Versions?.length > 1 && !usedAlready.includes(variable.SourceVariableId)">
                <mat-select [(value)]="fvar.SourceVariableMetaId"
                            (selectionChange)="setVersion(variable, $event)"
                            panelClass="data-table-panel-opts fitted-select"
                            required>
                  <mat-option [value]="option.SourceVariableMetaId"
                              [title]="option.Name"
                              *ngFor="let option of variable.Versions;">{{ option.Name }}
                  </mat-option>
                  <mat-optgroup label="Old versions"
                                *ngIf="variable.OldVersions.length">
                    <mat-option [value]="option.SourceVariableMetaId"
                                [title]="option.Name"
                                *ngFor="let option of variable.OldVersions">{{ option.Name }}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="column"
                 [style.width.px]="aggWidth()">
              <mat-form-field *ngIf="!usedAlready.includes(variable.SourceVariableId)">
                <mat-select [(value)]="fvar.AggregationMethodId"
                            panelClass="data-table-panel-opts "
                            required
                            [disabled]="!!!getAggregationMethods(variable)">
                  <mat-option [value]="option.Value"
                              *ngFor="let option of getAggregationMethods(variable)">{{ option.Display }}</mat-option>
                  <mat-option [value]="fvar.AggregationMethodId"
                              *ngIf="!!!getAggregationMethods(variable)">
                    {{ envService.getAggregationMethod(fvar.AggregationMethodId).Display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="center-text btn--flat red"
                   style="padding: 0;"
                   *ngIf="usedAlready.includes(variable.SourceVariableId)">
                Already imported
              </div>
            </div>
            <div class="column"
                 style="width: 90px;">
              <ng-container *ngIf="!usedAlready.includes(variable.SourceVariableId); else skipped">
                <ng-container *ngIf="fvar.status === 'Failed'; else status">
                  <span class="red dotted-underline"
                        [indicioTippy]="{ content: fvar.failedMsg, maxWidth: 450 }">Failed</span>
                </ng-container>
                <ng-template #status>
                  <ng-container *ngIf="fvar.status === 'Warning'; else realStatus">
                    <span class="red dotted-underline"
                          indicioTippy="Import to forecast successful.<br><br>Warning: {{ fvar.warningMsg }}">Warning</span>
                  </ng-container>
                  <ng-template #realStatus>
                    {{ fvar.status }}
                  </ng-template>
                </ng-template>
              </ng-container>
              <ng-template #skipped>
                Skipped
              </ng-template>
            </div>
            <div class="column"
                 style="width: 50px;">
              <div class="ion-trash-a h-red cursor-pointer"
                   [ngClass]="{ disabled: pending === true || importedCount > 0 }"
                   (click)="removeVariable(variable)"></div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row extra-margin-top">
    <div class="col-xs-12 text-right">
      <ng-container *ngIf="pending || importedCount !== 0">
        Imported: {{importedCount }}/{{ toImport.length}}
      </ng-container>
      <ng-container *ngIf="!pending && importedCount === 0">
        Selected: {{ toImport.length }}/{{ variables.length }}
      </ng-container>
    </div>
  </div>

  <div class="row extra-margin-top">
    <div class="col-xs-12 center-text">
      <button class="btn full-width"
              [class]="'btn full-width' + (pending || extraErrors || nameChangeNeededForImport() ? ' disabled' : '')"
              data-test-id="import-to-forecast-btn"
              [indicioTippy]="extraErrors || nameChangeNeededForImport() ? extraErrors ? extraErrors : 'One or more names needs to change.' : null"
              (click)="import()"
              *ngIf="variables?.length && importedCount !== toImport.length">
        <span *ngIf="!pending">Import</span>
        <i class="ion-load-c spinner"
           *ngIf="pending"></i>
      </button>

      <button class="btn full-width"
              (click)="close()"
              *ngIf="!variables?.length || importedCount === toImport.length">
        {{ importedCount > 0 ? 'Done' : 'Close' }}
      </button>
    </div>
  </div>
</ng-template>
