<div class="company-edit-general-tab"
     style="--grid-template-global: 1fr 1fr"
     *ngIf="!!company">

  <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="countryTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="userCountTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>

  <ng-container *ngTemplateOutlet="supportUsersTemplate"></ng-container>
</div>

<ng-template #nameTemplate>
  <h2>Info and settings</h2>
  <div class="grid-system center-vertical info-row">
    <div>Company name:</div>
    <div class="flex-end">
      <div *ngIf="editName"
           style="width: 365px;">
        <mat-form-field>
          <input type="text"
                 matInput
                 [(ngModel)]="newName"
                 class="material"
                 (keyup.enter)="saveName()">
          <i matSuffix
             *ngIf="editName && company.Name === newName"
             class="ion-close-round red cursor-pointer edit-pen"
             (click)="editName = false">
          </i>
          <fa-icon matSuffix
                   *ngIf="editName && company.Name !== newName"
                   class="blue cursor-pointer edit-pen"
                   [icon]="faSave"
                   (click)="saveName()">
          </fa-icon>
        </mat-form-field>
      </div>
      <div *ngIf="!editName"
           class="flex-end">
        <div class="crop-string name-field"
             style="display: inline-block; max-width: 335px; padding: 1px 0;"
             [title]="newName">{{ newName }}
        </div>
        <span style="margin-left: 5px">
          <i class="ion-edit cursor-pointer edit-pen"
             (click)="editName = true"></i>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #countryTemplate>
  <div class="grid-system center-vertical info-row"
       style="margin-top: -8px;">
    <div style="padding-top: 10px;">Country:</div>
    <div class="flex-end">
      <indicio-searchable-dropdown [required]="true"
                                   style="width: 250px;"
                                   optionDisplay="Display"
                                   [values]="envService.Countries"
                                   (valueChangeEvent)="setCountry($event)"
                                   [selectedValue]="selectedCountry">
      </indicio-searchable-dropdown>
    </div>
  </div>
</ng-template>

<ng-template #userCountTemplate>
  <div class="grid-system center-vertical info-row"
       style="--grid-template: 1fr 1fr">
    <div>Users: </div>

    <div class="flex-end">
      <div style="margin-right: 10px; margin-bottom: 4px;">{{ company.UserCount }} of</div>
      <mat-form-field style="width: 250px">
        <mat-select [value]="company.MaxUsers"
                    required
                    (selectionChange)="setMaxUsers($event)">
          <mat-option [value]="count"
                      *ngFor="let count of [1, 3, 5, 10, 20, 30, 50, 100]"> {{count}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #summaryTemplate>
  <div class="grid-system center-vertical info-row">
    <div>Enabled: </div>
    <div class="text-right">{{ company.Disabled ? 'Disabled' : 'Enabled' }}</div>
  </div>
  <div class="grid-system center-vertical info-row">
    <div>Forecasts: </div>
    <div class="text-right">{{ company.ForecastCount }}</div>
  </div>
  <div class="grid-system center-vertical info-row">
    <div>Number of employees: </div>
    <div class="text-right">{{ company.Employees.length }}</div>
  </div>
  <div class="grid-system center-vertical info-row">
    <div>Registration date: </div>
    <div class="text-right">{{ company.CreatedDate | date }}</div>
  </div>
</ng-template>

<ng-template #supportUsersTemplate>
  <h2>Support</h2>
  <div class="grid-system center-vertical info-row">
    <div>Support users: </div>
    <div class="flex-end">
      <mat-form-field style="width: 250px;"
                      *ngIf="!loadingSupportUsers; else supportLoadingTemplate">
        <mat-select [(value)]="selectedSupporters"
                    (selectionChange)="setAdministrators($event)"
                    multiple="true"
                    required
                    panelClass="fitted-select">
          <ng-container *ngFor="let role of supportUsers">
            <mat-optgroup *ngIf="role.users.length > 0"
                          [label]="role.role">
              <mat-option [value]="p"
                          [title]="p.Description"
                          *ngFor="let p of role.users">{{ p.Display }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <ng-template #supportLoadingTemplate>
        <indicio-spinner></indicio-spinner>&nbsp;Loading support users...
      </ng-template>
    </div>
  </div>
  <ng-container *ngIf="selectedSupporters.length">
    <hr>
    <h3>Users supporting this company</h3>
    <ul style="list-style: disc; padding: 15px;">
      <li *ngFor="let user of selectedSupporters">
        {{ user.Display }}
      </li>
    </ul>
  </ng-container>
</ng-template>
