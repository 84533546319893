// Angular specific
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandlerModule } from '@core/errors/error-handler.module';
import { httpInterceptorProviders } from '@core/interceptors';
import { IndicioStore } from '@core/store';
// Indicio specific
import { environment } from '@env/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from '@modules/root/app.component';
import { AppRoutingModule } from '@modules/root/app.routing';
import { IndicioRootComponents } from '@modules/root/components';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
// Store
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
// Cookies
import { IndicioDialogsModule } from '@modules/dialog/dialogs.module';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig, initConfig } from './app.config';
import { IndicioRootViews } from './views';

@NgModule({
  declarations: [
    AppComponent,
    ...IndicioRootComponents,
    ...IndicioRootViews
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot(IndicioStore, { developmentMode: false }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: !environment.storeLogging }),
    // BsDropdownModule.forRoot(),
    // TooltipModule.forRoot(),
    // ModalModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    IndicioDialogsModule,
    ErrorHandlerModule,
    FontAwesomeModule,
  ],
  providers: [
    DatePipe,
    CookieService,
    httpInterceptorProviders,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
