import { CompanyModel } from '@core/store/company/company.model';

export class CompanyDataRemoved {
  public static readonly type = '[COMPANY] Company data removed';
  constructor(public company: CompanyModel) { }
}

export class GetCompaniesSuccessAction {
  public static readonly type = '[COMPANY] Get companies success';
  constructor(public companies: CompanyModel[]) { }
}

export class CreateCompanySuccessAction {
  public static readonly type = '[COMPANY] Create company success';
  constructor(public company: CompanyModel) { }
}

export namespace CompanyActions {

  export class SetActive {
    public static readonly type = '[COMPANY] Set active company';
    constructor(public companyId: string) { }
  }

  export class SetActiveCompanySuccess {
    public static readonly type = '[COMPANY] Set active company success';
    constructor(public companyId: string) { }
  }

  export class ChangedActiveCompany {
    public static readonly type = '[COMPANY] ChangedActiveCompany';
    constructor(public companyId: string) { }
  }

  export class RemovedEmployeeSuccess {
    public static readonly type = '[COMPANY] Remove employee success';
    constructor(public companyId: string, public email: string) { }
  }

  export class CheckMacrobondCredentials {
    public static readonly type = '[COMPANY] Check Macrobond credentials success';
    constructor(public companyId: string) { }
  }
  export class ResetCompanyData {
    public static readonly type = '[COMPANY] Resect company data';
    constructor() { }
  }

  export class GetCompanySuccessAction {
    public static readonly type = '[COMPANY] Get company success';
    constructor(public company: CompanyModel) { }
  }

  export class UpdatedCompany {
    public static readonly type = '[COMPANY] Get updated success';
    constructor(public company: CompanyModel) { }
  }
}
