import { Def } from '@core/decorators/def';
import { ICanFilter } from '@core/interfaces/if-can-filter';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import { RemovedStatus } from '@core/types/removed.status.type';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { SourceType, SourceTypesType } from '@modules/lang/language-files/source-types';
import { VisibilityLevelType } from '@modules/lang/language-files/visibility';
import { Periodicity } from '@modules/lang/types/periodicity';
import { TagMetaDTO } from '../tags/dtos/tags.dtos';
import { SourceVariableBaseRevisionDTO } from './dtos/source-variable-revision.dto';
import { PeriodicityInfoDTO } from './dtos/source-variable-version-dto';

export class SourceVariableViewDTO {
  // Source IDs
  @Def() SourceVariableId: string;
  @Def() SourceVariableMetaId: string;
  @Def() RemoteVariableId: string;
  // File info
  @Def() UploadedFileId: string;
  @Def() RemoteFileId: string;
  @Def() FileName: string;
  // Properties
  @Def() Name: string;
  @Def() Periodicity: PeriodicityType;
  @Def() ManualPeriodicity: boolean;
  @Def() PeriodicityInfo: PeriodicityInfoDTO;
  @Def() LastBaseObservation: string;
  @Def() FirstBaseObservation: string;
  @Def() CreatedDate: string;
  @Def() VisibilityLevelId: VisibilityLevelType;
  @Def() SourceType: SourceTypesType;
  @Def() Tags: TagMetaDTO[];
  // Owner
  @Def() OwnerEmail: string;
  @Def() OwnerId: string;

  // Frontend set properties
  sourceType: SourceType;
  periodicity: Periodicity;
  firstBaseObservation: Date;
  lastBaseObservation: Date;
  createdDate: Date;
  usedInForecast: boolean;
  fileId: string;
}

export class SourceVariableMetaModel implements IHasModelId, ICanFilter {
  // Ids
  @Def() SourceVariableId: string;
  @Def() OwnerEmail: string;
  @Def() OwnerId: string;
  @Def() CompanyId: string;
  @Def() VisibilityLevelId: VisibilityLevelType;
  @Def() UploadedFileId: string;
  @Def() RemoteFileId: string;
  @Def() RemoteVariableId: string;
  @Def() RemoteReferenceId: string;
  // Props
  @Def() Tags: TagMetaDTO[];
  @Def() LastBaseObservation: Date;
  @Def() Name: string;
  @Def() SourceType: SourceTypesType;
  @Def() FileName: string;
  @Def() InfixExpression: string;
  @Def() Used: boolean;
  @Def() RevisionCount: number;

  sourceType: SourceType;

  public get ObjectName() { return this.Name; }
  public getModelId() { return this.SourceVariableId; }
  public getFileId() { return this.UploadedFileId || this.RemoteFileId; }
}

export class GroupVariableBridgeDTO {
  @Def() SourceVariableId: string;
  @Def() Identifier: string;
}

export class SourceVariableModel extends SourceVariableMetaModel {

  // Dates
  @Def() CreatedDate: Date;
  @Def() ModifiedDate: Date;

  // Version(s) and Group info
  @Def() Versions: SourceVariableVersionModel[] = [];
  @Def() OldVersions: SourceVariableVersionModel[] = [];
  @Def() GroupVariableBridges: GroupVariableBridgeDTO[] = [];
  @Def() Revisions: SourceVariableBaseRevisionDTO[] = [];
  @Def() UpAggregationMethodId: string;
  @Def() DownAggregationMethodId: string;
  @Def() groupPeriodicity: PeriodicityType;
  @Def() versionsAheadOfCurrent = [];
  @Def() versionsNeedUpdate = [];
  @Def() GroupMetaIds: string[] = [];
  @Def() Periodicity: PeriodicityType;
  @Def() LastUpdated: Date = null;

  // Should not be here at all
  Values: string[] = [];
  Dates: any[] = [];
  MaxValues = [];
  MinValues = [];

  // Frontend flags
  showVersions = false;
  isLoading = false;
  fetched = false;
  usedInForecast = false;
  removedStatus: RemovedStatus;
  failedMsg: string;
  errorStatus: string;

  public _tmpAggregationMethod: string;

  // Helper functions
  public get periodicity() { return this.getBaseVersion().Periodicity; }
  public getBaseVersion() { return this.Versions.find(v => v.Base === true); }
  public getVersionById(metaId: string) { return this.Versions.find(x => x.SourceVariableMetaId === metaId); }
}

