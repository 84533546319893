import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { ClientSettingsMapper } from './client-settings.mapper';
import { DataManagementSettingsModel } from './data-management-settings.model';
import { ClientSettingsDTO } from './dtos/client-settings.dto';
import { DataManagementSettingsDTO } from './dtos/data-management-settings-dto';


@Injectable({
  providedIn: 'root'
})
export class ClientSettingsService {

  public DataManagementSettings: DataManagementSettingsModel;
  public settings: ClientSettingsDTO = new ClientSettingsDTO();

  constructor(
    private base: HttpService,
    private mapper: ClientSettingsMapper
  ) { }

  public setSettings(m: ClientSettingsDTO = this.settings) {
    if (!(m instanceof ClientSettingsDTO)) { m = this.settings = new ClientSettingsDTO(); }
    this.settings = Object.faMapTo(new ClientSettingsDTO(), m);
    this.settings.FullFormat = `${this.settings.DateFormat} ${this.settings.TimeFormat}`;
    this.settings.FullFormatNoSeconds = `${this.settings.DateFormat} ${this.settings.TimeFormatNoSeconds}`;
  }

  public async getDataManagementFilter() {
    const settings = await this.getOrFetchDataManagementSettings();
    return settings.getSetting('filter');
  }

  public async getSortSettings(key = 'filter') {
    const settings = await this.getOrFetchDataManagementSettings();
    return settings.getSetting(key);
  }

  public getOrFetchDataManagementSettings() {
    if (this.DataManagementSettings) {
      return Promise.resolve(this.DataManagementSettings);
    }
    return this.fetchDataManagementSettings()
      .then(settings => {
        this.DataManagementSettings = settings;
        return settings;
      });
  }

  public async saveDataManagementSettings(key, value) {
    await this.getOrFetchDataManagementSettings();
    const index = this.DataManagementSettings.DataManagementSortSettings.map(s => Object.keys(s)[0]).indexOf(key);
    if (index > -1) {
      this.DataManagementSettings.DataManagementSortSettings[index][key] = value;
    } else {
      const newSetting = {};
      newSetting[key] = value;
      this.DataManagementSettings.DataManagementSortSettings.push(newSetting);
    }
    return this.updateDataManagementSettings(this.DataManagementSettings);
  }

  public updateSettings(dto: ClientSettingsDTO = this.settings) {
    return this.base.put<null>('client/settings', dto)
      .then(() => dto);
  }

  private fetchSettings() {
    return this.base.get<ClientSettingsDTO>('client/settings')
      .then((res) => res.body);
  }

  private fetchDataManagementSettings() {
    return this.base.get<DataManagementSettingsDTO>('client/settings/data-management')
      .then(result => this.mapper.mapDataManagementSettings(result.body));
  }

  private updateDataManagementSettings(dataManagementSettings: DataManagementSettingsModel) {
    const dto = this.mapper.mapDataManagementSettingsDTO(dataManagementSettings);
    return this.base.put<null>('client/settings/data-management', dto);
  }
}
