<indicio-modal-base [child]="this"
                    [modalTitle]="modalTitle"
                    [loading]="isLoading">

  <ng-container *ngIf="!isLoading">
    <div class="row">
      <div style="font-size: 18px; font-weight: 500;"
           class="col-xs-12">
        {{ subTitle }}
      </div>
    </div>
    <ng-container *ngIf="step === 1"
                  [ngTemplateOutlet]="step1Template">
    </ng-container>
    <ng-container *ngIf="step === 2"
                  [ngTemplateOutlet]="step2Template">
    </ng-container>
    <ng-container *ngIf="step === 3"
                  [ngTemplateOutlet]="step3Template">
    </ng-container>
    <ng-container *ngIf="step === 4"
                  [ngTemplateOutlet]="step4Template">
    </ng-container>
    <ng-container *ngIf="step === 5"
                  [ngTemplateOutlet]="step5Template">
    </ng-container>
    <ng-container [ngTemplateOutlet]="buttonRowTemplate"></ng-container>
  </ng-container>
</indicio-modal-base>

<ng-template #step1Template>
  <div class="row">
    <div class="col-xs-12">
      Please fill in the following information
    </div>
  </div>

  <indicio-modal-option-row optionTitle="Organization name"
                            optionSubtitle="As seen in URL when logging into sharepoint online."
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <input type="text"
             matInput
             required
             placeholder="Company name"
             name="maxusers"
             [(ngModel)]="sharepointOrgName"
             (keyup)="onOrgNameChange()"
             class="material">
      <span matSuffix>.sharepoint.com</span>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Sharepoint site name"
                            optionSubtitle="Name of the sharepoint site to integrate with. Note: Only one site currently supported"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <input type="text"
             matInput
             required
             placeholder="Site name"
             name="maxusers"
             [(ngModel)]="sharepointSiteName"
             (keyup)="onOrgNameChange()"
             class="material">
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #step2Template>
  <div class="row margin-top">
    <div class="col-xs-12">
      <ol>
        <li>
          Go to
          <a target="_blank"
             [href]="sharepointAddressAppReg">{{ sharepointOrgName + '.sharepoint.com/sites/' + sharepointSiteName
            }}</a>
        </li>
        <li>
          Click on generate Client Id and copy it to the field below
        </li>
        <li>
          Click on generate Client secret and copy it to the field below
        </li>
        <li>
          <span>Enter the following information</span>
          <table class="add-sharepoint-app-table selectable-text">
            <tr>
              <td class="strong">Title</td>
              <td #titleRef>Indicio Integration</td>
              <td>
                <indicio-copy [element]="titleRef"></indicio-copy>
              </td>
            </tr>
            <tr>
              <td class="strong">App domain</td>
              <td #domainRef>api.indicio.com</td>
              <td>
                <indicio-copy [element]="domainRef"></indicio-copy>
              </td>
            </tr>
            <tr>
              <td class="strong">Redirect URI</td>
              <td #redirRef>https://app.indicio.com/provider/sharepoint</td>
              <td>
                <indicio-copy [element]="redirRef"></indicio-copy>
              </td>
            </tr>
          </table>
        </li>
        <li><strong>Click create</strong></li>
      </ol>
    </div>
  </div>
  <hr>
  <indicio-modal-option-row optionTitle="Client Id"
                            optionSubtitle="Client Id for the connection"
                            textWidth="5"
                            optionsWidth="7">
    <mat-form-field style="margin-top: 15px;">
      <input type="text"
             matInput
             required
             name="maxusers"
             [(ngModel)]="sharepointClientId"
             class="material">
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Client secret"
                            optionSubtitle="Client secret for the connection"
                            textWidth="5"
                            optionsWidth="7">
    <mat-form-field style="margin-top: 15px;">
      <input type="text"
             matInput
             required
             name="maxusers"
             [(ngModel)]="sharepointSecretId"
             class="material">
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #step3Template>

  <div class="row">
    <div class="col-xs-12 margin-top">
      <ol>
        <li>Go to <a target="_blank"
             [href]="sharepointAppInvUrl">{{ sharepointOrgName + '.sharepoint.com/sites/' + sharepointSiteName }}</a>
        </li>
        <li>
          <span>Copy the App Id and click on lookup</span>
          <table class="add-sharepoint-app-table selectable-text">
            <tr>
              <td class="strong">App ID</td>
              <td #appIdRef>{{ sharepointClientId }}</td>
              <td>
                <indicio-copy [element]="appIdRef"></indicio-copy>
              </td>
            </tr>
          </table>
        </li>
        <li>Verify that everything looks correct</li>
        <li>
          <span>Copy the XML snippet and paste it in the permission request field</span>
          <table class="add-sharepoint-app-table selectable-text">
            <tr>
              <td class="strong"
                  vAlign="top">XML</td>
              <td #xmlSnippetRef>
                &lt;AppPermissionRequests AllowAppOnlyPolicy=&quot;true&quot;&gt;
                &lt;AppPermissionRequest Scope=&quot;http://sharepoint/content/sitecollection&quot;
                Right=&quot;Read&quot; /&gt;
                &lt;/AppPermissionRequests&gt;
              </td>
              <td vAlign="top">
                <indicio-copy [element]="xmlSnippetRef"></indicio-copy>
              </td>
            </tr>
          </table>
        </li>
        <li><strong> Click create</strong></li>
      </ol>
    </div>
  </div>
</ng-template>

<ng-template #step4Template>
  <div class="row">
    <div class="col-xs-12 margin-top">
      <table class="selectable-text">
        <tr>
          <td class="strong"
              style="min-width: 120px;">Client ID</td>
          <td> {{ sharepointClientId }}</td>
        </tr>
        <tr>
          <td class="strong">Client secret</td>
          <td>{{ sharepointSecretId }}</td>
        </tr>
        <tr>
          <td class="strong">Tenant ID</td>
          <td>{{ sharepointTenantId }}</td>
        </tr>
        <tr>
          <td class="strong">Server URL</td>
          <td>{{ sharepointOrgName + '.sharepoint.com' }}</td>
        </tr>
        <tr>
          <td class="strong">Site</td>
          <td>{{ sharepointSiteName }}</td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #step5Template>
  <div class="row margin-top">
    <div class="col-xs-12">
      The sharepoint online settings used in your organization does not allow custom applications to authenticate.<br>
      This is a new default setting imposed by Microsoft, that needs to be disabled for sharepoint.online accounts that
      wants to integrate with Indicio.<br><br>
      To enable custom application access to sharepoint, follow the steps below<br><br>
      <ol>
        <li>
          Download and install
          <a target="_blank"
             href="https://www.microsoft.com/en-gb/download/details.aspx?id=35588">SharePoint Online Management
            Shell</a>
        </li>
        <li>
          <span>Start the Sharepoint Online Management Shell and run the following commands</span>
          <table class="add-sharepoint-app-table selectable-text">
            <tr>
              <td class="strong"
                  vAlign="top">Connect</td>
              <td vAlign="top"
                  #connectCmdRef>Connect-SPOService -Url https://{{sharepointOrgName}}-admin.sharepoint.com</td>
              <td vAlign="top">
                <indicio-copy [element]="connectCmdRef"></indicio-copy>
              </td>
            </tr>
            <tr>
              <td class="strong"
                  vAlign="top">Enable</td>
              <td vAlign="top"
                  #accessCmdRef>Set-SPOTenant -DisableCustomAppAuthentication $false</td>
              <td vAlign="top">
                <indicio-copy [element]="accessCmdRef"></indicio-copy>
              </td>
            </tr>
          </table>
        </li>
        <li>Wait ~5 minutes and try to connect the application again!</li>
      </ol>
    </div>
  </div>
</ng-template>

<ng-template #buttonRowTemplate>
  <div class="row margin-top">
    <div class="col-xs-6">
      <button (click)="back()"
              type="button"
              class="btn outline blue left">{{ step === 1 ? 'Cancel' : 'Back' }}</button>
    </div>
    <div class="col-xs-6">
      <button class="btn right"
              (click)="next()"
              [ngClass]="{ disabled: isNextDisabled() }">
        <i *ngIf="pending"
           class="ion-load-c spinner"></i>
        <span>{{ step < 4
            ? 'Next'
            : 'Connect'
            }}</span>
      </button>
    </div>
  </div>
  <ng-container *ngIf="step === 1 && this.edit">
    <div class="row">
      <div class="col-xs-6">
        <button (click)="removeConnection()"
                type="button"
                class="btn red outline left">Remove</button>
      </div>
    </div>
  </ng-container>
</ng-template>
