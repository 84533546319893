<div class="date-range">
  <div class="legend">
    <div class="box test">Train</div>
    <div class="box train">Test</div>
    <div class="box forecast">Forecast</div>
    <div class="box ignored">Ignored data</div>
  </div>
  <div class="date-range-container margin-top clearfix">
    <div class="slider"
         id="date-range"></div>
    <span class="label left">{{ earliestDataStartDate | dateFormat: options.forecastVersion.Periodicity :
      appearance.DateFormat }}</span>
    <span class="label right">{{ _dateArray.last() | dateFormat: options.forecastVersion.Periodicity :
      appearance.DateFormat }}</span>
  </div>
  <div class="settings extra-margin-top row"
       *ngIf="_dateArray.length">
    <div class="col-xs-6 col-no-padding">
      <div class="row">
        <div class="col-xs-7">Out-of-sample accuracy</div>
        <div class="col-xs-5">
          <mat-slide-toggle data-test-id='auto-seasonal-update-toggle-btn'
                            [disabled]="!canUpdateForecast || activeObservationCount < MIN_OBS_OOS"
                            [checked]="oosEnabled"
                            [indicioTippy]="disabledOOSToggleTippy || null"
                            (change)="toggleOOSEnabled()">
          </mat-slide-toggle>
        </div>
      </div>
      <div class="row margin-top">
        <div class="col-xs-7"
             [ngClass]="!oosEnabled ? ' disabled' : ''"
             indicioTippy="Use an expanding window when calculating out-of-sample accuracy.">Use expanding window (EW)
        </div>
        <div class="col-xs-5">
          <mat-slide-toggle data-test-id='toggle-expanding-window'
                            [disabled]="!canUpdateForecast || !oosEnabled"
                            [checked]="expandingWindowEnabled"
                            [indicioTippy]="!oosEnabled ? 'Enable out-of-sample accuracy first.' :  null "
                            (change)="toggleExpandingWindow()">
          </mat-slide-toggle>
          <div class="btn-small-icon data-m center-icon warning"
               style="float: right; margin-top: 2px; margin-left: 5px;"
               [ngClass]="!expandingWindowEnabled ? ' disabled' : ''">
            <i class="ion-alert-circled"
               indicioTippy="Enabling expanding window may cause the calculation of models to take up to four times longer."></i>
          </div>
        </div>
      </div>
      <div class="row margin-top">
        <div class="col-xs-7"
             [ngClass]="!oosEnabled || !expandingWindowEnabled ? 'disabled' : ''"
             indicioTippy="How often should the model be refitted when calculating out-of-sample accuracy.">Expanding
          window recalculation frequency
        </div>
        <div class="col-xs-5">
          <mat-form-field>
            <mat-select [(value)]="recalcFrequency"
                        [disabled]="!oosEnabled || !expandingWindowEnabled"
                        [required]="true"
                        (selectionChange)="selectRecalculationFrequency($event.value)">
              <mat-option [value]="val"
                          *ngFor="let val of recalcFrequencyOptions">{{ val }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row margin-top"
           style="height: 15px;">
        <div class="col-xs-7">
          <p style="display: inline-block;font-weight: 400;"
             indicioTippy="To change the test size please drag the slider above.">Test set size</p>
        </div>
        <div class="col-xs-5 input-label">
          <span *ngIf="oosEnabled">
            {{ testSize }}%
            <i *ngIf="testSize >= 10"
               class="ion-information-circled blue"
               style="font-size: 14px; margin-left: 5px;"
               [indicioTippy]="'Recommended test size: ' + recommendedTestSize + '%'"></i>
            <i *ngIf="testSize < 10"
               class="ion-alert-circled yellow"
               style="font-size: 14px; margin-left: 5px;"
               [indicioTippy]="'A larger test size may provide better accuracy estimates.'"></i>
          </span>
          <span *ngIf="!oosEnabled">
            N/A
          </span>
        </div>
      </div>

      <div class="row marign-top">
        <div class="col-xs-12">
          <div class="grid-system center-vertical"
               style="--grid-template: 1fr 100px">
            <div>Forecasted points: {{ forecastHorizon }} {{ activePriority === 'horizon' ? '(priority)' : '' }}</div>
            <div style="text-align: end;">Max: {{ maxForecastHorizon }}</div>
          </div>
          <div class="grid-system center-vertical"
               style="--grid-template: 30px 1fr 30px; gap: 5px">
            <div class="max-lag-btn"
                 [ngClass]="{ disabled: !canUpdateForecast }"
                 (click)="decreaseHorizon()">
              <i class="ion-android-remove-circle"></i>
            </div>
            <div>
              <indicio-slider [current]="forecastHorizon"
                              [from]="1"
                              [enabledFrom]="1"
                              [to]="fVersion.MaxHorizon"
                              [disabled]="!canUpdateForecast"
                              [enabledTo]="maxForecastHorizon"
                              (dragActite)="setMaxLagAndHorizon(true)"
                              (valueChanged)="onForecastUntilDate($event)">
              </indicio-slider>
            </div>
            <div class="max-lag-btn"
                 [ngClass]="{ disabled: !canUpdateForecast }"
                 (click)="increaseHorizon()">
              <i class="ion-android-add-circle"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row marign-top">
        <div class="col-xs-12">

          <div class="grid-system center-vertical"
               style="--grid-template: 1fr 100px">
            <div>Maximum lag: {{ maxLag }} {{ activePriority === 'lag' ? '(priority)' : '' }}</div>
            <div style="text-align: end;">Max: {{ maxMaxLag }}</div>
          </div>
          <div class="grid-system center-vertical"
               style="--grid-template: 30px 1fr 30px; gap: 5px">
            <div class="max-lag-btn"
                 [ngClass]="{ disabled: !canUpdateForecast }"
                 (click)="decreaseLag()">
              <i class="ion-android-remove-circle"></i>
            </div>
            <div>
              <indicio-slider [title]="'Max lag recommendation: ' + fVersion.Frequency"
                              [current]="maxLag"
                              [from]="1"
                              [enabledFrom]="1"
                              [to]="12"
                              [disabled]="!canUpdateForecast"
                              [enabledTo]="maxMaxLag"
                              (dragActite)="setMaxLagAndHorizon(false)"
                              (valueChanged)="setLag($event)">
              </indicio-slider>
            </div>
            <div class="max-lag-btn"
                 [ngClass]="{ disabled: !canUpdateForecast }"
                 (click)="increaseLag()">
              <i class="ion-android-add-circle"></i>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-xs-6 col-no-padding"
         style="margin-top: -8px">
      <div class="data-table"
           style="width: 250px; float: right;">
        <div class="data-table__body"
             style="margin-top: 0 !important;max-height:100%;border: none;">
          <ng-container *ngTemplateOutlet="dateTemplate; context: {
                title: 'Data start',
                fn: onDataStartDate.bind(this),
                min: _dateArrayDate[earliestDataStartDateIdx],
                max: _dateArrayDate[latestDataStartDateIdx],
                value: dataStartDatePicked, isOos: false }">
          </ng-container>
          <ng-container *ngTemplateOutlet="dateTemplate; context: {
              title: 'Forecast start',
              fn: onForecastStartDate.bind(this),
              min: _dateArrayDate[earliestForecastStartDateIdx],
              max: _dateArrayDate[latestForecastStartDateIdx],
              value: forecastStartDatePicked,
              isOos: false }">
          </ng-container>
          <ng-container *ngTemplateOutlet="dateTemplate; context: {
              title: 'Forecast until',
              fn: onForecastUntilDate.bind(this),
              min: _dateArrayDate[earliestForecastUntilDateIdx],
              max: _dateArrayDate[latestForecastUntilDateIdx],
              value: forecastUntilDatePicked,
              isOos: false }">
          </ng-container>
          <ng-container *ngTemplateOutlet="showANumTemplate; context: {
              title: 'Observation count',
              value: activeObservationCount,
              desc: 'Datapoints used in forecast' }">
          </ng-container>
          <ng-container *ngTemplateOutlet="showANumTemplate; context: {
              title: 'Train set size',
              value: trainSetSize,
              desc: 'Datapoints used when training a model' }">
          </ng-container>
          <ng-container *ngTemplateOutlet="showANumTemplate; context: {
              title: 'Test set size',
              value: oosEnabled ? testSetSize : 'N/A',
              desc: 'Datapoints used when testing a model',
              disabled: !oosEnabled
            }">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #dateTemplate
             let-title="title"
             let-fn="fn"
             let-min="min"
             let-max="max"
             let-value="value"
             let-oos="oos"
             let-isOos="isOos">
  <div class="body__row body__row--center-height box-height"
       style="background: none;border-bottom: none;">
    <div class="column left-align"
         [class]="{ disabled: isOos && !oos }"
         style="width:150px; font-size:14px; border-left: none; padding: 0 5px 0 0">{{ title }}</div>
    <div class="column left-align"
         style="width:100px; font-size:14px; border-left: none; padding: 0 5px 0 0">
      <div>
        <indicio-date-picker [periodicity]="fVersion.Periodicity"
                             [disabled]="!canUpdateForecast || (isOos && !oos)"
                             (selectDateEvent)="fn($event)"
                             [min]="min"
                             [max]="max"
                             [value]="value">
        </indicio-date-picker>
        <div *ngIf="isOos && !oos"
             class="disabled"
             style="position: absolute; top: 8px">N/A</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showANumTemplate
             let-title="title"
             let-value="value"
             let-desc="desc"
             let-disabled="disabled">
  <div class="body__row body__row--center-height box-height"
       [class]="{ disabled: disabled } "
       style="background: none;border-bottom: none;">
    <div class="column left-align"
         style="width:150px; font-size:14px; border-left: none; padding: 0 5px 0 0;"
         indicioTippy="{{ desc }}">
      {{ title }}
    </div>
    <div class="column left-align"
         style="width:100px; font-size:14px; border-left: none; padding: 0 5px 0 0;">
      {{ value }}
    </div>
  </div>
</ng-template>
