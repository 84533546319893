import { PusherInfoMessageDTO } from '@core/services/pusher/dtos/pusher-info-message.dto';
import { ForecastMetaDTO } from '@core/store/forecast/dtos/forecast/forecast-meta-dto';
import { ScriptCalculateInfoDTO } from '@core/store/forecast/dtos/misc/script-calculate-info-dto';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { CompanyModel } from '../company/company.model';
import { ProjectModel } from '../project/project.model';
import { ForecastVersionDateRangeDTO } from './dtos/forecast-version/forecast-version-meta-dto';
import { RefreshForecastVariableStepEventArgsDTO, SwapVariablesStepEventArgsDTO } from './dtos/forecast-version/pusher-event-args.dto';
import { ForecastModelFilterSettingsDTO } from './dtos/model-filters/forecast-model-filter-settings-dto';
import { ForecastVersionModel } from './models/forecast-version.model';
import { ScriptName } from '@core/types/script.name.type';

export namespace ForecastActions {

  export class GetDateInfoSuccess {
    public static readonly type = '[Forecast] Get date-info success';
    constructor(public dto: ForecastVersionDateRangeDTO) { }
  }
  export class FetchCanCalc {
    public static readonly type = '[Forecast] Fetch can calc';
    constructor(public forecastVersionId: string) { }
  }

  export class UpdateCalcVsStatus {
    public static readonly type = '[Forecast] update var select can-calc status';
    constructor(public forecastVersionId: string, public canCalc: boolean) { }
  }

  export class RemoveAllResults {
    public static readonly type = '[Forecast] Remove all results (uni, multi, vs and scenario)';
    constructor(public forecastVersionId: string) { }
  }

  export class SetRefreshFVarStepInfo {
    public static readonly type = '[Forecast] Set Refresh FVar step info';
    constructor(public forecastVersionId: string, public obj: RefreshForecastVariableStepEventArgsDTO) { }
  }

  export class SetSwapVariableStepInfo {
    public static readonly type = '[Forecast] Set swap variable step info';
    constructor(public forecastVersionId: string, public obj: SwapVariablesStepEventArgsDTO) { }
  }

  export class UpdateForecastVersionMetaFields {
    public static readonly type = '[Forecast] update forecast version meta fields';
    constructor(public forecastVersionId: string, public obj: object) { }
  }

  export class RemoveScenarioResults {
    public static readonly type = '[Forecast] Remove scenario results';
    constructor(public forecastVersionId: string, public scenarioIds: string[]) { }
  }

  export class RemoveUniResults {
    public static readonly type = '[Forecast] Remove uni results';
    constructor(public forecastVersionId: string, public modelNames: string[]) { }
  }

  export class RemoveMultiResults {
    public static readonly type = '[Forecast] Remove multi results';
    constructor(public forecastVersionId: string, public modelNames: string[]) { }
  }

  export class NotifyUniTriggered {
    public static readonly type = '[Forecast] Notify user about something';
    constructor(public forecastVersionId: string, public reason: string) { }
  }

  export class ScriptTriggered {
    public static readonly type = '[Forecast] Script is triggered';
    constructor(public forecastVersionId: string, public scriptType: ScriptName) { }
  }

  export class GenericMessage {
    public static readonly type = '[Forecast] Generic message';
    constructor(public forecastVersionId: string, public Dto: PusherInfoMessageDTO) { }
  }

  export class RemoveIndicatorWarnings {
    public static readonly type = '[Forecast] Remove indicator warnings';
    constructor(public forecastVersionId: string) { }
  }

  export class CreatedForecastVariable {
    public static readonly type = '[Forecast] created forecast variable';
    constructor(
      public fVersionId: string,
      public fVarId: string,
      public sourceVarId: string
    ) { }
  }

  export class AddedMainVariable {
    public static readonly type = '[Forecast] Added main variable to a forecast';
    constructor(
      public forecastId: string,
      public fVersionId: string,
      public fVarId: string,
      public sourceVarId: string
    ) { }
  }

  export class UpdatedForecast {
    public static readonly type = '[Forecast] Updated forecast';
    constructor(
      public forecastId: string
    ) { }
  }

  export class ClearWarnings {
    public static readonly type = '[Forecast] Clear forecast warnings';
    constructor(
      public forecasVersiontId: string
    ) { }
  }
}

export class ForecastModuleReloadAction {
  public static readonly type = '[Forecast] Force reload of Forecast-module';
  constructor(
    public forecastVersion: ForecastVersionModel,
    public company: CompanyModel,
    public project: ProjectModel,
    public isSame: boolean = false
  ) { }
}

export class GetForecastModelFilterSettingsAction {
  public static readonly type = '[Forecast] Get Forecast model filter settings';
  constructor(public forecastId: string, public settings: ForecastModelFilterSettingsDTO) { }
}

export class GetForecastsInProjectSuccessAction {
  public static readonly type = '[Forecast] Get forecast metas in project success';
  constructor(public models: ForecastMetaDTO[]) { }
}

export class GetForecastMetaSuccessAction {
  public static readonly type = '[Forecast] Get forecast meta success';
  constructor(public model: ForecastMetaDTO) { }
}

export class GetForecastVersionSuccessAction {
  public static readonly type = '[Forecast] Get forecast version success';
  constructor(public model: ForecastVersionModel) { }
}

export class SetActiveForecastVersionSuccessAction {
  public static readonly type = '[Forecast] Set forecast version success';
  constructor(public model: ForecastVersionModel) { }
}

export class GetForecastSuccessAction {
  public static readonly type = '[Forecast] Get forecast by id success';
  constructor(public forecast: ForecastModel) { }
}

export class GetNowcastSuccessAction {
  public static readonly type = '[Forecast] Get nowcast success';
  constructor(public forecast: ForecastModel) { }
}

export class RemoveNowcastSuccessAction {
  public static readonly type = '[Forecast] Remove nowcast success';
  constructor(public parentFversionId: string, public nowcastedFvarId: string) { }
}

export class CreateForecastSuccessAction {
  public static readonly type = '[Forecast] Create new forecast';
  constructor(public forecastId: string, public projectId: string) { }
}

export class RemoveForecastSuccessAction {
  public static readonly type = '[Forecast] Remove forecast';
  constructor(public forecastId: string) { }
}

export class SetForecastAsNotFetchedAction {
  public static readonly type = '[Forecast] Set forecast as not fetched';
  constructor(public forecastId: string) { }
}

export class GetCanCalculateInfoSuccessAction {
  public static readonly type = '[Forecast] Get can calculate info success';
  constructor(
    public forecastVersionId: string,
    public calcInfo: ScriptCalculateInfoDTO
  ) { }
}

export class SetActiveForecastIdAction {
  public static readonly type = '[Forecast] Set new active ForecastId';
  constructor(
    public forecastId: string
  ) { }
}
