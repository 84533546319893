import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WikiPageAdminMetaDTO } from '@core/store/wiki/wiki.entities';
import { WikiService } from '@core/store/wiki/wiki.service';
import { AdminWikiService } from '@modules/admin/components/content/wiki/wiki-admin.service';

export interface AdminWikiPageCollectionDialogData {

}

@Component({
  selector: 'indicio-page-collection-dialog',
  templateUrl: 'page-collection.dialog.html',
  styleUrls: ['page-collection.dialog.less']
})
export class AdminWikiPageCollectionDialogComponent {
  public static Id: string = 'AdminWikiPageCollectionDialogComponent';

  public pages: WikiPageAdminMetaDTO[] = [];
  public filterRegex: RegExp;

  constructor(
    public dialogRef: MatDialogRef<AdminWikiPageCollectionDialogComponent>,
    public wikiService: WikiService,
    private adminService: AdminWikiService,
    @Inject(MAT_DIALOG_DATA) public data: AdminWikiPageCollectionDialogData) {
    this.adminService.getAllWikiPages()
      .then((pages) => {
        this.pages = pages;
      });
  }

  public filter(filter: string) {
    if (!filter) { this.filterRegex = null; return; }
    this.filterRegex = new RegExp(filter, 'i');
  }

  public getPageParent(pate: WikiPageAdminMetaDTO) {
    return this.adminService.wikiPages.find((c) => c.Slug === pate.ParentSlug);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  usePage(page: WikiPageAdminMetaDTO) {
    const string = `[${page.Title}](indicio-wiki://${page.Slug} "${page.Title}")`;
    this.dialogRef.close(string);
  }
}
