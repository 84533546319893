<div>
  <div *ngIf="codeValid"
       class="container-xsmall content-margin">
    <indicio-icon-and-text-logo [showText]="false"
                                [showIcon]="false"></indicio-icon-and-text-logo>
    <div class="login-form bg-white"
         data-test-id="reset-password-container">
      <h1 class="dark">Reset password</h1>
      <h4 class="dark">Please enter a new password:</h4>
      <div class="row extra-margin-top">
        <div class="col-xs-4 ">
          <button class="btn right cursor-pointer"
                  style="margin: 0;border-radius: 5px;height: auto;"
                  type="button"
                  (click)="addGeneratedPassword()">
            <p style="font-size: 11px;margin:1px;">Generate password</p>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <input [type]="showPass ? 'text' : 'password'"
                 class="form-control"
                 matInput
                 autocomplete="new-password"
                 [(ngModel)]="password"
                 (keyup)="checkPassword()"
                 name="newpw1"
                 placeholder="Type new password"
                 required>
          <i style="margin-left: 2px; font-size: 15px;vertical-align: middle;"
             title="Show password"
             [class]="showPass ? 'ion-eye' : 'ion-eye-disabled'"
             class="h-grey a-lightgrey cursor-pointer"
             style="position: absolute;
             right: 15px;
             top: 23px;color:black;"
             (click)="showPassword()"
             matSuffix></i>
          <mat-hint *ngIf="passwordStrength === 0"><span class="error-text">Weak password</span></mat-hint>
          <mat-hint *ngIf="passwordStrength === 1"><span class="warning-text">Medium password</span></mat-hint>
          <mat-hint *ngIf="passwordStrength === 2"><span class="success-text">Strong password</span></mat-hint>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 text-center">
          <input type="password"
                 [(ngModel)]="password2"
                 name="password2"
                 class="form-control"
                 placeholder="Re-type password"
                 (keyup.enter)="resetPassword()"
                 required>
        </div>
      </div>
      <div class="row signup-navigation">
        <div class="col-xs-4">
          <button class="btn landing blue left outline light signup-btn"
                  style="font-size: 1rem;"
                  (click)="backToLogin()">Back to login</button>
        </div>
        <div class="col-xs-4"></div>
        <div class="col-xs-4">
          <button class="btn landing light right signup-btn"
                  style="font-size: 1rem;"
                  (click)="resetPassword()">Submit</button>
        </div>
      </div>
    </div>
    <indicio-about-footer></indicio-about-footer>
  </div>
