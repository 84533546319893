import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { ClientDTO } from '@core/store/client/dtos/client-dto';
import { CompanyMapper } from '@core/store/company/company-mapper';
import { AsyncUtils } from '@shared/utils/async.utils';
import { CompanyModel } from '../company/company.model';
import { ClientMapper } from './client-mapper';
import { ClientSettingsMapper } from './client-settings.mapper';
import { ClientModel } from './client.model';
import { DisplayFilterModel } from './display-filter.model';
import { ClientSecurityDTO } from './dtos/client-security-dto';
import { ClientSettingsDTO } from './dtos/client-settings.dto';
import { ProfileModel } from './profile.model';

class ClientResponse {
  public client: ClientModel;
  public companies: CompanyModel[];
  public profile: ProfileModel;
  public clientSettings: ClientSettingsDTO;
  public displayFilters: DisplayFilterModel[];
}

@Injectable({
  providedIn: 'root'
})
export class ClientBackendService {

  constructor(
    private base: HttpService,
    private clientMapper: ClientMapper,
    private companyMapper: CompanyMapper,
    private clientSettingsMapper: ClientSettingsMapper
  ) { }

  public sendIAmActive() {
    return this.base.get('client/mark-as-active');
  }

  public async getClient(): Promise<ClientResponse> {
    let tries = 0;
    while (true) {
      try {
        const clientRes = await this.base.get<ClientDTO>('client').then(x => x.body);
        const companies = clientRes.Companies.map(company => this.companyMapper.mapMeta(company));
        const client = this.clientMapper.map(clientRes);
        const profile = this.clientMapper.mapProfile(clientRes.Profile);
        const clientSettings = this.clientSettingsMapper.map(clientRes.ClientSettings);
        const displayFilters = clientRes.DisplayFilters.map(x => this.clientMapper.mapDisplayFilter(x));
        return { client, companies, profile, clientSettings, displayFilters };
      } catch (err) {
        if (tries === 2) {
          throw err;
        }
        tries++;
        console.error('Failed to fetch client, retrying in 2 seconds', err);
        await AsyncUtils.sleep(2000);
      }
    }
  }

  public setAutomaticLogout(automatic: boolean) {
    return this.base.post<null>('my/profile/set-automatic-logout', automatic);
  }

  public fetchSecuritySettings() {
    return this.base.post<ClientSecurityDTO>('client/fetch-security-settings', {}).then(({ body }) => body);
  }
}
