import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentService, TwoFactorProviders } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { MFASupplier } from '@modules/lang/language-files/mfa-suppliers';
import { LanguageService } from '@modules/lang/language.service';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

@Component({
  selector: 'indicio-2fa-settings',
  templateUrl: './2-factor-settings.component.html',
  styleUrls: ['./2-factor-settings.component.less']
})
export class Authentication2FASettingsComponent {

  inProgress = false;

  suppliers: TwoFactorProviders[] = ['GoogleAuthenticator'];
  suppliersDisplay: MFASupplier[] = [];

  oneTimeCodes: string[] = [];
  manualSetupCode: string = 'Test';
  image: any;
  code_to_verify: number;

  new_method: TwoFactorProviders = 'GoogleAuthenticator';
  MFAError: string = null;
  setupStep: number = 1;

  public warningMessage: string = null;
  public modalWarningMessage: string = null;

  @Input() preventDisabled2FA: boolean = false;
  @Input() checkStoredAuth: boolean = true;
  @Input() useSkip: boolean = false;
  @Input() mustHave2FA: boolean = false;

  @Output() closeEvent = new EventEmitter();
  @Output() successEvent = new EventEmitter();
  @Output() skipEvent = new EventEmitter();

  constructor(
    public store: Store,
    public authService: AuthFrontendService,
    public profileService: ProfileFrontendService,
    public appearance: AppearanceService,
    public env: EnvironmentService,
    private statusService: StatusService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private lang: LanguageService) {
    this.suppliersDisplay = this.lang.mapEntries(this.suppliers, this.lang.mfaSuppliers);
  }

  public reset() {
    this.new_method = null;
    this.MFAError = null;
    this.setupStep = 1;
    this.oneTimeCodes = [];
    this.manualSetupCode = '';
    this.image = null;
    this.code_to_verify = null;
  }

  public verify2FA() {
    this.inProgress = true;
    this.authService.verify2FA({
      Code: this.code_to_verify,
      RememberMe: false,
      RememberToken: null,
      BackupCode: null
    }).then(() => {
      this.reset();
      this.authService.setupInProcess = true;
      this.statusService.setMessage('2-factor enabled', 'Success', true);
      if (this.checkStoredAuth) {
        this.authService.checkStoredAuth(false).finally(() => {
          this.authService.setupInProcess = false;
        });
      }
      this.successEvent.emit(true);
    })
      .catch(err => { this.warningMessage = err.error; this.statusService.setError(err, true); })
      .finally(() => this.inProgress = false);
  }

  public onSkip() {
    if (this.useSkip) {
      this.skipEvent.emit(true);
    }
  }

  public disable2FA() {
    this.inProgress = true;
    const ref = this.dialogService.openTextInputDialog({
      Title: 'Disable 2FA',
      Text: 'Enter two-factor code or backup code to disable two-factor authentication.',
      Label: '2fa/backup-code',
      Value: '',
      placeholder: '',
      ConfirmText: 'Proceed'
    });

    ref.subscribe((code: string) => {
      if (!!!code) { this.inProgress = false; return; }
      this.authService.verify2FA({
        Code: /^\d+$/.test(code) ? +code : null,
        BackupCode: /^\d+$/.test(code) ? null : code,
        RememberMe: false,
        RememberToken: null
      }).then(() => {
        this.proceedDisable2FA();
      }).catch(() => {
        this.inProgress = false;
        this.dialogService.openConfirmDialog({
          Title: 'Disable 2FA: Failed',
          Message: 'Provided two-factor or backup code was incorrect.',
          ConfirmText: 'OK',
          Style: 'primary',
          ExtraWarning: '',
        }, { width: '400px' });
      });
    });
  }

  private proceedDisable2FA() {
    const is2FaForced = this.authService.is2FaForced();
    const ref = this.dialogService.openConfirmDialog({
      Title: 'Disable 2FA: Confirm',
      Message: 'Are you sure you want to disable two-factor authentication?',
      ConfirmText: 'Yes, disable',
      Style: 'warn',
      ExtraWarning: is2FaForced ? 'Your company requires two-factor to be enabled. You must reactivate two-factor with a provider of your choice immediately, or you will lose access to your account.' : '',
      CancelText: 'No'
    });

    ref.subscribe((proceed: boolean) => {
      if (!proceed) { this.inProgress = false; return; }
      this.authService.disable2FA()
        .then(() => {
          this.modalWarningMessage = 'This may take a while...';
          this.statusService.setMessage('2-factor disabled', 'Success', true);
          this.authService.checkStoredAuth(false).finally(() => {
            this.inProgress = false;
            this.modalWarningMessage = null;
          });
        })
        .catch(err => {
          this.inProgress = false;
          this.modalWarningMessage = null;
          this.statusService.setError(err);
          this.warningMessage = err.error;
        });
    });
  }

  public setup2FA() {
    const body = { Application: this.new_method, CountryCode: undefined, PhoneNumber: undefined };
    this.inProgress = true;

    this.authService.setup2FA(body)
      .then(ok => {
        this.setupStep = 2;
        switch (this.new_method) {
          case 'GoogleAuthenticator':
            this.finishGoogleSetup(ok.body);
            break;
        }
      })
      .catch(err => this.statusService.setError(err, true))
      .finally(() => this.inProgress = false)
      ;
  }

  private finishGoogleSetup(body: any) {
    this.oneTimeCodes = body.BackupCodes;
    this.manualSetupCode = body.ManualSetupCode;
    this.image = body.ImageUrl;
  }

  public close() {
    this.closeEvent.emit(true);
  }

}
