import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';

import { AssessmentMapper } from '@core/store/assessment/assessment-mapper';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { AssessmentDTO } from '@core/store/assessment/dtos/assessment-dto';

@Injectable({
  providedIn: 'root'
})
export class AssessmentBackendService {

  constructor(
    private http: HttpService,
    private assessmentMapper: AssessmentMapper
  ) { }

  public createAssessment(model: AssessmentModel) {
    const dto = this.assessmentMapper.toCreate(model);
    return this.http.post<AssessmentDTO>(`forecast/version/${model.ForecastVersionId}/assessments`, dto)
      .then(resp => this.assessmentMapper.map(resp.body));
  }

  public getAssessment(ForecastVersionId: string, assessmentId: string) {
    return this.http.get<AssessmentDTO>(`forecast/version/${ForecastVersionId}/assessments/${assessmentId}`)
      .then(resp => this.assessmentMapper.map(resp.body));
  }

  public getAllAssessmentsInForecast(forecastId: string) {
    return this.http.get<AssessmentDTO[]>(`forecast/version/${forecastId}/assessments/`)
      .then(resp => resp.body.map(x => this.assessmentMapper.map(x)));
  }

  public updateAssessment(assessment: AssessmentModel) {
    const dto = this.assessmentMapper.toUpdate(assessment);
    return this.http.put<null>(`forecast/version/${assessment.ForecastVersionId}/assessments/${assessment.AssessmentId}`, dto)
      .then(_ => assessment);
  }

  public updateAssessmentOverview(assessment: AssessmentModel) {
    const dto = this.assessmentMapper.toUpdateOverview(assessment);
    return this.http.post<null>(`forecast/version/${assessment.ForecastVersionId}/assessments/${assessment.AssessmentId}/active`, dto)
      .then(_ => assessment);
  }

  public removeAssessment(assessment: AssessmentModel) {
    return this.http.delete<null>(`forecast/version/${assessment.ForecastVersionId}/assessments/${assessment.AssessmentId}`);
  }
}
