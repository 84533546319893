
import { Injectable } from '@angular/core';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearStateAction } from '../auth/auth.actions';
import { CompanyActions } from '../company/company.actions';
import {
  GetAssessmentsInForecastVersionSuccessAction,
  GetAssessmentSuccessAction,
  RemoveAssessmentSuccessAction
} from './assessment.actions';



class AssessmentStateModel {
  Assessments: AssessmentModel[];
}
@State<AssessmentStateModel>({
  name: 'assessments',
  defaults: {
    Assessments: []
  }
})
@Injectable()
export class AssessmentState {

  @Selector()
  static assessments(state: AssessmentStateModel) { return state.Assessments; }

  @Action(GetAssessmentSuccessAction)
  getAssessmentSuccess(ctx: StateContext<AssessmentStateModel>, action: GetAssessmentSuccessAction) {
    const assessments = ctx.getState().Assessments;
    assessments.addOrUpdate(action.assessment);
    ctx.patchState({ Assessments: [...assessments] });
  }

  @Action(GetAssessmentsInForecastVersionSuccessAction)
  getAssessmentsInForecastSuccess(ctx: StateContext<AssessmentStateModel>, action: GetAssessmentsInForecastVersionSuccessAction) {
    const assessments = ctx.getState().Assessments;
    action.assessments.forEach(a => assessments.addOrUpdate(a));
    ctx.patchState({ Assessments: [...assessments] });
  }

  @Action(RemoveAssessmentSuccessAction)
  getAllAssessmentsInForecast(ctx: StateContext<AssessmentStateModel>, action: RemoveAssessmentSuccessAction) {
    const assessments = ctx.getState().Assessments;
    assessments.removeById(action.assessmentId);
    ctx.patchState({ Assessments: [...assessments] });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  logoutUser(ctx: StateContext<AssessmentStateModel>) {
    ctx.patchState({ Assessments: [] });
  }
}
