import { Component, Inject, ViewEncapsulation } from '@angular/core';


import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { WIKIPDFSettingsDTO, WikiPageAdminMetaDTO } from '@core/store/wiki/wiki.entities';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { AdminWikiService } from '@modules/admin/components/content/wiki/wiki-admin.service';
import { ArrayUtils } from '@shared/utils/array.utils';
import { ValueUtils } from '@shared/utils/value.utils';

export class WikiOrderAdminDialogData { }
class WikiPageTree extends WikiPageAdminMetaDTO {
  children: WikiPageTree[];
}

@Component({
  selector: 'indicio-wiki-order-dialog',
  templateUrl: './wiki-order.dialog.component.html',
  styleUrls: ['./wiki-order.dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class WikiOrderAdminDialogComponent extends DialogV2BaseDialog<WikiOrderAdminDialogComponent> {

  public static Id: string = 'WikiOrderAdminDialogComponent';

  public treeData: WikiPageTree[] = [];

  public lastMovedChildSlug: string = null;
  public lastMovedTimeout: any = null;


  // Frontend flags
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: WikiOrderAdminDialogData,
    private service: AdminWikiService,
    private status: StatusService,
    dialogRef: MatDialogRef<WikiOrderAdminDialogComponent>,
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.treeData = this.sortArrayWithChildren(this.service.wikiPages, null);
    this.initialized = true;
  }

  public findParent(page: WikiPageAdminMetaDTO) {
    return ArrayUtils.findDeep(this.treeData, 'children', 'Slug', page.ParentSlug);
  }

  public toggleInclude(page: WikiPageTree, forcedValue: boolean = null) {
    page.IncludePDF = forcedValue != null ? forcedValue : !page.IncludePDF;
    page.children.forEach(child => this.toggleInclude(child, page.IncludePDF));
  }

  public moveChild(child: WikiPageAdminMetaDTO, parentSlug: string, direction: 1 | -1) {
    const parent = this.findParent(child);
    const childPosition = parent.children.findIndex(c => c.Slug === child.Slug);

    const newChildPosition = childPosition + direction;
    if (newChildPosition < 0 || newChildPosition >= parent.children.length) {
      return;
    }

    this.lastMovedChildSlug = child.Slug;

    if (this.lastMovedTimeout) {
      this.lastMovedTimeout = clearTimeout(this.lastMovedTimeout);
    }

    this.lastMovedTimeout = setTimeout(() => {
      this.lastMovedChildSlug = null;
      this.lastMovedTimeout = null;
    }, 1000);

    // Move child in the array
    parent.children.splice(childPosition, 1);
    parent.children.splice(childPosition + direction, 0, child);

    // Update order of all children
    parent.children.forEach((c, i) => {
      c.Order = i + 1;
      c.frontendOrder = ValueUtils.AddZeroUnderTen(i + 1);
    });
  }


  private sortArrayWithChildren(array: WikiPageAdminMetaDTO[], parentSlug = null) {
    const sortedArray = [];

    // Add items with the given parentSlug
    const itemsWithParent = array.filter(item => item.ParentSlug === parentSlug);
    for (const item of itemsWithParent) {
      // Recursively add children of this item
      let children = this.sortArrayWithChildren(array, item.Slug);
      // Set order of children
      children.sort((a, b) => a.Order - b.Order);
      // Set frontend order of children
      children = children.map(c => Object.assign(new WikiPageAdminMetaDTO, c));
      children.forEach((child, i) => {
        child.frontendOrder = ValueUtils.AddZeroUnderTen(i + 1);
        child.Order = i;
      });
      // Add item with its children to the sorted array
      sortedArray.push({ ...item, children });
    }

    return sortedArray;
  }

  private treeToList(page: WikiPageTree): WikiPageAdminMetaDTO[] {
    const result = [];
    result.push(page);
    const children = page.children || [];
    children.forEach(child => {
      const childResult = this.treeToList(child);
      result.push(...childResult);
    });
    return result;
  }

  public back() {
    this.dialogRef.close();
  }

  public save() {
    this.isSaving = true;
    const dto: WIKIPDFSettingsDTO[] = this.treeToList(this.treeData[0]).map(page => ({ Slug: page.Slug, Order: page.Order, IncludePDF: page.IncludePDF }));
    this.service.setPDFSettings(dto)
      .then(() => this.status.setMessage('PDF settings saved', 'Success'))
      .then(() => this.dialogRef.close())
      .finally(() => this.isSaving = false);
  }

}
