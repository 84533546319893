import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { DialogV2BaseService } from '@dialogs/base/dialogs.V2.base-service';
import { take } from 'rxjs/operators';
import { CalculateDialogComponent } from './calculate/calculate.dialog';
import { CalculateDialogData } from './calculate/calculate.dialog.constants';


@Injectable({ providedIn: 'root' })
export class AutomationDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openCalcResultsDialog(data: CalculateDialogData) {
    const config: MatDialogConfig = { width: '850px', position: { top: '6%' } };
    const cfg = this.getConfig(CalculateDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, boolean>(CalculateDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }
}
