import { Def } from '@core/decorators/def';

export class EmployeeMetaDTO {
  @Def() ClientId: string;
  @Def() Email: string;
}

export class EmployeeDTO extends EmployeeMetaDTO {
  @Def() FirstName: string;
  @Def() LastName: string;
  @Def() CompanyRole: string;
  @Def() CompanyPosition: string;
  @Def() SharedProjectRole: string;
  @Def() Joined: string;
  @Def() Disabled: boolean;
  // Fields below belong to "invite" and not employment DB table
  @Def() PendingSignup: boolean;
  @Def() Expires: string;
  @Def() CreatedDate: string;
}
