import { Component, Input } from '@angular/core';

@Component({
  selector: 'indicio-spinner',
  template: '<mat-spinner [style.width.px]="size" [style.height.px]="size"></mat-spinner>',
})
export class IndicioSpinnerComponent {

  @Input() size: number = 14;
  @Input() color: string;

  public get colorVar() { return `--mdc-circular-progress-active-indicator-color: ${this.color}`; }
}
