import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnChanges, OnDestroy, Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'indicio-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchableDropdownComponent<T> implements OnChanges, OnDestroy {

  @Input() label: string;
  @Input() optionDisplay: string = null;
  @Input() rightOptionDisplay: string = null;
  @Input() optionTitle: string = null;
  @Input() values: T[];
  @Input() initializeWithFirstValue = true;
  @Input() required = false;
  @Input() selectedValue: T = null;
  @Input() dropdownClass: string;
  @Input() multiple = false;
  @Input() disabled: boolean = false;

  @Output() valueChangeEvent = new EventEmitter<T>();

  // Properties
  private _onDestroy = new Subject<void>();
  public mainControl: FormControl<T>;
  public searchControl: FormControl<string>;
  public filteredValues: T[];

  public ngOnChanges() {
    this.setup();
  }

  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setup() {
    if (!this.values) { return; }
    this.mainControl = new FormControl<T>(null);
    this.searchControl = new FormControl<string>('');
    this.filteredValues = this.values.slice();

    this.setInitialValue();

    this.searchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(_ => this.filter());
    this.mainControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(val => this.valueChangeEvent.emit(val));

    if (this.disabled) {
      this.mainControl.disable();
    }
  }

  private setInitialValue() {
    const initial = this.mainControl.value;
    if (this.selectedValue) {
      this.mainControl.setValue(this.selectedValue);
    } else if (this.initializeWithFirstValue && this.values.length > 0) {
      this.mainControl.setValue(this.values[0]);
    }

    if (initial !== this.mainControl.value) {
      this.valueChangeEvent.emit(this.mainControl.value);
    }
  }

  private filter() {
    let search = this.searchControl.value;
    if (!search) {
      return this.filteredValues = this.values.slice();
    }

    this.filteredValues = this.values.filter(e => {
      const val = this.optionDisplay ? e[this.optionDisplay] : e;
      const regex = new RegExp(search, 'i');
      return regex.test(val);
    });
  }
}
