import { ReportModel } from '@core/store/reports/models/report.model';
import { FiscalYearHelper } from './fiscalYear.helper';
import { OverviewTemplateMapper } from './overview.mapper';

export namespace ReportTools {
  export function ConvertReport<T>(report: ReportModel) {
    switch (report.Template) {
      case 'fiscal-year':
        return FiscalYearHelper.generateReport(report) as T;
      case 'overview':
        return OverviewTemplateMapper(report) as T;
      default:
        break;
    }
  }
}

