import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { StringUtils } from '@shared/utils/string.utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchableHierarchy } from './searchable-hierarchy.types';


@Component({
  selector: 'indicio-searchable-hierarchy-dropdown',
  templateUrl: './searchable-hierarchy.component.html',
  styleUrls: ['./searchable-hierarchy.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchableHierarchyDropdownComponent implements OnChanges, OnDestroy {

  @ViewChild('selector') dropdown: MatSelect;

  @Input() label: string;
  @Input() rootNode: SearchableHierarchy.Node;
  @Input() required = false;

  @Output() valueChangeEvent = new EventEmitter<SearchableHierarchy.Node>();

  // Properties
  public selectedNode: SearchableHierarchy.Node;
  public flatList: SearchableHierarchy.Node[];
  public filteredNodes: SearchableHierarchy.Node[] = [];

  private _onDestroy = new Subject<void>();
  public searchControl: FormControl<string>;
  private initialized = false;

  public ngOnChanges() {
    this.setup();
  }

  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setup() {
    if (!this.rootNode || this.initialized) { return; }
    // Create a flat list of all nodes
    this.flatList = this.flattenValues();
    // Setup main and search controll
    this.searchControl = new FormControl<string>('');
    // Set the root node as the currently active node - this will not be emitted.
    this.selectedNode = this.rootNode;
    // Setup search subscription
    this.searchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(s => this.filter());

    this.initialized = true;
  }

  public clickedNode(node: SearchableHierarchy.Node, close = false) {
    this.selectedNode = node;
    this.valueChangeEvent.emit(node);
    if (node.Children?.length > 0 && !close) {
      this.dropdown.open();
    }
  }

  public getParents() {
    const parents: SearchableHierarchy.Node[] = [];
    let current = this.selectedNode;
    while (!!current.Parent) {
      parents.push(current.Parent);
      current = current.Parent;
    }
    return parents.reverse();
  }

  private filter() {
    let search = this.searchControl.value;
    if (!search) { return this.filteredNodes = []; }
    this.filteredNodes = this.flatList.filter(e => e.Display.toLowerCase().includes(search.toLowerCase())).slice(0, 100);
  }


  /**
   * Flatten the hierarchy into a flat list with nodes with modified display values, indicating the corresponding parent.
   */
  private flattenValues(): SearchableHierarchy.Node[] {
    const fn = (n: SearchableHierarchy.Node, parents: string[]): SearchableHierarchy.Node[] => {
      const parentsToUse = parents.filter(x => !!x && !StringUtils.isNumeric(x));
      const parentStr = parentsToUse.length > 0 ? parentsToUse.join(' > ') + ' > ' : '';
      const self = { ...n, Display: parentStr + n.Display };
      if (!n.Children) { return [self]; }
      const children = n.Children.map(child => fn(child, [...parents, n.Value])).flatten();
      return [self, ...children];
    };

    return fn(this.rootNode, []);
  }
}
