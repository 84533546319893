<indicio-dialog>
  <h1 mat-dialog-title>{{ data.ScenarioId ? 'Edit' : 'Create' }} scenario
    <div class="subtitle crop-string">
      {{ edit ? 'Scenario: ' + scenario?.Item.Name : 'Creating a new scenario'}}
    </div>
  </h1>
  <div mat-dialog-content
       class="scenario-dialog">

    <ng-container *ngIf="initialized; else loadingTemplate">

      <mat-accordion>
        <!-- Stationarity-->
        <mat-expansion-panel [expanded]="viewIndex === 0"
                             (closed)="checkName()"
                             (opened)="viewIndex = 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              General info
            </mat-panel-title>
            <mat-panel-description>
              <div [ngClass]="{ 'red': missingName }"
                   [indicioTippy]="missingName ? 'Name can not be empty' : null">Set general information about the
                scenario</div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-container [ngTemplateOutlet]="generalTemplate"></ng-container>
          <mat-action-row>
            <button mat-button
                    color="primary"
                    (click)="viewIndex = 1">Next</button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="viewIndex === 1"
                             (closed)="checkVariables()"
                             (opened)="viewIndex = 1">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Variables
            </mat-panel-title>
            <mat-panel-description>
              <div [ngClass]="{ 'red': missingVariables }"
                   [indicioTippy]="missingVariables ? 'At least one variable with a value has to be present' : null">Add
                variables to the scenario</div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-container [ngTemplateOutlet]="manageTemplate"></ng-container>
          <mat-action-row>
            <button mat-button
                    color="warn"
                    (click)="viewIndex = 0">Previous</button>
            <button mat-button
                    color="primary"
                    (click)="viewIndex = 2">Next</button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="viewIndex === 2"
                             (opened)="viewIndex = 2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Settings
            </mat-panel-title>
            <mat-panel-description>
              Edit the settings of the scenario
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-container [ngTemplateOutlet]="settingsTemplate"></ng-container>
          <mat-action-row>
            <button mat-button
                    color="warn"
                    (click)="viewIndex = 1">Previous</button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>

    </ng-container>

  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">
      Cancel
    </button>
    <button mat-flat-button
            [disabled]="!savable"
            [color]="saved ? 'accent' : (edit ? (scenario?.isModified ? 'primary' : 'accent') : 'primary')"
            (click)="save()">
      {{ saved ? 'Calculate' : (edit ? (scenario?.isModified ? 'Save' : 'Calculate') : 'Create') }}
    </button>
  </div>
</indicio-dialog>

<ng-template #loadingTemplate>
  <div>
    <indicio-spinner></indicio-spinner> Loading...
  </div>
</ng-template>

<ng-template #manageTemplate>
  <indicio-scenario-dialog-manage-tab [scenario]="scenario.Item"
                                      [fVersion]="fVersion"
                                      [availableModels]="availableModels"
                                      (inputEvent)="checkVariables()"
                                      (issuesEvent)="issues = $event"></indicio-scenario-dialog-manage-tab>
</ng-template>

<ng-template #settingsTemplate>
  <indicio-scenario-dialog-options-tab [scenario]="scenario.Item"
                                       [availableModels]="availableModels"></indicio-scenario-dialog-options-tab>
</ng-template>

<ng-template #generalTemplate>
  <indicio-scenario-dialog-general-tab [scenario]="scenario.Item"
                                       (inputEvent)="checkName()"></indicio-scenario-dialog-general-tab>
</ng-template>
