<indicio-dialog>
  <h1 mat-dialog-title>Add Bloomberg credentials</h1>
  <div mat-dialog-content>
    <p>
      To connect to Bloomberg you need to add a credentials file.
      <br>
      You can either drag and drop a file or click the button below.
      <br>
      <br>
      If you haven't created a credentials file yet, you can do so by following
      the instructions on the <indicio-wiki-icon slug="guide-setup-bloomberg-data-go">wiki</indicio-wiki-icon>.
    </p>
    <ng-container [ngTemplateOutlet]="fileInputTemplate"></ng-container>
    <ng-container *ngIf="credentials"
                  [ngTemplateOutlet]="credentialsTemplate"></ng-container>
    <ng-container *ngIf="error"
                  [ngTemplateOutlet]="errorTemplate"></ng-container>
  </div>

  <div mat-dialog-actions>
    <button mat-stroked-button
            (click)="onNoClick()">
      Cancel
    </button>
    <div [indicioTippy]="!credentials ? 'Add a bloomberg credentials file before connecting' : null">
      <button mat-flat-button
              color="primary"
              [disabled]="!credentials"
              (click)="addCredentials()">
        <span *ngIf="!pending">Connect</span>
        <indicio-spinner *ngIf="pending"></indicio-spinner>
      </button>
    </div>
  </div>
</indicio-dialog>

<ng-template #fileInputTemplate>
  <div>
    <input type="file"
           accept=".txt"
           id="file"
           name="file"
           #fileinput
           style="display: none;"
           (change)="fileChange(fileinput.files)">
    <div (dragover)="allowDrop($event)"
         (dragenter)="toggleDragOver(true)"
         (dragleave)="toggleDragOver(false)"
         (drop)="drop($event)"
         data-test-id="drag-and-drop-box"
         (click)="fileinput.click()"
         class="drag-and-drop-box">
      <p><i class="ion-upload"></i></p>
      <p>Click to browse files or drag it here.</p>
    </div>
  </div>
</ng-template>

<ng-template #credentialsTemplate>
  <hr style="margin: 20px 0;">
  <h2>Credentials found <i class="ion-checkmark-circled green"></i></h2>
  <div style="--grid-template-global: 120px 1fr">
    <div class="grid-system">
      <div>Name:</div>
      <div>{{ credentials.name }}</div>
    </div>
    <div class="grid-system">
      <div>ClientId:</div>
      <div>{{ credentials.client_id }}</div>
    </div>
    <div class="grid-system">
      <div>Scopes:</div>
      <div>
        <ul>
          <li *ngFor="let scope of credentials.scopes">{{ scope }}</li>
        </ul>
      </div>
    </div>
    <div class="grid-system">
      <div>Created date:</div>
      <div>{{ credentials.creationDate | dateFormat }}</div>
    </div>
    <div class="grid-system">
      <div>Expiration date:</div>
      <div>{{ credentials.expirationDate | dateFormat }}</div>
    </div>
  </div>
</ng-template>

<ng-template #errorTemplate>
  <hr style="margin: 20px 0">
  <h2>Error <i class="ion-alert-circled red"></i></h2>
  <div>Could not parse the file supplied, please try again with a proper credentials file obtained from bloomberg.</div>
</ng-template>
