import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AlphaForexModalOpts } from './alpha-forex-modal.options';

export class OpenAlphaForexModal {
  public static readonly type = '[MODAL] Open alpha currency modal';
  data: AlphaForexModalOpts;
  constructor(
    forecastVersion: ForecastVersionModel
  ) {
    this.data = { forecastVersion };
  }
}
