<indicio-modal-base [child]="this"
                    [modalTitle]="title">
  <ng-container *ngIf="!isLoading">
    <p>Choose file(s) to upload (supported extensions: .csv .xls .xlsx) <indicio-wiki-icon
                         slug="advanced-supported-file-formats"
                         [link]="true"
                         style="margin-left: 5px;"
                         [size]="20"></indicio-wiki-icon></p>
    <ng-container *ngTemplateOutlet="fileInputTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="fileListTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="fileUpdateButtonRowTemplate">
    </ng-container>
  </ng-container>
</indicio-modal-base>

<ng-template #fileListTemplate>
  <ng-container *ngIf="singleUpdate">
    <div class="row margin-top text-center">
      <div class="col-xs-12">
        Updating file: {{ opts.file.FileName }}
      </div>
    </div>
  </ng-container>
  <div class="data-table margin-top"
       *ngIf="files.length"
       style="width: 500px">
    <div class="data-table__header">
      <div class="column header left-align"
           [ngStyle]="{ width: anyIsUpdate ? '180px' : '300px' }">Filename</div>
      <div *ngIf="anyIsUpdate"
           class="column header left-align"
           style="width: 120px">Previous filename</div>
      <div class="column header"
           style="width: 70px;">Action</div>
      <div class="column header"
           style="width: 130px;">Status</div>
    </div>
    <div class="data-table__body"
         #fileuploadscroll
         [style.maxHeight.px]="301">
      <ng-container *ngIf="!initLoading">
        <ng-container *ngFor="let fileInfo of files; let i = index;">
          <ng-container *ngTemplateOutlet="fileUploadRowTemplate; context: { i } ">
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #fileInputTemplate>
  <input type="file"
         accept=".csv, .xls, .xlsx"
         multiple
         id="file"
         data-test-id="file-form"
         name="file"
         #fileinput
         (change)="fileChange(fileinput.files)">
  <div class="row">
    <div class="col-xs-12">
      <div (dragover)="allowDrop($event)"
           (dragenter)="toggleDragOver(true)"
           (dragleave)="toggleDragOver(false)"
           (drop)="drop($event)"
           data-test-id="drag-and-drop-box"
           (click)="fileinput.click()"
           class="drag-and-drop-box">
        <p><i class="ion-upload"></i></p>
        <p>Click to browse files or drag them here.</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fileUpdateButtonRowTemplate>
  <div class="row margin-top">
    <div class="col-xs-12 center-text">
      <button class="btn full-width"
              data-test-id="upload-file-confirm-btn"
              (click)="!goneFishing ? upload() : (!pending ? actOnUploadFinished() : null)"
              [ngClass]="{ disabled: pending || !canUpload || !this.files.length }">
        <i *ngIf="pending"
           class="ion-load-c spinner"></i>
        <span *ngIf="!pending">
          {{ buttonText }}
        </span>
      </button>
    </div>
  </div>
</ng-template>


<ng-template #fileUploadRowTemplate
             let-i=i>
  <ng-container *ngIf="files[i]; let fileInfo">
    <div class="body__row body__row--center-height body__row--max-height-30">
      <div class="column left-align filename-Medium crop-string"
           [ngStyle]=" { width: !!fileInfo.OldFile ? '180px' : '300px' }"
           [indicioTippy]="'Filename: ' + fileInfo.FileToUpload.FileName">
        {{ fileInfo.FileToUpload.FileName }}
      </div>
      <div *ngIf="!!fileInfo.OldFile"
           class="column left-align filename-Medium crop-string"
           style="width: 120px"
           [indicioTippy]="'Old filename: ' + fileInfo.OldFile.FileName">
        {{ fileInfo.OldFile.FileName }}
      </div>
      <div class="column"
           style="width: 70px;">
        <span [class]="{ red: fileInfo.OldFile }"
              [indicioTippy]="!fileInfo.OldFile
              ? 'New file'
              : 'Previously uploaded by \<br>' + fileInfo.OldFile.OwnerEmail + '\<br>@ ' + (fileInfo.OldFile.CreatedDate | date: appearance.FullFormat)">
          {{ !fileInfo.OldFile ? 'New' : 'Update' }}
        </span>
      </div>
      <div class="column"
           style="width: 130px;">
        <ng-container *ngTemplateOutlet="fileUploadStatusTemplate; context: { i } ">
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #fileUploadStatusTemplate
             let-i=i>
  <ng-container *ngIf="files[i]; let fileInfo">
    <ng-container *ngIf="fileInfo.Valid">
      <ng-container *ngIf="!pending">
        <ng-container *ngTemplateOutlet="NotPendingTemplate; context: { i }"></ng-container>
      </ng-container>
      <ng-container *ngIf="pending">
        <ng-container *ngTemplateOutlet="PendingTemplate; context: { i }"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!fileInfo.Valid">
      <ng-container *ngTemplateOutlet="ErrorFileTemplate; context: { i }">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #ErrorFileTemplate
             let-i=i>
  <ng-container *ngIf="files[i]; let fileInfo">
    <span class="red"
          [indicioTippy]="'Not a valid file type ' + fileInfo.FileToUpload.errorStatus !== null ? ': ' + fileInfo.FileToUpload.errorStatus : ''">
      Unsupported
    </span>
  </ng-container>
</ng-template>

<ng-template #PendingTemplate
             let-i=i>
  <ng-container *ngIf="files[i]; let fileInfo">
    <div [indicioTippy]="fileInfo.State === 'initializing' ? 'Initializing file'
        : fileInfo.State === 'uploading' ? 'Uploading file'
        : fileInfo.State === 'waiting' ? 'Waiting for user action'
        : 'Done'">
      <i class="upload-progress"
         *ngIf="fileInfo.State !== 'waiting'"
         [ngClass]="{ 'ion-load-c spinner': fileInfo.State === 'uploading' || fileInfo.State === 'initializing',
                    'ion-checkmark-circled green': fileInfo.State === 'complete',
                    'ion-close-circled red': fileInfo.State === 'error'
                  }">
      </i>
      <span class="waiting"
            *ngIf="fileInfo.State === 'waiting'">Waiting
      </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #NotPendingTemplate
             let-i=i>
  <ng-container *ngIf="files[i]; let fileInfo">
    <ng-container *ngIf="fileInfo.State === 'waiting'">
      <div *ngIf="fileInfo.OldFile">
        <ng-container *ngIf="fileInfo.anyNonNameChanges">
          <span class="pointer dotted-underline"
                indicioTippy="File content updated. Click here to preview"
                (click)="inspectPreUploadChanges(fileInfo)">
            Changes detected
          </span>
        </ng-container>
        <ng-container *ngIf="!fileInfo.anyNonNameChanges">
          <span *ngIf="fileInfo.nameChanged"
                class="pointer dotted-underline"
                [indicioTippy]="'Filename changed. Click here to preview'"
                (click)="inspectPreUploadChanges(fileInfo)">
            Filename changed
          </span>
          <span *ngIf="!fileInfo.nameChanged"
                class="pointer dotted-underline"
                indicioTippy="Ready to upload. Click here to preview"
                (click)="inspectPreUploadChanges(fileInfo)">
            Ready to upload
          </span>
        </ng-container>
      </div>
      <span *ngIf="!fileInfo.OldFile">
        Ready to upload
      </span>
    </ng-container>
    <ng-container *ngIf="fileInfo.State === 'complete'">
      <span *ngIf="fileInfo.OldFile"
            class="pointer dotted-underline"
            indicioTippy="Upload complete, click here to inspect changes"
            (click)="inspectFileUpdatedChanges(fileInfo)">
        Uploaded
      </span>
      <span *ngIf="!fileInfo.OldFile"
            indicioTippy="Upload complete">
        Uploaded
      </span>
    </ng-container>
    <ng-container *ngIf="fileInfo.State === 'warning' && !goneFishing">
      <ng-container *ngIf="!fileInfo.FileToUpload.containsLT1Values">
        <span class="pointer dotted-underline"
              [class]="fileInfo.canUpload ? 'green' : 'orange'"
              [indicioTippy]="{ content: 'Parsing the file resulted in one or more warnings. Click here to view info', maxWidth: 250 }"
              (click)="inspectPreUploadChanges(fileInfo)">
          <ng-container *ngIf="!fileInfo.canUpload">Input required</ng-container>
          <ng-container *ngIf="fileInfo.canUpload">Ready to upload</ng-container>
        </span>
      </ng-container>
      <ng-container *ngIf="fileInfo.FileToUpload.containsLT1Values">
        <div class="warning-text"
             [indicioTippy]="{ content: 'File contains \'&lt;1\' values. These will be interpreted as \'0.5\'', maxWidth: 370 }">
          <i class="ion-alert-circled"></i>&nbsp;
          <span class="dotted-underline">Ready to upload</span>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="fileInfo.State === 'error'">
      <span class="red pointer dotted-underline"
            (click)="openErrorFileInfoModal()"
            [indicioTippy]="{
              content: goneFishing ? 'Errors occured during file upload.<br>' + fileInfo.FileToUpload.errorStatus + '<br><br>Click here to read more' : 'File contains errors and cannot be updated: ' + fileInfo.FileToUpload.errorStatus,
              maxWidth: 350
            }">
        {{ goneFishing ? 'Failed' : 'Error' }} <i class="ion-information-circled"></i>
      </span>
    </ng-container>
  </ng-container>
</ng-template>
