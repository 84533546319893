import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CreateCompanyDTO } from '@core/store/company/dtos/create-company-dto';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Country } from '@modules/lang/types/country';
import { DisplayValue } from '@modules/lang/types/display-value';


@Component({
  selector: 'indicio-create-company-general-tab',
  templateUrl: './create-company-tab.general.component.html',
  styleUrls: ['./create-company-tab.general.component.less'],
})
export class CreateCompanyTabGeneralComponent implements OnChanges {

  @Input() company: CreateCompanyDTO;
  @Input() supportUsers: { role: string, users: DisplayValue[]; }[] = [];

  public selectedCountry: Country;

  public selectedSupportUsers: DisplayValue[] = [];

  constructor(
    public appearance: AppearanceService,
    public envService: EnvironmentService,
    public authService: AuthFrontendService,
  ) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.company) { return; }
    this.setup();
  }

  public setAdministrators($event: MatSelectChange) {
    this.selectedSupportUsers = $event.value;
    this.company.Support = $event.value.map(x => x.Value);
  }

  public setCountry(country: Country) {
    this.selectedCountry = country;
    this.company.CountryCode = country.Value;
  }

  private setup() {
    this.selectedCountry = this.envService.Countries.find(x => x.Value === this.company.CountryCode);
  }
}
