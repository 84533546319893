import { Injectable } from '@angular/core';
import { FileActions } from '@core/store/file/file.actions';
import { Store } from '@ngxs/store';
import { PusherActionDTO } from '../dtos/pusher-action-dto';

export type PusherFileAction =
  'sync-list-view'
  ;

@Injectable({
  providedIn: 'root'
})
export class PusherFileBehaviourService {

  constructor(
    private store: Store,
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {

    switch (dto.Action as PusherFileAction) {
      case 'sync-list-view':
        return this.store.dispatch(new FileActions.SyncAll(dto.CompanyId));


      default:
        console.error(`Missing behaviour for action: (${dto.Action})`);
        return;
    }
  }

}
