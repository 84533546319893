
export type SecurityModalView = 'password' | '2fa' | 'sessions' | 'account';

export class SecurityModalOpts {
  view: SecurityModalView;
}

export class OpenSecuritySettingsModal {
  public static readonly type = '[MODAL] Open change password';
  data: SecurityModalOpts;
  constructor(
    view: SecurityModalView = 'password'
  ) { this.data = { view }; }
}
