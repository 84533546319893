import { Component, Input, OnChanges } from '@angular/core';
import { IFile } from '@core/interfaces/if-file';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { EmployeeMetaDTO } from '@core/store/company/dtos/employee-dto';
import { FileAccessDTO } from '@core/store/file/dtos/file-access.dto';
import { FileFrontendService } from '@core/store/file/file.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

@Component({
  selector: 'indicio-file-info-dialog-access-tab',
  templateUrl: './file-info.access-tab.html',
  styleUrls: ['./file-info.access-tab.less'],
})
export class FileInfoDialogAccessTabComponent implements OnChanges {

  @Input() file: IFile;
  @Input() companyUsers: EmployeeMetaDTO[] = [];

  public users: FileAccessDTO[];
  public possibleEditors: EmployeeMetaDTO[] = [];

  constructor(
    private fileService: FileFrontendService,
    public clientService: ClientFrontendService,
    private status: StatusService,
    public appearance: AppearanceService,
    private dialog: DialogService
  ) {
  }

  public transferOwner() {
    if (this.clientService.userEmail !== this.file.OwnerEmail) {
      return this.dialog.openInfoMessageDialog({
        Title: 'Cannot transfer owner',
        Content: 'Only the current owner can transfer the ownership of the file to another user.'
      });
    }

    if (this.file.Users.length < 2) {
      return this.dialog.openInfoMessageDialog({
        Title: 'No valid user',
        Ingress: 'You can only transfer ownership of a file to a current editor of the file.',
        Content: 'To add another user as editor of the file, click on the \'+ Add editor\' button.'
      });
    }

    const ref = this.dialog.openDropdownInputDialog({
      Title: 'Transfer file ownership',
      Text: 'Choose another user as owner of this file',
      Label: 'User',
      Values: this.file.Users.filter(x => x.Access !== 'owner').map(x => ({ Display: x.Email, Value: x.Email, Description: '' }))
    }).toPromise();

    ref.then(info => {
      if (info == null) { return; }
      this.fileService.updateAccess(this.file, { Access: 'owner', Email: info.Value, Type: 'change-owner' })
        .then(users => {
          this.status.setMessage('File owner changed', 'Success', true);
          this.file.Users = users;
          this.setUsers(users);
          this.setupPossibleEditors();
        })
        .catch(err => this.status.setError(err, true));
    });
  }

  public addEditor() {
    if (this.possibleEditors.length < 1) {
      return this.dialog.openInfoMessageDialog({
        Title: 'No viable users found',
        Content: `Only members of the same company can be added as editors of the file.
                  All eligable users have already been added as editors of this file.`
      });
    }

    const ref = this.dialog.openDropdownInputDialog({
      Title: 'Add editor',
      Text: 'An editor can update, access and invite more people to use this file',
      Label: 'User',
      ConfirmText: 'Add editor',
      Values: this.possibleEditors.map(x => ({ Display: x.Email, Value: x.Email, Description: '' }))
    }).toPromise();

    ref.then(info => {
      if (info == null) { return; }
      this.fileService.updateAccess(this.file, { Access: 'editor', Email: info.Value, Type: 'add' })
        .then(users => {
          this.status.setMessage('New file editor added', 'Success', true);
          this.file.Users = users;
          this.setUsers(users);
          this.setupPossibleEditors();
        })
        .catch(err => this.status.setError(err, true));
    });
  }

  public removeEditor(user: FileAccessDTO) {
    if (user.Access === 'owner') { return; }
    const ref = this.dialog.openConfirmDialog({
      Title: 'Remove editor',
      Message: `This will remove access to the file, but the user will still have access to all variables created from the file.
                <br><br>
                Are you sure you want to remove ${user.Email} as editor of the file?`,
      ConfirmText: 'Yes, remove',
      Style: 'warn',
      CancelText: 'No',
    }).toPromise();

    ref.then(remove => {
      if (!remove) { return; }
      this.fileService.updateAccess(this.file, { Access: 'editor', Email: user.Email, Type: 'remove' })
        .then(users => {
          this.status.setMessage('File editor removed', 'Success', true);
          this.file.Users = users;
          this.setUsers(users);
          this.setupPossibleEditors();
        })
        .catch(err => this.status.setError(err, true));
    });
  }

  private setUsers(users: FileAccessDTO[]) {
    this.users = users.sort((a, b) => a.Access === 'owner' ? -1 : b.Access === 'owner' ? 1 : 0);
  }

  private setupPossibleEditors() {
    this.possibleEditors = this.companyUsers
      .filter(e => e.ClientId !== this.clientService.client.ClientId)
      .filter(e => this.file.Users.every(u => u.Email !== e.Email));
  }

  public ngOnChanges() {
    if (!this.file) { return; }
    this.setUsers(this.file.Users);
    this.setupPossibleEditors();
  }
}
