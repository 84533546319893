import { Def } from '@core/decorators/def';

export class ProjectMemberDTO {
  @Def() ClientId: string;
  @Def() Email: string;
  @Def() FirstName: string;
  @Def() LastName: string;
  @Def() CountryCode: string;
  @Def() ProfilePicture: string;
  @Def() CreatedDate: string;
  @Def() PendingSignup: boolean;
  @Def() ProjectRole: string;
  @Def() Disabled: boolean;
}
