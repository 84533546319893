<div class="container-small signup-container"
     data-test-id="signup-container">
  <indicio-icon-and-text-logo [showIcon]="false"></indicio-icon-and-text-logo>
  <ng-container *ngIf="codeProvided && codeValid"
                [ngTemplateOutlet]="accountFormTemplate"></ng-container>
  <ng-container *ngIf="!codeProvided || !codeValid"
                [ngTemplateOutlet]="activationCodeTemplate"></ng-container>
  <indicio-about-footer></indicio-about-footer>
</div>

<ng-template #activationCodeTemplate>
  <div class="activation-code">
    <div class="signin-container">
      <h2 style="color: #000000cc">Enter your activation code</h2>
      <div class="email-input">
        <input type="text"
               [(ngModel)]="profile.Code"
               [ngModelOptions]="{standalone: true}"
               placeholder="Activation Code"
               autocomplete="username"
               required>
        <span *ngIf="profile.Code.length > 0 && profile.Code.length !== 36">
          <span class="error-text">Not a valid activation code.</span>
        </span>
      </div>
      <button mat-raised-button
              color="primary"
              class="next-button"
              (click)="update()">
        Next
      </button>
      <div class="signup">
        <span class="dotted-underline pointer bold"
              style="color: #838383"
              (click)="backTologin()">Back to login</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #accountFormTemplate>
  <form #f="ngForm"
        (ngSubmit)="createProfile(f.value, $event)"
        class="login-form bg-white"
        ngNativeValidate>
    <h2 class="dark"
        style="margin-top: 0;">
      Create account
    </h2>
    <h4 class="dark">
      Please enter your account details:
    </h4>
    <div class="row extra-margin-top">
      <div class="col-sm-6">
        <mat-form-field>
          <mat-label>First name</mat-label>
          <input type="text"
                 autocomplete="given-name"
                 [(ngModel)]="profile.FirstName"
                 name="firstname"
                 matInput
                 required>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field>
          <mat-label>Last name</mat-label>
          <input type="text"
                 autocomplete="family-name"
                 [(ngModel)]="profile.LastName"
                 name="lastname"
                 matInput
                 required>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <mat-form-field>
          <mat-label>Email/Username</mat-label>
          <input type="text"
                 autocomplete="username"
                 name="username"
                 disabled
                 matInput
                 [value]="profile.Username">
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <indicio-searchable-dropdown label="Country"
                                     [required]="true"
                                     optionDisplay="Display"
                                     [values]="envService.Countries"
                                     (valueChangeEvent)="setCountry($event)"
                                     [initializeWithFirstValue]="false">
        </indicio-searchable-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 ">
        <button class="btn right cursor-pointer"
                style="margin: 0;border-radius: 5px;height: auto;"
                type="button"
                (click)="addGeneratedPassword()">
          <p style="font-size: 11px;margin:1px;">Generate password</p>
        </button>
      </div>
    </div>
    <div class="row margin-top">
      <div class="col-xs-6">
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input [type]="showPass ? 'text' : 'password'"
                 autocomplete="new-password"
                 [(ngModel)]="password1"
                 placeholder="Type new password"
                 (keyup)="checkPassword()"
                 name="password1"
                 class="material"
                 matInput
                 required>
          <i matSuffix
             title="Show password"
             mat-icon-button
             style="color: black"
             [class]="showPass ? 'ion-eye' : 'ion-eye-disabled'"
             (click)="showPassword()"></i>
          <mat-hint *ngIf="passwordStrength === 0"><span class="error-text">Weak password</span></mat-hint>
          <mat-hint *ngIf="passwordStrength === 1"><span class="warning-text">Medium password</span></mat-hint>
          <mat-hint *ngIf="passwordStrength === 2"><span class="success-text">Strong password</span></mat-hint>
        </mat-form-field>
      </div>
      <div class="col-xs-6">
        <mat-form-field>
          <mat-label>Password check</mat-label>
          <input type="password"
                 autocomplete="new-password"
                 [(ngModel)]="password2"
                 placeholder="Re-type Password"
                 name="password2"
                 class="material"
                 matInput
                 required>
        </mat-form-field>
      </div>
    </div>

    <div class="col-xs-12 black accept-text">
      <i data-test-id="signup-check-box"
         [ngClass]="agreedGDPR ? 'ion-android-checkbox' : 'ion-android-checkbox-outline-blank'"
         class="pointer"
         (click)="changeGDPRStatus()"></i>
      By checking this box you confirm that you have read and agree to our <a class="blue pointer"
         (click)="readGDPR()">personal data processing policies.</a>
    </div>

    <button data-test-id="signup-submit-btnn"
            [ngClass]="{disabled : disableSubmit}"
            [indicioTippy]="disableSubmit ? getErrorText : null"
            class="btn large landing extra-margin-top text-center"
            type="submit">
      <div *ngIf="inProgress"
           class="ion-load-c spinner"></div> Submit
    </button>
    <br>
  </form>
</ng-template>
