import { Injectable, OnDestroy } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { CompanyModel } from '@core/store/company/company.model';
import { ForecastModuleReloadAction, SetActiveForecastVersionSuccessAction } from '@core/store/forecast/forecast.actions';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ChangeActiveProjectAction } from '@core/store/project/project.actions';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';


@Injectable()
export class ForecastModuleService implements OnDestroy {

  private subHelper = new Subscription();

  public forecastVersionId: string;
  public forecastId: string;
  public projectId: string;
  public companyId: string;

  public initialized = false;

  public get forecastLoading() { return this.forecastService.loading.ForecastLoading; }
  public get forecastVersionLoading() { return this.forecastService.loading.ForecastVersionLoading; }

  constructor(
    private actions: ActionService,
    private store: Store,
    private forecastService: ForecastFrontendService,
    private projectService: ProjectFrontendService
  ) { }

  public setup(opts: { forecastVersion: ForecastVersionModel, project: ProjectModel, company: CompanyModel; }) {
    const projChanged = this.projectId !== opts.project.ProjectId;
    this.forecastId = opts.forecastVersion.ForecastId;
    this.forecastVersionId = opts.forecastVersion.ForecastVersionId;
    this.projectId = opts.project.ProjectId;
    this.companyId = opts.company.CompanyId;
    if (!this.initialized) {
      this.setupSubs();
    } else {
      if (projChanged) {
        this.store.dispatch(new ChangeActiveProjectAction(this.projectId));
      }
    }
    this.initialized = true;
  }

  private async syncFromStore() {
    await this.forecastService.getOrFetchForecastVersion(this.forecastVersionId);
    await this.forecastService.getOrFetchForecast(this.forecastId);
    await this.projectService.getOrFetchProject(this.companyId, this.projectId);
  }

  private setupSubs() {
    this.subHelper.add(this.actions.dispatched(ForecastModuleReloadAction)
      .subscribe((action: ForecastModuleReloadAction) => {
        if (action.isSame) {
          this.syncFromStore();
        } else {
          this.setup({ company: action.company, forecastVersion: action.forecastVersion, project: action.project });
          this.store.dispatch(new SetActiveForecastVersionSuccessAction(action.forecastVersion));
        }
      }));
  }

  public ngOnDestroy() {
    this.subHelper.unsubscribe();
  }
}
