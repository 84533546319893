import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { ToSService } from '@core/store/tos/tos.service';
import { OpenAssessmentModal, OpenAssessmentOverviewModal } from '@shared/modals/assessments/assessment-modal.actions';
import { OpenConfirmModal } from '@shared/modals/confirm/confirm-modal.actions';
import { OpenGdprModal } from '@shared/modals/gdpr/gdpr-text-modal.action';
import { OpenCreateHistoricEventModal } from '@shared/modals/historic-event/create/create-historic-event-modal.action';
import { OpenEditHistoricEventModal } from '@shared/modals/historic-event/edit/edit-historic-event-modal.action';
import { OpenInformationModal } from '@shared/modals/info/information-modal.actions';
import { CloseActiveModals } from '@shared/modals/modal.actions';
import { ModalComponent } from '@shared/modals/modal.component';
import { OpenBrowseNowcastModal } from '@shared/modals/nowcast/browse-nowcast/browse-nowcast-modal.action';
import { OpenCreateNowcastModal } from '@shared/modals/nowcast/create-nowcast/create-nowcast-modal.actions';
import { OpenProfileModal } from '@shared/modals/profile/profile-modal.actions';
import { OpenEditProjectModal } from '@shared/modals/project/edit-project/project-edit-modal.actions';
import { OpenTransferProjectModal } from '@shared/modals/project/transfer-project/transfer-project-modal.action';
import { OpenSecuritySettingsModal } from '@shared/modals/security-settings/security-settings-modal.actions';
import { OpenStartDateModal } from '@shared/modals/start-date/start-date-modal.action';
import { OpenToSModal } from '@shared/modals/tos/tos-modal.action';
import { CreateTaskModalComponent, DataProcessingModalComponent, TaskHistoryModalComponent, UpdatedDataInfoModalComponent } from '.';
import { OpenGoogle2FAModal } from './2-factor-providers/google/google-2fa-modal.actions';
import { Google2FAModalComponent } from './2-factor-providers/google/google-2fa-modal.component';
import { AssessmentOverviewModalComponent } from './assessments/overview/assessment-overview-modal.component';
import { AssessmentModalComponent } from './assessments/specific/assessment-modal.component';
import { OpenTaskModalComponent } from './auto-task/create-edit/task-modal.action';
import { OpenTaskHistoryModalComponent } from './auto-task/history/task-history-modal.action';
import { OpenClientActivityModal } from './client-activity/client-activity-modal.action';
import { ClientActivityModalComponent } from './client-activity/client-activity-modal.component';
import { OpenChangeCompanyModal } from './company/change-company/change-company-modal.actions';
import { ChangeCompanyModalComponent } from './company/change-company/change-company-modal.component';
import { OpenCompanyModal } from './company/overview/company-modal.actions';
import { CompanyModalComponent } from './company/overview/company-modal.component';
import { OpenSetCompanyModal } from './company/set-company/set-company-modal.actions';
import { SetCompanyModalComponent } from './company/set-company/set-company-modal.component';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';
import { ChartDownloadModalComponent } from './data-download/chart-download/chart-download-modal.component';
import { OpenChartDownloadModal, OpenForecastDataDownloadModal } from './data-download/data-download.actions';
import { ForecastDataDownloadModalComponent } from './data-download/forecast-data-download/forecast-data-download-modal.component';
import { OpenDataProcessingModal } from './data-processing/data-processing-modal.actions';
import { DeleteUploadedFilesModalComponent } from './files/delete-multiple-files/delete-multiple-files-modal.component';
import { FileErrorInformationModalComponent } from './files/file-error/file-error-information-modal.component';
import { OpenDeleteUploadedFilesModal, OpenFileErrorInfoModal, OpenUploadFileModal } from './files/file-modal.actions';
import { OpenNewOrUpdatedFilesModal } from './files/new-or-updated-files/new-or-updated-files.actions';
import { NewOrUpdatedFilesModalComponent } from './files/new-or-updated-files/new-or-updated-files.component';
import { FileUploadModalComponent } from './files/upload-file/upload-file-modal.component';
import { OpenCreateForecastVariableMultiModal } from './forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { CreateForecastVariableMultiModalComponent } from './forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.component';
import { OpenDeleteForecastVariableModal } from './forecast-variable/delete-forecast-variable/delete-forecast-variable-modal.action';
import { DeleteForecastVariableModalComponent } from './forecast-variable/delete-forecast-variable/delete-forecast-variable-modal.component';
import { OpenForecastedIndicatorModal } from './forecast-variable/forecasted-indicator/forecasted-indicator-modal.actions';
import { ForecastedIndicatorModalComponent } from './forecast-variable/forecasted-indicator/forecasted-indicator-modal.component';
import { OpenUpdateMultiIndicatorsModalAction } from './forecast-variable/update-multi-indicators/update-multi-indicators-modal.action';
import { UpdateMultiIndicatorsModalComponent } from './forecast-variable/update-multi-indicators/update-multi-indicators-modal.component';
import { OpenUpdatedDataInfoModal } from './forecast-variable/updated-data-info/updated-data-info-modal.actions';
import { CopyForecastModalComponent } from './forecast/copy/copy-forecast-modal.component';
import { OpenCopyForecastModal, OpenMoveForecastModal } from './forecast/forecast-modal.actions';
import { MoveForecastModalComponent } from './forecast/move/move-forecast-modal.component';
import { OpenForecastWarningsModalAction } from './forecast/warnings/forecast-warnings-modal.action';
import { ForecastWarningsModalComponent } from './forecast/warnings/forecast-warnings-modal.component';
import { GDPRTextModalComponent } from './gdpr/gdpr-text-modal.component';
import { CreateHistoricEventModalComponent } from './historic-event/create/create-historic-event-modal.component';
import { OpenDeleteForecastEventModal } from './historic-event/delete-forecast-event/delete-forecast-event-modal.action';
import { DeleteForecastEventModalComponent } from './historic-event/delete-forecast-event/delete-forecast-event-modal.component';
import { OpenDeleteHistoricEventsModalComponent } from './historic-event/delete-multiple-events/delete-multiple-events-modal.actions';
import { DeleteHistoricEventsModalComponent } from './historic-event/delete-multiple-events/delete-multiple-events-modal.component';
import { EditHistoricEventModalComponent } from './historic-event/edit/edit-historic-event-modal.component';
import { OpenImportHistoricEventsModalComponent } from './historic-event/import-multiple-events/import-multiple-events-modal.action';
import { ImportHistoricEventsModalComponent } from './historic-event/import-multiple-events/import-multiple-events-modal.component';
import { OpenImportHistoricEventsCorrelationModal } from './imported-historic-events/correlation/event-correlation-modal.action';
import { HistoricEventsCorrelationModalComponent } from './imported-historic-events/correlation/event-correlation-modal.component';
import { OpenEditImportedHistoricEventModal } from './imported-historic-events/edit/edit-imported-historic-event-modal.action';
import { EditImportedHistoricEventModalComponent } from './imported-historic-events/edit/edit-imported-historic-event-modal.component';
import { InfoModalComponent } from './info/information-modal.component';
import { OpenCreateNewsModal } from './news/create-news/create-news-modal.action';
import { CreateNewsModalComponent } from './news/create-news/create-news-modal.component';
import { OpenEditNewsModal } from './news/edit-news/edit-news-modal.actions';
import { EditNewsModalComponent } from './news/edit-news/edit-news-modal.component';
import { BrowseNowcastModalComponent } from './nowcast/browse-nowcast/browse-nowcast-modal.component';
import { CreateNowcastModalComponent } from './nowcast/create-nowcast/create-nowcast-modal.component';
import { OpenMultiCreateNowcastModal } from './nowcast/multi-create-nowcast/multi-create-nowcast-modal.actions';
import { MultiCreateNowcastModalComponent } from './nowcast/multi-create-nowcast/multi-create-nowcast-modal.component';
import { ProfileModalComponent } from './profile/profile-modal.component';
import { ProjectEditModalComponent } from './project/edit-project/project-edit-modal.component';
import { TransferProjectModalComponent } from './project/transfer-project/transfer-project-modal.component';
import { OpenACEAImportModal } from './providers/acea/acea-import-modal.actions';
import { ACEAImportModalComponent } from './providers/acea/acea-import-modal.components';
import { OpenAlphaCryptoModal } from './providers/alphavantage/crypto/alpha-crypto-modal.actions';
import { AlphaCryptoModalComponent } from './providers/alphavantage/crypto/alpha-crypto-modal.component';
import { OpenAlphaForexModal } from './providers/alphavantage/forex/alpha-forex-modal.action';
import { AlphaForexModalComponent } from './providers/alphavantage/forex/alpha-forex-modal.component';
import { OpenAlphaMainModal } from './providers/alphavantage/main/alpha-main-modal.actions';
import { AlphaMainModalComponent } from './providers/alphavantage/main/alpha-main-modal.component';
import { OpenAlphaStockModal } from './providers/alphavantage/stock/alpha-stock-modal.actions';
import { AlphaStockModalComponent } from './providers/alphavantage/stock/alpha-stock-modal.component';
import { OpenFREDImportModal } from './providers/fred/import/fred-import-modal.actions';
import { FREDImportModalComponent } from './providers/fred/import/fred-import-modal.component';
import { OpenKoladaImportModal } from './providers/kolada/kolada-import-modal.actions';
import { KoladaImportModalComponent } from './providers/kolada/kolada-import-modal.component';
import { OpenModifyAPIKeyModal } from './providers/modify-api-key/modify-api-key.actions';
import { ModifyAPIKeyModalComponent } from './providers/modify-api-key/modify-api-key.component';
import { OpenModifyAPILoginModal } from './providers/modify-api-login/modify-api-login.actions';
import { ModifyAPILoginModalComponent } from './providers/modify-api-login/modify-api-login.component';
import { OpenOxfordImportModal } from './providers/oxford/import/oxford-import-modal.actions';
import { OxfordImportModalComponent } from './providers/oxford/import/oxford-import-modal.components';
import { OpenQuandlImportModal } from './providers/quandl/quandl-import-modal.actions';
import { QuandlImportModalComponent } from './providers/quandl/quandl-import-modal.component';
import { OpenRefinitivModal } from './providers/refinitiv/refinitiv-modal.actions';
import { RefinitivModalComponent } from './providers/refinitiv/refinitiv-modal.component';
import { OpenRemoveSharepointModal } from './providers/sharepoint/remove/remove-sharepoint-modal.actions';
import { RemoveSharepointModalComponent } from './providers/sharepoint/remove/remove-sharepoint-modal.component';
import { OpenAddSharepointProviderModal } from './providers/sharepoint/sharepoint-modal.actions';
import { SharepointModalComponent } from './providers/sharepoint/sharepoint-modal.component';
import { OpenCreateReportModal } from './reports/create/create-report-modal.actions';
import { CreateReportModalComponent } from './reports/create/create-report-modal.component';
import { SecuritySettingsModalComponent } from './security-settings/security-settings-modal.component';
import { OpenDeleteSourceVariableModal } from './source-variable/delete-source-variable/delete-source-variable-modal.actions';
import { DeleteSourceVariableModalComponent } from './source-variable/delete-source-variable/delete-source-variable-modal.component';
import { OpenMultiUpdateRemoteDataModalAction } from './source-variable/multi-update-remotedata/multi-update-remotedata-modal.action';
import { MultiUpdateRemoteDataModalComponent } from './source-variable/multi-update-remotedata/multi-update-remotedata-modal.component';
import { OpenSourceVariableRevisionModal } from './source-variable/revisions/source-variable-revision-modal.action';
import { SourceVariableRevisionModalComponent } from './source-variable/revisions/source-variable-revision-modal.component';
import { StartDateModalComponent } from './start-date/start-date-modal.component';
import { TosModalComponent } from './tos/tos-modal.component';
import { OpenVarSelectResultModal } from './var-select/var-select-modal.action';
import { VarSelectResultModalComponent } from './var-select/var-select-modal.component';


export abstract class ModalAction {
  public static readonly type: string;
  ao?: boolean = false; // Allow outside
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  static activeModal: any;
  modalComponentLoader: ModalComponent;

  components: any[] = [];

  constructor(
    private actions: ActionService,
    private tosService: ToSService,
  ) {
    this.setupActionListeners();
  }

  public setModalComponentLoader(modal: ModalComponent) {
    this.modalComponentLoader = modal;
  }

  private setupActionListeners() {
    this.setupAction(new OpenGoogle2FAModal(), Google2FAModalComponent);
    this.setupAction(new OpenAssessmentOverviewModal(null, null, null, null, null), AssessmentOverviewModalComponent);
    this.setupAction(new OpenAssessmentModal(null, null, null, null, null, null), AssessmentModalComponent);
    this.setupAction(new OpenSecuritySettingsModal(), SecuritySettingsModalComponent);
    this.setupAction(new OpenChangeCompanyModal(null, null), ChangeCompanyModalComponent);
    this.setupAction(new OpenCompanyModal(null), CompanyModalComponent);
    this.setupAction(new OpenConfirmModal(null, null, null, null, null, null, null), ConfirmModalComponent);
    this.setupAction(new OpenChartDownloadModal(null, null, null, null, null, null, null), ChartDownloadModalComponent);
    this.setupAction(new OpenDeleteUploadedFilesModal(null, null, null), DeleteUploadedFilesModalComponent);
    this.setupAction(new OpenRemoveSharepointModal(null, null, null, null), RemoveSharepointModalComponent);
    this.setupAction(new OpenFileErrorInfoModal(null, null), FileErrorInformationModalComponent);
    this.setupAction(new OpenNewOrUpdatedFilesModal(null, null, null), NewOrUpdatedFilesModalComponent);
    this.setupAction(new OpenTaskModalComponent(null), CreateTaskModalComponent);
    this.setupAction(new OpenTaskHistoryModalComponent(null), TaskHistoryModalComponent);
    this.setupAction(new OpenClientActivityModal(), ClientActivityModalComponent);
    this.setupAction(new OpenUploadFileModal(null, null, null), FileUploadModalComponent);
    this.setupAction(new OpenCopyForecastModal(null), CopyForecastModalComponent);
    this.setupAction(new OpenMoveForecastModal(null), MoveForecastModalComponent);
    this.setupAction(new OpenCreateForecastVariableMultiModal(null, null, null, null), CreateForecastVariableMultiModalComponent);
    this.setupAction(new OpenDeleteForecastVariableModal(null), DeleteForecastVariableModalComponent);
    this.setupAction(new OpenGdprModal(), GDPRTextModalComponent);
    this.setupAction(new OpenCreateHistoricEventModal(null), CreateHistoricEventModalComponent);
    this.setupAction(new OpenDeleteForecastEventModal(null, null), DeleteForecastEventModalComponent);
    this.setupAction(new OpenDeleteHistoricEventsModalComponent(null, null), DeleteHistoricEventsModalComponent);
    this.setupAction(new OpenEditHistoricEventModal(null, null), EditHistoricEventModalComponent);
    this.setupAction(new OpenEditImportedHistoricEventModal(null, null), EditImportedHistoricEventModalComponent);
    this.setupAction(new OpenImportHistoricEventsModalComponent(null, null), ImportHistoricEventsModalComponent);
    this.setupAction(new OpenImportHistoricEventsCorrelationModal(null, null, null), HistoricEventsCorrelationModalComponent);
    this.setupAction(new OpenInformationModal(null, null, null, null, null), InfoModalComponent);
    this.setupAction(new OpenCreateNewsModal(), CreateNewsModalComponent);
    this.setupAction(new OpenEditNewsModal(null), EditNewsModalComponent);
    this.setupAction(new OpenBrowseNowcastModal(null, null), BrowseNowcastModalComponent);
    this.setupAction(new OpenCreateNowcastModal(null, null, null), CreateNowcastModalComponent);
    this.setupAction(new OpenProfileModal(), ProfileModalComponent);
    this.setupAction(new OpenEditProjectModal(null), ProjectEditModalComponent);
    this.setupAction(new OpenTransferProjectModal(null, null), TransferProjectModalComponent);
    this.setupAction(new OpenAlphaCryptoModal(null), AlphaCryptoModalComponent);
    this.setupAction(new OpenAlphaForexModal(null), AlphaForexModalComponent);
    this.setupAction(new OpenAlphaMainModal(null), AlphaMainModalComponent);
    this.setupAction(new OpenAlphaStockModal(null), AlphaStockModalComponent);
    this.setupAction(new OpenFREDImportModal(null, null), FREDImportModalComponent);
    this.setupAction(new OpenOxfordImportModal(null, null, null, null, null), OxfordImportModalComponent);
    this.setupAction(new OpenACEAImportModal(null, null), ACEAImportModalComponent);
    this.setupAction(new OpenAddSharepointProviderModal(null, null), SharepointModalComponent);
    this.setupAction(new OpenKoladaImportModal(null, null), KoladaImportModalComponent);
    this.setupAction(new OpenModifyAPIKeyModal(null, null, null, null, null), ModifyAPIKeyModalComponent);
    this.setupAction(new OpenModifyAPILoginModal(null, null, null, null, null), ModifyAPILoginModalComponent);
    this.setupAction(new OpenQuandlImportModal(null, null), QuandlImportModalComponent);
    this.setupAction(new OpenRefinitivModal(null), RefinitivModalComponent);
    this.setupAction(new OpenDeleteSourceVariableModal(null, null, null), DeleteSourceVariableModalComponent);
    this.setupAction(new OpenSourceVariableRevisionModal(null), SourceVariableRevisionModalComponent);
    this.setupAction(new OpenStartDateModal(null, null), StartDateModalComponent);
    this.setupAction(new OpenToSModal(null), TosModalComponent);
    this.setupAction(new OpenVarSelectResultModal(null, null, null, null), VarSelectResultModalComponent);
    this.setupAction(new OpenForecastDataDownloadModal(null), ForecastDataDownloadModalComponent);
    this.setupAction(new OpenDataProcessingModal(null, null, null), DataProcessingModalComponent);
    this.setupAction(new OpenSetCompanyModal(null), SetCompanyModalComponent);
    this.setupAction(new OpenMultiCreateNowcastModal(null, null, null), MultiCreateNowcastModalComponent);
    this.setupAction(new OpenCreateReportModal(), CreateReportModalComponent);
    this.setupAction(new OpenUpdateMultiIndicatorsModalAction(null, null), UpdateMultiIndicatorsModalComponent);
    this.setupAction(new OpenMultiUpdateRemoteDataModalAction(null, null), MultiUpdateRemoteDataModalComponent);
    this.setupAction(new OpenForecastWarningsModalAction(null, null), ForecastWarningsModalComponent);
    this.setupAction(new OpenUpdatedDataInfoModal(null, null, null, null), UpdatedDataInfoModalComponent);
    this.setupAction(new OpenForecastedIndicatorModal(null, null, null, null), ForecastedIndicatorModalComponent);
    this.setupCloseAction();
  }

  private setupCloseAction() {
    this.actions.dispatched(CloseActiveModals).subscribe(() => {
      if (this.modalComponentLoader) {
        this.modalComponentLoader.close();
      }
    });
  }

  private setupAction<T extends ModalAction>(action: T, component: any) {
    this.actions.dispatched(action).subscribe((value: T) => {
      setTimeout(() => {
        this.openModalByAction(component, value.data, action.ao);
      }, 0);
    });
  }

  private openModalByAction(component: any, options: any, alwaysAllowOpen: boolean = false) {
    const allowOpen = this.tosService.hasAcceptedTos || alwaysAllowOpen;
    if (!allowOpen) {
      console.error('Not opening ', component.name, ' - Not allowed.');
      return;
    }
    if (this.modalComponentLoader) {
      this.modalComponentLoader.openModal(component, options);
    }
  }
}
