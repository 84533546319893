<h1 mat-dialog-title
    class="crop-string"
    [title]="template.Title">{{ template.Title }}
  <div class="crop-string"
       [title]="template.MainForecastVariable.Name"
       style="font-size: 14px; color: #888; line-height: 13px;">{{ template.MainForecastVariable.Name }}</div>
</h1>
<div mat-dialog-content>
  <div class="template-info-container">

    <div *ngIf="canEdit"
         class="cursor-pointer edit-template"
         (click)="openEdit()">
      <span>Edit template </span>
      <i class="ion-edit"></i>
    </div>

    <ng-container *ngTemplateOutlet="infoTemplate"></ng-container>

    <ng-container *ngIf="!canUse">
      <div class="row"
           style="padding: 10px 5px; color: orangered;">
        <div class="col-xs-12">You do not have access to the following data providers: </div>
        <div class="col-xs-12"> {{ missing }} </div>
      </div>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
  <button mat-flat-button
          [indicioTippy]="canUse ? null : 'You do not have access to one or more of the needed data providers to use this template.'"
          [ngClass]="{ disabled: !canUse }"
          data-test-id="use-forecast-template-btn"
          color="primary"
          (click)="use()">
    Use template
  </button>
</div>

<ng-template #infoTemplate>
  <div>Forecast horizon: {{ template.Settings.Horizon }} {{ template.Periodicity }}s</div>
  <div class="flex">Maintained by:&nbsp;
    <div style="display: inline-block;">
      {{ template.AuthorInfo.AuthorName }}
      <ng-container *ngIf="template.AuthorInfo.AuthorBio?.length">
        <br>
        {{ template.AuthorInfo.AuthorBio }}
      </ng-container>
    </div>
  </div>
  <div *ngIf="template.Tags.length && template.Tags[0].Name !== TemplateTypes.Untagged">
    <div class="flex">Tags:&nbsp;
      <div style="display: inline-block;">
        {{ tagString }}
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="tableTemplate"></ng-container>
</ng-template>

<ng-template #tableTemplate>
  <div class="data-table"
       style="width: 550px; margin-top: 10px;">
    <div class="data-table__header">
      <div class="column header left-align"
           style="width: 308px">Variable
      </div>
      <div class="column header"
           style="width: 100px;">
        Influence
      </div>
      <div class="column header left-align"
           style="width: 140px">Source</div>
    </div>
    <div class="data-table__body"
         style="max-height: 350px;">
      <ng-container *ngFor="let indicator of template.Indicators let index = index">
        <ng-container
                      *ngTemplateOutlet="variableRowTemplate; context: { title: index === 0 ? 'Indicators' : '', variable: indicator, index: index }">
        </ng-container>
      </ng-container>


      <div class="body__row body__row--center-height body__row--max-height-30">
      </div>
    </div>
  </div>
</ng-template>

<ng-template #infoRowTemplate
             let-title="title"
             let-value="value">
  <div class="body__row body__row--center-height body__row--max-height-30">
    <div class="column"
         style="width: 150px; text-align: left;">
      <div class="row">
        <div class="col-xs-12">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="column"
         style="width: 200px; text-align: left;">
      {{ value }}
    </div>
  </div>
</ng-template>

<ng-template #variableRowTemplate
             let-variable="variable"
             let-title="title"
             let-index="index">
  <div class="body__row body__row--center-height body__row--max-height-30">
    <div class="column crop-string"
         style="width: 308px; text-align: left;"
         [indicioTippy]="'Variable name: ' + variable.Name">
      {{ variable.Name }}
    </div>
    <div class="column dotted-underline"
         *ngIf="template.normalizedInfluence[index] as inf"
         [indicioTippy]="'Influence score: ' + template.normalizedInfluence[index].real "
         style="width: 100px;">
      <indicio-influence-stars [influence]="template.normalizedInfluence[index].influence"></indicio-influence-stars>
    </div>
    <div class="column"
         style="text-align: left;"
         [ngStyle]="{ width: !variable.hasAccess ? '115px' : '140px'}">
      {{ variable.source.Display }}
    </div>
    <div *ngIf="!variable.hasAccess"
         class="column"
         style="width: 25px; color: red"
         [indicioTippy]="'You do not have access to the data provider: ' + variable.source.Display">
      <i class="ion-alert-circled"></i>
    </div>
  </div>
</ng-template>

<ng-template #authorInfoTemplate>
  <div class="body__row body__row--center-height body__row--max-height-30">
    <div class="column"
         style="width: 150px; text-align: left;">
      <div class="row">
        <div class="col-xs-12">
          Maintained by
        </div>
      </div>
    </div>
    <div class="column crop-string"
         style="width: 380px; text-align: left;">
      {{ template.AuthorInfo.AuthorName }} ({{ template.AuthorInfo.AuthorEmail }})
    </div>
  </div>
  <div *ngIf="template.AuthorInfo.AuthorBio?.length"
       class="body__row body__row--center-height body__row--max-height-30">
    <div class="column"
         style="width: 150px; text-align: left;">
    </div>
    <div class="column"
         style="width: 380px; text-align: left;">
      {{ template.AuthorInfo.AuthorBio }}
    </div>
  </div>
</ng-template>
