<indicio-modal-base [child]="this"
                    modalTitle="Create new report"
                    [loading]=isLoading>

  <ng-container *ngIf="!isLoading">
    <div class="create-report-container">
      <ng-container [ngTemplateOutlet]="createForm"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="buttonRow"></ng-container>
  </ng-container>
</indicio-modal-base>

<ng-template #createForm>
  <indicio-modal-option-row optionTitle="Name"
                            optionSubtitle="Choose a name for the report"
                            textWidth=7
                            optionsWidth=5>
    <div style="margin-top:15px">
      <mat-form-field>
        <input data-test-id='forcast-name-input-field'
               type="text"
               class="material"
               matInput
               autocomplete="off"
               [ngModel]="createDto.Name"
               (ngModelChange)="changeReportName($event)"
               name="Name"
               indicioAutoFocusInput
               maxlength="64"
               required>
        <mat-hint *ngIf="reportNameMustChange">
          <span class="error-text">A report with that name already exists.</span>
        </mat-hint>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Template"
                            optionSubtitle="Pick a template for the report"
                            textWidth=7
                            optionsWidth=5>
    <div style="margin-top:15px">
      <mat-form-field>
        <mat-select [(value)]="createDto.Template"
                    (selectionChange)="changeTemplate($event.value)"
                    required>
          <mat-option [value]="template.Value"
                      [title]="template.Description"
                      *ngFor="let template of templatesAvailable">{{ template.Display }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>

  <ng-container [ngSwitch]="createDto.Template">
    <ng-container *ngSwitchCase="'fiscal-year'">
      <ng-container *ngTemplateOutlet="fiscalOptionsTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'overview'">
      <ng-container *ngTemplateOutlet="overviewTemplateOptions"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #buttonRow>
  <div class="row extra-margin-top">
    <div class="col-xs-6">
      <button class="btn outline blue left"
              type="button"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6">
      <div class="pull-right">
        <button class="btn"
                (click)="createReport()"
                [disabled]="saveDisabled"
                type="submit">
          <span *ngIf="pending"
                class="ion-load-c spinner"></span>
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fiscalOptionsTemplate>
  <indicio-modal-option-row optionTitle="Fiscal year"
                            optionSubtitle="Fiscal year starts in {{ fiscalMonth }}"
                            textWidth=7
                            optionsWidth=5>
    <div option-subtitle-note
         class='subtitle-note'
         [ngClass]="{ 'dotted-underline cursor-pointer' : isCompanyAdmin}"
         (click)='isCompanyAdmin ? openCompany() : null'>
      (Not {{ fiscalMonth }}? {{ isCompanyAdmin ? 'Click here to update' : 'Contact a company admin' }})
    </div>
    <div style="margin-top:35px">
      <indicio-date-picker periodicity="year"
                           style="user-select: none !important"
                           [value]="date"
                           (selectDateEvent)="chosenYearHandler($event)">
      </indicio-date-picker>
    </div>
  </indicio-modal-option-row>

  <ng-container *ngTemplateOutlet="forecastOptionRowTemplate; context: { limit: true}"></ng-container>
</ng-template>

<ng-template #overviewTemplateOptions>
  <ng-container *ngTemplateOutlet="forecastOptionRowTemplate"></ng-container>
</ng-template>

<ng-template #forecastOptionRowTemplate
             let-limit="limit">
  <indicio-modal-option-row optionTitle="Forecasts"
                            textWidth=7
                            optionsWidth=5>
    <div option-subtitle-note
         class='subtitle-note'>
      Pick forecasts you wish to include
      <ng-container *ngIf="limit">
        <i class="ion-information-circled cursor-pointer"
           indicioTippy="You can only pick forecasts that's within the report period"></i>
      </ng-container>
    </div>
    <div style="margin-top:15px">
      <mat-form-field>
        <mat-select [value]="createDto.ForecastIds"
                    [multiple]="true"
                    required>
          <mat-optgroup *ngFor="let project of allProjectsAvailable"
                        label="Project: {{ project.ProjectName }}">
            <mat-option [value]="forecast.ForecastId"
                        (onSelectionChange)="setForecasts($event)"
                        [disabled]="forecast.disabled"
                        [title]="forecast.disabled ? 'Not within report period' : forecast.Name"
                        *ngFor="let forecast of project.Forecasts">{{ forecast.Name }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
  </indicio-modal-option-row>
</ng-template>
