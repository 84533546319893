<div class="system-message-container"
     (click)="!!message.InfoAction ? message.InfoAction() : null"
     *ngIf="show"
     [class]="message.Position || 'top'"
     [ngClass]="{ 'with-info': !message.InfoAction } ">

  <div class="message"
       [innerHtml]="message.Content"
       data-test-id="service-message-text">
  </div>

  <i class="pull-right pointer"
     [class]="message.InfoIcon ?? 'icon-cross'"
     *ngIf="!message.Sticky"
     data-test-id="service-message-action"></i>
</div>
