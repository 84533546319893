import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { NewsPostModel } from '@core/store/news/news-post.model';
import { NewsFrontendService } from '@core/store/news/news.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { OpenCreateNewsModal } from '@shared/modals/news/create-news/create-news-modal.action';
import { OpenEditNewsModal } from '@shared/modals/news/edit-news/edit-news-modal.actions';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

@Component({
  selector: 'indicio-news-snippet',
  templateUrl: './news-snippet.component.html',
  styleUrls: ['./news-snippet.component.less']
})
export class NewsSnippetComponent implements OnInit {

  @Input() article: NewsPostModel;

  public userCanCreateNews: boolean;
  public userCanUpdateNews: boolean;

  private isAlreadyOnNews: boolean;
  public articleRoute: string[];

  constructor(
    private store: Store,
    private authService: AuthFrontendService,
    public newsService: NewsFrontendService,
    public appearance: AppearanceService,
    public dialogService: DialogService
  ) {
    this.userCanCreateNews = this.authService.hasPermission('CAN_CREATE_NEWSPOSTS');
    this.userCanUpdateNews = this.authService.hasPermission('CAN_UPDATE_NEWSPOSTS');
    this.isAlreadyOnNews = !!window.location.href.match(/news/);
  }

  ngOnInit() {
    if (this.isAlreadyOnNews) {
      this.articleRoute = [this.article.Meta.NewsPostId];
    } else {
      this.articleRoute = ['news', this.article.Meta.NewsPostId];
    }
  }

  public readMoreDialog(article: NewsPostModel) {
    this.dialogService.openNewsArticleDialog({ article });
  }

  public editPost(article: NewsPostModel) {
    this.store.dispatch(new OpenEditNewsModal(article));
  }

  public openCreateNewsModal() {
    this.store.dispatch(new OpenCreateNewsModal());
  }
}
