import { Def } from '@core/decorators/def';

export class QuandlDatabaseModel {
  @Def() id: number;
  @Def() name: string;
  @Def() database_code: string;
  @Def() description: string;
  @Def() datasets_count: number;
  @Def() downloads: number;
  @Def() premium: boolean;
  @Def() image: string;
  @Def() favorite: boolean;
  @Def() url_name: string;
}
