import { Injectable } from '@angular/core';
import { TagModel } from '@core/store/tags/models/tag.model';
import { TagsFrontendService } from '@core/store/tags/tags.frontend.service';
import { IForecastTreeNodeForecast, IForecastTreeNodeProject, IForecastTreeNodeTag } from './components/forecasts-drawer/components/nav-tree/entities/forecast-tree.entities';
import { ForecastNavDTO, ProjectNavDTO } from './entities/navigation.dtos';
import { NavigationForecastListViewType } from './entities/navigation.entities';

type ForecastOrTagNode = IForecastTreeNodeForecast | IForecastTreeNodeTag;

@Injectable({
  providedIn: 'root'
})
export class NavigationMapper {
  constructor(
    private tagService: TagsFrontendService
  ) { }

  public map(dto: ProjectNavDTO[], listType: NavigationForecastListViewType) {
    switch (listType) {
      case 'project-list': {
        return this.mapProjectList(dto);
      }
      case 'flat-list': {
        return this.mapFlatList(dto);
      }
      case 'tag-list': {
        return this.mapTagList(dto);
      }
    }
  }

  private mapProjectList(dto: ProjectNavDTO[]) {
    return dto.map(project => this.mapProject(project));
  }

  private mapFlatList(dto: ProjectNavDTO[]) {
    return this.mapProjectList(dto).map(project => project.Children).flatten();
  }

  private mapTagList(dto: ProjectNavDTO[]) {
    const forecasts = this.mapProjectList(dto).map(project => <IForecastTreeNodeForecast[]> project.Children).flatten();
    const tags = [...new Set(forecasts.map(forecast => forecast.TagIds).flatten())].map(tagId => this.tagService.getTagById(tagId)).filter(x => x);
    const untaggedForecasts = forecasts.filter(forecast => !forecast.TagIds.length);
    const tagTree: ForecastOrTagNode[] = [];
    tags.forEach(targetTag => {
      const topParents = this.getTopParent(targetTag, targetTag);
      const parentIds = this.getParents(targetTag);
      topParents.forEach(parent => {
        const mapped = this.mapTag(parent, parentIds.find(x => x.includes(parent.TagId)), forecasts);
        const existing = tagTree.find(x => x.ObjectId === mapped.ObjectId);
        if (!existing) {
          tagTree.push(mapped);
        } else {
          const addOrUpdate = (existingChildren: ForecastOrTagNode[], newChildren: ForecastOrTagNode[]) => {
            newChildren.forEach(newChild => {
              const existingChild = existingChildren.find(x => x.ObjectId === newChild.ObjectId);
              if (!existingChild) {
                existingChildren.push(newChild);
              } else {
                addOrUpdate(existingChild.Children, newChild.Children);
              }
            });
          };
          addOrUpdate(existing.Children, mapped.Children);
        }
      });
    });

    if (untaggedForecasts.length) {
      const untagged = this.unTaggedTag();
      untagged.Children = untaggedForecasts;
      tagTree.push(untagged);
    }

    return tagTree;
  }

  private mapProject(
    projectDto: ProjectNavDTO,
  ): IForecastTreeNodeProject {
    const project = <IForecastTreeNodeProject> {
      Name: projectDto.Name,
      Type: 'project',
      ObjectId: projectDto.ProjectId,
      CompanyShared: projectDto.CompanyShared,
      SharedBy: projectDto.SharedBy,
      NewData: projectDto.NewDataExists,
      order: 'a',
      isOpen: false,
      isActive: false
    };
    project.Children = projectDto.Forecasts.map(forecast => this.mapForecast(forecast, project));
    return project;
  }

  private mapTag(
    tagModel: TagModel,
    tagFilter: string[] = [],
    forecasts: IForecastTreeNodeForecast[],
  ): IForecastTreeNodeTag {
    let children = [...new Set(tagModel.ChildTags.slice().filter(x => tagFilter.includes(x.TagId)))].map(x => this.mapTag(x, tagFilter, forecasts));
    const forecastsForTag = forecasts.filter(forecast => forecast.TagIds.includes(tagModel.TagId)).map(x => <IForecastTreeNodeForecast> Object.assign({}, x));

    if (forecastsForTag.length) {
      children = children.concat(forecastsForTag);
    }

    const order = !tagModel.isUserDefined() ? 'a' : 'b';
    const tag = <IForecastTreeNodeTag> {
      Name: tagModel.Name,
      NewData: false,
      isOpen: false,
      isActive: false,
      Type: 'tag',
      Children: children,
      ObjectId: tagModel.TagId,
      order: order
    };
    return tag;
  }

  private mapForecast(
    forecast: ForecastNavDTO,
    parent: IForecastTreeNodeProject | IForecastTreeNodeTag
  ): IForecastTreeNodeForecast {
    return <IForecastTreeNodeForecast> {
      Name: forecast.Name,
      Type: 'forecast',
      Periodicity: forecast.Periodicity,
      TagIds: forecast.TagIds,
      Children: [],
      ObjectId: forecast.ForecastId,
      NewData: forecast.NewDataExists,
      parent: parent,
      order: 'c',
      isOpen: false,
      isActive: false,
      level: 0
    };
  }

  private getParents(tag: TagModel) {
    const parents = [];
    tag.ParentTags.forEach((parent, i) => {
      parents.push([parent.TagId]);
      parents[i].push(...this.getParents(parent).flatten());
      parents[i] = parents[i].filter(x => x?.length > 0);
      parents[i].push(tag.TagId);
    });
    return parents;
  }

  private getTopParent(tag: TagModel, targetTag: TagModel, topParents: TagModel[] = []) {
    if (tag.ParentTags.length === 0) {
      topParents.push(tag);
    }
    tag.ParentTags.forEach(parent => {
      if (parent.ParentTags.length) {
        this.getTopParent(parent, targetTag, topParents);
      } else {
        topParents.push(parent);
      }
    });
    return topParents;
  }

  private unTaggedTag() {
    return <IForecastTreeNodeTag> {
      Name: 'Untagged forecasts',
      isOpen: false,
      isActive: false,
      Type: 'tag',
      Children: [],
      ObjectId: 'untagged',
      order: 'z'
    };
  }
}

