import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthState } from '@core/store/auth/auth.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class LoginRouteGuard {

  constructor(
    private store: Store,
    private router: Router
  ) { }

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const loggedIn = this.store.selectSnapshot(AuthState.loggedIn);
    const token = this.store.selectSnapshot(AuthState.token);
    if (token && !loggedIn) {
      this.router.navigate(['loading'], { queryParams: { returnUrl: state.url } });
      return false;
    } else if (!token && !loggedIn) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return loggedIn;
  }
}
