import { Injectable } from '@angular/core';
import { Events } from '@modules/automation/events';
import { Store } from '@ngxs/store';
import { PusherActionDTO } from '../dtos/pusher-action-dto';

export type CPusherEventsActions =
  'new-event'
  ;

@Injectable({
  providedIn: 'root'
})
export class PusherEventsBehaviourService {

  constructor(
    private store: Store,
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {
    switch (dto.Action as CPusherEventsActions) {
      case 'new-event':
        return this.store.dispatch(new Events.Actions.NewActivityLog(dto.Obj));

      default:
        console.error(`Missing behaviour for action: (${dto.Action})`);
        return;
    }
  }
}
