import { ChangeDetectorRef, Component } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { UserStatisticsDTO } from '@core/store/company/dtos/user-statistics.dto';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { UserStatRequestDTO } from '@modules/admin/entities/user-stats-request.dto';
import { AdminFrontendService } from '@modules/admin/services/admin-frontend.service';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateUtils } from '@shared/utils/date.utils';
import { FileUtils } from '@shared/utils/file.utils';
import * as moment from 'moment';
import { ClientActivityModalOpts } from './client-activity-modal.options';


@Component({
  selector: 'indicio-historic-event-modal',
  templateUrl: './client-activity-modal.component.html',
  styleUrls: []
})
export class ClientActivityModalComponent extends ModalModelComponent {

  public opts: ClientActivityModalOpts;
  public companies: CompanyModel[] = [];
  public searchDto: UserStatRequestDTO = new UserStatRequestDTO();
  public fromDateModel: moment.Moment = null;
  public asCSV: boolean;
  public shouldOpenCompanyModal: boolean = false;
  public selectedCompany: CompanyModel;

  constructor(
    protected store: Store,
    private status: StatusService,
    private admin: AdminFrontendService,
    private auth: AuthFrontendService,
    public appearance: AppearanceService,
    public env: EnvironmentService,
    public client: AuthFrontendService,
    public company: CompanyFrontendService,
    public userClient: ClientFrontendService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  public onDateChanged(date: moment.Moment) {
    this.fromDateModel = DateUtils.getStartOf(date.toDate(), this.searchDto.Periodicity);
    this.searchDto.From = this.fromDateModel.toISOString();
  }

  public onPeriodicityChanged(periodicity: PeriodicityType) {
    this.searchDto.Periodicity = periodicity;
    this.onDateChanged(DateUtils.newMoment());
  }

  public isAdmin() {
    return this.client.isAdmin;
  }

  public setOptions(options: ClientActivityModalOpts) {
    this.opts = options;
    this.shouldOpenCompanyModal = options.shouldOpenCompanyModal;

    this.onDateChanged(moment());

    if (this.isAdmin()) {
      this.admin.getAllCompanies()
        .then(comps => this.companies = comps)
        .catch(err => this.status.setError(err))
        .finally(() => this.isLoading = false);
    } else {
      this.isLoading = false;
    }

    if (this.shouldOpenCompanyModal) {
      this.searchDto.CompanyId = this.userClient.activeCompany.CompanyId;
    }
  }

  public setCompany(selection: CompanyModel) {
    if (selection === null) {
      this.asCSV = false;
    }
    this.searchDto.CompanyId = selection.CompanyId;
    this.cd.detectChanges();
  }

  public getStats() {
    this.company.getUserStatistics(this.searchDto)
      .then(resp => {
        this.status.setMessage('Get statistics success', 'Success', true);
        const date = DateUtils.newMoment().format('YYYY-MM-DD');
        const content = this.asCSV ? this.generateCSV(resp) : JSON.stringify(resp, null, 2);
        const filename = `user-statistics_${date}.${this.asCSV ? 'csv' : 'txt'}`;
        const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });

        FileUtils.downloadBlob(blob, filename);
      })
      .catch(err => {
        this.status.setError(err, false);
      });
  }

  private generateCSV(stats: UserStatisticsDTO) {
    const getHeader = () => {
      let header = '';
      stats.CompanyActivity[0].UserActivity[0].PeriodActivity.forEach(act => {
        let format;
        switch (this.searchDto.Periodicity) {
          case 'day': {
            format = 'YYYY-MM-DD';
            break;
          }
          case 'week': {
            format = '[W]W';
            break;
          }
          case 'month': {
            format = 'YYYY-MMM';
            break;
          }
          case 'quarter': {
            format = 'YYYY-[Q]Q';
            break;
          }
          case 'halfyear': {
            format = 'YYYY-MM';
            break;
          }
          case 'year': {
            format = '[yE4R]YYYY';
            break;
          }
        }
        header += `${DateUtils.newMoment(act.From).format(format)};`;
      });
      return header;
    };

    let csv = 'User;';
    csv += getHeader() + '\r\n';

    stats.CompanyActivity[0].UserActivity.forEach(user => {
      csv += `${user.UserEmail};`;
      csv += user.PeriodActivity.map(act => act.Minutes).join(';');
      csv += '\r\n';
    });

    return csv;
  }
}
