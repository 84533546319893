import { Def } from '@core/decorators/def';
import { IFileVersion } from '@core/interfaces/if-file';

export class FileVersionModel implements IFileVersion {
  @Def() UploadedFileVersionId: string;
  @Def() FileData: string;
  @Def() CreatedDate: Date;

  getModelId(): string {
    return this.UploadedFileVersionId;
  }
}
