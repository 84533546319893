<indicio-modal-base [child]="this"
                    [modalTitle]="'Move forecast'">

  <form #f="ngForm"
        *ngIf="!isLoading"
        (ngSubmit)="onSubmit()"
        ngNativeValidate>
    <div class="">
      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Forecast name</div>
          <span class="subtitle">Name of the moved forecast</span>
        </div>
        <div class="col-xs-6"
             style="margin-top:15px">
          <mat-form-field>
            <mat-label>Forecast name</mat-label>
            <input data-test-id='forcast-name-input-field'
                   type="text"
                   class="material"
                   matInput
                   autocomplete="off"
                   [ngModel]="moveDto.Name"
                   (ngModelChange)="changeForecastName($event)"
                   name="Name"
                   maxlength="64"
                   required>
            <mat-hint *ngIf="forecastNameMustChange">
              <span class="error-text">A forecast with that name already exists in the target project.</span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Current project name</div>
          <span class="subtitle">The current project of the forecast</span>
        </div>
        <div class="col-xs-6"
             style="margin-top:30px">
          <div>{{ oldProject.Name }}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6">
          <div class="input-label bold">Target project</div>
          <span class="subtitle">The project to move the forecast to</span>
        </div>
        <div class="col-xs-6"
             style="margin-top:15px">
          <mat-form-field>
            <mat-select [value]="moveDto.ProjectId"
                        (selectionChange)="changeProject($event)"
                        [disabled]="projects.length === 0"
                        required
                        placeholder="Pick project">
              <mat-option [value]="project.ProjectId"
                          [title]="project.Name"
                          *ngFor="let project of projects">{{ project.Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row"
           *ngIf="projects.length === 0">
        <div class="col-xs-12">
          <div class="note-box">No target project available</div>
        </div>
      </div>

      <div class="row extra-margin-bottom">
        <div class="col-xs-6">
          <button class="btn outline blue left"
                  type="button"
                  (click)="close()">Cancel</button>
        </div>
        <div class="col-xs-6">
          <button class="btn right"
                  [disabled]="forecastNameMustChange || !moveDto.Name.length"
                  type="submit">
            <span *ngIf="!pending">Move</span>
            <span *ngIf="pending"
                  class="ion-load-c spinner"></span>
          </button>
        </div>
      </div>
    </div>
  </form>

</indicio-modal-base>
