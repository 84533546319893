import { Injectable } from '@angular/core';
import { GraphThemeModel } from '@core/store/client/graph-theme.model';
import { ProfileBackendService } from '@core/store/profile/profile.backend.service';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { ClientState } from '../client/client.state';
import { ProfileModel } from '../client/profile.model';
import { ProfileActions } from './profile.actions';


@Injectable({
  providedIn: 'root'
})
export class ProfileFrontendService {
  private sub: Subscription = new Subscription();


  public get profile() { return this.store.selectSnapshot(ClientState.profile); }
  public profile$() { return this.store.select(ClientState.profile); }

  public get graphThemes() { return this.store.selectSnapshot(ClientState.client).GraphThemes; }
  public graphThemes$() { return this.store.select(ClientState.client); }

  constructor(
    private store: Store,
    private backend: ProfileBackendService
  ) { }

  public updateProfile(model: ProfileModel) {
    return this.backend.updateProfile(model)
      .then(() => {
        this.store.dispatch(new ProfileActions.GetProfileSuccessAction(model));
        return model;
      });
  }

  public createGraphTheme(theme: GraphThemeModel) {
    return this.backend.createTheme(theme);
  }

  public updateGraphTheme(theme: GraphThemeModel) {
    return this.backend.updateTheme(theme);
  }

  public deleteGraphTheme(theme: GraphThemeModel) {
    return this.backend.deleteTheme(theme);
  }
}


