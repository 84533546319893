<ng-container *ngIf="!loadingContent; else loader">
  <div class="forecast-nav-tree-container">
    <ng-container *ngTemplateOutlet="newList"></ng-container>
  </div>
</ng-container>

<ng-template #newList>
  <cdk-virtual-scroll-viewport class="virtual-scroll-container"
                               [style.height]="calculateContainerHeight()"
                               itemSize="30"
                               #scrollViewport
                               *ngIf="expandedNodeList?.length > 0">
    <ng-container *cdkVirtualFor="let node of expandedNodeList; let i = index;">
      <ng-container [ngSwitch]="node.Type">
        <ng-container *ngSwitchCase="'project'">
          <ng-container *ngTemplateOutlet="nodeProjectTemplate; context: { node: node }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'tag'">
          <ng-container *ngTemplateOutlet="nodeTagTemplate; context: { node: node }">
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'forecast'">
          <ng-container *ngTemplateOutlet="nodeForecastTemplate; context: { node: node }">
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="getProjectForCreateForecastButton(node, i) as proj">
        <ng-container *ngTemplateOutlet="createForecastButtonTemplate; context: { level: 2, project: proj }">
        </ng-container>
      </ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #nodeProjectTemplate
             let-n=node>
  <ng-container *ngIf="getPNode(n) as node">
    <ng-container *ngIf="getProject(node) as project">
      <div style="height: 30px;"
           class="node center-vertical cursor-pointer {{ node.Type }}"
           [ngClass]="{ active: isActive(node) }"
           [style.padding-left]="7 + node.level * 7 + 'px'"
           (click)="clickedNode(node, $event)">
        <div style="display:inline-block;">
          <div class="icon">
            <i [class]="treeControl.isExpanded(node) ? 'ion-chevron-down' : 'ion-chevron-right'"></i>
          </div>
        </div>
        <div [title]="node.Name"
             class="crop-string"
             style="display:inline-block;"
             [attr.data-test-id]="'list-item-' + node.Name">
          <span>{{node.Name}}</span>
        </div>
        <ng-container *ngIf="node.CompanyShared; else fillerDiv"></ng-container>
        <div class="info-chip shared"
             indicioTippy="New data available, click to update"
             (click)="openRefreshDialog(node); $event.stopPropagation();"
             *ngIf="node.NewData && project.hasPermission('CAN_UPDATE_FORECAST_VARIABLE'); else fillerDiv">
          <i class="ion-loop"></i>
        </div>
        <div class="info-chip shared"
             indicioTippy="Company shared by {{ node.SharedBy }}"
             *ngIf="node.CompanyShared">
          <i class="ion-ios-people"></i>
        </div>
        <div class="menu">
          <ng-container
                        *ngTemplateOutlet="projectMenuTemplate; context: { node: node, project: project }"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #nodeTagTemplate
             let-node=node>
  <div class="node center-vertical cursor-pointer {{ node.type }}"
       style="height: 30px;"
       [ngClass]="{ active: node.isOpen || node.isActive }"
       (click)="clickedNode(node, $event)"
       [style.padding-left.px]="7 + (node.level) * 7">
    <div style="display:inline-block;">
      <div class="icon"
           style="margin-right:11px;">
        <i [class]="treeControl.isExpanded(node) ? 'ion-chevron-down' : 'ion-chevron-right'"></i>
      </div>
    </div>
    <div [title]="node.Name"
         class="crop-string"
         style="display:inline-block;">
      <span>{{node.Name}}</span>
    </div>
    <div class="menu">
    </div>
  </div>
</ng-template>

<ng-template #nodeForecastTemplate
             let-node=node>
  <indicio-nav-tree-forecast-node [node]="node"
                                  [listView]="listView"
                                  [scrollPosition]="scrollPosition"
                                  (clickEvent)="clickedNode(node, null)"></indicio-nav-tree-forecast-node>
</ng-template>

<ng-template #loader>
  <ng-container *ngIf="getPNode(null)"></ng-container>
  <div class="col-xs-12 center-vertical"
       style="height: 30px; padding-bottom: 10px;">
    <i class="ion-load-c spinner"
       style="margin: 0 5px 0 1px"></i> Loading content
  </div>
</ng-template>

<ng-template #projectMenuTemplate
             let-node="node"
             let-project="project">
  <indicio-options-menu [filler]="true"
                        [nohover]="true"
                        iconsize="21px"
                        [containerScrolled]="scrollPosition"
                        (openEvent)="toggleProjectMenu(node, $event)"
                        fillerTop="-67%">
    <ul>
      <ng-container *ngIf="project.hasPermission('CAN_UPDATE_FORECAST_VARIABLE')">
        <li (click)="openSyncDataDialog(node)"
            indicioTippy="Fetch latest version for each source variable used in this project">
          <i class="ion-loop"></i>
          <div class="text">
            Update data
          </div>
        </li>
        <li *ngIf="isAdmin$ | async"
            (click)="openCalcResultsDialog(node)"
            indicioTippy="Calculate all results for all forecasts used in this project">
          <i class="ion-ios-list-outline"></i>
          <div class="text">
            Calculate
          </div>
        </li>
        <li divider></li>
      </ng-container>
      <ng-container *ngIf="project.hasPermission('CAN_CREATE_FORECAST')">
        <li (click)="createForecast(project)">
          <i class="ion-plus"></i>
          <div class="text">Add forecast</div>
        </li>
        <li divider></li>
      </ng-container>
      <li (click)="editProject(project, 'Members')"
          *ngIf="project.hasPermission('CAN_UPDATE_PROJECT')">
        <i class="ion-android-share"></i>
        <div class="text">Share</div>
      </li>
      <li (click)="editProject(project)">
        <i data-test-id="project-settings-option"
           [ngClass]="project.hasPermission('CAN_UPDATE_PROJECT') ? 'ion-android-settings' : 'ion-information-circled'"></i>
        <div class="text">Settings</div>
      </li>
      <ng-container *ngIf="project.hasPermission('CAN_DELETE_PROJECT')">
        <li divider></li>
        <li (click)="removeProject(project)"
            data-test-id="remove-project-trash-btn">
          <i [ngClass]="!project.isPending ? 'ion-trash-b' : 'ion-load-c spinner'"></i>
          <div class="text">Remove</div>
        </li>
      </ng-container>
    </ul>
  </indicio-options-menu>
</ng-template>

<ng-template #fillerDiv>
  <div></div>
</ng-template>

<ng-template #createForecastButtonTemplate
             let-level="level"
             let-project="project">
  <div class="node center-vertical cursor-pointer create-forecast"
       style="height: 30px;"
       data-test-id="create-forecast-btn"
       (click)="createForecast(project)"
       [style.padding-left.px]="level * 7">
    <div></div>
    <div style="display:inline-block;">
      <div class="icon"
           style="margin-right:3px;">
        <i class="ion-plus green"></i>
      </div>
      <span>Create forecast</span>
    </div>
  </div>
</ng-template>
