import { DisplayValue } from '../types/display-value';

export type FileTypesType = 'file' | 'sharepoint';

export type SourceTypesType = FileTypesType | 'ftp' | 'other' | 'group' | 'valueguard' |
  'bis' | 'scb' | 'kiprognos' | 'eurostat' | 'fred' | 'alphavantage' | 'oecd' |
  'refinitiv' | 'quandl' | 'kolada' | 'forecast' | 'trend' | 'oxford' |
  'acea' | 'macrobondapi' | 'googletrends' | 'bloomberg' | 'sqldatabase' | 'statfin' | 'statcan' | 'statdan' | 'statnor';

// These providers are the ones which can be used for templates
export const RemoteDataProviders: SourceTypesType[] = [
  'macrobondapi',
  'valueguard',
  'bis',
  'scb',
  'statfin',
  'statcan',
  'statdan',
  'statnor',
  'oecd',
  'kiprognos',
  'eurostat',
  'fred',
  'alphavantage',
  'refinitiv',
  'quandl',
  'kolada',
  'oxford',
  'sharepoint',
  'acea',
  'googletrends',
  'sqldatabase'
];

export class SourceType extends DisplayValue<SourceTypesType> { }

export const SourceTypes = [
  new SourceType('ftp', 'FTP', 'This data comes from a ftp server'),
  new SourceType('trend', 'Trend', 'This data is another variables trend'),
  new SourceType('file', 'File', 'This data comes from an uploaded file'),
  new SourceType('macrobondapi', 'Macrobond', 'Macrobond delivers macroeconomic data, to help with financial and econimic analyses'),
  new SourceType('scb', 'SCB', 'Statistics Sweden, the statistical government agency of Sweden'),
  new SourceType('sharepoint', 'Sharepoint', 'Syncronize and browse data from an external sharepoint server'),
  new SourceType('acea', 'ACEA', 'The European Automobile Manufacturers’ Association, or ACEA, unites Europe’s 15 major car, truck, van and bus makers.'),
  new SourceType('googletrends', 'Google Trends', 'Google Trends is an online search tool that allows the user to see how often specific keywords, subjects and phrases have been queried over a specific period of time.'),
  new SourceType('sqldatabase', 'MS SQL Database', 'Connect an SQL Database to access data seamlessly in Indicio.'),
  new SourceType('other', 'Unknown', 'This data has an unknown source'),
  new SourceType('forecast', 'Forecast', 'This data comes from a forecast'),
  new SourceType('oecd', 'OECD', 'Data from the Organisation for Economic Co-operation and Development (OECD)'),
  new SourceType('oxford', 'Oxford Economics', 'Data from Oxford Economics', 'Add credentials below to start working with Oxford Economics! <p>If you do not have an Oxford account, click <a target="_blank" href="https:\/\/www.oxfordeconomics.com/about-us/sales-and-service">here</a> to get one. <p>To reset your Oxford account password, click <a target="_blank" href="https:\/\/login.oxfordeconomics.com/resetPassword">here.</a> </p>'),
  new SourceType('fred', 'FRED', 'Federal Reserve Economic Data (FRED) contains over 765,000 data series, maintained by the Research division of the Federal Reserve Bank of St. Louis', '<p><a href="https://research.stlouisfed.org/" target="_blank">FRED</a> contains over 765,000 data series and is free to use, however you need to create a user account to access it.<br><br> Follow the guide below to get access:</p><ol><li>Go to <a href="https://fred.stlouisfed.org/" target="_blank">https://fred.stlouisfed.org/</a></li><li>Click register in the top right corner and fill in your details</li><li>Log in and click on My account -> API key</li><li>Copy the API key and paste it here</li></ol>Your API will be saved in Indicio so next time you click on FRED you will get instant access.<p style="font-size: 13px;">This product uses the FRED® API but is not endorsed or certified by the Federal Reserve Bank of St. Louis. Read their Terms of use <a href="https://fred.stlouisfed.org/docs/api/terms_of_use.html" target=_blank>here</a></p>'),
  new SourceType('group', 'Group', 'Consolidated variable group'),
  new SourceType('kolada', 'Kolada', 'Swedish municipal and county council database, with over 5000 different key figures'),
  new SourceType('bloomberg', 'Bloomberg', 'Bloomberg provides top-quality financial data to use when making critical decisions.'),
  new SourceType('valueguard', 'Valueguard', 'Valueguard delivers data related to housing and construction in Sweden'),
  new SourceType('kiprognos', 'KI Prognoscenter', 'Konjunkturinstitutets prognosdatabas'),
  new SourceType('eurostat', 'Eurostat', "Eurostat's mission is to provide high quality statistics and data on Europe"),
  new SourceType('bis', 'BIS', 'The Bank for International Settlements (BIS) delivers data on financial stability, international monetary spillovers and global liquidity'),
  new SourceType('refinitiv', 'Refinitiv Datastream', 'Refinitiv Datastream contains over 35 million individual instruments or indicators across all major asset classes, including 8.5 million active economic indicators'),
  new SourceType('statfin', 'Statistics Finland', 'Statistics Finland, the statistical government agency of Finland'),
  new SourceType('statcan', 'Statistics Canada', 'Statistics Canada, the statistical government agency of Canada'),
  new SourceType('statdan', 'Statistics Denmark', 'Statistics Denmark, the statistical government agency of Denmark'),
  new SourceType('statnor', 'Statistics Norway', 'Statistics Norway, the statistical government agency of Norway'),
  new SourceType('quandl', 'Nasdaq Data Link', 'Nasdaq Data Link is a major player within financial and alternative data', '<p>Nasdaq (Nasdaq: NDAQ) is a leading global provider of trading, clearing, exchange technology, listing, information and public company services. It’s free to use but it also comes as a paid version which contains more datasets. To access it, please follow the guide below:<\/p><ol> <li>Go into <a href="https:\/\/data.nasdaq.com" target="_blank">https:\/\/data.nasdaq.com\/<\/a><\/li><li>Click on <a href="https:\/\/data.nasdaq.com\/sign-up" target="_blank">sign up<\/a> and fill in your details  </li>  <li>    Log in and go to <a href="https:\/\/data.nasdaq.com\/account\/profile" target="_blank">account settings</a></li><li>Click generate API key<\/li><li>Copy the API key and paste it here<\/li><\/ol><p>Your API key will be saved in Indicio so next time you click on Nasdaq Data Link you will get instant access.<\/p>'),
  new SourceType('alphavantage', 'Alpha Vantage', 'Alpha vantage delivers stock and FOREX data', 'To use Alphavantage, you need to create an API key <a href="https://www.alphavantage.co/support/#api-key" target="_blank">here<\/a> on Alpha Vantage. Copy/paste the key into the field below.')
];




