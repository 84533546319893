import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableAccessModel, ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { FileDialogService } from '@dialogs/file/file-dialogs.service';
import { FileDialogViewIndex } from '@dialogs/file/info/file-info.dialog.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FileTypesType } from '@modules/lang/language-files/source-types';
import { Store } from '@ngxs/store';
import { AlgConverter } from '@shared/components/line-graph/alg-line.utils';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgOptions } from '@shared/components/line-graph/alg.options';
import { OpenDataProcessingModal } from '@shared/modals/data-processing/data-processing-modal.actions';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { DateFormatPipe } from '@shared/modules/pipes';
import { VariableDialogService } from '../../../../variable-dialogs.service';
import { FVarDialogViewIndex, ForecastVariableInfoDialogData } from '../../forecast-variable-info.dialog';
import { EForecastVariableStatus } from '@core/store/forecast-variable/models/forecast-variable-meta.model';


@Component({
  selector: 'indicio-fvar-info-dialog-general-tab',
  templateUrl: './fvar-info-tab.general.component.html',
  styleUrls: ['./fvar-info-tab.general.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FVarInfoDialogGeneralTabComponent implements OnChanges {

  // Font-aweseome
  public faSave = faSave as IconProp;
  public faInfo = faInfoCircle as IconProp;

  @Input() variable: ForecastVariableModel;
  @Input() sourceVariable: SourceVariableModel;
  @Input() forecast: ForecastModel;
  @Input() forecastVersion: ForecastVersionModel;
  @Input() options: ForecastVariableInfoDialogData;

  @Output() changedSvarMetaEvent = new EventEmitter<ForecastVariableModel>();
  @Output() closeDialogEvent = new EventEmitter();

  // Name settings
  public newName: string;
  public editName: boolean = false;

  // Frontend flags
  private pendingName: boolean = false;
  private pendingVersion: boolean = false;

  // SourceVariable version options
  public newVersionId: string;
  public get sVarVersions() { return !!this.sourceVariable ? this.sourceVariable.Versions.filter(x => (x.FromNowcast && x.ParentForecastId === this.variable.Nowcast?.ForecastId) || !x.FromNowcast) : []; }
  public get sVarVersion() { return this.sVarVersions.find(v => v.SourceVariableMetaId === this.variable.SourceVariableMetaId); }

  // Entity Ids
  public get fVersionId() { return this.options.forecastVersionId; }
  public get forecastId() { return this.options.forecastId; }
  public get fVariableId() { return this.options.forecastVariableId; }

  // Graph setup
  public historicLine: ALGLineModel;
  public graphOptions: AlgOptions.Options;
  public chartStyles = { borderSize: 2, borderRadius: 4, historyLine: '#6388D0', plotHeight: 300 };
  public ownerDetails: ForecastVariableAccessModel;

  // Mixed freq info
  public get noFutureMf() {
    return this.forecastVersion.EnableMFM &&
      !!!this.variable.MixedFreqTwinId &&
      !this.variable.IsMixedFreq &&
      this.variable.sourceVariablePeriodicity.isShorter(this.variable.Periodicity);
  }

  constructor(
    private status: StatusService,
    private store: Store,
    private fVarService: ForecastVariableFrontendService,
    private cd: ChangeDetectorRef,
    private datepipe: DateFormatPipe,
    private dialogService: DialogService,
    private fileDialogs: FileDialogService,
    private varDialogService: VariableDialogService
  ) {
  }

  public ngOnChanges() {
    if (!this.variable) { return; }
    this.newName = this.variable.Name;
    this.newVersionId = this.variable.SourceVariableMetaId;
    this.ownerDetails = {
      IsOwner: this.variable.SourceVariableAccess,
      OwnerEmail: this.variable.OwnerEmail,
      MissingAccessMessage: `You cannot download this variable. It is not shared by the owner. (${this.variable.OwnerEmail} )`
    };
    this.setupGraph();
    this.cd.detectChanges();
  }

  public openMacrobondInfo() {
    this.dialogService.openMacrobondSeriesInfoDialog({ RemoteReferenceId: this.variable.RemoteReferenceId });
  }

  public openFileModal() {
    this.closeDialogEvent.emit();
    this.fileDialogs.openFileInfo({
      fileId: this.sourceVariable.getFileId(),
      type: this.sourceVariable.SourceType as FileTypesType,
      view: FileDialogViewIndex.general,
      companyId: this.sourceVariable.CompanyId
    });
  }

  public openTwin(twinId: string = this.variable.TrendTwinId) {
    this.closeDialogEvent.emit();
    this.varDialogService.openForecastVariableInfo({ forecastId: this.forecastId, forecastVariableId: twinId, forecastVersionId: this.fVersionId, view: FVarDialogViewIndex.general });
  }

  public openDataProcessingModal(variable: ForecastVariableModel) {
    this.fVarService.getOrFetchById(variable.ForecastVersionId, variable.ForecastVariableId)
      .then(_ => this.closeDialogEvent.emit())
      .then(() => this.store.dispatch(new OpenDataProcessingModal(this.forecastId, this.fVersionId, this.fVariableId)))
      .catch(err => this.status.setError(err));
  }

  public showSourceVariableInfo(editTags: boolean = false) {
    const openModal = () => {
      this.closeDialogEvent.emit();
      this.varDialogService.openSourceVariableInfo({ sourceVariableId: this.variable.SourceVariableId, forecastId: this.forecastId, forecastVersionId: this.fVersionId });
    };

    if (editTags) {
      const ref = this.dialogService.openConfirmDialog({
        Title: 'Edit tags',
        Message: 'Tags are handled by the source variable modal, do you want to navigate to this source variable and edit tags for it?',
        ConfirmText: 'Yes, open up the modal.',
        Style: 'primary'
      });
      ref.subscribe((proceed: boolean) => {
        if (!proceed) { return; }
        openModal();
      });
    } else {
      openModal();
    }
  }

  public async saveName() {
    if (this.pendingName) { return; }
    this.pendingName = true;
    this.editName = false;
    this.variable.Name = this.newName;
    return this.fVarService.updateForecastVariableSettings(this.variable)
      .then(() => this.status.setMessage('Variable name updated successfully', 'Success', true))
      .catch(error => this.status.setError(error, true))
      .finally(() => this.pendingName = false);
  }

  public changeSelectedVersion(metaId: string) {
    const newVersion = this.sVarVersions.find(x => x.SourceVariableMetaId === metaId);
    if (!newVersion) { return; }
    this.newVersionId = newVersion.SourceVariableMetaId;
    const ref = this.dialogService.openConfirmDialog({
      Title: 'Change source version?',
      Message: 'Click proceed if you want to change the source variable version used for this forecast variable.',
      ConfirmText: 'Proceed',
      CancelText: 'Cancel',
      ConfirmFunction: this.saveVariable.bind(this),
      Style: 'warn'
    }, { position: { top: '8%' } });
    ref.subscribe(changed => {
      if (!changed) {
        this.newVersionId = this.sVarVersion.SourceVariableMetaId;
        this.cd.detectChanges();
      }
    });
  }

  public saveVariable() {
    if (this.pendingVersion) { return; }
    this.pendingVersion = true;
    this.fVarService.changeSourceMetaIdForFVar(this.fVersionId, this.fVariableId, this.newVersionId)
      .then(updateInfo => {
        this.variable = updateInfo.fVariable;
        this.changedSvarMetaEvent.emit(this.variable);
      })
      .then(() => this.status.setMessage('New variable version set', 'Success', true))
      .catch(error => this.status.setError(error, true))
      .finally(() => this.pendingVersion = false);
  }

  private setupGraph() {
    this.historicLine = undefined;
    this.historicLine = AlgConverter.fromForecastVariable(this.variable);
    this.historicLine.Active = true;
    this.setGraphOptions();
  }

  private setGraphOptions() {
    const header = this.variable.Name;
    const startDate = this.historicLine[0]?.Values[0]?.D;
    const endDate = this.variable.LastDate;
    let subheader = '';

    if (startDate && endDate) {
      subheader = this.datepipe.transform(startDate) + ' - ' + this.datepipe.transform(endDate);
    }

    this.graphOptions = AlgOptions.CreateOptions({
      noDataText: '',
      inModal: true,
      menuConfig: {
        showMenu: true,
        dontShowPastForecastBtn: true,
        showSeasonalAdjBtn: this.variable.SeasonalFound,
        showOutlierAdjBtn: this.variable.OutliersFound,
        forceActiveAggregatedBtn: this.variable.Aggregated,
      },
      dontShowCIHover: true,
      axisConfig: { yAxisPosition: 'inside' },
      isPercent: this.variable.IsPercent,
      header: header,
      closeCallback: () => this.closeDialogEvent.emit(true),
      subheader: subheader,
      dates: this.historicLine.Values.map(x => x.m),
      pointsToShow: this.historicLine.Values.length,
      showOnlyHistoric: true
    });
  }

  protected readonly EForecastVariableStatus = EForecastVariableStatus;
}
