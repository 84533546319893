import { VsResultType } from '@modules/lang/language-files/var-select';
import { VarSelectModalOpts, VarSelectModalView } from './var-select-modal.options';

export class OpenVarSelectResultModal {
  public static readonly type = '[MODAL] Open var-select result modal';
  data: VarSelectModalOpts;
  constructor(
    projectId: string,
    forecastId: string,
    fVersionId: string,
    vsResultType: VsResultType,
    tab: VarSelectModalView = 'influence'
  ) {
    this.data = { projectId, forecastId, fVersionId, resultType: vsResultType, tab };
  }
}
