import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ThemeOptionType } from '@core/constants/theme-options';
import { SetAdvancedMode } from '@core/store/client/client.actions';
import { ClientDialogService } from '@dialogs/client/client-dialogs.service';
import { Store } from '@ngxs/store';
import { DateUtils } from '@shared/utils/date.utils';
import { AuthActions } from '../auth/auth.actions';
import { ClientSettingsService } from '../client/client-settings.service';
import { SourceVariableListViewType } from '../client/dtos/client-settings.dto';
import { ProfileActions } from './profile.actions';

@Injectable({
  providedIn: 'root'
})
export class AppearanceService {

  private currentTime = DateUtils.newDate();

  private loginBgImage = '/assets/images/bg@2x.jpg';
  private backgroundImages = [
    '/assets/images/01.jpg',
    '/assets/images/02.jpg',
    '/assets/images/03.jpg',
    '/assets/images/04.jpg',
    '/assets/images/05.jpg',
    '/assets/images/06.jpg',
  ];
  private currentBg: string = this.backgroundImages[Math.floor(this.backgroundImages.length * Math.random())];
  // issue #: Lägga till stöd för tidszoner, defaulta till webläsarens default om det går
  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private clientDialog: ClientDialogService,
    private clientSettings: ClientSettingsService
  ) { }

  public get settings() { return this.clientSettings.settings; }
  public get DateFormat() { return this.settings.DateFormat; }
  public set DateFormat(newDateFormat: string) { this.settings.DateFormat = newDateFormat; }
  public get TimeFormat() { return this.settings.TimeFormat; }
  public set TimeFormat(value: string) { this.settings.TimeFormat = value; }
  public get TimeFormatNoSeconds() { return this.settings.TimeFormatNoSeconds; }
  public get FullFormat() { return this.settings.FullFormat; }
  public get AdminDate() { return this.settings.AdminDate; }
  public get AdminTimeFormatShort() { return this.settings.AdminDate; }
  public get FullFormatNoSeconds() { return this.settings.FullFormatNoSeconds; }
  public get Theme() { return this.settings.Theme; }
  public get ActualTheme() { return this.settings.ActualTheme; }
  public get DecimalCharacter() { return this.settings.DecimalCharacter; }
  public get AdvancedUI() { return this.settings.AdvancedUI; }
  public get RoCEnabled() { return false; }
  public get RoCYYEnabled() { return false; }
  public set SourceVariableListView(value: SourceVariableListViewType) { this.settings.SourceVariableListView = value; }
  public get SourceVariableListView() { return this.settings.SourceVariableListView; }

  public get isDarkMode() { return this.Theme === 'dark'; }

  public get dateFormat() { return this.DateFormat; }
  public get timeFormat() { return this.TimeFormat; }

  public async openSetThemeDialog() {
    const savedTheme = this.Theme;
    const saved = await this.clientDialog.openClientSettings({});
    this.settings.InitialSetup = true;
    if (!saved) { this.setTheme(savedTheme, false); }
    await this.updateAppearanceSetting();
    this.store.dispatch(new AuthActions.RunPostLogin(true));
  }

  public updateAppearanceSetting() {
    return this.clientSettings.updateSettings(this.settings);
  }

  public toggleAdvancedUI(state: boolean = null) {
    this.settings.AdvancedUI = state !== null
      ? state
      : !this.settings.AdvancedUI;
    this.store.dispatch(new SetAdvancedMode(this.settings.AdvancedUI));
    return this.updateAppearanceSetting();
  }

  public setRandomBg() {
    const bg = this.backgroundImages[Math.floor(this.backgroundImages.length * Math.random())];
    this.currentBg = bg;
    this.setBg(bg);
  }

  public resetBg() {
    this.setBg(this.currentBg);
  }

  public setLoginBg() {
    this.setBg(this.loginBgImage);
  }

  private setBg(bg?: string) {
    const htmlElement = <HTMLElement> document.querySelector('body');
    if (!htmlElement) { return; }
    if (bg) {
      htmlElement.style.setProperty('--background-image', `url(${bg})`);
    } else {
      htmlElement.style.setProperty('--background-image', 'unset');
    }
  }

  public GetDateFormats(): any[] {
    return [
      {
        display: this.datePipe.transform(this.currentTime, 'yyyy-MM-dd'),
        value: 'yyyy-MM-dd',
        title: 'yyyy-MM-dd'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'dd-MM-yyyy'),
        value: 'dd-MM-yyyy',
        title: 'dd-MM-yyyy'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'dd/MM/yyyy'),
        value: 'dd/MM/yyyy',
        title: 'dd/MM/yyyy'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'dd-MM-yy'),
        value: 'dd-MM-yy',
        title: 'dd-MM-yy'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'dd/MM/yy'),
        value: 'dd/MM/yy',
        title: 'dd/MM/yy'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'd/M-yy'),
        value: 'd/M-yy',
        title: 'd/M-yy'
      }
    ];
  }

  public GetTimeFormats(): any[] {
    return [
      {
        display: this.datePipe.transform(this.currentTime, 'HH:mm:ss'),
        value: 'HH:mm:ss',
        title: 'HH:mm:ss'
      },
      {
        display: this.datePipe.transform(this.currentTime, 'HH:mm'),
        value: 'HH:mm',
        title: 'HH:mm'
      }
    ];
  }


  public setTheme(theme: ThemeOptionType, save = true) {
    const themeToSave = theme;
    if (theme === 'default' || theme === 'auto') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theme = 'dark';
      } else {
        theme = 'blue';
      }
    }
    this.settings.Theme = themeToSave;
    this.settings.ActualTheme = theme;
    document.querySelector('body').className = `${theme}-theme`;
    if (theme === 'legacy') {
      this.setBg(this.currentBg);
    } else {
      this.setBg();
    }
    if (!save) { return Promise.resolve(); }
    return this.updateAppearanceSetting().then(() => {
      this.store.dispatch(new ProfileActions.SavedColorTheme(theme));
    });
  }
}
