import { ChangeCompanyModalOpts } from './change-company-modal.options';

export class OpenChangeCompanyModal {
  public static readonly type = '[MODAL] Open change company modal';
  data: ChangeCompanyModalOpts = null;
  constructor(
    oldCompanyId: string,
    newCompanyId: string
  ) { this.data = { newCompanyId: newCompanyId, oldCompanyId: oldCompanyId }; }
}
