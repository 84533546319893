import { AuthToken } from './auth-token';

export namespace AuthActions {
  export class Logout {
    public static readonly type = '[AUTH] Logout';
    constructor(public remember2fa: boolean = true) { }
  }

  export class StoreToken {
    public static readonly type = '[AUTH] Store auth token';
    constructor(public token: AuthToken) { }
  }

  export class StoredToken {
    public static readonly type = '[AUTH] Stored auth token in state';
    constructor(public token: AuthToken) { }
  }

  export class StoreRememberMe {
    public static readonly type = '[AUTH] Store remember me cookie';
    constructor(public obj: any) { }
  }

  export class RunPostLogin {
    public static readonly type = '[AUTH] Run post login actions';
    constructor(public checkCompanies = false) { }
  }
}


export class ClearStateAction {
  public static readonly type = '[AUTH] Clear state';
  constructor() { }
}
