<h1 mat-dialog-title>Manage connection to {{ env.getSourceType(data.Source.Provider).Display }}</h1>
<div mat-dialog-content>
  State: <span *ngIf="connectionLoader; else connectionStateTemplate"
        class="ion-load-c spinner"></span>
  <ng-template #connectionStateTemplate>
    <span [class]="connected ? 'green' : 'red'">{{ connected ? 'Connected' : 'Disconnected' }}</span>
  </ng-template>
</div>

<div mat-dialog-actions
     style="justify-content: space-between;">
  <button mat-flat-button
          (click)="close()">
    Close
  </button>
  <ng-container *ngIf="!connectionLoader">
    <button mat-flat-button
            *ngIf="connected; else connectTemplate"
            class="red"
            (click)="disconnect()">
      Disconnect
    </button>
  </ng-container>

  <ng-template #connectTemplate>
    <button mat-flat-button
            class="green"
            [disabled]="connectLoading"
            (click)="connect()">
      Connect
    </button>
  </ng-template>
</div>
