import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { BloombergService } from '@core/store/providers/bloomberg/bloomberg.service';
import {
  BloombergFieldDTO,
  CreateRequestDTO,
  FigiResultDTO,
  OpenFigiSearchDTO,
} from '@core/store/providers/bloomberg/entities/bloomberg-requests.dtos';
import { DisplayValue } from '@modules/lang/types/display-value';
import * as moment from 'moment';
import { debounceTime, map } from 'rxjs/operators';
import { DialogV2BaseDialog } from '../../../base/dialogs.V2.base-dialog';
import { BloombergCreateRequestDialogData, BloombergRecurrenceOptionType, BloombergRecurrenceOptions } from './create-request.dialog.constants';

@Component({
  selector: 'indicio-bloomberg-create-request-dialog',
  templateUrl: 'create-request.dialog.html',
  styleUrls: ['./create-request.dialog.less'],
})
export class BloombergCreateRequestDialogComponent extends DialogV2BaseDialog<BloombergCreateRequestDialogComponent> {

  public static Id: string = 'BloombergCreateRequestDialogComponent';

  // Form controlls
  public instrumentCtrl = new FormControl<string>('');
  public fieldCtrl = new FormControl<string>('');

  // Search related fields
  public figiQuery: string;
  public figiSearch = new OpenFigiSearchDTO();
  public exchangeCodes: DisplayValue<string>[];
  public marketSectors: string[];

  // Instrument search
  public instrumentStates: FigiResultDTO[];
  public selectedInstrument: FigiResultDTO;
  public selectedInstrumentString: string;
  public instrumentLoading = false;

  // Fields search
  public fieldStates: BloombergFieldDTO[];
  public selectedField: BloombergFieldDTO;
  public selectedFieldString: string;
  public fieldLoading = false;
  public fieldQuery: string;

  // Selected information
  public allEx: DisplayValue<string> = { Display: '* All *', Value: 'xXx', Description: 'All exchanges' };
  public allMark = '* All markets *';
  public selectedExchange: DisplayValue<string> = this.allEx;
  public selectedMarket = this.allMark;
  public title: string = '';
  public instruments: string[] = [];
  public fields: string[] = [];
  public startDate: moment.Moment = moment();
  public minDate: Date = new Date();
  public timeOfDay: string = moment().format('HH:MM');
  public recurrence: BloombergRecurrenceOptionType = 'weekly';
  public recurrenceOptions = BloombergRecurrenceOptions;

  public pending = false;

  public get canSave() {
    return this.instruments.length && this.fields.length && this.title.length;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: BloombergCreateRequestDialogData,
    public dialogRef: MatDialogRef<BloombergCreateRequestDialogComponent, boolean>,
    public service: BloombergService,
    private statusService: StatusService
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.instrumentCtrl.valueChanges.pipe(debounceTime(700), map((state) => {
      if (!state.length || state === this.selectedInstrumentString) { return; }
      this.searchFigi(state);
    })
    ).subscribe();

    this.fieldCtrl.valueChanges.pipe(map((state) => {
      if (state?.length < 3 || state === this.selectedFieldString) { return; }
      this.fieldLoading = true;
      this.service.searchFields(state)
        .then((results) => this.fieldStates = results)
        .finally(() => this.fieldLoading = false);
    })
    ).subscribe();

    /* Populate the exhange codes and market sector arrays in the bloomberg service for later use. Used during manual instrument adding. */
    const exPromise = this.service.getExchangeCodes();
    const markPromise = this.service.getMarketSectors();
    Promise.all([exPromise, markPromise])
      .then(([ex, mark]) => {
        this.exchangeCodes = [this.allEx, ...ex];
        this.marketSectors = [this.allMark, ...mark];
      })
      .finally(() => this.initialized = true);
  }

  public setSelectedExchange(val: DisplayValue<string>) {
    this.selectedExchange = val;
    if (val.Value === 'xXx') {
      this.figiSearch.exchCode = null;
    } else {
      this.figiSearch.exchCode = val.Value;
    }
    this.searchFigi(this.instrumentCtrl.value);
  }

  public setSelectedMarket(val: string) {
    this.selectedMarket = val;
    if (val === this.allMark)
      this.figiSearch.marketSecDes = null;
    else {
      this.figiSearch.marketSecDes = val;
    }
    this.searchFigi(this.instrumentCtrl.value);
  }

  public setTitle(title: string) {
    this.title = title.replace(/[^a-zA-Z0-9]/g, '');
  }

  public formatInstrumentOption(ticker: FigiResultDTO) {
    this.selectedInstrumentString = this.getInstrumentString(ticker);
    this.instrumentCtrl.setValue(this.selectedInstrumentString);
  }

  public formatFieldOption(field: BloombergFieldDTO) {
    this.selectedFieldString = field.Mnemonic;
    this.fieldCtrl.setValue(field.Mnemonic);
  }

  public addInstrument() {
    if (!this.selectedInstrumentString.length || this.instruments.includes(this.selectedInstrumentString)) {
      return;
    }
    this.instruments.push(this.selectedInstrumentString);
    this.selectedInstrumentString = '';
  }

  public removeInstrument(ticker: string) {
    if (!ticker.length || !this.instruments.includes(ticker)) { return; }
    this.instruments = this.instruments.filter(x => x !== ticker);
  }

  private getInstrumentString(ticker: FigiResultDTO) {
    return `${ticker.Ticker} ${ticker.ExchangeCode} ${ticker.MarketSector}`;
  }

  public addField(field: string) {
    if (!field.length || this.fields.includes(field)) {
      return;
    }
    field = field.toUpperCase();
    this.fields.push(field);
    this.selectedFieldString = '';
  }

  public removeField(field: string) {
    if (!field.length || !this.fields.includes(field)) { return; }
    this.fields = this.fields.filter(x => x !== field);
  }

  public async searchFigi(query: string) {
    this.instrumentStates = [];
    if (!query) { return; }
    this.figiSearch.query = query;
    this.instrumentLoading = true;
    return this.service.searchFigi(this.figiSearch)
      .then(res => this.instrumentStates = res)
      .finally(() => (this.instrumentLoading = false));
  }

  public chosenStartdate(date: moment.Moment) {
    this.startDate = date;
  }

  public openAddManualTicker() {
    this.service.openGetManualTickerDialog()
      .then(figiInfo => {
        if (!figiInfo) { return; }
        const figiString = this.getInstrumentString(figiInfo);
        if (this.instruments.includes(figiString)) { return; }
        this.instruments.push(figiString);
      });
  }

  public createRequest() {
    this.pending = true;

    const dto = Object.assign(new CreateRequestDTO, {
      Tickers: this.instruments,
      Fields: this.fields,
      Days: 9999,
      // max 40 chars, a-zA-Z0-9, no spaces
      Title: this.title,
      Trigger: this.recurrence,
      RequestStartDate: this.startDate.format('YYYY-MM-DD'),
      Time: this.timeOfDay,
      // max 21 chars, a-zA-Z0-9 first char must be a letter, no spaces
      Identifier: this.getIdentifier(),
    });

    return this.service
      .createRequest(this.data.catalogId, dto)
      .then((results) => {
        this.save();
        this.statusService.setMessage(results.Message, 'Success');
      })
      .catch((err) => this.statusService.setError(err, true))
      .finally(() => (this.pending = false));
  }

  private getIdentifier() {
    let identifier = this.title;
    // Limit identifier to 21 chars, only a-zA-Z0-9, first char must be a letter and no spaces
    identifier = identifier.replace(/[^a-zA-Z0-9]/g, '');
    identifier = identifier.substring(0, 21);
    identifier = identifier.replace(/^[^a-zA-Z]+/, '');
    return identifier;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save() {
    this.dialogRef.close(true);
  }
}
