<indicio-dialog-base>

  <h1 mat-dialog-title>Create new tag</h1>
  <div mat-dialog-content>
    <section class="margin-top">
      <mat-checkbox [checked]="newTag.Type === 3"
                    [disabled]="disableRegion"
                    (change)="toggleRegion($event)">Region</mat-checkbox>
    </section>
    <mat-form-field class="margin-top">
      <mat-label>Tag name</mat-label>
      <input type="text"
             class="material"
             matInput
             autocomplete="off"
             [ngModel]="newTag.Name"
             (ngModelChange)="setName($event)"
             name="Name"
             maxlength="64"
             require>
      <mat-hint *ngIf="nameError">
        <span class="error-text">{{ nameError }}</span>
      </mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="!disableRelatives">
      <mat-label>Parent tags</mat-label>
      <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let tag of newTag.ParentTags"
                      [selectable]="true"
                      [removable]="true"
                      (removed)="removeParent(tag)">
          {{ tag.Name }}
          <mat-icon matChipRemove
                    *ngIf="true">cancel</mat-icon>
        </mat-chip-row>
        <input placeholder="Add new parent"
               #parentInput
               [formControl]="parentControl"
               [matAutocomplete]="autoParent"
               [matChipInputFor]="chipList">
      </mat-chip-grid>
      <mat-autocomplete #autoParent="matAutocomplete"
                        (optionSelected)="selectedParent($event)">
        <mat-option *ngFor="let parent of filteredParents | async"
                    [value]="parent">
          {{parent.Name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </div>


  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Cancel</button>
    <button [disabled]="!newTagValid()"
            mat-flat-button
            [mat-dialog-close]="newTag"
            cdkFocusInitial>
      Create
    </button>
  </div>
</indicio-dialog-base>
