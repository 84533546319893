import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'indicio-stepped-progress',
  templateUrl: './stepped-progress.component.html',
  styleUrls: ['./stepped-progress.component.less']
})
export class SteppedProgressComponent {

  @Input() steps: string[];
  @Input() step: number = 1;

  @Output() stepChangeEvent = new EventEmitter<number>();

  public setStep(step: number) {
    this.step = step;
    this.stepChangeEvent.emit(this.step);
  }
}
