import { RemoteCalls } from '@core/constants/remote-calls.constants';
import { Def } from '@core/decorators/def';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';

export class CreateForecastDTO {
  // Max length 128
  @Def() Name: string;
  @Def() Periodicity: PeriodicityType;
  @Def() StepChosen: number;
  @Def() Horizon: number = 3;
  @Def() StartDate: String;
  @Def() ProjectId: string;
  @Def() SourceVariableId: string;

  // frontend helpers
  public unsaved? = false;
  public status?: RemoteCalls.Steps;
}
