import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { StatusService } from '@core/services/status/status.service';
import { CanSwapForecastVariableDTO } from '@core/store/forecast-variable/dtos/create-forecast-variable-dto';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { SwapVariablesStepEventArgsDTO } from '@core/store/forecast/dtos/forecast-version/pusher-event-args.dto';
import { ForecastActions } from '@core/store/forecast/forecast.actions';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';

export interface SwapVariableDialogData {
  forecastVariable: ForecastVariableModel;
  forecastVersion: ForecastVersionModel;
}

@Component({
  selector: 'indicio-swap-variable-dialog',
  templateUrl: 'swap-variable.dialog.html',
})
export class SwapVariableDialogComponent {
  public static Id: string = 'SwapVariableDialogComponent';

  public stepStatus: SwapVariablesStepEventArgsDTO = new SwapVariablesStepEventArgsDTO();
  public canSwapStatus: CanSwapForecastVariableDTO;
  public canSwapLoading = true;

  public forecastVersion: ForecastVersionModel;
  public forecastVariable: ForecastVariableModel;
  public userHasTriggeredSwap: boolean = false;
  public hasOldMain: boolean = false;
  public forbidden: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SwapVariableDialogComponent>,
    private fvarService: ForecastVariableFrontendService,
    private status: StatusService,
    private actions: ActionService,
    @Inject(MAT_DIALOG_DATA) public data: SwapVariableDialogData) {
    this.setupActionListener();
    this.forecastVariable = data.forecastVariable;
    this.forecastVersion = data.forecastVersion;
    this.hasOldMain = !!this.forecastVersion.ForecastVariable;
    this.stepStatus.ForecastVersionId = data.forecastVersion.ForecastVersionId;
    this.stepStatus.MainVariableRemoved = !this.hasOldMain;
    this.fvarService.canSwapVariable(this.forecastVersion.ForecastVersionId, this.forecastVariable.ForecastVariableId)
      .then((dto) => {
        this.canSwapStatus = dto;
      })
      .catch(error => {
        this.status.setError(error);
        this.forbidden = true;
      }).finally(() => { this.canSwapLoading = false; });
  }

  private setupActionListener() {
    this.actions.dispatched(ForecastActions.SetSwapVariableStepInfo).subscribe((e: ForecastActions.SetSwapVariableStepInfo) => {
      this.stepStatus = e.obj;
    });
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(null);
  }

  proceedSwapping() {
    if (this.forbidden) {
      return this.onNoClick();
    }
    this.userHasTriggeredSwap = true;
    this.fvarService.swapVariable(this.forecastVersion.ForecastVersionId, this.forecastVariable.ForecastVariableId)
      .catch(error => {
        this.status.setError(error);
      });
  }
}
