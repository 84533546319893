import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { CreateNowcastModalOpts } from './create-nowcast-modal.options';

export class OpenCreateNowcastModal {
  public static readonly type = '[MODAL] Open create nowcast';
  data: CreateNowcastModalOpts;
  constructor(
    variable: ForecastVariableModel,
    forecastVersion: ForecastVersionModel,
    isMainVariable: boolean
  ) {
    this.data = { forecastVersion, variable, isMainVariable };
  }
}
