import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { RemotePathEntryDTO } from '../dtos/remote-data-path-entry.dto';
import { RemoteDataRequestDTO } from '../dtos/remote-data-request-dto';
import { RemoteDataSourceDTO } from '../dtos/remote-data-source.dto';
import { RemoteDataSourceModel } from '../models/remote-data-source.model';
import { RemoteDataMapper } from '../remote-data.mapper';
import { ConnectDbDTO, CreateDbConfigurationDTO, DbConfigurationDTO, DbTableInfoDTO, DbVariableMetaDTO } from './sql-database.dtos';

@Injectable({ providedIn: 'root' })
export class DatabaseService {

  private _loading = new BehaviorSubject<boolean>(false);
  public isLoading$() { return this._loading.asObservable(); }
  public setLoading(loading: boolean) { this._loading.next(loading); }

  public activeCategory: RemotePathEntryDTO = null;
  public activeLeaf: string = null;

  constructor(
    private http: HttpService,
    private store: Store,
    private remoteMapper: RemoteDataMapper
  ) { }

  public getVariable(remoteSourceId: string, meta: DbVariableMetaDTO) {
    return this.http.post<RemoteDataRequestDTO>(`my/db-connections/${remoteSourceId}/variable`, meta)
      .then(resp => resp.body);
  }

  public disconnectDatabase(remoteSourceId: string) {
    return this.http.delete(`my/db-connections/${remoteSourceId}/disconnect`);
  }

  public getSelectionVariables(remoteSourceId: string, categorySelection: string[], skip: number) {
    return this.http.post<DbVariableMetaDTO[]>(`my/db-connections/${remoteSourceId}/get-selection`, { Selection: categorySelection, Skip: skip })
      .then(resp => resp.body);
  }

  public search(remoteSourceId: string, dto: { SearchString: string, Skip: number; }) {
    return this.http.post<DbVariableMetaDTO[]>(`my/db-connections/${remoteSourceId}/search`, dto)
      .then(resp => resp.body);
  }

  public addNewConnection(remoteSourceId: string, dto: ConnectDbDTO) {
    return this.http.post<RemoteDataSourceDTO>('my/db-connections/add-or-update', { RemoteDataSourceId: remoteSourceId, Database: dto })
      .then(resp => this.remoteMapper.toRemoteDataSourceDetailModel(resp.body));
  }

  public getNavContent(remoteSourceId: string, force: boolean) {
    return this.http.get<any[]>(`my/db-connections/${remoteSourceId}/get-nav-content?force=${force}`)
      .then(resp => resp.body);
  }

  public getDbMetaInfo(remoteSourceId: string) {
    return this.http.get<DbTableInfoDTO[]>(`my/db-connections/${remoteSourceId}/get-meta-info`)
      .then(resp => resp.body);
  }

  public addNewDbConfig(remoteSource: RemoteDataSourceModel, dto: CreateDbConfigurationDTO) {
    return this.http.post<DbConfigurationDTO>(`my/db-connections/${remoteSource.RemoteDataSourceId}/configure`, dto)
      .then(resp => {
        remoteSource.DatabaseDTO.Config = resp.body;
        return { remoteSource, cfg: resp.body };
      });
  }

  public syncDbConfig(remoteSource: RemoteDataSourceModel) {
    return this.http.get<DbConfigurationDTO>(`my/db-connections/${remoteSource.RemoteDataSourceId}/sync-config`)
      .then(resp => {
        remoteSource.DatabaseDTO.Config = resp.body;
        return { remoteSource, cfg: resp.body };
      });
  }
}
