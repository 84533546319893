import { Component } from '@angular/core';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { KoladaKPIGroupMemberModel } from '@core/store/providers/kolada/kolada-groups.model';
import { KoladaKPIModel } from '@core/store/providers/kolada/kolada-kpi.model';
import { KoladaMunicipalityModel } from '@core/store/providers/kolada/kolada-municipality.model';
import { KoladaOrganizationalUnitModel } from '@core/store/providers/kolada/kolada-organizational-unit.dto';
import { KoladaService } from '@core/store/providers/kolada/kolada.service';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { ProviderService } from '@core/store/providers/provider.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { Store } from '@ngxs/store';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OrderByPipe } from '@shared/modules/pipes';
import { KoladaImportModalOpts } from './kolada-import-modal.options';


const RIKET_ID = '0000';

@Component({
  selector: 'indicio-kolada-add-import-modal',
  templateUrl: './kolada-import-modal.component.html'
})
export class KoladaImportModalComponent extends ModalModelComponent {

  member: KoladaKPIGroupMemberModel;
  forecastVersion: ForecastVersionModel;
  dividedByGender: boolean;

  kpi: KoladaKPIModel;
  kommuner: KoladaMunicipalityModel[];
  landsting: KoladaMunicipalityModel[];
  ous: KoladaOrganizationalUnitModel[];

  remoteDataModel = new RemoteDataRequestModel;

  variableName: string;
  chosenMunicipality: string;
  chosenMunicipalityModel: KoladaMunicipalityModel = null;
  chosenUO: string;
  gender: string;
  nameConflict = false;

  genderOptions = [
    {
      Display: 'Man',
      Value: 'M'
    },
    {
      Display: 'Kvinna',
      Value: 'K'
    },
    {
      Display: 'Totalt',
      Value: 'T'
    }
  ];

  public get loading() { return this.kpi === undefined || this.kommuner === undefined; }

  constructor(
    public store: Store,
    private koladaService: KoladaService,
    private providerService: ProviderService,
    private clientService: ClientFrontendService,
    private forecastVariableService: ForecastVariableFrontendService,
    private statusService: StatusService,
    public envService: EnvironmentService,
    private orderPipe: OrderByPipe,
    private sourcevariableService: SourceVariableFrontendService
  ) {
    super();
  }

  public setOptions(options: KoladaImportModalOpts) {
    this.member = options.member;
    this.forecastVersion = options.forecast;

    this.remoteDataModel.Provider = DataProvider.kolada;

    this.getKPI();
    this.getMunicipalities();
  }

  private getKPI() {
    return this.koladaService.getKPI(this.member.member_id).then(kpi => {
      this.kpi = kpi[0];
      this.variableName = this.kpi.title;
      this.remoteDataModel.MetaData = this.kpi.faKeyValueArray();
      this.checkName();
    });
  }

  public checkName() {
    this.nameConflict = this.sourcevariableService.isNameConflict(this.variableName);
  }

  private getMunicipalities() {
    return this.koladaService.getMunicipalities().then(municipalities => {
      let kommuner = municipalities.filter(m => m.type === 'K');
      let landsting = municipalities.filter(m => m.type === 'L');
      const riket = <KoladaMunicipalityModel> { title: 'Riket', id: RIKET_ID, type: 'R' };

      kommuner = this.orderPipe.transform(kommuner, 'title', undefined, false);
      landsting = this.orderPipe.transform(landsting, 'title', undefined, false);

      kommuner.unshift(riket);
      landsting.unshift(riket);

      this.kommuner = kommuner;
      this.landsting = landsting;
    });
  }

  public setMunicipality(model: KoladaMunicipalityModel) {
    this.chosenMunicipalityModel = model;
    this.chosenMunicipality = model.id;
    this.chosenUO = null;
    if (this.kpi.has_ou_data && model.id !== RIKET_ID) {
      this.ous = [];
      this.koladaService.getMunicipalityOUs(model.id).then(ous => {
        this.ous = this.orderPipe.transform(ous, 'title', undefined, false);
      });
    }
  }

  public getMunicipalityValues() {
    return this.koladaService.getMunicipalityValues(this.member.member_id, this.chosenMunicipality);
  }

  public getOUValues() {
    return this.koladaService.getOUValues(this.member.member_id, this.chosenUO)
      .catch(_error => {
        throw new Error('Error getting data for this organization unit');
      });
  }

  public async addVariable() {
    this.pending = true;
    try {
      const data = await this.getData();

      if (!data.values.length) {
        this.statusService.setMessage(`Ingen data hittad för ${this.getMuniName(this.chosenMunicipality)}.`, 'Error', true);
        this.pending = false;
        return;
      }

      this.remoteDataModel.Data = data;
      this.remoteDataModel.Name = this.variableName;
      this.remoteDataModel.RemoteUri = data.uri;
      this.remoteDataModel.RemoteReferenceId = this.kpi.id;

      if (this.gender) {
        this.remoteDataModel.Extras = {
          gender: this.gender
        };
      }

      this.providerService.addVariableFromDatasource(this.clientService.activeCompany.CompanyId, this.remoteDataModel)
        .then(sourcevariable => {
          this.statusService.setMessage('Variable added successfully', 'Success');
          this.close();
          this.forecastVariableService.checkErrorsAndCreateForecastVariable(sourcevariable, this.forecastVersion)
            .catch(_err => {
              this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [sourcevariable.SourceVariableId]));
            });
        })
        .catch(err => this.statusService.setError(err, true))
        .finally(() => this.pending = false);
    } catch (error) {
      this.pending = false;
      this.statusService.setMessage('Error fetching observations', 'Error', true);
    }
  }

  private async getData() {
    if (this.chosenUO) {
      return await this.getOUValues();
    } else {
      return await this.getMunicipalityValues();
    }
  }

  private getMuniName(id) {
    try {
      let muni = this.kommuner.find(k => k.id === id);
      if (!muni) {
        muni = this.landsting.find(k => k.id === id);
      }
      return muni.title;
    } catch (e) {
      return '';
    }
  }
}
