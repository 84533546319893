import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CopyForecastDTO } from '@core/store/forecast/dtos/forecast/copy-forecast-dto';
import { UpdateForecastDTO } from '@core/store/forecast/dtos/forecast/update-forecast-dto';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProjectModel } from '@core/store/project/project.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Periodicity } from '@modules/lang/types/periodicity';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

@Component({
  selector: 'indicio-fcast-dialog-general-tab',
  templateUrl: './fcast-tab.general.component.html',
  styleUrls: ['./fcast-tab.general.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FCastSettingsDialogGeneralTabComponent implements OnChanges {

  @Input() forecast: ForecastModel;
  @Input() forecastVersion: ForecastVersionModel;
  @Input() project: ProjectModel;
  @Input() canUpdateForecast: boolean;

  @Output() closeDialogEvent = new EventEmitter();

  // New settings
  public newName: string;
  public newPeriodicity: PeriodicityType;
  public forecastNameMustChange: boolean = false;

  // Periodicity settings
  public validPeriodicities: Periodicity[];

  public pending: boolean = false;

  public get changed() {
    return this.newName !== this.forecast.Name || this.newPeriodicity !== this.forecast.Periodicity;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private forecastService: ForecastFrontendService,
    private companyService: CompanyFrontendService,
    private dialogService: DialogService,
    private status: StatusService,
    private router: Router,
    private statusService: StatusService,
    public envService: EnvironmentService
  ) {
  }

  public ngOnChanges() {
    this.newName = this.forecast.Name;
    this.newPeriodicity = this.forecast.Periodicity;
    this.checkForecastName();
    this.validPeriodicities = this.envService.getValidPeriodicites(this.companyService.horizonInfos);

    this.cd.detectChanges();
  }

  public save() {
    if (!this.changed) { return Promise.resolve(); }
    if (this.forecastVersion.hasData && this.newPeriodicity !== this.forecast.Periodicity) {
      // We cannot allow the periodicity to change to a shorter one than the main variable-source periodicity, since that would lead
      // to the main variable being dis-aggregated.
      if (!!this.forecastVersion.ForecastVariable &&
        this.forecastVersion.ForecastVariable.sourceVariablePeriodicity.isLonger(this.newPeriodicity)) {
        return this.dialogService.openConfirmDialog({
          Title: 'Periodicity not compatible with main variable.',
          Message: `You cannot change the periodicity of this forecast to ${this.newPeriodicity}ly since the main variable cannot be dis-aggregated.`,
          ConfirmText: 'Reset periodicity',
          Style: 'primary',
          ExtraWarning: '',
          HideCancel: true
        }, { position: { top: '7%' } }).toPromise();
      }

      const ref = this.dialogService.openConfirmDialog({
        Title: 'Copy forecast to change periodicity',
        Message: 'Changing the periodicity can only be done through copying the forecast since the variables will be (dis)-aggregated.',
        ConfirmText: 'Copy forecast',
        Style: 'primary',
        ExtraWarning: '',
        CancelText: 'Cancel'
      }, { position: { top: '7%' } });

      return ref.toPromise().then((proceed: boolean) => {
        if (!proceed) { return; }
        this.pending = true;
        const dto = new CopyForecastDTO();
        dto.ForecastId = this.forecast.ForecastId;
        dto.ForecastVersionId = this.forecastVersion.ForecastVersionId;
        dto.IncludeAssessments = true;
        dto.IncludeEvents = true;
        dto.IncludeScenarios = true;
        dto.IncludeIndicators = true;
        dto.Name = 'Copy: ' + this.forecast.Name;
        dto.ProjectId = this.forecast.ProjectId;
        dto.Periodicity = this.newPeriodicity;
        return this.forecastService.copyForecast(dto)
          .then(copy => {
            this.status.setMessage('Forecast copied successfully', 'Success');
            this.router.navigateByUrl(`/forecast/${copy.ForecastId}/(forecast:overview)?copy=true`);
          })
          .catch(error => {
            this.status.setError(error, true);
          })
          .finally(() => {
            this.pending = false;
          });
      });
    } else {
      if (this.changed) {
        return this.updateForecast();
      }
    }
  }

  public updateForecast() {
    return this.forecastService.updateForecast(this.forecast, <UpdateForecastDTO> { Periodicity: this.forecast.Periodicity, Name: this.newName })
      .then(_ => {
        this.status.setMessage('Forecast updated successfully', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
        return error;
      })
      .finally(() => { this.pending = false; });
  }

  public changeForecastName(newName: string) {
    this.newName = newName.trim();
    this.checkForecastName();
  }

  public changePeriodicity(newPeriodicity: PeriodicityType) {
    this.newPeriodicity = newPeriodicity;
  }

  private checkForecastName() {
    return this.forecastService.forecastNameConflict(this.newName, this.project, this.forecast.ForecastId).then(notOk => {
      this.forecastNameMustChange = !!notOk;
      return notOk;
    });
  }
}
