import { Injectable } from '@angular/core';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { RemovalWarningsBackendService } from './removal-warnings.backend.service';

@Injectable({
  providedIn: 'root'
})
export class RemovalWarningsFrontendService {

  constructor(
    private service: RemovalWarningsBackendService,
    private companyService: CompanyFrontendService,
  ) { }

  public getRemovalWarningsForForecast(fcId: string) {
    return this.service.getRemovalWarningsForForecast(fcId);
  }

  public getRemovalWarningsForForecastVersion(fVersionId: string) {
    return this.service.getRemovalWarningsForForecastVersion(fVersionId);
  }

  public getRemovalWarningsForProject(projectId: string) {
    return this.service.getRemovalWarningsForProject(projectId, this.companyService.activeCompanyId);
  }
}
