import { DiagnosticInfoDTO } from '@core/store/forecast-variable/dtos/diagnostic-info-dto';
import { Transformations } from '@core/types/transformation.type';
import { DisplayValue } from '@modules/lang/types/display-value';
import { Periodicity } from '@modules/lang/types/periodicity';
import { VariableTransformation } from '@modules/lang/types/variable-transformation';

export namespace FVarAdvancedSettings {

  export class Data {
    public diagnostic: DiagnosticInfoDTO = null;
  }

  export const conclusionDisplay: DisplayValue<number>[] = [
    { Value: 0, Display: 'Stationary' },
    { Value: 1, Display: 'Stationary with trend' },
    { Value: 2, Display: 'Not stationary' },
    { Value: 3, Display: 'Heteroscedasticity is affecting the results' },
    { Value: 4, Display: 'Too few observations for conclusive results' }
  ];

  export function FilterModelTransformations(transformations: VariableTransformation[]) {
    return transformations.filter(x => Transformations.Models.includes(x.Value));
  }

  export function FilterVariableTransformations(p: Periodicity, transformations: VariableTransformation[]) {
    let options = transformations.filter(x => Transformations.Variable.includes(x.Value));
    if (p.is('year')) { options = options.filter(x => !x.Value.match(/YoY/i)); }
    return options;
  }
}
