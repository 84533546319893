import { AccuracyDropdownOptions, AccuracyMeasureType, EAccuracyChartView } from '@core/constants/accuracy.constants';
import { AccuracyMeasureDTO } from '@core/entities/dtos/accuracy-measure-dto';
import { DataTable } from '@shared/components/data-table/data-table.model';
import { AccuracyMeasureUtils } from '@shared/utils/forecast/accuracy-measure.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import { ErrorBarChart } from './error-bar-chart.input';

class ErrorBarChartMapperOptions {
  OutOfSampleEnabled: boolean;
  Models: ErrorBarChart.Model[];
}

export class ErrorBarchartMapper {

  constructor(private opts: ErrorBarChartMapperOptions) { }

  public toDataTable(view: EAccuracyChartView, measurement: AccuracyMeasureType) {
    const models = this.opts.Models;
    let dataTables: DataTable[] = [];

    switch (view) {
      case EAccuracyChartView.INSAMPLE_OUTOFSAMPLE_FIRSTSTEP:
      case EAccuracyChartView.INSAMPLE_FIRSTSTEP:
        dataTables = this.setupInOutSample(models);
        break;
      case EAccuracyChartView.OUTOFSAMPLE_FIRST_LASTSTEP:
        dataTables = this.setupOutSampleFirstLastStep(models);
        break;
      case EAccuracyChartView.OUTOFSAMPLE_AVERAGE:
        dataTables = this.setupOutSampleAverage(models);
        break;
      case EAccuracyChartView.OUTOFSAMPLE_ALLSTEPS:
        dataTables = this.setupOutSampleAllSteps(models, measurement);
        break;
    }

    dataTables.forEach(config => {
      config.PercentageColumns = config.Columns.map((x, i) => x === 'MAPE' || x === 'MPE' ? i : null).filter(x => x !== null);
    });

    return dataTables;
  }

  public orderTable(table: DataTable, columnIndex: number) {
    if (table.TitleColumn === columnIndex) { return; }
    if (table.OrderByColumn === columnIndex) {
      table.OrderByDirection = table.OrderByDirection === 'asc' ? 'desc' : 'asc';
    }
    table.OrderByColumn = columnIndex;
    table.Rows = table.Rows.sort((a, b) => {
      const aVal = a[columnIndex];
      const bVal = b[columnIndex];
      if (aVal === bVal) { return 0; }
      if (aVal > bVal) { return table.OrderByDirection === 'asc' ? 1 : -1; }
      return table.OrderByDirection === 'asc' ? -1 : 1;
    });
  }

  public setHighlightColumn(title: AccuracyMeasureType, table: DataTable) {
    table.HighlightedColumn = table.Columns.indexOf(title);
  }

  private setupInOutSample(models: ErrorBarChart.Model[]) {
    const types = ['inSample'];
    let tables: DataTable[] = [];
    if (this.opts.OutOfSampleEnabled) { types.push('outSample'); }
    types.forEach((graphType: 'inSample' | 'outSample') => {
      const title = graphType === 'inSample' ? 'In-sample accuracy' : 'Out-of-sample accuracy';
      const table = this.createNewDataTable(title, AccuracyDropdownOptions.map(x => x.Value), tables);
      models.forEach(model => {
        const barChartEntry = this.createDataTableEntry(model);
        if (tables.length > 0) { barChartEntry.shift(); }
        barChartEntry.push(...AccuracyDropdownOptions.map(x => this.getInOutPropertyValue(model, graphType, x.Value)));
        table.Rows.push(barChartEntry);
      });
      tables.push(table);
    });
    return tables;
  }

  private setupOutSampleFirstLastStep(models: ErrorBarChart.Model[]) {
    const types = ['first', 'last'];
    const tables: DataTable[] = [];
    types.forEach((type: 'first' | 'last') => {
      const title = type === 'first' ? 'First step accuracy' : 'Last step accuracy';
      const table = this.createNewDataTable(title, AccuracyDropdownOptions.map(x => x.Value), tables);
      models.forEach(model => {
        const barChartEntry = this.createDataTableEntry(model);
        if (tables.length > 0) { barChartEntry.shift(); }
        barChartEntry.push(...AccuracyDropdownOptions.map(x => this.getOutAccurracyFirtstLastValue(model, type, x.Value)));
        table.Rows.push(barChartEntry);
      });
      tables.push(table);
    });
    return tables;
  }

  private setupOutSampleAverage(models: ErrorBarChart.Model[]) {
    const types = ['average'];
    const tables: DataTable[] = [];
    types.forEach((type: 'average') => {
      const title = 'Average accuracy';
      const table = this.createNewDataTable(title, AccuracyDropdownOptions.map(x => x.Value), tables);
      models.forEach(model => {
        const barChartEntry = this.createDataTableEntry(model);
        if (tables.length > 0) { barChartEntry.shift(); }
        barChartEntry.push(...AccuracyDropdownOptions.map(x => this.getOutAccurracyAverageValue(model, x.Value)));
        table.Rows.push(barChartEntry);
      });
      tables.push(table);
    });
    return tables;
  }

  private setupOutSampleAllSteps(models: ErrorBarChart.Model[], measurement: AccuracyMeasureType) {
    const steps = (models[0][`${measurement}Steps`] as AccuracyMeasureDTO[]).map(x => x.Step);
    let tables: DataTable[] = [];
    const title = 'All steps accuracy';
    const table: DataTable = this.createNewDataTable(title, steps.map(x => `Step ${x}`), tables);
    models.forEach(model => {
      const values = steps.map(x => this.getOutAccurracyStepValue(model, x - 1, measurement));
      const barChartEntry = this.createDataTableEntry(model);
      if (tables.length > 0) { barChartEntry.shift(); }
      barChartEntry.push(...values);
      table.Rows.push(barChartEntry);
    });
    tables.push(table);
    return tables;
  }

  private createNewDataTable(title: string, values: string[], tables: DataTable[]): DataTable {
    const table: DataTable = { Title: title, Rows: [], Columns: [], ColumnConfig: '', TitleColumn: undefined, PercentageColumns: [] };
    if (tables.length === 0) {
      table.Columns.unshift('Model');
      table.TitleColumn = 0;
    }
    table.Columns.push(...values);
    table.ColumnConfig = this.getColumnConfig(tables, table);
    return table;
  }

  private getColumnConfig(tables: DataTable[], table: DataTable): string {
    if (tables.length === 0) { return `1fr repeat(${table.Columns.length - 1}, minmax(60px, calc(100% / ${table.Columns.length - 1})))`; }
    return `repeat(${table.Columns.length}, minmax(60px, calc(100% / ${table.Columns.length})))`;
  }

  private createDataTableEntry(model: ErrorBarChart.Model) {
    const entry = [];
    entry.push(model.display);
    return entry;
  }


  private getOutAccurracyFirtstLastValue(model: ErrorBarChart.Model, step: 'first' | 'last', accuracyType: AccuracyMeasureType) {
    const values = model[`${accuracyType}Steps`] as AccuracyMeasureDTO[];
    let value;
    switch (step) {
      case 'first':
        value = this.getOutAccurracyStepValue(model, 0, accuracyType);
        break;
      case 'last':
        value = this.getOutAccurracyStepValue(model, values.length - 1, accuracyType);
        break;
    }
    if (!value) { return null; }
    return this.toDisplay(value);
  }

  private getOutAccurracyStepValue(model: ErrorBarChart.Model, step: number, accuracyType: AccuracyMeasureType) {
    const values = model[`${accuracyType}Steps`] as AccuracyMeasureDTO[];
    if (!values?.length || !values[step]) { return null; }
    let value = values[step].Value;
    return this.toDisplay(value);
  }

  private getOutAccurracyAverageValue(model: ErrorBarChart.Model, accuracyType: AccuracyMeasureType) {
    const values = model[`${accuracyType}Steps`] as AccuracyMeasureDTO[];
    if (!values.length) { return null; }
    const value = values.reduce((a, b) => a + b.Value, 0) / values.length;
    return this.toDisplay(value);
  }

  private getInOutPropertyValue(model: ErrorBarChart.Model, inOut: 'inSample' | 'outSample', accuracyType: AccuracyMeasureType) {
    const value = inOut === 'inSample'
      ? AccuracyMeasureUtils.getInPropertyValue(model, accuracyType)
      : AccuracyMeasureUtils.getOutPropertyValue(model, accuracyType);
    if (!value) { return null; }
    return this.toDisplay(value);
  }

  private toDisplay(v: any): string {
    return ValueUtils.getValueAsAbbreviatedString(Math.abs(+(v || 0)));
  }
}
