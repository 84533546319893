import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShapValueDTO } from '@core/store/stat-model/dtos/stat-model.dto';
import * as moment from 'moment';

export interface ShapValuesDialogData {
  ShapValues: ShapValueDTO[];
  ForecastVersionId: string;
  Subtitle: string;
}

@Component({
  selector: 'indicio-shapley-values-dialog',
  templateUrl: 'shap-values.dialog.html',
  styleUrls: ['./shap-values.dialog.less']
})
export class ShapValuesDialogComponent {
  public static Id: string = 'ShapValuesDialogComponent';

  public dates: moment.Moment[] = [];

  constructor(
    public dialogRef: MatDialogRef<ShapValuesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShapValuesDialogData) {
    this.dates = this.data.ShapValues[0].Values.map(x => x.m);
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
