import { DateUtils } from '@shared/utils/date.utils';
import { GraphData } from '../alg-models/graph-data.model';
import { ALGTypes } from '../alg-types';
import { ALGUtils } from '../alg.utils';
import { GraphDataInput } from './get-graph-data';

export interface setLineSegmentsInput {
  GraphData: GraphData,
  GraphDataInput: GraphDataInput,
  FromDate: Date,
  ToDate: Date,
}

export function setupLineSegments(inp: setLineSegmentsInput) {
  if (inp.GraphData.DataEmpty) { return inp.GraphData; }

  inp.GraphData.HistoricSegments = getHistoricSegments(inp);
  const first = inp.GraphData.HistoricSegments[0];
  if (first?.invalidStart && first.Values[0].D > inp.FromDate) {
    inp.GraphData.HistoricSegments.splice(0, 1);
  }
  setForecastedSegments(inp);
  setFittedSegments(inp);
  setLineSegments(inp);
  setPastForecastSegments(inp);
  return inp.GraphData;
}

function setLineSegments(inp: setLineSegmentsInput) {
  return inp.GraphData.Lines.forEach(line => {
    const valsToUse = DateUtils.filterBetween(line.Values, inp.FromDate.getTime(), inp.ToDate.getTime());
    line.Segments = ALGUtils.splitLineIntoSegments(valsToUse);
  });
}

function getHistoricSegments(inp: setLineSegmentsInput): ALGTypes.LineSegment[] {
  let toShow = inp.GraphData.HistoricLine.Values;
  if (toShow.length === 0) { return []; }
  if (inp.GraphDataInput.ShowHistoricData === false && toShow.length > 0) {
    toShow = toShow.filter(v => v.V != null).slice(-2);
  }

  const valsToUse = DateUtils.filterBetween(toShow, inp.FromDate.getTime(), inp.ToDate.getTime());
  return ALGUtils.splitLineIntoSegments(valsToUse);
}

function setForecastedSegments(inp: setLineSegmentsInput) {
  inp.GraphData.Forecasted.forEach(forecasted => {
    const valsToUse = DateUtils.filterBetween(forecasted.Values, inp.FromDate.getTime(), inp.ToDate.getTime());
    forecasted.Segments = ALGUtils.splitLineIntoSegments(valsToUse);
  });
}

function setFittedSegments(inp: setLineSegmentsInput) {
  const fieldName = inp.GraphDataInput.GraphType === 'summary' ? 'WF' : 'F';
  inp.GraphData.Fitted.forEach(fitted => {
    const valsToUse = DateUtils.filterBetween(fitted.Values, inp.FromDate.getTime(), inp.ToDate.getTime());
    fitted.Segments = ALGUtils.splitLineIntoSegments(valsToUse, fieldName);
  });
}

function setPastForecastSegments(inp: setLineSegmentsInput) {
  inp.GraphData.PastForecasts.forEach((modelInfo) => {
    modelInfo.Segments = [];
    modelInfo.PastForecasts.forEach(pasts => {
      pasts.forEach(e => e.V = !isFinite(e.V) ? null : e.V);
      const segs = ALGUtils.splitLineIntoSegments(pasts, 'V');
      modelInfo.Segments.push(segs);
    });
  });
}
