import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  AccuracyChartViewDropdownOptions,
  AccuracyDropdownOptions,
  AccuracyMeasureType,
  EAccuracyChartView
} from '@core/constants/accuracy.constants';
import { AccuracyMeasureDTO } from '@core/entities/dtos/accuracy-measure-dto';
import { ActionService } from '@core/services/actions/actions.service';
import { DisplayActions } from '@core/store/display/display.actions';
import { DisplayFrontendService } from '@core/store/display/display.frontend.service';
import {
  GetMultivariateModelSuccessAction,
  GetUnivariateModelSuccessAction,
  MultivariateActions
} from '@core/store/stat-model/stat-model.actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { DisplayValue } from '@modules/lang/types/display-value';
import { BarChartEntry, BarChartOptions } from '@shared/components/graphs/bar-chart/bar-chart.options';
import { AccuracyMeasureUtils } from '@shared/utils/forecast/accuracy-measure.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { Subscription } from 'rxjs';
import { ErrorBarChart } from './error-bar-chart.input';

@Component({
  selector: 'indicio-error-bar-chart',
  templateUrl: './error-bar-chart.component.html',
  styleUrls: ['./error-bar-chart.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorBarChartComponent implements OnInit, OnChanges, OnDestroy {

  private sub = new Subscription();
  public faIcon: IconProp = faXmark;

  private labelWidths: Map<string, number> = new Map();
  private modelCount: number;

  @Input() data: ErrorBarChart.Input;
  @Output() openAccuracyDialogEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  /* Settable property that stops the update from happening, e.g. when the sample accuracy dialog is open. */
  public updatable: boolean = true;
  public chartOptions: DisplayValue<EAccuracyChartView>[];
  public chartOptionsExtras = new Map<EAccuracyChartView, { Disable: boolean, Tippy: string; }>();
  public chosenChartOption: DisplayValue<EAccuracyChartView>;
  public activeMeasurement: DisplayValue<AccuracyMeasureType>;
  public measurements: DisplayValue<AccuracyMeasureType>[] = AccuracyDropdownOptions;
  public barChartConfigs: BarChartOptions[] = [];
  public showContent: boolean = true;

  constructor(
    private displayService: DisplayFrontendService,
    private actions: ActionService,
  ) {
  }

  public get isPercentage() { return this.activeMeasurement?.Value === 'MAPE' || this.activeMeasurement?.Value === 'MPE'; }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public ngOnChanges() {
    this.setup();
  }



  public toggleShow() {
    this.showContent = !this.showContent;
    if (!this.showContent) { return; }
    setTimeout(() => this.updateChart(), 0);
  }

  public ngOnInit() {
    this.setupSubscriptions();
  }

  private setupSubscriptions() {
    this.sub.add(this.actions.dispatched(
      DisplayActions.AccuracyMeasureVariableMultivariate,
      DisplayActions.AccuracyMeasureVariableUnivariate
    ).subscribe((event: DisplayActions.AccuracyMeasureVariableMultivariate | DisplayActions.AccuracyMeasureVariableUnivariate) => {
      this.activeMeasurement = this.measurements.find(x => x.Value === event.state);
      this.updateChart();
    }));

    this.sub.add(this.actions.dispatched(
      DisplayActions.AccuracyChartView,
    ).subscribe((event: DisplayActions.AccuracyChartView) => {
      this.chosenChartOption = this.chartOptions.find(x => x.Value === event.state);
      this.updateChart();
    }));

    this.sub.add(this.actions.dispatched(
      GetUnivariateModelSuccessAction,
      GetMultivariateModelSuccessAction,
      MultivariateActions.BuiltTransformsSet
    ).subscribe(() => this.setup()));
  }

  public changeAccuracyMeasure(value: DisplayValue<AccuracyMeasureType>) {
    this.activeMeasurement = value;
    this.displayService.setSetting({ AccuracyMeasureVariableMultivariate: this.activeMeasurement.Value });
    this.displayService.setSetting({ AccuracyMeasureVariableUnivariate: this.activeMeasurement.Value });

    this.updateChart();
  }

  public changeChartOption(value: DisplayValue<EAccuracyChartView>) {
    this.chosenChartOption = value;
    this.displayService.setSetting({ AccuracyChartView: this.chosenChartOption.Value });

    this.updateChart();
  }

  public updateChart(showThisModelName: string = null) {
    if (!this.updatable) { return; }
    this.newSetup(showThisModelName);
  }

  private newSetup(forceShowModelName: string = null) {
    const models = this.data.transformations.filter(x => !!x && x.show);
    this.barChartConfigs = [];

    if (!models.length) { return; }
    let configs: BarChartOptions[];

    switch (this.chosenChartOption.Value) {
      case EAccuracyChartView.INSAMPLE_OUTOFSAMPLE_FIRSTSTEP:
      case EAccuracyChartView.INSAMPLE_FIRSTSTEP:
        configs = this.setupInOutSample(models);
        break;
      case EAccuracyChartView.OUTOFSAMPLE_FIRST_LASTSTEP:
        configs = this.setupOutSampleFirstLastStep(models);
        break;
      case EAccuracyChartView.OUTOFSAMPLE_AVERAGE:
        configs = this.setupOutSampleAverage(models);
        break;
      case EAccuracyChartView.OUTOFSAMPLE_ALLSTEPS:
        configs = this.setupOutSampleAllSteps(models);
        break;
    }

    configs.forEach(config => {
      this.sortAndFixChartData(config, forceShowModelName);

      if (config.Data.every(x => x.Value === null)) { return; }

      this.barChartConfigs.push(config);
    });
  }

  private setupInOutSample(models: ErrorBarChart.Model[]) {
    const titles = ['In-sample'];
    const steps = [-1];
    if (this.data.oosEnabled) {
      titles.push('Out-of-sample');
      steps.push(1);
    }
    return this.setupSteps(titles, steps, models);
  }

  private setupOutSampleFirstLastStep(models: ErrorBarChart.Model[]) {
    const titles = ['First step', 'Last step'];
    const steps = [1, this.data.horizon];
    return this.setupSteps(titles, steps, models);
  }

  private setupOutSampleAverage(models: ErrorBarChart.Model[]) {
    return this.setupSteps(['Average'], [0], models);
  }

  private setupOutSampleAllSteps(models: ErrorBarChart.Model[]) {
    const steps = [...Array(this.data.horizon).keys()].map(index => index + 1);
    const titles = steps.map(index => `Step ${index}`);
    return this.setupSteps(titles, steps, models);
  }

  private setupSteps(titles: string[], steps: number[], models: ErrorBarChart.Model[]) {
    let barCharts: BarChartOptions[] = [];
    steps.forEach((step, i) => {
      const barchart: BarChartOptions = this.createNewBarchart(titles[i]);
      models.forEach(model => {
        // Step 0 is average out of sample, step -1 is first in sample step and positive is actual steps out of sample.
        let value = step >= 0 ? this.getOutAccuracyValue(model, step) : this.getInAccuracyValue(model);
        let isNA = false;
        if (value === null) {
          value = 0;
          isNA = true;
          return;
        }
        const barChartEntry = this.createBarchartEntry(model, value, isNA);
        barchart.Data.push(barChartEntry);
      });
      barCharts.push(barchart);
    });
    return barCharts;
  }

  private createBarchartEntry(model: ErrorBarChart.Model, value: number, isNA: boolean = false) {
    const barChartEntry = new BarChartEntry();
    barChartEntry.Label = StringUtils.cropIfNeeded(model.display, 30, 'middle');
    barChartEntry.Color = model.color;
    barChartEntry.Value = value;
    barChartEntry.Id = model.name;
    barChartEntry.IsNA = isNA;

    if (this.labelWidths.has(barChartEntry.Label)) {
      barChartEntry.nTextBBoxW = this.labelWidths.get(barChartEntry.Label);
    } else {
      barChartEntry.nTextBBoxW = StringUtils.getTextWidth({ text: barChartEntry.Label, family: '"Roboto", serif', size: this.modelCount > 20 ? '9.5px' : '11px' });
      this.labelWidths.set(barChartEntry.Label, barChartEntry.nTextBBoxW);
    }

    return barChartEntry;
  }

  private sortAndFixChartData(barchart: BarChartOptions, forceShowModelName: string) {
    const reversed = AccuracyMeasureUtils.shouldScaleBeReversed(this.activeMeasurement.Value);
    const compareItems = (a: BarChartEntry, b: BarChartEntry) => {
      if (!a.IsNA && b.IsNA) {
        return -1; // Move a to the end
      } else if (a.IsNA && !b.IsNA) {
        return 1; // Move b to the end
      }
      return reversed ? b.Value - a.Value : a.Value - b.Value; // Compare values
    };

    barchart.Data.sort(compareItems);
    barchart.Data.forEach((entry, i) => { if (i > 2 && forceShowModelName !== entry.Id) { entry.HideLabel = true; } });
  }

  private createNewBarchart(title: string): BarChartOptions {
    return { Title: title, Data: [], Percentage: this.isPercentage, Labels: { xAxis: '', yAxis: '' } };
  }

  private getOutAccuracyValue(model: ErrorBarChart.Model, step: number) {
    const chosenAccuracy = this.activeMeasurement.Value;
    const values = model[`${chosenAccuracy}Steps`] as AccuracyMeasureDTO[];
    if (!values.length) { return null; }
    let value = null;
    if (step === 0 && values.length === this.data.horizon)
      value = values.reduce((a, b) => a + b.Value, 0) / values.length;
    else if (step > 0 && values.length >= step)
      value = values[step - 1].Value;
    return value != null ? Math.abs(value) : null;
  }

  private getInAccuracyValue(model: ErrorBarChart.Model) {
    const value = AccuracyMeasureUtils.getInPropertyValue(model, this.activeMeasurement.Value);
    return value != null ? Math.abs(value) : null;
  }

  private setup() {
    if (!this.data) { return; }
    const settings = this.displayService.settings;
    this.modelCount = this.data.transformations.length;
    let accuracyMeasure;
    switch (this.data.type) {
      case 'multivariate':
        accuracyMeasure = settings.AccuracyMeasureVariableMultivariate;
        break;
      case 'univariate':
        accuracyMeasure = settings.AccuracyMeasureVariableUnivariate;
        break;
    }

    this.chartOptions = [...AccuracyChartViewDropdownOptions];
    this.chartOptions.forEach(o => this.chartOptionsExtras.set(o.Value, { Disable: false, Tippy: null }));
    this.activeMeasurement = this.measurements.find(x => x.Value === accuracyMeasure);
    this.chosenChartOption = this.chartOptions.find(x => x.Value === settings.AccuracyChartView);

    if (!this.data.oosEnabled) {
      this.chartOptions = [
        this.chartOptions.find(x => x.Value === EAccuracyChartView.INSAMPLE_FIRSTSTEP),
        ...this.chartOptions.filter(x => x.Value !== EAccuracyChartView.INSAMPLE_FIRSTSTEP)
      ];
      this.chartOptions
        .filter(x => x.Value !== EAccuracyChartView.INSAMPLE_FIRSTSTEP)
        .forEach(disable => this.chartOptionsExtras.set(disable.Value, { Disable: true, Tippy: 'Measurement not available when out-of-sample is disabled.' }));

      if (this.chosenChartOption.Value !== EAccuracyChartView.INSAMPLE_FIRSTSTEP) {
        this.changeChartOption(this.chartOptions.find(x => x.Value === EAccuracyChartView.INSAMPLE_FIRSTSTEP));
      }
    } else {
      this.chartOptions = AccuracyChartViewDropdownOptions.filter(x => x.Value !== EAccuracyChartView.INSAMPLE_FIRSTSTEP);
      if (this.data.horizon === 1) {
        this.chartOptions
          .filter(x => x.Value !== EAccuracyChartView.INSAMPLE_OUTOFSAMPLE_FIRSTSTEP)
          .forEach(disable => this.chartOptionsExtras.set(disable.Value, { Disable: true, Tippy: 'Measurement not available when the forecast horizon is 1.' }));
      }

      if (this.chosenChartOption.Value === EAccuracyChartView.INSAMPLE_FIRSTSTEP) {
        this.changeChartOption(this.chartOptions.find(x => x.Value === EAccuracyChartView.INSAMPLE_OUTOFSAMPLE_FIRSTSTEP));
      }
    }

    if (this.chosenChartOption == null) {
      this.changeChartOption(this.chartOptions[0]);
    }

    setTimeout(() => this.updateChart(), 0);
  }
}
