import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ProviderDefinitions } from '@core/constants/provider-definitions';
import { IMemberModel } from '@core/interfaces/if-member';
import { MemberMapper } from '@core/mappers/member.mapper';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { UpdatedUserPermissionsAction } from '@core/store/client/client.actions';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyActions } from '@core/store/company/company.actions';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { VisibilityLevelType } from '@modules/lang/language-files/visibility';
import { Store } from '@ngxs/store';
import { MembersListComponent } from '@shared/components';
import { MemberListOptions } from '@shared/components/member-list/member-list.options';
import { OpenClientActivityModal } from '@shared/modals/client-activity/client-activity-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { OpenChangeCompanyModal } from '../change-company/change-company-modal.actions';
import { OpenCompanyModal } from './company-modal.actions';
import { CompanyModalOpts } from './company-modal.options';

export type CompanyModalViewTypes = 'General' | 'Users' | 'Projects' | 'Providers' | 'Tags' | 'Security';

@Component({
  selector: 'indicio-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.less']
})
export class CompanyModalComponent extends ModalModelComponent {

  @ViewChild(MembersListComponent)
  public employeesList: MembersListComponent;

  // Input properties
  opts: CompanyModalOpts = null;
  company: CompanyModel = null;
  projects: ProjectModel[] = [];
  canUserEdit = false;
  isUserGuest = false;
  memberListOptions: MemberListOptions = null;

  view: CompanyModalViewTypes = 'General';
  shouldInviteNewMember = false;
  emailValid = true;
  canResetCompanyData = false;
  saveAndInviteTitle = '';
  _isChanged = false;

  initialCompany: string;

  providers: any[];

  SourceFromProviderVisibility: VisibilityLevelType = 'private';
  SourceFromFileVisibility: VisibilityLevelType = 'private';

  public get members() { return this.memberListOptions.CurrentMembers; }
  public get ownerIdx() { return this.members.findIndex(member => member.IsOwner === true); }
  public get owner() { return this.members.find(x => x.IsOwner === true); }
  public get userCanInvite() { return this.company.hasPermission('CAN_INVITE_COMPANY_USERS'); }
  public get newMemberRoleOptions() { return this.memberListOptions.NewMemberRoleOptions; }
  public get memberService() { return this.memberListOptions.MemberService; }
  public get companies() { return this.companyService.companies; }
  public sortedMembers: IMemberModel[] = [];

  constructor(
    protected store: Store,
    public appearance: AppearanceService,
    private actions: ActionService,
    private status: StatusService,
    public envService: EnvironmentService,
    private cd: ChangeDetectorRef,
    private profileService: ProfileFrontendService,
    private companyService: CompanyFrontendService,
    private projectService: ProjectFrontendService,
    private memberMapper: MemberMapper,
    private clientService: ClientFrontendService,
    private dialogService: DialogService
  ) {
    super();
  }

  public get isCompanyChanged() {
    return this.initialCompany !== JSON.stringify(this.company);
  }

  private get profile() { return this.profileService.profile; }
  public get invitesToSend() { return this.employeesList.invitesToSendOnSave.length; }
  public toggleSaveMemberButton(shouldToggle) {
    this.shouldInviteNewMember = shouldToggle;
  }

  public async setSourceFromProviderVisibility($event) {
    const value = $event.value;
    this.SourceFromProviderVisibility = value;
    this.status.setMessage('Please hold tries to save.', 'Warning', true);
    const ok = await this.companyService.setSourceFromProviderVisibility(this.clientService.activeCompany, this.SourceFromProviderVisibility);
    if (ok) {
      this.status.setMessage('Saved successfully', 'Success', true);
    } else {
      this.status.setMessage('Saved unsuccessfully', 'Error', true);
    }
  }

  public async setSourceFromFileVisibility($event) {
    const value = $event.value;
    this.SourceFromFileVisibility = value;
    this.status.setMessage('Please hold tries to save.', 'Warning', true);
    const ok = await this.companyService.setSourceFromFileVisibility(this.clientService.activeCompany, this.SourceFromFileVisibility);
    if (ok) {
      this.status.setMessage('Saved successfully', 'Success', true);
    } else {
      this.status.setMessage('Saved unsuccessfully', 'Error', true);
    }
  }

  public setCompany(companyId: string) {
    const current = this.clientService.client.ActiveCompanyId;
    if (current !== companyId) {
      this.close();
      this.store.dispatch(new OpenChangeCompanyModal(current, companyId));
    }
  }

  public getEmployeeEmail(empId: string) {
    return this.clientService.activeCompany.Employees.find(e => e.ClientId === empId);
  }

  private setupSubscriptions() {
    this.addSubscription(this.actions.dispatched(UpdatedUserPermissionsAction).subscribe({
      next: () => this.setPermissions(),
    }));
    this.addSubscription(this.actions.dispatched(CompanyActions.GetCompanySuccessAction).subscribe({
      next: (value: CompanyActions.GetCompanySuccessAction) => {
        if (value.company.CompanyId === this.company.CompanyId) {
          this.company = value.company;
          this.setupMemberListOptions();
        }
      }
    }));
  }

  public async setOptions(options: CompanyModalOpts) {
    this.SourceFromProviderVisibility = this.clientService.activeCompany.SourceFromProviderVisibility;
    this.SourceFromFileVisibility = this.clientService.activeCompany.SourceFromFileVisibility;

    this.opts = options;
    this.view = options.view || 'General';

    const cPromise = this.companyService.getOrFetchCompany(this.opts.companyId);
    const pPromise = this.projectService.fetchAllProjects(this.opts.companyId)
      .catch(_e => this.projects = []);

    Promise.all([cPromise, pPromise])
      .then(([c, p]) => {
        this.company = c;
        this.projects = p;
        this.setupSubscriptions();
        if (this.userCanInvite) {
          this.companyService.get2FAInfoForCompany(this.company).finally(() => this.setup());
        } else {
          this.setup();
        }
      });
  }

  private setup() {
    this.setSaved();
    this.providers = ProviderDefinitions.getDataProviders().filter(x => !this.company.DisabledProviders.includes(x.Name));
    this.setPermissions();
    this.setupMemberListOptions();
    const env = this.envService.env;
    if (this.company.isUserAdmin(this.profile.Email) && env.Environment !== 'Production') {
      this.canResetCompanyData = true;
    }
    this._isChanged = this.isCompanyChanged;
    this.isLoading = false;
  }

  private setSaved() {
    this.initialCompany = JSON.stringify(this.company);
  }

  public removeAllCompanyData() {
    const ref = this.dialogService.openConfirmDialog({
      Title: 'Remove company data',
      Message: 'Remove all data in this company?',
      ConfirmText: 'Yes - Delete',
      Style: 'warn',
    }, { width: '522px' });

    ref.subscribe((accept: boolean) => {
      if (!accept) { return; }
      this.close();
      this.store.dispatch(new OpenCompanyModal(this.opts.companyId));
      this.companyService.removeCompanyData(this.company)
        .then(() => this.status.setMessage('Company data removed', 'Success', true))
        .catch((err) => this.status.setError(err, true));
    });
  }

  public resetAllUsers() {
    const ref = this.dialogService.openConfirmDialog({
      Title: 'Reset user data',
      Message: 'Reset user data for all employees in this company?',
      ConfirmText: 'Yes - Delete',
      Style: 'warn',
    }, { width: '522px' });

    ref.subscribe((accept: boolean) => {
      if (!accept) { return; }
      this.close();
      this.store.dispatch(new OpenCompanyModal(this.opts.companyId));
      this.companyService.resetUsers(this.company)
        .then(() => this.status.setMessage('User data reset', 'Success', true))
        .catch((err) => this.status.setError(err, true));
    });
  }

  public openClientActivityModal() {
    this.close();
    this.store.dispatch(new OpenClientActivityModal(true, this.opts.companyId));
  }

  public canViewUserStatistics() {
    return this.clientService.activeCompany.hasPermission('CAN_VIEW_USER_STATISTICS');
  }

  public canForce2FA() {
    return this.clientService.activeCompany.hasPermission('CAN_FORCE_2FA');
  }

  public setFiscalYear(change: MatSelectChange) {
    this.company.FiscalYear = change.value;
    this.companyService.updateCompany(this.company)
      .then(c => {
        this.status.setMessage('Fiscal year updated', 'Success', true);
        this.company = c;
        this.setSaved();
      })
      .catch(error => {
        this.status.setError(error, true);
      });
  }

  private setPermissions() {
    this.canUserEdit = this.company.canUserEdit(this.profileService.profile.Email);
    this.isUserGuest = this.company.isUserGuest(this.profileService.profile.Email);
  }

  public toggleForce2FA() {
    this.company.Force2FA = !this.company.Force2FA;
    this.companyService.set2FACompany(this.company.CompanyId, { Force2FA: this.company.Force2FA })
      .then(() => this.status.setMessage('Updated security setting', 'Success', true))
      .catch(err => this.status.setError(err, true))
      ;
  }

  public toggleForceAutomaticLogout() {
    this.company.ForceAutomaticLogout = !this.company.ForceAutomaticLogout;
    this.companyService.setAutomaticLogout(this.company.CompanyId, this.company.ForceAutomaticLogout)
      .then(() => this.status.setMessage('Updated security setting', 'Success', true))
      .catch(err => this.status.setError(err, true))
      ;
  }

  public toggleDisabledProvider(providerId: string) {
    const idx = this.company.DisabledProviders.indexOf(providerId);
    if (idx > -1) {
      this.company.DisabledProviders.splice(idx, 1);
    } else {
      this.company.DisabledProviders.push(providerId);
    }
  }

  private async setupMemberListOptions() {
    const options: MemberListOptions = {
      CurrentMembers: this.company.Employees.map(e => this.memberMapper.mapCompanyEmployee(e, this.company)),
      NewMemberSuggestions: [],
      MemberService: this.companyService,
      CurrentUserCanInvite: this.company.hasPermission('CAN_INVITE_COMPANY_USERS'),
      CurrentUserEmail: this.profile.Email,
      CompanyId: this.company.CompanyId,
      ProjectId: null,
      NewMemberRole: this.envService.getCompanyRole('companyuser'),
      NewMemberRoleOptions: this.envService.CompanyRoles,
      Type: 'company'
    };
    this.memberListOptions = options;
  }

  public updateMemberRole(event: { email: string, newRole: string; }) {
    this.memberListOptions.CurrentMembers.find(x => x.Email === event.email).MemberRole = this.envService.getCompanyRole(event.newRole);
    this.company.Employees.find(x => x.Email === event.email).CompanyRole = this.envService.getCompanyRole(event.newRole).Value;
    this.save().then(() => {
      this.status.setMessage('Successfully updated user role', 'Success', true);
    });
  }

  public changeView(view: CompanyModalViewTypes) {
    this.view = view;
    this.cd.detectChanges();
  }

  public saveInvitation() {
    if (this.employeesList && this.employeesList.invitesToSendOnSave.length) {
      this.employeesList.sendQueuedInvites()
        .catch(() => { /* Do nothing */ })
        .finally(() => {
          this.save()
            .then(() => {
              this.status.setMessage('Successfully sent invitation', 'Success', true);
            })
            .finally(() => {
              this.memberService.isLoading = false;
              setTimeout(() => {
                this.employeesList.sortMembers();
              }, 0);
            });
        });
    }
  }

  public save() {
    this.pending = true;
    return this.companyService.updateCompany(this.company)
      .then((updateCompany) => {
        this.company = updateCompany;
        if (this.userCanInvite) {
          this.companyService.get2FAInfoForCompany(this.company).finally(() => this.setup());
        } else {
          this.setup();
        }
        this.status.setMessage('Successfully saved company settings', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
      }).finally(() => {
        this.pending = false;
        this.setSaved();
      });
  }

}
