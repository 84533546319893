import { Component } from '@angular/core';
import { AlphaVantageModalBackAction } from '@core/actions/navigation.actions';
import {
  AlphaVantageCryptoCurrencies,
  AlphaVantageCryptoPeriodicities,
  AlphaVantageCryptoPeriodicitiesList,
  AlphaVantageCurrencies,
  AlphaVantageEntry,
  AlphaVantageFromToKey,
  AlpvaVantageValueTypes
} from '@core/constants/alphavantage.constants';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AlphaVantageService } from '@core/store/providers/alphavantage/alphavantage.service';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { ProviderService } from '@core/store/providers/provider.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { LanguageService } from '@modules/lang/language.service';
import { Store } from '@ngxs/store';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OpenModifyAPIKeyModal } from '../../modify-api-key/modify-api-key.actions';
import { AlphaVantageUtils } from '../alphavantage.utils';
import { OpenAlphaCryptoModal } from './alpha-crypto-modal.actions';
import { AlphaCryptoModalOpts } from './alpha-crypto-modal.options';


@Component({
  selector: 'indicio-alpha-crypto-modal',
  templateUrl: './alpha-crypto-modal.component.html'
})
export class AlphaCryptoModalComponent extends ModalModelComponent {

  // Entities
  private forecastVersion: ForecastVersionModel;
  public variable = new RemoteDataRequestModel();

  // Currency selection
  public cryptoCurrencies: AlphaVantageEntry[] = AlphaVantageCryptoCurrencies;
  public currencies: AlphaVantageEntry[] = AlphaVantageCurrencies;
  public from: AlphaVantageEntry;
  public to: AlphaVantageEntry;

  // Periodicity selection
  public periodicities = AlphaVantageCryptoPeriodicitiesList;
  public periodicity: AlphaVantageCryptoPeriodicities = AlphaVantageCryptoPeriodicities.DIGITAL_CURRENCY_DAILY;

  // Value type selection (min, open, max, etc)
  public valueTypes = AlpvaVantageValueTypes;

  // Frontend flags
  public nameConflict: boolean;

  constructor(
    public store: Store,
    public envService: EnvironmentService,
    public alphaService: AlphaVantageService,
    public providerService: ProviderService,
    private langService: LanguageService,
    private clientService: ClientFrontendService,
    private statusService: StatusService,
    private forecastVariableService: ForecastVariableFrontendService,
    private sourcevariableService: SourceVariableFrontendService
  ) {
    super();
  }

  public back() {
    this.close();
    this.store.dispatch(new AlphaVantageModalBackAction(this.forecastVersion));
  }

  public setOptions(options: AlphaCryptoModalOpts) {
    this.initApiKey();
    this.forecastVersion = options.forecastVersion;
    this.variable.ValueType = 'close';
    this.isLoading = false;
  }

  initApiKey() {
    const source = this.clientService.client.findDataSource('alphavantage', true);
    if (!source || !source.ApiKey) {
      setTimeout(() => {
        this.close();
        this.store.dispatch(new OpenModifyAPIKeyModal(
          this.envService.getSourceType(DataProvider.alphavantage),
          this.langService.sourceTypes.find(x => x.Value === 'alphavantage')?.Extra ?? 'Contact support.',
          null,
          _key => {
            this.store.dispatch(new OpenAlphaCryptoModal(this.forecastVersion));
          }, () => { },
          new RegExp('^[0-9A-Z]{16}$')));
      }, 0);

      return;
    } else {
      this.alphaService.setAPIKey(source.ApiKey);
    }
  }

  generateName(fromOrTo: AlphaVantageEntry, key: AlphaVantageFromToKey) {
    if (key === 'from') { this.from = fromOrTo; }
    if (key === 'to') { this.to = fromOrTo; }
    this.variable.Name = AlphaVantageUtils.generateName(this.from, this.to, 'Crypto currency');
    this.checkName();
  }

  public checkName() {
    this.nameConflict = this.sourcevariableService.isNameConflict(this.variable.Name);
  }

  public async importData() {
    this.pending = true;
    try {
      const data = await this.alphaService.getCryptoCurrencyData(this.periodicity, this.from.code, this.to.code);

      if (!data.hasOwnProperty('Meta Data')) {
        throw new Error('Error fetching data, try again or correct your request.');
      }

      this.variable.Provider = DataProvider.alphavantage;
      this.variable.RemoteReferenceId = `${this.from.code.toUpperCase()}/${this.to.code.toUpperCase()}`;
      this.variable.Data = data;
      this.variable.RemoteUri = this.alphaService.currentUri;

      const sourcevariable = await this.providerService.addVariableFromDatasource(this.clientService.activeCompany.CompanyId, this.variable);
      this.statusService.setMessage('Variable created successfully!', 'Success');
      this.close();
      this.forecastVariableService.checkErrorsAndCreateForecastVariable(sourcevariable, this.forecastVersion)
        .catch(_err => {
          this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [sourcevariable.SourceVariableId]));
        });
    } catch (e) {
      if (e.hasOwnProperty('stack')) {
        e = { error: e.message };
      }
      this.statusService.setError(e, true);
      this.pending = false;
    }
  }
}


