import { Def } from '@core/decorators/def';

export class UpdateForecastVersionDTO {
  @Def() Name: string;
  @Def() StartDate: string;
  @Def() DataStartDate: string;
  @Def() StepChosen: number;
  @Def() Horizon: number;
  @Def() EnableMAPE: boolean;
  @Def() EnableShap: boolean;
  @Def() EnableMFM: boolean;
  @Def() Alpha: number;

  @Def() MaxLag: number;
  @Def() ModelThresholdMAPE: number;
  @Def() CalculateOutliers: boolean;
  @Def() CalculateSeasonal: boolean;
  @Def() AutoUpdateVariables: boolean;
  @Def() IsLatest: boolean;
  @Def() UseModelRollingWindow: boolean;
  @Def() ModelRollingWindowRecalc: number;
  @Def() TestStartDate: string;
  @Def() OOSEnabled: boolean;

  @Def() ModelScaling: string;
}
