import { Component } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyModel } from '@core/store/company/company.model';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectMemberModel } from '@core/store/project/project-member.model';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { TransferProjectModalOpts } from './transfer-project-modal.options';


@Component({
  selector: 'indicio-project-transfer-modal',
  templateUrl: './transfer-project-modal.component.html'
})
export class TransferProjectModalComponent extends ModalModelComponent {

  // Input properties
  project: ProjectModel = null;
  company: CompanyModel = null;
  members: ProjectMemberModel[];
  back?: any;

  index: number = null;
  canUserEdit = false;
  transferTo: string;
  leaveAfter: boolean = false;

  isPending = false;

  constructor(
    protected store: Store,
    private status: StatusService,
    public envService: EnvironmentService,
    private projectService: ProjectFrontendService,
    private clientService: ClientFrontendService,
    private profileService: ProfileFrontendService
  ) {
    super();
  }

  public setOptions(options: TransferProjectModalOpts) {
    this.company = this.clientService.activeCompany;
    this.project = options.project;
    this.back = options.back;
    this.members = this.project.Members.filter(m => m.Email !== this.profileService.profile.Email);
    this.leaveAfter = options.leaveAfterTransfer;
    this.isLoading = false;
  }

  public tranferOwnership() {
    this.projectService.transferOwnership(this.project, this.transferTo)
      .then(() => {
        if (this.leaveAfter) {
          this.projectService.leave(this.project.CompanyId, this.project.ProjectId)
            .then(() => {
              this.status.setMessage('Successfully left project', 'Success', false);
              this.close();
            });
        } else {
          this.status.setMessage('Transfer Ok', 'Success', false);
          this.close();
        }
      })
      .catch(error => {
        this.status.setError(error);
      });
  }
}
