import { VarSelectResultModel } from '@core/store/var-select/var-select-result.model';
import { VarSelectSettingsDTO } from '../forecast/dtos/var-select-settings/var-select-settings-dto';

export namespace VarSelectActions {
  export class GetSuccess {
    public static readonly type = '[VAR SELECT] Get variable select result success';
    constructor(public varSelects: VarSelectResultModel[]) { }
  }

  export class Trigger {
    public static readonly type = '[VAR SELECT] Trigger variable select';
    constructor(public forecastVersionId: string) { }
  }

  export class TriggerSuccess {
    public static readonly type = '[VAR SELECT] Trigger variable select success';
    constructor(public forecastVersionId: string) { }
  }

  export class TriggerFailed {
    public static readonly type = '[VAR SELECT] Trigger variable select failed';
    constructor(public error: any) { }
  }

  export class RemoveResult {
    public static readonly type = '[VAR SELECT] Remove variable select result';
    constructor(public forecastVersionId: string) { }
  }

  export class SetResultOnVariables {
    public static readonly type = '[VAR SELECT] Set variable select models on variables';
    constructor(public results: VarSelectResultModel[]) { }
  }

  export class PostSetResultOnVariables {
    public static readonly type = '[VAR SELECT] Action triggered after SetResultOnVariables';
    constructor(public fVersionId: string) { }
  }

  export class UpdatedSettingsSucces {
    public static readonly type = '[VAR SELECT] Update variable select settings in forecast';
    constructor(public forecastId: string, public forecastVersionId: string = null, public settings: VarSelectSettingsDTO) { }
  }
}
