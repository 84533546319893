<indicio-dialog class="syncable-variables-dialog">
  <h2 mat-dialog-title>{{ title }}
    <div class="crop-string subtitle"
         [title]="opts.ObjectName"
         *ngIf="initialized">
      <span class="capitalize">{{ opts.type }}</span>: {{ opts.ObjectName }}
    </div>
  </h2>
  <mat-dialog-content>
    <p>
      This will fetch the latest version for the listed variables from their respective data provider(s).<br>
      Affected forecast variables will then be updated with the new data.
    </p>

    <ng-container *ngIf="initialized; else loader">
      <ng-container *ngIf="!!data?.length"
                    [ngTemplateOutlet]="syncInfo">
      </ng-container>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #loader>
  <div class="center-horizontal-and-vertical"
       style="height: 100px;">
    <indicio-spinner [size]="24">
    </indicio-spinner>
    &nbsp; Loading information...
  </div>

</ng-template>

<ng-template #syncInfo>
  <indicio-data-table columnTemplate="50px 1fr 120px 120px">
    <div title-row>
      <div column>
        <span title>#</span>
      </div>
      <div column>
        <span title>Name</span>
      </div>
      <div column>
        <span title>Source</span>
      </div>
      <div column>
        <span title>Status</span>
      </div>
    </div>
    <div style="max-height: 360px; overflow: auto;">
      <ng-container *ngFor="let item of data">
        <div row
             style="--column-template: 1fr 120px; font-weight: 500;">
          <div column>
            <span>Forecast: {{ item.ForecastName }}</span>
          </div>
          <div column
               center>
            <span>Variables: {{ item.numComplete }}/{{ item.Variables.length }}</span>
          </div>
        </div>
        <div row
             *ngFor="let fVar of item.Variables; let i = index">
          <div column
               center>
            <span>#{{ i + 1 }}</span>
          </div>
          <div column
               class="crop-string">
            <span>{{ fVar.VariableName }}</span>
          </div>
          <div column
               class="crop-string"
               [indicioTippy]="fVar.sourceType.Description">
            <span>{{ fVar.sourceType.Display }}</span>
          </div>
          <div class="status-column"
               column
               center
               [indicioTippy]="fVar.errorMessage">
            <span [attr.data-status]="fVar.status">{{ fVar.status || 'Pending' }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </indicio-data-table>
  <div class="grid-system"
       style="--grid-template: 1fr 220px; margin-top: 20px;">
    <div>
      <span *ngIf="overviewStatus?.Status === 'Error'"
            class="italic red">
        {{ overviewStatus?.Message }}
      </span>
    </div>
    <div class="text-right"
         style="font-weight: 400; font-size: 16px;">
      <span *ngIf="!inProgress && !done">{{ count }} variable(s) to update</span>
      <span *ngIf="inProgress || done">{{ synced }} / {{ count }} variables updated</span>
    </div>
  </div>

</ng-template>

<ng-template #actionsTemplate>
  <button mat-stroked-button
          type="button"
          (click)="close()">Close</button>
  <button mat-raised-button
          *ngIf="!done"
          (click)="synchronizeData()"
          [disabled]="syncLoading"
          color="primary">
    <div class="center-horizontal-and-vertical">
      <indicio-spinner *ngIf="syncLoading"></indicio-spinner>
      &nbsp; <span>{{syncLoading ? 'Updating' : 'Update data' }}</span>
    </div>
  </button>
</ng-template>
