<div class="indicio-datatable"
     (cdkObserveContent)="setup()"
     [style]="styleString">
  <div class="indicio-datatable__header">
    <ng-content select="[title-row]"></ng-content>
  </div>
  <div class="indicio-datatable__body">
    <ng-content></ng-content>
  </div>
</div>
