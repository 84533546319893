import { Def } from '@core/decorators/def';
import { HistoricValueDTO } from '@core/entities/dtos/plot-value-dto';
import { ForecastResultDTO } from '@core/store/forecast-result/entities/forecast-result-dto';
import { ReportTemplateType } from '@modules/lang/language-files/reports';

export class ReportSettingsDTO {
  SummaryMethod: string;
}
export class CreateReportEntryDTO {
  ForecastId: string;
  Name: string;
}

export class CreateReportDTO {
  CompanyId: string;
  Name: string;
  Template: ReportTemplateType;
  ForecastIds: string[];
  StartDate: string;
}

export class ReportDTO extends CreateReportDTO {
  CreatorEmail: string;
  CreatedDate: string;
  ModifiedDate: string;
  Entries: ReportEntryDTO[];
  Settings: ReportSettingsDTO;
}

export class ReportEntryDTO {
  ReportId: string;
  ForecastId: string;
  ForecastVersionId: string;
  SelectedResultId: string;
  SourceVariableId: string;
  Periodicity: string;
  ForecastStartDate: string;
  LastForecastedDate: string;
  SourceVariableName: string;
  Name: string;
  Tags: string[];
  HistoricValues: HistoricValueDTO[];
  ForecastVariableTransform: string;
  IsPercent: boolean;
  ForecastResults: ForecastResultDTO[];
}

export class ReportMetaDTO extends CreateReportDTO {
  ReportId: string;
}

export class UpdateReportDTO {
  @Def() ReportId: string;
  @Def() Name: string;
  @Def() Template: string;
  @Def() NameDisplay: string;
  @Def() SelectedTagId: string;
  @Def() StartDate: string;
}
