import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { INavTreeNode } from './entities/nav-tree.entities';


@Component({
  selector: 'indicio-nav-tree',
  templateUrl: './nav-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavTreeComponent {

  @Input() activeNode: INavTreeNode = null;
  @Input() public loadingContent: boolean;
  @Input() public treeNodes: INavTreeNode[];

  @Output() getListingEvent = new EventEmitter();
  @Output() activeNodeEvent = new EventEmitter<INavTreeNode>();

  public shouldBeVisible(node: INavTreeNode) {
    return node?.shouldBeVisible();
  }

  private inActive(nodes: INavTreeNode[]) {
    nodes.forEach(n => {
      n.isActive = false;
      if (n.children?.length) {
        this.inActive(n.children);
      }
    });
  }

  public clickedNode(node: INavTreeNode) {
    if (!!this.activeNode) { this.activeNode.isActive = false; }
    if (node == null || !!!this.treeNodes?.length) { return; }

    this.inActive(this.treeNodes.filter(x => !!x));
    this.activeNode = node;
    node.isActive = true;
    this.activeNodeEvent.emit(node);
    if (node.foldOnClick()) {
      this.clickedToggleFold(node);
    }
  }

  public clickedToggleFold(node: INavTreeNode) {
    node.isOpen = !node.isOpen;
  }

  public isActive(node: INavTreeNode) {
    return node && node.isActive;
  }
}
