import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { ALGTypes } from '@shared/components/line-graph/alg-types';
import * as moment from 'moment';

export class AssessmentUtils {

  public static filterByType(assessments: AssessmentModel[], transformation: ALGTypes.Transform) {
    if (!assessments?.length) { return []; }
    const isRoc = transformation === ALGTypes.Transform.roc;
    const isRocY = transformation === ALGTypes.Transform.rocy;
    const isRoCType = isRoc || isRocY;
    return assessments.filter(a => a.Active
      && (a.Unit === (isRoc ? 'roc' : isRocY ? 'roc-yy' : 'percent')
        || a.Unit === (!isRoCType ? 'nominal' : '')
        || a.Unit === (!isRoCType ? 'absolute' : '')));
  }

  public static CalculateCenterOfMass(assessments: AssessmentModel[]) {
    let totalMass = 0;
    let totalWeight = 0;
    assessments.forEach(ass => {
      totalMass += ass.Weight * ass.NewValue;
      totalWeight += ass.Weight;
    });
    return totalMass / totalWeight;
  }

  public static GetAssessmentGroups(toUse: AssessmentModel[]) {
    const result: { moment: moment.Moment, items: AssessmentModel[], newValue; }[] = [];
    const len = toUse.length;
    for (let i = 0; i < len; i++) {
      const m: moment.Moment = toUse[i].moment;
      const idx = result.findIndex(x => m.isSame(x.moment, 'day'));
      if (idx === -1) {
        result.push({ moment: m, items: [toUse[i]], newValue: undefined });
      } else {
        result[idx].items.push(toUse[i]);
      }
    }
    result.forEach(assessmentGroup => {
      const weightedValue = AssessmentUtils.CalculateCenterOfMass(assessmentGroup.items);
      assessmentGroup.newValue = weightedValue;
      assessmentGroup.moment = assessmentGroup.items[0].moment;
    });
    return result;
  }
}
