<div class="fcast-info-version-tab">

  <mat-accordion>
    <!-- Stationarity-->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Settings
        </mat-panel-title>
        <mat-panel-description>
          Settings for this version
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="settingsTemplate"></ng-container>
    </mat-expansion-panel>
    <!-- Stationarity-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Versions
        </mat-panel-title>
        <mat-panel-description>
          All versions of this forecast
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container [ngTemplateOutlet]="versionsTemplate"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #settingsTemplate>
  <indicio-modal-option-row [disabled]="!canUpdateForecast"
                            optionTitle="Filter models by MAPE"
                            optionSubtitle="MAPE filtering is {{ newSettings.EnableMAPE ? 'active' : 'disabled' }}">
    <div class="text-right"
         style="margin-top: 25px;">
      <mat-slide-toggle [disabled]="!canUpdateForecast"
                        [checked]="newSettings.EnableMAPE"
                        (change)="toggleEnableMape()">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>

  <indicio-modal-option-row [disabled]="!canUpdateForecast"
                            optionTitle="Enable SHAP"
                            optionSubtitle="Shapley additive explanations are {{ newSettings.EnableShap ? 'enabled' : 'disabled'}}">
    <div class="text-right"
         style="margin-top: 25px;">
      <mat-slide-toggle [disabled]="!canUpdateForecast"
                        [checked]="newSettings.EnableShap"
                        (change)="toggleEnableShap()">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>

  <indicio-modal-option-row [disabled]="!canUpdateForecast"
                            optionTitle="Mixed frequency support"
                            optionSubtitle="Support for mixed frequency models is {{ newSettings.EnableMFM ? 'enabled' : 'disabled' }}">
    <div class="text-right"
         style="margin-top: 25px;">
      <mat-slide-toggle [disabled]="!canUpdateForecast"
                        [checked]="newSettings.EnableMFM"
                        (change)="toggleEnableMFM()">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>

  <indicio-modal-option-row [disabled]="!canUpdateForecast || !newSettings.EnableMAPE"
                            optionTitle="MAPE threshold"
                            optionSubtitle="Maximum mean average percentage error (MAPE) allowed for the models">
    <div class="grid-system"
         style="--grid-template: 45px 1fr; margin-top:25px; align-items: center; padding-right: 20px;">
      <div>{{ newSettings.ModelThresholdMAPE }}%</div>
      <div class="text-right"
           [indicioTippy]="'MAPE threshold: ' + newSettings.ModelThresholdMAPE">
        <mat-slider [disabled]="!canUpdateForecast || !newSettings.EnableMAPE"
                    [max]="50"
                    [min]="5"
                    [step]="5"
                    [discrete]="false"
                    [showTickMarks]="false">
          <input matSliderThumb
                 [ngModel]="newSettings.ModelThresholdMAPE"
                 (ngModelChange)="newSettings.ModelThresholdMAPE = $event">
        </mat-slider>
      </div>
    </div>
  </indicio-modal-option-row>

  <!--Statistical settings-->
  <indicio-modal-option-row [disabled]="!canUpdateForecast"
                            optionTitle="Significance level"
                            optionSubtitle="The significance level, usually denoted by &alpha;, is used to filter models">
    <div class="grid-system"
         style="--grid-template: 45px 1fr; margin-top:25px; align-items: center; padding-right: 20px;">
      <div>{{ newSettings.Alpha }}</div>
      <div class="text-right"
           [indicioTippy]="'Significance level: ' + alphaOptions[newAlphaIndex]">
        <mat-slider [disabled]="!canUpdateForecast"
                    [min]="0"
                    [max]="2"
                    [step]="1"
                    [disabled]="!canUpdateForecast"
                    [discrete]="false"
                    [showTickMarks]="false">
          <input matSliderThumb
                 [ngModel]="newAlphaIndex"
                 (ngModelChange)="setAlpha($event)">
        </mat-slider>
      </div>
    </div>
  </indicio-modal-option-row>

  <indicio-modal-option-row [disabled]="!canUpdateForecast"
                            optionTitle="Maximum lag"
                            optionSubtitle="The maximum lag that the models are allowed to use">
    <div class="grid-system"
         style="--grid-template: 45px 1fr; margin-top:25px; align-items: center; padding-right: 20px;">
      <div>{{ newSettings.MaxLag }}</div>
      <div class="text-right"
           [indicioTippy]="'Maximum lag: ' + newSettings.MaxLag">
        <mat-slider [min]="1"
                    [max]="12"
                    [step]="1"
                    [disabled]="!canUpdateForecast"
                    [discrete]="false"
                    [showTickMarks]="false"
                    title="Max lag recommendation: {{ forecastVersion.Frequency }}">
          <input matSliderThumb
                 [ngModel]="newSettings.MaxLag"
                 (ngModelChange)="newSettings.MaxLag = $event">
        </mat-slider>
      </div>
    </div>
  </indicio-modal-option-row>

  <!-- Save button -->
  <div class="pull-right"
       style="width:fit-content; margin-top: 25px;">
    <button *ngIf="canUpdateForecast"
            mat-raised-button
            type="button"
            color="primary"
            [disabled]="!changed"
            (click)="save()">
      <span *ngIf="!pending">Save changes</span>
      <indicio-spinner *ngIf="pending"></indicio-spinner>
    </button>
  </div>
</ng-template>

<ng-template #versionsTemplate>
  <indicio-data-table columnTemplate="50px 1fr 100px">
    <div title-row>
      <div column>
        <span title>#</span>
      </div>
      <div column>
        <span title>Name</span>
      </div>
      <div column>
        <span title>Actions</span>
      </div>
    </div>

    <div style="max-height: 200px; overflow: auto;">
      <div row
           *ngFor="let version of forecast.ForecastVersions | orderBy: '-VersionNumber'; let i = index">
        <div column
             center>
          <span>#{{ version.VersionNumber }}</span>
        </div>
        <div column>
          <div style="display: grid; grid-template-columns: 1fr 85px;">
            <div>
              <span>Version {{ version.VersionNumber }}</span>
              <span class="italic"
                    *ngIf="forecast.activeVersionId === version.ForecastVersionId">&nbsp;- active</span>
            </div>
            <div class="text-right"
                 *ngIf="forecast.activeVersionId !== version.ForecastVersionId">
              <span class="dotted-underline pointer"
                    (click)="newActiveVersion(version)">set as active</span>
            </div>
          </div>
        </div>
        <div column
             center>
          <i class="ion-trash-b red cursor-pointer"
             indicioTippy="Remove this version"
             *ngIf="canUpdateForecast && version.IsLatest && version.VersionNumber !== 1"
             (click)="deleteForecastVersion(version)"></i>
        </div>
      </div>


    </div>
  </indicio-data-table>

  <div style="background-color: rgb(222 192 101); padding: 7px; margin: 20px 0; color: black"
       *ngIf="canUpdateForecast">
    Note: You can only remove the latest version, but you cannot remove version 1.
  </div>
</ng-template>
