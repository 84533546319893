import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { StatusService } from '@core/services/status/status.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { SyncOverviewDTO } from '@core/store/source-variable/dtos/sync-state.dto';
import { SourceVariableActions } from '@core/store/source-variable/source-variable.actions';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { VariableDialogService } from '@dialogs/variables/variable-dialogs.service';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { Syncable } from './syncable-variables.entities';

export class SyncableVariablesDialogData {
  type: Syncable.TargetTypes;
  ObjectId: string;
  ObjectName: string;
}

@Component({
  selector: 'indicio-syncable-variables-dialog',
  templateUrl: './syncable-variables.dialog.html',
  styleUrls: ['./syncable-variables.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class SyncableVariablesDialogComponent extends DialogV2BaseDialog<SyncableVariablesDialogComponent> implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();


  // This dialog asks the user for an email and lets the user ask for a reset-password link.
  public static Id: string = 'SyncableVariablesDialogComponent';

  public overviewStatus: SyncOverviewDTO = null;
  public data: Syncable.DataInfoDTO[];
  public title: string;
  public opts: SyncableVariablesDialogData;

  // Frontend flags
  public syncLoading: boolean = false;
  public done: boolean = false;

  // Getters
  public get count() { return this.data.reduce((a, b) => a + b.Variables.length, 0); }
  public get synced() { return this.data.reduce((a, b) => a + b.Variables.filter(v => v.status === 'Updated').length, 0); }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: SyncableVariablesDialogData,
    dialogRef: MatDialogRef<SyncableVariablesDialogComponent>,
    private actions: ActionService,
    private companyService: CompanyFrontendService,
    private sourceService: SourceVariableFrontendService,
    private dialogService: VariableDialogService,
    private store: Store,
    private status: StatusService,
  ) {
    super(dialogRef);
    this.opts = data;
  }

  public ngOnInit() {
    this.initialize();
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public close() {
    this.dialogRef.close();
  }

  protected initialize() {
    this.setTitle();
    this.setupSubscriptions();
    this.syncInfo()
      .catch(err => this.status.setError(err))
      .finally(() => this.initialized = true);
  }

  public nextStep(autoStartNext: boolean) {
    this.close();
    this.dialogService.openFVarUpdateDialog({ ...this.opts, AutoStart: autoStartNext });
  }

  public synchronizeData() {
    if (this.done) { return this.close(); }
    this.syncLoading = true;
    const fcastIds = this.data.map(f => f.ForecastId);
    this.sourceService.syncVariablesFromRemote(this.companyService.activeCompanyId, fcastIds)
      .then(() => {
        this.status.setMessage('Synchronization started', 'Success', true);
        this.inProgress = true;
      })
      .catch(err => {
        this.status.setError(err);
        this.syncLoading = false;
      });
  }

  private async syncInfo() {
    return this.companyService.getSyncableVariableInfos(this.opts.type, this.opts.ObjectId)
      .then(infos => {
        this.data = infos;
        if (!this.data.length) {
          this.nextStep(false);
        }
        this.data.forEach(info => {
          info.Variables = [...info.Variables.filter(x => x.IsIndicator), ...info.Variables.filter(x => !x.IsIndicator)];
          info.numComplete = 0;
          info.Variables.forEach(v => v.errorMessage = null);
        });
      });
  }

  private setTitle() {
    switch (this.opts.type) {
      case 'forecast':
        this.title = 'Update source variables used in forecast';
        break;
      case 'project':
        this.title = 'Update source variables used in project';
        break;
      case 'hierarchy':
        this.title = 'Update source variables used in hierarchy';
        break;
    }
  }

  private handleEvent(evt: SourceVariableActions.RemoteBulkSync) {
    for (let id of evt.dto.FVarIds) {
      const targetForecast = this.data.find(fcast => fcast.Variables.some(x => x.ForecastVariableId === id));
      if (targetForecast == null) { continue; }

      if (evt.dto.Status === 'Updated')
        targetForecast.numComplete++;

      const targetVariable = targetForecast.Variables.find(x => x.ForecastVariableId === id);
      if (targetVariable == null) { continue; }

      targetVariable.status = evt.dto.Status;
      targetVariable.errorMessage = evt.dto.Status === 'Error' ? evt.dto.Message : null;
    }
  }

  private handleOverview(evt: SourceVariableActions.RemoteBulkSyncOverview) {
    this.overviewStatus = evt.dto;
    if (evt.dto.Status === 'Error' || evt.dto.Status === 'Updated') {
      this.done = true;
      this.nextStep(true);
    }
  }

  private setupSubscriptions() {
    this.sub.add(this.actions.dispatched(SourceVariableActions.RemoteBulkSync).subscribe((evt: SourceVariableActions.RemoteBulkSync) => {
      this.handleEvent(evt);
    }));
    this.sub.add(this.actions.dispatched(SourceVariableActions.RemoteBulkSyncOverview).subscribe((evt: SourceVariableActions.RemoteBulkSyncOverview) => {
      this.handleOverview(evt);
    }));
  }
}
