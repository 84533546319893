import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { ForecastTemplateFrontendService } from '@core/store/forecast-template/forecast-template.frontend.service';
import { ForecastTemplate, ForecastTemplateTagDTO } from '@core/store/forecast-template/forecast-template.model';

export interface EditForecastTemplateDialogData {
  Template: ForecastTemplate;
}

@Component({
  selector: 'indicio-edit-forecast-template-dialog',
  styleUrls: ['edit-forecast-template.dialog.less'],
  templateUrl: 'edit-forecast-template.dialog.html',
})
export class EditForecastTemplateDialogComponent {
  public static Id: string = 'EditForecastTemplateDialogComponent';

  public template: ForecastTemplate = null;
  public usedProviders: string[] = [];

  public selectedTags: ForecastTemplateTagDTO[] = [];

  // Flags
  public loader = false;
  public initialized = false;

  constructor(
    public dialogRef: MatDialogRef<EditForecastTemplateDialogComponent>,
    public templateService: ForecastTemplateFrontendService,
    private statusService: StatusService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: EditForecastTemplateDialogData
  ) {
    this.template = data.Template;
    this.selectedTags = this.template.Tags.slice();
    this.templateService.getTags().then(() => this.initialized = true);
  }

  public onSelectTag($event: ForecastTemplateTagDTO[]) {
    this.selectedTags = $event;
  }

  public createNewTag() {
    this.templateService.createNewTag()
      .then(newTag => {
        if (!newTag) { return; }
        this.selectedTags.push(newTag);
        this.cd.detectChanges();
      });
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public async save() {
    this.template.Tags = this.selectedTags;
    const updated = await this.templateService.update(this.template);
    this.template = updated;
    this.dialogRef.close(updated);
    this.statusService.setMessage('Template updated successfully', 'Success');
  }
}
