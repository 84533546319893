<svg [class]="size === 'small' ? 'checkmark-small' : 'checkmark'"
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 52 52"
     [ngClass]="color ? getClassColor('checkmark') : null">
        <circle class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
                [ngClass]="color ? getClassColor('checkmark__circle') : null" />
        <path class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8" />
</svg>
