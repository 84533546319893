<indicio-modal-base [child]="this"
                    modalTitle="{{ title }}"
                    modalExtraClass="small"
                    extraClasses="small">

  <div class="row">
    <div class="col-xs-12">
      <p [innerHTML]="message"></p>
      <button data-test-id="decline-delete-btn"
              [ngClass]="negativeEffect ? 'outline blue' : 'outline red' "
              class="btn "
              *ngIf="decline"
              (click)="declineFn()">
        {{ decline }}
      </button>
      <button data-test-id="confirm-delete-btn"
              *ngIf="!disableYes"
              [ngClass]="!negativeEffect ? '' : 'important' "
              class="btn right"
              (click)="confirmFn()">
        {{ confirm }}
      </button>
      <button data-test-id="close-delete-popup-btn"
              *ngIf="disableYes"
              class="btn right"
              (click)="close();">
        OK
      </button>
    </div>
  </div>
</indicio-modal-base>
