import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(
    private http: HttpClient
  ) { }


  public getFrontendVersion() {
    return this.http.get<{ PackageVersion: string; }>('/assets/system.json', {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    }).toPromise();
  }
}
