import { DisplayValue } from '../types/display-value';

export enum EAutoTaskState {
  NOT_SET = 0,
  STARTING = 1,
  FAILED = 2,
  SUCCESS = 3,
  RUNNING = 4,
  QUEUED = 5
}

export enum EAutoTaskTriggerType {
  'by-schedule' = 'by-schedule',
  'by-event' = 'by-event'
}

export enum EAutoTaskByEventType {
  'on-file-updated' = 'on-file-updated',
  'on-source-updated' = 'on-source-updated',
  'on-new-result' = 'on-new-result'
}

export enum EAutoTaskSendMailTrigger {
  'on-new-result' = 'on-new-result',
  'on-result-change-above-5%' = 'on-result-change-above-5%',
  'always' = 'always'
}

export type AutoTaskStepType = 'update-variables' | 'calc-results' | 'save-result' | 'use-result' | 'send-result';
export type AutoTaskTriggerOnType = 'first' | 'second' | 'third' | 'fourth' | 'last' | 'date';
export type AutoTaskByEventType = keyof typeof EAutoTaskByEventType;
export type AutoTaskTriggerType = keyof typeof EAutoTaskTriggerType;
export type AutoTaskSendMailTriggerType = keyof typeof EAutoTaskSendMailTrigger;

export class AutoTaskTrigger extends DisplayValue<AutoTaskTriggerType> { };
export const AutoTaskTriggerTypes = [
  new AutoTaskTrigger('by-schedule', 'By schedule', 'The task is triggered by a schedule.'),
  new AutoTaskTrigger('by-event', 'By event', 'The task is triggered by an event.')
];

export class AutoTaskSendEmailTrigger extends DisplayValue<AutoTaskSendMailTriggerType> { };
export const AutoTaskSendEmailTriggerTypes = [
  new AutoTaskSendEmailTrigger('on-new-result', 'On new result', 'The email will be sent when there is a new result.'),
  new AutoTaskSendEmailTrigger('on-result-change-above-5%', 'On result change above 5%', 'The email will be sent when the change is above 5%.'),
  new AutoTaskSendEmailTrigger('always', 'Always', 'The email will always be sent.')
];

export class AutoTaskTriggerOnEvent extends DisplayValue<AutoTaskByEventType> { };
export const AutoTaskTriggerOnEventTypes = [
  new AutoTaskTriggerOnEvent('on-file-updated', 'On file updated', 'The task is triggered when a file is updated.'),
  new AutoTaskTriggerOnEvent('on-source-updated', 'On source variable updated', 'The task is triggered when a source variable is updated.'),
  new AutoTaskTriggerOnEvent('on-new-result', 'On new result', 'The task is triggered when a new result is available.')
];

export class AutoTaskTriggerOn extends DisplayValue<AutoTaskTriggerOnType> { };
export const AutoTaskTriggerOnTypes = [
  new AutoTaskTriggerOn('first', 'First', 'The first occurrence'),
  new AutoTaskTriggerOn('second', 'Second', 'The second occurrence'),
  new AutoTaskTriggerOn('third', 'Third', 'The third occurrence'),
  new AutoTaskTriggerOn('fourth', 'Fourth', 'The fourth occurrence'),
  new AutoTaskTriggerOn('last', 'Last', 'The last occurrence'),
  new AutoTaskTriggerOn('date', 'Date', 'The date occurrence')
];

export class AutoTaskStep extends DisplayValue<AutoTaskStepType> { };
export const AutoTaskStepTypes = [
  new AutoTaskStep('update-variables', 'Update forecast', 'Update the data used in a forecast and calculate new uni- and multivariate models if needed.'),
  new AutoTaskStep('calc-results', 'Calculate forecast', 'Calculate univariate and multivariate results, and indicator analysis if needed'),
  new AutoTaskStep('save-result', 'Save forecast result', 'Save a new forecast result, for use in another forecast or for analytics'),
  new AutoTaskStep('use-result', 'Use result', 'Use a previously saved result in a forecast'),
  new AutoTaskStep('send-result', 'Send result', 'Send an email containing selected information about a forecast')
];


