import { Injectable } from '@angular/core';
import { CloseMainMenuAction } from '@core/actions/navigation.actions';
import {
  AuthActions, ClearStateAction
} from '@core/store/auth/auth.actions';
import { ClientState } from '@core/store/client/client.state';
import { IndicioPermissionType } from '@core/types/indicio-permission.type';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClientModel } from '../client/client.model';
import { AuthToken } from './auth-token';


export interface AuthStateModel {
  token: AuthToken;
}
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null
  }
})
@Injectable()
export class AuthState {

  @Selector()
  static token(state: AuthStateModel) { return state.token; }

  @Selector()
  static isSysAdmin(state: AuthStateModel) {
    if (!state.token) { return false; }
    if (!state.token.sys_admin) { return false; }
    return state.token.sys_admin === true;
  }

  @Selector()
  static isSupport(state: AuthStateModel) {
    if (!state.token) { return false; }
    return state.token.sysrole === 'syssales' || state.token.sysrole === 'syssupport';
  }

  @Selector()
  static isSales(state: AuthStateModel) {
    if (!state.token) { return false; }
    return state.token.sysrole === 'syssales';
  }

  @Selector()
  static mfa_authed(state: AuthStateModel) {
    if (!state.token) { return false; }
    return state.token.two_factor_authed;
  }
  @Selector()
  static mfa_enabled(state: AuthStateModel) {
    if (!state.token) { return false; }
    return state.token.two_factor_enabled;
  }
  @Selector()
  static mfa_method(state: AuthStateModel) {
    if (!state.token) { return null; }
    return state.token.two_factor_method;
  }
  @Selector()
  static sessionId(state: AuthStateModel) {
    if (!state.token) { return null; }
    return state.token.session_id;
  }

  @Selector()
  static systemPermissions(state: AuthStateModel) {
    if (!state.token) { return false; }
    return state.token.sysperms as IndicioPermissionType[];
  }

  @Selector([ClientState.client])
  static loggedIn(state: AuthStateModel, client: ClientModel) {
    return !!state.token && !!client;
  }

  constructor() { }

  @Action(AuthActions.StoreToken)
  storeToken(ctx: StateContext<AuthStateModel>, action: AuthActions.StoreToken) {
    ctx.patchState({ token: action.token });
    ctx.dispatch(new AuthActions.StoredToken(action.token));
  }

  @Action(ClearStateAction)
  logoutUser(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ token: null });
    ctx.dispatch(new CloseMainMenuAction());
  }
}

