/**
 * ItemState
 * Save simple data objects and track changes
 * @example
 * const scenario = new ItemState<ScenarioDTO>().set(scenario);
 */
export class ItemState<T> {

  public Item: T;
  public backup: T;

  public get isModified() {
    return JSON.stringify(this.Item) !== JSON.stringify(this.backup);
  }

  public set(item: T) {
    this.Item = item;
    this.backup = JSON.parse(JSON.stringify(item));
    return this;
  }

  public restore() {
    this.Item = JSON.parse(JSON.stringify(this.backup));
  }
}
