import { IndicioConstants } from '@core/constants/indicio.constants';
import { Base64FileDTO } from '../file/dtos/file-dto';
import { WikiConstants } from './wiki.constants';

export class WikiPageMetaDTO {
  PageSize: WikiPageSize;
  Slug: string;
  Title: string;
  Content: string;
  Version: number;
  Published: boolean;
  ParentSlug: string;
  CreatedDate?: Date;
  ModifiedDate?: Date;
  IncludePDF: boolean;
  Order: number;
}

export class CreateWikiPageDTO extends WikiPageMetaDTO { }
export class UpdateWikiPageDTO extends WikiPageMetaDTO { }

export class WikiPageDTO extends WikiPageMetaDTO {
  Parents: WikiParent[];
  PageSize: WikiPageSize;

  public get assetsInContent() {
    return this.Content.match(WikiConstants.AssetRegexp);
  }
}

export class WikiExportDTO {
  Pages: WikiPageDTO[];
  Assets: WikiAssetDTO[];
}

export class WikiPageVersionDTO {
  Version: number;
  CreatedDate: string;
}

export class WikiParent {
  Slug: string;
  Title: string;
}

export class WikiPageSize {
  Width: string;
  Height: string;
}

export class WikiPageAdminMetaDTO {
  Title: string;
  Published: boolean;
  ParentSlug: string;
  Slug: string;
  CreatedDate: Date;
  Versions: number;
  Views: number;
  Order: number;
  IncludePDF: boolean = true;

  cachedPageExists: boolean;
  frontendOrder: string;
}

export class WIKIPDFSettingsDTO {
  Slug: string;
  IncludePDF: boolean;
  Order: number;
}

export class WikiAssetDTO extends Base64FileDTO {
  AssetId: string;
  CreatedDate: Date;
  ModifiedDate: Date;
  Size?: number;

  public get imageString() {
    return `data:${this.MimeType};base64,${this.Base64String}`;
  }
}

export class WikiPDFVersionDTO extends Base64FileDTO {
  WikiPDFVersionId: string;
  License: IndicioConstants.Licenses = IndicioConstants.Licenses.Indicio;
  CreatedDate: Date;
}

export class WikiPDFVersionMetaDTO {
  WikiPDFVersionId: string;
  Name: string;
  License: string;
  Size: number;
  CreatedDate: Date;
}
