import { TagModel } from './models/tag.model';

export namespace TagAction {
  export class GetSuccess {
    public static readonly type = '[TAGS] Get tag success';
    constructor(public tag: TagModel) { }
  }

  export class GetAllSuccess {
    public static readonly type = '[TAGS] Get all tags success';
    constructor(public tags: TagModel[]) { }
  }

  export class UpdateSuccess {
    public static readonly type = '[TAGS] Update tag success';
    constructor(public tag: TagModel) { }
  }

  export class CreateSuccess {
    public static readonly type = '[TAGS] Create tag success';
    constructor(public tag: TagModel) { }
  }

  export class DeleteSuccess {
    public static readonly type = '[TAGS] Delete report success';
    constructor(public tag: TagModel) { }
  }
}
