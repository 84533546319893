import { Component, ViewContainerRef } from '@angular/core';
import { ModalModelComponent } from '@shared/modals/modal.model';

@Component({
  selector: 'indicio-modal-outlet',
  template: ''
})
export class ModalComponent {

  constructor(
    private viewContainerRef: ViewContainerRef
  ) { }

  openModal(component, options) {
    options = options || {};
    const ref = this.viewContainerRef.createComponent(component);
    ref.changeDetectorRef.detectChanges();
    const instance = <ModalModelComponent> ref.instance;
    instance.setOptions(options);
    instance.close = this.close.bind(this);
    this.onClose = instance.onClose.bind(instance);
  }

  onClose() { }

  close() {
    this.onClose();
    this.viewContainerRef.clear();
  }
}
