<div mat-dialog-content>

  <div class="sample-accuracy-container flex-row"
       [ngClass]="{ 'body-scroll': dataTables.length > 1 }"
       indicioHoverRows>
    <ng-container *ngFor="let table of dataTables">
      <div class="table">
        <ng-container *ngTemplateOutlet="tableTemplate; context: { tableData: table }"></ng-container>
      </div>
    </ng-container>
  </div>

  <div class="row"
       style="max-width: 672px; margin: 0 auto;">
    <div class="col-xs-6">
      <mat-form-field class="white-form-style-helper">
        <mat-select [value]="chosenChartOption"
                    (selectionChange)="changeChartOption($event.value)">
          <mat-option [value]="value"
                      *ngFor="let value of chartOptions">{{ value.Display }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xs-6">
      <mat-form-field class="white-form-style-helper">
        <mat-select [value]="currentAccuracyMeasurement"
                    (selectionChange)="changeAccuracyMeasure($event.value)">
          <mat-option [value]="value"
                      [title]="value.Description"
                      *ngFor="let value of accuracyMeasurements">{{ value.Display }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div></div>
  <div>
    <div class="pointer"
         (click)="download()"
         style="margin-right: 20px; display: inline-block; color: var(--indicio-modal-text-color, black);">
      <fa-icon [icon]="downloadIcon"></fa-icon>&nbsp;<span class="dotted-underline">Download</span>
    </div>
    <button mat-stroked-button
            (click)="close()">
      Close
    </button>
  </div>
</div>

<ng-template #tableTemplate
             let-tableData="tableData">

  <div class="row">
    <div class="col-xs-12 text-center margin-bottom-10">
      {{ tableData.Title }}
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12"
         style="font-size: 11px;">
      <indicio-data-table [columnTemplate]="tableData.ColumnConfig">
        <div title-row>
          <div *ngFor="let title of tableData.Columns; let titleIdx = index"
               column
               class="crop-string"
               (click)="orderTable(tableData, titleIdx)"
               [ngClass]="{ 'cursor-pointer': tableData.TitleColumn !== titleIdx }">
            <span title>
              {{ title }}
              <i
                 [class]="tableData.OrderByColumn === titleIdx ? (tableData.OrderByDirection === 'asc' ? 'ion-arrow-up-b' : 'ion-arrow-down-b') : null"></i>
            </span>
          </div>
        </div>
        <div [ngClass]="{ 'body-scroll': dataTables.length === 1 }">
          <div row
               *ngFor="let row of tableData.Rows; let rowIdx = index"
               [attr.data-row]="'row' + rowIdx">
            <div column
                 class="crop-string"
                 *ngFor="let column of row; let rowColumnIdx = index"
                 [title]="column || 'N/A'"
                 [ngClass]="{ 'highlighted': rowColumnIdx === tableData.HighlightedColumn }"
                 [attr.left]="tableData.TitleColumn === rowColumnIdx ? true : null"
                 [attr.center]="tableData.TitleColumn !== rowColumnIdx ? true : null">
              <span>{{ column + ((chosenChartOption.Value === 3 && rowColumnIdx !== 0 &&
                (currentAccuracyMeasurement.Value === 'MAPE' || currentAccuracyMeasurement.Value === 'MPE')) ||
                tableData.PercentageColumns.includes(rowColumnIdx) ?
                '%' : '') ||
                'N/A'
                }}</span>
            </div>
          </div>
        </div>
      </indicio-data-table>
    </div>
  </div>

</ng-template>
