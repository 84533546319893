<indicio-dialog>
  <h1 mat-dialog-title>Client settings</h1>
  <div mat-dialog-content>
    <p>Indicio now support themes, you can change it below or in the appearance settings under "Account".</p>

    <mat-form-field>
      <mat-label>Color theme</mat-label>
      <mat-select [ngModel]="currentTheme"
                  (ngModelChange)="appearance.settings.Theme = $event;"
                  (selectionChange)="changeTheme($event)">
        <mat-option [value]="option.value"
                    [title]="option.title"
                    [disabled]="option.admin && !auth.isAdmin"
                    *ngFor="let option of themeOptions">
          {{ option.display }}
          <ng-container *ngIf="option.admin && !auth.isAdmin">
            (coming soon)
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br>
    <br>

  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Close</button>
    <button mat-flat-button
            color="primary"
            (click)="save()"
            cdkFocusInitial>Save</button>
  </div>
</indicio-dialog>
