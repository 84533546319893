<indicio-dialog>
  <h1 mat-dialog-title>Enter manual Bloomberg ticker information</h1>
  <div mat-dialog-content>
    <ng-container *ngTemplateOutlet="tickerInputTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="exchangeCodeTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="marketTemplate"></ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button
            (click)="onNoClick()">
      Cancel
    </button>
    <div [indicioTippy]="!canSave ? 'One or more fields are emtpy. All fields are required.' : null">
      <button mat-flat-button
              color="primary"
              [disabled]="!canSave"
              (click)="save()">
        <span>+ Add</span>
      </button>
    </div>
  </div>
</indicio-dialog>

<ng-template #tickerInputTemplate>
  <mat-form-field>
    <mat-label>Ticker name</mat-label>
    <input matInput
           autocomplete="off"
           [(ngModel)]="figiInfo.Ticker"
           name="Ticker"
           required>
  </mat-form-field>
</ng-template>

<ng-template #exchangeCodeTemplate>
  <indicio-searchable-dropdown [label]="'Select exchance code'"
                               [required]="true"
                               [optionDisplay]="'Display'"
                               [optionTitle]="'Description'"
                               [values]="exchanceCodes"
                               [initializeWithFirstValue]="false"
                               [selectedValue]="selectedExchange"
                               (valueChangeEvent)="setSelectedExchange($event)">
  </indicio-searchable-dropdown>
</ng-template>

<ng-template #marketTemplate>
  <indicio-searchable-dropdown [label]="'Select market sector'"
                               [required]="true"
                               [values]="markets"
                               [initializeWithFirstValue]="false"
                               [selectedValue]="figiInfo.MarketSector"
                               (valueChangeEvent)="figiInfo.MarketSector = $event">
  </indicio-searchable-dropdown>
</ng-template>
