<div class="alg-svg-container line-graph unselectable"
     data-test-id="alg-container"
     [ngStyle]="{'height' : (svgSizes.PlotHeight || 420) + 'px', 'width' : svgSizes.PlotWidth + 'px', 'margin-top': displayMenu ? '30px' : null }">
  <div *ngIf="svgConfig.isMiniAlg"
       data-test-id="mini-alg-exists">
  </div>
  <ng-container *ngIf="svgConfig.isMiniAlg && decoupledTransform"
                [ngTemplateOutlet]="transformTitleTemplate">
  </ng-container>
  <ng-container *ngIf="showLoader"
                [ngTemplateOutlet]="loaderTemplate">
  </ng-container>
  <ng-container *ngIf="showDateResetBtn"
                [ngTemplateOutlet]="dataResetTemplate">
  </ng-container>
  <ng-container *ngIf="displayMenu"
                [ngTemplateOutlet]="menuTemplate">
  </ng-container>
  <ng-container *ngIf="(!svgConfig.isMiniAlg || svgConfig.isMiniAlg && decoupledTransform) && showMenu && svgConfig.ShowPastForecasts"
                [ngTemplateOutlet]="subMenuTemplate">
  </ng-container>

  <div *ngIf="valuesCut"
       class="values-cutoff-text">
    Showing last 500 observations
  </div>
</div>

<ng-template #loaderTemplate>
  <div style="position: absolute; top: calc(50% - 60px); left: calc(50% - 55px); text-align: center; z-index: 1">
    <i style="font-size: 50px;"
       class="ion-load-c spinner"></i>
    <div>Loading models...</div>
  </div>
</ng-template>

<ng-template #transformTitleTemplate>
  <div class="floating-transform-title">
    {{ getTransformName() }}
  </div>
</ng-template>

<ng-template #subMenuTemplate>
  <div class="past-forecast-submenu">
    <span class="past-settings-btn no-hover"
          style="padding: 0 5px">Past forecast step(s):</span>
    <span class="past-settings-btn"
          [ngClass]="{ active: svgConfig.PastType === 0 }"
          (click)="setPastType(0)">All</span>
    <span class="past-settings-btn"
          [ngClass]="{ active: svgConfig.PastType === 1 }"
          (click)="setPastType(1)">First</span>
    <span class="past-settings-btn"
          [ngClass]="{ active: svgConfig.PastType === horizon }"
          (click)="setPastType(horizon)">Last</span>
    <span class="past-settings-btn no-hover"
          style="padding: 0 5px">Step: </span>
    <select class="past-forecast-select"
            (change)="setPastType(+$event.target.value)">
      <option [value]="0">All</option>
      <option *ngFor="let i of horizonSteps"
              [value]="i">{{ i }}</option>
    </select>
    <ng-container *ngTemplateOutlet="pastErrorTemplate; context: {
      allError: svgConfig.PastType === 0 && graphData.ActiveModelCount !== 1,
      adjError: !svgConfig.isOriginalData
    }"></ng-container>

  </div>
</ng-template>

<ng-template #pastErrorTemplate
             let-allError="allError"
             let-adjError="adjError">
  <div *ngIf="allError || adjError"
       class="past-forecast-warning orange">
    {{ adjError
    ? 'Cannot show past forecasts with an active adjustment (seasonal/outlier)'
    : 'Select a single model to view all its past forecasts'
    }}
  </div>
</ng-template>

<ng-template #menuTemplate>
  <div class="line-graph__menu {{ graphType === 'variable' ? 'variable' : 'other' }}"
       [ngClass]="{ 'mini-alg' : svgConfig.isMiniAlg }">
    <div class="menu__item pointer title"
         data-test-id="graph-transform-setting"
         (click)="toggleMenu($event)">
      <span class="transform-title-header text-center">Transform</span>
      <span class="transform-title broad text-center">
        {{ getTransformName() }}
      </span>
      <div class="item__content">
        <div (click)="dispatchTransformChange(transforms.original)"
             class="pointer option">
          <i [ngClass]="svgConfig.noTransform ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          None
        </div>
        <div (click)="dispatchTransformChange(transforms.roc)"
             class="pointer option"
             data-test-id="growth-transform"
             *ngIf="showROCBtn">
          <i [ngClass]="svgConfig.roc ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          {{ getGrowthName() }}
        </div>
        <div (click)="dispatchTransformChange(transforms.rocy)"
             class="pointer option"
             *ngIf="showROCYYBtn">
          <i [ngClass]="svgConfig.rocY ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          Growth YOY
        </div>

        <div (click)="dispatchTransformChange(transforms.diff)"
             class="pointer option"
             *ngIf="showDiffBtn">
          <i [ngClass]="svgConfig.diff ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          {{ getDiffName() }}
        </div>
        <div (click)="dispatchTransformChange(transforms.diffy)"
             class="pointer option"
             *ngIf="showDiffyBtn">
          <i [ngClass]="svgConfig.diffy ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          Diff YOY
        </div>

        <div (click)="dispatchTransformChange(transforms.rolling12m)"
             class="pointer option"
             *ngIf="showRolling12MBtn">
          <i [ngClass]="svgConfig.rolling12MAvg ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          Rolling 12M Avg
        </div>
        <div (click)="dispatchTransformChange(transforms.rolling12mSum)"
             class="pointer option"
             *ngIf="showRolling12MBtn">
          <i [ngClass]="svgConfig.rolling12MSum ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          Rolling 12M Sum
        </div>
        <div (click)="dispatchTransformChange(transforms.rolling4q)"
             class="pointer option"
             *ngIf="showRolling4QBtn">
          <i [ngClass]="svgConfig.rolling4QAvg ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          Rolling 4Q Avg
        </div>
        <div (click)="dispatchTransformChange(transforms.rolling4qSum)"
             class="pointer option"
             *ngIf="showRolling4QBtn">
          <i [ngClass]="svgConfig.rolling4QSum ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          Rolling 4Q Sum
        </div>
        <ng-container *ngIf="showDecoupleBtn && !svgConfig.isMiniAlg">
          <hr>
          <div (click)="dispatchDecoupledChange(!decoupledTransform)">
            <i
               [ngClass]="!decoupledTransform ? 'ion-android-checkbox-outline' : 'ion-android-checkbox-outline-blank'"></i>
            Apply to all plots
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="!svgConfig.isMiniAlg">
      <ng-container *ngIf="showSeasonalAdjBtn || activateAggregatedBtn || showOutlierAdjBtn"
                    [ngTemplateOutlet]="adjustmentTemplate">
      </ng-container>
      <ng-container *ngIf="showPastForecastBtn || showFittedDataBtn"
                    [ngTemplateOutlet]="dataSelectionTemplate">
      </ng-container>
      <ng-container *ngIf="showIndicatorBtn"
                    [ngTemplateOutlet]="eyeMenuTemplate">
      </ng-container>
      <ng-container *ngIf="showDatePickerBtn"
                    [ngTemplateOutlet]="datePickerTemplate">
      </ng-container>
      <ng-container *ngIf="showDownloadBtn && !graphData.DataEmpty"
                    [ngTemplateOutlet]="downloadDataTemplate">
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #dataSelectionTemplate>
  <div class="menu__item pointer title"
       (click)="toggleMenu($event)">
    <span class="transform-title-header text-center">Data</span>
    <span class="transform-title broad text-center">{{ getDataTypeName() }}</span>
    <div class="item__content">
      <div (click)="clickShowOriginal()">
        <i
           [ngClass]="!svgConfig.ShowPastForecasts && !svgConfig.ShowFittedData ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
        Original
      </div>
      <div (click)="clickFittedData()"
           data-test-id="fitted-values-graph-setting"
           *ngIf="showFittedDataBtn">
        <i [ngClass]="svgConfig.ShowFittedData ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
        Fitted values
      </div>
      <div *ngIf="showPastForecastBtn"
           [ngClass]="{ 'no-hover': !svgConfig.isOriginalData }"
           [indicioTippy]="{ placement: 'left', maxWidth: 200, content: svgConfig.isOriginalData ? null : 'Not available when adjusting the data (' + getDataName() + ')' }">
        <div class="ignore"
             (click)="clickPastForecasts()"
             [ngClass]="{ 'disabled': !svgConfig.isOriginalData }">
          <i
             [ngClass]="svgConfig.ShowPastForecasts ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
          Past forecasts
        </div>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #adjustmentTemplate>
  <div class="menu__item pointer title"
       (click)="toggleMenu($event)">
    <span class="transform-title-header text-center">Adjustment</span>
    <span class="transform-title text-center">{{ getDataName() }}</span>
    <div class="item__content">
      <div (click)="dispatchDataTypeChange(ALGDataType.original)"
           class="pointer option">
        <i [ngClass]="svgConfig.isOriginalData ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
        None
      </div>
      <div (click)="!showSeasonalAdjBtn ? null : dispatchDataTypeChange(ALGDataType.seasonal)"
           class="pointer option"
           [ngClass]="{ 'disabled': !showSeasonalAdjBtn }">
        <i [ngClass]="svgConfig.seasonal ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
        Seasonal adj
      </div>
      <div (click)="!showOutlierAdjBtn ? null : dispatchDataTypeChange(ALGDataType.outlier)"
           class="pointer option"
           [ngClass]="{ 'disabled': !showOutlierAdjBtn }">
        <i [ngClass]="svgConfig.outliers ? 'ion-android-radio-button-on' : 'ion-android-radio-button-off'"></i>
        Outliers adj
      </div>

    </div>
  </div>
</ng-template>

<ng-template #eyeMenuTemplate>
  <div class="menu__item pointer title"
       (click)="toggleMenu($event)"
       *ngIf="showIndicatorBtn">
    <span class="transform-title text-center">View</span>
    <div class="item__content">
      <div (click)="toggleShowIndicators()">
        <i [ngClass]="showIndicators ? 'ion-android-checkbox-outline' : 'ion-android-checkbox-outline-blank'"></i>
        Indicators
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dataResetTemplate>
  <div class="date-reset">
    <i class="ion-refresh"
       (click)="resetDateRange()"
       indicioTippy="Reset date range"></i>
  </div>
</ng-template>

<ng-template #downloadDataTemplate>
  <div class="menu__item pointer title small"
       indicioTippy="Download"
       (click)="toggleMenu($event)">
    <span>
      <fa-icon [icon]="downloadIcon"></fa-icon>
    </span>
    <div class="item__content">
      <div (click)="!isOwner ? null: downloadData()"
           *ngIf="graphData.Forecasted.length !== 0 || !!!graphData.HistoricLine || graphData.HistoricLine.Values.length !== 0 || graphData.PastForecasts.length !== 0 || graphData.Fitted.length !== 0 || graphData.Lines.length !== 0"
           [indicioTippy]="!isOwner ? { content: ownerDetails.MissingAccessMessage, placement: 'left' } : null"
           [class]="{ disabled: !isOwner }">Download graph data</div>
      <div (click)="downloadImage()">Download as image</div>
      <div *ngIf="showExport"
           (click)="exportResult()">Export to Macrobond</div>
    </div>
  </div>
</ng-template>

<ng-template #datePickerTemplate>
  <div class="menu__item title small dates"
       indicioTippy="Change data range"
       data-test-id="graph-date-setting"
       (click)="toggleMenu($event, true)"
       sticky>
    <div class="icon pointer"><i class="ion-calendar"></i></div>
    <div class="item__content"
         style="width: 300px">
      <div class="row no-hover extra-margin-top">
        <div class="col-xs-6">
          <indicio-date-picker [periodicity]="this.periodicity?.Value"
                               [value]="fromDateMomentCopy"
                               [min]="disableFromUntil"
                               [max]="disableFromSince"
                               (selectDateEvent)="setDatePicker($event, 'start')"
                               placeholder="View data from">
          </indicio-date-picker>
        </div>
        <div class="col-xs-6">
          <indicio-date-picker [periodicity]="this.periodicity?.Value"
                               [value]="toDateMomentCopy"
                               [min]="disableToUntil"
                               [max]="disableToSince"
                               (selectDateEvent)="setDatePicker($event, 'end')"
                               placeholder="View data to">
          </indicio-date-picker>
        </div>
      </div>
      <div class="row no-hover extra-margin-top">
        <div class="col-xs-6">
          <button class="btn outline blue btn-small-inline"
                  style="padding: 7px 10px; margin-bottom: 5px;">Close</button>
        </div>
        <div class="col-xs-6 text-right">
          <button (click)="applyDateRanges($event)"
                  class="btn btn-small-inline"
                  style="padding: 7px 10px; margin-bottom: 5px;">Apply</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
