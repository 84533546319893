import { Injectable } from '@angular/core';
import { CompanyMapper } from '@core/store/company/company-mapper';
import { CompanyAdminModel } from '@core/store/company/company.model';
import { CompanyMetaAdminDTO } from '@core/store/company/dtos/company-dto';
import { RClientInfoDTO, RRequestInfoDTO, SystemsInfoDTO } from '@modules/admin/entities/systems-info.dto';
import { RClientInfoModel, RRequestInfoModel, SystemsInfoModel } from '@modules/admin/entities/systems-info.model';
import { DateUtils } from '@shared/utils/date.utils';
import { FileUtils } from '@shared/utils/file.utils';
import * as moment from 'moment';
import { EndpointDTO } from '../components/health/performance/entities/endpoint-dto';
import { EndpointModel } from '../components/health/performance/entities/endpoint.model';
import { ClientMetaDTO } from '../entities/client-meta.dto';
import { LogType, ParsedLogFile, ParsedLogType, SysLogDTO, SystemLogSource } from '../entities/syslog-dto';
import { UtilitiesLogFileDTO } from '../entities/utilities-logfile.dto';

@Injectable({
  providedIn: 'root'
})
export class AdminMapper {

  constructor(private companyMapper: CompanyMapper) { }

  public mapSystemInfo(dto: SystemsInfoDTO, model?: SystemsInfoModel) {
    model = Object.faMapTo(model || new SystemsInfoModel(), dto);
    model.RClients = dto.RClients.map(x => this.mapRClientInfo(x));
    model.Requests = dto.Requests.map(x => this.mapRRequestInfo(x));
    model.Requests = model.Requests.sort((a, b) => +a.CreatedDate - +b.CreatedDate);
    return model;
  }

  public mapClientMeta(dto: ClientMetaDTO) {
    return Object.assign(new ClientMetaDTO, dto, { LastActivity: DateUtils.fromUtc(dto.LastActivity as any) });
  }

  public mapCompany(dto: CompanyMetaAdminDTO): CompanyAdminModel {
    const model = Object.assign(new CompanyAdminModel, this.companyMapper.mapMeta(dto), <CompanyMetaAdminDTO> <unknown> {
      LastActivityDate: DateUtils.newNullableDate(dto.LastActivityDate),
      LastActivityUserEmail: dto.LastActivityUserEmail
    });

    const expiryDate = dto.TrialExpirationDate ? DateUtils.newMoment(dto.TrialExpirationDate) : DateUtils.newMoment(dto.CreatedDate).add(3, 'months');

    model.recentActivity = dto.LastActivityDate && moment(dto.LastActivityDate).isAfter(expiryDate);

    model.oldTrial = !model.Disabled && model.Trial
      && ((dto.TrialExpirationDate && moment().isAfter(DateUtils.newMoment(model.TrialExpirationDate))) || (!!!dto.TrialExpirationDate && DateUtils.newMoment(model.CreatedDate).isBefore(moment().subtract(3, 'month'))));

    model.daysUsedAfterTrial = model.oldTrial ? moment(dto.LastActivityDate).diff(expiryDate, 'days') : 0;

    return model;
  }

  public mapRRequestInfo(dto: RRequestInfoDTO) {
    const model = Object.faMapTo(new RRequestInfoModel(), dto);
    model.ClaimedAt = DateUtils.newNullableDate(dto.ClaimedAt);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    return model;
  }

  private mapRClientInfo(dto: RClientInfoDTO) {
    const model = Object.faMapTo(new RClientInfoModel(), dto);
    model.LastActive = DateUtils.newDate(dto.LastActive);
    model.LatestRequest = DateUtils.newNullableDate(dto.LatestRequest);
    return model;
  }

  public mapEndpointPerformance(dto: EndpointDTO) {
    const model = Object.faMapTo(new EndpointModel, dto);
    model.LastUsage = DateUtils.newDate(dto.LastUsage);
    model.Dates = dto.Dates.map(x => DateUtils.newDate(x));
    model.Average = dto.Average.map(x => +x);
    model.Min = dto.Min.map(x => +x);
    model.Max = dto.Max.map(x => +x);
    return model;
  }

  public mapSysLog(dto: SysLogDTO) {
    dto.moment = DateUtils.newMoment(dto.Date);
    dto.sizeString = FileUtils.formatBytes(dto.Size);
    dto.Archived = dto.Path.includes('archive');
    dto.Source = this.getLogSource(dto.Path);
    dto.Type = this.getLogType(dto.Path);
    return dto;
  }

  public mapLogFile(body: string, source: SystemLogSource | 'utils', type?: LogType) {

    if (source === 'utils') {
      return this.addLineNumbers(body.split('\n'));
    }


    let temp = body.split(/\|[\s+]?[\r]?\n/gm);
    if (source !== 'frontend') {
      if (source !== 'identity' && (type === 'error' || type === 'warning')) {
        return this.parseBackendErrorLog(temp, (type === 'error' ? 'Exception' : 'Warning') as ParsedLogType);
      }
      return this.addLineNumbers(temp);
    } else {
      temp = body.split(/[\r]?\n/gm);
      return this.parseFrontendErrorLog(temp);
    }
  }

  public mapUtilitiesLog(dto: UtilitiesLogFileDTO) {
    dto.Size = dto.size;
    dto.sizeString = FileUtils.formatBytes(dto.size);
    dto.Name = dto.fileName;
    dto.Path = dto.fileName;
    dto.Type = this.getLogType(dto.Name);
    dto.Source = 'utils';
    return dto;
  }


  private getLogSource(name: string) {
    name = name.toLowerCase();
    switch (true) {
      case name.includes('frontend'):
        return 'frontend';
      case name.includes('import'):
        return 'import';
      case name.includes('identity') || name.includes('id-'):
        return 'identity';
      case name.includes('providers'):
        return 'provider';
      default:
        return 'api';
    }
  }


  private getLogType(path: string) {
    path = path.toLowerCase();
    switch (true) {
      case path.includes('error'):
        return 'error';
      case path.includes('debug'):
        return 'debug';
      case path.includes('warn'):
        return 'warning';
      case path.includes('trace'):
        return 'trace';
      default:
        return 'info';
    }
  }

  private parseBackendErrorLog(temp: string[], type: ParsedLogType = 'Exception') {
    let lines = temp.map(line => line.split('|')).map(x => [x[0].trim(), x[3]?.trim(), x.slice(4, x.length).join()]);
    const mappedLines: ParsedLogFile[] = lines.map(line => {
      return {
        Date: line[0],
        Subject: line[1],
        Stacktrace: line[2].replace(/\n/g, '<br>>').replace(/([\w-]+.cs:line \d{1,})/g, '<span class="highlight">$1</span>'),
        Type: type
      };
    });
    return mappedLines;
  }

  private addLineNumbers(content: string[]) {
    let fileContent = '';
    for (let i = 0; i < content.length; i++) {
      fileContent += i + ':      ' + content[i] + '\n';
    }
    return fileContent;
  }

  private parseFrontendErrorLog(rows: string[]) {
    const lines = rows.map(row => row.split('|')).map(x => [x[0], x.slice(3, x.length).join().trim()]);
    const mappedLines: ParsedLogFile[] = lines.filter(x => !!x[1])
      .map(line => {
        try {
          return { D: line[0], C: JSON.parse(line[1]) };
        } catch (error) {
          console.error('failed to parse json:' + line[1]);
          return { D: line[0], C: line[1] };
        }
      })
      .map(y => {
        const ans: ParsedLogFile = {
          Date: y.D,
          Subject: y.C?.Subject ?? y.C,
          Stacktrace: y.C?.Stacktrace?.replace(/\n/g, '<br>>').replace(/([\w-\.]+.js:\d{1,}:\d{1,})/g, '<span class="highlight">$1</span>'),
          Type: y.C?.Type ?? 'Exception',
          User: y.C?.User ?? 'Unknown user',
          ForecastId: y.C?.ForecastId,
          CompanyId: y.C?.CompanyId,
          Location: y.C?.Location
        };
        return ans;
      });
    return mappedLines;
  }
}
