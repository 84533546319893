import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { StatusService } from '@core/services/status/status.service';
import { StatusComponent } from '@shared/components/status/status.component';


@Component({
  selector: 'indicio-modal-base',
  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.less'],
})
export class ModalBaseComponent implements OnInit {

  @ViewChild(StatusComponent, { static: true }) statusComponent: StatusComponent;

  @Input() set loading(flag: boolean) { this._loading = flag; this.change.detectChanges(); }
  @Input() modalTitle: string;
  @Input() child: any;
  @Input() modalClass: string = 'modal-content';
  @Input() extraClasses: string;
  @Input() bodyExtraClass: string = '';
  @Input() modalExtraClass: string = '';
  @Input() onBackdropClose: Function = null;
  @Input() hideCloseButton = false;

  _loading: boolean;
  bodyClass: string;
  mClass: string;
  cClass: string;

  constructor(
    public statusService: StatusService,
    private change: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.cClass = this.getClass();
    this.mClass = this.getModalClass();
    this.bodyClass = this.getBodyClass();
  }

  public backdropClose() {
    if (this.onBackdropClose) {
      return this.onBackdropClose();
    }
    this.child.close();
  }

  public getClass() { return `${this.modalClass} ${this.extraClasses}`; }
  public getBodyClass() { return `${this.bodyExtraClass}`; }
  public getModalClass() { return `${this.modalExtraClass}`; }
}
