import { Def } from '@core/decorators/def';
import { Country } from '@modules/lang/types/country';

export class ProfileModel {
  @Def() ClientId: string;
  @Def() Email: string;
  @Def() FirstName: string;
  @Def() LastName: string;
  @Def() Bio: string;
  @Def() CountryCode: string;
  @Def() Country: Country;
  @Def() ProfilePicture: string;
  @Def() CreatedDate: Date;
  @Def() DisplayLanguage = 'en';
}
