import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { Store } from '@ngxs/store';
import { ClearStateAction } from '../auth/auth.actions';
import { CompanyActions } from '../company/company.actions';
import { ForecastActions } from '../forecast/forecast.actions';
import { ForecastFrontendService } from '../forecast/forecast.frontend.service';
import { QueueActions } from '../script-queue/script-queue.actions';
import { ScenarioDTO } from './dtos/scenario.dtos';
import { ScenarioAction } from './scenario.actions';
import { ScenarioBackendService } from './scenario.backend.service';


@Injectable({
  providedIn: 'root'
})
export class ScenarioFrontendService {

  loading = {
    ScenarioResultLoading: false
  };

  // Map<forecastid, ScenarioDTO[]>;
  private scenarios: Map<string, ScenarioDTO[]> = new Map();
  // Map<scenarioId, forecastId>;
  private scenarioInForecast: Map<string, string> = new Map();

  private scenarioById(scenarioId: string) {
    const forecast = this.scenarioInForecast.get(scenarioId);
    return forecast ? this.scenarios.get(forecast)?.find(x => x.ScenarioId === scenarioId) : null;
  }
  public scenariosInFcast(fcastId: string) { return this.scenarios.get(fcastId) || []; }

  constructor(
    private store: Store,
    private actions: ActionService,
    private fvService: ForecastFrontendService,
    private backend: ScenarioBackendService,
  ) {
    this.setupListeners();
  }

  private setupListeners() {
    this.actions.dispatched(ScenarioAction.CreateOrUpdateSuccess).subscribe(({ scenario }) => {
      this.addOrUpdateScenario(scenario);
    });

    this.actions.dispatched(ScenarioAction.GetAllSuccess).subscribe(({ scenarios }: ScenarioAction.GetAllSuccess) => {
      scenarios.forEach(scenario => this.addOrUpdateScenario(scenario));
    });

    this.actions.dispatched(ScenarioAction.TriggerSuccess).subscribe(({ scenarioId }) => {
      const scenario = this.scenarioById(scenarioId);
      if (!scenario) { return; }
      scenario.Triggered = true;
      scenario.Pending = true;
      scenario.FinalResults = [];
    });

    this.actions.dispatched(QueueActions.Finished).subscribe((event: QueueActions.Finished) => {
      if (event.message.ScenarioId && event.message.Extras?.PendingScenarios === 0) {
        const scenario = this.scenarioById(event.message.ScenarioId);
        if (!scenario) { return; }
        scenario.Pending = false;
        scenario.Processing = false;
      }
    });

    this.actions.dispatched(ScenarioAction.ClearResults).subscribe(({ forecastVersionId }: ScenarioAction.ClearResults) => {
      const scenarios = this.scenariosInFcast(forecastVersionId);
      scenarios.forEach(x => {
        x.FinalResults = [];
        x.Triggered = false;
      });
    });

    this.actions.dispatched(ForecastActions.RemoveScenarioResults).subscribe(({ scenarioIds }: ForecastActions.RemoveScenarioResults) => {
      scenarioIds.forEach(id => {
        const scenario = this.scenarioById(id);
        if (!scenario) { return; }
        scenario.FinalResults = [];
        scenario.Triggered = false;
      });
    });

    this.actions.dispatched(ScenarioAction.DeleteSuccess).subscribe(({ scenarioId }) => {
      this.removeScenario(scenarioId);
    });

    this.actions.dispatched(QueueActions.Queued).subscribe((action: QueueActions.Queued) => {
      if (action.message.ScriptName !== 'conditional') { return; }
      const scenario = this.scenarioById(action.message.ScenarioId);
      if (!scenario) { return; }
      scenario.Pending = false;
      scenario.Processing = true;
    });

    this.actions.dispatched(ClearStateAction, CompanyActions.ResetCompanyData).subscribe(() => {
      this.scenarios = new Map();
      this.scenarioInForecast = new Map();
    });
  }

  public getOrFetchAllScenarios(forecastVersionId: string) {
    const scenarios = this.scenariosInFcast(forecastVersionId);
    const ids = scenarios.map(x => x.ScenarioId && x.fetched ? x.ScenarioId : '');
    return this.fvService.getOrFetchForecastVersion(forecastVersionId)
      .then(fv => {
        if (fv.ScenarioIds.some(x => !(ids.includes(x)))) {
          return this.fetchAllScenarios(forecastVersionId);
        } else {
          return scenarios;
        }
      });
  }

  public getOrFetchScenario(fversionId: string, scenarioId: string) {
    const scenario = this.scenarioById(scenarioId);
    if (!scenario || !scenario.fetched) {
      return this.fetchScenario(fversionId, scenarioId);
    } else {
      return Promise.resolve(scenario);
    }
  }

  public fetchAllScenarios(forecastVersionId: string) {
    return this.backend.getAllScenariosInForecast(forecastVersionId)
      .then(scenarios => {
        this.scenarios.set(forecastVersionId, scenarios);
        return scenarios;
      });
  }

  public fetchScenario(fversionId: string, scenarioId: string) {
    return this.backend.getScenario(fversionId, scenarioId)
      .then(newScenario => {
        this.addOrUpdateScenario(newScenario);
        this.store.dispatch(new ScenarioAction.CreateOrUpdateSuccess(newScenario));
        return newScenario;
      });
  }

  public fetchAvailableScenarioMultiModels(fVersionId: string) {
    return this.backend.getAvailableScenarioMultiModels(fVersionId)
      .then(resp => resp);
  }

  public createNewScenario(scenario: ScenarioDTO) {
    return this.backend.createScenario(scenario)
      .then(newScenario => {
        this.addOrUpdateScenario(newScenario);
        this.store.dispatch(new ScenarioAction.CreateOrUpdateSuccess(scenario));
        return newScenario;
      });
  }

  public updateScenario(scenario: ScenarioDTO) {
    return this.backend.updateScenario(scenario)
      .then(updated => {
        updated.color = scenario.color;
        this.addOrUpdateScenario(updated);
        this.store.dispatch(new ScenarioAction.CreateOrUpdateSuccess(scenario));
        return updated;
      });
  }

  public deleteScenario(scenario: ScenarioDTO) {
    return this.backend.deleteScenario(scenario)
      .then(() => {
        this.removeScenario(scenario.ScenarioId);
        this.store.dispatch(new ScenarioAction.DeleteSuccess(scenario.ScenarioId, scenario.ForecastVersionId));
        return scenario.ScenarioId;
      });
  }

  public triggerScenario(scenario: ScenarioDTO) {
    return this.backend.triggerScenario(scenario)
      .then((s) => {
        scenario.Triggered = true;
        this.store.dispatch(new ScenarioAction.TriggerSuccess(scenario.ScenarioId));
        this.store.dispatch(new ScenarioAction.CreateOrUpdateSuccess(s));
        return scenario.ScenarioId;
      });
  }

  private addOrUpdateScenario(newScenario: ScenarioDTO) {
    const scenarios = this.scenariosInFcast(newScenario.ForecastVersionId);
    if (scenarios) {
      scenarios.addOrUpdateBy(newScenario, 'ScenarioId');
    } else {
      this.scenarios.set(newScenario.ForecastVersionId, [newScenario]);
    }
    this.scenarioInForecast.set(newScenario.ScenarioId, newScenario.ForecastVersionId);
  }

  private removeScenario(scenarioId: string) {
    const forecastId = this.scenarioInForecast.get(scenarioId);
    if (!forecastId) { return; }
    const scenarios = this.scenariosInFcast(forecastId);
    this.scenarios.set(forecastId, scenarios.filter(x => x.ScenarioId !== scenarioId));
    this.scenarioInForecast.delete(scenarioId);
  }

}
