<h1 mat-dialog-title>{{ data.Title }}</h1>
<div mat-dialog-content>
  {{ data.Text }}
</div>
<div mat-dialog-actions
     *ngIf="data.State === 'INITIAL'">
  <div>
    <button mat-button
            *ngIf="manyCompanies && data.Force"
            (click)="openChangeCompanyModal()">
      Change company
    </button>

    <button mat-button
            *ngIf="!data.Force"
            (click)="close()">
      Close
    </button>

    <button mat-flat-button
            *ngIf="data.Force"
            (click)="logout()">
      Logout
    </button>
  </div>
  <button mat-flat-button
          class="green"
          (click)="proceed()">
    {{ data.ButtonText }}
  </button>
</div>

<div mat-dialog-actions
     style="justify-content: flex-end;"
     *ngIf="data.State === 'COMPLETE'">
  <button mat-flat-button
          class="green"
          (click)="close()">
    {{ data.ButtonText }}
  </button>
</div>
