import { DisplayValue } from '../types/display-value';

export type ReportTemplateType = 'fiscal-year' | 'overview';

export class ReportTemplate extends DisplayValue<ReportTemplateType> { };
export const ReportTemplates = [
  new ReportTemplate('fiscal-year', 'Fiscal year', 'A fiscal year report'),
  new ReportTemplate('overview', 'Overview', 'An overview of multiple forecasts')
];


