<indicio-dialog-base class="save-forecast-result">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content
       style="overflow: hidden;">
    <ng-container *ngTemplateOutlet="resultNameTemplate"></ng-container>
  </div>
  <div mat-dialog-actions>
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </div>
</indicio-dialog-base>

<ng-template #buttonTemplate>
  <button mat-stroked-button
          (click)="close()">
    Close
  </button>
  <button color="primary"
          [disabled]="!initialized || !!nameError"
          mat-flat-button
          (click)="save()">
    Save
  </button>
</ng-template>

<ng-template #resultNameTemplate>
  <div class="row">
    <div class="col-xs-12">
      <mat-form-field>
        <mat-label *ngIf="initialized">Result name</mat-label>
        <mat-label *ngIf="!initialized"><i class="ion-load-c spinner"></i> Generating result...</mat-label>
        <input type="text"
               placeholder="Name"
               matInput
               [ngModelOptions]="{standalone: true}"
               [required]="initialized"
               [(ngModel)]="name"
               (keyup)="nameChange($event.target.value)"
               class="material">
        <mat-hint *ngIf="nameError">
          <span class="error-text">{{nameError}}</span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</ng-template>
