import { Def } from '@core/decorators/def';
import { Transformations } from '@core/types/transformation.type';

export class CreateForecastVariableDTO {
  @Def() SourceVariableMetaId: string;
  @Def() Name: string;
  @Def() AggregationMethodId: string;
  @Def() IsExogenous: boolean;
  @Def() SourceValuesTransformation: Transformations.FVarTrans;
}

export class CanSwapForecastVariableDTO {
  public Messages: string[];
}
