import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { CreateHEventModalOpts } from './create-historic-event-modal.options';

export class OpenCreateHistoricEventModal {
  public static readonly type = '[MODAL] Open create historic event modal';
  data: CreateHEventModalOpts;
  constructor(
    forecastVersion?: ForecastVersionModel
  ) { this.data = { forecastVersion }; }
}
