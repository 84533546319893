import { WikiAssetDTO, WikiPageDTO } from './wiki.entities';

export namespace WikiActions {

  export class OpenAdminEdit {
    public static readonly type = '[WIKI] Open admin edit';
    constructor(public slug: string) { }
  }

  export class CloseSidebar {
    public static readonly type = '[WIKI] Close the wiki sidebar';
    constructor() { }
  }

  export class OpenInSidebar {
    public static readonly type = '[WIKI] Open page in sidebar';
    constructor(public slug: string) { }
  }

  export class PageClosed {
    public static readonly type = '[WIKI] Page closed';
    constructor(public slug: string) { }
  }

  export class Pinned {
    public static readonly type = '[WIKI] Page pinned';
    constructor(public page: WikiPageDTO) { }
  }

  export class AssetLoaded {
    public static readonly type = '[WIKI] Asset loaded';
    constructor(public asset: WikiAssetDTO) { }
  }
}
