<div *ngIf="initComplete"
     class="grid-system"
     style="align-items: end;"
     [ngStyle]="{ '--grid-template': (transformToAdd?.isMoving || transformToAdd?.isGeometric ? '1fr 0.4fr' : '1fr') }">
  <indicio-searchable-dropdown label="Transformation"
                               [indicioTippy]="tippy"
                               [disabled]="disabled"
                               [required]="required"
                               [values]="transformations"
                               [initializeWithFirstValue]="false"
                               optionDisplay="Display"
                               [selectedValue]="selectedTransform"
                               (valueChangeEvent)="selectTransformation($event)">
  </indicio-searchable-dropdown>
  <mat-form-field *ngIf="transformToAdd?.isMoving"
                  style="padding-left: 10px">
    <mat-label>Order</mat-label>
    <input required
           class="material"
           matInput
           min=1
           max=24
           type="number"
           name="maTerms"
           (ngModelChange)="setMaTerms($event)"
           [ngModel]="transformToAdd?.MaArgs.Terms">
  </mat-form-field>
  <mat-form-field *ngIf="transformToAdd?.isGeometric"
                  style="padding-left: 10px">
    <mat-label>Alpha</mat-label>
    <input required
           class="material"
           matInput
           min=0.01
           max=0.99
           step=0.01
           type="number"
           name="gaAlpha"
           (ngModelChange)="setGaAlpha($event)"
           [ngModel]="transformToAdd?.GaArgs.Alpha">
  </mat-form-field>
</div>
