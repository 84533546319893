import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { d3Utils } from '@shared/utils/d3/d3.utils';
import { UUIdUtils } from '@shared/utils/uuid.utils';
import * as d3 from 'd3';

@Component({
  selector: 'indicio-slider',
  template: '<div class="slider-container" #slider [id]="sliderId"></div>',
  styleUrls: ['./slider.component.less']
})
export class SliderComponent implements OnChanges, AfterViewInit {

  public sliderId: string = UUIdUtils.GetNewIdV4();

  // SVG Objects
  /* Main SVG object */
  private svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  /* Scales */
  private dragScale: d3.ScaleLinear<number, number>;
  /* groups */
  private colorGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  private sliderGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>;
  /* bars */
  private enabledLine: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
  private activeLine: d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
  /* drag objects */
  private circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;

  @Input() from: number;
  @Input() to: number;
  @Input() enabledFrom: number;
  @Input() enabledTo: number;
  @Input() current: number;
  @Input() disabled: boolean;

  @Output() valueChanged = new EventEmitter<number>();
  @Output() dragActite = new EventEmitter();

  // Sizes
  private cornerRadius = 3;
  private barHeight = 6;
  private svgHeight = 50;
  private circleRadius = 10;
  private padding = 20;

  // Flags
  private initialized = false;

  // State
  private lastValue: number;

  // Getters
  private get getContainerWidth() { return d3Utils.getElementSizes(this.el).Width; }
  private get rectY() { return (this.svgHeight / 2) - (this.barHeight / 2); }
  private get rectX() { return this.padding; }
  private get clipId() { return `slider-clip-${this.sliderId}`; }

  constructor(
    private el: ElementRef
  ) {
  }

  public setup() {
    // Create main SVG obj
    this.svg = d3.create('svg');
    this.svg.attr('width', this.getContainerWidth).attr('height', this.svgHeight);

    // Create scales
    this.dragScale = this.getDragScale();

    // Get some pixel positions from the new scales, used below during init of svg-objects

    // Create groups
    this.colorGroup = this.svg.append('g').attr('class', 'backround-lines-group').attr('transform', `translate(${this.rectX}, ${this.rectY})`);
    this.sliderGroup = this.svg.append('g').attr('class', 'slider-group').attr('transform', `translate(${this.rectX}, ${this.rectY})`);

    // Add a clip-path to stop all rects from being drawn outside (rounded corners)
    this.colorGroup.append('clipPath')
      .attr('id', this.clipId)
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('rx', this.cornerRadius).attr('ry', this.cornerRadius)
      .attr('height', this.barHeight).attr('width', this.dragScale(this.to));

    // Add the background line, covering the full range/domain.
    this.colorGroup.append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('clip-path', `url(#${this.clipId})`)
      .attr('height', this.barHeight).attr('width', this.dragScale(this.to))
      .attr('stroke', 0).attr('class', 'slider-line')
      .style('fill', this.disabled ? '#616161' : '#6387d033');

    // Add the 'enabled' section
    this.enabledLine = this.colorGroup.append('rect')
      .attr('x', this.dragScale(this.enabledFrom)).attr('y', 0)
      .attr('clip-path', `url(#${this.clipId})`)
      .attr('height', this.barHeight).attr('width', this.dragScale(this.enabledTo))
      .attr('stroke', 0).attr('class', 'enabled-line')
      .style('fill', this.disabled ? '#616161' : '#6387d05c');

    // Add the 'active' section
    this.activeLine = this.colorGroup.append('rect')
      .attr('x', this.dragScale(this.enabledFrom)).attr('y', 0)
      .attr('clip-path', `url(#${this.clipId})`)
      .attr('height', this.barHeight).attr('width', this.dragScale(this.current))
      .attr('stroke', 0).attr('class', 'active-line')
      .style('fill', this.disabled ? '#9e9e9e' : '#6387d0');

    // Add the drag handle (a circle)
    this.circle = this.sliderGroup.append('circle')
      .attr('r', this.circleRadius)
      .attr('cy', this.barHeight / 2)
      .attr('class', 'cursor-pointer')
      .style('fill', this.disabled ? '#9e9e9e' : '#6387d0');

    if (!this.disabled) {
      this.enableDrag(this.circle);
    }
    this.updateCircle();

    this.el.nativeElement.firstElementChild.append(this.svg.node());
    this.initialized = true;
    return this;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) { return; }
    this.update();
  }

  public ngAfterViewInit(): void {
    this.setup();
  }

  private update() {
    this.updateCircle();
    this.updateActiveLine();
    this.updateEnabledLine();
  }

  private updateCircle() {
    this.circle.attr('cx', this.dragScale(this.current));
  }

  private updateActiveLine() {
    this.activeLine.attr('width', this.dragScale(this.current));
  }

  private updateEnabledLine() {
    this.enabledLine.attr('width', this.dragScale(this.enabledTo));
  }

  private enableDrag(e: d3.Selection<any, any, any, any>) {
    const self = this;
    e.call(d3.drag()
      .on('start', function () { self.dragStarted(this); })
      .on('start.interrupt', function () { e.interrupt(); })
      .on('drag', event => { self.dragged(event); })
      .on('end', function () { self.dragEnded(this); }));
  }

  private dragStarted(e: Element) {
    d3.select(e).attr('r', this.circleRadius + 1);
  }

  private dragEnded(e: Element) {
    this.emit(this.current);
    d3.select(e).attr('r', this.circleRadius);
  }

  private getAllowedPosition(value: number) {
    value = Math.max(value, this.enabledFrom);
    value = Math.min(value, this.enabledTo);
    return value;
  }

  private dragged(event: any) {
    let value = Math.floor(this.dragScale.invert(event.x));
    value = this.getAllowedPosition(value);
    this.emit(value);
  }

  private emit(value: number) {
    if (this.lastValue === value) { return; }
    this.lastValue = value;
    this.valueChanged.emit(value);
  }

  private getDragScale() {
    return d3.scaleLinear().domain([this.from, this.to]).range([0, this.getContainerWidth - (2 * this.padding)]).clamp(true);
  }
}
