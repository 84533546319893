import { Def } from '@core/decorators/def';

export class DisplayFilterDTO {
  @Def() DisplayFilterId: string;
  @Def() Name: string;
  @Def() Key: string;
  @Def() Display: string;
  @Def() Active: boolean;
  @Def() Type: string;
}
