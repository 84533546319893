import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';

export type ModelPropertiesType = 'full' | 'train' | 'rw';
export type ModelComponentType = 'univariate' | 'multivariate';

export class ResidualAnalysisDialogData {
  files: ResultFileDTO[] = [];
  currentIndex: number = 0;
}

@Component({
  selector: 'indicio-residual-analysis-dialog',
  templateUrl: 'residual-analysis.dialog.html',
  styleUrls: ['./residual-analysis.dialog.less'],
  encapsulation: ViewEncapsulation.None
})
export class ResidualAnalysisDialogComponent {
  public static Id: string = 'ResidualAnalysisDialogComponent';

  public isLoading: boolean = false;
  public images: ResultFileDTO[] = [];
  public currentIndex: number = 0;
  public modelName: string = null;
  public dataFromSource: ResidualAnalysisDialogData = null;

  constructor(
    public dialogRef: MatDialogRef<ResidualAnalysisDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResidualAnalysisDialogData
  ) {
    if (data.files.length > 0) {
      this.images = data.files;
      this.currentIndex = data.currentIndex;
      this.modelName = data.files[this.currentIndex].displayName;
      this.dataFromSource = data;
    } else {
      this.onNoClick();
    }
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(null);
  }

}
