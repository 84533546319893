import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CloseMainMenuAction, NavigateToAutomation } from '@core/actions/navigation.actions';
import { StatusService } from '@core/services/status/status.service';
import { Automation } from '@core/store/automation/automation';
import { AutomationService } from '@core/store/automation/automation.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { Store } from '@ngxs/store';
import { CalculateDialogData } from './calculate.dialog.constants';

@Component({
  selector: 'indicio-calculate-dialog',
  templateUrl: 'calculate.dialog.html',
})
export class CalculateDialogComponent extends DialogV2BaseDialog<CalculateDialogComponent> {

  public static Id: string = 'CalculateDialogComponent';

  public title: string;
  public infos: Automation.Types.CalculableInfoDTO[];

  constructor(
    dialogRef: MatDialogRef<CalculateDialogComponent, CalculateDialogData>,
    @Inject(MAT_DIALOG_DATA)
    public data: CalculateDialogData,
    private store: Store,
    private status: StatusService,
    private service: AutomationService,
    private companyService: CompanyFrontendService
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.setTitle();
    this.service.getCalculableInfo(this.companyService.activeCompanyId, this.data.type, this.data.ObjectId)
      .then(resp => this.setupInfo(resp))
      .catch(err => {
        this.status.setError(err, true);
      })
      .finally(() => this.initialized = true);
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public calculate() {
    this.service.calculate(this.companyService.activeCompanyId, this.infos.map(i => i.ForecastId))
      .then(() => {
        this.status.setMessage('Tasks queued', 'Success');
        this.dialogRef.close(false);
        this.store.dispatch(new CloseMainMenuAction);
        this.store.dispatch(new NavigateToAutomation('logs'));
      })
      .catch(err => this.status.setError(err, true));
  }

  private setupInfo(infos: Automation.Types.CalculableInfoDTO[]) {
    this.infos = infos;
  }

  private setTitle() {
    switch (this.data.type) {
      case 'forecast':
        this.title = 'Calculate results in forecast';
        break;
      case 'project':
        this.title = 'Calculate results for all forecasts in project';
        break;
      case 'hierarchy':
        this.title = 'Calculate results for all forecasts in hierarchy';
        break;
    }
  }
}
