import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MINIMUM_OBSERVATION_COUNT_FOR_OUT_OF_SAMPLE_CALCULATIONS,
  RW_MAX_RECALCULATION_FREQUENCY
} from '@core/services/environment/environment.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { DateUtils } from '@shared/utils/date.utils';
import { ForecastUtils } from '@shared/utils/forecast/forecast.utils';
import { StateUtils } from '@shared/utils/state.utils';
import * as moment from 'moment';
import { DataRangeSliderOutput, DateRangeLimits, DateRangeOptions, DateRangeSliderOptions } from './date-range.options';
import { DateRangeSlider } from './date-range.slider';

@Component({
  selector: 'indicio-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.less'],
})
export class DateRangeComponent implements OnInit {

  /*  */
  public MIN_OBS: number = 10;
  public MIN_OBS_OOS: number = MINIMUM_OBSERVATION_COUNT_FOR_OUT_OF_SAMPLE_CALCULATIONS;

  @Input() options: DateRangeOptions;
  @Output() changed = new EventEmitter();

  modalState = new StateUtils.StateHelper();

  constructor(
    public appearance: AppearanceService,
    private forecastService: ForecastFrontendService
  ) { }

  /* Getters */
  public get fVersion() { return this.options.forecastVersion; }
  public get canUpdateForecast() { return this.options.canUpdateForecast; }
  private get momentUnit() { return this.fVersion.periodicity.getMomentDurationConstructor(); }

  /* Date-picker getters */
  public get dataStartDate() { return this.idxToMoment(this.dataStartDateIdx); }
  public get testStartDate() { return this.idxToMoment(this.testStartDateIdx); }
  public get forecastStartDate() { return this.idxToMoment(this.forecastStartDateIdx); }

  public get earliestDataStartDate() { return this.idxToDate(this.earliestDataStartDateIdx); }
  public get recommendedTestSize() { return this.activeObservationCount > 100 ? 20 : 50; }

  /* These 3 main dates and indices is what the whole component revolves around.          */
  /* The strange structure below ensures that each angular date picker has its own moment */
  /* that it can look at and modify at will, and all other functions will update it.      */
  /* Date index setters */
  public set dataStartDateIdx(idx: number) { this.dataStartDateIndex = idx; this.dataStartDatePicked = this._dateArray[idx]; }
  public set testStartDateIdx(idx: number) { this.testStartDateIndex = idx; this.testStartDatePicked = this._dateArray[idx]; }
  public set forecastStartDateIdx(idx: number) { this.forecastStartDateIndex = idx; this.forecastStartDatePicked = this._dateArray[idx]; }

  public set forecastHorizon(horizon: number) { this._forecastHorizon = horizon; this.forecastUntilDatePicked = this._dateArray[this.forecastUntilDateIdx]; }
  public get forecastHorizon() { return this._forecastHorizon; }

  /* Date index getters */
  public get dataStartDateIdx() { return this.dataStartDateIndex; }
  public get testStartDateIdx() { return this.testStartDateIndex; }
  public get forecastStartDateIdx() { return this.forecastStartDateIndex; }
  public get forecastUntilDateIdx() { return this.forecastStartDateIndex + this.forecastHorizon - 1; }

  public get minTestSetSize() { return ForecastUtils.testSetMin(this.activeObservationCount, this.forecastHorizon, this.expandingWindowInt); }
  public get maxTestSetSize() { return ForecastUtils.testSetMax(this.activeObservationCount, this.maxLag, this.expandingWindowInt); }

  public get expandingWindowInt() { return this.expandingWindowEnabled ? this.recalcFrequency : 0; }

  // Values for display only
  public get testSetSize() { return this.forecastStartDateIdx - this.testStartDateIdx; }
  public get trainSetSize() { return (this.oosEnabled ? this.testStartDateIdx : this.forecastStartDateIdx) - this.dataStartDateIdx; }

  public get earliestDataStartDateIdx() { return 0; }
  public get latestDataStartDateIdx() { return this.oosEnabled ? this.forecastStartDateIdx - this.MIN_OBS_OOS : this.forecastStartDateIdx - this.MIN_OBS; }
  public get earliestTestStartDateIdx() { return this.forecastStartDateIdx - this.maxTestSetSize; }
  public get latestTestStartDateIdx() { return this.forecastStartDateIdx - this.minTestSetSize; }
  public get earliestForecastStartDateIdx() { return this.oosEnabled ? this.dataStartDateIdx + this.MIN_OBS_OOS : this.dataStartDateIdx + this.MIN_OBS; }
  public get earliestForecastUntilDateIdx() { return this.forecastStartDateIdx; }
  public get latestForecastUntilDateIdx() { return this.forecastStartDateIdx + this.maxForecastHorizon - 1; }
  public get activeObservationCount() { return this.forecastStartDateIdx - this.dataStartDateIdx; }

  /* Date indices */
  dataStartDateIndex: number;
  testStartDateIndex: number;
  forecastStartDateIndex: number;
  latestForecastStartDateIdx: number;

  /* Dates for date pickers (must be explicit objects to not break the angular date picker) */
  dataStartDatePicked: moment.Moment;
  testStartDatePicked: moment.Moment;
  forecastStartDatePicked: moment.Moment;
  forecastUntilDatePicked: moment.Moment;

  /* Data */
  _dateArray: moment.Moment[] = [];
  _dateArrayDate: Date[] = [];
  pending = false;

  /* Dragbar d3 objects */
  slider: DateRangeSlider;
  limits: DateRangeLimits;

  // Input from user
  activePriority: 'horizon' | 'lag' = 'horizon';
  _forecastHorizon: number;
  maxLag: number;
  maxMaxLag: number;
  testSize: number;
  oosEnabled: boolean;
  maxForecastHorizon: number = 1;
  recalcFrequencyOptions = Array.from(Array(RW_MAX_RECALCULATION_FREQUENCY), (_x, i) => i + 1);
  recalcFrequency: number = 3;
  expandingWindowEnabled: boolean = false;

  public get disabledOOSToggleTippy() {
    if (!this.canUpdateForecast) {
      return 'You do not have permission to change this setting.';
    } else if (this.activeObservationCount < this.MIN_OBS_OOS) {
      return `A minimum of ${this.MIN_OBS_OOS} observations are needed to enable this setting.`;
    } else { return null; }
  }

  public ngOnInit() {
    this.initialize();
  }

  public initialize() {
    document.querySelector('div#date-range').innerHTML = '';
    this.initFromFVersion();
    this.setMaxLagAndHorizon();
    this.setDefaultState();
    this.setTestSize();
    this.initSlider();
  }

  private initFromFVersion() {
    this._dateArray = this.fVersion.allDatesArray;
    this._dateArrayDate = this._dateArray.map(x => x.toDate());
    this.oosEnabled = this.fVersion.OOSEnabled;
    this.recalcFrequency = this.fVersion.ModelRollingWindowRecalc;
    this.expandingWindowEnabled = this.fVersion.UseModelRollingWindow;
    this.latestForecastStartDateIdx = this.dateToIndex(DateUtils.newMoment(this.fVersion.LatestForecastStartDate));
    this.forecastStartDateIdx = this.dateToIndex(DateUtils.newMoment(this.fVersion.StartDate));
    this.dataStartDateIdx = !!this.fVersion.DataStartDate
      ? this.dateToIndex(DateUtils.newMoment(this.fVersion.DataStartDate))
      : 0;

    if (this.fVersion.TestStartDate != null) {
      this.testStartDateIdx = this.dateToIndex(DateUtils.newMoment(this.fVersion.TestStartDate));
    } else {
      this.testSize = this.recommendedTestSize;
    }

    this.forecastHorizon = this.fVersion.Horizon;
    this.maxLag = this.fVersion.MaxLag;
    const maxLagHorizon = ForecastUtils.getLagAndHorizon(this.maxLag, this.fVersion.Horizon, this.activeObservationCount, this.expandingWindowInt, this.oosEnabled);
    this.maxForecastHorizon = Math.min(this.fVersion.MaxHorizon, maxLagHorizon.MaxHorizon);
    this.maxMaxLag = Math.min(maxLagHorizon.MaxLag, 12);
  }

  private initSlider() {
    const sliderOptions: DateRangeSliderOptions = {
      length: this._dateArray.length,
      startIdx: this.dataStartDateIdx,
      oosBarEnabled: this.oosEnabled,
      testSize: this.testSize,
      endIdx: this.forecastStartDateIdx,
      forecastHorizon: this.forecastHorizon,
      firstDate: this._dateArray[0].format('YYYY-MM-DD'),
      lastDate: this._dateArray.last().format('YYYY-MM-DD'),
      periodicity: this.fVersion.Periodicity,
      maxLag: this.fVersion.MaxLag,
      maxEndIdx: this.latestForecastStartDateIdx,
      testStartIdx: this.testStartDateIdx,
      width: this.options.width || 750,
      maxHorizon: this.fVersion.MaxHorizon,
      disabled: !this.canUpdateForecast
    };

    this.limits = {
      minStartIdx: 0,
      maxStartIdx: this.latestDataStartDateIdx,
      minTestIdx: this.earliestTestStartDateIdx,
      maxTestIdx: this.latestTestStartDateIdx,
      minEndIdx: this.earliestForecastStartDateIdx,
      expandingWindowInt: this.expandingWindowInt
    };

    // Create the date range slider and attach it to the DOM
    this.slider = new DateRangeSlider(sliderOptions, this.limits);
    this.slider.changeEmitter.subscribe((state: DataRangeSliderOutput) => {
      this.sliderStateChanged(state);
    });

    document.querySelector('div#date-range').append(this.slider.elems.svg.node());
  }

  private idxToDate(idx: number) { return this._dateArrayDate[idx]; }
  private idxToMoment(idx: number) { return this._dateArray[idx]; }

  private updateSliderLimits() {
    this.limits = {
      minStartIdx: 0,
      maxStartIdx: this.latestDataStartDateIdx,
      minTestIdx: this.earliestTestStartDateIdx,
      maxTestIdx: this.latestTestStartDateIdx,
      minEndIdx: this.earliestForecastStartDateIdx,
      expandingWindowInt: this.expandingWindowInt
    };
    this.slider.setLimits(this.limits);
  }

  private sliderStateChanged(state: DataRangeSliderOutput) {
    if (this.dataStartDateIdx !== state.startIdx) {
      this.onDataStartDate(this._dateArray[state.startIdx]);
    }
    else if (this.testStartDateIdx !== state.testIdx) {
      this.onTestStartDate(this._dateArray[state.testIdx]);
    }
    else if (this.forecastStartDateIdx !== state.endIdx) {
      this.onForecastStartDate(this._dateArray[state.endIdx]);
    }
  }

  public setMaxLagAndHorizon(prioritizeHorizon: boolean = true) {
    this.activePriority = prioritizeHorizon ? 'horizon' : 'lag';
    const lagAndHorizon = ForecastUtils.getLagAndHorizon(this.maxLag, this.forecastHorizon, this.activeObservationCount, this.expandingWindowInt, this.oosEnabled, prioritizeHorizon);
    this.maxForecastHorizon = Math.min(lagAndHorizon.MaxHorizon, this.fVersion.MaxHorizon);
    this.forecastHorizon = lagAndHorizon.Horizon;
    this.maxLag = lagAndHorizon.Lag;
    this.maxMaxLag = Math.min(lagAndHorizon.MaxLag, 12);
  }

  // Modify testStartDate such that it conforms to the rules set up for it.
  private sanitizeTestStartDate() {
    if (this.testStartDateIndex == null) {
      this.testStartDateIndex = this.recommendedTestSize * this.activeObservationCount / 100;
    }
    if (this.testStartDateIndex > this.latestTestStartDateIdx) {
      this.testStartDateIndex = this.latestTestStartDateIdx;
    }
    if (this.testStartDateIndex < this.earliestTestStartDateIdx) {
      this.testStartDateIndex = this.earliestTestStartDateIdx;
    }
  }

  public onDataStartDate(date: number | moment.Moment) {
    date = this.momentToIndex(date);
    this.dataStartDateIdx = date;
    this.setMaxLagAndHorizon();
    this.sanitizeTestStartDate();
    this.setTestSize();

    // Update slider
    this.slider.setStartIdx(date);
    this.slider.setTestStartIdx(this.testStartDateIndex);
    this.updateSliderLimits();

    this.emitChanges();
  }

  public onTestStartDate(date: number | moment.Moment) {
    date = this.momentToIndex(date);
    this.testStartDateIdx = date;
    this.setMaxLagAndHorizon();
    this.setTestSize();

    // Update slider
    this.slider.setTestStartIdx(date);
    this.updateSliderLimits();

    this.emitChanges();
  }

  public onForecastStartDate(date: number | moment.Moment) {
    date = this.momentToIndex(date);
    this.forecastStartDateIdx = date;
    this.setMaxLagAndHorizon();
    this.sanitizeTestStartDate();
    this.setTestSize();

    // Update slider
    this.slider.setEndIdx(date);
    this.slider.setTestStartIdx(this.testStartDateIdx);
    this.updateSliderLimits();

    this.emitChanges();
  }

  public onForecastUntilDate(horizon: number | moment.Moment) {
    if (moment.isMoment(horizon)) {
      horizon = this.dateToIndex(horizon) - this.forecastStartDateIdx + 1;
    }

    this.forecastHorizon = horizon as number;
    this.setMaxLagAndHorizon();
    this.sanitizeTestStartDate();

    // Update slider
    this.slider.setForecastHorizon(this.forecastHorizon);
    this.slider.setTestStartIdx(this.testStartDateIdx);
    this.updateSliderLimits();

    this.emitChanges();
  }

  public setTestSize() {
    if (this.oosEnabled) {
      this.testSize = Math.round((this.testSetSize / this.activeObservationCount) * 100);
    }
  }

  /* This function is used by the owning modal: ForecastDateRangeModalComponent, which will call this save-func when user click on 'save'
     or the equivalent button. */
  public setChangesOnForecastVersion() {
    if (this.oosEnabled === false) {
      this.fVersion.TestStartDate = null;
    } else {
      this.fVersion.TestStartDate = DateUtils.alignJsDate(this.testStartDate, this.fVersion.Periodicity);
    }
    this.fVersion.OOSEnabled = this.oosEnabled;
    this.fVersion.DataStartDate = this.dataStartDateIdx !== 0
      ? DateUtils.alignJsDate(this.dataStartDate, this.fVersion.Periodicity)
      : null;
    this.fVersion.StartDate = DateUtils.alignJsDate(this.forecastStartDate, this.fVersion.Periodicity);
    this.fVersion.Horizon = this.forecastHorizon;
    this.fVersion.MaxLag = this.maxLag;
    this.fVersion.UseModelRollingWindow = this.expandingWindowEnabled;
    this.fVersion.ModelRollingWindowRecalc = this.recalcFrequency;
  }

  public getUpdateDTO() {
    return {
      TestStartDate: this.oosEnabled ? DateUtils.alignJsDate(this.testStartDate, this.fVersion.Periodicity) : null,
      DataStartDate: this.dataStartDateIdx !== 0 ? DateUtils.alignJsDate(this.dataStartDate, this.fVersion.Periodicity) : null,
      StartDate: DateUtils.alignJsDate(this.forecastStartDate, this.fVersion.Periodicity),
      Horizon: this.forecastHorizon,
      MaxLag: this.maxLag,
      UseModelRollingWindow: this.expandingWindowEnabled,
      ModelRollingWindowRecalc: this.recalcFrequency,
      OOSEnabled: this.oosEnabled,
    };
  }

  /* This function is used by the owning modal: ForecastDateRangeModalComponent, which will call this save-func when the user clicks on 'save'
     or the equivalent button. */
  public saveChanges(forecastVersion: ForecastVersionModel) {
    return this.forecastService.updateForecastVersion(forecastVersion, this.getUpdateDTO())
      .catch(err => {
        this.setDefaultState();
        return err;
      });
  }

  public toggleOOSEnabled() {
    this.oosEnabled = !this.oosEnabled;
    if (!this.oosEnabled) { this.expandingWindowEnabled = false; }
    this.setMaxLagAndHorizon();
    this.sanitizeTestStartDate();
    this.setTestSize();

    // Update slider
    this.slider.setOosBarEnabled(this.oosEnabled, this.testStartDateIdx);
    this.updateSliderLimits();

    this.emitChanges();
  }

  public toggleExpandingWindow() {
    this.expandingWindowEnabled = !this.expandingWindowEnabled;
    this.sanitizeTestStartDate();
    this.onTestStartDate(this.testStartDateIdx);
    this.emitChanges();
  }

  public selectRecalculationFrequency(value: string) {
    this.recalcFrequency = +value;
    this.sanitizeTestStartDate();
    this.onTestStartDate(this.testStartDateIdx);
    this.emitChanges();
  }

  // #region Max lag

  public setLag(lag: number) {
    this.maxLag = Math.min(lag, this.maxMaxLag);
    this.setMaxLagAndHorizon(false);
    this.sanitizeTestStartDate();

    this.updateSliderLimits();
    this.slider.setTestStartIdx(this.testStartDateIdx);

    this.emitChanges();
  }

  public decreaseLag() {
    if (!this.canUpdateForecast) { return; }
    this.setLag(Math.max(this.maxLag - 1, 1));
  }

  public increaseLag() {
    if (!this.canUpdateForecast) { return; }
    this.setLag(Math.min(this.maxLag + 1, 12));
  }
  // #endregion

  // #region Horizon
  public decreaseHorizon() {
    if (!this.canUpdateForecast) { return; }
    this.onForecastUntilDate(Math.max(--this.forecastHorizon, 1));
  }

  public increaseHorizon() {
    if (!this.canUpdateForecast) { return; }
    this.onForecastUntilDate(Math.min(++this.forecastHorizon, this.maxForecastHorizon));
  }
  // #endregion

  // #region Date helpers

  /* Convert a given date or moment to an index, from the _dateArray */
  private dateToIndex(m: Date | moment.Moment) {
    if (moment.isMoment(m)) { m = m.toDate(); }
    const idx = this._dateArray.findIndex(x => DateUtils.newMoment(m as Date).isSame(x, this.momentUnit));
    return idx === -1 ? 0 : idx;
  }

  /* Convert a moment to a valid index-input (used by functions accepting both an index or a moment) */
  private momentToIndex(date: moment.Moment | number) {
    if (moment.isMoment(date)) { date = this.dateToIndex(date); }
    return date;
  }

  // #endregion

  // #region Modal state 'changes' checker
  private setDefaultState() { this.modalState.setState('fc', this.getFVersionSettings()); }

  private getFVersionSettings() {
    return {
      TestStartDate: this.oosEnabled ? this.testStartDate : null,
      DataStartDate: this.dataStartDate,
      StartDate: this.forecastStartDate,
      Horizon: this.forecastHorizon,
      MaxLag: this.maxLag,
      UseModelRollingWindow: this.expandingWindowEnabled,
      ModelRollingWindowRecalc: this.recalcFrequency
    };
  }

  private emitChanges() {
    this.changed.emit(this.modalState.isChanged('fc', this.getFVersionSettings()));
  }
  // #endregion
}
