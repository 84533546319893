declare global {

  interface Object {

    /**
     * Map to object, from object
     * @returns {T}
     * @memberof Object
     * @param to
     * @param from
     */
    faMapTo<T, F>(to: T, from: F): T;
    faJoin(glue: string, separator: string);
    faKeyValueArray();
    faRemoveByMatch(match: string);
  }
}

export class ObjectExtensions {

  public static Extend() {
    ObjectExtensions.addMapToFn();
    ObjectExtensions.addJoinFn();
    ObjectExtensions.addKeyValyeArrayFn();
    ObjectExtensions.addRemoveKeyMatchingFn();
  }

  private static mapThis<T extends object>(obj1: T, obj2: Object) {
    Object.keys(obj2).forEach(key => {
      if (key in obj1 || key in obj1['__proto__']) {
        try {
          obj1[key] = obj2[key];
        } catch (error) { }
      }
    });
    return obj1;
  }

  private static joinThis(glue: string, separator: string) {
    return Object.getOwnPropertyNames(this).map(name => [name, this[name]].join(glue)).join(separator);
  }

  private static keyValueArrayThis() {
    const o = {};
    Object.keys(this).forEach(key => {
      if (!Array.isArray(this[key])) {
        o[key] = [this[key]];
      }
    });
    return o;
  }

  private static removeKeyMatchingThis(str: string) {
    return removeFromObject(this, str);

    function removeFromObject(obj, match: string) {
      const reg = new RegExp(match, 'i');
      Object.keys(obj).forEach(property => {
        if (property.match(reg)) {
          return delete obj[property];
        }

        if (obj.hasOwnProperty(property) && obj[property] !== null) {
          if (Array.isArray(obj[property])) {
            for (let i = 0; i < obj[property].length; i++) {
              removeFromObject(obj[property][i], match);
            }
          } else if (typeof obj[property] === 'object') {
            removeFromObject(obj[property], match);
          }
        }
      });
      return obj;
    }
  }

  private static addMapToFn() {
    if (!Object.prototype.faMapTo) {
      Object.defineProperty(
        Object.prototype,
        'faMapTo',
        <PropertyDescriptor> {
          value: this.mapThis,
          writable: false,
          enumerable: false,
          configurable: false
        }
      );
    }
  }

  private static addJoinFn() {
    if (!Object.prototype.faJoin) {
      Object.defineProperty(
        Object.prototype,
        'faJoin',
        <PropertyDescriptor> {
          value: this.joinThis,
          writable: false,
          enumerable: false,
          configurable: false
        }
      );
    }
  }

  private static addKeyValyeArrayFn() {
    if (!Object.prototype.faKeyValueArray) {
      Object.defineProperty(
        Object.prototype,
        'faKeyValueArray',
        <PropertyDescriptor> {
          value: this.keyValueArrayThis,
          writable: false,
          enumerable: false,
          configurable: false
        }
      );
    }
  }

  private static addRemoveKeyMatchingFn() {
    if (!Object.prototype.faRemoveByMatch) {
      Object.defineProperty(
        Object.prototype,
        'faRemoveByMatch',
        <PropertyDescriptor> {
          value: this.removeKeyMatchingThis,
          writable: false,
          enumerable: false,
          configurable: false
        }
      );
    }
  }
}
