import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';

import { HistoricEventMapper } from './historic-event.mapper';
import { HistoricBaseEventDTO } from './models/base/historic-base-event.dto';
import { HistoricBaseEventModel, HistoricEventModel } from './models/base/historic-event.model';
import { ImportedHistoricBaseEventDTO } from './models/imported/imported-historic-base-event.dto';
import { ImportedHistoricBaseEventModel } from './models/imported/imported-historic-base-event.model';

@Injectable({
  providedIn: 'root'
})
export class HistoricEventBackendService {

  constructor(
    private http: HttpService,
    private mapper: HistoricEventMapper
  ) { }

  ///
  /// BASE PART
  ///
  public getHistoricBaseEvents(companyId: string) {
    return this.http.get<HistoricBaseEventDTO[]>(`company/${companyId}/event-groups`)
      .then(res => res.body.map(e => this.mapper.mapBase(e)));
  }

  public createBaseEvent(event: HistoricBaseEventModel) {
    const dto = this.mapper.toCreateBase(event);
    return this.http.post<HistoricBaseEventDTO>(`company/${event.CompanyId}/event-groups`, dto)
      .then(resp => this.mapper.mapBase(resp.body));
  }

  public updateBaseEvent(event: HistoricBaseEventModel) {
    const dto = this.mapper.toUpdateBase(event);
    return this.http.put<HistoricBaseEventDTO>(`company/${event.CompanyId}/event-groups`, dto)
      .then(resp => this.mapper.mapBase(resp.body));
  }

  public deleteBaseEvent(event: HistoricBaseEventModel) {
    return this.http.delete<null>(`company/${event.CompanyId}/event-groups/${event.HistoricBaseEventId}`)
      .then(_ => event.HistoricBaseEventId);
  }

  ///
  /// EVENT PART
  ///
  public deleteEvent(event: HistoricEventModel, companyId: string, baseEventId: string) {
    return this.http.delete<HistoricBaseEventDTO>(`company/${companyId}/event-groups/${baseEventId}/events/${event.HistoricEventId}`);
  }

  ///
  /// FORECAST PART
  ///
  public getHistoricEventsInForecastVersion(forecastVersionId: string) {
    return this.http.get<ImportedHistoricBaseEventDTO[]>(`forecast-version/${forecastVersionId}/event-groups`)
      .then(res => res.body.map(x => this.mapper.mapImportedBase(x)));
  }

  public addEventToForecast(event: ImportedHistoricBaseEventModel) {
    const dto = this.mapper.toImportToForecastDTO(event);
    return this.http.post<ImportedHistoricBaseEventDTO>(`forecast-version/${event.ForecastVersionId}/event-groups`, dto)
      .then(resp => this.mapper.mapImportedBase(resp.body));
  }

  public removeEventFromForecast(event: ImportedHistoricBaseEventModel) {
    return this.http.delete<null>(`forecast-version/${event.ForecastVersionId}/historic-events/${event.ImportedHistoricBaseEventId}`)
      .then(_ => event.ImportedHistoricBaseEventId);
  }

  public updateEventInForecast(event: ImportedHistoricBaseEventModel) {
    const dto = this.mapper.toUpdateInForecastDTO(event);
    return this.http.put<ImportedHistoricBaseEventDTO>(`forecast-version/${event.ForecastVersionId}/event-groups`, dto)
      .then(resp => this.mapper.mapImportedBase(resp.body));
  }
}
