import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

@Component({
  selector: 'indicio-mb-select-category',
  template: `
      <mat-form-field>
        <mat-label *ngIf="!showLoader">Select category</mat-label>
        <mat-label *ngIf="showLoader"> <i class="ion-load-c spinner"></i> Loading category options...</mat-label>
        <mat-select [value]="currentCategory"
                    (selectionChange)="newCategorySelected.emit($event.value)"
                    required>
          <mat-option [value]="addCategoryValue"
                      (click)="addNewCategory()">{{ addCategoryValue }}</mat-option>
          <mat-option [value]="cat"
                      *ngFor="let cat of categories">{{ cat }}</mat-option>
        </mat-select>
      </mat-form-field>
  `
})
export class MacrobondSelectCategoryComponent {

  public addCategoryValue = '* Add new category *';

  @Input() categories: string[] = [];
  @Input() currentCategory: string;
  @Input() showLoader: boolean = true;

  @Output() newCategory = new EventEmitter<string>();
  @Output() newCategorySelected = new EventEmitter<string>();

  constructor(
    private dialogService: DialogService
  ) {
  }

  public addNewCategory() {
    this.newCategorySelected.emit('-1');
    setTimeout(() => this.newCategorySelected.emit(null), 0);
    var ref = this.dialogService.openTextInputDialog({
      Label: 'Category name',
      Title: 'Add new category',
      Text: 'The category will be created as an in-house category in your macrobond account.',
      Value: ''
    });
    ref.subscribe(ans => {
      if (!!ans) {
        this.newCategory.emit(ans);
        this.newCategorySelected.emit(ans);
      }
    });
  }
}
