import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HistoricValueDTO } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastBenchmarkModel } from '@core/store/source-variable/forecast-benchmark.model';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { StepAhead } from '@shared/components/step-ahead/step-ahead';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { StateUtils } from '@shared/utils/state.utils';
import { CreateOrEditBenchmarkDialogData } from './create-or-edit-benchmark.dialog.options';

@Component({
  selector: 'indicio-create-or-edit-benchmark-dialog',
  templateUrl: 'create-or-edit-benchmark.dialog.html',
})
export class CreateOrEditBenchmarkDialogComponent {
  public static Id: string = 'CreateOrEditBenchmarkDialogComponent';

  // Fetched entitites
  public benchmark: ForecastBenchmarkModel;
  public fvar: ForecastVariableModel;

  // frontend stuffz
  private modalState = new StateUtils.StateHelper();
  public stepAhead: StepAhead.Model;
  public edit: boolean;
  public isLoading = true;
  public pending = false;

  public get isChanged() { return this.modalState.isChanged('bench', this.benchmark) || !this.stepAhead.isModified(); }

  constructor(
    public dialogRef: MatDialogRef<CreateOrEditBenchmarkDialogComponent>,
    public env: EnvironmentService,
    private dialog: DialogService,
    private sourceService: SourceVariableFrontendService,
    private status: StatusService,
    private fvarService: ForecastVariableFrontendService,
    @Inject(MAT_DIALOG_DATA) private data: CreateOrEditBenchmarkDialogData
  ) {
    this.edit = !!data.ForecastBenchmarkId;
    Promise.all(([[
      this.syncFvar(),
      this.syncBench()
    ].filter(x => x !== null)
    ]))
      .then(() => this.setup())
      .finally(() => this.isLoading = false);
  }

  public onNoClick(): void {
    this.close();
  };

  public restoreValues() {
    this.stepAhead.restore(false);
  }

  public close() {
    this.dialogRef.close(true);
    this.dialog.openListAllBenchmarks({
      ForecastVariableId: this.data.ForecastVariableId,
      ForecastVersionId: this.data.ForecastVersionId,
      SourceVariableId: this.data.SourceVariableId
    });
  }

  public create() {
    this.pending = true;
    this.benchmark.Values = <HistoricValueDTO[]> this.stepAhead.getSetValues();
    this.sourceService.createForecastBenchmark(this.benchmark, this.data.ForecastVersionId)
      .then(() => {
        this.close();
        this.status.setMessage('Benchmark created', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
      })
      .finally(() => this.pending = false);
  }

  public update() {
    this.pending = true;
    this.benchmark.Values = <HistoricValueDTO[]> this.stepAhead.getSetValues();
    this.sourceService.updateForecastBenchmark(this.benchmark)
      .then(() => {
        this.close();
        this.status.setMessage('Benchmark updated', 'Success', true);
      })
      .catch(error => {
        this.status.setError(error, true);
      })
      .finally(() => this.pending = false);
  }

  private setup() {
    this.stepAhead = StepAhead.MapFromForecastVariable(this.fvar);
    if (this.data.ForecastBenchmarkId) {
      const thisDates = this.stepAhead.dates.slice();
      this.stepAhead.dates = this.benchmark.Values.map(x => x.D);
      if (this.stepAhead.dates.length < thisDates.length) {
        const missingCount = thisDates.length - this.stepAhead.dates.length;
        this.stepAhead.dates.push(...thisDates.slice(-missingCount));
      }
      this.benchmark.Values.forEach((v, i) => {
        this.stepAhead.setStep(i, v.V / this.stepAhead.factor.factor);
      });
      this.stepAhead.setSaved();
    } else {
      this.benchmark = new ForecastBenchmarkModel();
      this.benchmark.SourceVariableId = this.data.SourceVariableId;
    }
    this.modalState.setState('bench', this.benchmark);
  }

  private syncFvar() {
    return this.fvarService.getOrFetchById(this.data.ForecastVersionId, this.data.ForecastVariableId)
      .then(fvar => this.fvar = fvar)
      .catch(err => this.status.setError(err, true));
  }

  private syncBench() {
    return !this.data.ForecastBenchmarkId
      ? null
      : this.sourceService.getOrFetchForecastBenchmark(this.data.ForecastBenchmarkId)
        .then(b => this.benchmark = b)
        .catch(err => this.status.setError(err, true));
  }
}
