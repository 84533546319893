<indicio-dialog>
  <h1 mat-dialog-title>Create new request</h1>
  <div mat-dialog-content
       *ngIf="initialized">
    <indicio-modal-option-row optionTitle="Request name"
                              optionSubtitle="Max 40 characters, no spaces, only letters (A-Z) and numbers (0-9)"
                              textWidth="6"
                              optionsWidth="6">
      <mat-form-field style="margin-top: 20px">
        <input matInput
               [indicio-autofocus]="true"
               pattern="^[a-zA-Z0-9]{1,40}$"
               maxlength="40"
               (input)="setTitle($event.target.value)"
               (blur)="setTitle($event.target.value)"
               [value]="title"
               required />
      </mat-form-field>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Recurrance"
                              optionSubtitle="How often should this request be run?"
                              textWidth="6"
                              optionsWidth="6">
      <mat-form-field style="margin-top: 15px;">
        <mat-select [(value)]="recurrence"
                    required>
          <mat-option [value]="mode.Value"
                      [title]="mode.Description"
                      *ngFor="let mode of recurrenceOptions">{{ mode.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Start date"
                              optionSubtitle="What date should the request start?"
                              textWidth="6"
                              optionsWidth="6">
      <div style="margin-top: 30px;">
        <indicio-date-picker periodicity="day"
                             [value]="startDate"
                             [min]="minDate"
                             (selectDateEvent)="chosenStartdate($event)">
        </indicio-date-picker>
      </div>
    </indicio-modal-option-row>

    <indicio-modal-option-row optionTitle="Time of day"
                              optionSubtitle="What time of day should the request be run?"
                              textWidth="6"
                              optionsWidth="6">
      <div style="margin-top: 30px;">
        <input type="time"
               name="timeOfDay"
               [(ngModel)]="timeOfDay"
               required />
        {{data.timeZone}}
      </div>
    </indicio-modal-option-row>

    <div class="tickers box">
      <ng-container *ngTemplateOutlet="exCodeAndMarketTemplate"></ng-container>
      <div class="inputs">
        <div class="center-vertical">Search instrument:</div>
        <div>
          <ng-container *ngTemplateOutlet="instrumentSearchTemplate"></ng-container>
        </div>
        <button mat-raised-button
                color="accent"
                [disabled]="!selectedInstrumentString || instruments.includes(selectedInstrumentString)"
                (click)="addInstrument(); instrumentCtrl.setValue('');">Add</button>
      </div>
      <div class="list">
        <ng-container *ngIf="instruments.length; else noTickers">
          <span class="bold">Added instruments:</span>
          <ul>
            <li *ngFor="let ticker of instruments">
              <span class="pointer removable"
                    (click)="removeInstrument(ticker)">{{ ticker }}</span>
            </li>
          </ul>
        </ng-container>
        <ng-template #noTickers>
          <div class="center-vertical"
               style="color: var(--indicio-modal-accent-color, #8f8f8f)">
            No instruments added
          </div>
        </ng-template>
        <div class="center-vertical pointer"
             style="margin-top: 10px"
             (click)="openAddManualTicker()">
          + Add manual
        </div>
      </div>
    </div>

    <div class="tickers box margin-top">
      <div class="inputs">
        <div class="center-vertical">Search fields:</div>
        <div>
          <ng-container *ngTemplateOutlet="fieldSearchTemplate"></ng-container>
        </div>
        <button mat-raised-button
                [disabled]="!selectedFieldString || fields.includes(selectedFieldString)"
                color="accent"
                (click)="addField(fieldCtrl.value); fieldCtrl.setValue('');">
          Add
        </button>
      </div>
      <div class="list">
        <ng-container *ngIf="fields.length; else noFields">
          <span class="bold">Added fields:</span>
          <ul>
            <li *ngFor="let field of fields">
              <span class="pointer removable"
                    (click)="removeField(field)">{{ field }}</span>
            </li>
          </ul>
        </ng-container>
        <ng-template #noFields>
          <div class="center-vertical"
               style="color: var(--indicio-modal-accent-color, #8f8f8f)">
            No fields added
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Cancel</button>
    <div [indicioTippy]="!canSave ? 'A title and at least one instrument and field are required.' : null">
      <button mat-flat-button
              color="primary"
              [disabled]="!canSave || pending"
              (click)="createRequest()">
        <span *ngIf="!pending">Create</span>
        <indicio-spinner *ngIf="pending"></indicio-spinner>
      </button>
    </div>
  </div>
</indicio-dialog>

<ng-template #exCodeAndMarketTemplate>
  <div class="grid-system"
       style="--grid-template: 1fr 1fr; gap: 10px; padding: 0 10px">

    <indicio-searchable-dropdown [label]="'Select exchance code'"
                                 [required]="true"
                                 [optionDisplay]="'Display'"
                                 [optionTitle]="'Description'"
                                 [values]="exchangeCodes"
                                 [initializeWithFirstValue]="false"
                                 [selectedValue]="selectedExchange"
                                 (valueChangeEvent)="setSelectedExchange($event)">
    </indicio-searchable-dropdown>

    <indicio-searchable-dropdown [label]="'Select market sector'"
                                 [required]="true"
                                 [values]="marketSectors"
                                 [initializeWithFirstValue]="false"
                                 [selectedValue]="selectedMarket"
                                 (valueChangeEvent)="setSelectedMarket($event)">
    </indicio-searchable-dropdown>

  </div>
</ng-template>

<ng-template #instrumentSearchTemplate>
  <form>
    <mat-form-field>
      <input matInput
             [matAutocomplete]="auto"
             [formControl]="instrumentCtrl" />
      <indicio-spinner color="var(--indicio-modal-text-color, black)"
                       *ngIf="instrumentLoading"
                       matSuffix></indicio-spinner>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="formatInstrumentOption($event.option.value)"
                        panelClass="fitted-select">
        <mat-option [value]="instrument"
                    style="line-height: 14px;"
                    *ngFor="let instrument of instrumentStates">
          <span>{{instrument.Name}}</span> | <small>{{instrument.ExchangeCode}}
          </small> | <small>{{ instrument.MarketSector }}</small>
          <br>
          <small>Ticker: {{ instrument.Ticker }}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</ng-template>

<ng-template #fieldSearchTemplate>
  <form>
    <mat-form-field>
      <input matInput
             [matAutocomplete]="autoField"
             [formControl]="fieldCtrl" />
      <indicio-spinner color="var(--indicio-modal-text-color, black)"
                       *ngIf="fieldLoading"
                       matSuffix></indicio-spinner>
      <mat-autocomplete #autoField="matAutocomplete"
                        (optionSelected)="formatFieldOption($event.option.value)">
        <mat-option [value]="field"
                    [title]="field.Mnemonic"
                    *ngFor="let field of fieldStates">
          <span>{{field.Mnemonic}}</span><br>
          <small style="color: var(--mainModalAltTextColor, gray); margin-top: -10px; display: block;">{{
            field.CleanName }}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</ng-template>
