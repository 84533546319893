import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';

export class FredTagModel implements IHasModelId {
  @Def() name: string;
  @Def() group_id: string;
  @Def() notes: string;
  @Def() created: string;
  @Def() popularity: number;
  @Def() series_count: number;
  getModelId() { return this.name; }
}
