import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssessmentFrontendService } from '@core/store/assessment/assessment.frontend.service';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { GraphThemeModel } from '@core/store/client/graph-theme.model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { StatModelMapper } from '@core/store/stat-model/stat-model.mapper';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Store } from '@ngxs/store';
import { ALGLineModel, ALGSingleSeriesModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { ALGTypes } from '@shared/components/line-graph/alg-types';
import { AlgChartStyles, AlgOptions } from '@shared/components/line-graph/alg.options';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogGraphThemesComponent } from '@shared/modules/dialogs/graph-themes/dialog-graph-themes.dialog';
import { FileUtils } from '@shared/utils/file.utils';
import { logoIndicioSVGgray } from '@shared/utils/logo.utils';
import { saveSvgAsPng } from 'save-svg-as-png';
import { ChartDownloadModalOpts } from './chart-download-modal.options';
import { ChartDownloadSettingsModel } from './chart-download-settings.model';


@Component({
  selector: 'indicio-chart-download-modal',
  templateUrl: './chart-download-modal.component.html',
  styleUrls: ['./chart-download-modal.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ChartDownloadModalComponent extends ModalModelComponent {

  private options: ChartDownloadModalOpts;

  forecast: ForecastModel;
  forecastVersion: ForecastVersionModel;
  assessments: AssessmentModel[] = [];
  historicLine: ALGLineModel;
  models: ALGSingleSeriesModel[];
  type: ALGTypes.Graph;
  userDataSettings: ChartDownloadSettingsModel;
  header: string;
  subheader: string;
  singleVariable: ALGSingleSeriesModel[];
  ModalView = 'backgroundColor';
  selectedTheme: GraphThemeModel;
  Lines: ALGLineModel[];
  periodicity: PeriodicityType;

  algOptions: AlgOptions.Options;

  chartStyles: AlgChartStyles = {
    backgroundColor: 'rgba(243,243,243,0)',
    graphOverlay: 'var(--indicio-alg-overlay-color, rgba(0,0,0,0.05))',
    historyLine: 'var(--indicio-alg-historic-line-color, rgba(99,136,208, 1))',
    axisXline: 'var(--indicio-alg-axis-line-color, rgba(255,255,255,1))',
    axisYline: 'var(--indicio-alg-axis-line-color, rgba(255,255,255,1))',
    axisText: 'var(--indicio-modal-text-color, rgba(0,0,0,1))',
    borderColor: 'var(--indicio-alg-border-color, rgba(196,196,196,1))',
    borderSize: 2,
    borderRadius: 4,
    marginRight: 0,
    plotWidth: 760,
    plotHeight: 450
  };

  themes: GraphThemeModel[] = [];

  constructor(
    protected store: Store,
    protected uniMapper: StatModelMapper,
    private assessmentService: AssessmentFrontendService,
    public profileService: ProfileFrontendService,
    public dialog: MatDialog,
  ) { super(); }

  public setOptions(options: ChartDownloadModalOpts) {
    this.periodicity = options.periodicity;
    this.options = options;
    this.forecast = options.forecast;
    this.forecastVersion = options.forecastVersion;
    this.type = <ALGTypes.Graph> options.type;
    this.header = options.options.header;
    this.subheader = options.options.subheader;
    this.assessments = !!this.forecastVersion ? this.assessmentService.assessmentsInForecastVersion(this.forecastVersion?.ForecastVersionId) : [];

    if (this.type === 'univariate' || this.type === 'multivariate') {
      this.models = options.singleVariable;
    } else if (this.type === 'variable') {
      this.singleVariable = options.singleVariable;
      this.chartStyles.borderRadius = 0;
      this.chartStyles.borderSize = 1;
    } else if (this.type === 'scenario') {
      this.models = options.singleVariable;
    } else if (this.type === 'summary') {
      this.models = options.singleVariable;
    }

    this.models = options.models;
    this.userDataSettings = options.userDataSettings;
    this.Lines = options.Lines;
    this.historicLine = options.HistoricLine;

    this.setupAlgOptions();

    this.isLoading = false;
  }

  private setupAlgOptions() {
    if (this.options.options != null) {
      this.algOptions = this.options.options;
    } else {
      this.algOptions = AlgOptions.CreateOptions({
        noDataText: '',
        inModal: true,
        dates: this.algOptions.dates
      });
    }
    if (this.options.fromDate && this.options.toDate) {
      this.algOptions.fromDate = this.options.fromDate;
      this.algOptions.toDate = this.options.toDate;
    }

    this.algOptions.dontShowCIHover = !this.userDataSettings.showCIHover;
    this.chartStyles.marginRight = 20;

    if (this.header.startsWith('Scenario')) {
      this.algOptions.dontShowCIHover = true;
    }

    this.algOptions.axisConfig.yAxisPosition === 'floating' && (this.algOptions.axisConfig.yAxisPosition = 'outside');
    this.algOptions.menuConfig.showMenu = false;
  }

  public downloadSVG() {
    const parent = document.querySelector('.chart-download');
    const svg = parent.querySelector('.svg-content');
    const svgClone = svg.cloneNode(true) as Element;
    svgClone.setAttribute('style', 'fill: none');
    const p95 = svgClone.querySelector('.p95');
    const foreignObject = svgClone.querySelector('foreignObject');
    foreignObject.querySelectorAll('div').forEach((div) => {
      div.style.setProperty('font-family', 'sans-serif');
    });
    if (p95 != null) {
      const p75 = svgClone.querySelector('.p75');
      const p50 = svgClone.querySelector('.p50');
      p95.setAttribute('style', 'fill: rgba(226, 74, 74, 0.4)');
      p75.setAttribute('style', 'fill: rgba(226, 74, 74, 0.6)');
      p50.setAttribute('style', 'fill: rgba(255, 255, 255, 0.8)');
    }
    const imageElement = svgClone.querySelector('image');
    imageElement.outerHTML = '';
    const graphOuter = svgClone.querySelector('.graph-outer');
    graphOuter.innerHTML += logoIndicioSVGgray;
    var serializer = new XMLSerializer();
    var xmlString = serializer.serializeToString(svgClone);
    const svgBlob = new Blob([xmlString], { type: 'image/svg+xml;base64,charset=utf-8' });
    FileUtils.downloadBlob(svgBlob, this.header);
  }

  public saveImage() {
    const imageOptions = {
      encoderOptions: 1,
      left: -5,
      top: -20,
      scale: 2,
      right: 20,
      height: this.chartStyles.plotHeight + 40,
      backgroundColor: 'white',
    };
    saveSvgAsPng(document.querySelector('div.chart-download svg'), `${this.header}.png`, imageOptions);
  }

  public openSaveThemeDialog() {
    const dialogRef = this.dialog.open(DialogGraphThemesComponent, {
      width: '350px',
      data: { themes: this.profileService.graphThemes, currentTheme: this.chartStyles }
    });

    dialogRef.afterClosed().subscribe(selectedTheme => {
      if (!selectedTheme) { return; }
      this.setTheme(selectedTheme);
    });
  }

  setTheme(theme: GraphThemeModel) {
    this.chartStyles = Object.assign({}, this.chartStyles, theme.Theme);
    this.selectedTheme = theme;
  }

  public changeView(view) {
    this.ModalView = view;
  }

  public updateColor(property, color) {
    this.chartStyles[property] = color;
    this.chartStyles = Object.assign({}, this.chartStyles);
  }

  public updateNumber(event) {
    const property = event.target.name;
    this.chartStyles[property] = event.target.value;
    this.chartStyles = Object.assign({}, this.chartStyles);
  }
}
