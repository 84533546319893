<ng-container *ngIf="!authService.loginInProgress && (authService.loggedIn$ | async) === false; else loaderTemplate">
  <div class="login-container">
    <indicio-icon-and-text-logo [showIcon]="false"></indicio-icon-and-text-logo>
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="'email'">
        <ng-container *ngTemplateOutlet="usernameTemplate"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'password'">
        <ng-container *ngTemplateOutlet="passwordTemplate">
        </ng-container>
      </ng-container>
    </ng-container>
    <indicio-about-footer></indicio-about-footer>
  </div>
</ng-container>

<ng-template #usernameTemplate>
  <div class="signin-container">
    <h2 style="color: #000000cc">Sign in</h2>
    <div class="email-input">
      <input type="text"
             [(ngModel)]="username"
             [ngModelOptions]="{standalone: true}"
             (keyup)="next($event)"
             placeholder="Email"
             autocomplete="username"
             required>
      <span *ngIf="emailError">
        <span class="error-text">Not a valid email.</span>
      </span>
    </div>
    <button mat-raised-button
            color="primary"
            [disabled]="showLoader"
            class="next-button"
            (click)="next(null)">
      <ng-container *ngIf="!showLoader; else btnLoader">
        Next
      </ng-container>
    </button>
    <div class="signup">
      <span class="dotted-underline pointer bold"
            (click)="resetPassword()">Forgot password?</span>
    </div>
    <br>
    Don't have an account?
    <br>
    <div class="signup">
      <span class="dotted-underline pointer bold"
            (click)="requestAccess()">Sign up</span>
      or <span class="dotted-underline pointer bold"
            (click)="signup()">Use activation code</span>
    </div>
  </div>
</ng-template>

<ng-template #passwordTemplate>
  <div class="signin-container">
    <h2 style="color: #000000cc">Please enter your password</h2>
    <div class="email-input">
      <input type="password"
             [(ngModel)]="password"
             [ngModelOptions]="{standalone: true}"
             (keyup.enter)="login()"
             placeholder="Password"
             indicioAutoFocusInput
             autocomplete="current-password"
             required>
    </div>
    <button mat-raised-button
            color="primary"
            [disabled]="showLoader"
            class="next-button"
            (click)="login()">
      <ng-container *ngIf="!showLoader; else btnLoader">
        Login
      </ng-container>
    </button>
    <div class="signup">
      <span class="dotted-underline pointer bold"
            (click)="resetPassword()">Forgot password?</span>
      <br>
      <br>
      <span class="dotted-underline pointer bold"
            style="color: #838383"
            (click)="back()">Cancel login</span>
    </div>

  </div>
</ng-template>

<ng-template #btnLoader><i class="ion-load-c spinner"></i></ng-template>

<ng-template #loaderTemplate>
  <indicio-loading-profile [fromMacrobond]="fromMacrobond"></indicio-loading-profile>
</ng-template>
