import { Injectable } from '@angular/core';
import { IMemberModel } from '@core/interfaces/if-member';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { CompanyModel } from '@core/store/company/company.model';
import { EmployeeModel } from '@core/store/company/employee.model';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectMemberModel } from '@core/store/project/project-member.model';
import { ProjectModel } from '@core/store/project/project.model';

@Injectable({
  providedIn: 'root'
})
export class MemberMapper {

  constructor(
    private envService: EnvironmentService,
    private profileService: ProfileFrontendService,
  ) { }

  public mapProjectMember(from: ProjectMemberModel, project: ProjectModel): IMemberModel {
    const model: IMemberModel = {
      ClientId: from.ClientId,
      Email: from.Email,
      IsCurrentUser: from.Email === this.profileService.profile.Email,
      IsOwner: from.Email === project.OwnerEmail,
      MemberRole: this.envService.getProjectRole(from.ProjectRole),
      ProjectId: project.ProjectId,
      CompanyId: project.CompanyId,
      PendingSignup: from.PendingSignup,
      Expires: from.Expires,
      CreatedDate: from.CreatedDate,
      Disabled: from.Disabled
    };
    return model;
  }

  public mapCompanyEmployee(from: EmployeeModel, company: CompanyModel): IMemberModel {
    const model: IMemberModel = {
      ClientId: from.ClientId,
      Email: from.Email,
      IsCurrentUser: from.Email === this.profileService.profile.Email,
      IsOwner: false,
      MemberRole: this.envService.getCompanyRole(from.CompanyRole),
      CompanyId: company.CompanyId,
      ProjectId: null,
      PendingSignup: from.PendingSignup,
      Expires: from.Expires,
      CreatedDate: from.CreatedDate,
      twoFactorEnabled: from.twoFactorEnabled,
      Joined: from.Joined,
      Disabled: from.Disabled
    };
    return model;
  }

}
