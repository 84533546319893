<h1 mat-dialog-title>
  {{ !!data.WikiSlug ? 'Edit' : 'Create' }} wiki page
  <ng-container *ngIf="restored"><span class="orange cursor-pointer"
          (click)="removeCache()"
          title="Click to remove and restore saved copy"> (restored)</span></ng-container>
</h1>
<div mat-dialog-content
     style="height: calc(90vh - 150px); max-height: unset; position: relative; width: 99.9%; overflow: hidden;">
  <div style="padding: 10px 10px; background-color: #812b2b; color: white; margin-bottom: 10px;"
       *ngIf="data.WikiSlug && adminService.isOpen(data.WikiSlug)">Open by: {{
    adminService.currentEditors(data.WikiSlug).join(', ') }}
  </div>
  <div indicioResizable
       (resizeEvent)="onResize($event)"
       class="grid-system"
       style="--grid-template: 1fr 7px {{ previewWidth }}; gap: 10px; height: 100%;">
    <div style="padding-right: 10px;">
      <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
    </div>
    <div resizeHandle="1"
         class="draggy"></div>
    <div resizeTarget="1">
      <ng-container *ngTemplateOutlet="previewTemplate"></ng-container>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Cancel
  </button>
  <button mat-flat-button
          [disabled]="disabledCreate"
          (click)="data.WikiSlug ? update() : save()">
    {{ data.WikiSlug ? 'Update' : 'Create' }}
  </button>
</div>

<ng-template #formTemplate>
  <mat-form-field>
    <mat-label>Title</mat-label>
    <input type="text"
           matInput
           [ngModelOptions]="{standalone: true}"
           required
           (keyup)="backupPage(); setSlug()"
           [(ngModel)]="createDTO.Title"
           class="material">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Slug</mat-label>
    <input type="text"
           matInput
           [ngModelOptions]="{standalone: true}"
           required
           [disabled]="!!data.WikiSlug"
           (keyup)="backupPage();"
           [(ngModel)]="createDTO.Slug"
           class="material">
  </mat-form-field>

  <div class="compose-container">
    <textarea [ngModelOptions]="{standalone: true}"
              [(ngModel)]="createDTO.Content"
              (keyup)="backupPage()"
              #content
              require></textarea>
  </div>

  <div class="margin-bottom-10">
    <button class="btn btn--text dotted-underline"
            (click)="openAssets()">Assets</button>
    <button class="btn btn--text dotted-underline"
            (click)="openPages()">Pages</button>
    <a href="https://www.markdownguide.org/basic-syntax/"
       target="_blank"
       title="Markdown guide"
       style="font-size: 15px;"><i class="ion-social-markdown"></i></a>

    <a href="https://katex.org/docs/supported"
       target="_blank"
       title="KaTeX guide"
       style="font-size: 14px; margin-left: 20px;">
      <fa-icon [icon]="katexIcon"></fa-icon>
    </a>
  </div>

  <indicio-searchable-dropdown label="Parent"
                               optionDisplay="Title"
                               [values]="validParents | orderBy: 'Title'"
                               [selectedValue]="selectedParent"
                               [initializeWithFirstValue]="false"
                               [required]="true"
                               (valueChangeEvent)="setCategory($event)">
  </indicio-searchable-dropdown>

  <mat-form-field>
    <mat-label>Width</mat-label>
    <input type="text"
           matInput
           [ngModelOptions]="{standalone: true}"
           required
           [(ngModel)]="sizes.Width"
           class="material">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Height</mat-label>
    <input type=""
           matInput
           [ngModelOptions]="{standalone: true}"
           required
           [(ngModel)]="sizes.Height"
           class="material">
  </mat-form-field>

  <div style="margin-top: 10px;">
    <mat-slide-toggle [checked]="createDTO.Published"
                      (change)="createDTO.Published = !createDTO.Published; backupPage()">
      Published
    </mat-slide-toggle>
  </div>
</ng-template>

<ng-template #previewTemplate>
  <div class="preview">
    <indicio-wiki-page [page]="createDTO"
                       [showTitle]="true"
                       #preview></indicio-wiki-page>
  </div>
</ng-template>
