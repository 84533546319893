import { Injectable, OnDestroy } from '@angular/core';
import { PusherActions } from '@core/actions/pusher.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { PusherService } from '@core/services/frontend/pusher.service';
import { Subscription } from 'rxjs';
import { PusherActionDTO } from './dtos/pusher-action-dto';
import { PusherActionBehaviourService } from './pusher-action.behaviour.service';

@Injectable({
  providedIn: 'root'
})
export class PusherActionsFrontendService implements OnDestroy {

  private sub: Subscription = new Subscription();

  constructor(
    private actions: ActionService,
    private behaviourService: PusherActionBehaviourService,
    private pusherService: PusherService,
  ) {
    this.sub.add(this.actions.dispatched(PusherActions.SetupCompanyBindings)
      .subscribe(() => this.setup()));
    this.sub.add(this.actions.dispatched(PusherActions.SetupPrivateBindings)
      .subscribe(() => this.setupPrivate()));
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setupPrivate() {
    this.pusherService.addPrivateBinding('action', (dto: PusherActionDTO) => {
      this.behaviourService.actOnPusherAction(dto);
    });
  }

  private setup() {
    this.pusherService.addCompanyBinding('action', (dto: PusherActionDTO) => {
      this.behaviourService.actOnPusherAction(dto);
    });
  }
}



