import { Injectable, OnDestroy } from '@angular/core';
import { PusherActions } from '@core/actions/pusher.actions';
import { ActionService } from '@core/services/actions/actions.service';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { NavigationService } from '@core/services/frontend/navigation.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { MultivariateFrontendService } from '@core/store/stat-model/multivariate/multivariate.frontend.service';
import { UnivariateFrontendService } from '@core/store/stat-model/univariate/univariate.frontend.service';
import { VariableSelectFrontendService } from '@core/store/var-select/var-select.frontend.service';
import { ScriptName } from '@core/types/script.name.type';
import { UnivariateModelName } from '@modules/lang/language-files/stat-models';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RQueueInfoModel } from './r-queue.models';
import { QueueActions } from './script-queue.actions';
import { ScriptQueueState } from './script-queue.state';


@Injectable({
  providedIn: 'root'
})
export class ScriptQueueFrontendService implements OnDestroy {

  private subHelper: Subscription = new Subscription();

  public getQueueByForecastVersionId$(forecastVersionId: string) {
    return this.store.select(ScriptQueueState.queue)
      .pipe(map(allQueues => {
        const q = allQueues.find(specificQueue => specificQueue.ForecastVersionId === forecastVersionId);
        return q || new RQueueInfoModel();
      }));
  }

  public getQueueByForecastVersionId(forecastVersionId: string) {
    const all = this.store.selectSnapshot(ScriptQueueState.queue);
    const ans = all.find(x => x.ForecastVersionId === forecastVersionId);
    return ans || new RQueueInfoModel();
  }

  public scriptInProgress(fVersionId: string, script: ScriptName) {
    const queue = this.getQueueByForecastVersionId(fVersionId);
    return !!queue.Entries.find(s => s.ScriptName === script);
  }

  constructor(
    private store: Store,
    private actions: ActionService,
    private forecastService: ForecastFrontendService,
    private forecastVariableService: ForecastVariableFrontendService,
    private univariateService: UnivariateFrontendService,
    private multivariateService: MultivariateFrontendService,
    private varSelectService: VariableSelectFrontendService,
    private envService: EnvironmentService,
    private navService: NavigationService
  ) {
    this.listenForSetup();
  }

  private reset() {
    this.subHelper.unsubscribe();
    this.subHelper = new Subscription();
    this.listenForSetup();
    this.setupPending();
    this.setupFinished();
  }

  private listenForSetup() {
    this.subHelper.add(this.actions.dispatched(PusherActions.SetupCompanyBindings)
      .subscribe(() => this.reset())
    );
  }

  public ngOnDestroy() {
    this.subHelper.unsubscribe();
  }

  private setupPending() {
    this.subHelper.add(this.actions.dispatched(QueueActions.Queued).subscribe((action: QueueActions.Queued) => {
      const msg = action.message;
      switch (msg.ScriptName) {
        case this.envService.ScriptNames.DataProcessing:
          this.forecastVariableService.setNotProcessed(msg.ForecastVersionId, msg.ForecastVariableId);
          break;

        case this.envService.ScriptNames.Univariate:
          this.univariateService.setNotFetched(msg.ForecastVersionId);
          this.univariateService.removeModel(msg.ForecastVersionId, (msg.ModelName as UnivariateModelName));
          break;
      }
    }));
  }

  private setupFinished() {
    this.subHelper.add(this.actions.dispatched(QueueActions.Finished).subscribe((action: QueueActions.Finished) => {
      const msg = action.message;
      const forecast = this.forecastService.forecastByVersionId(msg.ForecastVersionId);
      if (!forecast) { return; }
      const isOnForecast = this.navService.isOnForecast(forecast.ForecastId);
      switch (msg.ScriptName) {
        case this.envService.ScriptNames.Univariate:
          this.univariateService.setNotFetched(msg.ForecastVersionId);
          if (!isOnForecast) { return; }
          this.univariateService.fetchModel(msg.ForecastVersionId, msg.ModelName as UnivariateModelName);
          break;

        case this.envService.ScriptNames.Multivariate:
          this.multivariateService.setNotFetched(msg.ForecastVersionId);
          if (!isOnForecast) { return; }
          const g = this.envService.MultivariateModels.find(model => model.Model.Model === msg.ModelName);
          this.multivariateService.fetchModel(msg.ForecastVersionId, g);
          break;

        case this.envService.ScriptNames.DataProcessing:
          this.forecastVariableService.setNotFetched(msg.ForecastVersionId, msg.ForecastVariableId);
          if (!isOnForecast) { return; }
          this.forecastVariableService.fetchById(msg.ForecastVersionId, msg.ForecastVariableId);
          break;

        case this.envService.ScriptNames.VariableSelection:
          this.varSelectService.setNotFetched(msg.ForecastVersionId);
          if (!isOnForecast) { return; }
          this.varSelectService.fetchVarSelect(msg.ForecastVersionId);
          break;

        default:
          break;
      }
    }));
  }
}
