<indicio-modal-base [child]="this"
                    modalTitle="Google Authenticator login"
                    [loading]=isLoading
                    bodyExtraClass="overflow-auto">

  <ng-container *ngIf="!isLoading">
    <ng-container *ngTemplateOutlet="googleTemplate"></ng-container>
  </ng-container>
</indicio-modal-base>

<ng-template #googleTemplate>
  <div class="row margin-top">
    <div class="col-xs-12 text-center bold">Provide your google authenticator code below to login</div>
    <ng-container *ngIf="!useBackup">
      <div class="col-xs-7">
        <div class="input-label bold">Enter code</div>
        <span class="subtitle">The code can be found in your 2-factor app</span>
      </div>
      <div class="col-xs-5 text-right"
           style="margin-top: 15px;">
        <mat-form-field>
          <mat-label>2-factor code</mat-label>
          <input #inputCode
                 type="number"
                 class="material"
                 [(ngModel)]="codeToVerify"
                 matInput
                 indicioAutoFocusInput
                 name="otp"
                 (ngModelChange)="codeInput();"
                 required>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="useBackup">
      <div class="col-xs-7">
        <div class="input-label bold">Enter backup code</div>
        <span class="subtitle">Use a backup code from when you activated 2-factor</span>
      </div>
      <div class="col-xs-5 text-right"
           style="margin-top: 15px;">
        <mat-form-field>
          <mat-label>Backup code</mat-label>
          <input #inputCode
                 minlength="12"
                 type="text"
                 class="material"
                 [(ngModel)]="backupCode"
                 matInput
                 indicioAutoFocusInput
                 name="otp-backup"
                 required>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
  <div class="row"
       [ngClass]="{ disabled: useBackup }">
    <div class="col-xs-7">
      <div class="input-label bold">Remember me on this computer</div>
      <span class="subtitle">{{ rememberMe
        ? 'Enabled: Remember this computer for 7 days'
        : 'Disabled: You will need to login with 2-factor next time you log in' }}</span>
      <br *ngIf="rememberMe">
    </div>
    <div class="col-xs-5 text-right"
         style="margin-top: 15px;">
      <mat-slide-toggle [checked]="rememberMe"
                        (change)="rememberMe = !rememberMe">
      </mat-slide-toggle>
    </div>
  </div>
  <div class="row"
       style="display: flex;">
    <div class="col-xs-4"></div>
    <div class="col-xs-4 italic pointer blue dotted-underline vertical-align"
         style="flex-direction: row-reverse;"
         (click)="useBackup = !useBackup">{{ useBackup ? 'Use authenticator code' : 'Use backup code'}}</div>
    <div class="col-xs-4">
      <button class="btn pull-right"
              (click)="verify2FA()">
        <i *ngIf="inProgress"
           class="ion-load-c spinner"></i>
        <span *ngIf="!inProgress">Verify</span>
      </button>
    </div>
  </div>
</ng-template>
