import { ProjectMemberModel } from './project-member.model';
import { ProjectModel } from './project.model';

export class ChangeActiveProjectAction {
  public static readonly type = '[PROJECT] Change active project';
  constructor(public projectId: string) { }
}

export class RemovedProjectsInCompanyAction {
  public static readonly type = '[PROJECT] Remove project in company';
  constructor(public companyId: string) { }
}

export class GetProjectSuccessAction {
  public static readonly type = '[PROJECT] Get project success';
  constructor(public project: ProjectModel) { }
}

export class GetProjectsInCompanySuccessAction {
  public static readonly type = '[PROJECT] Get projects in company success';
  constructor(public companyId: string, public projects: ProjectModel[]) { }
}

export class RemoveProjectSuccessAction {
  public static readonly type = '[PROJECT] Remove project success';
  constructor(public projectId: string) { }
}

export class GetProjectMembersSuccessAction {
  public static readonly type = '[PROJECT] Get project members success';
  constructor(public projectId: string, public members: ProjectMemberModel[]) { }
}

export class RemoveProjectMemberSuccessAction {
  public static readonly type = '[PROJECT] Remove member success';
  constructor(public projectId: string, public memberEmail: string) { }
}
