import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { Periodicity } from '@modules/lang/types/periodicity';
import { Store } from '@ngxs/store';
import { AlgConverter } from '@shared/components/line-graph/alg-line.utils';
import { ALGLineModel } from '@shared/components/line-graph/alg-models/graph-data.model';
import { AlgChartStyles, AlgOptions, StrokeSetups } from '@shared/components/line-graph/alg.options';
import { MathUtils } from '@shared/utils/math.utils';
import { StateUtils } from '@shared/utils/state.utils';

class GroupVersionTmp {
  Name: string;
  SourceVariableId: string;
  Periodicity: Periodicity;
  MetaId: string;
  NeedUpAgg: boolean;
  NeedDownAgg: boolean;
  FromGroup: boolean;
}

const AllowedIdentifiers = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export interface CreateGroupVariableDialogComponentData {
  forecastVersionId: string,
  sourceVariableIds: string[],
  variable: SourceVariableModel;
}

@Component({
  selector: 'indicio-create-user-dialog',
  templateUrl: 'create-group-variable.dialog.html',
})
export class CreateGroupVariableDialogComponent {

  public static Id: string = 'CreateGroupVariableDialogComponent';

  private opts: CreateGroupVariableDialogComponentData;
  private modalState = new StateUtils.StateHelper();
  private sourceVariableVersions: GroupVersionTmp[] = [];
  private missingMetas = true;
  private loadingVersion: boolean;
  private expressionRegex: RegExp;
  private previewValues: PlotValueDTO[] = [];

  // Status flags
  public tooManyVariables = false;
  public nestedGroups = false;
  public isLoading = true;
  public update = false;
  public nameConflict = false;
  public needUpAgg = false;
  public needDownAgg = false;
  public graphReady = false;
  public validExpression: boolean = true;

  // the new "group"
  public variable: SourceVariableModel;

  // identifiers for the variables to be used in the infix expression
  public identifiers: string[];
  public sourceVariables: SourceVariableModel[];
  public historicLine: ALGLineModel;
  public options: AlgOptions.Options;
  public chartStyles: AlgChartStyles = {
    borderSize: 2, borderRadius: 4, historyLine: '#6388D0',
    colorScheme: 'dark',
    plotHeight: 210
  };

  public get periodicity() { return this.envService.getPeriodicity(this.variable.groupPeriodicity); }
  public get fromGroups() { return this.sourceVariableVersions.filter(x => x.FromGroup); }
  constructor(
    protected store: Store,
    public sourceService: SourceVariableFrontendService,
    public clientService: ClientFrontendService,
    public envService: EnvironmentService,
    public dialogRef: MatDialogRef<CreateGroupVariableDialogComponent>,
    public appearance: AppearanceService,
    public auth: AuthFrontendService,
    public companyService: CompanyFrontendService,
    private status: StatusService,
    @Inject(MAT_DIALOG_DATA) public data: CreateGroupVariableDialogComponentData) {
    this.setOptions(data);
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public save() {
    if (this.isDisabled) {
      return;
    }
    this.variable.GroupMetaIds = this.sourceVariableVersions.map(x => x.MetaId);
    this.update ? this.updateVar() : this.createVar();
  }

  public get isChanged() {
    return this.modalState.isChanged('variable', this.variable) || this.sourceVariableVersions.length;
  }

  public get isDisabled() {
    return !!this.disabledText;
  }

  public get previewDisabled() {
    return !!this.previewDisabledText;
  }

  public get disabledText() {
    switch (true) {
      case !!!this.variable.Name:
        return 'Name is required';
      case this.nameConflict:
        return 'Name already exists';
      default:
        return this.previewDisabledText;
    }
  }

  public get previewDisabledText() {
    switch (true) {
      case this.loadingVersion:
        return 'Loading variables...';
      case this.missingMetas:
        return 'No variables selected';
      case !!!this.variable?.groupPeriodicity:
        return 'No periodicity selected';
      case this.needUpAgg && this.variable?.UpAggregationMethodId === null:
        return 'Up aggregation method is required';
      case this.needDownAgg && this.variable?.DownAggregationMethodId === null:
        return 'Down aggregation method is required';
      case !this.validExpression:
        return 'Invalid expression';
      default:
        return null;
    }
  }

  public changeGroupName(newName: string) {
    const name = newName.trim();
    this.variable.Name = name;
    this.nameConflict = this.sourceService.isNameConflict(name);
  }

  public checkAggregation() {
    for (let i = 0, n = this.sourceVariableVersions.length; i < n; i++) {
      const v = this.sourceVariableVersions[i];
      v.NeedUpAgg = v.Periodicity.isShorter(this.variable.groupPeriodicity);
      v.NeedDownAgg = v.Periodicity.isLonger(this.variable.groupPeriodicity);
    }
    this.needDownAgg = this.sourceVariableVersions.some(v => v.NeedDownAgg);
    this.needUpAgg = this.sourceVariableVersions.some(v => v.NeedUpAgg);
  }

  public fetchPreview() {
    if (this.previewDisabled) {
      this.status.setMessage(this.previewDisabledText, 'Warning', true);
      return;
    }
    this.variable.GroupMetaIds = this.sourceVariableVersions.map(x => x.MetaId);
    this.sourceService.getPreviewGroup(this.variable, this.identifiers)
      .then(values => {
        this.previewValues = values;
        this.updateGraphData();
      })
      .catch(error => {
        this.status.setError(error, true);
      });
  }

  public changeGroupExpression(newExpression: string) {
    this.validExpression = MathUtils.checkGroupInfixExpression(newExpression, this.expressionRegex);
    this.variable.InfixExpression = newExpression;
  }

  /*
   *
   * Private functions below
   *
   */

  private setOptions(options: CreateGroupVariableDialogComponentData) {
    this.opts = options;
    if (this.opts.sourceVariableIds.length > AllowedIdentifiers.length) {
      this.tooManyVariables = true;
      this.isLoading = false;
      return;
    }
    this.variable = options.variable == null ? new SourceVariableModel() : options.variable;
    if (options.variable != null) {
      options.sourceVariableIds = this.variable.GroupVariableBridges.map(x => x.SourceVariableId);
      this.identifiers = this.variable.GroupVariableBridges.map(x => x.Identifier);
      this.variable.groupPeriodicity = this.variable.periodicity;
      this.update = true;
    } else {
      this.variable.CompanyId = this.clientService.activeCompany.CompanyId;
      this.variable.VisibilityLevelId = 'private';
      this.variable.Name = '';
    }
    this.setupSources();
  }

  private setupSources() {
    this.sourceService.getOrFetchSourceVariablesInBulk(this.opts.sourceVariableIds)
      .then(sourceVariables => {
        this.sourceVariables = sourceVariables;
        this.nestedGroups = sourceVariables.some(x => x.SourceType === 'group');
        this.sourceVariableVersions = this.sourceVariables.map(x => {
          const base = x.getBaseVersion();
          return {
            Name: x.Name,
            SourceVariableId: x.SourceVariableId,
            Periodicity: base.periodicity,
            MetaId: base.SourceVariableMetaId,
            NeedDownAgg: false,
            NeedUpAgg: false,
            FromGroup: x.SourceType === 'group'
          };
        });

        if (this.variable.groupPeriodicity) { this.checkAggregation(); }
        this.missingMetas = this.sourceVariableVersions.length < 1;
        if (!this.update) {
          this.setupIdentifiers(this.opts.sourceVariableIds.length);
          this.setPeriodicity();
          this.variable.InfixExpression = this.identifiers.join(' + ');
        }
        this.expressionRegex = MathUtils.getInfixExpressionRegex(this.identifiers);
        this.fetchPreview();
        this.updateGraphData();
        this.isLoading = false;
        this.modalState.setState('variable', this.variable);
      });
  }

  private setPeriodicity() {
    if ((this.sourceVariables.every(x => x.Periodicity === this.sourceVariables[0].Periodicity))) {
      this.variable.groupPeriodicity = this.sourceVariables[0].Periodicity;
    }
  }

  private createVar() {
    this.sourceService.createGroup(this.variable, this.identifiers)
      .then(() => {
        this.status.setMessage('Group variable created', 'Success');
        this.dialogRef.close();
      })
      .catch(error => {
        this.status.setError(error, true);
      });
  }

  private updateVar() {
    this.sourceService.updateGroup(this.variable, this.identifiers)
      .then(() => {
        this.status.setMessage('Group variable updated', 'Success');
        this.dialogRef.close();
      })
      .catch(error => {
        this.status.setError(error, true);
      });
  }

  private setupIdentifiers(length: number) {
    const result: string[] = [];
    for (let i = 0; i < length; i++) {
      const index = i % AllowedIdentifiers.length;
      result.push(AllowedIdentifiers[index]);
    }
    this.identifiers = result;
  }

  private updateGraphData() {
    const historicLineModel = AlgConverter.fromSpeedHistoricValues(this.previewValues as PlotValueDTO[], this.variable.Name, 'data');

    historicLineModel.Name = this.variable.Name;
    this.historicLine = historicLineModel;
    this.options = this.getGraphOptions();
    this.graphReady = true;
  }

  private getGraphOptions() {
    const opts = AlgOptions.CreateOptions({
      noDataText: 'No data selected',
      inModal: true,
      summary: true,
      menuConfig: {
        showMenu: false,
      },
      axisConfig: {
        yAxisPosition: 'inside',
        yAxisLine: StrokeSetups.AxisLineDashed
      },
      updateGraph: false,
      showAssessments: false,
      dontShowCIHover: false,
      isPercent: false,
      rocEnabled: this.appearance.RoCEnabled,
      rocYYEnabled: this.appearance.RoCYYEnabled,
      header: 'Group variable ',
      subheader: 'Result: ',
      dates: this.previewValues.map(x => x.m),
      pointsToShow: Math.min(this.previewValues.length, 200),
      showOnlyHistoric: true
    });
    return opts;
  }
}
