import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppearanceService } from '@core/store/profile/appearance.service';

export class Auth2FASettingsData {
  public force: boolean;
  public forceFrom: Date;
}
@Component({
  selector: 'indicio-2fa-settings-dialog',
  templateUrl: '2-factor-settings.dialog.html',
})
export class Authentication2FASettingsDialogComponent {
  public static Id: string = 'Authentication2FASettingsDialogComponent';

  public force: boolean = null;
  public forceFrom: Date = null;

  constructor(
    public dialogRef: MatDialogRef<Authentication2FASettingsDialogComponent>,
    public appearance: AppearanceService,
    @Inject(MAT_DIALOG_DATA) public data: Auth2FASettingsData) {
    this.force = data.force;
    this.forceFrom = data.forceFrom;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(true);
  }

}
