import { ConfirmModalOpts } from './confirm-modal.options';


export class OpenConfirmModal {
  public static readonly type = '[MODAL] Open confirm modal';
  data: ConfirmModalOpts;
  constructor(
    proceed: any,
    message: string,
    title: string,
    decline: string,
    confirm: string,
    negativeEffect: boolean,
    back: any = null,
    disableYes: boolean = false,
    onClose: any = null
  ) {
    this.data = { proceed, message, title, decline, confirm, negativeEffect, back, disableYes, onClose };
  }
}


