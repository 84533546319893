import { DisplayValue } from '../types/display-value';

export type OutlierModelType = 'auto.arima' | 'arima';
export type OutlierStrategyType = 'en-masse' | 'bottom-up';
export type OutlierDetectionType = 'AO' | 'LS' | 'TC' | 'IO' | 'SLS';

export class OutlierModel extends DisplayValue<OutlierModelType> { };
export const OutlierModels = [
  new OutlierModel('auto.arima', 'Auto-ARIMA', 'Use an auto.arima model for outlier detection (slower)'),
  new OutlierModel('arima', 'ARIMA', 'Use an arima model for outlier detection (faster)')
];

export class OutlierStrategy extends DisplayValue<OutlierStrategyType> { };
export const OutlierStrategies = [
  new OutlierStrategy('en-masse', 'En-masse', 'After refitting the model using all detected outliers as regressors, only the significant ones are kept'),
  new OutlierStrategy('bottom-up', 'Bottom-up', 'Iteratively add detected outliers, sorted by their t-statistic, and refit the model. Stop adding new outliers when the significance of any previously added outlier drops')
];

export class OutlierType extends DisplayValue<OutlierDetectionType> { };
export const OutlierTypes = [
  new OutlierType('AO', 'AO', 'Additive outliers'),
  new OutlierType('LS', 'LS', 'Level shifts'),
  new OutlierType('TC', 'TC', 'Temporary changes'),
  new OutlierType('IO', 'IO', 'Innovational outliers'),
  new OutlierType('SLS', 'SLS', 'Seasonal level shifts')
];
