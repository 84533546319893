import { Component, EventEmitter, Input, Output } from '@angular/core';

export class CheckCircleModel {
  Color: string;
  Text: string;
  HoverText: string;
  Active: boolean = true;
  Checked: boolean = true;

  constructor(color: string, text: string, active: boolean, checked: boolean) {
    this.Color = color;
    this.Text = text;
    this.Active = active;
    this.Checked = checked;
  }
}

@Component({
  selector: 'indicio-check-circle',
  templateUrl: './check-circle.component.html',
  styleUrls: ['./check-circle.component.less']
})
export class CheckCircleComponent {

  @Input() model: CheckCircleModel;

  @Output() clickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public click() {
    this.model.Checked = !this.model.Checked;
    this.clickEvent.emit(this.model.Checked);
  }
}
