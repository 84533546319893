
export namespace SearchableHierarchy {

  export class Node {
    Value: string;
    Display: string;
    Children?: Node[];
    Parent?: Node;
  }

}
