import { AutoTaskByEventType, AutoTaskSendMailTriggerType, AutoTaskStepType, AutoTaskTriggerOnType, AutoTaskTriggerType } from '@modules/lang/language-files/automations';

export class CreateAutoTaskDTO {
  Name: string;
  Description: string = '';
  Active: boolean = true;
  Interval: string;
  TriggerOn: string;
  TriggerAtDay: number;
  TriggerAtHour: number;
  TriggerType: string;
  OnEventType: string;
  Steps: AutoTaskStepDTO[] = [];
  CompanyId: string;
}

export class AutoTaskDTO extends CreateAutoTaskDTO {
  AutoTaskId: string;
  CreatedDate: string;
  LastRunAt: string;
  History: AutoTaskRunResultDTO[];
  CreatorEmail: string;
  ProjectIds: string[];
  Errors: AutoTaskAlertInfoDTO[] = [];
  Warning: AutoTaskAlertInfoDTO[] = [];
}

export class AutoTaskStepResultDTO {
  Step: number;
  Type: string;
  Messages: string[];
}

export class AutoTaskAlertInfoDTO {
  Step: number;
  Message: string;
}

export class AutoTaskRunResultDTO {
  AutoTaskRunResultId: string;
  StepResults: AutoTaskStepResultDTO[];
  CurrentStep: number;
  ExitSuccess: boolean;
  StartedAt: string;
  CompletedAt: string;
}

export class AutoTaskStepDTO {
  AutoTaskStepId: string;
  Step: number;
  StepType: string;
  StepMetas: AutoTaskStepMetaDTO[];
  TargetForecastId: string;
  SendWhen: string;
}

export class AutoTaskStepMetaDTO {
  AutoTaskStepMetaId: string;
  ForecastId: string;
  Email: string;
}

export class AutoTaskInfoDTO {
  StepTypes: AutoTaskStepType[];
  TriggerOnTypes: AutoTaskTriggerOnType[];
  TriggerTypes: AutoTaskTriggerType[];
  OnEventTypes: AutoTaskByEventType[];
  EmailTriggerOnTypes: AutoTaskSendMailTriggerType[];
}

export class AutoTaskHistoryDTO {
  AutoTaskHistoryId: string;
  CompanyId: string;
  AutoTaskId: string;
  StepTypes: string;
  Name: string;
  TriggeredBy: string;
  CreatorEmail: string;
  TriggerType: string;
  OnEventType: string;
  Interval: string;
  TriggerOn: string;
  TriggerAtDay: number;
  TriggerAtHour: number;
  StepCount: number;
  RunTimeMs: number;
  TimeInQueueMs: number;
  StartedAt: string;
  QueuedAt: string;
}
