import { Component, Input } from '@angular/core';

@Component({
  selector: 'indicio-icon-logo',
  templateUrl: './icon-logo.component.html',
  styles: [`
    .indicio-icon-logo {
      width: 120px;
      fill: #333;
      stroke: 'transparent';
    }
  `]
})
export class IndicioIconLogoComponent {
  @Input() style: string;
  @Input() fill: string;
  @Input() stroke: string;
}
