<indicio-modal-base [child]="this"
                    [modalTitle]="edit ? 'Edit task' : 'Create new task'"
                    [loading]=isLoading
                    modalClass="edit-auto-task-modal-opts"
                    bodyExtraClass="overflow-auto">
  <ng-container *ngIf="!isLoading && task">
    <ng-container *ngTemplateOutlet="generalFoldableTemplate"></ng-container>

    <div cdkDropList
         (cdkDropListDropped)="drop($event)"
         class="drag-list">

      <ng-container *ngFor="let s of task.Steps; let index = index">
        <div class="drag-box"
             [cdkDragDisabled]="task.Steps.length < 2"
             cdkDrag>
          <div class="drag-placeholder"
               *cdkDragPlaceholder></div>
          <ng-container *ngTemplateOutlet="taskStepTemplate; context: { i: index }"></ng-container>
          <div class="drag-handle"
               *ngIf="task.Steps.length > 1"
               cdkDragHandle>
            <svg width="18px"
                 fill="#ccc"
                 viewBox="0 0 24 24">
              <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
              </path>
              <path d="M0 0h24v24H0z"
                    fill="none"></path>
            </svg>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </ng-container>
</indicio-modal-base>

<ng-template #generalFoldableTemplate>
  <indicio-foldable-row [open]="generalOpen"
                        (toggleEvent)="generalOpen = $event"
                        [title]="'General - Task name: ' + (!task.Name ? 'Not set' : task.Name)">

    <indicio-modal-option-row optionTitle="Name"
                              optionSubtitle="Enter a name for the task"
                              textWidth="6"
                              optionsWidth="6">
      <mat-form-field style="margin-top: 15px;">
        <mat-label>Task name</mat-label>
        <input data-test-id="task-modal-name-field"
               required
               matInput
               [(ngModel)]="task.Name">
      </mat-form-field>
    </indicio-modal-option-row>
    <indicio-modal-option-row optionTitle="Task trigger"
                              optionSubtitle="What should trigger the task"
                              textWidth="6"
                              optionsWidth="6">
      <mat-form-field style="margin-top: 15px;">
        <mat-select [(value)]="task.TriggerType">
          <mat-option [value]="p.Value"
                      [title]="p.Value === 'by-event' ? 'Coming soon' : p.Description"
                      *ngFor="let p of taskEnvInfo.TriggerTypes"
                      [disabled]="p.Value === 'by-event'">{{ p.Display }}</mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>
    <ng-container [ngSwitch]="task.TriggerType">
      <ng-container *ngSwitchCase="'by-schedule'"
                    [ngTemplateOutlet]="scheduleTriggerTemplate"></ng-container>
      <ng-container *ngSwitchCase="'by-event'"
                    [ngTemplateOutlet]="eventTriggerTemplate"></ng-container>
    </ng-container>
  </indicio-foldable-row>
</ng-template>

<ng-template #taskStepTemplate
             let-i="i">
  <ng-container *ngIf="task.Steps[i] let step">

    <indicio-foldable-row #row
                          (toggleEvent)="step.stepOpen = $event"
                          [open]="step.stepOpen"
                          [manualToggle]="true">
      <div class="row"
           style="width: 100%;"
           foldable-row-title>
        <div class="col-xs-6"
             (click)="row.runToggleEvent()">
          Step {{ step.Step + ' ' + (!step.stepType ? '' : step.stepType.Display) }}
          <ng-container *ngIf="task.getErrorForStep(step.Step) as errors">
            <i *ngIf="errors.length"
               indicioTippy="Step contains one or more problems"
               (click)="openStepAlertDialog(errors, 'errors', $event)"
               class="ion-alert-circled red"></i>
            <ng-container *ngIf="task.getWarningForStep(step.Step) as warnings">
              <i *ngIf="!errors.length && warnings.length"
                 indicioTippy="Step contains one or more problems"
                 (click)="openStepAlertDialog(warnings, 'warnings', $event)"
                 class="ion-alert-circled orange"></i>
            </ng-container>
          </ng-container>
        </div>
        <div class="col-xs-6 text-right"
             style="padding-right: 30px">
          <div (click)="row.runToggleEvent()">
            <ng-container *ngIf="step.StepType !== 'send-result'; else sendMailTitleTemplate">
              Selected forecasts:
            </ng-container>
            <ng-template #sendMailTitleTemplate>Recipients: </ng-template>
            {{ step.StepMetas.length }}
          </div>
          <i class="pointer red ion-trash-b"
             indicioTippy="Remove step"
             style="font-size: 18px; position: absolute; right: 10px; top: -2px"
             (click)="removeStep(step)"></i>
        </div>
      </div>
      <indicio-modal-option-row optionTitle="Action type"
                                optionSubtitle="The type of action to execute"
                                textWidth="6"
                                optionsWidth="6">

        <mat-form-field style="margin-top:15px">
          <mat-select [value]="step.StepType"
                      (selectionChange)="setStepType($event.value, step)">
            <mat-option [value]="p.Value"
                        [title]="p.Description"
                        *ngFor="let p of taskEnvInfo.StepTypes">{{ p.Display }}</mat-option>
          </mat-select>
        </mat-form-field>
      </indicio-modal-option-row>
      <ng-container [ngSwitch]="step.StepType">
        <ng-container *ngSwitchCase="'update-variables'">
          <ng-container *ngTemplateOutlet="updateVariablesTemplate; context: { i }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'calc-results'">
          <ng-container *ngTemplateOutlet="calcResultTemplate; context: { i }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'save-result'">
          <ng-container *ngTemplateOutlet="saveResultTemplate; context: { i }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'use-result'">
          <ng-container *ngTemplateOutlet="useResultTemplate; context: { i }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'send-result'">
          <ng-container *ngTemplateOutlet="sendResultTemplate; context: { i }"></ng-container>
        </ng-container>
      </ng-container>
    </indicio-foldable-row>
  </ng-container>
</ng-template>

<ng-template #sendResultTemplate
             let-i="i">
  <ng-container *ngIf="task.Steps[i] let step">
    <ng-container
                  *ngTemplateOutlet="setTargetForecastTemplate; context: { step, title: 'Choose forecast', description: 'Results from this forecast will be sent to the recipients below.' }">
    </ng-container>
    <indicio-modal-option-row optionTitle="Send when"
                              optionSubtitle="Condition for when the result should be sent."
                              textWidth="6"
                              optionsWidth="6">
      <mat-form-field style="margin-top: 15px">
        <mat-select [(value)]="step.SendWhen"
                    required>
          <mat-option [value]="val.Value"
                      [title]="val.Description"
                      *ngFor="let val of taskEnvInfo.EmailTriggerOnTypes">{{ val.Display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </indicio-modal-option-row>

    <ng-container *ngTemplateOutlet="setEmailRecieversTemplate; context: { step }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #useResultTemplate
             let-i="i">
  <ng-container *ngIf="task.Steps[i] let step">
    <ng-container
                  *ngTemplateOutlet="setTargetForecastTemplate; context: { step, description: 'Select the forecast in which to import/use the selected results' }">
    </ng-container>

    <ng-container *ngIf="step.TargetForecastId">
      <ng-container
                    *ngTemplateOutlet="setSourceForecastTemplate; context: { step, desc: 'Results from the selected forecasts will be used in the target forecast' }">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #saveResultTemplate
             let-i="i">
  <ng-container *ngIf="task.Steps[i] let step">
    <ng-container
                  *ngTemplateOutlet="setForecastForStepTemplate; context: { step, desc: 'A new result will be saved in the selected forecasts' }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #calcResultTemplate
             let-i="i">
  <ng-container *ngIf="task.Steps[i] let step">
    <ng-container
                  *ngTemplateOutlet="setForecastForStepTemplate; context: { step, desc: 'Calculate new uni- and multivariate models, if new data is available or the models are missing.' }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #updateVariablesTemplate
             let-i="i">
  <ng-container *ngIf="task.Steps[i] let step">
    <ng-container
                  *ngTemplateOutlet="setForecastForStepTemplate; context: { step, desc: 'Data in the selected forecast(s) will be synchronized and updated and models will be calculated.' }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #setTargetForecastTemplate
             let-title="title"
             let-description="description"
             let-step="step">
  <indicio-modal-option-row [optionTitle]="title || 'Select target forecast'"
                            [optionSubtitle]="description"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px">
      <mat-select [value]="step.TargetForecastId"
                  (selectionChange)="setTargetForecast($event.value, step)"
                  required>
        <mat-optgroup *ngFor="let project of projects"
                      label="Project: {{ project.ProjectName }}">
          <mat-option [value]="forecast.ForecastId"
                      [title]="forecast.Name"
                      *ngFor="let forecast of project.Forecasts">{{ forecast.Name }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #setSourceForecastTemplate
             let-step="step">
  <div class="data-table full-width">
    <div class="data-table__header">
      <div class="column header left-align">Variable name</div>
      <div class="column header"
           indicioTippy="The role the result has in the target forecast">Type</div>
      <div class="column header">Results from</div>
      <div class="column"
           [style.width.px]="30"></div>
    </div>
    <div class="data-table__body big">
      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngFor="let meta of step.StepMetas"
           style="background-color: transparent !important;">
        <div class="column left-align crop-string"
             [indicioTippy]="meta.meta?.SourceName">{{ meta.meta?.SourceName }}</div>
        <div class="column">{{ !meta.meta ? '' : (meta.meta.IsIndicator ? 'Indicator' : 'Main Variable') }}</div>
        <div class="column">
          <mat-form-field>
            <mat-select [value]="meta.ForecastId"
                        (selectionChange)="setSourceForecast($event.value, meta, step)"
                        panelClass="data-table-panel-opts"
                        required>
              <mat-option disabled
                          value="-1">Select forecast</mat-option>
              <mat-optgroup *ngFor="let project of projects"
                            label="Project: {{ project.ProjectName }}">
                <ng-container *ngFor="let forecast of project.Forecasts">
                  <mat-option [value]="forecast.ForecastId"
                              [title]="forecast.Name"
                              [indicioTippy]="getTippyText(forecast.ForecastId, step) || null"
                              [disabled]="!isSourceChoosable(forecast.ForecastId, step)">{{ forecast.Name }}
                  </mat-option>
                </ng-container>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="column pointer"
             indicioTippy="Remove"
             (click)="removeSourceForecast(meta, step)"
             [style.width.px]="30"><i class="ion-close-round red"></i></div>
      </div>
    </div>
  </div>
  <button class="btn btn--text"
          [indicioTippy]="!anySourceChoosable(step) ? 'No more usable results' : null"
          [class.disabled]="!anySourceChoosable(step)"
          style="min-width: unset; padding-left: 0;"
          (click)="addEmptyStepMeta(step)">Add result</button>
</ng-template>

<ng-template #setForecastForStepTemplate
             let-step="step"
             let-desc="desc">
  <indicio-modal-option-row optionTitle="Select forecast(s)"
                            [optionSubtitle]="desc"
                            textWidth="6"
                            optionsWidth="6">

    <mat-form-field style="margin-top:25px">
      <mat-select [(value)]="step.forecastIds"
                  [multiple]="true"
                  required>
        <mat-optgroup *ngFor="let project of projects"
                      label="Project: {{ project.ProjectName }}">
          <mat-option [value]="forecast.ForecastId"
                      (onSelectionChange)="setForecasts($event, step)"
                      [title]="forecast.Name"
                      *ngFor="let forecast of project.Forecasts">{{ forecast.Name }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #scheduleTriggerTemplate>
  <indicio-modal-option-row optionTitle="Interval"
                            optionSubtitle="How often should the task be run"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [value]="task.Interval"
                  (selectionChange)="setInterval($event.value)">
        <mat-option [value]="p.Value"
                    *ngFor="let p of intervals">{{ p.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
  <ng-container [ngSwitch]="task.Interval">
    <ng-container *ngSwitchCase="Period.day"
                  [ngTemplateOutlet]="dayIntervalTemplate"></ng-container>
    <ng-container *ngSwitchCase="Period.week"
                  [ngTemplateOutlet]="weekIntervalTemplate"></ng-container>
    <ng-container *ngSwitchCase="Period.month"
                  [ngTemplateOutlet]="monthIntervalTemplate"></ng-container>
  </ng-container>
</ng-template>

<ng-template #eventTriggerTemplate>
  <indicio-modal-option-row optionTitle="Event type"
                            optionSubtitle="What type of event should trigger the task"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="task.OnEventType">
        <mat-option [value]="p.Value"
                    [title]="p.Description"
                    *ngFor="let p of taskEnvInfo.OnEventTypes">{{ p.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <ng-container [ngSwitch]="task.OnEventType">
    <ng-container *ngSwitchCase="'on-file-updated'"
                  [ngTemplateOutlet]="fileEventTypeTemplate"></ng-container>
    <ng-container *ngSwitchCase="'on-source-updated'"
                  [ngTemplateOutlet]="sourceEventTypeTemplate"></ng-container>
    <ng-container *ngSwitchCase="'on-new-result'"
                  [ngTemplateOutlet]="newResultEventTypeTemplate"></ng-container>
  </ng-container>
</ng-template>

<ng-template #dayIntervalTemplate>
  <ng-container [ngTemplateOutlet]="triggerAtHourTemplate"></ng-container>
</ng-template>

<ng-template #weekIntervalTemplate>
  <ng-container [ngTemplateOutlet]="triggerAtDayTemplate"></ng-container>
  <ng-container [ngTemplateOutlet]="triggerAtHourTemplate"></ng-container>
</ng-template>

<ng-template #monthIntervalTemplate>
  <ng-container [ngTemplateOutlet]="triggerOnTemplate"></ng-container>
  <ng-container *ngIf="task.TriggerOn !== 'date'"
                [ngTemplateOutlet]="triggerAtDayTemplate"></ng-container>
  <ng-container *ngIf="task.TriggerOn === 'date'"
                [ngTemplateOutlet]="triggerAtDateTemplate"></ng-container>
  <ng-container [ngTemplateOutlet]="triggerAtHourTemplate"></ng-container>
</ng-template>

<ng-template #triggerAtHourTemplate>
  <indicio-modal-option-row optionTitle="Trigger at hour"
                            optionSubtitle="What hour should the task be run"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="task.TriggerAtHour">
        <mat-option [value]="p"
                    *ngFor="let p of taskEnvInfo.hoursInADay">
          {{ p < 10
            ? '0'
            : ''
            }}{{p}}:00
            </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #triggerAtDayTemplate>
  <indicio-modal-option-row optionTitle="Trigger at day"
                            optionSubtitle="What day should the task be run"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="task.TriggerAtDay">
        <mat-option [value]="ind"
                    *ngFor="let p of weekdays; let ind = index">{{ p }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #triggerAtDateTemplate>
  <indicio-modal-option-row optionTitle="Trigger at date"
                            optionSubtitle="What date should the task be run"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="task.TriggerAtDay">
        <mat-option [value]="p"
                    *ngFor="let p of daysInMonth">{{ p }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #triggerOnTemplate>
  <indicio-modal-option-row optionTitle="Trigger on"
                            optionSubtitle="When should the task be triggered"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="task.TriggerOn">
        <mat-option [value]="p.Value"
                    [title]="p.Description"
                    *ngFor="let p of taskEnvInfo.TriggerOnTypes">{{ p.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>


<ng-template #fileEventTypeTemplate>
  <indicio-modal-option-row optionTitle="Files to trigger on"
                            optionSubtitle="What files should trigger the task"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="task.TriggerOn">
        <mat-option [value]="p.Value"
                    [title]="p.Description"
                    *ngFor="let p of taskEnvInfo.TriggerOnTypes">{{ p.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #sourceEventTypeTemplate>
  <indicio-modal-option-row optionTitle="Source variables to trigger on"
                            optionSubtitle="What source variables should trigger the task"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="task.TriggerOn">
        <mat-option [value]="p.Value"
                    [title]="p.Description"
                    *ngFor="let p of taskEnvInfo.TriggerOnTypes">{{ p.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>

<ng-template #newResultEventTypeTemplate>
  <indicio-modal-option-row optionTitle="Forecasts with new result"
                            optionSubtitle="What forecasts should trigger the task on new results"
                            textWidth="6"
                            optionsWidth="6">
    <mat-form-field style="margin-top: 15px;">
      <mat-select [(value)]="task.TriggerOn">
        <mat-option [value]="p.Value"
                    [title]="p.Description"
                    *ngFor="let p of taskEnvInfo.TriggerOnTypes">{{ p.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>
</ng-template>


<ng-template #buttons>
  <div class="row">
    <div class="col-xs-12">
      <button class="btn"
              [class]="{ disabled: task.Steps.length >= 10 }"
              [indicioTippy]="task.Steps.length >= 10 ? 'Can not add more than 10 steps' : null"
              (click)="addNewStep()">
        Add step
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <button class="btn outline blue"
              (click)="close()">
        Close
      </button>
    </div>
    <div class="col-xs-6">
      <button class="btn green pull-right"
              (click)="createOrSave()"
              [indicioTippy]="taskMessage"
              [class]="{ disabled: !taskValid }">
        <span>{{edit ? 'Save' : 'Create' }} task</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #setEmailRecieversTemplate
             let-step="step">
  <div class="data-table full-width margin-top">
    <div class="data-table__header">
      <div class="column header left-align">Recipients</div>
    </div>
    <div class="data-table__body big">
      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngFor="let meta of step.StepMetas; let i = index"
           style="background-color: transparent !important; height: 30px;">
        <div class="column left-align crop-string"
             [style.width.px]="440">

          <input data-test-id="task-modal-email-recipient-field-{{i}}"
                 required
                 type="text"
                 placeholder="Email adress"
                 [(ngModel)]="meta.Email">
        </div>
        <div class="column"
             *ngIf="{ valid: isValidEmail(meta.Email) } as check">
          <ng-container *ngIf="!!meta.Email; else missingEmail">
            <span class="red"
                  *ngIf="!check.valid">Invalid</span>
            <span class="green"
                  *ngIf="check.valid">Valid</span>
          </ng-container>
          <ng-template #missingEmail>
            <span class="red">Missing</span>
          </ng-template>
        </div>
        <div class="column pointer"
             indicioTippy="Remove"
             (click)="removeEmail(meta, step)"
             [style.width.px]="60"><i class="ion-close-round red"></i></div>
      </div>
    </div>
  </div>
  <button class="btn btn--text"
          style="min-width: unset; padding-left: 0;"
          (click)="addEmptyStepMeta(step)">Add recipient</button>
</ng-template>
