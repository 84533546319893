import { DisplayValue } from '../types/display-value';

export type MissingValueModelType = 'amelia' | 'linearinterpolation';

export class MissingValueModel extends DisplayValue<MissingValueModelType> { };
export const MissingValueModels = [
  new MissingValueModel('amelia', 'Amelia', 'Amelia missing values model'),
  new MissingValueModel('linearinterpolation', 'Linear Interpolation', 'Linear Interpolation missing values model')
];


