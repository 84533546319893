import { Def } from '@core/decorators/def';
import { PastForecastDTO } from '@core/entities/dtos/past-forecast-dto';
import { FittedValueDTO, PlotValueDTO, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { ShapValueDTO } from '@core/store/stat-model/dtos/stat-model.dto';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { ModelName } from '@modules/lang/types/model-name';
import { Periodicity } from '@modules/lang/types/periodicity';

export class ForecastResultModel {

  @Def() ForecastResultId: string;
  @Def() ForecastId: string;
  @Def() CompanyId: string;
  @Def() ForecastVersionId: string;
  @Def() MainVariableSourceMetaId: string;
  @Def() CreatedDate: Date;
  @Def() Name: string;
  @Def() Exponent: number;
  @Def() MaxLag: number;
  @Def() ForecastName: string;
  @Def() Periodicity: PeriodicityType;
  @Def() WeightMethod: string;
  @Def() ColorIndex: number = 0;
  @Def() FromNowcast: boolean;
  @Def() FromReconciliation: boolean;
  @Def() AutoGeneratedUni: boolean;
  @Def() AutoGeneratedMulti: boolean;
  @Def() RollingAccuracy: boolean;
  @Def() Residuals: SimplePlotValue[];
  @Def() FittedValues: FittedValueDTO[];
  @Def() Values: PlotValueDTO[];
  @Def() Models: ForecastResultModelModel[];
  @Def() PastForecasts: PastForecastDTO[];
  @Def() ShapModels: ShapValueDTO[];

  // Frontend stuffz
  periodicity: Periodicity;
  color: string;
  shortname: string;
  active: boolean;

  getModels(modelsToInclude: ModelName[]) {
    return this.Models.filter(m => !!modelsToInclude.filter(x => m.Name.match(new RegExp(x.nameRegEx, 'i'))).length);
  }
}

export class ForecastResultModelModel {
  @Def() Name: string;
  @Def() Weight: number[];
  @Def() Active: boolean;
  @Def() UnivariateModelId: number;
  @Def() MultivariateModelId: number;

  public get averageWeight() {
    return this.Weight.avg();
  }
}
