import { DeleteSourceVarModalOptions } from './delete-source-variable-modal.options';

export class OpenDeleteSourceVariableModal {
  public static readonly type = '[MODAL] Open delete source variable modal';
  data: DeleteSourceVarModalOptions;
  constructor(
    public sourceVariableIds: string[],
    public startWithoutConfirmation: boolean,
    public callback: any
  ) {
    this.data = { sourceVariableIds, callback, startWithoutConfirmation };
  }
}
