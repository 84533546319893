import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { EAutoTaskState } from '@modules/lang/language-files/automations';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { CompanyFrontendService } from '../company/company.frontend.service';
import { AutoTaskAction } from './auto-task.actions';
import { AutoTaskBackendService } from './auto-task.backend.service';
import { AutoTaskState } from './auto-task.state';
import { AutoTaskModel } from './dtos/auto-task.model';


@Injectable({
  providedIn: 'root'
})
export class AutoTaskFrontendService {

  // GETTERS
  public get autoTasks() { return this.store.selectSnapshot(AutoTaskState.autoTasks); }
  public get autoTaskInfo() { return this.store.selectSnapshot(AutoTaskState.autoTaskInfo); }

  constructor(
    private store: Store,
    private actions: ActionService,
    private service: AutoTaskBackendService,
    private companyService: CompanyFrontendService
  ) {
    this.setupSubs();
  }

  // Private fields
  private sub = new Subscription();
  public getTaskById(taskId: string) { return this.autoTasks.find(x => x.AutoTaskId === taskId); }

  public getAutoTaskInfo() {
    if (this.autoTaskInfo === null) {
      return this.service.getAutoTaskInfo(this.companyService.activeCompanyId)
        .then(info => {
          this.store.dispatch(new AutoTaskAction.GetInfoSuccess(info));
          return info;
        });
    }
    return Promise.resolve(this.autoTaskInfo);
  }

  public getAllAutoTasks(companyId: string) {
    return this.getAutoTaskInfo()
      .then(() => this.service.getAllAutoTasks(companyId))
      .then(tasks => {
        this.store.dispatch(new AutoTaskAction.GetAllSuccess(tasks));
        return tasks;
      });
  }

  public getOrFetch(companyId: string, taskId: string) {
    const t = this.getTaskById(taskId);
    if (!t) {
      return this.fetch(companyId, taskId);
    }
    return Promise.resolve(t);
  }

  public fetch(companyId: string, taskId: string) {
    return this.getAutoTaskInfo()
      .then(() => this.service.getAutoTask(companyId, taskId))
      .then(task => {
        this.store.dispatch(new AutoTaskAction.GetSuccess(task));
        return task;
      });
  }

  public createAutoTask(companyId: string, model: AutoTaskModel) {
    return this.service.createAutoTask(companyId, model)
      .then(task => {
        this.store.dispatch(new AutoTaskAction.GetSuccess(task));
        return task;
      });
  }

  public updateAutoTask(companyId: string, model: AutoTaskModel) {
    return this.service.updateAutoTask(companyId, model)
      .then(task => {
        this.store.dispatch(new AutoTaskAction.GetSuccess(task));
        return task;
      });
  }

  public setActive(companyId: string, model: AutoTaskModel) {
    return this.service.setActive(companyId, model).then(active => {
      this.store.dispatch(new AutoTaskAction.GetSuccess(model));
      return model;
    }).catch(err => {
      model.Active = !model.Active;
      throw err;
    });
  }

  public triggerAutoTask(companyId: string, taskId: string) {
    return this.service.triggerAutoTask(companyId, taskId);
  }

  public deleteAutoTask(companyId: string, taskId: string) {
    return this.service.deleteAutoTask(companyId, taskId)
      .then(() => {
        this.store.dispatch(new AutoTaskAction.RemoveSuccess(taskId));
      });
  }

  /**
   *
   * Private helpers
   *
   */
  private setupSubs() {
    this.sub.add(this.actions.dispatched(AutoTaskAction.SetState).subscribe((action: AutoTaskAction.SetState) => {
      if (action.state === EAutoTaskState.FAILED || action.state === EAutoTaskState.SUCCESS) {
        this.fetch(action.companyId, action.taskId);
      }
    }));
  }
}
