import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { AssessmentUtils } from '@shared/utils/forecast/assessment.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import { ALGSingleSeriesModel } from '../alg-models/graph-data.model';
import { ALGTypes } from '../alg-types';

export class AssessmentImpactOutput {
  GroupedAssessments: any[] = [];
  Models: ALGSingleSeriesModel[] = [];
}

// This function calculates the impact of each assessment, and sets the correct value for each
// assessed data-point
export function setAssessmentImpactOnData(
  assessments: AssessmentModel[],
  models: ALGSingleSeriesModel[],
  transformation: ALGTypes.Transform
): AssessmentImpactOutput {
  const result = new AssessmentImpactOutput();

  if (!assessments.some(x => x.Active)) {
    result.GroupedAssessments = [];
    result.Models = models;
    return result;
  }

  const toUse = AssessmentUtils.filterByType(assessments, transformation);
  const groups = AssessmentUtils.GetAssessmentGroups(toUse);
  groups.forEach(assGroup => {
    models.forEach(model => ValueUtils.setValueForDate(model.Values, assGroup.moment, 'day', assGroup.newValue));
  });
  result.Models = models;
  result.GroupedAssessments = groups;
  return result;
}
