import { Def } from '@core/decorators/def';


export class GraphThemeSettingsModel {
  @Def() backgroundColor: string;
  @Def() graphOverlay: string;
  @Def() historyLine: string;
  @Def() axisXline: string;
  @Def() axisYline: string;
  @Def() axisText: string;
  @Def() borderColor: string;
}


export class GraphThemeModel {
  @Def() GraphThemeId: string;
  @Def() Name: string;
  @Def() Theme: GraphThemeSettingsModel;
}
