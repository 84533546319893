import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { Transformations } from '@core/types/transformation.type';
import { FVarAdvancedSettings } from '@dialogs/variables/forecast-variable/info/tabs/advanced/advanced-settings';
import { Periodicity } from '@modules/lang/types/periodicity';
import { VariableTransformation } from '@modules/lang/types/variable-transformation';


@Component({
  selector: 'indicio-variable-transform-dropdown',
  templateUrl: './fvar-transform-dropdown.component.html',
  styleUrls: ['./fvar-transform-dropdown.component.less'],
})
export class VariableTransformDropdownComponent implements OnChanges {

  @Input() currentTransform: Transformations.FVarTrans;
  @Input() transformations: VariableTransformation[] = [];
  @Input() disabled: boolean = false;
  @Input() tippy: string = null;
  @Input() required: boolean = false;
  @Input() periodicity: Periodicity;

  @Output() selectedTransformEvent = new EventEmitter<Transformations.FVarTrans>();

  public initComplete: boolean = false;

  public variableTransforms: VariableTransformation[];
  public transformToAdd: Transformations.FVarTrans;
  public selectedTransform: VariableTransformation;

  constructor(
    private env: EnvironmentService
  ) {
  }

  public ngOnChanges() {
    this.setup();
  }

  public selectTransformation(value: VariableTransformation) {
    if (!this.transformToAdd) { this.transformToAdd = new Transformations.FVarTrans(null); }
    this.selectedTransform = value;
    this.transformToAdd.Transformation = value.Value;
    this.emitTransform();
  }

  public setMaTerms(terms: number) {
    if (terms < 1) terms = 1;
    if (terms > 24) terms = 24;
    this.transformToAdd.MaArgs.Terms = terms;
    this.emitTransform();
  }

  public setGaAlpha(alpha: number) {
    if (alpha >= 1) alpha = 0.99;
    if (alpha <= 0) alpha = 0.01;
    this.transformToAdd.GaArgs.Alpha = alpha;
    this.emitTransform();
  }

  private emitTransform() {
    this.transformToAdd.setArgument();
    this.selectedTransformEvent.emit(this.transformToAdd);
  }

  private setup() {
    this.variableTransforms = FVarAdvancedSettings.FilterVariableTransformations(this.periodicity, this.env.VariableTransformations);
    this.selectedTransform = this.variableTransforms.find(x => x.Value === this.currentTransform?.Transformation);
    this.transformToAdd = !!this.currentTransform ? this.currentTransform : null;
    this.initComplete = true;
  }
}
