<indicio-dialog class="engineer-multiple-fvar-dialog-container">
  <h2 mat-dialog-title>Feature engineer multiple forecast variables</h2>
  <mat-dialog-content>

    <div style="margin: 20px 0;">Add transformations to multiple forecast variables at once. Note, if you want to add
      specific transformations to a
      single variable, you can do so from the variable info dialog.
    </div>

    <div class="intro-text">
      Feature engineering: By adding copies of the same variable, but with different transformations,
      it is sometimes possible to extract more informations from a variable.
    </div>

    <ng-container *ngTemplateOutlet="optionsTemplate"></ng-container>

    <hr style="margin: 10px 0;">

    <div class="grid-system"
         style="--grid-template: 1fr 300px; gap: 10px">
      <span>Variables ({{ this.variables?.length }})</span>
      <span>Transformations to add</span>
    </div>

    <div class="grid-system"
         style="--grid-template: 1fr 300px; gap: 10px; margin-top: 20px;">
      <ng-container *ngTemplateOutlet="variableTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="transformsTemplate"></ng-container>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="margin-top: 20px">
    <div></div>
    <div>
      <button mat-raised-button
              [disabled]="!selectedFeatures?.length"
              (click)="saveFeatures()"
              type="button">
        Create variables
      </button>
    </div>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #optionsTemplate>
  <indicio-modal-option-row optionTitle="Add transformation"
                            optionSubtitle="Add transformation to this set of variables."
                            textWidth="6"
                            optionsWidth="6">

    <indicio-variable-transform-dropdown [currentTransform]="nextFeature"
                                         [transformations]="availableFeatures"
                                         [periodicity]="fVersion.periodicity"
                                         (selectedTransformEvent)="selectFeature($event)">
    </indicio-variable-transform-dropdown>

    <div class="add-transform">
      <span><!-- Empty --></span>
      <span [ngClass]="{ disabled: !nextFeature }"
            class="add-button"
            (click)="addFeature()">
        <i class="ion-plus-round"></i>
        Add
      </span>
    </div>
  </indicio-modal-option-row>

  <div class="row"
       *ngIf="currentFeatures?.length">
    <div class="col-xs-12"><button mat-flat-button
              color="warn"
              (click)="removeFeatures()">Remove all transformations</button></div>
  </div>
</ng-template>

<ng-template #emptyTransform>
  <div class="list-entry">
    <span>No transformations added</span>
  </div>
</ng-template>

<ng-template #variableTemplate>
  <indicio-data-table columnTemplate="1fr 35px 30px">
    <div class="scroll-content"
         *ngIf="variables?.length">
      <div row
           *ngFor="let variable of variables; let i = index">
        <div column
             class="crop-string"
             [title]="variable.Name">
          <span class="dotted-underline pointer"
                (click)="openVariableDialog(variable)">{{ variable.Name }}</span>
        </div>
        <div column
             center>
          <span *ngIf="variable?.CurrentFeatures.length"
                class="pointer dotted-underline"
                [indicioTippy]>
            <div tippy-content>
              Active features:
              <div *ngFor="let feature of variable?.CurrentFeatures">
                <small>{{ feature?.Display.Display }}</small>
              </div>
            </div>
            {{ variable.CurrentFeatures.length }}
          </span>
        </div>
        <div column
             center>
          <i class="ion-trash-b pointer"
             (click)="removeVariable(i)"
             style="color:#E26464;"></i>
        </div>
      </div>
    </div>
  </indicio-data-table>
</ng-template>

<ng-template #transformsTemplate>
  <div class="transforms-list">
    <div class="scroll-content">
      <ng-container *ngIf="selectedFeatures?.length > 0; else emptyTransform">
        <div *ngFor="let trans of selectedFeatures; let i = index"
             class="list-entry">
          <span>
            {{ trans.trans.Display }}
            {{ trans.trans.getArgString() }}
          </span>
          <span>
            {{ trans.creating }}
          </span>
          <i class="ion-trash-b pointer"
             (click)="removeFeature(i)"
             style="color:#E26464; font-size: 18px">
          </i>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
