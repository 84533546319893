<indicio-modal-base [child]="this"
                    [modalTitle]="modalTitle"
                    [loading]="isLoading">

  <form #f="ngForm"
        *ngIf="assessment"
        (ngSubmit)="save()"
        ngNativeValidate>

    <div class="row"
         title="Selected date for the assessment">
      <div class="col-xs-6">
        <div class="input-label bold">{{ edit ? 'Selected date' : 'Choose date' }}</div>
        <span class="subtitle">The date for which an assessment is made</span>
      </div>
      <div class="col-xs-6"
           style="margin-top:15px">
        <div class="input-label"
             *ngIf="edit">
          {{ timePoint }}
        </div>
        <mat-form-field *ngIf="!edit">
          <mat-select [(value)]="timePoint"
                      required
                      placeholder="Choose forecast point"
                      (selectionChange)="updateDate()">
            <mat-option [value]="option.Value"
                        *ngFor="let option of dateOptions">{{ option.Display }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row"
         title="The actual assessment">
      <div class="col-xs-6">
        <div class="input-label bold">Assessment</div>
        <span class="subtitle">The assessment impact</span>
      </div>
      <div class="col-xs-6"
           style="margin-top:15px">
        <div class="col-xs-6 col-no-padding">
          <div *ngIf="isCreator; else showQuantity">
            <mat-form-field>
              <input matInput
                     class="material"
                     type="number"
                     name="Quantity"
                     step="0.1"
                     [(ngModel)]="assessment.Quantity"
                     (mouseup)="calcResult()"
                     (change)="calcResult()"
                     placeholder="Enter number"
                     required>
            </mat-form-field>
          </div>
          <ng-template #showQuantity>
            <div class="text-field">
              {{ assessment.Quantity }}
              <span *ngIf="isRocType || assessment.Unit === 'percent'">%</span>
            </div>
          </ng-template>
        </div>
        <div class="col-xs-6"
             style="padding: 0 0 0 5px;">
          <div style="margin-top: -7px">
            <mat-form-field>
              <mat-select [(ngModel)]="assessment.Unit"
                          required
                          name="Unit"
                          [disabled]="edit"
                          placeholder="Unit"
                          (selectionChange)="changeUnit()">
                <mat-option [value]="option.Value"
                            [title]="option.Description"
                            *ngFor="let option of environment.AssessmentUnits">{{ option.Display }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>


    <div class="row"
         title="The source which the assessment is based on">
      <div class="col-xs-6">
        <div class="input-label bold">Source</div>
        <span class="subtitle">The source of the assessment</span>
      </div>
      <div class="col-xs-6"
           style="margin-top:15px">
        <ng-container *ngIf="isCreator; else showSource">
          <mat-form-field>
            <input matInput
                   class="material"
                   type="text"
                   name="Source"
                   [(ngModel)]="assessment.Source"
                   placeholder="E.g. a newspaper"
                   required>
          </mat-form-field>
        </ng-container>
        <ng-template #showSource>
          <div class="input-label m6">
            {{ assessment.Source }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="row"
         title="The reason for the assessment. E.g. 'New tariffs'">
      <div class="col-xs-6">
        <div class="input-label bold">Reason</div>
        <span class="subtitle">The reason for the assessment</span>
      </div>
      <div class="col-xs-6"
           style="margin-top:15px">
        <ng-container *ngIf="isCreator; else showReason">
          <mat-form-field>
            <input matInput
                   class="material"
                   type="text"
                   name="Reason"
                   [(ngModel)]="assessment.Reason"
                   placeholder="E.g. 'New tariffs'"
                   required>
          </mat-form-field>
        </ng-container>
        <ng-template #showReason>
          <div class="input-label m6">
            {{ assessment.Reason }}
          </div>
        </ng-template>
      </div>
    </div>


    <div class="row"
         title="The category of the assessment">
      <div class="col-xs-6">
        <div class="input-label bold">Category</div>
        <span class="subtitle">Assessment category</span>
      </div>
      <div class="col-xs-6"
           style="margin-top:15px">
        <mat-form-field *ngIf="isCreator; else showCategory">
          <mat-select [(ngModel)]="assessment.Category"
                      required
                      name="category"
                      placeholder="Choose category"
                      (selectionChange)="updateValueAfter()">
            <mat-option [value]="option.Value"
                        [title]="option.Description"
                        *ngFor="let option of environment.AssessmentCategories">{{ option.Display }}</mat-option>
          </mat-select>
        </mat-form-field>
        <ng-template #showCategory>
          <div class="text-field"
               title="{{ assessment.assessmentCategory.Description }}">
            {{ assessment.assessmentCategory.Display }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="row"
         title="Assessment trustworthiness">
      <div class="col-xs-6">
        <div class="input-label bold">Trustworthiness</div>
        <span class="subtitle">Assessment trustworthiness</span>
      </div>
      <div class="col-xs-6"
           style="margin-top:15px">
        <mat-form-field *ngIf="isCreator; else showLevel">
          <mat-select [(ngModel)]="assessment.TrustLevel"
                      required
                      name="trustworthiness"
                      placeholder="Choose trustworthiness"
                      (selectionChange)="updateValueAfter()">
            <mat-option [value]="option.Value"
                        [title]="option.Description"
                        *ngFor="let option of environment.AssessmentLevels">{{ option.Display }}</mat-option>
          </mat-select>
        </mat-form-field>
        <ng-template #showLevel>
          <div class="text-field"
               title="{{ environment.getAssessmentUnit(assessment.Unit).Description }}">
            {{ environment.getAssessmentUnit(assessment.Unit).Display }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="row">
      <div class="value-field pull-right">
        <span>{{ valueTitle }} before assessment:</span>
        <span class="pull-right">{{ oldValue }}</span>
        <br>
        <span>{{ valueTitle }} after assessment:</span>
        <span class="pull-right">{{ newValue }}</span>
      </div>
    </div>


    <div class="row">
      <div class="col-xs-6">
        <button class="btn outline blue left"
                type="button"
                (click)="back()"
                *ngIf="userCanCreateAssessment || userCanUpdateAssessment">
          <span *ngIf="!isStateChanged">Back</span>
          <span *ngIf="isStateChanged">Cancel</span>
        </button>
      </div>
      <div *ngIf="isCreator; else showOk"
           class="col-xs-6">
        <button *ngIf="userCanCreateAssessment || userCanUpdateAssessment"
                class="btn right"
                [ngClass]="{ disabled: !isStateChanged || isDisabled}"
                type="submit">
          {{ edit ? 'Update' : 'Save' }}
        </button>
        <button *ngIf="!userCanCreateAssessment || !userCanUpdateAssessment"
                class="btn outline blue right"
                type="button"
                (click)="back()">Close</button>
      </div>
      <ng-template #showOk>
        <button class="btn outline blue right"
                type="button"
                (click)="back()">Close</button>
      </ng-template>
    </div>
  </form>
</indicio-modal-base>
