<div style="display: grid; flex-direction: column; grid-template-rows: auto 1fr auto; height: 100%;">
  <h1 mat-dialog-title
      style="margin: 0;">{{ article.Meta.Title }}</h1>
  <div mat-dialog-content>
    {{ article.Meta.CreatedDate | date: appearance.FullFormatNoSeconds }}
    <ng-container *ngIf="canEdit">•
      <span (click)="editPost();"
            class="cursor-pointer">
        Edit
      </span>
    </ng-container>
    <hr style="margin-bottom: 0;">
    <div style="padding: 10px 0;">
      <markdown ngPreserveWhitespaces>
        {{ article.Meta.Body }}
      </markdown>
    </div>
  </div>
  <div mat-dialog-actions>
    <div></div>
    <button mat-flat-button
            [mat-dialog-close]="">
      Close
    </button>
  </div>
</div>
