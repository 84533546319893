import { Component, Inject, ViewEncapsulation } from '@angular/core';


import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { WikiPDFVersionDTO, WikiPDFVersionMetaDTO } from '@core/store/wiki/wiki.entities';
import { DialogV2BaseDialog } from '@dialogs/base/dialogs.V2.base-dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { AdminWikiService } from '@modules/admin/components/content/wiki/wiki-admin.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { FileUtils } from '@shared/utils/file.utils';

export class WikiPDFAdminDialogData { }

@Component({
  selector: 'indicio-wiki-pdf-dialog',
  templateUrl: './wiki-pdf.dialog.component.html',
  styleUrls: ['./wiki-pdf.dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class WikiPDFAdminDialogComponent extends DialogV2BaseDialog<WikiPDFAdminDialogComponent> {
  public static Id: string = 'WikiPDFAdminDialogComponent';
  public faDownload: IconProp = faFloppyDisk;

  // Frontend flags
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  public loadingPDFs = true;
  public files: WikiPDFVersionMetaDTO[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: WikiPDFAdminDialogData,
    private service: AdminWikiService,
    private dialogService: DialogService,
    private status: StatusService,
    public appearance: AppearanceService,
    dialogRef: MatDialogRef<WikiPDFAdminDialogComponent>,
  ) {
    super(dialogRef);
    this.initialize();
  }

  protected initialize() {
    this.initialized = true;
    this.getPDFs();
  }


  public back() {
    this.dialogRef.close();
  }

  public save() {
    this.isSaving = true;
  }

  public getFormattedSize(size: number) {
    return FileUtils.formatBytes(size, 0);
  }

  public download(id: string) {
    this.service.downloadPDF(id);
  }

  public askRemoveAsset(pdf: WikiPDFVersionMetaDTO) {
    this.dialogService.openConfirmDialog({
      Message: 'Are you sure you want to remove this pdf?',
      Title: 'Remove pdf',
    }).subscribe(answer => {
      if (!answer) { return; }
      this.removePDF(pdf);
    });
  }


  //
  // File drop-zone functions
  //

  public fileChange(fileList: FileList) {
    this.resetList();
    Promise.all(this.getFileList(fileList))
      .then(files => {
        return files.map(file => this.service.createPDF(file));
      })
      .then(promises => Promise.all(promises))
      .then(assets => this.getPDFs());
  }

  public allowDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  public drop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const inputElement = <HTMLInputElement> (document.querySelector('input[type=file]'));
    inputElement.files = event.dataTransfer.files;
    this.fileChange(inputElement.files);
    const box = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    box.classList.remove('active');
  }

  public toggleDragOver(state: boolean) {
    const element = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    if (state) {
      element.classList.add('active');
    } else {
      element.classList.remove('active');
    }
  }

  private getFileList(fileList: FileList) {
    this.isLoading = true;
    const promises: Promise<WikiPDFVersionDTO>[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const dto = Object.assign(new WikiPDFVersionDTO, <WikiPDFVersionDTO> { Name: file.name, MimeType: file.type });
      const promise = new Promise<WikiPDFVersionDTO>((res, rej) => {
        const dataReader = new FileReader();
        dataReader.onloadend = () => {
          const body: string = <string> dataReader.result;
          dto.Base64String = body.substring(body.indexOf(',') + 1);
          res(dto);
        };
        dataReader.onerror = rej;
        dataReader.readAsDataURL(file);
      });
      promises.push(promise);
    }
    return promises;
  }

  /**
   *
   * Private functions
   *
   */
  private resetList() {
    this.files = [];
    this.status.removeMessage();
  }

  private removePDF(asset: WikiPDFVersionMetaDTO) {
    this.isLoading = true;
    this.service.removePDF(asset.WikiPDFVersionId)
      .then(() => this.getPDFs());

  }

  private getPDFs() {
    this.isLoading = true;
    this.service.getAllPDFs()
      .then(pdfs => {
        this.files = pdfs;
        this.loadingPDFs = false;
        this.isLoading = false;
      });
  }

}
