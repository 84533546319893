import { Def } from '@core/decorators/def';

export class ImportedHistoricEventCorrelationDTO {
  FutureHistoricEventId: string;
  PastHistoricEventId: string;
  Strength: number;
}

export class ImportedHistoricEventDTO {
  ImportedHistoricEventId: string;
  ImportedHistoricBaseEventId: string;
  Name: string;
  EffectType: string;
  Date: string;
  EndDate: string;
  Correlations: ImportedHistoricEventCorrelationDTO[];
}

export class ImportedHistoricBaseEventDTO {
  ImportedHistoricBaseEventId: string;
  ForecastVersionId: string;
  HistoricBaseEventId: string;
  Name: string;
  Type: string;
  Location: string;
  Events: ImportedHistoricEventDTO[];
  Order: number;
}

export class CreateImportedHistoricEventDTO {
  @Def() ImportedHistoricEventId: string;
  @Def() Name: string;
  @Def() EffectType: string;
  @Def() Date: string;
  @Def() EndDate?: string;
  @Def() Correlations: ImportedHistoricEventCorrelationDTO[];
}

export class CreateImportedHistoricBaseEventDTO {
  @Def() HistoricBaseEventId: string = null;
  @Def() ForecastVersionId: string;
  @Def() Name: string;
  @Def() Type: string;
  @Def() Location: string;
  @Def() Events: CreateImportedHistoricEventDTO[];
}

export class UpdateImportedHistoricBaseEventDTO extends CreateImportedHistoricBaseEventDTO {
  @Def() ImportedHistoricBaseEventId: string;
}

