import { ChangeDetectorRef, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { WikiActions } from '@core/store/wiki/wiki.actions';
import { WikiPageDTO } from '@core/store/wiki/wiki.entities';
import { WikiService } from '@core/store/wiki/wiki.service';
import { LightDarkModeType } from '@shared/components/light-dark-button/light-dark-button.component';
import { ToggleLightDarkMode } from '@shared/components/light-dark-button/light-dark-button.component.actions';
import { ResizeOutput } from '@shared/directives/resizable.directive';

@Component({
  selector: 'indicio-wiki-sidebar',
  templateUrl: './wiki-sidebar.component.html',
  styleUrls: ['./wiki-sidebar.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class WikiSidebarComponent {

  @Input() scrollPosition: number = 0;

  public mode: LightDarkModeType = 'light';
  public activePage: WikiPageDTO = null;

  public get loggedIn$() { return this.authService.loggedIn$; }

  /* Drag elems */
  private minAllowed: number = 210;
  private sidebarElem: HTMLElement;
  private contentElem: HTMLElement;

  public constructor(
    private el: ElementRef,
    private actions: ActionService,
    public wikiService: WikiService,
    private cd: ChangeDetectorRef,
    public authService: AuthFrontendService,
  ) {
    this.actions.dispatched(WikiActions.OpenInSidebar).subscribe((e: WikiActions.OpenInSidebar) => {
      this.wikiService.openInSidebar = true;
      this.open(e.slug);
    });

    this.actions.dispatched(WikiActions.Pinned).subscribe((e: WikiActions.Pinned) => {
      this.activePage = e.page;
      this.wikiService.openInSidebar = true;
      setTimeout(() => this.setup(), 0);
    });

    this.actions.dispatched(WikiActions.CloseSidebar).subscribe((e: WikiActions.CloseSidebar) => {
      this.close(true);
    });

    this.actions.dispatched(ToggleLightDarkMode).subscribe((e: ToggleLightDarkMode) => {
      this.mode = e.state;
      this.cd.detectChanges();
    });
  }

  public unpin() {
    this.wikiService.openInSidebar = false;
    this.wikiService.open(this.activePage.Slug);
    this.close(true);
  }

  public back() {
    this.open(this.wikiService.previousPage());
  }

  public open(slug: string) {
    this.wikiService.getOrFetchPage(slug)
      .then(dto => {
        this.close();
        this.wikiService.lastOpenedSlug = slug;
        this.activePage = dto;
        setTimeout(() => this.setup(), 0);
      });
  }

  private setup() {
    this.setupContentHeight();
    this.scrollContentToTop();
    this.minAllowed = 245;
    if (this.checkIfContentIsOutside()) {
      this.resize({ Id: '1', NewSize: this.minAllowed });
    }
    this.sidebarElem = this.el.nativeElement.querySelector('.wiki-sidebar-container') as HTMLElement;
  }

  private setupContentHeight() {
    const titleElem = this.el.nativeElement.querySelector('.wiki-title');
    const bbox = titleElem.getBoundingClientRect();
    const margin = bbox.top + 10; // 10px extra margin
    const height = bbox.height + margin;
    this.contentElem = this.el.nativeElement.querySelector('.wiki-content');
    this.contentElem.style.maxHeight = `calc(100% - ${height}px)`;
    this.contentElem.style.height = `calc(100% - ${height}px)`;
  }

  private scrollContentToTop() {
    this.contentElem.scrollTop = 0;
  }

  /**
   * Resize the dragbar by making it "delta" pixels bigger or smaller.
   * @returns
   * @param e
   */
  public resize(e: ResizeOutput) {
    const newWidth = Math.max(Math.min(e.NewSize, 1000), this.minAllowed);
    /* Set the new width */
    this.sidebarElem && (this.sidebarElem.style.width = newWidth + 'px');
    /* After we have renderd the change (setTimeout lets the DOM run through a cycle), we check for updated min-allowed. */
    setTimeout(() => {
      this.updateMinAllowed();
      this.setupContentHeight();
    }, 0);
  }

  private updateMinAllowed() {
    /* If the minAllowed number is changed from the default, disallow further updates - we have found our minimum. */
    if (this.minAllowed !== 210) { return; }
    /* We wanted it smaller, but contentElem is 'capped' somewhere depending on equations. Check if we reached the limit. */
    this.checkIfContentIsOutside();
  }

  private checkIfContentIsOutside() {
    const contentRect = this.contentElem.getBoundingClientRect();
    if (contentRect.right > window.innerWidth) {
      this.minAllowed = contentRect.width + 30; // 20 = padding and scroll
      return true;
    }
  }

  public close(exitSidebar: boolean = false) {
    this.activePage = null;
    this.wikiService.openInSidebar = !exitSidebar;
  }
}
