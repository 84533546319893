<indicio-modal-base [child]="this"
                    modalTitle="Add API Key for {{ providerName }}"
                    [loading]=isLoading>

  <div *ngIf="!isLoading">
    <span [innerHTML]="helpText | safeHtml"></span>
    <div class="extra-margin-top">
      <form #f="ngForm"
            (ngSubmit)="setKey()">
        <mat-form-field>
          <input type="text"
                 matInput
                 class="material"
                 placeholder="Enter API key"
                 name="apikey"
                 [pattern]="regEx"
                 [(ngModel)]="newApiKey">
        </mat-form-field>
        <button type="button"
                class="btn btn-confirm outline blue"
                (click)="close()">
          Cancel
        </button>
        <button type="submit"
                class=" btn btn-confirm right"
                [disabled]="!regEx.test(newApiKey)">
          <i *ngIf="pending"
             class="ion-load-c spinner"></i>&nbsp;
          <span>Save</span>
        </button>
      </form>
    </div>
  </div>
</indicio-modal-base>
