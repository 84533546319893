import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { MonthPlotOptions } from '@shared/components/graphs/monthplot/monthplot.options';
import { SeasonalValueDTO } from '@core/entities/dtos/plot-value-dto';
import { DetectedSeasonalOutlierDTO } from '@core/store/forecast-variable/models/forecast-variable-model';
import { DateFormatPipe } from '@shared/modules/pipes';

export namespace MonthplotMapper {

  import Bucket = MonthPlotOptions.Bucket;

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
  const daysInMonth = Array.from(Array(31), (_, i) => (i + 1).toString());
  const weekDays = ['M', 'T', 'W', 'T', 'F'];
  const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  export function getLabels(periodicity: PeriodicityType, component: number = 3) {
    if (periodicity === 'month') {
      return months;
    } else if (periodicity === 'quarter') {
      return quarters;
    } else if (periodicity === 'day') {
      return component === 3 ? days : component === 2 ? daysInMonth : months;
    } else if (periodicity === 'weekday') {
      return component === 3 ? weekDays : component === 2 ? daysInMonth : months;
    }
  }

  export function mapValues(
    values: SeasonalValueDTO[],
    outliers: DetectedSeasonalOutlierDTO[],
    periodicity: PeriodicityType,
    log: boolean,
    variableLatestDate: Date,
    firstYear: number,
    lastYear: number,
    component: number,
    datePipe: DateFormatPipe
  ) {
    const labels = getLabels(periodicity, component);
    const dailyDayPlot = (periodicity === 'weekday' || periodicity === 'day') && component === 1;

    if (dailyDayPlot) {
      values = meanPerMonth(values);
      periodicity = 'month';
    }

    const componentBuckets: MonthPlotOptions.Bucket[] = [];
    const componentMaxBuckets: MonthPlotOptions.Bucket[] = [];
    const componentMinBuckets: MonthPlotOptions.Bucket[] = [];
    const historicBuckets: MonthPlotOptions.Bucket[] = [];
    const forecastBuckets: MonthPlotOptions.Bucket[] = [];
    const irregularBuckets: MonthPlotOptions.Bucket[] = [];
    const tickLabels: string[][] = [];
    for (let i = 0; i < labels.length; i++) {
      componentBuckets.push(new Bucket());
      componentMaxBuckets.push(new Bucket());
      componentMinBuckets.push(new Bucket());
      historicBuckets.push(new Bucket());
      irregularBuckets.push(new Bucket());
      forecastBuckets.push(new Bucket());

      tickLabels[i] = [];
    }

    let firstForecasted = Array.from(Array(labels.length), _ => true);
    const startTimestamp = Date.UTC(firstYear, 0, 1);
    const endTimestamp = Date.UTC(lastYear + 1, 0, 1) - 1;
    values.forEach(v => {
      if (v.D.getTime() < startTimestamp || v.D.getTime() > endTimestamp) {
        return;
      }
      const bucket = dateToBucket(v.D, periodicity, component);
      const comp = component === 1 ? v.C1 : component === 2 ? v.C2 : v.C3;
      if (v.D <= variableLatestDate) {
        const outlier = outliers.find(x => x.Date.getTime() === v.D.getTime());
        const si = log ? comp * v.I : comp + v.I;
        if (outlier !== undefined) {
          historicBuckets[bucket].Values.push(new MonthPlotOptions.ValueModel(si, true, true, outlier.Type.toUpperCase()));
        } else {
          historicBuckets[bucket].Values.push(new MonthPlotOptions.ValueModel(si));
        }

        forecastBuckets[bucket].Values.push(new MonthPlotOptions.ValueModel(null, false));
        componentBuckets[bucket].Values.push(new MonthPlotOptions.ValueModel(comp));
        irregularBuckets[bucket].Values.push(new MonthPlotOptions.ValueModel(v.I));
        if (dailyDayPlot) {
          componentMaxBuckets[bucket].Values.push(new MonthPlotOptions.ValueModel(v.C2));
          componentMinBuckets[bucket].Values.push(new MonthPlotOptions.ValueModel(v.C3));
        }
      } else {
        if (firstForecasted[bucket]) {
          forecastBuckets[bucket].Values.pop();
          const value = componentBuckets[bucket].Values.length > 0 ? componentBuckets[bucket].Values.last().Value : comp;
          forecastBuckets[bucket].Values.push(new MonthPlotOptions.ValueModel(value, false));
        }
        firstForecasted[bucket] = false;
        forecastBuckets[bucket].Values.push(new MonthPlotOptions.ValueModel(comp));
      }

      tickLabels[bucket].push(datePipe.transform(v.D, periodicity));
    });

    let componentBase = true;
    let historicBase = false;
    for (let i = 0; i < labels.length; i++) {
      if (historicBuckets[i].Values.length === 1) {
        historicBuckets[i].Values.push(new MonthPlotOptions.ValueModel(historicBuckets[i].Values[0].Value, false));
        historicBase = true;
      }
      if (componentBuckets[i].Values.length === 1) {
        componentBuckets[i].Values.push(new MonthPlotOptions.ValueModel(componentBuckets[i].Values[0].Value, false));
        componentBase = false;
      }
      if (irregularBuckets[i].Values.length === 1) irregularBuckets[i].Values.push(new MonthPlotOptions.ValueModel(irregularBuckets[i].Values[0].Value, false));
      historicBuckets[i].BaseValue = historicBuckets[i].Values.map(x => x.Value).avg();
      componentBuckets[i].BaseValue = componentBuckets[i].Values.map(x => x.Value).avg();
      irregularBuckets[i].BaseValue = irregularBuckets[i].Values.map(x => x.Value).avg();
    }

    let componentSeries = new MonthPlotOptions.SeriesModel('Seasonal component' + (dailyDayPlot ? ' (mean)' : ''), '#f99', componentBuckets, 'line', componentBase);
    let componentMaxSeries = new MonthPlotOptions.SeriesModel('Seasonal component (max)', '#a99', componentMaxBuckets, 'line', false);
    let componentMinSeries = new MonthPlotOptions.SeriesModel('Seasonal component (min)', '#a99', componentMinBuckets, 'line', false);
    let historicSeries = new MonthPlotOptions.SeriesModel('Historic data', '#99f', historicBuckets, 'bars', historicBase);
    historicSeries.CircleColor = '#f99';
    let irregularSeries = new MonthPlotOptions.SeriesModel('Irregular component' + (dailyDayPlot ? ' (mean)' : ''), '#9f9', irregularBuckets, 'line', false);
    let forecastSeries = new MonthPlotOptions.SeriesModel('Forecasted component', '#99f', forecastBuckets, 'line', false);

    return { componentSeries, componentMaxSeries, componentMinSeries, historicSeries, irregularSeries, forecastSeries, tickLabels, labels };
  }

  function dateToBucket(d: Date, periodicity: PeriodicityType, component: number = 3) {
    if (periodicity === 'month') {
      return d.getMonth();
    } else if (periodicity === 'quarter') {
      return Math.floor(d.getMonth() / 3);
    } else {
      return component === 3 ? getDay(d) : (component === 2 ? d.getDate() - 1 : d.getMonth());
    }
  }

  function getDay(d: Date) {
    const day = d.getDay() - 1;
    return day === -1 ? 6 : day;
  }

  function meanPerMonth(values: SeasonalValueDTO[]) {
    const result: SeasonalValueDTO[] = [];
    let sum = values[0].C1;
    let sumI = values[0].I;
    let max = values[0].C1;
    let min = values[0].C1;
    let count = 1;
    let month = values[0].D.getMonth();
    for (let i = 1; i < values.length; i++) {
      const thisMonth = values[i].D.getMonth();
      if (month === thisMonth) {
        sum += values[i].C1;
        sumI += values[i].I;
        count++;
        max = Math.max(max, values[i].C1);
        min = Math.min(min, values[i].C1);
      } else {
        result.push({
          C1: sum / count, C2: max, C3: min, D: values[i - 1].D, I: sumI / count, T: 0, m: values[i - 1].m
        });
        sum = values[i].C1;
        sumI = values[i].I;
        count = 1;
        max = values[i].C1;
        min = values[i].C1;
        month = thisMonth;
      }
    }
    return result;
  }
}
