import { Def } from '@core/decorators/def';
import { IFile } from '@core/interfaces/if-file';
import { RemovedStatus } from '@core/types/removed.status.type';
import { FileAccessDTO } from '../dtos/file-access.dto';
import { VariableSheetDTO } from '../dtos/variable-sheet-dto';
import { RemoteFileVersionModel } from './remote-file-version.model';

export class RemoteFileModel implements IFile {

  @Def() RemoteFileId: string;
  @Def() CreatedDate: Date;
  @Def() FileName: string;
  @Def() OwnerEmail: string;
  @Def() CompanyId: string;
  @Def() ParsedData: VariableSheetDTO[];
  @Def() Separator: string;
  @Def() Versions: RemoteFileVersionModel[];
  @Def() Extension: string;
  @Def() Used: boolean;
  @Def() SourceType: 'sharepoint';
  @Def() Users: FileAccessDTO[] = [];

  removedStatus: RemovedStatus;
  errorStatus: string;
  loading: boolean = false;
  forceUpdate: boolean;

  fetched = false;

  public getModelId(): string { return this.RemoteFileId; }
  public getSourceType() { return this.SourceType; }
  public latestVersion() { return this.Versions.length ? this.Versions.last() : null; }
  public get ObjectName() { return this.FileName; }
  public get numberOfVersions() { return this.Versions.length; }
  public get latestCreatedDate() { return this.latestVersion() ? this.latestVersion().CreatedDate : null; }
}


