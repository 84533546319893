import { Component, Input } from '@angular/core';

@Component({
  selector: 'indicio-checkmark',
  templateUrl: './checkmark.component.html',
  styleUrls: ['./checkmark.component.less']
})
export class CheckmarkComponent {

  @Input() color: string;
  @Input() size: string;

  public getClassColor(className: string) {
    return `${className}--${this.color}`;
  }
}
