import { ReportModel } from '@core/store/reports/models/report.model';
import { DateUtils } from '@shared/utils/date.utils';
import { OverviewReport, OverviewReportEntry } from '../models/overview-model';

export function OverviewTemplateMapper(report: ReportModel) {
  const overview = new OverviewReport();
  overview.Name = report.Name;
  overview.Entries = report.Entries.map(entry => {
    return Object.assign(new OverviewReportEntry, <OverviewReportEntry> {
      Name: entry.Name,
      Periodicity: entry.Periodicity,
      ForecastStartDate: DateUtils.newMoment(entry.ForecastStartDate),
      LastForecastedDate: DateUtils.newMoment(entry.LastForecastedDate),
      ForecastId: entry.ForecastId,
      ForecastVersionId: entry.ForecastVersionId,
      HistoricValues: entry.HistoricValues,
      ForecastedValues: entry.ForecastResults?.find(x => x.ForecastResultId === entry.SelectedResultId)?.Values || [],
      StartDate: entry.HistoricValues[0].m,
      IsPercent: entry.IsPercent,
      ForecastVariableTransform: entry.ForecastVariableTransform,
      EndDate: entry.HistoricValues.filter(x => !x.IF).last().m,

      // Frontend stuffz
      noResultsAvailable: entry.ForecastResults.length === 0,
    });
  });

  const dates = [];
  overview.Entries.forEach(entry => {
    entry.HistoricValues.slice(-Math.min(entry.HistoricValues.length, 20)).concat(entry.ForecastedValues).forEach(value => {
      if (dates.find(x => x.isSame(value.m, 'day'))) { return; }
      dates.push(value.m);
    });
  });
  overview.dates = Array.from(dates).sort((a, b) => a.diff(b));

  overview.dates.forEach((date, i) => {
    if (!overview.dates[i + 1]) { return; }
    const next = overview.dates[i + 1];
    if (next.diff(date, 'month') < 12) { return; }
    overview.dateJumps += 1;
  });

  return overview;
}
