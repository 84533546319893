import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface TestUserDialogData {
  UserCount: number;
  CompanyCount: number;
}

@Component({
  selector: 'indicio-test-user-dialog',
  templateUrl: 'test-user.dialog.html',
})
export class TestUserDialogComponent {
  public static Id: string = 'TestUserDialogComponent';

  constructor(
    public dialogRef: MatDialogRef<TestUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TestUserDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(this.data);
  }
}
