import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { SLGOptions } from '@shared/components/graphs/slg/slg.options';
import { DateUtils } from '@shared/utils/date.utils';

export interface AdminSourceGraphDialogData {
  SourceVariableId: string;
}

@Component({
  selector: 'indicio-admin-source-graph-dialog',
  templateUrl: 'source-graph.dialog.html',
})
export class AdminSourceGraphDialogComponent {
  public static Id: string = 'AdminSourceGraphDialogComponent';

  public graphData: SLGOptions.Options = null;

  public sourceVariable: SourceVariableModel = null;

  constructor(
    public dialogRef: MatDialogRef<AdminSourceGraphDialogComponent>,
    private sourceService: SourceVariableFrontendService,
    @Inject(MAT_DIALOG_DATA) public data: AdminSourceGraphDialogData) {
    this.sourceService.getOrFetchSourceVariablesInBulk([data.SourceVariableId]).then((sv) => {
      this.sourceVariable = sv[0];
      this.setupSLG();
    });
  }

  private setupSLG() {
    const slgOptions = new SLGOptions.Options();
    slgOptions.TimeAxis = new SLGOptions.AxesOptions();
    slgOptions.Periodicity = this.sourceVariable.Periodicity;
    let measurements = [this.sourceVariable];
    let models = measurements.map(m => {
      return {
        ObjectId: m.SourceVariableId,
        Color: 'blue',
        Name: m.Name,
        values: m.getBaseVersion().PlotValues.map((x, i) => ({ Value: x.V, Date: x.D }))
      };
    });
    models.forEach((line, i) => {
      const slgData = new SLGOptions.Data();
      slgData.AxisPosition = SLGOptions.AxisPosition.left;
      slgData.Color = line.Color;
      slgData.StrokeWidth = 2;
      slgData.Name = line.Name;
      slgData.Data = line.values.map((v, j) => {
        if (i === 0) {
          slgOptions.TimeAxis.Dates.push(DateUtils.newDate(v.Date));
        }
        return {
          moment: DateUtils.newMoment(v.Date),
          Date: DateUtils.newDate(v.Date),
          Value: v.Value
        };
      });
      slgData.Data.sort((a, b) => a.Date.getTime() - b.Date.getTime());
      slgOptions.Data.push(slgData);
    });
    slgOptions.TimeAxis.Dates.sort((a, b) => a.getTime() - b.getTime());
    const allValueModels = slgOptions.Data.map(d => d.Data);
    const allValues = allValueModels.map(vm => vm.map(m => m.Value)).reduce((acc, curr) => acc.concat(...curr), []);
    const filtered = allValues.filter(v => !isNaN(+v));
    const max = Math.max(...filtered);
    const min = Math.min(...filtered);

    // Setup Time axis
    slgOptions.TimeAxis.Position = SLGOptions.AxisPosition.bottom;
    slgOptions.TimeAxis.Min = slgOptions.TimeAxis.Dates[0];
    slgOptions.TimeAxis.Max = slgOptions.TimeAxis.Dates.last();
    slgOptions.TimeAxis.TickCount = Math.max(this.sourceVariable.Values.length, 7);

    // Setup left axis
    slgOptions.LeftAxis = new SLGOptions.AxesOptions();
    slgOptions.LeftAxis.Position = SLGOptions.AxisPosition.left;
    slgOptions.LeftAxis.Min = min;
    slgOptions.LeftAxis.Max = max;
    slgOptions.LeftAxis.TickCount = 4;
    slgOptions.LeftAxis.Color = 'black';

    slgOptions.Width = 550;
    slgOptions.Height = 275;
    slgOptions.DotSize = 0;
    slgOptions.Margins = { bottom: 25, left: 50, right: 15, top: 5 };
    this.graphData = slgOptions;
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(true);
  }
}
