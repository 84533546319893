import { Component } from '@angular/core';

import { StatusService } from '@core/services/status/status.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { StartDateModalOptions } from './start-date-modal.options';

@Component({
  selector: 'indicio-start-date-modal',
  templateUrl: './start-date-modal.component.html'
})
export class StartDateModalComponent extends ModalModelComponent {
  // Input (from options)
  forecastVersion: ForecastVersionModel;
  variable: ForecastVariableModel;

  // Modal fields
  currentStartDate: Date;
  suggestedStartDate: Date;
  variableLastDate: Date;

  constructor(
    protected store: Store,
    public appearance: AppearanceService,
    private status: StatusService,
    private forecastService: ForecastFrontendService
  ) {
    super();
  }

  public setOptions(options: StartDateModalOptions) {
    this.forecastVersion = options.forecast;
    this.variable = options.variable;
    this.currentStartDate = this.forecastVersion.StartDate;
    this.variableLastDate = this.variable.LatestDate;
    this.setSuggestedStartDate();
    this.isLoading = false;
  }

  public back() {
    this.close();
  }

  public change() {
    this.pending = true;
    this.forecastService.updateForecastVersion(this.forecastVersion, { StartDate: this.suggestedStartDate.toString() })
      .then(() => {
        this.pending = false;
        this.close();
        this.status.setMessage('Start date changed', 'Success');
      })
      .catch(error => {
        this.status.setError(error, true);
        this.pending = false;
      });
  }

  public setSuggestedStartDate() {
    const periodicity = this.forecastVersion.periodicity;
    const startDate = periodicity.getSuggestedStartDate(this.variable.LatestDate);
    this.suggestedStartDate = startDate;
  }
}


