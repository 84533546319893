import { Def } from '@core/decorators/def';
import { RemovalWarningType } from './removal-warning.dto';

export class RemovalWarning<T> {
  Type: RemovalWarningType;
  Content: T;

  constructor(type: RemovalWarningType, content: T) {
    this.Type = type;
    this.Content = content;
  }
}

export class ForecastResultUsedWarning {
  @Def() ParentForecastName: string;
  @Def() ParentProjectName: string;
  @Def() FVarName: string;
  @Def() ForecastName: string;
  @Def() ProjectName: string;
  @Def() ForecastId: string;
  @Def() ForecastVersionId: string;
}

export class ForecastUsedInReportWarning {
  @Def() ProjectId: string;
  @Def() ProjectName: string;
  @Def() ForecastId: string;
  @Def() ForecastName: string;
  @Def() ReportId: string;
  @Def() ReportName: string;
}
