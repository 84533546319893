<div class="company-create-preview-tab">
  <h3 class="extra-margin-top margin-bottom-20">
    Preview new company
    <div class="subtitle">You are about to create a new company with the following settings</div>
  </h3>

  <div style="--grid-template-global: 120px 1fr">
    <div class="grid-system">
      <div class="grid-label">Name</div>
      <div [ngClass]="{ error: !company.Name }">{{ company.Name || 'No name set yet' }}</div>
    </div>
    <div class="grid-system">
      <div class="grid-label">Max users</div>
      <div>{{ company.MaxUsers }}</div>
    </div>
    <div class="grid-system">
      <div class="grid-label">Country of origin</div>
      <div [ngClass]="{ red: !company.CountryCode }">{{ envService.getCountry(company.CountryCode).Display }}</div>
    </div>
    <div class="grid-system"
         *ngIf="authService.isAdmin && envService.LicenseTypes?.length">
      <div class="
         grid-label">License type</div>
      <div>{{ envService.getLicense(company.LicenseType).Display }}</div>
    </div>
    <div class="grid-system"
         *ngIf="authService.isAdmin && selectedSupportUsers?.length">
      <div class="
         grid-label">Support users</div>
      <div>
        <ng-container *ngFor="let user of selectedSupportUsers; let last = last">
          <span>&bull; {{ user.Display }}</span>
          <br *ngIf="!last">
        </ng-container>
      </div>
    </div>
  </div>

  <div class="margin-top"
       *ngIf="company.Trial">It is set as trial and the trial will expire <span class="dotted-underline">{{
      company.TrialExpirationDate | dateFormat }}</span>
  </div>

  <hr style="margin: 20px 0;">

  <div class="margin-top"
       *ngIf="validUsers.length">
    <div style="margin: 10px 0;">The following users will be invited</div>
    <indicio-data-table columnTemplate="50px 1fr 120px">
      <div title-row>
        <div column><span title>#</span></div>
        <div column
             left><span title>Email</span></div>
        <div column
             left><span title>Role</span></div>
      </div>
      <div style="max-height: 150px; overflow: auto;">
        <ng-container *ngFor="let user of validUsers; let index = index">
          <div row>
            <div column
                 class="center-vertical"
                 style="justify-content: center;"><span>{{ index + 1 }}</span></div>
            <div class="center-vertical crop-string"
                 [title]="user.Email"
                 column><span class="ok">{{ user.Email }}</span></div>
            <div column
                 class="center-vertical">
              <span>{{ user.CompanyRole.replace('company','') }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </indicio-data-table>

    <ng-container *ngIf="invalidUsers.length">
      <div style="margin: 10px 0;">The following emails are invalid, please recheck list</div>
      <div class="error"
           *ngFor="let user of invalidUsers">{{ user.Email }}</div>
    </ng-container>
  </div>
</div>
