import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { DialogV2BaseService } from '@dialogs/base/dialogs.V2.base-service';


@Injectable({ providedIn: 'root' })
export class GlobalDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public getOpenDialogs() {
    return this.dialog.openDialogs.map(x => x.id);
  }
}
