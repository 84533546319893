
export class GetFileColumnDiffDTO {
  CompanyId: string;
  FileVersionId: string;
  SheetIndex: number;
  ColumnIndex: number;
  OldPaddingCount: number;
  NewRows: string[];
}

export class ColumnRowChangeDTO {
  Row: number;
  Old: string;
  New: string;
}

export class FileColumnDiffDTO {
  Offset: number;
  ValueCount: number;
  Above20: boolean;
  Above3: boolean;
  Changes: ColumnRowChangeDTO[];
}

export class VariableUpdateInfoDTO {
  SourceVariableId: string;
  SourceVariableMetaId: string;
  Name: string;
  Found: boolean;
  OldSheet: number;
  OldValueIndex: number;
  OldDateIndex: number;
  OldHashStartRow: number;
  // Tuple with (sheet, column, hash-start)
  NewHashStartRows: { m_Item1: number, m_Item2: number, m_Item3: number; }[];
  NewLocations: NewLocationInfoDTO[];
}

export class NewLocationInfoDTO {
  NewSheet: number = -1;
  NewValueIndex: number = -1;
  NewDateIndex: number = -1;
  NewHashStartRow: number = -1;
  Diff: FileColumnDiffDTO = null;
  Exact: boolean = false;
  Offset: number;

  forbidDiff: boolean = false;
  diffLoading: boolean = false;
}

export class ConfirmedNewLocationDTO extends NewLocationInfoDTO {
  SourceVariableId: string;
  SourceVariableMetaId: string;
  SkipUpdate: boolean = false;
}


