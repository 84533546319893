import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusService } from '@core/services/status/status.service';
import { Base64FileDTO } from '@core/store/file/dtos/file-dto';
import { WikiAssetDTO } from '@core/store/wiki/wiki.entities';
import { WikiService } from '@core/store/wiki/wiki.service';
import { AdminWikiService } from '@modules/admin/components/content/wiki/wiki-admin.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { FileUtils } from '@shared/utils/file.utils';

export interface AdminManageWikiAssetsDialogData { }

@Component({
  selector: 'indicio-manage-wiki-assets-dialog',
  templateUrl: 'manage-assets.dialog.html',
  styleUrls: ['manage-assets.dialog.less'],
})
export class AdminManageWikiAssetsDialogComponent {
  public loadingAssets = true;
  public files: Base64FileDTO[] = [];

  public filterRegex: RegExp;

  public initLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<AdminManageWikiAssetsDialogComponent>,
    private adminService: AdminWikiService,
    public wikiService: WikiService,
    private status: StatusService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: AdminManageWikiAssetsDialogData) {
    this.getAssets()
      .finally(() => this.loadingAssets = false);
  }

  public getFormattedSize(size: number) {
    return FileUtils.formatBytes(size, 0);
  }

  public filter(filter: string) {
    this.filterRegex = new RegExp(filter, 'i');
  }

  public useAsset(asset: WikiAssetDTO) {
    this.wikiService.getOrFetchAsset(asset.AssetId)
      .then(asset => this.dialogRef.close(asset));
  }

  public askRemoveAsset(asset: WikiAssetDTO) {
    this.dialogService.openConfirmDialog({
      Message: 'Are you sure you want to remove this asset?',
      Title: 'Remove asset',
    }).subscribe(answer => {
      if (!answer) { return; }
      this.removeAsset(asset);
    });
  }

  public viewAsset(asset: WikiAssetDTO) {
    this.wikiService.getOrFetchAsset(asset.AssetId)
      .then(asset => {

        var image = new Image();
        image.src = `data:${asset.MimeType};base64,${asset.Base64String}`;

        var w = window.open('');
        w.document.write(image.outerHTML);

      });
  }

  close(): void {
    this.dialogRef.close(false);
  }


  //
  // File drop-zone functions
  //

  public fileChange(fileList: FileList) {
    this.resetList();
    Promise.all(this.getFileList(fileList))
      .then(files => files.map(file => this.adminService.createAsset(file)))
      .then(promises => Promise.all(promises))
      .then(assets => this.getAssets());
  }

  public allowDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  public drop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const inputElement = <HTMLInputElement> (document.querySelector('input[type=file]'));
    inputElement.files = event.dataTransfer.files;
    this.fileChange(inputElement.files);
    const box = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    box.classList.remove('active');
  }

  public toggleDragOver(state: boolean) {
    const element = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    if (state) {
      element.classList.add('active');
    } else {
      element.classList.remove('active');
    }
  }

  private getFileList(fileList: FileList) {
    this.initLoading = true;
    const promises: Promise<Base64FileDTO>[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const dto = Object.assign(new Base64FileDTO(), <Base64FileDTO> { Name: file.name, MimeType: file.type });
      const promise = new Promise<Base64FileDTO>((res, rej) => {
        const dataReader = new FileReader();
        dataReader.onloadend = () => {
          const body: string = <string> dataReader.result;
          dto.Base64String = body.substring(body.indexOf(',') + 1);
          res(dto);
        };
        dataReader.onerror = rej;
        dataReader.readAsDataURL(file);
      });
      promises.push(promise);
    }
    return promises;
  }

  /**
   *
   * Private functions
   *
   */
  private resetList() {
    this.files = [];
    this.status.removeMessage();
  }

  private removeAsset(asset: WikiAssetDTO) {
    this.adminService.removeAsset(asset.AssetId).then(() => {
      this.wikiService.assets = this.wikiService.assets.filter(x => x.AssetId !== asset.AssetId);
    });
  }

  private getAssets() {
    this.initLoading = true;
    return this.adminService.getAllAssets().then(assets => {
      this.wikiService.assets = [...assets];
      this.loadingAssets = false;
    }).finally(() => this.initLoading = false);
  }
}
