
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearStateAction } from '../auth/auth.actions';
import { CompanyActions } from '../company/company.actions';
import { AutoTaskAction } from './auto-task.actions';
import { AutoTaskInfoModel, AutoTaskModel } from './dtos/auto-task.model';



class AutoTaskStateModel {
  AutoTasks: AutoTaskModel[];
  Info: AutoTaskInfoModel;
}
@State<AutoTaskStateModel>({
  name: 'autotask',
  defaults: {
    AutoTasks: [],
    Info: null
  }
})
@Injectable()
export class AutoTaskState {

  @Selector()
  static autoTasks(state: AutoTaskStateModel) { return state.AutoTasks; }

  @Selector()
  static autoTaskInfo(state: AutoTaskStateModel) { return state.Info; }

  @Action(AutoTaskAction.GetInfoSuccess)
  getInfoSuccess(ctx: StateContext<AutoTaskStateModel>, action: AutoTaskAction.GetInfoSuccess) {
    ctx.patchState({ Info: action.info });
  }

  @Action(AutoTaskAction.AddMessage)
  addMessage(ctx: StateContext<AutoTaskStateModel>, action: AutoTaskAction.AddMessage) {
    const state = ctx.getState();
    const task = state.AutoTasks.find(x => x.AutoTaskId === action.taskId);
    if (!task) { return; }
    task.messages.push(action.message);
    ctx.patchState({ AutoTasks: state.AutoTasks });
  }

  @Action(AutoTaskAction.GetAllSuccess)
  getAllSuccess(ctx: StateContext<AutoTaskStateModel>, action: AutoTaskAction.GetAllSuccess) {
    ctx.patchState({ AutoTasks: action.tasks });
  }

  @Action(AutoTaskAction.GetSuccess)
  getSuccess(ctx: StateContext<AutoTaskStateModel>, action: AutoTaskAction.GetSuccess) {
    const state = ctx.getState();
    const task = state.AutoTasks.find(x => x.AutoTaskId === action.task.AutoTaskId);
    if (!!task) {
      action.task.messages = task.messages;
    }
    state.AutoTasks.addOrUpdate(action.task);
    ctx.patchState({ AutoTasks: state.AutoTasks });
  }

  @Action(AutoTaskAction.RemoveSuccess)
  removeSuccess(ctx: StateContext<AutoTaskStateModel>, action: AutoTaskAction.RemoveSuccess) {
    const state = ctx.getState();
    state.AutoTasks.removeById(action.taskId);
    ctx.patchState({ AutoTasks: state.AutoTasks });
  }

  @Action(AutoTaskAction.SetState)
  setState(ctx: StateContext<AutoTaskStateModel>, action: AutoTaskAction.SetState) {
    const state = ctx.getState();
    const task = state.AutoTasks.find(x => x.AutoTaskId === action.taskId);
    if (!task) { return; }
    task.State = action.state;
    state.AutoTasks.addOrUpdate(task);
    ctx.patchState({ AutoTasks: state.AutoTasks });
  }

  @Action([ClearStateAction, CompanyActions.ResetCompanyData])
  clearState(ctx: StateContext<AutoTaskStateModel>) {
    ctx.patchState({ AutoTasks: [], Info: null });
  }
}
