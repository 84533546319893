import { SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { EValueFactor, ValueUtils } from '@shared/utils/value.utils';

export namespace StepAhead {

  export class Model {

    dates: Date[];
    factor: EValueFactor;
    values: number[];
    _values: number[]; // Saved
    display: number[] = [];
    variable: ForecastVariableModel;

    public get name() { return this.variable.Name; }
    public get variableId() { return this.variable.ForecastVariableId; }
    public get valueCount() { return this.values.length; }
    public get allValuesSet() { return !this.values.some(x => x == null); }
    private get multiplier() { return this.factor.factor ? this.factor.factor : 1; }

    public setSaved() { this._values = this.values.slice(); }

    public isModified() { return JSON.stringify(this._values) !== JSON.stringify(this.values); }

    public restore(fromSource: boolean) {
      this.values = fromSource
        ? this.variable.FutureValues.map(x => x.V)
        : this._values.slice();
      this.setDisplay();
    }

    public setDisplay() {
      this.display = this.values.map(x => {
        if (x == null) { return null; }
        return ValueUtils.FormatNumberBasedOnFactor(x, this.factor.factor);
      });
    }

    /** This function expects the input to be from the the indicio-number-input component.
     * If used to setup values, do not forget to handle the factor correctly.
     */
    public setStep(step: number, newValue: number) {
      const value = newValue * this.multiplier;
      this.values[step] = newValue != null ? value : null;
      this.setDisplay();
    }

    public getSetValues() {
      return this.values.map((v, i) => {
        if (v == null) { return null; }
        return <SimplePlotValue> {
          D: this.dates[i],
          V: v
        };
      }).filter(x => x != null);
    }
  }

  export function MapFromForecastVariable(model: ForecastVariableModel) {

    const stepModel = new Model();
    const hasExoValuesSet = !!model.ExogenousFutureValues?.length;

    stepModel.variable = model;
    stepModel.dates = model.FutureValues.map(x => x.D);
    stepModel.values = hasExoValuesSet
      ? model.ExogenousFutureValues
      : model.FutureValues.map(x => x.V);

    // Get all future values and some of the last observed values
    const factorValues = [
      ...stepModel.values,
      ...model.ForecastVariableValues.slice(-10).map(x => x.V)
    ];

    stepModel.factor = ValueUtils.GetMostCommonValueFactor(factorValues.filter(x => x != null));
    stepModel.values.forEach((val, idx) => {
      if (val == null) { return stepModel.display[idx] = null; }
      const abbreviated = ValueUtils.FormatNumberBasedOnFactor(val, stepModel.factor.factor);
      stepModel.display[idx] = abbreviated;
    });

    stepModel.setSaved();
    return stepModel;
  }
}
