<indicio-dialog>
  <h1 mat-dialog-title>Create forecast</h1>
  <div mat-dialog-content>
    <ng-container [ngTemplateOutlet]="createTemplate"
                  *ngIf="initialized; else loadingTemplate">

    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">Close</button>
    <div [indicioTippy]="disabledText || null">
      <button mat-flat-button
              color="primary"
              [disabled]="!project || !createDto.Name || forecastNameMustChange || pending"
              (click)="save()"
              cdkFocusInitial>
        <indicio-spinner *ngIf="pending"></indicio-spinner>
        Create
      </button>
    </div>
  </div>
</indicio-dialog>

<ng-template #createTemplate>
  <div class="orange margin-bottom-10 italic"
       *ngIf="unsaved">
    Unsaved version
  </div>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Forecast name"
                            optionSubtitle="Enter the name of the forecast"
                            textWidth=7
                            optionsWidth=5>

    <mat-form-field>
      <input type="text"
             class="material"
             matInput
             autocomplete="off"
             [ngModel]="createDto.Name"
             (ngModelChange)="changeForecastName($event)"
             name="Name"
             indicioAutoFocusInput
             maxlength="64"
             required>
      <mat-hint *ngIf="forecastNameMustChange">
        <span class="error-text">A forecast with that name already exists in the target project.</span>
      </mat-hint>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Project"
                            optionSubtitle="Select project for the forecast"
                            textWidth=7
                            optionsWidth=5
                            *ngIf="needProject">

    <mat-form-field>
      <mat-select [value]="project"
                  (selectionChange)="setProject($event)"
                  required
                  name="project">
        <mat-option [value]="project"
                    [title]="project.Name"
                    *ngFor="let project of projects | orderBy: 'Name'">
          {{ project.Name }}
          <i class="ion-briefcase"
             *ngIf="project.VisibilityLevelId === 'company'"></i>
        </mat-option>
      </mat-select>
      <mat-hint class="cursor-pointer"
                (click)="createProject()">Create project</mat-hint>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Periodicity"
                            optionSubtitle="Select forecast periodicity"
                            textWidth=7
                            optionsWidth=5>
    <mat-form-field>
      <mat-select [(value)]="createDto.Periodicity"
                  [indicioTippy]="data.UserCanChangeSettings ? null : 'Setting currently locked.'"
                  [disabled]="!data.UserCanChangeSettings"
                  (selectionChange)="onNewHorizon()">
        <mat-option [value]="periodicity.Value"
                    *ngFor="let periodicity of validPeriodicities">{{ periodicity.Display }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row [stacked]="true"
                            optionTitle="Horizon"
                            optionSubtitle="Select number of forecasted points"
                            textWidth=7
                            optionsWidth=5>
    <mat-form-field>
      <mat-select [(ngModel)]="createDto.Horizon"
                  [indicioTippy]="data.UserCanChangeSettings ? null : 'Setting currently locked.'"
                  [disabled]="!data.UserCanChangeSettings"
                  name="horizon">
        <mat-option [value]="point"
                    [title]="point"
                    *ngFor="let point of forecastPoints">{{ point }}</mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <ng-container *ngIf="!data.HideTemplateOption">
    <div class="row"
         style="margin-top: 20px;">
      <div class="col-xs-12"></div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div data-test-id='create-forcast-from-template-button'
             class="blue cursor-pointer"
             (click)="createFromTemplate()">
          Browse Templates
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <indicio-spinner></indicio-spinner> Loading...
</ng-template>
