import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { AlphaCryptoModalOpts } from './alpha-crypto-modal.options';

export class OpenAlphaCryptoModal {
  public static readonly type = '[MODAL] Open alpha crypto modal';
  data: AlphaCryptoModalOpts;
  constructor(
    forecastVersion: ForecastVersionModel
  ) {
    this.data = { forecastVersion };
  }
}
