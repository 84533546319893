<div class="hierarchical-tree-container"
     (scroll)="treeScrolled($event)">
  <ng-container *ngIf="!loadingContent; else loader">
    <ng-container *ngIf="treeNodes">
      <ng-container *ngTemplateOutlet="nodeListTemplate; context: { nodes: treeNodes, level: 0 }"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #nodeListTemplate
             let-nodes=nodes
             let-level=level>
  <ng-container *ngFor="let node of nodes; let i = index">
    <ng-container *ngTemplateOutlet="nodeTemplate; context: { node: node, level: level }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #nodeTemplate
             let-node=node
             let-level=level>
  <ng-container *ngIf="{ isTop: (level === 0), n: asTreeNode(node), l: +level } as info">
    <div class="node center-vertical cursor-pointer"
         [class]="{ active: info.n.isOpen || info.n.menuOpen }"
         *ngIf="shouldBeVisible(node)"
         [ngStyle]="{ 'grid-template-columns': (level * 20)  + 'px 30px 1fr 50px 28px' }">
      <!-- Empty col -->
      <ng-container *ngTemplateOutlet="empty"></ng-container>
      <!-- Chevron -->
      <div class="chevron"
           (click)="clickedNode(node)">
        <i [class]="node.isOpen ? 'ion-chevron-down' : 'ion-chevron-right'"></i>
      </div>
      <!-- Name -->
      <div class="crop-string"
           (click)="editRelation(node, true)">
        <span title="Navigate to forecast">
          {{ node.Relation.ForecastName }}
        </span>
      </div>
      <!-- Icons -->
      <div class="center-vertical"
           style="height: 30px; justify-content: end">
        <div (click)="node.state === 'ok' ? null : handleRelationErrors(node); $event.stopPropagation()"
             *ngIf="node.state !== 'ok'; else refrestVariableTemplate"
             [indicioTippy]="{ maxWidth: 600, content: node.valid ?
               node.invalidChildrenMessages.length
                 ? '<b>Problem with child nodes</b>: <br><br>' +node.invalidChildrenMessages.join('<br>')
                 : node.warnings.length ? node.warnings.join('<br>') : null
              : node.messages.length > 1
                ? node.messages.length + ' errors found, click to view'
                : node.messages[0] }"
             [ngStyle]="{ 'font-size': !node.valid || node.invalidChildrenMessages.length ? '17px' : null }"
             class="icon center-vertical"
             [class]="node.status === 'ok' ? 'cursor-default' : 'cursor-pointer'">
          <fa-icon [class]="
            node.valid && !node.invalidChildrenMessages.length
            ? node.warnings.length
              ? 'orange'
              : 'green'
            : 'red'"
                   [icon]="node.valid && !node.invalidChildrenMessages.length ? faCheck : faTriangle">
          </fa-icon>
        </div>
        <ng-template #refrestVariableTemplate>
          <div indicioTippy="New data available, click to update"
               (click)="openRefreshDialog(node); $event.stopPropagation();"
               *ngIf="node.canUpdateVariables; else empty">
            <i style="width: 30px; font-size: 20px; margin-right: 3px;"
               class="ion-loop"></i>
          </div>
        </ng-template>
        <div *ngIf="info.isTop; else empty"
             class="info-chip cursor-default"
             (click)="$event.stopPropagation()"
             indicioTippy="{{ env.getPeriodicity(node.Hierarchy.Periodicity).Display }} periodicity"
             style="align-items: unset;">{{ node.Hierarchy.Periodicity[0] }}
        </div>
      </div>
      <!-- Menu -->
      <div class="menu">
        <ng-container *ngTemplateOutlet="hierarchyOptionsTemplate; context: { node, isTop: info.isTop }"></ng-container>
      </div>
    </div>

    <div *ngIf="node.isOpen">
      <ng-container *ngTemplateOutlet="nodeListTemplate; context: { nodes: node.children, level: level + 1 }">
      </ng-container>
      <ng-container *ngTemplateOutlet="addChildRowTemplate; context: { level: level + 1, node: node }">
      </ng-container>
      <ng-container *ngIf="info.isTop">
        <ng-container *ngIf="!node.Hierarchy.Reconciliations.length; else navToReconciliationTemplate">
          <ng-container *ngTemplateOutlet="addReconciliationRowTemplate; context: { node: node }">
          </ng-container>
        </ng-container>
        <ng-template #navToReconciliationTemplate>
          <div class="node center-vertical cursor-pointer"
               data-test-id="nav-to-reconciliation-btn"
               (click)="navToReconciliation(node)"
               style="grid-template-columns: 30px 1fr 28px">
            <div>
              <!-- Empty col -->
            </div>
            <div>
              <i class="ion-network green"
                 style="margin-right: 7px"></i>
              <span>View reconciliation</span>
            </div>
            <!-- Menu -->
            <div class="menu">
              <ng-container *ngTemplateOutlet="reconciliationOptsTemplate; context: { node }"></ng-container>
            </div>
          </div>
        </ng-template>

      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #addChildRowTemplate
             let-level="level"
             let-node="node">
  <div class="node center-vertical cursor-pointer"
       data-test-id="add-child-to-hierarchy-btn"
       [ngClass]="{ disabled: (level === 1 && node.missingForecast) || node.level === 5 }"
       [indicioTippy]="level === 1 && node.missingForecast
                        ? { content: 'You need to set a forecast on the top node first.', placement: 'right' }
                        :  node.level === 5 ? 'You can\'t add more than 5 child levels' : null"
       (click)="addNewChild(node)"
       [ngStyle]="{ 'grid-template-columns': (30 + (level - 1) * 20) + 'px 1fr' }">
    <!-- Empty col -->
    <div></div>
    <div>
      <i class="ion-plus green"
         style="margin-right: 7px"></i>
      <span>Add child</span>
    </div>
  </div>
</ng-template>

<ng-template #addReconciliationRowTemplate
             let-node="node">
  <div class="node center-vertical cursor-pointer"
       data-test-id="add-reconciliation-to-hierarchy-btn"
       [ngClass]="{ disabled: !node.canBeReconciled }"
       [indicioTippy]="!node.canBeReconciled ? { content: 'You need at least two valid children in your hierarchy to run a reconciliation.', placement: 'right' } : null"
       (click)="addNewReconciliation(node)"
       style="grid-template-columns: 30px 1fr">
    <div>
      <!-- Empty col -->
    </div>
    <div>
      <i class="ion-network green"
         style="margin-right: 7px"></i>
      <span>Reconcile</span>
    </div>
  </div>
</ng-template>

<ng-template #hierarchyOptionsTemplate
             let-node="node"
             let-isTop="isTop">
  <indicio-options-menu fillerTop="-33%"
                        [nohover]="true"
                        iconsize="21px"
                        [containerScrolled]="scrollPosition"
                        [filler]="true"
                        (openEvent)="toggleHierarchyMenu(node, $event)">
    <ul>
      <ng-container *ngIf="node.Relation.hasPermissionToUpdate">
        <li (click)="openSyncDataDialog(node)"
            indicioTippy="Fetch latest version for each source variable used in this hierarchy">
          <i class="ion-loop"></i>
          <div class="text">
            Update data
          </div>
        </li>
        <li *ngIf="isAdmin$ | async"
            (click)="openCalcResultsDialog(node)"
            indicioTippy="Calculate all results for all forecasts used in this hierarchy">
          <i class="ion-ios-list-outline"></i>
          <div class="text">
            Calculate
          </div>
        </li>
        <li divider></li>
      </ng-container>

      <li (click)="addNewChild(node)"
          [indicioTippy]="isTop && !node.valid ? { content: 'You need to set a forecast on the top node first.', placement: 'right' } : null"
          [ngClass]="{ disabled: isTop && !node.valid }">
        <i class="ion-plus"></i>
        <div class="text">
          Add child
        </div>
      </li>
      <li *ngIf="isTop"
          [ngClass]="{ disabled: !node.canBeReconciled }"
          [indicioTippy]="!node.canBeReconciled ? { content: 'You need at least two valid children in your hierarchy to run a reconciliation.', placement: 'right' } : null"
          (click)="!node.Hierarchy.Reconciliations.length ? addNewReconciliation(node) : editReconciliation(node)">
        <i class="ion-plus"></i>
        <div class="text">
          {{ !node.Hierarchy.Reconciliations.length ? 'Add reconciliation' : 'Edit reconciliation' }}
        </div>
      </li>
      <li divider></li>
      <ng-container *ngIf="!node.missingForecast">
        <li (click)="navToForecast(node)">
          <i class="ion-android-exit"></i>
          <div class="text">
            Navigate to forecast
          </div>
        </li>
        <li *ngIf="node.Hierarchy.Reconciliations.length"
            (click)="navToReconciliation(node)">
          <i class="ion-android-exit"></i>
          <div class="text">
            Navigate to reconciliation
          </div>
        </li>
        <li divider></li>
      </ng-container>
      <li (click)="editRelation(node)">
        <i class="ion-edit"></i>
        <div class="text">{{ !node.missingForecast ? 'Change forecast' : 'Select forecast' }}
        </div>
      </li>
      <li *ngIf="isTop"
          (click)="editHierarchy(node.Hierarchy)">
        <i
           [ngClass]="company.hasPermission('CAN_UPDATE_HIERARCHICAL_FORECAST') ? 'ion-android-settings' : 'ion-information-circled'"></i>
        <div class="text">
          Settings
        </div>
      </li>
      <li divider></li>
      <ng-container *ngIf="isTop">
        <li *ngIf="company.hasPermission('CAN_DELETE_HIERARCHICAL_FORECAST')"
            (click)="deleteHierarchy(node.Hierarchy)">
          <i class="ion-trash-b"></i>
          <div class="text">
            Remove
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="!isTop">
        <li *ngIf="company.hasPermission('CAN_UPDATE_HIERARCHICAL_FORECAST')"
            (click)="deleteRelation(node)">
          <i class="ion-trash-b"></i>
          <div class="text">
            Remove
          </div>
        </li>
      </ng-container>
    </ul>
  </indicio-options-menu>
</ng-template>

<ng-template #reconciliationOptsTemplate
             let-node="node">
  <indicio-options-menu fillerTop="-33%"
                        [nohover]="true"
                        iconsize="21px"
                        [containerScrolled]="scrollPosition"
                        [filler]="true"
                        (openEvent)="toggleHierarchyMenu(node, $event)">
    <ul>
      <li (click)="editReconciliation(node)">
        <i class="ion-edit"></i>
        <div class="text">
          Edit reconciliation
        </div>
      </li>
      <li divider></li>
      <li *ngIf="company.hasPermission('CAN_UPDATE_HIERARCHICAL_FORECAST')"
          (click)="deleteReconciliation(node)">
        <i class="ion-trash-b"></i>
        <div class="text">
          Remove reconciliation
        </div>
      </li>
    </ul>
  </indicio-options-menu>
</ng-template>

<ng-template #loader>
  <div class="row full-size">
    <div class="col-xs-12 tab-loader">
      <i class="ion-load-c spinner"></i>
    </div>
  </div>
</ng-template>

<ng-template #empty>
  <div></div>
</ng-template>
