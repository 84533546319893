import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigateToAddData } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProjectModel } from '@core/store/project/project.model';
import { DateRangeComponent } from '@dialogs/forecast/settings/tabs/date-settings/range-component/date-range.component';
import { DateRangeOptions } from '@dialogs/forecast/settings/tabs/date-settings/range-component/date-range.options';
import { Periodicity } from '@modules/lang/types/periodicity';
import { Store } from '@ngxs/store';

@Component({
  selector: 'indicio-fcast-dialog-date-settings-tab',
  templateUrl: './fcast-tab.date-settings.component.html',
  styleUrls: ['./fcast-tab.date-settings.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FCastSettingsDialogDateSettingsTabComponent implements OnChanges {

  @ViewChild(DateRangeComponent)
  dateRange: DateRangeComponent;

  @Input() forecast: ForecastModel;
  @Input() forecastVersion: ForecastVersionModel;
  @Input() project: ProjectModel;
  @Input() canUpdateForecast: boolean;
  @Input() trigger: boolean[];

  @Output() modifiedEvent = new EventEmitter<boolean>();
  @Output() closeDialogEvent = new EventEmitter<boolean>();

  public dateRangeOptions: DateRangeOptions;
  public changed: boolean = false;


  // Periodicity settings
  public validPeriodicities: Periodicity[];

  public pending: boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
    private status: StatusService,
    private store: Store,
    public envService: EnvironmentService
  ) {
  }

  public ngOnChanges() {
    if (!this.forecastVersion.ForecastVariable) { return; }
    setTimeout(() => {
      this.dateRangeOptions = <DateRangeOptions> {
        forecast: this.forecast,
        forecastVersion: this.forecastVersion,
        canUpdateForecast: this.canUpdateForecast,
        width: 780
      };
    }, 0);

    this.dateRange?.initialize();

    this.cd.detectChanges();
  }

  public gotoSourceVariables() {
    this.store.dispatch(new NavigateToAddData(this.forecast.ForecastId));
    this.closeDialogEvent.emit(true);
  }

  public setChanged(changed: boolean) {
    this.changed = changed;
    this.modifiedEvent.emit(changed);
  }

  public save() {
    if (!this.changed) { return Promise.resolve(); }
    this.dateRange.setChangesOnForecastVersion();
    this.pending = true;
    return this.dateRange.saveChanges(this.forecastVersion)
      .then(_ => {
        this.status.setMessage('Changes saved successfully', 'Success', true);
        this.setChanged(false);
      })
      .catch(err => this.status.setError(err, true))
      .finally(() => this.pending = false);
  }
}
