import { EditNewsModalOpts } from './edit-news-modal.options';
import { EditNewsModalComponent } from '@shared/modals';

export class OpenEditNewsModal {
  public static readonly type = '[MODAL] Open edit news modal';
  public static readonly component = EditNewsModalComponent;
  data: EditNewsModalOpts;
  constructor(
    newsPost,
    onClose?
  ) {
    this.data = { newsPost, onClose };
  }
}
