import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataProvider } from '@core/constants/provider-definitions';
import { OpenIdFrontendService } from '@core/services/open-id/open-id.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { OpenIdStorageKeys } from '@modules/home/views/openid-landing/openid-landing.component';
import { Store } from '@ngxs/store';
import { OpenSetCompanyModal } from '@shared/modals/company/set-company/set-company-modal.actions';
import { StorageUtils } from '@shared/utils/storage.utils';
import { DialogActions } from '../../../dialog.actions';
import { DialogService } from '../../../dialog.service';

export enum SetupOpenIdIntegrationState {
  INITIAL = 'INITIAL',
  COMPLETE = 'COMPLETE',
}

export interface SetupOpenIdIntegrationDialogData {
  Url?: string;
  Title: string;
  Text: string;
  ButtonText: string;
  State?: SetupOpenIdIntegrationState;
  Provider?: DataProvider;
  Force?: boolean;
}

@Component({
  selector: 'indicio-setup-openid-integration-dialog',
  templateUrl: 'setup-openid-integration.dialog.html',
})
export class SetupOpenIdIntegrationDialogComponent {

  public static Id = 'SetupOpenIdIntegrationDialogComponent';

  public manyCompanies: boolean;

  constructor(
    public dialogRef: MatDialogRef<SetupOpenIdIntegrationDialogComponent>,
    private store: Store,
    private clientService: ClientFrontendService,
    private authService: AuthFrontendService,
    private openidService: OpenIdFrontendService,
    private forecasterService: ForecastFrontendService,
    private dialog: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: SetupOpenIdIntegrationDialogData) {
    if (!this.data.State) { this.data.State = SetupOpenIdIntegrationState.INITIAL; }
    this.manyCompanies = this.clientService.client.CompanyIds.length > 1;

    if (this.clientService.activeCompany.LicenseType !== 'Macrobond' && this.data.State === SetupOpenIdIntegrationState.INITIAL) {
      StorageUtils.set(OpenIdStorageKeys.forecastId, this.forecasterService.activeForecastId);
    }

    if (this.data.State === SetupOpenIdIntegrationState.INITIAL && !!!this.data.Url) {
      this.openidService.setupOpenIdIntegration(this.data.Provider || DataProvider.macrobondapi)
        .then(url => this.data.Url = url);
    }
  }

  public close() {
    this.store.dispatch(new DialogActions.Close('SetupOpenIdIntegrationDialogComponent'));
    this.dialogRef.close(null);
  }

  public openChangeCompanyModal() {
    this.dialogRef.close(null);
    this.store.dispatch(new OpenSetCompanyModal(this.clientService.activeCompany.CompanyId));
  }

  public logout() {
    this.authService.logout(false);
  }

  proceed() {
    this.dialog.openRedirectDialog({ Url: this.data.Url, To: this.data.Provider });
  }
}
