import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { TagDTO, TagTypes } from '../dtos/tags.dtos';


export interface TagModelTreeNode {
  level: number;
  hasChildren: boolean;
  _real: TagModel;
}
export class TagModel extends TagDTO implements IHasModelId {

  public ParentTags: TagModel[] = [];
  public ChildTags: TagModel[] = [];

  public getModelId() { return this.TagId; }

  public getAllChildIds(): string[] {
    const result = [this.TagId];
    const children = this.ChildTags.reduce((acc: string[], curr: TagModel) => [...acc, ...curr.getAllChildIds()], [] as string[]);
    result.push(...children);
    return result;
  }

  public getAllParentIds() {
    const result = [this.TagId];
    const parents = this.ParentTags.reduce((acc: string[], curr: TagModel) => [...acc, ...curr.getAllParentIds()], [] as string[]);
    result.push(...parents);
    return result;
  }

  public isSystemTag() {
    return this.Type === TagTypes.COUNTRY || this.Type === TagTypes.CONTINENT;
  }

  public isRegion() {
    return this.isSystemTag() || this.Type === TagTypes.USERDEFINEDREGION || this.Type === TagTypes.CUSTOMREGION;
  }

  public isUserDefined() {
    return this.Type === TagTypes.USERDEFINEDREGION || this.Type === TagTypes.USERDEFINED;
  }
}


