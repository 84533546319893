<indicio-dialog-base>
  <h1 mat-dialog-title>Select steady-state priors for Bayesian VAR</h1>
  <div mat-dialog-content>

    <ng-container *ngIf="!isLoading else showLoader">
      <div class="bvar-priors">
        <div class="row">
          <div class="col-xs-12">
            Select transformation to be used in the model,
            prior mean and variance for each variable to build a Bayesian VAR with steady-state priors. <br><br>
            The graph shows a preview of the variable currently being edited, using the selected transformation.
          </div>
        </div>
        <div class="row extra-margin-top">
          <div class="col-xs-12">
            <h3>{{ variable.Name }}</h3>
          </div>
        </div>
        <div *ngIf="graphReady"
             class="row margin-top">
          <div class="col-xs-12">
            <indicio-alg class="modal-chart"
                         [periodicityType]="periodicity"
                         [HistoricLine]="historicLine"
                         [options]="options"
                         [chartStyles]="chartStyles"
                         [showExport]="false"
                         graphType="generic"
                         #graph>
            </indicio-alg>
          </div>
        </div>
        <div *ngFor="let variable of settings"
             class="row margin-top">
          <div class="col-xs-4 input-label"
               [title]="variable.Name">
            <div [style]="{ 'font-weight': variable.Focused ? 500 : 400 }"
                 class="crop-string">{{ variable.Name }}</div>
          </div>
          <div class="col-xs-3">
            <indicio-searchable-dropdown label="Transformation"
                                         optionDisplay='Display'
                                         [id]="variable.ForecastVariableId"
                                         [values]="(variable.LogAllowed ? transformationOptions : transformationOptionsNoLog)"
                                         [initializeWithFirstValue]="false"
                                         [selectedValue]="variable.Transformation"
                                         [required]="true"
                                         (click)="setFocused(variable.ForecastVariableId)"
                                         (valueChangeEvent)="setValue($event.Value, variable, 'trans')">
            </indicio-searchable-dropdown>
          </div>
          <div class="col-xs-2"
               style="margin-top: 7px;">
            <mat-form-field>
              <mat-label>Prior mean</mat-label>
              <input type="text"
                     required
                     [name]="variable.ForecastVariableId"
                     [id]="variable.ForecastVariableId"
                     step="any"
                     matInput
                     [ngModel]="variable.PriorMean"
                     (change)="setValue($event, variable, 'mean')"
                     (focus)="setFocused(variable.ForecastVariableId)"
                     (keyup)="setValue($event, variable, 'mean')"
                     placeholder="Mean"
                     style="padding-right:10px">

            </mat-form-field>
          </div>
          <div class="col-xs-2"
               style="margin-top: 7px;">
            <mat-form-field>
              <mat-label>Prior variance</mat-label>
              <input type="text"
                     required
                     [name]="variable.ForecastVariableId"
                     [id]="variable.ForecastVariableId"
                     step="any"
                     matInput
                     [ngModel]="variable.PriorVariance"
                     (change)="setValue($event, variable, 'var')"
                     (focus)="setFocused(variable.ForecastVariableId)"
                     (keyup)="setValue($event, variable, 'var')"
                     placeholder="Variance"
                     style="padding-right:10px">
              <mat-hint *ngIf="!variable.ValidVariance">
                <span class="error-text">Must be greater than zero.</span>
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-xs-1 input-label"
               style="margin-top: 15px;">
            <i *ngIf="variable.ValidVariance"
               class="ion-android-checkbox green"
               style="font-size: 25px"
               title="Variable settings are valid"></i>
            <i *ngIf="!variable.ValidVariance"
               class="ion-ios-undo yellow pointer"
               style="font-size: 25px;"
               title="Reset changes"
               (click)="resetSettings(variable)"></i>
          </div>
        </div>
      </div>
    </ng-container>
    <br>
  </div>
  <div mat-dialog-actions>
    <button mat-button
            (click)="onNoClick()">
      Cancel
    </button>
    <div *ngIf="!canRun"
         [indicioTippy]="cantSaveMessage">
      <button mat-flat-button
              color="primary"
              [disabled]="!canSave"
              (click)="save()">Save
      </button>
    </div>

    <button *ngIf="canRun"
            mat-flat-button
            color="primary"
            [disabled]="!canRun"
            (click)="run()">Run
    </button>
  </div>
</indicio-dialog-base>

<ng-template #showLoader>
  <div class="center-text">
    <i class="ion-load-c spinner info-loader "
       style="font-size: 50px;"></i>
  </div>
</ng-template>
