import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { INeedFetch } from '@core/interfaces/if-need-fetch';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ScriptCalculateInfoModel } from '@core/store/forecast/models/script-calculate-info.model';
import { ImportedHistoricBaseEventModel } from '@core/store/historic-event/models/imported/imported-historic-base-event.model';
import { ModelScaling } from '@core/types/model-scaling.type';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { VsResultType } from '@modules/lang/language-files/var-select';
import { Periodicity } from '@modules/lang/types/periodicity';
import { DateUtils } from '@shared/utils/date.utils';
import * as moment from 'moment';
import { VarSelectSettingsDTO } from '../dtos/var-select-settings/var-select-settings-dto';

export type VariableOwnerInfo = {
  Item1: string;
  Item2: string;
};

export const ForecastVersionDateFields = [
  'StartDate', 'DataStartDate', 'CreatedDate', 'EarliestSharedDate', 'TestStartDate',
];
export const ForecastVersionDateArrayTriggerFields = [
  'StartDate', 'DataStartDate', 'OverlappingValueCount', 'EarliestSharedDate', 'TestStartDate'
];

export class ForecastVersionMetaModel implements IHasModelId {

  @Def() ForecastVersionId: string;
  @Def() VersionNumber: number;
  @Def() Horizon: number;
  @Def() Periodicity: PeriodicityType;
  @Def() Alpha: number;
  @Def() StepChosen: number;
  @Def() MaxLag: number;
  @Def() MaxHorizon: number;
  @Def() Frequency: number;
  @Def() UseModelRollingWindow: boolean;
  @Def() ModelRollingWindowRecalc: number;
  @Def() EnableMAPE: boolean;
  @Def() EnableShap: boolean;
  @Def() EnableMFM: boolean;
  @Def() ActiveVSResultType: VsResultType;
  @Def() ModelThresholdMAPE: number;
  @Def() CalculateOutliers: boolean;
  @Def() OOSEnabled: boolean;
  @Def() IsLatest: boolean;
  @Def() CalculateSeasonal: boolean;
  @Def() AutoUpdateVariables: boolean;
  @Def() CanCreateNewVersion: boolean;
  @Def() ForecastId: string;
  @Def() ForecastVariableId: string;
  @Def() IndicatorVariableIds: string[] = [];
  @Def() AssessmentIds: string[] = [];
  @Def() ImportedHistoricBaseEventIds: string[] = [];
  @Def() ScenarioIds: string[] = [];
  @Def() StartDate: Date;
  @Def() TestStartDate: Date;
  @Def() DataStartDate: Date;

  @Def() CreatedDate: Date;
  @Def() ModelScaling: ModelScaling.ModelScalingType;
  @Def() LatestForecastStartDate: Date;

  // Cached fields
  @Def() OverlappingValueCount: number;
  @Def() EarliestSharedDate?: Date;

  // Frontend mapped
  @Def() LastResultDate: Date;
  @Def() DataUntilDateRequirement: Date;

  triggerVsInProgress = false;
  periodicity: Periodicity = null;

  public getModelId(): string { return this.ForecastVersionId; }
}

export class ForecastVersionModel extends ForecastVersionMetaModel implements IHasModelId, INeedFetch {

  @Def() ImportedHistoricBaseEvents: ImportedHistoricBaseEventModel[] = [];
  @Def() ForecastVariable: ForecastVariableModel;
  @Def() IndicatorVariables: ForecastVariableModel[] = [];
  @Def() ActiveBenchmarks: string[] = [];
  @Def() IndicatorWarnings: VariableOwnerInfo[] = [];

  VarSelectSettings: VarSelectSettingsDTO = null;
  allDatesArray: moment.Moment[] = [];
  activeDatesArray: moment.Moment[] = [];

  @Def() ScriptCalculateInfo: ScriptCalculateInfoModel = new ScriptCalculateInfoModel();
  public get CanTriggerVariableSelect() { return this.ScriptCalculateInfo.CanTriggerVariableSelect; }
  public get triggerCalculationTitle() { return this.ScriptCalculateInfo.triggerCalculationTitle; }
  public get triggerVarSelectTitle() { return this.ScriptCalculateInfo.triggerVarSelectTitle; }
  public get activeDataStartDate() { return !!this.DataStartDate ? this.DataStartDate : this.EarliestSharedDate; }
  public get testSetSize() {
    return this.OOSEnabled
      ? DateUtils.diffDate(this.StartDate, this.TestStartDate, this.Periodicity)
      : 0;
  }

  public get mfVariableSet() { return this.ActiveVSResultType === 'MF'; }
  public get isNewVS() { return this.VarSelectSettings?.VSMode !== 'stepwise'; }
  public get hasMFData() { return this.EnableMFM && this.getAllVariables().some(v => v.IsMixedFreq); }

  fetched = false;
  isPending = false;
  needToTriggerMulti = false;
  originalActiveVariables: { 'Id': string, 'SF': boolean; 'MF': boolean; }[] = [];

  public isBenchmarkActive(benchId: string) {
    return this.ActiveBenchmarks.includes(benchId);
  }

  public getTestSizeAsPercentage() {
    const activeObsCount = DateUtils.diffDate(this.StartDate, this.activeDataStartDate, this.Periodicity);
    return Math.round((this.testSetSize / activeObsCount) * 100);
  }

  public setOriginalActiveVarables() {
    this.originalActiveVariables = this.IndicatorVariables.map(v => ({ 'Id': v.ForecastVariableId, 'SF': v.Active, 'MF': v.ActiveMF }));
    this.checkActiveVariables();
  }

  public checkActiveVariables() {
    for (let i = 0; i < this.IndicatorVariables.length; i++) {
      const currentIndicator = this.IndicatorVariables[i];
      const savedState = this.originalActiveVariables.find(x => x.Id === currentIndicator.ForecastVariableId);
      if (currentIndicator.Active !== savedState.SF || currentIndicator.ActiveMF !== savedState.MF) { this.needToTriggerMulti = true; return; }
    }
    this.needToTriggerMulti = false;
  }

  public fVarNameExists(name: string) {
    const namesInForecast = this.getAllVariables().map(x => x.Name);
    return namesInForecast.includes(name);
  }

  public setAllDatesArray() {
    this.sanitizeForecastHorizon(this.MaxHorizon);
    this.sanitizeStepChosen();

    const lastDate = DateUtils.nextPeriodAfterSteps(DateUtils.newMoment(this.LatestForecastStartDate), this.periodicity, this.MaxHorizon - 1);
    let firstDate = DateUtils.newMoment(this.EarliestSharedDate);
    const dates: moment.Moment[] = [];
    while (firstDate.isSameOrBefore(lastDate)) {
      dates.push(firstDate);
      firstDate = DateUtils.nextPeriod(firstDate.clone(), this.periodicity);
    }
    this.allDatesArray = dates;
    let startIndex = this.DataStartDate ? this.allDatesArray.findIndexFromEnd(x => x.isSameOrBefore(this.DataStartDate)) : 0;
    // Note: Move this function to a service or other class with access to the store, and notify backend of this happening!
    // This happens when DataStartDate sent from backend is invalid.
    if (startIndex < 0) {
      startIndex = 0;
    }
    const endIndex = this.allDatesArray.findIndex(x => x.isSameOrAfter(this.LastResultDate));
    this.activeDatesArray = dates.slice(startIndex, endIndex + 1);
  }

  private sanitizeForecastHorizon(maxHorizon: number) {
    if (this.Horizon > maxHorizon && this.OverlappingValueCount > 0) {
      this.Horizon = maxHorizon;
    }
  }

  private sanitizeStepChosen() {
    if (this.StepChosen > this.Horizon) {
      this.StepChosen = this.Horizon;
    }
  }

  public needFetch(): boolean {
    let needFetching = false;
    if (this.IndicatorVariableIds.length !== this.IndicatorVariables.length) { needFetching = true; }
    return !this.fetched || needFetching;
  }

  public removeVariableById(variableId: string) {

    if (this.ForecastVariable && this.ForecastVariable.ForecastVariableId === variableId) {
      this.ForecastVariable = null;
      this.ForecastVariableId = null;
      return;
    }

    this.IndicatorVariables.removeById(variableId);
    this.IndicatorVariableIds.removeId(variableId);
  }

  public addVariable(variable: ForecastVariableModel) {
    if (!variable.IsIndicator) {
      this.ForecastVariableId = variable.ForecastVariableId;
      this.ForecastVariable = variable;
    } else {
      let setOrg = false;
      if (!this.IndicatorVariables.some(x => x.ForecastVariableId === variable.ForecastVariableId)) {
        setOrg = true;
      }
      this.IndicatorVariables.replaceIfExist(variable);
      this.IndicatorVariableIds.addUniqueId(variable.ForecastVariableId);
      if (setOrg) {
        this.setOriginalActiveVarables();
      }
    }
  }

  public getAllVariables() {
    const ans: ForecastVariableModel[] = [];
    if (this.ForecastVariable) { ans.push(this.ForecastVariable); }
    ans.push(...this.IndicatorVariables);
    return ans;
  }

  public isSourceVariableUsed(sourceVariableId: string) {
    const ids = this.getAllVariables().map(v => v.SourceVariableId);
    return ids.includes(sourceVariableId);
  }

  public updateVariables(variables: ForecastVariableModel[]) {
    variables.forEach(variable => {
      if (!variable.IsIndicator) {
        this.ForecastVariable = variable;
      } else {
        this.IndicatorVariables.addOrUpdate(variable);
      }
    });
  }

  public getVariableById(variableId: string): ForecastVariableModel {
    if (this.ForecastVariable && this.ForecastVariable.ForecastVariableId === variableId) { return this.ForecastVariable; }
    return this.IndicatorVariables.find(x => x.ForecastVariableId === variableId);
  }

  public getVariableBySourceVariableId(sourceVariableId: string): ForecastVariableModel {
    if (this.ForecastVariable && this.ForecastVariable.SourceVariableId === sourceVariableId) { return this.ForecastVariable; }
    return this.IndicatorVariables.find(x => x.SourceVariableId === sourceVariableId);
  }

  public getVariableBySourceVariableMetaId(sourceMetaId: string): ForecastVariableModel {
    if (this.ForecastVariable && this.ForecastVariable.SourceVariableMetaId === sourceMetaId) { return this.ForecastVariable; }
    return this.IndicatorVariables.find(x => x.SourceVariableMetaId === sourceMetaId);
  }

  public removeNowcastResultByMetaId(SourceVariableMetaId: string) {
    const all = this.getAllVariables();
    all.forEach(variable => {
      if (variable.Nowcast && variable.Nowcast.Results) {
        variable.Nowcast.Results.removeByKey('SourceVariableMetaId', SourceVariableMetaId);
      }
    });
  }

  public get hasUnivariateResult() {
    return Object.values(this.ScriptCalculateInfo.Univariate).some(v => v.ExitError !== null);
  }

  public get hasMultivariateResult() {
    return Object.values(this.ScriptCalculateInfo.Multivariate).some(v => v.ExitError !== null);
  }

  public get hasResults(): boolean {
    return this.hasUnivariateResult || this.hasMultivariateResult;
  }

  public get anyActiveIndicators() {
    const sfActiveIndicators = this.IndicatorVariables.filter(x => !x.IsMixedFreq).some(x => x.Active);
    const mfActiveIndicators = this.IndicatorVariables.filter(x => !x.IsMixedFreq).some(x => x.ActiveMF) ||
      (this.ForecastVariable?.ActiveMF && this.ForecastVariable?.MixedFreqTwinId != null);
    return sfActiveIndicators || (this.hasMixedFreq && mfActiveIndicators);
  }

  public get hasMixedFreq() {
    return this.EnableMFM && !!this.IndicatorVariables?.some(x => x.IsMixedFreq);
  }

  public get hasVariables() {
    return this.IndicatorVariables.length > 0 || this.ForecastVariable !== null;
  }

  public get hasData(): boolean {
    return this.hasResults || !!this.hasVariables;
  }

  public get hasMultiData(): boolean {
    const variables = this.IndicatorVariables.length > 0 && this.ForecastVariable !== null;
    return this.hasMultivariateResult || !!variables;
  }
}
