import { Component, OnDestroy } from '@angular/core';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ConfirmedNewLocationDTO } from '@core/store/file/dtos/variable-update-info-dto';
import { FileFrontendService } from '@core/store/file/file.frontend.service';
import { FileInfoModel } from '@core/store/file/models/file-info.model';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { FileDialogService } from '@dialogs/file/file-dialogs.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { FileUtils } from '@shared/utils/file.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import { OpenFileErrorInfoModal, OpenUploadFileModal } from '../file-modal.actions';
import { OpenNewOrUpdatedFilesModal } from '../new-or-updated-files/new-or-updated-files.actions';
import { UploadFileModalOpts } from './upload-file-modal.options';

export type FileUploadState = 'initializing' | 'waiting' | 'uploading' | 'complete' | 'error' | 'warning' | 'skipped';

@Component({
  selector: 'indicio-file-upload-modal',
  templateUrl: './upload-file-modal.component.html'
})
export class FileUploadModalComponent extends ModalModelComponent implements OnDestroy {

  // HTLM variables and lists
  public files: FileInfoModel[] = [];
  public buttonText: string = 'Upload';
  public title: string = 'Upload new file(s)';
  public goneFishing: boolean = false;
  public initLoading: boolean = false;
  public singleUpdate: boolean = false;
  public lessThanOneWarning: boolean = false;
  private listOfUnsureDelimiterFiles = [];
  public get canUpload() {
    const allCanUpload = !!this.files.length && this.files.every(x => x.canUpload);
    return allCanUpload;
  }
  public get anyIsUpdate() {
    return this.singleUpdate || this.files.some(x => !!x.OldFile);
  }
  // Private stuff
  private forecastId: string = null;
  private companyId: string = null;
  private forecastVersionId: string = null;
  public opts: UploadFileModalOpts;

  constructor(
    protected store: Store,
    private status: StatusService,
    public appearance: AppearanceService,
    private clientService: ClientFrontendService,
    private fileService: FileFrontendService,
    private fileDialog: FileDialogService,
    private dialog: DialogService
  ) {
    super();
  }

  public setOptions(options: UploadFileModalOpts) {
    this.opts = options;
    if (options.fileInfos && options.fileInfos.length) {
      this.files = options.fileInfos;
      this.goneFishing = true;
    }
    if (this.opts.file) {
      this.title = 'Update file with new data';
      this.singleUpdate = true;
    }
    this.forecastId = options.forecastId;
    this.forecastVersionId = options.forecastVersionId;
    this.companyId = this.clientService.activeCompany.CompanyId;
    this.isLoading = false;
    this.pending = false;
  }

  public fileChange(fileList: FileList) {
    this.resetList();
    if (fileList.length > 1 && this.opts.file) {
      this.status.setMessage('Can only select one file to update file.', 'Warning', true, [`File to update: ${this.opts.file.FileName}`]);
      return;
    }
    this.initList(fileList)
      .then(() => {
        this.syncVariableUpdateInfo();
      });
  }

  public upload() {
    if (this.pending) { return; }
    this.pending = true;
    this.goneFishing = true;
    setTimeout(async () => {
      this.status.removeMessage();
      for (const f of this.files) {
        if (f.canUpload) {
          await this.uploadFile(f);
        } else {
          f.State = 'skipped';
        }
      }
      this.pending = false;
      this.updateButtonText();
    }, 0);
  }

  public inspectPreUploadChanges(Info: FileInfoModel) {
    this.dialog.openPreFileUploadDialog({ Info });
  }

  public inspectFileUpdatedChanges(info: FileInfoModel) {
    this.close();
    this.fileDialog.openFileDiff({
      oldFile: info.OldFile,
      newFile: info.NewFile,
      onBack: () => this.store.dispatch(new OpenUploadFileModal(this.opts.forecastId, this.opts.forecastVersionId, this.opts.file, this.files))
    });
  }

  public openErrorFileInfoModal() {
    this.close();
    this.store.dispatch(new OpenFileErrorInfoModal(() => this.store.dispatch(new OpenUploadFileModal(this.forecastId, this.forecastVersionId, this.opts.file, this.files)), null));
  }

  public actOnUploadFinished() {
    this.close();
    if (this.files.some(x => x.proceedToCreateVariables)) {
      this.store.dispatch(new OpenNewOrUpdatedFilesModal(this.forecastId, this.forecastVersionId, this.files.filter(x => x.proceedToCreateVariables).map(y => y.NewFile)));
    }
  }

  //
  // File drop-zone functions
  //
  public allowDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  public drop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const inputElement = <HTMLInputElement> (document.querySelector('input[type=file]'));
    inputElement.files = event.dataTransfer.files;
    this.fileChange(inputElement.files);
    const box = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    box.classList.remove('active');
  }

  public toggleDragOver(state: boolean) {
    const element = <HTMLElement> (document.querySelector('.drag-and-drop-box'));
    if (state) {
      element.classList.add('active');
    } else {
      element.classList.remove('active');
    }
  }

  /**
   *
   * Private functions
   *
   */
  private resetList() {
    this.files = [];
    this.goneFishing = false;
    this.status.removeMessage();
  }

  private updateButtonText() {
    if (!this.goneFishing) { return this.buttonText = 'Upload'; }
    if (!this.pending && this.files.some(x => x.proceedToCreateVariables)) {
      return this.buttonText = 'Proceed to file(s)';
    }
    this.buttonText = 'Done';
  }

  private createNewFileModel(filename: string, fileToRead: File) {
    const file = new UploadedFileModel();
    file.CompanyId = this.companyId;
    file.FileName = filename;
    file.Extension = FileUtils.getExtension(filename);
    file.isCsv = file.Extension === 'csv';
    if (!file.Extension) {
      file.errorStatus = 'File extension not valid';
    }
    if (file.isCsv) {
      const extensionReader = new FileReader();
      extensionReader.onloadend = async () => {
        const body: string = <string> extensionReader.result;
        const delimiterGuess = FileUtils.getSeparator(body);
        file.containsLT1Values = FileUtils.containsLessThanOneValues(body);
        if (delimiterGuess.length === 1) {
          file.Separator = delimiterGuess[0].Delimiter.value;
        } else {
          // Dialogues will be opened for each file in this list, below in initList
          this.listOfUnsureDelimiterFiles.push({
            FileName: filename,
            File: file,
            Data: {
              Title: 'Pick CSV delimiter',
              ConfirmText: 'Confirm',
              CancelText: 'Skip file',
              Label: '',
              Text: `Delimiter could not be determined. Please pick the correct one below<br><br>
              <strong>Filename</strong><br>${filename}`,
              Values: delimiterGuess.map(x => ({
                Value: x.Delimiter.value,
                Display: `${x.Delimiter.display} (${x.Delimiter.title})`,
                Description: null
              })),
            }
          });
        }
      };
      extensionReader.readAsText(fileToRead);
    } else {
      file.Separator = 'NA';
    }
    return new Promise<UploadedFileModel>((res, rej) => {
      const dataReader = new FileReader();
      dataReader.onloadend = () => {
        const body: string = <string> dataReader.result;
        file.FileData = body.substring(body.indexOf(',') + 1);
        res(file);
      };
      dataReader.onerror = rej;
      dataReader.readAsDataURL(fileToRead);
    });
  }

  private initList(fileList: FileList) {
    this.initLoading = true;
    const promises: Promise<UploadedFileModel>[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const fileToRead = fileList.item(i);
      const filename = fileToRead.name;
      promises.push(this.createNewFileModel(filename, fileToRead));
    }
    return Promise.all(promises)
      .then(files => {
        this.files.push(...files.map(f => {
          const infoObject = new FileInfoModel();
          infoObject.FileToUpload = f;
          infoObject.OldFile = this.opts.file || this.fileService.uploadedFiles.find(file => file.FileName === f.FileName);
          if (infoObject.OldFile) { infoObject.FileToUpload.UploadedFileId = infoObject.OldFile.UploadedFileId; }
          infoObject.Valid = f.errorStatus === null;
          infoObject.State = f.containsLT1Values ? 'warning' : 'waiting';
          return infoObject;
        }));
        return this.openSetMissingSeparators();
      })
      .then(() => true)
      .finally(() => this.initLoading = false);
  }

  private openSetMissingSeparators(): Promise<boolean> {
    if (this.listOfUnsureDelimiterFiles.length > 0) {
      const fileInfo = this.listOfUnsureDelimiterFiles.shift();
      const ref = this.dialog.openDropdownInputDialog(fileInfo.Data);
      return ref.toPromise()
        .then(ans => {
          if (ans === null) {
            this.files = this.files.filter(x => x.FileToUpload.FileName !== fileInfo.FileName);
          } else {
            fileInfo.File.Separator = ans.Value;
          }
          return this.openSetMissingSeparators();
        });
    } else {
      return Promise.resolve(true);
    }
  }

  private syncVariableUpdateInfo() {
    const toSolve = this.files.filter(x => x.OldFile);
    this.files.filter(x => !x.OldFile).filter(x => x.State !== 'warning').forEach(x => x.State = 'waiting');
    toSolve.forEach(fInfo => {
      this.pending = true;
      fInfo.State = 'initializing';
      this.fileService.getFileUpdateInfo(fInfo.FileToUpload)
        .then(resp => {
          fInfo.UpdateInfo = resp;
          let state: FileUploadState = 'waiting';
          resp.Variables.forEach(vDto => {
            const confirmedDto = new ConfirmedNewLocationDTO();
            confirmedDto.SourceVariableId = vDto.SourceVariableId;
            confirmedDto.SourceVariableMetaId = vDto.SourceVariableMetaId;
            confirmedDto.NewSheet = fInfo.FileToUpload.isCsv ? 0 : -1;
            if (vDto.Found && vDto.NewLocations.length === 1) {
              const newLoc = vDto.NewLocations[0];
              confirmedDto.NewDateIndex = newLoc.NewDateIndex;
              confirmedDto.NewValueIndex = newLoc.NewValueIndex;
              confirmedDto.NewSheet = newLoc.NewSheet;
              confirmedDto.NewHashStartRow = newLoc.NewHashStartRow;
              confirmedDto.Exact = newLoc.Exact;
            } else {
              state = 'warning';
            }
            if (ValueUtils.isNum(confirmedDto.NewSheet, false)) {
              const dateCols = fInfo.getAllPossibleDateColumnIndexes(confirmedDto.NewSheet);
              if (dateCols.length === 1) {
                confirmedDto.NewDateIndex = dateCols[0];
              }
            }
            fInfo.VariableLocations.push(confirmedDto);
          });
          fInfo.State = state;
        })
        .catch(err => {
          const { errors, message } = this.status.getMessage(err);
          fInfo.State = 'error';
          fInfo.FileToUpload.errorStatus = message;
          if (message != null && errors != null) {
            fInfo.FileToUpload.errorStatus = message + '<br>' + errors.map(x => x.replace('ascending', '<b style="text-decoration: underline">ascending</b>')).join('<br>');
          }
        })
        .finally(() => this.pending = false);
    });
  }

  private uploadFile(fileInfo: FileInfoModel) {
    fileInfo.State = 'uploading';
    const promise = !fileInfo.OldFile
      ? this.fileService.createNewFile(fileInfo.FileToUpload)
      : this.fileService.createNewFileVersion(fileInfo.FileToUpload, fileInfo.VariableLocations);

    return promise
      .then(newFile => {
        fileInfo.State = 'complete';
        fileInfo.NewFile = newFile;
      })
      .catch(err => {
        fileInfo.State = 'error';
        const { message, errors } = this.status.getMessage(err);
        fileInfo.FileToUpload.errorStatus = message;
        if (message != null && errors != null) {
          fileInfo.FileToUpload.errorStatus = message + '<br><br>' + errors.map(x => x.replace('ascending', '<b style="text-decoration: underline">ascending</b>')).join('<br>');
        }
      });
  }
}
