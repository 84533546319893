import { CalendarEffects } from '@dialogs/variables/forecast-variable/info/tabs/seasonal/fvar-calendar-settings';
import { AggregationMethod } from '@modules/lang/language-files/aggregation';
import { AssessmentCategory, AssessmentLevel, AssessmentUnit } from '@modules/lang/language-files/assessment';
import { Currency } from '@modules/lang/language-files/currencies';
import { HistoricEventEffectType, HistoricEventLocation, HistoricEventType } from '@modules/lang/language-files/historic-events';
import { LicenseType } from '@modules/lang/language-files/license-types';
import { MissingValueModel } from '@modules/lang/language-files/missing-values';
import { NotificationType } from '@modules/lang/language-files/notification-types';
import { OutlierModel, OutlierStrategy, OutlierType } from '@modules/lang/language-files/outliers';
import { ReportTemplate } from '@modules/lang/language-files/reports';
import { CompanyRole, ProjectRole } from '@modules/lang/language-files/roles';
import { SeasonalCalendar, SeasonalModel, SeasonalOutlier, SeasonalStrategy, SeasonalTrend } from '@modules/lang/language-files/seasonal';
import { SourceType } from '@modules/lang/language-files/source-types';
import { ModelPropertiesType, ModelTransformationType } from '@modules/lang/language-files/stat-models';
import { GraphTransformation } from '@modules/lang/language-files/transformations';
import { ValidityLevel } from '@modules/lang/language-files/validity-levels';
import { ValueType } from '@modules/lang/language-files/value-types';
import { VSCoefMethod, VSLassoStruct, VSMeasurement, VSMode } from '@modules/lang/language-files/var-select';
import { SourceVariableVisibilityLevel, VisibilityLevel } from '@modules/lang/language-files/visibility';
import { Country } from '@modules/lang/types/country';
import { DisplayValue } from '@modules/lang/types/display-value';
import { FileType } from '@modules/lang/types/file-type';
import { ModelGroup, ModelName } from '@modules/lang/types/model-name';
import { Periodicity } from '@modules/lang/types/periodicity';
import { VariableTransformation } from '@modules/lang/types/variable-transformation';


export type EnvironmentType = 'Development' | 'Nightly' | 'Test' | 'Production' | 'LocalDebug';

export class EnvironmentState {

  // Roles
  CompanyRoles: CompanyRole[] = [];
  ProjectRoles: ProjectRole[] = [];

  // Assessments
  AssessmentCategories: AssessmentCategory[] = [];
  AssessmentLevels: AssessmentLevel[] = [];
  AssessmentUnits: AssessmentUnit[] = [];
  // Aggregation Methods
  AggregationMethods: AggregationMethod[] = [];
  // Scripts
  ScriptNames: any = {};
  // Model names
  UnivariateModelNames: ModelName[] = [];
  UnivariateModels: ModelGroup[] = [];
  MultivariateModelNames: ModelName[] = [];
  MultivariateModels: ModelGroup[] = [];
  AllModelNames: ModelName[] = [];
  AllModelGroups: ModelGroup[] = [];
  // Files
  FileTypes: FileType[] = [];
  //
  ValueTypes: ValueType[] = [];
  // Currencies and Countries
  Currencies: Currency[] = [];
  Countries: Country[] = [];
  // Historic Events
  HistoricEventTypes: HistoricEventType[] = [];
  HistoricEventLocations: HistoricEventLocation[] = [];
  HistoricEventEffectTypes: HistoricEventEffectType[] = [];
  // Graph transformations
  GraphTransformations: GraphTransformation[] = [];
  // Variable Information
  Periodicities: Periodicity[] = [];
  SourceTypes: SourceType[] = [];
  VariableTransformations: VariableTransformation[] = [];
  ModelTransformations: DisplayValue<ModelTransformationType>[] = [];
  ModelPropertyTypes: DisplayValue<ModelPropertiesType>[] = [];
  // Var select settings
  VSModes: VSMode[] = [];
  VSMeasurements: VSMeasurement[] = [];
  VSLassoStructs: VSLassoStruct[] = [];
  VSCoefMethods: VSCoefMethod[] = [];
  // Missing settings
  MissingValueModels: MissingValueModel[] = [];
  // Seasonal settings
  SeasonalModels: SeasonalModel[] = [];
  SeasonalStrategies: SeasonalStrategy[] = [];
  SeasonalTrends: SeasonalTrend[] = [];
  SeasonalCalendarEffects: SeasonalCalendar[] = [];
  SeasonalOutlierTypes: SeasonalOutlier[] = [];
  HolidayTypes: DisplayValue<CalendarEffects.HolidayType>[];
  RegionTypes: DisplayValue<CalendarEffects.RegionType>[];
  HolidayCategories: CalendarEffects.HolidayCategory[];
  // Outlier settings
  OutlierModels: OutlierModel[] = [];
  OutlierStrategies: OutlierStrategy[] = [];
  OutlierTypes: OutlierType[] = [];
  // Report settings
  ReportTemplates: ReportTemplate[] = [];
  // General
  ValidityLevels: ValidityLevel[] = [];
  VisibilityLevels: VisibilityLevel[] = [];
  SourceVariableVisibilityLevels: SourceVariableVisibilityLevel[] = [];
  NotificationTypes: NotificationType[] = [];
  // Admin - Company
  LicenseTypes: LicenseType[] = [];
  LatestToSId: string = null;
}
