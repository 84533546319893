import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';
import { Periodicity } from '@modules/lang/types/periodicity';
import { HierarchyBaseDTO } from '../../entities/dtos/hierarchy.dto';
import { HierarchyModel } from '../../entities/models/hierarchy.model';
import { HierarchicalForecastFrontendService } from '../../services/hierachical-forecast.frontend.service';

export interface CreateOrUpdateHierarchyDialogData {
  hierarchy?: HierarchyModel;
}

@Component({
  selector: 'indicio-create-hierarchy-dialog',
  templateUrl: 'create-or-update-hierarchy.dialog.html',
  styleUrls: ['../hierarchy.dialog.less']
})
export class CreateOrUpdateHierarchyDialogComponent {

  // Dialog identifier
  public static Id: string = 'create-create-hierarchy';

  // New entity
  public dto: HierarchyBaseDTO;

  // Options
  public forecastPoints: number[] = [];
  public periodicities: Periodicity[] = [];
  public sourceVariables: SourceVariableModel[] = [];

  // Status
  public isLoading: boolean;
  public isUpdate: boolean;

  constructor(
    public dialogRef: MatDialogRef<CreateOrUpdateHierarchyDialogComponent>,
    public env: EnvironmentService,
    private status: StatusService,
    private hierForecastService: HierarchicalForecastFrontendService,
    private companyService: CompanyFrontendService,
    private auth: AuthFrontendService,
    @Inject(MAT_DIALOG_DATA) public data: CreateOrUpdateHierarchyDialogData
  ) {
    this.isLoading = true;
    this.isUpdate = !!this.data.hierarchy;
    this.setupOpts();
    this.resetDto();
    this.isLoading = false;
  }

  public close() {
    this.dialogRef.close(false);
  }

  public setForecastPoints() {
    this.forecastPoints = Array.from(Array(this.companyService.maxHorizon(this.dto.Periodicity)), (_, i) => i + 1);
  }

  public create() {
    this.hierForecastService.create(this.dto)
      .then(() => {
        this.status.setMessage('New hierarchy created', 'Success');
        this.dialogRef.close(true);
      })
      .catch(err => this.status.setError(err, true));
  }

  public update() {
    this.hierForecastService.update(this.data.hierarchy, this.dto)
      .then(() => {
        this.status.setMessage('Hierarchy updated', 'Success');
        this.dialogRef.close(true);
      })
      .catch(err => this.status.setError(err, true));
  }

  private resetDto() {
    this.dto = new HierarchyBaseDTO();
    if (!this.isUpdate) {
      this.dto.Horizon = 3;
      this.dto.Periodicity = 'month';
    } else {
      this.dto.Horizon = this.data.hierarchy.Horizon;
      this.dto.Periodicity = this.data.hierarchy.Periodicity;
    }

    this.setForecastPoints();
  }

  private setupOpts() {
    this.periodicities = this.env.getValidPeriodicites(this.companyService.horizonInfos);
  }
}
