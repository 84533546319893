import { Injectable } from '@angular/core';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { DateFormatPipe } from '@shared/modules/pipes';
import { DateUtils } from '@shared/utils/date.utils';
import { HierarchyDTO } from '../entities/dtos/hierarchy.dto';
import { HForecastTreeNode } from '../entities/models/hierarchical-forecast.tree-node';
import { HierarchyModel } from '../entities/models/hierarchy.model';
import { ReconciliationEntryMeta, ReconciliationReport } from '../entities/models/reconciliation-report.model';
import { HierarchyUtils } from './hierarchy.utils';
import { HierarchyRelationDTO, UpdateHierarchyRelationDTO } from '../entities/dtos/hierarchy-relation.dto';

@Injectable({
  providedIn: 'root'
})
export class HierarchicalForecastMapper {

  constructor(
    private dateFormatter: DateFormatPipe,
    private projectService: ProjectFrontendService
  ) { }

  public map(dto: HierarchyDTO) {
    const model = Object.faMapTo(new HierarchyModel(), dto);
    model.CreatedDate = DateUtils.newDate(dto.CreatedDate);
    model.ModifiedDate = DateUtils.newDate(dto.ModifiedDate);
    model.ReconciliationStartDate = DateUtils.newNullableDate(dto.ReconciliationStartDate);
    model.NonNegative = dto.NonNegative ?? false;
    const empty = model.Relations.filter(x => !!!x.ForecastId);
    const ordered = model.Relations.filter(x => !!x.ForecastId).sort((a, b) => a.ForecastName.localeCompare(b.ForecastName));
    model.Relations = [...ordered, ...empty];

    if (model.Relations.length > 0) {
      model.Relations.forEach(relation => {
        const proj = this.projectService.projectByForecastId(relation.ForecastId);
        relation.hasPermissionToUpdate = !!proj && proj.hasPermission('CAN_UPDATE_FORECAST_VARIABLE');
      });
    }

    model.validReconciliation = model.Reconciliations.length > 0 && model.Reconciliations.every(x => x.ReconciliationResults.every(y => y.ToForecastResultId));
    return model;
  }

  public mapReconciliationReport(HFNode: HForecastTreeNode) {
    const reconciliation = HFNode.Hierarchy.Reconciliations.find(x => x.HierarchyId === HFNode.Hierarchy.HierarchyId);
    const hierarchy = HFNode.Hierarchy;
    const model = Object.faMapTo(new ReconciliationReport, reconciliation);
    model.Name = reconciliation.Name;
    model.Creator = reconciliation.CreatorClientEmail;
    model.StartDate = this.dateFormatter.transform(reconciliation.ReconciliationResults[0].FromResult.Values[0].D, hierarchy.Periodicity);
    model.EndDate = this.dateFormatter.transform(reconciliation.ReconciliationResults[0].FromResult.Values[hierarchy.Horizon - 1].D, hierarchy.Periodicity);

    model.Entries = HierarchyUtils.flattenTree(HFNode).map(x => {
      const entry = new ReconciliationEntryMeta();
      entry.Name = x.Relation.ForecastName;

      if (x.level > 0) {
        const indent = Array.from({ length: x.level }, () => '›').join('');
        entry.Name = `${indent} ${entry.Name}`;
      }

      const reconResult = reconciliation.ReconciliationResults.find(y => y.RelationId === x.Relation.RelationId);

      entry.Values = reconResult.ToResult.Values.map((y, index) => ({
        Date: this.dateFormatter.transform(y.D, hierarchy.Periodicity),
        Value: HierarchyUtils.getValueAtIndex(index, reconResult, HFNode.displayAs) + (HFNode.displayAs === 'percent_diff' ? '%' : '')
      }));

      // set fitted and residuals values to the correct date format
      if (reconResult.ToResult.FittedValues.length > 0) {
        entry.FittedValues = reconResult.ToResult.FittedValues.map(x => ({ ...x, D: this.dateFormatter.transform(x.D, hierarchy.Periodicity) })).reverse();
      }

      if (reconResult.ToResult.Residuals.length > 0) {
        entry.Residuals = reconResult.ToResult.Residuals.map(x => ({ ...x, D: this.dateFormatter.transform(x.D, hierarchy.Periodicity) }));
      }

      return entry;
    });

    return model;
  }

  public mapNewRelation(relation: HierarchyRelationDTO, newForecastId: string): UpdateHierarchyRelationDTO {
    const dto: UpdateHierarchyRelationDTO = {
      HierarchyId: relation.HierarchyId,
      ParentRelationId: relation.ParentRelationId,
      RelationId: relation.RelationId,
      ForecastId: newForecastId
    };
    return dto;
  }
}
