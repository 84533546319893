
export class SqlDatabaseInfoModelDTO {

  Name: string; // DisplayName
  SetupComplete: boolean;
  Encrypt: boolean;
  Database: string;
  Tables: DbTableInfoDTO[];
  Config: DbConfigurationDTO;
}

export class ConnectDbDTO {
  Name: string;
  Username: string;
  Password: string;
  Database: string;
  Url: string;
  Port: number;
  Encrypt: boolean;
}

export class DbTableInfoDTO {
  Name: string;
  Columns: DbColumnInfoDTO[];
}

export class DbColumnInfoDTO {
  Name: string;
  Type: string;
}

export class CreateDbConfigurationDTO {
  // Meta table
  TableName: string;
  IdField: string;
  NameField: string;

  // Values table
  ObservationTableName: string;
  ObservationIdField: string;
  ObservationDateField: string;
  ObservationValueField: string;

  CategoryFields: string[];
  MetaFields: string[];
}

export class DbConfigurationDTO extends CreateDbConfigurationDTO {
  Navigation: DbNavTreeDTO[];
}

export class DbNavTreeDTO {
  Name: string;
  CategorySelection: string[];
  Children: DbNavTreeDTO[];
}

export class DbVariableMetaDTO {
  public Id: string;
  public Name: string;
  public CategoryValues: string[];
  public MetaValues: string[];
}
