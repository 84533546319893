import { Def } from '@core/decorators/def';

export class CreateCompanyDTO {
  @Def() Name: string;
  @Def() CountryCode: string;
  @Def() MaxUsers: number;
  @Def() LicenseType: string;
  @Def() DisabledProviders: string;
  @Def() Trial: boolean;
  @Def() TrialExpirationDate: string;
  @Def() Users: INewUser[] = [];
  @Def() Support: string[] = [];
}

export interface INewUser {
  Email: string;
  CompanyRole: string;
  valid?: boolean;
}
