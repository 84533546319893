import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnChanges, OnDestroy, Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'indicio-searchable-multiselect-dropdown',
  templateUrl: './searchable-multiselect-dropdown.component.html',
  styleUrls: ['./searchable-multiselect-dropdown.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchableMultiDropdownComponent<T> implements OnChanges, OnDestroy {

  @Input() label: string;
  @Input() optionDisplay: string = null;
  @Input() optionTitle: string = null;
  @Input() optionValue: string = null;
  @Input() values: T[];
  @Input() initializeWithFirstValue = true;
  @Input() required = false;
  @Input() selectedValue: T[] = null;
  @Input() dropdownClass: string;
  @Input() disabled: boolean = false;

  @Output() valueChangeEvent = new EventEmitter<T[]>();

  // Properties
  private _onDestroy = new Subject<void>();
  public mainControl: FormControl<T[]>;
  public searchControl: FormControl<string>;
  public filteredValues: T[];

  public ngOnChanges() {
    this.setup();
  }

  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setup() {
    if (!this.values) { return; }
    this.mainControl = new FormControl<T[]>(null);
    this.searchControl = new FormControl<string>('');
    this.filteredValues = this.values.slice();

    this.setInitialValue();

    this.searchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(s => this.filter());
    this.mainControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(val => this.emitSelected(val));

    if (this.disabled) {
      this.mainControl.disable();
    }
  }

  private setInitialValue() {
    const initial = this.mainControl.value;
    if (this.selectedValue) {
      this.mainControl.setValue(this.values.filter(v => this.selectedValue.some(s => this.isSame(s, v))));
    } else if (this.initializeWithFirstValue && this.values.length > 0) {
      this.mainControl.setValue([this.values[0]]);
    }

    if (initial !== this.mainControl.value) {
      this.valueChangeEvent.emit(this.mainControl.value);
    }
  }

  private isSame(a: T, b: T) {
    const aVal = !!this.optionValue ? a[this.optionValue] : a;
    const bVal = !!this.optionValue ? b[this.optionValue] : b;
    return aVal === bVal;
  }

  private emitSelected(objs: T[]) {
    this.valueChangeEvent.emit(objs);
  }

  private filterInternal(search: string) {
    return this.values.filter(e => {
      const val = this.optionDisplay ? e[this.optionDisplay] : e;
      const regex = new RegExp(search, 'i');
      return regex.test(val);
    });
  }

  private filter() {
    let search = this.searchControl.value;
    if (!search) {
      return this.filteredValues = this.values.slice();
    }

    this.filteredValues = this.values.filter(e => this.filterInternal(search));
  }
}
