<div class="company-create-users-tab">
  <h3 class="extra-margin-top">
    Users to invite
    <div class="subtitle">
      A list of users to invite. Can be separated by commas, semicolons or new lines.
    </div>
  </h3>

  <div style="margin-top: 20px;">
    <textarea #content
              [(ngModel)]="userList"
              (paste)="handlePaste($event)"
              (blur)="parseUserList()"
              name="users"
              cols="15"
              rows="10"></textarea> <br>
    <button mat-button
            (click)="parseUserList()">Verify list</button>
    <ng-container *ngIf="validUsers.length">
      <h3 style="margin-top: 10px;">These users will be invited when the company is created</h3>
      <br>
      <indicio-data-table columnTemplate="50px 1fr 120px">
        <div title-row>
          <div column><span title>#</span></div>
          <div column
               left><span title>Email</span></div>
          <div column
               left><span title>Role</span></div>
        </div>
        <div style="max-height: 150px; overflow: auto;">
          <ng-container *ngFor="let user of validUsers; let index = index">
            <div row>
              <div column
                   class="center-vertical"
                   style="justify-content: center;"><span>{{ index + 1 }}</span></div>
              <div class="center-vertical crop-string"
                   [title]="user.Email"
                   column><span [class]="user.valid ? 'green' : 'red'">{{ user.Email }}</span></div>
              <div column
                   class="center-vertical">
                <mat-form-field style="width: 100%;">
                  <mat-select [(ngModel)]="user.CompanyRole"
                              required>
                    <mat-option [value]="c.Value"
                                *ngFor="let c of roles"> {{ c.Display }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </div>
      </indicio-data-table>

      <ng-container *ngIf="invalidUsers.length">
        <div style="margin: 10px 0;">The following emails are invalid, please recheck list</div>
        <div class="red"
             *ngFor="let user of invalidUsers">{{ user.Email }}</div>
      </ng-container>
    </ng-container>
  </div>
</div>
