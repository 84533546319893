import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { MarkdownService } from 'ngx-markdown';
import { Subscription } from 'rxjs';
import { WikiActions } from '../../wiki.actions';
import { WikiConstants } from '../../wiki.constants';
import { CreateWikiPageDTO, WikiAssetDTO, WikiPageDTO } from '../../wiki.entities';
import { WikiMapper } from '../../wiki.mapper';
import { WikiService } from '../../wiki.service';

@Component({
  selector: 'indicio-wiki-page',
  templateUrl: './wiki-page.component.html',
  styleUrls: ['./wiki-page.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class WikiPageComponent implements OnInit, OnChanges {

  @Input() public page: WikiPageDTO | CreateWikiPageDTO;
  @Input() public showTitle: boolean = false;
  @Input() public darkMode: boolean;

  private subscription = new Subscription();

  public get isDarkMode(): boolean {
    return this.darkMode == null && this.appearance.ActualTheme === 'dark';
  }

  constructor(
    private markdownService: MarkdownService,
    private wikiService: WikiService,
    private wikiMapper: WikiMapper,
    private actions: ActionService,
    private cd: ChangeDetectorRef,
    private appearance: AppearanceService
  ) {

  }

  ngOnInit() {
    this.markdownService.reload();
    this.setupSubscriptions();

    this.markdownService.renderer.text = (text: string) => {
      // Replace asset loaders with asset images
      const assetSearchRegex = WikiConstants.AssetRegexp;
      let assetMatch;
      while (assetSearchRegex.global && (assetMatch = assetSearchRegex.exec(text))) {
        const asset = this.wikiService.assets.find(x => x.AssetId === assetMatch[1]);
        if (asset && !!asset.Base64String) {
          let imageText = this.markdownService.renderer.image(asset.imageString, asset.Name, asset.Name);

          if (!this.wikiService.openInSidebar && assetMatch[2]) {
            let [height, width] = assetMatch[2].split('x');
            if (Number(height) > 0) { imageText = imageText.replace(/^<img\s/gm, `<img height="${height}" `); }
            if (Number(width) > 0) { imageText = imageText.replace(/^<img\s/gm, `<img width="${width}" `); }
          }

          text = text.replace(assetMatch[0], imageText);
          this.cd.detectChanges();
        }
      }

      // Replace asset loaders with loader elements
      const assetLoaderSearchRegex = WikiConstants.AssetLoaderRegexp;
      let loaderMatch;
      while (assetSearchRegex.global && (loaderMatch = assetLoaderSearchRegex.exec(text))) {
        text = text.replace(loaderMatch[0], WikiConstants.AssetLoaderElement);
        this.cd.detectChanges();
      }

      return text;
    };

    // Replace wiki links with custom component
    this.markdownService.renderer.link = (href, title, text) => {
      return (`<indicio-wiki-link href="${href}" title="${title}" text="${text}"></indicio-wiki-link>`);
    };
  }

  ngOnChanges() {
    if (!this.page) { return; }
    const searchRegex = WikiConstants.AssetLoaderRegexp;
    let match;
    // Load assets if we're missing them and replace asset loaders with assets if they exist
    while (searchRegex.global && (match = searchRegex.exec(this.page.Content))) {
      const asset = this.wikiService.assets.find(x => x.AssetId === match[1]);
      if (!asset || !!!asset.Base64String) {
        this.wikiService.getOrFetchAsset(match[1]);
      } else {
        this.page.Content = this.wikiMapper.replaceLoadersWithAssets(this.page.Content, asset.AssetId);
        this.cd.detectChanges();
        this.markdownService.reload();
      }
    }
  }

  private setupSubscriptions() {
    // Subscribe to asset loaded events
    this.subscription.add(this.actions.dispatched(WikiActions.AssetLoaded).subscribe((payload: { asset: WikiAssetDTO; }) => {
      this.page.Content = this.wikiMapper.replaceLoadersWithAssets(this.page.Content, payload.asset.AssetId);
      this.cd.detectChanges();
      this.markdownService.reload();
    }));
  }
}
