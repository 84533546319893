import { DateUtils } from '@shared/utils/date.utils';
import * as moment from 'moment';
import { PeriodicityType } from '../language-files/periodicities';
import { DisplayValue } from './display-value';


export class Periodicity extends DisplayValue<PeriodicityType> {

  // Regular expression to match against strings, regardless of case
  private nameRegEx: RegExp;

  constructor(name: PeriodicityType, display: string, desc: string) {
    super('month', 'Unknown', 'Unknown');
    if (name === null) { return; }
    this.Value = name;
    this.Description = desc;
    this.Display = display;
    this.nameRegEx = new RegExp(`^${this.Value}$`, 'i');
  }

  public isShorterOrSame(name: PeriodicityType): boolean {
    return this.is(name) || this.isShorter(name);
  }

  public isLongerOrSame(name: PeriodicityType): boolean {
    return this.is(name) || this.isLonger(name);
  }

  public is(name: PeriodicityType) { return this.nameRegEx.test(name); }

  public isShorter(other: PeriodicityType): boolean {
    const otherInt = this.getInt(other);
    const thisInt = this.getInt(this.Value);
    return thisInt < otherInt;
  }

  public isLonger(other: PeriodicityType): boolean {
    const otherInt = this.getInt(other);
    const thisInt = this.getInt(this.Value);
    return thisInt > otherInt;
  }

  public getInt(name: PeriodicityType = this.Value): number {
    switch (name) {
      case 'day': return 1;
      case 'weekday': return 1;
      case 'week': return 2;
      case 'month': return 3;
      case 'quarter': return 4;
      case 'halfyear': return 5;
      case 'year': return 6;
      default:
        console.error('Periodicity name not valid: In Periodicity.ts (getInt)');
    }
  }

  public getMomentDurationConstructor(): moment.unitOfTime.DurationConstructor {
    let units: moment.unitOfTime.DurationConstructor;
    // Manipulate the variable
    if (this.is('day')) {
      units = 'day';
    } else if (this.is('weekday')) {
      units = 'day';
    } else if (this.is('week')) {
      units = 'week';
    } else if (this.is('month')) {
      units = 'month';
    } else if (this.is('quarter')) {
      units = 'quarter';
    } else if (this.is('halfyear')) {
      console.error('Half year not supported by moment. Fix this everywhere');
      units = 'halfyear' as any;
    } else if (this.is('year')) {
      units = 'year';
    }
    return units;
  }

  public getSuggestedStartDate(lastDate: Date) {
    return DateUtils.nextPeriod(DateUtils.newMoment(lastDate), this).toDate();
  }
}
