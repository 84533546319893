import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { DialogV2BaseService } from '@dialogs/base/dialogs.V2.base-service';
import { take } from 'rxjs/operators';
import { ExportSeasonalDataToMacrobondDialogComponent, ExportSeasonalDataToMacrobondDialogData } from './export-sa-data/export-sa-data-to-macrobond.dialog';


@Injectable({ providedIn: 'root' })
export class MacrobondDialogsService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openExportSaDataToMacrobond(
    data: ExportSeasonalDataToMacrobondDialogData,
    config: MatDialogConfig = { width: '500px' }
  ) {
    const cfg = this.getConfig(ExportSeasonalDataToMacrobondDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, any>(ExportSeasonalDataToMacrobondDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }
}
