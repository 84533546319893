import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { CompanyActions } from '@core/store/company/company.actions';
import { CompanyModel } from '@core/store/company/company.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { AdminCompanyService } from '@modules/admin/components/system/companies/company.admin.service';
import { Country } from '@modules/lang/types/country';
import { DisplayValue } from '@modules/lang/types/display-value';
import { Store } from '@ngxs/store';
import { DialogService } from '@shared/modules/dialogs/dialog.service';


export class SupportUser {
  Display: string;
  Value: string;
  Roles: string[];
}

@Component({
  selector: 'indicio-company-edit-admin-dialog-general-tab',
  templateUrl: './company-edit-tab.general.component.html',
  styleUrls: ['./company-edit-tab.general.component.less'],
})
export class CompanyEditGeneralTabComponent implements OnChanges {

  // Font-aweseome
  public faSave = faSave as IconProp;
  public faInfo = faInfoCircle as IconProp;

  @Input() company: CompanyModel;

  @Output() newCountryEvent = new EventEmitter<Country>();
  @Output() maxUsersEvent = new EventEmitter<number>();
  @Output() supportEvent = new EventEmitter<string[]>();

  // Settings
  public newName: string;
  public editName: boolean = false;
  public pendingName: boolean = false;
  public selectedCountry: Country;

  public supportUsers: { role: string, users: DisplayValue[]; }[] = [];
  public selectedSupporters: DisplayValue[] = [];
  public loadingSupportUsers: boolean = false;

  constructor(
    private status: StatusService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private service: AdminCompanyService,
    private dialogs: DialogService,
    public envService: EnvironmentService,
  ) {
  }

  public ngOnChanges() {
    if (!this.company) { return; }
    this.setup();
    this.cd.detectChanges();
  }

  private setup() {
    this.newName = this.company.Name;
    this.selectedCountry = this.envService.getCountry(this.company.CountryCode);

    this.loadingSupportUsers = true;

    this.service.getSupportUsers()
      .then(resp => this.supportUsers = resp)
      .then(() => {
        this.selectedSupporters = this.company.Support.map(x => {
          return this.supportUsers.find(y => y.users.find(z => z.Value === x)).users.find(z => z.Value === x);
        });
      })
      .finally(() => this.loadingSupportUsers = false);
  }

  public setAdministrators($event: MatSelectChange) {
    this.selectedSupporters = $event.value;
    this.supportEvent.emit(this.selectedSupporters.map(x => x.Value));
  }

  public async saveName() {
    if (this.pendingName || this.newName === this.company.Name) { return; }
    this.editName = false;

    const confirm = this.dialogs.openConfirmDialog({
      Title: 'Change name of company',
      Message: `Old name: <b>${this.company.Name}</b><br><br>
                New name: <b>${this.newName}</b><br><br>
                Are you sure you want to rename the company?`,
      CancelText: 'Cancel',
      ConfirmText: 'Yes, rename',
      Style: 'warn',
    }).toPromise();

    return confirm.then(ans => {
      if (!ans) {
        this.newName = this.company.Name;
        return;
      }
      this.pendingName = true;
      return this.service.updateCompanyName(this.company.CompanyId, { Name: this.newName })
        .then(() => {
          this.status.setMessage('Company name updated', 'Success', true);
          this.company.Name = this.newName;
          this.store.dispatch(new CompanyActions.GetCompanySuccessAction(this.company));
        })
        .catch(error => this.status.setError(error, true))
        .finally(() => this.pendingName = false);
    });
  }

  public setCountry($event: Country) {
    this.newCountryEvent.emit($event);
  }

  public setMaxUsers(change: MatSelectChange) {
    this.maxUsersEvent.emit(change.value);
  }
}
