import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { IndicioHttpContext } from '@core/services/http/indicio-http-context';
import { ForecastVersionDTO } from '@core/store/forecast/dtos/forecast-version/forecast-version-dto';
import { ForecastVersionDateRangeDTO } from '@core/store/forecast/dtos/forecast-version/forecast-version-meta-dto';
import { CreateForecastDTO } from '@core/store/forecast/dtos/forecast/create-forecast-dto';
import { CreateNowcastDTO } from '@core/store/forecast/dtos/forecast/create-nowcast-dto';
import { ForecastDTO, RecentForecastDTO } from '@core/store/forecast/dtos/forecast/forecast-dto';
import { ForecastMetaDTO } from '@core/store/forecast/dtos/forecast/forecast-meta-dto';
import { ScriptCalculateInfoDTO } from '@core/store/forecast/dtos/misc/script-calculate-info-dto';
import { VsResultType } from '@modules/lang/language-files/var-select';
import { FVersionSettings } from '../display/forecast-version-settings';
import { RQueueInfoDTO } from '../script-queue/dtos/r-queue.dtos';
import { UpdateForecastVersionDTO } from './dtos/forecast-version/update-forecast-version-dto';
import { CopyForecastDTO } from './dtos/forecast/copy-forecast-dto';
import { MoveForecastDTO } from './dtos/forecast/move-forecast-dto';
import { GetPotentialForecastsDTO, PotentialForecastDTO } from './dtos/forecast/potential-forecast.dto';
import { UpdateForecastDTO } from './dtos/forecast/update-forecast-dto';
import { ForecastModelFilterSettingsDTO } from './dtos/model-filters/forecast-model-filter-settings-dto';
import { VarSelectSettingsDTO } from './dtos/var-select-settings/var-select-settings-dto';
import { ForecastEndpoints } from './forecast.endpoints';
import { ForecastMapper } from './mapper/forecast-mapper';
import { ForecastVersionModel } from './models/forecast-version.model';
import { ForecastModel } from './models/forecast.model';


@Injectable({
  providedIn: 'root'
})
export class ForecastBackendService {

  constructor(
    private mapper: ForecastMapper,
    private http: HttpService
  ) { }

  public getForecast(forecastId: string) {
    return this.http.get<ForecastDTO>(ForecastEndpoints.get(forecastId))
      .then(res => {
        const model = this.mapper.map(res.body);
        return model;
      });
  }

  public setActiveVs(forecastVersionId: string, vsType: VsResultType) {
    return this.http.post<boolean>(`forecast/version/${forecastVersionId}/set-active-vs/${vsType}`, null);
  }

  public deleteVersion(forecastVersionId: string) {
    return this.http.delete<null>(ForecastEndpoints.deleteVersion(forecastVersionId));
  }

  public getForecastVersion(forecastVersionId: string) {
    return this.http.get<ForecastVersionDTO>(ForecastEndpoints.getVersion(forecastVersionId));
  }

  public getForecastVersionDateInfo(forecastVersionId: string) {
    return this.http.get<ForecastVersionDateRangeDTO>(ForecastEndpoints.getDateInfo(forecastVersionId))
      .then(resp => resp.body);
  }

  public fetchCanCalculateInformation(forecastVersionId: string) {
    return this.http.get<ScriptCalculateInfoDTO>(ForecastEndpoints.getCanCalc(forecastVersionId))
      .then(resp => resp.body);
  }

  public getForecastMeta(forecastId: string) {
    return this.http.get<ForecastMetaDTO>(ForecastEndpoints.getMeta(forecastId))
      .then(res => res.body);
  }

  public getRecentlyModified(companyId: string, count: number) {
    return this.http.get<RecentForecastDTO[]>(ForecastEndpoints.recentlyModified(companyId, count))
      .then(res => res.body.map(x => this.mapper.mapRecentForecast(x)));
  }

  public getForecastCount(companyId: string) {
    return this.http.get<number>(ForecastEndpoints.getForecastCount(companyId))
      .then(res => res.body);
  }

  public getForecastsInProject(projectId: string) {
    return this.http.get<ForecastMetaDTO[]>(ForecastEndpoints.getMetas(projectId))
      .then(res => res.body);
  }

  public createForecast(companyId: string, createDto: CreateForecastDTO) {
    return this.http.post<ForecastDTO>(ForecastEndpoints.create(companyId, createDto.ProjectId), createDto)
      .then(res => this.mapper.map(res.body));
  }

  public copyForecast(companyId: string, dto: CopyForecastDTO) {
    return this.http.post<ForecastDTO>(ForecastEndpoints.copy(companyId), dto, 'backend', IndicioHttpContext.GetContext({ Timeout: 300000 }))
      .then(({ body }) => this.mapper.map(body));
  }

  public moveForecast(companyId: string, moveDto: MoveForecastDTO) {
    return this.http.post<ForecastDTO>(ForecastEndpoints.move(companyId), moveDto)
      .then(({ body }) => this.mapper.map(body));
  }

  public removeForecast(companyId: string, forecastId: string) {
    return this.http.delete<null>(ForecastEndpoints.remove(companyId, forecastId));
  }

  public removeNowcast(companyId: string, forecastId: string) {
    return this.http.delete<null>(ForecastEndpoints.removeNowcast(companyId, forecastId));
  }

  public createNowcast(body: CreateNowcastDTO) {
    return this.http.post<ForecastDTO>(ForecastEndpoints.createNowcast(), body, 'backend',
      IndicioHttpContext.GetContext({ Timeout: 300000 }))
      .then(res => this.mapper.map(res.body));
  }

  public updateForecast(forecast: ForecastModel, dto: UpdateForecastDTO) {
    return this.http.put<ForecastDTO>(ForecastEndpoints.update(forecast.ForecastId), dto)
      .then(res => this.mapper.map(res.body, forecast));
  }

  public updateForecastVersion(version: ForecastVersionModel, dto: UpdateForecastVersionDTO) {
    return this.http.put<ForecastVersionDTO>(ForecastEndpoints.updateVersion(version.ForecastVersionId), dto, 'backend', IndicioHttpContext.GetContext({
      Info: { dto },
      Timeout: 300000
    }))
      .then(({ body }) => body);
  }

  public getForecastVersionSettings(versionId: string) {
    return this.http.get<FVersionSettings.ForecastVersionClientSettingsDTO>(ForecastEndpoints.settings(versionId))
      .then(({ body }) => body);
  }

  public saveForecastVersionSettings(dto: FVersionSettings.ForecastVersionClientSettingsDTO) {
    return this.http.post<FVersionSettings.ForecastVersionClientSettingsDTO>(ForecastEndpoints.saveSettings(dto.ForecastVersionId), dto)
      .then(({ body }) => body);
  }

  public getPotentialForecasts(dto: GetPotentialForecastsDTO, companyId: string) {
    return this.http.post<PotentialForecastDTO[]>(ForecastEndpoints.potentialForecasts(companyId), dto)
      .then(({ body }) => body);
  }

  /**
   *
   * MODEL FILTER SETTINGS
   *
   */
  public getForecastModelFilterSettings(forecastId: string) {
    return this.http.get<ForecastModelFilterSettingsDTO>(ForecastEndpoints.filters(forecastId))
      .then(resp => resp.body);
  }

  public saveForecastModelFilterSettings(forecastId: string, dto: ForecastModelFilterSettingsDTO) {
    return this.http.post<ForecastModelFilterSettingsDTO>(ForecastEndpoints.saveFilters(forecastId), dto)
      .then(resp => resp.body);
  }

  public removeForecastModelFilterSettings(forecastId: string, filterId: string) {
    return this.http.delete<ForecastModelFilterSettingsDTO>(ForecastEndpoints.removeFilter(forecastId, filterId))
      .then(resp => resp.body);
  }

  /**
   *
   * VAR SELECT SETTINGS
   *
   */
  public saveVarSelectSettings(fVersionId: string, settings: VarSelectSettingsDTO) {
    return this.http.post<VarSelectSettingsDTO>(ForecastEndpoints.saveVsSettings(fVersionId), settings)
      .then(resp => resp.body);
  }
  public getVarSelectSettings(fversionId: string) {
    return this.http.get<VarSelectSettingsDTO>(ForecastEndpoints.vsSettings(fversionId))
      .then(resp => resp.body);
  }

  /**
 *
 * R QUEUE
 *
 */
  public getRequestQueue(forecastVersionId: string) {
    return this.http.get<RQueueInfoDTO>(ForecastEndpoints.rQueue(forecastVersionId))
      .then(resp => resp.body);
  }

  /**
 *
 * Warnings
 *
 */
  public removeIndicatorWarnings(forecastVersionId: string) {
    return this.http.post<null>(ForecastEndpoints.removeWarnings(forecastVersionId), null)
      .then(resp => resp.body);
  }
}
