<indicio-dialog-base>
  <h1 mat-dialog-title>{{update ? "Update group variable" : "Create new group variable"}}</h1>
  <ng-container *ngIf="!isLoading else showLoader">
    <ng-container *ngIf="!tooManyVariables; else tooManyTemplate">
      <ng-container *ngIf="!nestedGroups; else nestedGroupsTemplate">
        <div mat-dialog-content>
          <div class="group-variables">
            <div class="row margin-top"
                 *ngIf="graphReady">
              <div class="col-xs-12">
                <indicio-alg class="modal-chart"
                             [periodicityType]="periodicity.Value"
                             [HistoricLine]="historicLine"
                             [options]="options"
                             [chartStyles]="chartStyles"
                             [showExport]="false"
                             graphType="generic">
                </indicio-alg>
              </div>
            </div>
            <!-- Group settings -->
            <div class="row">
              <div class="col-xs-12 relative">
                <mat-form-field style="margin-top: -20px;">
                  <mat-label>Group name</mat-label>
                  <input required
                         class="material"
                         matInput
                         type="text"
                         name="name"
                         (ngModelChange)="changeGroupName($event)"
                         [ngModel]="variable.Name">
                  <mat-hint *ngIf="nameConflict">
                    <span class="error-text">Name conflict</span>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row margin-top">
              <div class="col-xs-12 relative">
                <mat-form-field>
                  <mat-label>Expression</mat-label>
                  <input required
                         class="material"
                         matInput
                         type="text"
                         name="expression"
                         (ngModelChange)="changeGroupExpression($event)"
                         [ngModel]="variable.InfixExpression"
                         (keyup.enter)="fetchPreview()">
                  <div matSuffix>
                    <mat-icon (click)="fetchPreview()">refresh</mat-icon>
                  </div>
                  <mat-hint *ngIf="!validExpression">
                    <span class="error-text">Invalid expression, allowed operators: ( ) + - * / ^</span>
                  </mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row margin-top">
              <div class="col-xs-12">
                <div class="data-table"
                     style="width: 700px;">
                  <div class="data-table__header">
                    <div class="column header"
                         style="width: 400px; text-align: left; padding-left: 5px;">Name</div>
                    <div class="column header"
                         style="width: 150px;">Identifier</div>
                    <div class="column header"
                         style="width: 150px;">Aggregation</div>
                  </div>
                  <div class="data-table__body"
                       style="max-height: 200px;">
                    <div class="body__row body__row--center-height body__row--max-height-30"
                         *ngFor="let variable of sourceVariables; let i = index">
                      <div class="column crop-string"
                           style="width: 400px; text-align: left;">
                        <span [title]="variable.Name">{{ variable.Name }}</span>
                      </div>
                      <div class="column"
                           style="width: 150px;">
                        <span [title]="identifiers[i]">{{ identifiers[i] }}</span>
                      </div>
                      <div class="column"
                           style="width: 150px;">
                        <mat-form-field>
                          <mat-select [value]="variable.getBaseVersion().AggregationMethodId"
                                      panelClass="data-table-panel-opts"
                                      disabled
                                      required>
                            <mat-option [value]="option.Value"
                                        [title]="option.Description"
                                        *ngFor="let option of envService.AggregationMethods">{{ option.Display }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <indicio-modal-option-row [stacked]="true"
                                      optionTitle="Group periodicity">
              <mat-form-field>
                <mat-select [(value)]="variable.groupPeriodicity"
                            (selectionChange)="checkAggregation()"
                            required>
                  <mat-option [value]="p.Value"
                              [title]="p.Description"
                              *ngFor="let p of envService.getValidPeriodicites(companyService.horizonInfos)">
                    {{ p.Display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </indicio-modal-option-row>

            <indicio-modal-option-row [stacked]="true"
                                      optionTitle="Group access">
              <mat-form-field>
                <mat-select [(value)]="variable.VisibilityLevelId"
                            (selectionChange)="checkAggregation()"
                            required>
                  <mat-option [value]="p.Value"
                              [title]="p.Description"
                              *ngFor="let p of envService.VisibilityLevels">
                    {{ p.Display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </indicio-modal-option-row>

            <div class="row"
                 *ngIf="needUpAgg">
              <div class="col-xs-12 relative">
                <mat-form-field>
                  <mat-select [(value)]="variable.UpAggregationMethodId"
                              required
                              placeholder="Aggregation Method">
                    <mat-option [value]="option.Value"
                                [title]="option.Description"
                                *ngFor="let option of envService.AggregationMethods">{{ option.Display }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row"
                 *ngIf="needDownAgg">
              <div class="col-xs-12 relative">
                <mat-form-field>
                  <mat-select [(value)]="variable.DownAggregationMethodId"
                              required
                              placeholder="Disaggregation Method">
                    <mat-option [value]="option.Value"
                                [title]="option.Description"
                                *ngFor="let option of envService.AggregationMethods">{{ option.Display }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div mat-dialog-actions>
          <button mat-button
                  (click)="onNoClick()">
            Cancel
          </button>
          <button mat-flat-button
                  color="primary"
                  [disabled]="isDisabled"
                  (click)="save()">
            {{update ? "Update" : "Create"}}
          </button>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</indicio-dialog-base>

<ng-template #tooManyTemplate>
  <div mat-dialog-content>
    <div>
      Cannot add more than 52 variables to a group.
    </div>
  </div>
  <div mat-dialog-actions>
    <div></div>
    <button mat-flat-button
            color="primary"
            (click)="onNoClick()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #nestedGroupsTemplate>
  <div mat-dialog-content>
    <div>
      Nested groups are not allowed. Do not include group variables in a new group variable.
    </div>
    <br>
    <div>
      Remove the following variables before creating the group:
    </div>
    <div *ngFor="let v of fromGroups">
      - {{ v.Name }}
    </div>

  </div>
  <div mat-dialog-actions>
    <div></div>
    <button mat-flat-button
            color="primary"
            (click)="onNoClick()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #showLoader>
  <div class="center-text">
    <i class="ion-load-c spinner info-loader "
       style="font-size: 50px;"></i>
  </div>
</ng-template>
