import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';

import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { SourceVariableDTO } from '@core/store/source-variable/dtos/source-variable-dto';
import { SourceVariableMapper } from '@core/store/source-variable/source-variable.mapper';
import { SourceVariableModel } from '@core/store/source-variable/source-variable.model';

@Injectable({
  providedIn: 'root'
})
export class GroupVariableBackendService {

  constructor(
    private http: HttpService,
    private mapper: SourceVariableMapper
  ) { }

  public createGroupVariable(variable: SourceVariableModel, identifiers: string[]) {
    const dto = this.mapper.toCreateGroup(variable, identifiers);
    return this.http.post<SourceVariableDTO>(`company/${variable.CompanyId}/create-group-variable`, dto)
      .then(resp => this.mapper.map(resp.body));
  }

  public getPreviewGroupVariable(variable: SourceVariableModel, identifiers: string[]) {
    const dto = this.mapper.toCreateGroup(variable, identifiers);
    return this.http.post<PlotValueDTO[]>(`company/${variable.CompanyId}/preview-group-variable`, dto).then(resp => resp.body);
  }

  public updateGroupVariable(variable: SourceVariableModel, identifiers: string[]) {
    const dto = this.mapper.toEditGroup(variable, identifiers);
    return this.http.post<SourceVariableDTO>(`company/${variable.CompanyId}/group/variables/${variable.SourceVariableId}/edit-group-variable`, dto)
      .then(resp => this.mapper.map(resp.body));
  }
}









