import { CalendarEffects } from '@dialogs/variables/forecast-variable/info/tabs/seasonal/fvar-calendar-settings';
import { DisplayValue } from '../types/display-value';

export type SeasonalModelType = 'x13' | 'x11' | 'jdx13';
export type SeasonalStrategyType = 'forecasted' | 'previous';
export type SeasonalTrendType = 'deactivate' | 'main_variable' | 'all_variables';
export type SeasonalOutlierType = 'ao' | 'ls' | 'tc';

export class SeasonalModel extends DisplayValue<SeasonalModelType> { };
export const SeasonalModels = [
  new SeasonalModel('x13', 'X13', 'SEATS model first developed by the Bank of Spain'),
  new SeasonalModel('jdx13', 'JDemetra+ X13', 'JDemetra+ X13 model developed by EuroStat'),
  new SeasonalModel('x11', 'X11', 'X11 model developed by the U.S. Census Bureau')
];

export class SeasonalStrategy extends DisplayValue<SeasonalStrategyType> { };
export const SeasonalStrategies = [
  new SeasonalStrategy('forecasted', 'Forecasted', 'Forecast the seasonal pattern'),
  new SeasonalStrategy('previous', 'Last period', 'Use the seasonal pattern from the last known period')
];

export class SeasonalTrend extends DisplayValue<SeasonalTrendType> { };
export const SeasonalTrends = [
  new SeasonalTrend('deactivate', 'Deactivate', 'Deactivate seasonal trends'),
  new SeasonalTrend('main_variable', 'Main variable', 'Use main variable for seasonal trend'),
  new SeasonalTrend('all_variables', 'All variables', 'Use all variables for seasonal trend')
];

export class SeasonalOutlier extends DisplayValue<SeasonalOutlierType> { };
export const SeasonalOutlierTypes = [
  new SeasonalOutlier('ao', 'AO', 'Additive outlier'),
  new SeasonalOutlier('ls', 'LS', 'Level shift'),
  new SeasonalOutlier('tc', 'TC', 'Temporary change')
];

export class SeasonalCalendar extends DisplayValue<CalendarEffects.CalendarType> { };
export const SeasonalCalendars = [
  new SeasonalCalendar('td', 'Trading day', 'Trading day'),
  new SeasonalCalendar('tdnolpyear', 'Trading Day without leap year', 'Trading Day without leap year'),
  new SeasonalCalendar('tdstock', 'Stock Trading Day', 'Stock Trading Day'),
  new SeasonalCalendar('lom', 'Length-of-Month', 'Length-of-Month'),
  new SeasonalCalendar('loq', 'Length-of-Quarter', 'Length-of-Quarter'),
  new SeasonalCalendar('lpyear', 'Leap Year', 'Leap Year'),
  new SeasonalCalendar('easter', 'Easter', 'Easter'),
  new SeasonalCalendar('easterstock', 'Stock Easter, End-of-Month', 'Stock Easter, End-of-Month'),
  new SeasonalCalendar('td1coef', 'Trading Day (one coef)', 'Trading Day (one coef)'),
  new SeasonalCalendar('td1nolpyear', 'Trading Day without leap year (one coef)', 'Trading Day without leap year (one coef)'),
  new SeasonalCalendar('tdstock1coef', 'Stock Trading Day (one coef)', 'Stock Trading Day (one coef)')
];
