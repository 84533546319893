import { ModalAction } from '@shared/modals/modal.service';

export type Google2FaModalView = '';

export class Google2FaModalOptions {
}

export class OpenGoogle2FAModal implements ModalAction {
  public static readonly type = '[MODAL] Open google 2fa modal';
  data: Google2FaModalOptions;
  ao: boolean = true;
  constructor(
  ) {
  }
}
