import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WikiService } from '@core/store/wiki/wiki.service';

@Component({
  selector: 'indicio-modal-option-row',
  template: `<div class="row modal-option-row" [class]="{ stacked: stacked}" [indicioTippy]=rowHoverText>
                <div [class]="'col-xs-'+textWidth">
                  <div class="input-label bold"
                      *ngIf="optionTitle.length > 0"
                       [ngClass]="{ disabled: disabled }">
                       <div style="display: inline-block"
                            [ngClass]="{ 'cursor-pointer': showWiki }"
                            (click)="showWiki ? openWiki() : null">
                          <span [ngClass]="{ 'dotted-underline': showWiki }">
                            {{ optionTitle }}
                          </span>&nbsp;
                          <indicio-wiki-icon [slug]="wikiSlug" [link]="false"></indicio-wiki-icon>
                       </div>
                  </div>
                  <div [class]="optionTitle.length === 0 ? 'subtitle input-label extra-margin-top' : 'subtitle'">
                    <span *ngIf="optionSubtitle" [innerHTML]="optionSubtitle"></span>
                    <ng-content select="[option-subtitle-note]"></ng-content>
                  </div>
                </div>
                <div [class]="'col-xs-'+optionsWidth">
                  <ng-content></ng-content>
                </div>
              </div>`
})
export class ModalOptionRowComponent implements OnChanges {

  @Input() optionTitle: string = '';
  @Input() optionSubtitle: string;
  @Input() disabled: boolean = false;
  @Input() textWidth: string = '8';
  @Input() optionsWidth: string = '4';
  @Input() rowHoverText: string = null;
  @Input() stacked: boolean = false;
  @Input() wikiSlug: string = null;

  public showWiki: boolean = false;

  constructor(
    private wikiService: WikiService
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.stacked?.currentValue === true) {
      this.textWidth = '12';
      this.optionsWidth = '12';
    }

    if (!!this.wikiSlug && this.wikiService.exists(this.wikiSlug)) {
      this.showWiki = true;
    }
  }

  public openWiki() {
    this.wikiService.open(this.wikiSlug);
  }
}
