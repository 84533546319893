import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface GenericInfoMessageDialogData {
  Title: string;
  Ingress?: string;
  Content: string | string[];
}

@Component({
  selector: 'indicio-generic-info-message-dialog',
  templateUrl: 'generic-info-message.dialog.html',
})
export class GenericInfoMessageDialogComponent {
  public static Id: string = 'GenericInfoMessageDialogComponent';

  public isList: boolean;

  constructor(
    public dialogRef: MatDialogRef<GenericInfoMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericInfoMessageDialogData) {
    this.isList = Array.isArray(data.Content);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(true);
  }
}
