<h1 mat-dialog-title>Report settings</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Report name</mat-label>
    <input type="text"
           class="material"
           matInput
           autocomplete="off"
           [(ngModel)]="newName"
           name="Name"
           indicioAutoFocusInput
           maxlength="64"
           require>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">Cancel</button>
  <button [disabled]="false"
          mat-flat-button
          [mat-dialog-close]="updatedSettings"
          cdkFocusInitial>
    Save
  </button>
</div>
