import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ProjectModel } from '@core/store/project/project.model';
import { AssessmentOverviewModalOpts } from './overview/assessment-overview-modal.options';
import { AssessmentModalOpts } from './specific/assessment-modal.options';

export class OpenAssessmentModal {
  public static readonly type = '[MODAL] Open assessment modal';
  data: AssessmentModalOpts;
  constructor(
    forecastVersion: ForecastVersionModel,
    project: ProjectModel,
    inputData: PlotValueDTO[],
    callback: any,
    assessment: AssessmentModel | null,
    onBack?: Function
  ) { this.data = { forecastVersion, project, inputData, callback, assessment, onBack }; }
}


export class OpenAssessmentOverviewModal {
  public static readonly type = '[MODAL] Open assessment overview modal';
  data: AssessmentOverviewModalOpts;
  constructor(
    project: ProjectModel,
    forecastVersion: ForecastVersionModel,
    inputData: PlotValueDTO[],
    callback: any = null,
    assessment: AssessmentModel = null,
  ) { this.data = { forecastVersion, project, inputData, callback, assessment }; }
}


