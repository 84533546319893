import { PlotValue, PlotValueDTO, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { ShapValueDTO } from '@core/store/stat-model/dtos/stat-model.dto';
import { Periodicity } from '@modules/lang/types/periodicity';
import { Store } from '@ngxs/store';
import { GraphHover } from '@shared/components/line-graph/alg-models/graph-hover';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { ShapGraphDialogData } from '@shared/modules/dialogs/shap-values/graph-dialog/shap-graph.dialog';
import { DateFormatPipe } from '@shared/modules/pipes';
import { DateUtils } from '@shared/utils/date.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import { ALGTypes } from '../alg-types';
import { SvgConfig, SvgObjects } from '../alg-worker/svg-manipulations/create-svg-objects';
import { ALGActions } from '../alg.actions';
import { ALGLineModel, ALGSingleSeriesModel, AlgModel, GraphData } from './graph-data.model';

export interface AlgInteractionsData {
  projectId: string;
  forecastId: string;
  fVersionId: string;
  fVarId: string;
  svgObjs: SvgObjects,
  instance: number,
  graphData: GraphData,
  periodicity: Periodicity,
  datePipe: DateFormatPipe,
  graphType: ALGTypes.Graph,
  config: SvgConfig;
  horizon: number;
}

class AlgInteractionPastInfo {
  Model: ALGSingleSeriesModel;
  Forecasts: ALGTypes.LineSegment[];
  Color: string;
  Dates: number[];
  Type: number;
}

export class AlgInteractions {

  private Hover: GraphHover;

  // Data
  private MaxHoverCount: number;
  public NonHistoricLines: ALGLineModel[];
  public PastForecasts: AlgInteractionPastInfo[];
  public FittedData: { Model: AlgModel; Values: ALGTypes.LineSegment[]; }[];
  public ShapData: { Model: ALGSingleSeriesModel, ShapValues: ShapValueDTO[]; }[];
  public ActiveModels: AlgModel[];
  public ActiveLines: ALGLineModel[];

  // Helpers/Getters
  public get Models() { return [...this.ActiveModels, ...this.ActiveLines.filter(x => x.IsHistoric)]; }
  public get IsPercent() { return this.data.config.isRocType || this.data.config.isPercent; }
  public get IsSummary() { return this.data.graphType === 'summary'; }
  private get YScale() { return this.data.svgObjs.YScale; }
  private get XScale() { return this.data.svgObjs.XScale; }
  private get ShowPast() { return this.data.config.ShowPastForecasts; }
  private get ShowFitted() { return this.data.config.ShowFittedData; }
  private get ActiveModelCount() { return this.data.graphData.ActiveModelCount; }
  private get NoTransform() { return this.data.config.noTransform; }
  private get ShouldShowCi() { return !this.ShowPast && this.NoTransform && this.data.config.ShowCIHover && this.ActiveModelCount === 1; }
  private get ShapField() { return this.IsSummary ? 'WShapValues' : 'ShapValues'; }
  private get FittedField() { return this.IsSummary ? 'WFittedValues' : 'FittedValues'; }
  private getDisplayValue = (v: number) => ValueUtils.getValueAsAbbreviatedString(v * (this.IsPercent ? 100 : 1), this.IsPercent);
  private getGrowthDisplay = (v: PlotValue, field: 'V' | 'F' | 'WF' = 'V') => this.data.config.isRocType && v.N ? 'Cannot calculate growth from 0' : this.getDisplayValue(v[field]);

  /**
   * Note that this class will be re-initialized whenever the data in the ALG changes.
   * To make the hover as fast as possible, make sure to setup easy access to the relevant data as soon as possible.
   */
  constructor(
    private store: Store,
    private dialogs: DialogService,
    public data: AlgInteractionsData
  ) {
    this.Hover = new GraphHover({
      targetGroup: data.svgObjs.GraphDataGroup,
      isPercentage: this.data.config.isRocType || this.data.config.isPercent,
      instance: data.instance,
      edgeDistance: data.svgObjs.Sizes.Hover.distanceToEdge,
      graphHeight: data.svgObjs.Sizes.getGraphHeight(),
      graphWidth: data.svgObjs.Sizes.getGraphWidth(),
      yScale: data.svgObjs.YScale
    });
    this.setupData();
  }

  private setupData() {
    this.ActiveModels = this.data.graphData.Forecasted.filter(f => f.Model.show);
    this.ActiveLines = this.data.graphData.Lines;
    this.MaxHoverCount = Math.min(this.ActiveModels.length + this.ActiveLines.length + 1, 5);
    this.NonHistoricLines = this.ActiveLines.filter(x => !x.IsHistoric);
    this.ShapData = this.ActiveModels.map(m => m.Model).filter(m => m[this.ShapField]?.length).map(m => ({ Model: m, ShapValues: m[this.ShapField] }));
    this.FittedData = this.data.graphData.Fitted.filter(m => m.Model[this.FittedField]?.length).map(m => ({ Model: m, Values: m.Segments }));
    this.PastForecasts = this.data.graphData.PastForecasts
      .map(p => p.Segments.map((segments, i) => ({
        Type: p.Type,
        Model: p.Model,
        Forecasts: segments,
        Color: p.Type === 0 ? this.data.svgObjs.Colors.getPastColor(i) : p.Color,
        Dates: [...new Set(segments.map(s => s.Values.map(v => v.D.getTime())).flatten())]
      })))
      .flatten();
  }

  /*
   * Public functions:
   * - hide: Hides all the hover elements
   * - show: Show all the hover elements
   * - move(x, y): Move all the relevant hover elements to show the content given the mouseX (x) and mouseY (y) positions
   * - click(x, y): Open dialog, displaying data about point X, Y.
   * - clickDate(m): Open dialog, displaying data about date 'm' (moment)
   */
  public hideHover() { this.Hover.hideHover(); }
  public showHover() { this.Hover.showHover(); }

  public hideHoverBox() { this.Hover.hideHoverBox(); }
  public showHoverBox() { this.Hover.showHoverBox(); }

  public moveHover({ X, Y }: ALGTypes.MousePos) {
    /* Get all points related to the current X,Y position */
    const allPoints = this.getPointsAtMouseXY(X, Y);
    if (!allPoints) { return; }
    /* Get the (max 5) closest points to the current mouse position */
    const hoverInfo = this.filterClosest(allPoints);
    /* Map the info to be drawn */
    const newHoverInfo = this.mapHoverInfos(hoverInfo);
    /* No points found; return */
    if (!hoverInfo.ModelPoints.length && !hoverInfo.LinePoints.length) { return; }
    /* Pre checks passed; lets move the hover elements */
    const newXLabel = this.data.datePipe.formatWithLimit(hoverInfo.Moment.toDate(), this.data.periodicity.Value);
    this.Hover.updateHover(newHoverInfo, hoverInfo.NewX, newXLabel);
  }

  private mapHoverInfos(x: ALGTypes.AlgDateInfo) {
    const cropFn = (str?: string) => StringUtils.cropIfNeeded(str || 'N/A', 20, 'middle');
    let infos: ALGTypes.AlgHoverTextLine[] = [];
    if (this.ShouldShowCi && x.IsInForecast) {
      const model = x.ModelPoints[0];
      infos.push({ Circle: false, Text: 'Confidence intervals (CI)', Margins: [0, 5] });
      infos.push({ Circle: false, Text: 'CI 95%', Value: model.Value.A95 });
      infos.push({ Circle: false, Text: 'CI 75%', Value: model.Value.A75 });
      infos.push({ Circle: false, Text: 'CI 50%', Value: model.Value.A50 });
      infos.push({ Circle: true, CircleColor: '#FFFFFF', Text: cropFn(model.Model.modelName?.Display), Value: model.Value.V, Margins: [5, 5], ValueStr: this.getGrowthDisplay(model.Value) });
      infos.push({ Circle: false, Text: 'CI 50%', Value: model.Value.I50 });
      infos.push({ Circle: false, Text: 'CI 75%', Value: model.Value.I75 });
      infos.push({ Circle: false, Text: 'CI 95%', Value: model.Value.I95 });
    } else if (x.IsInForecast) {
      infos.push({ Circle: false, Text: 'Model forecasts', Margins: [0, 5] });
      x.ModelPoints.forEach(model => {
        const color = this.data.graphData.ActiveModelCount !== 1 || !this.NoTransform ? model.Model.Color || 'white' : 'white';
        infos.push({ Circle: true, CircleColor: color, Text: cropFn(model.Model.modelName?.Display), Value: model.Value.V, ValueStr: this.getGrowthDisplay(model.Value) });
      });
    } else if (x.ModelPoints.length > 0) {
      infos.push({ Circle: false, Text: 'Historic data', Margins: [0, 5] });
      infos.push({ Circle: true, CircleColor: 'var(--indicio-alg-historic-line-color, white)', Text: 'Value', Value: x.ModelPoints[0].Value.V, ValueStr: this.getGrowthDisplay(x.ModelPoints[0].Value) });
    }
    if (x.PastPoints.length > 0) {
      const pastTitle = this.data.config.PastType === 0 ? 'Past forecasts' : this.data.config.PastType === 1 ? `Past forecasts one ${this.data.periodicity.Value} ahead` : `Past forecasts ${this.data.config.PastType} ${this.data.periodicity.Value}s ahead`;
      infos.push({ Circle: false, Text: pastTitle, Margins: [5, 5] });
      x.PastPoints.forEach(p => infos.push({ Circle: true, CircleColor: p.Color, Text: cropFn(p.Title), Value: p.Value.V, ValueStr: this.getGrowthDisplay(p.Value) }));
    }
    if (x.ShapPoints.length > 0) {
      infos.push({ Circle: false, Text: 'Shapley values', Margins: [5, 5] });
      x.ShapPoints.forEach(p => infos.push({ Circle: false, Text: cropFn(p.Shap.Name), Value: p.Value.V }));
    }
    if (x.LinePoints.length > 0) {
      const groups = x.LinePoints.groupBy(x => x.Model.Type);
      Array.from(groups.keys()).sort().forEach(k => {
        infos.push({ Circle: false, Text: k, Margins: [5, 5] });
        groups.get(k).forEach(l => {
          infos.push({ Circle: true, CircleColor: l.Model.Color || 'white', Text: cropFn(l.Model.Name), Value: l.Value.V, ValueStr: this.getGrowthDisplay(l.Value) });
        });
      });
    }
    if (x.FittedPoints.length > 0) {
      infos.push({ Circle: false, Text: 'Fitted values', Margins: [5, 5] });
      x.FittedPoints.forEach(p => {
        const valueDisplay = this.getGrowthDisplay(p.Value, this.IsSummary ? 'WF' : 'F');
        const color = p.Model.Color || 'white';
        infos.push({ Circle: true, CircleColor: color || 'white', Text: cropFn(p.Model.modelName?.Display || 'Weighted'), Value: this.IsSummary ? p.Value.WF : p.Value.F, ValueStr: valueDisplay });
      });
    }

    return infos;
  }

  /** Get the closest points to the current mouse position. */
  private getPointsAtMouseXY(mouseX: number, mouseY: number): ALGTypes.AlgDateInfo {
    /* Setup mouse position values (y-value, x-value both as date and new pixel position) */
    const date = DateUtils.newMoment(this.XScale.invert(mouseX));
    const modelDate = DateUtils.bisectDate(date, this.data.graphData.Dates);
    if (!modelDate)
      return null;

    const data = this.getDataForDate(modelDate);
    data.MouseValue = this.YScale.invert(mouseY);
    return data;
  }

  private getDataForDate(date: moment.Moment): ALGTypes.AlgDateInfo {
    const newXPos = this.XScale(date.toDate());
    const isInForecast = this.data.config.isPointForecasted(newXPos);
    /* Local temporary helper functions */
    const sameDay = (m: moment.Moment) => m.isSame(date, 'day');
    /* Determine if the current hover-date is the last forecasted point */
    const isLastDate = sameDay(this.data.graphData.Dates.last());
    // Get model points and 'stand alone' lines
    const modelPoints = this.Models.map(m => ({
      Model: m,
      Value: m.Segments.map(s => s.Values).flatten().find(x => sameDay(x.m)) as PlotValueDTO
    })).filter(m => !!m.Value);
    const linePoints = this.NonHistoricLines.filter(x => x.Active).map(m => ({ Model: m, Value: m.Segments.map(s => s.Values).flatten().find(x => sameDay(x.m)) })).filter(m => m.Value != null);
    // Get relevant (closest) fitted values, if asked for
    const fittedPoints = isInForecast ? [] : this.FittedData.map(m => ({ Model: m.Model, Value: m.Values.map(s => s.Values).flatten().find(x => sameDay(x.m)) })).filter(m => m.Value != null);
    // Get past forecasts, if asked for
    const pastForecastPoints = isLastDate ? [] : this.PastForecasts
      .map(p => this.mapActivePasts(p, date))
      .filter(m => !!m && !!m.Value);
    // Get Shap values
    const shapValues = !isInForecast
      ? []
      : this.ShapData.map(info => info.ShapValues.map(shap => ({ Model: info.Model, Shap: shap, Value: shap.Values.find(v => sameDay(v.m)) }))).flatten().filter(m => m.Value != null);
    return {
      NewX: newXPos,
      IsInForecast: isInForecast,
      AtLastDate: isLastDate,
      MouseValue: 0,
      Moment: date,
      ModelPoints: modelPoints,
      FittedPoints: fittedPoints,
      PastPoints: pastForecastPoints,
      LinePoints: linePoints,
      ShapPoints: shapValues
    };
  }

  private mapActivePasts(p: AlgInteractionPastInfo, date: moment.Moment) {
    const sameDay = (m: moment.Moment) => m.isSame(date, 'day');
    const sameTime = date.toDate().getTime();
    const s1 = { ...p, Values: p.Forecasts.map(s => s.Values).flatten() };
    const stepValue = s1.Values.find((v, i) => (p.Type || i > 0) && sameDay(v.m));
    if (!stepValue) return null;
    const step = s1.Dates.findIndex(p => p === sameTime);
    return {
      ...s1,
      Title: (s1.Type === 0 ? `${step} ${this.data.periodicity.Value}${step > 1 ? 's' : ''} ahead` : `${s1.Model.modelName?.Display}`),
      Value: stepValue
    };
  }

  private filterClosest(info: ALGTypes.AlgDateInfo): ALGTypes.AlgDateInfo {
    const sortFn1 = <T extends { Value: SimplePlotValue; }>(a: T, b: T) => Math.abs(info.MouseValue - a.Value.V) - Math.abs(info.MouseValue - b.Value.V);
    const sortFn2 = <T extends { Value: SimplePlotValue; }>(a: T, b: T) => b.Value.V - a.Value.V;
    return {
      ...info,
      ModelPoints: info.ModelPoints.sort(sortFn1).slice(0, this.MaxHoverCount).sort(sortFn2),
      LinePoints: info.LinePoints.sort(sortFn1).slice(0, this.MaxHoverCount - 1).sort(sortFn2),
      PastPoints: !this.ShowPast ? [] : info.PastPoints.sort(sortFn1).slice(0, 4).sort(sortFn2),
      ShapPoints: [],
      FittedPoints: !this.ShowFitted ? [] : info.FittedPoints.sort((a, b) => Math.abs(info.MouseValue - a.Value.F) - Math.abs(info.MouseValue - b.Value.F))
        .slice(0, this.MaxHoverCount - 1).sort((a, b) => a.Value.F - b.Value.F)
    };
  }

  public click({ X, Y }: ALGTypes.MousePos) {
    /* For now; we do not add click functions to variable algs or mini algs. */
    if (this.data.config.isMiniAlg || this.data.graphType === 'variable' || this.data.graphType === 'scenario' || !this.data.fVersionId) {
      return;
    }
    if (!this.NoTransform) {
      const nn = ALGTypes.getTransformName(this.data.config.algTransform, this.data.periodicity);
      this.dialogs.openInfoMessageDialog({
        Title: 'Shapley explanations',
        Content: 'Shapley explanations are not available with an active transformation.',
        Ingress: `Active transformation: ${nn}`
      });
      return;
    }
    if (!this.data.config.isOriginalData) {
      const nn = ALGTypes.getDataName(this.data.config.algDataType);
      this.dialogs.openInfoMessageDialog({
        Title: 'Shapley explanations',
        Content: 'Shapley explanations are not available with an active adjustment.',
        Ingress: `Active adjustment: ${nn}`
      });
      return;
    }
    /* Get the (max 5) closest points to the current mouse position */
    const info = this.getPointsAtMouseXY(X, Y);
    /* Disable click for historic data and some other stuff */
    if (!info || !info.IsInForecast) { return; }
    this.clickDate(info.Moment);
  }

  public clickDate(m: moment.Moment) {
    this.store.dispatch(new ALGActions.Click(this.getShapDialogData(m)));
  }

  private getShapDialogData(date: moment.Moment): ShapGraphDialogData {
    const data = this.getDataForDate(date);
    return {
      Periodicity: this.data.periodicity.Value,
      ProjectId: this.data.projectId,
      ForecastId: this.data.forecastId,
      ForecastVersionId: this.data.fVersionId,
      Moment: date,
      ModelNames: data.ModelPoints.filter(x => !!x.Model.modelName).map(x => x.Model.modelName),
      ModelPoints: data.ModelPoints,
      ShapPoints: data.ShapPoints,
      Dates: [...this.data.graphData.Dates.slice(-this.data.horizon)],
      getDataFn: this.getShapDialogData.bind(this),
      GraphType: this.data.graphType,
    };
  }
}
