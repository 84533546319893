import { CSVConstants } from '@core/constants/csv.constants';
import { IFile, IFileVersion } from '@core/interfaces/if-file';
import { ColumnDTO, VariableSheetDTO } from '@core/store/file/dtos/variable-sheet-dto';
import { FileVersionModel } from '@core/store/file/models/file-version.model';
import { RemoteFileVersionModel } from '@core/store/file/models/remote-file-version.model';
import { RemoteFileModel } from '@core/store/file/models/remote-file.model';
import { UploadedFileModel } from '@core/store/file/models/uploaded-file.model';
import { FileTypesType } from '@modules/lang/language-files/source-types';

export namespace FileUtils {

  export const acceptedExtensions: string[] = ['csv', 'xls', 'xlsx'];

  export function containsLessThanOneValues(content: string) {
    return !!content.split('\n').filter(x => x.match('<1')).length;
  }

  export function getSeparator(content: string) {
    return guessDelimiters(content, [';', '\t', ',']);
  }

  export function getSeparatorDefinition(separator: CSVConstants.Separator) {
    return CSVConstants.SeparatorDefinitions.find(x => x.value === separator);
  }

  function guessDelimiters(text: string, possibleDelimiters: CSVConstants.Separator[]) {
    let nonEmptyLines = text.split('\n').filter(x => x.length);
    if (nonEmptyLines.length === 1) {
      nonEmptyLines = nonEmptyLines[0].split('\r').filter(x => x.length);
    }
    // Remove all leading lines that does not include a valid separator
    const firstValidLine = nonEmptyLines.findIndex(x => x.split('').some(y => possibleDelimiters.includes(y as any)));
    nonEmptyLines = nonEmptyLines.slice(firstValidLine);
    let firstRow: number[];
    let validSeparators: boolean[];
    for (let i = 0; i < nonEmptyLines.length; i++) {
      const newEntry = Array(possibleDelimiters.length).fill(0);
      const row = nonEmptyLines[i];
      let inSingleQuote = false;
      let inDoubleQuote = false;
      for (let j = 0; j < row.length; j++) {
        if (row[j] === "'" && !inDoubleQuote) { inSingleQuote = !inSingleQuote; }
        if (row[j] === '"' && !inSingleQuote) { inDoubleQuote = !inDoubleQuote; }
        if (inSingleQuote || inDoubleQuote) { continue; }
        for (let k = 0; k < possibleDelimiters.length; k++) {
          if (possibleDelimiters[k] === row[j]) {
            newEntry[k]++;
          }
        }
      }
      if (i > 0) {
        for (let j = 0; j < validSeparators.length; j++) {
          validSeparators[j] = validSeparators[j] && newEntry[j] === firstRow[j];
        }
        if (validSeparators.filter(x => !!x).length === 0) {
          break;
        }
      } else {
        validSeparators = newEntry.map(x => x > 0);
        firstRow = newEntry;
      }
    }

    const matchingDelimiters = possibleDelimiters.map((d, idx) => ({
      Delimiter: getSeparatorDefinition(d),
      Match: validSeparators[idx] || validSeparators.every(x => !x),
    }));
    return matchingDelimiters.filter(x => x.Match);
  }

  export function getExtension(filename: string) {
    const split = filename.split('.');
    const fileExtension = split.last();
    const ext = this.acceptedExtensions.find(x => x === fileExtension);
    return !!ext ? ext : null;
  }

  export function acceptedFileType(filename: string) {
    const validFiletype = this.acceptedExtensions;
    return validFiletype.includes(filename.toLowerCase().split('.').pop());
  }

  export function downloadBlob(blob: Blob, name: string) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement('a');
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;
    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    // Remove link from body
    document.body.removeChild(link);
  }

  export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
  }

  export function cloneIFile(file: IFile, type: FileTypesType) {
    const copy = type === 'file' ? new UploadedFileModel() : new RemoteFileModel();
    return Object.assign(copy, <IFile> {
      ...file,
      CreatedDate: new Date(file.CreatedDate),
      ParsedData: file.ParsedData.map(p => cloneVariableSheet(p)),
      Versions: file.Versions.map(v => cloneIFileVersion(v, type))
    });
  }

  export function cloneIFileVersion(version: IFileVersion, type: FileTypesType) {
    const copy = type === 'file' ? new FileVersionModel() : new RemoteFileVersionModel();
    return Object.assign(copy, <IFileVersion> {
      ...version,
      CreatedDate: new Date(version.CreatedDate)
    });
  }

  export function cloneVariableSheet(sheet: VariableSheetDTO) {
    return <VariableSheetDTO> {
      ...sheet,
      ParsedColumns: sheet.ParsedColumns.map(c => cloneColumnDTO(c))
    };
  }

  export function cloneColumnDTO(col: ColumnDTO) {
    return <ColumnDTO> {
      ...col,
      Raw: [...col.Raw],
      DisabledRows: !!col.DisabledRows ? [...col.DisabledRows] : null
    };
  }
}
