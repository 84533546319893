import { Injectable } from '@angular/core';
import { PusherHierarchicalBehaviourService } from '@core/store/hierarchical-forecast/hierarchical-action.behaviour';
import { PusherAutoTaskBehaviourService } from './behaviours/auto-task-action.behaviour';
import { PusherClientBehaviourService } from './behaviours/client-action.behaviour';
import { PusherEventsBehaviourService } from './behaviours/events-action.behaviour';
import { PusherFileBehaviourService } from './behaviours/file-action.behaviour';
import { PusherForecastBehaviourService } from './behaviours/forecast-action.behaviour';
import { PusherRQueueBehaviourService } from './behaviours/r-queue-action.behaviour';
import { PusherSourceVariableBehaviourService } from './behaviours/source-variable-action.behaviour';
import { PusherTemplateBehaviourService } from './behaviours/template-action.behaviour';
import { PusherActionDTO } from './dtos/pusher-action-dto';


@Injectable({
  providedIn: 'root'
})
export class PusherActionBehaviourService {

  constructor(
    private sourceVariableBehaviour: PusherSourceVariableBehaviourService,
    private forecastBehaviour: PusherForecastBehaviourService,
    private rQueueBehaviour: PusherRQueueBehaviourService,
    private autoTaskBehaviour: PusherAutoTaskBehaviourService,
    private templateBehaviour: PusherTemplateBehaviourService,
    private hierarchyBehaviour: PusherHierarchicalBehaviourService,
    private clientBehaviour: PusherClientBehaviourService,
    private fileBehaviour: PusherFileBehaviourService,
    private eventsBehaviour: PusherEventsBehaviourService
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {
    switch (dto.Type) {
      case 'forecast':
        return this.forecastBehaviour.actOnPusherAction(dto);
      case 'source-variable':
        return this.sourceVariableBehaviour.actOnPusherAction(dto);
      case 'r-queue':
        return this.rQueueBehaviour.actOnPusherAction(dto);
      case 'auto-task':
        return this.autoTaskBehaviour.actOnPusherAction(dto);
      case 'forecast-template':
        return this.templateBehaviour.actOnPusherAction(dto);
      case 'hierarchy':
        return this.hierarchyBehaviour.actOnPusherAction(dto);
      case 'company':
        return this.clientBehaviour.actOnPusherAction(dto);
      case 'file':
        return this.fileBehaviour.actOnPusherAction(dto);
      case 'events':
        return this.eventsBehaviour.actOnPusherAction(dto);

      default:
        console.error(`Missing behaviour for type: (${dto.Type})`);
        return;
    }
  }
}
