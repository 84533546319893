import { DisplayValue } from '../types/display-value';

export class NotificationType extends DisplayValue<string> { };

export const NotificationTypes = [
  new NotificationType('none', 'None', ''),
  new NotificationType('new-project-invite', 'New project invite', 'Recieved new project invite', 'Project'),
  new NotificationType('removed-project-invite', 'Removed project invite', 'Project invite was removed', 'Project'),
  new NotificationType('used-project-invite', 'Used project invite', 'User accepted a project invite', 'Project'),
  new NotificationType('new-company-invite', 'New company invite', 'Recieved new company invite', 'Company'),
  new NotificationType('removed-company-invite', 'Removed company invite', 'Company invite was removed', 'Company'),
  new NotificationType('used-company-invite', 'Used company invite', 'User accepted a company invite', 'Company'),
  new NotificationType('new-company', 'New company', 'A new company was created', 'Company'),
  new NotificationType('updated-company', 'Updated company', 'A company was updated', 'Company'),
  new NotificationType('removed-company', 'Removed company', 'A company was removed', 'Company'),
  new NotificationType('removed-company-employee', 'Removed company employee', 'An administrator removed a company employee', 'Company'),
  new NotificationType('new-assessment', 'New assessment', 'A new assessment was created', 'Assessments'),
  new NotificationType('updated-assessment', 'Updated assessment', 'An assessment has been updated', 'Assessments'),
  new NotificationType('removed-assessment', 'Removed assessment', 'An assessment was removed', 'Assessments'),
  new NotificationType('new-source-variable', 'New source variable', '', 'Variables'),
  new NotificationType('updated-source-variable', 'Updated source variable', '', 'Variables'),
  new NotificationType('removed-source-variable', 'Removed source variable', '', 'Variables'),
  new NotificationType('new-project', 'New project', '', 'Project'),
  new NotificationType('updated-project', 'Updated project', '', 'Project'),
  new NotificationType('removed-project', 'Removed project', '', 'Project'),
  new NotificationType('removed-project-member', 'Removed project member', '', 'Project'),
  new NotificationType('new-forecast', 'New forecast', '', 'Forecast'),
  new NotificationType('updated-forecast', 'Updated forecast', '', 'Forecast'),
  new NotificationType('removed-forecast', 'Removed forecast', '', 'Forecast'),
  new NotificationType('new-forecast-scenario', 'New forecast scenario', '', 'Forecast'),
  new NotificationType('updated-forecast-scenario', 'Updated forecast scenario', '', 'Forecast'),
  new NotificationType('deleted-forecast-scenario', 'Deleted forecast scenario', '', 'Forecast'),
  new NotificationType('new-forecast-result', 'New forecast result', '', 'Forecast'),
  new NotificationType('new-forecast-variable', 'New forecast variable', '', 'Forecast'),
  new NotificationType('updated-forecast-variable', 'Updated forecast variable', '', 'Forecast'),
  new NotificationType('updated-forecast-variable-outlier-info', 'Updated forecast variable outlier info', '', 'Forecast'),
  new NotificationType('updated-forecast-variable-settings', 'Updated forecast variable settings', '', 'Forecast'),
  new NotificationType('removed-forecast-variable', 'Removed forecast variable', '', 'Forecast'),
  new NotificationType('refreshed-forecast-variable', 'Refreshed forecast variable', '', 'Forecast'),
  new NotificationType('new-historic-event', 'New historic event', '', 'HistoricEvents'),
  new NotificationType('updated-historic-event', 'Updated historic event', '', 'HistoricEvents'),
  new NotificationType('removed-historic-event', 'Removed historic event', '', 'HistoricEvents'),
  new NotificationType('new-historic-event-in-forecast', 'New historic event in forecast', '', 'HistoricEvents'),
  new NotificationType('removed-historic-event-from-forecast', 'Removed historic event from forecast', '', 'HistoricEvents'),
  new NotificationType('new-file', 'New file', '', 'Files'),
  new NotificationType('new-file-version', 'New file version', '', 'Files'),
  new NotificationType('updated-file', 'Updated file', '', 'Files'),
  new NotificationType('removed-file', 'Removed file', '', 'Files'),
  new NotificationType('new-user', 'New user', '', 'Users'),
  new NotificationType('member-left-project', 'Member left project', '', 'Project'),
  new NotificationType('updated-company-role', 'Updated company role', '', 'Company'),
  new NotificationType('new-nowcast', 'New nowcast', '', 'Forecast'),
  new NotificationType('removed-nowcast', 'Removed nowcast', '', 'Forecast'),
  new NotificationType('new-nowcast-result', 'New nowcast result', '', 'Forecast'),
  new NotificationType('new-forecast-version', 'New forecast version', '', 'Forecast'),
  new NotificationType('updated-forecast-version', 'Updated forecast version', '', 'Forecast'),
  new NotificationType('removed-forecast-version', 'Removed forecast version', '', 'Forecast'),
  new NotificationType('new-historic-event-correlation', 'New historic event correlation', '', 'Forecast'),
  new NotificationType('updated-historic-event-correlation', 'Updated historic event correlation', '', 'Forecast'),
  new NotificationType('removed-historic-event-correlation', 'Removed historic event correlation', '', 'Forecast'),
  new NotificationType('updated-historic-event-in-forecast', 'Updated historic event in forecast', '', 'Forecast'),
  new NotificationType('file-ownership-change', 'File ownership change', '', 'Files'),
  new NotificationType('new-news-post', 'New news post', '', 'Company'),
  new NotificationType('remove-news-post', 'Remove news post', '', 'Company'),
  new NotificationType('new-news-reply', 'New news reply', '', 'Company'),
  new NotificationType('copy-forecast', 'Copy forecast', '', 'Forecast')
];



