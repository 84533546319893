import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { ReconciliationResultDTO } from '../../entities/dtos/hierarchy-reconciliation.dto';


export interface InspectReconciliationResultDialogData {
  result: ReconciliationResultDTO;
}

@Component({
  selector: 'indicio-inspect-reconciliation-result-dialog',
  templateUrl: 'inspect-reconciliation-result.dialog.html',
  styleUrls: ['../hierarchy.dialog.less']
})
export class InspectReconciliationResultDialogComponent {

  // Dialog identifier
  public static Id: string = 'inspect-reconciliation-result';

  // Entities
  public dto: ReconciliationResultDTO = null;

  // Status
  public isLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<InspectReconciliationResultDialogComponent>,
    public env: EnvironmentService,
    private status: StatusService,
    private dialog: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: InspectReconciliationResultDialogData
  ) {
    this.isLoading = true;
    this.setup();
  }

  public close() {
    this.dialogRef.close(null);
  }

  private setup() {
    this.dto = this.data.result;
    this.isLoading = false;
  }
}
