
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { StatusModel } from '@core/services/status/status.model';
import { StatusService } from '@core/services/status/status.service';
import { BugDialogService } from '@dialogs/bugs/bug-dialogs.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';


@Component({
  selector: 'indicio-status',
  templateUrl: './status.component.html'
})
export class StatusComponent implements OnInit {

  public bugIcon: IconProp = faShareFromSquare;

  @Input() modal = false;
  @Input() dialog = false;

  show = false;
  status: StatusModel = new StatusModel();
  subscription: Subscription = null;

  constructor(
    public statusService: StatusService,
    private ref: ChangeDetectorRef,
    private bugDialogService: BugDialogService,
  ) {
  }
  ngOnInit(): void {
    this.subscribe();
  }

  public openBugReport() {
    this.bugDialogService.opeBugReport({ Message: this.status.message });
  }

  public errorHasLink(str: string) {
    if (str.includes('<a') && str.includes('</a>')) {
      return true;
    }
    return false;
  }

  public subscribe() {
    this.subscription = this.statusService.status$.subscribe(value => {
      this.status = value;
      if (value.message !== undefined && (value.showInModal === this.modal || !value.showInModal && this.modal === false)) {
        this.show = true;
      } else {
        this.show = false;
      }
      this.ref.detectChanges();
    });
  }

  public unsubscribe() {
    this.subscription.unsubscribe();
  }
}
