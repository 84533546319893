import { Component } from '@angular/core';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { OxfordDatabankDTO, OxfordIndicatorDTO, OxfordTreeLocationDTO } from '@core/store/providers/oxford/oxford-dtos';
import { OxfordVariableMetaModel } from '@core/store/providers/oxford/oxford-models';
import { OxfordService } from '@core/store/providers/oxford/oxford.service';
import { ProviderService } from '@core/store/providers/provider.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { Store } from '@ngxs/store';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { OxfordImportModalOpts } from './oxford-import-modal.options';

@Component({
  selector: 'indicio-oxford-import-modal',
  templateUrl: './oxford-import-modal.component.html'
})
export class OxfordImportModalComponent extends ModalModelComponent {

  modalTitle = 'Import variable from Oxford';

  remoteUri: string = 'https://services.oxfordeconomics.com/api/download?includemetadata=true&page=0&pagesize=5';

  indicator: OxfordIndicatorDTO;
  meta: OxfordVariableMetaModel;
  databank: OxfordDatabankDTO;
  forecastVersion: ForecastVersionModel;

  periodicity: string;
  region: OxfordTreeLocationDTO = null;

  nameConflict = false;

  remoteDataModel = new RemoteDataRequestModel;

  periodicityOptions = [
    {
      Display: 'Quarterly',
      Value: 'q'
    },
    {
      Display: 'Yearly',
      Value: 'a'
    },
  ];

  loading = false;

  aggregationMethodId: string = IndicioConstants.EAggregationMethods.Average;


  country: string;
  category: string;

  public get isPeriodicitySet() { return !!this.periodicity; }

  constructor(
    public store: Store,
    public service: OxfordService,
    private clientService: ClientFrontendService,
    private statusService: StatusService,
    private sourcevariableService: SourceVariableFrontendService,
    private forecastVariableService: ForecastVariableFrontendService,
    private providerService: ProviderService,
    private fvarMapper: ForecastVariableMapper,
    public env: EnvironmentService
  ) {
    super();
  }

  public async setOptions(options: OxfordImportModalOpts) {
    this.indicator = options.indicator;
    this.forecastVersion = options.forecastVersion;
    this.meta = options.meta;
    this.databank = options.databank;
    this.region = options.region;

    this.remoteDataModel.Name = `${this.region.Name} - ${options.indicator.Name.substring(0, 256)}`;
    this.remoteDataModel.Provider = DataProvider.oxford;
    this.remoteDataModel.MetaData = options.meta.faKeyValueArray();
    this.remoteDataModel.RemoteUri = this.remoteUri;
    this.remoteDataModel.RemoteReferenceId = options.indicator.Code;

    this.checkName();

    if (!this.meta.HasQuarterly) {
      this.periodicityOptions = this.periodicityOptions.slice(1, 2);
    }
    this.periodicity = this.periodicityOptions[0].Value;

    this.isLoading = false;
  }

  public checkName() {
    this.nameConflict = this.sourcevariableService.isNameConflict(this.remoteDataModel.Name);
  }

  public async addVariable(a) {
    this.loading = true;

    const newRemoteDataModel = Object.faMapTo(new RemoteDataRequestModel, this.remoteDataModel);

    newRemoteDataModel.MetaData = {
      ...this.remoteDataModel.MetaData,
      StartYear: [this.databank.StartYear],
      RegionCode: [this.region.Code],
      ProductTypeCode: [this.indicator.ProductTypeCode],
      DatabankCode: [this.databank.DatabankCode],
      Frequency: [this.periodicity === 'a' ? 'Annual' : 'Quarterly']
    };

    this.providerService.addVariableFromDatasource(this.clientService.activeCompany.CompanyId, newRemoteDataModel)
      .then(sourcevariable => {
        const validAgg = this.env.getValidAggregationMethods(sourcevariable, this.forecastVersion.Periodicity).some(x => {
          return x.Value === this.aggregationMethodId;
        });

        sourcevariable._tmpAggregationMethod = this.aggregationMethodId;

        this.close();

        if (!validAgg) {
          setTimeout(() => this.statusService.setMessage('Variable created successfully, but chosen aggregation method is invalid', 'Warning', true), 10);
          return this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [sourcevariable.SourceVariableId]));
        }

        this.statusService.setMessage('Variable added successfully', 'Success', !validAgg);

        this.forecastVariableService.createForecastVariable(this.fvarMapper.mapFromSourceVariableModel(sourcevariable, this.forecastVersion.ForecastVersionId))
          .catch(err => {
            this.statusService.setError(err);
          });
      })
      .catch(e => {
        this.statusService.setError(e, true);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
