import { Injectable } from '@angular/core';
import { AuthFrontendService } from '@core/store/auth/auth.frontend.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { PusherActionDTO } from '../dtos/pusher-action-dto';

export type PusherClientAction =
  'fetch-security-settings'
  ;

@Injectable({
  providedIn: 'root'
})
export class PusherClientBehaviourService {

  constructor(
    private authService: AuthFrontendService,
    private client: ClientFrontendService
  ) { }

  public actOnPusherAction(dto: PusherActionDTO) {

    switch (dto.Action as PusherClientAction) {
      case 'fetch-security-settings':
        // Ignore action if from other user
        if (dto.ActorId !== this.client.client.ClientId) { return; }
        return this.authService.fetchClientSecuritySettings();

      default:
        console.error(`Missing behaviour for action: (${dto.Action})`);
        return;
    }
  }

}
