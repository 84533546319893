import { Component } from '@angular/core';
import { NavigateToForecast } from '@core/actions/navigation.actions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { CompanyFrontendService } from '@core/store/company/company.frontend.service';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ForecastVariableNowcastOptionsModel, NowcastPeriodicity } from '@core/store/forecast-variable/models/forecast-variable-nowcast-options.model';
import { CreateNowcastDTO } from '@core/store/forecast/dtos/forecast/create-nowcast-dto';
import { PotentialForecastDTO } from '@core/store/forecast/dtos/forecast/potential-forecast.dto';
import { ForecastHelper } from '@core/store/forecast/forecast-helper';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { Store } from '@ngxs/store';
import { OpenConfirmModal } from '@shared/modals/confirm/confirm-modal.actions';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateUtils } from '@shared/utils/date.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { CreateNowcastModalOpts } from './create-nowcast-modal.options';


@Component({
  selector: 'indicio-create-nowcast-modal',
  templateUrl: './create-nowcast-modal.component.html'
})
export class CreateNowcastModalComponent extends ModalModelComponent {

  opts: CreateNowcastModalOpts;

  // Modal variables
  forecastVersion: ForecastVersionModel = null;
  forecast: ForecastModel = null;
  variable: ForecastVariableModel = null;
  nowcastOptions: ForecastVariableNowcastOptionsModel = null;
  potentialForecasts: PotentialForecastDTO[] = [];

  forecastPoints = 0;
  status = '';
  includeData = false;
  quick = false;
  inProgress = false;
  changeDateInProgress = false;
  isMainVariable: boolean = null;
  nowcastCanBeCreated = false;
  nowcastMainToFullPeriod = false;
  mainNowcast: NowcastPeriodicity;

  selectedPeriodicity: NowcastPeriodicity;
  startDate: Date;

  showDetails: boolean[];

  nowcastOpen: boolean;
  potentialsOpen: boolean;

  public get sourcePeriodicity() { return this.env.getPeriodicity(this.nowcastOptions.SourcePeriodicity); }

  constructor(
    protected store: Store,
    private statusService: StatusService,
    private forecastService: ForecastFrontendService,
    private fVarService: ForecastVariableFrontendService,
    private clientService: ClientFrontendService,
    private companyService: CompanyFrontendService,
    public env: EnvironmentService,
    public appearance: AppearanceService
  ) { super(); }

  public get maxHorizon() { return this.companyService.maxHorizon(this.getSelectedPeriodicity()); }
  public get hasTooManyForecastPoints() { return this.forecastPoints > this.maxHorizon; }

  public removeFvar() {
    this.pending = true;
    this.fVarService.deleteForecastVariable(this.opts.variable.ForecastVariableId, this.forecastVersion.ForecastVersionId)
      .then(() => {
        this.close();
        this.statusService.setMessage('Forecast variable removed', 'Success');
      })
      .catch(err => {
        this.statusService.setError(err);
      })
      .finally(() => this.pending = false)
      ;
  }

  public setOptions(options: CreateNowcastModalOpts) {
    this.opts = options;

    const fcPromise = this.forecastService.getOrFetchForecastVersion(options.forecastVersion.ForecastVersionId);
    const fvPromise = this.fVarService.getOrFetchById(options.forecastVersion.ForecastVersionId, options.variable.ForecastVariableId);

    Promise.all([fcPromise, fvPromise])
      .then(([fc, fv]) => {
        this.forecastVersion = fc;
        this.variable = fv;
        this.forecast = this.forecastService.forecastById(this.forecastVersion.ForecastId);
        return this.forecastService.getPotentialForecasts({
          ResultsTo: DateUtils.convertToBackendDate(this.forecastVersion.DataUntilDateRequirement),
          IsMain: this.opts.isMainVariable,
          SourceVariableId: options.variable.SourceVariableId,
          Periodicity: fc.Periodicity,
        }, this.clientService.activeCompanyId);
      })
      .then(pt => {
        this.potentialForecasts = pt;
        this.setup();
      })
      .catch(err => {
        this.statusService.setError(err, true);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public clickOnUse(fcast: PotentialForecastDTO) {
    if (fcast.selectedResultId && fcast.selectedResultId !== 'fake') {
      fcast.saveLoading = true;
      this.fVarService.changeSourceMetaIdForFVar(this.forecastVersion.ForecastVersionId, this.variable.ForecastVariableId, fcast.selectedResultId)
        .then(() => {
          this.close();
        }).finally(() => {
          fcast.saveLoading = false;
        });
    } else {
      this.close();
      this.store.dispatch(new NavigateToForecast(fcast.ForecastId, 'summary'));
    }
  }

  public navToForecast(fcast: PotentialForecastDTO) {
    this.close();
    this.store.dispatch(new NavigateToForecast(fcast.ForecastId));
  }

  public setup() {
    this.nowcastOptions = this.variable?.NowcastOptions;
    if (!this.nowcastOptions || !this.nowcastOptions.CanNowcast) {
      this.nowcastCanBeCreated = false;
    } else {
      this.nowcastCanBeCreated = true;
      this.nowcastOpen = true;
      this.isMainVariable = this.opts.isMainVariable;
    }
    if (this.potentialForecasts.length > 0) {
      this.potentialForecasts.forEach(p => {
        p.selectedResultId = p.Results.length > 0 ? p.Results[0].SourceVariableMetaId : 'fake';
      });
    }

    if (!this.nowcastCanBeCreated) {
      return;
    }

    this.showDetails = this.variable.NowcastOptions.Periodicities.map(_ => false);

    if (!this.variable.IsIndicator && ForecastHelper.canNowcastLastPeriod(this.forecastVersion, this.variable)) {
      this.nowcastMainToFullPeriod = true;
      this.mainNowcast = this.variable.NowcastOptions.Periodicities.find(x => x.Main);
      this.selectedPeriodicity = this.mainNowcast;
    } else {
      this.selectedPeriodicity = this.nowcastOptions.Periodicities.find(x => x.Periodicity === this.nowcastOptions.RecommendedPeriodicity);
    }
    this.pickNowcastPeriodicity(this.selectedPeriodicity.Periodicity);
  }

  public get isOneOptionSelected() {
    return (this.selectedPeriodicity !== null);
  }

  public pickNowcastPeriodicity(periodicity: string) {
    this.selectedPeriodicity = this.nowcastOptions.Periodicities.find(p => p.Periodicity === periodicity);
    this.forecastPoints = this.selectedPeriodicity.MissingDataPoints;
  }

  public getSelectedPeriodicity(): PeriodicityType {
    if (this.selectedPeriodicity !== null) {
      return this.selectedPeriodicity.Periodicity;
    }
    if (this.nowcastMainToFullPeriod) {
      return this.nowcastOptions.SourcePeriodicity;
    }
    return 'month';
  }

  public refreshVariableData() {
    this.inProgress = true;
    this.fVarService.updateForecastVariableValues(this.variable.ForecastVersionId, this.variable.ForecastVariableId)
      .then(_ => {
        this.statusService.setMessage('Data updated', 'Success');
        this.close();
      })
      .catch(e => this.statusService.setError(e))
      .finally(() => this.inProgress = false);
  }

  public createNowcast() {
    this.inProgress = true;
    this.status = 'Creating, please wait';

    const body = new CreateNowcastDTO();
    body.ForecastVariableId = this.variable.ForecastVariableId;
    body.ForecastVersionId = this.forecastVersion.ForecastVersionId;
    body.IncludeParentData = this.includeData;
    body.Quick = this.quick;
    body.Horizon = this.forecastPoints;
    body.Periodicity = this.selectedPeriodicity.Periodicity;

    this.forecastService.createNowcast(body)
      .then(created => {
        this.inProgress = false;
        this.status = 'Done';
        this.close();
        this.openNavQuestion(created.ForecastId);
      })
      .catch(error => {
        this.inProgress = false;
        this.statusService.setError(error, true);
        this.status = 'Failed';
      });
  }

  private openNavQuestion(forecastId: string) {
    this.store.dispatch(new OpenConfirmModal(
      () => this.store.dispatch(new NavigateToForecast(forecastId)),
      'Nowcast successfully created. Do you want to navigate to the new forecast?',
      'Nowcast created',
      'No',
      'Navigate',
      false,
      null
    ));
  }

  public toggleIncludeData() {
    this.includeData = !this.includeData;
  }

  public toggleQuick() {
    this.quick = !this.quick;
    if (this.quick) {
      this.includeData = false;
    }
  }

  public getDisplayName(periodicity: string) {
    return StringUtils.capitalizeFirstLetter(DateUtils.convertToLyFormat(periodicity as PeriodicityType));
  }
}
