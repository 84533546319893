import { DisplayValue } from '../types/display-value';


export class HistoricEventEffectType extends DisplayValue<string> { };
export const HistoricEventEffectTypes = [
  new HistoricEventEffectType('level-shift', 'Level shift', 'The effect of this event is constant over time'),
  new HistoricEventEffectType('lagged-spike', 'Lagged spike', 'The effect of this event is like a normal spike, but with an additional spike the following period'),
  new HistoricEventEffectType('spike', 'Spike', 'The effect of this event appears on a specific date, then diminishes'),
  new HistoricEventEffectType('exp-decay', 'Exponential decay', 'The effect of this event decreases exponentially over time')
];

export class HistoricEventLocation extends DisplayValue<string> { };
export const HistoricEventLocations = [
  new HistoricEventLocation('worldwide', 'Worldwide', 'An event with an impact on the whole world'),
  new HistoricEventLocation('north-america', 'North America', 'The countries and territories in northern america'),
  new HistoricEventLocation('south-america', 'South America', 'The countries and territories in southern america'),
  new HistoricEventLocation('central-america', 'Central America', 'The countries and territories in central america'),
  new HistoricEventLocation('europe', 'Europe', 'Countries in the European Union'),
  new HistoricEventLocation('north-africa', 'North Africa', 'Countries in the northern part of Africa'),
  new HistoricEventLocation('south-africa', 'South Africa', 'Countries in the southern parts of Africa, not only the country with the same name'),
  new HistoricEventLocation('middle-east', 'Middle east', 'An area heavily influenced by the amount of oil in the region'),
  new HistoricEventLocation('east-asia', 'East asia', 'The countries and territories in eastern asia'),
  new HistoricEventLocation('west-asia', 'West asia', 'The countries and territories in western asia'),
  new HistoricEventLocation('oceania', 'Oceania', 'The countries and territories located in oceania')
];

export class HistoricEventType extends DisplayValue<string> { };
export const HistoricEventTypes = [
  new HistoricEventType('political', 'Political', 'An event tied to a specific political decision'),
  new HistoricEventType('economical', 'Economic', 'An economic event, e.g. a stock market crash'),
  new HistoricEventType('weather', 'Weather', 'Weather-influenced event'),
  new HistoricEventType('disaster', 'Disaster', 'A natural disaster such as earthquake or flood'),
  new HistoricEventType('war', 'War', 'An event tied to a war'),
  new HistoricEventType('terrorism', 'Terrorism', 'Terror-related event'),
  new HistoricEventType('infrastructure', 'Infrastructure', 'Power-outage or other infrastrucutre event'),
  new HistoricEventType('other', 'Other', 'Event that does not fit in other categories')
];
