import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { StatusService } from '@core/services/status/status.service';
import { TagModel, TagModelTreeNode } from '@core/store/tags/models/tag.model';
import { TagsFrontendService } from '@core/store/tags/tags.frontend.service';
import { DialogService } from '@shared/modules/dialogs/dialog.service';


@Component({
  selector: 'indicio-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TagListComponent implements OnChanges {
  @ViewChild('parentInput') parentInput: ElementRef;
  @ViewChild('childInput') childInput: ElementRef;

  /**
   * Angular tree below
   */
  treeControl = new FlatTreeControl<TagModelTreeNode>(getNodeLevel, getIsNodeExpandable);
  dataSource: MatTreeFlatDataSource<TagModel, TagModelTreeNode>;
  expandedNodes = new Array<TagModelTreeNode>();
  treeFlattener: MatTreeFlattener<TagModel, TagModelTreeNode> = null;
  // End tree

  /**
   * Angular form
   */
  // End form

  @Input() public companyId: string;

  public isLoading = true;
  public searchStr: string = '';

  constructor(
    public service: TagsFrontendService,
    private status: StatusService,
    private dialogService: DialogService,
  ) {
    this.treeFlattener = new MatTreeFlattener<TagModel, TagModelTreeNode>(
      nodeTransformer,
      getNodeLevel,
      getIsNodeExpandable,
      this.getNodeChildren.bind(this)
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.service.selectedTag = null;
    this.resetTree();
  }

  public updateSearch() {
    this.setup();
    if (this.searchStr !== '') {
      this.treeControl.collapseAll();
      this.treeControl.expandAll();
    }
  }

  private resetTree() {
    this.dataSource.data = [];
  }

  ngOnChanges() {
    this.setup();
  }

  public addNewParent(isEdit: boolean = false) {
    const ref = this.dialogService.openModifyTagRelativesDialog({
      Title: (isEdit ? 'Edit' : 'Add') + ' tag parents',
      RelativesType: 'parents'
    });
    ref.subscribe((save: boolean) => {
      if (!save) { return; }
      this.updateSelectedTag();
    });
  }

  public addNewChild(isEdit: boolean = false) {
    const ref = this.dialogService.openModifyTagRelativesDialog({
      Title: (isEdit ? 'Edit' : 'Add') + ' tag children',
      RelativesType: 'children'
    }, {
      width: '600px'
    });
    ref.subscribe((save: boolean) => {
      if (!save) { return; }
      this.updateSelectedTag();
    });
  }

  private updateSelectedTag() {
    this.service.updateTag(this.service.selectedTag)
      .then(() => {
        this.setup();
        this.status.setMessage('Tag updated', 'Success', true);
      });
  }

  public renameTag() {
    let text;
    let textColor;
    if (this.service.selectedTag.isSystemTag()) {
      text = 'WARNING: You are about to rename a system tag, this might effect calendar effects during data processing.';
      textColor = 'red';
    }
    const ref = this.dialogService.openTextInputDialog({
      Title: 'Rename tag',
      Text: text,
      TextColor: textColor,
      Label: 'Input a new tag name',
      Value: this.service.selectedTag.Name,
      placeholder: 'New name'
    });

    ref.subscribe((newName: string) => {
      if (!newName) { return; }
      this.service.selectedTag.Name = newName;
      this.updateSelectedTag();
    });
  }

  public addNewTag() {
    const ref = this.dialogService.openCreateTagDialog();
    ref.subscribe((newTag: TagModel) => {
      if (!newTag) { return; }
      this.service.createNewTag(newTag)
        .then(() => {
          this.setup();
          this.status.setMessage('New tag created', 'Success', true);
        })
        .catch(err => this.status.setError(err));
    });
  }

  public openRemoveDialog() {
    let extraWarning;

    if (this.service.selectedTag.isSystemTag()) {
      extraWarning = 'WARNING: You are about to remove a system tag, this might effect calendar effects during data processing.';
    }

    const warn = this.service.selectedTag.ChildTagIds.length > 0;
    const ref = this.dialogService.openConfirmDialog({
      Title: warn ? 'Warning: Remove tag?' : 'Remove tag?',
      ExtraWarning: extraWarning,
      Message: warn ? 'This tag contains children. This action will not remove the children.' : 'Are you sure you want to remove this tag?',
      ConfirmText: 'Remove',
      Style: 'warn'
    });

    ref.subscribe((proceed: boolean) => {
      if (!proceed) { return; }
      this.removeSelectedTag();
    });
  }

  private removeSelectedTag() {
    if (!this.service.selectedTag) { return; }
    this.service.deleteTag(this.service.selectedTag)
      .then(() => {
        this.service.selectedTag = null;
        this.setup();
        this.status.setMessage('Tag removed', 'Success', true);
      })
      .catch(err => {
        this.status.setError(err, true);
      });
  }

  private setup() {
    const all = this.service.currentTags(this.searchStr);
    const roots = all.filter(x => x.ParentTagIds.length === 0);
    this.saveExpandedNodes();
    this.dataSource.data = roots;
    this.restoreExpandedNodes();
    this.isLoading = false;
  }

  public clickedNode(node: TagModelTreeNode) {
    if (node._real.ChildTags.length > 0) {
      this.treeControl.toggle(node);
    }
  }

  public selectNode(node: TagModelTreeNode) {
    this.treeControl.expand(node);

    if (this.service.selectedTag && this.service.selectedTag.TagId === node._real.TagId) {
      return this.service.selectedTag = null;
    }

    this.service.selectedTag = node._real;
  }

  public isActive(node: TagModelTreeNode) {
    return this.service.selectedTag && node._real.TagId === this.service.selectedTag.TagId;
  }

  // Check usage
  public hasChild = (_: number, node: TagModelTreeNode) => node.hasChildren;

  private saveExpandedNodes() {
    this.expandedNodes = new Array<TagModelTreeNode>();
    this.treeControl.dataNodes.forEach(node => {
      if (node.hasChildren && this.treeControl.isExpanded(node)) {
        this.expandedNodes.push(node);
      }
    });
  }

  private restoreExpandedNodes() {
    this.expandedNodes.forEach(node => {
      this.treeControl.expand(this.treeControl.dataNodes.find(n => n._real.TagId === node._real.TagId));
    });
  }

  // Function that returns a nested node's list of children
  private getNodeChildren(node: TagModel) {
    const all = this.service.currentTags(this.searchStr);
    const tags = all.filter(x => node.ChildTagIds.includes(x.TagId));
    return tags;
  }
}

// Function that maps a nested node to a flat node
function nodeTransformer(node: TagModel, level: number) {
  return {
    level,
    hasChildren: node.ChildTags?.length > 0,
    _real: node
  };
}

// Function that gets a flat node's level
function getNodeLevel(node: TagModelTreeNode) {
  return node.level;
}

// Function that determines whether a flat node is expandable or not
function getIsNodeExpandable(node: TagModelTreeNode) {
  return node.hasChildren;
}
