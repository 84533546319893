import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ContentTypeInterceptor implements HttpInterceptor {

  private scbReg = /api\.scb\.se/;
  private fredReg = /api\.stlouisfed\.org/;
  private tokenReg = /token$/;
  constructor(
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let clonedReq = req;

    if (!req.url.match(this.tokenReg) && !req.url.match(this.scbReg) && !req.url.match(this.fredReg)) {
      clonedReq = req.clone({
        setHeaders: {
          'Accept': 'application/json',
          'content-type': 'application/json',
        }
      });
    }

    return next.handle(clonedReq);
  }
}
