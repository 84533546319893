import { Def } from '@core/decorators/def';

export class KoladaKPIGroupMemberModel {
  @Def() member_id: string;
  @Def() member_title: string;
  @Def() is_divided_by_gender: boolean;
  @Def() description: string;
}

export class KoladaKPIGroupModel {
  @Def() id: string;
  @Def() members: KoladaKPIGroupMemberModel[];
  @Def() title: string;
}

export class KoladaKPIGroupsModel {
  @Def() count: number;
  @Def() values: KoladaKPIGroupModel[];
}
