import { Component, OnDestroy } from '@angular/core';
import { StatusService } from '@core/services/status/status.service';
import { SystemMessage } from '@core/services/system/system-message';
import { AuthActions } from '@core/store/auth/auth.actions';
import { AppearanceService } from '@core/store/profile/appearance.service';
import { TermsOfServiceDTO } from '@core/store/tos/tos.dtos';
import { ToSService } from '@core/store/tos/tos.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { ToSModalOpts } from './tos-modal.options';


@Component({
  selector: 'indicio-tos-modal',
  templateUrl: './tos-modal.component.html'
})
export class TosModalComponent extends ModalModelComponent implements OnDestroy {

  // Site specific
  atBottom: boolean;
  tos: TermsOfServiceDTO = null;
  saving = false;

  public get hasAcceptedTos$() { return this.tosService.hasAcceptedTos$; }

  constructor(
    public store: Store,
    private tosService: ToSService,
    private statusService: StatusService,
    public appearance: AppearanceService,
  ) { super(); }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setOptions(opts: ToSModalOpts) {
    this.isLoading = true;
    this.tosService.getOrFetchTosById(opts.tosId)
      .then(tos => {
        this.tos = tos;
        this.isLoading = false;
      })
      .catch(error => {
        this.statusService.setError(error, true);
        this.isLoading = false;
      });
  }

  public acceptTos() {
    this.saving = true;
    this.tosService.acceptTos(this.tos.TermsOfServiceAgreementId)
      .then(() => {
        this.statusService.setMessage('Successfully accepted terms', 'Success');
        this.store.dispatch(new SystemMessage.Actions.Clear('tos'));
        this.saving = false;
        this.close();
      })
      .then(() => {
        this.store.dispatch(new AuthActions.RunPostLogin(true));
      })
      .catch(error => {
        this.statusService.setError(error, true);
        this.saving = false;
      });
  }

  public onScroll(event) {
    const element = event.target;
    if (element.scrollTop > (element.scrollHeight - (element.offsetHeight + 25))) {
      this.atBottom = true;
    } else {
      this.atBottom = false;
    }
  }
}


