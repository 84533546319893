import { Def } from '@core/decorators/def';
import { GroupMetaEntry } from '@core/store/source-variable/dtos/create-group-variable-dto';
import { VisibilityLevelType } from '@modules/lang/language-files/visibility';

export class UpdateGroupVariableDTO {
  @Def() Name: string;
  @Def() Visibility: string;
  @Def() UpAggregationMethodId: string;
  @Def() DownAggregationMethodId: string;
  @Def() GroupMetaEntries: GroupMetaEntry[];
  @Def() InfixExpression: string;
}

export class UpdateSourceVariableDTO {
  @Def() Name: string;
  @Def() VisibilityLevelId: VisibilityLevelType;
}
