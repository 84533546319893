import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ForecastBackendService } from '../forecast/forecast.backend.service';
import { DisplayActions } from './display.actions';
import { FVersionSettings } from './forecast-version-settings';


@Injectable({
  providedIn: 'root'
})
export class DisplayFrontendService {

  settings: FVersionSettings.ForecastVersionClientSettingsDTO;

  constructor(
    private store: Store,
    private forecastService: ForecastBackendService
  ) { }

  public useSettings(settings: FVersionSettings.ForecastVersionClientSettingsDTO) {
    this.settings = settings;
  }

  public setSetting(setting: Partial<FVersionSettings.ForecastVersionClientSettingsDTO>, save = true) {
    this.settings = { ...this.settings, ...setting };
    this.handleSetActions(setting);
    if (save) { return this.saveSetting(); }
    return Promise.resolve(this.settings);
  }

  public toggleHidden(type: FVersionSettings.TOGGLEABLE_SETTINGS, name: string, save = true) {
    const current = this.settings[type].slice();
    current.togglePresence(name);
    this.setSetting({ [type]: current }, save);
  }

  public saveSetting() {
    return this.forecastService.saveForecastVersionSettings(this.settings);
  }

  private handleSetActions(settings: Partial<FVersionSettings.ForecastVersionClientSettingsDTO>) {
    if (!settings) { return; }
    if (settings.ALGTransform) { this.store.dispatch(new DisplayActions.SetALGTransform(this.settings.ForecastVersionId, settings.ALGTransform)); }
    if (settings.ALGDataType) { this.store.dispatch(new DisplayActions.SetALGDataType(this.settings.ForecastVersionId, settings.ALGDataType)); }
    if (settings.DecoupledTransform != null) { this.store.dispatch(new DisplayActions.SetALGDecoupledTransform(this.settings.ForecastVersionId, settings.DecoupledTransform)); }
    if (settings.AccuracyChartView) { this.store.dispatch(new DisplayActions.AccuracyChartView(this.settings.ForecastVersionId, settings.AccuracyChartView)); }
    if (settings.AccuracyMeasureVariableMultivariate) { this.store.dispatch(new DisplayActions.AccuracyMeasureVariableMultivariate(this.settings.ForecastVersionId, settings.AccuracyMeasureVariableMultivariate)); }
    if (settings.AccuracyMeasureVariableUnivariate) { this.store.dispatch(new DisplayActions.AccuracyMeasureVariableUnivariate(this.settings.ForecastVersionId, settings.AccuracyMeasureVariableUnivariate)); }
  }
}
