import { Component } from '@angular/core';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { DataProvider } from '@core/constants/provider-definitions';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableMapper } from '@core/store/forecast-variable/forecast-variable-mapper';
import { ForecastVariableFrontendService } from '@core/store/forecast-variable/forecast-variable.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { UpdateRemoteDataSourceDTO } from '@core/store/providers/dtos/remote-data-source.dto';
import { NewRemoteDataSourceModelModel } from '@core/store/providers/models/new-remote-data-source-model.model';
import { RemoteDataRequestModel } from '@core/store/providers/models/remote-data-request.model';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { ProviderFrontendService } from '@core/store/providers/provider.frontend.service';
import { ProviderService } from '@core/store/providers/provider.service';
import { RefinitivService } from '@core/store/providers/refinitiv/refinitiv.service';
import { SourceVariableFrontendService } from '@core/store/source-variable/source-variable.frontend.service';
import { Store } from '@ngxs/store';
import { OpenCreateForecastVariableMultiModal } from '@shared/modals/forecast-variable/create-forecast-variable-multi/create-forecast-variable-multi-modal.action';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { RefinitivModalOpts } from './refinitiv-modal.options';


@Component({
  selector: 'indicio-refinitiv-modal',
  templateUrl: './refinitiv-modal.component.html',
  styleUrls: ['./refinitiv-modal.component.less']
})
export class RefinitivModalComponent extends ModalModelComponent {

  forecastVersion: ForecastVersionModel;
  remoteDataModel = new RemoteDataRequestModel;
  source: RemoteDataSourceModel;

  // Frontend flags
  // Refinitiv credentials (need LoginPassword)
  needLP: boolean;
  edit: boolean;

  // Credentials
  username: string;
  password: string;

  advanced: boolean;

  variableName: string;
  remoteReferenceId: string;
  dataType: string;
  periodicity: string;

  aggregationMethodId: string = IndicioConstants.EAggregationMethods.Average;

  periodicities = [
    {
      value: 'day',
      display: 'Daily'
    },
    {
      value: 'week',
      display: 'Weekly'
    },
    {
      value: 'month',
      display: 'Monthly'
    },
    {
      value: 'quarter',
      display: 'Quarterly'
    },
    {
      value: 'year',
      display: 'Yearly'
    }
  ];

  nameConflict = false;
  nameMustChange: boolean;

  constructor(
    public store: Store,
    public envService: EnvironmentService,
    public providerService: ProviderFrontendService,
    private providerBackendService: ProviderService,
    private forecastService: ForecastFrontendService,
    private clientService: ClientFrontendService,
    private statusService: StatusService,
    private service: RefinitivService,
    private forecastVariableService: ForecastVariableFrontendService,
    private sourcevariableService: SourceVariableFrontendService,
    private fvarMapper: ForecastVariableMapper
  ) {
    super();
  }

  public setOptions(options: RefinitivModalOpts) {
    this.remoteDataModel.Provider = DataProvider.refinitiv;
    this.forecastService.getOrFetchForecastVersion(options.forecastVersionId)
      .then(fv => this.forecastVersion = fv)
      .catch(err => this.statusService.setError(err, true))
      .finally(() => this.isLoading = false);

    this.isLoading = false;

    if (options.edit === true) {
      this.needLP = true;
      this.edit = true;
    }

    this.initLogin();
  }

  initLogin() {
    this.source = this.clientService.client.findDataSource('refinitiv');
    if (!this.source || !this.source.User) {
      this.needLP = true;
      return;
    } else {
      this.setLoginDetails(this.source);
    }
  }

  private setLoginDetails(source: RemoteDataSourceModel) {
    this.remoteDataModel.User = source.User;
    setTimeout(() => {
      this.username = source.User;
    }, 0);
  }

  public checkNameErrors() {
    if (this.variableName.length < 2 || this.variableName.length > 127) {
      this.nameMustChange = true;
    } else {
      this.nameMustChange = false;
    }

    this.nameConflict = this.sourcevariableService.isNameConflict(this.variableName);
  }

  public async addVariable() {
    if (this.nameConflict || this.nameMustChange) { return; }
    this.pending = true;
    try {
      this.remoteDataModel.Name = this.variableName;
      this.remoteDataModel.Periodicity = this.periodicity;
      this.remoteDataModel.RemoteReferenceId = this.remoteReferenceId;

      if (this.advanced) {
        this.remoteDataModel.Query = {
          DataType: this.dataType
        };
      }

      this.providerBackendService.addVariableFromDatasource(this.clientService.activeCompany.CompanyId, this.remoteDataModel)
        .then(sourcevariable => {
          const validAgg = this.envService.getValidAggregationMethods(sourcevariable, this.forecastVersion.Periodicity).some(x => {
            return x.Value === this.aggregationMethodId;
          });

          sourcevariable._tmpAggregationMethod = this.aggregationMethodId;
          if (!validAgg) {
            setTimeout(() => this.statusService.setMessage('Variable created successfully, but chosen aggregation method is invalid', 'Warning', true), 10);
            return this.store.dispatch(new OpenCreateForecastVariableMultiModal(this.forecastVersion, [sourcevariable.SourceVariableId]));
          }

          this.statusService.setMessage('Variable added successfully', 'Success');
          this.close();
          if (sourcevariable.Periodicity !== this.periodicity) {
            this.statusService.setMessage(`Chosen periodicity was not found. ${sourcevariable.periodicity} is provided instead.`, 'Warning');
          }

          this.forecastVariableService.createForecastVariable(this.fvarMapper.mapFromSourceVariableModel(sourcevariable, this.forecastVersion.ForecastVersionId))
            .catch(err => {
              this.statusService.setError(err);
            });
        })
        .catch(err => this.statusService.setError(err, true))
        .finally(() => this.pending = false);
    } catch (error) {
      this.pending = false;
      this.statusService.setMessage('Error fetching observations', 'Error', true);
    }
  }

  saveProvider() {
    let call;
    this.pending = true;
    if (this.edit) {
      call = () => this.editProvider();
    } else {
      call = () => this.createProvider();
    }
    return this.service.testLoginDetails(this.username, this.password)
      .then(() => {
        return call();
      }).catch(() => {
        this.statusService.setMessage('Wrong username and password combination', 'Error', true);
      }).finally(() => {
        this.pending = false;
      });
  }

  createProvider() {
    const model: NewRemoteDataSourceModelModel = {
      Provider: this.envService.getSourceType(DataProvider.refinitiv).Value.toLowerCase(),
      User: this.username,
      Password: this.password
    };

    return this.providerService.createDataSource(model)
      .then(source => {
        this.statusService.setMessage('Login details saved.', 'Success', true);
        this.setLoginDetails(source);
        this.needLP = false;
      });
  }

  editProvider() {
    const model: UpdateRemoteDataSourceDTO = {
      RemoteDataSourceId: this.source.RemoteDataSourceId,
      Provider: this.envService.getSourceType(DataProvider.refinitiv).Value.toLowerCase(),
      User: this.username,
      Password: this.password
    };

    return this.providerService.editDataSource(model)
      .then(source => {
        this.statusService.setMessage('Login details saved.', 'Success', true);
        this.setLoginDetails(source);
        this.needLP = false;
      });
  }
}


