<svg [style]="style"
     class="indicio-icon-logo"
     id="Lager_1"
     style="enable-background:new -279 372.2 52.7 48.8;"
     version="1.1"
     viewBox="-279 372.2 52.7 48.8"
     x="0px"
     xml:space="preserve"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     y="0px">
  <g id="Lager_1_1_">
    <path d="M-262.9,418.8c-1.5,0-3.3-1-4.1-2.2l-10.1-17.6c-0.7-1.3-0.7-3.4,0-4.7l10.2-17.5c0.7-1.3,2.6-2.3,4.1-2.3
		h20.3c1.5,0,3.3,1.1,4.1,2.3l10.1,17.5c0.7,1.3,0.7,3.4,0,4.7l-10.3,17.5c-0.7,1.3-2.6,2.3-4.1,2.3L-262.9,418.8L-262.9,418.8z
		 M-237.7,394.1c-1.5,0-2.6,1.1-2.8,2.6h-8.9c-0.1-1.4-1.3-2.6-2.8-2.6s-2.6,1.1-2.8,2.6h-8.9c-0.1-1.4-1.3-2.6-2.8-2.6
		c-1.6,0-2.8,1.3-2.8,2.8c0,1.6,1.3,2.8,2.8,2.8s2.6-1.1,2.8-2.6h8.9c0.1,1.4,1.3,2.6,2.8,2.6s2.6-1.1,2.8-2.6h8.9
		c0.1,1.4,1.3,2.6,2.8,2.6c1.6,0,2.8-1.3,2.8-2.8C-234.9,395.3-236.2,394.1-237.7,394.1z"
          [style.stroke]="stroke"
          [style.fill]="fill" />
  </g>
</svg>
