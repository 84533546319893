import { Injectable } from '@angular/core';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { FredObservationFrequecy } from '@core/store/providers/fred/fred.backend.service';
import { Periodicity } from '@modules/lang/types/periodicity';
import { DateUtils } from '@shared/utils/date.utils';
import { FredCategoryListDTO } from './dtos/fred-category-list.dto';
import { FredDataSerieListDTO } from './dtos/fred-dataserie-list.dto';
import { FredObservationsDTO } from './dtos/fred-observations.dto';
import { FredTagListDTO } from './dtos/fred-tags.dto';
import { FredCategoryModel } from './fred-category.model';
import { FredDataSerieModel } from './fred-dataserie.model';
import { FredObservationModel } from './fred-observation.model';
import { FredObservationsModel } from './fred-observations.model';
import { FredTagModel } from './fred-tag.model';


@Injectable({
  providedIn: 'root'
})
export class FredMapper {
  public mapCategories(dto: FredCategoryListDTO) {
    const models = dto.categories.map(category => {
      return Object.faMapTo(new FredCategoryModel, category);
    });
    return models;
  }

  public mapSeries(dto: FredDataSerieListDTO) {
    const models = dto.seriess.map(serie => {
      const model = Object.faMapTo(new FredDataSerieModel, serie);
      model.title += !!model.seasonal_adjustment_short && model.seasonal_adjustment_short !== 'NSA' ? ', ' + model.seasonal_adjustment_short : '';
      model.oldData = DateUtils.isOldData(model.observation_end, this.mapFrequencyToPeriodicity(model.frequency_short));
      return model;
    });
    return models;
  }

  public mapObservations(dto: FredObservationsDTO, uri: string) {
    const model = Object.faMapTo(new FredObservationsModel, dto);
    model.uri = uri;

    model.observations = dto.observations.filter(o => o.value !== '.').map(observation => {
      return Object.faMapTo(new FredObservationModel, observation);
    });

    return model;
  }

  public mapTags(dto: FredTagListDTO): FredTagModel[] {
    const model = [];

    dto.tags.forEach(tag => {
      model.push(Object.faMapTo(new FredTagModel, tag));
    });

    return model;
  }

  public mapFrequencyToPeriodicity(frequency: FredObservationFrequecy) {
    switch (frequency.toLowerCase()) {
      case 'd':
        return 'day';
      case 'wesu':
      case 'w':
        return 'week';
      case 'm':
        return 'month';
      case 'q':
        return 'quarter';
      case 'a':
        return 'year';
    }
  }

  public mapPeriodicityToFrequencyShort(periodicity: Periodicity) {
    switch (periodicity.Value) {
      case 'day':
        return 'd';
      case 'week':
        return 'wesu';
      case 'month':
        return 'm';
      case 'quarter':
        return 'q';
      case 'year':
        return 'a';
    }
  }

  public mapAggregationMethod(aggregation: string) {
    switch (aggregation) {
      case 'avg':
        return IndicioConstants.EAggregationMethods.Average;
      case 'sum':
        return IndicioConstants.EAggregationMethods.Sum;
      case 'eop':
        return IndicioConstants.EAggregationMethods.LastValue;
    }
  }
}
