import { Def } from '@core/decorators/def';
import { HistoricValueDTO, SimplePlotValue } from '@core/entities/dtos/plot-value-dto';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { SourceTypesType } from '@modules/lang/language-files/source-types';
import { Periodicity } from '@modules/lang/types/periodicity';
import { PeriodicityInfoDTO } from './dtos/source-variable-version-dto';

export class SourceVariableVersionModel implements IHasModelId {
  // IDs
  @Def() SourceVariableMetaId: string;
  @Def() SourceVariableId: string;
  @Def() CompanyId: string;
  @Def() ForecastResultId: string;
  @Def() ParentForecastId: string;
  @Def() ParentForecastVersionId: string;
  @Def() UploadedFileVersionId: string;
  @Def() VisibilityLevelId = 'private';
  // Data
  @Def() PlotValues: SimplePlotValue[];
  // Props
  @Def() SourceType: SourceTypesType;
  @Def() UploadedFileId: string;
  @Def() RemoteVariableId: string;
  @Def() SourceVariableName: string;
  @Def() Name: string;
  @Def() Base: boolean;
  @Def() ValueCount: number;
  @Def() CreatedDate: Date;
  @Def() FirstDate: Date;
  @Def() LastDate: Date;
  @Def() Forecasted: boolean;
  @Def() Models: string[];
  @Def() WeightMethod: string;
  @Def() Periodicity: PeriodicityType;
  @Def() ManualPeriodicity: boolean;
  @Def() PeriodicityInfo: PeriodicityInfoDTO;
  @Def() DuplicateDates: boolean;
  @Def() Used: boolean;
  @Def() AutoGenerated: boolean;
  @Def() FromNowcast: boolean;
  // Create info
  @Def() Dates = [];
  @Def() Values: HistoricValueDTO[] = [];
  @Def() MaxValues = [];
  @Def() MinValues = [];
  @Def() New: boolean;
  @Def() SelectedColumns: any = null;
  @Def() StaticValues: boolean = false;
  @Def() IsPercent: boolean = false;
  @Def() AggregationMethodId: string = 'average';
  // File meta
  @Def() SheetIndex: number;
  @Def() DateIndex: number;
  @Def() ValueIndex: number;
  @Def() IdentifierIndex: number;
  @Def() Identifier: string;
  @Def() DisabledRows: number[];

  // Frontend types
  periodicity: Periodicity = null;

  // Frontend flags
  fetched = false;

  public getModelId() { return this.SourceVariableMetaId; }
}
