import { Injectable } from '@angular/core';
import { ForecastEndpoints } from '@core/store/forecast/forecast.endpoints';
import { HttpService } from '../http/http.service';
import { RemovalWarningDTO } from './entities/removal-warning.dto';
import { RemovalWarningsMapper } from './removal-warnings.mapper';



@Injectable({
  providedIn: 'root'
})
export class RemovalWarningsBackendService {

  constructor(
    private http: HttpService,
    private mapper: RemovalWarningsMapper
  ) { }

  public getRemovalWarningsForForecast(fcId: string) {
    return this.http.get<RemovalWarningDTO[]>(ForecastEndpoints.removalWarnings(fcId))
      .then(({ body }) => this.mapper.map(body));
  }

  public getRemovalWarningsForForecastVersion(fVersionId: string) {
    return this.http.get<RemovalWarningDTO[]>(ForecastEndpoints.removalWarningsVersion(fVersionId))
      .then(({ body }) => this.mapper.map(body));
  }

  public getRemovalWarningsForProject(projectId: string, companyId: string) {
    return this.http.get<RemovalWarningDTO[]>(`company/${companyId}/projects/${projectId}/removal-warnings`)
      .then(({ body }) => this.mapper.map(body));
  }
}
