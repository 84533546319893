import { Country } from '@modules/lang/types/country';

export class ProjectMemberModel {
  ClientId: string = null;
  Email: string = null;
  FirstName: string = null;
  LastName: string = null;
  Country: Country = null;
  ProfilePicture: string = null;
  CreatedDate: Date = null;
  PendingSignup: boolean = null;
  ProjectRole: string = null;
  Expires: Date = null;
  Disabled: boolean = false;
}
