<div class="company-edit-providers-tab"
     style="--grid-template-global: 1fr 0.2fr"
     *ngIf="!!company">
  <ng-container *ngTemplateOutlet="providersTemplate"></ng-container>
</div>

<ng-template #providersTemplate>
  <div class="providers-list">
    <div *ngFor="let provider of providers"
         class="grid-system">
      <div>{{ envService.getSourceType(provider.Name).Display }}</div>
      <div>
        <mat-slide-toggle data-test-id='auto-variable-update-toggle-btn'
                          [checked]="!company.DisabledProviders.includes(provider.Name)"
                          (change)="toggleDisabledProvider(provider.Name)">
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</ng-template>
