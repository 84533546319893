import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { d3Utils } from '@shared/utils/d3/d3.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { ValueUtils } from '@shared/utils/value.utils';
import * as d3 from 'd3';
import { NumberValue } from 'd3-scale';
import { ShapGraphTypes } from './shap-graph.types';

@Component({
  selector: 'indicio-shap-graph',
  styleUrls: ['./shap-graph.component.less'],
  templateUrl: 'shap-graph.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ShapGraphComponent implements OnChanges, AfterViewInit {

  public _id = `shap-graph-${Math.random().toString(36).substring(7)}`;
  public elements = new ShapGraphTypes.Objects();

  @Input() data: ShapGraphTypes.Input;

  /* Private flags */
  private initialized = false;
  /* Getters */
  private get values() { return this.data.data.map(x => x.Value); }
  private get sortedValues() { return this.data.data.sort((a, b) => Math.abs(b.Value) - Math.abs(a.Value)); }
  private get maxAbsValue() { return Math.max(...this.values.map(v => Math.abs(v))); }
  // SVG-getters
  private get svgTickPadding() { return 0.2; }
  private get svgHeight() { return Math.min(300 / this.data.data.length, 45) * this.data.data.length + 30; }
  private get svgWidth() { return d3Utils.getElementSizes(this.el).Width; }
  private get yDomain() { return this.sortedValues.map(x => x.Name); }
  private get xDomain() { return [-this.maxAbsValue * 1.05, this.maxAbsValue * 1.05]; }
  private get xRange() { return [20, this.svgWidth - 40]; }
  private get yRange(): [NumberValue, NumberValue] { return [0, this.svgHeight - 30]; }
  private get xScale() { return this.elements.xScale; }
  private get yScale() { return this.elements.yScale; }
  private get xAxisGroup() { return this.elements.xAxisGroup; }
  private get yAxisGroup() { return this.elements.yAxisGroup; }

  constructor(
    private el: ElementRef
  ) {
  }

  public ngAfterViewInit(): void {
    this.initialized = true;
    setTimeout(() => this.updateGraph(), 0);
  }

  public ngOnChanges() {
    if (!this.initialized) { return; }
    setTimeout(() => this.updateGraph(), 0);
  }

  private updateGraph() {
    /* Early return if we lack data or are not initialized */
    if (!this.data?.data?.length || !this.initialized) { return; }

    this.sortedValues.forEach((x, i) => x.Index = i);

    this.elements.SVG = this.createNewSvg();
    d3Utils.updateSizes(this.elements.SVG, { H: this.svgHeight, W: this.svgWidth });

    /* Create the main group and the X and Y scales */
    this.elements.MainGroup = d3Utils.addGroup(this.elements.SVG, { Translate: { X: 10, Y: 0 }, Class: 'main-content' });
    this.elements.yScale = d3.scaleBand().range(this.yRange).domain(this.yDomain).padding(this.svgTickPadding);
    this.elements.xScale = d3.scaleLinear().range(this.xRange).domain(this.xDomain);

    /* Create axes groups */
    this.elements.yAxisGroup = d3Utils.addGroup(this.elements.MainGroup, { Class: 'y-axis', Styles: { 'font-size': '13px' } });
    this.elements.xAxisGroup = d3Utils.addGroup(this.elements.MainGroup, { Class: 'x-axis', Styles: { 'font-size': '13px' }, Translate: { X: 0, Y: this.svgHeight - 30 } });

    /* Add one group for each entry in the data */
    const entries = this.elements.MainGroup
      .selectAll('g .shap-entries')
      .data(this.sortedValues)
      .enter().append('g').attr('class', 'shap-entries');

    /* Self reference for inner functions */
    const self = this;

    /* Create all the 'paths' (rectangles with arrow-heads) in the graph */
    entries.append('path')
      .attr('d', d => this.getPath(d))
      .attr('fill', d => (d.Value < 0 ? '#d7285fde' : '#3d49e2d9'));

    /* Create the text elements that show the value for each variable */
    entries.append('text')
      .text(d => ValueUtils.getValueAsAbbreviatedString(d.Value, this.data.isPercent))
      .attr('alignment-baseline', 'middle')
      .attr('font-size', 13)
      .attr('font-weight', 400)
      .attr('x', this.xScale(0))
      .attr('y', d => this.yScale(d.Name) + this.yScale.bandwidth() / 2)
      .attr('dx', function (d) { return self.getTextDx(this, d).DX; })
      .attr('dy', 1)
      .attr('fill', function (d) { return !self.getTextDx(this, d).Outside ? 'white' : `var(--indicio-modal-text-color, ${(d.Value < 0 ? '#8f0432' : '#040d8a')})`; });

    /* Create the title elements that show the variable name for each entry */
    entries.append('text')
      .text(d => StringUtils.cropIfNeeded(d.Name, 74, 'end'))
      .attr('alignment-baseline', 'middle')
      .attr('font-size', 13)
      .attr('font-weight', 400)
      .attr('x', this.xScale(0))
      .attr('y', d => this.yScale(d.Name) + this.yScale.bandwidth() / 2)
      .attr('dx', function (d) { return d.Value < 0 ? 10 : -(this.getBBox().width + 10); })
      .attr('dy', 1)
      .attr('fill', 'var(--indicio-modal-title-color, #444)');

    /* Add hover functionality */
    entries.append('rect')
      .attr('y', d => this.yScale(d.Name))
      .attr('x', 0)
      .attr('fill', 'transparent')
      .call(e => d3Utils.updateSizes(e, { H: this.yScale.bandwidth(), W: this.svgWidth - 20 }))
      .on('mouseover', (_, x) => this.hoverIdx(x.Index))
      .on('mouseout', () => this.hoverIdx(null));

    /* Add titles to each entry */
    entries.append('title')
      .text(d => d.Name);

    /* Add the x axis */
    this.xAxisGroup.call(d3.axisBottom(this.xScale).ticks(5));

    /* Add a vertical line at zero */
    this.addVerticalLine();
  }

  private hoverIdx(idx?: number) {
    const idx2 = idx === null ? null : idx * 2;
    this.yAxisGroup.selectAll('.tick text').classed('hovered', (_, i) => idx2 == null ? false : idx2 === i || idx2 + 1 === i);
    this.elements.MainGroup.selectAll('g .shap-entries text').classed('hovered', (_, i) => idx2 == null ? false : idx2 === i || idx2 + 1 === i);
  }

  private addVerticalLine() {
    const context = d3.path();
    context.moveTo(this.xScale(0), 0);
    context.lineTo(this.xScale(0), -(this.svgHeight - 30));
    this.xAxisGroup.append('path').attr('d', context.toString()).attr('stroke', 'black');
  }

  /**
   * Creates a new SVG elements and appends it to the target element in the DOM.
   * Removes any old SVG element.
   * @returns The new SVG.
   */
  private createNewSvg() {
    if (this.elements.SVG) { this.elements.SVG.remove(); }
    const svg = d3Utils.createSVG(this._id);
    d3Utils.appendSvg(this.el.nativeElement.children[0], svg);
    return svg;
  }

  /**
   * Get the coordinates used to draw the path for each rect-arrow
   * ```
   * x0         x1  x2
   * _____________  y0
   * |            \ y1
   * |____________/ y2
   * ```
   * @returns The x- and y coordinates: {x0, x1}
   * - x0: Upper left corner</li>
   * - x1: X-coordinate for arrow-start
   * - x2: X-coordinate for arrow-point
   * - y0: Y-coordinate for top edge of the bar
   * - y1: Y-coordinate for the middle of the bar
   * - y2: Y-coordinate for the bottom edge of the bar
   */
  private getCoordinates(d: ShapGraphTypes.ShapValue) {
    const neg = d.Value < 0;
    const x0 = this.xScale(0);
    const w = neg ? x0 - this.xScale(d.Value) : this.xScale(d.Value) - x0;
    const arrowWidth = Math.min(Math.max(w * 0.5, 1), this.yScale.bandwidth() / 3);
    const rectWidth = w - arrowWidth;
    const x1 = x0 + (neg ? -rectWidth : rectWidth);
    const x2 = x1 + (neg ? -arrowWidth : arrowWidth);
    const y0 = this.yScale(d.Name);
    const y1 = y0 + this.yScale.bandwidth() / 2;
    const y2 = y0 + this.yScale.bandwidth();
    return { x0, x1, x2, y0, y1, y2 };
  }

  private getTextDx(svgElem: SVGTextElement, d: ShapGraphTypes.ShapValue) {
    const { x0, x1, x2 } = this.getCoordinates(d);
    const box = svgElem.getBBox();
    const textOutside = d.Value < 0
      ? box.width + 6 > x0 - x1
      : box.width + 6 > x1 - x0;

    const dX = d.Value < 0
      ? textOutside ? x2 - x0 - box.width - 5 : x1 - x0 + 3
      : textOutside ? x2 - x0 + 5 : x1 - x0 - box.width - 3;

    return { Outside: textOutside, DX: dX };
  }

  private getPath(d: ShapGraphTypes.ShapValue): string {
    const { x0, x1, x2, y0, y1, y2 } = this.getCoordinates(d);
    const path = d3.path();
    path.moveTo(x0, y0);
    path.lineTo(x1, y0);
    path.lineTo(x2, y1);
    path.lineTo(x1, y2);
    path.lineTo(x0, y2);
    path.closePath();
    return path.toString();
  }
}
