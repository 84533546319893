import { Injectable } from '@angular/core';
import { ActionService } from '@core/services/actions/actions.service';
import { HttpService } from '@core/services/http/http.service';
import { CompanyActions } from '@core/store/company/company.actions';
import { Store } from '@ngxs/store';
import { Events } from './events';

@Injectable({ providedIn: 'root' })
export class ActivityLogService {

  public events: Events.Types.ActivityLogDTO[] = [];

  constructor(
    private actions: ActionService,
    private store: Store,
    private http: HttpService) {
  }

  public async getActivityLogs(companyId: string, skip: number = 0, take: number = 100) {
    const res = await this.http.get<Events.Types.ActivityLogDTO[]>(`company/${companyId}/automation/logs?skip=${skip}&take=${take}`);
    this.events = res.body;
    this.store.dispatch(Events.Actions.ReRender);
  }

  public setupSubs() {
    this.actions.dispatched(Events.Actions.NewActivityLog).subscribe((action: Events.Actions.NewActivityLog) => {
      this.add(action.evt);
    });
    this.actions.dispatched(CompanyActions.SetActiveCompanySuccess).subscribe((a: CompanyActions.SetActiveCompanySuccess) => {
      this.getActivityLogs(a.companyId);
    });
  }

  private add(evt: Events.Types.ActivityLogDTO) {
    this.events.unshift(evt);
    if (this.events.length > 200) {
      this.events.splice(-(this.events.length - 200));
    }
    this.store.dispatch(Events.Actions.ReRender);
  }
}
