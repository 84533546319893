import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

import { NavigateToHome } from '@core/actions/navigation.actions';
import { priorityOptions, PriorityOptionType } from '@core/constants/priority-order';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { UpdateNewsPostDTO } from '@core/store/news/dtos/update-news-post.dto';
import { NewsPostModel } from '@core/store/news/news-post.model';
import { NewsFrontendService } from '@core/store/news/news.frontend.service';
import { OpenConfirmModal } from '@shared/modals/confirm/confirm-modal.actions';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DateUtils } from '@shared/utils/date.utils';
import { EditNewsModalOpts } from './edit-news-modal.options';

@Component({
  selector: 'indicio-edit-news-modal',
  templateUrl: './edit-news-modal.component.html',
  styleUrls: ['./edit-news-modal.component.less']
})
export class EditNewsModalComponent extends ModalModelComponent {

  pending: boolean;
  pendingDeletion: boolean;
  newsPost: NewsPostModel;
  newsPostBackup: NewsPostModel;
  selectedPrio: PriorityOptionType;
  unsaved: boolean;
  publishDate = '';

  public get priorityOptions() { return priorityOptions; }

  constructor(
    private status: StatusService,
    private newsService: NewsFrontendService,
    public envService: EnvironmentService,
    private store: Store
  ) { super(); }

  setOptions(options: EditNewsModalOpts) {
    this.newsPost = Object.faMapTo(new NewsPostModel, JSON.parse(JSON.stringify(options.newsPost)));
    this.newsPostBackup = Object.faMapTo(new NewsPostModel, JSON.parse(JSON.stringify(options.newsPost)));
    this.selectedPrio = this.priorityOptions.find(x => x.Value === this.newsPost.Meta.Priority);
    if (this.newsPost.Meta.PublishDate) {
      this.publishDate = DateUtils.newMoment(this.newsPost.Meta.PublishDate).format('YYYY-MM-DD');
    }

    this.onClose = function () {
      this.savePost.call(this, arguments);
      if (options.onClose) {
        options.onClose.call(this, arguments);
      }
    };
    this.isLoading = false;
    this.loadPost();
  }

  savePost() {
    const post: UpdateNewsPostDTO = this.createNewsModel();
    if (Object.keys(JSON.parse(JSON.stringify(post))).length && JSON.stringify(this.newsPost) !== JSON.stringify(this.newsPostBackup)) {
      localStorage.setItem(`news.${this.newsPost.getModelId()}`, JSON.stringify(post));
    } else {
      localStorage.removeItem(`news.${this.newsPost.getModelId()}`);
    }
  }

  loadPost() {
    const post: UpdateNewsPostDTO = JSON.parse(localStorage.getItem(`news.${this.newsPost.getModelId()}`));
    if (post && Object.keys(post).length) {
      this.newsPost.Meta.NewsPostId = post.NewsPostId;
      this.newsPost.Meta.Body = post.Body;
      this.newsPost.Meta.Preview = post.Preview;
      this.newsPost.Meta.Title = post.Title;
      this.newsPost.Meta.HideAuthor = post.HideAuthor;
      this.newsPost.Meta.Priority = post.Priority;
      this.newsPost.Meta.DisableComments = post.DisableComments;
      this.newsPost.Meta.Hidden = post.Hidden;
      this.publishDate = post.PublishDate ? DateUtils.newMoment(this.newsPost.Meta.PublishDate).format('YYYY-MM-DD') : null;
      this.unsaved = true;
    }
  }

  updatePost() {
    this.pending = true;
    const post: UpdateNewsPostDTO = this.createNewsModel();
    this.newsService.updateNewsPost(post)
      .then(() => {
        this.pending = false;
        this.newsPostBackup = this.newsPost;
        this.close();
      })
      .catch(error => {
        this.pending = false;
        this.status.setError(error);
      });
  }

  private createNewsModel(): UpdateNewsPostDTO {
    return {
      NewsPostId: this.newsPost.Meta.NewsPostId,
      Body: this.newsPost.Meta.Body,
      Preview: this.newsPost.Meta.Preview,
      Title: this.newsPost.Meta.Title,
      HideAuthor: this.newsPost.Meta.HideAuthor,
      Priority: this.newsPost.Meta.Priority,
      DisableComments: this.newsPost.Meta.DisableComments,
      Hidden: this.newsPost.Meta.Hidden,
      PublishDate: this.publishDate
    };
  }


  public deletePost() {
    this.close();
    const proceed = () => {
      return this.newsService.removeNewsPost(this.newsPost)
        .then(() => {
          if (!this.onClose) {
            this.store.dispatch(new NavigateToHome());
          }
        });
    };
    const decline = 'No';
    const confirm = 'Yes, remove it';
    const negativeEffect = true;
    this.store.dispatch(new OpenConfirmModal(proceed, 'Are you sure', 'Delete news item', decline, confirm, negativeEffect));
  }
}
