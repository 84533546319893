import { CopyForecastModalOpts } from './copy/copy-forecast-modal.options';
import { MoveForecastModalOpts } from './move/move-forecast-modal.options';

export class OpenCopyForecastModal {
  public static readonly type = '[MODAL] Open copy forecast modal';
  data: CopyForecastModalOpts;
  constructor(
    forecastId: string
  ) { this.data = { forecastId }; }
}

export class OpenMoveForecastModal {
  public static readonly type = '[MODAL] Open move forecast modal';
  data: MoveForecastModalOpts;
  constructor(
    forecastId: string
  ) { this.data = { forecastId }; }
}
