import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TagModel } from '@core/store/tags/models/tag.model';
import { TagsFrontendService } from '@core/store/tags/tags.frontend.service';

export interface ModifyTagRelativesDialogData {
  Title: string;
  RelativesType: 'parents' | 'children';
}

@Component({
  selector: 'indicio-modify-tag-relatives-dialog',
  templateUrl: 'modify-tag-relatives.dialog.html',
})
export class ModifyTagRelativesDialogComponent {
  public static Id: string = 'ModifyTagRelativesDialogComponent';

  @ViewChild('relativeInput') relativeInput: ElementRef<HTMLInputElement>;

  public relativeControl = new UntypedFormControl();
  public filteredRelatives: Observable<TagModel[]>;

  public get isParent() { return this.data.RelativesType === 'parents'; }
  public get relatives() {
    if (this.isParent) {
      return this.service.selectedTag.ParentTags;
    } else {
      return this.service.selectedTag.ChildTags;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ModifyTagRelativesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModifyTagRelativesDialogData,
    public service: TagsFrontendService
  ) {
    this.filteredRelatives = this.relativeControl.valueChanges.pipe(
      startWith(''),
      map((tagName: string) => this.service.filterTags(tagName, this.data.RelativesType)));
  }

  public selectedRelative(event: MatAutocompleteSelectedEvent): void {
    const tag: TagModel = event.option.value;
    if (this.isParent) {
      this.service.selectedTag.ParentTags.push(tag);
      this.service.selectedTag.ParentTagIds.push(tag.TagId);
    } else {
      this.service.selectedTag.ChildTags.push(tag);
      this.service.selectedTag.ChildTagIds.push(tag.TagId);
    }
    this.relativeInput.nativeElement.value = '';
    this.relativeInput.nativeElement.blur();
    this.relativeControl.setValue('');
  }

  public removeParent(tag: TagModel): void {
    if (this.isParent) {
      this.service.selectedTag.ParentTags.removeById(tag.TagId);
      this.service.selectedTag.ParentTagIds.removeId(tag.TagId);
    } else {
      this.service.selectedTag.ChildTags.removeById(tag.TagId);
      this.service.selectedTag.ChildTagIds.removeId(tag.TagId);
    }
    this.relativeInput.nativeElement.value = '';
    this.relativeInput.nativeElement.blur();
    this.relativeControl.setValue('');
  }

  public onNoClick(): void {
    this.dialogRef.close(false);
  }

  public save() {
    this.dialogRef.close(true);
  }
}
