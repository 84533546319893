import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { NotificationMessageModel } from '@core/store/notifications/notification-message.model';

export class NotificationModel implements IHasModelId {
  @Def() NotificationId: string;
  @Def() Message: NotificationMessageModel;
  @Def() Occurred: Date;
  @Def() TimeSeen: Date = null;
  @Def() forceHide: boolean = false;

  displayDate: string;
  hidden = false;

  public getModelId() {
    return this.NotificationId;
  }
}
