import { Store } from '@ngxs/store';
import { DebugBoxActions } from './debug-box.actions';

export class DebugBoxHelper {

  private static store: Store;
  public static enabled: boolean = false;

  public static setStore(store: Store) {
    this.store = store;
  }

  public static send(name: string, ms: number) {
    if (!this.enabled) { return; }
    this.store.dispatch(new DebugBoxActions.Display({
      name: name,
      ms: Math.round(ms)
    }));
  }

  public static start() {
    return {
      Last: performance.now(),
      log: function (msg: string) {
        const now = performance.now();
        DebugBoxHelper.send(msg, now - this.Last);
        this.Last = now;
      }
    };
  }
}
