<indicio-dialog class="svar-info">
  <h2 mat-dialog-title>Source Variable information
    <div class="crop-string subtitle"
         [title]="variable.Name"
         *ngIf="initialized">
      Variable: {{ variable.Name }}
    </div>
  </h2>
  <mat-dialog-content>
    <ng-container *ngIf="initialized && !variable; else variableTemplate">
      <div class="row">
        <div class="col-xs-12 center-text">
          <button mat-stroked-button
                  type="button"
                  (click)="close()">
            Close
          </button>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button
            type="button"
            (click)="close()">Close</button>
    <div [indicioTippy]="importBtnTippyText">
      <button mat-raised-button
              data-test-id="import-source-variable-to-forecast-btn"
              [disabled]="!initialized || isVariableUsedInForecast || periodicityReviewRequired || createAndImportLoading"
              (click)="importToForecast()"
              color="primary">
        <span *ngIf="!createAndImportLoading">Import</span>
        <indicio-spinner *ngIf="createAndImportLoading"></indicio-spinner>
      </button>
    </div>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #variableTemplate>
  <ng-container *ngIf="initialized; else loadingTemplate">
    <div>
      <ng-container *ngTemplateOutlet="graphTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="infoTemplate"></ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #graphTemplate>
  <div *ngIf="canShowGrapgh">
    <indicio-alg class="modal-chart"
                 [periodicityType]="activeVersion.Periodicity"
                 [forecast]="forecast"
                 [forecastVersion]="forecastVersion"
                 [HistoricLine]="historicLine"
                 [options]="graphOptions"
                 [chartStyles]="chartStyles"
                 graphType="variable">
    </indicio-alg>
  </div>
</ng-template>

<ng-template #infoTemplate>
  <div class="grid-system"
       style="--grid-template: 550px 1fr; padding-top: 10px">
    <div class="info-grid"
         style="--grid-template-global: 145px 360px;">
      <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
      <!-- Datapoints -->
      <div class="grid-system">
        <div>Datapoints:</div>
        <div>
          {{ activeVersion.ValueCount }} points
          ({{ activeVersion.FirstDate | dateFormat: activeVersion.Periodicity }} -
          {{ activeVersion.LastDate | dateFormat: activeVersion.Periodicity }})
        </div>
      </div>
      <!-- Tags -->
      <div class="grid-system">
        <div>Tags:</div>
        <div class="crop-string dotted-underline">
          <span (click)="editTags()"
                class="pointer"
                indicioTippy="Edit tags">
            {{ tagsStr }}
          </span>
        </div>
      </div>
      <!-- Last synced -->
      <div class="grid-system"
           *ngIf="variable.LastUpdated">
        <div>Last synced at:</div>
        <div>
          {{ variable.LastUpdated | date: appearance.FullFormat }}
          <i class="ion-loop pointer h-grey"
             [ngClass]="(isSyncing() | async) ? 'spinner disabled' : ''"
             (click)="syncRemoteVariable()"></i>
        </div>
      </div>
      <!-- Visibility -->
      <div class="grid-system"
           style="margin-top: 5px;">
        <div>Visibility:</div>
        <div style="width: 210px;">
          <mat-form-field *ngIf="isOwner">
            <mat-select [(value)]="newVisibility"
                        (selectionChange)="saveVisibility()">
              <mat-option [value]="level.Value"
                          *ngFor="let level of envService.VisibilityLevels">{{ level.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="!isOwner">{{ envService.getVisibilityLevel(variable.VisibilityLevelId).Display }}
          </ng-container>
        </div>
      </div>
      <!-- Aggregation -->
      <div class="grid-system"
           style="margin-top: 5px;">
        <div>Aggregation method:</div>
        <div style="width: 210px;">
          <mat-form-field>
            <mat-select [(value)]="aggregationMethodId"
                        required>
              <mat-option [value]="option.Value"
                          *ngFor="let option of envService.AggregationMethods">{{ option.Display }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- Periodicity -->
      <div class="grid-system">
        <div>Periodicity:</div>
        <div [ngClass]="{ 'orange-dark pointer': periodicityReviewRequired }"
             (click)="periodicityReviewRequired ? openReviewPeriodicityDialog() : null">
          {{ activeVersion.periodicity.Display }}
          <span *ngIf="manualPeriodicity">(manual)</span>
          <i *ngIf="periodicityReviewRequired"
             class="ion-alert-circled"></i>
        </div>
      </div>

      <!-- Source type -->
      <ng-container>
        <div class="grid-system">
          <div>Source:</div>
          <div>
            <ng-container *ngTemplateOutlet="sourceTemplate; context: { variable: variable }">
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- Owner -->
      <div class="grid-system">
        <div>Owner</div>
        <div>{{ variable.OwnerEmail }} ({{ variable.CreatedDate | date: appearance.dateFormat }})</div>
      </div>

      <!-- User  -->
      <div class="grid-system cursor-pointer"
           *ngIf="isVariableUsedInForecast || isVariableUsedInAnotherForecast"
           (click)="openUsedInfoDialog()">
        <div>Used</div>
        <div>Variable is used in {{ isVariableUsedInForecast ? 'this' : 'another '}} forecast.
          <i class="ion-checkmark green"></i>
        </div>
      </div>
    </div>
    <!-- Right-aligned version dropdown, second div of big grid-system. -->
    <div>
      <div *ngIf="versions.length > 1"
           [class]="!!variable ? 'version-container' : null">
        <div style="width: 100%;">
          <mat-form-field>
            <mat-label>Version</mat-label>
            <mat-select [value]="versionId"
                        panelClass="fitted-select small-header"
                        (selectionChange)="changeSelectedVersion($event)">
              <mat-option [value]="option.SourceVariableMetaId"
                          [title]="option.Name"
                          *ngFor="let option of versions">
                <small>{{ option.LastDate | dateFormat: option.Periodicity }} | {{ option.periodicity.Display }}</small>
                {{ option.Name }}
              </mat-option>
              <mat-select-trigger>{{ activeVersion.Name }}</mat-select-trigger>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="margin-top: 10px"
             class="text-right"
             [ngClass]="{ red: isNonBaseVersion, disabled: !isNonBaseVersion, 'dotted-underline': isNonBaseVersion }">
          <span [indicioTippy]="!isNonBaseVersion ? 'You cannot remove the base version.' : null"
                class="pointer italic"
                (click)="deleteActiveSourceVariableMeta()">Remove version</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #nameTemplate>
  <div class="grid-system">
    <div class="center-vertical">Name:</div>
    <div class="center-vertical crop-string">
      <div *ngIf="editName"
           style="width: 365px;">
        <mat-form-field>
          <input type="text"
                 matInput
                 [(ngModel)]="newName"
                 class="material"
                 (keyup.enter)="saveName()">
          <i matSuffix
             *ngIf="editName && variable.Name === newName"
             class="ion-close-round red cursor-pointer edit-pen"
             (click)="editName = false">
          </i>
          <fa-icon matSuffix
                   *ngIf="editName && variable.Name !== newName"
                   class="blue cursor-pointer edit-pen"
                   [icon]="faSave"
                   (click)="saveName()">
          </fa-icon>
        </mat-form-field>
      </div>
      <div *ngIf="!editName"
           class="center-vertical">
        <div class="crop-string name-field"
             style="display: inline-block; max-width: 335px; padding: 1px 0;"
             [title]="newName">{{ newName }}
        </div>
        <span style="margin-left: 5px">
          <i class="ion-edit cursor-pointer edit-pen"
             (click)="editName = true"></i>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sourceTemplate
             let-variable="variable">
  <ng-container [ngSwitch]="variable.sourceType.Value">
    <ng-container *ngSwitchCase="'file'">
      <ng-container *ngIf="!!variable && activeVersion?.SourceType === 'file'; else noAccessTemplate">
        <ng-container *ngTemplateOutlet="fileTemplate"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'sharepoint'">
      <ng-container *ngIf="!!variable && activeVersion?.SourceType === 'sharepoint'; else noAccessTemplate">
        <ng-container *ngTemplateOutlet="fileTemplate"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'macrobondapi'">
      <span class="cursor-pointer"
            indicioTippy="Open macrobond information view"
            (click)="openMacrobondInfo()">
        {{ variable.sourceType.Display }} ({{ variable.RemoteReferenceId }})</span>
    </ng-container>
    <ng-container *ngSwitchCase="'group'">
      <span class="cursor-pointer"
            indicioTippy="Edit group variable"
            (click)="editGroupVariable()">
        {{ variable.sourceType.Display }} <fa-icon [icon]="faInfo"></fa-icon></span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ variable.sourceType.Display }}
      <span class="selectable-text"
            *ngIf="variable.RemoteReferenceId">
        ({{ variable.RemoteReferenceId }})
      </span>
    </ng-container>
  </ng-container>

  <ng-template #fileTemplate>
    <span class="cursor-pointer"
          indicioTippy="Open file information view"
          (click)="openFileModal()">
      {{ variable.sourceType.Display }} ({{ variable.FileName }})</span>
  </ng-template>

  <ng-template #noAccessTemplate>
    <ng-container *ngIf="!variable">
      <span indicioTippy="You do not have access to view this information">
        {{ variable.sourceType.Display }}
      </span>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #loadingTemplate>
  <div class="center-vertical">
    <indicio-spinner color="black"
                     style="display: inline-block;"
                     [size]="15"></indicio-spinner>&nbsp; Loading variable...
  </div>
</ng-template>
