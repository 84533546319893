<h1 mat-dialog-title>File column preview</h1>
<div mat-dialog-content>
  <div class="margin-bottom-10"
       *ngIf="forbidDiff">
    Changes cannot be shown, see file or variable revision to see changes after uploading.
  </div>
  <div class
       *ngIf="offset < 0">
    Not showing {{ -offset }} removed rows
  </div>

  <ng-container *ngIf="above20">
    Changes above 20% were detected.
  </ng-container>
  <ng-container *ngIf="above3">
    Changes above 3% were detected.
  </ng-container>
  <ng-container *ngIf="above3 || above20">
    <indicio-modal-option-row optionTitle="Include variable in update"
                              [optionSubtitle]="data.Location.SkipUpdate ? 'Variable will not be updated' : 'Variable will be updated'"
                              textWidth="10"
                              optionsWidth="1">
      <div class="toggle-icon"
           style="margin-top: 25px;">
        <mat-slide-toggle [checked]="!data.Location.SkipUpdate"
                          (change)="data.Location.SkipUpdate = !data.Location.SkipUpdate">
        </mat-slide-toggle>
      </div>
    </indicio-modal-option-row>
  </ng-container>

  <div class="data-table__body margin-top"
       #fileuploadscroll
       [style.maxHeight.vh]="51">
    <cdk-virtual-scroll-viewport style="max-height: 50vh; height: 100vh; overflow-x: hidden; max-width: 450px"
                                 maxBufferPx="700"
                                 minBufferPx="500"
                                 itemSize="27">
      <div class="body__row body__row--center-height body__row--max-height-30"
           style="max-width: 442px"
           [indicioTippy]="'Variable name: ' + variableName">
        <div class="column left-align crop-string">
          Variable: {{ variableName }}
        </div>
      </div>
      <div class="body__row body__row--center-height body__row--max-height-30"
           style="max-width: 442px">
        <div class="column left-align"
             style="width: 40px;">
          #
        </div>
        <div class="column left-align"
             style="width: 130px;">
          Date column
        </div>
        <div class="column left-align"
             style="width: 150px;">
          {{ forbidDiff ? 'Not shown' : 'Old Values' }}
        </div>
        <div class="column left-align"
             style="width: 150px;">
          New Values
        </div>
      </div>
      <div *cdkVirtualFor="let row of rows; let index = index"
           class="body__row body__row--center-height body__row--max-height-30"
           style=" max-width: 442px"
           [ngClass]="{ 'disabled': row[0] }">
        <div class="column left-align"
             style="width: 40px;">
          {{ index }}
        </div>
        <div class="column left-align"
             style="width: 130px;">
          {{ row[1] }}
        </div>
        <div class="column left-align"
             style="width: 150px;">
          {{ forbidDiff ? '' : !!row[3] || row[3]?.length === 0 ? row[3] : row[2] }}
        </div>
        <div class="column left-align"
             [class]="{ orange: !!row[3] && !forbidDiff }"
             style="width: 150px;">
          {{ row[2] }}
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button
          (click)="onNoClick()">
    Close
  </button>
</div>
