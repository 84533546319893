import { ForecastedIndicatorModalOpts } from './forecasted-indicator-modal.options';

export class OpenForecastedIndicatorModal {
  public static readonly type = '[MODAL] Open forecasted indicator modal';
  data: ForecastedIndicatorModalOpts;
  constructor(
    public fVarId: string,
    public sourceMetaId: string,
    public fVersionId: string,
    public sourceVariableId: string,
  ) { this.data = { fVarId, sourceMetaId, fVersionId, sourceVariableId }; }
}
