import * as d3 from 'd3';

export namespace MonthPlotOptions {

  export type SeriesType = 'line' | 'bars';

  export class SeriesModel {
    Buckets: Bucket[];
    Name: string;
    Color: string = 'blue';
    CircleColor: string = 'blue';
    Type: SeriesType;
    DrawBase: boolean = false;

    constructor(name: string, color: string, buckets: Bucket[], type: SeriesType, drawBase: boolean = false) {
      this.Name = name;
      this.Color = color;
      this.Buckets = buckets;
      this.Type = type;
      this.DrawBase = drawBase;
    }
  }

  export class Bucket {
    Values: ValueModel[] = [];
    BaseValue: number = 0;
  }

  export class ValueModel {
    Value: number;
    Circle: boolean = false;
    CircleLabel: string;
    Hover: boolean = true;

    constructor(value: number, hover: boolean = true, circle: boolean = false, circleLabel: string = '') {
      this.Value = value;
      this.Circle = circle;
      this.CircleLabel = circleLabel;
      this.Hover = hover;
    }
  }

  export class Data {
    Labels: LabelsModel = new LabelsModel();
    Series: SeriesModel[] = [];
  }

  export class LabelsModel {
    Buckets: string[];
    Ticks: string[][] = [];
  }

  export class AxesOptions {
    Min: number | Date;
    Max: number | Date;
    Dates: Date[] = [];
    TickCount: number = 4;
    Color: string = 'black';
    Id = Math.random().toString(36).substring(10);
  }

  export class Options {
    Data: Data = new MonthPlotOptions.Data();
    YAxis: AxesOptions = new MonthPlotOptions.AxesOptions;
    Height: number = 400;
    Width: number = 700;
    BorderWidth = 2;
    InnerPadding = 10;
    Margins: { top: number, right: number, bottom: number, left: number; } = { top: 10, right: 0, bottom: 30, left: 0 };
    PercentScale: boolean = false;
  }

  export class SVGElements {
    svg: d3.Selection<SVGGElement, {}, HTMLElement, any> = null;
    xScale: d3.ScaleLinear<number, number>;
    yScale: d3.ScaleLinear<number, number>;
    graphGroup: d3.Selection<SVGGElement, {}, HTMLElement, any>;
    innerGraph: d3.Selection<SVGGElement, {}, HTMLElement, any>;
    background: d3.Selection<SVGGElement, {}, HTMLElement, any>;
  }
}

