
export namespace Events {

  export namespace Types {

    export class ActivityLogEntryDTO {
      Key: string;
      Value: string;
    }

    export class ActivityLogDTO {
      public Display: string;
      public Time: string;
      public Actor: string;
      public Info: ActivityLogEntryDTO[];
    }
  }

  export namespace Actions {

    export class NewActivityLog {
      public static readonly type = '[EVENTS] New event';
      constructor(public evt: Types.ActivityLogDTO) { }
    }

    export class ReRender {
      public static readonly type = '[EVENTS] Re render view';
    }
  }
}
