import { Component } from '@angular/core';

import { ModalModelComponent } from '@shared/modals/modal.model';

@Component({
  selector: 'indicio-gdpr-modal',
  templateUrl: './gdpr-text-modal.component.html'
})
export class GDPRTextModalComponent extends ModalModelComponent {

  constructor(
  ) { super(); }

  public setOptions() { }
}


