import { DisplayValue } from '../types/display-value';
import { VariableTransformation } from '../types/variable-transformation';

export class GraphTransformation extends DisplayValue<string> { };
export const GraphTransformations = [
  new GraphTransformation('no_transform', 'No transformation', 'No transformation is used'),
  new GraphTransformation('roc', 'Growth', 'Percentage change from previous point'),
  new GraphTransformation('roc_yy', 'Growth - YoY', 'Percentage change, year over year'),
  new GraphTransformation('original', 'Original', 'Original data'),
  new GraphTransformation('rocy', 'Growth - year', 'Rate of change, year over year'),
  new GraphTransformation('diffy', 'Differentiated - year', 'Differentiated year over year'),
  new GraphTransformation('diff', 'Differentiated', 'Differentiated'),
  new GraphTransformation('rolling12m', 'Rolling average - 12 months', 'Rolling average over 12 months'),
  new GraphTransformation('rolling12mSum', 'Rolling sum - 12 months', 'Rolling sum over 12 months'),
  new GraphTransformation('rolling4q', 'Rolling average - 4 quarters', 'Rolling average over 4 quarters'),
  new GraphTransformation('rolling4qSum', 'Rolling sum - 4 quarters', 'Rolling sum over 4 quarters')
];

export const VariableTransformations = [
  new VariableTransformation('no_transform', 'No transformation', 'No transformation is used'),
  new VariableTransformation('log', 'Log transformation', 'Prior is logarithmically transformed'),
  new VariableTransformation('diff_normal', 'Diff', 'Prior is normally differentiated'),
  new VariableTransformation('diff_season', 'Diff YoY', 'Prior is seasonally differentiated'),
  new VariableTransformation('growth_normal', 'Growth', 'Prior is normally differentiated'),
  new VariableTransformation('growth_season', 'Growth YoY', 'Prior is normally differentiated'),
  new VariableTransformation('growth_annualized', 'Annualized growth', 'Prior is annualized growth'),
  new VariableTransformation('moving_average', 'Moving average', 'Prior is moving average'),
  new VariableTransformation('moving_sum', 'Moving sum', 'Prior is moving sum'),
  new VariableTransformation('geometric_average', 'Geometric average', 'Prior is geometric average'),
  new VariableTransformation('all', 'All transformations', 'Use all transformations')
]




