import { Injectable } from '@angular/core';
import { HttpService } from '@core/services/http/http.service';
import { IndicioHttpContext } from '@core/services/http/indicio-http-context';
import { SourceVariableDTO, SourceVariableMetaDTO } from '@core/store/source-variable/dtos/source-variable-dto';
import { SourceVariableUsageDTO } from '@core/store/source-variable/dtos/used-dto';
import { SourceVariableVersionModel } from '@core/store/source-variable/source-variable-version.model';
import { SourceVariableMapper } from '@core/store/source-variable/source-variable.mapper';
import { SourceVariableModel, SourceVariableViewDTO } from '@core/store/source-variable/source-variable.model';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { TagMetaDTO } from '../tags/dtos/tags.dtos';
import { TagsMapper } from '../tags/tags-mapper';
import { ActiveBenchmarksDTO } from './dtos/active-benchmarks.dto';
import { CreateSourceVariableFromFileDTO } from './dtos/create-source-variable-from-file-dto';
import { ForecastBenchmarkDTO } from './dtos/forecast-benchmark-dto';
import { PeriodicitySourceVarInfoDTO } from './dtos/source-variable-version-dto';
import { UpdateSourceVariableDTO } from './dtos/update-dtos';
import { ForecastBenchmarkModel } from './forecast-benchmark.model';


@Injectable({
  providedIn: 'root'
})
export class SourceVariableBackendService {

  constructor(
    private http: HttpService,
    private mapper: SourceVariableMapper,
    private tagMapper: TagsMapper
  ) { }

  public getPeriodicityInfo(companyId: string, sourceVariableId: string, periodicities: PeriodicityType[]) {
    return this.http.post<PeriodicitySourceVarInfoDTO>(`company/${companyId}/source-variables/${sourceVariableId}/periodicity-info`, periodicities).then(({ body }) => body);
  }

  public getListViewInCompany(companyId: string) {
    return this.http.get<SourceVariableViewDTO[]>(`company/${companyId}/source-variables/list-view`)
      .then(resp => {
        return resp.body.map(variable => this.mapper.mapViewDTO(variable));
      });
  }

  public setManualPeriodicity(companyId: string, variableId: string, periodicity: PeriodicityType) {
    return this.http.put<null>(`company/${companyId}/source-variables/${variableId}/set-periodicity/${periodicity}`, null)
      .then(_ => periodicity);
  }

  public createSourceVariable(model: SourceVariableVersionModel) {
    const dto = this.mapper.toCreate(model);
    return this.http.post<SourceVariableDTO>(`company/${model.CompanyId}/create-variable-from-file`, dto)
      .then(resp => {
        return this.mapper.map(resp.body);
      });
  }

  public async syncVariablesFromRemote(companyId: string, forecastIds: string[]) {
    return this.http.post<boolean>(`company/${companyId}/source-variables/synchronize`, forecastIds)
      .then(resp => resp.body);
  }

  public async syncVariableFromRemote(companyId: string, sourceVariableId: string) {
    return this.http.get<Date>(`company/${companyId}/source-variables/synchronize/${sourceVariableId}`)
      .then(resp => resp.body);
  }

  public createSourceVariableFromFile(dto: CreateSourceVariableFromFileDTO) {
    return this.http.post<SourceVariableDTO>(`company/${dto.CompanyId}/create-variable-from-file`, dto, 'backend', IndicioHttpContext.GetContext({
      Info: { dto }
    }))
      .then(resp => {
        return this.mapper.map(resp.body);
      });
  }

  public getSourceVariable(companyId: string, sourceVariableId: string, model?: SourceVariableModel) {
    return this.http.get<SourceVariableDTO>(`company/${companyId}/variables/${sourceVariableId}/get-source-variable`, 'backend', IndicioHttpContext.GetContext({
      Info: {
        SourceVariableId: sourceVariableId,
        CompanyId: companyId,
        OwnerEmail: model?.OwnerEmail
      }
    }))
      .then(resp => {
        return this.mapper.map(resp.body, model);
      });
  }

  public getSourceVariableBulk(companyId: string, newSvarIds: string[], updateSvars: SourceVariableModel[]) {
    newSvarIds = newSvarIds.concat(updateSvars.map(x => x.SourceVariableId));
    return this.http.post<SourceVariableDTO[]>(`company/${companyId}/get-bulk-source-variables`, newSvarIds)
      .then(resp => resp.body.map(sv => this.mapper.map(sv, updateSvars.find(x => x.SourceVariableId === sv.Meta?.SourceVariableId))));
  }

  public getAllUserVariables(companyId: string) {
    return this.http.get<SourceVariableMetaDTO[]>(`company/${companyId}/get-source-variables`)
      .then(resp => {
        return resp.body.map(variable => this.mapper.mapSourceMeta(variable));
      });
  }

  public editSourceVariable(companyId: string, sVarId: string, dto: UpdateSourceVariableDTO) {
    return this.http.put<null>(`company/${companyId}/variables/${sVarId}/edit-source-variable`, dto)
      .then(() => dto);
  }

  public deleteSourceVariable(model: SourceVariableModel) {
    return this.http.delete<null>(`company/${model.CompanyId}/variables/${model.SourceVariableId}/delete-source-variable`)
      .then(() => model);
  }

  public deleteSourceVariableVersion(model: SourceVariableVersionModel) {
    return this.http.delete<null>(`company/${model.CompanyId}/metas/${model.SourceVariableMetaId}/delete-source-variable-meta`)
      .then(() => model);
  }

  public getSourceVariableUsedStatus(companyId: string, variableIds: string[]) {
    return this.http.post<SourceVariableUsageDTO[]>(`company/${companyId}/variables/usage`, { SourceVariableIds: variableIds })
      .then(response => response.body);
  }

  /**
   * Benchmarks
   */

  public getActiveForecastBenchmarks(forecastVersionIds: string[]) {
    return this.http.post<ActiveBenchmarksDTO[]>('forecast-version/get-active-benchmarks', forecastVersionIds)
      .then(({ body }) => body);
  }

  public toggleForecastBenchmark(forecastVersionId: string, benchmarkId: string) {
    return this.http.post<null>(`forecast-version/${forecastVersionId}/toggle-benchmark/${benchmarkId}`, {})
      .then(() => { });
  }

  public getForecastBenchmarks(companyId: string, variableId: string) {
    return this.http.get<ForecastBenchmarkDTO[]>(`company/${companyId}/variables/${variableId}/get-source-benchmarks`)
      .then(({ body }) => body.map((bench, i) => this.mapper.mapBenchmark(bench, i)));
  }

  public createForecastBenchmark(companyId: string, model: ForecastBenchmarkModel, forecastVersionId: string) {
    const createDto = this.mapper.toCreateBenchDTO(model, forecastVersionId);
    return this.http.post<ForecastBenchmarkDTO>(`company/${companyId}/variables/create-benchmark`, createDto)
      .then(({ body }) => this.mapper.mapBenchmark(body));
  }

  public updateForecastBenchmark(companyId: string, model: ForecastBenchmarkModel) {
    const updateDto = this.mapper.toUpdateBenchDTO(model);
    return this.http.put<ForecastBenchmarkDTO>(`company/${companyId}/variables/update-benchmark`, updateDto)
      .then(({ body }) => this.mapper.mapBenchmark(body));
  }

  public deleteForecastBenchmark(companyId: string, benchId: string) {
    return this.http.delete(`company/${companyId}/variables/benchmark/${benchId}`);
  }

  /**
   * TAGS
   */
  public updateSourceVariableTags(companyId: string, sourceVariableId: string, updated: TagMetaDTO[]) {
    const toSend = updated.map(x => this.tagMapper.toMeta(x));
    return this.http.put(`company/${companyId}/variables/${sourceVariableId}/set-tags`, toSend);
  }
}









