import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ForecastModelFilterDTO, ForecastModelFilterSettingsDTO } from '@core/store/forecast/dtos/model-filters/forecast-model-filter-settings-dto';
import { ModelFiltersFrontendService } from '@core/store/forecast/model-filters.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ForecastModel } from '@core/store/forecast/models/forecast.model';
import { ProjectModel } from '@core/store/project/project.model';
import { MultivariateFrontendService } from '@core/store/stat-model/multivariate/multivariate.frontend.service';
import { UnivariateFrontendService } from '@core/store/stat-model/univariate/univariate.frontend.service';
import { VariableSelectFrontendService } from '@core/store/var-select/var-select.frontend.service';
import { UnivariateModelName } from '@modules/lang/language-files/stat-models';
import { DialogService } from '@shared/modules/dialogs/dialog.service';

@Component({
  selector: 'indicio-fcast-dialog-models-tab',
  templateUrl: './fcast-tab.models.component.html',
  styleUrls: ['./fcast-tab.models.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FCastSettingsDialogModelsTabComponent implements OnChanges {

  @Input() forecast: ForecastModel;
  @Input() forecastVersion: ForecastVersionModel;
  @Input() project: ProjectModel;
  @Input() canUpdateForecast: boolean;
  @Input() trigger: boolean[];

  @Output() closeDialogEvent = new EventEmitter();
  @Output() modifiedEvent = new EventEmitter<boolean>();

  public pending = false;

  public get changed() { return JSON.stringify(this.newSettings) !== JSON.stringify(this.oldSettings); }

  // Settings
  newSettings: ForecastModelFilterSettingsDTO = null;
  oldSettings: ForecastModelFilterSettingsDTO = null;

  constructor(
    private modelFilterService: ModelFiltersFrontendService,
    private uniService: UnivariateFrontendService,
    private multiService: MultivariateFrontendService,
    private vsService: VariableSelectFrontendService,
    private dialogService: DialogService,
    private status: StatusService,
    public envService: EnvironmentService
  ) {
  }

  public ngOnChanges() {
    // Set new settings
    this.modelFilterService.getForecastModelFilterSettings(this.forecast.ForecastId)
      .then(dto => {
        this.setDefaults(dto);
      });
  }

  public clickAll(type: string, group: 'Univariate' | 'Multivariate') {
    let ms = this.newSettings.Filters;

    switch (group) {
      case 'Univariate':
        ms = this.getUniModelsFromFilter(ms);
        break;
      case 'Multivariate':
        ms = this.getMultiModelsFromFilter(ms);
    }

    switch (type) {
      case 'models':
        const modelState = ms.some(x => !x.SkipModel);
        ms.forEach(m => this.toggleModelFilter(m.ModelName, 'full', modelState));
        break;
      case 'org':
        const orgState = ms.some(x => !x.SkipOrg);
        ms.forEach(m => this.toggleModelFilter(m.ModelName, 'org', orgState));
        break;
      case 'diff':
        const diffState = ms.some(x => !x.SkipDiff);
        ms.forEach(m => this.toggleModelFilter(m.ModelName, 'diff', diffState));
        break;
      case 'log':
        const logState = ms.some(x => !x.SkipLog);
        ms.forEach(m => this.toggleModelFilter(m.ModelName, 'log', logState));
        break;
    }
    this.modifiedEvent.emit(this.changed);
  }

  public getFilterState(model: string, type: string) {
    const m = this.getOrCreateFilterDTO(model);
    // Return if there's a new model in town that we don't know about
    if (!m) { return; }
    switch (type) {
      case 'full':
        return !m.SkipModel;
      case 'org':
        return !m.SkipOrg;
      case 'diff':
        return !m.SkipDiff;
      case 'log':
        return !m.SkipLog;
    }
  }

  public toggleModelFilter(model: string, type: string, state?: boolean) {
    if (!this.canUpdateForecast) { return; }
    const m = this.getOrCreateFilterDTO(model);
    switch (type) {
      case 'full':
        state = state !== undefined ? state : !m.SkipModel;
        m.SkipModel = state;
        m.SkipOrg = state;
        m.SkipDiff = state;
        m.SkipLog = state;
        break;
      case 'org':
        m.SkipOrg = state !== undefined ? state : !m.SkipOrg;
        this.checkModelActive(model);
        break;
      case 'diff':
        m.SkipDiff = state !== undefined ? state : !m.SkipDiff;
        this.checkModelActive(model);
        break;
      case 'log':
        m.SkipLog = state !== undefined ? state : !m.SkipLog;
        this.checkModelActive(model);
        break;
    }
    this.modifiedEvent.emit(this.changed);
  }

  private checkModelActive(model: string) {
    const m = this.getOrCreateFilterDTO(model);
    m.SkipModel = m.SkipDiff && m.SkipLog && m.SkipOrg;
  }

  private getOrCreateFilterDTO(model: string) {
    let m = this.newSettings.Filters.find(x => x.ModelName === model);
    if (!m) { this.newSettings.Filters.push({ ModelName: model, SkipModel: false, SkipOrg: false, SkipDiff: false, SkipLog: false, ForecastModelFilterId: null }); }
    return m;
  }

  private getUniModelsFromFilter(filters: ForecastModelFilterDTO[]) {
    return filters.filter(f => !!this.envService.UnivariateModelNames.find(u => u.Value === f.ModelName));
  }

  private getMultiModelsFromFilter(filters: ForecastModelFilterDTO[]) {
    return filters.filter(f => !!this.envService.MultivariateModelNames.find(u => u.Value === f.ModelName));
  }

  private setDefaults(dto: ForecastModelFilterSettingsDTO) {
    this.newSettings = { ...dto, Filters: dto.Filters.map(f => ({ ...f })) };
    this.oldSettings = dto;
    this.modifiedEvent.emit(this.changed);
  }

  public async save() {
    if (!this.changed) { return Promise.resolve(); }
    this.pending = true;
    return this.modelFilterService.saveForecastModelFilterSettings(this.forecast.ForecastId, this.newSettings)
      .then(saved => {
        this.status.setMessage('Model filter settings saved', 'Success', true);
        this.actOnFilterSaved(saved);
        this.setDefaults(saved);
      })
      .catch(err => { this.status.setError(err, true); })
      .finally(() => this.pending = false);
  }

  private actOnFilterSaved(updatedFilters: ForecastModelFilterSettingsDTO) {
    const wasSkipped = this.oldSettings.Filters.filter(x => x.SkipModel);
    const wasIncluded = this.oldSettings.Filters.filter(x => !x.SkipModel);
    let uni: Promise<boolean> = null;
    let multi: Promise<boolean> = null;

    const vsResultsExist = this.vsService.fVersionHasValidVarSelect(this.forecastVersion.ForecastVersionId);

    // Trigger new Uni
    const needTriggerUni = wasSkipped
      .filter(n => this.envService.UnivariateModels.findIndex(x => x.Model.Value === n.ModelName) !== -1)
      .filter(x => !updatedFilters.Filters.find(y => y.ModelName === x.ModelName).SkipModel)
      .map(y => y.ModelName as UnivariateModelName);
    if (needTriggerUni.length) {
      uni = this.uniService.triggerUnivariateModels(this.forecastVersion.ForecastVersionId, needTriggerUni)
        .then(() => true)
        .catch(() => false);
    }

    // Remove old Uni
    const needRemoveUni = wasIncluded
      .filter(n => this.envService.UnivariateModels.findIndex(x => x.Model.Value === n.ModelName) !== -1)
      .filter(x => updatedFilters.Filters.find(y => y.ModelName === x.ModelName).SkipModel)
      .map(y => y.ModelName as UnivariateModelName);
    if (needRemoveUni.length) {
      this.uniService.removeUnivariateModels(this.forecastVersion.ForecastVersionId, needRemoveUni);
    }

    const modelsToInclude = this.envService.MultivariateModelNames.map(e => e.Model);
    // Trigger new Multi
    const needsTrigger = wasSkipped
      .filter(n => this.envService.MultivariateModels.findIndex(x => x.Model.Value === n.ModelName) !== -1)
      .filter(x => !updatedFilters.Filters.find(y => y.ModelName === x.ModelName).SkipModel);
    const multiModelsToTrigger = needsTrigger.filter(x => modelsToInclude.includes(x.ModelName))
      .map(y => this.envService.MultivariateModels.find(mn => mn.Model.Value === y.ModelName).Model);

    if (multiModelsToTrigger.length && vsResultsExist && this.forecastVersion.anyActiveIndicators) {
      multi = this.multiService.triggerMultivariateModels(this.forecastVersion.ForecastVersionId, multiModelsToTrigger)
        .then(() => true)
        .catch(() => false);
    }

    const needRemoveMulti = wasIncluded
      .filter(n => this.envService.MultivariateModels.findIndex(x => x.Model.Value === n.ModelName) !== -1)
      .filter(x => updatedFilters.Filters.find(y => y.ModelName === x.ModelName).SkipModel)
      .map(y => this.envService.MultivariateModels.find(mn => mn.Model.Value === y.ModelName).Model);
    if (needRemoveMulti.length) {
      this.multiService.removeMultivariateModels(this.forecastVersion.ForecastVersionId, needRemoveMulti);
    }

    const multiWantedButDenied = multiModelsToTrigger.length && !vsResultsExist;

    if (multiWantedButDenied) {
      this.dialogService.openConfirmDialog({
        Title: 'Calculate new results?',
        Message: 'You do not currently have any indicator analysis results. Do you want to calculate indicator analysis and build multivariate models?',
        CancelText: 'No',
        ConfirmText: 'Yes, calculate',
        ConfirmFunction: () => {
          this.vsService.triggerVarSelect(this.forecastVersion.ForecastVersionId, true)
            .then(() => {
              this.status.setMessage('Running new calculations', 'Success', true);
            })
            .catch(err => {
              this.status.setError(err, true);
            });
        }
      });
    }

    const anyToTrigger = needTriggerUni.length > 0 || multiModelsToTrigger.length > 0;
    if (!multiWantedButDenied && anyToTrigger) {
      Promise.all([uni || Promise.resolve(true), multi || Promise.resolve(true)])
        .then(([u, m]) => {
          const uniFailed = !u && needTriggerUni.length > 0;
          const multiFailed = !m && multiModelsToTrigger.length > 0;
          if (uniFailed || multiFailed) {
            const message = 'Some models could not be calculated';
            this.status.setMessage(message, 'Warning', true);
          } else if (uniFailed && multiFailed) {
            this.status.setError('We couldn\'t build any models', true);
          } else {
            const message = 'Model calculation(s) successfully started';
            this.status.setMessage(message, 'Success', true);
          }
        });
    }
  }
}
