import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { INeedFetch } from '@core/interfaces/if-need-fetch';
import { NewsCommentModel } from './news-comment.model';
import { NewsPostMetaModel } from './news-post-meta.model';

export class NewsPostModel implements IHasModelId, INeedFetch {

  @Def() Meta: NewsPostMetaModel;
  @Def() Comments: NewsCommentModel[];

  shouldDisplay: boolean;

  public get CreatedDate() {
    return this.Meta.CreatedDate;
  }

  public get Priority() {
    return this.Meta.Priority;
  }

  public getModelId() {
    return this.Meta.NewsPostId;
  }

  public needFetch() {
    return !Array.isArray(this.Comments);
  }
}
