import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';

type urlType = 'id' | 'backend' | 'utility' | 'backend-only-ping' | 'import' | 'wiki';

@Injectable({ providedIn: 'root' })
export class HttpService {

  public get baseUrl() { return this.envService.env.UriBackendApi + 'api/v1/'; };
  public get idUrl() { return this.envService.env.UriIdentityApi; }
  public get utilityUrl() { return this.envService.env.UriFrontendUtilsApi; }
  public get tokenUrl() { return this.envService.env.UriIdentityApi + 'token'; }
  public get backendSecretUrl() { return this.envService.env.UriBackendApi; }
  public get importUrl() { return this.envService.env.UriImportApi + 'api/v1/'; }
  public get wikiUrl() { return this.envService.env.UriWikiApi + 'api/v1/'; }

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService
  ) {
  }

  public get<T>(path: string, base: urlType = 'backend', context?: HttpContext) {
    const b = this.getUrl(base, path);
    return this.http.get<T>(b, { observe: 'response', context }).toPromise();
  }

  public put<T>(path: string, body: any, base: urlType = 'backend', context?: HttpContext) {
    const b = this.getUrl(base, path);
    return this.http.put<T>(b, body, { observe: 'response', context }).toPromise();
  }

  public post<T>(path: string, body: any, base: urlType = 'backend', context?: HttpContext) {
    const b = this.getUrl(base, path);
    return this.http.post<T>(b, body, { observe: 'response', context }).toPromise();
  }

  public delete<T>(path: string, base: urlType = 'backend', context?: HttpContext) {
    const b = this.getUrl(base, path);
    return this.http.delete<T>(b, { observe: 'response', context }).toPromise();
  }

  public patch<T>(path: string, body: any, base: urlType = 'backend', context?: HttpContext) {
    const b = this.getUrl(base, path);
    return this.http.patch<T>(b, body, { observe: 'response', context }).toPromise();
  }

  private getUrl(target: urlType, path: string) {
    switch (target) {
      case 'id':
        return this.idUrl + (path ? path : '');
      case 'backend':
        return this.baseUrl + (path ? path : '');
      case 'utility':
        return this.utilityUrl + (path ? path : '');
      case 'backend-only-ping':
        return this.backendSecretUrl + (path ? path : '');
      case 'import':
        return this.importUrl + (path ? path : '');
      case 'wiki':
        return this.wikiUrl + (path ? path : '');
      default:
        throw new Error('targetType invalid. Was ' + target);
    }
  }
}
