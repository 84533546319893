import { AceaImportModalOpts } from './acea-import-modal.options';

export class OpenACEAImportModal {
  public static readonly type = '[MODAL] Open acea import modal';
  data: AceaImportModalOpts;
  constructor(
    series: any,
    forecastVersionId: string
  ) {
    this.data = { series, forecastVersionId };
  }
}
