<div class="foldable-row"
     [class]="{ 'foldable-row--dark' : dark }">
  <div class="foldable-row-title">
    <div class="row center-horizontal-and-vertical">
      <div class="col-xs-12">
        <div class="cursor-pointer flex"
             (click)="!manualToggle ? runToggleEvent() : null">
          <div style="width: 13px;"
               class="margin-right-6">
            <i [class]="!open ? 'ion-chevron-right' : 'ion-chevron-down'"
               (click)="runToggleEvent()"></i>
          </div>

          <ng-container *ngIf="!title">
            <ng-content select="[foldable-row-title]"></ng-content>
          </ng-container>
          <ng-container *ngIf="title">
            {{ title }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="foldable-row-body"
       title=""
       *ngIf="open">
    <ng-content></ng-content>
  </div>
</div>
