import { ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { NumberValue } from 'd3';
import { d3Types } from './d3-svg.types';

export namespace d3Utils {

  const DefaultRect: d3Types.ElementSettings = { Rx: 7 };
  const DefaultLine: d3Types.ElementSettings = { Stroke: 'black', StrokeWidth: 1 };
  const DefaultGroup: d3Types.ElementSettings = { Opacity: 1 };
  const DefaultCircle: d3Types.ElementSettings = { R: 5, Fill: 'white', Stroke: '#32395cd6', StrokeWidth: 0 };
  const DefaultText: d3Types.ElementSettings = {};
  const DefaultLabelValue: d3Types.ElementSettings = { Children: [{}, { FontWeight: 600 }] };

  export function createSVG(id: string) {
    const svg = d3.create('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('id', `svg-graph-${id}`);
    return svg;
  }

  export function removeSvg(id: string) {
    d3.select(`#svg-graph-${id}`).remove();
  }

  export function appendSvg(target: any, svg: d3Types.SVG) {
    const container = d3.select(target);
    const n = svg.node();
    container.append(() => n);
  }

  export function appendSvgById(targetId: string, svg: d3Types.SVG) {
    const container = d3.select(`#${targetId}`);
    const n = svg.node();
    container.append(() => n);
  }

  export function addScaleband(container: d3Types.Base, settings: d3Types.ScaleSettings<string, [NumberValue, NumberValue]>) {
    const band = d3.scaleBand().range(settings.range).domain(settings.domain);
    if (!!settings.padding) { band.padding(settings.padding); }
    return _addAxisToGroup(container, band, settings);
  }

  export function addScaleLinear(container: d3Types.Base, settings: d3Types.ScaleSettings<number, number[]>) {
    const band = d3.scaleLinear().range(settings.range).domain(settings.domain);
    return _addAxisToGroup(container, band, settings);
  }

  function _addAxisToGroup<T, T2, T3>(container: d3Types.Base, band: T, settings: d3Types.ScaleSettings<T2, T3>) {
    const g = addGroup(container);
    switch (settings.position) {
      case 'left':
        g.call(d3.axisLeft(band as any)); break;
      case 'right':
        g.call(d3.axisRight(band as any)); break;
      case 'bottom':
        g.call(d3.axisBottom(band as any)); break;
      case 'top':
        g.call(d3.axisTop(band as any)); break;
    }
    return { G: g, Scale: band };
  }

  export function getElementSizes(element: ElementRef) {
    const container = d3.select(element.nativeElement.children[0]);
    const node = container.node();
    return {
      Width: (node as HTMLElement)?.clientWidth || 0,
      Height: (node as HTMLElement)?.clientHeight || 0
    };
  }

  export function addGroup(target: d3Types.Base, settings: d3Types.ElementSettings = {}) {
    const newGroup = addSettings(target.append('g'), Object.assign({}, DefaultGroup, settings));
    if (!!settings.Translate) { moveElement(newGroup, settings.Translate.X, settings.Translate.Y); }
    return newGroup;
  }

  export function addRectElement(target: d3Types.Group, settings: d3Types.ElementSettings = {}) {
    return addSettings(target.append('rect'), Object.assign({}, DefaultRect, settings));
  }

  export function addLineElement(target: d3Types.Group, settings: d3Types.LineSettings) {
    return addLineSettings(target.append('line'), Object.assign({}, DefaultLine, settings));
  }

  export function updateSizes(target: d3Types.Base, sizes: d3Types.SizeSettings) {
    if (!!sizes.W) { target.attr('width', sizes.W); }
    if (!!sizes.H) { target.attr('height', sizes.H); }
    if (!!sizes.R) { target.attr('r', sizes.R); }
  }

  export function addCircleElement(target: d3Types.Group, settings: d3Types.ElementSettings = {}) {
    return addSettings(target.append('circle'), Object.assign({}, DefaultCircle, settings));
  }

  export function addTextElement(target: d3Types.Group, settings: d3Types.ElementSettings = {}) {
    return addSettings(target.append('text'), Object.assign({}, DefaultText, settings));
  }

  export function addLabelValueElement(target: d3Types.Group, settings: d3Types.ElementSettings = {}) {
    const cfg = Object.assign({}, DefaultLabelValue, settings);
    const elem = addSettings(target.append('text'), cfg);
    cfg.Children.forEach(span => addSettings(elem.append('tspan'), Object.assign({}, DefaultText, span)));
    return elem;
  }

  export function updateLabelValue(target: d3Types.Group, content: string[]) {
    target.selectAll('tspan').each(function (d, i) { d3.select(this).text(content[i]); });
  }

  export function moveElement(target: d3Types.Base, newX: number, newY: number, transition = d3.transition().duration(0)) {
    target.transition(transition).attr('transform', `translate(${newX}, ${newY})`);
  }

  export function setVisibilityByClass(grp: d3Types.Group, cls: string, state: boolean) {
    grp.selectAll(`[class*="${cls}"]`).each(function () { setVisibility(d3.select(this), state); });
  }

  /** Set the visibilitu of the hover group. Contains all hover elements */
  export function setVisibility(selection: d3Types.Base | d3Types.Base[], state: boolean) {
    if (!Array.isArray(selection)) { selection = [selection]; }
    selection.forEach(e => e.attr('opacity', state ? 1 : 0));
  }

  export function getTranslation(target: d3Types.Base) {
    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    const transform = target.attr('transform');
    g.setAttributeNS(null, 'transform', transform);
    const matrix = g.transform.baseVal.consolidate().matrix;
    return [matrix.e, matrix.f];
  }

  function addLineSettings<T extends d3Types.Base>(target: T, settings: d3Types.LineSettings) {
    target.attr('x1', settings.X1).attr('y1', settings.Y1);
    target.attr('x2', settings.X2).attr('y2', settings.Y2);
    return addSettings(target, settings);
  }

  function addSettings<T extends d3Types.Base>(target: T, settings: d3Types.ElementSettings) {
    // Content
    if (!!settings.Text) { target.text(settings.Text); }
    // Sizes?
    if (!!settings.Sizes) { updateSizes(target, settings.Sizes); }
    // Design
    if (!!settings.Class) { target.attr('class', settings.Class); }
    if (!!settings.Fill) { target.attr('fill', settings.Fill); }
    if (!!settings.Stroke) { target.attr('stroke', settings.Stroke); }
    if (!!settings.StrokeWidth) { target.attr('stroke-width', settings.StrokeWidth); }
    if (settings.Opacity != null) { target.attr('opacity', settings.Opacity); }
    if (!!settings.FontWeight) { target.style('font-weight', settings.FontWeight); }
    if (!!settings.Styles) { Object.entries(settings.Styles).forEach((k) => target.style(k[0], k[1])); }
    // SVG Attrs
    if (!!settings.R) { target.attr('r', settings.R); }
    if (!!settings.Rx) { target.attr('rx', settings.Rx); }
    if (!!settings.X) { target.attr('x', settings.X); }
    if (!!settings.Y) { target.attr('y', settings.Y); }
    if (!!settings.Cx) { target.attr('cx', settings.Cx); }
    if (!!settings.Cy) { target.attr('cy', settings.Cy); }
    return target;
  }
}
