<indicio-dialog class="create-multiple-fvar-dialog-container">
  <h2 mat-dialog-title>Create multiple forecast variables</h2>
  <mat-dialog-content>
    <div class="data-table"
         style="width: 802px;">
      <div class="data-table__header">
        <div class="column header"
             style="width: 40px;">#</div>
        <div class="column header"
             style="width: 360px;">Name</div>
        <div class="column header"
             style="width: 252px;">Transformation</div>
        <div class="column header"
             style="width: 100px;">Status</div>
        <div class="column"
             style="width: 50px;">
          &nbsp;
        </div>
      </div>
    </div>
    <div class="data-table__body"
         [style.maxHeight.px]="301">
      <div class="body__row body__row--center-height body__row--max-height-30"
           *ngFor="let variable of createDtos; let i = index">
        <div class="column"
             style="width: 40px;">{{ i + 1 }}</div>
        <div class="column"
             style="width: 360px;">
          <ng-container *ngTemplateOutlet="nameTemplate; context: { variable, index: i }">
          </ng-container>
        </div>
        <div class="column"
             style="width: 252px;">
          <ng-template *ngTemplateOutlet="transformTemplate; context: { variable, index: i }">
          </ng-template>
        </div>
        <div class="column"
             style="width: 100px;">
          <ng-template *ngTemplateOutlet="statusTemplate; context: { variable, index: i }">
          </ng-template>
        </div>
        <div class="column"
             style="width: 50px;">
          <div class="ion-trash-a h-red cursor-pointer"
               [ngClass]="{ disabled: inProgress === true || importedCount > 0 }"
               (click)="removeVariable(i)"></div>
        </div>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions style="margin-top: 20px">
    <div></div>
    <div [indicioTippy]="anyNameConflic ? 'One or more name conflicts exists.' : null">
      <button mat-raised-button
              [indicioTippy]="'This will create ' + createDtos.length + ' new forecast variables.' "
              [disabled]="anyNameConflic || createDtos.length < 1"
              (click)="done ? close() : import()"
              type="button"
              color="primary">
        <div>
          <indicio-spinner *ngIf="inProgress"
                           [size]="14"></indicio-spinner>
          {{ done ? 'Close' : 'Import variables' }}
        </div>
      </button>
    </div>
  </mat-dialog-actions>
</indicio-dialog>

<ng-template #nameTemplate
             let-variable="variable"
             let-index="index">

  <!-- Grid: Name/Input, Conflict, Button -->
  <div class="grid-system"
       style="--grid-template: 1fr 30px 30px; column-gap: 5px;">
    <!-- Edit = false -->
    <ng-container *ngIf="!editNameOpen[index]">
      <div class="crop-string name-text"
           [indicioTippy]="variable.Name">{{ variable.Name }}
      </div>
      <div class="edit-btn-container">
        <i *ngIf="nameConflicts[index]"
           class="ion-alert-circled red"
           indicioTippy="A variable with that name already exists in the forecast."></i>
      </div>
      <div class="edit-btn-container h-bg-lightgrey">
        <i class="ion-edit edit-button cursor-pointer"
           (click)="editNameOpen[index] = !editNameOpen[index]"></i>
      </div>
    </ng-container>
    <!-- Edit = true -->
    <ng-container *ngIf="editNameOpen[index]">
      <div class="crop-string"
           style="margin-top: 1px">
        <mat-form-field style="width: -webkit-fill-available;">
          <input type="text"
                 minlength="2"
                 maxlength="128"
                 matInput
                 [(ngModel)]="variable.Name"
                 (ngModelChange)="updateNameConflicts()"
                 class="material"
                 (keyup.enter)="editNameOpen[index] = !editNameOpen[index]">
        </mat-form-field>
      </div>
      <div class="edit-btn-container">
        <i *ngIf="nameConflicts[index]"
           class="ion-alert-circled red"
           indicioTippy="A variable with that name already exists in the forecast."></i>
      </div>
      <div class="edit-btn-container h-bg-lightgrey">
        <fa-icon class="blue cursor-pointer edit-button "
                 [icon]="faSave"
                 (click)="editNameOpen[index] = !editNameOpen[index]"></fa-icon>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #transformTemplate
             let-variable="variable"
             let-index="index">
  {{ transformNames[index] }}
</ng-template>

<ng-template #statusTemplate
             let-variable="variable"
             let-index="index">
  <ng-container *ngIf="infos[index].Status === 'Failed'; else status">
    <span class="red dotted-underline"
          [indicioTippy]="{ content: infos[index].Message, maxWidth: 450 }">
      Failed
    </span>
  </ng-container>
  <ng-template #status>
    <ng-container *ngIf="infos[index].Status === 'Warning'; else realStatus">
      <span class="red dotted-underline"
            indicioTippy="Import to forecast successful.<br><br>Warning: {{ infos[index].Message }}">
        Warning
      </span>
    </ng-container>
    <ng-template #realStatus>
      {{ infos[index].Status }}
    </ng-template>
  </ng-template>
</ng-template>
