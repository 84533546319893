import { Def } from '@core/decorators/def';
import { IHasModelId } from '@core/interfaces/if-has-model-id';
import { UpdatableStatus } from '@core/interfaces/if-update-status';
import { ForecastVariableNowcastOptionsModel } from '@core/store/forecast-variable/models/forecast-variable-nowcast-options.model';
import { TagMetaDTO } from '@core/store/tags/dtos/tags.dtos';
import { ApiStatus } from '@core/types/api.status.type';
import { Transformations } from '@core/types/transformation.type';
import { CalendarEffects } from '@dialogs/variables/forecast-variable/info/tabs/seasonal/fvar-calendar-settings';
import { AggregationMethod } from '@modules/lang/language-files/aggregation';
import { MissingValueModelType } from '@modules/lang/language-files/missing-values';
import { OutlierDetectionType, OutlierModelType, OutlierStrategyType } from '@modules/lang/language-files/outliers';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';
import { SeasonalModelType, SeasonalOutlierType, SeasonalStrategyType } from '@modules/lang/language-files/seasonal';
import { SourceType, SourceTypesType } from '@modules/lang/language-files/source-types';
import { Periodicity } from '@modules/lang/types/periodicity';
import { VariableTransformation } from '@modules/lang/types/variable-transformation';
import { ALGTypes } from '@shared/components/line-graph/alg-types';


export class ForecastVariableMetaModel implements IHasModelId, UpdatableStatus {

  @Def() ForecastVariableId: string;
  @Def() ForecastVersionId: string;
  @Def() ForecastId: string;
  @Def() ProjectId: string;
  @Def() SourceVariableId: string;
  @Def() SourceVariableMetaId: string;
  @Def() RemoteVariableId: string;
  @Def() TrendTwinId: string;
  @Def() MixedFreqTwinId: string;

  @Def() Name: string;
  @Def() SourceVariablePeriodicity: PeriodicityType;
  @Def() Active: boolean;
  @Def() ActiveMF: boolean;
  @Def() ProviderDataMissing: boolean;
  @Def() AggregationMethodId: string;
  @Def() CalculateOutliers: boolean;
  @Def() CalculateSeasonal: boolean;
  @Def() IsExogenous: boolean;
  @Def() ExogenousValuesNeeded: boolean;
  @Def() OutliersFound: boolean;
  @Def() SeasonalFound: boolean;
  @Def() SeasonalLogTrans: boolean;
  @Def() NeedDisAggregation: boolean;
  @Def() IsTrend: boolean;
  @Def() IsMixedFreq: boolean;
  @Def() Aggregated: boolean;
  @Def() NowcastOptions: ForecastVariableNowcastOptionsModel;
  @Def() NowcastExists: boolean;
  @Def() NowcastFailed: boolean;

  @Def() Status: EForecastVariableStatus;

  @Def() Created: Date;
  @Def() FirstDate: Date;
  @Def() LastDate: Date;
  @Def() LatestDate: Date;
  @Def() WillCutDataIfActive: boolean;
  @Def() NowcastNeededAfterDate: Date;
  @Def() LastModified: Date;

  @Def() HasMissingValues: boolean;
  @Def() IsIndicator: boolean;
  @Def() MissingDataPoints: number;
  @Def() Periodicity: PeriodicityType;
  @Def() IsPercent: boolean;
  @Def() IsBase: boolean;
  @Def() PriorMean: number;
  @Def() PriorVariance: number;
  @Def() PriorTransformation: VariableTransformation;
  @Def() Processed: boolean;
  @Def() Source: SourceTypesType;
  @Def() SourceVariableName: string;
  @Def() Transformation: string;
  @Def() ValueTransform: Transformations.FVarTrans;
  @Def() UpdatedValuesExists: boolean;
  @Def() UploadedFileId: string;
  @Def() RemoteFileId: string;
  @Def() SeasonalModel: SeasonalModelType;
  @Def() SeasonalStrategy: SeasonalStrategyType;
  @Def() UseSeasonalTrend: boolean;
  @Def() SeasonalOutliers: boolean;
  @Def() SeasonalCalendarEffects: CalendarEffects.CalendarType[];
  @Def() SeasonalOutlierTypes: SeasonalOutlierType[];
  @Def() OutlierModel: OutlierModelType;
  @Def() OutlierStrategy: OutlierStrategyType;
  @Def() OutlierTypes: OutlierDetectionType[];
  @Def() MissingValueModel: MissingValueModelType = 'amelia';
  @Def() MissingValueIndicators: string[] = [];
  @Def() RemoteReferenceId: string;
  @Def() UsedValueCount: number;

  @Def() CompanyShared: boolean;
  @Def() OwnerId: string;
  @Def() OwnerEmail: string;
  @Def() SourceVariableAccess: boolean;

  @Def() MultivariateTransform: ALGTypes.Transform;
  @Def() ScenarioTransform: ALGTypes.Transform;

  @Def() Tags: TagMetaDTO[] = [];

  // Frontend
  periodicity: Periodicity;
  sourceVariablePeriodicity: Periodicity;
  sourceType: SourceType;
  aggregationMethod: AggregationMethod;
  deleteInProgress: boolean;
  updateInProgress: boolean;
  recalcInProgress: boolean;
  updateError: any;
  updateMessage: string;
  updateStatus: ApiStatus;
  shouldUpdate: boolean;

  SeasonalCalendarRegion: string[] = [];
  SeasonalCalendarHolidays: string[] = [];
  FoundSeasonalCalendarRegion: string[] = [];
  FoundSeasonalCalendarHolidays: string[] = [];

  public getModelId(): string { return this.ForecastVariableId; }
}

export enum EForecastVariableStatus {
  INITIAL = 0,
  DATAPROCESSING_ERROR = 1,
  PROCESSED = 2,
  VARIABLE_ERROR = 3,
  VARSELECT_ERROR = 4
}

