// From indico
import { CountryNamePipe } from '@shared/modules/pipes/country-code-name.pipe';
import { FilterDataPipe } from '@shared/modules/pipes/filter-data.pipe';
import { KeysPipe } from '@shared/modules/pipes/keys.pipe';
import { NotificationPipe } from '@shared/modules/pipes/notification.pipe';
import { OrderByDatePipe } from '@shared/modules/pipes/order-by-date.pipe';
import { OrderByPipe } from '@shared/modules/pipes/order-by.pipe';
import { PropertyValuePipe } from '@shared/modules/pipes/property-value.pipe';
import { ApplyPipe } from './apply.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { FilterPipe } from './filter.pipe';
import { FilterHistoricEventsPipe } from './filters/filter-historicevents.pipe';
import { FilterSourceVariablePipe } from './filters/filter-sourcevariable.pipe';
import { FilterUploadedFilesPipe } from './filters/filter-uploadedfiles.pipe';
import { SanitizerPipe } from './sanitizer.pipe';
import { SecMinHrFromMsPipe } from './sec-min-hr-from-ms.pipe';

export { CountryNamePipe } from '@shared/modules/pipes/country-code-name.pipe';
export { FilterDataPipe } from '@shared/modules/pipes/filter-data.pipe';
export { KeysPipe } from '@shared/modules/pipes/keys.pipe';
export { NotificationPipe } from '@shared/modules/pipes/notification.pipe';
export { OrderByDatePipe } from '@shared/modules/pipes/order-by-date.pipe';
export { OrderByPipe } from '@shared/modules/pipes/order-by.pipe';
export { PropertyValuePipe } from '@shared/modules/pipes/property-value.pipe';
export { ApplyPipe } from './apply.pipe';
export { CapitalizePipe } from './capitalize.pipe';
export { DateFormatPipe } from './date-format.pipe';
export { FilterPipe } from './filter.pipe';
export { FilterHistoricEventsPipe } from './filters/filter-historicevents.pipe';
export { FilterSourceVariablePipe } from './filters/filter-sourcevariable.pipe';
export { FilterUploadedFilesPipe } from './filters/filter-uploadedfiles.pipe';
export { SanitizerPipe } from './sanitizer.pipe';
export { SecMinHrFromMsPipe } from './sec-min-hr-from-ms.pipe';

export const IndicioPipes = [
  ApplyPipe,
  CountryNamePipe,
  DateFormatPipe,
  FilterDataPipe,
  FilterSourceVariablePipe,
  FilterHistoricEventsPipe,
  FilterUploadedFilesPipe,
  FilterPipe,
  KeysPipe,
  NotificationPipe,
  OrderByDatePipe,
  OrderByPipe,
  PropertyValuePipe,
  SanitizerPipe,
  SecMinHrFromMsPipe,
  CapitalizePipe
];






