import { Def } from '@core/decorators/def';
import { PlotValueDTO } from '@core/entities/dtos/plot-value-dto';
import { PeriodicityType } from '@modules/lang/language-files/periodicities';

export class PeriodicityFitDTO {
  @Def() Periodicity: PeriodicityType;
  @Def() Missing: number;
  @Def() Extra: number;
}

/** Key in AllFits is of PeriodicityType type. */
export class PeriodicityInfoDTO {
  @Def() IsGoodFit: boolean;
  @Def() BestFit: PeriodicityFitDTO;
  @Def() AllFits: PeriodicityFitDTO[];
}

export class PeriodicityFitInfoDTO {
  Periodicity: PeriodicityType;
  Missing: string[];
  Extra: string[];
}
export class PeriodicitySourceVarInfoDTO {
  SourceVariableId: string;
  PeriodicityInfos: PeriodicityFitInfoDTO[];
}



export class SourceVariableVersionMetaDTO {
  @Def() SourceVariableMetaId: string;
  @Def() Name: string;
  @Def() SourceVariableName: string;
  @Def() SourceVariableId: string;
  @Def() SourceType: string;
  @Def() VisibilityLevelId: string;
  @Def() ValueCount: number;
  @Def() FirstDate: string;
  @Def() LastDate: string;
  @Def() Base: boolean;
  @Def() Periodicity: string;
  @Def() ManualPeriodicity: boolean;
  @Def() PeriodicityInfo: PeriodicityInfoDTO;
  @Def() DuplicateDates: boolean;
  @Def() Forecasted: boolean;
  @Def() WeightMethod: string;
  @Def() Models: string[];
  @Def() CreatedDate: string;
  @Def() CompanyId: string;
  @Def() ParentForecastId: string;
  @Def() ParentForecastVersionId: string;
  @Def() ForecastResultId: string;
  @Def() UploadedFileVersionId: string;
  @Def() UploadedFileId: string;
  @Def() RemoteVariableId: string;
  @Def() Used: boolean;
  @Def() AutoGenerated: boolean;
  @Def() FromNowcast: boolean;
}

export class SourceVariableVersionDTO extends SourceVariableVersionMetaDTO {
  @Def() SheetIndex: number;
  @Def() DateIndex: number;
  @Def() ValueIndex: number;
  @Def() IdentifierIndex: number;
  @Def() Identifier: string;
  @Def() DisabledRows: number[];
  @Def() PlotValues: PlotValueDTO[];
}
