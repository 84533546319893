import { MissingValueModelChangedDTO } from '@core/services/pusher/dtos/fvar-set-missing-value-model.dto';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { ALGTypes } from '@shared/components/line-graph/alg-types';
import { UpdatableForecastVariableFieldsDTO } from './dtos/forecast-variable-meta-dto';
import { ForecastVariableNowcastOptionsDTO } from './dtos/forecast-variable-nowcast-options-dto';

export namespace VariableActions {
  export class RemoveTrend {
    public static readonly type = '[ForecastVariable] Remove forecast variable trend modelling success';
    constructor(public forecastVersionId: string, public forecastVariableId: string) { }
  }

  export class SetMissingValueModel {
    public static readonly type = '[ForecastVariable] Set missing values model';
    constructor(public forecastVersionId: string, public forecastVariableId: string, public dto: MissingValueModelChangedDTO) { }
  }

  export class GetForecastVariable {
    public static readonly type = '[ForecastVariable] [GET] Forecast variable';
    constructor(public forecastVersionId: string, public forecastVariableId: string) { }
  }

  export class UpdateForecastVariablesFields {
    public static readonly type = '[ForecastVariable] Update forecast variables fields';
    constructor(public forecstVariables: UpdatableForecastVariableFieldsDTO[]) { }
  }

  export class SetNowcastOptions {
    public static readonly type = '[ForecastVariable] Set Nowcast options';
    constructor(public forecastVersionId: string, public forecastVariableId: string, public options: ForecastVariableNowcastOptionsDTO) { }
  }

  export class SaveTransformation {
    public static readonly type = '[ForecastVariable] Save transformation';
    constructor(public forecastVariableId: string, public view: ALGTypes.Graph, public transform: ALGTypes.Transform) { }
  }

  export class CreatedForecastVariable {
    public static readonly type = '[ForecastVariable] Created forecast variable';
    constructor(public forecastVariableId: string) { }
  }
}
export class GetForecastVariableSuccessAction {
  public static readonly type = '[ForecastVariable] Get forecast variable success';
  constructor(public variable: ForecastVariableModel) { }
}

export class GetAllForecastVariablesSuccessAction {
  public static readonly type = '[ForecastVariable] Get all forecast variables in forecast success';
  constructor(public forecastVersionId: string, public variable: ForecastVariableModel[]) { }
}

export class RemoveForecastVariableSuccessAction {
  public static readonly type = '[ForecastVariable] Remove forecast variable success';
  constructor(public forecastVersionId: string, public variableId: string) { }
}
