import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'indicio-foldable-row',
  templateUrl: './foldable-row.component.html',
  styleUrls: ['./foldable-row.component.less']
})
export class FoldableRowComponent {

  @Input() open = false;
  @Input() title: string;
  @Input() bgColor: string = '#EFEFEF';
  @Input() dark: boolean;
  @Input() manualToggle: boolean = false;

  @Output() toggleEvent = new EventEmitter();

  public runToggleEvent() {
    this.open = !this.open;
    this.toggleEvent.emit(this.open);
  }
}
