import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IndicioConstants } from '@core/constants/indicio.constants';
import { EnvironmentService } from '@core/services/environment/environment.service';



export interface PickSystemRoleDialogData {
  Roles: IndicioConstants.SystemRoleType[];
}

@Component({
  selector: 'indicio-pick-system-role-dialog',
  templateUrl: 'pick-system-role.dialog.component.html',
  styleUrls: ['pick-system-role.dialog.component.less']
})
export class PickSystemRoleDialogComponent {
  public static Id: string = 'PickSystemRoleDialogComponent';

  public role: IndicioConstants.SystemRoleType;
  public roles: { Value: IndicioConstants.SystemRoleType, Display: string; }[];

  constructor(
    public dialogRef: MatDialogRef<PickSystemRoleDialogComponent>,
    private env: EnvironmentService,
    @Inject(MAT_DIALOG_DATA) public data: PickSystemRoleDialogData
  ) {
    this.role = data.Roles[0];
    this.roles = IndicioConstants.SystemRoles.filter(x => data.Roles.includes(x.Value));
  }

  public proceed() {
    this.dialogRef.close(this.role);
  }
}
