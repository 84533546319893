import { AlphaMainModalOpts } from './alpha-main-modal.options';

export class OpenAlphaMainModal {
  public static readonly type = '[MODAL] Open alpha main modal';
  data: AlphaMainModalOpts;
  constructor(
    forecastVersionId: string
  ) {
    this.data = { forecastVersionId };
  }
}
