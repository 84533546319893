<div class="fcast-info-indicator-analysis-tab">
  <div style="margin: -10px 0 10px 0;">
    <indicio-wiki-modal-row slug="indicator-analysis"
                            title="Guide to using indicator analysis"></indicio-wiki-modal-row>
  </div>

  <mat-accordion>
    <!-- Stationarity-->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Settings
        </mat-panel-title>
        <mat-panel-description>
          Settings for indicator analysis
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngIf="vsSettings; else loadingTemplate"
                    [ngTemplateOutlet]="settingsTemplate"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #settingsTemplate>
  <indicio-modal-option-row optionTitle="Strategy"
                            optionSubtitle="Which method should be used to find the best indicators"
                            wikiSlug="indicator-analysis-strategies"
                            [disabled]="!canUpdateForecast">
    <mat-form-field style="margin-top:15px">
      <mat-select [value]="vsSettings.VSMode"
                  required
                  [disabled]="!canUpdateForecast"
                  panelClass="fitted-select"
                  (selectionChange)="setVsMode($event)">
        <mat-option [value]="mode.Value"
                    [title]="mode.Description"
                    *ngFor="let mode of envService.SelectableVSModes">{{ mode.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Coefficient search method"
                            optionSubtitle="How should coefficient influence be calculated"
                            [disabled]="!canUpdateForecast || vsSettings.VSMode !== 'coef'"
                            [rowHoverText]="vsSettings.VSMode !== 'coef' ? 'Setting unavailable when using ' + vsMode.Display + ' strategy': null">
    <mat-form-field style="margin-top:15px">
      <mat-select [value]="vsSettings.VSCoefMethod"
                  required
                  panelClass="fitted-select"
                  [disabled]="!canUpdateForecast || vsSettings.VSMode !== 'coef'"
                  (selectionChange)="vsSettings.VSCoefMethod = $event.value">
        <mat-option [value]="mode.Value"
                    [title]="mode.Description"
                    *ngFor="let mode of envService.VSCoefMethods">{{ mode.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Variable selection horizon"
                            optionSubtitle="For which forecast horizon should the indicator analysis be optimised"
                            [disabled]="!canUpdateForecast || vsSettings.VSMode !== 'stepwise'"
                            [rowHoverText]="vsSettings.VSMode !== 'stepwise' ? 'Setting unavailable when using ' + vsMode.Display + ' strategy': null">
    <mat-form-field style="margin-top:15px">
      <mat-select [value]="forecastVersion.StepChosen"
                  (selectionChange)="newStepChosen = $event.value"
                  required
                  [disabled]="!canUpdateForecast || vsSettings.VSMode !== 'stepwise'">
        <mat-option [value]="num"
                    [title]="num"
                    *ngFor="let num of stepwiseOptions">{{ num === 0 ? 'All' : num }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Maximum number of indicators"
                            optionSubtitle="The maximum number of indicators to set as active"
                            [disabled]="!canUpdateForecast">
    <mat-form-field style="margin-top:15px">
      <mat-select [value]="vsSettings.VSBestVarCount"
                  [disabled]="!canUpdateForecast"
                  (selectionChange)="vsSettings.VSBestVarCount = $event.value">
        <mat-option [value]="num"
                    [indicioTippy]="num === -1 ? 'Number of indicators are chosen automatic to maximize the number of lags in the model' : null"
                    *ngFor="let num of [-1, 0, 3, 5, 8]">{{ num === 0 ? 'No limit' : num === -1 ? 'Auto' : num }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Lasso structure"
                            optionSubtitle="Penalty structure to use for indicator analysis"
                            [disabled]="!canUpdateForecast">
    <mat-form-field style="margin-top:15px">
      <mat-select [value]="vsSettings.VSLassoStruct"
                  [disabled]="!canUpdateForecast"
                  (selectionChange)="vsSettings.VSLassoStruct = $event.value">
        <mat-option [value]="struct.Value"
                    [title]="struct.Description"
                    *ngFor="let struct of validLassoStructs">{{ struct.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Accuracy measure"
                            optionSubtitle="Select accuracy measure to use when comparing indicators"
                            [disabled]="!canUpdateForecast || !measurementActive"
                            [rowHoverText]="!measurementActive ? 'Setting unavailable when using ' + vsMode.Display + ' strategy': null">
    <mat-form-field style="margin-top:15px">
      <mat-select [value]="vsSettings.VSMeasurement"
                  [disabled]="!canUpdateForecast || !measurementActive"
                  (selectionChange)="vsSettings.VSMeasurement = $event.value">
        <mat-option [value]="meas.Value"
                    [title]="meas.Description"
                    *ngFor="let meas of envService.validVSMeasurements(this.vsSettings.VSMode)">
          {{ meas.Display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </indicio-modal-option-row>

  <indicio-modal-option-row optionTitle="Rolling window"
                            optionSubtitle="Evaluate indicator influence over time"
                            [disabled]="!canUpdateForecast || vsSettings.VSMode === 'stepwise'"
                            [rowHoverText]="vsSettings.VSMode === 'stepwise' ? 'Setting unavailable when using ' + vsMode.Display + ' strategy': null">
    <div style="margin-top:25px"
         class="toggle-icon text-right"
         [ngClass]="{'disabled': vsSettings.VSMode === 'stepwise' }">
      <mat-slide-toggle data-test-id='auto-seasonal-update-toggle-btn'
                        [disabled]="!canUpdateForecast || vsSettings.VSMode === 'stepwise'"
                        [checked]="vsSettings.VSUseRollingWindow"
                        (change)="vsSettings.VSUseRollingWindow = !vsSettings.VSUseRollingWindow">
      </mat-slide-toggle>
    </div>
  </indicio-modal-option-row>

  <!-- Save button -->
  <div class="pull-right"
       style="width:fit-content; margin-top: 25px;">
    <button *ngIf="canUpdateForecast"
            mat-raised-button
            type="button"
            color="primary"
            [disabled]="!changed"
            (click)="save()">
      <span *ngIf="!pending">Save changes</span>
      <indicio-spinner *ngIf="pending"></indicio-spinner>
    </button>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div>
    <indicio-spinner></indicio-spinner> Loading...
  </div>
</ng-template>
