import { Component, ViewEncapsulation } from '@angular/core';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastVariableModel } from '@core/store/forecast-variable/models/forecast-variable-model';
import { RefreshForecastVariableStepEventArgsDTO } from '@core/store/forecast/dtos/forecast-version/pusher-event-args.dto';
import { ForecastActions } from '@core/store/forecast/forecast.actions';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { ForecastVersionModel } from '@core/store/forecast/models/forecast-version.model';
import { ProfileFrontendService } from '@core/store/profile/profile.frontend.service';
import { ProjectFrontendService } from '@core/store/project/project.frontend.service';
import { ProjectModel } from '@core/store/project/project.model';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { ForecastWarningsModalOpts } from './forecast-warnings-modal.options';


@Component({
  selector: 'indicio-forecast-warnings-modal',
  templateUrl: './forecast-warnings-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ForecastWarningsModalComponent extends ModalModelComponent {

  opts: ForecastWarningsModalOpts;
  toUpdate: ForecastVariableModel[];
  forecastVersion: ForecastVersionModel;
  project: ProjectModel;
  stepStatus: RefreshForecastVariableStepEventArgsDTO;
  updatedCount = 0;
  processDone = false;
  newVersion = false;
  isOwner: boolean;

  constructor(
    protected store: Store,
    public envService: EnvironmentService,
    private forecastService: ForecastFrontendService,
    private clientService: ClientFrontendService,
    private projectService: ProjectFrontendService,
    private profileService: ProfileFrontendService,
    private status: StatusService
  ) {
    super();
  }

  public setOptions(options: ForecastWarningsModalOpts) {
    this.opts = options;

    const fcvPromise = this.forecastService.getOrFetchForecastVersion(this.opts.forecastVersionId);
    const projPromise = this.projectService.getOrFetchProject(this.clientService.activeCompanyId, this.opts.projectId);
    Promise.all([fcvPromise, projPromise]).then(([fversion, proj]) => {
      this.forecastVersion = fversion;
      this.project = proj;
      this.isOwner = this.project.isUserOwner(this.profileService.profile.Email);
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public discard() {
    this.pending = true;
    this.forecastService.removeIndicatorWarnings(this.opts.forecastVersionId)
      .then(() => {
        this.close();
        this.store.dispatch(new ForecastActions.ClearWarnings(this.opts.forecastVersionId));
      })
      .catch(e => this.status.setError(e, true))
      .finally(() => this.pending = false);
  }
}
