<div>
  <mat-form-field>
    <mat-label>Tags</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row *ngFor="let tag of result"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="clearTags(tag)">
        {{ tag.Name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input [placeholder]="placeholder"
             #searchInput
             #trigger="matAutocompleteTrigger"
             [formControl]="control"
             [matAutocomplete]="autoRelative"
             [matChipInputFor]="chipList"
             (ngModelChange)="changed($event)"
             (blur)="removeInputValue();">
    </mat-chip-grid>
    <mat-autocomplete #autoRelative="matAutocomplete"
                      (optionSelected)="optionSelected($event)">
      <mat-option *ngFor="let tag of filteredTags;"
                  [value]="tag">
        {{ tag.Name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
