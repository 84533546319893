import { EditHEventModalOpts } from './edit-historic-event-modal.options';

export class OpenEditHistoricEventModal {
  public static readonly type = '[MODAL] Open edit historic event modal';
  data: EditHEventModalOpts;
  constructor(
    forecastVersionId: string,
    eventId: string = null,
    onBack?: Function
  ) { this.data = { forecastVersionId, eventId, onBack }; }
}
