import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import {
  GetAssessmentSuccessAction,
  GetAssessmentsInForecastVersionSuccessAction,
  RemoveAssessmentSuccessAction
} from '@core/store/assessment/assessment.actions';
import { AssessmentBackendService } from '@core/store/assessment/assessment.backend.service';
import { AssessmentModel } from '@core/store/assessment/assessment.model';
import { map } from 'rxjs/operators';
import { AssessmentState } from './assessment.state';

@Injectable({
  providedIn: 'root'
})
export class AssessmentFrontendService {

  // OBSERVABLES
  public assessments$ = this.store.select(AssessmentState.assessments);
  public assessmentsInForecastVersion$(fVersionId: string) {
    return this.assessments$.pipe(map(x => x.filter(y => y.ForecastVersionId === fVersionId)));
  }

  // GETTERS
  public get assessments() { return this.store.selectSnapshot(AssessmentState.assessments); }
  public assessmentsInForecastVersion(fVersionId: string) { return this.assessments.filter(x => x.ForecastVersionId === fVersionId); }

  constructor(
    private store: Store,
    private backendService: AssessmentBackendService
  ) {
  }

  public createAssessment(assessment: AssessmentModel) {
    return this.backendService.createAssessment(assessment)
      .then(ass => {
        this.store.dispatch(new GetAssessmentSuccessAction(ass));
        return ass;
      });
  }

  public getAssessment(ForecastVersionId: string, assId: string) {
    this.backendService.getAssessment(ForecastVersionId, assId)
      .then(assessment => {
        this.store.dispatch(new GetAssessmentSuccessAction(assessment));
        return assessment;
      });
  }

  public syncAssessmentsInForecast(ForecastVersionId: string) {
    return this.backendService.getAllAssessmentsInForecast(ForecastVersionId)
      .then(assessments => {
        this.store.dispatch(new GetAssessmentsInForecastVersionSuccessAction(ForecastVersionId, assessments));
        return assessments;
      });
  }

  public updateAssessment(assessment: AssessmentModel) {
    return this.backendService.updateAssessment(assessment)
      .then(ass => {
        this.store.dispatch(new GetAssessmentSuccessAction(ass));
        return ass;
      });
  }

  public updateAssessmentOverview(assessment: AssessmentModel) {
    return this.backendService.updateAssessmentOverview(assessment)
      .then(ass => {
        this.store.dispatch(new GetAssessmentSuccessAction(ass));
        return ass;
      });
  }

  public removeAssessment(assessment: AssessmentModel) {
    return this.backendService.removeAssessment(assessment)
      .then(() => {
        this.store.dispatch(new RemoveAssessmentSuccessAction(assessment.ForecastVersionId, assessment.AssessmentId));
      });
  }
}
