import { Def } from '@core/decorators/def';

export class KoladaMunicipalityValueModel {
  @Def() count: number;
  @Def() gender: string;
  @Def() status: string;
  @Def() value: number;
}

export class KoladaMunicipalityValuePeriodModel {
  @Def() kpi: string;
  @Def() municipality: string;
  @Def() period: number;
  @Def() values: KoladaMunicipalityValueModel[];

  uri: string;
}

export class KoladaMunicipalityValueListModel {
  @Def() count: number;
  @Def() values: KoladaMunicipalityValuePeriodModel[];
  uri: string;
}
