import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActionService } from '@core/services/actions/actions.service';
import { DialogV2BaseService } from '@dialogs/base/dialogs.V2.base-service';
import { take } from 'rxjs/operators';
import { CreateMultiFVariableDialogData, FVarCreateMultiDialogComponent } from './forecast-variable/create-multiple/create-multiple-fvar.dialog';
import { FVarEngineeringMultiDialogComponent, FVarEngineeringMultiDialogData } from './forecast-variable/engineering-multiple/engineering-multi-fvar.dialog';
import { FVarInfoDialogComponent, ForecastVariableInfoDialogData } from './forecast-variable/info/forecast-variable-info.dialog';
import { FVarUpdateDialogComponent, FVarUpdateDialogData } from './forecast-variable/update/update-variables.dialog';
import { SyncableVariablesDialogComponent, SyncableVariablesDialogData } from './remote-variable/syncable-variables/syncable-variables.dialog';
import { SourceVariableInfoDialogComponent, SourceVariableInfoDialogData } from './source-variable/info/source-variable-info.dialog';


@Injectable({ providedIn: 'root' })
export class VariableDialogService extends DialogV2BaseService {

  constructor(
    actions: ActionService,
    dialog: MatDialog
  ) {
    super(dialog, actions);
  }

  public openSyncDataDialog(data: SyncableVariablesDialogData) {
    const config: MatDialogConfig = { width: '850px', position: { top: '6%' } };
    const cfg = this.getConfig(SyncableVariablesDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, boolean>(SyncableVariablesDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }

  public openFVarUpdateDialog(data: FVarUpdateDialogData, cfg?: MatDialogConfig) {
    if (!cfg) { cfg = { width: '700px', position: { top: '6%' } }; }
    const config = this.getConfig(FVarUpdateDialogComponent.Id, data, cfg);
    const dialogRef = this.dialog.open(FVarUpdateDialogComponent, config);
    return dialogRef.afterClosed().pipe(take(1));
  }

  public openCreateMultiFVar(data: CreateMultiFVariableDialogData) {
    const config: MatDialogConfig = { width: '850px', position: { top: '6%' } };
    const cfg = this.getConfig(FVarCreateMultiDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, boolean>(FVarCreateMultiDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }

  public openEngineeringMultiFVar(data: FVarEngineeringMultiDialogData) {
    const config: MatDialogConfig = { width: '650px', position: { top: '6%' } };
    const cfg = this.getConfig(FVarEngineeringMultiDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, boolean>(FVarEngineeringMultiDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }

  public openForecastVariableInfo(data: ForecastVariableInfoDialogData) {
    const config: MatDialogConfig = { width: '815px' };
    const cfg = this.getConfig(FVarInfoDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, any>(FVarInfoDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }

  public openSourceVariableInfo(data: SourceVariableInfoDialogData) {
    const config: MatDialogConfig = { width: '815px' };
    const cfg = this.getConfig(SourceVariableInfoDialogComponent.Id, data, config);
    const ref = this.dialog.open<any, any, any>(SourceVariableInfoDialogComponent, cfg);
    return ref.afterClosed().pipe(take(1)).toPromise();
  }
}
