import { Def } from '@core/decorators/def';
import { ResultFileDTO } from '@core/entities/dtos/result-file-dto';
import { VSMeasurementType, VSModeType, VsResultType } from '@modules/lang/language-files/var-select';

export class CoefLagDTO {
  @Def() ObjectId: string;
  @Def() ForecastVariableId: string;
  @Def() Values: number[];
  @Def() Const?: number;
  @Def() IsEvent: boolean;
}

export class XNameDTO {
  XName: string;
  ObjectId?: string;
  ForecastVariableId?: string;
}

export class VariableSelectionModelDTO {
  ForecastVariableId: string = null;
  Name: string = null;
  IsMainVariable: boolean = false;
  Influence: number[] = [];
  OOSInfluence: number[] = [];
  InSampleInfluence: number[] = [];
  COEFInfluence: number[] = [];
  COEFLags: CoefLagDTO[] = [];
  ActiveIndicatorIds: string[] = [];
  Equation: string;
  color: string;
}

export class VariableSelectionExoModelDTO {
  IsEvent: string = null;
  ObjectId: string = null;
  OOSInfluence: number[] = [];
  InSampleInfluence: number[] = [];
  COEFInfluence: number[] = [];
  COEFLags: CoefLagDTO[] = [];
  color: string;
  name: string;
  IsMainVariable: boolean = false;
}

export class VariableSelectionResultDTO {
  @Def() VariableSelectResultId: string;
  @Def() ForecastVersionId: string;
  @Def() RRequestId: string;

  @Def() ResultAvailable: boolean;
  @Def() VsResultType: VsResultType;
  @Def() VSMode: VSModeType;
  @Def() VSLassoStruct: string;
  @Def() VSMeasurement: VSMeasurementType;
  @Def() VSBestVarCount: number;
  @Def() VSUseRollingWindow: boolean;
  @Def() VSPlots: ResultFileDTO[];

  @Def() ExitSuccess: boolean;
  @Def() ExitError: boolean;

  @Def() InfoMessages: string[];
  @Def() WarningMessages: string[];
  @Def() ErrorMessages: string[];

  @Def() VariableSelectionModels: VariableSelectionModelDTO[];
  @Def() VariableSelectionExoModels: VariableSelectionExoModelDTO[];
  @Def() NameMaps: XNameDTO[];
}
