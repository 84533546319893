import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NavigateToDataProvider } from '@core/actions/navigation.actions';
import { DataProvider } from '@core/constants/provider-definitions';
import { StatusService } from '@core/services/status/status.service';
import { ClientFrontendService } from '@core/store/client/client.frontend.service';
import { ForecastFrontendService } from '@core/store/forecast/forecast.frontend.service';
import { UpdateRemoteDataSourceDTO } from '@core/store/providers/dtos/remote-data-source.dto';
import { NewRemoteDataSourceModelModel } from '@core/store/providers/models/new-remote-data-source-model.model';
import { RemoteDataSourceModel } from '@core/store/providers/models/remote-data-source.model';
import { ProviderFrontendService } from '@core/store/providers/provider.frontend.service';
import { SharepointService } from '@core/store/providers/sharepoint/sharepoint.service';
import { Store } from '@ngxs/store';
import { ModalModelComponent } from '@shared/modals/modal.model';
import { DialogService } from '@shared/modules/dialogs/dialog.service';
import { OpenRemoveSharepointModal } from './remove/remove-sharepoint-modal.actions';
import { SharepointModalOpts } from './sharepoint-modal.options';


@Component({
  selector: 'indicio-sharepoint-modal',
  templateUrl: './sharepoint-modal.component.html',
  styleUrls: ['./sharepoint-modal.component.less']
})
export class SharepointModalComponent extends ModalModelComponent {

  modalTitle = 'Add sharepoint integration';
  subTitle = '';
  public step = 1;

  // Step 1
  sharepointOrgName: string = '';
  sharepointSiteName: string = '';
  sharepointAddressAppReg: SafeUrl = '';
  sharepointAppInvUrl: SafeUrl = '';

  // Step 2
  // New provider information
  sharepointClientId: string = '';
  sharepointSecretId: string = '';
  sharepointTenantId: string = '';

  opts: SharepointModalOpts;

  edit: RemoteDataSourceModel = null;
  RemoteDataSourceId: string = null;
  currentProvider: RemoteDataSourceModel;
  forecastVersionId: string = null;

  public get disableConnectBtn() {
    const disable = !(!!this.sharepointClientId && !!this.sharepointTenantId && !!this.sharepointSecretId);
    return disable;
  }

  constructor(
    public store: Store,
    public service: SharepointService,
    private statusService: StatusService,
    private domSantizer: DomSanitizer,
    private providerService: ProviderFrontendService,
    private dialogService: DialogService,
    private clientService: ClientFrontendService,
    private forecastService: ForecastFrontendService
  ) {
    super();
    this.onOrgNameChange();
    const getFverId = this.forecastService.activeVersion$(this.forecastService.activeForecastId);
    getFverId.subscribe((x) => {
      if (x == null) {
        return;
      }
      this.forecastVersionId = x.ForecastVersionId;
    });
  }

  public update() {
    if (this.edit != null) {
      const dto = new UpdateRemoteDataSourceDTO();
      dto.ApiKey = this.edit.ApiKey;
      dto.Password = this.sharepointSecretId;
      const url = `${this.sharepointOrgName}.sharepoint.com`;
      dto.RemoteUri = `https://${url}/sites/${this.sharepointSiteName}/`;
      dto.Provider = DataProvider.sharepoint;
      dto.User = this.sharepointClientId;
      dto.RemoteDataSourceId = this.edit.RemoteDataSourceId;
      const ExtraColumn = { SharepointUrl: url };
      dto.Extras = JSON.stringify(ExtraColumn);
      this.service.updateSharepointSite(dto)
        .then(() => {
          this.close();
          this.statusService.setMessage('Sharepoint connection updated', 'Success');
        })
        .catch(err => this.statusService.setError(err, true))
        .finally(() => this.isLoading = false)
        ;
    }
  }

  public removeConnection() {
    if (!this.edit) { return; }
    this.close();
    this.store.dispatch(new OpenRemoveSharepointModal(this.forecastService.activeForecastId, this.forecastVersionId,
      this.currentProvider.RemoteDataSourceId));
  }

  public connect() {
    const dto = new NewRemoteDataSourceModelModel();
    dto.ApiKey = this.sharepointTenantId;
    dto.User = this.sharepointClientId;
    dto.Password = this.sharepointSecretId;
    dto.RemoteUri = `https://${this.sharepointOrgName}.sharepoint.com/sites/${this.sharepointSiteName}/`;
    dto.Extras = JSON.stringify({ SharepointUrl: `${this.sharepointOrgName}.sharepoint.com` });
    dto.Provider = DataProvider.sharepoint;
    this.isLoading = true;
    this.service.addSharepointSite(dto)
      .then(() => {
        this.close();
        this.store.dispatch(new NavigateToDataProvider(this.opts.forecastId, DataProvider.sharepoint));
        this.statusService.setMessage('Sharepoint connection established', 'Success');
      })
      .catch(err => {
        if (err.error === 'Could not sync data from sharepoint: Authorization method not allowed') {
          this.step = 5;
          this.statusService.setMessage('Could not connect to sharepoint', 'Warning', true);
          this.updateTitle();
        } else {
          this.statusService.setError(err, true);
        }
      })
      .finally(() => this.isLoading = false)
      ;
  }

  public getTenantId() {
    this.service.getTenantId(`${this.sharepointOrgName}.sharepoint.com`)
      .then(id => {
        this.sharepointTenantId = id;
      })
      .catch(_ => this.statusService.setMessage('Could not verify your sharepoint address', 'Error', true))
      .finally()
      ;
  }

  public onOrgNameChange() {
    const val = `https://${this.sharepointOrgName}.sharepoint.com/sites/${this.sharepointSiteName}/_layouts/15/appregnew.aspx`;
    const url = this.domSantizer.bypassSecurityTrustUrl(val);
    this.sharepointAddressAppReg = url;
    this.setTenantUrl();
  }

  private setTenantUrl() {
    const val = `https://${this.sharepointOrgName}.sharepoint.com/sites/${this.sharepointSiteName}/_layouts/15/appinv.aspx`;
    const url = this.domSantizer.bypassSecurityTrustUrl(val);
    this.sharepointAppInvUrl = url;
  }

  public isNextDisabled() {
    switch (this.step) {
      case 1:
        return !(!!this.sharepointOrgName && !!this.sharepointSiteName);
      case 2:
        return !(!!this.sharepointClientId && !!this.sharepointSecretId);
      case 4:
        return !!!this.sharepointTenantId;
      case 3:
      default:
        return false;
    }
  }

  public setOptions(options: SharepointModalOpts) {
    this.opts = options;
    this.isLoading = false;
    if (options.edit) {
      this.currentProvider = this.providerService.availableDataSources.find(x => x.Provider === DataProvider.sharepoint);
      this.sharepointOrgName = this.currentProvider.RemoteUri.match(/\/\/(.*)\.sharepoint/)[1];
      this.sharepointSiteName = this.currentProvider.RemoteUri.match(/sites\/(.*)\//)[1];
      this.RemoteDataSourceId = options.edit.RemoteDataSourceId;
      this.edit = options.edit;
      this.modalTitle = 'Edit sharepoint';
      this.onOrgNameChange();
      this.setTenantUrl();
    }
  }

  public next() {
    if (this.step === 4) {
      if (this.edit) {
        return this.update();
      }
      this.connect();
    } else {
      if (this.step === 3) {
        this.getTenantId();
      }
      this.step++;
      this.updateTitle();
    }
  }

  private updateTitle() {
    let mode = 'Add';
    if (this.edit) {
      mode = 'Edit';
    }
    switch (this.step) {
      case 1:
        this.modalTitle = `${mode} sharepoint integration (1/4)`;
        this.subTitle = '';
        break;
      case 2:
        this.modalTitle = `${mode} sharepoint integration (2/4)`;
        this.subTitle = 'Create a Sharepoint application';
        break;
      case 3:
        this.modalTitle = `${mode} sharepoint integration (3/4)`;
        this.subTitle = 'Invite the application';
        break;
      case 4:
        this.modalTitle = `${mode} sharepoint integration (4/4)`;
        this.subTitle = 'Review settings and connect';
        break;
      case 5:
        this.modalTitle = `${mode} sharepoint integration`;
        this.subTitle = 'Allow custom application to authenticate';
        break;
    }
  }

  public back() {
    if (this.step === 1) {
      this.close();
    } else {
      this.step--;
      this.updateTitle();
    }
  }
}
