export class AuditLogSearchDTO {
  CompanyId: string;
  ProjectName: string;
  ProjectId: string;
  ForecastName: string;
  ForecastId: string;
  SubjectName: string;
  SubjectId: string;
  ObjectName: string;
  ObjectId: string;
  Action: string;
  From: Date;
  To: Date;
  Skip: number = 0;
  Take: number = 100;
}
